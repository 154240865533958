import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function SignalStep2Icon(props: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper {...props} viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="10" fill="#F2B824" />
      <path
        d="M8.48565 13.2897C9.18548 12.6771 9.25617 11.6131 8.64355 10.9133C8.03094 10.2135 6.96698 10.1428 6.26715 10.7554C5.56732 11.368 5.49663 12.4319 6.10925 13.1318C6.72187 13.8316 7.78582 13.9023 8.48565 13.2897Z"
        fill="white"
        stroke="white"
        strokeWidth="1.41667"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.8579 12.3201C11.897 11.7317 11.8198 11.1412 11.6308 10.5826C11.4417 10.024 11.1445 9.50802 10.756 9.06426C10.3675 8.6205 9.89547 8.2576 9.36676 7.99628C8.83804 7.73496 8.26303 7.58035 7.67456 7.54126"
        stroke="white"
        strokeWidth="1.41667"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </SvgWrapper>
  );
}
