import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import * as styles from 'src/modals/common/Purchase/PurchaseModal.css';
import { Feature } from 'src/WebApiController';

import { SellerAccountSettingCurrencyConversionOverrides } from '../SellerAccountSettingCurrencyConversionOverrides';
import { SellerAccountSettingPaymentMethods } from '../SellerAccountSettingPaymentMethods';
import { SellerAccountSettingVendors } from '../SellerAccountSettingVendors';

export const SellerAccountSettingBody = () => {
  const hasDailyFxRateOverrideFeature = useUserHasFeature(
    Feature.OverrideDailyFxRate
  );
  const hasCurrencyConversionSettingsTabFeature = useUserHasFeature(
    Feature.CurrencyConversionSettingsTab
  );

  return (
    <>
      <div className={styles.bodySection}>
        <SellerAccountSettingPaymentMethods />
      </div>

      {hasDailyFxRateOverrideFeature &&
        !hasCurrencyConversionSettingsTabFeature && (
          <div className={styles.bodySection}>
            <SellerAccountSettingCurrencyConversionOverrides />
          </div>
        )}

      <div className={styles.bodySection}>
        <SellerAccountSettingVendors />
      </div>
    </>
  );
};
