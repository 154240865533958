import { ComponentProps } from 'react';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { Stack } from 'src/core/ui';
import { ModalBodyDataContainer } from 'src/modals/Modal/Modal.styled';
import {
  EventListingConstraints,
  TicketGroupInput,
} from 'src/WebApiController';

import { CostSection } from './components/CostSection';
import { DeliverySection } from './components/DeliverySection';
import { NewListingSection } from './components/NewListingSection';
import { PredeliverySection } from './components/PredeliverySection';
import { SeatingSection } from './components/SeatingSection';
import { UploadPredeliveryAction } from './purchaseTicketsFlow.utils';
import { PurchaseOrderInfoForTicketGroupInput } from './TicketGroupInput.types';

export enum CostType {
  CostPerTicket = 'CostPerTicket',
  TotalCost = 'TotalCost',
}

export function TicketGroupInputForm({
  hideHeader,
  ticketGroup,
  ...rest
}: {
  hideHeader: boolean;
} & ComponentProps<typeof TicketGroupInputBody>) {
  return (
    <ModalBodyDataContainer>
      <TicketGroupInputBody ticketGroup={ticketGroup} {...rest} />
    </ModalBodyDataContainer>
  );
}

function TicketGroupInputBody({
  ticketGroup,
  ticketGroupIndex,
  disabled,
  listingConstraints,
  showPredeliverySection,
  onHidePredeliverAction,
  purchaseOrderInfo,
}: {
  ticketGroup: TicketGroupInput;
  ticketGroupIndex: number;
  disabled?: boolean;
  listingConstraints?: EventListingConstraints | null;
  showPredeliverySection?: UploadPredeliveryAction;
  onHidePredeliverAction: () => void;
  purchaseOrderInfo?: PurchaseOrderInfoForTicketGroupInput | null;
}) {
  return (
    <EventMapContextProvider event={ticketGroup.event!} doNotGetScoreOverrides>
      {showPredeliverySection ? (
        <PredeliverySection
          action={showPredeliverySection}
          ticketGroupIndex={ticketGroupIndex}
          onCancel={onHidePredeliverAction}
        />
      ) : (
        <>
          <Stack direction="column" gap="xl">
            {ticketGroup.ticketGroupId < 0 && (
              <NewListingSection
                ticketGroupIndex={ticketGroupIndex}
                disabled={disabled}
              />
            )}
            <SeatingSection
              ticketGroupIndex={ticketGroupIndex}
              disabled={disabled}
              listingConstraints={listingConstraints}
              purchaseOrderInfo={purchaseOrderInfo}
            />
            <CostSection
              ticketGroupIndex={ticketGroupIndex}
              disabled={disabled}
            />
            <DeliverySection
              ticketGroupIndex={ticketGroupIndex}
              purchaseOrderInfo={purchaseOrderInfo}
              disabled={disabled}
            />
          </Stack>
        </>
      )}
    </EventMapContextProvider>
  );
}
