import {
  EntityWithRealTicketsPartial,
  MappedTicketInfo,
} from 'src/components/UploadArtifacts/UploadETicketsV2';
import { SeatInfo } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Seats/SeatInfo/SeatInfo';
import { vars } from 'src/core/themes';
import { PageIcon } from 'src/svgs/PageIcon';

interface MappedPageLabelProps {
  ticketInfo: MappedTicketInfo;
  entityWithTickets: EntityWithRealTicketsPartial;
}

export const MappedPageLabel = ({
  ticketInfo,
  entityWithTickets,
}: MappedPageLabelProps) => {
  return (
    <SeatInfo
      seatFr={ticketInfo.ticket.seat}
      ticketIndex={ticketInfo.ticketIndex}
      row={ticketInfo.ticket.row}
      section={entityWithTickets!.seating.section}
      size="big"
      icon={<PageIcon width="14" height="14" size={vars.iconSize.m} />}
    />
  );
};
