import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { ListingDetails } from 'src/WebApiController';

import { ViewSeatsListBody } from './ViewSeatsListBody';

export const ViewSeatsListModal = ({
  cancelTo,
  seeAvailableSeats,
}: {
  cancelTo?: ModalProps;
  seeAvailableSeats?: boolean;
}) => {
  const { posEntity: listing } = useActivePosEntityContext<ListingDetails>();

  return (
    <>
      <CancellableFormHeader cancelTo={cancelTo}>
        <ConnectedEventEntityHeader
          title={
            <Content
              id={
                seeAvailableSeats
                  ? ContentId.AvailableSeats
                  : ContentId.SoldSeats
              }
            />
          }
        />
      </CancellableFormHeader>

      <ModalBody>
        <ViewSeatsListBody
          listing={listing}
          seeAvailableSeats={seeAvailableSeats}
        />
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter cancelTo={cancelTo}></CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
