import { forwardRef } from 'react';
import { Components } from 'react-virtuoso';
import { scrollerWrapper } from 'src/tables/Table/GroupedVirtuosoTable/PosGroupedVirtuosoTable.css';

export const PosGroupedVirtuosoScroller: Components['Scroller'] = forwardRef(
  function GroupedVirtuosoScroller({ context, children, ...props }, ref) {
    return (
      <div className={scrollerWrapper}>
        <div {...props} ref={ref}>
          {children}
        </div>
      </div>
    );
  }
);
