import { isEqual } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Modal as RSModal } from 'reactstrap';
import { IconButton } from 'src/components/Buttons';
import {
  BulkEditNumber,
  LanguagePreference,
} from 'src/components/common/BulkActions/BulkEditNumber';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Button, SimpleTable } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { EventScoreUpdate } from 'src/modals/EventPricingSeatMap/EventPricingSeatMapBody';
import { CopyIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { getMedianScoreForSection } from 'src/utils/seatScoreUtils';
import { filterSectionByRowType } from 'src/utils/venueConfigUtils';
import { SectionInfo, SectionScoreOverride } from 'src/WebApiController';

import { ChangeRowScoreDialogMultiSection } from '../ChangeRowScoreDialog';
import { rowScoreOverrideFilter } from '../ChangeRowScoreDialog/ChangeRowScoreBodyMultiSection';
import * as styles from './ChangeMultiSectionsScoresDialog.css';

type SectionScoreInput = {
  sectionId: number;
  score: number | null;
};

export type ChangeMultiSectionsScoresDialogProps = ComponentProps<
  typeof RSModal
> & {
  selectedSections: SectionInfo[];
  scoreOverrides?: SectionScoreOverride[] | null;
  configPayload: string | null;
  onOkay: (newScores: SectionScoreInput[]) => void;
  onCancel: () => void;
  onSubmitSectionScoreChange: (updates: EventScoreUpdate) => Promise<void>;
};

export function ChangeMultiSectionsScoresDialog({
  selectedSections,
  scoreOverrides,
  configPayload,
  onOkay,
  onCancel,
  onSubmitSectionScoreChange,
  ...rest
}: ChangeMultiSectionsScoresDialogProps) {
  const existingScores = useMemo(
    (): SectionScoreInput[] =>
      selectedSections.map((s) => ({
        sectionId: s.id,
        score: getMedianScoreForSection(s.id, scoreOverrides ?? []),
      })),
    [scoreOverrides, selectedSections]
  );

  const changeRowScoreDialog = useBasicDialog();

  const [scores, setScores] = useState<SectionScoreInput[]>(() => [
    ...existingScores,
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const selectedSectionsMap = useMemo(() => {
    return selectedSections.reduce(
      (r, c) => {
        r[c.id] = c;
        return r;
      },
      {} as Record<number, SectionInfo>
    );
  }, [selectedSections]);

  useEffect(() => {
    if (scores.length !== existingScores.length) {
      setScores([...existingScores]);
    }
  }, [existingScores, scores.length]);

  const onRowSeatScoreChanged = useCallback(
    (
      newScoreOverrides: SectionScoreOverride[],
      newConfigPayload: string | null
    ) => {
      onSubmitSectionScoreChange({
        newScoreOverrides,
        newConfigPayload: newConfigPayload ?? undefined,
      }).then(() => {
        changeRowScoreDialog.closeDialog();
      });
    },
    [onSubmitSectionScoreChange, changeRowScoreDialog]
  );

  // Minimum number of configurable rows across all selected sections
  const minNumRowsSelectedSections = useMemo(() => {
    const sanitizedSections = selectedSections.filter(filterSectionByRowType);
    let min = Number.MAX_SAFE_INTEGER;
    for (const selectedSection of sanitizedSections) {
      const scoredRows =
        scoreOverrides?.filter(
          rowScoreOverrideFilter([selectedSection?.specRow?.id ?? 0])
        ) ?? [];
      min = Math.min(min, scoredRows.length);
    }
    return min;
  }, [scoreOverrides, selectedSections]);

  const onChangeSingleSectionScore = useCallback(
    (section: SectionScoreInput) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(event.target.value);
        if (value < 0 || value > 100) return;

        const index = scores.findIndex(
          (xs) => xs.sectionId === section.sectionId
        );
        scores[index] = { ...scores[index], score: value };
        setScores([...scores]);
      },
    [scores]
  );

  const onBulkChangeSectionScore = useCallback(
    (score: number) => setScores((prev) => prev.map((s) => ({ ...s, score }))),
    []
  );

  const onRelativeChangeAllScore = useCallback(
    (value: number, isRelative: boolean, isAbove: boolean) => {
      const percentageChange = 100 + (isAbove ? value : -value);
      const absoluteChange = isAbove ? value : -value;
      setScores((prev) =>
        prev.map((s) => {
          const validScore = s.score ?? 0;
          const newScore = isRelative
            ? (validScore * percentageChange) / 100
            : validScore + absoluteChange;
          const sanitizedScore = Math.min(100, Math.max(0, newScore));
          return { ...s, score: sanitizedScore };
        })
      );
    },
    []
  );

  if (!selectedSections.length) {
    return null;
  }

  return (
    <>
      <GenericDialog
        {...rest}
        size="m"
        header={<Content id={ContentId.ChangeSectionScore} />}
        onOpened={() => {
          setIsLoading(false);
          setScores([]);
        }}
        footer={
          <>
            <Button
              className={styles.cancelButton}
              onClick={onCancel}
              disabled={isLoading}
            >
              <Content id={ContentId.Cancel} />
            </Button>
            {minNumRowsSelectedSections > 0 && (
              <Button
                variant="outline"
                onClick={() => {
                  // We have to pick the score that was input
                  if (scores.length) {
                    setIsLoading(true);
                    onOkay(scores);
                    changeRowScoreDialog.launchDialog();
                  }
                }}
                disabled={isLoading || !scores.length}
              >
                <Content id={ContentId.EditRowScores} />
              </Button>
            )}
            <Button
              disabled={isLoading || isEqual(scores, existingScores)}
              onClick={() => {
                if (scores.length) setIsLoading(true);
                onOkay(scores);
              }}
            >
              <Content id={ContentId.Save} />
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        <BulkEditNumber
          isLoading={isLoading}
          showAbsolute={true}
          showApplyToAll={true}
          onChange={onRelativeChangeAllScore}
          absoluteUnit="123"
          languagePreference={LanguagePreference.Increase_Decrease}
        />
        <SimpleTable.Table style={{ width: '100%' }}>
          <SimpleTable.Thead>
            <SimpleTable.Tr>
              <SimpleTable.Th>
                <Content id={ContentId.Section} />
              </SimpleTable.Th>
              <SimpleTable.Th>
                <Content id={ContentId.Score} />
              </SimpleTable.Th>
            </SimpleTable.Tr>
          </SimpleTable.Thead>
          <SimpleTable.Tbody>
            {scores
              .filter((s) => selectedSectionsMap[s.sectionId])
              .sort((s1, s2) =>
                selectedSectionsMap[s1.sectionId].name.localeCompare(
                  selectedSectionsMap[s2.sectionId].name
                )
              )
              .map((s) => (
                <SimpleTable.Tr key={`${s.sectionId}`} title={`${s.sectionId}`}>
                  <SimpleTable.Td>
                    {selectedSectionsMap[s.sectionId].name}
                  </SimpleTable.Td>
                  <SimpleTable.Td>
                    <PosTextField
                      disabled={isLoading}
                      value={
                        s.score != null ? roundToPrecision(s.score, 4) : ''
                      }
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      min={0}
                      max={100}
                      type="number"
                      inputMode="numeric"
                      onChange={onChangeSingleSectionScore(s)}
                      postfixDisplay={
                        <IconButton
                          icon={
                            <CopyIcon withHoverEffect size={vars.iconSize.m} />
                          }
                          titleContentId={ContentId.ApplyToAll}
                          onClick={onBulkChangeSectionScore.bind(
                            null,
                            s.score ?? 0
                          )}
                        />
                      }
                    />
                  </SimpleTable.Td>
                </SimpleTable.Tr>
              ))}
          </SimpleTable.Tbody>
        </SimpleTable.Table>
      </GenericDialog>
      <ChangeRowScoreDialogMultiSection
        {...changeRowScoreDialog.dialogProps}
        selectedSections={selectedSections.filter(filterSectionByRowType)}
        configPayload={configPayload}
        scoreOverrides={scoreOverrides}
        onOkay={onRowSeatScoreChanged}
        onCancel={changeRowScoreDialog.closeDialog}
      />
    </>
  );
}
