import { ReportGroupBy } from 'src/WebApiController';

import { SalesReportTableColumnId } from './salesColumnUtils.types';
import {
  GROUP_BY_TO_PRIMARY_COLUMN_ID,
  GROUP_BY_TO_VISIBLE_COLUMN_IDS,
  NON_METRIC_SALES_REPORT_TABLE_COLUMN_IDS,
} from './salesReportColumnUtils.constants';

export const isMetricConfigurableByGroupBy = (
  id: string,
  groupBy?: ReportGroupBy
) => {
  if (!groupBy) {
    return true;
  }

  if (GROUP_BY_TO_PRIMARY_COLUMN_ID[groupBy] === id) return true;

  if (
    !NON_METRIC_SALES_REPORT_TABLE_COLUMN_IDS.includes(
      id as SalesReportTableColumnId
    )
  )
    return true;

  if (
    GROUP_BY_TO_VISIBLE_COLUMN_IDS[groupBy]?.includes(
      id as SalesReportTableColumnId
    )
  ) {
    return true;
  }
  return false;
};
