import { MarketplacePaymentsTab } from 'src/components/Payments';
import { Content, useContent } from 'src/contexts/ContentContext';
import * as Tabs from 'src/core/ui/Tabs';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { PaymentTab } from 'src/navigations/Routes/Payments/Payments.constants';
import { ContentId } from 'src/utils/constants/contentId';

import { MainRoute } from '../MainRoute';
import * as styles from './Payments.css';

export function Payments() {
  const paymentsTitle = useContent(ContentId.Payments);

  return (
    <LayoutContent mainRoute={MainRoute.Payments} routeTitle={paymentsTitle}>
      <Tabs.Root
        defaultValue={PaymentTab.MarketplacePayments}
        className={styles.paymentsPageTabsRoot}
      >
        <Tabs.List className={styles.tabsWrapper}>
          <Tabs.Trigger value={PaymentTab.MarketplacePayments}>
            <Content id={ContentId.MarketplacePayments} />
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content
          value={PaymentTab.MarketplacePayments}
          className={styles.tabWrapper}
        >
          <MarketplacePaymentsTab />
        </Tabs.Content>
      </Tabs.Root>
    </LayoutContent>
  );
}
