import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import { useAlertWithSuppressionDialog } from './useAlertWithSuppressionDialog';

export function useDeliveryInfoChangeDialog(
  onOkay: () => void,
  onCancel: () => void
) {
  const { value, ...rest } = useAlertWithSuppressionDialog(
    'deliveryInfoChangeSuppressionTimestamp',
    onOkay,
    onCancel
  );
  return {
    ...rest,
    lastTimeStamp: value?.deliveryInfoChangeSuppressionTimestamp,
    bodyText: (
      <Content id={ContentId.DeliveryInfoChangePropagateToSalesConfirmation} />
    ),
    cancelText: <Content id={ContentId.No} />,
  };
}
