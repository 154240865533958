/* eslint-disable react-hooks/rules-of-hooks */
import { differenceInMinutes, format } from 'date-fns';
import { ButtonWithIcon } from 'src/components/Buttons';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { ModalProps } from 'src/modals/Modal';
import { SaleDetailsModalConfig } from 'src/modals/SaleDetails';
import { LaunchConfirmSale } from 'src/modals/SaleDetails/actions/LaunchConfirmSale';
import { LaunchDeallocateSale } from 'src/modals/SaleDetails/actions/LaunchDeallocateSale';
import { LaunchDownloadUserDocuments } from 'src/modals/SaleDetails/actions/LaunchDownloadUserDocuments';
import { LaunchEditOfflineSale } from 'src/modals/SaleDetails/actions/LaunchEditOfflineSale';
import { LaunchExpireHold } from 'src/modals/SaleDetails/actions/LaunchExpireHold';
import { LaunchMarkAsFulfilled } from 'src/modals/SaleDetails/actions/LaunchMarkAsFulfilled';
import { LaunchRejectSale } from 'src/modals/SaleDetails/actions/LaunchRejectSale';
import { LaunchResetFulfillmentButton } from 'src/modals/SaleDetails/actions/LaunchResetFulfillmentButton';
import { LaunchTransferTickets } from 'src/modals/SaleDetails/actions/LaunchTransferTickets';
import { LaunchUploadBarcodes } from 'src/modals/UploadBarcodes/LaunchUploadBarcodes';
import { LaunchUploadETickets } from 'src/modals/UploadETickets/LaunchUploadETickets';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { CancelledHoldIcon } from 'src/svgs/CancelledHoldIcon';
import { FulfilledIcon } from 'src/svgs/FulfilledIcon';
import { FulfillmentFailedIcon } from 'src/svgs/FulfillmentFailedIcon';
import { HoldIcon } from 'src/svgs/HoldIcon';
import { PendingAllocationIcon } from 'src/svgs/PendingAllocationIcon';
import { PendingFulfillmentIcon } from 'src/svgs/PendingFulfillmentIcon';
import { PendingRejectionIcon } from 'src/svgs/PendingRejectionIcon';
import { ProcessingFulfillmentIcon } from 'src/svgs/ProcessingFulfillmentIcon';
import { SaleCancelledIcon } from 'src/svgs/SaleCancelledIcon';
import { IconsFill, WarningOutlineIcon } from 'src/svgs/Viagogo';
import {
  REACT_APP_STUBHUB_MY_HUB_BASE_URL,
  SaleDeeplinkQueryParam,
} from 'src/utils/constants/constants';
import {
  ActionOutboxEntityType,
  DocumentProcessorClient,
  DocumentUploadInfo,
  Event,
  FileParameter,
  Listing,
  Marketplace,
  MarketplaceContractState,
  MarketplaceSaleInput,
  MarketplaceSaleLineItem,
  PointOfSaleSaleStatus,
  PosClientConfig,
  PosManagedMarketplaceSaleInput,
  SaleActionType,
  SaleClient,
  SaleDetails,
  SaleInput,
  SiteMarketplacePaymentLineInput,
  Ticket,
  TicketBarcode,
  TicketType,
  UserDocument,
} from 'src/WebApiController';

import { ContentId } from './constants/contentId';
import { SALES_STATUS_TO_CID } from './constants/contentIdMaps';
import { FormatContentId } from './constants/formatContentId';
import { getFullUrl, getPathFromMainRoute } from './deepLinkUtils';
import { getInHandDateBasedOnEvent } from './eventWithDataUtils';
import { getLocaleFromLanguageOrCurrent } from './localeUtils';
import { getTimeDateDifferenceAsShorthand } from './numberFormatter';
import { posChangedField, posField } from './posFieldUtils';
import { getDefaultTicketType, getTicketTypeIcon } from './ticketTypeUtils';

export const NON_MAIN_SALE_ACTIONS = [
  SaleActionType.Deallocate,
  SaleActionType.MarkAsFulfilled,
  SaleActionType.ResetFulfillment,
];

export const getSaleDetailsModalConfigWithDeepLink = (
  deepLinkId?: string | number,
  doTrackView = false
) => {
  const modalConfig = SaleDetailsModalConfig;
  return {
    ...modalConfig,
    deepLinkValue: deepLinkId,
    trackViewEntityType: doTrackView ? ActionOutboxEntityType.Sale : undefined,
  };
};

export const getSaleDetailsDeepLinkUrl = (deepLinkId?: string | number) => {
  return getFullUrl(MainRoute.Sales, SaleDeeplinkQueryParam, deepLinkId);
};

export const getSaleDetailsRelativeUrl = (deepLinkId?: string | number) => {
  const navigateUrl = `${getPathFromMainRoute(
    MainRoute.Sales
  )}?${SaleDeeplinkQueryParam}=${deepLinkId}`;

  return navigateUrl;
};

export const openOnStubHubMyAccount = (marketplaceSaleId: string | null) => {
  if (!marketplaceSaleId) return;

  window.open(
    new URL(
      `/sales?saleId=${marketplaceSaleId}`,
      /* TODO - POS-777 - this should be the viagogo url if the event is a UK event */
      REACT_APP_STUBHUB_MY_HUB_BASE_URL
    ),
    '_blank'
  );
};

export const getSaleActionButton = (
  saleId: number,
  marketplaceSaleId: string | null,
  marketplace: Marketplace | null,
  saleStatus: PointOfSaleSaleStatus,
  action: SaleActionType,
  ticketType: TicketType | null,
  isNoFulfill?: boolean | null,
  variant?: ButtonProps['variant'],
  iconOnlyMode?: boolean,
  cancelTo?: ModalProps,
  disabled?: boolean
) => {
  if (action === SaleActionType.Reject) {
    return (
      <LaunchRejectSale
        saleId={saleId}
        marketplaceSaleId={marketplaceSaleId}
        iconOnlyMode={iconOnlyMode}
        variant={variant}
        disabled={disabled}
      />
    );
  }
  if (action === SaleActionType.DownloadUserDocuments) {
    return (
      <LaunchDownloadUserDocuments
        saleId={saleId}
        iconOnlyMode={iconOnlyMode}
        variant={variant}
        disabled={disabled}
      />
    );
  }

  if (
    action === SaleActionType.Deallocate ||
    action === SaleActionType.RejectAllocation
  ) {
    return (
      <LaunchDeallocateSale
        action={action}
        saleId={saleId}
        marketplaceSaleId={marketplaceSaleId}
        marketplace={marketplace}
        saleStatus={saleStatus}
        iconOnlyMode={iconOnlyMode}
        variant={variant}
        disabled={disabled}
      />
    );
  }

  if (action === SaleActionType.ResetFulfillment) {
    return (
      <LaunchResetFulfillmentButton
        saleId={saleId}
        marketplaceSaleId={marketplaceSaleId}
        variant={variant}
        iconOnlyMode={iconOnlyMode}
        disabled={disabled}
      />
    );
  }

  if (action === SaleActionType.MarkAsFulfilled) {
    return (
      <LaunchMarkAsFulfilled
        saleId={saleId}
        marketplaceSaleId={marketplaceSaleId}
        iconOnlyMode={iconOnlyMode}
        variant={variant}
        disabled={disabled}
      />
    );
  }

  if (
    action === SaleActionType.Confirm ||
    action === SaleActionType.Allocate ||
    action === SaleActionType.ConfirmAllocation
  ) {
    return (
      <LaunchConfirmSale
        action={action}
        saleId={saleId}
        marketplaceSaleId={marketplaceSaleId}
        saleStatus={saleStatus}
        iconOnlyMode={iconOnlyMode}
        variant={variant}
        cancelTo={cancelTo}
        disabled={disabled}
      />
    );
  }
  if (action === SaleActionType.ExpireHold) {
    return (
      <LaunchExpireHold
        saleId={saleId}
        marketplaceSaleId={marketplaceSaleId}
        iconOnlyMode={iconOnlyMode}
        variant={variant}
        disabled={disabled}
      />
    );
  }

  if (
    action === SaleActionType.Fulfill ||
    action === SaleActionType.Refulfill
  ) {
    switch (ticketType) {
      case TicketType.Barcode:
        return (
          <LaunchUploadBarcodes
            disabled={disabled}
            onUploadBarcodes={(
              clientConfig: PosClientConfig,
              saleId: number,
              barcodes: TicketBarcode[]
            ) =>
              new SaleClient(clientConfig).updateBarcodesForSale(
                saleId,
                barcodes
              )
            }
            entityId={saleId}
            idOnMkp={marketplaceSaleId}
            iconOnlyMode={iconOnlyMode}
            cancelTo={cancelTo}
            variant={variant}
            action={action}
            loadingContentId={FormatContentId.LoadingSaleId}
            errorContentId={FormatContentId.CouldNotFindSaleId}
            searchingContentId={FormatContentId.SearchingForSaleId}
            isSaleNoFulfill={isNoFulfill}
          />
        );
      case TicketType.ETicket:
      case TicketType.QRCode:
        return (
          <LaunchUploadETickets
            disabled={disabled}
            entityId={saleId}
            marketplaceSaleId={marketplaceSaleId}
            iconOnlyMode={iconOnlyMode}
            cancelTo={cancelTo}
            variant={variant}
            ticketType={ticketType}
            action={action}
            onUploadETickets={(
              clientConfig: PosClientConfig,
              entityId: number,
              etickets: UserDocument[]
            ) =>
              ticketType === TicketType.ETicket
                ? new SaleClient(clientConfig).saveETicketAssignments(
                    entityId,
                    etickets
                  )
                : new SaleClient(clientConfig).saveQRCodeAssignments(
                    entityId,
                    etickets
                  )
            }
            onUploadDocuments={(
              client: DocumentProcessorClient,
              docUploadInfo: DocumentUploadInfo,
              file: FileParameter
            ) =>
              client.uploadDocumentForSale(
                docUploadInfo.entityId,
                ActionOutboxEntityType.Sale,
                docUploadInfo.documentType,
                docUploadInfo.blobName,
                docUploadInfo.documentId,
                docUploadInfo.contentType,
                file
              )
            }
            loadingContentId={FormatContentId.LoadingSaleId}
            errorContentId={FormatContentId.CouldNotFindSaleId}
            searchingContentId={FormatContentId.SearchingForSaleId}
            isSaleNoFulfill={isNoFulfill}
          />
        );
      case TicketType.Transfer:
      case TicketType.ETicketUrl:
      case TicketType.SMPWallet:
        return (
          <LaunchTransferTickets
            disabled={disabled}
            saleId={saleId}
            marketplaceSaleId={marketplaceSaleId}
            iconOnlyMode={iconOnlyMode}
            cancelTo={cancelTo}
            variant={variant}
            eTicketUrlOnly={
              ticketType === TicketType.ETicketUrl ||
              ticketType === TicketType.SMPWallet
            }
            isSaleNoFulfill={isNoFulfill}
          />
        );
      default:
        return (
          <ButtonWithIcon
            disabled={disabled}
            icon={getTicketTypeIcon(
              ticketType,
              iconOnlyMode || (variant && variant !== 'regular')
                ? IconsFill.textBrand
                : IconsFill.textInverted
            )}
            textContentId={
              ContentId.FulfillOnStubHub
            } /* TODO - POS-777 - this should be fulfill on Viagogo if the event is a UK event */
            titleContentId={
              iconOnlyMode
                ? ContentId.FulfillOnStubHub
                : ContentId.FulfillActionNotAvailable
            }
            iconOnlyMode={iconOnlyMode}
            variant={variant}
            onClick={(e) => {
              e.stopPropagation();
              openOnStubHubMyAccount(marketplaceSaleId);
            }}
          />
        );
    }
  }

  if (action === SaleActionType.EditOffline) {
    return (
      <LaunchEditOfflineSale
        saleId={saleId}
        cancelTo={cancelTo}
        marketplaceSaleId={marketplaceSaleId}
        iconOnlyMode={iconOnlyMode}
        variant={variant}
        disabled={disabled}
        eventId={null}
      />
    );
  }
};

export function getInHandDisplayData(
  inhandTextString: string,
  utcDate?: Date | null,
  fromUtcDate: Date = new Date(),
  needsToFulfill = false
): {
  displayText: string | null;
  displayTooltip: string | null;
  alertIcon: React.ReactNode | null;
} {
  const minutes = !utcDate ? -1 : differenceInMinutes(utcDate, fromUtcDate);
  const shorthandDifference = getTimeDateDifferenceAsShorthand({
    inputDate: utcDate,
    dateToCompareTo: fromUtcDate,
    hideRelativeTerms: true,
  });
  let displayText = null;
  let alertIcon: React.ReactNode | null = (
    <WarningOutlineIcon fill={IconsFill.textWarning} size={vars.iconSize.s} />
  );

  if (minutes <= 0) {
    displayText = inhandTextString;

    if (needsToFulfill) {
      alertIcon = (
        <WarningOutlineIcon
          fill={IconsFill.textNegative}
          size={vars.iconSize.s}
        />
      );
    }
  } else {
    displayText = shorthandDifference;
    const hours = minutes / 60;
    // Do not alert when in-hand date is still < 48 hours in the future
    if (hours > 48) alertIcon = null;
  }

  const displayTooltip = utcDate
    ? format(utcDate, 'MMM d, yyyy hh:mm', {
        locale: getLocaleFromLanguageOrCurrent(),
      })
    : null;

  return {
    displayText,
    displayTooltip,
    alertIcon,
  };
}

export const getMarketplaceSaleInputFromListing = (
  listing: Listing,
  event: Event
) => {
  const curMillis = new Date().getTime();

  return {
    marketplaceSaleId: `Sale-${curMillis}`, // the chance of 2 people creating the same Offline sale in the same milli is rare - this makes a short enough ID
    listingId: listing.id,
    section: posChangedField(listing.seating.section),
    row: posChangedField(listing.seating.row),
    seatFrom: posChangedField(''),
    seatTo: posChangedField(''),
    posEventId: listing.posEvId,
    viagogoEventId: event.viagId,
    eventMappingId: event.mappingId,
    saleDate: posChangedField(new Date().toISOString()),
    currencyCode: posChangedField(listing.currency),
    totalNetProceeds: posChangedField(0),
    inHandDate: posChangedField(
      listing.inHandAt ?? getInHandDateBasedOnEvent(event)
    ),
    venueId: event.venueId,
    quantitySold: posChangedField(listing.availQty),
    ticketType: posChangedField(
      listing.topPriTktType ?? getDefaultTicketType(listing.delivType)
    ),
    buyerName: posChangedField(null),
    buyerEmail: posChangedField(null),
    buyerPhone: posChangedField(null),
    listingNotes: posChangedField(listing.seatTraits),
    saleStatus: posChangedField(PointOfSaleSaleStatus.PendingFulfillment), // We want to create this already-allocated
  } as MarketplaceSaleInput;
};

export const getPosManagedMarketplaceSaleInputFromListing = (
  listing: Listing,
  event: Event
) => {
  const curMillis = new Date().getTime();

  return {
    marketplaceSaleId: `Offline-${curMillis}`, // the chance of 2 people creating the same Offline sale in the same milli is rare - this makes a short enough ID
    listingId: listing.id,
    section: posChangedField(listing.seating.section),
    row: posChangedField(listing.seating.row),
    seatFrom: posChangedField(''),
    seatTo: posChangedField(''),
    posEventId: listing.posEvId,
    viagogoEventId: event.viagId,
    eventMappingId: event.mappingId,
    saleDate: posChangedField(new Date().toISOString()),
    currencyCode: posChangedField(listing.currency),
    totalNetProceeds: posChangedField(0),
    inHandDate: posChangedField(
      listing.inHandAt ?? getInHandDateBasedOnEvent(event)
    ),
    venueId: event.venueId,
    quantitySold: posChangedField(listing.availQty),
    ticketType: posChangedField(
      listing.topPriTktType ?? getDefaultTicketType(listing.delivType)
    ),
    buyerName: posChangedField(null),
    buyerEmail: posChangedField(null),
    buyerPhone: posChangedField(null),
    listingNotes: posChangedField(listing.seatTraits),
    saleStatus: posChangedField(PointOfSaleSaleStatus.Hold),
  } as PosManagedMarketplaceSaleInput;
};

export const getPosManagedMarketplaceSaleInputFromSale = (
  sale: SaleDetails,
  event: Event
) => {
  return {
    saleId: sale.id,
    marketplaceSaleId: sale.idOnMkp,
    listingId: sale.listingId ?? 0,
    section: posField(sale.seating.section),
    row: posField(sale.seating.row),
    seatFrom: posField(sale.seating.seatFr),
    seatTo: posField(sale.seating.seatTo),
    posEventId: sale.posEvId,
    viagogoEventId: event.viagId,
    eventMappingId: event.mappingId,
    saleDate: posField(new Date().toISOString()),
    currencyCode: posField(sale.currency),
    totalNetProceeds: posField(sale.ttlNetProcs?.amt ?? 0),
    inHandDate: posField(sale.inHandAt),
    venueId: event.venueId,
    quantitySold: posField(sale.qtySold),
    ticketType: posField(sale.ticketType),
    buyerName: posField(sale.transDest?.name),
    buyerEmail: posField(sale.transDest?.email),
    buyerPhone: posField(sale.transDest?.phoneNumber),
    listingNotes: posField(sale.seatTraits),
    saleStatus: posField(sale.status),
    paymentState: posField(sale.pmtState),
    internalNotes: posField(sale.privNotes),
    fulfillerSellerUserId: posField(sale.fulfillerId),
  } as PosManagedMarketplaceSaleInput;
};

export const getConsecutiveSeatingsForQuantity = (
  tickets?: Ticket[],
  quantity?: number | null,
  canAllocateToNonConsecutiveSeats?: boolean
) => {
  if (!tickets || !quantity) return undefined;

  const filteredTickets = tickets
    .filter((t) => t.saleId == null && t.holdId == null) // we only want the unsold/unhold tickets
    .sort((a, b) => (a.lstOrd ?? a.ord) - (b.lstOrd ?? b.ord));

  const result: Record<number, number> = {};

  if (quantity < 2) {
    // Quantity 1 or less, we want to set seatFr and seatTo to same number
    return {
      result: filteredTickets.reduce(
        (r, c) => {
          r[c.lstOrd ?? c.ord] = c.lstOrd ?? c.ord;
          return r;
        },
        {} as Record<number, number>
      ),
      maxAvailableQuantity: 1,
    };
  }

  let maxAvailableQuantity = 1;

  if (canAllocateToNonConsecutiveSeats) {
    // We treat the tickets as a consecutive sequence
    maxAvailableQuantity = filteredTickets.length;
    for (let i = 0; i < filteredTickets.length; i++) {
      if (i + quantity - 1 < filteredTickets.length) {
        const ord = filteredTickets[i].lstOrd ?? filteredTickets[i].ord;
        const nextOrd =
          filteredTickets[i + quantity - 1].lstOrd ??
          filteredTickets[i + quantity - 1].ord;
        result[ord] = nextOrd;
      }
    }
  } else {
    // We want to generate a seating for every seatFr
    for (let i = 0; i < filteredTickets.length; i++) {
      let isValidSequence = true;
      let sequenceLength = 1;
      for (
        let j = i;
        j < i + quantity - 1 && j < filteredTickets.length - 1;
        j++
      ) {
        const currentSeat = filteredTickets[j];
        const nextSeat = filteredTickets[j + 1];
        if (currentSeat.seat && nextSeat.seat) {
          const curOrdinal = currentSeat.lstOrd ?? currentSeat.ord;
          const nexOrdinal = nextSeat.lstOrd;
          if (curOrdinal + 1 !== nexOrdinal) {
            isValidSequence = false;
            break;
          } else {
            sequenceLength++;
          }
        } else {
          sequenceLength++;
        }
      }

      if (sequenceLength > maxAvailableQuantity) {
        // Update the max possible quantity as we go along
        maxAvailableQuantity = sequenceLength;
      }

      // If we found a valid sequence for the requested quantity - save it to result
      if (isValidSequence && sequenceLength === quantity) {
        const ord = filteredTickets[i].lstOrd ?? filteredTickets[i].ord;
        const nextOrd =
          filteredTickets[i + quantity - 1].lstOrd ??
          filteredTickets[i + quantity - 1].ord;
        result[ord] = nextOrd;
      }
    }
  }

  return { result: result, maxAvailableQuantity: maxAvailableQuantity };
};

export const getSaleStatusDisplayContentId = (
  status?: PointOfSaleSaleStatus | null,
  marketplaceContractState?: MarketplaceContractState | null,
  marketplace?: Marketplace | null
) => {
  if (
    marketplaceContractState ===
      MarketplaceContractState.CancelledProvisional &&
    marketplace !== Marketplace.Offline
  ) {
    return ContentId.CancelledHoldOnMarketplace;
  }

  return status ? SALES_STATUS_TO_CID[status] : undefined;
};

export const getSaleStatusIcon = (
  status?: PointOfSaleSaleStatus | null,
  marketplaceContractState?: MarketplaceContractState | null,
  marketplace?: Marketplace | null
) => {
  if (
    marketplaceContractState ===
      MarketplaceContractState.CancelledProvisional &&
    marketplace !== Marketplace.Offline
  ) {
    return CancelledHoldIcon;
  }

  switch (status) {
    case PointOfSaleSaleStatus.CancelledHold:
      return CancelledHoldIcon;

    case PointOfSaleSaleStatus.Fulfilled:
      return FulfilledIcon;

    case PointOfSaleSaleStatus.FulfillmentFailed:
      return FulfillmentFailedIcon;

    case PointOfSaleSaleStatus.Hold:
      return HoldIcon;

    case PointOfSaleSaleStatus.PendingConfirmation:
      return PendingAllocationIcon;

    case PointOfSaleSaleStatus.ProcessingFulfillment:
      return ProcessingFulfillmentIcon;

    case PointOfSaleSaleStatus.PendingRejection:
      return PendingRejectionIcon;

    case PointOfSaleSaleStatus.Rejected:
      return SaleCancelledIcon;
    case PointOfSaleSaleStatus.PendingFulfillment:
    default:
      return PendingFulfillmentIcon;
  }
};

export const getSiteMarketplacePaymentLineInput = (
  mkpLine: MarketplaceSaleLineItem
): SiteMarketplacePaymentLineInput => {
  return {
    marketplacePaymentLineId: mkpLine.id,
    marketplacePaymentId: mkpLine.posPmtId,
    externalPaymentId: mkpLine.extPmtId,
    externalPaymentLineId: mkpLine.extLineId,
    externalSaleId: mkpLine.extSaleId,
    saleId: mkpLine.saleId,
    currency: mkpLine.amt.currency!,
    amount: mkpLine.amt.amt,
    isCredit: mkpLine.isCred,
    paymentLineType: mkpLine.type,
    paymentDate: mkpLine.date,
    receivedDate: mkpLine.recvDate,
  };
};

export const getSaleDetailsUpdateInput = (
  sale?: SaleDetails | null
): SaleInput => {
  return {
    saleId: sale?.id ?? -1,
    ticketType: posField(sale?.ticketType ?? null),
    fulfillerSellerUserId: posField(sale?.fulfillerId ?? null),
    tags: posField(sale?.tags ?? null),
    internalNotes: posField(sale?.privNotes ?? null),
    lineItems: posField(sale?.lineItems ?? null),
    paymentReceived: posField(sale?.pmtRcv ?? null),
    paymentReferenceId: posField(sale?.pmtRefId ?? null),
    paymentAmountReceived: posField(sale?.pmtAmtRcv ?? null),
    paymentCurrency: posField(sale?.pmtCurcy ?? null),
    disableAutoSourcing: posField(sale?.disAutoSource ?? null),
    isNoFulfill: posField(sale?.isNoFulfill ?? null),
    marketplacePaymentLines: posField(
      sale?.mkpLines?.map(getSiteMarketplacePaymentLineInput) ?? null
    ),
  };
};
