import { ComponentProps, useCallback, useMemo } from 'react';
import { useListingNotesSelector } from 'src/components/Selectors/ListingNotesSelector/useListingNotesSelector';
import { MultiSelectDropBoxWithPills } from 'src/core/POS/MultiSelectDropBoxWithPills';
import { newIntId } from 'src/utils/idUtils';
import { ListingNote, ListingNoteType } from 'src/WebApiController';

export type ListingNoteSelectionInputProps = {
  listingNotes: ListingNote[] | null;
  validListingNoteIds?: number[] | null;
  onChange: (listingNotes: ListingNote[]) => void;
} & Omit<
  ComponentProps<typeof MultiSelectDropBoxWithPills>,
  'values' | 'onChange' | 'valueOptionsContent'
>;

export const ListingNoteSelectionInput = ({
  disabled,
  listingNotes,
  validListingNoteIds,
  placeholderText,
  onChange,
  showPills = true,
  ...multiSelectorProps
}: ListingNoteSelectionInputProps) => {
  const { query, multiSelectorProps: hookMultiSelectorProps } =
    useListingNotesSelector({
      disabled,
      validListingNoteIds,
      placeholderText,
    });

  const listingNotesMap = useMemo(
    () =>
      query.data
        ?.filter((l) => (l.listingNoteText || l.listingNoteUntranslated).trim())
        .reduce(
          (r, l) => {
            r[l.listingNoteId.toString()] = l;
            return r;
          },
          {} as Record<string, ListingNote>
        ),
    [query.data]
  );

  const onSelectionChanged = useCallback(
    (lnIds: string[], searchText?: string | null) => {
      if (!listingNotesMap) {
        return;
      }

      const newListingNotes = lnIds.map((lnId) => {
        const listingNoteId = parseInt(lnId);
        return listingNotesMap[listingNoteId];
      });

      if (searchText) {
        newListingNotes.push({
          listingNoteId: newIntId(),
          listingNoteText: searchText,
          listingNoteUntranslated: searchText,
          sellerAccountId: null, // this gets filled in the backend
          stubhubListingNoteId: null,
          type: ListingNoteType.Information,
        });
      }

      onChange(newListingNotes);
    },
    [listingNotesMap, onChange]
  );

  return (
    <MultiSelectDropBoxWithPills
      {...multiSelectorProps}
      {...hookMultiSelectorProps}
      avoidCollisions={false}
      side="bottom"
      disabled={disabled}
      showPills={showPills}
      values={(listingNotes ?? [])
        .filter((l) => l != null)
        .map((l) => {
          const listingNote = listingNotesMap?.[l.listingNoteId];
          if (listingNote) {
            return l.listingNoteId.toString();
          }

          return l.listingNoteText || l.listingNoteUntranslated;
        })}
      onChange={onSelectionChanged}
    />
  );
};
