import { useMeasure } from 'react-use';
import { Content } from 'src/contexts/ContentContext';
import { Switch } from 'src/core/interim/Switch';
import { PosFormField } from 'src/core/POS/PosFormField';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import { EventGroupings } from './components/EventGroupings';
import { GroupListingsBodyProps } from './components/groupingTypes';
import { getListingsForMergeListingGroupInput } from './components/groupingUtils';
import { ListingGroupInput } from './components/ListingGroupInput';
import { useFullySoldFilter } from './GroupListingsBody.hook';

export const GroupListingsBody = ({
  events,
  listingGroup,
  onEventSelected,
}: GroupListingsBodyProps) => {
  const {
    excludeFullySold,
    showExcludeFullySold,
    sanitizedEvents,
    mergeListingGroupInputs,
    onToggleExcludeFullySold,
  } = useFullySoldFilter(events, listingGroup);

  const [containerRef, { height: containerHeight }] =
    useMeasure<HTMLDivElement>();

  return (
    <Stack direction="column" gap="xl" height="full">
      {showExcludeFullySold && (
        <PosFormField
          label={<Content id={ContentId.ExcludeFullySoldListings} />}
        >
          <Switch
            checked={excludeFullySold}
            onChange={onToggleExcludeFullySold}
          />
        </PosFormField>
      )}
      <Stack
        ref={containerRef}
        direction="column"
        style={{ height: listingGroup ? '100%' : '90%' }}
      >
        {listingGroup ? (
          <ListingGroupInput
            key={`mergeListingGroupInput-${0}`}
            mergeListingGroupInputIndex={0}
            listings={getListingsForMergeListingGroupInput(
              mergeListingGroupInputs[0],
              sanitizedEvents
            )}
          />
        ) : (
          <EventGroupings
            events={sanitizedEvents}
            onEventSelected={onEventSelected}
            containerHeight={containerHeight}
          />
        )}
      </Stack>
    </Stack>
  );
};
