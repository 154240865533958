import { PosEnumSelect, PosEnumSelectProps } from 'src/core/POS/PosSelect';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { DELIVERY_OPTIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { DeliveryType, Feature, Permission } from 'src/WebApiController';

import { getDeliveryTypeIcons } from './DeliveryTypeSelector.utils';

export type DeliveryTypeSelectorProps = Omit<
  PosEnumSelectProps<DeliveryType>,
  'valueOptionsContent' | 'valueOptionsIcon'
> & {
  valueOptionsContent?: Record<DeliveryType, string>;
  // Use this when the delivery type is used for read only purposes (filter, etc)
  allowCustomDeliveryType?: boolean;
};

export const DeliveryTypeSelector = ({
  allowCustomDeliveryType,
  placeholderText = ContentId.AllDeliveryOptions,
  valueOptionsContent,
  ...rest
}: DeliveryTypeSelectorProps) => {
  const hasCustomDeliveryTypeFeature = useUserHasFeature(
    Feature.CustomDeliveryType
  );

  const canSetCustomDeliveryType = useUserHasAnyOfPermissions(
    Permission.Purchases_UpdateCustomDeliveryType,
    Permission.Purchases_UpdateCustomDeliveryTypeOwned
  );

  const moderatedOptionsContent = Object.entries(
    valueOptionsContent ?? DELIVERY_OPTIONS_TO_CID
  ).reduce(
    (acc, [key, val]) => {
      if (
        key === DeliveryType.Custom &&
        ((!allowCustomDeliveryType && !canSetCustomDeliveryType) ||
          !hasCustomDeliveryTypeFeature)
      ) {
        return acc;
      }

      if (key === DeliveryType.Wallet) {
        return acc;
      }

      return { ...acc, [key]: val };
    },
    {} as Record<DeliveryType, string>
  );

  return (
    <PosEnumSelect
      style={{ width: '100%' }}
      {...rest}
      placeholderText={placeholderText}
      valueOptionsContent={moderatedOptionsContent}
      valueOptionsIcon={getDeliveryTypeIcons()}
    />
  );
};
