import clsx from 'clsx';
import { ReactNode } from 'react';
import { vars } from 'src/core/themes';
import { HamburgerMenuIcon } from 'src/svgs/HamburgerMenuIcon';

import * as styles from './ColumnListItem.css';

type ColumnListItemProps = {
  children: ReactNode;
  actionButton: ReactNode;
  draggable?: boolean;
  highlighted?: boolean;
};

export const ColumnListItem = ({
  children,
  actionButton,
  draggable,
  highlighted,
}: ColumnListItemProps) => {
  return (
    <div
      className={clsx(styles.root, {
        [styles.pulsedHighlight]: highlighted,
      })}
    >
      <div className={styles.itemContainer}>
        {draggable && (
          <HamburgerMenuIcon
            className={styles.icon}
            size={vars.iconSize.s}
            color={'#677383'}
          />
        )}
        <span className={styles.title}>{children}</span>
      </div>
      <div>{actionButton}</div>
    </div>
  );
};
