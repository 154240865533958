import { useToggle } from 'react-use';
import { ListingNoteSelectionInput } from 'src/components/Input/ListingNoteSelectionInput';
import { Content, useContentContext } from 'src/contexts/ContentContext';
import { HorizontalTextList } from 'src/core/POS/HorizontalTextList';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useGetEventListingConstraints } from 'src/hooks/useGetEventListingConstraints';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { AddInternalNoteModal } from 'src/modals/AddNote';
import { EditIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getListingNoteIcon } from 'src/utils/listingNotesUtils';
import {
  Event,
  Feature,
  ListingDetailsUpdateInput,
  ListingNote,
  SaleInput,
} from 'src/WebApiController';

import { Detail } from '../Detail';
import * as styles from './ListingNotesSection.css';

export type InternalNotesFormKey =
  | 'privateNotes.value'
  | keyof Pick<SaleInput, 'internalNotes'>;

export type ListingNotesFormKey = keyof Pick<
  ListingDetailsUpdateInput,
  'seatTraits'
>;

export const ListingNotesSection = ({
  listingNotes,
  internalNotes,
  disabled,
  event,
  allowEditListingNotes,
  onListingNotesChanged,
  onInternalNotesChanged,
}: {
  disabled?: boolean;
  listingNotes?: ListingNote[] | null;
  internalNotes?: string | null;
  event?: Event;
  allowEditListingNotes?: boolean;
  onListingNotesChanged?: (listingNotes?: ListingNote[] | null) => void;
  onInternalNotesChanged?: (internalNotes?: string | null) => void;
}) => {
  const [isAddNoteModalOpen, toggleAddNoteModal] = useToggle(false);

  const onSubmit = () => {
    toggleAddNoteModal();
  };
  const { eventListingConstraintsQuery } = useGetEventListingConstraints(
    event?.viagId ? [event.viagId] : []
  );
  const validListingNoteIds = event?.viagId
    ? eventListingConstraintsQuery.data?.[event.viagId]?.validListingNoteIds
    : null;

  const contentContext = useContentContext();
  const hasListingNotesAdditionalInfoFeature = useUserHasFeature(
    Feature.ListingNotesAdditionalInfo
  );

  return (
    <>
      {isAddNoteModalOpen && (
        <AddInternalNoteModal
          onSubmit={onSubmit}
          onClose={toggleAddNoteModal}
          internalNotes={internalNotes}
          onInternalNotesChanged={onInternalNotesChanged}
        />
      )}

      <Stack direction="column" gap="l">
        {allowEditListingNotes ? (
          <Detail
            label={<Content id={ContentId.SeatTraits} />}
            detail={
              <ListingNoteSelectionInput
                avoidCollisions={false}
                side="bottom"
                disabled={disabled}
                placeholderText={
                  !listingNotes ? ContentId.DontChange : undefined
                }
                listingNotes={listingNotes ?? null}
                validListingNoteIds={validListingNoteIds}
                onChange={(listingNotes) =>
                  onListingNotesChanged?.(listingNotes)
                }
              />
            }
          />
        ) : (
          listingNotes?.map((n) => (
            <HorizontalTextList
              key={n.listingNoteId}
              preIcon={getListingNoteIcon(
                n,
                contentContext,
                hasListingNotesAdditionalInfoFeature
              )}
              texts={[n.listingNoteText]}
            />
          ))
        )}
        <Detail
          label={<Content id={ContentId.PrivateNote} />}
          detail={
            <Stack direction="column" gap="m">
              {internalNotes ? (
                <div className={styles.notesGroup}>
                  <HorizontalTextList
                    key="internalNote"
                    texts={[internalNotes]}
                  />
                </div>
              ) : null}
              <Button
                className={styles.addNotesButton}
                size="unset"
                variant="link"
                onClick={toggleAddNoteModal}
                disabled={disabled}
              >
                {internalNotes ? (
                  <EditIcon
                    size={vars.iconSize.s}
                    fill={IconsFill.currentColor}
                    withHoverEffect
                  />
                ) : (
                  <PlusIcon
                    size={vars.iconSize.s}
                    fill={IconsFill.currentColor}
                    withHoverEffect
                  />
                )}
                <Content
                  id={internalNotes ? ContentId.EditNote : ContentId.AddNote}
                />
              </Button>
            </Stack>
          }
        />
      </Stack>
    </>
  );
};
