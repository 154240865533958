import { Stack } from 'src/core/ui';

export const ViagogoLogo = ({ height }: { height?: number | string }) => {
  return (
    <Stack>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height ?? '50px'}
        viewBox="0 0 20 16"
        fill="currentColor"
        color="#5f6062"
      >
        <path d="M19.813 7.25v4.844h-2.156v-.656q-1.156.844-2.313.844-1.219 0-1.906-.734t-.688-1.953q0-3.094 4.469-3.094h.188q0-.531-.188-.719-.281-.281-1.031-.281-1.031 0-1.969.469l-.281.094-.719-1.594q1.469-.938 3.156-.938 1.844 0 2.781 1.031.344.281.5.844.156.531.156 1.844zm-2.5 2.813l.094-.094V8.188q-1.781 0-2.25.469-.281.281-.281.75 0 1 1.031 1 .406 0 .875-.172t.531-.172zM10.5 2.875q-.563 0-.969-.406T9.125 1.5Q9.031.5 10 .109t1.531.328q.625.656.281 1.547t-1.313.891zm1.313.938v8.281H9.219V3.813h2.594zm-5.688 0H8.75l-3.156 8.281H3.156L.093 3.813h2.688l1.688 5.313z"></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height ?? '50px'}
        viewBox="0 0 19 16"
        fill="currentColor"
        color="#6fb229"
      >
        <path d="M14.125 12.375q-1.781 0-2.938-1.25T10.031 8q-.094-1.938 1.156-3.188 1.281-1.281 3.125-1.281t3.063 1.219 1.219 3.063q0 1.938-1.281 3.281-1.281 1.281-3.188 1.281zm-1.5-4.469q0 1.031.469 1.641t1.313.609q1.469 0 1.469-2.25 0-1.031-.422-1.625t-1.203-.594-1.203.594-.422 1.625zm-4.187 6.969Q7.313 16 5.282 16q-1.031 0-1.969-.281-1.313-.406-1.844-.938L2.5 13.125q1 .75 2.5.75 1.781 0 1.781-1.688v-.656q-.563.563-1.781.563-1.781 0-2.75-1.156T1.281 8q0-1.875 1.25-3.156Q3.812 3.531 5.75 3.531q1.844 0 3.531.938v8.625q0 .719-.844 1.781zM6.781 9.594V5.875q-.469-.188-1.125-.188-.781 0-1.281.609t-.5 1.609q0 .938.469 1.5t1.219.563 1.219-.375z"></path>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height={height ?? '50px'}
        viewBox="0 0 19 16"
        fill="currentColor"
        color="#5f6062"
      >
        <path d="M14.125 12.375q-1.781 0-2.938-1.25T10.031 8q-.094-1.938 1.156-3.188 1.281-1.281 3.125-1.281t3.063 1.219 1.219 3.063q0 1.938-1.281 3.281-1.281 1.281-3.188 1.281zm-1.5-4.469q0 1.031.469 1.641t1.313.609q1.469 0 1.469-2.25 0-1.031-.422-1.625t-1.203-.594-1.203.594-.422 1.625zm-4.187 6.969Q7.313 16 5.282 16q-1.031 0-1.969-.281-1.313-.406-1.844-.938L2.5 13.125q1 .75 2.5.75 1.781 0 1.781-1.688v-.656q-.563.563-1.781.563-1.781 0-2.75-1.156T1.281 8q0-1.875 1.25-3.156Q3.812 3.531 5.75 3.531q1.844 0 3.531.938v8.625q0 .719-.844 1.781zM6.781 9.594V5.875q-.469-.188-1.125-.188-.781 0-1.281.609t-.5 1.609q0 .938.469 1.5t1.219.563 1.219-.375z"></path>
      </svg>
    </Stack>
  );
};
