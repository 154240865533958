import {
  cloneElement,
  ComponentPropsWithoutRef,
  ComponentPropsWithRef,
  forwardRef,
  isValidElement,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useRef,
} from 'react';
import { Button, Popover } from 'src/core/ui';

import * as styles from './PosDropdownAnimated.css';

export type PosDropdownAnimatedProps = {
  trigger?: ReactNode;
  rootProps?: ComponentPropsWithRef<typeof Popover.Root>;
  triggerProps?: ComponentPropsWithRef<typeof Popover.Trigger>;
};

export const PosDropdownAnimated = forwardRef<
  HTMLDivElement,
  PropsWithChildren<
    ComponentPropsWithoutRef<typeof Popover.Content> & PosDropdownAnimatedProps
  >
>(function PosDropdownAnimated(
  { rootProps, triggerProps, trigger, children, id, ...rest },
  ref
): ReactElement {
  return (
    <Popover.Root {...rootProps}>
      <Popover.Trigger
        id={id && `${id}-trigger`}
        {...triggerProps}
        asChild
        onClick={(e) => {
          // This is to prevent the click from passing through to the container (which maybe a clickable tile)
          e.stopPropagation();
        }}
      >
        <Button
          id={id && `${id}-trigger-button`}
          variant="textPlain"
          textColor="primary"
          shape="none"
          size="unset"
          justifyContent="space-between"
          disabled={triggerProps?.disabled}
        >
          {isValidElement(trigger)
            ? cloneElement(trigger as ReactElement<{ disabled: boolean }>, {
                disabled: !!triggerProps?.disabled,
              })
            : trigger}
        </Button>
      </Popover.Trigger>
      <Popover.Content
        className={styles.content}
        id={id && `${id}-content`}
        ref={ref}
        {...rest}
      >
        {children}
      </Popover.Content>
    </Popover.Root>
  );
});

export const PosDropdownAnimatedItem = forwardRef<
  HTMLButtonElement,
  PropsWithChildren<ComponentPropsWithoutRef<'button'>> & {
    noPadding?: boolean;
    keepOpenAfterSelection?: boolean;
    justifyContent?: 'start' | 'center' | 'space-between';
  }
>(function PosDropdownAnimatedItem(
  { onClick, justifyContent, keepOpenAfterSelection, children, id, ...props },
  ref
): ReactElement {
  const closeRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Button
        id={id && `${id}-dropdown-item`}
        variant="textPlain"
        textColor="primary"
        shape="none"
        size="unset"
        className={styles.item}
        ref={ref}
        {...props}
        onClick={(e) => {
          onClick?.(e);
          if (!keepOpenAfterSelection) {
            closeRef.current?.click?.();
          }
        }}
        justifyContent={justifyContent ? justifyContent : 'space-between'}
      >
        {children}
      </Button>
      <Popover.Close
        hidden
        ref={closeRef}
        onClick={(e) => {
          // We want the default behavior of the onClick event here.
          // We only don't want the click event to propagate to other elements, e.g. eventAccordion that will cause the collapsible to fold
          e.stopPropagation();
        }}
      />
    </>
  );
});
