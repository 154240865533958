import { StatusCodes } from 'http-status-codes';
import { Content } from 'src/contexts/ContentContext';
import { PosUiActionResult } from 'src/WebApiController';

import { ContentId } from './constants/contentId';

export const getErrorInfoFromStatusCode = (
  statusCode?: number | null,
  messageText?: string | React.ReactNode | null
) => {
  let header = ContentId.Error_InternalServerError_Header;
  let message = ContentId.Error_InternalServerError_GenericMessage;

  switch (statusCode) {
    case 400:
      header = ContentId.Error_BadRequest_Header;
      message = ContentId.Error_BadRequest_GenericMessage;
      break;

    case 401:
      header = ContentId.Error_Unauthorized_Header;
      message = ContentId.Error_Unauthorized_GenericMessage;
      break;

    case 403:
      header = ContentId.Error_Forbidden_Header;
      message = ContentId.Error_Forbidden_GenericMessage;
      break;

    case 404:
      header = ContentId.Error_NotFound_Header;
      message = ContentId.Error_NotFound_GenericMessage;
      break;

    case 408:
      header = ContentId.Error_RequestTimeout_Header;
      message = ContentId.Error_RequestTimeout_GenericMessage;
      break;

    case 501:
      header = ContentId.Error_NotImplemented_Header;
      message = ContentId.Error_NotImplemented_GenericMessage;
      break;

    case 502:
      header = ContentId.Error_BadGatewayError_Header;
      message = ContentId.Error_BadGatewayError_GenericMessage;
      break;

    default:
      if (messageText === 'Failed to fetch') {
        // These kinds of errors is when ajax couldn't hit the internet - there is no status
        // code returned with this error so that's why we can't catch it with status-code
        header = ContentId.Error_BadGatewayError_Header;
        message = ContentId.Error_BadGatewayError_GenericMessage;
      }
      break;
  }

  const headerDisplay = <Content id={header} />;
  const messageDisplay =
    !messageText ||
    (typeof messageText === 'string' &&
      KnownGenericErrors.includes(messageText))
      ? message
      : undefined;

  return {
    headerDisplay: headerDisplay,
    messageDisplay: messageDisplay ? (
      <Content id={messageDisplay} />
    ) : (
      messageText
    ),
  };
};

const KnownGenericErrors = [
  'An unexpected server error occurred.',
  'Failed to fetch',
];

export const isSuccess = (uiResult?: PosUiActionResult | null) => {
  return uiResult?.status === StatusCodes.OK;
};
