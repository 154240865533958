import { useCallback, useEffect, useMemo, useState } from 'react';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { PillList } from 'src/core/ui/PillList';
import { RowInfo, SectionInfo } from 'src/WebApiController';

import { ROW_FILTER_MISSING } from './FilterPillList.constants';
import * as styles from './FilterPillList.css';
import { RowIdFilterDropdown } from './RowIdFilterDropdown';

type FilterPillListProps = {
  disabled?: boolean;
  selectedSections: SectionInfo[];
  rowIdFilters?: number[] | undefined;
  sectionRowIdFilters?: { [key: string]: string } | undefined;

  applyRowFilterChanges: (
    rowIdFiltersNew: number[],
    sectionRowIdFiltersNew: {
      [key: string]: string;
    }
  ) => void;

  applySelectedSectionChanges: (
    sectionId: number,
    selectedSections: SectionInfo[]
  ) => void;
};

type RowOverride = RowInfo & {
  isManualOverride: boolean;
};

export const FilterPillList = ({
  disabled,
  selectedSections,
  rowIdFilters,
  sectionRowIdFilters,
  applyRowFilterChanges,
  applySelectedSectionChanges,
}: FilterPillListProps) => {
  const [focusedSections, setFocusedSections] = useState<SectionInfo[]>([]);

  const [keysPressed, setKeysPressed] = useState<string[]>([]);

  const { activeConfigOverride } = useEventMapContext();

  // Backfill the sections with newly added rows from score override
  const backfilledSections = useMemo(() => {
    if (!activeConfigOverride?.scoreOverrides) {
      return selectedSections;
    }
    const sectionToScoredRows =
      activeConfigOverride?.scoreOverrides?.reduce(
        (acc, score) => {
          acc[score.sectionId] = acc[score.sectionId] || [];
          if (score.rowOrdinal != null && score.rowName != null) {
            acc[score.sectionId].push({
              id: score.rowId,
              name: score.rowName,
              ordinal: score.rowOrdinal,
              tktClass: score.tkClsId,
              isManualOverride: score.isManualOverride,
            } as RowOverride);
          }
          return acc;
        },
        {} as Record<number, RowOverride[]>
      ) ?? {};

    const sanitized: SectionInfo[] = [];
    for (const section of selectedSections) {
      const venueRows = section.rows;
      if (sectionToScoredRows[section.id]) {
        for (const { isManualOverride, ...scoredRow } of sectionToScoredRows[
          section.id
        ]) {
          const index = venueRows.findIndex((row) => row.id === scoredRow.id);
          if (index < 0) {
            // New row from score override
            venueRows.push(scoredRow);
          } else {
            // override section rows from override
            if (isManualOverride) {
              venueRows[index] = scoredRow;
            }
          }
        }
      }
      sanitized.push({ ...section, rows: venueRows });
    }
    return sanitized;
  }, [activeConfigOverride?.scoreOverrides, selectedSections]);

  const updateRowFilters = useCallback(
    (rowFilter: string, row?: RowInfo) => {
      let newRowIdFilters = rowIdFilters ?? [];

      const newSectionRowIdFilters = {
        ...sectionRowIdFilters,
      };

      focusedSections.forEach((section) => {
        const matchingRow = section.rows.find(
          ({ id, name }) =>
            id === row?.id || name?.toLowerCase() === rowFilter.toLowerCase()
        );
        if (matchingRow !== undefined) {
          // Remove all the row filters associated with the section id
          const sectionRowIds = section.rows.map((r) => r.id);
          newRowIdFilters = [
            ...newRowIdFilters.filter(
              (rowId) => !sectionRowIds.includes(rowId)
            ),
          ];

          // Exclude all rows from section if the matching row has no ordinal
          if (matchingRow.ordinal == null) {
            newSectionRowIdFilters[section.id] = matchingRow.id.toString();
            return;
          }

          // Push all the row ids with an ordinal less than or equal to the matching row
          section.rows.forEach((row) => {
            if (row.ordinal == null || matchingRow.ordinal == null) return;
            if (row.ordinal <= matchingRow.ordinal) {
              newRowIdFilters.push(row.id);
            }
          });

          // Only store the highest ordinal row id to display in the pill
          newSectionRowIdFilters[section.id] = matchingRow.id.toString();
        } else {
          delete newSectionRowIdFilters[section.id];
          const sectionRowIds = section.rows.map(({ id }) => id);
          newRowIdFilters = newRowIdFilters.filter(
            (id) => !sectionRowIds.includes(id)
          );
        }
      });

      applyRowFilterChanges(newRowIdFilters, newSectionRowIdFilters);
      setKeysPressed([]);
      setFocusedSections((prev) => {
        return prev.filter((s) => !(s.id in newSectionRowIdFilters));
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rowIdFilters, sectionRowIdFilters, backfilledSections, focusedSections]
  );

  useEffect(() => {
    const sectionsMissingRowFilter = backfilledSections.filter(
      (s) => sectionRowIdFilters && !(s.id in sectionRowIdFilters)
    );

    setFocusedSections(sectionsMissingRowFilter);
  }, [backfilledSections, sectionRowIdFilters]);

  useEffect(() => {
    let keyPressTimeout: NodeJS.Timeout;

    const handleKeyDown = (event: KeyboardEvent) => {
      const ignoreElements = ['INPUT', 'TEXTAREA', 'SELECT'];
      if (
        document.activeElement &&
        ignoreElements.includes(document.activeElement.tagName)
      ) {
        return;
      }

      if (!backfilledSections) return;

      if (!event.ctrlKey && /^[a-zA-Z0-9]$/.test(event.key)) {
        setKeysPressed((prevKeys) => {
          const updatedKeys = [...prevKeys, event.key];

          clearTimeout(keyPressTimeout);

          keyPressTimeout = setTimeout(() => {
            const rowFilter = updatedKeys.join('').toLowerCase();
            updateRowFilters(rowFilter);
          }, 600);

          return updatedKeys;
        });
      } else if (!event.ctrlKey && event.key === 'Enter') {
        setKeysPressed((prevKeys) => {
          const rowFilter = prevKeys.join('').toLowerCase();
          updateRowFilters(rowFilter);
          clearTimeout(keyPressTimeout);
          return [];
        });
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      clearTimeout(keyPressTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backfilledSections, focusedSections]);

  const onPillHover = useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget == null) {
      return;
    }

    e.currentTarget.classList.add(styles.boldPillHover);
  }, []);

  const onPillLeave = useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (e.currentTarget == null) {
      return;
    }

    e.currentTarget.classList.remove(styles.boldPillHover);
  }, []);

  const onPillClick = useCallback(
    (pillId: number, e: React.MouseEvent<HTMLElement>) => {
      // If shift key is pressed, select all pills or unselect all of them if they are all selected
      if (e.shiftKey) {
        if (backfilledSections.length == focusedSections.length) {
          setFocusedSections([]);
        } else {
          setFocusedSections(backfilledSections);
        }
      } else {
        setFocusedSections((prevFocusedSections) => {
          const isActive = prevFocusedSections.some(
            (pill) => pill.id === pillId
          );
          if (isActive) {
            return prevFocusedSections.filter((pill) => pill.id !== pillId);
          } else {
            const newPill = backfilledSections.find(
              (section) => section.id === pillId
            );

            return newPill
              ? [...prevFocusedSections, newPill]
              : prevFocusedSections;
          }
        });
      }
    },
    [focusedSections.length, backfilledSections]
  );

  const filterSectionsPills = useMemo(() => {
    const rowLookup = sectionRowIdFilters ?? {};

    return backfilledSections
      .map((section) => {
        const rowFilter = rowLookup?.[section.id];
        const rowInfo =
          rowFilter != null && rowFilter !== ROW_FILTER_MISSING
            ? section.rows.find((r) => r.id.toString() == rowFilter)
            : undefined;

        const isFocusedPill = focusedSections.find((s) => s.id == section.id);
        return {
          value: section.id.toString(),
          rowFilter: rowFilter,
          display: section.name,
          customPillContainer: isFocusedPill ? styles.boldPill : undefined,
          postfixItem: (
            <RowIdFilterDropdown
              section={section}
              rowName={isFocusedPill ? keysPressed.join('') : undefined}
              row={
                isFocusedPill
                  ? rowInfo
                    ? ({
                        ...rowInfo,
                        name: keysPressed.join('') || rowInfo.name,
                      } as RowInfo)
                    : undefined
                  : rowInfo
              }
              focusedSectionIds={focusedSections.map((f) => f.id)}
              onChange={(row) => {
                updateRowFilters(row.name ?? '', row);
              }}
              disabled={false}
            />
          ),
        };
      })
      .sort((a, b) => {
        // Pills without a row name (rowInfo) come first
        if (!a.rowFilter && b.rowFilter) {
          return -1;
        } else if (a.rowFilter && !b.rowFilter) {
          return 1;
        }

        // Otherwise just sort by the section name
        return a.display.localeCompare(b.display, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      })
      .map(({ value, display, postfixItem, customPillContainer }) => ({
        value,
        display,
        postfixItem,
        customPillContainer,
      }));
  }, [
    focusedSections,
    sectionRowIdFilters,
    backfilledSections,
    keysPressed,
    updateRowFilters,
  ]);

  if (!filterSectionsPills.length) return null;

  return (
    <div style={{ width: '100%', overflow: 'auto' }}>
      <PillList
        pills={filterSectionsPills}
        disabled={disabled}
        onPillHover={onPillHover}
        onPillLeave={onPillLeave}
        onPillClick={onPillClick}
        onPillDeleted={(key) => {
          const sectionId = Number(key);
          if (!isNaN(sectionId)) {
            applySelectedSectionChanges(sectionId, backfilledSections);
          }
        }}
      />
    </div>
  );
};
