import { ReactNode, useContext, useMemo } from 'react';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { AdCampaignIdProvider } from 'src/contexts/AdCampaignIdContext/AdCampaignIdContext';
import {
  AdCampaignsDataContext,
  AdCampaignsDataContextProvider,
} from 'src/contexts/AdCampaignsDataContext/AdCampaignsDataContext';
import {
  AdPlatformReportingContext,
  AdPlatformReportingContextProvider,
} from 'src/contexts/AdPlatformReportingContext/AdPlatformReportingContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { AdCampaignsTable } from 'src/tables/AdCampaignsTable';
import { AdCampaignEntityWithMetrics } from 'src/tables/AdCampaignsTable/AdCampaignsTable.type';
import { ContentId } from 'src/utils/constants/contentId';
import { SomethingWentWrong } from 'src/views';
import {
  AdPlatformTransactionAgg,
  TransactionAggType,
  UserSetting,
} from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import { AdCampaignsToolbar } from './AdCampaignsToolbar/AdCampaignsToolbar';

const SPONSOREDLISTINGS_USER_SETTINGS: UserSetting[] = [];

export function SponsoredListings() {
  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={SPONSOREDLISTINGS_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <AdCampaignsDataContextProvider adCampaignId={undefined}>
        <AdPlatformReportingContextProvider
          aggType={TransactionAggType.CampaignId}
          adCampaignId={undefined}
        >
          <SponsoredListingsContent />
        </AdPlatformReportingContextProvider>
      </AdCampaignsDataContextProvider>
    </SellerUserSettingsProvider>
  );
}

function SponsoredListingsContent() {
  const sponsoredListingsTitle = useContent(ContentId.SponsoredListings);

  const { adCampaignsDataQuery: adCampaignsDataQuery, errorInfo } = useContext(
    AdCampaignsDataContext
  );

  return (
    <AdCampaignIdProvider>
      <DialogProvider>
        <LayoutContent
          mainRoute={MainRoute.SponsoredListings}
          routeTitle={sponsoredListingsTitle}
        >
          <Stack direction="column" width="full" height="full">
            {adCampaignsDataQuery.isLoading && <PosSpinner />}
            {errorInfo && (
              <SomethingWentWrong
                header={errorInfo.errorHeader}
                message={
                  errorInfo.errorMessage ?? (
                    <Content id={ContentId.FailToLoadListContent} />
                  )
                }
              />
            )}
            {!adCampaignsDataQuery.isLoading && !errorInfo && (
              <>
                <AdCampaignsToolbar />
                {adCampaignsDataQuery.data?.length ? (
                  <AdCampaignsFlattenedView />
                ) : (
                  <EmptySectionContent.Root
                    icon={
                      <EmptySectionContent.SolidIconContainer>
                        <SearchSolidIcon />
                      </EmptySectionContent.SolidIconContainer>
                    }
                  >
                    <EmptySectionContent.Label>
                      <Content id={ContentId.NoResultFound} />
                    </EmptySectionContent.Label>
                    <EmptySectionContent.DetailMessage>
                      <Content id={ContentId.NoEventsMatching} />
                    </EmptySectionContent.DetailMessage>
                  </EmptySectionContent.Root>
                )}
              </>
            )}
          </Stack>
        </LayoutContent>
      </DialogProvider>
    </AdCampaignIdProvider>
  );
}

type AdCampaignsFlattenedViewProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
};

export const AdCampaignsFlattenedView = ({
  before,
  topListItemBefore,
}: AdCampaignsFlattenedViewProps) => {
  const { adCampaignsDataQuery: campaignDataQuery, allCampaignData } =
    useContext(AdCampaignsDataContext);

  const { reportData, errorInfo } = useContext(AdPlatformReportingContext);

  const campaignEntitiesWithMetrics = useMemo(() => {
    if (!allCampaignData) {
      return;
    }

    // TODO: Rather than building a dictionary here, we should get the data pre-indexed by id.
    const reportDataByAdCampaignId = reportData?.reduce<{
      [key: string]: AdPlatformTransactionAgg;
    }>((acc, entity) => {
      if (entity.adCampaignId) {
        acc[entity.adCampaignId] = entity;
      }
      return acc;
    }, {});

    const items: AdCampaignEntityWithMetrics[] = allCampaignData.map((item) => {
      const metric = reportDataByAdCampaignId?.[item.campaignId];
      return {
        ...item,
        adSpend:
          metric?.adSpend ??
          (reportDataByAdCampaignId || errorInfo ? null : undefined),
        salesProceeds:
          metric?.salesProceeds ??
          (reportDataByAdCampaignId || errorInfo ? null : undefined),
        qtySold:
          metric?.qtySold ??
          (reportDataByAdCampaignId || errorInfo ? null : undefined),
      };
    });

    return items;
  }, [allCampaignData, errorInfo, reportData]);

  return (
    <Stack direction="column" height="full" width="full">
      {before}
      {topListItemBefore}
      <AdCampaignsTable
        useVirtuoso
        disablePagination
        adCampaignEntities={campaignEntitiesWithMetrics}
        failedToRetrieveData={Boolean(campaignDataQuery.error)}
        isItemsLoading={campaignDataQuery.isLoading}
      />
    </Stack>
  );
};
