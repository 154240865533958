import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Content,
  FormatContent,
  isContentId,
  useContent,
} from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ACTION_OUTBOX_ENTITY_TYPE_TO_CID,
  MARKET_PLACE_TO_CID,
  NOTIFICATION_TYPE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { getTimeAgoString, stringToUtcDate } from 'src/utils/dateTimeUtils';
import { getListingDetailsRelativeUrl } from 'src/utils/inventoryUtils';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { getSaleDetailsRelativeUrl } from 'src/utils/saleUtils';
import {
  ActionOutboxEntityType,
  Notification,
  NotificationType,
} from 'src/WebApiController';

import { SeatingInfo } from '../common/SeatingInfo';
import { ConnectedEventInfo } from '../Events/ConnectedEventInfo';
import { NotificationDisplay } from './NotificationDisplay';

const NOTIFICATION_INTENT_MAP = {
  [NotificationType.SaleNew]: 'success',
  [NotificationType.SaleFulfilmentFailed]: 'error',
  [NotificationType.SaleMarketplaceCancelled]: 'warning',
  [NotificationType.ListingHideSeatsFailed]: 'error',
  [NotificationType.ListingUpdateAllInPriceFailed]: 'error',
  [NotificationType.ListingUpdateInHandDateFailed]: 'error',
  [NotificationType.ListingUpdateSplitTypeFailed]: 'error',
  [NotificationType.ListingUpdateDeliveryTypeFailed]: 'error',
  [NotificationType.ListingUpdateUnitNetProceedsFailed]: 'error',
  [NotificationType.ListingBroadcastFailed]: 'error',
  [NotificationType.ListingUnbroadcastFailed]: 'error',
  [NotificationType.ListingMergeFailed]: 'error',
  [NotificationType.ListingPriceUpdate]: 'success',
  [NotificationType.ListingSplitFailed]: 'error',
  [NotificationType.AutopriceBelowFloor]: 'error',
  [NotificationType.AutopriceInsufficientCompListings]: 'error',
  [NotificationType.AutopriceFirebrake]: 'error',
  [NotificationType.SellerSupportCaseNewReply]: 'success',
} satisfies {
  [key in NotificationType]: 'success' | 'warning' | 'error';
};

type ConnectedNotificationDisplayProps = {
  notification: Notification;
  onDismiss: () => void;
};

/**
 * Wrapper around `NotificationDisplay` that transforms `Notification` into props for
 * `NotificationDisplay`.
 * @param props
 * @returns
 */
export function ConnectedNotificationDisplay({
  notification: {
    notificationType,
    entityType,
    entityId,
    createDateTime,
    event,
    performer,
    venue,
    section,
    row,
    seatFrom,
    seatTo,
    marketplace,
  },
  onDismiss,
}: ConnectedNotificationDisplayProps) {
  const navigate = useNavigate();

  const entityTypeContent = useContent(
    ACTION_OUTBOX_ENTITY_TYPE_TO_CID[entityType]
  );
  const url = useMemo(() => {
    switch (entityType) {
      case ActionOutboxEntityType.Sale:
        return getSaleDetailsRelativeUrl(entityId);

      case ActionOutboxEntityType.Listing:
        return getListingDetailsRelativeUrl(entityId);

      case ActionOutboxEntityType.Purchase:
        return getPurchaseOrderRelativeUrl(entityId);

      case ActionOutboxEntityType.SellerSupportCase:
        return null;

      case ActionOutboxEntityType.Document:
        return null;
    }
  }, [entityType, entityId]);

  const getNotificationTypeContent = useCallback(
    (notificationType: NotificationType) => {
      const parsedContent = NOTIFICATION_TYPE_TO_CID[notificationType];
      return isContentId(parsedContent) ? (
        <Content id={parsedContent} />
      ) : (
        <FormatContent
          id={parsedContent}
          params={
            marketplace ? (
              MARKET_PLACE_TO_CID[marketplace]
            ) : (
              <Content id={ContentId.NA} />
            )
          }
        />
      );
    },
    [marketplace]
  );

  return (
    <NotificationDisplay
      notificationIntent={NOTIFICATION_INTENT_MAP[notificationType]}
      notificationType={getNotificationTypeContent(notificationType)}
      timeAgo={getTimeAgoString(stringToUtcDate(createDateTime))}
      entity={
        <>
          {entityTypeContent} #{entityId}
        </>
      }
      entityType={entityTypeContent}
      seating={
        <SeatingInfo
          section={section ?? ''}
          row={row}
          seatFr={seatFrom}
          seatTo={seatTo}
        />
      }
      onGoToEntity={() => {
        onDismiss();
        if (url) {
          navigate(url);
        }
      }}
      onDismiss={onDismiss}
    >
      <ConnectedEventInfo
        showYear
        event={event}
        performer={performer}
        venue={venue}
        showEventDateTbd={undefined}
      />
    </NotificationDisplay>
  );
}
