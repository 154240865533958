import { useMemo } from 'react';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { EmptySelectionKey } from 'src/utils/adGroupUtils';
import { Performer } from 'src/WebApiController';

type AdGroupPerformerSelectorProps = Omit<
  PosSelectProps,
  'valueOptionsContent'
> & {
  filterCallback: (performer?: Performer) => boolean;
};

export const AdGroupPerformerSelector = ({
  filterCallback,
  placeholderText,
  ...props
}: AdGroupPerformerSelectorProps) => {
  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const allPerformers = useMemo(() => {
    if (!catalogData?.performers) return {};
    const performers = catalogData.performers;

    const results = Object.values(performers).reduce(
      (acc, performer) => {
        if (performer.viagId && filterCallback(performer)) {
          acc[performer.viagId.toString()] = performer.name;
        }
        return acc;
      },
      {} as Record<string, string>
    );

    if (Object.values(results).length == 0) {
      results[EmptySelectionKey] = 'N/A';
    }

    return results;
  }, [catalogData?.performers, filterCallback]);

  return (
    <PosSelect
      {...props}
      searchable={
        props.searchable != null
          ? props.searchable
          : Object.values(allPerformers).length > 10
      }
      loading={catalogData == null}
      placeholderText={placeholderText}
      valueOptionsContent={allPerformers}
    />
  );
};
