import styled from 'styled-components/macro';

export const SortIconContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: transparent;
  height: 36px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }

  .sort-active {
    color: ${(props) => props.theme.colors.textDarker};

    &:hover {
      opacity: 0.5;
      transition: all 0.2s;
    }
  }
  .sort-inactive {
    color: ${(props) => props.theme.colors.textDisabled};

    &:hover {
      opacity: 0.5;
      transition: all 0.2s;
    }
  }
`;
