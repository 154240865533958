import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { EventIcon } from 'src/svgs/EventIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';

export const LaunchChangeEvent = ({
  onClick,
  disabled,
  showIcon,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  showIcon?: boolean;
}) => {
  const hasPermission = useUserHasAnyOfPermissions(
    Permission.Purchases_Edit,
    Permission.Purchases_EditOwned
  );

  if (!hasPermission) {
    return null;
  }

  return (
    <>
      <PosDropdownItem key="changeEvent" onClick={onClick} disabled={disabled}>
        <Stack gap="m">
          {showIcon && (
            <EventIcon
              stroke={vars.color.textPrimary}
              fill={'transparent'}
              size={vars.iconSize.m}
            />
          )}
          <Content id={ContentId.ChangeEvent} />
        </Stack>
      </PosDropdownItem>
    </>
  );
};
