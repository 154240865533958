import {
  Content,
  FormatContent,
  isContentId,
  useContent,
} from 'src/contexts/ContentContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import {
  EVENT_MAPPING_STATE_FILTER_TO_CID,
  MAPPING_REJECTION_REASON_ID_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EventMappingStateFilter } from 'src/utils/eventQueryUtils';

import * as styles from './EventInfo.css';

export type EventMappingStateDisplayProps = {
  eventMappingState?:
    | EventMappingStateFilter.Pending
    | EventMappingStateFilter.Rejected;
  mappingRejectionReasonId?: number | null;
  mappingRejectionReasonDescriptionDefault?: string | null;
};

export function EventMappingStateDisplay({
  eventMappingState,
  mappingRejectionReasonId,
  mappingRejectionReasonDescriptionDefault,
}: EventMappingStateDisplayProps) {
  const requestStateText = useContent(
    EVENT_MAPPING_STATE_FILTER_TO_CID[
      eventMappingState ?? EventMappingStateFilter.Pending
    ]
  );

  const eventMappingRejectionReason = mappingRejectionReasonId
    ? MAPPING_REJECTION_REASON_ID_TO_CID[mappingRejectionReasonId] ??
      mappingRejectionReasonDescriptionDefault
    : undefined;

  if (eventMappingState == null) {
    return null;
  }

  if (eventMappingState === EventMappingStateFilter.Rejected) {
    return (
      <Stack className={styles.root} direction="column" gap="s">
        <WarningMessage
          style={{ alignItems: 'flex-start' }}
          message={
            <Stack direction="column">
              <span>
                <FormatContent
                  id={FormatContentId.RequestState}
                  params={[requestStateText]}
                />
              </span>
              <span>
                <Content id={ContentId.Reason} />
                {': '}
                {eventMappingRejectionReason &&
                isContentId(eventMappingRejectionReason) ? (
                  <Content id={eventMappingRejectionReason} />
                ) : (
                  eventMappingRejectionReason
                )}
              </span>
            </Stack>
          }
        />
      </Stack>
    );
  }

  return (
    <Stack className={styles.root} direction="column" gap="s">
      <WarningMessage
        message={
          <FormatContent
            id={FormatContentId.RequestState}
            params={[requestStateText]}
          />
        }
      />
    </Stack>
  );
}
