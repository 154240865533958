import { ReactNode, useEffect } from 'react';
import { useEventsAccordionSelectedLegend } from 'src/components/Events/EventListing/useEventsAccordionSelectedLegend';
import { useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { ContentId } from 'src/utils/constants/contentId';

interface SetInventoryEventAccordionSelectionCountLabelProps {
  children: ReactNode;
}

export const SetInventoryEventAccordionSelectionCountLabel = ({
  children,
}: SetInventoryEventAccordionSelectionCountLabelProps) => {
  const listingText = useContent(ContentId.Listing);
  const listingsText = useContent(ContentId.Listings);
  const { setSelectedCountLabel, setUsingCustomSelectedCountLabel } =
    useMultiSelectionContext();

  useEffect(() => {
    setUsingCustomSelectedCountLabel(true);
  }, [setUsingCustomSelectedCountLabel]);

  const selectedLegend = useEventsAccordionSelectedLegend({
    singleElementLabel: listingText,
    multipleElementsLabel: listingsText,
  });

  useEffect(() => {
    setSelectedCountLabel(selectedLegend);
  }, [selectedLegend, setSelectedCountLabel]);

  return <>{children}</>;
};
