import { Content } from 'src/contexts/ContentContext';
import {
  PosDropdownAnimated,
  PosDropdownAnimatedItem,
} from 'src/core/POS/PosDropdownAnimated';
import { ContentId } from 'src/utils/constants/contentId';

import { ZoneAreaMetadata } from '../utils/colorUtils';
import * as styles from './ZoneSectionGroupDropdownList.css';

export type SectionGroupProps = {
  zoneSection: [string, string] | null;
  setZoneSection: React.Dispatch<React.SetStateAction<[string, string] | null>>;
  zoneAreaMetadata: ZoneAreaMetadata;
};

export const ZoneSectionGroupDropdownList = ({
  zoneSection,
  setZoneSection,
  zoneAreaMetadata,
}: SectionGroupProps) => {
  return (
    <PosDropdownAnimated
      key="event-action-dropdown"
      trigger={
        <div className={styles.zoneGroupContainer}>
          {zoneSection ? (
            <>
              <div
                className={styles.zoneGroupColorBox}
                style={{ backgroundColor: zoneSection?.[1] }}
              />
              <span>{zoneSection?.[0]}</span>
            </>
          ) : (
            <Content id={ContentId.NoneSelected} />
          )}
        </div>
      }
      align="end"
    >
      {Object.entries(zoneAreaMetadata).map(([label, md]) => (
        <PosDropdownAnimatedItem
          key={label}
          className={styles.zoneGroupContainer}
          justifyContent="start"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setZoneSection([label, md.color]);
          }}
        >
          <div
            className={styles.zoneGroupColorBox}
            style={{ backgroundColor: md.color }}
          />
          <span>{label}</span>
        </PosDropdownAnimatedItem>
      ))}
    </PosDropdownAnimated>
  );
};
