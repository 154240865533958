import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { EventAccordionItemProps } from 'src/components/Accordions';
import { ButtonWithIcon, ReloadButton } from 'src/components/Buttons';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button, SimpleTable, Stack } from 'src/core/ui';
import { BulkUpdateDeliveryOverrideDialog } from 'src/dialogs/BulkEdits/BulkUpdateDeliveryOverrideDialog';
import { usePurchaseEventOverrides } from 'src/hooks/api/usePurchaseEventOverrides';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { EmptyPurchaseQuery } from 'src/utils/eventQueryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  DeliveryType,
  EventWithData,
  Feature,
  PurchaseEventOverride,
  PurchaseOrderQuery,
  PurchaseOrderState,
} from 'src/WebApiController';

import * as styles from '../../Events/EventPage/EventPage.css';
import { PURCHASE_BULK_BACKFILL_DELIVERY_SETTINGS_KEY } from '../PurchaseActionDropdown';
import { PurchaseEventOverridesInput } from './FulfillmentSection/FulfillmentSection.types';
import { OverrideSettingForm } from './FulfillmentSection/OverrideSettingForm';
import * as sectionStyles from './PurchaseEventFulfillmentSection.css';
import { hasNoChangeOrIsDeleteOnly } from './PurchaseEventFulfillmentSection.utils';

type PurchaseEventContentProps = {
  accordionItemProps?: EventWithData;
  purchaseOrderAccordionItem?: EventAccordionItemProps;
};

export function PurchaseEventFulfillmentSection({
  accordionItemProps,
  purchaseOrderAccordionItem,
}: PurchaseEventContentProps) {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = PURCHASE_BULK_BACKFILL_DELIVERY_SETTINGS_KEY;

  const { showErrorDialog } = useErrorBoundaryContext();

  const { BodyComponent } = purchaseOrderAccordionItem || {};
  const event = accordionItemProps?.event;

  const { availableVendors } = usePurchaseVendorSelector({});
  const numVendors = Object.keys(availableVendors).length;

  const updateDialog = useBasicDialog();

  const { openDialog, closeDialog } = useDialog(
    DialogId.BulkBackfillDeliverySettings,
    BulkUpdateDeliveryOverrideDialog
  );

  const {
    purchaseEventOverrides,
    isLoading,
    updatePurchaseEventOverrides,
    isMutationLoading,
  } = usePurchaseEventOverrides(event?.viagId);

  const methods = useForm<PurchaseEventOverridesInput>({
    defaultValues: {
      settings: purchaseEventOverrides ?? [],
    },
  });

  const {
    getValues,
    watch,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    methods.reset({
      settings: purchaseEventOverrides ?? [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseEventOverrides]);

  const onSubmit = useCallback(async () => {
    const { settings } = getValues();
    await updatePurchaseEventOverrides(settings);
  }, [getValues, updatePurchaseEventOverrides]);

  const onReset = useCallback(() => {
    methods.setValue('settings', purchaseEventOverrides ?? [], {
      shouldDirty: true,
    });
  }, [methods, purchaseEventOverrides]);

  const settings = watch('settings');

  const canAddNew = (settings?.length ?? 0) < numVendors + 1;

  const onCreateNewSetting = useCallback(() => {
    if (!canAddNew) {
      return;
    }

    const newSetting: PurchaseEventOverride = {
      vendorId: null,
      delivType: Object.values(DeliveryType)[0],
    };

    methods.setValue('settings', [...settings, newSetting], {
      shouldDirty: true,
    });
  }, [canAddNew, methods, settings]);

  const onDeleteSetting = useCallback(
    (index: number) => {
      const newSettings = [...settings];
      newSettings.splice(index, 1);
      methods.setValue('settings', newSettings, { shouldDirty: true });
    },
    [methods, settings]
  );

  const purchaseQuery = useMemo(() => {
    return {
      ...EmptyPurchaseQuery,
      purchaseOrderState: PurchaseOrderState.Active,
      eventOrMappingIds: [event!.viagId!.toString()],
    } as PurchaseOrderQuery;
  }, [event]);

  const onBulkUpdate = useCallback(
    async (
      updateSalesOfListings: boolean,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkBackfillDeliverySettingsPreview(
              purchaseQuery,
              updateSalesOfListings,
              false
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkBackfillDeliverySettingsPreview', error, {
              trackErrorData: { purchaseQuery },
            });
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkBackfillDeliverySettings(
              preview!.preview,
              updateSalesOfListings,
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              closeDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkBackfillDeliverySettings', error, {
              trackErrorData: {
                preview,
              },
            });
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeDialog,
      purchaseQuery,
      showErrorDialog,
      updateKey,
    ]
  );

  const onSaveHandler = useCallback(() => {
    if (hasNoChangeOrIsDeleteOnly(purchaseEventOverrides, settings)) {
      onSubmit();
      return;
    }

    openDialog({
      updateKey: updateKey,
      entityType: ActionOutboxEntityType.TicketGroup,
      ...updateDialog.dialogProps,
      onSaveOverrideSettings: onSubmit,
      onBulkUpdate: onBulkUpdate,
      onCancel: () => {
        closeDialog();
      },
      backfillDeliveryStrategyFeature:
        Feature.BackfillDeliveryStrategyPurchaseEvent,
      backfillDeliveryStrategyToSaleFeature:
        Feature.BackfillDeliveryStrategyPurchaseEventToSales,
      disableBackfill: hasNoChangeOrIsDeleteOnly(
        purchaseEventOverrides,
        settings
      ),
    });
  }, [
    closeDialog,
    onBulkUpdate,
    onSubmit,
    openDialog,
    purchaseEventOverrides,
    settings,
    updateDialog.dialogProps,
    updateKey,
  ]);

  return (
    <div className={styles.tableSection} id="InventoryEventFulfillmentSection">
      {BodyComponent && accordionItemProps && (
        <div className={styles.tableContainer} style={{ maxWidth: '1200px' }}>
          {isLoading ? (
            <PosSpinner />
          ) : (
            <Stack direction="column" gap="m">
              <FormProvider {...methods}>
                {settings?.length !== 0 && (
                  <SimpleTable.Table>
                    <SimpleTable.Thead>
                      <SimpleTable.Tr>
                        <SimpleTable.Th
                          colSpan={1}
                          className={sectionStyles.tableTopHeader}
                        >
                          <div>
                            <Content id={ContentId.IfPurchaseIsCreatedWith} />
                          </div>
                        </SimpleTable.Th>
                        <SimpleTable.Th
                          colSpan={1}
                          className={sectionStyles.tableTopHeader}
                        >
                          <div>
                            <Content id={ContentId.ThenDefaultThePurchaseTo} />
                          </div>
                        </SimpleTable.Th>
                      </SimpleTable.Tr>
                      <SimpleTable.Tr style={{ borderTop: 'unset' }}>
                        <SimpleTable.Th style={{ width: '60%' }}>
                          <Content id={ContentId.Vendor} />
                        </SimpleTable.Th>
                        <SimpleTable.Th style={{ width: '40%' }}>
                          <Content id={ContentId.DeliveryType} />
                        </SimpleTable.Th>
                      </SimpleTable.Tr>
                    </SimpleTable.Thead>
                    <SimpleTable.Tbody>
                      {settings.map((s, i) => (
                        <OverrideSettingForm
                          key={`${s.delivType}-${i}`}
                          disabled={isMutationLoading}
                          index={i}
                          onDeleteSetting={onDeleteSetting}
                        />
                      ))}
                    </SimpleTable.Tbody>
                  </SimpleTable.Table>
                )}
              </FormProvider>
              {canAddNew && (
                <Stack direction="row" gap="m" width="full" alignItems="end">
                  <ButtonWithIcon
                    icon={
                      <PlusIcon
                        withHoverEffect
                        fill={IconsFill.textBrand}
                        size={vars.iconSize.m}
                      />
                    }
                    disabled={isMutationLoading || isLoading}
                    textContentId={ContentId.AddOverride}
                    onClick={() => {
                      onCreateNewSetting();
                    }}
                    style={{ width: 'min-content', whiteSpace: 'nowrap' }}
                    variant={'text'}
                  />
                </Stack>
              )}
              {isDirty && (
                <Stack direction="row" gap="m" width="full" alignItems="end">
                  <ReloadButton
                    textContentId={ContentId.Reset}
                    variant={'outline'}
                    disabled={isMutationLoading || isLoading}
                    onClick={onReset}
                  />

                  <Button
                    onClick={onSaveHandler}
                    disabled={isMutationLoading || isLoading}
                  >
                    <Content id={ContentId.Save} />
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
        </div>
      )}
    </div>
  );
}
