import { useEffect, useMemo, useRef, useState } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { CrossIcon, WarningOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Tag } from 'src/WebApiController';

import { checkMatchValueType } from '../TagsFormBody.util';
import { TagValueInput } from './TagValueInput';

export const TagValueInputCell = ({
  tag,
  tagsCurrentEntity,
  onChange,
  onBlur,
  disabled,
}: {
  tag: Tag;
  tagsCurrentEntity: Tag[];
  onChange: (newValue: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
}) => {
  const [newValue, setNewValue] = useState<string>(tag.value);
  const [disableBlur, setDisableBlur] = useState<boolean>(false);
  const duplicateMsg = useContent(ContentId.ExistingTagValue);
  const valueTypeErrorMsg = useContent(ContentId.ValueDoesNotSatisfyType);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (tag.value !== newValue) {
      setNewValue(tag.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag.value]);

  const hasDuplicate = useMemo(() => {
    const existingTags = tagsCurrentEntity.filter((t) => t.key === tag.key);
    if (existingTags.length) {
      const values = existingTags.map((t) => t.value);
      return values.includes(newValue) && newValue !== tag.value;
    }
    return false;
  }, [newValue, tag.key, tag.value, tagsCurrentEntity]);

  const isValidNewValue = useMemo(() => {
    if (!newValue) return true;
    return checkMatchValueType(tag.valueType, newValue) && !hasDuplicate;
  }, [hasDuplicate, newValue, tag.valueType]);

  const errorMsg = useMemo(() => {
    if (!newValue) return null;
    if (hasDuplicate) {
      return duplicateMsg;
    }
    if (!checkMatchValueType(tag.valueType, newValue)) {
      return valueTypeErrorMsg;
    }
    return null;
  }, [duplicateMsg, hasDuplicate, newValue, tag.valueType, valueTypeErrorMsg]);

  const inputFieldPosfix = (
    <>
      {errorMsg && (
        <WarningOutlineIcon
          size={vars.iconSize.m}
          fill={'red'}
          title={errorMsg}
        />
      )}
      {newValue ? (
        <CrossIcon
          withHoverEffect
          onMouseDown={() => setDisableBlur(true)}
          onMouseLeave={() => setDisableBlur(false)}
          onClick={() => {
            setNewValue('');
            setDisableBlur(false);
            inputRef.current?.focus();
          }}
          disabled={disabled}
        />
      ) : null}
    </>
  );

  return (
    <TagValueInput
      ref={inputRef}
      tag={tag}
      newValue={newValue}
      isValidNewValue={isValidNewValue}
      setNewValue={setNewValue}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      disableBlur={disableBlur}
      inputFieldPosfix={inputFieldPosfix}
    />
  );
};
