import { ColumnDef } from '@tanstack/react-table';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import {
  SaleMarketplaceIdDisplay,
  SaleMarketplaceIdDisplayProps,
} from 'src/components/Sales/SaleMarketplaceIdDisplay';
import { Content } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { FormatOption } from 'src/modals/EditTableColumns';
import { SHIMMERING_DIV_HEIGHT_SINGLE_SALE } from 'src/tables/SalesTable/SalesTable.type';
import { SeatInfoContainer, TableShimmeringDiv } from 'src/tables/Table';
import { DateCell } from 'src/tables/Table/TableCells';
import { SalesTableColumnId } from 'src/utils/columns/sales/salesColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { getAllInPriceFromListPrice } from 'src/utils/inventoryUtils';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { safeSortDateTime } from 'src/utils/tableUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  Marketplace,
  MarketplaceAccountSetting,
  Sale,
  Seating,
  UiMoney,
} from 'src/WebApiController';

const calculateSaleWebsitePrice = (
  marketplaceSettings: MarketplaceAccountSetting[],
  proceeds: UiMoney,
  qtySold: number,
  marketPlace: string | null
) => {
  // Find the marketplace settings from sale marketplace, default it to StubHub
  const marketplaceSetting = marketplaceSettings.find((setting) =>
    marketPlace
      ? setting.mkp === marketPlace
      : setting.mkp === Marketplace.StubHub
  );
  const proceedsPerTicket = proceeds.amt / qtySold;

  return getAllInPriceFromListPrice(
    proceedsPerTicket,
    null,
    marketplaceSetting?.sellerFee
  );
};

export const getColumnDefinitions = (
  marketplaceSettings: MarketplaceAccountSetting[]
): ColumnDef<Sale | null>[] => [
  {
    id: SalesTableColumnId.Seating,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content id={ContentId.Seats} />
      </TableHeaderCellDiv>
    ),
    minSize: 80,
    maxSize: 150,
    accessorFn: (data) => data?.seating,
    cell: ({ getValue }) => {
      const value = getValue<Seating | null>();
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <SeatInfoContainer>
          <SeatingInfo {...value} />
        </SeatInfoContainer>
      );
    },
    enableSorting: false,
  },
  {
    id: SalesTableColumnId.QuantitySold,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.SoldQuantity} />
      </TableHeaderCellDiv>
    ),
    minSize: 50,
    maxSize: 80,
    accessorFn: (data) => (data ? data.qtySold || 0 : null),
    cell: function Cell({ getValue, column: { id: columnId }, table }) {
      const value = getValue<number>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SectionType.Sales
      );

      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    enableSorting: false,
  },
  {
    id: SalesTableColumnId.Proceeds,
    minSize: 50,
    maxSize: 120,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.WebsitePrice} />
      </TableHeaderCellDiv>
    ),
    accessorFn: (data) => {
      return !data
        ? null
        : {
            proceeds: data.ttlNetProcs,
            qtySold: data.qtySold,
            marketPlace: data.mkp,
          };
    },
    cell: function Cell({ getValue, column: { id: columnId }, table }) {
      const value = getValue<{
        proceeds: UiMoney | null;
        qtySold: number;
        marketPlace: string | null;
      } | null>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        table.options.meta?.sectionType ?? SectionType.Sales
      );

      if (value == null || value.proceeds == null || !value.qtySold) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      const { proceeds, qtySold, marketPlace } = value;
      const allInPrice = calculateSaleWebsitePrice(
        marketplaceSettings,
        proceeds,
        qtySold,
        marketPlace
      );

      return (
        <TableCellDiv align="right">
          <div>
            {userDefinedPrecision
              ? applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: allInPrice,
                  currencyCode: proceeds.currency,
                  currencyDecimalDigits: proceeds.dec,
                })
              : allInPrice ?? <Content id={ContentId.NA} />}
          </div>
        </TableCellDiv>
      );
    },
    enableSorting: false,
  },
  {
    id: SalesTableColumnId.Sale,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.Sale} />
      </TableHeaderCellDiv>
    ),
    minSize: 100,
    maxSize: 100,
    accessorFn: (data) =>
      !data
        ? null
        : {
            marketplaceSaleId: data.idOnMkp,
            saleId: data.id,
            isExternal: Boolean(data.idOnMkp),
            marketplace: data.mkp,
          },
    cell: ({ getValue }) => {
      const value = getValue();
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <SaleMarketplaceIdDisplay
          {...(value as SaleMarketplaceIdDisplayProps)}
        />
      );
    },
    enableSorting: false,
  },
  {
    id: SalesTableColumnId.OrderDate,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.SaleDate} />
      </TableHeaderCellDiv>
    ),
    minSize: 100,
    maxSize: 140,
    accessorFn: (data) => data?.saleDate,
    cell: function Cell({ getValue, column: { id: columnId }, table }) {
      const value = getValue<string>();
      const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
        useColumnUserSetting(
          columnId,
          table.options.meta?.sectionType ?? SectionType.Sales
        );

      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_SALE} />
        );
      }

      return (
        <TableCellDiv align="right">
          <DateCell
            precision={userDefinedPrecision}
            date={value}
            enableUtcFallback
            hideRelativeTerms
          />
        </TableCellDiv>
      );
    },
    sortingFn: safeSortDateTime<Sale>,
  },
];
