import { Venue } from 'src/WebApiController';

import * as styles from './EventSearchDropdownResult.css';

export const VenueSearchResultItem = ({ venue }: { venue: Venue }) => {
  return (
    <div className={styles.eventSearchResultItemDiv}>
      <div className={styles.eventSearchResultItemNameDiv}>{venue.name}</div>
      <div className={styles.eventSearchResultItemDateDiv}>
        {`${venue.city}, ${venue.state || ''} ${venue.country?.name || ''}`}
      </div>
    </div>
  );
};
