import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { dataThemeAttributeName } from 'src/app/constants';
import {
  AppContextProvider,
  AppTelemetryProvider,
  useAppContext,
} from 'src/contexts/AppContext';
import { SiteContentProvider } from 'src/contexts/ContentContext';
import { ScreenSizeProvider } from 'src/contexts/ScreenSizeContext/ScreenSizeContext';
import {
  SiteThemeContextProvider,
  useSiteTheme,
} from 'src/contexts/SiteTheme/SiteThemeContext';
import {
  PurpleLegacyTheme,
  purpleTheme,
  TicketUtilLegacyTheme,
  ticketUtilTheme,
} from 'src/core/themes';
import { DarkLegacyTheme } from 'src/core/themes/darkLegacyTheme';
import { darkTheme } from 'src/core/themes/darkTheme.css';
import { clearIDBCache } from 'src/core/utils/createIndexDBPersister';
import { REACT_APP_NAME } from 'src/utils/constants/constants';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  ClientLogClient,
  ClientLogRequest,
  CoBrandId,
  LogLevel,
} from 'src/WebApiController';
import { ThemeProvider } from 'styled-components/macro';

import { SomethingWentWrong } from './SomethingWentWrong';

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError(error) {
      if (ApiException.isApiException(error) && error.status === 401) {
        clearIDBCache(); // delete the cache storage before reloading
        window.location.reload();
      }
    },
  }),
});

export const RootErrorPage = () => {
  const error = useRouteError();
  console.log(error);

  const message = (error as { message?: string }).message;
  const status = isRouteErrorResponse(error) ? error.status : undefined;
  const statusText = isRouteErrorResponse(error) ? error.statusText : undefined;
  const stack = (error as { stack?: string }).stack;

  const { activeAccountWebClientConfig } = useAppContext();
  const logClient = useMemo(
    () => new ClientLogClient(activeAccountWebClientConfig),
    [activeAccountWebClientConfig]
  );

  useEffect(() => {
    const logRequest: ClientLogRequest = {
      logLevel: LogLevel.Error,
      applicationSource: REACT_APP_NAME,
      errorId: null,
      error: message ?? '',
      pageUrl: window.location.href,
      componentStack: stack ?? null,
      additionalInfo: {
        status: status,
        statusText: statusText,
      },
    };

    tryInvokeApi(
      async () => {
        await logClient.log(logRequest);

        if (message?.includes('Minified React error #301')) {
          // When this happens - the cache is corrupted and usually clearing the cache fixes it
          // So instead of forcing the user to do it automatically - we should just do it and take
          // them back to where they were before
          clearIDBCache();
          window.location.reload();
        }
      },
      () => {
        console.warn('Failed to log error to server');
      }
    );
  });

  const { headerDisplay, messageDisplay } = status
    ? getErrorInfoFromStatusCode(status, statusText)
    : { headerDisplay: undefined, messageDisplay: undefined };

  const { cobrandId } = useAppContext();
  const { isDarkMode } = useSiteTheme();

  useEffect(() => {
    document.body.setAttribute(dataThemeAttributeName, 'light');

    if (cobrandId === CoBrandId.TicketUtil) {
      document.body.classList.toggle(ticketUtilTheme, true);
      document.body.classList.remove(purpleTheme);
      document.body.classList.remove(darkTheme);
    } else if (isDarkMode) {
      document.body.classList.toggle(darkTheme, true);
      document.body.setAttribute(dataThemeAttributeName, 'dark');
      document.body.classList.remove(ticketUtilTheme);
      document.body.classList.remove(purpleTheme);
    } else {
      document.body.classList.toggle(purpleTheme, true);
      document.body.classList.remove(ticketUtilTheme);
      document.body.classList.remove(darkTheme);
    }
  }, [cobrandId, isDarkMode]);

  return (
    <AppTelemetryProvider>
      <AppContextProvider>
        <QueryClientProvider client={queryClient}>
          <ScreenSizeProvider>
            <SiteContentProvider>
              <SiteThemeContextProvider>
                <ThemeProvider
                  theme={
                    cobrandId === CoBrandId.TicketUtil
                      ? TicketUtilLegacyTheme
                      : isDarkMode
                      ? DarkLegacyTheme
                      : PurpleLegacyTheme
                  }
                >
                  <SomethingWentWrong
                    header={headerDisplay}
                    message={messageDisplay}
                    messages={[message]}
                  />
                </ThemeProvider>
              </SiteThemeContextProvider>
            </SiteContentProvider>
          </ScreenSizeProvider>
        </QueryClientProvider>
      </AppContextProvider>
    </AppTelemetryProvider>
  );
};
