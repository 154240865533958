import { ReactNode, useEffect, useMemo } from 'react';
import { useGetNoSelectedItemsLabel } from 'src/components/common/MultiSelect/useGetNoSelectedItemsLabel';
import { useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { ContentId } from 'src/utils/constants/contentId';

interface SetPurchasesTimeViewSelectionCountLabelProps {
  children: ReactNode;
}

export const SetPurchasesTimeViewSelectionCountLabel = ({
  children,
}: SetPurchasesTimeViewSelectionCountLabelProps) => {
  const purchaseText = useContent(ContentId.Purchase);
  const purchasesText = useContent(ContentId.Purchases);
  const selectedText = useContent(ContentId.Selected);
  const {
    setSelectedCountLabel,
    totalItemsSelected,
    getSelection,
    setUsingCustomSelectedCountLabel,
    getGroupSelectableGroupMetadata,
  } = useMultiSelectionContext();

  const noSelectedItemsLabel = useGetNoSelectedItemsLabel();

  useEffect(() => {
    setUsingCustomSelectedCountLabel(true);
  }, [setUsingCustomSelectedCountLabel]);

  const selectedCountLabel = useMemo<string>(() => {
    if (!totalItemsSelected) {
      return noSelectedItemsLabel;
    }

    const selection = getSelection();

    let purchasesCount = 0;

    const singleSelectedItems = new Set(
      selection.items.itemIds.filter((itemId) => parseInt(itemId) > 0) ?? []
    );

    purchasesCount += singleSelectedItems.size;

    selection.groupIds.forEach((groupId) => {
      const g = getGroupSelectableGroupMetadata(groupId);
      purchasesCount += g?.itemCount ?? 0;
    });

    const selectedPurchasesLabel =
      purchasesCount === 1 ? purchaseText : purchasesText;

    return `(${purchasesCount} ${selectedPurchasesLabel} ${selectedText})`.toLocaleLowerCase();
  }, [
    totalItemsSelected,
    getSelection,
    purchaseText,
    purchasesText,
    selectedText,
    noSelectedItemsLabel,
    getGroupSelectableGroupMetadata,
  ]);

  useEffect(() => {
    setSelectedCountLabel(selectedCountLabel);
  }, [selectedCountLabel, setSelectedCountLabel]);

  return <>{children}</>;
};
