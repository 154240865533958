import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature, Permission } from 'src/WebApiController';

interface BulkPricingActionsPermissions {
  hasEditPricingPermission: boolean;
  hasUpdateListingPermission: boolean;
  hasAutoPricingFeature: boolean;
}

export const useBulkPricingActionsPermissions =
  (): BulkPricingActionsPermissions => {
    const hasEditPricingPermission = useUserHasAnyOfPermissions(
      Permission.Inventory_SetPrice,
      Permission.Inventory_SetPriceOnIsPricerOf,
      Permission.Inventory_SetPriceOnPurchasedBy
    );

    const hasUpdateListingPermission = useUserHasAnyOfPermissions(
      Permission.Inventory_UpdateListingInfo,
      Permission.Inventory_UpdateListingInfoOnIsPricerOf,
      Permission.Inventory_UpdateListingInfoOnPurchasedBy
    );

    const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

    return {
      hasEditPricingPermission,
      hasAutoPricingFeature,
      hasUpdateListingPermission,
    };
  };
