import { forwardRef, RefObject } from 'react';
import { Components } from 'react-virtuoso';
import { PosTableData } from 'src/tables/Table';
import { PosGroupedVirtuosoContext } from 'src/tables/Table/GroupedVirtuosoTable/PosGroupedVirtuosoContext';
import { PosVirtuosoTableRow } from 'src/tables/Table/PosVirtuosoTableComponent/PosVirtuosoTableRow';

import * as styles from '../Table.css';

export const PosGroupedVirtuosoItem: Components['Item'] = forwardRef(
  function GroupedVirtuosoItem({ context, children, item, ...props }, ref) {
    const { tableRows } = context as PosGroupedVirtuosoContext<PosTableData>;
    const itemIndex = props['data-item-index'];
    const row = tableRows[itemIndex];
    return (
      <div ref={ref as RefObject<HTMLDivElement>} {...props}>
        <table className={styles.table}>
          <tbody>
            <PosVirtuosoTableRow item={row} {...props}>
              {children}
            </PosVirtuosoTableRow>
          </tbody>
        </table>
      </div>
    );
  }
);
