import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from '../GroupListings/GroupListings.css';
import { GroupListingAutoCompSettingsForm } from './autoCompGroup.types';
import { GroupListingAutoCompTable } from './components/GroupListingAutoCompTable';

export const GroupListingsAutoGroupListingsBody = () => {
  const { watch } = useFormContext<GroupListingAutoCompSettingsForm>();

  const mergeListingGroupInputs = watch('mergeListingGroupInputs');

  return (
    <Stack direction="column" gap="xl" width="full">
      {mergeListingGroupInputs.length ? (
        mergeListingGroupInputs.map((m, i) => (
          <div key={i}>
            <Stack gap="m">
              <span>
                <Content id={ContentId.Name} />:
              </span>
              <span>
                <b>{m.name}</b>
              </span>
            </Stack>
            <Stack gap="m">
              <div className={styles.tableContainer}>
                <GroupListingAutoCompTable data={m.items} />
              </div>
            </Stack>
          </div>
        ))
      ) : (
        <WarningMessage
          message={<Content id={ContentId.NoGroupsAvailableForVenueConfig} />}
        />
      )}
    </Stack>
  );
};
