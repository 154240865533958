import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { MultiSectionsDisplay } from 'src/components/Events/VenueMap';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { VenueMapContentProps } from 'src/components/Events/VenueMap/VenueMapContent';
import { Content } from 'src/contexts/ContentContext';
import { Button, Stack, Switch } from 'src/core/ui';
import { AddSectionGroupDialog } from 'src/dialogs/AddSectionGroupDialog';
import { ChangeSectionGroupDialog } from 'src/dialogs/ChangeSectionGroupDialog';
import { useAutoCompMapSectionHandler } from 'src/hooks/useAutoCompMapSectionHandler';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DetailGroup, DetailSection } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import {
  AutoCompAndAutoGroupMode,
  Feature,
  ZoneSectionArea,
} from 'src/WebApiController';

import { GroupListingAutoCompSettingsForm } from '../autoCompGroup.types';
import { getZoneAreaMetadata, ZoneAreaMetadata } from '../utils/colorUtils';
import * as styles from './SectionGroup.css';
import { ZoneSectionGroupDraggableList } from './ZoneSectionGroupDraggableList';
import { ZoneSectionGroupList } from './ZoneSectionGroupList';

export const SectionGroup = ({ mode }: { mode: AutoCompAndAutoGroupMode }) => {
  const { watch, setValue } =
    useFormContext<GroupListingAutoCompSettingsForm>();

  const hasAutoCompPersistAllConfigChangesFeature = useUserHasFeature(
    Feature.AutoCompPersistAllConfigChanges,
  );
  const hasAutoCompRankOverridesFeature = useUserHasFeature(
    Feature.AutoCompRankOverrides
  );
  const zoneSectionAreaGroupLookup = watch('zoneSectionAreaGroupLookup');
  const useCustomRank = watch('sectionGroupCustomRankEnabled');

  const addSectionGroupDialog = useBasicDialog();
  const changeSectionGroupDialog = useBasicDialog();

  const zoneAreaMetadata = useMemo(() => {
    if (!zoneSectionAreaGroupLookup) {
      return {};
    }

    return getZoneAreaMetadata(
      zoneSectionAreaGroupLookup.zoneNameToRankMap,
      zoneSectionAreaGroupLookup.zoneSectionAreaRankOverrides,
      zoneSectionAreaGroupLookup.groupIdToMedianSeatScoreMap,
      useCustomRank && hasAutoCompRankOverridesFeature
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    zoneSectionAreaGroupLookup.zoneNameToRankMap,
    zoneSectionAreaGroupLookup.zoneSectionAreaRankOverrides,
    zoneSectionAreaGroupLookup.groupIdToMedianSeatScoreMap,
    useCustomRank,
    hasAutoCompRankOverridesFeature,
  ]);

  const onLaunchingSingleSectionDialog = useCallback(
    () => changeSectionGroupDialog.launchDialog(),
    [changeSectionGroupDialog]
  );

  const {
    selectedSectionIds,
    selectedSectionData,
    onSectionClicked,
    onResetSections,
    onToggleMirrors,
    onSelectedSectionsChange,
  } = useAutoCompMapSectionHandler(onLaunchingSingleSectionDialog);

  const onSectionZoneChanged = useCallback(
    (newGroupId: string, sectionIds: number[]) => {
      // Update sectionGroupId mapping
      const sectionGroupIdMap =
        zoneSectionAreaGroupLookup?.sectionIdToGroupIdMap;

      const newSectionGroupIdMap = {
        ...sectionGroupIdMap,
        ...sectionIds.reduce((acc: { [key: string]: string }, sectionId) => {
          acc[sectionId] = newGroupId;
          return acc;
        }, {}),
      };

      setValue(
        'zoneSectionAreaGroupLookup.sectionIdToGroupIdMap',
        newSectionGroupIdMap
      );

      // Initialize the array to hold section areas to move
      const sectionAreasToMove: ZoneSectionArea[] = [];

      sectionIds.reduce((acc, sectionId) => {
        const oldGroupId = sectionGroupIdMap?.[sectionId];

        if (oldGroupId) {
          const oldZoneSectionAreas =
            zoneSectionAreaGroupLookup?.groupIdToSectionIdMap[oldGroupId] ?? [];

          // Partition the areas into those to move and those to keep
          const [areasToMove, newZoneSectionAreas] = oldZoneSectionAreas.reduce(
            ([toMove, toKeep], area) => {
              if (area.sectId === sectionId) {
                const [ticketClassName, zoneAreaName] = newGroupId.split(': ');

                const newArea = {
                  ...area,
                  rank: 1,
                  zoneAreaName: zoneAreaName,
                  ticketClassName: ticketClassName,
                  ticketClassId: 0,
                };

                toMove.push(newArea);
              } else {
                toKeep.push(area);
              }
              return [toMove, toKeep];
            },
            [[], []] as [ZoneSectionArea[], ZoneSectionArea[]]
          );

          // Add the areas to move to the main collection
          sectionAreasToMove.push(...areasToMove);

          // Update the map with new areas after removing the section
          acc[oldGroupId] = newZoneSectionAreas;
        }

        return acc;
      }, zoneSectionAreaGroupLookup.groupIdToSectionIdMap);

      // Append sectionIds to newGroupId
      const newGroupOldSectionAreas = [
        ...(zoneSectionAreaGroupLookup?.groupIdToSectionIdMap[newGroupId] ||
          []),
        ...sectionAreasToMove,
      ];

      setValue(
        `zoneSectionAreaGroupLookup.groupIdToSectionIdMap.${newGroupId}`,
        newGroupOldSectionAreas
      );

      if (hasAutoCompPersistAllConfigChangesFeature) {
        // The new group has custom sections in it so mark it as
        // a custom group so we persist these changes
        const oldCustomSectionGroups = zoneSectionAreaGroupLookup?.customSectionGroups ?? [];
        if (!oldCustomSectionGroups.includes(newGroupId)) {
          const newCustomSectionGroups = [
            ...oldCustomSectionGroups,
            newGroupId,
          ];
          setValue(
            'zoneSectionAreaGroupLookup.customSectionGroups',
            newCustomSectionGroups,
          );
        }
      }

      changeSectionGroupDialog.closeDialog();
    },
    [
      changeSectionGroupDialog,
      setValue,
      zoneSectionAreaGroupLookup?.groupIdToSectionIdMap,
      zoneSectionAreaGroupLookup?.sectionIdToGroupIdMap,
      zoneSectionAreaGroupLookup?.customSectionGroups,
      hasAutoCompPersistAllConfigChangesFeature,
    ]
  );

  const onAddSectionGroup = useCallback(
    (zoneName: string, sectionGroupName: string) => {
      // Including the space so the UI is consistent with how we render the other section area names
      // Before saving in the DB, we split on `:` and trim the values on the C# side
      const customZoneAreaName = `${zoneName}: ${sectionGroupName}`;

      // Update the groupIdToSectionIdMap with the custom section group
      const newGroupIdToSectionIdMap = {
        ...zoneSectionAreaGroupLookup?.groupIdToSectionIdMap,
        [customZoneAreaName]: [],
      };
      setValue(
        'zoneSectionAreaGroupLookup.groupIdToSectionIdMap',
        newGroupIdToSectionIdMap
      );

      // Update the zoneNameToMapMap the new `zone: section group name` entry
      const updatedZoneNameToRankMap = {
        ...zoneSectionAreaGroupLookup?.zoneNameToRankMap,
        [zoneName]: {
          ...(zoneSectionAreaGroupLookup?.zoneNameToRankMap[zoneName] || {}),
          [sectionGroupName]: 1,
        },
      };
      setValue(
        'zoneSectionAreaGroupLookup.zoneNameToRankMap',
        updatedZoneNameToRankMap
      );

      // Flag that the new entries are a custom section group
      const newCustomSectionGroup = [
        ...(zoneSectionAreaGroupLookup?.customSectionGroups || []),
        customZoneAreaName,
      ];
      setValue(
        'zoneSectionAreaGroupLookup.customSectionGroups',
        newCustomSectionGroup
      );

      addSectionGroupDialog.closeDialog();
    },
    [
      zoneSectionAreaGroupLookup?.groupIdToSectionIdMap,
      zoneSectionAreaGroupLookup?.zoneNameToRankMap,
      zoneSectionAreaGroupLookup?.customSectionGroups,
      setValue,
      addSectionGroupDialog,
    ]
  );

  const getColor = useCallback(
    (
      sectionIdMap: { [key: string]: string } | undefined,
      zoneAreaMetadata: ZoneAreaMetadata
    ): NonNullable<VenueMapContentProps['getColor']> => {
      return ({ sectionId }) => {
        if (!sectionIdMap || !sectionId) {
          return undefined;
        }

        const sectionGroupId = sectionIdMap[sectionId.toString()];

        if (!sectionGroupId) {
          return undefined;
        }

        const md = zoneAreaMetadata[sectionGroupId];
        return { fill: `${md.color}`, stroke: 'black' };
      };
    },
    []
  );

  return (
    <DetailSection name={<Content id={ContentId.SectionGroups} />} fullHeight>
      <DetailGroup style={{ height: '100%', gridColumn: '1' }}>
        <div className={styles.sectionGroupSettingsContainer}>
          <div className={styles.gridPanelContainer}>
            {mode != AutoCompAndAutoGroupMode.AutoGroup && (
              <Stack direction="row" gap="m" alignItems="center">
                <strong>
                  <Content id={ContentId.CustomRanks} />
                </strong>
                <Switch
                  checked={useCustomRank}
                  onChange={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked) => {
                    setValue('sectionGroupCustomRankEnabled', isChecked);
                  }}
                />
                <Content
                  id={useCustomRank ? ContentId.Enabled : ContentId.Disabled}
                />
              </Stack>
            )}
            {useCustomRank && hasAutoCompRankOverridesFeature ? (
              <ZoneSectionGroupDraggableList
                zoneAreaMetadata={zoneAreaMetadata}
              />
            ) : (
              <ZoneSectionGroupList zoneAreaMetadata={zoneAreaMetadata} />
            )}
            <div className={styles.addSectionButton}>
              <Button
                variant="text"
                onClick={addSectionGroupDialog.launchDialog}
              >
                + <Content id={ContentId.AddSection} />
              </Button>
            </div>
            <AddSectionGroupDialog
              {...addSectionGroupDialog.dialogProps}
              onOkay={onAddSectionGroup}
              onCancel={addSectionGroupDialog.closeDialog}
            />
          </div>
          <div className={styles.venuemapContainer}>
            <EventVenueMap
              selectedSectionIds={selectedSectionIds}
              onSectionClicked={onSectionClicked}
              onToggleMirrors={onToggleMirrors}
              setSelectedSections={onSelectedSectionsChange}
              showDefaultMapColors={true}
              getColor={getColor(
                zoneSectionAreaGroupLookup?.sectionIdToGroupIdMap,
                zoneAreaMetadata
              )}
              staticInfoContent={
                selectedSectionData && (
                  <Stack direction="column" gap="l">
                    <MultiSectionsDisplay
                      sections={selectedSectionData}
                      onClearSelection={onResetSections}
                    />
                    <Button
                      size="md"
                      onClick={() => changeSectionGroupDialog.launchDialog()}
                    >
                      <Content id={ContentId.EditZone} />
                    </Button>
                  </Stack>
                )
              }
            />
            <ChangeSectionGroupDialog
              {...changeSectionGroupDialog.dialogProps}
              selectedSections={selectedSectionData ?? []}
              sectionIdMapping={
                zoneSectionAreaGroupLookup?.sectionIdToGroupIdMap
              }
              zoneAreaMetadata={zoneAreaMetadata}
              onOkay={onSectionZoneChanged}
              onCancel={changeSectionGroupDialog.closeDialog}
            />
          </div>
        </div>
      </DetailGroup>
    </DetailSection>
  );
};
