import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { ListingPriceCalculation, Seating } from 'src/WebApiController';

import {
  ComparableListingDisplay,
  ComparableListingDisplayProps,
} from '../../ComparableListingDisplay';
import { ComparableListing } from '../ComparableListings';
import * as styles from './YourAutoPricerResultsDisplay.css';

export type YourAutopriceResultsDisplayProps = Pick<
  ComparableListingDisplayProps,
  'isCurrentListing'
> & {
  currentListing: ComparableListing;
  anchorListing?: ComparableListing;
  listingPriceCalc?: ListingPriceCalculation | null;
};

export const YourAutopriceResultsDisplay: React.FC<
  YourAutopriceResultsDisplayProps
> = ({ isCurrentListing, currentListing, anchorListing, listingPriceCalc }) => {
  if (currentListing.price == null || currentListing.currencyCode == null) {
    return null;
  }

  if (anchorListing) {
    if (anchorListing.price == null || anchorListing.currencyCode == null) {
      return null;
    }
  }

  const formatterCurrent = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currentListing.currencyCode,
  });

  const formatterAnchor =
    anchorListing == null
      ? null
      : new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: anchorListing.currencyCode,
        });

  return (
    <div className={styles.comparableListingsAnchorDisplay}>
      <Content id={ContentId.YourAutoPriceResults} />
      {anchorListing && (
        <ComparableListingDisplay
          listingId={anchorListing.listingId}
          isOutlier={anchorListing.isOutlier}
          isAnchor={anchorListing.isAnchor}
          isYourListing={anchorListing.isYourListing}
          price={formatterAnchor!.format(anchorListing!.price!)}
          listingPriceCalc={listingPriceCalc}
          validPurchaseQuantities={anchorListing.validPurchaseQuantities}
          listingNotes={anchorListing.listingNotes}
          seating={
            {
              section: anchorListing.section ?? '',
              row: anchorListing.row ?? '',
            } as Seating
          }
        />
      )}

      <ComparableListingDisplay
        listingId={currentListing.listingId}
        isOutlier={currentListing.isOutlier}
        isAnchor={currentListing.isAnchor}
        isYourListing={currentListing.isYourListing}
        isCurrentListing={isCurrentListing}
        price={formatterCurrent.format(currentListing.price)}
        listingPriceCalc={listingPriceCalc}
        validPurchaseQuantities={currentListing.validPurchaseQuantities}
        listingNotes={currentListing.listingNotes}
        seating={
          {
            section: currentListing.section ?? '',
            row: currentListing.row ?? '',
          } as Seating
        }
      />
    </div>
  );
};
