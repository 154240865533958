import { PosIconProps } from './SvgWrapper';

export function StubhubFulfillmentLogo(props: Omit<PosIconProps, 'viewBox'>) {
  return (
      <svg width="33" height="30" viewBox="0 0 33 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M3.02681 23.5256C2.58982 25.3258 1.52681 28.0256 1.52681 28.0256C1.52681 28.0256 6.52679 25.5256 8.02681 25.5256C9.52682 25.5256 22.0268 26.0238 31.0268 26.5256C32.0253 26.5813 32.0268 26.5256 32.0268 25.5256V2.02562C32.0268 1.02564 32.0231 0.938995 31.0268 1.02562C19.5268 2.02556 13.5268 2.5238 2.02681 3.52562C1.03059 3.61241 0.936088 3.52792 1.02681 4.52562C1.52676 10.0238 3.27 22.5238 3.02681 23.5256Z"
              fill="#23034C" stroke="#23034C"/>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.5737 6.5C12.5737 6.22386 12.7975 6 13.0737 6H20.9799C21.2561 6 21.4799 6.22386 21.4799 6.5V9.50001H20.308V7.74997C20.308 7.47382 20.0842 7.24997 19.808 7.24997H14.2455C13.9694 7.24997 13.7455 7.47382 13.7455 7.74997V14.5H12.5737V6.5ZM12.5737 16.5V21.5C12.5737 21.7761 12.7975 22 13.0737 22H20.9799C21.2561 22 21.4799 21.7761 21.4799 21.5V11.5H20.308V20.25C20.308 20.5261 20.0842 20.75 19.808 20.75H14.2455C13.9694 20.75 13.7455 20.5261 13.7455 20.25V16.5H12.5737ZM17.0272 20.2501C17.4158 20.2501 17.7307 19.9143 17.7307 19.5001C17.7307 19.0859 17.4158 18.7501 17.0272 18.7501C16.6387 18.7501 16.3237 19.0859 16.3237 19.5001C16.3237 19.9143 16.6387 20.2501 17.0272 20.2501ZM18.9018 10.833V10.117L23.3446 10.117L22.3425 8.95625L22.7793 8.45L24.0899 9.96875L24.5268 10.475L24.0899 10.9812L22.7793 12.45L22.3425 11.9437L23.3446 10.8325L18.9018 10.833ZM10.709 15.8829L15.1518 15.8829V15.167L10.709 15.1674L11.7111 14.0562L11.2743 13.5499L9.96366 15.0187L9.52679 15.5249L9.96366 16.0312L11.2743 17.5499L11.7111 17.0437L10.709 15.8829Z"
                fill="white"/>
      </svg>
  );
}
