import { useRef } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { shared, vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useGetAccessiblePurchasePaymentMethodsQuery } from 'src/hooks/api/useGetAccessiblePurchasePaymentMethodsQuery';
import { paymentsWrapper } from 'src/navigations/Routes/Settings/Views/VendorManagement/Payments/Payment.css';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import {
  PurchasePaymentMethodsImperativeRef,
  PurchasePaymentMethodsTableContainer,
} from 'src/tables/PurchasePaymentMethodsTable/PurchasePaymentMethodsTableContainer';
import { ContentId } from 'src/utils/constants/contentId';

export const Payments = () => {
  const purchasePaymentMethodsQuery =
    useGetAccessiblePurchasePaymentMethodsQuery();

  const purchasePaymentMethods = purchasePaymentMethodsQuery.data;

  const purchasePaymentMethodsRef =
    useRef<PurchasePaymentMethodsImperativeRef | null>(null);

  return (
    <div>
      <div className={paymentsWrapper}>
        <Stack justifyContent="spaceBetween" alignItems="center">
          <div className={shared.typography.title5}>
            <Content id={ContentId.PaymentMethods} />
          </div>
          <Button
            onClick={() =>
              purchasePaymentMethodsRef.current?.onAddNewPaymentMethod()
            }
          >
            <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
            <span style={{ whiteSpace: 'nowrap' }}>
              <Content id={ContentId.AddPurchasePaymentMethod} />
            </span>
          </Button>
        </Stack>
      </div>
      {purchasePaymentMethodsQuery.isLoading ? (
        <PosSpinner />
      ) : (
        <PurchasePaymentMethodsTableContainer
          purchasePaymentMethods={purchasePaymentMethods ?? []}
          paymentMethodsQuery={purchasePaymentMethodsQuery}
          purchasePaymentMethodsImperativeRef={purchasePaymentMethodsRef}
          useNewStyles={true}
        />
      )}
    </div>
  );
};
