import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Link } from 'react-router-dom';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import { MerchantColorBadge } from 'src/components/common/MerchantColorBadge';
import { ConversationType } from 'src/components/Messages/ConversationSidePanel/ConversationListItem/ConversationListItem';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { EmailPurchaseOrderContext } from 'src/contexts/EmailPurchaseOrderContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { HotkeyDisplay } from 'src/core/POS/HotkeyDisplay';
import { vars } from 'src/core/themes';
import { Button, DropdownMenu } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { getClassifyEmailModalConfig } from 'src/modals/ClassifyEmail';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getListingDetailsRelativeUrl } from 'src/utils/inventoryUtils';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { getSaleDetailsRelativeUrl } from 'src/utils/saleUtils';
import { AutoPoClient, AutoPoInboundEmail } from 'src/WebApiController';
import { UAParser } from 'ua-parser-js';

import * as styles from '../ConversationMainPanel.css';

const HOTKEY_LETTER_MAP = {
  purchase: 'p',
  sale: 's',
  transfer: 'x',
  private: 'r',
  junk: 'j',
};

export type ConversationHeaderDisplayProps = PropsWithChildren<{
  inboundEmail?: AutoPoInboundEmail;
  conversationTitle?: string | null;
  entityId?: ReactNode;
  fromEmailAddress?: string;
  toEmailAddress?: string;
  merchantName?: string | null;
  vendorId?: number | null;
  saleId?: number;
  inventoryId?: string;
  purchaseOrderId?: number;
  showClassifyOptions?: boolean;
  onEmailClassified?: (inboundEmailId: number) => void;
}>;

export const ConversationHeaderDisplay = ({
  inboundEmail,
  conversationTitle,
  entityId,
  fromEmailAddress,
  toEmailAddress,
  merchantName,
  vendorId,
  saleId,
  inventoryId,
  purchaseOrderId,
  children,
  showClassifyOptions,
  onEmailClassified,
}: ConversationHeaderDisplayProps) => {
  const isLargeDesktop = useMatchMedia('largeDesktop');

  const { activeAccountWebClientConfig } = useAppContext();
  const { setModal } = useContext(ModalContext);
  const { setPartialPurchaseOrderKey, setInboundEmailId } = useContext(
    EmailPurchaseOrderContext
  );

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [canReprocessEmail, setCanReprocessEmail] = useState<boolean>(false);

  const [showReprocessConfirmDialog, setShowReprocessConfirmDialog] =
    useState(false);

  useEffect(() => {
    new AutoPoClient(activeAccountWebClientConfig)
      .canReprocessEmail(
        inboundEmail?.inboundEmailId!,
        inboundEmail?.purchaseOrderId
      )
      .then((canReprocess) => {
        setCanReprocessEmail(canReprocess);
      });
  }, [activeAccountWebClientConfig, inboundEmail]);

  const onClassifyPurchase = useCallback(async () => {
    setPartialPurchaseOrderKey({
      partialPurchaseOrderId: inboundEmail?.partialPurchaseOrderId,
      toEmailAddress: inboundEmail?.toEmailAddress ?? undefined,
      purchaseOrderVendorId: inboundEmail?.purchaseOrderVendorId,
    });
    setInboundEmailId(inboundEmail?.inboundEmailId);
    setModal(
      getClassifyEmailModalConfig({
        inboundEmailId: inboundEmail?.inboundEmailId!,
        conversationType: ConversationType.Purchase,
        onEmailClassified,
      })
    );
  }, [
    inboundEmail,
    onEmailClassified,
    setInboundEmailId,
    setModal,
    setPartialPurchaseOrderKey,
  ]);

  const onClassifySale = useCallback(async () => {
    setModal(
      getClassifyEmailModalConfig({
        inboundEmailId: inboundEmail?.inboundEmailId!,
        conversationType: ConversationType.Sale,
        onEmailClassified,
      })
    );
  }, [inboundEmail, onEmailClassified, setModal]);

  const onClassifyTransfer = useCallback(async () => {
    setModal(
      getClassifyEmailModalConfig({
        inboundEmailId: inboundEmail?.inboundEmailId!,
        conversationType: ConversationType.Transfer,
        onEmailClassified,
      })
    );
  }, [inboundEmail, onEmailClassified, setModal]);

  const onClassifyPrivate = useCallback(async () => {
    setModal(
      getClassifyEmailModalConfig({
        inboundEmailId: inboundEmail?.inboundEmailId!,
        conversationType: ConversationType.Private,
        onEmailClassified,
      })
    );
  }, [inboundEmail, onEmailClassified, setModal]);

  const onClassifyJunk = useCallback(async () => {
    setModal(
      getClassifyEmailModalConfig({
        inboundEmailId: inboundEmail?.inboundEmailId!,
        conversationType: ConversationType.Junk,
        onEmailClassified,
      })
    );
  }, [inboundEmail, onEmailClassified, setModal]);

  const onReprocessEmail = () => {
    setShowReprocessConfirmDialog(true);
  };
  const onConfirmReprocessEmail = async () => {
    await new AutoPoClient(activeAccountWebClientConfig).unclassifyInboundEmail(
      inboundEmail?.inboundEmailId!,
      inboundEmail?.purchaseOrderId
    );
    setShowReprocessConfirmDialog(false);
  };

  const [userAgent] = useState(() => new UAParser(navigator.userAgent));
  const hotkeyPrefixText =
    userAgent.getOS().name === 'Mac OS' || userAgent.getOS().name === 'iOS'
      ? '⌘'
      : 'ctrl';

  useHotkeys(
    `ctrl+${HOTKEY_LETTER_MAP.purchase}, meta+${HOTKEY_LETTER_MAP.purchase}`,
    () => {
      setIsDropdownOpen(false);
      onClassifyPurchase();
    },
    { preventDefault: true },
    [onClassifyPurchase]
  );
  useHotkeys(
    `ctrl+${HOTKEY_LETTER_MAP.sale}, meta+${HOTKEY_LETTER_MAP.sale}`,
    () => {
      setIsDropdownOpen(false);
      onClassifySale();
    },
    { preventDefault: true },
    [onClassifySale]
  );
  useHotkeys(
    `ctrl+${HOTKEY_LETTER_MAP.transfer}, meta+${HOTKEY_LETTER_MAP.transfer}`,
    () => {
      setIsDropdownOpen(false);
      onClassifyTransfer();
    },
    { preventDefault: true },
    [onClassifyTransfer]
  );
  useHotkeys(
    `ctrl+${HOTKEY_LETTER_MAP.private}, meta+${HOTKEY_LETTER_MAP.private}`,
    () => {
      setIsDropdownOpen(false);
      onClassifyPrivate();
    },
    { preventDefault: true },
    [onClassifyPrivate]
  );
  useHotkeys(
    `ctrl+${HOTKEY_LETTER_MAP.junk}, meta+${HOTKEY_LETTER_MAP.junk}`,
    () => {
      setIsDropdownOpen(false);
      onClassifyJunk();
    },
    { preventDefault: true },
    [onClassifyJunk]
  );

  const hasEntityId = !!saleId || !!inventoryId || !!purchaseOrderId;

  const menuContent = [];

  if (showClassifyOptions) {
    menuContent.push(
      <DropdownMenu.Item onClick={onClassifyPurchase}>
        <Content id={ContentId.MarkAsPurchase} />
        <HotkeyDisplay>
          {hotkeyPrefixText} + {HOTKEY_LETTER_MAP.purchase}
        </HotkeyDisplay>
      </DropdownMenu.Item>
    );
    menuContent.push(
      <DropdownMenu.Item onClick={onClassifySale}>
        <Content id={ContentId.MarkAsSale} />
        <HotkeyDisplay>
          {hotkeyPrefixText} + {HOTKEY_LETTER_MAP.sale}
        </HotkeyDisplay>
      </DropdownMenu.Item>
    );
    menuContent.push(
      <DropdownMenu.Item onClick={onClassifyTransfer}>
        <Content id={ContentId.MarkAsTransfer} />
        <HotkeyDisplay>
          {hotkeyPrefixText} + {HOTKEY_LETTER_MAP.transfer}
        </HotkeyDisplay>
      </DropdownMenu.Item>
    );
    menuContent.push(
      <DropdownMenu.Item onClick={onClassifyPrivate}>
        <Content id={ContentId.MarkAsPrivate} />
        <HotkeyDisplay>
          {hotkeyPrefixText} + {HOTKEY_LETTER_MAP.private}
        </HotkeyDisplay>
      </DropdownMenu.Item>
    );
    menuContent.push(
      <DropdownMenu.Item onClick={onClassifyJunk}>
        <Content id={ContentId.MarkAsJunk} />
        <HotkeyDisplay>
          {hotkeyPrefixText} + {HOTKEY_LETTER_MAP.junk}
        </HotkeyDisplay>
      </DropdownMenu.Item>
    );
  }

  if (canReprocessEmail) {
    menuContent.push(
      <DropdownMenu.Item
        onClick={onReprocessEmail}
        disabled={!!inboundEmail?.saleId}
      >
        <Content id={ContentId.ReprocessEmail} />
      </DropdownMenu.Item>
    );
  }

  return (
    <div className={styles.conversationHeader}>
      {!isLargeDesktop && (
        <div style={{ padding: `${vars.spacing['sm']} 0` }}>
          <SwiperButton dir="left" />
        </div>
      )}

      <div className={styles.topRowConatiner}>
        <div className={styles.titleContainer}>{conversationTitle}</div>
        <div className={styles.rightBarContainer}>
          {entityId && (
            <div className={styles.orderIdContainer}>
              <span>{entityId}</span>
            </div>
          )}
          <div style={{ alignSelf: 'center' }}>
            {(merchantName || vendorId) && (
              <MerchantColorBadge vendorId={vendorId} name={merchantName} />
            )}
          </div>
          {menuContent.length > 0 && (
            <div className={styles.actions}>
              <DropdownMenu.Root
                open={isDropdownOpen}
                onOpenChange={setIsDropdownOpen}
              >
                <DropdownMenu.Trigger asChild>
                  <Button variant="text" shape="inline">
                    <MoreIcon
                      withHoverEffect
                      fill={IconsFill.textBrand}
                      align="middle"
                    />
                  </Button>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content align="end">
                  {menuContent}
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>
          )}
        </div>
      </div>
      {children}
      {fromEmailAddress && (
        <div className={styles.emailContainer}>
          <span>
            <Content id={ContentId.From} />: {fromEmailAddress}
          </span>
        </div>
      )}
      {toEmailAddress && (
        <div className={styles.emailContainer}>
          <span>
            <Content id={ContentId.To} />: {toEmailAddress}
          </span>
        </div>
      )}
      {hasEntityId && (
        <div className={styles.bottomRowContainer}>
          {saleId && (
            <Link
              className={styles.link}
              to={getSaleDetailsRelativeUrl(saleId)}
            >
              <Content id={ContentId.ViewSale} />
            </Link>
          )}
          {inventoryId && (
            <Link
              className={styles.link}
              to={getListingDetailsRelativeUrl(inventoryId)}
            >
              <Content id={ContentId.ViewInventory} />
            </Link>
          )}
          {purchaseOrderId && (
            <Link
              className={styles.link}
              to={getPurchaseOrderRelativeUrl(purchaseOrderId)}
            >
              <Content id={ContentId.ViewPurchase} />
            </Link>
          )}
        </div>
      )}
      <ConfirmDialog
        isOpen={showReprocessConfirmDialog}
        headerText={<Content id={ContentId.ReprocessEmail} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        onOkay={onConfirmReprocessEmail}
        onCancel={() => setShowReprocessConfirmDialog(false)}
      />
    </div>
  );
};
