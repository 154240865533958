import { clsx } from 'clsx';
import { createPortal } from 'react-dom';
import { useUploadETicketsContext } from 'src/components/UploadArtifacts/UploadETicketsV2/UploadETicketsContext';
import { UploadETicketsPreviewTicketModalBody } from 'src/components/UploadArtifacts/UploadETicketsV2/views/PreviewModal/UploadETicketsPreviewTicketModalBody/UploadETicketsPreviewTicketModalBody';
import { useMatchMedia } from 'src/hooks/useMatchMedia';

import * as uploadETicketsStyles from '../../UploadETickets.css';
import * as styles from './UploadETicketsPreviewTicketModal.css';

export const UploadETicketsPreviewTicketModal = () => {
  const isMobile = useMatchMedia('mobile');
  const isLargeDesktop = useMatchMedia('largeDesktop');
  const { selectedUniquePageId } = useUploadETicketsContext();

  if (!selectedUniquePageId) {
    return null;
  }

  const previewModal = (
    <div
      className={clsx(
        uploadETicketsStyles.uploadETicketsSectionPanel,
        styles.mainStyles,
        {
          [styles.mobileWrapper]: isMobile,
          [styles.largeDesktopWrapper]: isLargeDesktop,
        }
      )}
      style={isLargeDesktop ? { right: 1000 } : undefined}
    >
      <UploadETicketsPreviewTicketModalBody />
    </div>
  );

  if (isLargeDesktop) {
    return <>{createPortal(previewModal, document.body)}</>;
  }

  return previewModal;
};
