import { useMutation } from '@tanstack/react-query';
import genPassword from 'browser-generate-password';
import { Marketplace, SellerAccountClient } from 'src/WebApiController';

async function handleResponse(response: Response) {
  return response.ok
    ? response
    : Promise.reject(
        new Error(`Response failure ${response.status} ${response.statusText}`)
      );
}

/**
 * https://app.ticketmaster.com/tradedeskapi/user/docs/index.html
 * @returns Mutation
 */
export function useTradeDeskSendResetPasswordCodeMutation() {
  return useMutation({
    async mutationFn({ username }: { username: string }) {
      return fetch(
        'https://app.ticketmaster.com/tradedeskapi/user/password/v2',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            username,
          },
        }
      ).then(handleResponse);
    },
  });
}

/**
 * https://app.ticketmaster.com/tradedeskapi/user/docs/index.html
 * @returns Mutation
 */
export function useTradeDeskResetPasswordMutation(activeAccountId?: string) {
  return useMutation({
    async mutationFn({
      username,
      oldPassword,
      newPassword = genPassword(10, true),
      code,
    }: {
      username: string;
      oldPassword?: string;
      /**
       * New password is automatically generated if not passed in.
       */
      newPassword?: string;
      code?: string;
    }) {
      if (!activeAccountId) {
        throw new Error('activeAccountId is undefined');
      }

      const headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('username', username);
      if (oldPassword) headers.append('old-password', oldPassword);
      if (newPassword) headers.append('new-password', newPassword);
      if (code) headers.append('code', code);
      const resp = await fetch(
        'https://app.ticketmaster.com/tradedeskapi/user/password/v2',
        {
          method: 'PUT',
          headers,
        }
      ).then(handleResponse);
      await new SellerAccountClient({
        activeAccountId,
      }).updateMarketplaceSellerAccountPassword(
        Marketplace.Ticketmaster,
        newPassword
      );
      return resp;
    },
  });
}
