import { Row } from '@tanstack/react-table';
import { sortGroupType } from 'src/modals/GroupListings/components/groupingUtils';
import { ListingWithEvent } from 'src/tables/ListingTable/ListingTable.types';
import { SaleWithEvent } from 'src/tables/SalesTable/SalesTable.type';
import {
  IListingGroupItem,
  Listing,
  ListingGroup,
  Money,
  PurchaseOrderTicketGroup,
  Seating,
  UiMoney,
} from 'src/WebApiController';

export const compareNumber = (
  numA: number | undefined | null,
  numB: number | undefined | null
) => {
  if (numA == null && numB) return 1;
  if (numA && numB == null) return -1;

  if (numA && numB) {
    if (numA !== numB) {
      return numA < numB ? -1 : 1;
    }
  }
  return 0;
};

export const localeCompareNumeric = <T>(
  rowA: Row<T | null>,
  rowB: Row<T | null>,
  colId: string
) => {
  const valueA = rowA.getValue<string>(colId);
  const valueB = rowB.getValue<string>(colId);
  if (!valueA) return valueB ? -1 : 1;
  if (!valueB) return 1;

  return valueA.localeCompare(valueB, undefined, {
    numeric: true,
  });
};

export const sortMoney = <T>(
  rowA: Row<T | null>,
  rowB: Row<T | null>,
  colId: string
) => {
  const valueA = rowA.getValue(colId) as UiMoney | Money;
  const valueB = rowB.getValue(colId) as UiMoney | Money;
  if (valueA == null) return valueB ? -1 : 1;
  if (valueB == null) return 1;

  const amtA =
    (valueA as UiMoney).amt != null
      ? (valueA as UiMoney).amt
      : (valueA as Money).amount;
  const amtB =
    (valueB as UiMoney).amt != null
      ? (valueB as UiMoney).amt
      : (valueB as Money).amount;

  return amtA - amtB;
};

const getNumericPrefix = (str: string | null) => {
  if (!str) return null;

  const match = str.match(/^\d+/);
  return match ? parseInt(match[0]) : null;
};

export const sortByNumericPrefix = <T>(
  rowA: Row<T | null>,
  rowB: Row<T | null>,
  colId: string
) => {
  const valueA = rowA.getValue(colId) as string;
  const valueB = rowB.getValue(colId) as string;
  if (!valueA) return valueB ? -1 : 1;
  if (!valueB) return 1;

  return compareByNumericPrefix(valueA, valueB);
};

export const compareByNumericPrefix = (
  strA: string | null,
  strB: string | null
) => {
  const numA = getNumericPrefix(strA);
  const numB = getNumericPrefix(strB);

  const cmpNumberResult = compareNumber(numA, numB);
  if (cmpNumberResult !== 0) {
    return cmpNumberResult;
  }

  const postfixA = strA ? strA.slice(numA ? numA.toString().length : 0) : '';
  const postfixB = strB ? strB.slice(numB ? numB.toString().length : 0) : '';

  return postfixA < postfixB ? -1 : 1;
};

export const sortGeneric = <T>(
  rowA: Row<T | null>,
  rowB: Row<T | null>,
  colId: string
) => {
  const convertToComparableValue = (value: unknown) => {
    const str = String(value ?? '')
      .trim()
      .toLowerCase();
    const strAsInt = parseInt(str);
    return !Number.isNaN(strAsInt) ? strAsInt : str;
  };

  const [a, b] = [
    convertToComparableValue(rowA.getValue(colId)),
    convertToComparableValue(rowB.getValue(colId)),
  ];

  if (a === b) return 0;
  if (a === '') return 1;
  if (b === '') return -1;
  if (typeof a === 'number' && typeof b !== 'number') return -1;
  if (typeof b === 'number' && typeof a !== 'number') return 1;
  return a < b ? -1 : 1;
};

export const sortListingSeating = (
  rowA: Row<ListingWithEvent | null>,
  rowB: Row<ListingWithEvent | null>,
  colId: string
) => {
  if (rowA.original?.isLtGrp && rowB.original?.listing?.isLtGrp) {
    return sortGroupType(
      (rowA.original.listing as ListingGroup).groupType,
      (rowB.original.listing as ListingGroup).groupType
    );
  } else if (rowA.original?.isLtGrp || rowB.original?.listing?.isLtGrp) {
    return rowA.original?.isLtGrp ? 1 : -1; // listing group sort first
  }

  return sortSeatingForTable(rowA, rowB, colId);
};

export const sortListingSeatingSection = (
  rowA: Row<ListingWithEvent | null>,
  rowB: Row<ListingWithEvent | null>,
  colId: string
) => {
  if (rowA.original?.isLtGrp && rowB.original?.listing?.isLtGrp) {
    return sortGroupType(
      (rowA.original.listing as ListingGroup).groupType,
      (rowB.original.listing as ListingGroup).groupType
    );
  } else if (rowA.original?.isLtGrp || rowB.original?.listing?.isLtGrp) {
    return rowA.original?.isLtGrp ? 1 : -1; // listing group sort first
  }

  return sortSeatingSection(
    rowA.original?.listing?.seating,
    rowB.original?.listing?.seating
  );
};

export const sortPurchaseOrderTicketGroupSeating = (
  rowA: Row<PurchaseOrderTicketGroup | null>,
  rowB: Row<PurchaseOrderTicketGroup | null>,
  colId: string
) => {
  if (!rowA?.original || !rowB?.original) {
    return 0;
  }

  const rowATicketGroupToGetSeating = rowA.original.isTktGrp
    ? rowA.original
    : rowA.original.ticketGroupItems?.[0];

  const rowBTicketGroupToGetSeating = rowB.original.isTktGrp
    ? rowB.original
    : rowB.original.ticketGroupItems?.[0];

  if (rowATicketGroupToGetSeating?.seating == null) {
    return -1;
  }

  if (rowBTicketGroupToGetSeating?.seating == null) {
    return -1;
  }

  return sortSeating(
    rowATicketGroupToGetSeating.seating,
    rowBTicketGroupToGetSeating.seating
  );
};

export const sortSalesSeating = (
  rowA: Row<SaleWithEvent | null>,
  rowB: Row<SaleWithEvent | null>,
  colId: string
) => {
  if (!rowA?.original || !rowB?.original) {
    return 0;
  }

  return sortSeating(rowA.original.sale.seating, rowB.original.sale.seating);
};

export const sortSalesSeatingSection = (
  rowA: Row<SaleWithEvent | null>,
  rowB: Row<SaleWithEvent | null>,
  colId: string
) => {
  if (!rowA?.original || !rowB?.original) {
    return 0;
  }

  return sortSeatingSection(
    rowA.original.sale.seating,
    rowB.original.sale.seating
  );
};

export const sortSeatingForTable = <T>(
  rowA: Row<T | null>,
  rowB: Row<T | null>,
  colId: string
) => {
  const valueA = rowA.getValue(colId);
  const valueB = rowB.getValue(colId);
  if (!valueA) return valueB ? -1 : 1;
  if (!valueB) return 1;

  const { seating: a } = valueA as {
    seating: Seating;
  };
  const { seating: b } = valueB as {
    seating: Seating;
  };

  return sortSeating(a, b);
};

export const sortSeating = (a: Seating, b: Seating) => {
  if (a.section !== b.section) {
    return compareByNumericPrefix(a.section, b.section);
  } else {
    if (a.row !== b.row) {
      return compareByNumericPrefix(a.row, b.row);
    }

    const seatFromA = a.seatFr ?? '';
    const seatFromB = b.seatFr ?? '';
    const seatFromAInt = parseInt(seatFromA);
    const seatFromBInt = parseInt(seatFromB);
    if (!isNaN(seatFromAInt) && !isNaN(seatFromBInt)) {
      return seatFromAInt < seatFromBInt ? -1 : 1;
    }
    return seatFromA < seatFromB ? -1 : 1;
  }
};

export const sortPurchaseOrderSeatingSection = (
  rowA: Row<PurchaseOrderTicketGroup | null>,
  rowB: Row<PurchaseOrderTicketGroup | null>,
  colId: string
) => {
  if (!rowA?.original || !rowB?.original) {
    return 0;
  }

  const rowATicketGroupToGetSeating = rowA.original.isTktGrp
    ? rowA.original
    : rowA.original.ticketGroupItems?.[0];

  const rowBTicketGroupToGetSeating = rowB.original.isTktGrp
    ? rowB.original
    : rowB.original.ticketGroupItems?.[0];

  if (rowATicketGroupToGetSeating?.seating == null) {
    return -1;
  }

  if (rowBTicketGroupToGetSeating?.seating == null) {
    return -1;
  }

  return sortSeatingSection(
    rowATicketGroupToGetSeating.seating,
    rowBTicketGroupToGetSeating.seating
  );
};

export const sortSeatingSection = <T>(
  seatingA: Seating | undefined,
  seatingB: Seating | undefined
) => {
  if (!seatingA) return -1;
  if (!seatingB) return 1;

  return compareByNumericPrefix(seatingA.section, seatingB.section);
};

export const safeSortDateTime = <T>(
  rowA: Row<T | null>,
  rowB: Row<T | null>,
  colId: string
) => {
  // Convert both null and empty string to 0 timestamp
  const valueA = rowA.getValue<Date | string | number>(colId) || 0;
  const valueB = rowB.getValue<Date | string | number>(colId) || 0;

  const timestampA = new Date(valueA).getTime();
  const timestampB = new Date(valueB).getTime();
  if (!isNaN(timestampA) && !isNaN(timestampB)) {
    return timestampA - timestampB;
  }
  return isNaN(timestampA) ? 1 : -1;
};

export enum GridActionType {
  Edit,
  Delete,
  Select,
  Share,
  Duplicate,
  History,
}

export const getNextEditableListingRow = (
  rows: ListingWithEvent[],
  currentRow: ListingWithEvent
): ListingWithEvent | undefined => {
  const currentIndex = rows.findIndex(
    (row) => row.listing?.id === currentRow.listing?.id
  );
  for (let index = currentIndex + 1; index < rows.length; index++) {
    const row = rows[index];
    if (!row.isCompListing && !row.isLtGrp) {
      return row;
    }
  }
  return undefined;
};

const sortLstingInGroup = (items: IListingGroupItem[]) => {
  return items.sort((a, b) => {
    if (a.isLtGrp && b.isLtGrp) {
      return sortGroupType(
        (a as ListingGroup).groupType,
        (b as ListingGroup).groupType
      );
    }
    if (a.isLtGrp || b.isLtGrp) {
      return a.isLtGrp ? -1 : 1; // listing group sort first
    }
    if (a.ltGrpPrior && b.ltGrpPrior) {
      return a.ltGrpPrior - b.ltGrpPrior;
    }
    // Sold listings will have ltGrpPrior cleaned and we fallback to sortby seating
    if (a.ltGrpPrior && b.ltGrpPrior) {
      return a.ltGrpPrior ? -1 : 1;
    }
    return sortSeating(a.seating, b.seating);
  });
};

export const sortGroupedListing = (listing: Listing) => {
  if (!listing.isLtGrp) {
    return;
  }
  (listing as ListingGroup).groupItems = sortLstingInGroup(
    (listing as ListingGroup).groupItems
  );
  for (const group of (listing as ListingGroup).groupItems) {
    sortGroupedListing(group as Listing);
  }
};
