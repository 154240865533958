import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ResetIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M9.1 12l1.519 1.49-2.496 2.446h7.162c1.193 0 2.17-.957 2.17-2.127v-.852h2.17v.852c0 2.34-1.953 4.255-4.34 4.255H8.123l2.496 2.447L9.099 22 4 17l5.1-5zm6.05-10l5.1 5-5.1 5-1.519-1.49 2.496-2.446H8.965c-1.193 0-2.17.957-2.17 2.127v.852h-2.17v-.852c0-2.34 1.953-4.255 4.34-4.255h7.162L13.63 3.49 15.151 2z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
