import { sizing } from 'src/core/themes/themeContract.css';

import { TypographyVariantType } from '../../theme-types';

export enum ScreenVariant {
  sm = 'sm',
  lg = 'lg',
}

export const fontWeightConfig = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export type TypographyStyling = {
  fontSize: string;
  defaultfontWeight: string | number;
  lineHeight: string;
  letterSpacing?: string;
};

export const VariantConfig: Record<
  TypographyVariantType,
  Record<ScreenVariant, TypographyStyling>
> = {
  title1: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['5xl'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '56px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['4xl'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '48px',
    },
  },
  title2: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['4xl'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '48px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['3xl'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '40px',
    },
  },
  title3: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['3xl'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '40px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['2xl'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '40px',
    },
  },
  title4: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['2xl'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '32px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['xl'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '32px',
    },
  },
  title5: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['xl'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '28px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['lg'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '24px',
    },
  },
  title6: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['lg'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '24px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['base'],
      defaultfontWeight: fontWeightConfig.bold,
      lineHeight: '24px',
    },
  },
  subtitle1: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['base'],
      defaultfontWeight: fontWeightConfig.medium,
      lineHeight: '24px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['base'],
      defaultfontWeight: fontWeightConfig.medium,
      lineHeight: '24px',
    },
  },
  subtitle2: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['sm'],
      defaultfontWeight: fontWeightConfig.medium,
      lineHeight: '20px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['lg'],
      defaultfontWeight: fontWeightConfig.medium,
      lineHeight: '20px',
    },
  },
  body1: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['base'],
      defaultfontWeight: fontWeightConfig.regular,
      lineHeight: '24px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['base'],
      defaultfontWeight: fontWeightConfig.regular,
      lineHeight: '24px',
    },
  },
  body2: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['sm'],
      defaultfontWeight: fontWeightConfig.regular,
      lineHeight: '20px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['sm'],
      defaultfontWeight: fontWeightConfig.regular,
      lineHeight: '20px',
    },
  },
  caption1: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['xs'],
      defaultfontWeight: fontWeightConfig.medium,
      lineHeight: '16px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['xs'],
      defaultfontWeight: fontWeightConfig.medium,
      lineHeight: '16px',
    },
  },
  caption2: {
    [ScreenVariant.lg]: {
      fontSize: sizing.typography.fontSize['xxs'],
      defaultfontWeight: fontWeightConfig.medium,
      lineHeight: '16px',
    },
    [ScreenVariant.sm]: {
      fontSize: sizing.typography.fontSize['xxs'],
      defaultfontWeight: fontWeightConfig.medium,
      lineHeight: '16px',
    },
  },
};
