import { ReportConfig } from 'src/hooks/useReportConfigs';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { ReportFileType } from 'src/WebApiController';

export type ReportExportOptions = {
  reportToExport: ReportConfig;
  fileType: ReportFileType;
  serverSideExport?: boolean;
};

export type ReportExportOptionsV2 = {
  reportToExport: ReportConfigV2;
  fileType: ReportFileType.CSV | ReportFileType.XLSX;
};

export enum AccessorReturnType {
  STRING = 'string',
  SELLER_USER_ID = 'sellerUserId',
  PURCHASE_BUYER_INFOS = 'purchaseBuyerInfos',
}

/**
 * Values from the table don't always match the values we want to export
 * e.g. some cells return sellerUserId, but we want to export the seller name
 * this type is used to return the value and the type of the value
 * so that the export function can resolve the value correctly
 */
export type AccessorReturn<T> = {
  stringValueType: AccessorReturnType;
  /**
   * String values used for ReportsExport to resolve the real value
   * If undefined, it means that the value is already a string and we can use it for resolving directly
   */
  stringValues?: string[] | string | null | undefined;
  value: T;
};
