import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { BackSection } from 'src/components/common/BackSection';
import { ReportsInventoryExport } from 'src/components/Reports/ReportsExport';
import { ReportExportOptions } from 'src/components/Reports/ReportsExport/ReportsExport.types';
import { ReportsSaleExport } from 'src/components/Reports/ReportsExport/ReportsSaleExport';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useReportMetricsContext } from 'src/contexts/ReportMetricsContext';
import { vars } from 'src/core/themes';
import { DropdownMenu, Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { ShareReportDialog } from 'src/dialogs/ShareReportDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ReportConfig, useReportConfigs } from 'src/hooks/useReportConfigs';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CopyIcon } from 'src/svgs';
import {
  DownloadIcon,
  IconsFill,
  MoreIcon,
  ProcessingIcon,
  ShareIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { isReportFilterOverriden, ReportTypes } from 'src/utils/reportsUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  EntityWithTicketsQuery,
  Feature,
  Permission,
  ReportFileType,
  ReportGroupBy,
} from 'src/WebApiController';

import * as styles from './ReportsPageToolbar.css';

export const ReportsPageToolbar = ({
  reportConfig,
  returnUrl,
}: {
  reportConfig: ReportConfig | undefined;
  returnUrl: string;
}) => {
  const shareDialog = useBasicDialog();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { filterQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();
  const { isAllReportMetricsLoaded, totalCount } = useReportMetricsContext();

  const hasExportInventoryFeature = useUserHasFeature(Feature.ExportInventory);
  const hasCreateReportFeature = useUserHasAnyOfPermissions(
    Permission.Reports_CreateReports
  );

  const [reportExportOptions, setReportExportOptions] =
    useState<ReportExportOptions | null>(null);

  const onReportExported = () => {
    setReportExportOptions(null);
  };

  const reportConfigWithTempQuery = useMemo(() => {
    if (!reportConfig) {
      return reportConfig;
    }
    return {
      ...reportConfig,
      filter: filterQuery,
      hasFilterOverride:
        reportConfig.hasFilterOverride ||
        isReportFilterOverriden(filterQuery, reportConfig.filter),
    } as ReportConfig;
  }, [filterQuery, reportConfig]);

  const {
    upsertReportConfig: upsertReportConfigInventory,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateInventory,
  } = useReportConfigs<ReportTypes.Inventory>({
    reportType: ReportTypes.Inventory,
  });

  const {
    upsertReportConfig: upsertReportConfigSale,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateSale,
  } = useReportConfigs<ReportTypes.Sale>({
    reportType: ReportTypes.Sale,
  });

  const invokeUpsertReport = useCallback(
    async (report: ReportConfig) => {
      return await tryInvokeApi(
        async () => {
          if (report.reportType === ReportTypes.Inventory) {
            return await upsertReportConfigInventory(report.reportId, report);
          } else {
            // report.reportType === ReportTypes.Sale
            return await upsertReportConfigSale(report.reportId, report);
          }
        },
        (error) => {
          showErrorDialog('ReportClient.mergeReport', error, {
            trackErrorData: report,
          });
        }
      );
    },
    [showErrorDialog, upsertReportConfigInventory, upsertReportConfigSale]
  );

  const onReportShareSubmit = useCallback<
    ComponentProps<typeof ShareReportDialog>['onOkay']
  >(
    async (roleIds, sellerUserIds) => {
      const updatedReport = {
        ...reportConfig!,
        roleIdsToShare: roleIds,
        sellerUserIdsToShare: sellerUserIds,
      };
      await invokeUpsertReport(updatedReport);
      shareDialog.closeDialog();
    },
    [reportConfig, invokeUpsertReport, shareDialog]
  );

  const onReportDuplicateSubmit = useCallback(async () => {
    const newReport = {
      ...reportConfigWithTempQuery!,
      reportId: null,
      reportName:
        reportConfig!.reportType === ReportTypes.Inventory
          ? getReportConfigNameForDuplicateInventory(reportConfig!.reportName)
          : getReportConfigNameForDuplicateSale(reportConfig!.reportName),
    } as ReportConfig;
    const newReportId = await invokeUpsertReport(newReport);
    if (newReportId) {
      if (reportConfig!.reportType === ReportTypes.Inventory) {
        window.open(
          `/reports/inventory/${newReportId}`,
          '_blank',
          'noreferrer'
        );
      } else {
        // report.reportType === ReportTypes.Sale
        window.open(`/reports/sale/${newReportId}`, '_blank', 'noreferrer');
      }
    }
  }, [
    reportConfigWithTempQuery,
    reportConfig,
    getReportConfigNameForDuplicateInventory,
    getReportConfigNameForDuplicateSale,
    invokeUpsertReport,
  ]);

  return (
    <>
      <div className={styles.backSectionContainer}>
        <BackSection returnUrl={returnUrl} />
        <Stack gap="l">
          {reportConfig && (
            <>
              {reportExportOptions ? (
                <RotatingWrapper>
                  <ProcessingIcon
                    fill={IconsFill.textBrand}
                    size={vars.iconSize.l}
                  />
                </RotatingWrapper>
              ) : (
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger asChild>
                    <div
                      style={{
                        padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}`,
                      }}
                    >
                      <MoreIcon
                        withHoverEffect
                        fill={IconsFill.textBrand}
                        align="middle"
                      />
                    </div>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Portal>
                    <DropdownMenu.Content align="end">
                      {reportConfig.isOwner && (
                        <>
                          <DropdownMenu.Item
                            key="ShareReport"
                            onClick={shareDialog.launchDialog}
                          >
                            <Stack alignItems="center" gap="m" width="full">
                              <ShareIcon
                                withHoverEffect
                                fill={IconsFill.textBrand}
                                size={vars.iconSize.m}
                              />
                              <Content id={ContentId.Share} />
                            </Stack>
                          </DropdownMenu.Item>
                        </>
                      )}
                      {(hasExportInventoryFeature ||
                        reportConfig.groupBy !== ReportGroupBy.ListingId) && (
                        <DropdownMenu.Group>
                          <DropdownMenu.Label key="DownloadReport">
                            <Stack alignItems="center" gap="m" width="full">
                              <DownloadIcon
                                withHoverEffect
                                fill={IconsFill.textBrand}
                                size={vars.iconSize.m}
                              />
                              <Content id={ContentId.Download} />
                            </Stack>
                          </DropdownMenu.Label>
                          <DropdownMenu.Item
                            key="DownloadExcel"
                            onClick={() =>
                              setReportExportOptions({
                                reportToExport: reportConfigWithTempQuery!,
                                fileType: ReportFileType.XLSX,
                                serverSideExport: !isAllReportMetricsLoaded,
                              })
                            }
                          >
                            <Content id={ContentId.MicrosoftExcelExt} />
                          </DropdownMenu.Item>
                          <DropdownMenu.Item
                            key="DownloadCsv"
                            onClick={() =>
                              setReportExportOptions({
                                reportToExport: reportConfigWithTempQuery!,
                                fileType: ReportFileType.CSV,
                                serverSideExport: !isAllReportMetricsLoaded,
                              })
                            }
                          >
                            <Content id={ContentId.CommaSeparatedValuesExt} />
                          </DropdownMenu.Item>
                          {isAllReportMetricsLoaded && (
                            // We don't support server side PDF export so we hide it for now
                            // Need to be determined on https://thestubhub.atlassian.net/browse/POS-4291 on how we want to handle this
                            <DropdownMenu.Item
                              key="DownloadPdf"
                              onClick={() =>
                                setReportExportOptions({
                                  reportToExport: reportConfigWithTempQuery!,
                                  fileType: ReportFileType.PDF,
                                })
                              }
                            >
                              <Content id={ContentId.PdfExt} />
                            </DropdownMenu.Item>
                          )}

                          <DropdownMenu.Separator />
                        </DropdownMenu.Group>
                      )}
                      {hasCreateReportFeature &&
                        reportConfig.groupBy !==
                          ReportGroupBy.SaleIdTicketIdCommissionUser &&
                        reportConfig.groupBy !==
                          ReportGroupBy.ArAgingMarketplace && (
                          <DropdownMenu.Item
                            key="Duplicate"
                            onClick={onReportDuplicateSubmit}
                          >
                            <Stack alignItems="center" gap="m" width="full">
                              <CopyIcon
                                withHoverEffect
                                fill={vars.color.textBrand}
                                size={vars.iconSize.l}
                              />
                              <Content id={ContentId.Duplicate} />
                            </Stack>
                          </DropdownMenu.Item>
                        )}
                    </DropdownMenu.Content>
                  </DropdownMenu.Portal>
                </DropdownMenu.Root>
              )}
            </>
          )}
        </Stack>
      </div>
      <ShareReportDialog
        {...shareDialog.dialogProps}
        reportOwnerName={reportConfig?.ownerDisplayName ?? ''}
        reportName={reportConfig?.reportName ?? ''}
        roleIdsShared={reportConfig?.roleIdsToShare ?? []}
        sellerUserIdsShared={reportConfig?.sellerUserIdsToShare ?? []}
        onOkay={onReportShareSubmit}
        onClosed={() => {
          shareDialog.closeDialog();
        }}
        centered
      />
      {reportConfig && (
        <>
          {reportConfig.reportType === ReportTypes.Inventory ? (
            <ReportsInventoryExport
              reportExportOptions={reportExportOptions}
              onReportExported={onReportExported}
            />
          ) : (
            <ReportsSaleExport
              reportExportOptions={reportExportOptions}
              onReportExported={onReportExported}
            />
          )}
        </>
      )}
    </>
  );
};
