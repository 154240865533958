import { useCallback, useEffect, useMemo } from 'react';
import { translateEventToInventoryAccordionItem } from 'src/components/Accordions';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { BulkEditHubContextProvider } from 'src/contexts/BulkEditHubContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { CollapsableViewProvider } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  FilterQueryContextProvider,
  useFilterQueryContext,
} from 'src/contexts/FilterQueryContext';
import { MultiSelectionContextProvider } from 'src/contexts/MultiSelectionContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import {
  getCatalogData as getCatalogDataForListing,
  getCatalogDataExpanded,
} from 'src/navigations/Routes/Inventory';
import { INVENTORY_USER_SETTINGS } from 'src/navigations/Routes/InventoryEvent/constants';
import { NoData } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  DefaultListingQuery,
  EmptyListingQuery,
} from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { ListingCatalogQueryKey } from 'src/utils/inventoryUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  ActionOutboxEntityType,
  ListingClient,
  ListingDetails,
  ListingQuery,
  UserSetting,
} from 'src/WebApiController';

import * as styles from '../../Events/EventPage/EventPage.css';
import { useAccordionItem } from '../../Events/EventPage/hooks/useAccordionItem';
import { SidePanelToolbar } from '../../Events/EventPage/SidePanel/SidePanelToolbar';
import { ListingSideTableFilterBar } from '../ListingFilterBar/ListingSideTableFilterBar';

const Inventory = ({
  viagVirtualId,
  queryInput,
}: {
  viagVirtualId: string;
  queryInput?: Partial<Pick<ListingQuery, 'purchaseOrderIds' | 'saleIds'>>;
}) => {
  const { isLoading, isItemsLoading, accordionItemProps } =
    useAccordionItem(viagVirtualId);

  const { setFilterQuery } = useFilterQueryContext<ListingQuery>();

  useEffect(() => {
    setFilterQuery({
      ...DefaultListingQuery,
      ...queryInput,
      eventOrMappingIds: [viagVirtualId],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryInput, setFilterQuery]);

  const inventoryAccordionItem = useMemo(
    () =>
      accordionItemProps &&
      translateEventToInventoryAccordionItem(accordionItemProps, 0, true),
    [accordionItemProps]
  );

  const { BodyComponent, AfterHeaderComponent } = inventoryAccordionItem || {};

  const listingIds = inventoryAccordionItem?.listings?.map((l) => l.id) || [];

  const noData =
    (!isLoading && !inventoryAccordionItem) ||
    (!isItemsLoading && !accordionItemProps?.listings);

  const hasSelectedEntities =
    (queryInput?.saleIds && queryInput?.saleIds?.length > 0) ||
    (queryInput?.purchaseOrderIds && queryInput?.purchaseOrderIds?.length > 0);

  return (
    <>
      <div className={styles.sidePanelToolbar}>
        <SidePanelToolbar
          sectionType={SectionType.InventorySideTable}
          filterBar={<ListingSideTableFilterBar />}
          noData={noData}
          entityIds={hasSelectedEntities ? listingIds : undefined}
        />
        {!noData && AfterHeaderComponent && <AfterHeaderComponent />}
      </div>
      {isLoading && <PosSpinner />}
      {noData ? (
        <NoData>
          <FormatContent
            id={FormatContentId.NoDataAvailable}
            params={<Content id={ContentId.Listings} />}
          />
        </NoData>
      ) : (
        BodyComponent &&
        accordionItemProps && (
          <BodyComponent
            {...accordionItemProps}
            isSideTable={true}
            useTableNavKeys={true}
            disablePagination={true}
            withOuterPadding={false}
          />
        )
      )}
    </>
  );
};

export const InventorySideTable = ({
  viagVirtualId,
  queryInput,
}: {
  viagVirtualId: string;
  queryInput?: Partial<Pick<ListingQuery, 'purchaseOrderIds' | 'saleIds'>>;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], filterQuery: ListingQuery) =>
      getCatalogDataExpanded(ids, filterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('ListingClient.getListingsForEvents', error, {
            ...filterQuery,
            eventIds: ids,
          });
        },
      }),
    [activeAccountWebClientConfig, trackError]
  );

  const getActivePosEntity = useCallback(
    async (listingId: number) => {
      if (activeAccountWebClientConfig.activeAccountId) {
        const listingDetail = await new ListingClient(
          activeAccountWebClientConfig
        ).getListingByListingId(listingId);

        if (listingDetail) {
          return {
            posEntityId: listingDetail.id,
            posEntity: listingDetail,
            posEntityDisplayId: listingDetail.idOnMkp,
          };
        }
      }
      return {};
    },
    [activeAccountWebClientConfig]
  );

  return (
    <BulkEditHubContextProvider>
      <SellerUserSettingsProvider
        initialUserSettingIds={INVENTORY_USER_SETTINGS}
        currentLoginUserOnly={true}
      >
        <FilterQueryContextProvider<ListingQuery>
          initialQuery={{
            ...DefaultListingQuery,
            eventOrMappingIds: [viagVirtualId],
          }}
          emptyQuery={EmptyListingQuery}
          viewModeSettingId={UserSetting.InventoryPageViewMode}
          loadQueryFromUrl={false}
        >
          <CatalogDataContextProvider<ListingQuery>
            queryKey={ListingCatalogQueryKey}
            getCatalogData={(c, f) => getCatalogDataForListing(c, f, true)}
            getCatalogDataExpanded={getCatalogDataExpandedCallback}
            transformEventData={transformData}
          >
            <CatalogMultiSelectionContextProvider type={'listing'}>
              <MultiSelectionContextProvider>
                <ActivePosEntityProvider<ListingDetails>
                  entityType={ActionOutboxEntityType.Listing}
                  getActivePosEntity={getActivePosEntity}
                >
                  <DialogProvider>
                    <CollapsableViewProvider isEnabled={true}>
                      <Inventory
                        viagVirtualId={viagVirtualId}
                        queryInput={queryInput}
                      />
                    </CollapsableViewProvider>
                  </DialogProvider>
                </ActivePosEntityProvider>
              </MultiSelectionContextProvider>
            </CatalogMultiSelectionContextProvider>
          </CatalogDataContextProvider>
        </FilterQueryContextProvider>
      </SellerUserSettingsProvider>
    </BulkEditHubContextProvider>
  );
};
