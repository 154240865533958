import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Detail, DetailGroup } from 'src/modals/common';
import { MarketplacePaymentLines } from 'src/modals/SaleDetails/components/MarketplacePaymentSection/MarketplacePaymentLines';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
  SALE_PAYMENT_STATE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { Feature, SaleDetails } from 'src/WebApiController';

import * as styles from './MarketplacePaymentSection.css';

export const MarketplacePaymentSection = ({
  sale,
}: {
  sale?: SaleDetails | null;
}) => {
  const hasMarketplacePaymentLinesDisplayFeature = useUserHasFeature(
    Feature.MarketplacePaymentLinesDisplay
  );
  const hasManageMarketplacePaymentFeature = useUserHasFeature(
    Feature.ManageMarketplacePayment
  );

  const canViewMarketplacePaymentLines =
    hasMarketplacePaymentLinesDisplayFeature ||
    hasManageMarketplacePaymentFeature;

  return (
    <Stack direction="column" gap="l">
      <DetailGroup>
        <Detail
          label={<Content id={ContentId.PaymentState} />}
          detail={
            <span className={styles.emphasizedText}>
              {sale?.mkpPmtState ? (
                <Content
                  id={MARKETPLACE_SALE_PAYMENT_STATE_TO_CID[sale.mkpPmtState]}
                />
              ) : sale?.pmtState ? (
                <Content id={SALE_PAYMENT_STATE_TO_CID[sale.pmtState]} />
              ) : null}
            </span>
          }
        />
      </DetailGroup>
      {canViewMarketplacePaymentLines && sale && (
        <MarketplacePaymentLines
          sale={sale}
          canManageMarketplacePayment={hasManageMarketplacePaymentFeature}
        />
      )}
    </Stack>
  );
};
