import { useCallback } from 'react';
import { SelectAllHotkeys } from 'src/components/common/MultiSelect/Toggle/contants';
import { MultiSelectionToggle } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggle';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { PosHotkeys } from 'src/core/POS/PosHotkeys';

export const MultiSelectionToggleGlobal = () => {
  const { selectionMode, setSelectionMode } = useMultiSelectionContext();

  const onMultiSelectListingClick = useCallback(() => {
    setSelectionMode(
      selectionMode?.mode !== MultiSelectScope.AllGroups
        ? { mode: MultiSelectScope.AllGroups }
        : undefined
    );
  }, [selectionMode, setSelectionMode]);

  const isChecked = selectionMode?.mode === MultiSelectScope.AllGroups;

  return (
    <PosHotkeys
      keyNames={SelectAllHotkeys}
      onHotkeyActivated={onMultiSelectListingClick}
    >
      <MultiSelectionToggle
        onChange={onMultiSelectListingClick}
        isToggled={isChecked}
      />
    </PosHotkeys>
  );
};
