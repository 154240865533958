import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  Feature,
  SellerAccountClient,
  TimezoneInfo,
} from 'src/WebApiController';

import { useUserHasFeature } from '../useUserHasFeature';

export const useCustomTimeZoneSettings = () => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const hasTimeZoneFeature = useUserHasFeature(Feature.POSTimezone);

  const queryClient = useQueryClient();
  const queryKey = [
    'getCustomTimeZoneSettings',
    activeAccountWebClientConfig.activeAccountId,
  ];

  const shouldQuery =
    hasTimeZoneFeature && activeAccountWebClientConfig.activeAccountId != null;
  const query = useQuery({
    queryKey,
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new SellerAccountClient(
        activeAccountWebClientConfig
      ).getCustomTimeZoneSettings();
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('SellerAccountClient.getCustomTimeZoneSettings', error);
      },
    },
  });

  const mutation = useMutation({
    mutationFn: async ({ input }: { input: TimezoneInfo | undefined }) => {
      return await new SellerAccountClient(
        activeAccountWebClientConfig
      ).mergeCustomTimeZoneSettings(input);
    },
    // optimistic ui
    onMutate: ({ input }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, input);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { input }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('SellerAccountClient.mergeCustomTimeZoneSettings', err, {
        trackErrorData: { input },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    customTimeZoneSettings: query.data,
    isLoading: query.isLoading,
    mergeCustomTimeZoneSettings: mutation.mutateAsync,
  };
};
