import { useCallback, useEffect, useMemo, useState } from 'react';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { CheckIcon, LoadingIcon } from 'src/svgs/Viagogo';

import * as styles from './LoadingButton.css';

const FadeAnimationDuration = 500;
const GracePeriod = 1_500;

type LoadingButtonProps = {
  isLoading: boolean;
  isCompleted: boolean;
  title?: string;
  onClick?: () => Promise<void>;
};

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  isLoading,
  isCompleted,
  title,
  onClick,
}) => {
  const [animationStyle, setAnimationStyle] = useState<string | null>();

  // Grace period to allow fade out
  const clearAnimation = useCallback(() => {
    setTimeout(() => setAnimationStyle(null), FadeAnimationDuration);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeout: any = null;
    if (isCompleted) {
      setAnimationStyle(styles.checkBoxFadeIn);
      timeout = setTimeout(() => {
        setAnimationStyle(styles.checkBoxFadeOut);
        clearAnimation();
      }, GracePeriod);
    }

    return () => timeout && clearTimeout(timeout);
  }, [clearAnimation, isCompleted]);

  const icon = useMemo(() => {
    if (isLoading || animationStyle) {
      return <LoadingIcon className={styles.spinnerCircle} />;
    }
    return <CheckIcon fill={vars.color.backgroundBrandActive} />;
  }, [animationStyle, isLoading]);

  return (
    <Button
      variant="outline"
      size="md"
      disabled={!!animationStyle || isLoading}
      onClick={onClick}
      title={title}
    >
      {icon}
    </Button>
  );
};
