import {useQuery} from '@tanstack/react-query';
import {useAppContext} from 'src/contexts/AppContext';
import {ErrorTypes, useErrorBoundaryContext,} from 'src/contexts/ErrorBoundaryContext';
import {FulfillmentProviderClient} from 'src/WebApiController';

export const useGetFulfillmentProviderSettings = () => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const shouldQuery =
      activeAccountWebClientConfig.activeAccountId != null;
  const fulfillmentProviderSettingsQuery = useQuery({
    queryKey: [
      'FulfillmentProviderClient.getFulfillmentProviderSettings',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      return await new FulfillmentProviderClient(
          activeAccountWebClientConfig
      ).getFulfillmentProviderSettings();
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('FulfillmentProviderClient.getFulfillmentProviderSettings', error, {
          trackErrorData: {
            activeAccountId: activeAccountWebClientConfig.activeAccountId,
          },
        });
      },
    },
  });

  return {
    fulfillmentProviderSettings: fulfillmentProviderSettingsQuery.data,
    fulfillmentProviderSettingsQuery
  };
};
