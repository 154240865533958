import { UploadETicketsDraggableSource } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Draggable/constants';
import * as styles from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/basePageDocument.css';
import {
  MultiplePageDocumentPage,
  MultiplePageDocumentPageProps,
} from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/MultiplePageDocument/MultiplePageDocumentPage';
import {
  UploadETicketsDraggableData,
  UploadETicketsDraggableOrigin,
} from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/UploadETicketsDraggableData';
import { PosDraggable } from 'src/core/POS/PosDragNDrop';

export const MultipleDraggableDocumentPage = (
  props: MultiplePageDocumentPageProps
) => {
  return (
    <PosDraggable<UploadETicketsDraggableData>
      className={styles.dragContainer}
      draggableSource={UploadETicketsDraggableSource}
      draggableData={{
        isSinglePageDocument: false,
        origin: UploadETicketsDraggableOrigin.TicketCard,
        componentProps: props,
      }}
    >
      <MultiplePageDocumentPage {...props} />
    </PosDraggable>
  );
};
