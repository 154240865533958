import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCatalogMultiSelectionContext } from 'src/contexts/CatalogMultiSelectionContext';
import {
  EventSessionRole,
  useEventHubContext,
} from 'src/contexts/EventHubContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { SectionInfo } from 'src/WebApiController';

type StubHubListingsManualPricingContextType = {
  onQuantityFilterChanged: (val: number) => void;
  onSectionZoneFilterChanged: (
    sections: SectionInfo[],
    flipOnSameSections?: boolean
  ) => void;
  onSectionFilterChanged: (section: SectionInfo) => void;
  onRowFiltersChanged: (
    rowIdFiltersNew: number[],
    sectionRowIdFiltersNew: { [key: string]: string }
  ) => void;
  resetFilters: () => void;
  quantityFilter: number;
  selectedSections: SectionInfo[];
  sectionIdFilters: number[];
  rowIdFilters: number[];
  sectionRowIdFilters: {
    [key: string]: string;
  };
  hasActiveFilters: boolean;
};

/**
 * Context for managing the state of the manual pricing filters. Provides components with callbacks to invoke
 * setState functions and also returns the values of the state functions
 *
 * @context
 * @description Provides a context for managing the state of the manual pricing filters
 *
 * @param onQuantityFilterChanged - Callback function to update the quantity filter when it changes.
 * @param onSectionZoneFilterChanged - Callback function to update the section zone filter when it changes.
 * @param onSectionFilterChanged - Callback function to update the section filter when it changes.
 * @param onRowFiltersChanged - Callback function to update the row filters when they change.
 * @param resetFilters - Function to reset all filters to their initial state.
 * @param quantityFilter - The current value of the quantity filter.
 * @param selectedSections - An array of SectionInfo objects representing the currently selected sections.
 * @param sectionIdFilters - An array of numbers representing the section ID filters.
 * @param rowIdFilters - An array of numbers representing the row ID filters.
 * @param sectionRowIdFilters - An object where the keys are section IDs and the values are strings representing the max row ID filters for each section.
 *                              We need to keep track of this mapping because it allows the UI to render the correct max row filters for each section.
 *                              The sectionIdFilters and rowIdFilters are alone suffient to determine the final cardinality of listings we want to show,
 *                              and this mapping object should only be used by the section pills to show the mapping of section to max row filter.
 *
 */
const StubHubListingsManualPricingContext =
  createContext<StubHubListingsManualPricingContextType>({
    onQuantityFilterChanged: () => {},
    onSectionZoneFilterChanged: () => {},
    onSectionFilterChanged: () => {},
    onRowFiltersChanged: () => {},
    resetFilters: () => {},
    quantityFilter: 2,
    selectedSections: [],
    sectionIdFilters: [],
    rowIdFilters: [],
    sectionRowIdFilters: {} as {
      [key: string]: string;
    },
    hasActiveFilters: false,
  });

export const useStubHubListingManualPricingContext = () =>
  useContext(StubHubListingsManualPricingContext);

type StubHubListingManualPricingContextProviderProps = {
  children: ReactNode;
  groupId?: string;
};

export const StubHubListingManualPricingContextProvider = ({
  children,
  groupId,
}: StubHubListingManualPricingContextProviderProps) => {
  const [selectedSections, setSelectedSections] = useState<SectionInfo[]>([]);
  const [quantityFilter, setQuantityFilter] = useState<number>(2);
  const [rowIdFilters, setRowIdFilters] = useState<number[]>([]);
  const [sectionRowIdFilters, setSectionRowIdFilters] = useState<{
    [key: string]: string;
  }>({});

  const { flattenedIds } = useCatalogMultiSelectionContext();

  const { setGroupItems } = useMultiSelectionContext();
  const { venueMapInfo } = useEventMapContext();

  const { sessionRole, enabled, selectedVenueMapSections, onSectionsSelected } =
    useEventHubContext();

  const clearGroupItems = useCallback(() => {
    if (groupId && flattenedIds) {
      setGroupItems(
        groupId,
        flattenedIds.reduce(
          (acc, id) => {
            acc[id] = false;
            return acc;
          },
          {} as Record<number | string, boolean>
        )
      );
    }
  }, [flattenedIds, groupId, setGroupItems]);

  useEffect(() => {
    setSelectedSections([]);
    setRowIdFilters([]);
    setSectionRowIdFilters({});
    setQuantityFilter(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const onSectionZoneFilterChanged = useCallback(
    (sections: SectionInfo[], flipOnSameSections = true) => {
      setSelectedSections((prevSections) => {
        const sectionMap = new Map(
          prevSections.map((section) => [section.id, section])
        );

        // If every section passed in already exists in prevSections then we should remove them entirely
        const completelyOverlap = sections.every((section) =>
          sectionMap.has(section.id)
        );

        if (completelyOverlap && flipOnSameSections) {
          sections.forEach((section) => sectionMap.delete(section.id));

          // We need to remove the associated rows as well
          const rowIdsToRemove = sections
            .map((section) => section.rows.map((r) => r.id))
            .flat();

          setRowIdFilters((prevFilters) =>
            prevFilters.filter((id) => !rowIdsToRemove.includes(id))
          );

          // Update sectionRowIdFilters to remove the sections that have been removed
          setSectionRowIdFilters((prevFilters) => {
            const newFilters = { ...prevFilters };
            sections.forEach((section) => {
              if (newFilters[section.id]) {
                delete newFilters[section.id];
              }
            });

            return newFilters;
          });
        } else {
          // Do an upsert of the sections instead
          sections.forEach((section) => {
            if (sectionMap.has(section.id)) {
              sectionMap.set(section.id, {
                ...sectionMap.get(section.id),
                ...section,
              });
            } else {
              sectionMap.set(section.id, section);
            }
          });
        }

        if (enabled && sessionRole === EventSessionRole.SidePanel) {
          const sectionIds = Array.from(sectionMap.values()).map(
            ({ id }) => id
          );
          onSectionsSelected(sectionIds);
        }

        return Array.from(sectionMap.values());
      });

      clearGroupItems();
    },
    [clearGroupItems, enabled, onSectionsSelected, sessionRole]
  );

  const onSectionFilterChanged = useCallback(
    (section: SectionInfo) => {
      setSelectedSections((prevSections) => {
        const idx = prevSections.findIndex(({ id }) => id === section.id);

        if (idx === -1) {
          const updated = [section, ...prevSections];

          if (enabled && sessionRole === EventSessionRole.SidePanel) {
            const sectionIds = updated.map(({ id }) => id);
            onSectionsSelected(sectionIds);
          }
          return [section, ...prevSections];
        } else {
          const updatedSections = [
            ...prevSections.slice(0, idx),
            ...prevSections.slice(idx + 1),
          ];

          // Remove all the associated row ids for this section
          const rowIdsToRemove = section.rows.map((r) => r.id);
          setRowIdFilters((prevFilters) =>
            prevFilters.filter((id) => !rowIdsToRemove.includes(id))
          );

          setSectionRowIdFilters((prevFilters) => {
            const newFilters = { ...prevFilters };
            delete newFilters[section.id];
            return newFilters;
          });

          if (enabled && sessionRole === EventSessionRole.SidePanel) {
            const sectionIds = updatedSections.map(({ id }) => id);
            onSectionsSelected(sectionIds);
          }

          return updatedSections;
        }
      });
      clearGroupItems();
    },
    [clearGroupItems, enabled, onSectionsSelected, sessionRole]
  );

  useEffect(() => {
    if (enabled && sessionRole === EventSessionRole.Main) {
      const sections =
        venueMapInfo?.sections.filter(({ id }) =>
          selectedVenueMapSections.includes(id)
        ) ?? [];

      setSelectedSections(sections);
    }
  }, [
    enabled,
    onSectionZoneFilterChanged,
    JSON.stringify(selectedVenueMapSections),
    sessionRole,
    venueMapInfo?.sections,
  ]);

  const onRowFiltersChanged = useCallback(
    (
      rowIdFiltersNew: number[],
      sectionRowIdFiltersNew: { [key: string]: string }
    ) => {
      setRowIdFilters(rowIdFiltersNew);
      setSectionRowIdFilters(sectionRowIdFiltersNew);
      clearGroupItems();
    },
    [clearGroupItems]
  );

  const onQuantityFilterChanged = useCallback(
    (quantityFilterNew: number) => {
      setQuantityFilter(quantityFilterNew);
      clearGroupItems();
    },
    [clearGroupItems]
  );

  const resetFilters = () => {
    setSelectedSections([]);
    setRowIdFilters([]);
    setSectionRowIdFilters({});
    setQuantityFilter(2);
  };

  const hasActiveFilters =
    selectedSections.length > 0 ||
    rowIdFilters.length > 0 ||
    quantityFilter !== 2 ||
    Object.keys(sectionRowIdFilters).length > 0;

  const sectionIdFilters = useMemo(
    () => selectedSections.map((section) => section.id),
    [selectedSections]
  );

  return (
    <StubHubListingsManualPricingContext.Provider
      value={{
        onQuantityFilterChanged,
        onSectionZoneFilterChanged,
        onSectionFilterChanged,
        onRowFiltersChanged,
        resetFilters,
        selectedSections,
        sectionIdFilters,
        rowIdFilters,
        sectionRowIdFilters,
        quantityFilter,
        hasActiveFilters,
      }}
    >
      {children}
    </StubHubListingsManualPricingContext.Provider>
  );
};
