import { useCallback, useMemo, useState } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { EditIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType, Tag } from 'src/WebApiController';

import * as styles from './TagsFormBody.css';
import { TagsPanelDisplayMode } from './TagsPanelDisplayMode';
import { TagsPanelEditMode } from './TagsPanelEditMode';

export const TagsFormBodyV2 = ({
  disabled,
  tagsCurrentEntity,
  onTagsUpdate,
  entityType,
  canCreateTagType,
  canEditTags,
}: {
  disabled?: boolean;
  canCreateTagType: boolean;
  canEditTags: boolean;
  tagsCurrentEntity: Tag[];
  onTagsUpdate: (tagsCurrentEntity: Tag[]) => void;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Purchase
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.SellerEvent;
}) => {
  const { tagsMetadata: existingTagsCurrentType = [] } =
    useTagsForEntityType(entityType);

  const tagsWithEmptyValue = useMemo(() => {
    // Fill in the ordinal for tags that don't have it
    const hasEveryOrdinal = tagsCurrentEntity.every((t) => t.ordinal != null);

    let tagsOrdinalFilled: Tag[] = [...tagsCurrentEntity];
    if (!hasEveryOrdinal) {
      tagsOrdinalFilled = tagsOrdinalFilled
        .sort((t1, t2) => t1.key.localeCompare(t2.key))
        .map((t, i) => ({ ...t, ordinal: i }));
    }

    tagsOrdinalFilled = tagsOrdinalFilled.sort(
      (t1, t2) => (t1.ordinal ?? 0) - (t2.ordinal ?? 0)
    );

    // Add tags that don't have value
    (existingTagsCurrentType ?? []).forEach((t) => {
      if (!tagsOrdinalFilled.find((t2) => t2.key === t.key)) {
        tagsOrdinalFilled.push({
          ...t,
          value: '',
          ordinal: tagsOrdinalFilled.length,
        });
      }
    });

    return tagsOrdinalFilled;
  }, [existingTagsCurrentType, tagsCurrentEntity]);

  const tagsWithValue = useMemo(() => {
    return tagsWithEmptyValue.filter((t) => t.value);
  }, [tagsWithEmptyValue]);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const onTagsUpdateHandler = useCallback(
    (newTags: Tag[]) => {
      // Filter out tags with empty value
      const tagsWithValue = newTags.filter((t) => t.value);
      // De-duplicate tags
      let tagsDeDup: Tag[] = [];
      tagsWithValue.forEach((t) => {
        if (!tagsDeDup.find((t2) => t2.key === t.key && t2.value === t.value)) {
          tagsDeDup.push(t);
        }
      });
      tagsDeDup = tagsDeDup.map((t, i) => ({ ...t, ordinal: i }));
      onTagsUpdate(tagsDeDup);
    },
    [onTagsUpdate]
  );

  const onTagValueChange = useCallback(
    (tag: Tag, newValue: string) => {
      const newTags = tagsWithEmptyValue.map((t) => {
        if (t.key === tag.key && t.value === tag.value) {
          return { ...t, value: newValue };
        }
        return t;
      });
      onTagsUpdateHandler(newTags);
    },
    [onTagsUpdateHandler, tagsWithEmptyValue]
  );

  const editTagTypesLabel = useContent(ContentId.EditTagTypes);

  return (
    <>
      <div className={styles.mainContent}>
        <div className={styles.editIconContainer}>
          <EditIcon
            disabled={disabled || isEditMode || !canCreateTagType}
            fill={IconsFill.textBrand}
            onClick={() => {
              setIsEditMode(true);
            }}
            withHoverEffect
            title={editTagTypesLabel}
          />
        </div>
        {isEditMode ? (
          <TagsPanelEditMode
            tagsWithEmptyValue={tagsWithEmptyValue}
            tagsWithValue={tagsWithValue}
            onTagsUpdate={onTagsUpdateHandler}
            onTagValueChange={onTagValueChange}
            disabled={disabled || !canCreateTagType}
            onEditDone={() => {
              setIsEditMode(false);
            }}
            entityType={entityType}
          />
        ) : (
          <TagsPanelDisplayMode
            tagsWithEmptyValue={tagsWithEmptyValue}
            tagsWithValue={tagsWithValue}
            onTagsUpdate={onTagsUpdateHandler}
            onTagValueChange={onTagValueChange}
            disabled={disabled || !canEditTags}
          />
        )}
      </div>
    </>
  );
};
