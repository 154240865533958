export enum IconsFill {
  textPrimary = 'textPrimary',
  textStrong = 'textStrong',
  textBrand = 'textBrand',
  textDisabled = 'textDisabled',
  textSuccess = 'textSuccess',
  textNegative = 'textNegative',
  textInfo = 'textInfo',
  textWarning = 'textWarning',
  currentColor = 'currentColor',
  textInverted = 'textInverted',
}

export enum PaymentSize {
  s = 's',
  l = 'l',
}

export enum PaymentViewbox {
  square = '0 0 24 24',
  rect = '0 0 36 24',
}
