import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function SalesMetricIcon(props: PosIconProps) {
  return (
    <SvgWrapper {...props} viewBox="0 0 45 65" fill="none">
      <path
        d="M4 48.5C16 58 39.5 58.5 39.5 44C39.5 28.5 8.5 37.0973 8.5 20.5C8.5 6.49997 29.5 6.50001 41 15.5"
        stroke="currentColor"
        strokeWidth="10"
      />
      <path d="M24 0V65" stroke="currentColor" strokeWidth="10" />
    </SvgWrapper>
  );
}
