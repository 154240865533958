import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const PaymentsIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_140_26716)">
        <path
          d="M7.91663 7.5V15"
          stroke={props.stroke || 'currentColor'}
          stroke-width="1.0"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M12.0834 7.5V15"
          stroke={props.stroke || 'currentColor'}
          stroke-width="1.0"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M16.25 7.5V15"
          stroke={props.stroke || 'currentColor'}
          stroke-width="1.0"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M3.75 7.5V15"
          stroke={props.stroke || 'currentColor'}
          stroke-width="1.0"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M10 0.833008L1.25 4.76292V7.49967H18.75V4.76292L10 0.833008Z"
          stroke={props.stroke || 'currentColor'}
          stroke-width="1.0"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M18.75 17.9167H1.25V16.6667C1.25 15.7462 1.99619 15 2.91667 15H17.0833C18.0038 15 18.75 15.7462 18.75 16.6667V17.9167Z"
          stroke={props.stroke || 'currentColor'}
          stroke-width="1.0"
          stroke-miterlimit="10"
          stroke-linecap="square"
        />
        <path
          d="M11.0417 4.37467C11.0417 4.94997 10.5753 5.41634 10 5.41634C9.42475 5.41634 8.95837 4.94997 8.95837 4.37467C8.95837 3.79938 9.42475 3.33301 10 3.33301C10.5753 3.33301 11.0417 3.79938 11.0417 4.37467Z"
          stroke={props.stroke || 'currentColor'}
          stroke-width="1.0"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_140_26716">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
      {/*</svg>*/}
    </SvgWrapper>
  );
};
