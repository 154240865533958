import { useCallback, useState } from 'react';

export const useBasicDialog = (
  options: {
    onOkay?: () => void;
    onCancel?: () => void;
    openByDefault?: boolean;
  } = {}
) => {
  const [showDialog, setShowDialog] = useState(!!options.openByDefault);

  const launchDialog = useCallback(() => setShowDialog(true), [setShowDialog]);
  const closeDialog = useCallback(() => setShowDialog(false), [setShowDialog]);

  const handleOkay = useCallback(() => {
    closeDialog();
    options.onOkay?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.onOkay, closeDialog]);

  const handleCancel = useCallback(() => {
    closeDialog();
    options.onCancel?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.onCancel, closeDialog]);

  return {
    closeDialog,
    launchDialog,
    dialogProps: {
      isOpen: showDialog,
      onOkay: handleOkay,
      onCancel: handleCancel,
    },
  };
};
