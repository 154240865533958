import * as styles from './PosKey.css';

interface KeyProps {
  char: string;
}

export const PosKey = ({ char }: KeyProps) => {
  if (!char || !char.length) {
    return null;
  }
  const singleChar = char.charAt(0);

  return <div className={styles.keyWrapper}>{singleChar}</div>;
};
