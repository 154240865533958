import { useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';
import { Content } from 'src/contexts/ContentContext';
import { HorizontalTextList } from 'src/core/POS/HorizontalTextList';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { AddAdminHoldNoteModal } from 'src/modals/AddNote';
import { Detail, DetailSection } from 'src/modals/common';
import { EditIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ListingDetailsBroadcastSectionUpdates,
  Permission,
} from 'src/WebApiController';

import * as styles from './AdminHoldNotes.css';

export const AdminHoldNotesSection = ({ disabled }: { disabled?: boolean }) => {
  const { watch } = useFormContext<ListingDetailsBroadcastSectionUpdates>();
  const adminHoldNotes = watch('adminHoldNotes');

  const hasPermission = useUserHasAnyOfPermissions(
    Permission.SellerAccount_Admin
  );

  const [isAddNoteModalOpen, toggleAddNoteModal] = useToggle(false);

  const onSubmit = () => {
    toggleAddNoteModal();
  };

  if (!hasPermission) {
    return null;
  }

  return (
    <DetailSection name={<Content id={ContentId.Notes} />}>
      {isAddNoteModalOpen && (
        <AddAdminHoldNoteModal
          onSubmit={onSubmit}
          onClose={toggleAddNoteModal}
        />
      )}
      <Stack direction="column" gap="l">
        <Detail
          label={<Content id={ContentId.HoldNote} />}
          detail={
            <Stack direction="column" gap="m">
              {adminHoldNotes ? (
                <div className={styles.notesGroup}>
                  <HorizontalTextList
                    key="adminHoldNotes"
                    texts={[adminHoldNotes]}
                  />
                </div>
              ) : null}
              <Button
                className={styles.addNotesButton}
                size="unset"
                variant="link"
                onClick={toggleAddNoteModal}
                disabled={disabled}
              >
                {adminHoldNotes ? (
                  <EditIcon
                    size={vars.iconSize.s}
                    fill={IconsFill.currentColor}
                    withHoverEffect
                  />
                ) : (
                  <PlusIcon
                    size={vars.iconSize.s}
                    fill={IconsFill.currentColor}
                    withHoverEffect
                  />
                )}
                <Content
                  id={adminHoldNotes ? ContentId.EditNote : ContentId.AddNote}
                />
              </Button>
            </Stack>
          }
        />
      </Stack>
    </DetailSection>
  );
};
