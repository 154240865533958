import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function SeatMapIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M18.75 10.885c-.295.612-.404.86-.78 1.41.458.306 1.396 1.17 1.816 1.552l-4.313 4.313A5.7 5.7 0 0012 16.977a5.7 5.7 0 00-3.473 1.183l-4.351-4.35a11.782 11.782 0 016.45-2.863c1.107 2.48 3.473 4.236 3.588 4.313l.458.305.458-.305c.076-.039.687-.497 1.45-1.26 1.183-1.221 2.672-3.168 2.672-5.458 0-2.52-2.061-4.58-4.58-4.58-2.481 0-4.466 1.985-4.58 4.427v.153c0 .305.038.61.076.916a13.338 13.338 0 00-7.634 3.779L2 13.77l6.489 6.489.534-.535A4.122 4.122 0 0112 18.504c1.145 0 2.176.42 2.977 1.221l.534.535L22 13.77l-.573-.496c-.534-.535-2.067-1.97-2.677-2.39zM14.672 5.49a3.062 3.062 0 013.053 3.053c0 2.29-2.099 4.351-3.053 5.114-.954-.801-3.054-2.824-3.054-5.114a3.062 3.062 0 013.054-3.053zm0 1.908a1.145 1.145 0 100 2.29 1.145 1.145 0 000-2.29z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
