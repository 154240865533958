import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { SellerAccountClient } from 'src/WebApiController';

export type UseGetUserInfo = {
  id: string;
  name: string;
  email: string;
  isDeactivated: boolean;
};

export const getUserDisplayName = (
  userInfo: UseGetUserInfo,
  deactivatedText?: string
) => {
  let displayString = userInfo.name ?? userInfo.email ?? userInfo.id;

  if (userInfo.isDeactivated && deactivatedText) {
    displayString += ` (${deactivatedText})`;
  }

  return displayString;
};

export const useGetUserInfos = (userIds?: string[]) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const { getUserInfo } = useSellerAccountContext();
  const getUserInfosQuery = useQuery({
    queryKey: ['GetUserInfo', userIds],
    queryFn: async () => {
      const result = {} as Record<string, UseGetUserInfo>;

      if (!userIds?.length) return result;

      const userInfos = userIds.map(async (userId) => {
        const userInfo = getUserInfo(userId);
        if (userInfo) {
          return {
            id: userInfo.userId,
            name: userInfo.displayName,
            email: userInfo.email,
            isDeactivated: userInfo.isDeactivated,
          };
        }

        if (userId != null) {
          const sellerUser = await new SellerAccountClient(
            activeAccountWebClientConfig
          ).getSellerUser(userId);

          if (sellerUser) {
            return {
              id: sellerUser.sellerUserId,
              name: sellerUser.sellerDisplayName,
              email: sellerUser.email,
              isDeactivated: Boolean(sellerUser.deleteDate),
            };
          }
        }

        return null;
      });

      return (await Promise.all(userInfos)).reduce((r, c) => {
        if (c) {
          r[c.id] = c;
        }
        return r;
      }, result);
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('SellerAccountClient.GetUserInfo', error);
      },
    },
  });

  return getUserInfosQuery;
};
