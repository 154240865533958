import clsx from 'clsx';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DateRangePresetName,
  DateRangeRelativePreset,
  formatRelativeDateRange,
  getDateRangeRelativePresets,
  getRelativePresetFromUiDateTimeRange,
} from 'src/utils/dateTimeUtils';
import { DateTimeRangeWithRelative } from 'src/WebApiController';

import * as styles from './DateRangePresets.css';
import {
  Suggestion,
  SuggestionContainer,
  SuggestionsContainer,
  SuggestionsHeading,
} from './DateRangePresets.styled';

export type DateRangeRelativePresetsProp = {
  presetNames: DateRangePresetName[];
  selectedDateRange?: DateTimeRangeWithRelative | null;
  onClick: (dateRange: DateTimeRangeWithRelative) => void;
  disabled?: boolean;
  hideSuggestionTitle?: boolean;
};

export function DateRangeRelativePresets({
  selectedDateRange,
  presetNames,
  onClick,
  disabled,
  hideSuggestionTitle,
}: DateRangeRelativePresetsProp) {
  const handleClick = (preset: DateRangeRelativePreset) => {
    onClick(preset.range);
  };

  const presets = getDateRangeRelativePresets(presetNames);
  const presetName =
    getRelativePresetFromUiDateTimeRange(selectedDateRange)?.name;
  return (
    <SuggestionsContainer>
      {!hideSuggestionTitle && (
        <SuggestionsHeading>
          <Content id={ContentId.DateRangeSuggestionsTitle} />
        </SuggestionsHeading>
      )}
      {presets.map((preset, index) => (
        <SuggestionContainer key={index} onClick={() => handleClick(preset)}>
          <Suggestion
            className={clsx(styles.suggestion, {
              [styles.suggestionSelected]: presetName === preset.name,
            })}
            disabled={disabled}
          >
            <span className={styles.suggestionText}>{preset.label}</span>{' '}
            <span>({formatRelativeDateRange(preset.range)})</span>
          </Suggestion>
        </SuggestionContainer>
      ))}
    </SuggestionsContainer>
  );
}
