import { Temporal } from '@js-temporal/polyfill';
import { useQuery } from '@tanstack/react-query';
import { max, min } from 'lodash-es';
import { useMemo } from 'react';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import {
  roundRelativeDateDown,
  roundRelativeDateUp,
} from 'src/utils/dateTimeUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  DateTimeRangeWithRelative,
  MarketplacePaymentClient,
  MarketplacePaymentQuery,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useErrorBoundaryContext } from '../ErrorBoundaryContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import { PAYMENT_SINGLE_MONTH_QUERY_KEY } from './MarketplacePaymentDataContext.constants';

export const useSingleMonthMarketplacePaymentsQuery = (
  firstOfMonth: string,
  isExpanded: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { filterQuery } = useFilterQueryContext<MarketplacePaymentQuery>();
  const { trackError } = useErrorBoundaryContext();

  const firstOfMonthPlainDateTime = Temporal.PlainDateTime.from(firstOfMonth);
  const lastOfMonthPlainDateTime = firstOfMonthPlainDateTime
    .with({
      day: firstOfMonthPlainDateTime.daysInMonth,
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    })
    .toString();

  const monthDateRange: DateTimeRangeWithRelative = useMemo(() => {
    let filterDateStart = '';
    let filterDateEnd = '';
    if (filterQuery.paymentDates) {
      if (filterQuery.paymentDates.start) {
        filterDateStart = filterQuery.paymentDates.start;
      }
      if (filterQuery.paymentDates.relativeStartSecs != null) {
        const relativeDateStart = new Date(
          new Date().getTime() +
            filterQuery.paymentDates.relativeStartSecs * 1000
        );

        filterDateStart = roundRelativeDateUp(
          relativeDateStart,
          filterQuery.paymentDates.roundingMode
        ).toISOString();
      }

      if (filterQuery.paymentDates.end) {
        filterDateEnd = filterQuery.paymentDates.end;
      }
      if (filterQuery.paymentDates.relativeEndSecs != null) {
        const relativeDateEnd = new Date(
          new Date().getTime() + filterQuery.paymentDates.relativeEndSecs * 1000
        );

        filterDateEnd = roundRelativeDateDown(
          relativeDateEnd,
          filterQuery.paymentDates.roundingMode
        ).toISOString();
      }
    }

    return {
      start:
        max([firstOfMonthPlainDateTime.toString(), filterDateStart]) ?? null,
      end: min([lastOfMonthPlainDateTime.toString(), filterDateEnd]) ?? null,
    } as DateTimeRangeWithRelative;
  }, [
    filterQuery.paymentDates,
    firstOfMonthPlainDateTime,
    lastOfMonthPlainDateTime,
  ]);

  const moddedFilterQuery = {
    ...filterQuery,
    paymentDates: monthDateRange,
  };

  const singleMonthPaymentQuery = useQuery({
    queryKey: [PAYMENT_SINGLE_MONTH_QUERY_KEY, isExpanded, moddedFilterQuery],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      return tryInvokeApi(
        async () => {
          return await new MarketplacePaymentClient(
            activeAccountWebClientConfig
          ).getMarketplacePayments(moddedFilterQuery);
        },
        (error) => {
          trackError(PAYMENT_SINGLE_MONTH_QUERY_KEY, error, moddedFilterQuery);
        }
      );
    },
    enabled: isExpanded && activeAccountWebClientConfig.activeAccountId != null,
    staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  return {
    singleMonthPaymentQuery,
    filterQuery: moddedFilterQuery as MarketplacePaymentQuery,
  };
};
