import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Switch } from 'src/core/interim/Switch';
import { PosFormField } from 'src/core/POS/PosFormField';
import { Stack } from 'src/core/ui';
import { Slider } from 'src/core/ui/Slider';
import { DetailGroup } from 'src/modals/common';
import {
  convertSliderValueFromServer,
  convertSliderValueToServer,
  SLIDER_MAX,
  SLIDER_MIN,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { EventPricingSeatMapForm } from 'src/utils/eventWithDataUtils';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { AutoPricingCompListingMode } from 'src/WebApiController';

import * as styles from './EventPricingSection.css';
import { useEventAutoPricingSettings } from './useEventAutoPricingSettings';

export const CompListingSameQualitySettings = ({
  disabled,
}: {
  disabled?: boolean;
}) => {
  useFormContext<EventPricingSeatMapForm>();

  const {
    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingQuantityScoreAdjustmentEnabled,
    onCompListingChange,
  } = useEventAutoPricingSettings();

  const isCompListingAmountDisabled =
    disabled ||
    !compListingMode ||
    compListingMode === AutoPricingCompListingMode.SameZone ||
    compListingMode === AutoPricingCompListingMode.SameEvent ||
    compListingMode === AutoPricingCompListingMode.ParentListing;

  return (
    <>
      <DetailGroup style={{ gridColumn: '1 / span 3' }}>
        {!isCompListingAmountDisabled && (
          <Stack direction="column" gap="m">
            <Slider.Root
              disabled={disabled}
              min={SLIDER_MIN * 100}
              max={SLIDER_MAX * 100}
              step={0.01}
              minStepsBetweenThumbs={0.01}
              value={[compListingFloor, compListingCeiling].map(
                convertSliderValueFromServer
              )}
              renderHandle={(val) => (
                <Slider.Handle>{roundToPrecision(val, 0)}%</Slider.Handle>
              )}
              onValueChange={([floor, ceil]) => {
                onCompListingChange({
                  compListingFloorNew: convertSliderValueToServer(floor),
                  compListingCeilingNew: convertSliderValueToServer(ceil),
                });
              }}
            />
            <div className={styles.yourListingIndicatorContainer}>
              <Content id={ContentId.YourListing} />
              <div className={styles.yourListingIndicatorArrow} />
            </div>
          </Stack>
        )}
      </DetailGroup>
      {compListingMode === AutoPricingCompListingMode.QualityScore && (
        <DetailGroup style={{ gridColumn: '1' }}>
          <PosFormField
            label={
              <Content id={ContentId.CompListingQuantityScoreAdjustment} />
            }
          >
            <Switch
              disabled={disabled}
              checked={Boolean(compListingQuantityScoreAdjustmentEnabled)}
              onClick={(e: ChangeEvent<HTMLElement>) => e.stopPropagation()}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked !== compListingQuantityScoreAdjustmentEnabled) {
                  onCompListingChange({
                    compListingQuantityScoreAdjustmentEnabledNew: isChecked,
                  });
                }
              }}
            />
          </PosFormField>
        </DetailGroup>
      )}
    </>
  );
};
