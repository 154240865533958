import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  EventConfigClient,
  SellerAccountEventSetting,
} from 'src/WebApiController';

export const useSellerAccountEventSettings = (
  eventId?: number | null,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const queryClient = useQueryClient();
  const getQueryKey = [
    'EventConfigClient.getSellerAccountEventSettings',
    eventId,
  ];

  const shouldQuery =
    !disabled &&
    Boolean(activeAccountWebClientConfig.activeAccountId && eventId);

  const getQuery = useQuery({
    queryKey: getQueryKey,
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const settings = await new EventConfigClient(
        activeAccountWebClientConfig
      ).getSellerAccountEventSettings(eventId!);

      return settings.filter((s) => s.delivType != null);
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('EventConfigClient.getSellerAccountEventSettings', error, {
          eventId,
        });
      },
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({
      settings,
    }: {
      settings: SellerAccountEventSetting[];
    }) => {
      return await new EventConfigClient(
        activeAccountWebClientConfig
      ).mergeSellerAccountEventSettingsForDelivery(settings, eventId!);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ settings }) => {
      queryClient.cancelQueries({ queryKey: getQueryKey });
      const prevValue =
        queryClient.getQueryData<SellerAccountEventSetting[]>(getQueryKey);

      queryClient.setQueryData(getQueryKey, settings);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { settings }, context) => {
      queryClient.setQueryData(getQueryKey, context?.prevValue);
      showErrorDialog(
        'EventConfigClient.mergeSellerAccountEventSettingsForDelivery',
        err,
        {
          trackErrorData: { settings },
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey });
    },
  });

  return {
    sellerAccountEventSettings: getQuery.data,
    isLoading: getQuery.isLoading,
    async updateSellerAccountEventSettings(
      settings: SellerAccountEventSetting[]
    ) {
      await updateMutation.mutateAsync({
        settings,
      });
    },
    isMutationLoading: updateMutation.isPending,
  };
};
