import {
  ChangeEvent,
  forwardRef,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { mergeProps } from 'src/core/utils';
import { UiCurrency } from 'src/WebApiController';

import { PosTextField } from '../PosTextField';
import * as styles from './PosCurrencyField.css';

export type PosCurrencyFieldProps = Omit<
  React.ComponentProps<typeof PosTextField>,
  'step' | 'type' | 'inputMode'
> & {
  uiCurrency: UiCurrency;
};

export const PosCurrencyField = forwardRef<
  HTMLInputElement,
  PosCurrencyFieldProps
>(function PosCurrencyField(
  { uiCurrency, value, onChange, onBlur, onKeyUp, rootProps, ...rest },
  ref
): ReactElement {
  const [isInputting, setIsInputting] = useState(false);

  const fixedValue = useMemo(() => {
    if (value == null) {
      return '';
    }
    const numericalValue = parseFloat((value ?? 0).toString());
    return isInputting
      ? numericalValue
      : numericalValue.toFixed(uiCurrency.dec);
  }, [isInputting, uiCurrency.dec, value]);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIsInputting(true);
      onChange?.(e);
    },
    [onChange]
  );

  const onBlurHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      setIsInputting(false);

      // If user type in numbers less than the current decimal digit,
      // like 0.003 when the display is 0.00, we know they meant to type from the end
      // so it should shift upward (like how the rest of money inputs are)
      if (e.target.value) {
        const newValue = parseFloat(e.target.value);
        const fixedNum = newValue.toFixed(uiCurrency.dec);
        if (fixedNum !== fixedValue) {
          e.target.value = fixedNum;
          onChange?.(e);
        }
      }

      onBlur?.(e);
    },
    [fixedValue, onBlur, onChange, uiCurrency.dec]
  );

  return (
    <PosTextField
      type="number"
      inputMode="decimal"
      value={fixedValue}
      step={1 / Math.pow(10, uiCurrency.dec)}
      prefixDisplay={uiCurrency.sym}
      ref={ref}
      {...rest}
      rootProps={mergeProps({ className: styles.input }, rootProps ?? {})}
      onBlur={onBlurHandler}
      onChange={onChangeHandler}
      onKeyUp={onKeyUp}
    />
  );
});
