import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useGetAccessiblePurchasePaymentMethodsQuery } from 'src/hooks/api/useGetAccessiblePurchasePaymentMethodsQuery';
import { BodySectionTitle } from 'src/modals/common/Purchase';
import { PurchasePaymentMethodsTableContainer } from 'src/tables/PurchasePaymentMethodsTable/PurchasePaymentMethodsTableContainer';
import { ContentId } from 'src/utils/constants/contentId';

export const SellerAccountSettingPaymentMethods = () => {
  const purchasePaymentMethodsQuery =
    useGetAccessiblePurchasePaymentMethodsQuery();

  const purchasePaymentMethods = purchasePaymentMethodsQuery.data;

  return (
    <>
      <BodySectionTitle>
        <Content id={ContentId.PaymentMethods} />
      </BodySectionTitle>
      {purchasePaymentMethodsQuery.isLoading ? (
        <PosSpinner />
      ) : (
        <PurchasePaymentMethodsTableContainer
          purchasePaymentMethods={purchasePaymentMethods ?? []}
          paymentMethodsQuery={purchasePaymentMethodsQuery}
        />
      )}
    </>
  );
};
