import React, {
  createContext,
  DragEvent,
  ReactNode,
  useContext,
  useState,
} from 'react';
import { DnDFileUploaderExternalContext } from 'src/core/POS/DnDFileUploader/DnDFileUploader';

type DndFileUploaderContext = DnDFileUploaderExternalContext & {
  setDndFileUploaderContext: (state: DnDFileUploaderExternalContext) => void;
};

const defaultDndFileUploaderContext: DndFileUploaderContext = {
  isDragging: false,
  handleDrag: (e: DragEvent) => undefined,
  handleDrop: (e: DragEvent) => undefined,
  selectFiles: () => undefined,
  setDndFileUploaderContext: (context: DnDFileUploaderExternalContext) =>
    undefined,
};

export const DndFileUploaderContext = createContext<DndFileUploaderContext>(
  defaultDndFileUploaderContext
);

export const useDndFilerUploaderContext = () =>
  useContext(DndFileUploaderContext);

export const DndFilerUploaderContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [dnDFileUploaderContext, setDnDFileUploaderContext] =
    useState<DnDFileUploaderExternalContext>(defaultDndFileUploaderContext);

  return (
    <DndFileUploaderContext.Provider
      value={{
        ...dnDFileUploaderContext,
        setDndFileUploaderContext: setDnDFileUploaderContext,
      }}
    >
      {children}
    </DndFileUploaderContext.Provider>
  );
};
