import { createContext, useCallback, useEffect, useState } from 'react';
import { Modal, ModalProps } from 'src/modals/Modal';
import {
  addDeepLinkUrlPart,
  useRemoveDeepLinkUrlPart,
} from 'src/utils/deepLinkUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { ActivityLogClient } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useErrorBoundaryContext } from '../ErrorBoundaryContext';

type ModalContextType = {
  isModalOpen: boolean;
  closeModal: (forceClose?: boolean | React.SyntheticEvent) => void;
  setModal: (props: ModalProps) => void;
  updateModal: (props: Partial<ModalProps>) => void;
};

export const ModalContext = createContext<ModalContextType>({
  isModalOpen: false,
  setModal: () => {},
  updateModal: () => {},
  closeModal: () => {},
});

/**
 * Adds a modal to the page where the provider is added
 * Provides utils to set all or update part of the modal
 * The modal is assumed to be open when it contains content
 */
export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalProps, setModalProps] = useState<ModalProps | null>(null);
  const { deepLinkKey, deepLinkValue } = modalProps ?? {};
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  const removeDeepLinkUrlPart = useRemoveDeepLinkUrlPart();

  useEffect(() => {
    if (deepLinkKey && deepLinkValue) {
      addDeepLinkUrlPart(deepLinkKey, deepLinkValue);
    }
  }, [deepLinkKey, deepLinkValue]);

  const handleCloseModal = (forceClose?: boolean | React.SyntheticEvent) => {
    if (forceClose !== true && modalProps?.onCloseOverride) {
      modalProps.onCloseOverride();
    } else {
      closeModal();
    }
  };

  const setModal = useCallback(
    (modal: ModalProps) => {
      if (modal.deepLinkValue && modal.trackViewEntityType) {
        tryInvokeApi(
          async () => {
            await new ActivityLogClient(
              activeAccountWebClientConfig
            ).trackEntityView(modal.deepLinkValue, modal.trackViewEntityType);
          },
          (error) => {
            trackError('ActivityLogClient.trackEntityView', error, {
              deepLinkValue: modal.deepLinkValue,
              trackViewEntityType: modal.trackViewEntityType,
            });
          }
        );
      }
      setModalProps(modal);
    },
    [activeAccountWebClientConfig, trackError]
  );

  const closeModal = useCallback(() => {
    // TODO: refactor deeplink to a hook and pass as onClose where used
    removeDeepLinkUrlPart(deepLinkKey);
    setModalProps(null);
  }, [deepLinkKey, removeDeepLinkUrlPart]);

  const updateModal = useCallback<ModalContextType['updateModal']>(
    (partial) => {
      if (modalProps) {
        setModalProps({ ...modalProps, ...partial });
      }
    },
    [modalProps]
  );

  const isModalOpen = !!modalProps;

  return (
    <ModalContext.Provider
      value={{
        isModalOpen,
        setModal: setModal,
        updateModal,
        closeModal: handleCloseModal,
      }}
    >
      {children}
      {modalProps && (
        <Modal
          {...modalProps}
          isOpen
          onClose={handleCloseModal}
          onExit={modalProps?.onExit}
        />
      )}
    </ModalContext.Provider>
  );
};
