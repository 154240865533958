import { useBulkEditTagsPermissions } from 'src/components/common/BulkActions/BulkEditTagsAction/useBulkEditTagsPermissions';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { BulkEditTagAction } from 'src/dialogs/BulkEdits/BulkEditTagDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType } from 'src/WebApiController';

export const LaunchBulkEditTags = ({
  onClick,
  disabled,
  entityType,
}: {
  onClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    editTagAction: BulkEditTagAction
  ) => void;
  disabled?: boolean;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.Purchase;
}) => {
  const { hasEditTagsPermissions } = useBulkEditTagsPermissions({ entityType });

  if (!hasEditTagsPermissions) {
    return null;
  }

  return (
    <>
      <PosDropdownItem
        key="AddTagValues"
        onClick={(e) => onClick(e, BulkEditTagAction.Update)}
        disabled={disabled}
      >
        <Content id={ContentId.UpdateTagValues} />
      </PosDropdownItem>
      <PosDropdownItem
        key="RemoveTagValues"
        onClick={(e) => onClick(e, BulkEditTagAction.Remove)}
        disabled={disabled}
      >
        <Content id={ContentId.RemoveTagValues} />
      </PosDropdownItem>
    </>
  );
};
