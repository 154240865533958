import { useContext } from 'react';
import { ModalContext } from 'src/contexts/ModalContext';
import { Stack } from 'src/core/ui';
import { VendorAccounts } from 'src/modals/VendorDetails/components/VendorAccounts/VendorAccounts';
import { VendorSummary } from 'src/modals/VendorDetails/VendorSummary';
import { PurchaseVendor } from 'src/WebApiController';

import { VendorDeliveryOverrideForm } from './VendorDeliveryOverrideForm';

interface VendorDetailsBodyContentProps {
  vendor: PurchaseVendor;
}

export const VendorDetailsBodyContent = ({
  vendor,
}: VendorDetailsBodyContentProps) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <Stack direction="column" gap="xxl" height="full">
      <VendorSummary vendor={vendor} onVendorDeleted={closeModal} />
      <VendorDeliveryOverrideForm vendor={vendor} />
      <VendorAccounts vendor={vendor} />
    </Stack>
  );
};
