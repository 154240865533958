import { useCallback, useContext } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { MergeListingsModal } from 'src/modals/MergeListings';
import { BulkMergeListingsModal } from 'src/modals/MergeListings/BulkMergeListings';
import { MergeIcon } from 'src/svgs';
import { IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Event } from 'src/WebApiController';

export const LaunchMergeListings = ({
  event,
  disabled,
}: {
  event?: Event | undefined;
  disabled?: boolean;
}) => {
  const { setModal } = useContext(ModalContext);
  const {
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();

  const mergeListings = useCallback(() => {
    setModal({
      children:
        event != null ? (
          <MergeListingsModal event={event} />
        ) : (
          <BulkMergeListingsModal />
        ),
      clickOutsideToClose: true,
      ...{ size: 'slide-in' },
    });
  }, [setModal, event]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (event) {
        setListItemExpansion(true, [event.viagVirtualId]);
      }

      mergeListings();
    },
    [event, mergeListings, setListItemExpansion]
  );

  return (
    <PosDropdownItem
      key="MergeListings"
      onClick={onLaunchDialog}
      disabled={disabled}
    >
      <Stack gap="m" alignItems="center">
        <MergeIcon
          withHoverEffect
          stroke={IconsFill.currentColor}
          size={vars.iconSize.m}
        />
        <Content id={ContentId.MergeListings} />
      </Stack>
    </PosDropdownItem>
  );
};
