/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { REACT_APP_NAME } from 'src/utils/constants/constants';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ClientLogClient,
  ClientLogRequest,
  LogLevel,
} from 'src/WebApiController';

function getTabId() {
  return sessionStorage.tabId
    ? sessionStorage.tabId
    : (sessionStorage.tabId = Math.random());
}

const useMeasurement = () => {
  const { activeAccountWebClientConfig } = useAppContext();
  const logClient = useMemo(
    () => new ClientLogClient(activeAccountWebClientConfig),
    [activeAccountWebClientConfig]
  );

  return useCallback(async () => {
    if ((window.performance as any).memory) {
      const logRequest: ClientLogRequest = {
        logLevel: LogLevel.Information,
        applicationSource: REACT_APP_NAME,
        errorId: null,
        error: 'MemoryStat',
        pageUrl: window.location.href,
        componentStack: null,
        additionalInfo: {
          tabId: getTabId(),
          //memory: await performance.measureUserAgentSpecificMemory(), // TODO:: can't use this as window.crossOriginIsolated is false
          memory: {
            usedJSHeapSize: (window.performance as any).memory.usedJSHeapSize,
            totalJSHeapSize: (window.performance as any).memory.totalJSHeapSize,
            jsHeapSizeLimit: (window.performance as any).memory.jsHeapSizeLimit,
          },
        },
      };

      await tryInvokeApi(
        async () => {
          await logClient.log(logRequest);
        },
        () => {
          console.warn('Failed to log memory metrics to server');
        }
      );
    }
  }, [logClient]);
};

export const useMemoryTelemetry = () => {
  const INTERVAL_SECONDS = 300;
  const getMeasurement = useMeasurement();

  useEffect(() => {
    const timerId = setInterval(getMeasurement, 1000 * INTERVAL_SECONDS);
    return () => clearInterval(timerId);
  }, [getMeasurement]);
};
