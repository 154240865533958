import { InitialTableState, PaginationState } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

export const usePaginationSettings = (
  numOfItems: number,
  currentPage: number,
  pageSize: number,
  disablePagination?: boolean,
  initState?: InitialTableState
) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex:
      currentPage >= 0 ? currentPage : initState?.pagination?.pageIndex || 0,
    pageSize: disablePagination ? numOfItems : pageSize,
  });

  useEffect(() => {
    if (disablePagination && pagination.pageSize !== numOfItems) {
      setPagination((prevState) => ({
        ...prevState,
        pageSize: numOfItems,
      }));
    } else {
      if (pagination.pageIndex * pagination.pageSize >= numOfItems) {
        // If the current page index is greater than the number of pages, reset to the first page
        setPagination((prevState) => ({
          ...prevState,
          pageIndex: 0,
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numOfItems]);

  useEffect(() => {
    if (currentPage >= 0) {
      setPagination((prevState) => ({
        ...prevState,
        pageIndex: currentPage,
      }));
    }
  }, [currentPage]);

  return { pagination, setPagination };
};
