import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const SuggestionsContainer = styled.div`
  display: block;
`;

export const SuggestionsHeading = styled.header`
  color: ${(props) => props.theme.colors.textPrimary};
  padding-bottom: ${() => vars.spacing.xxs};
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('caption1')};
`;
export const SuggestionContainer = styled.div`
  padding: ${() => `${vars.spacing.sm} ${vars.spacing.lg}`};
  margin: ${() => `0 -${vars.spacing.lg}`};
  :hover {
    background-color: ${(props) => props.theme.colors.backgroundBrandLight};
  }
`;

export const Suggestion = styled.button`
  text-align: left;
  text-transform: capitalize;
  padding: 0;
  border: none;
  background: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('body1')};
`;
