import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const CancelledHoldIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 24">
      <path
        d="M23.5 18.5C23.5 21.3 21.3 23.5 18.5 23.5C15.7 23.5 13.5 21.3 13.5 18.5C13.5 15.7 15.7 13.5 18.5 13.5C21.3 13.5 23.5 15.7 23.5 18.5Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M16.2998 16.2L20.7998 20.7999"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7998 16.2L16.2998 20.7999"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 20.5C8.82843 20.5 9.5 19.8284 9.5 19C9.5 18.1716 8.82843 17.5 8 17.5C7.17157 17.5 6.5 18.1716 6.5 19C6.5 19.8284 7.17157 20.5 8 20.5Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 0.5H4L8 17.5H12"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.9999 11L23.4999 6.5H5.3999"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.2998 14.5H11.9998"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
