import { useMemo } from 'react';
import {
  PosMultiSelect,
  PosMultiSelectProps,
} from 'src/core/POS/PosMultiSelect';
import { useStateProvinces } from 'src/hooks/api/useStateProvinces';
import { ContentId } from 'src/utils/constants/contentId';
import { StateProvince } from 'src/WebApiController';

export const StateProvinceMultiSelector = ({
  placeholderText,
  countryCodes,
  ...props
}: Omit<PosMultiSelectProps, 'valueOptionsContent'> & {
  countryCodes: string[] | null;
}) => {
  const stateProvincesQuery = useStateProvinces();

  const stateProvincesOptions = useMemo(() => {
    if (stateProvincesQuery.data == null) {
      return {};
    }

    let stateProvinces: StateProvince[] = [];

    if (countryCodes?.length) {
      stateProvinces = countryCodes.flatMap((countryCode) => {
        return stateProvincesQuery.data?.[countryCode] ?? [];
      });
    } else {
      stateProvinces = Object.values(stateProvincesQuery.data).flatMap(
        (stateProvinces) => stateProvinces
      );
    }

    return stateProvinces.reduce(
      (r, s) => {
        if (!s.name) {
          return r;
        }
        r[s.id] =
          countryCodes?.length === 1
            ? s.name
            : `${s.name}${s.countryName ? ', ' : ''}${s.countryName}`;
        return r;
      },
      {} as Record<number, string>
    );
  }, [countryCodes, stateProvincesQuery.data]);

  return (
    <PosMultiSelect
      {...props}
      loading={stateProvincesQuery.data == null}
      placeholderText={placeholderText ?? ContentId.SelectStateProvince}
      valueOptionsContent={stateProvincesOptions}
      showSelectedOptionsFirst
    />
  );
};
