import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const PendingQCIcon = (props: PosIconProps) => {
  const fillColor = props.fill || '#87909A';
  return (
    <SvgWrapper {...props} fill="none" viewBox="0 0 20 20">
      <path
        d="M10.0002 18.8937C14.9121 18.8937 18.894 14.9118 18.894 9.99996C18.894 5.08807 14.9121 1.1062 10.0002 1.1062C5.08832 1.1062 1.10645 5.08807 1.10645 9.99996C1.10645 14.9118 5.08832 18.8937 10.0002 18.8937Z"
        stroke={fillColor}
      />
      <path
        d="M6.86437 12.9559C8.20092 12.9559 9.28441 11.6724 9.28441 10.0891C9.28441 8.50581 8.20092 7.22229 6.86437 7.22229C5.52782 7.22229 4.44434 8.50581 4.44434 10.0891C4.44434 11.6724 5.52782 12.9559 6.86437 12.9559Z"
        stroke="#87909A"
        strokeWidth={fillColor}
      />
      <path
        d="M15.0927 8.49633C14.7136 7.72801 14.0677 7.22217 13.3348 7.22217C12.1672 7.22217 11.2207 8.50569 11.2207 10.089C11.2207 11.6723 12.1672 12.9558 13.3348 12.9558C14.0677 12.9558 14.7136 12.4499 15.0927 11.6816"
        stroke="#87909A"
        strokeWidth={fillColor}
      />
      <path
        d="M7.34863 11.0447L9.2847 12.9559"
        stroke={fillColor}
        strokeWidth="0.884617"
      />
    </SvgWrapper>
  );
};
