import { useCallback, useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { Slider } from 'src/core/ui/Slider';
import { ContentId } from 'src/utils/constants/contentId';

import { AutoPricingSettingsInput } from '../../BulkEditAutoPricingSettingsDialog.types';
import { useAutoPricingSettings } from '../../useBulkAutoPricingSettings';
import { PricingSetting } from '../PricingSetting';
import { SettingsSubheader } from '../PricingSubheader';

type PricingRestrictionProps = {
  origin: Pick<AutoPricingSettingsInput, 'circuitBreakerMinCompListingCount'>;
};

export const PricingRestriction: React.FC<PricingRestrictionProps> = ({
  origin,
}) => {
  const { circuitBreakerMinCompListingCount, onCircuitBreakerChange } =
    useAutoPricingSettings();

  const minCompListingCountHasChange = useMemo(
    () =>
      origin.circuitBreakerMinCompListingCount?.value !==
      circuitBreakerMinCompListingCount?.value,
    [
      circuitBreakerMinCompListingCount?.value,
      origin.circuitBreakerMinCompListingCount?.value,
    ]
  );
  const onResetMinCompListingCount = useCallback(
    () =>
      onCircuitBreakerChange(
        undefined,
        undefined,
        undefined,
        undefined,
        origin.circuitBreakerMinCompListingCount?.value
      ),
    [onCircuitBreakerChange, origin.circuitBreakerMinCompListingCount?.value]
  );

  return (
    <>
      <SettingsSubheader title={ContentId.Restrictions} />
      <PricingSetting
        header={<Content id={ContentId.MinListingsRequired} />}
        detail={
          <Slider.Root
            min={1}
            max={30}
            step={1}
            value={[circuitBreakerMinCompListingCount?.value ?? 10]}
            onValueChange={([count]) => {
              onCircuitBreakerChange(
                undefined,
                undefined,
                undefined,
                undefined,
                Math.round(count)
              );
            }}
          />
        }
        hasChange={minCompListingCountHasChange}
        hasConflict={origin.circuitBreakerMinCompListingCount?.hasConflict}
        onReset={onResetMinCompListingCount}
      />
    </>
  );
};
