import { useEffect, useMemo } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useEventLastReviewedContext } from 'src/contexts/SellerEventLastReviewContext/SellerEventLastReviewContext';
import { Stack } from 'src/core/ui';
import { useOverflowX } from 'src/hooks/useOverflowX';
import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  POS_EVENT_IDS_SEPARATOR,
} from 'src/navigations/Routes/InventoryEvent/InventoryEvent.constants';

import { useCatchUpParams } from '../InventoryEventPage.hooks';
import * as styles from './InventoryEventCatchUp.css';
import {
  InventoryEventCatchUpNextButton,
  InventoryEventCatchUpPrevButton,
} from './InventoryEventCatchUpBarButtons';
import { InventoryEventCatchUpCard } from './InventoryEventCatchUpCard';
import { InventoryEventCatchUpCardProps } from './InventoryEventCatchUpCard.types';
import { InventoryEventCatchUpDropdown } from './InventoryEventCatchUpDropdown';

export function InventoryEventCatchUpBar() {
  const { eventsTransformed } = useCatalogDataContext();

  const { ref, isOverflowX } = useOverflowX();

  const {
    viagVirtualIdsList,
    posEventIdsList,
    activeEventIds,
    activeViagVirtualId,
    setSearchParams,
    numberOfEvents,
  } = useCatchUpParams();

  const { eventLastReviewed, onSettingVirtualIds } =
    useEventLastReviewedContext();

  useEffect(() => {
    onSettingVirtualIds(viagVirtualIdsList ?? []);
  }, [onSettingVirtualIds, viagVirtualIdsList]);

  const selectedIndex = useMemo(() => {
    if (activeEventIds && posEventIdsList) {
      const activePosEventIds = activeEventIds.split(POS_EVENT_IDS_SEPARATOR);
      return posEventIdsList.findIndex((posEventIds) =>
        activePosEventIds.some((posEventId) => posEventIds.includes(posEventId))
      );
    } else if (activeViagVirtualId && viagVirtualIdsList) {
      return viagVirtualIdsList.findIndex(
        (viagId) => activeViagVirtualId === viagId
      );
    }
  }, [
    activeEventIds,
    activeViagVirtualId,
    posEventIdsList,
    viagVirtualIdsList,
  ]);

  const cardPropsOld = useMemo(() => {
    return posEventIdsList?.map((posEventIds, i) => {
      const accordionItemProps = eventsTransformed?.find((e) =>
        e.event.posIds.some((id) => posEventIds.includes(id.toLowerCase()))
      );

      return {
        event: accordionItemProps?.event ?? [],
        onClick: () => {
          setSearchParams((prev) => {
            prev.set(
              ACTIVE_EVENT_IDS_QUERY_PARAM,
              accordionItemProps?.event.posIds.join(POS_EVENT_IDS_SEPARATOR) ??
                ''
            );
            return prev;
          });
        },
        isActive: i === selectedIndex,
      };
    }) as InventoryEventCatchUpCardProps[];
  }, [eventsTransformed, posEventIdsList, selectedIndex, setSearchParams]);

  const cardPropsNew = useMemo(() => {
    return viagVirtualIdsList?.map((viagVirtIds, i) => {
      const accordionItemProps = eventsTransformed?.find((e) =>
        viagVirtIds.includes(e.event.viagVirtualId.toLowerCase())
      );

      return {
        event: accordionItemProps?.event ?? [],
        onClick: () => {
          setSearchParams((prev) => {
            prev.set(
              ACTIVE_EVENT_IDS_2_QUERY_PARAM,
              accordionItemProps?.event.viagVirtualId ?? ''
            );
            return prev;
          });
        },
        isActive: i === selectedIndex,
        isReviewed: eventLastReviewed?.[viagVirtIds] != null,
      };
    }) as InventoryEventCatchUpCardProps[];
  }, [
    eventLastReviewed,
    eventsTransformed,
    selectedIndex,
    setSearchParams,
    viagVirtualIdsList,
  ]);

  useEffect(() => {
    if (ref.current) {
      const childElements = ref.current.children;
      if (selectedIndex != null && childElements[selectedIndex]) {
        childElements[selectedIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIndex]);

  const cardProps = cardPropsNew ?? cardPropsOld;
  if (!cardProps) return;

  return (
    <Stack direction="column" gap="s" className={styles.root}>
      <Stack direction="row" gap="m" width="full">
        <InventoryEventCatchUpPrevButton />

        <Stack
          direction="row"
          gap="m"
          className={styles.inventoryEventCatchUpCardContainer}
          ref={ref}
        >
          {cardProps.map((props, i) => (
            <InventoryEventCatchUpCard key={i} {...props} />
          ))}
        </Stack>

        <InventoryEventCatchUpNextButton />
        {isOverflowX && <InventoryEventCatchUpDropdown cardProps={cardProps} />}
      </Stack>

      <div className={styles.numberContainer}>
        {selectedIndex != null && numberOfEvents && (
          <span>{`${selectedIndex + 1} / ${numberOfEvents}`}</span>
        )}
      </div>
    </Stack>
  );
}
