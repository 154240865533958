import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const HeaderRightGroup = styled.div<{ editMode?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ editMode, theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      text-align: ${editMode ? 'left' : 'right'};
      max-width: calc(50% - ${vars.spacing.lg});
      align-items: ${editMode ? 'flex-start' : 'flex-end'};
      `
    )}`}
`;

export const HeaderLeftGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      max-width: calc(50% - ${vars.spacing.lg});
      `
    )}`}
`;

export const HeaderVendorContainer = styled.div`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title4')};
  padding-block-end: ${() => vars.spacing.lg};

  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PurchaseOrderStateContainer = styled.div`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title4')};

  color: ${(props) => props.theme.colors.textNegative};
  padding-block-end: ${() => vars.spacing.lg};
  width: min-content;
  border: 1px solid ${(props) => props.theme.colors.textNegative};
  border-radius: ${() => vars.spacing.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${() => vars.spacing.sm};
`;

export const DeliverAlternativeAccountCheckboxWrapper = styled.span``;
