import { useCallback, useContext } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { DetailText } from 'src/modals/common';
import { ViewTransferTickets } from 'src/modals/TransferTickets/ViewTransferTickets';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { ArtifactType } from 'src/WebApiController';
import { SaleDetails } from 'src/WebApiController';

import { ModalProps } from '../Modal';
import { ViewBarcodes } from '../UploadBarcodes';
import { ViewETickets } from '../UploadETickets';
import { ViewExternalIds } from '../UploadExternalIds';
import { ViewTicketUrls } from '../UploadTicketUrls';

function getViewActionConfig(artifactType: ArtifactType | null) {
  switch (artifactType) {
    case ArtifactType.Barcode:
      return {
        contentId: ContentId.ViewBarcodes,
        ViewActionModal: ViewBarcodes,
      };
    case ArtifactType.ETicket:
      return {
        contentId: ContentId.ViewETickets,
        ViewActionModal: ViewETickets,
      };
    case ArtifactType.QRCode:
      return {
        contentId: ContentId.ViewQRCodes,
        ViewActionModal: ViewETickets,
      };
    case ArtifactType.TransferProof:
      return {
        contentId: ContentId.ViewTransferProof,
        ViewActionModal: ViewTransferTickets,
      };
    case ArtifactType.ETicketUrl:
      return {
        contentId: ContentId.ViewWallet,
        ViewActionModal: ViewTransferTickets,
      };
    case ArtifactType.TicketUrl:
      return {
        contentId: ContentId.ViewWallet,
        ViewActionModal: ViewTicketUrls,
      };
    case ArtifactType.ExternalId:
      return {
        contentId: ContentId.ViewTicketIds,
        ViewActionModal: ViewExternalIds,
      };
    default:
      return {
        id: undefined,
        ViewActionModal: undefined,
      };
  }
}

export const ViewTickets = ({
  type,
  cancelTo,
  loadingContentId,
  searchingContentId,
  errorContentId,
}: {
  type: ArtifactType | null;
  cancelTo: ModalProps;
  loadingContentId: FormatContentId;
  searchingContentId: FormatContentId;
  errorContentId: FormatContentId;
}) => {
  const { posEntity } = useActivePosEntityContext<EntityWithRealTickets>();
  const { setModal } = useContext(ModalContext);
  const { ViewActionModal, contentId } = getViewActionConfig(type);

  const notAvailableContent = (
    <DetailText>
      {posEntity?.mkp ? (
        <FormatContent
          id={FormatContentId.FulfilledExternalMarketplace}
          params={`${posEntity.mkp}`}
        />
      ) : (
        <Content id={ContentId.FulfilledExternalMarketplace} />
      )}
    </DetailText>
  );

  const viewTickets = useCallback(() => {
    if (!ViewActionModal) {
      return;
    }

    setModal({
      children: (
        <ViewActionModal
          artifactType={type}
          cancelTo={cancelTo}
          isReadOnly
          loadingContentId={loadingContentId}
          searchingContentId={searchingContentId}
          errorContentId={errorContentId}
        />
      ),
      size: 'slide-in',
      clickOutsideToClose: true,
      deepLinkKey: cancelTo.deepLinkKey,
      deepLinkValue: cancelTo.deepLinkValue,
    });
  }, [
    ViewActionModal,
    setModal,
    type,
    cancelTo,
    loadingContentId,
    searchingContentId,
    errorContentId,
  ]);

  if (!ViewActionModal) {
    return notAvailableContent;
  }

  if (type === ArtifactType.TransferProof) {
    // Transfer is only on Sale
    const sale = posEntity as SaleDetails;
    if (
      // If it has none of the transfer artifacts
      !(
        sale?.transferProofDocs ||
        sale?.transferUrls ||
        sale?.transferProofId
      ) &&
      // or if it has no barcode to show
      (sale?.tickets ?? []).findIndex((t) => t.barcode) < 0
    ) {
      return notAvailableContent;
    }
  }

  // Both the below can be Sale or Listing (predelivered)

  if (type === ArtifactType.Barcode) {
    // if has at least one barcode, show the barcodes view, else show not available
    if ((posEntity?.tickets ?? []).findIndex((t) => t.barcode) < 0) {
      return notAvailableContent;
    }
  } else if (type === ArtifactType.ETicket) {
    // if has at least one e-ticket docs, show the docs view, else show not available
    if ((posEntity?.tickets ?? []).findIndex((t) => t.eTicket) < 0) {
      return notAvailableContent;
    }
  } else if (type === ArtifactType.TicketUrl) {
    // if has at least one e-ticket docs, show the docs view, else show not available
    if ((posEntity?.tickets ?? []).findIndex((t) => t.ticketUrl) < 0) {
      return notAvailableContent;
    }
  } else if (type === ArtifactType.ExternalId) {
    // if has at least one e-ticket docs, show the docs view, else show not available
    if ((posEntity?.tickets ?? []).findIndex((t) => t.externalId) < 0) {
      return notAvailableContent;
    }
  }

  return contentId ? (
    <Button
      variant={'link'}
      onClick={viewTickets}
      size="unset"
      style={{ padding: `${vars.spacing['sm']} 0`, width: 'fit-content' }}
    >
      <Content id={contentId} />
    </Button>
  ) : null;
};
