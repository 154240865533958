import { ComponentProps, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { AdGroupSectionSelector } from 'src/components/Selectors/AdGroupSectionSelector';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { PillItemProps } from 'src/core/ui/PillList/PillItem';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { IconsFill, PlusIcon, SeatIcon } from 'src/svgs/Viagogo';
import { EmptySelectionKey } from 'src/utils/adGroupUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { getMatchingSectionRow } from 'src/utils/seatScoreUtils';
import { Seating, TicketClassInfo } from 'src/WebApiController';

import { BodySectionTitle, FieldWrapper } from '../common';
import * as styles from './AdGroupCreate.css';
import { BodySectionBody } from './AdGroupCreate.styled';
import { AdGroupSelectError } from './AdGroupSelectError';

export type SeatingSectionInput = {
  seating: Seating | null | undefined;
};

export interface SeatingSectionProps {
  onSelectSeating: (
    seating: Seating | null | undefined,
    ticketClass: TicketClassInfo | null | undefined
  ) => void;
  eventId?: number;
  ticketClass?: TicketClassInfo;
  seating: Seating | null | undefined;
  error?: string;
}

export const SeatingSection = ({
  onSelectSeating,
  eventId,
  ticketClass,
  seating,
  error,
}: SeatingSectionProps) => {
  const addSeatingDialog = useBasicDialog();
  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const event = useMemo(() => {
    if (!eventId || !catalogData?.events?.[eventId.toString()]) {
      return undefined;
    }

    return catalogData.events[eventId.toString()].event;
  }, [catalogData, eventId]);

  const onAddSeatingSave = useCallback(
    (
      seating: Seating | null | undefined,
      ticketClass: TicketClassInfo | null | undefined
    ) => {
      onSelectSeating(seating, ticketClass);
      addSeatingDialog.closeDialog();
    },
    [onSelectSeating, addSeatingDialog]
  );

  const onAddSeatingCancel = useCallback(() => {
    addSeatingDialog.closeDialog();
  }, [addSeatingDialog]);

  const onAddSeating = useCallback(() => {
    addSeatingDialog.launchDialog();
  }, [addSeatingDialog]);

  const contentNA = useContent(ContentId.NA);

  const pills = useMemo(() => {
    const pills = [] as PillItemProps[];
    if (seating !== undefined) {
      pills.push({
        value: 'seating',
        display: seating?.section ?? contentNA,
        icon: <SeatIcon />,
        onDelete: () => {
          onSelectSeating(undefined, undefined);
        },
      });
    }

    return pills;
  }, [seating, contentNA, onSelectSeating]);

  const enableButton = event;

  return (
    <>
      <div className={styles.bodySection}>
        <BodySectionTitle>
          <Content id={ContentId.Section} />
        </BodySectionTitle>
        {seating !== undefined ? (
          <BodySectionBody>
            <PillList pills={pills} />
          </BodySectionBody>
        ) : (
          <BodySectionBody>
            <Button
              variant={'link'}
              onClick={onAddSeating}
              disabled={!enableButton}
            >
              <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
              <Content id={ContentId.AddSection} />
            </Button>
            <AdGroupSelectError error={error} />
          </BodySectionBody>
        )}
      </div>
      {addSeatingDialog.dialogProps.isOpen && (
        <EventMapContextProvider event={event}>
          <AddSeatingDialog
            {...addSeatingDialog.dialogProps}
            eventId={eventId}
            ticketClass={ticketClass}
            onSave={onAddSeatingSave}
            onCancel={onAddSeatingCancel}
          />
        </EventMapContextProvider>
      )}
    </>
  );
};

export type AddSeatingFieldValues = {
  seating: Seating | null | undefined;
  ticketClass: TicketClassInfo | null | undefined;
};

export type AddSeatingDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onSave: (
    seating: Seating | null | undefined,
    ticketClass: TicketClassInfo | null | undefined
  ) => void;
  onCancel: () => void;
  eventId?: number;
  ticketClass?: TicketClassInfo;
};

export const AddSeatingDialog = ({
  onSave,
  onCancel,
  eventId,
  ticketClass,
  ...genericDialogProps
}: AddSeatingDialogProps) => {
  const addSeatingForm = useForm<AddSeatingFieldValues>({
    defaultValues: {
      seating: undefined,
    },
  });

  const seating = addSeatingForm.watch('seating');

  const onSubmit = useCallback(() => {
    onSave(seating, addSeatingForm.getValues('ticketClass'));
  }, [onSave, seating, addSeatingForm]);

  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const getListingsForEvent = useCallback(
    (eventId: number | undefined) => {
      if (eventId == null || catalogData == null) {
        return undefined;
      }
      return catalogData?.events[eventId].listings;
    },
    [catalogData]
  );

  const getSectionsForEvent = useCallback(
    (eventId: number | undefined) => {
      if (eventId == null || catalogData == null) {
        return undefined;
      }
      return catalogData?.venueMapByEventId[eventId].sections;
    },
    [catalogData]
  );

  const listingsData = getListingsForEvent(eventId);
  const sectionsData = getSectionsForEvent(eventId);

  const seatings = useMemo(() => {
    if (!listingsData) {
      return null;
    }

    return (
      listingsData.reduce(
        (acc, listing) => {
          const seating = listing.seating;
          const sectionId = listing.seating.sectionId;

          if (seating != null && sectionId != null) {
            if (ticketClass) {
              // Filter by ticket class id if needed
              const row = getMatchingSectionRow(listing.seating, sectionsData);

              const ticketClassId =
                row.section?.specRow?.tktClass?.ticketClassId;
              if (ticketClassId == ticketClass.id) {
                acc[sectionId] = listing.seating;
              }
            } else {
              acc[sectionId] = listing.seating;
            }
          }

          return acc;
        },
        {} as Record<string, Seating>
      ) ?? {}
    );
  }, [listingsData, sectionsData, ticketClass]);

  const setSeating = useCallback(
    (seatingId: string) => {
      if (!seatings) {
        return;
      }

      const seating =
        seatingId == EmptySelectionKey
          ? null
          : seatings[parseInt(seatingId, 10)];
      addSeatingForm.clearErrors('seating');
      addSeatingForm.setValue('seating', seating);
      addSeatingForm.setValue('ticketClass', null);

      if (seating) {
        const row = getMatchingSectionRow(seating, sectionsData);

        const ticketClass = row.section?.specRow?.tktClass;
        if (ticketClass) {
          addSeatingForm.setValue('ticketClass', {
            id: ticketClass?.ticketClassId,
            name: ticketClass?.ticketClassName,
            color: ticketClass?.color,
            cssPostFix: ticketClass?.cssPostFix,
          });
        }
      }
    },
    [addSeatingForm, seatings, sectionsData]
  );

  return (
    <GenericDialog
      size="xl"
      header={<Content id={ContentId.Seating} />}
      footer={
        <>
          <CancelButton onClick={onCancel} />
          <Button
            variant={'regular'}
            onClick={addSeatingForm.handleSubmit(onSubmit)}
          >
            <Content id={ContentId.OK} />
          </Button>
        </>
      }
      onClosed={() => {
        addSeatingForm.reset();
      }}
      {...genericDialogProps}
      onCancel={onCancel}
    >
      <FieldWrapper>
        <PosFormField label={<Content id={ContentId.Seating} />}>
          <AdGroupSectionSelector
            seatings={seatings}
            value={
              seating === null
                ? EmptySelectionKey
                : seating?.sectionId?.toString()
            }
            onChange={(seatingId) => {
              setSeating(seatingId);
            }}
            style={{ width: '100%' }}
          />
        </PosFormField>
      </FieldWrapper>
    </GenericDialog>
  );
};
