import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function MyTicketsOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M22.592 13.072l-1.588.23.798 3.156-11.708 2.915 8.538-5.752 3.493-2.379.731-.499-.729-1.15-1.662-2.467-.462.032a1.707 1.707 0 01-1.44-.703 1.695 1.695 0 01-.106-1.6l.213-.398-.282-.438L16.223.908 1.122 11.099l3.457 5.137 1.444 5.773L23.7 17.607l-1.109-4.535zm-1.952-2.73L8.172 18.755 3.338 11.5l12.468-8.413 1.046 1.572a3.222 3.222 0 00.392 2.73 3.082 3.082 0 002.35 1.382l1.046 1.571zM9.374 9.848L8.93 9.19l-2.627 1.771.443.657 2.627-1.771zm1.106 1.627l-.664-.985-2.627 1.771.664.985 2.627-1.771zm.885 1.299l-.443-.657-2.626 1.772.442.656 2.627-1.771zm1.109 1.628l-.665-.985-2.626 1.772.664.985 2.627-1.772z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
