import { RecipeVariants } from '@vanilla-extract/recipes';
import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react';
import { mergeProps } from 'src/core/utils';

import * as styles from './TextField.css';

export const Root = forwardRef<
  HTMLDivElement,
  Omit<ComponentPropsWithoutRef<'div'>, 'onChange' | 'onKeyDown' | 'onKeyUp'> &
    RecipeVariants<typeof styles.root.textBox> & { disabled?: boolean }
>(function Root(
  { variant = 'regular', shape = 'square', state, children, ...props },
  ref
): ReactElement {
  return (
    <div
      ref={ref}
      {...mergeProps(
        {
          className: styles.root.textBox({
            state: props.disabled ? 'disabled' : state,
            variant,
            shape,
          }),
        },
        props
      )}
    >
      {children}
    </div>
  );
});

export const Input = forwardRef<
  HTMLInputElement,
  Omit<ComponentPropsWithoutRef<'input'>, 'type' | 'children'> &
    RecipeVariants<typeof styles.input.textBox> & {
      // We want to restrict the type prop to only textual type
      // Other input types will be handled with another control
      type?:
        | 'date'
        | 'datetime-local'
        | 'email'
        | 'file'
        | 'month'
        | 'number'
        | 'password'
        | 'search'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'week';
    }
>(function Input(
  { type = 'text', alignment = 'left', onKeyDown, ...props },
  ref
): ReactElement {
  return (
    <input
      ref={ref}
      type={type}
      {...mergeProps(
        {
          className: styles.input.textBox({
            alignment,
          }),
        },
        props
      )}
      onKeyDown={(e) => {
        if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
          // if the key presss is left or right arrow, we want to keep it from affecting outside controls
          e.stopPropagation();
        }

        onKeyDown?.(e);
      }}
    />
  );
});

export const Area = forwardRef<
  HTMLTextAreaElement,
  Omit<ComponentPropsWithoutRef<'textarea'>, 'type' | 'children'> &
    RecipeVariants<typeof styles.input.textBox>
>(function Area({ alignment = 'left', ...props }, ref): ReactElement {
  return (
    <textarea
      ref={ref}
      {...mergeProps(
        {
          className: styles.input.textBox({
            alignment,
          }),
        },
        props
      )}
    />
  );
});
