import { UseFormSetValue } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext/Content';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { DealSplitType, DealType } from 'src/WebApiController';

import { createDefaultDealConfig } from './DealConfigSection';
import { posChangedField } from 'src/utils/posFieldUtils';
import { useDealContext } from 'src/contexts/DealContext/DealContext';

export const DealTypeSection = ({
  dealType,
  setValue,
}: {
  dealType?: DealType;
  setValue: UseFormSetValue<PurchaseOrderDetailsInput>;
}) => {
  const { allActiveUserInfos } = useSellerAccountContext();
  const { setLocalDeal, updateLocalDealDetails} = useDealContext();


  return (
    <RadioGroup
      onValueChange={(value: DealType) => {
        setLocalDeal((prev) => {
          if (!prev) return prev;
          return { ...prev, dealType: value};
        });
        if (value === DealType.Guaranteed) {
          updateLocalDealDetails({dealConfigId: null});
          setValue('deal.value.dealDetails.dealConfigs', null);
        } else if (value === DealType.Sor) {
          updateLocalDealDetails({dealConfigId: null, configSplitType: DealSplitType.Quantity, 
            dealConfigs: posChangedField([
              createDefaultDealConfig('userId', allActiveUserInfos),
            ])});
        }
      }}
      value={dealType}
    >
      <Stack gap="m">
        <Radio value={DealType.Sor} label={<Content id={ContentId.SOR} />} />
        <Radio
          value={DealType.Guaranteed}
          label={<Content id={ContentId.GUR} />}
        />
      </Stack>
    </RadioGroup>
  );
};
