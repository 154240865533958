import { useCallback, useEffect, useState } from 'react';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { useBulkEditHubContext } from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { vars } from 'src/core/themes';
import { Button, Popover, Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { parseBulkEditMethodName } from 'src/hooks/useBulkEditHub';
import { CrossIcon, IconsFill, LoadingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { BulkEditStep } from 'src/WebApiController';

import {
  BulkEditStatus,
  BulkEditStatusProps,
} from '../BulkEditStatus/BulkEditStatus';
import {
  ActionOutboxEntityTypeForBulkEdit,
  getTitleForMethod,
} from '../BulkEditStatus/bulkEditUtils';
import * as styles from './BulkEditStatusPopover.css';

export const BulkEditStatusPopoverContent = ({
  titleContentId,
  ...rest
}: BulkEditStatusProps & { titleContentId: ContentId }) => {
  return (
    <Stack direction="column" width="full" gap="m">
      <Content id={titleContentId} />
      <BulkEditStatus {...rest} />
    </Stack>
  );
};

export const BulkEditStatusPopover = ({
  entityType,
}: {
  entityType: ActionOutboxEntityTypeForBulkEdit;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const {
    progress,
    setProgress,
    errors,
    warnings,
    mainDialogOpened,
    setPreview,
    setCurJob,
    ...rest
  } = useBulkEditHubContext();

  const isDone =
    progress?.step === BulkEditStep.Done ||
    progress?.step === BulkEditStep.Cancelled;

  useEffect(() => {
    if (!showSpinner && !isDone) {
      setShowSpinner(true);
    }
    if (isDone && !isPopoverOpen && !mainDialogOpened) {
      setIsPopoverOpen(true);
    }
  }, [isDone, isPopoverOpen, mainDialogOpened, showSpinner]);

  const [isRefreshing, setIsRefreshing] = useState(false);
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshData } = usePurchaseDataContext();

  const onRefreshDoneData = useCallback(async () => {
    setIsRefreshing(true);
    setActivePosEntity(0);

    // Depending whether it's Listing, Sales, or Purchase
    // the context would be different here
    if (refreshData) {
      await refreshData(true);
    }
    if (refreshExpandedListItems) {
      await refreshExpandedListItems();
    }

    setIsRefreshing(false);
  }, [refreshData, refreshExpandedListItems, setActivePosEntity]);

  if ((!isPopoverOpen && !showSpinner) || progress == null) {
    return null;
  }

  return (
    <Popover.Root
      open={isPopoverOpen}
      onOpenChange={async (open) => {
        if (
          open == false && // closing
          progress?.step === BulkEditStep.Done // progress is done
        ) {
          await onRefreshDoneData();

          setShowSpinner(false);
          setProgress(undefined);
          setCurJob(undefined);
        }

        setIsPopoverOpen(open);
      }}
    >
      <Popover.Trigger asChild>
        <Button variant="text" textColor="primary" shape="pill" size="unset">
          {isDone ? (
            <LoadingIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              align="middle"
            />
          ) : (
            <RotatingWrapper>
              <LoadingIcon
                withHoverEffect
                fill={IconsFill.textBrand}
                align="middle"
              />
            </RotatingWrapper>
          )}
        </Button>
      </Popover.Trigger>
      <Popover.Content align="end">
        <div className={styles.popoverContainer}>
          <Stack justifyContent="end">
            <CrossIcon
              withHoverEffect
              onClick={async () => {
                if (isDone) {
                  await onRefreshDoneData();

                  setShowSpinner(false);
                  setProgress(undefined);
                  setCurJob(undefined);
                }
                setIsPopoverOpen(false);
              }}
            />
          </Stack>
          {progress && (
            <BulkEditStatusPopoverContent
              titleContentId={getTitleForMethod(
                parseBulkEditMethodName(progress.method)
              )}
              isLoading={isRefreshing}
              entityType={entityType}
              updateKey={progress.updateKey}
              {...rest}
            />
          )}
          {isDone && (
            <Stack
              justifyContent="end"
              style={{ paddingTop: vars.spacing['lg'] }}
            >
              <Button
                variant="outline"
                onClick={async () => {
                  setProgress(undefined);
                  setPreview(undefined);
                  setIsPopoverOpen(false);

                  await onRefreshDoneData();

                  setShowSpinner(false);
                  setCurJob(undefined);
                }}
              >
                <Content id={ContentId.Close} />
              </Button>
            </Stack>
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};
