import { useQuery } from '@tanstack/react-query';
import { once } from 'lodash-es';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  DATA_REFRESH_RATE_IN_MILLIS_LONG,
  MAX_NUM_OF_ITEMS_FOR_REPORT_VIEWS,
} from 'src/utils/constants/constants';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { getServerSideOnlyMetricsQuery } from 'src/utils/getServerSideOnlyMetricsQuery';
import {
  EntityWithTicketsQuery,
  Feature,
  ReportGroupBy,
  ReportMetricsBase,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { useFilterQueryContext } from '../FilterQueryContext';

export type IReportMetricsContext<TMetrics extends ReportMetricsBase> = {
  hasTimeout?: boolean;
  hasUnknownError?: boolean;
  isLoading: boolean;
  isRefreshing?: boolean;
  reportMetrics?: TMetrics[];
  reportSummary?: TMetrics;
  totalCount?: number;
  refreshMetrics: (overrideMax: boolean) => void;
  isAllReportMetricsLoaded?: boolean;
};

export const createReportMetricsContext = once(<
  TMetrics extends ReportMetricsBase,
>() => React.createContext({} as IReportMetricsContext<TMetrics>));

export function useReportMetricsContext<TMetrics extends ReportMetricsBase>() {
  return useContext(createReportMetricsContext<TMetrics>());
}
type ReportMetricsResponse<TMetrics extends ReportMetricsBase> = {
  metrics: TMetrics[];
  summary: TMetrics;
  totalCount: number;
  hasTimeout: boolean;
  hasUnknownError: boolean;
};

export function ReportMetricsContextProvider<
  TMetrics extends ReportMetricsBase,
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>({
  queryKey,
  getReportMetrics,
  groupBy,
  reportMetricsRequested,
  children,
  disabled,
  applyMaxRowLimitByDefault = true,
}: PropsWithChildren<{
  queryKey: string;
  getReportMetrics?: (
    filterQuery: TQuery,
    groupBy: ReportGroupBy,
    reportMetricsRequested?: string[],
    maxResultSize?: number
  ) => Promise<ReportMetricsResponse<TMetrics>>;
  groupBy?: ReportGroupBy;
  reportMetricsRequested?: string[];
  disabled?: boolean;
  applyMaxRowLimitByDefault?: boolean;
}>) {
  const ReportMetricsContext = createReportMetricsContext<TMetrics>();

  const [reportConfigMaxSizeOverride, setReportConfigMaxSizeOverride] =
    useState(!applyMaxRowLimitByDefault);

  const hasMaxRowLimitFeature = useUserHasFeature(Feature.ReportMaxRowLimit);

  const { trackError } = useErrorBoundaryContext();

  const { isLoading, allEventIds } = useCatalogDataContext();
  const { filterQuery } = useFilterQueryContext<TQuery>();
  const { activeAccountWebClientConfig } = useAppContext();

  // Removing the client-side queries
  const transformedQuery = getServerSideOnlyMetricsQuery(filterQuery) as TQuery;

  const shouldQuery =
    !disabled &&
    !(
      !getReportMetrics ||
      !transformedQuery ||
      !groupBy ||
      !activeAccountWebClientConfig.activeAccountId ||
      !allEventIds
    );
  const maxResultSize = useMemo(
    () =>
      // has no max row feature or has bypassed limit
      !hasMaxRowLimitFeature || reportConfigMaxSizeOverride
        ? undefined
        : MAX_NUM_OF_ITEMS_FOR_REPORT_VIEWS,
    [hasMaxRowLimitFeature, reportConfigMaxSizeOverride]
  );

  const reportMetricsQuery = useQuery({
    queryKey: [
      `${queryKey}-report-metrics`,
      transformedQuery,
      allEventIds,
      activeAccountWebClientConfig,
      groupBy,
      disabled,
      allEventIds == null,
      reportMetricsRequested,
      maxResultSize,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const reportMetricsData = await getReportMetrics!(
        {
          ...transformedQuery,
          eventOrMappingIds: allEventIds.map((ev) => ev.viagVirtualId),
          performerIds: allEventIds
            .filter((ev) => ev.performerId != null)
            .map((ev) => ev.performerId!),
          venueIds: allEventIds.map((ev) => ev.venueId),
        },
        groupBy!,
        reportMetricsRequested,
        maxResultSize
      );

      return reportMetricsData;
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      persist: !reportConfigMaxSizeOverride, // only persist if this is not a bypass max-size report

      onError: (error: ErrorTypes) => {
        trackError('getReportMetrics', error, {
          ...transformedQuery,
          eventIds: allEventIds,
        });
      },
    },
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  const refreshMetrics = useCallback(
    (overrideMax: boolean) => {
      if (reportConfigMaxSizeOverride !== overrideMax) {
        setReportConfigMaxSizeOverride(overrideMax);

        return; // we will let useQuery key-change do the re-query
      }

      // If we didn't update the overrideMax, just refetch the current result here
      reportMetricsQuery.refetch();
    },
    [reportConfigMaxSizeOverride, reportMetricsQuery]
  );

  const isAllReportMetricsLoaded =
    reportMetricsQuery.data?.totalCount != null &&
    reportMetricsQuery.data?.totalCount <=
      reportMetricsQuery.data?.metrics.length;

  return (
    <ReportMetricsContext.Provider
      value={{
        hasTimeout: reportMetricsQuery.data?.hasTimeout,
        hasUnknownError: reportMetricsQuery.data?.hasUnknownError,
        isLoading: reportMetricsQuery.isLoading || isLoading,
        isRefreshing: reportMetricsQuery.isRefetching,
        reportMetrics: reportMetricsQuery.data?.metrics ?? undefined,
        reportSummary: reportMetricsQuery.data?.summary ?? undefined,
        totalCount: reportMetricsQuery.data?.totalCount,
        refreshMetrics: refreshMetrics,
        isAllReportMetricsLoaded,
      }}
    >
      {children}
    </ReportMetricsContext.Provider>
  );
}
