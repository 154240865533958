import { REACT_APP_NAME } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditPurchaseClient,
  BulkEditSaleClient,
  BulkEditStep,
  ClientLogClient,
  LogLevel,
  PosClientConfig,
} from 'src/WebApiController';

export const BulkEditProgressingSteps = [
  BulkEditStep.Initializing,
  BulkEditStep.InProgress,
];

export type ActionOutboxEntityTypeForBulkEdit =
  | ActionOutboxEntityType.Listing
  | ActionOutboxEntityType.Sale
  | ActionOutboxEntityType.Purchase
  | ActionOutboxEntityType.TicketGroup;

export const cancelBulkEdit = async (
  entityType: ActionOutboxEntityType,
  activeAccountWebClientConfig: PosClientConfig,
  updateKey: string,
  progress?: BulkEditProgress | null,
  setProgress?: (newProgress: BulkEditProgress) => void
) => {
  const client =
    entityType === ActionOutboxEntityType.Listing
      ? new BulkEditListingClient(activeAccountWebClientConfig)
      : entityType === ActionOutboxEntityType.Sale
      ? new BulkEditSaleClient(activeAccountWebClientConfig)
      : entityType === ActionOutboxEntityType.Purchase
      ? new BulkEditPurchaseClient(activeAccountWebClientConfig)
      : undefined;

  if (client) {
    client.cancelBackgroundTask(updateKey);
    if (progress) {
      setProgress?.({
        ...progress,
        step: BulkEditStep.Cancelled,
      });
    }
  } else {
    new ClientLogClient(activeAccountWebClientConfig).log({
      logLevel: LogLevel.Warning,
      applicationSource: REACT_APP_NAME,
      errorId: null,
      error: `Unhandled BulkEditClient for entity: ${entityType}`,
      pageUrl: window.location.href,
      componentStack: 'bulkEditUtils.ts - cancelBulkEdit',
      additionalInfo: {
        entityType,
        updateKey,
        progress,
      },
    });
  }
};

export const bulkEditInProgress = (
  hasBackgroundBulkEditFeature?: boolean,
  isLoading?: boolean,
  progress?: BulkEditProgress
) => {
  const progressCountIsMax =
    (progress?.failureCount ?? 0) +
      (progress?.successCount ?? 0) +
      (progress?.warningCount ?? 0) >=
    (progress?.totalCount ?? 0);

  const progressPercentIsMax =
    (progress?.failurePercent ?? 0) +
      (progress?.successPercent ?? 0) +
      (progress?.warningPercent ?? 0) >=
    1;

  if (!hasBackgroundBulkEditFeature && isLoading) {
    return true;
  }

  if (progress?.step) {
    if (progress?.step === BulkEditStep.Initializing) {
      return true;
    }

    if (
      progress?.step === BulkEditStep.InProgress &&
      !(progressCountIsMax || progressPercentIsMax)
    ) {
      return true;
    }
  }

  return false;
};

export const getTitleForMethod = ({
  bulkActionType,
  entityType,
}: {
  bulkActionType: BulkActionType;
  entityType: ActionOutboxEntityType;
}) => {
  switch (bulkActionType) {
    case BulkActionType.UpdateBroadcastSettings:
      return ContentId.BroadcastSettings;
    case BulkActionType.UpdatePrices:
      return ContentId.IncreaseDecreasePrice;
    case BulkActionType.UpdateTagValues:
      return ContentId.UpdateTagValues;
    case BulkActionType.UpdatePriceFloorCeiling:
      return ContentId.UpdatePriceFloorCeiling;
    case BulkActionType.UpdateAutoPricingSettings:
      return ContentId.AutopricingSettings;
    case BulkActionType.UpdateMarketplacePrices:
      return ContentId.ListingSettings;
    case BulkActionType.UpdateMarketplaceSeating:
      return ContentId.UpdateMarketplaceSeating;
    case BulkActionType.GroupListings:
      return ContentId.GroupListings;
    case BulkActionType.ChangeEvent:
      return ContentId.ChangeEvent;
    case BulkActionType.AdminHold:
      return ContentId.Hold;
    case BulkActionType.UpdateDeliveryType:
      return ContentId.DeliveryStrategy;
    case BulkActionType.UpdateBuyerCommissions:
      return ContentId.BuyerCommission;

    case BulkActionType.UpdateGeneralSettings:
      if (entityType === ActionOutboxEntityType.Sale) {
        return ContentId.SaleSettings;
      } else if (entityType === ActionOutboxEntityType.Purchase) {
        return ContentId.PurchaseSettings;
      } else {
        return ContentId.ListingSettings;
      }
    case BulkActionType.UploadPurchaseFile:
      return ContentId.UploadPurchaseFile;

    case BulkActionType.UpdateInternalNotes:
      return ContentId.UpdatePrivateNotes;
    case BulkActionType.DeleteSeatSavers:
      return ContentId.DeletePlaceholders;
    case BulkActionType.RemovePredelivery:
      return ContentId.RemovePredelivery;
    default:
      return ContentId.BulkActions;
  }
};

export const getPrimaryAffectedIds = (
  preview: BulkEditPreviewWithDetails | null | undefined,
  entityType: ActionOutboxEntityTypeForBulkEdit
) => {
  const result =
    preview?.preview?.bulkEditCountPreview?.[entityType]?.idsAffected;
  return result?.length ? result : null;
};

export const hasPrimaryAffectedIds = (
  preview: BulkEditPreviewWithDetails | null | undefined,
  entityType:
    | ActionOutboxEntityTypeForBulkEdit
    | ActionOutboxEntityTypeForBulkEdit[]
) => {
  if (entityType instanceof Array) {
    return entityType.some(
      (et) => preview?.preview?.bulkEditCountPreview?.[et]?.idsAffected?.length
    );
  }

  return getPrimaryAffectedIds(preview, entityType) != null;
};
