import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { DraggableCore, DraggableData, DraggableEvent } from 'react-draggable';
import { SidePanelButton } from 'src/components/Buttons/SidePanelButton';
import {
  SidePanelPage,
  useSidePanelContext,
} from 'src/contexts/SidePanelContext';

import {
  ContainerStyledDiv,
  ContentStyledDiv,
  DividerContainer,
} from './ResizablePanel.styled';

const MIN_PANEL_SIZE = 120; // enough to show grouping info
const MAX_PANEL_SIZE = 420;

export enum ResizeEdge {
  North,
  South,
  East,
  West,
}

export type ResizablePanelProps = {
  direction: ResizeEdge;
  sidePanelPage?: SidePanelPage;
  initialSize?: number;
  disabled?: boolean;
  onResize?: (curSize: number) => void;
  autoHide?: boolean;
  minPanelSize?: number;
  maxPanelSize?: number;
  minPanelPercent?: number;
  maxPanelPercent?: number;
  defaultRatio?: number;
  showExpandButton?: boolean;
};

export function ResizablePanel({
  children,
  direction,
  initialSize,
  disabled,
  onResize,
  sidePanelPage,
  autoHide = true,
  minPanelSize = MIN_PANEL_SIZE,
  maxPanelSize = MAX_PANEL_SIZE,
  minPanelPercent,
  maxPanelPercent,
  defaultRatio = 0.25,
  showExpandButton,
}: PropsWithChildren<ResizablePanelProps>) {
  const {
    isHidden,
    setCollapsed,
    setHidden,
    sidePanelWidths,
    onUpdateSidePanelWidth,
  } = useSidePanelContext();
  const [disableAnimation, setDisabledAnimation] = useState(false);

  const isHorizontal =
    direction === ResizeEdge.East || direction === ResizeEdge.West;

  const contentRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const getMaxSize = useCallback(() => {
    const parentBound =
      wrapperRef.current?.parentElement?.getBoundingClientRect();

    const parentSize = isHorizontal ? parentBound?.width : parentBound?.height;
    const maxSize =
      maxPanelPercent && parentSize
        ? maxPanelPercent * parentSize
        : maxPanelSize;
    return parentSize ? Math.min(defaultRatio * parentSize, maxSize) : maxSize;
  }, [defaultRatio, isHorizontal, maxPanelPercent, maxPanelSize]);

  const [size, setSize] = useState(isHidden ? 0 : getMaxSize());

  const getMinSize = useCallback(() => {
    const parentBound =
      wrapperRef.current?.parentElement?.getBoundingClientRect();
    const parentSize = isHorizontal ? parentBound?.width : parentBound?.height;
    const minSize =
      minPanelPercent && parentSize
        ? minPanelPercent * parentSize
        : minPanelSize;

    return minSize;
  }, [isHorizontal, minPanelPercent, minPanelSize]);

  const updateSize = useCallback(
    (size: number) => {
      const minSize = getMinSize();
      let actualSize = size;
      if (actualSize < minSize) {
        actualSize = minSize;
      }
      if (actualSize > getMaxSize()) {
        actualSize = getMaxSize();
      }
      setCollapsed(actualSize <= minSize);
      if (actualSize <= minSize && autoHide) {
        setHidden(true);
      } else {
        setSize(actualSize);
        onResize?.(actualSize);
        if (sidePanelPage) {
          onUpdateSidePanelWidth(sidePanelPage, actualSize);
        }
      }
    },
    [
      getMinSize,
      getMaxSize,
      setCollapsed,
      autoHide,
      setHidden,
      onResize,
      onUpdateSidePanelWidth,
      sidePanelPage,
    ]
  );

  useEffect(() => {
    const content = contentRef.current;
    const parent = wrapperRef.current?.parentElement;
    if (content && parent) {
      const actualContent = content.children[0];

      if (sidePanelPage && sidePanelWidths?.[sidePanelPage]) {
        setSize(sidePanelWidths[sidePanelPage]);
      } else if (initialSize != null) {
        updateSize(initialSize);
      } else if (size === 0) {
        const bound = actualContent.getBoundingClientRect();
        const parentBound =
          wrapperRef.current?.parentElement?.getBoundingClientRect();
        const parentSize = isHorizontal
          ? parentBound?.width
          : parentBound?.height;

        let proposedSize = isHorizontal ? bound.width : bound.height;
        proposedSize = proposedSize || defaultRatio * parentSize;

        // Initialize the size value based on the content's current size
        updateSize(proposedSize);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSize]);

  useEffect(() => {
    if (isHidden) {
      setSize(0);
      onResize?.(0);
    } else {
      const defaultSize =
        (sidePanelPage && sidePanelWidths?.[sidePanelPage]) ?? getMaxSize();
      setSize(defaultSize);
      onResize?.(defaultSize);
      setCollapsed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHidden, sidePanelPage, sidePanelWidths]);

  const onDrag = useCallback(
    (_: DraggableEvent, data: DraggableData) => {
      const factor =
        direction === ResizeEdge.East || direction === ResizeEdge.South
          ? -1
          : 1;

      // modify the size based on the drag delta
      const delta = isHorizontal ? data.deltaX : data.deltaY;
      updateSize(Math.max(10, size - delta * factor));

      if (isHidden) {
        if (data.deltaX > 0 && direction === ResizeEdge.East) {
          setHidden(false);
        } else if (data.deltaX < 0 && direction === ResizeEdge.West) {
          setHidden(false);
        } else if (data.deltaY > 0 && direction === ResizeEdge.South) {
          setHidden(false);
        } else if (data.deltaY < 0 && direction === ResizeEdge.North) {
          setHidden(false);
        }
      }
    },
    [direction, isHidden, isHorizontal, setHidden, size, updateSize]
  );

  const divider = disabled ? null : (
    <DraggableCore
      onDrag={onDrag}
      onStart={() => setDisabledAnimation(true)}
      onStop={() => setDisabledAnimation(false)}
      disabled={disabled}
      grid={
        initialSize == null
          ? undefined
          : [isHorizontal ? initialSize : 0, !isHorizontal ? initialSize : 0]
      }
    >
      <DividerContainer isHorizontal={isHorizontal}>
        {showExpandButton && <SidePanelButton direction={direction} />}
        <div className="divider" />
      </DividerContainer>
    </DraggableCore>
  );
  const isHandleInFront =
    direction === ResizeEdge.West || direction === ResizeEdge.North;
  return (
    <ContainerStyledDiv ref={wrapperRef} isHorizontal={isHorizontal}>
      {isHandleInFront && divider}
      <ContentStyledDiv
        shouldAnimateContent={!disableAnimation}
        ref={contentRef}
        isHorizontal={isHorizontal}
        size={size}
      >
        {
          // this ensure that children is only 1 element
          React.Children.only(children)
        }
      </ContentStyledDiv>
      {!isHandleInFront && divider}
    </ContainerStyledDiv>
  );
}
