import { useContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseOrderDetails } from 'src/WebApiController';

import * as styles from './PurchaseOrderBasicInfo.css';

export const PurchaseOrderBasicInfo = ({
  purchaseOrder,
}: {
  purchaseOrder: PurchaseOrderDetails;
}) => {
  const purchaseOrderIdLabel = useContent(ContentId.UniqueId);
  const vendorLabel = useContent(ContentId.Vendor);
  const vendorAccountNameLabel = useContent(ContentId.VendorAccount);
  const purchaseDateLabel = useContent(ContentId.PurchaseDate);

  return (
    <div className={styles.purchaseOrderInfoContainer}>
      <span className={styles.attrLabel}>{purchaseOrderIdLabel}</span>
      <span className={styles.attrValue}>{purchaseOrder.id}</span>

      <span className={styles.attrLabel}>{vendorLabel}</span>
      <span className={styles.attrValue}>{purchaseOrder.vendor?.name}</span>

      <span className={styles.attrLabel}>{vendorAccountNameLabel}</span>
      <span className={styles.attrValue}>
        {purchaseOrder.vendAcc?.email || purchaseOrder.vendAcc?.name}
      </span>

      <span className={styles.attrLabel}>{purchaseDateLabel}</span>
      <span className={styles.attrValue}>{purchaseOrder.poDate}</span>
    </div>
  );
};
