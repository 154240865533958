import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { TagValueInput } from 'src/components/TagsFormBody/TagValueCell';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosSelect } from 'src/core/POS/PosSelect';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  Tag,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';
import { FieldLabelWrapper, FieldWrapper } from './BulkEditTagDialog.styled';

export enum BulkEditTagAction {
  Update = 'Update',
  Remove = 'Remove',
}
export type BulkEditTagDialogProps = ComponentProps<typeof RSModal> & {
  updateKey: string;
  action: BulkEditTagAction;
  onOkay: (
    action: BulkEditTagAction,
    tagKey: string | null,
    tagValue?: string | undefined,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.Purchase;
};

export function BulkEditTagDialog({
  updateKey,
  action,
  onOkay,
  onCancel,
  entityType,
  ...rest
}: BulkEditTagDialogProps) {
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshData } = usePurchaseDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();

  const [selectedTag, setSelectedTag] = useState<Tag>();
  const [tagValue, setTagValue] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        // Depending whether it's Listing, Sales, or Purchase
        // the context would be different here
        if (refreshData) {
          await refreshData(true);
        }
        if (refreshExpandedListItems) {
          await refreshExpandedListItems();
        }
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }

        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshData,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    entityType,
    BulkActionType.UpdateTagValues,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const { tagsMetadata: existingTagsMetadata = [] } =
    useTagsForEntityType(entityType);

  const keyOptionsContent = useMemo(() => {
    const keyOptions = (existingTagsMetadata ?? [])
      .sort((r1, r2) => r1.key.localeCompare(r2.key))
      .reduce(
        (re, r) => {
          re[r.key] = r.key;
          return re;
        },
        {} as Record<string, string>
      );

    return keyOptions ?? {};
  }, [existingTagsMetadata]);

  const isInputValid = useMemo(() => {
    return selectedTag && (tagValue || action === BulkEditTagAction.Remove);
  }, [action, selectedTag, tagValue]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(action, null, undefined, false, onPreviewReceived);
      } else {
        onOkay(
          action,
          selectedTag!.key,
          tagValue,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [
      onOkay,
      action,
      selectedTag,
      tagValue,
      preview,
      hasBackgroundBulkEditFeature,
    ]
  );

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <GenericDialog
        {...rest}
        size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
        header={
          <BulkEditHeader
            headerText={
              action === BulkEditTagAction.Update ? (
                <Content id={ContentId.UpdateTagValue} />
              ) : (
                <Content id={ContentId.RemoveTagValue} />
              )
            }
          />
        }
        onOpened={() => {
          setSelectedTag(undefined);
          setTagValue(undefined);
          initJob();
        }}
        onKeyUp={(e) => {
          if (submittButtonRef.current && e.key === 'Enter' && isInputValid) {
            submittButtonRef.current.click();
          }
        }}
        onClick={(e) => {
          // This so that this dialog does not cause event to flow to the EventHeader open/close event
          e.stopPropagation();
          e.preventDefault();
        }}
        onClosed={() => {
          setMainDialogOpened(false);
          if (progress) {
            setSelectionMode(undefined);
          }
          // Call the outside one if there is one
          rest.onClosed?.();
        }}
        footer={
          <BulkEditFooter
            entityType={entityType}
            isLoading={isLoading}
            hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
            onClose={onClose}
            onSubmit={onSubmitHandler}
            disabled={!isInputValid}
            submittButtonRef={submittButtonRef}
          />
        }
        onCancel={isLoading ? undefined : onCancel}
      >
        <BulkEditStatus
          entityType={entityType}
          isLoading={isRefreshing || isLoading}
          updateKey={updateKey}
        >
          <Stack direction="column" width="full">
            <FieldWrapper>
              <FieldLabelWrapper>
                <Content id={ContentId.SelectTag} />
              </FieldLabelWrapper>
              <PosSelect
                style={{ width: '100%' }}
                onChange={(newValue: string) => {
                  setSelectedTag(
                    (existingTagsMetadata ?? []).find((t) => t.key === newValue)
                  );
                }}
                value={selectedTag?.key ?? ''}
                displayText={selectedTag?.key ?? ''}
                searchable
                disabled={isLoading}
                valueOptionsContent={keyOptionsContent}
                placeholderText={ContentId.Name}
              />
            </FieldWrapper>
            {action !== BulkEditTagAction.Remove && (
              <>
                <FieldWrapper>
                  <FieldLabelWrapper>
                    <Content id={ContentId.UpdateTagValue} />
                  </FieldLabelWrapper>
                  <TagValueInput
                    tag={selectedTag}
                    newValue={tagValue ?? ''}
                    isValidNewValue={true}
                    setNewValue={setTagValue}
                    onChange={setTagValue}
                    disabled={isLoading}
                    style={{ maxWidth: 'unset', width: '100%' }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <WarningMessage
                    message={<Content id={ContentId.UpdateTagValueWarning} />}
                  />
                </FieldWrapper>
              </>
            )}
          </Stack>
        </BulkEditStatus>
      </GenericDialog>
    </>
  );
}
