import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { Content, useContentContext } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { HorizontalTextList } from 'src/core/POS/HorizontalTextList';
import { vars } from 'src/core/themes';
import { Stack, TooltipPopover } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ListingGroupIcon } from 'src/svgs';
import { TableShimmeringDiv } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { getListingNoteIcon } from 'src/utils/listingNotesUtils';
import { Feature, Listing } from 'src/WebApiController';

import * as styles from '../MergeListings.css';
import { GroupIdNameMap } from '../utils';

export const MergeSuggestionsDisplay = ({
  listings,
  listingCount,
  disabled,
  selected,
  onSelectedChange,
  listingGroupMap,
}: {
  listings: Listing[] | undefined;
  listingCount: number;
  disabled?: boolean;
  selected?: boolean;
  onSelectedChange?: (selected: boolean) => void;
  listingGroupMap: GroupIdNameMap;
}) => {
  return (
    <div className={styles.mergeSuggestionsDisplay}>
      {listings?.length ? (
        <>
          <Checkbox
            checked={!!selected}
            disabled={disabled}
            onChange={() => onSelectedChange?.(!selected)}
          />
          <div className={styles.mergeSuggestionListings}>
            {listings.map((l) => (
              <MergeSuggestionListingItem
                key={l.id}
                listing={l}
                listingGroupMap={listingGroupMap}
              />
            ))}
          </div>
        </>
      ) : (
        <div className={styles.mergeSuggestionListings}>
          {Array.from({ length: listingCount }).map((_, i) => (
            <div key={i} className={styles.mergeSuggestionListingItem}>
              <TableShimmeringDiv height={'28px'} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const MergeSuggestionListingItem = ({
  listing,
  listingGroupMap,
}: {
  listing: Listing;
  listingGroupMap: GroupIdNameMap;
}) => {
  const contentContext = useContentContext();
  const hasListingNotesAdditionalInfoFeature = useUserHasFeature(
    Feature.ListingNotesAdditionalInfo
  );
  const hasShowSeatTraitOnMergeFeature = useUserHasFeature(
    Feature.ShowSeatTraitOnMerge
  );

  return (
    <Stack
      className={styles.mergeSuggestionListingItem}
      direction="column"
      gap="s"
    >
      <Stack direction="row" gap="s">
        <SeatingInfo {...listing.seating} />
        <div style={{ marginLeft: vars.spacing.xxs }}>
          {listing.ltGrpId && listingGroupMap[listing.ltGrpId] && (
            <TooltipPopover
              triggerContent={
                <ListingGroupIcon
                  stroke={vars.color.textBrand}
                  size={vars.iconSize.m}
                />
              }
            >
              <div>
                <Content id={ContentId.MergeListingGroupTooltip} />{' '}
                <strong>{listingGroupMap[listing.ltGrpId]}</strong>
              </div>
            </TooltipPopover>
          )}
        </div>
      </Stack>
      {listing.seatTraits?.length && hasShowSeatTraitOnMergeFeature && (
        <HorizontalTextList
          className={styles.listingNotes}
          preIcon={getListingNoteIcon(
            listing.seatTraits[0],
            contentContext,
            hasListingNotesAdditionalInfoFeature
          )}
          texts={listing.seatTraits.map((n) => n.listingNoteText)}
          maxTextsToShowOnHover={listing.seatTraits.length}
        />
      )}
    </Stack>
  );
};
