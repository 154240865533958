import { CatalogResults, Listing } from 'src/WebApiController';

import { ExpandedEventData } from './CatalogDataContext';

export const updateCatalogResults = (
  catalogResults: CatalogResults,
  expandedEventsData: ExpandedEventData[]
): CatalogResults => {
  if (!expandedEventsData || !expandedEventsData?.length) {
    return catalogResults;
  }

  let finalCatalogResults: CatalogResults = catalogResults;

  for (const expandedEventData of expandedEventsData) {
    for (const eventId in expandedEventData) {
      const curEvent = finalCatalogResults.events[eventId];
      if (curEvent) {
        // Update listings
        const listings = expandedEventData[eventId].listings;
        if (listings) {
          const listCnt = listings.length;
          let listGrpCnt = 0,
            ungrListCnt = 0;
          listings.forEach((listing: Listing) => {
            listing.isLtGrp ? listGrpCnt++ : ungrListCnt++;
          });

          finalCatalogResults = {
            ...finalCatalogResults,
            events: {
              ...finalCatalogResults.events,
              [eventId]: {
                ...curEvent,
                listings,
                listCnt,
                listGrpCnt,
                ungrListCnt,
              },
            },
          };
        }

        // Update sales
        const sales = expandedEventData[eventId].sales;
        if (sales) {
          const salesCnt = sales.length;
          finalCatalogResults = {
            ...finalCatalogResults,
            events: {
              ...finalCatalogResults.events,
              [eventId]: {
                ...curEvent,
                sales,
                salesCnt,
              },
            },
          };
        }

        // Update ticketGroups
        const ticketGroups = expandedEventData[eventId].ticketGroups;
        if (ticketGroups) {
          const ticketGrpsCnt = ticketGroups.length;
          finalCatalogResults = {
            ...finalCatalogResults,
            events: {
              ...finalCatalogResults.events,
              [eventId]: {
                ...curEvent,
                ticketGroups,
                ticketGrpsCnt,
              },
            },
          };
        }
      }
    }
  }

  return finalCatalogResults;
};
