import { createContext, ReactNode, useContext, useState } from 'react';

export type IGenericDialogContext = {
  isDialogOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
};

export const GenericDialogContext = createContext<IGenericDialogContext>({
  isDialogOpen: false,
  setIsDialogOpen: () => undefined,
});

export const useGenericDialogContext = () => useContext(GenericDialogContext);

export function GenericDialogProvider({ children }: { children: ReactNode }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <GenericDialogContext.Provider
      value={{
        isDialogOpen,
        setIsDialogOpen,
      }}
    >
      {children}
    </GenericDialogContext.Provider>
  );
}
