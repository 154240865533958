import { ReactNode } from 'react';
import { Stack } from 'src/core/ui';

interface MultiSelectActionsContainerProps {
  children: ReactNode;
}

export const MultiSelectActionsContainer = ({
  children,
}: MultiSelectActionsContainerProps) => {
  return (
    <Stack gap="m" flexWrap="wrap">
      {children}
    </Stack>
  );
};
