import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Event, ListingClient } from 'src/WebApiController';

import { UseGetListingMergeSuggestions } from './UseGetListingMergeSuggestions.types';
import { clusterListingMergeSuggestionsByListingIdOverlap } from './useGetListingMergeSuggestions.utils';

export const useGetListingMergeSuggestionsByEvent = (
  event?: Event | null,
  excludeHeldListings = false
): UseGetListingMergeSuggestions => {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    event?.viagId != null;

  const mergeSuggestionsQuery = useQuery({
    queryKey: [
      'ListingClient.getListingMergeSuggestionsByEvent',
      activeAccountWebClientConfig,
      event?.viagId,
      excludeHeldListings,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new ListingClient(
        activeAccountWebClientConfig
      ).getListingMergeSuggestionsByEvent(event!.viagId!, excludeHeldListings);
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('ListingClient.getListingMergeSuggestionsByEvent', error, {
          trackErrorData: {
            viagogoEventId: event?.viagId,
            excludeHeldListings,
          },
        });
      },
    },
  });

  const mergeSuggestions = useMemo(() => {
    return clusterListingMergeSuggestionsByListingIdOverlap(
      mergeSuggestionsQuery.data
    );
  }, [mergeSuggestionsQuery.data]);

  return {
    mergeSuggestions,
    isLoading: mergeSuggestionsQuery.isLoading,
  };
};
