import { ColumnDef } from '@tanstack/react-table';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import {
  filterColumnsByFeatures,
  getListingReportColumnConfigById,
} from 'src/utils/columns/columnUtils';
import { ListingReportTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { filterCustomColumnsForListingReport } from 'src/utils/columns/inventory/inventoryReportCustomColumnUtils';
import { ListingReportMetricsWithGroupBy } from 'src/utils/reportsUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import { ActionOutboxEntityType, UserSetting } from 'src/WebApiController';

import {
  customColumnDef,
  eventTagColumnDef,
  LISTING_REPORT_TABLE_COLUMNS_CONFIG,
  tagColumnDef,
} from './configs/ListingReportTableColumnsConfig';

type ListingReportTableColumnDefContextType = {
  displayedColumnsConfig:
    | ColumnDef<ListingReportMetricsWithGroupBy | null>[]
    | undefined;
  isLoading?: boolean;
};

const ListingReportTableColumnDefContext =
  createContext<ListingReportTableColumnDefContextType>({
    displayedColumnsConfig: undefined,
  });

export const ListingReportTableColumnDefContextProvider = ({
  report,
  children,
}: PropsWithChildren<{
  report: ReportConfig;
}>) => {
  const { appContext, loginContext } = useAppContext();
  const { tagsMetadataNumeric, isLoading: listingTagIsLoading } =
    useTagsForEntityType(ActionOutboxEntityType.Listing, true);

  const { tagsMetadata: tagsMetadataEvent, isLoading: eventTagIsLoading } =
    useTagsForEntityType(ActionOutboxEntityType.SellerEvent, true);

  const {
    tagsMetadata: tagsMetadataPurchase,
    isLoading: purchaseTagIsLoading,
  } = useTagsForEntityType(ActionOutboxEntityType.Purchase, true);

  const { value: customListingColumns = [], isLoading: customColumnIsLoading } =
    useServerUserSetting<CustomListingColumn[]>({
      id: UserSetting.InventoryCustomColumns,
    });

  const customListingColumnsFiltered = filterCustomColumnsForListingReport(
    customListingColumns,
    tagsMetadataNumeric
  );

  const displayedColumnsConfig = useMemo(() => {
    const columns = new Set([
      // Add unconfigurable columns first
      ...Object.values(LISTING_REPORT_TABLE_COLUMNS_CONFIG)
        .filter((column) => {
          const columnDef = getListingReportColumnConfigById(
            column.id as ListingReportTableColumnId
          ).personalization;
          const hasFeatureForColumn =
            columnDef.requiredFeatures.length === 0 ||
            hasFeatures(
              loginContext?.user,
              appContext?.features,
              columnDef.requiredFeatures
            );

          return !columnDef.isConfigurable && hasFeatureForColumn;
        })
        .map((item) => item.id as ListingReportTableColumnId),
      // Add user defined columns next
      ...filterColumnsByFeatures(
        report.metrics,
        SectionType.ListingsReport,
        customListingColumnsFiltered,
        loginContext?.user,
        appContext?.features,
        tagsMetadataNumeric,
        report.groupBy
      ),
    ]);

    return Array.from(columns).reduce<
      ColumnDef<ListingReportMetricsWithGroupBy | null>[]
    >((acc, columnId) => {
      if (tagsMetadataNumeric?.find((t) => t.key === columnId)) {
        acc.push(tagColumnDef(columnId));
      } else if (tagsMetadataPurchase?.find((t) => t.key === columnId)) {
        acc.push(tagColumnDef(columnId));
      } else if (tagsMetadataEvent?.find((t) => t.key === columnId)) {
        acc.push(eventTagColumnDef(columnId));
      } else if (customListingColumnsFiltered.find((c) => c.id === columnId)) {
        acc.push(
          customColumnDef(
            columnId,
            customListingColumnsFiltered.find((c) => c.id === columnId)?.formula
          )
        );
      } else {
        const columnConfig = Object.values(
          LISTING_REPORT_TABLE_COLUMNS_CONFIG
        ).find((column) => column.id === columnId);
        if (columnConfig)
          acc.push(
            columnConfig as ColumnDef<ListingReportMetricsWithGroupBy | null>
          );
      }

      return acc;
    }, []);
  }, [
    appContext?.features,
    customListingColumnsFiltered,
    loginContext?.user,
    report.groupBy,
    report.metrics,
    tagsMetadataEvent,
    tagsMetadataNumeric,
    tagsMetadataPurchase,
  ]);

  const isLoading =
    listingTagIsLoading ||
    eventTagIsLoading ||
    purchaseTagIsLoading ||
    customColumnIsLoading;

  return (
    <ListingReportTableColumnDefContext.Provider
      value={{
        displayedColumnsConfig: isLoading ? undefined : displayedColumnsConfig,
        isLoading,
      }}
    >
      {children}
    </ListingReportTableColumnDefContext.Provider>
  );
};

export const useListingReportTableColumnDef = () =>
  useContext(ListingReportTableColumnDefContext);
