import { RowData } from '@tanstack/react-table';
import { PosVirtuosoTableProps } from 'src/tables/Table/PosVirtuosoTableComponent/PosVirtuosoTableContext';
import * as styles from 'src/tables/Table/Table.css';

export const PosVirtuosoTable = <T extends RowData>({
  children,
  context,
  ...props
}: PosVirtuosoTableProps<T>) => (
  <table
    className={styles.table}
    style={{ tableLayout: context?.tableLayout, ...context?.tableStyle }}
    {...props}
  >
    {children}
  </table>
);
