import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReloadButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserCanUpdateSale } from 'src/hooks/useUserHasSalePermissions';
import { ModalFooter } from 'src/modals/Modal/Modal.styled';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getSaleActionButton,
  getSaleDetailsModalConfigWithDeepLink,
  NON_MAIN_SALE_ACTIONS,
} from 'src/utils/saleUtils';
import { tagHasChanges } from 'src/utils/tagUtils';
import {
  ActionOutboxEntityType,
  PointOfSaleSaleStatus,
  SaleActionType,
  SaleClient,
  SaleDetails,
  SaleInput,
  Tag,
  TicketType,
} from 'src/WebApiController';

export function SaleDetailsFooter() {
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { refetch: refreshTagMetadata, tagsMetadata } = useTagsForEntityType(
    ActionOutboxEntityType.Sale
  );

  const updateSaleMutation = useMutation({
    mutationFn: async (data: SaleInput) => {
      await new SaleClient(activeAccountWebClientConfig).updateSale(data);
      onReload();
      reset(data);
    },

    onError: (err: ErrorTypes, data) => {
      showErrorDialog('SaleClient.updateSale', err, {
        trackErrorData: { data },
      });
    },
  });

  const { formState, handleSubmit, reset, watch } = useFormContext<SaleInput>();

  const tags = watch('tags');

  const isMobile = useMatchMedia('mobile');
  const { closeModal } = useContext(ModalContext);
  const {
    posEntity: sale,
    isLoading,
    errorInfo,
    setActivePosEntity,
  } = useActivePosEntityContext<SaleDetails>();

  const canUpdate = useUserCanUpdateSale(sale);

  const cancelTo = getSaleDetailsModalConfigWithDeepLink(sale?.id);

  const onReload = useCallback(() => {
    if (sale) {
      setActivePosEntity(sale.id, sale.idOnMkp, true);
    }
    // Update the tags metadata
    refreshTagMetadata();
  }, [refreshTagMetadata, sale, setActivePosEntity]);

  const formIsDirty = useMemo(() => {
    if (
      Object.keys(formState.dirtyFields).length === 1 &&
      Object.keys(formState.dirtyFields).includes('tags')
    ) {
      return tagHasChanges(
        (formState.defaultValues?.tags?.value ?? []) as Tag[],
        tags?.value,
        tagsMetadata ?? []
      );
    } else {
      return formState.isDirty;
    }
  }, [
    formState.defaultValues?.tags,
    formState.dirtyFields,
    formState.isDirty,
    tags,
    tagsMetadata,
  ]);

  // This flag is used to explicitly manually fulfill a sale when there is already artifact
  const canAttemptManualForceFulfill = useMemo(() => {
    // Condition 1, 2: isNoFulfill is true, and is pending fulfillment state
    if (
      !sale?.isNoFulfill ||
      sale?.status !== PointOfSaleSaleStatus.PendingFulfillment
    ) {
      return false;
    }

    // Condition 3: sale has artifact matching the ticket type
    if (
      sale.ticketType === TicketType.Barcode &&
      sale.tickets.every((t) => t.barcode)
    ) {
      return true;
    }

    if (
      sale.ticketType === TicketType.QRCode &&
      sale.tickets.every((t) => t.eTicket)
    ) {
      return true;
    }

    if (
      sale.ticketType === TicketType.QRCode &&
      sale.tickets.every((t) => t.qrCode)
    ) {
      return true;
    }

    return false;
  }, [sale?.isNoFulfill, sale?.status, sale?.ticketType, sale?.tickets]);

  if (errorInfo) {
    return <OkButton onClick={closeModal} />;
  }

  const refreshAction: { key: string; action: JSX.Element | null }[] = [
    {
      key: 'Refresh',
      action: (
        <ReloadButton
          variant={isMobile ? 'text' : 'outline'}
          onClick={onReload}
        />
      ),
    },
  ];

  const mainActionsStartingIndex = Math.max(
    0,
    (sale?.actions?.length ?? 0) - (isMobile ? 2 : 3)
  );

  const actions: { key: string; action: JSX.Element | null }[] = sale?.actions
    ? sale.actions.map((a, i) => {
        const resolvedAction =
          a === SaleActionType.Fulfill && canAttemptManualForceFulfill
            ? SaleActionType.Refulfill
            : a;

        return {
          key: resolvedAction,
          action:
            getSaleActionButton(
              sale.id,
              sale.idOnMkp,
              sale.mkp,
              sale.status,
              resolvedAction,
              sale.ticketType,
              sale.isNoFulfill,
              i < mainActionsStartingIndex
                ? 'text'
                : i === sale.actions.length - 1 &&
                  resolvedAction !== SaleActionType.Reject &&
                  !NON_MAIN_SALE_ACTIONS.includes(resolvedAction)
                ? 'regular'
                : 'outline',
              false,
              cancelTo,
              isLoading
            ) ?? null,
        };
      })
    : [];

  if (formIsDirty && canUpdate) {
    actions.push({
      key: 'Submit',
      action: (
        <OkButton
          onClick={handleSubmit((data) => {
            const saleId = sale?.id;
            if (saleId) {
              updateSaleMutation.mutate({
                ...data,
                saleId,
              });
            }
          })}
          textContentId={ContentId.Save}
          disabled={isLoading}
        />
      ),
    });
  }

  const mainActions = (isMobile ? [] : refreshAction).concat(
    actions.slice(mainActionsStartingIndex)
  );

  const subActions = (isMobile ? refreshAction : []).concat(
    actions.slice(0, mainActionsStartingIndex)
  );
  return (
    <ModalFooter>
      {mainActions.map((a) => (
        <div key={a.key}>{a.action}</div>
      ))}
      {subActions.length > 0 ? (
        <PosDropdown
          triggerProps={{ disabled: isLoading }}
          trigger={
            <MoreIcon
              disabled={isLoading}
              withHoverEffect
              fill={IconsFill.textBrand}
              align="middle"
            />
          }
          align="end"
        >
          {subActions
            .sort((a, b) => a.key.localeCompare(b.key))
            .map((a) => (
              <PosDropdownItem disabled={isLoading} key={a.key}>
                {a.action}
              </PosDropdownItem>
            ))}
        </PosDropdown>
      ) : null}
    </ModalFooter>
  );
}
