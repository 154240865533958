import { pickBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonWithIcon } from 'src/components/Buttons';
import { MapWithCompListingsPreview } from 'src/components/Listings/MapWithCompListingsPreview';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Stack } from 'src/core/ui';
import { SectionSelectorPanel } from 'src/dialogs/SectionSelectDialog';
import { useComparableListingsVenueMapColor } from 'src/hooks/useComparableListingsVenueMapColor';
import { useCompListingDisabledSections } from 'src/hooks/useCompListingDisabledSections';
import { useGetCompListings } from 'src/hooks/useGetCompListings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Event,
  Feature,
  ListingDetails,
  ListingDetailsPricingUpdates,
  RowInfo,
  Seating,
  SectionInfo,
} from 'src/WebApiController';

import { AutoPriceCompListingsPreview } from '../AutoPriceCompListingsPreview';
import { useAutoPricingPreview } from '../AutoPricePreview/useAutoPricePreview';
import { useListingAutoPricingSettings } from '../useListingAutoPricingSettings';
import { useListingDetailsModalSidePanelContext } from '.';
import * as styles from './ListingDetailsModalSidePanel.css';

export const ListingDetailsModalSidePanel = ({
  event,
  listing,
}: {
  event: Event;
  listing: ListingDetails;
}) => {
  const { clearErrors } = useFormContext<ListingDetailsPricingUpdates>();

  const {
    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingSelectedSectionSettings,
    onCompListingChange,
  } = useListingAutoPricingSettings(listing);

  const { priceCalcQuery, priceUpdates } = useAutoPricingPreview(
    event.viagId,
    listing.id
  );
  const { compListingsQuery } = useGetCompListings(event, listing?.id);

  const { venueMapInfo } = useEventMapContext();

  const hasDefaultFilterRowsFeature = useUserHasFeature(
    Feature.DefaultFilterRows
  );

  const hasMergeSimplifiedUIFeature = useUserHasFeature(
    Feature.MergeSimplifiedUI
  );

  const { disabledSectionIds } = useCompListingDisabledSections(
    listing,
    false,
    compListingMode,
    compListingFloor,
    compListingCeiling
  );
  const { contentType, onCloseSidePanel } =
    useListingDetailsModalSidePanelContext();

  const handleSectionRowFilters = (
    section: SectionInfo,
    sectionRow: RowInfo | undefined
  ) => {
    if (sectionRow?.ordinal != null) {
      return section.rows
        .filter((r) => r.ordinal != null && r.ordinal <= sectionRow.ordinal!)
        .map((r) => r.id);
    }
    return section.rows.map((r) => r.id);
  };

  const findMatchingRow = (
    section: SectionInfo,
    listingSeating: Seating,
    listingRow: string
  ) => {
    return section.rows.find(
      ({ id, name }) =>
        id === listingSeating.rowId ||
        name?.toLowerCase() === listingRow.toLowerCase()
    );
  };

  const onSelectedSectionsChange = useCallback(
    (selectedSections: SectionInfo[]) => {
      const { seating: listingSeating } = listing;
      const listingRow = listingSeating.row!;

      const newSectionIdFilters: number[] = [];
      const newRowIdFilters: number[] = [];

      const oldSectionRowIdFilter = Object.assign(
        {},
        compListingSelectedSectionSettings?.sectionRowIdFilter ?? {}
      );

      selectedSections.forEach((section) => {
        newSectionIdFilters.push(section.id);

        if (hasDefaultFilterRowsFeature) {
          const matchingRow = findMatchingRow(
            section,
            listingSeating,
            listingRow
          );

          if (matchingRow) {
            newRowIdFilters.push(
              ...handleSectionRowFilters(section, matchingRow)
            );
            oldSectionRowIdFilter[section.id] = matchingRow.id.toString();
          } else {
            handleExistingSectionFilter();
          }
        } else {
          handleExistingSectionFilter();
        }

        function handleExistingSectionFilter() {
          if (section.id in oldSectionRowIdFilter) {
            const sectionRow = section.rows.find(
              (r) => r.id == Number.parseInt(oldSectionRowIdFilter[section.id])
            );
            newRowIdFilters.push(
              ...handleSectionRowFilters(section, sectionRow)
            );
          } else {
            newRowIdFilters.push(
              ...handleSectionRowFilters(section, undefined)
            );
          }
        }
      });

      const newSectionRowIdFilters = pickBy(oldSectionRowIdFilter, (_, key) =>
        newSectionIdFilters.includes(parseInt(key))
      );
      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings,
        sectionIdFilter: newSectionIdFilters,
        rowIdFilter: newRowIdFilters,
        sectionRowIdFilter: newSectionRowIdFilters,
      };

      onCompListingChange({
        compListingOnlyForSameZoneEnabledNew: false,
        compListingOnlyForSelectedSectionsEnabledNew: true,
        compListingSelectedSectionSettingsNew,
      });
      clearErrors('compListingSelectedSectionSettings');
    },
    [
      clearErrors,
      compListingSelectedSectionSettings,
      hasDefaultFilterRowsFeature,
      listing,
      onCompListingChange,
    ]
  );

  const filterSections = useMemo(() => {
    const selectedSectionId =
      compListingSelectedSectionSettings?.sectionIdFilter ?? [];
    return (
      venueMapInfo?.sections?.filter(({ id }) =>
        selectedSectionId.includes(id)
      ) ?? []
    );
  }, [compListingSelectedSectionSettings, venueMapInfo]);

  const getColor = useComparableListingsVenueMapColor(
    [listing],
    priceCalcQuery.data?.compListings,
    compListingsQuery.data ?? undefined
  );

  const showComparables =
    contentType === 'comparables' && listing && event?.viagId != null;

  const showSectionSelector = contentType === 'sectionSelection' && listing;

  if (!showComparables && !showSectionSelector) return null;

  return (
    <Stack direction="column" className={styles.mainContainerFull}>
      <div className={styles.buttonBar}>
        <ButtonWithIcon
          variant="textPlain"
          shape="none"
          icon={<CrossIcon withHoverEffect />}
          onClick={onCloseSidePanel}
          textContentId={ContentId.Close}
        />
      </div>
      <Stack
        direction="column"
        gap="xl"
        style={{ height: 'calc(100% - 52px)' }}
      >
        {showComparables &&
          (hasMergeSimplifiedUIFeature ? (
            <MapWithCompListingsPreview
              listing={listing}
              listingInput={priceUpdates}
              pricingPreview={priceCalcQuery?.data ?? undefined}
              compListings={priceCalcQuery?.data?.compListings ?? []}
              onSelectedSectionsChange={onSelectedSectionsChange}
              isInModal
              getColor={getColor}
            />
          ) : (
            <AutoPriceCompListingsPreview
              event={event}
              listing={listing}
              onSelectedSectionsChange={onSelectedSectionsChange}
            />
          ))}
        {showSectionSelector && (
          <SectionSelectorPanel
            sectionIds={
              compListingSelectedSectionSettings?.sectionIdFilter ?? []
            }
            disabledSectionIds={disabledSectionIds}
            onSelectedSectionsChange={onSelectedSectionsChange}
          />
        )}
      </Stack>
    </Stack>
  );
};
