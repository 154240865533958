import { useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { EventAccordionItemProps } from 'src/components/Accordions';
import { ButtonWithIcon, ReloadButton } from 'src/components/Buttons';
import { PURCHASE_BULK_BACKFILL_DELIVERY_SETTINGS_KEY } from 'src/components/Purchases/PurchaseActionDropdown';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button, SimpleTable, Stack } from 'src/core/ui';
import { BulkUpdateDeliveryOverrideDialog } from 'src/dialogs/BulkEdits/BulkUpdateDeliveryOverrideDialog';
import { useSellerAccountEventSettings } from 'src/hooks/api/useSellerAccountEventSettings';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { EmptyPurchaseQuery } from 'src/utils/eventQueryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  DeliveryType,
  EventWithData,
  Feature,
  PurchaseOrderQuery,
  PurchaseOrderState,
  SellerAccountEventSetting,
} from 'src/WebApiController';

import { EventSettingForm } from '../FulfillmentSection/EventSettingForm';
import { SellerAccountEventSettingsInput } from '../FulfillmentSection/FulfillmentSection.types';
import * as styles from '../InventoryEventPage.css';
import * as sectionStyles from './InventoryEventFulfillmentSection.css';
import { hasNoChangeOrIsDeleteOnly } from './InventoryEventFulfillmentSection.utils';

type InventoryEventContentProps = {
  accordionItemProps?: EventWithData;
  inventoryAccordionItem?: EventAccordionItemProps;
};

export function InventoryEventFulfillmentSection({
  accordionItemProps,
  inventoryAccordionItem,
}: InventoryEventContentProps) {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = PURCHASE_BULK_BACKFILL_DELIVERY_SETTINGS_KEY;

  const { showErrorDialog } = useErrorBoundaryContext();

  const { BodyComponent } = inventoryAccordionItem || {};
  const event = accordionItemProps?.event;

  const hasAutoFulfillSettingFeature = useUserHasFeature(
    Feature.AutoFulfillSetting
  );

  const { availableVendors } = usePurchaseVendorSelector({});
  const numVendors = Object.keys(availableVendors).length;

  const updateDialog = useBasicDialog();

  const { openDialog, closeDialog } = useDialog(
    DialogId.BulkBackfillDeliverySettings,
    BulkUpdateDeliveryOverrideDialog
  );

  const {
    sellerAccountEventSettings,
    isLoading,
    updateSellerAccountEventSettings,
    isMutationLoading,
  } = useSellerAccountEventSettings(event?.viagId);

  const methods = useForm<SellerAccountEventSettingsInput>({
    defaultValues: {
      settings: sellerAccountEventSettings ?? [],
    },
  });

  const {
    getValues,
    watch,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    methods.reset({
      settings: sellerAccountEventSettings ?? [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerAccountEventSettings]);

  const onSubmit = useCallback(async () => {
    const { settings } = getValues();
    await updateSellerAccountEventSettings(settings);
  }, [getValues, updateSellerAccountEventSettings]);

  const onReset = useCallback(() => {
    methods.setValue('settings', sellerAccountEventSettings ?? [], {
      shouldDirty: true,
    });
  }, [methods, sellerAccountEventSettings]);

  const settings = watch('settings');

  const canAddNew = (settings?.length ?? 0) < numVendors + 1;

  const onCreateNewSetting = useCallback(() => {
    if (!canAddNew) {
      return;
    }

    const newSetting: SellerAccountEventSetting = {
      vendorId: null,
      delivType: Object.values(DeliveryType)[0],
      isNoFulfill: false,
      inHandAt: null,
      tktTypeRules: {
        overrides: [],
      },
    };

    methods.setValue('settings', [...settings, newSetting], {
      shouldDirty: true,
    });
  }, [canAddNew, methods, settings]);

  const onDeleteSetting = useCallback(
    (index: number) => {
      const newSettings = [...settings];
      newSettings.splice(index, 1);
      methods.setValue('settings', newSettings, { shouldDirty: true });
    },
    [methods, settings]
  );

  const purchaseQuery = useMemo(() => {
    return {
      ...EmptyPurchaseQuery,
      purchaseOrderState: PurchaseOrderState.Active,
      eventOrMappingIds: [event!.viagId!.toString()],
    } as PurchaseOrderQuery;
  }, [event]);

  const onBulkUpdate = useCallback(
    async (
      updateSalesOfListings: boolean,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkBackfillDeliverySettingsPreview(
              purchaseQuery,
              updateSalesOfListings,
              false
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkBackfillDeliverySettingsPreview', error, {
              trackErrorData: { purchaseQuery },
            });
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkBackfillDeliverySettingsListingOnly(
              preview!.preview,
              updateSalesOfListings,
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              closeDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkBackfillDeliverySettingsListingOnly', error, {
              trackErrorData: {
                preview,
              },
            });
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeDialog,
      purchaseQuery,
      showErrorDialog,
      updateKey,
    ]
  );

  const onSaveHandler = useCallback(() => {
    const { settings } = getValues();

    if (hasNoChangeOrIsDeleteOnly(sellerAccountEventSettings, settings)) {
      onSubmit();
      return;
    }

    openDialog({
      updateKey: updateKey,
      entityType: ActionOutboxEntityType.Listing,
      skipTicketGroups: true,
      ...updateDialog.dialogProps,
      onSaveOverrideSettings: onSubmit,
      onBulkUpdate: onBulkUpdate,
      onCancel: () => {
        closeDialog();
      },
      backfillDeliveryStrategyFeature:
        Feature.BackfillDeliveryStrategyInventoryEvent,
      backfillDeliveryStrategyToSaleFeature:
        Feature.BackfillDeliveryStrategyInventoryEventToSales,
    });
  }, [
    getValues,
    openDialog,
    updateKey,
    updateDialog.dialogProps,
    onSubmit,
    onBulkUpdate,
    sellerAccountEventSettings,
    closeDialog,
  ]);

  return (
    <div className={styles.tableSection} id="InventoryEventFulfillmentSection">
      {BodyComponent && accordionItemProps && (
        <div className={styles.tableContainer}>
          {isLoading ? (
            <PosSpinner />
          ) : (
            <Stack direction="column" gap="m">
              <FormProvider {...methods}>
                {settings?.length !== 0 && (
                  <SimpleTable.Table>
                    <SimpleTable.Thead>
                      <SimpleTable.Tr>
                        <SimpleTable.Th
                          colSpan={2}
                          className={sectionStyles.tableTopHeader}
                        >
                          <div>
                            <Content id={ContentId.IfPurchaseIsCreatedWith} />
                          </div>
                        </SimpleTable.Th>
                        <SimpleTable.Th
                          colSpan={hasAutoFulfillSettingFeature ? 3 : 2}
                          className={sectionStyles.tableTopHeader}
                        >
                          <div>
                            <Content id={ContentId.ThenDefaultListingsTo} />
                          </div>
                        </SimpleTable.Th>
                      </SimpleTable.Tr>
                      <SimpleTable.Tr style={{ borderTop: 'unset' }}>
                        <SimpleTable.Th style={{ width: '15%' }}>
                          <Content id={ContentId.Vendor} />
                        </SimpleTable.Th>
                        <SimpleTable.Th style={{ width: '15%' }}>
                          <Content id={ContentId.DeliveryType} />
                        </SimpleTable.Th>
                        <SimpleTable.Th style={{ width: '30%' }}>
                          <Content id={ContentId.DeliveryStrategy} />
                        </SimpleTable.Th>
                        {hasAutoFulfillSettingFeature && (
                          <SimpleTable.Th>
                            <Content id={ContentId.AutoFulfill} />
                          </SimpleTable.Th>
                        )}
                        <SimpleTable.Th style={{ width: '20%' }}>
                          <Content id={ContentId.InHand} />
                        </SimpleTable.Th>
                        <SimpleTable.Th>
                          <Content id={ContentId.Actions} />
                        </SimpleTable.Th>
                      </SimpleTable.Tr>
                    </SimpleTable.Thead>
                    <SimpleTable.Tbody>
                      {settings.map((s, i) => (
                        <EventSettingForm
                          key={`${s.delivType}-${i}`}
                          event={event}
                          disabled={isMutationLoading}
                          index={i}
                          onDeleteSetting={onDeleteSetting}
                        />
                      ))}
                    </SimpleTable.Tbody>
                  </SimpleTable.Table>
                )}
              </FormProvider>
              {canAddNew && (
                <Stack direction="row" gap="m" width="full" alignItems="end">
                  <ButtonWithIcon
                    icon={
                      <PlusIcon
                        withHoverEffect
                        fill={IconsFill.textBrand}
                        size={vars.iconSize.m}
                      />
                    }
                    disabled={isMutationLoading || isLoading}
                    textContentId={ContentId.AddOverride}
                    onClick={() => {
                      onCreateNewSetting();
                    }}
                    style={{ width: 'min-content', whiteSpace: 'nowrap' }}
                    variant={'text'}
                  />
                </Stack>
              )}
              {isDirty && (
                <Stack direction="row" gap="m" width="full" alignItems="end">
                  <ReloadButton
                    textContentId={ContentId.Reset}
                    variant={'outline'}
                    disabled={isMutationLoading || isLoading}
                    onClick={onReset}
                  />
                  <Button
                    onClick={onSaveHandler}
                    disabled={isMutationLoading || isLoading}
                  >
                    <Content id={ContentId.Save} />
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
        </div>
      )}
    </div>
  );
}
