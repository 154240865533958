import { EventIdQueryParam } from 'src/utils/constants/constants';
import { UserSetting } from 'src/WebApiController';

export const queryIdSearchParam = 'queryId';

export const INVENTORY_USER_SETTINGS = [
  UserSetting.InventoryDefaultTab,
  UserSetting.InventoryV2DefaultTab,
  UserSetting.QuickFiltersStateInventory,
  UserSetting.InventoryColumnNumberPrecision,
  UserSetting.InventoryColumnEditability,
  UserSetting.SuspiciousPriceChange,
  UserSetting.InventoryColumnWidths,
  UserSetting.InventoryColumnOrder,
  UserSetting.InventoryColumnsEnabled,
  UserSetting.InventoryPageViewMode,
  UserSetting.InventoryFlattenedColumnOrder,
  UserSetting.InventoryFlattenedColumnsEnabled,
  UserSetting.InventoryFlattenedColumnNumberPrecision,
  UserSetting.InventoryFlattenedColumnEditability,
  UserSetting.InventorySideTableColumnNumberPrecision,
  UserSetting.InventorySideTableColumnEditability,
  UserSetting.InventorySideTableColumnOrder,
  UserSetting.InventorySideTableColumnsEnabled,
  UserSetting.InventoryCustomColumns,
  UserSetting.EventsColumnWidths,
  UserSetting.EventsColumnOrder,
  UserSetting.EventsColumnEditability,
  UserSetting.EventsColumnNumberPrecision,
  UserSetting.EventsColumnsEnabled,
  UserSetting.EventsCustomColumns,
];

export const getDefaultReturnUrl = (viagVirtualId?: string | null) => {
  const queryParams = new URLSearchParams(location.search);

  queryParams.delete(EventIdQueryParam);
  queryParams.delete('queryId'); // TODO - this is obsolete and no longer use, but it could still exists

  return `/inventory?${queryParams.toString()}${
    viagVirtualId ? `#${viagVirtualId}` : ''
  }`;
};
