import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const ParentListingModeIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 40 40">
      <path
        d="M11 13H19"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 17H19"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.0667 15C23.0667 13.2415 24.3265 11.7738 26 11.4364V8.45455C26 7.65164 25.3429 7 24.5333 7H5.46667C4.65707 7 4 7.65164 4 8.45455V11.4364C5.67347 11.7738 6.93333 13.24 6.93333 15C6.93333 16.7585 5.67347 18.2262 4 18.5636V21.5455C4 22.3484 4.65707 23 5.46667 23H24.5333C25.3429 23 26 22.3484 26 21.5455V18.5636C24.3265 18.2262 23.0667 16.7585 23.0667 15Z"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 23V32H28"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeDasharray="2 2"
      />
      <mask id="path-5-inside-1_17_737" fill="white">
        <rect x="26" y="29" width="9" height="6" rx="1" />
      </mask>
      <rect
        x="26"
        y="29"
        width="9"
        height="6"
        rx="1"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="4"
        mask="url(#path-5-inside-1_17_737)"
      />
    </SvgWrapper>
  );
};
