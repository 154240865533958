import { useCallback, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import {
  ConfirmDialog,
  ConfirmDialogProps,
} from 'src/core/interim/dialogs/ConfirmDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { Event, PricingClient } from 'src/WebApiController';

import { contentWrapper } from './ApplyHeatMapToAllEventsDialog.css';

export type ApplyHeatMapToAllEventsDialogProps = Omit<
  ConfirmDialogProps,
  'headerText' | 'bodyText'
> & {
  event: Event;
  eventIds: number[] | undefined | null;
  eventConfigScoreOverrideId: number | undefined;
  onClose: () => void;
};

export const ApplyHeatMapToAllEventsDialog = ({
  event,
  eventIds,
  eventConfigScoreOverrideId,
  onClose,
  ...props
}: ApplyHeatMapToAllEventsDialogProps) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { performer, venue } = useGetEventFullInfo(event);
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { mapConfigOverridesQuery } = useEventMapContext();

  const onApplyToAllEvents = useCallback(
    (eventIds: number[]) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const client = new PricingClient(activeAccountWebClientConfig);

          eventIds!.forEach(
            async (eid) =>
              await client.setEventSeatMapScoreOverride(
                eid,
                eventConfigScoreOverrideId,
                null
              )
          );

          mapConfigOverridesQuery.refetch();
          onClose();
        },
        (error) => {
          showErrorDialog('PricingClient.setEventSeatMapScoreOverride', error, {
            trackErrorData: {
              eventIds,
              eventConfigScoreOverrideId,
            },
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      eventConfigScoreOverrideId,
      mapConfigOverridesQuery,
      onClose,
      showErrorDialog,
    ]
  );

  if (!eventIds?.length || !eventConfigScoreOverrideId) {
    return null;
  }

  return (
    <ConfirmDialog
      size="md"
      headerText={<Content id={ContentId.ApplyToAllEvents} />}
      bodyText={
        <div className={contentWrapper}>
          {isLoading ? (
            <PosSpinner />
          ) : (
            <FormatContent
              id={FormatContentId.ApplyToAllEventsConfirmation}
              params={[
                `${eventIds.length}`,
                `${performer?.name}`,
                `${venue?.name}`,
              ]}
            />
          )}
        </div>
      }
      okText={ContentId.AllEvents}
      cancelText={ContentId.ThisEvent}
      {...props}
      onOkay={() => onApplyToAllEvents(eventIds)}
      onCancel={() => onApplyToAllEvents([event.viagId!])}
    />
  );
};
