import { ComponentProps } from 'react';
import { vars } from 'src/core/themes';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { ChevronLeftIcon, ChevronRightIcon } from 'src/svgs/Viagogo';
import Swiper from 'swiper';
import { useSwiper } from 'swiper/react';

import { expandButton } from './SidePanelButton/SidePanelButton.css';

type SwiperButtonProps = { dir: 'left' | 'right' } & Pick<
  ComponentProps<'div'>,
  'onClick'
> & {
    swiperRef?: React.MutableRefObject<Swiper | undefined>;
  };

export function SwiperButton({ dir, onClick, swiperRef }: SwiperButtonProps) {
  const mobile = useMatchMedia('mobile');
  const swiper = useSwiper();
  const ExpandIcon = dir === 'left' ? ChevronRightIcon : ChevronLeftIcon;

  if (!mobile) {
    return null;
  }

  return (
    <div
      id="swiper-button"
      className={expandButton}
      style={{
        position: 'relative',
        top: 0,
        width: vars.iconSize.xl,
        height: vars.iconSize.xl,
      }}
      dir={dir}
      onClick={(e) => {
        onClick?.(e);
        dir === 'left'
          ? swiperRef?.current
            ? swiperRef?.current.slidePrev()
            : swiper?.slidePrev()
          : swiperRef?.current
          ? swiperRef?.current.slideNext()
          : swiper?.slideNext();
      }}
    >
      <ExpandIcon
        id="swiper-button-icon"
        withHoverEffect
        fill={vars.color.textBrand}
        size={vars.iconSize.s}
      />
    </div>
  );
}
