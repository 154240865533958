import { AutoPriceResultDisplay } from 'src/components/Listings/AutoPriceResultDisplay';
import { Content } from 'src/contexts/ContentContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Button, Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { ContentId } from 'src/utils/constants/contentId';
import { AutoPricingResultType } from 'src/WebApiController';

import { useAutoPricingSidePanelContext } from '../../../AutoPricingSidePanel';
import * as styles from './AutoPricePreview.css';
import { useAutoPricingPreview } from './useAutoPricePreview';

export const AutoPricePreview = ({
  viagogoEventId,
  listingId,
}: {
  listingId: number;
  viagogoEventId: number;
}) => {
  const { priceCalcQuery } = useAutoPricingPreview(viagogoEventId, listingId);
  const isMobile = useMatchMedia('mobile');
  const { onShowContent } = useAutoPricingSidePanelContext();

  return (
    <Button
      variant="textPlain"
      shape="none"
      className={styles.rootContainer}
      onClick={(e) => onShowContent('comparables', e)} // undefined is for toggle
    >
      <Stack direction="column" gap="l" className={styles.rootContent}>
        <Stack
          direction="row"
          gap="l"
          justifyContent="spaceBetween"
          style={{ flexWrap: 'wrap' }}
        >
          <Stack
            direction="column"
            gap="s"
            justifyContent="spaceBetween"
            alignItems="start"
          >
            <div className={styles.mainTitle}>
              <Content id={ContentId.AutoPricePreview} />
            </div>
            <div className={styles.subTitle}>
              <Content id={ContentId.AutoPricePreviewSubTitle} />
            </div>
          </Stack>
          <Stack
            direction="column"
            gap="s"
            alignItems="center"
            justifyContent="spaceBetween"
          >
            <div className={styles.mainValue}>
              {priceCalcQuery.data?.autoPricingResultType && // show when result type is not null and
                priceCalcQuery.data?.autoPricingResultType !==
                  AutoPricingResultType.PriceSuccess && // is not success result and
                (priceCalcQuery.data?.autoPricingResultType !==
                  AutoPricingResultType.None || // is not none or there's no price display (we show N/A when there's no price indicating we can't even call the endpoint)
                  !priceCalcQuery.data?.websitePrice?.disp) && (
                  <AutoPriceResultDisplay
                    resultType={priceCalcQuery.data?.autoPricingResultType}
                    useWarningIcon
                  />
                )}
              {priceCalcQuery.data?.websitePrice?.disp}
            </div>
            <div className={styles.valueLabel}>
              <Content id={ContentId.YourListing} />
            </div>
          </Stack>
          <Stack
            direction="column"
            gap="s"
            alignItems="center"
            justifyContent="spaceBetween"
          >
            <div className={styles.mainValue}>
              {priceCalcQuery.data?.compListings?.length ?? '0'}
            </div>
            <div className={styles.valueLabel}>
              <Content id={ContentId.Comparables} />
            </div>
          </Stack>
          <Button
            variant="text"
            onClick={(e) => onShowContent('comparables', e)}
            style={isMobile ? { width: '100%' } : undefined}
          >
            <Content id={ContentId.ExploreComparables} />
          </Button>
        </Stack>
        {priceCalcQuery.data?.errorMessage && (
          <WarningMessage message={priceCalcQuery.data.errorMessage} />
        )}
      </Stack>
    </Button>
  );
};
