import { useMutation } from '@tanstack/react-query';
import { cloneDeep, isEqual } from 'lodash-es';
import { Fragment, useCallback, useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  getFormattedContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosEnumSelect, PosSelect } from 'src/core/POS/PosSelect';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack, Switch } from 'src/core/ui';
import { useSyncCenterSettings } from 'src/hooks/api/useSyncCenterSetting';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { CheckIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKETPLACE_PAYMENT_TERM_TO_CID,
  OUTLIER_MODE_TO_CID,
  SPLIT_TYPE_TO_CID,
  STANDARD_DEVIATIONS_TO_CID,
  YES_NO_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { FromYesNoEnum, ToYesNoEnum } from 'src/utils/eventQueryUtils';
import {
  AutoPricingOutlierMode,
  Feature,
  Marketplace,
  MarketplacePaymentTerm,
  Permission,
  SellerAccountClient,
  SplitType,
  SyncCenterSettings,
} from 'src/WebApiController';

import { MainRoute } from '../../../MainRoute';
import { TicketTypeOverrideInputForm } from './Components/TicketTypeOverrideInputForm';
import * as styles from './SyncCenter.css';
import { useAccountQuantitiesFilter } from './SyncCenter.hooks';
import { SyncCenterFieldValues } from './SyncCenter.types';
import {
  getListingPackQuantityOptions,
  setValueAsNumber,
  toSyncCenterFieldValues,
  toSyncCenterSettings,
} from './SyncCenter.utils';
import { TradeDeskApiResetInstructions } from './TradeDeskApiResetInstructions';
import { useUserHasAutopricingFeature } from 'src/hooks/useUserHasAutopricingFeature';
import { has } from 'remove-accents';

export function SyncCenterContent() {
  const query = useSyncCenterSettings();
  return (
    <div className={styles.root}>
      <div className={styles.center}>
        <h1 className={styles.pageName}>
          <Content id={ContentId.SyncCenter} />
        </h1>
        {query.isLoading ? (
          <PosSpinner />
        ) : (
          <SyncCenterForm
            disabled={query.isFetching}
            defaultValues={query.data}
            onSaved={() => query.refetch()}
          />
        )}
      </div>
    </div>
  );
}

export function SyncCenter() {
  const syncCenterTitle = useContent(ContentId.SyncCenter);
  return (
    <LayoutContent
      mainRoute={MainRoute.SyncCenter}
      routeTitle={syncCenterTitle}
    >
      <SyncCenterContent />
    </LayoutContent>
  );
}

function SyncCenterForm({
  disabled,
  defaultValues,
  onSaved,
}: {
  disabled?: boolean;
  defaultValues?: SyncCenterSettings | null;
  onSaved?: () => void;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const contentContext = useContentContext();
  const hasPurchaseSettingsTabFeature = useUserHasFeature(
    Feature.PurchaseSettingsTab
  );
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const hasPriceByNetProceedsFeature = useUserHasFeature(
    Feature.PriceByWebsitePrice
  );
  const accountPricingFeature = useUserHasAnyOfPermissions(
    Permission.Account_SetPrice
  );
  const hasMarketplaceTermFeature = useUserHasFeature(
    Feature.MarketplacePaymentTerms
  );
  const hasMarketplaceEnableDisableFeature = useUserHasFeature(
    Feature.EnableDisableMarketplaces
  );
  const hasSeatSaverFeature = useUserHasFeature(Feature.SeatSaver);
  const hasPricingMultiSelectCompQuantitiesFeature = useUserHasFeature(
    Feature.PricingMultiSelectCompQuantities
  );
  const hasMarketplacePreventInitialBroadcastFeature = useUserHasFeature(
      Feature.PreventInitialBroadcastForXDays
  );

  const hasAutoPricingTabFeature = useUserHasAutopricingFeature(
    Feature.AutoPricingTabInAccountSettings
  );

  const quantityPackLabel = useCallback(
    (ordinal: number, isQuantities = false, isGte = false) => {
      let packLabel = null;
      if (!isQuantities) {
        packLabel = ordinal === 9 ? '10+' : (ordinal + 1).toString();
      } else {
        packLabel = isGte ? `${ordinal}+` : ordinal.toString();
      }
      return getFormattedContent(
        FormatContentId.PacksOf,
        packLabel,
        contentContext
      );
    },
    [contentContext]
  );

  const mutation = useMutation({
    mutationFn: async (data: SyncCenterSettings) => {
      return new SellerAccountClient(activeAccountWebClientConfig)
        .mergeSyncCenterSettings(data)
        .then(() => onSaved?.());
    },
    onError: (err: ErrorTypes, data) => {
      showErrorDialog('SellerAccountClient.mergeSyncCenterSettings', err, {
        trackErrorData: { data },
      });
    },
  });

  const marketplaceEnableDisableConfirmDlg = useBasicDialog();

  const methods = useForm<SyncCenterFieldValues>({
    defaultValues: defaultValues
      ? toSyncCenterFieldValues(cloneDeep(defaultValues))
      : undefined,
  });

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState,
    reset,
  } = methods;

  // If the defaultValues changed - we need to ensure the form is reset - otherwise it will keep the old values
  // and it will re-submit with the old values
  useEffect(() => {
    const newDefaultValues =
      defaultValues && toSyncCenterFieldValues(cloneDeep(defaultValues));
    if (
      newDefaultValues &&
      !isEqual(newDefaultValues, formState.defaultValues)
    ) {
      reset(newDefaultValues);
    }
  }, [defaultValues, formState.defaultValues, reset]);

  const marketplaceSettings = watch('marketplaceSettings');
  const outlierMode = watch('outlierMode');
  const standardDeviations = watch('standardDeviations');
  const defaultQuantityFilters = watch('defaultQuantityFilters');
  const { exactMatchQuantityFilters, greaterThanOrEqualToQuantityFilter } =
    watch('compListingQuantityFilters') ?? {};

  const noneTxt = useContent(ContentId.None);
  const getMarketplaceEnableDisable = useCallback(
    (enable: boolean) => {
      const data = getValues('marketplaceSettings');
      const mkps = data?.filter((m) => m.isEnabled === enable);

      return mkps?.length
        ? mkps
            .map((m) => m.marketplace)
            .sort()
            .join(', ')
        : noneTxt;
    },
    [getValues, noneTxt]
  );

  const {
    getDefaultQuantitiesFilter,
    quantitiesFilterOptions,
    getQuantitiesFilterValue,
    onUpdateQuantitiesFilter,
  } = useAccountQuantitiesFilter();

  return (
    <FormProvider {...methods}>
      <>
        <div>
          <div>
            {marketplaceSettings?.map((settings, i) => {
              return settings.marketplace === Marketplace.StubHub ||
                settings.marketplace === Marketplace.Offline ? null : (
                <div
                  key={settings.marketplace}
                  className={styles.sectionContainer}
                >
                  <div className={styles.sectionName}>
                    {settings.marketplace}
                  </div>
                  <div className={styles.form}>
                    <PosFormField
                      label={<Content id={ContentId.Markup} />}
                      className={styles.textField}
                    >
                      <PosTextField
                        disabled={disabled || mutation.isPending}
                        type="number"
                        postfixDisplay="%"
                        {...register(`marketplaceSettings.${i}.markup`, {
                          setValueAs: setValueAsNumber,
                        })}
                      />
                    </PosFormField>
                    <PosFormField
                      label={<Content id={ContentId.SellerFee} />}
                      className={styles.textField}
                    >
                      <PosTextField
                        disabled={disabled || mutation.isPending}
                        type="number"
                        postfixDisplay="%"
                        {...register(`marketplaceSettings.${i}.sellerFee`, {
                          setValueAs: setValueAsNumber,
                        })}
                      />
                    </PosFormField>

                    {hasMarketplaceTermFeature && (
                      <Controller
                        control={control}
                        name={`marketplaceSettings.${i}.paymentTerm`}
                        render={({ field: { ...field } }) => (
                          <PosFormField
                            label={
                              <Content id={ContentId.MarketplacePaymentTerms} />
                            }
                            className={styles.enumSelect}
                          >
                            <PosEnumSelect<MarketplacePaymentTerm>
                              disabled={disabled || mutation.isPending}
                              valueOptionsContent={
                                MARKETPLACE_PAYMENT_TERM_TO_CID
                              }
                              {...field}
                            />
                          </PosFormField>
                        )}
                      />
                    )}

                    {hasMarketplacePreventInitialBroadcastFeature && (
                        <PosFormField
                            label={
                              <Content id={ContentId.DelayBroadcastAfterInitialCreation} />
                            }
                            className={styles.textField}
                        >
                          <PosTextField
                              disabled={disabled || mutation.isPending}
                              type="number"
                              {...register(`marketplaceSettings.${i}.broadcastHoldDurationInDays`, {
                                setValueAs: setValueAsNumber,
                              })}
                          />
                        </PosFormField>
                    )}

                    {hasMarketplaceEnableDisableFeature ? (
                      <Controller
                        control={control}
                        name={`marketplaceSettings.${i}.isEnabled`}
                        render={({ field: { value, onChange, ...field } }) => (
                          <Checkbox
                            disabled={disabled || mutation.isPending}
                            labelPosition="right"
                            label={
                              <Content id={ContentId.DisableThisMarketplace} />
                            }
                            checked={!value}
                            onChange={(e) => {
                              onChange(!e.currentTarget.checked);
                            }}
                            {...field}
                          />
                        )}
                      />
                    ) : settings.isEnabled ? (
                      <Stack gap="m" alignItems="center">
                        <CheckIcon fill={IconsFill.textSuccess} />
                        <Content id={ContentId.Enabled} />
                      </Stack>
                    ) : (
                      <Stack gap="m" alignItems="center">
                        <CheckIcon fill={IconsFill.textDisabled} />
                        <Content id={ContentId.Disabled} />
                      </Stack>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.sectionContainer}>
            <div className={styles.sectionName}>
              <Content id={ContentId.AllMarketplaces} />
            </div>
            <div className={styles.form}>
              <Controller
                control={control}
                name="defaultSplitType"
                render={({ field: { ...field } }) => (
                  <PosFormField
                    label={<Content id={ContentId.DefaultSplitType} />}
                  >
                    <PosEnumSelect<SplitType>
                      disabled={disabled || mutation.isPending}
                      placeholderText={ContentId.SelectSplitType}
                      valueOptionsContent={SPLIT_TYPE_TO_CID}
                      {...field}
                    />
                  </PosFormField>
                )}
              />
              {hasSeatSaverFeature && (
                <Controller
                  control={control}
                  name="defaultSplitTypeForSeatSaver"
                  render={({ field: { ...field } }) => (
                    <PosFormField
                      label={
                        <Content id={ContentId.DefaultSplitTypeForSeatSaver} />
                      }
                    >
                      <PosEnumSelect<SplitType>
                        disabled={disabled || mutation.isPending}
                        placeholderText={ContentId.SelectSplitType}
                        valueOptionsContent={SPLIT_TYPE_TO_CID}
                        {...field}
                      />
                    </PosFormField>
                  )}
                />
              )}
              <Controller
                control={control}
                name="defaultHideSeatNumbers"
                render={({ field: { value, onChange } }) => (
                  <PosFormField
                    label={<Content id={ContentId.DefaultHideSeatNumbers} />}
                  >
                    <PosEnumSelect
                      disabled={disabled || mutation.isPending}
                      valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                      value={ToYesNoEnum(value)}
                      onChange={(yesNoEnumValue) => {
                        onChange(FromYesNoEnum(yesNoEnumValue));
                      }}
                    />
                  </PosFormField>
                )}
              />
              <PosFormField
                label={<Content id={ContentId.DefaultInHandDateOffset} />}
                className={styles.textField}
              >
                <PosTextField
                  disabled={disabled || mutation.isPending}
                  type="number"
                  postfixDisplay={<Content id={ContentId.DaysPostfix} />}
                  {...register('inHandDatePaddingInDays', {
                    setValueAs: setValueAsNumber,
                  })}
                />
              </PosFormField>
              {!hasAutoPricingTabFeature && (
                <PosFormField
                  label={<Content id={ContentId.PriceFloor} />}
                  className={styles.textField}
                >
                  <PosTextField
                    disabled={disabled || mutation.isPending}
                    type="number"
                    postfixDisplay="%"
                    {...register('priceFloor', {
                      setValueAs: setValueAsNumber,
                    })}
                  />
                </PosFormField>
              )}
              <PosFormField
                label={<Content id={ContentId.ShownQuantity} />}
                className={styles.textField}
              >
                <PosTextField
                  disabled={disabled || mutation.isPending}
                  type="number"
                  {...register('defaultMaxQuantityDisplay', {
                    setValueAs: setValueAsNumber,
                  })}
                />
              </PosFormField>
              <TicketTypeOverrideInputForm
                disabled={disabled || mutation.isPending}
              />
            </div>
          </div>
          {!hasPurchaseSettingsTabFeature && (
            <div className={styles.sectionContainer}>
              <div className={styles.sectionName}>
                <Content id={ContentId.Purchase} />
              </div>
              <div className={styles.form}>
                <PosFormField
                  label={<Content id={ContentId.InHandDaysBeforeEvent} />}
                  className={styles.textField}
                >
                  <PosTextField
                    disabled={disabled || mutation.isPending}
                    type="number"
                    {...register('inHandDaysBeforeEvent', {
                      setValueAs: setValueAsNumber,
                    })}
                  />
                </PosFormField>
                <Controller
                  control={control}
                  name="isAutoPoQualityControlled"
                  render={({ field: { value, onChange, ...field } }) => (
                    <Checkbox
                      disabled={disabled || mutation.isPending}
                      labelPosition="right"
                      label={
                        <Content id={ContentId.EnableQualityControlForAutoPo} />
                      }
                      checked={Boolean(value)}
                      onChange={(e) => {
                        onChange(e.currentTarget.checked);
                      }}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          )}
          {hasAutoPricingFeature && accountPricingFeature && !hasAutoPricingTabFeature && (
            <div className={styles.sectionContainer}>
              <div className={styles.sectionName}>
                <Content id={ContentId.Pricing} />
              </div>
              <div className={styles.form}>
                <Stack gap="xl" direction="column">
                  {hasPriceByNetProceedsFeature ? (
                    <Controller
                      control={control}
                      name="priceByNetProceeds"
                      render={({ field: { value, onChange } }) => (
                        <Stack direction="row" gap="l" alignItems="center">
                          <Switch
                            disabled={disabled || mutation.isPending}
                            checked={Boolean(value)}
                            onChange={(e) => e.stopPropagation()}
                            onCheckedChange={(isChecked) => {
                              onChange(isChecked);
                            }}
                          />
                          <div className={styles.boldText}>
                            <Content id={ContentId.SimulateWebsiteDiscount} />
                          </div>
                        </Stack>
                      )}
                    />
                  ) : null}
                  <Stack direction="column">
                    <div className={styles.enumeratedPriorityLabel}>
                      <Content id={ContentId.DefaultQuantityFilters} />
                    </div>

                    <div className={styles.enumeratedPriorityInputGrid}>
                      {hasPricingMultiSelectCompQuantitiesFeature && (
                        <>
                          {exactMatchQuantityFilters?.map(
                            ({ posListingQuantity }, index) => (
                              <Fragment key={posListingQuantity}>
                                <div>
                                  {quantityPackLabel(posListingQuantity, true)}
                                </div>
                                <div>
                                  <Controller
                                    control={control}
                                    name={`compListingQuantityFilters.exactMatchQuantityFilters.${index}.compQuantityFilters`}
                                    defaultValue={getDefaultQuantitiesFilter(
                                      posListingQuantity
                                    )}
                                    render={({
                                      field: { value, onChange, ...field },
                                    }) => {
                                      return (
                                        <PosMultiSelect
                                          disabled={
                                            disabled || mutation.isPending
                                          }
                                          values={getQuantitiesFilterValue(
                                            value
                                          )}
                                          valueOptionsContent={
                                            quantitiesFilterOptions
                                          }
                                          onChange={(val) =>
                                            onUpdateQuantitiesFilter(
                                              val,
                                              onChange
                                            )
                                          }
                                          sortFn={(a, b) =>
                                            parseInt(a) - parseInt(b)
                                          }
                                          {...field}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </Fragment>
                            )
                          )}
                          {greaterThanOrEqualToQuantityFilter && (
                            <>
                              <Fragment
                                key={
                                  greaterThanOrEqualToQuantityFilter.posListingQuantity
                                }
                              >
                                <div>
                                  {quantityPackLabel(
                                    greaterThanOrEqualToQuantityFilter.posListingQuantity,
                                    true,
                                    true
                                  )}
                                </div>
                                <div>
                                  <Controller
                                    control={control}
                                    name={`compListingQuantityFilters.greaterThanOrEqualToQuantityFilter.compQuantityFilters`}
                                    defaultValue={getDefaultQuantitiesFilter(
                                      greaterThanOrEqualToQuantityFilter.posListingQuantity
                                    )}
                                    render={({
                                      field: { value, onChange, ...field },
                                    }) => {
                                      return (
                                        <PosMultiSelect
                                          disabled={
                                            disabled || mutation.isPending
                                          }
                                          values={getQuantitiesFilterValue(
                                            value
                                          )}
                                          valueOptionsContent={
                                            quantitiesFilterOptions
                                          }
                                          onChange={(val) =>
                                            onUpdateQuantitiesFilter(
                                              val,
                                              onChange
                                            )
                                          }
                                          sortFn={(a, b) =>
                                            parseInt(a) - parseInt(b)
                                          }
                                          {...field}
                                        />
                                      );
                                    }}
                                  />
                                </div>
                              </Fragment>
                            </>
                          )}
                        </>
                      )}
                      {!hasPricingMultiSelectCompQuantitiesFeature &&
                        defaultQuantityFilters?.map((qf) => (
                          <Fragment key={qf.ordinal}>
                            <div>{quantityPackLabel(qf.ordinal)}</div>
                            <div>
                              <Controller
                                control={control}
                                name={`defaultQuantityFilters.${qf.ordinal}.quantity`}
                                defaultValue={2}
                                render={({
                                  field: { value, onChange, ...field },
                                }) => {
                                  return (
                                    <PosSelect
                                      disabled={disabled || mutation.isPending}
                                      enableEmptySelection
                                      placeholderText={
                                        ContentId.DefaultQuantity
                                      }
                                      value={
                                        value == null ? '2' : value.toString()
                                      }
                                      valueOptionsContent={getListingPackQuantityOptions(
                                        10
                                      )}
                                      onChange={(val) => {
                                        onChange(
                                          val === '' ? null : parseInt(val)
                                        );
                                      }}
                                      {...field}
                                    />
                                  );
                                }}
                              />
                            </div>
                          </Fragment>
                        ))}
                    </div>
                  </Stack>

                  <Stack gap="m" alignItems="center">
                    <Content id={ContentId.MaxDiscountInputPrompt1} />
                    <PosTextField
                      disabled={disabled || mutation.isPending}
                      type="number"
                      min={0}
                      max={100}
                      postfixDisplay="%"
                      rootProps={{
                        className: styles.inputInline,
                      }}
                      className={styles.inputInline}
                      {...register('maxDiscountVelocityPercent', {
                        setValueAs: setValueAsNumber,
                      })}
                    />
                    <Content id={ContentId.MaxDiscountInputPrompt2} />
                    <PosTextField
                      disabled={disabled || mutation.isPending}
                      type="number"
                      min={1}
                      max={24}
                      rootProps={{
                        className: styles.inputInline,
                      }}
                      className={styles.inputInline}
                      {...register('maxDiscountVelocityTicksInHours', {
                        setValueAs: setValueAsNumber,
                      })}
                    />
                    <Content id={ContentId.MaxDiscountInputPrompt3} />
                  </Stack>
                  <PosFormField
                    label={<Content id={ContentId.FloorRelativeToUnitCost} />}
                    className={styles.textField}
                  >
                    <PosTextField
                      disabled={disabled || mutation.isPending}
                      type="number"
                      postfixDisplay="%"
                      {...register('relativeFloor', {
                        setValueAs: setValueAsNumber,
                      })}
                    />
                  </PosFormField>
                  <PosFormField
                    label={<Content id={ContentId.CeilingRelativeToUnitCost} />}
                    className={styles.textField}
                  >
                    <PosTextField
                      disabled={disabled || mutation.isPending}
                      type="number"
                      postfixDisplay="%"
                      {...register('relativeCeiling', {
                        setValueAs: setValueAsNumber,
                      })}
                    />
                  </PosFormField>
                  <PosFormField
                    label={<Content id={ContentId.OutlierMode} />}
                    className={styles.textField}
                  >
                    <PosEnumSelect
                      value={outlierMode}
                      disabled={disabled || mutation.isPending}
                      valueOptionsContent={OUTLIER_MODE_TO_CID}
                      enableEmptySelection={true}
                      placeholderText={ContentId.Default}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(v) => {
                        if (v) {
                          setValue('outlierMode', v as AutoPricingOutlierMode);
                        } else {
                          setValue('outlierMode', null);
                        }

                        if (
                          v == null ||
                          (v as AutoPricingOutlierMode) !==
                            AutoPricingOutlierMode.StandardDeviations
                        ) {
                          setValue('standardDeviations', null);
                        }
                      }}
                    />
                  </PosFormField>
                  <PosFormField
                    label={<Content id={ContentId.OutlierFiltering} />}
                    className={styles.textField}
                  >
                    <PosEnumSelect
                      value={standardDeviations?.toString()}
                      disabled={
                        disabled ||
                        mutation.isPending ||
                        outlierMode !==
                          AutoPricingOutlierMode.StandardDeviations
                      }
                      valueOptionsContent={STANDARD_DEVIATIONS_TO_CID}
                      enableEmptySelection={true}
                      placeholderText={ContentId.Default}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(v) => {
                        const value = parseInt(v ?? '');
                        if (value != null && !isNaN(value)) {
                          setValue('standardDeviations', value);
                        } else {
                          setValue('standardDeviations', null);
                        }
                      }}
                    />
                  </PosFormField>
                </Stack>
              </div>
            </div>
          )}
          {marketplaceSettings?.map((settings) => {
            // Only show instructions for ticketmaster accounts where the token version is 1
            // and username exists
            if (
              settings.marketplace !== Marketplace.Ticketmaster ||
              settings.tokenVersion !== 1 ||
              !settings.userName
            ) {
              return null;
            }
            return (
              <div
                key={settings.marketplace}
                className={styles.sectionContainer}
              >
                <div className={styles.sectionName}>
                  <Content id={ContentId.TicketmasterTradeDeskApi} />
                </div>
                <TradeDeskApiResetInstructions
                  marketplaceUserName={settings.userName}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.footer}>
          <Button
            disabled={disabled || mutation.isPending}
            onClick={handleSubmit((data) => {
              if (
                !isEqual(
                  data.marketplaceSettings?.map((m) => m.isEnabled),
                  formState.defaultValues?.marketplaceSettings?.map(
                    (m) => m?.isEnabled
                  )
                )
              ) {
                marketplaceEnableDisableConfirmDlg.launchDialog();
              } else {
                mutation.mutate(
                  toSyncCenterSettings(
                    data,
                    hasPricingMultiSelectCompQuantitiesFeature
                  )
                );
              }
            })}
          >
            <Content id={ContentId.Save} />
          </Button>
        </div>
        <ConfirmDialog
          {...marketplaceEnableDisableConfirmDlg.dialogProps}
          size="m"
          headerText={
            <Content id={ContentId.MarketplaceEnableDisableConfirm} />
          }
          bodyText={
            <Stack direction="column" gap="l">
              <Stack direction="column" gap="s">
                <span>
                  <Content id={ContentId.MarketplaceEnableConfirmMessage} />
                </span>
                <span>
                  <b>{getMarketplaceEnableDisable(true)}</b>
                </span>
              </Stack>
              <Stack direction="column" gap="s">
                <span>
                  <Content id={ContentId.MarketplaceDisableConfirmMessage} />
                </span>
                <span>
                  <b>{getMarketplaceEnableDisable(false)}</b>
                </span>
              </Stack>
              <span>
                <Content id={ContentId.DoYouWantToContinue} />
              </span>
            </Stack>
          }
          onOkay={handleSubmit((data) => {
            mutation.mutate(
              toSyncCenterSettings(
                data,
                hasPricingMultiSelectCompQuantitiesFeature
              )
            );
            marketplaceEnableDisableConfirmDlg.closeDialog();
          })}
          onCancel={marketplaceEnableDisableConfirmDlg.closeDialog}
          okText={ContentId.Yes}
        />
      </>
    </FormProvider>
  );
}
