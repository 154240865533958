import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const FieldLabelWrapper = styled.div`
  font-weight: 600;
  ${({
    theme: {
      media,
      typography: { getStyles },
    },
  }) => `
${getStyles('body1')}
  padding-left: 0;
  ${media.makeMediaQuery(
    media.breakpoints.md,
    `padding-left: 0px;` // reset padding for tablets and above
  )}
`}
`;

export const FieldWrapper = styled.div`
  padding-block-end: ${() => vars.spacing.m};

  label {
    width: 100%;
    margin-bottom: 0;
  }
`;
