import { vars } from 'src/core/themes';

import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const GroupItemIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper
      {...props}
      viewBox="0 0 17 16" // The viewbox here is important as it won't display properly without this specific value
      size={vars.iconSize.s}
      fill={vars.color.textDisabled}
    >
      <g stroke="none" strokeWidth="1" fill="inherit" fillRule="evenodd">
        <g transform="translate(1.000000, 0.000000)" fill="inherit">
          <path d="M7,4.769 C7,5.956 6.024,6.917 4.818,6.917 L2.181,6.917 C0.977,6.917 0.001,5.955 0.001,4.769 L0.001,2.233 C0.001,1.046 0.977,0.085 2.181,0.085 L4.818,0.085 C6.024,0.085 7,1.047 7,2.233 L7,4.769 L7,4.769 Z"></path>
          <path d="M7,13.818 C7,15.023 6.024,16 4.818,16 L2.181,16 C0.977,16 0.001,15.023 0.001,13.818 L0.001,11.181 C0.001,9.976 0.977,8.999 2.181,8.999 L4.818,8.999 C6.024,8.999 7,9.976 7,11.181 L7,13.818 L7,13.818 Z"></path>
          <path d="M16,4.769 C16,5.956 15.024,6.917 13.818,6.917 L11.181,6.917 C9.977,6.917 9.001,5.955 9.001,4.769 L9.001,2.233 C9.001,1.046 9.977,0.085 11.181,0.085 L13.818,0.085 C15.024,0.085 16,1.047 16,2.233 L16,4.769 L16,4.769 Z"></path>
          <path d="M16,13.818 C16,15.023 15.024,16 13.818,16 L11.181,16 C9.977,16 9.001,15.023 9.001,13.818 L9.001,11.181 C9.001,9.976 9.977,8.999 11.181,8.999 L13.818,8.999 C15.024,8.999 16,9.976 16,11.181 L16,13.818 L16,13.818 Z"></path>
        </g>
      </g>
    </SvgWrapper>
  );
};
