import { ComponentProps, ReactNode, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent, useFormattedContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isSuccess } from 'src/utils/errorUtils';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  PosClientConfig,
  PosUiActionResult,
  TicketBarcode,
} from 'src/WebApiController';

import {
  BarcodeForm,
  checkBarcodeErrors,
  checkDuplicateBarcodes,
} from './UploadBarcodes.utils';

export type UploadBarcodesFormProps = {
  entityWithTickets: Pick<
    EntityWithRealTickets,
    'viagVirtualId' | 'id' | 'ticketCnt' | 'tickets' | 'seating'
  >;
  renderContent: (onUploadClick: () => void, disabled?: boolean) => ReactNode;
  onComplete: (isCancelled: boolean) => void;
  onUpload?: (
    posClientConfig: PosClientConfig,
    entityId: number,
    barcodes: TicketBarcode[]
  ) => Promise<PosUiActionResult>;
  defaultToBulk?: boolean;
  isReadOnly?: boolean;
};

export const UploadBarcodesForm = (props: UploadBarcodesFormProps) => {
  const methods = useForm<BarcodeForm>({
    defaultValues: {
      barcodes: props.entityWithTickets.tickets?.map(
        ({ id, barcode, row, seat }) => {
          return {
            ticketId: id,
            barcode: barcode || '',
            section: props.entityWithTickets.seating.section,
            row: row,
            seat: seat,
          };
        }
      ),
    },
  });
  return (
    <FormProvider {...methods}>
      <UploadBarcodesContent {...props} {...methods} />
    </FormProvider>
  );
};

const UploadBarcodesContent = ({
  entityWithTickets,
  renderContent,
  onComplete,
  onUpload,
  handleSubmit,
  setError,
  watch,
  isReadOnly = false,
}: UploadBarcodesFormProps &
  Omit<
    ComponentProps<typeof FormProvider<BarcodeForm, unknown>>,
    'children'
  >) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { ticketCnt } = entityWithTickets;
  const barcodes = watch('barcodes');
  const bulkUpload = watch('isBulkUpload');

  const [readOnly, setReadOnly] = useState<boolean>(isReadOnly);

  const [alreadyReportedUsedBarcodes, setAlreadyReportedUsedBarcodes] =
    useState<string[]>([]);
  const barcodeAlreadyUsedWarning = useContent(ContentId.BarcodeAlreadyInUsed);

  const { showErrorDialog, genericError } = useErrorBoundaryContext();

  const requiredMsg = useContent(ContentId.Required);
  const duplicateMsg = useContent(ContentId.DuplicateBarcode);
  const barcodesProvided =
    barcodes?.map((b) => b.barcode).filter((b) => b?.length) ?? [];
  const missingMsg = useFormattedContent(
    FormatContentId.MissingBarcodes,
    `${ticketCnt - barcodesProvided.length}`
  );

  const extraMsg = useFormattedContent(
    FormatContentId.ExtraBarcodes,
    `${barcodesProvided.length - ticketCnt}`
  );

  const onSubmit = useCallback(
    async ({ barcodes }: BarcodeForm) => {
      if (!onUpload) {
        console.log(
          'Internal Error - no onUploadBarcodes provided for non-readonly mode.'
        );
        return;
      }

      if (!entityWithTickets) {
        console.log('Internal Error - entity (listing or sale) is null.');
        return;
      }

      tryInvokeApi(
        async () => {
          setReadOnly(true);

          const result = await onUpload(
            activeAccountWebClientConfig,
            entityWithTickets.id,
            barcodes!
          );
          if (!isSuccess(result)) {
            showErrorDialog(
              'onUploadBarcodes',
              {
                message: result.message ?? genericError,
                status: result.status!,
              } as ApiException,
              {
                onDismissError: () => onComplete(true),
                trackErrorData: {
                  saleId: entityWithTickets?.id,
                  barcodes,
                },
              }
            );
          } else {
            onComplete(false);
          }
        },
        (error) => {
          showErrorDialog('onUploadBarcodes', error, {
            trackErrorData: {
              saleId: entityWithTickets?.id,
              barcodes,
            },
          });
        },
        () => setReadOnly(false)
      );
    },
    [
      onUpload,
      entityWithTickets,
      activeAccountWebClientConfig,
      showErrorDialog,
      genericError,
      onComplete,
    ]
  );

  const onUploadClick = useCallback(async () => {
    let hasErrors = checkBarcodeErrors(
      bulkUpload,
      barcodes,
      ticketCnt,
      setError,
      requiredMsg,
      missingMsg as string,
      extraMsg as string,
      duplicateMsg
    );

    if (hasErrors) {
      return;
    }

    hasErrors = Boolean(
      await checkDuplicateBarcodes(
        entityWithTickets!.id,
        barcodes,
        activeAccountWebClientConfig,
        alreadyReportedUsedBarcodes,
        setAlreadyReportedUsedBarcodes,
        (index: number) =>
          setError(`barcodes.${index}.barcode`, {
            type: 'warning',
            message: barcodeAlreadyUsedWarning,
          }),
        () => setReadOnly(true),
        () => setReadOnly(false),
        showErrorDialog,
        entityWithTickets!.viagVirtualId
      )
    );

    if (!hasErrors) {
      handleSubmit(onSubmit)();
    }
  }, [
    bulkUpload,
    barcodes,
    ticketCnt,
    setError,
    requiredMsg,
    missingMsg,
    extraMsg,
    duplicateMsg,
    entityWithTickets,
    activeAccountWebClientConfig,
    alreadyReportedUsedBarcodes,
    showErrorDialog,
    barcodeAlreadyUsedWarning,
    handleSubmit,
    onSubmit,
  ]);

  const reallyReadOnly = isReadOnly || readOnly;

  return renderContent(onUploadClick, reallyReadOnly);
};
