import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TicketTypePriorityInput } from 'src/components/common/TicketTypePriorityInput';
import { DeliveryTypeSelector } from 'src/components/Selectors/DeliveryTypeSelector';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { PosFormField } from 'src/core/POS/PosFormField';
import { Stack, Switch } from 'src/core/ui';
import {
  PosAccordionContent,
  PosAccordionItem,
  PosAccordionRoot,
  PosAccordionTrigger,
} from 'src/core/ui/Accordion';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FieldWrapper } from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { DeliveryType, Feature, PurchaseVendor } from 'src/WebApiController';

import * as styles from './PurchaseVendorDialog.css';

export const PurchaseVendorDeliveryOverrideInput = ({
  collapsible,
}: {
  collapsible?: boolean;
}) => {
  const hasSellerAccountPurchaseVendorOverrideFeature = useUserHasFeature(
    Feature.SellerAccountPurchaseVendorOverride
  );

  if (!hasSellerAccountPurchaseVendorOverrideFeature) {
    return null;
  }

  if (collapsible) {
    return (
      <PosAccordionRoot type="single" collapsible>
        <PosAccordionItem value={'delivery'}>
          <PosAccordionTrigger>
            <div className={styles.sectionHeading}>
              <Content id={ContentId.DefaultDeliveryConfigurations} />
            </div>
          </PosAccordionTrigger>
          <PosAccordionContent>
            <PurchaseVendorDeliveryOverrideInputContent />
          </PosAccordionContent>
        </PosAccordionItem>
      </PosAccordionRoot>
    );
  }

  return (
    <Stack gap="m" direction="column">
      <div className={styles.sectionHeading}>
        <Content id={ContentId.DefaultDeliveryConfigurations} />
      </div>

      <PurchaseVendorDeliveryOverrideInputContent />
    </Stack>
  );
};

const PurchaseVendorDeliveryOverrideInputContent = () => {
  const { formState, watch, setValue } = useFormContext<PurchaseVendor>();

  const hasAutoFulfillSettingFeature = useUserHasFeature(
    Feature.AutoFulfillSetting
  );

  const deliveryType = watch('delivType');
  const isNoFulfill = watch('isNoFulfill');
  const ticketTypeRules = watch('tktTypeRules');

  const [skipAutoFulfill, setSkipAutoFulfill] = useState(isNoFulfill == null);
  const [skipDeliveryOverride, setSkipDeliveryOverride] = useState(
    deliveryType == null
  );

  return (
    <Stack gap="m" direction="column">
      <div className={styles.subtleText}>
        <Content id={ContentId.DefaultDeliveryConfigurationsDescription} />
      </div>
      {hasAutoFulfillSettingFeature && (
        <>
          <Checkbox
            label={<Content id={ContentId.EnableAutoFulfillOverride} />}
            labelPosition="right"
            onChange={(e) => {
              const skip = !e.target.checked;
              setSkipAutoFulfill(skip);
              if (skip) {
                setValue('isNoFulfill', null, {
                  shouldDirty: true,
                });
              } else {
                // We want to default to false if the user enables the setting
                setValue('isNoFulfill', true, {
                  shouldDirty: true,
                });
              }
            }}
            checked={!skipAutoFulfill}
          />
          {!skipAutoFulfill && (
            <FieldWrapper>
              <PosFormField label={<Content id={ContentId.AutoFulfill} />}>
                <Switch
                  checked={!isNoFulfill}
                  disabled={formState.isSubmitting}
                  onChange={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked) => {
                    setValue('isNoFulfill', !isChecked, {
                      shouldDirty: true,
                    });
                  }}
                />
              </PosFormField>
            </FieldWrapper>
          )}
        </>
      )}

      <Checkbox
        label={<Content id={ContentId.EnableDeliveryOverride} />}
        labelPosition="right"
        onChange={(e) => {
          const skip = !e.target.checked;
          setSkipDeliveryOverride(skip);
          if (skip) {
            setValue('delivType', null, {
              shouldDirty: true,
            });
          } else {
            setValue('delivType', DeliveryType.InApp, {
              shouldDirty: true,
            });
          }

          setValue('tktTypeRules', null, {
            shouldDirty: true,
          });
        }}
        checked={!skipDeliveryOverride}
      />
      {!skipDeliveryOverride && (
        <>
          <FieldWrapper>
            <PosFormField label={<Content id={ContentId.DeliveryType} />}>
              <DeliveryTypeSelector
                disabled={formState.isSubmitting}
                value={deliveryType}
                style={{ maxWidth: '500px' }}
                placeholderText={ContentId.None}
                onChange={(newD) => {
                  if (newD !== deliveryType) {
                    setValue('delivType', newD, {
                      shouldDirty: true,
                    });
                    if (!newD) {
                      setValue('tktTypeRules', null, {
                        shouldDirty: true,
                      });
                    }
                  }
                }}
              />
            </PosFormField>
          </FieldWrapper>
          {deliveryType && (
            <FieldWrapper>
              <PosFormField label={<Content id={ContentId.DeliveryStrategy} />}>
                <TicketTypePriorityInput
                  deliveryType={deliveryType}
                  ticketTypeRules={ticketTypeRules}
                  onTicketTypeRulesChange={(newRules) => {
                    setValue('tktTypeRules', newRules, {
                      shouldDirty: true,
                    });
                  }}
                  disabled={formState.isSubmitting}
                />
              </PosFormField>
            </FieldWrapper>
          )}
        </>
      )}
    </Stack>
  );
};
