import { useMemo } from 'react';
import { splitIntoGroups } from 'src/utils/arrayUtils';
import { CatalogResults } from 'src/WebApiController';

import { MAX_ENTITIES_IN_GROUP_FOR_LOAD } from './CatalogMetrics.type';

export function useGetEventGroupsToGetMetricsFor(
  data: CatalogResults | null | undefined
) {
  const events = data?.events;
  const allEventIds = useMemo(
    () =>
      Object.values(events ?? {})
        .map((ev) => ({
          viagVirtualId: ev.event.viagVirtualId,
          performerId: ev.event.perfId,
          venueId: ev.event.venueId,
        }))
        .sort((ev1, ev2) => ev1.viagVirtualId.localeCompare(ev2.viagVirtualId)),
    [events]
  );

  // This is the event ids we need to get metrics for at the moment
  const eventIdsToLoadMetricsFor = useMemo(
    () => new Set(Object.keys(events ?? [])),
    [events]
  );

  const allEventIdGroups = useMemo(() => {
    return splitIntoGroups(allEventIds, MAX_ENTITIES_IN_GROUP_FOR_LOAD);
  }, [allEventIds]);

  const groupsToGetMetricsFor = useMemo(() => {
    if (allEventIdGroups.length <= 1) {
      // If there's no group or only 1 group, just return it
      // No point in doing the filter below over 1 group
      return allEventIdGroups.length === 1 ? [allEventIdGroups[0]] : [];
    }

    const idGroups = allEventIdGroups.filter((gr) =>
      gr.some((ev) => eventIdsToLoadMetricsFor.has(ev.viagVirtualId))
    );

    // If we found groups, return them, else, just return the first one
    return idGroups.length ? idGroups : [allEventIdGroups[0]] ?? [];
  }, [allEventIdGroups, eventIdsToLoadMetricsFor]);
  return groupsToGetMetricsFor;
}
