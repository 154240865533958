/* eslint-disable @typescript-eslint/no-unused-vars */
import { EntityWithTicketsQuery } from 'src/WebApiController';

import { QueryWithViewMode } from './eventQueryUtils';

export const getServerSideOnlyMetricsQuery = (
  query: EntityWithTicketsQuery & QueryWithViewMode
) => {
  const {
    // IMPORTANT: pulling out all the parts that do not affect server and if we leave them in
    // they will trigger a re-get unnecessarily and cause performance issue
    searchText,
    sortBy,
    isSortDescending,
    performerIds,
    venueIds,
    viewMode,
    ...rest
  } = query;

  return rest as EntityWithTicketsQuery;
};
