import { useCallback, useState } from 'react';
import { FieldValues, UseFormHandleSubmit } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import {
    Alert,
    AlertWithSuppressionDialog,
} from 'src/dialogs/AlertWithSuppressionDialog';
import { useMergeGroupedListingDialog } from 'src/dialogs/AlertWithSuppressionDialog/useMergeGroupedListingDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';

export function useMergeGroupedListingAlertDialog<TInput extends FieldValues>(
  handleSubmit: UseFormHandleSubmit<TInput, undefined>,
  mergeListings: (
    formData: TInput,
    closeModalAfterMerge?: boolean
  ) => Promise<void | null>
) {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const warningDialog = useBasicDialog();

  const mergeGroupedListingHookProps = useMergeGroupedListingDialog(
    () => {
      handleSubmit(onSubmit)();
    },
    () => {}
  );

  const onSubmit = useCallback(
    async (formData: TInput) => {
      return await mergeListings(formData, true);
    },
    [mergeListings]
  );

  return {
    setAlerts,
    warningDialog,
    mergeGroupedListingHookProps,
    onSubmit,
    AlertDialog: (
      <AlertWithSuppressionDialog
        headerText={<Content id={ContentId.MergeListings} />}
        size="md"
        {...warningDialog.dialogProps}
        alerts={alerts}
        onOkay={() => {
          warningDialog.closeDialog();
        }}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
        onCancel={() => warningDialog.closeDialog()}
      />
    ),
  };
}
