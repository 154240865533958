import { BOOLEAN_OPTIONS_CONTENT } from 'src/utils/constants/contentIdMaps';
import { TagsValueType } from 'src/WebApiController';

export const URL_REGEX_TAGS =
  /^((http|https):\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;

export const checkMatchValueType = (
  valueType: TagsValueType | null,
  value: string
) => {
  if (valueType == null) return true;
  switch (valueType) {
    case TagsValueType.String:
      return true;
    case TagsValueType.Url:
      return Boolean(value.match(URL_REGEX_TAGS));
    case TagsValueType.Decimal:
      return !isNaN(Number(value));
    case TagsValueType.Int:
      return !isNaN(Number(value)) && Number.isInteger(Number(value));
    case TagsValueType.Boolean:
      return Object.values(BOOLEAN_OPTIONS_CONTENT).includes(
        value.toLowerCase()
      );
    default:
      return true;
  }
};
