import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PricingClient } from 'src/WebApiController';

export const useGetAutoCompSettings = ({
  viagogoEventId,
}: {
  viagogoEventId: number | null;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const shouldQuery = Boolean(viagogoEventId);

  return useQuery({
    queryKey: ['ListingGroupClient.getAutoCompSettings', viagogoEventId],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const result = await new PricingClient(
        activeAccountWebClientConfig
      ).getAutoCompSettings(viagogoEventId!);

      return result;
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('ListingGroupClient.getAutoCompSettings', error, {
          trackErrorData: {
            viagogoEventId,
          },
        });
      },
    },
  });
};
