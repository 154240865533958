import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const IconButtonContainer = styled.div<{ disabled?: boolean }>`
  all: unset;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${() => vars.borderRadius.s};
  padding: 2px ${() => vars.spacing.sm};
  transition: all 0.3s ease;
  max-width: fit-content;
  svg {
    cursor: pointer;
  }

  :hover {
    background: ${(props) => props.theme.colors.backgroundBrandLight};
  }
`;
