import { MutableRefObject, useImperativeHandle } from 'react';
import { PurchaseVendorAccountDialog } from 'src/dialogs/PurchaseVendorAccountDialog';
import { PurchaseVendorDialog } from 'src/dialogs/PurchaseVendorDialog';
import { useAddVendorAccountActions } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorActions/useAddVendorAccountActions';
import { useAddVendorActions } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorActions/useAddVendorActions';
import { useOpenVendorDetailsModal } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorActions/useOpenVendorDetailsModal';
import { PurchaseVendorActions } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsTable/VendorsTableColumnsConfig';
import { PurchaseVendor } from 'src/WebApiController';

export type PurchaseVendorActionsImperativeRef = PurchaseVendorActions;

interface PurchaseVendorActionsProps {
  vendorActionsRef: MutableRefObject<PurchaseVendorActionsImperativeRef | null>;
}

export const VendorActions = ({
  vendorActionsRef,
}: PurchaseVendorActionsProps) => {
  const {
    onVendorCancel,
    onSaveVendor,
    vendorDialog,
    currentVendor,
    onEditVendor,
  } = useAddVendorActions();

  const {
    currentVendorAccount,
    onSaveVendorAccount,
    onVendorAccountCancel,
    venderAccountDialog,
    onAddNewVendorAccount,
  } = useAddVendorAccountActions();

  const { openVendorDetailsModal } = useOpenVendorDetailsModal();

  useImperativeHandle(
    vendorActionsRef,
    () => {
      return {
        editVendor: onEditVendor,
        viewVendorDetails: (vendor: PurchaseVendor) => {
          openVendorDetailsModal(vendor.id);
        },
        addNewVendorAccount: (vendor: PurchaseVendor) => {
          onAddNewVendorAccount(vendor.id);
        },
      };
    },
    [onAddNewVendorAccount, onEditVendor, openVendorDetailsModal]
  );

  return (
    <>
      {currentVendor && (
        <PurchaseVendorDialog
          {...vendorDialog.dialogProps}
          vendor={currentVendor}
          onClosed={onVendorCancel}
          onSave={onSaveVendor}
          useReducedEdit={true}
        />
      )}
      {currentVendorAccount && (
        <PurchaseVendorAccountDialog
          {...venderAccountDialog.dialogProps}
          vendorAccountId={currentVendorAccount.id}
          vendorId={currentVendorAccount.vendorId}
          onClosed={onVendorAccountCancel}
          onSave={onSaveVendorAccount}
        />
      )}
    </>
  );
};
