import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { BulkAddListingAdminHoldDialog } from 'src/dialogs/BulkEdits/BulkAddListingAdminHoldDialog';
import { SQL_MAX_DATEITME } from 'src/utils/dateTimeUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_ADMIN_HOLD_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchAddHold } from './LaunchAddHold';

export const useBulkAddHoldAction = (
  filterQueryWithEventIds: ListingQuery,

  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = event
    ? `adminHold-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_ADMIN_HOLD_UPDATE_KEY;
  const {
    refreshCatalog,
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkAddListingAdminHold,
    closeDialog: closeBulkAddListingAdminHold,
  } = useDialog(
    DialogId.BulkAddListingAdminHold,
    BulkAddListingAdminHoldDialog
  );

  const onSubmitAddHold = useCallback(
    async (
      adminHoldExpirationDate: Date | null,
      adminHoldNotes: string | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateAdminHoldPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateAdminHoldPreview', error, {
              trackErrorData: { filterQueryWithEventIds, addHold: true },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateAdminHold(
              preview!.preview,
              (adminHoldExpirationDate ?? SQL_MAX_DATEITME).toISOString(),
              adminHoldNotes,
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                if (!event) {
                  await refreshCatalog();
                }
                await refreshExpandedListItems();
              }

              closeBulkAddListingAdminHold();
            }
          },
          (error) => {
            showErrorDialog(
              'BulkEditListingClient.bulkUpdateAdminHold',
              error,
              {
                trackErrorData: {
                  adminHoldExpirationDate,
                  adminHoldNotes,
                  preview,
                  addHold: true,
                },
              }
            );
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQueryWithEventIds,
      showErrorDialog,
      updateKey,
      closeBulkAddListingAdminHold,
      event,
      refreshExpandedListItems,
      refreshCatalog,
    ]
  );

  return {
    dropDown: (
      <LaunchAddHold
        key="AddHold"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (event) {
            setListItemExpansion(true, [event.viagVirtualId]);
          }
          openBulkAddListingAdminHold({
            event,
            updateKey,
            onOkay: onSubmitAddHold,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkAddListingAdminHold,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
