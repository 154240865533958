import { ComponentProps, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { MultiSelectDropBoxWithPills } from 'src/core/POS/MultiSelectDropBoxWithPills';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { ContentId } from 'src/utils/constants/contentId';
import { INVENTORY_REPORT_WIDGETS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { ReportWidgetTypesInventory } from 'src/WebApiController';

export type WidgetsSelectionInputProps = Omit<
  ComponentProps<typeof MultiSelectDropBoxWithPills>,
  'values' | 'onChange' | 'valueOptionsContent'
>;

export const WidgetsSelectionInput = ({
  disabled,
  ...multiSelectorProps
}: WidgetsSelectionInputProps) => {
  const { setValue, watch } = useFormContext<ReportConfig>();

  const widgets = watch('widgets');

  const onSelectionChanged = useCallback(
    (newWidgets: string[]) => {
      setValue('widgets', newWidgets as ReportWidgetTypesInventory[]);
    },
    [setValue]
  );

  return (
    <>
      <MultiSelectDropBoxWithPills
        {...multiSelectorProps}
        searchable
        placeholderText={ContentId.ChooseWidgets}
        valueOptionsContent={INVENTORY_REPORT_WIDGETS_TO_CID}
        avoidCollisions={false}
        side="bottom"
        disabled={disabled}
        values={widgets ?? []}
        onChange={onSelectionChanged}
        fullWidth={true}
      />
    </>
  );
};
