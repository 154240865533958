import { useEffect } from 'react';
import { SidePanelFilters } from 'src/components/Events/EventPage/SidePanel/SidePanelFilters';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useQuickFilters } from 'src/hooks/useQuickFilters';
import { PurchaseOrderQuery, UserSetting } from 'src/WebApiController';

import { useDefaultPurchaseFilters } from './hooks/useDefaultPurchaseFilters';

export function PurchaseSideTableFilterBar() {
  const {
    filterQuery: purchaseQuery,
    initialQuery,
    setFilterQuery,
    setTempQuery,
  } = useFilterQueryContext<PurchaseOrderQuery>();

  useEffect(() => {
    setTempQuery(purchaseQuery);
  }, [purchaseQuery, setTempQuery]);

  const defaultQuickFilters = useDefaultPurchaseFilters({
    purchaseQuery,
    initialQuery,
  });

  const { quickFilters } = useQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStatePurchases,
    customQuickFiltersSetting: UserSetting.QuickFiltersCustomPurchases,
    defaultQuickFilters,
  });

  return (
    <SidePanelFilters
      setFilterQuery={setFilterQuery}
      quickFilters={quickFilters}
      initialQuery={initialQuery}
    />
  );
}
