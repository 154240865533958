import { ComponentProps, forwardRef, ReactElement } from 'react';
import { TextArea } from 'src/core/ui';

export type PosTextAreaProps = ComponentProps<typeof TextArea.Area> & {
  rootProps?: Omit<ComponentProps<typeof TextArea.Root>, 'children'>;
};

export const PosTextArea = forwardRef<HTMLTextAreaElement, PosTextAreaProps>(
  function PosTextArea({ rootProps, ...rest }, ref): ReactElement {
    return (
      <TextArea.Root {...(rootProps ?? {})}>
        <TextArea.Area ref={ref} {...rest} />
      </TextArea.Root>
    );
  }
);
