import { useCallback } from 'react';
import { LaunchBulkEditTags } from 'src/components/common/BulkActions/BulkEditTagsAction';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  BulkEditTagAction,
  BulkEditTagDialog,
} from 'src/dialogs/BulkEdits/BulkEditTagDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_TAGS_UPDATE_KEY } from '../../InventoryActionDropdown.constants';

export const useBulkEditTagsAction = (
  filterQueryWithEventIds: ListingQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const updateKey = event
    ? `listingTags-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_TAGS_UPDATE_KEY;
  const {
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkEditTagDialog,
    closeDialog: closeBulkEditTagDialog,
  } = useDialog(DialogId.BulkEditTags, BulkEditTagDialog);

  const onSubmitEditTags = useCallback(
    async (
      action: BulkEditTagAction,
      tagKey: string | null,
      tagValue?: string | undefined,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateTagsPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateTagsPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (tagKey) {
        await tryInvokeApi(
          async () => {
            const listingClient = new BulkEditListingClient(
              activeAccountWebClientConfig
            );

            const succeeded =
              action === BulkEditTagAction.Update
                ? await listingClient.bulkUpdateTags(
                    preview!.preview,
                    tagKey,
                    tagValue,
                    updateKey,
                    supportBackgroundProcess
                  )
                : await listingClient.bulkRemoveTags(
                    preview!.preview,
                    tagKey,
                    updateKey,
                    supportBackgroundProcess
                  );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }

              closeBulkEditTagDialog();
            }
          },
          (error) => {
            showErrorDialog('onSubmitEditTags', error, {
              trackErrorData: { tagKey, tagValue, preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      setIsLoading,
      filterQueryWithEventIds,
      showErrorDialog,
      updateKey,
      closeBulkEditTagDialog,
      setActivePosEntity,
      refreshExpandedListItems,
    ]
  );

  const onChangeTagClick = useCallback(
    (e: React.MouseEvent, editTagAction: BulkEditTagAction) => {
      e.preventDefault();
      e.stopPropagation();
      if (event) {
        setListItemExpansion(true, [event.viagVirtualId]);
      }

      openBulkEditTagDialog({
        updateKey: updateKey,
        action: editTagAction,
        onOkay: onSubmitEditTags,
        entityType: ActionOutboxEntityType.Listing,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkEditTagDialog,
      });
    },
    [
      closeBulkEditTagDialog,
      event,
      onSubmitEditTags,
      openBulkEditTagDialog,
      setIsLoading,
      setListItemExpansion,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkEditTags
        key="EditTags"
        onClick={onChangeTagClick}
        disabled={isLoading}
        entityType={ActionOutboxEntityType.Listing}
      />
    ),
  };
};
