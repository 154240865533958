import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const FulfillmentFailedIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 23 24">
      <path
        d="M7.5 15.5H0.5V4.5H14.5V6.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 4.5H0.5L4.5 0.5H10.5L14.5 4.5Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 0.5V4.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 23.5H8.5L15.5 9.5L22.5 23.5Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 14V19.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 21C15.2 21 15 21.2 15 21.5C15 21.8 15.2 22 15.5 22C15.8 22 16 21.8 16 21.5C16 21.2 15.8 21 15.5 21Z"
        fill="black"
      />
    </SvgWrapper>
  );
};
