import { useQuery } from '@tanstack/react-query';
import { sub } from 'date-fns';
import { debounce } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useMatches } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { MessagesAddSourceModal } from 'src/modals/MessagesAddSourceModal/MessagesAddSourceModal';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  AutoPoClient,
  AutoPoInboundEmailClassificationType,
  AutoPoInboundEmailPartialPurchaseOrderQuery,
} from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as styles from './Email.css';
import { EmailNavPageNameHandle } from './Email.types';
import { EMAIL_NAV_PAGE_NAME_MAP, EmailNavToolbar } from './EmailNavToolbar';
import { MessagesExplorer } from './MessagesExplorer';

const SEARCH_DELAY_MS = 1000;

const DEFAULT_QUERY: AutoPoInboundEmailPartialPurchaseOrderQuery = {
  classificationTypes: null,
  includeUnclassified: null,
  subjectSearchText: null,
  fromEmailAddress: null,
  toEmailAddress: null,
  textBodySearchText: null,
  classificationTypeUpdateDateFrom: null,
  classificationTypeUpdateDateTo: null,
};

/**
 * Classification types to include in the "Recently Categorized" page
 */
const RECENTLY_CATEGORIZED_CLASSIFICATION_TYPES = Object.values(
  AutoPoInboundEmailClassificationType
).filter((type) => type !== AutoPoInboundEmailClassificationType.Junk);

function isPageNameHandle(handle: unknown): handle is EmailNavPageNameHandle {
  return (
    handle != null &&
    typeof handle === 'object' &&
    'pageName' in handle &&
    typeof handle.pageName === 'string' &&
    handle.pageName in EMAIL_NAV_PAGE_NAME_MAP
  );
}

export function Email() {
  const matches = useMatches();
  const pageName = matches
    .map(({ handle }) => handle)
    .find(isPageNameHandle)?.pageName;

  const messagesText = useContent(ContentId.Messages);
  const searchForMessagePlaceholderText = useContent(
    ContentId.SearchForMessagePlaceholder
  );

  const [query, setQuery] =
    useState<AutoPoInboundEmailPartialPurchaseOrderQuery>(DEFAULT_QUERY);
  const setQueryDebounced = useMemo(
    () => debounce(setQuery, SEARCH_DELAY_MS),
    []
  );
  useEffect(() => {
    if (pageName === 'uncategorized') {
      setQuery((prev) => ({
        ...DEFAULT_QUERY,
        subjectSearchText: prev.subjectSearchText,
        textBodySearchText: prev.textBodySearchText,
        classificationTypes: null,
        includeUnclassified: true,
      }));
    } else if (pageName === 'recentlyCategorized') {
      setQuery((prev) => ({
        ...DEFAULT_QUERY,
        subjectSearchText: prev.subjectSearchText,
        textBodySearchText: prev.textBodySearchText,
        classificationTypes: RECENTLY_CATEGORIZED_CLASSIFICATION_TYPES,
        classificationTypeUpdateDateFrom: sub(new Date(), {
          weeks: 1,
        }).toISOString(),
      }));
    } else {
      setQuery(DEFAULT_QUERY);
    }
  }, [pageName]);

  const { activeAccountWebClientConfig } = useAppContext();
  const uncategorizedEmailsCountQuery = useQuery({
    queryKey: ['uncategorizedEmailsCount'],
    queryFn() {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new AutoPoClient(
        activeAccountWebClientConfig
      ).getPartialPurchaseOrderInboundEmailsCount({
        ...DEFAULT_QUERY,
        includeUnclassified: true,
      });
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
  });

  const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useState(false);

  if (pageName == null) {
    return <Navigate to="/messages" />;
  }
  return (
    <LayoutContent mainRoute={MainRoute.Email} routeTitle={messagesText}>
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.title}>
            <Content id={ContentId.Messages} />
          </div>
          {pageName !== 'inbox' && (
            <div className={styles.advancedSearch}>
              <PosSearchBox
                placeholder={searchForMessagePlaceholderText}
                onChange={(e) => {
                  const searchText = e.currentTarget.value;
                  setQueryDebounced((prev) => ({
                    ...prev,
                    subjectSearchText: searchText,
                  }));
                }}
              />
            </div>
          )}
        </div>
        <div className={styles.navToolbar}>
          <EmailNavToolbar
            pageName={pageName}
            uncategorizedEmailsCount={uncategorizedEmailsCountQuery.data}
          />
          <Button
            className={styles.addSourceButton}
            onClick={() => {
              setIsAddSourceModalOpen(true);
            }}
          >
            <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
            <Content id={ContentId.AddSource} />
          </Button>
        </div>
        <div className={styles.body}>
          <MessagesExplorer query={query} pageName={pageName} />
        </div>
      </div>
      <MessagesAddSourceModal
        isOpen={isAddSourceModalOpen}
        onClose={() => {
          setIsAddSourceModalOpen(false);
        }}
      />
    </LayoutContent>
  );
}
