import { FormatContent } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { CrossIcon } from 'src/svgs/Viagogo';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { SectionInfo, SectionScoreOverride } from 'src/WebApiController';

import { SectionDisplay } from './SectionDisplay';
import * as styles from './SectionDisplays.css';

export const MultiSectionsDisplay = ({
  sections,
  scoreOverrides,
  onClearSelection,
}: {
  sections: SectionInfo[];
  scoreOverrides?: SectionScoreOverride[] | null;
  onClearSelection?: () => void;
}) => {
  return sections.length === 1 ? (
    <SectionDisplay section={sections[0]} scoreOverrides={scoreOverrides} />
  ) : (
    <Stack direction="column" gap="s">
      <Stack
        gap="xxl"
        style={{ alignItems: 'center', justifyContent: 'space-between' }}
      >
        <div className={styles.sectionDisplay}>
          <FormatContent
            id={FormatContentId.NSelectedSections}
            params={`${sections.length}`}
          />
        </div>
        {onClearSelection && (
          <CrossIcon withHoverEffect onClick={() => onClearSelection()} />
        )}
      </Stack>
    </Stack>
  );
};
