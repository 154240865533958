import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Button, Stack } from 'src/core/ui';
import { AddRowCompOverrideDialog } from 'src/dialogs/AddRowCompOverrideDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { ROW_COMP_SETTING_TO_CID } from 'src/utils/constants/contentIdMaps';

import { GroupListingAutoCompSettingsForm } from '../autoCompGroup.types';
import * as styles from './RowRadioGroup.css';

export const RowComp = () => {
  const { watch, setValue } =
    useFormContext<GroupListingAutoCompSettingsForm>();
  const rowCompOffset = watch('rowCompOffset');
  const addRowCompOverrideDialog = useBasicDialog();

  return (
    <DetailSection name={<Content id={ContentId.RowComps} />}>
      <SectionContent numOfColumns={1}>
        <DetailGroup style={{ gridColumn: '1' }}>
          <Stack direction="column" alignItems="start">
            <Stack direction="row" gap="xl" alignItems="center">
              {Object.entries(ROW_COMP_SETTING_TO_CID).map(
                ([option, contentId]) => {
                  return (
                    <label
                      key={option}
                      className={`${styles.radioOption} ${
                        rowCompOffset === parseInt(option)
                          ? styles.selected
                          : ''
                      }`}
                    >
                      <input
                        type="radio"
                        value={option}
                        checked={rowCompOffset === parseInt(option)}
                        onChange={(e) =>
                          setValue('rowCompOffset', parseInt(e.target.value))
                        }
                        className={styles.hiddenRadio}
                      />
                      <Content id={contentId} />
                    </label>
                  );
                }
              )}
            </Stack>
            <div className={styles.addOverrideButton}>
              <Button
                variant="text"
                onClick={addRowCompOverrideDialog.launchDialog}
              >
                + <Content id={ContentId.Override} />s
              </Button>
            </div>
            <AddRowCompOverrideDialog
              {...addRowCompOverrideDialog.dialogProps}
              onOkay={addRowCompOverrideDialog.closeDialog}
              onCancel={addRowCompOverrideDialog.closeDialog}
            />
          </Stack>
        </DetailGroup>
      </SectionContent>
    </DetailSection>
  );
};
