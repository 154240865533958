import * as RadixScrollArea from '@radix-ui/react-scroll-area';
import {
  ComponentPropsWithoutRef,
  createContext,
  forwardRef,
  RefObject,
  useContext,
  useEffect,
  useRef,
} from 'react';
import { vars } from 'src/core/themes';
import { styledComponent } from 'src/core/utils';
import {
  BackIcon,
  ExpandIcon,
  FoldIcon,
  ForwardIcon,
  IconsFill,
} from 'src/svgs/Viagogo';

import * as styles from './ScrollArea.css';
import { useAnimateScroll } from './useAnimateScroll';
import { useScrollEdges } from './useScrollEdges';

const ScrollAreaContext = createContext<{
  viewportRef: RefObject<HTMLDivElement>;
}>({
  viewportRef: {
    current: null,
  },
});

const Root = styledComponent(RadixScrollArea.Root, styles.root);

const Viewport = styledComponent(RadixScrollArea.Viewport, styles.viewport);

const Scrollbar = styledComponent(RadixScrollArea.Scrollbar, styles.scrollbar);

const Thumb = styledComponent(RadixScrollArea.Thumb, styles.thumb);

const Corner = RadixScrollArea.Corner;

/**
 * Use for a scrolling area that overlays the scrollbar on top of the content instead of offsetting
 * the content to the side.
 */
export const ScrollArea = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof Root> & {
    /**
     * Whether to show arrows at the edge of the scroll container that can be hovered to scroll.
     */
    showScrollEdges?: boolean;
  }
>(function ScrollArea({ children, showScrollEdges = false, ...props }, ref) {
  const viewportRef = useRef<HTMLDivElement>(null);
  return (
    <ScrollAreaContext.Provider
      value={{
        viewportRef,
      }}
    >
      <Root ref={ref} {...props}>
        <Viewport ref={viewportRef}>{children}</Viewport>
        {showScrollEdges && <ScrollEdges />}
        <Scrollbar orientation="vertical">
          <Thumb />
        </Scrollbar>
        <Scrollbar orientation="horizontal">
          <Thumb />
        </Scrollbar>
        <Corner />
      </Root>
    </ScrollAreaContext.Provider>
  );
});

function ScrollEdges() {
  const { viewportRef } = useContext(ScrollAreaContext);
  const { scrollDirection, startScroll, endScroll } =
    useAnimateScroll(viewportRef);
  const edges = useScrollEdges(viewportRef);

  useEffect(() => {
    if (
      (scrollDirection.current === 'up' && edges.atTop) ||
      (scrollDirection.current === 'down' && edges.atBottom) ||
      (scrollDirection.current === 'left' && edges.atLeft) ||
      (scrollDirection.current === 'right' && edges.atRight)
    ) {
      endScroll();
    }
  }, [edges, scrollDirection, endScroll]);

  return (
    <>
      {!edges.atTop && (
        <div
          className={styles.arrowContainer['up']}
          onMouseEnter={() => startScroll('up')}
          onMouseLeave={() => endScroll()}
        >
          <FoldIcon size={vars.iconSize.s} fill={IconsFill.textBrand} />
        </div>
      )}
      {!edges.atBottom && (
        <div
          className={styles.arrowContainer['down']}
          onMouseEnter={() => startScroll('down')}
          onMouseLeave={() => endScroll()}
        >
          <ExpandIcon size={vars.iconSize.s} fill={IconsFill.textBrand} />
        </div>
      )}
      {!edges.atLeft && (
        <div
          className={styles.arrowContainer['left']}
          onMouseEnter={() => startScroll('left')}
          onMouseLeave={() => endScroll()}
        >
          <BackIcon size={vars.iconSize.s} fill={IconsFill.textBrand} />
        </div>
      )}
      {!edges.atRight && (
        <div
          className={styles.arrowContainer['right']}
          onMouseEnter={() => startScroll('right')}
          onMouseLeave={() => endScroll()}
        >
          <ForwardIcon size={vars.iconSize.s} fill={IconsFill.textBrand} />
        </div>
      )}
    </>
  );
}
