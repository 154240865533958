import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function CalendarOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M8.364 2.727v5.455H6.545V6.364H3.818v3.181h16.364V6.364h-1.478l.625-1.819H22v17.273H2V4.545h4.545V2.727h1.819zm11.818 8.637H3.818V20h16.364v-8.636zm-2.727-8.637v5.455h-1.819V6.364H9.643l.625-1.819h5.368V2.727h1.819z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
