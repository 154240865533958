import { useCallback, useContext } from 'react';
import { ReloadButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserCanSetPrice } from 'src/hooks/useUserHasListingPermissions';
import { ModalFooter } from 'src/modals/Modal/Modal.styled';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getListingActionButtons,
  getListingDetailsModalConfigWithDeepLink,
} from 'src/utils/inventoryUtils';
import { ListingActionType, ListingDetails } from 'src/WebApiController';

import { LaunchDeleteSeatSaver } from './actions/LaunchDeleteSeatSaver';
import { LaunchViewComps } from './actions/LaunchViewComps';

export const ListingDetailsFooter = ({
  onSubmit,
  disabled,
  setIsLoading,
}: {
  onSubmit?: () => void;
  disabled?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
}) => {
  const { closeModal } = useContext(ModalContext);

  const {
    event,
    posEntity: listing,
    isLoading,
    errorInfo,
    setActivePosEntity,
  } = useActivePosEntityContext<ListingDetails>();
  const cancelTo = getListingDetailsModalConfigWithDeepLink(listing?.id);
  const isMobile = useMatchMedia('mobile');

  const onReload = useCallback(() => {
    if (listing) {
      setActivePosEntity(listing.id, listing.idOnMkp, true);
    }
  }, [listing, setActivePosEntity]);

  const canSetPrice = useUserCanSetPrice(listing, false);

  if (errorInfo) {
    return <OkButton onClick={closeModal} />;
  }

  if (!listing) {
    return null;
  }

  const mainActions: { key: string; action: JSX.Element | null }[] = [
    {
      key: 'Refresh',
      action: (
        <ReloadButton
          disabled={disabled || isLoading}
          variant={'outline'}
          onClick={onReload}
        />
      ),
    },
  ];

  const uiActions: { key: string; action: JSX.Element | null }[] = [
    ...(canSetPrice
      ? [
          {
            key: 'ViewComps',
            action: (
              <LaunchViewComps
                listingId={listing.id}
                marketplaceListingId={listing.idOnMkp}
                cancelTo={cancelTo}
                disabled={disabled || isLoading}
              />
            ),
          },
        ]
      : []),
  ];

  const actions: { key: string; action: JSX.Element | null }[] = [
    ...uiActions,
    ...listing.actions
      .filter((a) => a !== ListingActionType.FullySoldNoAction)
      .map((a, i) => ({
        key: a,
        action:
          getListingActionButtons(
            false /* for table */,
            listing,
            [a],
            event,
            a === ListingActionType.Broadcast ||
              a === ListingActionType.Unbroadcast
              ? 'regular'
              : a === ListingActionType.DownloadUserDocuments
              ? 'outline'
              : 'text',
            false,
            cancelTo,
            disabled || isLoading
          )?.[0] ?? null,
      })),
  ];

  const mainAction = actions.find((a) =>
    [
      ListingActionType.Broadcast,
      ListingActionType.Unbroadcast,
      ListingActionType.DownloadUserDocuments,
    ].includes(a.key as ListingActionType)
  );
  if (mainAction) {
    mainActions.push(mainAction);
  }
  if (onSubmit) {
    mainActions.push({
      key: 'Submit',
      action: (
        <OkButton
          onClick={onSubmit}
          textContentId={ContentId.Save}
          disabled={disabled || isLoading}
        />
      ),
    });
  }

  const subActions = actions.filter(
    (a) =>
      ![
        ListingActionType.Broadcast,
        ListingActionType.Unbroadcast,
        ListingActionType.DownloadUserDocuments,
      ].includes(a.key as ListingActionType)
  );

  return listing ? (
    <ModalFooter>
      <Stack
        gap="l"
        justifyContent="spaceBetween"
        alignItems="center"
        width="full"
      >
        <Stack>
          {listing.isSeatSaver && (
            <LaunchDeleteSeatSaver
              disabled={disabled || isLoading}
              setIsLoading={setIsLoading}
              listingId={listing.id}
            />
          )}
        </Stack>
        <Stack gap="m" alignItems="center">
          {listing?.isDeleted ? (
            <OkButton onClick={closeModal} content={ContentId.Close} />
          ) : (
            <>
              {mainActions.map((a) => (
                <div key={a.key}>{a.action}</div>
              ))}
              {subActions.length > 0 ? (
                <PosDropdown
                  trigger={
                    <MoreIcon
                      disabled={disabled || isLoading}
                      withHoverEffect
                      fill={IconsFill.textBrand}
                      align="middle"
                    />
                  }
                  triggerProps={{ disabled: disabled || isLoading }}
                  align="end"
                >
                  {subActions
                    .sort((a, b) => a.key.localeCompare(b.key))
                    .map((a) => (
                      <PosDropdownItem
                        disabled={disabled || isLoading}
                        key={a.key}
                      >
                        {a.action}
                      </PosDropdownItem>
                    ))}
                </PosDropdown>
              ) : null}
            </>
          )}
        </Stack>
      </Stack>
    </ModalFooter>
  ) : null;
};
