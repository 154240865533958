import { useFormContext } from 'react-hook-form';
import { UploadTicketUrlsForm } from 'src/components/UploadArtifacts/UploadTicketUrls';
import { UploadTicketUrlsBody } from 'src/components/UploadArtifacts/UploadTicketUrls/UploadTicketUrlsBody';
import { Stack } from 'src/core/ui';
import { PurchaseWizardFooter } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import {
  ActionOutboxEntityType,
  PosClientConfig,
  PosUiActionResult,
  Ticket,
  TicketGroupInput,
  TicketUrl,
} from 'src/WebApiController';

import {
  EntityWithRealTickets,
  toSeating,
} from '../../../../utils/ticketUtils';
import {
  ticketGroupInputFooter,
  ticketGroupPredeliveryBody,
} from '../PurchaseTicketFlow.css';

export const UploadTicketUrlsSection = ({
  ticketGroupIndex,
  onCancel,
}: {
  ticketGroupIndex: number;
  onCancel: () => void;
}) => {
  const { setValue, watch } = useFormContext<PurchaseTicketsInput>();

  const ticketGroupInput = watch(`ticketGroups.${ticketGroupIndex}`);

  const onSubmit = (
    _: PosClientConfig,
    entityId: number,
    ticketUrls: TicketUrl[]
  ) => {
    const newTickets = posChangedField(
      ticketGroupInput.tickets!.value!.map((t) => {
        const tb = ticketUrls.find((b) => b.ticketId === t!.id);
        t!.ticketUrl = tb?.url ?? null;
        return { ...t } as Ticket;
      })
    );

    setValue(`ticketGroups.${ticketGroupIndex}.tickets`, newTickets);

    return Promise.resolve({
      status: 200,
      entityId,
      entityType: ActionOutboxEntityType.Purchase,
    } as PosUiActionResult);
  };

  const toEntityWithTicket = (
    ticketGroup: TicketGroupInput
  ): Pick<
    EntityWithRealTickets,
    'tickets' | 'id' | 'viagVirtualId' | 'seating' | 'ticketCnt'
  > => {
    return {
      id: ticketGroup.ticketGroupId,
      viagVirtualId: ticketGroup.viagogoVirtualId,
      seating: toSeating(ticketGroup),
      ticketCnt: ticketGroup.numberOfTickets?.value ?? 0,
      tickets: ticketGroup.tickets?.value ?? [],
    };
  };

  const ticketGroup = toEntityWithTicket(ticketGroupInput);
  return (
    <UploadTicketUrlsForm
      entityWithTickets={toEntityWithTicket(ticketGroupInput)}
      onComplete={onCancel}
      onUpload={onSubmit}
      renderContent={(onUploadClick: () => void, disabled?: boolean) => (
        <Stack direction="column" height="full" justifyContent="spaceBetween">
          <UploadTicketUrlsBody
            className={ticketGroupPredeliveryBody}
            isReadOnly={disabled}
            ticketCnt={ticketGroup.ticketCnt}
            seating={ticketGroup.seating}
          />
          <PurchaseWizardFooter
            className={ticketGroupInputFooter}
            disabled={disabled}
            onPrevious={onCancel}
            onPreviousLabel={ContentId.Cancel}
            onNext={onUploadClick}
            onNextLabel={ContentId.Save}
          />
        </Stack>
      )}
    />
  );
};
