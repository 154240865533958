import { PosDraggableIndicatorHandles } from 'src/core/POS/PosDragNDrop/PosDraggableIndicator';
import { vars } from 'src/core/themes';
import HorizontalDraghandles from 'src/images/Horizontal.png';
import VerticalDraghandles from 'src/images/Vertical.png';
import styled from 'styled-components/macro';

export const DroppableContainer = styled.div<{
  isOver: boolean;
  hideBorder?: boolean;
}>`
  background-color: ${(props) =>
    props.isOver
      ? props.theme.colors.backgroundInteraction
      : props.theme.colors.backgroundPrimary};

  ${(props) =>
    !props.hideBorder
      ? `border: 1px solid ${
          props.isOver
            ? props.theme.colors.borderActive
            : props.theme.colors.borderPrimary
        }`
      : 'border: none'};

  height: 100%;
`;

export const PosCustomDragLayerContainer = styled.div.attrs(
  (props: { currentOffsetX: number; currentOffsetY: number }) => ({
    style: {
      transform: `translate(${props.currentOffsetX}px, ${props.currentOffsetY}px)`,
    },
  })
)<{ currentOffsetX: number; currentOffsetY: number }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none; // This is the key to be able to grab anywhere inside the draggable area to drag

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
`;

export const DragHandles = styled.div<
  PosDraggableIndicatorHandles & { removeHoverEffect?: boolean }
>`
  width: 100%;
  height: 100%;
  padding: ${vars.spacing.sm} ${vars.spacing.xl} ${vars.spacing.xl};
  background-image: ${({
    rightHandle,
    bottomHandle,
    leftHandle,
    topHandle,
  }) => `
  ${topHandle ? `url(${HorizontalDraghandles})` : 'none'}, 
  ${rightHandle ? `url(${VerticalDraghandles})` : 'none'},
  ${bottomHandle ? `url(${HorizontalDraghandles})` : 'none'},
  ${leftHandle ? `url(${VerticalDraghandles})` : 'none'}
  `};
  background-size:
    20px 10px,
    10px 20px,
    20px 10px,
    10px 20px;

  background-position:
    50% ${vars.spacing.sm},
    calc(100% - ${vars.spacing.sm}) 50%,
    50% calc(100% - ${vars.spacing.sm}),
    ${vars.spacing.sm} 50%;
  background-repeat: no-repeat;
  &:hover {
    background-color: ${(props) => {
      return props.removeHoverEffect
        ? 'unset'
        : `${props.theme.colors.backgroundSecondary}`;
    }};
  }
`;
