import { useCallback, useContext } from 'react';
import { UploadButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { ButtonProps } from 'src/core/ui';
import {
  UploadTicketUrlProps,
  UploadTicketUrls,
} from 'src/modals/UploadTicketUrls';
import { ContentId } from 'src/utils/constants/contentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { ListingActionType, SaleActionType } from 'src/WebApiController';

export const LaunchUploadTicketUrls = ({
  entityId,
  idOnMkp,
  variant,
  iconOnlyMode,
  dropdownItemMode,
  disabled,
  action,
  ...modalProps
}: UploadTicketUrlProps & {
  entityId: number;
  idOnMkp?: string | null;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  dropdownItemMode?: boolean;
  disabled?: boolean;
  action:
    | ListingActionType.PredeliverTicketUrls
    | ListingActionType.RedeliverTicketUrls
    | SaleActionType.Fulfill
    | SaleActionType.Refulfill;
}) => {
  const { setModal } = useContext(ModalContext);
  const { setActivePosEntity } =
    useActivePosEntityContext<EntityWithRealTickets>();

  const uploadTicketUrls = useCallback(() => {
    setModal({
      children: <UploadTicketUrls {...modalProps} />,
      clickOutsideToClose: true,
      size: 'slide-in',
    });
  }, [setModal, modalProps]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setActivePosEntity(entityId, idOnMkp);
      uploadTicketUrls();
    },
    [setActivePosEntity, entityId, idOnMkp, uploadTicketUrls]
  );

  if (dropdownItemMode) {
    return (
      <PosDropdownItem disabled={disabled} onClick={onLaunchDialog}>
        <Content
          id={
            action === ListingActionType.RedeliverTicketUrls ||
            action === SaleActionType.Refulfill
              ? ContentId.ReuploadTicketUrls
              : ContentId.UploadTicketUrls
          }
        />
      </PosDropdownItem>
    );
  }

  return (
    <UploadButton
      disabled={disabled}
      variant={variant}
      iconOnlyMode={iconOnlyMode}
      onClick={onLaunchDialog}
      textContentId={
        action === ListingActionType.RedeliverTicketUrls ||
        action === SaleActionType.Refulfill
          ? ContentId.ReuploadTicketUrls
          : ContentId.UploadTicketUrls
      }
    />
  );
};
