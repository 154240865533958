import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import { useAlertWithSuppressionDialog } from './useAlertWithSuppressionDialog';

export function useManualPriceDisableAutoPricingDialog(onOkay: () => void) {
  const { value, ...rest } = useAlertWithSuppressionDialog(
    'manualPriceDisableAutoPricingTimestamp',
    onOkay,
    undefined
  );
  return {
    ...rest,
    lastTimeStamp: value?.manualPriceDisableAutoPricingTimestamp,
    bodyText: <Content id={ContentId.ManualPriceDisableAutoPricingWarning} />,
  };
}
