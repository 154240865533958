import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { Content, useContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { shared } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import {
  PosAccordionContent,
  PosAccordionItem,
  PosAccordionRoot,
  PosAccordionTrigger,
} from 'src/core/ui/Accordion';
import {
  ReportConfigV2,
  useReportConfigsV2,
} from 'src/hooks/useReportConfigsV2';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { TableColumnsDisplayV2 } from 'src/modals/ColumnSettings/TableColumnsDisplayV2';
import { FieldWrapper } from 'src/modals/common/Purchase';
import { LISTING_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/ListingTable/configs/ListingReportTableColumnsConfig';
import { SALE_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleReportTableColumnsConfig';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING } from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import { ListingReportTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { filterCustomColumnsForListingReport } from 'src/utils/columns/inventory/inventoryReportCustomColumnUtils';
import { SalesReportTableColumnId } from 'src/utils/columns/sales/salesColumnUtils.types';
import { filterCustomColumnsForSale } from 'src/utils/columns/sales/salesCustomColumnUtils';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES } from 'src/utils/columns/sales/salesReportColumnUtils.constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  LISTING_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID,
  REPORT_IS_SORT_ASCENDING_TO_CID,
  SALE_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID,
} from 'src/utils/constants/contentIdMaps';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { ActionOutboxEntityType, UserSetting } from 'src/WebApiController';

import { ShareReportBody } from '../ShareReportDialog';
import { GroupBySelectorV2 } from './GroupBySelector/GroupBySelectorV2';
import { InventoryMetricsSelectionInput } from './MetricsSelectionInputModal/InventoryMetricsSelectionInputModalV2';
import { SalesMetricsSelectionInput } from './MetricsSelectionInputModal/SalesMetricsSelectionInputModalV2';
import * as styles from './ReportBuilderDialog.css';
import { ReportBuilderFilterInputV2 } from './ReportBuilderFilterInputV2';
import { useGetReportTableColumnIdMapping } from './useGetReportTableColumnIdMapping';
import { WidgetsSelectionInput } from './WidgetsSelectionInput';

export type ReportBuilderDialogProps = {
  report: ReportConfigV2;
  onSave: (data: ReportConfigV2) => void;
} & ComponentProps<typeof RSModal>;

const AdvancedSettingsId = 'advanced-settings';

type AdvancedSettingsAccordion = typeof AdvancedSettingsId | undefined;

export function ReportBuilderDialogBodyV2<S extends ReportTypesV2>({
  onSave,
  onClosed,
  register,
  setError,
  setValue,
  handleSubmit,
  formState,
  watch,
  reportType,
  ...rest
}: Omit<ReportBuilderDialogProps, 'report'> &
  Omit<
    ComponentProps<typeof FormProvider<ReportConfigV2, unknown>>,
    'children' | 'trigger' | 'reset' | 'unregister'
  > & {
    reportType: ReportTypesV2;
  }) {
  const { reportConfigs: existingReports } = useReportConfigsV2<S>({
    reportType,
  });

  const {
    listingReportColumnIdToGroupingMappingQuery: {
      data: listingReportColumnIdToGrouping,
    },
    listingReportColumnIdToAggregateMappingQuery: {
      data: listingReportColumnIdToAggregate,
    },
    saleReportColumnIdToGroupingMappingQuery: {
      data: saleReportColumnIdToGrouping,
    },
    saleReportColumnIdToAggregateMappingQuery: {
      data: saleReportColumnIdToAggregate,
    },
  } = useGetReportTableColumnIdMapping();

  const requiredMsg = useContent(ContentId.Required);
  const duplicateNameMsg = useContent(ContentId.DuplicatedReportName);

  const nameError = formState.errors.reportName?.message;
  const widgetsError = formState.errors.widgets?.message;
  const filtersError = formState.errors.request?.filters?.message;
  const metricsError = formState.errors.request?.aggregations?.message;
  const groupByError = formState.errors.request?.groupings?.message;

  const metricsField = 'request.aggregations';
  const groupbyField = 'request.groupings';
  const sortByField = 'request.orderBy';
  const isSortAscendingField = 'request.orderByAsc';

  const reportId = watch('reportId');
  const reportName = watch('reportName');
  const groupBy = watch(groupbyField);
  const metrics = watch(metricsField);
  const sortBy = watch(sortByField);
  const isSortAscending = watch(isSortAscendingField);
  const roleIdsToShare = watch('roleIdsToShare');
  const sellerUserIdsToShare = watch('sellerUserIdsToShare');

  const [advancedSettingsExpandedItem, setAdvancedSettingsExpandedItem] =
    useState<AdvancedSettingsAccordion>(undefined);

  const metricNames = useMemo(
    () => metrics?.map((m) => m.columnName) ?? [],
    [metrics]
  );

  const onSubmit = useCallback(() => {
    let hasErrors = false;
    if (!reportName) {
      setError('reportName', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (groupBy == null || groupBy.length === 0) {
      setError(groupbyField, { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (metrics == null || metrics.length === 0) {
      setError(metricsField, { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (
      existingReports
        ?.filter((r) => r.reportId !== reportId)
        .some(
          (r) =>
            r.reportName.toLocaleUpperCase() === reportName.toLocaleUpperCase()
        )
    ) {
      setError(
        'reportName',
        { message: duplicateNameMsg },
        { shouldFocus: true }
      );
      hasErrors = true;
    }

    if (!hasErrors) {
      handleSubmit(onSave)();
    }
  }, [
    reportName,
    groupBy,
    metrics,
    existingReports,
    setError,
    requiredMsg,
    reportId,
    duplicateNameMsg,
    handleSubmit,
    onSave,
  ]);

  const { tagsMetadataNumeric } = useTagsForEntityType(
    reportType === ReportTypesV2.InventoryV2
      ? ActionOutboxEntityType.Listing
      : ActionOutboxEntityType.Sale,
    true
  );

  const { tagsMetadata: tagsMetadataEvent } = useTagsForEntityType(
    ActionOutboxEntityType.SellerEvent,
    true
  );

  const { tagsMetadata: tagsMetadataPurchase } = useTagsForEntityType(
    ActionOutboxEntityType.Purchase,
    true
  );

  const reportingTags = useMemo(() => {
    const groupByColumNames = groupBy?.map((g) => g.columnName) ?? [];
    if (groupByColumNames.includes(ListingReportTableColumnId.Event)) {
      return [...(tagsMetadataNumeric ?? []), ...(tagsMetadataEvent ?? [])];
    }
    if (groupByColumNames.includes(ListingReportTableColumnId.PurchaseId)) {
      return [...(tagsMetadataNumeric ?? []), ...(tagsMetadataPurchase ?? [])];
    }

    return tagsMetadataNumeric;
  }, [groupBy, tagsMetadataEvent, tagsMetadataNumeric, tagsMetadataPurchase]);

  const { value: customListingColumns = [] } = useServerUserSetting<
    CustomListingColumn[]
  >({
    id: UserSetting.InventoryCustomColumns,
  });
  const { value: customSaleColumns = [] } = useServerUserSetting<
    CustomSalesColumn[]
  >({
    id: UserSetting.SaleCustomColumns,
  });

  const metricsFiltered = useMemo(() => {
    // Filter out reporting tags that are later turned off reporting
    const customColumnsFiltered =
      reportType === ReportTypesV2.InventoryV2
        ? filterCustomColumnsForListingReport(
            customListingColumns,
            tagsMetadataNumeric
          )
        : filterCustomColumnsForSale(customSaleColumns, tagsMetadataNumeric);

    const presetColumnIds = Object.values(
      reportType === ReportTypesV2.InventoryV2
        ? LISTING_REPORT_TABLE_COLUMNS_CONFIG
        : SALE_REPORT_TABLE_COLUMNS_CONFIG
    ).map((i) => i.id);

    const activeTagNames = reportingTags?.map((t) => t.key) ?? [];
    const customColumnIds = customColumnsFiltered.map((c) => c.id);

    return metricNames.filter(
      (m) =>
        presetColumnIds.includes(m) ||
        activeTagNames.includes(m) ||
        customColumnIds.includes(m)
    );
  }, [
    reportType,
    customListingColumns,
    tagsMetadataNumeric,
    customSaleColumns,
    reportingTags,
    metricNames,
  ]);

  const primaryColumnId = useMemo(() => {
    const GROUP_BY_TO_PRIMARY_COLUMN_ID =
      reportType === ReportTypesV2.InventoryV2
        ? GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING
        : GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES;
    return GROUP_BY_TO_PRIMARY_COLUMN_ID[groupBy![0]?.columnName];
  }, [groupBy, reportType]);

  const reportColumnIdToGrouping =
    reportType === ReportTypesV2.InventoryV2
      ? listingReportColumnIdToGrouping
      : saleReportColumnIdToGrouping;
  const reportColumnIdToAggregate =
    reportType === ReportTypesV2.InventoryV2
      ? listingReportColumnIdToAggregate
      : saleReportColumnIdToAggregate;

  const groupByOptions = useMemo(() => {
    return Object.keys(reportColumnIdToGrouping ?? {}).reduce(
      (acc, key) => {
        const cid =
          reportType === ReportTypesV2.InventoryV2
            ? LISTING_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID[
                key as ListingReportTableColumnId
              ]
            : SALE_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID[
                key as SalesReportTableColumnId
              ];
        acc[key] = cid;
        return acc;
      },
      {} as Record<string, ContentId | string>
    );
  }, [reportColumnIdToGrouping, reportType]);

  const sortByOptions = useMemo(() => {
    const tableColumnIds = [
      ...Object.keys(reportColumnIdToAggregate ?? {}),
      ...Object.keys(reportColumnIdToGrouping ?? {}),
    ];
    return tableColumnIds.reduce(
      (acc, columnId) => {
        const cid =
          reportType === ReportTypesV2.InventoryV2
            ? LISTING_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID[
                columnId as ListingReportTableColumnId
              ]
            : SALE_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID[
                columnId as SalesReportTableColumnId
              ];
        acc[columnId] = cid;
        return acc;
      },
      {} as Record<string, ContentId | string>
    );
  }, [reportColumnIdToAggregate, reportColumnIdToGrouping, reportType]);

  const onSortByChange = useCallback(
    (newValue: string) => {
      setValue(sortByField, newValue);
    },
    [setValue]
  );

  const onIsSortAscendingChange = useCallback(
    (newValue: string) => {
      setValue(isSortAscendingField, newValue === 'true');
    },
    [setValue]
  );

  return (
    <>
      <GenericDialog
        {...rest}
        size="xl"
        header={
          <Content
            id={reportId == null ? ContentId.AddReport : ContentId.EditReport}
          />
        }
        footer={
          <>
            {onClosed && (
              <CancelButton
                onClick={onClosed}
                disabled={formState.isSubmitting}
              />
            )}
            <Button
              variant={'regular'}
              onClick={onSubmit}
              disabled={formState.isSubmitting}
            >
              <Content id={ContentId.Save} />
            </Button>
          </>
        }
        onCancel={onClosed}
      >
        <div className={styles.reportBuilderDialogContainer}>
          <FieldWrapper>
            <PosFormField
              errors={nameError}
              label={
                <span className={shared.typography.subtitle1}>
                  <Content id={ContentId.Name} />
                </span>
              }
            >
              <PosTextField
                rootProps={{
                  disabled: formState.isSubmitting,
                  state: getTextFieldState(nameError),
                }}
                disabled={formState.isSubmitting}
                {...register('reportName')}
              />
            </PosFormField>
          </FieldWrapper>

          <div className={styles.pivotGridContainer}>
            <Stack className={styles.pivotGridItem} direction="column">
              <PosFormField
                errors={filtersError}
                label={
                  <span className={shared.typography.subtitle1}>
                    <Content id={ContentId.Filters} />
                  </span>
                }
              />
              <div className={styles.pivotGridItemInner}>
                <ReportBuilderFilterInputV2 reportType={reportType} listView />
              </div>
            </Stack>
            <Stack className={styles.pivotGridItem} direction="column">
              <PosFormField
                errors={groupByError}
                label={
                  <span className={shared.typography.subtitle1}>
                    <Content id={ContentId.GroupBy} />
                  </span>
                }
              />
              <div className={styles.pivotGridItemInner}>
                <GroupBySelectorV2
                  options={groupByOptions}
                  reportType={reportType}
                />
              </div>
            </Stack>
            <Stack className={styles.pivotGridItem} direction="column">
              <PosFormField
                errors={metricsError}
                label={
                  <span className={shared.typography.subtitle1}>
                    <Content id={ContentId.Columns} />
                  </span>
                }
              />
              <div className={styles.pivotGridItemInner}>
                {reportType === ReportTypesV2.InventoryV2 ? (
                  <InventoryMetricsSelectionInput />
                ) : (
                  <SalesMetricsSelectionInput />
                )}
              </div>
            </Stack>
            <Stack className={styles.pivotGridItem} direction="column">
              <PosFormField
                label={
                  <span className={shared.typography.subtitle1}>
                    <Content id={ContentId.Value} />
                  </span>
                }
              />
              <div className={styles.pivotGridItemInner}>
                <div className={styles.metricsContainer}>
                  {metricsFiltered != null && metricsFiltered.length > 0 ? (
                    <>
                      {reportType === ReportTypesV2.InventoryV2 ? (
                        <TableColumnsDisplayV2<CustomListingColumn>
                          items={metricsFiltered ?? []}
                          sectionType={SectionType.ListingsReport}
                          tableType="inventory"
                        />
                      ) : (
                        <TableColumnsDisplayV2<CustomSalesColumn>
                          items={metricsFiltered ?? []}
                          sectionType={SectionType.SalesReport}
                          tableType="sales"
                        />
                      )}
                    </>
                  ) : (
                    <div className={styles.noneSelectedText}>
                      <Content id={ContentId.NoneSelected} />
                    </div>
                  )}
                </div>
              </div>
            </Stack>
          </div>

          <FieldWrapper>
            <PosFormField
              label={
                <span className={shared.typography.subtitle1}>
                  <Content id={ContentId.Sharing} />
                </span>
              }
            >
              <div className={styles.sharingContainer}>
                <Content id={ContentId.SharingReportDescription} />
                <ShareReportBody
                  roleIds={roleIdsToShare}
                  sellerUserIds={sellerUserIdsToShare}
                  onRoleIdsChange={(value) => setValue('roleIdsToShare', value)}
                  onSellerUserIdsChange={(value) =>
                    setValue('sellerUserIdsToShare', value)
                  }
                />
              </div>
            </PosFormField>
          </FieldWrapper>
          <FieldWrapper>
            <PosAccordionRoot
              type="single"
              value={advancedSettingsExpandedItem}
              collapsible
              onValueChange={(activeExpandedItem) => {
                setAdvancedSettingsExpandedItem(
                  activeExpandedItem as AdvancedSettingsAccordion
                );
              }}
            >
              <PosAccordionItem value={AdvancedSettingsId}>
                <PosAccordionTrigger>
                  <Content id={ContentId.AdvancedSettings} />
                </PosAccordionTrigger>
                <PosAccordionContent>
                  {reportType === ReportTypesV2.InventoryV2 && (
                    <FieldWrapper>
                      <PosFormField
                        errors={widgetsError}
                        label={
                          <span className={shared.typography.subtitle1}>
                            <Content id={ContentId.Widgets} />
                          </span>
                        }
                      >
                        <WidgetsSelectionInput
                          disabled={formState.isSubmitting}
                        />
                      </PosFormField>
                    </FieldWrapper>
                  )}
                  <FieldWrapper>
                    <PosFormField
                      label={
                        <span className={shared.typography.subtitle1}>
                          <Content id={ContentId.SortBy} />
                        </span>
                      }
                    >
                      <div className={styles.sortByContainer}>
                        <PosSelect
                          style={{ width: '100%' }}
                          displayText={sortByOptions[sortBy ?? primaryColumnId]}
                          value={sortBy ?? primaryColumnId}
                          defaultValue={primaryColumnId}
                          onChange={onSortByChange}
                          valueOptionsContent={sortByOptions}
                          placeholderText={ContentId.Columns}
                        />
                        <PosSelect
                          style={{ width: '100%' }}
                          displayText={
                            REPORT_IS_SORT_ASCENDING_TO_CID[
                              String(Boolean(isSortAscending))
                            ]
                          }
                          value={String(Boolean(isSortAscending))}
                          onChange={onIsSortAscendingChange}
                          valueOptionsContent={REPORT_IS_SORT_ASCENDING_TO_CID}
                        />
                      </div>
                    </PosFormField>
                  </FieldWrapper>
                </PosAccordionContent>
              </PosAccordionItem>
            </PosAccordionRoot>
          </FieldWrapper>
        </div>
      </GenericDialog>
    </>
  );
}
