import { useContent } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';

import { LaunchAddSeatSaver } from './components/AddSeatSaverAction';
import { LaunchGroupListings } from './components/GroupListingsAction/LaunchGroupListings';
import { LaunchMergeListings } from './components/MergeListingsAction/LaunchMergeListings';

export const InventoryGlobalActionDropdown = () => {
  const actionsMsg = useContent(ContentId.Actions);
  const hasUpdateListingInfoPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_UpdateListingInfo
  );

  return (
    <>
      <PosDropdown
        key="event-action-dropdown"
        trigger={
          <div
            title={actionsMsg}
            style={{ padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}` }}
          >
            <MoreIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              align="middle"
            />
          </div>
        }
        align="end"
      >
        <LaunchGroupListings events={undefined} />

        {hasUpdateListingInfoPermission && (
          <LaunchMergeListings event={undefined} />
        )}

        <LaunchAddSeatSaver />
      </PosDropdown>
    </>
  );
};
