import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { AccountAutoPricingSettingsInputs, SellerAccountClient } from 'src/WebApiController';

export const useAccountAutoPricingSettings = () => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const queryClient = useQueryClient();
  const getQueryKey = [
    'SellerAccountClient.getAccountAutoPricingSettings',
    activeAccountWebClientConfig.activeAccountId,
  ];

  const shouldQuery = activeAccountWebClientConfig.activeAccountId != null;
  const getAccountAutoPricingSettingsQuery = useQuery({
    queryKey: getQueryKey,
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      const pricingSettings = await new SellerAccountClient(
        activeAccountWebClientConfig
      ).getAccountAutoPricingSettings();

      return pricingSettings;
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    staleTime: 0,
    meta: {
      persist: false,

      onError: (error: ErrorTypes) => {
        showErrorDialog('SellerAccountClient.getAccountAutoPricingSettings', error, {
          trackErrorData: {
            activeAccountId: activeAccountWebClientConfig.activeAccountId,
          },
        });
      },
    },
  });

  const mergeAccountAutoPricingSettingsMutation = useMutation({
    mutationFn: async ({
      inputs,
    }: {
      inputs: AccountAutoPricingSettingsInputs,
    }) => {
      return await new SellerAccountClient(
        activeAccountWebClientConfig,
      ).mergeAccountAutoPricingSettings(inputs);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ inputs }) => {
      queryClient.cancelQueries({ queryKey: getQueryKey });
      const prevValue =
        queryClient.getQueryData<AccountAutoPricingSettingsInputs>(getQueryKey);

      queryClient.setQueryData(getQueryKey, inputs);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { inputs }, context) => {
      queryClient.setQueryData(getQueryKey, context?.prevValue);
      showErrorDialog('SellerAccountClient.mergeAccountAutoPricingSettings', err, {
        trackErrorData: { inputs },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey });
    },
  });

  const onMergeAccountAutoPricingSettings = useCallback(
    async (inputs?: AccountAutoPricingSettingsInputs) => {
      if (!inputs) {
        return;
      }

      await mergeAccountAutoPricingSettingsMutation.mutateAsync({
        inputs,
      });
      getAccountAutoPricingSettingsQuery.refetch();
    },
    [mergeAccountAutoPricingSettingsMutation]
  );

  return {
    accountAutoPricingSettings: getAccountAutoPricingSettingsQuery.data,
    onMergeAccountAutoPricingSettings,
    isLoading: getAccountAutoPricingSettingsQuery.isLoading,
    isPending:
      getAccountAutoPricingSettingsQuery.isFetching ||
      mergeAccountAutoPricingSettingsMutation.isPending,
    loaded: getAccountAutoPricingSettingsQuery.isFetched,
  };
};