import { ComponentProps, useMemo } from 'react';
import { getContent, useContentContext } from 'src/contexts/ContentContext';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { Stack } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { PillItemProps } from 'src/core/ui/PillList/PillItem';

import * as styles from './MultiSelectDropBoxWithPills.css';

export type MultiSelectDropBoxWithPillsProps = ComponentProps<
  typeof PosMultiSelect
> &
  Pick<ComponentProps<typeof Stack>, 'flexWrap' | 'direction'> & {
    showPills?: boolean;
  };

export const MultiSelectDropBoxWithPills = ({
  values,
  valueOptionsContent,
  valueOptionsIcon,
  disabled,
  onChange,
  align,
  fullWidth,
  direction: pillsDirection = 'row',
  flexWrap: pillsFlexWrap,
  showPills = true,
  ...rest
}: MultiSelectDropBoxWithPillsProps) => {
  const contentContext = useContentContext();
  const pills = useMemo(
    () =>
      values.map((v) => {
        const valueDisplay = valueOptionsContent[v];
        const valueContentDisplay = getContent(valueDisplay, contentContext);
        const valueIcon = valueOptionsIcon?.[v];

        return {
          value: v,
          display: valueContentDisplay || v,
          icon: valueIcon,
        } as PillItemProps;
      }),
    [values, valueOptionsContent, contentContext, valueOptionsIcon]
  );

  return (
    <div className={styles.multiSelectDropBoxWithPillsContainer}>
      <PosMultiSelect
        values={values}
        valueOptionsContent={valueOptionsContent}
        valueOptionsIcon={valueOptionsIcon}
        disabled={disabled}
        onChange={onChange}
        align={align ?? 'start'}
        fullWidth={fullWidth}
        {...rest}
      />
      {showPills && pills.length > 0 && (
        <div className={styles.selectionPillsContainer}>
          <PillList
            pills={pills}
            disabled={disabled}
            onPillDeleted={(value) =>
              onChange?.(values.filter((v) => v !== value))
            }
            direction={pillsDirection}
            flexWrap={pillsFlexWrap}
          />
        </div>
      )}
    </div>
  );
};
