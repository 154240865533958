import { Content } from 'src/contexts/ContentContext/Content';
import { useDealContext } from 'src/contexts/DealContext/DealContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { IconsFill, InfoOutlineIcon, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PurchaseOrderDealCommission,
  PurchaseOrderDealConfig,
} from 'src/WebApiController';

import * as styles from '../Deals.css';
import { ChangeCommissionInput } from './DealConfigHurdle';
import {
  DealConfigSplitCommissionPurchasedBy,
  DealConfigSplitCommissionVendorAccount,
} from './DealConfigHurdleSplitCommissions';

export const DealConfigHurdleSplit = ({
  index,
  config,
  vendorCommission,
  onChangeVendorCommission,
  onAddNewCommission,
  onDeleteCommission,
  onChangeCommission,
}: {
  index: number;
  config: PurchaseOrderDealConfig;
  vendorCommission: PurchaseOrderDealCommission;
  onChangeVendorCommission: (input: number) => void;
  onAddNewCommission?: () => void;
  onDeleteCommission: (removeIndex: number) => void;
  onChangeCommission: ({
    changedUserId,
    changedValue,
    commissionIndex,
  }: ChangeCommissionInput) => void;
}) => {
  const { activeUserIds } = useDealContext();
  const totalHurdlePercentage =
    config.vendorCommission.splitValue +
    config.purchaserCommissions.reduce(
      (acc, commission) => acc + commission.splitValue,
      0
    );

  return (
    <PosFormField
      label={
        <div className={styles.SectionTitle}>
          <Content id={ContentId.Split} />
        </div>
      }
      errors={undefined}
    >
      <Stack direction="column" width="full">
        <Stack>
          <Stack direction="column" gap="m" width="eighty">
            <DealConfigSplitCommissionVendorAccount
              key={index}
              vendorCommission={vendorCommission}
              onChangeCommission={onChangeVendorCommission}
            />
            <Stack direction="column" width="full" gap="m">
              {config.purchaserCommissions.map(
                (commission: PurchaseOrderDealCommission, index: number) => {
                  return (
                    <DealConfigSplitCommissionPurchasedBy
                      key={index}
                      index={index}
                      commission={commission}
                      onDeleteCommission={() => onDeleteCommission(index)}
                      onChangeCommission={onChangeCommission}
                    />
                  );
                }
              )}
            </Stack>
          </Stack>
          {onAddNewCommission &&
            activeUserIds.length > config.purchaserCommissions.length && (
              <Button
                variant={'link'}
                onClick={onAddNewCommission}
                style={{ alignSelf: 'end' }}
              >
                <PlusIcon
                  size={vars.iconSize.s}
                  fill={IconsFill.currentColor}
                />
                <Content id={ContentId.AddSplit} />
              </Button>
            )}
        </Stack>
        <Stack direction="column" width="eighty">
          <div className={styles.ThickDivider} />
          <Stack justifyContent="end" gap="m" style={{ textAlign: 'right' }}>
            <div
              className={
                totalHurdlePercentage !== 100
                  ? styles.DisabledTotalCommission
                  : styles.TotalCommission
              }
            >
              <Content id={ContentId.Total} />: {totalHurdlePercentage}/100%
              {totalHurdlePercentage !== 100 && (
                <Stack
                  className={styles.TotalCommissionInfo}
                  alignItems="center"
                  gap="s"
                >
                  <InfoOutlineIcon
                    size={vars.iconSize.s}
                    fill={IconsFill.currentColor}
                  />
                  <Content id={ContentId.TotalSplitInfo} />
                </Stack>
              )}
            </div>
          </Stack>
        </Stack>
      </Stack>
    </PosFormField>
  );
};
