import { clsx } from 'clsx';
import {
  SeatInfo,
  SeatInfoProps,
} from 'src/components/UploadArtifacts/UploadETicketsV2/views/Seats/SeatInfo/SeatInfo';
import { ClearButton } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/ClearButton/ClearButton';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { PageIcon } from 'src/svgs/PageIcon';

import * as styles from './MappedSeat.css';

interface MappedSeatProps {
  seatInfo: SeatInfoProps;
  fileName: string;
  onUnMapSeat: () => void;
  isSelected: boolean;
  onClick: () => void;
}

export const MappedSeat = ({
  seatInfo,
  fileName,
  onUnMapSeat,
  isSelected,
  onClick,
}: MappedSeatProps) => {
  const useMobileStyle = !useMatchMedia('largeDesktop');
  return (
    <div
      className={clsx(styles.seatWrapper, styles.mapperSeatWrapper, {
        [styles.isSelected]: isSelected,
      })}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
    >
      <SeatInfo {...seatInfo} size="big" />
      <Stack
        gap="m"
        alignItems={useMobileStyle ? 'start' : 'center'}
        className={styles.fileNameWrapper}
        direction={useMobileStyle ? 'column' : 'row'}
      >
        <Stack gap="m" flexShrink={false} alignItems="center">
          <PageIcon size={vars.iconSize.m} className={styles.pageIcon} />
          <span>{fileName}</span>
        </Stack>
        <ClearButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation();
            e.preventDefault();
            onUnMapSeat();
          }}
        />
      </Stack>
    </div>
  );
};
