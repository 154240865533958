import * as Sentry from '@sentry/react';
import { ObjectLiteral, useErrorTracker } from '@viagogo/telemetry-react';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ErrorDialog } from 'src/dialogs/ErrorDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { REACT_APP_NAME } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { ApiException, LoginClient } from 'src/WebApiController';
import {
  ClientLogClient,
  ClientLogRequest,
  LogLevel,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useContent } from '../ContentContext';

export type ErrorTypes =
  | ApiException
  | null
  | undefined
  | {
      status?: number | null;
      message?: string | null;
      response?: string | null;
    };

export type IErrorBoundaryContext = {
  trackError: (
    errorId: string,
    error: ErrorTypes,
    data?: ObjectLiteral
  ) => void;
  showErrorDialog: (
    errorId: string,
    error:
      | ApiException
      | null
      | undefined
      | {
          status?: number | null;
          message?: string | null;
          response?: string | null;
        },
    additionalOptions?: {
      onDismissError?: () => void;
      customerHeader?: React.ReactNode;
      trackErrorData?: ObjectLiteral;
    }
  ) => void;
  genericError: string;
};

export const ErrorBoundaryContext = React.createContext<IErrorBoundaryContext>({
  trackError: () => {},
  showErrorDialog: () => {},
  genericError: '',
});

export const useErrorBoundaryContext = () => useContext(ErrorBoundaryContext);

/**
 * Adds a modal to the page where the provider is added
 * Provides utils to set all or update part of the modal
 * The modal is assumed to be open when it contains content
 */
export const ErrorBoundaryContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [trackError] = useErrorTracker();

  const { activeAccountWebClientConfig } = useAppContext();

  const genericError = useContent(
    ContentId.Error_InternalServerError_GenericMessage
  );

  const { dialogProps, closeDialog, launchDialog } = useBasicDialog();

  const [message, setMessage] = useState<string | null | undefined>();
  const [statusCode, setStatusCode] = useState<number | null | undefined>();
  const [customerHeader, setCustomerHeader] = useState<React.ReactNode>();
  const [onDismissError, setOnDismissError] = useState<() => void>();

  const logClient = useMemo(
    () => new ClientLogClient(activeAccountWebClientConfig),
    [activeAccountWebClientConfig]
  );

  const trackErrorWrapper = useCallback(
    async (
      errorId: string,
      error:
        | ApiException
        | null
        | undefined
        | {
            status?: number | null;
            message?: string | null;
            response?: string | null;
          },
      data?: ObjectLiteral
    ) => {
      if (error?.status === 401) {
        // If we get an unauthorized, reload the page which will cause the login change to either
        // pull the updated login, or redirect to login page
        window.location.assign('/login');
      }

      const getLogLevel = (
        status: number | null | undefined,
        message: string
      ) => {
        // Uncomment this to break here to debug when we have errors
        // that we want to convert to a warning
        // debugger;
        if (status === 401) {
          return LogLevel.Warning;
        }

        if (
          message === 'Failed to fetch' ||
          message == 'NetworkError when attempting to fetch resource.'
        ) {
          return LogLevel.Warning;
        }

        return LogLevel.Error;
      };

      try {
        Sentry.captureException(error);
      } catch (e) {
        console.warn('Sentry log request failed', e);
      }

      const message = error?.response ?? error?.message ?? genericError;
      const statusStr = error?.status ? `[StatusCode]: ${error.status}` : '';

      data = { ...(data ?? {}), response: error?.response };

      const dataSerialized = JSON.stringify(data);

      console.error(`[${errorId}] - ${message}
[Details]:
${statusStr}
${dataSerialized}
`);

      trackError(errorId, message, {
        data: dataSerialized,
        status: error?.status,
        response: error?.response,
      });

      const logRequest: ClientLogRequest = {
        logLevel: getLogLevel(error?.status, message),
        applicationSource: REACT_APP_NAME,
        errorId: errorId,
        error: message,
        pageUrl: window.location.href,
        componentStack: null,
        additionalInfo: {
          responseStatus: error?.status,
          data: dataSerialized,
        },
      };

      tryInvokeApi(
        async () => {
          logClient.log(logRequest);
        },
        () => {
          console.warn('Failed to log error to server');
        }
      );

      if (error?.status === 401) {
        // Check to see if the login-context is still good
        new LoginClient({}).getLoginContext('/').then((newLoginContext) => {
          if (
            !newLoginContext?.isSuccess ||
            newLoginContext?.user?.activeAccount?.id !==
              activeAccountWebClientConfig.activeAccountId
          ) {
            // Getting latest login unsuccessful, redirect to login to force re-sign-in
            window.location.assign('/login');
          }
        });
      }
    },
    [
      genericError,
      trackError,
      logClient,
      activeAccountWebClientConfig.activeAccountId,
    ]
  );

  const showErrorDialog = useCallback(
    (
      errorId: string,
      error:
        | ApiException
        | null
        | undefined
        | {
            status?: number | null;
            message?: string | null;
            response?: string | null;
          },
      additionalOptions?: {
        onDismissError?: () => void;
        customerHeader?: React.ReactNode;
        trackErrorData?: ObjectLiteral;
      }
    ) => {
      const { onDismissError, customerHeader, trackErrorData } =
        additionalOptions || {};

      const message = error?.response ?? error?.message ?? genericError;

      setMessage(message);
      setStatusCode(error?.status);
      setCustomerHeader(customerHeader);
      setOnDismissError(onDismissError);

      trackErrorWrapper(errorId ?? 'Generic Error', error, trackErrorData);

      launchDialog();
    },
    [genericError, launchDialog, trackErrorWrapper]
  );

  const onErrorOk = useCallback(() => {
    closeDialog();

    onDismissError?.();

    // Clear all the error dialog props after dismiss
    setMessage('');
    setStatusCode(undefined);
    setCustomerHeader(undefined);
    setOnDismissError(undefined);
  }, [closeDialog, onDismissError]);

  return (
    <ErrorBoundaryContext.Provider
      value={{
        trackError: trackErrorWrapper,
        showErrorDialog,
        genericError,
      }}
    >
      {children}
      <ErrorDialog
        {...dialogProps}
        headerText={customerHeader}
        messageText={message}
        statusCode={statusCode}
        onOkay={onErrorOk}
      />
    </ErrorBoundaryContext.Provider>
  );
};
