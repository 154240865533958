import { Content } from "src/contexts/ContentContext";
import { PosTextField } from "src/core/POS/PosTextField";
import { Stack } from "src/core/ui";
import { ContentId } from "src/utils/constants/contentId";

import * as styles from "../AutoPricing.css";
import { useAutoPricingSettingsFormContext } from "../useAutoPricingSettingsFormContext";

export const AutoPricingCircuitBreakerMaxDiscountVelocityInput = ({
  disabled,
}: {
  disabled: boolean,
}) => {

  const {
    setValue,
    watch,
  } = useAutoPricingSettingsFormContext();

  const {
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
  } = watch();

  return (
    <Stack gap="m" alignItems="center">
      <Content id={ContentId.MaxDiscountInputPrompt1} />
      <PosTextField
        disabled={disabled}
        type="number"
        min={0}
        max={100}
        postfixDisplay="%"
        rootProps={{
          className: styles.inputInline,
        }}
        className={styles.inputInline}
        value={circuitBreakerMaxDiscountVelocityPercent
          ? circuitBreakerMaxDiscountVelocityPercent * 100
          : ''
        }
        onChange={(e) => {
          const v = parseFloat(e.target.value);
          if (!isNaN(v)) {
            setValue(
              'circuitBreakerMaxDiscountVelocityPercent',
              v / 100
            );
          }
        }}
      />
      <Content id={ContentId.MaxDiscountInputPrompt2} />
      <PosTextField
        disabled={disabled}
        type="number"
        min={1}
        max={24}
        rootProps={{
          className: styles.inputInline,
        }}
        className={styles.inputInline}
        value={circuitBreakerMaxDiscountVelocityTicksInHours ?? ''}
        onChange={(e) => {
          const v = parseFloat(e.target.value);
          if (!isNaN(v)) {
            setValue('circuitBreakerMaxDiscountVelocityTicksInHours', v);
          }
        }}
      />
      <Content id={ContentId.MaxDiscountInputPrompt3} />
    </Stack>
  );
};