import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

interface PurchaseTicketFlowTitleProps {
  showSearch: boolean;
  isSingleInputModeForMultiEvent: boolean;
  currentGroupIndex: number;
  currentGroupTicketsCount: number;
}

export const PurchaseTicketFlowTitle = ({
  currentGroupTicketsCount,
  currentGroupIndex,
  isSingleInputModeForMultiEvent,
  showSearch,
}: PurchaseTicketFlowTitleProps) => {
  if (showSearch) {
    return <Content id={ContentId.WhatTicketsDidYouBuy} />;
  }

  if (!showSearch && !isSingleInputModeForMultiEvent) {
    return (
      <>
        <Content id={ContentId.Event} /> {`${currentGroupIndex + 1}`}
      </>
    );
  }

  if (!showSearch && isSingleInputModeForMultiEvent) {
    const eventStrId =
      currentGroupTicketsCount == 1 ? ContentId.Event : ContentId.Events;

    return (
      <>
        {currentGroupTicketsCount} <Content id={eventStrId} />
      </>
    );
  }

  return '';
};
