import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ActivityLogClient } from 'src/WebApiController';

const GET_RECENT_ACTIVITY_LOG_KEY = `${ActivityLogClient.name}.${ActivityLogClient.prototype.getRecentActivityLog.name}`;
const ACTIVITY_LOG_POLLING_INTERVAL = 10000;

/**
 * Fetches activity log items on polling interval.
 * @param count Number of items to fetch
 * @returns Return from `useQuery`.
 */
export function useLiveActivityLog(count: number) {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const activityLogResult = useQuery({
    queryKey: [
      GET_RECENT_ACTIVITY_LOG_KEY,
      activeAccountWebClientConfig.activeAccountId,
      count,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      const results = await new ActivityLogClient(
        activeAccountWebClientConfig
      ).getRecentActivityLog(count, true);
      return results
        .sort((a, b) => {
          return (
            new Date(b.actionDate).getTime() - new Date(a.actionDate).getTime()
          );
        })
        .slice(0, count);
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    retry: 3,
    refetchInterval: (query) =>
      query.state.status === 'error' ? false : ACTIVITY_LOG_POLLING_INTERVAL,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(GET_RECENT_ACTIVITY_LOG_KEY, error, { count });
      },
    },
  });
  return activityLogResult;
}
