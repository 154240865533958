import { uniq } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { mapReportMetrics } from 'src/components/ReportsInventoryPage/ReportsInventoryPage.utils';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useReportMetricsContext } from 'src/contexts/ReportMetricsContext';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useColumnDisplayName } from 'src/modals/InventoryTableColumns/useColumnDisplayName';
import {
  ListingReportTableColumnDefContextProvider,
  useListingReportTableColumnDef,
} from 'src/tables/ListingTable/ListingReportTableColumnDefContext';
import { isMetricConfigurableByGroupBy } from 'src/utils/columns/inventory/inventoryColumnUtils';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID } from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import { ContentId } from 'src/utils/constants/contentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { downloadFileFromBlob } from 'src/utils/fileUtils';
import {
  isReportFilterOverriden,
  listingMetricsToReportMetricRequest,
  ListingReportMetricsWithGroupBy,
} from 'src/utils/reportsUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  EntityWithTicketsQuery,
  ListingQuery,
  ListingReportMetricColumn,
  ListingReportMetrics,
  ReportClient,
} from 'src/WebApiController';

import { ReportsInventoryContextProvider } from '../../../navigations/Routes/ReportsInventory/ReportsInventoryContextProvider';
import { ReportExportOptions } from './ReportsExport.types';
import {
  exportReport,
  getReportExportFileBasename,
  mapReportMetricToTableString,
  mapReportMetricToUserIds,
  sortReportMetrics,
} from './ReportsExport.utils';

export function ReportsInventoryExport({
  reportExportOptions,
  onReportExported,
}: {
  reportExportOptions: ReportExportOptions | null;
  onReportExported: () => void;
}) {
  // Loading report metrics depend on the context provider (need to wait for the context provider to load the report metrics)

  // If reportToExport is null,
  // no reports need to be exported, so not rendering the <ReportsInventoryExportInner component

  // If reportToExport is not null, then render the <ReportsInventoryExportInner component.
  // It will load the report metrics and export the report to a PDF file
  // After the report is exported, the onReportExported callback will be called to reset the reportToExport state to null
  if (reportExportOptions == null) return null;

  return (
    <ReportsInventoryContextProvider
      reportConfig={reportExportOptions.reportToExport}
      applyMaxRowLimitByDefault={false}
    >
      {reportExportOptions?.serverSideExport ? (
        <ReportsListingServerSideExportInner
          reportExportOptions={reportExportOptions}
          onReportExported={onReportExported}
        />
      ) : (
        <ListingReportTableColumnDefContextProvider
          report={reportExportOptions.reportToExport}
        >
          <ReportsInventoryClientSideExportInner
            reportExportOptions={reportExportOptions}
            onReportExported={onReportExported}
          />
        </ListingReportTableColumnDefContextProvider>
      )}
    </ReportsInventoryContextProvider>
  );
}

function ReportsListingServerSideExportInner({
  reportExportOptions: { reportToExport: reportConfig, fileType },
  onReportExported,
}: {
  reportExportOptions: ReportExportOptions;
  onReportExported: () => void;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { allEventIds } = useCatalogDataContext();
  const { filterQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();
  const { trackError } = useErrorBoundaryContext();

  const reportMetricsRequested = useMemo(() => {
    return reportConfig.metrics
      .filter((m) => isMetricConfigurableByGroupBy(m, reportConfig.groupBy))
      .map((metric) => listingMetricsToReportMetricRequest(metric))
      .filter((m) => !!m) as string[];
  }, [reportConfig.metrics, reportConfig.groupBy]);

  const downloadReport = async () => {
    await tryInvokeApi(
      async () => {
        const result = await new ReportClient(
          activeAccountWebClientConfig
        ).downloadListingReportMetrics({
          query: {
            ...(reportConfig.filter as ListingQuery),
            eventOrMappingIds: allEventIds!.map((ev) => ev.viagVirtualId),
            performerIds: allEventIds!
              .filter((ev) => ev.performerId != null)
              .map((ev) => ev.performerId!),
            venueIds: allEventIds!.map((ev) => ev.venueId),
          },
          groupBy: reportConfig.groupBy,
          globalReportTypeId: reportConfig.globalReportTypeId,
          metrics: reportConfig.metrics,
          isUsingFilterOverride:
            reportConfig.hasFilterOverride ||
            isReportFilterOverriden(filterQuery, reportConfig.filter),
          fileType,
          listingReportMetricsRequested:
            reportMetricsRequested as ListingReportMetricColumn[],
        });

        if (result.data) {
          downloadFileFromBlob(
            `${getReportExportFileBasename(
              reportConfig.reportName
            )}.${fileType.toLowerCase()}`,
            result.data
          );
        }
      },
      (error) => {
        trackError('ReportClient.downloadListingReportMetrics', error);
      },
      () => onReportExported()
    );
  };

  useEffect(() => {
    if (allEventIds != null) {
      downloadReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEventIds]);

  return <></>;
}

function ReportsInventoryClientSideExportInner({
  reportExportOptions: { reportToExport: reportConfig, fileType },
  onReportExported,
}: {
  reportExportOptions: ReportExportOptions;
  onReportExported: () => void;
}) {
  const { reportMetrics, isLoading, reportSummary } =
    useReportMetricsContext<ListingReportMetrics>();
  const { data, eventsTransformed } = useCatalogDataContext();
  const { displayedColumnsConfig } = useListingReportTableColumnDef();
  const { displayNames: columnDisplayNames } = useColumnDisplayName({
    columnIds: reportConfig.metrics,
    sectionType: SectionType.ListingsReport,
  });
  const deactivatedText = useContent(ContentId.Deactivated);

  const reportMetricsMapped: ListingReportMetricsWithGroupBy[] | undefined =
    useMemo(() => {
      if (!isLoading) {
        return mapReportMetrics(reportMetrics, eventsTransformed, data);
      }
    }, [data, eventsTransformed, isLoading, reportMetrics]);

  const userIds = useMemo(() => {
    if (!reportMetricsMapped || !displayedColumnsConfig?.length) {
      return undefined;
    }

    const userIds: string[] = [];

    reportMetricsMapped.forEach((reportMetric) => {
      const userIdsInRow = mapReportMetricToUserIds(
        reportMetric,
        displayedColumnsConfig
      );
      userIds.push(...userIdsInRow);
    });

    return uniq(userIds);
  }, [displayedColumnsConfig, reportMetricsMapped]);

  const useGetUserInfosQuery = useGetUserInfos(userIds);

  const allActiveUserInfos = useMemo(() => {
    if (userIds != null && userIds.length === 0) {
      return {};
    }
    return useGetUserInfosQuery.data;
  }, [useGetUserInfosQuery.data, userIds]);

  // Main data to be exported
  const tableData = useMemo(() => {
    if (
      !reportMetricsMapped ||
      !displayedColumnsConfig?.length ||
      !allActiveUserInfos ||
      !deactivatedText
    ) {
      return undefined;
    }

    const result: string[][] = [];

    const sortBy =
      reportConfig.sortBy ??
      GROUP_BY_TO_PRIMARY_COLUMN_ID[reportConfig.groupBy];
    const sortByColumnConfig = displayedColumnsConfig.find(
      (c) => c.id === sortBy
    );

    const dataSorted = reportMetricsMapped.sort((a, b) =>
      sortReportMetrics(
        a,
        b,
        sortByColumnConfig,
        reportConfig.isSortDescending ?? false
      )
    );

    dataSorted.forEach((reportMetric) => {
      const row = mapReportMetricToTableString(
        reportMetric,
        displayedColumnsConfig,
        allActiveUserInfos,
        deactivatedText
      );
      result.push(row);
    });

    return result;
  }, [
    allActiveUserInfos,
    deactivatedText,
    displayedColumnsConfig,
    reportConfig.groupBy,
    reportConfig.isSortDescending,
    reportConfig.sortBy,
    reportMetricsMapped,
  ]);

  // Summary data
  const tableFoot = useMemo(() => {
    if (
      !reportSummary ||
      !displayedColumnsConfig?.length ||
      !allActiveUserInfos
    ) {
      return undefined;
    }

    const dataMapped = mapReportMetrics(
      [reportSummary],
      eventsTransformed,
      data
    );

    if (dataMapped?.length) {
      return mapReportMetricToTableString(
        dataMapped[0],
        displayedColumnsConfig,
        allActiveUserInfos
      );
    }
  }, [
    allActiveUserInfos,
    data,
    displayedColumnsConfig,
    eventsTransformed,
    reportSummary,
  ]);

  // Table header
  const tableHeader = useMemo(() => {
    if (!columnDisplayNames.length || !displayedColumnsConfig) {
      return undefined;
    }

    return displayedColumnsConfig.map((c) => {
      const index = reportConfig.metrics.indexOf(c.id ?? '');
      if (index < 0) {
        return '';
      }
      return columnDisplayNames[index];
    });
  }, [columnDisplayNames, displayedColumnsConfig, reportConfig.metrics]);

  const onDataLoaded = (
    tableHeader: string[],
    tableData: string[][],
    tableFoot: string[]
  ) => {
    if (tableData.length > 0) {
      exportReport(reportConfig, fileType, tableHeader, tableData, tableFoot);
    }

    onReportExported();
  };

  useEffect(() => {
    if (
      data != null &&
      eventsTransformed != null &&
      tableData != null &&
      tableHeader != null &&
      tableFoot != null
    ) {
      onDataLoaded(tableHeader, tableData, tableFoot);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, tableHeader, tableFoot]);

  return <></>;
}
