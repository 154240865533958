import { useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { median } from 'src/utils/seatScoreUtils';
import { SectionInfo, SectionScoreOverride } from 'src/WebApiController';

import * as styles from './SectionDisplays.css';

export const SectionDisplay = ({
  section,
  scoreOverrides,
  lowestPrice,
  medianPrice,
  highestPrice,
  sectionAreaName,
}: {
  section: SectionInfo;
  scoreOverrides?: SectionScoreOverride[] | null;
  lowestPrice?: string | null;
  medianPrice?: string | null;
  highestPrice?: string | null;
  sectionAreaName?: string | null;
}) => {
  const score = useMemo(() => {
    const scores =
      scoreOverrides
        ?.filter((s) => s.sectionId === section.id)
        .map((s) => s.score ?? 0) ?? [];

    // If we do not have any scores just show the spec row score.
    // Otherwise, return the median
    if (scores.length == 0) {
      return section.specRow?.seatQualityScore;
    }

    return median(scores);
  }, [scoreOverrides, section.id, section.specRow?.seatQualityScore]);

  return (
    <Stack direction="column" gap="s">
      <Stack gap="m" alignItems="center">
        <div className={styles.sectionDisplay}>
          <Content id={ContentId.Section} />
        </div>
        <div className={styles.sectionDisplay} title={section.id.toString()}>
          {section.name}
        </div>
      </Stack>
      {score != null && (
        <Stack
          gap="m"
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div className={styles.sectionDisplayLabel}>
            <Content id={ContentId.Score} />
          </div>
          <div
            className={styles.sectionDisplayValue}
            title={section.specRow?.id.toString()}
          >
            {roundToPrecision(score, 4)}
          </div>
        </Stack>
      )}
      {lowestPrice != null && (
        <Stack
          gap="m"
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div className={styles.sectionDisplayLabel}>
            <Content id={ContentId.Lowest} />
          </div>
          <div className={styles.sectionDisplayValue}>{lowestPrice}</div>
        </Stack>
      )}
      {medianPrice != null && (
        <Stack
          gap="m"
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div className={styles.sectionDisplayLabel}>
            <Content id={ContentId.Median} />
          </div>
          <div className={styles.sectionDisplayValue}>{medianPrice}</div>
        </Stack>
      )}
      {highestPrice != null && (
        <Stack
          gap="m"
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div className={styles.sectionDisplayLabel}>
            <Content id={ContentId.Highest} />
          </div>
          <div className={styles.sectionDisplayValue}>{highestPrice}</div>
        </Stack>
      )}
      {sectionAreaName != null && (
        <Stack
          gap="m"
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <div className={styles.sectionDisplayLabel}>
            <Content id={ContentId.Group} />
          </div>
          <div className={styles.sectionDisplayValue}>{sectionAreaName}</div>
        </Stack>
      )}
    </Stack>
  );
};
