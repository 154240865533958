import { Label } from '@radix-ui/react-label';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { PosTextArea } from 'src/core/POS/PosTextArea';
import { typography } from 'src/core/themes/shared.css';
import { ContentId } from 'src/utils/constants/contentId';

import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal';
import * as styles from './AddNote.css';

type AddNoteModalProps = {
  onClose: () => void;
  onSubmit: () => void;
  internalNotes?: string | null;
  onInternalNotesChanged?: (notes?: string | null) => void;
};

export const AddInternalNoteModal = ({
  onSubmit,
  onClose,
  internalNotes,
  onInternalNotesChanged,
}: AddNoteModalProps) => {
  const [isOpen, toggleIsOpen] = useToggle(true);

  const [note, setNote] = useState(internalNotes ?? '');

  const onSubmitHandler = () => {
    onInternalNotesChanged?.(note);
    onSubmit();
  };

  useEffect(() => {
    if (!isOpen) setTimeout(onClose, 300);
  }, [isOpen, onClose]);

  return (
    <AddNoteDisplay
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
      note={note}
      setNote={setNote}
      onSubmitHandler={onSubmitHandler}
      showVisibility
    />
  );
};

export const AddAdminHoldNoteModal = ({
  onSubmit,
  onClose,
}: Omit<AddNoteModalProps, 'internalNotesFormKey'>) => {
  const [isOpen, toggleIsOpen] = useToggle(true);
  const { watch, setValue } = useFormContext<{
    adminHoldNotes?: string | null;
  }>();
  const adminHoldNotes = watch('adminHoldNotes');
  const [note, setNote] = useState(adminHoldNotes ?? '');

  const onSubmitHandler = () => {
    setValue('adminHoldNotes', note, { shouldDirty: true });
    onSubmit();
  };

  useEffect(() => {
    if (!isOpen) setTimeout(onClose, 300);
  }, [isOpen, onClose]);

  return (
    <AddNoteDisplay
      isOpen={isOpen}
      toggleIsOpen={toggleIsOpen}
      note={note}
      setNote={setNote}
      onSubmitHandler={onSubmitHandler}
    />
  );
};

type AddNoteDisplayProps = {
  isOpen: boolean;
  toggleIsOpen: () => void;
  note: string;
  setNote: (note: string) => void;
  onSubmitHandler: () => void;
  showVisibility?: boolean;
};

const AddNoteDisplay = ({
  isOpen,
  toggleIsOpen,
  note,
  setNote,
  onSubmitHandler,
  showVisibility,
}: AddNoteDisplayProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleIsOpen}
      unmountOnClose
      centered
      size="sm"
    >
      <ModalHeader onClose={toggleIsOpen}>
        <div className={styles.headerBar}>
          <h5 className={clsx(typography.title5, styles.headerText)}>
            <Content id={ContentId.Notes} />
          </h5>
        </div>
      </ModalHeader>
      <ModalBody className={styles.modalBody}>
        {showVisibility && (
          <div className={styles.formSection}>
            <Label className={styles.label}>
              <Content id={ContentId.Visibility} />
            </Label>
            <PosDropdown
              trigger={
                <PosDropdownItem>
                  <Content id={ContentId.Private} />
                </PosDropdownItem>
              }
            >
              <PosDropdownItem>
                <Content id={ContentId.Private} />
              </PosDropdownItem>
            </PosDropdown>
          </div>
        )}
        <div className={styles.formSection}>
          <Label className={styles.label}>
            <Content id={ContentId.Notes} />
          </Label>
          <PosTextArea
            aria-describedby="pos-text-area"
            onChange={(e) => setNote(e.currentTarget.value)}
            value={note}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <CancelButton textContentId={ContentId.Cancel} onClick={toggleIsOpen} />
        <OkButton onClick={onSubmitHandler} textContentId={ContentId.OK} />
      </ModalFooter>
    </Modal>
  );
};
