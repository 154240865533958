import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useUserCanUpdateListing } from 'src/hooks/useUserHasListingPermissions';
import { ListingNotesSection } from 'src/modals/common';
import {
  Listing,
  ListingDetailsUpdateInput,
  ListingNote,
} from 'src/WebApiController';

export const NotesSection = ({
  listing,
  disabled,
  isBulkEdit,
}: {
  listing?: Listing | null;
  disabled?: boolean;
  isBulkEdit?: boolean;
}) => {
  const { watch, setValue } = useFormContext<ListingDetailsUpdateInput>();
  const internalNotes = watch('internalNotes');
  const seatTraits = watch('seatTraits');

  const canUpdate = useUserCanUpdateListing(listing, false);

  const onListingNotesChanged = useCallback(
    (listingNotes?: ListingNote[] | null) => {
      setValue('seatTraits', listingNotes ?? null);
    },
    [setValue]
  );

  const onInternalNotesChanged = useCallback(
    (notes?: string | null) => {
      setValue('internalNotes', notes ?? null);
    },
    [setValue]
  );

  if (isBulkEdit) {
    return null;
  }

  return (
    <ListingNotesSection
      listingNotes={seatTraits ?? listing?.seatTraits}
      internalNotes={internalNotes}
      allowEditListingNotes={listing?.isSeatSaver}
      disabled={disabled || !canUpdate}
      onListingNotesChanged={
        listing?.isSeatSaver ? onListingNotesChanged : undefined
      }
      onInternalNotesChanged={onInternalNotesChanged}
    />
  );
};
