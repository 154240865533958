import { PurchaseWizard } from 'src/modals/PurchaseWizard';
import { PurchaseDeeplinkQueryParam } from 'src/utils/constants/constants';

export const PurchaseWizardModalConfig = {
  children: <PurchaseWizard />,
  clickOutsideToClose: true,
  deepLinkKey: PurchaseDeeplinkQueryParam,
  deepLinkValue: undefined,
  size: 'slide-in',
};
