import { Fragment } from 'react';
import { LinkAndCopy } from 'src/components/common/LinkAndCopy';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { SaleMarketplaceIdDisplay } from 'src/components/Sales/SaleMarketplaceIdDisplay';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { Detail, DetailSection, SectionContent } from 'src/modals/common';
import { SeatIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getSaleDetailsRelativeUrl,
  getSaleStatusDisplayContentId,
} from 'src/utils/saleUtils';
import { SaleDisplayInfo, Seating, Ticket } from 'src/WebApiController';

type SalesSectionProps = {
  sales?: SaleDisplayInfo[];
  tickets?: Ticket[];
  /**
   * Seat Section to append to row and seating info
   */
  listingSeating?: Seating;
};

export const SalesSection = ({
  sales,
  tickets = [],
  listingSeating,
}: SalesSectionProps) => {
  const ticketsWithSales = tickets.filter((ticket) => ticket.id);

  const ticketsBySalesId = ticketsWithSales.reduce<Record<string, Ticket[]>>(
    (acc, curr) => {
      if (!curr.id) return acc;
      const sale = acc[curr.id] ?? [];
      return { ...acc, [curr.id]: [...sale, curr] };
    },
    {}
  );

  return (
    <DetailSection name={<Content id={ContentId.Sales} />}>
      <SectionContent numOfColumns={3}>
        {sales?.map((s) => {
          const { id, idOnMkp, mkp, stat } = s;
          const saleTickets = ticketsBySalesId[id];
          return (
            <Fragment key={id}>
              <Detail
                label={<Content id={ContentId.SaleId} />}
                detail={
                  <LinkAndCopy
                    relativeUrl={getSaleDetailsRelativeUrl(id!)}
                    valueToCopy={id}
                    linkChildren={
                      <SaleMarketplaceIdDisplay
                        saleId={id}
                        marketplaceSaleId={idOnMkp}
                        marketplace={mkp}
                        textAlign="left"
                      />
                    }
                  />
                }
              />
              <Detail
                label={<Content id={ContentId.SaleStatus} />}
                detail={
                  <div>
                    <Content id={getSaleStatusDisplayContentId(stat)} />
                  </div>
                }
              />
              <Detail
                label={<Content id={ContentId.Seating} />}
                detail={
                  <div>
                    {saleTickets?.map((ticket) => {
                      return (
                        <Stack key={ticket.id} gap="m">
                          <SeatIcon />
                          <SeatingInfo
                            seatFr={ticket.seat}
                            seatTo={null}
                            row={ticket.row}
                            section={listingSeating?.section ?? ''}
                          />
                        </Stack>
                      );
                    })}
                  </div>
                }
              />
            </Fragment>
          );
        })}
      </SectionContent>
    </DetailSection>
  );
};
