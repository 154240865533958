import { ComponentProps } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { IconsFill, UploadIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { BulkUploadPurchaseDialog } from './BulkUploadPurchaseDialog';

export type LaunchBulkUploadPurchaseDialogProps = ComponentProps<
  typeof Button
> & { className?: string };

export const LaunchBulkUploadPurchaseDialog = ({
  variant = 'link',
  ...props
}: LaunchBulkUploadPurchaseDialogProps) => {
  const bulkUploadPurchaseDialog = useBasicDialog();

  const onBulkUploadPurchase = () => {
    bulkUploadPurchaseDialog.launchDialog();
  };

  return (
    <>
      <Button {...props} variant={variant} onClick={onBulkUploadPurchase}>
        <UploadIcon
          withHoverEffect
          fill={IconsFill.currentColor}
          size={vars.iconSize.s}
        />
        <Content id={ContentId.BulkUpload} />
      </Button>
      <BulkUploadPurchaseDialog
        {...bulkUploadPurchaseDialog.dialogProps}
        onClosed={bulkUploadPurchaseDialog.closeDialog}
        onCancel={bulkUploadPurchaseDialog.closeDialog}
      />
    </>
  );
};
