import { useUpdatePurchasesPermissions } from 'src/components/Purchases/BulkActions/useUpdatePurchasesPermissions';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

export const LaunchBulkDeleteTicketGroup = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const { hasUpdatePurchasesPermission } = useUpdatePurchasesPermissions();
  const hasBulkDeleteTicketGroupFeature = useUserHasFeature(
    Feature.BulkDeleteTicketGroup
  );

  if (!hasUpdatePurchasesPermission || !hasBulkDeleteTicketGroupFeature) {
    return null;
  }

  return (
    <>
      <PosDropdownItem
        key="bulk-delete-ticket-groups"
        onClick={onClick}
        disabled={disabled}
      >
        <Stack gap="m" alignItems="center" width="full">
          <Content id={ContentId.CancelTicketGroupsParenth} />
        </Stack>
      </PosDropdownItem>
    </>
  );
};
