import { format } from 'date-fns';
import { ListingExperiment } from 'src/WebApiController';

export const toExperimentName = (experiment: ListingExperiment) => {
  const formatedStartDate = format(
    new Date(experiment.startDate),
    'MM/dd/yyyy'
  );
  const formatedEndDate = format(new Date(experiment.endDate), 'MM/dd/yyyy');
  if (experiment.discountPercentage) {
    return `${experiment.insertBy}:${experiment.discountPercentage}%-${formatedStartDate}-${formatedEndDate}`;
  }
  return `${experiment.insertBy}:${formatedStartDate}-${formatedEndDate}`;
};
