import { SortingState } from '@tanstack/react-table';
import { useEffect } from 'react';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext/MultiSelectionContext';

interface UseClearShiftSelectionOnSortingChangeProps {
  sortingState: SortingState;
}

export const useClearShiftSelectionOnSortingChange = ({
  sortingState,
}: UseClearShiftSelectionOnSortingChangeProps) => {
  const { clearShiftKeySelection, selectionMode } = useMultiSelectionContext();

  useEffect(() => {
    // If selection mode is AllGroups, then don't reset the shiftKey selection state
    // because the user is selecting between different tables,
    // and when the component is mounted using an Accordion, this effect runs
    // clearing the state.
    if (!selectionMode || selectionMode.mode === MultiSelectScope.AllGroups) {
      return;
    }
    clearShiftKeySelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionMode, sortingState]);
};
