import { ComponentProps, useCallback, useContext } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { HistoryIcon } from 'src/svgs/HistoryIcon';
import { IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { ActivityLogModal } from './ActivityLogModal';
import * as styles from './ActivityLogModal.css';
import { ActivityLogLaunchButtonIconSpan } from './ActivityLogModal.styled';

export const LaunchActivityLog = ({
  entityId,
  cancelTo,
}: {
  entityId: number;
} & ComponentProps<typeof ActivityLogModal>) => {
  const { setModal } = useContext(ModalContext);
  const { setActivePosEntity } = useActivePosEntityContext();

  const activityLogUi = useCallback(() => {
    setModal({
      children: <ActivityLogModal cancelTo={cancelTo} />,
      clickOutsideToClose: false,
      size: 'slide-in',
    });
  }, [setModal, cancelTo]);

  const onLaunchDialog = useCallback(() => {
    setActivePosEntity(entityId, undefined, true);
    activityLogUi();
  }, [setActivePosEntity, entityId, activityLogUi]);

  return (
    <div className={styles.activityLogLaunchButtonIconContainer}>
      <ActivityLogLaunchButtonIconSpan onClick={onLaunchDialog}>
        <HistoryIcon
          withHoverEffect={true}
          fill={IconsFill.currentColor}
          style={{ verticalAlign: 'middle' }}
        />
        <Content id={ContentId.ActivityLogLaunchButtonText} />
      </ActivityLogLaunchButtonIconSpan>
    </div>
  );
};
