import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const HeaderWrapperDiv = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  color: ${(props) => props.theme.colors.textPrimary};
  width: 100%;
  padding: 0;
  border-top: 1px solid ${(props) => props.theme.colors.borderPrimary};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};
`;

export const Header = styled.header`
  display: grid;
  grid-template-columns: 12% 1fr 8%;
  align-items: center;
  align-self: center;
  padding: ${() => `0 ${vars.spacing.sm}`};
  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      grid-template-columns: 20% 1fr 20%;
      padding: 0 ${vars.spacing.xl};
    }`
    )}`};
`;

export const HeaderLeftAreaDiv = styled.div`
  display: flex;
  align-self: flex-start;
  align-items: stretch;
  align-self: center;
  padding: ${() => `${vars.spacing.sm} 0`};

  .brand-logo {
    display: flex;
    align-items: center;
    padding-inline-end: ${() => vars.spacing.xxs};
    ${({ theme: { media } }) =>
      `${media.makeMediaQuery(
        media.breakpoints.md,
        `padding-inline-end: ${vars.spacing.lg};`
      )}`}
  }
`;

export const ProfileDiv = styled.div<{ loggedIn: boolean }>`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  color: ${({ loggedIn, theme }) =>
    loggedIn ? theme.colors.textBrand : theme.colors.textPrimary};

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      flex-direction: row;
      `
    )}`}

  svg {
    margin-inline-start: ${() => vars.spacing.sm};
  }
`;

export const DropdownLabel = styled.strong`
  display: none;
  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      display: block;
      `
    )}`}
`;

export const DropdownDivider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};
  width: 100%;
`;

export const DesktopNavTitleSale = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${() => vars.spacing.sm};
  padding: 0 1px;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      font-weight: 500;
      font-size: ${() => vars.typography.fontSize['lg']};
      line-height: 24px;
      text-align: center;
      `
    )}
  `}

  &.active {
    font-weight: 600;
    color: ${(props) => props.theme.colors.textBrand};

    border-top-color: ${(props) => props.theme.colors.backgroundBrandActive};
    ${({ theme: { media, colors } }) =>
      `${media.makeMediaQuery(
        media.breakpoints.md,
        `border-bottom-color: ${colors.backgroundBrandActive};
       border-top-color: transparent;
      `
      )}`}

    &:hover {
      color: ${(props) => props.theme.colors.textBrandHover};
      border-bottom-color: ${(props) =>
        props.theme.colors.backgroundBrandHover};
    }
  }
`;

export const NavTitleSaleCheck = styled.span`
  svg {
    fill: ${(props) => props.theme.colors.accent100};
  }
`;
