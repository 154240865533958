import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFilterQueryState } from 'src/components/MainFilterBar/useFilterQueryState';
import { Content } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_SEPARATOR,
} from 'src/navigations/Routes/InventoryEvent/InventoryEvent.constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  Feature,
  ListingQuery,
} from 'src/WebApiController';

export const CatchUpAction = () => {
  const hasPricingCatchupFeature = useUserHasFeature(Feature.PricingCatchup);
  const navigate = useNavigate();
  const { selectedViagogoVirtualIds } = useFilterQueryState<ListingQuery>(
    ActionOutboxEntityType.Listing
  );

  const onCatchUp = useCallback(() => {
    const tabEventIds = selectedViagogoVirtualIds?.join(
      TAB_EVENT_IDS_SEPARATOR
    );

    const activeEventId = selectedViagogoVirtualIds?.[0];
    const catchUpEventUrl = `/inventory/event?${ACTIVE_EVENT_IDS_2_QUERY_PARAM}=${activeEventId}&${TAB_EVENT_IDS_2_QUERY_PARAM}=${tabEventIds}`;
    navigate(catchUpEventUrl);
  }, [navigate, selectedViagogoVirtualIds]);

  if (!hasPricingCatchupFeature) {
    return null;
  }

  return (
    <Button
      variant="outline"
      onClick={onCatchUp}
      disabled={!selectedViagogoVirtualIds?.length}
    >
      <Content id={ContentId.Catchup} />
    </Button>
  );
};
