import { ChangeEvent, ComponentProps, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import {
  FieldError,
  FieldLabel,
  FieldWrapper,
} from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { PURCHASE_COST_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { CostDetail } from 'src/WebApiController';

import * as styles from './PurchaseCostDialog.css';

export type PurchaseCostDialogProps = ComponentProps<typeof RSModal> & {
  cost: CostDetail;
  currencyCode: string;
  onSave: (data: CostDetail) => void;
};

export function PurchaseCostDialog({ cost, ...rest }: PurchaseCostDialogProps) {
  const methods = useForm<CostDetail>({
    defaultValues: {
      ...cost,
      cost: cost?.cost && {
        ...cost.cost,
        disp: null, // we clear the display because editing cost now will dynamically render the amount with the currency
      },
    },
  });

  return (
    <FormProvider {...methods}>
      <PurchaseCostDialogBody {...methods} {...rest} />
    </FormProvider>
  );
}

const PurchaseCostDialogBody = ({
  currencyCode,
  onSave,
  onClosed,
  getValues,
  setValue,
  register,
  formState,
  clearErrors,
  setError,
  handleSubmit,
  watch,
  ...rest
}: Omit<PurchaseCostDialogProps, 'cost'> &
  Omit<
    ComponentProps<typeof FormProvider<CostDetail, unknown>>,
    'children'
  >) => {
  const { date, id } = getValues();
  const costType = watch('costType');
  const costAmount = watch('cost.amt');
  const costDateError = formState.errors.date?.message;
  const costTypeError = formState.errors.costType?.message;
  const amountError = formState.errors.cost?.amt?.message;

  const requiredMsg = useContent(ContentId.Required);

  const { getUiCurrency } = useLocalizationContext();

  const onSubmit = useCallback(() => {
    clearErrors();

    let hasErrors = false;
    if (!date) {
      setError('date', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }
    if (!costAmount) {
      setError('cost.amt', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }
    if (!costType) {
      setError('costType', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (!hasErrors) {
      handleSubmit(onSave)();
    }
  }, [
    clearErrors,
    costAmount,
    costType,
    date,
    handleSubmit,
    onSave,
    requiredMsg,
    setError,
  ]);

  return (
    <GenericDialog
      {...rest}
      header={
        <Content
          id={id > 0 ? ContentId.EditCostCredit : ContentId.AddCostCredit}
        />
      }
      footer={
        <>
          {onClosed && <CancelButton onClick={onClosed} />}
          <Button
            variant={'regular'}
            onClick={onSubmit}
            disabled={formState.isSubmitting}
          >
            <Content id={ContentId.Save} />
          </Button>
        </>
      }
      onCancel={onClosed}
    >
      <div className={styles.purchaseCostDialogContainer}>
        <FieldWrapper>
          <FieldLabel>
            <Content id={ContentId.Type} />
          </FieldLabel>
          <PosEnumSelect
            value={costType}
            disabled={formState.isSubmitting}
            onChange={(newCostType) => {
              if (newCostType && newCostType !== costType) {
                setValue('costType', newCostType);
              }
            }}
            valueOptionsContent={PURCHASE_COST_TYPE_TO_CID}
          />
          {costTypeError && <FieldError>{costTypeError}</FieldError>}
        </FieldWrapper>
        <FieldWrapper>
          <PosFormField
            errors={amountError}
            label={<Content id={ContentId.Amount} />}
          >
            <PosCurrencyField
              rootProps={{
                state: getTextFieldState(amountError),
              }}
              value={costAmount}
              uiCurrency={getUiCurrency(currencyCode)}
              {...register('cost.amt', {
                validate: {
                  required: (fieldVal) => {
                    return parseFloat(fieldVal?.toString() ?? '')
                      ? undefined
                      : requiredMsg;
                  },
                },
                valueAsNumber: true,
                onChange: (e: ChangeEvent<HTMLInputElement>) => {
                  const rval = parseFloat(e.target.value);
                  clearErrors('cost.amt');
                  setValue('cost.disp', null); // Clear the display because now th
                  setValue('cost.amt', rval);
                },
              })}
            />
          </PosFormField>
        </FieldWrapper>
        <FieldWrapper>
          <PosFormField
            label={<Content id={ContentId.DateIncurred} />}
            errors={costDateError}
          >
            <DatePickerInput
              fieldError={costDateError}
              disabled={formState.isSubmitting}
              date={date ? new Date(date) : undefined}
              onDateChange={(newDate: Date) => {
                if (newDate) {
                  clearErrors('date');
                  const dateStr = newDate.toISOString();
                  if (dateStr !== date) {
                    setValue('date', dateStr);
                  }
                } else {
                  setValue('date', ''); // this allow setting to empty and validating
                }
              }}
              locale={getLocaleFromLanguageOrCurrent()}
            />
          </PosFormField>
        </FieldWrapper>
        <FieldWrapper>
          <PosFormField label={<Content id={ContentId.Notes} />}>
            <PosTextField
              rootProps={{
                disabled: formState.isSubmitting,
              }}
              disabled={formState.isSubmitting}
              {...register('description')}
            />
          </PosFormField>
        </FieldWrapper>
      </div>
    </GenericDialog>
  );
};
