import { format } from 'date-fns';
import { STUBHUB_LOGO_IMAGE } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ACTION_OUTBOX_ENTITY_TYPE_TO_CID,
  NOTIFICATION_TYPE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getFormattedEventName } from 'src/utils/eventWithDataUtils';
import { getFormattedLocation } from 'src/utils/format';
import { getListingDetailsDeepLinkUrl } from 'src/utils/inventoryUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { getPurchaseOrderDeepLinkUrl } from 'src/utils/purchaseUtils';
import { getSaleDetailsDeepLinkUrl } from 'src/utils/saleUtils';
import {
  ActionOutboxEntityType,
  Marketplace,
  Notification,
} from 'src/WebApiController';

const getUrl = (
  entityType: ActionOutboxEntityType,
  entityId: number
): URL | null | undefined => {
  switch (entityType) {
    case ActionOutboxEntityType.Sale:
      return getSaleDetailsDeepLinkUrl(entityId);

    case ActionOutboxEntityType.Listing:
      return getListingDetailsDeepLinkUrl(entityId);

    case ActionOutboxEntityType.Purchase:
      return getPurchaseOrderDeepLinkUrl(entityId);

    case ActionOutboxEntityType.SellerSupportCase:
      return new URL(
        'http://add-deep-link-to-support-case-view-when-its-created'
      );

    case ActionOutboxEntityType.Document:
    case ActionOutboxEntityType.SellerEvent:
    case ActionOutboxEntityType.InboundEmail:
      return null;
  }
};

const buildMessage = (
  notification: Notification,
  resolveContent: (
    contentId: ContentId | FormatContentId,
    marketplace: Marketplace | null
  ) => string | string[] | undefined
): string => {
  const locale = getLocaleFromLanguageOrCurrent();
  let result = `${resolveContent(
    ACTION_OUTBOX_ENTITY_TYPE_TO_CID[notification.entityType],
    notification.marketplace
  )} #${notification.entityId} \n`;

  if (notification.event) {
    result += `${getFormattedEventName({
      event: notification.event,
      performer: notification.performer,
    }).join(' ')}\n`;

    const startDate =
      notification.event?.dates.start == null
        ? null
        : new Date(notification.event?.dates.start);

    if (startDate) {
      const eventDate = `${format(startDate, 'MMM d, yyyy', {
        locale,
      })} - ${format(startDate, 'h:mm a', { locale })}`;

      result += `${eventDate}\n`;
    }
  }

  if (notification.venue) {
    result += `${resolveContent(
      ContentId.VenueName,
      null
    )}: ${getFormattedLocation(notification.venue)}\n`;
  }

  return result;
};

export const fireNativeNotification = (
  notification: Notification,
  resolveContent: (
    contentId: ContentId | FormatContentId,
    marketplace: Marketplace | null
  ) => string | string[] | undefined
) => {
  window.Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      const notif = new window.Notification(
        resolveContent(
          NOTIFICATION_TYPE_TO_CID[notification.notificationType],
          notification.marketplace
        )?.toString() ?? '',
        {
          body: buildMessage(notification, resolveContent),
          icon: STUBHUB_LOGO_IMAGE,
        }
      );
      notif.onclick = () => {
        const url = getUrl(notification.entityType, notification.entityId);
        if (url) {
          window.open(url)?.focus();
        }
      };
    }
  });
};
