import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PurchaseClient } from 'src/WebApiController';

export const GET_ACCESSIBLE_VENDORS_QUERY_KEY = 'getAccessibleVendors';

interface UseGetAccessibleVendorsQueryProps {
  getOverride: boolean;
  getVendorAccountsCount: boolean;
}

export function useGetAccessibleVendorsQuery({
  getOverride,
  getVendorAccountsCount,
}: UseGetAccessibleVendorsQueryProps) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  return useQuery({
    queryKey: [
      GET_ACCESSIBLE_VENDORS_QUERY_KEY,
      activeAccountWebClientConfig.activeAccountId,
      getOverride,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return tryInvokeApi(
        () => {
          return new PurchaseClient(
            activeAccountWebClientConfig
          ).getAccessibleVendors(getOverride, getVendorAccountsCount);
        },
        (error) => {
          trackError('PurchaseClient.getAccessibleVendors', error);
        }
      );
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
  });
}
