import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  UploadExternalIdsBody,
  UploadExternalIdsForm,
} from 'src/components/UploadArtifacts/UploadExternalIds';
import { Stack } from 'src/core/ui';
import { PurchaseWizardFooter } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { toEntityWithTicket } from 'src/utils/ticketUtils';
import {
  ActionOutboxEntityType,
  PosClientConfig,
  PosUiActionResult,
  TicketExternalId,
} from 'src/WebApiController';

import {
  ticketGroupInputFooter,
  ticketGroupPredeliveryBody,
} from '../PurchaseTicketFlow.css';

export const UploadExternalIdsSection = ({
  ticketGroupIndex,
  onCancel,
}: {
  ticketGroupIndex: number;
  onCancel: () => void;
}) => {
  const { setValue, watch } = useFormContext<PurchaseTicketsInput>();

  const ticketGroup = watch(`ticketGroups.${ticketGroupIndex}`);

  const onSubmit = (
    _: PosClientConfig,
    entityId: number,
    externalIds: TicketExternalId[]
  ) => {
    const newTickets = ticketGroup.tickets?.value?.map((t) => {
      const tb = externalIds.find((b) => b.ticketId === t.id);
      t.externalId = tb?.externalId ?? null;
      return { ...t };
    });

    setValue(
      `ticketGroups.${ticketGroupIndex}.tickets`,
      posChangedField(newTickets ?? null)
    );

    return Promise.resolve({
      status: 200,
      entityId,
      entityType: ActionOutboxEntityType.Purchase,
    } as PosUiActionResult);
  };

  const entityWithTickets = useMemo(
    () => toEntityWithTicket(ticketGroup),
    [ticketGroup]
  );

  return (
    <UploadExternalIdsForm
      entityWithTickets={entityWithTickets}
      onComplete={onCancel}
      onUpload={onSubmit}
      renderContent={(onUploadClick: () => void, disabled?: boolean) => (
        <Stack direction="column" height="full" justifyContent="spaceBetween">
          <UploadExternalIdsBody
            className={ticketGroupPredeliveryBody}
            isReadOnly={disabled}
            ticketCnt={entityWithTickets.ticketCnt}
            seating={entityWithTickets.seating}
          />
          <PurchaseWizardFooter
            className={ticketGroupInputFooter}
            disabled={disabled}
            onPrevious={onCancel}
            onPreviousLabel={ContentId.Cancel}
            onNext={onUploadClick}
            onNextLabel={ContentId.Save}
          />
        </Stack>
      )}
    />
  );
};
