import { debounce } from 'lodash-es';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { State } from 'react-use/lib/useScroll';
import { VirtuosoScrollEvent } from 'src/tables/Table';

const MinScrollHeightToCollapse = 30;

interface ICollapsableViewContext {
  isCollapsedView: boolean;
  setIsCollapsedView: (isCollapsedView: boolean) => void;

  isEnabled: boolean;
  onScroll: (virtuosoScrollEvent: VirtuosoScrollEvent) => void;
}

interface CollapsableRefProps {
  isCollapsedView: boolean;
  isEnabled: boolean;
  lastScrollState: State;
}

const collapsableRefProps: CollapsableRefProps = {
  isCollapsedView: false,
  isEnabled: false,
  lastScrollState: {
    x: 0,
    y: 0,
  },
};

export const CollapsableViewContext = createContext<ICollapsableViewContext>({
  isCollapsedView: false,
  setIsCollapsedView: () => void 0,
  isEnabled: false,
  onScroll: () => void 0,
});

export const useCollapsableViewContext = () =>
  useContext(CollapsableViewContext);

export const CollapsableViewProvider = ({
  children,
  isEnabled,
}: {
  isEnabled: boolean;
  children: ReactNode;
}) => {
  const [isCollapsedView, setIsCollapsedView] = useState<boolean>(false);

  const refProps = useRef<CollapsableRefProps>(collapsableRefProps);
  refProps.current.isCollapsedView = isCollapsedView;
  refProps.current.isEnabled = isEnabled;

  /**
   * Only collapse when scrollY changes.
   * Don't add deps in this useCallback to avoid renders
   */
  const onScroll = useCallback(
    debounce((virtuosoScrollEvent: VirtuosoScrollEvent) => {
      const { element, scrollState } = virtuosoScrollEvent;
      const { scrollHeight, clientHeight } = element;
      const { isCollapsedView, lastScrollState, isEnabled } = refProps.current;

      if (!isEnabled) {
        return;
      }

      const lastScrollY = lastScrollState.y;
      refProps.current.lastScrollState = scrollState;

      // If scrollY hasn't changed, then don't do anything.
      if (lastScrollY === scrollState.y) {
        return;
      }

      const hasScrollDiff =
        scrollHeight - clientHeight > MinScrollHeightToCollapse;
      const isAtTop = virtuosoScrollEvent.scrollState.y === 0;

      if (isCollapsedView) {
        if (isAtTop && hasScrollDiff) {
          setIsCollapsedView(false);
        }
        return;
      }

      // NOT collapsed
      if (!hasScrollDiff) {
        return;
      }

      setIsCollapsedView(true);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 200),
    []
  );

  return (
    <CollapsableViewContext.Provider
      value={{
        isCollapsedView,
        setIsCollapsedView,
        isEnabled,
        onScroll,
      }}
    >
      {children}
    </CollapsableViewContext.Provider>
  );
};
