import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import styled from 'styled-components/macro';

import * as styles from './MetricsDisplay.css';

const SUB_HEIGHT = '10px';
export const MetricsDisplayPlaceholder = ({
  width = '100%',
  height = '100%',
}: {
  width?: string;
  height?: string;
}) => {
  return (
    <MetricsPlaceholderWrapper
      key="metrics-placeholder"
      width={width}
      height={height}
    >
      <ContentPlaceholder className={styles.metricsItem} height={SUB_HEIGHT} />
      <ContentPlaceholder className={styles.metricsItem} height={SUB_HEIGHT} />
    </MetricsPlaceholderWrapper>
  );
};

const MetricsPlaceholderWrapper = styled.div<{ width: string; height: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;
