import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { InfoMessage } from 'src/core/POS/MessageWithIcon/InfoMessage';
import { BulkEditDeliveryTypeDialog } from 'src/dialogs/BulkEdits/BulkEditDeliveryTypeDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditPreview,
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  BulkEditTicketGroupClient,
  DeliveryType,
  PurchaseOrderQuery,
  TicketTypeOverride,
} from 'src/WebApiController';

import { PURCHASE_BULK_UPDATE_DELIVERY_TYPE_UPDATE_KEY } from '../../PurchaseActionDropdown.constants';
import { LaunchBulkUpdateDeliveryType } from './LaunchBulkUpdateDeliveryType';

export const useBulkUpdateDeliveryType = (
  filterQuery: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string,
  isEventView?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const updateKey = groupId
    ? `purchase-delivery-type-event-${groupId}`
    : PURCHASE_BULK_UPDATE_DELIVERY_TYPE_UPDATE_KEY;

  const { showErrorDialog } = useErrorBoundaryContext();
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const {
    openDialog: openBulkEditDeliveryTypeDialog,
    closeDialog: closeBulkEditDeliveryTypeDialog,
  } = useDialog(DialogId.BulkEditDeliveryType, BulkEditDeliveryTypeDialog);

  const updatePurchaseOrders = !isEventView;

  const onSubmit = useCallback(
    async (
      deliveryType: DeliveryType | null,
      ticketTypeOverride: TicketTypeOverride | null,
      updateSalesOfListings: boolean,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails,
      retryableEntities?: BulkEditPreview
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const bulkEditClient = updatePurchaseOrders
              ? new BulkEditPurchaseClient(activeAccountWebClientConfig)
              : new BulkEditTicketGroupClient(activeAccountWebClientConfig);

            // Resolve the query considering the retryable entities
            const query: PurchaseOrderQuery = {
              ...filterQuery,
              eventOrMappingIds: retryableEntities
                ? []
                : filterQuery.eventOrMappingIds,
              entityIds:
                updatePurchaseOrders && retryableEntities
                  ? retryableEntities.bulkEditCountPreview[
                      ActionOutboxEntityType.Purchase
                    ]?.idsAffected ?? []
                  : filterQuery.entityIds,
              ticketGroupIds:
                !updatePurchaseOrders && retryableEntities
                  ? retryableEntities.bulkEditCountPreview[
                      ActionOutboxEntityType.TicketGroup
                    ]?.idsAffected ?? []
                  : filterQuery.ticketGroupIds,
            };

            const preview = await bulkEditClient.bulkUpdateDeliveryTypePreview(
              query,
              updateSalesOfListings
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateDeliveryTypePreview', error, {
              trackErrorData: { filterQuery, retryableEntities },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (deliveryType && ticketTypeOverride) {
        await tryInvokeApi(
          async () => {
            const bulkEditClient = updatePurchaseOrders
              ? new BulkEditPurchaseClient(activeAccountWebClientConfig)
              : new BulkEditTicketGroupClient(activeAccountWebClientConfig);

            const succeeded = await bulkEditClient.bulkUpdateDeliveryType(
              {
                item1: preview!.preview,
                item2: deliveryType,
                item3: ticketTypeOverride.overrides[0].ticketTypePriorities,
              },
              updateSalesOfListings,
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                await refreshData(true);
                await refreshExpandedListItems();
              }

              closeBulkEditDeliveryTypeDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateDeliveryType', error, {
              trackErrorData: {
                preview,
                deliveryType,
                ticketTypeOverride,
                updateSalesOfListings,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkEditDeliveryTypeDialog,
      filterQuery,
      refreshData,
      refreshExpandedListItems,
      setIsLoading,
      showErrorDialog,
      updateKey,
      updatePurchaseOrders,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkUpdateDeliveryType
        key="purchaseDeliveryTypeUpdate"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkEditDeliveryTypeDialog({
            updateKey,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            entityType: ActionOutboxEntityType.TicketGroup, // delivery type only apply to TGs
            infoMessage: (
              <InfoMessage
                message={
                  <Content id={ContentId.BulkEditDeliveryTypePurchaseMessage} />
                }
              />
            ),
            onCancel: closeBulkEditDeliveryTypeDialog,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
