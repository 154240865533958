import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const CheckCircleIcon = ({ ...props }: PosIconProps) => {
  return (
    <SvgWrapper fill="none" {...props} viewBox={`0 0 11 11`}>
      <circle cx="5.5" cy="5.5" r="4.5" fill="#677383" stroke="none" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.68748 4.76871C3.64145 4.72438 3.56864 4.7243 3.52251 4.76853L3.05601 5.21585C3.00737 5.26249 3.0071 5.34017 3.05541 5.38714L4.97876 7.25699C5.02675 7.30364 5.10374 7.30159 5.14917 7.25244L7.88757 4.28986C7.93166 4.24217 7.92938 4.16793 7.88244 4.12303L7.41597 3.67679C7.36748 3.63042 7.29027 3.63324 7.24531 3.68304L5.14868 6.00524C5.10362 6.05515 5.02618 6.05785 4.97774 6.01121L3.68748 4.76871Z"
        fill="white"
      />
    </SvgWrapper>
  );
};
