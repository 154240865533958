import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function FilterIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M4.882 20.182H2v-1.818h2.882a2.728 2.728 0 110 1.818zm0-14.546H2V3.818h2.882a2.728 2.728 0 110 1.818zm14.236 7.273a2.728 2.728 0 110-1.818H22v1.818h-2.882zm-6.209-1.818v1.818H2v-1.818h10.91zM22 3.818v1.818H11.09V3.818H22zm0 14.546v1.818H11.09v-1.818H22z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
