import { ChangeEventHandler, ComponentPropsWithRef } from 'react';
import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export type SwitchProps = {
  checked: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  disabled?: boolean;
  containerClassName?: string;
};
export function Switch({
  checked,
  onChange,
  disabled,
  containerClassName,
  ...props
}: SwitchProps &
  Omit<
    ComponentPropsWithRef<typeof StyledInput>,
    'type' | 'checked' | 'onChange' | 'disabled'
  >) {
  return (
    <StyledContainer className={containerClassName}>
      <Styledlabel checked={checked} disable={disabled}>
        <StyledInput
          type="checkbox"
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          {...props}
        />
      </Styledlabel>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  display: flex;
  height: 24px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledInput = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const Styledlabel = styled.label<{ checked: boolean; disable?: boolean }>`
  width: 32px !important;
  height: ${() => `${vars.spacing.xs}`};
  position: relative;
  background-color: ${(props) => props.theme.colors.textDisabled};
  border-radius: ${() => vars.spacing.xxs};
  cursor: ${(props) => (props.disable ? 'default' : 'pointer')};

  &::before {
    content: '';
    top: 50%;
    left: 0;
    transform: ${(props) =>
      props.checked ? 'translate(100%, -50%)' : 'translate(0, -50%)'};
    transition: 0.3s transform;
    position: absolute;
    width: ${() => vars.spacing.lg};
    height: ${() => vars.spacing.lg};
    border-radius: 50%;
    background: ${(props) => props.theme.colors.backgroundPrimary};
    border: 2px solid
      ${(props) =>
        props.disable
          ? props.theme.colors.borderDisabled
          : props.theme.colors.borderActive};
  }
`;
