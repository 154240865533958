import { ChangeEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { Radio, RadioGroup } from 'src/core/ui';
import { Stack } from 'src/core/ui';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import {
  PRICE_FLOOR_CEILING_MODE_OPTIONS,
  PriceFloorCeilingMode,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getAllInPriceFromListPrice,
  getListPriceFromAllinPrice,
} from 'src/utils/inventoryUtils';
import {
  AutoPricingCompListingMode,
  ListingDetails,
  ListingDetailsUpdateInput,
  Marketplace,
  UserSetting,
} from 'src/WebApiController';

import { useListingAutoPricingSettings } from '../useListingAutoPricingSettings';
import * as styles from './PricingSection.css';

export const PricingProtection = ({
  listing,
  disabled,
  isBulkEdit,
}: {
  listing?: ListingDetails | null;
  disabled?: boolean;
  isBulkEdit?: boolean;
}) => {
  const { setValue, watch, clearErrors, formState } =
    useFormContext<ListingDetailsUpdateInput>();

  const { loginContext } = useAppContext();
  const stubHubMkpSettings =
    loginContext?.user?.activeAccount?.marketplaceSettings?.find(
      (m) => m.mkp === Marketplace.StubHub
    );

  const dontChangeText = useContent(ContentId.DontChange);
  const listPrice = watch('listPrice');
  const netProceedsFloor = watch('netProceedsFloor');
  const netProceedsCeiling = watch('netProceedsCeiling');
  const marketplacePriceUpdates = watch('marketplacePriceUpdates');

  const floorError = formState.errors.netProceedsFloor?.message;
  const ceilingError = formState.errors.netProceedsCeiling?.message;

  const {
    canSetPrice,
    autoPricingEnabled,
    uiCurrency,
    compListingMode,
    skipPriceProtection,
  } = useListingAutoPricingSettings(listing, isBulkEdit);

  const turnOffSkipSetting = useCallback(
    () => setValue('skipPriceProtection', false),
    [setValue]
  );

  const priceProtectionDisabled =
    disabled ||
    listing?.isDeleted ||
    !canSetPrice ||
    Boolean(
      autoPricingEnabled &&
        compListingMode === AutoPricingCompListingMode.ParentListing
    );

  const adjustListPriceToBeWithinFloorAndCeiling = useCallback(() => {
    if (listPrice != null) {
      if (netProceedsFloor != null && listPrice < netProceedsFloor) {
        setValue('listPrice', netProceedsFloor);
      } else if (netProceedsCeiling != null && listPrice > netProceedsCeiling) {
        setValue('listPrice', netProceedsCeiling);
      }
    }
    marketplacePriceUpdates
      .filter((m) => m.priceByMarketplace)
      .forEach((m, i) => {
        if (m.listPrice != null) {
          if (netProceedsFloor != null && m.listPrice < netProceedsFloor) {
            setValue(
              `marketplacePriceUpdates.${i}.listPrice`,
              netProceedsFloor
            );
          } else if (
            netProceedsCeiling != null &&
            m.listPrice > netProceedsCeiling
          ) {
            setValue(
              `marketplacePriceUpdates.${i}.listPrice`,
              netProceedsCeiling
            );
          }
        }
      });
  }, [
    listPrice,
    marketplacePriceUpdates,
    netProceedsCeiling,
    netProceedsFloor,
    setValue,
  ]);

  const { value: priceFloorMode, setUserSetting: setPriceFloorMode } =
    useServerUserSetting<PriceFloorCeilingMode>({
      id: UserSetting.PriceFloorCeilingMode,
      currentLoginUserOnly: true,
    });

  const onPriceFloorChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      turnOffSkipSetting();
      const v = parseFloat(e.target.value);
      if (v >= 0 && v < Number.MAX_VALUE) {
        clearErrors('listPrice');
        marketplacePriceUpdates?.forEach((_, i) =>
          clearErrors(`marketplacePriceUpdates.${i}.listPrice`)
        );
        if (netProceedsCeiling && v < netProceedsCeiling) {
          clearErrors('netProceedsFloor');
        }

        let newV = v;
        if (priceFloorMode === PriceFloorCeilingMode.Website) {
          // If we're editing in WebSite mode, we need to convert it to proceeds
          newV = getListPriceFromAllinPrice(
            v,
            listing,
            stubHubMkpSettings?.sellerFee
          )!;
        }

        setValue('netProceedsFloor', newV);
      } else {
        setValue('netProceedsFloor', null);
      }
    },
    [
      turnOffSkipSetting,
      clearErrors,
      marketplacePriceUpdates,
      netProceedsCeiling,
      priceFloorMode,
      setValue,
      listing,
      stubHubMkpSettings?.sellerFee,
    ]
  );

  const onPriceCeilingChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      turnOffSkipSetting();
      const v = parseFloat(e.target.value);
      if (v >= 0 && v <= Number.MAX_VALUE) {
        clearErrors('listPrice');
        marketplacePriceUpdates?.forEach((_, i) =>
          clearErrors(`marketplacePriceUpdates.${i}.listPrice`)
        );
        if (netProceedsFloor && v > netProceedsFloor) {
          clearErrors('netProceedsCeiling');
        }

        let newV = v;
        if (priceFloorMode === PriceFloorCeilingMode.Website) {
          // If we're editing in WebSite mode, we need to convert it to proceeds
          newV = getListPriceFromAllinPrice(
            v,
            listing,
            stubHubMkpSettings?.sellerFee
          )!;
        }

        setValue('netProceedsCeiling', newV);
      } else {
        setValue('netProceedsCeiling', null);
      }
    },
    [
      turnOffSkipSetting,
      clearErrors,
      marketplacePriceUpdates,
      netProceedsFloor,
      priceFloorMode,
      setValue,
      listing,
      stubHubMkpSettings?.sellerFee,
    ]
  );

  return (
    <>
      <DetailSection name={<Content id={ContentId.PricingProtection} />}>
        {isBulkEdit ? (
          <Checkbox
            label={<Content id={ContentId.ApplyPricingProtectionSettings} />}
            labelPosition="right"
            onChange={(e) => setValue('skipPriceProtection', !e.target.checked)}
            checked={!skipPriceProtection}
          />
        ) : undefined}

        <SectionContent numOfColumns={1}>
          <DetailGroup style={{ gridColumn: '1' }}>
            <PosFormField label={<Content id={ContentId.EnterValuesFor} />}>
              <RadioGroup
                value={priceFloorMode}
                defaultValue={PriceFloorCeilingMode.Proceeds}
                onValueChange={setPriceFloorMode}
                disabled={priceProtectionDisabled}
              >
                <Stack direction="column" gap="m">
                  {PRICE_FLOOR_CEILING_MODE_OPTIONS.map((m) => (
                    <Radio
                      key={m.value}
                      value={m.value}
                      label={m.children}
                      disabled={priceProtectionDisabled}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </PosFormField>
          </DetailGroup>

          <DetailGroup style={{ gridColumn: '1' }}>
            <Stack gap="l" alignItems="center">
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.DontPriceBelow} />
              </div>
              <PosFormField
                errors={floorError}
                style={{ width: 'fit-content' }}
              >
                <PosCurrencyField
                  rootProps={{
                    state: getTextFieldState(floorError),
                    disabled: priceProtectionDisabled,
                  }}
                  placeholder={
                    isBulkEdit && netProceedsFloor == -1
                      ? dontChangeText
                      : undefined
                  }
                  disabled={priceProtectionDisabled}
                  uiCurrency={uiCurrency}
                  value={
                    isBulkEdit && netProceedsFloor == -1
                      ? undefined
                      : (priceFloorMode === PriceFloorCeilingMode.Website
                          ? getAllInPriceFromListPrice(
                              netProceedsFloor,
                              listing,
                              stubHubMkpSettings?.sellerFee
                            )
                          : netProceedsFloor) ?? ''
                  }
                  onChange={onPriceFloorChange}
                  onBlur={adjustListPriceToBeWithinFloorAndCeiling}
                />
              </PosFormField>
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.OrAbove} />
              </div>
              <PosFormField
                errors={ceilingError}
                style={{ width: 'fit-content' }}
              >
                <PosCurrencyField
                  rootProps={{
                    state: getTextFieldState(ceilingError),
                    disabled: priceProtectionDisabled,
                  }}
                  placeholder={
                    isBulkEdit && netProceedsCeiling == -1
                      ? dontChangeText
                      : undefined
                  }
                  disabled={priceProtectionDisabled}
                  uiCurrency={uiCurrency}
                  value={
                    isBulkEdit && netProceedsCeiling == -1
                      ? undefined
                      : (priceFloorMode === PriceFloorCeilingMode.Website
                          ? getAllInPriceFromListPrice(
                              netProceedsCeiling,
                              listing,
                              stubHubMkpSettings?.sellerFee
                            )
                          : netProceedsCeiling) ?? ''
                  }
                  onChange={onPriceCeilingChange}
                  onBlur={adjustListPriceToBeWithinFloorAndCeiling}
                />
              </PosFormField>
            </Stack>
          </DetailGroup>

          {isBulkEdit &&
            ((netProceedsFloor ?? 0) > 0 || (netProceedsCeiling ?? 0) > 0) && (
              <DetailGroup style={{ gridColumn: '1 / span 2' }}>
                <WarningMessage
                  message={
                    <Content id={ContentId.BulkEditPriceFloorCeilingWarning} />
                  }
                />
              </DetailGroup>
            )}
        </SectionContent>
      </DetailSection>
    </>
  );
};
