import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const MobileQRIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 41 41" fill="none">
      <path d="M0.5 13V0.5H12.5" stroke="currentColor" strokeWidth="4" />
      <path d="M28 0.5H40.5V13" stroke="currentColor" strokeWidth="4" />
      <path d="M0.5 28V40.5H13" stroke="currentColor" strokeWidth="4" />
      <path d="M28 40.5H40.5V28" stroke="currentColor" strokeWidth="4" />
      <rect
        x="6.5"
        y="12.5"
        width="28"
        height="20"
        rx="3.5"
        stroke="currentColor"
        strokeWidth="3"
      />
      <path
        d="M18 8.5H23C24.933 8.5 26.5 10.067 26.5 12V12.5H14.5V12C14.5 10.067 16.067 8.5 18 8.5Z"
        stroke="currentColor"
        strokeWidth="3"
      />
      <circle cx="20.5" cy="22.5" r="7" stroke="currentColor" strokeWidth="3" />
    </SvgWrapper>
  );
};
