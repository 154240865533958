import { useCallback } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { BulkEditMarketplaceSeatingDialog } from 'src/dialogs/BulkEdits/BulkEditMarketplaceSeatingDialog/BulkEditMarketplaceSeatingDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  ListingDetails,
  ListingDetailsUpdateInput,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_MARKETPLACE_SEATING_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchUpdateMarketplaceSeating } from './LaunchEditMarketplaceSeating';

export const useBulkEditMarketplaceSeatingAction = (
  filterQueryWithEventIds: ListingQuery,

  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = event
    ? `marketplace-seating-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_MARKETPLACE_SEATING_UPDATE_KEY;
  const {
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();

  const {
    openDialog: openBulkEditMarketplaceSeatingDialog,
    closeDialog: closeBulkEditMarketplaceSeatingDialog,
  } = useDialog(
    DialogId.BulkEditMarketplaceSeating,
    BulkEditMarketplaceSeatingDialog
  );
  const onSubmit = useCallback(
    async (
      listingUpdate: ListingDetailsUpdateInput | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateMarketplaceSeatingOverridesPreview(
              filterQueryWithEventIds
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog(
              'bulkUpdateMarketplaceSeatingOverridesPreview',
              error,
              {
                trackErrorData: { filterQueryWithEventIds },
              }
            );
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (listingUpdate) {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateMarketplaceSeatingOverrides(
              {
                item1: preview!.preview,
                item2: listingUpdate,
              },
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }
              closeBulkEditMarketplaceSeatingDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateMarketplaceSeatingOverrides', error, {
              trackErrorData: { listingUpdate, preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQueryWithEventIds,
      showErrorDialog,
      updateKey,
      closeBulkEditMarketplaceSeatingDialog,
      setActivePosEntity,
      refreshExpandedListItems,
    ]
  );

  return {
    dropDown: (
      <LaunchUpdateMarketplaceSeating
        key="MarketplaceSeating"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (event) {
            setListItemExpansion(true, [event.viagVirtualId]);
          }
          openBulkEditMarketplaceSeatingDialog({
            updateKey,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkEditMarketplaceSeatingDialog,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
