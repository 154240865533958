import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { UserSetting } from 'src/WebApiController';

import { AlertSuppressionSetting } from './AlertWithSuppressionDialog';

export function useAlertWithSuppressionDialog(
  key: keyof AlertSuppressionSetting,
  onOkay?: () => void,
  onCancel?: () => void
) {
  const { value } = useServerUserSetting<AlertSuppressionSetting>({
    id: UserSetting.SuspiciousPriceChange,
  });
  return {
    value,
    key,
    onOkay,
    onCancel,
  };
}
