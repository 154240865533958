import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';

import {
  usePurchaseVendorAccountSelector,
  UsePurchaseVendorAccountSelectorOptions,
} from './usePurchaseVendorAccountSelector';

export type PurchaseVendorAccountSelectorProps = Omit<
  PosSelectProps,
  'valueOptionsContent'
> &
  UsePurchaseVendorAccountSelectorOptions;

export function PurchaseVendorAccountSelector({
  vendorId,
  disabled,
  additionalOptions,
  additionalOptionsPosition,
  placeholderText,
  ...props
}: PurchaseVendorAccountSelectorProps) {
  const { query, posSelectProps } = usePurchaseVendorAccountSelector({
    vendorId,
    disabled,
    additionalOptions,
    additionalOptionsPosition,
    placeholderText,
  });

  const { isFetchingNextPage, hasNextPage, fetchNextPage } = query;
  return (
    <PosSelect
      {...props}
      disabled={disabled}
      {...posSelectProps}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      isFetchingNextPage={isFetchingNextPage}
    />
  );
}
