import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Content,
  getContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { useSellerRoleContext } from 'src/contexts/SellerRoleContext';
import { Switch } from 'src/core/interim/Switch';
import { DividerLine, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PERMISSION_ACCESS_AREA_TO_CID,
  PERMISSION_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  Permission,
  PermissionAccessArea,
  SellerRoleInput,
} from 'src/WebApiController';

import * as styles from '../SellerRoleModal.css';
import { SellerPermissionDisplay } from './SellerPermissionDisplay';

export const SellerPermissionSelectionSection = ({
  area,
  disabled,
}: {
  area: PermissionAccessArea;
  disabled?: boolean;
}) => {
  const { watch, setValue, clearErrors } = useFormContext<SellerRoleInput>();
  const permissions = watch('permissions');
  const contentContext = useContentContext();

  const { allPermissions } = useSellerRoleContext();
  const permissionInfosOfArea = Object.values(allPermissions ?? {})
    .filter((p) => p.area === area)
    .sort((a, b) =>
      (
        getContent(PERMISSION_TO_CID[a.permission][0], contentContext) ??
        a.permission
      ).localeCompare(
        getContent(PERMISSION_TO_CID[b.permission][0], contentContext) ??
          b.permission
      )
    );
  const permissionsOfArea = permissionInfosOfArea.map((pa) => pa.permission);

  const allSelected = permissionsOfArea.every(
    (pa) => permissions?.includes(pa)
  );

  const onEnableAllForArea = useCallback(
    (checkAll: boolean) => {
      const permissionsNotInArea =
        permissions?.filter((p) => !permissionsOfArea.includes(p)) ?? [];
      if (checkAll) {
        clearErrors('permissions');
        setValue('permissions', [
          ...permissionsNotInArea,
          ...permissionsOfArea,
        ]);
      } else {
        setValue('permissions', permissionsNotInArea);
      }
    },
    [clearErrors, permissions, permissionsOfArea, setValue]
  );

  const onTogglePermissionSelect = useCallback(
    (permission: Permission, selected: boolean) => {
      const permissionsExcluding =
        permissions?.filter((p) => p !== permission) ?? [];
      if (selected) {
        clearErrors('permissions');
        setValue('permissions', [...permissionsExcluding, permission]);
      } else {
        setValue('permissions', permissionsExcluding);
      }
    },
    [clearErrors, permissions, setValue]
  );

  if (permissionsOfArea.length === 0) {
    return null;
  }

  return (
    <Stack direction="column" width="full" gap="l">
      <Stack gap="l" alignItems="center" justifyContent="spaceBetween">
        <span className={styles.permissionArea}>
          <Content id={PERMISSION_ACCESS_AREA_TO_CID[area]} />
        </span>
        <Stack
          gap="m"
          alignItems="center"
          style={{ cursor: disabled ? 'default' : 'pointer' }}
          onClick={
            disabled
              ? undefined
              : (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onEnableAllForArea(!allSelected);
                }
          }
        >
          {!disabled && (
            <>
              <Switch
                checked={allSelected}
                disabled={disabled}
                onChange={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onEnableAllForArea(!allSelected);
                }}
              />
              <Content id={ContentId.EnableAll} />
            </>
          )}
        </Stack>
      </Stack>
      <div className={styles.permissionAreaContainer}>
        <Stack
          direction="column"
          gap="m"
          width="full"
          className={styles.permissionAreaParts}
        >
          <span className={styles.permissionVerb}>
            <Content id={ContentId.View} />
          </span>
          {permissionInfosOfArea.map((pa) => {
            const isSelected = permissions?.includes(pa.permission);
            return (
              <SellerPermissionDisplay
                disabled={disabled}
                isSelected={isSelected}
                toggleSelect={() =>
                  onTogglePermissionSelect(pa.permission, !isSelected)
                }
                key={pa.permission}
                permissionInfo={pa}
                part="view"
              />
            );
          })}
        </Stack>
        <Stack
          direction="column"
          gap="m"
          width="full"
          className={styles.permissionAreaParts}
        >
          <span className={styles.permissionVerb}>
            <Content id={ContentId.Actions} />
          </span>
          {permissionInfosOfArea.map((pa) => {
            const isSelected = permissions?.includes(pa.permission);
            return (
              <SellerPermissionDisplay
                disabled={disabled}
                isSelected={isSelected}
                toggleSelect={() =>
                  onTogglePermissionSelect(pa.permission, !isSelected)
                }
                key={pa.permission}
                permissionInfo={pa}
                part="action"
              />
            );
          })}
        </Stack>
      </div>
      <DividerLine />
    </Stack>
  );
};
