import { range } from 'lodash-es';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { TableVirtuoso } from 'react-virtuoso';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { SimpleTable, Stack } from 'src/core/ui';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { GroupingType } from 'src/WebApiController';

import * as styles from '../../GroupListings.css';
import {
  GroupingTemplate,
  GroupingTemplateSeating,
  ListingGroupBy,
} from '../groupingTypes';

export const GroupTemplateTable = ({
  groupBy,
  template,
  onTemplateChange,
  allowDelete,
  style,
}: {
  groupBy: ListingGroupBy;
  template: GroupingTemplate;
  allowDelete: boolean;
  onTemplateChange: (
    groupTemplate: GroupingTemplate,
    removed?: boolean
  ) => void;
  style?: React.CSSProperties;
}) => {
  const assignNewPriority = useCallback(
    (newPriority: number, key: string) => {
      // First find the element that already has this priority
      const indexOfExisting = template.seatings.findIndex(
        (l) => l.priority === newPriority
      );
      // Now find the index of item
      const indexOfItem = template.seatings.findIndex((l) => l.id === key);

      if (indexOfExisting < indexOfItem) {
        // Priority changed to higher (lower index), so we need to start at indexOfListing and go to up to indexOfItem
        for (let i = indexOfExisting; i < indexOfItem; i++) {
          const l = template.seatings[i];
          l.priority = l.priority! + 1; // shift 1 forward
        }
        template.seatings[indexOfItem].priority = newPriority; // set new priority on target listing

        // Save the new listing (ordered by priority)
        onTemplateChange({ ...template });
      } else if (indexOfExisting > indexOfItem) {
        // Priority changed to lower (higher index), so we need to start at indexOfExisting and go down to indexOfItem
        for (let i = indexOfExisting; i > indexOfItem; i--) {
          const l = template.seatings[i];
          l.priority = l.priority! - 1; // shift 1 back
        }

        template.seatings[indexOfItem].priority = newPriority; // set new priority on target listing

        // Save the new listing (ordered by priority)
        onTemplateChange({ ...template });
      } else {
        // There is no change, just return
        return;
      }
    },
    [onTemplateChange, template]
  );

  const priorityOptions = useMemo(
    () =>
      range(template.seatings.length).reduce(
        (r, i) => {
          r[(i + 1).toString()] = (i + 1).toString();
          return r;
        },
        {} as Record<string, string>
      ),
    [template.seatings.length]
  );

  const requiredMsg = useContent(ContentId.Required);
  const error = template.groupName ? undefined : requiredMsg;

  const renderHeaderContent = useCallback(() => {
    return (
      <SimpleTable.Tr style={{ backgroundColor: vars.color.backgroundPrimary }}>
        <SimpleTable.Th
          className={styles.tableCell}
          style={{ width: '0%' }} // this makes this column width fit-content
        >
          <Content id={ContentId.Rank} />
        </SimpleTable.Th>
        <SimpleTable.Th>
          <Content
            id={
              groupBy.groupingType === GroupingType.Quantity
                ? ContentId.UnsoldQuantity
                : groupBy.groupingType === GroupingType.UnitCost
                ? ContentId.UnitCost
                : ContentId.Seating
            }
          />
        </SimpleTable.Th>
        <SimpleTable.Th>
          <Content id={ContentId.Actions} />
        </SimpleTable.Th>
      </SimpleTable.Tr>
    );
  }, [groupBy.groupingType]);

  const renderRowContent = useCallback(
    (seat: GroupingTemplateSeating) => {
      return (
        <>
          <SimpleTable.Td
            className={styles.tableCell}
            style={{ width: '0%' }} // this makes this column width fit-content
          >
            <PosSelect
              style={{ width: '100%' }}
              value={seat.priority!.toString()}
              size="md"
              contentProps={{ align: 'end' }}
              onChange={(newPriorityString: string | null) => {
                if (newPriorityString) {
                  const newPriority = parseInt(newPriorityString);
                  assignNewPriority(newPriority, seat.id.toString());
                }
              }}
              valueOptionsContent={priorityOptions}
            />
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}>
            {groupBy.groupingType === GroupingType.Quantity ? (
              seat.availQty
            ) : groupBy.groupingType === GroupingType.UnitCost ? (
              seat.unitCst?.disp ??
              seat.unitCst?.amt ??
              seat.faceValue?.disp ??
              seat.faceValue?.amt
            ) : (
              <SeatingInfo {...seat.seating} seatFr={null} seatTo={null} />
            )}
          </SimpleTable.Td>
          <SimpleTable.Td
            className={styles.tableCell}
            style={{ width: '0%' }} // this makes this column width fit-content
          >
            <CrossIcon
              withHoverEffect
              onClick={() => {
                template.seatings = template.seatings.filter(
                  (s) => s.id !== seat.id
                );
                onTemplateChange({ ...template });
              }}
            />
          </SimpleTable.Td>
        </>
      );
    },
    [
      assignNewPriority,
      groupBy.groupingType,
      onTemplateChange,
      priorityOptions,
      template,
    ]
  );

  const templateSeatingsData = useMemo(
    () => template.seatings.sort((s1, s2) => s1.priority! - s2.priority!),
    [template.seatings]
  );

  return (
    <Stack direction="column" gap="s" style={style}>
      <Stack direction="row" gap="l" alignItems="center">
        <PosFormField
          label={<Content id={ContentId.Name} />}
          errors={error}
          style={{ flex: '1' }}
        >
          <PosTextField
            rootProps={{
              state: getTextFieldState(error),
            }}
            value={template.groupName}
            onChange={(e) => {
              if (e.target.value !== template.groupName) {
                template.groupName = e.target.value;
                onTemplateChange({ ...template });
              }
            }}
          />
        </PosFormField>
        <PosFormField
          label={<Content id={ContentId.DesiredActiveListings} />}
          style={{ flex: '0.5' }}
        >
          <PosTextField
            value={template.desiredActiveListings ?? '0'}
            type="number"
            min={0}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const v = parseInt(e.target.value);
              if (v >= 0 && v !== template.desiredActiveListings) {
                template.desiredActiveListings = v;
                onTemplateChange({ ...template });
              }
            }}
          />
        </PosFormField>
        {allowDelete && (
          <CrossIcon
            onClick={() => onTemplateChange(template, true)}
            withHoverEffect
          />
        )}
      </Stack>
      <div className={styles.tableContainerVirtuoso}>
        <TableVirtuoso
          data={templateSeatingsData}
          style={{ width: '100%', height: '100%' }}
          overscan={{ main: 500, reverse: 500 }}
          components={{
            Table: SimpleTable.Table,
            TableHead: SimpleTable.Thead,
            TableBody: SimpleTable.Tbody,
            TableRow: SimpleTable.Tr,
          }}
          fixedHeaderContent={renderHeaderContent}
          itemContent={(index, seat) => {
            return renderRowContent(seat);
          }}
        />
      </div>
    </Stack>
  );
};
