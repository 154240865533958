import { useState } from 'react';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useUpdatePurchasesPermissions } from 'src/components/Purchases/BulkActions/useUpdatePurchasesPermissions';
import { useUpdateSalesSettingsPermissions } from 'src/components/Sales/BulkActions/useUpdateSalesSettingsPermissions';
import { useBulkEditCommissionAction } from 'src/components/Sales/SalesActionDropdown/components/BulkEditCommissionAction';
import { useBulkUpdateInternalNotesAction } from 'src/components/Sales/SalesActionDropdown/components/BulkUpdateInternalNotesAction';
import { useBulkUpdateSalesSettings } from 'src/components/Sales/SalesActionDropdown/components/BulkUpdateSalesGeneralSettings';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { EventWithData } from 'src/WebApiController';

import { useSalesBulkActionsState } from '../../useSalesBulkActionsState';

interface SettingsActionsProps {
  event: EventWithData['event'];
}

export const SettingsActions = ({ event }: SettingsActionsProps) => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const [isLoading, setIsLoading] = useState(false);
  const { filterQueryWithEventIds } = useSalesBulkActionsState(event);

  const bulkUpdateGeneralSettings = useBulkUpdateSalesSettings(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event
  );

  const bulkUpdateInternalNotes = useBulkUpdateInternalNotesAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event
  );

  const bulkEditCommissionAction = useBulkEditCommissionAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event
  );

  const { hasUpdateSalesSettingsPermission } =
    useUpdateSalesSettingsPermissions();
  const {
    hasUpdatePurchasesPermission,
    hasPurchaseUpdateCommissionPermission,
  } = useUpdatePurchasesPermissions();

  if (
    !hasUpdateSalesSettingsPermission &&
    !hasUpdatePurchasesPermission &&
    !hasPurchaseUpdateCommissionPermission
  ) {
    return null;
  }

  return (
    <>
      <PosDropdown
        key="sales-settings-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Settings} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {bulkUpdateGeneralSettings.dropDown}
        {bulkUpdateInternalNotes.dropDown}
        {bulkEditCommissionAction.dropDown}
      </PosDropdown>
    </>
  );
};
