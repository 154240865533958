import { useBulkUpdateInternalNotesPermissions } from 'src/components/common/BulkActions/BulkUpdateInternalNotesAction/useBulkUpdateInternalNotesPermissions';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType, Feature } from 'src/WebApiController';

export const LaunchBulkUpdateInternalNotes = ({
  onClick,
  disabled,
  entityType,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.Purchase;
}) => {
  const { hasEditInternalNotesPermissions } =
    useBulkUpdateInternalNotesPermissions({
      entityType,
    });

  const hasBulkUpdateInternalNotesFeature = useUserHasFeature(
    Feature.BulkUpdateInternalNotes
  );

  if (!hasEditInternalNotesPermissions || !hasBulkUpdateInternalNotesFeature) {
    return null;
  }

  return (
    <PosDropdownItem
      key="UpdatePrivateNotes"
      onClick={onClick}
      disabled={disabled}
    >
      <Content id={ContentId.UpdatePrivateNotes} />
    </PosDropdownItem>
  );
};
