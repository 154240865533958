import type { ThemeMediaQuery } from '../../theme-types';

const makeMediaQuery = (size: number, args: string): string => `
    @media only screen and (min-width: ${size}px) {
        ${args}
    }
`;

const breakpoints = {
  xs: 0,
  sm: 360,
  md: 600, // px 1: Small (phone) 600-919 medium , 920-1199 medium large
  ml: 920, // px 2: Medium (tablet)
  lg: 1200, // px 3: Large (laptop/desktop)/4: Extra-Large (Widescreen)
  xl: 2100,
};

const phone = `
    only screen and (min-width: ${0}px) and (max-width: ${breakpoints.md - 1}px)
`;

const tablet = `
    only screen and (min-width: ${breakpoints.md}px) and (max-width: ${
      breakpoints.ml - 1
    }px)
`;

const desktop = `
    only screen and (min-width: ${breakpoints.ml}px)  
`;

const xlDesktop = `
    only screen and (min-width: ${breakpoints.xl}px)  
`;

export const media: ThemeMediaQuery = {
  breakpoints,
  makeMediaQuery,
  phone,
  tablet,
  desktop,
  xlDesktop,
};
