import { useState } from 'react';
import { BulkEditTagsActionPermissionsHandler } from 'src/components/common/BulkActions/BulkEditTagsAction/BulkEditTagsActionPermissionsHandler';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { usePurchaseTileBulkActionsState } from 'src/components/Purchases/BulkActions/PurchasesTileBulkActions/usePurchaseTileBulkActionsState';
import { useBulkEditTagsAction } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkEditTagsAction';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType } from 'src/WebApiController';

import { PurchasesBulkActionProps } from '../../PurchasesGlobalBulkActions/PurchasesBulkActions.types';

type TagsActionsProps = {
  groupId: string;
};

export const TagsActions = ({
  groupId,
  isEventView,
}: TagsActionsProps & PurchasesBulkActionProps) => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const [isLoading, setIsLoading] = useState(false);

  const { filterQueryWithEventIds } = usePurchaseTileBulkActionsState(
    groupId,
    isEventView
  );

  const bulkEditTags = useBulkEditTagsAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    groupId
  );

  if (isEventView) {
    return null;
  }

  return (
    <BulkEditTagsActionPermissionsHandler
      entityType={ActionOutboxEntityType.Purchase}
    >
      <PosDropdown
        key="purchases-tags-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Tags} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {bulkEditTags.dropDown}
      </PosDropdown>
    </BulkEditTagsActionPermissionsHandler>
  );
};
