import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  ListingDetails,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';
import * as styles from './BulkEditListingPriceDialog.css';
import { FieldWrapper } from './BulkEditListingPriceDialog.styled';

export type BulkEditListingPriceDialogProps = ComponentProps<typeof RSModal> & {
  updateKey: string;
  increasePrice: boolean;
  onOkay: (
    priceChangePercentage: number | undefined,
    priceChangeAbsolute: number | undefined,
    increasePrice: boolean,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

export enum PriceChangeMode {
  Percent = 'Percent',
  Absolute = 'Absolute',
}

export const PRICE_CHANGE_MODE_OPTIONS = [
  {
    value: PriceChangeMode.Percent,
    children: <Content id={ContentId.Percentage} />,
  },
  {
    value: PriceChangeMode.Absolute,
    children: <Content id={ContentId.AbsoluteAmount} />,
  },
];

export function BulkEditListingPriceDialog({
  updateKey,
  increasePrice,
  onOkay,
  onCancel,
  ...rest
}: BulkEditListingPriceDialogProps) {
  const { getUiCurrency } = useLocalizationContext();

  const { loginContext } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();

  const uiCurrency = useMemo(
    () => getUiCurrency(loginContext?.user?.activeAccount?.currencyCode),
    [getUiCurrency, loginContext?.user?.activeAccount?.currencyCode]
  );

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshExpandedListItems();
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.UpdatePrices,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const [priceChangeMode, setPriceChangeMode] = useState(
    PriceChangeMode.Absolute
  );
  const [changeValue, setChangeValue] = useState<number>();

  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(undefined, undefined, false, false, onPreviewReceived);
      } else {
        onOkay(
          priceChangeMode === PriceChangeMode.Percent ? changeValue : undefined,
          priceChangeMode === PriceChangeMode.Absolute
            ? changeValue
            : undefined,
          increasePrice,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [
      onOkay,
      priceChangeMode,
      changeValue,
      increasePrice,
      preview,
      hasBackgroundBulkEditFeature,
    ]
  );

  const isInputValid = useMemo(() => {
    return !!changeValue;
  }, [changeValue]);

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
      header={
        <BulkEditHeader
          headerText={
            increasePrice ? (
              <Content id={ContentId.IncreasePrice} />
            ) : (
              <Content id={ContentId.DecreasePrice} />
            )
          }
        />
      }
      onOpened={() => {
        setPriceChangeMode(PriceChangeMode.Absolute);
        setChangeValue(undefined);
        initJob();
      }}
      onKeyUp={(e) => {
        if (submittButtonRef.current && e.key === 'Enter' && isInputValid) {
          submittButtonRef.current.click();
        }
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={ActionOutboxEntityType.Listing}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          disabled={!isInputValid}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={ActionOutboxEntityType.Listing}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack
          direction="column"
          gap="l"
          className={styles.bulkEditListingPriceDialogContainer}
        >
          <PosFormField
            label={
              <span>
                <Content id={ContentId.AdjustPricesBy} />
                {': '}
              </span>
            }
          >
            <RadioGroup
              value={priceChangeMode}
              defaultValue={PriceChangeMode.Percent}
              onValueChange={(newMode: string) => {
                setPriceChangeMode(newMode as PriceChangeMode);
                if (newMode == PriceChangeMode.Percent) {
                  if (!increasePrice && (changeValue ?? 0) > 99) {
                    setChangeValue(99); // we can't decrease by 100 or more % because that will result in zero
                  }
                }
              }}
              disabled={isLoading}
            >
              <Stack direction="column" gap="m">
                {PRICE_CHANGE_MODE_OPTIONS.map((m) => (
                  <Radio
                    key={m.value}
                    value={m.value}
                    label={m.children}
                    disabled={isLoading}
                  />
                ))}
              </Stack>
            </RadioGroup>
          </PosFormField>
          {priceChangeMode === PriceChangeMode.Percent ? (
            <PosTextField
              value={changeValue}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              disabled={isLoading}
              min={0}
              type="number"
              inputMode="numeric"
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (value < 0) return;
                if (!increasePrice && value > 99) return; // we can't decrease by 100 or more % because that will result in zero

                setChangeValue(parseFloat(e.target.value));
              }}
              postfixDisplay={'%'}
            />
          ) : (
            <PosCurrencyField
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              disabled={isLoading}
              uiCurrency={uiCurrency}
              value={changeValue}
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                if (value < 0) return;

                setChangeValue(parseFloat(e.target.value));
              }}
            />
          )}
          <FieldWrapper>
            <WarningMessage
              message={<Content id={ContentId.BulkEditPricesWarning} />}
            />
          </FieldWrapper>
        </Stack>
      </BulkEditStatus>
    </GenericDialog>
  );
}
