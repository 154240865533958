import clsx from 'clsx';
import { MouseEvent, ReactNode, useMemo } from 'react';
import { useToggle } from 'react-use';
import { ExpandCollapsableContentButton } from 'src/components/common/CollapsableContent/ExpandCollapsableContentButton';
import { collapsedRow } from 'src/components/Events/EventListing/InventoryEventListing/InventoryFlattenedView.css';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content } from 'src/contexts/ContentContext';
import { InputPriceFocusProvider } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { ListingTableFlattened } from 'src/tables/ListingTable/ListingTableFlattened';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { getEventPerformerVenue } from 'src/utils/eventWithDataUtils';
import { SectionType } from 'src/utils/types/sectionType';

import * as styles from './InventoryFlattenedView.css';

type InventoryFlattenedViewProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
  /**
   * If provided, the list will scroll to the month containing the event with the given posEventId
   */
  scrollToEventId?: string;
};

export const InventoryFlattenedView = ({
  before,
  topListItemBefore,
}: InventoryFlattenedViewProps) => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const { isCollapsedView, setIsCollapsedView } = useCollapsableViewContext();

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  const { data: catalog, eventsTransformed } = useCatalogDataContext();
  const { listingCount, ungroupedListingCount, listingGroupCount } =
    useMemo(() => {
      let listingCount = 0;
      let ungroupedListingCount = 0;
      let listingGroupCount = 0;

      (eventsTransformed ?? []).forEach((ev) => {
        listingCount += ev.listCnt;
        listingGroupCount += ev.listGrpCnt;
        ungroupedListingCount += ev.ungrListCnt;
      });

      return { listingCount, ungroupedListingCount, listingGroupCount };
    }, [eventsTransformed]);

  const listings = useMemo(() => {
    const listingsArray = eventsTransformed?.every((ev) => ev.listings == null)
      ? null
      : eventsTransformed?.flatMap((ev) => ev.listings);
    return listingsArray
      ?.map((l) => {
        const { event, performer, venue } = getEventPerformerVenue(
          l?.viagVirtualId,
          catalog
        );

        if (event) {
          return { event: event.event, listing: l, performer, venue };
        }

        return null;
      })
      .filter((l) => l != null)
      .map((l) => l!);
  }, [catalog, eventsTransformed]);

  return (
    <Stack direction="column" height="full" width="full">
      {isColumnModalOpen ? (
        <ColumnSettingsModal<CustomListingColumn>
          onClose={toggleColumnModal}
          sectionType={SectionType.ListingsFlattened}
        />
      ) : null}
      <div
        className={clsx({
          [styles.metricsAnimationUncollapsed]: !isCollapsedView,
          [styles.metricsAnimationCollapsed]: isCollapsedView,
        })}
      >
        {before}
      </div>
      {topListItemBefore}
      <Stack
        justifyContent="end"
        alignItems="center"
        className={clsx({
          [collapsedRow]: isCollapsedView,
        })}
      >
        {isCollapsedView && (
          <ExpandCollapsableContentButton
            onClick={() => setIsCollapsedView(false)}
          />
        )}
        <Button variant="textPlain" onClick={onColumnSettingButtonClickHandler}>
          <LayoutIcon size={vars.iconSize.m} />
          <Content id={ContentId.Columns} />
        </Button>
      </Stack>
      <InputPriceFocusProvider disablePagination pageSize={listingCount}>
        <ListingTableFlattened
          useVirtuoso
          disablePagination
          listingCount={listingCount}
          ungroupedListingCount={ungroupedListingCount}
          listingGroupCount={listingGroupCount}
          listings={listings}
          failedToRetrieveData={false}
        />
      </InputPriceFocusProvider>
    </Stack>
  );
};
