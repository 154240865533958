import { RefObject, useLayoutEffect, useRef, useState } from 'react';

interface OverflowX {
  ref: RefObject<HTMLDivElement>;
  isOverflowX: boolean;
}

/**
 * Hook to check if an element has overflow-x
 */
export const useOverflowX = (): OverflowX => {
  const [isOverflowX, setIsOverflowX] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const { current } = ref;

    const resizeObserver = new ResizeObserver(() => {
      if (current) {
        const hasOverflowX = current.scrollWidth > current.clientWidth;
        if (hasOverflowX !== isOverflowX) {
          setIsOverflowX(hasOverflowX);
        }
      }
    });

    if (current) {
      resizeObserver.observe(current);
      const hasOverflowX = current.scrollWidth > current.clientWidth;
      if (hasOverflowX !== isOverflowX) {
        setIsOverflowX(hasOverflowX);
      }
    }

    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  return { ref, isOverflowX };
};
