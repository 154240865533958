import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  mediaQuery,
  MediaQueryType,
  useMatchMediaQuery,
} from 'src/hooks/useMatchMedia';

type ScreenSizeState = Record<MediaQueryType, boolean>;

const screenSizeState: ScreenSizeState = {
  mobile: false,
  tablet: false,
  desktop: false,
  largeDesktop: false,
};

export const ScreenSizeContext =
  createContext<ScreenSizeState>(screenSizeState);

export const useScreenSizeContent = () => useContext(ScreenSizeContext);

export const ScreenSizeProvider = ({ children }: { children: ReactNode }) => {
  const [screenSiseState, setScreenSiseState] =
    useState<ScreenSizeState>(screenSizeState);

  const isMobile = useMatchMediaQuery(mediaQuery.mobile);
  const isTablet = useMatchMediaQuery(mediaQuery.tablet);
  const isDesktop = useMatchMediaQuery(mediaQuery.desktop);
  const isLargeDesktop = useMatchMediaQuery(mediaQuery.largeDesktop);

  useEffect(() => {
    setScreenSiseState({
      mobile: isMobile,
      tablet: isTablet,
      desktop: isDesktop,
      largeDesktop: isLargeDesktop,
    });
  }, [isMobile, isTablet, isDesktop, isLargeDesktop]);

  return (
    <ScreenSizeContext.Provider value={screenSiseState}>
      {children}
    </ScreenSizeContext.Provider>
  );
};
