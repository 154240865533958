import {
  Seating,
  SectionInfo,
  VenueMapInfo,
  VenueMapSectionType,
} from 'src/WebApiController';

export const isSeatingMatchSectionInfo = (
  section: SectionInfo,
  sectionText?: string | null,
  rowId?: number | null
) => {
  return (
    sectionText?.toLocaleUpperCase() === section.name.toLocaleUpperCase() ||
    section.rows.some((r) => r.id === rowId)
  );
};

export const SECTION_TYPES_WITH_ROWS = [
  VenueMapSectionType.ForcedRow,
  VenueMapSectionType.OptionalRow,
  VenueMapSectionType.OptionalRowPairs,
  VenueMapSectionType.ForcedRowPairs,
];

/**
 * Indicates if the venue map has sections with rows and if these rows only contain speculative data
 * @param venueMap
 * @returns
 */
export const isMissingRowData = (venueMap: VenueMapInfo | null | undefined) => {
  if (!venueMap) {
    // If we don't have a venue map, we can't determine if it's missing row data
    return false;
  }

  const sectionsWithRow = venueMap.sections.filter((s) =>
    SECTION_TYPES_WITH_ROWS.includes(s.sectionType)
  );
  const hasNonSpeculativeRows = sectionsWithRow.some(
    (s) => s.rows.filter((r) => r.isSpeculative === false).length > 0
  );

  return sectionsWithRow.length > 0 && !hasNonSpeculativeRows;
};

export const findTicketClass = (
  seating: Seating,
  sectionInfo?: SectionInfo[] | null
) => {
  if (!sectionInfo?.length) {
    return;
  }

  if (seating.rowId != null) {
    return sectionInfo
      .find((s) => s.rows.some((r) => r.id === seating.rowId))
      ?.rows.find((r) => r.id === seating.rowId)?.tktClass;
  }

  if (seating.row) {
    return sectionInfo
      .find(
        (s) =>
          s.name.toLocaleUpperCase() === seating.section.toLocaleUpperCase() &&
          s.rows.some(
            (r) =>
              r.name?.toLocaleUpperCase() === seating.row?.toLocaleUpperCase()
          )
      )
      ?.rows.find(
        (r) => r.name?.toLocaleUpperCase() === seating.row?.toLocaleUpperCase()
      )?.tktClass;
  }
};

export const filterSectionByZone =
  (classId: number | undefined) =>
  ({ rows }: SectionInfo) =>
    rows?.some(({ tktClass: ticketClass }) => ticketClass?.id === classId);

export const filterSectionByZoneRestrict =
  (classId: number | undefined) =>
  ({ rows, sectionType }: SectionInfo) =>
    SECTION_TYPES_WITH_ROWS.includes(sectionType) &&
    rows?.filter((r) => r.isSpeculative === false).length > 0 &&
    rows?.some(({ tktClass: ticketClass }) => ticketClass?.id === classId);

export const filterSectionByRowType = ({ rows, sectionType }: SectionInfo) =>
  SECTION_TYPES_WITH_ROWS.includes(sectionType) &&
  rows?.filter((r) => r.isSpeculative === false).length > 0;
