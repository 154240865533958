import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useCurrencyConversionOverrides } from 'src/hooks/useCurrencyConversionOverrides';
import { BodySectionTitle } from 'src/modals/common/Purchase';
import { CurrencyConversionOverridesTableContainer } from 'src/tables/CurrencyConversionOverridesTable/CurrencyConversionOverridesTableContainer';
import { ContentId } from 'src/utils/constants/contentId';

export const SellerAccountSettingCurrencyConversionOverrides = () => {
  const { currencyConversionOverrides, isLoading } =
    useCurrencyConversionOverrides();

  return (
    <>
      <BodySectionTitle>
        <Content id={ContentId.DailyOverrideFxRates} />
      </BodySectionTitle>
      {isLoading ? (
        <PosSpinner />
      ) : (
        <CurrencyConversionOverridesTableContainer
          currencyConversionOverrides={currencyConversionOverrides}
        />
      )}
    </>
  );
};
