import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { once } from 'lodash-es';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { getServerSideOnlyMetricsQuery } from 'src/utils/getServerSideOnlyMetricsQuery';
import { EntityWithTicketsQuery } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { useFilterQueryContext } from '../FilterQueryContext';

export type ReportMetricsResponse = { [key: string]: any };

export type IReportMetricsContext = {
  isLoading: boolean;
  reportMetrics?: ReportMetricsResponse[];
  reportSummary?: ReportMetricsResponse;
  refreshMetrics: () => void;
  fetchNextPage: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
};

export const createReportMetricsContextV2 = once(() =>
  React.createContext({} as IReportMetricsContext)
);

export function useReportMetricsContextV2() {
  return useContext(createReportMetricsContextV2());
}

export function ReportMetricsContextProviderV2<
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>({
  queryKey,
  getReportMetrics,
  getReportMetricsSummary,
  children,
  disabled,
}: PropsWithChildren<{
  queryKey: string;
  getReportMetrics?: (
    query: TQuery,
    pageNumber: number
  ) => Promise<ReportMetricsResponse[]>;
  getReportMetricsSummary?: (query: TQuery) => Promise<ReportMetricsResponse>;
  disabled?: boolean;
}>) {
  const ReportMetricsContext = createReportMetricsContextV2();

  const { trackError } = useErrorBoundaryContext();

  const { eventsTransformed, isLoading } = useCatalogDataContext();
  const { filterQuery } = useFilterQueryContext<TQuery>();
  const { activeAccountWebClientConfig } = useAppContext();

  // Removing the client-side queries
  const transformedQuery = getServerSideOnlyMetricsQuery(filterQuery) as TQuery;

  const allEventIds = (eventsTransformed ?? []).flatMap((ev) => ({
    viagVirtualId: ev.event.viagVirtualId,
    performerId: ev.event.perfId,
    venueId: ev.event.venueId,
  }));

  const shouldQuery =
    !disabled &&
    !(
      !getReportMetrics ||
      !transformedQuery ||
      !activeAccountWebClientConfig.activeAccountId ||
      !eventsTransformed
    );

  const reportMetricsQuery = useInfiniteQuery({
    queryKey: [
      `${queryKey}-report-metrics`,
      transformedQuery,
      allEventIds,
      activeAccountWebClientConfig,
      disabled,
      eventsTransformed == null,
    ],
    queryFn: async ({ pageParam: pageNumber = 0 }) => {
      if (!shouldQuery) {
        return null;
      }

      const queryWithCatalog = {
        ...transformedQuery,
        eventOrMappingIds: allEventIds.map((ev) => ev.viagVirtualId),
        performerIds: allEventIds
          .filter((ev) => ev.performerId != null)
          .map((ev) => ev.performerId!),
        venueIds: allEventIds.map((ev) => ev.venueId),
      } as TQuery;

      const reportMetricsData = await getReportMetrics!(
        queryWithCatalog,
        pageNumber
      );

      return {
        reportMetricsData,
        pageNumber,
      };
    },
    getNextPageParam(lastPage) {
      return lastPage?.reportMetricsData.length
        ? lastPage.pageNumber + 1
        : undefined;
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    initialPageParam: 0,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('getReportMetrics', error, {
          ...transformedQuery,
          eventIds: allEventIds,
        });
      },
    },
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  const reportMetricsSummaryQuery = useQuery({
    queryKey: [
      `${queryKey}-report-metrics-summary`,
      transformedQuery,
      allEventIds,
      activeAccountWebClientConfig,
      disabled,
      eventsTransformed == null,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const queryWithCatalog = {
        ...transformedQuery,
        eventIds: allEventIds.map((ev) => ev.viagVirtualId),
        performerIds: allEventIds
          .filter((ev) => ev.performerId != null)
          .map((ev) => ev.performerId!),
        venueIds: allEventIds.map((ev) => ev.venueId),
      } as TQuery;

      const reportMetricsData =
        await getReportMetricsSummary!(queryWithCatalog);

      return {
        reportMetricsData,
      };
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('getReportMetricsSummary', error, {
          ...transformedQuery,
          eventIds: allEventIds,
        });
      },
    },
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  const refreshMetrics = useCallback(
    () => reportMetricsQuery.refetch(),
    [reportMetricsQuery]
  );

  const flattenedData = useMemo(
    () =>
      reportMetricsQuery.data?.pages?.flatMap(
        (m) => m?.reportMetricsData ?? []
      ),
    [reportMetricsQuery.data]
  );

  return (
    <ReportMetricsContext.Provider
      value={{
        isLoading: reportMetricsQuery.isLoading || isLoading,
        reportMetrics: flattenedData,
        reportSummary:
          reportMetricsSummaryQuery.data?.reportMetricsData ?? undefined,
        refreshMetrics: refreshMetrics,
        fetchNextPage: reportMetricsQuery.fetchNextPage,
        hasNextPage: reportMetricsQuery.hasNextPage,
        isFetchingNextPage: reportMetricsQuery.isFetchingNextPage,
      }}
    >
      {children}
    </ReportMetricsContext.Provider>
  );
}
