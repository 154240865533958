import { darkLogo } from 'src/components/common/MarketplaceLogo/MarketplaceLogo.css';

export const TickPickLogo = ({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width ?? 400}
      height={height ?? 89}
      viewBox="0 0 400 89"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      textRendering="geometricPrecision"
      shapeRendering="geometricPrecision"
      className={darkLogo}
    >
      <g>
        <path
          opacity="0.968"
          fill="#3298fe"
          d="M 6.5,-0.5 C 13.1667,-0.5 19.8333,-0.5 26.5,-0.5C 27.5135,4.17049 30.1801,5.33715 34.5,3C 35.8228,2.18405 36.4894,1.01739 36.5,-0.5C 44.1667,-0.5 51.8333,-0.5 59.5,-0.5C 53.7142,9.30977 51.0475,19.9764 51.5,31.5C 42.6822,32.4933 33.6822,32.8266 24.5,32.5C 16.4703,38.5498 11.4703,46.5498 9.5,56.5C 7.92558,56.0629 6.75891,55.0629 6,53.5C 3.22592,48.6332 1.05926,43.6332 -0.5,38.5C -0.5,30.8333 -0.5,23.1667 -0.5,15.5C 1.24149,10.0066 3.57482,4.67329 6.5,-0.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.921"
          fill="#000000"
          d="M 97.5,13.5 C 114.167,13.5 130.833,13.5 147.5,13.5C 147.5,17.5 147.5,21.5 147.5,25.5C 141.5,25.5 135.5,25.5 129.5,25.5C 129.5,41.5 129.5,57.5 129.5,73.5C 124.57,73.8025 119.737,73.4692 115,72.5C 114.569,57.1545 114.569,41.8212 115,26.5C 109.259,25.5251 103.425,25.1918 97.5,25.5C 97.5,21.5 97.5,17.5 97.5,13.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.853"
          fill="#000000"
          d="M 151.5,13.5 C 156.167,13.5 160.833,13.5 165.5,13.5C 165.5,17.5 165.5,21.5 165.5,25.5C 160.833,25.5 156.167,25.5 151.5,25.5C 151.5,21.5 151.5,17.5 151.5,13.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.909"
          fill="#000000"
          d="M 209.5,13.5 C 214.167,13.5 218.833,13.5 223.5,13.5C 223.334,23.5056 223.5,33.5056 224,43.5C 227.167,39 230.333,34.5 233.5,30C 238.864,29.1705 244.198,29.3372 249.5,30.5C 244.286,37.092 238.953,43.592 233.5,50C 239.027,57.361 244.361,64.861 249.5,72.5C 244.198,73.6628 238.864,73.8295 233.5,73C 230.333,67.8333 227.167,62.6667 224,57.5C 223.501,62.8229 223.334,68.1563 223.5,73.5C 218.833,73.5 214.167,73.5 209.5,73.5C 209.5,53.5 209.5,33.5 209.5,13.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.917"
          fill="#000000"
          d="M 252.5,13.5 C 262.839,13.3335 273.172,13.5002 283.5,14C 294.357,18.2124 298.524,26.0457 296,37.5C 294.268,45.5649 289.435,50.7316 281.5,53C 276.882,53.994 272.215,54.494 267.5,54.5C 267.5,60.8333 267.5,67.1667 267.5,73.5C 262.5,73.5 257.5,73.5 252.5,73.5C 252.5,53.5 252.5,33.5 252.5,13.5 Z M 267.5,25.5 C 272.605,25.1455 277.105,26.4789 281,29.5C 281.893,32.9162 281.393,36.0828 279.5,39C 275.702,40.7827 271.702,41.6161 267.5,41.5C 267.5,36.1667 267.5,30.8333 267.5,25.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.847"
          fill="#000000"
          d="M 299.5,13.5 C 304.167,13.5 308.833,13.5 313.5,13.5C 313.5,17.5 313.5,21.5 313.5,25.5C 308.833,25.5 304.167,25.5 299.5,25.5C 299.5,21.5 299.5,17.5 299.5,13.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.916"
          fill="#000000"
          d="M 399.5,29.5 C 399.5,30.1667 399.5,30.8333 399.5,31.5C 394.364,37.4782 389.364,43.6448 384.5,50C 389.363,57.0156 394.363,63.8489 399.5,70.5C 399.5,71.5 399.5,72.5 399.5,73.5C 394.489,73.6659 389.489,73.4993 384.5,73C 381.209,68.2522 378.042,63.4188 375,58.5C 374.501,63.4889 374.334,68.4889 374.5,73.5C 369.833,73.5 365.167,73.5 360.5,73.5C 360.5,53.5 360.5,33.5 360.5,13.5C 365.167,13.5 369.833,13.5 374.5,13.5C 374.334,23.1724 374.5,32.8391 375,42.5C 377.783,38.0493 380.95,33.8826 384.5,30C 389.489,29.5007 394.489,29.3341 399.5,29.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.904"
          fill="#000000"
          d="M 185.5,28.5 C 191.678,28.1067 197.678,28.9401 203.5,31C 205.223,31.5232 206.556,32.5232 207.5,34C 205.345,37.6485 203.011,41.1485 200.5,44.5C 195.54,39.888 190.373,39.5547 185,43.5C 181.256,49.789 181.756,55.6223 186.5,61C 188.218,61.346 189.885,61.846 191.5,62.5C 195.522,61.1519 199.522,59.8186 203.5,58.5C 204.833,62.5 206.167,66.5 207.5,70.5C 203.559,71.757 199.559,72.9236 195.5,74C 173.351,74.8439 164.518,64.3439 169,42.5C 172.585,35.4032 178.085,30.7365 185.5,28.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.903"
          fill="#000000"
          d="M 335.5,28.5 C 343.349,27.9626 350.683,29.6293 357.5,33.5C 355,37.1667 352.5,40.8333 350,44.5C 342.279,38.4312 336.279,39.7646 332,48.5C 330.517,55.878 333.351,60.5447 340.5,62.5C 344.156,61.5894 347.656,60.2561 351,58.5C 354.536,61.8775 356.036,65.8775 355.5,70.5C 343.591,76.6584 332.425,75.6584 322,67.5C 313.127,53.3018 315.293,41.1352 328.5,31C 331.004,30.3024 333.337,29.4691 335.5,28.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.843"
          fill="#000000"
          d="M 151.5,29.5 C 156.099,29.2 160.599,29.5333 165,30.5C 165.667,44.5 165.667,58.5 165,72.5C 160.263,73.4692 155.43,73.8025 150.5,73.5C 150.184,58.755 150.517,44.0884 151.5,29.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.903"
          fill="#000000"
          d="M 299.5,29.5 C 304.167,29.5 308.833,29.5 313.5,29.5C 313.5,44.1667 313.5,58.8333 313.5,73.5C 308.833,73.5 304.167,73.5 299.5,73.5C 299.5,58.8333 299.5,44.1667 299.5,29.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.973"
          fill="#0062bb"
          d="M 51.5,31.5 C 59.8599,31.1688 68.1932,31.5021 76.5,32.5C 87.3043,51.3383 86.9709,70.005 75.5,88.5C 69.8333,88.5 64.1667,88.5 58.5,88.5C 57.1724,84.746 54.5058,83.5793 50.5,85C 49.2899,86.0391 48.2899,87.2058 47.5,88.5C 38.8333,88.5 30.1667,88.5 21.5,88.5C 28.3666,79.3869 31.7,69.0536 31.5,57.5C 31.8076,48.4234 29.4743,40.0901 24.5,32.5C 33.6822,32.8266 42.6822,32.4933 51.5,31.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.935"
          fill="#002967"
          d="M 24.5,32.5 C 29.4743,40.0901 31.8076,48.4234 31.5,57.5C 23.9809,57.825 16.6476,57.4916 9.5,56.5C 11.4703,46.5498 16.4703,38.5498 24.5,32.5 Z"
        />
      </g>
    </svg>
  );
};
