import { useState } from 'react';
import { BulkEditTagsActionPermissionsHandler } from 'src/components/common/BulkActions/BulkEditTagsAction/BulkEditTagsActionPermissionsHandler';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useFilterQueryState } from 'src/components/MainFilterBar/useFilterQueryState';
import { useBulkEditTagsAction } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkEditTagsAction';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { PurchasesBulkActionProps } from '../PurchasesBulkActions.types';

export const TagsActions = ({ isEventView }: PurchasesBulkActionProps) => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const [isLoading, setIsLoading] = useState(false);
  const { filterQueryWithEventIds } = useFilterQueryState<PurchaseOrderQuery>(
    ActionOutboxEntityType.Purchase,
    undefined,
    isEventView
  );
  const bulkEditTags = useBulkEditTagsAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  if (isEventView) {
    return null;
  }

  return (
    <BulkEditTagsActionPermissionsHandler
      entityType={ActionOutboxEntityType.Purchase}
    >
      <PosDropdown
        key="purchases-tags-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Tags} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {bulkEditTags.dropDown}
      </PosDropdown>
    </BulkEditTagsActionPermissionsHandler>
  );
};
