import { isEqual } from 'lodash-es';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { EventSearch } from 'src/components/Events/EventSearch';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  GenericDialog,
  GenericDialogProps,
} from 'src/core/interim/dialogs/GenericDialog';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, SimpleTable, Stack } from 'src/core/ui';
import { SearchOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { compareMarketplace } from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Event,
  EventConfigClient,
  Marketplace,
  SellerEventMarketplaceEventInfo,
} from 'src/WebApiController';

import * as styles from './MarketplaceEventEditDialog.css';

type MarketplaceEventEditDialogForm = {
  mkpEventInfos: SellerEventMarketplaceEventInfo[];
};

export type MarketplaceEventEditDialogProps = ComponentProps<typeof RSModal> & {
  event: Event;
  mkpEventInfos?: SellerEventMarketplaceEventInfo[];
};

export function MarketplaceEventEditDialog({
  mkpEventInfos,
  ...rest
}: MarketplaceEventEditDialogProps) {
  const methods = useForm<MarketplaceEventEditDialogForm>({
    defaultValues: {
      mkpEventInfos: mkpEventInfos?.sort((a, b) =>
        compareMarketplace(a.marketplace, b.marketplace)
      ),
    },
  });

  useEffect(() => {
    const newMkpEventInfos = mkpEventInfos?.sort((a, b) =>
      compareMarketplace(a.marketplace, b.marketplace)
    );
    if (
      !isEqual(methods.formState.defaultValues?.mkpEventInfos, newMkpEventInfos)
    ) {
      methods.reset({
        mkpEventInfos: newMkpEventInfos,
      });
    }
  }, [methods, mkpEventInfos]);

  return (
    <FormProvider {...methods}>
      <MarketplaceEventEditDialogBody {...methods} {...rest} />
    </FormProvider>
  );
}

const MarketplaceEventEditDialogBody = ({
  event,
  onClosed,
  getValues,
  register,
  setValue,
  formState,
  clearErrors,
  handleSubmit,
  ...rest
}: Omit<MarketplaceEventEditDialogProps, 'mkpEventInfos'> &
  Omit<
    ComponentProps<
      typeof FormProvider<MarketplaceEventEditDialogForm, unknown>
    >,
    'children'
  > & {
    event: Event;
  }) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isLoading, setIsLoading] = useState(false);
  const [activeMarketPlaceName, setActiveMarketPlaceName] = useState<
    `mkpEventInfos.${number}.eventIdOverride` | undefined
  >(undefined);

  const onSubmit = useCallback(
    (formValue: MarketplaceEventEditDialogForm) => {
      setIsLoading(true);

      tryInvokeApi(
        async () => {
          const inputs = formValue.mkpEventInfos.map(
            (x) =>
              ({
                ...x,
                viagogoEventId: event.viagId,
                eventMappingId: event.mappingId,
              }) as SellerEventMarketplaceEventInfo
          );

          const result = await new EventConfigClient(
            activeAccountWebClientConfig
          ).updateSellerEventMarketplaceEventInfos(inputs);

          if (result) {
            onClosed();
          }
        },
        (error) => {
          showErrorDialog(
            'EventConfigClient.updateSellerEventMarketplaceEventInfos',
            error,
            {
              trackErrorData: formValue,
            }
          );
        },
        () => setIsLoading(false)
      );
    },
    [
      activeAccountWebClientConfig,
      event.mappingId,
      event.viagId,
      onClosed,
      showErrorDialog,
    ]
  );

  const mkpEventInfos = getValues().mkpEventInfos;

  const onSaveClick = useCallback(() => {
    clearErrors();

    handleSubmit(onSubmit)();
  }, [clearErrors, handleSubmit, onSubmit]);

  const stripTrailingSlash = (text: string) => {
    return text.endsWith('/') ? text.slice(0, -1) : text;
  };

  const isUrl = (str: string) => {
    try {
      new URL(str);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleEventOverrideChange =
    (i: number, mp: Marketplace) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!isUrl(e.target.value)) {
        return;
      }

      const sanitizedString = stripTrailingSlash(e.target.value.trim());
      let eventOverrideId = '';
      const urlComponents = sanitizedString.split('/');

      switch (mp) {
        case Marketplace.StubHub:
        case Marketplace.Ticketmaster: {
          const eventIdx = urlComponents.indexOf('event');
          if (eventIdx === -1) {
            return;
          }
          eventOverrideId = urlComponents[eventIdx + 1];
          break;
        }
        case Marketplace.SeatGeek: {
          const eventId = urlComponents[urlComponents.length - 1];
          eventOverrideId = eventId.split('#')[0];
          break;
        }
        case Marketplace.VividSeats: {
          const productionIdx = urlComponents.indexOf('production');
          if (productionIdx === -1) {
            return;
          }
          eventOverrideId = urlComponents[productionIdx + 1];
          break;
        }
        case Marketplace.Gametime: {
          const eventsIdx = urlComponents.indexOf('events');
          if (eventsIdx === -1) {
            return;
          }
          eventOverrideId = urlComponents[eventsIdx + 1];
          break;
        }
        default:
          // we haven't mapped the event ID extraction yet
          eventOverrideId = e.target.value;
          break;
      }

      e.target.value = eventOverrideId;
      setValue(`mkpEventInfos.${i}.eventIdOverride`, eventOverrideId, {
        shouldDirty: true,
      });
    };

  const isSearchingEvent = !!activeMarketPlaceName;

  const mainDialogProps: GenericDialogProps = {
    ...rest,
    header: <Content id={ContentId.EditMarketplaceEvent} />,
    footer: (
      <>
        {onClosed && (
          <CancelButton
            onClick={onClosed}
            disabled={formState.isSubmitting || isLoading}
          />
        )}
        <Button
          variant={'regular'}
          onClick={onSaveClick}
          disabled={formState.isSubmitting || isLoading || !formState.isDirty}
        >
          <Content id={ContentId.Save} />
        </Button>
      </>
    ),
    onCancel: onClosed,
  };

  const eventSearchDialogProps: GenericDialogProps = {
    isOpen: true,
    header: <Content id={ContentId.SearchEvent} />,
    footer: (
      <CancelButton
        textContentId={ContentId.Back}
        onClick={() => setActiveMarketPlaceName(undefined)}
      />
    ),
  };

  return (
    <GenericDialog
      size={'lg'}
      {...(isSearchingEvent ? eventSearchDialogProps : mainDialogProps)}
    >
      {isSearchingEvent ? (
        <EventSearch
          numEventsSelected={0}
          isSingleSelect
          onEventsSelect={(eventsSearchResults) => {
            const eventSearchResult = eventsSearchResults[0];
            if (eventSearchResult) {
              setValue(
                activeMarketPlaceName,
                eventSearchResult.event.viagVirtualId,
                {
                  shouldDirty: true,
                }
              );
              setActiveMarketPlaceName(undefined);
            }
          }}
        />
      ) : (
        <>
          <SimpleTable.Table className={styles.eventIdTable}>
            <SimpleTable.Thead>
              <SimpleTable.Tr>
                <SimpleTable.Th
                  className={`${styles.marketplaceHeaderCell} ${styles.marketplaceCell}`}
                >
                  <Content id={ContentId.Merchant} />
                </SimpleTable.Th>
                <SimpleTable.Th
                  className={`${styles.marketplaceHeaderCell} ${styles.marketplaceCell}`}
                >
                  <Content id={ContentId.EventId} />
                </SimpleTable.Th>
              </SimpleTable.Tr>
            </SimpleTable.Thead>
            <SimpleTable.Tbody>
              {mkpEventInfos.map((mp, i) => {
                return (
                  <SimpleTable.Tr
                    className={styles.marketplaceRow}
                    key={mp.marketplace}
                  >
                    <SimpleTable.Td className={styles.marketplaceCell}>
                      <MarketplaceLogo
                        marketplace={mp.marketplace}
                        event={event!}
                        isViagogo={false}
                        marketplaceEventInfo={mp}
                      />
                    </SimpleTable.Td>
                    <SimpleTable.Td className={styles.marketplaceCell}>
                      <Stack gap="m">
                        <PosTextField
                          placeholder={mp.mappedEventId ?? ''}
                          disabled={formState.isSubmitting || isLoading}
                          {...register(`mkpEventInfos.${i}.eventIdOverride`, {
                            onChange: () =>
                              handleEventOverrideChange(i, mp.marketplace),
                          })}
                          postfixDisplay={
                            <Button
                              variant="text"
                              shape="inline"
                              onClick={() => {
                                setActiveMarketPlaceName(
                                  `mkpEventInfos.${i}.eventIdOverride`
                                );
                              }}
                            >
                              <SearchOutlineIcon />
                            </Button>
                          }
                        />
                      </Stack>
                    </SimpleTable.Td>
                  </SimpleTable.Tr>
                );
              })}
            </SimpleTable.Tbody>
          </SimpleTable.Table>
          {formState.isDirty && (
            <Stack width="full" justifyContent="end">
              <WarningMessage
                message={<Content id={ContentId.UpdateEventIdWarning} />}
              />
            </Stack>
          )}
        </>
      )}
    </GenericDialog>
  );
};
