import { useFormContext } from 'react-hook-form';
import { TicketTypePriorityInput } from 'src/components/common/TicketTypePriorityInput';
import { Content } from 'src/contexts/ContentContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { Detail, DetailGroup } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { DeliveryType, ListingDetailsUpdateInput } from 'src/WebApiController';

export const TicketTypePrioritySection = ({
  deliveryType,
  disabled,
}: {
  deliveryType: DeliveryType | null;
  disabled: boolean;
}) => {
  const { watch, setValue } = useFormContext<ListingDetailsUpdateInput>();
  const isMobile = useMatchMedia('mobile');
  const ticketTypeRules = watch('ticketTypeRules');

  return (
    <DetailGroup style={{ gridColumn: isMobile ? 1 : '1 / span 2' }}>
      <Detail
        label={<Content id={ContentId.DeliveryStrategy} />}
        style={{ flex: 1 }}
        detail={
          <>
            <TicketTypePriorityInput
              deliveryType={deliveryType}
              ticketTypeRules={ticketTypeRules}
              onTicketTypeRulesChange={(newRules) => {
                setValue('ticketTypeRules', newRules);
              }}
              disabled={disabled}
            />
          </>
        }
      />
    </DetailGroup>
  );
};
