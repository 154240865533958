import { ReactNode } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { MobileQRIcon } from 'src/svgs';
import { CustomDeliveryIcon } from 'src/svgs/CustomDeliveryIcon';
import {
  BarcodeIcon,
  IconsFill,
  IconsProps,
  MobileTransferIcon,
  PaperTicketOutlineIcon,
  PdfIcon,
  PrintAtHomeIcon,
  TicketAlertOutlineIcon,
  WalletOutlineIcon,
} from 'src/svgs/Viagogo';
import {
  DeliveryType,
  TicketType,
  TicketTypePriority,
} from 'src/WebApiController';

import { moveElements } from './arrayUtils';
import { ContentId } from './constants/contentId';
import { TICKET_TYPE_TO_CID } from './constants/contentIdMaps';

export const getTicketTypeIcon = (
  type: TicketType | null,
  fill: IconsFill,
  showIconTitle = true
) => {
  const props = {
    title: showIconTitle ? type ?? TicketType.Custom : undefined,
    fill: fill,
    size: vars.iconSize.m,
    align: 'middle',
  } as IconsProps;

  switch (type) {
    case TicketType.Barcode:
      return <BarcodeIcon {...props} />;
    case TicketType.ETicket:
      return <PdfIcon {...props} />;
    case TicketType.QRCode:
      return <MobileQRIcon {...props} />;
    case TicketType.Paper:
      return <PaperTicketOutlineIcon {...props} />;
    case TicketType.Transfer:
      return <MobileTransferIcon {...props} />;
    case TicketType.Season:
      return <TicketAlertOutlineIcon {...props} />;
    case TicketType.ETicketUrl:
    case TicketType.SMPWallet:
      return <WalletOutlineIcon {...props} />;
    case TicketType.Custom:
    default:
      return <PrintAtHomeIcon {...props} />;
  }
};

export const getTicketTypeIcons = (fill: IconsFill) => {
  return Object.keys(typeof TicketType).reduce(
    (r, t) => {
      r[t as TicketType] = getTicketTypeIcon(t as TicketType, fill);
      return r;
    },
    {} as Record<TicketType, ReactNode>
  );
};

export const isPriorityBlocked = (p: TicketTypePriority) => {
  return p.isBlocked || p.priority == null
}

export const getTicketTypeLabel = (type: TicketType | null) => {
  if (!type) {
    return <Content id={ContentId.Other} />;
  }

  return <Content id={TICKET_TYPE_TO_CID[type]} />;
};

export const getDefaultTicketType = (
  deliveryType: DeliveryType
): TicketType => {
  switch (deliveryType) {
    case DeliveryType.InApp:
      return TicketType.Transfer;

    case DeliveryType.PDF:
      return TicketType.ETicket;

    case DeliveryType.Paper:
      return TicketType.Paper;

    case DeliveryType.MemberCard:
      return TicketType.Season;
    case DeliveryType.Wallet:
      return TicketType.ETicketUrl;
    default:
      return TicketType.Custom;
  }
};

export const getDeliveryTypeIcon = (
  deliveryType?: DeliveryType | null,
  topPriTicketType?: TicketType | null,
  fill?: IconsFill,
  showIconTitle = true
) => {
  if (topPriTicketType) {
    return getTicketTypeIcon(
      topPriTicketType,
      fill ?? IconsFill.currentColor,
      showIconTitle
    );
  }

  const props = {
    title: showIconTitle ? deliveryType : undefined,
    fill: fill ?? IconsFill.currentColor,
    size: vars.iconSize.m,
    align: 'middle',
  } as IconsProps;

  switch (deliveryType) {
    case DeliveryType.InApp:
      return <MobileTransferIcon {...props} />;

    case DeliveryType.PDF:
      return <PdfIcon {...props} />;

    case DeliveryType.Paper:
      return <PaperTicketOutlineIcon {...props} />;

    case DeliveryType.MemberCard:
      return <TicketAlertOutlineIcon {...props} />;

    case DeliveryType.Wallet:
      return <WalletOutlineIcon {...props} />;

    case DeliveryType.Custom:
      return <CustomDeliveryIcon {...props} />;

    default:
      return <PrintAtHomeIcon {...props} />;
  }
};

export const sortPriorityOrders = (
  a: TicketTypePriority,
  b: TicketTypePriority
) => {
  // blocked always go last
  if (a.isBlocked && !b.isBlocked) {
    return 1;
  }
  if (!a.isBlocked && b.isBlocked) {
    return -1;
  }
  return a.priority - b.priority;
};

// Normally we don't need this, but just in case existing data from DB is already corrupted
export const backfillTicketTypePriorityOrders = (
  ticketTypes: TicketType[],
  ticketTypePriorities: TicketTypePriority[]
) => {
  ticketTypes.forEach((tt) => {
    if (!ticketTypePriorities.find((to) => to.ticketType === tt)) {
      ticketTypePriorities.push({
        ticketType: tt,
        priority: null,
      } as unknown as TicketTypePriority);
    }
  });

  ticketTypePriorities.forEach((to, i) => {
    if (!to.priority && !to.isBlocked) {
      // For those missing value we put to the end
      to.priority = i + ticketTypePriorities.length;
    }
  });
};

export const BLOCKED_PRIORITY = -1;

export const shiftTicketTypePriority = (
  priorityOrderBefore: TicketTypePriority[],
  ticketType: TicketType,
  priority: number // starting from one
): TicketTypePriority[] => {
  priorityOrderBefore.sort(sortPriorityOrders);

  const fromIndex = priorityOrderBefore.findIndex(
    (p) => p.ticketType === ticketType
  );

  const isBlocked = priority === BLOCKED_PRIORITY;
  const toIndex = isBlocked ? priorityOrderBefore.length - 1 : priority - 1;

  priorityOrderBefore[fromIndex].isBlocked = isBlocked;
  priorityOrderBefore[fromIndex].priority = isBlocked ? 0 : priority;

  const priorityOrderAfter = moveElements(
    priorityOrderBefore,
    fromIndex,
    toIndex
  ).map((to, i) => {
    if (to.isBlocked) {
      return to;
    }
    return {
      ...to,
      isBlocked: false,
      priority: i + 1,
    };
  });

  return priorityOrderAfter;
};
