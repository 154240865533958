import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  ListingExperiment,
  ListingExperimentClient,
} from 'src/WebApiController';

export function useListingExperiments(viagogoEventId: number) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [
      'getListingExperimentsByViagogoEventId',
      activeAccountWebClientConfig.activeAccountId,
      viagogoEventId,
    ],
    [activeAccountWebClientConfig.activeAccountId, viagogoEventId]
  );

  const listingExperimentQuery = useQuery({
    queryKey,
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return await new ListingExperimentClient(
        activeAccountWebClientConfig
      ).getListingExperimentsByViagogoEventId(viagogoEventId);
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog(
          'ListingExperimentClient.getListingExperimentsByViagogoEventId',
          error
        );
      },
    },
  });

  const createExperimentMutation = useMutation({
    mutationFn: async (experiment: ListingExperiment) => {
      return await new ListingExperimentClient(
        activeAccountWebClientConfig
      ).createListingExperiment(experiment);
    },
    onMutate: (experiment) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue: ListingExperiment[] | undefined =
        queryClient.getQueryData(queryKey);
      if (prevValue) {
        prevValue.push(experiment);
        queryClient.setQueryData(queryKey, prevValue);
      } else {
        queryClient.setQueryData(queryKey, [experiment]);
      }
      return { prevValue };
    },
    onError: (err: ErrorTypes, experiment, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('ListingExperimentClient.createListingExperiment', err, {
        trackErrorData: { experiment },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const updateExperimentMutation = useMutation({
    mutationFn: async (experiment: ListingExperiment) => {
      return await new ListingExperimentClient(
        activeAccountWebClientConfig
      ).updateListingExperiment(experiment);
    },
    onMutate: (experiment) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue: ListingExperiment[] | undefined =
        queryClient.getQueryData(queryKey);
      if (prevValue) {
        const index = prevValue.findIndex(
          (e) =>
            e.viagogoEventId === viagogoEventId &&
            e.listingGroupId === experiment.listingGroupId
        );
        if (index >= 0) {
          prevValue[index] = experiment;
          queryClient.setQueryData(queryKey, prevValue);
        }
      }
      return { prevValue };
    },
    onError: (err: ErrorTypes, experiment, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('ListingExperimentClient.updateListingExperiment', err, {
        trackErrorData: { experiment },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const stopExperimentMutation = useMutation({
    mutationFn: async (listingGroupId: string | null) => {
      return await new ListingExperimentClient(
        activeAccountWebClientConfig
      ).stopListingExperiment(viagogoEventId, listingGroupId);
    },
    onMutate: (listingGroupId: string | null) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue: ListingExperiment[] | undefined =
        queryClient.getQueryData(queryKey);
      if (prevValue) {
        const index = prevValue.findIndex(
          (e) =>
            e.viagogoEventId === viagogoEventId &&
            e.listingGroupId === listingGroupId
        );
        if (index >= 0) {
          const current = prevValue[index];
          prevValue[index] = {
            ...current,
            endDate: new Date().toISOString(),
            discountPercentage: null,
          };
          queryClient.setQueryData(queryKey, prevValue);
        }
      }
      return { prevValue };
    },
    onError: (err: ErrorTypes, viagogoEventId, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('ListingExperimentClient.stopListingExperiment', err, {
        trackErrorData: { viagogoEventId },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const onCreateExperiment = useCallback(
    async (experiment: ListingExperiment) => {
      await createExperimentMutation.mutateAsync(experiment);
    },
    [createExperimentMutation]
  );
  const onUpdateExperiment = useCallback(
    async (experiment: ListingExperiment) => {
      await updateExperimentMutation.mutateAsync(experiment);
    },
    [updateExperimentMutation]
  );
  const onStopExperiment = useCallback(
    async (listingGroupId: string | null) => {
      await stopExperimentMutation.mutateAsync(listingGroupId);
    },
    [stopExperimentMutation]
  );

  return {
    experiments: listingExperimentQuery.data,
    onCreateExperiment,
    onUpdateExperiment,
    onStopExperiment,
    isLoading:
      listingExperimentQuery.isLoading ||
      createExperimentMutation.isPending ||
      updateExperimentMutation.isPending ||
      stopExperimentMutation.isPending,
  };
}
