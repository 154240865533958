import { ComponentProps, useCallback, useContext } from 'react';
import { ConfirmButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { ConfirmSaleModal } from 'src/modals/ConfirmSale';
import { IconsFill, SearchOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  PointOfSaleSaleStatus,
  SaleActionType,
  SaleDetails,
} from 'src/WebApiController';

export const LaunchConfirmSale = ({
  action,
  saleId,
  marketplaceSaleId,
  saleStatus,
  cancelTo,
  variant,
  ...buttonProps
}: {
  action:
    | SaleActionType.Confirm
    | SaleActionType.Allocate
    | SaleActionType.ConfirmAllocation;
  saleId: number;
  marketplaceSaleId?: string | null;
  saleStatus: PointOfSaleSaleStatus;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
} & ComponentProps<typeof ConfirmSaleModal>) => {
  const { setModal } = useContext(ModalContext);
  const { setActivePosEntity } = useActivePosEntityContext<SaleDetails>();

  const confirmSale = useCallback(() => {
    setModal({
      children: <ConfirmSaleModal cancelTo={cancelTo} action={action} />,
      clickOutsideToClose: true,
      size: 'slide-in',
      deepLinkValue: saleId,
      trackViewEntityType: ActionOutboxEntityType.Sale,
    });
  }, [setModal, cancelTo, action, saleId]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setActivePosEntity(saleId, marketplaceSaleId);
      confirmSale();
    },
    [confirmSale, marketplaceSaleId, setActivePosEntity, saleId]
  );

  const contentId =
    action === SaleActionType.Confirm
      ? saleStatus === PointOfSaleSaleStatus.CancelledHold
        ? ContentId.UndoCancel
        : ContentId.ConfirmSale
      : action === SaleActionType.ConfirmAllocation
      ? ContentId.ConfirmAllocation
      : ContentId.AllocateTickets;

  return (
    <ConfirmButton
      {...buttonProps}
      variant={variant}
      textContentId={contentId}
      titleContentId={contentId}
      onClick={onLaunchDialog}
      icon={
        action === SaleActionType.Allocate ? (
          <SearchOutlineIcon
            withHoverEffect
            size={vars.iconSize.m}
            fill={
              buttonProps.iconOnlyMode || variant !== 'regular'
                ? IconsFill.textBrand
                : IconsFill.textInverted
            }
          />
        ) : undefined
      }
    />
  );
};
