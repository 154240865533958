import { vars } from 'src/core/themes';
import { Event, Performer, Sale, Venue } from 'src/WebApiController';

export const SHIMMERING_DIV_HEIGHT_SINGLE_SALE = vars.spacing['3xl'];

export type SaleWithEvent = {
  sale: Sale;
  event: Event;
  performer?: Performer | null;
  venue?: Venue | null;
  isFlattenedMode?: boolean;
};

export type SaleFormulaFields = {
  totalNetProceeds?: number | null;
  quantitySold?: number | null;
  charges?: number | null;
  credits?: number | null;
  soldTicketCost?: number | null;
  pandL?: number | null;
  margin?: number | null;
};
