import {
  EventAccordionItemBodyComponentType,
  MinRowsForUsingVirtuoso,
} from 'src/components/Accordions';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { SalesTable } from 'src/tables/SalesTable';
import { SaleTiles } from 'src/tiles/SaleTiles';

type EventSaleBodyProps = EventAccordionItemBodyComponentType;

export const EventSaleBody = (props: EventSaleBodyProps) => {
  const isMobile = useMatchMedia('mobile');
  const Body = isMobile ? SaleTiles : SalesTable;
  const usingVirtuoso = props.salesCnt > MinRowsForUsingVirtuoso;

  return <Body {...props} useVirtuoso={usingVirtuoso} />;
};
