import {
  ComponentProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmButton } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { isDatePassedHours } from 'src/utils/dateTimeUtils';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Event,
  Feature,
  ListingClient,
  ListingMetrics,
  MergeListingsInput,
} from 'src/WebApiController';

import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';
import * as styles from './MergeListings.css';
import { MergeListingsBody } from './MergeListingsBody';
import { useMergeGroupedListingAlertDialog } from './useMergeGroupedListingsDialog';

export const MergeListingsModal = ({ event }: { event: Event }) => {
  const methods = useForm<MergeListingsInput>({
    defaultValues: {
      listingIds: [],
    },
  });

  return (
    <FormProvider {...methods}>
      <MergeListingsModalContent {...methods} event={event} />
    </FormProvider>
  );
};

const MergeListingsModalContent = ({
  event,
  formState,
  handleSubmit,
  setError,
  watch,
  getValues,
}: { event: Event } & Omit<
  ComponentProps<typeof FormProvider<MergeListingsInput, unknown>>,
  'children'
>) => {
  const { closeModal } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitting } = formState;

  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    eventsTransformed,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshMetrics } = useCatalogMetricsContext<ListingMetrics>();

  const listingIds = watch('listingIds');
  const eventData = useMemo(
    () =>
      eventsTransformed?.find(
        (ev) => ev.event.viagVirtualId === event.viagVirtualId
      ),
    [eventsTransformed, event.viagVirtualId]
  );

  const needToMergeTwoOrMoreListingsError = useContent(
    ContentId.NeedToMergeToTwoOrMoreListings
  );

  const hasMergeGroupedListingFeature = useUserHasFeature(
    Feature.MergeListingsThatBelongsToGroups
  );

  const mergeListings = useCallback(
    async (formData: MergeListingsInput, closeModalAfterMerge?: boolean) => {
      if (formData.listingIds.length < 2) {
        setError('listingIds', {
          message: needToMergeTwoOrMoreListingsError,
        });
        return;
      }

      // If this is called, there are no form errors (else onSubmit is never called)
      setIsLoading(true);

      return await tryInvokeApi(
        async () => {
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).mergeListings(formData);

          if (isSuccess(result)) {
            await refreshExpandedListItems();
            refreshMetrics?.();
            if (closeModalAfterMerge) {
              closeModal(true);
            }
          } else {
            showErrorDialog('ListingClient.mergeListings', result, {
              trackErrorData: formData,
            });
          }
        },
        (error) => {
          showErrorDialog('ListingClient.mergeListings', error, {
            trackErrorData: formData,
          });
        },
        () => setIsLoading(false)
      );
    },
    [
      activeAccountWebClientConfig,
      closeModal,
      needToMergeTwoOrMoreListingsError,
      refreshExpandedListItems,
      refreshMetrics,
      setError,
      showErrorDialog,
    ]
  );

  const {
    setAlerts,
    warningDialog,
    mergeGroupedListingHookProps,
    onSubmit,
    AlertDialog,
  } = useMergeGroupedListingAlertDialog(handleSubmit, mergeListings);

  const onSubmitHandler = useCallback(() => {
    const formData = getValues();
    const hasListingFromGroup = formData.listingIds.some(
      (formDataListingId) => {
        const listing = eventData?.listings?.find(
          (listing) => listing.id === formDataListingId
        );

        // If no listing is found in eventData.listings then that means it's in a group
        if (!listing) {
          return true;
        }

        return false;
      }
    );

    if (
      hasListingFromGroup &&
      isDatePassedHours(mergeGroupedListingHookProps.lastTimeStamp, 1)
    ) {
      setAlerts([mergeGroupedListingHookProps]);
      warningDialog.launchDialog();
    } else {
      handleSubmit(onSubmit)();
    }
  }, [
    getValues,
    mergeGroupedListingHookProps,
    eventData?.listings,
    setAlerts,
    warningDialog,
    handleSubmit,
    onSubmit,
  ]);

  const onMergeSuggestions = useCallback(
    (listingIds: number[]) => {
      mergeListings({ listingIds });
    },
    [mergeListings]
  );

  return (
    <>
      <CancellableFormHeader disabled={isLoading || isSubmitting}>
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.MergeListings} />}
        />
      </CancellableFormHeader>
      <ModalBody>
        {isLoading || (eventData && eventData.listings == null) ? (
          <PosSpinner />
        ) : (
          eventData && (
            <>
              <ModalBodyHeaderContainer>
                <Summary event={eventData.event} />
              </ModalBodyHeaderContainer>
              <ModalBodyDataContainer>
                <MergeListingsBody
                  {...eventData}
                  disabled={isLoading || isSubmitting}
                  onMergeSuggestions={onMergeSuggestions}
                  footer={
                    listingIds?.length ? (
                      <div className={styles.footerButtonsContainer}>
                        <ConfirmButton
                          onClick={
                            hasMergeGroupedListingFeature
                              ? onSubmitHandler
                              : handleSubmit(onSubmit)
                          }
                          disabled={isLoading || isSubmitting}
                          textContentId={ContentId.Merge}
                        />
                      </div>
                    ) : undefined
                  }
                />
                {AlertDialog}
              </ModalBodyDataContainer>
            </>
          )
        )}
      </ModalBody>
      <ModalFooter>
        <CancellableFormFooter
          disabled={isLoading || isSubmitting}
        ></CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
