import clsx from 'clsx';
import { ReactNode } from 'react';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { IconsFill, LocationOutlineIcon } from 'src/svgs/Viagogo';

import * as styles from './VenueNameDisplay.css';

export type VenueNameDisplayProps = {
  venueName?: ReactNode;
  /**
   * The location of the venue (eg. City, State).
   */
  venueLocation?: ReactNode;
  slimMode?: boolean;
  showTitle?: boolean;
};

export function VenueNameDisplay({
  venueName,
  venueLocation,
  slimMode,
  showTitle = true,
}: VenueNameDisplayProps) {
  return venueName || venueLocation ? (
    <Stack gap="m" alignItems="center" width={slimMode ? 'full' : undefined}>
      {(venueName || venueLocation) && (
        <>
          {!slimMode && (
            <div className={styles.iconWrapper}>
              <LocationOutlineIcon
                size={vars.iconSize.s}
                fill={IconsFill.textDisabled}
              />
            </div>
          )}
          <Stack
            direction="row"
            flexWrap="wrap"
            className={clsx(styles.venueNameLocation, {
              [styles.slimVenueNameLocation]: slimMode,
            })}
          >
            {venueName && (
              <span
                className={clsx(styles.venueName, {
                  [styles.slimVenueName]: slimMode,
                })}
                title={
                  showTitle && typeof venueName === 'string'
                    ? venueName
                    : undefined
                }
              >
                {venueName}
              </span>
            )}
            {venueLocation && (
              <>
                {/* TODO: create a component for joining react components */}
                {venueName && <>&nbsp;&nbsp;</>}
                <span
                  className={clsx(styles.venueLocation, {
                    [styles.slimVenueLocation]: slimMode,
                  })}
                  title={
                    showTitle && typeof venueLocation === 'string'
                      ? venueLocation
                      : undefined
                  }
                >
                  {venueLocation}
                </span>
              </>
            )}
          </Stack>
        </>
      )}
    </Stack>
  ) : null;
}
