import { MultiSelectActionsContainer } from 'src/components/common/MultiSelect/MultiSelectActionsContainer';
import { SettingsActions } from 'src/components/Purchases/BulkActions/PurchasesGlobalBulkActions/Actions/SettingsActions';
import { TagsActions } from 'src/components/Purchases/BulkActions/PurchasesGlobalBulkActions/Actions/TagsActions';

import { AccountingActions } from './Actions/AccountingActions';
import { PurchasesBulkActionProps } from './PurchasesBulkActions.types';

export const PurchasesGlobalBulkActions = ({
  ...props
}: PurchasesBulkActionProps) => {
  return (
    <MultiSelectActionsContainer>
      <TagsActions {...props} />
      <SettingsActions {...props} />
      <AccountingActions {...props} />
    </MultiSelectActionsContainer>
  );
};
