import { useEffect, useRef } from 'react';
import * as Tabs from 'src/core/ui/Tabs';

import * as styles from './TabPanel.css';
import type { TabPanelProps } from './TabPanel.types';

/**
 * Component that renders tabs in a bar that allows users to swap between content
 */
export const TabPanel = ({
  tabs,
  activeTabIndex,
  onValueChange,
  orientation = 'horizontal',
  tabListProps,
  tabContentProps,
  ...props
}: TabPanelProps) => {
  const activeTab = tabs[activeTabIndex];

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, [activeTab]);

  return (
    <Tabs.Root
      value={activeTab.value}
      onValueChange={onValueChange}
      orientation={orientation}
      {...props}
    >
      <Tabs.List ref={ref} orientation={orientation} {...tabListProps}>
        {tabs.map((tab) => (
          <Tabs.Trigger
            key={tab.value}
            value={tab.value}
            className={styles.tabTitle}
            orientation={orientation}
          >
            {tab.title}
          </Tabs.Trigger>
        ))}
      </Tabs.List>
      {tabs.map((tab) => (
        <Tabs.Content
          key={tab.value}
          value={tab.value}
          orientation={orientation}
          {...tabContentProps}
        >
          {tab.content}
        </Tabs.Content>
      ))}
    </Tabs.Root>
  );
};
