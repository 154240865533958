import { createColumnHelper } from '@tanstack/react-table';
import { ListingDeliveryTypeDisplay } from 'src/components/Listings/ListingDeliveryTypeDisplay';
import {
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_XL,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { isContentId, useContentContext } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { ReportMetricsResponse } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { useMapDeliveryTypesToString } from 'src/hooks/useMapDeliveryTypesToString';
import { FormatOption } from 'src/modals/EditTableColumns';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';
import { PaymentSize } from 'src/svgs/Viagogo';
import { LinkCell } from 'src/tables/ReportTableCommon/LinkCell';
import { SHIMMERING_DIV_HEIGHT_LISTING_REPORT } from 'src/tables/ReportTableCommon/ReportTableCommon.constants';
import { TableHeader } from 'src/tables/ReportTableCommon/TableHeader';
import { TextCell } from 'src/tables/ReportTableCommon/TextCell';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { DateCell } from 'src/tables/Table/TableCells';
import { InHandCell } from 'src/tables/Table/TableCells';
import { getColumnPersonalization } from 'src/utils/columns/columnPersonalizationUtils';
import { ListingReportTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { ContentIds } from 'src/utils/constants/contentIdDataMap';
import {
  PAYMENT_METHOD_TYPE_TO_CID,
  PURCHASE_PAYMENT_STATUS_TO_CID,
  SALE_PAYMENT_STATE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { getListingDetailsRelativeUrl } from 'src/utils/inventoryUtils';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { getPaymentMethodIcon } from 'src/utils/paymentMethodUtils';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { getSaleDetailsRelativeUrl } from 'src/utils/saleUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  DeliveryType,
  PointOfSalePaymentState,
  PurchasePaymentMethodType,
  PurchasePaymentStatus,
} from 'src/WebApiController';

const SECTION_TYPE = SectionType.ListingsReport;

const columnHelper = createColumnHelper<ReportMetricsResponse | null>();

const accessorFn = (columnId: ListingReportTableColumnId) => {
  return (data: ReportMetricsResponse | null) =>
    data?.[columnId.toUpperCase()] ?? '';
};

export const LISTING_REPORT_SPECIAL_TABLE_COLUMNS_CONFIG = {
  [ListingReportTableColumnId.EventId]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.EventId),
    {
      id: ListingReportTableColumnId.EventId,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => {
        const eventId = params.row.original?.eventId;
        if (!eventId) {
          return (
            <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
          );
        }
        const eventUrl = createInventoryEventUrl(eventId);
        return (
          <LinkCell
            text={String(params.getValue())}
            url={eventUrl}
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.EventDate]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.EventDate),
    {
      id: ListingReportTableColumnId.EventDate,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<string>();
        const { userDefinedPrecision = FormatOption.DateTime_MMDD } =
          useColumnUserSetting(columnId, SECTION_TYPE);

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const dateValue = value ? new Date(value).toISOString() : value;

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={dateValue}
            align="left"
            enableUtcFallback
            hideRelativeTerms
            useSiteTimeZone={false}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.SaleId]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.SaleId),
    {
      id: ListingReportTableColumnId.SaleId,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const saleId = getValue<string | null>();

        if (!saleId) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const saleIds = [Number(saleId)];

        return (
          <LinkCell
            text={saleIds.map((saleId) => `#${saleId}`)}
            url={saleIds.map((saleId) => getSaleDetailsRelativeUrl(saleId))}
            separator={<span>{', '}</span>}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.HoldId]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.HoldId),
    {
      id: ListingReportTableColumnId.HoldId,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const holdId = getValue<string | null>();

        if (!holdId) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const holdIds = [Number(holdId)];

        return (
          <LinkCell
            text={holdIds.map((holdId) => `#${holdId}`)}
            url={holdIds.map((holdId) => getSaleDetailsRelativeUrl(holdId))}
            separator={<span>{', '}</span>}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.DeliveryType]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.DeliveryType),
    {
      id: ListingReportTableColumnId.DeliveryType,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );
        const value = getValue<DeliveryType | null>();

        const strDeliveryTypes = useMapDeliveryTypesToString({
          deliveryTypesObj: { delivType: value },
        });

        if (!original || !value) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <TableCellDiv align="left" title={strDeliveryTypes}>
            <ListingDeliveryTypeDisplay
              listing={{ delivType: value }}
              userDefinedPrecision={userDefinedPrecision}
              showIconTitle={false}
            />
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.PurchasePaymentStatus]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.PurchasePaymentStatus),
    {
      id: ListingReportTableColumnId.PurchasePaymentStatus,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue }) {
        const value = getValue<PurchasePaymentStatus | null>();

        const { contentResolver } = useContentContext();

        if (value == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const values = [value];

        const contentIds = values.map((v) => PURCHASE_PAYMENT_STATUS_TO_CID[v]);
        const contentText = contentIds
          .map((cid) => {
            if (isContentId(cid)) {
              return contentResolver(ContentIds[cid as ContentId]);
            } else {
              return cid;
            }
          })
          .join(', ');

        return (
          <TableCellDiv align="left" title={contentText}>
            {contentText}
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.PaymentMethodType]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.PaymentMethodType),
    {
      id: ListingReportTableColumnId.PaymentMethodType,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );
        const { contentResolver } = useContentContext();

        const value = getValue<string | null>();
        if (!original || !value) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const values = [value as PurchasePaymentMethodType];

        if (userDefinedPrecision === FormatOption.EnumDisplay_Text) {
          const contentIds = values.map((v) => PAYMENT_METHOD_TYPE_TO_CID[v]);
          const contentText = contentIds
            .map((cid) => {
              if (isContentId(cid)) {
                return contentResolver(ContentIds[cid as ContentId]);
              } else {
                return cid;
              }
            })
            .join(', ');

          return (
            <TableCellDiv align="left" title={contentText}>
              {contentText}
            </TableCellDiv>
          );
        }

        return (
          <TableCellDiv
            align="left"
            title={values?.length === 1 ? values[0] : undefined}
          >
            {values.map((v, i) => {
              const PurchaseMethodIcon = getPaymentMethodIcon(v);
              return (
                <PurchaseMethodIcon
                  size={PaymentSize.s}
                  key={`paymenticon-${i}`}
                  isSquare={false}
                />
              );
            })}
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.InHandDate]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.InHandDate),
    {
      id: ListingReportTableColumnId.InHandDate,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue<string | null>();
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <TableCellDiv align="left">
            <InHandCell date={value ? new Date(value) : null} />
          </TableCellDiv>
        );
      },
      sortingFn: 'datetime',
    }
  ),
  [ListingReportTableColumnId.PurchaseId]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.PurchaseId),
    {
      id: ListingReportTableColumnId.PurchaseId,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const purchaseId = getValue<string | null>();

        if (!purchaseId) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const purchaseIds = [Number(purchaseId)];
        return (
          <LinkCell
            text={purchaseIds.map((purchaseId) => `#${purchaseId}`)}
            url={purchaseIds.map((purchaseId) =>
              getPurchaseOrderRelativeUrl(purchaseId)
            )}
            separator={<span>{', '}</span>}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.SalePaymentState]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.SalePaymentState),

    {
      id: ListingReportTableColumnId.SalePaymentState,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue }) {
        const value = getValue<string | null>();
        if (value == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const values = [value as PointOfSalePaymentState];

        const pmtStates = values.map((s) => SALE_PAYMENT_STATE_TO_CID[s]);

        const valuesDeduped = Array.from(new Set(pmtStates));

        return (
          <TableCellDiv align="left">
            {valuesDeduped.flatMap((contentId, index) => {
              if (index < valuesDeduped.length - 1) {
                return [
                  <Content key={index} id={contentId} />,
                  <span key={`separator-${index}`}>{', '}</span>,
                ];
              }

              return [<Content key={index} id={contentId} />];
            })}
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.ListingId]: columnHelper.accessor(
    accessorFn(ListingReportTableColumnId.ListingId),
    {
      id: ListingReportTableColumnId.ListingId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const listingId = getValue<string>();

        if (!listingId) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const url = getListingDetailsRelativeUrl(listingId!);
        return (
          <LinkCell
            text={`#${listingId}`}
            url={url}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
} as const;

export const listingReportV2TableColumnDef = (
  columnId: ListingReportTableColumnId
) => {
  const specialConfig = Object.entries(
    LISTING_REPORT_SPECIAL_TABLE_COLUMNS_CONFIG
  ).find(([key]) => key === columnId)?.[1];

  if (specialConfig) {
    return specialConfig;
  }

  const personalization = getColumnPersonalization({
    id: columnId,
    sectionType: SECTION_TYPE,
  });

  const align = personalization.isNumeric ? 'right' : 'left';

  return columnHelper.accessor(accessorFn(columnId), {
    id: columnId,
    header: (params) => (
      <TableHeader {...params} align={align} sectionType={SECTION_TYPE} />
    ),
    size: COLUMN_DEFAULT_SIZE_BASE,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell(params) {
      const value = params.getValue<string | null>();

      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SECTION_TYPE
      );

      const { getUiCurrency } = useLocalizationContext();

      if (!params.row.original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
        );
      }

      // Handle columns with personalization
      if (personalization.isCurrency) {
        const uiCurrency = getUiCurrency(params.row.original['CurrencyCode']);

        return (
          <TableCellDiv align={align}>
            {userDefinedPrecision ? (
              applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: value,
                currencyCode: uiCurrency.code,
                currencyDecimalDigits: uiCurrency.dec,
              })
            ) : (
              <Content id={ContentId.NA} />
            )}
          </TableCellDiv>
        );
      }

      if (personalization.isNumeric) {
        return (
          <TableCellDiv align={align}>
            {userDefinedPrecision
              ? applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: value,
                  isPercent: personalization.isPercent,
                })
              : value ?? <Content id={ContentId.NA} />}
          </TableCellDiv>
        );
      }

      if (personalization.isDateTime) {
        const dateValue = value ? new Date(value).toISOString() : value;
        return (
          <DateCell
            precision={userDefinedPrecision}
            date={dateValue}
            align={align}
            hideRelativeTerms
            useSiteTimeZone={columnId !== ListingReportTableColumnId.EventDate}
          />
        );
      }

      return <TextCell {...params} align={align} sectionType={SECTION_TYPE} />;
    },
    footer: function Footer(params) {
      const original = params.table.options.meta?.summaryData;

      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SECTION_TYPE
      );

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
        );
      }
      const value = params.column.accessorFn?.(original, -1);

      if (personalization.isCurrency) {
        return (
          <TableCellDiv align="right">
            {userDefinedPrecision ? (
              applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: value,
                currencyCode: original['CurrencyCode'],
              })
            ) : (
              <Content id={ContentId.NA} />
            )}
          </TableCellDiv>
        );
      }

      if (personalization.isNumeric) {
        return (
          <TableCellDiv align="right">
            {userDefinedPrecision
              ? applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: value,
                  isPercent: personalization.isPercent,
                })
              : value ?? <Content id={ContentId.NA} />}
          </TableCellDiv>
        );
      }

      return <TableCellDiv align={align}>{value}</TableCellDiv>;
    },
  });
};
