import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { VenueNameDisplay } from 'src/components/Events/EventInfo';
import { ListingDisplay } from 'src/components/Listings/ListingDisplay';
import {
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { TableShimmeringDiv } from 'src/tables/Table';
import { getEventDisplayText } from 'src/utils/adGroupUtils';
import { AdGroupsTableColumnId } from 'src/utils/columns/adGroups/adGroupsColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID } from 'src/utils/constants/contentIdMaps';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { sortMoney } from 'src/utils/tableUtils';
import {
  AdGroupState,
  EventWithData,
  Listing,
  MoneyType,
  Performer,
  RowInfo,
  SectionInfo,
  TicketClassInfo,
  TopLevelCategory,
  UiMoney,
  Venue,
} from 'src/WebApiController';

import { SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS } from '../AdGroupsTable.type';
import { AdGroupActionsCell } from './AdGroupsActionsCell';

export type AdGroupRow = {
  id: string;
  name: string;
  state: AdGroupState;
  campaignId: string;
  baseBid: MoneyType;
  maxBid: MoneyType;
  dailyBudget: MoneyType;
  genre?: TopLevelCategory;
  performer?: Performer;
  venue?: Venue;
  event?: EventWithData;
  ticketClass?: TicketClassInfo;
  section?: SectionInfo;
  row?: RowInfo;
  listing?: Listing;
  bidModifier?: number;
  adSpend: UiMoney | null | undefined;
  salesProceeds: UiMoney | null | undefined;
  qtySold: number | null | undefined;
};

const columnHelper = createColumnHelper<AdGroupRow | null>();

export const ADGROUPS_TABLE_COLUMNS_CONFIG: ColumnDef<
  AdGroupRow | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>[] = [
  columnHelper.accessor((data) => (data ? data.name || '' : null), {
    id: AdGroupsTableColumnId.Name,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[AdGroupsTableColumnId.Name]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      return <TableCellDiv align="left">{getValue<string>()}</TableCellDiv>;
    },
  }),

  columnHelper.accessor((data) => (data ? data.state || '' : null), {
    id: AdGroupsTableColumnId.State,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[AdGroupsTableColumnId.State]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      // TODO
      const value = getValue<string>();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      return <TableCellDiv align="left">{value}</TableCellDiv>;
    },
  }),

  columnHelper.accessor((data) => (data ? data.baseBid : null), {
    id: AdGroupsTableColumnId.BaseBid,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.BaseBid
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue }) => {
      const value = getValue()?.amount;
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }
      return <TableCellDiv align="right">{value}</TableCellDiv>;
    },
    sortingFn: sortMoney<AdGroupRow>, // TODO: Use UiMoney
  }),

  columnHelper.accessor((data) => (data ? data.maxBid : null), {
    id: AdGroupsTableColumnId.MaxBid,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[AdGroupsTableColumnId.MaxBid]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue }) => {
      const value = getValue()?.amount;
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }
      return <TableCellDiv align="right">{value}</TableCellDiv>;
    },
    sortingFn: sortMoney<AdGroupRow>, // TODO: Use UiMoney
  }),

  columnHelper.accessor((data) => (data ? data.dailyBudget : null), {
    id: AdGroupsTableColumnId.DailyBudget,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.DailyBudget
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue }) => {
      const value = getValue()?.amount;
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }
      return <TableCellDiv align="right">{value}</TableCellDiv>;
    },
    sortingFn: sortMoney<AdGroupRow>, // TODO: Use UiMoney
  }),

  columnHelper.accessor((data) => (data ? data.genre : null), {
    id: AdGroupsTableColumnId.Genre,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[AdGroupsTableColumnId.Genre]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<string>();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      return <TableCellDiv align="left">{value}</TableCellDiv>;
    },
  }),

  columnHelper.accessor((data) => (data ? data.performer : null), {
    id: AdGroupsTableColumnId.Performer,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.Performer
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<Performer>()?.name;

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      return <TableCellDiv align="left">{value}</TableCellDiv>;
    },
  }),

  columnHelper.accessor((data) => (data ? data.venue : null), {
    id: AdGroupsTableColumnId.Venue,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[AdGroupsTableColumnId.Venue]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const venue = getValue<Venue>();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      return (
        <TableCellDiv align="left" title={venue?.descr ?? undefined}>
          <VenueNameDisplay venueLocation={venue?.descr} />
        </TableCellDiv>
      );
    },
  }),

  columnHelper.accessor((data) => (data ? data.event : null), {
    id: AdGroupsTableColumnId.Event,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[AdGroupsTableColumnId.Event]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const event = getValue<EventWithData>()?.event;
      const eventDisplayText = event ? getEventDisplayText(event) : '';

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      return <TableCellDiv align="left">{eventDisplayText}</TableCellDiv>;
    },
  }),

  columnHelper.accessor((data) => (data ? data.ticketClass : null), {
    id: AdGroupsTableColumnId.TicketClass,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.TicketClass
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<TicketClassInfo>()?.name;

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      return <TableCellDiv align="left">{value}</TableCellDiv>;
    },
  }),

  columnHelper.accessor((data) => (data ? data.section : null), {
    id: AdGroupsTableColumnId.Section,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.Section
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<SectionInfo>()?.name;

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      return <TableCellDiv align="left">{value}</TableCellDiv>;
    },
  }),

  columnHelper.accessor((data) => (data ? data.listing : null), {
    id: AdGroupsTableColumnId.Listing,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.Listing
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<Listing | null | undefined>();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      return <>{value ? <ListingDisplay listing={value!} /> : <></>}</>;
    },
  }),

  columnHelper.accessor((data) => (data ? data.adSpend : null), {
    id: AdGroupsTableColumnId.AdSpend,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.AdSpend
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<UiMoney | null | undefined>();
      const { getUiCurrency } = useLocalizationContext();
      const currency = getUiCurrency(value?.currency);

      if (!original || value === undefined) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      if (value === null) return <Content id={ContentId.NA} />;

      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            inputNumber: value.amt,
            currencyCode: currency.code,
            currencyDecimalDigits: currency.dec,
          })}
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.salesProceeds : null), {
    id: AdGroupsTableColumnId.SoldProceeds,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.SoldProceeds
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<UiMoney | null | undefined>();
      const { getUiCurrency } = useLocalizationContext();
      const currency = getUiCurrency(value?.currency);

      if (!original || value === undefined) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      if (value === null) return <Content id={ContentId.NA} />;

      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            inputNumber: value.amt,
            currencyCode: currency.code,
            currencyDecimalDigits: currency.dec,
          })}
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.qtySold : null), {
    id: AdGroupsTableColumnId.SoldQty,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.SoldQty
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue();
      if (!original || value === undefined) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      if (value === null) return <Content id={ContentId.NA} />;

      return <TableCellDiv align="right">{value}</TableCellDiv>;
    },
  }),

  columnHelper.accessor((data) => (data ? data.bidModifier : null), {
    id: AdGroupsTableColumnId.BidModifier,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.BidModifier
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue()?.value ?? getValue();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
        );
      }

      return <TableCellDiv align="left">{value}</TableCellDiv>;
    },
  }),

  columnHelper.display({
    id: AdGroupsTableColumnId.Actions,
    enableSorting: false,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            ADGROUPS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdGroupsTableColumnId.Actions
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ row: { original } }) => {
      return !original ? (
        <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADGROUPS} />
      ) : (
        <AdGroupActionsCell adGroupId={original.id} />
      );
    },
  }),
];
