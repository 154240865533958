import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useBuyerSelector } from 'src/components/Selectors/BuyerSelector/useBuyerSelector';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PosManagedMarketplaceSaleInput } from 'src/WebApiController';

type MarketplaceSaleInputBuyer = Pick<
  PosManagedMarketplaceSaleInput,
  'marketplaceSaleId' | 'buyerEmail' | 'buyerName' | 'buyerPhone' | 'saleId'
>;

export const BuyerSection = ({ disabled }: { disabled?: boolean }) => {
  const requiredMsg = useContent(ContentId.Required);

  const { register, formState, setValue, getValues, clearErrors } =
    useFormContext<MarketplaceSaleInputBuyer>();

  const { buyerEmail } = getValues();
  const { query, posSelectProps } = useBuyerSelector({
    disabled,
    placeholderText: ContentId.AddNewBuyer,
  });
  const [selectedBuyer, setSelectedBuyer] = useState<string>(
    buyerEmail?.value ?? ''
  );

  const isEdit = formState?.defaultValues?.saleId != null;
  const buyerEmailError = formState.errors.buyerEmail?.message;
  const orderIdError = formState.errors.marketplaceSaleId?.message;
  return (
    <DetailSection name={<Content id={ContentId.YourBuyer} />}>
      <SectionContent>
        <DetailGroup>
          <PosFormField
            label={<Content id={ContentId.ExternalSaleId} />}
            errors={orderIdError}
          >
            <PosTextField
              inputMode="email"
              disabled={disabled || isEdit}
              rootProps={{
                state: getTextFieldState(orderIdError),
              }}
              {...register('marketplaceSaleId', {
                required: requiredMsg,
              })}
            />
          </PosFormField>
          <PosFormField label={<Content id={ContentId.ChooseBuyer} />}>
            <PosSelect
              disabled={disabled}
              {...posSelectProps}
              style={{ width: '100%' }}
              enableEmptySelection
              value={selectedBuyer}
              onChange={(newSelection) => {
                setSelectedBuyer(newSelection);
                if (newSelection !== 'AddNewBuyer') {
                  clearErrors('buyerEmail');
                  setValue('buyerEmail', posChangedField(newSelection));
                  const buyer = query.data?.find(
                    (b) => b.email === newSelection
                  );
                  if (buyer) {
                    setValue('buyerName', posChangedField(buyer.name));
                    setValue('buyerPhone', posChangedField(buyer.phone));
                  }
                }
              }}
            />
          </PosFormField>
          <PosFormField
            label={<Content id={ContentId.Email} />}
            errors={buyerEmailError}
          >
            <PosTextField
              inputMode="email"
              disabled={disabled}
              rootProps={{
                state: getTextFieldState(buyerEmailError),
              }}
              {...register('buyerEmail.value', {
                required: requiredMsg,
                onChange: ({ target: { value } }) => {
                  const buyer = query.data?.find((b) => b.email === value);
                  if (!buyer) {
                    setSelectedBuyer('');
                  } else {
                    setValue('buyerEmail', posChangedField(value));
                    setValue('buyerName', posChangedField(buyer.name));
                    setValue('buyerPhone', posChangedField(buyer.phone));
                    setSelectedBuyer(value);
                  }
                },
              })}
            />
          </PosFormField>
          <PosFormField label={<Content id={ContentId.Name} />}>
            <PosTextField
              disabled={disabled}
              {...register('buyerName.value', {
                required: requiredMsg,
                onChange: ({ target: { value } }) => {
                  setValue('buyerName', posChangedField(value));
                },
              })}
            />
          </PosFormField>
          <PosFormField label={<Content id={ContentId.Phone} />}>
            <PosTextField
              disabled={disabled}
              inputMode="tel"
              {...register('buyerPhone.value', {
                required: requiredMsg,
                onChange: ({ target: { value } }) => {
                  setValue('buyerPhone', posChangedField(value));
                },
              })}
            />
          </PosFormField>
        </DetailGroup>
      </SectionContent>
    </DetailSection>
  );
};
