import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  Feature,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';

export type BulkUpdateDeliveryOverrideDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  onBulkUpdate: (
    updateSalesOfListings: boolean,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.TicketGroup;
  skipTicketGroups?: boolean;
  purchaseOrderQuery?: PurchaseOrderQuery;
  disabled?: boolean;
  onSaveOverrideSettings?: () => Promise<void>;
  onCancel: () => void;
  backfillDeliveryStrategyFeature:
    | Feature.BackfillDeliveryStrategyInventoryEvent
    | Feature.BackfillDeliveryStrategyPurchaseEvent
    | Feature.BackfillDeliveryStrategyVendorAccount;
  backfillDeliveryStrategyToSaleFeature:
    | Feature.BackfillDeliveryStrategyInventoryEventToSales
    | Feature.BackfillDeliveryStrategyPurchaseEventToSales
    | Feature.BackfillDeliveryStrategyVendorAccountToSales;
};

enum UpdateFulfillmentMode {
  UpdateExistingPurchasesAndListings = 'UpdateExistingPurchasesAndListings',
  UpdateExistingListings = 'UpdateExistingListings',
  UpdateNone = 'UpdateNone',
}

export function BulkUpdateDeliveryOverrideDialog({
  updateKey,
  entityType,
  skipTicketGroups,
  purchaseOrderQuery,
  onBulkUpdate,
  onSaveOverrideSettings,
  onCancel,
  disabled,
  backfillDeliveryStrategyFeature,
  backfillDeliveryStrategyToSaleFeature,
  ...dialogProps
}: BulkUpdateDeliveryOverrideDialogProps) {
  const [updateFulfillmentMode, setUpdateFulfillmentMode] =
    useState<UpdateFulfillmentMode>(UpdateFulfillmentMode.UpdateNone);
  const [updateSalesOfListings, setUpdateSalesOfListings] =
    useState<boolean>(false);
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const hasBackfillDeliveryStrategyFeature = useUserHasFeature(
    backfillDeliveryStrategyFeature
  );
  const hasBackfillDeliveryStrategyToSaleFeature = useUserHasFeature(
    backfillDeliveryStrategyToSaleFeature
  );

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity?.(0);
        await refreshExpandedListItems();

        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel?.();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, preview, initJob, stage } = useBulkEditHub(
    entityType,
    BulkActionType.BackfillDeliverySettings,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature = useMemo(
    () => bulkEditHub?.state === HubConnectionState.Connected,
    [bulkEditHub?.state]
  );

  const onBeforeSubmit = useCallback(() => {
    if (updateFulfillmentMode === UpdateFulfillmentMode.UpdateNone) {
      onSaveOverrideSettings?.();
      onCancel?.();
      return false; // don't proceed to preview
    }

    return true; // proceed to preview
  }, [onCancel, onSaveOverrideSettings, updateFulfillmentMode]);

  const onBulkEditSubmitHandler = useCallback(
    async (
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onBulkUpdate(updateSalesOfListings, false, onPreviewReceived);
      } else {
        // If we're doing update, only save settings when confirmed
        await onSaveOverrideSettings?.();
        onBulkUpdate(
          updateSalesOfListings,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [
      onBulkUpdate,
      updateSalesOfListings,
      onSaveOverrideSettings,
      hasBackgroundBulkEditFeature,
      preview,
    ]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      header={<Content id={ContentId.UpdateFulfillmentSettings} />}
      size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
      {...dialogProps}
      onOpened={() => {
        setUpdateFulfillmentMode(UpdateFulfillmentMode.UpdateNone);
        setUpdateSalesOfListings(false);
        initJob();
      }}
      onKeyUp={(e) => {
        if (submitButtonRef.current && e.key === 'Enter') {
          submitButtonRef.current.click();
        }
      }}
      onClosed={() => {
        if (updateFulfillmentMode !== UpdateFulfillmentMode.UpdateNone) {
          setMainDialogOpened(false);
        }
        // Call the outside one if there is one
        dialogProps.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={entityType}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onBeforeSubmit={onBeforeSubmit}
          onSubmit={onBulkEditSubmitHandler}
          submittButtonRef={submitButtonRef}
          onSubmitContentIdOverride={
            updateFulfillmentMode === UpdateFulfillmentMode.UpdateNone
              ? ContentId.Confirm
              : undefined
          }
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={entityType}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack direction="column" gap="l" width="full" alignItems="center">
          {skipTicketGroups ? (
            <Content id={ContentId.UpdateFulfillmentSettingsMessage} />
          ) : (
            <Content id={ContentId.UpdateFulfillmentSettingsMessagePurchase} />
          )}
        </Stack>
        {hasBackfillDeliveryStrategyFeature && (
          <BulkUpdateDeliveryOverrideDialogModeRadioGroup
            onValueChange={(mode) => {
              setUpdateFulfillmentMode(mode);
              if (mode === UpdateFulfillmentMode.UpdateNone) {
                setUpdateSalesOfListings(false);
              }
            }}
            updateFulfillmentMode={updateFulfillmentMode}
            skipTicketGroups={skipTicketGroups}
            disabled={disabled}
          />
        )}
        {hasBackfillDeliveryStrategyFeature &&
          hasBackfillDeliveryStrategyToSaleFeature && (
            <Checkbox
              labelPosition="right"
              checked={updateSalesOfListings}
              onChange={() => {
                setUpdateSalesOfListings((prev) => !prev);
              }}
              disabled={
                isLoading ||
                updateFulfillmentMode === UpdateFulfillmentMode.UpdateNone
              }
              label={<Content id={ContentId.UpdateAssociatedSales} />}
            />
          )}
      </BulkEditStatus>
    </GenericDialog>
  );
}

export const BulkUpdateDeliveryOverrideDialogModeRadioGroup = ({
  onValueChange,
  updateFulfillmentMode,
  skipTicketGroups,
  disabled,
}: {
  onValueChange?: ((value: UpdateFulfillmentMode) => void) | undefined;
  updateFulfillmentMode: UpdateFulfillmentMode;
  skipTicketGroups?: boolean;
  disabled?: boolean;
}) => {
  return (
    <RadioGroup
      onValueChange={(value: string) => {
        onValueChange?.(value as UpdateFulfillmentMode);
      }}
      value={updateFulfillmentMode}
    >
      <Stack direction="column" gap="m" style={{ textTransform: 'capitalize' }}>
        {skipTicketGroups ? (
          <Radio
            value={UpdateFulfillmentMode.UpdateExistingListings}
            disabled={disabled}
            label={
              <Stack direction="column">
                <Content id={ContentId.UpdateExistingListings} />
              </Stack>
            }
          />
        ) : (
          <Radio
            value={UpdateFulfillmentMode.UpdateExistingPurchasesAndListings}
            disabled={disabled}
            label={
              <Stack direction="column">
                <Content id={ContentId.UpdateExistingPurchasesAndListings} />
              </Stack>
            }
          />
        )}
        <Radio
          value={UpdateFulfillmentMode.UpdateNone}
          disabled={disabled}
          label={
            <Stack direction="column">
              {skipTicketGroups ? (
                <Content id={ContentId.DontUpdateExistingListings} />
              ) : (
                <Content id={ContentId.DontUpdateExistingPurchasesOrListings} />
              )}
            </Stack>
          }
        />
      </Stack>
    </RadioGroup>
  );
};
