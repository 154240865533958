import clsx from 'clsx';
import { isEmpty, union } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import { PurchasesSideTable } from 'src/components/Purchases/PurchaseEventPage/PurchasesSideTable';
import { SalesSideTable } from 'src/components/Sales/SaleEventPage/SaleSideTable';
import { useAppContext } from 'src/contexts/AppContext';
import {
  EventSessionRole,
  useEventHubContext,
} from 'src/contexts/EventHubContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { Stack } from 'src/core/ui';
import * as Tabs from 'src/core/ui/Tabs';
import { useListingGroupPricingSettings } from 'src/hooks/api/useListingGroupPricingSettings';
import {
  ListingCompsNumberRange,
  ListingCompsSpectrum,
  useComparableListingsVenueMapColor,
} from 'src/hooks/useComparableListingsVenueMapColor';
import { useGetAccountAutoPricingSettings } from 'src/hooks/useGetAccountAutoPricingSettings';
import { useGetCompListings } from 'src/hooks/useGetCompListings';
import { useListingHasVenueMapInfo } from 'src/hooks/useListingHasVenueMapInfo';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useUserCanSetPrice } from 'src/hooks/useUserHasListingPermissions';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { useAutoPricingPreview } from 'src/modals/ListingDetails/components/AutoPricePreview/useAutoPricePreview';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import { getSectionInfoDisplay } from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { getListingDetailsUpdateInput } from 'src/utils/inventoryUtils';
import {
  getCompleteEventConfigScoreOverrides,
  getMatchingSectionRow,
  getSeatScore,
} from 'src/utils/seatScoreUtils';
import {
  CompListing,
  Event,
  Feature,
  Listing,
  ListingDetailsPricingUpdates,
  ListingDetailsUpdateInput,
  ListingGroup,
  ListingQuery,
  SectionInfo,
} from 'src/WebApiController';

import { ListingWithSeatScore } from '../../ConnectedComparableListings';
import { MapWithCompListingsPreview } from '../../MapWithCompListingsPreview';
import { InventoryEventPageSeatMapV2 } from '../InventoryEventPageSeatMapV2';
import { NoteSection } from '../Sections/NoteSection';
import { NoteSectionV2 } from '../Sections/NoteSectionV2';
import { MarketListingHeader, SidePanelTab } from './MarketListingHeader';
import * as styles from './MarketListings.css';
import { VenueMapRightNowStats } from './VenueMapStats';

type MarketListingFormProps = {
  event: Event;
  eventListings?: Listing[] | null;
  selectedListingIds: number[];
};

export const MarketListingsForm = ({
  event,
  eventListings,
  selectedListingIds,
}: MarketListingFormProps) => {
  const selectedListing = useMemo(() => {
    if (selectedListingIds.length <= 1) {
      let listing = eventListings?.find((l) => l.id === selectedListingIds[0]);
      if (!listing) {
        listing = eventListings
          ?.flatMap((l) => flattenListingGroup(l))
          .find((l) => l.isFull && l.id === selectedListingIds[0]) as Listing;
      }

      if (!listing?.isLtGrp) {
        return listing;
      }
    }
    return undefined;
  }, [eventListings, selectedListingIds]);

  const selectedGroup = useMemo(() => {
    return eventListings?.find((l) => {
      const lg = l as ListingGroup;

      return (
        lg.isLtGrp &&
        lg.groupType == 'Leader' &&
        selectedListingIds.includes(lg.id)
      );
    });
  }, [eventListings, selectedListingIds]);

  const { pricingSettings: groupPricingSettings, loaded: groupSettingLoaded } =
    useListingGroupPricingSettings(selectedListing?.ltGrpId);
  const {
    pricingSettings: accountPricingSettings,
    loaded: accountSettingLoaded,
  } = useGetAccountAutoPricingSettings();

  // React-hook-form setup
  const methods = useForm<ListingDetailsUpdateInput>({
    defaultValues: getListingDetailsUpdateInput(
      selectedListing,
      null,
      undefined,
      accountPricingSettings,
      groupPricingSettings
    ),
  });

  useEffect(() => {
    if (accountSettingLoaded || groupSettingLoaded) {
      methods.reset(
        getListingDetailsUpdateInput(
          selectedListing,
          null,
          undefined,
          accountPricingSettings,
          groupPricingSettings
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedListing, accountSettingLoaded, groupSettingLoaded]);

  const canSetPrice = useUserCanSetPrice(selectedListing, false);

  return canSetPrice ? (
    <FormProvider {...methods}>
      <MarketListingsWithAutoPricing
        event={event}
        eventListings={eventListings}
        listing={selectedListing}
        listingGroup={selectedGroup}
        selectedListingIds={selectedListingIds}
      />
    </FormProvider>
  ) : undefined;
};

type MarketListingsWithAutoPricingProps = {
  event: Event;
  eventListings?: Listing[] | null;
  listing: Listing | undefined;
  listingGroup: Listing | undefined;
  selectedListingIds: number[];
};

export const MarketListingsWithAutoPricing = ({
  event,
  eventListings,
  listing,
  listingGroup,
  selectedListingIds,
}: MarketListingsWithAutoPricingProps) => {
  // State Hooks
  const { mapExists } = useListingHasVenueMapInfo(listing);
  const [showRelatedListings, setShowRelatedListings] = useState(false);
  const [compQuantityFilters, setCompQuantityFilters] = useState<string[]>([]);
  const { watch } = useFormContext<ListingDetailsPricingUpdates>();
  const input = watch();

  const [activeTab, setActiveTab] = useState<SidePanelTab>(
    SidePanelTab.LISTINGS
  );

  const { loginContext } = useAppContext();
  const { filterQuery } = useFilterQueryContext<ListingQuery>();
  const { venueMapInfo, activeConfigOverride } = useEventMapContext();

  const isMobile = useMatchMedia('mobile');
  const hasEventNotesV2 = useUserHasFeature(Feature.EventNotesV2);

  const { compListingsQuery, compListingsGroupQuery } = useGetCompListings(
    event,
    listing?.id,
    listingGroup?.ltGrpId
  );

  const { priceCalcQuery } = useAutoPricingPreview(event.viagId, listing?.id);

  const { sessionRole, enabled } = useEventHubContext();

  const sessionView = sessionRole === EventSessionRole.SidePanel;

  const {
    compListingSelectedSectionSettings,
    onCompListingSectionIdFilterChange,
    onCompListingSectionZoneChange,
    onSelectedSectionsChange,
  } = useListingAutoPricingSettings(listing);

  const formSectionFilterIds = useMemo(() => {
    return compListingSelectedSectionSettings?.sectionIdFilter ?? [];
  }, [compListingSelectedSectionSettings]);

  // Comp listings with section filters
  const compListings = useMemo(() => {
    if (listing?.id) {
      return priceCalcQuery.data?.compListings;
    }
    if (isEmpty(formSectionFilterIds)) {
      if (listingGroup?.ltGrpId) {
        return compListingsGroupQuery.data?.compListings;
      }
      return compListingsQuery.data ?? undefined;
    }

    if (listingGroup?.ltGrpId) {
      return compListingsGroupQuery.data?.compListings?.filter((l) => {
        const { section } = getMatchingSectionRow(l, venueMapInfo?.sections);
        return section && formSectionFilterIds.includes(section.id);
      });
    }
    return compListingsQuery.data?.filter((l) => {
      const { section } = getMatchingSectionRow(l, venueMapInfo?.sections);
      return section && formSectionFilterIds.includes(section.id);
    });
  }, [
    listing?.id,
    formSectionFilterIds,
    venueMapInfo?.sections,
    listingGroup?.ltGrpId,
    compListingsQuery.data,
    priceCalcQuery.data?.compListings,
    compListingsGroupQuery.data?.compListings,
  ]);

  const selectedSectionIds = useMemo(() => {
    const setionIds: number[] = [];
    if (!listing) {
      return setionIds;
    }

    const { section } = getMatchingSectionRow(
      listing.seating,
      venueMapInfo?.sections
    );

    if (section) {
      setionIds.push(section.id);
    }

    return setionIds;
  }, [listing, venueMapInfo?.sections]);

  useEffect(() => {
    if (
      formSectionFilterIds.length === 0 ||
      formSectionFilterIds[0] === undefined
    ) {
      if (showRelatedListings) {
        setShowRelatedListings(false);
      }
    } else {
      if (showRelatedListings == false) {
        setShowRelatedListings(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formSectionFilterIds]);

  const filteredSections = useMemo(() => {
    return (
      venueMapInfo?.sections.filter((section) => {
        return formSectionFilterIds?.includes(section.id);
      }) ?? []
    );
  }, [venueMapInfo?.sections, formSectionFilterIds]);

  const scoreOverrides = useMemo(
    () =>
      getCompleteEventConfigScoreOverrides(
        venueMapInfo?.sectionScores,
        activeConfigOverride?.scoreOverrides,
        false
      ),
    [activeConfigOverride?.scoreOverrides, venueMapInfo?.sectionScores]
  );

  const populateListingWithSeatScore = useCallback(
    (listing: Listing) => {
      const seatScore = getSeatScore(
        listing.seating,
        scoreOverrides,
        venueMapInfo?.sections
      );
      if (seatScore != null) return { ...listing, seatScore };

      return listing;
    },
    [scoreOverrides, venueMapInfo?.sections]
  );

  const selectedListingFromListingGroupWithSeatScore:
    | ListingWithSeatScore[]
    | undefined = useMemo(() => {
    if (!listingGroup) {
      return;
    }

    return flattenListingGroup(listingGroup)
      .map((l) => l as Listing)
      .map(populateListingWithSeatScore);
  }, [populateListingWithSeatScore, listingGroup]);

  const showFilter = useMemo(() => {
    if (listing?.id) {
      return false;
    }
    if (listingGroup?.ltGrpId) {
      return false;
    }
    return true;
  }, [listing?.id, listingGroup?.ltGrpId]);

  const compListingsWithSeatScore: CompListing[] | undefined = useMemo(() => {
    const compsWithScore =
      compListings?.map((l) => {
        const seatScore = getSeatScore(
          l,
          scoreOverrides,
          venueMapInfo?.sections
        );
        if (seatScore != null) return { ...l, seatScore };

        return l;
      }) ?? [];

    if (!showFilter || isEmpty(compQuantityFilters)) {
      return compsWithScore;
    }
    return compsWithScore.filter(({ validPurchaseQuantities }) => {
      if (isEmpty(validPurchaseQuantities)) {
        return true;
      }
      return validPurchaseQuantities.some(
        (quantity) => compQuantityFilters.indexOf(quantity + '') > -1
      );
    });
  }, [
    compListings,
    showFilter,
    compQuantityFilters,
    scoreOverrides,
    venueMapInfo?.sections,
  ]);

  const getColor = useComparableListingsVenueMapColor(
    eventListings ?? [],
    compListingsWithSeatScore,
    compListingsQuery.data ?? undefined
  );

  const onSectionClicked = useCallback(
    (e: MouseEvent, section: SectionInfo) => {
      if (e.shiftKey) {
        if (venueMapInfo && section.rows.length > 0) {
          // Try to just get the ticket class id of the first valid row in the section
          const row = section.rows[0];
          if (row) {
            const ticketClassId = row.tktClass?.id;
            if (ticketClassId) {
              // If we have a valid ticketClassId, then try to get all sections with the same ticketClassId
              const sectionsSameZone = venueMapInfo.sections?.filter((s) =>
                s.rows.some((r) => r.tktClass?.id === ticketClassId)
              );
              onCompListingSectionZoneChange(sectionsSameZone);
            }
          }
        }
      } else {
        onCompListingSectionIdFilterChange(
          section.id,
          filteredSections,
          section,
          listing
        );
      }
    },
    [
      filteredSections,
      listing,
      onCompListingSectionIdFilterChange,
      onCompListingSectionZoneChange,
      venueMapInfo,
    ]
  );

  const onSectionHovered = useCallback(
    (hoveredSection: SectionInfo) => {
      return getSectionInfoDisplay(
        hoveredSection,
        compListingsWithSeatScore,
        venueMapInfo?.sectionScores
      );
    },
    [compListingsWithSeatScore, venueMapInfo?.sectionScores]
  );

  const onToggleMirrors = useCallback(
    (mirrors: SectionInfo[], exclude?: boolean) => {
      const mirrorIds = mirrors.map(({ id }) => id);
      const filteredIds: number[] = [];
      if (exclude) {
        const filtered = formSectionFilterIds.filter(
          (id) => !mirrorIds.includes(id)
        );
        filteredIds.push(...filtered);
      } else {
        const filtered = formSectionFilterIds.concat(mirrorIds);
        filteredIds.push(...filtered);
      }
      const filteredSections = (venueMapInfo?.sections ?? []).filter(({ id }) =>
        filteredIds.includes(id)
      );
      onCompListingSectionZoneChange(filteredSections);
    },
    [
      formSectionFilterIds,
      onCompListingSectionZoneChange,
      venueMapInfo?.sections,
    ]
  );

  const compListingSectionIds = useMemo(() => {
    if (isEmpty(selectedListingIds)) {
      // no listing selected
      return formSectionFilterIds;
    }
    // Section name lookup should be sufficient,
    // update here to also check on rowId when necessary.
    const sectionNames = (priceCalcQuery.data?.compListings ?? []).map(
      (l) => l.section
    );
    const compSections = (venueMapInfo?.sections ?? [])
      .filter((section) => sectionNames.includes(section.name))
      .map(({ id }) => id);

    return union(compSections, formSectionFilterIds);
  }, [
    formSectionFilterIds,
    selectedListingIds,
    priceCalcQuery.data?.compListings,
    venueMapInfo?.sections,
  ]);

  const listingIdFilterQuery = useMemo(() => {
    const marketplaceEntityIds = filterQuery?.marketplaceEntityIds?.map(Number);

    return {
      listingIds:
        (selectedListingIds?.length > 0 ? selectedListingIds : null) ||
        marketplaceEntityIds,
    };
  }, [filterQuery?.marketplaceEntityIds, selectedListingIds]);

  return (
    <Tabs.Root
      value={activeTab}
      onValueChange={(value) => setActiveTab(value as SidePanelTab)}
      style={{ width: '100%', height: '100%' }}
    >
      <Stack direction="column" className={styles.root} gap="l" width="full">
        {isMobile && <SwiperButton dir="left" />}
        <MarketListingHeader includeSales />

        <Tabs.Content
          style={{ width: '100%', height: '100%' }}
          value={SidePanelTab.LISTINGS}
        >
          <MapWithCompListingsPreview
            listing={listing}
            listingInput={input}
            showSimplifiedSettings={mapExists}
            pricingPreview={priceCalcQuery?.data ?? undefined}
            compListings={compListingsWithSeatScore}
            compListingsFromListgingGroup={
              selectedListingFromListingGroupWithSeatScore
            }
            onSelectedSectionsChange={onSelectedSectionsChange}
            isLoading={
              priceCalcQuery.isLoading ||
              compListingsQuery.isLoading ||
              compListingsGroupQuery.isLoading
            }
            getColor={getColor}
            colorBand={
              !listing?.id
                ? {
                    numOfSteps: ListingCompsNumberRange,
                    spectrum: ListingCompsSpectrum,
                    labels: [ContentId.Cheaper, ContentId.MoreExpensive],
                  }
                : undefined
            }
            statsContent={
              <VenueMapRightNowStats
                compListings={compListingsWithSeatScore ?? []}
                currencyCode={
                  listing?.currency ??
                  loginContext?.user?.activeAccount.currencyCode ??
                  null
                }
                quantityFilters={compQuantityFilters}
                setQuantityFilters={setCompQuantityFilters}
                showFilter={showFilter}
              />
            }
          />
        </Tabs.Content>
        <Tabs.Content value={SidePanelTab.SALES}>
          <Stack
            direction={sessionView ? 'row' : 'column'}
            width="full"
            height="full"
            gap="m"
          >
            <div
              className={clsx(styles.venueMapContainer, {
                [styles.companionVenueMapContainer]:
                  enabled && sessionRole === EventSessionRole.SidePanel,
              })}
            >
              <InventoryEventPageSeatMapV2
                selectedListings={selectedListingIds}
                selectedSectionIds={selectedSectionIds}
                markedSectionIds={compListingSectionIds}
                onSectionClicked={onSectionClicked}
                onSectionHovered={onSectionHovered}
                onToggleMirrors={onToggleMirrors}
                setSelectedSections={(sections) =>
                  onCompListingSectionZoneChange(sections, false)
                }
                showColorBand={!listing}
                getColor={getColor}
                statsContent={
                  <VenueMapRightNowStats
                    compListings={compListingsWithSeatScore ?? []}
                    currencyCode={
                      listing?.currency ??
                      loginContext?.user?.activeAccount.currencyCode ??
                      null
                    }
                    quantityFilters={compQuantityFilters}
                    setQuantityFilters={setCompQuantityFilters}
                    showFilter={showFilter}
                  />
                }
              />
            </div>
            <Stack
              direction="column"
              gap="m"
              className={clsx(styles.contentContainer, {
                [styles.companionContentContainer]:
                  enabled && sessionRole === EventSessionRole.SidePanel,
              })}
            >
              <SalesSideTable
                viagVirtualId={event.viagVirtualId}
                selectedSections={filteredSections}
                queryInput={listingIdFilterQuery}
              />
            </Stack>
          </Stack>
        </Tabs.Content>
        <Tabs.Content value={SidePanelTab.PURCHASES}>
          <Stack direction="column" width="full" gap="m">
            <PurchasesSideTable
              viagVirtualId={event.viagVirtualId}
              queryInput={listingIdFilterQuery}
            />
          </Stack>
        </Tabs.Content>
        <Tabs.Content value={SidePanelTab.NOTES}>
          {hasEventNotesV2 ? (
            <NoteSectionV2 eventId={event.viagId} />
          ) : (
            <NoteSection viagVirtualId={event.viagVirtualId} />
          )}
        </Tabs.Content>
      </Stack>
    </Tabs.Root>
  );
};
