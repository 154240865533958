import { PosIconProps } from './SvgWrapper';

export function TimeoutIcon(props: Omit<PosIconProps, 'viewBox'>) {
  return (
    <svg
      width={props.width ?? '41'}
      height={props.height ?? '40'}
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3332 35C9.12817 35 1.6665 27.5383 1.6665 18.3333C1.6665 9.12832 9.12817 1.66666 18.3332 1.66666C25.1682 1.66666 31.0415 5.78082 33.6132 11.6675"
        stroke={'#677383'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M18.3333 10V18.3333H10"
        stroke={'#677383'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M30.7155 31.7014C30.7155 31.6362 30.7684 31.5833 30.8336 31.5833C30.8988 31.5833 30.9517 31.6362 30.9517 31.7014C30.9517 31.7666 30.8988 31.8195 30.8336 31.8195C30.7684 31.8195 30.7155 31.7666 30.7155 31.7014Z"
        fill="#212121"
        stroke={'#677383'}
      />
      <path
        d="M30.8334 35.9028C35.4742 35.9028 39.2362 32.1407 39.2362 27.5C39.2362 22.8593 35.4742 19.0972 30.8334 19.0972C26.1927 19.0972 22.4307 22.8593 22.4307 27.5C22.4307 32.1407 26.1927 35.9028 30.8334 35.9028Z"
        stroke={'#677383'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M30.8335 22.9167V29.0278"
        stroke={'#677383'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M30.8336 32.0833C31.0445 32.0833 31.2155 31.9123 31.2155 31.7014C31.2155 31.4905 31.0445 31.3195 30.8336 31.3195C30.6227 31.3195 30.4517 31.4905 30.4517 31.7014C30.4517 31.9123 30.6227 32.0833 30.8336 32.0833Z"
        stroke={'#677383'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
}
