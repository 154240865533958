import { Colors } from 'src/core/themes/constants/colors';

import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function FullySoldIcon(props: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper {...props} viewBox="0 0 20 20" fill="none">
      <circle
        cx="10"
        cy="10"
        r="9.58333"
        fill={Colors.green400}
        stroke={Colors.green400}
        strokeWidth="0.833333"
      />
      <path
        d="M10 5V15"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.1666 6.33336C11.4691 6.09752 10.736 5.98473 9.99992 6.00003C8.84992 6.00003 7.33325 6.50003 7.33325 7.83336C7.33325 10.3334 12.6666 9.17769 12.6666 11.8334C12.6666 13.155 11.3566 13.6667 9.99992 13.6667C9.0856 13.6914 8.17653 13.5209 7.33325 13.1667"
        stroke="white"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </SvgWrapper>
  );
}
