import {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useCallback,
  useMemo,
} from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import {
  getTextFieldState,
  PosTextFieldProps,
} from 'src/core/POS/PosTextField';
import { ContentId } from 'src/utils/constants/contentId';
import { onPriceFieldChange } from 'src/utils/inventoryUtils';
import { roundToPrecision } from 'src/utils/numberFormatter';

type ListingPriceInputProps<T> = {
  isBulkEdit?: boolean;
  currencyCode: string;
  field: Path<T>;
  otherField: Path<T>;
  isAllInPrice?: boolean;
  fieldToOtherRatio: number;
  onUnbroadcast?: () => void;
  onChange?: (newPrice: number) => void;
  onEnter?: () => void;
} & Omit<
  PosTextFieldProps,
  'type' | 'errors' | 'currency' | 'value' | 'onChange'
>;

const ListingPriceInputInner = <T extends FieldValues>(
  {
    isBulkEdit,
    currencyCode,
    field,
    otherField,
    isAllInPrice,
    fieldToOtherRatio,
    onBlur,
    disabled,
    onChange,
    onEnter,
    placeholder,
    ...rest
  }: ListingPriceInputProps<T>,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const { getUiCurrency } = useLocalizationContext();
  const { setValue, watch, setError, clearErrors, getFieldState, formState } =
    useFormContext<T>();

  let price = watch(field) as number | null;
  const otherPrice = watch(otherField) as number | null;

  const uiCurrency = useMemo(
    () => getUiCurrency(currencyCode),
    [currencyCode, getUiCurrency]
  );

  if (isAllInPrice && otherPrice && price !== fieldToOtherRatio * otherPrice) {
    // This is a hack where the price fields for external marketplaces are not editable and is being derived from
    // only the StubHub price - so if the StubHub price has no fees, the external marketplaces price won't see the
    // reflection of their own fees
    price = roundToPrecision(fieldToOtherRatio * otherPrice, uiCurrency.dec);
  }

  const requiredMsg = useContent(ContentId.Required);
  const dontChange = useContent(ContentId.DontChange);

  const fieldError = getFieldState(field, formState)?.error?.message;

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = parseFloat(e.target.value) ?? 0;

      onChange?.(newValue);

      onPriceFieldChange(
        newValue,
        field,
        price,
        otherField,
        otherPrice,
        // This is the otherToFieldRatio, so we take the inverse of fieldToOtherRatio
        1 / fieldToOtherRatio,
        clearErrors,
        setValue
      );
    },
    [
      clearErrors,
      field,
      fieldToOtherRatio,
      onChange,
      otherField,
      otherPrice,
      price,
      setValue,
    ]
  );

  const onBlurHandler = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (!isBulkEdit) {
        if (price == null || otherPrice == null) {
          setError(field, { message: requiredMsg });
          setError(otherField, { message: requiredMsg });
        }
      }

      if ((price ?? 0) < 0 || (otherPrice ?? 0) < 0) {
        setError(field, { message: requiredMsg });
        setError(otherField, { message: requiredMsg });
      }
      onBlur?.(e);
    },
    [
      field,
      isBulkEdit,
      onBlur,
      otherField,
      otherPrice,
      price,
      requiredMsg,
      setError,
    ]
  );

  return (
    <PosFormField errors={disabled ? undefined : fieldError}>
      <PosCurrencyField
        rootProps={{
          state: getTextFieldState(fieldError),
          disabled: disabled,
        }}
        placeholder={isBulkEdit ? dontChange : placeholder}
        uiCurrency={uiCurrency}
        value={price ?? undefined}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onKeyUp={(e) => {
          if (e.key === 'Enter' && typeof onEnter === 'function') {
            onEnter();
          }
        }}
        disabled={disabled}
        ref={ref}
        {...rest}
      />
    </PosFormField>
  );
};

export const ListingPriceInput = forwardRef(ListingPriceInputInner) as <T>(
  props: ListingPriceInputProps<T> & { ref?: ForwardedRef<HTMLInputElement> }
) => ReturnType<typeof ListingPriceInputInner>;
