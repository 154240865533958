import { Locale } from 'date-fns';
import * as Locales from 'date-fns/locale';

export const getCurrentLocale = () => {
  return getLocaleFromLanguage(getCurrentLanguage());
};

export const getCurrentLanguage = () => {
  const curLocaleStr =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  return curLocaleStr;
};

export const getCurrentLanguages = () => {
  return navigator.languages && navigator.languages.length
    ? navigator.languages
    : [navigator.language];
};

export const generateAcceptLanguage = () => {
  return getCurrentLanguages().join(',');
};

export const getLocaleFromLanguage = (language: string) => {
  return getDateFnsLocale(language.replaceAll('-', '') as keyof typeof Locales);
};

export const getLocaleFromLanguageOrCurrent = (language?: string | null) => {
  language = language || getCurrentLanguage();
  return getLocaleFromLanguage(language);
};

export const getDateFnsLocale = (locale: keyof typeof Locales): Locale => {
  return Locales[locale] ?? Locales.enUS;
};

export const compareLocaleLowercased = (a: string, b: string) => {
  const aL = a?.toLocaleLowerCase();
  const bL = b?.toLocaleLowerCase();
  return aL < bL ? -1 : aL === bL ? 0 : 1;
};

export const getInitialsFromText = (text: string) => {
  if (!text || text.length <= 2) {
    return (text || '').toLocaleUpperCase();
  }

  const firstLetter = text[0];
  const indexOfLastSpace = text.lastIndexOf(' ');
  if (indexOfLastSpace > 0 && indexOfLastSpace < text.length - 1) {
    return (firstLetter + text[indexOfLastSpace + 1]).toUpperCase();
  }

  return (firstLetter + text[1]).toUpperCase();
};
