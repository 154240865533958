import { ColumnDef, SortingFn } from '@tanstack/react-table';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { CrossIcon, IconsFill } from 'src/svgs/Viagogo';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { getDeliveryTypeIcon } from 'src/utils/ticketTypeUtils';
import { DeliveryType, IListingGroupItem } from 'src/WebApiController';

import * as styles from '../GroupListings.css';

export type IListingGroupItemWithMeta = IListingGroupItem & {
  rank: number;
  assignNewPriority: (newPriority: number, listingId: number) => void;
  setUndercutAmount: (
    listingId: number,
    undercutAbsoluteAmount?: number | null,
    undercutRelativeAmount?: number | null
  ) => void;
  priorityOptions: Record<string, string>;
  removeListingFromInput: (listingId: number) => void;
};

const DeliveryTypeCell = ({ deliveryType }: { deliveryType: DeliveryType }) => {
  const icon = getDeliveryTypeIcon(deliveryType, null, IconsFill.textStrong);
  return icon;
};

// We don't sort the table using the built-in sorting mechanism,
// rather we sort the data in the onSortingChange callback
const dummySortingFn: SortingFn<IListingGroupItemWithMeta | null> = (_, b) => 0;

export const getListingGroupTableConfig =
  (): ColumnDef<IListingGroupItemWithMeta | null>[] => [
    {
      id: 'Rank',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Rank} />
        </TableHeaderCellDiv>
      ),
      minSize: 80,
      maxSize: 100,
      cell: ({ row: { original, depth } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        const { rank, assignNewPriority, priorityOptions, id } = original;

        if (depth > 0) return null;

        return (
          <TableCellDiv align="right" showTooltip={false}>
            <PosSelect
              style={{ width: '100%' }}
              value={(rank + 1).toString()}
              size="md"
              contentProps={{ align: 'end' }}
              onChange={(newPriorityString: string | null) => {
                if (newPriorityString) {
                  const newPriority = parseInt(newPriorityString);
                  assignNewPriority(newPriority, id);
                }
              }}
              valueOptionsContent={priorityOptions}
            />
          </TableCellDiv>
        );
      },
      enableSorting: false,
    },
    {
      id: 'Section',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Section} />
        </TableHeaderCellDiv>
      ),
      minSize: 50,
      maxSize: 100,
      accessorFn: (data) => data?.seating.section,
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align="right">{original.seating.section}</TableCellDiv>
        );
      },
      sortingFn: dummySortingFn,
    },
    {
      id: 'Row',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Row} />
        </TableHeaderCellDiv>
      ),
      minSize: 50,
      maxSize: 70,
      accessorFn: (data) => data?.seating.row ?? '',
      cell: ({ row: { original, depth } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align={depth === 0 ? 'right' : 'center'}>
            {depth === 0 ? (
              original.seating.row
            ) : (
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.By} />
              </div>
            )}
          </TableCellDiv>
        );
      },
      sortingFn: dummySortingFn,
    },
    {
      id: 'Seats',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Seats} />
        </TableHeaderCellDiv>
      ),
      minSize: 50,
      maxSize: 100,
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        let text = original.seating.seatFr;
        if (
          original.seating.seatTo &&
          original.seating.seatTo !== original.seating.seatFr
        ) {
          text += ` - ${original.seating.seatTo}`;
        }

        return <TableCellDiv align="right">{text}</TableCellDiv>;
      },
      enableSorting: false,
    },
    {
      id: 'TicketType',
      header: () => (
        <TableHeaderCellDiv align="center">
          <Content id={ContentId.TicketType} />
        </TableHeaderCellDiv>
      ),
      minSize: 50,
      maxSize: 50,
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align="center">
            {<DeliveryTypeCell deliveryType={original.delivType} />}
          </TableCellDiv>
        );
      },
      enableSorting: false,
    },
    {
      id: 'Remaining',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.UnsoldQty} />
        </TableHeaderCellDiv>
      ),
      minSize: 70,
      maxSize: 70,
      accessorFn: (data) => data?.availQty,
      cell: function Cell({ row: { original } }) {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align="right">
            {original.availQty ?? <Content id={ContentId.NA} />}
          </TableCellDiv>
        );
      },
      sortingFn: dummySortingFn,
    },
    {
      id: 'Original',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.TotalQty} />
        </TableHeaderCellDiv>
      ),
      minSize: 70,
      maxSize: 70,
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return <TableCellDiv align="right">{original.ticketCnt}</TableCellDiv>;
      },
      enableSorting: false,
    },
    {
      id: 'UnitCost',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.UnitCost} />
        </TableHeaderCellDiv>
      ),
      minSize: 70,
      maxSize: 100,
      cell: function Cell({ row: { original } }) {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align="right">
            {original.unitCst?.disp ?? original?.faceValue?.disp ?? (
              <Content id={ContentId.NA} />
            )}
          </TableCellDiv>
        );
      },
      enableSorting: false,
    },
    {
      id: 'Action',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Actions} />
        </TableHeaderCellDiv>
      ),
      minSize: 60,
      maxSize: 60,
      cell: function Cell({ row: { original } }) {
        const deleteText = useContent(ContentId.Delete);

        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align="right">
            <CrossIcon
              title={deleteText}
              withHoverEffect
              onClick={() => original.removeListingFromInput(original.id)}
              size={vars.iconSize.m}
            />
          </TableCellDiv>
        );
      },
      enableSorting: false,
    },
  ];
