import { FormProvider, useForm } from 'react-hook-form';
import { container, form } from 'src/components/SignUp/EmailForm/EmailForm.css';
import { Input } from 'src/components/SignUp/EmailForm/Input/Input';
import { SubmitButton } from 'src/components/SignUp/EmailForm/SubmitButton/SubmitButton';
import { useAppContext } from 'src/contexts/AppContext';
import { useSignUpContext } from 'src/contexts/SignUpContext/SignUpContext';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { REACT_APP_NAME } from 'src/utils/constants/constants';
import { ClientLogClient, LogLevel } from 'src/WebApiController';

export interface SignUpForm {
  email: string;
}

export const EmailForm = () => {
  const isMobile = useMatchMedia('mobile');
  const { setIsSignupSubmitted } = useSignUpContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const methods = useForm<SignUpForm>();
  const { handleSubmit } = methods;

  const onSubmit = (formValue: SignUpForm) => {
    new ClientLogClient(activeAccountWebClientConfig).log({
      logLevel: LogLevel.Information,
      pageUrl: window.location.href,
      componentStack: 'signup',
      applicationSource: REACT_APP_NAME,
      errorId: null,
      error: `new sign-up request: ${formValue.email}`,
      additionalInfo: { email: formValue.email },
    });
    setIsSignupSubmitted(true);
  };

  return (
    <FormProvider {...methods}>
      <form className={form} onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column" gap="xl" alignItems="center">
          <Stack
            justifyContent="spaceBetween"
            alignItems="center"
            className={container}
            width="full"
          >
            <Input />
            {!isMobile && <SubmitButton />}
          </Stack>
          {isMobile && (
            <div>
              <SubmitButton />
            </div>
          )}
        </Stack>
      </form>
    </FormProvider>
  );
};
