import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { CurrencyFilterSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { Switch } from 'src/core/interim/Switch';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  FieldError,
  FieldLabel,
  FieldWrapper,
  PurchasePaymentMethodSelectionInput,
} from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { Feature } from 'src/WebApiController';

import * as styles from './AddPurchasePaymentTiles.css';
import {
  Divider,
  InputRowAmount,
  SwitchContainer,
} from './AddPurchasePaymentTiles.styled';
import { FullWidthSelectionInputContainer } from './GeneratePurchasePaymentsForm.styled';

export type AddPurchasePaymentTilesFieldValues = {
  payments: {
    currencyCode: string;
    paymentAmount?: number;
    isPaid: boolean;
    paymentDueDate?: Date;
    paymentDate?: Date;
    paymentMethodId?: number;
  }[];
};

export type AddPurchasePaymentTilesProps = {
  form: UseFormReturn<AddPurchasePaymentTilesFieldValues>;
  fieldArray: UseFieldArrayReturn<
    AddPurchasePaymentTilesFieldValues,
    'payments',
    'id'
  >;
};

export function AddPurchasePaymentTiles({
  form: {
    control,
    formState,
    resetField,
    setValue,
    getValues,
    getFieldState,
    clearErrors,
    watch,
    register,
  },
  fieldArray: { fields },
}: AddPurchasePaymentTilesProps) {
  const requiredMsg = useContent(ContentId.Required);
  const { getUiCurrency } = useLocalizationContext();

  const hasEnforcePurchasePaymentCurrencyFeature = useUserHasFeature(
    Feature.EnforcePurchasePaymentCurrency
  );

  return (
    <div className={styles.paymentTilesContainer}>
      {fields.map((field, index) => (
        <div key={field.id}>
          <FieldWrapper>
            <FieldLabel>
              <Content id={ContentId.TotalDue} />
            </FieldLabel>
            <InputRowAmount>
              {!hasEnforcePurchasePaymentCurrencyFeature && (
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name={`payments.${index}.currencyCode`}
                  render={({ field: { ...field }, fieldState }) => (
                    <CurrencyFilterSelector
                      hasErrors={Boolean(fieldState.error)}
                      {...field}
                    />
                  )}
                />
              )}
              <PosCurrencyField
                rootProps={{
                  state: getFieldState(
                    `payments.${index}.paymentAmount`,
                    formState
                  ).error?.message
                    ? 'error'
                    : undefined,
                }}
                uiCurrency={getUiCurrency(
                  getValues(`payments.${index}.currencyCode`)
                )}
                value={getValues(`payments.${index}.paymentAmount`)}
                {...register(`payments.${index}.paymentAmount`, {
                  validate: {
                    required: (fieldVal) => {
                      return parseFloat(fieldVal?.toString() ?? '')
                        ? undefined
                        : requiredMsg;
                    },
                  },
                  valueAsNumber: true,
                  onChange: ({ target: { value } }) => {
                    clearErrors(`payments.${index}.paymentAmount`);
                    setValue(
                      `payments.${index}.paymentAmount`,
                      parseFloat(value)
                    );
                  },
                })}
              />
            </InputRowAmount>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel>
              <Content id={ContentId.DateDue} />
            </FieldLabel>
            <Controller
              name={`payments.${index}.paymentDueDate`}
              rules={{ required: true }}
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <DatePickerInput
                  fieldError={fieldState.error}
                  locale={getLocaleFromLanguageOrCurrent()}
                  {...field}
                  onDateChange={(d) => {
                    setValue(field.name, d);
                  }}
                />
              )}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel>
              <Content id={ContentId.Paid} />
            </FieldLabel>
            <Controller
              name={`payments.${index}.isPaid`}
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <SwitchContainer>
                  <Switch
                    checked={value}
                    onChange={(e) => {
                      const isPaid = e.currentTarget.checked;
                      if (isPaid) {
                        setValue(
                          `payments.${index}.paymentDate`,
                          getValues(`payments.${index}.paymentDueDate`)
                        );
                      } else {
                        resetField(`payments.${index}.paymentDate`);
                      }
                      onChange(e);
                    }}
                    {...field}
                  />
                </SwitchContainer>
              )}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel>
              <Content id={ContentId.DatePaid} />
            </FieldLabel>
            <Controller
              name={`payments.${index}.paymentDate`}
              rules={{ required: watch(`payments.${index}.isPaid`) }}
              control={control}
              render={({ field: { ...field }, fieldState }) => (
                <DatePickerInput
                  fieldError={fieldState.error}
                  disabled={!watch(`payments.${index}.isPaid`)}
                  locale={getLocaleFromLanguageOrCurrent()}
                  {...field}
                  onDateChange={(d) => {
                    setValue(field.name, d);
                  }}
                />
              )}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel>
              <Content id={ContentId.PaymentMethodUsed} />
            </FieldLabel>
            <FullWidthSelectionInputContainer>
              <Controller
                control={control}
                name={`payments.${index}.paymentMethodId`}
                rules={{ required: true }}
                render={({ field: { value, ...field }, fieldState }) => (
                  <PurchasePaymentMethodSelectionInput
                    className="full-width"
                    value={value !== undefined ? String(value) : undefined}
                    hasErrors={Boolean(fieldState.error)}
                    {...field}
                  />
                )}
              />
            </FullWidthSelectionInputContainer>
          </FieldWrapper>
          <Divider />
        </div>
      ))}
      {formState.errors.payments && (
        <FieldError>
          <Content id={ContentId.RequiredFieldsError} />
        </FieldError>
      )}
    </div>
  );
}
