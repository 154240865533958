import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './Title.css';

export const Titles = () => {
  return (
    <div className={styles.mainWrapper}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>REACH</span>
        <span className={styles.titleTopRight}>pro</span>
      </div>
      <span className={styles.subtitle}>
        <Content id={ContentId.SignUpWelcomeSubtitle} />
      </span>
    </div>
  );
};
