import { clsx } from 'clsx';
import {
  eventColors,
  eventStateLabel,
  eventStatusContent,
  eventStatusWrapper,
} from 'src/components/Events/EventStatus/EventStatus.css';
import { Content } from 'src/contexts/ContentContext';
import { EVENT_STATUS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { ApiEventStatus } from 'src/WebApiController';

enum StatusColor {
  Red = 'red',
  Yellow = 'yellow',
  Gray = 'gray',
}

const EventStatusToColor: Readonly<Record<ApiEventStatus, StatusColor>> = {
  [ApiEventStatus.Cancelled]: StatusColor.Red,
  [ApiEventStatus.Deleted]: StatusColor.Red,
  [ApiEventStatus.Draft]: StatusColor.Gray,
  [ApiEventStatus.Normal]: StatusColor.Gray,
  [ApiEventStatus.Contingent]: StatusColor.Yellow,
  [ApiEventStatus.Postponed]: StatusColor.Yellow,
  [ApiEventStatus.Relocated]: StatusColor.Yellow,
  [ApiEventStatus.Rescheduled]: StatusColor.Yellow,
  [ApiEventStatus.RelocatedAndRescheduled]: StatusColor.Yellow,
};

export interface EventStatusProps {
  status: ApiEventStatus | null | undefined;
}

export const EventStatus = ({ status }: EventStatusProps) => {
  // Don't show normal state
  if (!status || status === ApiEventStatus.Normal) {
    return null;
  }

  return (
    <div className={eventStatusWrapper}>
      <div
        className={clsx(
          eventStatusContent,
          eventColors[EventStatusToColor[status]]
        )}
      >
        <span className={eventStateLabel}>
          <Content id={EVENT_STATUS_TO_CID[status]} />
        </span>
      </div>
    </div>
  );
};
