import { useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import { SetStateAction, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { TabWithStarIcon } from 'src/components/Listings/InventoryEventPage/TabWithStarIcon';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  getFormattedContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { Button } from 'src/core/ui';
import * as Tabs from 'src/core/ui/Tabs';
import { DetailSection } from 'src/modals/common';
import { modalDetails } from 'src/modals/common/Modals.css';
import { TicketsSection } from 'src/modals/SaleDetails/components/TicketsSection';
import { ContentId } from 'src/utils/constants/contentId';
import {
  SaleClient,
  SaleDetails,
  Seating,
  SeatingForAlloc,
} from 'src/WebApiController';

import { SeatingAllocationInput } from './SeatingAllocationInput';
import * as styles from './SeatingAllocationInput.css';
import {
  FlattenedSeatingAllocation,
  SeatingSingleTicketAllocationInput,
} from './SeatingSingleTicketAllocationInput';

export const enum SeatingAllocationInputTab {
  Listings = 'listings',
  Tickets = 'tickets',
}

export const SeatingAllocationInputV2 = ({
  selectedSeatings,
  setSelectedSeatings,
  activeTab,
  setActiveTab,
  originalSeating,
}: {
  selectedSeatings: FlattenedSeatingAllocation[];
  setSelectedSeatings: React.Dispatch<
    React.SetStateAction<FlattenedSeatingAllocation[]>
  >;
  activeTab: SeatingAllocationInputTab;
  setActiveTab: React.Dispatch<SetStateAction<SeatingAllocationInputTab>>;
  originalSeating: Seating;
}) => {
  const { getValues } = useFormContext<SaleDetails>();

  const sale = getValues();

  return (
    <div className={modalDetails}>
      <div className={styles.seatAllocationContainer}>
        <h5>
          <Content id={ContentId.AllocateTickets} />
        </h5>
        <div className={styles.seatAllocationInstructions}>
          <Content id={ContentId.WhatSeatToFulfill} />
        </div>
        <DetailSection name={<Content id={ContentId.SoldAs} />}>
          <TicketsSection
            {...originalSeating}
            quantity={sale!.qtySold}
            listingId={sale!.listingId}
          />
        </DetailSection>

        <Tabs.Root
          value={activeTab}
          onValueChange={(value) => {
            setActiveTab(value as SeatingAllocationInputTab);
            setSelectedSeatings([]);
          }}
          className={styles.tabsSection}
        >
          <Tabs.List
            style={{
              padding: `${vars.spacing['lg']} ${vars.spacing['xl']} 0 ${vars.spacing['xl']}`,
            }}
          >
            <Tabs.Trigger
              key={SeatingAllocationInputTab.Listings}
              value={SeatingAllocationInputTab.Listings}
            >
              <Content id={ContentId.ChooseListing} />
            </Tabs.Trigger>
            <Tabs.Trigger
              key={SeatingAllocationInputTab.Tickets}
              value={SeatingAllocationInputTab.Tickets}
            >
              <Content id={ContentId.ChooseTickets} />
            </Tabs.Trigger>
          </Tabs.List>

          <Tabs.Content
            key={SeatingAllocationInputTab.Listings}
            value={SeatingAllocationInputTab.Listings}
          >
            <SeatingAllocationInput
              originalSeating={originalSeating}
              onlyShowSeatingAllocation
            />
          </Tabs.Content>
          <Tabs.Content
            key={SeatingAllocationInputTab.Tickets}
            value={SeatingAllocationInputTab.Tickets}
          >
            <SeatingSingleTicketAllocationInput
              originalSeating={originalSeating}
              selectedSeatings={selectedSeatings}
              setSelectedSeatings={setSelectedSeatings}
            />
          </Tabs.Content>
        </Tabs.Root>
      </div>
    </div>
  );
};
