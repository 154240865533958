import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function MinusIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <g
        xmlns="http://www.w3.org/2000/svg"
        id="0.-Foundations/Icon/IconSet/Action/Minus"
        stroke="none"
        strokeWidth="1"
        fill="inherit"
        fillRule="evenodd"
      >
        <polygon id="Shape" points="4 10.5 4 13.5 20 13.5 20 10.5" />
      </g>
    </SvgWrapper>
  );
}
