import { useCallback, useContext } from 'react';
import { ModalContext } from 'src/contexts/ModalContext';
import { useGetVendorIdFromUrl } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useGetVendorIdFromUrl';
import { getVendorDetailsModalConfigWithDeepLink } from 'src/utils/vendorUtils';

export const useOpenVendorDetailsModal = () => {
  const { setModal } = useContext(ModalContext);
  const activeVendorId = useGetVendorIdFromUrl();

  const openVendorDetailsModal = useCallback(
    (vendorId: number) => {
      setModal(getVendorDetailsModalConfigWithDeepLink(vendorId));
    },
    [setModal]
  );

  const openVendorDetailsModalFromUrl = useCallback(() => {
    if (activeVendorId) {
      openVendorDetailsModal(activeVendorId);
    }
  }, [activeVendorId, openVendorDetailsModal]);

  return {
    openVendorDetailsModal,
    openVendorDetailsModalFromUrl,
  };
};
