import { differenceWith } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { Content } from 'src/contexts/ContentContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { BodySectionTitle, TicketsDetailRow } from 'src/modals/common';
import { DeleteIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';

import {
  PurchaseDetailsDataTableLabelCell,
  PurchaseDetailsDataTableValueCell,
} from '../CostDetailsTable/CostDetailsTable.styled';
import * as styles from '../PurchaseModal.css';

export type PurchaseCommissionInput = Pick<
  PurchaseOrderDetailsInput,
  'commissions'
>;

export function CommissionSection({
  disabled,
  readonly,
}: {
  disabled?: boolean;
  readonly?: boolean;
}) {
  const { setValue, watch } = useFormContext<PurchaseCommissionInput>();

  const { allActiveUserInfos } = useSellerAccountContext();
  const activeUserIds = useMemo(
    () =>
      allActiveUserInfos
        ? Object.values(allActiveUserInfos).map((u) => u.userId)
        : [],
    [allActiveUserInfos]
  );

  const commissions = watch('commissions');

  const showAddCommission = useMemo(() => {
    if (!activeUserIds) return false;
    return (commissions?.value?.length ?? 0) < activeUserIds.length;
  }, [commissions, activeUserIds]);

  const onAddNewCommission = useCallback(() => {
    const firstAvailableUserId = differenceWith(
      activeUserIds,
      commissions?.value ?? [],
      (activeUserId, commission) => activeUserId === commission.buyerUserId
    )[0];
    if (!firstAvailableUserId) return;
    setValue(
      'commissions',
      posChangedField([
        ...(commissions?.value ?? []),
        {
          buyerUserId: firstAvailableUserId,
          commissionPercentage: 0,
        },
      ])
    );
  }, [activeUserIds, commissions, setValue]);

  const onChangeCommissionUser = (newId: string, i: number) => {
    if (
      commissions &&
      commissions?.value?.find(
        (existingCommission) => existingCommission.buyerUserId === newId
      )
    ) {
      return;
    }
    setValue(
      'commissions',
      posChangedField(
        (commissions?.value ?? []).map((prevCommission, prevIndex) =>
          prevIndex === i
            ? {
                buyerUserId: newId,
                commissionPercentage: prevCommission.commissionPercentage,
              }
            : prevCommission
        )
      )
    );
  };

  const onChangeCommissionPercentage = (inputValue: string, i: number) => {
    let value = parseFloat(inputValue);
    if (isNaN(value)) {
      value = 0;
    } else {
      const othersSumPercentage = Array.isArray(commissions)
        ? commissions.reduce((sum: number, current, idx) => {
            if (idx === i) return sum;
            return sum + current.commissionPercentage * 100;
          }, 0)
        : 0;
      value = Math.min(Math.max(0, value), 100 - othersSumPercentage);
    }
    setValue(
      'commissions',
      posChangedField(
        (commissions?.value ?? []).map((prevCommission, prevIndex) =>
          prevIndex === i
            ? {
                buyerUserId: prevCommission.buyerUserId,
                commissionPercentage: value / 100,
              }
            : prevCommission
        )
      )
    );
  };

  return (
    <div className={styles.bodySection}>
      <BodySectionTitle>
        <Content id={ContentId.BuyerCommission} />
      </BodySectionTitle>
      <Stack direction="column" gap="m" className={styles.commissionList}>
        {commissions?.value?.map((c, i) =>
          readonly ? (
            <div key={i} className={styles.buyerCommissionRow}>
              <PosFormField>
                <PurchaseDetailsDataTableLabelCell>
                  {allActiveUserInfos?.[c.buyerUserId]?.displayName}
                </PurchaseDetailsDataTableLabelCell>
              </PosFormField>
              <PosFormField>
                <PurchaseDetailsDataTableValueCell align="right">
                  {roundToPrecision(c.commissionPercentage * 100, 5)}
                  {'%'}
                </PurchaseDetailsDataTableValueCell>
              </PosFormField>
            </div>
          ) : (
            <div key={i} className={styles.buyerCommissionRow}>
              <PosFormField>
                <SellerAccountEmployeeSelector
                  value={c.buyerUserId ?? ''}
                  disabled={disabled}
                  onChange={(newId) => onChangeCommissionUser(newId, i)}
                  onClick={(e) => e.stopPropagation()}
                  className={styles.buyerSelector}
                />
              </PosFormField>
              <PosFormField>
                <PosTextField
                  disabled={disabled}
                  type="number"
                  inputMode="decimal"
                  postfixDisplay="%"
                  value={roundToPrecision(c.commissionPercentage * 100, 5)}
                  min={0}
                  max={100}
                  onChange={(e) =>
                    onChangeCommissionPercentage(e.target.value, i)
                  }
                />
                <DeleteIcon
                  fill={IconsFill.currentColor}
                  disabled={disabled}
                  withHoverEffect
                  onClick={() => {
                    setValue(
                      'commissions',
                      posChangedField(
                        commissions?.value?.filter(
                          (_current, index) => index !== i
                        ) ?? []
                      )
                    );
                  }}
                />
              </PosFormField>
            </div>
          )
        )}
      </Stack>
      {showAddCommission && !readonly && (
        <TicketsDetailRow>
          <Button variant={'link'} onClick={onAddNewCommission}>
            <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
            <Content id={ContentId.AddCommission} />
          </Button>
        </TicketsDetailRow>
      )}
    </div>
  );
}
