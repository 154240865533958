import { ComponentProps, useCallback, useContext } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { ViewCompsModal } from 'src/modals/ViewCompsModal';
import { DollarIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType, ListingDetails } from 'src/WebApiController';

export const LaunchViewComps = ({
  listingId,
  marketplaceListingId,
  cancelTo,
  variant,
  ...buttonProps
}: {
  listingId: number;
  marketplaceListingId?: string | null;
  variant?: ButtonProps['variant'];
  disabled?: boolean;
} & ComponentProps<typeof ViewCompsModal>) => {
  const { setModal } = useContext(ModalContext);
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();

  const viewComps = useCallback(() => {
    setModal({
      children: <ViewCompsModal cancelTo={cancelTo} />,
      clickOutsideToClose: true,
      size: 'slide-in',
      deepLinkValue: listingId,
      trackViewEntityType: ActionOutboxEntityType.Listing,
    });
  }, [setModal, cancelTo, listingId]);

  const onLaunchDialog = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setActivePosEntity(listingId, marketplaceListingId);
      viewComps();
    },
    [setActivePosEntity, listingId, marketplaceListingId, viewComps]
  );

  return (
    <ButtonWithIcon
      {...buttonProps}
      variant={variant ?? 'text'}
      onClick={onLaunchDialog}
      textContentId={ContentId.ViewComps}
      icon={<DollarIcon withHoverEffect size={vars.iconSize.m} />}
    />
  );
};
