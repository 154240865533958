import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { getPerformerAndVenueForEvent } from 'src/utils/eventWithDataUtils';
import { Event, Performer, Venue } from 'src/WebApiController';

export const useGetEventFullInfo = (
  event?: Event | null,
  performer?: Performer | null,
  venue?: Venue | null
) => {
  const { event: activeEvent } = useActivePosEntityContext();
  const { data } = useCatalogDataContext();

  const { performer: cachedPerfomer, venue: cachedVenue } =
    getPerformerAndVenueForEvent(event ?? activeEvent, data);

  return {
    event: event ?? activeEvent,
    performer: performer ?? cachedPerfomer,
    venue: venue ?? cachedVenue,
  };
};
