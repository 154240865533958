import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { ErrorIconMessage } from 'src/core/POS/ErrorIconMessage';
import { ContentId } from 'src/utils/constants/contentId';
import { isMarketplaceActive } from 'src/utils/userUtils';

import { notificationContainer } from './AccountDisabledNotification.css';

export const AccountDisabledNotification = () => {
  const { loginContext } = useAppContext();
  const accountEnabledForBroadcast = useMemo(
    () =>
      Boolean(
        loginContext?.user?.activeAccount?.marketplaceSettings?.filter((m) =>
          isMarketplaceActive(m)
        )?.length
      ),
    [loginContext?.user?.activeAccount?.marketplaceSettings]
  );

  if (accountEnabledForBroadcast) {
    return null;
  }
  return (
    <div className={notificationContainer}>
      <ErrorIconMessage alignment="center">
        <Content id={ContentId.AccountNotSetupForAnyMarketplace} />
      </ErrorIconMessage>
    </div>
  );
};
