import { Row, RowData } from '@tanstack/react-table';
import clsx from 'clsx';
import { useRef } from 'react';
import { ItemProps } from 'react-virtuoso';
import * as styles from 'src/tables/Table/Table.css';

import { SectionRowTableContext } from './SectionRowTable';

type TableContextStyle<T extends RowData> = {
  context?: SectionRowTableContext<T> | undefined;
};

export type SectionRowTableRowProps<T extends RowData> = ItemProps<Row<T>> &
  TableContextStyle<T>;

export const SectionRowTableRow = <T extends RowData>({
  context,
  children,
  ...props
}: SectionRowTableRowProps<T>) => {
  const { tableRowStyle, onRowSelect } = context || {};
  const inputRef = useRef<HTMLTableRowElement>(null);

  const rowProps = {
    className: clsx(styles.tableRow, {
      [styles.isSelectedRow]: props.item.getIsSelected(),
    }),
    style: {
      ...tableRowStyle,
      width: '99%',
    },
    ref: inputRef,
    key: props.item.id,
    onClick: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      e.stopPropagation();
      onRowSelect?.(props.item);
    },
    ...props,
  };

  return <tr {...rowProps}>{children}</tr>;
};
