import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function MoreIcon(props: IconsProps) {
  return (
    <SvgWrapper aria-label="MoreIcon" {...props}>
      <path
        d="M4.8 9.2C3.24 9.2 2 10.44 2 12c0 1.56 1.24 2.8 2.8 2.8 1.56 0 2.8-1.24 2.8-2.8 0-1.56-1.24-2.8-2.8-2.8zm7.2 0c1.56 0 2.8 1.24 2.8 2.8 0 1.56-1.24 2.8-2.8 2.8-1.56 0-2.8-1.24-2.8-2.8 0-1.56 1.24-2.8 2.8-2.8zm7.2 0c1.56 0 2.8 1.24 2.8 2.8 0 1.56-1.24 2.8-2.8 2.8-1.56 0-2.8-1.24-2.8-2.8 0-1.56 1.24-2.8 2.8-2.8zM4.8 10.8c.68 0 1.2.52 1.2 1.2 0 .68-.52 1.2-1.2 1.2-.68 0-1.2-.52-1.2-1.2 0-.68.52-1.2 1.2-1.2zm7.2 0c-.68 0-1.2.52-1.2 1.2 0 .68.52 1.2 1.2 1.2.68 0 1.2-.52 1.2-1.2 0-.68-.52-1.2-1.2-1.2zm7.2 0c-.68 0-1.2.52-1.2 1.2 0 .68.52 1.2 1.2 1.2.68 0 1.2-.52 1.2-1.2 0-.68-.52-1.2-1.2-1.2z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
