import { useMeasure } from 'react-use';

/**
 * The width that we want to scale the html emails relative to.
 */
export const HTML_EMAIL_MAX_WIDTH = 700;

export type EmailRendererProps = {
  htmlBody: string | null | undefined;
  textBody?: string | null | undefined;
};

export function EmailRenderer({ htmlBody, textBody }: EmailRendererProps) {
  const [wrapperRef, { width: wrapperWidth }] = useMeasure<HTMLDivElement>();
  return (
    <div ref={wrapperRef}>
      <div
        style={{
          overflow: 'auto',
          zoom:
            wrapperWidth < HTML_EMAIL_MAX_WIDTH
              ? wrapperWidth / HTML_EMAIL_MAX_WIDTH
              : undefined,
        }}
      >
        {htmlBody ? (
          <div dangerouslySetInnerHTML={{ __html: htmlBody }} />
        ) : (
          <div>{textBody}</div>
        )}
      </div>
    </div>
  );
}
