import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  ActionType,
  ActivityLogQuery,
  AuditActionType,
} from 'src/WebApiController';

import {
  EMPTY_ACTIVITY_LOG_QUERY,
  LISTING_ACTIVITY_LOG_TAB_TO_ACTION_TYPES,
  LISTING_ACTIVITY_LOG_TAB_TO_AUDIT_ACTION_TYPES,
  LISTING_ACTIVITY_LOG_TAB_TO_CID,
  ListingActivityLogTab,
  PURCHASE_ORDER_ACTIVITY_LOG_TAB_TO_ACTION_TYPES,
  PURCHASE_ORDER_ACTIVITY_LOG_TAB_TO_AUDIT_ACTION_TYPES,
  PURCHASE_ORDER_ACTIVITY_LOG_TO_CID,
  PurchaseOrderActivityLogTab,
  SALE_ACTIVITY_LOG_TAB_TO_ACTION_TYPES,
  SALE_ACTIVITY_LOG_TAB_TO_AUDIT_ACTION_TYPES,
  SALE_ACTIVITY_LOG_TAB_TO_CID,
  SaleActivityLogTab,
  TAG_AUDIT_ACTION_TYPES,
} from './ActivityLogModal.constants';

export const getActivityLogFilterByTab = (
  entityType: ActionOutboxEntityType | null,
  tab: string,
  isTagTab = false
): ActivityLogQuery => {
  let actionTypes: ActionType[] | null = null;
  let auditActionTypes: AuditActionType[] | null = null;
  let tagName: string | null = null;
  if (isTagTab) {
    tagName = tab;
    actionTypes = [];
    auditActionTypes = TAG_AUDIT_ACTION_TYPES;
  } else {
    if (entityType === ActionOutboxEntityType.Listing) {
      actionTypes =
        LISTING_ACTIVITY_LOG_TAB_TO_ACTION_TYPES[tab as ListingActivityLogTab];
      auditActionTypes =
        LISTING_ACTIVITY_LOG_TAB_TO_AUDIT_ACTION_TYPES[
          tab as ListingActivityLogTab
        ];
    } else if (entityType === ActionOutboxEntityType.Sale) {
      actionTypes =
        SALE_ACTIVITY_LOG_TAB_TO_ACTION_TYPES[tab as SaleActivityLogTab];
      auditActionTypes =
        SALE_ACTIVITY_LOG_TAB_TO_AUDIT_ACTION_TYPES[tab as SaleActivityLogTab];
    } else if (entityType === ActionOutboxEntityType.Purchase) {
      actionTypes =
        PURCHASE_ORDER_ACTIVITY_LOG_TAB_TO_ACTION_TYPES[
          tab as PurchaseOrderActivityLogTab
        ];
      auditActionTypes =
        PURCHASE_ORDER_ACTIVITY_LOG_TAB_TO_AUDIT_ACTION_TYPES[
          tab as PurchaseOrderActivityLogTab
        ];
    }
  }

  return {
    ...EMPTY_ACTIVITY_LOG_QUERY,
    actionTypes,
    auditActionTypes,
    entityType,
    tagName,
  };
};

export const getActivityLogTabValues = (
  entityType: ActionOutboxEntityType | null
): {
  value: string;
  contentId: ContentId;
}[] => {
  if (!entityType) {
    return [];
  }

  if (entityType === ActionOutboxEntityType.Listing) {
    return Object.entries(LISTING_ACTIVITY_LOG_TAB_TO_CID).map(
      ([tabKey, cid]) => {
        return {
          value: tabKey,
          contentId: cid,
        };
      }
    );
  } else if (entityType === ActionOutboxEntityType.Sale) {
    return Object.entries(SALE_ACTIVITY_LOG_TAB_TO_CID).map(([tabKey, cid]) => {
      return {
        value: tabKey,
        contentId: cid,
      };
    });
  } else if (entityType === ActionOutboxEntityType.Purchase) {
    return Object.entries(PURCHASE_ORDER_ACTIVITY_LOG_TO_CID).map(
      ([tabKey, cid]) => {
        return {
          value: tabKey,
          contentId: cid,
        };
      }
    );
  }

  return [];
};
