import { getContent, IContentContext } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import {
  AccessibilityIcon,
  AisleSeatIcon,
  GenericDisclosuresIcon,
  IconsFill,
  ObstructedViewIcon,
  ParkingPassIcon,
} from 'src/svgs/Viagogo';
import { ListingNote, ListingNoteType } from 'src/WebApiController';

import { LISTING_NOTE_TYPE_TO_CID } from './constants/contentIdMaps';

export const getListingNoteIcon = (
  note: ListingNote,
  contentContext: IContentContext,
  showTypeInfo = false
) => {
  const listingNoteTypeText = getContent(
    note.type ? LISTING_NOTE_TYPE_TO_CID[note.type] : '',
    contentContext
  );
  const listNoteText = (note.listingNoteUntranslated || note.listingNoteText)
    .trim()
    .toLocaleLowerCase();

  const fill = showTypeInfo
    ? note.type === ListingNoteType.Defect
      ? IconsFill.textNegative
      : note.type === ListingNoteType.Perk
      ? IconsFill.textSuccess
      : note.type === ListingNoteType.RestrictionOnUse
      ? IconsFill.textWarning
      : IconsFill.currentColor
    : undefined;

  const iconProps = {
    size: vars.iconSize.s,
    fill: fill,
    title: listingNoteTypeText,
  };

  if (listNoteText.includes('aisle')) {
    return <AisleSeatIcon {...iconProps} />;
  }

  if (listNoteText.includes('parking')) {
    return <ParkingPassIcon {...iconProps} />;
  }

  if (listNoteText.includes('wheelchair')) {
    return <AccessibilityIcon {...iconProps} />;
  }

  if (
    listNoteText.includes('view') &&
    ['limited', 'obstructed', 'side', 'rear', 'partial'].findIndex((x) =>
      listNoteText.includes(x)
    ) > 0
  ) {
    return <ObstructedViewIcon {...iconProps} />;
  }

  return <GenericDisclosuresIcon {...iconProps} />;
};
