import clsx from 'clsx';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { MenuItems } from 'src/app/AppRoutes';
import { ProLogo } from 'src/components/common/ProLogo';
import { useAppContext } from 'src/contexts/AppContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { CrossIcon } from 'src/svgs/Viagogo';
import { hasAccessToRoute } from 'src/utils/userUtils';
import { Feature } from 'src/WebApiController';

import { MainMenu } from './MainMenu/MainMenu';
import * as styles from './MainNavSideBar.css';
import { MenuItem } from './MenuItem/MenuItem';
import { UserMenu } from './UserMenu/UserMenu';

type MainNavSideBarProps = {
  toggleMenu: (value?: boolean) => void;
  isExpanded: boolean;
};

const OPEN_MENU_MOUSE_EVENT = 'mouseenter';
const CLOSE_MENU_MOUSE_EVENT = 'mouseleave';

export const MainNavSideBar = ({
  toggleMenu,
  isExpanded,
}: MainNavSideBarProps) => {
  const isMobile = useMatchMedia('mobile');
  const { loginContext, appContext } = useAppContext();

  const delayedOpen = useMemo(() => {
    return debounce(() => {
      toggleMenu(true);
    }, 300);
  }, [toggleMenu]);

  const toggleClose = useCallback(() => {
    delayedOpen.cancel();
    toggleMenu(false);
  }, [delayedOpen, toggleMenu]);

  const mainSideBarRef = useRef<HTMLDivElement>(null);

  // Used to avoid the effect to unsubscribe and subscribe again to DOM events
  // when using a callback that depends on this.
  const useRefExpanded = useRef<boolean>(isExpanded);
  useRefExpanded.current = isExpanded;

  const handleOnMouseEnter = useCallback(
    (e: MouseEvent) => {
      const isExpanded = useRefExpanded.current;
      if (!isExpanded) {
        delayedOpen();
      }
    },
    [delayedOpen]
  );

  const handleOnMouseLeave = useCallback(
    (e: MouseEvent) => {
      // Only close the menu if the target event is fired
      // from the main parent div
      if (mainSideBarRef.current === e?.target) {
        toggleClose();
      }
    },
    [toggleClose]
  );

  useEffect(() => {
    const sideBar = mainSideBarRef.current;
    sideBar?.addEventListener(OPEN_MENU_MOUSE_EVENT, handleOnMouseEnter);
    return () => {
      sideBar?.removeEventListener(OPEN_MENU_MOUSE_EVENT, handleOnMouseEnter);
    };
  }, [handleOnMouseEnter]);

  useEffect(() => {
    const sideBar = mainSideBarRef.current;
    sideBar?.addEventListener(CLOSE_MENU_MOUSE_EVENT, handleOnMouseLeave);
    return () => {
      sideBar?.removeEventListener(CLOSE_MENU_MOUSE_EVENT, handleOnMouseLeave);
    };
  }, [handleOnMouseLeave]);

  return (
    <div
      ref={mainSideBarRef}
      className={clsx(styles.root, {
        [styles.expanded]: isExpanded,
        [styles.mobileRoot]: isMobile,
      })}
    >
      <div className={styles.content}>
        {isMobile && (
          <div className={styles.closeButton} onClick={toggleClose}>
            <CrossIcon />
          </div>
        )}
        <div className={styles.topMenuArea}>
          <div className={styles.logoContainer}>
            <ProLogo className={styles.logo} />
          </div>
          <MainMenu toggleClose={toggleClose} />
          {hasAccessToRoute(
            loginContext?.user,
            appContext?.features,
            MainRoute.Email
          ) && <MessagesItem toggleClose={toggleClose} />}
        </div>
        <hr className={styles.divider} />
        <div className={styles.bottomMenuArea}>
          <UserMenu toggleClose={toggleClose} />
        </div>
      </div>
    </div>
  );
};

// TODO: Add notification count pill
const MessagesItem = ({ toggleClose }: { toggleClose: () => void }) => {
  const { icon, label, path, view } = MenuItems[MainRoute.Email];

  const { mainRoute } = useAppContext();
  const hasAutoPOFeature = useUserHasFeature(Feature.AutoPO);

  return hasAutoPOFeature ? (
    <MenuItem
      isActive={view === mainRoute}
      icon={icon}
      textContent={label}
      path={path}
      onClick={toggleClose}
    />
  ) : null;
};
