import styled from 'styled-components/macro';

export const ActivityLogDateCellDiv = styled.div`
  text-transform: 'none';
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({
    theme: {
      typography: { size },
    },
  }) => size(1)};
`;
