import {
  MutableRefObject,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react';
import useResizeObserver from 'use-resize-observer';

export const PosEventsAccordionBodyHeight =
  '--pos-events-accordion-body-height';
const DefaultBottomMargin = 20;

const getVirtuosoTopListItemBounding = (): DOMRect | undefined =>
  document
    .querySelector('[data-test-id="virtuoso-top-item-list"]')
    ?.getBoundingClientRect();

interface UseVirtuosoAccordionSizeProps {
  accordionHeaderRef: MutableRefObject<HTMLDivElement | null>;
  accordionBodyRef: MutableRefObject<HTMLDivElement | null>;
  isExpanded: boolean;
  enabled: boolean;
}

const MIN_HEIGHT_FOR_VIRTUOSO_ACCORDION = 500;

export const useSetEventsAccordionMaxHeight = ({
  enabled,
  accordionHeaderRef,
  accordionBodyRef,
  isExpanded,
}: UseVirtuosoAccordionSizeProps): void => {
  const [eventsAccordionHeaderBounding, setEventsAccordionHeaderBounding] =
    useState<DOMRect | undefined>(undefined);

  const setStyle = useCallback(
    (
      eventAccordionHeaderBounding: DOMRect | undefined,
      enabled: boolean,
      expanded: boolean
    ) => {
      if (!accordionBodyRef.current || !enabled || !expanded) {
        return;
      }
      const virtuosoTopListItemBottom =
        getVirtuosoTopListItemBounding()?.bottom ?? 0;

      const eventsAccordionHeaderHeight =
        eventAccordionHeaderBounding?.height ?? 0;

      const bodyMaxExpandedHeight = Math.max(
        MIN_HEIGHT_FOR_VIRTUOSO_ACCORDION,
        window.innerHeight -
          virtuosoTopListItemBottom -
          eventsAccordionHeaderHeight -
          DefaultBottomMargin
      );

      accordionBodyRef.current.style.setProperty(
        PosEventsAccordionBodyHeight,
        `${bodyMaxExpandedHeight}px`
      );
    },
    [accordionBodyRef]
  );

  // Listens for header changes, for example, screen resizing or showing bulk actions
  useResizeObserver<HTMLDivElement>({
    ref: accordionHeaderRef,
    onResize: () => {
      const bounding = accordionHeaderRef.current?.getBoundingClientRect();
      setStyle(bounding, enabled, isExpanded);
      setEventsAccordionHeaderBounding(bounding);
    },
  });

  useLayoutEffect(() => {
    setStyle(eventsAccordionHeaderBounding, enabled, isExpanded);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExpanded, accordionBodyRef, eventsAccordionHeaderBounding, enabled]);
};
