import { clsx } from 'clsx';
import { getDocumentPageUniqueId } from 'src/components/UploadArtifacts/UploadETicketsV2';
import { MultiplePageDocumentProps } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents';
import { BasePageDocumentProps } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/BasePageDocumentProps';
import { ClearButton } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/ClearButton/ClearButton';
import { MappedPageLabel } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/MappedLabel/MappedPageLabel';
import * as styles from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/MultiplePageDocument/MultiplePageDocument.css';
import { Content } from 'src/contexts/ContentContext';
import { PosDraggableIndicator } from 'src/core/POS/PosDragNDrop';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export type MultiplePageDocumentPageProps = MultiplePageDocumentProps &
  BasePageDocumentProps;

export const MultiplePageDocumentPage = ({
  page,
  pageIndex,
  onRemoveMappedTicket,
  pageUniqueIdToTicketMap,
  entityWithTickets,
  onPageClick,
  selectedUniquePageId,
  isDragElement,
  isDragging,
  onMouseEntersPage,
  onMouseLeavesPage,
}: MultiplePageDocumentPageProps) => {
  const uniquePageId = getDocumentPageUniqueId(page);
  const isSelected = selectedUniquePageId === uniquePageId;
  const ticketInfo = pageUniqueIdToTicketMap[uniquePageId];

  return (
    <div
      className={clsx(
        {
          [styles.draggingElement]: isDragElement,
          [styles.isSelected]: isSelected,
          [styles.pageHover]: !isDragging,
          [styles.isDragging]: isDragging,
          [styles.draggingWrapper]: isDragElement && isDragging,
        },
        styles.documentWrapper
      )}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onPageClick(uniquePageId);
      }}
      onMouseEnter={() => {
        if (isDragging) {
          return;
        }
        onMouseEntersPage(uniquePageId);
      }}
      onMouseLeave={() => {
        if (isDragging) {
          return;
        }
        onMouseLeavesPage();
      }}
    >
      <PosDraggableIndicator
        leftHandle={true}
        rightHandle={false}
        topHandle={false}
        bottomHandle={false}
        classNames={styles.dragHandle}
        removeHoverEffect={true}
      >
        <Stack gap={'m'} direction={'column'} alignItems={'start'}>
          <span
            className={clsx(styles.documentName, {
              [styles.documentNameDragging]: isDragElement,
            })}
          >
            <Content id={ContentId.Page} /> {pageIndex + 1}
          </span>
          {!isDragElement && ticketInfo && entityWithTickets && (
            <Stack alignItems={'start'} gap="m" direction={'column'}>
              <MappedPageLabel
                ticketInfo={ticketInfo}
                entityWithTickets={entityWithTickets}
              />
              <ClearButton
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onRemoveMappedTicket(ticketInfo);
                }}
              />
            </Stack>
          )}
        </Stack>
      </PosDraggableIndicator>
    </div>
  );
};
