import styled from 'styled-components/macro';

export const SelectedPerformerVenueListContainer = styled.div<{
  isOpen: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${(props) => (props.isOpen ? 'calc(40vh - 41px)' : '0')};
  overflow: auto;
  background: ${(props) => props.theme.colors.backgroundSecondary};
  transition: max-height 0.5s;
`;
