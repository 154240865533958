import { isEqual, once } from 'lodash-es';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { FilterToolbarItemId } from 'src/components/FilterToolbar';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import {
  getQueryFromUrl,
  getUrlFilterQueryParam,
  isFilterQueryParamEmpty,
  MainFilterQueryParam,
  QueryWithViewMode,
  VIEW_MODE_SETTING_ID_TO_DEFAULT,
} from 'src/utils/eventQueryUtils';
import { UserSetting } from 'src/WebApiController';

export type IFilterQueryContext<TQuery extends QueryWithViewMode> = {
  initialQuery: TQuery;
  filterQuery: TQuery;
  emptyQuery: TQuery;
  tempQuery: TQuery;
  setFilterQuery: (newQuery: TQuery) => void;
  setTempQuery: (newQuery: TQuery) => void;
  resetTempQuery: () => void;
  isQueryInitialized: boolean;
};

interface ConstState<TQuery extends QueryWithViewMode> {
  initialQuery: TQuery;
  filterQuery: TQuery;
  queryFromUrl: TQuery;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryValueTransformToUrl?: (key: FilterToolbarItemId, value: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryValueTransformFromUrl?: (key: string, value: any) => any;
  searchParams: URLSearchParams;
  saveQueryInUrl: boolean;
  readQueryFromUrl: boolean;
  handleFilterChange: (query: TQuery) => void;
}

export const createFilterQueryContext = once(<
  TQuery extends QueryWithViewMode,
>() => React.createContext({} as IFilterQueryContext<TQuery>));

export function useFilterQueryContext<TQuery extends QueryWithViewMode>() {
  return useContext(createFilterQueryContext<TQuery>());
}

export function FilterQueryContextProvider<TQuery extends QueryWithViewMode>({
  initialQuery,
  emptyQuery,
  saveQueryInUrl = false,
  loadQueryFromUrl = true,
  viewModeSettingId,
  queryValueTransformToUrl,
  queryValueTransformFromUrl,
  children,
}: PropsWithChildren<{
  initialQuery: TQuery;
  emptyQuery: TQuery;
  saveQueryInUrl?: boolean;
  loadQueryFromUrl?: boolean;
  viewModeSettingId?:
    | UserSetting.InventoryPageViewMode
    | UserSetting.SalePageViewMode
    | UserSetting.PurchasePageViewMode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryValueTransformToUrl?: (key: FilterToolbarItemId, value: any) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryValueTransformFromUrl?: (key: string, value: any) => any;
}>) {
  const FilterQueryContext = createFilterQueryContext<TQuery>();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isQueryInitialized, setIsQueryInitialized] = useState<boolean>(false);

  const [readQueryFromUrl, setReadQueryFromUrl] =
    useState<boolean>(loadQueryFromUrl);

  /**
   * This is used to not generate a new query from the url every time filterQuery changes.
   */
  const [queryFromUrl, setQueryFromUrl] = useState<TQuery>(initialQuery);

  // Source of truth query
  const [filterQuery, setFilterQuery] = useState<TQuery>(initialQuery);

  // Used in filters
  const [tempQuery, setTempQuery] = useState<TQuery>(initialQuery);

  const handleFilterChange = useCallback((f: TQuery) => {
    setFilterQuery(f);
  }, []);

  const refConstState = useRef<ConstState<TQuery>>({
    initialQuery,
    filterQuery,
    queryFromUrl,
    queryValueTransformToUrl,
    queryValueTransformFromUrl,
    searchParams,
    saveQueryInUrl,
    handleFilterChange,
    readQueryFromUrl,
  });

  refConstState.current = {
    initialQuery,
    filterQuery,
    queryFromUrl,
    queryValueTransformToUrl,
    queryValueTransformFromUrl,
    searchParams,
    saveQueryInUrl,
    handleFilterChange,
    readQueryFromUrl,
  };

  // Effect when location changes => update filterQuery
  useEffect(() => {
    const {
      handleFilterChange,
      readQueryFromUrl,
      initialQuery,
      queryFromUrl,
      queryValueTransformFromUrl,
    } = refConstState.current;

    // if !loadQueryFromUrl then skip first render effect
    if (!readQueryFromUrl) {
      setReadQueryFromUrl(true);
      setIsQueryInitialized(true);
      return;
    }

    const tempQueryFromUrl = getQueryFromUrl<TQuery>(
      initialQuery,
      searchParams.toString(),
      queryValueTransformFromUrl
    );

    if (!tempQueryFromUrl) {
      setIsQueryInitialized(true);
      return;
    }

    if (isEqual(tempQueryFromUrl, queryFromUrl)) {
      setIsQueryInitialized(true);
      return;
    }

    setQueryFromUrl(tempQueryFromUrl);
    handleFilterChange(tempQueryFromUrl);
    setIsQueryInitialized(true);
    // Don't add any other deps than searchParams.
    // This needs to run only when search params changes
  }, [searchParams]);

  // filterQuery Change => Set in URL
  useEffect(() => {
    const {
      filterQuery,
      queryFromUrl,
      initialQuery,
      searchParams,
      queryValueTransformToUrl,
      saveQueryInUrl,
    } = refConstState.current;

    if (!queryFromUrl || !filterQuery || !initialQuery) {
      return;
    }

    if (isEqual(filterQuery, queryFromUrl)) {
      return;
    }

    if (saveQueryInUrl) {
      const searchQuery = getUrlFilterQueryParam(
        initialQuery,
        filterQuery,
        searchParams,
        queryValueTransformToUrl
      );
      const queryParamsToSet = new URLSearchParams(searchParams);
      if (isFilterQueryParamEmpty(searchQuery)) {
        queryParamsToSet.delete(MainFilterQueryParam);
      }
      setSearchParams(queryParamsToSet);
      setQueryFromUrl(filterQuery);
    }
    // DO NOT add more deps here.
    // This effect must run only when the filterQuery changes
  }, [filterQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    value: defaultViewModeUserSetting = viewModeSettingId
      ? VIEW_MODE_SETTING_ID_TO_DEFAULT[viewModeSettingId]
      : undefined,
    isLoading: isViewModeSettingLoading,
  } = useServerUserSetting<string>({
    id: viewModeSettingId,
    currentLoginUserOnly: true,
  });

  useEffect(() => {
    if (
      isQueryInitialized &&
      defaultViewModeUserSetting &&
      defaultViewModeUserSetting !== filterQuery?.viewMode
    ) {
      setFilterQuery((prev) => ({
        ...prev,
        viewMode: defaultViewModeUserSetting,
      }));
      // We need to update initialQuery, otherwise the quick filter "All"
      // may change the current view mode
      initialQuery.viewMode = defaultViewModeUserSetting;
    }
    // Only want to run this use-effect when the user settings changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultViewModeUserSetting, isQueryInitialized]);

  const resetTempQuery = useCallback(
    (queryKeys?: (keyof TQuery)[]) => {
      if (queryKeys) {
        const newTempQuery = { ...tempQuery };
        queryKeys.forEach((key) => (newTempQuery[key] = emptyQuery[key]));
        setTempQuery(newTempQuery);
      } else {
        setTempQuery(filterQuery);
      }
    },
    [emptyQuery, filterQuery, tempQuery]
  );

  return (
    <FilterQueryContext.Provider
      value={{
        initialQuery,
        filterQuery,
        emptyQuery,
        tempQuery,
        setTempQuery,
        setFilterQuery: handleFilterChange,
        resetTempQuery,
        isQueryInitialized: isQueryInitialized && !isViewModeSettingLoading,
      }}
    >
      {children}
    </FilterQueryContext.Provider>
  );
}
