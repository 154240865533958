import { SaleFormulaFields } from 'src/tables/SalesTable/SalesTable.type';
import {
  evaluateFormulaBase,
  FormulaEditable,
  FormulaExecutable,
  fromExecutableFormula,
  parseEditableFormulaBase,
  parseExecutableFormula,
  tagFieldNameToKey,
  tagKeyToFieldName,
  validateFormulaSingleStatement,
} from 'src/utils/columns/customColumnUtils';
import { Sale, Tag, UiMoney } from 'src/WebApiController';

import { SALE_FORMULA_FIELDS_TO_CID } from '../../constants/contentIdMaps';
import { SaleReportMetricsWithGroupBy } from '../../reportsUtils';
import { ColumnPersonalizationFlags } from '../columnUtils.types';
import {
  getPersonalizationFlagsFromFormulaBase,
  MathematicalToken,
  parseMathematicalTokensFromFormulaBase,
} from '../customColumnMathUtils';
import { SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from './salesColumnUtils.constants';
import { SalesTableColumnId } from './salesColumnUtils.types';
import { CustomSalesColumn } from './salesCustomColumnUtils.types';

export const SALE_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG: Partial<ColumnPersonalizationFlags> =
  {
    isHiddenByDefault: true,
    isNumeric: true,
  };

export const FORMULA_FIELD_TO_COLUMN_ID: Record<
  keyof SaleFormulaFields,
  SalesTableColumnId
> = {
  ['totalNetProceeds']: SalesTableColumnId.Proceeds,
  ['quantitySold']: SalesTableColumnId.QuantitySold,
  ['charges']: SalesTableColumnId.Charges,
  ['credits']: SalesTableColumnId.Credits,
  ['soldTicketCost']: SalesTableColumnId.SoldTicketCost,
  ['pandL']: SalesTableColumnId.PandL,
  ['margin']: SalesTableColumnId.Margin,
};

export const parseEditableFormula = (
  editableFormula: FormulaEditable | undefined
): string[] | undefined => {
  const parsedTokenResult = parseEditableFormulaBase(editableFormula);
  if (!parsedTokenResult) {
    return;
  }

  const tokens: string[] = [];
  parsedTokenResult.forEach((tokenResult) => {
    if (tokenResult.isPlainToken) {
      tokens.push(tokenResult.token);
    } else {
      const fieldName = tokenResult.token;
      if (
        SALE_FORMULA_FIELDS_TO_CID[fieldName as keyof SaleFormulaFields] == null
      ) {
        // Tag field
        tokens.push(tagFieldNameToKey(fieldName));
        return;
      }
      tokens.push(
        SALE_FORMULA_FIELDS_TO_CID[fieldName as keyof SaleFormulaFields]
      );
    }
  });

  return tokens;
};

export const filterCustomColumnsForSale = (
  customColumns: CustomSalesColumn[],
  tagsForEntityType: Tag[] | undefined
): CustomSalesColumn[] => {
  const saleFormulaFields = Object.keys(SALE_FORMULA_FIELDS_TO_CID);
  const tagKeys = tagsForEntityType?.map((t) => tagKeyToFieldName(t.key)) ?? [];
  return customColumns.filter((column) => {
    const editableFormula = fromExecutableFormula(column.formula);
    if (!editableFormula) return false;

    const fieldNamesUsed =
      parseEditableFormulaBase(editableFormula, true)?.map((t) => t.token) ??
      [];

    return fieldNamesUsed.every(
      (f) => saleFormulaFields.includes(f) || tagKeys.includes(f)
    );
  });
};

const mapListingFormulaFields = (sale?: Sale): SaleFormulaFields => {
  const tagFields = sale?.tags?.reduce((acc, tag) => {
    const numericValue = parseFloat(tag.value);
    if (isNaN(numericValue)) return acc;

    return {
      ...acc,
      [tagKeyToFieldName(tag.key)]: numericValue,
    };
  }, {});

  return {
    totalNetProceeds: sale?.ttlNetProcs?.amt,
    quantitySold: sale?.qtySold,
    charges: sale?.charges?.amt,
    credits: sale?.credits?.amt,
    soldTicketCost: sale?.soldTktCost?.amt,
    pandL: sale?.pandL?.amt,
    margin: sale?.margin,
    ...tagFields,
  };
};

export const evaluateFormula = (formula: FormulaExecutable, sale?: Sale) => {
  const data = mapListingFormulaFields(sale);

  return evaluateFormulaBase(formula, data);
};

export const evaluateFormulaSaleMetrics = (
  formula: FormulaExecutable,
  sale?: SaleReportMetricsWithGroupBy
) => {
  const tagFields = sale?.tags?.reduce((acc, tag) => {
    const numericValue = parseFloat(tag.value);
    if (isNaN(numericValue)) return acc;

    return {
      ...acc,
      [tagKeyToFieldName(tag.key)]: numericValue,
    };
  }, {});

  const data = {
    totalNetProceeds: sale?.netProcs?.amt,
    quantitySold: sale?.qtySold,
    charges: sale?.chargs?.amt,
    credits: sale?.creds?.amt,
    soldTicketCost: sale?.soldCst?.amt,
    pandL: sale?.pandL?.amt,
    margin: sale?.marg,
    ...tagFields,
  };

  return evaluateFormulaBase(formula, data);
};

export const SALE_FORMULA_TEST_DATA: Sale = {
  qtySold: 1,
  ttlNetProcs: { amt: 1 } as UiMoney,
  charges: { amt: 1 } as UiMoney,
  credits: { amt: 1 } as UiMoney,
  soldTktCost: { amt: 1 } as UiMoney,
  pandL: { amt: 1 } as UiMoney,
  margin: 1,
} as Sale;

const createSaleTestData = (tagsForEntityType: Tag[] | undefined): Sale => {
  const tagData = tagsForEntityType?.map((t) => {
    t.value = '1';
    return t;
  });

  return {
    ...SALE_FORMULA_TEST_DATA,
    tags: tagData ?? [],
  };
};

export const validateFormula = (
  formula: string,
  tagsForEntityType: Tag[] | undefined
) => {
  return (
    parseExecutableFormula(formula) != null &&
    evaluateFormula(formula, createSaleTestData(tagsForEntityType)) != null &&
    validateFormulaSingleStatement(formula)
  );
};

export const findDependentSaleTableColumnIds = (
  editableFormula: FormulaEditable
): SalesTableColumnId[] => {
  const fieldNames =
    parseEditableFormulaBase(editableFormula, true)?.map((t) => t.token) ?? [];

  return (
    Object.entries(FORMULA_FIELD_TO_COLUMN_ID)
      .filter(([key]) => fieldNames.includes(key))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => value)
  );
};

const getTokenFromFieldName = (fieldName: string): MathematicalToken => {
  const columnId =
    FORMULA_FIELD_TO_COLUMN_ID[fieldName as keyof SaleFormulaFields];

  if (columnId == null) {
    // Tag field - here we assume it's not currency - might need to change
    return {
      token: `tag-${fieldName}`,
      isPlainToken: false,
      isCurrency: false,
    };
  }

  const { isCurrency, isPercent } =
    SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[columnId];

  return {
    token: columnId,
    isPlainToken: false,
    isCurrency,
    isPercent,
  };
};

/**
 * Parse editableFormula, transforming formula into mathematical tokens
 * to infer personalization flags
 *
 * E.g. `${ticketCount} + 20` ==> [
 *     { token: 'ticketCount', isCurrency: false, isPercent: false, isPlainToken: false },
 *     { token: '+', isCurrency: false, isPercent: false, isPlainToken: true },
 *     { token: '20', isCurrency: false, isPercent: false, isPlainToken: true }
 * ]
 * @param editableFormula
 * @returns parsed list of tokens, or undefined if failed to parse
 */
const parseMathematicalTokensFromFormula = (
  editableFormula: FormulaEditable | undefined
): MathematicalToken[] | undefined => {
  return parseMathematicalTokensFromFormulaBase(
    editableFormula,
    getTokenFromFieldName
  );
};

export const getPersonalizationFlagsFromFormula = (
  executableFormula: FormulaExecutable
): Partial<ColumnPersonalizationFlags> => {
  const editableFormula = fromExecutableFormula(executableFormula);
  const mathTokens = parseMathematicalTokensFromFormula(editableFormula);

  return getPersonalizationFlagsFromFormulaBase(mathTokens);
};
