import { Content } from "src/contexts/ContentContext";
import { ContentId } from "src/utils/constants/contentId";
import { AccountAutoPricingSettingsInputs, AutoPricingCompListingMode, UiCurrency } from "src/WebApiController";
import { FormProvider, useForm } from "react-hook-form";
import { cloneDeep } from "lodash-es";
import { Button, Stack } from "src/core/ui";
import { Switch } from 'src/core/interim/Switch';
import { useAvailableAutopricingModes } from "src/hooks/useAvailableAutopricingModes";
import { AutoPricingCompListingModeSelector } from "src/components/AutoPricing/AutoPricingCompListingModeSelector/AutoPricingCompListingModeSelector";
import { AutoPricingCompFloorAndCeilingInput } from "src/components/AutoPricing/AutoPricingCompFloorAndCeilingInput/AutoPricingCompFloorAndCeilingInput";
import { AutoPricingDefaultCompQuantitiesInput } from "src/components/AutoPricing/AutoPricingDefaultCompQuantitiesInput/AutoPricingDefaultCompQuantitiesInput";
import { AutoPricingUnderCutRelativeAndAbsoluteAmountsInput } from "src/components/AutoPricing/AutoPricingUnderCutRelativeAndAbsoluteAmountsInput/AutoPricingUnderCutRelativeAndAbsoluteAmountsInput";
import { ChangeEvent } from "react";
import { AutoPricingOutlierDetectionSettingsInput } from "src/components/AutoPricing/AutoPricingOutlierDetectionSettingsInput/AutoPricingOutlierDetectionSettingsInput";
import { AutoPricingMinCompListingsSlider } from "src/components/AutoPricing/AutoPricingMinCompListingsSlider/AutoPricingMinCompListingsSlider";
import { AutoPricingOutlierDetectionModeSelector } from "src/components/AutoPricing/AutoPricingOutlierDetectionModeSelector/AutoPricingOutlierDetectionModeSelector";
import { PosTextField } from "src/core/POS/PosTextField";
import { setValueAsNumber } from "../../SyncCenter/SyncCenter.utils";
import { AutoPricingCircuitBreakerMaxDiscountVelocityInput } from "src/components/AutoPricing/AutoPricingCircuitBreakerMaxDiscountVelocityInput/AutoPricingCircuitBreakerMaxDiscountVelocityInput";

import * as styles from './AccountAutoPricingSettingsForm.css';

export const AccountAutoPricingSettingsForm = ({
  pricingSettings,
  onSaved,
  uiCurrency,
  disabled: disabledProp,
  allowSeatScoreCompListingMode,
  allowPriceByWebsitePrice,
}: {
  pricingSettings: AccountAutoPricingSettingsInputs | null | undefined,
  onSaved?: (pricingSettingsNew: AccountAutoPricingSettingsInputs) => Promise<void>,
  uiCurrency: UiCurrency,
  disabled: boolean,
  allowSeatScoreCompListingMode: boolean,
  allowPriceByWebsitePrice: boolean,
}) => {
  const {
    outlierModes,
    outlierModesDisabled,
  } = useAvailableAutopricingModes(
    true,
    pricingSettings?.undercutMode,
    pricingSettings?.compListingMode,
    true,
    false,
    false,
    true,
  );

  const methods = useForm<AccountAutoPricingSettingsInputs>({
    defaultValues: pricingSettings ? cloneDeep(pricingSettings) : undefined,
  });

  const {
    handleSubmit,
    register,
    setValue,
    watch,
  } = methods;

  const {
    priceByNetProceeds,
    compListingMode,
    compListingExcludeFanInventory,
    compListingExcludeDefects,
    compListingOnlyForSameZoneEnabled,
  } = watch();

  const showCompListingSettingsDetails =
    compListingMode === AutoPricingCompListingMode.QualityScore;

  const disabled = disabledProp;
  return (
    <FormProvider {...methods}>
      <div className={styles.sectionContainer}>
        <div className={styles.sectionName}>
          <Content id={ContentId.PriceAgainst} />
        </div>

        <div>
          <Content id={ContentId.AllListings} />
        </div>

        <Stack direction="column" gap="l">
          <AutoPricingCompListingModeSelector
            allowSeatScoreCompListingMode={allowSeatScoreCompListingMode}
            disabled={disabled}
          />

          {showCompListingSettingsDetails && (
            <AutoPricingCompFloorAndCeilingInput disabled={disabled} />
          )}
        </Stack>

        {showCompListingSettingsDetails && (
          <>
            <div>
              <Content id={ContentId.RestrictToSameZone} />
            </div>

            <Switch
              containerClassName={styles.inputInline}
              disabled={disabled}
              checked={(compListingOnlyForSameZoneEnabled ?? false)}
              onClick={(e: ChangeEvent<HTMLElement>) =>
                e.stopPropagation()
              }
              onChange={(e) => {
                const compListingOnlyForSameZoneEnabledNew = e.target.checked;
                if (compListingOnlyForSameZoneEnabledNew !== compListingOnlyForSameZoneEnabled) {
                  setValue('compListingOnlyForSameZoneEnabled', compListingOnlyForSameZoneEnabledNew);
                }
              }}
            />
          </>
        )}

        <div>
          <Content id={ContentId.DefaultQuantityFilters} />
        </div>

        <Stack direction="column">
          <AutoPricingDefaultCompQuantitiesInput disabled={disabled} />
        </Stack>

        <div>
          <Content id={ContentId.MinListingsRequired} />
        </div>

        <AutoPricingMinCompListingsSlider disabled={disabled} />
      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.sectionName}>
          <Content id={ContentId.CalculatePrice} />
        </div>

        {allowPriceByWebsitePrice && (
          <>
            <div>
              <Content id={ContentId.SimulateWebsiteDiscount} />
            </div>

            <Switch
              containerClassName={styles.inputInline}
              disabled={disabled}
              checked={priceByNetProceeds}
              onClick={(e: ChangeEvent<HTMLElement>) =>
                e.stopPropagation()
              }
              onChange={(e) => {
                const priceByNetProceedsNew = e.target.checked;
                if (priceByNetProceedsNew !== priceByNetProceeds) {
                  setValue('priceByNetProceeds', priceByNetProceedsNew);
                }
              }}
            />
          </>
        )}

        <div className={styles.inlinePricingInputText}>
          <Content id={ContentId.HowToDeterminePrice} />
        </div>

        <AutoPricingUnderCutRelativeAndAbsoluteAmountsInput
          uiCurrency={uiCurrency}
          disabled={disabled}
        />

        <div className={styles.inlinePricingInputText}>
          <Content id={ContentId.FloorRelativeToUnitCost} />
        </div>

        <PosTextField
          disabled={disabled}
          type="number"
          postfixDisplay="%"
          rootProps={{
            className: styles.inputInline,
          }}
          className={styles.inputInline}
          {...register('circuitBreakerRelativeFloor', {
            setValueAs: setValueAsNumber,
          })}
        />
      </div>

      <div className={styles.sectionContainer}>
        <div className={styles.sectionName}>
          <Content id={ContentId.PricingProtection} />
        </div>

        <div>
          <Content id={ContentId.ExcludeFanInventory} />
        </div>

        <Switch
          containerClassName={styles.inputInline}
          disabled={disabled}
          checked={(compListingExcludeFanInventory ?? false)}
          onClick={(e: ChangeEvent<HTMLElement>) =>
            e.stopPropagation()
          }
          onChange={(e) => {
            const compListingExcludeFanInventoryNew = e.target.checked;
            if (compListingExcludeFanInventoryNew !== compListingExcludeFanInventory) {
              setValue('compListingExcludeFanInventory', compListingExcludeFanInventoryNew);
            }
          }}
        />

        <div>
          <Content id={ContentId.IncludeDefects} />
        </div>

        <Switch
          containerClassName={styles.inputInline}
          disabled={disabled}
          checked={!(compListingExcludeDefects ?? true)}
          onClick={(e: ChangeEvent<HTMLElement>) =>
            e.stopPropagation()
          }
          onChange={(e) => {
            const compListingExcludeDefectsNew = !e.target.checked;
            if (compListingExcludeDefectsNew !== compListingExcludeDefects) {
              setValue('compListingExcludeDefects', compListingExcludeDefectsNew);
            }
          }}
        />

        <div className={styles.inlinePricingInputText}>
          <Content id={ContentId.OutlierMode} />
        </div>

        <AutoPricingOutlierDetectionModeSelector
          outlierModes={outlierModes}
          outlierModesDisabled={outlierModesDisabled}
          disabled={disabled}
        />

        <div className={styles.inlinePricingInputText}>
          <Content id={ContentId.OutlierFiltering} />
        </div>

        <AutoPricingOutlierDetectionSettingsInput disabled={disabled} />

        <div className={styles.inlinePricingInputText}>
          <Content id={ContentId.Velocity} />
        </div>

        <AutoPricingCircuitBreakerMaxDiscountVelocityInput disabled={disabled} />
      </div>

      <div className={styles.footer}>
        <Button
          disabled={disabled}
          onClick={handleSubmit((data) => onSaved?.(data))}
        >
          <Content id={ContentId.Save} />
        </Button>
      </div>
    </FormProvider>
  );
};