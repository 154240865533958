import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function MobileTicketIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M14.935 2a3 3 0 013 3v14a3 3 0 01-3 3H9.06a3 3 0 01-3-3V5a3 3 0 013-3h5.875zm.438 1.563h-6.75a1 1 0 00-.994.883l-.006.117v14.875a1 1 0 00.883.993l.117.006h6.75a1 1 0 00.993-.883l.006-.116V4.563a1 1 0 00-1-1zm-3.375 14.374a.937.937 0 110 1.875.937.937 0 010-1.875z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
