import { OpenStubhubDropdownAction } from 'src/components/common/OpenSubhubDropdownAction';
import { AddPurchaseAction } from 'src/components/Purchases/PurchaseTileActionDropdown/components/AddPurchase';
import { useContent } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Event } from 'src/WebApiController';

interface PurchaseTileActionDropdownProps {
  event: Event;
}

export const PurchaseTileActionDropdown = ({
  event,
}: PurchaseTileActionDropdownProps) => {
  const actionsMsg = useContent(ContentId.Actions);
  return (
    <PosDropdown
      key="purchase-tile-action-dropdown"
      trigger={
        <div
          title={actionsMsg}
          style={{ padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}` }}
        >
          <MoreIcon withHoverEffect fill={IconsFill.textBrand} align="middle" />
        </div>
      }
      align="end"
      triggerProps={{ disabled: false }}
    >
      <OpenStubhubDropdownAction event={event} />
      <AddPurchaseAction event={event} />
    </PosDropdown>
  );
};
