import styled from 'styled-components/macro';

export const BlinkingWrapper = styled.div`
  line-height: 0;
  &.blink-on {
    animation: blink-animation 1s steps(10, start) infinite;
    -webkit-animation: blink-animation 1s steps(10, start) infinite;
    transition: all 0.2s;
  }
  @keyframes blink-animation {
    to {
      opacity: 0.5;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      opacity: 0.5;
    }
  }
`;
