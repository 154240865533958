import { useContext } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { RolesTable } from './RolesTable';
import { getSellerRoleModalConfig } from './SellerRoleModal/SellerRoleModalConfig';
import * as styles from './UsersManagement.css';

export const Roles = () => {
  const { setModal } = useContext(ModalContext);

  return (
    <Stack className={styles.root} direction="column" gap="l">
      <Stack justifyContent="spaceBetween" alignItems="center">
        <div className={styles.title}>
          <Content id={ContentId.Roles} />
        </div>
        <Button
          variant="outline"
          style={{ alignSelf: 'flex-end', textTransform: 'capitalize' }}
          onClick={() => setModal(getSellerRoleModalConfig())}
        >
          <PlusIcon fill={IconsFill.currentColor} size={vars.iconSize.s} />
          <Content id={ContentId.NewRole} />
        </Button>
      </Stack>
      <RolesTable />
    </Stack>
  );
};
