import { useCallback, useEffect, useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import { useFormContext, useWatch } from 'react-hook-form';
import { EntityWithRealTicketsPartial } from 'src/components/UploadArtifacts/UploadETicketsV2';
import { useUploadETicketsContext } from 'src/components/UploadArtifacts/UploadETicketsV2/UploadETicketsContext';
import { PageDragPreview } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Draggable/PageDragPreview/PageDragPreview';
import {
  MultiplePageDocument,
  SingleDraggablePageDocument,
} from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents';
import { UploadETicketsUploadingFile } from 'src/components/UploadArtifacts/UploadETicketsV2/views/UploadETicketsUploadingFile';
import { Content } from 'src/contexts/ContentContext';
import { DnDFileUploader } from 'src/core/POS/DnDFileUploader';
import { useDndFilerUploaderContext } from 'src/core/POS/DnDFileUploader/DndFileUploaderContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { MAX_ALLOWED_UPLOAD_FILE_SIZE } from 'src/core/utils';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { IconsFill, UploadIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getFileHash } from 'src/utils/fileUtils';

import * as styles from '../UploadETickets.css';
import { UploadETicketsThumbnailContainer } from '../UploadETickets.styled';
import {
  DocumentFileUploadInfo,
  ETicketsForm,
  getDocumentPageIdToTicketMap,
  getDocumentPageUniqueId,
  MappedTicketInfo,
  PageUniqueIdToTicketMap,
} from '../UploadETickets.utils';

export function UploadETicketsTicketsSection({
  eTicketUploadInfos,
  setETicketUploadInfos,
  entityWithTickets,
}: {
  entityWithTickets: EntityWithRealTicketsPartial;
  eTicketUploadInfos: DocumentFileUploadInfo[];
  setETicketUploadInfos: (infos: DocumentFileUploadInfo[]) => void;
}) {
  const isMobile = useMatchMedia('mobile');
  const { setValue } = useFormContext<ETicketsForm>();
  const watchTickets = useWatch({ name: 'ticketAssignments' });
  const { setDndFileUploaderContext, selectFiles } =
    useDndFilerUploaderContext();

  const {
    setPageUniqueIdToTicketMap,
    setSelectedUniquePageId,
    selectedUniquePageId,
    setHoverUniquePageId,
  } = useUploadETicketsContext();

  const pageUniqueIdToTicketMap = useMemo<PageUniqueIdToTicketMap>(
    () =>
      getDocumentPageIdToTicketMap(watchTickets, entityWithTickets!.tickets),
    [entityWithTickets, watchTickets]
  );

  useEffect(() => {
    setPageUniqueIdToTicketMap(pageUniqueIdToTicketMap);
  }, [pageUniqueIdToTicketMap, setPageUniqueIdToTicketMap]);

  const { isDragging } = useDragLayer((monitor) => {
    return {
      isDragging: monitor.isDragging(),
    };
  });

  const removeTicketFromSeat = useCallback(
    ({ ticket, ticketIndex }: MappedTicketInfo) => {
      setValue(
        `ticketAssignments.${ticketIndex}`,
        {
          entityId: ticket.id,
          documentId: '',
          pageNumber: 0,
          pageId: '',
          pageUri: '',
          documentUri: '',
          documentType: null,
        },
        { shouldValidate: true }
      );
    },
    [setValue]
  );

  const onFileDeleted = useCallback(
    (ui: DocumentFileUploadInfo) => {
      if (ui.pages) {
        // If any of the page is mapped, unmap it
        ui.pages.forEach((ep) => {
          const mappedToTicket =
            pageUniqueIdToTicketMap[getDocumentPageUniqueId(ep)];
          if (mappedToTicket) {
            removeTicketFromSeat(mappedToTicket);
          }
        });
      }
      ui.file = undefined;
      ui.uploadInfo = undefined;
      ui.pages = undefined;

      setETicketUploadInfos([...eTicketUploadInfos]);
    },
    [
      eTicketUploadInfos,
      pageUniqueIdToTicketMap,
      removeTicketFromSeat,
      setETicketUploadInfos,
    ]
  );

  const onNewFileSelected = useCallback(
    async (files: File[]) => {
      const currentUploadedFileHashes: string[] = await Promise.all(
        eTicketUploadInfos
          .map((documentFileUploadInfo) => documentFileUploadInfo.file)
          .filter((f) => !!f)
          .map((file) => getFileHash(file!))
      );

      const filesToUpload: File[] = [];

      for await (const file of files) {
        const hash = await getFileHash(file!);
        if (!currentUploadedFileHashes.includes(hash)) {
          filesToUpload.push(file);

          // This function may recibe all selected files while the modal is open,
          // so, it may contain duplicated files.
          currentUploadedFileHashes.push(hash);
        }
      }

      if (filesToUpload.length === 0) {
        return;
      }

      const newUploadUIs = filesToUpload.map((file) => ({
        file,
        pages: [],
        uploadInfo: undefined,
      }));

      setETicketUploadInfos([...newUploadUIs, ...eTicketUploadInfos]);
    },
    [eTicketUploadInfos, setETicketUploadInfos]
  );

  const dropFiles = (
    <div className={styles.singleFileUploadWithPreviewContainer}>
      <DnDFileUploader
        onNewFilesSelected={onNewFileSelected}
        multiple={true}
        acceptedFileTypes={['.pdf']}
        maxFileSize={MAX_ALLOWED_UPLOAD_FILE_SIZE}
        showSelectedFiles={false}
        useLabelV2Styles={true}
        setExternalContext={setDndFileUploaderContext}
        highlightDropBoxOnDragging={false}
      />
    </div>
  );

  const onPageClick = useCallback(
    (pageId: string) => {
      if (isMobile) {
        if (selectedUniquePageId === pageId) {
          setSelectedUniquePageId(undefined);
          return;
        }
      }
      setSelectedUniquePageId(pageId);
    },
    [isMobile, selectedUniquePageId, setSelectedUniquePageId]
  );

  const onMouseEntersPage = useCallback(
    (uniquePageId: string) => {
      if (isMobile) {
        return;
      }
      setHoverUniquePageId(uniquePageId);
    },
    [isMobile, setHoverUniquePageId]
  );

  const onMouseLeavesPage = useCallback(() => {
    if (isMobile) {
      return;
    }
    setHoverUniquePageId(undefined);
  }, [isMobile, setHoverUniquePageId]);

  return (
    <div className={styles.uploadETicketsSectionPanel}>
      <div className={styles.seatAssignmentSectionHeader}>
        <Content id={ContentId.Tickets} />
        {!isMobile && (
          <Button
            className={styles.clearButton}
            variant="link"
            onClick={selectFiles}
          >
            <UploadIcon fill={IconsFill.currentColor} size={vars.iconSize.m} />
            <Content id={ContentId.Upload} />
          </Button>
        )}
      </div>
      <div className={styles.divider} />
      <UploadETicketsThumbnailContainer>
        {isMobile && dropFiles}
        {eTicketUploadInfos
          .filter((ui) => !!ui.file)
          .map((ui, ticketDocumentIndex) => {
            const key = `e-ticket-file-${ticketDocumentIndex}`;
            const isBeingProcessed = ui.file?.name && !ui.pages?.length;

            // If the ui has uploadInfo but no pages, it's being uploaded/processed, so just display a spinner for the file
            if (isBeingProcessed) {
              return (
                <UploadETicketsUploadingFile
                  key={key}
                  documentFileUploadInfo={ui}
                  onCancel={onFileDeleted}
                />
              );
            }

            // Has no uploadInfo or has pages, display component to display thumbnail or single-file uploader
            return (
              <div
                className={styles.singleFileUploadWithPreviewContainer}
                key={key}
              >
                {ui.pages?.length === 1 ? (
                  <SingleDraggablePageDocument
                    documentFileUploadInfo={ui}
                    onFileDeleted={() => onFileDeleted(ui)}
                    pageUniqueIdToTicketMap={pageUniqueIdToTicketMap}
                    entityWithTickets={entityWithTickets}
                    onRemoveMappedTicket={removeTicketFromSeat}
                    onPageClick={onPageClick}
                    selectedUniquePageId={selectedUniquePageId}
                    isDragging={isDragging}
                    onMouseEntersPage={onMouseEntersPage}
                    onMouseLeavesPage={onMouseLeavesPage}
                  />
                ) : (
                  <MultiplePageDocument
                    documentFileUploadInfo={ui}
                    onFileDeleted={() => onFileDeleted(ui)}
                    pageUniqueIdToTicketMap={pageUniqueIdToTicketMap}
                    entityWithTickets={entityWithTickets}
                    onRemoveMappedTicket={removeTicketFromSeat}
                    onPageClick={onPageClick}
                    selectedUniquePageId={selectedUniquePageId}
                    isDragging={isDragging}
                    onMouseEntersPage={onMouseEntersPage}
                    onMouseLeavesPage={onMouseLeavesPage}
                  />
                )}
              </div>
            );
          })}
        {!isMobile && dropFiles}
      </UploadETicketsThumbnailContainer>
      <PageDragPreview />
    </div>
  );
}
