import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ListIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M2 3h20v4H2V3zm0 7h20v4H2v-4zm0 7h20v4H2v-4z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
