import { UseFormRegister } from 'react-hook-form';
import { IconButton } from 'src/components/Buttons';
import { useContent, useFormattedContent } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { CrossIcon } from 'src/svgs/Viagogo';
import { URL_REGEX } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { TransferTicketsForm } from 'src/utils/ticketUtils';
import { isUniqueWithin } from 'src/utils/validation';

import * as styles from './UrlProof.css';

export const URLInputField = ({
  id,
  index,
  register,
  canRemove,
  onRemove,
  allFields,
  error,
  disabled,
}: {
  id: string | number;
  index: number;
  register: UseFormRegister<TransferTicketsForm>;
  canRemove: boolean;
  onRemove: () => void;
  allFields: TransferTicketsForm['acceptanceUrlInputs'];
  error?: string;
  disabled?: boolean;
}) => {
  const requiredUrlMsg = useContent(ContentId.Required);
  const invalidUrlMsg = useContent(ContentId.InvalidUrl);
  const dupeUrlMsg = useContent(ContentId.DuplicateUrl);
  const label = useFormattedContent(FormatContentId.UrlNumber, `${index + 1}`);

  return (
    <div className={styles.urlInputWrapper} key={id}>
      <PosFormField errors={error} label={label}>
        <PosTextField
          disabled={disabled}
          rootProps={{
            state: getTextFieldState(error),
          }}
          type="url"
          inputMode="url"
          maxLength={400}
          {...register(`acceptanceUrlInputs.${index}.url`, {
            pattern: {
              value: URL_REGEX,
              message: invalidUrlMsg,
            },
            validate: {
              required: (fieldVal) => {
                return fieldVal?.length ? undefined : requiredUrlMsg;
              },
              isUnique: (fieldVal) => {
                return (
                  isUniqueWithin(
                    fieldVal,
                    allFields.map((t) => t.url) || [],
                    true
                  ) || dupeUrlMsg
                );
              },
            },
          })}
        />
        {canRemove && (
          <IconButton onClick={onRemove} icon={<CrossIcon withHoverEffect />} />
        )}
      </PosFormField>
    </div>
  );
};
