import clsx from 'clsx';
import { ReactNode, useCallback, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { shared, vars } from 'src/core/themes';
import { Button, Card } from 'src/core/ui';
import { PurchaseVendorAccountDialog } from 'src/dialogs/PurchaseVendorAccountDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { EditIcon, IconsFill, MoreIcon, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  PurchaseClient,
  PurchaseVendorAccount,
  PurchaseVendorAccountDetails,
} from 'src/WebApiController';

import { VendorAccountEditInfo } from '../../VendorManagement/Vendors/hooks/useAddVendorAccount';
import * as styles from './SellerAccountSettingVendorAccounts.css';

const EMPTY_INFO_PLACEHOLDER = '-';

export type SellerAccountSettingVendorAccountsProps = {
  vendorId: number;
  vendorAccounts: PurchaseVendorAccount[] | undefined;
  refetchVendorAccounts: () => Promise<unknown>;
};

export function SellerAccountSettingVendorAccounts({
  vendorId,
  vendorAccounts,
  refetchVendorAccounts,
}: SellerAccountSettingVendorAccountsProps) {
  const { activeAccountWebClientConfig } = useAppContext();
  const venderAccountDialog = useBasicDialog();
  const deleteDialog = useBasicDialog();
  const [currentVendorAccount, setCurrentVendorAccount] =
    useState<VendorAccountEditInfo>();
  const { showErrorDialog } = useErrorBoundaryContext();

  const onAddNewVendorAccount = useCallback(() => {
    setCurrentVendorAccount({
      vendorId: vendorId,
      id: '',
    });
    venderAccountDialog.launchDialog();
  }, [venderAccountDialog, vendorId]);

  const onSaveVendorAccount = useCallback(
    async (vendorAccount: PurchaseVendorAccountDetails) => {
      await tryInvokeApi(
        async () => {
          const newId = await new PurchaseClient(
            activeAccountWebClientConfig
          ).mergeVendorAccount(vendorAccount);

          if (newId) {
            refetchVendorAccounts();
            venderAccountDialog.closeDialog();
          } else {
            // This should never happen
            // Normal errors would have fallen to the error catch
            showErrorDialog(
              'PurchaseClient.mergeVendorAccount',
              {
                message:
                  'Unable to create or save vendorAccount ' + vendorAccount.id,
                status: 500,
              } as ApiException,
              {
                trackErrorData: vendorAccount,
              }
            );
          }
        },
        (error) => {
          showErrorDialog('PurchaseClient.mergeVendorAccount', error, {
            trackErrorData: vendorAccount,
          });
        }
      );
    },
    [
      activeAccountWebClientConfig,
      refetchVendorAccounts,
      venderAccountDialog,
      showErrorDialog,
    ]
  );

  const onVendorAccountCancel = useCallback(() => {
    setCurrentVendorAccount(undefined);
    venderAccountDialog.closeDialog();
  }, [venderAccountDialog]);

  const onVendorAccountDelete = useCallback(async () => {
    await tryInvokeApi(
      async () => {
        const newId = await new PurchaseClient(
          activeAccountWebClientConfig
        ).deleteVendorAccount(currentVendorAccount?.id);

        if (newId) {
          refetchVendorAccounts();
          deleteDialog.closeDialog();
        } else {
          // This should never happen
          // Normal errors would have fallen to the error catch
          showErrorDialog(
            'PurchaseClient.deleteVendorAccount',
            {
              message:
                'Unable to delete vendor account ' + currentVendorAccount?.id,
              status: 500,
            } as ApiException,
            {
              trackErrorData: currentVendorAccount,
            }
          );
        }
      },
      (error) => {
        showErrorDialog('PurchaseClient.deleteVendorAccount', error, {
          trackErrorData: currentVendorAccount,
        });
      }
    );
  }, [
    activeAccountWebClientConfig,
    currentVendorAccount,
    refetchVendorAccounts,
    deleteDialog,
    showErrorDialog,
  ]);

  return (
    <>
      <div className={shared.flex({ direction: 'column', gap: 'md' })}>
        <div className={styles.title}>
          <Content id={ContentId.BuyerAccounts} />
        </div>
        <Virtuoso
          className={styles.gridContainer}
          style={{
            width: '100%',
            minHeight: `${Math.min(8, vendorAccounts?.length ?? 0) * 100}px`,
          }}
          data={vendorAccounts}
          overscan={window.innerHeight}
          itemContent={(i, vendorAccount) => {
            const { id, name, email } = vendorAccount;
            return (
              <Card key={id} className={styles.card}>
                <InfoEntry label={<Content id={ContentId.Name} />}>
                  {name || EMPTY_INFO_PLACEHOLDER}
                </InfoEntry>
                <InfoEntry label={<Content id={ContentId.Email} />}>
                  {email || EMPTY_INFO_PLACEHOLDER}
                </InfoEntry>
                <div className={styles.moreDropdownWrapper}>
                  <PosDropdown
                    trigger={
                      <MoreIcon
                        withHoverEffect
                        fill={IconsFill.textBrand}
                        align="middle"
                      />
                    }
                    align="end"
                  >
                    <PosDropdownItem
                      onClick={() => {
                        setCurrentVendorAccount(vendorAccount);
                        venderAccountDialog.launchDialog();
                      }}
                    >
                      <div
                        className={shared.flex({
                          alignItems: 'center',
                          gap: 'md',
                        })}
                      >
                        <EditIcon withHoverEffect />
                        <Content id={ContentId.Edit} />
                      </div>
                    </PosDropdownItem>
                    {/*
                    Endpoints are not available (DeleteVendorAccount) - TODO on POS-518
                    <PosDropdownItem
                      onClick={() => {
                        setCurrentVendorAccount(vendorAccount);
                        deleteDialog.launchDialog();
                      }}
                    >
                      <div
                        className={shared.flex({
                          alignItems: 'center',
                          gap: 'md',
                        })}
                      >
                        <DeleteIcon withHoverEffect />
                        <Content id={ContentId.Remove} />
                      </div>
                    </PosDropdownItem> */}
                  </PosDropdown>
                </div>
              </Card>
            );
          }}
        />
        <Button
          variant="outline"
          className={styles.addAccountButton}
          onClick={onAddNewVendorAccount}
        >
          <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
          <Content id={ContentId.AddAccountAction} />
        </Button>
      </div>
      {currentVendorAccount && (
        <PurchaseVendorAccountDialog
          {...venderAccountDialog.dialogProps}
          vendorAccountId={currentVendorAccount.id}
          vendorId={currentVendorAccount.vendorId}
          onClosed={onVendorAccountCancel}
          onSave={onSaveVendorAccount}
        />
      )}
      <ConfirmDialog
        {...deleteDialog.dialogProps}
        headerText={<Content id={ContentId.DeletePurchaseVendorAccount} />}
        bodyText={<Content id={ContentId.DeletePurchaseVendorAccountWarning} />}
        onOkay={onVendorAccountDelete}
        onCancel={deleteDialog.closeDialog}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
      />
    </>
  );
}

function InfoEntry({
  label,
  children,
}: {
  label: ReactNode;
  children: ReactNode;
}) {
  return (
    <div className={shared.flex({ direction: 'column', gap: 'sm' })}>
      <div className={clsx(shared.typography.caption1, styles.textOverflow)}>
        {label}
      </div>
      <div className={styles.textOverflow}>{children}</div>
    </div>
  );
}
