import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const MergeIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 24">
      <circle
        cx="10.5"
        cy="10.5"
        r="10"
        stroke="currentColor"
        fill="none"
      ></circle>
      <path
        d="M 4.618 5.794 C 4.618 5.794 6.252 5.794 7.495 5.794 C 8.174 5.794 9.079 6.198 9.521 7.56 C 10.084 9.288 11.514 10.185 11.514 10.185 M 11.514 10.185 L 17.559 10.185 M 11.514 10.185 C 11.514 10.185 10.101 11.976 9.521 13.541 C 9.196 14.419 8.172 15.206 7.495 15.206 C 6.116 15.206 4.618 15.206 4.618 15.206 M 17.893 10.52 L 15.073 6.854 M 17.882 9.816 L 15.073 13.541"
        stroke="currentColor"
        fill="none"
      ></path>
    </SvgWrapper>
  );
};
