import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMeasure } from 'react-use';
import { useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useGetEventSellThroughRates } from 'src/hooks/api/useGetEventSellThroughRates';
import { ContentId } from 'src/utils/constants/contentId';
import { SellThroughRateHistory } from 'src/WebApiController';

import {
  EventSellThroughRateTargetsForm,
  SellThroughRateSectionProps,
} from './SellThroughRateSection.types';

export const SellThroughRateGraphBody = ({
  event,
}: SellThroughRateSectionProps) => {
  const sellThroughRatesQuery = useGetEventSellThroughRates(event.viagId);

  const targetText = useContent(ContentId.Target);
  const actualText = useContent(ContentId.Actual);
  const todayText = useContent(ContentId.Today);

  const { watch } = useFormContext<EventSellThroughRateTargetsForm>();
  const strTargets = watch('strTargets');

  const [containerRef, { width: containerWidth }] =
    useMeasure<HTMLDivElement>();

  const todayDate = useMemo(() => {
    const now = new Date(Date.now());
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }, []);

  const dataAsTuples = useMemo(() => {
    const { data: sellThroughRates } = sellThroughRatesQuery;
    const sellThroughRateTargets = strTargets
      .filter((r) => r.timelineDate != null && r.sellThroughRate != null)
      .map(
        (r) =>
          ({
            timelineDate: r.timelineDate,
            sellThroughRatePercentage: r.sellThroughRate! * 0.01,
          }) as SellThroughRateHistory
      );

    if (!sellThroughRates?.length && !sellThroughRateTargets?.length) return;

    const dataMapped: {
      [key: string]: Highcharts.SeriesOptionsType & {
        data: [number, number][];
      };
    } = {};

    if (sellThroughRates?.length) {
      const sellThroughRatesSorted = sellThroughRates.sort(
        (a, b) =>
          new Date(a.timelineDate!).getTime() -
          new Date(b.timelineDate!).getTime()
      );

      // We want to extrapolate the most recent actual value to be shown for today's date
      // By adding a new data point with today's date and the same value
      const needsNewDataPoint =
        new Date(
          sellThroughRatesSorted[sellThroughRatesSorted.length - 1].timelineDate
        ) < todayDate;

      const sellThroughRatesExtrapolated = needsNewDataPoint
        ? [
            ...sellThroughRatesSorted,
            {
              ...sellThroughRatesSorted[sellThroughRatesSorted.length - 1],
              timelineDate: todayDate,
            },
          ]
        : [...sellThroughRatesSorted];

      const strItem: Highcharts.SeriesOptionsType & {
        data: [number, number][];
      } = {
        data: [],
        type: 'spline',
        name: actualText,
        color: vars.color.textBrand,
        opacity: 1,
        marker: {
          enabled: sellThroughRatesExtrapolated.length < 2,
        },
        visible: true,
        tooltip: {
          valueSuffix: '%',
          valueDecimals: 2,
        },
      };

      sellThroughRatesExtrapolated.forEach((rate) => {
        strItem.data.push([
          new Date(rate.timelineDate).getTime(),
          rate.sellThroughRatePercentage * 100,
        ]);
      });

      if (strItem.data.length > 0) {
        dataMapped['actual'] = strItem;
      }
    }

    if (sellThroughRateTargets?.length) {
      const strTargetItem: Highcharts.SeriesOptionsType & {
        data: [number, number][];
      } = {
        data: [],
        type: 'spline',
        name: targetText,
        color: vars.color.textBrand,
        opacity: 0.5,
        dashStyle: 'Dash',
        marker: {
          lineColor: vars.color.textBrand,
          fillColor: vars.color.textBrand,
          symbol: 'circle',
        },
        visible: true,
        tooltip: {
          valueSuffix: '%',
        },
      };

      sellThroughRateTargets
        ?.sort(
          (a, b) =>
            new Date(a.timelineDate!).getTime() -
            new Date(b.timelineDate!).getTime()
        )
        ?.forEach((rate) => {
          strTargetItem.data.push([
            new Date(rate.timelineDate).getTime(),
            rate.sellThroughRatePercentage * 100,
          ]);
        });

      if (strTargetItem.data.length > 0) {
        dataMapped['target'] = strTargetItem;
      }
    }

    return Object.values(dataMapped);
  }, [sellThroughRatesQuery, strTargets, todayDate, actualText, targetText]);

  const options: Highcharts.Options = {
    title: { text: '' },
    chart: {
      zooming: { type: 'x' },
      width: 0.9 * (containerWidth ?? 500),
      backgroundColor: 'transparent',
    },
    xAxis: {
      type: 'datetime',
      plotLines: [
        {
          // Today indicator
          color: vars.color.backgroundBrandHover,
          width: 3,
          value: todayDate.getTime(),
          label: {
            text: todayText,
            align: 'right',
            x: -10,
            y: 20,
            rotation: 0,
            style: {
              color: vars.color.textBrand,
              fontSize: vars.typography.fontSize.sm,
            },
          },
        },
      ],
      labels: {
        style: {
          color: vars.color.textPrimary,
          fontSize: vars.typography.fontSize.sm,
        },
      },
      lineColor: vars.color.textBrandActive,
      lineWidth: 1,
      tickColor: undefined,
    },
    yAxis: {
      labels: {
        format: '{text}%',
        style: {
          color: vars.color.textPrimary,
          fontSize: vars.typography.fontSize.sm,
        },
      },
      lineColor: vars.color.textBrandActive,
      lineWidth: 1,
      title: { text: '' },
      gridLineColor: undefined,
      max: 100,
      min: 0,
    },
    legend: { enabled: true, itemStyle: { color: vars.color.textPrimary } },
    plotOptions: {
      spline: {
        color: vars.color.backgroundBrandActive,
        marker: {
          radius: 4,
          lineColor: vars.color.backgroundBrandHover,
          fillColor: '#fff',
          lineWidth: 2,
        },
        lineWidth: 2,
        threshold: null,
      },
      series: {
        animation: false,
      },
    },
    series: dataAsTuples,
    // Watermark
    credits: { enabled: false },
  };

  return (
    <>
      {sellThroughRatesQuery?.data?.length || strTargets.length ? (
        <Stack
          direction="column"
          ref={containerRef}
          style={{ minWidth: '600px', maxWidth: '1200px', flexGrow: 1 }}
        >
          <HighchartsReact highcharts={Highcharts} options={options} />
        </Stack>
      ) : null}
    </>
  );
};
