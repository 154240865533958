import { Label } from '@radix-ui/react-label';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  SITE_THEME_MODE_STORAGE_KEY,
  SiteThemeOption,
  SiteZoomOption,
  useSiteTheme,
} from 'src/contexts/SiteTheme/SiteThemeContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack, TooltipPopover } from 'src/core/ui';
import { clearIDBCache } from 'src/core/utils/createIndexDBPersister';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { IconsFill, InfoOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SITE_THEME_OPTION_TO_CID_MAP } from 'src/utils/constants/contentIdMaps';
import { Feature, Permission } from 'src/WebApiController';

import { CurrencySection } from './CurrencySection';
import * as styles from './MyAccount.css';
import { TimezoneSection } from './TimezoneSection';

// TODO: Will convert elements to form
export const MyAccount = () => {
  const { loginContext } = useAppContext();
  const { isZoomSupported, selectedZoom, setSiteZoom } = useSiteTheme();
  const { selectedMode, setSiteMode } = useSiteTheme();
  const hasPOSTimezoneFeature = useUserHasFeature(Feature.POSTimezone);
  const hasCurrencyConversionSettingsTabFeature = useUserHasFeature(
    Feature.CurrencyConversionSettingsTab
  );
  const userInformation = {
    name: loginContext?.user?.activeAccount?.accountName,
    email: loginContext?.user?.userName,
  };

  const hasAdminPermission = useUserHasAnyOfPermissions(
    Permission.SellerAccount_Admin
  );

  const queryClient = useQueryClient();

  const onZoomChange = useCallback(
    (value: string) => {
      if (value !== selectedZoom) setSiteZoom(value as SiteZoomOption);
    },
    [selectedZoom, setSiteZoom]
  );

  const onThemeChange = useCallback(
    (value: string) => {
      if (value !== selectedMode) setSiteMode(value as SiteThemeOption);
    },
    [selectedMode, setSiteMode]
  );

  const zoomOptions = Object.values(SiteZoomOption).reduce(
    (options, current) => ({ ...options, [current]: current }),
    {}
  );
  return (
    <div>
      <div className={styles.accountInformation}>
        <div className={styles.sectionHeading}>
          <Content id={ContentId.AccountInformation} />
        </div>
        <Label className={styles.label}>
          <Content id={ContentId.Name} />
          <PosTextField value={userInformation.name} disabled />
        </Label>
        <Label className={styles.label}>
          <Content id={ContentId.Email} />
          <PosTextField value={userInformation.email} disabled />
        </Label>
      </div>
      <div className={styles.divider} />
      <div className={styles.passwordSection}>
        <div className={styles.sectionContainer}>
          <div className={styles.sectionHeading}>
            <Content id={ContentId.Password} />
          </div>
          <p className={styles.subtleText}>
            <Content id={ContentId.NeverShareYourPasswordWithAnyone} />
          </p>
        </div>
        <Button variant="outline" disabled>
          <Content id={ContentId.ChangePassword} />
        </Button>
      </div>
      <div className={styles.divider} />
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeading}>
          <Content id={ContentId.Display} />
        </div>
        <p className={styles.subtleText}>
          <Content id={ContentId.ChooseYourDisplaySettings} />
        </p>
        {isZoomSupported && (
          <Label className={styles.label}>
            <Content id={ContentId.Zoom} />
            <PosSelect
              className={styles.select}
              value={selectedZoom}
              valueOptionsContent={zoomOptions}
              onChange={onZoomChange}
            />
          </Label>
        )}
        <Label className={styles.label}>
          <Content id={ContentId.Theme} />
          <PosSelect
            className={styles.select}
            value={selectedMode}
            valueOptionsContent={SITE_THEME_OPTION_TO_CID_MAP}
            onChange={onThemeChange}
          />
        </Label>
        {hasPOSTimezoneFeature && hasAdminPermission && (
          <>
            <div className={styles.divider} />
            <TimezoneSection />
          </>
        )}

        <div className={styles.divider} />
        <Label className={styles.label}>
          <Content id={ContentId.Language} />
          <PosSelect
            disabled
            className={styles.select}
            defaultValue="english" // TODO: replace with map
            valueOptionsContent={{
              english: 'English', // TODO: replace with map
            }}
            onChange={() => {}}
          />
        </Label>
        <Label className={styles.label}>
          <Stack gap="m">
            <Content id={ContentId.ClearBrowsingData} />
            <TooltipPopover
              contentVariant="dark"
              triggerContent={
                <InfoOutlineIcon withHoverEffect fill={IconsFill.textWarning} />
              }
            >
              <Content id={ContentId.ClearBrowsingDataHelp} />
            </TooltipPopover>
          </Stack>
          <Button
            style={{ width: 'fit-content' }}
            onClick={() => {
              queryClient.clear();
              clearIDBCache();
              localStorage.removeItem(SITE_THEME_MODE_STORAGE_KEY);
            }}
          >
            <Content id={ContentId.ClearData} />
          </Button>
        </Label>
      </div>
      <div className={styles.divider} />
      {!hasCurrencyConversionSettingsTabFeature && (
        <>
          <CurrencySection />
          <div className={styles.divider} />
        </>
      )}
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeading}>
          <Content id={ContentId.ExperimentalFeatures} />
        </div>
        <p className={styles.subtleText}>
          <Content id={ContentId.ExperimentalFeaturesComingSoon} />
        </p>
      </div>
    </div>
  );
};
