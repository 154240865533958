import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ErrorOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M12 2c5.51 0 10 4.49 10 10s-4.49 10-10 10S2 17.51 2 12 6.49 2 12 2zm0 1.667c-4.592 0-8.333 3.741-8.333 8.333 0 4.592 3.741 8.333 8.333 8.333 4.592 0 8.333-3.741 8.333-8.333 0-4.592-3.741-8.333-8.333-8.333zM12.943 12l2.862-2.862a.666.666 0 10-.943-.943L12 11.057 9.138 8.195a.666.666 0 10-.943.943L11.057 12l-2.862 2.862a.666.666 0 10.943.943L12 12.943l2.862 2.862a.665.665 0 00.943 0 .666.666 0 000-.943L12.943 12z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
