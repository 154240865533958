import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ModalBodyDataContainer } from 'src/modals/Modal/Modal.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { TransferTicketsForm } from 'src/utils/ticketUtils';
import { Feature, Permission, TransferTicketType } from 'src/WebApiController';

export const ProofTypeSelectionBody = ({
  supportTicketmasterBarcodeTransfer,
}: {
  supportTicketmasterBarcodeTransfer?: boolean;
}) => {
  const { watch, setValue } = useFormContext<TransferTicketsForm>();
  const watchTransferType = watch('transferType');
  const has1TicketApiFeature = useUserHasFeature(Feature.OneTicketTransferApi);
  const hasTransferWithoutProof = useUserHasAnyOfPermissions(
    Permission.Sales_ConfirmTransferWithoutProof
  );
  const hasTransferWithoutProofFeature = useUserHasFeature(
    Feature.ConfirmTransferWithoutProof
  );

  return (
    <ModalBodyDataContainer>
      <Stack direction="column" gap="xl">
        <Content id={ContentId.TransferTicketsChoice} />
        <Stack direction="column" gap="l">
          <RadioGroup
            onValueChange={(value) =>
              setValue('transferType', value as TransferTicketType)
            }
            value={watchTransferType}
          >
            <Stack direction="column" gap="m">
              <Radio
                value={TransferTicketType.ImageProof}
                label={
                  <Content
                    id={ContentId.ProvideTransferImageProofInstruction}
                  />
                }
              />
              <Radio
                value={TransferTicketType.URL}
                label={<Content id={ContentId.ProvideTransferUrlInstruction} />}
              />
              {supportTicketmasterBarcodeTransfer && (
                <>
                  <Radio
                    value={TransferTicketType.ApiTransferTicketmaster}
                    label={
                      <Content
                        id={
                          ContentId.TicketmasterBarcodeTransferViaTradeDeskInstruction
                        }
                      />
                    }
                  />
                  {has1TicketApiFeature && (
                    <Radio
                      value={TransferTicketType.ApiTransfer1Ticket}
                      label={
                        <Content
                          id={
                            ContentId.TicketmasterBarcodeTransferViaOneTicketInstruction
                          }
                        />
                      }
                    />
                  )}
                </>
              )}
              {hasTransferWithoutProof && hasTransferWithoutProofFeature && (
                <Radio
                  value={TransferTicketType.None}
                  label={<Content id={ContentId.ConfirmTransferWithoutProof} />}
                />
              )}
            </Stack>
          </RadioGroup>
        </Stack>
      </Stack>
    </ModalBodyDataContainer>
  );
};
