import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import { PurchasesSideTable } from 'src/components/Purchases/PurchaseEventPage/PurchasesSideTable';
import { Content } from 'src/contexts/ContentContext';
import {
  EventSessionRole,
  useEventHubContext,
} from 'src/contexts/EventHubContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { Button, Stack } from 'src/core/ui';
import * as Tabs from 'src/core/ui/Tabs';
import { useGetCompListings } from 'src/hooks/useGetCompListings';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { getSectionInfoDisplay } from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getCompleteEventConfigScoreOverrides,
  getSeatScore,
} from 'src/utils/seatScoreUtils';
import {
  CompListing,
  Event,
  Feature,
  Listing,
  SectionInfo,
} from 'src/WebApiController';

import { InventoryEventPageSeatMapV2 } from '../InventoryEventPageSeatMapV2';
import { NoteSection } from '../Sections/NoteSection';
import { NoteSectionV2 } from '../Sections/NoteSectionV2';
import { ManualSectionRowIdFilter } from './ManualSectionRowIdFilter';
import { MarketListingHeader, SidePanelTab } from './MarketListingHeader';
import * as styles from './MarketListings.css';
import { useStubHubListingManualPricingContext } from './StubHubListingsManualPricingContext';

const QUANTITY_OPTIONS = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
].reduce(
  (r, c) => {
    r[c] = c;
    return r;
  },
  {} as Record<string, string>
);

type StubHubListingsWithManualPricingProps = {
  event: Event;
  eventListings?: Listing[] | null;

  selectedListingIds: number[];
};

export const StubHubListingsWithManualPricing = ({
  event,
  eventListings,
  selectedListingIds,
}: StubHubListingsWithManualPricingProps) => {
  const [activeTab, setActiveTab] = useState<SidePanelTab>(
    SidePanelTab.LISTINGS
  );

  const hasSidePanelFeature = useUserHasFeature(
    Feature.FullPageEventViewSidePanel
  );
  const hasFullPageEventViewSidePanelSelectionFilterFeature = useUserHasFeature(
    Feature.FullPageEventViewSidePanelSelectionFilter
  );
  const hasEventNotesV2 = useUserHasFeature(Feature.EventNotesV2);

  const { sessionRole, enabled } = useEventHubContext();

  const listingIdFilterQuery = useMemo(() => {
    return {
      listingIds: selectedListingIds?.length > 0 ? selectedListingIds : null,
    };
  }, [selectedListingIds]);

  const selectedListing = useMemo(() => {
    if (selectedListingIds.length > 1) {
      return undefined;
    }
    let listing = eventListings?.find((l) => l.id === selectedListingIds[0]);
    if (!listing) {
      listing = eventListings
        ?.flatMap((l) => flattenListingGroup(l))
        .find((l) => l.isFull && l.id === selectedListingIds[0]) as Listing;
    }

    if (listing?.isLtGrp) {
      return undefined;
    }
    return listing;
  }, [eventListings, selectedListingIds]);

  const {
    onQuantityFilterChanged,
    onSectionZoneFilterChanged,
    onSectionFilterChanged,
    resetFilters,
    selectedSections,
    sectionIdFilters,
    rowIdFilters,
    quantityFilter,
    hasActiveFilters,
  } = useStubHubListingManualPricingContext();

  const isMobile = useMatchMedia('mobile');

  const { compListingsQuery } = useGetCompListings(event, selectedListing?.id);

  const { venueMapInfo, activeConfigOverride } = useEventMapContext();

  const scoreOverrides = useMemo(
    () =>
      getCompleteEventConfigScoreOverrides(
        venueMapInfo?.sectionScores,
        activeConfigOverride?.scoreOverrides,
        false
      ),
    [activeConfigOverride?.scoreOverrides, venueMapInfo?.sectionScores]
  );

  const compListingsWithSeatScore: CompListing[] | undefined = useMemo(() => {
    const compListingData = compListingsQuery.data;

    const compListings =
      compListingData?.map((l) => {
        const seatScore = getSeatScore(
          l,
          scoreOverrides,
          venueMapInfo?.sections
        );
        if (seatScore != null) return { ...l, seatScore };

        return l;
      }) ?? [];

    if (selectedSections.length === 0) {
      return compListings;
    }
    const filterNames = new Set<string>(
      selectedSections.map(({ name }) => name)
    );

    const allFilterRowIds = new Set<number>();
    for (const section of selectedSections) {
      for (const row of section.rows) {
        allFilterRowIds.add(row.id);
      }
    }

    const formFilterRowIds = new Set(rowIdFilters);

    const filterRowIds =
      formFilterRowIds.size > 0 ? formFilterRowIds : allFilterRowIds;

    return compListings.filter((l) =>
      l.rowId
        ? filterRowIds.has(l.rowId)
        : l.section
        ? filterNames.has(l.section)
        : false
    );
  }, [
    compListingsQuery.data,
    selectedSections,
    rowIdFilters,
    scoreOverrides,
    venueMapInfo?.sections,
  ]);

  const onSectionHovered = useCallback(
    (hoveredSection: SectionInfo) => {
      return getSectionInfoDisplay(
        hoveredSection,
        compListingsWithSeatScore,
        venueMapInfo?.sectionScores
      );
    },
    [compListingsWithSeatScore, venueMapInfo?.sectionScores]
  );

  const onSectionClicked = useCallback(
    (e: MouseEvent, section: SectionInfo) => {
      if (e.shiftKey) {
        if (venueMapInfo && section.rows.length > 0) {
          // Try to just get the ticket class id of the first valid row in the section
          const row = section.rows[0];
          if (row) {
            const ticketClassId = row.tktClass?.id;
            if (ticketClassId) {
              // If we have a valid ticketClassId, then try to get all sections with the same ticketClassId
              const sectionsSameZone = venueMapInfo.sections?.filter((s) =>
                s.rows.some((r) => r.tktClass?.id === ticketClassId)
              );
              onSectionZoneFilterChanged(sectionsSameZone);
            }
          }
        }
      } else {
        onSectionFilterChanged(section);
      }
    },
    [onSectionFilterChanged, onSectionZoneFilterChanged, venueMapInfo]
  );

  const onToggleMirrors = useCallback(
    (mirrors: SectionInfo[], exclude?: boolean) => {
      const mirrorIds = mirrors.map(({ id }) => id);
      const filteredIds: number[] = [];
      if (exclude) {
        const filtered = sectionIdFilters.filter(
          (id) => !mirrorIds.includes(id)
        );
        filteredIds.push(...filtered);
      } else {
        const filtered = sectionIdFilters.concat(mirrorIds);
        filteredIds.push(...filtered);
      }
      const filteredSections = (venueMapInfo?.sections ?? []).filter(({ id }) =>
        filteredIds.includes(id)
      );
      onSectionZoneFilterChanged(filteredSections);
    },
    [onSectionZoneFilterChanged, sectionIdFilters, venueMapInfo?.sections]
  );

  return (
    <Tabs.Root
      value={activeTab}
      onValueChange={(value) => setActiveTab(value as SidePanelTab)}
      style={{ width: '100%', height: '100%' }}
    >
      <Stack direction="column" className={styles.root} gap="l" width="full">
        {isMobile && <SwiperButton dir="left" />}
        <MarketListingHeader />

        <Tabs.Content value={SidePanelTab.LISTINGS}>
          <Stack
            direction={
              sessionRole === EventSessionRole.SidePanel ? 'row' : 'column'
            }
            width="full"
            height="full"
            gap="m"
          >
            <div
              className={clsx(styles.venueMapContainer, {
                [styles.companionVenueMapContainer]:
                  enabled && sessionRole === EventSessionRole.SidePanel,
              })}
            >
              <InventoryEventPageSeatMapV2
                selectedListings={[]}
                selectedSectionIds={sectionIdFilters}
                markedSectionIds={sectionIdFilters}
                onSectionClicked={onSectionClicked}
                onSectionHovered={onSectionHovered}
                onToggleMirrors={onToggleMirrors}
                setSelectedSections={(sections) =>
                  onSectionZoneFilterChanged(sections, false)
                }
                showColorBand={false}
              />
            </div>
            <Stack
              direction="column"
              gap="m"
              className={clsx(styles.contentContainer, {
                [styles.companionContentContainer]:
                  enabled && sessionRole === EventSessionRole.SidePanel,
              })}
            >
              <Stack direction="row" gap="m">
                {hasActiveFilters && (
                  <Button variant={'textPlain'} onClick={() => resetFilters()}>
                    <Content id={ContentId.ClearAll} />
                  </Button>
                )}
              </Stack>

              <ManualSectionRowIdFilter />
              <Stack direction="row" gap="m">
                <div className={styles.inlinePricingInputText}>
                  <Content id={ContentId.MinQty} />
                </div>
                <PosSelect
                  style={{ width: 'fit-content' }}
                  searchable={true}
                  // Value must be string for PosSelect
                  value={
                    quantityFilter != null
                      ? quantityFilter.toString()
                      : undefined
                  }
                  valueOptionsContent={QUANTITY_OPTIONS}
                  placeholderText={ContentId.None}
                  onChange={(newValue) => {
                    onQuantityFilterChanged(parseInt(newValue));
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </Tabs.Content>
        {hasSidePanelFeature && (
          <Tabs.Content value={SidePanelTab.PURCHASES}>
            <Stack direction="column" width="full" gap="m">
              <PurchasesSideTable
                viagVirtualId={event.viagVirtualId}
                queryInput={
                  hasFullPageEventViewSidePanelSelectionFilterFeature
                    ? listingIdFilterQuery
                    : undefined
                }
              />
            </Stack>
          </Tabs.Content>
        )}
        <Tabs.Content value={SidePanelTab.NOTES}>
          {hasEventNotesV2 ? (
            <NoteSectionV2 eventId={event.viagId} />
          ) : (
            <NoteSection viagVirtualId={event.viagVirtualId} />
          )}
        </Tabs.Content>
      </Stack>
    </Tabs.Root>
  );
};
