import { ReactNode } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import Swiper from 'swiper';

import { ListingFilterBar } from '../Listings/ListingFilterBar';
import { PurchaseFilterBar } from '../Purchases/PurchaseFilterBar/PurchaseFilterBar';
import { SaleFilterBar } from '../Sales/SaleFilterBar';
import * as styles from './MainFilterBar.css';

export function MainFilterBar(props: {
  isEventPage?: boolean;
  returnUrl?: string;
  posEventId?: string;
  swiperRef?: React.MutableRefObject<Swiper | undefined>;
}) {
  const { isEventPage, returnUrl, posEventId, swiperRef, ...sharedProps } =
    props;
  const { mainRoute } = useAppContext();

  return mainRoute === MainRoute.Inventory ? (
    <ListingFilterBar
      {...sharedProps}
      isEventPage={isEventPage}
      returnUrl={returnUrl}
      posEventId={posEventId}
      swiperRef={swiperRef}
    />
  ) : mainRoute === MainRoute.Purchases ? (
    <PurchaseFilterBar
      isEventPage={isEventPage}
      returnUrl={returnUrl}
      posEventId={posEventId}
    />
  ) : (
    <SaleFilterBar
      {...sharedProps}
      isEventPage={isEventPage}
      returnUrl={returnUrl}
      posEventId={posEventId}
      swiperRef={swiperRef}
    />
  );
}

type MainFilterBarDivProps = { children: ReactNode };
export const MainFilterBarDiv = ({ children }: MainFilterBarDivProps) => {
  return <div className={styles.mainFilterBarDiv}>{children}</div>;
};
