import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  Feature,
  PricingClient,
  SellThroughRateHistory,
} from 'src/WebApiController';

import { useUserHasFeature } from '../useUserHasFeature';

export const useSellerEventSellThroughRateTargets = (
  viagogoEventId?: number | null,
  viagogoMappingId?: string | null
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  const queryClient = useQueryClient();
  const queryKey = [
    'getEventSellThroughRateTargets',
    activeAccountWebClientConfig.activeAccountId,
    viagogoEventId,
    viagogoMappingId,
  ];

  const shouldQuery =
    hasAutoPricingFeature &&
    activeAccountWebClientConfig.activeAccountId != null &&
    (viagogoEventId != null || viagogoMappingId != null);

  const sellThroughRateTargetsQuery = useQuery({
    queryKey,
    queryFn: () => {
      if (!shouldQuery) return null;

      return new PricingClient(
        activeAccountWebClientConfig
      ).getEventSellThroughRateTargets(viagogoEventId, viagogoMappingId);
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.getEventSellThroughRateTargets', error, {
          viagogoEventId,
          viagogoMappingId,
        });
      },
    },
  });

  const sellThroughRateTargetsMutation = useMutation({
    mutationFn: async ({ input }: { input: SellThroughRateHistory[] }) => {
      return await new PricingClient(
        activeAccountWebClientConfig
      ).setEventSellThroughRateTargets(viagogoEventId, viagogoMappingId, input);
    },
    // optimistic ui
    onMutate: ({ input }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, input);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { input }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('PricingClient.setEventSellThroughRateTargets', err, {
        trackErrorData: { input },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    sellThroughRateTargetsQuery,
    sellThroughRateTargetsMutation,
  };
};
