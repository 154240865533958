import { isEqual } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  getMarketplaceSaleInputFromListing,
  getSaleDetailsRelativeUrl,
} from 'src/utils/saleUtils';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  ApiException,
  Event,
  ListingDetails,
  MarketplaceSaleInput,
  SaleClient,
} from 'src/WebApiController';

import { CancellableFormFooter } from '../common';
import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from '../common/EventEntityHeader';
import { modalDetails } from '../common/Modals.css';
import { Summary } from '../common/Summary';
import { ModalProps } from '../Modal';
import {
  ModalBody,
  ModalBodyHeaderContainer,
  ModalFooter,
} from '../Modal/Modal.styled';
import { MarketplaceSaleBody } from './MarketplaceSaleBody';

type MarketplaceSaleProps = {
  cancelTo?: ModalProps;
};

export const MarketplaceSale = ({ cancelTo }: MarketplaceSaleProps) => {
  const { posEntity, event } =
    useActivePosEntityContext<EntityWithRealTickets>();
  const listing =
    posEntity?.entityType === ActionOutboxEntityType.Listing
      ? (posEntity as ListingDetails)
      : undefined;

  const methods = useForm<MarketplaceSaleInput>({
    defaultValues:
      listing && event
        ? getMarketplaceSaleInputFromListing(listing, event)
        : undefined,
  });

  return (
    <FormProvider {...methods}>
      <SaleContent
        {...methods}
        cancelTo={cancelTo}
        listing={listing}
        event={event}
      />
    </FormProvider>
  );
};

export const SaleContent = ({
  listing,
  event,
  cancelTo,
  formState,
  handleSubmit,
  watch,
  getValues,
  setError,
}: Omit<
  ComponentProps<typeof FormProvider<MarketplaceSaleInput, unknown>>,
  'children'
> &
  MarketplaceSaleProps & {
    listing?: ListingDetails;
    event?: Event | null;
  }) => {
  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { setModal, closeModal } = useContext(ModalContext);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = useCallback(
    async (saleFormValue: MarketplaceSaleInput) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const client = new SaleClient(activeAccountWebClientConfig);

          const r = await client.createMarketplaceSale(saleFormValue);

          if (isSuccess(r)) {
            if (r.entityId) {
              navigate(getSaleDetailsRelativeUrl(r.entityId));
            }
            if (cancelTo) {
              setModal(cancelTo);
            } else {
              closeModal(true);
            }
          } else {
            showErrorDialog(
              'SaleClient.createMarketplaceSale',
              {
                message: r.message ?? genericError,
                status: r.status!,
              } as ApiException,
              {
                trackErrorData: saleFormValue,
              }
            );
          }
        },
        (error) => {
          showErrorDialog('SaleClient.createMarketplaceSale', error, {
            trackErrorData: saleFormValue,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      cancelTo,
      closeModal,
      genericError,
      navigate,
      setModal,
      showErrorDialog,
    ]
  );

  const requiredMsg = useContent(ContentId.Required);
  const inhandDateMustBeforeEventDate = useContent(
    ContentId.InHandDateMustBeBeforeEventDate
  );

  const onSubmitHandler = useCallback(() => {
    const formValues = getValues();
    if (isEqual(formValues, formState.defaultValues)) return;

    if (!formValues.saleDate) {
      setError('saleDate', { message: requiredMsg }, { shouldFocus: true });
    }

    if (!formValues.totalNetProceeds?.value) {
      formValues.totalNetProceeds = posChangedField(0);
    }

    if (!formValues.inHandDate?.value) {
      setError('inHandDate', { message: requiredMsg }, { shouldFocus: true });
    } else if (
      new Date(formValues.inHandDate.value) >= new Date(event!.dates.start!)
    ) {
      setError(
        'inHandDate',
        { message: inhandDateMustBeforeEventDate },
        { shouldFocus: true }
      );
    }
    if ((formValues.quantitySold?.value ?? 0) <= 0) {
      setError('quantitySold', { message: requiredMsg }, { shouldFocus: true });
    }

    if (listing?.tickets?.find((t) => !!t.seat)) {
      // If the listing tickets has seats - so better the sale
      if (!formValues.seatFrom?.value || !formValues.seatTo?.value) {
        setError('seatFrom', { message: requiredMsg }, { shouldFocus: true });
      }
    }

    handleSubmit(onSubmit)();
  }, [
    getValues,
    formState.defaultValues,
    event,
    listing?.tickets,
    handleSubmit,
    onSubmit,
    setError,
    requiredMsg,
    inhandDateMustBeforeEventDate,
  ]);

  const formHasChanges = useMemo(() => {
    const formValues = watch();
    return !isEqual(formValues, formState.defaultValues);
  }, [formState.defaultValues, watch]);

  return (
    <>
      <CancellableFormHeader
        cancelTo={cancelTo}
        disabled={isLoading || formState.isSubmitting}
        showDialogOnCancel={formHasChanges}
      >
        <ConnectedEventEntityHeader
          title={
            <>
              <Content id={ContentId.AddMarketplaceSale} />
            </>
          }
        />
      </CancellableFormHeader>

      <ModalBody>
        <div className={modalDetails}>
          {event && listing && (
            <ModalBodyHeaderContainer>
              <Summary event={event} posEntity={listing} />
            </ModalBodyHeaderContainer>
          )}
          {!listing ? (
            <PosSpinner />
          ) : (
            <MarketplaceSaleBody disabled={isLoading} listing={listing} />
          )}
        </div>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          cancelTo={cancelTo}
          disabled={isLoading}
          showDialogOnCancel={formHasChanges}
        >
          <OkButton
            onClick={onSubmitHandler}
            disabled={isLoading || !formHasChanges}
            textContentId={ContentId.Save}
          />
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
