import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { isSuccess } from 'src/utils/errorUtils';
import { ConfigTypeOverride, ListingGroupClient } from 'src/WebApiController';

interface UseautoCompSettingsPreviewProps {
  viagogoEventId: number | null;
  configTypeOverride: ConfigTypeOverride;
  combineCornersAndEnds: boolean;
  enabled: boolean;
}

export const useAutoCompSettingsPreview = ({
  viagogoEventId,
  configTypeOverride,
  combineCornersAndEnds,
  enabled,
}: UseautoCompSettingsPreviewProps) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const shouldQuery = Boolean(
    configTypeOverride &&
      viagogoEventId &&
      combineCornersAndEnds != null &&
      enabled
  );

  return useQuery({
    queryKey: [
      'ListingGroupClient.autoCompSettingsPreview',
      viagogoEventId,
      configTypeOverride,
      combineCornersAndEnds,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const result = await new ListingGroupClient(
        activeAccountWebClientConfig
      ).autoCompSettingsPreview({
        viagogoEventId: viagogoEventId!,
        configTypeOverride: configTypeOverride!,
        combineCornersAndEnds: combineCornersAndEnds!,
      });

      if (isSuccess(result)) {
        return result.data;
      } else {
        showErrorDialog(
          'ListingGroupClient.autoCompSettingsPreview',
          { message: result.message, status: result.status },
          {
            trackErrorData: {
              viagogoEventId,
              configTypeOverride,
            },
          }
        );
      }
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('ListingGroupClient.autoCompSettingsPreview', error, {
          trackErrorData: {
            viagogoEventId,
            configTypeOverride,
          },
        });
      },
    },
  });
};
