import { useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { TooltipPopover } from 'src/core/ui';
import { WarningOutlineIcon } from 'src/svgs/Viagogo';
import {
  AUTO_PRICING_RESULT_TYPES_OPTIONS_TO_CID,
  AUTO_PRICING_RESULT_TYPES_OPTIONS_TO_COLOR,
} from 'src/utils/constants/contentIdMaps';
import { AutoPricingResultType } from 'src/WebApiController';

import * as styles from './AutoPriceResultDisplay.css';

export const AutoPriceResultDisplay = ({
  resultType = AutoPricingResultType.None,
  useWarningIcon,
}: {
  resultType?: AutoPricingResultType | null;
  useWarningIcon?: boolean;
}) => {
  const autoPricingResultStateContent =
    AUTO_PRICING_RESULT_TYPES_OPTIONS_TO_CID[
      resultType ?? AutoPricingResultType.None
    ];

  const contentText = useContent(autoPricingResultStateContent);

  const autoPricingResultColor =
    AUTO_PRICING_RESULT_TYPES_OPTIONS_TO_COLOR[
      resultType ?? AutoPricingResultType.None
    ];

  return (
    <TooltipPopover
      variant="link"
      triggerContent={
        useWarningIcon ? (
          <WarningOutlineIcon
            fill={autoPricingResultColor}
            withHoverEffect
            size={vars.iconSize.s}
          />
        ) : (
          <div
            className={styles.pricingResultTypeIndicator}
            style={
              autoPricingResultColor
                ? { borderColor: autoPricingResultColor }
                : { display: 'none' }
            }
          />
        )
      }
      contentVariant="dark"
      title={contentText}
    >
      {contentText}
    </TooltipPopover>
  );
};
