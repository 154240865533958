import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';

export const LaunchUpdateMarketplaceSeating = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const hasPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_UpdateListingInfo,
    Permission.Inventory_UpdateListingInfoOnIsPricerOf,
    Permission.Inventory_UpdateListingInfoOnPurchasedBy
  );

  if (!hasPermission) {
    return null;
  }

  return (
    <PosDropdownItem
      key="MarketplaceSeating"
      onClick={onClick}
      disabled={disabled}
    >
      <Stack alignItems="center" gap="m" width="full">
        <Content id={ContentId.UpdateMarketplaceSeating} />
      </Stack>
    </PosDropdownItem>
  );
};
