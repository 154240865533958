import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';

import { connector } from './StackedFilter.css';

export const StackedFilter = ({
  FilterComponents,
}: {
  FilterComponents: React.ReactElement[];
}) => {
  return (
    <Stack direction="column" gap="m" width="full" id="stackedFilter">
      {FilterComponents.map((FilterComponent, index) => (
        <Stack key={`stack-filter-${index}`}>
          {index > 0 && (
            <div
              key={index}
              className={connector}
              style={{ marginLeft: index > 1 ? vars.spacing['lg'] : 0 }}
            />
          )}
          {FilterComponent}
        </Stack>
      ))}
    </Stack>
  );
};
