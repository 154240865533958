import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import clsx from 'clsx';
import endOfDay from 'date-fns/endOfDay';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DateRange as ReactDateRange,
  DateRangePickerProps,
} from 'react-date-range';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { vars } from 'src/core/themes';
import { DividerLine, Popover } from 'src/core/ui';
import { CalendarIcon, CrossIcon, ExpandIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  formatDate,
  tryGetDateRangePresetSuggestionText,
  tryGetDateRangeRelativePresetSuggestionText,
  validateDate,
} from 'src/utils/dateTimeUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { DateTimeRange, DateTimeRangeWithRelative } from 'src/WebApiController';

import {
  DateRange,
  DateRangePresets,
  DateRangePresetsProp,
} from './DateRangePresets/DateRangePresets';
import { DateRangeRelativePresets } from './DateRangePresets/DateRangeRelativePresets';
import * as styles from './DateRangeSelector.css';
import {
  CustomDateRangeButton,
  DateErrorContainer,
  DateRangePickerContainer,
  DateRangeTrigger,
  DateRangeTriggerContent,
  IconContainer,
} from './DateRangeSelector.styled';

export type DateRangeSelectorProps = Omit<
  DateRangePresetsProp,
  'onClick' | 'selectedDateRange'
> & {
  value?: DateTimeRangeWithRelative | DateTimeRange | null;
  defaultValue?: DateTimeRangeWithRelative | DateTimeRange | null;
  onChange?: (value: DateTimeRangeWithRelative | null) => void;
  onBlur?: (value: DateTimeRangeWithRelative | null) => void;
  useRelativePresets?: boolean;
  /**
   * If true, the site's timezone will be applied to the date being displayed.
   * the underlying date value (value, data, onDateChange) will not be changed.
   */
  useSiteTimeZone?: boolean;
  isEndDateFixed?: boolean; // New flag for controlling end date behavior
  displayTextStyle?: React.CSSProperties;
} & Omit<DateRangePickerProps, 'range' | 'onChange'>;

export function DateRangeSelector({
  value,
  startDatePlaceholder,
  endDatePlaceholder,
  className,
  dateDisplayFormat = 'MM/dd/yyyy',
  retainEndDateOnFirstSelection = true,
  rangeColors = [vars.color.backgroundHighlight],
  locale = getLocaleFromLanguageOrCurrent(),
  onChange,
  onBlur,
  disabled,
  useRelativePresets,
  useSiteTimeZone = true,
  onPreviewChange,
  isEndDateFixed = false,
  displayTextStyle,
  ...presetProps
}: DateRangeSelectorProps) {
  const { toZonedTime, fromZonedTime } = useSiteTimezoneContext();
  const currentLocale = getLocaleFromLanguageOrCurrent();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dateValue, setDateValue] = useState({
    start: formatDate(value?.start || '', currentLocale.code),
    end: formatDate(value?.end || '', currentLocale.code),
  });
  const [dateErrors, setDateErrors] = useState<string[]>([]);

  const defaultDateRangeDisplayText = useContent(
    ContentId.DateRangeDisplayText
  );
  const defaultFromlabel = useContent(ContentId.FromDate);
  const defaultToLabel = useContent(ContentId.ToDate);
  const endDateBeforeStartDate = useContent(ContentId.EndDateBeforeStartDate);
  const invalidEndDate = useContent(ContentId.InvalidEndDate);
  const invalidStartDate = useContent(ContentId.InvalidStartDate);
  const dateErrorConfig = useMemo(
    () => ({
      endDateBeforeStartDate,
      invalidEndDate,
      invalidStartDate,
    }),
    [endDateBeforeStartDate, invalidEndDate, invalidStartDate]
  );

  const getFormattedDate = useCallback(
    (time: string | null | undefined) => {
      return useSiteTimeZone
        ? toZonedTime(time ? new Date(time) : new Date())
        : time
        ? new Date(time)
        : new Date();
    },
    [useSiteTimeZone, toZonedTime]
  );

  const onDateRangePickerChange = useCallback(
    (from: Date | undefined, to: Date | undefined) => {
      const newValue: DateTimeRangeWithRelative = value
        ? {
            relativeEndSecs: null,
            relativeStartSecs: null,
            roundingMode: null,
            ...value,
          }
        : {
            start: null,
            end: null,
            relativeStartSecs: null,
            relativeEndSecs: null,
            roundingMode: null,
          };

      if (from == null) {
        newValue.start = null;
      } else {
        newValue.start = from.toISOString();
      }
      if (to == null) {
        newValue.end = null;
      } else {
        newValue.end = to.toISOString();
      }
      newValue.relativeStartSecs = null;
      newValue.relativeEndSecs = null;

      onChange?.(newValue);
      onBlur?.(newValue);
      setDateValue({
        start: formatDate(newValue.start || '', currentLocale.code),
        end: formatDate(newValue.end || '', currentLocale.code),
      });
    },
    [value, onChange, onBlur, currentLocale.code]
  );

  const onClear = useCallback(() => {
    onDateRangePickerChange(undefined, undefined);
  }, [onDateRangePickerChange]);

  const onSuggestionClick = useCallback(
    (range: DateRange) => {
      onDateRangePickerChange(range.from, range.to);
      setIsOpen(false);
    },
    [onDateRangePickerChange]
  );

  const onSuggestionRelativeClick = useCallback(
    (range: DateTimeRangeWithRelative) => {
      onChange?.(range);
      onBlur?.(range);
      setDateValue({
        start: formatDate('', currentLocale.code),
        end: formatDate('', currentLocale.code),
      });
      setIsOpen(false);
    },
    [onChange, onBlur, currentLocale.code]
  );

  useEffect(() => {
    const dateErrors = validateDate(dateValue, currentLocale, dateErrorConfig);
    setDateErrors(dateErrors);
  }, [dateValue, dateErrorConfig, currentLocale]);

  const getDisplayText = useCallback(() => {
    const suggestionText = tryGetDateRangePresetSuggestionText(
      value,
      useSiteTimeZone ? toZonedTime : undefined,
      displayTextStyle
    );

    if (suggestionText) {
      return suggestionText;
    }

    const suggestionTextRelative = tryGetDateRangeRelativePresetSuggestionText(
      value as DateTimeRangeWithRelative | null | undefined
    );

    if (suggestionTextRelative) {
      return suggestionTextRelative;
    }

    const { end, start } = dateValue;
    if (value && start) {
      if (useSiteTimeZone && value.start && value.end) {
        const startZoned = formatDate(
          toZonedTime(new Date(value.start)).toISOString(),
          currentLocale.code
        );
        const endZoned = formatDate(
          toZonedTime(new Date(value.end)).toISOString(),
          currentLocale.code
        );

        return `${startZoned} - ${endZoned}`;
      }
      return `${start} - ${end}`;
    }

    return defaultDateRangeDisplayText;
  }, [
    currentLocale.code,
    dateValue,
    defaultDateRangeDisplayText,
    displayTextStyle,
    toZonedTime,
    useSiteTimeZone,
    value,
  ]);

  const highlightDropBox = !(
    value?.start == null &&
    value?.end == null &&
    (value as DateTimeRangeWithRelative)?.relativeEndSecs == null &&
    (value as DateTimeRangeWithRelative)?.relativeStartSecs == null
  );

  return (
    <Popover.Root
      open={isOpen}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
        if (!isOpen) {
          setShowDatePicker(false);
        }
      }}
    >
      <Popover.Trigger asChild>
        <DateRangeTrigger isActive={highlightDropBox} disabled={disabled}>
          <DateRangeTriggerContent className={styles.dateRangeTriggerContent}>
            <div>{getDisplayText()}</div>
            <div className={styles.dateRangeTriggerContentRight}>
              {!disabled &&
                (!!dateValue.start ||
                  (value as DateTimeRangeWithRelative)?.relativeEndSecs !=
                    null ||
                  (value as DateTimeRangeWithRelative)?.relativeStartSecs !=
                    null) &&
                highlightDropBox && (
                  <IconContainer>
                    <CrossIcon size={vars.iconSize.xs} onClick={onClear} />
                  </IconContainer>
                )}
              <ExpandIcon size={vars.iconSize.xs} />
            </div>
          </DateRangeTriggerContent>
        </DateRangeTrigger>
      </Popover.Trigger>
      <Popover.Content align="start" className={styles.dateRangePopoverContent}>
        <DateRangePickerContainer>
          {useRelativePresets ? (
            <DateRangeRelativePresets
              {...presetProps}
              disabled={disabled}
              onClick={onSuggestionRelativeClick}
              selectedDateRange={value as DateTimeRangeWithRelative}
            />
          ) : (
            <DateRangePresets
              {...presetProps}
              disabled={disabled}
              onClick={onSuggestionClick}
              selectedDateRange={value}
              useSiteTimeZone={useSiteTimeZone}
            />
          )}
          <div style={{ paddingTop: vars.spacing['sm'] }}>
            <DividerLine />
          </div>
          <CustomDateRangeButton
            className={styles.customDateRangeButton}
            disabled={disabled}
            onClick={() => setShowDatePicker((val) => !val)}
          >
            <div className={styles.optionText}>
              <Content id={ContentId.CustomDateRange} />
            </div>{' '}
            <CalendarIcon size={vars.iconSize.m} withHoverEffect />
          </CustomDateRangeButton>
          {showDatePicker && (
            <>
              <div className={styles.divider} />
              <DateErrorContainer>
                {dateErrors.map((err) => (
                  <li key={err}>{err}</li>
                ))}
              </DateErrorContainer>
              <ReactDateRange
                onPreviewChange={onPreviewChange}
                editableDateInputs={true}
                className={clsx(className, styles.dateRangePicker)}
                dateDisplayFormat={dateDisplayFormat}
                retainEndDateOnFirstSelection={retainEndDateOnFirstSelection}
                maxDate={
                  isEndDateFixed ? getFormattedDate(value?.end) : undefined
                }
                ranges={[
                  {
                    startDate: getFormattedDate(value?.start),
                    endDate: getFormattedDate(value?.end),
                    key: 'range',
                  },
                ]}
                startDatePlaceholder={startDatePlaceholder ?? defaultFromlabel}
                endDatePlaceholder={endDatePlaceholder ?? defaultToLabel}
                onChange={(range) => {
                  const startDate =
                    range.range.startDate && useSiteTimeZone
                      ? fromZonedTime(range.range.startDate)
                      : range.range.startDate;
                  const rangeSelectorEndDate = range.range.endDate
                    ? useSiteTimeZone
                      ? fromZonedTime(endOfDay(range.range.endDate))
                      : endOfDay(range.range.endDate)
                    : undefined;

                  const endDate = isEndDateFixed
                    ? value?.end
                      ? new Date(value.end)
                      : new Date()
                    : rangeSelectorEndDate;

                  onDateRangePickerChange(startDate, endDate);
                }}
                rangeColors={rangeColors}
                locale={locale}
              />
            </>
          )}
        </DateRangePickerContainer>
      </Popover.Content>
    </Popover.Root>
  );
}
