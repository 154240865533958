import { Cell, Header, Row, Table as ReactTable } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { ReactNode, useCallback } from 'react';

export const useColumnVirtualizer = <T,>(
  table: ReactTable<T>,
  scrollRef: React.MutableRefObject<HTMLElement | null>,
  renderColumnHeader: (header: Header<T, unknown>) => ReactNode,
  renderCell: (cell: Cell<T, unknown>) => ReactNode
) => {
  const visibleColumns = table.getVisibleFlatColumns();

  //we are using a slightly different virtualization strategy for columns (compared to virtual rows) in order to support dynamic row heights
  const columnVirtualizer = useVirtualizer({
    count: visibleColumns.length,
    estimateSize: (index) => visibleColumns[index].getSize(), //estimate width of each column for accurate scrollbar dragging
    getScrollElement: () => scrollRef.current,
    horizontal: true,
    overscan: 3, //how many columns to render on each side off screen each way (adjust this for performance)
  });

  const virtualColumns = columnVirtualizer.getVirtualItems();

  //different virtualization strategy for columns - instead of absolute and translateY, we add empty columns to the left and right
  let virtualPaddingLeft: number | undefined;
  let virtualPaddingRight: number | undefined;

  if (columnVirtualizer && virtualColumns?.length) {
    virtualPaddingLeft = virtualColumns[0]?.start ?? 0;
    virtualPaddingRight =
      columnVirtualizer.getTotalSize() -
      (virtualColumns[virtualColumns.length - 1]?.end ?? 0);
  }

  const renderHeaderRow = useCallback(() => {
    const headerGroups = table.getHeaderGroups();

    return (
      <>
        {headerGroups.map((headerGroup) => (
          <>
            {virtualPaddingLeft ? (
              //fake empty column to the left for virtualization scroll padding
              <th style={{ display: 'flex', width: virtualPaddingLeft }} />
            ) : null}
            {virtualColumns.map((vc) => {
              const header = headerGroup.headers[vc.index];
              return header.column.getIsPinned()
                ? null
                : renderColumnHeader(header); // we alway render the pinned columns as below
            })}
            {virtualPaddingRight ? (
              //fake empty column to the right for virtualization scroll padding
              <th style={{ display: 'flex', width: virtualPaddingRight }} />
            ) : null}
            {headerGroup.headers
              .filter((h) => h.column.getIsPinned())
              .map((h) => renderColumnHeader(h))}
          </>
        ))}
      </>
    );
  }, [
    renderColumnHeader,
    table,
    virtualColumns,
    virtualPaddingLeft,
    virtualPaddingRight,
  ]);

  const renderRowContent = useCallback(
    (row: Row<T>) => {
      const visibleCells = row.getVisibleCells();
      return (
        <>
          {virtualPaddingLeft ? (
            //fake empty column to the left for virtualization scroll padding
            <th style={{ display: 'flex', width: virtualPaddingLeft }} />
          ) : null}
          {virtualColumns.map((vc) => {
            const cell = visibleCells[vc.index];
            return cell.column.getIsPinned() ? null : renderCell(cell); // we alway render the pinned columns as below
          })}
          {virtualPaddingRight ? (
            //fake empty column to the right for virtualization scroll padding
            <th style={{ display: 'flex', width: virtualPaddingRight }} />
          ) : null}
          {visibleCells
            .filter((c) => c.column.getIsPinned())
            .map((c) => renderCell(c))}
        </>
      );
    },
    [renderCell, virtualColumns, virtualPaddingLeft, virtualPaddingRight]
  );

  return { renderHeaderRow, renderRowContent };
};
