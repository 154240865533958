import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import {
  AdGroupRow,
  ADGROUPS_TABLE_COLUMNS_CONFIG,
} from 'src/tables/AdGroupsTable/configs/AdGroupsTableColumnConfig';
import { AdGroupsTableColumnId } from 'src/utils/columns/adGroups/adGroupsColumnUtils.types';

export const useGetAdGroupsTableColumns = () => {
  return useMemo(() => {
    // TODO: Filter out reporting columns until we enabled this on AdCampaign side
    const columns = ADGROUPS_TABLE_COLUMNS_CONFIG.filter(
      (item) =>
        ![
          AdGroupsTableColumnId.AdSpend,
          AdGroupsTableColumnId.SoldProceeds,
          AdGroupsTableColumnId.SoldQty,
        ].includes(item.id as AdGroupsTableColumnId)
    ).map((item) => item.id as AdGroupsTableColumnId);

    return Array.from(columns).reduce<ColumnDef<AdGroupRow | null>[]>(
      (acc, columnId) => {
        const columnConfig = Object.values(ADGROUPS_TABLE_COLUMNS_CONFIG).find(
          (column) => column.id === columnId
        );
        if (columnConfig) {
          acc.push(columnConfig);
        }
        return acc;
      },
      []
    );
  }, []);
};
