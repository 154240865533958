import { useEffect, useState } from 'react';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { MainNavTopBarEmpty } from 'src/navigations/MainNav/MainNavTopBar';
import { REACT_APP_STUBHUB_UK_URL } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import * as styles from './RedirectToStubHubUk.css';

export const RedirectToStubHubUk = () => {
  const [curSecond, setCurSecond] = useState(10); // 10 seconds
  useEffect(() => {
    if (curSecond <= 0) {
      window.location.href = REACT_APP_STUBHUB_UK_URL!;
    }
    const timer = setTimeout(() => {
      setCurSecond(curSecond - 1);
    }, 1000);

    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  return (
    <>
      <MainNavTopBarEmpty />
      <div className={styles.redirectToStubHubUkContainer}>
        <div className={styles.redirectionTitle}>
          <Content id={ContentId.DirectToStubHubInternationalTitle} />
        </div>
        <div>
          <Content id={ContentId.DirectToStubHubInternationalMessage} />
        </div>
        <div className={styles.redirectToStubHubUkButton}>
          <Button
            onClick={() => (window.location.href = REACT_APP_STUBHUB_UK_URL!)}
          >
            <FormatContent
              id={FormatContentId.TakeMeThereInSeconds}
              params={curSecond.toString()}
            />
          </Button>
        </div>
      </div>
    </>
  );
};
