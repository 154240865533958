import { useQuery } from '@tanstack/react-query';
import { PricingClient } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { ErrorTypes, useErrorBoundaryContext } from '../ErrorBoundaryContext';

export const useGetEventMapConfigOverrides = (
  viagogoEventId?: number | null,
  venueConfigId?: number | null,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled &&
    Boolean(
      activeAccountWebClientConfig.activeAccountId &&
        viagogoEventId &&
        venueConfigId
    );
  const seatScoreOverridesQuery = useQuery({
    queryKey: [
      'getEventSeatMapScoreOverrides',
      activeAccountWebClientConfig.activeAccountId,
      viagogoEventId,
      venueConfigId,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }

      return new PricingClient(
        activeAccountWebClientConfig
      ).getEventSeatMapScoreOverrides(viagogoEventId!, venueConfigId!);
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.getEventSeatMapScoreOverrides', error, {
          viagogoEventId,
          venueConfigId,
        });
      },
    },
  });

  const shouldQuery2 =
    !disabled && activeAccountWebClientConfig.activeAccountId != null;
  const sellerAccountScoreOverridesQuery = useQuery({
    queryKey: [
      'GetEventSeatMapScoreOverridesForSellerAccount',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () => {
      if (!shouldQuery2) {
        return null;
      }

      return new PricingClient(
        activeAccountWebClientConfig
      ).getEventSeatMapScoreOverridesForSellerAccount();
    },

    enabled: shouldQuery2,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'PricingClient.GetEventSeatMapScoreOverridesForSellerAccount',
          error
        );
      },
    },
  });

  return { seatScoreOverridesQuery, sellerAccountScoreOverridesQuery };
};
