import { useCallback, useContext } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { ImageMagnifier } from 'src/core/POS/ImageMagnifier';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { Detail, DetailGroup, DetailSection } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleDetails, UserDocumentLinks } from 'src/WebApiController';

import { SectionWrapper } from '../common/Detail/Detail.styled';
import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';
import * as styles from './TransferTickets.css';
import { TransferTicketmasterBarcodesBody } from './views/TransferTicketmasterBarcodes';

export const ViewTransferTickets = ({ cancelTo }: { cancelTo: ModalProps }) => {
  const { setModal } = useContext(ModalContext);
  const { event, posEntity: sale } = useActivePosEntityContext<SaleDetails>();
  const goBack = useCallback(() => {
    setModal(cancelTo);
  }, [setModal, cancelTo]);

  return (
    <>
      <CancellableFormHeader cancelTo={cancelTo}>
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.ViewTransferTicket} />}
        />
      </CancellableFormHeader>
      <ModalBody>
        <ModalBodyHeaderContainer>
          <Summary event={event!} posEntity={sale!} />
        </ModalBodyHeaderContainer>
        <ModalBodyDataContainer>
          <TrasnferProofBody saleDetails={sale!} />
        </ModalBodyDataContainer>
      </ModalBody>
      <ModalFooter>
        <Button onClick={goBack}>
          <Content id={ContentId.Back} />
        </Button>
      </ModalFooter>
    </>
  );
};

// Pulled into a separate component since there were some weird issues with error boundary otherwise
const TrasnferProofBody = ({ saleDetails }: { saleDetails: SaleDetails }) => {
  const {
    transDest,
    transSrc,
    transferProofDocs,
    transferUrls,
    transferProofId,
    tickets,
  } = saleDetails;

  const transferBarcodes = tickets?.map((t) => t.barcode).filter((b) => b);

  const yourTickets = useContent(ContentId.YourTickets);
  const app = useContent(ContentId.App);
  const transferFrom = useContent(ContentId.TransferFrom);

  const yourBuyer = useContent(ContentId.YourBuyer);
  const transferTo = useContent(ContentId.TransferTo);
  const contact = useContent(ContentId.Contact);

  return (
    <>
      <SectionWrapper>
        <DetailSection name={yourTickets}>
          {transSrc?.map((tts) => (
            <DetailGroup key={tts.email}>
              <Detail
                label={app}
                detail={<div className={styles.transferToInfo}>{tts.app}</div>}
              />
              <Detail
                label={transferFrom}
                detail={
                  <div>
                    <div className={styles.transferToInfo}>{tts.name}</div>
                    <div className={styles.transferToInfo}>{tts.email}</div>
                  </div>
                }
              />
            </DetailGroup>
          ))}
        </DetailSection>
        <DetailSection name={yourBuyer}>
          {transDest ? (
            <DetailGroup>
              <Detail
                label={transferTo}
                detail={
                  <div className={styles.transferToInfo}>{transDest.name}</div>
                }
              />
              <Detail
                label={contact}
                detail={
                  <div className={styles.transferToInfo}>{transDest.email}</div>
                }
              />
            </DetailGroup>
          ) : (
            <Detail
              label={transferTo}
              detail={<Content id={ContentId.UnableToRetrieveBuyersInfo} />}
            />
          )}
        </DetailSection>
      </SectionWrapper>
      {transferProofDocs?.length ? (
        <SectionWrapper>
          <Stack direction="column" gap="xl">
            <Content id={ContentId.ScreenshotsOfTransfer} />
            <ImageProofContent transferProofDocs={transferProofDocs} />{' '}
          </Stack>
        </SectionWrapper>
      ) : transferUrls?.length ? (
        <SectionWrapper>
          <Stack direction="column" gap="xl">
            <Content id={ContentId.UrlsToAcceptTickets} />
            <UrlProofContent transferUrls={transferUrls} />
          </Stack>
        </SectionWrapper>
      ) : transferProofId ? (
        <SectionWrapper>
          <Stack direction="column" gap="xl">
            <Content id={ContentId.TransferProofId} />
            <TransferIdProofContent transferProofId={transferProofId} />
          </Stack>
        </SectionWrapper>
      ) : transferBarcodes?.length ? (
        <SectionWrapper>
          <TransferTicketmasterBarcodesBody
            instructionsContentId={
              ContentId.TicketmasterBarcodeTransferInstruction
            }
            viewOnly={true}
          />
        </SectionWrapper>
      ) : null}
    </>
  );
};

const ImageProofContent = ({
  transferProofDocs,
}: {
  transferProofDocs: UserDocumentLinks[];
}) => {
  return (
    <>
      {transferProofDocs.map((d) => (
        <p key={d.documentId}>
          <ImageMagnifier imageSrc={d.pageUri} magnifierSize={100} />
        </p>
      ))}
    </>
  );
};

const UrlProofContent = ({ transferUrls }: { transferUrls: string[] }) => {
  return (
    <>
      {transferUrls.map((url, i) => (
        <p key={i}>
          <PosTextField value={url} disabled />
        </p>
      ))}
    </>
  );
};

const TransferIdProofContent = ({
  transferProofId,
}: {
  transferProofId: string;
}) => {
  return <PosTextField value={transferProofId} disabled />;
};
