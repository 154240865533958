import { Content } from 'src/contexts/ContentContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

import { AutoPoRestictionForm } from './AutoPoRestictionForm';
import { PurchaseOrderGeneralSettingForm } from './PurchaseOrderGeneralSettingForm';
import * as styles from './PurchaseOrderSetting.css';

export function PurchaseOrderSettingsContent() {
  const hasAutoPoFeature = useUserHasFeature(Feature.AutoPO);
  const hasAutoPoRestrictionSettingsFeature = useUserHasFeature(
    Feature.AutoPoRestrictionSettings
  );

  return (
    <div className={styles.root}>
      <div className={styles.center}>
        <h1 className={styles.pageName}>
          <Content id={ContentId.Purchases} />
        </h1>
        <PurchaseOrderGeneralSettingForm />
        {hasAutoPoFeature && hasAutoPoRestrictionSettingsFeature && (
          <AutoPoRestictionForm />
        )}
      </div>
    </div>
  );
}
