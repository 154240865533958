import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  AutoPoClient,
  AutoPoInboundEmailPartialPurchaseOrderQuery,
  AutoPoPartialPurchaseOrderInboundEmail,
} from 'src/WebApiController';

const NUM_TAKE = 100;

export function usePartialPurchaseOrderInboundEmails({
  query,
}: {
  query: AutoPoInboundEmailPartialPurchaseOrderQuery;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  const queryResult = useInfiniteQuery({
    queryKey: [
      'AutoPoClient.getPartialPurchaseOrderInboundEmails',
      activeAccountWebClientConfig.activeAccountId,
      query,
    ],
    async queryFn({ pageParam: skip = 0 }) {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      const results = await new AutoPoClient(
        activeAccountWebClientConfig
      ).getPartialPurchaseOrderInboundEmails(query, NUM_TAKE, skip);
      if (results.length === 0) {
        return;
      }
      return {
        results,
        skip,
        totalCount: results[0].totalCount,
      };
    },
    getNextPageParam(lastPage) {
      return lastPage && lastPage.skip < lastPage.totalCount
        ? lastPage.skip + NUM_TAKE
        : undefined;
    },
    meta: {
      onError(err: ErrorTypes) {
        trackError('AutoPoClient.getPartialPurchaseOrderInboundEmails', err, {
          query,
        });
      },
    },
    initialPageParam: 0,
    staleTime: 0,
    gcTime: 0,
    enabled: activeAccountWebClientConfig.activeAccountId != null,
  });
  const flattenedData = useMemo(() => {
    const flattenedData: AutoPoPartialPurchaseOrderInboundEmail[] = [];
    if (queryResult.data) {
      for (const page of queryResult.data.pages) {
        if (page) {
          for (const email of page?.results ?? []) {
            flattenedData.push(email);
          }
        }
      }
    }
    return flattenedData;
  }, [queryResult.data]);
  return {
    flattenedData,
    ...queryResult,
  };
}
