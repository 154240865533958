import { Venue } from 'src/WebApiController';

export const getFormattedLocation = (venue: Venue): string => {
  const { city, state: stateProvince, country } = venue;
  return `${city ? `${city}, ` : ''}${
    stateProvince ? `${stateProvince}, ` : ''
  }${country?.code || ''}`;
};

/**
 * Convert size in bytes (number) to human readable string with units.
 * E.g. 1024 -> '1 KB'
 * @param numBytes number of bytes
 * @param precision precision in the formatted string
 * @param addSpace whether to add space between number and unit in the output string
 * @returns
 */
export const getFormattedBytesSize = (
  numBytes: number,
  precision = 3,
  addSpace = true
) => {
  const UNITS = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  if (Math.abs(numBytes) < 1)
    return numBytes + (addSpace ? ' ' : '') + UNITS[0];

  const exponent = Math.min(
    Math.floor(Math.log10(numBytes < 0 ? -numBytes : numBytes) / 3),
    UNITS.length - 1
  );

  const n = Number(
    ((numBytes < 0 ? -numBytes : numBytes) / 1000 ** exponent).toPrecision(
      precision
    )
  );

  return (
    (numBytes < 0 ? '-' : '') + n + (addSpace ? ' ' : '') + UNITS[exponent]
  );
};

/**
 * Decodes base64 encoded string and returns a byte array.
 * @param base64String
 * @returns
 */
export function decodeBase64(base64String: string): Uint8Array {
  const byteCharacters = atob(base64String);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return byteArray;
}
