import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Button } from 'src/core/ui';
import { ZoneSectionGroupDropdownList } from 'src/modals/GroupListingsAutoGroupAndCompV2/components/ZoneSectionGroupDropdownList';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionInfo } from 'src/WebApiController';

import * as styles from './ChangeSectionGroupDialog.css';

export type ChangeSectionGroupDialogProps = ComponentProps<typeof RSModal> & {
  selectedSections: SectionInfo[] | undefined;
  sectionIdMapping: { [key: string]: string } | undefined;
  zoneAreaMetadata:
    | {
        [key: string]: { color: string; rank: number; id: string; key: string };
      }
    | undefined;
  onOkay: (groupId: string, sectionIds: number[]) => void;
  onCancel: () => void;
};

export function ChangeSectionGroupDialog({
  selectedSections,
  sectionIdMapping,
  zoneAreaMetadata,
  onOkay,
  onCancel,
  ...rest
}: ChangeSectionGroupDialogProps) {
  const [zoneSection, setZoneSection] = useState<[string, string] | null>(null);

  const selectedSection = useMemo(() => {
    return selectedSections?.[0];
  }, [selectedSections]);

  useEffect(() => {
    const sectionId = selectedSection?.id;
    const groupId =
      sectionIdMapping && sectionId ? sectionIdMapping[sectionId] : undefined;

    if (groupId && zoneAreaMetadata && zoneAreaMetadata[groupId]) {
      setZoneSection([groupId, zoneAreaMetadata[groupId].color]);
    }
  }, [sectionIdMapping, selectedSection?.id, zoneAreaMetadata]);

  if (!selectedSections?.length || !selectedSection) {
    return null;
  }

  return (
    <>
      <GenericDialog
        {...rest}
        size="m"
        header={<Content id={ContentId.ChangeZone} />}
        footer={
          <>
            <Button className={styles.cancelButton} onClick={onCancel}>
              <Content id={ContentId.Cancel} />
            </Button>
            <Button
              disabled={zoneSection == null}
              onClick={() => {
                if (zoneSection != null) {
                  onOkay(
                    zoneSection[0],
                    selectedSections!.map((s) => s.id)
                  );
                }
              }}
            >
              <Content id={ContentId.Save} />
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        <div>
          <Content id={ContentId.ChangeGroup} />
        </div>
        {zoneAreaMetadata && (
          <ZoneSectionGroupDropdownList
            zoneSection={zoneSection}
            setZoneSection={setZoneSection}
            zoneAreaMetadata={zoneAreaMetadata}
          />
        )}
      </GenericDialog>
    </>
  );
}
