import { useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { UploadButton } from 'src/components/Buttons';
import {
  TicketUrlForm,
  UploadTicketUrlsForm,
} from 'src/components/UploadArtifacts/UploadTicketUrls';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button } from 'src/core/ui';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import {
  PosClientConfig,
  PosUiActionResult,
  SaleMetrics,
  TicketUrl,
} from 'src/WebApiController';

import { UploadTicketUrlsBody } from '../../components/UploadArtifacts/UploadTicketUrls/UploadTicketUrlsBody';
import { modalDetails } from '../common/Modals.css';
import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';

export type UploadTicketUrlProps = {
  onUploadTicketUrls?: (
    posClientConfig: PosClientConfig,
    entityId: number,
    ticketUrls: TicketUrl[]
  ) => Promise<PosUiActionResult>;
  cancelTo?: ModalProps;
  defaultToBulk?: boolean;
  isReadOnly?: boolean;
  loadingContentId: FormatContentId;
  searchingContentId: FormatContentId;
  errorContentId: FormatContentId;
};

export const UploadTicketUrls = ({
  onUploadTicketUrls,
  loadingContentId,
  searchingContentId,
  errorContentId,
  cancelTo,
}: UploadTicketUrlProps) => {
  const { loadingState } = useEventItemLoadingDisplay<EntityWithRealTickets>(
    loadingContentId,
    searchingContentId,
    errorContentId
  );

  return (
    loadingState || (
      <UploadTicketUrlsContent
        cancelTo={cancelTo}
        onUploadTicketUrls={onUploadTicketUrls}
      />
    )
  );
};

export type UploadTicketUrlsContentProps = Omit<
  UploadTicketUrlProps,
  'loadingContentId' | 'searchingContentId' | 'errorContentId'
>;

export const UploadTicketUrlsContent = ({
  onUploadTicketUrls,
  cancelTo,
  isReadOnly = false,
}: UploadTicketUrlsContentProps) => {
  const { closeModal, setModal } = useContext(ModalContext);

  const { posEntity: entityWithTickets, setActivePosEntity } =
    useActivePosEntityContext<EntityWithRealTickets>();
  const { refreshMetrics } = useCatalogMetricsContext<SaleMetrics>();

  const goBack = useCallback(
    async (isCancelled: boolean) => {
      if (!isCancelled) {
        refreshMetrics?.();

        // Refresh the active data so the SaleDetail dialog will have the new content
        await setActivePosEntity(
          entityWithTickets!.id,
          entityWithTickets!.idOnMkp,
          true
        );
      }

      if (cancelTo) {
        setModal(cancelTo);
      } else {
        closeModal(true);
      }
    },
    [
      cancelTo,
      refreshMetrics,
      setActivePosEntity,
      entityWithTickets,
      setModal,
      closeModal,
    ]
  );

  return (
    <UploadTicketUrlsForm
      entityWithTickets={entityWithTickets!}
      onComplete={goBack}
      onUpload={onUploadTicketUrls}
      renderContent={(onUploadClick: () => void, disabled?: boolean) => (
        <UploadTicketUrlsFormContent
          cancelTo={cancelTo}
          onUploadClick={onUploadClick}
          disabled={disabled || isReadOnly}
          goBack={goBack}
        />
      )}
    />
  );
};

const UploadTicketUrlsFormContent = ({
  cancelTo,
  onUploadClick,
  disabled,
  goBack,
}: {
  cancelTo?: ModalProps;
  onUploadClick: () => void;
  disabled?: boolean;
  goBack: (isCancelled: boolean) => void;
}) => {
  const { formState } = useFormContext<TicketUrlForm>();
  const { isDirty, isSubmitting } = formState;

  const { event, posEntity } =
    useActivePosEntityContext<EntityWithRealTickets>();

  return (
    <>
      <CancellableFormHeader
        disabled={isSubmitting}
        cancelTo={cancelTo}
        showDialogOnCancel={isDirty}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.SaveTicketUrls} />}
        />
      </CancellableFormHeader>
      <ModalBody>
        <ModalBodyHeaderContainer>
          <Summary event={event!} posEntity={posEntity!} />
        </ModalBodyHeaderContainer>
        <ModalBodyDataContainer>
          {isSubmitting || !posEntity ? (
            <PosSpinner />
          ) : (
            <div className={modalDetails}>
              <UploadTicketUrlsBody
                isReadOnly={disabled}
                ticketCnt={posEntity.ticketCnt}
                seating={posEntity.seating}
              />
            </div>
          )}
        </ModalBodyDataContainer>
      </ModalBody>
      <ModalFooter>
        {/* We want to use isReadOnly here because isReadOnly is meant for this dialog is informational */}
        {!disabled && (
          <CancellableFormFooter
            disabled={disabled || isSubmitting}
            cancelTo={cancelTo}
            showDialogOnCancel={isDirty}
          >
            <UploadButton
              disabled={disabled || isSubmitting}
              onClick={onUploadClick}
              textContentId={ContentId.SaveTicketUrls}
            />
          </CancellableFormFooter>
        )}
        {disabled && (
          <Button onClick={() => goBack(true)} disabled={isSubmitting}>
            <Content id={ContentId.Back} />
          </Button>
        )}
      </ModalFooter>
    </>
  );
};
