import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { DownloadIcon, IconsFill, ProcessingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { downloadFileFromBlob } from 'src/utils/fileUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PurchaseClient, PurchaseOrderQuery } from 'src/WebApiController';

import { ButtonWithIcon } from './ButtonWithIcon';

export const ExportPurchasesButton = ({
  filterQuery,
  textContentId,
  iconOnlyMode,
  textOnly,
  variant,
  isEventView,
  ...props
}: {
  filterQuery: PurchaseOrderQuery;
  textContentId?: ContentId;
  iconOnlyMode?: boolean;
  textOnly?: boolean;
  variant?: string;
  isEventView: boolean;
} & Omit<ComponentProps<typeof ButtonWithIcon>, 'icon' | 'onClick'>) => {
  const { eventsTransformed } = useCatalogDataContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isLoading, setIsLoading] = useState(false);
  const isButtonDisabled = isLoading || eventsTransformed == null;

  const onExportButtonClicked = useCallback(async () => {
    setIsLoading(true);
    await tryInvokeApi(
      async () => {
        const result = await new PurchaseClient(
          activeAccountWebClientConfig
        ).exportPurchases(filterQuery, isEventView);

        if (result.data) {
          downloadFileFromBlob(result.fileName ?? 'purchase.csv', result.data);
        }
      },
      (error) => {
        showErrorDialog('PurchaseClient.exportPurchases', error, {
          trackErrorData: { filterQuery, isEventView },
        });
      },
      () => setIsLoading(false)
    );
  }, [activeAccountWebClientConfig, filterQuery, isEventView, showErrorDialog]);

  const iconFill = useMemo(
    () =>
      props.disabled
        ? IconsFill.textDisabled
        : iconOnlyMode || (variant ?? 'text') !== 'regular'
        ? IconsFill.textBrand
        : IconsFill.textInverted,
    [iconOnlyMode, props.disabled, variant]
  );
  return (
    <ButtonWithIcon
      {...props}
      onClick={isButtonDisabled ? undefined : onExportButtonClicked}
      disabled={isLoading || props.disabled}
      icon={
        !textOnly ? (
          isLoading ? (
            <RotatingWrapper>
              <ProcessingIcon fill={iconFill} />
            </RotatingWrapper>
          ) : (
            <DownloadIcon withHoverEffect fill={iconFill} />
          )
        ) : null
      }
      iconOnlyMode={iconOnlyMode}
      variant={variant ?? 'text'}
      textContentId={textContentId || ContentId.Export}
    />
  );
};
