import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { AdGroupsDataContext } from 'src/contexts/AdGroupsDataContext/AdGroupsDataContext';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { AdPlatformReportingContext } from 'src/contexts/AdPlatformReportingContext/AdPlatformReportingContext';
import { Stack } from 'src/core/ui';
import { AdGroupsTable } from 'src/tables/AdGroupsTable';
import { AdGroupRow } from 'src/tables/AdGroupsTable/configs/AdGroupsTableColumnConfig';
import { getTopLevelCategoryName } from 'src/utils/adGroupUtils';
import {
  AdGroup,
  AdPlatformTransactionAgg,
  Listing,
  RowInfo,
  SectionInfo,
  TicketClassInfo,
  VenueMapInfo,
} from 'src/WebApiController';

type AdGroupsFlattenedViewProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
};

export const AdGroupsFlattenedView = ({
  before,
  topListItemBefore,
}: AdGroupsFlattenedViewProps) => {
  const { adGroupsDataQuery, adGroupsData } = useContext(AdGroupsDataContext);
  const { reportData, errorInfo: reportingErrorInfo } = useContext(
    AdPlatformReportingContext
  );
  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const getListingById = useCallback(
    (listingId: string) => {
      if (catalogData == null) {
        return undefined;
      }
      return catalogData.listingsByExternalId[listingId];
    },
    [catalogData]
  );

  const listingExternalIdLookup = useCallback(
    (key: string) => {
      return getListingById(key);
    },
    [getListingById]
  );

  const venueMapLookup = useCallback(
    (eventId?: number) => {
      if (!catalogData || !eventId) {
        return undefined;
      }
      return catalogData.venueMapByEventId[eventId];
    },
    [catalogData]
  );

  const ticketClassInfoLookupForMap = useCallback(
    (key: number, venueMap: VenueMapInfo | undefined) => {
      const rowInfo = venueMap?.sections
        .flatMap((section) => section.rows)
        .find((row) => row.tktClass?.id == key);
      return rowInfo?.tktClass ?? undefined;
    },
    []
  );

  const ticketClassInfoLookup = useCallback(
    async (ticketClassId: number, eventId: number) => {
      const venueMap = await venueMapLookup(eventId);
      return ticketClassInfoLookupForMap(ticketClassId, venueMap);
    },
    [ticketClassInfoLookupForMap, venueMapLookup]
  );

  const sectionInfoLookupForMap = useCallback(
    (sectionId: number | undefined, venueMap: VenueMapInfo | undefined) => {
      if (!sectionId) return;
      return venueMap?.sections.find((section) => section.id == sectionId);
    },
    []
  );

  const sectionInfoLookup = useCallback(
    async (sectionId: number, eventId: number) => {
      const venueMap = await venueMapLookup(eventId);
      return sectionInfoLookupForMap(sectionId, venueMap);
    },
    [sectionInfoLookupForMap, venueMapLookup]
  );

  const rowInfoLookupForMap = useCallback(
    (rowId: number | undefined, venueMap: VenueMapInfo | undefined) => {
      if (!rowId) return;
      const result = venueMap?.sections.reduce<{
        section: SectionInfo | undefined;
        row: RowInfo | undefined;
      }>(
        (acc, section) => {
          const row = section.rows.find((row) => row.id == rowId);
          if (row) {
            return { section, row };
          }
          return acc;
        },
        { section: undefined, row: undefined }
      );

      return result;
    },
    []
  );

  const rowInfoLookup = useCallback(
    async (rowId: number, eventId: number) => {
      const venueMap = await venueMapLookup(eventId);

      return rowInfoLookupForMap(rowId, venueMap);
    },
    [rowInfoLookupForMap, venueMapLookup]
  );

  const populateBaseProperties = useCallback(
    (
      adGroup: AdGroup,
      reportDataByAdGroupId:
        | { [key: string]: AdPlatformTransactionAgg }
        | undefined
    ) => {
      const metric = reportDataByAdGroupId?.[adGroup.adGroupId];
      return {
        id: adGroup.adGroupId,
        state: adGroup.state,
        name: adGroup.name,
        campaignId: adGroup.campaignId,
        baseBid: adGroup.baseBid,
        maxBid: adGroup.maxBid,
        dailyBudget: adGroup.dailyBudget,
        adSpend:
          metric?.adSpend ??
          (reportDataByAdGroupId || reportingErrorInfo ? null : undefined),
        salesProceeds:
          metric?.salesProceeds ??
          (reportDataByAdGroupId || reportingErrorInfo ? null : undefined),
        qtySold:
          metric?.qtySold ??
          (reportDataByAdGroupId || reportingErrorInfo ? null : undefined),
      };
    },
    [reportingErrorInfo]
  );

  const processExternalIds = useCallback(
    async (
      adGroup: AdGroup,
      reportDataByAdGroupId:
        | { [key: string]: AdPlatformTransactionAgg }
        | undefined
    ) => {
      return Promise.all(
        adGroup.bidModifiers
          .filter((b) => b.key.externalId != null)
          .map(async (b) => {
            const baseProperties = populateBaseProperties(
              adGroup,
              reportDataByAdGroupId
            );
            const listing = listingExternalIdLookup(b.key.externalId!) as
              | Listing
              | undefined;

            if (listing) {
              const venueMap = venueMapLookup(listing.viagEvId ?? undefined);

              const ticketClass = venueMap?.sections
                .flatMap((section) => section.rows)
                .find((row) => row.id === listing.seating.rowId)?.tktClass;

              const section = sectionInfoLookupForMap(
                listing.seating.sectionId ?? undefined,
                venueMap
              );

              const event = listing.viagEvId
                ? catalogData?.events[listing.viagEvId]
                : undefined;

              const venue = event
                ? catalogData?.venues[event.event.venueId.toString()]
                : undefined;

              const performer = event?.event.perfId
                ? catalogData?.performers[event.event.perfId.toString()]
                : undefined;

              const genre = event?.event.genre?.toString();

              return {
                ...baseProperties,
                listing: listing,
                ticketClass: ticketClass,
                section: section,
                event: event,
                venue: venue,
                performer: performer,
                genre: genre,
              } as AdGroupRow;
            }

            return null;
          })
      );
    },
    [
      catalogData?.events,
      catalogData?.performers,
      catalogData?.venues,
      listingExternalIdLookup,
      populateBaseProperties,
      sectionInfoLookupForMap,
      venueMapLookup,
    ]
  );

  const processRowInfos = useCallback(
    async (
      adGroup: AdGroup,
      reportDataByAdGroupId:
        | { [key: string]: AdPlatformTransactionAgg }
        | undefined
    ) => {
      return Promise.all(
        adGroup.bidModifiers
          .filter((b) => b.key.rowId != null && b.key.eventId != null)
          .map(async (b) => {
            const baseProperties = populateBaseProperties(
              adGroup,
              reportDataByAdGroupId
            );

            const eventId = b.key.eventId!;
            const rowAndSectionInfo = await rowInfoLookup(
              b.key.rowId!,
              eventId
            );
            if (rowAndSectionInfo) {
              const event = eventId ? catalogData?.events[eventId] : undefined;
              const venue = event
                ? catalogData?.venues[event.event.venueId.toString()]
                : undefined;
              const performer = event?.event.perfId
                ? catalogData?.performers[event.event.perfId.toString()]
                : undefined;
              const genre = event?.event.genre.toString();
              return {
                ...baseProperties,
                row: rowAndSectionInfo.row,
                section: rowAndSectionInfo.section,
                ticketClass: rowAndSectionInfo.row?.tktClass,
                event: event,
                venue: venue,
                performer: performer,
                genre: genre,
              } as AdGroupRow;
            }
            return null;
          })
      );
    },
    [
      catalogData?.events,
      catalogData?.performers,
      catalogData?.venues,
      populateBaseProperties,
      rowInfoLookup,
    ]
  );

  const processTicketClassInfos = useCallback(
    async (
      adGroup: AdGroup,
      reportDataByAdGroupId:
        | { [key: string]: AdPlatformTransactionAgg }
        | undefined
    ) => {
      return Promise.all(
        adGroup.bidModifiers
          .filter((b) => b.key.ticketClassId != null && b.key.eventId != null)
          .map(async (b) => {
            const baseProperties = populateBaseProperties(
              adGroup,
              reportDataByAdGroupId
            );

            const eventId = b.key.eventId!;
            const ticketClass = (await ticketClassInfoLookup(
              b.key.ticketClassId!,
              eventId!
            )) as TicketClassInfo | undefined;
            if (ticketClass) {
              const event = eventId ? catalogData?.events[eventId] : undefined;
              const venue = event
                ? catalogData?.venues[event.event.venueId.toString()]
                : undefined;
              const performer = event?.event.perfId
                ? catalogData?.performers[event.event.perfId.toString()]
                : undefined;
              const genre = event?.event.genre.toString();
              return {
                ...baseProperties,
                ticketClass: ticketClass,
                event: event,
                venue: venue,
                performer: performer,
                genre: genre,
              } as AdGroupRow;
            }
            return null;
          })
      );
    },
    [
      catalogData?.events,
      catalogData?.performers,
      catalogData?.venues,
      populateBaseProperties,
      ticketClassInfoLookup,
    ]
  );

  const processSectionInfos = useCallback(
    async (
      adGroup: AdGroup,
      reportDataByAdGroupId:
        | { [key: string]: AdPlatformTransactionAgg }
        | undefined
    ) => {
      return Promise.all(
        adGroup.bidModifiers
          .filter((b) => b.key.sectionId != null && b.key.eventId != null)
          .map(async (b) => {
            const baseProperties = populateBaseProperties(
              adGroup,
              reportDataByAdGroupId
            );

            const eventId = b.key.eventId!;
            const sectionInfo = await sectionInfoLookup(
              b.key.sectionId!,
              eventId
            );
            if (sectionInfo) {
              const venueMapTicketClass = sectionInfo.specRow?.tktClass;
              const ticketClass: TicketClassInfo | undefined =
                venueMapTicketClass
                  ? {
                      id: venueMapTicketClass?.ticketClassId,
                      name: venueMapTicketClass?.ticketClassName,
                      color: venueMapTicketClass?.color,
                      cssPostFix: venueMapTicketClass?.cssPostFix,
                    }
                  : undefined;
              const event = eventId ? catalogData?.events[eventId] : undefined;
              const venue = event
                ? catalogData?.venues[event.event.venueId.toString()]
                : undefined;
              const performer = event?.event.perfId
                ? catalogData?.performers[event.event.perfId.toString()]
                : undefined;
              const genre = event?.event.genre.toString();
              return {
                ...baseProperties,
                section: sectionInfo,
                ticketClass: ticketClass,
                event: event,
                venue: venue,
                performer: performer,
                genre: genre,
              } as AdGroupRow;
            }
            return null;
          })
      );
    },
    [
      catalogData?.events,
      catalogData?.performers,
      catalogData?.venues,
      populateBaseProperties,
      sectionInfoLookup,
    ]
  );

  const processEvents = useCallback(
    async (
      adGroup: AdGroup,
      reportDataByAdGroupId:
        | { [key: string]: AdPlatformTransactionAgg }
        | undefined
    ) => {
      return Promise.all(
        adGroup.bidModifiers
          .filter(
            (b) =>
              b.key.eventId != null &&
              b.key.ticketClassId == null &&
              b.key.sectionId == null &&
              b.key.rowId == null &&
              b.key.externalId == null
          )
          .map(async (b) => {
            const baseProperties = populateBaseProperties(
              adGroup,
              reportDataByAdGroupId
            );

            const eventId = b.key.eventId!;
            const event = eventId ? catalogData?.events[eventId] : undefined;
            const venue = event
              ? catalogData?.venues[event.event.venueId.toString()]
              : undefined;
            const performer = event?.event.perfId
              ? catalogData?.performers[event.event.perfId.toString()]
              : undefined;
            const genre = event?.event.genre.toString();
            return {
              ...baseProperties,
              event: event,
              venue: venue,
              performer: performer,
              genre: genre,
            } as AdGroupRow;
          })
      );
    },
    [
      catalogData?.events,
      catalogData?.performers,
      catalogData?.venues,
      populateBaseProperties,
    ]
  );

  const processLeafCategories = useCallback(
    async (
      adGroup: AdGroup,
      reportDataByAdGroupId:
        | { [key: string]: AdPlatformTransactionAgg }
        | undefined
    ) => {
      return Promise.all(
        adGroup.bidModifiers
          .filter((b) => b.key.leafCategoryId != null)
          .map(async (b) => {
            const baseProperties = populateBaseProperties(
              adGroup,
              reportDataByAdGroupId
            );

            const leafCategoryId = b.key.leafCategoryId!;
            const performer = leafCategoryId
              ? catalogData?.performers[leafCategoryId]
              : undefined;

            const genre = performer?.categ.at(0);
            return {
              ...baseProperties,
              performer: performer,
              genre: genre,
            } as AdGroupRow;
          })
      );
    },
    [catalogData?.performers, populateBaseProperties]
  );

  const processTopLevelCategories = useCallback(
    async (
      adGroup: AdGroup,
      reportDataByAdGroupId:
        | { [key: string]: AdPlatformTransactionAgg }
        | undefined
    ) => {
      return Promise.all(
        adGroup.bidModifiers
          .filter((b) => b.key.topLevelCategoryId != null)
          .map(async (b) => {
            const baseProperties = populateBaseProperties(
              adGroup,
              reportDataByAdGroupId
            );

            const genre = getTopLevelCategoryName(b.key.topLevelCategoryId!);
            return {
              ...baseProperties,
              genre: genre,
            } as AdGroupRow;
          })
      );
    },
    [populateBaseProperties]
  );

  const resolveAndFlattenAdGroups = useCallback(
    async ({
      adGroups,
      reportDataByAdGroupId,
    }: {
      adGroups: AdGroup[];
      reportDataByAdGroupId:
        | { [key: string]: AdPlatformTransactionAgg }
        | undefined;
    }): Promise<AdGroupRow[]> => {
      return adGroups.reduce<Promise<AdGroupRow[]>>(
        async (accPromise, adGroup) => {
          const acc = await accPromise;

          const newRows = await Promise.all([
            processExternalIds(adGroup, reportDataByAdGroupId),
            processRowInfos(adGroup, reportDataByAdGroupId),
            processSectionInfos(adGroup, reportDataByAdGroupId),
            processTicketClassInfos(adGroup, reportDataByAdGroupId),
            processEvents(adGroup, reportDataByAdGroupId),
            processLeafCategories(adGroup, reportDataByAdGroupId),
            processTopLevelCategories(adGroup, reportDataByAdGroupId),
          ]);

          // Flatten the results and filter out null values
          return acc.concat(
            newRows
              .flat()
              .filter(
                (row): row is AdGroupRow => row !== null && row !== undefined
              )
          );
        },
        Promise.resolve([])
      );
    },
    [
      processEvents,
      processExternalIds,
      processLeafCategories,
      processRowInfos,
      processSectionInfos,
      processTicketClassInfos,
      processTopLevelCategories,
    ]
  );

  const [adGroupRows, setAdGroupRows] = useState<AdGroupRow[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getAdGroupRows = async () => {
      if (!adGroupsData || !catalogData) {
        return [];
      }

      setIsLoading(true);

      // TODO: Rather than building a dictionary here, we should get the data pre-indexed by id.
      const reportDataByAdGroupId = reportData?.reduce<{
        [key: string]: AdPlatformTransactionAgg;
      }>((acc, entity) => {
        if (entity.adGroupId) {
          acc[entity.adGroupId] = entity;
        }
        return acc;
      }, {});

      const adGroupRows = await resolveAndFlattenAdGroups({
        adGroups: adGroupsData,
        reportDataByAdGroupId: reportDataByAdGroupId,
      });

      setAdGroupRows(adGroupRows);
      setIsLoading(false);
    };
    getAdGroupRows();
  }, [adGroupsData, catalogData, reportData, resolveAndFlattenAdGroups]);

  return (
    <Stack direction="column" height="full" width="full">
      {before}
      {topListItemBefore}
      <AdGroupsTable
        useVirtuoso
        disablePagination
        adGroupEntities={adGroupRows}
        failedToRetrieveData={Boolean(adGroupsDataQuery.error)}
        isItemsLoading={
          adGroupsDataQuery.isLoading || !catalogData || isLoading
        }
      />
    </Stack>
  );
};
