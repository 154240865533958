import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import { useAlertWithSuppressionDialog } from './useAlertWithSuppressionDialog';

export function useManualPriceAdjustFloorOrCeilingDialog(
  listPrice: number | null,
  netProceedsFloor: number | null | undefined,
  netProceedsCeiling: number | null,
  onOkay: () => void,
  onCancel: () => void
) {
  const { value, ...rest } = useAlertWithSuppressionDialog(
    'manualPriceAdjustFloorOrCeilingTimestamp',
    onOkay,
    onCancel
  );
  return {
    ...rest,
    lastTimeStamp: value?.manualPriceAdjustFloorOrCeilingTimestamp,
    bodyText: (
      <Content
        id={
          (listPrice ?? 0) < (netProceedsFloor ?? Number.MIN_VALUE)
            ? ContentId.ManualPriceAdjustFloorWarning
            : (listPrice ?? 0) > (netProceedsCeiling ?? Number.MAX_VALUE)
            ? ContentId.ManualPriceAdjustCeilingWarning
            : ContentId.EmptyContent
        }
      />
    ),
  };
}
