import { useContext } from 'react';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { ModalContext } from 'src/contexts/ModalContext';
import { Stack } from 'src/core/ui';
import { ModalFooter } from 'src/modals/Modal/Modal.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { SellerRoleInfo } from 'src/WebApiController';

import { LaunchDeleteRole } from './components/LaunchDeleteRole';

export const SellerRoleModalFooter = ({
  roleInfo,
  onSubmit,
  disabled,
  setIsLoading,
  hasChanges,
}: {
  roleInfo?: SellerRoleInfo | null;
  onSubmit?: () => void;
  disabled?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
  hasChanges?: boolean;
}) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalFooter>
      <Stack
        gap="l"
        justifyContent="spaceBetween"
        alignItems="center"
        width="full"
      >
        {!roleInfo?.prefinedRole && roleInfo?.id && !roleInfo?.isDeleted ? (
          <LaunchDeleteRole
            disabled={disabled}
            setIsLoading={setIsLoading}
            roleInfo={roleInfo!}
          />
        ) : (
          <span></span>
        )}
        <Stack gap="m" alignItems="center">
          <CancelButton onClick={closeModal} content={ContentId.Close} />
          <OkButton
            onClick={onSubmit}
            textContentId={
              roleInfo?.isDeleted ? ContentId.ActivateRole : ContentId.Save
            }
            disabled={disabled || (!roleInfo?.isDeleted && !hasChanges)}
          />
        </Stack>
      </Stack>
    </ModalFooter>
  );
};
