import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ReportClient, ReportSnapshotInfo } from 'src/WebApiController';

interface UseReportSnapshotInfoProps {
  reportConfigId: number | undefined;
}

export const useFetchReportSnapshotsInfo = ({
  reportConfigId,
}: UseReportSnapshotInfoProps): UseQueryResult<
  ReportSnapshotInfo[] | null,
  Error
> => {
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const reportClient = useMemo(
    () => new ReportClient(activeAccountWebClientConfig),
    [activeAccountWebClientConfig]
  );

  const queryKey = useMemo(
    () => ['reportSnapshotsInfo', reportConfigId],
    [reportConfigId]
  );

  // Query to get all report snapshots info
  return useQuery<ReportSnapshotInfo[], Error>({
    queryKey,
    queryFn: async () => {
      return reportClient.getReportSnapshotInfos(reportConfigId);
    },

    enabled: !!reportConfigId,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    meta: {
      onError: (err: any) => {
        showErrorDialog('ReportClient.getReportSnapshotInfos', err, {
          trackErrorData: { reportConfigId },
        });
      },
      persist: true,
    },
    refetchOnWindowFocus: false,
    staleTime: Infinity, // We don't want useQuery to auto-refresh base on stale, we control our own refetch
    gcTime: 0, // we don't want to cache this data
  });
};
