import { debounce } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import { ReportFilterEditabilityDropdown } from 'src/components/FilterToolbar/FilterDialogV2/FiltersList/ReportFilterEditabilityDropdown';
import { useContent } from 'src/contexts/ContentContext';
import { useSidePanelContext } from 'src/contexts/SidePanelContext';
import { PosSelectableSearchBox } from 'src/core/POS/PosSearchBox/PosSelectableSearchBox';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { EVENT_GROUPING_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  DEFAULT_REPORT_FILTER_EDITABILITY,
  ReportFilterEditability,
  ReportPerformerVenueFilterItemId,
} from 'src/utils/reportsFilterUtils';
import { EventGroupingType } from 'src/WebApiController';

import { PerformerVenueFilterBarDiv } from './PerformerVenueFilterBar.styled';
import type { PerformerVenueFilterBarProps } from './PerformerVenueFilterBar.types';

export function PerformerVenueFilterBar({
  groupingQuery,
  onGroupingQueryChanged,
  onEditabilityChange,
  performerFilterEditability,
  venueFilterEditability,
  embeddedDisplayOnly,
  swiperRef,
}: PerformerVenueFilterBarProps) {
  const searchPlaceholderText = useContent(ContentId.Search);
  const { isCollapsed } = useSidePanelContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      if (text !== groupingQuery.searchText) {
        onGroupingQueryChanged({
          ...groupingQuery,
          searchText: text || null,
        });
      }
    }, 200),
    [groupingQuery]
  );

  const onSearchChange = useCallback(
    (searchText: string) => debouncedOnSearchChange(searchText),
    [debouncedOnSearchChange]
  );

  const onSelectorChange = useCallback(
    (groupingType: EventGroupingType | null) => {
      if (groupingType !== groupingQuery.groupingType) {
        onGroupingQueryChanged({
          ...groupingQuery,
          groupingType: groupingType!, // null is not a selectable option for this enum filter
        });
      }
    },
    [groupingQuery, onGroupingQueryChanged]
  );

  const activeReportFilterId = useMemo(() => {
    return groupingQuery.groupingType === EventGroupingType.Performer
      ? ReportPerformerVenueFilterItemId.PerformerIds
      : ReportPerformerVenueFilterItemId.VenueIds;
  }, [groupingQuery.groupingType]);

  const activeEditability = useMemo(() => {
    return groupingQuery.groupingType === EventGroupingType.Performer
      ? performerFilterEditability
      : venueFilterEditability;
  }, [
    groupingQuery.groupingType,
    performerFilterEditability,
    venueFilterEditability,
  ]);

  const activeValueOptionsContent = useMemo(() => {
    if (!embeddedDisplayOnly) {
      return EVENT_GROUPING_TO_CID;
    }

    return Object.entries(EVENT_GROUPING_TO_CID).reduce(
      (acc, [key, value]) => {
        if (
          key === EventGroupingType.Performer &&
          (performerFilterEditability ?? DEFAULT_REPORT_FILTER_EDITABILITY) !==
            ReportFilterEditability.Hidden
        ) {
          acc[key as EventGroupingType] = value;
        }
        if (
          key === EventGroupingType.Venue &&
          (venueFilterEditability ?? DEFAULT_REPORT_FILTER_EDITABILITY) !==
            ReportFilterEditability.Hidden
        ) {
          acc[key as EventGroupingType] = value;
        }
        return acc;
      },
      {} as Record<EventGroupingType, ContentId>
    );
  }, [embeddedDisplayOnly, performerFilterEditability, venueFilterEditability]);

  const selectorProps = useMemo(
    () => ({
      value: groupingQuery.groupingType || EventGroupingType.Performer,
      defaultValue: EventGroupingType.Performer,
      onChange: onSelectorChange,
      valueOptionsContent: activeValueOptionsContent,
    }),
    [activeValueOptionsContent, groupingQuery.groupingType, onSelectorChange]
  );

  return (
    <PerformerVenueFilterBarDiv isCollapsed={isCollapsed}>
      {!isCollapsed && (
        <Stack direction="column" gap="m" alignItems="end">
          <PosSelectableSearchBox
            placeholder={searchPlaceholderText}
            onSearchChange={onSearchChange}
            selectorProps={selectorProps}
            disabled={
              embeddedDisplayOnly &&
              activeEditability !== ReportFilterEditability.Edit
            }
          />
          {!embeddedDisplayOnly && onEditabilityChange != null && (
            <ReportFilterEditabilityDropdown
              filterId={activeReportFilterId}
              reportFilterEditability={activeEditability}
              onEditabilityChange={onEditabilityChange}
            />
          )}
        </Stack>
      )}
      <SwiperButton dir="right" swiperRef={swiperRef} />
    </PerformerVenueFilterBarDiv>
  );
}
