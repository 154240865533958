import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PurchaseClient } from 'src/WebApiController';

export const GET_ACCESSIBLE_VENDOR_ACCOUNTS_KEY = 'getAccessibleVendorAccounts';

export function useGetAccessibleVendorAccountsQuery(
  purchaseOrderVendorIds: number[] | null | undefined
) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  return useQuery({
    queryKey: [
      GET_ACCESSIBLE_VENDOR_ACCOUNTS_KEY,
      activeAccountWebClientConfig.activeAccountId,
      purchaseOrderVendorIds,
    ],
    queryFn: () => {
      if (!purchaseOrderVendorIds?.length) {
        // If all of these are nulls, return nothing
        return null;
      }

      return new PurchaseClient(
        activeAccountWebClientConfig
      ).getAccessibleVendorAccounts(
        { item1: purchaseOrderVendorIds, item2: [] },
        null,
        null,
        null
      );
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PurchaseClient.getAccessibleVendorAccounts', error, {
          vendorIdSelected: purchaseOrderVendorIds,
        });
      },
    },
  });
}
