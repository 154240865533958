import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import { useAlertWithSuppressionDialog } from './useAlertWithSuppressionDialog';

export function useCancelTicketGroupDialog(
  onOkay: () => void,
  onCancel: () => void
) {
  const { value, ...rest } = useAlertWithSuppressionDialog(
    'cancelTicketGroupSuppressionTimestamp',
    onOkay,
    onCancel
  );
  return {
    ...rest,
    lastTimeStamp: value?.cancelTicketGroupSuppressionTimestamp,
    bodyText: <Content id={ContentId.CancelSingleTicketGroupWarning} />,
    cancelText: ContentId.No,
  };
}
