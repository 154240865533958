export const coalesceUndefinedOnly = <T>(
  x: T | null | undefined,
  y: T | null | undefined
) => {
  return x === undefined ? y : x;
};

export const removeDuplicates = <T = unknown>(arr: T[]): T[] => {
  return [...new Set(arr)];
};
