import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import { logToServer } from 'src/utils/clientLogUtils';
import { LoginClient, LogLevel } from 'src/WebApiController';

export const useGetActiveAccountClientConfig = (requestName: string) => {
  const { activeAccountWebClientConfig, setLoginContext } = useAppContext();

  const navigate = useNavigate();

  const getActiveAccountClientConfig = useCallback(async () => {
    if (activeAccountWebClientConfig.activeAccountId == null) {
      logToServer(
        LogLevel.Warning,
        requestName,
        'Null activeAccountId - repulling loginContext',
        { activeAccountWebClientConfig }
      );

      const newLogin = await new LoginClient({}).getLoginContext(
        window.location.pathname
      );
      if (!newLogin.isSuccess || newLogin.user?.activeAccount.id == null) {
        logToServer(
          LogLevel.Error,
          requestName,
          'Null activeAccountId - unable to repull loginContext - reload to refresh login',
          { newLogin }
        );
        navigate(`/login`, {
          state: { returnUrl: window.location.pathname },
        });
      } else {
        logToServer(
          LogLevel.Information,
          requestName,
          'Null activeAccountId - repull loginContext successful - updating loginContext in app',
          { newLogin }
        );
        setLoginContext(newLogin);
        activeAccountWebClientConfig.activeAccountId =
          newLogin.user.activeAccount.id;
      }
    }

    return activeAccountWebClientConfig;
  }, [activeAccountWebClientConfig, navigate, requestName, setLoginContext]);

  return getActiveAccountClientConfig;
};
