import { useCallback } from 'react';
import { useInvalidateGetAccessibleVendorsQuery } from 'src/hooks/api/useInvalidateGetAccessibleVendorsQuery';
import { useInvalidateGetVendorQuery } from 'src/hooks/api/useInvalidateGetVendorQuery';
import { useAddVendor } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useAddVendor';

export const useAddVendorActions = () => {
  const invalidateGetAccessibleVendorsQuery =
    useInvalidateGetAccessibleVendorsQuery();
  const invalidateGetVendorQuery = useInvalidateGetVendorQuery();

  const onVendorSaved = useCallback(async () => {
    await Promise.all([
      invalidateGetAccessibleVendorsQuery(),
      invalidateGetVendorQuery(),
    ]);
  }, [invalidateGetAccessibleVendorsQuery, invalidateGetVendorQuery]);

  return useAddVendor({ onVendorSaved });
};
