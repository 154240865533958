import { useMemo } from 'react';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionInfo } from 'src/WebApiController';

export type RowSelectorProps = Omit<PosSelectProps, 'valueOptionsContent'> & {
  selectedSection?: SectionInfo | null;
};

export function RowSelector({
  disabled,
  placeholderText,
  selectedSection,
  searchable,
  ...props
}: RowSelectorProps) {
  const filteredRows = useMemo(
    () => selectedSection?.rows.filter((r) => r.name && !r.isSpeculative) ?? [],
    [selectedSection?.rows]
  );
  const valueOptionsContent = useMemo(
    () =>
      filteredRows.reduce(
        (re, r) => {
          re[r.id.toString()] = r.name!;
          return re;
        },
        {} as Record<string, string>
      ),
    [filteredRows]
  );

  const nameToOrdinal = useMemo(
    () =>
      filteredRows.reduce(
        (res, row) => {
          res[row.name!] = row.ordinal!;
          return res;
        },
        {} as Record<string, number>
      ),
    [filteredRows]
  );

  return (
    <PosSelect
      {...props}
      disabled={disabled}
      loading={selectedSection === undefined}
      searchable={searchable ?? true}
      allowSearchAsInput
      valueOptionsContent={valueOptionsContent}
      placeholderText={placeholderText ?? ContentId.ChooseRow}
      useVirtualWindow={false} // TODO: temporarily disable virtual list for row selector to avoid wonky behavior, POS-4158
      sortFn={(a, b) => (nameToOrdinal[a] ?? 0) - (nameToOrdinal[b] ?? 0)}
      sortBy="value"
    />
  );
}
