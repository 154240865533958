import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ProfileOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M4.19428583,15.6398038 C8.81852565,13.4720159 15.2939598,13.4620046 19.5776883,15.4601843 L19.5776883,15.4601843 L20.2865988,18.2735181 C18.0473734,20.1923469 15.1210864,21.2557552 12.0798581,21.2499765 C9.00727374,21.2556953 6.05031272,20.1681713 3.80236884,18.2094964 L3.80236884,18.2094964 Z M11.8658502,2.75 C12.8132194,2.75 13.6971299,3.09964698 14.295085,3.72431702 C14.8714136,4.32551293 15.186275,5.14633306 15.0826591,5.97200501 L15.0826591,5.97200501 L14.8236509,8.42036312 C14.7393951,9.13128366 14.3918706,9.74699528 13.8749458,10.1845086 C13.3462396,10.6319934 12.643472,10.8924189 11.8658502,10.8924189 C11.0871718,10.8924189 10.3756325,10.6221546 9.84195483,10.170462 C9.32574155,9.73355082 8.97662458,9.12573128 8.90913234,8.43003623 L8.90913234,8.43003623 L8.65017248,5.97279718 C8.5568466,5.1535261 8.84447543,4.33803715 9.43208247,3.72921742 C10.0691444,3.10459183 10.9493932,2.75352321 11.8658502,2.75 Z"
        stroke={props.fill}
        strokeWidth="1.5"
      />
    </SvgWrapper>
  );
}
