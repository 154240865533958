import { ComponentProps, useCallback } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { Content, useContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { FieldWrapper } from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseVendor } from 'src/WebApiController';

import { PurchaseVendorDeliveryOverrideInput } from './PurchaseVendorDeliveryOverrideInput';
import * as styles from './PurchaseVendorDialog.css';

export type PurchaseVendorDialogProps = {
  vendor: PurchaseVendor;
  onSave: (data: PurchaseVendor) => void;
  useReducedEdit?: boolean;
} & ComponentProps<typeof RSModal>;

export function PurchaseVendorDialog({
  vendor,
  ...rest
}: PurchaseVendorDialogProps) {
  const methods = useForm<PurchaseVendor>({
    defaultValues: vendor,
    values: vendor,
  });

  return (
    <FormProvider {...methods}>
      <PurchaseVendorDialogBody {...rest} />
    </FormProvider>
  );
}

type PurchaseVendorDialogBodyProps = Omit<PurchaseVendorDialogProps, 'vendor'>;

export const PurchaseVendorDialogBody = ({
  onSave,
  onClosed,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onOkay, // unused
  useReducedEdit,
  ...rest
}: PurchaseVendorDialogBodyProps) => {
  const { register, setError, handleSubmit, formState, getValues, watch } =
    useFormContext<PurchaseVendor>();

  const requiredMsg = useContent(ContentId.Required);

  const { id } = getValues();

  const nameError = formState.errors.name?.message;

  const isGlobal = watch('isGlobal');
  const vendorName = watch('name');

  const onSubmit = useCallback(() => {
    let hasErrors = false;
    if (!vendorName) {
      setError('name', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (!hasErrors) {
      handleSubmit(onSave)();
    }
  }, [handleSubmit, onSave, requiredMsg, setError, vendorName]);

  return (
    <GenericDialog
      {...rest}
      size="m"
      header={
        <Content
          id={
            id <= 0 ? ContentId.AddPurchaseVendor : ContentId.EditPurchaseVendor
          }
        />
      }
      footer={
        <>
          {onClosed && (
            <CancelButton
              onClick={onClosed}
              disabled={formState.isSubmitting}
            />
          )}
          <Button
            variant={'regular'}
            onClick={onSubmit}
            disabled={formState.isSubmitting}
          >
            <Content id={ContentId.Save} />
          </Button>
        </>
      }
      onCancel={onClosed}
    >
      <div className={styles.purchaseVendorDialogContainer}>
        <FieldWrapper>
          <PosFormField
            errors={nameError}
            label={<Content id={ContentId.Name} />}
          >
            <PosTextField
              rootProps={{
                disabled: formState.isSubmitting,
                state: getTextFieldState(nameError),
              }}
              disabled={formState.isSubmitting || (isGlobal ?? false)}
              {...register('name', {
                required: true,
              })}
            />
          </PosFormField>
        </FieldWrapper>

        {!useReducedEdit && <PurchaseVendorDeliveryOverrideInput />}
      </div>
    </GenericDialog>
  );
};
