import { Content } from 'src/contexts/ContentContext';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionInfo } from 'src/WebApiController';

import * as styles from './SectionSelectionPanel.css';
import { SectionSelector } from './SectionSelector';

export type SectionSelectorProps = {
  sectionIds: number[];
  disabledSectionIds?: number[];
  onSelectedSectionsChange: (selectedSections: SectionInfo[]) => void;
};

export const SectionSelectorPanel = ({
  sectionIds,
  disabledSectionIds,
  onSelectedSectionsChange,
}: SectionSelectorProps) => {
  return (
    <div className={styles.sectionSelectPanelContainerFull}>
      <SectionSelector
        sectionIds={sectionIds}
        disabledSectionIds={disabledSectionIds}
        onSelectedSectionsChange={onSelectedSectionsChange}
      />
      {sectionIds.length > 0 && (
        <Stack direction="row" justifyContent="spaceBetween" width="full">
          <Button
            variant={'textPlain'}
            onClick={() => onSelectedSectionsChange([])}
          >
            <Content id={ContentId.ClearAll} />
          </Button>
        </Stack>
      )}
    </div>
  );
};
