import clsx from 'clsx';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';

import {
  gradient,
  gradientDark,
  gradientLight,
  wrapper,
} from './PinnedCellGradient.css';

interface PinnedCellGradientProps {
  height: string | number;
}

export const PinnedCellGradient = ({ height }: PinnedCellGradientProps) => {
  const { isDarkMode } = useSiteTheme();

  return (
    <div className={wrapper} style={{ height }}>
      <div
        className={clsx(gradient, isDarkMode ? gradientDark : gradientLight)}
      ></div>
    </div>
  );
};
