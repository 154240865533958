import { useFormContext } from 'react-hook-form';
import { TagsFormBodyV2 } from 'src/components/TagsFormBody';
import { Content } from 'src/contexts/ContentContext';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserCanUpdateListingTags } from 'src/hooks/useUserHasListingPermissions';
import { DetailSection } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  Listing,
  ListingDetailsUpdateInput,
  Permission,
} from 'src/WebApiController';

export const TagsSection = ({
  listing,
  disabled,
  isBulkEdit,
}: {
  listing?: Listing | null;
  disabled?: boolean;
  isBulkEdit?: boolean;
}) => {
  const { setValue, watch } = useFormContext<ListingDetailsUpdateInput>();
  const tags = watch('tags') ?? [];

  const canCreateTage = useUserHasAnyOfPermissions(
    Permission.Inventory_AddTagType
  );
  const canEditTags = useUserCanUpdateListingTags(listing, isBulkEdit);

  return (
    <DetailSection name={<Content id={ContentId.Tags} />}>
      <TagsFormBodyV2
        canEditTags={canEditTags}
        canCreateTagType={canCreateTage}
        tagsCurrentEntity={tags}
        onTagsUpdate={(tags) => {
          setValue('tags', tags);
        }}
        disabled={disabled}
        entityType={ActionOutboxEntityType.Listing}
      />
    </DetailSection>
  );
};
