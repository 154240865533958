import clsx from 'clsx';
import { collapsableButton } from 'src/components/common/CollapsableContent/ExpandCollapsableContentButton.css';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';

interface ExpandCollapsableContentButton {
  onClick: () => void;
  className?: string;
}

export const ExpandCollapsableContentButton = ({
  onClick,
  className,
}: ExpandCollapsableContentButton) => {
  return (
    <Button
      variant="outline"
      shape="pill"
      className={clsx(collapsableButton, className)}
      onClick={onClick}
    >
      <ChevronDownIcon
        width={vars.iconSize.xxs}
        fill={IconsFill.currentColor}
      />
    </Button>
  );
};
