import { useDragLayer } from 'react-dnd';
import { draggingWrapperZIndex } from 'src/components/UploadArtifacts/UploadETicketsV2/constants';
import { MultiplePageDocumentPage } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/MultiplePageDocument/MultiplePageDocumentPage';
import { SinglePageDocumentPage } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/SinglePageDocument/SinglePageDocumentPage';
import {
  UploadETicketsDraggableData,
  UploadETicketsDraggableOrigin,
} from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/UploadETicketsDraggableData';

export const PageDragPreview = () => {
  const { isDragging, item, currentOffset } = useDragLayer<
    any,
    UploadETicketsDraggableData
  >((monitor) => {
    const item = monitor.getItem() as { data: UploadETicketsDraggableData };
    const dragOrigin = item?.data?.origin;
    return {
      item,
      itemType: monitor.getItemType(),
      currentOffset:
        dragOrigin === UploadETicketsDraggableOrigin.PreviewModal
          ? monitor.getClientOffset()
          : monitor.getSourceClientOffset(),
      isDragging: monitor.isDragging(),
    };
  });

  if (!item || !item.data?.pageRef?.current || !isDragging || !currentOffset) {
    return null;
  }

  const { x: left, y: top } = currentOffset;
  const width = item.data.pageRef.current.clientWidth;

  const isSingleDocumentPage = item.data.isSinglePageDocument;
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width,
        zIndex: draggingWrapperZIndex,
        pointerEvents: 'none',
        background: 'transparent',
        transform: `translate(${left}px,${top}px)`,
      }}
    >
      {isSingleDocumentPage ? (
        <SinglePageDocumentPage
          {...item.data.componentProps}
          isDragging={true}
          isDragElement={true}
        />
      ) : (
        <MultiplePageDocumentPage
          {...item.data.componentProps}
          isDragging={true}
          isDragElement={true}
        />
      )}
    </div>
  );
};
