import React from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import CircleIcon from 'src/core/ui/CircleIcon/CircleIcon';
import { Stack } from 'src/core/ui/Stack';
import { ReportGroupIcon } from 'src/svgs/ReportGroupIcon';
import { TimeoutIcon } from 'src/svgs/TimeoutIcon';
import { ClockIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';

import { TableErrorStateHeader } from './Table.css';
import { NoData } from './Table.styled';

interface TableStateWrapperProps {
  isLoading: boolean;
  hasError: boolean;
  hasTimeout: boolean;
  hasData: boolean;
  children: React.ReactNode;
}

const TableStateWrapper = <TQuery extends QueryWithViewMode>({
  isLoading,
  hasError,
  hasTimeout,
  hasData,
  children,
}: TableStateWrapperProps) => {
  const { initialQuery, setFilterQuery, setTempQuery } =
    useFilterQueryContext<TQuery>();

  if (isLoading) {
    return <PosSpinner />;
  }

  if (hasError) {
    return (
      <NoData>
        <Stack direction="column" alignItems="center">
          <CircleIcon
            icon={<ClockIcon />}
            styleOverride={{ marginBottom: vars.spacing.sm }}
          />
          <div>
            <Content id={ContentId.UnknownError} />
          </div>

          <Content id={ContentId.UnexpectedErrorOccured} />
        </Stack>
      </NoData>
    );
  }

  if (hasTimeout) {
    return (
      <NoData>
        <Stack direction="column" alignItems="center">
          <CircleIcon
            icon={<TimeoutIcon />}
            styleOverride={{ marginBottom: vars.spacing.sm }}
          />
          <div className={TableErrorStateHeader}>
            <Content id={ContentId.ResultTimeout} />
          </div>
          <Content id={ContentId.ResultTimeoutDescription} />
        </Stack>
      </NoData>
    );
  }

  if (!hasData) {
    return (
      <NoData>
        <Stack direction="column" alignItems="center">
          <CircleIcon
            icon={<ReportGroupIcon />}
            styleOverride={{ marginBottom: vars.spacing.sm }}
          />
          <div className={TableErrorStateHeader}>
            <Content id={ContentId.NoResults} />
          </div>
          <div>
            <Content id={ContentId.NoResultFound} />
            {'. '}
            <Content id={ContentId.AdjustFiltersSuggestion} />
          </div>
          <Button
            variant="outline"
            onClick={() => {
              setFilterQuery(initialQuery);
              setTempQuery(initialQuery);
            }}
            style={{ marginTop: vars.spacing.xl }}
          >
            <Content id={ContentId.ResetFilters} />
          </Button>
        </Stack>
      </NoData>
    );
  }

  return <>{children}</>;
};

export default TableStateWrapper;
