import { vars } from 'src/core/themes';
import { spacing } from 'src/core/themes/legacy/shared/spacing';
import styled from 'styled-components/macro';

export const SummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: ${() => vars.spacing.lg};
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      border: none;
      padding: 0px;
    `
    )}`}
`;

export const SummaryColumn = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${(props) => (props.gap ? `${props.gap}px` : vars.spacing.sm)};
`;

export const SummaryDateSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${() => vars.spacing.sm};
  min-width: max-content;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      flex-direction: column;
      gap: 2px;
      padding: ${vars.spacing.lg};
    `
    )}`}
`;

export const Divider = styled.div`
  width: 1px;
  height: 80%;
  border-left: 1px solid ${(props) => props.theme.colors.borderDark};
  display: block;
`;

export const SummaryDetailsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${() => vars.spacing.lg};
  gap: ${() => vars.spacing.m};
  width: 100%;
  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding: ${vars.spacing.xl};
    `
    )}`}
`;

export const SummaryEventTitle = styled.span`
  font-weight: 700;
  font-size: ${() => vars.typography.fontSize['lg']};
  line-height: 24px;
  color: ${(props) => props.theme.colors.textStrong};
  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      font-size: ${vars.typography.fontSize['2xl']}
    `
    )}`}
`;

export const MarketPlaceDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-end;
  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      gap: ${vars.spacing.sm};
    `
    )}`}
`;

export const SummaryMainText = styled.span<{ fontWeight?: number }>`
  font-size: ${() => vars.typography.fontSize['sm']};
  line-height: 20px;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 500)};
  color: ${(props) => props.theme.colors.textStrong};
`;

export const SummarySecondaryText = styled(SummaryMainText)`
  font-weight: 400;
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const SummaryMainTextTealAccent = styled(SummaryMainText)`
  color: ${(props) => props.theme.colors.accent075};
  padding-right: ${() => vars.spacing.xxs};
`;

export const SummaryMainTextPinkAccent = styled(SummaryMainText)`
  font-weight: 700;
  color: #db2c7b;
  /* ${(props) => props.theme.colors.secondary100}; */
`;
