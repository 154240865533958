import { PaymentsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/payment-wrapper';

export function WalletIcon(props: PaymentsProps) {
  return (
    <SvgWrapper isSquare={true} {...props}>
      <path
        d="m17.106 3.358.346 1.216L5.367 7.627l-.345-1.216 12.084-3.053Zm1.362 4.792H9.144l8.7-2.198.624 2.198Zm2.095 6.618h-4.457c-.355 0-.643-.272-.643-.606v-1.043c0-.334.288-.607.643-.607h4.457v2.256Zm-4.457-3.61c-1.147 0-2.08.88-2.08 1.961v1.043c0 1.082.933 1.961 2.08 1.961H22v-6.012c0-1.082-.933-1.961-2.08-1.961l-1.508-5.297a1.153 1.153 0 0 0-.57-.698 1.272 1.272 0 0 0-.924-.115L4.486 5.18c-.314.08-.577.27-.74.536a1.086 1.086 0 0 0-.123.873l.444 1.561C2.926 8.157 2 9.034 2 10.111v9.928C2 21.119 2.933 22 4.08 22h15.84c1.147 0 2.08-.88 2.08-1.961v-2.534l-1.437-.68v3.214c0 .334-.29.606-.643.606H4.08c-.354 0-.643-.272-.643-.606V10.11c0-.334.289-.606.643-.606h15.84c.354 0 .643.272.643.606v1.047h-4.457Z"
        fill="#677383"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
