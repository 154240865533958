import { useFormContext } from 'react-hook-form';
import { SeatBarcodeInput } from 'src/components/UploadArtifacts/UploadBarcodes/SeatBarcodeInput';
import { useContent } from 'src/contexts/ContentContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { TicketBarcode } from 'src/WebApiController';

import { BarcodeForm } from '../UploadBarcodes.utils';

export const BarcodeInput = ({
  ticketBarcode,
  disabled,
  index,
}: {
  ticketBarcode: TicketBarcode;
  disabled: boolean;
  index: number;
  forceShowErrors?: boolean;
}) => {
  const { register, formState } = useFormContext<BarcodeForm>();

  const field: `barcodes.${number}.barcode` = `barcodes.${index}.barcode`;

  const error = formState.errors.barcodes?.[index]?.barcode;
  const warning = error?.type === 'warning' ? error?.message : '';
  const errorMessage = error?.type !== 'warning' ? error?.message ?? '' : '';

  const requiredMsg = useContent(ContentId.Required);

  return (
    <>
      <SeatBarcodeInput
        ticketBarcode={ticketBarcode}
        index={index}
        disabled={disabled}
        errors={errorMessage ? [errorMessage] : undefined}
        {...register(field, {
          required: requiredMsg,
        })}
      />
      {warning && <WarningMessage message={warning} />}
    </>
  );
};
