import { isEqual } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonWithIcon } from 'src/components/Buttons';
import { FilterPillList } from 'src/components/common/FilterPill';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { PillItem } from 'src/core/ui/PillList/PillItem';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { SectionSelectDialog } from 'src/dialogs/SectionSelectDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useCompQuantitiesFilter } from 'src/hooks/useCompQuantitiesFilter';
import { useUndercutAmountSettings } from 'src/hooks/useUndercutAmountSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EditIcon, IconsFill } from 'src/svgs/Viagogo';
import {
  getUndercutPriceRepresentingMode,
  UNDERCUT_PRICE_MODE_OPTIONS,
  UndercutPriceSettingsMode,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import {
  Feature,
  ListingDetailsAutoPricingSectionUpdatesV2,
} from 'src/WebApiController';

import { AutoPricingSettingsInput } from '../../BulkEditAutoPricingSettingsDialog.types';
import { useAutoPricingSettings } from '../../useBulkAutoPricingSettings';
import * as styles from '../AutoPricingSettings.css';
import { PricingSetting } from '../PricingSetting';
import { SettingsSubheader } from '../PricingSubheader';

type PricingAgainstProps = {
  origin: Pick<
    AutoPricingSettingsInput,
    | 'compListingMode'
    | 'compListingQuantityScoreAdjustmentEnabled'
    | 'compListingQuantityScoreAdjustmentOverrideJson'
    | 'compListingOnlyForSelectedSectionsEnabled'
    | 'compListingSelectedSectionSettings'
    | 'undercutAbsoluteAmount'
    | 'undercutRelativeAmount'
  >;
  compListingModes: Record<string, ContentId>;
  compListingModesDisabled: Record<string, ContentId | undefined>;
};

export const PricingAgainst: React.FC<PricingAgainstProps> = ({
  origin,
  compListingModes,
  compListingModesDisabled,
}) => {
  const {
    uiCurrency,
    compListingMode,
    compListingQuantityScoreAdjustmentOverrideJson,
    compListingSelectedSectionSettings,
    undercutMode,
    undercutRelativeAmount,
    undercutAbsoluteAmount,
    includeExistingSelectedSections,
    onCompListingChange,
    onUndercutChange,
    onCompListingSectionIdFilterChange,
    onCompListingModeChange,
  } = useAutoPricingSettings();

  const hasAllowSeatScoreCompListingModeFeature = useUserHasFeature(
    Feature.AllowSeatScoreCompListingMode
  );

  const { setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();

  const { event, venueMapInfo } = useEventMapContext();

  const sectionSelectDialog = useBasicDialog();

  const {
    quantitiesFilterOptions,
    quantitiesFilterValue,
    onUpdateQuantitiesFilter,
  } = useCompQuantitiesFilter(
    compListingQuantityScoreAdjustmentOverrideJson?.value,
    onCompListingChange
  );

  const {
    undercutAbsoluteAmountMode,
    setUndercutAbsoluteAmountMode,
    undercutRelativeAmountMode,
    setUndercutRelativeAmountMode,
  } = useUndercutAmountSettings(
    undercutAbsoluteAmount?.value,
    undercutRelativeAmount?.value
  );

  const onResetCompListingMode = useCallback(() => {
    onCompListingChange({
      compListingModeNew: origin.compListingMode.value,
      compListingQuantityScoreAdjustmentEnabledNew:
        origin.compListingQuantityScoreAdjustmentEnabled.value,
      compListingOnlyForSelectedSectionsEnabledNew:
        origin.compListingOnlyForSelectedSectionsEnabled?.value,
      compListingSelectedSectionSettingsNew:
        origin.compListingSelectedSectionSettings?.value,
    });
  }, [
    onCompListingChange,
    origin.compListingMode.value,
    origin.compListingOnlyForSelectedSectionsEnabled?.value,
    origin.compListingQuantityScoreAdjustmentEnabled.value,
    origin.compListingSelectedSectionSettings?.value,
  ]);

  const selectedSectionsHasChange = useMemo(
    () =>
      !isEqual(
        origin.compListingSelectedSectionSettings?.value,
        compListingSelectedSectionSettings?.value
      ) ||
      (origin.compListingSelectedSectionSettings?.hasConflict &&
        !includeExistingSelectedSections),
    [
      compListingSelectedSectionSettings?.value,
      includeExistingSelectedSections,
      origin.compListingSelectedSectionSettings?.hasConflict,
      origin.compListingSelectedSectionSettings?.value,
    ]
  );
  const onResetSelectedSections = useCallback(() => {
    onCompListingChange({
      compListingOnlyForSelectedSectionsEnabledNew:
        origin.compListingOnlyForSelectedSectionsEnabled?.value,
      compListingSelectedSectionSettingsNew:
        origin.compListingSelectedSectionSettings?.value,
    });
  }, [
    onCompListingChange,
    origin.compListingOnlyForSelectedSectionsEnabled?.value,
    origin.compListingSelectedSectionSettings?.value,
  ]);

  const quantitiesFilterHasChange = useMemo(
    () =>
      origin.compListingQuantityScoreAdjustmentOverrideJson?.value !==
      compListingQuantityScoreAdjustmentOverrideJson?.value,
    [
      compListingQuantityScoreAdjustmentOverrideJson?.value,
      origin.compListingQuantityScoreAdjustmentOverrideJson?.value,
    ]
  );
  const onResetQuantitiesFilter = useCallback(
    () =>
      onCompListingChange({
        compListingQuantityScoreAdjustmentOverrideJsonNew:
          origin.compListingQuantityScoreAdjustmentOverrideJson?.value,
      }),
    [
      onCompListingChange,
      origin.compListingQuantityScoreAdjustmentOverrideJson?.value,
    ]
  );

  const undercutAmountHasChange = useMemo(
    () =>
      origin.undercutAbsoluteAmount?.value !== undercutAbsoluteAmount?.value ||
      origin.undercutRelativeAmount?.value !== undercutRelativeAmount?.value,
    [
      origin.undercutAbsoluteAmount?.value,
      origin.undercutRelativeAmount?.value,
      undercutAbsoluteAmount?.value,
      undercutRelativeAmount?.value,
    ]
  );
  const onResetUndercutAmount = useCallback(
    () =>
      onUndercutChange(
        undefined,
        origin.undercutAbsoluteAmount?.value,
        origin.undercutRelativeAmount?.value
      ),
    [
      onUndercutChange,
      origin.undercutAbsoluteAmount?.value,
      origin.undercutRelativeAmount?.value,
    ]
  );

  const existingSectionsText = useContent(ContentId.ExistingSections);
  const editSections = useContent(ContentId.EditSections);

  const selectedSections = useMemo(() => {
    const formSectionFilterIds =
      compListingSelectedSectionSettings?.value?.sectionIdFilter ?? [];
    return (
      venueMapInfo?.sections.filter((section) => {
        return formSectionFilterIds.includes(section.id);
      }) ?? []
    );
  }, [
    compListingSelectedSectionSettings?.value?.sectionIdFilter,
    venueMapInfo?.sections,
  ]);

  const rowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.value?.rowIdFilter ?? [];
  }, [compListingSelectedSectionSettings]);

  const sectionRowIdFilters = useMemo(() => {
    return compListingSelectedSectionSettings?.value?.sectionRowIdFilter ?? {};
  }, [compListingSelectedSectionSettings]);

  const applyCompListingChanges = (
    rowIdFilters: number[],
    sectionRowIdFilters: { [x: string]: string }
  ) => {
    const compListingSelectedSectionSettingsNew = {
      sectionIdFilter:
        compListingSelectedSectionSettings?.value?.sectionIdFilter ?? [],
      rowIdFilter: Array.from(new Set(rowIdFilters)),
      sectionRowIdFilter: sectionRowIdFilters,
    };

    onCompListingChange({
      compListingSelectedSectionSettingsNew,
      compListingOnlyForSelectedSectionsEnabledNew: true,
    });
  };

  return (
    <>
      <SettingsSubheader title={ContentId.PriceAgainst} />
      {hasAllowSeatScoreCompListingModeFeature && (
        <PricingSetting
          header={<Content id={ContentId.AllListings} />}
          detail={
            <PosEnumSelect
              value={compListingMode?.value}
              valueOptionsContent={compListingModes}
              valueOptionsDisabled={compListingModesDisabled}
              placeholderText={ContentId.None}
              onClick={(e) => e.stopPropagation()}
              onChange={onCompListingModeChange}
              style={{ width: '50%' }}
            />
          }
          hasChange={origin.compListingMode.value !== compListingMode?.value}
          hasConflict={origin.compListingMode.hasConflict}
          onReset={onResetCompListingMode}
        />
      )}
      <PricingSetting
        header={
          <Stack width="full" gap="m">
            <Content id={ContentId.Sections} />
            {event && (
              <ButtonWithIcon
                variant="textPlain"
                title={editSections}
                icon={
                  <EditIcon
                    fill={IconsFill.textBrand}
                    size={vars.iconSize.s}
                    withHoverEffect
                  />
                }
                onClick={sectionSelectDialog.launchDialog}
                style={{ padding: 0, textAlign: 'start' }}
              />
            )}
          </Stack>
        }
        detail={
          <Stack gap="m" width="full" alignItems="center">
            {origin.compListingSelectedSectionSettings?.hasConflict && (
              <PillItem
                value={includeExistingSelectedSections + ''}
                display={existingSectionsText}
                onPillClick={(value, e) => {
                  if (!includeExistingSelectedSections) {
                    event && setValue('includeExistingSelectedSections', true);
                  }
                }}
                customPillContainer={
                  includeExistingSelectedSections
                    ? styles.pillSelected
                    : styles.pill
                }
                onDelete={() =>
                  event && setValue('includeExistingSelectedSections', false)
                }
                disabled={!includeExistingSelectedSections}
              />
            )}
            {event && (
              <div style={{ flex: 1, width: '400px', overflow: 'auto' }}>
                <FilterPillList
                  selectedSections={selectedSections}
                  rowIdFilters={rowIdFilters}
                  sectionRowIdFilters={sectionRowIdFilters}
                  applyRowFilterChanges={applyCompListingChanges}
                  applySelectedSectionChanges={
                    onCompListingSectionIdFilterChange
                  }
                />
              </div>
            )}
          </Stack>
        }
        hasChange={selectedSectionsHasChange}
        hasConflict={origin.compListingSelectedSectionSettings?.hasConflict}
        onReset={onResetSelectedSections}
      />
      <PricingSetting
        header={<Content id={ContentId.QuantitiesFilter} />}
        detail={
          <PosMultiSelect
            align="start"
            values={quantitiesFilterValue ?? []}
            onChange={onUpdateQuantitiesFilter}
            valueOptionsContent={quantitiesFilterOptions}
          />
        }
        hasChange={quantitiesFilterHasChange}
        hasConflict={
          origin.compListingQuantityScoreAdjustmentOverrideJson?.hasConflict
        }
        onReset={onResetQuantitiesFilter}
      />
      <PricingSetting
        header={<Content id={ContentId.UnderCutModePrompt} />}
        detail={
          <Stack gap="m">
            <ToggleGroup
              disabled={!undercutMode || !undercutRelativeAmount?.value}
              options={UNDERCUT_PRICE_MODE_OPTIONS}
              value={undercutRelativeAmountMode}
              onValueChange={(value) => {
                if (!value) return;

                const mode = value as UndercutPriceSettingsMode;
                setUndercutRelativeAmountMode(mode);

                if (undercutRelativeAmount?.value) {
                  const curAmountAbs = Math.abs(undercutRelativeAmount.value);
                  onUndercutChange(
                    undefined,
                    undefined,
                    getUndercutPriceRepresentingMode(curAmountAbs, mode)
                  );
                }
              }}
            />

            <div className={styles.inlinePricingInputText}>
              <Content id={ContentId.PriceBy} />
            </div>

            <PosFormField style={{ width: 'fit-content' }}>
              <PosTextField
                disabled={!undercutMode}
                value={
                  undercutRelativeAmount?.value != null
                    ? roundToPrecision(
                        Math.abs(undercutRelativeAmount.value) * 100,
                        8
                      )
                    : ''
                }
                placeholder={undefined}
                type="number"
                postfixDisplay="%"
                onChange={(e) => {
                  const v = parseFloat(e.target.value);
                  if (v >= 0 && v <= Number.MAX_VALUE) {
                    const relativeValue = Math.min(v, 100) / 100;
                    if (
                      undercutRelativeAmountMode ===
                      UndercutPriceSettingsMode.Premium
                    ) {
                      onUndercutChange(undefined, undefined, -relativeValue);
                    } else {
                      onUndercutChange(undefined, undefined, relativeValue);
                    }
                  } else {
                    onUndercutChange(undefined, undefined, null);
                  }
                }}
              />
            </PosFormField>
            <div className={styles.inlinePricingInputText}>
              <Content id={ContentId.Then} />
            </div>
            <ToggleGroup
              disabled={!undercutMode || !undercutAbsoluteAmount}
              options={UNDERCUT_PRICE_MODE_OPTIONS}
              value={undercutAbsoluteAmountMode}
              onValueChange={(value) => {
                if (!value) return;

                const mode = value as UndercutPriceSettingsMode;
                setUndercutAbsoluteAmountMode(mode);

                if (undercutAbsoluteAmount?.value) {
                  const curAmountAbs = Math.abs(undercutAbsoluteAmount.value);
                  onUndercutChange(
                    undefined,
                    getUndercutPriceRepresentingMode(curAmountAbs, mode)
                  );
                }
              }}
            />
            <div className={styles.inlinePricingInputText}>
              <Content id={ContentId.By} />
            </div>
            <PosFormField style={{ width: 'fit-content' }}>
              <PosCurrencyField
                uiCurrency={uiCurrency}
                disabled={!undercutMode}
                value={
                  undercutAbsoluteAmount?.value != null
                    ? Math.abs(undercutAbsoluteAmount.value)
                    : ''
                }
                placeholder={undefined}
                onChange={(e) => {
                  const v = parseFloat(e.target.value);
                  if (v >= 0 && v <= Number.MAX_VALUE) {
                    if (
                      undercutAbsoluteAmountMode ===
                      UndercutPriceSettingsMode.Premium
                    ) {
                      onUndercutChange(undefined, -v);
                    } else {
                      onUndercutChange(undefined, v);
                    }
                  } else {
                    onUndercutChange(undefined, null);
                  }
                }}
              />
            </PosFormField>
          </Stack>
        }
        hasChange={undercutAmountHasChange}
        hasConflict={
          origin.undercutAbsoluteAmount?.hasConflict ||
          origin.undercutRelativeAmount?.hasConflict
        }
        onReset={onResetUndercutAmount}
      />
      <SectionSelectDialog
        {...sectionSelectDialog.dialogProps}
        initialSectionIds={
          compListingSelectedSectionSettings?.value?.sectionIdFilter ?? []
        }
        onSave={(selectedSections) => {
          const compListingSelectedSectionSettingsNew = {
            ...compListingSelectedSectionSettings?.value,
            sectionIdFilter: selectedSections.map((s) => s.id),
            rowIdFilter:
              compListingSelectedSectionSettings?.value?.rowIdFilter ?? [],
            sectionRowIdFilter:
              compListingSelectedSectionSettings?.value?.sectionRowIdFilter ??
              {},
          };
          onCompListingChange({ compListingSelectedSectionSettingsNew });
          sectionSelectDialog.closeDialog();
        }}
        onClosed={sectionSelectDialog.closeDialog}
      />
    </>
  );
};
