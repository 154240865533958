import { ReactNode } from 'react';

import { MainMetrics, Metrics } from './MetricsDisplay.styled';

export const MetricsDisplay = ({
  className,
  mainMetrics,
  subMetrics,
  subMetricBelow,
  style,
}: {
  className?: string;
  mainMetrics: ReactNode;
  subMetrics?: ReactNode;
  subMetricBelow?: boolean;
  style?: React.CSSProperties;
}) => {
  return (
    <Metrics className={className} style={style}>
      {!subMetricBelow && subMetrics}
      <MainMetrics>{mainMetrics}</MainMetrics>
      {subMetricBelow && subMetrics}
    </Metrics>
  );
};
