import { ColumnDef } from '@tanstack/react-table';
import { IconButton } from 'src/components/Buttons';
import { Content, useContent } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { FormatOption } from 'src/modals/EditTableColumns';
import { DeleteIcon, EditIcon } from 'src/svgs/Viagogo';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { DateCell } from 'src/tables/Table/TableCells';
import { ContentId } from 'src/utils/constants/contentId';
import { PAYMENT_METHOD_NAME_REGEX_TO_CONTENT_ID } from 'src/utils/constants/contentIdMaps';
import { GridActionType } from 'src/utils/tableUtils';
import { CurrencyConversionOverride } from 'src/WebApiController';

export type CurrencyConversionOverrideWithHandlers = {
  currencyConversionOverride: CurrencyConversionOverride;
  onCurrencyConversionOverrideAction?: (
    currencyConversionOverride: CurrencyConversionOverride,
    actionType: GridActionType
  ) => void;
};

export const CURRENCY_CONVERSION_OVERRIDE_TABLE_COLUMNS_CONFIG: ColumnDef<CurrencyConversionOverrideWithHandlers | null>[] =
  [
    {
      id: 'currencyConversionOverrideFromCurrency',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.From} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.currencyConversionOverride?.fromCurrencyCode,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue<string | undefined>();
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
    },
    {
      id: 'currencyConversionOverrideToCurrency',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.To} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.currencyConversionOverride?.toCurrencyCode,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue<string | undefined>();
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
    },
    {
      id: 'currencyConversionOverrideConversionRate',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.ConversionRate} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.currencyConversionOverride?.conversionRate,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue<number | null | undefined>();
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
    },
    {
      id: 'currencyConversionOverrideConversionRateDate',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Date} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) =>
        data?.currencyConversionOverride?.conversionRateDate,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue<string>();
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <DateCell
            align="right"
            date={value ?? null}
            precision={FormatOption.DateTime_MMDD_Time_Day}
          />
        );
      },
    },
    {
      id: 'currencyConversionPaymentMethodName',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.PaymentMethod} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) =>
        data?.currencyConversionOverride?.purchasePaymentMethodName,
      cell: function Cell({ getValue, row: { original } }) {
        const allText = useContent(ContentId.All);
        if (!original) {
          return <TableShimmeringDiv />;
        }

        const { purchasePaymentMethodName, paymentMethodNameKnownRegexId } =
          original.currencyConversionOverride;

        return (
          <TableCellDiv align="right">
            {purchasePaymentMethodName == null &&
            paymentMethodNameKnownRegexId != null ? (
              <Content
                id={
                  PAYMENT_METHOD_NAME_REGEX_TO_CONTENT_ID[
                    paymentMethodNameKnownRegexId
                  ]
                }
              />
            ) : (
              <>{purchasePaymentMethodName ?? allText}</>
            )}
          </TableCellDiv>
        );
      },
    },
    {
      id: 'actions',
      enableSorting: false,
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Actions} />
        </TableHeaderCellDiv>
      ),
      minSize: 100,
      maxSize: 100,
      cell: ({ row: { original } }) => {
        return !original ? (
          <TableShimmeringDiv />
        ) : (
          <TableCellDiv align="right">
            <IconButton
              icon={<EditIcon withHoverEffect />}
              onClick={() =>
                original.onCurrencyConversionOverrideAction &&
                original.onCurrencyConversionOverrideAction(
                  original.currencyConversionOverride,
                  GridActionType.Edit
                )
              }
              titleContentId={ContentId.EditPurchaseVendorAccount}
            />
            <IconButton
              icon={<DeleteIcon withHoverEffect />}
              onClick={() =>
                original.onCurrencyConversionOverrideAction &&
                original.onCurrencyConversionOverrideAction(
                  original.currencyConversionOverride,
                  GridActionType.Delete
                )
              }
              titleContentId={ContentId.DeletePurchaseVendorAccount}
            />
          </TableCellDiv>
        );
      },
    },
  ];
