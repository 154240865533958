import { useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { ModalContext } from 'src/contexts/ModalContext/ModalContext';
import { vars } from 'src/core/themes';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { OpenLinkIcon } from 'src/svgs/OpenLinkIcon';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getPurchaseOrderDeepLinkUrl,
  getPurchaseOrderDetailsModalConfigWithDeepLink,
  getPurchaseOrderRelativeUrl,
} from 'src/utils/purchaseUtils';
import { PurchaseOrderDetails } from 'src/WebApiController';

export const PurchaseActionsCell = ({
  purchaseOrderId,
  linkOnly,
}: {
  purchaseOrderId: number;
  linkOnly?: boolean;
}) => {
  const { setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();
  const { setModal } = useContext(ModalContext);
  const { mainRoute } = useAppContext();
  const navigate = useNavigate();

  const onViewClick = useCallback(
    async (newTab: boolean) => {
      if (newTab) {
        window.open(getPurchaseOrderDeepLinkUrl(purchaseOrderId), '_blank');
      } else if (mainRoute === MainRoute.Purchases) {
        setActivePosEntity(purchaseOrderId, undefined, true);
        setModal(
          getPurchaseOrderDetailsModalConfigWithDeepLink(purchaseOrderId)
        );
      } else {
        navigate(getPurchaseOrderRelativeUrl(purchaseOrderId));
      }
    },
    [setActivePosEntity, purchaseOrderId, setModal, mainRoute, navigate]
  );

  return (
    <Link
      style={{ padding: `${vars.spacing['xxs']} ${vars.spacing['sm']}` }}
      to={getPurchaseOrderRelativeUrl(purchaseOrderId)}
      onClick={(e) => {
        if (e.button === 0 && e.getModifierState('Control') === false) {
          // Perform like a button click when it's a left-click (and no Ctrl)
          // Else the click will be whatever <a /> does
          e.preventDefault();
          e.stopPropagation();

          onViewClick(!!linkOnly);
        }
      }}
    >
      <IconButton
        icon={
          <OpenLinkIcon
            size={vars.iconSize.m}
            fill={vars.color.textBrand}
            withHoverEffect
          />
        }
        titleContentId={ContentId.ViewDetails}
      />
    </Link>
  );
};
