import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditNumber } from 'src/components/common/BulkActions/BulkEditNumber';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_CID,
  PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_WARNING_CID,
} from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  ListingDetails,
  PricingFloorCeilingAdjustmentType,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';
import { FloorCeiling } from './BulkEditListingPricingFloorCeilingDialog.constants';
import * as styles from './BulkEditListingPricingFloorCeilingDialog.css';
import {
  FieldLabelWrapper,
  FieldWrapper,
} from './BulkEditListingPricingFloorCeilingDialog.styled';

/**
 * Props for the BulkEditListingPricingFloorCeilingDialog component.
 *
 * @param floorCeiling Indicates whether the dialog is for adjusting the floor or ceiling.
 *
 * @param onOkay Callback function to be called when the user clicks the "Okay" button. Callback accepts
                  the following parameters
 * - amount: The raw amount value we the user is adjusting the floor / ceiling by
 * - isAbove: Indicates whether the value should be increased or decreased
 * - floorCeiling: The floor/ceiling object.
 * - isRelative: Indicates whether the adjustment is relative or absolute.
 * - relativeToTarget: The type of adjustment to be made.
 * - supportBackgroundProcess: Optional parameter to indicate whether to support background process.
 */
export type BulkEditListingPricingFloorCeilingDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  floorCeiling: FloorCeiling;
  onOkay: (
    amount: number,
    isAbove: boolean,
    floorCeiling: FloorCeiling,
    isRelative: boolean,
    relativeToTarget: PricingFloorCeilingAdjustmentType,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

export function BulkEditListingPricingFloorCeilingDialog({
  updateKey,
  floorCeiling,
  onOkay,
  onCancel,
  ...rest
}: BulkEditListingPricingFloorCeilingDialogProps) {
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);
  const marketPriceText = useContent(ContentId.MarketPrice);

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshExpandedListItems();
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.UpdatePriceFloorCeiling,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  // Form values
  const [amount, setAmount] = useState(0);
  const [isAbove, setIsAbove] = useState(false);
  const [isRelative, setIsRelative] = useState(true);
  const [relativeToTarget, setRelativeToTarget] =
    useState<PricingFloorCeilingAdjustmentType>(
      floorCeiling === FloorCeiling.Floor
        ? PricingFloorCeilingAdjustmentType.UnitCost
        : PricingFloorCeilingAdjustmentType.MarketPrice
    );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(
          0,
          false,
          FloorCeiling.Floor,
          false,
          PricingFloorCeilingAdjustmentType.CurrentFloor,
          false,
          onPreviewReceived
        );
      } else {
        onOkay(
          amount,
          isAbove,
          floorCeiling,
          isRelative,
          relativeToTarget,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [
      onOkay,
      amount,
      isAbove,
      floorCeiling,
      isRelative,
      relativeToTarget,
      preview,
      hasBackgroundBulkEditFeature,
    ]
  );

  const isInputValid = useMemo(() => {
    return !!amount;
  }, [amount]);

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
      header={
        <BulkEditHeader
          headerText={
            floorCeiling === FloorCeiling.Floor ? (
              <Content id={ContentId.AdjustPricingFloor} />
            ) : (
              <Content id={ContentId.AdjustPricingCeiling} />
            )
          }
        />
      }
      onOpened={() => {
        setAmount(0);
        setRelativeToTarget(
          floorCeiling === FloorCeiling.Floor
            ? PricingFloorCeilingAdjustmentType.UnitCost
            : PricingFloorCeilingAdjustmentType.MarketPrice
        );
        initJob();
      }}
      onKeyUp={(e) => {
        if (submittButtonRef.current && e.key === 'Enter' && isInputValid) {
          submittButtonRef.current.click();
        }
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={ActionOutboxEntityType.Listing}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          disabled={!isInputValid}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={ActionOutboxEntityType.Listing}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <div
          className={styles.bulkEditListingPricingFloorCeilingDialogContainer}
        >
          <FieldWrapper>
            {floorCeiling === FloorCeiling.Ceiling && (
              <FieldLabelWrapper>
                <FormatContent
                  id={FormatContentId.PercentageRelativeTo}
                  params={[marketPriceText]}
                />
              </FieldLabelWrapper>
            )}

            <Stack direction="column" gap="m" alignItems="start">
              <BulkEditNumber
                initAmount={amount}
                isLoading={isLoading}
                showAbsolute={floorCeiling === FloorCeiling.Floor}
                onChange={(value, isRelative, isAbove) => {
                  setAmount(value);
                  setIsRelative(isRelative);
                  setIsAbove(isAbove);
                }}
              />
              {floorCeiling === FloorCeiling.Floor && (
                <Stack direction="row" gap="l" alignItems="center">
                  <div className={styles.inlineText}>
                    <Content id={ContentId.RelativeTo} />
                  </div>

                  <PosFormField style={{ width: '100%' }}>
                    <PosEnumSelect
                      style={{ width: 'fit-content', height: '40px' }}
                      searchable={true}
                      value={relativeToTarget}
                      valueOptionsContent={
                        PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_CID
                      }
                      onChange={(newValue) => {
                        if (newValue != null && newValue != relativeToTarget) {
                          setRelativeToTarget(newValue);
                        }
                      }}
                    />
                  </PosFormField>
                </Stack>
              )}
            </Stack>
          </FieldWrapper>
          <FieldWrapper>
            <WarningMessage
              message={
                <Content
                  id={
                    PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_WARNING_CID[
                      relativeToTarget
                    ]
                  }
                />
              }
            />
          </FieldWrapper>
        </div>
      </BulkEditStatus>
    </GenericDialog>
  );
}
