import { MetricTile } from 'src/components/Metrics';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { ContentId } from 'src/utils/constants/contentId';
import { getCurrentLanguage } from 'src/utils/localeUtils';
import {
  EventMarketplaceWebsiteMetricOfInteger,
  EventMarketplaceWebsiteMetricOfUiMoney,
} from 'src/WebApiController';

import { DashboardWidgetTile } from '../DashboardWidgetTile/DashboardWidgetTile';

export const EventWebsiteMetricWidget = ({
  titleContentId,
  metric,
}: {
  titleContentId: ContentId;
  metric:
    | EventMarketplaceWebsiteMetricOfInteger
    | EventMarketplaceWebsiteMetricOfUiMoney
    | undefined;
}) => {
  const MaybePercentageChangeMetric = () => {
    if (!metric) {
      return <ContentPlaceholder height="2rem" width="100%" />;
    }

    const numberFormatOptions: Intl.NumberFormatOptions =
      typeof metric.currentValue === 'number'
        ? { style: 'decimal' }
        : {
            style: 'currency',
            notation: 'compact',
            currency: metric.currentValue.currency!,
          };
    const currentValue =
      typeof metric.currentValue === 'number'
        ? metric.currentValue
        : metric.currentValue.amt;
    const previousValue: number =
      typeof metric.previousValue === 'number'
        ? metric.previousValue
        : metric.previousValue.amt;

    const numberFormat = Intl.NumberFormat(
      getCurrentLanguage(),
      numberFormatOptions
    );
    const formattedCurrentValue = numberFormat.format(currentValue);
    return (
      <MetricTile.Metric isCompact={false} showLoadingPlaceholders={true}>
        <MetricTile.PercentChangeMetricText
          formattedCurrentValue={formattedCurrentValue}
          currentValue={currentValue}
          previousValue={previousValue}
        />
      </MetricTile.Metric>
    );
  };

  return (
    <DashboardWidgetTile titleContentId={titleContentId}>
      <MaybePercentageChangeMetric />
    </DashboardWidgetTile>
  );
};
