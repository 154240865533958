import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Switch } from 'src/core/interim/Switch';
import { Stack } from 'src/core/ui';
import { mergeProps } from 'src/core/utils';
import { ContentId } from 'src/utils/constants/contentId';
import { Seating } from 'src/WebApiController';

import * as styles from '../../../modals/UploadTicketUrls/UploadTicketUrls.css';
import { BulkTicketUrlInput } from '../UploadStringArtifacts/BulkStringArtifactInput';
import { ManualTicketUrlEntry } from '../UploadStringArtifacts/ManualStringArtifactEntry';
import { TicketUrlForm } from './UploadTicketUrls.utils';

export type UploadTicketUrlsBodyProps = {
  isReadOnly?: boolean;
  ticketCnt: number;
  seating: Seating;
  instructionContentId?: ContentId;
  className?: string;
};
export const UploadTicketUrlsBody = ({
  className,
  isReadOnly,
  seating,
  ticketCnt,
  instructionContentId,
}: UploadTicketUrlsBodyProps) => {
  const { watch, setValue } = useFormContext<TicketUrlForm>();

  const bulkUpload = watch('isBulkUpload');

  const inputProps = {
    readOnly: isReadOnly,
    seating,
    quantity: ticketCnt,
  };

  return (
    <div
      {...mergeProps(
        { className: styles.ticketUrlsContentWrapper },
        { className: className }
      )}
    >
      {!isReadOnly && (
        <Content id={instructionContentId ?? ContentId.EnterTicketUrlsIns} />
      )}
      <Stack direction="row" gap="m" alignItems="center" justifyContent="end">
        <Content id={ContentId.BulkEntry} />
        <Switch
          checked={bulkUpload}
          onChange={() => setValue('isBulkUpload', !bulkUpload)}
          disabled={isReadOnly}
        />
      </Stack>

      {!bulkUpload ? (
        <ManualTicketUrlEntry {...inputProps} />
      ) : (
        <BulkTicketUrlInput
          {...inputProps}
          onPreview={() => setValue('isBulkUpload', !bulkUpload)}
        />
      )}
    </div>
  );
};
