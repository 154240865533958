import { Content } from 'src/contexts/ContentContext';
import {
  ConfirmDialog,
  ConfirmDialogProps,
} from 'src/core/interim/dialogs/ConfirmDialog';
import { ContentId } from 'src/utils/constants/contentId';

export type UnsavedChangesProps = Omit<
  ConfirmDialogProps,
  'headerText' | 'bodyText'
>;
export const UnsavedChanges = ({
  onOkay,
  onCancel,
  ...props
}: UnsavedChangesProps) => {
  return (
    <ConfirmDialog
      headerText={<Content id={ContentId.UnsavedChanges} />}
      bodyText={<Content id={ContentId.UnsavedChangesWarning} />}
      onOkay={onOkay}
      onCancel={onCancel}
      okText={ContentId.Yes}
      cancelText={ContentId.No}
      {...props}
    />
  );
};
