import { ChangeEvent, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CurrencyFilterSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextArea } from 'src/core/POS/PosTextArea';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { DetailSection, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import {
  SALE_PAYMENT_STATE_TO_CID,
  SALES_STATUS_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  PointOfSaleSaleStatus,
  PosManagedMarketplaceSaleInput,
} from 'src/WebApiController';

const statusAllowedChangeable = [
  PointOfSaleSaleStatus.PendingFulfillment,
  PointOfSaleSaleStatus.ProcessingFulfillment,
  PointOfSaleSaleStatus.Fulfilled,
  PointOfSaleSaleStatus.FulfillmentFailed,
];

type MarketplaceSaleInputMarketplaceInfo = Pick<
  PosManagedMarketplaceSaleInput,
  | 'saleId'
  | 'saleDate'
  | 'saleStatus'
  | 'paymentState'
  | 'inHandDate'
  | 'totalNetProceeds'
  | 'internalNotes'
  | 'currencyCode'
>;

export const MarketplaceSaleInfoSection = ({
  disabled,
}: {
  disabled?: boolean;
}) => {
  const { getValues, watch, formState, setValue, clearErrors } =
    useFormContext<MarketplaceSaleInputMarketplaceInfo>();
  const {
    saleId,
    saleDate,
    saleStatus,
    paymentState,
    inHandDate,
    totalNetProceeds,
    internalNotes,
  } = getValues();
  const currencyCode = watch('currencyCode');

  const saleDateError = formState.errors.saleDate?.message;
  const currencyCodeError = formState.errors.currencyCode?.message;
  const inHandDateError = formState.errors.inHandDate?.message;
  const totalNetProceedsError = formState.errors.totalNetProceeds?.message;

  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = useMemo(
    () => getUiCurrency(currencyCode?.value),
    [getUiCurrency, currencyCode]
  );

  const isMobile = useMatchMedia('mobile');

  const allowChangingSaleStatus =
    saleStatus &&
    statusAllowedChangeable.includes(
      saleStatus?.value ?? PointOfSaleSaleStatus.PendingAllocation
    );

  const allowedSaleStatus = useMemo(() => {
    if (allowChangingSaleStatus) {
      const saleStatusOptions: Record<string, ContentId> = {};
      statusAllowedChangeable.forEach((s) => {
        saleStatusOptions[s] = SALES_STATUS_TO_CID[s];
      });

      return saleStatusOptions;
    }

    return {};
  }, [allowChangingSaleStatus]);

  return (
    <DetailSection name={<Content id={ContentId.SalesSummary} />}>
      <SectionContent
        numOfColumns={isMobile ? 1 : 2}
        format={isMobile ? undefined : 'max-content auto'}
      >
        <PosFormField
          label={<Content id={ContentId.SaleDate} />}
          errors={saleDateError}
        >
          <DatePickerInput
            fieldError={saleDateError}
            disabled={disabled}
            date={saleDate?.value ? new Date(saleDate.value) : undefined}
            onDateChange={(date: Date) => {
              if (date) {
                clearErrors('saleDate');
                const dateStr = date.toISOString();
                if (dateStr !== saleDate?.value) {
                  setValue('saleDate', posChangedField(dateStr));
                }
              }
            }}
            locale={getLocaleFromLanguageOrCurrent()}
          />
        </PosFormField>
        <PosFormField
          label={<Content id={ContentId.InHandDate} />}
          errors={inHandDateError}
        >
          <DatePickerInput
            fieldError={inHandDateError}
            disabled={disabled}
            date={inHandDate?.value ? new Date(inHandDate.value) : undefined}
            onDateChange={(date: Date) => {
              if (date) {
                clearErrors('inHandDate');
                const dateStr = date.toISOString();
                if (dateStr !== inHandDate?.value) {
                  setValue('inHandDate', posChangedField(dateStr));
                }
              }
            }}
            locale={getLocaleFromLanguageOrCurrent()}
          />
        </PosFormField>
        <div style={{ display: 'flex', gap: vars.spacing['sm'] }}>
          <PosFormField
            label={<Content id={ContentId.Currency} />}
            errors={currencyCodeError}
            style={{ width: 'auto' }}
          >
            <CurrencyFilterSelector
              disabled={disabled}
              value={uiCurrency.code}
              defaultValue={
                formState.defaultValues?.currencyCode?.value ?? undefined
              }
              onChange={(newCurrencyCode) => {
                if (
                  newCurrencyCode &&
                  newCurrencyCode !== currencyCode?.value
                ) {
                  setValue('currencyCode', posChangedField(newCurrencyCode));
                }
              }}
            />
          </PosFormField>
          <PosFormField
            errors={totalNetProceedsError}
            label={<Content id={ContentId.TotalNetProceeds} />}
          >
            <PosCurrencyField
              rootProps={{
                state: getTextFieldState(totalNetProceedsError),
              }}
              disabled={disabled}
              uiCurrency={uiCurrency}
              value={totalNetProceeds?.value ?? ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const v = parseFloat(e.target.value) || 0;
                if (v >= 0 && v <= Number.MAX_VALUE) {
                  clearErrors('totalNetProceeds');
                  setValue('totalNetProceeds', posChangedField(v));
                }
              }}
            />
          </PosFormField>
        </div>
        {saleId && allowChangingSaleStatus && (
          <PosFormField label={<Content id={ContentId.Status} />}>
            <PosEnumSelect
              style={{ width: '100%' }}
              value={saleStatus?.value}
              disabled={disabled}
              valueOptionsContent={allowedSaleStatus}
              onChange={(newStatus) => {
                if (newStatus && newStatus !== saleStatus?.value) {
                  setValue('saleStatus', posChangedField(newStatus));
                }
              }}
            />
          </PosFormField>
        )}
        {saleId && (
          <PosFormField label={<Content id={ContentId.PaymentState} />}>
            <PosEnumSelect
              style={{ width: '100%' }}
              value={paymentState?.value}
              disabled={disabled}
              valueOptionsContent={SALE_PAYMENT_STATE_TO_CID}
              onChange={(newState) => {
                if (newState && newState !== paymentState?.value) {
                  setValue('paymentState', posChangedField(newState));
                }
              }}
            />
          </PosFormField>
        )}
        <PosFormField label={<Content id={ContentId.Notes} />}>
          <PosTextArea
            style={{ width: '100%' }}
            value={internalNotes?.value ?? ''}
            disabled={disabled}
            onChange={(e: React.SyntheticEvent) => {
              const newNotes = (e.target as HTMLInputElement).value;
              if (newNotes && newNotes !== internalNotes?.value) {
                setValue('internalNotes', posChangedField(newNotes));
              }
            }}
            onBlur={() => {
              const newNotes = internalNotes?.value?.trim();
              if (newNotes && newNotes !== internalNotes?.value) {
                setValue('internalNotes', posChangedField(newNotes));
              }
            }}
          />
        </PosFormField>
      </SectionContent>
    </DetailSection>
  );
};
