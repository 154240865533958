import {
  ComponentProps,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { CurrencyFilterSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { usePurchasePaymentMethodSelector } from 'src/components/Selectors/PurchasePaymentMethodSelector';
import { Content, useContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import {
  FieldError,
  FieldLabel,
  FieldWrapper,
} from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { PAYMENT_METHOD_NAME_REGEX_TO_CONTENT_ID } from 'src/utils/constants/contentIdMaps';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import {
  CurrencyConversionOverride,
  CurrencyConversionOverrideRequest,
  PaymentMethodNameKnownRegex,
} from 'src/WebApiController';

export type CurrencyConversionOverrideDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onSave?: (data: CurrencyConversionOverrideRequest) => void | Promise<void>;
  onCancel?: () => void;
  currencyConversionOverride: CurrencyConversionOverrideRequest | undefined;
  otherExistingCurrencyConversionOverrides: CurrencyConversionOverride[];
};

export function CurrencyConversionOverrideDialog({
  onSave,
  onCancel,
  currencyConversionOverride,
  otherExistingCurrencyConversionOverrides,
  ...genericDialogProps
}: CurrencyConversionOverrideDialogProps): ReactElement | null {
  const requiredText = useContent(ContentId.Required);
  const duplicateText = useContent(ContentId.SameOverrideExists);
  const sameCurrencyCodeText = useContent(
    ContentId.DifferentCurrencyCodesRequired
  );
  const conversionRateGreaterThanZeroText = useContent(
    ContentId.ConversionRateMustBeGreaterThanZero
  );
  const allText = useContent(ContentId.All);

  const additionalOptions = useMemo(() => {
    return Object.values(PAYMENT_METHOD_NAME_REGEX_TO_CONTENT_ID).reduce(
      (options, contentId) => ({ ...options, [contentId]: contentId }),
      {} as Record<string, string>
    );
  }, []);

  const contentIdToPresetRegexIdMap = useMemo(() => {
    return Object.entries(PAYMENT_METHOD_NAME_REGEX_TO_CONTENT_ID).reduce(
      (map, [presetRegexId, contentId]) => ({
        ...map,
        [contentId]: presetRegexId,
      }),
      {} as Record<string, string>
    );
  }, []);

  const { posSelectProps: paymentMethodSelectProps } =
    usePurchasePaymentMethodSelector({
      additionalOptions,
    });

  const {
    control,
    register,
    reset,
    handleSubmit,
    watch,
    setError,
    setValue,
    getValues,
    clearErrors,
    formState: { isSubmitting, errors },
  } = useForm<CurrencyConversionOverrideRequest>({
    defaultValues: currencyConversionOverride,
  });

  /**
   * Return true if there are no validation errors
   */
  const validateInput = useCallback(
    (formData: CurrencyConversionOverrideRequest): boolean => {
      clearErrors();

      // Double check for required
      if (!formData.fromCurrencyCode) {
        setError('fromCurrencyCode', { message: requiredText });
        return false;
      }
      if (!formData.toCurrencyCode) {
        setError('toCurrencyCode', { message: requiredText });
        return false;
      }
      if (formData.conversionRate == null) {
        setError('conversionRate', { message: requiredText });
        return false;
      }
      if (!formData.conversionRateDate) {
        setError('conversionRateDate', { message: requiredText });
        return false;
      }

      // Don't allow conflicting currency conversion overrides
      if (
        otherExistingCurrencyConversionOverrides.some((c) => {
          const dateInput = new Date(formData.conversionRateDate);
          const dateExisting = new Date(c.conversionRateDate);

          return (
            c.fromCurrencyCode === formData.fromCurrencyCode &&
            c.toCurrencyCode === formData.toCurrencyCode &&
            c.purchasePaymentMethodId === formData.purchasePaymentMethodId &&
            dateInput.toDateString() === dateExisting.toDateString()
          );
        })
      ) {
        setError('fromCurrencyCode', { message: duplicateText });
        return false;
      }

      // Don't allow same currency code
      if (formData.fromCurrencyCode === formData.toCurrencyCode) {
        setError('fromCurrencyCode', { message: sameCurrencyCodeText });
        setError('toCurrencyCode', { message: sameCurrencyCodeText });
        return false;
      }

      // Don't allow zero
      if (formData.conversionRate <= 0) {
        setError('conversionRate', {
          message: conversionRateGreaterThanZeroText,
        });
        return false;
      }

      return true;
    },
    [
      clearErrors,
      conversionRateGreaterThanZeroText,
      duplicateText,
      otherExistingCurrencyConversionOverrides,
      requiredText,
      sameCurrencyCodeText,
      setError,
    ]
  );

  const onSaveHandler = useCallback(() => {
    if (!validateInput(getValues())) {
      return;
    }

    if (onSave) {
      handleSubmit(onSave)();
    }
  }, [getValues, handleSubmit, onSave, validateInput]);

  const currencyConversionOverrideId = watch('currencyConversionOverrideId');
  const conversionRate = watch('conversionRate');
  const purchasePaymentMethodId = watch('purchasePaymentMethodId');
  const paymentMethodNameKnownRegexId = watch('paymentMethodNameKnownRegexId');

  const paymentMethodSelectValue = useMemo(() => {
    if (paymentMethodNameKnownRegexId != null) {
      return PAYMENT_METHOD_NAME_REGEX_TO_CONTENT_ID[
        paymentMethodNameKnownRegexId
      ];
    }

    return purchasePaymentMethodId !== undefined
      ? String(purchasePaymentMethodId)
      : undefined;
  }, [paymentMethodNameKnownRegexId, purchasePaymentMethodId]);

  const paymentMethodSelectOnChange = useCallback(
    (value: string) => {
      if (
        Object.values(PAYMENT_METHOD_NAME_REGEX_TO_CONTENT_ID).includes(
          value as ContentId
        )
      ) {
        setValue(
          'paymentMethodNameKnownRegexId',
          contentIdToPresetRegexIdMap[value] as PaymentMethodNameKnownRegex
        );
        setValue('purchasePaymentMethodId', null);
      } else {
        const valueAsNumber = parseInt(value);
        if (value != null && !isNaN(valueAsNumber)) {
          setValue('purchasePaymentMethodId', valueAsNumber);
        } else {
          setValue('purchasePaymentMethodId', null);
        }
        setValue('paymentMethodNameKnownRegexId', null);
      }
    },
    [contentIdToPresetRegexIdMap, setValue]
  );

  useEffect(() => {
    reset(currencyConversionOverride);
  }, [currencyConversionOverride, reset]);

  return (
    <GenericDialog
      size="md"
      header={
        <Content
          id={
            currencyConversionOverrideId == null
              ? ContentId.AddOverrideFxRate
              : ContentId.EditOverrideFxRate
          }
        />
      }
      footer={
        <>
          <CancelButton disabled={isSubmitting} onClick={onCancel} />
          <Button
            variant={'regular'}
            disabled={isSubmitting}
            onClick={onSaveHandler}
          >
            <Content id={ContentId.Save} />
          </Button>
        </>
      }
      onClosed={() => {
        reset();
      }}
      {...genericDialogProps}
      onCancel={onCancel}
    >
      <FieldWrapper>
        <FieldLabel>
          <Content id={ContentId.From} />
        </FieldLabel>
        <Controller
          control={control}
          rules={{ required: requiredText }}
          name={`fromCurrencyCode`}
          render={({ field: { ...field }, fieldState }) => (
            <CurrencyFilterSelector
              hasErrors={Boolean(fieldState.error)}
              {...field}
              onChange={(e) => {
                clearErrors('fromCurrencyCode');
                clearErrors('toCurrencyCode');
                field.onChange(e);
              }}
            />
          )}
        />
        {errors.fromCurrencyCode && (
          <FieldError>{errors.fromCurrencyCode?.message}</FieldError>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <FieldLabel>
          <Content id={ContentId.To} />
        </FieldLabel>
        <Controller
          control={control}
          rules={{ required: requiredText }}
          name={`toCurrencyCode`}
          render={({ field: { ...field }, fieldState }) => (
            <CurrencyFilterSelector
              hasErrors={Boolean(fieldState.error)}
              {...field}
              onChange={(e) => {
                clearErrors('fromCurrencyCode');
                clearErrors('toCurrencyCode');
                field.onChange(e);
              }}
            />
          )}
        />
        {errors.toCurrencyCode && (
          <FieldError>{errors.toCurrencyCode?.message}</FieldError>
        )}
      </FieldWrapper>

      <FieldWrapper>
        <PosFormField
          errors={errors.conversionRate?.message}
          label={<Content id={ContentId.ConversionRate} />}
        >
          <PosTextField
            rootProps={{
              disabled: isSubmitting,
              state: getTextFieldState(errors.conversionRate),
            }}
            type="number"
            inputMode="decimal"
            disabled={isSubmitting}
            {...register('conversionRate', {
              required: true,
              valueAsNumber: true,
            })}
            value={conversionRate}
            onChange={(e) => {
              clearErrors('conversionRate');
              let value = parseFloat(e.target.value);
              if (isNaN(value) || value < 0) {
                value = 0;
              }
              setValue('conversionRate', value);
            }}
          />
        </PosFormField>
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel>
          <Content id={ContentId.OverrideDate} />
        </FieldLabel>
        <Controller
          name={`conversionRateDate`}
          rules={{ required: requiredText }}
          control={control}
          render={({ field: { ...field }, fieldState }) => (
            <DatePickerInput
              fieldError={fieldState.error}
              locale={getLocaleFromLanguageOrCurrent()}
              {...field}
              onDateChange={(d) => {
                clearErrors(field.name);
                setValue(field.name, d.toDateString());
              }}
            />
          )}
        />
        {errors.conversionRateDate && (
          <FieldError>{errors.conversionRateDate?.message}</FieldError>
        )}
      </FieldWrapper>

      <FieldWrapper>
        <FieldLabel>
          <Content id={ContentId.PaymentMethod} />
        </FieldLabel>
        <PosSelect
          className="full-width"
          value={paymentMethodSelectValue}
          hasErrors={Boolean(errors.purchasePaymentMethodId != null)}
          {...paymentMethodSelectProps}
          placeholderText={allText}
          onChange={paymentMethodSelectOnChange}
        />

        {errors.purchasePaymentMethodId && (
          <FieldError>{errors.purchasePaymentMethodId?.message}</FieldError>
        )}
      </FieldWrapper>
      <FieldWrapper>
        <WarningMessage
          message={<Content id={ContentId.AddOrUpdateFxOverrideWarning} />}
        />
      </FieldWrapper>
    </GenericDialog>
  );
}
