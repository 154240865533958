import { ReactNode } from 'react';
import { Content } from 'src/contexts/ContentContext/Content';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { UploadPredeliveryAction } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/purchaseTicketsFlow.utils';
import { ChevronUpIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { ListingActionType, TicketGroupInput } from 'src/WebApiController';

export const PredeliveryActionsDropdown = ({
  ticketGroup,
  disabled,
  onPredeliveryAction,
  triggerCmp,
}: {
  ticketGroup: Pick<TicketGroupInput, 'tickets'>;
  disabled?: boolean;
  onPredeliveryAction: (action: UploadPredeliveryAction) => void;
  triggerCmp?: ReactNode;
}) => {
  const hasBarcodes = ticketGroup.tickets?.value?.some((t) => t.barcode);
  const hasExternalIds = ticketGroup.tickets?.value?.some((t) => t.externalId);
  const hasTicketUrls = ticketGroup.tickets?.value?.some((t) => t.ticketUrl);
  const hasETickets = ticketGroup.tickets?.value?.some((t) => t.eTicket);
  const hasQRcodes = ticketGroup.tickets?.value?.some((t) => t.qrCode);
  return (
    <PosDropdown
      id="ticket-group-predelivery-dropdown"
      key="event-broadcast-actions-dropdown"
      trigger={
        triggerCmp || (
          <Button variant="outline" style={{ whiteSpace: 'nowrap' }}>
            <Stack gap="m" justifyContent="spaceBetween" alignItems="center">
              <Content id={ContentId.UploadPredelivery} />
              <ChevronUpIcon size={vars.iconSize.xs} withHoverEffect />
            </Stack>
          </Button>
        )
      }
      align="start"
      triggerProps={{
        disabled: disabled,
      }}
    >
      <PosDropdownItem
        id="ticket-group-upload-barcodes-dropdown-item"
        disabled={disabled}
        onClick={() =>
          onPredeliveryAction(ListingActionType.PredeliverBarcodes)
        }
      >
        <Content
          id={
            hasBarcodes ? ContentId.ReuploadBarcodes : ContentId.UploadBarcodes
          }
        />
      </PosDropdownItem>
      <PosDropdownItem
        id="ticket-group-upload-ticket-urls-dropdown-item"
        disabled={disabled}
        onClick={() =>
          onPredeliveryAction(ListingActionType.PredeliverTicketUrls)
        }
      >
        <Content
          id={
            hasTicketUrls
              ? ContentId.ReuploadTicketUrls
              : ContentId.UploadTicketUrls
          }
        />
      </PosDropdownItem>
      <PosDropdownItem
        id="ticket-group-upload-ticket-ids-dropdown-item"
        disabled={disabled}
        onClick={() =>
          onPredeliveryAction(ListingActionType.PredeliverExternalIds)
        }
      >
        <Content
          id={
            hasExternalIds
              ? ContentId.ReuploadTicketIds
              : ContentId.UploadTicketIds
          }
        />
      </PosDropdownItem>
      <PosDropdownItem
        id="ticket-group-upload-e-tickets-dropdown-item"
        disabled={disabled}
        onClick={() =>
          onPredeliveryAction(ListingActionType.PredeliverETickets)
        }
      >
        <Content
          id={
            hasETickets ? ContentId.ReuploadETickets : ContentId.UploadETickets
          }
        />
      </PosDropdownItem>
      <PosDropdownItem
        id="ticket-group-upload-qr-codes-dropdown-item"
        disabled={disabled}
        onClick={() => onPredeliveryAction(ListingActionType.PredeliverQRCode)}
      >
        <Content
          id={hasQRcodes ? ContentId.ReuploadQRCodes : ContentId.UploadQRCodes}
        />
      </PosDropdownItem>
    </PosDropdown>
  );
};
