import * as signalR from '@microsoft/signalr';
import { ILogger, LogLevel } from '@microsoft/signalr';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import { debounce, isEmpty } from 'lodash-es';
import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSet } from 'react-use';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ContentId } from 'src/utils/constants/contentId';
import { ContentIds } from 'src/utils/constants/contentIdDataMap';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { FormatContentIds } from 'src/utils/constants/formatContentIdDataMap';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Marketplace,
  Notification,
  NotificationClient,
  NotificationState,
  NotificationType,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { isContentId, useContentContext } from '../ContentContext';
import { fireNativeNotification } from './NativeNotification';

export class SignalRLogger implements ILogger {
  log(logLevel: LogLevel, message: string) {
    if (logLevel <= LogLevel.Debug) {
      return;
    }
    if (logLevel <= LogLevel.Information) {
      console.debug(message);
    } else if (logLevel <= LogLevel.Warning) {
      console.warn(message);
    } else if (logLevel <= LogLevel.Critical) {
      console.error(message);
    }
  }
}

export type NotificationFilter = {
  type?: Set<NotificationType>;
};

type INotificationsContext = {
  notifications: Notification[];
  notificationsIsLoading: boolean;
  notificationsIsInitialLoading: boolean;
  dismissNotification: (notificationId: number) => Promise<void>;
  setNotificationFilter: Dispatch<NotificationFilter>;
  /**
   * Whether a filter is set, but there are no results for the filter.
   */
  noResultsForNotificationFilter: boolean;
};

export const NotificationsContext = React.createContext<INotificationsContext>({
  notifications: [],
  notificationsIsLoading: false,
  notificationsIsInitialLoading: false,
  dismissNotification: async () => {},
  setNotificationFilter: () => {},
  noResultsForNotificationFilter: false,
});

export const useNotificationsContext = () => useContext(NotificationsContext);

export const NotificationsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { contentResolver, formattedContentResolver } = useContentContext();

  const hubConnection = useRef<signalR.HubConnection>();
  const [dismissedNotifications, { add: addDismissedNotification }] =
    useSet<number>();

  const [notificationFilter, setNotificationFilter] =
    useState<NotificationFilter>();

  const resolveContent = useCallback(
    (
      contentId: ContentId | FormatContentId,
      marketplace: Marketplace | null
    ): string | string[] | undefined => {
      return isContentId(contentId)
        ? contentResolver({ ...ContentIds[contentId] })
        : (formattedContentResolver(
            FormatContentIds[contentId].id,
            [marketplace ?? ''],
            FormatContentIds[contentId].defaultValue
          ) as string);
    },
    [contentResolver, formattedContentResolver]
  );

  const getNotificationsQuery = useQuery({
    queryKey: [
      'NotificationsClient.getActiveNotifications',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new NotificationClient(
        activeAccountWebClientConfig
      ).getActiveNotifications();
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('NotificationsClient.getActiveNotifications', error);
      },
    },
  });

  const undismissedNotifications = useMemo(() => {
    if (!getNotificationsQuery.data) {
      return [];
    }
    return getNotificationsQuery.data
      .filter((n) => !dismissedNotifications.has(n.notificationId))
      .sort((a, b) => b.createDateTime.localeCompare(a.createDateTime));
  }, [dismissedNotifications, getNotificationsQuery.data]);

  const filteredNotifications = useMemo(() => {
    return undismissedNotifications.filter((n) => {
      if (
        notificationFilter?.type &&
        !notificationFilter.type.has(n.notificationType)
      ) {
        return false;
      }
      return true;
    });
  }, [undismissedNotifications, notificationFilter]);

  const getNotificationById = useCallback(
    async (notificationId: number): Promise<Notification | null> => {
      if (!activeAccountWebClientConfig.activeAccountId) {
        return null;
      }
      return tryInvokeApi(
        async () => {
          return new NotificationClient(
            activeAccountWebClientConfig
          ).getNotification(notificationId);
        },
        (error: ErrorTypes) => {
          trackError('NotificationClient.getNotificationById', error, {
            notificationId,
          });
        }
      );
    },
    [activeAccountWebClientConfig, trackError]
  );

  const getNotificationsQueryDebounced = debounce(
    getNotificationsQuery.refetch,
    2000
  );

  useEffect(() => {
    if (!hubConnection.current) {
      hubConnection.current = new signalR.HubConnectionBuilder()
        .withUrl('/hubs/notification')
        .withAutomaticReconnect()
        .configureLogging(new SignalRLogger())
        .build();

      hubConnection.current.on('new', async (message) => {
        try {
          const messageList = JSON.parse(message);
          for (const m of messageList) {
            console.debug(
              'notificationcontext - got new notification',
              m,
              hubConnection
            );
            const notification = await getNotificationById(m.NotificationId);
            // TODO (POS-3544): Dismiss notification depending on NotificationState
            notification &&
              notification.notificationState == NotificationState.Active &&
              fireNativeNotification(notification, resolveContent);
          }
          await getNotificationsQueryDebounced();
        } catch (e) {
          console.error(
            'notificationcontext - malformed SignalR Message: ' + message
          );
          try {
            Sentry.captureException(e);
          } catch (err) {
            console.warn('Sentry log request failed', err);
          }
        }
      });

      hubConnection.current.start().catch(console.error);

      console.debug(
        'Creating new Notification connection with id: ' +
          hubConnection.current.connectionId
      );
    }

    // Close the connection before unmount
    return () => {
      if (
        hubConnection.current &&
        hubConnection.current.state === signalR.HubConnectionState.Connected
      ) {
        console.debug(
          'Stopping Notification connection with id: ' +
            hubConnection.current.connectionId
        );
        hubConnection.current.stop();
        hubConnection.current = undefined;
      }
    };
  }, [getNotificationById, getNotificationsQueryDebounced, resolveContent]);

  const dismissNotification = useCallback(
    async (notificationId: number) => {
      await tryInvokeApi(
        async () => {
          addDismissedNotification(notificationId);
          await new NotificationClient(
            activeAccountWebClientConfig
          ).dismissNotification(notificationId);
          await getNotificationsQueryDebounced();
        },
        (error: ErrorTypes) => {
          trackError('NotificationClient.dismissNotification', error, {
            notificationId,
          });
        }
      );
    },
    [
      activeAccountWebClientConfig,
      addDismissedNotification,
      getNotificationsQueryDebounced,
      trackError,
    ]
  );

  return (
    <NotificationsContext.Provider
      value={{
        notifications: filteredNotifications,
        notificationsIsLoading: getNotificationsQuery.isPending,
        notificationsIsInitialLoading: getNotificationsQuery.isLoading,
        dismissNotification,
        setNotificationFilter,
        noResultsForNotificationFilter:
          !isEmpty(notificationFilter) &&
          undismissedNotifications.length !== 0 &&
          filteredNotifications.length === 0,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
