import { formatInTimeZone } from 'date-fns-tz';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { vars } from 'src/core/themes';
import { Stack, Tooltip } from 'src/core/ui';
import { AdminHoldIcon } from 'src/svgs/AdminHoldIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { isSqlMaxDate, stringToUtcDate } from 'src/utils/dateTimeUtils';

import * as styles from './AdminHoldBadge.css';

export const AdminHoldBadge = ({
  adminHoldExpirationDate,
  adminHoldNotes,
  showDetailOnHover,
  iconOnlyMode,
}: {
  adminHoldExpirationDate: string | null;
  adminHoldNotes: string | null;
  showDetailOnHover?: boolean;
  iconOnlyMode?: boolean;
}) => {
  const holdTxt = useContent(ContentId.Hold);
  const { timeZone } = useSiteTimezoneContext();

  adminHoldExpirationDate =
    adminHoldExpirationDate == null ||
    isSqlMaxDate(stringToUtcDate(adminHoldExpirationDate))
      ? null
      : adminHoldExpirationDate;

  const content = iconOnlyMode ? (
    <div className={styles.iconContainer}>
      <AdminHoldIcon
        size={vars.iconSize.m}
        title={adminHoldExpirationDate || adminHoldNotes ? undefined : holdTxt}
      />
    </div>
  ) : (
    <div className={styles.badge}>
      <span style={{ color: vars.color.textBrand }}>
        <Content id={ContentId.OnHoldBroadcastDisabled} />
      </span>
    </div>
  );

  return showDetailOnHover ? (
    <Tooltip triggerContent={content}>
      {adminHoldExpirationDate || adminHoldNotes ? (
        <Stack direction="column" style={{ padding: vars.spacing['sm'] }}>
          {adminHoldExpirationDate && (
            <span>
              <span style={{ fontWeight: 600 }}>
                <Content id={ContentId.HeldUntil} />
                {': '}
              </span>
              {formatInTimeZone(
                stringToUtcDate(adminHoldExpirationDate),
                timeZone,
                'dd MMM yyyy, hh:mm a'
              )}
            </span>
          )}
          {adminHoldNotes && (
            <span>
              <span style={{ fontWeight: 600 }}>
                <Content id={ContentId.Notes} />
                {': '}
              </span>
              {adminHoldNotes}
            </span>
          )}
        </Stack>
      ) : (
        holdTxt
      )}
    </Tooltip>
  ) : (
    content
  );
};
