import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import * as styles from './SortableZoneAreaItem.css';

export const SortableZoneAreaItem = ({
  label,
  color,
  id,
}: {
  label: string;
  color: string;
  id: string;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      key={label}
      className={styles.sortableZoneAreaItemContainer}
    >
      <div
        className={styles.zoneGroupColorBox}
        style={{ backgroundColor: color }}
      />
      <span>{label}</span>
    </div>
  );
};
