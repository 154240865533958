import { useCallback, useEffect, useState } from 'react';

/**
 * Custom useKeyPress hook to only return whether a key is pressed or not
 * This is an alternative to the useKeyPress hook from react-use - it keeps re-rendering the component as long as the key is pressed
 */
export const useKeyPress = (targetKey: string) => {
  const [isPressed, setPressed] = useState(false);

  const downHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === targetKey) {
        setPressed(true);
      }
    },
    [targetKey]
  );

  const upHandler = useCallback(
    ({ key }: KeyboardEvent) => {
      if (key === targetKey) {
        setPressed(false);
      }
    },
    [targetKey]
  );
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isPressed;
};
