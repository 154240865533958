import { useBulkPricingActionsPermissions } from 'src/components/common/BulkActions/BulkPricingActions/useBulkPricingActionsPermissions';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export const LaunchUpdateListingSettings = ({
  onClick,
  disabled,
  bulkEditMode,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;

  bulkEditMode: 'general' | 'autoPricing';
}) => {
  const {
    hasUpdateListingPermission,
    hasAutoPricingFeature,
    hasEditPricingPermission,
  } = useBulkPricingActionsPermissions();

  if (bulkEditMode === 'general' && !hasUpdateListingPermission) {
    return null;
  }

  if (
    bulkEditMode === 'autoPricing' &&
    (!hasAutoPricingFeature || !hasEditPricingPermission)
  ) {
    return null;
  }

  return (
    <PosDropdownItem
      key={bulkEditMode ? 'AutoPricingSettings' : 'ListingSettings'}
      onClick={onClick}
      disabled={disabled}
    >
      {bulkEditMode === 'general' ? (
        <Stack gap="m" alignItems="center" width="full">
          <Content id={ContentId.EditGeneralSettings} />
        </Stack>
      ) : (
        <Content id={ContentId.AutopricingSettings} />
      )}
    </PosDropdownItem>
  );
};
