import signupVideo from 'src/assets/signup.mp4';
import { EmailForm, Footer, Titles } from 'src/components/SignUp';
import { InviteOnlyBanner } from 'src/components/SignUp/InviteOnlyBanner/InviteOnlyBanner';
import { SignUpValidator } from 'src/components/SignUp/SignUpValidator';
import { useSignUpContext } from 'src/contexts/SignUpContext/SignUpContext';
import { SiteZoomOption } from 'src/contexts/SiteTheme/SiteThemeContext';
import { Stack } from 'src/core/ui';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import {
  signupContent,
  signUpFooter,
  signupPage,
  video,
} from 'src/navigations/Routes/SignUp/SignUp.css';
import { UserSetting } from 'src/WebApiController';

export const SignUp = () => {
  const { hasSignedUp } = useSignUpContext();

  const { value: serverSiteZoomValue } = useServerUserSetting<SiteZoomOption>({
    id: UserSetting.SiteZoom,
    currentLoginUserOnly: true,
  });

  return (
    <SignUpValidator>
      <Stack direction="column" width="full" height="full">
        <video autoPlay muted playsInline className={video} preload="true">
          <source src={signupVideo} type="video/mp4" />
        </video>
        <div className={signupPage}>
          <Stack
            alignItems="center"
            justifyContent="center"
            height="full"
            width="full"
            direction="column"
            className={signupContent}
          >
            <Titles />
            {hasSignedUp ? <InviteOnlyBanner /> : <EmailForm />}
          </Stack>
          <div className={signUpFooter}>
            <Footer />
          </div>
        </div>
      </Stack>
    </SignUpValidator>
  );
};
