import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { FilterToolbarItem } from 'src/components/FilterToolbar';
import {
  CityMultiSelector,
  CountryMultiSelector,
  StateProvinceMultiSelector,
} from 'src/components/Selectors/LocationSelector';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { EntityWithTicketsQuery, Feature } from 'src/WebApiController';

export type LocationFilterQuery = Pick<
  EntityWithTicketsQuery,
  'countryCodes' | 'stateProvinceIds' | 'cityIds'
>;

export const useLocationFilter = <
  TQuery extends QueryWithViewMode & LocationFilterQuery,
>(): FilterToolbarItem[] => {
  const hasFeature = useUserHasFeature(Feature.FilterEventByLocation);
  const { tempQuery, setTempQuery } = useFilterQueryContext<TQuery>();

  const { countryCodes, stateProvinceIds, cityIds } = tempQuery;

  return useMemo<FilterToolbarItem[]>(() => {
    return hasFeature
      ? [
          {
            filterId: 'countryCodes',
            labelContentId: ContentId.Country,
            filterQueryKeys: ['countryCodes'],
            filterItem: (
              <CountryMultiSelector
                triggerProps={{ style: { width: '100%' } }}
                key="countryFilter"
                searchable
                values={countryCodes ?? []}
                onChange={(newCountryCodes) => {
                  const countryCodesSorted = newCountryCodes.sort();
                  const orgCountryCodesSorted = (countryCodes ?? []).sort();
                  const hasChange = !isEqual(
                    countryCodesSorted,
                    orgCountryCodesSorted
                  );

                  if (hasChange) {
                    setTempQuery({
                      ...tempQuery,
                      countryCodes: countryCodesSorted?.length
                        ? countryCodesSorted
                        : null,
                      stateProvinceIds: null,
                      cityIds: null,
                    });
                  }
                }}
              />
            ),
          },
          {
            filterId: 'stateProvinceIds',
            labelContentId: ContentId.StateProvince,
            filterQueryKeys: ['stateProvinceIds'],
            filterItem: (
              <StateProvinceMultiSelector
                triggerProps={{ style: { width: '100%' } }}
                searchable
                key="stateProvinceFilter"
                values={(stateProvinceIds ?? []).map(String)}
                countryCodes={countryCodes}
                onChange={(newStateProvinceIds) => {
                  const stateProvinceIdsSorted = newStateProvinceIds
                    .map(Number)
                    .sort() as number[];
                  const orgStateProvinceIdsSorted = (
                    stateProvinceIds ?? []
                  ).sort();
                  const hasChange = !isEqual(
                    stateProvinceIdsSorted,
                    orgStateProvinceIdsSorted
                  );

                  if (hasChange) {
                    setTempQuery({
                      ...tempQuery,
                      stateProvinceIds: stateProvinceIdsSorted,
                      cityIds: null,
                    });
                  }
                }}
              />
            ),
          },
          {
            filterId: 'cityIds',
            labelContentId: ContentId.City,
            filterQueryKeys: ['cityIds'],
            filterItem: (
              <CityMultiSelector
                triggerProps={{ style: { width: '100%' } }}
                key="cityFilter"
                searchable
                values={(cityIds ?? []).map(String)}
                countryCodes={countryCodes}
                stateProvinceIds={stateProvinceIds ?? []}
                onChange={(newCityIds) => {
                  const cityIdsSorted = newCityIds
                    .map(Number)
                    .sort() as number[];
                  const orgCityIdsSorted = (cityIds ?? []).sort();
                  const hasChange = !isEqual(cityIdsSorted, orgCityIdsSorted);

                  if (hasChange) {
                    setTempQuery({
                      ...tempQuery,
                      cityIds: cityIdsSorted,
                    });
                  }
                }}
              />
            ),
          },
        ]
      : [];
  }, [
    cityIds,
    countryCodes,
    hasFeature,
    setTempQuery,
    stateProvinceIds,
    tempQuery,
  ]);
};
