import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function InfoOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M12 2c5.51 0 10 4.49 10 10s-4.49 10-10 10S2 17.51 2 12 6.49 2 12 2zm0 1.667c-4.592 0-8.333 3.741-8.333 8.333 0 4.592 3.741 8.333 8.333 8.333 4.592 0 8.333-3.741 8.333-8.333 0-4.592-3.741-8.333-8.333-8.333zm0 6.2c.589 0 1.067.478 1.067 1.066v5.334a1.067 1.067 0 01-2.134 0v-5.334c0-.588.478-1.066 1.067-1.066zm0-3.2A1.067 1.067 0 1112 8.8a1.067 1.067 0 010-2.134z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
