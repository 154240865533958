import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const ShuffleIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper
      {...props}
      viewBox="0 0 24 24" // The viewbox here is important as it won't display properly without this specific value
    >
      <path
        d="M17.448 2.03362C17.8385 1.64315 18.4716 1.64302 18.8621 2.0334L21.4146 4.58483C22.1959 5.36584 22.1961 6.63239 21.4149 7.41355L18.8675 9.96094C18.477 10.3515 17.8438 10.3515 17.4533 9.96094C17.0628 9.57042 17.0628 8.93725 17.4533 8.54673L19 7H14.2361C13.8573 7 13.511 7.214 13.3416 7.55279L11.8954 10.4452L10.7699 8.22417L11.5528 6.65836C12.061 5.64201 13.0998 5 14.2361 5H19L17.4479 3.44791C17.0574 3.05738 17.0575 2.42415 17.448 2.03362Z"
        fill="inherit"
      />
      <path
        d="M17.448 14.0336C17.8385 13.6432 18.4716 13.643 18.8621 14.0334L21.4146 16.5848C22.1959 17.3658 22.1961 18.6324 21.4149 19.4136L18.8675 21.9609C18.477 22.3515 17.8438 22.3515 17.4533 21.9609C17.0628 21.5704 17.0628 20.9373 17.4533 20.5467L19 19H14.2361C13.0998 19 12.061 18.358 11.5528 17.3416L6.65836 7.55279C6.48897 7.214 6.1427 7 5.76393 7H3C2.44772 7 2 6.55228 2 6C2 5.44772 2.44772 5 3 5H5.76393C6.90025 5 7.93904 5.64201 8.44721 6.65836L13.3416 16.4472C13.511 16.786 13.8573 17 14.2361 17H19L17.4479 15.4479C17.0574 15.0574 17.0575 14.4241 17.448 14.0336Z"
        fill="inherit"
      />
      <path
        d="M8.12308 13.5178L9.24864 15.7388L8.44721 17.3416C7.93904 18.358 6.90025 19 5.76393 19H3C2.44772 19 2 18.5523 2 18C2 17.4477 2.44772 17 3 17H5.76393C6.1427 17 6.48897 16.786 6.65836 16.4472L8.12308 13.5178Z"
        fill="inherit"
      />
    </SvgWrapper>
  );
};
