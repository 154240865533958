import Flexsearch from 'flexsearch';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import {
  getContent,
  IContentContext,
  isContentId,
  useContentContext,
} from 'src/contexts/ContentContext';

/**
 * Building index for FlexSearch is expensive (5-10s for 1M entries), so we defer building index until the select expands
 *
 * If number of items is greater than this threshold, we will defer building index until the select expands (see onOpenChange)
 * to avoid blocking the UI. Otherwise we will build the index immediately (see useEffect hook).
 */
const DEFER_BUILD_INDEX_ITEMS_THRESHOLD = 100_000;

const REVERSE_TOKENIZE_ITEM_THRESHOLD = 100_000;
const FULL_TOKENIZE_ITEM_THRESHOLD = 10_000;

const buildFlexSearchIndex = (
  valueOptionsContent: Record<string, string>,
  contentContext: IContentContext
) => {
  const itemCount = Object.keys(valueOptionsContent).length;

  const index = new Flexsearch.Index({
    preset: 'performance',
    tokenize:
      itemCount < FULL_TOKENIZE_ITEM_THRESHOLD
        ? 'full'
        : itemCount < REVERSE_TOKENIZE_ITEM_THRESHOLD
        ? 'reverse'
        : 'forward',
  });

  Object.entries(valueOptionsContent).forEach(([key, val]) => {
    const value = isContentId(val) ? getContent(val, contentContext) : val;
    index.add(key, value);
  });
  return index;
};

/**
 *
 * @param valueOptionsContent
 * @param enabled
 * @param isOpen is true when the select is expanded (ready to use)
 */
export function useFlexSearchIndex(
  valueOptionsContent: Record<string, string>,
  enabled?: boolean,
  isOpen?: boolean
) {
  const contentContext = useContentContext();

  const [flexSearchIndex, setFlexSearchIndex] =
    useState<Flexsearch.Index | null>(null);

  useEffect(() => {
    setFlexSearchIndex(null);
    // If the number of items is less than the threshold, build the index immediately
    if (
      Object.keys(valueOptionsContent).length <=
        DEFER_BUILD_INDEX_ITEMS_THRESHOLD ||
      isOpen
    ) {
      if (enabled) {
        const index = buildFlexSearchIndex(valueOptionsContent, contentContext);
        setFlexSearchIndex(index);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, valueOptionsContent, contentContext]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedBuildSearchIndex = useCallback(
    debounce(() => {
      const index = buildFlexSearchIndex(valueOptionsContent, contentContext);
      setFlexSearchIndex(index);
    }, 300),
    [valueOptionsContent, contentContext]
  );

  useEffect(() => {
    // If the number of items is greater than the threshold, build the index when the select expands
    if (
      Object.keys(valueOptionsContent).length >
      DEFER_BUILD_INDEX_ITEMS_THRESHOLD
    ) {
      if (isOpen) {
        if (enabled && !flexSearchIndex) {
          debouncedBuildSearchIndex(); // debounce to avoid blocking the UI
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, valueOptionsContent]);

  return {
    flexSearchIndex,
  };
}
