import { useNavigate } from 'react-router-dom';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { clearIDBCache } from 'src/core/utils/createIndexDBPersister';
import { useNetworkState } from 'src/hooks/useNetworkState';
import { IconsFill, NotifyIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './SomethingWentWrong.css';

export const SomethingWentWrong = ({
  header,
  message,
  messages,
}: {
  header?: React.ReactNode;
  message?: React.ReactNode;
  messages?: React.ReactNode[];
}) => {
  const navigate = useNavigate();
  const isOnline = useNetworkState();

  const allMessages = [
    message ?? (
      <Content
        id={
          isOnline
            ? ContentId.Error_InternalServerError_GenericMessage
            : ContentId.Error_BadGatewayError_GenericMessage
        }
      />
    ),
    ...(messages ?? []),
  ];
  return (
    <div className={styles.errorMessageWrapper}>
      <h4 className={styles.errorHeader}>
        <NotifyIcon fill={IconsFill.textNegative} size={vars.iconSize.l} />
        {header ?? (
          <Content
            id={
              isOnline
                ? ContentId.Error_InternalServerError_Header
                : ContentId.Error_BadGatewayError_Header
            }
          />
        )}
      </h4>
      {allMessages.map((message, i) => (
        <div key={i} className={styles.errorMessage}>
          {message}
        </div>
      ))}
      <div className={styles.errorMessage}>
        <Button
          variant={'regular'}
          onClick={() => {
            // Clear local-storage in case bad/empty results stored in local-storage
            clearIDBCache();
            navigate(0);
          }}
        >
          <Content id={ContentId.Retry} />
        </Button>
      </div>
    </div>
  );
};
