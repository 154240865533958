import { isEmpty } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Modal as RSModal } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, SimpleTable } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { EventScoreUpdate } from 'src/modals/EventPricingSeatMap/EventPricingSeatMapBody';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { getMedianScoreForSection } from 'src/utils/seatScoreUtils';
import { SectionInfo, SectionScoreOverride } from 'src/WebApiController';

import { ChangeRowScoreDialog } from '../ChangeRowScoreDialog';
import { rowScoreOverrideFilter } from '../ChangeRowScoreDialog/ChangeRowScoreBodyMultiSection';
import * as styles from './ChangeSectionScoreDialog.css';

export type ChangeSectionScoreDialogProps = ComponentProps<typeof RSModal> & {
  selectedSection: SectionInfo | null;
  configPayload: string | null;
  surroundingSections: SectionInfo[];
  scoreOverrides?: SectionScoreOverride[] | null;
  onOkay: (newScore: number, sectionId: number) => void;
  onCancel: () => void;
  onSubmitSectionScoreChange: (updates: EventScoreUpdate) => Promise<void>;
};

export function ChangeSectionScoreDialog({
  selectedSection,
  configPayload,
  surroundingSections,
  scoreOverrides,
  onOkay,
  onCancel,
  onSubmitSectionScoreChange,
  ...rest
}: ChangeSectionScoreDialogProps) {
  const existingScore = useMemo(
    () =>
      selectedSection &&
      getMedianScoreForSection(selectedSection.id, scoreOverrides ?? []),
    [scoreOverrides, selectedSection]
  );

  const changeRowScoreDialog = useBasicDialog();

  const [score, setScore] = useState(existingScore ?? undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (score == null && existingScore != null) {
      setScore(existingScore);
    }
  }, [existingScore, score]);

  const getSurroundingSections = useCallback(() => {
    return (
      <SimpleTable.Table>
        <SimpleTable.Thead>
          <SimpleTable.Tr>
            <SimpleTable.Th>
              <Content id={ContentId.Section} />
            </SimpleTable.Th>
            <SimpleTable.Th>
              <Content id={ContentId.Score} />
            </SimpleTable.Th>
          </SimpleTable.Tr>
        </SimpleTable.Thead>
        <SimpleTable.Tbody>
          {surroundingSections
            .sort((s1, s2) => s1.name.localeCompare(s2.name))
            .map((s, idx) => {
              const score = getMedianScoreForSection(
                s.id,
                scoreOverrides ?? []
              );
              return (
                <SimpleTable.Tr
                  key={`${s.id} - ${idx}`}
                  title={`${s.id} - ${idx}`}
                >
                  <SimpleTable.Td>{s.name}</SimpleTable.Td>
                  <SimpleTable.Td>
                    {score != null ? roundToPrecision(score, 4) : null}
                  </SimpleTable.Td>
                </SimpleTable.Tr>
              );
            })}
        </SimpleTable.Tbody>
      </SimpleTable.Table>
    );
  }, [scoreOverrides, surroundingSections]);

  const onRowSeatScoreChanged = useCallback(
    (
      newScoreOverrides: SectionScoreOverride[],
      newConfigPayload: string | null
    ) => {
      onSubmitSectionScoreChange({
        newScoreOverrides,
        newConfigPayload: newConfigPayload ?? undefined,
      }).then(() => {
        changeRowScoreDialog.closeDialog();
      });
    },
    [onSubmitSectionScoreChange, changeRowScoreDialog]
  );

  const rows = useMemo(
    () =>
      scoreOverrides?.filter(
        rowScoreOverrideFilter([selectedSection?.specRow?.id ?? 0])
      ) ?? [],
    [scoreOverrides, selectedSection?.specRow?.id]
  );

  if (!selectedSection) {
    return null;
  }

  return (
    <>
      <GenericDialog
        {...rest}
        size="m"
        header={<Content id={ContentId.ChangeSectionScore} />}
        onOpened={() => {
          setIsLoading(false);
          setScore(undefined);
        }}
        onKeyUp={(e) => {
          if (!isLoading && e.key === 'Enter' && score != null) {
            setIsLoading(true);
            onOkay(score, selectedSection.id);
          }
        }}
        footer={
          <>
            <Button
              className={styles.cancelButton}
              onClick={onCancel}
              disabled={isLoading}
            >
              <Content id={ContentId.Cancel} />
            </Button>
            {!isEmpty(rows) && (
              <Button
                variant="outline"
                onClick={() => {
                  // We have to pick the score that was input
                  if (score != null) {
                    setIsLoading(true);
                    onOkay(score, selectedSection.id);
                    changeRowScoreDialog.launchDialog();
                  }
                }}
                disabled={isLoading || score == null}
              >
                <Content id={ContentId.EditRowScore} />
              </Button>
            )}
            <Button
              disabled={isLoading || score == null}
              onClick={() => {
                if (score != null) {
                  setIsLoading(true);
                  onOkay(score, selectedSection.id);
                }
              }}
            >
              <Content id={ContentId.Save} />
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        <div className={styles.changeSectionScoreDialogContainer}>
          <div
            className={styles.selectedSectionContainer}
            title={`${selectedSection.id}`}
          >
            <PosFormField
              label={<Content id={ContentId.Section} />}
              style={{ flexBasis: 'max-content' }}
            >
              <div className={styles.selectedSectionNameContainer}>
                {selectedSection.name}
              </div>
            </PosFormField>
            <PosFormField label={<Content id={ContentId.Score} />}>
              <PosTextField
                disabled={isLoading}
                value={score != null ? roundToPrecision(score, 4) : ''}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                min={0}
                max={100}
                type="number"
                inputMode="numeric"
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  if (value < 0 || value > 100) return;
                  setScore(value);
                }}
              />
            </PosFormField>
          </div>
          <PosFormField
            label={<Content id={ContentId.SimilarSections} />}
            className={styles.similarSectionsContainer}
          >
            <div className={styles.similarSectionsContent}>
              {getSurroundingSections()}
            </div>
          </PosFormField>
        </div>
      </GenericDialog>
      <ChangeRowScoreDialog
        {...changeRowScoreDialog.dialogProps}
        selectedSection={selectedSection}
        configPayload={configPayload}
        scoreOverrides={scoreOverrides}
        onOkay={onRowSeatScoreChanged}
        onCancel={changeRowScoreDialog.closeDialog}
      />
    </>
  );
}
