import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { SimpleTable } from 'src/core/ui';
import { useEventMarketplaceSettings } from 'src/hooks/useEventMarketplaceSettings';
import { MultiDetailSection } from 'src/modals/common';
import { BulkEditListingGroupSettingsFields } from 'src/modals/GroupListings/components/groupingTypes';
import { ContentId } from 'src/utils/constants/contentId';
import { compareMarketplace } from 'src/utils/eventWithDataUtils';
import { isMarketplaceActive } from 'src/utils/userUtils';
import { Event, Marketplace } from 'src/WebApiController';

import { MarketplaceDisplay } from './MarketplaceDisplay';
import * as styles from './MarketplaceDisplay.css';

/**
 * Get marketplace id from marketplace enum.
 * This assumes the enum order is aligned with the Marketplace.Marketplace table
 */
const getMarketplaceIdFromMarketplace = (marketplace: Marketplace) => {
  let marketplaceId = 0;
  Object.values(Marketplace).forEach((mp, index) => {
    if (mp === marketplace) {
      marketplaceId = index + 1;
    }
  });
  return marketplaceId;
};

type BulkEditListingGroupSettingMarketplaceBroadcastProps = {
  event?: Event;
};

export const BulkEditListingGroupSettingsMarketplaceBroadcast = ({
  event,
}: BulkEditListingGroupSettingMarketplaceBroadcastProps) => {
  const { watch, setValue } =
    useFormContext<BulkEditListingGroupSettingsFields>();

  const marketplaceSettings = watch('marketplaceSettings');

  const { eventMarketplaceSettings, isUKEvent, isInternationalEvent } =
    useEventMarketplaceSettings(event);

  const onListingBroadcastToggle = useCallback(
    (isSelected: boolean, isBroadcasting: boolean, mp: Marketplace) => {
      const marketplaceId = getMarketplaceIdFromMarketplace(mp);
      const copied = { ...marketplaceSettings?.listingMarketplaceSettings };

      if (isSelected) {
        copied[marketplaceId] = {
          listingBroadcastStateId: isBroadcasting ? 2 : 1,
        };
      } else {
        delete copied[marketplaceId];
      }

      setValue('marketplaceSettings', { listingMarketplaceSettings: copied });
    },
    [marketplaceSettings, setValue]
  );

  const marketplacesSelected = useMemo(() => {
    const selected: Marketplace[] = [];
    const settings = marketplaceSettings?.listingMarketplaceSettings ?? [];
    const includedIds = Object.keys(settings);
    Object.values(Marketplace).forEach((mp, index) => {
      if (includedIds.includes(index + 1 + '')) {
        selected.push(mp);
      }
    });
    return selected;
  }, [marketplaceSettings]);

  const marketplaceToStateId = useMemo(() => {
    const marketplaceToId = {} as Record<Marketplace, number>;
    const settings = marketplaceSettings?.listingMarketplaceSettings;
    const includedIds = settings != null ? Object.keys(settings) : [];
    Object.values(Marketplace).forEach((mp, index) => {
      const key = index + 1 + '';
      if (includedIds.includes(key) && settings != null) {
        marketplaceToId[mp] = settings[key].listingBroadcastStateId;
      }
    });
    return marketplaceToId;
  }, [marketplaceSettings]);

  return (
    <MultiDetailSection
      name={<Content id={ContentId.Broadcast} />}
      extraContent={[]}
    >
      <SimpleTable.Table>
        <SimpleTable.Thead>
          <SimpleTable.Tr>
            <SimpleTable.Th
              className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
            ></SimpleTable.Th>
            <SimpleTable.Th
              className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
            >
              <Content id={ContentId.Merchant} />
            </SimpleTable.Th>
            <SimpleTable.Th
              className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
            >
              <Content id={ContentId.Broadcast} />
            </SimpleTable.Th>
            <SimpleTable.Th
              className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
            >
              <Content id={ContentId.Status} />
            </SimpleTable.Th>
          </SimpleTable.Tr>
        </SimpleTable.Thead>
        <SimpleTable.Tbody>
          {eventMarketplaceSettings
            .sort((a, b) => compareMarketplace(a.mkp, b.mkp))
            .flatMap((mk) => {
              const isSelected = marketplacesSelected.includes(mk.mkp);
              const isActive = marketplaceToStateId[mk.mkp] === 2;

              if (mk.mkp === Marketplace.StubHub) {
                return [
                  <MarketplaceDisplay
                    key={`${mk}-${false}`}
                    marketplace={mk.mkp}
                    marketplaceActive={isMarketplaceActive(mk)}
                    isActive={isActive}
                    isSelected={isSelected}
                    onListingBroadcastToggle={onListingBroadcastToggle}
                    isLoading={false}
                    isUKEvent={isUKEvent}
                  />,
                  <MarketplaceDisplay
                    key={`${mk}-${true}`}
                    marketplace={mk.mkp}
                    marketplaceActive={isMarketplaceActive(mk)}
                    isViagogo
                    isActive={isActive}
                    isSelected={isSelected}
                    onListingBroadcastToggle={onListingBroadcastToggle}
                    isLoading={false}
                  />,
                ];
              }

              if (!event || !isInternationalEvent) {
                return [
                  <MarketplaceDisplay
                    key={`${mk}-${false}`}
                    marketplace={mk.mkp}
                    marketplaceActive={isMarketplaceActive(mk)}
                    isActive={isActive}
                    isSelected={isSelected}
                    onListingBroadcastToggle={onListingBroadcastToggle}
                    isLoading={false}
                  />,
                ];
              }

              // Only support Stubhub/Viagogo for international events
              return [];
            })}
        </SimpleTable.Tbody>
      </SimpleTable.Table>
    </MultiDetailSection>
  );
};
