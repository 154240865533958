import { ComponentProps, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { AdGroupEventSelector } from 'src/components/Selectors/AdGroupEventSelector';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { PillItemProps } from 'src/core/ui/PillList/PillItem';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { EventIcon } from 'src/svgs/EventIcon';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import {
  EmptySelectionKey,
  getEventDisplayText,
  getTopLevelCategoryName,
} from 'src/utils/adGroupUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { getPerformerAndVenueForEvent } from 'src/utils/eventWithDataUtils';
import { EventWithData } from 'src/WebApiController';

import { BodySectionTitle, FieldWrapper } from '../common';
import * as styles from './AdGroupCreate.css';
import { BodySectionBody } from './AdGroupCreate.styled';
import { AdGroupSelectError } from './AdGroupSelectError';

export type EventSectionInput = {
  event: EventWithData | null | undefined;
};

export interface EventSectionProps {
  onSelectEvent: (event: EventWithData | null | undefined) => void;
  performerId?: number;
  genreId?: number;
  event: EventWithData | null | undefined;
  error?: string;
}

export const EventSection = ({
  onSelectEvent,
  performerId,
  genreId,
  event,
  error,
}: EventSectionProps) => {
  const addEventDialog = useBasicDialog();

  const onAddEventSave = useCallback(
    (event: EventWithData | null | undefined) => {
      onSelectEvent(event);
      addEventDialog.closeDialog();
    },
    [onSelectEvent, addEventDialog]
  );

  const onAddEventCancel = useCallback(() => {
    addEventDialog.closeDialog();
  }, [addEventDialog]);

  const onAddEvent = useCallback(() => {
    addEventDialog.launchDialog();
  }, [addEventDialog]);

  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const contentNA = useContent(ContentId.NA);

  const pills = useMemo(() => {
    const pills = [] as PillItemProps[];
    if (event !== undefined) {
      const { venue } = getPerformerAndVenueForEvent(event?.event, catalogData);

      pills.push({
        value: 'event',
        display: event ? getEventDisplayText(event.event, venue) : contentNA,
        icon: <EventIcon />,
        onDelete: () => {
          onSelectEvent(undefined);
        },
      });
    }

    return pills;
  }, [catalogData, contentNA, event, onSelectEvent]);

  return (
    <>
      <div className={styles.bodySection}>
        <BodySectionTitle>
          <Content id={ContentId.Event} />
        </BodySectionTitle>
        {event !== undefined ? (
          <BodySectionBody>
            <PillList pills={pills} />
          </BodySectionBody>
        ) : (
          <BodySectionBody>
            <Button variant={'link'} onClick={onAddEvent}>
              <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
              <Content id={ContentId.AddEvent} />
            </Button>
            <AdGroupSelectError error={error} />
          </BodySectionBody>
        )}
      </div>
      {addEventDialog.dialogProps.isOpen && (
        <AddEventDialog
          {...addEventDialog.dialogProps}
          performerId={performerId}
          genreId={genreId}
          onSave={onAddEventSave}
          onCancel={onAddEventCancel}
        />
      )}
    </>
  );
};

export type AddEventFieldValues = {
  event: EventWithData | null | undefined;
};

export type AddEventDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onSave: (event: EventWithData | null | undefined) => void;
  onCancel: () => void;
  performerId?: number;
  genreId?: number;
};

export const AddEventDialog = ({
  onSave,
  onCancel,
  performerId,
  genreId,
  ...genericDialogProps
}: AddEventDialogProps) => {
  const addEventForm = useForm<AddEventFieldValues>({
    defaultValues: {
      event: undefined,
    },
  });

  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const setEvent = useCallback(
    (eventId: string) => {
      if (!catalogData) {
        return;
      }
      const event =
        eventId == EmptySelectionKey
          ? null
          : catalogData.events[parseInt(eventId, 10)];
      addEventForm.clearErrors('event');
      addEventForm.setValue('event', event);
    },
    [addEventForm, catalogData]
  );

  const event = addEventForm.watch('event');

  const onSubmit = useCallback(() => {
    onSave(event);
  }, [event, onSave]);

  const handleEventFilter = (event: EventWithData | null | undefined) => {
    if (performerId && event?.event.perfId != performerId) {
      return false;
    }
    if (genreId && event?.event.genre != getTopLevelCategoryName(genreId)) {
      return false;
    }
    return true;
  };

  return (
    <GenericDialog
      size="xl"
      header={<Content id={ContentId.Event} />}
      footer={
        <>
          <CancelButton onClick={onCancel} />
          <Button
            variant={'regular'}
            onClick={addEventForm.handleSubmit(onSubmit)}
          >
            <Content id={ContentId.OK} />
          </Button>
        </>
      }
      onClosed={() => {
        addEventForm.reset();
      }}
      {...genericDialogProps}
      onCancel={onCancel}
    >
      <FieldWrapper>
        <PosFormField label={<Content id={ContentId.Event} />}>
          <AdGroupEventSelector
            filterCallback={handleEventFilter}
            value={
              event === null
                ? EmptySelectionKey
                : event?.event.viagId?.toString()
            }
            onChange={(eventId) => {
              setEvent(eventId);
            }}
            style={{ width: '100%' }}
          />
        </PosFormField>
      </FieldWrapper>
    </GenericDialog>
  );
};
