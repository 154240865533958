import { useMemo } from 'react';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useAvailableAutopricingModes } from 'src/hooks/useAvailableAutopricingModes';
import { WarningOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';

import { AutoPricingSettingsInput } from '../BulkEditAutoPricingSettingsDialog.types';
import { useAutoPricingSettings } from '../useBulkAutoPricingSettings';
import { AutoPricingEnabledSetting } from './AutoPricingEnabled';
import * as styles from './AutoPricingSettings.css';
import { PricingAdvancedSettings } from './PricingAdvancedSettings';
import { PricingAgainst } from './PricingAgainst';
import { PricingProtection } from './PricingProtection';
import { PricingRestriction } from './PricingRestriction';
import { PricingSetting } from './PricingSetting';
import { AutoPricingStrategySetting } from './PricingStrategy';

type AutoPricingSettingsProps = {
  settingsInput: AutoPricingSettingsInput;
};

export const AutoPricingSettings: React.FC<AutoPricingSettingsProps> = ({
  settingsInput,
}) => {
  const settingContent = useContent(ContentId.Settings);
  const details = useContent(ContentId.Details);
  const status = useContent(ContentId.Status);

  const {
    compListingMode,
    undercutMode,
    compListingOnlyForSelectedSectionsEnabled,
  } = useAutoPricingSettings();

  const { activeConfigOverride, venueMapsByScoreModelQuery } =
    useEventMapContext();

  const defaultScoreOverrides = useMemo(() => {
    // In the backend, if AVG scores are missing, then we always fallback to MVG, even if AVG is explicitly selected
    return (
      venueMapsByScoreModelQuery?.data?.AdvancedVenueGeometry?.sectionScores ??
      venueMapsByScoreModelQuery?.data?.MergedVenueGeometry?.sectionScores ??
      venueMapsByScoreModelQuery?.data?.VenueGeometry?.sectionScores
    );
  }, [
    venueMapsByScoreModelQuery?.data?.AdvancedVenueGeometry?.sectionScores,
    venueMapsByScoreModelQuery?.data?.MergedVenueGeometry?.sectionScores,
    venueMapsByScoreModelQuery?.data?.VenueGeometry?.sectionScores,
  ]);

  const scores = getCompleteEventConfigScoreOverrides(
    defaultScoreOverrides,
    activeConfigOverride?.scoreOverrides,
    false
  );

  const {
    outlierModes,
    outlierModesDisabled,
    compListingModes,
    compListingModesDisabled,
  } = useAvailableAutopricingModes(
    true,
    undercutMode?.value,
    compListingMode?.value,
    Boolean(scores?.some((s) => s.score)),
    false,
    false,
    compListingOnlyForSelectedSectionsEnabled?.value
  );

  const hasConflict = useMemo(
    () => Object.values(settingsInput).some(({ hasConflict }) => hasConflict),
    [settingsInput]
  );

  return (
    <Stack
      direction="column"
      gap="s"
      alignItems="start"
      width="full"
      className={styles.container}
    >
      {hasConflict && (
        <Stack
          width="full"
          direction="row"
          gap="m"
          className={styles.conflictAlert}
        >
          <WarningOutlineIcon size={vars.iconSize.l} />
          <Content id={ContentId.PricingSettingsListingsConflictWarning} />
        </Stack>
      )}
      <PricingSetting
        header={settingContent}
        detail={details}
        status={status}
        isHeader
      />
      <AutoPricingStrategySetting />
      <AutoPricingEnabledSetting origin={settingsInput} />
      <PricingAgainst
        origin={settingsInput}
        compListingModes={compListingModes}
        compListingModesDisabled={compListingModesDisabled}
      />
      <PricingProtection origin={settingsInput} />
      <PricingRestriction origin={settingsInput} />
      <PricingAdvancedSettings
        origin={settingsInput}
        outlierModes={outlierModes}
        outlierModesDisabled={outlierModesDisabled}
      />
    </Stack>
  );
};
