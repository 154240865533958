import { Content } from 'src/contexts/ContentContext';
import { MultiDetailSection } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { ListingDetails } from 'src/WebApiController';

import { DuplicateListingSection } from '../DuplicateListingSection';
import { EnableNativeSyncSection } from '../EnableNativeSyncSection';
import { ProofOfPurchaseSection } from '../ProofOfPurchaseSection';
import { MarketplaceTable } from './MarketplaceTable';
import { useGetMarketplaceFailures } from './useGetMarketplaceFailures';

export const MarketplaceBroadcastSection = ({
  listing,
  disabled,
}: {
  listing: ListingDetails;
  disabled?: boolean;
}) => {
  const sections = [
    <EnableNativeSyncSection key="1" listing={listing} disabled={disabled} />,
    <ProofOfPurchaseSection key="2" listing={listing} />,
    <DuplicateListingSection key="3" listing={listing} />,
  ];
  const marketplaceFailures = useGetMarketplaceFailures(listing);
  return (
    <>
      <MultiDetailSection
        name={<Content id={ContentId.Broadcast} />}
        extraContent={sections}
      >
        <MarketplaceTable
          disabled={disabled}
          listing={listing}
          marketplaceFailures={marketplaceFailures}
        />
      </MultiDetailSection>
    </>
  );
};
