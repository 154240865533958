import React, { useCallback, useState } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { CheckIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  ActivityLogClient,
  ApiException,
  SaleClient,
  SaleDetails,
} from 'src/WebApiController';

export const LaunchMarkAsFulfilled = ({
  saleId,
  marketplaceSaleId,
  variant,
  iconOnlyMode,
  disabled,
}: {
  saleId: number;
  marketplaceSaleId?: string | null;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<SaleDetails>();

  const { launchDialog, closeDialog, dialogProps } = useBasicDialog();

  const markAsFulfilled = useCallback(() => {
    setIsLoading(true);
    tryInvokeApi(
      async () => {
        const client = new SaleClient(activeAccountWebClientConfig);

        const result = await client.markAsFulfilled(saleId);
        if (!isSuccess(result)) {
          showErrorDialog(
            'SaleClient.markAsFulfilled',
            {
              message: result.message ?? genericError,
              status: result.status!,
            } as ApiException,
            {
              trackErrorData: { saleId },
            }
          );
        } else {
          await setActivePosEntity(saleId, marketplaceSaleId, true);
        }
      },
      (error) => {
        showErrorDialog('SaleClient.markAsFulfilled', error, {
          trackErrorData: { saleId },
        });
      },
      () => {
        setIsLoading(false);
        closeDialog();
      }
    );
  }, [
    activeAccountWebClientConfig,
    closeDialog,
    genericError,
    marketplaceSaleId,
    saleId,
    setActivePosEntity,
    showErrorDialog,
  ]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      new ActivityLogClient(activeAccountWebClientConfig).trackEntityView(
        saleId,
        ActionOutboxEntityType.Sale
      );
      launchDialog();
    },
    [saleId, launchDialog, activeAccountWebClientConfig]
  );

  return (
    <div>
      <ButtonWithIcon
        onClick={onLaunchDialog}
        disabled={disabled || isLoading}
        icon={
          <CheckIcon
            withHoverEffect
            fill={
              !iconOnlyMode && variant == 'regular'
                ? IconsFill.textInverted
                : IconsFill.textBrand
            }
            size={vars.iconSize.m}
          />
        }
        iconOnlyMode={iconOnlyMode}
        variant={variant}
        textContentId={ContentId.MarkAsFulfilled}
      />
      <ConfirmDialog
        {...dialogProps}
        disabled={isLoading}
        isLoading={isLoading}
        headerText={<Content id={ContentId.MarkAsFulfilled} />}
        bodyText={<Content id={ContentId.MarkAsFulfilledWarning} />}
        onOkay={markAsFulfilled}
      />
    </div>
  );
};
