import { useCallback, useContext } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { Button, Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { DeleteIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { ApiException, ListingClient, Permission } from 'src/WebApiController';

export const LaunchDeleteSeatSaver = ({
  listingId,
  disabled,
  setIsLoading,
}: {
  listingId: number;
  disabled?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
}) => {
  const { closeModal } = useContext(ModalContext);
  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const hasSeatSaverPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_ManageSeatSaver,
    Permission.Inventory_ManageSeatSaverCreatedBy
  );
  const {
    refreshCatalog,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const dialogProps = useBasicDialog();

  const onDeleteSeatSaver = useCallback(async () => {
    setIsLoading?.(true);
    dialogProps.closeDialog();

    await tryInvokeApi(
      async () => {
        const r = await new ListingClient(
          activeAccountWebClientConfig
        ).deleteSeatSaver(listingId);

        if (isSuccess(r)) {
          await refreshExpandedListItems(); // Because we're deleting - this will need to be cleared from the table
          refreshCatalog();
          closeModal(true);
        } else {
          showErrorDialog(
            'ListingClient.deleteSeatSaver',
            {
              message: r.message ?? genericError,
              status: r.status!,
            } as ApiException,
            {
              trackErrorData: {
                listingId,
              },
            }
          );
        }
      },
      (error) => {
        showErrorDialog('ListingClient.deleteSeatSaver', error);
      },
      () => setIsLoading?.(false)
    );
  }, [
    activeAccountWebClientConfig,
    closeModal,
    dialogProps,
    genericError,
    listingId,
    refreshCatalog,
    refreshExpandedListItems,
    setIsLoading,
    showErrorDialog,
  ]);

  if (!hasSeatSaverPermission) {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        textColor="error"
        onClick={() => dialogProps.launchDialog()}
        disabled={disabled}
      >
        <Stack gap="m">
          <DeleteIcon withHoverEffect fill={IconsFill.currentColor} />
          <Content id={ContentId.DeletePlaceholder} />
        </Stack>
      </Button>
      <ConfirmDialog
        headerText={<Content id={ContentId.DeletePlaceholder} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        {...dialogProps.dialogProps}
        centered
        disabled={disabled}
        onOkay={onDeleteSeatSaver}
        onClosed={dialogProps.closeDialog}
        onCancel={dialogProps.closeDialog}
      />
    </>
  );
};
