import { MultiSelectActionsContainer } from 'src/components/common/MultiSelect/MultiSelectActionsContainer';
import { BroadcastActions } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/Actions/BroadcastActions';
import { EventWithData } from 'src/WebApiController';

import { GroupActions } from './Actions/GroupActions';
import { PricingActions } from './Actions/PricingActions';
import { SettingsActions } from './Actions/SettingsActions';
import { TagsActions } from './Actions/TagsActions';

type InventoryBulkActionsProps = {
  eventWithData: EventWithData;
  isEventPage: boolean;
  listingCount: number;
};

export const InventoryEventBulkActions = (props: InventoryBulkActionsProps) => {
  const { eventWithData, isEventPage, listingCount } = props;

  return (
    <MultiSelectActionsContainer>
      <GroupActions data={eventWithData} />
      <PricingActions event={eventWithData.event} />
      <TagsActions event={eventWithData.event} />
      <BroadcastActions
        event={eventWithData.event}
        isEventPage={isEventPage}
        listingCount={listingCount}
      />
      <SettingsActions event={eventWithData.event} isEventPage={isEventPage} />
    </MultiSelectActionsContainer>
  );
};
