import { ComponentProps } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { DatePickerInput, TimePickerInput } from 'src/core/POS/DateSelector';
import { PosTextArea } from 'src/core/POS/PosTextArea';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export type AddAdminHoldInputProps = ComponentProps<typeof RSModal> & {
  adminHoldExpirationDate: Date | null;
  adminHoldNotes: string | null;
  onAdminHoldExpirationDateChange: (date: Date | null) => void;
  onAdminHoldNotesChange: (notes: string | null) => void;
  disabled?: boolean;
};

export function AddAdminHoldInput({
  adminHoldExpirationDate,
  adminHoldNotes,
  onAdminHoldExpirationDateChange,
  onAdminHoldNotesChange,
  disabled,
}: AddAdminHoldInputProps) {
  return (
    <Stack direction="column" gap="m">
      <Stack direction="row" gap="m">
        <Stack direction="column" gap="m">
          <Content id={ContentId.HeldUntilDate} />
          <DatePickerInput
            date={adminHoldExpirationDate ?? undefined}
            onDateChange={(date: Date) => {
              const newDate = adminHoldExpirationDate
                ? new Date(adminHoldExpirationDate.getTime())
                : new Date();
              newDate.setFullYear(date.getFullYear());
              newDate.setMonth(date.getMonth());
              newDate.setDate(date.getDate());

              const now = new Date();
              if (newDate < now) {
                newDate.setTime(now.getTime());
              }

              onAdminHoldExpirationDateChange(newDate);
            }}
            disabled={disabled}
            minDate={new Date()}
          />
        </Stack>
        <Stack direction="column" gap="m">
          <Content id={ContentId.Time} />
          <TimePickerInput
            value={adminHoldExpirationDate ?? undefined}
            onTimeChange={(t: Date | null) => {
              const newDate = adminHoldExpirationDate
                ? new Date(adminHoldExpirationDate.getTime())
                : new Date();

              if (t) {
                newDate.setFullYear(t.getFullYear());
                newDate.setMonth(t.getMonth());
                newDate.setDate(t.getDate());
                newDate.setHours(t.getHours());
                newDate.setMinutes(t.getMinutes());
              } else {
                newDate.setHours(0);
                newDate.setMinutes(0);
              }

              const now = new Date();
              if (newDate < now) {
                newDate.setTime(now.getTime());
              }

              onAdminHoldExpirationDateChange(newDate);
            }}
            disabled={disabled}
          />
        </Stack>
      </Stack>

      <Content id={ContentId.Notes} />
      <PosTextArea
        value={adminHoldNotes ?? undefined}
        disabled={disabled}
        onChange={(e) => {
          onAdminHoldNotesChange(e.target.value);
        }}
      />
    </Stack>
  );
}
