import { formatDistance } from 'date-fns';
import React, { useEffect, useState } from 'react';

const DefaultRefreshFrequency = 60 * 1_000; // 1 minute

type TimeDistanceProps = {
  date: Date | string | number;
  frequency?: number;
  includeSeconds?: boolean;
  addSuffix?: boolean;
};

/**
 * Simply component to count the date based of now.
 */
export const TimeDistance: React.FC<TimeDistanceProps> = ({
  date,
  frequency = DefaultRefreshFrequency,
  includeSeconds = false,
  addSuffix = true,
}) => {
  const [timeDistance, setTimeDistance] = useState('');

  useEffect(() => {
    const updateAgo = () => {
      setTimeDistance(
        formatDistance(new Date(date).getTime(), new Date().getTime(), {
          addSuffix,
          includeSeconds,
        })
      );
    };

    updateAgo();
    const interval = setInterval(updateAgo, frequency);

    return () => clearInterval(interval);
  }, [date, frequency, includeSeconds, addSuffix]);

  return <span>{timeDistance}</span>;
};
