import { useEffect, useRef } from 'react';
import {
  IUploadETicketsContext,
  useUploadETicketsContext,
} from 'src/components/UploadArtifacts/UploadETicketsV2/UploadETicketsContext';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Colors } from 'src/core/themes/constants/colors';
import { Button, Stack } from 'src/core/ui';
import { PosKey } from 'src/core/ui/Key';
import { useKeyPress } from 'src/hooks/useKeyPress';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { ChevronLeftIcon, ChevronRightIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './PreviewModalNavigationFooter.css';

type KeyboardRefActions = Pick<
  IUploadETicketsContext,
  'selectPrevUnmappedTicket' | 'selectNextUnmappedTicket'
>;

export const PreviewModalNavigationFooter = () => {
  const isLargeDesktop = useMatchMedia('largeDesktop');
  const isMobile = useMatchMedia('mobile');
  const { selectPrevUnmappedTicket, selectNextUnmappedTicket } =
    useUploadETicketsContext();

  const isArrowLeftKeyPressed = useKeyPress('ArrowLeft');
  const isArrowRightKeyPressed = useKeyPress('ArrowRight');

  const ref = useRef<KeyboardRefActions>({
    selectPrevUnmappedTicket,
    selectNextUnmappedTicket,
  });
  ref.current = {
    selectPrevUnmappedTicket,
    selectNextUnmappedTicket,
  };

  useEffect(() => {
    if (isArrowLeftKeyPressed) {
      ref.current?.selectPrevUnmappedTicket();
    }
  }, [isArrowLeftKeyPressed]);

  useEffect(() => {
    if (isArrowRightKeyPressed) {
      ref.current?.selectNextUnmappedTicket();
    }
  }, [isArrowRightKeyPressed]);

  if (isMobile) {
    return null;
  }

  return (
    <Stack
      justifyContent="spaceBetween"
      alignItems="center"
      className={styles.buttonsContainer}
    >
      <Stack alignItems="center" gap="m">
        <Button
          shape="rect"
          onClick={selectPrevUnmappedTicket}
          className={styles.arrowButton}
        >
          <span className={styles.navigationLabel}>
            <ChevronLeftIcon size={vars.iconSize.s} fill={Colors.neutral100} />
          </span>
        </Button>
        {isLargeDesktop && (
          <>
            <Content id={ContentId.PreviousTicket} /> <PosKey char="k" />
          </>
        )}
        {!isLargeDesktop && !isMobile && <Content id={ContentId.Prev} />}
      </Stack>

      <Stack alignItems="center" gap="m">
        {isLargeDesktop && (
          <>
            <PosKey char="L" />
            <Content id={ContentId.NextTicket} />
          </>
        )}
        {!isLargeDesktop && !isMobile && <Content id={ContentId.Next} />}
        <Button
          shape="rect"
          onClick={() => selectNextUnmappedTicket()}
          className={styles.arrowButton}
        >
          <span className={styles.navigationLabel}>
            <ChevronRightIcon size={vars.iconSize.s} fill={Colors.neutral100} />
          </span>
        </Button>
      </Stack>
    </Stack>
  );
};
