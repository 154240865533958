import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { SummaryTile } from 'src/components/common/SummaryTile';
import { MetricsDisplay } from 'src/components/Metrics';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ReleaseNoteDisplayDialog } from 'src/dialogs/ReleaseNoteDisplayDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useReleaseNotes } from 'src/hooks/useReleaseNotes';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getInventoryListingMetrics,
  getInventoryListingMetricsDisplayStrings,
} from 'src/utils/ticketMetricUtils';
import {
  ALL_INVENTORY_VIEW_PERMISSIONS,
  ALL_SALES_VIEW_PERMISSIONS,
  hasAccessToRoute,
} from 'src/utils/userUtils';
import { CatalogClient, Feature } from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import { ActivitySection } from './ActivitySection';
import * as styles from './Home.css';
import { MetricsSection as MetricTilesSection } from './MetricsSection';
import { NotificationsSection } from './NotificationsSection';
import { RecentSalesSection } from './RecentSalesSection/RecentSalesSection';

export function Home() {
  return (
    // Wrapping all home related logic to HomeContent because this allow Home to redirect if there's no Home access
    <LayoutContent mainRoute={MainRoute.Home}>
      <HomeContent />
    </LayoutContent>
  );
}

export function HomeContent() {
  const { trackError } = useErrorBoundaryContext();
  const isMobile = useMatchMedia('mobile');
  const { loginContext, appContext, activeAccountWebClientConfig } =
    useAppContext();
  const hasViewSalesPermission = useUserHasAnyOfPermissions(
    ...ALL_SALES_VIEW_PERMISSIONS
  );
  const hasViewListingsPermission = useUserHasAnyOfPermissions(
    ...ALL_INVENTORY_VIEW_PERMISSIONS
  );
  const hasNotificationsAccess = hasAccessToRoute(
    loginContext?.user,
    appContext?.features,
    MainRoute.Notifications
  );

  const {
    releaseNoteInfos: releaseNotesUnread,
    setReleaseNoteIdsRead,
    releaseNoteIdsRead,
  } = useReleaseNotes({ getUnreadOnly: true });
  const releaseNotesDialog = useBasicDialog();
  const metricsQuery = useQuery({
    queryKey: ['catalogHomeMetrics', activeAccountWebClientConfig],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      const metrics = await new CatalogClient(
        activeAccountWebClientConfig
      ).getCatalogHomeMetrics();
      return metrics;
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          `${CatalogClient.name}.${CatalogClient.prototype.getCatalogHomeMetrics.name}`,
          error
        );
      },
    },
  });
  const formattedMetrics:
    | ReturnType<typeof getInventoryListingMetricsDisplayStrings>
    | undefined =
    metricsQuery.isLoading || !metricsQuery.data
      ? undefined
      : getInventoryListingMetricsDisplayStrings(
          metricsQuery.data,
          loginContext?.user?.activeAccount?.currencyCode
        );
  const { mainMetrics, subMetrics } = getInventoryListingMetrics(
    metricsQuery.data,
    loginContext?.user?.activeAccount?.currencyCode
  );

  const hasHomePageActivityLogFeature = useUserHasFeature(
    Feature.HomePageActivityLog
  );

  useEffect(() => {
    if (releaseNotesUnread && releaseNotesUnread.length > 0) {
      if (!releaseNotesDialog.dialogProps.isOpen) {
        releaseNotesDialog.launchDialog();
      }
    } else {
      if (releaseNotesDialog.dialogProps.isOpen) {
        releaseNotesDialog.closeDialog();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseNotesUnread]);

  return (
    <div className={styles.root}>
      <Stack className={styles.sectionsContainer} direction="column">
        <Stack
          gap="l"
          justifyContent="spaceBetween"
          flexWrap="wrap"
          style={!isMobile ? { paddingTop: vars.spacing['sm'] } : {}}
        >
          <h1 className={styles.pageName}>
            <Content id={ContentId.Home} />
          </h1>
          {hasViewListingsPermission && (
            <Stack className={styles.headerMetricsContainer}>
              <MetricsDisplay
                className={styles.metricsDisplay}
                subMetricBelow
                mainMetrics={mainMetrics}
                subMetrics={subMetrics}
              />
              <SummaryTile.Metric
                name={<Content id={ContentId.TotalCost} />}
                showLoadingPlaceholders={metricsQuery.isLoading}
              >
                {formattedMetrics?.formattedTotalCost}
              </SummaryTile.Metric>
            </Stack>
          )}
        </Stack>
        {hasViewListingsPermission && <MetricTilesSection />}
        {hasViewSalesPermission && <RecentSalesSection />}
        {hasNotificationsAccess && (
          <Stack gap="xl" flexWrap="wrap">
            {hasHomePageActivityLogFeature && (
              <div className={styles.sectionWrapper}>
                <ActivitySection />
              </div>
            )}
            <div className={styles.sectionWrapper}>
              <NotificationsSection />
            </div>
          </Stack>
        )}
      </Stack>
      {Boolean(releaseNotesUnread?.length) && (
        <ReleaseNoteDisplayDialog
          {...releaseNotesDialog.dialogProps}
          releaseNoteInfos={releaseNotesUnread}
          headerText={<Content id={ContentId.DeletePurchaseVendorAccount} />}
          bodyText={
            <Content id={ContentId.DeletePurchaseVendorAccountWarning} />
          }
          onReleaseNotesRead={async () => {
            await setReleaseNoteIdsRead([
              ...(releaseNoteIdsRead ?? []),
              ...(releaseNotesUnread?.map((rn) => rn.id) ?? []),
            ]);
            releaseNotesDialog.closeDialog();
          }}
        />
      )}
    </div>
  );
}
