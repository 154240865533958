import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function WalletOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        fillRule="evenodd"
        d="M4.16667 0H17.5V5H20V20H3.33333C1.49226 20 0 18.5077 0 16.6667V4.16667C0 2.7856 1.11893 1.66667 2.5 1.66667H4.16667V0ZM5.83333 1.66667V5H15.8333V1.66667H5.83333ZM2.5 6.66667C2.20778 6.66667 1.9273 6.61657 1.66667 6.5245V16.6667C1.66667 17.5873 2.41274 18.3333 3.33333 18.3333H18.3333V6.66667H2.5ZM1.66667 4.16667C1.66667 4.62726 2.0394 5 2.5 5H4.16667V3.33333H2.5C2.0394 3.33333 1.66667 3.70607 1.66667 4.16667ZM14.1667 11.6667C13.7064 11.6667 13.3333 12.0398 13.3333 12.5C13.3333 12.9602 13.7064 13.3333 14.1667 13.3333C14.6269 13.3333 15 12.9602 15 12.5C15 12.0398 14.6269 11.6667 14.1667 11.6667ZM11.6667 12.5C11.6667 11.1193 12.786 10 14.1667 10C15.5474 10 16.6667 11.1193 16.6667 12.5C16.6667 13.8807 15.5474 15 14.1667 15C12.786 15 11.6667 13.8807 11.6667 12.5Z"
      />
    </SvgWrapper>
  );
}
