import { MultiSelectActionsContainer } from 'src/components/common/MultiSelect/MultiSelectActionsContainer';

import { BroadcastActions } from './Actions/BroadcastActions';
import { CatchUpAction } from './Actions/CatchUpAction';
import { PricingActions } from './Actions/PricingActions';
import { SettingsActions } from './Actions/SettingsActions';
import { TagsActions } from './Actions/TagsActions';

export const InventoryGlobalBulkActions = () => {
  return (
    <MultiSelectActionsContainer>
      <PricingActions />
      <TagsActions />
      <BroadcastActions />
      <SettingsActions />
      <CatchUpAction />
    </MultiSelectActionsContainer>
  );
};
