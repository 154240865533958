import { CommonBasePalette } from './palette.types';

export const BASE_LIGHT_PALETTE: CommonBasePalette = {
  // Start: Neutral
  neutralPrimary: '#1A2329', // neutral13
  neutralPrimaryHover: '#2B343A', // neutral21
  neutralPrimaryActive: '#141D23', // neutral10
  textOnNeutralPrimary: '#FFFFFF', // neutral100
  borderNeutralPrimary: '#B1BAC2', // neutral75

  neutralSecondary: '#F8FAFB', // neutral98
  neutralSecondaryHover: '#EFF1F2', // neutral95
  neutralSecondaryActive: '#DFE3E5', // neutral90
  textOnNeutralSecondary: '#091218', // neutral5
  // End: Neutral

  // Start: Disabled
  disabled: '#DFE3E5', // neutral90
  textOnDisabled: '#B1BAC2', // neutral75

  borderDisabled: '#B1BAC2', // neutral75

  textDisabledOnSurface: '#B1BAC2', // neutral75
  // End: Disabled

  // Start: Accent
  accentPrimary: '#E12277', // fuchsia50
  textOnAccentPrimary: '#FFFFFF', // fuc
  borderAccentPrimary: '#FF649B', // fuchsia64

  accentSecondary: '#FFE8EC', // fuchsia94
  textOnAccentSecondary: '#C30063', // fuchsia42

  textAccentOnSurface: '#C30063', // fuchsia42
  // End: Accent

  // Start: Error
  errorPrimary: '#D81819', // error46
  errorPrimaryHover: '#FF3E34', // error57
  errorPrimaryActive: '#AE000A', // error36
  textOnErrorPrimary: '#FFFFFF', // error100
  borderErrorPrimary: '#D81819', // error46

  errorSecondary: '#FFE9E6', // error94
  errorSecondaryHover: '#FFDAD5', // error90
  errorSecondaryActive: '#FFCFC8', // error87
  textOnErrorSecondary: '#D81819', // error46

  textErrorOnSurface: '#D81819', // error46
  // End: Error

  // Start: Success
  successPrimary: '#00A645', // success60
  textOnSuccessPrimary: '#FFFFFF', // success100
  borderSuccessPrimary: '#39C35E', // success70

  successSecondary: '#EBFFE7', // success98
  textOnSuccessSecondary: '#006E2B', // success40

  textSuccessOnSurface: '#006E2B', // success40
  // End: Success

  // Start: Warning
  warningPrimary: '#F2B824', // warning78
  textOnWarningPrimary: '#5C4300', // warning30
  borderWarningPrimary: '#F2B824', // warning78

  warningSecondary: '#FFECCA', // warning94
  textOnWarningSecondary: '#5C4300', // warning30
  // End: Warning

  // Start: Info
  infoPrimary: '#0075C4', // info48
  textOnInfoPrimary: '#FFFFFF', // info100
  borderInfoPrimary: '#8EC2FF', // info77

  infoSecondary: '#E0ECFF', // info93
  textOnInfoSecondary: '#000F20', // info4

  textInfoOnSurface: '#0075C4', // info48
  // End: Info

  // Start: Surface
  surfacePrimary: '#FFFFFF', // neutral100
  surfaceSecondary: '#F5F7F8', // neutral97

  textPrimaryOnSurface: '#091218', // neutral5
  textSecondaryOnSurface: '#606970', // neutral44

  textLinkOnSurface: '#5E29BA', // indigo33
  textLinkHoverOnSurface: '#3E008E', // indigo20
  textLinkActiveOnSurface: '#835CCD', // indigo48
  textLinkVisitedOnSurface: '#6535BE', // indigo36
  // End: Surface

  // Start: Border
  borderPrimary: '#141D23', // neutral13
  borderSecondary: '#B1BAC2', // neutral75
  // End: Border

  // Start: Elevated
  elevated: '#2F343B', // neutral10
  textPrimaryOnElevated: '#FFFFFF', // neutral100
  textSecondaryOnElevated: '#B1BAC2', // neutral75
  // End: Elevated

  scrim: '#00000060',
};
