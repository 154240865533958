import { EventIdQueryParam } from 'src/utils/constants/constants';

export const createInventoryEventUrl = (
  eventId: string,
  includeSearchParams = true,
  useEventV2 = false,
  queryParamOverride?: string
): string => {
  let searchParams = '';
  if (includeSearchParams) {
    const url = new URL(window.location.href);
    url.searchParams.set(queryParamOverride || EventIdQueryParam, eventId);
    searchParams = `?${url.searchParams.toString()}`;
  }
  return `/inventory/event${useEventV2 ? '/v2' : ''}${searchParams}`;
};
