import { useAppContext } from 'src/contexts/AppContext';
import { hasPermissionForListing } from 'src/utils/userPermissionUtils';
import { Listing, Permission } from 'src/WebApiController';

export const useUserHasListingPermission = (
  listing: Listing | null | undefined,
  forBulkEdit: boolean | undefined,
  root: Permission,
  isPricerOf?: Permission,
  isPurchasedBy?: Permission
) => {
  const { loginContext } = useAppContext();

  const canUpdate = hasPermissionForListing(
    loginContext?.user,
    listing,
    forBulkEdit,
    root,
    isPricerOf,
    isPurchasedBy
  );

  return canUpdate;
};

export const useUserCanUpdateListing = (
  listing: Listing | null | undefined,
  forBulkEdit: boolean | undefined
) => {
  const canUpdate = useUserHasListingPermission(
    listing,
    forBulkEdit,
    Permission.Inventory_UpdateListingInfo,
    Permission.Inventory_UpdateListingInfoOnIsPricerOf,
    Permission.Inventory_UpdateListingInfoOnPurchasedBy
  );
  return canUpdate;
};

export const useUserCanSetPrice = (
  listing: Listing | null | undefined,
  forBulkEdit: boolean | undefined
) => {
  const canSetPrice = useUserHasListingPermission(
    listing,
    forBulkEdit,
    Permission.Inventory_SetPrice,
    Permission.Inventory_SetPriceOnIsPricerOf,
    Permission.Inventory_SetPriceOnPurchasedBy
  );
  return canSetPrice;
};

export const useUserCanUpdateListingTags = (
  listing: Listing | null | undefined,
  forBulkEdit: boolean | undefined
) => {
  const canSetPrice = useUserHasListingPermission(
    listing,
    forBulkEdit,
    Permission.Inventory_UpdateTags,
    Permission.Inventory_UpdateTagsOnIsPricerOf,
    Permission.Inventory_UpdateTagsOnPurchasedBy
  );
  return canSetPrice;
};

export const useUserCanBroadcast = (
  listing: Listing | null | undefined,
  forBulkEdit: boolean | undefined
) => {
  const canSetPrice = useUserHasListingPermission(
    listing,
    forBulkEdit,
    Permission.Inventory_BroadcastUnbroadcast,
    Permission.Inventory_BroadcastUnbroadcastOnIsPricerOf,
    Permission.Inventory_BroadcastUnbroadcastOnPurchasedBy
  );
  return canSetPrice;
};

export const useUserCanPredeliver = (
  listing: Listing | null | undefined,
  forBulkEdit: boolean | undefined
) => {
  const canUpdate = useUserHasListingPermission(
    listing,
    forBulkEdit,
    Permission.Inventory_Predelivery,
    Permission.Inventory_PredeliveryOnIsPricerOf,
    Permission.Inventory_PredeliveryOnPurchasedBy
  );
  return canUpdate;
};

export const useUserCanViewPredelivery = (
  listing: Listing | null | undefined,
  forBulkEdit: boolean | undefined
) => {
  // It is assumed if you can fulfill - then you can view fulfillment artifacts
  const canPredeliver = useUserCanPredeliver(listing, forBulkEdit);

  const canView = useUserHasListingPermission(
    listing,
    forBulkEdit,
    Permission.Inventory_ViewFulfillmentArtifacts,
    Permission.Inventory_ViewFulfillmentArtifactsOnIsPricerOf,
    Permission.Inventory_ViewFulfillmentArtifactsOnPurchasedBy
  );

  return canView || canPredeliver;
};

export const useUserCanSetDeliveryStrategy = (
  listing: Listing | null | undefined,
  forBulkEdit: boolean | undefined
) => {
  const canSetPrice = useUserHasListingPermission(
    listing,
    forBulkEdit,
    Permission.Inventory_UpdateDeliveryStrategy,
    Permission.Inventory_UpdateDeliveryStrategyOnIsPricerOf,
    Permission.Inventory_UpdateDeliveryStrategyOnPurchasedBy
  );
  return canSetPrice;
};
