import {
  ListingReportTableColumnId,
  ListingTableColumnId,
  ListingTableFlattenedColumnId,
} from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import {
  SalesTableColumnId,
  SalesTableFlattenedColumnId,
} from 'src/utils/columns/sales/salesColumnUtils.types';

import {
  FormatOption,
  FormatOption_DateTime,
  FormatOption_EnumDisplay,
  FormatOption_Event,
  FormatOption_HiddenCustomFormat,
  FormatOption_Percent,
  FormatOption_Seating,
  FormatOption_Standard,
  FormatOptionType,
} from './EditTableColumns.constants';
import {
  GenerateFormatOptionDisplayString,
  GetDefaultFormatOption,
} from './EditTableColumns.types';

export const getFormatOptionType = (option: FormatOption): FormatOptionType => {
  if (Object.values<FormatOption>(FormatOption_Standard).includes(option)) {
    return FormatOptionType.Standard;
  }
  if (Object.values<FormatOption>(FormatOption_Seating).includes(option)) {
    return FormatOptionType.Seating;
  }
  if (Object.values<FormatOption>(FormatOption_Event).includes(option)) {
    return FormatOptionType.Event;
  }
  if (Object.values<FormatOption>(FormatOption_DateTime).includes(option)) {
    return FormatOptionType.DateTime;
  }
  if (Object.values<FormatOption>(FormatOption_EnumDisplay).includes(option)) {
    return FormatOptionType.EnumDisplay;
  }
  if (Object.values<FormatOption>(FormatOption_Percent).includes(option)) {
    return FormatOptionType.Percentage;
  }
  // Default case
  return FormatOptionType.Standard;
};

/**
 * EnumDisplay and Seating are localized text, thus can't be defined here
 */
export const FORMAT_OPTION_TO_DISPLAY_MAP: Omit<
  Record<FormatOption, string>,
  | FormatOption_EnumDisplay
  | FormatOption_Seating
  | FormatOption_Event
  | FormatOption_HiddenCustomFormat
> = {
  [FormatOption.Millions]: '0.1M',
  [FormatOption.Thousands]: '100K',
  [FormatOption.Whole]: '100,000',
  [FormatOption.Precise]: '100,000.43',
  [FormatOption.DateTime_MMDD]: 'MM/DD',
  [FormatOption.DateTime_MMDD_Time]: 'MM/DD Time',
  [FormatOption.DateTime_MMDD_Time_Day]: 'MM/DD Time, Day',
  [FormatOption.DateTime_MonthDD]: 'Month DD',
  [FormatOption.DateTime_MonthDD_Time]: 'Month DD, Time',
  [FormatOption.DateTime_MonthDD_Time_Day]: 'Month DD, Time, Day',
  [FormatOption.DateTime_MMDDYY]: 'MM/DD/YY',
  [FormatOption.DateTime_Month_DD_YY]: 'Month DD YY',
  [FormatOption.DateTime_Month_DD_YY_Time]: 'Month DD YY, Time',
  [FormatOption.DateTime_Month_DD_YY_Time_Day]: 'Month DD YY, Time, Day',
  [FormatOption.Percent_RoundToTwo]: '43.42',
  [FormatOption.Percent_RoundToOne]: '43.2',
  [FormatOption.Percent_RoundToWhole]: '43',
  [FormatOption.Percent_AsDecimal_RoundToOne]: '0.4',
  [FormatOption.Percent_AsDecimal_RoundToTwo]: '0.43',
  [FormatOption.Percent_AsDecimal_RoundToThree]: '0.434',
};

/**
 * Takes the flags and parameters to generate the content that should be rendered on
 * a FormatOptionPill (usually)
 */
export const generateFormatOptionDisplayString: GenerateFormatOptionDisplayString =
  ({ localizedText, option, isPercent, isCurrency, uiCurrency }) => {
    const {
      originalSeating,
      fullSeating,
      sectionOnly,
      fullEventInfo,
      eventName,
      icon,
      text,
    } = localizedText;
    const shouldShowPercent =
      isPercent &&
      !isCurrency &&
      !(
        [
          FormatOption.Percent_AsDecimal_RoundToOne,
          FormatOption.Percent_AsDecimal_RoundToTwo,
          FormatOption.Percent_AsDecimal_RoundToThree,
        ] as string[]
      ).includes(option);

    const contentDisplayMap: Record<FormatOption, string> = {
      ...FORMAT_OPTION_TO_DISPLAY_MAP,
      [FormatOption.Seating_OriginalSeating]: originalSeating,
      [FormatOption.Seating_FullSeating]: fullSeating,
      [FormatOption.Seating_SectionOnly]: sectionOnly,
      [FormatOption.Event_FullInfo]: fullEventInfo,
      [FormatOption.Event_EventName]: eventName,
      [FormatOption.EnumDisplay_Icon]: icon,
      [FormatOption.EnumDisplay_Text]: text,
      [FormatOption.Actions_LinkOnly]: '', // We do not expose this
      [FormatOption.Id_RawIdOnly]: '', // We do not expose this
    };

    // Currency will win if both isCurrency and isPercent being set
    const currencyContent = isCurrency ? uiCurrency.sym : '';
    // We add fallback in case we no longer support an enum or change its value
    const displayContent =
      contentDisplayMap[option] ?? contentDisplayMap[FormatOption.Precise];
    // Percent will only display if isCurrency is not set
    const percentContent = shouldShowPercent ? '%' : '';

    const textContent = `${currencyContent}${displayContent}${percentContent}`;

    return textContent;
  };

export const LISTING_TABLE_COLUMN_DEFAULT_PRECISION: Record<
  string,
  FormatOption
> = {
  [ListingTableColumnId.Seating]: FormatOption.Seating_FullSeating,
  [ListingTableColumnId.SeatingSeats]: FormatOption.Seating_FullSeating,
  [ListingTableFlattenedColumnId.Event]: FormatOption.Event_FullInfo,
  [ListingTableColumnId.DeliveryType]: FormatOption.EnumDisplay_Icon,
  [ListingTableColumnId.UndercutMode]: FormatOption.EnumDisplay_Text,
  [ListingTableColumnId.CompListingMode]: FormatOption.EnumDisplay_Text,
  [ListingReportTableColumnId.PaymentMethodType]: FormatOption.EnumDisplay_Icon,
};

export const LISTING_FLATTENED_TABLE_COLUMN_DEFAULT_PRECISION: Record<
  string,
  FormatOption
> = {
  [ListingTableColumnId.DeliveryType]: FormatOption.EnumDisplay_Text,
};

export const SALE_TABLE_COLUMN_DEFAULT_PRECISION: Record<string, FormatOption> =
  {
    [SalesTableColumnId.Seating]: FormatOption.Seating_FullSeating,
    [SalesTableFlattenedColumnId.Event]: FormatOption.Event_FullInfo,
    [SalesTableColumnId.Status]: FormatOption.EnumDisplay_Text,
    [SalesTableColumnId.TicketType]: FormatOption.EnumDisplay_Icon,
    [SalesTableColumnId.OrderDate]: FormatOption.DateTime_MonthDD_Time,
  };

export const SALE_FLATTENED_TABLE_COLUMN_DEFAULT_PRECISION: Record<
  string,
  FormatOption
> = {
  [SalesTableColumnId.Status]: FormatOption.EnumDisplay_Text,
  [SalesTableColumnId.TicketType]: FormatOption.EnumDisplay_Text,
};

export const getDefaultFormatOption: GetDefaultFormatOption = ({
  isSeating,
  isEvent,
  isEnumWithIcon,
  isInteger,
  isDateTime,
  isPercent,
}) => {
  return isSeating
    ? FormatOption.Seating_FullSeating
    : isEvent
    ? FormatOption.Event_FullInfo
    : isEnumWithIcon
    ? FormatOption.EnumDisplay_Icon
    : isDateTime
    ? FormatOption.DateTime_MonthDD_Time
    : isPercent
    ? FormatOption.Percent_AsDecimal_RoundToTwo
    : isInteger
    ? FormatOption.Whole
    : FormatOption.Precise;
};

export const PURCHASES_EVENT_TABLE_COLUMN_DEFAULT_PRECISION: Record<
  string,
  FormatOption
> = {
  [PurchasesTableColumnId.PurchaseDate]: FormatOption.DateTime_MonthDD_Time_Day,
};
