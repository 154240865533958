import { MultiSelectActionsContainer } from 'src/components/common/MultiSelect/MultiSelectActionsContainer';
import { FulfillmentActions } from 'src/components/Sales/BulkActions/SalesGlobalBulkActions/Actions/FulfillmentActions';
import { SettingsActions } from 'src/components/Sales/BulkActions/SalesGlobalBulkActions/Actions/SettingsActions';
import { TagsActions } from 'src/components/Sales/BulkActions/SalesGlobalBulkActions/Actions/TagsActions';

export const SalesGlobalBulkActions = () => {
  return (
    <MultiSelectActionsContainer>
      <TagsActions />
      <FulfillmentActions />
      <SettingsActions />
    </MultiSelectActionsContainer>
  );
};
