import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { TopLevelCategory } from 'src/WebApiController';

export const topLevelCategories: Record<string, string> = {
  [TopLevelCategory.Concert]: ContentId.Concert,
  [TopLevelCategory.Festival]: ContentId.Festival,
  [TopLevelCategory.Sports]: ContentId.Sports,
  [TopLevelCategory.Theatre]: ContentId.Theatre,
};

export const AdGroupGenreSelector = ({
  placeholderText,
  ...props
}: Omit<PosSelectProps, 'valueOptionsContent'>) => {
  return (
    <PosSelect
      {...props}
      searchable={
        props.searchable != null
          ? props.searchable
          : Object.values(topLevelCategories).length > 10
      }
      placeholderText={placeholderText}
      valueOptionsContent={topLevelCategories}
    />
  );
};
