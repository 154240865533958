import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useGetVendor } from 'src/hooks/api/useGetVendor';
import { ModalBody } from 'src/modals/Modal';
import { ModalBodyDataContainer } from 'src/modals/Modal/Modal.styled';
import { VendorDetailsBodyContent } from 'src/modals/VendorDetails/VendorDetailsBodyContent';
import { VendorDetailsModalHeader } from 'src/modals/VendorDetails/VendorDetailsModalHeader';
import { useGetVendorIdFromUrl } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useGetVendorIdFromUrl';

export const VendorDetailsModalBody = () => {
  const vendorId = useGetVendorIdFromUrl();
  const { isLoading, data: vendor } = useGetVendor(vendorId);
  const hasData = !!vendor;

  return (
    <ModalBody>
      <VendorDetailsModalHeader />
      <ModalBodyDataContainer>
        {isLoading ? (
          <PosSpinner />
        ) : (
          hasData && <VendorDetailsBodyContent vendor={vendor} />
        )}
      </ModalBodyDataContainer>
    </ModalBody>
  );
};
