import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { selectDistinctListingIds } from 'src/utils/ticketUtils';
import {
  Event,
  ListingClient,
  ListingDetails,
  SaleDetails,
} from 'src/WebApiController';

import { ModalBodyDataContainer } from '../Modal/Modal.styled';
import { BuyerSection } from './components/BuyerSection';
import { MarketplaceSaleInfoSection } from './components/MarketplaceSaleInfoSection';
import { TicketSection } from './components/TicketSection';

export const ManagedMarketplaceSaleBody = ({
  listing,
  sale,
  disabled,
  event,
}: {
  listing?: ListingDetails;
  sale?: SaleDetails;
  disabled?: boolean;
  event?: Event | null;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const saleLinkedListingIds = useMemo(
    () =>
      sale &&
      (selectDistinctListingIds(sale?.tickets).filter(
        (l) => l != null
      ) as number[]),
    [sale]
  );

  const shouldQuery = Boolean(
    saleLinkedListingIds != null &&
      activeAccountWebClientConfig != null &&
      saleLinkedListingIds.length > 0
  );

  const saleLinkedListingQuantityQuery = useQuery({
    queryKey: [
      'ListingClient.getListingAvailableQuantitiesByListingIds',
      saleLinkedListingIds,
      activeAccountWebClientConfig,
    ],
    queryFn: () => {
      if (!shouldQuery) return null;

      return new ListingClient(
        activeAccountWebClientConfig
      ).getListingAvailableQuantitiesByListingIds(saleLinkedListingIds!);
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
  });

  const linkedListingAvailableQuantities =
    saleLinkedListingQuantityQuery.data ?? 0;

  const maxQuantity = useMemo(() => {
    if (listing) {
      return listing.availQty;
    }

    return sale ? sale.ticketCnt + linkedListingAvailableQuantities : undefined;
  }, [linkedListingAvailableQuantities, listing, sale]);

  return (
    <ModalBodyDataContainer>
      <BuyerSection disabled={disabled} />
      <MarketplaceSaleInfoSection disabled={disabled} />
      {saleLinkedListingQuantityQuery.isFetching ? (
        <PosSpinner />
      ) : (
        <TicketSection
          disabled={disabled}
          maxQuantity={maxQuantity}
          deliveryType={listing?.delivType}
          tickets={listing?.tickets}
          event={event}
          listing={listing}
          isEditingExisting={sale && sale.id > 0}
        />
      )}
    </ModalBodyDataContainer>
  );
};
