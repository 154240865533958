import { useEffect, useState } from 'react';

interface UseTimeoutFlagProps {
  msTimeout?: number;
}

/**
 * Returns true after the specified timeout time.
 */
export const useTimeoutFlag = ({ msTimeout }: UseTimeoutFlagProps) => {
  const [hasTimedOut, setHasTimedOut] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHasTimedOut(true);
    }, msTimeout);
  }, [msTimeout]);

  return hasTimedOut;
};
