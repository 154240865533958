import removeAccents from 'remove-accents';

/**
 * Removes all non-alpha-numeric chars
 * using ~`!@#$%^&*()_-+={[}]|\:;'",<.>/?
 * @param str
 */
export const removeSpecialChars = (str: string): string => {
  return removeAccents(str).replace(
    /[\/\[\^~`\!@\#\$%\^&\*\(\)_\-\+\=\{\[\}\]\|\\\:;\\'\\",\<\.\>\\\/\?\]\/g]/g,
    ''
  );
};
