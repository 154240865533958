import * as Sentry from '@sentry/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AdminClient,
  CoBrandId,
  LoginContext,
  PosClientConfig,
  ReactAppContext,
} from 'src/WebApiController';

export interface IAppContext {
  appContext?: ReactAppContext;
  mainRoute: MainRoute;
  setMainRoute: (view: MainRoute) => void;
  loginContext?: LoginContext | null;
  activeAccountUserId?: string | null;
  activeAccountWebClientConfig: PosClientConfig;
  setLoginContext: (context?: LoginContext | null) => void;
  cobrandId?: CoBrandId;
  overrideCoBrandId: (cobrandId: CoBrandId) => void;
}

const emptyAppContext: IAppContext = {
  appContext: undefined,
  mainRoute: MainRoute.Home,
  loginContext: undefined,
  activeAccountWebClientConfig: {},
  setLoginContext: () => {},
  setMainRoute: () => {},
  cobrandId: undefined,
  overrideCoBrandId: () => {},
};

export const AppContext = createContext<IAppContext>(emptyAppContext);

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { showErrorDialog } = useErrorBoundaryContext();

  const [loadingAppContext, setLoadingAppContext] = useState(false);
  const [reactAppContext, setReactAppContext] = useState<ReactAppContext>();
  const [loginContext, setLoginContext] = useState<
    LoginContext | null | undefined
  >();
  const [mainRoute, setMainRoute] = useState<MainRoute>(MainRoute.Home);

  const [cobrandIdOverride, setCobrandIdOverride] = useState<
    CoBrandId | undefined
  >(undefined);

  const initAppContextIfNeeded = useCallback(async () => {
    setLoadingAppContext(true);
    tryInvokeApi(
      async () => {
        setReactAppContext(await new AdminClient({}).getAppContext());
        setLoadingAppContext(false);
      },
      (error) => {
        showErrorDialog('AdminClient.getAppContext', error);
      }
    );
  }, [showErrorDialog]);

  useEffect(() => {
    if (!loadingAppContext && reactAppContext === undefined) {
      initAppContextIfNeeded();
    }
  }, [
    initAppContextIfNeeded,
    loginContext,
    loadingAppContext,
    reactAppContext,
  ]);

  const setLoginContextWrapper = useCallback((newLC?: LoginContext | null) => {
    if (newLC?.isSuccess && newLC?.user?.activeAccount.id != null) {
      // Add logging info of user context
      Sentry.setTag('seller-account-id', newLC.user.activeAccount.id);
      Sentry.setTag('seller-account', newLC.user.activeAccount.accountName);
      Sentry.setTag('user-id', newLC.user.userId);
      Sentry.setTag('user-name', newLC.user.userName);
      Sentry.setTag(
        'impersonated-user-id',
        newLC.user.activeAccount?.impersonatedUserId
      );
      Sentry.setTag(
        'impersonated-user',
        newLC.user.activeAccount?.impersonatedUsername
      );

      setLoginContext(newLC);
    }
  }, []);

  const activeAccountWebClientConfig = useMemo(
    () => ({
      activeAccountId: loginContext?.user?.activeAccount?.id,
    }),
    [loginContext?.user?.activeAccount?.id]
  );

  return (
    <AppContext.Provider
      value={{
        appContext: reactAppContext,
        mainRoute: mainRoute,
        setMainRoute,
        loginContext,
        activeAccountUserId:
          loginContext?.user?.activeAccount?.impersonatedUserId ||
          loginContext?.user?.userId,
        activeAccountWebClientConfig: activeAccountWebClientConfig,
        setLoginContext: setLoginContextWrapper,
        cobrandId: cobrandIdOverride ?? reactAppContext?.cobrandId,
        overrideCoBrandId: setCobrandIdOverride,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
