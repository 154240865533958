import { forwardRef, ReactElement, useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { PurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { UserGroupSelector } from 'src/components/Selectors/UserGroupSelector';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DatePickerInput, TimePickerInput } from 'src/core/POS/DateSelector';
import { PosEnumSelect, PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import {
  BOOLEAN_OPTIONS_CONTENT,
  MARKET_PLACE_TO_CID,
  TAG_VALUE_TYPE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { ToMarketplaceEnum } from 'src/utils/tagUtils';
import { Marketplace, Tag, TagsValueType } from 'src/WebApiController';

export const TOTAL_VALUE_LENGTH_LIMIT = 2000;

type TagValueInputProps = {
  tag: Tag | undefined;
  newValue: string;
  isValidNewValue: boolean;
  setNewValue: (newValue: string) => void;
  onChange: (newValue: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  disableBlur?: boolean;
  inputFieldPosfix?: JSX.Element;
  style?: React.CSSProperties;
};

export const TagValueInput = forwardRef<HTMLInputElement, TagValueInputProps>(
  function TagValueInput(
    {
      tag,
      newValue,
      isValidNewValue,
      setNewValue,
      onChange,
      onBlur,
      disabled,
      disableBlur,
      inputFieldPosfix,
      style,
    },
    ref
  ): ReactElement {
    const tagTypeText = useContent(
      TAG_VALUE_TYPE_TO_CID[tag?.valueType ?? ContentId.EmptyContent]
    );

    const customOnBlurSelect = useCallback(() => {
      if (disabled) return;

      if (isValidNewValue) {
        onChange(newValue);
      } else {
        setNewValue(tag!.value);
        onBlur?.();
      }
    }, [
      disabled,
      isValidNewValue,
      newValue,
      onBlur,
      onChange,
      setNewValue,
      tag,
    ]);

    const customOnBlurInput = useCallback(() => {
      // disableBlur is used to prevent onBlur from being called when clicking on the cross icon
      if (disabled || disableBlur) return;

      if (isValidNewValue) {
        onChange(newValue);
      } else {
        setNewValue(tag?.value ?? '');
        onBlur?.();
      }
    }, [
      disableBlur,
      disabled,
      isValidNewValue,
      newValue,
      onBlur,
      onChange,
      setNewValue,
      tag?.value,
    ]);

    const onKeyDownHandler = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (!disabled) {
          const key = e.key;
          if (key === 'Enter') {
            e.stopPropagation();
            if (isValidNewValue) {
              onChange(newValue);
            } else {
              setNewValue(tag?.value ?? '');
              onBlur?.();
            }
          }
          if (key === 'Escape') {
            e.stopPropagation();
            setNewValue(tag?.value ?? '');
            onBlur?.();
          }
        }
      },
      [
        disabled,
        isValidNewValue,
        newValue,
        onBlur,
        onChange,
        setNewValue,
        tag?.value,
      ]
    );

    const [tagValueDate, setTagValueDate] = useState<Date | null>(
      tag?.value ? new Date(tag.value) : null
    );

    return (
      <>
        {tag?.valueType === TagsValueType.Date ? (
          <Stack direction="column" gap="m">
            <Stack direction="row" gap="m">
              <DatePickerInput
                date={tagValueDate ?? undefined}
                onDateChange={(date: Date) => {
                  const newDate = tagValueDate
                    ? new Date(tagValueDate.getTime())
                    : new Date();
                  newDate.setFullYear(date.getFullYear());
                  newDate.setMonth(date.getMonth());
                  newDate.setDate(date.getDate());

                  setTagValueDate(newDate);
                  setNewValue(newDate.toISOString());
                }}
                disabled={disabled}
                textFieldStyles={{ width: '100px' }}
              />

              <TimePickerInput
                value={tagValueDate ?? undefined}
                onTimeChange={(t: Date | null) => {
                  const newDate = tagValueDate
                    ? new Date(tagValueDate.getTime())
                    : new Date();

                  if (t) {
                    newDate.setFullYear(t.getFullYear());
                    newDate.setMonth(t.getMonth());
                    newDate.setDate(t.getDate());
                    newDate.setHours(t.getHours());
                    newDate.setMinutes(t.getMinutes());
                  } else {
                    newDate.setHours(0);
                    newDate.setMinutes(0);
                  }

                  setTagValueDate(newDate);
                  setNewValue(newDate.toISOString());
                }}
                disabled={disabled}
                textFieldStyles={{ width: '100px' }}
              />

              <Button
                style={{
                  borderRadius: vars.borderRadius.circle,
                  backgroundColor: vars.color.backgroundBrand,
                  borderColor: vars.color.backgroundBrand,
                }}
                disabled={disabled || tagValueDate == undefined}
                onClick={() => {
                  onChange(tagValueDate?.toISOString() ?? '');
                }}
              >
                <Content id={ContentId.Save} />
              </Button>
              <Button
                style={{
                  borderRadius: vars.borderRadius.circle,
                  backgroundColor: vars.color.backgroundBrand,
                  borderColor: vars.color.backgroundBrand,
                }}
                disabled={disabled || tagValueDate == undefined}
                onClick={() => {
                  setTagValueDate(null);
                  setNewValue('');
                  onChange('');
                }}
              >
                <Content id={ContentId.Reset} />
              </Button>
            </Stack>
          </Stack>
        ) : tag?.valueType === TagsValueType.Marketplace ? (
          <PosEnumSelect
            value={ToMarketplaceEnum(newValue)}
            onChange={(value) => {
              setNewValue(Marketplace[value as keyof typeof Marketplace]);
              onChange(Marketplace[value as keyof typeof Marketplace]);
            }}
            disabled={disabled}
            style={style}
            valueOptionsContent={MARKET_PLACE_TO_CID}
            onBlur={customOnBlurSelect}
          />
        ) : tag?.valueType === TagsValueType.POSUser ? (
          <SellerAccountEmployeeSelector
            value={newValue}
            enableEmptySelection
            onChange={(value) => {
              setNewValue(value);
              onChange(value);
            }}
            placeholderText={ContentId.None}
            disabled={disabled}
            style={style}
            onBlur={customOnBlurSelect}
          />
        ) : tag?.valueType === TagsValueType.UserGroup ? (
          <UserGroupSelector
            value={newValue}
            enableEmptySelection
            onChange={(value) => {
              setNewValue(value);
              onChange(value);
            }}
            placeholderText={ContentId.None}
            disabled={disabled}
            style={style}
            onBlur={customOnBlurSelect}
          />
        ) : tag?.valueType === TagsValueType.Vendor ? (
          <PurchaseVendorSelector
            style={style}
            placeholderText={ContentId.None}
            enableEmptySelection
            value={newValue}
            disabled={disabled}
            onChange={(newId) => {
              setNewValue(newId);
              onChange(newId);
            }}
            onBlur={customOnBlurSelect}
          />
        ) : tag?.valueType === TagsValueType.Boolean ? (
          <PosSelect
            displayText={newValue}
            disabled={disabled}
            value={newValue}
            onChange={(value) => {
              setNewValue(value);
              onChange(value);
            }}
            style={style}
            valueOptionsContent={BOOLEAN_OPTIONS_CONTENT}
            placeholderText={ContentId.None}
            onBlur={customOnBlurSelect}
            enableEmptySelection
          />
        ) : (
          // String, Decimal, Int
          <PosTextField
            ref={ref}
            disabled={disabled || tag == null}
            value={newValue}
            type={
              tag?.valueType === TagsValueType.Decimal ||
              tag?.valueType === TagsValueType.Int
                ? 'number'
                : 'text'
            }
            placeholder={tagTypeText}
            onChange={(e) => {
              setNewValue(e.target.value);
            }}
            postfixDisplay={inputFieldPosfix}
            maxLength={TOTAL_VALUE_LENGTH_LIMIT}
            onBlur={customOnBlurInput}
            onKeyDown={onKeyDownHandler}
            // Need this for onBlur to work
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            style={style}
          />
        )}
      </>
    );
  }
);
