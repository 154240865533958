import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { UseGetUserInfo, useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { AttrLabel, DetailSection } from 'src/modals/common';
import { modalDetails } from 'src/modals/common/Modals.css';
import { GenericFeatureIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import {
  SaleClient,
  SaleDetails,
  Seating,
  SeatingForAlloc,
  TicketInfoForAllocation,
} from 'src/WebApiController';

import * as styles from './SeatingAllocationInput.css';
import {
  AllocationSeatTable,
  NoAllocationContainer,
} from './SeatingAllocationInput.styled';

export type FlattenedSeatingAllocation = TicketInfoForAllocation &
  Pick<SeatingForAlloc, 'section' | 'row' | 'recAlloc'>;

export const SeatingSingleTicketAllocationInput = ({
  selectedSeatings,
  setSelectedSeatings,
  originalSeating,
}: {
  selectedSeatings: FlattenedSeatingAllocation[];
  setSelectedSeatings: React.Dispatch<
    React.SetStateAction<FlattenedSeatingAllocation[]>
  >;
  originalSeating: Seating;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { getValues } = useFormContext<SaleDetails>();

  const [allPossibleSeatings, setAllPossibleSeatings] = useState(false);

  const sale = getValues();

  const ticketsForAllocationQuery = useQuery({
    queryKey: [
      'SaleClient.getSeatingsForSaleAllocation',
      sale.id,
      originalSeating,
      allPossibleSeatings,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      const client = new SaleClient(activeAccountWebClientConfig);
      const data = await client.getSeatingsForSaleAllocation(
        sale.id,
        allPossibleSeatings
      );
      return data;
    },
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('SaleClient.getSeatingsForSaleAllocation', error, {
          trackErrorData: {
            saleId: sale.id,
            allPossibleSeatings: allPossibleSeatings,
          },
        });
      },
    },
  });

  if (ticketsForAllocationQuery.failureReason) {
    showErrorDialog(
      'SaleClient.getSeatingsForSaleAllocation',
      { message: ticketsForAllocationQuery.failureReason.message },
      {
        trackErrorData: {
          saleId: sale.id,
          allPossibleSeatings: allPossibleSeatings,
        },
      }
    );
  }

  const possibleSeatings: FlattenedSeatingAllocation[] | undefined =
    useMemo(() => {
      const possibleSeatings = ticketsForAllocationQuery.data?.possibleSeatings;
      if (!possibleSeatings) {
        return possibleSeatings;
      }
      const seatingTickets: FlattenedSeatingAllocation[] = [];
      const ticketIds = new Set();

      const possibleSeatingsConsecutive = possibleSeatings.filter(
        (seating) => !seating.nonConsecSeats
      );

      const possibleSeatingsNonConsecutive = possibleSeatings.filter(
        (seating) => seating.nonConsecSeats
      );

      const filteredPossibleSeating = [
        ...possibleSeatingsConsecutive,
        ...possibleSeatingsNonConsecutive,
      ];

      filteredPossibleSeating.forEach((seating) => {
        seating.tktsForAlloc.forEach((ticket) => {
          if (!ticketIds.has(ticket.id)) {
            ticketIds.add(ticket.id);
            seatingTickets.push({
              section: seating.section,
              row: seating.row,
              recAlloc: seating.recAlloc,
              ...ticket,
            });
          }
        });
      });

      return seatingTickets;
    }, [ticketsForAllocationQuery.data?.possibleSeatings]);

  const handleToggleSeating = (seating: FlattenedSeatingAllocation) => {
    setSelectedSeatings((prevSelected) => {
      const isAlreadySelected = prevSelected.some((s) => s.id === seating.id);

      if (isAlreadySelected) {
        const newSelected = prevSelected.filter((s) => s.id !== seating.id);
        return newSelected;
      } else {
        if (prevSelected.length >= sale.qtySold) {
          return prevSelected;
        } else {
          const newSelected = [...prevSelected, seating];
          return newSelected;
        }
      }
    });
  };

  const userIds = possibleSeatings
    ?.map((seating) => seating.purchasedBy)
    .filter((id): id is string => id !== null);

  const purchasedByUsers = useGetUserInfos(userIds);

  return (
    <div className={modalDetails}>
      <div className={styles.seatAllocationContainer}>
        <DetailSection name={<Content id={ContentId.Allocate} />}>
          {possibleSeatings ? (
            possibleSeatings.length ? (
              <>
                <AllocationSeatTable>
                  <thead>
                    <tr>
                      <th>&nbsp;</th>
                      <th>
                        <AttrLabel>
                          <Content id={ContentId.Section} />
                        </AttrLabel>
                      </th>
                      <th>
                        <AttrLabel>
                          <Content id={ContentId.Row} />
                        </AttrLabel>
                      </th>
                      <th>
                        <AttrLabel>
                          <Content id={ContentId.Seats} />
                        </AttrLabel>
                      </th>
                      <th>
                        <AttrLabel>
                          <Content id={ContentId.PurchasedBy} />
                        </AttrLabel>
                      </th>
                      <th>
                        <AttrLabel>
                          <Content id={ContentId.Vendor} />
                        </AttrLabel>
                      </th>
                      <th>
                        <AttrLabel>
                          <Content id={ContentId.VendorAccount} />
                        </AttrLabel>
                      </th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  {possibleSeatings.map((seating, index) => (
                    <PossibleSeatingTableRow
                      key={index}
                      index={index}
                      seating={seating}
                      isSelected={selectedSeatings.some(
                        (s) => s.id === seating.id
                      )}
                      handleSelection={handleToggleSeating}
                      purchasedBy={
                        seating.purchasedBy
                          ? purchasedByUsers?.data
                            ? purchasedByUsers.data[seating.purchasedBy]
                            : null
                          : null
                      }
                    />
                  ))}
                </AllocationSeatTable>
                {ticketsForAllocationQuery.data?.hasMoreAllocations && (
                  <div className={styles.allocationActionsContainer}>
                    <Button
                      variant="link"
                      onClick={() =>
                        setAllPossibleSeatings(!allPossibleSeatings)
                      }
                    >
                      <Content
                        id={
                          allPossibleSeatings
                            ? ContentId.AllocateToListingTickets
                            : ContentId.AllocateToDifferentTickets
                        }
                      />
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <NoAllocationContainer>
                <Content id={ContentId.NoSeatingsAvailableForAllocation} />
                <NavLink
                  title={getPurchaseOrderRelativeUrl(0, sale.viagVirtualId)}
                  tag={Link}
                  to={getPurchaseOrderRelativeUrl(0, sale.viagVirtualId)}
                >
                  <Content id={ContentId.AddPurchase} />
                </NavLink>
              </NoAllocationContainer>
            )
          ) : (
            <PosSpinner size={vars.iconSize.xxs} />
          )}
        </DetailSection>
      </div>
    </div>
  );
};

const PossibleSeatingTableRow = ({
  seating,
  index,
  isSelected,
  handleSelection,
  purchasedBy,
}: {
  seating: FlattenedSeatingAllocation;
  index: number;
  handleSelection: (seating: FlattenedSeatingAllocation) => void;
  isSelected: boolean;
  purchasedBy?: UseGetUserInfo | null;
}) => {
  const recommended = useContent(ContentId.Recommended);
  const deactivatedText = useContent(ContentId.Deactivated);

  const {
    id,
    seat,
    section,
    row,
    vendorId,
    vendorName,
    vendorAccEmail,
    vendorAccName,
    recAlloc,
  } = seating;

  const { availableVendors } = usePurchaseVendorSelector({});
  return (
    <tr key={id}>
      <td>
        <Checkbox
          checked={isSelected}
          onChange={() => handleSelection(seating)}
          label={null}
        />
      </td>
      <td>{section}</td>
      <td>{row}</td>
      <td>{seat}</td>
      <td>
        {`${purchasedBy?.name} ${
          purchasedBy?.isDeactivated ? ` (${deactivatedText})` : ''
        }`}
      </td>
      <td>{vendorId ? availableVendors[vendorId]?.name : vendorName}</td>
      <td>{vendorAccEmail || vendorAccName}</td>
      <td>
        {recAlloc && (
          <div title={recommended}>
            <GenericFeatureIcon fill={IconsFill.textBrand} />
          </div>
        )}
      </td>
    </tr>
  );
};
