import { head, uniq } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { filterSectionByZone } from 'src/utils/venueConfigUtils';
import { SectionInfo } from 'src/WebApiController';

export const useEventHeatMapSectionHandler = (
  onLaunchingDialog: () => void
) => {
  const { venueMapInfo } = useEventMapContext();
  const [selectedSection, setSectionSelected] = useState<SectionInfo | null>(
    null
  );
  const [surroundingSections, setSurroundingSections] = useState<SectionInfo[]>(
    []
  );
  const [selectedSectionIds, setSelectedSectionIds] = useState<number[]>([]);

  const selectedSectionData = useMemo(() => {
    if (!venueMapInfo || !selectedSectionIds?.length) {
      return undefined;
    }

    return venueMapInfo?.sections?.filter((s: SectionInfo) =>
      selectedSectionIds.includes(s.id)
    );
  }, [selectedSectionIds, venueMapInfo]);

  const onResetSections = useCallback(() => setSelectedSectionIds([]), []);

  const onShiftSelect = useCallback(
    (sectionInfo: SectionInfo) => {
      // We are using the ticketClassId to group the sections
      const selectedClassId = head(
        sectionInfo?.rows
          ?.map(({ tktClass: ticketClass }) => ticketClass?.id)
          .filter((id) => !!id)
      );
      const updatedSectionIds = venueMapInfo?.sections
        ?.filter(filterSectionByZone(selectedClassId))
        ?.map((s: SectionInfo) => s.id);

      setSelectedSectionIds((prev) => {
        if (prev.includes(sectionInfo.id)) {
          return prev.filter((id) => !updatedSectionIds?.includes(id));
        }
        return uniq([...prev, ...(updatedSectionIds ?? [])]);
      });
    },
    [venueMapInfo?.sections]
  );

  const onCtrlSelect = useCallback(
    (sectionInfo: SectionInfo) =>
      setSelectedSectionIds((prev) => {
        if (prev.includes(sectionInfo.id)) {
          return prev.filter((id) => id !== sectionInfo.id);
        }
        return [...prev, sectionInfo.id];
      }),
    []
  );

  const onSingleSelect = useCallback(
    (sectionInfo: SectionInfo, surroundingSections?: SectionInfo[]) => {
      setSectionSelected(sectionInfo);
      setSurroundingSections(surroundingSections ?? []);
      onLaunchingDialog();
    },
    [onLaunchingDialog]
  );

  const onSectionClicked = useCallback(
    (
      e: MouseEvent,
      sectionInfo: SectionInfo,
      surroundingSections?: SectionInfo[]
    ) => {
      if (e.shiftKey) {
        onShiftSelect(sectionInfo);
        return;
      }
      if (e.ctrlKey || e.metaKey) {
        onCtrlSelect(sectionInfo);
        return;
      }

      onSingleSelect(sectionInfo, surroundingSections);
    },
    [onCtrlSelect, onSingleSelect, onShiftSelect]
  );

  const onToggleMirrors = useCallback(
    (mirrors: SectionInfo[], exclude?: boolean) => {
      setSelectedSectionIds((prev) => {
        const mirrorIds = mirrors.map(({ id }) => id);
        if (exclude) {
          return [...prev.filter((id) => !mirrorIds.includes(id))];
        } else {
          return uniq([...prev, ...mirrorIds]);
        }
      });
    },
    []
  );

  const onSelectedSectionsChange = useCallback((sections: SectionInfo[]) => {
    setSelectedSectionIds(sections.map(({ id }) => id));
  }, []);

  return {
    selectedSection,
    selectedSectionIds,
    selectedSectionData,
    surroundingSections,
    onSectionClicked,
    onResetSections,
    onToggleMirrors,
    onSelectedSectionsChange,
  };
};
