import * as styles from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/ClearButton/ClearButton.css';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { CrossIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

interface ClearButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ClearButton = ({ onClick }: ClearButtonProps) => {
  return (
    <Button variant="link" onClick={onClick} className={styles.clearButton}>
      <CrossIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
      <Content id={ContentId.Clear} />
    </Button>
  );
};
