import { isEmpty, uniq } from 'lodash-es';
import React, { useCallback, useMemo } from 'react';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { Stack } from 'src/core/ui';
import { calculatePercentile } from 'src/utils/arrayUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { CompListing } from 'src/WebApiController';

import * as styles from './VenueMapStats.css';
import { VeneuMapRightNowStats } from './VenueMapStats.types';

type VenueMapRightNowStatsProps = {
  compListings: CompListing[];
  currencyCode: string | null;
  quantityFilters: string[];
  setQuantityFilters: (filters: string[]) => void;
  showFilter: boolean;
};

export const VenueMapRightNowStats: React.FC<VenueMapRightNowStatsProps> = ({
  compListings,
  currencyCode,
  quantityFilters,
  setQuantityFilters,
  showFilter,
}) => {
  const minText = useContent(ContentId.Min);
  const p25Text = useContent(ContentId.P25);
  const p50Text = useContent(ContentId.P50);
  const splitChoices = useMemo(() => {
    return uniq(
      compListings.flatMap((listing) => listing.validPurchaseQuantities ?? [])
    ).reduce(
      (res, quantity) => {
        const stringQuantity = quantity + '';
        res[stringQuantity] = stringQuantity;
        return res;
      },
      {} as Record<string, string>
    );
  }, [compListings]);

  const status = useMemo((): VeneuMapRightNowStats => {
    const listingCount = compListings.length;
    let ticketCount = 0;
    const ticketPrices: number[] = [];
    for (const listing of compListings) {
      if ((listing.availableTickets ?? 0) > 0) {
        ticketCount += listing.availableTickets ?? 0;
        const prices = Array(listing.availableTickets ?? 0).fill(
          listing.sellerAllInPrice?.amt ?? 0
        );
        ticketPrices.push(...prices);
      }
    }
    return {
      listingCount,
      ticketCount,
      ticketPriceMin: calculatePercentile(ticketPrices, 0),
      ticketPriceP25: calculatePercentile(ticketPrices, 25),
      ticketPriceAvg: calculatePercentile(ticketPrices, 50),
    };
  }, [compListings]);

  const formatPrice = useCallback(
    (price: number) => {
      return applyNumberFormatting({
        inputNumber: price,
        currencyCode,
        currencyDecimalDigits: 2,
      });
    },
    [currencyCode]
  );

  return (
    <>
      {!isEmpty(splitChoices) && showFilter && (
        <Stack className={styles.splitTypeBox} direction="row" gap="s">
          <PosMultiSelect
            placeholderText={ContentId.SelectQuantities}
            values={quantityFilters}
            onChange={setQuantityFilters}
            valueOptionsContent={splitChoices}
          />
        </Stack>
      )}
      <Stack className={styles.statsBox} direction="column" gap="s">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          gap="m"
        >
          <div className={styles.statsDisplayLabel}>
            <Content id={ContentId.ListingQuantity} />
          </div>
          <div className={styles.statsDisplayValue}>
            {status.listingCount.toLocaleString()}
          </div>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          gap="m"
        >
          <div className={styles.statsDisplayLabel}>
            <Content id={ContentId.TicketQuantity} />
          </div>
          <div className={styles.statsDisplayValue}>
            {status.ticketCount.toLocaleString()}
          </div>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          gap="m"
        >
          <div className={styles.statsDisplayLabel}>
            <FormatContent
              id={FormatContentId.TicketPricePercentile}
              params={minText}
            />
          </div>
          <div className={styles.statsDisplayValue}>
            {formatPrice(status.ticketPriceMin)}
          </div>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          gap="m"
        >
          <div className={styles.statsDisplayLabel}>
            <FormatContent
              id={FormatContentId.TicketPricePercentile}
              params={p25Text}
            />
          </div>
          <div className={styles.statsDisplayValue}>
            {formatPrice(status.ticketPriceP25)}
          </div>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="spaceBetween"
          gap="m"
        >
          <div className={styles.statsDisplayLabel}>
            <FormatContent
              id={FormatContentId.TicketPricePercentile}
              params={p50Text}
            />
          </div>
          <div className={styles.statsDisplayValue}>
            {formatPrice(status.ticketPriceAvg)}
          </div>
        </Stack>
      </Stack>
    </>
  );
};
