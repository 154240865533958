import { ReactNode } from 'react';
import { FlattenedViewIcon } from 'src/svgs/FlattenedViewIcon';
import { TileViewIcon } from 'src/svgs/TileViewIcon';
import { IconsFill } from 'src/svgs/Viagogo';
import { SalesViewMode } from 'src/WebApiController';

export const SALE_VIEW_MODE_TO_ICON: Record<string, ReactNode> = {
  [SalesViewMode.TileView]: (
    <TileViewIcon fill={IconsFill.currentColor} withHoverEffect />
  ),
  [SalesViewMode.FlattenedView]: (
    <FlattenedViewIcon fill={IconsFill.currentColor} withHoverEffect />
  ),
};
