import { formatInTimeZone } from 'date-fns-tz';
import { PurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { UserGroupSelector } from 'src/components/Selectors/UserGroupSelector';
import { TOTAL_VALUE_LENGTH_LIMIT } from 'src/components/TagsFormBody/TagValueCell';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosEnumSelect, PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import {
  BOOLEAN_OPTIONS_CONTENT,
  MARKET_PLACE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { ToMarketplaceEnum } from 'src/utils/tagUtils';
import { Marketplace, TagsValueType } from 'src/WebApiController';

export type TagInfoFilter = {
  tagName: string | null;
  tagValue: string | null;
};

export const TagValueFilterInput = ({
  value,
  valueType,
  onChange,
  disabled,
}: {
  disabled?: boolean;
  value: string;
  valueType?: TagsValueType | null;
  onChange: (newValue: string) => void;
}) => {
  const { timeZone, timezoneOffsetMins } = useSiteTimezoneContext();
  return (
    <>
      {valueType === TagsValueType.Date ? (
        <Stack direction="column" gap="m">
          <DatePickerInput
            date={
              value
                ? new Date(
                    new Date(value).getTime() + timezoneOffsetMins * 60 * 1000
                  )
                : undefined
            }
            onDateChange={(date: Date) => {
              const newDate = value ? new Date(value) : new Date();
              newDate.setFullYear(date.getFullYear());
              newDate.setMonth(date.getMonth());
              newDate.setDate(date.getDate());

              // Setting to yyyy-MM-dd format to match ISO format
              onChange(formatInTimeZone(newDate, timeZone, 'yyyy-MM-dd'));
            }}
            disabled={disabled}
          />
        </Stack>
      ) : valueType === TagsValueType.Marketplace ? (
        <PosEnumSelect
          value={ToMarketplaceEnum(value)}
          onChange={(value) => {
            onChange(Marketplace[value as keyof typeof Marketplace]);
          }}
          disabled={disabled}
          valueOptionsContent={MARKET_PLACE_TO_CID}
        />
      ) : valueType === TagsValueType.Boolean ? (
        <PosSelect
          displayText={value}
          value={value}
          disabled={disabled}
          onChange={onChange}
          valueOptionsContent={BOOLEAN_OPTIONS_CONTENT}
          placeholderText={ContentId.Value}
        />
      ) : valueType === TagsValueType.POSUser ? (
        <SellerAccountEmployeeSelector
          value={value}
          onChange={onChange}
          placeholderText={ContentId.Value}
          disabled={disabled}
        />
      ) : valueType === TagsValueType.UserGroup ? (
        <UserGroupSelector
          value={value}
          onChange={onChange}
          placeholderText={ContentId.Value}
          disabled={disabled}
        />
      ) : valueType === TagsValueType.Vendor ? (
        <PurchaseVendorSelector
          value={value}
          onChange={onChange}
          placeholderText={ContentId.Value}
          disabled={disabled}
        />
      ) : (
        <PosTextField
          disabled={disabled}
          value={value}
          type={
            valueType === TagsValueType.Decimal ||
            valueType === TagsValueType.Int
              ? 'number'
              : 'text'
          }
          onChange={(e) => {
            const newValue = e.target.value ?? null;
            onChange(newValue);
          }}
          maxLength={TOTAL_VALUE_LENGTH_LIMIT}
        />
      )}
    </>
  );
};
