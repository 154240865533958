import { useAppContext } from 'src/contexts/AppContext';
import { DateSelector } from 'src/core/POS/DateSelector/DateSelector';
import {
  DatePresetName,
  StandardDatePresetNames,
} from 'src/utils/dateTimeUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';

export type InHandDateInputProps = {
  value: Date | string | null;
  allowEmptySelection?: boolean;
  targetDate: Date;
  disabled?: boolean;
  onChange: (date?: Date, daysBeforeEvent?: number) => void;
  placeholderText?: string;
  daysBeforeEvent?: number;
};

export const InHandDateInput = ({
  value,
  targetDate,
  onChange,
  disabled,
  allowEmptySelection,
  placeholderText,
  daysBeforeEvent,
}: InHandDateInputProps) => {
  const { loginContext } = useAppContext();

  const inHandDate = value
    ? new Date(value)
    : allowEmptySelection
    ? null
    : new Date();

  const customPresetNames = [...StandardDatePresetNames];

  const inHandDaysBeforeEvent =
    daysBeforeEvent ??
    loginContext?.user?.activeAccount?.sellerAccountSettings
      ?.inHandDaysBeforeEvent;

  if (inHandDaysBeforeEvent) {
    customPresetNames.push(DatePresetName.DaysBeforeEvent);
  }

  const getDaysBeforeEvent = (presetName?: string): number | undefined => {
    if (presetName === undefined) {
      return undefined;
    }

    switch (presetName) {
      case DatePresetName.DaysBeforeEvent:
        return inHandDaysBeforeEvent ?? undefined;
      case DatePresetName.OneDayBeforeEvent:
        return 1;
      case DatePresetName.OneWeekBeforeEvent:
        return 7;
      case DatePresetName.Now:
        return 0;
      default:
        return undefined;
    }
  };

  return (
    <DateSelector
      disabled={disabled}
      placeholderText={placeholderText}
      date={inHandDate ?? undefined}
      onChange={(date, presetName) => {
        onChange(date, getDaysBeforeEvent(presetName));
      }}
      locale={getLocaleFromLanguageOrCurrent()}
      presetNames={customPresetNames}
      inHandDaysBeforeEvent={inHandDaysBeforeEvent ?? undefined}
      targetDate={targetDate}
      // In hand date is relative to event date
      useSiteTimeZone={false}
    />
  );
};
