import { ContentIdAndDefaultValue } from 'src/contexts/ContentContext';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import {
  EventGroupingType,
  EventSort,
  EventTimeFrameFilter,
  PointOfSalePaymentState,
  PointOfSaleSaleStatus,
} from 'src/WebApiController';

import { ContentId } from './contentId';

// Please keep this map in alphabetical order
export const ContentIds: Record<ContentId, ContentIdAndDefaultValue> = {
  [ContentId.Above]: {
    id: ContentId.Above,
    defaultValue: 'Above',
  },
  [ContentId.AbsoluteAmount]: {
    id: ContentId.AbsoluteAmount,
    defaultValue: 'Absolute Amount',
  },
  [ContentId.AbsoluteRankPremium]: {
    id: ContentId.AbsoluteRankPremium,
    defaultValue: 'Absolute Rank Premium',
  },
  [ContentId.ABTest]: {
    id: ContentId.ABTest,
    defaultValue: 'AB Test',
  },
  [ContentId.Access]: {
    id: ContentId.Access,
    defaultValue: 'Access',
  },
  [ContentId.AccessToViewUnauthorized]: {
    id: ContentId.AccessToViewUnauthorized,
    defaultValue: 'You are not authorized to access this view.',
  },
  [ContentId.AccordianToggle]: {
    id: ContentId.AccordianToggle,
    defaultValue: 'Toggle',
  },
  [ContentId.Account]: {
    id: ContentId.Account,
    defaultValue: 'Account',
  },
  [ContentId.Account_SetPrice_Details]: {
    id: ContentId.Account_SetPrice_Details,
    defaultValue: 'Configure pricing settings for the account',
  },
  [ContentId.AccountEmail]: {
    id: ContentId.AccountEmail,
    defaultValue: 'Account email',
  },
  [ContentId.AccountInformation]: {
    id: ContentId.AccountInformation,
    defaultValue: 'Account information',
  },
  [ContentId.Accounting]: {
    id: ContentId.Accounting,
    defaultValue: 'Accounting',
  },
  [ContentId.AccountName]: {
    id: ContentId.AccountName,
    defaultValue: 'Account name',
  },
  [ContentId.AccountNotSetupForAnyMarketplace]: {
    id: ContentId.AccountNotSetupForAnyMarketplace,
    defaultValue:
      'Your account is not setup to broadcast or sync with any marketplace. All listing and sale actions are not available. Please contact us to finish setting up the account.',
  },
  [ContentId.Accounts]: {
    id: ContentId.Accounts,
    defaultValue: 'Accounts',
  },
  [ContentId.Accounts_ViewAll_Details]: {
    id: ContentId.Accounts_ViewAll_Details,
    defaultValue: 'View all available accounts for the seller',
  },
  [ContentId.Actions]: {
    id: ContentId.Actions,
    defaultValue: 'Actions',
  },
  [ContentId.ActivateRole]: {
    id: ContentId.ActivateRole,
    defaultValue: 'Activate Role',
  },
  [ContentId.ActivateUser]: {
    id: ContentId.ActivateUser,
    defaultValue: 'Activate User',
  },
  [ContentId.Active]: {
    id: ContentId.Active,
    defaultValue: 'Active',
  },
  [ContentId.Activity]: {
    id: ContentId.Activity,
    defaultValue: 'Activity',
  },
  [ContentId.ActivityLogDate]: {
    id: ContentId.ActivityLogDate,
    defaultValue: 'Activity Date',
  },
  [ContentId.ActivityLogActionType]: {
    id: ContentId.ActivityLogActionType,
    defaultValue: 'Activity Type',
  },
  [ContentId.ActivityLogInitiatedBy]: {
    id: ContentId.ActivityLogInitiatedBy,
    defaultValue: 'Initiated By',
  },
  [ContentId.ActivityLogAdditionalInfo]: {
    id: ContentId.ActivityLogAdditionalInfo,
    defaultValue: 'Additional Info',
  },
  [ContentId.ActivityLogLaunchButtonText]: {
    id: ContentId.ActivityLogLaunchButtonText,
    defaultValue: 'History',
  },
  [ContentId.Actual]: {
    id: ContentId.Actual,
    defaultValue: 'Actual',
  },
  [ContentId.ActualSellThroughRate]: {
    id: ContentId.ActualSellThroughRate,
    defaultValue: 'Actual Sell-Through Rate',
  },
  [ContentId.ActualSTR]: {
    id: ContentId.ActualSTR,
    defaultValue: 'Act. STR',
  },
  [ContentId.AdCampaigns]: {
    id: ContentId.AdCampaigns,
    defaultValue: 'Ad Campaigns',
  },
  [ContentId.AddABTest]: {
    id: ContentId.AddABTest,
    defaultValue: 'Add AB Test',
  },
  [ContentId.AddHurdle]: {
    id: ContentId.AddHurdle,
    defaultValue: 'Add Hurdle',
  },
  [ContentId.AddNewDeal]: {
    id: ContentId.AddNewDeal,
    defaultValue: 'Add New Deal',
  },
  [ContentId.AddOverride]: {
    id: ContentId.AddOverride,
    defaultValue: 'Add Override',
  },
  [ContentId.AddRow]: {
    id: ContentId.AddRow,
    defaultValue: 'Add Row',
  },
  [ContentId.AddRule]: {
    id: ContentId.AddRule,
    defaultValue: '+ Add Rule',
  },
  [ContentId.AddSplit]: {
    id: ContentId.AddSplit,
    defaultValue: 'Add Split',
  },
  [ContentId.AddToGroup]: {
    id: ContentId.AddToGroup,
    defaultValue: 'Add to Group',
  },
  [ContentId.AdGroups]: {
    id: ContentId.AdGroups,
    defaultValue: 'Ad Groups',
  },
  [ContentId.Add]: {
    id: ContentId.Add,
    defaultValue: 'Add',
  },
  [ContentId.AddAccountAction]: {
    id: ContentId.AddAccountAction,
    defaultValue: 'Add new account',
  },
  [ContentId.AddChargeOrCredit]: {
    id: ContentId.AddChargeOrCredit,
    defaultValue: 'Add charge or credit',
  },
  [ContentId.AddColumns]: {
    id: ContentId.AddColumns,
    defaultValue: 'Add columns',
  },
  [ContentId.AddCommission]: {
    id: ContentId.AddCommission,
    defaultValue: 'Add Commission',
  },
  [ContentId.AddCostAction]: {
    id: ContentId.AddCostAction,
    defaultValue: 'Add Cost',
  },
  [ContentId.AddCostCredit]: {
    id: ContentId.AddCostCredit,
    defaultValue: 'Add Cost/Credit',
  },
  [ContentId.AddCustomColumn]: {
    id: ContentId.AddCustomColumn,
    defaultValue: 'Add custom column',
  },
  [ContentId.AddFilter]: {
    id: ContentId.AddFilter,
    defaultValue: 'Add Filter',
  },
  [ContentId.AddGroup]: {
    id: ContentId.AddGroup,
    defaultValue: 'Add Group',
  },
  [ContentId.AddInventoryReport]: {
    id: ContentId.AddInventoryReport,
    defaultValue: 'Add Inventory Report',
  },
  [ContentId.AddLineItem]: {
    id: ContentId.AddLineItem,
    defaultValue: 'Add line item',
  },
  [ContentId.AddMarketplaceSale]: {
    id: ContentId.AddMarketplaceSale,
    defaultValue: 'Add Marketplace Sale',
  },
  [ContentId.AddMembersToThisTeam]: {
    id: ContentId.AddMembersToThisTeam,
    defaultValue: 'Add members to this team',
  },
  [ContentId.AddMessagingSource]: {
    id: ContentId.AddMessagingSource,
    defaultValue: 'Add messaging source',
  },
  [ContentId.AddMessagingSourceDescription]: {
    id: ContentId.AddMessagingSourceDescription,
    defaultValue:
      'Forward your email messages into your account to manage your purchases, transfers, and more.',
  },
  [ContentId.AddMessagingSourceStep1]: {
    id: ContentId.AddMessagingSourceStep1,
    defaultValue: 'Go to your email provider.',
  },
  [ContentId.AddMessagingSourceStep2]: {
    id: ContentId.AddMessagingSourceStep2,
    defaultValue: 'Set up forwarding to address below:',
  },
  [ContentId.AddMessagingSourceStep3]: {
    id: ContentId.AddMessagingSourceStep3,
    defaultValue: 'Start managing your messages.',
  },
  [ContentId.AddNewBuyer]: {
    id: ContentId.AddNewBuyer,
    defaultValue: 'Add New Buyer...',
  },
  [ContentId.AddNewOverrideFxRate]: {
    id: ContentId.AddNewOverrideFxRate,
    defaultValue: 'Add New Override Fx Rate',
  },
  [ContentId.AddNewTeam]: {
    id: ContentId.AddNewTeam,
    defaultValue: 'Add New Team',
  },
  [ContentId.AddNote]: {
    id: ContentId.AddNote,
    defaultValue: 'Add Note',
  },
  [ContentId.AddOfflineSale]: {
    id: ContentId.AddOfflineSale,
    defaultValue: 'Add Offline Sale',
  },
  [ContentId.AddOrUpdateFxOverrideWarning]: {
    id: ContentId.AddOrUpdateFxOverrideWarning,
    defaultValue:
      'Adding or updating an FX rate override will adjust the unit cost on the affected listings.',
  },
  [ContentId.AddOverrideFxRate]: {
    id: ContentId.AddOverrideFxRate,
    defaultValue: 'Add Override Fx Rate',
  },
  [ContentId.AddPaymentMethodAction]: {
    id: ContentId.AddPaymentMethodAction,
    defaultValue: 'Add new payment method',
  },
  [ContentId.AddPayment]: {
    id: ContentId.AddPayment,
    defaultValue: 'Add Payment',
  },
  [ContentId.AddPlaceholder]: {
    id: ContentId.AddPlaceholder,
    defaultValue: 'Add Placeholder',
  },
  [ContentId.AddPlaceholderAdditionalInfoMessage]: {
    id: ContentId.AddPlaceholderAdditionalInfoMessage,
    defaultValue: 'Tell use more about your placeholder inventory',
  },
  [ContentId.AddPurchase]: {
    id: ContentId.AddPurchase,
    defaultValue: 'Add Purchase',
  },
  [ContentId.AddPurchasePaymentMethod]: {
    id: ContentId.AddPurchasePaymentMethod,
    defaultValue: 'Add Purchase Payment Method',
  },
  [ContentId.AddPurchaseVendor]: {
    id: ContentId.AddPurchaseVendor,
    defaultValue: 'Add Purchase Vendor',
  },
  [ContentId.AddPurchaseVendorAccount]: {
    id: ContentId.AddPurchaseVendorAccount,
    defaultValue: 'Add Purchase Vendor Account',
  },
  [ContentId.AddReport]: {
    id: ContentId.AddReport,
    defaultValue: 'Add Report',
  },
  [ContentId.AddSalesReport]: {
    id: ContentId.AddSalesReport,
    defaultValue: 'Add Sales Report',
  },
  [ContentId.AddSeatSaver]: {
    id: ContentId.AddSeatSaver,
    defaultValue: 'Add Seat Saver',
  },
  [ContentId.AddSeatSaveEventSearchMessage]: {
    id: ContentId.AddSeatSaveEventSearchMessage,
    defaultValue: 'What events are your seat saver for?',
  },
  [ContentId.AddSeatSaverAdditionalInfoMessage]: {
    id: ContentId.AddSeatSaverAdditionalInfoMessage,
    defaultValue: 'Tell us more about your seat saver',
  },
  [ContentId.AddSection]: {
    id: ContentId.AddSection,
    defaultValue: 'Add Section',
  },
  [ContentId.AddSource]: {
    id: ContentId.AddSource,
    defaultValue: 'Add source',
  },
  [ContentId.AddTag]: {
    id: ContentId.AddTag,
    defaultValue: 'Add Tag',
  },
  [ContentId.AddTagValues]: {
    id: ContentId.AddTagValues,
    defaultValue: 'Add Tag Values',
  },
  [ContentId.AddTagValuesSelected]: {
    id: ContentId.AddTagValuesSelected,
    defaultValue: 'Add Tag Values for Selected',
  },
  [ContentId.AddTagValuesAllVisible]: {
    id: ContentId.AddTagValuesAllVisible,
    defaultValue: 'Add Tag Values for All Visible Listings',
  },
  [ContentId.AddTarget]: {
    id: ContentId.AddTarget,
    defaultValue: 'Add target',
  },
  [ContentId.AddTickets]: {
    id: ContentId.AddTickets,
    defaultValue: 'Add Tickets',
  },
  [ContentId.AddTicketsForThisEvent]: {
    id: ContentId.AddTicketsForThisEvent,
    defaultValue: 'Add Tickets for This Event',
  },
  [ContentId.AddUser]: {
    id: ContentId.AddUser,
    defaultValue: 'Add User',
  },
  [ContentId.AddUsersAndGroups]: {
    id: ContentId.AddUsersAndGroups,
    defaultValue: 'Add users and groups',
  },
  [ContentId.AddUserToRole]: {
    id: ContentId.AddUserToRole,
    defaultValue: 'Add users to this role',
  },
  [ContentId.AddValue]: {
    id: ContentId.AddValue,
    defaultValue: 'Add Value',
  },
  [ContentId.AdjustFiltersSuggestion]: {
    id: ContentId.AdjustFiltersSuggestion,
    defaultValue: 'Try adjusting your filters to broaden the search criteria.',
  },
  [ContentId.AdjustPricesBy]: {
    id: ContentId.AdjustPricesBy,
    defaultValue: 'Adjust prices by',
  },
  [ContentId.AdjustPricingCeilingAllVisible]: {
    id: ContentId.AdjustPricingCeilingAllVisible,
    defaultValue: 'Adjust Proceeds Ceiling for All Visible Listings',
  },
  [ContentId.AdjustPricingFloorAllVisible]: {
    id: ContentId.AdjustPricingFloorAllVisible,
    defaultValue: 'Adjust Proceeds Floor for All Visible Listings',
  },
  [ContentId.AdjustPricingFloorSelected]: {
    id: ContentId.AdjustPricingFloorSelected,
    defaultValue: 'Adjust Proceeds Floor for Selected',
  },
  [ContentId.AdjustPricingCeilingSelected]: {
    id: ContentId.AdjustPricingCeilingSelected,
    defaultValue: 'Adjust Proceeds Ceiling for Selected',
  },
  [ContentId.AdjustPricingFloor]: {
    id: ContentId.AdjustPricingFloor,
    defaultValue: 'Adjust Proceeds Floor',
  },
  [ContentId.AdjustPricingCeiling]: {
    id: ContentId.AdjustPricingCeiling,
    defaultValue: 'Adjust Proceeds Ceiling',
  },
  [ContentId.AdjustProceedsFloor]: {
    id: ContentId.AdjustProceedsFloor,
    defaultValue: 'Adjust Proceeds Floor',
  },
  [ContentId.AdjustProceedsCeiling]: {
    id: ContentId.AdjustProceedsCeiling,
    defaultValue: 'Adjust Proceeds Ceiling',
  },
  [ContentId.AdvancedAutopricingSettings]: {
    id: ContentId.AdvancedAutopricingSettings,
    defaultValue: 'Advanced Autopricing Settings',
  },
  [ContentId.AdvancedSettings]: {
    id: ContentId.AdvancedSettings,
    defaultValue: 'Advanced settings',
  },
  [ContentId.AggressiveOption]: {
    id: ContentId.AggressiveOption,
    defaultValue: 'Aggressive (2sd)',
  },
  /**
   * @deprecated Unused
   */
  [ContentId.Ago]: {
    id: ContentId.Ago,
    defaultValue: 'ago',
  },
  [ContentId.AISuggestion]: {
    id: ContentId.AISuggestion,
    defaultValue: 'AI suggestion',
  },
  [ContentId.AllAmex]: {
    id: ContentId.AllAmex,
    defaultValue: 'All Amex',
  },
  [ContentId.AllAvailableMerges]: {
    id: ContentId.AllAvailableMerges,
    defaultValue: 'All available merges',
  },
  [ContentId.AllFulfillmentStates]: {
    id: ContentId.AllFulfillmentStates,
    defaultValue: 'All Fulfillment States',
  },
  [ContentId.AllGenres]: {
    id: ContentId.AllGenres,
    defaultValue: 'All Genres',
  },
  [ContentId.AllInPriceAutoCalculationInfo]: {
    id: ContentId.AllInPriceAutoCalculationInfo,
    defaultValue:
      'All-in price is estimated from your list price plus fees (if any).',
  },
  [ContentId.AllListings]: {
    id: ContentId.AllListings,
    defaultValue: 'All listings',
  },
  [ContentId.AllMarketplaces]: {
    id: ContentId.AllMarketplaces,
    defaultValue: 'All Marketplaces',
  },
  [ContentId.AllMastercard]: {
    id: ContentId.AllMastercard,
    defaultValue: 'All MasterCard',
  },
  [ContentId.AllMessages]: {
    id: ContentId.AllMessages,
    defaultValue: 'All messages',
  },
  [ContentId.Allocate]: {
    id: ContentId.Allocate,
    defaultValue: 'Allocate',
  },
  [ContentId.AllocatedListing]: {
    id: ContentId.AllocatedListing,
    defaultValue: 'Allocated Listing',
  },
  [ContentId.AllocateToAnyAvailableSeats]: {
    id: ContentId.AllocateToAnyAvailableSeats,
    defaultValue: 'Allocate to any available seats',
  },
  [ContentId.AllocateToDifferentTickets]: {
    id: ContentId.AllocateToDifferentTickets,
    defaultValue: 'Allocate to different tickets',
  },
  [ContentId.AllocateToListingTickets]: {
    id: ContentId.AllocateToListingTickets,
    defaultValue: 'Allocate to listing tickets',
  },
  [ContentId.Allocation]: {
    id: ContentId.Allocation,
    defaultValue: 'Allocation',
  },
  [ContentId.AllowMutipleValues]: {
    id: ContentId.AllowMutipleValues,
    defaultValue: 'Allow Mutiple Values',
  },
  [ContentId.AllPaymentTypes]: {
    id: ContentId.AllPaymentTypes,
    defaultValue: 'All Payment Types',
  },
  [ContentId.AllSubGenres]: {
    id: ContentId.AllSubGenres,
    defaultValue: 'All sub-genres',
  },
  [ContentId.AllVisa]: {
    id: ContentId.AllVisa,
    defaultValue: 'All Visa',
  },
  [ContentId.AlternativeTickets]: {
    id: ContentId.AlternativeTickets,
    defaultValue: 'Alternative Tickets',
  },
  [ContentId.AlternativeTicketsBuyerCompensation]: {
    id: ContentId.AlternativeTicketsBuyerCompensation,
    defaultValue: 'Alternative Tickets - Buyer Compensation',
  },
  [ContentId.AmountType]: {
    id: ContentId.AmountType,
    defaultValue: 'Amount Type',
  },
  [ContentId.Ampitheater]: {
    id: ContentId.Ampitheater,
    defaultValue: 'Ampitheater',
  },
  [ContentId.And]: {
    id: ContentId.And,
    defaultValue: 'And',
  },
  [ContentId.Angle]: {
    id: ContentId.Angle,
    defaultValue: 'Angle',
  },
  [ContentId.App]: {
    id: ContentId.App,
    defaultValue: 'App',
  },
  [ContentId.AppendSeatTraits]: {
    id: ContentId.AppendSeatTraits,
    defaultValue: 'Append Seat Trait',
  },
  [ContentId.ApplicationTempData]: {
    id: ContentId.ApplicationTempData,
    defaultValue: 'Application Temp Data',
  },
  [ContentId.Apply]: {
    id: ContentId.Apply,
    defaultValue: 'Apply',
  },
  [ContentId.ApplyCompListingSettings]: {
    id: ContentId.ApplyCompListingSettings,
    defaultValue: 'Apply Comp Listing Settings',
  },
  [ContentId.ApplyAdvancedPricingSettings]: {
    id: ContentId.ApplyAdvancedPricingSettings,
    defaultValue: 'Apply Advanced Pricing Settings',
  },
  [ContentId.ApplyFilter]: {
    id: ContentId.ApplyFilter,
    defaultValue: 'Apply filter',
  },
  [ContentId.ApplyGroupingTemplatesToAllEventsPrompt]: {
    id: ContentId.ApplyGroupingTemplatesToAllEventsPrompt,
    defaultValue:
      'Applying the grouping templates to all your events will override your current edits. Are you sure?',
  },
  [ContentId.ApplyAutoPricingSettings]: {
    id: ContentId.ApplyAutoPricingSettings,
    defaultValue: 'Apply Autopricing Settings',
  },
  [ContentId.ApplyPricingProtectionSettings]: {
    id: ContentId.ApplyPricingProtectionSettings,
    defaultValue: 'Apply Pricing Protection Settings',
  },
  [ContentId.ApplyToAll]: {
    id: ContentId.ApplyToAll,
    defaultValue: 'Apply to all',
  },
  [ContentId.ApplyToAllEvents]: {
    id: ContentId.ApplyToAllEvents,
    defaultValue: 'Apply to All Events',
  },
  [ContentId.ApplyUndercutSettings]: {
    id: ContentId.ApplyUndercutSettings,
    defaultValue: 'Apply Undercut Settings',
  },
  [ContentId.Approved]: {
    id: ContentId.Approved,
    defaultValue: 'Approved',
  },
  [ContentId.ApprovedForQualityControl]: {
    id: ContentId.ApprovedForQualityControl,
    defaultValue: 'Approved for Quality Control',
  },
  [ContentId.AppVersion]: {
    id: ContentId.AppVersion,
    defaultValue: 'Application Version',
  },
  [ContentId.Area]: {
    id: ContentId.Area,
    defaultValue: 'Area',
  },
  [ContentId.AreYouSure]: {
    id: ContentId.AreYouSure,
    defaultValue: 'Are you sure?',
  },
  [ContentId.AreYouSureEditRowsMultipleSections]: {
    id: ContentId.AreYouSureEditRowsMultipleSections,
    defaultValue:
      'Are you sure you want to edit rows across multiple sections?',
  },
  [ContentId.AreYouSureYouWantToRestoreThisPurchase]: {
    id: ContentId.AreYouSureYouWantToRestoreThisPurchase,
    defaultValue: 'Are you sure you want to restore this purchase?',
  },
  [ContentId.AutoComp]: {
    id: ContentId.AutoComp,
    defaultValue: 'Auto Comp',
  },
  [ContentId.AutoFulfillWithSpace]: {
    id: ContentId.AutoFulfillWithSpace,
    defaultValue: 'Auto Fulfill',
  },
  [ContentId.AutoGroup]: {
    id: ContentId.AutoGroup,
    defaultValue: 'Auto Group',
  },
  [ContentId.BulkAppendSeatTraitInfoMessage]: {
    id: ContentId.BulkAppendSeatTraitInfoMessage,
    defaultValue: 'Add new seat traits without removing existing ones.',
  },
  [ContentId.BulkEditDeliveryTypePurchaseMessage]: {
    id: ContentId.BulkEditDeliveryTypePurchaseMessage,
    defaultValue:
      'Updating delivery on the purchase order will also update the corresponding listings.',
  },
  [ContentId.BulkEditGettingPreview]: {
    id: ContentId.BulkEditGettingPreview,
    defaultValue: "We're generating a preview of your bulk edit.",
  },
  [ContentId.BulkEditListingGroupSettings]: {
    id: ContentId.BulkEditListingGroupSettings,
    defaultValue: 'Bulk Edit Listing Group Settings',
  },
  [ContentId.BulkEditListingGroupSettingsWarning]: {
    id: ContentId.BulkEditListingGroupSettingsWarning,
    defaultValue:
      'The changes will apply exclusively to listing groups. The preview will display all listings that belong to any impacted listing group.',
  },
  [ContentId.BulkEditNoCommissionProvidedWarning]: {
    id: ContentId.BulkEditNoCommissionProvidedWarning,
    defaultValue:
      'Providing no commissions will clear commissions for all selected purchases.',
  },
  [ContentId.BulkEditNoSeatTraitsProvidedWarning]: {
    id: ContentId.BulkEditNoSeatTraitsProvidedWarning,
    defaultValue:
      'Providing no seat traits will clear seat traits for all selected items.',
  },
  [ContentId.BulkEditSuccessWithWarnings]: {
    id: ContentId.BulkEditSuccessWithWarnings,
    defaultValue: 'The process completed with some warnings.',
  },
  [ContentId.BulkEditTicketTypePriorityInstruction]: {
    id: ContentId.BulkEditTicketTypePriorityInstruction,
    defaultValue:
      'Select the delivery type to update the delivery strategy. Only listings that match the selected delivery type will be updated.',
  },
  [ContentId.BulkMarkPurchaseAsPaid]: {
    id: ContentId.BulkMarkPurchaseAsPaid,
    defaultValue: 'Mark Purchase As Paid',
  },
  [ContentId.BulkMarkPurchaseAsPaidMessage]: {
    id: ContentId.BulkMarkPurchaseAsPaidMessage,
    defaultValue:
      'You are about to mark all the selected purchase order as paid. Continue?',
  },
  [ContentId.BulkMarkPurchaseAsPaidWarning]: {
    id: ContentId.BulkMarkPurchaseAsPaidWarning,
    defaultValue:
      'If there is any vendor account of the select purchase order does not have a default payment method, the system will auto create one for your account.',
  },
  [ContentId.BulkReplaceAndOverrideSeatTraitInfoMessage]: {
    id: ContentId.BulkReplaceAndOverrideSeatTraitInfoMessage,
    defaultValue:
      'Overwrite all existing seat traits with new ones. Providing no seat traits will clear traits for all selected items.',
  },
  [ContentId.BulkUpdatePlaceholdersMessage]: {
    id: ContentId.BulkUpdatePlaceholdersMessage,
    defaultValue:
      'Bulk action will apply only to the selected placeholder listing(s).',
  },
  [ContentId.BulkUpdateSeatTraitInfoMessage]: {
    id: ContentId.BulkUpdateSeatTraitInfoMessage,
    defaultValue:
      'Updating will override the current seat traits with the selected traits.',
  },
  [ContentId.BuyerAccount]: {
    id: ContentId.BuyerAccount,
    defaultValue: 'Buyer Account',
  },
  [ContentId.BuyerDelivery]: {
    id: ContentId.BuyerDelivery,
    defaultValue: 'Buyer Delivery',
  },
  [ContentId.CancelBulkTicketGroupWarning]: {
    id: ContentId.CancelBulkTicketGroupWarning,
    defaultValue:
      'Are you sure you want to cancel the selected ticket group(s)? This action is irreversible. Associated sales will be deallocated and moved to Pending Confirmation.',
  },
  [ContentId.CancelBulkPurchaseWarning]: {
    id: ContentId.CancelBulkPurchaseWarning,
    defaultValue:
      'Are you sure you want to cancel the selected purchase order(s)? This action is reversible. Associated sales will be deallocated and moved to Pending Confirmation.',
  },
  [ContentId.CancelPurchases]: {
    id: ContentId.CancelPurchases,
    defaultValue: 'Cancel Purchases',
  },
  [ContentId.CancelSingleTicketGroupWarning]: {
    id: ContentId.CancelSingleTicketGroupWarning,
    defaultValue:
      'Are you sure you want to cancel this ticket group? This action is irreversible. Associated sales will be deallocated and moved to Pending Confirmation.',
  },
  [ContentId.CancelTicketGroupsParenth]: {
    id: ContentId.CancelTicketGroupsParenth,
    defaultValue: 'Cancel Ticket Group(s)',
  },
  [ContentId.CancelPurchasesParenth]: {
    id: ContentId.CancelPurchasesParenth,
    defaultValue: 'Cancel Purchase(s)',
  },
  [ContentId.Carts]: {
    id: ContentId.Carts,
    defaultValue: 'Carts',
  },
  [ContentId.Ceiling]: {
    id: ContentId.Ceiling,
    defaultValue: 'Ceiling',
  },
  [ContentId.ChargeAmount]: {
    id: ContentId.ChargeAmount,
    defaultValue: 'Charge Amount',
  },
  [ContentId.Cheaper]: {
    id: ContentId.Cheaper,
    defaultValue: 'Cheaper',
  },
  [ContentId.ChooseListing]: {
    id: ContentId.ChooseListing,
    defaultValue: 'Choose Listing',
  },
  [ContentId.ChooseTickets]: {
    id: ContentId.ChooseTickets,
    defaultValue: 'Choose Tickets',
  },
  [ContentId.Clear]: {
    id: ContentId.Clear,
    defaultValue: 'Clear',
  },
  [ContentId.CombineCornersAndEnds]: {
    id: ContentId.CombineCornersAndEnds,
    defaultValue: 'Combine corners and ends',
  },
  [ContentId.Commands]: {
    id: ContentId.Commands,
    defaultValue: 'Commands',
  },
  [ContentId.Conflict]: {
    id: ContentId.Conflict,
    defaultValue: 'Conflict',
  },
  [ContentId.CommissionPayments]: {
    id: ContentId.CommissionPayments,
    defaultValue: 'Commission Payments',
  },
  [ContentId.ConnectedSeller]: {
    id: ContentId.ConnectedSeller,
    defaultValue: 'Connected Seller',
  },
  [ContentId.ConvertPayment]: {
    id: ContentId.ConvertPayment,
    defaultValue: 'Convert Payment',
  },
  [ContentId.ConvertPurchasePayment]: {
    id: ContentId.ConvertPurchasePayment,
    defaultValue: 'Convert Purchase Payment',
  },
  [ContentId.ConvertCurrency]: {
    id: ContentId.ConvertCurrency,
    defaultValue: 'Convert Currency',
  },
  [ContentId.ConvertedPayment]: {
    id: ContentId.ConvertedPayment,
    defaultValue: 'Converted Payment',
  },
  [ContentId.ConvertedPaymentAmountMustBeGreaterThanZero]: {
    id: ContentId.ConvertedPaymentAmountMustBeGreaterThanZero,
    defaultValue: 'Converted payment amount must be greater than zero',
  },
  [ContentId.ConvertedTo]: {
    id: ContentId.ConvertedTo,
    defaultValue: 'Converted to',
  },
  [ContentId.ConvPmtAmt]: {
    id: ContentId.ConvPmtAmt,
    defaultValue: 'Conv. Pmt Amt',
  },
  [ContentId.CreditAmount]: {
    id: ContentId.CreditAmount,
    defaultValue: 'Credit Amount',
  },
  [ContentId.CustomRanks]: {
    id: ContentId.CustomRanks,
    defaultValue: 'Custom Ranks',
  },
  [ContentId.DateRange]: {
    id: ContentId.DateRange,
    defaultValue: 'Date Range',
  },
  [ContentId.DeallocateSalesOfAffectedTicketsWarning]: {
    id: ContentId.DeallocateSalesOfAffectedTicketsWarning,
    defaultValue: 'Deallocate all associated sales of the affected tickets.',
  },
  [ContentId.ChangeGroup]: {
    id: ContentId.ChangeGroup,
    defaultValue: 'Change Group',
  },
  [ContentId.ChangeZone]: {
    id: ContentId.ChangeZone,
    defaultValue: 'Change Zone',
  },
  [ContentId.CheckVenueRows]: {
    id: ContentId.CheckVenueRows,
    defaultValue: 'Check Venue Rows',
  },
  [ContentId.CompListingMinCount]: {
    id: ContentId.CompListingMinCount,
    defaultValue: 'Comp. Listing Min Count',
  },
  [ContentId.CompListingMinQuantity]: {
    id: ContentId.CompListingMinQuantity,
    defaultValue: 'Comp. Listing Min Quantity',
  },
  [ContentId.CopiedWithExclamationMark]: {
    id: ContentId.CopiedWithExclamationMark,
    defaultValue: 'Copied!',
  },
  [ContentId.DefaultDelivery]: {
    id: ContentId.DefaultDelivery,
    defaultValue: 'Default Delivery',
  },
  [ContentId.Defect]: {
    id: ContentId.Defect,
    defaultValue: 'Defect',
  },
  [ContentId.DefaultDeliveryConfigurations]: {
    id: ContentId.DefaultDeliveryConfigurations,
    defaultValue: 'Default Delivery Configurations',
  },
  [ContentId.DefaultDeliveryConfigurationsDescription]: {
    id: ContentId.DefaultDeliveryConfigurationsDescription,
    defaultValue: 'Set default settings for new purchases with this vendor',
  },
  [ContentId.DelayBroadcastAfterInitialCreation]: {
    id: ContentId.DelayBroadcastAfterInitialCreation,
    defaultValue: 'Delay Broadcast After Initial Creation (Days)',
  },
  [ContentId.DeletePlaceholders]: {
    id: ContentId.DeletePlaceholders,
    defaultValue: 'Delete Placeholders',
  },
  [ContentId.DeliveryInfoChangePropagateToSalesConfirmation]: {
    id: ContentId.DeliveryInfoChangePropagateToSalesConfirmation,
    defaultValue:
      'You are about to update the delivery information. Would you also like to update the delivery information for associated sales?',
  },
  [ContentId.DeliveryOverrideInfo]: {
    id: ContentId.DeliveryOverrideInfo,
    defaultValue:
      'Default values are provided by delivery overrides from the corresponding event or vendor.',
  },
  [ContentId.DeliveryStrategy]: {
    id: ContentId.DeliveryStrategy,
    defaultValue: 'Delivery Strategy',
  },
  [ContentId.DeprecatingHeatMaps]: {
    id: ContentId.DeprecatingHeatMaps,
    defaultValue:
      'Venue Geometry and Merged Venue Geometry heat maps are being deprecated. Please update to the latest recommended model.',
  },
  [ContentId.Details]: {
    id: ContentId.Details,
    defaultValue: 'Details',
  },
  [ContentId.DiscountApplied]: {
    id: ContentId.DiscountApplied,
    defaultValue: 'Discount applied',
  },
  [ContentId.DonotDropMoreThan]: {
    id: ContentId.DonotDropMoreThan,
    defaultValue: "Don't drop more than",
  },
  [ContentId.DontUpdateExistingListings]: {
    id: ContentId.DontUpdateExistingListings,
    defaultValue: "Don't update existing listings",
  },
  [ContentId.DontUpdateExistingListingsOrSales]: {
    id: ContentId.DontUpdateExistingListingsOrSales,
    defaultValue: "Don't update existing listings or sales",
  },
  [ContentId.DownloadETickets]: {
    id: ContentId.DownloadETickets,
    defaultValue: 'Download E-Tickets',
  },
  [ContentId.Drafted]: {
    id: ContentId.Drafted,
    defaultValue: 'Drafted',
  },
  [ContentId.DontUpdateExistingPurchasesOrListings]: {
    id: ContentId.DontUpdateExistingPurchasesOrListings,
    defaultValue: "Don't update existing purchases or listings",
  },
  [ContentId.DropYourFileAnywhereToUpload]: {
    id: ContentId.DropYourFileAnywhereToUpload,
    defaultValue: 'Drop your file anywhere to upload',
  },
  [ContentId.Edited]: {
    id: ContentId.Edited,
    defaultValue: 'Edited',
  },
  [ContentId.EditMap]: {
    id: ContentId.EditMap,
    defaultValue: 'Edit Map',
  },
  [ContentId.EditZone]: {
    id: ContentId.EditZone,
    defaultValue: 'Edit Zone',
  },
  [ContentId.EnableAutoFulfillOverride]: {
    id: ContentId.EnableAutoFulfillOverride,
    defaultValue: 'Enable AutoFulfill Override',
  },
  [ContentId.EnableDeliveryOverride]: {
    id: ContentId.EnableDeliveryOverride,
    defaultValue: 'Enable Delivery Override',
  },
  [ContentId.EnableNativeSyncLabel]: {
    id: ContentId.EnableNativeSyncLabel,
    defaultValue: 'Process via native system',
  },
  [ContentId.EnableNativeSyncExplanation]: {
    id: ContentId.EnableNativeSyncExplanation,
    defaultValue:
      'Remove the listing from the external processor to handle it natively in our system',
  },
  [ContentId.EnterCompanionMode]: {
    id: ContentId.EnterCompanionMode,
    defaultValue: 'Enter Companion Mode',
  },
  [ContentId.EventConfig_Create_Details]: {
    id: ContentId.EventConfig_Create_Details,
    defaultValue: 'Ability to create event settings',
  },
  [ContentId.EventConfig_Edit_Details]: {
    id: ContentId.EventConfig_Edit_Details,
    defaultValue: 'Ability to edit event settings',
  },
  [ContentId.EventConfig_View_Details]: {
    id: ContentId.EventConfig_View_Details,
    defaultValue: 'Ability to view event settings',
  },
  [ContentId.EventNotReviewedSince]: {
    id: ContentId.EventNotReviewedSince,
    defaultValue: 'Event Not Reviewed Since',
  },
  [ContentId.EventState]: {
    id: ContentId.EventState,
    defaultValue: 'Event State',
  },
  [ContentId.ExistingSections]: {
    id: ContentId.ExistingSections,
    defaultValue: 'Existing Sections',
  },
  [ContentId.ExitCompanionMode]: {
    id: ContentId.ExitCompanionMode,
    defaultValue: 'Exit Companion Mode',
  },
  [ContentId.ExperimentName]: {
    id: ContentId.ExperimentName,
    defaultValue: 'Experiment Name',
  },
  [ContentId.Experiments]: {
    id: ContentId.Experiments,
    defaultValue: 'Experiments',
  },
  [ContentId.ExportPurchases]: {
    id: ContentId.ExportPurchases,
    defaultValue: 'Export Purchases',
  },
  [ContentId.ExternalPaymentId]: {
    id: ContentId.ExternalPaymentId,
    defaultValue: 'External Payment ID',
  },
  [ContentId.ExternalPurchaseIdAbbreviated]: {
    id: ContentId.ExternalPurchaseIdAbbreviated,
    defaultValue: 'External PO Id',
  },
  [ContentId.FallbackSplit]: {
    id: ContentId.FallbackSplit,
    defaultValue: 'Fallback Split',
  },
  [ContentId.FallbackSplitInfo]: {
    id: ContentId.FallbackSplitInfo,
    defaultValue:
      'In case your hurdle rules overlapped, you can setup a fallback hurdle to make sure what should be assigned.',
  },
  [ContentId.Filled]: {
    id: ContentId.Filled,
    defaultValue: 'Filled',
  },
  [ContentId.FX]: {
    id: ContentId.FX,
    defaultValue: 'FX',
  },
  [ContentId.FXRate]: {
    id: ContentId.FXRate,
    defaultValue: 'FX Rate',
  },
  [ContentId.GeneratingPreview]: {
    id: ContentId.GeneratingPreview,
    defaultValue: 'Generating Preview',
  },
  [ContentId.HasUnpricedListings]: {
    id: ContentId.HasUnpricedListings,
    defaultValue: 'Has Unpriced Listings',
  },
  [ContentId.GUR]: {
    id: ContentId.GUR,
    defaultValue: 'GUR (Guaranteed)',
  },
  [ContentId.Hour]: {
    id: ContentId.Hour,
    defaultValue: 'Hour',
  },
  [ContentId.Hours]: {
    id: ContentId.Hours,
    defaultValue: 'Hours',
  },
  [ContentId.IfPurchaseIsCreatedWith]: {
    id: ContentId.IfPurchaseIsCreatedWith,
    defaultValue: 'If purchase is created with',
  },
  [ContentId.IncludeDefects]: {
    id: ContentId.IncludeDefects,
    defaultValue: 'Include Defects',
  },
  [ContentId.IncludeHeldListings]: {
    id: ContentId.IncludeHeldListings,
    defaultValue: 'Include Held Listings',
  },
  [ContentId.Inferred]: {
    id: ContentId.Inferred,
    defaultValue: 'Inferred',
  },
  [ContentId.Inventory_UpdateDeliveryStrategy_Details]: {
    id: ContentId.Inventory_UpdateDeliveryStrategy_Details,
    defaultValue: 'Permits updating delivery strategy of inventory',
  },
  [ContentId.Inventory_UpdateDeliveryStrategyOnIsPricerOf_Details]: {
    id: ContentId.Inventory_UpdateDeliveryStrategyOnIsPricerOf_Details,
    defaultValue:
      'Permits updating delivery strategy of inventory where the user is the pricer',
  },
  [ContentId.Inventory_UpdateDeliveryStrategyOnPurchasedBy_Details]: {
    id: ContentId.Inventory_UpdateDeliveryStrategyOnPurchasedBy_Details,
    defaultValue:
      'Permits updating delivery strategy of inventory that are purchased by the current user',
  },
  [ContentId.Information]: {
    id: ContentId.Information,
    defaultValue: 'Information',
  },
  [ContentId.InternallyGenerated]: {
    id: ContentId.InternallyGenerated,
    defaultValue: 'Internally Generated',
  },
  [ContentId.ItemsWillBeUpdatedIfApplicable]: {
    id: ContentId.ItemsWillBeUpdatedIfApplicable,
    defaultValue: 'items will be updated if applicable',
  },
  [ContentId.KeepExistingTicketsOption]: {
    id: ContentId.KeepExistingTicketsOption,
    defaultValue:
      'Keep existing tickets: Retain the tickets already associated with the sale.',
  },
  [ContentId.Last3Days]: {
    id: ContentId.Last3Days,
    defaultValue: 'Last 3 Days',
  },
  [ContentId.Last2Weeks]: {
    id: ContentId.Last2Weeks,
    defaultValue: 'Last 2 Weeks',
  },
  [ContentId.ListingQuantity]: {
    id: ContentId.ListingQuantity,
    defaultValue: 'Listing Quantity',
  },
  [ContentId.ListingUpdateConfirmation]: {
    id: ContentId.ListingUpdateConfirmation,
    defaultValue: 'Listing Updates Confirmation',
  },
  [ContentId.ListingWillBeSplitInto]: {
    id: ContentId.ListingWillBeSplitInto,
    defaultValue: 'Listing will be split into the following:',
  },
  [ContentId.MappingError]: {
    id: ContentId.MappingError,
    defaultValue: 'Mapping Error',
  },
  [ContentId.MarkAsFulfilledWarning]: {
    id: ContentId.MarkAsFulfilledWarning,
    defaultValue:
      'This action will mark the selected sales as Fulfilled without uploading fulfillment artifacts. Sales not handled by the processor or those that cannot be fulfilled will not be changed. Continue?',
  },
  [ContentId.MarkedAsReviewed]: {
    id: ContentId.MarkedAsReviewed,
    defaultValue: 'Marked as Reviewed',
  },
  [ContentId.MarketplaceDelivery]: {
    id: ContentId.MarketplaceDelivery,
    defaultValue: 'Marketplace Delivery',
  },
  [ContentId.MarketplacePayments]: {
    id: ContentId.MarketplacePayments,
    defaultValue: 'Marketplace Payments',
  },
  [ContentId.MarketShare]: {
    id: ContentId.MarketShare,
    defaultValue: 'Market Share',
  },
  [ContentId.MarkPurchaseAsPaid]: {
    id: ContentId.MarkPurchaseAsPaid,
    defaultValue: 'Mark As Paid',
  },
  [ContentId.MergedGroupListingWarning]: {
    id: ContentId.MergedGroupListingWarning,
    defaultValue:
      'One or more selected listings are part of a group. Merging them will ungroup and unbroadcast the newly merged listing. Continue',
  },
  [ContentId.MergeListingGroupTooltip]: {
    id: ContentId.MergeListingGroupTooltip,
    defaultValue: 'This listing is part of a group:',
  },
  [ContentId.MinListingsRequired]: {
    id: ContentId.MinListingsRequired,
    defaultValue: 'Minimum Listings Required',
  },
  [ContentId.MoreExpensive]: {
    id: ContentId.MoreExpensive,
    defaultValue: 'More Expensive',
  },
  [ContentId.Multiple]: {
    id: ContentId.Multiple,
    defaultValue: 'Multiple',
  },
  [ContentId.Empty]: {
    id: ContentId.Empty,
    defaultValue: '(Empty)',
  },
  [ContentId.NextTicket]: {
    id: ContentId.NextTicket,
    defaultValue: 'Next Ticket',
  },
  [ContentId.NoHurdle]: {
    id: ContentId.NoHurdle,
    defaultValue: 'No Hurdle',
  },
  [ContentId.NoItemsWereEligibleForUpdate]: {
    id: ContentId.NoItemsWereEligibleForUpdate,
    defaultValue:
      'There are no items applicable for this edit. Please check your selection.',
  },
  [ContentId.NoResults]: {
    id: ContentId.NoResults,
    defaultValue: 'No Results',
  },
  [ContentId.Normal]: {
    id: ContentId.Normal,
    defaultValue: 'Normal',
  },
  [ContentId.Draft]: {
    id: ContentId.Draft,
    defaultValue: 'Draft',
  },
  [ContentId.Contingent]: {
    id: ContentId.Contingent,
    defaultValue: 'Contingent',
  },
  [ContentId.NotificationTypeListingPriceUpdate]: {
    id: ContentId.NotificationTypeListingPriceUpdate,
    defaultValue: 'Listing Price Update',
  },
  [ContentId.NotMapped]: {
    id: ContentId.NotMapped,
    defaultValue: 'Not mapped',
  },
  [ContentId.OpenEventPageV2InDevelopment]: {
    id: ContentId.OpenEventPageV2InDevelopment,
    defaultValue: 'Open Event Page v2 (In Development)',
  },
  [ContentId.OriginalSeating]: {
    id: ContentId.OriginalSeating,
    defaultValue: 'Original Seating',
  },
  [ContentId.OriginatedListing]: {
    id: ContentId.OriginatedListing,
    defaultValue: 'Originated Listing',
  },
  [ContentId.OutlierLimit]: {
    id: ContentId.OutlierLimit,
    defaultValue: 'Outlier Limit',
  },
  [ContentId.OutlierSpacing]: {
    id: ContentId.OutlierSpacing,
    defaultValue: 'Outlier Spacing',
  },
  [ContentId.P25]: {
    id: ContentId.P25,
    defaultValue: 'P25',
  },
  [ContentId.P50]: {
    id: ContentId.P50,
    defaultValue: 'P50',
  },
  [ContentId.PartiallyRefunded]: {
    id: ContentId.PartiallyRefunded,
    defaultValue: 'Partially Refunded',
  },
  [ContentId.Page]: {
    id: ContentId.Page,
    defaultValue: 'Page',
  },
  [ContentId.PaymentAmount]: {
    id: ContentId.PaymentAmount,
    defaultValue: 'Payment Amount',
  },
  [ContentId.PaymentReferenceID]: {
    id: ContentId.PaymentReferenceID,
    defaultValue: 'Payment Reference ID',
  },
  [ContentId.PayRef]: {
    id: ContentId.PayRef,
    defaultValue: 'Pay Ref',
  },
  [ContentId.PayRec]: {
    id: ContentId.PayRec,
    defaultValue: 'Pay Rec',
  },
  [ContentId.PayState]: {
    id: ContentId.PayState,
    defaultValue: 'Pay State',
  },
  [ContentId.PendingReshipment]: {
    id: ContentId.PendingReshipment,
    defaultValue: 'Pending Reshipment',
  },
  [ContentId.PerformerVenueSpecific]: {
    id: ContentId.PerformerVenueSpecific,
    defaultValue: 'Performer and Venue Specific',
  },
  [ContentId.AssignQRCodesIns]: {
    id: ContentId.AssignQRCodesIns,
    defaultValue: 'Assign an QR code for each seat',
  },
  [ContentId.AssignToDefaultPurchaser]: {
    id: ContentId.AssignToDefaultPurchaser,
    defaultValue: 'Assign to Default',
  },
  [ContentId.at]: {
    id: ContentId.at,
    defaultValue: 'at',
  },
  [ContentId.AtoZ]: {
    id: ContentId.AtoZ,
    defaultValue: 'A to Z',
  },
  [ContentId.AddTicketsForAnotherEvent]: {
    id: ContentId.AddTicketsForAnotherEvent,
    defaultValue: 'Add Tickets for Another Event',
  },
  [ContentId.AddUrl]: {
    id: ContentId.AddUrl,
    defaultValue: 'Add Url',
  },
  [ContentId.AddVendorAction]: {
    id: ContentId.AddVendorAction,
    defaultValue: 'Add new vendor',
  },
  [ContentId.All]: {
    id: ContentId.All,
    defaultValue: 'All',
  },
  [ContentId.AllDeliveryOptions]: {
    id: ContentId.AllDeliveryOptions,
    defaultValue: 'All Delivery Types',
  },
  [ContentId.AllEvents]: {
    id: ContentId.AllEvents,
    defaultValue: 'All Events',
  },
  /**
   * @deprecated Use `ContentId.WebsitePrice`
   */
  [ContentId.AllIn]: {
    id: ContentId.AllIn,
    defaultValue: 'All In',
  },
  [ContentId.AllMerchants]: {
    id: ContentId.AllMerchants,
    defaultValue: 'All Merchants',
  },
  [ContentId.AllocatedSeats]: {
    id: ContentId.AllocatedSeats,
    defaultValue: 'Allocated Seats',
  },
  [ContentId.AllocateTickets]: {
    id: ContentId.AllocateTickets,
    defaultValue: 'Allocate Tickets',
  },
  [ContentId.AllPaymentMethods]: {
    id: ContentId.AllPaymentMethods,
    defaultValue: 'All Payment Methods',
  },
  [ContentId.AllPaymentStates]: {
    id: ContentId.AllPaymentStates,
    defaultValue: 'All Payment States',
  },
  [ContentId.AllSaleStatuses]: {
    id: ContentId.AllSaleStatuses,
    defaultValue: 'All Sale Statuses',
  },
  [ContentId.AllTicketTypes]: {
    id: ContentId.AllTicketTypes,
    defaultValue: 'All Ticket Types',
  },
  [ContentId.AllTime]: {
    id: ContentId.AllTime,
    defaultValue: 'All time',
  },
  [ContentId.AllVendors]: {
    id: ContentId.AllVendors,
    defaultValue: 'All Vendors',
  },
  [ContentId.AllVendorAccounts]: {
    id: ContentId.AllVendorAccounts,
    defaultValue: 'All Vendor Accounts',
  },
  [ContentId.AlreadyInHand]: {
    id: ContentId.AlreadyInHand,
    defaultValue: 'Already in hand',
  },
  [ContentId.AmendTickets]: {
    id: '',
    defaultValue: 'Amend Tickets',
  },
  [ContentId.Amount]: {
    id: ContentId.Amount,
    defaultValue: 'Amount',
  },
  [ContentId.AmountPaid]: {
    id: ContentId.AmountPaid,
    defaultValue: 'Amount Paid',
  },
  [ContentId.AnUploadedDocumentIsRequired]: {
    id: ContentId.AnUploadedDocumentIsRequired,
    defaultValue: 'An uploaded image or document is required.',
  },
  [ContentId.Any]: {
    id: ContentId.Any,
    defaultValue: 'Any',
  },
  [ContentId.AssignETicketsIns]: {
    id: ContentId.AssignETicketsIns,
    defaultValue: 'Assign an e-Ticket for each seat.',
  },
  [ContentId.AtLeastOneUrlRequired]: {
    id: ContentId.AtLeastOneUrlRequired,
    defaultValue: 'At least one URL is required.',
  },
  [ContentId.AtoZArrow]: {
    id: ContentId.AtoZArrow,
    defaultValue: 'AZ ↑',
  },
  [ContentId.ATP]: {
    id: ContentId.ATP,
    defaultValue: 'ATP',
  },
  [ContentId.Attachment]: {
    id: ContentId.Attachment,
    defaultValue: 'Attachment',
  },
  [ContentId.AutoBroadcastNewListing]: {
    id: ContentId.AutoBroadcastNewListing,
    defaultValue: 'Auto-broadcast new listing',
  },
  [ContentId.AutoBroadcastRequiredFaceValue]: {
    id: ContentId.AutoBroadcastRequiredFaceValue,
    defaultValue:
      'Auto-broadcast listing requires Face Value to be greater than 0.',
  },
  [ContentId.AutoCompAndGroupListings]: {
    id: ContentId.AutoCompAndGroupListings,
    defaultValue: 'Auto-Comp and Group Listings',
  },
  [ContentId.AutoConversion]: {
    id: ContentId.AutoConversion,
    defaultValue: 'Auto Conversion',
  },
  [ContentId.AutoConversionSubtitle]: {
    id: ContentId.AutoConversionSubtitle,
    defaultValue:
      "Enabling auto-currency will automatically adjust listing costs from non-local currencies to your preferred currency, using the FX rate applicable on the purchase date. Please note that this won't impact your previous listings and will only start converting listings for newly created purchase orders.",
  },
  [ContentId.AutoFulfill]: {
    id: ContentId.AutoFulfill,
    defaultValue: 'AutoFulfill',
  },
  [ContentId.Automations]: {
    id: ContentId.Automations,
    defaultValue: 'Automations',
  },
  [ContentId.AutoPO]: {
    id: ContentId.AutoPO,
    defaultValue: 'AutoPO',
  },
  [ContentId.AutoPoConfiguration]: {
    id: ContentId.AutoPoConfiguration,
    defaultValue: 'Auto-Po Configurations',
  },
  [ContentId.AutoPoConfigurationDescription]: {
    id: ContentId.AutoPoConfigurationDescription,
    defaultValue:
      'Only vendors and countries specified here will be considered in our automatic PO generation.',
  },
  [ContentId.AutoPoVendorConfigutationDescription]: {
    id: ContentId.AutoPoVendorConfigutationDescription,
    defaultValue: "Vendors can be either 'Included' or 'Excluded' - not both.",
  },
  [ContentId.AutoPriceFrequency]: {
    id: ContentId.AutoPriceFrequency,
    defaultValue: 'Auto Pricing Frequency',
  },
  [ContentId.AutoPricePreview]: {
    id: ContentId.AutoPricePreview,
    defaultValue: 'Auto-pricing Preview',
  },
  [ContentId.AutoPricePreviewSubTitle]: {
    id: ContentId.AutoPricePreviewSubTitle,
    defaultValue: 'Based on the selections below.',
  },
  [ContentId.AutoPricer]: {
    id: ContentId.AutoPricer,
    defaultValue: 'AutoPricer',
  },
  [ContentId.Autopricing]: {
    id: ContentId.Autopricing,
    defaultValue: 'Autopricing',
  },
  [ContentId.AutoPricingAllListingsForEvent]: {
    id: ContentId.AutoPricingAllListingsForEvent,
    defaultValue: 'Event-wide Autopricing',
  },
  [ContentId.AutoPricingAllResultTypeOption]: {
    id: ContentId.AutoPricingAllResultTypeOption,
    defaultValue: 'All Result Types',
  },
  [ContentId.AutoPricingDisclaimer]: {
    id: ContentId.AutoPricingDisclaimer,
    defaultValue:
      'We aim to provide an estimated price for the website that most buyers are likely to encounter. However, please be aware that we cannot guarantee this price will be consistent for all buyers in every situation.',
  },
  [ContentId.AutoPricingEnabled]: {
    id: ContentId.AutoPricingEnabled,
    defaultValue: 'AutoPrice Enabled',
  },
  [ContentId.AutopricingNotAvailableForGA]: {
    id: ContentId.AutopricingNotAvailableForGA,
    defaultValue: 'Autopricing is not available for GA-only event',
  },
  [ContentId.AutopricingNotAvailableMissingMap]: {
    id: ContentId.AutopricingNotAvailableMissingMap,
    defaultValue: 'Autopricing is not available - seat map unavailable',
  },
  [ContentId.AutopricingNotAvailableNoScoresGeneral]: {
    id: ContentId.AutopricingNotAvailableNoScoresGeneral,
    defaultValue:
      'This model has no section scores. Section scores are required for the Deal Undercut Mode and Venue Wide Comp. Listing Modes.',
  },
  [ContentId.AutopricingNotAvailableNoScores]: {
    id: ContentId.AutopricingNotAvailableNoScores,
    defaultValue:
      'This mode is only available when there are section scores in your heat map.',
  },
  [ContentId.AutoPricingResultType]: {
    id: ContentId.AutoPricingResultType,
    defaultValue: 'Autopricing Result',
  },
  [ContentId.AutopricingSettings]: {
    id: ContentId.AutopricingSettings,
    defaultValue: 'Autopricing Settings',
  },
  [ContentId.AutopricingSettingsAll]: {
    id: ContentId.AutopricingSettingsAll,
    defaultValue: 'Autopricing Settings for All',
  },
  [ContentId.AutopricingSettingsAllVisible]: {
    id: ContentId.AutopricingSettingsAllVisible,
    defaultValue: 'Autopricing Settings for All Visible Listings',
  },
  [ContentId.AutopricingSettingsSelected]: {
    id: ContentId.AutopricingSettingsSelected,
    defaultValue: 'Autopricing Settings for Selected',
  },
  [ContentId.AutopricingStrategy]: {
    id: ContentId.AutopricingStrategy,
    defaultValue: 'Autopricing Strategy',
  },
  [ContentId.AutoSetup]: {
    id: ContentId.AutoSetup,
    defaultValue: 'Auto setup',
  },
  [ContentId.Available]: {
    id: ContentId.Available,
    defaultValue: 'Available',
  },
  [ContentId.AvailableInstruction]: {
    id: ContentId.AvailableInstruction,
    defaultValue: 'Tap section to see price and availability',
  },
  [ContentId.AvailableSeats]: {
    id: ContentId.AvailableSeats,
    defaultValue: 'Available Seats',
  },
  [ContentId.AverageTicketPrice]: {
    id: ContentId.AverageTicketPrice,
    defaultValue: 'Average Ticket Price',
  },
  [ContentId.AvoidOne]: {
    id: ContentId.AvoidOne,
    defaultValue: 'Avoid One',
  },
  [ContentId.AvoidOneAndThree]: {
    id: ContentId.AvoidOneAndThree,
    defaultValue: 'Avoid One and Three',
  },
  [ContentId.Back]: {
    id: ContentId.Back,
    defaultValue: 'Back',
  },
  [ContentId.BankTransfer]: {
    id: ContentId.BankTransfer,
    defaultValue: 'Bank Transfer',
  },
  [ContentId.Barcode]: {
    id: ContentId.Barcode,
    defaultValue: 'Barcode',
  },
  [ContentId.BarcodeAlreadyInUsed]: {
    id: ContentId.BarcodeAlreadyInUsed,
    defaultValue: 'Warning - barcode is already used by another ticket.',
  },
  [ContentId.BarcodeRequired]: {
    id: ContentId.BarcodeRequired,
    defaultValue: 'Barcode required.',
  },
  [ContentId.Below]: {
    id: ContentId.Below,
    defaultValue: 'Below',
  },
  [ContentId.BelowTheNextCheapest]: {
    id: ContentId.BelowTheNextCheapest,
    defaultValue: 'below the next cheapest',
  },
  [ContentId.BestRowExtrapolation]: {
    id: ContentId.BestRowExtrapolation,
    defaultValue: 'Best Row Extrapolation',
  },
  [ContentId.BestDealScore]: {
    id: ContentId.BestDealScore,
    defaultValue: 'Best Deal Score',
  },
  [ContentId.Between]: {
    id: ContentId.Between,
    defaultValue: 'Between',
  },
  [ContentId.Better]: {
    id: ContentId.Better,
    defaultValue: 'better',
  },
  [ContentId.BestDealScorePrompt]: {
    id: ContentId.BestDealScorePrompt,
    defaultValue:
      'Calculate what to price at to have the same Deal Score, then: ',
  },
  [ContentId.Blocked]: {
    id: ContentId.Blocked,
    defaultValue: 'Blocked',
  },
  [ContentId.Boolean]: {
    id: ContentId.Boolean,
    defaultValue: 'Boolean',
  },
  [ContentId.Broadcast]: {
    id: ContentId.Broadcast,
    defaultValue: 'Broadcast',
  },
  [ContentId.BroadcastAll]: {
    id: ContentId.BroadcastAll,
    defaultValue: 'Broadcast All',
  },
  [ContentId.BroadcastAllVisible]: {
    id: ContentId.BroadcastAllVisible,
    defaultValue: 'Broadcast All Visible Listings',
  },
  [ContentId.BroadcastByDefault]: {
    id: ContentId.BroadcastByDefault,
    defaultValue: 'Broadcast by default',
  },
  [ContentId.BroadcastDisabled]: {
    id: ContentId.BroadcastDisabled,
    defaultValue: 'Broadcast disabled',
  },
  [ContentId.Broadcasted]: {
    id: ContentId.Broadcasted,
    defaultValue: 'Broadcasted',
  },
  [ContentId.BroadcastError]: {
    id: ContentId.BroadcastError,
    defaultValue: 'Broadcast Error',
  },
  [ContentId.BroadcastErrorCode_BroadcastListing]: {
    id: ContentId.BroadcastErrorCode_BroadcastListing,
    defaultValue: 'Unable to broadcast the listing due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_Unknown]: {
    id: ContentId.BroadcastErrorCode_Unknown,
    defaultValue:
      'An unkown error has occurred while trying to broadcast the listing.',
  },
  [ContentId.BroadcastErrorCode_UpdatePrice]: {
    id: ContentId.BroadcastErrorCode_UpdatePrice,
    defaultValue:
      'Failed to update price on the marketplace due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_UpdateTicketType]: {
    id: ContentId.BroadcastErrorCode_UpdateTicketType,
    defaultValue:
      'Failed to update ticket type on the marketplace due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_UpdateSplitType]: {
    id: ContentId.BroadcastErrorCode_UpdateSplitType,
    defaultValue:
      'Failed to update split type on the marketplace due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_UpdateInHandDate]: {
    id: ContentId.BroadcastErrorCode_UpdateInHandDate,
    defaultValue:
      'Failed to update in-hand date on the marketplace due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_SplitListing]: {
    id: ContentId.BroadcastErrorCode_SplitListing,
    defaultValue:
      'Failed to update split listing on the marketplace due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_MergeListing]: {
    id: ContentId.BroadcastErrorCode_MergeListing,
    defaultValue:
      'Failed to update merged listings on the marketplace due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_UnbroadcastListing]: {
    id: ContentId.BroadcastErrorCode_UnbroadcastListing,
    defaultValue:
      'Unable to unbroadcast the listing due to an unknown reason due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_UpdateQuantity]: {
    id: ContentId.BroadcastErrorCode_UpdateQuantity,
    defaultValue:
      'Failed to update quantity on the marketplace due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_HideSeats]: {
    id: ContentId.BroadcastErrorCode_HideSeats,
    defaultValue:
      'Failed to update hide-seats on the marketplace due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_UpdateListingNotes]: {
    id: ContentId.BroadcastErrorCode_UpdateListingNotes,
    defaultValue:
      'Failed to update listing notes on the marketplace due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_UpdateSeating]: {
    id: ContentId.BroadcastErrorCode_UpdateSeating,
    defaultValue:
      'Failed to update seating on the marketplace due to an unknown reason.',
  },
  [ContentId.BroadcastErrorCode_MarketplaceResaleRestricted]: {
    id: ContentId.BroadcastErrorCode_MarketplaceResaleRestricted,
    defaultValue: 'Marketplace restricts resale.',
  },
  [ContentId.BroadcastErrorCode_MarketplaceManifestMismatch]: {
    id: ContentId.BroadcastErrorCode_MarketplaceManifestMismatch,
    defaultValue: 'Marketplace manifest mismatched.',
  },
  [ContentId.BroadcastErrorCode_MarketplacePriceBelowFloor]: {
    id: ContentId.BroadcastErrorCode_MarketplacePriceBelowFloor,
    defaultValue: 'Marketplace price is below the floor.',
  },
  [ContentId.BroadcastSelected]: {
    id: ContentId.BroadcastSelected,
    defaultValue: 'Broadcast Selected',
  },
  [ContentId.BroadcastSettings]: {
    id: ContentId.BroadcastSettings,
    defaultValue: 'Broadcast Settings',
  },
  [ContentId.BroadcastSettingsAllVisible]: {
    id: ContentId.BroadcastSettingsAllVisible,
    defaultValue: 'Broadcast Settings for All Visible Listings',
  },
  [ContentId.BroadcastSettingsForAll]: {
    id: ContentId.BroadcastSettingsForAll,
    defaultValue: 'Broadcast Settings for All',
  },
  [ContentId.BroadcastSettingsForSelected]: {
    id: ContentId.BroadcastSettingsForSelected,
    defaultValue: 'Broadcast Settings for Selected',
  },
  [ContentId.BrowseFile]: {
    id: ContentId.BrowseFile,
    defaultValue: 'Browse File',
  },
  [ContentId.BrowseFiles]: {
    id: ContentId.BrowseFiles,
    defaultValue: 'Browse Files',
  },
  [ContentId.BucketOptions]: {
    id: ContentId.BucketOptions,
    defaultValue: 'Bucket Options',
  },
  [ContentId.BugFixes]: {
    id: ContentId.BugFixes,
    defaultValue: 'Bug Fixes',
  },
  [ContentId.BuildACustomRole]: {
    id: ContentId.BuildACustomRole,
    defaultValue: 'Build a custom role',
  },
  [ContentId.BulkActions]: {
    id: ContentId.BulkActions,
    defaultValue: 'Bulk Actions',
  },
  [ContentId.BulkDeleteSelectedPlaceholderWarning]: {
    id: ContentId.BulkDeleteSelectedPlaceholderWarning,
    defaultValue:
      'You are about to delete the selected placeholder inventory. Continue?',
  },
  [ContentId.BulkDiscount]: {
    id: ContentId.BulkDiscount,
    defaultValue: 'Bulk Discount',
  },
  [ContentId.BulkEditCancelled]: {
    id: ContentId.BulkEditCancelled,
    defaultValue: 'Bulk edit is cancelled',
  },
  [ContentId.BulkEditDone]: {
    id: ContentId.BulkEditDone,
    defaultValue: 'Bulk edit is done',
  },
  [ContentId.BulkEditFailed]: {
    id: ContentId.BulkEditFailed,
    defaultValue: 'The process failed.',
  },
  [ContentId.BulkEditFloorCeilingCurrentFloorWarning]: {
    id: ContentId.BulkEditFloorCeilingCurrentFloorWarning,
    defaultValue:
      'Only listings with a current floor will be updated. Listings with price below the floor or above the ceiling will be adjusted to match the new floor or ceiling.',
  },
  [ContentId.BulkEditFloorCeilingUnitCostWarning]: {
    id: ContentId.BulkEditFloorCeilingUnitCostWarning,
    defaultValue:
      'Only listings with a unit cost will be updated. Listings with price below the floor or above the ceiling will be adjusted to match the new floor or ceiling.',
  },
  [ContentId.BulkEditFloorCeilingWarning]: {
    id: ContentId.BulkEditFloorCeilingWarning,
    defaultValue:
      'Only listings with a market price will be updated. Listings with price below the floor or above the ceiling will be adjusted to match the new floor or ceiling.',
  },
  [ContentId.BulkEditInProgress]: {
    id: ContentId.BulkEditInProgress,
    defaultValue: 'Bulk edit is in progress',
  },
  [ContentId.BulkEditListPriceAdjustingFloorCeilingWarning]: {
    id: ContentId.BulkEditListPriceAdjustingFloorCeilingWarning,
    defaultValue:
      'Adjusting any price below the floor or above the ceiling of a listing will auto-adjust the floor or ceiling of that listing.',
  },
  [ContentId.BulkEditPartialFailure]: {
    id: ContentId.BulkEditPartialFailure,
    defaultValue: 'The process completed with some failures.',
  },
  [ContentId.BulkEditPriceFloorCeilingWarning]: {
    id: ContentId.BulkEditPriceFloorCeilingWarning,
    defaultValue:
      'Changing price floor or ceiling will automatically change any listing price that is outside the boundary.',
  },
  [ContentId.BulkEditPricesWarning]: {
    id: ContentId.BulkEditPricesWarning,
    defaultValue:
      'Listings with price updates below the floor or above the ceiling will have their floor or ceiling be adjusted to match the new prices.',
  },
  [ContentId.BulkEditStarting]: {
    id: ContentId.BulkEditStarting,
    defaultValue: 'Bulk edit is starting',
  },
  [ContentId.BulkEditSuccess]: {
    id: ContentId.BulkEditSuccess,
    defaultValue: 'The process completed successfully.',
  },
  [ContentId.BulkEntry]: {
    id: ContentId.BulkEntry,
    defaultValue: 'Bulk Entry',
  },

  [ContentId.BulkUpload]: {
    id: ContentId.BulkUpload,
    defaultValue: 'Bulk Upload',
  },
  [ContentId.Buyer]: {
    id: ContentId.Buyer,
    defaultValue: 'Buyer',
  },
  [ContentId.BuyerAccounts]: {
    id: ContentId.BuyerAccounts,
    defaultValue: 'Buyer Accounts',
  },
  [ContentId.BuyerCommission]: {
    id: ContentId.BuyerCommission,
    defaultValue: 'Buyer Commission',
  },
  [ContentId.BuyerDeniedEntry]: {
    id: ContentId.BuyerDeniedEntry,
    defaultValue: 'Buyer Denied Entry',
  },
  [ContentId.By]: {
    id: ContentId.By,
    defaultValue: 'by ',
  },
  [ContentId.BypassLimit]: {
    id: ContentId.BypassLimit,
    defaultValue: 'Bypass Limit',
  },
  [ContentId.Calculate]: {
    id: ContentId.Calculate,
    defaultValue: 'Calculate',
  },
  [ContentId.CalculatePrice]: {
    id: ContentId.CalculatePrice,
    defaultValue: 'Calculate Price',
  },
  [ContentId.Cancel]: { id: ContentId.Cancel, defaultValue: 'Cancel' },
  [ContentId.CancellationDate]: {
    id: ContentId.CancellationDate,
    defaultValue: 'Cancellation Date',
  },
  [ContentId.Cancellations]: {
    id: ContentId.Cancellations,
    defaultValue: 'Cancellations',
  },
  [ContentId.Cancelled]: {
    id: ContentId.Cancelled,
    defaultValue: 'Cancelled',
  },
  [ContentId.CancelledEvent]: {
    id: ContentId.CancelledEvent,
    defaultValue: 'Cancelled Event',
  },
  [ContentId.CancelledHold]: {
    id: ContentId.CancelledHold,
    defaultValue: 'Cancelled Hold',
  },
  [ContentId.CancelledHoldOnMarketplace]: {
    id: ContentId.CancelledHoldOnMarketplace,
    defaultValue: 'Cancelled Hold on Marketplace',
  },
  [ContentId.CancelSelectMultiple]: {
    id: ContentId.CancelSelectMultiple,
    defaultValue: 'Cancel Select Multiple',
  },
  [ContentId.CancelTickets]: {
    id: '',
    defaultValue: 'Cancel Tickets',
  },
  [ContentId.CancelPurchase]: {
    id: ContentId.CancelPurchase,
    defaultValue: 'Cancel Purchase',
  },
  [ContentId.CancelPurchaseDescription]: {
    id: ContentId.CancelPurchaseDescription,
    defaultValue: 'Use if the Primary recently cancelled your purchase.',
  },
  [ContentId.CancelVoidPurchase]: {
    id: ContentId.CancelVoidPurchase,
    defaultValue: 'Cancel/Void Purchase',
  },

  [ContentId.CannotCalculateUndercut]: {
    id: ContentId.CannotCalculateUndercut,
    defaultValue: 'Cannot Calculate Undercut',
  },
  [ContentId.Cash]: {
    id: ContentId.Cash,
    defaultValue: 'Cash',
  },
  [ContentId.Catchup]: {
    id: ContentId.Catchup,
    defaultValue: 'Catchup',
  },
  [ContentId.Category]: {
    id: ContentId.Category,
    defaultValue: 'Category',
  },
  [ContentId.Category1007]: {
    id: ContentId.Category1007,
    defaultValue: 'Football',
  },
  [ContentId.Category1009]: {
    id: ContentId.Category1009,
    defaultValue: 'Golf',
  },
  [ContentId.Category4286]: {
    id: ContentId.Category4286,
    defaultValue: 'Ice hockey',
  },
  [ContentId.Category5099]: {
    id: ContentId.Category5099,
    defaultValue: 'Rodeo',
  },
  [ContentId.Category6401]: {
    id: ContentId.Category6401,
    defaultValue: 'American Football',
  },
  [ContentId.Category6404]: {
    id: ContentId.Category6404,
    defaultValue: 'Basketball',
  },
  [ContentId.Category6451]: {
    id: ContentId.Category6451,
    defaultValue: 'Motorsport',
  },
  [ContentId.Category6452]: {
    id: ContentId.Category6452,
    defaultValue: 'Baseball',
  },
  [ContentId.Category12567]: {
    id: ContentId.Category12567,
    defaultValue: 'Rowing',
  },
  [ContentId.Category263024]: {
    id: ContentId.Category263024,
    defaultValue: 'College Sports',
  },
  [ContentId.Category272988]: {
    id: ContentId.Category272988,
    defaultValue: 'Gymnastics',
  },
  [ContentId.Category272989]: {
    id: ContentId.Category272989,
    defaultValue: 'Wrestling',
  },
  [ContentId.Category273013]: {
    id: ContentId.Category273013,
    defaultValue: 'Curling',
  },
  [ContentId.Category273026]: {
    id: ContentId.Category273026,
    defaultValue: 'Fight',
  },
  [ContentId.Category273031]: {
    id: ContentId.Category273031,
    defaultValue: 'Tennis',
  },
  [ContentId.Category273034]: {
    id: ContentId.Category273034,
    defaultValue: 'Horse Racing',
  },
  [ContentId.Category273055]: {
    id: ContentId.Category273055,
    defaultValue: 'Extreme Sports',
  },
  [ContentId.Category273057]: {
    id: ContentId.Category273057,
    defaultValue: 'Figure Skating',
  },
  [ContentId.Category273089]: {
    id: ContentId.Category273089,
    defaultValue: '[All Other Sports]',
  },
  [ContentId.Category273137]: {
    id: ContentId.Category273137,
    defaultValue: 'Swimming',
  },
  [ContentId.Category273138]: {
    id: ContentId.Category273138,
    defaultValue: 'Bodybuilding',
  },
  [ContentId.Category273164]: {
    id: ContentId.Category273164,
    defaultValue: 'Lacrosse',
  },
  [ContentId.Category273171]: {
    id: ContentId.Category273171,
    defaultValue: 'Track and Field',
  },
  [ContentId.Category273393]: {
    id: ContentId.Category273393,
    defaultValue: 'Softball',
  },
  [ContentId.Category273409]: {
    id: ContentId.Category273409,
    defaultValue: 'Equestrian',
  },
  [ContentId.Category273431]: {
    id: ContentId.Category273431,
    defaultValue: 'Rugby',
  },
  [ContentId.Category273485]: {
    id: ContentId.Category273485,
    defaultValue: 'Volleyball',
  },
  [ContentId.Category273529]: {
    id: ContentId.Category273529,
    defaultValue: 'Exhibition Sports',
  },
  [ContentId.Category273657]: {
    id: ContentId.Category273657,
    defaultValue: 'Marching Band',
  },
  [ContentId.Category274164]: {
    id: ContentId.Category274164,
    defaultValue: 'Cricket',
  },
  [ContentId.Category274701]: {
    id: ContentId.Category274701,
    defaultValue: 'Darts',
  },
  [ContentId.Category274794]: {
    id: ContentId.Category274794,
    defaultValue: 'Dance and Cheer Competition',
  },
  [ContentId.Category275344]: {
    id: ContentId.Category275344,
    defaultValue: 'Snooker',
  },
  [ContentId.Category275641]: {
    id: ContentId.Category275641,
    defaultValue: 'Handball',
  },
  [ContentId.Category276077]: {
    id: ContentId.Category276077,
    defaultValue: 'Polo',
  },
  [ContentId.Category276281]: {
    id: ContentId.Category276281,
    defaultValue: 'Sports and Outdoors',
  },
  [ContentId.Category276306]: {
    id: ContentId.Category276306,
    defaultValue: 'Aviation',
  },
  [ContentId.Category276308]: {
    id: ContentId.Category276308,
    defaultValue: 'Hunting',
  },
  [ContentId.Category276311]: {
    id: ContentId.Category276311,
    defaultValue: 'Amateur Sports',
  },
  [ContentId.Category276861]: {
    id: ContentId.Category276861,
    defaultValue: 'eSports',
  },
  [ContentId.Category278221]: {
    id: ContentId.Category278221,
    defaultValue: 'Bullfighting',
  },
  [ContentId.Category278837]: {
    id: ContentId.Category278837,
    defaultValue: 'Sumo Wrestling',
  },
  [ContentId.Category279267]: {
    id: ContentId.Category279267,
    defaultValue: 'Strongman Sports',
  },
  [ContentId.Category279563]: {
    id: ContentId.Category279563,
    defaultValue: 'Sports Competition',
  },
  [ContentId.Category289352]: {
    id: ContentId.Category289352,
    defaultValue: 'Australian Football',
  },
  [ContentId.Category150012246]: {
    id: ContentId.Category150012246,
    defaultValue: 'Sports Parking',
  },
  [ContentId.Category1013]: {
    id: ContentId.Category1013,
    defaultValue: 'Ballet and Dance',
  },
  [ContentId.Category1015]: {
    id: ContentId.Category1015,
    defaultValue: 'Comedy',
  },
  [ContentId.Category1017]: {
    id: ContentId.Category1017,
    defaultValue: 'Musicals',
  },
  [ContentId.Category2294]: {
    id: ContentId.Category2294,
    defaultValue: 'Family',
  },
  [ContentId.Category4551]: {
    id: ContentId.Category4551,
    defaultValue: 'Cirque du Soleil',
  },
  [ContentId.Category6403]: {
    id: ContentId.Category6403,
    defaultValue: 'Conferences and Speaking Tours',
  },
  [ContentId.Category55548]: {
    id: ContentId.Category55548,
    defaultValue: 'Story of 60s & Woodstock',
  },
  [ContentId.Category259716]: {
    id: ContentId.Category259716,
    defaultValue: 'Classical Music and Opera',
  },
  [ContentId.Category356361]: {
    id: ContentId.Category356361,
    defaultValue: 'Las Vegas Show',
  },
  [ContentId.Category356453]: {
    id: ContentId.Category356453,
    defaultValue: 'Film Festival',
  },
  [ContentId.Category356499]: {
    id: ContentId.Category356499,
    defaultValue: 'Festivals and Fairs',
  },
  [ContentId.Category356585]: {
    id: ContentId.Category356585,
    defaultValue: 'Other Theater and Misc',
  },
  [ContentId.Category357019]: {
    id: ContentId.Category357019,
    defaultValue: 'VIP Event and Party',
  },
  [ContentId.Category357036]: {
    id: ContentId.Category357036,
    defaultValue: 'Movie Event',
  },
  [ContentId.Category358121]: {
    id: ContentId.Category358121,
    defaultValue: 'Broadway',
  },
  [ContentId.Category358959]: {
    id: ContentId.Category358959,
    defaultValue: 'Plays',
  },
  [ContentId.Category366262]: {
    id: ContentId.Category366262,
    defaultValue: 'Arts & Crafts',
  },
  [ContentId.Category366288]: {
    id: ContentId.Category366288,
    defaultValue: 'Performing Arts',
  },
  [ContentId.Category366529]: {
    id: ContentId.Category366529,
    defaultValue: 'Food and Dining',
  },
  [ContentId.Category366888]: {
    id: ContentId.Category366888,
    defaultValue: 'Tourist Attraction',
  },
  [ContentId.Category375100]: {
    id: ContentId.Category375100,
    defaultValue: 'Horse Show',
  },
  [ContentId.Category379731]: {
    id: ContentId.Category379731,
    defaultValue: 'LGBT',
  },
  [ContentId.Category379991]: {
    id: ContentId.Category379991,
    defaultValue: 'Encore Primary',
  },
  [ContentId.Category380480]: {
    id: ContentId.Category380480,
    defaultValue: 'Home',
  },
  [ContentId.Category381561]: {
    id: ContentId.Category381561,
    defaultValue: 'Sex n the City, a Musical Parody',
  },
  [ContentId.Category150002820]: {
    id: ContentId.Category150002820,
    defaultValue: 'TV-Show',
  },
  [ContentId.Category1014]: {
    id: ContentId.Category1014,
    defaultValue: 'Classical',
  },
  [ContentId.Category1022]: {
    id: ContentId.Category1022,
    defaultValue: 'Club and dance',
  },
  [ContentId.Category1023]: {
    id: ContentId.Category1023,
    defaultValue: 'Festival Tickets',
  },
  [ContentId.Category1026]: {
    id: ContentId.Category1026,
    defaultValue: 'Rap/Hip-Hop/Reggae',
  },
  [ContentId.Category1027]: {
    id: ContentId.Category1027,
    defaultValue: 'R&B/Urban Soul',
  },
  [ContentId.Category1059]: {
    id: ContentId.Category1059,
    defaultValue: 'Alternative and Indie',
  },
  [ContentId.Category1205]: {
    id: ContentId.Category1205,
    defaultValue: 'Country and Folk',
  },
  [ContentId.Category14858]: {
    id: ContentId.Category14858,
    defaultValue: 'Gospel Concert',
  },
  [ContentId.Category259810]: {
    id: ContentId.Category259810,
    defaultValue: 'Classical and Vocal Music',
  },
  [ContentId.Category259815]: {
    id: ContentId.Category259815,
    defaultValue: 'Latin Music',
  },
  [ContentId.Category259819]: {
    id: ContentId.Category259819,
    defaultValue: 'Jazz Music',
  },
  [ContentId.Category259820]: {
    id: ContentId.Category259820,
    defaultValue: 'New Age and Spiritual Music',
  },
  [ContentId.Category259822]: {
    id: ContentId.Category259822,
    defaultValue: 'RB and Soul Music',
  },
  [ContentId.Category259823]: {
    id: ContentId.Category259823,
    defaultValue: 'Pop Music',
  },
  [ContentId.Category259824]: {
    id: ContentId.Category259824,
    defaultValue: 'World Music',
  },
  [ContentId.Category259827]: {
    id: ContentId.Category259827,
    defaultValue: 'Rock Music',
  },
  [ContentId.Category259828]: {
    id: ContentId.Category259828,
    defaultValue: 'Reggae Music',
  },
  [ContentId.Category259829]: {
    id: ContentId.Category259829,
    defaultValue: 'Blues Music',
  },
  [ContentId.Category259833]: {
    id: ContentId.Category259833,
    defaultValue: 'Vocal Performance Music',
  },
  [ContentId.Category259921]: {
    id: ContentId.Category259921,
    defaultValue: 'Rock, Pop and Hip-Hop',
  },
  [ContentId.Category259922]: {
    id: ContentId.Category259922,
    defaultValue: 'Jazz, Blues and RnB Music',
  },
  [ContentId.Category260261]: {
    id: ContentId.Category260261,
    defaultValue: 'Bluegrass',
  },
  [ContentId.Category260263]: {
    id: ContentId.Category260263,
    defaultValue: 'Christian',
  },
  [ContentId.Category260264]: {
    id: ContentId.Category260264,
    defaultValue: 'Cover / Tribute',
  },
  [ContentId.Category260265]: {
    id: ContentId.Category260265,
    defaultValue: 'Flamenco',
  },
  [ContentId.Category260266]: {
    id: ContentId.Category260266,
    defaultValue: 'Funk',
  },
  [ContentId.Category260267]: {
    id: ContentId.Category260267,
    defaultValue: 'Indie',
  },
  [ContentId.Category260268]: {
    id: ContentId.Category260268,
    defaultValue: 'Progressive Rock',
  },
  [ContentId.Category260270]: {
    id: ContentId.Category260270,
    defaultValue: 'Electronic',
  },
  [ContentId.Category260271]: {
    id: ContentId.Category260271,
    defaultValue: 'Childrens',
  },
  [ContentId.Category260275]: {
    id: ContentId.Category260275,
    defaultValue: 'Miscellaneous Music',
  },
  [ContentId.Category260347]: {
    id: ContentId.Category260347,
    defaultValue: 'Country Tickets',
  },
  [ContentId.Category260357]: {
    id: ContentId.Category260357,
    defaultValue: 'Blues',
  },
  [ContentId.Category260385]: {
    id: ContentId.Category260385,
    defaultValue: 'Easy Listening Tickets',
  },
  [ContentId.Category260389]: {
    id: ContentId.Category260389,
    defaultValue: 'Reggae Tickets',
  },
  [ContentId.Category260392]: {
    id: ContentId.Category260392,
    defaultValue: "Children's Tickets",
  },
  [ContentId.Category260421]: {
    id: ContentId.Category260421,
    defaultValue: 'Jazz',
  },
  [ContentId.Category260478]: {
    id: ContentId.Category260478,
    defaultValue: 'Rap Tickets',
  },
  [ContentId.Category260483]: {
    id: ContentId.Category260483,
    defaultValue: 'Stage & Screen',
  },
  [ContentId.Category260492]: {
    id: ContentId.Category260492,
    defaultValue: 'International Tickets',
  },
  [ContentId.Category260530]: {
    id: ContentId.Category260530,
    defaultValue: 'Vocal Tickets',
  },
  [ContentId.Category260539]: {
    id: ContentId.Category260539,
    defaultValue: 'Avant-Garde Tickets',
  },
  [ContentId.Category260542]: {
    id: ContentId.Category260542,
    defaultValue: 'Pop/Rock',
  },
  [ContentId.Category260601]: {
    id: ContentId.Category260601,
    defaultValue: 'Electronic Tickets',
  },
  [ContentId.Category260624]: {
    id: ContentId.Category260624,
    defaultValue: 'New Age Tickets',
  },
  [ContentId.Category260628]: {
    id: ContentId.Category260628,
    defaultValue: 'Folk Tickets',
  },
  [ContentId.Category260637]: {
    id: ContentId.Category260637,
    defaultValue: 'Latin Tickets',
  },
  [ContentId.Category260644]: {
    id: ContentId.Category260644,
    defaultValue: 'Religious Tickets',
  },
  [ContentId.Category261289]: {
    id: ContentId.Category261289,
    defaultValue: 'Holiday Tickets',
  },
  [ContentId.Category261291]: {
    id: ContentId.Category261291,
    defaultValue: 'Comedy/Spoken Tickets',
  },
  [ContentId.Category308335]: {
    id: ContentId.Category308335,
    defaultValue: 'Acoustic',
  },
  [ContentId.Category308336]: {
    id: ContentId.Category308336,
    defaultValue: 'Ambient',
  },
  [ContentId.Category308341]: {
    id: ContentId.Category308341,
    defaultValue: 'Celtic',
  },
  [ContentId.Category308342]: {
    id: ContentId.Category308342,
    defaultValue: 'Disco',
  },
  [ContentId.Category308344]: {
    id: ContentId.Category308344,
    defaultValue: 'Doo Wop',
  },
  [ContentId.Category308345]: {
    id: ContentId.Category308345,
    defaultValue: 'Easy Listening',
  },
  [ContentId.Category308350]: {
    id: ContentId.Category308350,
    defaultValue: 'Hardcore',
  },
  [ContentId.Category308351]: {
    id: ContentId.Category308351,
    defaultValue: 'House',
  },
  [ContentId.Category308352]: {
    id: ContentId.Category308352,
    defaultValue: 'Industrial',
  },
  [ContentId.Category308353]: {
    id: ContentId.Category308353,
    defaultValue: 'Karaoke / Open Mic',
  },
  [ContentId.Category308355]: {
    id: ContentId.Category308355,
    defaultValue: 'Mariachi',
  },
  [ContentId.Category308357]: {
    id: ContentId.Category308357,
    defaultValue: 'Punk',
  },
  [ContentId.Category308358]: {
    id: ContentId.Category308358,
    defaultValue: 'Rockabilly',
  },
  [ContentId.Category308363]: {
    id: ContentId.Category308363,
    defaultValue: 'Ska',
  },
  [ContentId.Category308364]: {
    id: ContentId.Category308364,
    defaultValue: 'Surf Rock',
  },
  [ContentId.Category308365]: {
    id: ContentId.Category308365,
    defaultValue: 'Tejano',
  },
  [ContentId.Category308385]: {
    id: ContentId.Category308385,
    defaultValue: 'Trance',
  },
  [ContentId.Category321834]: {
    id: ContentId.Category321834,
    defaultValue: 'K-pop',
  },
  [ContentId.Category1024]: {
    id: ContentId.Category1024,
    defaultValue: 'Hard Rock and Metal Music',
  },
  [ContentId.CeilingRelativeToUnitCost]: {
    id: ContentId.CeilingRelativeToUnitCost,
    defaultValue: 'Ceiling Relative to Unit Cost',
  },
  [ContentId.ChangeEvent]: {
    id: ContentId.ChangeEvent,
    defaultValue: 'Change Event',
  },
  [ContentId.ChangeEventAll]: {
    id: ContentId.ChangeEventAll,
    defaultValue: 'Change Event for All',
  },
  [ContentId.ChangeEventSelected]: {
    id: ContentId.ChangeEventSelected,
    defaultValue: 'Change Event for Selected',
  },
  [ContentId.ChangeGroupBy]: {
    id: ContentId.ChangeGroupBy,
    defaultValue: 'Change Group By',
  },
  [ContentId.ChangeGroupByWarning]: {
    id: ContentId.ChangeGroupByWarning,
    defaultValue:
      'Changing from the current grouping type will make some of the existing columns disappear. Are you sure?',
  },
  [ContentId.ChangePassword]: {
    id: ContentId.ChangePassword,
    defaultValue: 'Change password',
  },
  [ContentId.ChangeSectionScore]: {
    id: ContentId.ChangeSectionScore,
    defaultValue: 'Change Score for Section',
  },
  [ContentId.ChangeTagValueTypeWarning]: {
    id: ContentId.ChangeTagValueTypeWarning,
    defaultValue:
      'Changing the value type will clear the existing values for this tag that are not of the new type. Are you sure?',
  },
  [ContentId.ChangeValueType]: {
    id: ContentId.ChangeValueType,
    defaultValue: 'Change Value Type for Key',
  },
  [ContentId.ChangeVendorAccount]: {
    id: ContentId.ChangeVendorAccount,
    defaultValue: 'Change Vendor Account',
  },
  [ContentId.Charge]: {
    id: ContentId.Charge,
    defaultValue: 'Charge',
  },
  [ContentId.Charges]: {
    id: ContentId.Charges,
    defaultValue: 'Charges',
  },
  [ContentId.CharityEvent]: {
    id: ContentId.CharityEvent,
    defaultValue: 'Charity Event',
  },
  [ContentId.Cheapest]: {
    id: ContentId.Cheapest,
    defaultValue: 'Cheapest',
  },
  [ContentId.Checkbox]: {
    id: ContentId.Checkbox,
    defaultValue: 'Checkbox',
  },
  [ContentId.ChooseBuyer]: {
    id: ContentId.ChooseBuyer,
    defaultValue: 'Choose Buyer',
  },
  [ContentId.ChooseSection]: {
    id: ContentId.ChooseSection,
    defaultValue: 'Choose Section',
  },
  [ContentId.ChooseRow]: {
    id: ContentId.ChooseRow,
    defaultValue: 'Choose Row',
  },
  [ContentId.ChooseWidgets]: {
    id: ContentId.ChooseWidgets,
    defaultValue: 'Choose Widgets to Display',
  },
  [ContentId.ChooseYourDisplaySettings]: {
    id: ContentId.ChooseYourDisplaySettings,
    defaultValue: 'Choose your display settings',
  },
  [ContentId.ChooseZone]: {
    id: ContentId.ChooseZone,
    defaultValue: 'Choose Zone',
  },
  [ContentId.ClassifyMessage]: {
    id: ContentId.ClassifyMessage,
    defaultValue: 'Classify Message',
  },
  [ContentId.ClearAll]: {
    id: ContentId.ClearAll,
    defaultValue: 'Clear all',
  },
  [ContentId.ClearAndOverwrite]: {
    id: ContentId.ClearAndOverwrite,
    defaultValue: 'Clear and Overwrite',
  },
  [ContentId.ClearBrowsingData]: {
    id: ContentId.ClearBrowsingData,
    defaultValue: 'Clear Browsing Data',
  },
  [ContentId.ClearBrowsingDataHelp]: {
    id: ContentId.ClearBrowsingDataHelp,
    defaultValue:
      'Clearing browsing data removes stored local information such as cached files and cookies from your session. This action does not affect your inventory data.',
  },
  [ContentId.ClearData]: {
    id: ContentId.ClearData,
    defaultValue: 'Clear Data',
  },
  [ContentId.ClearListingAutoPricingSettings]: {
    id: ContentId.ClearListingAutoPricingSettings,
    defaultValue: 'Clear Listing Auto Pricing Settings',
  },
  [ContentId.Clusters]: {
    id: ContentId.Clusters,
    defaultValue: 'Clusters',
  },
  [ContentId.Columns]: {
    id: ContentId.Columns,
    defaultValue: 'Columns',
  },
  [ContentId.ColumnSettings]: {
    id: ContentId.ColumnSettings,
    defaultValue: 'Column Settings',
  },
  [ContentId.ColumnWithSameNameExists]: {
    id: ContentId.ColumnWithSameNameExists,
    defaultValue: 'Column with the same name exists',
  },
  [ContentId.CombinedPermissionViewDescription]: {
    id: ContentId.CombinedPermissionViewDescription,
    defaultValue:
      'This is a combined and read-only view of all the permissions based on all the roles the user has.',
  },
  [ContentId.CommaSeparatedValuesExt]: {
    id: ContentId.CommaSeparatedValuesExt,
    defaultValue: 'Comma Separated Values (.csv)',
  },
  [ContentId.Comments]: {
    id: ContentId.Comments,
    defaultValue: 'Comments',
  },
  [ContentId.Commission]: {
    id: ContentId.Commission,
    defaultValue: 'Commission',
  },
  [ContentId.Commissioner]: {
    id: ContentId.Commissioner,
    defaultValue: 'Commissioner',
  },
  [ContentId.Comparables]: {
    id: ContentId.Comparables,
    defaultValue: 'Comparables',
  },
  [ContentId.CompCeiling]: {
    id: ContentId.CompCeiling,
    defaultValue: 'Comp. Ceiling',
  },
  [ContentId.CompFloor]: {
    id: ContentId.CompFloor,
    defaultValue: 'Comp. Floor',
  },
  [ContentId.CompListingCeiling]: {
    id: ContentId.CompListingCeiling,
    defaultValue: 'Comp. Listing Ceiling',
  },
  [ContentId.CompListingFloorMustLessThanCeiling]: {
    id: ContentId.CompListingFloorMustLessThanCeiling,
    defaultValue: 'Comp. Listing Floor must be less than Ceiling.',
  },
  [ContentId.CompListingModePrompt]: {
    id: ContentId.CompListingModePrompt,
    defaultValue: 'Which listings to price against?',
  },
  [ContentId.CompListingOnlyForSameZone]: {
    id: ContentId.CompListingOnlyForSameZone,
    defaultValue: 'Restrict to Same Zone',
  },
  // TODO: SponsoredListings - Clean up & sort alphabetically
  [ContentId.Perk]: {
    id: ContentId.Perk,
    defaultValue: 'Perk',
  },
  [ContentId.Permission_EventConfig_Create]: {
    id: ContentId.Permission_EventConfig_Create,
    defaultValue: 'Create Event Settings',
  },
  [ContentId.Permission_EventConfig_Edit]: {
    id: ContentId.Permission_EventConfig_Edit,
    defaultValue: 'Edit Event Settings',
  },
  [ContentId.Permission_EventConfig_View]: {
    id: ContentId.Permission_EventConfig_View,
    defaultValue: 'View Event Settings',
  },
  [ContentId.Permission_UpdateDeliveryStrategyOnPurchasedListings]: {
    id: ContentId.Permission_UpdateDeliveryStrategyOnPurchasedListings,
    defaultValue: 'Update delivery strategy on purchased listings (Purchaser)',
  },
  [ContentId.Permission_UpdateDeliveryStrategyOnPricedListings]: {
    id: ContentId.Permission_UpdateDeliveryStrategyOnPricedListings,
    defaultValue: 'Update delivery strategy on priced listings (Pricer)',
  },
  [ContentId.Permission_UpdateDeliveryStrategy]: {
    id: ContentId.Permission_UpdateDeliveryStrategy,
    defaultValue: 'Update delivery strategy',
  },
  [ContentId.Permission_UpdateDeliveryType]: {
    id: ContentId.Permission_UpdateDeliveryType,
    defaultValue: 'Update delivery type',
  },
  [ContentId.Permission_UpdateDeliveryTypeOwned]: {
    id: ContentId.Permission_UpdateDeliveryTypeOwned,
    defaultValue: 'Update delivery type on created purchase',
  },
  [ContentId.Permission_UpdateSeatTraits]: {
    id: ContentId.Permission_UpdateSeatTraits,
    defaultValue: 'Update seat traits',
  },
  [ContentId.Permission_UpdateSeatTraitsOwned]: {
    id: ContentId.Permission_UpdateSeatTraitsOwned,
    defaultValue: 'Update seat traits on created purchase',
  },
  [ContentId.Permission_UpdateCustomDeliveryType]: {
    id: ContentId.Permission_UpdateCustomDeliveryType,
    defaultValue: 'Set Custom delivery type',
  },
  [ContentId.Permission_UpdateCustomDeliveryTypeOwned]: {
    id: ContentId.Permission_UpdateCustomDeliveryTypeOwned,
    defaultValue: 'Set Custom delivery type on created purchase',
  },
  [ContentId.PmtAmount]: {
    id: ContentId.PmtAmount,
    defaultValue: 'Pmt Amount',
  },
  [ContentId.PmtMethod]: {
    id: ContentId.PmtMethod,
    defaultValue: 'Pmt Method',
  },
  [ContentId.PmtState]: {
    id: ContentId.PmtState,
    defaultValue: 'Pmt State',
  },
  [ContentId.Prev]: {
    id: ContentId.Prev,
    defaultValue: 'Prev',
  },
  [ContentId.PreviousTicket]: {
    id: ContentId.PreviousTicket,
    defaultValue: 'Previous Ticket',
  },
  [ContentId.PriceAgainst]: {
    id: ContentId.PriceAgainst,
    defaultValue: 'Price against',
  },
  [ContentId.PricingAgainst]: {
    id: ContentId.PricingAgainst,
    defaultValue: 'Pricing against',
  },
  [ContentId.PricingSettingsListingsConflictWarning]: {
    id: ContentId.PricingSettingsListingsConflictWarning,
    defaultValue:
      'Selected listings have conflicting settings. Only edited settings will be updated when applied.',
  },
  [ContentId.ProceedsAmount]: {
    id: ContentId.ProceedsAmount,
    defaultValue: 'Proceeds Amount',
  },
  [ContentId.Purchases_UpdateDeliveryType_Details]: {
    id: ContentId.Purchases_UpdateDeliveryType_Details,
    defaultValue: 'Add/Edit delivery type on purchases',
  },
  [ContentId.Purchases_UpdateDeliveryTypeOwned_Details]: {
    id: ContentId.Purchases_UpdateDeliveryTypeOwned_Details,
    defaultValue: 'Add/Edit delivery type on owned purchases',
  },
  [ContentId.Purchases_UpdateCustomDeliveryType_Details]: {
    id: ContentId.Purchases_UpdateCustomDeliveryType_Details,
    defaultValue:
      'Add/Edit Custom delivery type on purchases in addition to normal delivery types',
  },
  [ContentId.Purchases_UpdateCustomDeliveryTypeOwned_Details]: {
    id: ContentId.Purchases_UpdateCustomDeliveryTypeOwned_Details,
    defaultValue:
      'Add/Edit Custom delivery type on owned purchases in addition to normal delivery types',
  },
  [ContentId.Purchases_UpdateSeatTraits_Details]: {
    id: ContentId.Purchases_UpdateSeatTraits_Details,
    defaultValue: 'Edit seat traits on purchases',
  },
  [ContentId.Purchases_UpdateSeatTraitsOwned_Details]: {
    id: ContentId.Purchases_UpdateSeatTraitsOwned_Details,
    defaultValue: 'Edit seat traits on owned purchases',
  },
  [ContentId.Quantities]: {
    id: ContentId.Quantities,
    defaultValue: 'Quantities',
  },
  [ContentId.QuantitiesFilter]: {
    id: ContentId.QuantitiesFilter,
    defaultValue: 'Quantities Filter',
  },
  [ContentId.ReceivedDate]: {
    id: ContentId.ReceivedDate,
    defaultValue: 'Received Date',
  },
  [ContentId.RemainingSeatingOnly]: {
    id: ContentId.RemainingSeatingOnly,
    defaultValue: 'Remaining Seating Only',
  },
  [ContentId.RemoveExistingTicketsOption]: {
    id: ContentId.RemoveExistingTicketsOption,
    defaultValue:
      'Remove existing tickets: Delete previously used tickets and re-fulfill with new ones.',
  },
  [ContentId.RemoveHoldConfirmation]: {
    id: ContentId.RemoveHoldConfirmation,
    defaultValue:
      'This action will remove holds on applicable selected listings. Are you sure?',
  },
  [ContentId.ReplaceAndOverrideSeatTraits]: {
    id: ContentId.ReplaceAndOverrideSeatTraits,
    defaultValue: 'Replace and Override Seat Traits',
  },
  [ContentId.ReportNotAvailable]: {
    id: ContentId.ReportNotAvailable,
    defaultValue:
      "The report doesn't exist or you do not have permission to view it.",
  },
  [ContentId.ResetFulfillmentWarningV2]: {
    id: ContentId.ResetFulfillmentWarningV2,
    defaultValue:
      "You're about to Reset Fulfillment. This action will put the sale back to Pending Re-fulfillment and remove all existing tickets that were originally used to fulfill the sale. Continue?",
  },
  [ContentId.ResetToOriginal]: {
    id: ContentId.ResetToOriginal,
    defaultValue: 'Reset to Original',
  },
  [ContentId.RestrictionOnUse]: {
    id: ContentId.RestrictionOnUse,
    defaultValue: 'Restriction on Use',
  },
  [ContentId.Restrictions]: {
    id: ContentId.Restrictions,
    defaultValue: 'Restrictions',
  },
  [ContentId.ResumeExperiment]: {
    id: ContentId.ResumeExperiment,
    defaultValue: 'Resume Experiment',
  },
  [ContentId.ResultTimeout]: {
    id: ContentId.ResultTimeout,
    defaultValue: 'Result Timeout',
  },
  [ContentId.ResultTimeoutDescription]: {
    id: ContentId.ResultTimeoutDescription,
    defaultValue:
      'The request timed out due to a large number of results. Please adjust your filters to narrow the search and try again.',
  },
  [ContentId.RetrySkipped]: {
    id: ContentId.RetrySkipped,
    defaultValue: 'Retry Skipped',
  },
  [ContentId.ReuploadDocumentWarning]: {
    id: ContentId.ReuploadDocumentWarning,
    defaultValue:
      "You're about to Re-upload Tickets. This action will replace the existing tickets with the new upload. The current tickets will be removed. Continue?",
  },
  [ContentId.RevenueSplitHurdle]: {
    id: ContentId.RevenueSplitHurdle,
    defaultValue: 'Revenue Split Hurdle',
  },
  [ContentId.RowCompOverrides]: {
    id: ContentId.RowCompOverrides,
    defaultValue: 'Row Comp Overrides',
  },
  [ContentId.RowComps]: {
    id: ContentId.RowComps,
    defaultValue: 'Row Comps',
  },
  [ContentId.RowCompSettingExactMatch]: {
    id: ContentId.RowCompSettingExactMatch,
    defaultValue: 'MY ROW',
  },
  [ContentId.RowGroups]: {
    id: ContentId.RowGroups,
    defaultValue: 'Row Groups',
  },
  [ContentId.SaleDateFilterTitle]: {
    id: ContentId.SaleDateFilterTitle,
    defaultValue: 'Sale Activity Date Range',
  },
  [ContentId.PriceDateFilterTitle]: {
    id: ContentId.PriceDateFilterTitle,
    defaultValue: 'Price Activity Date Range',
  },
  [ContentId.PriceDateFilterChanged]: {
    id: ContentId.PriceDateFilterChanged,
    defaultValue: 'Price Changed Since',
  },
  [ContentId.PriceDateFilterUnchanged]: {
    id: ContentId.PriceDateFilterUnchanged,
    defaultValue: 'Price Not Changed Since',
  },
  [ContentId.SaleDateFilterOccured]: {
    id: ContentId.SaleDateFilterOccured,
    defaultValue: 'Event Had Sales Since',
  },
  [ContentId.SaleDateFilterNotOccured]: {
    id: ContentId.SaleDateFilterNotOccured,
    defaultValue: 'Event Not Had Sales Since',
  },
  [ContentId.PoId]: {
    id: ContentId.PoId,
    defaultValue: 'PO Id',
  },
  [ContentId.PoDate]: {
    id: ContentId.PoDate,
    defaultValue: 'PO Date',
  },
  [ContentId.SaleForEditTickets]: {
    id: ContentId.SaleForEditTickets,
    defaultValue:
      'If your edits result in one or more tickets removed, your 1 sale for the removed tickets will be deallocated and put back to Pending Confirmation, where you can either source new tickets or reject the order.',
  },
  [ContentId.SaleForRemovedTickets]: {
    id: ContentId.SaleForRemovedTickets,
    defaultValue:
      'Your 1 sale for the removed tickets will be deallocated and put back to Pending Confirmation, where you can either source new tickets or reject the order.',
  },
  [ContentId.SalePaymentConversionWarning]: {
    id: ContentId.SalePaymentConversionWarning,
    defaultValue:
      'Payment conversion and payment amount received will only applied to unrejected and uncancelled sales.',
  },
  [ContentId.SameEventsShareSameVenueConfigTitle]: {
    id: ContentId.SameEventsShareSameVenueConfigTitle,
    defaultValue: 'Some of your events share the same venue configuration',
  },
  [ContentId.SameEventsShareSameVenueConfigHeader]: {
    id: ContentId.SameEventsShareSameVenueConfigHeader,
    defaultValue:
      'Would you like to apply the same ticket information to these events?',
  },
  [ContentId.SameEventsShareSameVenueConfigMessage1]: {
    id: ContentId.SameEventsShareSameVenueConfigMessage1,
    defaultValue:
      "Click 'Yes' to apply the same ticket details to all events with the same venue configuration.",
  },
  [ContentId.SameEventsShareSameVenueConfigMessage2]: {
    id: ContentId.SameEventsShareSameVenueConfigMessage2,
    defaultValue:
      "Click 'No' to enter ticket details separately for each event.",
  },
  [ContentId.SearchEventOrLookupId]: {
    id: ContentId.SearchEventOrLookupId,
    defaultValue: 'Search Event or Lookup ID',
  },
  [ContentId.SectionGroups]: {
    id: ContentId.SectionGroups,
    defaultValue: 'Section Groups',
  },
  [ContentId.SectionUnmapped]: {
    id: ContentId.SectionUnmapped,
    defaultValue: 'Section Unmapped',
  },
  [ContentId.SelectCountry]: {
    id: ContentId.SelectCountry,
    defaultValue: 'Select Country',
  },
  [ContentId.SelectedFrom]: {
    id: ContentId.SelectedFrom,
    defaultValue: 'Selected from',
  },
  [ContentId.SelectQuantities]: {
    id: ContentId.SelectQuantities,
    defaultValue: 'Select Quantities',
  },
  [ContentId.SelectStateProvince]: {
    id: ContentId.SelectStateProvince,
    defaultValue: 'Select State/Province',
  },
  [ContentId.SelectCity]: {
    id: ContentId.SelectCity,
    defaultValue: 'Select City',
  },
  [ContentId.SetDefaultDeliveryForNewPurchases]: {
    id: ContentId.SetDefaultDeliveryForNewPurchases,
    defaultValue:
      'Set default vendor-level delivery settings for all new purchases',
  },
  [ContentId.SetDeliveryStrategy]: {
    id: ContentId.SetDeliveryStrategy,
    defaultValue: 'Set Delivery Strategy',
  },
  [ContentId.SOR]: {
    id: ContentId.SOR,
    defaultValue: 'SOR (Sale of Return)',
  },
  [ContentId.State]: {
    id: ContentId.State,
    defaultValue: 'State',
  },
  [ContentId.SellerId]: {
    id: ContentId.SellerId,
    defaultValue: 'Seller Id',
  },
  [ContentId.Budget]: {
    id: ContentId.Budget,
    defaultValue: 'Budget',
  },

  [ContentId.StartDateTime]: {
    id: ContentId.StartDateTime,
    defaultValue: 'Start Date',
  },

  [ContentId.EndDateTime]: {
    id: ContentId.EndDateTime,
    defaultValue: 'End Date',
  },

  [ContentId.CurrencyCode]: {
    id: ContentId.CurrencyCode,
    defaultValue: 'Currency Code',
  },

  [ContentId.BaseBid]: {
    id: ContentId.BaseBid,
    defaultValue: 'Base Bid',
  },
  [ContentId.MaxBid]: {
    id: ContentId.MaxBid,
    defaultValue: 'Max Bid',
  },
  [ContentId.DailyBudget]: {
    id: ContentId.DailyBudget,
    defaultValue: 'Daily Budget',
  },
  [ContentId.StopExperiment]: {
    id: ContentId.StopExperiment,
    defaultValue: 'Stop Experiment',
  },
  [ContentId.Supply]: {
    id: ContentId.Supply,
    defaultValue: 'Supply',
  },
  [ContentId.SuppressFor1HourV2]: {
    id: ContentId.SuppressFor1HourV2,
    defaultValue: 'Save my preference for one hour',
  },
  [ContentId.ThenDefaultListingsTo]: {
    id: ContentId.ThenDefaultListingsTo,
    defaultValue: 'Then default listings to',
  },
  [ContentId.ThenDefaultThePurchaseTo]: {
    id: ContentId.ThenDefaultThePurchaseTo,
    defaultValue: 'Then default the purchase to',
  },
  [ContentId.TicketDelivery]: {
    id: ContentId.TicketDelivery,
    defaultValue: 'Ticket Delivery',
  },
  [ContentId.TicketGroupBulkEditPurchaseWarning]: {
    id: ContentId.TicketGroupBulkEditPurchaseWarning,
    defaultValue:
      'Performing a bulk action on the selected purchase lines will affect the entire purchase order. Continue?',
  },
  [ContentId.TicketPrices]: {
    id: ContentId.TicketPrices,
    defaultValue: 'Ticket Prices',
  },
  [ContentId.TicketQuantity]: {
    id: ContentId.TicketQuantity,
    defaultValue: 'Ticket Quantity',
  },
  [ContentId.TicketsAllocated]: {
    id: ContentId.TicketsAllocated,
    defaultValue: 'Tickets allocated',
  },
  [ContentId.TicketsSoldAs]: {
    id: ContentId.TicketsSoldAs,
    defaultValue: 'Tickets sold as',
  },
  [ContentId.ToggleSelectionMode]: {
    id: ContentId.ToggleSelectionMode,
    defaultValue: 'Toggle Selection Mode',
  },
  [ContentId.TotalSplitInfo]: {
    id: ContentId.TotalSplitInfo,
    defaultValue: 'The total split percentage must be equal to 100%',
  },
  [ContentId.TurnOnAutoFulfillPrompt]: {
    id: ContentId.TurnOnAutoFulfillPrompt,
    defaultValue:
      'Do you want to send the fulfillment to the Marketplace? If you select No, you can do it later using the same action.',
  },
  [ContentId.UnknownError]: {
    id: ContentId.UnknownError,
    defaultValue: 'Unknown Error',
  },
  [ContentId.UnexpectedErrorOccured]: {
    id: ContentId.UnexpectedErrorOccured,
    defaultValue:
      'An unexpected error occurred. Please reload the page or report the issue for further assistance.',
  },
  [ContentId.Unordered]: {
    id: ContentId.Unordered,
    defaultValue: 'Unordered',
  },
  [ContentId.TypeYourNoteHere]: {
    id: ContentId.TypeYourNoteHere,
    defaultValue: 'Type your note here',
  },
  [ContentId.UpdateAllTicketGroupsUnderAssociatedPurchaseOrders]: {
    id: ContentId.UpdateAllTicketGroupsUnderAssociatedPurchaseOrders,
    defaultValue: 'Update all ticket groups under associated purchase order(s)',
  },
  [ContentId.UpdatedBy]: {
    id: ContentId.UpdatedBy,
    defaultValue: 'Updated By',
  },
  [ContentId.UpdateDeal]: {
    id: ContentId.UpdateDeal,
    defaultValue: 'Update Deal',
  },
  [ContentId.UpdateDeliveryType]: {
    id: ContentId.UpdateDeliveryType,
    defaultValue: 'Update Delivery Type',
  },
  [ContentId.UpdateDeliveryTypeForPlaceholders]: {
    id: ContentId.UpdateDeliveryTypeForPlaceholders,
    defaultValue: 'Update Delivery Type for Placeholders',
  },
  [ContentId.UpdateExistingListings]: {
    id: ContentId.UpdateExistingListings,
    defaultValue: 'Update Existing Listings',
  },
  [ContentId.UpdateExistingPurchasesAndListings]: {
    id: ContentId.UpdateExistingPurchasesAndListings,
    defaultValue: 'Update Existing Purchases and Listings',
  },
  [ContentId.UpdateFulfillmentSettings]: {
    id: ContentId.UpdateFulfillmentSettings,
    defaultValue: 'Update Fulfillment Settings',
  },
  [ContentId.UpdateFulfillmentSettingsMessage]: {
    id: ContentId.UpdateFulfillmentSettingsMessage,
    defaultValue:
      'Newly created listings will inherit the updated fulfillment properties.',
  },
  [ContentId.UpdateExistingListingsAndAssociatedSales]: {
    id: ContentId.UpdateExistingListingsAndAssociatedSales,
    defaultValue: 'Update Existing Listings and Associated Unfulfilled Sales',
  },
  [ContentId.UpdateFulfillmentSettingsMessagePurchase]: {
    id: ContentId.UpdateFulfillmentSettingsMessagePurchase,
    defaultValue:
      'Newly created purchases will inherit the updated delivery properties.',
  },
  [ContentId.UpdatePurchasedBy]: {
    id: ContentId.UpdatePurchasedBy,
    defaultValue: 'Update Purchased By',
  },
  [ContentId.UpdatePurchaseWithMergedListing]: {
    id: ContentId.UpdatePurchaseWithMergedListing,
    defaultValue: 'Update Purchase With Merged Listing',
  },
  [ContentId.UpdateAssociatedSales]: {
    id: ContentId.UpdateAssociatedSales,
    defaultValue: 'Also update associated unfulfilled sales',
  },
  [ContentId.UpdateSeatTraits]: {
    id: ContentId.UpdateSeatTraits,
    defaultValue: 'Update Seat Traits',
  },
  [ContentId.UpdateSeatTraitsForPlaceholders]: {
    id: ContentId.UpdateSeatTraitsForPlaceholders,
    defaultValue: 'Update Seat Traits for Placeholders',
  },
  [ContentId.UpdateSelectedTicketGroupsOnly]: {
    id: ContentId.UpdateSelectedTicketGroupsOnly,
    defaultValue: 'Update selected ticket group(s) only',
  },
  [ContentId.UseAbsoluteFloors]: {
    id: ContentId.UseAbsoluteFloors,
    defaultValue: 'Use Absolute Floors',
  },
  [ContentId.VendorGroup]: {
    id: ContentId.VendorGroup,
    defaultValue: 'Vendor Group',
  },
  [ContentId.VendorManagement]: {
    id: ContentId.VendorManagement,
    defaultValue: 'Vendor Management',
  },
  [ContentId.AddAdCampaign]: {
    id: ContentId.AddAdCampaign,
    defaultValue: 'Add Campaign',
  },
  [ContentId.AddAdGroup]: {
    id: ContentId.AddAdGroup,
    defaultValue: 'Add Ad Group',
  },
  [ContentId.EnterAdCampaignName]: {
    id: ContentId.EnterAdCampaignName,
    defaultValue: 'Enter Ad Campaign Name',
  },
  [ContentId.EnterAdGroupName]: {
    id: ContentId.EnterAdGroupName,
    defaultValue: 'Enter Ad Group Name',
  },
  [ContentId.ManageAdGroups]: {
    id: ContentId.ManageAdGroups,
    defaultValue: 'Manage Ad Groups',
  },
  [ContentId.BidModifierDimension]: {
    id: ContentId.BidModifierDimension,
    defaultValue: 'Bid Modifier Type',
  },
  [ContentId.EventBidModifierValue]: {
    id: ContentId.EventBidModifierValue,
    defaultValue: 'Bid Modifier Value',
  },
  [ContentId.TicketClass]: {
    id: ContentId.TicketClass,
    defaultValue: 'Ticket Class',
  },
  [ContentId.BidModifier]: {
    id: ContentId.BidModifier,
    defaultValue: 'Bid Modifier',
  },
  [ContentId.VendorPayments]: {
    id: ContentId.VendorPayments,
    defaultValue: 'Vendor Payments',
  },
  [ContentId.ViewApplicableListings]: {
    id: ContentId.ViewApplicableListings,
    defaultValue: 'View Applicable Listings',
  },
  [ContentId.AdSpend]: {
    id: ContentId.AdSpend,
    defaultValue: 'Ad Spend',
  },
  [ContentId.AddGenre]: {
    id: ContentId.AddGenre,
    defaultValue: 'Add Genre',
  },
  [ContentId.AddPerformer]: {
    id: ContentId.AddPerformer,
    defaultValue: 'Add Peformer',
  },
  [ContentId.AddEvent]: {
    id: ContentId.AddEvent,
    defaultValue: 'Add Event',
  },
  [ContentId.AddTicketClass]: {
    id: ContentId.AddTicketClass,
    defaultValue: 'Add Ticket Class',
  },
  [ContentId.AddListings]: {
    id: ContentId.AddListings,
    defaultValue: 'Add Listings',
  },
  [ContentId.AddVenue]: {
    id: ContentId.AddVenue,
    defaultValue: 'Add Venue',
  },
  [ContentId.MaxBidLessThanBaseBidMsg]: {
    id: ContentId.MaxBidLessThanBaseBidMsg,
    defaultValue: 'Max bid must be greater than or equal to base bid',
  },
  [ContentId.DailyBudgetLessThanMaxBidMsg]: {
    id: ContentId.MaxBidLessThanBaseBidMsg,
    defaultValue: 'Daily budget must be greater than or equal to max bid',
  },

  [ContentId.CompListingQuantityScoreAdjustment]: {
    id: ContentId.CompListingQuantityScoreAdjustment,
    defaultValue: 'Adjust Seat Score for Quantity',
  },
  [ContentId.CompMode]: {
    id: ContentId.CompMode,
    defaultValue: 'Comp. Mode',
  },
  [ContentId.Concert]: {
    id: ContentId.Concert,
    defaultValue: 'Concert',
  },
  [ContentId.ConcertsSectionsBehindStage]: {
    id: ContentId.ConcertsSectionsBehindStage,
    defaultValue: 'Concerts Sections Behind Stage',
  },
  [ContentId.Configure]: {
    id: ContentId.Configure,
    defaultValue: 'Configure',
  },
  [ContentId.ConfigureColumnTags]: {
    id: ContentId.ConfigureColumnTags,
    defaultValue: 'Configure Column Tags',
  },
  [ContentId.ConfigureColumnVariables]: {
    id: ContentId.ConfigureColumnVariables,
    defaultValue: 'Configure column variables',
  },
  [ContentId.ConfigureHeatMap]: {
    id: ContentId.ConfigureHeatMap,
    defaultValue: 'Configure Heat Map',
  },
  [ContentId.ConfiguringYourHeatMap]: {
    id: ContentId.ConfiguringYourHeatMap,
    defaultValue: 'Configuring your Heat Map',
  },
  [ContentId.ConfiguringHeatMapInstruction1]: {
    id: ContentId.ConfiguringHeatMapInstruction1,
    defaultValue:
      'Get the heat map looking right by setting the relationship between value, and DISTANCE, HEIGHT and ANGLE.',
  },
  [ContentId.ConfiguringHeatMapInstruction2]: {
    id: ContentId.ConfiguringHeatMapInstruction2,
    defaultValue:
      'Fine tune your heat map by clicking on a section, and adjusting its value.',
  },
  [ContentId.Confirm]: {
    id: ContentId.Confirm,
    defaultValue: 'Confirm',
  },
  [ContentId.ConfirmAllocation]: {
    id: ContentId.ConfirmAllocation,
    defaultValue: 'Confirm Allocation',
  },
  [ContentId.ConfirmationDeadline]: {
    id: ContentId.ConfirmationDeadline,
    defaultValue: 'Confirmation Deadline',
  },
  [ContentId.ConfirmDeletion]: {
    id: ContentId.ConfirmDeletion,
    defaultValue: 'Confirm Deletion',
  },
  [ContentId.ConfirmTransferWithoutProof]: {
    id: ContentId.ConfirmTransferWithoutProof,
    defaultValue: 'Confirm without Proof',
  },
  [ContentId.ConfirmVendorAccountChange]: {
    id: ContentId.ConfirmVendorAccountChange,
    defaultValue:
      "You're about to change the vendor account, which will auto-assign the purchaser to the default purchaser associated with the new vendor account.",
  },
  [ContentId.ConfirmWithoutAllocation]: {
    id: ContentId.ConfirmWithoutAllocation,
    defaultValue: 'Confirm without Allocation',
  },
  [ContentId.Contains]: {
    id: ContentId.Contains,
    defaultValue: 'Contains',
  },
  [ContentId.ConversionRate]: {
    id: ContentId.ConversionRate,
    defaultValue: 'Conversion Rate',
  },
  [ContentId.ConversionRateMustBeGreaterThanZero]: {
    id: ContentId.ConversionRateMustBeGreaterThanZero,
    defaultValue: 'Conversion Rate must be greater than zero',
  },
  [ContentId.CopyEmailAddress]: {
    id: ContentId.CopyEmailAddress,
    defaultValue: 'Copy email address',
  },
  [ContentId.CopyExternalId]: {
    id: ContentId.CopyExternalId,
    defaultValue: 'Copy external ID to clipboard',
  },
  [ContentId.Create]: {
    id: ContentId.Create,
    defaultValue: 'Create',
  },
  [ContentId.CreateACustomColumn]: {
    id: ContentId.CreateACustomColumn,
    defaultValue: 'Create a Custom Column',
  },
  [ContentId.CreateAHeatMap]: {
    id: ContentId.CreateAHeatMap,
    defaultValue: 'Create a Heat Map',
  },
  [ContentId.CreateANewTeam]: {
    id: ContentId.CreateANewTeam,
    defaultValue: 'Create a new team',
  },
  [ContentId.CreatedBy]: {
    id: ContentId.CreatedBy,
    defaultValue: 'Created By',
  },
  [ContentId.CreatedOn]: {
    id: ContentId.CreatedOn,
    defaultValue: 'Created On',
  },
  [ContentId.CreateNew]: {
    id: ContentId.CreateNew,
    defaultValue: 'Create New',
  },
  [ContentId.CreateNewField]: {
    id: ContentId.CreateNewField,
    defaultValue: 'Create new field',
  },
  [ContentId.CreateNewFromTemplateConfigPayload]: {
    id: ContentId.CreateNewFromTemplateConfigPayload,
    defaultValue: 'Or, create a new model from an existing configuration',
  },
  [ContentId.CreateNewInventoryReport]: {
    id: ContentId.CreateNewInventoryReport,
    defaultValue: 'Create new inventory report',
  },
  [ContentId.CreateNewOrder]: {
    id: ContentId.CreateNewOrder,
    defaultValue: 'Create New Order',
  },
  [ContentId.CreateNewReport]: {
    id: ContentId.CreateNewReport,
    defaultValue: 'Create new report',
  },
  [ContentId.CreateNewSaleReport]: {
    id: ContentId.CreateNewSaleReport,
    defaultValue: 'Create new sale report',
  },
  [ContentId.CreateNewTag]: {
    id: ContentId.CreateNewTag,
    defaultValue: 'Create New Tag',
  },
  [ContentId.CreatePricingStrategy]: {
    id: ContentId.CreatePricingStrategy,
    defaultValue: 'Create pricing strategy',
  },
  [ContentId.Credits]: {
    id: ContentId.Credits,
    defaultValue: 'Credits',
  },
  [ContentId.CSError]: {
    id: ContentId.CSError,
    defaultValue: 'CS Error',
  },
  [ContentId.CurrencyConversion]: {
    id: ContentId.CurrencyConversion,
    defaultValue: 'Currency Conversion',
  },
  [ContentId.CurrencySectionSubtitle]: {
    id: ContentId.CurrencySectionSubtitle,
    defaultValue:
      'Select your preferred default currency and auto-conversion settings',
  },
  [ContentId.Current]: {
    id: ContentId.Current,
    defaultValue: 'Current',
  },
  [ContentId.CurrentColumns]: {
    id: ContentId.CurrentColumns,
    defaultValue: 'Current columns',
  },
  [ContentId.CurrentFloor]: {
    id: ContentId.CurrentFloor,
    defaultValue: 'Current Floor',
  },
  [ContentId.Custom]: {
    id: ContentId.Custom,
    defaultValue: 'Custom',
  },
  [ContentId.CustomEventId]: {
    id: ContentId.CustomEventId,
    defaultValue: 'Custom Event ID',
  },
  [ContentId.CustomReports]: {
    id: ContentId.CustomReports,
    defaultValue: 'Custom Reports',
  },
  [ContentId.DailyOverrideFxRates]: {
    id: ContentId.DailyOverrideFxRates,
    defaultValue: 'Daily Override Fx Rates',
  },
  [ContentId.Dark]: {
    id: ContentId.Dark,
    defaultValue: 'Dark',
  },
  [ContentId.DarkMode]: {
    id: ContentId.DarkMode,
    defaultValue: 'Dark Mode',
  },
  [ContentId.Dashboard]: {
    id: ContentId.Dashboard,
    defaultValue: 'Dashboard',
  },
  [ContentId.DataIsRefreshing]: {
    id: ContentId.DataIsRefreshing,
    defaultValue: 'Data is refreshing...',
  },
  [ContentId.Date]: {
    id: ContentId.Date,
    defaultValue: 'Date',
  },
  [ContentId.Day]: {
    id: ContentId.Day,
    defaultValue: 'Day',
  },
  [ContentId.Days]: {
    id: ContentId.Days,
    defaultValue: 'Days',
  },
  [ContentId.DeactivateUser]: {
    id: ContentId.DeactivateUser,
    defaultValue: 'Deactivate User',
  },
  [ContentId.DealScore]: {
    id: ContentId.DealScore,
    defaultValue: 'Deal Score',
  },
  [ContentId.Decimal]: {
    id: ContentId.Decimal,
    defaultValue: 'Decimal',
  },
  [ContentId.DecimalNumber]: {
    id: ContentId.DecimalNumber,
    defaultValue: 'Decimal Number',
  },
  [ContentId.Decrease]: {
    id: ContentId.Decrease,
    defaultValue: 'Decrease',
  },
  [ContentId.DecreasePrice]: {
    id: ContentId.DecreasePrice,
    defaultValue: 'Decrease Price',
  },
  [ContentId.DecreasePriceByPercentAllVisible]: {
    id: ContentId.DecreasePriceByPercentAllVisible,
    defaultValue: 'Decrease Price of All Visible Listings By X%',
  },
  [ContentId.DecreasePriceForAll]: {
    id: ContentId.DecreasePriceForAll,
    defaultValue: 'Decrease Price for All',
  },
  [ContentId.DecreasePriceForSelected]: {
    id: ContentId.DecreasePriceForSelected,
    defaultValue: 'Decrease Price for Selected',
  },
  [ContentId.DefaultBuyer]: {
    id: ContentId.DefaultBuyer,
    defaultValue: 'Default Buyer',
  },
  [ContentId.DefaultCurrency]: {
    id: ContentId.DefaultCurrency,
    defaultValue: 'Default Currency',
  },
  [ContentId.DefaultPaymentMethod]: {
    id: ContentId.DefaultPaymentMethod,
    defaultValue: 'Default Payment Method',
  },
  [ContentId.DefaultQuantity]: {
    id: ContentId.DefaultQuantity,
    defaultValue: 'Default Quantity',
  },
  [ContentId.DefaultQuantityFilters]: {
    id: ContentId.DefaultQuantityFilters,
    defaultValue: 'Default Quantity Filters',
  },
  [ContentId.DefaultReports]: {
    id: ContentId.DefaultReports,
    defaultValue: 'Default Reports',
  },
  [ContentId.DefaultSplitTypeForSeatSaver]: {
    id: ContentId.DefaultSplitTypeForSeatSaver,
    defaultValue: 'Default Split Type for Placeholder',
  },
  [ContentId.DefineTargets]: {
    id: ContentId.DefineTargets,
    defaultValue: 'Define targets',
  },
  [ContentId.DefineTargetsSubtitle]: {
    id: ContentId.DefineTargetsSubtitle,
    defaultValue: 'Set target sell-through rates for specific dates.',
  },
  [ContentId.Delete]: {
    id: ContentId.Delete,
    defaultValue: 'Delete',
  },
  [ContentId.DeleteAllTemplates]: {
    id: ContentId.DeleteAllTemplates,
    defaultValue:
      'Are you sure you want to delete this template for all events?',
  },
  [ContentId.DeleteCustomColumn]: {
    id: ContentId.DeleteCustomColumn,
    defaultValue: 'Delete Custom Column',
  },
  [ContentId.DeleteFxOverrideWarning]: {
    id: ContentId.DeleteFxOverrideWarning,
    defaultValue:
      'Deleting an FX rate override will adjust the unit cost on the affected listings. Are you sure?',
  },
  [ContentId.DeleteOverrideFxRate]: {
    id: ContentId.DeleteOverrideFxRate,
    defaultValue: 'Delete Override Fx Rate',
  },
  [ContentId.DeletePlaceholder]: {
    id: ContentId.DeletePlaceholder,
    defaultValue: 'Delete Placeholder',
  },
  [ContentId.DeletePlaceholderAll]: {
    id: ContentId.DeletePlaceholderAll,
    defaultValue: 'Delete All Placeholder Inventory',
  },
  [ContentId.DeletePlaceholderAllVisible]: {
    id: ContentId.DeletePlaceholderAllVisible,
    defaultValue: 'Delete All Visible Placeholder Inventory',
  },
  [ContentId.DeletePlaceholderListing]: {
    id: ContentId.DeletePlaceholderListing,
    defaultValue: 'Delete Placeholder Listing',
  },
  [ContentId.DeletePlaceholderSelected]: {
    id: ContentId.DeletePlaceholderSelected,
    defaultValue: 'Delete All Selected Placeholder Inventory',
  },
  [ContentId.DeletePricingStrategy]: {
    id: ContentId.DeletePricingStrategy,
    defaultValue: 'Delete pricing strategy',
  },
  [ContentId.DeleteReport]: {
    id: ContentId.DeleteReport,
    defaultValue: 'Delete Report',
  },
  [ContentId.DeleteRole]: {
    id: ContentId.DeleteRole,
    defaultValue: 'Delete Role',
  },
  [ContentId.DeleteSeatSaver]: {
    id: ContentId.DeleteSeatSaver,
    defaultValue: 'Delete Seat Saver',
  },
  [ContentId.DeleteSeatSaverAll]: {
    id: ContentId.DeleteSeatSaverAll,
    defaultValue: 'Delete Seat Saver for All',
  },
  [ContentId.DeleteSeatSaverAllVisible]: {
    id: ContentId.DeleteSeatSaverAllVisible,
    defaultValue: 'Delete Seat Saver for All Visible Listings',
  },
  [ContentId.DeleteSeatSaverSelected]: {
    id: ContentId.DeleteSeatSaverSelected,
    defaultValue: 'Delete Seat Saver for Selected',
  },
  [ContentId.DeleteTag]: {
    id: ContentId.DeleteTag,
    defaultValue: 'Delete Tag',
  },
  [ContentId.DeleteTagConfirmation1]: {
    id: ContentId.DeleteTagConfirmation1,
    defaultValue: 'Are you sure you want to delete this tag definition?',
  },
  [ContentId.DeleteTagConfirmation2]: {
    id: ContentId.DeleteTagConfirmation2,
    defaultValue:
      'This action will remove all associated tag values and columns from reports.',
  },
  [ContentId.DeleteTeam]: {
    id: ContentId.DeleteTeam,
    defaultValue: 'Delete Team',
  },
  [ContentId.DeleteUser]: {
    id: ContentId.DeleteUser,
    defaultValue: 'Delete user',
  },
  [ContentId.DelistedClickToList]: {
    id: ContentId.DelistedClickToList,
    defaultValue: 'Delisted - click to list',
  },
  [ContentId.DelistedSyncFailed]: {
    id: ContentId.DelistedSyncFailed,
    defaultValue: 'Delisted, Sync Failed',
  },
  [ContentId.DeliveryDocuments]: {
    id: ContentId.DeliveryDocuments,
    defaultValue: 'Delivery Documents',
  },
  [ContentId.DeliveryMethodChange]: {
    id: ContentId.DeliveryMethodChange,
    defaultValue: 'Delivery Method Change',
  },
  [ContentId.DeliveryType]: {
    id: ContentId.DeliveryType,
    defaultValue: 'Delivery Type',
  },
  [ContentId.DemandFactors]: {
    id: ContentId.DemandFactors,
    defaultValue: 'Demand Factor',
  },
  [ContentId.Description]: {
    id: ContentId.Description,
    defaultValue: 'Description',
  },
  [ContentId.DescriptionOfPurpose]: {
    id: ContentId.DescriptionOfPurpose,
    defaultValue: 'Description of purpose',
  },
  [ContentId.DeselectAll]: {
    id: ContentId.DeselectAll,
    defaultValue: 'Deselect all',
  },
  [ContentId.DesiredActiveListings]: {
    id: ContentId.DesiredActiveListings,
    defaultValue: 'Desired Active Listings',
  },
  [ContentId.DecreasePriceByPercentAll]: {
    id: ContentId.DecreasePriceByPercentAll,
    defaultValue: 'Decrease All Price By X%',
  },
  [ContentId.DecreasePriceByPercentSelected]: {
    id: ContentId.DecreasePriceByPercentSelected,
    defaultValue: 'Decrease Selected Price By X%',
  },
  [ContentId.DesiredNumberOfGroups]: {
    id: ContentId.DesiredNumberOfGroups,
    defaultValue: 'Desired Number of Groups',
  },
  [ContentId.DigitalWallet]: {
    id: ContentId.DigitalWallet,
    defaultValue: 'Digital Wallet',
  },
  [ContentId.DirectToStubHubInternationalMessage]: {
    id: ContentId.DirectToStubHubInternationalMessage,
    defaultValue:
      'StubHub International is a different company. We will be unable to help you with your order once you leave this site. You will be responsible for reviewing their terms and conditions, guarantee and working directly with their customer service.',
  },
  [ContentId.DirectToStubHubInternationalTitle]: {
    id: ContentId.DirectToStubHubInternationalTitle,
    defaultValue:
      'You are being redirected to another business: StubHub International',
  },
  [ContentId.DifferentCurrencyCodesRequired]: {
    id: ContentId.DifferentCurrencyCodesRequired,
    defaultValue: 'Different currency codes are required',
  },
  [ContentId.AutoSourceTickets]: {
    id: ContentId.AutoSourceTickets,
    defaultValue: 'Auto-Source Tickets',
  },
  [ContentId.Disabled]: {
    id: ContentId.Disabled,
    defaultValue: 'Disabled',
  },
  [ContentId.DisableDarkMode]: {
    id: ContentId.DisableDarkMode,
    defaultValue: 'Disable Dark Mode',
  },
  [ContentId.Discount]: {
    id: ContentId.Discount,
    defaultValue: 'Discount',
  },
  [ContentId.Display]: {
    id: ContentId.Display,
    defaultValue: 'Display',
  },
  [ContentId.DisplayName]: {
    id: ContentId.DisplayName,
    defaultValue: 'Display name',
  },
  [ContentId.Distance]: {
    id: ContentId.Distance,
    defaultValue: 'Distance',
  },
  [ContentId.DollarsInBasket]: {
    id: ContentId.DollarsInBasket,
    defaultValue: '$ in Basket',
  },
  [ContentId.Done]: {
    id: ContentId.Done,
    defaultValue: 'Done',
  },
  [ContentId.DontChange]: {
    id: ContentId.DontChange,
    defaultValue: "Don't Change",
  },
  [ContentId.DontPriceBelow]: {
    id: ContentId.DontPriceBelow,
    defaultValue: "Don't price below",
  },
  [ContentId.Download]: {
    id: ContentId.Download,
    defaultValue: 'Download',
  },
  [ContentId.DownloadTemplate]: {
    id: ContentId.DownloadTemplate,
    defaultValue: 'Download Template',
  },
  [ContentId.DoYouWantToContinue]: {
    id: ContentId.DoYouWantToContinue,
    defaultValue: 'Do you want to continue?',
  },
  [ContentId.Duplicate]: {
    id: ContentId.Duplicate,
    defaultValue: 'Duplicate',
  },
  [ContentId.DuplicatedListing]: {
    id: ContentId.DuplicatedListing,
    defaultValue: 'Duplicated Listing',
  },
  [ContentId.DuplicatedReportName]: {
    id: ContentId.DuplicatedReportName,
    defaultValue: 'A report with the same name already exists.',
  },
  [ContentId.DuplicateEventNeedsToBeRescheduled]: {
    id: ContentId.DuplicateEventNeedsToBeRescheduled,
    defaultValue: 'Duplicate Event - Needs to be rescheduled',
  },
  [ContentId.DuplicateEventPerformerVenueDateAndTimeMatch]: {
    id: ContentId.DuplicateEventPerformerVenueDateAndTimeMatch,
    defaultValue: 'Duplicate Event - Performer, venue, date, and time match',
  },
  [ContentId.Duplicates]: {
    id: ContentId.Duplicates,
    defaultValue: 'Duplicates',
  },
  [ContentId.DuplicateTicketId]: {
    id: ContentId.DuplicateTicketId,
    defaultValue: 'Duplicate Ticket ID.',
  },
  [ContentId.each]: {
    id: ContentId.each,
    defaultValue: 'each',
  },
  [ContentId.EachTicketUrlOnNewLine]: {
    id: ContentId.EachTicketUrlOnNewLine,
    defaultValue: 'Each url should be on a new line.',
  },
  [ContentId.EachTicketIdOnNewLine]: {
    id: ContentId.EachTicketIdOnNewLine,
    defaultValue: 'Each ticket ID should be on a new line.',
  },
  [ContentId.EarlyShippingLabelGeneration]: {
    id: ContentId.EarlyShippingLabelGeneration,
    defaultValue: 'Early Shipping Label Generation',
  },
  [ContentId.Earnings]: {
    id: ContentId.Earnings,
    defaultValue: 'Earnings',
  },
  [ContentId.Editable]: {
    id: ContentId.Editable,
    defaultValue: 'Editable',
  },
  [ContentId.EditATeam]: {
    id: ContentId.EditATeam,
    defaultValue: 'Edit a team',
  },
  [ContentId.EditColumns]: {
    id: ContentId.EditColumns,
    defaultValue: 'Edit Columns',
  },
  [ContentId.EditEditabilityDescription]: {
    id: ContentId.EditEditabilityDescription,
    defaultValue: 'User can edit the value set for this filter',
  },
  [ContentId.EditEventWarning]: {
    id: ContentId.EditEventWarning,
    defaultValue:
      'Warning! Changing event of a ticket group will delete it and all associated listings and deallocate all associated sales and recreate the ticket group and listings.',
  },
  [ContentId.EditEventWarning2]: {
    id: ContentId.EditEventWarning2,
    defaultValue:
      'Warning! Changing event of a ticket group will delete it and all associated listings and recreate the ticket group and listings. You may chose to deallocate the associated sales below.',
  },
  [ContentId.EditGeneralSettings]: {
    id: ContentId.EditGeneralSettings,
    defaultValue: 'Edit General Settings',
  },
  [ContentId.EditMarketplaceEvent]: {
    id: ContentId.EditMarketplaceEvent,
    defaultValue: 'Edit Marketplace Event Info',
  },
  [ContentId.EditACustomColumn]: {
    id: ContentId.EditACustomColumn,
    defaultValue: 'Edit a Custom Column',
  },
  [ContentId.EditMetrics]: {
    id: ContentId.EditMetrics,
    defaultValue: 'Edit Metrics',
  },
  [ContentId.EditNote]: {
    id: ContentId.EditNote,
    defaultValue: 'Edit Note',
  },
  [ContentId.EditOverrideFxRate]: {
    id: ContentId.EditOverrideFxRate,
    defaultValue: 'Edit Override Fx Rate',
  },
  [ContentId.EditPricing]: {
    id: ContentId.EditPricing,
    defaultValue: 'Edit Pricing',
  },
  [ContentId.EditPricingStrategy]: {
    id: ContentId.EditPricingStrategy,
    defaultValue: 'Edit pricing strategy',
  },
  [ContentId.EditReport]: {
    id: ContentId.EditReport,
    defaultValue: 'Edit Report',
  },
  [ContentId.EditRowScore]: {
    id: ContentId.EditRowScore,
    defaultValue: 'Edit Row Score',
  },
  [ContentId.EditRowScores]: {
    id: ContentId.EditRowScores,
    defaultValue: 'Edit Row Scores',
  },
  [ContentId.EditScores]: {
    id: ContentId.EditScores,
    defaultValue: 'Edit Scores',
  },
  [ContentId.EditSections]: {
    id: ContentId.EditSections,
    defaultValue: 'Edit Sections',
  },
  [ContentId.EditTagTypes]: {
    id: ContentId.EditTagTypes,
    defaultValue: 'Edit Tag Types',
  },
  [ContentId.EditUser]: {
    id: ContentId.EditUser,
    defaultValue: 'Edit User',
  },
  [ContentId.EffectiveCeilingBelowFloor]: {
    id: ContentId.EffectiveCeilingBelowFloor,
    defaultValue: 'Effective Ceiling Below Floor',
  },
  [ContentId.EmailPreview]: {
    id: ContentId.EmailPreview,
    defaultValue: 'Email Preview',
  },
  [ContentId.EnableAll]: {
    id: ContentId.EnableAll,
    defaultValue: 'Enable All',
  },
  [ContentId.EnableAndDisableExperimentalFeatures]: {
    id: ContentId.EnableAndDisableExperimentalFeatures,
    defaultValue:
      'Enable and disable experimental features of StubHub platform',
  },
  [ContentId.EnableAutoPricing]: {
    id: ContentId.EnableAutoPricing,
    defaultValue: 'Enable auto pricing',
  },
  [ContentId.Enabled]: {
    id: ContentId.Enabled,
    defaultValue: 'Enabled',
  },
  [ContentId.EnableDarkMode]: {
    id: ContentId.EnableDarkMode,
    defaultValue: 'Enable Dark Mode',
  },
  [ContentId.EnableQualityControlForAutoPo]: {
    id: ContentId.EnableQualityControlForAutoPo,
    defaultValue: 'Enable quality control for Auto-PO',
  },
  [ContentId.EnjoyTheEvent]: {
    id: ContentId.EnjoyTheEvent,
    defaultValue: 'Enjoy the Event',
  },
  [ContentId.EnterBarcodesForTransferIns]: {
    id: ContentId.EnterBarcodesForTransferIns,
    defaultValue:
      'Review the barcode for each seat. Update or fill-in any one necessary. You can copy & paste an ordered list from excel in the bulk entry view to auto-populate the seats.',
  },
  [ContentId.EnterResetCodeFromEmail]: {
    id: ContentId.EnterResetCodeFromEmail,
    defaultValue: 'Enter reset code from email',
  },
  [ContentId.EnterStubHubEmailPrompt]: {
    id: ContentId.EnterStubHubEmailPrompt,
    defaultValue: "Enter the user's StubHub account email",
  },
  [ContentId.EnterTicketUrlsIns]: {
    id: ContentId.EnterTicketUrlsIns,
    defaultValue:
      'Review the ticket URL for each seat. Update or fill-in any one necessary. You can copy & paste an ordered list from excel in the bulk entry view to auto-populate the seats.',
  },
  [ContentId.EnterTicketIdsIns]: {
    id: ContentId.EnterTicketIdsIns,
    defaultValue:
      'Review the ticket ID for each seat. Update or fill-in any one necessary. You can copy & paste an ordered list from excel in the bulk entry view to auto-populate the seats.',
  },
  [ContentId.EnterValuesFor]: {
    id: ContentId.EnterValuesFor,
    defaultValue: 'Enter values for',
  },
  [ContentId.Equals]: {
    id: ContentId.Equals,
    defaultValue: 'Equals',
  },
  [ContentId.EventCancelled]: {
    id: ContentId.EventCancelled,
    defaultValue: 'Event Cancelled',
  },
  [ContentId.EventHasPassed]: {
    id: ContentId.EventHasPassed,
    defaultValue: 'Event has Passed',
  },
  [ContentId.EventId]: {
    id: ContentId.EventId,
    defaultValue: 'Event ID',
  },
  [ContentId.EventMapped]: {
    id: ContentId.EventMapped,
    defaultValue: 'Event Mapped',
  },
  [ContentId.EventPostponed]: {
    id: ContentId.EventPostponed,
    defaultValue: 'Event Postponed',
  },
  [ContentId.Events_AddTagType_Details]: {
    id: ContentId.Events_AddTagType_Details,
    defaultValue: 'Permits adding new tag types to events',
  },
  [ContentId.Events_Edit_Details]: {
    id: ContentId.Events_Edit_Details,
    defaultValue: 'Allows updating event details',
  },
  [ContentId.Events_SetPrice_Details]: {
    id: ContentId.Events_SetPrice_Details,
    defaultValue: 'Configure pricing settings for indvidual events',
  },
  [ContentId.Events_UpdateTags_Details]: {
    id: ContentId.Events_UpdateTags_Details,
    defaultValue: 'Permits updating of tags on event records',
  },
  [ContentId.Events_ViewAll_Details]: {
    id: ContentId.Events_ViewAll_Details,
    defaultValue:
      'Ability to query any event using ViagogoEventId or ViagogoEventMappingId',
  },
  [ContentId.EventTag]: {
    id: ContentId.EventTag,
    defaultValue: 'Event Tag',
  },
  [ContentId.EventTags]: {
    id: ContentId.EventTags,
    defaultValue: 'Event Tags',
  },
  [ContentId.EventView]: {
    id: ContentId.EventView,
    defaultValue: 'Event View',
  },
  [ContentId.Example]: {
    id: ContentId.Example,
    defaultValue: 'Example',
  },
  [ContentId.ExcessiveFailureRate]: {
    id: ContentId.ExcessiveFailureRate,
    defaultValue: 'Excessive Failure Rate',
  },
  [ContentId.Exclude]: {
    id: ContentId.Exclude,
    defaultValue: 'Exclude',
  },
  [ContentId.ExcludeFanInventory]: {
    id: ContentId.ExcludeFanInventory,
    defaultValue: 'Exclude Fan Inventory',
  },
  [ContentId.ExcludeFullySoldListings]: {
    id: ContentId.ExcludeFullySoldListings,
    defaultValue: 'Exclude fully sold listings',
  },
  [ContentId.ExclusionCurrencies]: {
    id: ContentId.ExclusionCurrencies,
    defaultValue: 'Exclusion Currencies',
  },
  [ContentId.ExclusionCurrenciesSubtitle]: {
    id: ContentId.ExclusionCurrenciesSubtitle,
    defaultValue: 'Exclude currencies that should not be auto-converted',
  },
  [ContentId.ExistingOrder]: {
    id: ContentId.ExistingOrder,
    defaultValue: 'Existing Order',
  },
  [ContentId.ExistingTagName]: {
    id: ContentId.ExistingTagName,
    defaultValue: 'Tag with the same name already exists',
  },
  [ContentId.ExistingTagValue]: {
    id: ContentId.ExistingTagValue,
    defaultValue: 'Tag with same key/value pair already exists',
  },
  [ContentId.Exit]: {
    id: ContentId.Exit,
    defaultValue: 'Exit',
  },
  [ContentId.ExpectedProceeds]: {
    id: ContentId.ExpectedProceeds,
    defaultValue: 'Expected Proceeds',
  },
  [ContentId.ExpectedProfit]: {
    id: ContentId.ExpectedProfit,
    defaultValue: 'Expected Profit',
  },
  [ContentId.ExpectedSTR]: {
    id: ContentId.ExpectedSTR,
    defaultValue: 'Expected STR',
  },
  [ContentId.ExperimentalFeatures]: {
    id: ContentId.ExperimentalFeatures,
    defaultValue: 'Experimental features',
  },
  [ContentId.ExperimentalFeaturesComingSoon]: {
    id: ContentId.ExperimentalFeaturesComingSoon,
    defaultValue: 'Experimental Features Coming Soon',
  },
  [ContentId.ExpiredShippingLabel]: {
    id: ContentId.ExpiredShippingLabel,
    defaultValue: 'Expired Shipping Label',
  },
  [ContentId.ExploreComparables]: {
    id: ContentId.ExploreComparables,
    defaultValue: 'Explore Comparables',
  },
  [ContentId.ExportListings]: {
    id: ContentId.ExportListings,
    defaultValue: 'Export Listings',
  },
  [ContentId.ExportSales]: {
    id: ContentId.ExportSales,
    defaultValue: 'Export Sales',
  },
  [ContentId.ExternalPurchaseId]: {
    id: ContentId.ExternalPurchaseId,
    defaultValue: 'External Purchase Id',
  },
  [ContentId.ExternalSaleId]: {
    id: ContentId.ExternalSaleId,
    defaultValue: 'External Sale Id',
  },
  [ContentId.Far]: {
    id: ContentId.Far,
    defaultValue: 'Far',
  },
  [ContentId.Festival]: {
    id: ContentId.Festival,
    defaultValue: 'Festival',
  },
  [ContentId.FindThe]: {
    id: ContentId.FindThe,
    defaultValue: 'Find the',
  },
  [ContentId.FulfillmentProviders]: {
    id: ContentId.FulfillmentProviders,
    defaultValue: 'Fulfillment Providers',
  },
  [ContentId.FulfillmentProvidersSubText]: {
    id: ContentId.FulfillmentProvidersSubText,
    defaultValue: 'Choose which channels you want to distribute to',
  },
  [ContentId.FullPrice]: {
    id: ContentId.FullPrice,
    defaultValue: 'Full Price',
  },
  [ContentId.GlobalReportFilterChangeCopyMessage]: {
    id: ContentId.GlobalReportFilterChangeCopyMessage,
    defaultValue:
      'Changes to the filter will not be persisted. Make a copy to save the changes.',
  },
  [ContentId.HasUnsoldListings]: {
    id: ContentId.HasUnsoldListings,
    defaultValue: 'Has Unsold Listings',
  },
  [ContentId.Hidden]: {
    id: ContentId.Hidden,
    defaultValue: 'Hidden',
  },
  [ContentId.HiddenEditabilityDescription]: {
    id: ContentId.HiddenEditabilityDescription,
    defaultValue: 'User cannot see this filter has been set',
  },
  [ContentId.HowToDeterminePrice]: {
    id: ContentId.HowToDeterminePrice,
    defaultValue: 'How to determine price',
  },
  [ContentId.LastMinuteSalesLateDelivery]: {
    id: ContentId.LastMinuteSalesLateDelivery,
    defaultValue: 'Last Minute Sales Late Delivery',
  },
  [ContentId.LastReviewed]: {
    id: ContentId.LastReviewed,
    defaultValue: 'Last Reviewed',
  },
  [ContentId.InventoryTag]: {
    id: ContentId.InventoryTag,
    defaultValue: 'Inventory Tag',
  },
  [ContentId.GlobalReportFilterChangeMessage]: {
    id: ContentId.GlobalReportFilterChangeMessage,
    defaultValue: 'Changes to the filter will not be persisted.',
  },
  [ContentId.Include]: {
    id: ContentId.Include,
    defaultValue: 'Include',
  },
  [ContentId.IncludeAllCountries]: {
    id: ContentId.IncludeAllCountries,
    defaultValue: 'Include All Countries',
  },
  [ContentId.IncludeAllVendors]: {
    id: ContentId.IncludeAllVendors,
    defaultValue: 'Include All Vendors',
  },
  [ContentId.IncludedCountries]: {
    id: ContentId.IncludedCountries,
    defaultValue: 'Countries Included for Processing',
  },
  [ContentId.ListedDelistFailed]: {
    id: ContentId.ListedDelistFailed,
    defaultValue: 'Listed, Delist Failed',
  },
  [ContentId.ListedSyncFailed]: {
    id: ContentId.ListedSyncFailed,
    defaultValue: 'Listed, Sync Failed',
  },
  [ContentId.ListingGroupSummaryActiveAndActive]: {
    id: ContentId.ListingGroupSummaryActiveAndActive,
    defaultValue: 'Active in group and listed',
  },
  [ContentId.ListingGroupSummaryActiveButNotListed]: {
    id: ContentId.ListingGroupSummaryActiveButNotListed,
    defaultValue: 'Active in group but not listed',
  },
  [ContentId.ListingGroupSummaryQueued]: {
    id: ContentId.ListingGroupSummaryQueued,
    defaultValue: 'Queued',
  },
  [ContentId.LMSError]: {
    id: ContentId.LMSError,
    defaultValue: 'LMS Error',
  },
  [ContentId.MarkAsFulfilled]: {
    id: ContentId.MarkAsFulfilled,
    defaultValue: 'Mark as Fulfilled',
  },
  [ContentId.MarkAsReviewed]: {
    id: ContentId.MarkAsReviewed,
    defaultValue: 'Mark as Reviewed',
  },
  [ContentId.MarketplaceEnableDisableConfirm]: {
    id: ContentId.MarketplaceEnableDisableConfirm,
    defaultValue: 'Enable/disable Marketplaces Confirmation',
  },
  [ContentId.MarketplaceFulfillmentState]: {
    id: ContentId.MarketplaceFulfillmentState,
    defaultValue: 'Marketplace Fulfillment State',
  },
  [ContentId.MaxReportSizeWarningLabel]: {
    id: ContentId.MaxReportSizeWarningLabel,
    defaultValue: 'Max rows exceeded',
  },
  [ContentId.MaxActiveListings]: {
    id: ContentId.MaxActiveListings,
    defaultValue: 'Max Active Listings',
  },
  [ContentId.MergedVenueGeometry]: {
    id: ContentId.MergedVenueGeometry,
    defaultValue: 'Merged Venue Geometry',
  },
  [ContentId.NoGroupsAvailableForVenueConfig]: {
    id: ContentId.NoGroupsAvailableForVenueConfig,
    defaultValue: 'No groupings available for this venue configuration.',
  },
  [ContentId.MinActiveListings]: {
    id: ContentId.MinActiveListings,
    defaultValue: 'Min Active Listings',
  },
  [ContentId.NoItemsWereChanged]: {
    id: ContentId.NoItemsWereChanged,
    defaultValue: 'No items matched the criteria for change.',
  },
  [ContentId.Mappings]: {
    id: ContentId.Mappings,
    defaultValue: 'Mappings',
  },
  [ContentId.NoFulfillmentProviders]: {
    id: ContentId.NoFulfillmentProviders,
    defaultValue: 'No fulfillment providers available.',
  },
  [ContentId.NotContains]: {
    id: ContentId.NotContains,
    defaultValue: 'Not Contains',
  },
  [ContentId.NumberOfUnsoldTickets]: {
    id: ContentId.NumberOfUnsoldTickets,
    defaultValue: 'Number of unsold tickets.',
  },
  [ContentId.NumberOfListedTicketsDescription]: {
    id: ContentId.NumberOfListedTicketsDescription,
    defaultValue: 'Number of unsold tickets that are priced and broadcasted.',
  },
  [ContentId.NumberOfUnlistedTicketsDescription]: {
    id: ContentId.NumberOfUnlistedTicketsDescription,
    defaultValue:
      'Number of unsold tickets that need action (either to be priced or broadcasted).',
  },
  [ContentId.OnlyThis]: {
    id: ContentId.OnlyThis,
    defaultValue: 'Only This',
  },
  [ContentId.OrderNotFulfilled]: {
    id: ContentId.OrderNotFulfilled,
    defaultValue: 'Order not Fulfilled',
  },
  [ContentId.OriginatedBy]: {
    id: ContentId.OriginatedBy,
    defaultValue: 'Originated By',
  },
  [ContentId.OverchargedSeller]: {
    id: ContentId.OverchargedSeller,
    defaultValue: 'Overcharged Seller',
  },
  [ContentId.PandLTitle]: {
    id: ContentId.PandLTitle,
    defaultValue: 'Profit & Loss from sold tickets.',
  },
  [ContentId.PaymentId]: {
    id: ContentId.PaymentId,
    defaultValue: 'Payment ID',
  },
  [ContentId.Penalty]: {
    id: ContentId.Penalty,
    defaultValue: 'Penalty',
  },
  [ContentId.POPaymentState]: {
    id: ContentId.POPaymentState,
    defaultValue: 'PO Payment State',
  },
  [ContentId.Postponed]: {
    id: ContentId.Postponed,
    defaultValue: 'Postponed',
  },
  [ContentId.PriceHitHardFloor]: {
    id: ContentId.PriceHitHardFloor,
    defaultValue: 'Price Hit Hard Floor',
  },
  [ContentId.PriceHitRelativeFloor]: {
    id: ContentId.PriceHitRelativeFloor,
    defaultValue: 'Price Hit Relative Floor',
  },
  [ContentId.PriceHitMaxAllowedDrop]: {
    id: ContentId.PriceHitMaxAllowedDrop,
    defaultValue: 'Price Hit Max Allowed Drop',
  },
  [ContentId.PrivateInventoryNotes]: {
    id: ContentId.PrivateInventoryNotes,
    defaultValue: 'Private Inventory Notes',
  },
  [ContentId.PrivateSaleNotes]: {
    id: ContentId.PrivateSaleNotes,
    defaultValue: 'Private Sale Notes',
  },
  [ContentId.PrivatePurchaseNotes]: {
    id: ContentId.PrivatePurchaseNotes,
    defaultValue: 'Private Purchase Notes',
  },
  [ContentId.PurchaseTag]: {
    id: ContentId.PurchaseTag,
    defaultValue: 'Purchase Tag',
  },
  [ContentId.PutSaleListingOnHold]: {
    id: ContentId.PutSaleListingOnHold,
    defaultValue: 'Put sale listing on hold',
  },
  [ContentId.QuantitiesForcedToBottom]: {
    id: ContentId.QuantitiesForcedToBottom,
    defaultValue: 'Quantities Forced to Bottom',
  },
  [ContentId.Queued]: {
    id: ContentId.Queued,
    defaultValue: 'Queued',
  },
  [ContentId.RAGDeliveryDispute]: {
    id: ContentId.RAGDeliveryDispute,
    defaultValue: 'RAG / Delivery Dispute',
  },
  [ContentId.RAGSellerFaultNoResponse]: {
    id: ContentId.RAGSellerFaultNoResponse,
    defaultValue: 'RAG - Seller fault No Response',
  },
  [ContentId.Relocated]: {
    id: ContentId.Relocated,
    defaultValue: 'Relocated',
  },
  [ContentId.RelocatedAndRescheduled]: {
    id: ContentId.RelocatedAndRescheduled,
    defaultValue: 'Relocated and rescheduled',
  },
  [ContentId.ReplaceWith]: {
    id: ContentId.ReplaceWith,
    defaultValue: 'Replace with',
  },
  [ContentId.Rescheduled]: {
    id: ContentId.Rescheduled,
    defaultValue: 'Rescheduled',
  },
  [ContentId.SaleTag]: {
    id: ContentId.SaleTag,
    defaultValue: 'Sale Tag',
  },
  [ContentId.ResetFilters]: {
    id: ContentId.ResetFilters,
    defaultValue: 'Reset Filters',
  },
  [ContentId.SaleIsFulfilledOnMarketplace]: {
    id: ContentId.SaleIsFulfilledOnMarketplace,
    defaultValue:
      'Fulfillment was reset within the platform but is still fulfilled on the marketplace.',
  },
  [ContentId.SaleIsFulfilledOnMarketplace2]: {
    id: ContentId.SaleIsFulfilledOnMarketplace2,
    defaultValue:
      'The fulfillment status was reset on the platform, but the order is still fulfilled on the marketplace.',
  },
  [ContentId.SameSize]: {
    id: ContentId.SameSize,
    defaultValue: 'Same Size',
  },
  [ContentId.FlatView]: {
    id: ContentId.FlatView,
    defaultValue: 'Flat View',
  },
  [ContentId.Floor]: {
    id: ContentId.Floor,
    defaultValue: 'Floor',
  },
  [ContentId.FloorMustBeLessThanCeiling]: {
    id: ContentId.FloorMustBeLessThanCeiling,
    defaultValue: 'Price floor must be less than ceiling.',
  },
  [ContentId.Change]: {
    id: ContentId.Change,
    defaultValue: 'Change',
  },
  [ContentId.Check]: {
    id: ContentId.Check,
    defaultValue: 'Check',
  },
  [ContentId.ChooseAccount]: {
    id: ContentId.ChooseAccount,
    defaultValue: 'Choose Account',
  },
  [ContentId.ChoosePaymentMethodPlaceholder]: {
    id: ContentId.ChoosePaymentMethodPlaceholder,
    defaultValue: 'Choose payment method',
  },
  [ContentId.ChoosePaymentMethodTypePlaceholder]: {
    id: ContentId.ChoosePaymentMethodTypePlaceholder,
    defaultValue: 'Choose payment type',
  },
  [ContentId.ChooseVendor]: {
    id: ContentId.ChooseVendor,
    defaultValue: 'Choose Vendor',
  },
  [ContentId.ChooseIncrementType]: {
    id: ContentId.ChooseIncrementType,
    defaultValue: 'Choose increment type',
  },
  [ContentId.ChooseSeatingNotes]: {
    id: ContentId.ChooseSeatingNotes,
    defaultValue: 'Choose seat traits',
  },
  [ContentId.City]: {
    id: ContentId.City,
    defaultValue: 'City',
  },
  [ContentId.Classify]: {
    id: ContentId.Classify,
    defaultValue: 'Classify',
  },
  [ContentId.ClickToViewCompsInstruction]: {
    id: ContentId.ClickToViewCompsInstruction,
    defaultValue: 'Click to see available tickets and prices on the left.',
  },
  [ContentId.CompListingMode]: {
    id: ContentId.CompListingMode,
    defaultValue: 'Comp. Listing Mode',
  },
  [ContentId.CompListingFloor]: {
    id: ContentId.CompListingFloor,
    defaultValue: 'Comp. Listing Floor',
  },
  [ContentId.CompListingFloorMustBeSetError]: {
    id: ContentId.CompListingFloorMustBeSetError,
    defaultValue: 'Comp. Listing Floor must be set',
  },
  [ContentId.ContentView]: {
    id: ContentId.ContentView,
    defaultValue: 'Content View',
  },
  [ContentId.Copied]: {
    id: ContentId.Copied,
    defaultValue: 'Copied!',
  },
  [ContentId.CostPerTicket]: {
    id: ContentId.CostPerTicket,
    defaultValue: 'Cost Per Ticket',
  },
  [ContentId.Country]: {
    id: ContentId.Country,
    defaultValue: 'Country',
  },

  [ContentId.ConfirmETicketsIns]: {
    id: ContentId.ConfirmETicketsIns,
    defaultValue: "Confirm the tickets you're providing",
  },
  [ContentId.ClickToTakeAction]: {
    id: ContentId.ClickToTakeAction,
    defaultValue: 'Click here to take action.',
  },
  [ContentId.Close]: {
    id: ContentId.Close,
    defaultValue: 'Close',
  },
  [ContentId.COD]: {
    id: ContentId.COD,
    defaultValue: 'COD',
  },
  [ContentId.ConfirmSale]: {
    id: ContentId.ConfirmSale,
    defaultValue: 'Confirm Sale',
  },
  [ContentId.Contact]: { id: ContentId.Contact, defaultValue: 'Contact' },
  [ContentId.CostAndCredits]: {
    id: ContentId.CostAndCredits,
    defaultValue: 'Cost & Credits',
  },
  [ContentId.Cost]: {
    id: ContentId.Cost,
    defaultValue: 'Cost',
  },
  [ContentId.CostBasis]: {
    id: ContentId.CostBasis,
    defaultValue: 'Cost Basis',
  },
  [ContentId.CreateCustomFilter]: {
    id: ContentId.CreateCustomFilter,
    defaultValue: 'Create custom filter',
  },
  [ContentId.Credit]: {
    id: ContentId.Credit,
    defaultValue: 'Credit',
  },
  [ContentId.CreditBalance]: {
    id: ContentId.CreditBalance,
    defaultValue: 'Credit Balance',
  },
  [ContentId.CreditCard]: {
    id: ContentId.CreditCard,
    defaultValue: 'Credit Card',
  },
  [ContentId.CreditCardFee]: {
    id: ContentId.CreditCardFee,
    defaultValue: 'Credit Card Fee',
  },
  [ContentId.CustomDate]: {
    id: ContentId.CustomDate,
    defaultValue: 'Custom Date',
  },
  [ContentId.CustomDateRange]: {
    id: ContentId.CustomDateRange,
    defaultValue: 'Custom Date Range',
  },
  [ContentId.CustomFilter]: {
    id: ContentId.CustomFilter,
    defaultValue: 'Custom Filter',
  },
  [ContentId.Currency]: {
    id: ContentId.Currency,
    defaultValue: 'Currency',
  },
  [ContentId.DateAscArrow]: {
    id: ContentId.DateAscArrow,
    defaultValue: 'Date ↓',
  },
  [ContentId.DateDescArrow]: {
    id: ContentId.DateDescArrow,
    defaultValue: 'Date ↑',
  },
  [ContentId.DateDue]: {
    id: ContentId.DateDue,
    defaultValue: 'Date Due',
  },
  [ContentId.DateIncurred]: {
    id: ContentId.DateIncurred,
    defaultValue: 'Date Incurred',
  },
  [ContentId.DatePaid]: {
    id: ContentId.DatePaid,
    defaultValue: 'Date Paid',
  },
  [ContentId.DateRangeDisplayText]: {
    id: ContentId.DateRangeDisplayText,
    defaultValue: 'Select Date',
  },
  [ContentId.DateRangeSuggestionsTitle]: {
    id: ContentId.DateRangeSuggestionsTitle,
    defaultValue: 'Suggestions',
  },
  [ContentId.DaysPostfix]: {
    id: ContentId.DaysPostfix,
    defaultValue: 'day(s)',
  },
  [ContentId.Deactivated]: {
    id: ContentId.Deactivated,
    defaultValue: 'Deactivated',
  },
  [ContentId.Deal]: {
    id: ContentId.Deal,
    defaultValue: 'Deal',
  },
  [ContentId.DeallocateSale]: {
    id: ContentId.DeallocateSale,
    defaultValue: 'Deallocate',
  },
  [ContentId.DeallocateSaleWarning]: {
    id: ContentId.DeallocateSaleWarning,
    defaultValue:
      'You are about to put this sale back to Pending Confirmation. Continue?',
  },
  [ContentId.DecreasePriceByPercent]: {
    id: ContentId.DecreasePriceByPercent,
    defaultValue: 'Decrease Price By X%',
  },
  [ContentId.Default]: {
    id: ContentId.Default,
    defaultValue: 'Default',
  },
  [ContentId.DefaultHideSeatNumbers]: {
    id: ContentId.DefaultHideSeatNumbers,
    defaultValue: 'Default Hide Seat Numbers',
  },
  [ContentId.DefaultInHandDateOffset]: {
    id: ContentId.DefaultInHandDateOffset,
    defaultValue: 'Default In Hand Date Offset',
  },
  [ContentId.DefaultSplitType]: {
    id: ContentId.DefaultSplitType,
    defaultValue: 'Default Split Type',
  },
  [ContentId.Deleted]: {
    id: ContentId.Deleted,
    defaultValue: 'Deleted',
  },
  [ContentId.DeletePurchasePaymentMethod]: {
    id: ContentId.DeletePurchasePaymentMethod,
    defaultValue: 'Delete Purchase Payment Method',
  },
  [ContentId.DeletePurchasePaymentMethodWarning]: {
    id: ContentId.DeletePurchasePaymentMethodWarning,
    defaultValue: 'You are about to delete this payment method. Continue?',
  },
  [ContentId.DeletePurchaseVendor]: {
    id: ContentId.DeletePurchaseVendor,
    defaultValue: 'Delete Purchase Vendor',
  },
  [ContentId.DeletePurchaseVendorWarning]: {
    id: ContentId.DeletePurchaseVendorWarning,
    defaultValue: 'You are about to delete this vendor. Continue?',
  },
  [ContentId.DeletePurchaseVendorAccount]: {
    id: ContentId.DeletePurchaseVendorAccount,
    defaultValue: 'Delete Purchase Vendor Account',
  },
  [ContentId.DeletePurchaseVendorAccountWarning]: {
    id: ContentId.DeletePurchaseVendorAccountWarning,
    defaultValue: 'You are about to delete this vendor account. Continue?',
  },
  [ContentId.Delisted]: {
    id: ContentId.Delisted,
    defaultValue: 'Delisted',
  },
  [ContentId.DelistingPending]: {
    id: ContentId.DelistingPending,
    defaultValue: 'Delisting...',
  },
  [ContentId.DelistingFailed]: {
    id: ContentId.DelistingFailed,
    defaultValue: 'Delist Failed',
  },
  [ContentId.DelistedClickToRelist]: {
    id: ContentId.DelistedClickToRelist,
    defaultValue: 'Delisted - click to relist',
  },
  [ContentId.DelistingClickToCancel]: {
    id: ContentId.DelistingClickToCancel,
    defaultValue: 'Delisting... - click to cancel',
  },
  [ContentId.DelistingFailedClickToTryAgain]: {
    id: ContentId.DelistingFailedClickToTryAgain,
    defaultValue: 'Delist Failed - click to try again',
  },
  [ContentId.DeliverTicket]: {
    id: ContentId.DeliverTicket,
    defaultValue: 'Deliver Ticket',
  },
  [ContentId.DeliverToAlternativeAccount]: {
    id: ContentId.DeliverToAlternativeAccount,
    defaultValue: 'Deliver to alternative account',
  },
  [ContentId.DebitCard]: {
    id: ContentId.DebitCard,
    defaultValue: 'Debit Card',
  },
  [ContentId.DebitNote]: {
    id: ContentId.DebitNote,
    defaultValue: 'Debit Note',
  },
  [ContentId.Delivery]: {
    id: ContentId.Delivery,
    defaultValue: 'Delivery',
  },
  [ContentId.DeliveryAccount]: {
    id: ContentId.DeliveryAccount,
    defaultValue: 'Delivery Account',
  },
  [ContentId.DeliveryCost]: {
    id: ContentId.DeliveryCost,
    defaultValue: 'Delivery Cost',
  },
  [ContentId.DeliveryWebsite]: {
    id: ContentId.DeliveryWebsite,
    defaultValue: 'Delivery Website',
  },
  [ContentId.DetailView]: {
    id: ContentId.DetailView,
    defaultValue: 'Detail View',
  },
  [ContentId.DirectOffline]: {
    id: ContentId.DirectOffline,
    defaultValue: 'Direct/Offline',
  },
  [ContentId.DisableThisMarketplace]: {
    id: ContentId.DisableThisMarketplace,
    defaultValue: 'Disable this marketplace',
  },
  [ContentId.Dismiss]: {
    id: ContentId.Dismiss,
    defaultValue: 'Dismiss',
  },
  [ContentId.Document]: {
    id: ContentId.Document,
    defaultValue: 'Document',
  },
  [ContentId.DoTheseTicketsHaveAnyRestrictions]: {
    id: ContentId.DoTheseTicketsHaveAnyRestrictions,
    defaultValue: 'Do these tickets have any restrictions on use?',
  },
  [ContentId.DoYouWorkForEventOrganizerOrViagogo]: {
    id: ContentId.DoYouWorkForEventOrganizerOrViagogo,
    defaultValue: 'Do you work for the Event Organizer or Viagogo?',
  },
  [ContentId.DragNAssignToSeats]: {
    id: ContentId.DragNAssignToSeats,
    defaultValue: 'Drag an e-Ticket to assign to seat',
  },
  [ContentId.DragNDropFiles]: {
    id: ContentId.DragNDropFiles,
    defaultValue: 'Drag & Drop file(s)',
  },
  [ContentId.Due]: {
    id: ContentId.Due,
    defaultValue: 'Due',
  },
  [ContentId.DuplicateBarcode]: {
    id: ContentId.DuplicateBarcode,
    defaultValue: 'Duplicate Barcode.',
  },
  [ContentId.DuplicateSeat]: {
    id: ContentId.DuplicateSeat,
    defaultValue: 'Duplicate seat.',
  },
  [ContentId.DuplicateUrl]: {
    id: ContentId.DuplicateUrl,
    defaultValue: 'Duplicate URL',
  },
  [ContentId.EachBarcodeOnNewLine]: {
    id: ContentId.EachBarcodeOnNewLine,
    defaultValue: 'Each barcode should be on a new line.',
  },
  [ContentId.ECheck]: {
    id: ContentId.ECheck,
    defaultValue: 'E-Check',
  },
  [ContentId.Economics]: {
    id: ContentId.Economics,
    defaultValue: 'Economics',
  },
  [ContentId.Edit]: {
    id: ContentId.Edit,
    defaultValue: 'Edit',
  },
  [ContentId.EditCostCredit]: {
    id: ContentId.EditCostCredit,
    defaultValue: 'Edit Cost/Credit',
  },
  [ContentId.EditOfflineSale]: {
    id: ContentId.EditOfflineSale,
    defaultValue: 'Edit Offline Sale',
  },
  [ContentId.EditPayment]: {
    id: ContentId.EditPayment,
    defaultValue: 'Edit Payment',
  },
  [ContentId.EditPaymentMethod]: {
    id: ContentId.EditPaymentMethod,
    defaultValue: 'Edit Purchase Payment Method',
  },
  [ContentId.EditPurchaseVendor]: {
    id: ContentId.EditPurchaseVendor,
    defaultValue: 'Edit Purchase Vendor',
  },
  [ContentId.EditPurchaseVendorAccount]: {
    id: ContentId.EditPurchaseVendorAccount,
    defaultValue: 'Edit Purchase Vendor Account',
  },
  [ContentId.EditCustomFilter]: {
    id: ContentId.EditCustomFilter,
    defaultValue: 'Edit custom filter',
  },
  [ContentId.EditPurchaseDetails]: {
    id: ContentId.EditPurchaseDetails,
    defaultValue: 'Edit Purchase Details',
  },
  [ContentId.EditTicketNotes]: {
    id: ContentId.EditTicketNotes,
    defaultValue: 'Provide additional information for your edits.',
  },
  [ContentId.Email]: {
    id: ContentId.Email,
    defaultValue: 'Email',
  },
  [ContentId.EmailDetails]: {
    id: ContentId.EmailDetails,
    defaultValue: 'Email Details',
  },
  [ContentId.Employee]: {
    id: ContentId.Employee,
    defaultValue: 'Employee',
  },
  [ContentId.EmployeeManagement]: {
    id: ContentId.EmployeeManagement,
    defaultValue: 'Employee Management',
  },
  [ContentId.EmptyContent]: {
    id: ContentId.EmptyContent,
    defaultValue: '',
  },
  [ContentId.EndDateBeforeStartDate]: {
    id: ContentId.EndDateBeforeStartDate,
    defaultValue: 'End date cannot be before start date',
  },
  [ContentId.EnterBarcodesIns]: {
    id: ContentId.EnterBarcodesIns,
    defaultValue:
      'Enter the barcodes associated with each seat. You can copy & paste an ordered list from excel in the bulk entry view to autopopulate the seats.',
  },
  [ContentId.EnterTransferIdForPurchase]: {
    id: ContentId.EnterTransferIdForPurchase,
    defaultValue: 'Enter the Transfer ID',
  },
  [ContentId.EnterPurchaseOrderIdFromVendor]: {
    id: ContentId.EnterPurchaseOrderIdFromVendor,
    defaultValue: 'Enter the purchase order ID',
  },
  [ContentId.Entity]: {
    id: ContentId.Entity,
    defaultValue: 'Entity',
  },
  [ContentId.Error_BadGatewayError_Header]: {
    id: ContentId.Error_BadGatewayError_Header,
    defaultValue: 'Internet Unavailable',
  },
  [ContentId.Error_BadGatewayError_GenericMessage]: {
    id: ContentId.Error_BadGatewayError_GenericMessage,
    defaultValue:
      'Internet Unavailable. Please check your connection and try again.',
  },
  [ContentId.Error_BadRequest_Header]: {
    id: ContentId.Error_BadRequest_Header,
    defaultValue: 'Bad Request',
  },
  [ContentId.Error_BadRequest_GenericMessage]: {
    id: ContentId.Error_BadRequest_GenericMessage,
    defaultValue: 'Some of your inputs are not valid. Please try again.',
  },
  [ContentId.Error_Forbidden_Header]: {
    id: ContentId.Error_Forbidden_Header,
    defaultValue: 'Forbidden',
  },
  [ContentId.Error_Forbidden_GenericMessage]: {
    id: ContentId.Error_Forbidden_GenericMessage,
    defaultValue:
      'You are not allowed to view this page or access this resource.',
  },
  [ContentId.Error_InternalServerError_Header]: {
    id: ContentId.Error_InternalServerError_Header,
    defaultValue: 'Unexpected Error',
  },
  [ContentId.Error_InternalServerError_GenericMessage]: {
    id: ContentId.Error_InternalServerError_GenericMessage,
    defaultValue:
      'An unexpected error occurred. Please try again or contact technical support if the problem perists.',
  },
  [ContentId.Error_NotImplemented_Header]: {
    id: ContentId.Error_NotImplemented_Header,
    defaultValue: 'Not Implemented',
  },
  [ContentId.Error_NotImplemented_GenericMessage]: {
    id: ContentId.Error_NotImplemented_GenericMessage,
    defaultValue:
      'This feature is not yet implemented. Please try again later.',
  },
  [ContentId.Error_Unauthorized_Header]: {
    id: ContentId.Error_Unauthorized_Header,
    defaultValue: 'Unauthorized',
  },
  [ContentId.Error_Unauthorized_GenericMessage]: {
    id: ContentId.Error_Unauthorized_GenericMessage,
    defaultValue:
      'You are not authorized to view this page or your login is expired. Please log-in again.',
  },
  [ContentId.Error_RequestTimeout_Header]: {
    id: ContentId.Error_RequestTimeout_Header,
    defaultValue: 'Request Timed Out',
  },
  [ContentId.Error_RequestTimeout_GenericMessage]: {
    id: ContentId.Error_RequestTimeout_GenericMessage,
    defaultValue:
      'Your action cannot be completed in the allowable length of time. Please try again later.',
  },
  [ContentId.Error_NotFound_Header]: {
    id: ContentId.Error_NotFound_Header,
    defaultValue: 'Action Not Found',
  },
  [ContentId.Error_NotFound_GenericMessage]: {
    id: ContentId.Error_NotFound_GenericMessage,
    defaultValue:
      'The action you are attempting does not exists. Please try again later.',
  },
  [ContentId.ETicket]: {
    id: ContentId.ETicket,
    defaultValue: 'E-Ticket',
  },
  [ContentId.ETicketUrl]: {
    id: ContentId.ETicketUrl,
    defaultValue: 'E-Ticket URL',
  },
  [ContentId.Event]: {
    id: ContentId.Event,
    defaultValue: 'Event',
  },
  [ContentId.EventDate]: {
    id: ContentId.EventDate,
    defaultValue: 'Event Date',
  },
  [ContentId.EventDateAsc]: {
    id: ContentId.EventDateAsc,
    defaultValue: 'Event Date Asc',
  },
  [ContentId.EventDateDesc]: {
    id: ContentId.EventDateDesc,
    defaultValue: 'Event Date Desc',
  },
  [ContentId.EventName]: {
    id: ContentId.EventName,
    defaultValue: 'Event Name',
  },
  [ContentId.EventTime]: {
    id: ContentId.EventTime,
    defaultValue: 'Event Time',
  },
  [ContentId.EventVisitors]: {
    id: ContentId.EventVisitors,
    defaultValue: 'Event Visitors',
  },
  [ContentId.ExpectedValue]: {
    id: ContentId.ExpectedValue,
    defaultValue: 'Expected Value',
  },
  [ContentId.Events]: {
    id: ContentId.Events,
    defaultValue: 'Events',
  },
  [ContentId.Everyone]: {
    id: ContentId.Everyone,
    defaultValue: 'Everyone',
  },
  [ContentId.ExchangeTickets]: {
    id: '',
    defaultValue: 'Exchange Tickets',
  },
  [ContentId.ExpectedValuePerTicket]: {
    id: ContentId.ExpectedValuePerTicket,
    defaultValue: 'Expected Value Per Ticket',
  },
  [ContentId.ExpireHold]: {
    id: ContentId.ExpireHold,
    defaultValue: 'Expire Hold',
  },
  [ContentId.ExpireHoldWarning]: {
    id: ContentId.ExpireHoldWarning,
    defaultValue: 'You are about to expire this hold. Continue?',
  },
  [ContentId.Export]: {
    id: ContentId.Export,
    defaultValue: 'Export',
  },
  [ContentId.FaceValue]: {
    id: ContentId.FaceValue,
    defaultValue: 'Face Value',
  },
  [ContentId.Failed]: {
    id: ContentId.Failed,
    defaultValue: 'Failed',
  },
  [ContentId.FailedAndRequiresSellerAction]: {
    id: ContentId.FailedAndRequiresSellerAction,
    defaultValue: 'Failed and requires user action',
  },
  [ContentId.FailedWithNonRetryableError]: {
    id: ContentId.FailedWithNonRetryableError,
    defaultValue: 'Failed',
  },
  [ContentId.FailedWithRetryableError]: {
    id: ContentId.FailedWithRetryableError,
    defaultValue: 'Failed and retried',
  },
  [ContentId.FailToLoadListContent]: {
    id: ContentId.FailToLoadListContent,
    defaultValue:
      'An unexpected occurred trying to load this list. Please reload the page or contact us if the problem persists.',
  },
  [ContentId.FailedToStartupTheApp]: {
    id: ContentId.FailedToStartupTheApp,
    defaultValue:
      'The app failed to start due to an unexpected error. Please reload the page or contact us if the problem persists.',
  },
  [ContentId.Failures]: {
    id: ContentId.Failures,
    defaultValue: 'Failures',
  },
  [ContentId.FarthestEvent]: {
    id: ContentId.FarthestEvent,
    defaultValue: 'Farthest Event',
  },
  [ContentId.Filters]: {
    id: ContentId.Filters,
    defaultValue: 'Filters',
  },
  [ContentId.FinalQuestionsOnYourTickets]: {
    id: ContentId.FinalQuestionsOnYourTickets,
    defaultValue: 'Final Questions on Your Tickets',
  },
  [ContentId.Finance]: {
    id: ContentId.Finance,
    defaultValue: 'Finance',
  },
  [ContentId.Finish]: {
    id: ContentId.Finish,
    defaultValue: 'Finish',
  },
  [ContentId.FloorRelativeToUnitCost]: {
    id: ContentId.FloorRelativeToUnitCost,
    defaultValue: 'Floor Relative to Unit Cost',
  },
  [ContentId.Format]: {
    id: ContentId.Format,
    defaultValue: 'Format',
  },
  [ContentId.FormulaBuilder]: {
    id: ContentId.FormulaBuilder,
    defaultValue: 'Formula Builder',
  },
  [ContentId.FormulaBuilderInfoLine1]: {
    id: ContentId.FormulaBuilderInfoLine1,
    defaultValue: '@ Opens variables',
  },
  [ContentId.FormulaBuilderInfoLine2]: {
    id: ContentId.FormulaBuilderInfoLine2,
    defaultValue: '/ Divide',
  },
  [ContentId.FormulaBuilderInfoLine3]: {
    id: ContentId.FormulaBuilderInfoLine3,
    defaultValue: '- Subtract',
  },
  [ContentId.FormulaBuilderInfoLine4]: {
    id: ContentId.FormulaBuilderInfoLine4,
    defaultValue: '+ Add',
  },
  [ContentId.FormulaBuilderInfoLine5]: {
    id: ContentId.FormulaBuilderInfoLine5,
    defaultValue: '* Multiply',
  },
  [ContentId.FormulaBuilderInfoLine6]: {
    id: ContentId.FormulaBuilderInfoLine6,
    defaultValue: '() Brackets',
  },
  [ContentId.ForTheEvent]: {
    id: ContentId.ForTheEvent,
    defaultValue: 'for the Event',
  },
  [ContentId.FreeEvent]: {
    id: ContentId.FreeEvent,
    defaultValue: 'Free Event',
  },
  [ContentId.From]: {
    id: ContentId.From,
    defaultValue: 'From',
  },
  [ContentId.FromDate]: {
    id: ContentId.FromDate,
    defaultValue: 'From Date',
  },
  [ContentId.Fulfill]: {
    id: ContentId.Fulfill,
    defaultValue: 'Fulfill',
  },
  [ContentId.FulfilledBy]: {
    id: ContentId.FulfilledBy,
    defaultValue: 'Fulfilled By',
  },
  [ContentId.Fulfiller]: {
    id: ContentId.Fulfiller,
    defaultValue: 'Fulfiller',
  },
  [ContentId.Fulfillment]: {
    id: ContentId.Fulfillment,
    defaultValue: 'Fulfillment',
  },
  [ContentId.FulfillActionNotAvailable]: {
    id: ContentId.FulfillActionNotAvailable,
    defaultValue:
      'Fulfillment action for this type of sale is not yet supported.',
  },
  [ContentId.Fulfilled]: {
    id: ContentId.Fulfilled,
    defaultValue: PointOfSaleSaleStatus.Fulfilled,
  },
  [ContentId.FulfilledExternalMarketplace]: {
    id: ContentId.FulfilledExternalMarketplace,
    defaultValue: 'Fulfilled in external marketplace',
  },
  [ContentId.FulfillmentDate]: {
    id: ContentId.FulfillmentDate,
    defaultValue: 'Fulfillment Date',
  },
  [ContentId.FulfillmentFailed]: {
    id: ContentId.FulfillmentFailed,
    defaultValue: PointOfSaleSaleStatus.FulfillmentFailed,
  },
  [ContentId.FulfillmentRestrictions]: {
    id: ContentId.FulfillmentRestrictions,
    defaultValue: 'Fulfillment Restrictions',
  },
  [ContentId.FulfillmentState]: {
    id: ContentId.FulfillmentState,
    defaultValue: 'Fulfillment State',
  },
  [ContentId.FullEventInfo]: {
    id: ContentId.FullEventInfo,
    defaultValue: 'Full Event Info',
  },
  [ContentId.HideAdvancedSettings]: {
    id: ContentId.HideAdvancedSettings,
    defaultValue: 'Hide Advanced Settings',
  },
  [ContentId.History]: {
    id: ContentId.History,
    defaultValue: 'History',
  },
  [ContentId.IgnoreTheFirst]: {
    id: ContentId.IgnoreTheFirst,
    defaultValue: 'Ignore the first',
  },
  [ContentId.IncorrectPerfomerOrGrouping]: {
    id: ContentId.IncorrectPerfomerOrGrouping,
    defaultValue: 'Incorrect perfomer or grouping',
  },
  [ContentId.InsightsView]: {
    id: ContentId.InsightsView,
    defaultValue: 'Insights View',
  },
  [ContentId.IncreaseDecreasePrice]: {
    id: ContentId.IncreaseDecreasePrice,
    defaultValue: 'Increase/Decrease Price',
  },
  [ContentId.InCheckout]: {
    id: ContentId.InCheckout,
    defaultValue: 'In Checkout',
  },
  [ContentId.InvalidBaseRow]: {
    id: ContentId.InvalidBaseRow,
    defaultValue: 'Invalid base row',
  },
  [ContentId.IsDuplicate]: {
    id: ContentId.IsDuplicate,
    defaultValue: 'Is Duplicate',
  },
  [ContentId.IsPricedByMarketplace]: {
    id: ContentId.IsPricedByMarketplace,
    defaultValue: 'Is Priced By Marketplace',
  },
  [ContentId.ListedAndSold]: {
    id: ContentId.ListedAndSold,
    defaultValue: 'Listed & Sold',
  },
  [ContentId.KeepCurrentPurchaser]: {
    id: ContentId.KeepCurrentPurchaser,
    defaultValue: 'Keep Current',
  },
  [ContentId.ListView]: {
    id: ContentId.ListView,
    defaultValue: 'List View',
  },
  [ContentId.MembersOnThisTeam]: {
    id: ContentId.MembersOnThisTeam,
    defaultValue: 'Members on this team',
  },
  [ContentId.MergeListings_ListingMismatchWarning]: {
    id: ContentId.MergeListings_ListingMismatchWarning,
    defaultValue:
      'The selected listings have different sections/rows. Merging will assign the same section/row to all seats.',
  },
  [ContentId.MinQty]: {
    id: ContentId.MinQty,
    defaultValue: 'Min Qty',
  },
  [ContentId.MinQuantity]: {
    id: ContentId.MinQuantity,
    defaultValue: 'Minimum Quantity',
  },
  [ContentId.MissingHeightData]: {
    id: ContentId.MissingHeightData,
    defaultValue:
      'Seat map is missing height data. Adjusting the curve will have no effect.',
  },
  [ContentId.MissingDistanceData]: {
    id: ContentId.MissingDistanceData,
    defaultValue:
      'Seat map is missing distance data. Adjusting the curve will have no effect.',
  },
  [ContentId.MissingAngleData]: {
    id: ContentId.MissingAngleData,
    defaultValue:
      'Seat map is missing angle data. Adjusting the curve will have no effect.',
  },
  [ContentId.MoreThan]: {
    id: ContentId.MoreThan,
    defaultValue: 'more than',
  },
  [ContentId.NewAppVersion]: {
    id: ContentId.NewAppVersion,
    defaultValue: 'A new and improved version of the application is available.',
  },
  [ContentId.NewHeatMap]: {
    id: ContentId.NewHeatMap,
    defaultValue:
      'A new recommended heatmap is available. Select it below to set it as your active heatmap.',
  },
  [ContentId.NotEnoughInformationToCreate]: {
    id: ContentId.NotEnoughInformationToCreate,
    defaultValue: 'Not enough information to create',
  },
  [ContentId.NotFoundOnPrimary]: {
    id: ContentId.NotFoundOnPrimary,
    defaultValue: 'Not found on primary',
  },
  [ContentId.NotMappedYet]: {
    id: ContentId.NotMappedYet,
    defaultValue: 'Not Mapped Yet',
  },
  [ContentId.NumberOfBuckets]: {
    id: ContentId.NumberOfBuckets,
    defaultValue: 'Number of Buckets',
  },

  [ContentId.OfMyListingQuality]: {
    id: ContentId.OfMyListingQuality,
    defaultValue: "of my listing's quality",
  },
  [ContentId.OnHoldBroadcastDisabled]: {
    id: ContentId.OnHoldBroadcastDisabled,
    defaultValue: 'On Hold (Broadcast Disabled)',
  },
  [ContentId.Override]: {
    id: ContentId.Override,
    defaultValue: 'Override',
  },
  [ContentId.OverrideDate]: {
    id: ContentId.OverrideDate,
    defaultValue: 'Override Date',
  },
  [ContentId.PandL]: {
    id: ContentId.PandL,
    defaultValue: 'P&L',
  },
  [ContentId.PaymentAmountReceived]: {
    id: ContentId.PaymentAmountReceived,
    defaultValue: 'Payment Amount Received',
  },
  [ContentId.PaymentCurrency]: {
    id: ContentId.PaymentCurrency,
    defaultValue: 'Payment Currency',
  },
  [ContentId.PaymentReceived]: {
    id: ContentId.PaymentReceived,
    defaultValue: 'Payment Received',
  },
  [ContentId.PaymentReference]: {
    id: ContentId.PaymentReference,
    defaultValue: 'Payment Reference',
  },
  [ContentId.PendingPartnerIntegrations]: {
    id: ContentId.PendingPartnerIntegrations,
    defaultValue: 'Pending Partner Integrations',
  },
  [ContentId.IncorrectCountryCode]: {
    id: ContentId.IncorrectCountryCode,
    defaultValue: 'Incorrect Country Code',
  },
  [ContentId.Members]: {
    id: ContentId.Members,
    defaultValue: 'Members',
  },
  [ContentId.Permission_UpdateBuyerCommission]: {
    id: ContentId.Permission_UpdateBuyerCommission,
    defaultValue: 'Update buyer commission',
  },
  [ContentId.Permission_UpdateBuyerCommissionOwned]: {
    id: ContentId.Permission_UpdateBuyerCommissionOwned,
    defaultValue: 'Update buyer commission on created purchase',
  },
  [ContentId.Permission_ConfirmRejectOnAssignedSales]: {
    id: ContentId.Permission_ConfirmRejectOnAssignedSales,
    defaultValue: 'Confirm/reject assigned sales (Fulfiller)',
  },
  [ContentId.Permission_ConfirmRejectSale]: {
    id: ContentId.Permission_ConfirmRejectSale,
    defaultValue: 'Confirm/reject sale',
  },
  [ContentId.Permission_ConfirmRejectSaleOnPricedListings]: {
    id: ContentId.Permission_ConfirmRejectSaleOnPricedListings,
    defaultValue: 'Confirm/reject sale from priced listings (Pricer)',
  },
  [ContentId.Permission_ConfirmRejectSalesFromPurchasedListings]: {
    id: ContentId.Permission_ConfirmRejectSalesFromPurchasedListings,
    defaultValue: 'Confirm/reject sales from purchased listings (Purchaser)',
  },
  [ContentId.Permission_CreatePO]: {
    id: ContentId.Permission_CreatePO,
    defaultValue: 'Create PO',
  },
  [ContentId.Permission_FulfillSale]: {
    id: ContentId.Permission_FulfillSale,
    defaultValue: 'Fulfill sale',
  },
  [ContentId.Permission_FulfillSaleOnPricedListings]: {
    id: ContentId.Permission_FulfillSaleOnPricedListings,
    defaultValue: 'Fulfill sale from priced listings (Pricer)',
  },
  [ContentId.Permission_FulfillSalesFromPurchasedListings]: {
    id: ContentId.Permission_FulfillSalesFromPurchasedListings,
    defaultValue: 'Fulfill sale from purchased listings (Purchaser)',
  },
  [ContentId.Permission_ManageCreatedPlaceholderListings]: {
    id: ContentId.Permission_ManageCreatedPlaceholderListings,
    defaultValue: 'Manage created placeholder listings',
  },
  [ContentId.Permission_ManageOfflineSalesOnPurchasedListings]: {
    id: ContentId.Permission_ManageOfflineSalesOnPurchasedListings,
    defaultValue: 'Manage offline sales from purchased listings (Purchaser)',
  },
  [ContentId.Permission_ManagePlaceholderListings]: {
    id: ContentId.Permission_ManagePlaceholderListings,
    defaultValue: 'Manage placeholder listings',
  },
  [ContentId.Permission_Predeliver]: {
    id: ContentId.Permission_Predeliver,
    defaultValue: 'Predeliver',
  },
  [ContentId.Permission_PredeliverOnAssignedListings]: {
    id: ContentId.Permission_PredeliverOnAssignedListings,
    defaultValue: 'Predeliver assigned listings (Pricer)',
  },
  [ContentId.Permission_BroadcastUnbroadcastOnAssignedListings]: {
    id: ContentId.Permission_BroadcastUnbroadcastOnAssignedListings,
    defaultValue: 'Broadcast/unbroadcast assigned listings (Pricer)',
  },
  [ContentId.Permission_PredeliverPurchasedListings]: {
    id: ContentId.Permission_PredeliverPurchasedListings,
    defaultValue: 'Predeliver purchased listings (Purchaser)',
  },
  [ContentId.Permission_SetPriceOnAssignedListings]: {
    id: ContentId.Permission_SetPriceOnAssignedListings,
    defaultValue: 'Set price on assigned listings (Pricer)',
  },
  [ContentId.Permission_UpdateCreatedReports]: {
    id: ContentId.Permission_UpdateCreatedReports,
    defaultValue: 'Update created reports',
  },
  [ContentId.Permission_UpdateInfoFromPurchasedListings]: {
    id: ContentId.Permission_UpdateInfoFromPurchasedListings,
    defaultValue: 'Update info on sale from purchased listings (Purchaser)',
  },
  [ContentId.Permission_UpdateInfoOnAssignedListings]: {
    id: ContentId.Permission_UpdateInfoOnAssignedListings,
    defaultValue: 'Update info on assigned listings (Pricer)',
  },
  [ContentId.Permission_UpdateInfoOnAssignedSales]: {
    id: ContentId.Permission_UpdateInfoOnAssignedSales,
    defaultValue: 'Update info on assigned sales (Fulfiller)',
  },
  [ContentId.Permission_UpdateInfoOnPricedListings]: {
    id: ContentId.Permission_UpdateInfoOnPricedListings,
    defaultValue: 'Update info on sale from priced listings (Pricer)',
  },
  [ContentId.Permission_UpdateInfoOnPurchasedListings]: {
    id: ContentId.Permission_UpdateInfoOnPurchasedListings,
    defaultValue: 'Update info on purchased listings (Purchaser)',
  },
  [ContentId.Permission_UpdateTagsFromPricedListings]: {
    id: ContentId.Permission_UpdateTagsFromPricedListings,
    defaultValue: 'Update tags on sale from priced listings (Pricer)',
  },
  [ContentId.Permission_UpdateTagsFromPurchasedListings]: {
    id: ContentId.Permission_UpdateTagsFromPurchasedListings,
    defaultValue: 'Update tags on sale from purchased listings (Purchaser)',
  },
  [ContentId.Permission_UpdateTagsOnAssignedListings]: {
    id: ContentId.Permission_UpdateTagsOnAssignedListings,
    defaultValue: 'Update tags on assigned listings (Pricer)',
  },
  [ContentId.Permission_SetPriceOnPurchasedListings]: {
    id: ContentId.Permission_SetPriceOnPurchasedListings,
    defaultValue: 'Set price on purchased listings (Purchaser)',
  },
  [ContentId.Permission_UpdateTagsOnAssignedSales]: {
    id: ContentId.Permission_UpdateTagsOnAssignedSales,
    defaultValue: 'Update tags on assigned sales (Fulfiller)',
  },
  [ContentId.Permission_UpdateTagsOnOwnedPurchasedOrder]: {
    id: ContentId.Permission_UpdateTagsOnOwnedPurchasedOrder,
    defaultValue: 'Update tags on created purchase',
  },
  [ContentId.Permission_UpdateTagsOnPurchasedListings]: {
    id: ContentId.Permission_UpdateTagsOnPurchasedListings,
    defaultValue: 'Update tags on purchased listings (Purchaser)',
  },
  [ContentId.Permission_FulfillSalesOnAssignedSales]: {
    id: ContentId.Permission_FulfillSalesOnAssignedSales,
    defaultValue: 'Fulfill assigned sales (Fulfiller)',
  },
  [ContentId.Permission_ViewAssignedListings]: {
    id: ContentId.Permission_ViewAssignedListings,
    defaultValue: 'View priced listings (Pricer)',
  },
  [ContentId.Permission_ViewAssignedSales]: {
    id: ContentId.Permission_ViewAssignedSales,
    defaultValue: 'View assigned sales (Fulfiller)',
  },
  [ContentId.Permission_ViewFulfillmentArtifactsOnAssignedListings]: {
    id: ContentId.Permission_ViewFulfillmentArtifactsOnAssignedListings,
    defaultValue: 'View fulfillment artifacts on assigned listings (Pricer)',
  },
  [ContentId.Permission_ViewFulfillmentArtifactsOnAssignedSales]: {
    id: ContentId.Permission_ViewFulfillmentArtifactsOnAssignedSales,
    defaultValue: 'View fulfillment artifacts on assigned sales (Fulfiller)',
  },
  [ContentId.Permission_ViewFulfillmentArtifactsOnPricedListings]: {
    id: ContentId.Permission_ViewFulfillmentArtifactsOnPricedListings,
    defaultValue: 'View fulfillment artifacts on priced listings (Pricer)',
  },
  [ContentId.Permission_ViewFulfillmentArtifactsOnPurchasedListings]: {
    id: ContentId.Permission_ViewFulfillmentArtifactsOnPurchasedListings,
    defaultValue:
      'View fulfillment artifacts on purchased listings (Purchaser)',
  },
  [ContentId.Permission_ViewFulfillmentArtifactsOnSaleFromPricedListings]: {
    id: ContentId.Permission_ViewFulfillmentArtifactsOnSaleFromPricedListings,
    defaultValue:
      'View fulfillment artifacts on sale from priced listings (Pricer)',
  },
  [ContentId.Permission_ViewFulfillmentArtifactsOnSaleFromPurchasedListings]: {
    id: ContentId.Permission_ViewFulfillmentArtifactsOnSaleFromPurchasedListings,
    defaultValue:
      'View fulfillment artifacts on sale from purchased listings (Purchaser)',
  },
  [ContentId.Permission_ViewGlobalReports]: {
    id: ContentId.Permission_ViewGlobalReports,
    defaultValue: 'View Global Reports',
  },
  [ContentId.Permission_ViewOwnedPurchasedOrders]: {
    id: ContentId.Permission_ViewOwnedPurchasedOrders,
    defaultValue: 'View owned purchased orders',
  },
  [ContentId.Permission_ViewSaleFromPricedListings]: {
    id: ContentId.Permission_ViewSaleFromPricedListings,
    defaultValue: 'View sale from priced listings (Pricer)',
  },
  [ContentId.Permission_ViewPurchasedListings]: {
    id: ContentId.Permission_ViewPurchasedListings,
    defaultValue: 'View purchased listings (Purchaser)',
  },
  [ContentId.Permission_ViewRecentListings]: {
    id: ContentId.Permission_ViewRecentListings,
    defaultValue: 'View recent listings',
  },
  [ContentId.Permission_ViewRecentSales]: {
    id: ContentId.Permission_ViewRecentSales,
    defaultValue: 'View recent sales',
  },
  [ContentId.Permission_ViewSaleOnPurchasedListings]: {
    id: ContentId.Permission_ViewSaleOnPurchasedListings,
    defaultValue: 'View sale from purchased listings (Purchaser)',
  },
  [ContentId.Permission_Webhook_View]: {
    id: ContentId.Permission_Webhook_View,
    defaultValue: 'View Webhook',
  },
  [ContentId.Permission_Webhook_Create]: {
    id: ContentId.Permission_Webhook_Create,
    defaultValue: 'Create Webhook',
  },
  [ContentId.Permission_Webhook_Edit]: {
    id: ContentId.Permission_Webhook_Edit,
    defaultValue: 'Edit Webhook',
  },
  [ContentId.Placeholder]: {
    id: ContentId.Placeholder,
    defaultValue: 'Placeholder',
  },
  [ContentId.PlaceholderPurchaseMessage]: {
    id: ContentId.PlaceholderPurchaseMessage,
    defaultValue:
      'Placeholder inventory have no purchases until a purchase is automatically or manually allocated.',
  },
  [ContentId.POCreatedBy]: {
    id: ContentId.POCreatedBy,
    defaultValue: 'PO Created By',
  },
  [ContentId.Priced]: {
    id: ContentId.Priced,
    defaultValue: 'Priced',
  },
  [ContentId.ProvisionallySourced]: {
    id: ContentId.ProvisionallySourced,
    defaultValue: 'Provisionally Sourced',
  },
  [ContentId.Purchases_UpdateBuyerCommissionOwned_Details]: {
    id: ContentId.Purchases_UpdateBuyerCommissionOwned_Details,
    defaultValue: 'Add/Edit commission for purchases owned by user',
  },
  [ContentId.Purchases_UpdateBuyerCommission_Details]: {
    id: ContentId.Purchases_UpdateBuyerCommission_Details,
    defaultValue: 'Add/Edit commission for purchases',
  },
  [ContentId.PutOnHold]: {
    id: ContentId.PutOnHold,
    defaultValue: 'Put on Hold',
  },
  [ContentId.PutOnHoldAll]: {
    id: ContentId.PutOnHoldAll,
    defaultValue: 'Put on Hold for All',
  },
  [ContentId.PutOnHoldSelected]: {
    id: ContentId.PutOnHoldSelected,
    defaultValue: 'Put on Hold for Selected',
  },
  [ContentId.RankPremium]: {
    id: ContentId.RankPremium,
    defaultValue: 'Rank Premium',
  },
  [ContentId.Reason]: {
    id: ContentId.Reason,
    defaultValue: 'Reason',
  },
  [ContentId.RefreshNow]: {
    id: ContentId.RefreshNow,
    defaultValue: 'Refresh Now',
  },
  [ContentId.RejectAllocation]: {
    id: ContentId.RejectAllocation,
    defaultValue: 'Reject Allocation',
  },
  [ContentId.RejectAllocationWarning]: {
    id: ContentId.RejectAllocationWarning,
    defaultValue:
      'You are about to reject the tickets allocated for this sale. Continue?',
  },
  [ContentId.RejectionReasonInfoText1]: {
    id: ContentId.RejectionReasonInfoText1,
    defaultValue: 'Your event was rejected due to the following reason: ',
  },
  [ContentId.RejectionReasonInfoText2]: {
    id: ContentId.RejectionReasonInfoText2,
    defaultValue:
      'If you believe this decision was an error, please contact an account manager for further assistance.',
  },
  [ContentId.RelativeRankPremium]: {
    id: ContentId.RelativeRankPremium,
    defaultValue: 'Relative Rank Premium',
  },
  [ContentId.RelativeTo]: {
    id: ContentId.RelativeTo,
    defaultValue: 'Relative to',
  },
  [ContentId.RemovePredelivery]: {
    id: ContentId.RemovePredelivery,
    defaultValue: 'Remove Predelivery',
  },
  [ContentId.RemoveTagValue]: {
    id: ContentId.RemoveTagValue,
    defaultValue: 'Remove Tag Value',
  },
  [ContentId.ReportHistory]: {
    id: ContentId.ReportHistory,
    defaultValue: 'Report History',
  },
  [ContentId.Reports_ViewGlobalReports_Details]: {
    id: ContentId.Reports_ViewGlobalReports_Details,
    defaultValue: 'View global reports, across all modules',
  },
  [ContentId.RequestState]: {
    id: ContentId.RequestState,
    defaultValue: 'Request State',
  },
  [ContentId.SameOverrideExists]: {
    id: ContentId.SameOverrideExists,
    defaultValue: 'Same Override with the same settings already exists',
  },
  [ContentId.SameSection]: {
    id: ContentId.SameSection,
    defaultValue: 'Same Section',
  },
  [ContentId.SaveFilter]: {
    id: ContentId.SaveFilter,
    defaultValue: 'Save Filter',
  },
  [ContentId.SaveSettingsToViewUpdatedComps]: {
    id: ContentId.SaveSettingsToViewUpdatedComps,
    defaultValue: 'Save settings to view refreshed related listings',
  },
  [ContentId.SearchAndReplace]: {
    id: ContentId.SearchAndReplace,
    defaultValue: 'Search and Replace',
  },
  [ContentId.SearchEvent]: {
    id: ContentId.SearchEvent,
    defaultValue: 'Search Event',
  },
  [ContentId.SearchFor]: {
    id: ContentId.SearchFor,
    defaultValue: 'Search for',
  },
  [ContentId.SeatingMapped]: {
    id: ContentId.SeatingMapped,
    defaultValue: 'Seating Mapped',
  },
  [ContentId.SectionClearingPrices]: {
    id: ContentId.SectionClearingPrices,
    defaultValue: 'Section Clearing Prices',
  },
  [ContentId.SeeAdvancedSettings]: {
    id: ContentId.SeeAdvancedSettings,
    defaultValue: 'See Advanced Settings',
  },
  [ContentId.SelectFilterValue]: {
    id: ContentId.SelectFilterValue,
    defaultValue:
      'Filter has default value and will not save nor affect the result',
  },
  [ContentId.SelectItemsForBulkEdit]: {
    id: ContentId.SelectItemsForBulkEdit,
    defaultValue: 'Select items for bulk edit',
  },
  [ContentId.SelectTag]: {
    id: ContentId.SelectTag,
    defaultValue: 'Select Tag',
  },
  [ContentId.SellerError]: {
    id: ContentId.SellerError,
    defaultValue: 'Seller Error',
  },
  [ContentId.SellerRelationship]: {
    id: ContentId.SellerRelationship,
    defaultValue: 'Seller Relationship',
  },
  [ContentId.SetActiveInGroup]: {
    id: ContentId.SetActiveInGroup,
    defaultValue: 'Set active in group',
  },
  [ContentId.SetTicketTypePriority]: {
    id: ContentId.SetTicketTypePriority,
    defaultValue: 'Set Ticket Type Priority',
  },
  [ContentId.ShippingRefund]: {
    id: ContentId.ShippingRefund,
    defaultValue: 'Shipping Refund',
  },
  [ContentId.ShowDetailedSettings]: {
    id: ContentId.ShowDetailedSettings,
    defaultValue: 'Show Detailed Settings',
  },
  [ContentId.ShownQty]: {
    id: ContentId.ShownQty,
    defaultValue: 'Shown Qty',
  },
  [ContentId.ShuffleRanks]: {
    id: ContentId.ShuffleRanks,
    defaultValue: 'Shuffle Ranks',
  },
  [ContentId.SignUpBannerTitle]: {
    id: ContentId.SignUpBannerTitle,
    defaultValue: 'Reach Pro is invite only for now.',
  },
  [ContentId.SignUpBannerSubTitle]: {
    id: ContentId.SignUpBannerSubTitle,
    defaultValue: 'Comeback for more details at a later date',
  },
  [ContentId.SignUpWelcomeSubtitle]: {
    id: ContentId.SignUpWelcomeSubtitle,
    defaultValue: 'Welcome to the future of tickets',
  },
  [ContentId.SimulateWebsiteDiscount]: {
    id: ContentId.SimulateWebsiteDiscount,
    defaultValue: 'Simulate Website Discounts',
  },
  [ContentId.Skipped]: {
    id: ContentId.Skipped,
    defaultValue: 'Skipped',
  },
  [ContentId.Spacing]: {
    id: ContentId.Spacing,
    defaultValue: 'Spacing',
  },
  [ContentId.SportsOrConcertsWith360]: {
    id: ContentId.SportsOrConcertsWith360,
    defaultValue: 'Sports or Concerts with 360',
  },
  [ContentId.StubHubListings]: {
    id: ContentId.StubHubListings,
    defaultValue: 'StubHub Listings',
  },
  [ContentId.StubhubRecommended]: {
    id: ContentId.StubhubRecommended,
    defaultValue: 'StubHub Recommended',
  },
  [ContentId.Submit]: {
    id: ContentId.Submit,
    defaultValue: 'Submit',
  },
  [ContentId.SyncFailedClickToRelist]: {
    id: ContentId.SyncFailedClickToRelist,
    defaultValue: 'Sync failed - click to relist',
  },
  [ContentId.SyncFailedClickToDelist]: {
    id: ContentId.SyncFailedClickToDelist,
    defaultValue: 'Sync failed - click to delist',
  },
  [ContentId.TagNameNotAllowed]: {
    id: ContentId.TagNameNotAllowed,
    defaultValue: 'Tag name not allowed.',
  },
  [ContentId.TargetPercentage]: {
    id: ContentId.TargetPercentage,
    defaultValue: 'Target Percentage',
  },
  [ContentId.TaxRefund]: {
    id: ContentId.TaxRefund,
    defaultValue: 'Tax Refund',
  },
  [ContentId.Team]: {
    id: ContentId.Team,
    defaultValue: 'Team',
  },
  [ContentId.TeamName]: {
    id: ContentId.TeamName,
    defaultValue: 'Team name',
  },
  [ContentId.Teams]: {
    id: ContentId.Teams,
    defaultValue: 'Teams',
  },
  [ContentId.ProvisionalAllocation]: {
    id: ContentId.ProvisionalAllocation,
    defaultValue: 'Provisional Allocation',
  },
  [ContentId.ThenBy]: {
    id: ContentId.ThenBy,
    defaultValue: 'Then By',
  },
  [ContentId.TheNextCheapest]: {
    id: ContentId.TheNextCheapest,
    defaultValue: 'the next cheapest',
  },
  [ContentId.TicketId]: {
    id: ContentId.TicketId,
    defaultValue: 'Ticket ID',
  },
  [ContentId.TicketGroupId]: {
    id: ContentId.TicketGroupId,
    defaultValue: 'Ticket Group ID',
  },
  [ContentId.TimeView]: {
    id: ContentId.TimeView,
    defaultValue: 'Time View',
  },
  [ContentId.Timezone]: {
    id: ContentId.Timezone,
    defaultValue: 'Timezone',
  },
  [ContentId.TimezoneDefaultOption]: {
    id: ContentId.TimezoneDefaultOption,
    defaultValue: 'Automatic (current location)',
  },
  [ContentId.TimezoneDescription]: {
    id: ContentId.TimezoneDescription,
    defaultValue:
      "Adjusting the timezone settings will automatically update the dates and times associated with purchase, listing and sale dates in the POS. Event dates will remain relative to the venue's location.",
  },
  [ContentId.TooManyResultPerformanceWarning]: {
    id: ContentId.TooManyResultPerformanceWarning,
    defaultValue:
      'This filter option can return a lot of items and cause performance degradation.',
  },
  [ContentId.TotalCostOfUnsoldTickets]: {
    id: ContentId.TotalCostOfUnsoldTickets,
    defaultValue: 'Total cost of unsold tickets.',
  },
  [ContentId.UndoCancel]: {
    id: ContentId.UndoCancel,
    defaultValue: 'Undo Cancel',
  },
  [ContentId.UngroupListings]: {
    id: ContentId.UngroupListings,
    defaultValue: 'Ungroup Listings',
  },
  [ContentId.Unsold]: {
    id: ContentId.Unsold,
    defaultValue: 'Unsold',
  },
  [ContentId.UnsoldEvents]: {
    id: ContentId.UnsoldEvents,
    defaultValue: 'Unsold Events',
  },
  [ContentId.UnsoldProceeds]: {
    id: ContentId.UnsoldProceeds,
    defaultValue: 'Unsold Proceeds',
  },
  [ContentId.Update]: {
    id: ContentId.Update,
    defaultValue: 'Update',
  },
  [ContentId.UpdateAutoFulfill]: {
    id: ContentId.UpdateAutoFulfill,
    defaultValue: 'Update AutoFulfill',
  },
  [ContentId.UpdateBuyerCommission]: {
    id: ContentId.UpdateBuyerCommission,
    defaultValue: 'Update Buyer Commission',
  },
  [ContentId.UpdateEventIdWarning]: {
    id: ContentId.UpdateEventIdWarning,
    defaultValue:
      'Updating the Event ID may affect IDs across multiple marketplaces.',
  },
  [ContentId.UpdateMarketplacePrices]: {
    id: ContentId.UpdateMarketplacePrices,
    defaultValue: 'Update Marketplace Prices',
  },
  [ContentId.UpdateMarketplaceSeating]: {
    id: ContentId.UpdateMarketplaceSeating,
    defaultValue: 'Update Marketplace Seating',
  },
  [ContentId.UpdateMode]: {
    id: ContentId.UpdateMode,
    defaultValue: 'Update Mode',
  },
  [ContentId.UpdatePriceEvery]: {
    id: ContentId.UpdatePriceEvery,
    defaultValue: 'Update price every',
  },
  [ContentId.UpdatePriceFloorCeiling]: {
    id: ContentId.UpdatePriceFloorCeiling,
    defaultValue: 'Update Price Floor/Ceiling',
  },
  [ContentId.UpdatePrivateNotes]: {
    id: ContentId.UpdatePrivateNotes,
    defaultValue: 'Update Private Notes',
  },
  [ContentId.UpdatePurchaseWithMergedListingWarning]: {
    id: ContentId.UpdatePurchaseWithMergedListingWarning,
    defaultValue:
      'This purchase contains ticket groups that have been merged. This update may result in the listings being split.',
  },
  [ContentId.UpdateTagValue]: {
    id: ContentId.UpdateTagValue,
    defaultValue: 'Update Tag Value',
  },
  [ContentId.UpdateTagValues]: {
    id: ContentId.UpdateTagValues,
    defaultValue: 'Update Tag Values',
  },
  [ContentId.UpdateTagValuesSelected]: {
    id: ContentId.UpdateTagValuesSelected,
    defaultValue: 'Update Tag Values for Selected',
  },
  [ContentId.UpdateTagValueWarning]: {
    id: ContentId.UpdateTagValueWarning,
    defaultValue: 'If no existing value is found, a new value will be added.',
  },
  [ContentId.UploadPredelivery]: {
    id: ContentId.UploadPredelivery,
    defaultValue: 'Upload Predelivery',
  },
  [ContentId.UploadSeatScoreModelInfo]: {
    id: ContentId.UploadSeatScoreModelInfo,
    defaultValue:
      'New model created from CSV upload will use the value in the Name field from the Seat Heapmap config form as the model name.',
  },
  [ContentId.UseAsTemplate]: {
    id: ContentId.UseAsTemplate,
    defaultValue: 'Use as template',
  },
  [ContentId.Velocity]: {
    id: ContentId.Velocity,
    defaultValue: 'Velocity',
  },
  [ContentId.VelocityHours]: {
    id: ContentId.VelocityHours,
    defaultValue: 'Velocity Hours',
  },
  [ContentId.VenueConfiguration]: {
    id: ContentId.VenueConfiguration,
    defaultValue: 'Venue Configuration',
  },
  [ContentId.VenueGeometry]: {
    id: ContentId.VenueGeometry,
    defaultValue: 'Venue Geometry',
  },
  [ContentId.VenueSpecific]: {
    id: ContentId.VenueSpecific,
    defaultValue: 'Venue Specific',
  },
  [ContentId.VersionMismatch]: {
    id: ContentId.VersionMismatch,
    defaultValue: 'Version Mismatch',
  },
  [ContentId.VersionMismatchExplanation]: {
    id: ContentId.VersionMismatchExplanation,
    defaultValue:
      'The fulfillment artifacts in POS and on the marketplace does not match. Please contact the marketplace.',
  },
  [ContentId.FulfillOnStubHub]: {
    id: ContentId.FulfillOnStubHub,
    defaultValue: 'Fulfill on StubHub',
  },
  [ContentId.FulfillOnViagogo]: {
    id: ContentId.FulfillOnViagogo,
    defaultValue: 'Fulfill on Viagogo',
  },
  [ContentId.FullSeating]: {
    id: ContentId.FullSeating,
    defaultValue: 'Full Seating',
  },
  [ContentId.FullySold]: {
    id: ContentId.FullySold,
    defaultValue: 'Fully Sold',
  },
  [ContentId.FutureEvents]: {
    id: ContentId.FutureEvents,
    defaultValue: EventTimeFrameFilter.Future,
  },
  [ContentId.General]: {
    id: ContentId.General,
    defaultValue: 'General',
  },
  [ContentId.GeneralAdmissionEvent]: {
    id: ContentId.GeneralAdmissionEvent,
    defaultValue: 'General Admission event',
  },
  [ContentId.GeneralAdmissionMessage]: {
    id: ContentId.GeneralAdmissionMessage,
    defaultValue:
      'All tickets give you access to any available seating or standing areas. There is no assigned seating.',
  },
  [ContentId.GeneralMessage]: {
    id: ContentId.GeneralMessage,
    defaultValue: 'General Message',
  },
  [ContentId.GeneralSettingsAll]: {
    id: ContentId.GeneralSettingsAll,
    defaultValue: 'Edit General Settings for All',
  },
  [ContentId.GeneralSettingsSelected]: {
    id: ContentId.GeneralSettingsSelected,
    defaultValue: 'Edit General Settings for Selected',
  },
  [ContentId.GenericActionFailure]: {
    id: ContentId.GenericActionFailure,
    defaultValue:
      'An unexpected error occurred. Please try again or contact technical support if the problem perists.',
  },
  [ContentId.Genre]: {
    id: ContentId.Genre,
    defaultValue: 'Genre',
  },
  [ContentId.GoBack]: {
    id: ContentId.GoBack,
    defaultValue: 'Go back',
  },
  [ContentId.GoTo]: {
    id: ContentId.GoTo,
    defaultValue: 'Go To',
  },
  [ContentId.GoToMessageThread]: {
    id: ContentId.GoToMessageThread,
    defaultValue: 'Go to message thread',
  },
  [ContentId.Group]: {
    id: ContentId.Group,
    defaultValue: 'Group',
  },
  [ContentId.GroupBy]: {
    id: ContentId.GroupBy,
    defaultValue: 'Group By',
  },
  [ContentId.GroupingDefault]: {
    id: ContentId.GroupingDefault,
    defaultValue: 'Grouping Default',
  },
  [ContentId.GroupingTemplate]: {
    id: ContentId.GroupingTemplate,
    defaultValue: 'Grouping Template',
  },
  [ContentId.GroupListings]: {
    id: ContentId.GroupListings,
    defaultValue: 'Group Listings',
  },
  [ContentId.HasBroadcastError]: {
    id: ContentId.HasBroadcastError,
    defaultValue: 'Has Broadcast Error',
  },
  [ContentId.HasUnsoldTickets]: {
    id: ContentId.HasUnsoldTickets,
    defaultValue: 'Has Unsold Tickets',
  },
  [ContentId.HeatMap]: {
    id: ContentId.HeatMap,
    defaultValue: 'Heat Map',
  },
  [ContentId.HeatMapInstruction1]: {
    id: ContentId.HeatMapInstruction1,
    defaultValue:
      'Set a heat map for this event - the more valuable seats, the hotter the color.',
  },
  [ContentId.HeatMapInstruction2]: {
    id: ContentId.HeatMapInstruction2,
    defaultValue:
      "When pricing your tickets, you'll be shown the prices of tickets as valuable as yours or better.",
  },
  [ContentId.Height]: {
    id: ContentId.Height,
    defaultValue: 'Height',
  },
  [ContentId.HeldUntil]: {
    id: ContentId.HeldUntil,
    defaultValue: 'Held Until',
  },
  [ContentId.HeldUntilDate]: {
    id: ContentId.HeldUntilDate,
    defaultValue: 'Held Until Date',
  },
  [ContentId.HiddenDueToFilter]: {
    id: ContentId.HiddenDueToFilter,
    defaultValue: 'Hidden by current filter.',
  },
  [ContentId.HideSeats]: {
    id: ContentId.HideSeats,
    defaultValue: 'Hide Seats',
  },
  [ContentId.High]: {
    id: ContentId.High,
    defaultValue: 'High',
  },
  [ContentId.Highest]: {
    id: ContentId.Highest,
    defaultValue: 'Highest',
  },
  [ContentId.HistoricalPricing]: {
    id: ContentId.HistoricalPricing,
    defaultValue: 'Historical Pricing',
  },
  [ContentId.Hold]: {
    id: ContentId.Hold,
    defaultValue: 'Hold',
  },
  [ContentId.HoldExpiration]: {
    id: ContentId.HoldExpiration,
    defaultValue: 'Hold Expiration',
  },
  [ContentId.HoldId]: {
    id: ContentId.HoldId,
    defaultValue: 'Hold Id',
  },
  [ContentId.HoldNote]: {
    id: ContentId.HoldNote,
    defaultValue: 'Hold Note',
  },
  [ContentId.Home]: {
    id: ContentId.Home,
    defaultValue: MainRoute.Home,
  },
  [ContentId.HoverZoomETicketIns]: {
    id: ContentId.HoverZoomETicketIns,
    defaultValue:
      'Hover over the images to zoom in. For each seat, drag and drop the appropriate E-Ticket image or select the image from the dropdown.',
  },
  [ContentId.HoverZoomQRCodeIns]: {
    id: ContentId.HoverZoomQRCodeIns,
    defaultValue:
      'Hover over the images to zoom in. For each seat, drag and drop the appropriate QR code or select one from the dropdown.',
  },
  [ContentId.HowDidYouPay]: {
    id: ContentId.HowDidYouPay,
    defaultValue: 'How did you pay?',
  },
  [ContentId.HowItWorks]: {
    id: ContentId.HowItWorks,
    defaultValue: 'How it works?',
  },
  [ContentId.Hyperlink]: {
    id: ContentId.Hyperlink,
    defaultValue: 'Hyperlink',
  },
  [ContentId.Icon]: {
    id: ContentId.Icon,
    defaultValue: 'Icon',
  },
  [ContentId.Id]: {
    id: ContentId.Id,
    defaultValue: 'ID',
  },
  [ContentId.IgnoreSoldTickets]: {
    id: ContentId.IgnoreSoldTickets,
    defaultValue: 'Ignore sold tickets',
  },
  [ContentId.Impersonate]: {
    id: ContentId.Impersonate,
    defaultValue: 'Impersonate',
  },
  [ContentId.Importance]: {
    id: ContentId.Importance,
    defaultValue: 'Importance',
  },
  [ContentId.ImPricing]: {
    id: ContentId.ImPricing,
    defaultValue: "I'm Pricing",
  },
  [ContentId.In]: {
    id: ContentId.In,
    defaultValue: 'in',
  },
  [ContentId.InactiveListings]: {
    id: ContentId.InactiveListings,
    defaultValue: 'Inactive Listings',
  },
  [ContentId.InApp]: {
    id: ContentId.InApp,
    defaultValue: 'In-App',
  },
  [ContentId.Inbox]: {
    id: ContentId.Inbox,
    defaultValue: 'Inbox',
  },
  [ContentId.IncludeInColumns]: {
    id: ContentId.IncludeInColumns,
    defaultValue: 'Include in Columns',
  },
  [ContentId.IncludeInReport]: {
    id: ContentId.IncludeInReport,
    defaultValue: 'Include in Report',
  },
  [ContentId.InclusiveOption]: {
    id: ContentId.InclusiveOption,
    defaultValue: 'Inclusive (4sd)',
  },
  [ContentId.InComparableZones]: {
    id: ContentId.InComparableZones,
    defaultValue: 'in Comparable Zones',
  },
  [ContentId.IncompatibleForMergingWithSelectedListings]: {
    id: ContentId.IncompatibleForMergingWithSelectedListings,
    defaultValue:
      'The following listings are not eligible for merging with the selected listings.',
  },
  [ContentId.Increase]: {
    id: ContentId.Increase,
    defaultValue: 'Increase',
  },
  [ContentId.IncreaseDecreasePriceByPercentAllVisible]: {
    id: ContentId.IncreaseDecreasePriceByPercentAllVisible,
    defaultValue: 'Increase/Decrease Price of All Visible Listings By X%',
  },
  [ContentId.IncreasePrice]: {
    id: ContentId.IncreasePrice,
    defaultValue: 'Increase Price',
  },
  [ContentId.IncreasePriceByPercent]: {
    id: ContentId.IncreasePriceByPercent,
    defaultValue: 'Increase Price By X%',
  },
  [ContentId.IncreasePriceByPercentAll]: {
    id: ContentId.IncreasePriceByPercentAll,
    defaultValue: 'Increase All Price By X%',
  },
  [ContentId.IncreasePriceByPercentAllVisible]: {
    id: ContentId.IncreasePriceByPercentAllVisible,
    defaultValue: 'Increase Price of All Visible Listings By X%',
  },
  [ContentId.IncreasePriceByPercentSelected]: {
    id: ContentId.IncreasePriceByPercentSelected,
    defaultValue: 'Increase Selected Price By X%',
  },
  [ContentId.IncreasePriceForAll]: {
    id: ContentId.IncreasePriceForAll,
    defaultValue: 'Increase Price for All',
  },
  [ContentId.IncreasePriceForSelected]: {
    id: ContentId.IncreasePriceForSelected,
    defaultValue: 'Increase Price for Selected',
  },
  [ContentId.Ineligible]: {
    id: ContentId.Ineligible,
    defaultValue: 'Ineligible',
  },
  [ContentId.Info]: {
    id: ContentId.Info,
    defaultValue: 'Info',
  },
  [ContentId.InHand]: { id: ContentId.InHand, defaultValue: 'In Hand' },
  [ContentId.InHandDate]: {
    id: ContentId.InHandDate,
    defaultValue: 'In Hand Date',
  },
  [ContentId.InHandDateMustBeBeforeEventDate]: {
    id: ContentId.InHandDateMustBeBeforeEventDate,
    defaultValue: "In-hand date needs to be before the event's start date.",
  },
  [ContentId.InHandDaysBeforeEvent]: {
    id: ContentId.InHandDaysBeforeEvent,
    defaultValue: 'In-Hand Days Before Event',
  },
  [ContentId.Inherit]: {
    id: ContentId.Inherit,
    defaultValue: 'Inherit',
  },
  [ContentId.InSection]: {
    id: ContentId.InSection,
    defaultValue: 'in Section',
  },
  [ContentId.InMyGroup]: {
    id: ContentId.InMyGroup,
    defaultValue: 'in My Group',
  },
  [ContentId.Insert]: {
    id: ContentId.Insert,
    defaultValue: 'Insert',
  },
  [ContentId.InsufficientComparables]: {
    id: ContentId.InsufficientComparables,
    defaultValue: 'Insufficient Comparables',
  },
  [ContentId.InOneDay]: { id: ContentId.InHand, defaultValue: 'In 1 day' },
  [ContentId.Integer]: {
    id: ContentId.Integer,
    defaultValue: 'Integer',
  },
  [ContentId.InTransit]: {
    id: ContentId.InTransit,
    defaultValue: 'In Transit',
  },
  [ContentId.InUse]: {
    id: ContentId.InUse,
    defaultValue: 'In Use',
  },
  [ContentId.InvalidFormula]: {
    id: ContentId.InvalidFormula,
    defaultValue: 'Invalid Formula',
  },
  [ContentId.InvalidUrl]: {
    id: ContentId.InvalidUrl,
    defaultValue: 'Invalid URL',
  },
  [ContentId.InvalidEndDate]: {
    id: ContentId.InvalidEndDate,
    defaultValue: 'Invalid end date',
  },
  [ContentId.InvalidStartDate]: {
    id: ContentId.InvalidStartDate,
    defaultValue: 'Invalid start date',
  },
  [ContentId.Inventory]: {
    id: ContentId.Inventory,
    defaultValue: MainRoute.Inventory,
  },
  [ContentId.Inventory_AddSeatSaver_Details]: {
    id: ContentId.Inventory_AddSeatSaver_Details,
    defaultValue: 'Add placeholder inventory',
  },
  [ContentId.Inventory_AddTagType_Details]: {
    id: ContentId.Inventory_AddTagType_Details,
    defaultValue: 'Permits adding new tag types to inventory',
  },
  [ContentId.Inventory_BroadcastUnbroadcast_Details]: {
    id: ContentId.Inventory_BroadcastUnbroadcast_Details,
    defaultValue: 'Broadcast / Unbroadcast inventory',
  },
  [ContentId.Inventory_BroadcastUnbroadcastOnIsPricerOf_Details]: {
    id: ContentId.Inventory_BroadcastUnbroadcastOnIsPricerOf_Details,
    defaultValue:
      'Broadcast / Unbroadcast inventory where the user is the pricer',
  },
  [ContentId.Inventory_BroadcastUnbroadcastOnPurchasedBy_Details]: {
    id: ContentId.Inventory_BroadcastUnbroadcastOnPurchasedBy_Details,
    defaultValue: 'Broadcast / Unbroadcast inventory that the user purchased',
  },
  [ContentId.Inventory_EditPricing_Details]: {
    id: ContentId.Inventory_EditPricing_Details,
    defaultValue:
      'Edit AllInPrice, ListPrice, CurrencyCode, or (TicketId, Barcode)',
  },
  [ContentId.Inventory_ManageSeatSaverCreatedBy_Details]: {
    id: ContentId.Inventory_ManageSeatSaverCreatedBy_Details,
    defaultValue: 'Update/delete placeholder inventory that the user created',
  },
  [ContentId.Inventory_ManageSeatSaver_Details]: {
    id: ContentId.Inventory_ManageSeatSaver_Details,
    defaultValue: 'Update/remove placeholder inventory',
  },
  [ContentId.Inventory_Predelivery_Details]: {
    id: ContentId.Inventory_Predelivery_Details,
    defaultValue: 'Predeliver inventory fulfillment artifacts',
  },
  [ContentId.Inventory_PredeliveryOnIsPricerOf_Details]: {
    id: ContentId.Inventory_PredeliveryOnIsPricerOf_Details,
    defaultValue: 'Predeliver inventory where the user is the pricer',
  },
  [ContentId.Inventory_PredeliveryOnPurchasedBy_Details]: {
    id: ContentId.Inventory_PredeliveryOnPurchasedBy_Details,
    defaultValue: 'Predeliver inventory that the user purchased',
  },
  [ContentId.Inventory_SetPrice_Details]: {
    id: ContentId.Inventory_SetPrice_Details,
    defaultValue: 'Edit prices of all accessible inventory',
  },
  [ContentId.Inventory_SetPriceOnIsPricerOf_Details]: {
    id: ContentId.Inventory_SetPriceOnIsPricerOf_Details,
    defaultValue:
      'Edit prices of all accessible inventory that the current user is pricer of ',
  },
  [ContentId.Inventory_SetPriceOnPurchasedBy_Details]: {
    id: ContentId.Inventory_SetPriceOnPurchasedBy_Details,
    defaultValue:
      'Edit prices of all accessible inventory and are purchased by current user',
  },
  [ContentId.Inventory_UpdateListingInfo_Details]: {
    id: ContentId.Inventory_UpdateListingInfo_Details,
    defaultValue: 'Edit SplitType, HideSeat, etc',
  },
  [ContentId.Inventory_UpdateListingInfoOnIsPricerOf_Details]: {
    id: ContentId.Inventory_UpdateListingInfoOnIsPricerOf_Details,
    defaultValue:
      'Allows updating inventory information where the user is the pricer',
  },
  [ContentId.Inventory_UpdateListingInfoOnPurchasedBy_Details]: {
    id: ContentId.Inventory_UpdateListingInfoOnPurchasedBy_Details,
    defaultValue:
      'Edit SplitType, HideSeat, etc. that are purchased by the current user',
  },
  [ContentId.Inventory_UpdateTags_Details]: {
    id: ContentId.Inventory_UpdateTags_Details,
    defaultValue: 'Permits updating of tags on inventory items',
  },
  [ContentId.Inventory_UpdateTagsOnIsPricerOf_Details]: {
    id: ContentId.Inventory_UpdateTagsOnIsPricerOf_Details,
    defaultValue:
      'Permits updating tags of inventory where the user is the pricer',
  },
  [ContentId.Inventory_UpdateTagsOnPurchasedBy_Details]: {
    id: ContentId.Inventory_UpdateTagsOnPurchasedBy_Details,
    defaultValue:
      'Permits updating tags related to purchaser information on inventory items',
  },
  [ContentId.Inventory_ViewAll_Details]: {
    id: ContentId.Inventory_ViewAll_Details,
    defaultValue: 'See all inventory including historical ones',
  },
  [ContentId.Inventory_ViewAllMarketplaceAttributes_Details]: {
    id: ContentId.Inventory_ViewAllMarketplaceAttributes_Details,
    defaultValue:
      "See inventory's marketplace attributes (broadcasting, price, etc.)",
  },
  [ContentId.Inventory_ViewExternalMarketplaceAttributes_Details]: {
    id: ContentId.Inventory_ViewExternalMarketplaceAttributes_Details,
    defaultValue:
      "See inventory's external marketplace attributes (broadcasting, price, etc.)",
  },
  [ContentId.Inventory_ViewFulfillmentArtifacts_Details]: {
    id: ContentId.Inventory_ViewFulfillmentArtifacts_Details,
    defaultValue: "See inventory's predelivered fulfillment artifacts",
  },
  [ContentId.Inventory_ViewFulfillmentArtifactsOnIsPricerOf_Details]: {
    id: ContentId.Inventory_ViewFulfillmentArtifactsOnIsPricerOf_Details,
    defaultValue:
      'View fulfillment artifacts on inventory where the user is the pricer',
  },
  [ContentId.Inventory_ViewFulfillmentArtifactsOnPurchasedBy_Details]: {
    id: ContentId.Inventory_ViewFulfillmentArtifactsOnPurchasedBy_Details,
    defaultValue:
      'View fulfillment artifacts on inventory that the user purchased',
  },
  [ContentId.Inventory_ViewListingOnIsPricerOf_Details]: {
    id: ContentId.Inventory_ViewListingOnIsPricerOf_Details,
    defaultValue: 'Permits viewing inventory where the user is the pricer',
  },
  [ContentId.Inventory_ViewListingsOnPurchasedBy_Details]: {
    id: ContentId.Inventory_ViewListingsOnPurchasedBy_Details,
    defaultValue: 'View inventory that the user purchased',
  },
  [ContentId.Inventory_ViewRecent_Details]: {
    id: ContentId.Inventory_ViewRecent_Details,
    defaultValue:
      'See all inventory for future events or events less than 3 months old',
  },
  [ContentId.Inventory_ViewRecentFromExternalMarketplace_Details]: {
    id: ContentId.Inventory_ViewRecentFromExternalMarketplace_Details,
    defaultValue:
      'See only inventory for future events or events less than 3 months old that are not StubHub sales',
  },
  [ContentId.Invited]: {
    id: ContentId.Invited,
    defaultValue: 'Invited',
  },
  [ContentId.InZone]: {
    id: ContentId.InZone,
    defaultValue: 'in Zone',
  },
  [ContentId.IPaidFace]: {
    id: ContentId.IPaidFace,
    defaultValue: 'I Paid Face',
  },
  [ContentId.IsGlobal]: {
    id: ContentId.IsGlobal,
    defaultValue: 'Is Global',
  },
  [ContentId.ItemName]: {
    id: ContentId.ItemName,
    defaultValue: 'Item name',
  },
  [ContentId.ItemsPerPage]: {
    id: ContentId.ItemsPerPage,
    defaultValue: 'Items per page',
  },
  [ContentId.IWorkForViagogo]: {
    id: ContentId.IWorkForViagogo,
    defaultValue: 'I work for Viagogo',
  },
  [ContentId.IWorkForEventOrganizer]: {
    id: ContentId.IWorkForEventOrganizer,
    defaultValue: 'I work for the Event Organizer',
  },
  [ContentId.IsThisASpeculativeListing]: {
    id: ContentId.IsThisASpeculativeListing,
    defaultValue: 'Is this a speculative listing?',
  },
  [ContentId.Json]: {
    id: ContentId.Json,
    defaultValue: 'Json',
  },
  [ContentId.Junk]: {
    id: ContentId.Junk,
    defaultValue: 'Junk',
  },
  [ContentId.JustNow]: {
    id: ContentId.JustNow,
    defaultValue: 'just now',
  },
  [ContentId.Key]: {
    id: ContentId.Key,
    defaultValue: 'Key',
  },
  [ContentId.Language]: {
    id: ContentId.Language,
    defaultValue: 'Language',
  },
  [ContentId.LastPriceUpdatedDate]: {
    id: ContentId.LastPriceUpdatedDate,
    defaultValue: 'Last Price Updated',
  },
  [ContentId.LastSaleDates]: {
    id: ContentId.LastSaleDates,
    defaultValue: 'Last Sale Dates',
  },
  [ContentId.LastUpdatedOn]: {
    id: ContentId.LastUpdatedOn,
    defaultValue: 'Last updated on',
  },
  [ContentId.LastWeek]: {
    id: ContentId.LastWeek,
    defaultValue: 'Last Week',
  },
  [ContentId.Last1Hour]: {
    id: ContentId.Last1Hour,
    defaultValue: 'Last 1 hour',
  },
  [ContentId.Last30Days]: {
    id: ContentId.Last30Days,
    defaultValue: 'Last 30 Days',
  },
  [ContentId.LastYear]: {
    id: ContentId.LastYear,
    defaultValue: 'Last Year',
  },
  [ContentId.LeastRecent]: {
    id: ContentId.LeastRecent,
    defaultValue: 'Least Recent',
  },
  [ContentId.LeftToSell]: {
    id: ContentId.LeftToSell,
    defaultValue: 'Left to Sell',
  },
  [ContentId.LessSales]: {
    id: ContentId.LessSales,
    defaultValue: 'Less Sales',
  },
  [ContentId.LiftPercentage]: {
    id: ContentId.LiftPercentage,
    defaultValue: '% Lift',
  },
  [ContentId.Light]: {
    id: ContentId.Light,
    defaultValue: 'Light',
  },
  [ContentId.Link]: {
    id: ContentId.Link,
    defaultValue: 'Link',
  },
  [ContentId.LinkCopied]: {
    id: ContentId.LinkCopied,
    defaultValue: 'Link Copied',
  },
  [ContentId.LinkHasBeenCopiedToClipboard]: {
    id: ContentId.LinkHasBeenCopiedToClipboard,
    defaultValue: 'Link has been copied to the clipboard.',
  },
  [ContentId.List]: {
    id: ContentId.List,
    defaultValue: 'List',
  },
  /**
   * @deprecated Unused
   */
  [ContentId.ListAndAllInPriceError]: {
    id: ContentId.ListAndAllInPriceError,
    defaultValue:
      "Either 'List' or 'All In' price must be greater than zero, but not both.",
  },
  [ContentId.Listed]: {
    id: ContentId.Listed,
    defaultValue: 'Listed',
  },
  [ContentId.ListedATP]: {
    id: ContentId.ListedATP,
    defaultValue: 'Listed ATP',
  },
  [ContentId.ListedAverageTicketPrice]: {
    id: ContentId.ListedAverageTicketPrice,
    defaultValue: 'Listed Average Ticket Price',
  },
  [ContentId.ListedClickToDelist]: {
    id: ContentId.ListedClickToDelist,
    defaultValue: 'Listed - click to delist',
  },
  [ContentId.ListedQty]: {
    id: ContentId.ListedQty,
    defaultValue: 'Listed Qty',
  },
  [ContentId.ListedQuantity]: {
    id: ContentId.ListedQuantity,
    defaultValue: 'Listed Quantity',
  },
  [ContentId.ListingConfirmation]: {
    id: ContentId.ListingConfirmation,
    defaultValue: 'Listing Confirmation',
  },
  [ContentId.ListedToViagogoSuccessfully]: {
    id: ContentId.ListedToViagogoSuccessfully,
    defaultValue: 'Listed to Viagogo successfully.',
  },
  [ContentId.ListingFailed]: {
    id: ContentId.ListingFailed,
    defaultValue: 'List Failed',
  },
  [ContentId.ListingFailedClickToTryAgain]: {
    id: ContentId.ListingFailedClickToTryAgain,
    defaultValue: 'List Failed - click to try again',
  },
  [ContentId.ListingFullSoldNoActionAvailable]: {
    id: ContentId.ListingFullSoldNoActionAvailable,
    defaultValue: 'Fully Sold - no actions available',
  },
  [ContentId.ListingId]: {
    id: ContentId.ListingId,
    defaultValue: 'Listing Id',
  },
  [ContentId.Listing]: {
    id: ContentId.Listing,
    defaultValue: 'Listing',
  },
  [ContentId.ListingPending]: {
    id: ContentId.ListingPending,
    defaultValue: 'Listing...',
  },
  [ContentId.ListingClickToCancel]: {
    id: ContentId.ListingClickToCancel,
    defaultValue: 'Listing... - click to cancel',
  },
  [ContentId.Listings]: {
    id: ContentId.Listings,
    defaultValue: 'Listings',
  },
  [ContentId.ListingNumberUsedRequired]: {
    id: ContentId.ListingNumberUsedRequired,
    defaultValue:
      'At least one ticket is needed per listing number. These listing numbers have no tickets assigned:',
  },
  /**
   * @deprecated Use `ContentId.Proceeds`
   */
  [ContentId.ListingSettings]: {
    id: ContentId.ListingSettings,
    defaultValue: 'Listing Settings',
  },
  [ContentId.ListingSettingsAllVisible]: {
    id: ContentId.ListingSettingsAllVisible,
    defaultValue: 'Edit General Settings for All Visible Listings',
  },
  [ContentId.ListPrice]: {
    id: ContentId.ListPrice,
    defaultValue: 'List Price',
  },
  /**
   * @deprecated Unused
   */
  [ContentId.ListPriceAutoCalculationInfo]: {
    id: ContentId.ListPriceAutoCalculationInfo,
    defaultValue:
      'List price is estimated from your all-in price minus fees (if any).',
  },
  [ContentId.ListPriceLessThanPriceFloorWarning]: {
    id: ContentId.ListPriceLessThanPriceFloorWarning,
    defaultValue:
      "You've made a price change that below the price floor. Are you sure?",
  },
  /**
   * @deprecated Use `ContentId.ProceedsPerTicket`
   */
  [ContentId.ListPricePerTicket]: {
    id: ContentId.ListPricePerTicket,
    defaultValue: 'List Price Per Ticket',
  },
  [ContentId.Location]: {
    id: ContentId.Location,
    defaultValue: 'Location',
  },
  [ContentId.Login]: {
    id: ContentId.Login,
    defaultValue: 'Login',
  },
  [ContentId.Logout]: {
    id: ContentId.Logout,
    defaultValue: 'Logout',
  },
  [ContentId.LookupByListingId]: {
    id: ContentId.LookupByListingId,
    defaultValue: 'Lookup By Listing Id',
  },
  [ContentId.LookupByOrderId]: {
    id: ContentId.LookupByOrderId,
    defaultValue: 'Lookup By Order Id',
  },
  [ContentId.LookupBySaleId]: {
    id: ContentId.LookupBySaleId,
    defaultValue: 'Lookup By Sale Id',
  },
  [ContentId.Low]: {
    id: ContentId.Low,
    defaultValue: 'Low',
  },
  [ContentId.Lowest]: {
    id: ContentId.Lowest,
    defaultValue: 'Lowest',
  },
  [ContentId.ManualPriceWarning]: {
    id: ContentId.ManualPriceWarning,
    defaultValue: 'Manual Price Warning',
  },
  [ContentId.ManualPriceDisableAutoPricingWarning]: {
    id: ContentId.ManualPriceDisableAutoPricingWarning,
    defaultValue:
      'Manually editing price will disable Auto-Pricing. Click Cancel to keep Auto-Pricing on.',
  },
  [ContentId.ManualPriceAdjustFloorWarning]: {
    id: ContentId.ManualPriceAdjustFloorWarning,
    defaultValue:
      'The price you entered is below your price floor. Click OK to adjust the floor to accomodate your new price, or Cancel to cap your price at the floor.',
  },
  [ContentId.ManualPriceAdjustCeilingWarning]: {
    id: ContentId.ManualPriceAdjustCeilingWarning,
    defaultValue:
      'The price you entered is above your price ceiling. Click OK to adjust the ceiling to accomodate your new price, or Cancel to cap your price at the ceiling.',
  },
  [ContentId.Mapped]: {
    id: ContentId.Mapped,
    defaultValue: 'Mapped',
  },
  [ContentId.MappedEventId]: {
    id: ContentId.MappedEventId,
    defaultValue: 'Mapped Event ID',
  },
  [ContentId.MappedTo]: {
    id: ContentId.MappedTo,
    defaultValue: 'Mapped To',
  },
  [ContentId.MapUnavailable]: {
    id: ContentId.MapUnavailable,
    defaultValue: 'Venue map not available',
  },
  [ContentId.Margin]: {
    id: ContentId.Margin,
    defaultValue: 'Margin',
  },
  [ContentId.MarkAsPurchase]: {
    id: ContentId.MarkAsPurchase,
    defaultValue: 'Mark as purchase',
  },
  [ContentId.MarkAsPrivate]: {
    id: ContentId.MarkAsPrivate,
    defaultValue: 'Mark as private',
  },
  [ContentId.MarkAsJunk]: {
    id: ContentId.MarkAsJunk,
    defaultValue: 'Mark as junk',
  },
  [ContentId.MarkAsSale]: {
    id: ContentId.MarkAsSale,
    defaultValue: 'Mark as sale',
  },
  [ContentId.MarkAsTransfer]: {
    id: ContentId.MarkAsTransfer,
    defaultValue: 'Mark as transfer',
  },
  [ContentId.MarketListings]: {
    id: ContentId.MarketListings,
    defaultValue: 'Market Listings',
  },
  [ContentId.Marketplace]: {
    id: ContentId.Marketplace,
    defaultValue: 'Marketplace',
  },
  [ContentId.MarketplaceEnableConfirmMessage]: {
    id: ContentId.MarketplaceEnableConfirmMessage,
    defaultValue: 'The following marketplaces will be enabled:',
  },
  [ContentId.MarketplaceDisableConfirmMessage]: {
    id: ContentId.MarketplaceDisableConfirmMessage,
    defaultValue: 'The following marketplaces will be disabled:',
  },
  [ContentId.MarketplacePaymentTerms]: {
    id: ContentId.MarketplacePaymentTerms,
    defaultValue: 'Marketplace Payment Terms',
  },
  [ContentId.MarketplaceSeatingForAll]: {
    id: ContentId.MarketplaceSeatingForAll,
    defaultValue: 'Marketplace Seating for All',
  },
  [ContentId.MarketplaceInhandDate]: {
    id: ContentId.MarketplaceInhandDate,
    defaultValue: 'Marketplace In hand Date',
  },
  [ContentId.MarketplaceSeatingForAllVisible]: {
    id: ContentId.MarketplaceSeatingForAllVisible,
    defaultValue: 'Marketplace Seating for All Visible Listings',
  },
  [ContentId.MarketplaceSeatingForSelected]: {
    id: ContentId.MarketplaceSeatingForSelected,
    defaultValue: 'Marketplace Seating for Selected',
  },
  [ContentId.MarketplaceVisibility]: {
    id: ContentId.MarketplaceVisibility,
    defaultValue: 'Marketplace Visibility',
  },
  [ContentId.MarketPrice]: {
    id: ContentId.MarketPrice,
    defaultValue: 'Market Price',
  },
  [ContentId.Markup]: {
    id: ContentId.Markup,
    defaultValue: 'Markup',
  },
  [ContentId.Max]: {
    id: ContentId.Max,
    defaultValue: 'Max',
  },
  [ContentId.MaxDiscountInputPrompt1]: {
    id: ContentId.MaxDiscountInputPrompt1,
    defaultValue: "Don't allow price drop of more than ",
  },
  [ContentId.MaxDiscountInputPrompt2]: {
    id: ContentId.MaxDiscountInputPrompt2,
    defaultValue: ' over a ',
  },
  [ContentId.MaxDiscountInputPrompt3]: {
    id: ContentId.MaxDiscountInputPrompt3,
    defaultValue: ' hour period.',
  },
  [ContentId.MaxValueLengthExceeded]: {
    id: ContentId.MaxValueLengthExceeded,
    defaultValue: 'Max value length for this tag exceeded',
  },
  [ContentId.Median]: {
    id: ContentId.Median,
    defaultValue: 'Median',
  },
  [ContentId.MemberCard]: {
    id: ContentId.MemberCard,
    defaultValue: 'Member Card',
  },
  [ContentId.Merchant]: {
    id: ContentId.Merchant,
    defaultValue: 'Merchant',
  },
  [ContentId.Merge]: {
    id: ContentId.Merge,
    defaultValue: 'Merge',
  },
  [ContentId.MergeListings]: {
    id: ContentId.MergeListings,
    defaultValue: 'Merge Listings',
  },
  [ContentId.MergeListings_GroupedListings]: {
    id: ContentId.MergeListings_GroupedListings,
    defaultValue:
      'This listing already belongs to a listing group and cannot be merged.',
  },
  [ContentId.MergeListings_SectionDifference]: {
    id: ContentId.MergeListings_SectionDifference,
    defaultValue:
      'The section of this listing is different from your selected listings.',
  },
  [ContentId.MergeListings_RowDifference]: {
    id: ContentId.MergeListings_RowDifference,
    defaultValue:
      'The row of this listing is different from your selected listings.',
  },
  [ContentId.MergeListings_DeliveryDifference]: {
    id: ContentId.MergeListings_DeliveryDifference,
    defaultValue:
      'The delivery type of this listing is different from your selected listings.',
  },
  [ContentId.Merges]: {
    id: ContentId.Merges,
    defaultValue: 'merges',
  },
  [ContentId.MergeSelected]: {
    id: ContentId.MergeSelected,
    defaultValue: 'Merge Selected',
  },
  [ContentId.MergeSuggested]: {
    id: ContentId.MergeSuggested,
    defaultValue: 'Merge Suggested',
  },
  [ContentId.Messages]: {
    id: ContentId.Messages,
    defaultValue: 'Messages',
  },
  [ContentId.Metrics]: {
    id: ContentId.Metrics,
    defaultValue: 'Metrics',
  },
  [ContentId.MetricView]: {
    id: ContentId.MetricView,
    defaultValue: 'Metric View',
  },
  [ContentId.MicrosoftExcelExt]: {
    id: ContentId.MicrosoftExcelExt,
    defaultValue: 'Microsoft Excel (.xlsx)',
  },
  [ContentId.Min]: {
    id: ContentId.Min,
    defaultValue: 'Min',
  },
  [ContentId.MinCompListingCount]: {
    id: ContentId.MinCompListingCount,
    defaultValue: 'Minimum Required Listings to Price Against',
  },
  [ContentId.MissingRowDataWarning]: {
    id: ContentId.MissingRowDataWarning,
    defaultValue:
      'Stadium missing row data. Pricing will be at the section level.',
  },
  [ContentId.MissingSeatScore]: {
    id: ContentId.MissingSeatScore,
    defaultValue: 'Missing Seat Score',
  },
  [ContentId.MissingSectionFilter]: {
    id: ContentId.MissingSectionFilter,
    defaultValue: 'Missing Section Filter',
  },
  [ContentId.MissingUnitCost]: {
    id: ContentId.MissingUnitCost,
    defaultValue: 'Missing Unit Cost',
  },
  [ContentId.MissingZone]: {
    id: ContentId.MissingZone,
    defaultValue: 'Missing Zone',
  },
  [ContentId.MLB]: {
    id: ContentId.MLB,
    defaultValue: 'MLB',
  },
  [ContentId.MoneyOrder]: {
    id: ContentId.MoneyOrder,
    defaultValue: 'UiMoney Order',
  },
  [ContentId.Month]: {
    id: ContentId.Month,
    defaultValue: 'Month',
  },
  [ContentId.MoreSales]: {
    id: ContentId.MoreSales,
    defaultValue: 'More Sales',
  },
  [ContentId.MostRecent]: {
    id: ContentId.MostRecent,
    defaultValue: 'Most Recent',
  },
  [ContentId.MultiplePayments]: {
    id: ContentId.MultiplePayments,
    defaultValue: 'Multiple Payments',
  },
  [ContentId.MultipleSelections]: {
    id: '',
    defaultValue: 'Multiple selections',
  },
  [ContentId.MyTicketsHaveRowsAndSeats]: {
    id: ContentId.MyTicketsHaveRowsAndSeats,
    defaultValue: 'My tickets have rows and seats',
  },
  [ContentId.MyAccount]: {
    id: ContentId.MyAccount,
    defaultValue: 'My Account',
  },
  [ContentId.NA]: {
    id: ContentId.NA,
    defaultValue: 'N/A',
  },
  [ContentId.Name]: {
    id: ContentId.Name,
    defaultValue: 'Name',
  },
  [ContentId.NameColumnPlaceholder]: {
    id: ContentId.NameColumnPlaceholder,
    defaultValue: 'Name the column',
  },
  [ContentId.Near]: {
    id: ContentId.Near,
    defaultValue: 'Near',
  },
  [ContentId.NearestEvent]: {
    id: ContentId.NearestEvent,
    defaultValue: 'Nearest Event',
  },
  [ContentId.NeedFulfillment]: {
    id: ContentId.NeedFulfillment,
    defaultValue: 'Needs Fulfillment',
  },
  [ContentId.NeedToMergeToTwoOrMoreListings]: {
    id: ContentId.NeedToMergeToTwoOrMoreListings,
    defaultValue: 'You need at least 2 listings to merge.',
  },
  [ContentId.NeedToSplitToTwoOrMoreListings]: {
    id: ContentId.NeedToSplitToTwoOrMoreListings,
    defaultValue: 'You need to split to 2 or more listings.',
  },
  [ContentId.NeverShareYourPasswordWithAnyone]: {
    id: ContentId.NeverShareYourPasswordWithAnyone,
    defaultValue: 'Never share your password with anyone',
  },
  [ContentId.NewFeature]: {
    id: ContentId.NewFeature,
    defaultValue: 'New Feature',
  },
  [ContentId.NewGroup]: {
    id: ContentId.NewGroup,
    defaultValue: 'New Group',
  },
  [ContentId.NewModel]: {
    id: ContentId.NewModel,
    defaultValue: 'New Model',
  },
  [ContentId.NewOrder]: {
    id: ContentId.NewOrder,
    defaultValue: 'New order',
  },
  [ContentId.NewRole]: {
    id: ContentId.NewRole,
    defaultValue: 'New Role',
  },
  [ContentId.NewRow]: {
    id: ContentId.NewRow,
    defaultValue: 'New row',
  },
  [ContentId.NewSale]: {
    id: ContentId.NewSale,
    defaultValue: 'New Sale',
  },
  [ContentId.NewSales]: {
    id: ContentId.NewSales,
    defaultValue: 'New Sales',
  },
  [ContentId.NewUser]: {
    id: ContentId.NewUser,
    defaultValue: 'New user',
  },
  [ContentId.NewVendor]: {
    id: ContentId.NewVendor,
    defaultValue: 'New vendor',
  },
  [ContentId.Next]: {
    id: ContentId.Next,
    defaultValue: 'Next',
  },
  [ContentId.Next2Weeks]: {
    id: ContentId.Next2Weeks,
    defaultValue: 'Next 2 Weeks',
  },
  [ContentId.NextWeek]: {
    id: ContentId.NextWeek,
    defaultValue: 'Next Week',
  },
  [ContentId.NextMonth]: {
    id: ContentId.NextMonth,
    defaultValue: 'Next Month',
  },
  [ContentId.NextYear]: {
    id: ContentId.NextYear,
    defaultValue: 'Next Year',
  },
  [ContentId.No]: {
    id: ContentId.No,
    defaultValue: 'No',
  },
  [ContentId.NoActiveAccountsForLogin]: {
    id: ContentId.NoActiveAccountsForLogin,
    defaultValue:
      'You do not have any available seller account to log into. Please contact us to setup one.',
  },
  [ContentId.NoActivityLog]: {
    id: ContentId.NoActivityLog,
    defaultValue: 'There is currently no activity log data to display.',
  },
  [ContentId.NoAutoPricing_MissingPermission]: {
    id: ContentId.NoAutoPricing_MissingPermission,
    defaultValue: 'You do not have permission to set auto pricing.',
  },
  [ContentId.NoAutoPricing_MissingRowId]: {
    id: ContentId.NoAutoPricing_MissingRowId,
    defaultValue:
      "Enable auto pricing by updating seating information using StubHub's event map.",
  },
  [ContentId.NoAutoPricing_NoVenueConfig]: {
    id: ContentId.NoAutoPricing_NoVenueConfig,
    defaultValue:
      'This event does not have mapping information for auto pricing.',
  },
  [ContentId.NoConversation]: {
    id: ContentId.NoConversation,
    defaultValue: 'There is currently no conversation to display.',
  },
  [ContentId.NoEventsMatching]: {
    id: ContentId.NoEventsMatching,
    defaultValue: 'There are no events matching your search',
  },
  [ContentId.NoFilteredNotifications]: {
    id: ContentId.NoFilteredNotifications,
    defaultValue: "There aren't any results for this filter.",
  },
  [ContentId.NoGroupingAvailableForDefault]: {
    id: ContentId.NoGroupingAvailableForDefault,
    defaultValue: 'No groups matched this grouping default',
  },
  [ContentId.NoMergesAvailable]: {
    id: ContentId.NoMergesAvailable,
    defaultValue: 'No merges available',
  },
  [ContentId.None]: { id: ContentId.None, defaultValue: 'None' },
  [ContentId.NoneSelected]: {
    id: ContentId.NoneSelected,
    defaultValue: 'None Selected',
  },
  [ContentId.NoNotifications]: {
    id: ContentId.NoNotifications,
    defaultValue: "You're all caught up on alerts.",
  },
  [ContentId.NoPerformersMatching]: {
    id: ContentId.NoPerformersMatching,
    defaultValue: 'There are no performers matching your search',
  },
  [ContentId.NoReportText]: {
    id: ContentId.NoReportText,
    defaultValue:
      'The report could not be loaded. Please check your filters and try again. If the issue persists, contact support for assistance.',
  },
  [ContentId.NoResultFound]: {
    id: ContentId.NoResultFound,
    defaultValue: 'No results found',
  },
  [ContentId.NormalOption]: {
    id: ContentId.NormalOption,
    defaultValue: 'Normal (3sd)',
  },
  [ContentId.NoSeatingsAvailableForAllocation]: {
    id: ContentId.NoSeatingsAvailableForAllocation,
    defaultValue:
      'There are no tickets available for this event to allocate. Click below to add new tickets.',
  },
  [ContentId.NoTagsExist]: {
    id: ContentId.NoTagsExist,
    defaultValue: 'No tags exist',
  },
  [ContentId.NotBroadcastOnAnyMarketplace]: {
    id: ContentId.NotBroadcastOnAnyMarketplace,
    defaultValue:
      "Not being broadcasted anywhere. Click 'Broadcast' to start broadcasting to all available marketplaces.",
  },
  [ContentId.Notes]: {
    id: ContentId.Notes,
    defaultValue: 'Notes',
  },
  [ContentId.NoTaxes]: { id: ContentId.NoTaxes, defaultValue: 'No Taxes' },
  [ContentId.Notifications]: {
    id: ContentId.Notifications,
    defaultValue: 'Notifications',
  },
  [ContentId.NotificationTypeAutopriceBelowFloor]: {
    id: ContentId.NotificationTypeAutopriceBelowFloor,
    defaultValue: 'Autoprice below floor',
  },
  [ContentId.NotificationTypeAutopriceInsufficientCompListings]: {
    id: ContentId.NotificationTypeAutopriceInsufficientCompListings,
    defaultValue: 'Autoprice insufficient comp listings',
  },
  [ContentId.NotificationTypeAutopriceFirebrake]: {
    id: ContentId.NotificationTypeAutopriceFirebrake,
    defaultValue: 'Autoprice Firebrake',
  },
  [ContentId.NotificationTypeSaleFulfilmentFailed]: {
    id: ContentId.NotificationTypeSaleFulfilmentFailed,
    defaultValue: 'Sale Fulfilment Failed',
  },
  [ContentId.NotificationTypeSaleMarketplaceCancelled]: {
    id: ContentId.NotificationTypeSaleMarketplaceCancelled,
    defaultValue: 'Marketplace Cancelled Sale',
  },
  [ContentId.NotificationTypeListingHideSeatsFailed]: {
    id: ContentId.NotificationTypeListingHideSeatsFailed,
    defaultValue: "Could not update 'Hide Seats'",
  },
  [ContentId.NotificationTypeListingUpdateDeliveryTypeFailed]: {
    id: ContentId.NotificationTypeListingUpdateDeliveryTypeFailed,
    defaultValue: "Listing update 'Delivery Type' failed",
  },
  [ContentId.NotificationTypeListingMergeFailed]: {
    id: ContentId.NotificationTypeListingMergeFailed,
    defaultValue: 'Failed to merge listings',
  },
  [ContentId.NotificationTypeListingSplitFailed]: {
    id: ContentId.NotificationTypeListingSplitFailed,
    defaultValue: 'Failed to split listing',
  },
  [ContentId.NotificationTypeSaleNew]: {
    id: ContentId.NotificationTypeSaleNew,
    defaultValue: 'New Sale',
  },
  [ContentId.NotImplemented]: {
    id: ContentId.NotImplemented,
    defaultValue: 'Not implemented',
  },
  [ContentId.NoToAll]: {
    id: ContentId.NoToAll,
    defaultValue: 'No to All',
  },
  [ContentId.NoVenueMetadataForAutopricing]: {
    id: ContentId.NoVenueMetadataForAutopricing,
    defaultValue:
      'Seat map requires at least one metadata (angle, distance, height) to auto-configure section scores.  You can still manually score sections by clicking on them.',
  },
  [ContentId.NoVenuesMatching]: {
    id: ContentId.NoVenuesMatching,
    defaultValue: 'There are no venues matching your search',
  },
  [ContentId.Now]: { id: ContentId.Now, defaultValue: 'Now' },
  [ContentId.NumberFormat]: {
    id: ContentId.NumberFormat,
    defaultValue: 'Number format',
  },
  [ContentId.NumberOfItemsPerPage]: {
    id: ContentId.NumberOfItemsPerPage,
    defaultValue: 'Number of items per page (Leave empty to disable paging)',
  },
  [ContentId.NumberOfTicketsHeld]: {
    id: ContentId.NumberOfTicketsHeld,
    defaultValue: 'Number of Tickets Held',
  },
  [ContentId.NumOfListingsToCreate]: {
    id: ContentId.NumOfListingsToCreate,
    defaultValue: 'Number of listings to create',
  },
  [ContentId.Odds]: {
    id: ContentId.Odds,
    defaultValue: 'Odds',
  },
  [ContentId.Off]: {
    id: ContentId.Off,
    defaultValue: 'Off',
  },
  [ContentId.OfSimilarQuality]: {
    id: ContentId.OfSimilarQuality,
    defaultValue: 'of Similar Quality',
  },
  [ContentId.OfSimilarQualityInZone]: {
    id: ContentId.OfSimilarQualityInZone,
    defaultValue: 'of Similar Quality and in Zone',
  },
  [ContentId.OfSimilarQualityQuantityAdjusted]: {
    id: ContentId.OfSimilarQualityQuantityAdjusted,
    defaultValue: 'of Similar Quality (Quantity Adjusted)',
  },
  [ContentId.OfTheComparableListings]: {
    id: ContentId.OfTheComparableListings,
    defaultValue: 'of the comparable listings',
  },
  [ContentId.OK]: { id: ContentId.OK, defaultValue: 'OK' },
  [ContentId.OlderThanLastYear]: {
    id: ContentId.OlderThanLastYear,
    defaultValue: 'Older',
  },
  [ContentId.On]: {
    id: ContentId.On,
    defaultValue: 'On',
  },
  [ContentId.OnePayment]: {
    id: ContentId.OnePayment,
    defaultValue: 'One Payment',
  },
  [ContentId.OneTimePass]: {
    id: ContentId.OneTimePass,
    defaultValue: 'One Time Pass',
  },
  [ContentId.OnlySingleValueIsAllowed]: {
    id: ContentId.OnlySingleValueIsAllowed,
    defaultValue: 'Only single value is allowed.',
  },
  [ContentId.Oops]: { id: ContentId.Oops, defaultValue: 'Oops!' },
  [ContentId.OpenEventPage]: {
    id: ContentId.OpenEventPage,
    defaultValue: 'Open Event Page',
  },
  [ContentId.OpenReport]: {
    id: ContentId.OpenReport,
    defaultValue: 'Open report',
  },
  [ContentId.Opponent]: {
    id: ContentId.Opponent,
    defaultValue: 'Opponent',
  },
  [ContentId.Or]: { id: ContentId.Or, defaultValue: 'or' },
  [ContentId.OrAbove]: {
    id: ContentId.OrAbove,
    defaultValue: 'or above',
  },
  [ContentId.OrderDate]: {
    id: ContentId.OrderDate,
    defaultValue: 'Order date',
  },
  [ContentId.OrderId]: {
    id: ContentId.OrderId,
    defaultValue: 'Order Id',
  },
  [ContentId.OrderIdDoesNotExist]: {
    id: ContentId.OrderIdDoesNotExist,
    defaultValue: 'Warning: this order ID does not exist.',
  },
  [ContentId.OrderIdDoesNotExistForVendor]: {
    id: ContentId.OrderIdDoesNotExistForVendor,
    defaultValue: 'Order ID does not exist for this vendor.',
  },
  [ContentId.OrderIdInUse]: {
    id: ContentId.OrderIdInUse,
    defaultValue:
      'Warning: this order ID is already used by another purchase order.',
  },
  [ContentId.OrderIdInUseSameVendor]: {
    id: ContentId.OrderIdInUseSameVendor,
    defaultValue:
      'Order ID is used by another purchase order under same vendor.',
  },
  [ContentId.Orders]: {
    id: ContentId.Orders,
    defaultValue: 'Orders',
  },
  [ContentId.Original]: { id: ContentId.Original, defaultValue: 'Original' },
  [ContentId.Other]: { id: ContentId.Other, defaultValue: 'Other' },
  [ContentId.OtherMarketplaceContact]: {
    id: ContentId.OtherMarketplaceContact,
    defaultValue: 'Other Marketplace Contact',
  },
  [ContentId.Outlier]: {
    id: ContentId.Outlier,
    defaultValue: 'Outlier',
  },
  [ContentId.OutlierFiltering]: {
    id: ContentId.OutlierFiltering,
    defaultValue: 'Outlier Filtering',
  },
  [ContentId.OutlierMode]: {
    id: ContentId.OutlierMode,
    defaultValue: 'Outlier Detection Mode',
  },
  [ContentId.OutstandingBalance]: {
    id: ContentId.OutstandingBalance,
    defaultValue: 'Outstanding Balance',
  },
  [ContentId.Overview]: {
    id: ContentId.Overview,
    defaultValue: 'Overview',
  },
  [ContentId.Owner]: {
    id: ContentId.Owner,
    defaultValue: 'Owner',
  },
  [ContentId.Pagination]: {
    id: ContentId.Pagination,
    defaultValue: 'Pagination',
  },
  [ContentId.Paid]: {
    id: ContentId.Paid,
    defaultValue: PointOfSalePaymentState.Paid,
  },
  [ContentId.PaidOffline]: {
    id: ContentId.PaidOffline,
    defaultValue: 'Paid Offline',
  },
  [ContentId.Pairs]: {
    id: ContentId.Pairs,
    defaultValue: 'Pairs',
  },
  [ContentId.Paper]: { id: ContentId.Paper, defaultValue: 'Paper' },
  [ContentId.ParentListing]: {
    id: ContentId.ParentListing,
    defaultValue: 'Parent Listing',
  },
  [ContentId.PartiallyPaid]: {
    id: ContentId.PartiallyPaid,
    defaultValue: 'Partially Paid',
  },
  [ContentId.Password]: {
    id: ContentId.Password,
    defaultValue: 'Password',
  },
  [ContentId.PastWeek]: {
    id: ContentId.PastWeek,
    defaultValue: 'Past Week',
  },
  [ContentId.PastMonth]: {
    id: ContentId.PastMonth,
    defaultValue: 'Past Month',
  },
  [ContentId.PastYear]: {
    id: ContentId.PastYear,
    defaultValue: 'Past Year',
  },
  [ContentId.Payment]: { id: ContentId.Payment, defaultValue: 'Payment' },
  [ContentId.PaymentMethod]: {
    id: ContentId.PaymentMethod,
    defaultValue: 'Payment Method',
  },
  [ContentId.PaymentPending]: {
    id: ContentId.PaymentPending,
    defaultValue: 'Payment Pending',
  },
  [ContentId.PaymentPostEvent]: {
    id: ContentId.PaymentPostEvent,
    defaultValue: 'Payment Post Event',
  },
  [ContentId.Payments]: { id: ContentId.Payments, defaultValue: 'Payments' },
  [ContentId.PaymentDate]: {
    id: ContentId.PaymentDate,
    defaultValue: 'Payment Date',
  },
  [ContentId.PaymentAccount]: {
    id: ContentId.PaymentAccount,
    defaultValue: 'Payment Account',
  },
  [ContentId.PaymentMethods]: {
    id: ContentId.PaymentMethods,
    defaultValue: 'Payment Methods',
  },
  [ContentId.PaymentMethodUsed]: {
    id: ContentId.PaymentMethodUsed,
    defaultValue: 'Payment Method Used',
  },
  [ContentId.PaymentState]: {
    id: ContentId.PaymentState,
    defaultValue: 'Payment State',
  },
  [ContentId.PastEvents]: {
    id: ContentId.PastEvents,
    defaultValue: EventTimeFrameFilter.Past,
  },
  [ContentId.PaymentType]: {
    id: ContentId.PaymentType,
    defaultValue: 'Payment Type',
  },
  [ContentId.PaymentUponSaleConfirmation]: {
    id: ContentId.PaymentUponSaleConfirmation,
    defaultValue: 'Payment Upon Sale Confirmation',
  },
  [ContentId.PaymentUponDelivery]: {
    id: ContentId.PaymentUponDelivery,
    defaultValue: 'Payment Upon Delivery',
  },
  [ContentId.PdfExt]: {
    id: ContentId.PdfExt,
    defaultValue: 'PDF (.pdf)',
  },
  [ContentId.Pending]: {
    id: ContentId.Pending,
    defaultValue: 'Pending',
  },
  [ContentId.PendingConfirmation]: {
    id: ContentId.PendingConfirmation,
    defaultValue: PointOfSaleSaleStatus.PendingConfirmation,
  },
  [ContentId.PendingFulfillment]: {
    id: ContentId.PendingFulfillment,
    defaultValue: PointOfSaleSaleStatus.PendingFulfillment,
  },
  [ContentId.PendingPickup]: {
    id: ContentId.PendingPickup,
    defaultValue: 'Pending Pickup',
  },
  [ContentId.PendingQC]: {
    id: ContentId.PendingQC,
    defaultValue: 'Pending Quality Control',
  },
  [ContentId.PendingRefulfillment]: {
    id: ContentId.PendingRefulfillment,
    defaultValue: 'Pending Re-fulfillment',
  },
  [ContentId.PendingRejection]: {
    id: ContentId.PendingRejection,
    defaultValue: PointOfSaleSaleStatus.PendingRejection,
  },
  [ContentId.PendingReview]: {
    id: ContentId.PendingReview,
    defaultValue: 'Pending Review',
  },
  [ContentId.PendingSourcing]: {
    id: ContentId.PendingSourcing,
    defaultValue: 'Pending Sourcing',
  },
  [ContentId.PeopleWithAccess]: {
    id: ContentId.PeopleWithAccess,
    defaultValue: 'People with access',
  },
  [ContentId.Percentage]: {
    id: ContentId.Percentage,
    defaultValue: 'Percentage',
  },
  [ContentId.Performer]: {
    id: ContentId.Performer,
    defaultValue: 'Performer',
  },
  [ContentId.PerformerAndVenue]: {
    id: ContentId.PerformerAndVenue,
    defaultValue: 'Performer/Venue',
  },
  [ContentId.PerformerAtVenue]: {
    id: ContentId.PerformerAtVenue,
    defaultValue: 'Performer at Venue',
  },
  [ContentId.Performers]: {
    id: ContentId.Performers,
    defaultValue: EventGroupingType.Performer,
  },
  [ContentId.Permission]: {
    id: ContentId.Permission,
    defaultValue: 'Permission',
  },
  [ContentId.Permission_AddSeatSaver]: {
    id: ContentId.Permission_AddSeatSaver,
    defaultValue: 'Add Placeholder Inventory',
  },
  [ContentId.Permission_AddTagType]: {
    id: ContentId.Permission_AddTagType,
    defaultValue: 'Add Tag Type',
  },
  [ContentId.Permission_ConfirmTransferWithoutProof]: {
    id: ContentId.Permission_ConfirmTransferWithoutProof,
    defaultValue: 'Confirm Transfer without Proof',
  },
  [ContentId.Permission_ConfigurePricing]: {
    id: ContentId.Permission_ConfigurePricing,
    defaultValue: 'Configure Price Settings',
  },
  [ContentId.Permission_CreateOfflineSale]: {
    id: ContentId.Permission_CreateOfflineSale,
    defaultValue: 'Add Offline Sales',
  },
  [ContentId.Permission_ManageOfflineSale]: {
    id: ContentId.Permission_ManageOfflineSale,
    defaultValue: 'Manage Offline Sales',
  },
  [ContentId.Permission_ResetFulfillment]: {
    id: ContentId.Permission_ResetFulfillment,
    defaultValue: 'Reset Fulfillment',
  },
  [ContentId.Permission_UpdateTags]: {
    id: ContentId.Permission_UpdateTags,
    defaultValue: 'Update Tags',
  },
  [ContentId.Permission_CreateReports]: {
    id: ContentId.Permission_CreateReports,
    defaultValue: 'Create Reports',
  },
  [ContentId.Permission_UpdateReports]: {
    id: ContentId.Permission_UpdateReports,
    defaultValue: 'Update Reports',
  },
  [ContentId.Permission_UpdateReportsOwned]: {
    id: ContentId.Permission_UpdateReportsOwned,
    defaultValue: 'Update Own Reports',
  },
  [ContentId.Permission_ViewAll]: {
    id: ContentId.Permission_ViewAll,
    defaultValue: 'View All',
  },
  [ContentId.Permission_ViewExternalMarketplaceAttributes]: {
    id: ContentId.Permission_ViewExternalMarketplaceAttributes,
    defaultValue: 'View External Marketplace Attributes',
  },
  [ContentId.Permission_ViewInventoryReports]: {
    id: ContentId.Permission_ViewInventoryReports,
    defaultValue: 'View Inventory Reports',
  },
  [ContentId.Permission_ViewRecent]: {
    id: ContentId.Permission_ViewRecent,
    defaultValue: 'View Recent',
  },
  [ContentId.Permission_ViewRecentFromExternalMarketplace]: {
    id: ContentId.Permission_ViewRecentFromExternalMarketplace,
    defaultValue: 'View Recent from External Marketplaces',
  },
  [ContentId.Permission_CreateForExternalMarketplaces]: {
    id: ContentId.Permission_CreateForExternalMarketplaces,
    defaultValue: 'Create for External Marketplaces',
  },
  [ContentId.Permission_ManageHolds]: {
    id: ContentId.Permission_ManageHolds,
    defaultValue: 'Manage Holds',
  },
  [ContentId.Permission_ViewProceeds]: {
    id: ContentId.Permission_ViewProceeds,
    defaultValue: 'View Proceeds',
  },
  [ContentId.Permission_ViewRecentProceeds]: {
    id: ContentId.Permission_ViewRecentProceeds,
    defaultValue: 'View Proceeds for Recent Sales',
  },
  [ContentId.Permission_ViewCostAndMargin]: {
    id: ContentId.Permission_ViewCostAndMargin,
    defaultValue: 'View Cost and Margin',
  },
  [ContentId.Permission_ViewPaymentState]: {
    id: ContentId.Permission_ViewPaymentState,
    defaultValue: 'View Payment State',
  },
  [ContentId.Permission_ViewFulfillmentArtifacts]: {
    id: ContentId.Permission_ViewFulfillmentArtifacts,
    defaultValue: 'View Fulfillment Artifacts',
  },
  [ContentId.Permission_ViewAllMarketplaceAttributes]: {
    id: ContentId.Permission_ViewAllMarketplaceAttributes,
    defaultValue: 'View All Marketplace Attributes',
  },
  [ContentId.Permission_SetPrice]: {
    id: ContentId.Permission_SetPrice,
    defaultValue: 'Set Price',
  },
  [ContentId.Permission_BroadcastUnbroadcast]: {
    id: ContentId.Permission_BroadcastUnbroadcast,
    defaultValue: 'Broadcast/Unbroadcast',
  },
  [ContentId.Permission_BroadcastUnbroadcastOnPurchasedBy]: {
    id: ContentId.Permission_BroadcastUnbroadcastOnPurchasedBy,
    defaultValue: 'Broadcast/Unbroadcast purchased listings (Purchaser)',
  },
  [ContentId.Permission_UpdateInfo]: {
    id: ContentId.Permission_UpdateInfo,
    defaultValue: 'Update Info',
  },
  [ContentId.Permission_Administration]: {
    id: ContentId.Permission_Administration,
    defaultValue: 'Administration',
  },
  [ContentId.Permission_Create]: {
    id: ContentId.Permission_Create,
    defaultValue: 'Create',
  },
  [ContentId.Permission_ViewSalesReports]: {
    id: ContentId.Permission_ViewSalesReports,
    defaultValue: 'View Sales Reports',
  },
  [ContentId.Permissions]: {
    id: ContentId.Permissions,
    defaultValue: 'Permissions',
  },
  [ContentId.PermissionsRequiredMessage]: {
    id: ContentId.PermissionsRequiredMessage,
    defaultValue: 'At least one permission is required.',
  },
  [ContentId.PerPayment]: {
    id: ContentId.PerPayment,
    defaultValue: 'Per payment',
  },
  [ContentId.Phone]: {
    id: ContentId.Phone,
    defaultValue: 'Phone',
  },
  [ContentId.PreDelivered]: {
    id: ContentId.PreDelivered,
    defaultValue: 'Pre-Delivered',
  },
  [ContentId.Premium]: {
    id: ContentId.Premium,
    defaultValue: 'Premium',
  },
  [ContentId.Preview]: {
    id: ContentId.Preview,
    defaultValue: 'Preview',
  },
  [ContentId.Price]: {
    id: ContentId.Price,
    defaultValue: 'Price',
  },
  [ContentId.PriceAboveCeiling]: {
    id: ContentId.PriceAboveCeiling,
    defaultValue: 'Price Above Ceiling',
  },
  [ContentId.PriceBelowFloor]: {
    id: ContentId.PriceBelowFloor,
    defaultValue: 'Price Below Floor',
  },
  [ContentId.PriceBelowMaxAllowedDrop]: {
    id: ContentId.PriceBelowMaxAllowedDrop,
    defaultValue: 'Price Below Max Allowed Drop',
  },
  [ContentId.OneDayBeforeEvent]: {
    id: ContentId.OneDayBeforeEvent,
    defaultValue: '24 hours before event',
  },
  [ContentId.OneWeekBeforeEvent]: {
    id: ContentId.OneWeekBeforeEvent,
    defaultValue: '1 week before event',
  },
  [ContentId.PriceBy]: {
    id: ContentId.PriceBy,
    defaultValue: 'price by ',
  },
  [ContentId.PriceByMarketplace]: {
    id: ContentId.PriceByMarketplace,
    defaultValue: 'Price by Marketplace',
  },
  [ContentId.PricedBy]: {
    id: ContentId.PricedBy,
    defaultValue: 'Priced By',
  },
  [ContentId.PriceMustBeBetweenFloorAndCeiling]: {
    id: ContentId.PriceMustBeBetweenFloorAndCeiling,
    defaultValue: 'Price must be between Price Floor and Ceiling.',
  },
  [ContentId.PriceCeiling]: {
    id: ContentId.PriceCeiling,
    defaultValue: 'Price Ceiling',
  },
  [ContentId.PriceEqualsLastPrice]: {
    id: ContentId.PriceEqualsLastPrice,
    defaultValue: 'Price Equals Last Price',
  },
  [ContentId.PriceFloor]: {
    id: ContentId.PriceFloor,
    defaultValue: 'Price Floor',
  },
  [ContentId.Pricer]: {
    id: ContentId.Pricer,
    defaultValue: 'Pricer',
  },
  [ContentId.Prices]: {
    id: ContentId.Prices,
    defaultValue: 'Prices',
  },
  [ContentId.PriceZeroUnbroadcastWarning]: {
    id: ContentId.PriceZeroUnbroadcastWarning,
    defaultValue:
      'Setting the price to zero will unbroadcast the listing. Continue?',
  },
  [ContentId.Pricing]: {
    id: ContentId.Pricing,
    defaultValue: 'Pricing',
  },
  [ContentId.PricingDetails]: {
    id: ContentId.PricingDetails,
    defaultValue: 'Pricing Details',
  },
  [ContentId.PricingHistory]: {
    id: ContentId.PricingHistory,
    defaultValue: 'Pricing History',
  },
  [ContentId.PricingProtection]: {
    id: ContentId.PricingProtection,
    defaultValue: 'Pricing Protection',
  },
  [ContentId.Private]: {
    id: ContentId.Private,
    defaultValue: 'Private',
  },
  [ContentId.PrivateNote]: {
    id: ContentId.PrivateNote,
    defaultValue: 'Private note',
  },
  [ContentId.Proceeds]: {
    id: ContentId.Proceeds,
    defaultValue: 'Proceeds',
  },
  [ContentId.ProceedsFloor]: {
    id: ContentId.ProceedsFloor,
    defaultValue: 'Proceeds Floor',
  },
  [ContentId.ProceedsCeiling]: {
    id: ContentId.ProceedsCeiling,
    defaultValue: 'Proceeds Ceiling',
  },
  [ContentId.ProceedsPerTicket]: {
    id: ContentId.ProceedsPerTicket,
    defaultValue: 'Proceeds Per Ticket',
  },
  [ContentId.Processing]: {
    id: ContentId.Processing,
    defaultValue: 'Processing',
  },
  [ContentId.ProcessingFulfillment]: {
    id: ContentId.ProcessingFulfillment,
    defaultValue: PointOfSaleSaleStatus.ProcessingFulfillment,
  },
  [ContentId.Processor]: {
    id: ContentId.Processor,
    defaultValue: 'Processor',
  },
  [ContentId.Profit]: {
    id: ContentId.Profit,
    defaultValue: 'Profit',
  },
  [ContentId.ProofOfPurchase]: {
    id: ContentId.ProofOfPurchase,
    defaultValue: 'Proof of Purchase',
  },
  [ContentId.ProofOfPurchasedInstruction]: {
    id: ContentId.ProofOfPurchasedInstruction,
    defaultValue: 'Please upload receipts or email for proof of purchase',
  },
  [ContentId.ProofOfPurchasedRequired]: {
    id: ContentId.ProofOfPurchasedRequired,
    defaultValue: 'Proof of Purchase Required',
  },
  [ContentId.ProofOfPurchasedRequiredMessage]: {
    id: ContentId.ProofOfPurchasedRequiredMessage,
    defaultValue:
      'Upload proof that you have neither row nor seat information available to you',
  },
  [ContentId.ProvideTransferImageProofInstruction]: {
    id: ContentId.ProvideTransferImageProofInstruction,
    defaultValue: 'Upload Screenshot of Transfer Confirmation',
  },
  [ContentId.ProvideTransferProofIdInstruction]: {
    id: ContentId.ProvideTransferProofIdInstruction,
    defaultValue: 'Provide proof of transfer ID or invoice ID (e.g. AXS)',
  },
  [ContentId.ProvideTransferUrlInstruction]: {
    id: ContentId.ProvideTransferUrlInstruction,
    defaultValue: 'Provide URLs where the buyer can accept the tickets.',
  },
  [ContentId.Purchase]: {
    id: ContentId.Purchase,
    defaultValue: 'Purchase',
  },
  [ContentId.PurchaseCancelled]: {
    id: ContentId.PurchaseCancelled,
    defaultValue: 'Purchase Cancelled',
  },
  [ContentId.PurchasedBy]: {
    id: ContentId.PurchasedBy,
    defaultValue: 'Purchased By',
  },
  [ContentId.PurchaseDetails]: {
    id: ContentId.PurchaseDetails,
    defaultValue: 'Purchase Details',
  },
  [ContentId.PurchaseHaveNoSales]: {
    id: ContentId.PurchaseHaveNoSales,
    defaultValue: 'This purchase has no sales.',
  },
  [ContentId.PurchaseHaveNoListings]: {
    id: ContentId.PurchaseHaveNoListings,
    defaultValue: 'This purchase has no listings.',
  },
  [ContentId.PurchaseId]: {
    id: ContentId.PurchaseId,
    defaultValue: 'Purchase Id',
  },
  [ContentId.Purchases]: {
    id: ContentId.Purchases,
    defaultValue: MainRoute.Purchases,
  },
  [ContentId.PurchaseDate]: {
    id: ContentId.PurchaseDate,
    defaultValue: 'Purchase Date',
  },
  [ContentId.PurchaseDates]: {
    id: ContentId.PurchaseDates,
    defaultValue: 'Purchase Dates',
  },
  [ContentId.Purchases_AddTagType_Details]: {
    id: ContentId.Purchases_AddTagType_Details,
    defaultValue: 'Permits adding new tag types to purchase orders',
  },
  [ContentId.Purchases_Create_Details]: {
    id: ContentId.Purchases_Create_Details,
    defaultValue: 'Create a new purchase',
  },
  [ContentId.Purchases_Edit_Details]: {
    id: ContentId.Purchases_Edit_Details,
    defaultValue: 'Edit any accessible purchase',
  },
  [ContentId.Purchases_EditOwned_Details]: {
    id: ContentId.Purchases_EditOwned_Details,
    defaultValue: 'Edit purchases made by the user',
  },
  [ContentId.Purchases_UpdateTags_Details]: {
    id: ContentId.Purchases_UpdateTags_Details,
    defaultValue: 'Permits updating of tags on purchase orders',
  },
  [ContentId.Purchases_UpdateTagsOwned_Details]: {
    id: ContentId.Purchases_UpdateTagsOwned_Details,
    defaultValue: 'Permits updateing tags on purchase made by the user',
  },
  [ContentId.Purchases_ViewAll_Details]: {
    id: ContentId.Purchases_ViewAll_Details,
    defaultValue: 'See all purchases including historical ones',
  },
  [ContentId.Purchases_ViewOwned_Details]: {
    id: ContentId.Purchases_ViewOwned_Details,
    defaultValue: 'View purchases made by the user',
  },
  [ContentId.Purchases_ViewRecent_Details]: {
    id: ContentId.Purchases_ViewRecent_Details,
    defaultValue:
      'See only purchases for future events or events less than 3 months old',
  },
  [ContentId.PurchaseSettings]: {
    id: ContentId.PurchaseSettings,
    defaultValue: 'Purchase Settings',
  },
  [ContentId.PurchaseSettingsAllVisible]: {
    id: ContentId.PurchaseSettingsAllVisible,
    defaultValue: 'Edit Settings for All Visible Purchases',
  },
  [ContentId.QRCode]: {
    id: ContentId.QRCode,
    defaultValue: 'QR Code',
  },
  [ContentId.QualityControl]: {
    id: ContentId.QualityControl,
    defaultValue: 'Quality Control',
  },
  [ContentId.QualityControlState]: {
    id: ContentId.QualityControlState,
    defaultValue: 'Quality Control State',
  },
  [ContentId.QualityOfComparableListings]: {
    id: ContentId.QualityOfComparableListings,
    defaultValue: 'Quality of Comparable Listings',
  },
  [ContentId.Quantity]: {
    id: ContentId.Quantity,
    defaultValue: 'Quantity',
  },
  [ContentId.QuantityAbbreviated]: {
    id: ContentId.QuantityAbbreviated,
    defaultValue: 'Qty',
  },
  [ContentId.QuantityAdjustedVenueWideQualityScore]: {
    id: ContentId.QuantityAdjustedVenueWideQualityScore,
    defaultValue: 'Quantity Adjusted Venue-wide Quality Score',
  },
  [ContentId.QuantityAvailablePremiums]: {
    id: ContentId.QuantityAvailablePremiums,
    defaultValue: 'Quantity Available Premiums',
  },
  [ContentId.QuantityScore]: {
    id: ContentId.QuantityScore,
    defaultValue: 'Quantity Score',
  },
  [ContentId.QueuedForPayment]: {
    id: ContentId.QueuedForPayment,
    defaultValue: PointOfSalePaymentState.Queued,
  },
  [ContentId.QuickFilters]: {
    id: ContentId.QuickFilters,
    defaultValue: 'Quick Filters',
  },
  [ContentId.RandomizeRankForEachEvents]: {
    id: ContentId.RandomizeRankForEachEvents,
    defaultValue: 'Randomize ranks for each event',
  },
  [ContentId.Rank]: {
    id: ContentId.Rank,
    defaultValue: 'Rank',
  },
  [ContentId.Readonly]: {
    id: ContentId.Readonly,
    defaultValue: 'Readonly',
  },
  [ContentId.RecentlyCategorized]: {
    id: ContentId.RecentlyCategorized,
    defaultValue: 'Recently Categorized',
  },
  [ContentId.RecentlySold]: {
    id: ContentId.RecentlySold,
    defaultValue: 'Recently Sold',
  },
  [ContentId.RecentSales]: {
    id: ContentId.RecentSales,
    defaultValue: 'Recent Sales',
  },
  [ContentId.Recommended]: {
    id: ContentId.Recommended,
    defaultValue: 'Recommended',
  },
  [ContentId.Reduce]: {
    id: ContentId.Reduce,
    defaultValue: 'Reduce',
  },
  [ContentId.Reference]: {
    id: ContentId.Reference,
    defaultValue: 'Reference',
  },
  [ContentId.Refunded]: {
    id: ContentId.Refunded,
    defaultValue: 'Refunded',
  },
  [ContentId.RefundNeeded]: {
    id: ContentId.RefundNeeded,
    defaultValue: 'Refund Needed',
  },
  [ContentId.Rejected]: {
    id: ContentId.Rejected,
    defaultValue: 'Rejected',
  },
  [ContentId.RejectSale]: {
    id: ContentId.RejectSale,
    defaultValue: 'Reject Sale',
  },
  [ContentId.RejectSaleWarning]: {
    id: ContentId.RejectSaleWarning,
    defaultValue: 'You are about to reject this sale. Continue?',
  },
  [ContentId.ReleaseNotes]: {
    id: ContentId.ReleaseNotes,
    defaultValue: 'Release Notes',
  },
  [ContentId.Reload]: {
    id: ContentId.Reload,
    defaultValue: 'Reload',
  },
  [ContentId.Remaining]: {
    id: '',
    defaultValue: 'Remaining',
  },
  [ContentId.Remove]: {
    id: ContentId.Remove,
    defaultValue: 'Remove',
  },
  [ContentId.RemoveAccess]: {
    id: ContentId.RemoveAccess,
    defaultValue: 'Remove Access',
  },
  [ContentId.RemoveBarcodes]: {
    id: ContentId.RemoveBarcodes,
    defaultValue: 'Remove Barcodes',
  },
  [ContentId.RemoveHold]: {
    id: ContentId.RemoveHold,
    defaultValue: 'Remove Hold',
  },
  [ContentId.RemoveHoldAll]: {
    id: ContentId.RemoveHoldAll,
    defaultValue: 'Remove Hold for All',
  },
  [ContentId.RemoveHoldSelected]: {
    id: ContentId.RemoveHoldSelected,
    defaultValue: 'Remove Hold for Selected',
  },
  [ContentId.RemoveHoldVisible]: {
    id: ContentId.RemoveHoldVisible,
    defaultValue: 'Remove Hold for All Visible Listings',
  },
  [ContentId.RemovePayment]: {
    id: ContentId.RemovePayment,
    defaultValue: 'Remove Payment',
  },
  [ContentId.RemovePredeliveredArtifacts]: {
    id: ContentId.RemovePredeliveredArtifacts,
    defaultValue: 'Remove Predelivered Artifacts',
  },
  [ContentId.RemovePdfs]: {
    id: ContentId.RemovePdfs,
    defaultValue: 'Remove e-Ticket Documents',
  },
  [ContentId.RemovePredeliveredArtifactsAll]: {
    id: ContentId.RemovePredeliveredArtifactsAll,
    defaultValue: 'Remove Predelivery for All',
  },
  [ContentId.RemovePredeliveredArtifactsAllVisible]: {
    id: ContentId.RemovePredeliveredArtifactsAllVisible,
    defaultValue: 'Remove Predelivery for All Visible Listings',
  },
  [ContentId.RemovePredeliveredArtifactsSelected]: {
    id: ContentId.RemovePredeliveredArtifactsSelected,
    defaultValue: 'Remove Predelivery for Selected',
  },
  [ContentId.RemovePredeliveredArtifactsWarning]: {
    id: ContentId.RemovePredeliveredArtifactsWarning,
    defaultValue:
      'Removing pre-delivered artifacts applies only to unsold tickets.',
  },
  [ContentId.RemoveTag]: {
    id: ContentId.RemoveTag,
    defaultValue: 'Remove Tag',
  },
  [ContentId.RemoveTagValues]: {
    id: ContentId.RemoveTagValues,
    defaultValue: 'Remove Tag Values',
  },
  [ContentId.RemoveTagValuesAllVisible]: {
    id: ContentId.RemoveTagValuesAllVisible,
    defaultValue: 'Remove Tag Values for All Visible Listings',
  },
  [ContentId.RemoveTagValuesSelected]: {
    id: ContentId.RemoveTagValuesSelected,
    defaultValue: 'Remove Tag Values for Selected',
  },
  [ContentId.RenewAccessToken]: {
    id: ContentId.RenewAccessToken,
    defaultValue: 'Renew access token',
  },
  [ContentId.Reply]: {
    id: ContentId.Reply,
    defaultValue: 'Reply',
  },
  [ContentId.Reporter]: {
    id: ContentId.Reporter,
    defaultValue: 'Reporter',
  },
  [ContentId.Reporting]: {
    id: ContentId.Reporting,
    defaultValue: 'Reporting',
  },
  [ContentId.ReportName]: {
    id: ContentId.ReportName,
    defaultValue: 'Report Name',
  },
  [ContentId.Reports]: {
    id: ContentId.Reports,
    defaultValue: 'Reports',
  },
  [ContentId.Reports_CreateReports_Details]: {
    id: ContentId.Reports_CreateReports_Details,
    defaultValue: 'Create new reports across various modules',
  },
  [ContentId.Reports_UpdateReports_Details]: {
    id: ContentId.Reports_UpdateReports_Details,
    defaultValue: 'Edit existing reports',
  },
  [ContentId.Reports_UpdateReportsOwned_Details]: {
    id: ContentId.Reports_UpdateReportsOwned_Details,
    defaultValue: 'Edit reports created or owned by the user',
  },
  [ContentId.Reports_ViewAll_Details]: {
    id: ContentId.Reports_ViewAll_Details,
    defaultValue: 'View all reports, across all modules',
  },
  [ContentId.Reports_ViewInventoryReports_Details]: {
    id: ContentId.Reports_ViewInventoryReports_Details,
    defaultValue: 'View inventory-related reports',
  },
  [ContentId.Reports_ViewSalesReports_Details]: {
    id: ContentId.Reports_ViewSalesReports_Details,
    defaultValue: 'View sales-related reports',
  },
  [ContentId.ReprocessEmail]: {
    id: ContentId.ReprocessEmail,
    defaultValue: 'Reprocess email',
  },
  [ContentId.RequestEvent]: {
    id: ContentId.RequestEvent,
    defaultValue: 'Request Event',
  },
  [ContentId.RequestPasswordResetEmail]: {
    id: ContentId.RequestPasswordResetEmail,
    defaultValue: 'Request password reset email',
  },
  [ContentId.Required]: {
    id: ContentId.Required,
    defaultValue: 'Required',
  },
  [ContentId.RequiredFieldsError]: {
    id: ContentId.RequiredFieldsError,
    defaultValue: 'Some required fields are empty.',
  },
  [ContentId.Reset]: {
    id: ContentId.Reset,
    defaultValue: 'Reset',
  },
  [ContentId.ResetAll]: {
    id: ContentId.ResetAll,
    defaultValue: 'Reset All',
  },
  [ContentId.ResetFulfillment]: {
    id: ContentId.ResetFulfillment,
    defaultValue: 'Reset Fulfillment',
  },
  [ContentId.ResetFulfillmentWarning]: {
    id: ContentId.ResetFulfillmentWarning,
    defaultValue:
      'You are about to put this sale back to Pending Re-fulfillment. Continue?',
  },
  [ContentId.ResetListings]: {
    id: ContentId.ResetListings,
    defaultValue: 'Reset Listings',
  },
  [ContentId.ResetListingsDeleteWarning]: {
    id: ContentId.ResetListingsDeleteWarning,
    defaultValue:
      'This action will delete the all listings that were merged or split (unless those listings were merged with other tickets from other purchases, then they will be changed to no longer contain tickets from this purchase) and new listings will be created from the original ticket groups. All listings that are created from the original ticket groups will remain unchanged.',
  },
  [ContentId.ResetSettings]: {
    id: ContentId.ResetSettings,
    defaultValue: 'Reset Settings',
  },
  [ContentId.RestorePurchase]: {
    id: ContentId.RestorePurchase,
    defaultValue: 'Restore Purchase',
  },
  [ContentId.RestrictTo]: {
    id: ContentId.RestrictTo,
    defaultValue: 'Restrict to',
  },
  [ContentId.RestrictToSameZone]: {
    id: ContentId.RestrictToSameZone,
    defaultValue: 'Restrict to Same Zone',
  },
  [ContentId.RestrictSelection]: {
    id: ContentId.RestrictSelection,
    defaultValue: 'Restrict selection',
  },
  [ContentId.RetrievingBuyersInfo]: {
    id: ContentId.RetrievingBuyersInfo,
    defaultValue: "Retrieving buyer's information...",
  },
  [ContentId.Retry]: {
    id: ContentId.Retry,
    defaultValue: 'Retry',
  },
  [ContentId.ReuploadBarcodes]: {
    id: ContentId.ReuploadBarcodes,
    defaultValue: 'Re-upload Barcodes',
  },
  [ContentId.ReuploadETickets]: {
    id: ContentId.ReuploadETickets,
    defaultValue: 'Re-upload E-Tickets',
  },
  [ContentId.ReuploadProof]: {
    id: ContentId.ReuploadProof,
    defaultValue: 'Re-upload Proof',
  },
  [ContentId.ReuploadQRCodes]: {
    id: ContentId.ReuploadQRCodes,
    defaultValue: 'Re-upload QR Codes',
  },
  [ContentId.ReuploadTicketUrls]: {
    id: ContentId.ReuploadTicketUrls,
    defaultValue: 'Re-upload Ticket URLs',
  },
  [ContentId.ReuploadTicketIds]: {
    id: ContentId.ReuploadTicketIds,
    defaultValue: 'Re-upload Ticket IDs',
  },
  [ContentId.Revenue]: {
    id: ContentId.Revenue,
    defaultValue: 'Revenue',
  },
  [ContentId.ReviewAllocation]: {
    id: ContentId.ReviewAllocation,
    defaultValue: 'Review Allocation',
  },
  [ContentId.RightNow]: {
    id: ContentId.RightNow,
    defaultValue: 'Right Now',
  },
  [ContentId.Role]: {
    id: ContentId.Role,
    defaultValue: 'Role',
  },
  [ContentId.Role_AutoFulfill_Details]: {
    id: ContentId.Role_AutoFulfill_Details,
    defaultValue: 'Auto process that handles fulfilling sales',
  },
  [ContentId.Role_AutoPO_Details]: {
    id: ContentId.Role_AutoPO_Details,
    defaultValue: 'Auto process that handles purchase',
  },
  [ContentId.Role_AutoPricer_Details]: {
    id: ContentId.Role_AutoPricer_Details,
    defaultValue: 'Auto process that handles pricing',
  },
  [ContentId.Role_Buyer_Details]: {
    id: ContentId.Role_Buyer_Details,
    defaultValue: 'One who handles buying tickets and creating purchases',
  },
  [ContentId.Role_Finance_Details]: {
    id: ContentId.Role_Finance_Details,
    defaultValue: 'One who handles finance',
  },
  [ContentId.Role_Fulfillment_Details]: {
    id: ContentId.Role_Fulfillment_Details,
    defaultValue: 'One who fulfills sales',
  },
  [ContentId.Role_Owner_Details]: {
    id: ContentId.Role_Owner_Details,
    defaultValue: ' Owner/CEO of a seller account',
  },
  [ContentId.Role_Pricer_Details]: {
    id: ContentId.Role_Pricer_Details,
    defaultValue: 'One who handles pricing on inventory',
  },
  [ContentId.Role_Processor_Details]: {
    id: ContentId.Role_Processor_Details,
    defaultValue:
      'One who handles inventory and sales for an API seller account',
  },
  [ContentId.Role_Reporter_Details]: {
    id: ContentId.Role_Reporter_Details,
    defaultValue: 'One who handles reporting',
  },
  [ContentId.Roles]: {
    id: ContentId.Roles,
    defaultValue: 'Roles',
  },
  [ContentId.RoleWithUsersCannotBeDeleted]: {
    id: ContentId.RoleWithUsersCannotBeDeleted,
    defaultValue:
      'This role cannot be deleted as there are users currently assigned to it. Reassign users before attempting deletion.',
  },
  [ContentId.Row]: {
    id: ContentId.Row,
    defaultValue: 'Row',
  },
  [ContentId.RowExtrapolation]: {
    id: ContentId.RowExtrapolation,
    defaultValue: 'Row Extrapolation',
  },
  [ContentId.RowScore]: {
    id: ContentId.RowScore,
    defaultValue: 'Row Score',
  },
  [ContentId.Sale]: {
    id: ContentId.Sale,
    defaultValue: 'Sale',
  },
  [ContentId.SaleCancelled]: {
    id: ContentId.SaleCancelled,
    defaultValue: 'Sale Cancelled',
  },
  [ContentId.SaleConflictWarning]: {
    id: ContentId.SaleConflictWarning,
    defaultValue:
      'This sale was recently updated. Please reload to see the lastest sale information and make any necessary changes.',
  },

  [ContentId.SaleDate]: {
    id: ContentId.SaleDate,
    defaultValue: 'Sale Date',
  },
  [ContentId.SaleId]: {
    id: ContentId.SaleId,
    defaultValue: 'Sale Id',
  },
  [ContentId.Sales]: { id: ContentId.Sales, defaultValue: 'Sales' },
  [ContentId.Sales_AddTagType_Details]: {
    id: ContentId.Sales_AddTagType_Details,
    defaultValue: 'Permits adding new tag types to sales',
  },
  [ContentId.Sales_ConfirmReject_Details]: {
    id: ContentId.Sales_ConfirmReject_Details,
    defaultValue: 'Can confirm (allocate) or reject all accessible sales',
  },
  [ContentId.Sales_ConfirmRejectOnIsFulfillerOf_Details]: {
    id: ContentId.Sales_ConfirmRejectOnIsFulfillerOf_Details,
    defaultValue: 'Confirm/Reject sales where the user is the fulfiller',
  },
  [ContentId.Sales_ConfirmRejectOnIsPricerOf_Details]: {
    id: ContentId.Sales_ConfirmRejectOnIsPricerOf_Details,
    defaultValue:
      'Confirm/Reject sales of inventory where the user is the pricer',
  },
  [ContentId.Sales_ConfirmRejectOnPurchasedBy_Details]: {
    id: ContentId.Sales_ConfirmRejectOnPurchasedBy_Details,
    defaultValue: 'Confirm/Reject sales of inventory that the user purchased',
  },
  [ContentId.Sales_ConfirmTransferWithoutProof_Details]: {
    id: ContentId.Sales_ConfirmTransferWithoutProof_Details,
    defaultValue: 'Confirm transfer without proof for accessible sales',
  },
  [ContentId.Sales_CreateForExternalMarketplaces_Details]: {
    id: ContentId.Sales_CreateForExternalMarketplaces_Details,
    defaultValue: 'Can create sales in POS for any non-StubHub marketplace',
  },
  [ContentId.Sales_CreateOfflineSale_Details]: {
    id: ContentId.Sales_CreateOfflineSale_Details,
    defaultValue: 'Create offline sales',
  },
  [ContentId.Sales_Fulfill_Details]: {
    id: ContentId.Sales_Fulfill_Details,
    defaultValue: 'Can fulfill all accessible sales',
  },
  [ContentId.Sales_FulfillOnIsFulfillerOf_Details]: {
    id: ContentId.Sales_FulfillOnIsFulfillerOf_Details,
    defaultValue:
      'Enables fulfilling sales actions where the user is the fulfiller',
  },
  [ContentId.Sales_FulfillOnPricerOf_Details]: {
    id: ContentId.Sales_FulfillOnPricerOf_Details,
    defaultValue:
      'Fulfill sales of inventory where the user priced the inventory',
  },
  [ContentId.Sales_FulfillOnPurchasedBy_Details]: {
    id: ContentId.Sales_FulfillOnPurchasedBy_Details,
    defaultValue: 'Fulfill sales of inventory that the user purchased',
  },
  [ContentId.Sales_ManageHold_Details]: {
    id: ContentId.Sales_ManageHold_Details,
    defaultValue: 'Create/edit/delete holds of sales',
  },
  [ContentId.Sales_ManageOfflineSale_Details]: {
    id: ContentId.Sales_ManageOfflineSale_Details,
    defaultValue: 'Update/delete offline sales',
  },
  [ContentId.Sales_ManageOfflineSaleCreatedBy_Details]: {
    id: ContentId.Sales_ManageOfflineSaleCreatedBy_Details,
    defaultValue: 'Update/delete offline sales that the user created',
  },
  [ContentId.Sales_ResetFulfillment_Details]: {
    id: ContentId.Sales_ResetFulfillment_Details,
    defaultValue: 'Reset fulfillment on accessible fulfilled sales',
  },
  [ContentId.Sales_UpdateSaleOnPurchasedBy_Details]: {
    id: ContentId.Sales_UpdateSaleOnPurchasedBy_Details,
    defaultValue: 'Update sales of inventory that the user purchased',
  },
  [ContentId.Sales_UpdateSalesInfo_Details]: {
    id: ContentId.Sales_UpdateSalesInfo_Details,
    defaultValue: 'Allows updating sales information',
  },
  [ContentId.Sales_UpdateSalesInfoOnIsFulfillerOf_Details]: {
    id: ContentId.Sales_UpdateSalesInfoOnIsFulfillerOf_Details,
    defaultValue:
      'Allows updating sales information where the user is the fulfiller',
  },
  [ContentId.Sales_UpdateSalesInfoOnIsPricerOf_Details]: {
    id: ContentId.Sales_UpdateSalesInfoOnIsPricerOf_Details,
    defaultValue:
      'Allows updating sales information where the user priced the inventory',
  },
  [ContentId.Sales_UpdateTags_Details]: {
    id: ContentId.Sales_UpdateTags_Details,
    defaultValue: 'Permits updating of tags on sales',
  },
  [ContentId.Sales_UpdateTagsOnIsFulfillerOf_Details]: {
    id: ContentId.Sales_UpdateTagsOnIsFulfillerOf_Details,
    defaultValue: 'Update tags of sales where the user is the fulfiller',
  },
  [ContentId.Sales_UpdateTagsOnIsPricerOf_Details]: {
    id: ContentId.Sales_UpdateTagsOnIsPricerOf_Details,
    defaultValue:
      'Update tags of sales of inventory where the user is the pricer',
  },
  [ContentId.Sales_UpdateTagsOnPurchasedBy_Details]: {
    id: ContentId.Sales_UpdateTagsOnPurchasedBy_Details,
    defaultValue: 'Update tags of sales of inventory that the user purchased',
  },
  [ContentId.Sales_ViewAll_Details]: {
    id: ContentId.Sales_ViewAll_Details,
    defaultValue: 'See all sales including historical ones',
  },
  [ContentId.Sales_ViewCostAndMargin_Details]: {
    id: ContentId.Sales_ViewCostAndMargin_Details,
    defaultValue: 'See cost and margins on all accessible sales',
  },
  [ContentId.Sales_ViewFulfillmentArtifacts_Details]: {
    id: ContentId.Sales_ViewFulfillmentArtifacts_Details,
    defaultValue: 'See fulfillment artifacts from a sale',
  },
  [ContentId.Sales_ViewFulfillmentArtifactsOnIsFulfillerOf_Details]: {
    id: ContentId.Sales_ViewFulfillmentArtifactsOnIsFulfillerOf_Details,
    defaultValue: 'View fulfillment artifacts on sales that the user fufilled',
  },
  [ContentId.Sales_ViewFulfillmentArtifactsOnIsPricerOf_Details]: {
    id: ContentId.Sales_ViewFulfillmentArtifactsOnIsPricerOf_Details,
    defaultValue:
      'View fulfillment artifacts on sales of inventory where the user is the pricer',
  },
  [ContentId.Sales_ViewFulfillmentArtifactsOnPurchasedBy_Details]: {
    id: ContentId.Sales_ViewFulfillmentArtifactsOnPurchasedBy_Details,
    defaultValue:
      'View fulfillment artifacts on sales of inventory that the user purchased',
  },
  [ContentId.Sales_ViewPaymentState_Details]: {
    id: ContentId.Sales_ViewPaymentState_Details,
    defaultValue: 'See payment state on all accessible sales',
  },
  [ContentId.Sales_ViewProceeds_Details]: {
    id: ContentId.Sales_ViewProceeds_Details,
    defaultValue: 'See proceeds of all accessible sales',
  },
  [ContentId.Sales_ViewRecent_Details]: {
    id: ContentId.Sales_ViewRecent_Details,
    defaultValue:
      'See only sales for future events or events less than 3 months old',
  },
  [ContentId.Sales_ViewRecentFromExternalMarketplace_Details]: {
    id: ContentId.Sales_ViewRecentFromExternalMarketplace_Details,
    defaultValue:
      'See only sales for future events or events less than 3 months old that are not StubHub sales',
  },
  [ContentId.Sales_ViewRecentProceeds_Details]: {
    id: ContentId.Sales_ViewRecentProceeds_Details,
    defaultValue: 'See proceeds of all accessible sales within the last 7 days',
  },
  [ContentId.Sales_ViewSalesOnIsFulfillerOf_Details]: {
    id: ContentId.Sales_ViewSalesOnIsFulfillerOf_Details,
    defaultValue: 'View sales where the user is the fulfiller',
  },
  [ContentId.Sales_ViewSalesOnIsPricerOf_Details]: {
    id: ContentId.Sales_ViewSalesOnIsPricerOf_Details,
    defaultValue: 'Permits viewing sales where the user is the pricer',
  },
  [ContentId.Sales_ViewSalesOnPurchasedBy_Details]: {
    id: ContentId.Sales_ViewSalesOnPurchasedBy_Details,
    defaultValue: 'View sales of inventory that the user purchased',
  },
  [ContentId.SalesAndLift]: {
    id: ContentId.SalesAndLift,
    defaultValue: 'Sales & Lift',
  },
  [ContentId.SalesAndMargin]: {
    id: ContentId.SalesAndMargin,
    defaultValue: 'Sales & Margin',
  },
  [ContentId.SaleSettings]: {
    id: ContentId.SaleSettings,
    defaultValue: 'Sale Settings',
  },
  [ContentId.SaleSettingsAllVisible]: {
    id: ContentId.SaleSettingsAllVisible,
    defaultValue: 'Edit General Settings for All Visible Sales',
  },
  [ContentId.SalesReports]: {
    id: ContentId.SalesReports,
    defaultValue: MainRoute.Sales,
  },
  [ContentId.SalesSummary]: {
    id: ContentId.SalesSummary,
    defaultValue: 'Sales Summary',
  },
  [ContentId.SaleStatus]: {
    id: ContentId.SaleStatus,
    defaultValue: 'Sale Status',
  },
  [ContentId.SaleTicketTypeBulkEditWarning]: {
    id: ContentId.SaleTicketTypeBulkEditWarning,
    defaultValue:
      'Ticket type change will only apply to sales that happened on StubHub and have not been fulfilled or rejected.',
  },
  [ContentId.SaleTicketTypeWarning]: {
    id: ContentId.SaleTicketTypeWarning,
    defaultValue:
      'You can only change the ticket type if your sale happened on StubHub and has not been fulfilled or rejected',
  },
  [ContentId.SameTicketsForAllEventsTitle]: {
    id: ContentId.SameTicketsForAllEventsTitle,
    defaultValue: 'Multiple Events Selected',
  },
  [ContentId.SameTicketsForAllEventsHeader]: {
    id: ContentId.SameTicketsForAllEventsHeader,
    defaultValue:
      'Do all your selected events share the same ticket information?',
  },
  [ContentId.SameTicketsForAllEventsMessage1]: {
    id: ContentId.SameTicketsForAllEventsMessage1,
    defaultValue: "Click 'Yes' to enter the same tickets for all the events.",
  },
  [ContentId.SameTicketsForAllEventsMessage2]: {
    id: ContentId.SameTicketsForAllEventsMessage2,
    defaultValue: "Click 'No' to enter the tickets for each event separately.",
  },
  [ContentId.SameZone]: {
    id: ContentId.SameZone,
    defaultValue: 'Same zone',
  },
  [ContentId.Save]: {
    id: ContentId.Save,
    defaultValue: 'Save',
  },
  [ContentId.SaveBarcodes]: {
    id: ContentId.SaveBarcodes,
    defaultValue: 'Save Barcodes',
  },
  [ContentId.SavePricingStrategyInfo]: {
    id: ContentId.SavePricingStrategyInfo,
    defaultValue:
      'Save your pricing settings as a strategy to easily reuse it.',
  },
  [ContentId.SaveThisFilter]: {
    id: ContentId.SaveThisFilter,
    defaultValue: 'Save this filter',
  },
  [ContentId.SaveTicketIds]: {
    id: ContentId.SaveTicketIds,
    defaultValue: 'Save Ticket IDs',
  },
  [ContentId.SaveTicketUrls]: {
    id: ContentId.SaveTicketUrls,
    defaultValue: 'Save Ticket URLs',
  },
  [ContentId.Score]: {
    id: ContentId.Score,
    defaultValue: 'Score',
  },
  [ContentId.ScreenshotsOfTransfer]: {
    id: ContentId.ScreenshotsOfTransfer,
    defaultValue: 'Screenshots of the transfer confirmation.',
  },
  [ContentId.Search]: { id: ContentId.Search, defaultValue: 'Search' },
  [ContentId.SearchEmptyResult]: {
    id: ContentId.SearchEmptyResult,
    defaultValue: 'No events were found based on your query.',
  },
  [ContentId.SearchEventPlaceholderText]: {
    id: ContentId.SearchEventPlaceholderText,
    defaultValue: 'Search for event, venue, or performer',
  },
  [ContentId.SearchForMessagePlaceholder]: {
    id: ContentId.SearchForMessagePlaceholder,
    defaultValue: 'Search for message',
  },
  [ContentId.SearchOrEnter]: {
    id: ContentId.SearchOrEnter,
    defaultValue: 'Search or Enter',
  },
  [ContentId.SearchVenueConfigOrEvent]: {
    id: ContentId.SearchVenueConfigOrEvent,
    defaultValue: 'Search performer at venue or event',
  },
  [ContentId.Season]: { id: ContentId.Season, defaultValue: 'Season' },
  [ContentId.Seat]: { id: ContentId.Seat, defaultValue: 'Seat' },
  [ContentId.SeatFrom]: {
    id: ContentId.SeatFrom,
    defaultValue: 'Seat From',
  },
  [ContentId.SeatHeatmap]: {
    id: ContentId.SeatHeatmap,
    defaultValue: 'Seat Heatmap',
  },
  [ContentId.Seating]: { id: ContentId.Seating, defaultValue: 'Seating' },
  [ContentId.SeatingInfoNotMatchStubHub]: {
    id: ContentId.SeatingInfoNotMatchStubHub,
    defaultValue: "Seating information not found in StubHub's event map.",
  },
  [ContentId.SeatingUnmapped]: {
    id: ContentId.SeatingUnmapped,
    defaultValue: 'Seating Unmapped',
  },
  [ContentId.Seats]: { id: ContentId.Seats, defaultValue: 'Seats' },
  [ContentId.SeatSaver]: {
    id: ContentId.SeatSaver,
    defaultValue: 'Seat Saver',
  },
  [ContentId.SeatSaverPurchaseMessage]: {
    id: ContentId.SeatSaverPurchaseMessage,
    defaultValue:
      'Seat Saver have no purchases until a purchase is automatically or manually allocated.',
  },
  [ContentId.SeatScore]: {
    id: ContentId.SeatScore,
    defaultValue: 'Seat Score',
  },
  [ContentId.SeatTo]: {
    id: ContentId.SeatTo,
    defaultValue: 'Seat To',
  },
  [ContentId.SeatTraits]: {
    id: ContentId.SeatTraits,
    defaultValue: 'Seat Traits',
  },
  [ContentId.SeatAllocationChangeWarning]: {
    id: ContentId.SeatAllocationChangeWarning,
    defaultValue:
      'If you change section or row, you might need to confirm the change with the marketplace first.',
  },
  [ContentId.SeatIncrementType]: {
    id: ContentId.SeatIncrementType,
    defaultValue: 'Seat Increment Type',
  },
  [ContentId.SectionOnly]: {
    id: ContentId.SectionOnly,
    defaultValue: 'Section Only',
  },
  [ContentId.SeeAll]: {
    id: ContentId.SeeAll,
    defaultValue: 'See All',
  },
  [ContentId.SeeMoreEllipses]: {
    id: ContentId.SeeMoreEllipses,
    defaultValue: 'See More...',
  },
  [ContentId.Select]: {
    id: ContentId.Select,
    defaultValue: 'Select',
  },
  [ContentId.SelectAll]: {
    id: ContentId.SelectAll,
    defaultValue: 'Select All',
  },
  [ContentId.SelectDatePlaceholder]: {
    id: ContentId.SelectDatePlaceholder,
    defaultValue: 'Select date',
  },
  [ContentId.SelectedListing]: {
    id: ContentId.SelectedListing,
    defaultValue: 'Selected Listing',
  },
  [ContentId.SelectedSection]: {
    id: ContentId.SelectedSection,
    defaultValue: 'Selected Sections',
  },
  [ContentId.SelectedSections]: {
    id: ContentId.SelectedSections,
    defaultValue: 'Selected Sections',
  },
  [ContentId.SelectFilter]: {
    id: ContentId.SelectFilter,
    defaultValue: 'Select filter',
  },
  [ContentId.SelectInboxPlaceholder]: {
    id: ContentId.SelectInboxPlaceholder,
    defaultValue: 'Select inbox',
  },
  [ContentId.SelectListingsToMerge]: {
    id: ContentId.SelectListingsToMerge,
    defaultValue: 'Select Listings to Merge',
  },
  [ContentId.SelectMultiple]: {
    id: ContentId.SelectMultiple,
    defaultValue: 'Select Multiple',
  },
  [ContentId.SelectNone]: {
    id: ContentId.SelectNone,
    defaultValue: 'Select None',
  },
  [ContentId.SelectSeatFrom]: {
    id: ContentId.SelectSeatFrom,
    defaultValue: 'Select Seat From',
  },
  [ContentId.SelectSections]: {
    id: ContentId.SelectSections,
    defaultValue: 'Select Sections',
  },
  [ContentId.SelectSectionsToPriceAgainst]: {
    id: ContentId.SelectSectionsToPriceAgainst,
    defaultValue: 'Select sections to price against',
  },
  [ContentId.SelectSortPlaceholder]: {
    id: ContentId.SelectSortPlaceholder,
    defaultValue: 'Select sort',
  },
  [ContentId.SelectSplitType]: {
    id: ContentId.SelectSplitType,
    defaultValue: 'Select split type',
  },
  [ContentId.SelectSubjectPlaceholder]: {
    id: ContentId.SelectSubjectPlaceholder,
    defaultValue: 'Select subject',
  },
  [ContentId.SelectTags]: {
    id: ContentId.SelectTags,
    defaultValue: 'Select Tags',
  },
  [ContentId.SelectTemplate]: {
    id: ContentId.SelectTemplate,
    defaultValue: 'Select Template',
  },
  [ContentId.SelectUsersAndGroups]: {
    id: ContentId.SelectUsersAndGroups,
    defaultValue: 'Select Users and Groups',
  },
  [ContentId.SelectValueType]: {
    id: ContentId.SelectValueType,
    defaultValue: 'Select Value Type',
  },
  [ContentId.SelectVariables]: {
    id: ContentId.SelectVariables,
    defaultValue: 'Select Variables',
  },
  [ContentId.SelectVendor]: {
    id: ContentId.SelectVendor,
    defaultValue: 'Select Vendor',
  },
  [ContentId.SelectVendorShowAccounts]: {
    id: ContentId.SelectVendorShowAccounts,
    defaultValue: 'Select a vendor to show the accounts',
  },
  [ContentId.SellAsTicketTypePriority]: {
    id: ContentId.SellAsTicketTypePriority,
    defaultValue: 'Sell as Ticket Type Priority',
  },
  [ContentId.SellerAccount]: {
    id: ContentId.SellerAccount,
    defaultValue: 'Seller Account',
  },
  [ContentId.Section]: {
    id: ContentId.Section,
    defaultValue: 'Section',
  },
  [ContentId.Sections]: {
    id: ContentId.Sections,
    defaultValue: 'Sections',
  },
  [ContentId.SeeAllResultsFor]: {
    id: ContentId.SeeAllResultsFor,
    defaultValue: 'See all results for',
  },
  [ContentId.SelectAccountToSignIn]: {
    id: ContentId.SelectAccountToSignIn,
    defaultValue: 'Select the account to sign-in',
  },
  [ContentId.SelectAccountToSwitch]: {
    id: ContentId.SelectAccountToSwitch,
    defaultValue: 'Select the account to switch to',
  },
  [ContentId.Selected]: {
    id: ContentId.Selected,
    defaultValue: 'Selected',
  },
  [ContentId.SelectYourFilters]: {
    id: ContentId.SelectYourFilters,
    defaultValue: 'Select your filters',
  },
  [ContentId.SearchAndSelectAnEvent]: {
    id: ContentId.SearchAndSelectAnEvent,
    defaultValue: 'Find and select an event.',
  },
  [ContentId.SellerAccount_Admin_Details]: {
    id: ContentId.SellerAccount_Admin_Details,
    defaultValue:
      'Able to perform account managements (add/remove users and roles, edit marketplace syncs, etc)',
  },
  [ContentId.SellerFee]: {
    id: ContentId.SellerFee,
    defaultValue: 'Seller Fee',
  },
  [ContentId.SellerOwnTickets]: {
    id: ContentId.SellerOwnTickets,
    defaultValue: 'I own these tickets',
  },
  [ContentId.SellerSupportCase]: {
    id: ContentId.SellerSupportCase,
    defaultValue: 'Seller Support Case',
  },
  [ContentId.SellerSupportCase_Create_Details]: {
    id: ContentId.SellerSupportCase_Create_Details,
    defaultValue:
      'Create a new seller support case and create a new reply for a support case',
  },
  [ContentId.SellerSupportCase_View_Details]: {
    id: ContentId.SellerSupportCase_View_Details,
    defaultValue: 'View existing seller support cases and all the replies',
  },
  [ContentId.SellThroughRate]: {
    id: ContentId.SellThroughRate,
    defaultValue: 'Sell-Through Rate',
  },
  [ContentId.SellThroughRateLowercase]: {
    id: ContentId.SellThroughRateLowercase,
    defaultValue: 'Sell-through rate',
  },
  [ContentId.SellThroughRateSubtitle]: {
    id: ContentId.SellThroughRateSubtitle,
    defaultValue: 'Price and broadcast to meet your targets.',
  },
  [ContentId.SendInhandDateToMarketplace]: {
    id: ContentId.SendInhandDateToMarketplace,
    defaultValue: 'Send In hand Date to Marketplace',
  },
  [ContentId.SetAsPaid]: {
    id: ContentId.SetAsPaid,
    defaultValue: 'Set as Paid',
  },
  [ContentId.SetPriority]: {
    id: ContentId.SetPriority,
    defaultValue: 'Set Priority',
  },
  [ContentId.Settings]: {
    id: ContentId.Settings,
    defaultValue: 'Settings',
  },
  [ContentId.Share]: {
    id: ContentId.Share,
    defaultValue: 'Share',
  },
  [ContentId.ShareReport]: {
    id: ContentId.ShareReport,
    defaultValue: 'Share report',
  },
  [ContentId.Sharing]: {
    id: ContentId.Sharing,
    defaultValue: 'Sharing',
  },
  [ContentId.SharingReportDescription]: {
    id: ContentId.SharingReportDescription,
    defaultValue: 'Add people or groups to share access to this report',
  },
  [ContentId.Shipment]: {
    id: ContentId.Shipment,
    defaultValue: 'Shipment',
  },
  [ContentId.Shipping]: {
    id: ContentId.Shipping,
    defaultValue: 'Shipping',
  },
  [ContentId.ShippingDeadline]: {
    id: ContentId.ShippingDeadline,
    defaultValue: 'Shipping Deadline',
  },
  [ContentId.ShowAdvancedSettings]: {
    id: ContentId.ShowAdvancedSettings,
    defaultValue: 'Show advanced settings',
  },
  [ContentId.ShowAllListings]: {
    id: ContentId.ShowAllListings,
    defaultValue: 'Show All Listings',
  },
  [ContentId.ShownQuantity]: {
    id: ContentId.ShownQuantity,
    defaultValue: 'Shown Quantity',
  },
  [ContentId.ShowParkingTickets]: {
    id: ContentId.ShowParkingTickets,
    defaultValue: 'Show Parking Tickets',
  },
  [ContentId.ShowRelatedListings]: {
    id: ContentId.ShowRelatedListings,
    defaultValue: 'Show Related Listings',
  },
  [ContentId.ShowSimpleSettings]: {
    id: ContentId.ShowSimpleSettings,
    defaultValue: 'Show simple settings...',
  },
  [ContentId.ShowVoidCancelledPurchases]: {
    id: ContentId.ShowVoidCancelledPurchases,
    defaultValue: 'Show Void/Cancelled Purchases',
  },
  [ContentId.SignIn]: {
    id: ContentId.SignIn,
    defaultValue: 'Sign in',
  },
  [ContentId.SignInAgain]: {
    id: ContentId.SignInAgain,
    defaultValue: 'Sign in again',
  },
  [ContentId.SignOut]: {
    id: ContentId.SignOut,
    defaultValue: 'Sign out',
  },
  [ContentId.SignupDate]: {
    id: ContentId.SignupDate,
    defaultValue: 'Signup date',
  },
  [ContentId.Simple]: {
    id: ContentId.Simple,
    defaultValue: 'Simple',
  },
  [ContentId.SinglePayment]: {
    id: ContentId.SinglePayment,
    defaultValue: 'Single Payment',
  },
  [ContentId.SingleValueOnly]: {
    id: ContentId.SingleValueOnly,
    defaultValue: 'Single Value Only',
  },
  [ContentId.SiteTheme]: {
    id: ContentId.SiteTheme,
    defaultValue: 'Site Theme',
  },
  [ContentId.SkipLogic]: {
    id: ContentId.SkipLogic,
    defaultValue: 'Skip Step',
  },
  [ContentId.SMPWallet]: {
    id: ContentId.SMPWallet,
    defaultValue: 'SMPWallet',
  },
  [ContentId.Sold]: {
    id: ContentId.Sold,
    defaultValue: 'Sold',
  },
  [ContentId.SoldATP]: {
    id: ContentId.SoldATP,
    defaultValue: 'Sold ATP',
  },
  [ContentId.SoldAverageTicketPrice]: {
    id: ContentId.SoldAverageTicketPrice,
    defaultValue: 'Sold Average Ticket Price',
  },
  [ContentId.SoldCost]: {
    id: ContentId.SoldCost,
    defaultValue: 'Sold Cost',
  },
  [ContentId.SoldAs]: {
    id: ContentId.SoldAs,
    defaultValue: 'Sold As',
  },
  [ContentId.SoldProceeds]: {
    id: ContentId.SoldProceeds,
    defaultValue: 'Sold Proceeds',
  },
  [ContentId.SoldProfit]: {
    id: ContentId.SoldProfit,
    defaultValue: 'Sold Profit',
  },
  [ContentId.SoldQty]: {
    id: ContentId.SoldQty,
    defaultValue: 'Sold Qty',
  },
  [ContentId.SoldQuantity]: {
    id: ContentId.SoldQuantity,
    defaultValue: 'Sold Quantity',
  },
  [ContentId.SoldSeats]: {
    id: ContentId.SoldSeats,
    defaultValue: 'Sold Seats',
  },
  [ContentId.SoldTicketCost]: {
    id: ContentId.SoldTicketCost,
    defaultValue: 'Sold Ticket Cost',
  },
  [ContentId.SoldTickets]: {
    id: ContentId.SoldTickets,
    defaultValue: 'Sold Tickets',
  },
  [ContentId.SoldToday]: {
    id: ContentId.SoldToday,
    defaultValue: 'Sold Today',
  },
  [ContentId.SoldLast7Days]: {
    id: ContentId.SoldLast7Days,
    defaultValue: 'Sold Last 7 Days',
  },
  [ContentId.SomethingWentWrong]: {
    id: ContentId.SomethingWentWrong,
    defaultValue: 'Something Went Wrong',
  },
  [ContentId.SomethingWentWrongTryAgain]: {
    id: ContentId.SomethingWentWrongTryAgain,
    defaultValue: 'Something went wrong. Please try again!',
  },
  [ContentId.Sort]: {
    id: ContentId.Sort,
    defaultValue: 'Sort',
  },
  [ContentId.SortBy]: {
    id: ContentId.SortBy,
    defaultValue: 'Sort By',
  },
  [ContentId.SortByDate]: {
    id: ContentId.SortByDate,
    defaultValue: EventSort.Date,
  },
  [ContentId.SortByTitle]: {
    id: ContentId.SortByTitle,
    defaultValue: EventSort.MainDisplay,
  },
  [ContentId.SortAtoZ]: {
    id: ContentId.SortAtoZ,
    defaultValue: 'Sort by: A to Z',
  },
  [ContentId.SortZtoA]: {
    id: ContentId.SortZtoA,
    defaultValue: 'Sort by: Z to A',
  },
  [ContentId.SortOldToNew]: {
    id: ContentId.SortOldToNew,
    defaultValue: 'Sort by: Oldest to Newest',
  },
  [ContentId.SortNewToOld]: {
    id: ContentId.SortNewToOld,
    defaultValue: 'Sort by: Newest to Oldest',
  },
  [ContentId.SpecialCharactersNotAllowed]: {
    id: ContentId.SpecialCharactersNotAllowed,
    defaultValue:
      'Special characters (quotes, brackets) are not allowed for reporting tags',
  },
  [ContentId.SpecificSections]: {
    id: ContentId.SpecificSections,
    defaultValue: 'Specific sections',
  },
  [ContentId.Split]: {
    id: ContentId.Split,
    defaultValue: 'Split',
  },
  [ContentId.SplitListing]: {
    id: ContentId.SplitListing,
    defaultValue: 'Split Listings',
  },
  [ContentId.SplitType]: {
    id: ContentId.SplitType,
    defaultValue: 'Split Type',
  },
  [ContentId.SponsoredListings]: {
    id: ContentId.SponsoredListings,
    defaultValue: 'Sponsored Listings',
  },
  [ContentId.Sports]: {
    id: ContentId.Sports,
    defaultValue: 'Sports',
  },
  [ContentId.SpotifyPopularity]: {
    id: ContentId.SpotifyPopularity,
    defaultValue: 'Spotify Popularity',
  },
  [ContentId.Stakes]: {
    id: ContentId.Stakes,
    defaultValue: 'Stakes',
  },
  [ContentId.Standing]: {
    id: ContentId.Standing,
    defaultValue: 'Standing',
  },
  [ContentId.StandardDeviations]: {
    id: ContentId.StandardDeviations,
    defaultValue: 'Standard Deviations',
  },
  [ContentId.StateProvince]: {
    id: ContentId.StateProvince,
    defaultValue: 'State/Province',
  },
  [ContentId.Status]: {
    id: ContentId.Status,
    defaultValue: 'Status',
  },
  [ContentId.Stop]: {
    id: ContentId.Stop,
    defaultValue: 'Stop',
  },
  [ContentId.STR]: {
    id: ContentId.STR,
    defaultValue: 'STR',
  },
  [ContentId.StrategyName]: {
    id: ContentId.StrategyName,
    defaultValue: 'Strategy name',
  },
  [ContentId.String]: {
    id: ContentId.String,
    defaultValue: 'String',
  },
  [ContentId.StubhubEventIdKnown]: {
    id: ContentId.StubhubEventIdKnown,
    defaultValue: 'Is the Stubhub Event Id Known?',
  },
  [ContentId.StubHubLoginRequired]: {
    id: ContentId.StubHubLoginRequired,
    defaultValue:
      'The user you entered needs a StubHub login to use this application.',
  },
  [ContentId.SubGenre]: {
    id: ContentId.SubGenre,
    defaultValue: 'Sub-Genre',
  },
  [ContentId.Subject]: {
    id: ContentId.Subject,
    defaultValue: 'Subject',
  },
  [ContentId.Succeeded]: {
    id: ContentId.Succeeded,
    defaultValue: 'Succeeded',
  },
  [ContentId.Success]: {
    id: ContentId.Success,
    defaultValue: 'Success',
  },
  [ContentId.Suggestions]: {
    id: ContentId.Suggestions,
    defaultValue: 'Suggestions',
  },
  [ContentId.Support]: {
    id: ContentId.Support,
    defaultValue: 'Support',
  },
  [ContentId.SupportCaseId]: {
    id: ContentId.SupportCaseId,
    defaultValue: 'Support Case Id',
  },
  [ContentId.SuppressFor1Hour]: {
    id: ContentId.SuppressFor1Hour,
    defaultValue: 'Suppress for one hour',
  },
  [ContentId.SimilarSections]: {
    id: ContentId.SimilarSections,
    defaultValue: 'Similar Sections',
  },
  [ContentId.SuspiciousPriceChange]: {
    id: ContentId.SuspiciousPriceChange,
    defaultValue: 'Suspicious Price Change',
  },
  [ContentId.SuspiciousPriceChangeWarning]: {
    id: ContentId.SuspiciousPriceChangeWarning,
    defaultValue:
      "You've made a price change that differs more than 25% from the original price. Are you sure?",
  },
  [ContentId.Summary]: {
    id: ContentId.Summary,
    defaultValue: 'Summary',
  },
  [ContentId.SwapTag]: {
    id: ContentId.SwapTag,
    defaultValue: 'Swap Tag',
  },
  [ContentId.SwapTagValues]: {
    id: ContentId.SwapTagValues,
    defaultValue: 'Swap Tag Values',
  },
  [ContentId.SwapTagValuesAllVisible]: {
    id: ContentId.SwapTagValuesAllVisible,
    defaultValue: 'Swap Tag Values for All Visible Listings',
  },
  [ContentId.SwapTagValuesSelected]: {
    id: ContentId.SwapTagValuesSelected,
    defaultValue: 'Swap Tag Values for Selected',
  },
  [ContentId.SwitchAccount]: {
    id: ContentId.SwitchAccount,
    defaultValue: 'Switch account',
  },
  [ContentId.SyncCenter]: {
    id: ContentId.SyncCenter,
    defaultValue: 'Sync Center',
  },
  [ContentId.SyncWithSystemSettings]: {
    id: ContentId.SyncWithSystemSettings,
    defaultValue: 'Sync with system settings',
  },
  [ContentId.System]: {
    id: ContentId.System,
    defaultValue: 'System',
  },
  [ContentId.Tag]: {
    id: ContentId.Tag,
    defaultValue: 'Tag',
  },
  [ContentId.Tags]: {
    id: ContentId.Tags,
    defaultValue: 'Tags',
  },
  [ContentId.TagsToIncludeInColumns]: {
    id: ContentId.TagsToIncludeInColumns,
    defaultValue: 'Tags to include in columns',
  },
  [ContentId.TakeMyParentListingAnd]: {
    id: ContentId.TakeMyParentListingAnd,
    defaultValue: 'Take my parent listing and:',
  },
  [ContentId.Target]: {
    id: ContentId.Target,
    defaultValue: 'Target',
  },
  [ContentId.TaxExempt]: {
    id: ContentId.TaxExempt,
    defaultValue: 'Tax Exempt',
  },
  [ContentId.TaxPaid]: {
    id: ContentId.TaxPaid,
    defaultValue: 'Tax Paid',
  },
  [ContentId.Text]: {
    id: ContentId.Text,
    defaultValue: 'Text',
  },
  [ContentId.ThanMineAnd]: {
    id: ContentId.ThanMineAnd,
    defaultValue: 'than mine, and',
  },
  [ContentId.Theatre]: {
    id: ContentId.Theatre,
    defaultValue: 'Theatre',
  },
  [ContentId.Theme]: {
    id: ContentId.Theme,
    defaultValue: 'Theme',
  },
  [ContentId.Then]: {
    id: ContentId.Then,
    defaultValue: 'then ',
  },
  [ContentId.ThisEvent]: {
    id: ContentId.ThisEvent,
    defaultValue: 'This Event',
  },
  [ContentId.ThisMonth]: {
    id: ContentId.ThisMonth,
    defaultValue: 'This Month',
  },
  [ContentId.ThisWasTaxExempt]: {
    id: ContentId.ThisWasTaxExempt,
    defaultValue: 'This was tax exempt',
  },
  [ContentId.ThisWeek]: {
    id: ContentId.ThisWeek,
    defaultValue: 'This Week',
  },
  [ContentId.ThisYear]: {
    id: ContentId.ThisYear,
    defaultValue: 'This Year',
  },
  [ContentId.Ticket]: {
    id: ContentId.Ticket,
    defaultValue: 'Ticket',
  },
  [ContentId.TicketGroup]: {
    id: ContentId.TicketGroup,
    defaultValue: 'Ticket Group',
  },
  [ContentId.TicketGroupHasErrors]: {
    id: ContentId.TicketGroupHasErrors,
    defaultValue: 'Ticket Group has one or more invalid inputs.',
  },
  [ContentId.TicketGroups]: {
    id: ContentId.TicketGroups,
    defaultValue: 'Ticket Groups',
  },
  [ContentId.TicketGroups_ViewAll_Details]: {
    id: ContentId.TicketGroups_ViewAll_Details,
    defaultValue: "View all seller's ticket groups",
  },
  [ContentId.TicketIdAlreadyInUsed]: {
    id: ContentId.TicketIdAlreadyInUsed,
    defaultValue: 'Warning - ticket ID is already used by another ticket.',
  },
  [ContentId.TicketmasterBarcodeTransferInstruction]: {
    id: ContentId.TicketmasterBarcodeTransferInstruction,
    defaultValue: 'Transfered via API',
  },
  [ContentId.TicketmasterBarcodeTransferViaOneTicketInstruction]: {
    id: ContentId.TicketmasterBarcodeTransferViaOneTicketInstruction,
    defaultValue: "Transfer using 1Ticket's API ($1 per transfer)",
  },
  [ContentId.TicketmasterBarcodeTransferViaTradeDeskInstruction]: {
    id: ContentId.TicketmasterBarcodeTransferViaTradeDeskInstruction,
    defaultValue: "Transfer using Ticketmaster's API ($1 per transfer)",
  },
  [ContentId.TicketmasterTradeDeskApi]: {
    id: ContentId.TicketmasterTradeDeskApi,
    defaultValue: 'Ticketmaster TradeDesk API',
  },
  [ContentId.Tickets]: {
    id: ContentId.Tickets,
    defaultValue: 'Tickets',
  },
  [ContentId.TicketsAvailable]: {
    id: ContentId.TicketsAvailable,
    defaultValue: 'Tickets Available',
  },
  [ContentId.TicketsListedAndSold]: {
    id: ContentId.TicketsListedAndSold,
    defaultValue: 'Tickets Listed & Sold',
  },
  [ContentId.TicketsOnPlatform]: {
    id: ContentId.TicketsOnPlatform,
    defaultValue: 'Tickets on Platform',
  },
  [ContentId.TicketsPurchased]: {
    id: ContentId.TicketsPurchased,
    defaultValue: 'Tickets Purchased',
  },
  [ContentId.TicketType]: {
    id: ContentId.TicketType,
    defaultValue: 'Ticket Type',
  },
  [ContentId.TicketTypePriority]: {
    id: ContentId.TicketTypePriority,
    defaultValue: 'Ticket Type Priority',
  },
  [ContentId.TicketTypePriorityForSelected]: {
    id: ContentId.TicketTypePriorityForSelected,
    defaultValue: 'Ticket Type Priority for Selected',
  },
  [ContentId.TicketTypePriorityForAll]: {
    id: ContentId.TicketTypePriorityForAll,
    defaultValue: 'Ticket Type Priority for All',
  },
  [ContentId.TicketUrlAlreadyInUsed]: {
    id: ContentId.TicketUrlAlreadyInUsed,
    defaultValue: 'Warning - URL is already used by another ticket.',
  },
  [ContentId.TileView]: {
    id: ContentId.TileView,
    defaultValue: 'Tile View',
  },
  [ContentId.Time]: {
    id: ContentId.Time,
    defaultValue: 'Time',
  },
  [ContentId.TitleName]: {
    id: ContentId.TitleName,
    defaultValue: 'Title/Name',
  },
  [ContentId.To]: {
    id: ContentId.To,
    defaultValue: 'To',
  },
  [ContentId.ToBeListed]: {
    id: ContentId.ToBeListed,
    defaultValue: 'To be listed',
  },
  [ContentId.ToBeDelisted]: {
    id: ContentId.ToBeDelisted,
    defaultValue: 'To be delisted',
  },
  [ContentId.ToDate]: {
    id: ContentId.ToDate,
    defaultValue: 'To Date',
  },
  [ContentId.Today]: {
    id: ContentId.Today,
    defaultValue: 'Today',
  },
  [ContentId.Total]: {
    id: ContentId.Total,
    defaultValue: 'Total',
  },
  /**
   * @deprecated Use `ContentId.TotalWebsitePrice`
   */
  [ContentId.TotalAllInPrice]: {
    id: ContentId.TotalAllInPrice,
    defaultValue: 'Total All In',
  },
  [ContentId.TotalATP]: {
    id: ContentId.TotalATP,
    defaultValue: 'Total ATP',
  },
  [ContentId.TotalCost]: {
    id: ContentId.TotalCost,
    defaultValue: 'Total Cost',
  },
  [ContentId.TotalDeliveryCharge]: {
    id: ContentId.TotalDeliveryCharge,
    defaultValue: 'Total Delivery Charge',
  },
  [ContentId.TotalDue]: {
    id: ContentId.TotalDue,
    defaultValue: 'Total Due',
  },
  [ContentId.TotalGrossProfit]: {
    id: ContentId.TotalGrossProfit,
    defaultValue: 'Total Gross Profit',
  },
  [ContentId.TotalListedQuantity]: {
    id: ContentId.TotalListedQuantity,
    defaultValue: 'Total Listed Quantity',
  },
  /**
   * @deprecated Use `ContentId.TotalProceeds`
   */
  [ContentId.TotalListPrice]: {
    id: ContentId.TotalListPrice,
    defaultValue: 'Total List Price',
  },
  [ContentId.TotalNetProceeds]: {
    id: ContentId.TotalNetProceeds,
    defaultValue: 'Total Net Proceeds',
  },
  [ContentId.TotalOriginal]: {
    id: ContentId.TotalOriginal,
    defaultValue: 'Total Original',
  },
  [ContentId.TotalPerTicketCost]: {
    id: ContentId.TotalPerTicketCost,
    defaultValue: 'Total Per-Ticket Cost',
  },
  [ContentId.TotalProceeds]: {
    id: ContentId.TotalProceeds,
    defaultValue: 'Total Proceeds',
  },
  [ContentId.TotalProfit]: {
    id: ContentId.TotalProfit,
    defaultValue: 'Total profit',
  },
  [ContentId.TotalQty]: {
    id: ContentId.TotalQty,
    defaultValue: 'Total Qty',
  },
  [ContentId.TotalQuantity]: {
    id: ContentId.TotalQuantity,
    defaultValue: 'Total Quantity',
  },
  [ContentId.TotalRemaining]: {
    id: ContentId.TotalRemaining,
    defaultValue: 'Total Remaining',
  },
  [ContentId.TotalSoldCost]: {
    id: ContentId.TotalSoldCost,
    defaultValue: 'Total Sold Cost',
  },
  [ContentId.TotalSoldQuantity]: {
    id: ContentId.TotalSoldQuantity,
    defaultValue: 'Total Sold Quantity',
  },
  [ContentId.TotalTicketCost]: {
    id: ContentId.TotalTicketCost,
    defaultValue: 'Total Ticket Cost',
  },
  [ContentId.TotalPurchaseCost]: {
    id: ContentId.TotalPurchaseCost,
    defaultValue: 'Total Purchase Cost',
  },
  [ContentId.TotalTicketEarnings]: {
    id: ContentId.TotalTicketEarnings,
    defaultValue: 'Total ticket earnings',
  },
  [ContentId.TotalTicketQuantity]: {
    id: ContentId.TotalTicketQuantity,
    defaultValue: 'Total Ticket Quantity',
  },
  [ContentId.TotalTickets]: {
    id: ContentId.TotalTickets,
    defaultValue: 'Total Tickets',
  },
  [ContentId.TotalUnitCost]: {
    id: ContentId.TotalUnitCost,
    defaultValue: 'Total Cost',
  },
  /**
   * @deprecated Use `ContentId.TotalUnsoldProceeds`
   */
  [ContentId.TotalUnsoldListPrice]: {
    id: ContentId.TotalUnsoldListPrice,
    defaultValue: 'Total Unsold List Price',
  },
  [ContentId.TotalUnsoldProceeds]: {
    id: ContentId.TotalUnsoldProceeds,
    defaultValue: 'Total Unsold Proceeds',
  },
  [ContentId.TotalUnsoldQuantity]: {
    id: ContentId.TotalUnsoldQuantity,
    defaultValue: 'Total Unsold Quantity',
  },
  [ContentId.TotalValueListed]: {
    id: ContentId.TotalValueListed,
    defaultValue: 'Total Value Listed',
  },
  [ContentId.TotalWebsitePrice]: {
    id: ContentId.TotalWebsitePrice,
    defaultValue: 'Total Website Price',
  },
  [ContentId.TradeDeskResetSummaryTitle]: {
    id: ContentId.TradeDeskResetSummaryTitle,
    defaultValue: 'Your Ticketmaster integration needs to be updated.',
  },
  [ContentId.TradeDeskResetSummaryBody]: {
    id: ContentId.TradeDeskResetSummaryBody,
    defaultValue:
      'Ticketmaster recently changed their account management and token generation process for the TradeDesk API. Take the following steps to ensure your Ticketmaster integration continues to work.',
  },
  [ContentId.TradeDeskResetInstructionsStep1]: {
    id: ContentId.TradeDeskResetInstructionsStep1,
    defaultValue: 'Request password reset code for your TradeDesk account:',
  },
  [ContentId.TradeDeskResetInstructionsStep2]: {
    id: ContentId.TradeDeskResetInstructionsStep2,
    defaultValue: 'Enter reset code received via your email:',
  },
  [ContentId.TradeDeskResetInstructionsStep3]: {
    id: ContentId.TradeDeskResetInstructionsStep3,
    defaultValue: 'Renew your TradeDesk API access token:',
  },
  [ContentId.TradeDeskResetInstructionsStep4]: {
    id: ContentId.TradeDeskResetInstructionsStep4,
    defaultValue: "Done! We'll handle the rest.",
  },
  [ContentId.Transactions]: {
    id: ContentId.Transactions,
    defaultValue: 'Transactions',
  },
  [ContentId.Transfer]: {
    id: ContentId.Transfer,
    defaultValue: 'Transfer',
  },
  [ContentId.TransferAccepted]: {
    id: ContentId.TransferAccepted,
    defaultValue: 'Transfer Accepted',
  },
  [ContentId.TransferExpired]: {
    id: ContentId.TransferExpired,
    defaultValue: 'Transfer Expired',
  },
  [ContentId.TransferId]: {
    id: ContentId.TransferId,
    defaultValue: 'Transfer Id',
  },
  [ContentId.TransferIdNote]: {
    id: ContentId.TransferIdNote,
    defaultValue:
      'Use if you purchased resale tickets. Specify where the tickets will get delivered to so you can find them later. Note: you might not know your Transfer Id until the transfer has happened.',
  },
  [ContentId.TransferFrom]: {
    id: ContentId.TransferFrom,
    defaultValue: 'Transfer From',
  },
  [ContentId.TransferProofId]: {
    id: ContentId.TransferProofId,
    defaultValue: 'Transfer or Invoice ID',
  },
  [ContentId.TransferSent]: {
    id: ContentId.TransferSent,
    defaultValue: 'Transfer Sent',
  },
  [ContentId.TransferTicketsIns]: {
    id: ContentId.TransferTicketsIns,
    defaultValue:
      'Transfer tickets to your buyer & take a screenshot of the confirmation',
  },
  [ContentId.TransferTicketsChoice]: {
    id: ContentId.TransferTicketsChoice,
    defaultValue:
      'How do you want to prove that you have transferred tickets to the buyer?',
  },
  [ContentId.TransferTo]: {
    id: ContentId.TransferTo,
    defaultValue: 'Transfer To',
  },
  [ContentId.TrueFalse]: {
    id: ContentId.TrueFalse,
    defaultValue: 'True/False',
  },
  [ContentId.TurnOffReporting]: {
    id: ContentId.TurnOffReporting,
    defaultValue: 'Turn off reporting',
  },
  [ContentId.TurnOffReportingWarning]: {
    id: ContentId.TurnOffReportingWarning,
    defaultValue:
      'One or more tags are displayed in some of the tables but being turned off. Are you sure?',
  },
  [ContentId.Type]: {
    id: ContentId.Type,
    defaultValue: 'Type',
  },
  [ContentId.UnableToRetrieveBuyersInfo]: {
    id: ContentId.UnableToRetrieveBuyersInfo,
    defaultValue: "Unable to retrieve buyer's information",
  },
  [ContentId.Unbroadcast]: {
    id: ContentId.Unbroadcast,
    defaultValue: 'Unbroadcast',
  },
  [ContentId.UnbroadcastAll]: {
    id: ContentId.UnbroadcastAll,
    defaultValue: 'Unbroadcast All',
  },
  [ContentId.UnbroadcastAllVisible]: {
    id: ContentId.UnbroadcastAllVisible,
    defaultValue: 'Unbroadcast All Visible Listings',
  },
  [ContentId.Unbroadcasted]: {
    id: ContentId.Unbroadcasted,
    defaultValue: 'Unbroadcasted',
  },
  [ContentId.Uncategorized]: {
    id: ContentId.Uncategorized,
    defaultValue: 'Uncategorized',
  },
  [ContentId.Undeliverable]: {
    id: ContentId.Undeliverable,
    defaultValue: 'Undeliverable',
  },
  [ContentId.UndercutAmount]: {
    id: ContentId.UndercutAmount,
    defaultValue: 'Undercut Amount',
  },
  [ContentId.UndercutMode]: {
    id: ContentId.UndercutMode,
    defaultValue: 'Undercut Mode',
  },
  [ContentId.UndercutAbsoluteAmount]: {
    id: ContentId.UndercutAbsoluteAmount,
    defaultValue: 'Undercut Absolute Amount',
  },
  [ContentId.UndercutAbsoluteOrRelativeAmountMustBeSetError]: {
    id: ContentId.UndercutAbsoluteOrRelativeAmountMustBeSetError,
    defaultValue: 'Absolute or Relative Amount must be set',
  },
  [ContentId.UnderCutModePrompt]: {
    id: ContentId.UnderCutModePrompt,
    defaultValue: 'How to determine price?',
  },
  [ContentId.UndercutRelativeAmount]: {
    id: ContentId.UndercutRelativeAmount,
    defaultValue: 'Undercut Relative Amount',
  },
  [ContentId.Ungroup]: {
    id: ContentId.Ungroup,
    defaultValue: 'Ungroup',
  },
  [ContentId.UngroupListingsAll]: {
    id: ContentId.UngroupListingsAll,
    defaultValue: 'Ungroup All Listings',
  },
  [ContentId.UngroupListingsAllVisible]: {
    id: ContentId.UngroupListingsAllVisible,
    defaultValue: 'Ungroup All Visible Listings',
  },
  [ContentId.UngroupListingsConfirmation]: {
    id: ContentId.UngroupListingsConfirmation,
    defaultValue:
      'This action will remove all grouped listings from their groups and any resulting empty group will be deleted.  Are you sure?',
  },
  [ContentId.UngroupListingsSelected]: {
    id: ContentId.UngroupListingsSelected,
    defaultValue: 'Ungroup Selected Listings',
  },
  [ContentId.UniqueId]: {
    id: ContentId.UniqueId,
    defaultValue: 'Unique Id',
  },
  [ContentId.UnitCost]: {
    id: ContentId.UnitCost,
    defaultValue: 'Unit Cost',
  },
  [ContentId.Unpaid]: {
    id: ContentId.Unpaid,
    defaultValue: PointOfSalePaymentState.Unpaid,
  },
  [ContentId.UnableToProcess]: {
    id: ContentId.UnableToProcess,
    defaultValue: 'Unable To Process',
  },
  [ContentId.Unread]: {
    id: ContentId.Unread,
    defaultValue: 'Unread',
  },
  [ContentId.UnbroadcastSelected]: {
    id: ContentId.UnbroadcastSelected,
    defaultValue: 'Unbroadcast Selected',
  },
  [ContentId.UnsavedChanges]: {
    id: ContentId.UnsavedChanges,
    defaultValue: 'Unsaved Changes',
  },
  [ContentId.UnsavedChangesWarning]: {
    id: ContentId.UnsavedChangesWarning,
    defaultValue: 'Leaving now will lose your progress. Continue?',
  },
  [ContentId.Unset]: {
    id: ContentId.Unset,
    defaultValue: 'Unset',
  },
  [ContentId.UnsoldCost]: {
    id: ContentId.UnsoldCost,
    defaultValue: 'Unsold Cost',
  },
  [ContentId.UnsoldQty]: {
    id: ContentId.UnsoldQty,
    defaultValue: 'Unsold Qty',
  },
  [ContentId.UnsoldQuantity]: {
    id: ContentId.UnsoldQuantity,
    defaultValue: 'Unsold Quantity',
  },
  [ContentId.UnsoldTickets]: {
    id: ContentId.UnsoldTickets,
    defaultValue: 'Unsold Tickets',
  },
  [ContentId.UpdateCurrency]: {
    id: ContentId.UpdateCurrency,
    defaultValue: 'Update Currency',
  },
  [ContentId.UpdatePurchaseCurrencyWarning]: {
    id: ContentId.UpdatePurchaseCurrencyWarning,
    defaultValue:
      "Updating currency won't trigger automatic conversion. Please adjust amounts manually if needed. Continue?",
  },
  [ContentId.UpdateSeatingInformation]: {
    id: ContentId.UpdateSeatingInformation,
    defaultValue: 'Update seating information',
  },
  [ContentId.Upload]: {
    id: ContentId.Upload,
    defaultValue: 'Upload',
  },
  [ContentId.UploadBarcodes]: {
    id: ContentId.UploadBarcodes,
    defaultValue: 'Upload Barcodes',
  },
  [ContentId.UploadETickets]: {
    id: ContentId.UploadETickets,
    defaultValue: 'Upload E-Tickets',
  },
  [ContentId.UploadFailureMessage]: {
    id: ContentId.UploadFailureMessage,
    defaultValue: 'Upload failed',
  },
  [ContentId.UploadProof]: {
    id: ContentId.UploadProof,
    defaultValue: 'Upload Proof',
  },
  [ContentId.UploadProofOfPurchase]: {
    id: ContentId.UploadProofOfPurchase,
    defaultValue: 'Upload Proof of Purchase',
  },
  [ContentId.UploadQRCodes]: {
    id: ContentId.UploadQRCodes,
    defaultValue: 'Upload QR Codes',
  },
  [ContentId.UploadSeatScoreFile]: {
    id: ContentId.UploadSeatScoreFile,
    defaultValue: 'Upload Seat Score File',
  },
  [ContentId.UploadPurchaseFile]: {
    id: ContentId.UploadPurchaseFile,
    defaultValue: 'Upload Purchase File',
  },
  [ContentId.UploadTicketUrls]: {
    id: ContentId.UploadTicketUrls,
    defaultValue: 'Upload Ticket URLs',
  },
  [ContentId.UploadTicketIds]: {
    id: ContentId.UploadTicketIds,
    defaultValue: 'Upload Ticket IDs',
  },
  [ContentId.UrlsToAcceptTickets]: {
    id: ContentId.UrlsToAcceptTickets,
    defaultValue: 'URLs where the buyer accepts the tickets.',
  },
  [ContentId.UseExistingRoleAsTemplateTitle]: {
    id: ContentId.UseExistingRoleAsTemplateTitle,
    defaultValue: 'Use existing role as template',
  },
  [ContentId.UseExistingRoleAsTemplateExplanation]: {
    id: ContentId.UseExistingRoleAsTemplateExplanation,
    defaultValue:
      'Use an existing role as a template for your new role. Customerize and modify after',
  },
  [ContentId.UserManagement]: {
    id: ContentId.UserManagement,
    defaultValue: 'User Management',
  },
  [ContentId.UserNotFound]: {
    id: ContentId.UserNotFound,
    defaultValue: 'User not found',
  },
  [ContentId.Users]: {
    id: ContentId.Users,
    defaultValue: 'Users',
  },
  [ContentId.UsersWithAccess]: {
    id: ContentId.UsersWithAccess,
    defaultValue: 'Users with access',
  },
  [ContentId.UseThisAnswerForNext24Hours]: {
    id: ContentId.UseThisAnswerForNext24Hours,
    defaultValue: 'Use this answer for the next 24 hours',
  },
  [ContentId.Value]: {
    id: ContentId.Value,
    defaultValue: 'Value',
  },
  [ContentId.ValueDoesNotSatisfyType]: {
    id: ContentId.ValueDoesNotSatisfyType,
    defaultValue: 'Value does not satisfy type.',
  },
  [ContentId.ValueType]: {
    id: ContentId.ValueType,
    defaultValue: 'Value Type',
  },
  [ContentId.Variables]: {
    id: ContentId.Variables,
    defaultValue: 'Variables',
  },
  [ContentId.VariablesToIncludeInColumns]: {
    id: ContentId.VariablesToIncludeInColumns,
    defaultValue: 'Variables to include in columns',
  },
  [ContentId.Vendor]: {
    id: ContentId.Vendor,
    defaultValue: 'Vendor',
  },
  [ContentId.VendorAccount]: {
    id: ContentId.VendorAccount,
    defaultValue: 'Vendor Account',
  },
  [ContentId.VendorAccounts]: {
    id: ContentId.VendorAccounts,
    defaultValue: 'Vendor Accounts',
  },
  [ContentId.Vendors]: {
    id: ContentId.Vendors,
    defaultValue: 'Vendors',
  },
  [ContentId.Venue]: {
    id: ContentId.Venue,
    defaultValue: 'Venue',
  },
  [ContentId.VenueConfigs]: {
    id: ContentId.VenueConfigs,
    defaultValue: 'Venue Configurations',
  },
  [ContentId.VenueMapWelcomeMessage]: {
    id: ContentId.VenueMapWelcomeMessage,
    defaultValue: 'Welcome to StubHub Active Maps',
  },
  [ContentId.VenueName]: {
    id: ContentId.VenueName,
    defaultValue: 'Venue Name',
  },
  [ContentId.Venues]: {
    id: ContentId.Venues,
    defaultValue: EventGroupingType.Venue,
  },
  [ContentId.VenueWideQualityScore]: {
    id: ContentId.VenueWideQualityScore,
    defaultValue: 'Venue Wide - Quality Score',
  },
  [ContentId.VenueWideInSectionPriority]: {
    id: ContentId.VenueWideInSectionPriority,
    defaultValue: 'Venue Wide - In-section Priority',
  },
  [ContentId.VenueWideQualityScoreSameZone]: {
    id: ContentId.VenueWideQualityScoreSameZone,
    defaultValue: 'Venue Wide - Quality Score (Same Zone)',
  },
  [ContentId.VerifyBuyerInfo]: {
    id: ContentId.VerifyBuyerInfo,
    defaultValue:
      'Verify the name and email of the buyer you will transfer the tickets to.',
  },
  [ContentId.VeryAggressive]: {
    id: ContentId.VeryAggressive,
    defaultValue: 'Very Aggressive (1sd)',
  },
  [ContentId.View]: {
    id: ContentId.View,
    defaultValue: 'View',
  },
  [ContentId.ViewAllAccessiblePermissions]: {
    id: ContentId.ViewAllAccessiblePermissions,
    defaultValue: 'View all accessible permissions',
  },
  [ContentId.ViewComps]: {
    id: ContentId.ViewComps,
    defaultValue: 'View Comps',
  },
  [ContentId.ViewDetails]: {
    id: ContentId.ViewDetails,
    defaultValue: 'View Details',
  },
  [ContentId.ViewBarcodes]: {
    id: ContentId.ViewBarcodes,
    defaultValue: 'View Barcodes',
  },
  [ContentId.ViewDuplicates]: {
    id: ContentId.ViewDuplicates,
    defaultValue: 'View Duplicates',
  },
  [ContentId.ViewEditabilityDescription]: {
    id: ContentId.ViewEditabilityDescription,
    defaultValue: 'User can see the filter has been set, but cannot edit',
  },
  [ContentId.ViewEmailDetails]: {
    id: ContentId.ViewEmailDetails,
    defaultValue: 'View email details',
  },
  [ContentId.ViewETickets]: {
    id: ContentId.ViewETickets,
    defaultValue: 'View E-Tickets',
  },
  [ContentId.ViewETicketUrls]: {
    id: ContentId.ViewETicketUrls,
    defaultValue: 'View E-Ticket URLs',
  },
  [ContentId.ViewInventory]: {
    id: ContentId.ViewInventory,
    defaultValue: 'View Inventory',
  },
  [ContentId.ViewPurchase]: {
    id: ContentId.ViewPurchase,
    defaultValue: 'View Purchase',
  },
  [ContentId.ViewQRCodes]: {
    id: ContentId.ViewQRCodes,
    defaultValue: 'View QR Codes',
  },
  [ContentId.ViewSale]: {
    id: ContentId.ViewSale,
    defaultValue: 'View Sale',
  },
  [ContentId.ViewSales]: {
    id: ContentId.ViewSales,
    defaultValue: 'View Sales',
  },
  [ContentId.ViewTicketIds]: {
    id: ContentId.ViewTicketIds,
    defaultValue: 'View Ticket IDs',
  },
  [ContentId.ViewTransferProof]: {
    id: ContentId.ViewTransferProof,
    defaultValue: 'View Transfer Proof',
  },
  [ContentId.ViewTransferTicket]: {
    id: ContentId.ViewTransferTicket,
    defaultValue: 'View Transfer Ticket',
  },
  [ContentId.ViewWallet]: {
    id: ContentId.ViewWallet,
    defaultValue: 'View Wallet',
  },
  [ContentId.Visibility]: {
    id: ContentId.Visibility,
    defaultValue: 'Visibility',
  },
  [ContentId.Void]: {
    id: ContentId.Void,
    defaultValue: 'Void',
  },
  [ContentId.VoidBulkPurchaseWarning]: {
    id: ContentId.VoidBulkPurchaseWarning,
    defaultValue:
      'Are you sure you want to void the selected purchase order(s)? This action is reversible. Associated sales will be deallocated and moved to Pending Confirmation.',
  },
  [ContentId.VoidPurchase]: {
    id: ContentId.VoidPurchase,
    defaultValue: 'Void Purchase',
  },
  [ContentId.VoidPurchaseDescription]: {
    id: ContentId.VoidPurchaseDescription,
    defaultValue:
      'Use if the purchase was created in error. Related tickets and payments will be removed.',
  },
  [ContentId.VoidSinglePurchaseWarning]: {
    id: ContentId.VoidSinglePurchaseWarning,
    defaultValue:
      'Are you sure you want to void this purchase order? This action is reversible. Associated sales will be deallocated and moved to Pending Confirmation.',
  },
  [ContentId.Wallet]: {
    id: ContentId.Wallet,
    defaultValue: 'Wallet',
  },
  [ContentId.WasTaxExempt]: {
    id: ContentId.WasTaxExempt,
    defaultValue: 'Was Tax Exempt',
  },
  [ContentId.WastedCost]: {
    id: ContentId.WastedCost,
    defaultValue: 'Wasted Cost',
  },
  [ContentId.WastedQty]: {
    id: ContentId.WastedQty,
    defaultValue: 'Wasted Qty',
  },
  [ContentId.WastedTickets]: {
    id: ContentId.WastedTickets,
    defaultValue: 'Wasted Tickets',
  },
  [ContentId.Weather]: {
    id: ContentId.Weather,
    defaultValue: 'Wasted Tickets',
  },
  [ContentId.Webhook_View_Details]: {
    id: ContentId.Webhook_View_Details,
    defaultValue: 'Ability to view Webhook',
  },
  [ContentId.Webhook_Create_Details]: {
    id: ContentId.Webhook_Create_Details,
    defaultValue: 'Ability to create Webhook',
  },
  [ContentId.Webhook_Edit_Details]: {
    id: ContentId.Webhook_Edit_Details,
    defaultValue: 'Ability to edit Webhook',
  },
  [ContentId.Website]: {
    id: ContentId.Website,
    defaultValue: 'Website',
  },
  [ContentId.WebsiteFloor]: {
    id: ContentId.WebsiteFloor,
    defaultValue: 'Website Price Floor',
  },
  [ContentId.WebsiteCeiling]: {
    id: ContentId.WebsiteCeiling,
    defaultValue: 'Website Price Ceiling',
  },
  [ContentId.WebsitePrice]: {
    id: ContentId.WebsitePrice,
    defaultValue: 'Website Price',
  },
  [ContentId.Week]: {
    id: ContentId.Week,
    defaultValue: 'Week',
  },
  [ContentId.WeekOfYear]: {
    id: ContentId.WeekOfYear,
    defaultValue: 'Week of Year',
  },
  [ContentId.Weeks]: {
    id: ContentId.Weeks,
    defaultValue: 'Weeks',
  },
  [ContentId.WhatIsAPlacholder]: {
    id: ContentId.WhatIsAPlacholder,
    defaultValue: 'What is a Placeholder.',
  },
  [ContentId.WhatIsASeatSaver]: {
    id: ContentId.WhatIsASeatSaver,
    defaultValue: 'What is a Seat Saver?',
  },
  [ContentId.WhatSeatToFulfill]: {
    id: ContentId.WhatSeatToFulfill,
    defaultValue: 'What seats do you want to fulfill with this order?',
  },
  [ContentId.WhatsNew]: {
    id: ContentId.WhatsNew,
    defaultValue: "What's New",
  },
  [ContentId.WhatTicketsDidYouBuy]: {
    id: ContentId.WhatTicketsDidYouBuy,
    defaultValue: 'What tickets did you buy?',
  },
  [ContentId.WhenAndWhere]: {
    id: ContentId.WhenAndWhere,
    defaultValue: 'When & Where',
  },
  [ContentId.WhereTicketsWerePurchased]: {
    id: ContentId.WhereTicketsWerePurchased,
    defaultValue: 'Where did you buy your tickets?',
  },
  [ContentId.WhenWhereWho]: {
    id: ContentId.WhenWhereWho,
    defaultValue: 'When, Where, Who',
  },
  [ContentId.WholeNumber]: {
    id: ContentId.WholeNumber,
    defaultValue: 'Whole Number',
  },
  [ContentId.Widgets]: {
    id: ContentId.Widgets,
    defaultValue: 'Widgets',
  },
  [ContentId.WillCall]: {
    id: ContentId.WillCall,
    defaultValue: 'Will Call',
  },
  [ContentId.WitheldFunds]: {
    id: ContentId.WitheldFunds,
    defaultValue: 'Witheld Funds',
  },
  [ContentId.Worse]: {
    id: ContentId.Worse,
    defaultValue: 'worse',
  },
  [ContentId.WrongTicketsReturnShippingCost]: {
    id: ContentId.WrongTicketsReturnShippingCost,
    defaultValue: 'Wrong Tickets Return Shipping Cost',
  },
  [ContentId.WrongTrackingNumber]: {
    id: ContentId.WrongTrackingNumber,
    defaultValue: 'Wrong Tracking Number',
  },
  [ContentId.Yes]: {
    id: ContentId.Yes,
    defaultValue: 'Yes',
  },
  [ContentId.YesToSome]: {
    id: ContentId.YesToSome,
    defaultValue: 'Yes to Some',
  },
  [ContentId.YourAutoPriceResults]: {
    id: ContentId.YourAutoPriceResults,
    defaultValue: 'Your auto price results',
  },
  [ContentId.YourBuyer]: {
    id: ContentId.YourBuyer,
    defaultValue: 'Your Buyer',
  },
  [ContentId.YoureCheaper]: {
    id: ContentId.YoureCheaper,
    defaultValue: "You're cheaper",
  },
  [ContentId.YoureMoreExpensive]: {
    id: ContentId.YoureMoreExpensive,
    defaultValue: "You're more expensive",
  },
  [ContentId.YourListing]: {
    id: ContentId.YourListing,
    defaultValue: 'Your Listing',
  },
  [ContentId.YourSale]: {
    id: ContentId.YourSale,
    defaultValue: 'Your Sale',
  },
  [ContentId.YourSaleMetricsPart1]: {
    id: ContentId.YourSaleMetricsPart1,
    defaultValue: 'Your sales stats for',
  },
  [ContentId.YourStats]: {
    id: ContentId.YourStats,
    defaultValue: 'Your Stats',
  },
  [ContentId.YourSummaryMessage]: {
    id: ContentId.YourSummaryMessage,
    defaultValue: 'Below is your summary for today',
  },
  [ContentId.YourTickets]: {
    id: ContentId.YourTickets,
    defaultValue: 'Your Tickets',
  },
  [ContentId.Zone]: {
    id: ContentId.Zone,
    defaultValue: 'Zone',
  },
  [ContentId.Zones]: {
    id: ContentId.Zones,
    defaultValue: 'Zones',
  },
  [ContentId.Zoom]: {
    id: ContentId.Zoom,
    defaultValue: 'Zoom',
  },
  [ContentId.ZoomInstruction]: {
    id: ContentId.ZoomInstruction,
    defaultValue: 'Pinch or double tap to zoom.',
  },
  [ContentId.ZtoA]: {
    id: ContentId.ZtoA,
    defaultValue: 'Z to A',
  },
  [ContentId.ZtoAArrow]: {
    id: ContentId.ZtoAArrow,
    defaultValue: 'ZA ↓',
  },
};
