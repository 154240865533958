import { useCallback } from 'react';
import { RowSelector } from 'src/components/Selectors/SectionRowSelector';
import { RowInfo, SectionInfo } from 'src/WebApiController';

import * as styles from './FilterPillList.css';

const PENCIL_CHAR = '\u270E';

export const RowIdFilterDropdown = ({
  rowName,
  row,
  section,
  focusedSectionIds,
  onChange,
  disabled,
}: {
  rowName?: string | null;
  row?: RowInfo;
  section?: SectionInfo;
  focusedSectionIds: number[];
  onChange: (row: RowInfo) => void;
  disabled: boolean;
}) => {
  const onRowChange = useCallback(
    (rowId: string) => {
      const rowIdNum = parseInt(rowId);
      const row = section?.rows?.find((r) => r.id === rowIdNum);
      if (row) {
        onChange(row);
      }
    },
    [onChange, section?.rows]
  );

  if (!section?.rows?.filter((r) => !r.isSpeculative)?.length) {
    return null;
  }

  return (
    <RowSelector
      className={styles.mutablePillItemFilterText}
      hideFoldButton
      searchable={false}
      placeholderText={`< ${rowName || PENCIL_CHAR}`}
      value={row?.id.toString() ?? ''}
      displayText={`< ${row?.name}`}
      selectedSection={section}
      disabled={disabled}
      onChange={onRowChange}
      onClick={(e) => {
        // When clicking on the row selector, if the section is already focused
        // do not unfocus it
        if (focusedSectionIds.includes(section.id)) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    />
  );
};
