import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ChatIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M20.228 2c.91 0 1.647.737 1.647 1.647v11.88c0 .91-.738 1.646-1.647 1.646h-9.624V22L5.79 17.173H3.757c-.91 0-1.647-.737-1.647-1.646V3.647C2.11 2.737 2.847 2 3.757 2h16.47zm.103 1.72H3.654v11.734H6.15l2.987 2.995v-2.995H20.33V3.72z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
