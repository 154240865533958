import { useCallback, useContext } from 'react';
import { UploadButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { ButtonProps } from 'src/core/ui';
import { TurnOnSaleAutoFulfillDialog } from 'src/dialogs/TurnOnSaleAutoFulfillDialog';
import { UploadBarcodeProps, UploadBarcodes } from 'src/modals/UploadBarcodes';
import { ContentId } from 'src/utils/constants/contentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { ListingActionType, SaleActionType } from 'src/WebApiController';

export const LaunchUploadBarcodes = ({
  entityId,
  idOnMkp,
  variant,
  iconOnlyMode,
  dropdownItemMode,
  disabled,
  action,
  isSaleNoFulfill,
  ...modalProps
}: UploadBarcodeProps & {
  entityId: number;
  idOnMkp?: string | null;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  dropdownItemMode?: boolean;
  disabled?: boolean;
  isSaleNoFulfill?: boolean | null;
  action:
    | ListingActionType.PredeliverBarcodes
    | ListingActionType.RedeliverBarcodes
    | SaleActionType.Fulfill
    | SaleActionType.Refulfill;
}) => {
  const { setModal } = useContext(ModalContext);
  const { setActivePosEntity } =
    useActivePosEntityContext<EntityWithRealTickets>();

  const uploadBarcodes = useCallback(() => {
    setModal({
      children: <UploadBarcodes {...modalProps} />,
      clickOutsideToClose: true,
      size: 'slide-in',
    });
  }, [setModal, modalProps]);

  const {
    openDialog: openTurnOnSaleAutoFulfillDialog,
    closeDialog: closeTurnOnSaleAutoFulfillDialog,
  } = useDialog(DialogId.Confirm, TurnOnSaleAutoFulfillDialog);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setActivePosEntity(entityId, idOnMkp);
      uploadBarcodes();
    },
    [setActivePosEntity, entityId, idOnMkp, uploadBarcodes]
  );

  const onClickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (isSaleNoFulfill) {
        e.preventDefault();
        e.stopPropagation();

        openTurnOnSaleAutoFulfillDialog({
          saleId: entityId,
          onOkay: () => {
            closeTurnOnSaleAutoFulfillDialog();
            onLaunchDialog(e);
          },
          onCancel: () => {
            closeTurnOnSaleAutoFulfillDialog();
            onLaunchDialog(e);
          },
        });
      } else {
        onLaunchDialog(e);
      }
    },
    [
      closeTurnOnSaleAutoFulfillDialog,
      entityId,
      isSaleNoFulfill,
      onLaunchDialog,
      openTurnOnSaleAutoFulfillDialog,
    ]
  );

  const actionContentId =
    action === SaleActionType.Refulfill && isSaleNoFulfill
      ? ContentId.Fulfill
      : action === ListingActionType.RedeliverBarcodes ||
        action === SaleActionType.Refulfill
      ? ContentId.ReuploadBarcodes
      : ContentId.UploadBarcodes;

  if (dropdownItemMode) {
    return (
      <PosDropdownItem disabled={disabled} onClick={onClickHandler}>
        <Content id={actionContentId} />
      </PosDropdownItem>
    );
  }

  return (
    <UploadButton
      disabled={disabled}
      variant={variant}
      iconOnlyMode={iconOnlyMode}
      onClick={onClickHandler}
      textContentId={actionContentId}
    />
  );
};
