import { createContext, ReactNode, useCallback } from 'react';
import { useRef } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  PurchaseClient,
  PurchaseOrderDetails,
  SaleClient,
  SaleDetails,
} from 'src/WebApiController';

import { useErrorBoundaryContext } from '../ErrorBoundaryContext';

type IConversationDataContext = {
  getPurchaseOrderDetails: (
    purchaseId: number
  ) => Promise<PurchaseOrderDetails | null>;
  getSaleDetails: (saleId: number) => Promise<SaleDetails | null>;
};

export const ConversationDataContext = createContext<IConversationDataContext>({
  getPurchaseOrderDetails: async () => null,
  getSaleDetails: async () => null,
});

export const ConversationDataContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const purchaseLookup = useRef<
    Record<string, PurchaseOrderDetails | undefined>
  >({});
  const getPurchaseOrderDetails = useCallback(
    async (purchaseId: number) => {
      let purchase = purchaseLookup.current[purchaseId];
      if (purchase == null) {
        purchase =
          (await tryInvokeApi(
            async () => {
              return await new PurchaseClient(
                activeAccountWebClientConfig
              ).getPurchaseOrderByPurchaseOrderId(purchaseId, true);
            },
            (error) => {
              trackError(
                'ConversationDataContext.PurchaseClient.getPurchaseOrderByPurchaseOrderId',
                error,
                { purchaseId }
              );
            }
          )) ?? undefined;
        if (purchase != null) {
          purchaseLookup.current[purchaseId] = purchase;
        }
      }
      return purchase ?? null;
    },
    [activeAccountWebClientConfig, purchaseLookup, trackError]
  );

  const saleLookup = useRef<Record<string, SaleDetails | undefined>>({});
  const getSaleDetails = useCallback(
    async (saleId: number) => {
      let sale = saleLookup.current[saleId];
      if (sale == null) {
        sale =
          (await tryInvokeApi(
            async () => {
              return await new SaleClient(
                activeAccountWebClientConfig
              ).getSaleBySaleId(saleId);
            },
            (error) => {
              trackError('SaleClient.getSaleBySaleId', error, { saleId });
            }
          )) ?? undefined;
        if (sale != null) {
          saleLookup.current[saleId] = sale;
        }
      }
      return sale ?? null;
    },
    [activeAccountWebClientConfig, saleLookup, trackError]
  );

  return (
    <ConversationDataContext.Provider
      value={{
        getPurchaseOrderDetails,
        getSaleDetails,
      }}
    >
      {children}
    </ConversationDataContext.Provider>
  );
};
