import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { Permission } from 'src/WebApiController';

interface UseUpdatePurchasesPermissions {
  hasUpdatePurchasesPermission: boolean;
  hasPurchaseUpdateCommissionPermission: boolean;
  hasPurchaseUpdateDeliveryTypePermission: boolean;
  hasPurchaseUpdateSeatTraitsPermission: boolean;
}

export const useUpdatePurchasesPermissions =
  (): UseUpdatePurchasesPermissions => {
    const hasPurchaseUpdatePermission = useUserHasAnyOfPermissions(
      Permission.Purchases_Edit,
      Permission.Purchases_EditOwned
    );
    const hasPurchaseUpdateCommissionPermission = useUserHasAnyOfPermissions(
      Permission.Purchases_UpdateBuyerCommission,
      Permission.Purchases_UpdateBuyerCommissionOwned
    );
    const hasPurchaseUpdateDeliveryTypePermission = useUserHasAnyOfPermissions(
      Permission.Purchases_UpdateDeliveryType,
      Permission.Purchases_UpdateDeliveryTypeOwned,
      Permission.Purchases_UpdateCustomDeliveryType,
      Permission.Purchases_UpdateCustomDeliveryTypeOwned
    );
    const hasPurchaseUpdateSeatTraitsPermission = useUserHasAnyOfPermissions(
      Permission.Purchases_UpdateSeatTraits,
      Permission.Purchases_UpdateSeatTraitsOwned
    );

    return {
      hasUpdatePurchasesPermission: hasPurchaseUpdatePermission,
      hasPurchaseUpdateCommissionPermission,
      hasPurchaseUpdateDeliveryTypePermission,
      hasPurchaseUpdateSeatTraitsPermission,
    };
  };
