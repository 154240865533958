import {
  AutoPoSellerAccountCountryRestriction,
  AutoPoSellerAccountVendorRestriction,
} from 'src/WebApiController';

export const sortCountryRestrictions = (
  a: AutoPoSellerAccountCountryRestriction,
  b: AutoPoSellerAccountCountryRestriction
) => {
  if (a.isEnabled !== b.isEnabled) {
    return a.isEnabled ? -1 : 1;
  }
  return a.country.localeCompare(b.country);
};

export const sortVendorRestrictions = (
  a: AutoPoSellerAccountVendorRestriction,
  b: AutoPoSellerAccountVendorRestriction
) => {
  if (a.isIncluded !== b.isIncluded) {
    return a.isIncluded ? -1 : 1;
  }
  return a.vendorId > b.vendorId ? -1 : 1;
};
