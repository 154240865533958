import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const AdminHoldIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 19 19" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.38662 4.8644C8.39794 4.2799 8.87529 3.80957 9.46249 3.80957H9.59277C10.0679 3.80957 10.4711 4.11754 10.6137 4.54475H11.0921C11.6864 4.54475 12.1682 5.02653 12.1682 5.62083V6.01512H12.5249C13.1191 6.01512 13.601 6.4969 13.601 7.0912V11.3929C13.601 13.5165 11.8793 15.2381 9.75562 15.2381C8.22088 15.2381 6.83031 14.3255 6.23093 12.9095C5.62254 11.4722 5.08832 10.1885 4.82539 9.55462C4.64042 9.10858 4.87358 8.60225 5.32886 8.44938C5.92246 8.25007 6.56002 8.46366 6.92037 8.94303V5.94047C6.92037 5.34628 7.4019 4.8644 7.9963 4.8644H8.38662ZM8.38642 5.68073H7.9963C7.85296 5.68073 7.73669 5.79691 7.73669 5.94047V10.8983L6.95067 11.0527L6.34482 9.56957C6.22358 9.27278 5.89264 9.1212 5.5887 9.22325C5.57944 9.22636 5.57669 9.23521 5.57944 9.24185C5.84193 9.87471 6.37534 11.1565 6.98268 12.5913C7.45314 13.7027 8.54592 14.4219 9.75562 14.4219C11.4285 14.4219 12.7846 13.0658 12.7846 11.3929V7.0912C12.7846 6.94774 12.6683 6.83146 12.5249 6.83146H12.1682V9.49189H11.3518V5.62083C11.3518 5.47737 11.2355 5.36108 11.0921 5.36108H10.6688V9.17225H9.85248V4.88564C9.85248 4.74219 9.73619 4.62589 9.59277 4.62589H9.46249C9.31903 4.62589 9.20275 4.74219 9.20275 4.88563V9.17225H8.38642V5.68073Z"
        fill={props.fill || '#87909A'}
      />
      <path
        d="M9.62443 18.7719C14.6767 18.7719 18.7723 14.6763 18.7723 9.62406C18.7723 4.57184 14.6767 0.476196 9.62443 0.476196C4.5722 0.476196 0.476562 4.57184 0.476562 9.62406C0.476562 14.6763 4.5722 18.7719 9.62443 18.7719Z"
        stroke={props.fill || '#87909A'}
      />
    </SvgWrapper>
  );
};
