import { useReportMetricsContextV2 } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import SaleReportTableV2 from 'src/tables/SalesTable/SaleReportTableV2';

import { ReportsFilterToolbarV2 } from '../Reports/ReportsFilterToolbar/ReportsFilterToolbarV2';
import * as styles from './ReportsSalePage.css';

export function ReportsSalePageV2({
  reportConfig,
}: {
  reportConfig: ReportConfigV2;
}) {
  const { reportMetrics, reportSummary, isLoading } =
    useReportMetricsContextV2();

  return (
    <div className={styles.root}>
      {reportConfig.reportName && (
        <h1 className={styles.pageName}>{reportConfig.reportName}</h1>
      )}
      <ReportsFilterToolbarV2 reportConfig={reportConfig} />
      {isLoading ? (
        <PosSpinner />
      ) : (
        <div className={styles.tableContainer}>
          <SaleReportTableV2
            className={styles.table}
            metricsData={reportMetrics}
            summaryData={reportSummary}
            report={reportConfig}
          />
        </div>
      )}
    </div>
  );
}
