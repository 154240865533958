import { vars } from 'src/core/themes';

export const AutomatiqLogo = ({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) => {
  const color = vars.color.marketplaceBackgroundAutomatiq;
  return (
    <svg
      width={width ?? 179}
      height={height ?? 38}
      viewBox="0 0 179 38"
      fill="none"
      className="svg replaced-svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.6807 22.8674C42.6807 23.8208 42.488 24.6134 42.1024 25.243C41.7157 25.8736 41.2243 26.3492 40.6292 26.6663C40.033 26.9833 39.4145 27.1418 38.7805 27.1418C37.7452 27.1418 36.9106 26.7917 36.2743 26.0915C35.638 25.3924 35.3204 24.4469 35.3204 23.2574V12H31V23.9417C31 25.4403 31.2596 26.7164 31.7767 27.7714C32.2927 28.8251 33.0103 29.6269 33.9297 30.1766C34.8479 30.7251 35.9076 31 37.1089 31C38.4852 31 39.6441 30.667 40.5857 29.9987C41.5307 29.3315 42.2082 28.4727 42.6228 27.4235H42.8133V30.7559H47V12H42.6807V22.8674Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.7382 27.1307C59.5093 27.1737 59.2567 27.1935 58.9838 27.1935C58.6196 27.1935 58.2914 27.1365 57.9937 27.02C57.6949 26.9036 57.4559 26.6847 57.2743 26.3645C57.0928 26.0455 57.0026 25.581 57.0026 24.9755V15.0767H60.6617V11.5862H57.0026V7H52.6319V11.5862H50V15.0767H52.6319V25.723C52.624 26.9199 52.8766 27.9166 53.3874 28.7153C53.8971 29.5128 54.5928 30.1008 55.4701 30.4792C56.3474 30.8564 57.3341 31.0287 58.4268 30.9961C59.0481 30.9786 59.5724 30.9216 60.0043 30.8215C60.4339 30.7225 60.7666 30.6294 61 30.5467L60.2614 27.02C60.1419 27.0515 59.9671 27.0887 59.7382 27.1307Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.017 24.5429C75.6881 25.4537 75.1889 26.1777 74.5185 26.7158C73.8504 27.2552 73.02 27.5259 72.0252 27.5259C71.0053 27.5259 70.1613 27.2552 69.4874 26.7158C68.8158 26.1777 68.3145 25.4537 67.9832 24.5429C67.6543 23.6322 67.4875 22.6184 67.4875 21.4995C67.4875 20.3728 67.6543 19.3546 67.9832 18.4382C68.3145 17.5241 68.8158 16.7957 69.4874 16.2519C70.1613 15.7092 71.0053 15.4385 72.0252 15.4385C73.02 15.4385 73.8504 15.7092 74.5185 16.2519C75.1889 16.7957 75.6881 17.5241 76.017 18.4382C76.3482 19.3546 76.5127 20.3728 76.5127 21.4995C76.5127 22.6184 76.3482 23.6322 76.017 24.5429ZM76.7695 13.1849C75.4241 12.3939 73.8354 12 72 12C70.1669 12 68.5759 12.3939 67.2316 13.1849C65.8862 13.976 64.8445 15.087 64.1079 16.5148C63.3701 17.9447 63 19.6096 63 21.5129C63 23.4128 63.3701 25.0743 64.1079 26.4942C64.8445 27.9163 65.8862 29.0229 67.2316 29.8128C68.5759 30.6039 70.1669 31 72 31C73.8354 31 75.4241 30.6039 76.7695 29.8128C78.115 29.0229 79.1566 27.9163 79.8944 26.4942C80.6322 25.0743 81 23.4128 81 21.5129C81 19.6096 80.6322 17.9447 79.8944 16.5148C79.1566 15.087 78.115 13.976 76.7695 13.1849Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.222 12C103.882 12 102.724 12.3113 101.751 12.934C100.775 13.5556 100.083 14.3893 99.6729 15.4316H99.4807C99.1288 14.3733 98.5205 13.5373 97.6571 12.9215C96.7937 12.3068 95.738 12 94.4866 12C93.2499 12 92.1864 12.3034 91.2959 12.9101C90.4044 13.5157 89.7658 14.3562 89.3813 15.4316H89.1654V12.2441H85V31H89.3577V19.5954C89.3577 18.8222 89.5106 18.1436 89.8153 17.5609C90.12 16.9792 90.5281 16.5288 91.043 16.206C91.5557 15.8855 92.1257 15.7247 92.7519 15.7247C93.6749 15.7247 94.4237 16.0133 94.9971 16.5903C95.5704 17.1685 95.8583 17.9418 95.8583 18.91V31H100.131V19.3012C100.131 18.244 100.426 17.383 101.015 16.7181C101.606 16.0555 102.426 15.7247 103.476 15.7247C104.359 15.7247 105.107 15.9962 105.716 16.5367C106.325 17.0785 106.631 17.9304 106.631 19.0948V31H111V18.4094C111 16.3178 110.463 14.728 109.388 13.6354C108.312 12.5451 106.925 12 105.222 12Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.642 24.0315C126.642 24.7103 126.466 25.3376 126.116 25.9112C125.767 26.4859 125.267 26.9489 124.625 27.2955C123.981 27.6421 123.22 27.8166 122.342 27.8166C121.432 27.8166 120.683 27.6119 120.096 27.2038C119.507 26.799 119.215 26.1963 119.215 25.398C119.215 24.8389 119.364 24.3827 119.66 24.026C119.958 23.6715 120.365 23.3953 120.88 23.194C121.396 22.995 121.979 22.8508 122.631 22.7635C122.922 22.7233 123.264 22.6763 123.658 22.6193C124.053 22.5645 124.452 22.4996 124.854 22.4281C125.256 22.3565 125.621 22.2727 125.946 22.1765C126.273 22.0815 126.504 21.9775 126.642 21.8656V24.0315ZM128.562 13.3351C127.83 12.861 127.019 12.52 126.133 12.3109C125.248 12.1051 124.359 12 123.465 12C122.169 12 120.986 12.1901 119.914 12.5692C118.844 12.9482 117.938 13.5151 117.198 14.2688C116.456 15.0235 115.925 15.9595 115.603 17.0765L119.685 17.6524C119.903 17.0206 120.324 16.4738 120.947 16.0109C121.57 15.548 122.419 15.3176 123.489 15.3176C124.502 15.3176 125.281 15.5636 125.82 16.0579C126.359 16.5543 126.628 17.2521 126.628 18.1544V18.226C126.628 18.6419 126.474 18.9461 126.164 19.1418C125.855 19.3375 125.361 19.4806 124.684 19.5723C124.007 19.6651 123.126 19.7668 122.04 19.8775C121.138 19.9737 120.266 20.1269 119.426 20.3393C118.584 20.5496 117.829 20.8593 117.162 21.2663C116.493 21.6733 115.965 22.2156 115.579 22.8944C115.193 23.5731 115 24.4319 115 25.4684C115 26.6738 115.271 27.688 115.815 28.5098C116.359 29.3328 117.099 29.9523 118.037 30.3716C118.975 30.7898 120.031 31 121.207 31C122.172 31 123.016 30.8658 123.737 30.5986C124.457 30.3313 125.057 29.9769 125.537 29.5341C126.015 29.0902 126.388 28.6138 126.653 28.1028H126.799V30.6288H131V18.321C131 17.1011 130.777 16.0825 130.33 15.2695C129.885 14.4544 129.294 13.8103 128.562 13.3351Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.738 27.1307C142.509 27.1737 142.257 27.1935 141.984 27.1935C141.62 27.1935 141.291 27.1365 140.994 27.02C140.695 26.9036 140.456 26.6847 140.274 26.3645C140.093 26.0455 140.003 25.581 140.003 24.9755V15.0767H143.662V11.5862H140.003V7H135.632V11.5862H133V15.0767H135.632V25.723C135.624 26.9199 135.877 27.9166 136.387 28.7153C136.897 29.5128 137.593 30.1008 138.47 30.4792C139.347 30.8564 140.334 31.0287 141.427 30.9961C142.048 30.9786 142.572 30.9216 143.004 30.8215C143.434 30.7225 143.767 30.6294 144 30.5467L143.261 27.02C143.142 27.0515 142.967 27.0887 142.738 27.1307Z"
        fill={color}
      ></path>
      <mask
        id="mask0_266_6568"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="179"
        height="38"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 38H179V0H0V38Z"
          fill="white"
        ></path>
      </mask>
      <g mask="url(#mask0_266_6568)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148 31H152V12H148V31Z"
          fill={color}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M168.063 24.7926C167.716 25.7038 167.207 26.4146 166.537 26.9226C165.868 27.4295 165.057 27.6824 164.107 27.6824C163.121 27.6824 162.291 27.4215 161.618 26.8975C160.945 26.3713 160.436 25.6537 160.092 24.739C159.749 23.8232 159.578 22.781 159.578 21.6112C159.578 20.4482 159.747 19.4163 160.086 18.5118C160.425 17.6109 160.929 16.9024 161.601 16.3909C162.27 15.8784 163.104 15.6221 164.107 15.6221C165.073 15.6221 165.89 15.8715 166.555 16.3659C167.221 16.8614 167.725 17.5562 168.069 18.4526C168.411 19.3468 168.583 20.3981 168.583 21.6112C168.583 22.8209 168.408 23.8836 168.063 24.7926ZM168.583 15.342H168.311C168.079 14.8864 167.753 14.4 167.331 13.8851C166.908 13.3691 166.339 12.9249 165.619 12.5547C164.899 12.1857 163.98 12 162.865 12C161.4 12 160.075 12.3691 158.882 13.1049C157.693 13.8396 156.748 14.9251 156.049 16.3592C155.35 17.7955 155 19.5531 155 21.6341C155 23.6924 155.344 25.4397 156.029 26.8794C156.716 28.3181 157.651 29.415 158.834 30.1713C160.016 30.9288 161.355 31.3069 162.853 31.3069C163.943 31.3069 164.85 31.127 165.569 30.7704C166.288 30.4105 166.868 29.9788 167.305 29.4708C167.743 28.9628 168.079 28.4798 168.311 28.0242H168.498V38H173V12.2438H168.583V15.342Z"
          fill={color}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148 10H152V6H148V10Z"
          fill={color}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M175 12.3037H175.775V14H176.225V12.3037H177V12H175V12.3037Z"
          fill={color}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M178.577 12L178.011 13.4493H177.989L177.421 12H177V14H177.331V12.6231H177.348L177.875 13.9945H178.123L178.65 12.6264H178.669V14H179V12H178.577Z"
          fill={color}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 21.825L22.9929 30H28L22.5709 19L19 21.825Z"
          fill={color}
        ></path>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.30949 23.0843L13.6802 10.7528L16.4686 17.1989L8.30949 23.0843ZM20.2173 14.7623L13.7007 0L0 31H4.49248L34 5.46403L20.2173 14.7623Z"
          fill={color}
        ></path>
      </g>
    </svg>
  );
};
