import React, { useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { WarningOutlineIcon } from 'src/svgs/Viagogo';
import { Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { ListingDetailsAutoPricingSectionUpdatesV2 } from 'src/WebApiController';

import {
  AutoPricingSettingsInput,
  PricingSettingsUpdateKey,
} from '../BulkEditAutoPricingSettingsDialog.types';
import * as styles from '../components/AutoPricingSettings.css';
import { usePricingSettingsPreviewTable } from './PricingSettingsPreview.hooks';

type PricingSettingsPreviewProps = {
  settingsInput: AutoPricingSettingsInput;
  defaultValues: ListingDetailsAutoPricingSectionUpdatesV2;
  updates: ListingDetailsAutoPricingSectionUpdatesV2;
  changedFields?: Partial<
    Record<PricingSettingsUpdateKey, boolean | undefined>
  >;
  updateChangedFields: (
    values: Partial<Record<PricingSettingsUpdateKey, boolean | undefined>>
  ) => void;
};

export const PricingSettingsPreview: React.FC<PricingSettingsPreviewProps> = ({
  settingsInput,
  defaultValues,
  updates,
  changedFields,
  updateChangedFields,
}) => {
  const options = usePricingSettingsPreviewTable(
    settingsInput,
    defaultValues,
    updates,
    updateChangedFields,
    changedFields
  );

  const hasConflict = useMemo(
    () => Object.values(settingsInput).some(({ hasConflict }) => hasConflict),
    [settingsInput]
  );

  return (
    <Stack width="full" direction="column" gap="m" style={{ height: '24vh' }}>
      {hasConflict && (
        <Stack
          width="full"
          direction="row"
          gap="m"
          className={styles.conflictAlert}
        >
          <WarningOutlineIcon size={vars.iconSize.l} />
          <Content id={ContentId.PricingSettingsListingsConflictWarning} />
        </Stack>
      )}
      <div className={styles.settingsPreviewHeader}>
        <Content id={ContentId.Update} />
      </div>
      <Table options={options} tableLayout="fixed" />
    </Stack>
  );
};
