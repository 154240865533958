import { useMemo } from 'react';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { EmptySelectionKey } from 'src/utils/adGroupUtils';
import {
  AdPlatformCatalogResults,
  TicketClassInfo,
} from 'src/WebApiController';

export type TicketClassSelectorProps = PosSelectProps & {
  ticketClasses: Record<number, TicketClassInfo> | null | undefined;
};

export const AdGroupTicketClassSelector = ({
  ticketClasses,
  placeholderText,
  ...props
}: Omit<TicketClassSelectorProps, 'valueOptionsContent'>) => {
  const { data: catalogData } = useAdPlatformCatalogDataContext();

  return (
    <SelectionBody
      {...props}
      catalogData={catalogData}
      ticketClasses={ticketClasses}
      placeholderText={placeholderText}
    ></SelectionBody>
  );
};

export const SelectionBody = ({
  catalogData,
  ticketClasses,
  placeholderText,
  ...props
}: {
  catalogData: AdPlatformCatalogResults | undefined;
  ticketClasses: Record<number, TicketClassInfo> | null | undefined;
  placeholderText: string | undefined;
} & Omit<PosSelectProps, 'valueOptionsContent'>) => {
  const ticketClassesData = useMemo(() => {
    if (!ticketClasses) {
      return {};
    }

    const results =
      Object.values(ticketClasses).reduce(
        (acc, ticketClass) => {
          const ticketClassId = ticketClass.id;
          const ticketClassName = ticketClass.name;
          if (ticketClassId != null && ticketClassName != null) {
            acc[ticketClassId.toString()] = ticketClassName;
          }
          return acc;
        },
        {} as Record<string, string>
      ) ?? {};
    if (Object.values(results).length == 0) {
      results[EmptySelectionKey] = 'N/A';
    }

    return results;
  }, [ticketClasses]);
  return (
    <PosSelect
      {...props}
      searchable={
        props.searchable != null
          ? props.searchable
          : ticketClasses
          ? Object.values(ticketClassesData).length > 10
          : false
      }
      loading={ticketClasses == null}
      placeholderText={placeholderText}
      valueOptionsContent={ticketClassesData}
    />
  );
};
