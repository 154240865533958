import clsx from 'clsx';
import { isValid, parse } from 'date-fns';
import { format } from 'date-fns';
import { Calendar, CalendarProps } from 'react-date-range';
import { FieldError } from 'react-hook-form';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { vars } from 'src/core/themes';
import { Popover } from 'src/core/ui';
import { CalendarOutlineIcon, CrossIcon } from 'src/svgs/Viagogo';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';

import { getTextFieldState, PosTextField } from '../PosTextField';
import * as styles from './DatePicker.css';

export const DatePickerInput = ({
  value,
  date,
  fieldError,
  disabled,
  className,
  dateDisplayFormat = 'MM/dd/yyyy',
  color = vars.color.backgroundHighlight,
  locale = getLocaleFromLanguageOrCurrent(),
  onDateChange,
  textFieldStyles,
  useSiteTimeZone = true,
  ...props
}: Omit<CalendarProps, 'onChange'> & {
  value?: string | null | Date;
  onDateChange?: (d: Date) => void;
  fieldError?: string | FieldError | undefined;
  disabled?: boolean;
  textFieldStyles?: React.CSSProperties;
  /**
   * If true, the site's timezone will be applied to the date being displayed.
   * the underlying date value (value, data, onDateChange) will not be changed.
   */
  useSiteTimeZone?: boolean;
}) => {
  const { toZonedTime, fromZonedTime } = useSiteTimezoneContext();

  date = date ?? (value ? new Date(value) : undefined);
  if (useSiteTimeZone) {
    if (date) {
      date = toZonedTime(date);
    }
    if (props.minDate) {
      props.minDate = toZonedTime(props.minDate);
    }
    if (props.maxDate) {
      props.maxDate = toZonedTime(props.maxDate);
    }
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild disabled={disabled}>
        <div className={styles.datePickerContainer}>
          <PosTextField
            rootProps={{
              state: getTextFieldState(fieldError),
              disabled: disabled,
            }}
            placeholder={'MM/DD/YYYY'}
            prefixDisplay={<CalendarOutlineIcon withHoverEffect />}
            value={date ? format(date, dateDisplayFormat) : undefined}
            onChange={(e) => {
              const str = e.target.value;
              const newDate = parse(str, dateDisplayFormat, new Date(), {
                locale: getLocaleFromLanguageOrCurrent(),
              });
              if (isValid(newDate)) {
                onDateChange?.(
                  useSiteTimeZone ? fromZonedTime(newDate) : newDate
                );
              }
            }}
            disabled={disabled}
            style={textFieldStyles}
          />
        </div>
      </Popover.Trigger>
      <Popover.Content align="start">
        <Popover.Close asChild className={styles.datePickerClose}>
          <CrossIcon withHoverEffect />
        </Popover.Close>
        <Calendar
          {...props}
          date={date}
          disabledDay={disabled ? () => true : undefined}
          className={clsx(className, styles.datePicker)}
          dateDisplayFormat={dateDisplayFormat}
          color={color}
          locale={locale}
          onChange={(d) =>
            onDateChange?.(useSiteTimeZone ? fromZonedTime(d) : d)
          }
        />
      </Popover.Content>
    </Popover.Root>
  );
};
