import { useQuery } from '@tanstack/react-query';
import { EventConfigClient } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { ErrorTypes, useErrorBoundaryContext } from '../ErrorBoundaryContext';

export const useGetEventMapsByScoreModelConfig = (
  eventId?: number | null,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled &&
    activeAccountWebClientConfig.activeAccountId != null &&
    eventId != null;
  const venueMapsByScoreModelQuery = useQuery({
    queryKey: [
      'EventConfigClient.getVenueMapsByScoreModelForEvent',
      activeAccountWebClientConfig.activeAccountId,
      eventId,
      disabled,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const map = await new EventConfigClient(
        activeAccountWebClientConfig
      ).getVenueMapsByScoreModelForEvent(eventId!);

      return map;
    },

    enabled: shouldQuery,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'EventConfigClient.getVenueMapsByScoreModelForEvent',
          error,
          {
            eventId,
          }
        );
      },
    },
  });

  return venueMapsByScoreModelQuery;
};
