import { useContent, useFormattedContent } from 'src/contexts/ContentContext';
import { useEventMetrics } from 'src/contexts/EventMetricsContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect/PosEnumSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { SellerEventDashboardMetricsTimePeriod } from 'src/WebApiController';

import * as dashboardStyles from '../../DashboardSection.css';
import { HeaderWidgetContainer } from '../../HeaderWidgetContainer';
import { SellerEventPerformanceMetricWidgetsBody } from './SellerEventPerformanceMetricWidgetsBody';

export const SellerEventPerformanceMetricWidgetsContainer = () => {
  const { performanceMetrics } = useEventMetrics();

  const yourStatsText = useContent(ContentId.YourStats);
  const allTimeText = useContent(ContentId.AllTime);
  const last7DaysText = useFormattedContent(
    FormatContentId.LastNDays,
    '7'
  ) as string;
  const timePeriodValueOptionsContent = {
    [SellerEventDashboardMetricsTimePeriod.Last7Days]: last7DaysText,
    [SellerEventDashboardMetricsTimePeriod.AllTime]: allTimeText,
  };

  return (
    <>
      <HeaderWidgetContainer
        title={yourStatsText}
        action={
          <PosEnumSelect
            className={dashboardStyles.dashboardHeaderSelect}
            value={performanceMetrics.timePeriod}
            valueOptionsContent={timePeriodValueOptionsContent}
            onChange={performanceMetrics.updateTimePeriod}
            shape="none"
          />
        }
      />

      <SellerEventPerformanceMetricWidgetsBody
        performanceMetrics={performanceMetrics.metrics}
        isLoading={performanceMetrics.isLoading}
      />
    </>
  );
};
