import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { clsx } from 'clsx';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import {
  mergeProps,
  RADIX_COLLISION_PADDING,
  RADIX_SIDE_OFFSET,
  styledComponent,
} from 'src/core/utils';

import * as styles from './DropdownMenu.css';
import { label, separator } from './DropdownMenu.css';

export const Root = DropdownMenu.Root;

export const Trigger = DropdownMenu.Trigger;

export const Portal = DropdownMenu.Portal;

export const Group = DropdownMenu.Group;

// DON'T use it until this works with zoom. Radix-ui issue.
export const SubContent = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof DropdownMenu.SubContent>
>(function Content({ children, ...props }, ref) {
  return (
    <DropdownMenu.Portal>
      <DropdownMenu.SubContent
        ref={ref}
        sideOffset={RADIX_SIDE_OFFSET}
        collisionPadding={RADIX_COLLISION_PADDING}
        {...mergeProps({ className: styles.content }, props)}
      >
        {children}
      </DropdownMenu.SubContent>
    </DropdownMenu.Portal>
  );
});

export const Content = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof DropdownMenu.Content>
>(function Content({ children, ...props }, ref) {
  return (
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        ref={ref}
        sideOffset={RADIX_SIDE_OFFSET}
        collisionPadding={RADIX_COLLISION_PADDING}
        {...mergeProps({ className: styles.content }, props)}
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  );
});

export const Item = styledComponent(DropdownMenu.Item, styles.item);

export const Separator = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof DropdownMenu.Separator>
>(function Separator({ className, ...props }, ref) {
  return (
    <DropdownMenu.Separator {...props} className={clsx(separator, className)} />
  );
});

export const Label = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof DropdownMenu.Label>
>(function Label({ className, ...props }, ref) {
  return (
    <DropdownMenu.Label
      {...props}
      className={clsx(styles.item, label, className)}
    />
  );
});
