import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { useEventInternalNotes } from 'src/hooks/api/useEventInternalNotes';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { DetailSection } from 'src/modals/common';
import {
  CheckIcon,
  CrossIcon,
  EditIcon,
  IconsFill,
  ProcessingIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';

import * as styles from '../InventoryEventPage.css';

type NotesUpdateInput = {
  internalNote: string | null;
};

export const NoteSection = ({
  viagVirtualId,
  disabled,
}: {
  viagVirtualId: string;
  disabled?: boolean;
}) => {
  const { setValue, watch, handleSubmit } = useForm<NotesUpdateInput>();
  const internalNote = watch('internalNote') ?? [];
  const saveMsg = useContent(ContentId.Save);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const hasEventsEditPermission = useUserHasAnyOfPermissions(
    Permission.Events_Edit
  );

  const {
    internalNote: existingNote,
    updateInternalNote,
    isLoading,
  } = useEventInternalNotes(viagVirtualId, disabled);

  const onSubmitHandler = useCallback(
    async (data: NotesUpdateInput) => {
      setIsSubmitting(true);
      await updateInternalNote(data.internalNote);
      setIsSubmitting(false);
      setIsEditing(false);
    },
    [updateInternalNote]
  );

  useEffect(() => {
    if (existingNote && !isEqual(internalNote, existingNote)) {
      setValue('internalNote', existingNote);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingNote]);

  const hasChanges = internalNote !== existingNote;

  const onKeyDownHandler = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (!(disabled || isSubmitting)) {
        const key = e.key;
        if (key === 'Enter') {
          e.stopPropagation();
          handleSubmit(onSubmitHandler)();
        }
      }
    },
    [disabled, handleSubmit, isSubmitting, onSubmitHandler]
  );

  const inputFieldPosfix = (
    <>
      {isSubmitting ? (
        <RotatingWrapper>
          <ProcessingIcon size={vars.iconSize.l} />
        </RotatingWrapper>
      ) : (
        <>
          {hasChanges ? (
            <CheckIcon
              title={saveMsg}
              withHoverEffect
              size={vars.iconSize.s}
              fill={IconsFill.textSuccess}
              onClick={(e) => {
                e.stopPropagation();
                handleSubmit(onSubmitHandler)();
              }}
              disabled={disabled || isSubmitting}
            />
          ) : null}
          <CrossIcon
            withHoverEffect
            onClick={(e) => {
              e.stopPropagation();
              setValue('internalNote', existingNote ?? null);
              setIsEditing(false);
            }}
            disabled={disabled || isSubmitting}
          />
        </>
      )}
    </>
  );

  if (isLoading) {
    return <PosSpinner />;
  }

  return (
    <div className={styles.tableSection} style={{ maxWidth: '768px' }}>
      <DetailSection name={<Content id={ContentId.Notes} />}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: vars.spacing['lg'],
          }}
        >
          {isEditing ? (
            <PosTextField
              disabled={disabled || isSubmitting}
              value={internalNote ?? ''}
              type={'text'}
              onChange={(e) => {
                setValue('internalNote', e.target.value);
              }}
              postfixDisplay={inputFieldPosfix}
              maxLength={4000}
              onKeyDown={onKeyDownHandler}
            />
          ) : (
            <div>{internalNote}</div>
          )}
          {!isEditing && hasEventsEditPermission && (
            <EditIcon
              fill={IconsFill.textBrand}
              withHoverEffect
              disabled={disabled || isSubmitting}
              onClick={(e) => {
                e.stopPropagation();
                setIsEditing(true);
              }}
            />
          )}
        </div>
      </DetailSection>
    </div>
  );
};
