import * as Label from '@radix-ui/react-label';
import { useCallback, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';
import { UserGroupMultiSelector } from 'src/components/Selectors/UserGroupSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { useSellerRoleContext } from 'src/contexts/SellerRoleContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { Root as Input } from 'src/core/ui/TextField/TextField';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  SellerAccountClient,
  SellerUserAccountInfo,
} from 'src/WebApiController';

import { activeBadge, inactiveBadge } from '../UsersManagement.css';
import * as styles from './EditUserModal.css';

export const EditUserModalHeader = () => {
  return (
    <div className={styles.header}>
      <h5 className={styles.headerText}>
        <Content id={ContentId.EditUser} />
      </h5>
    </div>
  );
};

type EditUserModalFooterProps = { onClose: () => void };

type EditUserInput = {
  userId: string;
  displayName: string;
  email: string;
  roles: number[];
  userGroupIds: number[];
  isDeactivated: boolean;
};

export const EditUserModalFooter = ({ onClose }: EditUserModalFooterProps) => {
  const deactivateUserText = useContent(ContentId.DeactivateUser);
  const activateUserText = useContent(ContentId.ActivateUser);
  const cancelText = useContent(ContentId.Cancel);
  const saveText = useContent(ContentId.Save);

  const { loginContext } = useAppContext();

  const { handleSubmit, setError, getValues, clearErrors, watch } =
    useFormContext<EditUserInput>();
  const requiredMsg = useContent(ContentId.Required);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const deleteConfirmDialog = useBasicDialog();
  const { refetchUserInfos } = useSellerAccountContext();

  const deactivated = watch('isDeactivated');
  const isCurrentUser = loginContext?.user?.userId === getValues().userId;

  const onActivateSubmit = useCallback(
    async (formValue: EditUserInput) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const result = await new SellerAccountClient(
            activeAccountWebClientConfig
          ).activateUserForSellerAccount({ sellerUserId: formValue.userId });

          if (result) {
            refetchUserInfos();
            onClose();
          }
        },
        (error) => {
          showErrorDialog('SellerAccountClient.getSellerUserByEmail', error, {
            trackErrorData: formValue,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [activeAccountWebClientConfig, onClose, refetchUserInfos, showErrorDialog]
  );

  const onUpdateSubmit = useCallback(
    async (formValue: EditUserInput) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const result = await new SellerAccountClient(
            activeAccountWebClientConfig
          ).updateSellerUser({
            sellerUserId: formValue.userId,
            displayName: formValue.displayName,
            roles: formValue.roles,
            userGroupIds: formValue.userGroupIds,
          });

          if (result) {
            refetchUserInfos();
            onClose();
          }
        },
        (error) => {
          showErrorDialog('SellerAccountClient.updateSellerUser', error, {
            trackErrorData: formValue,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [activeAccountWebClientConfig, refetchUserInfos, onClose, showErrorDialog]
  );

  const onUpdateSubmitHandler = useCallback(() => {
    const formValue = getValues();

    const { displayName, roles } = formValue;

    clearErrors('displayName');
    clearErrors('roles');

    let hasErrors = false;

    if (!displayName) {
      setError('displayName', { message: requiredMsg });
      hasErrors = true;
    }

    if (!roles || !roles.length) {
      setError('roles', { message: requiredMsg });
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    handleSubmit(onUpdateSubmit)();
  }, [
    getValues,
    clearErrors,
    handleSubmit,
    onUpdateSubmit,
    setError,
    requiredMsg,
  ]);

  const onDeleteSubmit = useCallback(
    async (formValue: EditUserInput) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const result = await new SellerAccountClient(
            activeAccountWebClientConfig
          ).deactivateUserForSellerAccount({ sellerUserId: formValue.userId });

          if (result) {
            refetchUserInfos();
            onClose();
          }
        },
        (error) => {
          showErrorDialog(
            'SellerAccountClient.removeUserFromSellerAccount',
            error,
            {
              trackErrorData: formValue,
            }
          );
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [activeAccountWebClientConfig, refetchUserInfos, onClose, showErrorDialog]
  );

  return (
    <>
      <div className={styles.footer}>
        <div>
          {deactivated ? (
            <Button
              variant="outline"
              onClick={() => {
                handleSubmit(onActivateSubmit)();
              }}
              disabled={isLoading}
            >
              {activateUserText}
            </Button>
          ) : (
            <Button
              variant="outline"
              className={styles.cancel}
              onClick={() => {
                deleteConfirmDialog.launchDialog();
              }}
              disabled={isLoading || isCurrentUser}
            >
              {deactivateUserText}
            </Button>
          )}
        </div>
        <div className={styles.buttonGroup}>
          <Button variant="outline" onClick={onClose} disabled={isLoading}>
            {cancelText}
          </Button>
          <Button onClick={onUpdateSubmitHandler} disabled={isLoading}>
            {saveText}
          </Button>
        </div>
      </div>
      <ConfirmDialog
        {...deleteConfirmDialog.dialogProps}
        headerText={<Content id={ContentId.DeactivateUser} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        onOkay={handleSubmit(onDeleteSubmit)}
        disabled={isLoading}
        onCancel={() => deleteConfirmDialog.closeDialog()}
      />
    </>
  );
};

export const EditUserModal = ({
  userId,
  displayName,
  email,
  roles,
  userGroupIds,
  isDeactivated,
  onClose,
}: SellerUserAccountInfo & { onClose: () => void }) => {
  const methods = useForm<EditUserInput>({
    defaultValues: {
      userId,
      displayName,
      email,
      roles,
      userGroupIds,
      isDeactivated,
    },
  });

  return (
    <FormProvider {...methods}>
      <EditUserModalContent onClose={onClose} />
    </FormProvider>
  );
};

export const EditUserModalContent = ({ onClose }: { onClose: () => void }) => {
  const [isOpen, toggleIsOpen] = useToggle(true);

  const {
    formState: { errors },
    watch,
    register,
    clearErrors,
    setValue,
  } = useFormContext<EditUserInput>();

  const { allSellerRolesForSelection } = useSellerRoleContext();
  const displayName = watch('displayName');
  const email = watch('email');
  const roles = watch('roles');
  const isDeactivated = watch('isDeactivated');
  const userGroupIds = watch('userGroupIds');

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        toggleIsOpen();
        onClose();
      }}
      unmountOnClose
      clickOutsideToClose
      centered
      size="sm"
    >
      <ModalHeader
        onClose={() => {
          toggleIsOpen();
          onClose();
        }}
      >
        <EditUserModalHeader />
      </ModalHeader>
      <ModalBody>
        <div className={styles.root}>
          <div className={styles.formInputGroup}>
            <Label.Root className={styles.input} htmlFor="displayName">
              <Content id={ContentId.DisplayName} />
            </Label.Root>
            <PosFormField errors={errors.displayName?.message}>
              <PosTextField
                value={displayName}
                {...register('displayName')}
                onChange={(e) => {
                  clearErrors('displayName');
                  setValue('displayName', e.target.value);
                }}
              />
            </PosFormField>
          </div>
          <div className={styles.formInputGroup}>
            <Label.Root className={styles.input} htmlFor="email">
              <Content id={ContentId.Email} />
            </Label.Root>
            <Input id="email" shape="square" disabled>
              {email}
            </Input>
          </div>
          <div className={styles.formInputGroup}>
            <Label.Root className={styles.input} htmlFor="roleSelect">
              <Content id={ContentId.Role} />
            </Label.Root>
            {allSellerRolesForSelection ? (
              <PosFormField
                errors={errors.roles?.message}
                style={{ width: '100%' }}
              >
                <PosMultiSelect
                  id="roleSelect"
                  triggerProps={{ style: { width: '100%' } }}
                  values={roles.map((r) => r.toString())}
                  valueOptionsContent={allSellerRolesForSelection}
                  onChange={(newValues) => {
                    clearErrors('roles');
                    setValue(
                      'roles',
                      newValues.map((v) => parseInt(v))
                    );
                  }}
                />
              </PosFormField>
            ) : (
              <PosSpinner />
            )}
          </div>

          <div className={styles.formInputGroup}>
            <Label.Root className={styles.input} htmlFor="userGroupIds">
              <Content id={ContentId.Team} />
            </Label.Root>
            <PosFormField
              errors={errors.userGroupIds?.message}
              style={{ width: '100%' }}
            >
              <UserGroupMultiSelector
                id="userGroupIds"
                triggerProps={{ style: { width: '100%' } }}
                values={userGroupIds.map((r) => r.toString())}
                onChange={(newValues) => {
                  clearErrors('userGroupIds');
                  setValue(
                    'userGroupIds',
                    newValues.map((v) => parseInt(v))
                  );
                }}
              />
            </PosFormField>
          </div>

          <div className={styles.horizontalGroup}>
            <Label.Root className={styles.input} htmlFor="status">
              <Content id={ContentId.Status} />
            </Label.Root>
            <div id="status">
              {!isDeactivated ? (
                <div className={activeBadge}>
                  <Content id={ContentId.Active} />
                </div>
              ) : (
                <div className={inactiveBadge}>
                  <Content id={ContentId.Deactivated} />
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <EditUserModalFooter
          onClose={() => {
            toggleIsOpen();
            onClose();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};
