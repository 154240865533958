import { Content } from 'src/contexts/ContentContext';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { ListingDetails } from 'src/WebApiController';

import { HistoricalPricingGraph } from './HistoricalPricingGraph/HistoricalPricingGraph';

export const HistoricalPricingGraphSection = ({
  listing,
}: {
  listing: ListingDetails;
}) => {
  return (
    <DetailSection name={<Content id={ContentId.PricingHistory} />}>
      <SectionContent numOfColumns={1}>
        <DetailGroup style={{ gridColumn: '1' }}>
          <HistoricalPricingGraph listing={listing} />
        </DetailGroup>
      </SectionContent>
    </DetailSection>
  );
};
