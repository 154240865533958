import {
  ConnectedComparableListingDisplay,
  ConnectedComparableListingDisplayProps,
} from './ConnectedComparableListingDisplay';
import {
  FloorCeilingBarDisplay,
  FloorCeilingBarDisplayProps,
} from './FloorCeilingBarDisplay';
import {
  YourAutopriceResultsDisplay,
  YourAutopriceResultsDisplayProps,
} from './YourAutoPricerResultsDisplay';

export enum DisplayType {
  FloorDisplay = 'FloorDisplay',
  CeilingDisplay = 'CeilingDisplay',
  AutopricerDisplay = 'AutopricerDisplay',
  ComparableDisplay = 'ComparableDisplay',
}

export type ComparableListingItemProps = {
  displayType: DisplayType;
  itemProps:
    | FloorCeilingBarDisplayProps
    | YourAutopriceResultsDisplayProps
    | ConnectedComparableListingDisplayProps;
};

export const ComparableListingItem: React.FC<ComparableListingItemProps> = ({
  displayType,
  itemProps,
}) => {
  switch (displayType) {
    case DisplayType.FloorDisplay:
    case DisplayType.CeilingDisplay:
      return (
        <FloorCeilingBarDisplay
          {...(itemProps as FloorCeilingBarDisplayProps)}
          displayType={displayType}
        />
      );

    case DisplayType.AutopricerDisplay:
      return (
        <YourAutopriceResultsDisplay
          {...(itemProps as YourAutopriceResultsDisplayProps)}
        />
      );
    default:
      return (
        <ConnectedComparableListingDisplay
          {...(itemProps as ConnectedComparableListingDisplayProps)}
        />
      );
  }
};
