import { useContext } from 'react';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { EventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalHeader } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { SomethingWentWrong } from 'src/views';
import styled from 'styled-components/macro';

import { PosSpinner } from '../core/POS/PosSpinner';

export const useEventItemLoadingDisplay = <TEventItem extends IPosEntity>(
  loadingContentId: FormatContentId,
  searchingContentId: FormatContentId,
  errorContentId: FormatContentId,
  activeId?: string
) => {
  const { posEntityId, posEntityDisplayId, isLoading, posEntity, errorInfo } =
    useActivePosEntityContext<TEventItem>();

  const { closeModal } = useContext(ModalContext);

  if (isLoading) {
    return {
      isLoading,
      posEntity,
      loadingState: (
        <>
          <ModalHeader onClose={closeModal}>
            <EventEntityHeader title={<Content id={ContentId.ViewDetails} />} />
          </ModalHeader>
          <SpinnerWrapper>
            <PosSpinner />
            <FormatContent
              id={posEntityDisplayId ? loadingContentId : searchingContentId}
              params={
                posEntityDisplayId
                  ? [posEntityDisplayId]
                  : [activeId || posEntityId?.toString() || '']
              }
            />
          </SpinnerWrapper>
        </>
      ),
    };
  }
  // unknown error
  if (errorInfo) {
    return {
      isLoading,
      posEntity,
      loadingState: (
        <>
          <ModalHeader onClose={closeModal}>{null}</ModalHeader>
          <NotFoundWrapper>
            <SomethingWentWrong
              header={errorInfo.errorHeader}
              message={errorInfo.errorMessage}
            />
          </NotFoundWrapper>
        </>
      ),
    };
  }

  if (posEntity == null) {
    return {
      isLoading,
      posEntity,
      loadingState: (
        <>
          <ModalHeader onClose={closeModal}>{null}</ModalHeader>
          <NotFoundWrapper>
            <FormatContent
              id={errorContentId}
              params={activeId || posEntityId?.toString() || ''}
            />
          </NotFoundWrapper>
        </>
      ),
    };
  }

  return { isLoading, posEntity, loadingState: null };
};

const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.textPrimary};

  min-height: 300px;
`;

const NotFoundWrapper = styled(SpinnerWrapper)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textStrong};
`;
