import clsx from 'clsx';
import { ComponentProps, ReactNode } from 'react';
import { Stack } from 'src/core/ui';
import { mergeProps } from 'src/core/utils';

import * as styles from './PageSection.css';

type RootProps = {
  children: ReactNode;
};

export function Root({ children }: RootProps) {
  return <section className={styles.root}>{children}</section>;
}

type HeaderProps = Pick<ComponentProps<'div'>, 'className' | 'onClick'> & {
  action?: ReactNode;
  /**
   * Title of the section.
   */
  children?: ReactNode;
  headerClassName?: string;
};

/**
 * This can be used separately in places where the `PageSection` component doesn't support the
 * features required.
 * @param props
 */
export function Header({
  action,
  children,
  headerClassName,
  ...props
}: HeaderProps) {
  return (
    <Stack
      alignItems="center"
      {...mergeProps({ className: styles.header }, props)}
    >
      {children != null && (
        <h2 className={clsx(styles.headerTitle, headerClassName)}>
          {children}
        </h2>
      )}
      <hr className={styles.headerDivider} />
      {action != null && <div className={styles.headerAction}>{action}</div>}
    </Stack>
  );
}
