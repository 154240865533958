import { PaginationState, TableOptions } from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useSeatingColumnSorting } from 'src/tables/common/hooks/useSeatingColumnSorting';
import { SALE_TABLE_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleTableColumnsConfig';
import { SALE_TABLE_FLATTENED_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleTableFlattenedColumnsConfig';
import { SaleWithEvent } from 'src/tables/SalesTable/SalesTable.type';
import { Table } from 'src/tables/Table';
import { RowWrapper } from 'src/tables/Table/Table.types';
import { defaultSalesColumnsConfig } from 'src/utils/columns/columnUtils';
import {
  SalesTableColumnId,
  SalesTableFlattenedColumnId,
} from 'src/utils/columns/sales/salesColumnUtils.types';
import { getEventPerformerVenue } from 'src/utils/eventWithDataUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { Feature, Sale } from 'src/WebApiController';

const PAGE_SIZE = 10;

const defaultRecentSalesColumns = [
  SalesTableFlattenedColumnId.Event,
  SalesTableFlattenedColumnId.EventDate,
  SalesTableFlattenedColumnId.Venue,
  SalesTableFlattenedColumnId.Location,
];

export const RecentSalesTable = ({
  data,
  onRowWrapper,
}: {
  data: Sale[];
  onRowWrapper: RowWrapper<SaleWithEvent | null>;
}) => {
  const { data: catalog } = useCatalogDataContext();
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: PAGE_SIZE,
  });
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );

  const seatingColumnSorting = useSeatingColumnSorting<SaleWithEvent>(
    SalesTableColumnId.Seating,
    SectionType.Sales
  );

  const options: Partial<TableOptions<SaleWithEvent | null>> = useMemo(
    () => ({
      data: data
        .map((sale) => {
          const { event, performer, venue } = getEventPerformerVenue(
            sale.viagVirtualId,
            catalog
          );
          if (event) {
            return { event: event.event, sale, performer, venue };
          }

          return null;
        })
        ?.filter((s) => s != null)
        ?.map((s) => s!),
      columns: Array.from(
        new Set([...defaultRecentSalesColumns, ...defaultSalesColumnsConfig])
      ).map(
        (id) =>
          SALE_TABLE_COLUMNS_CONFIG[id as SalesTableColumnId] ||
          SALE_TABLE_FLATTENED_COLUMNS_CONFIG[id as SalesTableFlattenedColumnId]
      ),
      state: {
        pagination,
        columnPinning: {
          right: hasTablePinActionColumnFeature
            ? [SalesTableColumnId.Actions]
            : undefined,
        },
      },
      onPaginationChange: setPagination,
      sortingFns: { ...seatingColumnSorting },
    }),
    [
      data,
      hasTablePinActionColumnFeature,
      pagination,
      catalog,
      seatingColumnSorting,
    ]
  );

  return (
    <Table options={options} rowWrapper={onRowWrapper} showPageSizeOptions />
  );
};
