import clsx from 'clsx';
import {
  ComponentProps,
  ComponentPropsWithoutRef,
  ComponentType,
  forwardRef,
  ReactNode,
} from 'react';
import { vars } from 'src/core/themes';
import { Collapsible } from 'src/core/ui';
import { mergeProps, styledComponent } from 'src/core/utils';
import {
  ExpandIcon as ExpandIconDefault,
  FoldIcon as FoldIconDefault,
  IconsFill,
} from 'src/svgs/Viagogo';

import * as styles from './PosCollapsible.css';

export type PosCollapsibleProps = Pick<
  ComponentProps<typeof Collapsible.Root>,
  'open' | 'onOpenChange'
> & {
  trigger?: ReactNode;
  children?: ReactNode;
};

export function PosCollapsible({
  open,
  onOpenChange,
  trigger,
  children,
}: PosCollapsibleProps) {
  return (
    <Root open={open} onOpenChange={onOpenChange}>
      <Trigger>{trigger}</Trigger>
      <Content>{children}</Content>
    </Root>
  );
}

export const Root = styledComponent(Collapsible.Root, styles.root);

export const Trigger = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithoutRef<typeof Collapsible.Trigger> & {
    ExpandIcon?: ComponentType<{ size: string; fill: IconsFill }>;
    FoldIcon?: ComponentType<{ size: string; fill: IconsFill }>;
    iconSize?: string;
  }
>(function Content({ children, ...props }, ref) {
  const ExpandIcon = props.ExpandIcon ?? ExpandIconDefault;
  const FoldIcon = props.FoldIcon ?? FoldIconDefault;

  return (
    <Collapsible.Trigger
      ref={ref}
      asChild
      {...mergeProps(
        { className: clsx(styles.trigger, props.className) },
        props
      )}
    >
      <div>
        <div className={styles.triggerContent}>{children}</div>
        <div className={styles.triggerIconOpen}>
          <FoldIcon
            size={props.iconSize ?? vars.iconSize.m}
            fill={IconsFill.textPrimary}
          />
        </div>
        <div className={styles.triggerIconClosed}>
          <ExpandIcon
            size={props.iconSize ?? vars.iconSize.m}
            fill={IconsFill.textPrimary}
          />
        </div>
      </div>
    </Collapsible.Trigger>
  );
});

export const Content = styledComponent(Collapsible.Content, styles.content);
