import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const MultiEditCheckboxUnchecked = (props: PosIconProps) => {
  return (
    <SvgWrapper
      aria-label="MultiEditCheckboxUnchecked"
      {...props}
      viewBox="0 0 21 21" // The viewbox here is important as it won't display properly without this specific value
    >
      <path
        d="M3.75 5V3.25V3C3.75 1.89543 4.64543 1 5.75 1H17.25C18.9069 1 20.25 2.34315 20.25 4V16.25C20.25 17.3546 19.3546 18.25 18.25 18.25H18H16.3303"
        strokeWidth="1.5"
        stroke={props.stroke ?? 'currentColor'}
        fill={props.fill ?? 'transparent'}
      />
      <path
        d="M2.83333 4.75H14.1667C14.7192 4.75 15.2491 4.96949 15.6398 5.36019C16.0305 5.7509 16.25 6.2808 16.25 6.83333V18.1667C16.25 18.7192 16.0305 19.2491 15.6398 19.6398C15.2491 20.0305 14.7192 20.25 14.1667 20.25H2.83333C2.2808 20.25 1.7509 20.0305 1.36019 19.6398C0.969493 19.2491 0.75 18.7192 0.75 18.1667V6.83333C0.75 6.2808 0.969493 5.7509 1.36019 5.36019C1.7509 4.96949 2.2808 4.75 2.83333 4.75Z"
        fill={props.fill ?? 'transparent'}
        stroke={props.stroke ?? 'currentColor'}
        strokeWidth="1.5"
      />
    </SvgWrapper>
  );
};
