import { useMemo } from 'react';
import { MultiSelectionToggleGroup } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGroup';
import { MetricsDisplayPlaceholder } from 'src/components/Metrics';
import { PurchaseTileActionDropdown } from 'src/components/Purchases/PurchaseTileActionDropdown';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature, TicketGroupMetrics } from 'src/WebApiController';

import {
  EventItemHeader,
  EventItemHeaderType,
  METRICS_PLACEHOLDER_DIMS,
} from '../../Events/EventItemHeader';
import {
  ItemsCountDiv,
  TotalCostDiv,
} from '../PurchaseDatesAccordion/PurchaseDateAccordion.styled';
import { FullPageEventAction } from '../SalesEventsAccordion/FullPageEventAction';

export const EventTicketGroupHeader = (props: EventItemHeaderType) => {
  const { event, ...rest } = props;
  const { performer, venue } = useGetEventFullInfo(event);
  const { eventMetrics } = useCatalogMetricsContext<TicketGroupMetrics>();

  const hasBulkEditInPurchaseEventFeature = useUserHasFeature(
    Feature.BulkEditInPurchaseEvent
  );

  const metrics = useMemo(() => {
    return eventMetrics?.[event.viagVirtualId];
  }, [event.viagVirtualId, eventMetrics]);

  const totalCostText = useContent(ContentId.TotalCost);

  const {
    eventsExpansion: { expandedListItems, toggleListItemExpansion },
  } = useCatalogDataContext();

  const onToggleGroup = (groupId: string) => {
    if (!expandedListItems.includes(groupId)) {
      toggleListItemExpansion([groupId]);
    }
  };

  return (
    <EventItemHeader
      showYear={undefined}
      {...rest}
      event={event}
      performer={performer}
      venue={venue}
      actions={
        <>
          <FullPageEventAction key="FullEventPageToggle" event={event} />
          {hasBulkEditInPurchaseEventFeature
            ? [
                <MultiSelectionToggleGroup
                  key="TicketGroupEventSelectionGroupToggle"
                  groupId={props.event.viagVirtualId}
                  onToggle={onToggleGroup}
                />,
              ]
            : []}
          <PurchaseTileActionDropdown event={event} />
        </>
      }
      headerMetrics={
        metrics ? (
          <TotalCostDiv
            title={`${totalCostText}: ${metrics.ttlCst?.disp}`}
            key="total-cost"
          >
            {metrics.ttlCst?.disp}
          </TotalCostDiv>
        ) : (
          <MetricsDisplayPlaceholder
            key="metrics-placeholder"
            {...METRICS_PLACEHOLDER_DIMS}
          />
        )
      }
      metrics={
        metrics ? (
          <ItemsCountDiv key="items-count">
            <div>
              {metrics.tktQty}{' '}
              <Content
                id={metrics.tktQty === 1 ? ContentId.Ticket : ContentId.Tickets}
              />
            </div>
          </ItemsCountDiv>
        ) : (
          <MetricsDisplayPlaceholder
            key="metrics-placeholder"
            {...METRICS_PLACEHOLDER_DIMS}
          />
        )
      }
    />
  );
};
