import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  Event,
  Feature,
  ListingClient,
  PricingClient,
} from 'src/WebApiController';

import { useUserHasFeature } from './useUserHasFeature';

export const useGetEventAutoPricingSettings = (
  event: Event,
  getIsAllListingEnabledForAutoPricing: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  const shouldQuery = !(
    !activeAccountWebClientConfig.activeAccountId ||
    !event?.viagId ||
    !hasAutoPricingFeature
  );

  const pricingSettingsQuery = useQuery({
    queryKey: ['PricingClient.getEventAutoPricingSettings', event?.viagId],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const pricingSettings = await new PricingClient(
        activeAccountWebClientConfig
      ).getEventAutoPricingSettings(event!.viagId!);

      return pricingSettings;
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('PricingClient.getEventAutoPricingSettings', error, {
          trackErrorData: { event },
        });
      },
    },
  });

  const shouldQuery2 =
    getIsAllListingEnabledForAutoPricing &&
    activeAccountWebClientConfig.activeAccountId != null &&
    event?.viagId != null;

  const allListingEnabledPricingQuery = useQuery({
    queryKey: ['PricingClient.getListingEnabledForAutoPricing', event?.viagId],
    queryFn: async () => {
      if (!shouldQuery2) {
        return null;
      }
      const allListingEnabledPricing = await new ListingClient(
        activeAccountWebClientConfig
      ).getListingEnabledForAutoPricing(event.posIds);

      return allListingEnabledPricing;
    },

    refetchOnWindowFocus: false,
    enabled: shouldQuery2,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog(
          'PricingClient.getListingEnabledForAutoPricing',
          error,
          {
            trackErrorData: { event },
          }
        );
      },
    },
  });

  return {
    pricingSettings: pricingSettingsQuery.data,
    allListingEnabledPricing: allListingEnabledPricingQuery.data,
    pricingSettingsQuery,
    allListingEnabledPricingQuery,
  };
};
