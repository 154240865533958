import { useCallback } from 'react';
import { LaunchBulkUpdateInternalNotes } from 'src/components/common/BulkActions/BulkUpdateInternalNotesAction';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { BulkUpdateInternalNotesDialog } from 'src/dialogs/BulkEdits/BulkUpdateInternalNotesDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { PURCHASE_BULK_UPDATE_INTERNAL_NOTES_UPDATE_KEY } from '../../PurchaseActionDropdown.constants';

export const useBulkUpdateInternalNotesAction = (
  filterQueryWithEventIds: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const updateKey = groupId
    ? `purchase-internal-notes-event-${groupId}`
    : PURCHASE_BULK_UPDATE_INTERNAL_NOTES_UPDATE_KEY;
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkUpdateInternalNotesDialog,
    closeDialog: closeBulkUpdateInternalNotesDialog,
  } = useDialog(
    DialogId.BulkUpdateInternalNotes,
    BulkUpdateInternalNotesDialog
  );

  const onSubmit = useCallback(
    async (
      isUpdate: boolean | null,
      searchString: string | null,
      replaceString: string | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkUpdateInternalNotesPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateInternalNotesPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (
        isUpdate != null &&
        searchString != null &&
        replaceString != null
      ) {
        await tryInvokeApi(
          async () => {
            const purchaseClient = new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            );

            const succeeded = await purchaseClient.bulkUpdateInternalNotes(
              preview!.preview,
              updateKey,
              isUpdate,
              searchString,
              replaceString,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshData(true);
                await refreshExpandedListItems();
              }

              closeBulkUpdateInternalNotesDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateInternalNotes', error, {
              trackErrorData: {
                isUpdate,
                searchString,
                replaceString,
                preview,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQueryWithEventIds,
      showErrorDialog,
      updateKey,
      closeBulkUpdateInternalNotesDialog,
      setActivePosEntity,
      refreshData,
      refreshExpandedListItems,
    ]
  );

  const onChangeClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (groupId) {
        setListItemExpansion(true, [groupId]);
      }
      openBulkUpdateInternalNotesDialog({
        updateKey,
        onOkay: onSubmit,
        onCancel: closeBulkUpdateInternalNotesDialog,
        entityType: ActionOutboxEntityType.Purchase,
      });
    },
    [
      closeBulkUpdateInternalNotesDialog,
      groupId,
      onSubmit,
      openBulkUpdateInternalNotesDialog,
      setListItemExpansion,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkUpdateInternalNotes
        key="UpdateInternalNotes"
        onClick={onChangeClick}
        disabled={isLoading}
        entityType={ActionOutboxEntityType.Purchase}
      />
    ),
  };
};
