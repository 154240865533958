import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Switch } from 'src/core/interim/Switch';
import { Stack } from 'src/core/ui';
import { mergeProps } from 'src/core/utils';
import { ContentId } from 'src/utils/constants/contentId';
import { Seating } from 'src/WebApiController';

import { BulkBarcodeInput } from './BulkBarcodeInput';
import { ManualBarcodeEntry } from './ManualBarcodeEntry';
import * as styles from './UploadBarcodes.css';
import { BarcodeForm } from './UploadBarcodes.utils';

export type UploadBarcodesBodyProps = {
  isReadOnly?: boolean;
  ticketCnt: number;
  seating: Seating;
  instructionContentId?: ContentId;
  className?: string;
};
export const UploadBarcodesBody = ({
  isReadOnly,
  seating,
  ticketCnt,
  instructionContentId,
  className,
}: UploadBarcodesBodyProps) => {
  const { watch, setValue } = useFormContext<BarcodeForm>();

  const bulkUpload = watch('isBulkUpload');

  const inputProps = {
    readOnly: isReadOnly,
    seating,
    quantity: ticketCnt,
  };

  return (
    <div
      {...mergeProps(
        { className: styles.barcodesContentWrapper },
        { className: className }
      )}
    >
      {!isReadOnly && (
        <Content id={instructionContentId ?? ContentId.EnterBarcodesIns} />
      )}
      <Stack direction="row" gap="m" alignItems="center" justifyContent="end">
        <Content id={ContentId.BulkEntry} />
        <Switch
          checked={bulkUpload}
          onChange={() => setValue('isBulkUpload', !bulkUpload)}
          disabled={isReadOnly}
        />
      </Stack>
      {!bulkUpload ? (
        <ManualBarcodeEntry {...inputProps} />
      ) : (
        <BulkBarcodeInput
          {...inputProps}
          onPreview={() => setValue('isBulkUpload', !bulkUpload)}
        />
      )}
    </div>
  );
};
