import { EventMetricsProvider } from 'src/contexts/EventMetricsContext/EventMetricsContext';
import { Stack } from 'src/core/ui';
import { EventWithData } from 'src/WebApiController';

import * as styles from './DashboardSection.css';
import { EventSectionClearingPricesWidgetsContainer } from './Widgets/EventSectionClearingPrices';
import { EventSupplyWidgetsContainer } from './Widgets/EventSupply/EventSupplyContainer';
import { EventWebsiteMetricWidgetsContainer } from './Widgets/EventWebsiteMetric';
import { SellerEventPerformanceMetricWidgetsContainer } from './Widgets/SellerEventPerformanceMetric';

export const DashboardSection = ({ event }: { event: EventWithData }) => {
  return (
    <EventMetricsProvider event={event}>
      <Stack gap="xl" direction="column" className={styles.dashboardContainer}>
        <SellerEventPerformanceMetricWidgetsContainer />
        <EventSupplyWidgetsContainer />
        {/* Removed "Demand Factors" until we have more data to populate here. See SUP-639
       <EventDemandFactorWidgetsContainer event={event} />
        */}
        <EventWebsiteMetricWidgetsContainer />
        <EventSectionClearingPricesWidgetsContainer event={event.event} />
      </Stack>
    </EventMetricsProvider>
  );
};
