import { isEmpty } from 'lodash-es';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useEventLastReviewedDateTime } from 'src/hooks/api/useEventLastReviewedDateTime';

type IEventLastReviewedContext = {
  eventLastReviewed: Record<string, string>;
  isLoading: boolean;
  isCompleted: boolean;
  onUpdateLastReviewedDateTime: (virtualViaId: string) => void;
  onSettingVirtualIds: (virtualViaIds: string[]) => void;
};

export const EventLastReviewedContext =
  createContext<IEventLastReviewedContext>({
    eventLastReviewed: {},
    isLoading: false,
    isCompleted: false,
    onUpdateLastReviewedDateTime: (_: string) => undefined,
    onSettingVirtualIds: (_: string[]) => undefined,
  });

export const useEventLastReviewedContext = () =>
  useContext(EventLastReviewedContext);

export const EventLastReviewedContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [eventLastReviewed, setEventLastReviewedLookup] = useState<
    Record<string, string>
  >({});
  const [viagogoEventIds, setViagogoEventIds] = useState<string[]>([]);
  const {
    eventLastReviewed: queryData,
    isLoading,
    isCompleted,
    onUpdateLastReviewedDateTime,
  } = useEventLastReviewedDateTime(viagogoEventIds);

  useEffect(() => {
    if (!isEmpty(queryData)) {
      setEventLastReviewedLookup((prev) => ({ ...prev, ...queryData }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryData)]);

  const onSettingVirtualIds = useCallback(
    (virtualViaIds: string[]) => {
      const existingIds = Object.entries(eventLastReviewed)
        .filter(([_, viewedAt]) => !!viewedAt)
        .map(([id, _]) => id);
      const filtered = virtualViaIds.filter((id) => !existingIds.includes(id));
      if (!isEmpty(filtered)) {
        setViagogoEventIds(filtered);
      }
    },
    [eventLastReviewed]
  );

  return (
    <EventLastReviewedContext.Provider
      value={{
        isLoading,
        isCompleted,
        eventLastReviewed,
        onUpdateLastReviewedDateTime,
        onSettingVirtualIds,
      }}
    >
      {children}
    </EventLastReviewedContext.Provider>
  );
};
