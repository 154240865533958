import { useCallback, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { PurchaseVendorDialog } from 'src/dialogs/PurchaseVendorDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  PurchaseClient,
  PurchaseVendor,
} from 'src/WebApiController';

interface AddVendorAccountProps {
  onVendorAdded?: () => void;
}

export const AddVendorButton = ({ onVendorAdded }: AddVendorAccountProps) => {
  const vendorDialog = useBasicDialog();
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [currentVendor, setCurrentVendor] = useState<PurchaseVendor>();

  const onAddNewVendor = useCallback(() => {
    setCurrentVendor({
      id: -1,
      name: '',
      isGlobal: false,
      delivType: null,
      isNoFulfill: null,
      tktTypeRules: null,
      vendAccCounts: 0,
    });
    vendorDialog.launchDialog();
  }, [vendorDialog]);

  const onSaveVendor = useCallback(
    async (vendor: PurchaseVendor) => {
      await tryInvokeApi(
        async () => {
          const newId = await new PurchaseClient(
            activeAccountWebClientConfig
          ).mergeVendor(vendor);

          if (newId) {
            onVendorAdded?.();
            vendorDialog.closeDialog();
            setCurrentVendor(undefined);
          } else {
            // This should never happen
            // Normal errors would have fallen to the error catch
            showErrorDialog(
              'PurchaseClient.mergeVendor',
              {
                message: 'Unable to create or save vendor ' + vendor.id,
                status: 500,
              } as ApiException,
              {
                trackErrorData: vendor,
              }
            );
          }
        },
        (error) => {
          showErrorDialog('PurchaseClient.mergeVendor', error, {
            trackErrorData: vendor,
          });
        }
      );
    },
    [activeAccountWebClientConfig, onVendorAdded, vendorDialog, showErrorDialog]
  );

  const onVendorCancel = useCallback(() => {
    setCurrentVendor(undefined);
    vendorDialog.closeDialog();
  }, [vendorDialog]);

  return (
    <>
      <Button onClick={onAddNewVendor}>
        <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
        <span style={{ whiteSpace: 'nowrap' }}>
          <Content id={ContentId.NewVendor} />
        </span>
      </Button>
      {currentVendor && (
        <PurchaseVendorDialog
          {...vendorDialog.dialogProps}
          vendor={currentVendor}
          onClosed={onVendorCancel}
          onSave={onSaveVendor}
          useReducedEdit={true}
        />
      )}
    </>
  );
};
