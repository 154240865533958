import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import {
  SECTION_TYPE_TO_CUSTOM_COLUMN_SETTING,
  SectionType,
} from 'src/utils/types/sectionType';

export const CustomColumnName = ({
  id,
  sectionType,
}: {
  id: string;
  sectionType: SectionType;
}) => {
  const { value: customColumns = [] } = useServerUserSetting<
    CustomListingColumn[]
  >({
    id: SECTION_TYPE_TO_CUSTOM_COLUMN_SETTING[sectionType],
  });

  const customColumnName = customColumns.find((c) => c.id === id)?.name ?? '';

  return (
    <span
      style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}
      title={customColumnName}
    >
      {customColumnName}
    </span>
  );
};
