import { Content } from 'src/contexts/ContentContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { Event, Venue } from 'src/WebApiController';

import {
  EventDateDisplay,
  EventNameDisplay,
  VenueNameDisplay,
} from '../../EventInfo';
import * as styles from './EventSearchDropdownResult.css';

export const EventSearchResultItem = ({
  event,
  venue,
}: {
  event: Event;
  venue?: Venue | null;
}) => {
  let eventName = event.name;
  let eventSubName = '';
  const lastIndex = eventName.lastIndexOf(' - ');
  if (lastIndex > 0) {
    eventSubName = eventName.slice(lastIndex + 3);
    eventName = eventName.slice(0, lastIndex);
  }

  const subnameIsVenue =
    venue?.name &&
    eventSubName.toLocaleLowerCase().includes(venue.name.toLocaleLowerCase());

  return (
    <div className={styles.eventSearchResultItemDiv}>
      <Stack direction="column" alignItems="start">
        <Stack gap="m" flexWrap="wrap" alignItems="center">
          <EventNameDisplay
            eventName={eventName}
            eventNameConnector={
              subnameIsVenue || !eventSubName ? undefined : ' - '
            }
            eventSubName={subnameIsVenue ? undefined : eventSubName}
          />
          <EventDateDisplay
            eventDateString={event.dates?.start ?? undefined}
            eventEndDateString={event.dates?.end ?? undefined}
            eventDateFormat={'MMM d, y'}
            showEventDateTbd
          />
        </Stack>
        {event.viagId == null && (
          <WarningMessage message={<Content id={ContentId.NotMappedYet} />} />
        )}
        <VenueNameDisplay
          venueName={venue?.name}
          venueLocation={venue?.descr}
        />
      </Stack>
    </div>
  );
};
