import { useMemo } from 'react';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import {
  PosMultiSelect,
  PosMultiSelectProps,
} from 'src/core/POS/PosMultiSelect';

export const CurrencyFilterMultiSelector = (
  props: Omit<PosMultiSelectProps, 'searchable' | 'valueOptionsContent'>
) => {
  const { currencyCache } = useLocalizationContext();

  const availableCurrenciesOptions = useMemo(
    () =>
      Object.values(currencyCache || []).reduce(
        (result, cur) => {
          result[cur.code] = cur.code;
          return result;
        },
        {} as Record<string, string>
      ),
    [currencyCache]
  );

  return (
    <PosMultiSelect
      {...props}
      searchable
      valueOptionsContent={availableCurrenciesOptions}
    />
  );
};
