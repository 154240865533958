import { useContent, useFormattedContent } from 'src/contexts/ContentContext';
import { useEventMetrics } from 'src/contexts/EventMetricsContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EventWebsiteMetricsTimePeriod } from 'src/WebApiController';

import * as styles from '../../DashboardSection.css';
import { HeaderWidgetContainer } from '../../HeaderWidgetContainer';
import { EventWebsiteMetricWidget } from './EventWebsiteMetricWidget';

export const EventWebsiteMetricWidgetsContainer = () => {
  const {
    websiteMetrics: { metrics, timePeriod, updateTimePeriod },
  } = useEventMetrics();

  const rightNowText = useContent(ContentId.RightNow);
  const last1HourText = useContent(ContentId.Last1Hour);
  const last24HoursText = useFormattedContent(
    FormatContentId.LastNHours,
    '24'
  ) as string;
  const timePeriodValueOptionsContent = {
    [EventWebsiteMetricsTimePeriod.OneHour]: last1HourText,
    [EventWebsiteMetricsTimePeriod.OneDay]: last24HoursText,
  };

  return (
    <>
      <HeaderWidgetContainer
        title={rightNowText}
        action={
          <PosEnumSelect
            className={styles.dashboardHeaderSelect}
            value={timePeriod}
            valueOptionsContent={timePeriodValueOptionsContent}
            onChange={updateTimePeriod}
            shape="none"
          />
        }
      />
      <Stack direction="row" gap="xl" width="full">
        <EventWebsiteMetricWidget
          titleContentId={ContentId.TicketsOnPlatform}
          metric={metrics?.ticketsOnPlatform}
        />
        <EventWebsiteMetricWidget
          titleContentId={ContentId.EventVisitors}
          metric={metrics?.eventVisitors}
        />
        <EventWebsiteMetricWidget
          titleContentId={ContentId.InCheckout}
          metric={metrics?.visitorsInCheckout}
        />
        <EventWebsiteMetricWidget
          titleContentId={ContentId.DollarsInBasket}
          metric={metrics?.totalTicketPriceInCheckout}
        />
      </Stack>
    </>
  );
};
