import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ReportV2Client } from 'src/WebApiController';

export const useGetReportTableColumnIdMapping = () => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const listingReportColumnIdToAggregateMappingQuery = useQuery({
    queryKey: ['ReportV2Client.getListingReportColumnIdToAggregateMapping'],
    queryFn: async () => {
      const results = await new ReportV2Client(
        activeAccountWebClientConfig
      ).getListingReportColumnIdToAggregateMapping();

      return results;
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'ReportV2Client.getListingReportColumnIdToAggregateMapping',
          error
        );
      },
    },
  });

  const listingReportColumnIdToGroupingMappingQuery = useQuery({
    queryKey: ['ReportV2Client.getListingReportColumnIdToGroupingMapping'],
    queryFn: async () => {
      const results = await new ReportV2Client(
        activeAccountWebClientConfig
      ).getListingReportColumnIdToGroupingMapping();

      return results;
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'ReportV2Client.getListingReportColumnIdToGroupingMapping',
          error
        );
      },
    },
  });

  const saleReportColumnIdToAggregateMappingQuery = useQuery({
    queryKey: ['ReportV2Client.getSaleReportColumnIdToAggregateMapping'],
    queryFn: async () => {
      const results = await new ReportV2Client(
        activeAccountWebClientConfig
      ).getSaleReportColumnIdToAggregateMapping();

      return results;
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'ReportV2Client.getSaleReportColumnIdToAggregateMapping',
          error
        );
      },
    },
  });

  const saleReportColumnIdToGroupingMappingQuery = useQuery({
    queryKey: ['ReportV2Client.getSaleReportColumnIdToGroupingMapping'],
    queryFn: async () => {
      const results = await new ReportV2Client(
        activeAccountWebClientConfig
      ).getSaleReportColumnIdToGroupingMapping();

      return results;
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'ReportV2Client.getSaleReportColumnIdToGroupingMapping',
          error
        );
      },
    },
  });

  return {
    listingReportColumnIdToAggregateMappingQuery,
    listingReportColumnIdToGroupingMappingQuery,
    saleReportColumnIdToAggregateMappingQuery,
    saleReportColumnIdToGroupingMappingQuery,
  };
};
