import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { Permission } from 'src/WebApiController';

interface BulkBroadcastActionsPermission {
  hasBroadcastPermission: boolean;
  hasSellerAccountAdminPermission: boolean;
}

export const useBulkBroadcastActionsPermission =
  (): BulkBroadcastActionsPermission => {
    const hasBroadcastPermission = useUserHasAnyOfPermissions(
      Permission.Inventory_BroadcastUnbroadcast,
      Permission.Inventory_BroadcastUnbroadcastOnIsPricerOf,
      Permission.Inventory_BroadcastUnbroadcastOnPurchasedBy
    );

    const hasSellerAccountAdminPermission = useUserHasAnyOfPermissions(
      Permission.SellerAccount_Admin
    );

    return {
      hasBroadcastPermission,
      hasSellerAccountAdminPermission,
    };
  };
