import { useMemo } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useReportMetricsContext } from 'src/contexts/ReportMetricsContext';
import { PosFloatingSpinner } from 'src/core/POS/PosFloatingSpinner';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import SaleReportTable from 'src/tables/SalesTable/SaleReportTable';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID } from 'src/utils/columns/sales/salesReportColumnUtils.constants';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleReportMetricsWithGroupBy } from 'src/utils/reportsUtils';
import { SaleReportMetrics } from 'src/WebApiController';

import { ReportsFilterToolbar } from '../Reports/ReportsFilterToolbar';
import * as styles from './ReportsSalePage.css';
import {
  mapReportMetrics,
  mapReportMetricsSummary,
} from './ReportsSalePage.utils';

export function ReportsSalePage({
  reportConfig,
}: {
  reportConfig: ReportConfig;
}) {
  const {
    reportMetrics,
    reportSummary,
    totalCount,
    isLoading,
    isRefreshing,
    refreshMetrics,
  } = useReportMetricsContext<SaleReportMetrics>();
  const { data, eventsTransformed } = useCatalogDataContext();

  const reportMetricsMapped: SaleReportMetricsWithGroupBy[] | undefined =
    useMemo(
      () => mapReportMetrics(reportMetrics, eventsTransformed, data),
      [data, eventsTransformed, reportMetrics]
    );

  const reportSummaryMapped: SaleReportMetricsWithGroupBy | undefined = useMemo(
    () =>
      mapReportMetricsSummary(reportConfig, reportSummary, reportMetricsMapped),
    [reportConfig, reportMetricsMapped, reportSummary]
  );

  const initSorting = useMemo(() => {
    const sortBy =
      reportConfig.sortBy ??
      GROUP_BY_TO_PRIMARY_COLUMN_ID[reportConfig.groupBy];

    return [
      {
        id: sortBy,
        desc: reportConfig.isSortDescending ?? false,
      },
    ];
  }, [
    reportConfig.groupBy,
    reportConfig.isSortDescending,
    reportConfig.sortBy,
  ]);

  return (
    <div className={styles.root}>
      {reportConfig.reportName && (
        <h1 className={styles.pageName}>{reportConfig.reportName}</h1>
      )}
      <ReportsFilterToolbar reportConfig={reportConfig} />
      {isLoading || !reportConfig ? (
        <PosSpinner />
      ) : (
        <div className={styles.tableContainer}>
          <SaleReportTable
            className={styles.table}
            metricsData={reportMetricsMapped}
            summaryData={reportSummaryMapped}
            totalCount={totalCount}
            report={reportConfig}
            refreshMetrics={refreshMetrics}
            initState={{
              sorting: initSorting,
            }}
          />
          {isRefreshing && (
            <PosFloatingSpinner
              message={<Content id={ContentId.DataIsRefreshing} />}
            />
          )}
        </div>
      )}
    </div>
  );
}
