import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

import { TileWrapper } from '../Tile/Tile.styled';

export const SaleEventTileWrapper = styled(TileWrapper)`
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};
`;

export const SaleTilesContainer = styled.div`
  ${SaleEventTileWrapper}:last-child {
    border-bottom: 0;
    padding-bottom: ${() => vars.spacing.sm};
  }
`;

export const Status = styled.span``;

export const Price = styled.span``;

export const UnHideIconContainer = styled.span`
  display: flex;
  ${({ theme: { typography } }) => typography.getStyles('body1', 'medium')}
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};
`;

export const DetailLabel = styled.span<{ textAlign?: TextAlign }>`
  ${({ theme: { typography } }) => typography.getStyles('caption1', 'regular')}
  color: ${({ theme: { colors } }) => colors.textPrimary};
  text-align: ${(props) =>
    props.textAlign ? props.textAlign.toString() : 'left'};
`;

export const DetailValue = styled.span<{ textAlign?: TextAlign }>`
  ${({ theme: { typography } }) => typography.getStyles('caption1', 'regular')}
  text-align: ${(props) =>
    props.textAlign ? props.textAlign.toString() : 'left'};
  padding-right: ${() => vars.spacing.xxs};
`;

export const DetailUnitCostValue = styled.span`
  ${({ theme: { typography } }) => typography.getStyles('caption1', 'regular')}
  display: flex;
  align-items: center;
  padding-right: ${() => vars.spacing.xxs};
`;

export enum TextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export const OtherDetailsDiv = styled.div`
  display: flex;
  color: ${({ theme: { colors } }) => colors.textPrimary};
  font-size: ${() => vars.typography.fontSize['xs']};
`;
