import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const MainLabel = styled.div`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title4')};
  margin-bottom: ${() => vars.spacing.sm};
`;

export const SplitListQuickOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${() => vars.spacing.lg};
  & > div {
    flex: 1;
    display: flex;
    gap: ${() => vars.spacing.xxs};
    align-items: center;
  }
`;
export const SplitListingTableContainer = styled.table`
  border-collapse: collapse;
  margin-top: ${() => vars.spacing.lg};
  width: 100%;
  max-width: 690px;
  border-radius: ${() => vars.spacing.xxs};
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
  table-layout: fixed;

  thead {
    background-color: ${(props) => props.theme.colors.backgroundPrimary};

    .header {
      ${(props) => props.theme.typography.getStyles('body2', 'regular')}
      color: ${(props) => props.theme.colors.textPrimary};
      background-color: ${(props) => props.theme.colors.backgroundSecondary};
    }
  }

  tr {
    &.even {
      background-color: ${(props) => props.theme.colors.backgroundSecondary};
    }
  }

  td,
  th {
    border: 1px solid transparent;
    background-clip: padding-box;
    vertical-align: middle;
    padding: ${() => `${vars.spacing.xxs} ${vars.spacing.sm}`};
  }

  tr > td + td,
  tr > th + th {
    border-left-width: ${() => vars.spacing.m};
  }

  tr + tr > td {
    border-top-width: 8px;
  }
`;

export const SoldTicketContainer = styled.div`
  color: ${(props) => props.theme.colors.textDisabled};
  text-align: center;
`;
