import { formatInTimeZone } from 'date-fns-tz';
import { useNavigate } from 'react-router-dom';
import { ConnectedEventInfo } from 'src/components/Events/ConnectedEventInfo';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { TooltipPopover } from 'src/core/ui/TooltipPopover';
import { OpenLinkIcon } from 'src/svgs/OpenLinkIcon';
import { getActivityLogChangeDisplay } from 'src/utils/activityLogUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { getTimeAgoString, stringToUtcDate } from 'src/utils/dateTimeUtils';
import { getListingDetailsRelativeUrl } from 'src/utils/inventoryUtils';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { getSaleDetailsRelativeUrl } from 'src/utils/saleUtils';
import {
  ActionOutboxEntityType,
  ActivityLog,
  ActivityLogValueChange,
  MarketplaceOperationResultState,
} from 'src/WebApiController';

import * as styles from './ActivityLogDisplay.css';

/**
 * Maximum number of lines we will show for additional info.
 *
 * If there are more than this value, 1 less will be shown and the rest will be hidden in a see
 * more.
 */
const MAX_ADDITIONAL_INFO_LINES = 3;

export type ActivityLogDisplayProps = {
  activityLog: ActivityLog;
};

export function ActivityLogDisplay({
  activityLog: {
    actionTypeDisplay,
    actionDate: actionDateStr,
    entityId,
    entityType,
    additionalInfo,
    initiatedBy: initiatedByUser,
    initiatedByImpersonated,
    event,
    performer,
    venue,
  },
}: ActivityLogDisplayProps) {
  const { timeZone } = useSiteTimezoneContext();
  const actionDate = new Date(stringToUtcDate(actionDateStr));
  const removedContent = useContent(ContentId.Empty);
  const navigate = useNavigate();

  const entityUrl = (() => {
    switch (entityType) {
      case ActionOutboxEntityType.Sale:
        return getSaleDetailsRelativeUrl(entityId);

      case ActionOutboxEntityType.Listing:
        return getListingDetailsRelativeUrl(entityId);

      case ActionOutboxEntityType.Purchase:
        return getPurchaseOrderRelativeUrl(entityId);

      case ActionOutboxEntityType.SellerSupportCase:
        return new URL(
          'http://add-deep-link-to-support-case-view-when-its-created'
        );

      case ActionOutboxEntityType.Document:
        return null;
    }
  })();
  const timeAgo = getTimeAgoString(actionDate);
  const additionalInfoKeys = Object.keys(additionalInfo);
  const actionTypeIntent = (() => {
    for (const entry of Object.values<ActivityLogValueChange>(additionalInfo)) {
      if (
        entry.message?.state &&
        entry.message.state !== MarketplaceOperationResultState.Succeeded
      ) {
        return 'error';
      }
    }
    return 'success';
  })();

  const initiatedBy = (
    <span>
      by {initiatedByUser?.username}
      {initiatedByImpersonated?.username
        ? ` (impersonating ${initiatedByImpersonated.username})`
        : ''}
    </span>
  );
  return (
    <Stack direction="column" gap="m">
      <Stack justifyContent="spaceBetween" gap="m" alignItems="start">
        <div className={styles.headerLeft}>
          <div>
            <span className={styles.actionType[actionTypeIntent]}>
              {actionTypeDisplay}
            </span>
            {timeAgo && (
              <>
                &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                <span
                  title={`${formatInTimeZone(
                    actionDate,
                    timeZone,
                    'LLL do yyyy, h:mm:ss aaa'
                  )}`}
                >
                  {timeAgo}
                </span>
              </>
            )}
          </div>
          {additionalInfoKeys.length === 0
            ? initiatedBy
            : (additionalInfoKeys.length <= MAX_ADDITIONAL_INFO_LINES
                ? additionalInfoKeys
                : additionalInfoKeys.slice(0, MAX_ADDITIONAL_INFO_LINES - 1)
              ).map((key, i) => (
                <div key={key} className={styles.additionalInfoEntryWrapper}>
                  <span className={styles.additionalInfoEntry}>
                    {getActivityLogChangeDisplay(
                      key,
                      additionalInfo[key],
                      removedContent
                    )}
                  </span>
                  {i === 0 && <> {initiatedBy}</>}
                </div>
              ))}
          {additionalInfoKeys.length > MAX_ADDITIONAL_INFO_LINES && (
            <TooltipPopover
              variant="textPlain"
              textColor="primary"
              triggerContent={<Content id={ContentId.SeeMoreEllipses} />}
            >
              {additionalInfoKeys.slice(2).map((key) => (
                <div key={key} className={styles.additionalInfoEntry}>
                  {getActivityLogChangeDisplay(
                    key,
                    additionalInfo[key],
                    removedContent
                  )}
                </div>
              ))}
            </TooltipPopover>
          )}
        </div>
        {entityUrl && (
          <Button
            variant="link"
            shape="inline"
            onClick={() => {
              navigate(entityUrl.toString());
            }}
          >
            <OpenLinkIcon size={vars.iconSize.m} />
          </Button>
        )}
      </Stack>
      <ConnectedEventInfo event={event} performer={performer} venue={venue} />
    </Stack>
  );
}
