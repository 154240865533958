import { useEffect, useState } from 'react';

import { SignalStep0Icon } from './SignalStep0Icon';
import { SignalStep1Icon } from './SignalStep1Icon';
import { SignalStep2Icon } from './SignalStep2Icon';
import { SignalStep3Icon } from './SignalStep3Icon';
import { PosIconProps } from './SvgWrapper';

export function AnimatedBroadcastIcon({
  ...props
}: Omit<PosIconProps, 'width' | 'height'>) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setStep((step + 1) % 4);
    }, 200);
    return () => clearInterval(timer);
  });

  return (
    <>
      {step === 0 && <SignalStep0Icon {...props} />}
      {step === 1 && <SignalStep1Icon {...props} />}
      {step === 2 && <SignalStep2Icon {...props} />}
      {step === 3 && <SignalStep3Icon {...props} />}
    </>
  );
}
