import { useRef } from 'react';
import { useDebounce } from 'react-use';
import * as styles from 'src/core/POS/PosSelect/PosSelect.css';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { CheckIcon, CrossIcon, IconsFill } from 'src/svgs/Viagogo';

interface InputSearchTestProps {
  searchPlaceholder: string;
  onChange: (value: string) => void;
  onSearchTextApplied: (value: string) => void;
  disabled: boolean;
  allowSearchAsInput: boolean;
  searchInput: string;
}

export const SelectInputSearch = ({
  searchPlaceholder,
  onChange,
  onSearchTextApplied,
  searchInput,
  allowSearchAsInput,
  disabled,
}: InputSearchTestProps) => {
  const refInput = useRef<HTMLInputElement | null>(null);

  useDebounce(
    () => {
      onChange(searchInput);
      setTimeout(() => refInput.current?.focus(), 10);
    },
    200,
    [searchInput, onChange]
  );
  return (
    <div className={styles.searchBoxContainer}>
      <PosTextField
        ref={(input: HTMLInputElement) => {
          refInput.current = input;
          setTimeout(() => refInput.current?.focus(), 0);
        }}
        value={searchInput}
        placeholder={searchPlaceholder}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onChange(e.target.value);
        }}
        onTouchEnd={(e) => {
          // Note: This event is mobile only to explicitly trap focus on click
          // onClick won't work properly on mobile with Radix UI
          //
          // see more in `src\core\ui\Select\Select.tsx :: Select.Content`
          // and https://github.com/radix-ui/primitives/issues/1658
          e.currentTarget.focus();
        }}
        onKeyDown={(e) => {
          if (!disabled) {
            const key = e.key;
            if (key === 'Enter') {
              e.stopPropagation();

              // See if search-text matches any option, return it as a selection, else return searchText
              if (searchInput) {
                onSearchTextApplied(searchInput);
              }
            }
          }
        }}
        postfixDisplay={
          searchInput && (
            <>
              {allowSearchAsInput && (
                <div
                  style={{
                    display: 'flex',
                  }}
                  onTouchEnd={(e) => {
                    // Note: This event is mobile only to explicitly handle event on click
                    // onClick won't work properly on mobile with Radix UI
                    //
                    // see more in `src\core\ui\Select\Select.tsx :: Select.Content`
                    // and https://github.com/radix-ui/primitives/issues/1658
                    e.stopPropagation();
                    e.preventDefault();
                    onSearchTextApplied(searchInput);
                  }}
                >
                  <CheckIcon
                    fill={IconsFill.textSuccess}
                    withHoverEffect
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onSearchTextApplied(searchInput);
                    }}
                  />
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                }}
                onTouchEnd={(e) => {
                  // Note: This event is mobile only to explicitly handle event on click
                  // onClick won't work properly on mobile with Radix UI
                  //
                  // see more in `src\core\ui\Select\Select.tsx :: Select.Content`
                  // and https://github.com/radix-ui/primitives/issues/1658
                  e.stopPropagation();
                  e.preventDefault();
                  onChange('');
                }}
              >
                <CrossIcon
                  size={vars.iconSize.m}
                  withHoverEffect
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onChange('');
                  }}
                />
              </div>
            </>
          )
        }
      />
    </div>
  );
};
