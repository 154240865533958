import { HTMLProps, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { PosCustomDragData } from './PosCustomDragLayer';
import * as styles from './PosDragNDrop.css';

export const DraggableSource = 'DnDDraggable';

export function PosDraggable<T>({
  draggableData,
  previewDragData,
  children,
  hideElementWhileDragging,
  draggableSource,
  ...rest
}: {
  draggableData: T;
  previewDragData?: PosCustomDragData;
  hideElementWhileDragging?: boolean;
  draggableSource?: string;
} & Omit<HTMLProps<HTMLDivElement>, 'ref' | 'as'>) {
  const dragContainerRef = useRef<HTMLDivElement | null>(null);

  const [{ isDragging }, dragRef, dragPreview] = useDrag(
    () => ({
      type: draggableSource || DraggableSource,
      item: () => {
        document.body.classList.add('dragging');
        return {
          data: { ...draggableData, pageRef: dragContainerRef },
          dragData: previewDragData,
        };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
      end: () => document.body.classList.remove('dragging'),
    }),
    [draggableSource, previewDragData, draggableData, dragContainerRef]
  );

  useEffect(() => {
    dragPreview(getEmptyImage(), { captureDraggingState: false });
  }, [dragPreview]);

  dragRef(dragContainerRef);

  return (
    <div
      className={styles.draggableContainer}
      ref={dragContainerRef}
      style={
        hideElementWhileDragging
          ? { visibility: isDragging ? 'hidden' : 'inherit' }
          : undefined
      }
      {...rest}
    >
      {children}
    </div>
  );
}
