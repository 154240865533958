import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const OpenLinkIcon = ({ fill, stroke, ...props }: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16" fill="none">
      <path
        d="M6.5 3H3.15139C2.72665 3 2.31142 3.12572 1.95801 3.36132V3.36132C1.3595 3.76033 1 4.43206 1 5.15139V12.4393C1 13.1185 1.26978 13.7698 1.75 14.25V14.25C2.23022 14.7302 2.88153 15 3.56066 15H10.4393C11.1185 15 11.7698 14.7302 12.25 14.25V14.25C12.7302 13.7698 13 13.1185 13 12.4393V9.5M6 10C6.4 9.6 12.1667 3.83333 15 1M15 1H10M15 1V6.5"
        stroke={stroke ?? fill ?? 'currentColor'}
        strokeLinecap="round"
      />
    </SvgWrapper>
  );
};
