import clsx from 'clsx';
import { useContext } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { typography } from 'src/core/themes/shared.css';
import * as styles from 'src/modals/ColumnSettings/ColumnSettings.css';
import { ModalHeader } from 'src/modals/Modal';
import * as vendorDetailsStyles from 'src/modals/VendorDetails/VendorDetails.css';
import { ContentId } from 'src/utils/constants/contentId';

export const VendorDetailsModalHeader = () => {
  const { closeModal } = useContext(ModalContext);

  return (
    <ModalHeader onClose={closeModal}>
      <div className={clsx(styles.headerBar, vendorDetailsStyles.modalHeader)}>
        <h5 className={clsx(typography.title5, styles.headerText)}>
          <Content id={ContentId.Details} />
        </h5>
      </div>
    </ModalHeader>
  );
};
