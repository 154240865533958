import { FormatContent } from 'src/contexts/ContentContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Stack } from 'src/core/ui';
import { FormatOption } from 'src/modals/EditTableColumns';
import { SalesTableFlattened } from 'src/tables/SalesTable/SalesTableFlattened';
import {
  SalesTableColumnId,
  SalesTableFlattenedColumnId,
} from 'src/utils/columns/sales/salesColumnUtils.types';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { BulkEditPreviewWithDetails } from 'src/WebApiController';

export const BulkEditPreviewSaleDetails = ({
  preview,
}: {
  preview?: BulkEditPreviewWithDetails | null;
}) => {
  if (!preview?.sales) {
    return null;
  }

  return (
    <Stack
      direction="column"
      style={{ height: '50vh' }}
      gap="l"
      alignItems="center"
    >
      {preview.sales.length <
        (preview?.preview?.bulkEditCountPreview?.Sale?.totalCount ?? 0) && (
        <WarningMessage
          message={
            <FormatContent
              id={FormatContentId.BulkEditMaxItemPreviewDisplayMessage}
              params={[
                `${preview.sales.length}`,
                `${preview?.preview?.bulkEditCountPreview?.Sale?.totalCount}`,
              ]}
            />
          }
        />
      )}

      <SalesTableFlattened
        sales={preview.sales}
        salesCount={preview.sales.length}
        useVirtuoso
        failedToRetrieveData={false}
        disablePagination
        disableSelection
        columnVisibilityOverride={[
          SalesTableColumnId.Sale,
          SalesTableFlattenedColumnId.Event,
          SalesTableFlattenedColumnId.EventDate,
          SalesTableColumnId.Seating,
          SalesTableColumnId.Actions,
        ]}
        formatOptionOverrides={{
          [SalesTableColumnId.Seating]: FormatOption.Seating_FullSeating,
          [SalesTableFlattenedColumnId.Event]: FormatOption.Event_EventName,
          [SalesTableFlattenedColumnId.EventDate]:
            FormatOption.DateTime_Month_DD_YY_Time,
          [SalesTableColumnId.Actions]: FormatOption.Actions_LinkOnly,
        }}
        withOuterPadding={false}
      />
    </Stack>
  );
};
