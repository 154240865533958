import { ContentId } from 'src/utils/constants/contentId';

export type PreviewColumn = {
  setting: ContentId;
  from: React.ReactNode;
  to: React.ReactNode;
  include: boolean;
  onChange: (checked: boolean) => void;
};

export enum PricingSettingsPreviewColumnId {
  Setting = 'setting',
  SettingFrom = 'settingFrom',
  SettingTo = 'settingTo',
  Checkbox = 'checkbox',
}
