import { isEmpty } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EventWithData } from 'src/WebApiController';
import {
  Feature,
  ListingGroup,
  ListingGroupItemInput,
  MergeListingGroupInput,
} from 'src/WebApiController';

import { MergeListingGroupInputListFields } from './components/groupingTypes';
import { flattenListingGroup } from './components/groupingUtils';

export const useFullySoldFilter = (
  events: EventWithData[],
  listingGroup?: ListingGroup
) => {
  const [excludeFullySold, setExcludeFullySold] = useState<boolean>(false);
  const hasExcludeFullySoldInGroupFeature = useUserHasFeature(
    Feature.ExcludeFullySoldInGroup
  );

  const { watch, setValue, formState } =
    useFormContext<MergeListingGroupInputListFields>();

  const mergeListingGroupInputs = watch('mergeListingGroupInputs');

  const fullySoldListingIds = useMemo(
    () =>
      events
        .flatMap((ev) => (ev.listings ?? []).flatMap(flattenListingGroup))
        .filter((l) => l.id > 0 && l.availQty <= 0)
        .map((l) => l.id),
    [events]
  );

  const hasFullySoldListings = useMemo(() => {
    if (listingGroup) {
      return (
        (formState?.defaultValues?.mergeListingGroupInputs?.[0]
          ?.listingGroupItems || []) as ListingGroupItemInput[]
      ).some((item) => fullySoldListingIds.includes(item!.listingId!));
    }
    return !isEmpty(fullySoldListingIds);
  }, [
    formState?.defaultValues?.mergeListingGroupInputs,
    fullySoldListingIds,
    listingGroup,
  ]);

  const sanitizedEvents = useMemo(() => {
    if (
      hasExcludeFullySoldInGroupFeature &&
      excludeFullySold &&
      hasFullySoldListings
    ) {
      return events.map((event) => ({
        ...event,
        listings: (event.listings || []).filter(
          ({ id }) => !fullySoldListingIds.includes(id)
        ),
      }));
    }
    return events;
  }, [
    hasExcludeFullySoldInGroupFeature,
    excludeFullySold,
    hasFullySoldListings,
    events,
    fullySoldListingIds,
  ]);

  const onToggleExcludeFullySold = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      if (isChecked) {
        const clonedInputs = [...mergeListingGroupInputs];
        for (const [index, input] of clonedInputs.entries()) {
          clonedInputs[index] = {
            ...input,
            listingGroupItems: (input.listingGroupItems || [])
              .filter(
                ({ listingId }) => !fullySoldListingIds.includes(listingId)
              )
              .map((item, idx) => ({
                ...item,
                priority: idx + 1,
              })),
          };
        }
        setValue('mergeListingGroupInputs', clonedInputs);
      } else {
        // Reset to default values
        if (listingGroup && formState?.defaultValues?.mergeListingGroupInputs) {
          setValue(
            'mergeListingGroupInputs',
            (formState.defaultValues.mergeListingGroupInputs ||
              []) as MergeListingGroupInput[]
          );
        }
      }
      setExcludeFullySold(isChecked);
    },
    [
      formState.defaultValues?.mergeListingGroupInputs,
      fullySoldListingIds,
      listingGroup,
      mergeListingGroupInputs,
      setValue,
    ]
  );

  const showExcludeFullySold = useMemo(
    () => hasExcludeFullySoldInGroupFeature && hasFullySoldListings,
    [hasFullySoldListings, hasExcludeFullySoldInGroupFeature]
  );

  return {
    excludeFullySold,
    showExcludeFullySold,
    mergeListingGroupInputs,
    sanitizedEvents,
    onToggleExcludeFullySold,
  };
};
