import clsx from 'clsx';
import { Content } from 'src/contexts/ContentContext';
import { ChevronDownIcon, ChevronUpIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './SelectedPerformerVenueListDivider.css';

export const SelectedPerformerVenuesDivider = ({
  selectedCount,
  totalCount,
  isOpened,
  onClick,
}: {
  selectedCount: number;
  totalCount: number;
  isOpened?: boolean;
  onClick?: () => void;
}) => {
  const clickable = Boolean(onClick);
  const upIcon = isOpened ? <ChevronUpIcon /> : <ChevronDownIcon />;
  return (
    <div
      onClick={onClick}
      className={clsx(styles.selectedPerformerVenuesDividerContainer, {
        [styles.selectedPerformerVenuesDividerContainerClickable]: !!onClick,
      })}
    >
      <div className={styles.dividerLine} />
      {clickable && upIcon}
      <div className={styles.selectedCount}>
        {selectedCount} / {totalCount} <Content id={ContentId.Selected} />
      </div>
      {clickable && upIcon}
      <div className={styles.dividerLine} />
    </div>
  );
};
