import { ComponentProps, useCallback, useContext } from 'react';
import { useBulkBroadcastActionsPermission } from 'src/components/common/BulkActions/BulkBroadcastActions/useBulkBroadcastActionsPermission';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { GroupListingsModal } from 'src/modals/GroupListings';
import { ContentId } from 'src/utils/constants/contentId';
import { Event } from 'src/WebApiController';

export const LaunchGroupListings = ({
  slideLeft,
  events,
  disabled,
}: ComponentProps<typeof GroupListingsModal> & {
  slideLeft?: boolean;
  events?: Event[];
  disabled?: boolean;
}) => {
  const { hasBroadcastPermission } = useBulkBroadcastActionsPermission();

  const { setModal } = useContext(ModalContext);
  const {
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();

  const groupListings = useCallback(() => {
    setModal({
      children: <GroupListingsModal events={events} />,
      clickOutsideToClose: true,
      ...{ size: slideLeft ? 'slide-left' : 'slide-in' },
    });
  }, [setModal, events, slideLeft]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (events?.length) {
        setListItemExpansion(
          true,
          events.map((ev) => ev.viagVirtualId)
        );
      }

      groupListings();
    },
    [events, groupListings, setListItemExpansion]
  );

  if (!hasBroadcastPermission) {
    return null;
  }

  return (
    <PosDropdownItem
      key="GroupListings"
      onClick={onLaunchDialog}
      disabled={disabled}
    >
      <Stack alignItems="center" gap="m" width="full">
        <Content id={ContentId.GroupListings} />
      </Stack>
    </PosDropdownItem>
  );
};
