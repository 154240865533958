import { RecipeVariants } from '@vanilla-extract/recipes';
import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react';
import { mergeProps } from 'src/core/utils';

import * as styles from './Button.css';

export type ButtonProps = ComponentPropsWithoutRef<'button'> &
  RecipeVariants<typeof styles.button> & {
    justifyContent?: 'start' | 'center' | 'space-between';
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  function Button(
    {
      variant,
      textColor,
      shape,
      size,
      children,
      justifyContent = 'center',
      ...props
    },
    ref
  ): ReactElement {
    return (
      <button
        ref={ref}
        type="button"
        {...mergeProps(
          { className: styles.button({ variant, textColor, shape, size }) },
          props
        )}
      >
        <div
          className={styles.buttonContainer}
          style={{ justifyContent: justifyContent }}
        >
          {children}
        </div>
      </button>
    );
  }
);
