import clsx from 'clsx';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useToggle } from 'react-use';
import { EventAccordionItemProps } from 'src/components/Accordions';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { EventWithData } from 'src/WebApiController';
import { Feature } from 'src/WebApiController';

import * as styles from '../../Events/EventPage/EventPage.css';

type PurchaseEventContentProps = {
  accordionItemProps?: EventWithData;
  purchaseOrderAccordionItem?: EventAccordionItemProps;
  selectedPurchaseOrderIds: number[];
  setSelectedPurchaseOrderIds: Dispatch<SetStateAction<number[]>>;
};

export function PurchaseEventInfoSection({
  accordionItemProps,
  purchaseOrderAccordionItem,
  selectedPurchaseOrderIds,
  setSelectedPurchaseOrderIds,
}: PurchaseEventContentProps) {
  const { BodyComponent, AfterHeaderComponent } =
    purchaseOrderAccordionItem || {};
  const { onScroll, isCollapsedView, isEnabled } = useCollapsableViewContext();

  const hasEventListingTableHotkeysFeature = useUserHasFeature(
    Feature.EventListingTableHotkeys
  );
  const { selectionMode } = useMultiSelectionContext();
  const isSelectionModeOn = !!selectionMode?.mode;

  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isColumnModalOpen) toggleColumnModal(true);
    },
    [isColumnModalOpen, toggleColumnModal]
  );

  return (
    <div className={styles.tableSection}>
      {BodyComponent && accordionItemProps && (
        <div
          className={clsx(
            styles.tableContainer,
            styles.eventPageTableContainer
          )}
        >
          <Stack justifyContent="end" className={styles.hoistedColumnSettings}>
            {isColumnModalOpen ? (
              <ColumnSettingsModal<CustomListingColumn>
                onClose={toggleColumnModal}
                sectionType={SectionType.PurchaseEvent}
              />
            ) : null}
            <Stack width="full" direction="column" alignItems="end">
              <Button
                variant="textPlain"
                onClick={onColumnSettingButtonClickHandler}
              >
                <LayoutIcon size={vars.iconSize.m} />
                <Content id={ContentId.Columns} />
              </Button>
            </Stack>
          </Stack>

          <div className={styles.bulkActionsStickyWrapper}>
            {AfterHeaderComponent && <AfterHeaderComponent />}
          </div>
          <div
            className={clsx({
              [styles.eventDetailsBodyWrapper]: isEnabled,
              [styles.eventDetailsBodyPadding]:
                !isSelectionModeOn && !isCollapsedView,
            })}
          >
            <BodyComponent
              {...accordionItemProps}
              selectedIds={selectedPurchaseOrderIds}
              onItemSelectionsChanged={(newSelectedIds: number[]) => {
                setSelectedPurchaseOrderIds(newSelectedIds);
              }}
              selectionMode={{ mode: MultiSelectScope.SingleItem }}
              disablePagination
              useTableNavKeys={hasEventListingTableHotkeysFeature}
              useVirtuoso
              withOuterPadding={false}
              onVirtuosoTableScroll={onScroll}
            />
          </div>
        </div>
      )}
    </div>
  );
}
