import { ChangeEvent, useMemo } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Switch } from 'src/core/interim/Switch';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosSelect } from 'src/core/POS/PosSelect';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { Slider } from 'src/core/ui/Slider';
import { Switch as CoreSwitch } from 'src/core/ui/Switch';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { SectionSelectDialog } from 'src/dialogs/SectionSelectDialog';
import { useAvailableAutopricingModes } from 'src/hooks/useAvailableAutopricingModes';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useCompQuantitiesFilter } from 'src/hooks/useCompQuantitiesFilter';
import { useUndercutAmountSettings } from 'src/hooks/useUndercutAmountSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DetailGroup } from 'src/modals/common';
import { RestrictSelectionType } from 'src/modals/ListingDetails/components/PricingSection/AutoPricingSection.types';
import { EditIcon, IconsFill, PlusIcon, ResetIcon } from 'src/svgs/Viagogo';
import {
  convertSliderValueFromServer,
  convertSliderValueToServer,
  getUndercutPriceRepresentingMode,
  SLIDER_MAX,
  SLIDER_MIN,
  UNDERCUT_PRICE_MODE_OPTIONS,
  UndercutPriceSettingsMode,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DefaultQuantityFilterOption,
  getMinQuantityOptions,
} from 'src/utils/inventoryUtils';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import {
  AutoPricingCompListingMode,
  AutoPricingUndercutMode,
  Feature,
  ListingGroup,
} from 'src/WebApiController';

import * as styles from './SectionRowIdFilter.css';
import { useListingGroupAutoPricingSettings } from './useListingGroupAutoPricingSettings';

export const AutoPricingSettingsSection = ({
  listingGroup,
  disabled,
}: {
  listingGroup?: ListingGroup;
  disabled?: boolean;
}) => {
  const sectionSelectDialog = useBasicDialog();
  const {
    uiCurrency,

    onResetSettings,
    eventPricingSettings,
    accountPricingSettings,

    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,

    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingSelectedSectionSettings,
    compListingOnlyForSelectedSectionsEnabled,
    compListingOnlyForSameZoneEnabled,
    compListingQuantityScoreAdjustmentOverrideJson,

    compListingFloorError,
    compListingSelectedSectionError,
    undercutAbsoluteAmountError,
    undercutRelativeAmountError,

    onUndercutChange,
    onCompListingChange,

    resetListingAutoPricingSettings,
    setResetListingAutoPricingSettings,
  } = useListingGroupAutoPricingSettings(listingGroup);

  const {
    undercutAbsoluteAmountMode,
    setUndercutAbsoluteAmountMode,
    undercutRelativeAmountMode,
    setUndercutRelativeAmountMode,
  } = useUndercutAmountSettings(undercutAbsoluteAmount, undercutRelativeAmount);

  const parsedQuantityOverridesJson = JSON.parse(
    compListingQuantityScoreAdjustmentOverrideJson || 'null'
  );
  const quantityFilterValue = parsedQuantityOverridesJson?.quantityFilter;

  const { activeConfigOverride, venueMapInfo, venueMapsByScoreModelQuery } = useEventMapContext();

  const defaultScoreOverrides = useMemo(() => {
    // In the backend, if AVG scores are missing, then we always fallback to MVG, even if AVG is explicitly selected
    return (
      venueMapsByScoreModelQuery?.data?.AdvancedVenueGeometry?.sectionScores ??
      venueMapsByScoreModelQuery?.data?.MergedVenueGeometry?.sectionScores ??
      venueMapsByScoreModelQuery?.data?.VenueGeometry?.sectionScores
    );
  }, [
    venueMapsByScoreModelQuery?.data?.AdvancedVenueGeometry?.sectionScores,
    venueMapsByScoreModelQuery?.data?.MergedVenueGeometry?.sectionScores,
    venueMapsByScoreModelQuery?.data?.VenueGeometry?.sectionScores,
  ]);

  const scores = getCompleteEventConfigScoreOverrides(
    defaultScoreOverrides,
    activeConfigOverride?.scoreOverrides,
    false
  );

  const hasPricingMultiSelectCompQuantitiesFeature = useUserHasFeature(
    Feature.PricingMultiSelectCompQuantities
  );

  const {
    quantitiesFilterOptions,
    quantitiesFilterValue,
    onUpdateQuantitiesFilter,
  } = useCompQuantitiesFilter(
    compListingQuantityScoreAdjustmentOverrideJson,
    onCompListingChange
  );

  const restrictSelection = Boolean(
    compListingOnlyForSameZoneEnabled ||
      compListingOnlyForSelectedSectionsEnabled
  );

  const showCompListingSettingsDetails = !(
    !compListingMode ||
    compListingMode === AutoPricingCompListingMode.SameZone ||
    compListingMode === AutoPricingCompListingMode.SameEvent ||
    compListingMode === AutoPricingCompListingMode.ParentListing
  );
  const showCompListingRestictSelectionDetails =
    compListingMode != null &&
    (compListingMode === AutoPricingCompListingMode.QualityScore ||
      compListingMode === AutoPricingCompListingMode.SameEvent ||
      compListingMode === AutoPricingCompListingMode.SameZone);

  const {
    compListingModes,
    compListingModesDisabled,
    undercutModes,
    undercutModesDisabled,
    restrictSelectionTypes,
  } = useAvailableAutopricingModes(
    true,
    undercutMode,
    compListingMode,
    Boolean(scores?.some((s) => s.score)),
    false,
    false,
    compListingOnlyForSelectedSectionsEnabled
  );

  const selectedSectionsPills = useMemo(
    () =>
      compListingSelectedSectionSettings?.sectionIdFilter
        ?.map((sectionId) => {
          const section = venueMapInfo?.sections.find(
            (section) => sectionId === section.id
          );
          return {
            value: section?.id.toString() ?? '',
            display: section?.name ?? '',
          };
        })
        .filter((info) => info.value && info.display) ?? [],
    [
      compListingSelectedSectionSettings?.sectionIdFilter,
      venueMapInfo?.sections,
    ]
  );

  const isDealScoreMode =
    (undercutMode ??
      eventPricingSettings?.undercutMode ??
      accountPricingSettings?.undercutMode) === AutoPricingUndercutMode.Deal;

  return (
    <>
      <DetailGroup style={{ gridColumn: '1' }}>
        <Stack gap="l">
          <div className={styles.autoPricingLabel}>
            <Content id={ContentId.ClearListingAutoPricingSettings} />
          </div>
          <CoreSwitch
            checked={Boolean(resetListingAutoPricingSettings)}
            onChange={(e) => e.stopPropagation()}
            onCheckedChange={(isChecked) => {
              if (isChecked !== resetListingAutoPricingSettings) {
                setResetListingAutoPricingSettings(isChecked);
              }
            }}
          />
        </Stack>
      </DetailGroup>
      <DetailGroup
        style={{ gridColumn: '1' }}
        className={styles.autoPricingLabel}
      >
        <Content id={ContentId.CompListingModePrompt} />
      </DetailGroup>
      <DetailGroup style={{ gridColumn: '1' }}>
        <Stack gap="l">
          <div className={styles.inlinePricingInputText}>
            <Content id={ContentId.AllListings} />
          </div>
          <PosEnumSelect
            value={
              compListingMode ??
              eventPricingSettings?.compListingMode ??
              accountPricingSettings?.compListingMode
            }
            disabled={disabled}
            valueOptionsContent={compListingModes}
            valueOptionsDisabled={compListingModesDisabled}
            placeholderText={ContentId.None}
            onClick={(e) => e.stopPropagation()}
            onChange={(v) => {
              if (v) {
                onCompListingChange({
                  compListingModeNew: v as AutoPricingCompListingMode,
                });
              } else {
                onCompListingChange({
                  compListingModeNew: null,
                  compListingFloorNew: null,
                  compListingCeilingNew: null,
                });
              }

              if (v === AutoPricingCompListingMode.ParentListing) {
                onUndercutChange(AutoPricingUndercutMode.Simple);
              }
            }}
          />
        </Stack>
      </DetailGroup>

      {showCompListingSettingsDetails && (
        <>
          <DetailGroup style={{ gridColumn: '1' }}>
            <Stack gap="l">
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.Between} />
              </div>
              <PosFormField
                errors={compListingFloorError}
                style={{ width: 'fit-content' }}
              >
                <PosTextField
                  rootProps={{
                    state: getTextFieldState(compListingFloorError),
                    style: { maxWidth: '100px' },
                  }}
                  type="number"
                  value={
                    compListingMode == null
                      ? ''
                      : roundToPrecision((compListingFloor - 1) * 100, 2)
                  }
                  onChange={(e) => {
                    const v = parseFloat(e.target.value);
                    if (!isNaN(v)) {
                      if (
                        v >= SLIDER_MIN * 100 &&
                        v <= SLIDER_MAX * 100 &&
                        convertSliderValueToServer(v) < compListingCeiling
                      ) {
                        onCompListingChange({
                          compListingFloorNew: convertSliderValueToServer(v),
                        });
                      }
                    }
                  }}
                  postfixDisplay="%"
                  placeholder={
                    compListingMode == null
                      ? eventPricingSettings?.compListingFloor?.toString() ??
                        accountPricingSettings?.compListingFloor?.toString() ??
                        undefined
                      : undefined
                  }
                />
              </PosFormField>
              <div className={styles.inlinePricingInputTextLowercase}>
                <Content id={ContentId.And} />
              </div>
              <PosFormField style={{ width: 'fit-content' }}>
                <PosTextField
                  rootProps={{
                    style: { maxWidth: '100px' },
                  }}
                  type="number"
                  value={
                    compListingMode == null
                      ? ''
                      : roundToPrecision((compListingCeiling - 1) * 100, 2)
                  }
                  onChange={(e) => {
                    const v = parseFloat(e.target.value);
                    if (!isNaN(v)) {
                      if (
                        v >= SLIDER_MIN * 100 &&
                        v <= SLIDER_MAX * 100 &&
                        convertSliderValueToServer(v) > compListingFloor
                      ) {
                        onCompListingChange({
                          compListingCeilingNew: convertSliderValueToServer(v),
                        });
                      }
                    }
                  }}
                  postfixDisplay="%"
                  placeholder={
                    compListingMode == null
                      ? eventPricingSettings?.compListingCeiling?.toString() ??
                        accountPricingSettings?.compListingCeiling?.toString() ??
                        undefined
                      : undefined
                  }
                />
              </PosFormField>
              <div className={styles.inlinePricingInputTextLowercase}>
                <Content id={ContentId.OfMyListingQuality} />
              </div>
            </Stack>
          </DetailGroup>
          <DetailGroup style={{ gridColumn: '1' }}>
            <Stack direction="column" gap="m">
              <Slider.Root
                disabled={disabled}
                min={SLIDER_MIN * 100}
                max={SLIDER_MAX * 100}
                step={0.01}
                minStepsBetweenThumbs={0.01}
                value={[compListingFloor, compListingCeiling].map(
                  convertSliderValueFromServer
                )}
                renderHandle={(val) => (
                  <Slider.Handle>{roundToPrecision(val, 0)}%</Slider.Handle>
                )}
                onValueChange={([floor, ceil]) => {
                  onCompListingChange({
                    compListingFloorNew: convertSliderValueToServer(floor),
                    compListingCeilingNew: convertSliderValueToServer(ceil),
                  });
                }}
              />
              <div className={styles.yourListingIndicatorContainer}>
                <Content id={ContentId.YourListing} />
                <div className={styles.yourListingIndicatorArrow} />
              </div>
            </Stack>
          </DetailGroup>
        </>
      )}
      <DetailGroup
        style={{ gridColumn: '1', width: '100%', overflow: 'hidden' }}
      >
        {hasPricingMultiSelectCompQuantitiesFeature ? (
          <PosFormField
            label={<Content id={ContentId.QuantitiesFilter} />}
            style={{ width: '100%' }}
          >
            <PosMultiSelect
              disabled={disabled}
              align="start"
              values={quantitiesFilterValue ?? []}
              onChange={onUpdateQuantitiesFilter}
              valueOptionsContent={quantitiesFilterOptions}
            />
          </PosFormField>
        ) : (
          <PosFormField
            label={<Content id={ContentId.MinQuantity} />}
            style={{ width: '100%' }}
          >
            <PosSelect
              disabled={disabled}
              style={{ width: 'fit-content' }}
              searchable={true}
              // Value must be string for PosSelect
              value={
                quantityFilterValue?.toString() ?? DefaultQuantityFilterOption
              }
              valueOptionsContent={getMinQuantityOptions()}
              placeholderText={ContentId.None}
              onChange={(newValue) => {
                const val = parseInt(newValue);

                const quantityOverrideJson = JSON.parse(
                  compListingQuantityScoreAdjustmentOverrideJson || 'null'
                );

                const quantityOverrideJsonNew = {
                  ...quantityOverrideJson,
                  quantityFilter: !isNaN(val) ? val : null,
                };

                onCompListingChange({
                  compListingQuantityScoreAdjustmentOverrideJsonNew:
                    JSON.stringify(quantityOverrideJsonNew),
                });
              }}
            />
          </PosFormField>
        )}
      </DetailGroup>

      {showCompListingRestictSelectionDetails && (
        <DetailGroup style={{ gridColumn: '1 / span 3' }}>
          <PosFormField label={<Content id={ContentId.RestrictSelection} />}>
            <Switch
              disabled={disabled}
              checked={restrictSelection}
              onClick={(e: ChangeEvent<HTMLElement>) => e.stopPropagation()}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked) {
                  if (
                    !compListingOnlyForSameZoneEnabled &&
                    !compListingOnlyForSelectedSectionsEnabled
                  ) {
                    // set zone by default
                    onCompListingChange({
                      compListingOnlyForSameZoneEnabledNew: true,
                      compListingOnlyForSelectedSectionsEnabledNew: false,
                    });
                  }
                } else {
                  onCompListingChange({
                    compListingOnlyForSameZoneEnabledNew: false,
                    compListingOnlyForSelectedSectionsEnabledNew: false,
                  });
                }
              }}
            />
          </PosFormField>
          {restrictSelection && (
            <Stack gap="l">
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.RestrictTo} />
              </div>
              <PosEnumSelect
                value={
                  compListingOnlyForSameZoneEnabled
                    ? RestrictSelectionType.SameZone
                    : RestrictSelectionType.SpecificSections
                }
                disabled={disabled}
                valueOptionsContent={restrictSelectionTypes}
                placeholderText={ContentId.None}
                onClick={(e) => e.stopPropagation()}
                onChange={(v) => {
                  if (v === RestrictSelectionType.SameZone) {
                    onCompListingChange({
                      compListingOnlyForSameZoneEnabledNew: true,
                      compListingOnlyForSelectedSectionsEnabledNew: false,
                    });
                  } else {
                    onCompListingChange({
                      compListingOnlyForSameZoneEnabledNew: false,
                      compListingOnlyForSelectedSectionsEnabledNew: true,
                    });
                  }
                }}
              />
            </Stack>
          )}
          {compListingOnlyForSelectedSectionsEnabled && (
            <PosFormField
              errors={compListingSelectedSectionError}
              style={{ width: 'fit-content' }}
            >
              <Stack direction="column" gap="l" alignItems="start">
                {compListingSelectedSectionSettings?.sectionIdFilter?.length ? (
                  <>
                    <Content id={ContentId.SelectedSections} />
                    <PillList pills={selectedSectionsPills} disabled />
                    <ButtonWithIcon
                      variant="textPlain"
                      icon={
                        <EditIcon
                          fill={IconsFill.textBrand}
                          size={vars.iconSize.s}
                          withHoverEffect
                        />
                      }
                      textContentId={ContentId.EditSections}
                      onClick={sectionSelectDialog.launchDialog}
                      disabled={disabled}
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    />
                  </>
                ) : (
                  <ButtonWithIcon
                    variant="textPlain"
                    shape="none"
                    icon={
                      <PlusIcon
                        fill={IconsFill.textBrand}
                        size={vars.iconSize.s}
                        withHoverEffect
                      />
                    }
                    textContentId={ContentId.SelectSections}
                    onClick={sectionSelectDialog.launchDialog}
                    disabled={disabled}
                    style={{ padding: 0 }}
                  />
                )}
              </Stack>
            </PosFormField>
          )}
        </DetailGroup>
      )}
      <DetailGroup
        style={{ gridColumn: '1' }}
        className={styles.autoPricingLabel}
      >
        <Content id={ContentId.UnderCutModePrompt} />
      </DetailGroup>
      <DetailGroup style={{ gridColumn: '1' }}>
        <Stack gap="l">
          <div className={styles.inlinePricingInputText}>
            <Content id={ContentId.FindThe} />
          </div>
          <PosEnumSelect
            value={undercutMode}
            disabled={disabled}
            valueOptionsContent={undercutModes}
            valueOptionsDisabled={undercutModesDisabled}
            placeholderText={ContentId.None}
            onClick={(e) => e.stopPropagation()}
            onChange={(v) => {
              if (v) {
                onUndercutChange(v as AutoPricingUndercutMode);
              } else {
                onUndercutChange(null, null, null);
              }
            }}
          />
          <div className={styles.inlinePricingInputText}>
            <Content id={ContentId.OfTheComparableListings} />
          </div>
        </Stack>
      </DetailGroup>
      {isDealScoreMode && (
        <div className={styles.inlinePricingInputText}>
          <Content id={ContentId.BestDealScorePrompt} />
        </div>
      )}
      <DetailGroup style={{ gridColumn: '1' }}>
        <Stack gap="l">
          <ToggleGroup
            disabled={!undercutMode || !undercutRelativeAmount}
            options={UNDERCUT_PRICE_MODE_OPTIONS}
            value={undercutRelativeAmountMode}
            onValueChange={(value) => {
              if (!value) return;

              const mode = value as UndercutPriceSettingsMode;
              setUndercutRelativeAmountMode(mode);

              if (undercutRelativeAmount) {
                const curAmountAbs = Math.abs(undercutRelativeAmount);
                onUndercutChange(
                  undefined,
                  undefined,
                  getUndercutPriceRepresentingMode(curAmountAbs, mode)
                );
              }
            }}
          />

          <div className={styles.inlinePricingInputText}>
            <Content id={ContentId.PriceBy} />
          </div>

          <PosFormField
            errors={undercutRelativeAmountError}
            style={{ width: 'fit-content' }}
          >
            <PosTextField
              rootProps={{
                state: getTextFieldState(undercutRelativeAmountError),
                style: { maxWidth: '100px' },
                disabled: !undercutMode,
              }}
              disabled={!undercutMode}
              value={
                undercutMode == null
                  ? ''
                  : undercutRelativeAmount != null
                  ? roundToPrecision(Math.abs(undercutRelativeAmount) * 100, 8)
                  : ''
              }
              placeholder={
                undercutMode == null
                  ? eventPricingSettings?.undercutRelativeAmount?.toString() ??
                    accountPricingSettings?.undercutRelativeAmount?.toString() ??
                    undefined
                  : undefined
              }
              type="number"
              postfixDisplay="%"
              onChange={(e) => {
                const v = parseFloat(e.target.value);
                if (v >= 0 && v <= Number.MAX_VALUE) {
                  const relativeValue = Math.min(v, 100) / 100;
                  if (
                    undercutRelativeAmountMode ===
                    UndercutPriceSettingsMode.Premium
                  ) {
                    onUndercutChange(undefined, undefined, -relativeValue);
                  } else {
                    onUndercutChange(undefined, undefined, relativeValue);
                  }
                } else {
                  onUndercutChange(undefined, undefined, null);
                }
              }}
            />
          </PosFormField>
          <div className={styles.inlinePricingInputText}>
            <Content id={ContentId.Then} />
          </div>
          <ToggleGroup
            disabled={!undercutMode || !undercutAbsoluteAmount}
            options={UNDERCUT_PRICE_MODE_OPTIONS}
            value={undercutAbsoluteAmountMode}
            onValueChange={(value) => {
              if (!value) return;

              const mode = value as UndercutPriceSettingsMode;
              setUndercutAbsoluteAmountMode(mode);

              if (undercutAbsoluteAmount) {
                const curAmountAbs = Math.abs(undercutAbsoluteAmount);
                onUndercutChange(
                  undefined,
                  getUndercutPriceRepresentingMode(curAmountAbs, mode)
                );
              }
            }}
          />
          <div className={styles.inlinePricingInputText}>
            <Content id={ContentId.By} />
          </div>
          <PosFormField
            errors={undercutAbsoluteAmountError}
            style={{ width: 'fit-content' }}
          >
            <PosCurrencyField
              rootProps={{
                state: getTextFieldState(undercutAbsoluteAmountError),
                style: { maxWidth: '100px' },
                disabled: !undercutMode,
              }}
              uiCurrency={uiCurrency}
              disabled={!undercutMode}
              value={
                undercutMode == null
                  ? ''
                  : undercutAbsoluteAmount != null
                  ? Math.abs(undercutAbsoluteAmount)
                  : ''
              }
              placeholder={
                undercutMode == null
                  ? eventPricingSettings?.undercutAbsoluteAmount?.toString() ??
                    accountPricingSettings?.undercutAbsoluteAmount?.toString() ??
                    undefined
                  : undefined
              }
              onChange={(e) => {
                const v = parseFloat(e.target.value);
                if (v >= 0 && v <= Number.MAX_VALUE) {
                  if (
                    undercutAbsoluteAmountMode ===
                    UndercutPriceSettingsMode.Premium
                  ) {
                    onUndercutChange(undefined, -v);
                  } else {
                    onUndercutChange(undefined, v);
                  }
                } else {
                  onUndercutChange(undefined, null);
                }
              }}
            />
          </PosFormField>
        </Stack>
      </DetailGroup>
      <DetailGroup style={{ gridColumn: 1 }}>
        <ButtonWithIcon
          variant="text"
          icon={<ResetIcon withHoverEffect />}
          textContentId={ContentId.ResetSettings}
          onClick={onResetSettings}
        />
      </DetailGroup>

      <SectionSelectDialog
        {...sectionSelectDialog.dialogProps}
        initialSectionIds={
          compListingSelectedSectionSettings?.sectionIdFilter ?? []
        }
        onSave={(selectedSections) => {
          const compListingSelectedSectionSettingsNew = {
            ...compListingSelectedSectionSettings,
            sectionIdFilter: selectedSections.map((s) => s.id),
            rowIdFilter: compListingSelectedSectionSettings?.rowIdFilter ?? [],
            sectionRowIdFilter:
              compListingSelectedSectionSettings?.sectionRowIdFilter ?? {},
          };
          onCompListingChange({ compListingSelectedSectionSettingsNew });
          sectionSelectDialog.closeDialog();
        }}
        onClosed={sectionSelectDialog.closeDialog}
      />
    </>
  );
};
