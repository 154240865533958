import { closestCenter, DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Stack } from 'src/core/ui';
import { ZoneSectionAreaRankOverride } from 'src/WebApiController';

import { GroupListingAutoCompSettingsForm } from '../autoCompGroup.types';
import { ZoneAreaMetadata } from '../utils/colorUtils';
import { SortableZoneAreaItem } from './SortableZoneAreaItem';
import * as styles from './ZoneSectionGroupDraggableList.css';

export type SortableZoneSectionAreaRankOverride =
  ZoneSectionAreaRankOverride & {
    id: string;
  };

export type ZoneSectionGroupDraggableListProps = {
  zoneAreaMetadata: ZoneAreaMetadata;
};

export const ZoneSectionGroupDraggableList = ({
  zoneAreaMetadata,
}: ZoneSectionGroupDraggableListProps) => {
  const { setValue, watch } =
    useFormContext<GroupListingAutoCompSettingsForm>();

  const zoneSectionAreaGroupLookup = watch('zoneSectionAreaGroupLookup');

  const zoneSectionAreaRankOverrides: SortableZoneSectionAreaRankOverride[] =
    useMemo(() => {
      if (zoneSectionAreaGroupLookup.zoneSectionAreaRankOverrides.length == 0) {
        return Object.values(zoneAreaMetadata).map((md, index) => ({
          id: md.key,
          zoneAreaName: md.key,
          rank: index,
          color: md.color,
        }));
      }

      return zoneSectionAreaGroupLookup.zoneSectionAreaRankOverrides.map(
        (md) => ({
          id: md.zoneAreaName,
          zoneAreaName: md.zoneAreaName,
          rank: md.rank,
          color: md.color,
        })
      );
    }, [
      zoneAreaMetadata,
      zoneSectionAreaGroupLookup.zoneSectionAreaRankOverrides,
    ]);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (over != null && active.id != over.id) {
        const oldIndex = zoneSectionAreaRankOverrides.findIndex(
          (ro) => ro.zoneAreaName === active.id
        );

        const newIndex = zoneSectionAreaRankOverrides.findIndex(
          (ro) => ro.zoneAreaName === over.id
        );

        const shiftedRankOverrides = arrayMove(
          zoneSectionAreaRankOverrides,
          oldIndex,
          newIndex
        );

        // Update ranks based on new positions
        const updatedRankOverrides = shiftedRankOverrides.map(
          (item, index) => ({
            ...item,
            rank: index,
          })
        );

        setValue(
          'zoneSectionAreaGroupLookup.zoneSectionAreaRankOverrides',
          updatedRankOverrides
        );
      }
    },
    [zoneSectionAreaRankOverrides, setValue]
  );

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext
        items={zoneSectionAreaRankOverrides}
        strategy={verticalListSortingStrategy}
      >
        <Stack direction="column" className={styles.sortableZoneAreaContainer}>
          {zoneSectionAreaRankOverrides.map((ro) => (
            <SortableZoneAreaItem
              key={ro.zoneAreaName}
              id={ro.zoneAreaName}
              label={ro.zoneAreaName}
              color={ro.color}
            />
          ))}
        </Stack>
      </SortableContext>
    </DndContext>
  );
};
