import { useMemo } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import {
  PosMultiSelect,
  PosMultiSelectProps,
} from 'src/core/POS/PosMultiSelect';
import { EMPTY_SELLER_USER_ID } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';

export const SellerAccountEmployeeMultiSelector = ({
  placeholderText,
  ...props
}: Omit<PosMultiSelectProps, 'valueOptionsContent'> & {
  showEmail?: boolean;
  allowUnchanged?: boolean;
}) => {
  const { allActiveUserInfos } = useSellerAccountContext();

  const unchanged = useContent(ContentId.DontChange);

  const allActiveUserOptions = useMemo(() => {
    if (!allActiveUserInfos) return {};

    const results = {} as Record<string, string>;
    if (props.allowUnchanged) {
      results[EMPTY_SELLER_USER_ID] = unchanged;
    }

    Object.values(allActiveUserInfos).reduce((r, cur) => {
      r[cur.userId] = props.showEmail
        ? `${cur.displayName} (${cur.email})`
        : cur.displayName;
      return r;
    }, results);

    return results;
  }, [allActiveUserInfos, props.allowUnchanged, props.showEmail, unchanged]);

  return (
    <PosMultiSelect
      {...props}
      loading={allActiveUserInfos == null}
      placeholderText={placeholderText ?? ContentId.Everyone}
      valueOptionsContent={allActiveUserOptions}
    />
  );
};
