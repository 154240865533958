import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const ListingIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 24">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4 5.2V5.88H16V0H11L10.76 0.4C10.2 1.4 9.12 2 8 2C6.88 2 5.8 1.4 5.24 0.4L5 0H0V20H16V7.48L14.4 6.68V9.52V18.4H1.6V9.56V6.8V5.2V3.52V1.6H4.12C4.88 2.64 6.04 3.36 7.28 3.52H8.72C10 3.32 11.12 2.64 11.88 1.6H14.4V3.52V5.2ZM5.06667 14H4V17H5.06667V14ZM7.33333 14H6V17H7.33333V14ZM12 14H10.6667V17H12V14ZM8.66667 14H9.73333V17H8.66667V14ZM7.57353 7.57353V5H8.67647V7.57353H11.25V8.67647H8.67647V11.25H7.57353V8.67647H5V7.57353H7.57353Z"
        fill="inherit"
      />
    </SvgWrapper>
  );
};
