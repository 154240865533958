import React, { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { CurrencyFilterSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { Content } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { FieldWrapper } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { TicketGroupEditReason } from 'src/WebApiController';

interface PurchaseCurrencyProps {
  disabled?: boolean;
}

export const PurchaseCurrency: React.FC<PurchaseCurrencyProps> = ({
  disabled,
}) => {
  const currencyChangeWarningDialog = useBasicDialog();
  const [newCurrencyCode, setNewCurrencyCode] = useState<string>('');

  const { getValues, setValue, formState } =
    useFormContext<PurchaseOrderDetailsInput>();

  const { id, currencyCode, ticketGroups } = getValues();
  const isNewPurchase = id <= 0;

  // Change Currency Dialog
  const onCurrencyChangeCancel = useCallback(() => {
    setNewCurrencyCode('');
    currencyChangeWarningDialog.closeDialog();
  }, [currencyChangeWarningDialog]);

  const onCurrencyChangeOk = useCallback(() => {
    setValue('currencyCode', posChangedField(newCurrencyCode));

    if (id > 0) {
      // If this is an existing purchase
      // We need to ensure all ticket-groups have an intention of edit
      const ticketGroupIntentions = getValues('ticketGroupIntentions');
      ticketGroups.forEach(({ ticketGroupId }) => {
        if (!ticketGroupIntentions[ticketGroupId]?.reason) {
          ticketGroupIntentions[ticketGroupId] = {
            ticketGroupId: ticketGroupId,
            reason: TicketGroupEditReason.AmendTickets,
            additionalInfo: '',
          };
        }
      });
      setValue('ticketGroupIntentions', { ...ticketGroupIntentions });
    }

    setValue(
      'ticketGroups',
      ticketGroups?.map((tg) => ({
        ...tg,
        currencyCode: posChangedField(newCurrencyCode),
      }))
    );
    currencyChangeWarningDialog.closeDialog();
    setNewCurrencyCode('');
  }, [
    currencyChangeWarningDialog,
    getValues,
    newCurrencyCode,
    id,
    setValue,
    ticketGroups,
  ]);

  return (
    <>
      <FieldWrapper>
        <PosFormField label={<Content id={ContentId.Currency} />}>
          <CurrencyFilterSelector
            style={{ width: '100%' }}
            disabled={disabled}
            value={currencyCode?.value ?? undefined}
            defaultValue={
              formState.defaultValues?.currencyCode?.value ?? undefined
            }
            onChange={(newCurrencyCode) => {
              if (newCurrencyCode && newCurrencyCode !== currencyCode?.value) {
                if (isNewPurchase) {
                  setValue('currencyCode', posChangedField(newCurrencyCode), {
                    shouldValidate: true,
                  });
                } else {
                  setNewCurrencyCode(newCurrencyCode);
                  currencyChangeWarningDialog.launchDialog();
                }
              }
            }}
          />
        </PosFormField>
      </FieldWrapper>
      {currencyChangeWarningDialog.dialogProps.isOpen && (
        <ConfirmDialog
          {...currencyChangeWarningDialog.dialogProps}
          headerText={<Content id={ContentId.UpdateCurrency} />}
          bodyText={<Content id={ContentId.UpdatePurchaseCurrencyWarning} />}
          onOkay={onCurrencyChangeOk}
          onCancel={onCurrencyChangeCancel}
          okText={ContentId.Yes}
          cancelText={ContentId.Cancel}
        />
      )}
    </>
  );
};
