import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { Marketplace } from 'src/WebApiController';

export const MerchantDisplay = ({
  name,
}: {
  name?: Marketplace | string | null;
}) => {
  if (!name) {
    return <Content id={ContentId.DirectOffline} />;
  }

  return <>{name}</>;
};
