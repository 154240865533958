import { fromPairs } from 'lodash-es';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getFlattenedListingIds } from 'src/utils/inventoryUtils';
import {
  EventWithData,
  Listing,
  PurchaseOrderTicketGroup,
  Sale,
  TimePeriodContainingPurchaseOrderResult,
} from 'src/WebApiController';

import { useCatalogDataContext } from '../CatalogDataContext';
import { packNumericIds } from '../MultiSelectionContext/MultiSelectionContext.utils';
import { usePurchaseDataContext } from '../PurchaseDataContext';

export type MultiSelectionType =
  | 'listing'
  | 'sale'
  | 'purchase'
  | 'ticketGroup';

/** Metadata of the selectable group
 * @prop id - ID of the group (Event ID or month)
 * @prop comboId - Combo ID for related items (ViagogoVirtualId for combined events)
 * @prop itemCount - count of total items in the group
 */
export type SelectableGroupMetadata = {
  id: string;
  comboId?: string;
  itemCount: number;
};

export type ICatalogMultiSelectionContext = {
  flattenedIds: (number | string)[] | undefined;
  groupsArray: SelectableGroupMetadata[];
};

const emptyContext: ICatalogMultiSelectionContext = {
  flattenedIds: undefined,
  groupsArray: [],
};

export const CatalogMultiSelectionContext =
  createContext<ICatalogMultiSelectionContext>(emptyContext);

export const useCatalogMultiSelectionContext = () =>
  useContext(CatalogMultiSelectionContext);

function transformGroupsArray(
  data: EventWithData[] | TimePeriodContainingPurchaseOrderResult[] | undefined,
  type: MultiSelectionType,
  isPurchaseFlattenedView?: boolean
): SelectableGroupMetadata[] {
  if (type === 'purchase' && !isPurchaseFlattenedView) {
    return ((data ?? []) as TimePeriodContainingPurchaseOrderResult[]).map(
      (monthData) => ({
        id: monthData.firstOfTimePeriod,
        itemCount: monthData.purchaseCount,
      })
    );
  }
  return ((data ?? []) as EventWithData[]).map((event) => ({
    id: event.event.viagVirtualId,
    comboId: event.event.viagVirtualId,
    itemCount:
      type === 'listing'
        ? event.listCnt
        : type === 'ticketGroup'
        ? event.ticketGrpsCnt
        : type === 'purchase'
        ? event.purchaseOrdersCnt
        : event.salesCnt,
  }));
}

export function CatalogMultiSelectionContextProvider({
  children,
  type,
}: {
  children: ReactNode;
  type: MultiSelectionType;
}) {
  const { data, eventsTransformed } = useCatalogDataContext();
  const {
    timePeriodPurchasesQuery,
    allPurchasesFiltered,
    isFlattenedView: isPurchaseFlattenedView,
  } = usePurchaseDataContext();
  const inputArray = useMemo(
    () =>
      type === 'purchase' && !isPurchaseFlattenedView
        ? timePeriodPurchasesQuery?.data ?? []
        : eventsTransformed,
    [
      eventsTransformed,
      isPurchaseFlattenedView,
      timePeriodPurchasesQuery?.data,
      type,
    ]
  );
  const inputObject = useMemo(
    () =>
      type === 'purchase' && !isPurchaseFlattenedView
        ? fromPairs(
            (timePeriodPurchasesQuery?.data ?? []).map((monthData) => [
              monthData.firstOfTimePeriod,
              monthData,
            ])
          )
        : data?.events,
    [
      data?.events,
      isPurchaseFlattenedView,
      timePeriodPurchasesQuery?.data,
      type,
    ]
  );

  const [flattenedIds, setFlattenedIDs] = useState<
    (number | string)[] | undefined
  >();
  const [groupsArray, setGroupsArray] = useState<SelectableGroupMetadata[]>(
    transformGroupsArray(inputArray, type, isPurchaseFlattenedView)
  );

  useEffect(() => {
    const allItems =
      type === 'purchase' && allPurchasesFiltered
        ? allPurchasesFiltered
        : ((inputArray ?? []) as EventWithData[]).reduce(
            (all, current) => [
              ...all,
              ...((current as EventWithData)?.listings ??
                (current as EventWithData).sales ??
                (current as EventWithData).ticketGroups ??
                []),
            ],
            [] as (Listing | Sale | PurchaseOrderTicketGroup)[]
          );
    const flattened =
      allItems.length > 0 && (allItems[0] as Listing).entityType === 'Listing'
        ? getFlattenedListingIds(allItems as Listing[])
        : type === 'ticketGroup'
        ? allItems.map((item) =>
            packNumericIds([
              item.id,
              (item as PurchaseOrderTicketGroup).tgId ?? 0,
            ])
          )
        : allItems.map((item) => item.id);
    setFlattenedIDs(flattened.length > 0 ? flattened : undefined);
    setGroupsArray(
      transformGroupsArray(inputArray, type, isPurchaseFlattenedView)
    );
  }, [
    inputObject,
    inputArray,
    type,
    allPurchasesFiltered,
    isPurchaseFlattenedView,
  ]);

  return (
    <CatalogMultiSelectionContext.Provider
      value={{
        flattenedIds,
        groupsArray,
      }}
    >
      {children}
    </CatalogMultiSelectionContext.Provider>
  );
}
