import { CloseButton } from 'reactstrap';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { DnDFileUploader } from 'src/core/POS/DnDFileUploader';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import { ImageMagnifier } from '../ImageMagnifier';
import * as styles from './SingleFileUploadWithPreview.css';
import {
  PageContainer,
  PageImageContainer,
  SingleFileUploadWithPreviewContainer,
  UploadFailure,
} from './SingleFileUploadWithPreview.styled';

export const SingleFileUploadWithPreview = <
  TPage extends { pageNumber: number; thumbUrl: string },
>({
  fileName,
  filePages = [],
  previewWidth = 'auto',
  previewHeight = 'auto',
  onFileDeleted,
  isDeletable,
  onNewFileSelected,
  acceptedFileTypes,
  renderPreview,
  multiple = false,
  hasError,
  maxFileSize,
}: {
  fileName?: string;
  filePages: TPage[];
  previewWidth?: string | number;
  previewHeight?: string | number;
  onFileDeleted: () => void;
  isDeletable?: boolean;
  onNewFileSelected: (files: File[]) => void;
  acceptedFileTypes?: string[];
  renderPreview?: (page: TPage) => JSX.Element;
  multiple?: boolean;
  hasError?: boolean;
  maxFileSize?: number;
}) => {
  return (
    <SingleFileUploadWithPreviewContainer>
      {fileName && (
        <div className={styles.nameContainer}>
          <div>{fileName}</div>
          {((filePages?.length > 0 && isDeletable) || hasError) && (
            <CloseButton onClick={() => onFileDeleted()} />
          )}
        </div>
      )}
      {hasError ? (
        <UploadFailure>
          <Content id={ContentId.UploadFailureMessage} />
        </UploadFailure>
      ) : filePages.length > 0 ? (
        filePages
          .sort((a, b) => a.pageNumber - b.pageNumber)
          .map((fp) =>
            renderPreview ? (
              <PageContainer key={fp.pageNumber}>
                {renderPreview(fp)}
              </PageContainer>
            ) : (
              <PageImageContainer key={fp.pageNumber}>
                {filePages.length > 1 && (
                  <div>
                    <FormatContent
                      id={FormatContentId.PageNum}
                      params={`${fp.pageNumber + 1}`}
                    />
                  </div>
                )}
                <ImageMagnifier imageSrc={fp.thumbUrl} magnifierSize={100} />
              </PageImageContainer>
            )
          )
      ) : (
        <div style={{ width: previewWidth, height: previewHeight }}>
          <DnDFileUploader
            onChange={(files) =>
              files[0] && onNewFileSelected(multiple ? files : [files[0]])
            }
            multiple={multiple}
            acceptedFileTypes={acceptedFileTypes}
            maxFileSize={maxFileSize}
          />
        </div>
      )}
    </SingleFileUploadWithPreviewContainer>
  );
};
