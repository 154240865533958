import {
  getExpandedRowModel,
  InitialTableState,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { GroupedActivityLog } from 'src/components/ActivityLog';
import { Content } from 'src/contexts/ContentContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { NoData, Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { generateShimmeringRows } from 'src/utils/dataTableUtils';
import { SomethingWentWrong } from 'src/views';
import { ActivityLog, Feature } from 'src/WebApiController';

import { ACTIVITY_LOG_GROUPED_TABLE_COLUMNS_CONFIG } from './configs/ActivityLogGroupedTableColumnsConfig';
import { ACTIVITY_LOG_TABLE_COLUMNS_CONFIG } from './configs/ActivityLogTableColumnsConfig';

export type ActivityLogTableDataProps = {
  activityLogs?: ActivityLog[];
  groupedActivityLogs?: GroupedActivityLog[];
  count: number;
  failedToRetrieveData: boolean;
};

type ActivityLogTableProps = ActivityLogTableDataProps & {
  failedToRetrieveData: boolean;
  onMount?: () => void;
  onUnmount?: (state: object) => void;
  initState?: InitialTableState;
};

const PAGE_SIZE = 20;

export function ActivityLogTable({
  activityLogs,
  groupedActivityLogs,
  count,
  failedToRetrieveData,
  onMount,
  onUnmount,
  initState,
}: ActivityLogTableProps) {
  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || [{ id: 'actionDate', desc: true }]
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: PAGE_SIZE,
  });

  const hasGroupedActivityLog = useUserHasFeature(Feature.GroupedActivityLog);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const flatActivityLogData = useMemo(
    () => activityLogs || (generateShimmeringRows(count) as null[]),
    [count, activityLogs]
  );

  const data = useMemo(
    () => groupedActivityLogs || (generateShimmeringRows(count) as null[]),
    [count, groupedActivityLogs]
  );

  const flatActivityLogOptions: Partial<TableOptions<ActivityLog | null>> =
    useMemo(
      () => ({
        data: flatActivityLogData,
        columns: ACTIVITY_LOG_TABLE_COLUMNS_CONFIG,
        state: {
          pagination,
          sorting,
        },
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
      }),
      [flatActivityLogData, pagination, sorting]
    );

  const options: Partial<TableOptions<GroupedActivityLog | null>> = useMemo(
    () => ({
      data,
      columns: ACTIVITY_LOG_GROUPED_TABLE_COLUMNS_CONFIG,
      state: {
        pagination,
        sorting,
      },
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      getRowCanExpand: (row) =>
        (row.original?.activityLogs && row.original?.activityLogs.length > 2) ||
        false,
      getExpandedRowModel: (table) => getExpandedRowModel()(table),
      getSubRows: (originalRow) => {
        if (originalRow?.activityLogs && originalRow.activityLogs.length < 2) {
          return [];
        }
        const activityLogs = originalRow?.activityLogs.map((activityLog) => ({
          ...activityLog,
          activityLogs: [activityLog], // Make each ActivityLog into a GroupedActivityLog
        }));
        return activityLogs ?? [];
      },
    }),
    [data, pagination, sorting]
  );

  return failedToRetrieveData ? (
    <SomethingWentWrong
      message={<Content id={ContentId.FailToLoadListContent} />}
    />
  ) : data?.length > 0 ? (
    hasGroupedActivityLog ? (
      <Table tableLayout="fixed" options={options} />
    ) : (
      <Table tableLayout="fixed" options={flatActivityLogOptions} />
    )
  ) : (
    <NoData>
      <Content id={ContentId.NoActivityLog} />
    </NoData>
  );
}
