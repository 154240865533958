import {
  Modal,
  ModalBody as RSModalBody,
  ModalFooter as RSModalFooter,
  ModalHeader as RSModalHeader,
} from 'reactstrap';
import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

// See this PR for information about "-fake" classes https://github.com/stubhub/StubHub.PointOfSale/pull/3041
// .modal-dialog
export const StyledModal = styled(Modal)<{
  size?:
    | 'sm'
    | 'm'
    | 'lg'
    | 'xl'
    | 'fullscreen'
    | 'slide-in'
    | 'slide-in-lg'
    | 'slide-left'
    | 'slide-left-sm'
    | 'slide-in-full';
}>`
  .modal-content {
    border: none;
  }

  ${({ size, theme: { media } }) =>
    media.makeMediaQuery(
      media.breakpoints.md,
      `max-width: ${size === 'sm' ? '500px' : '650px'};`
    )}

  ${({ size, theme: { media } }) =>
    size !== 'sm' &&
    size !== 'm' &&
    media.makeMediaQuery(media.breakpoints.md, `max-width: 750px;`)}

  ${({ size, theme: { media } }) =>
    size !== 'sm' &&
    size !== 'm' &&
    media.makeMediaQuery(media.breakpoints.lg, `max-width: 850px;`)}

  ${({ size, theme: { media } }) =>
    size === 'fullscreen' &&
    media.makeMediaQuery(
      media.breakpoints.sm,
      `
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      `
    )}
      
  // See this PR for information about "-fake" classes https://github.com/stubhub/StubHub.PointOfSale/pull/3041
  ${({ size }) =>
    (size === 'slide-in' ||
      size === 'slide-in-lg' ||
      size === 'slide-left' ||
      size === 'slide-left-sm' ||
      size === 'slide-in-full') &&
    `
    .modal.fade > &.modal-slide-fake,
    .modal.fade > &.modal-slide-in,
    .modal.fade > &.modal-slide-in-lg,
    .modal.fade > &.modal-slide-in-full,
    .modal.fade > &.modal-slide-left,
    .modal.fade > &.modal-slide-left-sm {
      transition: transform 0.3s ease-out !important;
      min-width: calc(100vw - 16px);
      max-width: calc(100vw - 16px);
      height: calc(100vh - 32px);
      max-height: calc(100vh - 32px);
      margin: 16px auto;
    }

    @supports (-webkit-touch-callout: none) {
      .modal.fade > &.modal-slide-fake,
      .modal.fade > &.modal-slide-in,
      .modal.fade > &.modal-slide-in-lg,
      .modal.fade > &.modal-slide-in-full,
      .modal.fade > &.modal-slide-left,
      .modal.fade > &.modal-slide-left-sm {
        max-height: -webkit-fill-available;
        padding-bottom: 16px;
      }
    }
  `}

  ${({ size }) =>
    (size === 'slide-in' ||
      size === 'slide-left' ||
      size === 'slide-left-sm' ||
      size === 'slide-in-full') &&
    `
    .modal-body {
      width: 1000px;
      max-width: 100%;
    }
  `}

  ${({ size }) =>
    size === 'slide-in-lg' &&
    `
    .modal-body {
      width: 1500px;
      max-width: 100%;
    }
  `}

  // Modal type: slide-in, size: sm and above
  ${({ size, theme }) =>
    (size === 'slide-in' ||
      size === 'slide-in-lg' ||
      size === 'slide-in-full' ||
      size === 'slide-left' ||
      size === 'slide-left-sm') &&
    `
    .modal.fade > &.modal-slide-fake > .modal-content,
    .modal.fade > &.modal-slide-in > .modal-content,
    .modal.fade > &.modal-slide-in-lg > .modal-content,
    .modal.fade > &.modal-slide-in-full > .modal-content,
    .modal.fade > &.modal-slide-left > .modal-content,
    .modal.fade > &.modal-slide-left-sm > .modal-content {
      border-radius: 16px;
      height: 100%;
    }

    ${ModalHeader} {
      padding: 0 ${vars.spacing.xl};
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    ${ModalBody} {
      border-bottom: none;
    }

    ${CloseButton} {
      position: relative;
      top: 0;
      right: 0;
    }
  `}

  // Modal type: slide-in, size: md and above
  ${({ size, theme: { media } }) =>
    (size === 'slide-in' ||
      size === 'slide-left' ||
      size === 'slide-left-sm') &&
    media.makeMediaQuery(
      media.breakpoints.md,
      `
      .modal.fade > &.modal-slide-fake > .modal-content,
      .modal.fade > &.modal-slide-in > .modal-content,
      .modal.fade > &.modal-slide-left > .modal-content,
      .modal.fade > &.modal-slide-left-sm > .modal-content {
        --bs-modal-inner-border-radius: 0;
        border-radius: 0;
      }

      .modal.fade > &.modal-slide-fake,
      .modal.fade > &.modal-slide-in,
      .modal.fade > &.modal-slide-left,
      .modal.fade > &.modal-slide-left-sm {
        margin: 0;
        min-width: 600px; // This is the smallest screen-width supported
        max-width: 95%; // Make the slide-in occupies at most 95% of the screen unless it's smaller than min-width which then becomes full-screen
        min-height: 100%;
      }

      .modal.fade.show > &.modal-slide-fake,
      .modal.fade.show > &.modal-slide-in,
      .modal.fade.show > &.modal-slide-left,
      .modal.fade.show > &.modal-slide-left-sm {
        transform: translate(0, 0);
        transition: transform 0.3s ease-in-out;
        display: flex;
        align-items: stretch;
        height: 100%;
      }
      `
    )}

${({ size, theme: { media } }) =>
    size === 'slide-in-full' &&
    media.makeMediaQuery(
      media.breakpoints.md,
      `
      .modal.fade > &.modal-slide-fake > .modal-content,
      .modal.fade > &.modal-slide-in-full > .modal-content {
        --bs-modal-inner-border-radius: 0;
        border-radius: 0;
      }

      .modal.fade > &.modal-slide-fake,
      .modal.fade > &.modal-slide-in-full {
        margin: 0;
        min-width: 600px; // This is the smallest screen-width supported
        max-width: 100%; // Make the slide-in occupies at most 100% of the screen unless it's smaller than min-width which then becomes full-screen
        min-height: 100%;
      }

      .modal.fade.show > &.modal-slide-fake,
      .modal.fade.show > &.modal-slide-in-full {
        transform: translate(0, 0);
        transition: transform 0.3s ease-in-out;
        display: flex;
        align-items: stretch;
        height: 100%;
      }
      `
    )}

  // Modal type: slide-in-lg, size: md and above
  ${({ size, theme: { media } }) =>
    size === 'slide-in-lg' &&
    media.makeMediaQuery(
      media.breakpoints.md,
      `
      .modal.fade > &.modal-slide-fake > .modal-content,
      .modal.fade > &.modal-slide-in-lg > .modal-content {
        --bs-modal-inner-border-radius: 0;
        border-radius: 0;
      }

      .modal.fade > &.modal-slide-fake,
      .modal.fade > &.modal-slide-in-lg {
        margin: 0;
        min-width: 1500px; // This is the smallest screen-width supported
        max-width: 95%; // Make the slide-in occupies at most 95% of the screen unless it's smaller than min-width which then becomes full-screen
        min-height: 100%;
      }

      .modal.fade.show > &.modal-slide-fake,
      .modal.fade.show > &.modal-slide-in-lg {
        transform: translate(0, 0);
        transition: transform 0.3s ease-in-out;
        display: flex;
        align-items: stretch;
        height: 100%;
      }
      `
    )}

  ${({ size, theme: { media } }) =>
    (size === 'slide-in' ||
      size === 'slide-in-lg' ||
      size === 'slide-in-full') &&
    media.makeMediaQuery(
      media.breakpoints.md,
      `
      .modal.fade > &.modal-slide-fake,
      .modal.fade > &.modal-slide-in,
      .modal.fade > &.modal-slide-in-full,
      .modal.fade > &.modal-slide-in-lg {
        position: absolute;
        top: 0;
        right: 0;

        transform: translate(50%, 0) scale(1);  // Note: translate(100%, 0) is not used because it will cause the modal to be pushed out of the screen and cause jittering when sliding back in
        transition: transform 0.3s ease-in-out;
      }
      `
    )}

  ${({ size, theme: { media } }) =>
    (size === 'slide-left' || size === 'slide-left-sm') &&
    media.makeMediaQuery(
      media.breakpoints.md,
      `
      .modal.fade > &.modal-slide-fake,
      .modal.fade > &.modal-slide-left,
      .modal.fade > &.modal-slide-left-sm {
        position: absolute;
        top: 0;
        left: 0;

        transform: translate(-50%, 0) scale(1);  // Note: translate(-100%, 0) is not used because it will cause the modal to be pushed out of the screen and cause jittering when sliding back in
        transition: transform 0.3s ease-in-out;
      }
      `
    )}

  ${({ size, theme: { media } }) =>
    size === 'slide-in' &&
    media.makeMediaQuery(
      media.breakpoints.lg,
      `
      .modal.fade > &.modal-slide-fake,
      .modal.fade > &.modal-slide-in {
        min-width: 1000px;
        max-width: 95%; // Make the slide-in occupies at most 95% of the screen unless it's smaller than min-width which then becomes full-screen
      }
      `
    )}

${({ size, theme: { media } }) =>
    size === 'slide-in-full' &&
    media.makeMediaQuery(
      media.breakpoints.lg,
      `
      .modal.fade > &.modal-slide-in-fake,
      .modal.fade > &.modal-slide-in-full {
        min-width: 1000px;
        max-width: 100%; // Make the slide-in occupies at most 100% of the screen unless it's smaller than min-width which then becomes full-screen
      }
      `
    )}

  ${({ size, theme: { media } }) =>
    size === 'slide-in-lg' &&
    media.makeMediaQuery(
      media.breakpoints.lg,
      `
      .modal.fade > &.modal-slide-fake,
      .modal.fade > &.modal-slide-in-lg {
        min-width: 1500px;
        max-width: 95%; // Make the slide-in occupies at most 95% of the screen unless it's smaller than min-width which then becomes full-screen
      }
      `
    )}

  ${({ size, theme: { media } }) =>
    size === 'slide-left' &&
    media.makeMediaQuery(
      media.breakpoints.lg,
      `
      .modal.fade > &.modal-slide-fake,
      .modal.fade > &.modal-slide-left {
        min-width: 1000px;
        max-width: 95%; // Make the slide-left occupies at most 95% of the screen unless it's smaller than min-width which then becomes full-screen
      }
      `
    )}

  ${({ size, theme: { media } }) =>
    size === 'slide-left-sm' &&
    media.makeMediaQuery(
      media.breakpoints.lg,
      `
      .modal.fade > &.modal-slide-fake,
      .modal.fade > &.modal-slide-left-sm {
        min-width: 200px;
        max-width: 95%; // Make the slide-left-sm occupies at most 95% of the screen unless it's smaller than min-width which then becomes full-screen
      }
      `
    )}

  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;
`;

export const ModalHeader = styled(RSModalHeader)`
  border-color: ${(props) => props.theme.colors.borderPrimary};
  padding: 0 ${() => vars.spacing.lg};

  .modal-title {
    width: 100%;
  }
`;

export const CloseButton = styled.div`
  z-index: 1;
  line-height: 1;

  @media ${({ theme: { media } }) => `${media.tablet}`} {
    top: ${() => `${vars.spacing.sm}`};
    right: ${() => `${vars.spacing.sm}`};
  }

  @media ${({ theme: { media } }) => `${media.desktop}`} {
    top: ${() => `${vars.spacing.m}`};
    right: ${() => `${vars.spacing.m}`};
  }
`;

export const ModalBody = styled(RSModalBody)`
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ModalBodyHeaderContainer = styled.div`
  padding: ${() => vars.spacing.lg};
  padding-bottom: 0;
  width: 100%;

  ${({ theme: { media } }) =>
    media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding: ${vars.spacing.xl};
      padding-bottom: 0;
      `
    )}
`;

export const ModalBodyDataContainer = styled.div`
  padding: ${() => vars.spacing.lg};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: auto;

  ${({ theme: { media } }) =>
    media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding: ${vars.spacing.xl};
      `
    )}
`;

export const ModalFooter = styled(RSModalFooter)`
  padding: ${() => `${vars.spacing.lg} ${vars.spacing.xl}`};
  border: none;
`;

export const Spacer = styled.div`
  width: 10px;
  height: 100%;
  margin: auto;
  background: ${({ theme }) => theme.colors.backgroundPrimary};
`;
