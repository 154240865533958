import styled from 'styled-components/macro';

export const ItemsCountDiv = styled.div`
  text-align: right;
  line-height: 18px;
  white-space: nowrap;
  color: ${(props) => props.theme.colors.textPrimary};
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('caption1')};
`;
