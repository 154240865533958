import { HubConnectionState } from '@microsoft/signalr';
import {
  ComponentProps,
  CSSProperties,
  useCallback,
  useRef,
  useState,
} from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { ListingNoteSelectionInput } from 'src/components/Input/ListingNoteSelectionInput';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { InfoMessage } from 'src/core/POS/MessageWithIcon/InfoMessage';
import { vars } from 'src/core/themes';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  ListingNote,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

const radioInfoMessageStyle: CSSProperties = {
  marginLeft: vars.spacing.xxs,
};

export type BulkEditSeatTraitsDialogProps = ComponentProps<typeof RSModal> & {
  updateKey: string;
  isLoading?: boolean;
  onOkay: (
    seatTraits: ListingNote[] | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails,
    appendSeatTraits?: boolean
  ) => void;
  onCancel: () => void;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Purchase
    | ActionOutboxEntityType.TicketGroup;
  infoMessage?: React.ReactNode;
};

export function BulkEditSeatTraitsDialog({
  updateKey,
  onOkay,
  onCancel,
  entityType,
  infoMessage,
  ...rest
}: BulkEditSeatTraitsDialogProps) {
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshData } = usePurchaseDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);
  const [appendSeatTraits, setAppendSeatTraits] = useState<boolean>(false);

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        if (refreshData) {
          await refreshData(true);
        }
        if (refreshExpandedListItems) {
          await refreshExpandedListItems();
        }
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshData,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    entityType,
    BulkActionType.UpdateSeatTraits,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const [seatTraits, setSeatTraits] = useState<ListingNote[]>([]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      if (onPreviewReceived) {
        setIsLoading(true);

        onOkay(null, false, onPreviewReceived);
        setIsLoading(false);
      } else {
        onOkay(
          seatTraits,
          hasBackgroundBulkEditFeature,
          undefined,
          preview,
          appendSeatTraits
        );
      }
    },
    [
      onOkay,
      appendSeatTraits,
      seatTraits,
      hasBackgroundBulkEditFeature,
      preview,
    ]
  );
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      size={stage === BulkEditStage.Preview ? 'xl' : 'lg'}
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.UpdateSeatTraits} />}
        />
      }
      {...rest}
      onKeyUp={(e) => {
        if (submitButtonRef.current && e.key === 'Enter') {
          submitButtonRef.current.click();
        }
      }}
      onOpened={() => {
        setSeatTraits([]);
        initJob();
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={entityType}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          submittButtonRef={submitButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={entityType}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack direction="column" gap="xxl" width="full">
          {infoMessage}
          <RadioGroup
            onValueChange={(value: string) => {
              setAppendSeatTraits(value === 'true');
            }}
            value={appendSeatTraits.toString()}
          >
            <Stack direction="column" gap="l">
              <Stack direction="column" gap="m">
                <Radio
                  value="true"
                  label={<Content id={ContentId.AppendSeatTraits} />}
                />
                <InfoMessage
                  style={radioInfoMessageStyle}
                  message={
                    <Content id={ContentId.BulkAppendSeatTraitInfoMessage} />
                  }
                  iconSize={vars.iconSize.s}
                />
              </Stack>
              <Stack direction="column" gap="m">
                <Radio
                  value="false"
                  label={
                    <Content id={ContentId.ReplaceAndOverrideSeatTraits} />
                  }
                />
                <InfoMessage
                  style={radioInfoMessageStyle}
                  message={
                    <Content
                      id={ContentId.BulkReplaceAndOverrideSeatTraitInfoMessage}
                    />
                  }
                  iconSize={vars.iconSize.s}
                />
              </Stack>
            </Stack>
          </RadioGroup>

          <ListingNoteSelectionInput
            triggerProps={{ style: { width: '100%' } }}
            avoidCollisions={false}
            side="bottom"
            disabled={isLoading}
            listingNotes={seatTraits}
            validListingNoteIds={undefined}
            onChange={setSeatTraits}
          />
          {seatTraits && seatTraits.length === 0 && (
            <WarningMessage
              message={
                <Content id={ContentId.BulkEditNoSeatTraitsProvidedWarning} />
              }
            />
          )}
        </Stack>
      </BulkEditStatus>
    </GenericDialog>
  );
}
