/* eslint-disable react-hooks/exhaustive-deps */
import { isEqual, pickBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useCompListingDisabledSections } from 'src/hooks/useCompListingDisabledSections';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getMatchingSectionRow,
  getSectionsSameZone,
} from 'src/utils/seatScoreUtils';
import { Listing, ListingDetailsUpdateInput } from 'src/WebApiController';

import { useListingAutoPricingSettings } from '../useListingAutoPricingSettings';
import { RestrictSelectionType } from './AutoPricingSection.types';

export const useRestrictSelection = (
  listing: Listing | undefined,
  defaultRestrictSelectionType: RestrictSelectionType,
  restrictSelectionTypes: Record<string, ContentId>,
  isBulkEdit?: boolean
) => {
  const {
    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingSelectedSectionSettings,
    onCompListingChange,
  } = useListingAutoPricingSettings(listing, isBulkEdit);

  const { clearErrors } = useFormContext<ListingDetailsUpdateInput>();
  const { venueMapInfo } = useEventMapContext();

  const matchingSectionRow = useMemo(() => {
    if (listing && venueMapInfo) {
      return getMatchingSectionRow(listing.seating, venueMapInfo.sections);
    }
    return null;
  }, [listing, venueMapInfo]);

  const { disabledSectionIds } = useCompListingDisabledSections(
    listing,
    isBulkEdit,
    compListingMode,
    compListingFloor,
    compListingCeiling
  );

  useEffect(() => {
    if (
      !disabledSectionIds.length ||
      !compListingSelectedSectionSettings?.sectionIdFilter
    ) {
      return;
    }

    const sectionIdFilterNew =
      compListingSelectedSectionSettings.sectionIdFilter.filter(
        (sid) => !disabledSectionIds.includes(sid)
      );

    if (
      !isEqual(
        sectionIdFilterNew,
        compListingSelectedSectionSettings.sectionIdFilter
      )
    ) {
      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings,
        sectionIdFilter: sectionIdFilterNew,
      };
      onCompListingChange({ compListingSelectedSectionSettingsNew });
    }
  }, [disabledSectionIds]);

  const [restrictSelectionType, setRestrictSelectionType] =
    useState<RestrictSelectionType>(defaultRestrictSelectionType);

  const updateToSameZone = useCallback((): void => {
    if (
      listing &&
      !isBulkEdit &&
      venueMapInfo &&
      Object.keys(restrictSelectionTypes).includes(
        RestrictSelectionType.SpecificSections
      )
    ) {
      // Display all sections in the same zone
      const sectionsSameZone = getSectionsSameZone(
        listing.seating,
        venueMapInfo.sections
      )?.filter((s) => !disabledSectionIds.includes(s.id));
      if (sectionsSameZone) {
        const sectionIdFilter = sectionsSameZone
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((s) => s.id);
        const rowIdFilter = (sectionsSameZone ?? [])
          .flatMap(({ rows }) => rows)
          .map(({ id }) => id);
        const sectionRowIdFilter = pickBy(
          Object.assign(
            {},
            compListingSelectedSectionSettings?.sectionRowIdFilter ?? {}
          ),
          (_, key) => sectionIdFilter.includes(parseInt(key))
        );
        const compListingSelectedSectionSettingsNew = {
          ...compListingSelectedSectionSettings,
          sectionIdFilter,
          rowIdFilter,
          sectionRowIdFilter,
        };
        onCompListingChange({
          compListingSelectedSectionSettingsNew,
          compListingOnlyForSameZoneEnabledNew: true,
          compListingOnlyForSelectedSectionsEnabledNew: false,
        });
      }
    }
  }, [
    compListingSelectedSectionSettings,
    disabledSectionIds,
    isBulkEdit,
    listing,
    onCompListingChange,
    restrictSelectionTypes,
    venueMapInfo,
  ]);

  const updateToSameSection = useCallback((): void => {
    const sectionIdFilter = matchingSectionRow?.section?.id
      ? [matchingSectionRow.section.id]
      : [];
    const rowIdFilter = compListingSelectedSectionSettings?.rowIdFilter ?? [];
    const sectionRowIdFilter = pickBy(
      Object.assign(
        {},
        compListingSelectedSectionSettings?.sectionRowIdFilter ?? {}
      ),
      (_, key) => sectionIdFilter.includes(parseInt(key))
    );
    const compListingSelectedSectionSettingsNew = {
      ...compListingSelectedSectionSettings,
      sectionIdFilter,
      rowIdFilter,
      sectionRowIdFilter,
    };
    onCompListingChange({
      compListingOnlyForSameZoneEnabledNew: false,
      compListingOnlyForSelectedSectionsEnabledNew: true,
      compListingSelectedSectionSettingsNew,
    });
  }, []);

  useEffect(() => {
    if (
      compListingOnlyForSelectedSectionsEnabled &&
      restrictSelectionType !== RestrictSelectionType.SameSection &&
      RestrictSelectionType.SpecificSections in restrictSelectionTypes
    ) {
      setRestrictSelectionType(RestrictSelectionType.SpecificSections);
    } else if (
      compListingOnlyForSameZoneEnabled &&
      restrictSelectionType !== RestrictSelectionType.SameZone &&
      RestrictSelectionType.SameZone in restrictSelectionTypes
    ) {
      setRestrictSelectionType(RestrictSelectionType.SameZone);
    }
  }, [
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    restrictSelectionTypes,
  ]);

  const onRestrictSelectionTypeChange = useCallback(
    (type: RestrictSelectionType | null) => {
      clearErrors('compListingFloor');
      setRestrictSelectionType(type ?? defaultRestrictSelectionType);
      if (type === RestrictSelectionType.SameZone) {
        return updateToSameZone();
      } else if (type === RestrictSelectionType.SpecificSections) {
        onCompListingChange({
          compListingOnlyForSameZoneEnabledNew: false,
          compListingOnlyForSelectedSectionsEnabledNew: true,
        });
        return;
      } else {
        // Same section
        updateToSameSection();
      }
    },
    [
      clearErrors,
      setRestrictSelectionType,
      updateToSameZone,
      onCompListingChange,
      updateToSameSection,
    ]
  );

  return {
    restrictSelectionType,
    disabledSectionIds,
    onRestrictSelectionTypeChange,
  };
};
