import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { DetailSection, SectionContent } from 'src/modals/common';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { downloadFile } from 'src/utils/fileUtils';
import { getFormattedBytesSize } from 'src/utils/format';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  SellerSupportCaseAttachmentRequest,
  SellerSupportCaseClient,
} from 'src/WebApiController';

import * as styles from './AttachmentsPanel.css';

export type AttachmentInfo = {
  attachmentId: string;
  name: string;
  size?: number;
  content?: string;
  contentType?: string;
};

export type AttachmentsPanelProps = {
  attachments: AttachmentInfo[];
  showDelete?: boolean;
  onDeleteClicked?: (attachment: AttachmentInfo) => void;
  isDownloadable?: boolean;
};

export const AttachmentDisplay = ({
  attachmentInfo,
  showDelete,
  onDeleteClicked,
  isDownloadable,
}: {
  attachmentInfo: AttachmentInfo;
  showDelete: boolean;
  onDeleteClicked?: (attachment: AttachmentInfo) => void;
  isDownloadable: boolean | undefined;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { showErrorDialog } = useErrorBoundaryContext();

  const handleDownloadFile = useCallback(async () => {
    setIsLoading(true);
    const downloadedFiles = await tryInvokeApi(
      async () => {
        const requests: SellerSupportCaseAttachmentRequest[] = [
          {
            id: attachmentInfo.attachmentId,
            contentType: attachmentInfo.contentType ?? '',
            name: attachmentInfo.name,
          },
        ];
        return await new SellerSupportCaseClient(
          activeAccountWebClientConfig
        ).retrieveSellerSupportCaseNoteAttachments(requests, true);
      },
      (error) => {
        showErrorDialog('retrieveSellerSupportCaseNoteAttachments', error);
      }
    );

    if (downloadedFiles) {
      if (downloadedFiles.length > 0 && downloadedFiles[0].content != null) {
        const fileToDownload = downloadedFiles[0];
        downloadFile(
          fileToDownload.name,
          fileToDownload.content ?? '',
          fileToDownload.contentType,
          true
        );
      }
    }

    setIsLoading(false);
  }, [
    activeAccountWebClientConfig,
    attachmentInfo.attachmentId,
    attachmentInfo.contentType,
    attachmentInfo.name,
    showErrorDialog,
  ]);

  return (
    <div
      className={clsx(styles.attachmentDisplay, {
        [styles.attachmentDisplayDownloadable]: isDownloadable,
      })}
      onClick={() => {
        if (!isLoading && isDownloadable && attachmentInfo.size) {
          handleDownloadFile();
        }
      }}
    >
      {isLoading ? (
        <PosSpinner size={vars.iconSize.xxs} />
      ) : (
        <>
          <div className={styles.attachmentInfoDisplay}>
            <div className={styles.fileName}>{attachmentInfo.name}</div>
            {attachmentInfo.size != null && (
              <div className={styles.fileSize}>
                {getFormattedBytesSize(attachmentInfo.size, 2, false)}
              </div>
            )}
          </div>
          <>
            {showDelete && (
              <div>
                <CrossIcon
                  size={vars.iconSize.s}
                  onClick={() => onDeleteClicked?.(attachmentInfo)}
                />
              </div>
            )}
          </>
        </>
      )}
    </div>
  );
};

export const AttachmentsPanel = ({
  attachments,
  showDelete,
  onDeleteClicked,
  isDownloadable,
}: AttachmentsPanelProps) => {
  const isMobile = useMatchMedia('mobile');

  return (
    <DetailSection name={<Content id={ContentId.Attachment} />}>
      <SectionContent numOfColumns={isMobile ? 3 : 4}>
        {attachments.map((a: AttachmentInfo) => {
          return (
            <div key={a.attachmentId}>
              <AttachmentDisplay
                attachmentInfo={a}
                showDelete={showDelete ?? false}
                onDeleteClicked={onDeleteClicked}
                isDownloadable={isDownloadable && a.size != null && a.size > 0}
              />
            </div>
          );
        })}
      </SectionContent>
    </DetailSection>
  );
};
