import { EventWithData, ListingMetrics } from 'src/WebApiController';

export const getEventIdsForPerformersOrVenues = (
  events?: Record<string, EventWithData>,
  performerIds?: number[] | null,
  venueEventIds?: number[] | null
) => {
  if (!events || !performerIds) {
    return [];
  }

  const results = Object.values(events)
    .filter(
      (ev) =>
        (ev.event.perfId && performerIds?.includes(ev.event.perfId)) ||
        (ev.event.venueId && venueEventIds?.includes(ev.event.venueId))
    )
    .map((ev) => ev.event.viagVirtualId);

  return results;
};

/* The goal of this method is to aggregate all the listing metrics with similar currency so we can reduce significantly the number of listing metrics
 * being sent to the server for aggregation just because of currency conversions
 * if the aggregation result in just 1 ListingMetrics - we don't need to send anything
 */
export const groupAndAggregateListingMetrics = (
  listingMetrics: ListingMetrics[],
  addMetrics: (
    metricA: ListingMetrics,
    metricB: ListingMetrics
  ) => ListingMetrics
) => {
  const metricsByCurrency = {} as Record<string, ListingMetrics>;

  listingMetrics.forEach((lm) => {
    const existing = metricsByCurrency[lm.currency];
    if (!existing) {
      metricsByCurrency[lm.currency] = lm;
    } else {
      metricsByCurrency[lm.currency] = addMetrics(existing, lm);
    }
  });

  return Object.values(metricsByCurrency);
};
