import { isEqual } from 'lodash-es';

export type OptionalPosField<T> = { value?: T | null; hasChanged?: boolean };

export const posField = <T>(value: T | null, hasChanged = false) => {
  return { value, hasChanged };
};

export const posChangedField = <T>(value: T | null) => {
  return posField(value, true);
};

export const hasChanged = <T>(
  newP: OptionalPosField<T> | null | undefined,
  oldP: OptionalPosField<T> | null | undefined
) => {
  if (oldP == null) {
    return newP?.hasChanged;
  }

  return newP?.hasChanged && !isEqual(newP.value, oldP.value);
};
