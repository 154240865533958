import { ColumnDef, Row } from '@tanstack/react-table';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { uniq } from 'lodash-es';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { getUserDisplayName, UseGetUserInfo } from 'src/hooks/userGetUserInfo';
import { formatPercent } from 'src/utils/numberFormatter';
import {
  Money,
  PurchaseBuyerInfo,
  ReportFileType,
  UiMoney,
} from 'src/WebApiController';
import * as XLSX from 'xlsx';

import { AccessorReturn, AccessorReturnType } from './ReportsExport.types';

export function sortReportMetrics<T>(
  a: T,
  b: T,
  sortByColumnConfig: ColumnDef<T | null> | undefined,
  isSortDescending: boolean
) {
  if (sortByColumnConfig) {
    let result = 0;
    // @ts-expect-error accessorFn is available
    const aVal = sortByColumnConfig.accessorFn(a);
    // @ts-expect-error accessorFn is available
    const bVal = sortByColumnConfig.accessorFn(b);
    if (aVal === bVal) {
      result = 0;
    } else if (aVal == null) {
      result = 1;
    } else if (bVal == null) {
      result = -1;
    } else if (
      sortByColumnConfig.sortingFn &&
      typeof sortByColumnConfig.sortingFn === 'function'
    ) {
      result = sortByColumnConfig.sortingFn(
        { getValue: () => aVal } as unknown as Row<T | null>,
        { getValue: () => bVal } as unknown as Row<T | null>,
        sortByColumnConfig.id ?? ''
      );
    } else if (typeof aVal === 'string' && typeof bVal === 'string') {
      result = aVal.localeCompare(bVal);
    } else {
      result = aVal > bVal ? 1 : -1;
    }

    return isSortDescending ? -result : result;
  }
  return 0;
}

export function mapReportMetricToUserIds<T>(
  reportMetric: T,
  displayedColumnsConfig: ColumnDef<T | null>[]
) {
  const userIds: string[] = [];

  displayedColumnsConfig.forEach((column) => {
    // @ts-expect-error accessorFn is available
    const value = column.accessorFn(reportMetric) as unknown;

    if (
      (value as AccessorReturn<unknown>)?.stringValueType ===
      AccessorReturnType.SELLER_USER_ID
    ) {
      const stringValues =
        (value as AccessorReturn<unknown>).stringValues ??
        ((value as AccessorReturn<unknown>).value as
          | string[]
          | string
          | null
          | undefined);

      if (typeof stringValues === 'string' && stringValues) {
        userIds.push(stringValues);
      } else if (Array.isArray(stringValues)) {
        userIds.push(...stringValues);
      }
    } else if (
      (value as AccessorReturn<unknown>)?.stringValueType ===
      AccessorReturnType.PURCHASE_BUYER_INFOS
    ) {
      const buyerInfos = (
        value as AccessorReturn<PurchaseBuyerInfo[] | undefined>
      ).value;

      userIds.push(...(buyerInfos?.map((bi) => bi.buyerUserId) ?? []));
    }
  });

  return uniq(userIds);
}

// The underlying value from the table cell is seller user id -
// we need to retrieve the display name from the user info
// we already have all user info in the context, just need to match them
function resolveSellerUserId(
  value: AccessorReturn<unknown>,
  allActiveUserInfos?: Record<string, UseGetUserInfo>,
  deactivatedText?: string
): string | undefined {
  const stringValues =
    (value as AccessorReturn<unknown>).stringValues ??
    ((value as AccessorReturn<unknown>).value as
      | string[]
      | string
      | null
      | undefined);

  const stringValueList = [];
  if (typeof stringValues === 'string' && stringValues) {
    stringValueList.push(stringValues);
  } else if (Array.isArray(stringValues)) {
    stringValueList.push(...stringValues);
  }

  if (!stringValueList.length) {
    return;
  }

  return stringValueList
    .map((userId) =>
      allActiveUserInfos?.[userId]
        ? getUserDisplayName(allActiveUserInfos[userId], deactivatedText)
        : undefined
    )
    .filter((name) => name != null)
    .join(', ');
}

function resolvePurchaseBuyerInfos(
  value: AccessorReturn<unknown>,
  allActiveUserInfos?: Record<string, UseGetUserInfo>,
  deactivatedText?: string
): string | undefined {
  const buyerInfos = (value as AccessorReturn<PurchaseBuyerInfo[] | undefined>)
    .value;

  if (!buyerInfos?.length) {
    return;
  }

  return buyerInfos
    .map((bi) => {
      const userId = bi.buyerUserId;
      if (!allActiveUserInfos?.[userId]) {
        return undefined;
      }

      let displayString = getUserDisplayName(
        allActiveUserInfos[userId],
        deactivatedText
      );

      if (bi.averageBuyerCommissionPercent) {
        displayString += ` (${formatPercent(
          bi.averageBuyerCommissionPercent,
          2
        )})`;
      }

      return displayString;
    })
    .filter((name) => name != null)
    .join(', ');
}

export function mapReportMetricToTableString<T>(
  reportMetric: T,
  displayedColumnsConfig: ColumnDef<T | null>[],
  allActiveUserInfos?: Record<string, UseGetUserInfo>,
  deactivatedText?: string
) {
  const row = displayedColumnsConfig.map((column) => {
    // @ts-expect-error error complains that `accessorFn` does not exist, but actually it does
    let value = column.accessorFn(reportMetric) as unknown;

    if ((value as UiMoney)?.disp != null) {
      // UiMoney type
      value = (value as UiMoney).disp;
    } else if ((value as Money)?.display != null) {
      // Money type
      value = (value as Money).display;
    } else if (
      (value as AccessorReturn<unknown>)?.stringValueType ===
      AccessorReturnType.SELLER_USER_ID
    ) {
      // Seller user id
      value = resolveSellerUserId(
        value as AccessorReturn<unknown>,
        allActiveUserInfos,
        deactivatedText
      );
    } else if (
      (value as AccessorReturn<unknown>)?.stringValueType ===
      AccessorReturnType.PURCHASE_BUYER_INFOS
    ) {
      // Purchase buyer infos
      value = resolvePurchaseBuyerInfos(
        value as AccessorReturn<unknown>,
        allActiveUserInfos,
        deactivatedText
      );
    } else if (
      (value as AccessorReturn<unknown>)?.stringValueType ===
      AccessorReturnType.STRING
    ) {
      value = (value as AccessorReturn<unknown>).stringValues;
    }

    let stringValue = value != null ? value.toString() : '';
    // Floating point number: hardcode to 2 decimal places for now
    if (
      stringValue &&
      stringValue?.includes('.') &&
      !isNaN(parseFloat(stringValue))
    ) {
      if (parseInt(stringValue) !== parseFloat(stringValue)) {
        stringValue = parseFloat(stringValue).toFixed(2);
      }
    }

    return stringValue;
  });

  return row;
}

export const getPdfPageSize = (columnCount: number) => {
  return columnCount > 25
    ? 'a0'
    : columnCount > 15
    ? 'a2'
    : columnCount > 9
    ? 'a3'
    : 'a4';
};

export const getReportExportFileBasename = (reportName: string) => {
  const now = new Date();

  return `${reportName.replace(/\s/g, '_')}_${format(now, 'yyyy-MM-dd')}`;
};

export const exportReport = (
  report: ReportConfig,
  fileType: ReportFileType,
  tableHeader: string[],
  tableData: string[][],
  tableFoot: string[]
) => {
  if (fileType === ReportFileType.PDF) {
    const doc = new jsPDF(
      'landscape',
      'mm',
      getPdfPageSize(report.metrics.length)
    );
    autoTable(doc, {
      head: [tableHeader],
      body: tableData,
      foot: [tableFoot],
      theme: 'striped',
      margin: 5,
      styles: {
        fontSize: 8,
      },
    });
    doc.save(`${getReportExportFileBasename(report.reportName)}.pdf`);
  } else if (
    fileType === ReportFileType.CSV ||
    fileType === ReportFileType.XLSX
  ) {
    const aoa = [tableHeader, ...tableData, tableFoot];
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(aoa);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    if (fileType === ReportFileType.CSV) {
      XLSX.writeFile(
        workbook,
        `${getReportExportFileBasename(report.reportName)}.csv`,
        { bookType: 'csv' }
      );
    } else if (fileType === ReportFileType.XLSX) {
      XLSX.writeFileXLSX(
        workbook,
        `${getReportExportFileBasename(report.reportName)}.xlsx`
      );
    }
  } else {
    console.warn('Unsupported file type', fileType);
  }
};
