import { useCallback, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  BulkEditListingGroupSettingsDialog,
  BulkEditListingGroupSettingsDialogProps,
} from 'src/dialogs/BulkEdits/BulkEditListingGroupSettingsDialog';
import { BulkEditListingGroupSettingsFields } from 'src/modals/GroupListings/components/groupingTypes';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingGroupClient,
  BulkEditListingGroupSettingsInput,
  BulkEditPreviewWithDetails,
  EventWithData,
  ListingGroupUndercutSetting,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_LISTING_GROUP_SETTINGS_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchBulkEditListingGroupSettings } from './LaunchBulkEditListingGroupSettings';

export const useBulkEditListingGroupSettings = (
  filterQueryWithEventIds: ListingQuery,
  eventWithData: EventWithData,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const updateKey = INVENTORY_BULK_UPDATE_LISTING_GROUP_SETTINGS_UPDATE_KEY;

  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const eventCurrency = useMemo(
    () => eventWithData.listings?.[0]?.currency ?? 'USD',
    [eventWithData.listings]
  );

  const selectedListingIds = useMemo(
    () => filterQueryWithEventIds.entityIds ?? [],
    [filterQueryWithEventIds.entityIds]
  );

  const onlyListingGroupsSelected = useMemo(() => {
    if (selectedListingIds.length === 0 || eventWithData.listings == null) {
      return true;
    }
    const selectedListings =
      eventWithData.listings
        ?.flatMap(flattenListingGroup)
        .filter((l) => selectedListingIds.includes(l.id)) ?? [];

    const selectedListingGroups = eventWithData.listings.filter((l) =>
      selectedListingIds.includes(l.id)
    );

    if (!selectedListingGroups || selectedListingGroups.length === 0) {
      return false;
    }

    const selectedListingGroupIds = selectedListingGroups
      .map((l) => l.ltGrpId)
      .filter((id) => id != null);

    return (selectedListings ?? []).every((l) =>
      selectedListingGroupIds.includes(l.ltGrpId ?? '')
    );
  }, [selectedListingIds, eventWithData.listings]);

  const event = eventWithData.event;

  const { openDialog: openBulkEditDialog, closeDialog: closeBulkEditDialog } =
    useDialog<BulkEditListingGroupSettingsDialogProps>(
      DialogId.BulkUpdateListingGroupSettings,
      BulkEditListingGroupSettingsDialog
    );

  const onSubmit = useCallback(
    (
      input: BulkEditListingGroupSettingsFields | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        tryInvokeApi(
          async () => {
            const client = await new BulkEditListingGroupClient(
              activeAccountWebClientConfig
            );
            const preview = await client.bulkEditListingsGroupSettingsPreview(
              filterQueryWithEventIds
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkEditListingGroupSettingsPreview', error, {
              trackErrorData: {
                filterQueryWithEventIds,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (input) {
        tryInvokeApi(
          async () => {
            const client = await new BulkEditListingGroupClient(
              activeAccountWebClientConfig
            );

            const rankPremiums: ListingGroupUndercutSetting = {
              undAbsAmt: input.rankPremiums?.undercutAbsoluteAmount ?? null,
              undRelAmt: input.rankPremiums?.undercutRelativeAmount ?? null,
              actRankUndAbsAmt: null,
              actRankUndRelAmt: null,
            };

            const listingGroupListingIds = preview!.listings
              ?.flatMap(flattenListingGroup)
              .filter(({ id, isLtGrp }) => id > 0 && !isLtGrp)
              .map((l) => l.id);

            const payload: BulkEditListingGroupSettingsInput = {
              desiredActiveListings: input.desiredActiveListings ?? null,
              deprioritizedQuantities: input.deprioritizedQuantities ?? null,
              groupUndercutSetting: rankPremiums,
              marketplaceSettings: input.marketplaceSettings ?? null,
              autoPricingInputs: input.pricingSettingsInputs ?? null,
              listingGroupListingIds: listingGroupListingIds ?? [],
            };

            const succeeded = await client.bulkEditListingGroupSettings(
              payload,
              updateKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                await refreshExpandedListItems();
              }

              closeBulkEditDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkEditListingGroupSettings', error, {
              trackErrorData: {
                input,
                preview,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkEditDialog,
      filterQueryWithEventIds,
      refreshExpandedListItems,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  const openDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      openBulkEditDialog({
        event: event,
        currency: eventCurrency,
        updateKey: INVENTORY_BULK_UPDATE_LISTING_GROUP_SETTINGS_UPDATE_KEY,
        isLoading: isLoading,
        onOkay: onSubmit,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkEditDialog,
      });
    },
    [
      closeBulkEditDialog,
      event,
      eventCurrency,
      isLoading,
      onSubmit,
      openBulkEditDialog,
      setIsLoading,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkEditListingGroupSettings
        key={`LaunchBulkEditListingGroupSettings`}
        onClick={openDialog}
        onlyListingGroupsSelected={onlyListingGroupsSelected}
      />
    ),
  };
};
