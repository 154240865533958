import { useFormContext } from 'react-hook-form';
import { SignUpForm } from 'src/components/SignUp/EmailForm/EmailForm';
import * as styles from 'src/components/SignUp/EmailForm/Input/Input.css';
import { useSignUpContext } from 'src/contexts/SignUpContext/SignUpContext';

export const Input = () => {
  const { register } = useFormContext<SignUpForm>();
  const { isSignupSubmitted } = useSignUpContext();
  return (
    <input
      disabled={isSignupSubmitted}
      {...register('email', { required: true })}
      type="email"
      className={styles.input}
      placeholder="Your email"
    />
  );
};
