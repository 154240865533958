import { ComponentPropsWithRef, useRef } from 'react';
import { Components } from 'react-virtuoso';
import { useSetTopListItemPosition } from 'src/components/Events/EventListing/EventVirtualizedList/useSetTopListItemPosition';
import { VirtuosoContext } from 'src/core/ui/Virtuoso/VirtuosoContext';

export const VirtuosoTopListItemWrapper: Components<
  unknown,
  VirtuosoContext
>['TopItemList'] = ({
  children,
  style,
  context,
}: ComponentPropsWithRef<'div'> & {
  context?: VirtuosoContext;
}) => {
  const topItemListRef = useRef<HTMLDivElement | null>(null);
  const isScrollAtTop = context?.scrollY === 0;

  useSetTopListItemPosition({
    topItemListRef,
    virtuosoId: context?.virtuosoId ?? '',
    isScrollAtTop,
    virtuosoScrollY: context?.scrollY ?? 0,
  });

  return (
    <div
      ref={topItemListRef}
      style={{ ...style, zIndex: 2 }}
      data-is-top-item={true}
      data-is-scroll-at-top={isScrollAtTop}
    >
      {context?.topListItemBefore}
      {children}
    </div>
  );
};
