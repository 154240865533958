import { SortableContext } from '@dnd-kit/sortable';
import clsx from 'clsx';
import { DropWrapper } from 'src/core/POS/DragAndDropSort/DragAndDrop';
import { DropGroupWrapper } from 'src/core/POS/DragAndDropSort/DragAndDropGroup';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { HamburgerMenuIcon } from 'src/svgs/HamburgerMenuIcon';

import { ColumnListItem } from '../InventoryTableColumns/ColumnListItem/ColumnListItem';
import * as styles from './ColumnGroupListItem.css';

export const PlaceholderColumnGroupListItem = ({
  displayName,
}: {
  displayName: string;
}) => {
  return (
    <ColumnListItem draggable actionButton={null}>
      <Stack direction="row" gap="m">
        <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
          {displayName}
        </span>
      </Stack>
    </ColumnListItem>
  );
};

export const ColumnGroupListItem = ({
  id,
  groupIndex,
  items,
  columnIdDisplayNameMapping,
  highlightIndex,
  renderActions,
}: {
  id: string;
  groupIndex: number;
  items: string[];
  columnIdDisplayNameMapping: Record<string, string>;
  highlightIndex?: number;
  renderActions: (id: string) => JSX.Element;
}) => {
  return (
    <DropGroupWrapper
      id={id}
      key={id}
      columnIdDisplayNameMapping={columnIdDisplayNameMapping}
    >
      <SortableContext items={items}>
        <div className={clsx(styles.root)}>
          <div className={styles.header}>
            <HamburgerMenuIcon
              className={styles.icon}
              size={vars.iconSize.s}
              color={'#677383'}
            />
            <span className={styles.title}>
              {columnIdDisplayNameMapping[id]}
            </span>
          </div>
          <div className={styles.itemContainer}>
            {items.map((item, index) => {
              return (
                <DropWrapper id={item} key={item}>
                  <ColumnListItem
                    key={item}
                    draggable
                    actionButton={renderActions(item)}
                    highlighted={highlightIndex === groupIndex + index}
                  >
                    <span
                      style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}
                    >
                      {columnIdDisplayNameMapping[item]}
                    </span>
                  </ColumnListItem>
                </DropWrapper>
              );
            })}
          </div>
        </div>
      </SortableContext>
    </DropGroupWrapper>
  );
};
