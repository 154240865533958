import { useState } from 'react';
import { ExportInventoryButton } from 'src/components/Buttons/ExportInventoryButton';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useBulkChangeTicketTypeRuleAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkChangeTicketTypeRuleAction';
import { useBulkEditCommissionAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkEditCommissionAction/useBulkEditCommissionAction';
import { useBulkEditMarketplaceSeatingAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkEditMarketplaceSeating';
import { useBulkRemovePredelivery } from 'src/components/Listings/InventoryActionDropdown/components/BulkRemovePredeliveryAction';
import { useBulkUpdateAutoFulfillAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkUpdateAutoFulfillAction';
import { useBulkUpdateDeliveryType } from 'src/components/Listings/InventoryActionDropdown/components/BulkUpdateDeliveryType';
import { useBulkUpdateInternalNotesAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkUpdateInternalNotesAction';
import { useBulkUpdateListingSettings } from 'src/components/Listings/InventoryActionDropdown/components/BulkUpdateListingSettingsAction';
import { useBulkUpdateSeatTraits } from 'src/components/Listings/InventoryActionDropdown/components/BulkUpdateSeatTraits';
import { useBulkDeleteSeatSavers } from 'src/components/Listings/InventoryActionDropdown/components/LaunchDeleteSeatSaversAction';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

import { useInventoryEventBulkActionsState } from '../../InventoryEventBulkActions/useInventoryEventBulkActionsState';

export const SettingsActions = () => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const hasExportInventoryFeature = useUserHasFeature(Feature.ExportInventory);

  const [isLoading, setIsLoading] = useState(false);
  const { affectedVisibleListingIds, filterQueryWithEventIds } =
    useInventoryEventBulkActionsState();

  const bulkRemovePredelivery = useBulkRemovePredelivery(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkEditListingSettings = useBulkUpdateListingSettings(
    'general',
    filterQueryWithEventIds,
    affectedVisibleListingIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkUpdateInternalNotes = useBulkUpdateInternalNotesAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkUpdateAutoFulfill = useBulkUpdateAutoFulfillAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkChangeTicketTypeRule = useBulkChangeTicketTypeRuleAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkEditMarketplaceSeating = useBulkEditMarketplaceSeatingAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkDeleteSeatSavers = useBulkDeleteSeatSavers(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkEditDeliverType = useBulkUpdateDeliveryType(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );
  const bulkEditSeatTraits = useBulkUpdateSeatTraits(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkEditCommissionAction = useBulkEditCommissionAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  return (
    <>
      <PosDropdown
        key="events-settings-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Other} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {bulkEditListingSettings.dropDown}
        {bulkUpdateInternalNotes.dropDown}
        {bulkUpdateAutoFulfill.dropDown}
        {bulkRemovePredelivery.dropDown}
        {bulkChangeTicketTypeRule.dropDown}
        {bulkDeleteSeatSavers.dropDown}
        {bulkEditDeliverType.dropDown}
        {bulkEditSeatTraits.dropDown}
        {bulkEditMarketplaceSeating.dropDown}
        {bulkEditCommissionAction.dropDown}
        {hasExportInventoryFeature && (
          <PosDropdownItem key="ExportInventory">
            <ExportInventoryButton
              style={{ color: vars.color.textStrong }}
              variant="text"
              shape="rect"
              disabled={!areBulkActionsEnabled}
              textContentId={ContentId.ExportListings}
              textOnly
            />
          </PosDropdownItem>
        )}
      </PosDropdown>
    </>
  );
};
