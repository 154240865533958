import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { normalizeActivityLogMessage } from 'src/utils/activityLogUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  ActionType,
  ActivityLog,
  ActivityLogClient,
  ListingDetails,
  ListingStatus,
  Marketplace,
  MarketplaceOperationResultState,
} from 'src/WebApiController';

export type MarketplaceFailure = {
  marketplace: Marketplace;
  messages?: (string | null | undefined)[];
};

export const useGetMarketplaceFailures = (
  listing: ListingDetails
): MarketplaceFailure[] => {
  const hasBroadcastErrors = listing.mkpListings?.filter(
    (ml) =>
      ml.status === ListingStatus.ListingFailed ||
      ml.status === ListingStatus.DelistingFailed ||
      (ml.errMsgs != null && ml.errMsgs.length > 0)
  );
  const { activeAccountWebClientConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [activities, setActivities] = useState<ActivityLog[] | undefined>(
    undefined
  );
  const { trackError } = useErrorBoundaryContext();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getBroadcastErrorsForListings = useCallback(
    debounce(() => {
      tryInvokeApi(
        async () => {
          setIsLoading(true);

          const activities = await new ActivityLogClient(
            activeAccountWebClientConfig
          ).getActivityLogByFilter({
            entityId: listing.id,
            entityType: ActionOutboxEntityType.Listing,
            actionTypes: [
              ActionType.ListingBroadcast,
              ActionType.ListingUnbroadcast,
              ActionType.ListingMarketplaceInsert,
              ActionType.ListingMarketplaceUpdate,
              ActionType.ListingMarketplaceDelete,
              ActionType.BroadcastMarketplaceListing,
              ActionType.UnbroadcastMarketplaceListing,
            ],
            auditActionTypes: null,
            userId: '00000000-0000-0000-0000-000000000000', // we only want System
            impersonatedUserId: null,
            includeUserOnly: false,
            // This used to be 10, but sometimes if it's just one marketplace broadcast failed, and all others didn't,
            // top 10 may be all the successful ones, so to find a failure one we need at least 100
            take: 100,
            skip: 0,
            tagName: null,
          });

          if (activities?.length) {
            setActivities(activities);
          }
        },
        (error) => {
          trackError('ActivityLogClient.getActivityLogByFilter', error, {
            trackErrorData: {
              listingId: listing.id,
            },
          });
        },
        () => setIsLoading(false)
      );
    }, 3000),
    [activeAccountWebClientConfig, listing.id, trackError]
  );

  useEffect(() => {
    if (activities === undefined && hasBroadcastErrors.length && !isLoading) {
      getBroadcastErrorsForListings();
    }
  }, [
    activities,
    getBroadcastErrorsForListings,
    hasBroadcastErrors.length,
    isLoading,
  ]);

  const failuresByMarketplaces = useMemo(() => {
    let failureMessages: MarketplaceFailure[] = [];
    if (activities?.length) {
      const additionalInfos = activities.map(
        (a) => a.additionalInfo['MarketplaceSyncResult']
      );

      failureMessages = additionalInfos
        .filter(
          (a) =>
            a?.message?.state &&
            a.message.state !== MarketplaceOperationResultState.Succeeded
        )
        .map(
          (a) =>
            ({
              marketplace: a.message.mkp as Marketplace,
              messages: normalizeActivityLogMessage(a.message.message),
            }) as MarketplaceFailure
        );
    }

    if (hasBroadcastErrors?.length) {
      const broadcastErrorMessages = hasBroadcastErrors
        .filter((error) => error.errMsgs != null && error.errMsgs.length > 0)
        .map(
          (error) =>
            ({
              marketplace: error.mkp as Marketplace,
              messages: error.errMsgs,
            }) as MarketplaceFailure
        );
      failureMessages = [...failureMessages, ...broadcastErrorMessages];
    }

    return failureMessages;
  }, [activities, hasBroadcastErrors]);

  return failuresByMarketplaces;
};
