import signalR, {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { InventoryEventTabs } from 'src/components/Listings/InventoryEventPage';
import { useCatchUpParams } from 'src/components/Listings/InventoryEventPage/InventoryEventPage.hooks';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  EventSessionIdQueryParam,
  EventSessionRoleQueryParam,
} from 'src/utils/constants/constants';
import { newGuidId } from 'src/utils/idUtils';
import {
  ActionOutboxEntityType,
  Feature,
  ListingClient,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { SignalRLogger } from '../NotificationsContext';

export enum EventSessionRole {
  Main = 'main',
  SidePanel = 'sidePanel',
}

export type IEventHubContext = {
  hubConnection?: HubConnection;
  onStartSession: () => void;
  onCancelSession: () => void;
  onSelectListing: (listingId: number) => void;
  onSectionsSelected: (sectionIds: number[]) => void;
  onUpdateListing: (listingId: number) => void;
  onUpdateInventoryTab: (tab: InventoryEventTabs) => void;
  sessionRole: EventSessionRole | undefined;
  selectedListingId: number | undefined;
  selectedVenueMapSections: number[];
  inventoryTab: InventoryEventTabs | undefined;
  enabled: boolean;
};

export const EventHubContext = createContext<IEventHubContext>({
  hubConnection: undefined,
  onStartSession: () => void 0,
  onCancelSession: () => void 0,
  onSelectListing: (_) => void 0,
  onSectionsSelected: (_: number[]) => void 0,
  onUpdateListing: (_) => void 0,
  onUpdateInventoryTab: (_) => void 0,
  sessionRole: undefined,
  selectedListingId: undefined,
  selectedVenueMapSections: [],
  inventoryTab: undefined,
  enabled: false,
});

export const useEventHubContext = () => useContext(EventHubContext);

export function EventHubContextProvider({ children }: { children: ReactNode }) {
  const { activeAccountWebClientConfig } = useAppContext();
  const hasEventMultiScreenFeature = useUserHasFeature(
    Feature.EventMultiScreen
  );
  const { updateItemInEvent } = useCatalogDataContext();
  const [hubConnection, setHubConnection] = useState<signalR.HubConnection>();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { isCatchupMode } = useCatchUpParams();

  const sessionId = searchParams.get(EventSessionIdQueryParam) ?? undefined;
  const sessionRole = searchParams.get(EventSessionRoleQueryParam) ?? undefined;

  const [selectedListingId, setSelectedListingId] = useState<number>();
  const [inventoryTab, setInventoryTab] = useState<InventoryEventTabs>();
  const [selectedVenueMapSections, setSelectedVenueMapSections] = useState<
    number[]
  >([]);

  const reset = useCallback(() => {
    setSelectedListingId(undefined);
    setInventoryTab(undefined);
    setSelectedVenueMapSections([]);
  }, []);

  useEffect(() => {
    // Only attempt to connect to signal R when there is a login
    if (
      hasEventMultiScreenFeature &&
      activeAccountWebClientConfig.activeAccountId
    ) {
      if (
        !hubConnection ||
        hubConnection.state === HubConnectionState.Disconnected ||
        hubConnection.state === HubConnectionState.Disconnecting
      ) {
        const newHubConnection = new HubConnectionBuilder()
          .withUrl('/hubs/event')
          .withAutomaticReconnect()
          .configureLogging(new SignalRLogger())
          .build();

        setHubConnection(newHubConnection);

        newHubConnection
          .start()
          .then(() => {
            console.debug(
              `Event connection ready with id: ${newHubConnection.connectionId}`
            );
          })
          .catch((err) => {
            console.warn('Event could not be started at this time: ' + err);
          });

        newHubConnection.onclose((err) => {
          console.debug('Event connection closed', err);
        });
      }
    }

    return () => {
      if (
        hubConnection &&
        !(
          hubConnection.state === HubConnectionState.Disconnected ||
          hubConnection.state === HubConnectionState.Disconnecting
        )
      ) {
        console.debug(
          `Stopping ListingNotification connection (state: ${hubConnection.state}) with id: ${hubConnection.connectionId}`
        );
        hubConnection.stop();
        setHubConnection(undefined);
        reset();
      }
    };
  }, [
    activeAccountWebClientConfig.activeAccountId,
    hasEventMultiScreenFeature,
    hubConnection,
    reset,
  ]);

  const onStartSession = useCallback(() => {
    if (sessionRole || !hasEventMultiScreenFeature) {
      return;
    }
    const sessionId = newGuidId();

    searchParams.set(EventSessionIdQueryParam, sessionId);
    searchParams.set(EventSessionRoleQueryParam, EventSessionRole.Main);

    navigate(`/inventory/event?${searchParams.toString()}`, {
      replace: true,
    });

    searchParams.set(EventSessionRoleQueryParam, EventSessionRole.SidePanel);

    window.open(`/inventory/event?${searchParams.toString()}`, '_blank');
  }, [hasEventMultiScreenFeature, navigate, searchParams, sessionRole]);

  const onCancelSession = useCallback(() => {
    if (hasEventMultiScreenFeature && sessionId) {
      hubConnection?.invoke('StopSession', sessionId);
    }
  }, [hasEventMultiScreenFeature, hubConnection, sessionId]);

  const onSelectListing = useCallback(
    (listingId: number) => {
      if (hasEventMultiScreenFeature && sessionId) {
        hubConnection?.invoke('SelectListing', sessionId, listingId);
      }
    },
    [hasEventMultiScreenFeature, sessionId, hubConnection]
  );

  const onUpdateListing = useCallback(
    (listingId: number) => {
      if (hasEventMultiScreenFeature && sessionId && sessionRole && listingId) {
        hubConnection?.invoke(
          'UpdateListing',
          sessionId,
          listingId,
          sessionRole
        );
      }
    },
    [hasEventMultiScreenFeature, hubConnection, sessionId, sessionRole]
  );

  // This is to broadcast the venue map section selection from side panel to main
  const onSectionsSelected = useCallback(
    (selectedSections: number[]) => {
      if (
        hasEventMultiScreenFeature &&
        sessionId &&
        sessionRole === EventSessionRole.SidePanel
      ) {
        hubConnection?.invoke(
          'UpdateSelectedSections',
          sessionId,
          JSON.stringify(selectedSections),
          sessionRole
        );
      }
    },
    [hasEventMultiScreenFeature, hubConnection, sessionId, sessionRole]
  );

  // This is to broadcast the event inventory tab update from main to side panel
  const onUpdateInventoryTab = useCallback(
    (inventoryTab: InventoryEventTabs) => {
      if (
        hasEventMultiScreenFeature &&
        sessionId &&
        sessionRole === EventSessionRole.Main &&
        inventoryTab
      ) {
        hubConnection?.invoke(
          'UpdateInventoryTab',
          sessionId,
          inventoryTab,
          sessionRole
        );
      }
    },
    [hasEventMultiScreenFeature, hubConnection, sessionId, sessionRole]
  );

  useEffect(() => {
    const onListingSelected = (serverSessionId: string, listingId: number) => {
      if (
        sessionId === serverSessionId &&
        sessionRole === EventSessionRole.SidePanel
      ) {
        setSelectedListingId(listingId);
      }
    };
    const onStopSession = (serverSessionId: string) => {
      if (sessionId === serverSessionId) {
        searchParams.delete(EventSessionIdQueryParam);
        searchParams.delete(EventSessionRoleQueryParam);

        navigate(`/inventory/event?${searchParams.toString()}`, {
          replace: true,
        });
        reset();
      }
    };
    const onListingUpdated = async (
      serverSessionId: string,
      listingId: number,
      fromRole: EventSessionRole
    ) => {
      if (
        hasEventMultiScreenFeature &&
        sessionId === serverSessionId &&
        fromRole !== sessionRole
      ) {
        // Refersh listing
        const updated = await new ListingClient({
          activeAccountId: activeAccountWebClientConfig.activeAccountId,
        }).getListingByListingId(listingId);
        if (updated) {
          updateItemInEvent(updated, ActionOutboxEntityType.Listing);
        }
      }
    };
    const onInventoryTabUpdated = (
      serverSessionId: string,
      tab: InventoryEventTabs,
      _: EventSessionRole
    ) => {
      if (
        sessionId === serverSessionId &&
        sessionRole === EventSessionRole.SidePanel
      ) {
        setInventoryTab(tab);
      }
    };
    const onVenueMapSectionsUpdated = (
      serverSessionId: string,
      selectedSectionsJson: string,
      _: EventSessionRole
    ) => {
      if (
        sessionId === serverSessionId &&
        sessionRole === EventSessionRole.Main
      ) {
        setSelectedVenueMapSections(
          JSON.parse(selectedSectionsJson) as number[]
        );
      }
    };
    hubConnection?.on('OnListingSelected', onListingSelected);
    hubConnection?.on('OnListingUpdated', onListingUpdated);
    hubConnection?.on('OnInventoryTabUpdated', onInventoryTabUpdated);
    hubConnection?.on('OnVenueMapSectionsUpdated', onVenueMapSectionsUpdated);
    hubConnection?.on('OnStopSession', onStopSession);

    return () => {
      hubConnection?.off('OnListingSelected', onListingSelected);
      hubConnection?.off('OnListingUpdated', onListingUpdated);
      hubConnection?.off('OnInventoryTabUpdated', onInventoryTabUpdated);
      hubConnection?.off(
        'OnVenueMapSectionsUpdated',
        onVenueMapSectionsUpdated
      );
      hubConnection?.off('OnStopSession', onStopSession);
    };
  }, [
    sessionId,
    hubConnection,
    sessionRole,
    onStartSession,
    onCancelSession,
    searchParams,
    navigate,
    reset,
    hasEventMultiScreenFeature,
    activeAccountWebClientConfig.activeAccountId,
    updateItemInEvent,
    setInventoryTab,
  ]);

  const enabled = useMemo(() => {
    // Disable when feature is off
    if (!hasEventMultiScreenFeature) {
      return false;
    }

    if (hubConnection?.state !== HubConnectionState.Connected) {
      return false;
    }

    // disable for catchup mode
    if (isCatchupMode) {
      return false;
    }

    return true;
  }, [hasEventMultiScreenFeature, hubConnection?.state, isCatchupMode]);

  return (
    <EventHubContext.Provider
      value={{
        enabled,
        hubConnection,
        onStartSession,
        onCancelSession,
        onSelectListing,
        onSectionsSelected,
        onUpdateListing,
        onUpdateInventoryTab,
        sessionRole: sessionRole
          ? (sessionRole as EventSessionRole)
          : undefined,
        selectedListingId,
        selectedVenueMapSections,
        inventoryTab,
      }}
    >
      {children}
    </EventHubContext.Provider>
  );
}
