import { sum } from 'lodash-es';
import { IconButton } from 'src/components/Buttons';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { BroadcastIconInverted, UnbroadcastIcon } from 'src/svgs';
import { ClockIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Listing, ListingGroup, ListingStatus } from 'src/WebApiController';

export const ListingGroupStatusSummary = ({
  listingGroup,
}: {
  listingGroup: ListingGroup;
}) => {
  return (
    <Stack direction="row" gap="m" alignItems="center">
      <Stack direction="row" gap="s" alignItems="center">
        <IconButton
          icon={<BroadcastIconInverted size={vars.iconSize.m} />}
          titleContentId={ContentId.ListingGroupSummaryActiveAndActive}
        />
        {sum(
          listingGroup.groupItems
            .flatMap((l) => (l.isLtGrp ? (l as ListingGroup).groupItems : []))
            .filter(
              (l) =>
                !l.isLtGrp &&
                ((l as Listing).status === ListingStatus.Listed ||
                  (l as Listing).status === ListingStatus.ListingPending)
            )
            .map((l) => l.availQty)
        )}
      </Stack>
      <Stack direction="row" gap="s" alignItems="center">
        <IconButton
          icon={<UnbroadcastIcon size={vars.iconSize.m} noOutline />}
          titleContentId={ContentId.ListingGroupSummaryActiveButNotListed}
        />
        {sum(
          listingGroup.groupItems
            .flatMap((l) => (l.isLtGrp ? (l as ListingGroup).groupItems : []))
            .filter(
              (l) =>
                !l.isLtGrp &&
                !(
                  (l as Listing).status === ListingStatus.Listed ||
                  (l as Listing).status === ListingStatus.ListingPending
                )
            )
            .map((l) => l.availQty)
        )}
      </Stack>
      <Stack direction="row" gap="s" alignItems="center">
        <IconButton
          icon={<ClockIcon size={vars.iconSize.m} noOutline />}
          titleContentId={ContentId.ListingGroupSummaryQueued}
        />
        {sum(
          listingGroup.groupItems
            .flatMap((l) => (l.isLtGrp ? (l as ListingGroup).groupItems : []))
            .filter((l) => l.availQty > 0)
            .map((l) => l.availQty)
        )}
      </Stack>
    </Stack>
  );
};
