import { Updater } from '@tanstack/react-table';
import { AdCampaignsTableColumnId } from 'src/utils/columns/adCampaigns/adCampaignsColumnUtils.types';
import { AdGroupsTableColumnId } from 'src/utils/columns/adGroups/adGroupsColumnUtils.types';
import { EventsTableColumnId } from 'src/utils/columns/events/eventsColumnUtils.types';
import {
  ListingReportTableColumnId,
  ListingTableColumnId,
} from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import {
  SalesReportTableColumnId,
  SalesTableColumnId,
} from 'src/utils/columns/sales/salesColumnUtils.types';

export type TableColumnIdType =
  | ListingTableColumnId
  | SalesTableColumnId
  | PurchasesTableColumnId
  | ListingReportTableColumnId
  | SalesReportTableColumnId
  | EventsTableColumnId
  | AdCampaignsTableColumnId
  | AdGroupsTableColumnId;

export type CustomColumnWidthsType<T extends TableColumnIdType> = Partial<
  Record<T, number>
>;

export type ColumnResizingContextType<T extends TableColumnIdType> = {
  isResizeEnabled: boolean;
  customColumnWidths: CustomColumnWidthsType<T>;
  onColumnSizingChange?: (x: Updater<CustomColumnWidthsType<T>>) => void;
};

export const COLUMN_DEFAULT_SIZE_XS = 12;
export const COLUMN_DEFAULT_SIZE_SM = 50;
export const COLUMN_DEFAULT_SIZE_BASE = 70;
export const COLUMN_DEFAULT_SIZE_LG = 130;
export const COLUMN_DEFAULT_SIZE_XL = 220;
export const COLUMN_DEFAULT_SIZE_XXL = 300;
export const COLUMN_MIN_SIZE = 45;
export const COLUMN_MAX_SIZE = 750;
