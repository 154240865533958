import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { Switch } from 'src/core/interim/Switch';
import { vars } from 'src/core/themes';
import { SimpleTable } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { getListingBroadcastIcon } from 'src/utils/inventoryUtils';
import { Event, ListingStatus, Marketplace } from 'src/WebApiController';

import * as styles from './MarketplaceDisplay.css';

export const MarketplaceDisplay = ({
  marketplace,
  marketplaceActive,
  isSelected,
  isActive,
  isLoading,
  event,
  isUKEvent,
  isViagogo,
  disabled,
  onListingBroadcastToggle,
}: {
  marketplace: Marketplace;
  marketplaceActive: boolean;
  isSelected: boolean;
  isActive: boolean;
  isLoading: boolean;
  event?: Event;
  isUKEvent?: boolean;
  isViagogo?: boolean;
  disabled?: boolean;
  onListingBroadcastToggle: (
    isSelected: boolean,
    isBroadcasting: boolean,
    marketplace: Marketplace
  ) => void;
}) => {
  const alwaysShowAsDelisted =
    isUKEvent && marketplace === Marketplace.StubHub && !isViagogo;

  const isChecked = !alwaysShowAsDelisted && isActive && isSelected;

  const { iconComponent: ListingBroadcastIcon } = getListingBroadcastIcon(
    alwaysShowAsDelisted || !marketplaceActive
      ? ListingStatus.Delisted // We always show Delisted for StubHub when event is in UK
      : isActive
      ? isLoading
        ? ListingStatus.ListingPending
        : ListingStatus.Listed
      : isLoading
      ? ListingStatus.DelistingPending
      : ListingStatus.Delisted
  );

  const listingStatusCell = (
    <>
      {isSelected && <ListingBroadcastIcon size={vars.iconSize.m} />}
      <span>
        <Content
          id={
            isSelected
              ? alwaysShowAsDelisted
                ? ContentId.Delisted // We always show Delisted for StubHub when event is in UK
                : !marketplaceActive
                ? ContentId.BroadcastDisabled
                : isActive
                ? isLoading
                  ? ContentId.ListingPending
                  : ContentId.ToBeListed
                : isLoading
                ? ContentId.DelistingPending
                : ContentId.ToBeDelisted
              : marketplaceActive
              ? ContentId.DontChange
              : ContentId.BroadcastDisabled
          }
        />
      </span>
    </>
  );

  return (
    <SimpleTable.Tr
      className={styles.broadcastRow}
      key={`${marketplace}-${isViagogo}`}
    >
      <SimpleTable.Td className={styles.broadcastCell}>
        <Checkbox
          checked={isSelected}
          disabled={disabled || !marketplaceActive}
          onChange={() =>
            onListingBroadcastToggle(!isSelected, isChecked, marketplace)
          }
        />
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.broadcastCell}>
        <MarketplaceLogo
          marketplace={marketplace}
          event={event}
          grayScaleColor={!isSelected}
          isViagogo={isViagogo}
        />
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.broadcastCell}>
        <Switch
          checked={isChecked}
          disabled={
            disabled ||
            (!isChecked && !(marketplaceActive ?? true)) ||
            !isSelected
          }
          onChange={() => {
            onListingBroadcastToggle(isSelected, !isChecked, marketplace);
          }}
          containerClassName={styles.broadcastStatus}
        />
      </SimpleTable.Td>
      <SimpleTable.Td className={styles.broadcastCell}>
        <div className={styles.broadcastCellData}>{listingStatusCell}</div>
      </SimpleTable.Td>
    </SimpleTable.Tr>
  );
};
