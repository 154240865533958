import type { ForwardedRef } from 'react';

/**
 * @param refs
 * @returns Given refs merged into a single ref.
 */
export function mergeRefs<T>(
  ...refs: [ForwardedRef<T>, ForwardedRef<T>, ...ForwardedRef<T>[]]
): ForwardedRef<T> {
  return (val) => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(val);
      } else if (ref) {
        ref.current = val;
      }
    }
  };
}
