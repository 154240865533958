import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { ModalProps } from 'src/modals/Modal';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';

import { UploadExternalIdsContent } from './UploadExternalIds';

export type ViewExternalIdModalProps = {
  cancelTo?: ModalProps;
  defaultToBulk?: boolean;
  isReadOnly?: boolean;
  loadingContentId: FormatContentId;
  searchingContentId: FormatContentId;
  errorContentId: FormatContentId;
};

export const ViewExternalIds = ({
  cancelTo,
  loadingContentId,
  searchingContentId,
  errorContentId,
}: ViewExternalIdModalProps) => {
  const { loadingState } = useEventItemLoadingDisplay<EntityWithRealTickets>(
    loadingContentId,
    searchingContentId,
    errorContentId
  );

  return (
    loadingState || <UploadExternalIdsContent cancelTo={cancelTo} isReadOnly />
  );
};
