import { PosIconProps, SvgWrapper } from 'src/svgs/SvgWrapper';

export function ChevronLeftIcon(props: PosIconProps) {
  return (
    <SvgWrapper
      aria-label="ChevronLeftIcon"
      fill="currentColor"
      {...props}
      viewBox="0 0 10 16"
    >
      <path d="M6.625 14.045L.804 8.232q-.33-.33-.33-.808t.33-.808L6.625.803q.33-.33.808-.33t.808.33l.67.67q.33.33.33.808t-.33.808L4.572 7.428l4.339 4.33q.33.339.33.813t-.33.804l-.67.67q-.33.33-.808.33t-.808-.33z" />
    </SvgWrapper>
  );
}
