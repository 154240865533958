import * as RadixSwitch from '@radix-ui/react-switch';
import { mergeProps } from 'src/core/utils';

import * as styles from './Switch.css';

export const Switch = (props: RadixSwitch.SwitchProps) => (
  <RadixSwitch.Root
    className={styles.switchRoot}
    {...mergeProps(
      {
        className: styles.switchRoot,
      },
      props
    )}
  >
    <RadixSwitch.Thumb className={styles.switchThumb} />
  </RadixSwitch.Root>
);
