import { DndContext } from '@dnd-kit/core';
import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { ToolbarButton } from 'src/core/POS/ToolbarButton';
import { vars } from 'src/core/themes';
import { Button, Popover } from 'src/core/ui';
import { DeleteIcon, EditIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ListIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import {
  CustomFilterIconsContainer,
  CustomFilterItem,
  CustomFilterPopoverContainer,
  CustomFilterPopoverTitle,
} from '../FilterToolbar.styled';
import { QuickFilterToolbarItem } from '../QuickFilterToolbarItem';
import * as styles from './CustomFilterButton.css';

export type CustomFilterButtonProps = {
  quickFilters: QuickFilterToolbarItem[];
  onToggleQuickFilter?: (id: string, isShown: boolean) => void;
  onMoveQuickFilter?: (id: string, overId: string) => void;
  onCreateCustomQuickFilter?: () => void;
  onDeleteCustomQuickFilter?: (id: string) => void;
  onUpdateCustomQuickFilter?: (id: string) => void;
};

export function CustomFilterButton({
  quickFilters,
  onToggleQuickFilter,
  onMoveQuickFilter,
  onCreateCustomQuickFilter,
  onDeleteCustomQuickFilter,
  onUpdateCustomQuickFilter,
}: CustomFilterButtonProps) {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <ToolbarButton className={styles.button}>
          <Content id={ContentId.CustomFilter} />
        </ToolbarButton>
      </Popover.Trigger>
      <Popover.Content asChild align="end">
        <CustomFilterPopoverContainer>
          <CustomFilterPopoverTitle>
            <Content id={ContentId.QuickFilters} />
          </CustomFilterPopoverTitle>
          <DndContext
            onDragEnd={function handleDragEnd(event) {
              const { active, over } = event;
              if (!over) return;

              onMoveQuickFilter?.(active.id.toString(), over.id.toString());
            }}
          >
            <SortableContext items={quickFilters}>
              {quickFilters?.map((item) => (
                <SortableCustomFilterItem
                  {...item}
                  key={item.id}
                  onToggle={(isShown) => {
                    onToggleQuickFilter?.(item.id, isShown);
                  }}
                  onEdit={() => {
                    onUpdateCustomQuickFilter?.(item.id);
                  }}
                  onDelete={() => {
                    onDeleteCustomQuickFilter?.(item.id);
                  }}
                />
              ))}
            </SortableContext>
          </DndContext>

          <Popover.Close asChild>
            <Button
              variant="text"
              style={{ width: 'fit-content' }}
              onClick={() => {
                onCreateCustomQuickFilter?.();
              }}
            >
              <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
              <span>
                <Content id={ContentId.CreateCustomFilter} />
              </span>
            </Button>
          </Popover.Close>
        </CustomFilterPopoverContainer>
      </Popover.Content>
    </Popover.Root>
  );
}

export type SortableCustomFilterItemProps = QuickFilterToolbarItem & {
  onToggle?: (isShown: boolean) => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

export function SortableCustomFilterItem({
  id,
  name,
  isCustomFilter,
  isHidden,
  onToggle,
  onEdit,
  onDelete,
}: SortableCustomFilterItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  return (
    <CustomFilterItem
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <Checkbox
        labelPosition="right"
        label={name}
        checked={!isHidden}
        onChange={(e) => {
          onToggle?.(e.target.checked);
        }}
      />
      <CustomFilterIconsContainer>
        {isCustomFilter && (
          <>
            <EditIcon
              fill={IconsFill.currentColor}
              withHoverEffect
              onClick={() => {
                onEdit?.();
              }}
            />
            <DeleteIcon
              fill={IconsFill.currentColor}
              withHoverEffect
              onClick={() => {
                onDelete?.();
              }}
            />
          </>
        )}
        <div className={styles.moveIcon} {...attributes} {...listeners}>
          <ListIcon fill={IconsFill.currentColor} />
        </div>
      </CustomFilterIconsContainer>
    </CustomFilterItem>
  );
}
