import { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { MultiSelectionToggleGroup } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGroup';
import { EventItemHeader } from 'src/components/Events/EventItemHeader';
import {
  EventItemHeaderType,
  METRICS_PLACEHOLDER_DIMS,
} from 'src/components/Events/EventItemHeader/EventItemHeader';
import { InventoryEventActionDropdown } from 'src/components/Listings/InventoryActionDropdown';
import {
  MetricsDisplay,
  MetricsDisplayPlaceholder,
} from 'src/components/Metrics';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { vars } from 'src/core/themes';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';
import { TagIcon } from 'src/svgs';
import { FullPageIcon } from 'src/svgs/FullPageIcon';
import { EventIdQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getInventoryListingMetrics,
  getInventoryListingMetricsV2,
} from 'src/utils/ticketMetricUtils';
import { Feature, ListingMetrics } from 'src/WebApiController';

import * as styles from './EventListingHeader.css';

export const EventListingHeader = (props: EventItemHeaderType) => {
  const location = useLocation();
  const { loginContext } = useAppContext();

  const { event, ...rest } = props;
  const { venue, performer } = useGetEventFullInfo(event);
  const hasInventoryTileMetricV2Feature = useUserHasFeature(
    Feature.InventoryTileMetricV2
  );

  const hasInventoryEventPageV2Feature = useUserHasFeature(
    Feature.InventoryEventPageV2
  );
  const getInventoryListingMetricsFn = hasInventoryTileMetricV2Feature
    ? getInventoryListingMetricsV2
    : getInventoryListingMetrics;

  const { eventMetrics } = useCatalogMetricsContext<ListingMetrics>();
  const metrics = useMemo(
    () =>
      getInventoryListingMetricsFn(
        eventMetrics?.[event.viagVirtualId],
        loginContext?.user?.activeAccount?.currencyCode
      ),
    [
      getInventoryListingMetricsFn,
      eventMetrics,
      event.viagVirtualId,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );

  const {
    eventsExpansion: { expandedListItems, toggleListItemExpansion },
  } = useCatalogDataContext();

  const onToggleGroup = useCallback(
    (groupId: string) => {
      if (!expandedListItems.includes(groupId)) {
        toggleListItemExpansion([groupId]);
      }
    },
    [expandedListItems, toggleListItemExpansion]
  );

  const eventId = event.viagVirtualId;
  const searchParam = useMemo(() => {
    const searchParam = new URLSearchParams(location.search);
    searchParam.set(EventIdQueryParam, eventId);
    return searchParam;
  }, [eventId, location.search]);

  const actions = useMemo(
    () => [
      ...(hasInventoryEventPageV2Feature
        ? [
            <Link
              key="OpenEventPageV2"
              to={{
                pathname: createInventoryEventUrl(
                  eventId,
                  false, // includeSearchParams
                  true // useEventV2
                ),
                search: searchParam.toString(),
              }}
              state={{
                returnUrl: `${location.pathname}${location.search}#${eventId}`,
              }}
            >
              <IconButton
                icon={
                  <FullPageIcon
                    fill={vars.color.textWarning}
                    size={vars.iconSize.m}
                    withHoverEffect
                  />
                }
                titleContentId={ContentId.OpenEventPageV2InDevelopment}
              />
            </Link>,
          ]
        : []),
      <Link
        key="OpenEventPage"
        to={{
          pathname: createInventoryEventUrl(
            eventId,
            false // includeSearchParams
          ),
          search: searchParam.toString(),
        }}
        state={{
          returnUrl: `${location.pathname}${location.search}#${eventId}`,
        }}
      >
        <IconButton
          icon={
            <FullPageIcon
              fill={vars.color.textBrand}
              size={vars.iconSize.m}
              withHoverEffect
            />
          }
          titleContentId={ContentId.OpenEventPage}
        />
      </Link>,
      <MultiSelectionToggleGroup
        key="MultiSelectionToggleGroup"
        groupId={props.event.viagVirtualId}
        onToggle={onToggleGroup}
      />,
      <InventoryEventActionDropdown
        key="InventoryEventActionDropdown"
        {...props}
      />,
    ],
    [
      eventId,
      hasInventoryEventPageV2Feature,
      location.pathname,
      location.search,
      onToggleGroup,
      props,
      searchParam,
    ]
  );

  return (
    <EventItemHeader
      {...rest}
      event={event}
      performer={performer}
      venue={venue}
      headerMetrics={
        metrics && (
          <MetricsDisplay
            key="headermetrics"
            mainMetrics={
              <div className={styles.mainMetricsContainer}>
                {!hasInventoryTileMetricV2Feature && (
                  <TagIcon fill={vars.color.textPrimary} />
                )}
                {metrics.mainMetrics}
              </div>
            }
          />
        )
      }
      metrics={
        !metrics ? (
          <MetricsDisplayPlaceholder
            key="metrics-placeholder"
            {...METRICS_PLACEHOLDER_DIMS}
          />
        ) : (
          <MetricsDisplay
            key="metrics"
            mainMetrics={null}
            subMetrics={metrics.subMetrics}
          />
        )
      }
      actions={actions}
      showMappingState={true}
    />
  );
};
