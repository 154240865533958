import { DownloadButton } from 'src/components/Buttons/DownloadButton';
import { OkButton } from 'src/components/Buttons/OkButton';
import { Content } from 'src/contexts/ContentContext';
import { DnDFileUploader } from 'src/core/POS/DnDFileUploader';
import { Stack } from 'src/core/ui';
import { WarningOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as modalHeaderStyles from '../MessagesAddSourceModal/MessagesAddSourceModal.css';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal';

export function UploadSeatScoreModal({
  isOpen,
  isLoading,
  uploadedFile,
  onDownloadTemplateClick,
  onUploadedFileChange,
  onCsvFileUploaded,
  onClose,
}: {
  isOpen?: boolean;
  isLoading?: boolean;
  uploadedFile?: File | undefined;
  onDownloadTemplateClick: () => void;
  onUploadedFileChange: (file: File) => void;
  onCsvFileUploaded: () => void;
  onClose?: () => void;
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} unmountOnClose centered size="sm">
      <ModalHeader onClose={onClose}>
        <div className={modalHeaderStyles.headerBar}>
          <h5 className={modalHeaderStyles.headerText}>
            <Content id={ContentId.UploadSeatScoreFile} />
          </h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={modalHeaderStyles.modalBody}>
          <DownloadButton
            disabled={isLoading}
            variant="outline"
            textContentId={ContentId.DownloadTemplate}
            onClick={onDownloadTemplateClick}
          />
          <DnDFileUploader
            disabled={isLoading}
            onChange={(files: File[]) =>
              files[0] && onUploadedFileChange(files[0])
            }
            acceptedFileTypes={['.csv']}
          />
          <Stack direction="row" alignItems="center" gap="m">
            <WarningOutlineIcon className={modalHeaderStyles.icon} />
            <Content id={ContentId.UploadSeatScoreModelInfo} />
          </Stack>
        </div>
      </ModalBody>
      <ModalFooter>
        <OkButton
          textContentId={ContentId.Save}
          disabled={isLoading || !uploadedFile}
          onClick={() => onCsvFileUploaded()}
        />
      </ModalFooter>
    </Modal>
  );
}
