import {
  PURCHASES_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES,
  PURCHASES_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG,
} from 'src/utils/columns/purchases/purchasesColumnUtils.constants';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import { CustomPurchasesColumn } from 'src/utils/columns/purchases/purchasesCustomColumnUtils.types';
import { ReportTag, Tag, TagsValueType } from 'src/WebApiController';

import { SectionType } from '../types/sectionType';
import { AdCampaignsListingsColumn } from './adCampaigns/adCampaignsCustomColumnUtils.types';
import { AdGroupsListingsColumn } from './adGroups/adGroupsCustomColumnUtils.types';
import { PERSONALIZATION_DEFAULTS } from './columnUtils.types';
import { EVENTS_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from './events/eventsColumnUtils.constants';
import { EventsTableColumnId } from './events/eventsColumnUtils.types';
import { getPersonalizationFlagsFromFormula as getPersonalizationFlagsFromFormulaEvents } from './events/eventsCustomColumnUtils';
import { EVENTS_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG } from './events/eventsCustomColumnUtils.constants';
import { CustomEventsColumn } from './events/eventsCustomColumnUtils.types';
import {
  LISTING_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES,
  LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES,
  LISTING_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG,
} from './inventory/inventoryColumnUtils.constants';
import {
  ListingReportTableColumnId,
  ListingTableColumnId,
  ListingTableFlattenedColumnId,
} from './inventory/inventoryColumnUtils.types';
import { getPersonalizationFlagsFromFormula as getPersonalizationFlagsFromFormulaListings } from './inventory/inventoryCustomColumnUtils';
import { CustomListingColumn } from './inventory/inventoryCustomColumnUtils.types';
import { getPersonalizationFlagsFromFormula as getPersonalizationFlagsFromFormulaListingsReport } from './inventory/inventoryReportCustomColumnUtils';
import { SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from './sales/salesColumnUtils.constants';
import {
  SalesReportTableColumnId,
  SalesTableColumnId,
  SalesTableFlattenedColumnId,
} from './sales/salesColumnUtils.types';
import {
  getPersonalizationFlagsFromFormula as getPersonalizationFlagsFromFormulaSales,
  SALE_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG,
} from './sales/salesCustomColumnUtils';
import { CustomSalesColumn } from './sales/salesCustomColumnUtils.types';
import { SALE_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from './sales/salesReportColumnUtils.constants';

export const getColumnPersonalization = ({
  id,
  sectionType,
  matchingCustomColumn,
  matchingTag,
}: {
  id: string;
  sectionType: SectionType;
  matchingCustomColumn?:
    | CustomListingColumn
    | CustomSalesColumn
    | CustomEventsColumn
    | CustomPurchasesColumn
    | AdCampaignsListingsColumn
    | AdGroupsListingsColumn;
  matchingTag?: Tag | ReportTag;
}) => {
  const personalizationDefaults = { ...PERSONALIZATION_DEFAULTS };

  if (matchingTag) {
    personalizationDefaults.isNumeric =
      matchingTag.valueType === TagsValueType.Int ||
      matchingTag.valueType === TagsValueType.Decimal;
    personalizationDefaults.isInteger =
      matchingTag.valueType === TagsValueType.Int;

    return { ...personalizationDefaults };
  } else {
    const {
      customColumnPersonalizationConfig,
      personalizationOverride,
      getPersonalizationFlagsFromFormula,
    } =
      sectionType === SectionType.Listings ||
      sectionType === SectionType.ListingsFlattened
        ? {
            customColumnPersonalizationConfig:
              LISTING_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG,
            personalizationOverride:
              LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
                id as ListingTableColumnId | ListingTableFlattenedColumnId
              ],
            getPersonalizationFlagsFromFormula:
              getPersonalizationFlagsFromFormulaListings,
          }
        : sectionType === SectionType.InventorySideTable
        ? {
            customColumnPersonalizationConfig:
              LISTING_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG,
            personalizationOverride:
              LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
                id as ListingTableColumnId | ListingTableFlattenedColumnId
              ],
            getPersonalizationFlagsFromFormula:
              getPersonalizationFlagsFromFormulaListingsReport,
          }
        : sectionType === SectionType.ListingsReport
        ? {
            customColumnPersonalizationConfig:
              LISTING_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG,
            personalizationOverride:
              LISTING_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
                id as ListingReportTableColumnId
              ],
            getPersonalizationFlagsFromFormula:
              getPersonalizationFlagsFromFormulaListingsReport,
          }
        : sectionType === SectionType.Sales ||
          sectionType === SectionType.SalesFlattened ||
          sectionType === SectionType.SalesSideTable
        ? {
            customColumnPersonalizationConfig:
              SALE_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG,
            personalizationOverride:
              SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
                id as SalesTableColumnId | SalesTableFlattenedColumnId
              ],
            getPersonalizationFlagsFromFormula:
              getPersonalizationFlagsFromFormulaSales,
          }
        : sectionType === SectionType.SalesReport
        ? {
            customColumnPersonalizationConfig:
              SALE_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG,
            personalizationOverride:
              SALE_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
                id as SalesReportTableColumnId
              ],
            getPersonalizationFlagsFromFormula:
              getPersonalizationFlagsFromFormulaSales,
          }
        : sectionType === SectionType.Purchases ||
          sectionType === SectionType.PurchaseEvent ||
          sectionType === SectionType.PurchaseSideTable
        ? {
            customColumnPersonalizationConfig:
              PURCHASES_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG,
            personalizationOverride:
              PURCHASES_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
                id as PurchasesTableColumnId
              ],
            getPersonalizationFlagsFromFormula: () => ({
              isCurrency: false,
              isPercent: false,
            }),
          }
        : // SectionType.Event
          {
            customColumnPersonalizationConfig:
              EVENTS_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG,
            personalizationOverride:
              EVENTS_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
                id as EventsTableColumnId
              ],
            getPersonalizationFlagsFromFormula:
              getPersonalizationFlagsFromFormulaEvents,
          };

    const personalization = matchingCustomColumn
      ? customColumnPersonalizationConfig
      : personalizationOverride;

    if (matchingCustomColumn?.formula) {
      const personalizationFromMathToken = getPersonalizationFlagsFromFormula(
        matchingCustomColumn.formula
      );
      personalizationDefaults.isCurrency =
        personalizationFromMathToken.isCurrency ??
        personalizationDefaults.isCurrency;
      personalizationDefaults.isPercent =
        personalizationFromMathToken.isPercent ??
        personalizationDefaults.isPercent;
    }

    return { ...personalizationDefaults, ...personalization };
  }
};
