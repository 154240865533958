import { BasePageDocumentProps } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/BasePageDocumentProps';

export enum UploadETicketsDraggableOrigin {
  PreviewModal = 'previewModal',
  TicketCard = 'TicketCard',
}

export interface UploadETicketsDraggableData {
  isSinglePageDocument: boolean;
  componentProps: BasePageDocumentProps;
  origin: UploadETicketsDraggableOrigin;
}
