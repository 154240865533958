import { ReactNode } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './Metrics.css';

export type ListingMainMetricsProps = {
  ticketsCount: ReactNode;
};

export function ListingMainMetrics({ ticketsCount }: ListingMainMetricsProps) {
  return (
    <div>
      {ticketsCount} <Content id={ContentId.Tickets} />
    </div>
  );
}

export type ListingSubMetricsProps = {
  totalUnsoldListPrice: ReactNode;
  totalNetProceeds: ReactNode;
  totalGrossMargin: ReactNode;
};

export function ListingSubMetrics({
  totalUnsoldListPrice,
  totalNetProceeds,
  totalGrossMargin,
}: ListingSubMetricsProps) {
  return (
    <div className={styles.listingSubMetricsContainer}>
      <span className={styles.listingSubMetrics}>{totalUnsoldListPrice}</span>
      <span className={styles.listingSubMetrics}>{totalNetProceeds}</span>
      <span className={styles.listingSubMetrics}>{totalGrossMargin}</span>
    </div>
  );
}
