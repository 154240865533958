import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export const CustomColumnInstruction = () => {
  return (
    <Stack
      direction="column"
      gap="s"
      style={{
        padding: vars.spacing['m'],
        backgroundColor: vars.color.backgroundSecondary,
        color: vars.color.textStrong,
        borderRadius: vars.borderRadius.m,
        fontSize: vars.typography.fontSize['sm'],
        border: `1px solid ${vars.color.border} !important`,
      }}
    >
      <span>
        <Content id={ContentId.FormulaBuilderInfoLine1} />
      </span>
      <span>
        <Content id={ContentId.FormulaBuilderInfoLine2} />
      </span>
      <span>
        <Content id={ContentId.FormulaBuilderInfoLine3} />
      </span>
      <span>
        <Content id={ContentId.FormulaBuilderInfoLine4} />
      </span>
      <span>
        <Content id={ContentId.FormulaBuilderInfoLine5} />
      </span>
      <span>
        <Content id={ContentId.FormulaBuilderInfoLine6} />
      </span>
    </Stack>
  );
};
