import { useEffect, useMemo, useRef, useState } from 'react';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Card } from 'src/core/ui';
import { useGetAccessibleVendorsQuery } from 'src/hooks/api/useGetAccessibleVendorsQuery';
import { useOpenVendorDetailsModal } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorActions/useOpenVendorDetailsModal';
import {
  PurchaseVendorActionsImperativeRef,
  VendorActions,
} from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorActions/VendorActions';
import { VendorsPageHeader } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsPageHeader/VendorsPageHeader';
import { VendorsTable } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsTable/VendorsTable';
import {
  PurchaseVendorActions,
  PurchaseVendorsWithActions,
} from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsTable/VendorsTableColumnsConfig';
import { useGetFilteredVendors } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useGetFilteredVendors';
import { PurchaseVendor } from 'src/WebApiController';

export const Vendors = () => {
  const refActions = useRef<PurchaseVendorActionsImperativeRef | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const vendorsQuery = useGetAccessibleVendorsQuery({
    getOverride: false,
    getVendorAccountsCount: true,
  });
  const allVendors = vendorsQuery.data;

  const { openVendorDetailsModalFromUrl } = useOpenVendorDetailsModal();

  useEffect(() => {
    openVendorDetailsModalFromUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vendorsWithActions: PurchaseVendorsWithActions[] = useMemo(() => {
    const purchaseVendorActions: PurchaseVendorActions = {
      editVendor: (vendor: PurchaseVendor) => {
        refActions.current?.editVendor(vendor);
      },
      viewVendorDetails: (vendor: PurchaseVendor) => {
        refActions.current?.viewVendorDetails(vendor);
      },
      addNewVendorAccount: (vendor: PurchaseVendor) => {
        refActions.current?.addNewVendorAccount(vendor);
      },
    };

    return (allVendors ?? []).map(
      (vendor: PurchaseVendor): PurchaseVendorsWithActions => ({
        vendor,
        vendorActions: purchaseVendorActions,
      })
    );
  }, [allVendors]);

  const filteredVendors = useGetFilteredVendors({
    vendors: vendorsWithActions,
    searchText,
  });

  const isLoading = vendorsQuery.isLoading;

  return (
    <>
      <VendorsPageHeader
        disableSearch={isLoading}
        onVendorAdded={vendorsQuery.refetch}
        onSearchTextChanged={setSearchText}
      />
      {isLoading ? (
        <PosSpinner />
      ) : (
        <Card>
          <VendorsTable vendors={filteredVendors} />
        </Card>
      )}
      <VendorActions vendorActionsRef={refActions} />
    </>
  );
};
