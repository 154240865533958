import { useContent } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { vars } from 'src/core/themes';
import { Stack, TooltipPopover } from 'src/core/ui';
import { InfoOutlineIcon } from 'src/svgs/Viagogo';
import { PERMISSION_TO_CID } from 'src/utils/constants/contentIdMaps';
import { SellerPermissionInfo } from 'src/WebApiController';

export const SellerPermissionDisplay = ({
  disabled,
  isSelected,
  toggleSelect,
  permissionInfo,
  part,
}: {
  disabled?: boolean;
  isSelected?: boolean;
  toggleSelect: () => void;
  permissionInfo: SellerPermissionInfo;
  part: 'view' | 'action';
}) => {
  const permissionContent = PERMISSION_TO_CID[permissionInfo.permission];
  const title = useContent(permissionContent[0]);
  const details = useContent(permissionContent[1]);
  const permPart = permissionContent[2];

  if (permPart !== part) {
    return null;
  }

  return (
    <Stack gap="m">
      <Checkbox
        disabled={disabled}
        checked={isSelected ?? false}
        onChange={() => toggleSelect()}
        label={title}
        labelPosition="right"
      />

      <TooltipPopover
        triggerContent={
          <InfoOutlineIcon withHoverEffect size={vars.iconSize.s} />
        }
        title={details}
        contentVariant="dark"
      >
        {details}
      </TooltipPopover>
    </Stack>
  );
};
