import { ChangeEvent, useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { useContent } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useInputPriceFocusContext } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { useUserCanSetPrice } from 'src/hooks/useUserHasListingPermissions';
import {
  CheckIcon,
  CrossIcon,
  IconsFill,
  ProcessingIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { InventoryViewMode, Listing, ListingQuery } from 'src/WebApiController';

import { TableCellDiv } from './ListingPriceCell.styled';
import {
  ListingPricingInput,
  ProceedsPriceField,
} from './ListingPriceForm.types';

type ListingProceedsFloorCellProps = {
  listing: Listing;
  currencyCode: string;
  field: ProceedsPriceField;
  isAllInPrice?: boolean;
  sellerFee?: number | null;
  disabled?: boolean;
  onFocus?: React.FocusEventHandler<HTMLInputElement> | undefined;
  viagVirtualId?: string;
};

export const ListingProceedsFloorCell: React.FC<
  ListingProceedsFloorCellProps
> = ({
  listing,
  currencyCode,
  sellerFee,
  field,
  isAllInPrice,
  onFocus,
  disabled,
  viagVirtualId,
}) => {
  const { getUiCurrency } = useLocalizationContext();
  const { setValue, watch, clearErrors, getFieldState, formState } =
    useFormContext<ListingPricingInput>();
  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(viagVirtualId);
  const isSelectionMode = listingSelection.isSingleGroupMultiSelect;

  const price = watch(field) ?? 0;

  const isSubmitting = watch('isSubmitting');
  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');
  const currentPageIndex = watch('currentPageIndex');

  const priceIsSubmitting =
    isSubmitting &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const uiCurrency = useMemo(
    () => getUiCurrency(currencyCode),
    [currencyCode, getUiCurrency]
  );
  const {
    pageSize,
    disablePagination,
    inputPriceFocusContext,
    setInputPriceFocusContext,
    disabledFocusContexts,
  } = useInputPriceFocusContext();

  const { filterQuery } = useFilterQueryContext<ListingQuery>();

  const fieldError = getFieldState(field, formState)?.error?.message;

  const priceHasChanged =
    formState.defaultValues?.[field] !== price &&
    price != null &&
    price > 0 &&
    !isSubmitting;

  // ListingTable don't always have these context wrappers - remember not to assume these are NOT NULL
  const findNextFocusIndex = useCallback(() => {
    // need to skip the disabled rows from disabledFocusContexts
    let newRowIndex = rowIndex + 1;
    let nextRowIndex =
      !disablePagination && pageSize && newRowIndex >= pageSize
        ? 0
        : newRowIndex;
    let nextPageIndex = disablePagination
      ? 0
      : nextRowIndex === 0
      ? currentPageIndex + 1
      : currentPageIndex;

    while (
      disabledFocusContexts?.some(
        (x) =>
          x?.currentPageIndex === nextPageIndex &&
          x?.rowIndex === nextRowIndex &&
          (x?.viagVirtualId === viagVirtualId ||
            filterQuery.viewMode === InventoryViewMode.FlattenedView)
      )
    ) {
      newRowIndex = nextRowIndex + 1;
      nextRowIndex =
        !disablePagination && pageSize && newRowIndex >= pageSize
          ? 0
          : newRowIndex;
      nextPageIndex = disablePagination
        ? 0
        : nextRowIndex === 0
        ? nextPageIndex + 1
        : nextPageIndex;
    }

    return { nextRowIndex, nextPageIndex };
  }, [
    currentPageIndex,
    disablePagination,
    disabledFocusContexts,
    filterQuery.viewMode,
    pageSize,
    rowIndex,
    viagVirtualId,
  ]);

  const inputIsFocus = useMemo(
    () =>
      (inputPriceFocusContext?.viagVirtualId === viagVirtualId ||
        filterQuery.viewMode === InventoryViewMode.FlattenedView) &&
      inputPriceFocusContext?.rowIndex === rowIndex &&
      inputPriceFocusContext?.priceField === field,
    [
      field,
      filterQuery.viewMode,
      inputPriceFocusContext?.viagVirtualId,
      inputPriceFocusContext?.priceField,
      inputPriceFocusContext?.rowIndex,
      rowIndex,
      viagVirtualId,
    ]
  );

  const saveMsg = useContent(ContentId.Save);
  const cancelMsg = useContent(ContentId.Cancel);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let newValue = parseFloat(e.target.value) ?? 0;

      if (Number.isNaN(newValue)) {
        newValue = 0;
      } else if (newValue > Infinity) {
        newValue = Number.MAX_VALUE;
      }

      if (newValue >= 0 && newValue <= Number.MAX_VALUE) {
        const sanitizedValue = isAllInPrice
          ? newValue * (1 - (sellerFee ?? 0))
          : newValue;
        setValue(field, Math.round(sanitizedValue * 100) / 100);
        clearErrors(field);
      }
    },
    [isAllInPrice, sellerFee, setValue, field, clearErrors]
  );

  const onBlurHandler = useCallback(() => {
    if (
      rowIndex !== undefined &&
      inputPriceFocusContext?.rowIndex === rowIndex
    ) {
      setInputPriceFocusContext(undefined);
    }
  }, [rowIndex, inputPriceFocusContext?.rowIndex, setInputPriceFocusContext]);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      if (fieldError) {
        return;
      }
      if (rowIndex !== undefined) {
        if (priceHasChanged) {
          e.stopPropagation();
          // Only submit if Enter is hit in the box that has changes
          setValue('isSubmitting', true);

          const { nextRowIndex, nextPageIndex } = findNextFocusIndex();
          setInputPriceFocusContext({
            viagVirtualId: viagVirtualId!,
            rowIndex: nextRowIndex,
            priceField: field,
            currentPageIndex: nextPageIndex,
          });
        } else {
          e.stopPropagation();

          const { nextRowIndex, nextPageIndex } = findNextFocusIndex();
          setInputPriceFocusContext({
            viagVirtualId: viagVirtualId!,
            rowIndex: nextRowIndex,
            priceField: field,
            currentPageIndex: nextPageIndex,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (inputIsFocus && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
      inputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [inputIsFocus]);

  const canSetPrice = useUserCanSetPrice(listing, false);

  const sanitizedPrice = useMemo(() => {
    if (isAllInPrice) {
      const allInPrice = price / (1 - (sellerFee ?? 0));
      return Math.round(allInPrice * 100) / 100;
    }
    return price;
  }, [isAllInPrice, price, sellerFee]);

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <PosFormField
        errors={disabled ? undefined : fieldError}
        showErrorsInline
        style={{ width: 'fit-content' }}
      >
        <PosCurrencyField
          rootProps={{
            variant: 'small',
            state: getTextFieldState(fieldError),
            disabled:
              disabled ||
              priceIsSubmitting ||
              isSelectionMode ||
              isSubmittingPricingSettings,
          }}
          uiCurrency={uiCurrency}
          alignment="right"
          ref={inputRef}
          disabled={
            disabled ||
            priceIsSubmitting ||
            isSubmittingPricingSettings ||
            isSelectionMode ||
            !canSetPrice
          }
          postfixDisplay={
            !disabled && !fieldError && priceHasChanged ? (
              <>
                <CheckIcon
                  title={saveMsg}
                  withHoverEffect
                  size={vars.iconSize.s}
                  fill={IconsFill.textSuccess}
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue('isSubmitting', true);
                  }}
                />
                <CrossIcon
                  title={cancelMsg}
                  withHoverEffect
                  size={vars.iconSize.s}
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(field, formState.defaultValues?.[field] ?? null);
                  }}
                />
              </>
            ) : priceIsSubmitting ? (
              <div className="operation-button">
                <RotatingWrapper>
                  <ProcessingIcon size={vars.iconSize.s} />
                </RotatingWrapper>
              </div>
            ) : undefined
          }
          value={sanitizedPrice}
          onBlur={onBlurHandler}
          onChange={onChangeHandler}
          onKeyDown={handleKeyDown}
          onFocus={onFocus}
          onClick={() => inputRef?.current?.select()}
        />
      </PosFormField>
    </TableCellDiv>
  );
};
