import { forwardRef, RefObject } from 'react';
import { Components } from 'react-virtuoso';

import * as styles from '../Table.css';

export const PosGroupedVirtuosoGroup: Components['Group'] = forwardRef(
  function GroupedVirtuosoGroup({ context, children, ...props }, ref) {
    return (
      <div ref={ref as RefObject<HTMLDivElement>} {...props}>
        <table className={styles.table}>
          <tbody>
            <tr>{children}</tr>
          </tbody>
        </table>
      </div>
    );
  }
);
