import { EventsFormulaFields } from 'src/tables/EventsTable/configs/EventsTableColumnsConfig';
import { ListingDetailedMetrics, UiMoney } from 'src/WebApiController';

import { ColumnPersonalizationFlags } from '../columnUtils.types';
import { EventsTableColumnId } from './eventsColumnUtils.types';

export const EVENTS_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG: Partial<ColumnPersonalizationFlags> =
  {
    isHiddenByDefault: true,
    isNumeric: true,
  };

export const FORMULA_FIELD_TO_COLUMN_ID: Record<
  keyof EventsFormulaFields,
  EventsTableColumnId | undefined
> = {
  ['actualStr']: EventsTableColumnId.ActualSTR,
  ['expectedStr']: undefined,
  ['liftPercentage']: EventsTableColumnId.LiftPercentage,
  ['listedAtp']: EventsTableColumnId.ListedAtp,
  ['revenue']: EventsTableColumnId.Revenue,
  ['totalAtp']: EventsTableColumnId.ATP,
  ['totalCost']: undefined,
  ['totalGrossProfit']: EventsTableColumnId.Profit,
  ['totalListPrice']: undefined,
  ['totalListedQuantity']: EventsTableColumnId.ListedQty,
  ['totalListedValue']: undefined,
  ['totalNetProceeds']: undefined,
  ['totalSoldCost']: EventsTableColumnId.SoldCost,
  ['totalSoldQuantity']: EventsTableColumnId.SoldQty,
  ['totalTicketQuantity']: EventsTableColumnId.TotalQty,
  ['totalUnsoldListPrice']: undefined,
  ['totalUnsoldQuantity']: EventsTableColumnId.UnsoldQty,
};

export const LISTING_METRIC_TEST_DATA: ListingDetailedMetrics = {
  str: 1,
  liftPerc: 1,
  listedATP: { amt: 1 } as UiMoney,
  rvn: { amt: 1 } as UiMoney,
  atp: { amt: 1 } as UiMoney,
  ttlCst: { amt: 1 } as UiMoney,
  pandL: { amt: 1 } as UiMoney,
  ttlListPrc: { amt: 1 } as UiMoney,
  listQty: 1,
  netProcs: { amt: 1 } as UiMoney,
  soldCst: { amt: 1 } as UiMoney,
  soldQty: 1,
  tktQty: 1,
  ttlUnsoldListPrc: { amt: 1 } as UiMoney,
  unsoldQty: 1,
} as ListingDetailedMetrics;
