import { debounce, isEmpty, uniqBy } from 'lodash-es';
import React, {
  ReactNode,
  Ref,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { vars } from 'src/core/themes';
import { TooltipPopover } from 'src/core/ui';
import { mergeRefs } from 'src/core/utils';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  IconsFill,
  InfoSolidIcon,
  MinusIcon,
  PlusIcon,
  ResetIcon,
} from 'src/svgs/Viagogo';
import { Feature, FocalPointInfo, SectionInfo } from 'src/WebApiController';
import svgPanZoom from 'svg-pan-zoom';

import { ColorBand, ColorBandProps } from './shared/components/ColorBand';
import { addFocalPoint } from './shared/utils/addFocalPoint';
import { VenueMapMouseEvent } from './shared/utils/VenueMapMouseEvent';
import * as styles from './VenueMap.css';
import {
  IconParent,
  StyledBorderDiv,
  StyledDiv,
  StyledHoverDiv,
  StyledIconParentVariant,
  StyledResetVariant,
  VenueMapContentContainer,
} from './VenueMap.styled';
import { VenueMapColorSelector, VenueMapColorStyles } from './VenueMap.types';
import {
  crossesPoints,
  DrawingPoint,
  isMissiongSectionData,
  registerDrawLine,
  registerPanningListners,
} from './VenueMapContent.hooks';

const clickEvents = ['click'];

const MaxSimilarSectionsToGetForSelectedSections = 10;
const ZOOM_SCALE_SENSITIVITY = 0.2;

export type VenueMapContentProps = {
  selectedSections?: SectionInfo[];
  markedSectionIds?: number[];
  onSectionClicked?: (
    e: MouseEvent,
    selectedSection: SectionInfo,
    surroundingSections?: SectionInfo[]
  ) => void;
  onSectionHovered?: (hoveredSection: SectionInfo) => ReactNode;
  setSelectedSections?: (sections: SectionInfo[]) => void;
  staticInfoContent?: React.ReactNode;
  infoIconContent?: React.ReactNode;
  availableSections: SectionInfo[];
  svg: string | TrustedHTML;
  isZoomEnabled: boolean;
  svgMapUrl: string; // what to use this for?
  venueConfigId: number; // what to use this for?
  showDefaultMapColors?: boolean;
  colorBandProps?: ColorBandProps;
  /* Callback to get color for a section/ticketClass pair to provide dynamic
   * colorization of sections in the map
   */
  focalPoint?: FocalPointInfo | null;
  getColor?: (info: VenueMapColorSelector) => VenueMapColorStyles | void;
  isHeatMap?: boolean;
  statsContent?: React.ReactNode;
};

const colors = {
  defaultBackground: vars.color.backgroundPrimary,
  defaultForeground: vars.color.textPrimary,
  defaultHoverBackground: vars.color.backgroundPrimaryHover,
  defaultHoverForeground: vars.color.textPrimaryHover,
  selectedBackground: vars.color.backgroundHighlight,
  selectedForeground: vars.color.textBrand,
  selectedHoverBackground: vars.color.backgroundHighlightHover,
  selectedHoverForeground: vars.color.textBrandHover,
};

const VenueMapContent = React.forwardRef(
  (
    {
      selectedSections,
      markedSectionIds,
      onSectionClicked,
      onSectionHovered,
      setSelectedSections,
      staticInfoContent,
      infoIconContent,
      availableSections,
      svg,
      focalPoint,
      isZoomEnabled = true,
      showDefaultMapColors,
      colorBandProps,
      getColor,
      isHeatMap = false,
      statsContent,
    }: VenueMapContentProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const localRef = useRef<HTMLDivElement>(null);

    const isPanning = useRef(false);

    const [hoveredContent, setHoverContent] = useState<ReactNode>();
    const [zoomFlag, setZoomFlag] = useState(false);
    const [blurMap, setBlurMap] = useState(true);

    // Hide map if there is a color override, and show it after color is initialized
    const [hideMap, setHideMap] = useState(!!getColor || showDefaultMapColors);

    const hasVenueMapRectSelectFeature = useUserHasFeature(
      Feature.VenueMapRectSelect
    );

    const sectionIdsMissingRowOrder = useMemo(
      () => availableSections.filter(isMissiongSectionData).map(({ id }) => id),
      [availableSections]
    );

    useEffect(() => {
      if (availableSections.length) setBlurMap(false);
    }, [availableSections.length]);

    useEffect(() => {
      if (!localRef.current) return;

      const svgElement = localRef.current.getElementsByTagName('svg')[0];
      addFocalPoint(svgElement, focalPoint);

      const sprites = (
        [
          ...svgElement.querySelectorAll('g[sprite-identifier] path'),
        ] as HTMLElement[]
      ).filter((p) => getSpriteIdentifier(p));

      if (isEmpty(sprites)) return;

      const venueMapSvg = svgPanZoom(svgElement, {
        zoomScaleSensitivity: ZOOM_SCALE_SENSITIVITY,
        zoomEnabled: isZoomEnabled,
        dblClickZoomEnabled: false,
        onZoom: () => setZoomFlag(true),
        onPan: () => setZoomFlag(true),
      });

      const onResize = () => {
        if (localRef.current) {
          svgElement.setAttribute(
            'width',
            localRef.current.clientWidth.toString()
          );
          svgElement.setAttribute(
            'height',
            localRef.current.clientHeight.toString()
          );
          venueMapSvg.resize(); // update SVG cached size and controls positions
          venueMapSvg.fit();
          venueMapSvg.center();
        }
      };

      const debounceOnResize = debounce(onResize, 200);

      const mouseEnter = ({ target }: MouseEvent) => {
        let htmlTarget = target as HTMLElement;
        if (htmlTarget.localName === 'text') {
          htmlTarget = Array.from(
            htmlTarget.parentElement?.children ?? []
          ).filter(
            (child) =>
              child.getAttribute('eid') === htmlTarget.getAttribute('eid') &&
              child.tagName === 'path'
          )[0] as HTMLElement;
        }

        const selectedSectionId = parseInt(getSpriteIdentifier(htmlTarget)!);

        const sectionIndex = availableSections?.findIndex(
          (s) => s.id === selectedSectionId
        );
        if (sectionIndex >= 0) {
          const section = availableSections[sectionIndex];
          if (section) {
            const hoveredContent = onSectionHovered?.(section);
            setHoverContent(hoveredContent);
          }
        }

        htmlTarget.setAttribute('fill-opacity', '0.25');
      };

      const mouseLeave = ({ target }: MouseEvent) => {
        let htmlTarget = target as HTMLElement;
        if (htmlTarget.localName === 'text') {
          htmlTarget = Array.from(htmlTarget.parentElement!.children).filter(
            (child) =>
              child.getAttribute('eid') === htmlTarget.getAttribute('eid') &&
              child.tagName === 'path'
          )[0] as HTMLElement;
        }

        htmlTarget.setAttribute('fill-opacity', '1');
      };

      const setSelectedHandler = (e: Event) => {
        if (isPanning.current) {
          return;
        }
        const mouseEvent = e as MouseEvent;

        let htmlTarget = e.target as HTMLElement;
        if (htmlTarget.localName === 'text') {
          htmlTarget = Array.from(htmlTarget.parentElement!.children).filter(
            (child) =>
              child.getAttribute('eid') === htmlTarget.getAttribute('eid') &&
              child.tagName === 'path'
          )[0] as HTMLElement;
        }
        const selectedTicketClassId = parseInt(
          htmlTarget.getAttribute('eid') ?? '0'
        );

        const selectedSectionId = parseInt(getSpriteIdentifier(htmlTarget)!);

        const selectedSection = availableSections.find(
          (x) =>
            x.id === selectedSectionId &&
            (selectedTicketClassId === 0 ||
              x.specRow?.ticketClassId === selectedTicketClassId ||
              !x.rows?.length ||
              !selectedTicketClassId ||
              x.rows?.find((r) => r.tktClass?.id === selectedTicketClassId))
        );

        const selectedSectionIndex = filteredSprites.findIndex(
          (g) =>
            g.sectionId === selectedSectionId &&
            (selectedTicketClassId === 0 ||
              g.ticketClassId === selectedTicketClassId)
        );

        const lookDistance = MaxSimilarSectionsToGetForSelectedSections / 2;

        // Get the 10 sections surrounding the clicked section
        const surroundingSections = [];
        for (
          let i = Math.max(selectedSectionIndex - lookDistance, 0);
          i <=
          Math.min(
            selectedSectionIndex + lookDistance,
            filteredSprites.length - 1
          );
          i++
        ) {
          const fs = filteredSprites[i];
          const s = availableSections.find(
            (s) =>
              s.id === fs.sectionId &&
              (s.specRow?.ticketClassId === fs.ticketClassId ||
                s.rows.some((r) => r.tktClass?.id === fs.ticketClassId) ||
                fs.ticketClassId === 0)
          );

          if (s) {
            if (surroundingSections.findIndex((ss) => ss.id === s.id) < 0) {
              surroundingSections.push(s);
            }
          }
        }

        selectedSection &&
          onSectionClicked?.(mouseEvent, selectedSection, surroundingSections);
      };

      sprites.forEach((path) => {
        const spriteIdentifier = getSpriteIdentifier(path);

        const sectionId = parseInt(spriteIdentifier!);
        const ticketClassId = parseInt(path.getAttribute('eid') ?? '0');

        const missingRowOrder = sectionIdsMissingRowOrder.includes(sectionId);

        const textSiblings = (Array.from(path.parentElement!.children).filter(
          (child) =>
            child.getAttribute('eid') === path.getAttribute('eid') &&
            child.tagName === 'text'
        ) || []) as HTMLElement[];

        const sectionName =
          textSiblings
            .map((sibling) => sibling.textContent)
            .find((text) => !!text) ?? undefined;

        const isSelected =
          selectedSections?.some(({ id }) => id === sectionId) ?? false;
        const isMarked = markedSectionIds?.some((id) => id === sectionId);

        const knownSection = availableSections.find((x) => x.id === sectionId);
        const knownRow =
          knownSection?.rows?.find((r) => r.tktClass?.id === ticketClassId) ??
          knownSection?.specRow;

        const { fill, stroke, textColor, strokeWidth } = getColor?.({
          sectionId,
          rowId: knownRow?.id,
          sectionName,
          ticketClassId,
          isSelected,
          isMarked,
        }) ?? {
          fill: '',
          stroke: '',
        };

        if (fill && stroke) {
          // If we have dynamic coloring from the function, use it and quit
          path.setAttribute('fill', fill);
          path.setAttribute('fill-opacity', '1');
          path.setAttribute('stroke', stroke);
        } else {
          const selectedSection = selectedSections?.find(
            (x) => x.id === sectionId
          );
          if (selectedSection) {
            path.setAttribute('fill', colors.selectedBackground);
            path.setAttribute('fill-opacity', '1');
            path.setAttribute('stroke', colors.selectedForeground);
          } else {
            if (knownSection) {
              path.setAttribute(
                'fill',
                showDefaultMapColors
                  ? knownRow?.tktClass?.color ?? colors.defaultBackground
                  : colors.defaultBackground
              );
              path.setAttribute('fill-opacity', '1');
              path.setAttribute('stroke', colors.defaultForeground);
            } else {
              path.setAttribute('fill', colors.defaultBackground);
              path.setAttribute('fill-opacity', '1');
              path.setAttribute('stroke', colors.defaultForeground);
            }
          }
        }

        path.style.cursor = 'pointer';
        if (!isEmpty(textSiblings)) {
          textSiblings.forEach((sibling) => {
            sibling.style.cursor = 'pointer';
            if (isSelected) {
              sibling.setAttribute('fill', vars.color.textBrand);
            } else if (textColor) {
              sibling.setAttribute('fill', textColor);
            } else {
              sibling.setAttribute('fill', 'black');
            }
          });
        }

        if (isSelected) {
          path.setAttribute('stroke', vars.color.textBrand);
          path.setAttribute('stroke-width', strokeWidth ?? '10px');
        } else if (isMarked) {
          path.setAttribute('stroke', vars.color.textBrand);
          path.setAttribute('stroke-width', strokeWidth ?? '6px');
        } else {
          path.setAttribute('stroke-width', strokeWidth ?? '2px');
        }

        if (missingRowOrder && isHeatMap) {
          textSiblings.forEach((sibling) => {
            if (sibling.textContent && sibling.textContent.slice(-1) != '!') {
              sibling.textContent = `${sibling.textContent}!`;
            }
          });
          path.setAttribute('stroke', vars.color.borderWarning);
          path.setAttribute('stroke-width', '4px');
        }
      });

      const filteredSprites = sprites.map((element) => {
        const ticketClassId = parseInt(element.getAttribute('eid') ?? '0');
        const sectionId = parseInt(getSpriteIdentifier(element)!);

        const textSiblings = (Array.from(
          element.parentElement!.children
        ).filter(
          (child) =>
            child.getAttribute('eid') === element.getAttribute('eid') &&
            child.tagName === 'text'
        ) || []) as HTMLElement[];

        const sectionName =
          textSiblings
            .map((sibling) => sibling.textContent)
            .find((text) => !!text) ?? undefined;

        return {
          element,
          textSiblings,
          sectionId,
          ticketClassId,
          sectionName,
          mouseEnter: (e: MouseEvent) => mouseEnter(e),
          mouseLeave: (e: MouseEvent) => mouseLeave(e),
          clickFunc: setSelectedHandler,
        };
      });

      const filteredSpritesByTicketClass = sprites
        .filter((element) => {
          const ticketClassId = parseInt(element.getAttribute('eid') ?? '0');
          return (
            availableSections.filter(
              (x) =>
                x.rows?.find((r) => r.tktClass?.id === ticketClassId) &&
                !filteredSprites.find((e) => e.element === element)
            ).length === 1
          );
        })
        .map((element) => {
          return {
            element,
            mouseEnter: (e: MouseEvent) => mouseEnter(e),
            mouseLeave: (e: MouseEvent) => mouseLeave(e),
          };
        });

      // add eventListeners
      const onAddingEventListeners = () => {
        filteredSprites.forEach(
          ({ element, textSiblings, mouseEnter, mouseLeave, clickFunc }) => {
            element.addEventListener('mouseenter', mouseEnter);
            element.addEventListener('mouseleave', mouseLeave);
            textSiblings.forEach((sibling) =>
              sibling.addEventListener('mouseenter', mouseEnter)
            );
            textSiblings.forEach((sibling) =>
              sibling.addEventListener('mouseleave', mouseLeave)
            );
            clickEvents.forEach((e) => {
              element.addEventListener(e, clickFunc);
              textSiblings.forEach((sibling) =>
                sibling.addEventListener(e, clickFunc)
              );
            });
          }
        );
        filteredSpritesByTicketClass.forEach(
          ({ element, mouseEnter, mouseLeave }) => {
            element.addEventListener('mouseenter', mouseEnter);
            element.addEventListener('mouseleave', mouseLeave);
          }
        );
        window.addEventListener('resize', debounceOnResize);
      };

      // remove eventListeners
      const onRemoveEventListeners = () => {
        filteredSprites.forEach(
          ({ element, textSiblings, mouseEnter, mouseLeave, clickFunc }) => {
            element.removeEventListener('mouseenter', mouseEnter);
            element.removeEventListener('mouseleave', mouseLeave);
            textSiblings.forEach((sibling) =>
              sibling.removeEventListener('mouseenter', mouseEnter)
            );
            textSiblings.forEach((sibling) =>
              sibling.removeEventListener('mouseleave', mouseLeave)
            );
            clickEvents.forEach((e) => {
              element.removeEventListener(e, clickFunc);
              textSiblings.forEach((sibling) =>
                sibling.removeEventListener(e, clickFunc)
              );
            });
          }
        );
        filteredSpritesByTicketClass.forEach(
          ({ element, mouseEnter, mouseLeave }) => {
            element.removeEventListener('mouseenter', mouseEnter);
            element.removeEventListener('mouseleave', mouseLeave);
          }
        );
        window.removeEventListener('resize', debounceOnResize);
      };

      const onDoulbeClickDone = (points: DrawingPoint[]) => {
        onAddingEventListeners();
        const selectedIds = new Set();
        filteredSprites.forEach(({ element }) => {
          if (crossesPoints(points, element.getBoundingClientRect())) {
            selectedIds.add(parseInt(getSpriteIdentifier(element) ?? '-1'));
          }
        });
        const updatedSections = availableSections
          .filter(
            ({ id }) => selectedIds.has(id) || markedSectionIds?.includes(id)
          )
          .sort((a, b) => a.name?.localeCompare(b.name));

        if (!isEmpty(updatedSections)) {
          setSelectedSections?.(
            uniqBy(updatedSections.concat(selectedSections ?? []), 'id')
          );
        }
      };

      const deregisterDrawLine = registerDrawLine(
        localRef.current,
        onRemoveEventListeners,
        onDoulbeClickDone,
        hasVenueMapRectSelectFeature
      );

      const deregisterPanEvents = registerPanningListners(
        localRef.current,
        isPanning
      );

      onAddingEventListeners();

      // Show the map when color initialization is done when there is color override
      if (getColor || showDefaultMapColors) {
        setHideMap(false);
      }

      return () => {
        onRemoveEventListeners();
        deregisterDrawLine();
        deregisterPanEvents();
      };
    }, [
      availableSections,
      getColor,
      isZoomEnabled,
      onSectionClicked,
      onSectionHovered,
      selectedSections,
      markedSectionIds,
      zoomFlag,
      focalPoint,
      showDefaultMapColors,
      hasVenueMapRectSelectFeature,
      setSelectedSections,
      sectionIdsMissingRowOrder,
      isHeatMap,
    ]);

    const onZoom = (zoomIn = true) => {
      if (localRef.current) {
        const svgElement = localRef.current.getElementsByTagName('svg')[0];
        const venueMapSvg = svgPanZoom(svgElement, {
          fit: true,
          contain: true,
          zoomScaleSensitivity: ZOOM_SCALE_SENSITIVITY,
        });
        zoomIn ? venueMapSvg.zoomIn() : venueMapSvg.zoomOut();
        setZoomFlag(true);
      }
    };

    const onReset = () => {
      if (localRef.current) {
        const svgElement = localRef.current.getElementsByTagName('svg')[0];
        const venueMapSvg = svgPanZoom(svgElement, {
          fit: true,
          contain: true,
          zoomScaleSensitivity: ZOOM_SCALE_SENSITIVITY,
        });

        venueMapSvg.resize(); // update SVG cached size and controls positions
        venueMapSvg.fit();
        venueMapSvg.center();
        setZoomFlag(false);
        setSelectedSections?.([]);
      }
    };

    return (
      <VenueMapContentContainer>
        <div style={{ position: 'relative', flexGrow: 1, overflow: 'hidden' }}>
          <StyledDiv
            id={'event-detail-map'}
            ref={mergeRefs(localRef, ref)}
            dangerouslySetInnerHTML={{ __html: svg }}
            setTransitionAnimation={true}
            blurMap={blurMap}
            hideMap={hideMap}
          />

          <StyledResetVariant showFiltersOnTop={false}>
            {isZoomEnabled && (
              <>
                {(Boolean(selectedSections?.length) || zoomFlag) && (
                  <div className={styles.resetWrapper}>
                    <StyledIconParentVariant onClick={onReset}>
                      <ResetIcon size={vars.iconSize.s} />
                    </StyledIconParentVariant>
                  </div>
                )}
                <StyledBorderDiv>
                  <StyledHoverDiv>
                    <IconParent onClick={() => onZoom(true)}>
                      <PlusIcon size={vars.iconSize.s} />
                    </IconParent>
                  </StyledHoverDiv>
                  <StyledHoverDiv>
                    <IconParent onClick={() => onZoom(false)}>
                      <MinusIcon size={vars.iconSize.s} />
                    </IconParent>
                  </StyledHoverDiv>
                </StyledBorderDiv>
              </>
            )}
            {infoIconContent && (
              <TooltipPopover
                variant="link"
                contentVariant="dark"
                triggerContent={
                  <InfoSolidIcon withHoverEffect fill={IconsFill.textBrand} />
                }
              >
                {infoIconContent}
              </TooltipPopover>
            )}
          </StyledResetVariant>

          {!statsContent && staticInfoContent && (
            <div className={styles.infoStaticBox}>{staticInfoContent}</div>
          )}
          {!statsContent && hoveredContent && (
            <div className={styles.infoHoverBox}>{hoveredContent}</div>
          )}
          {statsContent}
        </div>
        {colorBandProps && (
          <div className={styles.colorBandContainer}>
            <ColorBand {...colorBandProps} />
          </div>
        )}
      </VenueMapContentContainer>
    );
  }
);

const getSpriteIdentifier = (path: HTMLElement | null): string | null => {
  if (!path || !path.parentElement || path.parentElement.localName !== 'g') {
    return null;
  }

  const spriteIdentifier =
    path!.parentElement!.getAttribute('sprite-identifier');

  if (!spriteIdentifier) {
    return getSpriteIdentifier(path!.parentElement);
  }

  return spriteIdentifier!.slice(1);
};

export const hoverFuncSeatType = (ticketClasses: number[], type: string) => {
  const event = new MouseEvent(type, {
    view: window,
    bubbles: true,
    cancelable: true,
  }) as VenueMapMouseEvent;
  ticketClasses.forEach((t: number) => {
    const paths = Array.from(
      document.querySelectorAll(`#event-detail-map svg g path[eid="${t}"]`)
    );
    event.sectionIdAvailable = false;
    paths.forEach((path) => {
      path.dispatchEvent(event);
    });
  });
};

VenueMapContent.displayName = 'VenueMapContent';
export default VenueMapContent;
