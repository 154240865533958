import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const SameEventModeIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 40 40">
      <path
        d="M23 31L26 34L33 27"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M13 5V9"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M27 5V9"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
      <path
        d="M35 15H5"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M17 33H8C6.343 33 5 31.657 5 30V12C5 10.343 6.343 9 8 9H32C33.657 9 35 10.343 35 12V21"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </SvgWrapper>
  );
};
