import { format } from 'date-fns';
import { ReactNode } from 'react';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { ExpandCollapsableContentButton } from 'src/components/common/CollapsableContent/ExpandCollapsableContentButton';
import { SummaryTile } from 'src/components/common/SummaryTile';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Stack } from 'src/core/ui';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { ActionOutboxEntityType } from 'src/WebApiController';

import {
  eventDateWrapper,
  eventInfoWrapper,
  metricValueWrapper,
  rootWrapper,
  scrollDownButton,
} from './InventoryEventPageHeaderCondence.css';

interface InventoryEventPageHeaderCondenseProps {
  eventDate?: Date;
  eventName: ReactNode;
  eventVenue?: ReactNode;
  metricValue?: string;
  actions?: ReactNode;
}

const formatDate = (date?: Date): string | undefined => {
  if (!date) {
    return;
  }
  const currentLocale = getLocaleFromLanguageOrCurrent();
  const month = date.toLocaleString(currentLocale.code, { month: 'numeric' });
  const day = date.toLocaleString(currentLocale.code, { day: '2-digit' });
  const dayOfWeek = date.toLocaleString(currentLocale.code, {
    weekday: 'short',
  });
  return `${month}/${day} - ${dayOfWeek} - ${format(date, 'h aaa')}`;
};

export const InventoryEventPageHeaderCondense = ({
  eventName,
  eventDate,
  eventVenue,
  metricValue,
  actions,
}: InventoryEventPageHeaderCondenseProps) => {
  const { setIsCollapsedView } = useCollapsableViewContext();
  return (
    <div className={rootWrapper}>
      <ExpandCollapsableContentButton
        className={scrollDownButton}
        onClick={() => setIsCollapsedView(false)}
      />
      <SummaryTile.Root>
        <div className={eventDateWrapper}>{formatDate(eventDate)}</div>
        <Stack justifyContent="spaceBetween" className={eventInfoWrapper}>
          <Stack gap="m">
            {eventName}
            {eventVenue}
          </Stack>
          <Stack gap="m">
            <BulkEditStatusPopover
              entityType={ActionOutboxEntityType.Listing}
            />
            {actions}
            {metricValue && (
              <div className={metricValueWrapper}>{metricValue}</div>
            )}
          </Stack>
        </Stack>
      </SummaryTile.Root>
    </div>
  );
};
