import { ComponentProps, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSelect } from 'src/core/POS/PosSelect';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './AddToGroupDialog.css';

export type AddToGroupDialogProps = ComponentProps<typeof RSModal> & {
  groupOptions: Record<string, string>;
  onOkay: (groupId: string) => void;
  onCancel: () => void;
};

export function AddToGroupDialog({
  groupOptions,
  onOkay,
  onCancel,
  ...rest
}: AddToGroupDialogProps) {
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(
    Object.keys(groupOptions)[0] ?? undefined
  );
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <>
      <GenericDialog
        {...rest}
        size="m"
        header={<Content id={ContentId.AddToGroup} />}
        footer={
          <>
            {isSubmitting ? (
              <PosSpinner />
            ) : (
              <>
                <Button className={styles.cancelButton} onClick={onCancel}>
                  <Content id={ContentId.Cancel} />
                </Button>
                <Button
                  onClick={() => {
                    setIsSubmitting(true);
                    onOkay(selectedGroupId ?? '');
                  }}
                >
                  <Content id={ContentId.Preview} />
                </Button>
              </>
            )}
          </>
        }
        onCancel={onCancel}
      >
        <Stack direction="row" alignItems="center" gap="l">
          <Content id={ContentId.ChangeGroup} />

          <PosSelect
            onChange={(groupId) => setSelectedGroupId(groupId)}
            value={selectedGroupId}
            searchable
            disabled={isSubmitting}
            valueOptionsContent={groupOptions}
            onClick={(e) => e.stopPropagation()}
          />
        </Stack>
      </GenericDialog>
    </>
  );
}
