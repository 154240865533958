import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { ContentId } from 'src/utils/constants/contentId';
import { SomethingWentWrong } from 'src/views';
import {
  ApiException,
  AutoPoClient,
  ValueTupleOfStringAndString,
} from 'src/WebApiController';

import { EmailRenderer } from './EmailRenderer';

export const EmailBody = ({ inboundEmailId }: { inboundEmailId: number }) => {
  const { trackError } = useErrorBoundaryContext();

  const { activeAccountWebClientConfig } = useAppContext();
  const inboundEmailBodyResult = useQuery<
    ValueTupleOfStringAndString | null,
    ApiException
  >({
    queryKey: [
      'AutoPoClient.getInboundEmailBody',
      activeAccountWebClientConfig.activeAccountId,
      inboundEmailId,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      return new AutoPoClient(activeAccountWebClientConfig).getInboundEmailBody(
        inboundEmailId
      );
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    networkMode: 'offlineFirst',
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('AutoPoClient.getInboundEmailBody', error, {
          inboundEmailId,
        });
      },
    },
  });

  if (inboundEmailBodyResult.isLoading) {
    if (inboundEmailBodyResult.isPaused) {
      return (
        <SomethingWentWrong
          header={<Content id={ContentId.Error_BadGatewayError_Header} />}
          message={
            <Content id={ContentId.Error_BadGatewayError_GenericMessage} />
          }
        />
      );
    } else {
      return <PosSpinner />;
    }
  }

  if (!inboundEmailBodyResult.data) {
    return null;
  }
  return (
    <EmailRenderer
      htmlBody={inboundEmailBodyResult.data.item1}
      textBody={inboundEmailBodyResult.data.item2}
    />
  );
};
