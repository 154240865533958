import { ComponentProps, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { CurrencyFilterSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { FieldWrapper } from 'src/modals/common';
import { ChevronRightIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { formatCurrency } from 'src/utils/numberFormatter';
import { PurchasePayment, UiMoney } from 'src/WebApiController';

import * as styles from './ConvertCurrencyDialog.css';

export type ConvertCurrencyDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onSave: (formValues: ConvertCurrencyForm) => void;
  onCancel: () => void;
  payment: PurchasePayment;
  currentUserId: string;
};

export type ConvertCurrencyForm = Pick<
  PurchasePayment,
  | 'convertedPaymentAmount'
  | 'convertedCurrencyCode'
  | 'conversionRate'
  | 'conversionDate'
  | 'conversionUserId'
>;

const defaultCurrentCode = 'USD';

export const ConvertCurrencyDialog = ({
  onCancel,
  onSave,
  payment,
  currentUserId,
  ...dialogProps
}: ConvertCurrencyDialogProps) => {
  const fxText = useContent(ContentId.FX);
  const requiredMsg = useContent(ContentId.Required);
  const convertedPaymentAmountMustBeGreaterThanZeroMsg = useContent(
    ContentId.ConvertedPaymentAmountMustBeGreaterThanZero
  );
  const conversionRateMustBeGreaterThanZeroMsg = useContent(
    ContentId.ConversionRateMustBeGreaterThanZero
  );

  const { getUiCurrency } = useLocalizationContext();

  const {
    register,
    setValue,
    getValues,
    watch,
    formState,
    handleSubmit,
    trigger,
    reset,
  } = useForm<ConvertCurrencyForm>({
    mode: 'all',
    defaultValues: {
      convertedCurrencyCode:
        payment.convertedPaymentAmount?.currency ??
        payment.convertedCurrencyCode ??
        defaultCurrentCode,
      conversionRate: payment.conversionRate,
      convertedPaymentAmount: payment.convertedPaymentAmount,
      conversionDate: new Date().toISOString(),
      conversionUserId: currentUserId,
    },
  });

  useEffect(() => {
    reset({
      convertedCurrencyCode:
        payment.convertedPaymentAmount?.currency ??
        payment.convertedCurrencyCode ??
        defaultCurrentCode,
      conversionRate: payment.conversionRate,
      convertedPaymentAmount: payment.convertedPaymentAmount,
      conversionDate: new Date().toISOString(),
      conversionUserId: currentUserId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  const convertedCurrencyCode =
    watch('convertedCurrencyCode') ?? defaultCurrentCode;
  const { currency, disp, amt, dec } = payment.paymentAmount;

  const onSubmit = useCallback(async () => {
    await trigger();
    const hasErrors = Object.keys(formState.errors).length > 0;
    if (hasErrors) {
      return;
    }

    onSave(getValues());
  }, [formState.errors, getValues, onSave, trigger]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <GenericDialog
        {...dialogProps}
        size="m"
        header={<Content id={ContentId.ConvertCurrency} />}
        footer={
          <Button onClick={onSubmit}>
            <Content id={ContentId.Apply} />
          </Button>
        }
        onCancel={onCancel}
      >
        <Stack gap="m">
          <FieldWrapper>
            <PosFormField>
              <PosTextField
                value={disp || formatCurrency(amt, currency, dec)}
                disabled={true}
                postfixDisplay={currency}
              />
            </PosFormField>
          </FieldWrapper>

          <div>
            <ChevronRightIcon
              className={styles.chevronIcon}
              size={vars.iconSize.s}
            />
          </div>

          <FieldWrapper>
            <PosFormField
              errors={formState.errors.convertedPaymentAmount?.message}
            >
              <PosTextField
                {...register('convertedPaymentAmount.amt', {
                  validate: {
                    required: (value: number | null) => {
                      const v = value ? String(value) : '';
                      return !v.length ? requiredMsg : undefined;
                    },
                    minValue: (value: number | null) => {
                      return value && value <= 0
                        ? convertedPaymentAmountMustBeGreaterThanZeroMsg
                        : undefined;
                    },
                  },
                  setValueAs(convertedAmount): number | null {
                    if (!convertedAmount || isNaN(Number(convertedAmount))) {
                      return null;
                    }

                    return Number(convertedAmount);
                  },
                  onChange() {
                    const convertedAmount =
                      getValues().convertedPaymentAmount?.amt;
                    if (
                      formState.errors['convertedPaymentAmount'] ||
                      !convertedAmount
                    ) {
                      return;
                    }

                    const uiCurrency = getUiCurrency(convertedCurrencyCode);
                    setValue('convertedPaymentAmount', {
                      currency: convertedCurrencyCode,
                      dec: uiCurrency.dec,
                      amt: Number(convertedAmount),
                      disp: formatCurrency(
                        Number(convertedAmount),
                        uiCurrency.code,
                        uiCurrency.dec
                      ),
                    });

                    const conversionRate = Number(
                      (convertedAmount / payment.paymentAmount.amt).toFixed(4)
                    );
                    if (Number.isFinite(conversionRate)) {
                      setValue('conversionRate', conversionRate, {
                        shouldValidate: true,
                      });
                    }
                  },
                })}
                type="number"
                prefixDisplay={getUiCurrency(convertedCurrencyCode).sym}
                postfixDisplay={
                  <CurrencyFilterSelector
                    value={convertedCurrencyCode}
                    className={styles.currencySelector}
                    onChange={(currencyCode: string) => {
                      setValue('convertedCurrencyCode', currencyCode, {
                        shouldValidate: true,
                      });
                    }}
                  />
                }
              />
            </PosFormField>
          </FieldWrapper>

          <FieldWrapper width="75%" addFlexGrow={true}>
            <PosFormField errors={formState.errors.conversionRate?.message}>
              <PosTextField
                postfixDisplay={fxText}
                type="number"
                {...register('conversionRate', {
                  validate: {
                    required: (value) => {
                      return !value ? requiredMsg : undefined;
                    },
                    minValue: (value: number | null) => {
                      return !value || value <= 0
                        ? conversionRateMustBeGreaterThanZeroMsg
                        : undefined;
                    },
                  },
                  onChange() {
                    const conversionRate = getValues().conversionRate;
                    if (formState.errors['conversionRate'] || !conversionRate) {
                      return;
                    }

                    const convertedAmountAmount =
                      payment.paymentAmount.amt * conversionRate;

                    if (Number.isFinite(convertedAmountAmount)) {
                      const uiCurrency = getUiCurrency(convertedCurrencyCode);

                      const convertedAmount = {
                        currency: convertedCurrencyCode,
                        amt: Number(convertedAmountAmount),
                        disp: formatCurrency(
                          convertedAmountAmount,
                          uiCurrency.code,
                          uiCurrency.dec
                        ),
                      };

                      setValue(
                        'convertedPaymentAmount',
                        convertedAmount as UiMoney,
                        {
                          shouldValidate: true,
                        }
                      );
                    }
                  },
                  valueAsNumber: true,
                })}
              />
            </PosFormField>
          </FieldWrapper>
        </Stack>
      </GenericDialog>
    </form>
  );
};
