import { ReactNode } from 'react';
import { EventInfo } from 'src/components/Events/EventInfo';
import { useContent } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { ContentId } from 'src/utils/constants/contentId';
import { getFormattedEventName } from 'src/utils/eventWithDataUtils';
import { getFormattedLocation } from 'src/utils/format';
import { Event, Performer, Venue } from 'src/WebApiController';

import { summaryWrapper } from './Summary.css';
import {
  SummaryColumn,
  SummaryDetailsSection,
  SummaryRow,
} from './Summary.styled';

export const VenueConfigSummary = ({
  events,
  venues,
  performers,
  eventSelected,
  actions,
}: {
  events: Event[];
  venues?: Record<string, Venue>;
  performers?: Record<string, Performer>;
  eventSelected?: Event | null;
  actions?: ReactNode;
}) => {
  const atText = useContent(ContentId.at);
  const eventsText = useContent(ContentId.Events);
  const event = eventSelected ?? events[0];

  const { performer: cachedPerformer, venue: cachedVenue } =
    useGetEventFullInfo(event);

  let venue = cachedVenue;
  if (venues?.[event.viagVirtualId]) {
    venue = venues[event.viagVirtualId];
  }
  let performer = cachedPerformer;
  if (performers?.[event.viagVirtualId]) {
    performer = performers[event.viagVirtualId];
  }

  const [eventName, eventNameConnector, eventSubName] = getFormattedEventName({
    event,
    performer,
    venue,
    slimMode: false,
  });

  const venueLocation = venue && getFormattedLocation(venue);

  return (
    <div
      className={summaryWrapper}
      style={{ minHeight: '137px', alignItems: 'flex-start' }}
    >
      <SummaryDetailsSection>
        <SummaryRow>
          <SummaryColumn>
            <Stack gap="l" style={{ alignItems: 'center' }}>
              <EventInfo
                eventName={performer?.name ?? events[0].name}
                eventNameConnector={venue?.name ? atText : ''}
                eventSubName={venue?.name}
              />
              {` (${events.length} ${eventsText})`}
            </Stack>
          </SummaryColumn>
          {actions}
        </SummaryRow>
        {eventSelected && (
          <SummaryRow>
            <EventInfo
              eventName={eventName}
              eventNameConnector={eventNameConnector}
              eventSubName={eventSubName}
              venueName={venue?.name}
              venueLocation={venueLocation}
            />
          </SummaryRow>
        )}
      </SummaryDetailsSection>
    </div>
  );
};
