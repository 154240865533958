import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import { ProcessingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DefaultQuantityFilterOption,
  getMinQuantityOptions,
} from 'src/utils/inventoryUtils';

import {
  ListingPricingInput,
  ListingPricingSettingsCellProps,
} from '../ListingPriceForm.types';
import * as styles from './Cell.css';

export const CompListingMinQuantityCell: React.FC<
  ListingPricingSettingsCellProps
> = ({ listing, editable, event }) => {
  const { watch, formState, getFieldState, setValue } =
    useFormContext<ListingPricingInput>();

  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);

  const {
    compListingQuantityScoreAdjustmentEnabled,
    compListingQuantityScoreAdjustmentOverrideJson,
    canAutoPrice,
    onCompListingChange,
  } = useListingAutoPricingSettings(listing);

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingPrice = watch('isSubmitting');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const isDisabled =
    listing.isDeleted ||
    !canAutoPrice ||
    listingSelection.isSingleGroupMultiSelect ||
    fieldIsSubmitting ||
    !compListingQuantityScoreAdjustmentEnabled ||
    !editable;

  const submittingField = watch('submittingField');
  const isSubmittingField =
    fieldIsSubmitting &&
    submittingField === 'compListingQuantityScoreAdjustmentOverrideJson';

  const quantityFilterValue = useMemo(() => {
    const parsedQuantityOverridesJson = JSON.parse(
      compListingQuantityScoreAdjustmentOverrideJson || 'null'
    );
    return parsedQuantityOverridesJson?.quantityFilter;
  }, [compListingQuantityScoreAdjustmentOverrideJson]);

  const onMinQuantityUpdate = useCallback(
    (newValue: string) => {
      const val = parseInt(newValue);
      const defaultQuantityOverridesJson = JSON.parse(
        formState.defaultValues
          ?.compListingQuantityScoreAdjustmentOverrideJson || 'null'
      );
      if (val === defaultQuantityOverridesJson?.quantityFilter) {
        return;
      }
      const sanitizedValue = !isNaN(val) ? val : null;

      const quantityOverrideJsonNew = {
        ...defaultQuantityOverridesJson,
        quantityFilter: sanitizedValue,
      };

      onCompListingChange({
        compListingQuantityScoreAdjustmentOverrideJsonNew: JSON.stringify(
          quantityOverrideJsonNew
        ),
      });

      // Only submit if Enter is hit in the box that has changes
      setValue('isSubmittingPricingSettings', true);
      setValue('isSubmittingRowIndex', rowIndex);
      setValue(
        'submittingField',
        'compListingQuantityScoreAdjustmentOverrideJson'
      );
    },
    [
      formState.defaultValues?.compListingQuantityScoreAdjustmentOverrideJson,
      onCompListingChange,
      rowIndex,
      setValue,
    ]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <PosFormField
        errors={
          getFieldState(
            'compListingQuantityScoreAdjustmentOverrideJson',
            formState
          )?.error?.message
        }
        showErrorsInline
        className={styles.formFieldContainer}
      >
        {isSubmittingField ? (
          <div className="operation-button">
            <RotatingWrapper>
              <ProcessingIcon size={vars.iconSize.m} />
            </RotatingWrapper>
          </div>
        ) : (
          <PosSelect
            className={styles.formSelectField}
            disabled={isDisabled}
            style={{ width: 'fit-content' }}
            searchable={true}
            // Value must be string for PosSelect
            value={
              quantityFilterValue?.toString() ?? DefaultQuantityFilterOption
            }
            valueOptionsContent={getMinQuantityOptions()}
            placeholderText={ContentId.None}
            onChange={onMinQuantityUpdate}
          />
        )}
      </PosFormField>
    </TableCellDiv>
  );
};
