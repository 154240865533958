import { useCallback } from 'react';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditProgress,
} from 'src/WebApiController';

/**
 * Needs to be in sync with the method in C#
 * GetMethodName in BackgroundActionService.cs
 */
export const parseBulkEditMethodName = (methodName: string) => {
  const parts = methodName.split('-');
  return {
    entityType: parts[0] as ActionOutboxEntityType,
    bulkActionType: parts[1] as BulkActionType,
  };
};

export function useBulkEditHub(
  entityType: ActionOutboxEntityType,
  bulkEditAction: BulkActionType,
  updateKey: string,
  onBulkEditDone?: (
    progress: BulkEditProgress,
    errors: string[]
  ) => Promise<unknown>
) {
  const {
    bulkEditHub,
    progress,
    errors,
    warnings,
    mainDialogOpened,
    stage,
    setStage,
    preview,
    setPreview,
    setCurJob,
    setProgress,
    setErrors,
    setWarnings,
    setMainDialogOpened,
    retryableEntities,
    progressBeforeRetry,
    clearRetryStates,
  } = useBulkEditHubContext();

  const initJob = useCallback(() => {
    setProgress(undefined);
    setErrors([]);
    setWarnings([]);
    setMainDialogOpened(true);
    setPreview(undefined);
    setStage(BulkEditStage.Idle);
    clearRetryStates();
    setCurJob({
      entityType,
      action: bulkEditAction,
      updateKey,
      onBulkEditDone,
    });
  }, [
    bulkEditAction,
    clearRetryStates,
    entityType,
    onBulkEditDone,
    setCurJob,
    setErrors,
    setMainDialogOpened,
    setPreview,
    setProgress,
    setStage,
    setWarnings,
    updateKey,
  ]);

  return {
    bulkEditHub,
    progress,
    errors,
    warnings,
    mainDialogOpened,
    stage,
    preview,
    initJob,
    retryableEntities,
    progressBeforeRetry,
    clearRetryStates,
  };
}
