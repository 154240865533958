import { useCallback } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { BulkEditBroadcastSettingsDialog } from 'src/dialogs/BulkEdits/BulkEditBroadcastSettingsDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { lookupEventInCatalog } from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditListingClient,
  BulkEditPreview,
  BulkEditPreviewWithDetails,
  EventWithData,
  Listing,
  ListingActionType,
  ListingDetails,
  ListingGroup,
  ListingQuery,
  ListingStatus,
  Marketplace,
} from 'src/WebApiController';

export const useBulkEditBroadcastSettingsAction = ({
  affectedVisibleListingIds,
  filterQueryWithEventIds,
  setIsLoading,
  isLoading,
  event,
}: {
  affectedVisibleListingIds: number[];
  filterQueryWithEventIds: ListingQuery;
  setIsLoading: (v: boolean) => void;
  isLoading?: boolean;
  event: EventWithData['event'];
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const bulkBroadcastListingsKey = `broadcast-event-${event.viagVirtualId}`;

  const {
    data,
    updateItemInEvent,
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();

  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkEditBroadcastSettingsDialog,
    closeDialog: closeBulkEditBroadcastSettingsDialog,
  } = useDialog(
    DialogId.BulkEditBroadcastSettings,
    BulkEditBroadcastSettingsDialog
  );

  const {
    openDialog: openBulkEditUnbroadcastSettingsDialog,
    closeDialog: closeBulkEditUnbroadcastSettingsDialog,
  } = useDialog(
    DialogId.BulkEditBroadcastSettings,
    BulkEditBroadcastSettingsDialog
  );

  const onSubmitEditBroadcastSettings = useCallback(
    async (
      marketplacesToBroadcast: Marketplace[] | null,
      marketplacesToUnbroadcast: Marketplace[] | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreview
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const client = new BulkEditListingClient(
              activeAccountWebClientConfig
            );

            const preview = await client.bulkBroadcastListingsPreview(
              filterQueryWithEventIds
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkBroadcastListingsPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (marketplacesToBroadcast && marketplacesToUnbroadcast) {
        await tryInvokeApi(
          async () => {
            // First check if this event has listings expanded, if so, we want to update their state immediately
            const eventWithData = lookupEventInCatalog(
              data,
              event.viagVirtualId,
              ...event.posIds
            );
            if (eventWithData?.listings?.length) {
              const affectedListings = eventWithData.listings
                .flatMap((l) => [l, ...((l as ListingGroup)?.groupItems ?? [])])
                .filter((l) => affectedVisibleListingIds.includes(l.id));

              if (marketplacesToUnbroadcast.length) {
                affectedListings
                  .filter((l) =>
                    (l as Listing).actions.includes(
                      ListingActionType.Unbroadcast
                    )
                  )
                  .forEach((l) =>
                    updateItemInEvent(
                      {
                        ...(l as Listing),
                        status: ListingStatus.DelistingPending,
                      },
                      ActionOutboxEntityType.Listing
                    )
                  );
              }
              if (marketplacesToBroadcast.length) {
                affectedListings
                  .filter((l) =>
                    (l as Listing).actions.includes(ListingActionType.Broadcast)
                  )
                  .forEach((l) =>
                    updateItemInEvent(
                      {
                        ...(l as Listing),
                        status: ListingStatus.ListingPending,
                      },
                      ActionOutboxEntityType.Listing
                    )
                  );
              }
            }

            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkBroadcastListings(
              {
                item1: preview!,
                item2: marketplacesToBroadcast,
                item3: marketplacesToUnbroadcast,
              },
              bulkBroadcastListingsKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              // It's ok to close both, cuz only 1 can be opened anyway
              closeBulkEditBroadcastSettingsDialog();
              closeBulkEditUnbroadcastSettingsDialog();

              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest broadcast state can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }
            }
          },
          (error) => {
            showErrorDialog('onSubmitEditBroadcastSettings', error, {
              trackErrorData: {
                preview,
                marketplacesToBroadcast,
                marketplacesToUnbroadcast,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      affectedVisibleListingIds,
      bulkBroadcastListingsKey,
      closeBulkEditBroadcastSettingsDialog,
      closeBulkEditUnbroadcastSettingsDialog,
      data,
      event.posIds,
      event.viagVirtualId,
      filterQueryWithEventIds,
      refreshExpandedListItems,
      setActivePosEntity,
      setIsLoading,
      showErrorDialog,
      updateItemInEvent,
    ]
  );

  const openBulkEditBroadcastSettingsDialogWithEvent = useCallback(() => {
    openBulkEditBroadcastSettingsDialog({
      event,
      updateKey: bulkBroadcastListingsKey,
      filterQuery: filterQueryWithEventIds,
      marketplaceBroadcastSelectionMode: 'all',
      onOkay: onSubmitEditBroadcastSettings,
      onClosed: () => {
        setIsLoading(false);
      },
      onCancel: closeBulkEditBroadcastSettingsDialog,
    });
  }, [
    bulkBroadcastListingsKey,
    closeBulkEditBroadcastSettingsDialog,
    event,
    filterQueryWithEventIds,
    onSubmitEditBroadcastSettings,
    openBulkEditBroadcastSettingsDialog,
    setIsLoading,
  ]);

  const openBulkEditUnbroadcastSettingsDialogWithEvent = useCallback(() => {
    openBulkEditUnbroadcastSettingsDialog({
      event,
      updateKey: bulkBroadcastListingsKey,
      filterQuery: filterQueryWithEventIds,
      marketplaceBroadcastSelectionMode: 'none',
      onOkay: onSubmitEditBroadcastSettings,
      onClosed: () => {
        setIsLoading(false);
      },
      onCancel: closeBulkEditUnbroadcastSettingsDialog,
    });
  }, [
    bulkBroadcastListingsKey,
    closeBulkEditUnbroadcastSettingsDialog,
    event,
    filterQueryWithEventIds,
    onSubmitEditBroadcastSettings,
    openBulkEditUnbroadcastSettingsDialog,
    setIsLoading,
  ]);

  const onBroadcastAllClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setListItemExpansion(true, [event.viagVirtualId]);
      openBulkEditBroadcastSettingsDialogWithEvent();
    },
    [
      event.viagVirtualId,
      openBulkEditBroadcastSettingsDialogWithEvent,
      setListItemExpansion,
    ]
  );

  const onUnbroadcastAllClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setListItemExpansion(true, [event.viagVirtualId]);
      openBulkEditUnbroadcastSettingsDialogWithEvent();
    },
    [
      event.viagVirtualId,
      openBulkEditUnbroadcastSettingsDialogWithEvent,
      setListItemExpansion,
    ]
  );

  return {
    dropDown: (
      <>
        <PosDropdownItem
          key="BroadcastAll"
          onClick={onBroadcastAllClick}
          disabled={isLoading}
        >
          <Content id={ContentId.Broadcast} />
        </PosDropdownItem>
        <PosDropdownItem
          key="UnbroadcastAll"
          onClick={onUnbroadcastAllClick}
          disabled={isLoading}
        >
          <Content id={ContentId.Unbroadcast} />
        </PosDropdownItem>
      </>
    ),
  };
};
