import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ShiftKeyImperativeRef,
  useShiftKeySelection,
} from 'src/contexts/MultiSelectionContext/useShiftKeySelection';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import {
  EntityWithTicketsQuery,
  InventoryViewMode,
} from 'src/WebApiController';

import {
  type SelectableGroupMetadata,
  useCatalogMultiSelectionContext,
} from '../CatalogMultiSelectionContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import {
  GroupStateInput,
  InputId,
  ItemSelection,
  ItemSelectionValue,
  MultiSelectionContextProviderType,
  MultiSelectionContextType,
  SelectionItems,
  SelectionMode,
} from './MultiSelectionContext.types';

/**
 * Scope:
 * - AllGroups (all events or all months) - can select across groups
 * - SingleGroup - can select multiple items (listings, sales or purchases) for a single group
 * - SingleItem - can select a single item
 * - undefined (value, not enum) - inactive (no selection)
 */
export const enum MultiSelectScope {
  AllGroups = 'allGroups',
  SingleGroup = 'singleGroup',
  SingleItem = 'singleItem',
}

export const NO_GROUP_ID = 'NoGroupId';

const defaultValues: MultiSelectionContextType = {
  allGroupsIds: [],
  selectionMode: undefined,
  setSelectionMode: () => void 0,
  isAllSelected: false,

  // ShiftKey Selection
  clearShiftKeySelection: () => undefined,
  setShiftKeyRowSelectionState: () => void 0,
  setShiftKeyGroupSelectionState: (groupId: InputId) => void 0,
  isNoGroupIdSet: () => false,

  setAllGroupsIds: () => void 0,
  toggleGroup: () => void 0,
  getSelection: () => ({
    groupIds: [],
    items: {
      itemIds: [],
      groupIds: [],
    },
    isSingleGroupMultiSelect: false,
  }),
  getGroupToggleState: () => ({
    isGroupSelected: false,
    items: [],
  }),
  getItemToggleState: () => false,
  getGroupSelectableGroupMetadata: (groupId: string) => undefined,
  setGroupItems: () => void 0,
  setGroupStates: () => void 0,
  toggleSelectAll: () => void 0,
  totalItemsSelected: 0,
  itemSelection: {},

  // Selected count label
  setSelectedCountLabel: (label: string | undefined) => undefined,
  selectedCountLabel: undefined,
  usingCustomSelectedCountLabel: false,
  setUsingCustomSelectedCountLabel: (usingCustomSelectedCountLabel: boolean) =>
    undefined,
};

const MultiSelectionContext =
  createContext<MultiSelectionContextType>(defaultValues);

const defaultMultiSelection: ItemSelectionValue = {
  items: [],
  isGroupSelected: false,
};

export const useMultiSelectionContext = () => useContext(MultiSelectionContext);

/**
 * Context that handles multiselects for Item table/inventory view
 */
export const MultiSelectionContextProvider = ({
  children,
  initialSelectionMode,
}: MultiSelectionContextProviderType) => {
  const [selectedCountLabel, setSelectedCountLabel] = useState<
    string | undefined
  >(undefined);
  const [usingCustomSelectedCountLabel, setUsingCustomSelectedCountLabel] =
    useState<boolean>(false);

  const [allGroupsIds, setAllGroupsIds] = useState<string[]>([]);
  const shiftKeySelectionRef = useRef<ShiftKeyImperativeRef | null>(null);
  const [itemSelection, setMultiSelection] = useState<ItemSelection>({});

  const [selectionMode, setSelectionMode] = useState<SelectionMode | undefined>(
    initialSelectionMode
  );
  const { flattenedIds, groupsArray } = useCatalogMultiSelectionContext();
  const { filterQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();

  const isMultiSelectActive =
    selectionMode?.mode === MultiSelectScope.AllGroups;

  const consolidatedSelectionMode = isMultiSelectActive
    ? { mode: MultiSelectScope.AllGroups }
    : selectionMode;

  const clearShiftKeySelection = useCallback(() => {
    shiftKeySelectionRef.current?.clearShiftKeySelection();
  }, []);

  const isFlattenedView =
    filterQuery.viewMode === InventoryViewMode.FlattenedView;

  const [totalItemsSelectedCount, totalItemsCount] = useMemo(() => {
    const itemCountsResult = groupsArray.reduce(
      ([selectionCount, totalCount], group) => {
        const groupId = group.id;
        const groupItemCount = group.itemCount;
        const selection = itemSelection[groupId];
        let selectedCount = selectionCount;
        if (isFlattenedView) {
          if (Array.isArray(flattenedIds) && flattenedIds.length > 0) {
            selectedCount =
              itemSelection[NO_GROUP_ID]?.items?.filter(
                (item) =>
                  flattenedIds.includes(parseInt(item)) ||
                  flattenedIds.includes(item)
              ).length ?? 0;
          } else {
            selectedCount = itemSelection[NO_GROUP_ID]?.items?.length;
          }
        } else {
          if (selection) {
            selectedCount = selectionCount += selection.isGroupSelected
              ? groupItemCount
              : selection.items.length;
          }
        }
        return [selectedCount, (totalCount += groupItemCount)];
      },
      [0, 0]
    );

    if (
      isFlattenedView &&
      Array.isArray(flattenedIds) &&
      flattenedIds.length > 0
    ) {
      // Assuming we DO NOT return any result when it's > max (flattenedIds.length > MAX_NUM_OF_ITEMS_IN_TABLES)
      return [itemCountsResult[0], flattenedIds.length];
    }

    return itemCountsResult;
  }, [flattenedIds, groupsArray, isFlattenedView, itemSelection]);

  const isAllSelected =
    totalItemsSelectedCount > 0 && totalItemsCount === totalItemsSelectedCount;

  const toggleGroupInternal = useCallback((groupId: InputId) => {
    const strGroupId = String(groupId);
    setMultiSelection((prev) => {
      const prevItem = prev[strGroupId] ?? defaultMultiSelection;
      return {
        ...prev,
        [strGroupId]: {
          items: [],
          isGroupSelected: !prevItem.isGroupSelected,
        },
      };
    });
  }, []);

  const toggleGroup = (groupId: InputId) => {
    shiftKeySelectionRef.current?.setShiftKeyGroupSelectionState(groupId);
    toggleGroupInternal(groupId);
  };

  const setGroupItems = useCallback(
    (groupId: InputId, items: GroupStateInput) => {
      const strGroupId = String(groupId);
      const toggledItems = Object.entries(items).reduce<string[]>(
        (acc, [itemId, value]) => {
          if (value) acc.push(String(String(itemId)));
          return acc;
        },
        []
      );
      const toggledWOGroupsCount =
        Array.isArray(flattenedIds) && flattenedIds.length > 0
          ? toggledItems.filter(
              (item) =>
                flattenedIds.includes(parseInt(item)) ||
                flattenedIds.includes(item)
            ).length
          : toggledItems.length;
      let groupData = groupsArray.find((g) => g.id === groupId);
      if (!groupData) {
        groupData = {
          id: NO_GROUP_ID,
          itemCount:
            flattenedIds?.length ??
            groupsArray.reduce(
              (total, current) => total + current.itemCount,
              0
            ),
        } as SelectableGroupMetadata;
      }
      const { comboId } = groupData;
      let totalItemCount: number;
      if (comboId) {
        const relatedGroups = groupsArray.filter(
          (group) => group.comboId === comboId
        );
        totalItemCount = relatedGroups.reduce(
          (total, current) => total + current.itemCount,
          0
        );
      } else {
        totalItemCount = groupData.itemCount;
      }

      setMultiSelection((prev) => ({
        ...prev,
        [strGroupId]: {
          items: toggledItems,
          isGroupSelected: toggledWOGroupsCount === totalItemCount,
        },
      }));
    },
    [flattenedIds, groupsArray]
  );

  const setGroupStates = (groups: GroupStateInput) => {
    const state = Object.entries(groups).reduce<ItemSelection>(
      (acc, [key, value]) => {
        acc[key] = { isGroupSelected: value, items: [] };
        return acc;
      },
      {}
    );
    setMultiSelection(state);
  };

  const toggleSelectAll = () => {
    clearShiftKeySelection();
    const newSelections = isAllSelected
      ? {}
      : isFlattenedView
      ? {
          [NO_GROUP_ID]: {
            items: flattenedIds?.map((id) => String(id)) ?? [],
            isGroupSelected: true,
          },
        }
      : groupsArray.reduce<ItemSelection>((acc, group) => {
          acc[group.id] = {
            items: [],
            isGroupSelected: true,
          };
          return acc;
        }, {});

    setMultiSelection(newSelections);
  };

  const getGroupToggleState = useCallback(
    (groupId: InputId): ItemSelectionValue =>
      itemSelection[String(groupId)] || {
        isGroupSelected: false,
        items: [],
      },
    [itemSelection]
  );

  const getItemToggleState = (itemId: InputId, groupId: InputId) => {
    const strGroupId = String(groupId);
    return (
      !!itemSelection[strGroupId]?.isGroupSelected ||
      !!itemSelection[strGroupId]?.items.includes(String(itemId))
    );
  };

  const getSelection = useCallback(
    (groupId?: InputId) => {
      const strGroupId = String(groupId);
      // Group wide
      const groupIds: string[] = [];
      // Partial selections
      const items: SelectionItems = {
        itemIds: [],
        groupIds: [],
      };
      if (!groupId) {
        // Calculate all
        Object.entries(itemSelection).forEach(([strGroupId, item]) => {
          if (item.isGroupSelected) return groupIds.push(strGroupId);
          if (item.items.length) {
            items.groupIds.push(strGroupId);
            items.itemIds.push(...item.items);
          }
        });
        return {
          isSingleGroupMultiSelect: false,
          groupIds,
          items,
        };
      }

      // Calculate Group-wide
      const selection = itemSelection[strGroupId];
      if (!selection)
        return {
          isSingleGroupMultiSelect:
            selectionMode?.groupId === groupId &&
            selectionMode?.mode === MultiSelectScope.SingleGroup,
          groupIds,
          items,
        };

      if (selection.isGroupSelected) {
        groupIds.push(strGroupId);
      } else if (selection.items.length) {
        items.groupIds.push(strGroupId);
        items.itemIds.push(...selection.items);
      }

      return {
        isSingleGroupMultiSelect:
          selectionMode?.mode === MultiSelectScope.SingleGroup,
        groupIds,
        items,
      };
    },
    [itemSelection, selectionMode]
  );

  const onSetSelectionMode = useCallback(
    (mode?: SelectionMode) => {
      // Changing the mode should clear all selections
      setMultiSelection({});
      setSelectionMode(mode);
      clearShiftKeySelection();
    },
    [clearShiftKeySelection]
  );

  const getGroupSelectableGroupMetadata = useCallback(
    (groupId: string): SelectableGroupMetadata | undefined => {
      return groupsArray.find((group) => group.id === groupId);
    },
    [groupsArray]
  );

  /* ShiftKey Selection */
  const shiftKeySelection = useShiftKeySelection({
    shiftKeySelectionRef,
    selectionMode,
    getGroupToggleState,
    setGroupItems,
    toggleGroup: toggleGroupInternal,
    allGroupsIds,
  });

  return (
    <MultiSelectionContext.Provider
      value={{
        ...defaultValues,
        totalItemsSelected: totalItemsSelectedCount,
        isAllSelected,
        allGroupsIds,

        // ShiftKey selection
        ...shiftKeySelection,

        setAllGroupsIds,
        toggleSelectAll,
        toggleGroup,
        setGroupItems,
        setGroupStates,
        getSelection,
        getGroupToggleState,
        getItemToggleState,
        selectionMode: consolidatedSelectionMode,
        setSelectionMode: onSetSelectionMode,
        itemSelection,
        getGroupSelectableGroupMetadata,

        // Selected items label
        selectedCountLabel,
        setSelectedCountLabel,
        usingCustomSelectedCountLabel,
        setUsingCustomSelectedCountLabel,
      }}
    >
      {children}
    </MultiSelectionContext.Provider>
  );
};
