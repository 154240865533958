import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Stack, Switch } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkUpdateAutoFulfillDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  onOkay: (
    isNoFulfill: boolean | null,
    updateSalesOfListings: boolean,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
  entityType: ActionOutboxEntityType.Listing | ActionOutboxEntityType.Sale;
};

export function BulkUpdateAutoFulfillDialog({
  updateKey,
  onOkay,
  onCancel,
  entityType,
  ...rest
}: BulkUpdateAutoFulfillDialogProps) {
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();

  const [isNoFulfill, setIsNoFulfill] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshExpandedListItems();

        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }

        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    entityType,
    BulkActionType.UpdateIsNoFulfill,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const [updateSalesOfListings, setUpdateSalesOfListings] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(null, updateSalesOfListings, false, onPreviewReceived);
      } else {
        onOkay(
          isNoFulfill,
          updateSalesOfListings,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [
      onOkay,
      updateSalesOfListings,
      isNoFulfill,
      preview,
      hasBackgroundBulkEditFeature,
    ]
  );

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.UpdateAutoFulfill} />}
        />
      }
      onOpened={() => {
        setIsNoFulfill(false);
        setUpdateSalesOfListings(false);
        initJob();
      }}
      onKeyUp={(e) => {
        if (submittButtonRef.current && e.key === 'Enter') {
          submittButtonRef.current.click();
        }
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={entityType}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={entityType}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack direction="column" width="full" gap="l">
          <Stack gap="l">
            <span>
              <Content id={ContentId.AutoFulfill} />
            </span>
            <Switch
              checked={!isNoFulfill}
              disabled={isLoading}
              onChange={(e) => e.stopPropagation()}
              onCheckedChange={(isChecked) => {
                setIsNoFulfill(!isChecked);
              }}
            />
          </Stack>

          {entityType === ActionOutboxEntityType.Listing && (
            <Checkbox
              labelPosition="right"
              checked={updateSalesOfListings}
              onChange={() => {
                setUpdateSalesOfListings((prev) => !prev);
              }}
              disabled={isLoading}
              label={<Content id={ContentId.UpdateAssociatedSales} />}
            />
          )}
        </Stack>
      </BulkEditStatus>
    </GenericDialog>
  );
}
