export enum AdGroupsTableColumnId {
  Name = 'name',
  State = 'state',
  BaseBid = 'baseBid',
  MaxBid = 'maxBid',
  DailyBudget = 'dailyBudget',
  Genre = 'genre',
  Performer = 'performer',
  Venue = 'venue',
  TicketClass = 'ticketClass',
  Section = 'section',
  Listing = 'listing',
  Event = 'event',
  BidModifier = 'bidModifier',
  AdSpend = 'adSpend',
  SoldProceeds = 'salesProceeds',
  SoldQty = 'qtySold',
  Actions = 'actions',
}
