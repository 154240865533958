import { isEqual } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { OkButton } from 'src/components/Buttons/OkButton';
import { AdCampaignsDataContext } from 'src/contexts/AdCampaignsDataContext/AdCampaignsDataContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { ContentId } from 'src/utils/constants/contentId';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { AdCampaignState, CampaignManagerClient } from 'src/WebApiController';

import { CancellableFormFooter, FieldWrapper } from '../common';
import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from '../common/EventEntityHeader';
import { modalDetails } from '../common/Modals.css';
import { summaryWrapper } from '../common/Summary/Summary.css';
import { ModalBody } from '../Modal';
import { ModalBodyDataContainer, ModalFooter } from '../Modal/Modal.styled';
import * as styles from './AdCampaignCreate.css';

type AdCampaignCreateInput = {
  name: string;
  budget: number;
  startDateTime: string;
  endDateTime: string | null;
  currencyCode: string | null;
};

export const AdCampaignCreateForm = () => {
  const { loginContext } = useAppContext();

  const defaultValues = useMemo(() => {
    return {
      name: '',
      budget: 0,
      startDateTime: new Date().toISOString(),
      endDateTime: null,
      currencyCode: loginContext?.user?.activeAccount?.currencyCode,
    } as AdCampaignCreateInput;
  }, [loginContext?.user?.activeAccount?.currencyCode]);

  const methods = useForm<AdCampaignCreateInput>({
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <AdCampaignCreateFormContent {...methods} />
    </FormProvider>
  );
};

export function AdCampaignCreateFormContent({
  formState,
  handleSubmit,
  watch,
}: Omit<
  ComponentProps<typeof FormProvider<AdCampaignCreateInput, unknown>>,
  'children'
>) {
  const curFormValues = watch();

  const { closeModal } = useContext(ModalContext);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { refreshData } = useContext(AdCampaignsDataContext);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (adCampaignCreateInput: AdCampaignCreateInput) => {
      setIsLoading(true);

      tryInvokeApi(
        async () => {
          setIsLoading(true);

          const client = new CampaignManagerClient(
            activeAccountWebClientConfig
          );
          const response = await client.createCampaign(
            adCampaignCreateInput.name,
            AdCampaignState.Active,
            {
              amount: adCampaignCreateInput.budget,
              currencyCode: adCampaignCreateInput.currencyCode!,
            },
            adCampaignCreateInput.startDateTime,
            adCampaignCreateInput.endDateTime,
            adCampaignCreateInput.currencyCode!
          );

          if (response.success) {
            await refreshData(undefined);
          } else {
            const errorMessage = 'Failed to create Ad Campaign';

            showErrorDialog(
              'CampaignManagerClient.createCampaign',
              { message: errorMessage, status: 500 },
              {
                trackErrorData: adCampaignCreateInput,
              }
            );
            return;
          }

          closeModal(true);
        },
        (error) => {
          showErrorDialog('CampaignManagerClient.createCampaign', error, {
            trackErrorData: adCampaignCreateInput,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [activeAccountWebClientConfig, closeModal, refreshData, showErrorDialog]
  );

  const hasChanges = useCallback(
    (form: AdCampaignCreateInput) => {
      return (
        !isEqual(formState.defaultValues?.name, form.name) ||
        !isEqual(formState.defaultValues?.budget, form.budget) ||
        !isEqual(formState.defaultValues?.startDateTime, form.startDateTime) ||
        !isEqual(formState.defaultValues?.endDateTime, form.endDateTime) ||
        !isEqual(formState.defaultValues?.currencyCode, form.currencyCode)
      );
    },
    [
      formState.defaultValues?.name,
      formState.defaultValues?.budget,
      formState.defaultValues?.startDateTime,
      formState.defaultValues?.endDateTime,
      formState.defaultValues?.currencyCode,
    ]
  );

  const onSubmitHandler = useCallback(() => {
    if (!hasChanges(curFormValues)) return;
    handleSubmit(onSubmit)();
  }, [hasChanges, curFormValues, handleSubmit, onSubmit]);

  const formHasChanges = hasChanges(curFormValues);

  return (
    <>
      <CancellableFormHeader
        disabled={isLoading || formState.isSubmitting}
        showDialogOnCancel={formHasChanges}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.AddAdCampaign} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        <div className={modalDetails}>
          <ModalBodyDataContainer>
            <AdCampaignCreateFormBody />
          </ModalBodyDataContainer>
        </div>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          disabled={isLoading}
          showDialogOnCancel={formHasChanges}
        >
          <OkButton
            onClick={onSubmitHandler}
            disabled={isLoading || !formHasChanges}
            textContentId={ContentId.Save}
          />
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
}

export const AdCampaignCreateModal = {
  children: <AdCampaignCreateForm />,
  clickOutsideToClose: true,
  size: 'slide-in',
};

export const AdCampaignCreateFormBody = () => {
  const { timeZone } = useSiteTimezoneContext();
  const { register, getValues, setValue, formState, clearErrors } =
    useFormContext<AdCampaignCreateInput>();

  const { name, budget, startDateTime, endDateTime } = getValues();

  const namePlaceholder = useContent(ContentId.EnterAdCampaignName);

  const requiredMsg = useContent(ContentId.Required);

  const { getUiCurrency } = useLocalizationContext();
  const { loginContext } = useAppContext();

  const uiCurrency = getUiCurrency(
    loginContext?.user?.activeAccount?.currencyCode
  );

  const setBudget = useCallback(
    (budget: number) => {
      if (budget >= 0 && budget <= Number.MAX_VALUE) {
        clearErrors('budget');
        setValue('budget', budget);
      }
    },
    [clearErrors, setValue]
  );

  return (
    <>
      <div className={summaryWrapper}>
        <header className={styles.headerSection}>
          <div className={styles.headerGroups}>
            <FieldWrapper>
              <PosFormField
                errors={formState.errors.name?.message}
                label={<Content id={ContentId.Name} />}
              >
                <PosTextField
                  rootProps={{
                    state: getTextFieldState(formState.errors.name),
                  }}
                  value={name || undefined}
                  placeholder={namePlaceholder}
                  spellCheck={false}
                  {...register('name', {
                    validate: {
                      required: (fieldVal) => {
                        return fieldVal?.length ? undefined : requiredMsg;
                      },
                    },
                    onChange: (event) => {
                      if (event.target.value) {
                        clearErrors('name');
                      }
                    },
                  })}
                />
              </PosFormField>
            </FieldWrapper>
            <FieldWrapper>
              <PosFormField
                errors={formState.errors.budget?.message}
                label={<Content id={ContentId.Budget} />}
              >
                <PosCurrencyField
                  rootProps={{
                    state: getTextFieldState(formState.errors.budget),
                  }}
                  uiCurrency={uiCurrency}
                  value={budget}
                  onChange={(e) => {
                    const v = parseFloat(e.target.value) || 0;
                    setBudget(v);
                  }}
                />
              </PosFormField>
            </FieldWrapper>
            <FieldWrapper>
              <PosFormField
                label={<Content id={ContentId.StartDateTime} />}
                errors={formState.errors.startDateTime?.message}
              >
                <DatePickerInput
                  fieldError={formState.errors.startDateTime?.message}
                  date={startDateTime ? new Date(startDateTime) : undefined}
                  onDateChange={(date: Date) => {
                    if (date) {
                      clearErrors('startDateTime');
                      const dateStr = date.toISOString();
                      if (dateStr !== startDateTime) {
                        setValue('startDateTime', dateStr);
                      }
                    }
                  }}
                  locale={getLocaleFromLanguageOrCurrent()}
                />
              </PosFormField>
            </FieldWrapper>
            <FieldWrapper>
              <PosFormField
                label={<Content id={ContentId.EndDateTime} />}
                errors={formState.errors.endDateTime?.message}
              >
                <DatePickerInput
                  fieldError={formState.errors.endDateTime?.message}
                  date={endDateTime ? new Date(endDateTime) : undefined}
                  onDateChange={(date: Date) => {
                    if (date) {
                      clearErrors('endDateTime');
                      const dateStr = date.toISOString();
                      if (dateStr !== endDateTime) {
                        setValue('endDateTime', dateStr);
                      }
                    }
                  }}
                  locale={getLocaleFromLanguageOrCurrent()}
                />
              </PosFormField>
            </FieldWrapper>
          </div>
        </header>
      </div>
    </>
  );
};
