import { ComponentPropsWithRef, useMemo } from 'react';
import {
  contentComparator,
  useContentContext,
} from 'src/contexts/ContentContext';
import { PosSelect } from 'src/core/POS/PosSelect';

export type PosEnumSelectProps<EnumType extends string> = {
  value?: EnumType | null;
  defaultValue?: EnumType | null;
  valueOptionsContent: Record<EnumType, string>;
  onChange: (option: EnumType | null) => void;
} & Omit<
  ComponentPropsWithRef<typeof PosSelect>,
  'value' | 'valueOptionsContent' | 'onChange' | 'defaultValue'
>;

export function PosEnumSelect<EnumType extends string>({
  value,
  defaultValue,
  onChange,
  valueOptionsContent,
  ...rest
}: PosEnumSelectProps<EnumType>) {
  const contentContext = useContentContext();
  const s = useMemo(() => {
    return contentComparator.bind(undefined, contentContext);
  }, [contentContext]);

  return (
    <PosSelect
      value={value == null ? undefined : (value as string)}
      defaultValue={
        defaultValue === undefined
          ? undefined
          : defaultValue === null
          ? ''
          : defaultValue
      }
      onChange={(o: string) => {
        onChange(o as EnumType);
      }}
      valueOptionsContent={valueOptionsContent}
      {...rest}
      sortFn={s}
    />
  );
}
