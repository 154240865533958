import {
  QueryObserverResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { AdGroup, CampaignManagerClient } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useErrorBoundaryContext } from '../ErrorBoundaryContext';

export type IAdGroupsDataContext = {
  adGroupsDataQuery: UseQueryResult<AdGroup[] | null>;
  adGroupsData: AdGroup[] | null | undefined;
  errorInfo?: { errorHeader: React.ReactNode; errorMessage: React.ReactNode };
  refreshData: (
    adCampaignId?: string,
    adGroupId?: string
  ) => Promise<QueryObserverResult>;
};

export const AdGroupsDataContext = createContext<IAdGroupsDataContext>({
  adGroupsDataQuery: {} as UseQueryResult<AdGroup[] | null>,
  adGroupsData: [],
  refreshData: () => Promise.resolve({} as QueryObserverResult),
});

export const useAdGroupsDataContext = () => useContext(AdGroupsDataContext);

export const AdGroupsDataContextProvider = ({
  adCampaignId,
  adGroupId,
  children,
}: {
  adCampaignId: string | undefined;
  adGroupId: string | undefined;
  children: React.ReactNode;
}) => {
  const [errorInfo, setErrorInfo] = useState<{
    errorHeader: React.ReactNode;
    errorMessage: React.ReactNode;
  }>();

  const {
    adGroupsDataQuery: adGroupsDataQuery,
    adGroupsData,
    refreshData,
  } = useGetAdGroupsDataQuery(adCampaignId, adGroupId, setErrorInfo);

  return (
    <AdGroupsDataContext.Provider
      value={{
        adGroupsDataQuery: adGroupsDataQuery,
        adGroupsData: adGroupsData,
        errorInfo,
        refreshData,
      }}
    >
      {children}
    </AdGroupsDataContext.Provider>
  );
};

const useGetAdGroupsDataQuery = (
  adCampaignId: string | undefined,
  adGroupId: string | undefined,
  setErrorInfo: (
    value: React.SetStateAction<
      | {
          errorHeader: React.ReactNode;
          errorMessage: React.ReactNode;
        }
      | undefined
    >
  ) => void
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const ADGROUP_DATA_QUERY_KEY = 'CampaignManagerClient.retrieveAdGroups';
  const queryKey = useMemo(
    () => [
      ADGROUP_DATA_QUERY_KEY,
      activeAccountWebClientConfig.activeAccountId,
      adCampaignId,
      adGroupId,
    ],
    [activeAccountWebClientConfig.activeAccountId, adCampaignId, adGroupId]
  );

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    (adCampaignId != null || adGroupId != null);

  const adGroupsDataQuery = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      setErrorInfo(undefined);

      if (!shouldQuery) {
        return null;
      }

      return tryInvokeApi(
        async () => {
          const client = new CampaignManagerClient(
            activeAccountWebClientConfig
          );
          const data = await client.retrieveAdGroups(
            adCampaignId ?? undefined,
            adGroupId ?? undefined
          );
          return data;
        },
        (error) => {
          const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
            error?.status,
            error?.message
          );
          setErrorInfo({
            errorHeader: headerDisplay,
            errorMessage: messageDisplay,
          });
          trackError(ADGROUP_DATA_QUERY_KEY, error, {
            adCampaignId: adCampaignId,
            adGroupId: adGroupId,
          });
        }
      );
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  const queryClient = useQueryClient();

  const refreshData = useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });

    return await adGroupsDataQuery.refetch();
  }, [adGroupsDataQuery, queryClient, queryKey]);

  return {
    adGroupsDataQuery: adGroupsDataQuery,
    adGroupsData: adGroupsDataQuery.data,
    refreshData: refreshData,
  };
};
