import { useUpdatePurchasesPermissions } from 'src/components/Purchases/BulkActions/useUpdatePurchasesPermissions';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export const LaunchUpdatePurchaseSettings = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const { hasUpdatePurchasesPermission } = useUpdatePurchasesPermissions();

  if (!hasUpdatePurchasesPermission) {
    return null;
  }

  return (
    <>
      <PosDropdownItem
        key="general-settings-event-purchases"
        onClick={onClick}
        disabled={disabled}
      >
        <Stack gap="m" alignItems="center" width="full">
          {/* Now only purchased by is supported. Use ContentId.EditGeneralSettings when multiple settings are supported */}
          <Content id={ContentId.UpdatePurchasedBy} />
        </Stack>
      </PosDropdownItem>
    </>
  );
};
