import { useMemo } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import {
  NO_GROUP_ID,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { unpackNumericIds } from 'src/contexts/MultiSelectionContext/MultiSelectionContext.utils';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import {
  ActionOutboxEntityType,
  InventoryViewMode,
  ListingQuery,
  PurchaseOrderQuery,
  SaleQuery,
} from 'src/WebApiController';

export type FilterQueryType = ListingQuery | SaleQuery | PurchaseOrderQuery;

export type UseFilterQueryStateType<T extends FilterQueryType> = T & {
  eventOrMappingIds?: string[] | null;
  purchaseMonths?: string[];
};

type UseFilterQueryState<T extends FilterQueryType> = {
  filterQueryWithEventIds: UseFilterQueryStateType<T>;
  /**
   * Returns a list of selected viagogoIds grouped by event
   */
  selectedViagogoVirtualIds: string[] | undefined;
};

export const useFilterQueryState = <T extends FilterQueryType>(
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.Purchase,
  // All the group ids cannot be outside of this one
  groupIdSuperSet?: string[],
  useTicketGroupIds?: boolean
): UseFilterQueryState<T> => {
  const { getSelection, isAllSelected } = useMultiSelectionContext();
  const selectionState = getSelection();

  const { filterQuery } = useFilterQueryContext<T>();
  const { eventsTransformed } = useCatalogDataContext();
  const { timePeriodPurchasesQuery } = usePurchaseDataContext();

  const isFlattenedViewWithNoGroupId = useMemo(() => {
    const { groupIds, items } = selectionState;
    return (
      filterQuery.viewMode === InventoryViewMode.FlattenedView &&
      ((groupIds.length === 1 && groupIds[0] === NO_GROUP_ID) ||
        (items.groupIds.length === 1 && items.groupIds[0] === NO_GROUP_ID))
    );
  }, [filterQuery.viewMode, selectionState]);

  const filterQueryWithEventIds = useMemo<UseFilterQueryStateType<T>>(() => {
    const { groupIds, items } = selectionState;

    // Clean up the oldPosEventIds for filtering, which is only used for the event inventory page
    if (entityType === ActionOutboxEntityType.Listing) {
      Object.assign(filterQuery as ListingQuery, { oldPosEventIds: null });
    }

    // Flattened view, have to pick events from listings
    if (isFlattenedViewWithNoGroupId) {
      if (isAllSelected) {
        const allEventMappingIds =
          eventsTransformed
            ?.map((ev) => ev.event.viagVirtualId)
            ?.filter(
              (id) =>
                id !== NO_GROUP_ID &&
                (!groupIdSuperSet?.length || groupIdSuperSet.includes(id))
            ) ?? [];

        if (entityType === ActionOutboxEntityType.Purchase) {
          return {
            ...filterQuery,
            purchaseMonths: timePeriodPurchasesQuery.data?.map(
              (m) => m.firstOfTimePeriod
            ),
            eventOrMappingIds: allEventMappingIds,
          };
        }

        return {
          ...filterQuery,
          eventOrMappingIds: allEventMappingIds,
        };
      } else {
        // Item selections only
        const entityIds = selectionState.items.itemIds;
        return {
          ...filterQuery,
          eventOrMappingIds: [],
          entityIds: entityIds,
        };
      }
    }

    // Non-flattened view

    // Filter the group ids to the super-set so we don't accidentally include more items than this was meant for
    const filteredGroupIds = !groupIdSuperSet?.length
      ? groupIds
      : groupIds.filter((id) => groupIdSuperSet.includes(id));

    if (entityType === ActionOutboxEntityType.Purchase) {
      const unpackedPurchaseIds = new Set<number>();
      const unpackedTicketGroupIds = new Set<number>();
      if (useTicketGroupIds) {
        items.itemIds.forEach((packedId) => {
          const unpackedIds = unpackNumericIds(packedId);
          if (unpackedIds[0]) {
            unpackedPurchaseIds.add(unpackedIds[0]);
          }
          if (unpackedIds[1]) {
            unpackedTicketGroupIds.add(unpackedIds[1]);
          }
        });
      }

      const entityIds: number[] | null = useTicketGroupIds
        ? Array.from(unpackedPurchaseIds)
        : items.itemIds.map((id) => parseInt(id, 10));

      const ticketGroupIds: number[] | null = useTicketGroupIds
        ? Array.from(unpackedTicketGroupIds)
        : null;

      const allEventMappingIds =
        eventsTransformed
          ?.map((ev) => ev.event.viagVirtualId)
          ?.filter(
            (id) =>
              id !== NO_GROUP_ID &&
              (!groupIdSuperSet?.length ||
                !useTicketGroupIds ||
                groupIdSuperSet.includes(id))
          ) ?? [];

      return {
        ...filterQuery,
        // If it's a purchase query, use the purchase months
        purchaseMonths: useTicketGroupIds ? undefined : filteredGroupIds,
        eventOrMappingIds: useTicketGroupIds
          ? filteredGroupIds
          : allEventMappingIds,
        entityIds: entityIds,
        ticketGroupIds: ticketGroupIds,
      };
    }

    if (eventsTransformed == null) {
      return {
        ...filterQuery,
        eventOrMappingIds: filteredGroupIds,
        entityIds: items.itemIds.map((id) => parseInt(id, 10)),
      };
    }

    // We want to make sure we get all the events sharing the same group ids because a single gropu id can be part of a merged event
    const normalizedGroupIdsByMergedEvents = eventsTransformed
      ?.filter((ev) => groupIds.includes(ev.event.viagVirtualId))
      ?.map((ev) => ev.event.viagVirtualId)
      ?.filter((id) => id.length && !id.includes(NO_GROUP_ID));

    return {
      ...filterQuery,
      // Get all the event ids that matches the viagogo ids in groupIdsAsViagogoIds
      eventOrMappingIds: normalizedGroupIdsByMergedEvents,
      entityIds: items.itemIds.map((id) => parseInt(id, 10)),
    };
  }, [
    selectionState,
    isFlattenedViewWithNoGroupId,
    groupIdSuperSet,
    entityType,
    eventsTransformed,
    filterQuery,
    isAllSelected,
    timePeriodPurchasesQuery.data,
    useTicketGroupIds,
  ]);

  // This is used for the Catchup
  const selectedViagogoVirtualIds = useMemo<string[] | undefined>(() => {
    const { groupIds, items } = selectionState;

    // Flattened view, have to pick events from listings
    if (isFlattenedViewWithNoGroupId) {
      if (isAllSelected) {
        return eventsTransformed?.map((ev) => ev.event.viagVirtualId);
      } else {
        const entityIds = items.itemIds;
        return eventsTransformed
          ?.filter((ev) =>
            entityType === ActionOutboxEntityType.Listing
              ? ev.listings?.some((l) => entityIds.includes(l.id.toString()))
              : ev.sales?.some((s) => entityIds.includes(s.id.toString()))
          )
          ?.map((ev) => ev.event.viagVirtualId)
          ?.filter((id) => id.length && !id.includes(NO_GROUP_ID));
      }
    }

    // If the selection has events, use it
    if (groupIds?.length) {
      if (entityType === ActionOutboxEntityType.Purchase) {
        return;
      }

      return eventsTransformed
        ?.filter((ev) => groupIds.includes(ev.event.viagVirtualId))
        ?.map((ev) => ev.event.viagVirtualId)
        ?.filter((id) => id.length && !id.includes(NO_GROUP_ID));
    }

    return;
  }, [
    entityType,
    eventsTransformed,
    isAllSelected,
    isFlattenedViewWithNoGroupId,
    selectionState,
  ]);

  return { filterQueryWithEventIds, selectedViagogoVirtualIds };
};
