import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const TileWrapper = styled.div`
  width: 100%;
  padding: ${() => `${vars.spacing.m} ${vars.spacing.m}`};
  background: ${({ theme }) => theme.colors.backgroundPrimary};
  border-radius: ${() => vars.spacing.sm};
  margin-bottom: ${() => vars.spacing.xxs};
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundBrandLight};
  }
`;

export const TileWrapperIndented = styled.div`
  width: 100%;
  padding: ${() => `${vars.spacing.m} ${vars.spacing.m}`};
  background: ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: ${() => vars.spacing.sm};
  margin-bottom: ${() => vars.spacing.xxs};
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundBrandLight};
  }
`;

export const TileHeader = styled.span`
  font-size: var(--bs-body-font-size);
  ${({ theme: { typography } }) => typography.getStyles('caption1', 'regular')}
  color: ${({ theme: { colors } }) => colors.textPrimary};
  display: flex;
  align-items: center;
  padding-bottom: ${() => vars.spacing.m};
`;

export const TileLeftLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  gap: 4px;
`;

export const TileRightLayout = styled.div<{ columns?: number }>`
  display: grid;
  grid-template-columns:
    repeat(${(props) => (props.columns ?? 2) - 1}, max-content)
    auto;
  gap: 4px;
  align-items: center;
`;
