import { EventAccordionItemBodyComponentType } from 'src/components/Accordions';
import { FormatOptionEntries } from 'src/modals/EditTableColumns';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import {
  PurchaseOrder,
  PurchaseOrderTicketGroup,
  PurchaseViewMode,
} from 'src/WebApiController';

import { TableProps } from '../Table/Table';

export const SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE = '30px';

export type PurchaseOrderTableDataProps = {
  purchaseOrders?: (PurchaseOrderTicketGroup | PurchaseOrder)[] | null;
  purchaseCount: number;
  useVirtuoso?: boolean;
} & Pick<
  EventAccordionItemBodyComponentType,
  | 'onMount'
  | 'onUnmount'
  | 'initState'
  | 'getDataFail'
  | 'disablePagination'
  | 'onItemSelectionsChanged'
>;

export type PurchaseOrderTableProps = PurchaseOrderTableDataProps & {
  groupId: string; // First of month, or event virtualId
  viewMode?: PurchaseViewMode;
  isItemsLoading?: boolean;
  rowSupportsAccordion?: boolean;
  columnVisibilityOverride?: PurchasesTableColumnId[];
  formatOptionOverrides?: FormatOptionEntries;
  withOuterPadding?: boolean;
  disableSelection?: boolean;
  isSideTable?: boolean;
} & Pick<
    TableProps<PurchaseOrderTicketGroup | null>,
    'RowComponent' | 'virtuosoItemSize'
  >;
