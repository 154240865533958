// Copied from Viagogo
import { useEffect, useRef, useState } from 'react';
import { useScreenSizeContent } from 'src/contexts/ScreenSizeContext/ScreenSizeContext';
import { breakpoints } from 'src/core/themes/responsiveStyle';

export const makeMediaQuery = (min: number, max?: number): string =>
  `only screen and (min-width: ${min}px)${
    max ? ` and (max-width: ${max}px)` : ''
  }`;

export const mediaQuery = {
  mobile: makeMediaQuery(0, breakpoints.tablet - 1),
  tablet: makeMediaQuery(0, breakpoints.desktop - 1), // include mobile
  desktop: makeMediaQuery(breakpoints.desktop), // include large desktop
  largeDesktop: makeMediaQuery(breakpoints.largeDesktop),
} as const;

export type MediaQueryType = keyof typeof mediaQuery;

/**
 * Returns a bool indicating if a media query matches the screen size or not.
 * Don't use this for defined queries in ScreenSizeContent.
 * Use directly useMatchMedia for better performance.
 * @param query
 */
export const useMatchMediaQuery = (query: string) => {
  const mqlRef = useRef(window.matchMedia(query));
  const [match, setMatching] = useState(mqlRef.current.matches);

  useEffect(() => {
    let mql = mqlRef.current;
    if (query !== mqlRef.current.media) {
      mqlRef.current = window.matchMedia(query);
      mql = mqlRef.current;
    }

    const listenerFunc = (e: {
      matches: boolean | ((prevState: boolean) => boolean);
    }) => setMatching(e.matches);

    // should not split this by build but feature detect
    if ('addEventListener' in mql) {
      mql.addEventListener('change', listenerFunc);
    } else if ('addListener' in mql) {
      (mql as MediaQueryList).addListener(listenerFunc);
    }

    return () => {
      if ('removeEventListener' in mql) {
        mql.removeEventListener('change', listenerFunc);
      } else if ('removeListener' in mql) {
        (mql as MediaQueryList).removeListener(listenerFunc);
      }
    };
  }, [query]);

  return match;
};

export const useMatchMedia = (media: MediaQueryType): boolean => {
  const screenSizeState = useScreenSizeContent();
  return screenSizeState[media];
};
