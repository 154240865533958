import { EventWithData } from 'src/WebApiController';
import { CatalogResults, ListingReportMetrics } from 'src/WebApiController';

export const mapReportMetrics = (
  reportMetrics: ListingReportMetrics[] | undefined,
  eventsTransformed: EventWithData[] | undefined,
  data: CatalogResults | undefined
) => {
  const metricsMapped = reportMetrics?.map((r) => {
    const event = r.eventId
      ? eventsTransformed?.find(
          (e) =>
            e.event.viagId?.toString() === r.eventId ||
            e.event.mappingId === r.eventId
        )?.event
      : undefined;

    const performerId = r.perfId ?? event?.perfId;
    const performer = performerId ? data?.performers?.[performerId] : undefined;

    const venueId = r.venueId ?? event?.venueId;
    const venue = venueId ? data?.venues?.[venueId] : undefined;
    return {
      ...r,
      performer,
      venue,
      event,
    };
  });

  return metricsMapped;
};
