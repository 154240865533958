import {
  QueryObserverResult,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { createContext, useCallback, useContext, useState } from 'react';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import { MonthContainingMarketplacePaymentsResult } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { ErrorTypes } from '../ErrorBoundaryContext';
import {
  PAYMENT_MONTHLY_CONTAINER_QUERY_KEY,
  PAYMENT_SINGLE_MONTH_QUERY_KEY,
} from './MarketplacePaymentDataContext.constants';
import { useGetMonthMarketplacePaymentsQuery } from './useGetMonthMarketplacePaymentsQuery';

export type IMarketplacePaymentDataContext = {
  monthPaymentsQuery: UseQueryResult<
    MonthContainingMarketplacePaymentsResult[] | null,
    ErrorTypes
  >;
  refreshData: (
    refetchAll?: boolean
  ) => Promise<QueryObserverResult<unknown, unknown>>;
  errorInfo?: { errorHeader: React.ReactNode; errorMessage: React.ReactNode };
  onSelect: (targetId: string, forceSelect?: boolean) => void;
  selectedIds: string[];
  scrollToId?: string;
  setScrollToId: (id?: string) => void;
};

export const MarketplacePaymentDataContext =
  createContext<IMarketplacePaymentDataContext>({
    monthPaymentsQuery: {} as UseQueryResult<
      MonthContainingMarketplacePaymentsResult[] | null
    >,
    refreshData: () =>
      Promise.resolve({} as QueryObserverResult<unknown, unknown>),
    selectedIds: [],
    onSelect: () => {},
    setScrollToId: () => {},
  });

export const useMarketplacePaymentDataContext = () =>
  useContext(MarketplacePaymentDataContext);

export const MarketplacePaymentDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const queryClient = useQueryClient();

  const { activeAccountWebClientConfig } = useAppContext();
  const [errorInfo, setErrorInfo] = useState<{
    errorHeader: React.ReactNode;
    errorMessage: React.ReactNode;
  }>();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [scrollToId, setScrollToId] = useState<string | undefined>();

  const onSelect = useCallback(
    (targetId: string, forceSelect?: boolean) => {
      const indexOf = selectedIds.indexOf(targetId);

      if (indexOf >= 0) {
        // if force-select, we won't de-select if it's already selected
        if (forceSelect) {
          setScrollToId(targetId);
        } else {
          setSelectedIds(selectedIds.filter((id) => id !== targetId));
        }
      } else {
        setSelectedIds([...selectedIds, targetId]);
        setScrollToId(targetId);
      }
    },
    [selectedIds]
  );

  const monthPaymentsQuery = useGetMonthMarketplacePaymentsQuery(
    selectedIds,
    setSelectedIds,
    setErrorInfo
  );

  const refreshData = useCallback(
    async (refetchAll?: boolean) => {
      if (refetchAll) {
        queryClient.invalidateQueries({
          queryKey: [
            PAYMENT_MONTHLY_CONTAINER_QUERY_KEY,
            activeAccountWebClientConfig.activeAccountId,
          ],
        });
        // For the months one we want to just query all - since this is each month ()
        queryClient.invalidateQueries({
          queryKey: [PAYMENT_SINGLE_MONTH_QUERY_KEY, true],
          type: 'all',
        });
      }

      return monthPaymentsQuery.refetch();
    },
    [
      activeAccountWebClientConfig.activeAccountId,
      monthPaymentsQuery,
      queryClient,
    ]
  );

  if (monthPaymentsQuery.failureReason) {
    const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
      null,
      monthPaymentsQuery.failureReason.message
    );
    setErrorInfo({
      errorHeader: headerDisplay,
      errorMessage: messageDisplay,
    });
  }

  return (
    <MarketplacePaymentDataContext.Provider
      value={{
        monthPaymentsQuery,
        refreshData,
        errorInfo,
        selectedIds,
        onSelect,
        scrollToId,
        setScrollToId,
      }}
    >
      {children}
    </MarketplacePaymentDataContext.Provider>
  );
};
