import { CoBrandId } from 'src/WebApiController';
import styled from 'styled-components/macro';

export const UserInfoDisplayContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.lg,
      `
      align-items: flex-end;
      `
    )}`}
`;
export const ActiveAccountDisplay = styled.div<{ cobrandId?: CoBrandId }>`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('body1')};
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  color: ${(props) =>
    props.cobrandId === CoBrandId.TicketUtil
      ? props.theme.colors.textInverted
      : props.theme.colors.textPrimary};

  @media ${({ theme: { media } }) => media.desktop} {
    text-align: end;
  }
`;

export const ActiveUserDisplay = styled.div`
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.lg,
      `
      text-align: end;
      `
    )}`}
`;
