import { color, sizing } from './themeContract.css';

/**
 * Theme for vanilla-extract
 */
export { purpleTheme } from './purpleTheme.css';
export { responsiveStyle } from './responsiveStyle';
export * as shared from './shared.css';
export { ticketUtilTheme } from './ticketUtilTheme.css';

export const vars = { ...sizing, color };
/**
 * Theme for styled-components
 */
export { PurpleLegacyTheme } from './purpleLegacyTheme';
export { TicketUtilLegacyTheme } from './ticketUtilLegacyTheme';
