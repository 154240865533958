import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SimplifiedAutopricingGuidedPrice } from 'src/components/AutoPricing/components/SimplifiedAutopricingGuidedPrice';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { PosFormField } from 'src/core/POS/PosFormField';
import { Button, Stack, Switch } from 'src/core/ui';
import { useListingHasVenueMapInfo } from 'src/hooks/useListingHasVenueMapInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Feature,
  Listing,
  ListingDetails,
  ListingDetailsUpdateInput,
} from 'src/WebApiController';

import { AutoPricePreview } from '../AutoPricePreview';
import { ListingTab } from '../listingDetailsUtils';
import { useListingAutoPricingSettings } from '../useListingAutoPricingSettings';
import { AdvancePricingSettings } from './AdvancePricingSection';
import { AutoPricingSettingsSection } from './AutoPricingSettingsSection';
import { AutoPricingStrategySection } from './AutoPricingStrategySection';
import { PricingProtection } from './PricingProtection';

export const AutoPricingSection = ({
  listing,
  disabled,
  isBulkEdit,
  isGlobalEdit,
  isStrategyEdit,
  listingHasParent,
  onSwitchToTab,
}: {
  listing?: Listing | null;
  disabled?: boolean;
  isBulkEdit?: boolean;
  isGlobalEdit?: boolean;
  isStrategyEdit?: boolean;
  listingHasParent?: boolean;
  onSwitchToTab?: (tabName: ListingTab) => void;
}) => {
  const { setValue } = useFormContext<ListingDetailsUpdateInput>();

  const {
    canSetPrice,
    canAutoPrice,
    autoPricingEnabled,
    setAutoPricingDefaults,
    showAutoPricingSettings,
    skipAutoPricing,
    event,
  } = useListingAutoPricingSettings(listing, isBulkEdit);

  const hasAutoPricingUiGateRemovalFeature = useUserHasFeature(
    Feature.RemoveUiGateOnUnmap
  );

  const [showDetailedSettings, setShowDetailedSettings] = useState(
    !isStrategyEdit && !isBulkEdit ? false : true
  );

  const turnOffSkipSetting = useCallback(
    () => setValue('skipAutoPricing', false),
    [setValue]
  );

  const { mapExists, hasVenueMapInfo } = useListingHasVenueMapInfo(
    listing,
    hasAutoPricingUiGateRemovalFeature
  );

  if (!canSetPrice || !canAutoPrice) {
    return null;
  }

  return (
    <>
      <DetailSection name={<Content id={ContentId.Autopricing} />}>
        {isBulkEdit && !isStrategyEdit ? (
          <Checkbox
            label={<Content id={ContentId.ApplyAutoPricingSettings} />}
            labelPosition="right"
            onChange={(e) => {
              const skip = !e.target.checked;
              setValue('skipAutoPricing', skip);
              if (!skip) {
                if (autoPricingEnabled == null) {
                  // When this is turned on, we want to also set the value of autoPricingEnabled
                  // to false so it will match what the Switch is reflecting
                  setValue('autoPricingEnabled', false);
                }
              } else {
                // If skip is set to true, ensure all other skips are set to true
                setValue('skipAdvancePricing', true);
                setValue('skipCompListing', true);
                setValue('skipUndercut', true);
              }
            }}
            checked={!skipAutoPricing}
          />
        ) : undefined}
        <SectionContent numOfColumns={1}>
          {(isBulkEdit || hasVenueMapInfo) && canAutoPrice ? (
            <>
              {!isStrategyEdit && (
                <DetailGroup style={{ gridColumn: '1' }}>
                  <Stack gap="l" justifyContent="spaceBetween">
                    <Stack gap="l" alignItems="start" flexWrap="wrap">
                      <PosFormField
                        label={<Content id={ContentId.EnableAutoPricing} />}
                        style={{ width: 'auto' }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Switch
                            disabled={
                              disabled || listing?.isDeleted || !canAutoPrice
                            }
                            checked={Boolean(autoPricingEnabled)}
                            onChange={(e) => e.stopPropagation()}
                            onCheckedChange={(isChecked) => {
                              turnOffSkipSetting();
                              if (isChecked !== autoPricingEnabled) {
                                setValue('autoPricingEnabled', isChecked);

                                if (isChecked) {
                                  // If enabled, set defaults where necessary
                                  setAutoPricingDefaults();
                                }
                              }
                            }}
                          />
                        </div>
                      </PosFormField>
                      <AutoPricingStrategySection
                        listing={listing}
                        disabled={disabled}
                        isMarketListingsView
                        isBulkEdit={isBulkEdit}
                      />
                    </Stack>
                    <PosFormField
                      style={{ textAlign: 'right', width: 'auto' }}
                      label={<Content id={ContentId.ShowDetailedSettings} />}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end',
                          width: '100%',
                        }}
                      >
                        <Switch
                          disabled={
                            disabled || listing?.isDeleted || !canAutoPrice
                          }
                          checked={Boolean(showDetailedSettings)}
                          onChange={(e) => e.stopPropagation()}
                          onCheckedChange={(isChecked) => {
                            setShowDetailedSettings(isChecked);
                          }}
                        />
                      </div>
                    </PosFormField>
                  </Stack>
                </DetailGroup>
              )}

              {showDetailedSettings ? (
                <>
                  {!isBulkEdit &&
                    !isStrategyEdit &&
                    event?.viagId &&
                    listing?.id && (
                      <DetailGroup style={{ gridColumn: '1' }}>
                        <AutoPricePreview
                          viagogoEventId={event.viagId}
                          listingId={listing.id}
                        />
                      </DetailGroup>
                    )}
                  <AutoPricingSettingsSection
                    listing={listing!}
                    disabled={disabled || Boolean(skipAutoPricing)}
                    listingHasParent={
                      listingHasParent != null
                        ? listingHasParent // If we pass this in, we want to use it instead of looking at the listing
                        : !!listing?.ltGrpParentId
                    }
                    isBulkEdit={isBulkEdit}
                    isGlobalEdit={isGlobalEdit}
                    isStrategyEdit={isStrategyEdit}
                  />
                </>
              ) : (
                <>
                  <SimplifiedAutopricingGuidedPrice
                    listing={listing}
                    listingHasParent={listingHasParent}
                  />
                  {!isBulkEdit &&
                    !isStrategyEdit &&
                    event?.viagId &&
                    listing?.id && (
                      <DetailGroup style={{ gridColumn: '1' }}>
                        <AutoPricePreview
                          viagogoEventId={event.viagId}
                          listingId={listing.id}
                        />
                      </DetailGroup>
                    )}
                </>
              )}
            </>
          ) : (
            <DetailGroup style={{ gridColumn: '1' }}>
              <Stack direction="column" gap="l">
                {!canAutoPrice ? (
                  <Content id={ContentId.NoAutoPricing_MissingPermission} />
                ) : (
                  !isBulkEdit &&
                  (mapExists && !hasVenueMapInfo ? (
                    <>
                      <Content id={ContentId.NoAutoPricing_MissingRowId} />

                      <Button
                        variant="link"
                        onClick={() => onSwitchToTab?.(ListingTab.Broadcast)}
                        style={{ width: 'max-content', padding: '0' }}
                      >
                        <Content id={ContentId.UpdateSeatingInformation} />
                      </Button>
                    </>
                  ) : (
                    <Content id={ContentId.NoAutoPricing_NoVenueConfig} />
                  ))
                )}
              </Stack>
            </DetailGroup>
          )}
        </SectionContent>
      </DetailSection>
      {showDetailedSettings && (
        <PricingProtection
          listing={listing as ListingDetails}
          disabled={disabled}
          isBulkEdit={isBulkEdit}
        />
      )}
      {showDetailedSettings &&
        (isBulkEdit || hasVenueMapInfo) &&
        showAutoPricingSettings && (
          <AdvancePricingSettings
            disabled={disabled}
            isBulkEdit={isBulkEdit}
            isStrategyEdit={isStrategyEdit}
          />
        )}
    </>
  );
};
