import React from 'react';
import { vars } from 'src/core/themes';
import { CrossIcon } from 'src/svgs/Viagogo';

import { CloseButton, ModalHeader as StyledModalHeader } from './Modal.styled';

export const ModalHeader = ({
  onClose,
  children,
  disabled,
}: {
  onClose?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}) => {
  return (
    <StyledModalHeader
      tag="div"
      toggle={disabled ? undefined : onClose}
      close={
        <CloseButton onClick={disabled ? undefined : onClose}>
          <CrossIcon size={vars.iconSize.l} withHoverEffect />
        </CloseButton>
      }
    >
      {children}
    </StyledModalHeader>
  );
};
