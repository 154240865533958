import { FormProvider } from 'react-hook-form';
import { ListingDetails } from 'src/WebApiController';

import { useListingDetailsContext } from '../../ListingDetailsModalV2Context';
import { AutoPricingSection } from './Sections/AutoPricingSection';

type ListingDetailsAutoPricingFormProps = {
  listing: ListingDetails;
};

export const ListingDetailsAutoPricingForm: React.FC<
  ListingDetailsAutoPricingFormProps
> = ({ listing }) => {
  const { methods, isLoading, onTabChange } = useListingDetailsContext();

  return (
    <FormProvider {...methods.autoPricing}>
      <AutoPricingSection
        listing={listing}
        disabled={isLoading}
        onSwitchToTab={onTabChange}
      />
    </FormProvider>
  );
};
