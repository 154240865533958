import { useMemo } from 'react';
import { useGetNoSelectedItemsLabel } from 'src/components/common/MultiSelect/useGetNoSelectedItemsLabel';
import { useContent } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { ContentId } from 'src/utils/constants/contentId';

interface UseInventorySelectedLegendProps {
  singleElementLabel: string;
  multipleElementsLabel: string;
}

export const useEventsAccordionSelectedLegend = ({
  singleElementLabel,
  multipleElementsLabel,
}: UseInventorySelectedLegendProps): string => {
  const {
    selectionMode,
    getSelection,
    getGroupSelectableGroupMetadata,
    totalItemsSelected,
  } = useMultiSelectionContext();

  // How to read selection:
  //  {
  //   isSingleGroupMultiSelect: false,
  //   groupIds: ['152176600'], // Full selected groupIds
  //   items: { // Partially selected groupIds and items
  //     negative items mean subgroups. Don't count them
  //     itemIds: ['65300', '65843', '-1984169708', '-1984169705'],
  //     groupIds: ['152159378'],
  //   },
  // };

  const eventText = useContent(ContentId.Event);
  const eventsText = useContent(ContentId.Events);
  const selectedText = useContent(ContentId.Selected);
  const selectedFromText = useContent(ContentId.SelectedFrom);
  const noItemsSelected = useGetNoSelectedItemsLabel();
  const isAllGroupsMode = selectionMode?.mode === MultiSelectScope.AllGroups;

  return useMemo<string>(() => {
    if (!totalItemsSelected) {
      return noItemsSelected;
    }

    const selection = getSelection();

    const eventsCount = new Set([
      ...selection.groupIds,
      ...selection.items.groupIds,
    ]).size;

    let listingsCount = 0;

    const singleSelectedItems = new Set(
      selection.items.itemIds.filter((itemId) => parseInt(itemId) > 0) ?? []
    );

    listingsCount += singleSelectedItems.size;

    selection.groupIds.forEach((groupId) => {
      const g = getGroupSelectableGroupMetadata(groupId);
      listingsCount += g?.itemCount ?? 0;
    });

    const selectedEventsLabel = eventsCount === 1 ? eventText : eventsText;

    const selectedListingsLabel =
      listingsCount === 1 ? singleElementLabel : multipleElementsLabel;

    return (
      isAllGroupsMode
        ? `(${listingsCount} ${selectedListingsLabel} ${selectedFromText} ${eventsCount} ${selectedEventsLabel})`
        : `(${listingsCount} ${selectedListingsLabel} ${selectedText})`
    ).toLocaleLowerCase();
  }, [
    totalItemsSelected,
    getSelection,
    eventText,
    eventsText,
    singleElementLabel,
    multipleElementsLabel,
    isAllGroupsMode,
    selectedFromText,
    selectedText,
    noItemsSelected,
    getGroupSelectableGroupMetadata,
  ]);
};
