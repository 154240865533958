import { SortingState, TableOptions } from '@tanstack/react-table';
import { isEmpty } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useGetSalesByEventId } from 'src/hooks/api/useGetSalesByEventId';
import { NoData, Table } from 'src/tables/Table';
import { ListingTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { SalesTableColumnId } from 'src/utils/columns/sales/salesColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { Sale, SectionInfo, UserSetting } from 'src/WebApiController';

import { getColumnDefinitions } from './InventoryEventPageSales.constants';

type InventoryEventPageSalesProps = {
  viagVirtualId: string;
  selectedSections: SectionInfo[];
};

export const InventoryEventPageSales: React.FC<
  InventoryEventPageSalesProps
> = ({ viagVirtualId, selectedSections }: InventoryEventPageSalesProps) => {
  const { loginContext } = useAppContext();
  const { sales, isLoading } = useGetSalesByEventId(viagVirtualId);
  const [sorting, setSorting] = useState<SortingState>([
    { id: SalesTableColumnId.OrderDate, desc: true },
  ]);

  const marketplaceSettings = useMemo(
    () => loginContext?.user?.activeAccount?.marketplaceSettings ?? [],
    [loginContext?.user?.activeAccount?.marketplaceSettings]
  );

  const filteredEventSales = useMemo(() => {
    if (isEmpty(selectedSections)) {
      return sales;
    }
    const sectionIds = new Set(selectedSections.map(({ id }) => id));
    const sectionNames = new Set(selectedSections.map(({ name }) => name));
    const rowIds = new Set<number>(
      selectedSections.flatMap(({ rows }) => rows.map(({ id }) => id))
    );
    return sales?.filter(({ seating }) => {
      return (
        (seating?.sectionId && sectionIds.has(seating?.sectionId)) ||
        (seating?.section && sectionNames.has(seating?.section)) ||
        (seating?.rowId && rowIds.has(seating.rowId))
      );
    });
  }, [sales, selectedSections]);

  const options: Partial<TableOptions<Sale | null>> = useMemo(
    () => ({
      data: filteredEventSales,
      columns: getColumnDefinitions(marketplaceSettings),
      state: {
        sorting,
      },
      onSortingChange: setSorting,
    }),
    [filteredEventSales, marketplaceSettings, sorting]
  );

  if (isLoading) {
    return <PosSpinner />;
  }

  return !isEmpty(filteredEventSales) ? (
    <ColumnResizingContextProvider<ListingTableColumnId>
      userSettingId={UserSetting.InventoryColumnWidths}
    >
      <Table options={options} tableLayout="fixed" withOuterPadding />
    </ColumnResizingContextProvider>
  ) : (
    // We should never see this - because the events are filtered to only those that has sales
    // So this is to prevent a crash - but this should be looked at
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={<Content id={ContentId.Sale} />}
      />
    </NoData>
  );
};
