import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const DnDFileUploaderLabel = styled.label`
  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `padding: ${vars.spacing.xl};`
    )}`}
`;

export const SVGWrapper = styled.div`
  margin-bottom: ${() => `${vars.spacing.m}`};
`;
