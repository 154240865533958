import { PaymentsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/payment-wrapper';

export function GeneralIcon(props: PaymentsProps) {
  return (
    <SvgWrapper {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          x={0.5}
          y={0.5}
          width={35}
          height={23}
          rx={4}
          fill="#FFF"
          stroke="#CFD4D9"
        />
        <path fill="#677383" d="M1 5h34v5H1zM6 14h15v3H6z" />
      </g>
    </SvgWrapper>
  );
}
