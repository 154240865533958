import { useAppContext } from 'src/contexts/AppContext';
import { Marketplace } from 'src/WebApiController';

export const useMarketplaceHandledByProcessor = (
  marketplace?: Marketplace | null
) => {
  const { loginContext } = useAppContext();

  const isMarketplaceHandledByProcessor =
    marketplace &&
    loginContext?.user?.activeAccount?.marketplaceSettings?.some(
      (mk) => mk.isSyncHandledByProcessor && mk.mkp === marketplace
    );

  return isMarketplaceHandledByProcessor;
};

export const useAnyMarketplaceHandledByProcessor = () => {
  const { loginContext } = useAppContext();

  const isMarketplaceHandledByProcessor =
    loginContext?.user?.activeAccount?.marketplaceSettings?.some(
      (mk) => mk.isSyncHandledByProcessor
    );

  return isMarketplaceHandledByProcessor;
};
