import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { ModalProps } from 'src/modals/Modal';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';

import { UploadBarcodesContent } from './UploadBarcodes';

export type ViewBarcodeModalProps = {
  cancelTo?: ModalProps;
  defaultToBulk?: boolean;
  isReadOnly?: boolean;
  loadingContentId: FormatContentId;
  searchingContentId: FormatContentId;
  errorContentId: FormatContentId;
};

export const ViewBarcodes = ({
  cancelTo,
  loadingContentId,
  searchingContentId,
  errorContentId,
}: ViewBarcodeModalProps) => {
  const { loadingState } = useEventItemLoadingDisplay<EntityWithRealTickets>(
    loadingContentId,
    searchingContentId,
    errorContentId
  );

  return (
    loadingState || <UploadBarcodesContent cancelTo={cancelTo} isReadOnly />
  );
};
