import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { Button } from 'src/core/ui/Button';
import { ReportBuilderDialogV2 } from 'src/dialogs/ReportBuilderDialog/ReportBuilderDialogV2';
import { useGetReportTableColumnIdMapping } from 'src/dialogs/ReportBuilderDialog/useGetReportTableColumnIdMapping';
import { ShareReportDialog } from 'src/dialogs/ShareReportDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import {
  REPORT_CONFIG_V2_SCHEMA_VERSION,
  ReportConfigV2,
  useReportConfigsV2,
} from 'src/hooks/useReportConfigsV2';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ListingReportTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { SalesReportTableColumnId } from 'src/utils/columns/sales/salesColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import { GridActionType } from 'src/utils/tableUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { Permission, ReportColumn } from 'src/WebApiController';

import * as styles from './ReportsTableContainer.css';
import { ReportsTableV2 } from './ReportsTableV2';

const emptyReport: ReportConfigV2 = {
  schemaVersion: REPORT_CONFIG_V2_SCHEMA_VERSION,
  reportId: null,
  reportName: '',
  request: {
    groupings: [],
    aggregations: [],
    orderBy: '',
    orderByAsc: true,
    pageNumber: 0,
  } as any,
  reportType: ReportTypesV2.InventoryV2,
  ownerDisplayName: '',
  widgets: null,
  isOwner: true,
  roleIdsToShare: [] as number[],
  sellerUserIdsToShare: [] as string[],
  hasMetricsOverride: null,
  globalReportTypeId: null,
  viewableFilterItemIds: null,
  editableFilterItemIds: null,
  hiddenFilterItemIds: null,
};

export function ReportsTableContainerV2({
  reports,
}: {
  reports?: ReportConfigV2[];
}) {
  const canCreateReport = useUserHasAnyOfPermissions(
    Permission.Reports_CreateReports
  );

  const navigate = useNavigate();
  const { loginContext } = useAppContext();
  const reportBuilderDialogInventory = useBasicDialog();
  const reportBuilderDialogSale = useBasicDialog();
  const deleteDialog = useBasicDialog();
  const shareDialog = useBasicDialog();
  const reportHistoryDialog = useBasicDialog();
  const [currentReport, setCurrentReport] = useState<ReportConfigV2>();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    deleteReportConfig: deleteReportConfigInventory,
    upsertReportConfig: upsertReportConfigInventory,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateInventory,
  } = useReportConfigsV2<ReportTypesV2.InventoryV2>({
    reportType: ReportTypesV2.InventoryV2,
  });

  const {
    deleteReportConfig: deleteReportConfigSale,
    upsertReportConfig: upsertReportConfigSale,
    getReportConfigNameForDuplicate: getReportConfigNameForDuplicateSale,
  } = useReportConfigsV2<ReportTypesV2.SaleV2>({
    reportType: ReportTypesV2.SaleV2,
  });

  const {
    listingReportColumnIdToGroupingMappingQuery: {
      data: listingReportColumnIdToGrouping,
    },
    listingReportColumnIdToAggregateMappingQuery: {
      data: listingReportColumnIdToAggregate,
    },
    saleReportColumnIdToGroupingMappingQuery: {
      data: saleReportColumnIdToGrouping,
    },
    saleReportColumnIdToAggregateMappingQuery: {
      data: saleReportColumnIdToAggregate,
    },
  } = useGetReportTableColumnIdMapping();

  const defaultInventoryGroupings: ReportColumn[] = useMemo(() => {
    if (!listingReportColumnIdToGrouping) {
      return [];
    }

    const hasEventMapping =
      listingReportColumnIdToGrouping[ListingReportTableColumnId.Event] !==
      undefined;
    if (hasEventMapping) {
      return [
        {
          columnName: ListingReportTableColumnId.Event,
          columnValue:
            listingReportColumnIdToGrouping[ListingReportTableColumnId.Event],
        },
      ];
    } else {
      const [columnName, columnValue] = Object.entries(
        listingReportColumnIdToGrouping
      )[0];
      return [
        {
          columnName,
          columnValue,
        },
      ];
    }
  }, [listingReportColumnIdToGrouping]);

  const defaultSaleGroupings: ReportColumn[] = useMemo(() => {
    if (!saleReportColumnIdToGrouping) {
      return [];
    }

    const hasEventMapping =
      saleReportColumnIdToGrouping[SalesReportTableColumnId.Event] !==
      undefined;
    if (hasEventMapping) {
      return [
        {
          columnName: SalesReportTableColumnId.Event,
          columnValue:
            saleReportColumnIdToGrouping[SalesReportTableColumnId.Event],
        },
      ];
    } else {
      const [columnName, columnValue] = Object.entries(
        saleReportColumnIdToGrouping
      )[0];
      return [
        {
          columnName,
          columnValue,
        },
      ];
    }
  }, [saleReportColumnIdToGrouping]);

  const defaultInventoryAggregations: ReportColumn[] = useMemo(
    () =>
      Object.entries(listingReportColumnIdToAggregate ?? {}).map(
        ([columnName, columnValue]) => ({
          columnName,
          columnValue,
        })
      ),
    [listingReportColumnIdToAggregate]
  );

  const defaultSaleAggregations: ReportColumn[] = useMemo(
    () =>
      Object.entries(saleReportColumnIdToAggregate ?? {}).map(
        ([columnName, columnValue]) => ({
          columnName,
          columnValue,
        })
      ),
    [saleReportColumnIdToAggregate]
  );

  const onAddNewReport = useCallback(
    (reportType: ReportTypesV2) => {
      const defaultGroupings =
        reportType === ReportTypesV2.InventoryV2
          ? defaultInventoryGroupings
          : defaultSaleGroupings;
      const defaultAggregations =
        reportType === ReportTypesV2.InventoryV2
          ? defaultInventoryAggregations
          : defaultSaleAggregations;

      setCurrentReport({
        ...emptyReport,
        reportType,
        ownerDisplayName: loginContext?.user?.displayName ?? '',
        request: {
          ...emptyReport.request,
          groupings: defaultGroupings,
          aggregations: defaultAggregations,
          orderBy: defaultGroupings[0].columnName,
          orderByAsc: true,
        } as any,
      });

      if (reportType === ReportTypesV2.InventoryV2) {
        reportBuilderDialogInventory.launchDialog();
      } else if (reportType === ReportTypesV2.SaleV2) {
        reportBuilderDialogSale.launchDialog();
      }
    },
    [
      defaultInventoryAggregations,
      defaultInventoryGroupings,
      defaultSaleAggregations,
      defaultSaleGroupings,
      loginContext?.user?.displayName,
      reportBuilderDialogInventory,
      reportBuilderDialogSale,
    ]
  );

  const invokeUpsertReport = useCallback(
    async (report: ReportConfigV2) => {
      await tryInvokeApi(
        async () => {
          if (report.reportType === ReportTypesV2.InventoryV2) {
            upsertReportConfigInventory(report.reportId, report);
          } else if (report.reportType === ReportTypesV2.SaleV2) {
            upsertReportConfigSale(report.reportId, report);
          }
        },
        (error) => {
          showErrorDialog('ReportV2Client.mergeReport', error, {
            trackErrorData: report,
          });
        }
      );
    },
    [showErrorDialog, upsertReportConfigInventory, upsertReportConfigSale]
  );

  const onReportSaveSubmit = useCallback(
    async (report: ReportConfigV2) => {
      await invokeUpsertReport(report);
      if (report.reportType === ReportTypesV2.InventoryV2) {
        reportBuilderDialogInventory.closeDialog();
      } else if (report.reportType === ReportTypesV2.SaleV2) {
        reportBuilderDialogSale.closeDialog();
      }
    },
    [reportBuilderDialogInventory, reportBuilderDialogSale, invokeUpsertReport]
  );

  const reportsDefault = reports?.filter((r) => r.globalReportTypeId) ?? [];
  const reportsCustom = reports?.filter((r) => !r.globalReportTypeId) ?? [];

  const onReportAction = (
    reportConfig: ReportConfigV2,
    actionType: GridActionType,
    event?: React.MouseEvent
  ) => {
    switch (actionType) {
      case GridActionType.Select:
        if (reportConfig.reportId) {
          const navUrl =
            reportConfig.reportType === ReportTypesV2.InventoryV2
              ? `/reports/inventory/v2/${reportConfig.reportId}`
              : `/reports/sale/v2/${reportConfig.reportId}`;
          if (event?.metaKey || event?.ctrlKey) {
            // Open in new tab when holding down the meta/control key
            window.open(navUrl, '_blank', 'ref=noopener noreferrer');
          } else {
            navigate(navUrl);
          }
        }
        break;
      case GridActionType.Edit:
        setCurrentReport(reportConfig);
        if (reportConfig.reportType === ReportTypesV2.InventoryV2) {
          reportBuilderDialogInventory.launchDialog();
        } else if (reportConfig.reportType === ReportTypesV2.SaleV2) {
          reportBuilderDialogSale.launchDialog();
        }
        break;
      case GridActionType.Delete:
        setCurrentReport(reportConfig);
        deleteDialog.launchDialog();
        break;
      case GridActionType.Share:
        setCurrentReport(reportConfig);
        shareDialog.launchDialog();
        break;
      case GridActionType.Duplicate:
        invokeUpsertReport({
          ...reportConfig,
          reportId: null,
          reportName:
            reportConfig!.reportType === ReportTypesV2.InventoryV2
              ? getReportConfigNameForDuplicateInventory(
                  reportConfig.reportName
                )
              : getReportConfigNameForDuplicateSale(reportConfig!.reportName),
          isOwner: true,
          globalReportTypeId: null,
        });
        break;
      case GridActionType.History:
        setCurrentReport(reportConfig);
        reportHistoryDialog.launchDialog();
        break;
      default:
        break;
    }
  };

  const onReportDelete = useCallback(async () => {
    await tryInvokeApi(
      async () => {
        if (currentReport) {
          if (currentReport.reportType === ReportTypesV2.InventoryV2) {
            deleteReportConfigInventory(currentReport.reportId!);
          } else if (currentReport.reportType === ReportTypesV2.SaleV2) {
            deleteReportConfigSale(currentReport.reportId!);
          }
        }
        deleteDialog.closeDialog();
      },
      (error) => {
        showErrorDialog('UserSetting.deleteReport', error, {
          trackErrorData: currentReport,
        });
      }
    );
  }, [
    currentReport,
    deleteDialog,
    deleteReportConfigInventory,
    deleteReportConfigSale,
    showErrorDialog,
  ]);

  const onReportShareSubmit = useCallback<
    ComponentProps<typeof ShareReportDialog>['onOkay']
  >(
    async (roleIds, sellerUserIds) => {
      const updatedReport = {
        ...currentReport!,
        roleIdsToShare: roleIds,
        sellerUserIdsToShare: sellerUserIds,
      };
      await invokeUpsertReport(updatedReport);
      shareDialog.closeDialog();
    },
    [currentReport, invokeUpsertReport, shareDialog]
  );

  return (
    <>
      <div className={styles.mainContent}>
        <div className={styles.topRow}>
          {/* no need to translate - once we release - we will need to use ContentId.Report again */}
          <h1 className={styles.pageName}>Reports V2 (In development)</h1>
          {canCreateReport && (
            <div className={styles.tabsContainer}>
              <PosDropdown
                key="create-report-dropdown"
                trigger={
                  <Button style={{ height: 'min-content', width: '100%' }}>
                    <Content id={ContentId.CreateNewReport} />
                  </Button>
                }
                align="end"
              >
                <PosDropdownItem
                  key="AddInventoryReport"
                  onClick={() => onAddNewReport(ReportTypesV2.InventoryV2)}
                >
                  <Content id={ContentId.CreateNewInventoryReport} />
                </PosDropdownItem>
                <PosDropdownItem
                  key="AddSalesReport"
                  onClick={() => onAddNewReport(ReportTypesV2.SaleV2)}
                >
                  <Content id={ContentId.CreateNewSaleReport} />
                </PosDropdownItem>
              </PosDropdown>
            </div>
          )}
        </div>
        <div className={styles.reportTableContainer}>
          {reportsDefault.length > 0 && (
            <Stack direction="column">
              <h2
                className={styles.subHeader}
                style={{
                  paddingTop: vars.spacing['lg'],
                  paddingBottom: vars.spacing['lg'],
                }}
              >
                <Content id={ContentId.DefaultReports} />
              </h2>
              <ReportsTableV2
                reports={reportsDefault}
                onReportAction={onReportAction}
                isGlobalSection
                initState={{
                  sorting: [{ id: 'reportName', desc: false }],
                }}
              />
            </Stack>
          )}

          <Stack direction="column">
            <h2
              className={styles.subHeader}
              style={{
                paddingTop: vars.spacing['xl'],
                paddingBottom: vars.spacing['lg'],
              }}
            >
              <Content id={ContentId.CustomReports} />
            </h2>
            <ReportsTableV2
              reports={reportsCustom}
              onReportAction={onReportAction}
              initState={{
                sorting: [{ id: 'reportName', desc: false }],
              }}
            />
          </Stack>
        </div>
      </div>

      {currentReport && (
        <>
          <ReportBuilderDialogV2<ReportTypesV2.InventoryV2>
            {...reportBuilderDialogInventory.dialogProps}
            unmountOnClose
            report={currentReport}
            onClosed={() => {
              setCurrentReport(undefined);
              reportBuilderDialogInventory.closeDialog();
            }}
            onSave={onReportSaveSubmit}
          />
          <ReportBuilderDialogV2<ReportTypesV2.SaleV2>
            {...reportBuilderDialogSale.dialogProps}
            unmountOnClose
            report={currentReport}
            onClosed={() => {
              setCurrentReport(undefined);
              reportBuilderDialogSale.closeDialog();
            }}
            onSave={onReportSaveSubmit}
          />
          <ShareReportDialog
            {...shareDialog.dialogProps}
            reportName={currentReport?.reportName ?? ''}
            roleIdsShared={currentReport?.roleIdsToShare ?? []}
            sellerUserIdsShared={currentReport?.sellerUserIdsToShare ?? []}
            onOkay={onReportShareSubmit}
            onClosed={() => {
              setCurrentReport(undefined);
              shareDialog.closeDialog();
            }}
          />
        </>
      )}
      <ConfirmDialog
        {...deleteDialog.dialogProps}
        headerText={<Content id={ContentId.DeleteReport} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        onOkay={onReportDelete}
        onCancel={deleteDialog.closeDialog}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
      />
      {/* <ReportHistoryDialog
        {...reportHistoryDialog.dialogProps}
        reportConfig={currentReport}
        onClosed={() => {
          setCurrentReport(undefined);
          reportHistoryDialog.closeDialog();
        }}
      /> */}
    </>
  );
}
