import clsx from 'clsx';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FieldError } from 'src/modals/common';
import { CheckIcon, IconsFill } from 'src/svgs/Viagogo';
import { DELIVERY_OPTIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { getDeliveryTypeIcon } from 'src/utils/ticketTypeUtils';
import { DeliveryType, Feature } from 'src/WebApiController';

import * as styles from './DeliveryTypeRadioInput.css';

export const DeliveryTypeRadioInput = ({
  value,
  onChange,
  error,
  disabled,
  hideCustomType,
}: {
  value?: DeliveryType | null;
  onChange: (val: DeliveryType) => void;
  error?: string;
  disabled?: boolean;
  hideCustomType?: boolean;
}) => {
  const hasCustomDeliveryTypeFeature = useUserHasFeature(
    Feature.CustomDeliveryType
  );

  return (
    <Stack direction="column" gap="m" width="full">
      <div className={styles.radioContainer}>
        {Object.entries(DeliveryType)
          .filter(([_, val]) => {
            if (val === DeliveryType.Custom) {
              return hasCustomDeliveryTypeFeature && !hideCustomType;
            }
            else if (val === DeliveryType.Wallet)
            {
                return false;
            }
            return true;
          })
          .map(([key, val]) => {
            const icon = getDeliveryTypeIcon(val);

            return (
              <Stack
                direction="column"
                gap="m"
                justifyContent="center"
                alignItems="center"
                className={clsx(styles.radioTile, {
                  [styles.radioTileSelected]: val === value && !disabled,
                  [styles.radioTileDisabled]: disabled,
                })}
                key={key}
                onClick={() => !disabled && onChange(val)}
              >
                <div className={styles.checkboxContainer}>
                  {val === value && <CheckIcon fill={IconsFill.textBrand} />}
                </div>
                <Stack
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gap="s"
                >
                  {icon}
                  <span>
                    <Content id={DELIVERY_OPTIONS_TO_CID[val]} />
                  </span>
                </Stack>
              </Stack>
            );
          })}
      </div>

      {error && <FieldError>{error}</FieldError>}
    </Stack>
  );
};
