import { ComponentProps, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import { AddAdminHoldInput } from './AddAdminHoldInput';

export type AddAdminHoldDialogProps = ComponentProps<typeof RSModal> & {
  isLoading?: boolean;
  onOkay: (
    adminHoldExpirationDate: Date | null,
    adminHoldNotes: string | null
  ) => void;
  onCancel: () => void;
};

export function AddAdminHoldDialog({
  isLoading,
  onOkay,
  onCancel,
  ...rest
}: AddAdminHoldDialogProps) {
  const [adminHoldExpirationDate, setAdminHoldExpirationDate] =
    useState<Date | null>(null);
  const [adminHoldNotes, setAdminHoldNotes] = useState<string | null>(null);

  return (
    <GenericDialog
      {...rest}
      size="m"
      header={<Content id={ContentId.PutOnHold} />}
      onOpened={() => {
        setAdminHoldExpirationDate(null);
        setAdminHoldNotes(null);
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      footer={
        <>
          <Button
            variant="outline"
            onClick={() => {
              onCancel();
            }}
          >
            <Content id={ContentId.Cancel} />
          </Button>

          <Button
            disabled={isLoading}
            onClick={() => {
              onOkay(adminHoldExpirationDate, adminHoldNotes);
            }}
          >
            <Content id={ContentId.Save} />
          </Button>
        </>
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <AddAdminHoldInput
        adminHoldExpirationDate={adminHoldExpirationDate}
        adminHoldNotes={adminHoldNotes}
        onAdminHoldExpirationDateChange={setAdminHoldExpirationDate}
        onAdminHoldNotesChange={setAdminHoldNotes}
        disabled={isLoading}
      />
    </GenericDialog>
  );
}
