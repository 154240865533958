import * as Select from '@radix-ui/react-select';
import { clsx } from 'clsx';
import {
  ComponentPropsWithoutRef,
  createContext,
  forwardRef,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { vars } from 'src/core/themes';
import {
  mergeProps,
  RADIX_COLLISION_PADDING,
  RADIX_SIDE_OFFSET,
} from 'src/core/utils';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { CheckIcon, ExpandIcon, FoldIcon, IconsFill } from 'src/svgs/Viagogo';

import { Button } from '../Button';
import * as styles from './Select.css';

const SelectContext = createContext<{
  isDefaultValueSelected: boolean;
}>({
  /**
   * Whether the default value is selected.
   */
  isDefaultValueSelected: true,
});

export function Root(props: ComponentPropsWithoutRef<typeof Select.Root>) {
  const [isDefaultValueSelected, setIsDefaultValueSelected] = useState<boolean>(
    (props.value ?? props.defaultValue) === props.defaultValue
  );
  return (
    <SelectContext.Provider value={{ isDefaultValueSelected }}>
      <Select.Root
        {...mergeProps(
          {
            onValueChange: (value) => {
              setIsDefaultValueSelected(value === props.defaultValue);
            },
          },
          props
        )}
      />
    </SelectContext.Provider>
  );
}

export const Trigger = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithoutRef<typeof Button> & { hideFoldButton?: boolean }
>(function Trigger(
  {
    children,
    variant = 'outlineGray',
    textColor = 'strong',
    shape = 'rect',
    hideFoldButton,
    ...props
  },
  ref
) {
  const { isDefaultValueSelected } = useContext(SelectContext);
  return (
    <Select.Trigger asChild>
      <Button
        ref={ref}
        data-default-value={isDefaultValueSelected ? '' : undefined}
        variant={variant}
        textColor={textColor}
        shape={shape}
        justifyContent="space-between"
        {...mergeProps({ className: styles.trigger }, props)}
      >
        {children}
        {!hideFoldButton && (
          <>
            <Select.Icon className={styles.iconClosed}>
              <ExpandIcon
                size={vars.iconSize.xs}
                fill={
                  textColor === 'inverted'
                    ? IconsFill.textInverted
                    : IconsFill.textPrimary
                }
              />
            </Select.Icon>
            <Select.Icon className={styles.iconOpen}>
              <FoldIcon
                size={vars.iconSize.xs}
                fill={
                  textColor === 'inverted'
                    ? IconsFill.textInverted
                    : IconsFill.textPrimary
                }
              />
            </Select.Icon>
          </>
        )}
      </Button>
    </Select.Trigger>
  );
});

export const Value = forwardRef<
  HTMLSpanElement,
  ComponentPropsWithoutRef<typeof Select.Value>
>(({ className, style, ...props }, ref) => {
  return (
    <span className={clsx(styles.valueWrapper, className)} style={style}>
      <Select.Value ref={ref} {...props} />
    </span>
  );
});
Value.displayName = 'Value';

export const Content = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof Select.Content>
>(function Content({ children, ...props }, ref) {
  // This is for known issue:
  // Selecting an option triggers a touch event on elements positioned behind
  // https://github.com/radix-ui/primitives/issues/1658
  // It only happens on mobile and has no official solution yet
  // This is a workaround adapted from https://github.com/radix-ui/primitives/issues/1658#issuecomment-1695494301
  const isMobile = useMatchMedia('mobile');
  const contentRef = useRef<HTMLDivElement | null>(null);
  const onTouchEnd = (e: TouchEvent) => e.preventDefault();
  useEffect(() => {
    return () => {
      if (isMobile)
        contentRef.current?.removeEventListener('touchend', onTouchEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select.Portal>
      <Select.Content
        ref={(elem) => {
          if (ref != null) {
            if (typeof ref === 'function') {
              ref(elem);
            } else {
              ref.current = elem;
            }
          }
          if (isMobile) {
            elem?.addEventListener('touchend', onTouchEnd);
            contentRef.current = elem;
          }
        }}
        position="popper"
        sideOffset={RADIX_SIDE_OFFSET}
        collisionPadding={RADIX_COLLISION_PADDING}
        {...mergeProps({ className: styles.content }, props)}
      >
        <Select.ScrollUpButton className={styles.scrollButton}>
          <FoldIcon size={vars.iconSize.xs} />
        </Select.ScrollUpButton>
        <Select.Viewport>{children}</Select.Viewport>
        <Select.ScrollDownButton className={styles.scrollButton}>
          <ExpandIcon size={vars.iconSize.xs} />
        </Select.ScrollDownButton>
      </Select.Content>
    </Select.Portal>
  );
});

export const Item = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof Select.Item> & { prefixIcon?: ReactNode }
>(function Item({ children, prefixIcon, disabled, ...props }, ref) {
  return (
    <Select.Item
      ref={ref}
      {...mergeProps(
        { className: disabled ? styles.itemDisabled : styles.item },
        props
      )}
      disabled={disabled}
    >
      {prefixIcon ? (
        <div className={styles.iconContainer}>
          <Select.Icon className={styles.icon}>{prefixIcon}</Select.Icon>
          <Select.ItemText>{children}</Select.ItemText>
        </div>
      ) : (
        <Select.ItemText>{children}</Select.ItemText>
      )}
      <Select.ItemIndicator className={styles.icon}>
        <CheckIcon size={vars.iconSize.s} fill={IconsFill.textSuccess} />
      </Select.ItemIndicator>
    </Select.Item>
  );
});
