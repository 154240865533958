import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { format, startOfDay, startOfMonth } from 'date-fns';
import { useCallback, useContext, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { EventDateDisplay } from 'src/components/Events/EventInfo';
import { TagValueDisplay } from 'src/components/TagsFormBody/TagValueCell';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_SM,
  COLUMN_DEFAULT_SIZE_XL,
  COLUMN_DEFAULT_SIZE_XS,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import {
  Content,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import {
  NO_GROUP_ID,
  TableRow,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { HorizontalTextList } from 'src/core/POS/HorizontalTextList';
import * as PosCollapsible from 'src/core/POS/PosCollapsible';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { CopyToClipboardV2 } from 'src/core/ui/CopyToClipboard/CopyToClipboardV2';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { useMapDeliveryTypesToString } from 'src/hooks/useMapDeliveryTypesToString';
import { getUserDisplayName, useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { FormatOption, FormatOptionEntries } from 'src/modals/EditTableColumns';
import { FormatOption_Seating } from 'src/modals/EditTableColumns';
import { LaunchDownloadUserDocuments } from 'src/modals/PurchaseWizard/LaunchDownloadUserDocuments';
import { PredeliveryActionsDropdown } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/components/PredeliveryActionsDropdown';
import { UploadPredeliveryAction } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/purchaseTicketsFlow.utils';
import { TicketGroupEditContext } from 'src/modals/PurchaseWizard/PurchaseWizard.types';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { OpenLinkIcon } from 'src/svgs';
import {
  EditIcon,
  ExpandIcon,
  ForwardIcon,
  IconsFill,
  PaymentSize,
  UploadIcon,
} from 'src/svgs/Viagogo';
import { SEATING_SORTING_FN_NAME } from 'src/tables/common/hooks/useSeatingColumnSorting';
import { CustomNumericCell } from 'src/tables/ListingTable/configs/CustomNumericCell';
import { PurchaseActionsCell } from 'src/tables/PurchaseTable/configs/PurchaseActionsCell';
import { SeatInfoContainer, TableShimmeringDiv } from 'src/tables/Table';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PURCHASE_PAYMENT_STATUS_TO_CID,
  PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID,
  QUALITY_CONTROL_STATE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { isInFullPageEventView } from 'src/utils/deepLinkUtils';
import {
  EVENT_TYPE_PARKING,
  getEventPerformerVenue,
  lookupEventInCatalog,
} from 'src/utils/eventWithDataUtils';
import { getListingNoteIcon } from 'src/utils/listingNotesUtils';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { getPaymentMethodIcon } from 'src/utils/paymentMethodUtils';
import { posField } from 'src/utils/posFieldUtils';
import {
  combineWithTicketGroupId,
  formatConversionRate,
  getPurchaseOrderDeepLinkUrl,
  getPurchaseOrderDetailsModalConfigWithDeepLink,
  getPurchaseOrderRelativeUrl,
} from 'src/utils/purchaseUtils';
import { sortGeneric, sortMoney } from 'src/utils/tableUtils';
import { getDeliveryTypeIcon } from 'src/utils/ticketTypeUtils';
import {
  SECTION_TYPE_TO_PRECISION_SETTING,
  SectionType,
} from 'src/utils/types/sectionType';
import {
  DeliveryType,
  Event,
  Feature,
  PurchaseOrderDetails,
  PurchaseOrderTicketGroup,
  PurchasePaymentStatus,
  QualityControlState,
  Seating,
  TagsValueType,
  TicketGroupEditReason,
} from 'src/WebApiController';

import { SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE } from '../PurchaseTable.types';
import { PurchaseIdCell } from './PurchaseIdCell';
import * as styles from './PurchaseTableColumnsConfig.css';

const getSeating = (
  purchaseOrderTicketGroup: PurchaseOrderTicketGroup | null
): Seating | null => {
  if (!purchaseOrderTicketGroup) {
    return null;
  }
  const ticketGroupToGetSeating = purchaseOrderTicketGroup.isTktGrp
    ? purchaseOrderTicketGroup
    : purchaseOrderTicketGroup.ticketGroupItems?.[0];

  if (ticketGroupToGetSeating?.seating == null) {
    return null;
  }

  return ticketGroupToGetSeating.seating;
};

const columnHelper = createColumnHelper<PurchaseOrderTicketGroup | null>();

export const PURCHASE_TABLE_COLUMNS_CONFIG: ColumnDef<
  PurchaseOrderTicketGroup | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>[] = [
  columnHelper.accessor((data) => (data ? data : null), {
    id: PurchasesTableColumnId.Checkbox,
    enableSorting: false,
    enableResizing: false,
    header: '',
    size: COLUMN_DEFAULT_SIZE_SM,
    minSize: COLUMN_DEFAULT_SIZE_SM,
    maxSize: COLUMN_DEFAULT_SIZE_SM,
    cell: function Cell({
      row: { id, original, getIsSelected, depth, getCanSelectSubRows },
      table,
    }) {
      const { setShiftKeyRowSelectionState, isNoGroupIdSet } =
        useMultiSelectionContext();
      if (!original || !original.poDate) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      // We don't want to display this if the row is a ticket group
      if (
        original.isTktGrp &&
        table.options.meta?.sectionType !== SectionType.PurchaseEvent &&
        table.options.meta?.sectionType !== SectionType.PurchaseSideTable
      ) {
        return null;
      }

      const canSelect = getCanSelectSubRows() || depth === 0;
      return canSelect ? (
        <TableCellDiv
          align="left"
          showTooltip={false}
          style={{ paddingLeft: vars.spacing.sm }}
        >
          <Checkbox
            checked={getIsSelected()}
            onChange={() => {
              if (!original.poDate) {
                return;
              }
              let groupId = NO_GROUP_ID; // List View
              if (!isNoGroupIdSet()) {
                // Event View (TicketGroups)
                if (
                  original.isTktGrp &&
                  original.tgId &&
                  original.viagVirtualId
                ) {
                  groupId = original.viagVirtualId;
                } else {
                  // Time View
                  const startOfMonthDate = startOfDay(
                    startOfMonth(stringToUtcDate(original.poDate))
                  );
                  groupId = format(startOfMonthDate, "yyyy-MM-dd'T00:00:00'");
                }
              }
              setShiftKeyRowSelectionState(
                id,
                table.getRowModel().rows as unknown as TableRow[],
                groupId
              );
            }}
          />
        </TableCellDiv>
      ) : null;
    },
  }),
  columnHelper.accessor((data) => (data ? data : null), {
    id: PurchasesTableColumnId.AccordianToggle,
    enableSorting: false,
    header: '',
    size: COLUMN_DEFAULT_SIZE_XS,
    minSize: COLUMN_DEFAULT_SIZE_XS,
    maxSize: COLUMN_DEFAULT_SIZE_XS,
    meta: {
      styleOverrides: { padding: 0 },
    },
    cell: ({
      row: { original, toggleExpanded, getCanExpand, getIsExpanded },
    }) => {
      const canExpand = getCanExpand();
      const isExpanded = getIsExpanded();
      const Icon = isExpanded ? ExpandIcon : ForwardIcon;

      if (original?.isTktGrp) {
        return null;
      }

      return (
        <TableCellDiv align="center" showTooltip={false}>
          <Stack direction="row" alignItems="center">
            {canExpand && (
              <Icon
                size={vars.iconSize.s}
                withHoverEffect
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleExpanded(!isExpanded);
                }}
              />
            )}
          </Stack>
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.poDate || '' : null), {
    id: PurchasesTableColumnId.PurchaseDate,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.PurchaseDate
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    enableSorting: true,
    cell: function Cell({
      getValue,
      row: { original },
      table: {
        options: { meta },
      },
      column,
    }) {
      const columnId = column.id;
      const value = getValue();
      const rowSupportsAccordion = meta?.rowSupportsAccordion;
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        meta?.sectionType || SectionType.Purchases
      );
      const { timeZone } = useSiteTimezoneContext();

      const date = applyNumberFormatting({
        precision: userDefinedPrecision,
        inputNumber: value,
        isDateTime: true,
        enableUtcFallback: true,
        timeZone,
      }) as string;
      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      // We don't want to display this if the row is a ticket group
      if (
        original.isTktGrp &&
        meta?.sectionType !== SectionType.PurchaseEvent &&
        meta?.sectionType !== SectionType.PurchaseSideTable
      ) {
        return null;
      }

      return (
        <TableCellDiv align="left">
          <Stack direction="row" gap="m" alignItems="center">
            {rowSupportsAccordion && (
              <PosCollapsible.Trigger
                onClick={(e) => e.stopPropagation()}
                className={styles.purchaseOrderTableCollapseTrigger}
                FoldIcon={ExpandIcon}
                ExpandIcon={ForwardIcon}
                iconSize={vars.iconSize.s}
              />
            )}
            {value == null ? (
              <Content id={ContentId.NA} />
            ) : (
              <div className={styles.styledCell}>{date}</div>
            )}
          </Stack>
        </TableCellDiv>
      );
    },
    sortingFn: 'datetime',
  }),
  columnHelper.accessor((data) => (data ? data : null), {
    id: PurchasesTableColumnId.VendorOrderId,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.VendorOrderId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original }, table }) => {
      // We don't want to display this if the row is a ticket group
      if (
        original?.isTktGrp &&
        table.options.meta?.sectionType !== SectionType.PurchaseEvent &&
        table.options.meta?.sectionType !== SectionType.PurchaseSideTable
      ) {
        return null;
      }

      const value = getValue();
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      const rawIdOnly =
        table.options.meta?.formatOptionOverrides?.[
          PurchasesTableColumnId.VendorOrderId
        ] === FormatOption.Id_RawIdOnly;

      return (
        <TableCellDiv align="right">
          <Stack
            gap="s"
            alignItems="center"
            justifyContent="end"
            width="full"
            height="full"
            style={{ wordBreak: 'break-all' }}
          >
            <CopyToClipboardV2 textToCopy={value.vendOrdId}>
              <PurchaseIdCell
                vendOrdId={value.vendOrdId}
                vendor={rawIdOnly ? undefined : value.vendor}
                poState={value.poState}
              />
            </CopyToClipboardV2>
          </Stack>
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.delivType : null), {
    id: PurchasesTableColumnId.Shipment,
    header: () => (
      <TableHeaderCellDiv align="center">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.Shipment
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const delivType = getValue<DeliveryType>();
      const strDelivType = useMapDeliveryTypesToString({
        deliveryTypesObj: {
          delivType,
        },
      });

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }
      if (!delivType) return null;
      const icon = getDeliveryTypeIcon(delivType, null, IconsFill.textStrong);
      return (
        <TableCellDiv
          align="center"
          title={strDelivType}
          className={styles.iconContainer}
        >
          {icon}
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.tktGrpCnt : null), {
    id: PurchasesTableColumnId.Orders,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.Orders
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_BASE,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original }, table }) => {
      const value = getValue();
      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      // We don't want to display this if the row is a ticket group
      if (
        original.isTktGrp &&
        table.options.meta?.sectionType !== SectionType.PurchaseEvent &&
        table.options.meta?.sectionType !== SectionType.PurchaseSideTable
      ) {
        return null;
      }
      return <TableCellDiv align="right">{value ?? 0}</TableCellDiv>;
    },
  }),
  columnHelper.accessor((data) => (data ? data.ticketCnt : null), {
    id: PurchasesTableColumnId.Tickets,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.Tickets
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_BASE,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue();
      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }
      return <TableCellDiv align="right">{value ?? 0}</TableCellDiv>;
    },
  }),
  columnHelper.accessor((data) => (data ? data.pmtMethod : null), {
    id: PurchasesTableColumnId.Payment,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.Payment
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original }, table }) => {
      const value = getValue();
      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      // We don't want to display this if the row is a ticket group
      if (
        original.isTktGrp &&
        table.options.meta?.sectionType !== SectionType.PurchaseEvent &&
        table.options.meta?.sectionType !== SectionType.PurchaseSideTable
      ) {
        return null;
      }
      if (!value) return null;
      const PurchaseMethodIcon = getPaymentMethodIcon(value);
      return (
        <TableCellDiv align="right" title={value}>
          <PurchaseMethodIcon size={PaymentSize.s} isSquare={false} />
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.totalCst : null), {
    id: PurchasesTableColumnId.Cost,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[PurchasesTableColumnId.Cost]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue }) => {
      const value = getValue();
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }
      return <TableCellDiv align="right">{value.disp}</TableCellDiv>;
    },
    sortingFn: sortMoney<PurchaseOrderTicketGroup>,
  }),
  columnHelper.accessor((data) => (data ? data.totalPmt : null), {
    id: PurchasesTableColumnId.TotalPayment,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.TotalPayment
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue();
      if (original == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }
      return <TableCellDiv align="right">{value?.disp}</TableCellDiv>;
    },
    sortingFn: sortMoney<PurchaseOrderTicketGroup>,
  }),
  columnHelper.accessor((data) => (data ? data.totalConvertedPmt : null), {
    id: PurchasesTableColumnId.TotalConvertedPayment,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.TotalConvertedPayment
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue();
      if (original == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }
      return <TableCellDiv align="right">{value?.disp}</TableCellDiv>;
    },
    sortingFn: sortMoney<PurchaseOrderTicketGroup>,
  }),
  columnHelper.accessor((data) => (data ? data.pmtConvRate : null), {
    id: PurchasesTableColumnId.PaymentConversionRate,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.PaymentConversionRate
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue();
      if (original == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }
      return (
        <TableCellDiv align="right">
          {formatConversionRate(
            value,
            original.totalPmt?.currency,
            original.totalConvertedPmt?.currency
          )}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<PurchaseOrderTicketGroup>,
  }),
  columnHelper.accessor((data) => (data ? data.pmtStat : null), {
    id: PurchasesTableColumnId.PaymentStatus,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.PaymentStatus
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original }, table }) => {
      // We don't want to display this if the row is a ticket group
      if (
        original?.isTktGrp &&
        table.options.meta?.sectionType !== SectionType.PurchaseEvent &&
        table.options.meta?.sectionType !== SectionType.PurchaseSideTable
      ) {
        return null;
      }

      const value = getValue();
      if (value == null) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      return (
        <TableCellDiv align="left">
          <Content
            id={PURCHASE_PAYMENT_STATUS_TO_CID[value as PurchasePaymentStatus]}
          />
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.qcState ?? null : undefined), {
    id: PurchasesTableColumnId.QualityControlState,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.QualityControlState
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original }, table }) => {
      // We don't want to display this if the row is a ticket group
      if (
        original?.isTktGrp &&
        table.options.meta?.sectionType !== SectionType.PurchaseEvent &&
        table.options.meta?.sectionType !== SectionType.PurchaseSideTable
      ) {
        return null;
      }

      const value = getValue() as QualityControlState;
      if (value === undefined) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }
      return (
        <TableCellDiv align="left">
          <Content
            id={
              value == null
                ? ContentId.Unset
                : QUALITY_CONTROL_STATE_TO_CID[value]
            }
          />
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.buyerId || '' : null), {
    id: PurchasesTableColumnId.BuyerUserId,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.BuyerUserId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    enableSorting: true,
    cell: function Cell({ getValue, row: { original }, table }) {
      const { allActiveUserInfos } = useSellerAccountContext();
      const sellerUserId = getValue();
      const displayNamesFormatted = useMemo(
        () =>
          sellerUserId ? allActiveUserInfos?.[sellerUserId]?.displayName : null,
        [allActiveUserInfos, sellerUserId]
      );
      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      // We don't want to display this if the row is a ticket group
      if (
        original.isTktGrp &&
        table.options.meta?.sectionType !== SectionType.PurchaseEvent &&
        table.options.meta?.sectionType !== SectionType.PurchaseSideTable
      ) {
        return null;
      }
      return <TableCellDiv align="left">{displayNamesFormatted}</TableCellDiv>;
    },
  }),
  columnHelper.display({
    id: PurchasesTableColumnId.Actions,
    enableSorting: false,
    enableResizing: true,
    header: function Cell() {
      const hasTablePinActionColumnFeature = useUserHasFeature(
        Feature.TablePinActionColumn
      );

      if (hasTablePinActionColumnFeature) {
        return null;
      }

      return (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Actions} />
        </TableHeaderCellDiv>
      );
    },
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ row: { original }, table }) {
      const { setActivePosEntity } =
        useActivePosEntityContext<PurchaseOrderDetails>();
      const { setModal } = useContext(ModalContext);
      const { mainRoute } = useAppContext();
      const navigate = useNavigate();

      const onAmendTickets = useCallback(
        (ticketGroupEditContext?: Partial<TicketGroupEditContext>) => {
          const ticketGroupId = original?.isTktGrp
            ? original!.tgId!
            : original?.ticketGroupItems?.[0]?.tgId;
          setActivePosEntity(original!.id, undefined, true);
          ticketGroupId &&
            setModal(
              getPurchaseOrderDetailsModalConfigWithDeepLink(
                combineWithTicketGroupId(original!.id, {
                  ...ticketGroupEditContext,
                  ticketGroupId,
                  editIntention: TicketGroupEditReason.AmendTickets,
                })
              )
            );
        },
        [original, setActivePosEntity, setModal]
      );

      const ticketGroupNavId = useMemo(() => {
        const ticketGroupId = original?.isTktGrp
          ? original!.tgId!
          : original?.ticketGroupItems?.[0]?.tgId;

        return (
          original &&
          ticketGroupId &&
          combineWithTicketGroupId(original.id, {
            ticketGroupId: ticketGroupId,
          })
        );
      }, [original]);

      const onViewTicketGroup = useCallback(
        (newTab: boolean) => {
          const inFullPageEventView = isInFullPageEventView();
          if (ticketGroupNavId) {
            if (newTab) {
              window.open(
                getPurchaseOrderDeepLinkUrl(ticketGroupNavId),
                '_blank'
              );
            } else if (
              mainRoute === MainRoute.Purchases ||
              inFullPageEventView
            ) {
              setActivePosEntity(original!.id, undefined, true);
              setModal(
                getPurchaseOrderDetailsModalConfigWithDeepLink(ticketGroupNavId)
              );
            } else {
              navigate(getPurchaseOrderRelativeUrl(ticketGroupNavId));
            }
          } else {
            if (newTab) {
              window.open(getPurchaseOrderDeepLinkUrl(original!.id), '_blank');
            } else {
              setActivePosEntity(original!.id, undefined, true);
              setModal(
                getPurchaseOrderDetailsModalConfigWithDeepLink(original!.id)
              );
            }
          }
        },
        [
          mainRoute,
          navigate,
          original,
          setActivePosEntity,
          setModal,
          ticketGroupNavId,
        ]
      );

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      const linkOnly =
        table.options.meta?.formatOptionOverrides?.[
          PurchasesTableColumnId.Actions
        ] === FormatOption.Actions_LinkOnly;

      let predeliveryActions = null;
      const ticketGroup = original.isTktGrp
        ? original
        : original.ticketGroupItems?.[0];

      if (!linkOnly && ticketGroup) {
        predeliveryActions = (
          <PredeliveryActionsDropdown
            triggerCmp={
              <IconButton
                icon={
                  <UploadIcon
                    withHoverEffect
                    size={vars.iconSize.m}
                    fill={IconsFill.textBrand}
                  />
                }
              />
            }
            ticketGroup={{ tickets: posField(ticketGroup.tickets) }}
            onPredeliveryAction={(
              uploadPredeliveryAction: UploadPredeliveryAction
            ) => {
              onAmendTickets({
                additionalData: {
                  amendActionData: {
                    uploadPredeliveryAction: uploadPredeliveryAction,
                  },
                },
              });
            }}
          />
        );
      }

      if (
        original.isTktGrp ||
        (table.options.meta?.sectionType !== SectionType.PurchaseEvent &&
          table.options.meta?.sectionType !== SectionType.PurchaseSideTable)
      ) {
        return (
          <TableCellDiv align="right" showTooltip={false}>
            <Stack
              direction="row"
              style={{
                padding: `${vars.spacing['xxs']} ${vars.spacing['sm']}`,
              }}
            >
              {!linkOnly && ticketGroupNavId && (
                <>
                  <IconButton
                    icon={<EditIcon withHoverEffect size={vars.iconSize.m} />}
                    onClick={() => onAmendTickets()}
                    titleContentId={ContentId.AmendTickets}
                  />
                  {ticketGroup?.canGetDocArts ? (
                    <LaunchDownloadUserDocuments
                      ticketGroupId={ticketGroup.tgId!}
                      iconOnlyMode
                    />
                  ) : (
                    predeliveryActions
                  )}
                </>
              )}
              <Link
                style={{
                  padding: `${vars.spacing['xxs']} 0`,
                }}
                to={getPurchaseOrderRelativeUrl(
                  ticketGroupNavId ?? original.id
                )}
                onClick={(e) => {
                  if (
                    e.button === 0 &&
                    e.getModifierState('Control') === false
                  ) {
                    // Perform like a button click when it's a left-click (and no Ctrl)
                    // Else the click will be whatever <a /> does
                    e.preventDefault();
                    e.stopPropagation();

                    onViewTicketGroup(!!linkOnly);
                  }
                }}
              >
                <IconButton
                  icon={
                    <OpenLinkIcon
                      size={vars.iconSize.m}
                      fill={vars.color.textBrand}
                      withHoverEffect
                    />
                  }
                  titleContentId={ContentId.ViewDetails}
                />
              </Link>
            </Stack>
          </TableCellDiv>
        );
      }
      return (
        <TableCellDiv align="right" showTooltip={false}>
          {original.ticketGroupItems?.length === 1 && (
            <>
              {ticketGroup?.canGetDocArts ? (
                <LaunchDownloadUserDocuments
                  ticketGroupId={ticketGroup.tgId!}
                  iconOnlyMode
                />
              ) : (
                predeliveryActions
              )}
            </>
          )}
          <PurchaseActionsCell
            purchaseOrderId={original.id}
            linkOnly={linkOnly}
          />
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.vendAcc?.email || '' : null), {
    id: PurchasesTableColumnId.VendorAccountEmail,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.VendorAccountEmail
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    enableSorting: true,
    cell: function Cell({ getValue, row: { original }, table }) {
      const vendorAccountEmail = getValue() as string;

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      // We don't want to display this if the row is a ticket group
      if (
        original.isTktGrp &&
        table.options.meta?.sectionType !== SectionType.PurchaseEvent &&
        table.options.meta?.sectionType !== SectionType.PurchaseSideTable
      ) {
        return null;
      }

      return <TableCellDiv align="left">{vendorAccountEmail}</TableCellDiv>;
    },
    sortingFn: sortGeneric,
  }),
  columnHelper.accessor((data) => (data ? data.insertBy || '' : null), {
    id: PurchasesTableColumnId.CreatedBy,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.CreatedBy
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original }, table }) {
      const deactivatedText = useContent(ContentId.Deactivated);
      const insertByUserId = getValue() as string;
      const getUserInfosQuery = useGetUserInfos([insertByUserId]);

      const displayUserName = useMemo(() => {
        const user = insertByUserId
          ? getUserInfosQuery.data?.[insertByUserId]
          : undefined;

        return user ? getUserDisplayName(user, deactivatedText) : '';
      }, [deactivatedText, getUserInfosQuery.data, insertByUserId]);

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      // We don't want to display this if the row is a ticket group
      if (
        original.isTktGrp &&
        table.options.meta?.sectionType !== SectionType.PurchaseEvent &&
        table.options.meta?.sectionType !== SectionType.PurchaseSideTable
      ) {
        return null;
      }

      return <TableCellDiv align="left">{displayUserName}</TableCellDiv>;
    },
  }),
  columnHelper.accessor((data) => data, {
    id: PurchasesTableColumnId.Event,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[PurchasesTableColumnId.Event]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const multipleText = useContent(ContentId.Multiple);
      const purchaseOrder = getValue<PurchaseOrderTicketGroup>();
      const { data } = useCatalogDataContext();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      let displayText: string | null | undefined = null;
      let displayTitle: string | null | undefined = null;
      const { ticketGroupItems, isMultEv, isTktGrp, viagVirtualId } =
        purchaseOrder;

      const isValidTicketGroups =
        ticketGroupItems != null && ticketGroupItems.length > 1;

      const getEventDisplayName = (event: Event | null | undefined) => {
        if (event?.eventType === EVENT_TYPE_PARKING) {
          // In this case we don't want to show the parking icon to keep the cell plan text, so just display full name
          return event?.name;
        }
        return event?.shortName || event?.name;
      };

      const { event, performer, venue } = getEventPerformerVenue(
        viagVirtualId,
        data
      );
      const {
        event: firstTgEvent,
        performer: firstTgPerf,
        venue: firstTgVenue,
      } = getEventPerformerVenue(ticketGroupItems?.[0]?.viagVirtualId, data);

      const eventDisplayName = getEventDisplayName(event?.event);
      const firstTgEventDisplayName = getEventDisplayName(firstTgEvent?.event);

      const performerDisplayName = performer?.shortName || performer?.name;
      const firstTgPerformerDisplayName =
        firstTgPerf?.shortName || firstTgPerf?.name;

      if (isTktGrp && event) {
        displayText = eventDisplayName;
        displayTitle = event.event.name;
      } else if (isValidTicketGroups) {
        const ticketGroupItemHasPerformer = !!firstTgPerf?.name;

        const performerName =
          performerDisplayName || firstTgPerformerDisplayName;
        const performerFullName = performer?.name || performer?.shortName;

        const eventName = eventDisplayName || firstTgEventDisplayName;
        const eventFullName = event?.event?.name || firstTgEvent?.event?.name;

        // Performer field is not consitantly populated. We'll fallback to event name if performer is not available
        const displayName = ticketGroupItemHasPerformer
          ? performerName
          : eventName;
        const displayFullName = ticketGroupItemHasPerformer
          ? performerFullName
          : eventFullName;

        // If it's not multiple events we can skip additional checks
        if (!isMultEv) {
          displayText = displayName
            ? `${displayName} (${multipleText})`
            : multipleText;
          displayTitle = displayFullName
            ? `${displayFullName} (${multipleText})`
            : multipleText;
        } else {
          const allEventsSameName = ticketGroupItemHasPerformer
            ? ticketGroupItems?.every((tg) => tg.perfId === firstTgPerf?.viagId)
            : ticketGroupItems?.every((tg) => {
                const tgEv = lookupEventInCatalog(data, tg.viagVirtualId);
                return tgEv?.event?.name === firstTgEvent?.event?.name;
              });

          displayText =
            allEventsSameName && displayName
              ? `${displayName} (${multipleText})`
              : multipleText;
          displayTitle =
            allEventsSameName && displayFullName
              ? `${displayFullName} (${multipleText})`
              : multipleText;
        }
      } else if (event != null) {
        displayText = eventDisplayName;
        displayTitle = event?.event?.name;
      }

      return (
        <TableCellDiv align="left" title={displayTitle ?? undefined}>
          {displayText}
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => data, {
    id: PurchasesTableColumnId.Venue,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[PurchasesTableColumnId.Venue]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const purchaseOrder = getValue<PurchaseOrderTicketGroup>();
      const multipleText = useContent(ContentId.Multiple);
      const { data } = useCatalogDataContext();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      const { ticketGroupItems, isMultEv, tktGrpCnt, isTktGrp, viagVirtualId } =
        purchaseOrder;

      const { venue } = getEventPerformerVenue(viagVirtualId, data);

      const isMultipleEvents = isMultEv || tktGrpCnt > 1;

      const isValidTicketGroups =
        ticketGroupItems != null && ticketGroupItems.length > 1;

      let displayText: string | null = null;
      let displayTitle: string | null = null;

      if (isTktGrp && venue != null) {
        displayText = venue.shortName || venue.name;
        displayTitle = venue.name;
      } else if (isValidTicketGroups) {
        const { venue: firstTgVenue } = getEventPerformerVenue(
          ticketGroupItems?.[0]?.viagVirtualId,
          data
        );

        const venueName =
          venue?.shortName ||
          venue?.name ||
          firstTgVenue?.shortName ||
          firstTgVenue?.name;
        const venueFullName = venue?.name || firstTgVenue?.name;

        // If it's not multiple events then that means venue is the same. We can skip additional checks
        if (!isMultipleEvents) {
          displayText = venueName ? venueName : multipleText;
          displayTitle = venueFullName ? venueFullName : multipleText;
        } else {
          const allEventsSameVenue = ticketGroupItems?.every(
            (tg) => tg.venId === firstTgVenue?.viagId
          );

          displayText =
            allEventsSameVenue && venueName ? venueName : multipleText;
          displayTitle =
            allEventsSameVenue && venueFullName ? venueFullName : multipleText;
        }
      } else if (venue != null) {
        displayText = venue.shortName || venue.name;
        displayTitle = venue.name;
      }

      if (displayText == null) {
        return null;
      }

      return (
        <TableCellDiv align="left" title={displayTitle ?? undefined}>
          {displayText}
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => data, {
    id: PurchasesTableColumnId.EventDate,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[
              PurchasesTableColumnId.EventDate
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original }, table }) {
      const purchaseOrder = getValue<PurchaseOrderTicketGroup>();
      const multipleText = useContent(ContentId.Multiple);
      const { data } = useCatalogDataContext();

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      if (!purchaseOrder.isTktGrp && purchaseOrder.isMultEv) {
        return <TableCellDiv align="left">{multipleText}</TableCellDiv>;
      }

      const event = lookupEventInCatalog(data, purchaseOrder.viagVirtualId);
      if (event?.event == null) {
        return null;
      }

      const dates = event.event.dates;

      const customPrecision =
        table.options.meta?.formatOptionOverrides?.[
          PurchasesTableColumnId.EventDate
        ];

      const date = customPrecision
        ? (applyNumberFormatting({
            precision: customPrecision,
            inputNumber: dates?.start,
            isDateTime: true,
          }) as string)
        : undefined;

      return (
        <TableCellDiv align="left">
          {date ? (
            <div
              style={{
                textTransform: 'none',
              }}
            >
              {date}
            </div>
          ) : (
            <EventDateDisplay
              eventDateString={dates?.start ?? undefined}
              eventEndDateString={dates?.end ?? undefined}
              showEventDateTbd={true}
              eventSubDateFormat={' - h:mm aaa, eee'}
              slimMode
            />
          )}
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => data, {
    id: PurchasesTableColumnId.Seating,
    header: function Header({
      table: {
        options: { meta },
      },
      column,
    }) {
      const seatingText = useContent(
        PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[PurchasesTableColumnId.Seating]
      );
      const sectionText = useContent(ContentId.Section);
      const { userDefinedPrecision } = useColumnUserSetting(
        column.id,
        meta?.sectionType || SectionType.PurchaseEvent
      );

      const isSectionOnly =
        userDefinedPrecision === FormatOption.Seating_SectionOnly;
      return (
        <TableHeaderCellDiv align="left">
          <span>
            {isSectionOnly ? `${seatingText} (${sectionText})` : seatingText}
          </span>
        </TableHeaderCellDiv>
      );
    },
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({
      getValue,
      table: {
        options: { meta },
      },
      row: { original },
      column,
    }) {
      const purchaseOrder = getValue<PurchaseOrderTicketGroup>();
      const multipleText = useContent(ContentId.Multiple);
      const contentContext = useContentContext();
      const hasListingNotesAdditionalInfoFeature = useUserHasFeature(
        Feature.ListingNotesAdditionalInfo
      );
      const columnId = column.id;
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        meta?.sectionType || SectionType.PurchaseEvent
      );

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }

      if (
        !purchaseOrder.isTktGrp &&
        (purchaseOrder.isMultEv || purchaseOrder.tktGrpCnt > 1)
      ) {
        return <TableCellDiv align="left">{multipleText}</TableCellDiv>;
      }

      const ticketGroupToGetSeating = purchaseOrder.isTktGrp
        ? purchaseOrder
        : purchaseOrder.ticketGroupItems?.[0];

      if (ticketGroupToGetSeating?.seating == null) {
        return null;
      }

      const { seating, seatTraits: listingNotes } = ticketGroupToGetSeating;
      const adjustedSeating =
        userDefinedPrecision === FormatOption_Seating.Seating_SectionOnly
          ? {
              ...seating,
              row: null,
              seatFr: null,
              seatTo: null,
            }
          : seating;

      return (
        <SeatInfoContainer>
          <SeatingInfo {...adjustedSeating} />
          {listingNotes?.length ? (
            <HorizontalTextList
              className={styles.listingNotes}
              preIcon={getListingNoteIcon(
                listingNotes[0],
                contentContext,
                hasListingNotesAdditionalInfoFeature
              )}
              texts={listingNotes.map((n) => n.listingNoteText)}
              maxTextsToShowOnHover={listingNotes.length}
            />
          ) : null}
        </SeatInfoContainer>
      );
    },
    sortingFn: SEATING_SORTING_FN_NAME,
  }),
  columnHelper.accessor((data) => getSeating(data)?.row ?? '', {
    id: PurchasesTableColumnId.Row,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[PurchasesTableColumnId.Row]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original: purchaseOrder } }) {
      if (!purchaseOrder) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }
      const ticketGroupToGetSeating = purchaseOrder.isTktGrp
        ? purchaseOrder
        : purchaseOrder.ticketGroupItems?.[0];

      if (ticketGroupToGetSeating?.seating == null) {
        return null;
      }

      const { seating } = ticketGroupToGetSeating;
      return <TableCellDiv align="right">{seating.row}</TableCellDiv>;
    },
    sortingFn: sortGeneric,
  }),
  columnHelper.accessor((data) => getSeating(data)?.seatFr ?? '', {
    id: PurchasesTableColumnId.Seats,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[PurchasesTableColumnId.Seats]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original: purchaseOrder } }) {
      if (!purchaseOrder) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
        );
      }
      const ticketGroupToGetSeating = purchaseOrder.isTktGrp
        ? purchaseOrder
        : purchaseOrder.ticketGroupItems?.[0];

      if (ticketGroupToGetSeating?.seating == null) {
        return null;
      }

      const { seating } = ticketGroupToGetSeating;
      return (
        <TableCellDiv align="right">
          <SeatingInfo
            section={''}
            row={null}
            seatFr={seating.seatFr}
            seatTo={seating.seatTo}
            showSeatPrefix={false}
            justifyIconContent="spaceBetween"
          />
        </TableCellDiv>
      );
    },
    sortingFn: sortGeneric,
  }),
];
const SECTION_TYPE = SectionType.Purchases;

export const tagColumnDef = (
  tagName: string
): ColumnDef<PurchaseOrderTicketGroup | null> => ({
  id: tagName,
  header: () => (
    <TableHeaderCellDiv align="right">
      <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
        {tagName}
      </span>
    </TableHeaderCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_LG,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    const tag = data?.tags?.find((t) => t.key === tagName);
    if (
      tag?.valueType === TagsValueType.Int ||
      tag?.valueType === TagsValueType.Decimal
    ) {
      return tag ? parseFloat(tag.value) : null;
    }
    return tag?.value;
  },
  cell: function Cell({ getValue, row: { original }, table }) {
    const { value: storedColumnNumberPrecisions = {} } =
      useServerUserSetting<FormatOptionEntries>({
        id: SECTION_TYPE_TO_PRECISION_SETTING[
          table.options.meta?.sectionType ?? SECTION_TYPE
        ],
      });
    const tag = original?.tags?.find((t) => t.key === tagName);

    if (
      tag?.valueType === TagsValueType.Int ||
      tag?.valueType === TagsValueType.Decimal
    ) {
      const userDefinedPrecision = storedColumnNumberPrecisions[tagName];

      return (
        <CustomNumericCell
          columnId={tagName}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={Boolean(!original || !original)}
          shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE}
          sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
          matchingTag={tag}
          currencyCode={original?.currency}
        />
      );
    }

    if (!original) {
      return (
        <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_PURCHASE} />
      );
    }

    if (!tag) {
      return null;
    }

    return (
      <TableCellDiv align="right">
        <TagValueDisplay tag={tag} displayMode />
      </TableCellDiv>
    );
  },
});
