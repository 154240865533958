import { UploadDocumentInput } from 'src/components/Input/UploadDocumentInput';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  DocumentType,
  SaleDetails,
} from 'src/WebApiController';

export const ImageProofBody = ({
  setIsSaveDisabled,
}: {
  setIsSaveDisabled: (disabled: boolean) => void;
}) => {
  const { posEntity: sale } = useActivePosEntityContext<SaleDetails>();

  return (
    <UploadDocumentInput
      entityId={sale!.id}
      entityType={ActionOutboxEntityType.Sale}
      documentType={DocumentType.TicketTransfer}
      uploadInstruction={<Content id={ContentId.TransferTicketsIns} />}
      setIsSaveDisabled={setIsSaveDisabled}
    />
  );
};
