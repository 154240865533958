import { useCallback } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { BulkEditBroadcastSettingsDialog } from 'src/dialogs/BulkEdits/BulkEditBroadcastSettingsDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { lookupEventInCatalog } from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditListingClient,
  BulkEditPreview,
  BulkEditPreviewWithDetails,
  Listing,
  ListingActionType,
  ListingDetails,
  ListingGroup,
  ListingQuery,
  ListingStatus,
  Marketplace,
} from 'src/WebApiController';

import { INVENTORY_BULK_BROADCAST_LISTINGS_KEY } from '../../InventoryActionDropdown.constants';

export const useBulkEditBroadcastGlobalSettingsAction = ({
  filterQueryWithEventIds,
  setIsLoading,
  isLoading,
}: {
  filterQueryWithEventIds: ListingQuery;
  setIsLoading: (v: boolean) => void;
  isLoading?: boolean;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const {
    data,
    updateItemInEvent,
    eventsExpansion: { expandedListItems, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const { getGroupToggleState } = useMultiSelectionContext();

  const { showErrorDialog } = useErrorBoundaryContext();
  const {
    openDialog: openBulkEditBroadcastSettingsDialog,
    closeDialog: closeBulkEditBroadcastSettingsDialog,
  } = useDialog(
    DialogId.BulkEditBroadcastSettings,
    BulkEditBroadcastSettingsDialog
  );

  const {
    openDialog: openBulkEditUnbroadcastSettingsDialog,
    closeDialog: closeBulkEditUnbroadcastSettingsDialog,
  } = useDialog(
    DialogId.BulkEditBroadcastSettings,
    BulkEditBroadcastSettingsDialog
  );

  const onSubmitEditBroadcastSettings = useCallback(
    async (
      marketplacesToBroadcast: Marketplace[] | null,
      marketplacesToUnbroadcast: Marketplace[] | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreview
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        tryInvokeApi(
          async () => {
            const client = new BulkEditListingClient(
              activeAccountWebClientConfig
            );

            const preview = await client.bulkBroadcastListingsPreview(
              filterQueryWithEventIds
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkBroadcastListingsPreview', error, {
              trackErrorData: {
                filterQueryWithEventIds,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (marketplacesToBroadcast && marketplacesToUnbroadcast) {
        await tryInvokeApi(
          async () => {
            // Find all listings whose event is expanded - we want to update their state immediately
            expandedListItems.forEach((viagVirtualId) => {
              const eventWithData = lookupEventInCatalog(data, viagVirtualId);
              if (eventWithData?.listings?.length) {
                const affectedListings = eventWithData.listings.flatMap((l) => [
                  l,
                  // We only need to update broadcast state for top-level listings (they are the active ones)
                  ...((l as ListingGroup)?.groupItems ?? []),
                ]);

                const eventToggleState = getGroupToggleState(viagVirtualId);

                if (marketplacesToUnbroadcast.length) {
                  affectedListings
                    .filter(
                      (listing) =>
                        ((listing as Listing).actions.includes(
                          ListingActionType.Unbroadcast
                        ) &&
                          eventToggleState.isGroupSelected) ||
                        eventToggleState.items.includes(String(listing.id))
                    )
                    .forEach((l) =>
                      updateItemInEvent(
                        {
                          ...(l as Listing),
                          status: ListingStatus.DelistingPending,
                        },
                        ActionOutboxEntityType.Listing
                      )
                    );
                }
                if (marketplacesToBroadcast.length) {
                  affectedListings
                    .filter(
                      (listing) =>
                        ((listing as Listing).actions.includes(
                          ListingActionType.Broadcast
                        ) &&
                          eventToggleState.isGroupSelected) ||
                        eventToggleState.items.includes(String(listing.id))
                    )
                    .forEach((l) =>
                      updateItemInEvent(
                        {
                          ...(l as Listing),
                          status: ListingStatus.ListingPending,
                        },
                        ActionOutboxEntityType.Listing
                      )
                    );
                }
              }
            });

            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkBroadcastListings(
              {
                item1: preview!,
                item2: marketplacesToBroadcast,
                item3: marketplacesToUnbroadcast,
              },
              INVENTORY_BULK_BROADCAST_LISTINGS_KEY,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              // It's ok to close both, cuz only 1 can be opened anyway
              closeBulkEditBroadcastSettingsDialog();
              closeBulkEditUnbroadcastSettingsDialog();

              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest broadcast state can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }
            }
          },
          (error) => {
            showErrorDialog('onSubmitEditBroadcastSettings', error, {
              trackErrorData: {
                preview,
                marketplacesToBroadcast,
                marketplacesToUnbroadcast,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkEditBroadcastSettingsDialog,
      closeBulkEditUnbroadcastSettingsDialog,
      data,
      expandedListItems,
      filterQueryWithEventIds,
      getGroupToggleState,
      refreshExpandedListItems,
      setActivePosEntity,
      setIsLoading,
      showErrorDialog,
      updateItemInEvent,
    ]
  );

  const openBulkEditBroadcastSettingsDialogWithEvent = useCallback(() => {
    openBulkEditBroadcastSettingsDialog({
      event: undefined,
      updateKey: INVENTORY_BULK_BROADCAST_LISTINGS_KEY,
      filterQuery: filterQueryWithEventIds,
      marketplaceBroadcastSelectionMode: 'all',
      onOkay: onSubmitEditBroadcastSettings,
      onClosed: () => {
        setIsLoading(false);
      },
      onCancel: closeBulkEditBroadcastSettingsDialog,
    });
  }, [
    closeBulkEditBroadcastSettingsDialog,
    filterQueryWithEventIds,
    onSubmitEditBroadcastSettings,
    openBulkEditBroadcastSettingsDialog,
    setIsLoading,
  ]);

  const openBulkEditUnbroadcastSettingsDialogWithEvent = useCallback(() => {
    openBulkEditUnbroadcastSettingsDialog({
      event: undefined,
      updateKey: INVENTORY_BULK_BROADCAST_LISTINGS_KEY,
      filterQuery: filterQueryWithEventIds,
      marketplaceBroadcastSelectionMode: 'none',
      onOkay: onSubmitEditBroadcastSettings,
      onClosed: () => {
        setIsLoading(false);
      },
      onCancel: closeBulkEditUnbroadcastSettingsDialog,
    });
  }, [
    closeBulkEditUnbroadcastSettingsDialog,
    filterQueryWithEventIds,
    onSubmitEditBroadcastSettings,
    openBulkEditUnbroadcastSettingsDialog,
    setIsLoading,
  ]);

  const onBroadcastAllClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      openBulkEditBroadcastSettingsDialogWithEvent();
    },
    [openBulkEditBroadcastSettingsDialogWithEvent]
  );

  const onUnbroadcastAllClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      openBulkEditUnbroadcastSettingsDialogWithEvent();
    },
    [openBulkEditUnbroadcastSettingsDialogWithEvent]
  );

  return {
    dropDown: (
      <>
        <PosDropdownItem
          key="BroadcastAll"
          onClick={onBroadcastAllClick}
          disabled={isLoading}
        >
          <Content id={ContentId.Broadcast} />
        </PosDropdownItem>
        <PosDropdownItem
          key="UnbroadcastAll"
          onClick={onUnbroadcastAllClick}
          disabled={isLoading}
        >
          <Content id={ContentId.Unbroadcast} />
        </PosDropdownItem>
      </>
    ),
  };
};
