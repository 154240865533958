import {
  MutableRefObject,
  RefCallback,
  RefObject,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useMeasure } from 'react-use';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import * as styles from './BezierGraph.css';
import {
  BezierGraphManager,
  CalcOutputFn,
  SerializedSegment,
} from './BezierGraphManager';

export type OnDragEndFn = (customEv: {
  calcOutput: CalcOutputFn;
  /**
   * Whether the path is valid.
   *
   * Will return `false` if path is out of bounds or if it has multiple y values for a single x
   * value.
   */
  isPathValid: boolean;
  segments: paper.Segment[];
}) => void;

export type OnResizedFn = (resizedEv: {
  segments: paper.Segment[];
  height: number;
  width: number;
}) => void;

export type BezierGraphProps = {
  segments: SerializedSegment[];
  minLabel: string;
  midLabel?: string;
  maxLabel: string;
  /**
   * If set to `true`, the number of segments will be fixed, i.e. disable add/remove segments.
   * Default is `false`.
   */
  fixedSegmentAmount?: boolean;
  hideAxes?: boolean;
  hideActionLabel?: boolean;
  horizontalGraph?: boolean;
  /**
   * Function to transform the delta when dragging a section.
   * @param segment segment being dragging
   * @param delta
   * @returns transformed delta
   */
  deltaTransform?: (segment: paper.Segment, delta: paper.Point) => paper.Point;
  pathColor?: string;
  parentRef?: RefObject<HTMLElement>;
  /**
   * This ref callback can be used to obtain the `calcOutput` function in case it is needed outside
   * of `onDragEnd` events.
   *
   * @see BezierGraphManager#calcOutput()
   *
   * Alternatively, `calcOutput` will also be passed into the `onDragEnd` callback. Both copies of
   * the function can be used interchangeably.
   * @param calcOutput
   */
  calcOutputRef?:
    | MutableRefObject<CalcOutputFn | undefined>
    | RefCallback<CalcOutputFn>;
  exportPathRef?:
    | MutableRefObject<(() => SerializedSegment[]) | undefined>
    | RefCallback<() => SerializedSegment[]>;
  onPathSetFromSegments?: (ev: { segments: paper.Segment[] }) => void;
  onDragEnd?: OnDragEndFn;
  onResized?: OnResizedFn;
};

export function BezierGraph({
  segments,
  minLabel,
  midLabel,
  maxLabel,
  fixedSegmentAmount = false,
  hideAxes = false,
  hideActionLabel = false,
  horizontalGraph = false,
  deltaTransform,
  pathColor,
  calcOutputRef,
  exportPathRef,
  onPathSetFromSegments,
  onDragEnd,
  onResized,
}: BezierGraphProps) {
  const canManageControlPoints = useUserHasFeature(
    Feature.BezierGraphControlPointsManager
  );
  const managerRef = useRef<BezierGraphManager>();
  const [viewportRef, { height: viewportHeight, width: viewportWidth }] =
    useMeasure<HTMLDivElement>();

  useEffect(() => {
    managerRef.current?.setPath(segments);
    if (onPathSetFromSegments && managerRef.current) {
      onPathSetFromSegments({ segments: managerRef.current.getSegments() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segments]);

  useEffect(() => {
    managerRef.current?.setLabels({ minLabel, maxLabel });
  }, [minLabel, maxLabel]);

  useEffect(() => {
    const manager = managerRef.current;
    if (onDragEnd && manager) {
      const handler = manager.addMouseUpEventListener((_ev, customEv) => {
        onDragEnd(customEv);
      });
      return () => manager.removeMouseUpEventListener(handler);
    }
  }, [onDragEnd]);

  const canvasRef = useCallback<RefCallback<HTMLCanvasElement>>((canvas) => {
    if (canvas && !managerRef.current) {
      // XXX Fix for `onResize` not being called when the canvas visibility is changed.
      // When the canvas is not visible in the browser viewport it seems like the dimensions are not
      // properly set. Therefore, we need to call `onResize` manually to properly rerender the
      // graph.
      // TODO this can be revaluated on whether it is still required if we either:
      // 1. Change to a different method for obtaining graph values without having to render the
      // canvas.
      // 2. Change to a different method of hiding the canvas in `SeatScoreConfigCard` that retains
      // the correct canvas size even when hidden.
      const observer = new MutationObserver(() => {
        const bounds = canvas.getBoundingClientRect();
        managerRef.current?.onResize(bounds.width, bounds.height);
      });
      if (canvas.parentElement) {
        // XXX Because viewport visibility is set in `SeatScoreConfigCard`, we pass the parent
        // element here. The correctness of this may change if the html structure of
        // `SeatScoreConfigCard` changes. For example, if the visibility control is moved to another
        // element, we will need to change this to that element.
        // TODO It may be good to change the observed element to an element controlled by this
        // component in the future.
        observer.observe(canvas.parentElement, { attributes: true });
      }

      const manager = new BezierGraphManager(
        canvas,
        {
          minLabel,
          midLabel,
          maxLabel,
        },
        fixedSegmentAmount,
        hideAxes,
        hideActionLabel,
        horizontalGraph,
        pathColor,
        deltaTransform,
        canManageControlPoints
      );
      managerRef.current = manager;

      if (calcOutputRef) {
        setRef(calcOutputRef, (input: number) => manager.calcOutput(input));
      }
      if (exportPathRef) {
        setRef(exportPathRef, () => manager.exportPath());
      }

      return () => {
        observer.disconnect();
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (viewportHeight && viewportWidth) {
      const currentViewSize = managerRef.current?.getViewSize();
      if (
        currentViewSize?.width === viewportWidth &&
        currentViewSize?.height === viewportHeight
      ) {
        return;
      }

      managerRef.current?.onResize(viewportWidth, viewportHeight);
      onResized?.({
        segments: managerRef.current?.getSegments() ?? [],
        height: viewportHeight,
        width: viewportWidth,
      });
    }
  }, [onResized, viewportHeight, viewportWidth]);

  return (
    <div className={styles.canvasContainer} ref={viewportRef}>
      <canvas ref={canvasRef} className={styles.canvas} data-paper-resize />
    </div>
  );
}

type Ref<T> = MutableRefObject<T | undefined> | RefCallback<T>;

function setRef<T>(ref: Ref<T>, val: T) {
  if (typeof ref === 'function') {
    ref(val);
  } else {
    ref.current = val;
  }
}
