import { useCallback, useMemo } from 'react';
import { getCompQuantitiesOptions } from 'src/utils/inventoryUtils';
import {
  AutoPricingCompListingMode,
  SelectedSectionSettings,
} from 'src/WebApiController';

export const useCompQuantitiesFilter = (
  compQuantityScoreOverrideJson: string | null | undefined,
  onCompListingChange: (input: {
    compListingModeNew?: AutoPricingCompListingMode | null;
    compListingFloorNew?: number | null;
    compListingCeilingNew?: number | null;
    compListingOnlyForSameZoneEnabledNew?: boolean | null;
    compListingOnlyForSelectedSectionsEnabledNew?: boolean | null;
    compListingQuantityScoreAdjustmentEnabledNew?: boolean | null;
    compListingExcludeFanInventoryNew?: boolean | null;
    compListingQuantityScoreAdjustmentOverrideJsonNew?: string | null;
    compListingSelectedSectionSettingsNew?: SelectedSectionSettings | null;
  }) => void
) => {
  const parsedQuantityOverridesJson = JSON.parse(
    compQuantityScoreOverrideJson || 'null'
  );
  const quantitiesFilterOptions = useMemo(() => getCompQuantitiesOptions(), []);
  const quantitiesFilterValue = useMemo(() => {
    const quantitiesFilter = parsedQuantityOverridesJson?.quantitiesFilter;
    if (!quantitiesFilter) {
      return [];
    }
    // exactMatches and greaterThanOrEqualTo
    const { exact, gte } = quantitiesFilter;

    const eligibleQuantities = Object.values(quantitiesFilterOptions)
      .filter((value) => !isNaN(parseInt(value)))
      .map((value) => parseInt(value));
    const selectedQuantities = eligibleQuantities.filter((quantity) => {
      if (((exact as number[]) ?? []).includes(quantity)) {
        return true;
      }
      if (gte != null && (gte as number) <= quantity) {
        return true;
      }
      return false;
    });
    return Object.values(quantitiesFilterOptions).filter((value) =>
      selectedQuantities.includes(parseInt(value))
    );
  }, [parsedQuantityOverridesJson?.quantitiesFilter, quantitiesFilterOptions]);

  const onUpdateQuantitiesFilter = useCallback(
    (values: string[]) => {
      const exact = [];
      let gte = null;
      for (const value of values) {
        const quantity = parseInt(value);
        if (value.indexOf('+') > -1) {
          gte = quantity;
        } else {
          if (!isNaN(quantity)) {
            exact.push(quantity);
          }
        }
      }

      const quantityOverrideJsonNew = {
        ...parsedQuantityOverridesJson,
        quantitiesFilter: { exact, gte },
      };

      onCompListingChange({
        compListingQuantityScoreAdjustmentOverrideJsonNew: JSON.stringify(
          quantityOverrideJsonNew
        ),
      });
    },
    [onCompListingChange, parsedQuantityOverridesJson]
  );

  return {
    quantitiesFilterValue,
    quantitiesFilterOptions,
    onUpdateQuantitiesFilter,
  };
};
