import { PosIconProps } from './SvgWrapper';

export function TaciyonLogo(props: Omit<PosIconProps, 'viewBox'>) {
  return (
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_16_18)">
              <path
                  d="M19.9998 3.37327C19.9998 5.2064 18.5061 6.69107 16.6617 6.69107C14.8174 6.69107 13.3237 5.2064 13.3237 3.37327C13.3237 1.54014 14.8174 0.053977 16.6617 0.053977C18.5061 0.053977 19.9998 1.53865 19.9998 3.37178V3.37327ZM6.61454 0.0227051H2.89741C1.21639 0.0227051 -0.000183105 1.23189 -0.000183105 2.9027C-0.000183105 4.57351 1.21639 5.78269 2.89741 5.78269H5.26014C5.78152 5.78269 6.20552 6.20263 6.20552 6.72234V14.862C6.20552 16.6683 7.52248 17.9772 9.33984 17.9772C11.1572 17.9772 12.4742 16.6683 12.4742 14.862V5.84673C12.4727 1.94816 10.3467 0.0227051 6.61454 0.0227051Z"
                  fill="url(#paint0_linear_16_18)"/>
          </g>
          <defs>
              <linearGradient id="paint0_linear_16_18" x1="0.296467" y1="16.567" x2="22.5958" y2="-12.479"
                              gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FF9D00"/>
                  <stop offset="0.21" stop-color="#F75830"/>
                  <stop offset="0.41" stop-color="#F11E5A"/>
                  <stop offset="0.82" stop-color="#9800A6"/>
                  <stop offset="1" stop-color="#7600BF"/>
              </linearGradient>
              <clipPath id="clip0_16_18">
                  <rect width="20" height="18" fill="white"/>
              </clipPath>
          </defs>
      </svg>
  );
}
