/**
 * Copied from https://viagogo.visualstudio.com/Packages/_git/Viagogo.Npm.Core?path=/src/themes/shared/colors.ts
 * because the package doesn't export these values.
 *
 * This should **NOT** be used outside of the `themes/` directory.
 */
export const Colors = {
  // viagogo
  green075: '#71a230',
  green100: '#6fb229',
  green150: '#8ac53b',
  green200: '#8cc63f',
  green300: '#a3d165',
  green400: '#badd8c',
  green450: '#cee7ae',
  green500: '#d1e8b2',
  green600: '#e8f4d9',
  green700: '#f3f9eb',
  gray100: '#37383a',
  gray200: '#5f6062',
  gray250: '#87909a',
  gray300: '#7f8081',
  gray400: '#9fa0a1',
  gray450: '#bfc0c0',
  gray500: '#bfc0c0',
  gray600: '#dfdfe0',
  gray700: '#efefef',
  gray800: '#f2f2f2',
  gray900: '#f9f9f9',
  gray1000: '#f6f8f9',
  blue075: '#0094dc',
  blue100: '#1eb0ee',
  blue200: '#22afee',
  blue300: '#47BEF0', // this is the categories sports color
  blue500: '#b0e3f8',
  blue600: '#e1f3fd',
  blue700: '#e1f3fd',

  magenta300: '#f4538d',
  yellow100: '#ffb900',
  red100: '#B71C1C',
  red200: '#D32F2F',
  red300: '#da4f49',
  red400: 'e53935',
  red500: '#EF5350',
  red600: '#E57373',
  black: '#000',
  white: '#fff',
  darkBlue250: '#0f5b99',
  darkBlue300: '#0c709a',
  darkBlue700: '#0f5b99',
  dark060: '#212428',

  // Stubhub
  indigo025: '#15032E',
  indigo050: '#1E093A',
  indigo060: '#29134C',
  indigo070: '#2D0F54',
  indigo075: '#260354',
  indigo100: '#3F1D75',
  indigo200: '#643CA2',
  indigo300: '#55279E',
  indigo500: '#825ABF',
  indigo600: '#D4B9FF',
  indigo700: '#F1EBFA',

  // TicketUtil
  tuBlue025: '#2A3944',
  tuBlue100: '#0084EA',
  tuBlue200: '#339CEE',
  tuBlue300: '#66B5F2',
  tuBlue400: '#99CEF6',
  tuBlue500: '#CCE6FB',
  tuBlue600: '#E6F3FD',

  fuchsia075: '#AF346B',
  fuchsia100: '#DB2C7B',
  fuchsia200: '#ED2E80',
  fuchsia500: '#F56AA8',
  fuchsia600: '#FBBBD6',
  fuchsia700: '#FBEAF1',

  aqua075: '#0D8282',
  aqua100: '#29B2B2',
  aqua200: '#49D4D4',
  aqua500: '#72C8C9',
  aqua600: '#ABDDDE',
  aqua700: '#DAFAFC',

  neutral000: '#000000',
  neutral025: '#121212',
  neutral050: '#191C1F',
  neutral075: '#2F343B',
  neutral100: '#677383',
  neutral200: '#87909A',
  neutral300: '#B1BAC2',
  neutral400: '#CFD4D9',
  neutral500: '#E6E9EB',
  neutral600: '#F6F8F9',
  neutral700: '#FBFCFC',
  neutral1000: '#FFFFFF',

  negative075: '#9F2B18',
  negative100: '#D91A1A',
  negative200: '#FF3939',
  negative500: '#FCEFED',

  success075: '#198620',
  success100: '#00A645',
  success500: '#E5FFF0',
  warning075: '#996E00',
  warning100: '#F2B824',
  warning500: '#FEF7E7',

  info075: '#0F5894',
  info100: '#1475C4',
  info200: '#218FE8',
  info500: '#DAECFB',

  // Brand-specific color that don't have regular color naming
  brandAxs: '#0055A0',
  brandVivid: '#0E1963',
  brandSeatgeek: '#FF5B49',
  brandTickPick: '#3399FF',
  brandTicketmaster: '#026BDE',
  brandFanXchange: '#006099',
  brandTicketEvolution: '#9A362C',
  brandTicketNetwork: '#3A519D',
  brandTelecharge: '#000B6F',
  brandTicketon: '#202867',
  brandSeetickets: '#009C9B',
  brandMgmResorts: '#16057D',
  brandSmithCenterImport: '#63948E',
  brandComputicket: '#B50D90',
  brandLyte: '#ffffff',
  brandGoTickets: '#ffffff',
  brandB2BSales: '#ffffff',

  textSeatgeek: '#181818',
  textFanXchange: '#15F2D3',
  textEventbrite: '#FF5B49',
  textTicketon: '#FECA27',
  textEtix: '#00579B',
  textMgmResorts: '#FFF9BE',

  backgroundStubhubSeatmap: '#C2B5E0',

  seatCardBackgroundColor: '#1A2426',
} as const;
