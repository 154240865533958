import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import { ProcessingIcon } from 'src/svgs/Viagogo';
import { STANDARD_DEVIATIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { AutoPricingOutlierMode } from 'src/WebApiController';

import {
  ListingPricingInput,
  ListingPricingSettingsCellProps,
} from '../ListingPriceForm.types';
import * as styles from './Cell.css';

export const OutlierFilteringCell: React.FC<
  ListingPricingSettingsCellProps
> = ({ listing, editable, event }) => {
  const { watch, formState, getFieldState, setValue } =
    useFormContext<ListingPricingInput>();

  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);

  const {
    outlierMode,
    outlierStandardDeviations,
    canAutoPrice,
    onOutlierChange,
  } = useListingAutoPricingSettings(listing);

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingPrice = watch('isSubmitting');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const isDisabled =
    listing.isDeleted ||
    !canAutoPrice ||
    listingSelection.isSingleGroupMultiSelect ||
    fieldIsSubmitting ||
    !editable ||
    outlierMode !== AutoPricingOutlierMode.StandardDeviations;

  const submittingField = watch('submittingField');
  const isSubmittingField =
    fieldIsSubmitting && submittingField === 'outlierStandardDeviations';

  const onUpdateOutlierFilter = useCallback(
    (value: string | null) => {
      const sanitized = parseInt(value ?? '');
      if (value != null && !isNaN(sanitized)) {
        onOutlierChange({
          outlierModeNew: AutoPricingOutlierMode.StandardDeviations,
          outlierStandardDeviationsNew: sanitized,
        });
      } else {
        onOutlierChange({
          outlierModeNew: AutoPricingOutlierMode.StandardDeviations,
          outlierStandardDeviationsNew: null,
        });
      }
      // Only submit if Enter is hit in the box that has changes
      setValue('isSubmittingPricingSettings', true);
      setValue('isSubmittingRowIndex', rowIndex);
      setValue('submittingField', 'outlierStandardDeviations');
    },
    [onOutlierChange, rowIndex, setValue]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <PosFormField
        errors={
          getFieldState('outlierStandardDeviations', formState)?.error?.message
        }
        showErrorsInline
        className={styles.formFieldContainer}
      >
        {isSubmittingField ? (
          <div className="operation-button">
            <RotatingWrapper>
              <ProcessingIcon size={vars.iconSize.m} />
            </RotatingWrapper>
          </div>
        ) : (
          <PosEnumSelect
            className={styles.formSelectField}
            value={outlierStandardDeviations?.toString()}
            disabled={isDisabled}
            valueOptionsContent={STANDARD_DEVIATIONS_TO_CID}
            enableEmptySelection={true}
            onClick={(e) => e.stopPropagation()}
            onChange={onUpdateOutlierFilter}
          />
        )}
      </PosFormField>
    </TableCellDiv>
  );
};
