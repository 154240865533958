import { vars } from 'src/core/themes';
import { Marketplace } from 'src/WebApiController';

import { KnownVendors } from './knownVendors';

export const MARKETPLACE_TO_BACKGROUND_COLOR: Record<string, string> = {
  [Marketplace.StubHub]: vars.color.marketplaceBackgroundStubhub,
  [Marketplace.AXS]: vars.color.marketplaceBackgroundAxs,
  [Marketplace.SeatGeek]: vars.color.marketplaceBackgroundSeatgeek,
  [Marketplace.VividSeats]: vars.color.marketplaceBackgroundVivid,
  [Marketplace.Ticketmaster]: vars.color.marketplaceBackgroundTicketMaster,
  [Marketplace.TickPick]: vars.color.marketplaceBackgroundTickPick,
  [Marketplace.Offline]: vars.color.marketplaceBackgroundOffline,
  [Marketplace.FanXchange]: vars.color.marketplaceBackgroundFanXchange,
  [Marketplace.Gametime]: vars.color.marketplaceBackgroundGametime,
  [Marketplace.TicketEvolution]:
    vars.color.marketplaceBackgroundTicketEvolution,
  [Marketplace.TicketNetwork]: vars.color.marketplaceBackgroundTicketNetwork,
  [Marketplace.Automatiq]: vars.color.marketplaceBackgroundAutomatiq,
};

export const MARKETPLACE_TO_TEXT_COLOR: Record<string, string> = {
  [Marketplace.SeatGeek]: vars.color.marketplaceTextSeatgeek,
  [Marketplace.FanXchange]: vars.color.marketplaceTextFanXchange,
  [Marketplace.Automatiq]: vars.color.marketplaceTextAutomatiq,
};

export const KNOWNVENDOR_TO_BACKGROUND_COLOR: Record<string, string> = {
  [KnownVendors.TicketmasterCa]: vars.color.marketplaceBackgroundTicketmasterCa,
  [KnownVendors.Eventbrite]: vars.color.marketplaceBackgroundEventbrite,
  [KnownVendors.Livenation]: vars.color.marketplaceBackgroundLivenation,
  [KnownVendors.BroadwayDotCom]: vars.color.marketplaceBackgroundBroadwayDotCom,
  [KnownVendors.Telecharge]: vars.color.marketplaceBackgroundTelecharge,
  [KnownVendors.Ticketon]: vars.color.marketplaceBackgroundTicketon,
  [KnownVendors.Seetickets]: vars.color.marketplaceBackgroundSeetickets,
  [KnownVendors.Etix]: vars.color.marketplaceBackgroundEtix,
  [KnownVendors.Tixr]: vars.color.marketplaceBackgroundTixr,
  [KnownVendors.Festicket]: vars.color.marketplaceBackgroundFesticket,
  [KnownVendors.Insomniac]: vars.color.marketplaceBackgroundInsomniac,
  [KnownVendors.DiceImport]: vars.color.marketplaceBackgroundDiceImport,
  [KnownVendors.MgmResorts]: vars.color.marketplaceBackgroundMgmResorts,
  [KnownVendors.Tickeri]: vars.color.marketplaceBackgroundTickeri,
  [KnownVendors.SmithCenterImport]:
    vars.color.marketplaceBackgroundSmithCenterImport,
  [KnownVendors.Stubwire]: vars.color.marketplaceBackgroundStubwire,
  [KnownVendors.Computicket]: vars.color.marketplaceBackgroundComputicket,
};

export const KNOWNVENDOR_TO_TEXT_COLOR: Record<string, string> = {
  [KnownVendors.Eventbrite]: vars.color.marketplaceTextEventbrite,
  [KnownVendors.Livenation]: vars.color.marketplaceTextLivenation,
  [KnownVendors.Ticketon]: vars.color.marketplaceTextTicketon,
  [KnownVendors.Etix]: vars.color.marketplaceTextEtix,
  [KnownVendors.Festicket]: vars.color.marketplaceTextFesticket,
  [KnownVendors.MgmResorts]: vars.color.marketplaceTextMgmResorts,
  [KnownVendors.Tickeri]: vars.color.marketplaceTextTickeri,
  [KnownVendors.Stubwire]: vars.color.marketplaceTextStubwire,
};

/**
 * Function to map brand names to background colors.
 * If we have pre-defined color mappings for brands in `MARKETPLACE_TO_BACKGROUND_COLOR`,
 * simply return the corresponding color.
 * If we don't have a mapping for the brand name, we convert the string to a
 * certain hash and get the corresponding color. Hashing ensures that we get the
 * same color for the same brand name (case insensitive)
 * @param str name for brand
 * @returns color string for the brand
 */
export function mapBrandNameToBackgroundcolor(
  str: string | Marketplace | KnownVendors
): string {
  const marketplaceValue = Object.values(Marketplace).find(
    (m) => m.toString().toLocaleLowerCase() === str.toLocaleLowerCase()
  );

  if (marketplaceValue) {
    if (MARKETPLACE_TO_BACKGROUND_COLOR[marketplaceValue] != null) {
      return MARKETPLACE_TO_BACKGROUND_COLOR[marketplaceValue];
    }
  }

  const knownVendorValue = Object.values(KnownVendors).find(
    (m) => m.toString().toLocaleLowerCase() === str.toLocaleLowerCase()
  );

  if (knownVendorValue) {
    if (KNOWNVENDOR_TO_BACKGROUND_COLOR[knownVendorValue] != null) {
      return KNOWNVENDOR_TO_BACKGROUND_COLOR[knownVendorValue];
    }
  }

  // Fallback for colors without a mapping.
  // For missing color for brands, we need to ask Design for a new color
  if (str.length === 0) return vars.color.textBrand;
  str = str.toLocaleLowerCase();

  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    // Using value [0, 128] to ensure color is dark
    const value = (hash >> (i * 8)) & (255 / 2);
    color += ('00' + value.toString(16)).slice(-2);
  }
  return color;
}

export function mapBrandNameToTextcolor(
  str: string | Marketplace | KnownVendors
): string {
  const marketplaceValue = Object.values(Marketplace).find(
    (m) => m.toString().toLocaleLowerCase() === str.toLocaleLowerCase()
  );

  if (marketplaceValue) {
    if (MARKETPLACE_TO_TEXT_COLOR[marketplaceValue] != null) {
      return MARKETPLACE_TO_TEXT_COLOR[marketplaceValue];
    }
  }

  const knownVendorValue = Object.values(KnownVendors).find(
    (m) => m.toString().toLocaleLowerCase() === str.toLocaleLowerCase()
  );

  if (knownVendorValue) {
    if (KNOWNVENDOR_TO_TEXT_COLOR[knownVendorValue] != null) {
      return KNOWNVENDOR_TO_TEXT_COLOR[knownVendorValue];
    }
  }

  return vars.color.marketplaceTextDefault;
}
