import { AutoPricingSection } from 'src/modals/ListingDetails/components/PricingSection/AutoPricingSection';

export const AutoPricingSettings = ({
  isLoading,
  hasParents,
  isGlobalEdit,
}: {
  isLoading?: boolean;
  hasParents?: boolean;
  isGlobalEdit?: boolean;
}) => {
  return (
    <>
      <AutoPricingSection
        disabled={isLoading}
        listingHasParent={hasParents}
        isBulkEdit
        isGlobalEdit={isGlobalEdit}
      />
    </>
  );
};
