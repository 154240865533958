import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function EditIcon(props: IconsProps) {
  return (
    <SvgWrapper aria-label="EditIcon" {...props}>
      <path
        d="M17.968 8.341l-3.385-3.384 1.115-1.115a.77.77 0 011.115 0l3.385 3.384a.77.77 0 010 1.115l-.557.558 1.672.557c.916-.915.916-2.429 0-3.384l-3.385-3.385c-.916-.916-2.429-.916-3.384 0l-9.16 9.159L2 22l10.154-3.385 8.004-8.004-1.115-1.115-1.075-1.155zm-13.38 11.15L6.5 13.757l3.823 3.823-5.735 1.911zm7.208-2.708l-4.5-4.5 6.212-6.211 4.5 4.5-6.212 6.211z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
