import { MultiSelectActionsContainer } from 'src/components/common/MultiSelect/MultiSelectActionsContainer';
import { SettingsActions } from 'src/components/Sales/BulkActions/SalesEventBulkActions/Actions/SettingsActions';
import { TagsActions } from 'src/components/Sales/BulkActions/SalesEventBulkActions/Actions/TagsActions';
import { FulfillmentActions } from 'src/components/Sales/BulkActions/SalesGlobalBulkActions/Actions/FulfillmentActions';
import { EventWithData } from 'src/WebApiController';

interface SalesMonthlyBulkActionsProps {
  event: EventWithData['event'];
}

export const SalesMonthlyBulkActions = ({
  event,
}: SalesMonthlyBulkActionsProps) => {
  return (
    <MultiSelectActionsContainer>
      <TagsActions event={event} />
      <FulfillmentActions event={event} />
      <SettingsActions event={event} />
    </MultiSelectActionsContainer>
  );
};
