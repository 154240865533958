import { IconButton } from 'src/components/Buttons';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

export const MultiSelectCloseButton = () => {
  const { setSelectionMode } = useMultiSelectionContext();

  return (
    <IconButton
      onClick={() => setSelectionMode(undefined)}
      titleContentId={ContentId.Remove}
      icon={<CrossIcon withHoverEffect />}
    />
  );
};
