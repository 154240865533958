import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const UploadETicketsInstructions = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const UploadETicketsSubInstructions = styled.p`
  color: ${(props) => props.theme.colors.textPrimary};
  font-size: ${() => vars.typography.fontSize['sm']};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UploadETicketsThumbnailContainer = styled.div`
  min-width: 200px;
  margin-inline-end: ${() => vars.spacing.sm};
  height: calc(100% - ${() => vars.spacing.xl});
  overflow-y: auto;

  .draggable-page {
    padding: ${() => vars.spacing.xl};
    &:hover {
      background-color: ${(props) => props.theme.colors.backgroundSecondary};
    }
  }

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      margin-inline-end: ${vars.spacing.lg};
    `
    )}`}
`;

export const UploadETicketsThumbnailLoader = styled.div`
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
  padding: ${() => vars.spacing.lg};
  &:not(:last-child) {
    margin-bottom: ${() => vars.spacing.lg};
  }
`;

export const UploadETicketsThumbnailAssigned = styled.div`
  padding: ${() => vars.spacing.xl};
  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
  }
`;

export const UploadETicketsThumbnailAssignedMappedTo = styled.div`
  display: flex;
  div {
    margin-left: ${() => vars.spacing.sm};
  }
`;

export const UploadETicketsSeatingContainer = styled.div`
  height: calc(100% - ${() => vars.spacing.xl});
  overflow-y: auto;
  overflow-x: hidden;

  .draggable-seat {
    &:hover {
      background-color: ${(props) => props.theme.colors.backgroundSecondary};
    }
  }
`;

export const UploadETicketsSeatingPlaceholder = styled.div`
  color: ${(props) => props.theme.colors.textPrimaryHover};
  padding: ${() => vars.spacing.lg};

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding: ${vars.spacing['3xl']};
    `
    )}`}
`;

export const UploadETicketsSeat = styled.div`
  height: fit-content;
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: ${() => vars.spacing.lg};
  }
`;

export const PreviewTicket = styled.div`
  width: 100%;
  margin-bottom: ${() => `${vars.spacing.lg}`};
`;

export const Divider = styled.hr`
  border: none;
  border-bottom: 4px dotted ${(props) => props.theme.colors.borderPrimary};
  margin: ${() => `${vars.spacing.sm} 0`};
`;

export const SeatLabel = styled.div`
  margin-bottom: ${() => `${vars.spacing.sm}`};
`;
