import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePurchaseVendorAccountSelector } from 'src/components/Selectors/PurchaseVendorAccountSelector/usePurchaseVendorAccountSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { PurchaseVendorAccountDialog } from 'src/dialogs/PurchaseVendorAccountDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { VendorAccountEditInfo } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useAddVendorAccount';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField, posField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  Feature,
  PurchaseClient,
  PurchaseVendorAccount,
  PurchaseVendorAccountDetails,
} from 'src/WebApiController';

export type PurchaseVendorAccountSelectionInputProps = Omit<
  PosSelectProps,
  'onChange' | 'valueOptionsContent'
> & {
  vendorFieldName: 'vendor' | 'secondaryVendor';
  fieldName: 'vendorAccount' | 'secondaryVendorAccount';
};

export const PurchaseVendorAccountSelectionInput = ({
  disabled,
  vendorFieldName,
  fieldName,
  ...posSelectProps
}: PurchaseVendorAccountSelectionInputProps) => {
  const hasVendorAccountDeliveryStrategyFeature = useUserHasFeature(
    Feature.VendorAccountDeliveryStrategy
  );

  const { setValue, clearErrors, watch, formState } =
    useFormContext<PurchaseOrderDetailsInput>();
  const { activeAccountWebClientConfig } = useAppContext();
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const [currentVendorAccount, setCurrentVendorAccount] =
    useState<VendorAccountEditInfo>();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const vendorId = watch(`${vendorFieldName}.value.id`);
  const vendorAccount = watch(fieldName);

  const addVendorAccountText = useContent(ContentId.AddAccountAction);
  const additionalOptions = useMemo(
    () => ({
      [ContentId.AddAccountAction]: addVendorAccountText + '...',
    }),
    [addVendorAccountText]
  );
  const optionsAlwaysShow = useMemo(
    () => ({
      [ContentId.AddAccountAction]: true,
    }),
    []
  );
  const {
    query,
    sortedVendorAccounts,
    posSelectProps: hookPosSelectProps,
  } = usePurchaseVendorAccountSelector({
    vendorId: vendorId,
    disabled,
    additionalOptions,
    searchText,
    purchaseVendorAccountEmail: vendorAccount?.value?.email,
  });

  const { fetchNextPage, hasNextPage, isFetchingNextPage } = query;

  useEffect(() => {
    // If we don't have a selected account, auto-select the first one in the list
    if (
      vendorId &&
      (!vendorAccount || vendorAccount?.value?.vendorId !== vendorId)
    ) {
      const accounts = sortedVendorAccounts;
      if (accounts?.length) {
        clearErrors(fieldName);
        setValue(fieldName, posChangedField(accounts[0]));
      } else {
        setValue(fieldName, posField(null));
      }
    }
  }, [
    clearErrors,
    setValue,
    vendorAccount,
    vendorId,
    query.data,
    fieldName,
    sortedVendorAccounts,
  ]);

  const onAddNewVendorAccount = useCallback(
    (searchText?: string) => {
      if (vendorId) {
        setCurrentVendorAccount({
          vendorId: vendorId,
          id: '',
        });
      }

      launchDialog();
    },
    [launchDialog, vendorId]
  );

  const onSaveVendorAccount = useCallback(
    async (vendorAccount: PurchaseVendorAccountDetails) => {
      await tryInvokeApi(
        async () => {
          const newId = await new PurchaseClient(
            activeAccountWebClientConfig
          ).mergeVendorAccount(vendorAccount);

          if (newId) {
            query.refetch();
            setValue(
              fieldName,
              posChangedField({
                ...vendorAccount,
                id: newId,
              })
            );
            clearErrors(fieldName);

            closeDialog();
            setCurrentVendorAccount(undefined);
          } else {
            // This should never happen
            // Normal errors would have fallen to the error catch
            showErrorDialog(
              'PurchaseClient.mergeVendorAccount',
              {
                message:
                  'Unable to create or save vendor account ' + vendorAccount.id,
                status: 500,
              } as ApiException,
              {
                trackErrorData: vendorAccount,
              }
            );
          }
        },
        (error) => {
          showErrorDialog('PurchaseClient.mergeVendorAccount', error, {
            trackErrorData: vendorAccount,
          });
        }
      );
    },
    [
      activeAccountWebClientConfig,
      clearErrors,
      closeDialog,
      fieldName,
      query,
      setValue,
      showErrorDialog,
    ]
  );

  const onVendorAccountCancel = useCallback(() => {
    closeDialog();
    setCurrentVendorAccount(undefined);
  }, [closeDialog]);

  return (
    <>
      <PosSelect
        {...posSelectProps}
        {...hookPosSelectProps}
        sortMode="none" // Don't sort. Use current sorting
        valueOptionsAlwaysShow={optionsAlwaysShow}
        disabled={disabled}
        hasErrors={Boolean(formState.errors.vendorAccount?.message)}
        value={vendorAccount?.value?.id}
        onChange={(value, searchText) => {
          clearErrors(fieldName);
          if (value) {
            if (value === ContentId.AddAccountAction) {
              onAddNewVendorAccount(searchText);
            } else if (value !== vendorAccount?.value?.id) {
              const newAccount = sortedVendorAccounts!.find(
                (v: PurchaseVendorAccount) => v.id === value
              );
              if (newAccount) {
                setValue(fieldName, posChangedField(newAccount));
              }
            }
          }
        }}
        onSearchInputChange={setSearchText}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetchingNextPage={isFetchingNextPage}
        useVirtualWindow={true}
      />
      {vendorId && currentVendorAccount && (
        <PurchaseVendorAccountDialog
          {...dialogProps}
          vendorAccountId={currentVendorAccount.id}
          vendorId={currentVendorAccount.vendorId}
          onClosed={onVendorAccountCancel}
          onSave={onSaveVendorAccount}
        />
      )}
    </>
  );
};
