import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { REACT_QUERY_CACHE_MAX_AGE_IN_MS } from 'src/utils/constants/constants';
import { DeliveryType, TicketClient, TicketType } from 'src/WebApiController';

export function useTicketTypes(deliveryType?: DeliveryType | null) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const mappingsQuery = useQuery({
    queryKey: [
      'getAllDeliveryTypeTicketTypeMappings',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () =>
      new TicketClient(
        activeAccountWebClientConfig
      ).getAllDeliveryTypeTicketTypeMappings(),
    refetchOnWindowFocus: false,
    staleTime: REACT_QUERY_CACHE_MAX_AGE_IN_MS,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog(
          'TicketClient.getAllDeliveryTypeTicketTypeMappings',
          error
        );
      },
    },
  });

  const ticketTypes = useMemo(() => {
    if (mappingsQuery.isLoading) return [];

    const mapping =
      deliveryType == null
        ? null
        : mappingsQuery.data?.find((m) => m.deliveryType === deliveryType)
            ?.ticketTypes;

    return mapping
      ? Object.entries(mapping)
          .sort((a, b) => a[1][0] - b[1][0])
          .map((a) => a[0] as TicketType)
      : Object.values(TicketType);
  }, [deliveryType, mappingsQuery.data, mappingsQuery.isLoading]);

  return { ticketTypes };
}
