import { MetricTile } from 'src/components/Metrics';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { InventorySummaryListingMetricsDisplayStrings } from 'src/utils/ticketMetricUtils';

import * as styles from './InventorySummaryWidgets.css';

export const TicketsListedAndSoldDisplay = ({
  formattedTotalMetrics,
}: {
  formattedTotalMetrics:
    | InventorySummaryListingMetricsDisplayStrings
    | undefined
    | null;
}) => {
  return (
    <MetricTile.Root className={styles.metric}>
      <MetricTile.Title isCompact>
        <Content id={ContentId.ListedAndSold} />
      </MetricTile.Title>
      <div className={styles.metricWithVisualContainer}>
        <MetricTile.CircleProgressBar
          size={48}
          value={(formattedTotalMetrics?.totalSoldPercentage ?? 0) * 100}
        >
          {formattedTotalMetrics?.formattedTotalSoldPercentage}
        </MetricTile.CircleProgressBar>
        <MetricTile.Metric
          isCompact
          showLoadingPlaceholders={!formattedTotalMetrics}
        >
          <MetricTile.FractionMetricText
            numerator={formattedTotalMetrics?.formattedTotalSoldQuantity}
            denominator={formattedTotalMetrics?.formattedTotalTicketQuantity}
          />
        </MetricTile.Metric>
      </div>
    </MetricTile.Root>
  );
};
