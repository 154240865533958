import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import {
  EventMapContextProvider,
  useEventMapContext,
} from 'src/contexts/EventMapContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useAvailableAutopricingModes } from 'src/hooks/useAvailableAutopricingModes';
import { useGetAccountAutoPricingSettings } from 'src/hooks/useGetAccountAutoPricingSettings';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import { useUserCanSetPrice } from 'src/hooks/useUserHasListingPermissions';
import { PosIconProps } from 'src/svgs/SvgWrapper';
import { PricingSettingsField } from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import {
  COMP_LISTING_MODE_TO_CID,
  UNDERCUT_MODE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import {
  AutoPricingCompListingMode,
  AutoPricingUndercutMode,
  Event,
  Listing,
} from 'src/WebApiController';

import * as styles from '../ListingTable.css';
import { ListingPricingInput } from './ListingPriceForm.types';

type PricingModeCellProps = {
  pricingMode?:
    | AutoPricingCompListingMode
    | AutoPricingUndercutMode
    | undefined;
  DisplayIcon?: ((props: PosIconProps) => JSX.Element) | null;
  listing: Listing;
  editable?: boolean;
  field: PricingSettingsField;
  disabled?: boolean;
  event: Event;
};

export const PricingModeCell = ({
  pricingMode,
  DisplayIcon,
  listing,
  editable,
  field,
  disabled,
  event,
}: PricingModeCellProps) => {
  const modeContentId =
    field === 'compListingMode'
      ? COMP_LISTING_MODE_TO_CID[pricingMode as AutoPricingCompListingMode]
      : UNDERCUT_MODE_TO_CID[pricingMode as AutoPricingUndercutMode];
  const modeContent = useContent(modeContentId ?? '');
  const { watch } = useFormContext<ListingPricingInput>();

  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);
  const isSelectionMode = listingSelection.isSingleGroupMultiSelect;

  const [isExpanded, setIsExpanded] = useState(false);

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingPrice = watch('isSubmitting');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');
  const autoPricingEnabled = watch('autoPricingEnabled');

  const canSetPrice = useUserCanSetPrice(listing, false);

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const isPricingModeDisabled =
    fieldIsSubmitting ||
    disabled ||
    !autoPricingEnabled ||
    !canSetPrice ||
    listing.isDeleted ||
    isSelectionMode;

  const canEdit = editable && !isPricingModeDisabled;

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <EventMapContextProvider
        event={event}
        disabled={!isExpanded || !autoPricingEnabled}
      >
        <PosDropdown
          trigger={
            <div
              className={canEdit ? styles.textEditable : styles.textReadonly}
            >
              {DisplayIcon ? (
                <DisplayIcon
                  fill={'transparent'}
                  title={modeContent}
                  stroke={canEdit ? vars.color.textBrand : undefined}
                />
              ) : (
                <Content
                  id={
                    pricingMode
                      ? modeContentId
                      : isPricingModeDisabled
                      ? ''
                      : ContentId.Edit
                  }
                />
              )}
            </div>
          }
          rootProps={{
            onOpenChange: (isOpen: boolean) => {
              if (!canEdit) {
                setIsExpanded(false);
                return;
              }
              setIsExpanded(isOpen);
            },
            open: isExpanded,
          }}
        >
          <PricingModeCellContent
            listing={listing}
            field={field}
            disabled={disabled}
            event={event}
          />
        </PosDropdown>
      </EventMapContextProvider>
    </TableCellDiv>
  );
};

const PricingModeCellContent = ({
  listing,
  field,
  disabled,
  event,
}: PricingModeCellProps) => {
  const { setValue, watch, getFieldState, formState } =
    useFormContext<ListingPricingInput>();
  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);
  const isSelectionMode = listingSelection.isSingleGroupMultiSelect;

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');
  const autoPricingEnabled = watch('autoPricingEnabled');
  const undercutMode = watch('undercutMode');
  const compListingMode = watch('compListingMode');
  const fieldValue = watch(field);

  const canSetPrice = useUserCanSetPrice(listing, false);

  const fieldIsSubmitting =
    isSubmittingPricingSettings &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const isPricingModeDisabled =
    fieldIsSubmitting ||
    disabled ||
    !autoPricingEnabled ||
    !canSetPrice ||
    listing.isDeleted ||
    isSelectionMode;

  const fieldError = getFieldState(field, formState)?.error?.message;

  const fieldHasChanged =
    formState.defaultValues?.[field] !== fieldValue &&
    fieldValue &&
    !isSubmittingPricingSettings;

  const submit = useCallback(() => {
    if (fieldError) {
      return;
    }
    if (fieldHasChanged) {
      // Only submit if Enter is hit in the box that has changes
      setValue('isSubmittingPricingSettings', true);
      setValue('isSubmittingRowIndex', rowIndex);
    }
  }, [fieldError, fieldHasChanged, setValue, rowIndex]);

  useEffect(() => {
    submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue]);

  const { activeConfigOverride, venueMapInfo } = useEventMapContext();
  const scores = getCompleteEventConfigScoreOverrides(
    venueMapInfo?.sectionScores,
    activeConfigOverride?.scoreOverrides,
    false
  );

  const {
    compListingModes,
    compListingModesDisabled,
    undercutModes,
    undercutModesDisabled,
  } = useAvailableAutopricingModes(
    false,
    undercutMode,
    compListingMode,
    Boolean(scores?.some((s) => s.score)),
    Boolean(listing?.ltGrpParentId)
  );

  const { pricingSettings: eventPricingSettings } =
    useGetEventAutoPricingSettings(event, false);
  const { pricingSettings: accountPricingSettings } =
    useGetAccountAutoPricingSettings();

  const inheritText = useContent(ContentId.Inherit);
  const inheritCompListingMode = useContent(
    compListingModes[
      eventPricingSettings?.compListingMode ??
        accountPricingSettings?.compListingMode ??
        ''
    ] ?? ''
  );
  const inheritUndercutMode = useContent(
    undercutModes[
      eventPricingSettings?.undercutMode ??
        accountPricingSettings?.undercutMode ??
        ''
    ] ?? ''
  );

  const compListingOptions = Object.entries(compListingModes).map(
    ([value, children]) => (
      <PosDropdownItem
        key={value}
        onClick={(e) => {
          e.stopPropagation();
          setValue(field, value as AutoPricingCompListingMode);
        }}
        disabled={
          isPricingModeDisabled || compListingModesDisabled[value] != null
        }
      >
        <Content id={children} />
      </PosDropdownItem>
    )
  );
  if (inheritCompListingMode) {
    compListingOptions.unshift(
      <PosDropdownItem
        key="inherit"
        onClick={(e) => {
          e.stopPropagation();
          setValue(field, null);
        }}
        disabled={isPricingModeDisabled}
      >
        {`${inheritText} (${inheritCompListingMode})`}
      </PosDropdownItem>
    );
  }

  const undercutOptions = Object.entries(undercutModes).map(
    ([value, children]) => (
      <PosDropdownItem
        key={value}
        onClick={(e) => {
          e.stopPropagation();
          setValue(field, value as AutoPricingUndercutMode);
        }}
        disabled={isPricingModeDisabled || undercutModesDisabled[value] != null}
      >
        <Content id={children} />
      </PosDropdownItem>
    )
  );
  if (inheritUndercutMode) {
    undercutOptions.unshift(
      <PosDropdownItem
        key="inherit"
        onClick={(e) => {
          e.stopPropagation();
          setValue(field, null);
        }}
        disabled={isPricingModeDisabled}
      >
        {`${inheritText} (${inheritUndercutMode})`}
      </PosDropdownItem>
    );
  }

  return (
    <PosFormField
      errors={isPricingModeDisabled ? undefined : fieldError}
      showErrorsInline
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
        {field === 'compListingMode' ? compListingOptions : undercutOptions}
      </div>
    </PosFormField>
  );
};
