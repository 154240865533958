import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosSelect } from 'src/core/POS/PosSelect';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { LISTING_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/ListingTable/configs/ListingReportTableColumnsConfig';
import { SALE_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleReportTableColumnsConfig';
import { isMetricConfigurableByGroupBy as isListingMetricConfigurableByGroupBy } from 'src/utils/columns/inventory/inventoryColumnUtils';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING } from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import { isMetricConfigurableByGroupBy as isSaleMetricConfigurableByGroupBy } from 'src/utils/columns/sales/salesColumnUtils';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES } from 'src/utils/columns/sales/salesReportColumnUtils.constants';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypes } from 'src/utils/reportsUtils';
import { ReportGroupBy } from 'src/WebApiController';

interface GroupBySelectorProps {
  options: Record<string, ContentId>;
  reportType: ReportTypes;
  metricsFiltered: string[];
}

export const GroupBySelector = ({
  options,
  reportType,
  metricsFiltered = [],
}: GroupBySelectorProps) => {
  const [newGroupBy, setNewGroupBy] = useState<ReportGroupBy>();
  const { watch, clearErrors, setValue } = useFormContext<ReportConfig>();
  const changeGroupByConfirmDialog = useBasicDialog();

  const groupBy = watch('groupBy');
  const metrics = watch('metrics');

  const filterColumnByGroupBy = useCallback(
    (columnId: string, groupBy: ReportGroupBy) => {
      if (reportType === ReportTypes.Inventory) {
        return isListingMetricConfigurableByGroupBy(columnId, groupBy);
      } else if (reportType === ReportTypes.Sale) {
        return isSaleMetricConfigurableByGroupBy(columnId, groupBy);
      }

      return true;
    },
    [reportType]
  );

  const updateGroupBy = useCallback(
    (newGroupBy: ReportGroupBy) => {
      clearErrors('groupBy');
      setValue('groupBy', newGroupBy);

      // Bring the primary columns to the front if they are not already there
      const GROUP_BY_TO_PRIMARY_COLUMN_ID =
        reportType === ReportTypes.Inventory
          ? GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING
          : GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES;
      const primaryColumnId = GROUP_BY_TO_PRIMARY_COLUMN_ID[newGroupBy];

      if (!primaryColumnId || metrics.includes(primaryColumnId)) {
        return;
      }

      setValue('metrics', [primaryColumnId, ...metrics]);
    },
    [clearErrors, metrics, reportType, setValue]
  );

  const onTileClick = useCallback(
    (newGrpType: ReportGroupBy) => {
      if (newGrpType && newGrpType !== groupBy) {
        const presetColumnIdsUnfiltered = Object.values(
          reportType === ReportTypes.Inventory
            ? LISTING_REPORT_TABLE_COLUMNS_CONFIG
            : SALE_REPORT_TABLE_COLUMNS_CONFIG
        ).map((i) => i.id);

        const presetColumnIdsBeforeChange = presetColumnIdsUnfiltered.filter(
          (id) =>
            metricsFiltered.includes(id) && filterColumnByGroupBy(id, groupBy)
        );
        const presetColumnIdsAfterChange = presetColumnIdsUnfiltered.filter(
          (id) =>
            metricsFiltered.includes(id) &&
            filterColumnByGroupBy(id, newGrpType)
        );

        if (
          presetColumnIdsAfterChange.length < presetColumnIdsBeforeChange.length
        ) {
          setNewGroupBy(newGrpType);
          changeGroupByConfirmDialog.launchDialog();
          return;
        }

        updateGroupBy(newGrpType);
      }
    },
    [
      changeGroupByConfirmDialog,
      filterColumnByGroupBy,
      groupBy,
      metricsFiltered,
      reportType,
      updateGroupBy,
    ]
  );

  const onChangeGroupByConfirm = useCallback(() => {
    if (newGroupBy) {
      updateGroupBy(newGroupBy);
      setNewGroupBy(undefined);
    }
    changeGroupByConfirmDialog.closeDialog();
  }, [changeGroupByConfirmDialog, newGroupBy, updateGroupBy]);

  return (
    <>
      <PosSelect
        style={{ width: '100%' }}
        value={groupBy}
        onChange={(value) => {
          onTileClick(value as ReportGroupBy);
        }}
        valueOptionsContent={options}
        placeholderText={'Placeholder'}
      />
      <ConfirmDialog
        {...changeGroupByConfirmDialog.dialogProps}
        size={'m'}
        headerText={<Content id={ContentId.ChangeGroupBy} />}
        bodyText={<Content id={ContentId.ChangeGroupByWarning} />}
        onOkay={onChangeGroupByConfirm}
        onCancel={() => changeGroupByConfirmDialog.closeDialog()}
      />
    </>
  );
};
