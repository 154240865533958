import { MouseEventHandler, ReactNode } from 'react';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import * as styles from './NotificationDisplay.css';

type NotificationDisplayProps = {
  notificationIntent: keyof typeof styles.notificationType;
  /**
   * Type of notification.
   *
   * eg. Listing Broadcast Failed
   */
  notificationType?: ReactNode;
  /**
   * When this notification was triggered.
   *
   * eg. 1 day ago
   */
  timeAgo?: ReactNode;
  /**
   * Identifier for the entity this notification represents.
   *
   * eg. Sale #904398
   */
  entity?: ReactNode;
  entityType?: string;
  /**
   * Seating info.
   *
   * eg. 123 Row A Seats 1 - 10
   */
  seating?: ReactNode;
  /**
   * Pass the event header into here.
   */
  children?: ReactNode;
  onGoToEntity?: MouseEventHandler<HTMLButtonElement>;
  onDismiss?: MouseEventHandler<HTMLButtonElement>;
};

/**
 * @param props
 * @returns
 */
export function NotificationDisplay({
  notificationIntent,
  notificationType,
  timeAgo,
  entity,
  entityType,
  seating,
  children,
  onGoToEntity,
  onDismiss,
}: NotificationDisplayProps) {
  return (
    <Stack direction="column" gap="m">
      <Stack className={styles.header} justifyContent="spaceBetween" gap="m">
        <div className={styles.headerLeft}>
          {notificationType && (
            <span className={styles.notificationType[notificationIntent]}>
              {notificationType}
            </span>
          )}
          {timeAgo && <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;{timeAgo}</span>}
        </div>
        {entity && <div className={styles.entity}>{entity}</div>}
      </Stack>
      {children}
      {seating}
      {(onGoToEntity || onDismiss) && (
        <Stack alignItems="center" gap="m" justifyContent="end">
          {onGoToEntity && entityType && (
            <Button variant="text" shape="inline" onClick={onGoToEntity}>
              <FormatContent id={FormatContentId.GoTo} params={entityType} />
            </Button>
          )}
          {onDismiss && (
            <Button
              variant="text"
              shape="inline"
              textColor="primary"
              onClick={onDismiss}
            >
              <Content id={ContentId.Dismiss} />
            </Button>
          )}
        </Stack>
      )}
    </Stack>
  );
}
