import { useMemo } from 'react';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import {
  PosMultiSelect,
  PosMultiSelectProps,
} from 'src/core/POS/PosMultiSelect';
import { ContentId } from 'src/utils/constants/contentId';

export type TicketClassSelectorProps = Omit<
  PosMultiSelectProps,
  'valueOptionsContent'
>;

export function TicketClassSelector({
  disabled,
  placeholderText,
  ...props
}: TicketClassSelectorProps) {
  const { ticketClasses } = useEventMapContext();
  const valueOptionsContent = useMemo(() => {
    const ticketClassOptions = ticketClasses
      ?.sort((s1, s2) => s1.name.localeCompare(s2.name))
      .reduce(
        (r, s) => {
          r[s.id.toString()] = s.name;
          return r;
        },
        {} as Record<string, string>
      );

    return ticketClassOptions ?? {};
  }, [ticketClasses]);

  return (
    <PosMultiSelect
      {...props}
      disabled={disabled}
      loading={ticketClasses === undefined}
      searchable
      valueOptionsContent={valueOptionsContent}
      placeholderText={placeholderText ?? ContentId.ChooseZone}
    />
  );
}
