import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useContext } from 'react';
import { IconButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useSellerRoleContext } from 'src/contexts/SellerRoleContext';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { Stack } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { EditIcon, UnHideIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SellerUserAccountInfo } from 'src/WebApiController';

import { EditUserModal } from '../EditUser/EditUserModal';
import { getSellerPermissionDisplayModalConfig } from '../SellerPermissionDisplayModal';
import * as styles from '../UsersManagement.css';

const columnHelper = createColumnHelper<SellerUserAccountInfo | null>();

export const USERS_TABLE_COLUMNS_CONFIG = [
  columnHelper.accessor('displayName', {
    id: 'name',
    enableSorting: true,
    sortingFn: 'text',
    header: () => (
      <TableCellDiv align="left">
        <Content id={ContentId.DisplayName} />
      </TableCellDiv>
    ),
    cell: ({ getValue }) => (
      <TableCellDiv align="left">{getValue()}</TableCellDiv>
    ),
  }),
  columnHelper.accessor('email', {
    id: 'email',
    header: () => (
      <TableCellDiv align="left">
        <Content id={ContentId.Email} />
      </TableCellDiv>
    ),
    cell: ({ getValue }) => (
      <TableCellDiv align="left">
        <div className={styles.emailCell}>{getValue()}</div>
      </TableCellDiv>
    ),
  }),
  columnHelper.accessor('isDeactivated', {
    id: 'status',
    header: () => (
      <TableCellDiv align="left">
        {' '}
        <Content id={ContentId.Status} />
      </TableCellDiv>
    ),
    cell: ({ getValue }) => (
      <TableCellDiv align="left" showTooltip={false}>
        {!getValue() ? (
          <div className={styles.activeBadge}>
            <Content id={ContentId.Active} />
          </div>
        ) : (
          <div className={styles.inactiveBadge}>
            <Content id={ContentId.Deactivated} />
          </div>
        )}
      </TableCellDiv>
    ),
  }),
  columnHelper.accessor('roles', {
    id: 'role',
    header: () => (
      <TableCellDiv align="left">
        <Content id={ContentId.Role} />
      </TableCellDiv>
    ),
    cell: function Cell({ getValue }) {
      const roles = getValue();
      const { allSellerRoles } = useSellerRoleContext();

      if (!allSellerRoles) {
        return <ContentPlaceholder />;
      }

      // TODO - get content for those predefined roles

      const roleNames = roles
        .map((r) => allSellerRoles[r]?.name)
        .filter((n) => n);

      return <TableCellDiv align="left">{roleNames.join(', ')}</TableCellDiv>;
    },
  }),
  columnHelper.accessor((data) => data, {
    id: 'actions',
    header: () => (
      <TableCellDiv align="right">
        <Content id={ContentId.Actions} />
      </TableCellDiv>
    ),
    enableSorting: false,
    size: 40,
    cell: function Cell({ getValue }) {
      const { setModal, closeModal } = useContext(ModalContext);
      const data = getValue<SellerUserAccountInfo>();

      const onViewPermissionClick = useCallback(() => {
        setModal(getSellerPermissionDisplayModalConfig(data.roles));
      }, [data.roles, setModal]);

      const onEditClick = useCallback(() => {
        setModal({
          children: <EditUserModal {...data} onClose={closeModal} />,
          clickOutsideToClose: true,
          centered: true,
          size: 'sm',
        });
      }, [closeModal, data, setModal]);

      return (
        <TableCellDiv align="right" showTooltip={false}>
          <Stack>
            <IconButton
              titleContentId={ContentId.ViewAllAccessiblePermissions}
              icon={<UnHideIcon withHoverEffect />}
              onClick={onViewPermissionClick}
            />
            <IconButton
              icon={<EditIcon withHoverEffect />}
              onClick={onEditClick}
            />
          </Stack>
        </TableCellDiv>
      );
    },
  }),
];
