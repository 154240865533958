import { MouseEvent } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useToggle } from 'react-use';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { MultiSelectionToggleGroup } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGroup';
import { Content, FormatContent } from 'src/contexts/ContentContext/Content';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  ColumnSettingsModal,
  TableColumnsSectionType,
} from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { SwitchIcon } from 'src/svgs/SwitchIcon';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { EventIdQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { SectionType } from 'src/utils/types/sectionType';
import { ActionOutboxEntityType, Feature } from 'src/WebApiController';

import { getSidePanelSwitchToUrl } from '../utils';

const SwitchToButton = ({
  sectionType,
  onSwitchSection,
}: {
  sectionType: TableColumnsSectionType;
  onSwitchSection: (sectionType: TableColumnsSectionType) => void;
}) => {
  const switchContentId = (sectionType: TableColumnsSectionType) => {
    switch (sectionType) {
      case SectionType.PurchaseSideTable:
        return ContentId.Purchases;
      case SectionType.InventorySideTable:
        return ContentId.Inventory;
      case SectionType.SalesSideTable:
        return ContentId.Sales;
      default:
        return '';
    }
  };

  return (
    <Button variant="textPlain" onClick={() => onSwitchSection(sectionType)}>
      <SwitchIcon size={vars.iconSize.m} />
      <div style={{ width: 'max-content' }}>
        <FormatContent
          id={FormatContentId.SwitchTo}
          params={<Content id={switchContentId(sectionType)} />}
        />
      </div>
    </Button>
  );
};

export const SidePanelToolbar = ({
  sectionType,
  entityIds,
  filterBar,
  noData,
}: {
  sectionType: TableColumnsSectionType;
  entityIds?: number[];
  filterBar?: React.ReactNode;
  noData?: boolean;
}) => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);
  const [searchParams] = useSearchParams();
  const viagEventId = searchParams.get(EventIdQueryParam);
  const hasFullPageEventViewSideTableBulkEdit = useUserHasFeature(
    Feature.FullPageEventViewSideTableBulkEdit
  );
  const hasFullPageEventViewSideTableFilters = useUserHasFeature(
    Feature.FullPageEventViewSideTableFilters
  );

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };
  const navigate = useNavigate();
  const hasFullPageEventViewEntitySelection = useUserHasFeature(
    Feature.FullPageEventViewEntitySelection
  );

  const onSwitchSection = (sectionType: TableColumnsSectionType) => {
    const route = getSidePanelSwitchToUrl({
      sectionType,
      marketplaceEntityIds: entityIds,
      hasFullPageEventViewEntitySelection,
    });
    if (route) {
      navigate(route);
    }
  };

  const getEntityType = (sectionType: TableColumnsSectionType) => {
    switch (sectionType) {
      case SectionType.PurchaseSideTable:
        return ActionOutboxEntityType.Purchase;
      case SectionType.InventorySideTable:
        return ActionOutboxEntityType.Listing;
      case SectionType.SalesSideTable:
        return ActionOutboxEntityType.Sale;
      default:
        return ActionOutboxEntityType.Listing;
    }
  };

  if (noData) {
    return (
      <Stack
        justifyContent={
          hasFullPageEventViewSideTableFilters ? 'spaceBetween' : 'end'
        }
      >
        {hasFullPageEventViewSideTableFilters && filterBar}
        <SwitchToButton
          sectionType={sectionType}
          onSwitchSection={onSwitchSection}
        />
      </Stack>
    );
  }

  return (
    <Stack
      justifyContent={
        hasFullPageEventViewSideTableFilters ? 'spaceBetween' : 'end'
      }
    >
      {hasFullPageEventViewSideTableFilters && filterBar}
      <Stack justifyContent="end">
        {hasFullPageEventViewSideTableBulkEdit && (
          <Stack alignItems="center">
            <BulkEditStatusPopover entityType={getEntityType(sectionType)} />
            <MultiSelectionToggleGroup groupId={viagEventId || ''} />
          </Stack>
        )}
        <SwitchToButton
          sectionType={sectionType}
          onSwitchSection={onSwitchSection}
        />
        <Button variant="textPlain" onClick={onColumnSettingButtonClickHandler}>
          <LayoutIcon size={vars.iconSize.m} />
          <Content id={ContentId.Columns} />
        </Button>
      </Stack>
      {isColumnModalOpen ? (
        <ColumnSettingsModal<CustomListingColumn>
          onClose={toggleColumnModal}
          sectionType={sectionType}
        />
      ) : null}
    </Stack>
  );
};
