import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const SeatIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5552 6.33301V7.99967H4.66634V4.11079C4.66634 3.19412 5.41634 2.44412 6.33301 2.44412H9.66634C10.583 2.44412 11.333 3.19412 11.333 4.11079V7.44412L12.4441 6.88856V4.11079C12.4441 2.58301 11.1941 1.33301 9.66634 1.33301H6.33301C4.80523 1.33301 3.55523 2.58301 3.55523 4.11079V7.99967H2.44412V6.33301H1.33301V7.99967V9.11079V10.2219C1.33301 10.9441 1.80523 11.5552 2.44412 11.7775V14.6663H3.55523V11.8886H12.4441V14.6663H13.5552V11.7775C14.1941 11.5552 14.6663 10.9441 14.6663 10.2219V9.11079V7.99967V6.33301H13.5552ZM13.5552 10.2219C13.5552 10.5275 13.3052 10.7775 12.9997 10.7775H11.333H4.66634H2.99967C2.69412 10.7775 2.44412 10.5275 2.44412 10.2219V9.11079H13.5552V10.2219Z"
        fill="#677383"
      />
    </SvgWrapper>
  );
};
