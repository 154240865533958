import { IconButton } from 'src/components/Buttons';
import { vars } from 'src/core/themes';
import { ChevronLeftIcon, ChevronRightIcon } from 'src/svgs/Viagogo';

import { useCatchUpParams } from '../InventoryEventPage.hooks';

export function InventoryEventCatchUpPrevButton() {
  const { selectedIndex, numberOfEvents, onPrevClick } = useCatchUpParams();

  const prevButtonDisabled =
    selectedIndex == null || numberOfEvents == null || selectedIndex <= 0;

  return (
    <IconButton
      icon={
        <ChevronLeftIcon
          fill={vars.color.textBrand}
          size={vars.iconSize.m}
          disabled={prevButtonDisabled}
          withHoverEffect
        />
      }
      style={{ padding: vars.spacing['lg'] }}
      disabled={prevButtonDisabled}
      onClick={onPrevClick}
    />
  );
}

export function InventoryEventCatchUpNextButton() {
  const { selectedIndex, numberOfEvents, onNextClick } = useCatchUpParams();

  const nextButtonDisabled =
    selectedIndex == null ||
    numberOfEvents == null ||
    selectedIndex >= numberOfEvents - 1;

  return (
    <IconButton
      icon={
        <ChevronRightIcon
          fill={vars.color.textBrand}
          size={vars.iconSize.m}
          disabled={nextButtonDisabled}
          withHoverEffect
        />
      }
      style={{ padding: vars.spacing['lg'] }}
      disabled={nextButtonDisabled}
      onClick={onNextClick}
    />
  );
}
