import { useCallback } from 'react';
import {
  MultiSectionsDisplay,
  SectionDisplay,
} from 'src/components/Events/VenueMap';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { Button, Stack } from 'src/core/ui';
import { ChangeMultiSectionsScoresDialog } from 'src/dialogs/ChangeMultiSectionsScoresDialog';
import { ChangeSectionScoreDialog } from 'src/dialogs/ChangeSectionScoreDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useEventHeatMapSectionHandler } from 'src/hooks/useEventHeatMapSectionHandler';
import {
  HeatMapColors,
  HeatMapColorSteps,
  useHeatMapColor,
} from 'src/hooks/useHeatMapColor';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EventScoreUpdate } from 'src/modals/EventPricingSeatMap/EventPricingSeatMapBody';
import { ContentId } from 'src/utils/constants/contentId';
import {
  updateScoreForSectionMaintainingRowScoreRatio,
  updateScoreMaintainingRowScoreRatio,
} from 'src/utils/seatScoreUtils';
import {
  Feature,
  SectionInfo,
  SectionScoreOverride,
} from 'src/WebApiController';

export const EventVenueHeatMap = ({
  scoreOverrides,
  configPayload,
  onSubmitSectionScoreChange,
}: {
  scoreOverrides?: SectionScoreOverride[] | null;
  configPayload: string | null;
  onSubmitSectionScoreChange: (updates: EventScoreUpdate) => Promise<void>;
}) => {
  const { trackError } = useErrorBoundaryContext();

  const { getUiCurrency } = useLocalizationContext();
  const { loginContext } = useAppContext();
  const changeSectionScoreDialog = useBasicDialog();
  const changeMultiSectionsScoresDialog = useBasicDialog();

  const uiCurrency = getUiCurrency(
    loginContext?.user?.activeAccount?.currencyCode
  );
  const currencyDisplay = uiCurrency?.sym ?? '$';
  const { getColor } = useHeatMapColor(scoreOverrides);

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  const onSectionHovered = useCallback(
    (hoveredSection: SectionInfo) => (
      <SectionDisplay
        section={hoveredSection}
        scoreOverrides={scoreOverrides}
      />
    ),
    [scoreOverrides]
  );

  const onLaunchingSingleSectionDialog = useCallback(
    () => changeSectionScoreDialog.launchDialog(),
    [changeSectionScoreDialog]
  );

  const {
    selectedSection,
    selectedSectionIds,
    selectedSectionData,
    surroundingSections,
    onSectionClicked,
    onResetSections,
    onToggleMirrors,
    onSelectedSectionsChange,
  } = useEventHeatMapSectionHandler(onLaunchingSingleSectionDialog);

  const onSectionSeatScoreChanged = useCallback(
    (newScore: number, sectionId: number) => {
      const newScoreOverrides = updateScoreForSectionMaintainingRowScoreRatio(
        newScore,
        sectionId,
        scoreOverrides
      );

      if (newScoreOverrides?.length) {
        onSubmitSectionScoreChange({ newScoreOverrides }).then(() => {
          changeSectionScoreDialog.closeDialog();
        });
      } else {
        trackError(
          `Cannot update score for section ${sectionId} because it is not a valid section.`,
          null,
          { newScore, sectionId }
        );
        changeSectionScoreDialog.closeDialog();
      }
    },
    [
      scoreOverrides,
      onSubmitSectionScoreChange,
      changeSectionScoreDialog,
      trackError,
    ]
  );

  const onMultiSectionsSeatScoresChanged = useCallback(
    (newScores: { sectionId: number; score: number | null }[]) => {
      const newScoreOverrides = [...(scoreOverrides ?? [])];

      const notFoundSections: number[] = [];
      newScores.forEach((s) => {
        const overridesToEdit = newScoreOverrides?.filter(
          (so) => so.sectionId === s.sectionId
        );

        if (overridesToEdit.length) {
          if (s.score != null) {
            updateScoreMaintainingRowScoreRatio(s.score, overridesToEdit);
          }
        } else {
          notFoundSections.push(s.sectionId);
        }
      });

      if (notFoundSections.length === 0) {
        onSubmitSectionScoreChange({ newScoreOverrides }).then(() => {
          changeMultiSectionsScoresDialog.closeDialog();
        });
      } else {
        trackError(
          `Cannot update score for sections ${notFoundSections} because they are not valid overrides.`,
          null,
          { newScores }
        );
        changeMultiSectionsScoresDialog.closeDialog();
      }
    },
    [
      changeMultiSectionsScoresDialog,
      onSubmitSectionScoreChange,
      scoreOverrides,
      trackError,
    ]
  );

  return (
    <>
      <EventVenueMap
        selectedSectionIds={selectedSectionIds}
        onSectionClicked={onSectionClicked}
        onSectionHovered={onSectionHovered}
        onToggleMirrors={onToggleMirrors}
        setSelectedSections={onSelectedSectionsChange}
        colorBandProps={{
          numOfSteps: HeatMapColorSteps,
          spectrum: HeatMapColors,
          labels: [
            `${currencyDisplay}${currencyDisplay}${currencyDisplay}`,
            `${currencyDisplay}`,
          ],
        }}
        staticInfoContent={
          selectedSectionData && (
            <Stack direction="column" gap="l">
              <MultiSectionsDisplay
                sections={selectedSectionData}
                scoreOverrides={scoreOverrides}
                onClearSelection={onResetSections}
              />
              <Button
                size="md"
                onClick={() => changeMultiSectionsScoresDialog.launchDialog()}
              >
                <Content id={ContentId.EditScores} />
              </Button>
            </Stack>
          )
        }
        infoIconContent={
          hasAutoPricingFeature ? (
            <Content id={ContentId.AutoPricingDisclaimer} />
          ) : undefined
        }
        getColor={getColor}
        useRowMap={true}
        isHeatMap={true}
      />
      <ChangeSectionScoreDialog
        {...changeSectionScoreDialog.dialogProps}
        selectedSection={selectedSection}
        configPayload={configPayload}
        surroundingSections={surroundingSections}
        scoreOverrides={scoreOverrides}
        onOkay={onSectionSeatScoreChanged}
        onCancel={changeSectionScoreDialog.closeDialog}
        onSubmitSectionScoreChange={onSubmitSectionScoreChange}
      />
      <ChangeMultiSectionsScoresDialog
        {...changeMultiSectionsScoresDialog.dialogProps}
        selectedSections={selectedSectionData ?? []}
        scoreOverrides={scoreOverrides}
        configPayload={configPayload}
        onOkay={onMultiSectionsSeatScoresChanged}
        onCancel={changeMultiSectionsScoresDialog.closeDialog}
        onSubmitSectionScoreChange={onSubmitSectionScoreChange}
      />
    </>
  );
};
