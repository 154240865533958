import { useCallback } from 'react';
import { useInvalidateGetAccessibleVendorsQuery } from 'src/hooks/api/useInvalidateGetAccessibleVendorsQuery';
import {
  OnVendorAccountSaveArgs,
  OnVendorAccountSavedFn,
  useAddVendorAccount,
} from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useAddVendorAccount';

interface UseAddVendorAccountActionsProps {
  onVendorAccountSaved: OnVendorAccountSavedFn;
}

export const useAddVendorAccountActions = (
  props?: UseAddVendorAccountActionsProps
) => {
  const onVendorAccountSavedProp = props?.onVendorAccountSaved;
  const invalidateGetAccessibleVendorsQuery =
    useInvalidateGetAccessibleVendorsQuery();

  const onVendorAccountSaved = useCallback(
    async (value: OnVendorAccountSaveArgs) => {
      await Promise.all([
        invalidateGetAccessibleVendorsQuery(),
        onVendorAccountSavedProp?.(value),
      ]);
    },
    [invalidateGetAccessibleVendorsQuery, onVendorAccountSavedProp]
  );

  return useAddVendorAccount({
    onVendorAccountSaved,
  });
};
