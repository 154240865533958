import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { ContentId } from 'src/utils/constants/contentId';
import { TAG_VALUE_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { Feature, Tag, TagsValueType, UserSetting } from 'src/WebApiController';

import { CancelButton } from '../Buttons';
import * as styles from './TagsFormBody.css';

// Special characters that are not allowed in reporting column names to avoid interfering with the formula
const SPECIAL_CHAR_SET = ["'", '[', ']', '{', '}'];
const DEFAULT_MAX_NUM_REPORTING_COLUMN = 5;

// Reserved tag names that are not allowed to be created
const RESERVED_NAMES = ['SkyboxNotes'];

export const CreateTagMetadataForm = ({
  mergedTagsMetadata,
  disabled,
  onSubmit,
  onClose,
}: {
  mergedTagsMetadata: Tag[];
  disabled?: boolean;
  onSubmit: (newTagMetadata: Tag) => void;
  onClose: () => void;
}) => {
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Tag>({
    defaultValues: { key: '', value: '', valueType: TagsValueType.String },
  });

  const hasTagTypesUserGroupFeature = useUserHasFeature(
    Feature.TagTypesUserGroup
  );

  const duplicateMsg = useContent(ContentId.ExistingTagName);
  const requiredMsg = useContent(ContentId.Required);
  const specialCharsMsg = useContent(ContentId.SpecialCharactersNotAllowed);
  const notAllowedMsg = useContent(ContentId.TagNameNotAllowed);

  const { value: maxNumReportingColumn = DEFAULT_MAX_NUM_REPORTING_COLUMN } =
    useServerUserSetting<number>({
      id: UserSetting.ReportingColumnLimit,
    });

  const key = watch('key');
  const valueType = watch('valueType');
  const singleValueOnly = watch('singleValueOnly') ?? false;
  const allowMultipleValues = !singleValueOnly;
  const includeInReport = watch('includeInReport');

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const validateTagInput = useCallback(
    (data: Tag) => {
      clearErrors('key');
      clearErrors('valueType');

      if (!data.key) {
        setError('key', { message: requiredMsg }, { shouldFocus: true });
        return false;
      }

      if (mergedTagsMetadata.find((t) => t.key === data.key) != null) {
        setError('key', { message: duplicateMsg }, { shouldFocus: true });
        return false;
      }

      if (!data.valueType) {
        setError('valueType', { message: requiredMsg }, { shouldFocus: true });
        return false;
      }

      if (SPECIAL_CHAR_SET.some((c) => data.key.includes(c))) {
        setError('key', { message: specialCharsMsg }, { shouldFocus: true });
        return false;
      }

      if (RESERVED_NAMES.includes(data.key)) {
        setError('key', { message: notAllowedMsg }, { shouldFocus: true });
        return false;
      }

      return true;
    },
    [
      clearErrors,
      duplicateMsg,
      mergedTagsMetadata,
      notAllowedMsg,
      requiredMsg,
      setError,
      specialCharsMsg,
    ]
  );

  const onSubmitHandler = useCallback(
    async (newTag: Tag) => {
      // Validation
      if (!validateTagInput(newTag)) {
        return;
      }

      try {
        setIsSubmitting(true);
        await onSubmit(newTag);
        setIsSubmitting(false);
        onClose();
      } catch (e) {
        // error tracking is handled in the api hook, only doing console here
        console.error('Unable to save new tag metadata:', e);
      } finally {
        setIsSubmitting(false);
      }
    },
    [onClose, onSubmit, validateTagInput]
  );

  const tagTypesOptionsContent = useMemo(() => {
    return Object.entries(TAG_VALUE_TYPE_TO_CID).reduce(
      (cur, [key, value]) => {
        if (!hasTagTypesUserGroupFeature && key === TagsValueType.UserGroup) {
          return cur;
        }
        cur[key] = value;
        return cur;
      },
      {} as Record<string, string>
    );
  }, [hasTagTypesUserGroupFeature]);

  const willExceedReportingColumnLimit = useMemo(() => {
    const numReportingTags = mergedTagsMetadata.filter(
      (t) => t.includeInReport
    ).length;
    return numReportingTags >= maxNumReportingColumn;
  }, [maxNumReportingColumn, mergedTagsMetadata]);

  return (
    <div className={styles.formMainContent}>
      <PosFormField
        label={<Content id={ContentId.Name} />}
        errors={errors?.key?.message}
      >
        <PosTextField
          value={key}
          onChange={(e) => {
            clearErrors('key');
            setValue('key', e.target.value);
          }}
          disabled={disabled || isSubmitting}
          maxLength={750}
        />
      </PosFormField>

      <PosFormField
        label={<Content id={ContentId.ValueType} />}
        errors={errors?.valueType?.message}
      >
        <PosEnumSelect<TagsValueType>
          placeholderText={ContentId.SelectValueType}
          valueOptionsContent={tagTypesOptionsContent}
          value={valueType}
          onChange={(newValueType) => {
            clearErrors('valueType');
            setValue('valueType', newValueType);
          }}
          disabled={disabled || isSubmitting}
          style={{ width: '100%' }}
        />
      </PosFormField>
      <Checkbox
        labelPosition="right"
        checked={allowMultipleValues}
        onChange={() => {
          // isChecked is true means allow multiple values
          const isChecked = !allowMultipleValues;

          setValue('singleValueOnly', !isChecked);
          if (isChecked) {
            setValue('includeInReport', false);
          }
        }}
        disabled={disabled || isSubmitting}
        label={<Content id={ContentId.AllowMutipleValues} />}
      />

      <Checkbox
        labelPosition="right"
        disabled={
          disabled ||
          willExceedReportingColumnLimit ||
          !singleValueOnly ||
          isSubmitting
        }
        checked={includeInReport ?? false}
        onChange={() => {
          const isChecked = !includeInReport;
          setValue('includeInReport', isChecked);
        }}
        label={<Content id={ContentId.IncludeInColumns} />}
      />

      <div className={styles.formFooter}>
        <Button
          onClick={() => {
            handleSubmit(onSubmitHandler)();
          }}
          disabled={disabled || isSubmitting}
        >
          <Content id={ContentId.Create} />
        </Button>

        <CancelButton
          onClick={onClose}
          disabled={disabled || isSubmitting}
          textContentId={ContentId.Cancel}
        />
      </div>
    </div>
  );
};
