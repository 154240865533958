import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PurchaseClient, PurchaseVendorAccount } from 'src/WebApiController';

export const GET_ACCESSIBLE_VENDOR_ACCOUNTS_KEY = 'getAccessibleVendorAccounts';

const PAGE_SIZE = 1000;

interface RequestPage {
  skip: number;
  searchText: string;
  hasNextPage: boolean;
}

const defaultRequestPage: RequestPage = {
  skip: 0,
  searchText: '',
  hasNextPage: true,
};

export function useGetFilteredAccessibleVendorAccountsQuery(
  purchaseOrderVendorIds: number[] | null | undefined,
  searchText: string
) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  const [allVendorAccounts, setAllVendorAccounts] = useState<
    PurchaseVendorAccount[]
  >([]);

  const [requestPage, setRequestPage] =
    useState<RequestPage>(defaultRequestPage);

  useEffect(() => {
    const cleanSearchText = searchText || '';
    if (cleanSearchText !== requestPage.searchText) {
      setAllVendorAccounts([]);
      setRequestPage({
        ...defaultRequestPage,
        searchText: cleanSearchText,
      });
    }
  }, [requestPage.searchText, searchText]);

  const getVendorAccountsQuery = useQuery({
    queryKey: [
      GET_ACCESSIBLE_VENDOR_ACCOUNTS_KEY,
      activeAccountWebClientConfig.activeAccountId,
      purchaseOrderVendorIds,
      requestPage,
    ],
    queryFn: async () => {
      if (!purchaseOrderVendorIds?.length) {
        // If all of these are nulls, return nothing
        return allVendorAccounts;
      }

      const { skip, hasNextPage, searchText } = requestPage;

      // No next page
      if (!hasNextPage) {
        return allVendorAccounts;
      }

      const vendorAccounts = await new PurchaseClient(
        activeAccountWebClientConfig
      ).getAccessibleVendorAccounts(
        { item1: purchaseOrderVendorIds, item2: [] },
        searchText || null,
        PAGE_SIZE,
        skip
      );

      if (vendorAccounts.length === 0) {
        setRequestPage({
          ...requestPage,
          hasNextPage: false,
        });
        return allVendorAccounts;
      }

      const mergedVendors = [...allVendorAccounts, ...vendorAccounts];
      setAllVendorAccounts(mergedVendors);
      return mergedVendors;
    },

    refetchOnWindowFocus: false,
    enabled: !!purchaseOrderVendorIds?.length,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PurchaseClient.getAccessibleVendorAccounts', error, {
          vendorIdSelected: purchaseOrderVendorIds,
        });
      },
    },
  });

  const loadNextVendorAccountsPage = useCallback(() => {
    const nextRequestPage: RequestPage = {
      skip: allVendorAccounts.length,
      hasNextPage: true,
      searchText: requestPage.searchText,
    };

    setRequestPage(nextRequestPage);
  }, [allVendorAccounts.length, requestPage.searchText]);

  const updateVendorAccount = useCallback(
    (vendorAccount: PurchaseVendorAccount) => {
      const vendorAccountIndex = allVendorAccounts.findIndex(
        (va) => va.id === vendorAccount.id
      );

      if (vendorAccountIndex === -1) {
        return;
      }

      const allVendorVendorAccountsUpdated = [...allVendorAccounts];
      allVendorVendorAccountsUpdated[vendorAccountIndex] = vendorAccount;
      setAllVendorAccounts(allVendorVendorAccountsUpdated);
    },
    [allVendorAccounts]
  );

  const refreshSearch = useCallback(() => {
    setAllVendorAccounts([]);
    setRequestPage({
      ...requestPage,
      hasNextPage: true,
      skip: 0, // Start from the begging
    });
    getVendorAccountsQuery.refetch();
  }, [getVendorAccountsQuery, requestPage]);

  return {
    ...getVendorAccountsQuery,
    data: allVendorAccounts,
    loadNextVendorAccountsPage,
    updateVendorAccount,
    refreshSearch,
  };
}
