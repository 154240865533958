import clsx from 'clsx';
import type { ReactNode } from 'react';
import { ComponentPropsWithoutRef } from 'react';

import * as styles from './Detail.css';

export const Detail = ({
  label,
  detail,
  isStrong,
  isRightAligned,
  ...rest
}: {
  label: string | ReactNode;
  detail: string | ReactNode;
  isStrong?: boolean;
  isRightAligned?: boolean;
} & ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className={clsx(styles.sectionWrapper, {
        [styles.rightAligned]: isRightAligned,
      })}
      {...rest}
    >
      <span className={styles.attrLabel}>{label}</span>
      <span
        className={clsx(styles.root, {
          [styles.strong]: isStrong,
        })}
      >
        {detail}
      </span>
    </div>
  );
};
