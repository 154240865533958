import { useContent } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { LaunchBulkUploadPurchaseDialog } from 'src/dialogs/BulkEdits/BulkUploadPurchaseDialog';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';

export const PurchaseGlobalActionDropdown = ({
  showBulkUpload,
}: {
  showBulkUpload?: boolean;
}) => {
  const actionsMsg = useContent(ContentId.Actions);

  const hasPurchaseUpdatePermission = useUserHasAnyOfPermissions(
    Permission.Purchases_Edit,
    Permission.Purchases_EditOwned
  );

  if (!showBulkUpload && !hasPurchaseUpdatePermission) {
    return null;
  }

  return (
    <>
      <PosDropdown
        key="sales-global-action-dropdown"
        trigger={
          <div
            title={actionsMsg}
            style={{ padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}` }}
          >
            <MoreIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              align="middle"
            />
          </div>
        }
        align="end"
        triggerProps={{ disabled: false }}
      >
        {showBulkUpload && (
          <LaunchBulkUploadPurchaseDialog className="add-purchase" />
        )}
      </PosDropdown>
    </>
  );
};
