import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { MARKET_PLACE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { Marketplace, MarketplaceSaleInput } from 'src/WebApiController';

type MarketplaceSaleInputBuyer = Pick<MarketplaceSaleInput, 'marketplace'>;

export const MarketplaceSection = ({ disabled }: { disabled?: boolean }) => {
  const { loginContext } = useAppContext();
  const requiredMsg = useContent(ContentId.Required);

  const {
    formState: { errors },
    control,
  } = useFormContext<MarketplaceSaleInputBuyer>();

  const marketplaceOptionsContent = useMemo(
    () =>
      (
        loginContext?.user?.activeAccount?.marketplaceSettings
          ?.filter((m) => m.isSyncHandledByProcessor)
          .map((m) => m.mkp) ?? []
      ).reduce(
        (cur, m) => {
          cur[m] = MARKET_PLACE_TO_CID[m];
          return cur;
        },
        {} as Record<Marketplace, string>
      ),
    [loginContext?.user?.activeAccount?.marketplaceSettings]
  );

  return (
    <DetailSection name={<Content id={ContentId.Merchant} />}>
      <SectionContent>
        <DetailGroup>
          <Controller
            control={control}
            name="marketplace"
            rules={{ required: requiredMsg }}
            render={({ field: { ...field } }) => (
              <PosFormField
                label={<Content id={ContentId.Merchant} />}
                errors={errors.marketplace?.message}
              >
                <PosEnumSelect
                  disabled={disabled}
                  valueOptionsContent={marketplaceOptionsContent}
                  {...field}
                />
              </PosFormField>
            )}
          />
        </DetailGroup>
      </SectionContent>
    </DetailSection>
  );
};
