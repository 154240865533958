import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  EventConfigClient,
  EventListingConstraints,
} from 'src/WebApiController';

export const useGetEventListingConstraints = (eventIds: number[]) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const query = useQuery({
    queryKey: ['EventConfigClient.getListingConstraintsForEvents', eventIds],
    queryFn: async () => {
      if (!eventIds.length) {
        return null;
      }
      const results = await new EventConfigClient(
        activeAccountWebClientConfig
      ).getListingConstraintsForEvents(eventIds);

      if (results) {
        return results.reduce(
          (r, c) => {
            r[c.eventId] = c;
            return r;
          },
          {} as Record<number, EventListingConstraints>
        );
      }

      return null;
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('EventConfigClient.getListingConstraintsForEvents', error, {
          trackErrorData: {
            eventIds,
          },
        });
      },
    },
  });

  return {
    eventListingConstraintsQuery: query,
  };
};
