import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${() =>
    `${vars.spacing.lg} ${vars.spacing['4xl']} ${vars.spacing.m} 0`};
`;

export const EntityInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: flex-end;
  ${(props) => props.theme.typography.getStyles('body2')}
  ${({ theme: { media, spacing, typography } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      gap: ${vars.spacing.sm};
      ${typography.getStyles('body1')}
    }`
    )}`}
`;

export const EntityIdNumberDiv = styled.div`
  ${(props) => props.theme.typography.getStyles('subtitle1')}
  color: ${(props) => props.theme.colors.textSecondary};
`;
export const EntityTextDiv = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.typography.getStyles('body2')}
`;
