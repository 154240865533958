import clsx from 'clsx';
import { useMemo } from 'react';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { shared, vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { CrossIcon, ProfileOutlineIcon } from 'src/svgs/Viagogo';

import * as styles from './SellerUserListItem.css';

type SellerUserListItemProps = {
  sellerUserId?: string;
  onDelete?: (value: string) => void;
};

export const SellerUserListItem = ({
  sellerUserId,
  onDelete,
}: SellerUserListItemProps) => {
  const { allActiveUserInfos } = useSellerAccountContext();

  const userInfo = useMemo(() => {
    if (!sellerUserId || !allActiveUserInfos) return undefined;

    return allActiveUserInfos[sellerUserId];
  }, [allActiveUserInfos, sellerUserId]);

  return (
    <div className={styles.sellerUserListItem}>
      <Stack
        direction="row"
        alignItems="center"
        gap="l"
        style={{
          width: '65%',
        }}
        title={userInfo?.displayName}
      >
        <ProfileOutlineIcon size={vars.iconSize.xl} />
        <Stack direction="column" style={{ maxWidth: '85%' }}>
          <span
            className={clsx(styles.textNoWrap, shared.typography.subtitle1)}
          >
            {userInfo?.displayName}
          </span>
          <span className={clsx(styles.textNoWrap, shared.typography.body2)}>
            {userInfo?.email}
          </span>
        </Stack>
      </Stack>
      {onDelete && (
        <CrossIcon
          withHoverEffect
          fill={vars.color.textPrimary}
          size={vars.iconSize.m}
          onClick={() => {
            onDelete(sellerUserId ?? '');
          }}
        />
      )}
    </div>
  );
};
