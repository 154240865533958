import { useLayoutEffect, useState } from 'react';

interface UseOnElementResizeProps {
  element?: HTMLDivElement | null | undefined;
  callback: (entries: ResizeObserverEntry[]) => void;
}

export const useOnElementResize = ({
  element,
  callback,
}: UseOnElementResizeProps) => {
  const [resizeObserverEntries, setResizeObserverEntries] = useState<
    ResizeObserverEntry[]
  >([]);

  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      callback?.(entries);
      setResizeObserverEntries(entries);
    });

    if (element) {
      resizeObserver.observe(element || document.body);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [callback, element]);

  return resizeObserverEntries;
};
