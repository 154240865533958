import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { UserSetting } from 'src/WebApiController';

import { InternationalComplianceSetting } from './InternationalComplianceDialog';

export function useInternationalComplianceDialog(
  onSubmit: (result: InternationalComplianceSetting) => void
) {
  const { value, setUserSetting } =
    useServerUserSetting<InternationalComplianceSetting>({
      id: UserSetting.InternationalCompliance,
    });
  return {
    value,
    onSubmit: (result: InternationalComplianceSetting) => {
      setUserSetting(result);
      onSubmit(result);
    },
  };
}
