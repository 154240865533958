import {
  FormatOption,
  FormatOptionEntries,
  LISTING_TABLE_COLUMN_DEFAULT_PRECISION,
} from 'src/modals/EditTableColumns';
import {
  LISTING_FLATTENED_TABLE_COLUMN_DEFAULT_PRECISION,
  PURCHASES_EVENT_TABLE_COLUMN_DEFAULT_PRECISION,
  SALE_FLATTENED_TABLE_COLUMN_DEFAULT_PRECISION,
  SALE_TABLE_COLUMN_DEFAULT_PRECISION,
} from 'src/modals/EditTableColumns/EditTableColumns.util';
import { EVENTS_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from 'src/utils/columns/events/eventsColumnUtils.constants';
import { EventsTableColumnId } from 'src/utils/columns/events/eventsColumnUtils.types';
import {
  LISTING_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES,
  LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES,
} from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import {
  ListingReportTableColumnId,
  ListingTableColumnId,
} from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { PURCHASES_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from 'src/utils/columns/purchases/purchasesColumnUtils.constants';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import { SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from 'src/utils/columns/sales/salesColumnUtils.constants';
import {
  SalesReportTableColumnId,
  SalesTableColumnId,
} from 'src/utils/columns/sales/salesColumnUtils.types';
import { SALE_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from 'src/utils/columns/sales/salesReportColumnUtils.constants';
import {
  SECTION_TYPE_TO_COLUMN_EDITABILITY_SETTING,
  SECTION_TYPE_TO_PRECISION_SETTING,
  SectionType,
} from 'src/utils/types/sectionType';

import { useServerUserSetting } from './useUserSetting';

const getPersonalizationConfigOverrides = (
  columnId: string,
  sectionType: SectionType
) => {
  return {
    [SectionType.Events]:
      EVENTS_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as EventsTableColumnId
      ],
    [SectionType.Listings]:
      LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as ListingTableColumnId
      ],
    [SectionType.ListingsFlattened]:
      LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as ListingTableColumnId
      ],
    [SectionType.InventorySideTable]:
      LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as ListingTableColumnId
      ],
    [SectionType.Sales]:
      SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as SalesTableColumnId
      ],
    [SectionType.SalesFlattened]:
      SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as SalesTableColumnId
      ],
    [SectionType.SalesReport]:
      SALE_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as SalesReportTableColumnId
      ],
    [SectionType.SalesSideTable]:
      SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as SalesTableColumnId
      ],
    [SectionType.ListingsReport]:
      LISTING_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as ListingReportTableColumnId
      ],
    [SectionType.Purchases]:
      PURCHASES_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as PurchasesTableColumnId
      ],
    [SectionType.PurchaseEvent]:
      PURCHASES_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as PurchasesTableColumnId
      ],
    [SectionType.PurchaseSideTable]:
      PURCHASES_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
        columnId as PurchasesTableColumnId
      ],
  }[sectionType];
};

export const useColumnUserSetting = (
  columnId: string,
  sectionType: SectionType
) => {
  const { value: storedColumnNumberPrecisions = {} } =
    useServerUserSetting<FormatOptionEntries>({
      id: SECTION_TYPE_TO_PRECISION_SETTING[sectionType],
    });

  const { isInteger, isNumeric, isCurrency, isPercent } =
    getPersonalizationConfigOverrides(columnId, sectionType) ?? {};

  const sectionSpecificDefaultPrecision: FormatOption | undefined = {
    [SectionType.Listings]: LISTING_TABLE_COLUMN_DEFAULT_PRECISION[columnId],
    [SectionType.ListingsReport]:
      LISTING_TABLE_COLUMN_DEFAULT_PRECISION[columnId],
    [SectionType.ListingsFlattened]:
      LISTING_FLATTENED_TABLE_COLUMN_DEFAULT_PRECISION[columnId] ??
      LISTING_TABLE_COLUMN_DEFAULT_PRECISION[columnId],
    [SectionType.InventorySideTable]:
      LISTING_TABLE_COLUMN_DEFAULT_PRECISION[columnId],
    [SectionType.Sales]: SALE_TABLE_COLUMN_DEFAULT_PRECISION[columnId],
    [SectionType.SalesReport]: SALE_TABLE_COLUMN_DEFAULT_PRECISION[columnId],
    [SectionType.SalesFlattened]:
      SALE_FLATTENED_TABLE_COLUMN_DEFAULT_PRECISION[columnId],
    [SectionType.SalesSideTable]: SALE_TABLE_COLUMN_DEFAULT_PRECISION[columnId],
    [SectionType.Events]: undefined,
    [SectionType.Purchases]: undefined,
    [SectionType.PurchaseEvent]:
      PURCHASES_EVENT_TABLE_COLUMN_DEFAULT_PRECISION[columnId],
    [SectionType.PurchaseSideTable]: undefined,
  }[sectionType];

  const userDefinedPrecision =
    storedColumnNumberPrecisions[columnId] ??
    sectionSpecificDefaultPrecision ??
    (isPercent && !isCurrency
      ? FormatOption.Percent_RoundToTwo
      : isInteger
      ? FormatOption.Whole
      : isNumeric
      ? FormatOption.Precise
      : undefined);

  const { value: editableColumns = [] } = useServerUserSetting<string[]>({
    id: SECTION_TYPE_TO_COLUMN_EDITABILITY_SETTING[sectionType],
  });
  const editable = editableColumns.includes(columnId);

  return {
    userDefinedPrecision,
    editable,
  };
};
