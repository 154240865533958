import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { formatInTimeZone } from 'date-fns-tz';
import { useMemo } from 'react';
import { AccessorReturn } from 'src/components/Reports/ReportsExport/ReportsExport.types';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_XL,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import {
  FormatOption,
  type FormatOptionEntries,
} from 'src/modals/EditTableColumns';
import { CustomColumnName } from 'src/modals/InventoryTableColumns/CustomColumnName';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';
import { IconsFill } from 'src/svgs/Viagogo';
import { CustomNumericCell } from 'src/tables/ListingTable/configs/CustomNumericCell';
import { CustomNumericFooterCell } from 'src/tables/ListingTable/configs/CustomNumericFooterCell';
import { LinkCell } from 'src/tables/ReportTableCommon/LinkCell';
import { SHIMMERING_DIV_HEIGHT_SALE_REPORT } from 'src/tables/ReportTableCommon/ReportTableCommon.constants';
import { TableHeader } from 'src/tables/ReportTableCommon/TableHeader';
import { TextCell } from 'src/tables/ReportTableCommon/TextCell';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { DateCell, InHandCell } from 'src/tables/Table/TableCells';
import { evaluateFormulaSaleMetrics } from 'src/utils/columns/sales/salesCustomColumnUtils';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import {
  MARKETPLACE_PAYMENT_TERM_TO_CID,
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
  SALE_PAYMENT_STATE_TO_CID,
  TICKET_TYPE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  applyNumberFormatting,
  formatPercent,
} from 'src/utils/numberFormatter';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { SaleReportMetricsWithGroupBy } from 'src/utils/reportsUtils';
import {
  getSaleDetailsRelativeUrl,
  getSaleStatusDisplayContentId,
  getSaleStatusIcon,
} from 'src/utils/saleUtils';
import { sortMoney } from 'src/utils/tableUtils';
import { getTicketTypeIcon } from 'src/utils/ticketTypeUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  DateTimeRangeWithRelative,
  PointOfSaleSaleStatus,
  TagsValueType,
  TicketType,
  UiMoney,
  UserSetting,
} from 'src/WebApiController';

import { SalesReportTableColumnId } from '../../../utils/columns/sales/salesColumnUtils.types';
import { SALE_REPORT_TABLE_COLUMNS_ACCESSOR } from './SaleReportTableColumnsAccessor';
import {
  timeBucketToColumnId,
  timeBucketToDayString,
} from './SaleReportTableColumnsConfig.utils';

const SECTION_TYPE = SectionType.SalesReport;

export const tagColumnDef = (
  tagName: string
): ColumnDef<SaleReportMetricsWithGroupBy | null> => ({
  id: tagName,
  header: () => (
    <TableHeaderCellDiv align="right">
      <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
        {tagName}
      </span>
    </TableHeaderCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_BASE,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    const tag = data?.tags.find((t) => t.key === tagName);
    return tag ? parseFloat(tag.value) : null;
  },
  cell: function Cell({ getValue, row: { original } }) {
    const { userDefinedPrecision } = useColumnUserSetting(
      tagName,
      SECTION_TYPE
    );

    const tag = original?.tags.find((t) => t.key === tagName);

    return (
      <CustomNumericCell
        columnId={tagName}
        userDefinedPrecision={userDefinedPrecision}
        value={getValue<number | null>()}
        isLoading={!original}
        shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SALE_REPORT}
        sectionType={SECTION_TYPE}
        matchingTag={tag}
        currencyCode={original?.currency}
      />
    );
  },
});

export const eventTagColumnDef = (
  tagName: string
): ColumnDef<SaleReportMetricsWithGroupBy | null> => ({
  id: tagName,
  header: () => (
    <TableHeaderCellDiv align="right">
      <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
        {tagName}
      </span>
    </TableHeaderCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_BASE,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    const tag = data?.eventTags.find((t) => t.key === tagName);
    return tag?.value;
  },
  cell: function Cell({ getValue, row: { original } }) {
    const { value: storedColumnNumberPrecision = {} } =
      useServerUserSetting<FormatOptionEntries>({
        id: UserSetting.SaleColumnNumberPrecision,
      });
    const tag = original?.tags.find((t) => t.key === tagName);

    const userDefinedPrecision = storedColumnNumberPrecision[tagName];

    if (!original) {
      return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />;
    }

    if (
      tag?.valueType === TagsValueType.Decimal ||
      tag?.valueType === TagsValueType.Int
    ) {
      return (
        <CustomNumericCell
          columnId={tagName}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={!original}
          shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SALE_REPORT}
          sectionType={SECTION_TYPE}
          matchingTag={tag}
          currencyCode={original?.currency}
        />
      );
    }

    return (
      <TableCellDiv align="right">
        <div>{getValue<string | undefined>()}</div>
      </TableCellDiv>
    );
  },
});

export const arTimeBucketColumnDef = (
  timeBucket: DateTimeRangeWithRelative
): ColumnDef<SaleReportMetricsWithGroupBy | null> => {
  return {
    id: timeBucketToColumnId(timeBucket),
    header: function Cell() {
      const daysText = useContent(ContentId.Days);
      const totalText = useContent(ContentId.Total);
      return (
        <TableCellDiv align="right">
          <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
            {timeBucketToDayString(timeBucket, daysText, totalText)}
          </span>
        </TableCellDiv>
      );
    },
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => {
      const timeBucketWithData = data?.timeBucketsWithData?.find(
        (t) =>
          t.timeBkt?.relativeEndSecs === timeBucket.relativeEndSecs &&
          t.timeBkt?.relativeStartSecs === timeBucket.relativeStartSecs
      );
      return timeBucketWithData?.ttlOutsBal;
    },
    cell: function Cell({
      getValue,
      row: { original },
      column: { id: columnId },
    }) {
      const value = getValue<UiMoney>();

      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SECTION_TYPE
      );

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
        );
      }

      if (!value) return <Content id={ContentId.NA} />;

      const displayValue =
        applyNumberFormatting({
          precision: userDefinedPrecision,
          inputNumber: value.amt,
          currencyCode: value.currency,
          currencyDecimalDigits: value.dec,
        }) ?? undefined;

      return (
        <TableCellDiv align="right">
          <div>{displayValue ?? <Content id={ContentId.NA} />}</div>
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<SaleReportMetricsWithGroupBy>,
    footer: function Cell({ table, column: { accessorFn } }) {
      const original = table.options.meta?.summaryData;

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
        );
      }

      const value = accessorFn?.(original, -1) as UiMoney | undefined;

      if (!value) return <Content id={ContentId.NA} />;

      return (
        <TableCellDiv align="right">
          <div>
            {applyNumberFormatting({
              inputNumber: value.amt,
              currencyCode: value.currency,
              currencyDecimalDigits: value.dec,
            })}
          </div>
        </TableCellDiv>
      );
    },
  };
};

export const customColumnDef = (
  columnId: string,
  formula?: string | null
): ColumnDef<SaleReportMetricsWithGroupBy | null> => ({
  id: columnId,
  header: () => (
    <TableHeaderCellDiv align="right">
      <CustomColumnName id={columnId} sectionType={SECTION_TYPE} />
    </TableHeaderCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_BASE,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    return formula && data ? evaluateFormulaSaleMetrics(formula, data) : null;
  },
  cell: function Cell({ getValue, row: { original } }) {
    const { value: customColumns = [] } = useServerUserSetting<
      CustomSalesColumn[]
    >({
      id: UserSetting.SaleCustomColumns,
    });

    const { userDefinedPrecision } = useColumnUserSetting(
      columnId,
      SECTION_TYPE
    );

    return (
      <CustomNumericCell
        columnId={columnId}
        userDefinedPrecision={userDefinedPrecision}
        value={getValue<number | null>()}
        isLoading={!original}
        shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SALE_REPORT}
        sectionType={SECTION_TYPE}
        matchingCustomColumn={customColumns.find((c) => c.id === columnId)}
        currencyCode={original?.currency}
      />
    );
  },

  footer: function Cell({ table }) {
    const { value: customColumns = [] } = useServerUserSetting<
      CustomSalesColumn[]
    >({
      id: UserSetting.SaleCustomColumns,
    });

    return (
      <CustomNumericFooterCell
        table={table}
        columnId={columnId}
        shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SALE_REPORT}
        matchingCustomColumn={customColumns.find((c) => c.id === columnId)}
        sectionType={SECTION_TYPE}
      />
    );
  },
});

const columnHelper = createColumnHelper<SaleReportMetricsWithGroupBy | null>();

export const SALE_REPORT_TABLE_COLUMNS_CONFIG = {
  [SalesReportTableColumnId.Performer]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Performer],
    {
      id: SalesReportTableColumnId.Performer,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.Venue]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Venue],
    {
      id: SalesReportTableColumnId.Venue,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.Event]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Event],
    {
      id: SalesReportTableColumnId.Event,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.EventGenre]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.EventGenre],
    {
      id: SalesReportTableColumnId.EventGenre,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.EventCountry]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.EventCountry],
    {
      id: SalesReportTableColumnId.EventCountry,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.EventCity]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.EventCity],
    {
      id: SalesReportTableColumnId.EventCity,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.SeatingSection]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.SeatingSection],
    {
      id: SalesReportTableColumnId.SeatingSection,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.SeatingRow]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.SeatingRow],
    {
      id: SalesReportTableColumnId.SeatingRow,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.SaleMarketplace]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.SaleMarketplace
    ],
    {
      id: SalesReportTableColumnId.SaleMarketplace,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.SaleMonth]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.SaleMonth],
    {
      id: SalesReportTableColumnId.SaleMonth,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { timeZone } = useSiteTimezoneContext();
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const dateValue = getValue<string | null | undefined>();

        return (
          <TableCellDiv align="left">
            <div>
              {dateValue
                ? formatInTimeZone(new Date(dateValue), timeZone, 'MMMM yyyy')
                : ''}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.SaleWeek]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.SaleWeek],
    {
      id: SalesReportTableColumnId.SaleWeek,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { timeZone } = useSiteTimezoneContext();
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const dateValue = getValue<string | null | undefined>();

        const dateEndOfWeek = dateValue ? new Date(dateValue) : null;
        if (dateEndOfWeek) {
          dateEndOfWeek.setDate(dateEndOfWeek.getDate() + 6);
        }

        return (
          <TableCellDiv align="left">
            <div>
              {dateValue && dateEndOfWeek
                ? formatInTimeZone(
                    new Date(dateValue),
                    timeZone,
                    'yyyy/MM/dd'
                  ) +
                  ' - ' +
                  formatInTimeZone(dateEndOfWeek, timeZone, 'yyyy/MM/dd')
                : ''}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.SaleDay]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.SaleDay],
    {
      id: SalesReportTableColumnId.SaleDay,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { timeZone } = useSiteTimezoneContext();
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const dateValue = getValue<string | null | undefined>();

        return (
          <TableCellDiv align="left">
            <div>
              {dateValue
                ? formatInTimeZone(new Date(dateValue), timeZone, 'yyyy/MM/dd')
                : ''}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.QuantitySold]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.QuantitySold],
    {
      id: SalesReportTableColumnId.QuantitySold,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <TableCellDiv align="right">
            <div>
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: getValue<number>(),
              })}
            </div>
          </TableCellDiv>
        );
      },
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }
        const number = accessorFn?.(original, -1);

        return (
          <TableCellDiv align="right">
            <div>
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: number,
              })}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.Proceeds]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Proceeds],
    {
      id: SalesReportTableColumnId.Proceeds,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<UiMoney>();

        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        const displayValue =
          applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          }) ?? undefined;

        return (
          <TableCellDiv align="right">
            <div>{displayValue ?? <Content id={ContentId.NA} />}</div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<SaleReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.SoldTicketCost]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.SoldTicketCost],
    {
      id: SalesReportTableColumnId.SoldTicketCost,
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<UiMoney>();

        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        const displayValue =
          applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          }) ?? undefined;

        return (
          <TableCellDiv align="right">
            <div>{displayValue ?? <Content id={ContentId.NA} />}</div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<SaleReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.CommissionPercent]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.CommissionPercent
    ],
    {
      id: SalesReportTableColumnId.CommissionPercent,
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue();

        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        const displayValue =
          applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
            isPercent: true,
          }) ?? undefined;

        return (
          <TableCellDiv align="right">
            <div>{displayValue ?? <Content id={ContentId.NA} />}</div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.CommissionShare]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.CommissionShare
    ],
    {
      id: SalesReportTableColumnId.CommissionShare,
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<UiMoney>();

        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        const displayValue =
          applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          }) ?? undefined;

        return (
          <TableCellDiv align="right">
            <div>{displayValue ?? <Content id={ContentId.NA} />}</div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<SaleReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.OutstandingBalance]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.OutstandingBalance
    ],
    {
      id: SalesReportTableColumnId.OutstandingBalance,
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<UiMoney>();

        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        const displayValue =
          applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          }) ?? undefined;

        return (
          <TableCellDiv align="right">
            <div>{displayValue ?? <Content id={ContentId.NA} />}</div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<SaleReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.Charges]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Charges],
    {
      id: SalesReportTableColumnId.Charges,
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<UiMoney>();

        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        const displayValue =
          applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          }) ?? undefined;

        return (
          <TableCellDiv align="right">
            <div>{displayValue ?? <Content id={ContentId.NA} />}</div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<SaleReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.Credits]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Credits],
    {
      id: SalesReportTableColumnId.Credits,
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<UiMoney>();

        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        const displayValue =
          applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          }) ?? undefined;

        return (
          <TableCellDiv align="right">
            <div>{displayValue ?? <Content id={ContentId.NA} />}</div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<SaleReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.PandL]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.PandL],
    {
      id: SalesReportTableColumnId.PandL,
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<UiMoney>();

        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        const displayValue =
          applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          }) ?? undefined;

        return (
          <TableCellDiv align="right">
            <div>{displayValue ?? <Content id={ContentId.NA} />}</div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<SaleReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.Margin]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Margin],
    {
      id: SalesReportTableColumnId.Margin,
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue();

        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value,
                    isPercent: true,
                  })
                : value ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value,
                    isPercent: true,
                  })
                : value ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.EventId]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.EventId],
    {
      id: SalesReportTableColumnId.EventId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => {
        const eventId = params.row.original?.eventId;
        if (!eventId) {
          return (
            <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
          );
        }
        const eventUrl = createInventoryEventUrl(eventId);
        return (
          <LinkCell
            text={String(params.getValue())}
            url={eventUrl}
            align="left"
          />
        );
      },
    }
  ),
  [SalesReportTableColumnId.Category]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Category],
    {
      id: SalesReportTableColumnId.Category,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.EventDate]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.EventDate],
    {
      id: SalesReportTableColumnId.EventDate,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<string>();
        const { userDefinedPrecision = FormatOption.DateTime_MMDD_Time_Day } =
          useColumnUserSetting(columnId, SECTION_TYPE);

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={value}
            enableUtcFallback
            hideRelativeTerms
            align="left"
            useSiteTimeZone={false}
          />
        );
      },
    }
  ),
  [SalesReportTableColumnId.EventStateProvince]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.EventStateProvince
    ],
    {
      id: SalesReportTableColumnId.EventStateProvince,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.SaleId]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.SaleId],
    {
      id: SalesReportTableColumnId.SaleId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const saleId = getValue<string>();

        if (!saleId) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const url = getSaleDetailsRelativeUrl(saleId!);
        return (
          <LinkCell
            text={`#${saleId}`}
            url={url}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [SalesReportTableColumnId.MarketplaceSaleId]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.MarketplaceSaleId
    ],
    {
      id: SalesReportTableColumnId.MarketplaceSaleId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.Status]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Status],
    {
      id: SalesReportTableColumnId.Status,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const value = getValue() as PointOfSaleSaleStatus;

        // TODO: SaleReportMetricsWithGroupBy does not have allocationState. We should add it
        const saleStatusContentId = getSaleStatusDisplayContentId(value);

        const statusContent = useContent(saleStatusContentId ?? '');
        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const SaleStatusIcon = getSaleStatusIcon(value);

        return (
          <TableCellDiv align="left">
            {userDefinedPrecision === FormatOption.EnumDisplay_Icon ? (
              <SaleStatusIcon fill={'transparent'} title={statusContent} />
            ) : (
              <Content id={saleStatusContentId} />
            )}
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.TicketType]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.TicketType],
    {
      id: SalesReportTableColumnId.TicketType,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );
        const value = getValue() as TicketType;
        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }
        const ticketTypeIcon = getTicketTypeIcon(value, IconsFill.textStrong);
        return (
          <TableCellDiv align="left" title={value}>
            {userDefinedPrecision === FormatOption.EnumDisplay_Icon ? (
              <>{ticketTypeIcon}</>
            ) : (
              <Content id={TICKET_TYPE_TO_CID[value]} />
            )}
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.InHandDate]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.InHandDate],
    {
      id: SalesReportTableColumnId.InHandDate,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue<string | null>();
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }
        return (
          <TableCellDiv align="left">
            <InHandCell
              date={value ? new Date(value) : null}
              needsToFulfill={Boolean(
                original?.saleStatus &&
                  ![
                    PointOfSaleSaleStatus.ProcessingFulfillment,
                    PointOfSaleSaleStatus.Fulfilled,
                    PointOfSaleSaleStatus.CancelledHold,
                    PointOfSaleSaleStatus.Rejected,
                    PointOfSaleSaleStatus.PendingRejection,
                  ].includes(original?.saleStatus)
              )}
              handleFulfillmentState
            />
          </TableCellDiv>
        );
      },
      sortingFn: 'datetime',
    }
  ),
  [SalesReportTableColumnId.PaymentState]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.PaymentState],
    {
      id: SalesReportTableColumnId.PaymentState,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue }) {
        const value = getValue().value;

        if (value == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <TableCellDiv align="left">
            {value.marketplacePaymentState ? (
              <Content
                id={
                  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID[
                    value.marketplacePaymentState
                  ]
                }
              />
            ) : value.paymentState ? (
              <Content id={SALE_PAYMENT_STATE_TO_CID[value.paymentState]} />
            ) : null}
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.PaymentReceived]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.PaymentReceived
    ],
    {
      id: SalesReportTableColumnId.PaymentReceived,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const value = getValue();
        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <TableCellDiv align="left">
            <Content id={value ? ContentId.Yes : ContentId.No} />
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.PaymentReferenceId]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.PaymentReferenceId
    ],
    {
      id: SalesReportTableColumnId.PaymentReferenceId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const value = getValue();
        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return <TableCellDiv align="left">{value}</TableCellDiv>;
      },
    }
  ),
  [SalesReportTableColumnId.PricedBy]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.PricedBy],
    {
      id: SalesReportTableColumnId.PricedBy,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { allActiveUserInfos } = useSellerAccountContext();

        const pricerSellerUserId = getValue().value;

        const pricer = useMemo(
          () =>
            pricerSellerUserId
              ? allActiveUserInfos?.[pricerSellerUserId]?.displayName
              : null,
          [allActiveUserInfos, pricerSellerUserId]
        );

        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return <TableCellDiv align="left">{pricer}</TableCellDiv>;
      },
    }
  ),
  [SalesReportTableColumnId.Fulfiller]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Fulfiller],
    {
      id: SalesReportTableColumnId.Fulfiller,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { allActiveUserInfos } = useSellerAccountContext();

        const fulfillerId = getValue().value;

        const fulfiller = useMemo(
          () =>
            fulfillerId ? allActiveUserInfos?.[fulfillerId]?.displayName : null,
          [allActiveUserInfos, fulfillerId]
        );

        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return <TableCellDiv align="left">{fulfiller}</TableCellDiv>;
      },
    }
  ),
  [SalesReportTableColumnId.CommissionUser]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.CommissionUser],
    {
      id: SalesReportTableColumnId.CommissionUser,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { allActiveUserInfos } = useSellerAccountContext();

        const sellerUserId = getValue().value;

        const commissioner = useMemo(
          () =>
            sellerUserId
              ? allActiveUserInfos?.[sellerUserId]?.displayName
              : null,
          [allActiveUserInfos, sellerUserId]
        );

        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return <TableCellDiv align="left">{commissioner}</TableCellDiv>;
      },
    }
  ),
  [SalesReportTableColumnId.PurchaseId]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.PurchaseId],
    {
      id: SalesReportTableColumnId.PurchaseId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const purchaseIds = getValue<number[] | null>();

        if (!purchaseIds) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <LinkCell
            text={purchaseIds.map((purchaseId) => `#${purchaseId}`)}
            url={purchaseIds.map((purchaseId) =>
              getPurchaseOrderRelativeUrl(purchaseId)
            )}
            separator={<span>{', '}</span>}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [SalesReportTableColumnId.PurchasedBy]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.PurchasedBy],
    {
      id: SalesReportTableColumnId.PurchasedBy,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const buyerInfos = getValue().value;

        const useGetUserInfosQuery = useGetUserInfos(
          buyerInfos?.map((u) => u.buyerUserId)
        );
        const deactivatedText = useContent(ContentId.Deactivated);

        const buyerUserInfos = useMemo(() => {
          if (buyerInfos == null) return [];

          return buyerInfos.map((bi) => {
            const buyerUser = useGetUserInfosQuery.data?.[bi.buyerUserId];
            return {
              buyerUserId: bi.buyerUserId,
              buyerUser,
              averageBuyerCommissionPercentage:
                bi.averageBuyerCommissionPercent,
            };
          });
        }, [buyerInfos, useGetUserInfosQuery.data]);

        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <TableCellDiv align="left">
            {buyerUserInfos
              .map(
                (bui) =>
                  `${
                    bui.buyerUser?.name ??
                    bui.buyerUser?.email ??
                    bui.buyerUserId
                  } ${
                    bui.buyerUser?.isDeactivated ? `(${deactivatedText})` : ''
                  } ${
                    bui.averageBuyerCommissionPercentage
                      ? `(${formatPercent(
                          bui.averageBuyerCommissionPercentage,
                          2
                        )})`
                      : ''
                  }`
              )
              .join(', ')}
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.PurchaseDate]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.PurchaseDate],
    {
      id: SalesReportTableColumnId.PurchaseDate,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const value = getValue<string>();
        const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
          useColumnUserSetting(
            SalesReportTableColumnId.PurchaseDate,
            SECTION_TYPE
          );

        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={value}
            align="right"
            enableUtcFallback
            hideRelativeTerms
          />
        );
      },
    }
  ),
  [SalesReportTableColumnId.Vendor]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.Vendor],
    {
      id: SalesReportTableColumnId.Vendor,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.VendorAccountEmail]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.VendorAccountEmail
    ],
    {
      id: SalesReportTableColumnId.VendorAccountEmail,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.SeatingSeats]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.SeatingSeats],
    {
      id: SalesReportTableColumnId.SeatingSeats,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.OrderDate]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.OrderDate],
    {
      id: SalesReportTableColumnId.OrderDate,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        column: { id: columnId },
        row: { original },
      }) {
        const value = getValue<string>();
        const {
          userDefinedPrecision = FormatOption.DateTime_Month_DD_YY_Time,
        } = useColumnUserSetting(columnId, SECTION_TYPE);

        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={value}
            align="left"
            enableUtcFallback
            hideRelativeTerms
          />
        );
      },
      sortingFn: 'datetime',
    }
  ),
  [SalesReportTableColumnId.CancellationDate]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.CancellationDate
    ],
    {
      id: SalesReportTableColumnId.CancellationDate,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        column: { id: columnId },
        row: { original },
      }) {
        const value = getValue<string>();
        const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
          useColumnUserSetting(columnId, SECTION_TYPE);

        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={value}
            align="right"
            enableUtcFallback
            hideRelativeTerms
          />
        );
      },
      sortingFn: 'datetime',
    }
  ),
  [SalesReportTableColumnId.CurrencyCode]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.CurrencyCode],
    {
      id: SalesReportTableColumnId.CurrencyCode,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.InternalNotes]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.InternalNotes],
    {
      id: SalesReportTableColumnId.InternalNotes,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.TicketId]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.TicketId],
    {
      id: SalesReportTableColumnId.TicketId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.TicketGroupId]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.TicketGroupId],
    {
      id: SalesReportTableColumnId.TicketGroupId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [SalesReportTableColumnId.PaymentDate]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[SalesReportTableColumnId.PaymentDate],
    {
      id: SalesReportTableColumnId.PaymentDate,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        column: { id: columnId },
        row: { original },
      }) {
        const value = getValue<string>();
        const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
          useColumnUserSetting(columnId, SECTION_TYPE);

        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={value}
            align="right"
            enableUtcFallback
            hideRelativeTerms
          />
        );
      },
      sortingFn: 'datetime',
    }
  ),
  [SalesReportTableColumnId.FulfillmentDate]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.FulfillmentDate
    ],
    {
      id: SalesReportTableColumnId.FulfillmentDate,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="right" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        column: { id: columnId },
        row: { original },
      }) {
        const value = getValue<string>();
        const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
          useColumnUserSetting(columnId, SECTION_TYPE);

        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={value}
            align="right"
            enableUtcFallback
            hideRelativeTerms
          />
        );
      },
      sortingFn: 'datetime',
    }
  ),
  [SalesReportTableColumnId.PurchaseCreatedBy]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.PurchaseCreatedBy
    ],
    {
      id: SalesReportTableColumnId.PurchaseCreatedBy,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { allActiveUserInfos } = useSellerAccountContext();

        const sellerUserIds = getValue<AccessorReturn<string[] | null>>().value;

        const displayNamesFormatted = useMemo(
          () =>
            sellerUserIds
              ? [...new Set(sellerUserIds)]
                  .map(
                    (sellerUserId) =>
                      allActiveUserInfos?.[sellerUserId]?.displayName
                  )
                  .filter((name) => name != null)
                  .join(', ')
              : null,
          [allActiveUserInfos, sellerUserIds]
        );

        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <TableCellDiv align="left">{displayNamesFormatted}</TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.MarketplacePaymentTerms]: columnHelper.accessor(
    SALE_REPORT_TABLE_COLUMNS_ACCESSOR[
      SalesReportTableColumnId.MarketplacePaymentTerms
    ],
    {
      id: SalesReportTableColumnId.MarketplacePaymentTerms,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue();
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <TableCellDiv align={'left'}>
            <div>
              {value ? (
                <Content id={MARKETPLACE_PAYMENT_TERM_TO_CID[value]} />
              ) : null}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
} as const;
