import { Content, FormatContent } from 'src/contexts/ContentContext';
import {
  FailureMessage,
  SuccessMessage,
  WarningMessage,
  WorkingMessage,
} from 'src/core/POS/MessageWithIcon';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { BulkEditProgress, BulkEditStep } from 'src/WebApiController';

import { ActionOutboxEntityTypeForBulkEdit } from '../../bulkEditUtils';
import * as styles from './BulkEditProgressDisplay.css';
import { CountsByEntityDisplay } from './CountsByEntityDisplay';

export type BulkEditProgressDisplayProps = BulkEditProgress & {
  step: BulkEditStep;
  entityType?: ActionOutboxEntityTypeForBulkEdit;
};

export function BulkEditProgressDisplay({
  countsByEntity,
  successPercent,
  failurePercent,
  warningPercent,
  successCount,
  failureCount,
  warningCount,
  totalCount,
  status,
  step,
  entityType,
  retryableEntities,
}: BulkEditProgressDisplayProps) {
  successPercent =
    totalCount > 0
      ? 0
      : successPercent != null
      ? roundToPrecision(successPercent * 100, 0)
      : null;
  warningPercent =
    totalCount > 0
      ? 0
      : warningPercent != null
      ? roundToPrecision(warningPercent * 100, 0)
      : null;
  failurePercent =
    totalCount > 0
      ? 0
      : failurePercent != null
      ? roundToPrecision(failurePercent * 100, 0)
      : null;

  const skippedItemCount = entityType
    ? retryableEntities?.bulkEditCountPreview[entityType]?.idsAffected.length
    : undefined;

  return (
    <Stack direction="column" gap="l">
      <Stack className={styles.root} direction="column">
        <Stack direction="row" gap="l" justifyContent="spaceBetween">
          <div className={styles.textMain}>
            {step === BulkEditStep.Initializing ? (
              <Content id={ContentId.BulkEditStarting} />
            ) : step === BulkEditStep.InProgress ? (
              <Content id={ContentId.BulkEditInProgress} />
            ) : step === BulkEditStep.Done ? (
              <Content id={ContentId.BulkEditDone} />
            ) : step === BulkEditStep.DoneWithRetryableEntities ? (
              <Content id={ContentId.BulkEditDone} />
            ) : step === BulkEditStep.Cancelled ? (
              <Content id={ContentId.BulkEditCancelled} />
            ) : null}
          </div>
          <Stack direction="column" alignItems="end">
            {countsByEntity && Object.keys(countsByEntity).length > 0 ? (
              <CountsByEntityDisplay countsByEntity={countsByEntity} />
            ) : (
              <>
                {!!successPercent && (
                  <div className={styles.textSuccess}>
                    <FormatContent
                      id={FormatContentId.NCompleted}
                      params={`${successPercent} / 100%`}
                    />
                  </div>
                )}
                {!!successCount && (
                  <div className={styles.textSuccess}>
                    <FormatContent
                      id={FormatContentId.NCompleted}
                      params={
                        totalCount
                          ? `${successCount} / ${totalCount}`
                          : `${successCount}`
                      }
                    />
                  </div>
                )}
                {!!warningPercent && (
                  <div className={styles.textWarn}>
                    <FormatContent
                      id={FormatContentId.NWarnings}
                      params={`${warningPercent} / 100%`}
                    />
                  </div>
                )}
                {!!warningCount && (
                  <div className={styles.textWarn}>
                    <FormatContent
                      id={FormatContentId.NWarnings}
                      params={`${warningCount}`}
                    />
                  </div>
                )}
                {!!failurePercent && (
                  <div className={styles.textError}>
                    <FormatContent
                      id={FormatContentId.NFailed}
                      params={`${failurePercent} / 100%`}
                    />
                  </div>
                )}
                {!!failureCount && (
                  <div className={styles.textError}>
                    <FormatContent
                      id={FormatContentId.NFailed}
                      params={`${failureCount}`}
                    />
                  </div>
                )}
              </>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="spaceBetween">
          {step === BulkEditStep.Done ||
          step === BulkEditStep.DoneWithRetryableEntities ? (
            <>
              {!totalCount && (
                <WarningMessage
                  message={<Content id={ContentId.NoItemsWereChanged} />}
                />
              )}
              {!!failureCount && (!!warningCount || !!successCount) && (
                <WarningMessage
                  message={<Content id={ContentId.BulkEditPartialFailure} />}
                />
              )}
              {!failureCount && !!warningCount && !!successCount && (
                <SuccessMessage
                  message={
                    <Content id={ContentId.BulkEditSuccessWithWarnings} />
                  }
                />
              )}
              {!failureCount && !warningCount && !!successCount && (
                <SuccessMessage
                  message={<Content id={ContentId.BulkEditSuccess} />}
                />
              )}
              {!!failureCount && !warningCount && !successCount && (
                <FailureMessage
                  message={<Content id={ContentId.BulkEditFailed} />}
                />
              )}
            </>
          ) : (
            <WorkingMessage message={status ?? ''} />
          )}
        </Stack>
      </Stack>

      {retryableEntities && entityType && (
        <WarningMessage
          message={
            <FormatContent
              id={FormatContentId.BulkEditSkippedItemsPrompt}
              params={[skippedItemCount?.toString() ?? '']}
            />
          }
          messageStyle={{ fontSize: vars.typography.fontSize.lg }}
        />
      )}
    </Stack>
  );
}
