import categoryIconManifest from 'src/assets/categoryIconManifest.json';
import categoryOverrideMappings from 'src/assets/categoryIconOverrideMappings.json';
import { Performer, TopLevelCategory } from 'src/WebApiController';

const isCategoryOverrideMappingKey = (
  key: string
): key is keyof typeof categoryOverrideMappings =>
  key in categoryOverrideMappings;

const isCategoryIconManifestKey = (
  key: string
): key is keyof typeof categoryIconManifest => key in categoryIconManifest;

const getCategoryIconFilename = (categoryId: string) => {
  if (isCategoryOverrideMappingKey(categoryId)) {
    categoryId = categoryOverrideMappings[categoryId];
  }
  return isCategoryIconManifestKey(categoryId)
    ? categoryIconManifest[categoryId]
    : undefined;
};

export type PerformerCategoryIconProps = {
  performer: Performer;
  /**
   * Size in px
   */
  size?: number;
};

export function PerformerCategoryIcon({
  performer,
  size = 16,
}: PerformerCategoryIconProps) {
  const iconFilename =
    performer.genreId != null
      ? getCategoryIconFilename(performer.genreId.toString())
      : undefined;
  const iconFilepath = iconFilename
    ? `/icons/category/${iconFilename}#category-icon-svg`
    : getTopLevelCategoryIconFilepath(
        (() => {
          if (performer.categ) {
            const category = performer.categ[0];
            if (category) {
              return category;
            }
          }
          return performer.descr as TopLevelCategory;
        })()
      );
  return (
    <svg width={size} height={size} viewBox="0 0 40 40">
      <use href={iconFilepath} width="40" height="40" />
    </svg>
  );
}

function getTopLevelCategoryIconFilepath(topLevelCategory: TopLevelCategory) {
  switch (topLevelCategory) {
    case TopLevelCategory.Concert:
      return `/icons/category/concert.svg#category-icon-svg`;

    case TopLevelCategory.Sports:
      return `/icons/category/sports.svg#category-icon-svg`;

    case TopLevelCategory.Theatre:
      return `/icons/category/theater.svg#category-icon-svg`;

    case TopLevelCategory.Festival:
      return `/icons/category/music.svg#category-icon-svg`;

    default:
      return `/icons/category/music.svg#category-icon-svg`;
  }
}
