import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { IconsFill, IconsProps } from 'src/svgs/Viagogo';

import * as styles from './MenuItem.css';

type MenuItemProps = {
  isActive?: boolean;
  textContent: string | JSX.Element;
  path: string;
  icon: (props: IconsProps) => JSX.Element;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const MenuItem = ({
  isActive = false,
  textContent,
  icon: Icon,
  path,
  onClick,
}: MenuItemProps) => {
  return (
    <div className={styles.menuItemContainer} onClick={onClick}>
      <Link
        to={path}
        className={clsx(styles.menuItem, { [styles.activeItem]: isActive })}
      >
        <div className={styles.icon}>
          <Icon withHoverEffect fill={IconsFill.currentColor} />
        </div>
        <span className={styles.menuText}>{textContent}</span>
      </Link>
    </div>
  );
};
