import React from 'react';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { PosFormField } from 'src/core/POS/PosFormField';
import {
  getTextFieldState,
  PosTextField,
  PosTextFieldProps,
} from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { BarcodeIcon } from 'src/svgs/Viagogo';
import { TicketBarcode } from 'src/WebApiController';

interface SeatBarcodeInputProps extends PosTextFieldProps {
  ticketBarcode: TicketBarcode;
  errors?: string[];
  disabled?: boolean;
  index: number;
}
export const SeatBarcodeInput = React.forwardRef(
  (
    { ticketBarcode, errors, index, ...props }: SeatBarcodeInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const { row, section, seat } = ticketBarcode;
    return (
      <PosFormField
        errors={props.disabled ? undefined : errors}
        label={
          <SeatingInfo
            row={row}
            seatFr={seat || (index + 1).toString()}
            seatTo={null}
            section={section ?? ''}
          />
        }
      >
        <PosTextField
          rootProps={{
            state: getTextFieldState(errors),
          }}
          ref={ref}
          prefixDisplay={<BarcodeIcon size={vars.iconSize.m} align="middle" />}
          {...props}
        />
      </PosFormField>
    );
  }
);

SeatBarcodeInput.displayName = 'VenueMapContent';
