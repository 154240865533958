import { moveElements } from 'src/utils/arrayUtils';
import { BLOCKED_PRIORITY } from 'src/utils/ticketTypeUtils';
import { DeliveryType, TicketType } from 'src/WebApiController';

import { SyncCenterTicketTypeRules } from '../SyncCenter.types';
import { TicketTypeWithPriority } from './ticketTypeOverrideInputForm.types';

export const sortTicketTypePriority = (a: number, b: number) => {
  if (a === BLOCKED_PRIORITY) {
    return 1;
  }
  if (b === BLOCKED_PRIORITY) {
    return -1;
  }
  return a - b;
};

const sortTicketTypeWithPriority = (
  a: TicketTypeWithPriority,
  b: TicketTypeWithPriority
) => {
  if (a.priority == BLOCKED_PRIORITY) {
    return 1;
  }
  if (b.priority == BLOCKED_PRIORITY) {
    return -1;
  }
  return a.priority - b.priority;
};

export const mapTicketTypeWithPriorityList = (
  ticketTypes: TicketType[],
  deliveryType: DeliveryType,
  ticketTypeRules: SyncCenterTicketTypeRules | undefined
): TicketTypeWithPriority[] => {
  return ticketTypes.map((tt, i) => {
    if (ticketTypeRules?.[deliveryType]?.[tt] != null) {
      return {
        ticketType: tt,
        priority: ticketTypeRules[deliveryType]![tt]!,
      };
    } else {
      return {
        ticketType: tt,
        // For those missing value we put to the end
        priority: i + ticketTypes.length,
      };
    }
  });
};

export const shiftTicketTypePriority = (
  priorityOrderBefore: TicketTypeWithPriority[],
  ticketType: TicketType,
  priority: number // starting from one
): TicketTypeWithPriority[] => {
  priorityOrderBefore.sort(sortTicketTypeWithPriority);

  const isBlocked = priority === BLOCKED_PRIORITY;
  const fromIndex = priorityOrderBefore.findIndex(
    (p) => p.ticketType === ticketType
  );

  const toIndex = isBlocked ? priorityOrderBefore.length - 1 : priority - 1;

  priorityOrderBefore[fromIndex].priority = priority;

  const priorityOrderAfter = moveElements(
    priorityOrderBefore,
    fromIndex,
    toIndex
  ).map((to, i) => {
    if (to.priority == BLOCKED_PRIORITY) {
      return to;
    }
    return {
      ...to,
      priority: i + 1,
    };
  });

  return priorityOrderAfter;
};
