import { useFormContext } from 'react-hook-form';
import { TicketTypePriorityInput } from 'src/components/common/TicketTypePriorityInput';
import { DeliveryTypeRadioInput } from 'src/components/Input/DeliveryTypeRadioInput';
import { InHandDateInput } from 'src/components/Input/InHandDateInput';
import { Content } from 'src/contexts/ContentContext';
import { FormPage } from 'src/core/POS/FormPage';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FieldWrapper, FormRow } from 'src/modals/common';
import { IconsFill, SeatMapIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DeliveryType,
  Feature,
  Permission,
  TicketTypeOverride,
} from 'src/WebApiController';

import { SeatSaverListingDetailsInputForm } from '../SeatSaverListingWizard.types';

export const DeliverySection = ({
  inputIndeces,
  disabled,
}: {
  inputIndeces: number[];
  disabled?: boolean;
}) => {
  const { watch, getValues, setValue, clearErrors, getFieldState } =
    useFormContext<SeatSaverListingDetailsInputForm>();

  const isMobile = useMatchMedia('mobile');
  const hasSelectDeliveryStrategyInSeatSaverFeature = useUserHasFeature(
    Feature.SelectDeliveryStrategyInSeatSaver
  );

  const event = getValues(`inputs.${inputIndeces[0]}.event`);

  // Of these will have same value, so we just need one
  const deliveryTypeField: `inputs.${number}.deliveryType` = `inputs.${inputIndeces[0]}.deliveryType`;
  const deliveryType = watch(deliveryTypeField);
  const deliveryTypeError = getFieldState(deliveryTypeField).error?.message;

  const inHandDateField: `inputs.${number}.inHandDate` = `inputs.${inputIndeces[0]}.inHandDate`;
  const inHandDate = watch(inHandDateField);
  const inHandDateError = getFieldState(inHandDateField).error?.message;
  const daysBeforeEvent = watch(`inputs.${inputIndeces[0]}.daysBeforeEvent`);

  const tktTypeRulesField: `inputs.${number}.ticketTypeRules` = `inputs.${inputIndeces[0]}.ticketTypeRules`;
  const ticketTypeRules = watch(tktTypeRulesField);

  const canSetCustomDeliveryType = useUserHasAnyOfPermissions(
    Permission.Purchases_UpdateCustomDeliveryType,
    Permission.Purchases_UpdateCustomDeliveryTypeOwned
  );

  const onDeliveryTypeChange = (newDelType: DeliveryType) => {
    if (!disabled && newDelType && newDelType !== deliveryType) {
      clearErrors(deliveryTypeField);

      inputIndeces.forEach((i) => {
        setValue(`inputs.${i}.deliveryType`, newDelType);
      });
    }
  };

  const onticketTypeRulesChange = (
    newTicketTypeRules: TicketTypeOverride | null
  ) => {
    if (
      hasSelectDeliveryStrategyInSeatSaverFeature &&
      !disabled &&
      newTicketTypeRules &&
      newTicketTypeRules !== ticketTypeRules
    ) {
      clearErrors(tktTypeRulesField);

      inputIndeces.forEach((i) => {
        setValue(`inputs.${i}.ticketTypeRules`, newTicketTypeRules);
      });
    }
  };

  const onInHandDateChange = (date?: Date, daysBeforeEvent?: number) => {
    if (date) {
      clearErrors(inHandDateField);
      const dateStr = date.toISOString();
      if (dateStr !== inHandDate) {
        inputIndeces.forEach((i) => {
          setValue(`inputs.${i}.inHandDate`, dateStr);
          setValue(`inputs.${i}.daysBeforeEvent`, daysBeforeEvent ?? null);
        });
      }
    }
  };

  return (
    <FormPage.Section
      icon={
        <SeatMapIcon fill={IconsFill.currentColor} size={vars.iconSize.s} />
      }
      title={<Content id={ContentId.MarketplaceDelivery} />}
    >
      <FormRow vertical={isMobile}>
        <DeliveryTypeRadioInput
          value={deliveryType}
          onChange={onDeliveryTypeChange}
          error={deliveryTypeError}
          hideCustomType={!canSetCustomDeliveryType}
        />
      </FormRow>
      {hasSelectDeliveryStrategyInSeatSaverFeature && (
        <FormRow vertical={isMobile}>
          <PosFormField label={<Content id={ContentId.DeliveryStrategy} />}>
            <TicketTypePriorityInput
              deliveryType={deliveryType}
              ticketTypeRules={ticketTypeRules}
              onTicketTypeRulesChange={onticketTypeRulesChange}
            />
          </PosFormField>
        </FormRow>
      )}
      <FieldWrapper width="max-content">
        <FormRow vertical={isMobile}>
          <PosFormField
            label={<Content id={ContentId.InHandDate} />}
            errors={inHandDateError}
          >
            <InHandDateInput
              disabled={disabled}
              onChange={onInHandDateChange}
              value={inHandDate}
              daysBeforeEvent={daysBeforeEvent ?? undefined}
              targetDate={
                event?.dates?.start ? new Date(event?.dates?.start) : new Date()
              }
            />
          </PosFormField>
        </FormRow>
      </FieldWrapper>
    </FormPage.Section>
  );
};
