import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import { Content } from 'src/contexts/ContentContext';
import { DropWrapper } from 'src/core/POS/DragAndDropSort/DragAndDrop';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';

import { ColumnListItem } from '../InventoryTableColumns/ColumnListItem/ColumnListItem';
import { useColumnDisplayName } from '../InventoryTableColumns/useColumnDisplayName';
import * as styles from './ColumnsSection.css';

type ColumnsSelectionProps = {
  titleContentId?: ContentId;
  items?: string[];
  renderActions: (id: string) => JSX.Element;
  onDragEnd?: (event: DragEndEvent) => void;
  sectionType: SectionType;
  highlightIndex?: number;
  shouldHideItem?: (id: string) => boolean;
};

/**
 * The sections that represent the Add Columns and Current Columns for the Edit Columns experience
 */
export const ColumnsSection = ({
  titleContentId,
  items = [],
  renderActions,
  onDragEnd,
  sectionType,
  highlightIndex,
  shouldHideItem,
}: ColumnsSelectionProps) => {
  const { displayNames } = useColumnDisplayName({
    columnIds: items,
    sectionType,
  });

  return (
    <div>
      {titleContentId && (
        <div className={styles.title}>
          <Content id={titleContentId} />
        </div>
      )}
      <div className={styles.itemsContainer}>
        {onDragEnd ? (
          <DndContext onDragEnd={onDragEnd}>
            <SortableContext items={items}>
              {items.map((id, i) => {
                if (shouldHideItem?.(id)) {
                  return null;
                }

                return (
                  <DropWrapper id={id} key={id}>
                    <ColumnListItem
                      draggable
                      actionButton={renderActions(id)}
                      highlighted={highlightIndex === i}
                    >
                      <span
                        style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}
                      >
                        {displayNames[i]}
                      </span>
                    </ColumnListItem>
                  </DropWrapper>
                );
              })}
            </SortableContext>
          </DndContext>
        ) : (
          <>
            {items.map((id, i) => (
              <ColumnListItem
                key={id}
                actionButton={renderActions(id)}
                highlighted={highlightIndex === i}
              >
                <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
                  {displayNames[i]}
                </span>
              </ColumnListItem>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
