import { createColumnHelper } from '@tanstack/react-table';
import { formatInTimeZone } from 'date-fns-tz';
import {
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_XL,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { ReportMetricsResponse } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { FormatOption } from 'src/modals/EditTableColumns';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';
import { IconsFill } from 'src/svgs/Viagogo';
import { LinkCell } from 'src/tables/ReportTableCommon/LinkCell';
import { SHIMMERING_DIV_HEIGHT_SALE_REPORT } from 'src/tables/ReportTableCommon/ReportTableCommon.constants';
import { TableHeader } from 'src/tables/ReportTableCommon/TableHeader';
import { TextCell } from 'src/tables/ReportTableCommon/TextCell';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { DateCell } from 'src/tables/Table/TableCells';
import { InHandCell } from 'src/tables/Table/TableCells';
import { getColumnPersonalization } from 'src/utils/columns/columnPersonalizationUtils';
import { SalesReportTableColumnId } from 'src/utils/columns/sales/salesColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { TICKET_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import {
  getSaleDetailsRelativeUrl,
  getSaleStatusDisplayContentId,
  getSaleStatusIcon,
} from 'src/utils/saleUtils';
import { sortMoney } from 'src/utils/tableUtils';
import { getTicketTypeIcon } from 'src/utils/ticketTypeUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { PointOfSaleSaleStatus, TicketType } from 'src/WebApiController';

const SECTION_TYPE = SectionType.SalesReport;

const columnHelper = createColumnHelper<ReportMetricsResponse | null>();

const accessorFn = (columnId: SalesReportTableColumnId) => {
  return (data: ReportMetricsResponse | null) =>
    data?.[columnId.toUpperCase()] ?? '';
};

const SALE_REPORT_SPECIAL_TABLE_COLUMNS_CONFIG = {
  [SalesReportTableColumnId.SaleMonth]: columnHelper.accessor(
    accessorFn(SalesReportTableColumnId.SaleMonth),
    {
      id: SalesReportTableColumnId.SaleMonth,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { timeZone } = useSiteTimezoneContext();
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const dateValue = getValue<string | null | undefined>();

        return (
          <TableCellDiv align="left">
            <div>
              {dateValue
                ? formatInTimeZone(new Date(dateValue), timeZone, 'MMMM yyyy')
                : ''}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.SaleWeek]: columnHelper.accessor(
    accessorFn(SalesReportTableColumnId.SaleWeek),
    {
      id: SalesReportTableColumnId.SaleWeek,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { timeZone } = useSiteTimezoneContext();
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const dateValue = getValue<string | null | undefined>();

        const dateEndOfWeek = dateValue ? new Date(dateValue) : null;
        if (dateEndOfWeek) {
          dateEndOfWeek.setDate(dateEndOfWeek.getDate() + 6);
        }

        return (
          <TableCellDiv align="left">
            <div>
              {dateValue && dateEndOfWeek
                ? formatInTimeZone(
                    new Date(dateValue),
                    timeZone,
                    'yyyy/MM/dd'
                  ) +
                  ' - ' +
                  formatInTimeZone(dateEndOfWeek, timeZone, 'yyyy/MM/dd')
                : ''}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.EventId]: columnHelper.accessor(
    accessorFn(SalesReportTableColumnId.EventId),
    {
      id: SalesReportTableColumnId.EventId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => {
        const eventId = params.row.original?.eventId;
        if (!eventId) {
          return (
            <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
          );
        }
        const eventUrl = createInventoryEventUrl(eventId);
        return (
          <LinkCell
            text={String(params.getValue())}
            url={eventUrl}
            align="left"
          />
        );
      },
    }
  ),
  [SalesReportTableColumnId.EventDate]: columnHelper.accessor(
    accessorFn(SalesReportTableColumnId.EventDate),
    {
      id: SalesReportTableColumnId.EventDate,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<string>();
        const { userDefinedPrecision = FormatOption.DateTime_MMDD_Time_Day } =
          useColumnUserSetting(columnId, SECTION_TYPE);

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }
        const dateValue = value ? new Date(value).toISOString() : value;

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={dateValue}
            enableUtcFallback
            hideRelativeTerms
            align="left"
            useSiteTimeZone={false}
          />
        );
      },
    }
  ),
  [SalesReportTableColumnId.SaleId]: columnHelper.accessor(
    accessorFn(SalesReportTableColumnId.SaleId),
    {
      id: SalesReportTableColumnId.SaleId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const saleId = getValue<string>();

        if (!saleId) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const url = getSaleDetailsRelativeUrl(saleId!);
        return (
          <LinkCell
            text={`#${saleId}`}
            url={url}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [SalesReportTableColumnId.Status]: columnHelper.accessor(
    accessorFn(SalesReportTableColumnId.Status),
    {
      id: SalesReportTableColumnId.Status,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const value = getValue() as PointOfSaleSaleStatus;

        // TODO: SaleReportMetricsWithGroupBy does not have allocationState. We should add it
        const saleStatusContentId = getSaleStatusDisplayContentId(value);

        const statusContent = useContent(saleStatusContentId ?? '');
        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const SaleStatusIcon = getSaleStatusIcon(value);

        return (
          <TableCellDiv align="left">
            {userDefinedPrecision === FormatOption.EnumDisplay_Icon ? (
              <SaleStatusIcon fill={'transparent'} title={statusContent} />
            ) : (
              <Content id={saleStatusContentId} />
            )}
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.TicketType]: columnHelper.accessor(
    accessorFn(SalesReportTableColumnId.TicketType),
    {
      id: SalesReportTableColumnId.TicketType,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );
        const value = getValue() as TicketType;
        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }
        const ticketTypeIcon = getTicketTypeIcon(value, IconsFill.textStrong);
        return (
          <TableCellDiv align="left" title={value}>
            {userDefinedPrecision === FormatOption.EnumDisplay_Icon ? (
              <>{ticketTypeIcon}</>
            ) : (
              <Content id={TICKET_TYPE_TO_CID[value]} />
            )}
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.InHandDate]: columnHelper.accessor(
    accessorFn(SalesReportTableColumnId.InHandDate),
    {
      id: SalesReportTableColumnId.InHandDate,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue<string | null>();
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }
        return (
          <TableCellDiv align="left">
            <InHandCell
              date={value ? new Date(value) : null}
              needsToFulfill={Boolean(
                original?.saleStatus &&
                  ![
                    PointOfSaleSaleStatus.ProcessingFulfillment,
                    PointOfSaleSaleStatus.Fulfilled,
                    PointOfSaleSaleStatus.CancelledHold,
                    PointOfSaleSaleStatus.Rejected,
                    PointOfSaleSaleStatus.PendingRejection,
                  ].includes(original?.saleStatus)
              )}
              handleFulfillmentState
            />
          </TableCellDiv>
        );
      },
      sortingFn: 'datetime',
    }
  ),
  [SalesReportTableColumnId.PaymentReceived]: columnHelper.accessor(
    accessorFn(SalesReportTableColumnId.PaymentReceived),
    {
      id: SalesReportTableColumnId.PaymentReceived,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const value = getValue();
        if (original === undefined) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        return (
          <TableCellDiv align="left">
            <Content id={value ? ContentId.Yes : ContentId.No} />
          </TableCellDiv>
        );
      },
    }
  ),
  [SalesReportTableColumnId.PurchaseId]: columnHelper.accessor(
    accessorFn(SalesReportTableColumnId.PurchaseId),
    {
      id: SalesReportTableColumnId.PurchaseId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const purchaseId = getValue<string | null>();

        if (!purchaseId) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
          );
        }

        const purchaseIds = [Number(purchaseId)];

        return (
          <LinkCell
            text={purchaseIds.map((purchaseId) => `#${purchaseId}`)}
            url={purchaseIds.map((purchaseId) =>
              getPurchaseOrderRelativeUrl(purchaseId)
            )}
            separator={<span>{', '}</span>}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
} as const;

export const saleReportV2TableColumnDef = (
  columnId: SalesReportTableColumnId
) => {
  const specialConfig = Object.entries(
    SALE_REPORT_SPECIAL_TABLE_COLUMNS_CONFIG
  ).find(([key]) => key === columnId)?.[1];

  if (specialConfig) {
    return specialConfig;
  }

  const personalization = getColumnPersonalization({
    id: columnId,
    sectionType: SECTION_TYPE,
  });

  const align = personalization.isNumeric ? 'right' : 'left';

  return columnHelper.accessor(accessorFn(columnId), {
    id: columnId,
    header: (params) => (
      <TableHeader {...params} align={align} sectionType={SECTION_TYPE} />
    ),
    size: COLUMN_DEFAULT_SIZE_BASE,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell(params) {
      const value = params.getValue<string | null>();

      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SECTION_TYPE
      );

      const { getUiCurrency } = useLocalizationContext();

      if (!params.row.original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
        );
      }

      // Handle columns with personalization
      if (personalization.isCurrency) {
        const uiCurrency = getUiCurrency(params.row.original['CurrencyCode']);

        return (
          <TableCellDiv align={align}>
            {userDefinedPrecision ? (
              applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: value,
                currencyCode: uiCurrency.code,
                currencyDecimalDigits: uiCurrency.dec,
              })
            ) : (
              <Content id={ContentId.NA} />
            )}
          </TableCellDiv>
        );
      }

      if (personalization.isNumeric) {
        return (
          <TableCellDiv align={align}>
            {userDefinedPrecision
              ? applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: value,
                  isPercent: personalization.isPercent,
                })
              : value ?? <Content id={ContentId.NA} />}
          </TableCellDiv>
        );
      }

      if (personalization.isDateTime) {
        const dateValue = value ? new Date(value).toISOString() : value;
        return (
          <DateCell
            precision={userDefinedPrecision}
            date={dateValue}
            align={align}
            hideRelativeTerms
            useSiteTimeZone={columnId !== SalesReportTableColumnId.EventDate}
          />
        );
      }

      // Handle columns without personalization
      return <TextCell {...params} align={align} sectionType={SECTION_TYPE} />;
    },
    footer: function Footer(params) {
      const original = params.table.options.meta?.summaryData;

      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SECTION_TYPE
      );

      if (!original) {
        return (
          <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SALE_REPORT} />
        );
      }
      const value = params.column.accessorFn?.(original, -1);

      if (personalization.isCurrency) {
        return (
          <TableCellDiv align="right">
            {userDefinedPrecision ? (
              applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: value,
                currencyCode: original['CurrencyCode'],
              })
            ) : (
              <Content id={ContentId.NA} />
            )}
          </TableCellDiv>
        );
      }

      if (personalization.isNumeric) {
        return (
          <TableCellDiv align="right">
            {userDefinedPrecision
              ? applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: value,
                  isPercent: personalization.isPercent,
                })
              : value ?? <Content id={ContentId.NA} />}
          </TableCellDiv>
        );
      }

      return <TableCellDiv align={align}>{value}</TableCellDiv>;
    },
    sortingFn: personalization.isCurrency
      ? sortMoney
      : personalization.isNumeric
      ? 'alphanumeric'
      : personalization.isDateTime
      ? 'datetime'
      : 'basic',
  });
};
