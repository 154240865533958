import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function SwitchIcon(props: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper {...props} viewBox="0 0 39 36" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 15.12L8.36364 21.2M8.36364 21.2L13.4545 15.12M8.36364 21.2V5.84C8.36364 5.84 8.36364 2 14.0909 2C19.8182 2 19.8182 5.84 19.8182 5.84V29.52C19.8182 29.52 20.1364 34 25.5455 34C30.9545 34 30.9545 29.52 30.9545 29.52V15.12M30.9545 15.12L24.9091 21.2M30.9545 15.12L37 21.2"
        stroke="#371B6A"
        strokeWidth="3"
        fill="inherit"
      />
    </SvgWrapper>
  );
}
