import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  EventAccordionItemProps,
  translateEventToInventoryAccordionItem,
} from 'src/components/Accordions';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import { BackSection } from 'src/components/common/BackSection';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { StubHubListingManualPricingContextProvider } from 'src/components/Listings/InventoryEventPage/MarketListings/StubHubListingsManualPricingContext';
import { ActiveFocusContextProvider } from 'src/contexts/ActiveFocusContext/ActiveFocusContext';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { CollapsableViewProvider } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import {
  EventSessionRole,
  useEventHubContext,
} from 'src/contexts/EventHubContext/EventHubContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { InputPriceFocusProvider } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import {
  EventLastReviewedContextProvider,
  useEventLastReviewedContext,
} from 'src/contexts/SellerEventLastReviewContext/SellerEventLastReviewContext';
import {
  SidePanelPage,
  SidePanelProvider,
} from 'src/contexts/SidePanelContext';
import { ExplorerLayout } from 'src/core/layouts';
import { LoadingButton } from 'src/core/POS/LoadingButton/LoadingButton';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useSellerEventNote } from 'src/hooks/api/useSellerEventNote';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useQuickFilterSearchParam } from 'src/hooks/useQuickFilters';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import { getAppliedFilterCounts } from 'src/utils/eventQueryUtils';
import { getListingDetailsModalConfigWithDeepLink } from 'src/utils/inventoryUtils';
import {
  EventWithData,
  Feature,
  ListingDetails,
  ListingQuery,
} from 'src/WebApiController';
import Swiper from 'swiper';

import CommandMenu from './CommandMenu/CommandMenu';
import { InventoryEventCatchUpBar } from './InventoryEventCatchUp';
import * as styles from './InventoryEventPage.css';
import { useCatchUpParams } from './InventoryEventPage.hooks';
import { InventoryEventPageContent } from './InventoryEventPageContent';
import { InventoryEventPageHeader } from './InventoryEventPageHeader';
import { InventoryEventPageRightPanel } from './InventoryEventPageRightPanel';

export function InventoryEventPage({
  viagVirtualId,
  posEventIds,
  returnUrl,
}: {
  posEventIds?: string[];
  viagVirtualId?: string;
  returnUrl?: string;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const hasPricingCatchupFeature = useUserHasFeature(Feature.PricingCatchup);
  const {
    eventsTransformed,
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();

  useEffect(() => {
    if (!viagVirtualId && posEventIds?.length) {
      setListItemExpansion(
        true,
        posEventIds.map((id) => `old:${id}`)
      );
    } else if (viagVirtualId) {
      setListItemExpansion(true, [viagVirtualId]);
    }
  }, [
    activeAccountWebClientConfig,
    posEventIds,
    setListItemExpansion,
    viagVirtualId,
  ]);

  const { setAllGroupsIds } = useMultiSelectionContext();

  const accordionItemProps = useMemo(
    () =>
      eventsTransformed?.length
        ? eventsTransformed.find(
            (ev) =>
              ev.event.viagVirtualId === viagVirtualId ||
              ev.event.posIds.some((id) => posEventIds?.includes(id))
          )
        : undefined,
    [eventsTransformed, posEventIds, viagVirtualId]
  );

  const inventoryAccordionItem = useMemo(
    () =>
      accordionItemProps &&
      translateEventToInventoryAccordionItem(accordionItemProps, 0, true),
    [accordionItemProps]
  );

  useEffect(() => {
    if (inventoryAccordionItem) {
      setAllGroupsIds([inventoryAccordionItem.itemId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryAccordionItem]);

  return (
    <SidePanelProvider sidePanelId="inventory-event">
      <ActiveFocusContextProvider>
        <EventMapContextProvider event={accordionItemProps?.event}>
          <InputPriceFocusProvider
            disablePagination
            pageSize={accordionItemProps?.listCnt ?? 0}
          >
            <StubHubListingManualPricingContextProvider
              groupId={accordionItemProps?.event.viagVirtualId}
            >
              <EventLastReviewedContextProvider>
                <Stack direction="column" height="full">
                  <InventoryEventContent
                    accordionItemProps={accordionItemProps}
                    inventoryAccordionItem={inventoryAccordionItem}
                    returnUrl={returnUrl}
                  />
                  {hasPricingCatchupFeature && <InventoryEventCatchUpBar />}
                </Stack>
              </EventLastReviewedContextProvider>
            </StubHubListingManualPricingContextProvider>
          </InputPriceFocusProvider>
        </EventMapContextProvider>
      </ActiveFocusContextProvider>
    </SidePanelProvider>
  );
}

type InventoryEventContentProps = {
  accordionItemProps?: EventWithData;
  inventoryAccordionItem?: EventAccordionItemProps;
  returnUrl?: string;
};

export const enum InventoryEventTabs {
  Info = 'info',
  Tags = 'tags',
  SellThroughRate = 'sellThroughRate',
  ManualPricing = 'manualPricing',
  Transactions = 'transactions',
  Fulfillment = 'fulfillment',
  Dashboard = 'dashboard',
  EditMap = 'editMap',
  PricingABTest = 'pricingABTest',
  None = 'none',
}

function InventoryEventContent({
  accordionItemProps,
  inventoryAccordionItem,
  returnUrl,
}: InventoryEventContentProps) {
  const [selectedListingIds, setSelectedListingIds] = useState<number[]>([]);

  const { isLoading } = useCatalogDataContext();

  const isMobile = useMatchMedia('mobile');

  const quickFilterSearchParams = useQuickFilterSearchParam();
  const { tempQuery, initialQuery } = useFilterQueryContext<ListingQuery>();

  const { setModal } = useContext(ModalContext);
  const { posEntity: listing, setActivePosEntity } =
    useActivePosEntityContext<ListingDetails>();

  const hasPricingV2DemandDashboard = useUserHasFeature(
    Feature.PricingV2DemandDashboard
  );
  const intialActiveTab = hasPricingV2DemandDashboard
    ? InventoryEventTabs.Dashboard
    : InventoryEventTabs.Info;

  const [activeTab, setActiveTab] = useState(intialActiveTab);

  const hasEventLastReviewedFeature = useUserHasFeature(
    Feature.EventLastReviewed
  );
  const activeId = getDeepLinkIdFromUrl(
    InventoryDeeplinkQueryParam,
    window.location.href
  );

  useEffect(() => {
    if (activeId?.length && !listing) {
      setActivePosEntity(parseInt(activeId), undefined, true);
      setModal(getListingDetailsModalConfigWithDeepLink(activeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  const swiperRef = useRef<Swiper>();

  const event = accordionItemProps?.event;

  const {
    isCompleted,
    isLoading: isEventReviewedLoading,
    onSettingVirtualIds,
    onUpdateLastReviewedDateTime,
  } = useEventLastReviewedContext();

  useEffect(() => {
    onSettingVirtualIds(event?.viagVirtualId ? [event?.viagVirtualId] : []);
  }, [event?.viagVirtualId, onSettingVirtualIds]);

  const { addNote, isLoading: isEventNoteLoading } = useSellerEventNote(
    event?.viagId
  );
  const markAsReviewed = useContent(ContentId.MarkAsReviewed);
  const markedAsReviewed = useContent(ContentId.MarkedAsReviewed);

  const { onNextClick, isCatchupMode } = useCatchUpParams();

  const onMarkAsReviewed = useCallback(async () => {
    if (event?.viagVirtualId) {
      await addNote(markedAsReviewed);
      onUpdateLastReviewedDateTime(event.viagVirtualId);
      onNextClick();
    }
  }, [
    addNote,
    event?.viagVirtualId,
    markedAsReviewed,
    onNextClick,
    onUpdateLastReviewedDateTime,
  ]);

  const {
    onSelectListing,
    onUpdateInventoryTab,
    selectedListingId,
    sessionRole,
    inventoryTab,
    enabled,
  } = useEventHubContext();

  const onSetInventoryTab = useCallback(
    (tab: InventoryEventTabs) => {
      setActiveTab(tab);
      if (enabled && sessionRole === EventSessionRole.Main) {
        onUpdateInventoryTab(tab);
      }
    },
    [enabled, onUpdateInventoryTab, sessionRole]
  );

  useEffect(() => {
    if (enabled && sessionRole === EventSessionRole.SidePanel && inventoryTab) {
      setActiveTab(inventoryTab);
    }
  }, [activeTab, enabled, inventoryTab, sessionRole]);

  useEffect(() => {
    if (enabled && selectedListingId) {
      setSelectedListingIds([selectedListingId]);
    }
  }, [enabled, selectedListingId]);

  useEffect(() => {
    if (enabled && selectedListingIds?.length == 1) {
      onSelectListing(selectedListingIds[0]);
    }
  }, [enabled, onSelectListing, selectedListingIds]);

  if (!event) {
    if (
      quickFilterSearchParams ||
      getAppliedFilterCounts(tempQuery, initialQuery)
    )
      return (
        <EmptySectionContent.Root
          icon={
            <EmptySectionContent.SolidIconContainer>
              <SearchSolidIcon />
            </EmptySectionContent.SolidIconContainer>
          }
        >
          <EmptySectionContent.Label>
            <Content id={ContentId.NoResultFound} />
          </EmptySectionContent.Label>
          <EmptySectionContent.DetailMessage>
            <Content id={ContentId.NoFilteredNotifications} />
          </EmptySectionContent.DetailMessage>
        </EmptySectionContent.Root>
      );
  }

  if (isLoading || !event) {
    return <PosSpinner />;
  }

  return (
    <div className={styles.root}>
      <CommandMenu groupId={event.viagVirtualId} event={event} />
      <ExplorerLayout
        swiperRef={swiperRef}
        defaultRatio={0.35}
        maxPanelPercent={0.5}
        showExpandButton
        sidePanelPage={SidePanelPage.InventoryEvent}
        rightPanelContent={
          !(enabled && sessionRole) && (
            <InventoryEventPageRightPanel
              event={event}
              listings={accordionItemProps?.listings}
              selectedListingIds={selectedListingIds}
              activeTab={activeTab}
            />
          )
        }
      >
        <div className={styles.leftContainer}>
          {isMobile && (
            <Stack
              justifyContent="spaceBetween"
              alignItems="center"
              style={{
                padding: `0 ${vars.spacing['xl']} ${vars.spacing['lg']} ${vars.spacing['xl']}`,
              }}
            >
              <BackSection
                returnUrl={returnUrl ?? '/inventory'}
                state={{
                  fromPosEventId: event.viagVirtualId,
                }}
              />
              <SwiperButton dir="right" />
            </Stack>
          )}
          <CollapsableViewProvider isEnabled={true}>
            <InventoryEventPageHeader
              event={event}
              inventoryAccordionItem={inventoryAccordionItem}
            />
            {enabled && sessionRole === EventSessionRole.SidePanel ? (
              <InventoryEventPageRightPanel
                event={event}
                listings={accordionItemProps?.listings}
                selectedListingIds={selectedListingIds}
                activeTab={activeTab}
              />
            ) : (
              <InventoryEventPageContent
                accordionItemProps={accordionItemProps}
                inventoryAccordionItem={inventoryAccordionItem}
                selectedListingIds={selectedListingIds}
                setSelectedListingIds={setSelectedListingIds}
                activeTab={activeTab}
                setActiveTab={onSetInventoryTab}
              />
            )}
          </CollapsableViewProvider>
          {hasEventLastReviewedFeature && isCatchupMode && (
            <div className={styles.absoluteButton}>
              <LoadingButton
                title={markAsReviewed}
                isLoading={isEventReviewedLoading || isEventNoteLoading}
                isCompleted={isCompleted}
                onClick={onMarkAsReviewed}
              />
            </div>
          )}
        </div>
      </ExplorerLayout>
    </div>
  );
}
