import { ReactNode, useCallback, useMemo, useState } from 'react';
import {
  FilterToolbarGroup,
  FilterToolbarItem,
  FilterToolbarItemId,
} from 'src/components/FilterToolbar';
import {
  FilterDialogV2Body,
  FilterDialogV2Footer,
  FilterDialogV2Header,
  FilterDialogV2Wrapper,
  saveFiltersButton,
} from 'src/components/FilterToolbar/FilterDialogV2/FilterDialogV2.css';
import {
  FilterDropdownItem,
  FiltersDropdown,
} from 'src/components/FilterToolbar/FilterDialogV2/FiltersDropdown/FiltersDropdown';
import { FiltersList } from 'src/components/FilterToolbar/FilterDialogV2/FiltersList/FiltersList';
import { mapFiltersToFiltersState } from 'src/components/FilterToolbar/FilterDialogV2/hooks/mapFiltersToFiltersState';
import { useFilterFiltersByPermission } from 'src/components/FilterToolbar/FilterDialogV2/hooks/useFilterFiltersByPermission';
import { Content } from 'src/contexts/ContentContext';
import { CrossIconContainer } from 'src/core/interim/dialogs/GenericDialog/GenericDialog.styled';
import { vars } from 'src/core/themes';
import { Button, Popover, Stack } from 'src/core/ui';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DEFAULT_REPORT_FILTER_EDITABILITY,
  ReportFilterEditability,
} from 'src/utils/reportsFilterUtils';

import { isFilterEmpty } from './FiltersList/FiltersList.utils';

export interface FilterDialogV2Props {
  filters: FilterToolbarGroup[];
  onResetAll: () => void;
  onSubmitFilter?: () => void;
  appliedFilterIds: FilterToolbarItemId[];
  onSaveQuickFilter?: () => void;
  resetTempQuery?: (keys?: FilterToolbarItemId[]) => void;
  tempQuery?: Partial<Record<FilterToolbarItemId, unknown>>;
  initialQuery?: Partial<Record<FilterToolbarItemId, unknown>>;
  mandatoryFiltersToShow: FilterToolbarItemId[];
  filterAppliedMessage?: ReactNode;

  onEditabilityChange?: (
    filterId: FilterToolbarItemId | FilterToolbarItemId[],
    editability: ReportFilterEditability
  ) => void;
  embeddedDisplayOnly?: boolean;
  listView?: boolean;
  performerVenueFilter?: JSX.Element;
}

export type SelectedFilters = Record<string, FilterDropdownItem[]>;

export const FilterDialogV2 = ({
  filters,
  onResetAll,
  onSubmitFilter,
  appliedFilterIds,
  onSaveQuickFilter,
  resetTempQuery,
  tempQuery = {},
  initialQuery = {},
  mandatoryFiltersToShow,
  filterAppliedMessage,
  onEditabilityChange,
  embeddedDisplayOnly,
  listView,
  performerVenueFilter,
}: FilterDialogV2Props) => {
  const filtersMap = useMemo<
    Partial<Record<FilterToolbarItemId, FilterToolbarItem>>
  >(() => {
    const filtersMap: Partial<Record<FilterToolbarItemId, FilterToolbarItem>> =
      {};
    filters.forEach((filterGroup) => {
      filterGroup.items.forEach((filterItem) => {
        filtersMap[filterItem.filterId] = filterItem;
      });
    });
    return filtersMap;
  }, [filters]);

  const filteredByPermissionsFilters = useFilterFiltersByPermission({
    filters,
  });

  const nonHiddenFilterIds = useMemo(
    () =>
      onEditabilityChange
        ? filteredByPermissionsFilters
            .flatMap((group) => group.items)
            .filter(
              (item) =>
                (item.reportFilterEditability ??
                  DEFAULT_REPORT_FILTER_EDITABILITY) !==
                ReportFilterEditability.Hidden
            )
            .map((item) => item.filterId)
        : [],
    [filteredByPermissionsFilters, onEditabilityChange]
  );

  const appliedFilterIdsWithNonHidden = useMemo(
    () => Array.from(new Set([...appliedFilterIds, ...nonHiddenFilterIds])),
    [appliedFilterIds, nonHiddenFilterIds]
  );

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(() =>
    mapFiltersToFiltersState({
      filters: filteredByPermissionsFilters,
      appliedFilterIds: appliedFilterIdsWithNonHidden,
      mandatoryFiltersToShow,
    })
  );

  const hasFiltersApplied = useMemo<boolean>(
    () => Object.keys(selectedFilters).length > 0,
    [selectedFilters]
  );

  const hasFiltersWithDefaultValues = useMemo<boolean>(() => {
    return Object.values(selectedFilters)
      .flatMap((filterDropDownItem) => filterDropDownItem)
      .some((filterDropDownItem) => {
        return isFilterEmpty(
          filterDropDownItem.filterId,
          tempQuery,
          filtersMap,
          mandatoryFiltersToShow
        );
      });
  }, [filtersMap, mandatoryFiltersToShow, selectedFilters, tempQuery]);

  const clearAll = useCallback(() => {
    // Clear all and keep the mandatory fields
    const cleanSelectedFilters: SelectedFilters = {};
    for (const key in selectedFilters) {
      selectedFilters[key].forEach((dropdownItem) => {
        if (
          mandatoryFiltersToShow.includes(dropdownItem.filterId) ||
          nonHiddenFilterIds.includes(dropdownItem.filterId) ||
          initialQuery[dropdownItem.filterId] != null
        ) {
          cleanSelectedFilters[key] = cleanSelectedFilters[key] ?? [];
          cleanSelectedFilters[key].push(dropdownItem);
        }
      });
    }
    setSelectedFilters(cleanSelectedFilters);
    resetTempQuery?.();
    onResetAll();
  }, [
    initialQuery,
    mandatoryFiltersToShow,
    nonHiddenFilterIds,
    onResetAll,
    resetTempQuery,
    selectedFilters,
  ]);

  const onFilterRemoved = useCallback(
    (removedFilterDropdownItem: FilterDropdownItem) => {
      const titleContentId = removedFilterDropdownItem.titleContentId as string;

      const filterToolbarGroup = filters.find(
        (group) => group.titleContentId === titleContentId
      );

      if (!filterToolbarGroup) {
        return;
      }

      const filtersToRemove = filterToolbarGroup.items.filter(
        (filter) => filter.filterId === removedFilterDropdownItem.filterId
      );

      const filterIdsToRemove = filtersToRemove.map((f) => f.filterId);
      const filteredDropdownItems = (
        selectedFilters[titleContentId] ?? []
      ).filter(
        (dropdownItem) => !filterIdsToRemove.includes(dropdownItem.filterId)
      );

      const updatedSelectedFilters = { ...selectedFilters };
      if (filteredDropdownItems.length > 0) {
        updatedSelectedFilters[titleContentId] = filteredDropdownItems;
      } else {
        delete updatedSelectedFilters[titleContentId];
      }

      // all keys to remove from tempQuery
      const filterQueryKeysToRemove = filtersToRemove.flatMap(
        (f) => f.filterQueryKeys ?? []
      );

      resetTempQuery?.(filterQueryKeysToRemove);
      setSelectedFilters(updatedSelectedFilters);
      onEditabilityChange?.(
        filtersToRemove.map((f) => f.filterId),
        ReportFilterEditability.Hidden
      );
    },
    [filters, onEditabilityChange, resetTempQuery, selectedFilters]
  );

  if (embeddedDisplayOnly) {
    return (
      <FiltersList
        filters={filteredByPermissionsFilters}
        selectedDropdownItems={selectedFilters}
        tempQuery={tempQuery}
        filtersMap={filtersMap}
        onFilterRemoved={onFilterRemoved}
        onEditabilityChange={onEditabilityChange}
        mandatoryFiltersToShow={mandatoryFiltersToShow}
        embeddedDisplayOnly
        performerVenueFilter={performerVenueFilter}
        embeddedResetButton={
          <Button
            variant="text"
            onClick={clearAll}
            style={{ height: 'fit-content', whiteSpace: 'nowrap' }}
          >
            <Content id={ContentId.ResetFilters} />
          </Button>
        }
      />
    );
  }

  if (listView) {
    return (
      <>
        <FiltersList
          filters={filteredByPermissionsFilters}
          selectedDropdownItems={selectedFilters}
          tempQuery={tempQuery}
          filtersMap={filtersMap}
          onFilterRemoved={onFilterRemoved}
          onEditabilityChange={onEditabilityChange}
          mandatoryFiltersToShow={mandatoryFiltersToShow}
          listView
          performerVenueFilter={performerVenueFilter}
        />
        <FiltersDropdown
          filters={filteredByPermissionsFilters}
          selectedDropdownItems={selectedFilters}
          onFilterSelected={(filterDropdownItems: FilterDropdownItem[]) => {
            let updatedSelectedFilters: SelectedFilters = {
              ...selectedFilters,
            };

            filterDropdownItems.forEach((filterDropdownItem) => {
              const titleContentId =
                filterDropdownItem.titleContentId as string;
              updatedSelectedFilters = {
                ...updatedSelectedFilters,
                [titleContentId]: [
                  ...(updatedSelectedFilters[titleContentId] ?? []),
                  filterDropdownItem,
                ],
              };
            });

            setSelectedFilters(updatedSelectedFilters);
          }}
        />
      </>
    );
  }

  return (
    <div className={FilterDialogV2Wrapper}>
      <div className={FilterDialogV2Header}>
        <Content id={ContentId.Filters} />
        <Popover.Close asChild>
          <CrossIconContainer>
            <CrossIcon size={vars.iconSize.m} withHoverEffect />
          </CrossIconContainer>
        </Popover.Close>
      </div>
      <div className={FilterDialogV2Body}>
        <FiltersList
          filters={filteredByPermissionsFilters}
          selectedDropdownItems={selectedFilters}
          tempQuery={tempQuery}
          filtersMap={filtersMap}
          onFilterRemoved={onFilterRemoved}
          onEditabilityChange={onEditabilityChange}
          mandatoryFiltersToShow={mandatoryFiltersToShow}
        />
        <FiltersDropdown
          filters={filteredByPermissionsFilters}
          selectedDropdownItems={selectedFilters}
          onFilterSelected={(filterDropdownItems: FilterDropdownItem[]) => {
            let updatedSelectedFilters: SelectedFilters = {
              ...selectedFilters,
            };

            filterDropdownItems.forEach((filterDropdownItem) => {
              const titleContentId =
                filterDropdownItem.titleContentId as string;
              updatedSelectedFilters = {
                ...updatedSelectedFilters,
                [titleContentId]: [
                  ...(updatedSelectedFilters[titleContentId] ?? []),
                  filterDropdownItem,
                ],
              };
            });

            setSelectedFilters(updatedSelectedFilters);
          }}
        />
      </div>
      {hasFiltersApplied && (
        <div className={FilterDialogV2Footer}>{filterAppliedMessage}</div>
      )}
      <Stack
        className={FilterDialogV2Footer}
        justifyContent={
          hasFiltersApplied && onSaveQuickFilter ? 'spaceBetween' : 'end'
        }
      >
        {hasFiltersApplied && onSaveQuickFilter && (
          <Button
            variant="textPlain"
            onClick={() => {
              onSubmitFilter?.();
              onSaveQuickFilter?.();
            }}
            className={saveFiltersButton}
          >
            <Content id={ContentId.SaveFilter} />
          </Button>
        )}
        <Stack gap="m">
          <Button variant="outline" onClick={clearAll}>
            <Content id={ContentId.ClearAll} />
          </Button>
          <Popover.Close asChild>
            <Button variant="regular" onClick={onSubmitFilter}>
              <Content id={ContentId.Apply} />
            </Button>
          </Popover.Close>
        </Stack>
      </Stack>
    </div>
  );
};
