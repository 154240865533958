import {
  QueryObserverResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AdPlatformCatalogClient,
  AdPlatformCatalogResults,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';

export const EmptyAdPlatformCatalogResults: AdPlatformCatalogResults = {
  events: {},
  performers: {},
  venues: {},
  listingsByExternalId: {},
  venueMapByEventId: {},
};

export type IAdPlatformCatalogDataContext = {
  isLoading: boolean;
  isItemsLoading: boolean;
  data?: AdPlatformCatalogResults;
  errorInfo?: { errorHeader: React.ReactNode; errorMessage: React.ReactNode };
  refreshCatalog: () => Promise<QueryObserverResult>;
};

export const AdPlatformCatalogDataContext =
  createContext<IAdPlatformCatalogDataContext>({
    isLoading: false,
    isItemsLoading: false,
    refreshCatalog: () => Promise.resolve({} as QueryObserverResult),
  });

export const useAdPlatformCatalogDataContext = () =>
  useContext(AdPlatformCatalogDataContext);

export const AdPlatformCatalogDataContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [errorInfo, setErrorInfo] = useState<{
    errorHeader: React.ReactNode;
    errorMessage: React.ReactNode;
  }>();

  const { adPlatformCatalogDataQuery, refreshData } =
    useGetAdPlatformCatalogDataQuery(setErrorInfo);

  return (
    <AdPlatformCatalogDataContext.Provider
      value={{
        isLoading: adPlatformCatalogDataQuery.isPending,
        isItemsLoading: adPlatformCatalogDataQuery.isLoading,
        data: adPlatformCatalogDataQuery.data ?? undefined,
        errorInfo,
        refreshCatalog: refreshData,
      }}
    >
      {children}
    </AdPlatformCatalogDataContext.Provider>
  );
};

const useGetAdPlatformCatalogDataQuery = (
  setErrorInfo: (
    value: React.SetStateAction<
      | {
          errorHeader: React.ReactNode;
          errorMessage: React.ReactNode;
        }
      | undefined
    >
  ) => void
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const ADPLATFORMCATALOG_DATA_QUERY_KEY = 'AdPlatformCatalogDataQuery';
  const queryKey = useMemo(
    () => [
      ADPLATFORMCATALOG_DATA_QUERY_KEY,
      activeAccountWebClientConfig.activeAccountId,
    ],
    [activeAccountWebClientConfig.activeAccountId]
  );

  const shouldQuery = activeAccountWebClientConfig.activeAccountId != null;

  const query = useQuery({
    queryKey: queryKey,
    queryFn: async () => {
      setErrorInfo(undefined);

      if (!shouldQuery) {
        return null;
      }

      return tryInvokeApi(
        async () => {
          const client = new AdPlatformCatalogClient(
            activeAccountWebClientConfig
          );
          const data = await client.getCatalogForSeller();
          return data;
        },
        (error) => {
          const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
            error?.status,
            error?.message
          );
          setErrorInfo({
            errorHeader: headerDisplay,
            errorMessage: messageDisplay,
          });
          trackError(ADPLATFORMCATALOG_DATA_QUERY_KEY, error, {});
        }
      );
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  const queryClient = useQueryClient();

  const refreshData = useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });

    return await query.refetch();
  }, [query, queryClient, queryKey]);

  return {
    adPlatformCatalogDataQuery: query,
    adPlatformCatalogData: query.data,
    refreshData: refreshData,
  };
};
