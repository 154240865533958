import clsx from 'clsx';
import { EventItemHeader } from 'src/components/Events/EventItemHeader';
import { Checkbox } from 'src/core/interim/Checkbox';
import { vars } from 'src/core/themes';
import { ChevronRightIcon } from 'src/svgs/Viagogo';
import { getPerformerAndVenueForEvent } from 'src/utils/eventWithDataUtils';
import { CatalogResults, Event, Performer, Venue } from 'src/WebApiController';

import * as styles from './EventSearch.css';
import {
  EventSearchItemDiv,
  EventSearchItemInfoContainer,
} from './EventSearch.styled';

export const EventSearchItem = ({
  event,
  isSelected,
  catalog,
  onChecked,
  isSingleSelect,
}: {
  event: Event;
  isSelected?: boolean;
  catalog: CatalogResults;
  onChecked: (
    event: Event,
    isSelected: boolean,
    performer?: Performer | null,
    venue?: Venue | null
  ) => void;
  isSingleSelect?: boolean;
}) => {
  const { performer, venue } = getPerformerAndVenueForEvent(event, catalog);
  return (
    <div
      className={styles.eventSearchItemContainer}
      onClick={(e) => {
        e.stopPropagation();
        onChecked?.(event, !isSelected, performer, venue);
      }}
    >
      <EventSearchItemDiv
        className={clsx(styles.eventSearchItemDiv, {
          [styles.eventSearchItemDivSelected]: isSelected,
        })}
      >
        {!isSingleSelect && (
          <Checkbox
            checked={Boolean(isSelected)}
            // This is not really needed since we are using the onClick event but it is retained to be consistent with the other checkbox
            onChange={() => {
              onChecked?.(event, !isSelected, performer, venue);
            }}
          />
        )}

        <EventSearchItemInfoContainer>
          <EventItemHeader
            event={event}
            performer={performer}
            venue={venue}
            showMappingState
          />
        </EventSearchItemInfoContainer>
        {isSingleSelect && (
          <div className={styles.chevronIconContainer}>
            <ChevronRightIcon fill={vars.color.textPrimary} />
          </div>
        )}
      </EventSearchItemDiv>
    </div>
  );
};
