import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const InputRowAmount = styled.div`
  flex-wrap: nowrap;
  display: flex;
  column-gap: ${() => vars.spacing.m};
  align-items: center;
  div:nth-child(2) {
    flex-grow: 1;
  }
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};
  width: 100%;
  padding-bottom: ${() => vars.spacing.lg};
`;

export const SwitchContainer = styled.span`
  label {
    width: ${() => vars.spacing.xl};
  }
`;
