import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  getAllInPriceFromListPrice,
  getListPriceFromAllinPrice,
} from 'src/utils/inventoryUtils';
import { Listing, Marketplace } from 'src/WebApiController';

export const useAllInPriceFromListPrice = (
  listPrice: number | null | undefined,
  listing?: Listing | null
) => {
  const { loginContext } = useAppContext();

  return useMemo(() => {
    const stubHubMkpSettings =
      loginContext?.user?.activeAccount?.marketplaceSettings?.find(
        (m) => m.mkp === Marketplace.StubHub
      );
    if (!listPrice) {
      return null;
    }
    return getAllInPriceFromListPrice(
      listPrice,
      listing,
      stubHubMkpSettings?.sellerFee
    );
  }, [
    listPrice,
    listing,
    loginContext?.user?.activeAccount?.marketplaceSettings,
  ]);
};

export const useListPriceFromAllInPrice = (
  allInPrice: number | null | undefined,
  listing?: Listing
) => {
  const { loginContext } = useAppContext();

  return useMemo(() => {
    const stubHubMkpSettings =
      loginContext?.user?.activeAccount?.marketplaceSettings?.find(
        (m) => m.mkp === Marketplace.StubHub
      );
    if (!allInPrice) {
      return null;
    }
    return getListPriceFromAllinPrice(
      allInPrice,
      listing,
      stubHubMkpSettings?.sellerFee
    );
  }, [
    allInPrice,
    listing,
    loginContext?.user?.activeAccount?.marketplaceSettings,
  ]);
};
