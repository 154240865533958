import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const SaleAlertIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 20 21">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.97367 4.28255C5.96091 4.28255 2.71581 7.52766 2.71581 11.5404C2.71581 15.5532 5.99581 18.8332 10.0086 18.8332C14.0213 18.8332 17.3013 15.5532 17.3013 11.5404C17.3013 7.52766 14.0213 4.28255 9.97367 4.28255ZM9.97367 17.4374C6.72857 17.4374 4.07666 14.7855 4.07666 11.5404C4.07666 8.29531 6.72857 5.6434 9.97367 5.6434C13.2188 5.6434 15.8707 8.29531 15.8707 11.5404C15.8707 14.7855 13.2188 17.4374 9.97367 17.4374ZM10.2179 10.7728C9.65963 10.703 9.2758 10.6681 9.2758 10.2842C9.2758 9.97021 9.65963 9.86552 10.0435 9.86552C10.532 9.86552 10.9856 9.97021 11.3694 10.2494L12.172 9.23744C11.7183 8.8885 11.2298 8.67914 10.6715 8.60936V7.87659H9.31069V8.60936C8.40346 8.78382 7.6358 9.30723 7.6358 10.2842C7.6358 11.7149 8.7175 12.0289 9.97367 12.1336C10.6018 12.2034 10.9158 12.3779 10.9158 12.657C10.9158 12.9711 10.532 13.1455 10.0086 13.1455C9.52006 13.1455 8.89197 13.0408 8.33367 12.5174L7.46134 13.5293C8.05453 14.1574 8.61282 14.4017 9.31069 14.5064V15.274H10.6715V14.4715C11.6835 14.297 12.5209 13.7387 12.5209 12.657C12.5209 11.3311 11.3345 10.8774 10.2179 10.7728ZM2.55 4.81303L7.11342 2.1791L7.81115 3.38795L3.24773 6.02189L2.55 4.81303ZM12.186 3.37522L12.8839 2.1665L17.4468 4.80097L16.7489 6.00968L12.186 3.37522Z"
        fill="inherit"
      />
    </SvgWrapper>
  );
};
