import { formatInTimeZone } from 'date-fns-tz';
import { useMemo } from 'react';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { DivWithTitle } from 'src/core/ui/DivWithTitle';
import { useUserGroups } from 'src/hooks/useUserGroups';
import { Tag, TagsValueType } from 'src/WebApiController';

import * as styles from '../TagsFormBody.css';

export const TagValueDisplay = ({
  tag,
  displayMode,
}: {
  tag: Tag;
  displayMode?: boolean;
}) => {
  if (tag.valueType === TagsValueType.Url && displayMode) {
    return <UrlDisplay tag={tag} />;
  }

  if (tag.valueType === TagsValueType.Date) {
    return <DateDisplay tag={tag} />;
  }

  if (tag.valueType === TagsValueType.POSUser) {
    return <POSUserDisplay tag={tag} />;
  }

  if (tag.valueType === TagsValueType.UserGroup) {
    return <UserGroupDisplay tag={tag} />;
  }

  if (tag.valueType === TagsValueType.Vendor) {
    return <VendorDisplay tag={tag} />;
  }

  return (
    <DivWithTitle className={styles.textSecondary}>{tag.value}</DivWithTitle>
  );
};

const POSUserDisplay = ({ tag }: { tag: Tag }) => {
  const { allActiveUserInfos } = useSellerAccountContext();

  const sellerUserId = tag.value;

  const user = useMemo(() => {
    if (!sellerUserId || !allActiveUserInfos) {
      return null;
    }

    return allActiveUserInfos[sellerUserId];
  }, [allActiveUserInfos, sellerUserId]);

  return <div className={styles.textSecondary}>{user?.displayName}</div>;
};

const UserGroupDisplay = ({ tag }: { tag: Tag }) => {
  const { userGroups } = useUserGroups();

  const userGroupId = tag.value;

  const userGroup = useMemo(() => {
    if (!userGroupId?.length || !userGroups?.length) {
      return null;
    }

    return userGroups.find((ug) => ug.userGroupId.toString() === userGroupId);
  }, [userGroupId, userGroups]);

  return <div className={styles.textSecondary}>{userGroup?.userGroupName}</div>;
};

const VendorDisplay = ({ tag }: { tag: Tag }) => {
  const vendorId = tag.value;

  const { availableVendors } = usePurchaseVendorSelector({});
  const vendor = useMemo(() => {
    if (vendorId == null) {
      return null;
    }

    return availableVendors[vendorId];
  }, [availableVendors, vendorId]);

  return <div className={styles.textSecondary}>{vendor?.name}</div>;
};

const DateDisplay = ({ tag }: { tag: Tag }) => {
  const { timeZone } = useSiteTimezoneContext();
  return (
    <div className={styles.textSecondary}>
      {tag?.value
        ? formatInTimeZone(
            new Date(tag.value),
            timeZone,
            "MMMM d, yyyy 'at' h:mm a"
          )
        : ''}
    </div>
  );
};

const UrlDisplay = ({ tag }: { tag: Tag }) => {
  const absoluteUrl = useMemo(() => {
    if (tag.valueType !== TagsValueType.Url || !tag.value) {
      return;
    }
    if (
      tag.value.toLocaleLowerCase().startsWith('http') ||
      tag.value.toLocaleLowerCase().startsWith('https')
    ) {
      return tag.value;
    }

    // Browser will automatically use https if the site supports it
    return `http://${tag.value}`;
  }, [tag.value, tag.valueType]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      title={tag.value}
    >
      <a
        className={styles.textLink}
        href={absoluteUrl}
        // So that the link opens in a new tab
        target="_blank"
        rel="noreferrer"
      >
        {tag.value}
      </a>
    </div>
  );
};
