import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import { MenuItems } from 'src/app/AppRoutes';
import { SellerAccountBasicInfo } from 'src/components/SellerAccountBasicInfo';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { SearchResultPickerDialog } from 'src/dialogs/SearchResultPickerDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useHasReleaseNotesUnread } from 'src/hooks/useHasReleaseNotesUnread';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  DropdownDivider,
  DropdownLabel,
} from 'src/navigations/MainNav/MainNavTopBar/MainNavTopBar.styled';
import { UserInfoDisplay } from 'src/navigations/MainNav/UserInfoDisplay';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { UnreadDot } from 'src/navigations/Routes/ReleaseNotes';
import { ProfileOutlineIcon, SigninIcon, SignoutIcon } from 'src/svgs/Viagogo';
import {
  REACT_APP_MESSAGES_BRIDGE_URL,
  REACT_APP_REPORTS_BRIDGE_URL,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { sortSellerAccount } from 'src/utils/userUtils';
import {
  Feature,
  LoginClient,
  SellerAccount,
  SellerAccountClient,
} from 'src/WebApiController';

import { MenuItem } from '../MenuItem/MenuItem';
import {
  icon,
  menuItem,
  menuText,
  releaseNoteOptionContainer,
} from '../MenuItem/MenuItem.css';

const menuItems = [MainRoute.Settings] as const;

export const UserMenu = ({ toggleClose }: { toggleClose: () => void }) => {
  const { mainRoute } = useAppContext();
  return (
    <div>
      <Dropdown toggleClose={toggleClose} />
      {menuItems.map((item, index) => {
        const { view, path, label, icon } = MenuItems[item];
        return (
          <MenuItem
            key={index}
            isActive={mainRoute === view}
            textContent={label}
            icon={icon}
            path={path}
            onClick={toggleClose}
          />
        );
      })}
    </div>
  );
};

const MyAccountMenuItem = () => {
  const { loginContext } = useAppContext();
  const userDisplayName = loginContext?.user?.activeAccount.accountName;
  return (
    <>
      <div className={menuItem}>
        <div className={icon}>
          <ProfileOutlineIcon />
        </div>
        <span className={menuText}>
          {userDisplayName ?? <Content id={ContentId.MyAccount} />}
        </span>
      </div>
    </>
  );
};

const Dropdown = ({ toggleClose }: { toggleClose: () => void }) => {
  const { loginContext, activeAccountWebClientConfig } = useAppContext();
  const signInText = useContent(ContentId.SignIn);
  const navigate = useNavigate();
  const [sellerAccounts, setSellerAccounts] = useState<SellerAccount[]>([]);
  const [filteredSellerAccounts, setFilteredSellerAccounts] = useState<
    SellerAccount[]
  >([]);
  const [isSellerAccountLoading, setIsSellerAccountLoading] = useState(false);

  const { showErrorDialog } = useErrorBoundaryContext();
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const myAccountText = useContent(ContentId.MyAccount);
  const menuText = loginContext ? myAccountText : signInText;

  const hasAutoPoAccess = useUserHasFeature(Feature.AutoPO);

  const { hasReleaseNotesUnread } = useHasReleaseNotesUnread({});

  const onFilterSellerAccount = useCallback(
    (text: string) => {
      text = text.toLocaleUpperCase();
      const filtered = sellerAccounts.filter(
        (sa) =>
          sa.accountName.toLocaleUpperCase().includes(text) ||
          sa.impersonatedUsername?.toLocaleUpperCase()?.includes(text) ||
          sa.id.toLocaleUpperCase().includes(text)
      );
      setFilteredSellerAccounts(filtered);
    },
    [sellerAccounts]
  );

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  const onSwitchAccountClicked = useCallback(async () => {
    const accounts = await tryInvokeApi(
      async () => {
        setIsSellerAccountLoading(true);
        return await new SellerAccountClient(
          activeAccountWebClientConfig
        ).getCurrentUserSellerAccounts();
      },
      (error) => {
        showErrorDialog(
          'SellerAccountClient.getCurrentUserSellerAccounts',
          error
        );
      },
      () => setIsSellerAccountLoading(false)
    );
    if (accounts) {
      const sorted = sortSellerAccount(accounts);
      setSellerAccounts(sorted);
      setFilteredSellerAccounts(sorted);
      launchDialog();
    }
  }, [activeAccountWebClientConfig, launchDialog, showErrorDialog]);

  const onSellerAccountPicked = useCallback(
    async (account: SellerAccount) => {
      if (account.id === activeAccountWebClientConfig.activeAccountId) {
        return;
      }

      await tryInvokeApi(
        async () => {
          setIsSellerAccountLoading(true);
          await new LoginClient(activeAccountWebClientConfig).setActiveAccount(
            account.id,
            account.impersonatedUserId
          );
          // We need to reload so all the data is reload with the new login context
          window.location.reload();
          closeDialog();
        },
        (error) => {
          showErrorDialog('LoginClient.setActiveAccount', error, {
            trackErrorData: account,
          });
        },
        () => setIsSellerAccountLoading(false)
      );
    },
    [activeAccountWebClientConfig, closeDialog, showErrorDialog]
  );

  return (
    <>
      {loginContext ? (
        <PosDropdown
          trigger={<MyAccountMenuItem />}
          align="start"
          triggerProps={{ style: { width: '100%' } }}
        >
          <>
            <PosDropdownItem disabled style={{ color: vars.color.textPrimary }}>
              {loginContext ? (
                <UserInfoDisplay
                  userInfo={loginContext.user}
                  showUserName={false}
                />
              ) : (
                <DropdownLabel>{menuText}</DropdownLabel>
              )}
            </PosDropdownItem>
            <DropdownDivider />
            {loginContext?.user?.hasManyAccounts && (
              <PosDropdownItem
                onClick={() => {
                  onSwitchAccountClicked();
                  toggleClose();
                }}
              >
                <Content id={ContentId.SwitchAccount} />
              </PosDropdownItem>
            )}
            {!hasAutoPoAccess && (
              <PosDropdownItem
                onClick={() => {
                  openInNewTab(REACT_APP_MESSAGES_BRIDGE_URL!);
                  toggleClose();
                }}
              >
                <Content id={ContentId.Messages} />
              </PosDropdownItem>
            )}
            <PosDropdownItem
              onClick={() => {
                openInNewTab(REACT_APP_REPORTS_BRIDGE_URL!);
                toggleClose();
              }}
            >
              <Content id={ContentId.Payments} />
            </PosDropdownItem>

            <PosDropdownItem
              onClick={() => {
                navigate('/release-notes');
                toggleClose();
              }}
            >
              <div className={releaseNoteOptionContainer}>
                <Content id={ContentId.WhatsNew} />
                {hasReleaseNotesUnread ? <UnreadDot /> : null}
              </div>
            </PosDropdownItem>

            <PosDropdownItem
              onClick={() => {
                navigate('/notifications');
                toggleClose();
              }}
            >
              <Content id={ContentId.Notifications} />
            </PosDropdownItem>

            <DropdownDivider />
            <PosDropdownItem
              onClick={() => {
                navigate('/logout');
                toggleClose();
              }}
            >
              <Stack gap="m" alignItems="center">
                <SignoutIcon size={vars.iconSize.l} />
                <Content id={ContentId.SignOut} />
              </Stack>
            </PosDropdownItem>
          </>
        </PosDropdown>
      ) : (
        <MenuItem
          textContent={<Content id={ContentId.SignIn} />}
          path={'/login'}
          icon={SigninIcon}
          onClick={toggleClose}
        />
      )}
      <SearchResultPickerDialog
        {...dialogProps}
        header={<Content id={ContentId.SelectAccountToSwitch} />}
        resultItems={filteredSellerAccounts ?? []}
        isLoading={isSellerAccountLoading}
        renderItem={(account: SellerAccount) => (
          <SellerAccountBasicInfo
            account={account}
            isCurrent={
              account.id === activeAccountWebClientConfig.activeAccountId
            }
          />
        )}
        onResultItemSearched={onFilterSellerAccount}
        onResultItemPicked={onSellerAccountPicked}
        onCancel={closeDialog}
      />
    </>
  );
};
