import { ComponentProps } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { Button, Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';

import { DuplicateSeatsSelectionDialog } from './DuplicateSeatsSelectionDialog';

export type LaunchDuplicateSeatsSelectionDialogProps = ComponentProps<
  typeof Button
>;

export const LaunchDuplicateSeatsSelectionDialog = ({
  variant = 'link',
  ...props
}: LaunchDuplicateSeatsSelectionDialogProps) => {
  const dialog = useBasicDialog();
  const onLaunch = () => {
    dialog.launchDialog();
  };

  return (
    <Stack gap="m" alignItems="center">
      <Button {...props} variant={variant} onClick={onLaunch}>
        <Content id={ContentId.ViewDuplicates} />
      </Button>
      <DuplicateSeatsSelectionDialog
        {...dialog.dialogProps}
        onClosed={dialog.closeDialog}
        onCancel={dialog.closeDialog}
      />
    </Stack>
  );
};
