import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function LockIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M12 2c3.107 0 5.625 2.462 5.625 5.5v3.25h2.125a1 1 0 011 1V21a1 1 0 01-1 1H4.25a1 1 0 01-1-1v-9.25a1 1 0 011-1h2.125V7.5C6.375 4.462 8.893 2 12 2zm0 2.5c-1.726 0-3.125 1.306-3.125 2.917v3.333h6.25V7.417c0-1.611-1.4-2.917-3.125-2.917z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
