import { FAKE_SOLD_GROUP_ID } from 'src/components/Listings/constants';
import { ListingWithEvent } from 'src/tables/ListingTable/ListingTable.types';
import { sortSeating } from 'src/utils/tableUtils';
import { addMoneySameCurrency } from 'src/utils/ticketMetricUtils';
import {
  ActionOutboxEntityType,
  Listing,
  ListingGroup,
  ListingStatus,
  Marketplace,
} from 'src/WebApiController';

export const createFakeListingGroup = (
  listings: ListingWithEvent[] | undefined,
  groupName: string
): ListingWithEvent | null => {
  const firstListingWithEvent = listings?.[0];
  const listing = firstListingWithEvent?.listing;

  if (!listings || !firstListingWithEvent || !listing) {
    return null;
  }

  const allListings: Listing[] = listings
    .map((l) => l.listing)
    .flat()
    .filter((l): l is Listing => !!l)
    .sort((a, b) => sortSeating(a.seating, b.seating));

  // @ts-ignore some fields not needed
  const listingGroup: ListingGroup = {
    id: Math.abs(listing.id) * -1,
    insertBy: '',
    rowVer: 0,
    ltGrpId: FAKE_SOLD_GROUP_ID,
    ltGrpParentId: null,
    ltGrpPrior: -1,
    ltGrpActive: false,
    poBys: [],
    isLtGrp: true,
    isFull: false,
    entityType: ActionOutboxEntityType.Listing,
    isDeleted: false,
    isUndeliv: false,
    mkp: Marketplace.StubHub,
    idOnMkp: listing.idOnMkp,
    posEvId: listing.posEvId,
    viagVirtualId: listing.viagVirtualId,
    viagEvId: listing.viagEvId,
    viagEvMapId: listing.viagEvMapId,
    availQty: 0,
    maxDispQty: 0,
    mkpListings: [],
    isSeatSaver: false,
    isNoFulfill: false,
    seating: listing.seating,
    splitType: listing.splitType,
    hideSeats: false,
    tags: [],
    seatTraits: [],
    ticketCnt: 0,
    delivType: listing.delivType,
    preDelivState: listing.preDelivState,
    unitCst: null,
    listPrice: listing.listPrice,
    allInPrice: listing.allInPrice,
    actions: [],
    status: ListingStatus.FullySold,
    currency: listing.currency,
    pricerId: '',
    isCurUserPo: listing.isCurUserPo,
    privNotes: '',
    poId: listing.poId,
    poCreatedBys: [],
    groupItems: allListings,
    groupName,
  };

  allListings.forEach((listing) => {
    listingGroup.ticketCnt += listing.ticketCnt;
    listingGroup.availQty += listing.availQty;
    listingGroup.maxDispQty += listing.maxDispQty;
  });

  const currency = allListings[0].unitCst?.currency;
  const allListingsHaveSameCurrency = allListings.every(
    (l) => l.unitCst?.currency === currency
  );

  if (allListingsHaveSameCurrency) {
    listingGroup.unitCst = allListings[0].unitCst;
    listingGroup.faceValue = allListings[0].faceValue;
    for (let i = 1; i < allListings.length; i++) {
      listingGroup.unitCst = addMoneySameCurrency(
        listing.unitCst,
        allListings[i].unitCst
      );

      listingGroup.faceValue = addMoneySameCurrency(
        listing.faceValue,
        allListings[i].faceValue
      );
    }
  }

  return {
    isLtGrp: true,
    event: firstListingWithEvent.event,
    listing: listingGroup,
  };
};
