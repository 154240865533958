import { Fragment } from 'react';
import {
  FilterToolbarGroup,
  FilterToolbarItemId,
} from 'src/components/FilterToolbar';
import {
  EventTagInfoFilter,
  TagInfoFilter,
  useEventTagFilters,
  useTagFilters,
} from 'src/components/MainFilterBar';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType } from 'src/WebApiController';

export type MergedTagFilters = TagInfoFilter & EventTagInfoFilter;

interface UseMergedTagFiltersProps<TFilter extends MergedTagFilters> {
  query: TFilter;
  setQuery: (query: TFilter) => void;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Purchase
    | ActionOutboxEntityType.Sale;
  useFiltersDialogV2: boolean;
  includeEventTags?: boolean;
}

export const useMergedTagFilters = <TFilter extends MergedTagFilters>({
  query,
  setQuery,
  entityType,
  useFiltersDialogV2,
  includeEventTags = true,
}: UseMergedTagFiltersProps<TFilter>): FilterToolbarGroup[] => {
  const tagFilters = useTagFilters(query, setQuery, entityType);
  const eventTagFilters = useEventTagFilters(query, setQuery);

  if (!useFiltersDialogV2) {
    return includeEventTags ? [tagFilters, eventTagFilters] : [tagFilters];
  }

  const tagsFilterGroup: FilterToolbarGroup = {
    titleContentId: ContentId.Tags,
    type: 'row',
    items: [
      // tags
      {
        filterId: 'tagName' as const,
        labelContentId: tagFilters.titleContentId!,
        filterQueryKeys: tagFilters.items
          .map((filterToolbarItem) => filterToolbarItem.filterQueryKeys)
          .flat() as FilterToolbarItemId[],
        filterItem: (
          <Stack gap="m">
            {tagFilters.items.map((filterToolbarItem) => (
              <Fragment key={filterToolbarItem.filterId}>
                {filterToolbarItem.filterItem}
              </Fragment>
            ))}
          </Stack>
        ),
      },
    ],
  };

  if (includeEventTags) {
    tagsFilterGroup.items.push(
      // event tags
      {
        filterId: 'eventTagName' as const,
        labelContentId: eventTagFilters.titleContentId!,
        filterQueryKeys: eventTagFilters.items
          .map((filterToolbarItem) => filterToolbarItem.filterQueryKeys)
          .flat() as FilterToolbarItemId[],
        filterItem: (
          <Stack gap="m">
            {eventTagFilters.items.map((filterToolbarItem) => (
              <Fragment key={filterToolbarItem.filterId}>
                {filterToolbarItem.filterItem}
              </Fragment>
            ))}
          </Stack>
        ),
      }
    );
  }

  return [tagsFilterGroup];
};
