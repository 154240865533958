import { PropsWithChildren, ReactNode } from 'react';
import { Stack } from 'src/core/ui';

import * as styles from './FormPage.css';

export const Section = ({
  children,
  icon,
  title,
}: PropsWithChildren<{ icon: ReactNode; title: ReactNode }>) => {
  return (
    <Stack direction="column" gap="l">
      <Stack gap="m" className={styles.formSectionHeader} alignItems="center">
        {icon}
        {title}
      </Stack>
      {children}
    </Stack>
  );
};
