import { useCallback } from 'react';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { BulkConvertPurchasePaymentDialog } from 'src/dialogs/BulkEdits/BulkConvertPurchasePaymentDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { PURCHASE_BULK_CONVERT_PAYMENTS_KEY } from '../../PurchaseActionDropdown.constants';
import { LaunchBulkConvertPurchasePayments } from './LaunchBulkConvertPurchasePayments';

export const useBulkConvertPurchasePaymentsAction = (
  filterQueryWithEventIds: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const updateKey = groupId
    ? `convert-purchase-payments-event-${groupId}`
    : PURCHASE_BULK_CONVERT_PAYMENTS_KEY;
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const { openDialog: openBulkEditDialog, closeDialog: closeBulkEditDialog } =
    useDialog(
      DialogId.BulkConvertPurchasePayments,
      BulkConvertPurchasePaymentDialog
    );

  const onSubmit = useCallback(
    async (
      fromCurrencyCode: string | null,
      toCurrencyCode: string | null,
      conversionRate: number | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkConvertPurchasePaymentPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkConvertPurchasePaymentPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (
        conversionRate &&
        conversionRate > 0 &&
        toCurrencyCode &&
        fromCurrencyCode
      ) {
        await tryInvokeApi(
          async () => {
            const purchaseClient = new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            );

            const succeeded = await purchaseClient.bulkConvertPurchasePayment(
              preview!.preview,
              updateKey,
              fromCurrencyCode,
              toCurrencyCode,
              conversionRate,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshData(true);
                await refreshExpandedListItems();
              }

              closeBulkEditDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkConvertPurchasePayment', error, {
              trackErrorData: {
                filterQueryWithEventIds,
                fromCurrencyCode,
                toCurrencyCode,
                conversionRate,
                preview,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQueryWithEventIds,
      showErrorDialog,
      updateKey,
      closeBulkEditDialog,
      setActivePosEntity,
      refreshData,
      refreshExpandedListItems,
    ]
  );

  const onChangeClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (groupId) {
        setListItemExpansion(true, [groupId]);
      }
      openBulkEditDialog({
        updateKey,
        onOkay: onSubmit,
        onCancel: closeBulkEditDialog,
        entityType: ActionOutboxEntityType.Purchase,
      });
    },
    [
      closeBulkEditDialog,
      groupId,
      onSubmit,
      openBulkEditDialog,
      setListItemExpansion,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkConvertPurchasePayments
        key="ConvertPurchasePayments"
        onClick={onChangeClick}
        disabled={isLoading}
      />
    ),
  };
};
