import { useCallback, useContext } from 'react';
import { TransferButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { ModalContext } from 'src/contexts/ModalContext';
import { ButtonProps } from 'src/core/ui';
import { TurnOnSaleAutoFulfillDialog } from 'src/dialogs/TurnOnSaleAutoFulfillDialog';
import { ModalProps } from 'src/modals/Modal';
import { TransferTickets } from 'src/modals/TransferTickets';
import { ActionOutboxEntityType, SaleDetails } from 'src/WebApiController';

export const LaunchTransferTickets = ({
  saleId,
  marketplaceSaleId,
  cancelTo,
  eTicketUrlOnly,
  isSaleNoFulfill,
  ...buttonProps
}: {
  saleId: number;
  marketplaceSaleId?: string | null;
  variant?: ButtonProps['variant'];
  cancelTo?: ModalProps;
  iconOnlyMode?: boolean;
  disabled?: boolean;
  eTicketUrlOnly?: boolean;
  isSaleNoFulfill?: boolean | null;
}) => {
  const { setModal } = useContext(ModalContext);
  const { setActivePosEntity } = useActivePosEntityContext<SaleDetails>();

  const {
    openDialog: openTurnOnSaleAutoFulfillDialog,
    closeDialog: closeTurnOnSaleAutoFulfillDialog,
  } = useDialog(DialogId.Confirm, TurnOnSaleAutoFulfillDialog);

  const transferTickets = useCallback(() => {
    setModal({
      children: (
        <TransferTickets cancelTo={cancelTo} eTicketUrlOnly={eTicketUrlOnly} />
      ),
      clickOutsideToClose: true,
      size: 'slide-in',
      deepLinkValue: saleId,
      trackViewEntityType: ActionOutboxEntityType.Sale,
    });
  }, [setModal, cancelTo, eTicketUrlOnly, saleId]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setActivePosEntity(saleId, marketplaceSaleId);
      transferTickets();
    },
    [setActivePosEntity, saleId, marketplaceSaleId, transferTickets]
  );

  const onClickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (isSaleNoFulfill) {
        e?.preventDefault();
        e?.stopPropagation();

        openTurnOnSaleAutoFulfillDialog({
          saleId: saleId,
          onOkay: () => {
            closeTurnOnSaleAutoFulfillDialog();
            onLaunchDialog(e);
          },
          onCancel: () => {
            closeTurnOnSaleAutoFulfillDialog();
            onLaunchDialog(e);
          },
        });
      } else {
        onLaunchDialog(e);
      }
    },
    [
      closeTurnOnSaleAutoFulfillDialog,
      isSaleNoFulfill,
      onLaunchDialog,
      openTurnOnSaleAutoFulfillDialog,
      saleId,
    ]
  );

  return (
    <TransferButton
      {...buttonProps}
      eTicketUrlOnly={eTicketUrlOnly}
      onClick={onClickHandler}
    />
  );
};
