import { useQuery } from '@tanstack/react-query';
import { differenceInCalendarDays } from 'date-fns';
import { useMemo } from 'react';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import {
  MarketplacePaymentClient,
  MarketplacePaymentQuery,
  MonthContainingMarketplacePaymentsResult,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { ErrorTypes, useErrorBoundaryContext } from '../ErrorBoundaryContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import { PAYMENT_MONTHLY_CONTAINER_QUERY_KEY } from './MarketplacePaymentDataContext.constants';

function getDateValue(item: MonthContainingMarketplacePaymentsResult): string {
  return item.firstOfMonth;
}

export const useGetMonthMarketplacePaymentsQuery = (
  selectedIds: string[],
  setSelectedIds: (value: React.SetStateAction<string[]>) => void,
  setErrorInfo: (
    value: React.SetStateAction<
      | {
          errorHeader: React.ReactNode;
          errorMessage: React.ReactNode;
        }
      | undefined
    >
  ) => void,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { filterQuery, isQueryInitialized } =
    useFilterQueryContext<MarketplacePaymentQuery>();
  const { trackError } = useErrorBoundaryContext();

  const queryKey = useMemo(
    () => [
      PAYMENT_MONTHLY_CONTAINER_QUERY_KEY,
      activeAccountWebClientConfig?.activeAccountId,
      filterQuery,
    ],
    [activeAccountWebClientConfig?.activeAccountId, filterQuery]
  );

  const shouldQuery = Boolean(
    filterQuery &&
      activeAccountWebClientConfig?.activeAccountId &&
      isQueryInitialized &&
      !disabled
  );

  const paymentsQuery = useQuery({
    queryKey,
    queryFn: async () => {
      setErrorInfo(undefined);

      if (!shouldQuery) {
        return null;
      }

      const data = await new MarketplacePaymentClient(
        activeAccountWebClientConfig
      ).getMonthsContainerMarketplacePayments(filterQuery);

      const sortedData = data.sort((a, b) => {
        const dateA = new Date(getDateValue(a));
        const dateB = new Date(getDateValue(b));
        return filterQuery.isSortDescending
          ? differenceInCalendarDays(dateB, dateA)
          : differenceInCalendarDays(dateA, dateB);
      });

      let newSelectedIds = selectedIds;
      // Always expanding the first item if none selected and we just got data
      if (!selectedIds.length && sortedData.length) {
        const firstItem = sortedData[0];
        const dateValue = getDateValue(firstItem);
        newSelectedIds = [dateValue];
        setSelectedIds(newSelectedIds);
      }

      return sortedData;
    },

    enabled: shouldQuery,
    staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    meta: {
      onError: (error: ErrorTypes) => {
        const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
          error?.status,
          error?.message
        );
        setErrorInfo({
          errorHeader: headerDisplay,
          errorMessage: messageDisplay,
        });
        trackError(PAYMENT_MONTHLY_CONTAINER_QUERY_KEY, error, filterQuery);
      },
    },
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  return paymentsQuery;
};
