import { ReactNode } from 'react';

import * as styles from './SubfilterGroup.css';
import { SubfilterContainer } from './SubfilterGroup.styled';

export type SubfilterGroupItemProps = {
  name: ReactNode;
  count?: ReactNode;
  isSelected?: boolean;
  onClick?: () => void;
};

export function SubfilterGroupItem({
  name,
  count,
  isSelected,
  onClick,
}: SubfilterGroupItemProps) {
  return (
    <SubfilterContainer isSelected={isSelected} onClick={onClick}>
      <div className={styles.subfilterName}>{name}</div>
      {count && <div className={styles.subfilterCount}>{count}</div>}
      <div className={styles.subfilterSeeAll}>See All &gt;</div>
    </SubfilterContainer>
  );
}

export const SubfilterGroup = Object.assign(
  function SubfilterGroup({ children }: { children?: ReactNode }) {
    return <div className={styles.subfilterGroupContainer}>{children}</div>;
  },
  {
    Item: SubfilterGroupItem,
  }
);
