import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const Metrics = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  line-height: 18px;
  white-space: nowrap;
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('caption1')};
`;

export const MainMetrics = styled.span`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('subtitle1')};
`;

export const BulletPoint = styled.span<{ addBulletPoint?: boolean }>`
  width: ${() => vars.spacing.xxs};
  height: ${() => vars.spacing.xxs};
  margin: ${() => `0 ${vars.spacing.sm}`};
  ${(props) =>
    props.addBulletPoint
      ? `background-color: ${props.theme.colors.borderPrimary}`
      : ''};
  border-radius: ${() => vars.spacing.xxs};
  display: inline-block;
  vertical-align: middle;
`;
