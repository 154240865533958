import { EventIdQueryParam } from 'src/utils/constants/constants';
import { MainFilterQueryParam } from 'src/utils/eventQueryUtils';
import { SectionType } from 'src/utils/types/sectionType';

export const getDefaultReturnUrl = (
  path: string,
  viagVirtualId?: string | null
) => {
  const queryParams = new URLSearchParams(location.search);

  queryParams.delete(EventIdQueryParam);
  queryParams.delete('queryId'); // TODO - this is obsolete and no longer use, but it could still exists

  return `/${path}?${queryParams.toString()}${
    viagVirtualId ? `#${viagVirtualId}` : ''
  }`;
};

export const getSidePanelSwitchToUrl = ({
  sectionType,
  marketplaceEntityIds,
  hasFullPageEventViewEntitySelection,
}: {
  sectionType: SectionType;
  marketplaceEntityIds?: number[];
  hasFullPageEventViewEntitySelection: boolean;
}) => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  searchParams.delete(MainFilterQueryParam);

  let route = '';

  switch (sectionType) {
    case SectionType.PurchaseSideTable:
      route = `/purchases/event`;
      break;
    case SectionType.InventorySideTable:
      route = `/inventory/event`;
      break;
    case SectionType.SalesSideTable:
      route = `/sales/event`;
      break;
    default:
      break;
  }

  if (
    marketplaceEntityIds &&
    marketplaceEntityIds.length > 0 &&
    hasFullPageEventViewEntitySelection
  ) {
    searchParams.set(
      MainFilterQueryParam,
      JSON.stringify({ marketplaceEntityIds: marketplaceEntityIds.map(String) })
    );
  }

  const queryString = searchParams.toString();
  return queryString ? `${route}?${queryString}` : route;
};
