import { DefaultTheme } from 'styled-components/macro';

import { Colors } from './constants/colors';
import { PurpleTheme } from './legacy';
import {
  ThemeColors,
  ThemeMediaQuery,
  ThemeTypography,
} from './legacy/theme-types';

type CustomDefaultTheme = DefaultTheme & {
  name: string;
  rounded: boolean;
  floatingInputLabel: boolean;
  filledInLoading: boolean;
  colors: ThemeColors;
  typography: ThemeTypography;
  spacing: (...multipliers: number[]) => string;
  media: ThemeMediaQuery;
  logoPath: string;
};

const OldTheme = PurpleTheme as CustomDefaultTheme;

export const PurpleLegacyTheme: CustomDefaultTheme = {
  ...OldTheme /* We will slowly remove this as will fill in all the blanks */,
  colors: {
    ...OldTheme.colors,
    borderPrimary: Colors.neutral500,
    borderActive: Colors.indigo500,
    borderDark: Colors.neutral400,
    backgroundSecondary: Colors.neutral600,
    backgroundBrandLight: Colors.indigo700,
  },
};
