import { clsx } from 'clsx';
import { CloseButton } from 'reactstrap';
import { MappedTicketInfo } from 'src/components/UploadArtifacts/UploadETicketsV2';
import { BasePageDocumentProps } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/BasePageDocumentProps';
import { MultipleDraggableDocumentPage } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/MultiplePageDocument/MultipleDraggableDocumentPage';
import * as styles from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/MultiplePageDocument/MultiplePageDocument.css';
import { MultiplePageDocumentPageProps } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/MultiplePageDocument/MultiplePageDocumentPage';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { PaperTicketsIcon } from 'src/svgs/PaperTicketsIcon';

export type MultiplePageDocumentProps = Omit<
  BasePageDocumentProps,
  'page' | 'pageIndex'
> & {
  onRemoveMappedTicket: (ticketInfo: MappedTicketInfo) => void;
  onPageClick: (uniquePageId: string) => void;
  onFileDeleted: () => void;
};

export const MultiplePageDocument = (props: MultiplePageDocumentProps) => {
  const { documentFileUploadInfo, onFileDeleted } = props;
  const { pages, file } = documentFileUploadInfo;
  const fileName = file?.name || '';
  const isMobile = useMatchMedia('mobile');

  return (
    <div
      className={clsx(
        styles.multiplePageDocumentWrapper,
        styles.documentWrapper
      )}
    >
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="spaceBetween"
        gap="m"
      >
        <Stack
          alignItems="center"
          justifyContent="spaceBetween"
          className={styles.documentNameWrapper}
          gap="m"
        >
          <Stack alignItems="center" gap="m">
            {!isMobile && <PaperTicketsIcon />}
            <div className={styles.documentName}>{fileName}</div>
          </Stack>
          <CloseButton
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.stopPropagation();
              e.preventDefault();
              onFileDeleted();
            }}
          />
        </Stack>
        {(pages ?? []).map((page, pageIndex) => {
          const draggableMultiplePageDocumentProps: MultiplePageDocumentPageProps =
            {
              ...props,
              page,
              pageIndex,
            };
          return (
            <MultipleDraggableDocumentPage
              key={page.id}
              {...draggableMultiplePageDocumentProps}
            />
          );
        })}
      </Stack>
    </div>
  );
};
