import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const OperatorsIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <g clipPath="url(#clip0_2978_143493)">
        <path
          d="M11.0013 0.333984C8.8013 0.333984 7.52464 2.78432 6.62464 5.66732H4.33464C4.24623 5.66732 4.16145 5.70244 4.09893 5.76495C4.03642 5.82746 4.0013 5.91225 4.0013 6.00065C4.0013 6.08906 4.03642 6.17384 4.09893 6.23635C4.16145 6.29887 4.24623 6.33398 4.33464 6.33398H6.42497C6.2743 6.85765 6.13364 7.38998 6.0013 7.91998C5.13097 11.4007 4.5743 15.0007 2.33464 15.0007C2.24623 15.0007 2.16145 14.9655 2.09893 14.903C2.03642 14.8405 2.0013 14.7557 2.0013 14.6673C2.0013 14.411 2.33464 14.3007 2.33464 14.0007C2.33464 13.8238 2.2644 13.6543 2.13937 13.5292C2.01435 13.4042 1.84478 13.334 1.66797 13.334C1.40275 13.334 1.1484 13.4393 0.960862 13.6269C0.773326 13.8144 0.667969 14.0688 0.667969 14.334C0.667969 15.0693 1.41564 15.6673 2.33464 15.6673C5.09497 15.6673 6.4013 11.811 7.33464 8.08132C7.48097 7.49632 7.6183 6.90798 7.75664 6.33398H10.0013C10.0897 6.33398 10.1745 6.29887 10.237 6.23635C10.2995 6.17384 10.3346 6.08906 10.3346 6.00065C10.3346 5.91225 10.2995 5.82746 10.237 5.76495C10.1745 5.70244 10.0897 5.66732 10.0013 5.66732H7.91964C8.55764 3.11365 9.28564 1.00065 11.0013 1.00065C11.0897 1.00065 11.1745 1.03577 11.237 1.09828C11.2995 1.16079 11.3346 1.24558 11.3346 1.33398C11.3346 1.59032 11.0013 1.70065 11.0013 2.00065C11.0013 2.17746 11.0715 2.34703 11.1966 2.47206C11.3216 2.59708 11.4912 2.66732 11.668 2.66732C11.9332 2.66732 12.1875 2.56196 12.3751 2.37442C12.5626 2.18689 12.668 1.93253 12.668 1.66732C12.668 0.931984 11.9203 0.333984 11.0013 0.333984Z"
          fill="#3F1D75"
        />
        <path
          d="M15.0033 9.66798C13.8929 9.66798 13.1489 10.6266 12.4869 11.7366C12.1033 10.592 11.5659 9.66798 10.6699 9.66798C10.3279 9.70128 10.0085 9.85386 9.76758 10.099C9.71058 10.1623 9.67998 10.245 9.68205 10.3302C9.68413 10.4153 9.71873 10.4965 9.77875 10.5569C9.83877 10.6173 9.91966 10.6525 10.0048 10.6552C10.0899 10.6579 10.1729 10.6279 10.2366 10.5713C10.3536 10.4502 10.5048 10.3677 10.6699 10.3346C11.2126 10.3346 11.3759 11.6176 11.6699 12.668C11.7006 12.777 11.7329 12.8866 11.7659 12.9963C11.3212 13.7666 10.8543 14.483 10.3056 14.811C10.2702 14.6923 10.2026 14.5859 10.1101 14.5036C10.0177 14.4212 9.9042 14.3662 9.7823 14.3448C9.66039 14.3233 9.53491 14.3362 9.41992 14.382C9.30493 14.4278 9.20496 14.5047 9.13122 14.6042C9.05747 14.7036 9.01287 14.8216 9.00239 14.9449C8.99192 15.0682 9.01599 15.1921 9.07191 15.3025C9.12783 15.4129 9.21339 15.5056 9.31901 15.5702C9.42463 15.6347 9.54614 15.6686 9.66992 15.668C10.7059 15.668 11.4229 14.8326 12.0523 13.8193C12.4606 14.8346 13.0519 15.668 14.0033 15.668C14.3453 15.6347 14.6647 15.4821 14.9056 15.237C14.9626 15.1737 14.9932 15.091 14.9911 15.0058C14.989 14.9206 14.9544 14.8395 14.8944 14.7791C14.8344 14.7186 14.7535 14.6834 14.6684 14.6808C14.5832 14.6781 14.5003 14.7081 14.4366 14.7646C14.3195 14.8857 14.1684 14.9683 14.0033 15.0013C13.2699 15.0013 13.0582 13.8126 12.7466 12.6196C13.2403 11.747 13.7526 10.8926 14.3676 10.525C14.4029 10.6436 14.4706 10.7501 14.563 10.8324C14.6554 10.9147 14.769 10.9697 14.8909 10.9912C15.0128 11.0127 15.1383 10.9998 15.2532 10.954C15.3682 10.9081 15.4682 10.8312 15.542 10.7318C15.6157 10.6324 15.6603 10.5144 15.6708 10.3911C15.6813 10.2677 15.6572 10.1439 15.6013 10.0335C15.5453 9.92303 15.4598 9.83035 15.3542 9.7658C15.2485 9.70124 15.127 9.66737 15.0033 9.66798Z"
          fill="#3F1D75"
        />
      </g>
      <defs>
        <clipPath id="clip0_2978_143493">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
