import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';

import { ListingDetailsModalWrapper } from './ListingDetailsModalFeatureWrapper';

export const ListingDetailsModalConfig = {
  children: <ListingDetailsModalWrapper />,
  clickOutsideToClose: true,
  deepLinkKey: InventoryDeeplinkQueryParam,
  deepLinkValue: undefined,
  size: 'slide-in-full',
};
