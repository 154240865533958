import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function TicketAlertOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M9.078 2l.244.4c.569 1 1.666 1.6 2.803 1.6s2.234-.6 2.803-1.6l.244-.4h5.078v5.88h-1.625V3.6h-2.56a4.886 4.886 0 01-3.209 1.92h-1.462c-1.26-.16-2.438-.88-3.21-1.92H5.625v16.8h13V8.68l1.625.8V22H4V2h5.078zm3.006 5.88l1.382 2.68 3.046.44-2.193 2.12.568 2.96-2.721-1.4-2.722 1.44.487-2.96-2.193-2.12 3.046-.44 1.3-2.72z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
