import { SiteZoomOption } from 'src/contexts/SiteTheme/SiteThemeContext';

import { useMatchMedia } from './useMatchMedia';

export const useDefaultZoomOption = () => {
  const isMobile = useMatchMedia('mobile');
  const isTablet = useMatchMedia('tablet');
  const isDesktop = useMatchMedia('desktop');
  const isLargeDesktop = useMatchMedia('largeDesktop');

  const defaultZoomOption = isMobile
    ? SiteZoomOption['100%']
    : isTablet
    ? SiteZoomOption['65%']
    : isLargeDesktop
    ? SiteZoomOption['100%']
    : isDesktop
    ? SiteZoomOption['75%']
    : SiteZoomOption['100%'];

  return defaultZoomOption;
};
