import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { DATA_REFRESH_RATE_IN_MILLIS_FAST } from 'src/utils/constants/constants';
import {
  AutoPoClient,
  AutoPoInboundEmail,
  AutoPoInboundEmailClassificationType,
} from 'src/WebApiController';

const NUM_TAKE = 500;

export function useClassifiedInboundEmails(
  classificationTypes?: AutoPoInboundEmailClassificationType[],
  isSortDescending?: boolean | null
) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const inboundEmailsClassifiedQuery = useInfiniteQuery({
    queryKey: [
      'AutoPoClient.getClassifiedInboundEmails',
      activeAccountWebClientConfig.activeAccountId,
      classificationTypes,
      isSortDescending,
    ],
    queryFn: async ({ pageParam: skip = 0 }) => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      if (classificationTypes != null && classificationTypes.length === 0) {
        return {
          results: [],
          skip,
          totalCount: 0,
        };
      }

      const results = await new AutoPoClient(
        activeAccountWebClientConfig
      ).getClassifiedInboundEmails(
        classificationTypes ?? [],
        NUM_TAKE,
        skip ?? 0,
        isSortDescending
      );

      const totalCount = await new AutoPoClient(
        activeAccountWebClientConfig
      ).getClassifiedInboundEmailsCount(classificationTypes ?? []);

      if (results.length === 0) {
        return null;
      }
      return {
        results,
        skip,
        totalCount,
      };
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.results.length
        ? lastPage.skip + lastPage.results.length
        : undefined;
    },
    meta: {
      onError(err: ErrorTypes) {
        trackError('AutoPoClient.getClassifiedInboundEmails', err);
      },
    },
    initialPageParam: 0,
    staleTime: Infinity, // we already do refresh interval
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: true,
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_FAST,
  });

  const flattenedData = useMemo(() => {
    const flattenedData: AutoPoInboundEmail[] = [];
    if (inboundEmailsClassifiedQuery.data) {
      for (const page of inboundEmailsClassifiedQuery.data.pages) {
        if (page) {
          for (const email of page?.results ?? []) {
            flattenedData.push(email);
          }
        }
      }
    }
    return flattenedData;
  }, [inboundEmailsClassifiedQuery.data]);

  return {
    ...inboundEmailsClassifiedQuery,
    inboundEmailsClassified: flattenedData,
    inboundEmailsTotalCount:
      inboundEmailsClassifiedQuery.data?.pages[0]?.totalCount,
    isLoading:
      inboundEmailsClassifiedQuery.isLoading ||
      inboundEmailsClassifiedQuery.isFetchingNextPage,
    isError:
      inboundEmailsClassifiedQuery.isError ||
      (inboundEmailsClassifiedQuery.isLoading &&
        inboundEmailsClassifiedQuery.isPaused),
  };
}
