import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { vars } from 'src/core/themes';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { FullPageIcon } from 'src/svgs';
import { getAdCampaignUrl } from 'src/utils/adCampaignUtils';
import { ContentId } from 'src/utils/constants/contentId';

export const AdCampaignActionsCell = ({
  adCampaignId,
}: {
  adCampaignId: string;
}) => {
  const navigate = useNavigate();

  const onViewClick = useCallback(
    (url: string) => {
      setTimeout(() => {
        navigate(url, {
          state: {
            returnUrl: `${location.pathname}${location.search}`,
          },
        });
      }, 10);
    },
    [navigate]
  );

  return (
    <TableCellDiv align="right">
      <Link
        style={{ padding: `${vars.spacing['xxs']} ${vars.spacing['sm']}` }}
        to={getAdCampaignUrl(adCampaignId)}
        onClick={(e) => {
          if (e.button === 0 && e.getModifierState('Control') === false) {
            // Perform like a button click when it's a left-click (and no Ctrl)
            // Else the click will be whatever <a /> does
            e.preventDefault();
            e.stopPropagation();

            onViewClick(getAdCampaignUrl(adCampaignId));
          }
        }}
      >
        <IconButton
          icon={
            <FullPageIcon
              size={vars.iconSize.m}
              fill={vars.color.textBrand}
              withHoverEffect
            />
          }
          titleContentId={ContentId.ViewDetails}
        />
      </Link>
    </TableCellDiv>
  );
};
