import { useCallback, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { PurchaseVendorAccount } from 'src/WebApiController';

export const PurchaseSellerAccountSelector = () => {
  const confirmChangeVendorAccountDialog = useBasicDialog();
  const dialogRef = useRef<typeof confirmChangeVendorAccountDialog>(
    confirmChangeVendorAccountDialog
  );
  dialogRef.current = confirmChangeVendorAccountDialog;
  const { loginContext } = useAppContext();
  const currentUserId = loginContext?.user?.userId!;
  const { watch, setValue, getValues } =
    useFormContext<PurchaseOrderDetailsInput>();

  const buyerUserId = watch('buyerUserId');
  const isNewPurchase = watch('id') <= 0;
  const formPurchaseVendorAccount = watch('vendorAccount');

  const [localPurchaseVendorAccount, setLocalPurchaseVendorAccount] =
    useState<PurchaseVendorAccount | null>(
      formPurchaseVendorAccount?.value ?? null
    );
  const [tempBuyerUserId, setTempBuyerUserId] = useState<string | null>(null);

  /**
   * When the selected vendorAccount changes, set the defaultBuyerUserId
   * if exists or the current userId after confirmation
   */
  useEffect(() => {
    const vendorDefaultBuyerUserId =
      formPurchaseVendorAccount?.value?.dftBuyerId || currentUserId;
    const userBuyerId = getValues('buyerUserId');

    if (localPurchaseVendorAccount === formPurchaseVendorAccount) {
      return;
    }
    setLocalPurchaseVendorAccount(formPurchaseVendorAccount?.value ?? null);

    if (userBuyerId?.value === vendorDefaultBuyerUserId) {
      return;
    }

    if (isNewPurchase) {
      setValue('buyerUserId', posChangedField(vendorDefaultBuyerUserId));
      return;
    }

    setTempBuyerUserId(vendorDefaultBuyerUserId);

    // Don't use confirmChangeVendorAccountDialog in deps because
    // on re-renders, triggers the effect.
    dialogRef.current.launchDialog();
  }, [
    formPurchaseVendorAccount,
    localPurchaseVendorAccount,
    setValue,
    currentUserId,
    getValues,
    isNewPurchase,
  ]);

  const onConfirmChangeVendorAccount = useCallback(() => {
    setValue('buyerUserId', posChangedField(tempBuyerUserId));
    confirmChangeVendorAccountDialog.closeDialog();
    setTempBuyerUserId(null);
  }, [setValue, confirmChangeVendorAccountDialog, tempBuyerUserId]);

  const onCancelChangeVendorAccount = useCallback(() => {
    confirmChangeVendorAccountDialog.closeDialog();
    setTempBuyerUserId(null);
  }, [confirmChangeVendorAccountDialog]);

  return (
    <>
      <SellerAccountEmployeeSelector
        value={buyerUserId?.value ?? undefined}
        onChange={(newId) => {
          if (newId && newId !== buyerUserId?.value) {
            setValue('buyerUserId', posChangedField(newId));
          }
        }}
        style={{ width: '100%' }}
        sortBy="key"
      />
      {confirmChangeVendorAccountDialog.dialogProps.isOpen && (
        <ConfirmDialog
          {...confirmChangeVendorAccountDialog.dialogProps}
          headerText={<Content id={ContentId.ChangeVendorAccount} />}
          bodyText={<Content id={ContentId.ConfirmVendorAccountChange} />}
          onOkay={onConfirmChangeVendorAccount}
          onCancel={onCancelChangeVendorAccount}
          okText={ContentId.AssignToDefaultPurchaser}
          cancelText={ContentId.KeepCurrentPurchaser}
          size={'m'}
        />
      )}
    </>
  );
};
