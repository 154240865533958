import { useState } from 'react';

const useCopyToClipboard = () => {
  const [referenceIsCopied, setReferenceIsCopied] = useState('');

  function copyToClipboard(
    text: string,
    referenceId?: string
  ): Promise<void | '' | undefined> {
    return navigator.clipboard
      .writeText(text)
      .then(() => referenceId && setReferenceIsCopied(referenceId))
      .catch((err) => {
        console.error('Failed to copy: ', err);
        setReferenceIsCopied('');
      });
  }

  function resetReferenceIsCopiedState() {
    setReferenceIsCopied('');
  }

  return { referenceIsCopied, copyToClipboard, resetReferenceIsCopiedState };
};

export default useCopyToClipboard;
