import {
  DocumentPage,
  DocumentUploadInfo,
  Ticket,
  UserDocument,
  UserDocumentLinks,
} from 'src/WebApiController';

export type ETicketsForm = {
  ticketAssignments: UserDocumentLinks[];
};

export type DocumentFileUploadInfo = {
  file?: File;
  uploadInfo?: DocumentUploadInfo;
  pages?: DocumentPage[];
};

export const getDocumentPageUniqueId = (ep: DocumentPage) =>
  `${ep.id}-${ep.documentId}`;

export const getETicketDocumentPageUniqueId = (ep: UserDocument) =>
  `${ep.pageId}-${ep.documentId}`;

export type UniquePageIdToDocumentFileUploadInfoMap = Record<
  string,
  { documentFileUploadInfo: DocumentFileUploadInfo; page: DocumentPage }
>;

export const getPageToDocumentFileUploadInfoMap = (
  docUploadInfos: DocumentFileUploadInfo[]
): UniquePageIdToDocumentFileUploadInfoMap => {
  const documentFileUploadInfosMap: UniquePageIdToDocumentFileUploadInfoMap =
    {};

  docUploadInfos.forEach((doc) => {
    doc.pages
      ?.filter((page) => !!page)
      .forEach((page) => {
        documentFileUploadInfosMap[getDocumentPageUniqueId(page)] = {
          documentFileUploadInfo: doc,
          page,
        };
      });
  });

  return documentFileUploadInfosMap;
};

export type MappedTicketInfo = { ticketIndex: number; ticket: Ticket };
export type PageUniqueIdToTicketMap = Record<string, MappedTicketInfo>;

export const getDocumentPageIdToTicketMap = (
  ticketAssignments: UserDocument[],
  tickets: Ticket[]
): PageUniqueIdToTicketMap =>
  ticketAssignments.reduce(
    (prev, cur) => {
      const index = tickets.findIndex((t) => t.id === cur.entityId);
      prev[getETicketDocumentPageUniqueId(cur)] = {
        ticketIndex: index,
        ticket: tickets[index],
      };
      return prev;
    },
    {} as {
      [docPageUniqueId: string]: { ticketIndex: number; ticket: Ticket };
    }
  );
