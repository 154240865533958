import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { Stack, Switch } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { posChangedField, posField } from 'src/utils/posFieldUtils';
import { ListingDetailsAutoPricingSectionUpdatesV2 } from 'src/WebApiController';

import { AutoPricingSettingsInput } from '../../BulkEditAutoPricingSettingsDialog.types';
import { useAutoPricingSettings } from '../../useBulkAutoPricingSettings';
import { PricingSetting } from '../PricingSetting';
import { SettingsSubheader } from '../PricingSubheader';
import { FloorAdjustmentInput } from './FloorAdjustmentInput';
import * as styles from './PricingProtection.css';

type PricingProtectionProps = {
  origin: Pick<
    AutoPricingSettingsInput,
    'netProceedsFloor' | 'netProceedsCeiling'
  >;
};

export const PricingProtection: React.FC<PricingProtectionProps> = ({
  origin,
}) => {
  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();

  const uiCurrency = getUiCurrency(
    loginContext?.user?.activeAccount?.currencyCode
  );

  const { setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();

  const [isAbsolute, setIsAbsolute] = useState<boolean>(false);

  const {
    netProceedsCeiling,
    netProceedsFloor,
    netProceedsFloorAdjustmentAmount,
    netProceedsFloorAdjustmentRelative,
    netProceedsFloorAdjustmentType,
    onUpdateNetProceedsFloorAdjustment,
  } = useAutoPricingSettings();

  const onPriceFloorChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = parseFloat(e.target.value);
      if (v >= 0 && v < Number.MAX_VALUE) {
        setValue('netProceedsFloor', posChangedField(v));
      } else {
        setValue('netProceedsFloor', null);
      }
    },
    [setValue]
  );

  const onPriceCeilingChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = parseFloat(e.target.value);
      if (v >= 0 && v <= Number.MAX_VALUE) {
        setValue('netProceedsCeiling', posChangedField(v));
      } else {
        setValue('netProceedsCeiling', null);
      }
    },
    [setValue]
  );

  const floorOrCeilingHasChange = useMemo(
    () =>
      origin.netProceedsCeiling?.value !== netProceedsCeiling?.value ||
      origin.netProceedsFloor?.value !== netProceedsFloor?.value,
    [
      netProceedsCeiling?.value,
      netProceedsFloor?.value,
      origin.netProceedsCeiling?.value,
      origin.netProceedsFloor?.value,
    ]
  );

  const onResetPricingProtection = useCallback(() => {
    setValue('netProceedsCeiling', posField(origin.netProceedsCeiling?.value));
    setValue('netProceedsFloor', posField(origin.netProceedsFloor?.value));
  }, [
    origin.netProceedsCeiling?.value,
    origin.netProceedsFloor?.value,
    setValue,
  ]);

  const onRelativeSwitch = useCallback(
    (checked: boolean) => {
      setIsAbsolute(checked);
      onResetPricingProtection();
    },
    [onResetPricingProtection]
  );

  return (
    <>
      <SettingsSubheader title={ContentId.PricingProtection} />
      {!isAbsolute ? (
        <PricingSetting
          header={<Content id={ContentId.AdjustProceedsFloor} />}
          detail={
            <Stack gap="l" alignItems="center">
              <FloorAdjustmentInput
                amount={netProceedsFloorAdjustmentAmount ?? 0}
                isRelative={netProceedsFloorAdjustmentRelative}
                onChange={(amount, isRelative) => {
                  onUpdateNetProceedsFloorAdjustment({
                    adjustmentAmount: amount,
                    isRelative,
                  });
                }}
              />

              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.RelativeTo} />
              </div>

              <PosEnumSelect
                style={{ width: '300px', height: '40px' }}
                searchable={true}
                value={netProceedsFloorAdjustmentType}
                valueOptionsContent={PRICING_FLOOR_CEIL_ADJUSTMENT_TYPE_TO_CID}
                onChange={(newValue) => {
                  if (
                    newValue != null &&
                    newValue != netProceedsFloorAdjustmentType
                  ) {
                    onUpdateNetProceedsFloorAdjustment({
                      adjustmentType: newValue,
                    });
                  }
                }}
              />
            </Stack>
          }
          hasChange={
            !!netProceedsFloorAdjustmentAmount &&
            netProceedsFloorAdjustmentRelative != null &&
            !!netProceedsFloorAdjustmentType
          }
          onReset={() => onUpdateNetProceedsFloorAdjustment({})}
        />
      ) : (
        <PricingSetting
          header={<Content id={ContentId.DontPriceBelow} />}
          detail={
            <Stack gap="l" alignItems="center">
              <PosFormField style={{ width: 'fit-content' }}>
                <PosCurrencyField
                  placeholder={undefined}
                  uiCurrency={uiCurrency}
                  value={netProceedsFloor?.value ?? ''}
                  onChange={onPriceFloorChange}
                />
              </PosFormField>
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.OrAbove} />
              </div>
              <PosFormField style={{ width: 'fit-content' }}>
                <PosCurrencyField
                  placeholder={undefined}
                  uiCurrency={uiCurrency}
                  value={netProceedsCeiling?.value ?? ''}
                  onChange={onPriceCeilingChange}
                />
              </PosFormField>
            </Stack>
          }
          hasChange={floorOrCeilingHasChange}
          hasConflict={
            origin.netProceedsFloor?.hasConflict ||
            origin.netProceedsCeiling?.hasConflict
          }
          onReset={onResetPricingProtection}
        />
      )}
      <PricingSetting
        header={<Content id={ContentId.UseAbsoluteFloors} />}
        detail={
          <Stack width="fit">
            <Switch
              checked={isAbsolute}
              onCheckedChange={onRelativeSwitch}
              onChange={(event) => event.stopPropagation()}
            />
          </Stack>
        }
      />
    </>
  );
};
