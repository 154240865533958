import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Button } from 'src/core/ui';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ContentId } from 'src/utils/constants/contentId';
import { ApiException, LoginClient, LoginContext } from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as styles from './Login.css';

export function Logout() {
  return (
    <LayoutContent mainRoute={MainRoute.Logout}>
      <LogoutPage />
    </LayoutContent>
  );
}

function LogoutPage() {
  const navigate = useNavigate();
  const { showErrorDialog } = useErrorBoundaryContext();

  const handleLoginContextForLogout = async (loginContext: LoginContext) => {
    if (loginContext?.user) {
      const loginContext = await new LoginClient({}).logout();

      if ((loginContext?.authorizationUrl?.length || 0) > 0) {
        const authUrl = new URL(loginContext.authorizationUrl!);

        window.location.assign(
          `${authUrl.protocol}//${authUrl.host}/logout?returnUrl=${window.location.href}`
        );
      }
    }
  };

  const onLoggingOut = useCallback(async () => {
    try {
      const existingContext = await new LoginClient({}).getLoginContext(
        '/logout'
      );
      handleLoginContextForLogout(existingContext);
    } catch (error) {
      // When getting a 401 response, we go this route
      try {
        const defaultLoginContext = JSON.parse(
          (error as ApiException)?.response
        ) as LoginContext;
        handleLoginContextForLogout(defaultLoginContext);
      } catch (jsonError) {
        showErrorDialog('LoginClient.logout', error as ErrorTypes, {
          trackErrorData: { jsonError },
        });
      }
    }
  }, [showErrorDialog]);

  useEffect(() => {
    onLoggingOut();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.loginContainer}>
      <Button variant={'regular'} onClick={() => navigate('/login')}>
        <Content id={ContentId.SignInAgain} />
      </Button>
    </div>
  );
}
