import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useCallback, useMemo } from 'react';
import { EventAccordionItemProps } from 'src/components/Accordions';
import { ActionOutboxEntityTypeForBulkEdit } from 'src/components/common/BulkActions/BulkEditStatus';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { MultiSelectionToggleGroup } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGroup';
import { SummaryTile } from 'src/components/common/SummaryTile';
import {
  EventNameDisplay,
  VenueNameDisplay,
} from 'src/components/Events/EventInfo';
import { EventStatus } from 'src/components/Events/EventStatus';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, isContentId } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ScrollableToolbar } from 'src/core/ui/ScrollableToolbar';
import { MarketplaceEventEditDialog } from 'src/dialogs/MarketplaceEventEditDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EditIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { MAPPING_REJECTION_REASON_ID_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  compareMarketplace,
  getFormattedEventName,
  getIsInternationalEvent,
} from 'src/utils/eventWithDataUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Event,
  EventConfigClient,
  Feature,
  Marketplace,
  Performer,
  Permission,
  SellerEventMarketplaceEventInfo,
  TopLevelCategory,
} from 'src/WebApiController';

import { PerformerCategoryIcon } from '../../common/PerformerCategoryIcon';
import * as styles from './EventPage.css';

const visibleMarketPlaces: Readonly<Marketplace[]> = [
  Marketplace.StubHub,
  Marketplace.VividSeats,
  Marketplace.Ticketmaster,
  Marketplace.SeatGeek,
];

interface EventPageHeaderBaseProps {
  event: Event;
  eventAccordionItem?: EventAccordionItemProps;
  entityType: ActionOutboxEntityTypeForBulkEdit;
  eventMetrics?: (
    | {
        name: JSX.Element;
        value: string;
      }
    | {
        name: JSX.Element;
        value: number | undefined;
      }
  )[];
  extraActions?: React.ReactNode;
  isCollapsedView?: boolean;
  CollapsedView?: React.ReactNode;
}

export const EventPageHeaderBase: React.FC<EventPageHeaderBaseProps> = ({
  event,
  eventAccordionItem,
  entityType,
  eventMetrics,
  extraActions,
  isCollapsedView,
  CollapsedView,
}) => {
  const locale = getLocaleFromLanguageOrCurrent();
  const hasEventsEditPermission = useUserHasAnyOfPermissions(
    Permission.Events_Edit
  );
  const hasEventStatusDisplay = useUserHasFeature(Feature.EventStatusDisplay);
  const { loginContext, activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  const { venue, performer } = useGetEventFullInfo(event);
  const isInternationalEvent = getIsInternationalEvent(venue?.country?.code);
  const eventDate = event?.dates.start
    ? new Date(event.dates.start)
    : undefined;
  const eventMappingRejectionReason = event?.mapRejRsnId
    ? MAPPING_REJECTION_REASON_ID_TO_CID[event.mapRejRsnId] ??
      event?.mapRejDftTxt
    : undefined;
  const [eventName, eventNameConnector, eventSubName] = getFormattedEventName({
    event,
    performer,
  });
  const shouldQuery =
    activeAccountWebClientConfig?.activeAccountId != null &&
    event.viagVirtualId != null;

  const marketplaceEventInfoQuery = useQuery({
    queryKey: [
      'EventConfigClient.getSellerEventMarketplaceEventInfo',
      activeAccountWebClientConfig.activeAccountId,
      event?.viagVirtualId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      return tryInvokeApi(
        async () => {
          const result = await new EventConfigClient(
            activeAccountWebClientConfig
          ).getSellerEventMarketplaceEventInfo(event.viagId, event.mappingId);
          if (result) {
            return result.reduce(
              (r, m) => {
                r[m.marketplace] = m;
                return r;
              },
              {} as Record<string, SellerEventMarketplaceEventInfo>
            );
          }
          return {};
        },
        (error) => {
          trackError(
            'EventConfigClient.getSellerEventMarketplaceEventInfo',
            error,
            {
              viagogoEntityId: event.viagId,
              viagogoMappingId: event.mappingId,
            }
          );
        }
      );
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
  });

  const { launchDialog, closeDialog, dialogProps } = useBasicDialog();

  const onEventIdsEditClick = useCallback(() => {
    launchDialog();
  }, [launchDialog]);

  const mkpEventInfos = useMemo(
    () =>
      loginContext?.user?.activeAccount.marketplaceSettings
        .filter((m) => m.mkp !== Marketplace.Automatiq)
        .map((m) => {
          const minfo = marketplaceEventInfoQuery.data?.[m.mkp];
          return (
            minfo ??
            ({
              marketplace: m.mkp,
              viagogoEventId: event.viagId,
              mappedEventId:
                m.mkp === Marketplace.StubHub ? event.viagId : undefined,
            } as SellerEventMarketplaceEventInfo)
          );
        }),
    [
      event.viagId,
      loginContext?.user?.activeAccount.marketplaceSettings,
      marketplaceEventInfoQuery.data,
    ]
  );

  const marketPlaceLogos = useMemo(() => {
    return mkpEventInfos
      ?.filter((marketplace) =>
        visibleMarketPlaces.includes(marketplace.marketplace)
      )
      .sort((a, b) => compareMarketplace(a.marketplace, b.marketplace))
      .map((minfo) => {
        const hasEventId =
          minfo.marketplace == Marketplace.StubHub ||
          Boolean(minfo?.eventIdOverride || minfo?.mappedEventId) ||
          (Marketplace.Ticketmaster && minfo.websiteEventId);
        if (minfo.marketplace !== Marketplace.StubHub && isInternationalEvent) {
          return null;
        }
        const onMkpClick =
          hasEventId || !hasEventsEditPermission
            ? undefined
            : onEventIdsEditClick;
        return (
          <MarketplaceLogo
            key={minfo.marketplace}
            marketplace={minfo.marketplace}
            event={event}
            isViagogo={false}
            grayScaleColor={!hasEventId}
            marketplaceEventInfo={minfo}
            onClick={onMkpClick}
          />
        );
      });
  }, [
    event,
    hasEventsEditPermission,
    isInternationalEvent,
    mkpEventInfos,
    onEventIdsEditClick,
  ]);

  const marketplaceEventEditDialog = (
    <MarketplaceEventEditDialog
      {...dialogProps}
      event={event}
      mkpEventInfos={mkpEventInfos}
      onClosed={() => {
        marketplaceEventInfoQuery.refetch();
        closeDialog();
      }}
    />
  );

  // Conditionally render based on isCollapsedView
  if (isCollapsedView) {
    // Use a condensed header component or adjust rendering as needed
    return CollapsedView;
  }

  // Existing detailed header rendering
  return (
    <div className={styles.tilesSection}>
      <SummaryTile.Root>
        {eventDate && <SummaryTile.DateDisplay date={eventDate} />}
        <SummaryTile.Body>
          <SummaryTile.Title>
            <EventNameDisplay
              eventName={eventName}
              eventNameConnector={eventNameConnector}
              eventSubName={eventSubName}
              boldSubName={event?.genre === TopLevelCategory.Sports}
              useBigStyle={true}
            />
            {venue && (
              <div className={styles.eventVenue}>
                <VenueNameDisplay
                  venueName={venue.name}
                  venueLocation={venue.descr}
                />
              </div>
            )}
            <div className={styles.eventMetadata}>
              {eventDate && (
                <div className={styles.eventTime}>
                  {format(eventDate, 'h:mm aaa', { locale })}
                </div>
              )}{' '}
              {performer && (
                <SummaryTile.Description>
                  {(performer as Performer).categ && (
                    <>
                      <PerformerCategoryIcon
                        performer={performer as Performer}
                      />
                      &nbsp;
                    </>
                  )}
                  {performer?.descr}
                </SummaryTile.Description>
              )}
            </div>
            {eventMappingRejectionReason && (
              <div className={styles.eventMetadata}>
                <WarningMessage
                  style={{ alignItems: 'flex-start' }}
                  message={
                    <Stack direction="column" gap="s">
                      <span>
                        <Content id={ContentId.RejectionReasonInfoText1} />{' '}
                        {isContentId(eventMappingRejectionReason) ? (
                          <Content id={eventMappingRejectionReason} />
                        ) : (
                          eventMappingRejectionReason
                        )}
                      </span>
                      <span>
                        <Content id={ContentId.RejectionReasonInfoText2} />
                      </span>
                    </Stack>
                  }
                />
              </div>
            )}
          </SummaryTile.Title>
          <Stack
            direction="column"
            style={{ flex: 1, overflow: 'hidden', minWidth: '250px' }}
          >
            <SummaryTile.MetricGroup style={{ columnGap: vars.spacing['lg'] }}>
              {eventAccordionItem && (
                <Stack alignItems="end" gap="l">
                  <BulkEditStatusPopover entityType={entityType} />
                  {
                    <MultiSelectionToggleGroup
                      groupId={eventAccordionItem.event.viagVirtualId}
                      enableHotkey={true}
                    />
                  }
                  {extraActions}
                </Stack>
              )}
              <Stack>
                {hasEventStatusDisplay && event.eventStatus && (
                  <div className={styles.eventStatus}>
                    <EventStatus status={event.eventStatus} />
                  </div>
                )}
                {eventMetrics?.map((metric, index) => (
                  <SummaryTile.Metric key={index} name={metric.name}>
                    {metric.value}
                  </SummaryTile.Metric>
                ))}
              </Stack>
            </SummaryTile.MetricGroup>
            <Stack
              direction="row"
              gap="m"
              justifyContent="end"
              style={{ flex: 1 }}
              alignItems="center"
            >
              <ScrollableToolbar>
                <Stack
                  direction="row"
                  gap="l"
                  justifyContent="end"
                  style={{ flex: 1 }}
                >
                  {marketPlaceLogos}
                </Stack>
              </ScrollableToolbar>
              <div style={{ display: 'flex', flexShrink: 0 }}>
                {hasEventsEditPermission && (
                  <EditIcon
                    withHoverEffect
                    fill={IconsFill.textBrand}
                    onClick={onEventIdsEditClick}
                  />
                )}
              </div>
            </Stack>
          </Stack>
        </SummaryTile.Body>
      </SummaryTile.Root>
      {marketplaceEventEditDialog}
    </div>
  );
};
