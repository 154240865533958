import {
  Control,
  Controller,
  FieldPath,
  useFormContext,
} from "react-hook-form";
import { FormatContent } from "src/contexts/ContentContext";
import { FormatContentId } from "src/utils/constants/formatContentId";
import { AccountAutoPricingSettingsInputs, CompListingQuantityFilters } from "src/WebApiController";
import { AutoPricingCompQuantitiesSelector } from "../AutoPricingCompQuantitiesSelector/AutoPricingCompQuantitiesSelector";

import * as styles from '../AutoPricing.css';

const exactMatchPosListingQuantities = [...Array(9).fill(0).map((x, i) => i + 1)];
const greaterThanOrEqualToPosListingQuantity = 10;

export const AutoPricingDefaultCompQuantitiesInput = ({
  disabled,
}: {
  disabled: boolean,
}) => {
  const {
    control,
  } = useFormContext<AccountAutoPricingSettingsInputs>();

  return (
    <div className={styles.enumeratedPriorityInputGrid}>
      {exactMatchPosListingQuantities.map(
        (posListingQuantity, index) => {
          return (
            <AutoPricingDefaultCompQuantitiesPackSelector
              key={posListingQuantity}
              control={control}
              name={`defaultCompListingQuantityFilters.exactMatchQuantityFilters.${index}`}
              posListingQuantity={posListingQuantity}
              packLabelText={posListingQuantity.toString()}
              disabled={disabled}
            />
          )
        }
      )}

      <AutoPricingDefaultCompQuantitiesPackSelector
        key={greaterThanOrEqualToPosListingQuantity}
        control={control}
        name={`defaultCompListingQuantityFilters.greaterThanOrEqualToQuantityFilter`}
        posListingQuantity={greaterThanOrEqualToPosListingQuantity}
        packLabelText={`${greaterThanOrEqualToPosListingQuantity}+`}
        disabled={disabled}
      />
    </div>
  );
};

const AutoPricingDefaultCompQuantitiesPackSelector = ({
  control,
  name,
  posListingQuantity,
  packLabelText,
  disabled,
}: {
  control: Control<AccountAutoPricingSettingsInputs, any>,
  name: FieldPath<AccountAutoPricingSettingsInputs>,
  posListingQuantity: number,
  packLabelText: string,
  disabled: boolean,
}) => {
  return (
    <>
      <div className={styles.inlinePricingInputText}>
        <FormatContent id={FormatContentId.PacksOf} params={packLabelText} />
      </div>
      <div>
        <Controller
          control={control}
          name={name}
          render={({
            field: { value, onChange, ...field }
          }) => {
            return (
              <AutoPricingCompQuantitiesSelector
                selectedQuantities={(value as CompListingQuantityFilters)?.compQuantityFilters}
                onSelectedQuantitiesChange={(quantitiesNew) => {
                  onChange({
                    posListingQuantity: posListingQuantity,
                    compQuantityFilters: quantitiesNew,
                  } as CompListingQuantityFilters)
                }}
                disabled={disabled}
                {...field}
              />
            );
          }}
        />
      </div>
    </>
  );
};