import { max } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Modal as RSModal } from 'reactstrap';
import { RowRelativeScoreInfo } from 'src/components/Events/RowRelativeScoreDisplay';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { getRowRepresentingSectionMedianScore } from 'src/utils/seatScoreUtils';
import { compareByNumericPrefix } from 'src/utils/tableUtils';
import { SectionInfo, SectionScoreOverride } from 'src/WebApiController';

import { rowScoreOverrideFilter } from './ChangeRowScoreBodyMultiSection';
import { ChangeRowScoreBodySingleSection } from './ChangeRowScoreBodySingleSection';
import * as styles from './ChangeRowScoreDialog.css';

export type ChangeRowScoreDialogProps = ComponentProps<typeof RSModal> & {
  selectedSection: SectionInfo | null;
  configPayload: string | null;
  scoreOverrides?: SectionScoreOverride[] | null;
  onOkay: (
    newScoreOverrides: SectionScoreOverride[],
    newConfigPayload: string | null
  ) => void;
  onCancel: () => void;
};

export const findScoreOverrideByRowId = (
  scoreOverrides: SectionScoreOverride[] | null | undefined,
  rowId: number | undefined
): SectionScoreOverride | undefined => {
  return scoreOverrides?.find((s) => s.rowId === rowId);
};

const sortRowData = (rowDataArr: RowRelativeScoreInfo[]) => {
  const ordinalAvailable = rowDataArr.every((r) => r.ordinal != null);
  const nameAvailable = rowDataArr.every((r) => r.rowName != null);
  if (ordinalAvailable) {
    rowDataArr.sort((a, b) => (a.ordinal ?? 0) - (b.ordinal ?? 0));
  } else if (nameAvailable) {
    rowDataArr.sort((a, b) => compareByNumericPrefix(a.rowName, b.rowName));
  } else {
    rowDataArr.sort((a, b) => (a.rowId ?? 0) - (b.rowId ?? 0));
  }
  return rowDataArr;
};

export function ChangeRowScoreDialog({
  selectedSection,
  configPayload,
  scoreOverrides,
  onOkay,
  onCancel,
  ...rest
}: ChangeRowScoreDialogProps) {
  const [newConfigPayload, setNewConfigPayload] = useState(configPayload);
  const [rowDataToEdit, setRowDataToEdit] = useState<RowRelativeScoreInfo[]>(
    []
  );

  const sectionScoreOverride = useMemo(
    () =>
      scoreOverrides?.filter(
        ({ sectionId }) => sectionId === selectedSection?.id
      ),
    [scoreOverrides, selectedSection?.id]
  );

  const rowToScore = useMemo(
    () =>
      (scoreOverrides ?? [])
        .filter((s) => s.sectionId === selectedSection?.id)
        .reduce(
          (map, override) => {
            map[override.rowId] = override.score;
            return map;
          },
          {} as Record<number, number | null>
        ),
    [scoreOverrides, selectedSection]
  );

  const rows = useMemo(
    () =>
      sectionScoreOverride?.filter(
        rowScoreOverrideFilter([selectedSection?.specRow?.id ?? 0])
      ) ?? [],
    [sectionScoreOverride, selectedSection?.specRow?.id]
  );

  useEffect(() => {
    setNewConfigPayload(configPayload);
  }, [configPayload]);

  useEffect(() => {
    if (!selectedSection?.id) {
      setRowDataToEdit([]);
      return;
    }

    // Initialize the row items
    const medianRowId = getRowRepresentingSectionMedianScore(
      selectedSection.id,
      scoreOverrides ?? []
    )?.rowId;
    const baseRowScore = medianRowId ? rowToScore[medianRowId] : 0;

    const rowScores = rows.map((row) => {
      const isBaseRow = row.rowId === medianRowId;
      const existingRowScore = rowToScore[row.rowId];
      const percentage =
        existingRowScore && baseRowScore && baseRowScore !== 1
          ? (existingRowScore / baseRowScore - 1) * 100
          : 0;

      return {
        rowId: row.rowId,
        rowName: row.rowName,
        ordinal: row.rowOrdinal,
        isBaseRow,
        percentage,
      } as RowRelativeScoreInfo;
    });
    sortRowData(rowScores);

    const rowData = [];
    for (let i = 0; i < rowScores.length; i++) {
      rowData.push(rowScores[i]);
    }
    setRowDataToEdit(rowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSection?.id, JSON.stringify(rows), JSON.stringify(rowToScore)]);

  const onSubmit = useCallback(() => {
    const newScoreOverrides = [...(scoreOverrides ?? [])];
    const newSectionScoreOverrides = newScoreOverrides.filter(
      (s) => s.sectionId === selectedSection?.id
    );

    const baseRow = rowDataToEdit.find((r) => r.isBase);
    if (!baseRow?.ordinal || !baseRow.rowId) {
      onOkay(newScoreOverrides, newConfigPayload);
      return;
    }

    const { rowId: baseRowId, ordinal: baseOrdinal } = baseRow;
    const baseRowScore = rowToScore[baseRowId];

    // all work based off this, so if this doesn't exist, nothing to do
    if (baseRowScore == null) {
      onOkay(newScoreOverrides, newConfigPayload);
      return;
    }

    const ordinals = (sectionScoreOverride || [])
      .filter(({ rowOrdinal }) => rowOrdinal && rowOrdinal <= baseOrdinal)
      .map(({ rowOrdinal }) => rowOrdinal);
    // Default to the max ordinal
    const sanitizedOrdinal = ordinals.includes(baseOrdinal)
      ? baseOrdinal
      : max(ordinals);
    const baseRowPercentage =
      rowDataToEdit.find(({ ordinal }) => ordinal === sanitizedOrdinal)
        ?.percentage ?? 0;

    for (let i = 0; i < rowDataToEdit.length; i++) {
      const rowId = rowDataToEdit[i].rowId;
      const rowScoreOverrideToEdit = newSectionScoreOverrides.find(
        (s) => s.rowId === rowId
      );
      if (
        rowScoreOverrideToEdit &&
        !rowDataToEdit[i].isBase &&
        rowDataToEdit[i]?.percentage != null
      ) {
        rowScoreOverrideToEdit.score =
          ((100 + (rowDataToEdit[i]?.percentage ?? 0)) /
            (100.0 + baseRowPercentage)) *
          baseRowScore;
      }
    }

    // We need to ensure all the speculative rows also set with the score (i'll be the base row score)
    newSectionScoreOverrides
      .filter((r) => r.rowId === selectedSection?.specRow?.id)
      .forEach((r) => (r.score = baseRowScore));

    onOkay(newScoreOverrides, newConfigPayload);
  }, [
    newConfigPayload,
    onOkay,
    rowDataToEdit,
    rowToScore,
    scoreOverrides,
    sectionScoreOverride,
    selectedSection?.id,
    selectedSection?.specRow?.id,
  ]);

  if (!selectedSection) {
    return null;
  }

  return (
    <GenericDialog
      {...rest}
      size="m"
      header={<Content id={ContentId.RowScore} />}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          onSubmit();
        }
      }}
      footer={
        <>
          <Button className={styles.cancelButton} onClick={onCancel}>
            <Content id={ContentId.Cancel} />
          </Button>
          <Button onClick={onSubmit}>
            <Content id={ContentId.Save} />
          </Button>
        </>
      }
      onCancel={onCancel}
    >
      <div className={styles.ChangeRowScoreDialogContainer}>
        <div className={styles.topRowContainer}>
          <div className={styles.selectedSectionContainer}>
            <Content id={ContentId.Section} />
            <div className={styles.selectedSectionNameContainer}>
              {selectedSection.name}
            </div>
          </div>
        </div>
        <div className={styles.rowScoreContainer}>
          <ChangeRowScoreBodySingleSection
            sectionId={selectedSection.id}
            rowDataToEdit={rowDataToEdit}
            setRowDataToEdit={setRowDataToEdit}
            configPayload={newConfigPayload}
            setConfigPayload={setNewConfigPayload}
          />
        </div>
      </div>
    </GenericDialog>
  );
}
