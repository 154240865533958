import { Fragment, useMemo } from 'react';
import { LinkAndCopy } from 'src/components/common/LinkAndCopy';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { Content } from 'src/contexts/ContentContext';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { vars } from 'src/core/themes';
import { Tooltip } from 'src/core/ui';
import { UseGetUserInfo, useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { Detail, DetailGroup, SectionContent } from 'src/modals/common';
import { ListingLinksDetail } from 'src/modals/SaleDetails/components/ListingLinksDetail';
import {
  purchaseColumn,
  purchasedByColumn,
} from 'src/modals/SaleDetailsV2/components/SeatsSoldAs/SeatSoldAss.css';
import { useGetModalGridFormat } from 'src/modals/SaleDetailsV2/hooks/useGetModalGridFormat';
import { GenericDisclosuresIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { SaleDetails } from 'src/WebApiController';
import * as styles from './TicketsAllocated.css';

interface SeatsSoldAsProps {
  sale: SaleDetails;
}

const NUM_OF_COLUMNS = 8;

export const TicketsAllocated = ({ sale }: SeatsSoldAsProps) => {
  const { ticketGroupInfos } = sale;
  const { SectionContentGridFormat } = useGetModalGridFormat();
  const buyerUserIds = useMemo<string[]>(
    () =>
      ticketGroupInfos
        .filter((tkgInfo) => !!tkgInfo.buyerUserId)
        .map((tkgInfo) => tkgInfo.buyerUserId) as string[],
    [ticketGroupInfos]
  );

  const usersInfoQuery = useGetUserInfos(buyerUserIds);

  const usersMap: Record<string, UseGetUserInfo> = usersInfoQuery.data || {};

  if (!sale.originatedFromListingId) {
    return null;
  }

  return (
    <SectionContent format={SectionContentGridFormat}>
      {ticketGroupInfos.map((tkInfo, index) => {
        const { seating } = tkInfo;
        const isFirstRow = index === 0;

        return (
          <Fragment key={tkInfo.ticketGroupId}>
            {index === 0 && <ListingLinksDetail listingId={sale.listingId} />}

            <DetailGroup>
              <Detail
                isStrong
                label={isFirstRow ? <Content id={ContentId.Seating} /> : ''}
                detail={
                  <SeatingInfo
                    section={seating.section ?? ''}
                    row={seating.row ?? ''}
                    fontSize={vars.typography.fontSize.base}
                    seatFr={seating.seatFr}
                    seatTo={seating.seatTo}
                  />
                }
              />
            </DetailGroup>

            <DetailGroup>
              <Detail
                isStrong
                label={
                  isFirstRow ? (
                    <Content id={ContentId.QuantityAbbreviated} />
                  ) : (
                    ''
                  )
                }
                detail={<strong>x {tkInfo.ticketCount}</strong>}
              />
            </DetailGroup>

            <DetailGroup>
              <Detail
                isStrong
                label={
                  isFirstRow ? <Content id={ContentId.VendorAccount} /> : ''
                }
                detail={tkInfo.vendorAccount?.encryptedEmailAddress}
              />
            </DetailGroup>

            <DetailGroup>
              <Detail
                isStrong
                label={isFirstRow ? <Content id={ContentId.Notes} /> : ''}
                detail={
                  tkInfo.internalNotes ? (
                    <Tooltip
                      triggerContent={
                        <div>
                          <GenericDisclosuresIcon
                            fill={vars.color.textStrong}
                          />
                        </div>
                      }
                    >
                      <div className={styles.notesTooltip}>
                        <div className={styles.notesTooltipHeader}>
                          <Content id={ContentId.Notes} />
                        </div>
                        <div className={styles.notesTooltipContent}>
                          {tkInfo.internalNotes}
                        </div>
                      </div>
                    </Tooltip>
                  ) : undefined
                }
              />
            </DetailGroup>

            <DetailGroup className={purchaseColumn}>
              <Detail
                label={isFirstRow ? <Content id={ContentId.Purchase} /> : ''}
                detail={
                  <LinkAndCopy
                    relativeUrl={getPurchaseOrderRelativeUrl(
                      tkInfo.purchaseOrderId
                    )}
                    linkChildren={tkInfo.vendorOrderId}
                    valueToCopy={tkInfo.vendorOrderId ?? tkInfo.purchaseOrderId}
                  />
                }
              />
            </DetailGroup>

            <DetailGroup className={purchasedByColumn}>
              <Detail
                isStrong
                label={isFirstRow ? <Content id={ContentId.PurchasedBy} /> : ''}
                detail={
                  (tkInfo.buyerUserId &&
                    usersMap?.[tkInfo.buyerUserId]?.name) || (
                    <ContentPlaceholder
                      width={`(100/${NUM_OF_COLUMNS})px`}
                      height={'28px'}
                    />
                  )
                }
              />
            </DetailGroup>
          </Fragment>
        );
      })}
    </SectionContent>
  );
};
