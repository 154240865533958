import styled from 'styled-components/macro';

export const MainLabel = styled.div`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title5')};
  display: flex;
  justify-content: space-between;
`;
