import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { Stack } from 'src/core/ui';
import { Detail } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { SaleInput } from 'src/WebApiController';

export const PaymentCurrencyConversionSection = () => {
  const { watch, setValue } = useFormContext<SaleInput>();

  const paymentAmountReceived = watch('paymentAmountReceived');
  const paymentCurrency = watch('paymentCurrency');

  const { currencyCache, getUiCurrency } = useLocalizationContext();

  const availableCurrenciesOptions = useMemo(
    () =>
      Object.values(currencyCache || []).reduce(
        (result, cur) => {
          result[cur.code] = cur.code;
          return result;
        },
        {} as Record<string, string>
      ),
    [currencyCache]
  );

  const { loginContext } = useAppContext();

  const uiCurrency = useMemo(
    () =>
      getUiCurrency(loginContext?.user?.activeAccount?.currencyCode ?? 'USD'),
    [getUiCurrency, loginContext?.user?.activeAccount?.currencyCode]
  );

  return (
    <Stack direction="column" gap="l">
      <WarningMessage
        message={<Content id={ContentId.SalePaymentConversionWarning} />}
      />
      <Stack direction="row" gap="l">
        <Detail
          label={<Content id={ContentId.PaymentCurrency} />}
          style={{ width: '50%' }}
          detail={
            <PosSelect
              value={paymentCurrency?.value ?? undefined}
              placeholderText={ContentId.DontChange}
              searchable
              valueOptionsContent={availableCurrenciesOptions}
              onChange={(value) => {
                setValue('paymentCurrency', posChangedField(value), {
                  shouldDirty: true,
                });
              }}
            />
          }
        />
        <Detail
          label={<Content id={ContentId.PaymentAmountReceived} />}
          style={{ width: '50%' }}
          detail={
            <PosCurrencyField
              value={paymentAmountReceived?.value ?? ''}
              uiCurrency={getUiCurrency(paymentCurrency?.value)}
              onChange={(e) => {
                const newValue = parseFloat(e.target.value) || 0;
                setValue('paymentAmountReceived', posChangedField(newValue), {
                  shouldDirty: true,
                });

                setValue(
                  'paymentCurrency',
                  posChangedField(paymentCurrency?.value ?? uiCurrency.code),
                  {
                    shouldDirty: true,
                  }
                );
              }}
            />
          }
        />
      </Stack>
    </Stack>
  );
};
