import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import { Viewer } from '@react-pdf-viewer/core';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { SelectionMode } from '@react-pdf-viewer/selection-mode';
import type {
  ToolbarSlot,
  TransformToolbarSlot,
} from '@react-pdf-viewer/toolbar';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import clsx from 'clsx';
import {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { GlassMagnifier } from 'react-image-magnifiers';
import { IconButton } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DownloadIcon } from 'src/svgs/Viagogo';
import {
  ActionOutboxEntityType,
  ActivityLogClient,
  DocumentType,
  Feature,
} from 'src/WebApiController';
import { ThemeContext } from 'styled-components';

import * as styles from './ImageMagnifier.css';
import { viewerWrapper } from './ImageMagnifier.css';

export type ImageMagnifierProps = {
  trackingEntityType?: ActionOutboxEntityType;
  trackingEntityId?: number | null;
  trackingDocumentId?: string;
  trackingDocumentType?: DocumentType;
  pdfViewerClassnames?: string;
  toolbarSlot?: Partial<ToolbarSlot>;
} & ComponentProps<typeof GlassMagnifier>;

/**
 * Documentation of all props: https://github.com/AdamRisberg/react-image-magnifiers
 * Simple wrapper to provide some defaults for PoS usage.
 * `imageSrc` is most likely the only prop that will need to be provided.
 *
 * Future:
 * - Handle mobile detection
 *  - set different values (X and Y offset, mag size, etc)
 *  - or diff experience leveraging `Magnifier` or to open in a new window
 */
export const ImageMagnifier = ({
  magnifierSize = '20%',
  magnifierBorderColor,
  allowOverflow = true,
  imageSrc,
  trackingEntityType,
  trackingEntityId,
  trackingDocumentId,
  trackingDocumentType,
  pdfViewerClassnames,
  toolbarSlot,
  ...rest
}: ImageMagnifierProps) => {
  const isMobile = useMatchMedia('mobile');
  const theme = useContext(ThemeContext);
  const { isDarkMode } = useSiteTheme();
  const [displayAsEmbed, setDisplayAsEmbed] = useState(false);
  const { activeAccountWebClientConfig } = useAppContext();

  const hasPdfViewerToolbarFeature = useUserHasFeature(
    Feature.PdfViewerToolbar
  );

  const getFilePluginInstance = getFilePlugin();
  const { Download } = getFilePluginInstance;

  const toolbarPluginInstance = toolbarPlugin({
    selectionModePlugin: {
      selectionMode: SelectionMode.Hand,
    },
  });
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const shouldTrackDocumentEntityAction = useMemo(() => {
    return (
      trackingEntityType === ActionOutboxEntityType.Listing &&
      trackingEntityId &&
      trackingDocumentId &&
      trackingDocumentType
    );
  }, [
    trackingDocumentId,
    trackingDocumentType,
    trackingEntityId,
    trackingEntityType,
  ]);

  useEffect(() => {
    if (shouldTrackDocumentEntityAction && trackingEntityId) {
      new ActivityLogClient(
        activeAccountWebClientConfig
      ).trackListingDocumentView(
        trackingEntityId,
        trackingDocumentId,
        trackingDocumentType
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTrackDocumentEntityAction]);

  const trackDownload = useCallback(() => {
    if (shouldTrackDocumentEntityAction && trackingEntityId) {
      new ActivityLogClient(
        activeAccountWebClientConfig
      ).trackListingDocumentDownload(
        trackingEntityId,
        trackingDocumentId,
        trackingDocumentType
      );
    }
  }, [
    activeAccountWebClientConfig,
    shouldTrackDocumentEntityAction,
    trackingDocumentId,
    trackingDocumentType,
    trackingEntityId,
  ]);

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    // These slots will be empty
    Download: () => (
      <Download>
        {(props: RenderDownloadProps) => (
          <IconButton
            icon={<DownloadIcon />}
            onClick={() => {
              trackDownload();
              props.onClick();
            }}
          />
        )}
      </Download>
    ),
    Open: () => <></>,
    Print: () => <></>,
    SwitchTheme: () => <></>,
    EnterFullScreen: () => <></>,
    ShowPropertiesMenuItem: () => <></>, // Important to hide this as it will show the file name
    ShowProperties: () => <></>, // Important to hide this as it will show the file name
    ...toolbarSlot,
  });

  return (
    <>
      {/* due to some ticket URL is pure PDF (not images extracted from pdf) - the ImageMagnifier won't work but we have no way of knowing
  therefore we use an img to test the waters, but actually not displaying it, if we get an error, then use embed istead */}
      <img
        style={{ display: 'none' }}
        alt=""
        src={imageSrc}
        onError={() => {
          setDisplayAsEmbed(true);
        }}
      />
      {!displayAsEmbed ? (
        <GlassMagnifier
          allowOverflow={allowOverflow}
          magnifierSize={magnifierSize}
          magnifierBorderColor={
            magnifierBorderColor || theme.colors.borderActive
          }
          imageSrc={imageSrc}
          {...rest}
        />
      ) : (
        <div
          className={clsx(
            styles.pdfViewer,
            {
              [styles.pdfViewerMobile]: isMobile,
            },
            pdfViewerClassnames
          )}
        >
          <div
            className={clsx(
              hasPdfViewerToolbarFeature
                ? styles.pdfToolbar
                : styles.pdfToolbarSingleItem,
              {
                // Reference: https://react-pdf-viewer.dev/examples/use-the-default-button-to-switch-themes/
                ['rpv-core__viewer--dark']: isDarkMode,
                ['rpv-core__viewer--light']: !isDarkMode,
              }
            )}
          >
            {hasPdfViewerToolbarFeature ? (
              <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
            ) : (
              <Download>
                {(props: RenderDownloadProps) => (
                  <IconButton
                    icon={<DownloadIcon />}
                    onClick={() => {
                      trackDownload();
                      props.onClick();
                    }}
                  />
                )}
              </Download>
            )}
          </div>
          <div className={viewerWrapper}>
            <Viewer
              fileUrl={imageSrc}
              plugins={[getFilePluginInstance, toolbarPluginInstance]}
            />
          </div>
        </div>
      )}
    </>
  );
};
