import { isEmpty } from 'lodash-es';
import { useContext, useMemo } from 'react';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { Stack } from 'src/core/ui';
import * as Tabs from 'src/core/ui/Tabs';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import * as styles from '../EventPage.css';

export enum SidePanelTab {
  LISTINGS = 'listings',
  SALES = 'sales',
  NOTES = 'notes',
  PURCHASES = 'purchases',
  INVENTORY = 'inventory',
}

const HeaderTabs = ({
  tabs,
}: {
  tabs: (
    | {
        label: JSX.Element;
        value: SidePanelTab;
        content: JSX.Element;
      }
    | {
        label: JSX.Element;
        value: SidePanelTab;
        content?: undefined;
      }
  )[];
}) => {
  return (
    <Tabs.List>
      {tabs.map((tab) => (
        <Tabs.Trigger key={tab.value} value={tab.value}>
          {tab.label}
        </Tabs.Trigger>
      ))}
    </Tabs.List>
  );
};

type SidePanelHeaderProps = {
  tabs: (
    | {
        label: JSX.Element;
        value: SidePanelTab;
        content: JSX.Element;
      }
    | {
        label: JSX.Element;
        value: SidePanelTab;
        content?: undefined;
      }
  )[];
};

export const SidePanelHeader: React.FC<SidePanelHeaderProps> = ({ tabs }) => {
  const { setModal } = useContext(ModalContext);

  const {
    event,
    venueMapQuery,
    isGeneralAdmissionOnly,
    mapConfigOverridesQuery,
    venueMapsByScoreModelQuery,
  } = useEventMapContext();
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  const isConfigurable = useMemo(() => {
    // Has score overrides
    if (mapConfigOverridesQuery.data?.length) {
      return true;
    }

    // Venue map has configured scores
    if (
      (venueMapQuery.data?.sectionScores?.findIndex((s) => s.score) ?? -1) >= 0
    ) {
      return true;
    }

    // Has modeled scores
    const { AdvancedVenueGeometry, MergedVenueGeometry, VenueGeometry } =
      venueMapsByScoreModelQuery.data ?? {};
    if (
      !isEmpty(AdvancedVenueGeometry) ||
      !isEmpty(MergedVenueGeometry) ||
      !isEmpty(VenueGeometry)
    ) {
      return true;
    }

    return false;
  }, [
    mapConfigOverridesQuery.data?.length,
    venueMapQuery.data?.sectionScores,
    venueMapsByScoreModelQuery.data,
  ]);

  return (
    <Stack direction="column" gap="l">
      <div className={styles.pricingModelHeader}>
        <HeaderTabs tabs={tabs} />
      </div>
    </Stack>
  );
};
