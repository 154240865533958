import { ReactNode } from 'react';
import Hotkeys from 'react-hot-keys';

interface PosHotkeysProps {
  children: ReactNode;
  keyNames: string; // comma-separated hotkeys
  preventDefaultBrowserActions?: boolean;
  onHotkeyActivated: (keyName: string, event: KeyboardEvent) => void;
  disabled?: boolean;
}

export const PosHotkeys = ({
  children,
  keyNames,
  onHotkeyActivated,
  preventDefaultBrowserActions = true,
  disabled = false,
}: PosHotkeysProps) => {
  return (
    <Hotkeys
      keyName={keyNames}
      disabled={disabled}
      onKeyDown={(keyName, event) => {
        if (preventDefaultBrowserActions) {
          // Don't call event.stopPropagation(); because sometimes,
          // events are not caught when repeating events
          event.preventDefault();
        }
      }}
      onKeyUp={(keyName, event) => {
        if (preventDefaultBrowserActions) {
          // Don't call event.stopPropagation(); because sometimes,
          // events are not caught when repeating events
          event.preventDefault();
        }
        onHotkeyActivated(keyName, event);
      }}
    >
      {children}
    </Hotkeys>
  );
};
