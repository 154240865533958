import { useFormContext } from 'react-hook-form';
import { TagsFormBodyV2 } from 'src/components/TagsFormBody';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserCanUpdateSaleTags } from 'src/hooks/useUserHasSalePermissions';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  ActionOutboxEntityType,
  Permission,
  Sale,
  SaleInput,
} from 'src/WebApiController';

export const TagsSection = ({
  sale,
  disabled,
}: {
  sale?: Sale | null;
  disabled?: boolean;
}) => {
  const { setValue, watch } = useFormContext<SaleInput>();
  const tags = watch('tags');

  const canCreateTage = useUserHasAnyOfPermissions(Permission.Sales_AddTagType);
  const canEditTags = useUserCanUpdateSaleTags(sale);

  return (
    <TagsFormBodyV2
      canEditTags={canEditTags}
      canCreateTagType={canCreateTage}
      tagsCurrentEntity={tags?.value ?? []}
      onTagsUpdate={(tags) => {
        setValue('tags', posChangedField(tags), { shouldDirty: true });
      }}
      disabled={disabled}
      entityType={ActionOutboxEntityType.Sale}
    />
  );
};
