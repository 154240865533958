import clsx from 'clsx';
import { MouseEvent } from 'react';
import { useToggle } from 'react-use';
import { MinRowsForUsingVirtuoso } from 'src/components/Accordions';
import {
  multiSelectCollapsed,
  multiselectEventPageDetails,
} from 'src/components/Accordions/InventoryEventsAccordion/InventoryEventsAccordion.css';
import { InventoryEventsBody } from 'src/components/Accordions/InventoryEventsAccordion/InventoryEventsBody';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { InventoryEventBulkActions } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/InventoryEventBulkActions';
import { ActiveSortTableColumnProvider } from 'src/contexts/ActiveSortTableColumnContext';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs/LayoutIcon';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { EventWithData } from 'src/WebApiController';

import {
  EventAccordionItemProps,
  EventsAccordion,
  VirtualizedListingAccordionProps,
} from '../EventsAccordion';
import { EventListingHeader } from './EventListingHeader';

export const translateEventToInventoryAccordionItem = (
  props: EventWithData,
  index: number,
  isInventoryEventDetailsPage = false
) => {
  return {
    index,
    itemId: props.event.viagVirtualId,
    HeaderComponent: EventListingHeader,
    BodyComponent: InventoryEventsBody,
    AfterHeaderComponent: () => {
      const { isCollapsedView, isEnabled } = useCollapsableViewContext();
      return (
        <MultiSelectActionBar
          groupId={props.event.viagVirtualId}
          classNames={clsx({
            [multiSelectCollapsed]: isCollapsedView,
            [multiselectEventPageDetails]: isEnabled,
          })}
        >
          <InventoryEventBulkActions
            eventWithData={props}
            listingCount={props.listCnt}
            isEventPage={isInventoryEventDetailsPage}
          />
        </MultiSelectActionBar>
      );
    },
    usingVirtuoso:
      isInventoryEventDetailsPage || props.listCnt > MinRowsForUsingVirtuoso,
    ...props,
  } as EventAccordionItemProps;
};

export const InventoryEventsAccordion = (
  props: VirtualizedListingAccordionProps
) => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  return (
    <ActiveSortTableColumnProvider>
      {isColumnModalOpen ? (
        <ColumnSettingsModal<CustomListingColumn>
          sectionType={SectionType.Listings}
          onClose={toggleColumnModal}
        />
      ) : null}
      <EventsAccordion
        {...props}
        tableActions={
          <>
            <Button
              variant="textPlain"
              onClick={onColumnSettingButtonClickHandler}
            >
              <LayoutIcon size={vars.iconSize.m} />
              <Content id={ContentId.Columns} />
            </Button>
          </>
        }
      />
    </ActiveSortTableColumnProvider>
  );
};
