import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > * {
    flex: 1;
  }
`;

export const SectionContent = styled.div<{
  numOfColumns?: number;
  format?: string;
}>`
  display: grid;
  align-items: start;
  grid-template-columns: ${({ format, numOfColumns }) =>
    format ? format : `repeat(${numOfColumns ?? 4}, auto)`};
  row-gap: ${() => vars.spacing.lg};
  column-gap: ${() => vars.spacing.m};
  & + & {
    margin-top: ${() => vars.spacing.lg};
  }
`;

export const DetailGroup = styled.div<{ isDouble?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${() => vars.spacing.xl};

  grid-column: ${({ isDouble }) => (isDouble ? 'span 2' : '')};

  & > div:last-child {
    padding-bottom: 0;
  }
`;

export const AttrLabel = styled.span`
  font-weight: 400;
  font-size: ${() => vars.typography.fontSize['xs']};
  line-height: 16px;
  color: ${(props) => props.theme.colors.textPrimary};
  text-transform: capitalize;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      font-size: ${() => vars.typography.fontSize['sm']};
      line-height: 20px;
    }`
    )}`}
`;

export const DetailText = styled.div`
  color: ${(props) => props.theme.colors.textDarker};
  ${(props) => props.theme.typography.getStyles('body2', 'regular')}
`;
