import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonWithIcon } from 'src/components/Buttons';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { PosSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import {
  AutoPricingSettingsCollectionInput,
  AutoPricingStrategyDialog,
  AutoPricingStrategyDialogProps,
} from 'src/dialogs/AutoPricingStrategyDialog';
import { useAutoPricingStrategies } from 'src/hooks/api/useAutoPricingStrategies';
import { EditIcon, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  AutoPricingSettingsCollection,
  ListingDetailsAutoPricingSectionUpdatesV2,
} from 'src/WebApiController';

import { useAutoPricingSettings } from '../useBulkAutoPricingSettings';
import { PricingSetting } from './PricingSetting';

export const AutoPricingStrategySetting = () => {
  const {
    openDialog: openAutoPricingStrategyDialog,
    closeDialog: closeAutoPricingStrategyDialog,
  } = useDialog<AutoPricingStrategyDialogProps>(
    DialogId.AutoPricingStrategy,
    AutoPricingStrategyDialog
  );
  const { setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();
  const { autoPricingStrategiesQuery } = useAutoPricingStrategies();

  const [selectedAutoPricingStrategyId, setSelectedAutoPricingStrategyId] =
    useState<number | null>(null);

  const createStrategyText = useContent(ContentId.CreatePricingStrategy);

  const keyOptionsContent = useMemo(() => {
    return (
      autoPricingStrategiesQuery.data?.reduce(
        (re, r) => {
          re[r.autoPricingSettingsCollectionId?.toString() ?? ''] =
            r.strategyName;
          return re;
        },
        {} as Record<string, string>
      ) ?? {}
    );
  }, [autoPricingStrategiesQuery.data]);

  useEffect(() => {
    const autoPricingStrategy = autoPricingStrategiesQuery.data?.find(
      (r) => r.autoPricingSettingsCollectionId === selectedAutoPricingStrategyId
    );

    if (selectedAutoPricingStrategyId) {
      if (!autoPricingStrategy) {
        // On strategy delete, reset the form
        setSelectedAutoPricingStrategyId(null);
      } else {
        // on strategy change, apply the settings to the form
        applyAutoPricingStrategy(autoPricingStrategy);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPricingStrategiesQuery.data]);

  const {
    autoPricingEnabled,

    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,

    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingQuantityScoreAdjustmentEnabled,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingExcludeFanInventory,
    compListingExcludeDefects,

    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,

    outlierMode,
    outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing,

    onUndercutChange,
    onCompListingChange,
    onCircuitBreakerChange,
    onOutlierChange,
  } = useAutoPricingSettings();

  const applyAutoPricingStrategy = useCallback(
    (autoPricingStrategy: AutoPricingSettingsCollection) => {
      // Apply the settings to the form
      setValue('autoPricingEnabled', posChangedField(true));

      onUndercutChange(
        autoPricingStrategy.undercutMode,
        autoPricingStrategy.undercutAbsoluteAmount,
        autoPricingStrategy.undercutRelativeAmount
      );
      onCompListingChange({
        compListingModeNew: autoPricingStrategy.compListingMode,
        compListingFloorNew: autoPricingStrategy.compListingFloor,
        compListingCeilingNew: autoPricingStrategy.compListingCeiling,
        compListingOnlyForSameZoneEnabledNew:
          autoPricingStrategy.compListingOnlyForSameZoneEnabled,
        compListingOnlyForSelectedSectionsEnabledNew:
          autoPricingStrategy.compListingOnlyForSelectedSectionsEnabled,
        compListingQuantityScoreAdjustmentEnabledNew:
          autoPricingStrategy.compListingQuantityScoreAdjustmentEnabled,
        compListingExcludeFanInventoryNew:
          autoPricingStrategy.compListingExcludeFanInventory,
        compListingExcludeDefectsNew:
          autoPricingStrategy.compListingExcludeDefects,
      });
      onCircuitBreakerChange(
        autoPricingStrategy.circuitBreakerRelativeCeiling,
        autoPricingStrategy.circuitBreakerRelativeFloor,
        autoPricingStrategy.circuitBreakerMaxDiscountVelocityPercent,
        autoPricingStrategy.circuitBreakerMaxDiscountVelocityTicksInHours,
        autoPricingStrategy.circuitBreakerMinCompListingCount
      );
      onOutlierChange({
        outlierModeNew: autoPricingStrategy.outlierMode,
        outlierStandardDeviationsNew:
          autoPricingStrategy.outlierStandardDeviations,
        outlierKthLowestLimitNew: autoPricingStrategy.outlierKthLowestLimit,
        outlierKthLowestLimitRelativeSpacingNew:
          autoPricingStrategy.outlierKthLowestLimitRelativeSpacing,
        outlierKthLowestLimitAbsoluteSpacingNew:
          autoPricingStrategy.outlierKthLowestLimitAbsoluteSpacing,
      });

      setSelectedAutoPricingStrategyId(
        autoPricingStrategy.autoPricingSettingsCollectionId
      );
    },
    [
      onCircuitBreakerChange,
      onCompListingChange,
      onOutlierChange,
      onUndercutChange,
      setValue,
    ]
  );

  const onAutoPricingStrategyChange = useCallback(
    (value: string) => {
      if (!value) return;

      const autoPricingStrategy = autoPricingStrategiesQuery.data?.find(
        (r) => r.autoPricingSettingsCollectionId === parseInt(value, 10)
      );
      if (!autoPricingStrategy) {
        return;
      }

      applyAutoPricingStrategy(autoPricingStrategy);
    },
    [applyAutoPricingStrategy, autoPricingStrategiesQuery.data]
  );

  const currentValueEqualsSelectedStrategy = useMemo(() => {
    const autoPricingStrategy = autoPricingStrategiesQuery.data?.find(
      (r) => r.autoPricingSettingsCollectionId === selectedAutoPricingStrategyId
    );

    if (!autoPricingStrategy) return true;

    return (
      autoPricingEnabled?.value === true &&
      autoPricingStrategy &&
      (autoPricingStrategy.undercutMode == null ||
        autoPricingStrategy.undercutMode === undercutMode?.value) &&
      (autoPricingStrategy.undercutAbsoluteAmount == null ||
        autoPricingStrategy.undercutAbsoluteAmount ===
          undercutAbsoluteAmount?.value) &&
      (autoPricingStrategy.undercutRelativeAmount == null ||
        autoPricingStrategy.undercutRelativeAmount ===
          undercutRelativeAmount?.value) &&
      (autoPricingStrategy.compListingMode == null ||
        autoPricingStrategy.compListingMode === compListingMode?.value) &&
      (autoPricingStrategy.compListingFloor == null ||
        autoPricingStrategy.compListingFloor === compListingFloor?.value) &&
      (autoPricingStrategy.compListingCeiling == null ||
        autoPricingStrategy.compListingCeiling === compListingCeiling?.value) &&
      (autoPricingStrategy.compListingQuantityScoreAdjustmentEnabled == null ||
        autoPricingStrategy.compListingQuantityScoreAdjustmentEnabled ===
          compListingQuantityScoreAdjustmentEnabled?.value) &&
      (autoPricingStrategy.compListingOnlyForSameZoneEnabled == null ||
        autoPricingStrategy.compListingOnlyForSameZoneEnabled ===
          compListingOnlyForSameZoneEnabled?.value) &&
      (autoPricingStrategy.compListingOnlyForSelectedSectionsEnabled == null ||
        autoPricingStrategy.compListingOnlyForSelectedSectionsEnabled ===
          compListingOnlyForSelectedSectionsEnabled?.value) &&
      (autoPricingStrategy.compListingExcludeFanInventory == null ||
        autoPricingStrategy.compListingExcludeFanInventory ===
          compListingExcludeFanInventory?.value) &&
      (autoPricingStrategy.circuitBreakerRelativeCeiling == null ||
        autoPricingStrategy.circuitBreakerRelativeCeiling ===
          circuitBreakerRelativeCeiling?.value) &&
      (autoPricingStrategy.circuitBreakerRelativeFloor == null ||
        autoPricingStrategy.circuitBreakerRelativeFloor ===
          circuitBreakerRelativeFloor?.value) &&
      (autoPricingStrategy.circuitBreakerMaxDiscountVelocityPercent == null ||
        autoPricingStrategy.circuitBreakerMaxDiscountVelocityPercent ===
          circuitBreakerMaxDiscountVelocityPercent?.value) &&
      (autoPricingStrategy.circuitBreakerMaxDiscountVelocityTicksInHours ===
        null ||
        autoPricingStrategy.circuitBreakerMaxDiscountVelocityTicksInHours ===
          circuitBreakerMaxDiscountVelocityTicksInHours?.value) &&
      (autoPricingStrategy.circuitBreakerMinCompListingCount == null ||
        autoPricingStrategy.circuitBreakerMinCompListingCount ===
          circuitBreakerMinCompListingCount?.value) &&
      (autoPricingStrategy.outlierMode == null ||
        autoPricingStrategy.outlierMode === outlierMode?.value) &&
      (autoPricingStrategy.outlierStandardDeviations == null ||
        autoPricingStrategy.outlierStandardDeviations ===
          outlierStandardDeviations?.value) &&
      (autoPricingStrategy.outlierKthLowestLimit == null ||
        autoPricingStrategy.outlierKthLowestLimit ===
          outlierKthLowestLimit?.value) &&
      (autoPricingStrategy.outlierKthLowestLimitRelativeSpacing == null ||
        autoPricingStrategy.outlierKthLowestLimitRelativeSpacing ===
          outlierKthLowestLimitRelativeSpacing?.value) &&
      (autoPricingStrategy.outlierKthLowestLimitAbsoluteSpacing == null ||
        autoPricingStrategy.outlierKthLowestLimitAbsoluteSpacing ===
          outlierKthLowestLimitAbsoluteSpacing?.value)
    );
  }, [
    autoPricingEnabled,
    autoPricingStrategiesQuery.data,
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
    compListingCeiling,
    compListingFloor,
    compListingMode,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingExcludeFanInventory,
    compListingQuantityScoreAdjustmentEnabled,
    outlierMode,
    outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing,
    selectedAutoPricingStrategyId,
    undercutAbsoluteAmount,
    undercutMode,
    undercutRelativeAmount,
  ]);

  const isStrategyApplied =
    currentValueEqualsSelectedStrategy && selectedAutoPricingStrategyId;

  const defaultValuesForStrategy = useMemo(() => {
    const autoPricingStrategy = isStrategyApplied
      ? autoPricingStrategiesQuery.data?.find(
          (r) =>
            r.autoPricingSettingsCollectionId === selectedAutoPricingStrategyId
        )
      : undefined;

    return {
      autoPricingSettingsCollectionId:
        autoPricingStrategy?.autoPricingSettingsCollectionId,
      strategyName: autoPricingStrategy?.strategyName,

      autoPricingEnabled: autoPricingEnabled?.value,

      undercutMode: undercutMode?.value,
      undercutAbsoluteAmount: undercutAbsoluteAmount?.value,
      undercutRelativeAmount: undercutRelativeAmount?.value,

      compListingMode: compListingMode?.value,
      compListingFloor: compListingFloor?.value,
      compListingCeiling: compListingCeiling?.value,
      compListingQuantityScoreAdjustmentEnabled:
        compListingQuantityScoreAdjustmentEnabled?.value,
      compListingOnlyForSameZoneEnabled:
        compListingOnlyForSameZoneEnabled?.value,
      compListingOnlyForSelectedSectionsEnabled:
        compListingOnlyForSelectedSectionsEnabled?.value,
      compListingExcludeFanInventory: compListingExcludeFanInventory?.value,
      compListingExcludeDefects: compListingExcludeDefects?.value,

      circuitBreakerRelativeCeiling: circuitBreakerRelativeCeiling?.value,
      circuitBreakerRelativeFloor: circuitBreakerRelativeFloor?.value,
      circuitBreakerMaxDiscountVelocityPercent:
        circuitBreakerMaxDiscountVelocityPercent?.value,
      circuitBreakerMaxDiscountVelocityTicksInHours:
        circuitBreakerMaxDiscountVelocityTicksInHours?.value,
      circuitBreakerMinCompListingCount:
        circuitBreakerMinCompListingCount?.value,

      outlierMode: outlierMode?.value,
      outlierStandardDeviations: outlierStandardDeviations?.value,
      outlierKthLowestLimit: outlierKthLowestLimit?.value,
      outlierKthLowestLimitRelativeSpacing:
        outlierKthLowestLimitRelativeSpacing?.value,
      outlierKthLowestLimitAbsoluteSpacing:
        outlierKthLowestLimitAbsoluteSpacing?.value,
    } as AutoPricingSettingsCollectionInput;
  }, [
    isStrategyApplied,
    autoPricingStrategiesQuery.data,
    autoPricingEnabled?.value,
    undercutMode?.value,
    undercutAbsoluteAmount?.value,
    undercutRelativeAmount?.value,
    compListingMode?.value,
    compListingFloor?.value,
    compListingCeiling?.value,
    compListingQuantityScoreAdjustmentEnabled?.value,
    compListingOnlyForSameZoneEnabled?.value,
    compListingOnlyForSelectedSectionsEnabled?.value,
    compListingExcludeFanInventory?.value,
    compListingExcludeDefects?.value,
    circuitBreakerRelativeCeiling?.value,
    circuitBreakerRelativeFloor,
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    outlierMode,
    outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing,
    selectedAutoPricingStrategyId,
  ]);

  const detailContent = (
    <PosSelect
      onChange={onAutoPricingStrategyChange}
      value={
        isStrategyApplied ? selectedAutoPricingStrategyId.toString() : undefined
      }
      displayText={
        isStrategyApplied
          ? keyOptionsContent[selectedAutoPricingStrategyId.toString()]
          : ContentId.Custom
      }
      searchable
      valueOptionsContent={keyOptionsContent}
      placeholderText={ContentId.Custom}
      onClick={(e) => e.stopPropagation()}
      style={{ width: '50%' }}
    />
  );

  const headerContent = (
    <Stack direction="row" gap="m" width="full">
      <Content id={ContentId.StrategyName} />
      <ButtonWithIcon
        variant="textPlain"
        shape="none"
        title={createStrategyText}
        icon={
          isStrategyApplied ? (
            <EditIcon
              fill={vars.color.textBrand}
              size={vars.iconSize.s}
              withHoverEffect
            />
          ) : (
            <PlusIcon
              fill={vars.color.textBrand}
              size={vars.iconSize.s}
              withHoverEffect
            />
          )
        }
        onClick={() =>
          openAutoPricingStrategyDialog({
            defaultValues: defaultValuesForStrategy,
            isLoading: autoPricingStrategiesQuery.isLoading,
            onClosed: closeAutoPricingStrategyDialog,
            onStrategyUpdated: (strategyId: number) => {
              if (strategyId !== selectedAutoPricingStrategyId) {
                // An insert
                setSelectedAutoPricingStrategyId(strategyId);
                onAutoPricingStrategyChange(strategyId.toString());
              }
              closeAutoPricingStrategyDialog();
            },
            isBulkEdit: true,
            listingHasParent: false,
          })
        }
        style={{ padding: 0, textAlign: 'start' }}
      />
    </Stack>
  );

  return <PricingSetting header={headerContent} detail={detailContent} />;
};
