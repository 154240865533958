import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function BroadcastFailedIcon(props: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper {...props} viewBox="0 0 20 20" fill="none">
      <circle
        cx="10"
        cy="10"
        r="9.58333"
        fill="#D91A1A"
        stroke="#D91A1A"
        strokeWidth="0.833333"
      />
      <path
        d="M10 5.83337L10 10.8334"
        stroke="white"
        strokeWidth="1.33333"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M9.96714 14.3056C9.52508 14.3056 9.16671 13.9472 9.16671 13.5051C9.16671 13.0631 9.52508 12.7047 9.96714 12.7047C10.4092 12.7047 10.7676 13.0631 10.7676 13.5051C10.7676 13.9472 10.4092 14.3056 9.96714 14.3056Z"
        fill="#FBFCFC"
      />
    </SvgWrapper>
  );
}
