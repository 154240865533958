import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';

export const LaunchChangeTicketTypeRule = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const hasPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_UpdateDeliveryStrategy,
    Permission.Inventory_UpdateDeliveryStrategyOnIsPricerOf,
    Permission.Inventory_UpdateDeliveryStrategyOnPurchasedBy
  );

  if (!hasPermission) {
    return null;
  }

  return (
    <PosDropdownItem key="addHold" onClick={onClick} disabled={disabled}>
      <Stack alignItems="center" gap="m" width="full">
        <Content id={ContentId.SetDeliveryStrategy} />
      </Stack>
    </PosDropdownItem>
  );
};
