import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function MailIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M2 8.48V19.6h20v-4.08l-3.2-1.6L15 12l5.4-2.72v5.442l1.6.798V4.4H2v4.08zM20.4 18H3.6v-1.52l7.2-3.6 1.2.6 1.2-.6 7.2 3.6V18zM12 11.72l-8.4-4.2V6h16.8v1.52l-8.4 4.2zM3.6 9.28L9 12l-5.4 2.72V9.28z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
