import { debounce } from 'lodash-es';
import { ReactNode, useCallback, useMemo } from 'react';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { ContentId } from 'src/utils/constants/contentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { UserSetting } from 'src/WebApiController';

export const ViewModeSelector = <TQuery extends QueryWithViewMode>({
  query,
  initialQuery,
  setFilterQuery,
  viewModeSettingsId,
  viewModeCidMap,
  viewModeIconMap,
}: {
  query: TQuery;
  initialQuery: TQuery;
  setFilterQuery: (newQuery: TQuery) => void;
  viewModeSettingsId:
    | UserSetting.InventoryPageViewMode
    | UserSetting.SalePageViewMode
    | UserSetting.PurchasePageViewMode;
  viewModeCidMap: Record<string, string>;
  viewModeIconMap: Record<string, ReactNode>;
}) => {
  const {
    setUserSetting: setViewModeUserSetting,
    value: defaultViewModeUserSetting,
  } = useServerUserSetting<string>({
    id: viewModeSettingsId,
    currentLoginUserOnly: true,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnViewModeChange = useCallback(
    debounce((selectedViewMode?: string | null) => {
      if (selectedViewMode) {
        if (selectedViewMode !== query.viewMode) {
          setFilterQuery({
            ...query,
            viewMode: selectedViewMode,
          });
        }
        // We need to update initialQuery, otherwise the quick filter "All"
        // may change the current view mode
        if (selectedViewMode !== initialQuery.viewMode) {
          initialQuery.viewMode = selectedViewMode;
        }

        if (selectedViewMode !== defaultViewModeUserSetting) {
          setViewModeUserSetting(selectedViewMode);
        }
      }
    }, 200),
    [query]
  );

  const effectiveViewMode = useMemo(() => {
    const viewMode =
      query.viewMode ||
      defaultViewModeUserSetting ||
      Object.keys(viewModeCidMap)[0];

    return viewMode;
  }, [query.viewMode, defaultViewModeUserSetting, viewModeCidMap]);

  if (Object.keys(viewModeCidMap).length < 2) {
    return null;
  }

  return (
    <PosEnumSelect
      variant="textPlain"
      shape="none"
      value={effectiveViewMode}
      prefixDisplay={viewModeIconMap[effectiveViewMode ?? '']}
      displayText={ContentId.View}
      onChange={debouncedOnViewModeChange}
      valueOptionsContent={viewModeCidMap}
      valueOptionsIcon={viewModeIconMap}
    />
  );
};
