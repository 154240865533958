import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';

export const LaunchUngroupListings = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const hasPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_BroadcastUnbroadcast,
    Permission.Inventory_BroadcastUnbroadcastOnIsPricerOf,
    Permission.Inventory_BroadcastUnbroadcastOnPurchasedBy
  );

  if (!hasPermission) {
    return null;
  }

  return (
    <>
      <PosDropdownItem
        key="deleteSeatSaverBulk"
        onClick={onClick}
        disabled={disabled}
      >
        <Stack gap="m" alignItems="center" width="full">
          <Content id={ContentId.UngroupListings} />
        </Stack>
      </PosDropdownItem>
    </>
  );
};
