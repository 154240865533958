import { useState, useCallback, SetStateAction } from "react";
import { PosCurrencyField } from "src/core/POS/PosCurrencyField";
import { PosFormField } from "src/core/POS/PosFormField";
import { getTextFieldState, PosTextField } from "src/core/POS/PosTextField";
import { Stack } from "src/core/ui";
import { ToggleGroup } from "src/core/ui/ToggleGroup";
import {
  UndercutPriceSettingsMode,
  getUndercutPriceRepresentingMode,
  UNDERCUT_PRICE_MODE_OPTIONS,
} from "src/utils/autoPricingUtils";
import { ContentId } from "src/utils/constants/contentId";
import { AutoPricingInputs, UiCurrency } from "src/WebApiController";
import { useAutoPricingSettingsFormContext } from "../useAutoPricingSettingsFormContext";
import { roundToPrecision } from "src/utils/numberFormatter";

import * as styles from '../AutoPricing.css';
import { Content } from "src/contexts/ContentContext";

interface OnUnderCutChangeRelativeAmountProps {
  absUnderCutRelativeAmountNew: number | null,
  currentUnderCutRelativeAmountMode: UndercutPriceSettingsMode,
  absUnderCutAbsoluteAmountNew?: never,
  currentUnderCutAbsoluteAmountMode?: never,
};
interface OnUnderCutChangeAbsoluteAmountProps {
  absUnderCutRelativeAmountNew?: never,
  currentUnderCutRelativeAmountMode?: never,
  absUnderCutAbsoluteAmountNew: number | null,
  currentUnderCutAbsoluteAmountMode: UndercutPriceSettingsMode,
};

export const AutoPricingUnderCutRelativeAndAbsoluteAmountsInput = ({
  uiCurrency,
  disabled,
}: {
  uiCurrency: UiCurrency,
  disabled: boolean,
  }) => {
  const {
    setValue,
    clearErrors,
    formState,
    watch,
  } = useAutoPricingSettingsFormContext();

  const {
    undercutMode,
    undercutRelativeAmount,
    undercutAbsoluteAmount,
  } = watch();

  const undercutRelativeAmountError = formState.errors.undercutRelativeAmount?.message;
  const undercutAbsoluteAmountError = formState.errors.undercutAbsoluteAmount?.message;

  const [undercutRelativeAmountMode, setUndercutRelativeAmountMode] =
    useState(UndercutPriceSettingsMode.Discount);
  const [undercutAbsoluteAmountMode, setUndercutAbsoluteAmountMode] =
    useState(UndercutPriceSettingsMode.Discount);

  const onUnderCutChange = useCallback(
    ({
      absUnderCutRelativeAmountNew,
      currentUnderCutRelativeAmountMode,
      absUnderCutAbsoluteAmountNew,
      currentUnderCutAbsoluteAmountMode,
    }: OnUnderCutChangeRelativeAmountProps | OnUnderCutChangeAbsoluteAmountProps) => {
      if (absUnderCutRelativeAmountNew !== undefined) {
        const underCutRelativeAmountNew = absUnderCutRelativeAmountNew
          ? getUndercutPriceRepresentingMode(
            absUnderCutRelativeAmountNew,
            currentUnderCutRelativeAmountMode,
          ) : null;
        setValue('undercutRelativeAmount', underCutRelativeAmountNew);
      }

      if (absUnderCutAbsoluteAmountNew !== undefined) {
        const underCutAbsoluteAmountNew = absUnderCutAbsoluteAmountNew
          ? getUndercutPriceRepresentingMode(
            absUnderCutAbsoluteAmountNew,
            currentUnderCutAbsoluteAmountMode,
          ) : null;
        setValue('undercutAbsoluteAmount', underCutAbsoluteAmountNew);
      }

      clearErrors('undercutAbsoluteAmount');
      clearErrors('undercutRelativeAmount');
    },
    [
      setValue,
      clearErrors,
      undercutMode,
      undercutAbsoluteAmount,
      undercutRelativeAmount,
    ]
  );

  const isDisabled = disabled || !undercutMode;

  return (
    <Stack direction="row" gap="l">
      <AutoPricingUnderCutRelativeAmountInput
        undercutRelativeAmount={undercutRelativeAmount}
        undercutRelativeAmountMode={undercutRelativeAmountMode}
        setUndercutRelativeAmountMode={setUndercutRelativeAmountMode}
        undercutRelativeAmountError={undercutRelativeAmountError}
        onUnderCutChange={onUnderCutChange}
        disabled={isDisabled}
      />

      <div className={styles.inlinePricingInputText}>
        <Content id={ContentId.ThenBy} />
      </div>

      <AutoPricingUnderCutAbsoluteAmountInput
        uiCurrency={uiCurrency}
        undercutAbsoluteAmount={undercutAbsoluteAmount}
        undercutAbsoluteAmountMode={undercutAbsoluteAmountMode}
        setUndercutAbsoluteAmountMode={setUndercutAbsoluteAmountMode}
        undercutAbsoluteAmountError={undercutAbsoluteAmountError}
        onUnderCutChange={onUnderCutChange}
        disabled={isDisabled}
      />
    </Stack>
  );
};

const AutoPricingUnderCutRelativeAmountInput = ({
  undercutRelativeAmount,
  undercutRelativeAmountMode,
  setUndercutRelativeAmountMode,
  undercutRelativeAmountError,
  onUnderCutChange,
  disabled,
}: {
  undercutRelativeAmount: number | null,
  undercutRelativeAmountMode: UndercutPriceSettingsMode,
  setUndercutRelativeAmountMode: React.Dispatch<SetStateAction<UndercutPriceSettingsMode>>,
  undercutRelativeAmountError?: string,
  onUnderCutChange: (arg: OnUnderCutChangeRelativeAmountProps) => void,
  disabled: boolean,
}) => {
  return (
    <Stack gap="l">
      <ToggleGroup
        disabled={
          disabled ||
          !undercutRelativeAmount
        }
        options={UNDERCUT_PRICE_MODE_OPTIONS}
        value={undercutRelativeAmountMode}
        onValueChange={(value) => {
          if (!value) {
            return;
          }

          const undercutRelativeAmountModeNew = value as UndercutPriceSettingsMode;
          setUndercutRelativeAmountMode(undercutRelativeAmountModeNew);

          if (undercutRelativeAmount) {
            const absUndercutRelativeAmountNew = Math.abs(undercutRelativeAmount);
            onUnderCutChange({
              absUnderCutRelativeAmountNew: absUndercutRelativeAmountNew,
              currentUnderCutRelativeAmountMode: undercutRelativeAmountModeNew,
            });
          }
        }}
      />

      <div className={styles.inlinePricingInputText}>
        <Content id={ContentId.By} />
      </div>
      <PosFormField
        errors={undercutRelativeAmountError}
        style={{ width: 'fit-content' }}
      >
        <PosTextField
          rootProps={{
            state: getTextFieldState(undercutRelativeAmountError),
            style: { maxWidth: '100px' },
            disabled: disabled,
          }}
          disabled={disabled}
          type="number"
          postfixDisplay="%"
          value={
            undercutRelativeAmount === null
              ? ''
              : roundToPrecision(
                Math.abs(undercutRelativeAmount) * 100,
                8,
              )
          }
          onChange={(e) => {
            const v = parseFloat(e.target.value);
            const undercutRelativeAmountNew = v >= 0 && v <= Number.MAX_VALUE
              ? getUndercutPriceRepresentingMode(
                Math.min(v, 100) / 100,
                undercutRelativeAmountMode
              )
              : null;
            onUnderCutChange({
              absUnderCutRelativeAmountNew: undercutRelativeAmountNew,
              currentUnderCutRelativeAmountMode: undercutRelativeAmountMode,
            });
          }}
        />
      </PosFormField>
    </Stack>
  );
};

const AutoPricingUnderCutAbsoluteAmountInput = ({
  uiCurrency,
  undercutAbsoluteAmount,
  undercutAbsoluteAmountMode,
  setUndercutAbsoluteAmountMode,
  undercutAbsoluteAmountError,
  onUnderCutChange,
  disabled,
}: {
  uiCurrency: UiCurrency,
  undercutAbsoluteAmount: number | null,
  undercutAbsoluteAmountMode: UndercutPriceSettingsMode,
  setUndercutAbsoluteAmountMode: React.Dispatch<SetStateAction<UndercutPriceSettingsMode>>,
  undercutAbsoluteAmountError?: string,
  onUnderCutChange: (arg: OnUnderCutChangeAbsoluteAmountProps) => void,
  disabled: boolean,
}) => {
  return (
    <Stack gap="l">
      <ToggleGroup
        disabled={
          disabled ||
          !undercutAbsoluteAmount
        }
        options={UNDERCUT_PRICE_MODE_OPTIONS}
        value={undercutAbsoluteAmountMode}
        onValueChange={(value) => {
          if (!value) {
            return;
          }

          const undercutAbsoluteAmountModeNew = value as UndercutPriceSettingsMode;
          setUndercutAbsoluteAmountMode(undercutAbsoluteAmountModeNew);

          if (undercutAbsoluteAmount) {
            const absUndercutAbsoluteAmountNew = Math.abs(undercutAbsoluteAmount);
            onUnderCutChange({
              absUnderCutAbsoluteAmountNew: absUndercutAbsoluteAmountNew,
              currentUnderCutAbsoluteAmountMode: undercutAbsoluteAmountModeNew,
            });
          }
        }}
      />
      <div className={styles.inlinePricingInputText}>
        <Content id={ContentId.By} />
      </div>
      <PosFormField
        errors={undercutAbsoluteAmountError}
        style={{ width: 'fit-content' }}
      >
        <PosCurrencyField
          rootProps={{
            state: getTextFieldState(undercutAbsoluteAmountError),
            style: { maxWidth: '100px' },
            disabled: disabled,
          }}
          uiCurrency={uiCurrency}
          disabled={disabled}
          value={
            undercutAbsoluteAmount === null
              ? ''
              : Math.abs(undercutAbsoluteAmount)
          }
          onChange={(e) => {
            const v = parseFloat(e.target.value);
            const absUndercutAbsoluteAmountNew = v >= 0 && v <= Number.MAX_VALUE
              ? v
              : null;
            onUnderCutChange({
              absUnderCutAbsoluteAmountNew: absUndercutAbsoluteAmountNew,
              currentUnderCutAbsoluteAmountMode: undercutAbsoluteAmountMode,
            });
          }}
        />
      </PosFormField>
    </Stack>
  );
};