import { useCallback } from 'react';
import { useMatches, useNavigate } from 'react-router-dom';
import { useSet } from 'react-use';
import { AccountDisabledNotification } from 'src/components/common/AccountDisabledNotification';
import { ConversationMainPanel } from 'src/components/Messages/ConversationMainPanel';
import { ConversationSidePanel } from 'src/components/Messages/ConversationSidePanel';
import { ConversationDataContextProvider } from 'src/contexts/ConversationDataContext';
import { SellerSupportCaseDataContextProvider } from 'src/contexts/SellerSupportCaseDataContext';
import { SidePanelProvider } from 'src/contexts/SidePanelContext';
import { ExplorerLayout } from 'src/core/layouts';
import { AutoPoInboundEmailPartialPurchaseOrderQuery } from 'src/WebApiController';

import { EmailList } from './EmailList';
import { EmailNavPageName } from './EmailNavToolbar';
import * as styles from './MessagesExplorer.css';

export function MessagesExplorer({
  query,
  pageName,
}: {
  query?: AutoPoInboundEmailPartialPurchaseOrderQuery;
  pageName: EmailNavPageName;
}) {
  // const { inboundEmailId: activeInboundEmailId } = useParams();
  const navigate = useNavigate();
  const matches = useMatches();

  const [classifiedInboundEmailIds, { add: addClassifiedInboundEmailId }] =
    useSet<number>();
  const onEmailClassified = useCallback(
    (inboundEmailId: number) => {
      addClassifiedInboundEmailId(inboundEmailId);
      const parentMatch = matches.at(-2);
      parentMatch && navigate(parentMatch.pathname);
    },
    [addClassifiedInboundEmailId, navigate, matches]
  );

  return (
    <ConversationDataContextProvider>
      <SellerSupportCaseDataContextProvider>
        <AccountDisabledNotification />
        <SidePanelProvider sidePanelId="messages" initialOpened>
          <ExplorerLayout
            maxPanelPercent={0.25}
            showExpandButton
            leftPanelContent={
              pageName === 'inbox' ? (
                <ConversationSidePanel />
              ) : (
                <EmailList
                  query={query!}
                  classifiedInboundEmailIds={classifiedInboundEmailIds}
                />
              )
            }
            autoHide={false}
            initialSlide={0}
          >
            <div className={styles.emailContentContainer}>
              <ConversationMainPanel onEmailClassified={onEmailClassified} />
            </div>
          </ExplorerLayout>
        </SidePanelProvider>
      </SellerSupportCaseDataContextProvider>
    </ConversationDataContextProvider>
  );
}
