import { TableOptions } from '@tanstack/react-table';
import { CSSProperties, useMemo, useRef } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { useGetFilteredAccessibleVendorAccountsQuery } from 'src/hooks/api/useGetFilteredAccessibleVendorAccountsQuery';
import {
  VendorAccountActionsImperativeRef,
  VendorAccountsActions,
} from 'src/modals/VendorDetails/components/VendorAccounts/components/VendorAccountsTable/VendorAccountActions';
import {
  PurchaseVendorAccountWithActions,
  VENDORS_ACCOUNTS_TABLE_COLUMNS_CONFIG,
} from 'src/modals/VendorDetails/components/VendorAccounts/components/VendorAccountsTable/VendorsAccountsTableColumnsConfig';
import { OnVendorAccountSavedFn } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useAddVendorAccount';
import { Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseVendorAccount } from 'src/WebApiController';

const getRowId = (
  row: PurchaseVendorAccountWithActions | null,
  index: number
) => (row?.vendorAccount?.id ?? index).toString();

const columns = Object.values(VENDORS_ACCOUNTS_TABLE_COLUMNS_CONFIG);

const tableWrapperStyle: CSSProperties = { height: '100% ' };
const disabledPaginationStyles: CSSProperties = {
  position: 'sticky',
  top: '0',
  zIndex: '10',
};

interface VendorAccountsTableProps {
  vendorAccountsQuery: ReturnType<
    typeof useGetFilteredAccessibleVendorAccountsQuery
  >;
  onVendorAccountSaved: OnVendorAccountSavedFn;
}

export const VendorAccountsTable = ({
  vendorAccountsQuery,
  onVendorAccountSaved,
}: VendorAccountsTableProps) => {
  const vendorAccounts = vendorAccountsQuery.data;

  const refActions = useRef<VendorAccountActionsImperativeRef | null>(null);

  const purchaseVendorAccountWithActions = useMemo<
    PurchaseVendorAccountWithActions[]
  >(() => {
    return (vendorAccounts ?? []).map((va: PurchaseVendorAccount) => ({
      vendorAccount: va,
      vendorAccountActions: {
        editVendorAccount: (vendorAccount: PurchaseVendorAccount) => {
          refActions.current?.editVendorAccount(vendorAccount);
        },
      },
    }));
  }, [vendorAccounts]);

  const options: Partial<
    TableOptions<PurchaseVendorAccountWithActions | null>
  > = useMemo(
    () => ({
      data: purchaseVendorAccountWithActions,
      columns: columns,
      getRowId: getRowId,
    }),
    [purchaseVendorAccountWithActions]
  );

  if (!vendorAccountsQuery.isFetching && (vendorAccounts ?? []).length === 0) {
    return (
      <Stack justifyContent="center" alignItems="center" height="full">
        <Content id={ContentId.NoResults} />
      </Stack>
    );
  }

  return (
    <div style={tableWrapperStyle}>
      <Table
        options={options}
        withOuterPadding
        tableLayout="fixed"
        tableHeadStyle={disabledPaginationStyles}
        usePaginationFooter={false}
        useVirtuoso={true}
        onVirtuosoEndReached={vendorAccountsQuery.loadNextVendorAccountsPage}
        footerEl={
          <>
            {vendorAccountsQuery.isFetching && (
              <tr>
                <td colSpan={10}>
                  <Stack justifyContent="center">
                    <PosSpinner size="10px" />
                  </Stack>
                </td>
              </tr>
            )}
          </>
        }
      />
      <VendorAccountsActions
        vendorAccountsActionsRef={refActions}
        onVendorAccountSaved={onVendorAccountSaved}
      />
    </div>
  );
};
