import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import {
  Cell,
  getCoreRowModel,
  Row,
  RowData,
  Table as ReactTable,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import clsx from 'clsx';
import React, { useCallback, useRef, useState } from 'react';
import { State } from 'react-use/lib/useScroll';
import { TableVirtuoso } from 'react-virtuoso';
import { ColumnHeader } from 'src/tables/Table/components';
import { PosVirtuosoTable } from 'src/tables/Table/PosVirtuosoTableComponent/PosVirtuosoTable';
import { PosVirtuosoTableContext } from 'src/tables/Table/PosVirtuosoTableComponent/PosVirtuosoTableContext';
import { PosVirtuosoTableHead } from 'src/tables/Table/PosVirtuosoTableComponent/PosVirtuosoTableHead';
import * as styles from 'src/tables/Table/Table.css';
import { itemSizeDefault } from 'src/tables/Table/Table.utils';
import { SectionType } from 'src/utils/types/sectionType';

import { CellItem, SortableCell } from '../CellItem';
import {
  indexFromCellId,
  SectionRowColumnType,
} from '../config/SectionRowTableConfig';
import { EnrichedScoreOrverride } from '../SectionRowTableDialog.hooks';
import { SectionRowTableRow } from './SectionRowTableRow';

const virtuosoTableOverscan = { main: 500, reverse: 500 };
const virtuosoTableStyle = { width: '100%', height: '100%' };

export type SectionRowTableContext<T extends RowData> =
  PosVirtuosoTableContext<T> & {
    onRowSelect?: (row: Row<T>) => void;
  };

export interface VirtuosoScrollEvent {
  scrollState: State;
  element: HTMLElement;
}

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    paddingLeft?: string;
    paddingLeftLargeDesktop?: string;
    styleOverrides?: React.CSSProperties;
  }

  interface TableMeta<TData extends RowData> {
    summaryData?: TData | null | undefined;
    sectionType?: SectionType;
    rowSupportsAccordion?: boolean;
  }
}

export type TableLayout = 'fixed' | 'auto';

export type PosTableData = RowData;

export type TableProps<T extends PosTableData> = {
  tableLayout?: TableLayout;
  options: Partial<TableOptions<T>>;
  className?: string;
  onSwapCell: (
    sectionId: number,
    from: EnrichedScoreOrverride,
    to: EnrichedScoreOrverride
  ) => void;
  onSelectSections: (sectionIds: number[]) => void;
  canCustomRows: boolean;
};

export const SectionRowTable = ({
  tableLayout,
  options,
  className,
  onSwapCell,
  onSelectSections,
  canCustomRows,
}: TableProps<SectionRowColumnType>) => {
  const tableWrapperRef = useRef<HTMLTableElement | null>(null);
  const sensors = useSensors(useSensor(MouseSensor));
  const [activeId, setActiveId] = useState<string | null>(null);

  const table: ReactTable<SectionRowColumnType> = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    groupedColumnMode: false,
    getRowId: (row, index) => row?.sectionId ?? index,
    ...options,
    state: {
      ...options.state,
    },
  } as TableOptions<SectionRowColumnType>);

  const tableRows = table.getRowModel().rows;

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id + '');
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent, cells: Cell<SectionRowColumnType, unknown>[]) => {
      const { active, over } = event;

      const activeIndex = indexFromCellId(active.id + '');
      const overIndex = indexFromCellId(over?.id + '');

      if (activeIndex !== overIndex && activeIndex < cells.length) {
        const activeCell = cells[activeIndex];
        const overCell = cells[overIndex];
        const activeRow = activeCell.getValue() as EnrichedScoreOrverride;
        const overRow = overCell?.getValue() as
          | EnrichedScoreOrverride
          | undefined;

        if (activeRow && overRow) {
          onSwapCell(activeRow.sectionId, activeRow, overRow);
        }
      }

      setActiveId(null);
    },
    [onSwapCell]
  );
  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const renderRowContent = useCallback(
    (row: Row<SectionRowColumnType>) => {
      const items = row.getVisibleCells();
      return (
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragStart={handleDragStart}
          onDragEnd={(event) => handleDragEnd(event, items)}
          onDragCancel={handleDragCancel}
        >
          {items.length && <CellItem cell={items[0]} draggable={false} />}
          <SortableContext items={items} strategy={rectSortingStrategy}>
            {items.slice(1, items.length - 1).map((cell) => {
              if (cell.getValue() == null || !canCustomRows) {
                return <CellItem key={cell.id} cell={cell} draggable={false} />;
              }
              return <SortableCell key={cell.id} cell={cell} />;
            })}
          </SortableContext>
          {items.length && (
            <CellItem cell={items[items.length - 1]} draggable={false} />
          )}
          <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
            {activeId ? (
              <CellItem cell={items.find(({ id }) => id === activeId)!} />
            ) : null}
          </DragOverlay>
        </DndContext>
      );
    },
    [
      activeId,
      canCustomRows,
      handleDragCancel,
      handleDragEnd,
      handleDragStart,
      sensors,
    ]
  );

  const onRowSelect = useCallback(
    (row: Row<SectionRowColumnType>) => {
      if (!canCustomRows) {
        return;
      }
      table.setRowSelection((prev) => {
        const rowId = parseInt(row.id);
        const updated = {
          ...prev,
          [rowId]: !(prev[rowId] ?? true),
        };
        const selected = Object.entries(updated)
          .filter(([_, value]) => value)
          .map(([key, _]) => key)
          .map(Number);
        onSelectSections(selected);

        return updated;
      });
    },
    [canCustomRows, onSelectSections, table]
  );

  return (
    <div
      ref={tableWrapperRef}
      className={clsx(
        styles.POSTableContainer,
        styles.POSTableVirtuosoContainer,
        className
      )}
    >
      <TableVirtuoso<
        Row<SectionRowColumnType>,
        SectionRowTableContext<SectionRowColumnType>
      >
        style={virtuosoTableStyle}
        data={tableRows}
        overscan={virtuosoTableOverscan}
        context={{
          tableLayout,
          tableRows,
          onRowSelect,
        }}
        itemSize={itemSizeDefault}
        components={{
          Table: PosVirtuosoTable,
          TableHead: PosVirtuosoTableHead,
          TableRow: SectionRowTableRow,
        }}
        fixedHeaderContent={() => {
          return (
            <>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <ColumnHeader
                      key={header.id}
                      header={header}
                      table={table}
                    />
                  ))}
                </tr>
              ))}
            </>
          );
        }}
        itemContent={(index, row) => renderRowContent(row)}
      />
    </div>
  );
};

export default SectionRowTable;
