import { debounce } from 'lodash-es';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormText } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextArea } from 'src/core/POS/PosTextArea';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { TicketUrl } from 'src/WebApiController';

import { TicketUrlForm } from '../../UploadTicketUrls';
import * as styles from './BulkStringArtifactInput.css';

export const BulkTicketUrlInput = ({
  quantity,
  readOnly = false,
  onPreview,
}: {
  quantity: number;
  onPreview?: () => void;
  readOnly?: boolean;
}) => {
  const {
    register,
    trigger,
    setValue,
    watch,
    formState: { errors, touchedFields },
  } = useFormContext<TicketUrlForm>();
  const ticketUrls = watch('ticketUrls');

  const initialValue = useMemo(() => {
    const ticketUrlsArray = ticketUrls.map(({ url }: TicketUrl) => url);
    return ticketUrlsArray.join('\n').trimEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * Registering this was since the dispay value differs from how it is stored
     * ie. string for text area vs TicketUrl[]
     */
    register('ticketUrls');

    // loaded from a toggle, show errors
    if (touchedFields?.ticketUrls?.length) {
      trigger('ticketUrls');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (e: React.SyntheticEvent) => {
      debounce((e: React.SyntheticEvent) => {
        const newTicketUrls = (e.target as HTMLInputElement).value
          .trimEnd()
          .split('\n');

        if (newTicketUrls.length < quantity) {
          newTicketUrls.push(
            ...new Array(quantity - newTicketUrls.length).fill('')
          );
        }

        setValue(
          'ticketUrls',
          // create array of ticketUrls in original insert order
          newTicketUrls.map((url, idx) => ({
            // in order to display "Too many" error, set null
            ...ticketUrls[idx],
            url,
          })),
          { shouldValidate: true, shouldTouch: true, shouldDirty: true }
        );
      }, 100)(e);
    },
    [setValue, ticketUrls, quantity]
  );

  const ticketUrlsError = errors?.ticketUrls?.message;

  return (
    <div className={styles.bulkUploadWrapper}>
      <PosFormField errors={ticketUrlsError}>
        <PosTextArea
          rootProps={{
            disabled: readOnly,
            state: getTextFieldState(ticketUrlsError),
          }}
          disabled={readOnly}
          onChange={handleChange}
          defaultValue={initialValue}
          style={{ resize: 'vertical' }}
        />
      </PosFormField>
      {!readOnly && (
        <div className={styles.instructionsWrapper}>
          <FormText>
            <Content id={ContentId.EachTicketUrlOnNewLine} />
          </FormText>
          {onPreview && (
            <Button
              className={styles.previewButton}
              variant={'text'}
              onClick={onPreview}
            >
              <Content id={ContentId.Preview} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
