import { DownloadButton } from 'src/components/Buttons/DownloadButton';
import { FormatContent } from 'src/contexts/ContentContext';
import { shared, vars } from 'src/core/themes';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { getCurrentLanguage } from 'src/utils/localeUtils';
import { AutoPoInboundEmailThreadEmail } from 'src/WebApiController';

import { EmailRenderer } from '../EmailBody';
import * as styles from './EmailThreadItem.css';

const dateFormatter = new Intl.DateTimeFormat(getCurrentLanguage(), {
  dateStyle: 'long',
  timeStyle: 'short',
});

export function EmailThreadItem({
  email: {
    subject,
    receiveDate,
    fromEmailAddress,
    toEmailAddress,
    htmlBody,
    textBody,
    attachments,
  },
}: {
  email: AutoPoInboundEmailThreadEmail;
}) {
  return (
    <div>
      <div className={styles.header}>
        <div className={styles.row}>
          <div className={shared.typography.title6}>{subject && subject}</div>
          {receiveDate && (
            <div className={styles.emailDate}>
              {dateFormatter.format(stringToUtcDate(receiveDate))}
            </div>
          )}
        </div>
        <div className={styles.row}>
          <div className={styles.emailAddresses}>
            {fromEmailAddress && (
              <span>
                <FormatContent
                  id={FormatContentId.EmailAddressFrom}
                  params={<>{fromEmailAddress}</>}
                />
              </span>
            )}
            {fromEmailAddress && toEmailAddress && <br />}
            {toEmailAddress && (
              <span>
                <FormatContent
                  id={FormatContentId.EmailAddressTo}
                  params={<>{toEmailAddress}</>}
                />
              </span>
            )}
          </div>
        </div>
      </div>
      {attachments && attachments.length > 0 && (
        <div>
          {attachments.map((attachment, index) => (
            <div key={index}>
              <DownloadButton
                variant={'outline'}
                textContentId={attachment.attachmentFileName}
                onClick={() => window.open(attachment.downloadUri, '_blank')}
              />
            </div>
          ))}
        </div>
      )}
      {(htmlBody || textBody) && (
        <div style={{ padding: `0 ${vars.spacing.lg} ${vars.spacing.lg}` }}>
          <EmailRenderer htmlBody={htmlBody} textBody={textBody} />
        </div>
      )}
    </div>
  );
}
