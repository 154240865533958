import { useCallback, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { Button } from 'src/core/ui';
import { UserGroupInput, useUserGroups } from 'src/hooks/useUserGroups';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { UserGroup } from 'src/WebApiController';

import * as styles from './EditTeamModal.css';
import { TeamInput } from './TeamInput';

type AddTeamModalFooterProps = { onClose: () => void };

export const AddTeamModal = ({ onClose }: { onClose: () => void }) => {
  const methods = useForm<UserGroupInput>({
    defaultValues: {
      userGroupName: '',
      sellerUserIds: [],
    },
  });

  return (
    <FormProvider {...methods}>
      <AddTeamModalContent onClose={onClose} />
    </FormProvider>
  );
};

export const AddTeamModalContent = ({ onClose }: { onClose: () => void }) => {
  const [isOpen, toggleIsOpen] = useToggle(true);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        toggleIsOpen();
        onClose();
      }}
      unmountOnClose
      clickOutsideToClose
      centered
      size="sm"
    >
      <ModalHeader
        onClose={() => {
          toggleIsOpen();
          onClose();
        }}
      >
        <AddTeamModalHeader />
      </ModalHeader>
      <ModalBody>
        <TeamInput />
      </ModalBody>
      <ModalFooter>
        <AddTeamModalFooter
          onClose={() => {
            toggleIsOpen();
            onClose();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export const AddTeamModalHeader = () => {
  return (
    <div className={styles.header}>
      <h5 className={styles.headerText}>
        <Content id={ContentId.CreateANewTeam} />
      </h5>
    </div>
  );
};

export const AddTeamModalFooter = ({ onClose }: AddTeamModalFooterProps) => {
  const { createUserGroup } = useUserGroups();
  const cancelText = useContent(ContentId.Cancel);
  const saveText = useContent(ContentId.Save);

  const { handleSubmit } = useFormContext<UserGroup>();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isLoading, setIsLoading] = useState(false);

  const onCreateSubmit = useCallback(
    async (formValue: UserGroup) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const result = await createUserGroup(formValue);

          if (result) {
            onClose();
          }
        },
        (error) => {
          showErrorDialog('UserGroupClient.createUserGroup', error, {
            trackErrorData: formValue,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [onClose, showErrorDialog, createUserGroup]
  );

  const onCreateSubmitHandler = useCallback(() => {
    // TODO: Add validation for user if needed

    // const formValue = getValues();
    // const { userGroupName } = formValue;
    // clearErrors('userGroupName');
    // let hasErrors = false;
    // if (!roles || !roles.length) {
    //   setError('roles', { message: requiredMsg });
    //   hasErrors = true;
    // }

    // if (hasErrors) {
    //   return;
    // }

    handleSubmit(onCreateSubmit)();
  }, [handleSubmit, onCreateSubmit]);

  return (
    <div className={styles.footer}>
      <Button variant="outline" onClick={onClose} disabled={isLoading}>
        {cancelText}
      </Button>
      <div className={styles.buttonGroup}>
        <Button onClick={onCreateSubmitHandler} disabled={isLoading}>
          {saveText}
        </Button>
      </div>
    </div>
  );
};
