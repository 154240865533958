import { useCallback, useMemo, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { CheckIcon, IconsFill } from 'src/svgs/Viagogo';
import { getColumnConfigById } from 'src/utils/columns/columnUtils';
import {
  ListingTableColumnId,
  ListingTableFlattenedColumnId,
} from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import {
  SalesTableColumnId,
  SalesTableFlattenedColumnId,
} from 'src/utils/columns/sales/salesColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import {
  SECTION_TYPE_TO_CUSTOM_COLUMN_SETTING,
  SectionType,
} from 'src/utils/types/sectionType';
import { Feature } from 'src/WebApiController';

import {
  FormatOption,
  FormatOption_DateTime,
  FormatOption_EnumDisplay,
  FormatOption_Event,
  FormatOption_Percent,
  FormatOption_Seating,
  FormatOption_Standard,
} from '../EditTableColumns.constants';
import {
  generateFormatOptionDisplayString,
  getDefaultFormatOption,
} from '../EditTableColumns.util';
import {
  CustomColumn,
  FormatDisplayOption,
  FormatOptionPillSelectProps,
} from './FormatOptionPill.types';

export const FormatOptionPillSelect = ({
  id,
  initialValue,
  onSave,
  sectionType,
  trigger,
}: FormatOptionPillSelectProps) => {
  const hasViewListingOriginalSeatingFeature = useUserHasFeature(
    Feature.ViewListingOriginalSeating
  );
  const originalSeating = useContent(ContentId.OriginalSeating);
  const fullSeating = useContent(
    (sectionType === SectionType.Listings ||
      sectionType === SectionType.ListingsFlattened) &&
      hasViewListingOriginalSeatingFeature
      ? ContentId.RemainingSeatingOnly
      : ContentId.FullSeating
  );
  const sectionOnly = useContent(ContentId.SectionOnly);
  const fullEventInfo = useContent(ContentId.FullEventInfo);
  const eventName = useContent(ContentId.EventName);
  const icon = useContent(ContentId.Icon);
  const text = useContent(ContentId.Text);
  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();

  const uiCurrency = getUiCurrency(
    loginContext?.user?.activeAccount.currencyCode
  );

  const { value: customColumns = [] } = useServerUserSetting<CustomColumn[]>({
    id: SECTION_TYPE_TO_CUSTOM_COLUMN_SETTING[sectionType],
  });

  const columnConfig = getColumnConfigById({ id, customColumns, sectionType });
  const { isCurrency, isPercent, isEnumWithIcon, isDateTime, isInteger } =
    columnConfig.personalization;
  const defaultFormatOption = getDefaultFormatOption({
    isEnumWithIcon,
    isInteger,
    isSeating:
      id === ListingTableColumnId.Seating ||
      id === SalesTableColumnId.Seating ||
      id === PurchasesTableColumnId.Seating,
    isEvent:
      id === ListingTableFlattenedColumnId.Event ||
      id === SalesTableFlattenedColumnId.Event,
    isDateTime,
    isPercent,
  });

  const [selectedPrecision, setSelectedPrecision] = useState(
    initialValue ?? defaultFormatOption
  );

  const addToOptions = useCallback(
    (formatOptions: FormatOption[]) =>
      formatOptions.map((option) => ({
        option,
        isCurrency,
        isPercent,
        onClick: () => setSelectedPrecision(option),
      })),
    [isCurrency, isPercent]
  );

  const options: FormatDisplayOption[] = useMemo(() => {
    if (
      id === ListingTableColumnId.Seating ||
      id === SalesTableColumnId.Seating ||
      id === PurchasesTableColumnId.Seating
    ) {
      // Handle Seating
      return addToOptions(
        Object.values(FormatOption_Seating).filter(
          (v) =>
            !(
              v === FormatOption_Seating.Seating_OriginalSeating &&
              ((sectionType !== SectionType.Listings &&
                sectionType !== SectionType.ListingsFlattened) ||
                !hasViewListingOriginalSeatingFeature)
            )
        )
      );
    } else if (id === ListingTableColumnId.SeatingSeats) {
      // Handle SeatingSeats
      return addToOptions(
        Object.values(FormatOption_Seating).filter(
          (v) => !(v === FormatOption_Seating.Seating_SectionOnly)
        )
      );
    } else if (
      id === ListingTableFlattenedColumnId.Event ||
      id === SalesTableFlattenedColumnId.Event
    ) {
      // Handle Event
      return addToOptions(Object.values(FormatOption_Event));
    } else if (isDateTime) {
      // Handle DateTime
      return addToOptions(Object.values(FormatOption_DateTime));
    } else if (isEnumWithIcon) {
      // Handle EnumDisplay
      return addToOptions(Object.values(FormatOption_EnumDisplay));
    } else if (isPercent) {
      // Handle Percentage
      return addToOptions(Object.values(FormatOption_Percent));
    } else {
      // Handle Numeric
      const standardOptions = Object.values(FormatOption_Standard);
      return addToOptions(
        isInteger
          ? standardOptions.filter(
              (option) => option !== FormatOption_Standard.Precise
            )
          : standardOptions
      );
    }
  }, [
    addToOptions,
    hasViewListingOriginalSeatingFeature,
    id,
    isDateTime,
    isEnumWithIcon,
    isInteger,
    isPercent,
    sectionType,
  ]);

  return (
    <PosDropdown
      key="format-option-select-dropdown"
      trigger={trigger}
      align="end"
    >
      {options.map((item) => {
        const { isCurrency, isPercent, option, onClick } = item;
        const isSelected = selectedPrecision === option;
        const textContent = generateFormatOptionDisplayString({
          localizedText: {
            originalSeating,
            fullSeating,
            sectionOnly,
            fullEventInfo,
            eventName,
            icon,
            text,
          },
          uiCurrency,
          isCurrency,
          isPercent,
          option,
        });
        return (
          <PosDropdownItem
            key={option}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onSave(id, option);
              if (onClick) {
                onClick();
              }
            }}
          >
            {textContent}
            {isSelected && (
              <CheckIcon size={vars.iconSize.s} fill={IconsFill.textSuccess} />
            )}
          </PosDropdownItem>
        );
      })}
    </PosDropdown>
  );
};
