import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { id } from 'date-fns/locale';
import { castDraft, produce } from 'immer';
import { isEqual } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useContentContext } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { FormatContentIds } from 'src/utils/constants/formatContentIdDataMap';
import { ReportTypes } from 'src/utils/reportsUtils';
import {
  ListingQuery,
  ReportClient,
  ReportConfigOfListingQueryAndReportWidgetTypesInventory,
  ReportConfigOfSaleQueryAndReportWidgetTypesSale,
  ReportConfigOverrideOfListingQuery,
  ReportConfigOverrideOfSaleQuery,
  SaleQuery,
} from 'src/WebApiController';

/**
 * Map report config settings to their data types.
 *
 * Add to this type if new settings are added.
 */
type ReportConfigMap = {
  [ReportTypes.Inventory]: ReportConfigOfListingQueryAndReportWidgetTypesInventory;
  [ReportTypes.Sale]: ReportConfigOfSaleQueryAndReportWidgetTypesSale;
};

/**
 * Internal type to unify the different report config types.
 */
type ReportConfigTypeBase =
  | ReportConfigOfListingQueryAndReportWidgetTypesInventory
  | ReportConfigOfSaleQueryAndReportWidgetTypesSale;

type ReportConfigType<S extends ReportTypes> = ReportConfigMap[S];

/**
 * Helper type for consumers of this hook to use for typing report configs more easily.
 */
export type ReportConfig = ReportConfigTypeBase & {
  reportType: ReportTypes;
};

type UseReportConfigsOptions = {
  reportType: ReportTypes;
};

type ReportConfigInput = Omit<
  ReportConfig,
  'schemaVersion' | 'reportId' | 'reportType'
>;

/**
 * Data and functions returned by the hook to help with CRUD of report config with different types
 */
type UseReportConfigs = {
  reportConfigs: ReportConfig[];
  isLoading: boolean;
  deleteReportConfig: (reportId: number) => void;
  upsertReportConfig: (
    reportId: number | null,
    updatedConfig: Partial<ReportConfigInput>,
    isTemporary?: boolean
  ) => Promise<number | null>;
  upsertReportOverride: (
    configOverride:
      | ReportConfigOverrideOfListingQuery
      | ReportConfigOverrideOfSaleQuery,
    originalReportConfig:
      | ReportConfigOfListingQueryAndReportWidgetTypesInventory
      | ReportConfigOfSaleQueryAndReportWidgetTypesSale
  ) => void;
  getReportConfigNameForDuplicate: (reportName: string) => string;
};

const addTimezoneOffsetToSaleReportConfig = (
  reportNoOffset: ReportConfigOfSaleQueryAndReportWidgetTypesSale
): ReportConfigOfSaleQueryAndReportWidgetTypesSale => {
  return {
    ...reportNoOffset,
    filter: !reportNoOffset.filter
      ? reportNoOffset.filter
      : ({
          ...reportNoOffset.filter,
          timezoneOffsetMins: new Date().getTimezoneOffset(),
        } as SaleQuery),
  };
};

const addTimezoneOffsetToListingReportConfig = (
  reportNoOffset: ReportConfigOfListingQueryAndReportWidgetTypesInventory
): ReportConfigOfListingQueryAndReportWidgetTypesInventory => {
  return {
    ...reportNoOffset,
    filter: !reportNoOffset.filter
      ? reportNoOffset.filter
      : ({
          ...reportNoOffset.filter,
          timezoneOffsetMins: new Date().getTimezoneOffset(),
        } as ListingQuery),
  };
};

/**
 * Hook to help with CRUD of report config with different types
 * @param options
 * @returns
 */
export function useReportConfigs<S extends ReportTypes>({
  reportType,
}: UseReportConfigsOptions): UseReportConfigs {
  const { showErrorDialog, trackError } = useErrorBoundaryContext();
  const { formattedContentResolver } = useContentContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const reportClient = useMemo(
    () => new ReportClient(activeAccountWebClientConfig),
    [activeAccountWebClientConfig]
  );

  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => [
      'useReportConfigs',
      activeAccountWebClientConfig.activeAccountId,
      reportType,
    ],
    [activeAccountWebClientConfig.activeAccountId, reportType]
  );

  // Query to get all report configs
  const reportConfigQuery = useQuery<ReportConfigType<S>[] | null>({
    queryKey,
    queryFn: async () => {
      if (activeAccountWebClientConfig?.activeAccountId == null) {
        return null;
      }

      if (reportType === ReportTypes.Inventory) {
        return (await reportClient.getAvailableInventoryReportConfig()).map(
          addTimezoneOffsetToListingReportConfig
        ) as ReportConfigType<S>[];
      } else {
        return (await reportClient.getAvailableSaleReportConfig()).map(
          addTimezoneOffsetToSaleReportConfig
        ) as ReportConfigType<S>[];
      }
    },

    enabled: activeAccountWebClientConfig?.activeAccountId != null,
    meta: {
      onError: (err: ErrorTypes) => {
        trackError('ReportClient.getAvailableReportConfig', err, {
          trackErrorData: { reportType },
        });
      },
    },
    refetchOnWindowFocus: false,
  });

  const _reportConfigs = useMemo(() => {
    if (reportConfigQuery.data == null) {
      return [];
    }
    return reportConfigQuery.data;
  }, [reportConfigQuery.data]);

  // Mutation to upsert report config
  const upsertMutation = useMutation({
    mutationFn: async ({
      value,
      isTemporary,
    }: {
      value: ReportConfigType<S>;
      isTemporary?: boolean;
    }) => {
      if (isTemporary) {
        // Do nothing, just let onMutate handle the optimistic update
        return value.reportId!;
      }

      if (reportType === ReportTypes.Inventory) {
        return await reportClient.upsertInventoryReportConfig(
          value as ReportConfigOfListingQueryAndReportWidgetTypesInventory
        );
      }
      return await reportClient.upsertSaleReportConfig(
        value as ReportConfigOfSaleQueryAndReportWidgetTypesSale
      );
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates

    onMutate: ({ value }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue =
        queryClient.getQueryData<ReportConfigType<S>[]>(queryKey);

      const newValue =
        value.reportId == null
          ? [...(prevValue ?? []), value]
          : prevValue?.map((r) => (r.reportId === value.reportId ? value : r));
      queryClient.setQueryData<ReportConfigType<S>[]>(queryKey, newValue);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { value }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('reportClient.upsertReportConfig', err, {
        trackErrorData: { id, value },
      });
    },
    onSettled: (_data, _error, { isTemporary }) => {
      if (!isTemporary) {
        queryClient.invalidateQueries({ queryKey });
      }
    },
  });

  const upsertOverrideMutation = useMutation({
    mutationFn: async ({
      configOverride,
      originalReportConfig,
    }: {
      configOverride:
        | ReportConfigOverrideOfListingQuery
        | ReportConfigOverrideOfSaleQuery;
      originalReportConfig:
        | ReportConfigOfListingQueryAndReportWidgetTypesInventory
        | ReportConfigOfSaleQueryAndReportWidgetTypesSale;
    }) => {
      const reportConfigId = originalReportConfig.reportId!;
      configOverride.schemaVersion = originalReportConfig.schemaVersion;

      // Set override to null if it is the same as the original report config
      // and the original report config does not have an override.
      if (
        !originalReportConfig.hasFilterOverride &&
        isEqual(originalReportConfig.filter, configOverride.filter)
      ) {
        configOverride.filter = null;
      }
      if (
        !originalReportConfig.hasMetricsOverride &&
        isEqual(originalReportConfig.metrics, configOverride.metrics)
      ) {
        configOverride.metrics = null;
      }

      if (reportType === ReportTypes.Inventory) {
        await reportClient.upsertInventoryReportConfigOverride(
          reportConfigId,
          configOverride as ReportConfigOverrideOfListingQuery
        );
      } else {
        await reportClient.upsertSaleReportConfigOverride(
          reportConfigId,
          configOverride as ReportConfigOverrideOfSaleQuery
        );
      }
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ originalReportConfig, configOverride }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue =
        queryClient.getQueryData<ReportConfigType<S>[]>(queryKey);

      const newReportConfig = {
        ...originalReportConfig,
        filter: configOverride.filter ?? originalReportConfig.filter,
        metrics: configOverride.metrics ?? originalReportConfig.metrics,
      } as ReportConfigType<S>;

      const newValue = prevValue?.map((r) =>
        r.reportId === newReportConfig.reportId ? newReportConfig : r
      );
      queryClient.setQueryData<ReportConfigType<S>[]>(queryKey, newValue);
      return { prevValue };
    },
    onError: (
      err: ErrorTypes,
      { originalReportConfig, configOverride },
      context
    ) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('reportClient.upsertReportConfig', err, {
        trackErrorData: { id, originalReportConfig, configOverride },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  // Mutation to upsert report config
  const upsertReportOverride = useCallback(
    async (
      configOverride:
        | ReportConfigOverrideOfListingQuery
        | ReportConfigOverrideOfSaleQuery,
      originalReportConfig:
        | ReportConfigOfListingQueryAndReportWidgetTypesInventory
        | ReportConfigOfSaleQueryAndReportWidgetTypesSale
    ) => {
      return await upsertOverrideMutation.mutateAsync({
        configOverride,
        originalReportConfig,
      });
    },
    [upsertOverrideMutation]
  );

  const upsertReportConfig = (
    reportConfig: ReportConfigType<S>,
    isTemporary?: boolean
  ) => {
    return upsertMutation.mutateAsync({ value: reportConfig, isTemporary });
  };

  // Mutation to delete report config
  const deleteMutation = useMutation({
    mutationFn: async ({ reportId }: { reportId: number }) => {
      return await reportClient.deleteReportConfig(reportId);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ reportId }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue =
        queryClient.getQueryData<ReportConfigType<S>[]>(queryKey);

      queryClient.setQueryData<ReportConfigType<S>[]>(
        queryKey,
        prevValue?.filter((r) => r.reportId !== reportId)
      );
      return { prevValue };
    },
    onError: (err: ErrorTypes, { reportId }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('reportClient.deleteReportConfig', err, {
        trackErrorData: { id, reportId },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const deleteReportConfig = (reportId: number) => {
    deleteMutation.mutateAsync({ reportId });
  };

  // Array of report config only used within the hook that has the type
  // which can be directly set to the user setting.
  const reportConfigsInternal: ReportConfigType<S>[] = _reportConfigs.filter(
    (r) => {
      return r.schemaVersion === '1';
    }
  );

  const reportConfigs: ReportConfig[] = reportConfigsInternal.map(
    (r) =>
      ({
        ...r,
        reportType,
      }) satisfies ReportConfig
  );

  const getReportConfigNameForDuplicate = useCallback(
    (reportName: string) => {
      const reportNames = reportConfigsInternal.map((r) => r.reportName);
      const defaultName = formattedContentResolver(
        FormatContentIds[FormatContentId.CopyOf].id,
        reportName,
        FormatContentIds[FormatContentId.CopyOf].defaultValue
      ) as string;
      if (!reportNames.includes(defaultName)) {
        return defaultName;
      }

      let newName = defaultName;
      let i = 2;
      while (reportNames.includes(newName)) {
        newName = `${defaultName} (${i})`;
        i++;
      }
      return newName;
    },
    [formattedContentResolver, reportConfigsInternal]
  );

  return {
    reportConfigs,
    isLoading: reportConfigQuery.isLoading,
    deleteReportConfig: (reportId: number) => {
      deleteReportConfig(reportId);
    },
    upsertReportConfig: (
      reportId: number | null,
      configInput: Partial<ReportConfigInput>,
      isTemporary?: boolean
    ) => {
      // Create new
      if (reportId == null) {
        // Force conversion is applied here because the type of the report config
        // Depends on the consumer to pass in the right set of widgets and filter types.
        const newConfig = castDraft({
          ...configInput,
          schemaVersion: '1',
          reportId: null,
        } as ReportConfigType<S>);

        return upsertReportConfig(newConfig as ReportConfigType<S>);
      }

      // Update existing
      const configToUpdate = reportConfigsInternal.find(
        (r) => r.reportId === reportId
      );
      if (!configToUpdate) {
        return new Promise((resolve) => resolve(null));
      }
      const initDraft = castDraft(configToUpdate as ReportConfigType<S>);

      const updatedDraft = produce(initDraft, (draft) => {
        if (configInput.reportName != null) {
          draft.reportName = configInput.reportName;
        }

        if (configInput.groupBy != null) {
          draft.groupBy = configInput.groupBy;
        }
        if (configInput.metrics != null) {
          draft.metrics = configInput.metrics;
        }
        draft.ownerDisplayName = configInput.ownerDisplayName ?? '';
        draft.widgets = castDraft(configInput.widgets ?? null);
        draft.filter = castDraft(configInput.filter ?? null);
        draft.numberOfItemsPerPage = configInput.numberOfItemsPerPage ?? null;
        draft.sortBy = configInput.sortBy ?? null;
        draft.isSortDescending = configInput.isSortDescending ?? null;
        draft.sellerUserIdsToShare = configInput.sellerUserIdsToShare ?? [];
        draft.roleIdsToShare = configInput.roleIdsToShare ?? [];
        draft.viewableFilterItemIds = configInput.viewableFilterItemIds ?? [];
        draft.editableFilterItemIds = configInput.editableFilterItemIds ?? [];
        draft.hiddenFilterItemIds = configInput.hiddenFilterItemIds ?? [];
      });

      return upsertReportConfig(
        updatedDraft as ReportConfigType<S>,
        isTemporary
      );
    },
    upsertReportOverride,
    getReportConfigNameForDuplicate,
  };
}
