/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash-es';
import {
  ChangeEvent,
  ComponentProps,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { PillItemProps } from 'src/core/ui/PillList/PillItem';
import { CheckIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

export type AddSectionRowsDialogProps = ComponentProps<typeof RSModal> & {
  onClose: () => void;
  onAddRows: (rows: string[]) => void;
};

export const AddSectionRowsDialog: React.FC<AddSectionRowsDialogProps> = ({
  onClose,
  onAddRows,
  ...rest
}) => {
  const [rowInput, setRowInput] = useState<string>('');
  const [rows, setRows] = useState<string[]>([]);

  const pills = useMemo(() => {
    return rows.map((row) => ({ value: row, display: row }) as PillItemProps);
  }, [rows]);

  const onSubmitChange = useCallback(() => {
    onAddRows(rows);
    onClose();
  }, [rows, onAddRows]);

  const onRowInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();
      setRowInput(event.target.value);
    },
    []
  );

  const onAddRow = useCallback(() => {
    if (!isEmpty(rowInput)) {
      setRows((prev) => {
        if (!prev.includes(rowInput)) {
          return [...prev, rowInput];
        }
        return prev;
      });
      setRowInput('');
    }
  }, [rowInput, setRows, setRowInput]);

  const onKeyUp = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
        if (!isEmpty(rowInput)) {
          setRows((prev) => {
            if (!prev.includes(rowInput)) {
              return [...prev, rowInput];
            }
            return prev;
          });
          setRowInput('');
        }
      }
    },
    [rowInput, setRows, setRowInput]
  );

  return (
    <GenericDialog
      {...rest}
      size="m"
      header={<Content id={ContentId.AddRow} />}
      footer={
        <Stack direction="row" gap="m">
          <CancelButton onClick={onClose} />
          <OkButton
            onClick={onSubmitChange}
            disabled={isEmpty(rows)}
            textContentId={ContentId.Save}
          />
        </Stack>
      }
      onCancel={onClose}
    >
      <PosTextField
        value={rowInput}
        onChange={onRowInputChange}
        onKeyUp={onKeyUp}
        postfixDisplay={
          !isEmpty(rowInput) && (
            <CheckIcon
              size={'20px'}
              fill={vars.color.textSuccess}
              onClick={onAddRow}
            />
          )
        }
      />
      {rows.length > 0 && (
        <PillList
          pills={pills}
          onPillDeleted={(value) =>
            setRows((prev) => prev.filter((v) => v !== value))
          }
          direction={'row'}
          flexWrap={'grow'}
        />
      )}
    </GenericDialog>
  );
};
