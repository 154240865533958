import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext/Content';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { InfoMessage } from 'src/core/POS/MessageWithIcon/InfoMessage';
import { BulkEditSeatTraitsDialog } from 'src/dialogs/BulkEdits/BulkEditSeatTraitsDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  ListingNote,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_SEAT_TRAITS_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchBulkUpdateSeatTraits } from './LaunchBulkUpdateSeatTraits';

export const useBulkUpdateSeatTraits = (
  filterQuery: ListingQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const updateKey = event
    ? `inventory-seat-traits-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_SEAT_TRAITS_UPDATE_KEY;

  const { showErrorDialog } = useErrorBoundaryContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const {
    openDialog: openBulkEditSeatTraitsDialog,
    closeDialog: closeBulkEditSeatTraitsDialog,
  } = useDialog(DialogId.BulkEditSeatTraits, BulkEditSeatTraitsDialog);

  const onSubmit = useCallback(
    async (
      seatTraits: ListingNote[] | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails,
      appendSeatTraits?: boolean
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const bulkEditClient = new BulkEditListingClient(
              activeAccountWebClientConfig
            );

            const preview =
              await bulkEditClient.bulkUpdateSeatTraitsPreview(filterQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateTagsPreview', error, {
              trackErrorData: { filterQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (seatTraits) {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateSeatTraits(
              {
                item1: preview!.preview,
                item2: seatTraits,
              },
              updateKey,
              supportBackgroundProcess,
              appendSeatTraits
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                refreshExpandedListItems();
              }

              closeBulkEditSeatTraitsDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateSeatTraits', error, {
              trackErrorData: {
                preview,
                seatTraits,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkEditSeatTraitsDialog,
      filterQuery,
      refreshExpandedListItems,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkUpdateSeatTraits
        key="updateSeatTraits"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkEditSeatTraitsDialog({
            header: <Content id={ContentId.UpdateSeatTraitsForPlaceholders} />,
            updateKey,
            isLoading,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkEditSeatTraitsDialog,
            entityType: ActionOutboxEntityType.Listing,
            infoMessage: (
              <InfoMessage
                message={
                  <Content id={ContentId.BulkUpdatePlaceholdersMessage} />
                }
              />
            ),
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
