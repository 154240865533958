import clsx from 'clsx';
import { useMemo } from 'react';
import { Content, useFormattedContent } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import * as styles from 'src/core/POS/DateRangeSelector/DateRangePresets/DateRangePresets.css';
import {
  Suggestion,
  SuggestionContainer,
  SuggestionsContainer,
  SuggestionsHeading,
} from 'src/core/POS/DateRangeSelector/DateRangePresets/DateRangePresets.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  DatePreset,
  DatePresetName,
  formatDate,
  getDatePresets,
  getPresetFromDate,
} from 'src/utils/dateTimeUtils';

export type DatePickerPresetsProp = {
  presetNames: DatePresetName[];
  inHandDaysBeforeEvent?: number;
  selectedDate?: Date | null;
  targetDate: Date;
  onClick: (date: Date, presetName?: string) => void;
  disabled?: boolean;
  /**
   * If true, the site's timezone will be applied to the date being displayed.
   * the underlying date value (value, data, onDateChange) will not be changed.
   */
  useSiteTimeZone?: boolean;
};

export function DatePickerPresets({
  selectedDate = new Date(),
  presetNames,
  targetDate,
  disabled,
  onClick,
  inHandDaysBeforeEvent,
  useSiteTimeZone,
}: DatePickerPresetsProp) {
  const { toZonedTime, fromZonedTime } = useSiteTimezoneContext();

  const handleClick = (preset: DatePreset) => {
    onClick(
      useSiteTimeZone ? fromZonedTime(preset.value) : preset.value,
      preset.name
    );
  };

  const presets = useMemo(() => {
    const presets = getDatePresets(
      presetNames,
      targetDate,
      inHandDaysBeforeEvent
    );

    if (useSiteTimeZone) {
      return presets.map((preset) => ({
        ...preset,
        value: toZonedTime(preset.value),
      }));
    }

    return presets;
  }, [
    useSiteTimeZone,
    inHandDaysBeforeEvent,
    presetNames,
    targetDate,
    toZonedTime,
  ]);

  const presetName = getPresetFromDate(
    selectedDate,
    targetDate,
    inHandDaysBeforeEvent
  )?.name;

  const daysBeforeEventLabel = useFormattedContent(
    FormatContentId.DaysBeforeEvent,
    `${inHandDaysBeforeEvent}`
  );

  return (
    <SuggestionsContainer>
      <SuggestionsHeading>
        <Content id={ContentId.DateRangeSuggestionsTitle} />
      </SuggestionsHeading>
      {presets.map((preset, index) => (
        <SuggestionContainer key={index}>
          <Suggestion
            className={clsx(styles.suggestion, {
              [styles.suggestionSelected]: presetName === preset.name,
            })}
            disabled={disabled}
            onClick={() => handleClick(preset)}
          >
            <span className={styles.suggestionText}>
              {preset.name === DatePresetName.DaysBeforeEvent
                ? daysBeforeEventLabel
                : preset.label}
            </span>{' '}
            <span>{formatDate(preset.value.toISOString())}</span>
          </Suggestion>
        </SuggestionContainer>
      ))}
    </SuggestionsContainer>
  );
}
