import { ProLogo } from '../../../components/common/ProLogo';
import {
  Header,
  HeaderLeftAreaDiv,
  HeaderWrapperDiv,
} from './MainNavTopBar.styled';

export function MainNavTopBarEmpty() {
  return (
    <HeaderWrapperDiv>
      <Header>
        <HeaderLeftAreaDiv>
          <ProLogo />
        </HeaderLeftAreaDiv>
      </Header>
    </HeaderWrapperDiv>
  );
}
