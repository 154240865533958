import { useAppContext } from 'src/contexts/AppContext';
import { hasPermissionForSale } from 'src/utils/userPermissionUtils';
import { Permission, Sale } from 'src/WebApiController';

export const useUserHasSalePermission = (
  sale: Sale | null | undefined,
  root: Permission,
  isFulfillerOf?: Permission,
  isPricerOf?: Permission,
  isPurchasedBy?: Permission
) => {
  const { loginContext } = useAppContext();

  const canUpdate = hasPermissionForSale(
    loginContext?.user,
    sale,
    root,
    isFulfillerOf,
    isPricerOf,
    isPurchasedBy
  );

  return canUpdate;
};

export const useUserCanUpdateSale = (sale: Sale | null | undefined) => {
  return useUserHasSalePermission(
    sale,
    Permission.Sales_UpdateSalesInfo,
    Permission.Sales_UpdateSalesInfoOnIsFulfillerOf,
    Permission.Sales_UpdateSalesInfoOnIsPricerOf,
    Permission.Sales_UpdateSaleOnPurchasedBy
  );
};

export const useUserCanUpdateSaleTags = (sale: Sale | null | undefined) => {
  const canSetPrice = useUserHasSalePermission(
    sale,
    Permission.Sales_UpdateTags,
    Permission.Sales_UpdateTagsOnIsFulfillerOf,
    Permission.Sales_UpdateTagsOnIsPricerOf,
    Permission.Sales_UpdateTagsOnPurchasedBy
  );
  return canSetPrice;
};

export const useUserCanFulfillSale = (sale: Sale | null | undefined) => {
  return useUserHasSalePermission(
    sale,
    Permission.Sales_Fulfill,
    Permission.Sales_FulfillOnIsFulfillerOf,
    Permission.Sales_FulfillOnPricerOf,
    Permission.Sales_FulfillOnPurchasedBy
  );
};

export const useUserCanViewFulfillmentArtifacts = (
  sale: Sale | null | undefined
) => {
  // It is assumed if you can fulfill - then you can view fulfillment artifacts
  const canFulfill = useUserCanFulfillSale(sale);

  const canView = useUserHasSalePermission(
    sale,
    Permission.Sales_ViewFulfillmentArtifacts,
    Permission.Sales_ViewFulfillmentArtifactsOnIsFulfillerOf,
    Permission.Sales_ViewFulfillmentArtifactsOnIsPricerOf,
    Permission.Sales_ViewFulfillmentArtifactsOnPurchasedBy
  );

  return canView || canFulfill;
};
