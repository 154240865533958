import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const DealUndercutIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 40 40">
      <g clipPath="url(#clip0_17_770)">
        <path
          d="M29.625 23.5L33.125 20L29.625 16.5V10.375H23.5L20 6.875L16.5 10.375H10.375V16.5L6.875 20L10.375 23.5V29.625H16.5L20 33.125L23.5 29.625H29.625V23.5Z"
          stroke={props.stroke ?? '#212121'}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.25 24.875L23.75 15.125"
          stroke={props.stroke ?? '#212121'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.375 18.875C18.4105 18.875 19.25 18.0355 19.25 17C19.25 15.9645 18.4105 15.125 17.375 15.125C16.3395 15.125 15.5 15.9645 15.5 17C15.5 18.0355 16.3395 18.875 17.375 18.875Z"
          stroke={props.stroke ?? '#212121'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.625 24.875C23.6605 24.875 24.5 24.0355 24.5 23C24.5 21.9645 23.6605 21.125 22.625 21.125C21.5895 21.125 20.75 21.9645 20.75 23C20.75 24.0355 21.5895 24.875 22.625 24.875Z"
          stroke={props.stroke ?? '#212121'}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_17_770">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(6 6)"
          />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
};
