import { useCallback } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  BulkEditListingPriceDialog,
  BulkEditListingPriceDialogProps,
} from 'src/dialogs/BulkEdits/BulkEditListingPriceDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  Event,
  ListingDetails,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_LISTING_PRICES_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchChangeListingPrice } from './LaunchChangeListingPrice';

export const useBulkChangeListingPriceAction = (
  filterQueryWithEventIds: ListingQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const bulkUpdateListingsPricesUpdateKey = event
    ? `listingPrices-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_LISTING_PRICES_UPDATE_KEY;

  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();

  const {
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();

  const { showErrorDialog } = useErrorBoundaryContext();

  const { openDialog: openBulkEditDialog, closeDialog: closeBulkEditDialog } =
    useDialog<BulkEditListingPriceDialogProps>(
      DialogId.BulkEditListingPrice,
      BulkEditListingPriceDialog
    );

  const onSubmitChangePriceByPercent = useCallback(
    async (
      priceChangePercentage: number | undefined,
      priceChangeAbsolute: number | undefined,
      increasePrice: boolean,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateListingsPricesPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateListingsPricesPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            let succeeded: BulkEditProgress | undefined;
            if (priceChangePercentage !== undefined) {
              succeeded = await new BulkEditListingClient(
                activeAccountWebClientConfig
              ).bulkUpdateListingsPricesByPercent(
                preview!.preview,
                (increasePrice
                  ? 100 + priceChangePercentage
                  : 100 - priceChangePercentage) /
                  100 /* we're sending the percent back in 0 - 1 ratio */,
                bulkUpdateListingsPricesUpdateKey,
                supportBackgroundProcess
              );
            } else if (priceChangeAbsolute !== undefined) {
              succeeded = await new BulkEditListingClient(
                activeAccountWebClientConfig
              ).bulkUpdateListingsPrices(
                preview!.preview,
                increasePrice ? priceChangeAbsolute : -priceChangeAbsolute,
                bulkUpdateListingsPricesUpdateKey,
                supportBackgroundProcess
              );
            }

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }

              closeBulkEditDialog();
            }
          },
          (error) => {
            showErrorDialog('onSubmitChangePrice', error, {
              trackErrorData: {
                priceChangePercentage,
                priceChangeAbsolute,
                increasePrice,
                preview,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQueryWithEventIds,
      showErrorDialog,
      bulkUpdateListingsPricesUpdateKey,
      closeBulkEditDialog,
      setActivePosEntity,
      refreshExpandedListItems,
    ]
  );

  const onChangePriceByPercentClick = useCallback(
    (e: React.MouseEvent, increasePrice: boolean) => {
      e.preventDefault();
      e.stopPropagation();
      if (event) {
        setListItemExpansion(true, [event.viagVirtualId]);
      }
      openBulkEditDialog({
        updateKey: bulkUpdateListingsPricesUpdateKey,
        increasePrice,
        onOkay: onSubmitChangePriceByPercent,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkEditDialog,
      });
    },
    [
      bulkUpdateListingsPricesUpdateKey,
      closeBulkEditDialog,
      event,
      onSubmitChangePriceByPercent,
      openBulkEditDialog,
      setIsLoading,
      setListItemExpansion,
    ]
  );
  return {
    dropDown: (
      <LaunchChangeListingPrice
        key="ChangeListingPrice"
        onClick={onChangePriceByPercentClick}
        disabled={isLoading}
      />
    ),
  };
};
