import { AutoPricingOutlierMode } from "src/WebApiController";
import { useAutoPricingSettingsFormContext } from "../useAutoPricingSettingsFormContext";
import { ContentId } from "src/utils/constants/contentId";
import { PosEnumSelect } from "src/core/POS/PosSelect";

export const AutoPricingOutlierDetectionModeSelector = ({
  outlierModes,
  outlierModesDisabled,
  disabled,
}: {
  outlierModes: Record<string, ContentId>,
  outlierModesDisabled?: Record<string, ContentId | undefined>,
  disabled: boolean,
}) => {
  const {
    onOutlierSettingsChange,
    watch,
  } = useAutoPricingSettingsFormContext();

  const { outlierMode } = watch();

  return (
    <PosEnumSelect
      style={{ width: 'fit-content' }}
      value={outlierMode}
      disabled={disabled}
      valueOptionsContent={outlierModes}
      valueOptionsDisabled={outlierModesDisabled}
      enableEmptySelection={false}
      onClick={(e) => e.stopPropagation()}
      onChange={(v) => {
        onOutlierSettingsChange({
          outlierModeNew: v as AutoPricingOutlierMode,
      });
      }}
    />
  );
};