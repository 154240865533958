import clsx from 'clsx';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { CheckIcon, IconsFill } from 'src/svgs/Viagogo';

import * as styles from './FormatOptionPill.css';

/**
 * Still work in progress
 */
export const FunctionOptionPill = () => {
  const formatOptionPillBase = <FunctionOptionPillBase />;

  return <FunctionOptionPillSelect trigger={formatOptionPillBase} />;
};

export const FunctionOptionPillSelect = ({
  trigger,
}: {
  trigger: React.ReactNode;
}) => {
  return (
    <PosDropdown
      key="function-option-select-dropdown"
      trigger={trigger}
      align="end"
    >
      <PosDropdownItem
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {'SUM'}
        {true && (
          <CheckIcon size={vars.iconSize.s} fill={IconsFill.textSuccess} />
        )}
      </PosDropdownItem>
    </PosDropdown>
  );
};

export const FunctionOptionPillBase = ({
  onClick,
}: {
  onClick?: () => void;
}) => {
  return (
    <Button
      className={clsx(styles.root, { [styles.selected]: true })}
      onClick={onClick}
      size="md"
    >
      {'SUM'}
    </Button>
  );
};
