export enum MarketplacePaymentsTableColumnId {
  PaymentDate = 'paymentDate',
  ExternalPaymentID = 'externalPaymentID',
  PaymentReferenceID = 'paymentReferenceID',
  State = 'state',
  PaymentAmount = 'paymentAmount',
  CreditAmount = 'creditAmount',
  ChargeAmount = 'chargeAmount',
  ProceedsAmount = 'proceedsAmount',
  ReceivedDate = 'receivedDate',
  Marketplace = 'Marketplace',
}
