import { MouseEvent, useContext } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { Button } from 'src/core/ui';
import { AdCampaignCreateModal } from 'src/modals/AdCampaignCreate/AdCampaignCreate';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './AdCampaignsToolbar.css';
import {
  AdCampaignsToolbarDiv,
  FilterBarLeftSide,
  FilterBarRightSide,
} from './AdCampaignsToolbar.styled';

export type AdCampaignsToolbarType = {
  disabled?: boolean;
  onColumnSettingButtonClickHandler?: (
    e: MouseEvent<HTMLButtonElement>
  ) => void;
};

export function AdCampaignsToolbar() {
  const { setModal } = useContext(ModalContext);

  return (
    <AdCampaignsToolbarDiv className={styles.adCampaignsFilterBar}>
      <FilterBarLeftSide />
      <FilterBarRightSide>
        <Button
          className="add-adCampaign"
          variant="regular"
          onClick={() => setModal(AdCampaignCreateModal)}
        >
          <Content id={ContentId.AddAdCampaign} />
        </Button>
      </FilterBarRightSide>
    </AdCampaignsToolbarDiv>
  );
}
