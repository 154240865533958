/* eslint-disable react-hooks/exhaustive-deps */
import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useBulkEditHubContext } from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { EconomicsSection } from 'src/modals/ListingDetails/components';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getListingDetailsUpdateInput,
  isListingDetailsInputChanged,
} from 'src/utils/inventoryUtils';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  Event,
  Listing,
  ListingDetails,
  ListingDetailsUpdateInput,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkEditMarketplacePricesDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  listings?: Listing[] | null;
  selectedListingIds?: number[];
  event?: Event;
  onOkay: (
    listing: ListingDetailsUpdateInput | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

export const BulkEditMarketplacePricesDialog = ({
  listings,
  ...rest
}: BulkEditMarketplacePricesDialogProps) => {
  const { loginContext } = useAppContext();
  const methods = useForm<ListingDetailsUpdateInput>({
    defaultValues: getListingDetailsUpdateInput(
      null,
      null,
      loginContext?.user?.activeAccount?.marketplaceSettings
    ),
  });

  return (
    <FormProvider {...methods}>
      <BulkEditMarketplacePricesDialogContent
        listings={listings}
        {...rest}
        {...methods}
      />
    </FormProvider>
  );
};

function BulkEditMarketplacePricesDialogContent({
  updateKey,
  listings,
  selectedListingIds,
  event,
  onOkay,
  onCancel,
  handleSubmit,
  getValues,
  clearErrors,
  setError,
  setValue,
  watch,
  reset,
  formState,
  ...rest
}: BulkEditMarketplacePricesDialogProps &
  Omit<
    ComponentProps<typeof FormProvider<ListingDetailsUpdateInput, unknown>>,
    'children'
  >) {
  const { loginContext } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);
  const input = watch();

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshExpandedListItems();
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, stage, preview, initJob } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.UpdateMarketplacePrices,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(null, false, onPreviewReceived);
      } else {
        handleSubmit((i) =>
          onOkay(i, hasBackgroundBulkEditFeature, undefined, preview)
        )();
      }
      setIsLoading(false);
    },
    [stage, onOkay, preview, hasBackgroundBulkEditFeature]
  );

  const isInputValid = useMemo(() => {
    return isListingDetailsInputChanged(
      input,
      formState.defaultValues as ListingDetailsUpdateInput
    );
  }, [input, formState.defaultValues]);

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <GenericDialog
        {...rest}
        size="xl"
        header={
          <BulkEditHeader
            headerText={<Content id={ContentId.AllMarketplaces} />}
          />
        }
        onOpened={() => {
          reset(
            getListingDetailsUpdateInput(
              null,
              null,
              loginContext?.user?.activeAccount?.marketplaceSettings
            )
          );
          initJob();
        }}
        onKeyUp={(e) => {
          if (submittButtonRef.current && e.key === 'Enter' && isInputValid) {
            submittButtonRef.current.click();
          }
        }}
        onClosed={() => {
          setMainDialogOpened(false);
          if (progress) {
            setSelectionMode(undefined);
          }
          // Call the outside one if there is one
          rest.onClosed?.();
        }}
        footer={
          <BulkEditFooter
            entityType={ActionOutboxEntityType.Listing}
            isLoading={isLoading}
            hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
            onClose={onClose}
            onSubmit={onSubmitHandler}
            disabled={!isInputValid}
            submittButtonRef={submittButtonRef}
          />
        }
        onCancel={isLoading ? undefined : onCancel}
      >
        <BulkEditStatus
          entityType={ActionOutboxEntityType.Listing}
          isLoading={isRefreshing || isLoading}
          updateKey={updateKey}
        >
          <Stack direction="column" width="full" gap="s">
            <EconomicsSection
              disabled={isLoading}
              activeEvent={event}
              displayMode={'PRICE_ONLY'}
              isBulkEdit
            />
          </Stack>
        </BulkEditStatus>
      </GenericDialog>
    </>
  );
}
