import { Fragment, PropsWithChildren, ReactNode } from 'react';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';

import * as styles from './MultiDetailSection.css';

type MultiDetailSectionProps = PropsWithChildren<{
  name: ReactNode;
  extraContent: ReactNode[];
}>;

export const MultiDetailSection = ({
  name,
  children,
  extraContent,
}: MultiDetailSectionProps) => {
  const isMobile = useMatchMedia('mobile');

  return (
    <Stack direction="column" gap="l" className={styles.detailSection}>
      <Stack direction={isMobile ? 'column' : 'row'} gap="l" alignItems="start">
        <Stack
          direction={isMobile ? 'row' : 'column'}
          gap="m"
          className={isMobile ? undefined : styles.sectionHeaderContainer}
        >
          <span className={styles.sectionHeader}>{name}</span>
        </Stack>
        <Stack
          direction="column"
          gap="m"
          className={styles.detailSectionContent}
        >
          {children}
        </Stack>
      </Stack>
      {extraContent &&
        extraContent.map((content, index) => (
          <Fragment key={index}>{content}</Fragment>
        ))}
    </Stack>
  );
};
