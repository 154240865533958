import { useCallback, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { EmptyVendorAccountId } from 'src/dialogs/PurchaseVendorAccountDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  PurchaseClient,
  PurchaseVendorAccount,
  PurchaseVendorAccountDetails,
} from 'src/WebApiController';

export type OnVendorAccountSaveArgs = {
  vendorAccount: PurchaseVendorAccount;
  isNew: boolean;
};

export type VendorAccountEditInfo = {
  vendorId: number;
  id: string;
};

export type OnVendorAccountSavedFn = (value: OnVendorAccountSaveArgs) => void;

interface UseAddVendorAccountProps {
  onVendorAccountSaved?: OnVendorAccountSavedFn;
}

export const useAddVendorAccount = (props?: UseAddVendorAccountProps) => {
  const onVendorAccountSaved = props?.onVendorAccountSaved;

  // Purchase Vendor Account
  const [currentVendorAccount, setCurrentVendorAccount] =
    useState<VendorAccountEditInfo>();
  const venderAccountDialog = useBasicDialog();
  const { closeDialog, launchDialog } = venderAccountDialog;
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const onVendorAccountCancel = useCallback(() => {
    setCurrentVendorAccount(undefined);
    closeDialog();
  }, [closeDialog]);

  const onSaveVendorAccount = useCallback(
    async (vendorAccount: PurchaseVendorAccountDetails) => {
      await tryInvokeApi(
        async () => {
          const newId = await new PurchaseClient(
            activeAccountWebClientConfig
          ).mergeVendorAccount(vendorAccount);

          if (newId) {
            const va = {
              ...vendorAccount,
              id: newId,
            };
            onVendorAccountSaved?.({
              vendorAccount: va,
              isNew: vendorAccount.id === EmptyVendorAccountId,
            });
          } else {
            // This should never happen
            // Normal errors would have fallen to the error catch
            showErrorDialog(
              'PurchaseClient.mergeVendorAccount',
              {
                message:
                  'Unable to create or save vendorAccount ' + vendorAccount.id,
                status: 500,
              } as ApiException,
              {
                trackErrorData: vendorAccount,
              }
            );
          }
        },
        (error) => {
          showErrorDialog('PurchaseClient.mergeVendorAccount', error, {
            trackErrorData: vendorAccount,
          });
        },
        onVendorAccountCancel
      );
    },
    [
      onVendorAccountCancel,
      activeAccountWebClientConfig,
      onVendorAccountSaved,
      showErrorDialog,
    ]
  );

  const onAddNewVendorAccount = useCallback(
    (vendorId: number) => {
      setCurrentVendorAccount({
        vendorId: vendorId,
        id: EmptyVendorAccountId,
      });
      venderAccountDialog.launchDialog();
    },
    [venderAccountDialog]
  );

  const onEditVendorAccount = useCallback(
    (vendorAccount: PurchaseVendorAccount) => {
      setCurrentVendorAccount(vendorAccount);
      launchDialog();
    },
    [launchDialog]
  );

  return {
    currentVendorAccount,
    onAddNewVendorAccount,
    onEditVendorAccount,
    onSaveVendorAccount,
    onVendorAccountCancel,
    venderAccountDialog,
  };
};
