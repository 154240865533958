import { isEqual } from 'lodash';
import { useEffect, useMemo } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { shared } from 'src/core/themes';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import {
  SaleCostType,
  SaleLineItem,
  SaleLineItemStatus,
} from 'src/WebApiController';

import * as styles from './SaleLineItemModal.css';
import { AddLineItemModalBodyProps as SaleLineItemModalBodyProps } from './SaleLineItemModal.types';

const SaleLineItemModalBody = ({
  isOpen,
  onClose,
  onSave,
  currencyCode,
}: SaleLineItemModalBodyProps & UseFormReturn<SaleLineItem>) => {
  const itemNameText = useContent(ContentId.ItemName);
  const amountText = useContent(ContentId.Amount);
  const requiredText = useContent(ContentId.Required);

  const { control, reset, register, watch, setValue, handleSubmit, formState } =
    useFormContext<SaleLineItem>();

  const name = watch('description');
  const cost = watch('cost');
  const costType = watch('costType');
  const status = watch('status');
  const costDate = watch('costDate');
  const dueDate = watch('dueDate');

  const { getUiCurrency } = useLocalizationContext();

  const uiCurrency = useMemo(
    () => getUiCurrency(currencyCode),
    [getUiCurrency, currencyCode]
  );

  return (
    <Modal isOpen={isOpen} size="sm" centered>
      <ModalHeader onClose={onClose}>
        <div className={styles.headerBar}>
          <h5 className={`${shared.typography.title5} ${styles.headerText}`}>
            <Content id={ContentId.AddChargeOrCredit} />
          </h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <Stack direction="column" className={styles.bodyContainer} gap="l">
          <PosFormField
            label={itemNameText}
            errors={formState.errors.description?.message}
          >
            <PosTextField
              rootProps={{
                state: getTextFieldState(formState.errors.description),
              }}
              {...register('description', { required: requiredText })}
              value={name ?? ''}
            />
          </PosFormField>
          <RadioGroup
            className={styles.radioGroup}
            value={costType}
            onValueChange={(v) => setValue('costType', v as SaleCostType)}
          >
            <Radio
              label={<Content id={ContentId.Charge} />}
              value={SaleCostType.Charge}
            />
            <Radio
              label={<Content id={ContentId.Credit} />}
              value={SaleCostType.Credit}
            />
          </RadioGroup>
          <PosFormField
            label={amountText}
            errors={formState.errors.cost?.message}
          >
            <PosCurrencyField
              rootProps={{
                state: getTextFieldState(formState.errors.cost),
              }}
              value={cost}
              uiCurrency={uiCurrency}
              {...register('cost', {
                required: !(cost > 0) ? requiredText : undefined,
                valueAsNumber: true,
              })}
            />
          </PosFormField>
          <RadioGroup
            className={styles.radioGroup}
            value={status}
            onValueChange={(v) => setValue('status', v as SaleLineItemStatus)}
          >
            <Radio
              label={<Content id={ContentId.Paid} />}
              value={SaleLineItemStatus.Paid}
            />
            <Radio
              label={<Content id={ContentId.Unpaid} />}
              value={SaleLineItemStatus.Unpaid}
            />
          </RadioGroup>
          <Stack gap="l">
            <PosFormField
              label={<Content id={ContentId.DateIncurred} />}
              errors={formState.errors.costDate?.message}
            >
              <Controller
                name={'costDate'}
                rules={{ required: !costDate ? requiredText : undefined }}
                control={control}
                render={({ field: { ...field } }) => (
                  <DatePickerInput
                    fieldError={formState.errors.costDate?.message}
                    locale={getLocaleFromLanguageOrCurrent()}
                    {...field}
                    onDateChange={(d) => {
                      setValue(field.name, d.toISOString());
                    }}
                  />
                )}
              />
            </PosFormField>
            <PosFormField
              label={<Content id={ContentId.DateDue} />}
              errors={formState.errors.dueDate?.message}
            >
              <Controller
                name={'dueDate'}
                rules={{ required: !dueDate ? requiredText : undefined }}
                control={control}
                render={({ field: { ...field } }) => (
                  <DatePickerInput
                    fieldError={formState.errors.dueDate?.message}
                    locale={getLocaleFromLanguageOrCurrent()}
                    {...field}
                    onDateChange={(d) => {
                      setValue(field.name, d.toISOString());
                    }}
                  />
                )}
              />
            </PosFormField>
          </Stack>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <CancelButton
          textContentId={ContentId.Cancel}
          onClick={() => {
            onClose();
            reset();
          }}
        />
        <OkButton
          textContentId={ContentId.Save}
          onClick={() => {
            handleSubmit(onSave)();
            reset();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export const SaleLineItemModal = (props: SaleLineItemModalBodyProps) => {
  const methods = useForm<SaleLineItem>({
    defaultValues: props.item ?? {
      costType: SaleCostType.Charge,
      status: SaleLineItemStatus.Unpaid,
      costDate: new Date().toLocaleDateString(),
      dueDate: new Date().toLocaleDateString(),
      currencyCode: props.currencyCode,
    },
  });

  useEffect(() => {
    if (props.item && !isEqual(props.item, methods.formState.defaultValues)) {
      methods.reset(props.item);
    } else if (!props.item) {
      methods.reset({
        costType: SaleCostType.Charge,
        status: SaleLineItemStatus.Unpaid,
        costDate: new Date().toLocaleDateString(),
        dueDate: new Date().toLocaleDateString(),
        currencyCode: props.currencyCode,
      });
    }
  }, [methods, props.currencyCode, props.item]);

  return (
    <FormProvider {...methods}>
      <SaleLineItemModalBody {...methods} {...props} />
    </FormProvider>
  );
};
