import { isEqual } from 'lodash-es';
import { useMemo } from 'react';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { DateRangePresetName } from 'src/utils/dateTimeUtils';
import {
  ActionType,
  AuditActionType,
  DateTimeRangeWithRelative,
} from 'src/WebApiController';

import { FilterToolbar, FilterToolbarItemId } from '../FilterToolbar';
import * as styles from './ActivityLog.css';

export type ActivityLogFilterQuery = {
  date?: DateTimeRangeWithRelative | null;
  actionTypes: ActionType[];
  auditActionTypes: AuditActionType[];
  initiatedBy: string[];
};

export type ActivityLogFilterProps = {
  currentFilter: ActivityLogFilterQuery;
  onCurrentFilterUpdated: (settings: ActivityLogFilterQuery) => void;
  allActionTypes?:
    | {
        actionType: ActionType;
        auditActionType?: AuditActionType | null;
        display: string;
      }[]
    | undefined;
  allUsers: (string | null)[] | undefined;
};

export function ActivityLogFilter({
  currentFilter,
  onCurrentFilterUpdated,
  allActionTypes,
  allUsers,
}: ActivityLogFilterProps) {
  const getAppliedFilterCounts = () => {
    let count = 0;

    if (currentFilter.date != null) {
      count++;
    }
    // Two filters are combined into one in UI
    if (
      currentFilter.actionTypes.length > 0 ||
      currentFilter.auditActionTypes.length > 0
    ) {
      count++;
    }
    if (currentFilter.initiatedBy.length > 0) {
      count++;
    }

    return count;
  };

  const availableUsers = useMemo(
    () =>
      (allUsers ?? [])
        ?.filter((x) => x != null)
        .sort((a, b) =>
          a == null || b == null
            ? 1
            : a.localeCompare(b, undefined, {
                sensitivity: 'base',
              })
        )
        .reduce(
          (acc, item) => {
            acc[item!] = item!;
            return acc;
          },
          {} as Record<string, string>
        ),
    [allUsers]
  );

  const availableActionTypesWithAudit = useMemo(
    () =>
      (allActionTypes ?? [])
        .sort((a, b) =>
          a == null || b == null
            ? 1
            : a.toString().localeCompare(b.toString(), undefined, {
                sensitivity: 'base',
              })
        )
        .reduce(
          (acc, item) => {
            if (item.auditActionType) {
              acc[item.auditActionType] = item.display;
            } else {
              acc[item.actionType] = item.display;
            }
            return acc;
          },
          {} as Record<string, string>
        ),
    [allActionTypes]
  );

  const defaultFilter = {
    actionTypes: [],
    auditActionTypes: [],
    initiatedBy: [],
  };
  return (
    <div className={styles.activityLogFilterContainerDiv}>
      <FilterToolbar
        showAllButton={false} // TODO: clean this up and probably remove this prop
        showCustomFilterButton={false}
        isAllSelected={isEqual(currentFilter, defaultFilter)}
        onResetAll={() => onCurrentFilterUpdated(defaultFilter)}
        filterAppliedCounts={getAppliedFilterCounts()}
        mandatoryFiltersToShow={[]}
        filters={[
          {
            items: [
              {
                filterId: 'date' as FilterToolbarItemId,
                labelContentId: ContentId.Date,
                filterQueryKeys: ['date'] as FilterToolbarItemId[],
                filterItem: (
                  <DateRangeSelector
                    presetNames={[
                      DateRangePresetName.Today,
                      DateRangePresetName.ThisWeek,
                      DateRangePresetName.ThisMonth,
                    ]}
                    value={currentFilter.date}
                    onChange={(newValue) =>
                      onCurrentFilterUpdated({
                        ...currentFilter,
                        date: newValue ?? null,
                      })
                    }
                  />
                ),
              },
              {
                filterId: 'auditActionTypes' as FilterToolbarItemId,
                labelContentId: ContentId.ActivityLogActionType,
                filterQueryKeys: [
                  'actionTypes',
                  'auditActionTypes',
                ] as FilterToolbarItemId[],
                filterItem: (
                  <PosMultiSelect
                    align="start"
                    triggerProps={{ style: { width: '100%' } }}
                    values={[
                      ...currentFilter.actionTypes,
                      ...currentFilter.auditActionTypes,
                    ]}
                    placeholderText={ContentId.All}
                    searchable={
                      Object.keys(availableActionTypesWithAudit).length > 10
                    }
                    onChange={(newValues) => {
                      if (newValues) {
                        const actionTypes = newValues.filter((x) =>
                          Object.keys(ActionType).includes(x)
                        );
                        const auditActionTypes = newValues.filter((x) =>
                          Object.keys(AuditActionType).includes(x)
                        );
                        onCurrentFilterUpdated({
                          ...currentFilter,
                          actionTypes: actionTypes as ActionType[],
                          auditActionTypes:
                            auditActionTypes as AuditActionType[],
                        });
                      } else {
                        onCurrentFilterUpdated({
                          ...currentFilter,
                          actionTypes: [],
                          auditActionTypes: [],
                        });
                      }
                    }}
                    valueOptionsContent={availableActionTypesWithAudit}
                  />
                ),
              },
              {
                filterId: 'initiatedBy' as FilterToolbarItemId,
                labelContentId: ContentId.ActivityLogInitiatedBy,
                filterQueryKeys: ['initiatedBy'] as FilterToolbarItemId[],
                filterItem: (
                  <PosMultiSelect
                    align="start"
                    triggerProps={{ style: { width: '100%' } }}
                    values={currentFilter.initiatedBy}
                    placeholderText={ContentId.Everyone}
                    searchable={Object.keys(availableUsers).length > 10}
                    onChange={(newValues) => {
                      if (newValues.length) {
                        onCurrentFilterUpdated({
                          ...currentFilter,
                          initiatedBy: newValues,
                        });
                      } else {
                        onCurrentFilterUpdated({
                          ...currentFilter,
                          initiatedBy: [],
                        });
                      }
                    }}
                    valueOptionsContent={availableUsers}
                  />
                ),
              },
            ],
          },
        ]}
      />
    </div>
  );
}
