import { IconButton } from 'src/components/Buttons';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { PurchaseVendorsWithActions } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsTable/VendorsTableColumnsConfig';
import { OpenLinkIcon } from 'src/svgs';
import { EditIcon, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

interface VendorActionsCellProps {
  vendorWithActions: PurchaseVendorsWithActions | null;
}

export const VendorActionsCell = ({
  vendorWithActions,
}: VendorActionsCellProps) => {
  if (!vendorWithActions) {
    return null;
  }

  return (
    <Stack justifyContent="end">
      <IconButton
        icon={<PlusIcon withHoverEffect />}
        onClick={() =>
          vendorWithActions.vendorActions.addNewVendorAccount(
            vendorWithActions.vendor
          )
        }
        titleContentId={ContentId.AddAccountAction}
      />
      <IconButton
        icon={
          <OpenLinkIcon
            size={vars.iconSize.m}
            withHoverEffect
            fill={vars.color.textPrimary}
          />
        }
        onClick={() =>
          vendorWithActions.vendorActions.viewVendorDetails(
            vendorWithActions.vendor
          )
        }
        titleContentId={ContentId.ViewDetails}
      />
      <IconButton
        icon={<EditIcon withHoverEffect />}
        onClick={() =>
          vendorWithActions.vendorActions.editVendor(vendorWithActions.vendor)
        }
        titleContentId={ContentId.Edit}
      />
    </Stack>
  );
};
