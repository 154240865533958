import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature, Permission } from 'src/WebApiController';

export const LaunchBulkUpdateDeliveryType = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const hasPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_ManageSeatSaver,
    Permission.Inventory_ManageSeatSaverCreatedBy
  );

  const hasSeatSaverPermission = useUserHasFeature(Feature.SeatSaver);

  if (!hasPermission || !hasSeatSaverPermission) {
    return null;
  }

  return (
    <>
      <PosDropdownItem
        key="update-delivery-type-event-inventory"
        onClick={onClick}
        disabled={disabled}
      >
        <Stack gap="m" alignItems="center" width="full">
          <Content id={ContentId.UpdateDeliveryTypeForPlaceholders} />
        </Stack>
      </PosDropdownItem>
    </>
  );
};
