import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Content } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableShimmeringDiv } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { sortByNumericPrefix } from 'src/utils/tableUtils';
import { EventSectionClearingPrices, UiMoney } from 'src/WebApiController';

export enum EventSectionClearingPricesTableColumnId {
  SectionName = 'sectionName',
  P01 = 'p01',
  P25 = 'p25',
  P50 = 'p50',
  P75 = 'p75',
  P99 = 'p99',
}

const getMoneyCell = ({
  getValue,
}: CellContext<EventSectionClearingPricesTableColumnType, UiMoney>) => {
  const value = getValue();
  if (!value) {
    return <TableShimmeringDiv />;
  }

  const displayValue =
    applyNumberFormatting({
      inputNumber: value.amt,
      currencyCode: value.currency,
      currencyDecimalDigits: 0,
    }) ?? undefined;

  return <TableCellDiv align="left">{displayValue}</TableCellDiv>;
};

export type EventSectionClearingPricesTableColumnType =
  EventSectionClearingPrices | null;

export const EVENT_SECTION_CLEARING_PRICES_TABLE_COLUMNS_CONFIG: ColumnDef<
  EventSectionClearingPricesTableColumnType,
  any
>[] = [
  {
    id: EventSectionClearingPricesTableColumnId.SectionName,
    header: () => (
      <TableCellDiv align="left">
        <Content id={ContentId.Section} />
      </TableCellDiv>
    ),
    accessorFn: (data) => data?.sectionName,
    cell: ({ getValue }) => (
      <TableCellDiv align="left">{getValue<string>()}</TableCellDiv>
    ),
    sortingFn: sortByNumericPrefix<EventSectionClearingPricesTableColumnType>,
  },
  {
    id: EventSectionClearingPricesTableColumnId.P01,
    header: () => <TableCellDiv align="left">1st</TableCellDiv>,
    accessorFn: (data) => data?.p01,
    cell: getMoneyCell,
  },
  {
    id: EventSectionClearingPricesTableColumnId.P25,
    header: () => <TableCellDiv align="left">25th</TableCellDiv>,
    accessorFn: (data) => data?.p25,
    cell: getMoneyCell,
  },
  {
    id: EventSectionClearingPricesTableColumnId.P50,
    header: () => <TableCellDiv align="left">50th</TableCellDiv>,
    accessorFn: (data) => data?.p50,
    cell: getMoneyCell,
  },
  {
    id: EventSectionClearingPricesTableColumnId.P75,
    header: () => <TableCellDiv align="left">75th</TableCellDiv>,
    accessorFn: (data) => data?.p75,
    cell: getMoneyCell,
  },
  {
    id: EventSectionClearingPricesTableColumnId.P99,
    header: () => <TableCellDiv>99th</TableCellDiv>,
    accessorFn: (data) => data?.p99,
    cell: getMoneyCell,
  },
];
