import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMeasure } from 'react-use';
import {
  RowRelativeScoreDisplay,
  RowRelativeScoreInfo,
} from 'src/components/Events/RowRelativeScoreDisplay';
import { SerializedSegment } from 'src/core/POS/BezierGraph';
import { CalcOutputFn } from 'src/core/POS/BezierGraph/BezierGraphManager';
import { GraphSegments } from 'src/modals/EventSeatMapConfig/EventSeatMapConfigBody';
import {
  RowInfo,
  SectionInfo,
  SectionScoreOverride,
} from 'src/WebApiController';

import {
  ChangeRowScoreBody,
  DEFAULT_ROW_GAP_PX,
  getUpdatedRowData,
  GRAPH_WIDTH_PERCENTAGE,
  INITIAL_ROW_SCORE_SEGMENTS,
  ROW_GAP_RATIO,
} from './ChangeRowScoreBody';
import * as styles from './ChangeRowScoreDialog.css';

export const rowScoreFilter = ({ isSpeculative, ordinal }: RowInfo) =>
  !isSpeculative && ordinal != null;

export const rowScoreOverrideFilter =
  (speculativeRowIds: number[]) =>
  ({ rowId }: SectionScoreOverride) =>
    !speculativeRowIds.includes(rowId);

export type ChangeRowScoreBodyMultiSectionProps = {
  selectedSections: SectionInfo[];
  rowDataToEdit: RowRelativeScoreInfo[];
  setRowDataToEdit: React.Dispatch<
    React.SetStateAction<RowRelativeScoreInfo[]>
  >;
  configPayload: string | null;
  setConfigPayload: (configPayload: string | null) => void;
  initialSegmentsFromConfig: SerializedSegment[] | undefined;
};

export const ChangeRowScoreBodyMultiSection = ({
  selectedSections,
  rowDataToEdit,
  setRowDataToEdit,
  configPayload,
  setConfigPayload,
  initialSegmentsFromConfig,
}: ChangeRowScoreBodyMultiSectionProps) => {
  const [singleRowRef, { height: rowHeight }] = useMeasure<HTMLDivElement>();
  const gapHeight: number = useMemo(() => {
    if (!rowHeight) {
      return DEFAULT_ROW_GAP_PX;
    }
    return rowHeight * ROW_GAP_RATIO;
  }, [rowHeight]);

  const calcScoreOutputRef = useRef<CalcOutputFn>();
  const exportScorePathRef = useRef<() => SerializedSegment[]>();

  const onUpdateRowScores = useCallback(
    (segments: paper.Segment[]) => {
      const calcScoreOutput = calcScoreOutputRef.current;
      if (calcScoreOutput) {
        const newRowDataToEdit = getUpdatedRowData(
          segments,
          calcScoreOutput,
          rowDataToEdit,
          rowHeight,
          gapHeight
        );
        setRowDataToEdit(newRowDataToEdit);

        const configObj = configPayload
          ? (JSON.parse(configPayload) as GraphSegments)
          : {};

        configObj.scoreSegmentsBySection =
          configObj.scoreSegmentsBySection ?? {};
        configObj.activeRowIdsBySection = configObj.activeRowIdsBySection ?? {};
        configObj.baseRowIdBySection = configObj.baseRowIdBySection ?? {};

        selectedSections.forEach((s) => {
          const sectionId = s.id;
          const filteredRows = s.rows.filter(rowScoreFilter);

          configObj.scoreSegmentsBySection![sectionId.toString()] =
            exportScorePathRef.current?.();
          configObj.activeRowIdsBySection![sectionId.toString()] = rowDataToEdit
            .map(
              (r) => filteredRows.find((row) => row.ordinal === r.ordinal)?.id
            )
            .filter((r) => r !== undefined) as number[];

          const baseRowOrdinal = rowDataToEdit.find((r) => r.isBase)?.ordinal;
          const baseRowId = filteredRows.find(
            (r) => r.ordinal === baseRowOrdinal
          )?.id;
          configObj.baseRowIdBySection![sectionId.toString()] = baseRowId;
        });

        setConfigPayload(JSON.stringify(configObj));
      }
    },
    [
      configPayload,
      gapHeight,
      rowDataToEdit,
      rowHeight,
      selectedSections,
      setConfigPayload,
      setRowDataToEdit,
    ]
  );

  const rowList = rowDataToEdit.map((r, i) => (
    <RowRelativeScoreDisplay
      key={i}
      ref={i === 0 ? singleRowRef : undefined}
      rowRelativeScoreInfo={r}
      graphWidthPercentage={GRAPH_WIDTH_PERCENTAGE}
    />
  ));

  const initialSegments = useMemo(() => {
    return initialSegmentsFromConfig ?? INITIAL_ROW_SCORE_SEGMENTS;
  }, [initialSegmentsFromConfig]);

  const initializedRef = useRef<boolean>();
  useEffect(() => {
    if (
      initialSegmentsFromConfig &&
      !initializedRef.current &&
      calcScoreOutputRef.current
    ) {
      onUpdateRowScores(initialSegmentsFromConfig as paper.Segment[]);
      initializedRef.current = true;
    }
  }, [initialSegmentsFromConfig, onUpdateRowScores]);

  return (
    <div className={styles.rowScoreGraphicBodyContainer}>
      <ChangeRowScoreBody
        rowList={rowList}
        initialSegments={initialSegments}
        calcScoreOutputRef={calcScoreOutputRef}
        exportScorePathRef={exportScorePathRef}
        rowDataToEdit={rowDataToEdit}
        gapHeight={gapHeight}
        onUpdateRowScores={onUpdateRowScores}
      />
    </div>
  );
};
