import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import { Divider } from '../AutoPricingSettings.styled';
import * as styles from './SettingsSubheader.css';

type PricingSubheaderProps = {
  title: ContentId;
};

export const SettingsSubheader: React.FC<PricingSubheaderProps> = ({
  title,
}) => {
  return (
    <Stack direction="column" gap="s" className={styles.container}>
      <Divider />
      <div className={styles.header}>
        <Content id={title} />
      </div>
    </Stack>
  );
};
