import {
  InitialTableState,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { FormatContent, useContent } from 'src/contexts/ContentContext';
import { NoData, Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { PurchasePaymentMethod } from 'src/WebApiController';

import {
  PAYMENT_METHOD_TABLE_COLUMNS_CONFIG,
  PurchasePaymentMethodWithHandlers,
} from './configs/PurchasePaymentMethodsTableColumnsConfig';

export type PurchasePaymentMethodsTableDataProps = {
  purchasePaymentMethods?: PurchasePaymentMethod[];
};

export type PurchasePaymentMethodsTableProps =
  PurchasePaymentMethodsTableDataProps & {
    onMount?: () => void;
    onUnmount?: (state: object) => void;
    initState?: InitialTableState;
    withOuterPadding?: boolean;
  };

const PAGE_SIZE = 10;

export function PurchasePaymentMethodsTable({
  purchasePaymentMethods,
  onMount,
  onUnmount,
  initState,
  withOuterPadding,
}: PurchasePaymentMethodsTableProps) {
  const purchasePaymentMethodsText = useContent(ContentId.PaymentMethods);

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || []
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: PAGE_SIZE,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const data = useMemo(
    () =>
      purchasePaymentMethods?.map(
        (v) =>
          ({
            purchasePaymentMethod: v,
          }) as PurchasePaymentMethodWithHandlers
      ),
    [purchasePaymentMethods]
  );

  const options: Partial<
    TableOptions<PurchasePaymentMethodWithHandlers | null>
  > = useMemo(
    () => ({
      data,
      columns: PAYMENT_METHOD_TABLE_COLUMNS_CONFIG,
      state: {
        pagination,
        sorting,
      },
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
    }),
    [data, pagination, sorting]
  );

  return purchasePaymentMethods?.length ? (
    <Table options={options} withOuterPadding={withOuterPadding} />
  ) : (
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={purchasePaymentMethodsText}
      />
    </NoData>
  );
}
