/* eslint-disable react-hooks/exhaustive-deps */
import { TableOptions } from '@tanstack/react-table';
import { isEmpty, isEqual, max } from 'lodash-es';
import { ComponentProps, useCallback, useMemo } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Feature,
  SectionInfo,
  SectionScoreOverride,
} from 'src/WebApiController';

import { AddSectionRowsDialog } from './components/AddSectionRowsDialog';
import SectionRowTable from './components/SectionRowTable';
import {
  getSectionColumns,
  SectionRowColumnType,
} from './config/SectionRowTableConfig';
import { useTableData } from './SectionRowTableDialog.hooks';

export type SectionRowTableDialogProps = ComponentProps<typeof RSModal> & {
  sections: SectionInfo[];
  scoreOverrides?: SectionScoreOverride[];
  onClose: () => void;
  onSectionRowChange: (updated: SectionScoreOverride[]) => void;
};

export const SectionRowTableDialog: React.FC<SectionRowTableDialogProps> = ({
  sections,
  scoreOverrides,
  onClose,
  onSectionRowChange,
  ...rest
}) => {
  const hasCustomVenueRowsFeature = useUserHasFeature(Feature.CustomVenueRows);
  const addRowsDialog = useBasicDialog();
  const {
    data,
    overrides,
    selectedSectionIds,
    onAddRows,
    onSelectSections,
    onSwapOrdinals,
  } = useTableData(sections, scoreOverrides);

  const maxLength = useMemo(() => {
    return (
      max(
        sections.map(
          (section) =>
            section.rows.filter(({ ordinal }) => ordinal != null).length
        )
      ) ?? 0
    );
  }, []);

  const options: Partial<TableOptions<SectionRowColumnType>> = useMemo(
    () => ({
      data,
      columns: getSectionColumns(maxLength),
    }),
    [data]
  );

  const hasChange = useMemo(
    () => !isEqual(overrides, scoreOverrides),
    [overrides, scoreOverrides]
  );

  const onSubmitChange = useCallback(() => {
    onSectionRowChange(overrides);
    onClose();
  }, [overrides, onSectionRowChange, onClose]);

  return (
    <>
      <GenericDialog
        {...rest}
        fullscreen
        size="full"
        header={<Content id={ContentId.CheckVenueRows} />}
        footer={
          hasCustomVenueRowsFeature ? (
            <Stack direction="row" width="full" justifyContent="spaceBetween">
              <OkButton
                onClick={() => addRowsDialog.launchDialog()}
                disabled={isEmpty(selectedSectionIds)}
                textContentId={ContentId.AddRow}
              />
              <Stack direction="row" gap="m">
                <CancelButton onClick={onClose} />
                <OkButton
                  onClick={onSubmitChange}
                  disabled={!hasChange}
                  textContentId={ContentId.Save}
                />
              </Stack>
            </Stack>
          ) : (
            <Stack direction="row" gap="m">
              <CancelButton onClick={onClose} />
              <OkButton
                onClick={onSubmitChange}
                disabled={!hasChange}
                textContentId={ContentId.Save}
              />
            </Stack>
          )
        }
        onCancel={onClose}
      >
        <SectionRowTable
          options={options}
          onSwapCell={onSwapOrdinals}
          tableLayout="fixed"
          onSelectSections={onSelectSections}
          canCustomRows={hasCustomVenueRowsFeature}
        />
      </GenericDialog>
      <AddSectionRowsDialog
        {...addRowsDialog.dialogProps}
        onClose={addRowsDialog.closeDialog}
        onAddRows={onAddRows}
      />
    </>
  );
};
