import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function PdfIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M15.189 2l5.186 5.184v1.143h-6.38V3.633H5.146v16.734H18.73V9.143l1.646.816V22H3.5V2h11.689zM8.532 15.125l.171.006c.33.025.585.122.762.293.207.2.31.484.31.852 0 .379-.113.675-.338.888-.225.213-.57.32-1.032.32h-.628v1.391H6.625v-3.75h1.907zm3.562 0l.194.006c.248.014.455.058.62.132.207.092.378.224.513.397.135.172.233.372.294.6.06.23.091.471.091.727 0 .401-.045.712-.135.933-.09.22-.216.405-.376.555-.16.149-.333.248-.517.298-.251.068-.48.102-.684.102H10.39v-3.75h1.704zm5.156 0v.806h-1.684v.655h1.438v.757h-1.438v1.532h-1.152v-3.75h2.836zm-5.426.85h-.287v2.048h.281c.24 0 .41-.027.512-.08a.545.545 0 00.238-.282c.057-.134.086-.35.086-.65 0-.398-.064-.67-.192-.817-.129-.146-.341-.22-.638-.22zm-3.72-.088h-.327v.837h.281c.221 0 .377-.04.466-.117a.377.377 0 00.134-.298.419.419 0 00-.116-.299c-.078-.082-.224-.123-.438-.123zm7.538-11.111v1.918h1.934l-1.934-1.918z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
