import { ComponentProps, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { PerformerCategoryIcon } from 'src/components/common/PerformerCategoryIcon';
import { AdGroupPerformerSelector } from 'src/components/Selectors/AdGroupPerformerSelector';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { PillItemProps } from 'src/core/ui/PillList/PillItem';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import {
  EmptySelectionKey,
  getTopLevelCategoryId,
  getTopLevelCategoryName,
} from 'src/utils/adGroupUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { Performer, TopLevelCategory } from 'src/WebApiController';

import { BodySectionTitle, FieldWrapper } from '../common';
import * as styles from './AdGroupCreate.css';
import { BodySectionBody } from './AdGroupCreate.styled';
import { AdGroupSelectError } from './AdGroupSelectError';

export interface PerformerSectionProps {
  onSelectPerformer: (performer: Performer | null | undefined) => void;
  genreId?: number;
  performer: Performer | null | undefined;
  error?: string;
}

export const PerformerSection = ({
  onSelectPerformer,
  genreId,
  performer,
  error,
}: PerformerSectionProps) => {
  const addPerformerDialog = useBasicDialog();

  const onAddPerformerSave = useCallback(
    (performer: Performer | null | undefined) => {
      onSelectPerformer(performer);
      addPerformerDialog.closeDialog();
    },
    [onSelectPerformer, addPerformerDialog]
  );

  const onAddPerformerCancel = useCallback(() => {
    addPerformerDialog.closeDialog();
  }, [addPerformerDialog]);

  const onAddPerformer = useCallback(() => {
    addPerformerDialog.launchDialog();
  }, [addPerformerDialog]);

  const contentNA = useContent(ContentId.NA);

  const pills = useMemo(() => {
    const pills = [] as PillItemProps[];
    let performerRef = performer;
    if (performer !== undefined) {
      if (!performer) {
        performerRef = {
          genreId: getTopLevelCategoryId(TopLevelCategory.Concert),
          name: contentNA,
        } as Performer;
      }

      pills.push({
        value: 'performer',
        display: performerRef!.name,
        icon: <PerformerCategoryIcon performer={performerRef!} />,
        onDelete: () => {
          onSelectPerformer(undefined);
        },
      });
    }

    return pills;
  }, [performer, contentNA, onSelectPerformer]);

  return (
    <>
      <div className={styles.bodySection}>
        <BodySectionTitle>
          <Content id={ContentId.Performer} />
        </BodySectionTitle>
        {performer !== undefined ? (
          <BodySectionBody>
            <PillList pills={pills} />
          </BodySectionBody>
        ) : (
          <BodySectionBody>
            <Button variant={'link'} onClick={onAddPerformer}>
              <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
              <Content id={ContentId.AddPerformer} />
            </Button>
            <AdGroupSelectError error={error} />
          </BodySectionBody>
        )}
      </div>
      {addPerformerDialog.dialogProps.isOpen && (
        <AddPerformerDialog
          {...addPerformerDialog.dialogProps}
          genreId={genreId}
          onSave={onAddPerformerSave}
          onCancel={onAddPerformerCancel}
        />
      )}
    </>
  );
};

export type AddPerformerFieldValues = {
  performer: Performer | null | undefined;
};

export type AddPerformerDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onSave: (performer: Performer | null | undefined) => void;
  onCancel: () => void;
  genreId?: number;
};

export const AddPerformerDialog = ({
  onSave,
  onCancel,
  genreId,
  ...genericDialogProps
}: AddPerformerDialogProps) => {
  const addPerformerForm = useForm<AddPerformerFieldValues>({
    defaultValues: {
      performer: undefined,
    },
  });

  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const setPerformer = useCallback(
    (performerId: string) => {
      if (!catalogData) {
        return;
      }

      const performer =
        performerId == EmptySelectionKey
          ? null
          : catalogData.performers[parseInt(performerId, 10)];

      addPerformerForm.clearErrors('performer');
      addPerformerForm.setValue('performer', performer);
    },
    [addPerformerForm, catalogData]
  );

  const performer = addPerformerForm.watch('performer');

  const topLevelCategory = genreId
    ? getTopLevelCategoryName(genreId)
    : undefined;

  const onSubmit = useCallback(() => {
    onSave(performer);
  }, [performer, onSave]);

  const handleEventFilter = (performer: Performer | null | undefined) => {
    if (
      !performer ||
      !topLevelCategory ||
      performer.categ.some((tlc) => tlc == topLevelCategory)
    ) {
      return true;
    }
    return false;
  };

  return (
    <GenericDialog
      size="xl"
      header={<Content id={ContentId.Performer} />}
      footer={
        <>
          <CancelButton onClick={onCancel} />
          <Button
            variant={'regular'}
            onClick={addPerformerForm.handleSubmit(onSubmit)}
          >
            <Content id={ContentId.OK} />
          </Button>
        </>
      }
      onClosed={() => {
        addPerformerForm.reset();
      }}
      {...genericDialogProps}
      onCancel={onCancel}
    >
      <FieldWrapper>
        <PosFormField label={<Content id={ContentId.Performer} />}>
          <AdGroupPerformerSelector
            filterCallback={handleEventFilter}
            value={
              performer === null
                ? EmptySelectionKey
                : performer?.viagId?.toString()
            }
            onChange={(performerId) => {
              setPerformer(performerId);
            }}
            style={{ width: '100%' }}
          />
        </PosFormField>
      </FieldWrapper>
    </GenericDialog>
  );
};
