import {
  inviteOnlyBannerWrapper,
  subtitle,
  title,
} from 'src/components/SignUp/InviteOnlyBanner/InviteOnlyBanner.css';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

export const InviteOnlyBanner = () => {
  return (
    <div className={inviteOnlyBannerWrapper}>
      <div className={title}>
        <Content id={ContentId.SignUpBannerTitle} />
      </div>
      <div className={subtitle}>
        <Content id={ContentId.SignUpBannerSubTitle} />
      </div>
    </div>
  );
};
