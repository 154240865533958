import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  ActionOutboxEntityType,
  Tag,
  TagClient,
  TagsValueType,
} from 'src/WebApiController';

export function useTagsForEntityType(
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Purchase
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.SellerEvent,
  includeInReport?: boolean
) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const queryClient = useQueryClient();
  const mainQueryKey = [
    'TagClient.getTagsByEntityTypeId',
    activeAccountWebClientConfig.activeAccountId,
    entityType,
  ];

  const tagByEntityTypeQuery = useQuery({
    queryKey: [...mainQueryKey, includeInReport],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      return new TagClient(activeAccountWebClientConfig).getTagsByEntityTypeId(
        entityType,
        includeInReport ?? null
      );
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    networkMode: 'offlineFirst',
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('TagClient.getTagsByEntityTypeId', error, {
          entityType,
          includeInReport,
        });
      },
    },
  });

  const tagWithValueByEntityTypeQuery = useQuery({
    queryKey: [
      'TagClient.getTagsWithValueByEntityTypeId',
      activeAccountWebClientConfig.activeAccountId,
      entityType,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new TagClient(
        activeAccountWebClientConfig
      ).getTagsWithValueByEntityTypeId(entityType);
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('TagClient.getTagsWithValueByEntityTypeId', error, {
          entityType,
        });
      },
    },
  });

  const isTag = useCallback(
    (name: string) => {
      return tagByEntityTypeQuery.data?.find((t) => t.key === name) != null;
    },
    [tagByEntityTypeQuery.data]
  );

  const mutation = useMutation({
    mutationFn: async ({ tags }: { tags: Tag[] }) => {
      return await new TagClient(
        activeAccountWebClientConfig
      ).upsertEntityTagsMetadata(entityType, tags);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ tags }) => {
      queryClient.cancelQueries({ queryKey: mainQueryKey });
      const prevValue = queryClient.getQueryData(mainQueryKey);
      queryClient.setQueryData(mainQueryKey, tags);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { tags }, context) => {
      queryClient.setQueryData(mainQueryKey, context?.prevValue);
      showErrorDialog('TagClient.upsertEntityTagsMetadata', err, {
        trackErrorData: { tags },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: mainQueryKey });
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async ({ tags }: { tags: Tag[] }) => {
      return await new TagClient(
        activeAccountWebClientConfig
      ).deleteEntityTagsMetadata(
        entityType,
        tags.map((t) => t.key)
      );
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ tags }) => {
      queryClient.cancelQueries({ queryKey: mainQueryKey });
      const prevValue = queryClient.getQueryData<Tag[]>(mainQueryKey);
      queryClient.setQueryData(
        mainQueryKey,
        prevValue?.filter((t) => !tags.includes(t))
      );
      return { prevValue };
    },
    onError: (err: ErrorTypes, { tags }, context) => {
      queryClient.setQueryData(mainQueryKey, context?.prevValue);
      showErrorDialog('TagClient.deleteEntityTagsMetadata', err, {
        trackErrorData: { tags },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: mainQueryKey });
    },
  });

  const tagsWithNoValue = useMemo(() => {
    if (
      tagByEntityTypeQuery.data == null ||
      tagWithValueByEntityTypeQuery.data == null
    ) {
      return [];
    }

    return tagByEntityTypeQuery.data.filter((t) => {
      const tagWithValue = tagWithValueByEntityTypeQuery.data?.find(
        (t2) => t2.key === t.key
      );
      return tagWithValue == null;
    });
  }, [tagByEntityTypeQuery.data, tagWithValueByEntityTypeQuery.data]);

  return {
    tagsMetadata: tagByEntityTypeQuery.data,
    isLoading: tagByEntityTypeQuery.isLoading,
    tagsMetadataNumeric: tagByEntityTypeQuery.data?.filter(
      (t) =>
        t.valueType === TagsValueType.Decimal ||
        t.valueType === TagsValueType.Int
    ),
    tagsWithNoValue,
    isError: tagByEntityTypeQuery.isError,
    refetch: tagByEntityTypeQuery.refetch,
    async mergeEntityTagsMetadata(tagsToUpdate: Tag[]) {
      await mutation.mutateAsync({ tags: tagsToUpdate });
    },
    async deleteEntityTagsMetadata(tagsToDelete: Tag[]) {
      await deleteMutation.mutateAsync({ tags: tagsToDelete });
    },
    isTag,
  };
}
