import { useMemo } from 'react';
import { FilterToolbarGroup } from 'src/components/FilterToolbar';
import { useAppContext } from 'src/contexts/AppContext';
import { hasAnyOfPermissions } from 'src/utils/userPermissionUtils';

interface UseFilterFiltersByPermissionProps {
  filters: FilterToolbarGroup[];
}

export const useFilterFiltersByPermission = ({
  filters,
}: UseFilterFiltersByPermissionProps) => {
  const { loginContext } = useAppContext();

  return useMemo(
    () =>
      filters
        .map((filter) => {
          return {
            ...filter,
            items: filter.items.filter(
              (fi) =>
                fi.requiredPermissions == null ||
                hasAnyOfPermissions(loginContext?.user, fi.requiredPermissions)
            ),
          };
        })
        .filter((filter) => filter.items.length > 0),
    [filters, loginContext?.user]
  );
};
