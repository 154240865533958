import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useHasListingPricePermissions } from 'src/modals/ListingDetails/components/useHasListingPricePermissions';
import {
  AutoPricingSettingsCollection,
  Listing,
  PricingClient,
} from 'src/WebApiController';

export const useAutoPricingStrategies = (
  listing?: Listing | null,
  forBulkEdit?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const { canAutoPrice } = useHasListingPricePermissions(listing, forBulkEdit);
  const queryClient = useQueryClient();
  const queryKey = [
    'getAutoPricingStrategies',
    activeAccountWebClientConfig.activeAccountId,
  ];

  const shouldQuery =
    canAutoPrice && activeAccountWebClientConfig.activeAccountId != null;
  const autoPricingStrategiesQuery = useQuery({
    queryKey,
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new PricingClient(
        activeAccountWebClientConfig
      ).getAutoPricingStrategies();
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.getAutoPricingStrategies', error);
      },
    },
  });

  const autoPricingStrategiesUpdateMutation = useMutation({
    mutationFn: async ({ input }: { input: AutoPricingSettingsCollection }) => {
      return await new PricingClient(
        activeAccountWebClientConfig
      ).upsertAutoPricingStrategy(input);
    },
    // optimistic ui
    onMutate: ({ input }) => {
      queryClient.cancelQueries({ queryKey });

      const prevValue =
        queryClient.getQueryData<AutoPricingSettingsCollection[]>(queryKey);
      const newValue = [
        ...(prevValue?.filter(
          (c) =>
            c.autoPricingSettingsCollectionId !==
            input.autoPricingSettingsCollectionId
        ) ?? []),
        input,
      ];

      queryClient.setQueryData(queryKey, newValue);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { input }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('PricingClient.upsertAutoPricingStrategy', err, {
        trackErrorData: { input },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const autoPricingStrategiesDeleteMutation = useMutation({
    mutationFn: async ({ collectionId }: { collectionId: number }) => {
      return await new PricingClient(
        activeAccountWebClientConfig
      ).deleteAutoPricingStrategy(collectionId);
    },
    // optimistic ui
    onMutate: ({ collectionId }) => {
      queryClient.cancelQueries({ queryKey });

      const prevValue =
        queryClient.getQueryData<AutoPricingSettingsCollection[]>(queryKey);
      const newValue = prevValue?.filter(
        (c) => c.autoPricingSettingsCollectionId !== collectionId
      );

      queryClient.setQueryData(queryKey, newValue);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { collectionId }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('PricingClient.deleteAutoPricingStrategy', err, {
        trackErrorData: { collectionId },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    autoPricingStrategiesQuery,
    upsertAutoPricingStrategy: autoPricingStrategiesUpdateMutation.mutateAsync,
    deleteAutoPricingStrategy: autoPricingStrategiesDeleteMutation.mutateAsync,
  };
};
