import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const BulkUploadWrapper = styled.div`
  padding: ${() => `${vars.spacing.lg} 0`};

  textarea {
    min-height: 200px;

    :disabled {
      color: ${({ theme }) => theme.colors.textDisabled};
      border-color: ${({ theme }) => theme.colors.borderDisabled};
      background-color: ${(props) =>
        props.theme.colors.backgroundActiveDisabled};
      cursor: not-allowed;
    }
  }
`;

export const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${() => vars.spacing.sm};

  > *:first-child {
    flex: 1;
    margin-top: 0;
  }
`;
