import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export const DollarIcon = (props: IconsProps) => (
  <SvgWrapper {...props}>
    <path
      d="M11.3333 22V2H12.6667V22H11.3333ZM15.8211 8C15.7236 7.20833 15.3279 6.59375 14.6341 6.15625C13.9404 5.71875 13.0894 5.5 12.0813 5.5C11.3442 5.5 10.6992 5.61458 10.1463 5.84375C9.59892 6.07292 9.17073 6.38802 8.86179 6.78906C8.55827 7.1901 8.4065 7.64583 8.4065 8.15625C8.4065 8.58333 8.5122 8.95052 8.72358 9.25781C8.94038 9.5599 9.2168 9.8125 9.55285 10.0156C9.88889 10.2135 10.2412 10.3776 10.6098 10.5078C10.9783 10.6328 11.3171 10.7344 11.626 10.8125L13.3171 11.25C13.7507 11.3594 14.2331 11.5104 14.7642 11.7031C15.3008 11.8958 15.813 12.1589 16.3008 12.4922C16.794 12.8203 17.2005 13.2422 17.5203 13.7578C17.8401 14.2734 18 14.9062 18 15.6562C18 16.5208 17.7642 17.3021 17.2927 18C16.8266 18.6979 16.1436 19.2526 15.2439 19.6641C14.3496 20.0755 13.2629 20.2812 11.9837 20.2812C10.7913 20.2812 9.75881 20.0964 8.88618 19.7266C8.01897 19.3568 7.33604 18.8411 6.8374 18.1797C6.34417 17.5182 6.06504 16.75 6 15.875H8.0813C8.1355 16.4792 8.34688 16.9792 8.71545 17.375C9.08943 17.7656 9.56098 18.0573 10.1301 18.25C10.7046 18.4375 11.3225 18.5312 11.9837 18.5312C12.7534 18.5312 13.4444 18.4115 14.0569 18.1719C14.6694 17.9271 15.1545 17.5885 15.5122 17.1562C15.8699 16.7188 16.0488 16.2083 16.0488 15.625C16.0488 15.0938 15.8943 14.6615 15.5854 14.3281C15.2764 13.9948 14.8699 13.724 14.3659 13.5156C13.8618 13.3073 13.3171 13.125 12.7317 12.9688L10.6829 12.4062C9.38211 12.0469 8.3523 11.5339 7.5935 10.8672C6.83469 10.2005 6.45528 9.32812 6.45528 8.25C6.45528 7.35417 6.70732 6.57292 7.21138 5.90625C7.72087 5.23438 8.40379 4.71354 9.26016 4.34375C10.122 3.96875 11.084 3.78125 12.1463 3.78125C13.2195 3.78125 14.1734 3.96615 15.0081 4.33594C15.8428 4.70052 16.5041 5.20052 16.9919 5.83594C17.4851 6.47135 17.7453 7.19271 17.7724 8H15.8211Z"
      fill="inherit"
    />
  </SvgWrapper>
);
