import { useQuery } from '@tanstack/react-query';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { EmptyVendorAccountId } from 'src/dialogs/PurchaseVendorAccountDialog';
import {
  AutoPoClient,
  AutoPoInboundEmail,
  AutoPoInboundEmailClassificationType,
  AutoPoPartialPurchaseOrder,
  PurchaseClient,
  PurchaseVendor,
  PurchaseVendorAccount,
  PurchaseVendorAccountDetails,
} from 'src/WebApiController';

export type AutoPoVendorFields = {
  vendor: PurchaseVendor | null | undefined;
  vendorAccount: PurchaseVendorAccount | null | undefined;
};

export type AutoPoPartialPurchaseOrderFields = AutoPoPartialPurchaseOrder &
  AutoPoVendorFields;

type PartialPurchaseOrderKey = {
  partialPurchaseOrderId?: number | null;
  toEmailAddress?: string | null;
  purchaseOrderVendorId?: number | null;
};

type IEmailPurchaseOrderContext = {
  inboundEmailsClassified?: AutoPoInboundEmail[];
  isLoading?: boolean;
  isError?: boolean;

  inboundEmailId?: number;
  setInboundEmailId: (
    partialPurchaseOrderId: number | null | undefined
  ) => void;

  classificationType?: AutoPoInboundEmailClassificationType;
  setClassificationType: Dispatch<
    SetStateAction<AutoPoInboundEmailClassificationType | undefined>
  >;

  setOrderId: Dispatch<SetStateAction<string | undefined>>;

  partialPurchaseOrderKey?: PartialPurchaseOrderKey;
  setPartialPurchaseOrderKey: (
    key: PartialPurchaseOrderKey | undefined
  ) => void;

  partialPurchaseOrder?: AutoPoPartialPurchaseOrderFields;

  reset: () => void;
};

export const EmailPurchaseOrderContext =
  createContext<IEmailPurchaseOrderContext>({
    setInboundEmailId: () => {},
    setClassificationType: () => {},
    setOrderId: () => {},
    setPartialPurchaseOrderKey: () => {},
    reset: () => {},
  });

export const EmailPurchaseOrderContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { availableVendors } = usePurchaseVendorSelector({});

  const [partialPurchaseOrderKey, setPartialPurchaseOrderKey] =
    useState<PartialPurchaseOrderKey>();

  const [orderId, setOrderId] = useState<string>();
  const [inboundEmailId, setInboundEmailId] = useState<number>();
  const [classificationType, setClassificationType] =
    useState<AutoPoInboundEmailClassificationType>();

  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const [
    partialPurchaseOrderVendorFields,
    setPartialPurchaseOrderVendorFields,
  ] = useState<AutoPoVendorFields | undefined>();

  const getOrCreateVendorAccount = useCallback(
    async (
      toEmail: string | null | undefined,
      vendorId: number | null | undefined
    ): Promise<AutoPoVendorFields | undefined> => {
      if (vendorId == null || toEmail == null) {
        return;
      }

      const vendor = availableVendors[vendorId];
      if (vendor == null) {
        return;
      }

      let vendorAccountOverride: PurchaseVendorAccountDetails | undefined = (
        await new PurchaseClient(
          activeAccountWebClientConfig
        ).getAccessibleVendorAccounts(
          { item1: [vendorId], item2: [] },
          toEmail,
          1,
          0
        )
      )[0] as PurchaseVendorAccountDetails | undefined;

      if (vendorAccountOverride == null) {
        vendorAccountOverride = {
          id: EmptyVendorAccountId,
          name: 'autopo-' + toEmail,
          email: toEmail,
          password: null,
          vendorId: vendor.id,
          dftPayMethodId: null,
          dftBuyerId: null,
          isNoFulfill: null,
          delivType: null,
          tktTypeRules: null,
        };

        const newId = await new PurchaseClient(
          activeAccountWebClientConfig
        ).mergeVendorAccount(vendorAccountOverride);

        if (newId) {
          vendorAccountOverride.id = newId;
        } else {
          vendorAccountOverride = undefined;
        }
      }

      setPartialPurchaseOrderVendorFields(
        vendorAccountOverride
          ? {
              vendorAccount: vendorAccountOverride,
              vendor,
            }
          : undefined
      );
    },
    [activeAccountWebClientConfig, availableVendors]
  );

  const partialPurchaseOrderResult = useQuery({
    queryKey: [
      'AutoPoClient.getPartialPurchaseOrder',
      activeAccountWebClientConfig.activeAccountId,
      partialPurchaseOrderKey,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      const partialPurchaseOrderId =
        partialPurchaseOrderKey?.partialPurchaseOrderId;
      const partialPurchaseOrderVendorFields = await getOrCreateVendorAccount(
        partialPurchaseOrderKey?.toEmailAddress,
        partialPurchaseOrderKey?.purchaseOrderVendorId
      );

      if (partialPurchaseOrderId == null) {
        if (partialPurchaseOrderVendorFields != null) {
          return {
            vendorAccount: partialPurchaseOrderVendorFields.vendorAccount,
            vendor: partialPurchaseOrderVendorFields.vendor,
          } as AutoPoPartialPurchaseOrderFields;
        }
        return null;
      }

      const partialPurchaseOrder = (await new AutoPoClient(
        activeAccountWebClientConfig
      ).getPartialPurchaseOrder(
        partialPurchaseOrderId
      )) as AutoPoPartialPurchaseOrderFields;

      if (partialPurchaseOrderVendorFields != null) {
        partialPurchaseOrder.vendorAccount =
          partialPurchaseOrderVendorFields.vendorAccount;
        partialPurchaseOrder.vendor = partialPurchaseOrderVendorFields.vendor;
      }
      return partialPurchaseOrder;
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('AutoPoClient.getPartialPurchaseOrder', error, {
          partialPurchaseOrderKey,
        });
      },
    },
  });

  useEffect(() => {
    getOrCreateVendorAccount(
      partialPurchaseOrderKey?.toEmailAddress,
      partialPurchaseOrderKey?.purchaseOrderVendorId
    );
  }, [getOrCreateVendorAccount, partialPurchaseOrderKey]);

  const partialPurchaseOrder: AutoPoPartialPurchaseOrderFields = useMemo(() => {
    const result =
      partialPurchaseOrderResult.data ??
      ({} as unknown as AutoPoPartialPurchaseOrderFields);

    if (orderId) {
      result.orderId = orderId;
    }
    if (partialPurchaseOrderVendorFields) {
      result.vendorAccount = partialPurchaseOrderVendorFields?.vendorAccount;
      result.vendor = partialPurchaseOrderVendorFields?.vendor;
    }

    return result;
  }, [
    orderId,
    partialPurchaseOrderResult.data,
    partialPurchaseOrderVendorFields,
  ]);

  return (
    <EmailPurchaseOrderContext.Provider
      value={{
        inboundEmailId,
        setInboundEmailId: (inboundEmailId) => {
          setInboundEmailId(inboundEmailId ?? undefined);
        },
        classificationType,
        setClassificationType,

        setOrderId,
        partialPurchaseOrderKey,
        setPartialPurchaseOrderKey: (key) => {
          setPartialPurchaseOrderVendorFields(undefined);
          setPartialPurchaseOrderKey(key);
        },

        partialPurchaseOrder,

        reset() {
          setPartialPurchaseOrderVendorFields(undefined);
          setPartialPurchaseOrderKey(undefined);
          setInboundEmailId(undefined);
          setClassificationType(undefined);
          setOrderId(undefined);
        },
      }}
    >
      {children}
    </EmailPurchaseOrderContext.Provider>
  );
};
