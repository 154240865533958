import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { BulkRemoveAdminHoldDialog } from 'src/dialogs/BulkEdits/BulkRemoveHoldDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_ADMIN_HOLD_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchRemoveHold } from './LaunchRemoveHold';

export const useBulkRemoveHoldAction = (
  filterQueryWithEventIds: ListingQuery,

  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = event
    ? `adminHold-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_ADMIN_HOLD_UPDATE_KEY;
  const {
    refreshCatalog,
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { setSelectionMode } = useMultiSelectionContext();

  const {
    openDialog: openBulkRemoveHoldDialog,
    closeDialog: closeBulkRemoveHoldDialog,
  } = useDialog(DialogId.BulkRemoveHold, BulkRemoveAdminHoldDialog);

  const onSubmit = useCallback(
    async (
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateAdminHoldPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateAdminHoldPreview', error, {
              trackErrorData: { filterQueryWithEventIds, removeHold: true },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateAdminHold(
              preview!.preview,
              null,
              null,
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                if (!event) {
                  await refreshCatalog();
                }
                setSelectionMode();
                await refreshExpandedListItems();
              }
            }
          },
          (error) => {
            showErrorDialog(
              'BulkEditListingClient.bulkUpdateAdminHold',
              error,
              {
                trackErrorData: { preview, removeHold: true },
              }
            );
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      event,
      filterQueryWithEventIds,
      refreshCatalog,
      refreshExpandedListItems,
      setIsLoading,
      setSelectionMode,
      showErrorDialog,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchRemoveHold
        key="RemoveHold"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (event) {
            setListItemExpansion(true, [event.viagVirtualId]);
          }
          openBulkRemoveHoldDialog({
            updateKey,
            onOkay: onSubmit,
            onCancel: closeBulkRemoveHoldDialog,
            onClosed: () => {
              setIsLoading(false);
            },
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
