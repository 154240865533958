import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { SellerAccount } from 'src/WebApiController';

import {
  AccountNameDiv,
  AccountTypeDiv,
  ImpersonatedAccountNameDiv,
  SellerAccountInfoContainer,
  SellerAccountNameContainer,
} from './SellerAccountBasicInfo.style';

export const SellerAccountBasicInfo = ({
  account,
  isCurrent,
}: {
  account: SellerAccount;
  isCurrent: boolean;
}) => {
  return (
    <SellerAccountInfoContainer isCurrent={isCurrent}>
      <SellerAccountNameContainer title={account.id}>
        <AccountTypeDiv isCurrent={isCurrent}>
          {account.impersonatedUsername ? (
            <Content id={ContentId.Impersonate} />
          ) : (
            <Content id={ContentId.SellerAccount} />
          )}
          :
        </AccountTypeDiv>
        <AccountNameDiv isCurrent={isCurrent}>
          {account.accountName}
        </AccountNameDiv>
        <ImpersonatedAccountNameDiv>
          {account.impersonatedUsername
            ? `(${account.impersonatedUsername})`
            : ''}
        </ImpersonatedAccountNameDiv>
      </SellerAccountNameContainer>
    </SellerAccountInfoContainer>
  );
};
