import { useNavigate } from 'react-router-dom';
import { FilterToolbar } from 'src/components/FilterToolbar';
import { QuickFilterToolbarItem } from 'src/components/FilterToolbar/QuickFilterToolbarItem';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import { ConversationType } from './ConversationListItem/ConversationListItem';
import * as styles from './ConversationSidePanel.css';

export type ConversationFilterQuery = {
  isUnread?: boolean | undefined;
  isSortDescending: boolean | null;
};

export type ConversationFilterProps = {
  category: string | null;
  currentFilter: ConversationFilterQuery;
  setFilter: (settings: ConversationFilterQuery) => void;
};

export const defaultConversationFilter: ConversationFilterQuery = {
  isSortDescending: true,
};

const CONVERSATION_FILTER_TYPES_MAP = {
  support: [] as ConversationType[],
  purchases: [ConversationType.Purchase],
  sales: [ConversationType.Sale, ConversationType.Transfer],
  private: [ConversationType.Private],
  junk: [ConversationType.Junk],
};

export function getCategoryConversationTypes(category: string | null) {
  if (!category) {
    return [];
  }
  return (
    CONVERSATION_FILTER_TYPES_MAP[
      category as keyof typeof CONVERSATION_FILTER_TYPES_MAP
    ] ?? []
  );
}

function createConversationSearchParam(
  category: keyof typeof CONVERSATION_FILTER_TYPES_MAP
) {
  return new URLSearchParams({ category });
}

export function ConversationFilter({
  category,
  currentFilter,
  setFilter,
}: ConversationFilterProps) {
  const conversationTypes = getCategoryConversationTypes(category);
  const supportCaseOnly = category === 'support';

  const navigate = useNavigate();
  const quickFilters: QuickFilterToolbarItem[] = [
    {
      id: 'support',
      name: <Content id={ContentId.Support} />,
      isCustomFilter: false,
      isSelected: supportCaseOnly,
      onClick: () => {
        navigate(
          { search: `?${createConversationSearchParam('support')}` },
          { relative: 'path' }
        );
      },
    },
    {
      id: 'purchases',
      name: <Content id={ContentId.Purchases} />,
      isCustomFilter: false,
      isSelected: conversationTypes.includes(ConversationType.Purchase),
      onClick: () => {
        navigate(
          { search: `?${createConversationSearchParam('purchases')}` },
          { relative: 'path' }
        );
      },
    },
    {
      id: 'sale',
      name: <Content id={ContentId.Sales} />,
      isCustomFilter: false,
      isSelected:
        conversationTypes.includes(ConversationType.Sale) ||
        conversationTypes.includes(ConversationType.Transfer),
      onClick: () => {
        navigate(
          { search: `?${createConversationSearchParam('sales')}` },
          { relative: 'path' }
        );
      },
    },
    {
      id: 'private',
      name: <Content id={ContentId.Private} />,
      isCustomFilter: false,
      isSelected: conversationTypes.includes(ConversationType.Private),
      onClick: () => {
        navigate(
          { search: `?${createConversationSearchParam('private')}` },
          { relative: 'path' }
        );
      },
    },
    {
      id: 'junk',
      name: <Content id={ContentId.Junk} />,
      isCustomFilter: false,
      isSelected: conversationTypes.includes(ConversationType.Junk),
      onClick: () => {
        navigate(
          { search: `?${createConversationSearchParam('junk')}` },
          { relative: 'path' }
        );
      },
    },
  ];

  return (
    <div className={styles.filterToolbarContainer}>
      <FilterToolbar
        quickFilters={quickFilters}
        showCustomFilterButton={false}
        isAllSelected={conversationTypes.length === 0 && !supportCaseOnly}
        onResetAll={() => {
          navigate({ search: '' }, { relative: 'path' });
          setFilter({
            ...defaultConversationFilter,
            isSortDescending: currentFilter.isSortDescending,
          });
        }}
        filterAppliedCounts={0}
        filters={[]}
        mandatoryFiltersToShow={[]}
      />
    </div>
  );
}
