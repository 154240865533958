import { InHandDateDisplay } from 'src/components/common/InHandDateDisplay';

export type InHandCellProps = {
  date: Date | null;
  needsToFulfill?: boolean;
  handleFulfillmentState?: boolean;
};

export const InHandCell = ({
  date,
  needsToFulfill,
  handleFulfillmentState,
}: InHandCellProps) => {
  return (
    <InHandDateDisplay
      date={date}
      needsToFulfill={needsToFulfill}
      handleFulfillmentState={handleFulfillmentState}
    />
  );
};
