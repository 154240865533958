import { forwardRef } from 'react';
import { useFormattedContent } from 'src/contexts/ContentContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { Colors } from 'src/core/themes/constants/colors';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import * as styles from './RowRelativeScoreDisplay.css';

export interface RowRelativeScoreInfo {
  rowId?: number;
  rowName: string | null;
  ordinal: number | null;
  percentage?: number;
  isBase?: boolean;
}

export type RowRelativeScoreDisplayProps = {
  rowRelativeScoreInfo: RowRelativeScoreInfo;
  graphWidthPercentage: number;
};

const NEGATIVE_BACKGROUND_COLOR = Colors.fuchsia700;
const NEGATIVE_FOREGROUND_COLOR = '#d9317c';
const POSITIVE_BACKGROUND_COLOR = '#f1ecfa';
const POSITIVE_FOREGROUND_COLOR = '#3f2173';
const NEGATIVE_TEXT_COLOR = '#db3f84';
const POSITIVE_TEXT_COLOR = '#33b2b1';

const DARKMODE_NEGATIVE_BACKGROUND_COLOR = vars.color.backgroundPrimary;
const DARKMODE_POSITIVE_BACKGROUND_COLOR = DARKMODE_NEGATIVE_BACKGROUND_COLOR;
const DARKMODE_POSITIVE_FOREGROUND_COLOR = vars.color.backgroundHighlightHover;

export const RowRelativeScoreDisplay = forwardRef<
  HTMLDivElement,
  RowRelativeScoreDisplayProps
>(function RowRelativeScoreDisplay(
  { rowRelativeScoreInfo, graphWidthPercentage },
  ref
) {
  const {
    rowId,
    rowName,
    ordinal,
    percentage,
    isBase = false,
  } = rowRelativeScoreInfo;
  const { isDarkMode } = useSiteTheme();
  const rowOrdinalFormatText = useFormattedContent(
    FormatContentId.Row,
    ordinal?.toString() ?? rowId?.toString()
  );

  return (
    <div ref={ref} className={styles.rowRelativeScoreDisplayWrapper}>
      <div
        className={styles.rowNameDisplay}
        style={{ width: `${50 - graphWidthPercentage / 2}%` }}
      >
        {rowName ?? rowOrdinalFormatText}
      </div>
      <div
        className={styles.rowGraphDisplay}
        style={{ width: `${graphWidthPercentage}%` }}
      >
        <div
          style={{
            background: isDarkMode
              ? DARKMODE_NEGATIVE_BACKGROUND_COLOR
              : isBase
              ? POSITIVE_BACKGROUND_COLOR
              : NEGATIVE_BACKGROUND_COLOR,
            width: '50%',
            height: '100%',
          }}
        >
          <div
            style={{
              background: NEGATIVE_FOREGROUND_COLOR,
              width: `${
                !isBase && percentage != null && percentage < 0
                  ? -percentage
                  : 0
              }%`,
              height: '100%',
              float: 'right',
            }}
          />
        </div>
        <div
          style={{
            background: isDarkMode
              ? DARKMODE_POSITIVE_BACKGROUND_COLOR
              : POSITIVE_BACKGROUND_COLOR,
            width: '50%',
            height: '100%',
          }}
        >
          <div
            style={{
              background: isDarkMode
                ? DARKMODE_POSITIVE_FOREGROUND_COLOR
                : POSITIVE_FOREGROUND_COLOR,
              width: `${
                !isBase && percentage != null && percentage > 0 ? percentage : 0
              }%`,
              height: '100%',
              float: 'left',
            }}
          />
        </div>
      </div>
      <div
        className={styles.rowPercentageDisplay}
        style={{
          width: `${50 - graphWidthPercentage / 2}%`,
          color: isBase
            ? vars.color.textBrand
            : percentage != null && percentage > 0
            ? POSITIVE_TEXT_COLOR
            : NEGATIVE_TEXT_COLOR,
        }}
      >{`${isBase ? 100 : percentage?.toFixed(1)}%`}</div>
    </div>
  );
});
