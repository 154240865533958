import { useQuery } from '@tanstack/react-query';
import { EventConfigClient } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { ErrorTypes, useErrorBoundaryContext } from '../ErrorBoundaryContext';

export const useGetEventMapConfig = (
  eventId?: number | null,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled &&
    activeAccountWebClientConfig.activeAccountId != null &&
    eventId != null;
  const venueMapQuery = useQuery({
    queryKey: [
      'EventConfigClient.getVenueMapForEvent',
      activeAccountWebClientConfig.activeAccountId,
      eventId,
      disabled,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const map = await new EventConfigClient(
        activeAccountWebClientConfig
      ).getVenueMapForEvent(eventId!);

      return map;
    },

    enabled: shouldQuery,
    staleTime: Infinity, // this data hardly, and we already invalidate on reload
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('EventConfigClient.getVenueMapForEvent', error, { eventId });
      },
    },
  });

  return venueMapQuery;
};
