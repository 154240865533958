import { BrandSemanticColors } from './palette.types';

export const STUBHUB_LIGHT: BrandSemanticColors = {
  brandPrimary: '#5E29BA', // indigo33
  brandPrimaryHover: '#6535BE', // indigo36
  brandPrimaryActive: '#3F1D75', // indigo20
  textOnBrandPrimary: '#FFFFFF', // indigo100
  borderBrandPrimary: '#5E29BA', // indigo33

  brandSecondary: '#F1EBFA', // indigo94
  brandSecondaryHover: '#EEE0FF', // indigo91
  brandSecondaryActive: '#E5D2FF', // indigo87
  textOnBrandSecondary: '#5E29BA', // indigo33

  textBrandOnSurface: '#5E29BA', // indigo33

  textBrandOnElevated: '#9471D7', // indigo55
};

export const STUBHUB_DARK: BrandSemanticColors = {
  brandPrimary: '#6535BE', // indigo36
  brandPrimaryHover: '#835CCD', // indigo48
  brandPrimaryActive: '#5E29BA', // indigo33
  textOnBrandPrimary: '#FFFFFF', // indigo100
  borderBrandPrimary: '#6535BE', // indigo36

  brandSecondary: '#1B0045', // indigo6
  brandSecondaryHover: '#2C0069', // indigo13
  brandSecondaryActive: '#1B0045', // indigo6
  textOnBrandSecondary: '#E5D2FF', // indigo87

  textBrandOnSurface: '#9471D7', // indigo55

  textBrandOnElevated: '#9C79DB', // indigo58
};
