import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { EVENT_DETAILED_METRICS_QUERY_KEY } from 'src/tables/EventsTable/EventsTable.constants';
import { EventConfigClient, Tag } from 'src/WebApiController';

export const useEventTags = (
  viagVirtualId?: string | null,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const queryClient = useQueryClient();
  const queryKey = ['EventConfigClient.getTagsForEvent', viagVirtualId];

  const shouldQuery =
    !disabled &&
    Boolean(activeAccountWebClientConfig.activeAccountId && viagVirtualId);
  const tagsQuery = useQuery({
    queryKey,
    queryFn: async () => {
      if (!shouldQuery) return null;

      const tags = await new EventConfigClient(
        activeAccountWebClientConfig
      ).getTagsForEvent(viagVirtualId!);

      return tags;
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('EventConfigClient.getTagsForEvent', error, {
          viagVirtualId,
        });
      },
    },
  });

  const mutation = useMutation({
    mutationFn: async ({
      tags,
      eventId,
    }: {
      tags: Tag[];
      eventId: string | null;
    }) => {
      const res = await new EventConfigClient(
        activeAccountWebClientConfig
      ).updateTagsForEvent(eventId ?? undefined, tags);
      return res;
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ tags }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, tags);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { tags }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('EventConfigClient.updateTagsForEvent', err, {
        trackErrorData: { tags },
      });
    },
    onSettled: (_data, _error, { tags }) => {
      queryClient.invalidateQueries({ queryKey });
      if (tags.some((t) => t.includeInReport)) {
        queryClient.invalidateQueries({
          queryKey: [`${EVENT_DETAILED_METRICS_QUERY_KEY}-detailed`],
        });
      }
    },
  });

  return {
    tags: tagsQuery.data,
    isLoading: tagsQuery.isLoading,
    async updateTags(tags: Tag[], eventId: string | null) {
      await mutation.mutateAsync({ tags, eventId });
    },
  };
};
