import { submitButton } from 'src/components/SignUp/EmailForm/SubmitButton/SubmitButton.css';
import { Content } from 'src/contexts/ContentContext';
import { useSignUpContext } from 'src/contexts/SignUpContext/SignUpContext';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { ArrowRight } from 'src/svgs/ArrowRight';
import { IconsFill, LoadingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

export const SubmitButton = () => {
  const { isSignupSubmitted } = useSignUpContext();
  return (
    <button type="submit" className={submitButton} disabled={isSignupSubmitted}>
      <Content id={ContentId.Submit} />

      {isSignupSubmitted ? (
        <RotatingWrapper>
          <LoadingIcon
            withHoverEffect
            fill={IconsFill.textBrand}
            align="middle"
          />
        </RotatingWrapper>
      ) : (
        <ArrowRight />
      )}
    </button>
  );
};
