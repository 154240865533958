import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const TableRowDiv = styled('tr')<{
  selected: boolean;
  isSubRow?: boolean;
}>`
  ${(props) =>
    props.selected
      ? `background-color: ${props.theme.colors.backgroundAccent700};`
      : props.isSubRow
      ? `background-color: ${props.theme.colors.backgroundSecondary};`
      : ''}
  min-width: max-content;

  &:hover,
  &:focus,
  &:active,
  &:focus-visible,
  &:focus-within {
    background-color: ${(props) =>
      props.isSubRow
        ? props.theme.colors.backgroundActiveDisabled
        : props.selected
        ? props.theme.colors.backgroundAccent700
        : props.theme.colors.backgroundInteraction};
  }
`;

export const SeatInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 300px;

  .listing-notes {
    font-size: ${() => vars.typography.fontSize['xs']};
  }
`;

type TableShimmeringDivProps = { height?: string };
export const TableShimmeringDiv = ({ height }: TableShimmeringDivProps) => {
  return <ContentPlaceholder width="100%" height={height ?? '28px'} />;
};

export const NoData = styled.div`
  color: ${({ theme: { colors } }) => colors.textPrimary};
  text-align: center;
  padding: 1em 0;
  display: flex;
  justify-content: center;
`;
