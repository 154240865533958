import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { TableCellDiv, TableCellDivProps } from 'src/core/ui/TableCellDiv';

type TableHeaderCellDivProps = ComponentPropsWithoutRef<'div'> &
  TableCellDivProps;

export const TableHeaderCellDiv = forwardRef<
  HTMLDivElement,
  TableHeaderCellDivProps
>(function TableHeaderCellDiv({ children, ...props }, ref) {
  return (
    <TableCellDiv ref={ref} {...props} wrap="nowrap">
      {children}
    </TableCellDiv>
  );
});
