import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const RowExtrapolationUndercutIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 40 40">
      <path
        d="M16 20L22 14.5L16 9"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14H13.5C9.35812 14 6 17.3581 6 21.5C6 25.6419 9.35812 29 13.5 29H21"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 16H34"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26 22H34"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="25"
        y="10"
        width="10"
        height="19"
        rx="2"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
      />
    </SvgWrapper>
  );
};
