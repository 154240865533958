import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { EventAccordionItemProps } from 'src/components/Accordions';
import { translateEventToTicketGroupAccordionItem } from 'src/components/Accordions/TicketGroupsEventsAccordion';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import { BackSection } from 'src/components/common/BackSection';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import {
  EventPageTabs,
  TabItem,
} from 'src/components/Events/EventPage/EventPageTabs';
import { SidePanel } from 'src/components/Events/EventPage/SidePanel/SidePanel';
import { ActiveFocusContextProvider } from 'src/contexts/ActiveFocusContext/ActiveFocusContext';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { CollapsableViewProvider } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import {
  EventLastReviewedContextProvider,
  useEventLastReviewedContext,
} from 'src/contexts/SellerEventLastReviewContext/SellerEventLastReviewContext';
import {
  SidePanelPage,
  SidePanelProvider,
} from 'src/contexts/SidePanelContext';
import { ExplorerLayout } from 'src/core/layouts';
import { LoadingButton } from 'src/core/POS/LoadingButton/LoadingButton';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useSellerEventNote } from 'src/hooks/api/useSellerEventNote';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useQuickFilterSearchParam } from 'src/hooks/useQuickFilters';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { PurchaseDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import { getAppliedFilterCounts } from 'src/utils/eventQueryUtils';
import { getPurchaseOrderDetailsModalConfigWithDeepLink } from 'src/utils/purchaseUtils';
import {
  EventWithData,
  Feature,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  UserSetting,
} from 'src/WebApiController';
import Swiper from 'swiper';

import * as styles from '../../Listings/InventoryEventPage/InventoryEventPage.css';
import { useCatchUpParams } from '../../Listings/InventoryEventPage/InventoryEventPage.hooks';
import { TagsSection } from '../../Listings/InventoryEventPage/Sections/TagsSection';
import { PurchaseEventFulfillmentSection } from './PurchaseEventFulfillmentSection';
import { PurchaseEventInfoSection } from './PurchaseEventInfoSection';
import { PurchaseEventPageHeader } from './PurchaseEventPageHeader';

export function PurchaseEventPage({
  viagVirtualId,
  posEventIds,
  returnUrl,
}: {
  posEventIds?: string[];
  viagVirtualId?: string;
  returnUrl?: string;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const {
    eventsTransformed,
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();

  useEffect(() => {
    if (!viagVirtualId && posEventIds?.length) {
      setListItemExpansion(
        true,
        posEventIds.map((id) => `old:${id}`)
      );
    } else if (viagVirtualId) {
      setListItemExpansion(true, [viagVirtualId]);
    }
  }, [
    activeAccountWebClientConfig,
    posEventIds,
    setListItemExpansion,
    viagVirtualId,
  ]);

  const { setAllGroupsIds } = useMultiSelectionContext();

  const accordionItemProps = useMemo(
    () =>
      eventsTransformed?.length
        ? eventsTransformed.find(
            (ev) =>
              ev.event.viagVirtualId === viagVirtualId ||
              ev.event.posIds.some((id) => posEventIds?.includes(id))
          )
        : undefined,
    [eventsTransformed, posEventIds, viagVirtualId]
  );

  const purchaseOrderAccordionItem = useMemo(
    () =>
      accordionItemProps &&
      translateEventToTicketGroupAccordionItem(accordionItemProps, 0, true),
    [accordionItemProps]
  );

  useEffect(() => {
    if (purchaseOrderAccordionItem) {
      setAllGroupsIds([purchaseOrderAccordionItem.itemId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrderAccordionItem]);

  return (
    <SidePanelProvider sidePanelId="purchase-event">
      <ActiveFocusContextProvider>
        <EventMapContextProvider event={accordionItemProps?.event}>
          <EventLastReviewedContextProvider>
            <Stack direction="column" height="full">
              <PurchaseEventContent
                accordionItemProps={accordionItemProps}
                purchaseOrderAccordionItem={purchaseOrderAccordionItem}
                returnUrl={returnUrl}
              />
            </Stack>
          </EventLastReviewedContextProvider>
        </EventMapContextProvider>
      </ActiveFocusContextProvider>
    </SidePanelProvider>
  );
}

type PurchaseEventContentProps = {
  accordionItemProps?: EventWithData;
  purchaseOrderAccordionItem?: EventAccordionItemProps;
  returnUrl?: string;
};

export const enum PurchaseEventTabs {
  Purchases = 'purchases',
  Tags = 'tags',
  Fulfillment = 'fulfillment',
  None = 'none',
}

function PurchaseEventContent({
  accordionItemProps,
  purchaseOrderAccordionItem,
  returnUrl,
}: PurchaseEventContentProps) {
  const intialActiveTab = PurchaseEventTabs.Purchases;

  const [selectedPurchaseOrderIds, setSelectedPurchaseOrderIds] = useState<
    number[]
  >([]);
  const [activeTab, setActiveTab] = useState(intialActiveTab);

  const { isLoading } = useCatalogDataContext();
  const isMobile = useMatchMedia('mobile');

  const quickFilterSearchParams = useQuickFilterSearchParam();
  const { tempQuery, initialQuery, filterQuery } =
    useFilterQueryContext<PurchaseOrderQuery>();

  const { setModal } = useContext(ModalContext);
  const { posEntity: purchaseOrder, setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();

  const hasEventLastReviewedFeature = useUserHasFeature(
    Feature.EventLastReviewed
  );
  const hasEventDeliveryStrategyPurchaseFeature = useUserHasFeature(
    Feature.EventDeliveryStrategyPurchase
  );
  const hasSidePanel = useUserHasFeature(Feature.FullPageEventViewSidePanel);

  const {
    value: defaultPurchasesTabUserSetting,
    setUserSetting: setDefaultPurchasesTabUserSetting,
    isLoading: defaultPurchasesTabLoading,
  } = useServerUserSetting<PurchaseEventTabs>({
    id: UserSetting.PurchasesDefaultTab,
  });

  const activeId = getDeepLinkIdFromUrl(
    PurchaseDeeplinkQueryParam,
    window.location.href
  );

  useEffect(() => {
    if (activeId?.length && !purchaseOrder) {
      setActivePosEntity(parseInt(activeId), undefined, true);
      setModal(getPurchaseOrderDetailsModalConfigWithDeepLink(activeId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  const [defaultTab, setDefaultTab] = useState<PurchaseEventTabs | undefined>(
    defaultPurchasesTabUserSetting
  );

  useEffect(() => {
    if (
      defaultPurchasesTabUserSetting != null &&
      defaultPurchasesTabUserSetting !== activeTab &&
      defaultPurchasesTabUserSetting !== PurchaseEventTabs.None &&
      defaultPurchasesTabLoading === false
    ) {
      setActiveTab(defaultPurchasesTabUserSetting);
    }
    if (
      defaultPurchasesTabUserSetting != null &&
      defaultPurchasesTabUserSetting !== defaultTab &&
      defaultPurchasesTabUserSetting !== PurchaseEventTabs.None &&
      defaultPurchasesTabLoading === false
    ) {
      setDefaultTab(defaultPurchasesTabUserSetting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultPurchasesTabLoading, defaultPurchasesTabUserSetting]);

  const onSetDefaultTabClick = useCallback(
    async (tab: PurchaseEventTabs) => {
      if (tab !== activeTab && tab !== PurchaseEventTabs.None) {
        setActiveTab(tab);
      }
      if (tab !== defaultTab) {
        setDefaultTab(tab);
      }
      setDefaultPurchasesTabUserSetting(tab);
    },
    [activeTab, defaultTab, setDefaultPurchasesTabUserSetting]
  );

  const swiperRef = useRef<Swiper>();

  const event = accordionItemProps?.event;
  const {
    isCompleted,
    isLoading: isEventReviewedLoading,
    onSettingVirtualIds,
    onUpdateLastReviewedDateTime,
  } = useEventLastReviewedContext();

  useEffect(() => {
    onSettingVirtualIds(event?.viagVirtualId ? [event?.viagVirtualId] : []);
  }, [event?.viagVirtualId, onSettingVirtualIds]);

  const { addNote, isLoading: isEventNoteLoading } = useSellerEventNote(
    event?.viagId
  );
  const markAsReviewed = useContent(ContentId.MarkAsReviewed);
  const markedAsReviewed = useContent(ContentId.MarkedAsReviewed);

  const { onNextClick, isCatchupMode } = useCatchUpParams();

  const onMarkAsReviewed = useCallback(async () => {
    if (event?.viagVirtualId) {
      await addNote(markedAsReviewed);
      onUpdateLastReviewedDateTime(event.viagVirtualId);
      onNextClick();
    }
  }, [
    addNote,
    event?.viagVirtualId,
    markedAsReviewed,
    onNextClick,
    onUpdateLastReviewedDateTime,
  ]);

  const poIds = useMemo(() => {
    return (
      purchaseOrderAccordionItem?.ticketGroups?.map((tg) => String(tg.id)) || []
    );
  }, [purchaseOrderAccordionItem?.ticketGroups]);

  const tabs: TabItem<PurchaseEventTabs>[] = [
    {
      value: PurchaseEventTabs.Purchases,
      label: <Content id={ContentId.Purchases} />,
      content: (
        <PurchaseEventInfoSection
          accordionItemProps={accordionItemProps}
          purchaseOrderAccordionItem={purchaseOrderAccordionItem}
          selectedPurchaseOrderIds={selectedPurchaseOrderIds}
          setSelectedPurchaseOrderIds={setSelectedPurchaseOrderIds}
        />
      ),
      className: styles.tabContent,
    },
    {
      value: PurchaseEventTabs.Tags,
      label: <Content id={ContentId.Tags} />,
      content: event?.viagVirtualId && (
        <TagsSection eventId={event?.viagVirtualId} />
      ),
    },
    ...(hasEventDeliveryStrategyPurchaseFeature && event?.viagId
      ? [
          {
            value: PurchaseEventTabs.Fulfillment,
            label: <Content id={ContentId.Delivery} />,
            content: (
              <PurchaseEventFulfillmentSection
                accordionItemProps={accordionItemProps}
                purchaseOrderAccordionItem={purchaseOrderAccordionItem}
              />
            ),
          },
        ]
      : []),
  ];

  if (!event) {
    if (
      quickFilterSearchParams ||
      getAppliedFilterCounts(tempQuery, initialQuery)
    )
      return (
        <EmptySectionContent.Root
          icon={
            <EmptySectionContent.SolidIconContainer>
              <SearchSolidIcon />
            </EmptySectionContent.SolidIconContainer>
          }
        >
          <EmptySectionContent.Label>
            <Content id={ContentId.NoResultFound} />
          </EmptySectionContent.Label>
          <EmptySectionContent.DetailMessage>
            <Content id={ContentId.NoFilteredNotifications} />
          </EmptySectionContent.DetailMessage>
        </EmptySectionContent.Root>
      );
  }

  if (isLoading || !event) {
    return <PosSpinner />;
  }

  return (
    <div className={styles.root}>
      <ExplorerLayout
        swiperRef={swiperRef}
        defaultRatio={0.35}
        maxPanelPercent={0.5}
        showExpandButton
        sidePanelPage={hasSidePanel ? SidePanelPage.PurchaseEvent : undefined}
        rightPanelContent={
          hasSidePanel ? (
            <SidePanel
              event={event}
              pageType="Purchases"
              selectedEntityIds={selectedPurchaseOrderIds}
              filterQuery={{
                ...filterQuery,
                marketplaceEntityIds:
                  filterQuery.marketplaceEntityIds &&
                  filterQuery.marketplaceEntityIds?.length > 0
                    ? poIds
                    : null, // We have to pass in poIds cause we use tktGrpIds for filtering since this is a purchase event
              }}
            />
          ) : undefined
        }
      >
        <div className={styles.leftContainer}>
          {isMobile && (
            <Stack
              justifyContent="spaceBetween"
              alignItems="center"
              style={{
                padding: `0 ${vars.spacing['xl']} ${vars.spacing['lg']} ${vars.spacing['xl']}`,
              }}
            >
              <BackSection
                returnUrl={returnUrl ?? '/purchases'}
                state={{
                  fromPosEventId: event.viagVirtualId,
                }}
              />
              <SwiperButton dir="right" />
            </Stack>
          )}
          <CollapsableViewProvider isEnabled={true}>
            <PurchaseEventPageHeader
              event={event}
              purchaseOrderAccordionItem={purchaseOrderAccordionItem}
            />
            <EventPageTabs
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              onSetDefaultTabClick={onSetDefaultTabClick}
              tabs={tabs}
            />
          </CollapsableViewProvider>
          {hasEventLastReviewedFeature && isCatchupMode && (
            <div className={styles.absoluteButton}>
              <LoadingButton
                title={markAsReviewed}
                isLoading={isEventReviewedLoading || isEventNoteLoading}
                isCompleted={isCompleted}
                onClick={onMarkAsReviewed}
              />
            </div>
          )}
        </div>
      </ExplorerLayout>
    </div>
  );
}
