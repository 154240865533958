import { debounce } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EventEntitySearchBoxV2 } from 'src/components/Accordions/EventEntitySearchBox/EventEntitySearchBoxV2';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { MultiSelectionToggleGlobal } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGlobal';
import { FilterToolbar } from 'src/components/FilterToolbar';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import { InventoryGlobalActionDropdown } from 'src/components/Listings/InventoryActionDropdown';
import { ListingBasicInfo } from 'src/components/Listings/ListingBasicInfo';
import {
  listingsMandatoryFiltersToShow,
  ViewModeSelector,
} from 'src/components/MainFilterBar';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { FormatContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { SearchResultPickerDialog } from 'src/dialogs/SearchResultPickerDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useFilterToolbarQuickFilters } from 'src/hooks/useQuickFilters';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { INVENTORY_VIEW_MODE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import {
  DefaultListingQuery,
  getQueryFromUrl,
} from 'src/utils/eventQueryUtils';
import { getListingDetailsModalConfigWithDeepLink } from 'src/utils/inventoryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Feature,
  InventoryViewMode,
  Listing,
  ListingClient,
  ListingDetails,
  ListingQuery,
  UserSetting,
} from 'src/WebApiController';
import Swiper from 'swiper';

import {
  EventEntitySearchBox,
  SearchMode,
} from '../../Accordions/EventEntitySearchBox/EventEntitySearchBox';
import { SidePanelButton } from '../../Buttons/SidePanelButton';
import { SwiperButton } from '../../Buttons/SwiperButton';
import { BackSection } from '../../common/BackSection';
import { useListingFilters } from '../../MainFilterBar';
import * as styles from '../../MainFilterBar/MainFilterBar.css';
import { useDefaultListingFilter } from './hooks/useDefaultListingFilter';
import { INVENTORY_VIEW_MODE_TO_ICON } from './ListingFilterBar.types';

export function ListingFilterBar({
  isSearchOnly,
  isEventPage = false,
  returnUrl,
  posEventId,
  swiperRef,
}: {
  isSearchOnly?: boolean;
  isEventPage?: boolean;
  returnUrl?: string;
  posEventId?: string;
  swiperRef?: React.MutableRefObject<Swiper | undefined>;
}) {
  const activeId = getDeepLinkIdFromUrl(
    InventoryDeeplinkQueryParam,
    window.location.href
  );

  const [searchId, setSearchId] = useState<string>();

  const [searchResults, setSearchResults] = useState<Listing[]>();
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [searchText, setSearchText] = useState('');

  const isMobile = useMatchMedia('mobile');
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const { setModal, isModalOpen } = useContext(ModalContext);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { filters } = useListingFilters(isEventPage);

  const useFiltersDialogV2 = useUserHasFeature(Feature.FilterToolBarUIV2);
  const hasEventEntitySearchV2 = useUserHasFeature(Feature.EventEntitySearchV2);
  const hasFullPageEventViewEntitySelection = useUserHasFeature(
    Feature.FullPageEventViewEntitySelection
  );

  const { posEntity: listing, setActivePosEntity } =
    useActivePosEntityContext<ListingDetails>();

  const {
    filterQuery: listingQuery,
    initialQuery,
    tempQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<ListingQuery>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      if (text !== listingQuery.searchText) {
        setFilterQuery({
          ...listingQuery,
          searchText: text || null,
        });
      }
    }, 200),
    [listingQuery]
  );

  const defaultQuickFilters = useDefaultListingFilter({
    initialQuery,
    listingQuery,
  });

  const clearSearchEntity = useCallback(() => {
    const newQuery = {
      ...tempQuery,
      searchText: null,
      entityIds: null,
      marketplaceEntityIds: null,
    };
    setFilterQuery(newQuery);
  }, [tempQuery, setFilterQuery]);

  const location = useLocation();

  useEffect(() => {
    if (!hasFullPageEventViewEntitySelection) {
      return;
    }

    // Since we only support id searching in flattened view for Listings, reset filters when view mode changes
    if (
      listingQuery.viewMode !== InventoryViewMode.FlattenedView &&
      !isEventPage &&
      listingQuery.marketplaceEntityIds
    ) {
      setFilterQuery({
        ...listingQuery,
        marketplaceEntityIds: null,
        searchText: null,
      });
      setSearchText('');
    }
  }, [
    listingQuery.marketplaceEntityIds,
    listingQuery.viewMode,
    isEventPage,
    listingQuery,
    setFilterQuery,
    hasFullPageEventViewEntitySelection,
  ]);

  useEffect(() => {
    if (!hasFullPageEventViewEntitySelection) {
      return;
    }

    const queryFromUrl = getQueryFromUrl<ListingQuery>(
      initialQuery,
      location.search
    );

    if (
      Array.isArray(queryFromUrl?.marketplaceEntityIds) &&
      queryFromUrl?.marketplaceEntityIds.length
    ) {
      setSearchText((prev) =>
        queryFromUrl?.marketplaceEntityIds
          ? queryFromUrl?.marketplaceEntityIds.join(',')
          : prev
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFullPageEventViewEntitySelection, initialQuery, setFilterQuery]);

  const onLookupById = useCallback(
    async (id: string, isSearching: boolean) => {
      if (
        !id &&
        tempQuery.marketplaceEntityIds &&
        hasFullPageEventViewEntitySelection
      ) {
        const newQuery = {
          ...tempQuery,
          entityIds: null,
          marketplaceEntityIds: null,
        };
        setFilterQuery({ ...newQuery });
        return;
      }

      if (id && !isLoadingSearch) {
        if (listing?.idOnMkp === id || listing?.id === Number(id)) {
          // If the id hasn't changed, check that if it's open, if it's not, open it
          if (!isModalOpen) {
            setModal(getListingDetailsModalConfigWithDeepLink(listing.id));
          }

          // Else, just don't do anything
          return;
        }

        if (hasFullPageEventViewEntitySelection) {
          const matchedIds = id
            .split(',')
            .filter((s) => s.trim())
            .map((s) => s.trim()); // split by commas and remove all empty strings

          if (matchedIds.length > 1) {
            const newQuery = {
              ...DefaultListingQuery,
              entityIds: null,
              marketplaceEntityIds: matchedIds,
              viewMode: InventoryViewMode.FlattenedView,
            };
            setFilterQuery(newQuery);
            return;
          } else if (tempQuery.marketplaceEntityIds) {
            const newQuery = {
              ...tempQuery,
              entityIds: null,
              marketplaceEntityIds: null,
            };
            setFilterQuery(newQuery);
          }
        }

        setSearchId(id);
        setIsLoadingSearch(true);

        try {
          // when user enter a text here, we don't know whether they entered the listing id or unique id
          // since 99% of the time they'll be entering a listing id, we try to lookup by that first
          const listings = isSearching
            ? await tryInvokeApi(
                () =>
                  new ListingClient(
                    activeAccountWebClientConfig
                  ).getListingByMarketplaceListingId(id),
                (error) => {
                  showErrorDialog(
                    'ListingClient.getListingByMarketplaceListingId',
                    error,
                    { trackErrorData: { listingId: id } }
                  );
                }
              )
            : null;

          if (!listings || listings.length === 0) {
            const idNum = Number(id);

            if (isNaN(idNum)) {
              // If we can't find by either PO external id or internal id, just launch empty search result dialog
              launchDialog();
              return false;
            }

            // If we didn't find any listings by market-place-listing-id, assume this is a unique id search
            setActivePosEntity(idNum, undefined, true);
            setModal(getListingDetailsModalConfigWithDeepLink(idNum));

            return true;
          } else {
            // We have listings using the market place id
            // 99% of the time, the id should be unique, but if we support more than 1 marketplaces
            // the ids may collide and return more than one result, therefore we need to present to the user and ask them what they want
            if (listings.length === 1) {
              const listingInfo = listings[0];
              // happy path
              setActivePosEntity(listingInfo.id, listingInfo.idOnMkp, true);
              setModal(
                getListingDetailsModalConfigWithDeepLink(listingInfo.id, true)
              );

              return true;
            } else {
              setSearchResults(listings);
              launchDialog();
            }
          }
        } finally {
          setIsLoadingSearch(false);
        }
      }

      return false;
    },
    [
      activeAccountWebClientConfig,
      hasFullPageEventViewEntitySelection,
      isLoadingSearch,
      isModalOpen,
      launchDialog,
      listing?.id,
      listing?.idOnMkp,
      setActivePosEntity,
      setFilterQuery,
      setModal,
      showErrorDialog,
      tempQuery,
    ]
  );

  const onResultItemPicked = useCallback(
    (listing: Listing) => {
      setActivePosEntity(listing.id, listing.idOnMkp, true);
      setModal(getListingDetailsModalConfigWithDeepLink(listing.id, true));

      // We do not want to close the search dialog as users may want to click each one
      // We'll let them close the search dialog manually
    },
    [setActivePosEntity, setModal]
  );

  useEffect(() => {
    setTempQuery(listingQuery);
  }, [listingQuery, setTempQuery]);

  useEffect(() => {
    if (
      listingQuery.marketplaceEntityIds &&
      hasFullPageEventViewEntitySelection
    ) {
      return;
    }
    setSearchText(listingQuery.searchText ?? '');
  }, [
    hasFullPageEventViewEntitySelection,
    listingQuery.marketplaceEntityIds,
    listingQuery.searchText,
  ]);

  useEffect(() => {
    if (activeId?.length) {
      onLookupById(activeId, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  const { value: defaultViewModeUserSetting, setUserSetting } =
    useServerUserSetting<InventoryViewMode>({
      id: UserSetting.InventoryPageViewMode,
      currentLoginUserOnly: true,
    });

  const filterToolbarProps = useFilterToolbarQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStateInventory,
    customQuickFiltersSetting: UserSetting.QuickFiltersCustomInventory,
    defaultQuickFilters,
    currentQuery: listingQuery,
    initialQuery,
    onSelect: ({ query }) => {
      if (query.viewMode != null) {
        // We need to update initialQuery, otherwise the quick filter "All"
        // may change the current view mode
        if (query.viewMode !== initialQuery.viewMode) {
          initialQuery.viewMode = query.viewMode;
        }
      }

      setFilterQuery(query);

      // This needs to happen after to reduce re-rendering
      if (query.viewMode != null) {
        // Update user setting view mode too so it won't revert the filter query viewMode
        if (query.viewMode !== defaultViewModeUserSetting) {
          setUserSetting(query.viewMode);
        }
      }
    },
  });

  const { appliedFilters } = useFiltersHelpers({
    filters,
    activeQuery: listingQuery,
    mandatoryFiltersToShow: listingsMandatoryFiltersToShow,
    initialQuery,
  });

  const filterToolbar = listingQuery && (
    <FilterToolbar
      {...filterToolbarProps}
      filterAppliedCounts={appliedFilters.length}
      filterAppliedIds={appliedFilters}
      onSubmitFilter={() => setFilterQuery(tempQuery)}
      filters={filters}
      resetTempQuery={resetTempQuery}
      tempQuery={tempQuery}
      mandatoryFiltersToShow={listingsMandatoryFiltersToShow}
      useNewFilterUX={useFiltersDialogV2}
    />
  );

  return (
    <>
      {isSearchOnly ? (
        <div className={styles.mainFilterBarDiv}>
          <div className={styles.dockLeftDiv}>
            <div className={styles.mainFilterBarLeftChild}>
              <SwiperButton dir="left" swiperRef={swiperRef} />
            </div>
          </div>
          <div className={styles.dockRightDiv}>
            <div className={styles.mainFilterBarRightChild}>
              <BulkEditStatusPopover
                entityType={ActionOutboxEntityType.Listing}
              />
              {!isMobile && (
                <ViewModeSelector
                  query={listingQuery}
                  initialQuery={initialQuery}
                  setFilterQuery={setFilterQuery}
                  viewModeSettingsId={UserSetting.InventoryPageViewMode}
                  viewModeCidMap={INVENTORY_VIEW_MODE_TO_CID}
                  viewModeIconMap={INVENTORY_VIEW_MODE_TO_ICON}
                />
              )}
              <MultiSelectionToggleGlobal />
              {!isEventPage && (
                <>
                  <InventoryGlobalActionDropdown />
                  {hasEventEntitySearchV2 ? (
                    <EventEntitySearchBoxV2
                      maxWidth={'auto'}
                      disabled={isLoadingSearch}
                      onIdLookup={(value) => onLookupById(value, true)}
                      onEventSearch={(value) => debouncedOnSearchChange(value)}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      clearSearchEntity={clearSearchEntity}
                    />
                  ) : (
                    <EventEntitySearchBox
                      entityLookupPlaceholder={ContentId.LookupByListingId}
                      maxWidth="400px"
                      disabled={isLoadingSearch}
                      onIdLookup={(value) => value && onLookupById(value, true)}
                      onEventSearch={(value) => debouncedOnSearchChange(value)}
                      searchText={searchText}
                      setSearchText={setSearchText}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.mainFilterBarDiv}>
          {isMobile ? (
            filterToolbar
          ) : (
            <>
              <div className={styles.dockLeftDiv}>
                <div className={styles.mainFilterBarLeftChild}>
                  {isEventPage && (
                    <BackSection
                      returnUrl={returnUrl ?? '/inventory'}
                      state={{
                        fromPosEventId: posEventId,
                      }}
                    />
                  )}
                  {listingQuery.viewMode === InventoryViewMode.MetricView && (
                    <SidePanelButton position="relative" />
                  )}
                  {filterToolbar}
                </div>
              </div>
              <div className={styles.dockRightDiv}>
                <div className={styles.mainFilterBarRightChild}>
                  {!isEventPage && (
                    <>
                      <BulkEditStatusPopover
                        entityType={ActionOutboxEntityType.Listing}
                      />
                      <ViewModeSelector
                        query={listingQuery}
                        initialQuery={initialQuery}
                        setFilterQuery={setFilterQuery}
                        viewModeSettingsId={UserSetting.InventoryPageViewMode}
                        viewModeCidMap={INVENTORY_VIEW_MODE_TO_CID}
                        viewModeIconMap={INVENTORY_VIEW_MODE_TO_ICON}
                      />
                      <MultiSelectionToggleGlobal />
                      <InventoryGlobalActionDropdown />
                      {hasEventEntitySearchV2 ? (
                        <EventEntitySearchBoxV2
                          maxWidth={'auto'}
                          disabled={isLoadingSearch}
                          onIdLookup={(value) => onLookupById(value, true)}
                          onEventSearch={(value) =>
                            debouncedOnSearchChange(value)
                          }
                          searchText={searchText}
                          setSearchText={setSearchText}
                          clearSearchEntity={clearSearchEntity}
                        />
                      ) : (
                        <EventEntitySearchBox
                          entityLookupPlaceholder={ContentId.LookupByListingId}
                          maxWidth={350}
                          disabled={isLoadingSearch}
                          onIdLookup={(value) =>
                            value && onLookupById(value, true)
                          }
                          onEventSearch={(value) =>
                            debouncedOnSearchChange(value)
                          }
                          searchText={searchText}
                          setSearchText={setSearchText}
                        />
                      )}
                    </>
                  )}
                  {isEventPage && hasFullPageEventViewEntitySelection && (
                    <EventEntitySearchBox
                      maxWidth={'auto'}
                      disabled={isLoadingSearch}
                      onIdLookup={(value) => onLookupById(value, true)}
                      onEventSearch={(value) => debouncedOnSearchChange(value)}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      entityLookupPlaceholder={ContentId.LookupByListingId}
                      defaultSearchMode={SearchMode.IdLookup}
                      freezeSearchMode
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <SearchResultPickerDialog
        {...dialogProps}
        header={
          <FormatContent
            id={
              searchResults?.length
                ? FormatContentId.SearchResultPick
                : FormatContentId.CouldNotFindListingId
            }
            params={[searchId || '', `${searchResults?.length || 0}`]}
          />
        }
        resultItems={searchResults || []}
        renderItem={(listing: Listing) => (
          <ListingBasicInfo listing={listing} />
        )}
        onResultItemPicked={onResultItemPicked}
        onCancel={closeDialog}
      />
    </>
  );
}
