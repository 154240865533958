import { useFormContext } from 'react-hook-form';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { Content } from 'src/contexts/ContentContext';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useUserCanUpdateSale } from 'src/hooks/useUserHasSalePermissions';
import { Detail } from 'src/modals/common';
import { DetailGroup } from 'src/modals/common';
import { modalSection } from 'src/modals/common/Modals.css';
import {
  EMPTY_SELLER_USER_ID,
  FULFILLMENT_PROVIDER_SELLER_USER_IDS,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { Feature, Permission, Sale, SaleInput } from 'src/WebApiController';

export const FulfillmentSection = ({
  sale,
  isBulkEdit,
}: {
  sale?: Sale | null;
  isBulkEdit?: boolean;
}) => {
  const canUpdate = useUserCanUpdateSale(sale) || isBulkEdit;
  const canChangeFulfiller =
    useUserHasAnyOfPermissions(Permission.Sales_Fulfill) && canUpdate;
  const { watch, setValue } = useFormContext<SaleInput>();
  const hasFulfillmentProvidersFeature = useUserHasFeature(
    Feature.FulfillmentProviders
  );

  const fulfillerId = watch('fulfillerSellerUserId');

  return (
    <div className={modalSection}>
      <DetailGroup>
        <Detail
          label={<Content id={ContentId.FulfilledBy} />}
          detail={
            <SellerAccountEmployeeSelector
              value={
                fulfillerId?.value ??
                (isBulkEdit ? EMPTY_SELLER_USER_ID : undefined)
              }
              disabled={!canChangeFulfiller}
              allowUnchanged={isBulkEdit}
              enableEmptySelection
              style={{ width: '100%' }}
              onChange={(newId) => {
                if (newId !== fulfillerId?.value) {
                  setValue('fulfillerSellerUserId', posChangedField(newId), {
                    shouldDirty: true,
                  });
                  if (FULFILLMENT_PROVIDER_SELLER_USER_IDS.has(newId)) {
                    setValue('isNoFulfill', posChangedField(true), {
                      shouldDirty: true,
                    });
                  }
                }
              }}
              onClick={(e) => e.stopPropagation()}
              isFulfillmentSection={hasFulfillmentProvidersFeature}
            />
          }
        />
      </DetailGroup>
    </div>
  );
};
