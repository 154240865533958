import { useCallback, useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { Switch } from 'src/core/interim/Switch';
import { MultiSelectDropBoxWithPills } from 'src/core/POS/MultiSelectDropBoxWithPills';
import { PosSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useAutoCurrencyConversionSettings } from 'src/hooks/api/useAutoCurrencyConversionSettings';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './MyAccount.css';

export const CurrencySection = () => {
  const { currencyCache } = useLocalizationContext();
  const {
    autoCurrencyConversionSettings,
    mergeAutoCurrencyConversionSettings,
  } = useAutoCurrencyConversionSettings();

  const onDefaultCurrencyChange = useCallback(
    (currencyCode: string) => {
      if (!autoCurrencyConversionSettings) return;

      mergeAutoCurrencyConversionSettings({
        input: {
          ...autoCurrencyConversionSettings,
          defaultCurrencyCode: currencyCode,
          ignoredCurrencyCodes:
            autoCurrencyConversionSettings?.ignoredCurrencyCodes?.filter(
              (code) => code !== currencyCode
            ) ?? [],
        },
      });
    },
    [autoCurrencyConversionSettings, mergeAutoCurrencyConversionSettings]
  );

  const onIgnoredCurrencyCodesChange = useCallback(
    (values: string[]) => {
      if (!autoCurrencyConversionSettings) return;

      mergeAutoCurrencyConversionSettings({
        input: {
          ...autoCurrencyConversionSettings,
          ignoredCurrencyCodes: values,
        },
      });
    },
    [autoCurrencyConversionSettings, mergeAutoCurrencyConversionSettings]
  );

  const onEnabledToggle = useCallback(() => {
    if (!autoCurrencyConversionSettings) return;

    mergeAutoCurrencyConversionSettings({
      input: {
        ...autoCurrencyConversionSettings,
        enabled: !(autoCurrencyConversionSettings?.enabled ?? false),
      },
    });
  }, [autoCurrencyConversionSettings, mergeAutoCurrencyConversionSettings]);

  const availableCurrenciesOptions = useMemo(
    () =>
      Object.values(currencyCache || []).reduce(
        (result, cur) => {
          result[cur.code] = cur.code;
          return result;
        },
        {} as Record<string, string>
      ),
    [currencyCache]
  );

  const availableCurrenciesOptionsWithoutDefault = useMemo(
    () =>
      Object.values(currencyCache || []).reduce(
        (result, cur) => {
          if (
            cur.code !== autoCurrencyConversionSettings?.defaultCurrencyCode
          ) {
            result[cur.code] = cur.code;
          }
          return result;
        },
        {} as Record<string, string>
      ),
    [autoCurrencyConversionSettings?.defaultCurrencyCode, currencyCache]
  );

  return (
    <div
      className={styles.sectionContainer}
      style={{ gap: vars.spacing['xl'] }}
    >
      <h5 className={styles.sectionHeading}>
        <Content id={ContentId.Currency} />
      </h5>

      <Stack direction="column" gap="m">
        <div className={styles.label}>
          <Content id={ContentId.DefaultCurrency} />
        </div>
        <p className={styles.subtleText}>
          <Content id={ContentId.CurrencySectionSubtitle} />
        </p>
        <PosSelect
          disabled={autoCurrencyConversionSettings == null}
          className={styles.select}
          value={
            autoCurrencyConversionSettings?.defaultCurrencyCode ?? undefined
          }
          placeholderText={ContentId.Select}
          searchable
          valueOptionsContent={availableCurrenciesOptions}
          onChange={onDefaultCurrencyChange}
        />
      </Stack>

      <Stack direction="column" gap="m">
        <Stack direction="row" gap="m" alignItems="center">
          <Switch
            disabled={autoCurrencyConversionSettings == null}
            checked={autoCurrencyConversionSettings?.enabled ?? false}
            onChange={onEnabledToggle}
          />
          <div className={styles.label}>
            <Content id={ContentId.AutoConversion} />
          </div>
        </Stack>
        <p className={styles.subtleText}>
          <Content id={ContentId.AutoConversionSubtitle} />
        </p>
      </Stack>
      {autoCurrencyConversionSettings?.enabled && (
        <Stack direction="column" gap="m">
          <div className={styles.label}>
            <Content id={ContentId.ExclusionCurrencies} />
          </div>
          <p className={styles.subtleText}>
            <Content id={ContentId.ExclusionCurrenciesSubtitle} />
          </p>
          <MultiSelectDropBoxWithPills
            style={{ width: '100%' }}
            side="top"
            disabled={autoCurrencyConversionSettings == null}
            valueOptionsContent={availableCurrenciesOptionsWithoutDefault}
            placeholderText={ContentId.Select}
            values={autoCurrencyConversionSettings?.ignoredCurrencyCodes ?? []}
            onChange={onIgnoredCurrencyCodesChange}
            searchable
          />
        </Stack>
      )}
    </div>
  );
};
