import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { useAnyMarketplaceHandledByProcessor } from 'src/hooks/useMarketplaceHandledByProcessor';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType, Feature } from 'src/WebApiController';

import { useBulkUpdateAutoFulfillPermissions } from './useBulkUpdateAutoFulfillPermissions';

export const LaunchBulkUpdateAutoFulfill = ({
  onClick,
  disabled,
  entityType,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  entityType: ActionOutboxEntityType.Listing | ActionOutboxEntityType.Sale;
}) => {
  const { hasEditAutoFulfillPermissions } = useBulkUpdateAutoFulfillPermissions(
    {
      entityType,
    }
  );
  const hasAutoFulfillFeature = useUserHasFeature(Feature.AutoFulfillSetting);
  const anyMkpHandledByProcessor = useAnyMarketplaceHandledByProcessor();

  if (
    !anyMkpHandledByProcessor ||
    !hasEditAutoFulfillPermissions ||
    !hasAutoFulfillFeature
  ) {
    return null;
  }

  return (
    <PosDropdownItem
      key="UpdateAutoFulfill"
      onClick={onClick}
      disabled={disabled}
    >
      <Content id={ContentId.UpdateAutoFulfill} />
    </PosDropdownItem>
  );
};
