import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PurchaseClient } from 'src/WebApiController';

export function useGetAccessiblePurchasePaymentMethodsQuery() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  return useQuery({
    queryKey: [
      'getAccessiblePurchasePaymentMethods',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new PurchaseClient(
        activeAccountWebClientConfig
      ).getAccessiblePurchasePaymentMethods();
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PurchaseClient.getAccessiblePurchasePaymentMethods', error);
      },
    },
  });
}
