import { HubConnectionState } from '@microsoft/signalr';
import { useQuery } from '@tanstack/react-query';
import {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmButton } from 'src/components/Buttons';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useInventoryEventBulkActionsState } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/useInventoryEventBulkActionsState';
import { INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY } from 'src/components/Listings/InventoryActionDropdown';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useBulkEditHubContext } from 'src/contexts/BulkEditHubContext';
import { BulkEditStage } from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import * as Tabs from 'src/core/ui/Tabs';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { useEventMarketplaceSettings } from 'src/hooks/useEventMarketplaceSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { MergeListingGroupInputListFields } from 'src/modals/GroupListings/components/groupingTypes';
import { GroupListingsBody } from 'src/modals/GroupListings/GroupListingsBody';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  AutoCompAndAutoGroupMode,
  BulkActionType,
  BulkEditListingClient,
  ConfigTypeOverride,
  Event,
  Feature,
  GroupingType,
  ListingGroupClient,
  MergeListingGroupInput,
  RowCompOverride,
  RowGroupSetting,
  ZoneSectionAreaGroupLookup,
} from 'src/WebApiController';

import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';

enum GroupListingsTab {
  Groupings = 'groupings',
}

export const GroupListingsAutoGroupListingsV2 = ({
  event,
  mode,
  rowCompOffset,
  rowGroupSetting,
  configTypeOverride,
  combineCornersAndEnds,
  rowCompOverrides,
  sectionGroupCustomRankEnabled,
  lookup,
  cancelTo,
  isLoading,
}: {
  event: Event;
  mode: AutoCompAndAutoGroupMode;
  rowCompOffset: number;
  rowGroupSetting: RowGroupSetting;
  configTypeOverride: ConfigTypeOverride;
  combineCornersAndEnds: boolean;
  rowCompOverrides: RowCompOverride[];
  sectionGroupCustomRankEnabled: boolean;
  lookup: ZoneSectionAreaGroupLookup;
  cancelTo?: ModalProps;
  isLoading: boolean;
}) => {
  const methods = useForm<MergeListingGroupInputListFields>({
    defaultValues: {
      mergeListingGroupInputs: [],
      templateSettings: {
        groupBy: {
          groupingType: GroupingType.Custom,
        },
        desiredActiveListings: 0,
        deprioritizedQuantities: [],
      },
      eventSettings: [
        {
          groupBys: [
            {
              groupingType: GroupingType.None,
            },
          ],
          desiredActiveListings: 0,
          deprioritizedQuantities: [],
        },
      ],
      configTypeOverride: configTypeOverride,
      combineCornersAndEnds: combineCornersAndEnds,
      zoneSectionAreaGroupLookup: lookup,
      rowCompOffset: rowCompOffset,
      rowGroupSetting: rowGroupSetting,
      rowCompOverrides: rowCompOverrides,
      sectionGroupCustomRankEnabled: sectionGroupCustomRankEnabled,
    },
  });

  return isLoading ? (
    <PosSpinner />
  ) : (
    <FormProvider {...methods}>
      <GroupListingsAutoGroupListingsContent
        event={event}
        mode={mode}
        cancelTo={cancelTo}
        {...methods}
      />
    </FormProvider>
  );
};

export const GroupListingsAutoGroupListingsContent = ({
  event,
  mode,
  formState,
  handleSubmit,
  watch,
  reset,
  setValue,
  setError,
}: {
  event: Event;
  mode: AutoCompAndAutoGroupMode;
  cancelTo?: ModalProps;
} & Omit<
  ComponentProps<
    typeof FormProvider<MergeListingGroupInputListFields, unknown>
  >,
  'children'
>) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const hasBulkActionGroupListingsFeature = useUserHasFeature(
    Feature.BulkActionGroupListings
  );

  const { filterQueryWithEventIds } = useInventoryEventBulkActionsState(event);

  const eventListingIds = useMemo(
    () =>
      hasBulkActionGroupListingsFeature
        ? filterQueryWithEventIds.entityIds ?? []
        : [],
    [filterQueryWithEventIds.entityIds, hasBulkActionGroupListingsFeature]
  );

  // Values needed for the autoGroupListingsPreviewQuery
  const viagogoEventId = event?.viagId;
  const rowCompOffset = watch('rowCompOffset');
  const rowGroupSetting = watch('rowGroupSetting');
  const rowCompOverrides = watch('rowCompOverrides');
  const configTypeOverride = watch('configTypeOverride');
  const combineCornersAndEnds = watch('combineCornersAndEnds');
  const useCustomRank = watch('sectionGroupCustomRankEnabled');
  const lookup = watch('zoneSectionAreaGroupLookup');
  const mergeListingGroupInputs = watch('mergeListingGroupInputs');

  const requiredMsg = useContent(ContentId.Required);

  const shouldQuery = Boolean(
    viagogoEventId &&
      eventListingIds != null &&
      configTypeOverride &&
      combineCornersAndEnds != null &&
      rowCompOffset != null &&
      rowGroupSetting &&
      rowCompOverrides &&
      !!lookup
  );

  const autoGroupListingsPreviewQuery = useQuery({
    queryKey: [
      'ListingGroupClient.autoGroupListingsPreviewV2',
      viagogoEventId,
      configTypeOverride,
      combineCornersAndEnds,
      rowCompOffset,
      rowGroupSetting,
      JSON.stringify(eventListingIds),
      JSON.stringify(lookup?.sectionIdToGroupIdMap),
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const result = await new ListingGroupClient(
        activeAccountWebClientConfig
      ).autoGroupListingsPreviewV2({
        viagogoEventId: viagogoEventId!,
        eventListingIds: eventListingIds!,
        configTypeOverride: configTypeOverride!,
        combineCornersAndEnds: combineCornersAndEnds!,
        rowCompOffset: rowCompOffset!,
        rowGroupSetting: rowGroupSetting!,
        rowCompOverrides: rowCompOverrides!,
        useCustomRank: useCustomRank,
        zoneSectionAreaGroupLookupJson: JSON.stringify(lookup!),
      });

      if (isSuccess(result)) {
        return result.data;
      } else {
        showErrorDialog(
          'ListingGroupClient.autoGroupListingsPreviewV2',
          { message: result.message, status: result.status },
          {
            trackErrorData: {
              viagogoEventId,
              configTypeOverride,
              combineCornersAndEnds,
            },
          }
        );
      }
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog(
          'ListingGroupClient.autoGroupListingsPreviewV2',
          error,
          {
            trackErrorData: {
              viagogoEventId,
              configTypeOverride,
            },
          }
        );
      },
    },
  });

  const { defaultListingMarketplaceSettings } =
    useEventMarketplaceSettings(event);

  useEffect(() => {
    if (autoGroupListingsPreviewQuery.data) {
      const newJson = JSON.stringify(autoGroupListingsPreviewQuery.data);
      const curJson = JSON.stringify(mergeListingGroupInputs);
      if (newJson !== curJson) {
        const filteredData = autoGroupListingsPreviewQuery.data.filter(
          (item): item is MergeListingGroupInput => item !== null
        );

        setValue('mergeListingGroupInputs', filteredData);
      }
    }
  }, [
    autoGroupListingsPreviewQuery.data,
    defaultListingMarketplaceSettings,
    mergeListingGroupInputs,
    setValue,
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitting } = formState;
  const { setActivePosEntity } = useActivePosEntityContext();
  const { closeModal } = useContext(ModalContext);

  const validateForErrors = useCallback(() => {
    let hasErrors = false;
    mergeListingGroupInputs.forEach((m, i) => {
      if (!m.name) {
        setError(`mergeListingGroupInputs.${i}.name`, {
          message: requiredMsg,
        });
        hasErrors = true;
      }
    });

    return !hasErrors;
  }, [mergeListingGroupInputs, requiredMsg, setError]);

  const {
    eventsTransformed,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const eventData = useMemo(
    () =>
      event
        ? eventsTransformed?.filter(
            (ev) => ev.event.viagVirtualId === event.viagVirtualId
          ) ?? []
        : [],
    [eventsTransformed, event]
  );

  const [formIsDone, setFormIsDone] = useState(false);

  const { setProgress, setPreview, setStage, setMainDialogOpened } =
    useBulkEditHubContext();
  const { bulkEditHub, progress, errors, warnings, preview, stage, initJob } =
    useBulkEditHub(
      ActionOutboxEntityType.Listing,
      BulkActionType.GroupListings,
      INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY,
      () => {
        setActivePosEntity(0);
        setFormIsDone(true);
        return refreshExpandedListItems();
      }
    );

  const [activeTab, setActiveTab] = useState<GroupListingsTab>(
    GroupListingsTab.Groupings
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const onSubmit = useCallback(
    (inputFormData: MergeListingGroupInputListFields) => {
      setIsLoading(true);

      tryInvokeApi(
        async () => {
          initJob();

          const filteredListingGroupInputs =
            inputFormData.mergeListingGroupInputs
              .map((groupInput) => ({
                ...groupInput,
                listingGroupItems: groupInput.listingGroupItems.filter(
                  (x) => x.listingId > 0
                ),
              }))
              .filter((m) => m.listingGroupItems.length);

          const succeeded = await new BulkEditListingClient(
            activeAccountWebClientConfig
          ).bulkAutoCompAndGroupListingsV2(
            {
              mode: mode,
              viagogoEventId: viagogoEventId ?? 0,
              eventListingIds: eventListingIds!,
              configTypeOverride: configTypeOverride!,
              combineCornersAndEnds: combineCornersAndEnds!,
              rowCompOffset: rowCompOffset!,
              rowGroupSetting: rowGroupSetting!,
              rowCompOverrides: rowCompOverrides!,
              useCustomRank: useCustomRank,
              zoneSectionAreaGroupLookupJson: JSON.stringify(lookup),
              mergeListingGroupInputs: filteredListingGroupInputs,
            },
            INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY,
            hasBackgroundBulkEditFeature
          );

          reset(inputFormData);

          if (!hasBackgroundBulkEditFeature) {
            if (succeeded) {
              setActivePosEntity(0);
              await refreshExpandedListItems();

              closeModal();
            }
          }
        },
        (error) => {
          showErrorDialog(
            'BulkEditListingClient.bulkAutoCompAndGroupListingsV2',
            error,
            {
              trackErrorData: inputFormData,
            }
          );
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      initJob,
      activeAccountWebClientConfig,
      mode,
      viagogoEventId,
      eventListingIds,
      configTypeOverride,
      combineCornersAndEnds,
      rowCompOffset,
      rowGroupSetting,
      rowCompOverrides,
      useCustomRank,
      lookup,
      hasBackgroundBulkEditFeature,
      reset,
      setActivePosEntity,
      refreshExpandedListItems,
      closeModal,
      showErrorDialog,
    ]
  );

  return (
    <>
      <CancellableFormHeader
        disabled={isLoading || isSubmitting}
        onBeforeClose={() => {
          setProgress(undefined);
          setPreview(undefined);
          setStage(BulkEditStage.Idle);
          setMainDialogOpened(false);
        }}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.GroupListings} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        <>
          <ModalBodyHeaderContainer>
            {<Summary event={event} />}
          </ModalBodyHeaderContainer>
          <ModalBodyDataContainer>
            {isLoading || !event || autoGroupListingsPreviewQuery.isLoading ? (
              <PosSpinner />
            ) : (
              <BulkEditStatus
                entityType={ActionOutboxEntityType.Listing}
                updateKey={INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY}
                height={'full'}
              >
                {
                  // Even that we have only 1 event-map context, this is ok because it is expected // that eventData is a list of events with the same venue config
                }
                <EventMapContextProvider event={event}>
                  <Tabs.Root
                    value={activeTab}
                    onValueChange={(value) =>
                      setActiveTab(value as GroupListingsTab)
                    }
                    style={{ height: '100%' }}
                  >
                    <Tabs.List>
                      <Tabs.Trigger value={GroupListingsTab.Groupings}>
                        <Content id={ContentId.GroupListings} />
                      </Tabs.Trigger>
                    </Tabs.List>
                    <Tabs.Content
                      value={GroupListingsTab.Groupings}
                      style={{ height: '90%' }}
                    >
                      <GroupListingsBody
                        events={eventData}
                        listingGroup={undefined}
                        onEventSelected={() => {}}
                        isViewingTemplate={false}
                      />
                    </Tabs.Content>
                  </Tabs.Root>
                </EventMapContextProvider>
              </BulkEditStatus>
            )}
          </ModalBodyDataContainer>
        </>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          disabled={isLoading || isSubmitting}
          onBeforeClose={() => {
            setProgress(undefined);
            setPreview(undefined);
            setStage(BulkEditStage.Idle);
            setMainDialogOpened(false);
          }}
        >
          {!formIsDone && (
            <ConfirmButton
              onClick={() => {
                setMainDialogOpened(true);
                if (validateForErrors()) {
                  handleSubmit(onSubmit)();
                }
              }}
              icon={undefined}
              disabled={
                isLoading ||
                isSubmitting ||
                mergeListingGroupInputs
                  .map((m) => m.listingGroupItems.length)
                  .reduce((sum, cur) => sum + cur, 0) === 0
              }
              textContentId={ContentId.Save}
            />
          )}
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
