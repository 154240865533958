import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const PageIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 14 14">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8854 4.85747V4.1908L8.86022 1.16699H2.04163V12.8337H11.8854V5.80985L10.925 5.33366V11.8813H3.00199V2.11937H8.16396V4.85747H10.925H11.8854ZM9.12432 3.90509V2.78604L10.2528 3.90509H9.12432Z"
        fill={props.fill || '#87909A'}
      />
    </SvgWrapper>
  );
};
