import clsx from 'clsx';
import { useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DateRangePreset,
  DateRangePresetName,
  formatDateRange,
  getDateRangePresets,
  getPresetFromUiDateTimeRange,
} from 'src/utils/dateTimeUtils';
import { DateTimeRange } from 'src/WebApiController';

import * as styles from './DateRangePresets.css';
import {
  Suggestion,
  SuggestionContainer,
  SuggestionsContainer,
  SuggestionsHeading,
} from './DateRangePresets.styled';

export type DateRange = {
  from: Date;
  to: Date;
};

export type DateRangePresetsProp = {
  presetNames: DateRangePresetName[];
  selectedDateRange?: DateTimeRange | null;
  onClick: (dateRange: DateRange) => void;
  disabled?: boolean;
  /**
   * If true, the site's timezone will be applied to the date being displayed.
   * the underlying date value (value, data, onDateChange) will not be changed.
   */
  useSiteTimeZone?: boolean;
  hideSuggestionTitle?: boolean;
};

export function DateRangePresets({
  selectedDateRange,
  presetNames,
  onClick,
  disabled,
  useSiteTimeZone = true,
  hideSuggestionTitle,
}: DateRangePresetsProp) {
  const { toZonedTime, fromZonedTime } = useSiteTimezoneContext();

  const handleClick = (preset: DateRangePreset) => {
    const dateRange = {
      from: useSiteTimeZone
        ? fromZonedTime(preset.range.start)
        : preset.range.start,
      to: useSiteTimeZone ? fromZonedTime(preset.range.end) : preset.range.end,
    };
    onClick(dateRange);
  };

  const presets = useMemo(() => {
    let now = new Date();
    if (useSiteTimeZone) {
      now = toZonedTime(now);
    }
    const presets = getDateRangePresets(presetNames, now);

    return presets;
  }, [useSiteTimeZone, presetNames, toZonedTime]);

  const presetName = getPresetFromUiDateTimeRange(
    selectedDateRange,
    useSiteTimeZone ? toZonedTime : undefined
  )?.name;

  return (
    <SuggestionsContainer>
      {!hideSuggestionTitle && (
        <SuggestionsHeading>
          <Content id={ContentId.DateRangeSuggestionsTitle} />
        </SuggestionsHeading>
      )}
      {presets.map((preset, index) => (
        <SuggestionContainer key={index} onClick={() => handleClick(preset)}>
          <Suggestion
            className={clsx(styles.suggestion, {
              [styles.suggestionSelected]: presetName === preset.name,
            })}
            disabled={disabled}
          >
            <span className={styles.suggestionText}>{preset.label}</span>{' '}
            <span>({formatDateRange(preset.range)})</span>
          </Suggestion>
        </SuggestionContainer>
      ))}
    </SuggestionsContainer>
  );
}
