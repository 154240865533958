import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useUserCanSetPrice } from 'src/hooks/useUserHasListingPermissions';
import { Feature, Listing } from 'src/WebApiController';

export const useHasListingPricePermissions = (
  listing?: Listing | null,
  isBulkEdit?: boolean
) => {
  const canSetPrice = useUserCanSetPrice(listing, isBulkEdit);

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const canAutoPrice = hasAutoPricingFeature && canSetPrice;

  return {
    canSetPrice,
    canAutoPrice,
    hasAutoPricingFeature,
  };
};
