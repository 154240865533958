import { ReactNode, useCallback } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { Button, Stack } from 'src/core/ui';
import { UnsavedChanges } from 'src/dialogs/UnsavedChanges';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseOrderDetails } from 'src/WebApiController';

import { CloseModalButton } from '../CloseModalButton';
import { WizardFooter } from './PurchaseModal.styled';

export type PurchaseWizardFooterProps = {
  className?: string;
  purchase?: PurchaseOrderDetails | null;
  hasChanges?: boolean;
  onPreviousLabel?: ContentId;
  onPrevious?: () => void;
  onNextLabel?: ContentId;
  onNext?: () => void;
  additionalActions?: ReactNode[];
  isSubmitting?: boolean;
  disabled?: boolean;
};

export function PurchaseWizardFooter({
  className,
  hasChanges,
  onPreviousLabel,
  onPrevious,
  onNextLabel,
  onNext,
  additionalActions,
  isSubmitting,
  disabled,
}: PurchaseWizardFooterProps) {
  const { launchDialog, closeDialog, dialogProps } = useBasicDialog();

  const onPreviousWrapper = () => {
    if (hasChanges && onPrevious) {
      launchDialog();
    } else {
      onPrevious?.();
    }
  };

  const goToCancelView = useCallback(() => {
    closeDialog();
    onPrevious?.();
  }, [closeDialog, onPrevious]);

  if (!onPrevious && !onNext) {
    return null;
  }

  if (disabled) {
    return (
      <WizardFooter className={className}>
        <CloseModalButton disabled={isSubmitting}>
          <Content id={ContentId.Close} />
        </CloseModalButton>
      </WizardFooter>
    );
  }

  return (
    <WizardFooter className={className}>
      <Stack gap="m">
        {additionalActions}
        {onNext && onNextLabel && (
          <Button variant={'regular'} onClick={onNext} disabled={isSubmitting}>
            <Content id={onNextLabel} />
          </Button>
        )}
      </Stack>
      {onPrevious && onPreviousLabel && (
        <Button
          variant={'outline'}
          onClick={onPreviousWrapper}
          disabled={isSubmitting}
        >
          <Content id={onPreviousLabel} />
        </Button>
      )}

      <UnsavedChanges
        {...dialogProps}
        onOkay={goToCancelView}
        onCancel={closeDialog}
      />
    </WizardFooter>
  );
}
