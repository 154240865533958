import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';

export const LaunchRemovePredelivery = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const hasPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_Predelivery,
    Permission.Inventory_PredeliveryOnIsPricerOf,
    Permission.Inventory_PredeliveryOnPurchasedBy
  );

  if (!hasPermission) {
    return null;
  }

  return (
    <>
      <PosDropdownItem
        key="removePredeliveredArtifacts"
        onClick={onClick}
        disabled={disabled}
      >
        <Content id={ContentId.RemovePredelivery} />
      </PosDropdownItem>
    </>
  );
};
