import { Feature, ReportGroupBy } from 'src/WebApiController';

import { ColumnPersonalizationFlags } from '../columnUtils.types';
import {
  ListingReportTableColumnId,
  ListingTableFlattenedColumnId,
} from './inventoryColumnUtils.types';
import { ListingTableColumnId } from './inventoryColumnUtils.types';

/**
 * List of main columns to identify a listing
 * Used for grouping listing
 */
export const LISTING_MAIN_COLUMNS: ListingTableColumnId[] = [
  ListingTableColumnId.Checkbox,
  ListingTableColumnId.Seating,
  ListingTableColumnId.SeatingRow,
  ListingTableColumnId.SeatingSeats,
  ListingTableColumnId.DeliveryType,
  ListingTableColumnId.ShownQuantity,
  ListingTableColumnId.UnsoldQuantity,
  ListingTableColumnId.TicketCount,
  ListingTableColumnId.UnitCost,
];

export const LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES: Record<
  ListingTableColumnId | ListingTableFlattenedColumnId,
  Partial<ColumnPersonalizationFlags>
> = {
  [ListingTableColumnId.Checkbox]: { isConfigurable: false },

  [ListingTableFlattenedColumnId.Event]: {
    isHiddenByDefault: false,
    // Not actually numeric, but hack for numeric pills
    isNumeric: true,
  },
  [ListingTableFlattenedColumnId.Venue]: { isHiddenByDefault: true },
  [ListingTableFlattenedColumnId.Location]: { isHiddenByDefault: true },
  [ListingTableFlattenedColumnId.EventDate]: {
    isHiddenByDefault: true,
  },
  [ListingTableColumnId.Seating]: {
    isRequired: true,
    // Not actually numeric, but hack for numeric pills
    isNumeric: true,
  },
  [ListingTableColumnId.DeliveryType]: {
    // Not actually numeric, but hack for numeric pills
    isNumeric: true,
    isEnumWithIcon: true,
  },
  [ListingTableColumnId.ShownQuantity]: {
    isNumeric: true,
    isInteger: true,
  },
  [ListingTableColumnId.UnsoldQuantity]: {
    isNumeric: true,
    isInteger: true,
  },
  [ListingTableColumnId.TicketCount]: { isNumeric: true, isInteger: true },
  // InhandDate has its own custom cell. Not supported for custom date time format options
  [ListingTableColumnId.InHandDate]: {},
  [ListingTableColumnId.UnitCost]: { isNumeric: true, isCurrency: true },
  // Numeric but may affect how custom input cells render
  // We could later have abbreviated values show, and precise when clicked
  [ListingTableColumnId.ListPrice]: { isRequired: false },
  // Numeric but may affect how custom input cells render
  // We could later have abbreviated values show, and precise when clicked
  [ListingTableColumnId.AllInPrice]: { isRequired: false },
  [ListingTableColumnId.Actions]: { isConfigurable: false, isRequired: true },
  [ListingTableColumnId.ListingId]: { isHiddenByDefault: true },
  [ListingTableColumnId.AutoPricing]: {
    isHiddenByDefault: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.ProceedsFloor]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.ProceedsCeiling]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.WebsiteFloor]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.WebsiteCeiling]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.UndercutMode]: {
    // Not actually numeric, but hack for numeric pills
    isNumeric: true,
    isEnumWithIcon: true,
    isHiddenByDefault: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.UndercutAbsoluteAmount]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.UndercutRelativeAmount]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isPercent: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.UndercutNewPrice]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.CompListingMode]: {
    // Not actually numeric, but hack for numeric pills
    isNumeric: true,
    isEnumWithIcon: true,
    isHiddenByDefault: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.CompListingFloor]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.CompListingCeiling]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.CompListingMinimumQuantity]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing, Feature.PricingSettingsInTable],
  },
  [ListingTableColumnId.CompListingExcludeFanInventory]: {
    isHiddenByDefault: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [
      Feature.AutoPricing,
      Feature.ExcludeFanInventory,
      Feature.PricingSettingsInTable,
    ],
  },
  [ListingTableColumnId.OutlierMode]: {
    isHiddenByDefault: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing, Feature.PricingSettingsInTable],
  },
  [ListingTableColumnId.OutlierFiltering]: {
    isHiddenByDefault: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing, Feature.PricingSettingsInTable],
  },
  [ListingTableColumnId.OutlierLimit]: {
    isHiddenByDefault: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing, Feature.PricingSettingsInTable],
  },
  [ListingTableColumnId.OutlierSpacing]: {
    isHiddenByDefault: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing, Feature.PricingSettingsInTable],
  },
  [ListingTableColumnId.CircuitBreakerMinCompListingCount]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing, Feature.PricingSettingsInTable],
  },
  [ListingTableColumnId.CircuitBreakerVelocity]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing, Feature.PricingSettingsInTable],
  },
  [ListingTableColumnId.CircuitBreakerVelocityHours]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isEditabilityConfigurable: true,
    requiredFeatures: [Feature.AutoPricing, Feature.PricingSettingsInTable],
  },
  [ListingTableColumnId.SeatingRow]: { isHiddenByDefault: true },
  [ListingTableColumnId.SeatingSeats]: {
    isHiddenByDefault: true,
    // Not actually numeric, but hack for numeric pills
    isNumeric: true,
  },
  [ListingTableColumnId.AutoPricingResultType]: {
    isHiddenByDefault: true,
    requiredFeatures: [Feature.AutoPricing],
  },
  [ListingTableColumnId.LastPriceUpdatedDate]: {
    isHiddenByDefault: true,
    isDateTime: true,
  },
  [ListingTableColumnId.WastedCost]: {
    isNumeric: true,
    isCurrency: true,
    isHiddenByDefault: true,
  },
  [ListingTableColumnId.WastedTickets]: {
    isNumeric: true,
    isInteger: true,
    isHiddenByDefault: true,
  },
  [ListingTableColumnId.PurchaseId]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isInteger: true,
  },
  [ListingTableColumnId.VendorAccount]: {
    isHiddenByDefault: true,
  },
  [ListingTableColumnId.AutoFulfill]: {
    isHiddenByDefault: true,
    requiredFeatures: [Feature.AutoFulfillSetting],
  },
  [ListingTableColumnId.MarketplaceVisibility]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isPercent: true,
  },
  [ListingTableColumnId.PurchaseCreatedBy]: {
    isHiddenByDefault: true,
  },
  [ListingTableColumnId.PurchasedBy]: {
    isHiddenByDefault: true,
  },
  [ListingTableColumnId.PurchaseDate]: {
    isHiddenByDefault: true,
    isDateTime: true,
  },
  [ListingTableColumnId.RelativeRankPremium]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isPercent: true,
    requiredFeatures: [Feature.UndercutByRankListingGroup],
  },
  [ListingTableColumnId.AbsoluteRankPremium]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
    requiredFeatures: [Feature.UndercutByRankListingGroup],
  },
  [ListingTableColumnId.TicketClassColor]: {
    isHiddenByDefault: false,
  },
  [ListingTableColumnId.UnsoldProceeds]: {
    isNumeric: true,
    isCurrency: true,
    isHiddenByDefault: true,
  },
  [ListingTableColumnId.UnsoldCost]: {
    isNumeric: true,
    isCurrency: true,
    isHiddenByDefault: true,
  },
  [ListingTableColumnId.SoldQty]: {
    isNumeric: true,
    isInteger: true,
    isHiddenByDefault: true,
  },
  [ListingTableColumnId.SoldCost]: {
    isNumeric: true,
    isCurrency: true,
    isHiddenByDefault: true,
  },
  [ListingTableColumnId.TotalCost]: {
    isNumeric: true,
    isCurrency: true,
    isHiddenByDefault: true,
  },
} as const;

export const LISTING_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES: Record<
  ListingReportTableColumnId,
  Partial<ColumnPersonalizationFlags>
> = {
  // These can be primary columns for grouping. The flag 'isRequired' will be determined at run time
  [ListingReportTableColumnId.ListingId]: { isHiddenByDefault: true },
  [ListingReportTableColumnId.Performer]: { isHiddenByDefault: true },
  [ListingReportTableColumnId.Venue]: { isHiddenByDefault: true },
  [ListingReportTableColumnId.EventGenre]: { isHiddenByDefault: true },
  [ListingReportTableColumnId.EventCity]: { isHiddenByDefault: true },
  [ListingReportTableColumnId.Event]: { isHiddenByDefault: true },
  [ListingReportTableColumnId.EventCountry]: { isHiddenByDefault: true },
  [ListingReportTableColumnId.SeatingSection]: { isHiddenByDefault: true },
  [ListingReportTableColumnId.SeatingRow]: { isHiddenByDefault: true },

  [ListingReportTableColumnId.UnsoldQuantity]: {
    ...LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      ListingTableColumnId.UnsoldQuantity
    ],
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.TicketCount]: {
    ...LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      ListingTableColumnId.TicketCount
    ],
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.SoldQuantity]: {
    isNumeric: true,
    isInteger: true,
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.UnitCost]: {
    ...LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      ListingTableColumnId.UnitCost
    ],
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.SoldCost]: {
    isNumeric: true,
    isCurrency: true,
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.UnsoldCost]: {
    isNumeric: true,
    isCurrency: true,
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.SoldProceeds]: {
    isNumeric: true,
    isCurrency: true,
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.ListPrice]: {
    isNumeric: true,
    isCurrency: true,
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.AllInPrice]: {
    isNumeric: true,
    isCurrency: true,
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.EventId]: {
    // This can be a number but shoudln't be treated as a number (precision etc.)
    isNumeric: false,
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.Category]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.EventDate]: {
    isDateTime: true,
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.EventStateProvince]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.SaleId]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.HoldId]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.SeatingSeats]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.DeliveryType]: {
    ...LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      ListingTableColumnId.DeliveryType
    ],
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.InHandDate]: {
    ...LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      ListingTableColumnId.InHandDate
    ],
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.PurchaseId]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.PurchasedBy]: {
    ...LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      ListingTableColumnId.PurchasedBy
    ],
    requiredFeatures: [],
  },
  [ListingReportTableColumnId.PurchaseDate]: {
    isHiddenByDefault: true,
    isDateTime: true,
  },
  [ListingReportTableColumnId.PurchaseCreatedBy]: {
    ...LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      ListingTableColumnId.PurchaseCreatedBy
    ],
  },
  [ListingReportTableColumnId.Vendor]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.SalePaymentState]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.PurchasePaymentStatus]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.Charges]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
  },
  [ListingReportTableColumnId.Credits]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
  },
  [ListingReportTableColumnId.Fulfiller]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.PaymentMethodType]: {
    // Not actually numeric, but hack for numeric pills
    isNumeric: true,
    isEnumWithIcon: true,
    isHiddenByDefault: true,
    isRequired: false,
  },
  [ListingReportTableColumnId.Zone]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.PricedBy]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.InternalNotes]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.InternalNotesPurchase]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.VendorOrderId]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.VendorAccountEmail]: {
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.CancellationDatePurchase]: {
    isDateTime: true,
    isHiddenByDefault: true,
  },
  [ListingReportTableColumnId.PandL]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
  },
  [ListingReportTableColumnId.Margin]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isPercent: true,
  },
} as const;

/**
 * These columns must show up when a group by is applied
 */
export const GROUP_BY_TO_PRIMARY_COLUMN_ID: Record<
  string,
  ListingReportTableColumnId
> = {
  [ReportGroupBy.Performer]: ListingReportTableColumnId.Performer,
  [ReportGroupBy.Venue]: ListingReportTableColumnId.Venue,
  [ReportGroupBy.Event]: ListingReportTableColumnId.Event,
  [ReportGroupBy.EventGenre]: ListingReportTableColumnId.EventGenre,
  [ReportGroupBy.EventCountry]: ListingReportTableColumnId.EventCountry,
  [ReportGroupBy.EventCity]: ListingReportTableColumnId.EventCity,
  [ReportGroupBy.SeatingSection]: ListingReportTableColumnId.SeatingSection,
  [ReportGroupBy.SeatingRow]: ListingReportTableColumnId.SeatingRow,
  [ReportGroupBy.ListingId]: ListingReportTableColumnId.ListingId,
  [ReportGroupBy.PurchaseId]: ListingReportTableColumnId.PurchaseId,
  [ReportGroupBy.PricedBy]: ListingReportTableColumnId.PricedBy,
  [ReportGroupBy.Vendor]: ListingReportTableColumnId.Vendor,
  [ReportGroupBy.PurchasedBy]: ListingReportTableColumnId.PurchasedBy,
  [ReportGroupBy.FulfilledBy]: ListingReportTableColumnId.Fulfiller,
};

export const NON_METRIC_LISTING_REPORT_TABLE_COLUMN_IDS: ListingReportTableColumnId[] =
  [
    ListingReportTableColumnId.Event,
    ListingReportTableColumnId.EventGenre,
    ListingReportTableColumnId.EventCountry,
    ListingReportTableColumnId.EventCity,
    ListingReportTableColumnId.SeatingSection,
    ListingReportTableColumnId.SeatingRow,
    ListingReportTableColumnId.Performer,
    ListingReportTableColumnId.Venue,
    ListingReportTableColumnId.EventId,
    ListingReportTableColumnId.Category,
    ListingReportTableColumnId.EventDate,
    ListingReportTableColumnId.EventStateProvince,
    ListingReportTableColumnId.ListingId,
    ListingReportTableColumnId.SaleId,
    ListingReportTableColumnId.HoldId,
    ListingReportTableColumnId.SeatingSeats,
    ListingReportTableColumnId.DeliveryType,
    ListingReportTableColumnId.InHandDate,
    ListingReportTableColumnId.PurchaseId,
    ListingReportTableColumnId.PurchasedBy,
    ListingReportTableColumnId.PurchaseDate,
    ListingReportTableColumnId.PurchaseCreatedBy,
    ListingReportTableColumnId.PricedBy,
    ListingReportTableColumnId.Vendor,
    ListingReportTableColumnId.SalePaymentState,
    ListingReportTableColumnId.Charges,
    ListingReportTableColumnId.Credits,
    ListingReportTableColumnId.Fulfiller,
    ListingReportTableColumnId.PaymentMethodType,
    ListingReportTableColumnId.PurchasePaymentStatus,
    ListingReportTableColumnId.Zone,
    ListingReportTableColumnId.InternalNotes,
    ListingReportTableColumnId.InternalNotesPurchase,
    ListingReportTableColumnId.VendorOrderId,
    ListingReportTableColumnId.VendorAccountEmail,
    ListingReportTableColumnId.CancellationDatePurchase,
  ];

/**
 * These columns from NON_METRIC_LISTING_REPORT_TABLE_COLUMN_IDS
 * can only show up when a group by is applied (but GROUP_BY_TO_PRIMARY_COLUMN_ID takes precedence)
 */
export const GROUP_BY_TO_VISIBLE_COLUMN_IDS: Record<
  string,
  ListingReportTableColumnId[]
> = {
  [ReportGroupBy.Event]: [
    ListingReportTableColumnId.Performer,
    ListingReportTableColumnId.Venue,
    ListingReportTableColumnId.EventGenre,
    ListingReportTableColumnId.EventCity,
    ListingReportTableColumnId.EventId,
    ListingReportTableColumnId.Category,
    ListingReportTableColumnId.EventDate,
    ListingReportTableColumnId.EventStateProvince,
  ],
  [ReportGroupBy.Performer]: [ListingReportTableColumnId.Category],
  [ReportGroupBy.SeatingRow]: [
    // Don't make this available until we get the data right - see notes on https://thestubhub.atlassian.net/browse/POS-3430
    // ListingReportTableColumnId.Zone
  ],
  [ReportGroupBy.ListingId]: [
    ListingReportTableColumnId.Event,
    ListingReportTableColumnId.EventCountry,
    ListingReportTableColumnId.EventCity,
    ListingReportTableColumnId.SeatingSection,
    ListingReportTableColumnId.SeatingRow,
    ListingReportTableColumnId.Performer,
    ListingReportTableColumnId.Venue,
    ListingReportTableColumnId.EventId,
    ListingReportTableColumnId.Category,
    ListingReportTableColumnId.EventDate,
    ListingReportTableColumnId.EventStateProvince,
    ListingReportTableColumnId.SaleId,
    ListingReportTableColumnId.HoldId,
    ListingReportTableColumnId.SeatingSeats,
    ListingReportTableColumnId.SeatingSeats,
    ListingReportTableColumnId.DeliveryType,
    ListingReportTableColumnId.PurchaseId,
    ListingReportTableColumnId.PurchasedBy,
    ListingReportTableColumnId.PurchaseDate,
    ListingReportTableColumnId.Vendor,
    ListingReportTableColumnId.SalePaymentState,
    ListingReportTableColumnId.Charges,
    ListingReportTableColumnId.Credits,
    ListingReportTableColumnId.EventStateProvince,
    ListingReportTableColumnId.Fulfiller,
    ListingReportTableColumnId.InHandDate,
    ListingReportTableColumnId.InternalNotes,
    ListingReportTableColumnId.VendorOrderId,
    ListingReportTableColumnId.VendorAccountEmail,
    ListingReportTableColumnId.CancellationDatePurchase,
    ListingReportTableColumnId.PurchaseCreatedBy,
    // Don't make this available until we get the data right - see notes on https://thestubhub.atlassian.net/browse/POS-3430
    // ListingReportTableColumnId.Zone,
  ],
  [ReportGroupBy.PurchaseId]: [
    ListingReportTableColumnId.DeliveryType,
    ListingReportTableColumnId.PurchasedBy,
    ListingReportTableColumnId.PurchaseDate,
    ListingReportTableColumnId.Vendor,
    ListingReportTableColumnId.PurchasePaymentStatus,
    ListingReportTableColumnId.Charges,
    ListingReportTableColumnId.Credits,
    ListingReportTableColumnId.PaymentMethodType,
    ListingReportTableColumnId.InternalNotesPurchase,
    ListingReportTableColumnId.VendorOrderId,
    ListingReportTableColumnId.VendorAccountEmail,
    ListingReportTableColumnId.CancellationDatePurchase,
    ListingReportTableColumnId.PurchaseCreatedBy,
  ],
};

export const LISTING_TABLE_CUSTOM_COLUMN_PERSONALIZATION_CONFIG: Partial<ColumnPersonalizationFlags> =
  {
    isHiddenByDefault: true,
    isNumeric: true,
  };
