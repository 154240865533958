import { ColumnDef } from '@tanstack/react-table';
import { EventDateDisplay } from 'src/components/Events/EventInfo';
import { TagValueDisplay } from 'src/components/TagsFormBody/TagValueCell';
import { useAppContext } from 'src/contexts/AppContext';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_SM,
  COLUMN_DEFAULT_SIZE_XL,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { vars } from 'src/core/themes';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { FormatOption, FormatOptionEntries } from 'src/modals/EditTableColumns';
import { CustomColumnName } from 'src/modals/InventoryTableColumns/CustomColumnName';
import { CustomNumericCell } from 'src/tables/ListingTable/configs/CustomNumericCell';
import { TableShimmeringDiv } from 'src/tables/Table';
import { DateCell } from 'src/tables/Table/TableCells';
import { getColumnPersonalization } from 'src/utils/columns/columnPersonalizationUtils';
import { EventsTableColumnId } from 'src/utils/columns/events/eventsColumnUtils.types';
import { CustomEventsColumn } from 'src/utils/columns/events/eventsCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { EVENTS_TABLE_COLUMN_ID_TO_CONTENT_ID } from 'src/utils/constants/contentIdMaps';
import {
  compareEventsByDate,
  compareEventsByLocation,
  compareEventsByName,
  compareEventsByVenue,
  getFormattedEventName,
} from 'src/utils/eventWithDataUtils';
import {
  getGroupingLabelForString,
  getGroupLabelForDates,
  getGroupLabelForNumber,
  getGroupLabelForPercentNumber,
} from 'src/utils/groupingUtils';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { sortGeneric, sortMoney } from 'src/utils/tableUtils';
import {
  SECTION_TYPE_TO_PRECISION_SETTING,
  SectionType,
} from 'src/utils/types/sectionType';
import {
  DateTimeRange,
  Event,
  EventWithData,
  Feature,
  ListingDetailedMetrics,
  Performer,
  TagsValueType,
  UiMoney,
  UserSetting,
  Venue,
} from 'src/WebApiController';

import { evaluateFormula } from '../../../utils/columns/events/eventsCustomColumnUtils';
import { EventDisplayCell } from './EventDisplayCell';
import { EventActionsCell } from './EventsActionCell';
import * as styles from './EventsTableColumnsConfig.css';
import { LocationDisplayCell } from './LocationDisplayCell';
import { VenueDisplayCell } from './VenueDisplayCell';

const SHIMMERING_DIV_HEIGHT_SINGLE_LISTING = vars.spacing['4xl'];

const QUANTITY_COLUMN_MIN_SIZE = 50;
const QUANTITY_COLUMN_MAX_SIZE = 80;
const COST_COLUMN_MIN_SIZE = 50;
const COST_COLUMN_MAX_SIZE = 80;
const DATE_COLUMN_MIN_SIZE = 100;
const DATE_COLUMN_MAX_SIZE = 200;

const SECTION_TYPE = SectionType.Events;

export type EventWithDataAndMetrics = EventWithData & {
  isSinglePerformerView: boolean;
  performer?: Performer;
  venue?: Venue;
  metrics?: ListingDetailedMetrics;
};

export type EventsFormulaFields = {
  actualStr?: number | null;
  expectedStr?: number | null;
  liftPercentage?: number | null;
  listedAtp?: number | null;
  revenue?: number | null;
  totalAtp?: number | null;
  totalCost?: number | null;
  totalGrossProfit?: number | null;
  totalListPrice?: number | null;
  totalListedQuantity?: number | null;
  totalListedValue?: number | null;
  totalNetProceeds?: number | null;
  totalSoldCost?: number | null;
  totalSoldQuantity?: number | null;
  totalTicketQuantity?: number | null;
  totalUnsoldListPrice?: number | null;
  totalUnsoldQuantity?: number | null;
};

export const tagColumnDef = (
  tagName: string
): ColumnDef<EventWithDataAndMetrics | null> => ({
  id: tagName,
  header: () => (
    <TableCellDiv align="right">
      <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
        {tagName}
      </span>
    </TableCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_LG,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    const tag = data?.metrics?.tags?.find((t) => t.key === tagName);
    if (
      tag?.valueType === TagsValueType.Int ||
      tag?.valueType === TagsValueType.Decimal
    ) {
      return tag ? parseFloat(tag.value) : null;
    }
    return tag?.value;
  },
  cell: function Cell({ getValue, row: { original }, table }) {
    const { value: storedColumnNumberPrecisions = {} } =
      useServerUserSetting<FormatOptionEntries>({
        id: SECTION_TYPE_TO_PRECISION_SETTING[
          table.options.meta?.sectionType ?? SECTION_TYPE
        ],
      });
    const tag = original?.metrics?.tags?.find((t) => t.key === tagName);

    if (
      tag?.valueType === TagsValueType.Int ||
      tag?.valueType === TagsValueType.Decimal
    ) {
      const userDefinedPrecision = storedColumnNumberPrecisions[tagName];

      return (
        <CustomNumericCell
          columnId={tagName}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={Boolean(!original || !original.metrics)}
          shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_SINGLE_LISTING}
          sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
          matchingTag={tag}
          currencyCode={original?.metrics?.currency}
        />
      );
    }

    if (!original || !original.metrics) {
      return (
        <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_LISTING} />
      );
    }

    if (!tag) {
      return null;
    }

    return (
      <TableCellDiv align="right">
        <TagValueDisplay tag={tag} displayMode />
      </TableCellDiv>
    );
  },
});

export const customColumnDef = (
  columnId: string,
  formula?: string | null
): ColumnDef<EventWithDataAndMetrics | null> => ({
  id: columnId,
  header: () => (
    <TableHeaderCellDiv align="right">
      <CustomColumnName id={columnId} sectionType={SECTION_TYPE} />
    </TableHeaderCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_BASE,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    return formula && data
      ? evaluateFormula(formula, data.metrics) ?? null
      : null;
  },
  cell: function Cell({ getValue, row: { original } }) {
    const { value: storedInventoryColumnNumberPrecisions = {} } =
      useServerUserSetting<FormatOptionEntries>({
        id: UserSetting.SaleColumnNumberPrecision,
      });
    const { value: customColumns = [] } = useServerUserSetting<
      CustomEventsColumn[]
    >({
      id: UserSetting.EventsCustomColumns,
    });

    const userDefinedPrecision =
      storedInventoryColumnNumberPrecisions[columnId];

    const { loginContext } = useAppContext();
    const { getUiCurrency } = useLocalizationContext();
    const uiCurrency = getUiCurrency(
      loginContext?.user?.activeAccount.currencyCode
    );

    const { isCurrency, isPercent } = getColumnPersonalization({
      id: columnId,
      sectionType: SECTION_TYPE,
      matchingCustomColumn: customColumns.find((c) => c.id === columnId),
    });

    if (!original || !original.metrics) {
      return (
        <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_LISTING} />
      );
    }

    const value = getValue<number | null>();

    return (
      <TableCellDiv align="right">
        <div>
          {value == null ? (
            ''
          ) : isNaN(value) || !isFinite(value) ? (
            <Content id={ContentId.NA} />
          ) : (
            <>
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: getValue<number>(),
                currencyCode: isCurrency ? uiCurrency.code : undefined,
                currencyDecimalDigits: isCurrency ? uiCurrency.dec : undefined,
                isPercent: isPercent && !isCurrency,
              })}
            </>
          )}
        </div>
      </TableCellDiv>
    );
  },
});

export const EVENTS_TABLE_COLUMNS_CONFIG: Record<
  EventsTableColumnId,
  ColumnDef<EventWithDataAndMetrics | null>
> = {
  [EventsTableColumnId.Checkbox]: {
    id: EventsTableColumnId.Checkbox,
    header: '',
    size: COLUMN_DEFAULT_SIZE_SM,
    minSize: COLUMN_DEFAULT_SIZE_SM,
    maxSize: COLUMN_DEFAULT_SIZE_SM,
    enableSorting: false,
    enableResizing: false,
    cell: function Cell({ row: { original, getIsSelected } }) {
      const { setShiftKeyGroupSelectionState } = useMultiSelectionContext();
      const isSelected = getIsSelected();

      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }

      return (
        <TableCellDiv
          align="left"
          showTooltip={false}
          style={{ paddingLeft: vars.spacing.sm }}
        >
          <Checkbox
            checked={isSelected}
            onChange={() => {
              setShiftKeyGroupSelectionState(original.event.viagVirtualId);
            }}
          />
        </TableCellDiv>
      );
    },
  },
  [EventsTableColumnId.Event]: {
    id: EventsTableColumnId.Event,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content id={ContentId.EventName} />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<EventWithDataAndMetrics>();
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }

      return <EventDisplayCell data={value} forceDisplayEventName />;
    },
    sortingFn: compareEventsByName,
    getGroupingValue: (row) => {
      const eventName = getFormattedEventName({
        event: row?.event,
        performer: row?.performer,
        venue: row?.venue,
        useShortName: true,
      }).join(' ');
      return getGroupingLabelForString(eventName);
    },
  },

  [EventsTableColumnId.Venue]: {
    id: EventsTableColumnId.Venue,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content id={ContentId.Venue} />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<EventWithDataAndMetrics>();
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return <VenueDisplayCell data={value} />;
    },
    sortingFn: compareEventsByVenue,
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupingLabelForString(item?.venue?.name ?? ''),
  },
  [EventsTableColumnId.Location]: {
    id: EventsTableColumnId.Location,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content id={ContentId.Location} />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<EventWithDataAndMetrics>();
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <div>
          <LocationDisplayCell data={value} />
        </div>
      );
    },
    sortingFn: compareEventsByLocation,
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupingLabelForString(item?.venue?.descr ?? ''),
  },
  [EventsTableColumnId.Date]: {
    id: EventsTableColumnId.Date,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content id={ContentId.EventDate} />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.event?.dates,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<DateTimeRange | undefined>();
      const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time_Day } =
        useColumnUserSetting(columnId, SectionType.Events);

      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }

      const date = applyNumberFormatting({
        precision: userDefinedPrecision,
        inputNumber: value?.start,
        isDateTime: true,
      }) as string;

      return (
        <TableCellDiv align="left">
          {userDefinedPrecision === FormatOption.DateTime_MonthDD_Time ? (
            <EventDateDisplay
              eventDateString={value?.start ?? undefined}
              eventEndDateString={value?.end ?? undefined}
              showEventDateTbd={true}
              eventSubDateFormat={' - h:mm aaa, eee'}
            />
          ) : (
            <div className={styles.relativeTimeCell}>{date}</div>
          )}
        </TableCellDiv>
      );
    },
    sortingFn: compareEventsByDate,
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForDates(item?.event.dates.start),
  },
  [EventsTableColumnId.TotalQty]: {
    id: EventsTableColumnId.TotalQty,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.TotalQty} />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.tktQty,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.tktQty ?? 0),
  },
  [EventsTableColumnId.SoldQty]: {
    id: EventsTableColumnId.SoldQty,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.SoldQty} />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.soldQty,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.soldQty ?? 0),
  },
  [EventsTableColumnId.ActualSTR]: {
    id: EventsTableColumnId.ActualSTR,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.STR} />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.str ?? 0,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<number>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            inputNumber: value,
            precision: userDefinedPrecision,
            isPercent: true,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: EventWithDataAndMetrics | null) => {
      return getGroupLabelForPercentNumber(item?.metrics?.str ?? 0);
    },
  },
  [EventsTableColumnId.Revenue]: {
    id: EventsTableColumnId.Revenue,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.SoldProceeds} />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.rvn,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<UiMoney>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<EventWithDataAndMetrics>,
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.rvn?.amt ?? 0, '$'),
  },
  [EventsTableColumnId.UnsoldProceeds]: {
    id: EventsTableColumnId.UnsoldProceeds,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            EVENTS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              columnId as EventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.ttlUnsoldListPrc,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;

      const value = getValue<UiMoney>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<EventWithDataAndMetrics>,
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.ttlUnsoldListPrc?.amt ?? 0, '$'),
  },
  [EventsTableColumnId.SoldCost]: {
    id: EventsTableColumnId.SoldCost,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.SoldCost} />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => {
      return {
        currencyCode: data?.metrics?.currency ?? null,
        ...(data?.metrics?.soldCst ?? null),
      };
    },
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<UiMoney>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<EventWithDataAndMetrics>,
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.soldCst?.amt ?? 0, '$'),
  },
  [EventsTableColumnId.UnsoldCost]: {
    id: EventsTableColumnId.UnsoldCost,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            EVENTS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              columnId as EventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => {
      return {
        currency: data?.metrics?.currency ?? null,
        dec: data?.metrics?.soldCst?.dec ?? null,
        amt: data?.metrics
          ? data.metrics.ttlCst.amt - data.metrics.soldCst.amt
          : null,
      };
    },
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<UiMoney>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<EventWithDataAndMetrics>,
    getGroupingValue: (item: EventWithDataAndMetrics | null) => {
      const value = item?.metrics
        ? item.metrics.ttlCst.amt - item.metrics.soldCst.amt
        : 0;
      return getGroupLabelForNumber(value, '$');
    },
  },
  [EventsTableColumnId.TotalCost]: {
    id: EventsTableColumnId.TotalCost,
    header: ({ column: { id: columnId } }) => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            EVENTS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              columnId as EventsTableColumnId
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => {
      return {
        currencyCode: data?.metrics?.currency ?? null,
        ...(data?.metrics?.ttlCst ?? null),
      };
    },
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<UiMoney>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<EventWithDataAndMetrics>,
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.ttlCst?.amt ?? 0, '$'),
  },
  [EventsTableColumnId.Profit]: {
    id: EventsTableColumnId.Profit,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.SoldProfit} />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.pandL,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<UiMoney>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<EventWithDataAndMetrics>,
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.pandL?.amt ?? 0, '$'),
  },
  [EventsTableColumnId.LiftPercentage]: {
    id: EventsTableColumnId.LiftPercentage,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.LiftPercentage} />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.liftPerc,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            inputNumber: value,
            precision: userDefinedPrecision,
            isPercent: true,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForPercentNumber(item?.metrics?.liftPerc ?? 0),
  },
  [EventsTableColumnId.ATP]: {
    id: EventsTableColumnId.ATP,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.SoldATP} />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.atp,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<UiMoney>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<EventWithDataAndMetrics>,
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.atp?.amt ?? 0, '$'),
  },
  [EventsTableColumnId.ListedQty]: {
    id: EventsTableColumnId.ListedQty,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.ListedQty} />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.listQty ?? 0,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.listQty ?? 0, '$'),
  },
  [EventsTableColumnId.ListedAtp]: {
    id: EventsTableColumnId.ListedAtp,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.ListedATP} />
      </TableHeaderCellDiv>
    ),
    minSize: COST_COLUMN_MIN_SIZE,
    maxSize: COST_COLUMN_MAX_SIZE,
    accessorFn: (data) => {
      return {
        currencyCode: data?.metrics?.currency ?? null,
        ...(data?.metrics?.listedATP ?? null),
      };
    },
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<UiMoney>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value.amt,
            currencyCode: value.currency,
            currencyDecimalDigits: value.dec,
          })}
        </TableCellDiv>
      );
    },
    sortingFn: sortMoney<EventWithDataAndMetrics>,
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.listedATP?.amt ?? 0, '$'),
  },
  [EventsTableColumnId.UnsoldQty]: {
    id: EventsTableColumnId.UnsoldQty,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.UnsoldQty} />
      </TableHeaderCellDiv>
    ),
    minSize: QUANTITY_COLUMN_MIN_SIZE,
    maxSize: QUANTITY_COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.metrics?.unsoldQty,
    cell: function Cell({ getValue, row: { original }, column }) {
      const columnId = column.id;
      const value = getValue<number | undefined>();
      const { userDefinedPrecision } = useColumnUserSetting(
        columnId,
        SectionType.Events
      );
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          })}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForNumber(item?.metrics?.unsoldQty ?? 0),
  },
  [EventsTableColumnId.Action]: {
    id: EventsTableColumnId.Action,
    enableSorting: false,
    enableResizing: true,
    header: function Cell() {
      const hasTablePinActionColumnFeature = useUserHasFeature(
        Feature.TablePinActionColumn
      );

      if (hasTablePinActionColumnFeature) {
        return null;
      }

      return (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Actions} />
        </TableHeaderCellDiv>
      );
    },
    size: COLUMN_DEFAULT_SIZE_BASE,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    accessorFn: (data) => data?.event ?? null,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<Event>();
      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }
      return <EventActionsCell event={value} />;
    },
  },
  [EventsTableColumnId.LastPriceUpdatedDate]: {
    id: EventsTableColumnId.LastPriceUpdatedDate,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            EVENTS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              EventsTableColumnId.LastPriceUpdatedDate
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    minSize: DATE_COLUMN_MIN_SIZE,
    maxSize: DATE_COLUMN_MAX_SIZE,
    sortingFn: sortGeneric,
    accessorFn: (data) => data?.metrics?.priceUpdatedOn,
    cell: function Cell({
      row: { original },
      getValue,
      column: { id: columnId },
    }) {
      const value = getValue<string>();

      const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
        useColumnUserSetting(columnId, SECTION_TYPE);

      if (!original?.metrics) {
        return <TableShimmeringDiv height="30px" />;
      }

      const date = applyNumberFormatting({
        precision: userDefinedPrecision,
        inputNumber: value,
        isDateTime: true,
      }) as string;

      return (
        <TableCellDiv align="right">
          {userDefinedPrecision === FormatOption.DateTime_MonthDD_Time ? (
            <DateCell
              precision={userDefinedPrecision}
              date={value}
              enableUtcFallback
            />
          ) : (
            <div className={styles.relativeTimeCell}>{date}</div>
          )}
        </TableCellDiv>
      );
    },
    getGroupingValue: (item: EventWithDataAndMetrics | null) =>
      getGroupLabelForDates(item?.metrics?.priceUpdatedOn),
  },
};
