import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode } from 'react';

/**
 * Extends functionality of any component to inherit sort functionality
 */
export const DropWrapper = ({
  children,
  id,
}: {
  children: ReactNode;
  id: UniqueIdentifier;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  return (
    <div
      ref={setNodeRef}
      style={{
        cursor: 'move',
        transform: CSS.Translate.toString(transform),
        transition: transition,
        ...(isDragging
          ? {
              opacity: 0.5,
            }
          : {}),
      }}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
};
