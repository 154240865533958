import { MerchantDisplay } from 'src/components/common/MerchantDisplay';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { useContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { Sale } from 'src/WebApiController';

import * as styles from './SaleBasicInfo.css';
import { AttrLabel, AttrValue, SaleIdContainer } from './SaleBasicInfo.styled';

export const SaleBasicInfo = ({ sale }: { sale: Sale }) => {
  const saleIdLabel = useContent(ContentId.SaleId);
  const marketplaceLabel = useContent(ContentId.Merchant);

  return (
    /* TODO - search by sale id being mainly used with marketplace sale id (which can yield multiple results if we have multiple marketplaces that use the same ids)
              We are displaying Sale info without the Event's info, but there is several reason we don't need to show the Event info right now, mainly...
              1 - Event info currently is expensive to get (calling catalog API) - when we bring catalog API info in-house, we can add it performantly
              2 - We don't need Event info yet, because currently all our sales are from StubHub, and so NO user will see this dialog
              3 - This might actually be replaced with the Universal Search anyway (when we have both catalog in-house and the search index).
    */
    <div className={styles.saleInfoContainer}>
      <SaleIdContainer>
        <AttrLabel>{saleIdLabel}</AttrLabel>
        <AttrValue>{sale.idOnMkp || sale.id}</AttrValue>
        <AttrLabel>{marketplaceLabel}</AttrLabel>
        <AttrValue>
          <MerchantDisplay name={sale.mkp} />
        </AttrValue>
      </SaleIdContainer>
      <SeatingInfo {...sale.seating} />
    </div>
  );
};
