import { useToggle } from 'react-use';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { AddUserModal } from './EditUser/AddUserModal';
import * as styles from './UsersManagement.css';
import { UsersTable } from './UsersTable';

export const Users = () => {
  const [isAddUserModalOpen, toggleAddUserModalOpen] = useToggle(false);
  return (
    <Stack className={styles.root} direction="column" gap="l">
      <Stack justifyContent="spaceBetween" alignItems="center">
        <div className={styles.title}>
          <Content id={ContentId.Users} />
        </div>
        <Button
          variant="outline"
          style={{ alignSelf: 'flex-end', textTransform: 'capitalize' }}
          onClick={toggleAddUserModalOpen}
        >
          <PlusIcon fill={IconsFill.currentColor} size={vars.iconSize.s} />
          <Content id={ContentId.NewUser} />
        </Button>
      </Stack>
      <AddUserModal
        isOpen={isAddUserModalOpen}
        onClose={toggleAddUserModalOpen}
      />
      <UsersTable />
    </Stack>
  );
};
