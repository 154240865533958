import { darkLogo } from 'src/components/common/MarketplaceLogo/MarketplaceLogo.css';

export const GametimeLogo = ({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width ?? 160}
      height={height ?? 40}
      viewBox="0 0 140 22"
      fill="none"
      className={darkLogo}
    >
      <path
        fill="#00EF9F"
        d="M133.8,0.4L140,11l-6.2,10.6h-11.4c-0.5,0-0.7-0.5-0.5-0.9L133.8,0.4z"
      ></path>
      <path
        fill="#19CE85"
        d="M121.9,1.3l4.4,7.6l7.5-8.5h-11.4C121.9,0.4,121.6,0.9,121.9,1.3z"
      ></path>
      <path fill="#21AA6F" d="M133.8,0.4l-7.5,8.5l1.3,2.1L133.8,0.4z"></path>
      <path
        fill="#000000"
        d="M23.3,0.4h-2.9c-0.2,0-0.5,0.2-0.5,0.4L14.8,21c-0.1,0.3,0.2,0.6,0.5,0.6h2.8c0.3,0,0.5-0.2,0.5-0.4l0.7-3	c0-0.2,0.2-0.3,0.4-0.3h4.2c0.2,0,0.4,0.1,0.4,0.3l0.7,3c0.1,0.3,0.3,0.4,0.5,0.4h2.7c0.3,0,0.6-0.3,0.5-0.6l-5-20.2	C23.7,0.5,23.5,0.4,23.3,0.4z M20.1,14.1l1.7-8.2h0.1l1.6,8.2c0,0.2-0.1,0.4-0.3,0.4h-2.9C20.2,14.5,20.1,14.3,20.1,14.1z"
      ></path>
      <path
        fill="#000000"
        d="M31.6,21.1V0.9c0-0.3,0.2-0.5,0.5-0.5h2.5c0.2,0,0.4,0.1,0.5,0.3L39,9.2h0.1l3.9-8.5c0.1-0.2,0.3-0.3,0.5-0.3	H46c0.3,0,0.5,0.2,0.5,0.5v20.2c0,0.3-0.2,0.5-0.5,0.5h-2.8c-0.3,0-0.5-0.2-0.5-0.5V9.3h-0.1l-2.2,4.5c-0.1,0.2-0.3,0.3-0.5,0.3	h-1.6c-0.2,0-0.4-0.1-0.5-0.3l-2.4-4.5h-0.1v11.8c0,0.3-0.2,0.5-0.5,0.5h-2.6C31.8,21.6,31.6,21.4,31.6,21.1z"
      ></path>
      <path
        fill="#000000"
        d="M86.9,21.1V0.9c0-0.3,0.2-0.5,0.5-0.5H90c0.2,0,0.4,0.1,0.5,0.3l4,8.5h0.1l3.8-8.4c0.1-0.2,0.3-0.3,0.5-0.3h2.5	c0.3,0,0.5,0.2,0.5,0.5v20.2c0,0.3-0.2,0.5-0.5,0.5h-2.8c-0.3,0-0.5-0.2-0.5-0.5V9.3h-0.1l-2.2,4.5c-0.1,0.2-0.3,0.3-0.5,0.3h-1.6	c-0.2,0-0.4-0.1-0.5-0.3l-2.3-4.5h-0.1v11.8c0,0.3-0.2,0.5-0.5,0.5h-2.6C87.2,21.6,86.9,21.4,86.9,21.1z"
      ></path>
      <path
        fill="#000000"
        d="M83,21.6H80c-0.3,0-0.5-0.2-0.5-0.5V0.9c0-0.3,0.2-0.5,0.5-0.5H83c0.3,0,0.5,0.2,0.5,0.5v20.2	C83.5,21.4,83.2,21.6,83,21.6z"
      ></path>
      <path
        fill="#000000"
        d="M76.3,0.9c0-0.3-0.2-0.5-0.5-0.5H64.5c-0.3,0-0.5,0.2-0.5,0.5v2.5C64,3.7,64.2,4,64.5,4h3.3	c0.2,0,0.3,0.1,0.3,0.3v16.9c0,0.3,0.2,0.4,0.4,0.4h3.1c0.3,0,0.4-0.2,0.4-0.4V4.2c0-0.2,0.1-0.3,0.3-0.3h3.3c0.3,0,0.5-0.2,0.5-0.5	L76.3,0.9L76.3,0.9z"
      ></path>
      <path
        fill="#000000"
        d="M54,17.8v-4.9c0-0.2,0.1-0.3,0.3-0.3H60c0.3,0,0.4-0.2,0.4-0.4V9.6c0-0.3-0.2-0.4-0.4-0.4h-5.7	C54.1,9.1,54,9,54,8.8V4.2c0-0.2,0.1-0.3,0.3-0.3h6.5c0.3,0,0.4-0.2,0.4-0.4V0.8c0-0.3-0.2-0.4-0.4-0.4H50.4c-0.3,0-0.4,0.2-0.4,0.4	v20.3c0,0.3,0.2,0.4,0.4,0.4h10.7c0.3,0,0.4-0.2,0.4-0.4v-2.6c0-0.3-0.2-0.4-0.4-0.4h-6.9C54.1,18.1,54,17.9,54,17.8z"
      ></path>
      <path
        fill="#000000"
        d="M109.3,17.8v-4.9c0-0.2,0.1-0.3,0.3-0.3h5.6c0.3,0,0.5-0.2,0.5-0.5V9.7c0-0.3-0.2-0.5-0.5-0.5h-5.6	c-0.2,0-0.3-0.1-0.3-0.3V4.2c0-0.2,0.1-0.3,0.3-0.3h6.5c0.3,0,0.5-0.2,0.5-0.5V0.9c0-0.3-0.2-0.5-0.5-0.5h-10.2	c-0.3,0-0.5,0.2-0.5,0.5v20.2c0,0.3,0.2,0.5,0.5,0.5h10.5c0.3,0,0.5-0.2,0.5-0.5v-2.5c0-0.3-0.2-0.5-0.5-0.5h-6.8	C109.4,18.1,109.3,17.9,109.3,17.8z"
      ></path>
      <path
        fill="#000000"
        d="M6.8,10.8v2.1c0,0.3,0.2,0.5,0.5,0.5h1.6c0.2,0,0.3,0.1,0.3,0.3V16c0,1.3-1.1,2.4-2.4,2.4H6.5	c-1.3,0-2.4-1.1-2.4-2.4v-1.6v-0.9V5.9c0-1.3,1.1-2.3,2.4-2.3h0.3c1.4,0,2.3,1,2.3,2.2v0.2c0,0.3,0.2,0.5,0.5,0.5h2.8	c0.3,0,0.5-0.2,0.5-0.5V5.5c0-3-2.3-5.5-6.2-5.5H6.5C2.7,0,0,2.6,0,5.8v10.3C0,19.3,2.7,22,6.5,22h0.3c3.9,0,6.2-2.6,6.2-5.9v-5.3	c0-0.3-0.2-0.5-0.5-0.5H7.3C7,10.3,6.8,10.5,6.8,10.8z"
      ></path>
    </svg>
  );
};
