import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const SameZoneModeIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 40 40">
      <path
        d="M35.5 10C35.5 14.5 30 19.5 30 19.5C30 19.5 24.5 14.5 24.5 10C24.5 8.54131 25.0795 7.14236 26.1109 6.11091C27.1424 5.07946 28.5413 4.5 30 4.5C31.4587 4.5 32.8576 5.07946 33.8891 6.11091C34.9205 7.14236 35.5 8.54131 35.5 10Z"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30 12.5C31.3807 12.5 32.5 11.3807 32.5 10C32.5 8.61929 31.3807 7.5 30 7.5C28.6193 7.5 27.5 8.61929 27.5 10C27.5 11.3807 28.6193 12.5 30 12.5Z"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 10H6V33.5H31V24"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeDasharray="5 5"
      />
    </SvgWrapper>
  );
};
