import { uniq } from 'lodash-es';
import { Controller, useForm, UseFormReturn } from 'react-hook-form';
import { CurrencyFilterSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  FieldError,
  FieldLabel,
  FieldWrapper,
  PurchasePaymentMethodSelectionInput,
} from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { Feature } from 'src/WebApiController';

import {
  FullWidthSelectionInputContainer,
  InputGrid,
  InputRow,
  InputRowAmount,
  InputRowSide,
} from './GeneratePurchasePaymentsForm.styled';

export type GeneratePurchasePaymentsFieldValues = {
  fromDate: Date;
  toDate: Date;
  paymentCount: number;
  currencyCode: string;
  paymentAmountType: 'occurrence' | 'total';
  paymentAmount: number;
  paymentMethodId: number;
};

export type GeneratePurchasePaymentsFormProps = {
  form: UseFormReturn<GeneratePurchasePaymentsFieldValues>;
};

export function GeneratePurchasePaymentsForm({
  form: {
    control,
    formState: { errors },
    register,
    watch,
    setValue,
  },
}: GeneratePurchasePaymentsFormProps) {
  const hasEnforcePurchasePaymentCurrencyFeature = useUserHasFeature(
    Feature.EnforcePurchasePaymentCurrency
  );

  const requiredMsg = useContent(ContentId.Required);
  const occurrenceText = useContent(ContentId.PerPayment);
  const totalText = useContent(ContentId.Total);

  const paymentAmount = watch('paymentAmount');
  const currencyCode = watch('currencyCode');
  const { getUiCurrency } = useLocalizationContext();

  return (
    <>
      <InputGrid>
        <FieldWrapper>
          <FieldLabel>
            <Content id={ContentId.From} />
          </FieldLabel>
          <Controller
            control={control}
            name="fromDate"
            rules={{ required: requiredMsg }}
            render={({ field: { ...field }, fieldState }) => (
              <DatePickerInput
                fieldError={fieldState.error}
                locale={getLocaleFromLanguageOrCurrent()}
                {...field}
                onDateChange={(d) => {
                  setValue(field.name, d);
                }}
              />
            )}
          />
          {errors.fromDate && (
            <FieldError>{errors.fromDate?.message}</FieldError>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>
            <Content id={ContentId.ToDate} />
          </FieldLabel>
          <Controller
            control={control}
            name="toDate"
            rules={{ required: requiredMsg }}
            render={({ field: { ...field }, fieldState }) => (
              <DatePickerInput
                fieldError={fieldState.error}
                locale={getLocaleFromLanguageOrCurrent()}
                {...field}
                onDateChange={(d) => {
                  setValue(field.name, d);
                }}
              />
            )}
          />
          {errors.toDate && <FieldError>{errors.toDate?.message}</FieldError>}
        </FieldWrapper>
        <InputRow>
          <InputRowSide>
            <FieldWrapper>
              <FieldLabel>
                <Content id={ContentId.Payments} />
              </FieldLabel>
              <PosFormField errors={errors.paymentCount?.message}>
                <PosTextField
                  rootProps={{
                    state: getTextFieldState(errors.paymentCount),
                  }}
                  type="number"
                  inputMode="numeric"
                  placeholder="0"
                  {...register('paymentCount', { required: requiredMsg })}
                />
              </PosFormField>
            </FieldWrapper>
          </InputRowSide>
          <InputRowSide>
            <FieldWrapper>
              <FieldLabel>
                <Content id={ContentId.AmountType} />
              </FieldLabel>
              <FullWidthSelectionInputContainer>
                <Controller
                  control={control}
                  name="paymentAmountType"
                  render={({ field: { ...field }, fieldState }) => (
                    <PosSelect
                      style={{ width: '100%' }}
                      valueOptionsContent={{
                        occurrence: occurrenceText,
                        total: totalText,
                      }}
                      hasErrors={Boolean(fieldState.error)}
                      {...field}
                    />
                  )}
                />
              </FullWidthSelectionInputContainer>
            </FieldWrapper>
          </InputRowSide>
        </InputRow>
        <InputRow>
          <FieldWrapper>
            <FieldLabel>
              <Content id={ContentId.Amount} />
            </FieldLabel>
            <InputRowAmount>
              {!hasEnforcePurchasePaymentCurrencyFeature && (
                <Controller
                  control={control}
                  name="currencyCode"
                  rules={{ required: requiredMsg }}
                  render={({ field: { ...field }, fieldState }) => (
                    <CurrencyFilterSelector
                      hasErrors={Boolean(fieldState.error)}
                      {...field}
                    />
                  )}
                />
              )}
              <PosCurrencyField
                rootProps={{
                  state: getTextFieldState(errors.paymentAmount),
                }}
                uiCurrency={getUiCurrency(currencyCode)}
                value={paymentAmount}
                {...register('paymentAmount', {
                  validate: {
                    required: (fieldVal) => {
                      return parseFloat(fieldVal?.toString() ?? '')
                        ? undefined
                        : requiredMsg;
                    },
                  },
                  valueAsNumber: true,
                })}
              />
            </InputRowAmount>
            {(errors.currencyCode || errors.paymentAmount) &&
              uniq([
                errors.currencyCode?.message,
                errors.paymentAmount?.message,
              ])
                .filter((msg) => Boolean(msg))
                .map((msg) => {
                  return <FieldError key={msg}>{msg}</FieldError>;
                })}
          </FieldWrapper>
        </InputRow>
        <FieldWrapper>
          <FieldLabel>
            <Content id={ContentId.PaymentMethodUsed} />
          </FieldLabel>
          <FullWidthSelectionInputContainer>
            <Controller
              control={control}
              name="paymentMethodId"
              rules={{ required: requiredMsg }}
              render={({ field: { value, ...field }, fieldState }) => (
                <PurchasePaymentMethodSelectionInput
                  className="full-width"
                  value={value !== undefined ? String(value) : undefined}
                  hasErrors={Boolean(fieldState.error)}
                  {...field}
                />
              )}
            />
          </FullWidthSelectionInputContainer>
          {errors.paymentMethodId && (
            <FieldError>{errors.paymentMethodId?.message}</FieldError>
          )}
        </FieldWrapper>
      </InputGrid>
    </>
  );
}

export function useGeneratePurchasePaymentsForm({
  defaultCurrencyCode,
}: {
  defaultCurrencyCode: string;
}) {
  return useForm<GeneratePurchasePaymentsFieldValues>({
    defaultValues: {
      currencyCode: defaultCurrencyCode,
      paymentAmountType: 'occurrence',
    },
  });
}
