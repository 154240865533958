import { isEqual } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { BlinkingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TicketGroupIntentionDialog } from 'src/dialogs/TicketGroupIntentionDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetListingAndSaleIds } from 'src/modals/common/Purchase/hooks/useGetListingAndSaleIds';
import { IconsFill, WarningSolidIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { TicketGroupInput, TicketGroupIntention } from 'src/WebApiController';

import { ChangesIndicator } from '../PurchaseTicketTable.styled';

export const TicketGroupStatusIndicator = ({
  ticketGroup,
}: {
  ticketGroup: TicketGroupInput;
}) => {
  const { setValue, getFieldState, watch, formState } =
    useFormContext<PurchaseOrderDetailsInput>();
  const ticketGroups = watch('ticketGroups');
  const intentions = watch('ticketGroupIntentions');

  const unsavedActionText = useContent(ContentId.UnsavedChanges);

  const originalTicketGroup = useMemo(
    () =>
      formState.defaultValues?.ticketGroups?.find(
        (t) => t?.ticketGroupId === ticketGroup.ticketGroupId
      ),
    [formState.defaultValues?.ticketGroups, ticketGroup.ticketGroupId]
  );

  const index = useMemo(
    () =>
      ticketGroups.findIndex(
        (tg) => tg.ticketGroupId === ticketGroup.ticketGroupId
      ),
    [ticketGroup.ticketGroupId, ticketGroups]
  );
  const error = getFieldState(`ticketGroups.${index}`, formState).error
    ?.message;

  const intention = useMemo(
    () => intentions[ticketGroup.ticketGroupId],
    [intentions, ticketGroup.ticketGroupId]
  );

  const ticketGroupIntentionDialog = useBasicDialog();
  const hasChanges = !isEqual(ticketGroup, originalTicketGroup);

  const onTicketGroupIntentionSave = useCallback(
    (intention: TicketGroupIntention) => {
      intentions[intention.ticketGroupId] = intention;
      setValue('ticketGroupIntentions', { ...intentions });
      ticketGroupIntentionDialog.closeDialog();
    },
    [intentions, setValue, ticketGroupIntentionDialog]
  );

  const { saleIds } = useGetListingAndSaleIds({
    ticketGroups: [{ tickets: ticketGroup.tickets?.value }],
  });

  return (
    <>
      {error && (
        <div title={error}>
          <WarningSolidIcon
            fill={IconsFill.textNegative}
            size={vars.iconSize.l}
          />
        </div>
      )}
      {hasChanges && (
        <BlinkingWrapper className="blink-on">
          <ChangesIndicator
            title={`${unsavedActionText}: ${intention?.reason} ${
              intention?.additionalInfo ? `(${intention?.additionalInfo})` : ''
            }`}
            onClick={() => ticketGroupIntentionDialog.launchDialog()}
          >
            *
          </ChangesIndicator>
        </BlinkingWrapper>
      )}
      <TicketGroupIntentionDialog
        {...ticketGroupIntentionDialog.dialogProps}
        salesCount={saleIds.size}
        intention={intention}
        onClosed={ticketGroupIntentionDialog.closeDialog}
        onSave={onTicketGroupIntentionSave}
        saveButtonContentId={ContentId.Save}
      />
    </>
  );
};
