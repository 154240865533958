import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function PurchaseIcon({
  fill,
  stroke,
  ...props
}: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper {...props} viewBox="0 0 12 12" fill="none">
      <g clipPath="url(#clip0_261_3843)">
        <path
          d="M5.08881 3.87354H8.73382"
          stroke={stroke ?? fill ?? 'currentColor'}
          strokeWidth="1.215"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.08881 5.69629H8.73382"
          stroke={stroke ?? fill ?? 'currentColor'}
          strokeWidth="1.215"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.12625 9.34138V8.73389H1.44373V9.34138C1.44373 10.0127 1.98744 10.5564 2.65873 10.5564H9.34125C8.66996 10.5564 8.12625 10.0127 8.12625 9.34138Z"
          stroke={stroke ?? fill ?? 'currentColor'}
          strokeWidth="1.215"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.26624 7.51883V1.44385H10.5563V9.34132C10.5563 10.0126 10.0125 10.5563 9.34126 10.5563"
          stroke={stroke ?? fill ?? 'currentColor'}
          strokeWidth="1.215"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_261_3843">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </SvgWrapper>
  );
}
