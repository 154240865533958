import md5 from 'md5';

import { decodeBase64 } from './format';

export const downloadFile = (
  fileName: string,
  content: string,
  contentType: string | undefined,
  isBase64 = false
) => {
  const blob = new Blob([isBase64 ? decodeBase64(content) : content], {
    type: contentType,
  });
  downloadFileFromBlob(fileName, blob);
};

export const downloadFileFromUrl = (fileName: string, url: string) => {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const downloadFileFromBlob = (fileName: string, data: Blob) => {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

export const getFileHash = async (file: File): Promise<string> => {
  return md5(new Uint8Array(await file.arrayBuffer()));
};
