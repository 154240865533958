import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { usePurchaseVendorAccountSelector } from 'src/components/Selectors/PurchaseVendorAccountSelector/usePurchaseVendorAccountSelector';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import { DividerLine } from 'src/core/ui';
import { FieldWrapper } from 'src/modals/common/Purchase';
import { ChevronDownIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseVendor, PurchaseVendorAccount } from 'src/WebApiController';

import * as styles from './SecondaryVendorAccountDialog.css';
import {
  DividerContainer,
  DividerIcon,
  FieldLabelWrapper,
  FromToLabel,
} from './SecondaryVendorAccountDialog.styled';

export type SecondaryVendorAccountDialogProps = ComponentProps<
  typeof RSModal
> & {
  vendorAccountFrom: PurchaseVendorAccount | null;
  vendorAccountTo: PurchaseVendorAccount | null;
  onSave: (
    alternativeVendor: PurchaseVendor | null | undefined,
    alternativeVendorAccount: PurchaseVendorAccount | null | undefined
  ) => void;
  onCancel: () => void;
};

export function SecondaryVendorAccountDialog({
  ...rest
}: SecondaryVendorAccountDialogProps) {
  return <SecondaryVendorAccountDialogBody {...rest} />;
}

const SecondaryVendorAccountDialogBody = ({
  vendorAccountFrom,
  vendorAccountTo,
  onSave,
  onCancel,
  ...rest
}: SecondaryVendorAccountDialogProps) => {
  const {
    query: queryVendor,
    availableVendors,
    posSelectProps: hookVendorPosSelectProps,
  } = usePurchaseVendorSelector({});
  const currentVendor = useMemo(() => {
    const vendorId = (vendorAccountTo ?? vendorAccountFrom)?.vendorId;
    return vendorId == null ? null : availableVendors[vendorId];
  }, [availableVendors, vendorAccountTo, vendorAccountFrom]);

  const [localVendorAccountTo, setLocalVendorAccountTo] = useState<
    PurchaseVendorAccount | null | undefined
  >(vendorAccountTo || vendorAccountFrom);
  const [localVendorTo, setLocalVendorTo] = useState<
    PurchaseVendor | null | undefined
  >(currentVendor);

  const { posSelectProps: hookVendorAccountFromFilterSelectorProps } =
    usePurchaseVendorAccountSelector({
      vendorId: vendorAccountFrom?.vendorId,
    });

  const {
    query: queryVendorAccount,
    sortedVendorAccounts,
    posSelectProps: hookVendorAccountToPosSelectProps,
  } = usePurchaseVendorAccountSelector({
    vendorId: localVendorTo?.id,
  });

  useEffect(() => {
    // If we don't have a selected account, auto-select the first one in the list
    if (localVendorTo) {
      return;
    }
    if (queryVendor.data) {
      const vendors = queryVendor.data;
      if (vendors?.length) {
        setLocalVendorTo(vendors[0]);
        return;
      }
    }
    setLocalVendorTo(null);
  }, [queryVendor, localVendorTo]);

  useEffect(() => {
    if (localVendorAccountTo) {
      return;
    }
    if (queryVendorAccount.data) {
      if (sortedVendorAccounts?.length) {
        setLocalVendorAccountTo(sortedVendorAccounts[0]);
        return;
      }
    }
    setLocalVendorAccountTo(null);
  }, [queryVendorAccount, localVendorAccountTo, sortedVendorAccounts]);

  return (
    <GenericDialog
      {...rest}
      size="m"
      header={<Content id={ContentId.DeliverTicket} />}
      footer={
        <>
          {vendorAccountTo && (
            <OkButton
              textContentId={ContentId.Remove}
              variant={'outline'}
              style={{ color: vars.color.textError }}
              onClick={() => {
                onSave(null, null);
              }}
            />
          )}
          {<CancelButton onClick={onCancel} />}
          <OkButton
            textContentId={ContentId.Save}
            disabled={
              !localVendorAccountTo ||
              localVendorAccountTo.id === vendorAccountFrom?.id
            }
            variant={'regular'}
            onClick={() => {
              onSave(localVendorTo, localVendorAccountTo);
            }}
          />
        </>
      }
      onCancel={onCancel}
    >
      <div className={styles.secondaryVendorAccountDialogContainer}>
        <FromToLabel>
          <Content id={ContentId.From} />
        </FromToLabel>
        <FieldWrapper>
          <FieldLabelWrapper>
            <Content id={ContentId.Vendor} />
          </FieldLabelWrapper>
          <PosSelect
            {...hookVendorPosSelectProps}
            value={vendorAccountFrom?.vendorId?.toString()}
            disabled
            onChange={() => {}}
            style={{ width: '100%' }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabelWrapper>
            <Content id={ContentId.Account} />
          </FieldLabelWrapper>
          <PosSelect
            {...hookVendorAccountFromFilterSelectorProps}
            value={vendorAccountFrom?.id}
            disabled
            onChange={() => {}}
            style={{ width: '100%' }}
          />
        </FieldWrapper>
        <DividerContainer>
          <DividerLine />
          <DividerIcon>
            <ChevronDownIcon />
          </DividerIcon>
          <DividerLine />
        </DividerContainer>
        <FromToLabel>
          <Content id={ContentId.To} />
        </FromToLabel>
        <FieldWrapper>
          <FieldLabelWrapper>
            <Content id={ContentId.Vendor} />
          </FieldLabelWrapper>
          <PosSelect
            {...hookVendorPosSelectProps}
            value={localVendorTo?.id.toString()}
            onChange={(val) => {
              if (val) {
                const value = parseInt(val);
                const vendor = queryVendor.data!.find((v) => v.id === value);
                setLocalVendorTo(vendor);
                setLocalVendorAccountTo(null);
              }
            }}
            style={{ width: '100%' }}
          />
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabelWrapper>
            <Content id={ContentId.Account} />
          </FieldLabelWrapper>
          <PosSelect
            {...hookVendorAccountToPosSelectProps}
            value={localVendorAccountTo?.id}
            onChange={(val) => {
              if (val && localVendorAccountTo) {
                const vendorAccount = sortedVendorAccounts!.find(
                  (v) => v.id === val
                );
                setLocalVendorAccountTo(vendorAccount);
              }
            }}
            style={{ width: '100%' }}
          />
        </FieldWrapper>
      </div>
    </GenericDialog>
  );
};
