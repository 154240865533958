import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { BulkChangeTicketTypeRuleDialog } from 'src/dialogs/BulkEdits/BulkChangeTicketTypeRuleDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  DeliveryType,
  Event,
  ListingQuery,
  TicketTypeOverride,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_TICKET_TYPE_RULE_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchChangeTicketTypeRule } from './LaunchChangeTicketTypeRule';

export const useBulkChangeTicketTypeRuleAction = (
  filterQueryWithEventIds: ListingQuery,

  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = event
    ? `ticket-type-rule-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_TICKET_TYPE_RULE_UPDATE_KEY;
  const {
    refreshCatalog,
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkChangeTicketTypeRuleDialog,
    closeDialog: closeBulkChangeTicketTypeRuleDialog,
  } = useDialog(
    DialogId.BulkChangeTicketTypeRule,
    BulkChangeTicketTypeRuleDialog
  );

  const onSubmit = useCallback(
    async (
      deliveryType: DeliveryType | null,
      ticketTypeRules: TicketTypeOverride | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const client = new BulkEditListingClient(
              activeAccountWebClientConfig
            );

            const preview = await client.bulkUpdateTicketTypeRulesPreview(
              filterQueryWithEventIds
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateTicketTypeRulesPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (deliveryType && ticketTypeRules) {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateTicketTypeRules(
              {
                item1: preview!.preview,
                item2: deliveryType,
                item3:
                  ticketTypeRules.overrides[0].ticketTypePriorities ?? null,
              },
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                if (!event) {
                  await refreshCatalog();
                }
                await refreshExpandedListItems();
              }

              closeBulkChangeTicketTypeRuleDialog();
            }
          },
          (error) => {
            showErrorDialog(
              'BulkEditListingClient.bulkUpdateTicketTypeRules',
              error,
              {
                trackErrorData: {
                  preview,
                  deliveryType,
                  ticketTypeRules:
                    ticketTypeRules.overrides[0].ticketTypePriorities ?? null,
                },
              }
            );
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQueryWithEventIds,
      showErrorDialog,
      updateKey,
      closeBulkChangeTicketTypeRuleDialog,
      event,
      refreshExpandedListItems,
      refreshCatalog,
    ]
  );

  return {
    dropDown: (
      <LaunchChangeTicketTypeRule
        key="ChangeTicketTypeRule"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (event) {
            setListItemExpansion(true, [event.viagVirtualId]);
          }
          openBulkChangeTicketTypeRuleDialog({
            event,
            updateKey,
            onOkay: onSubmit,
            onClose: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkChangeTicketTypeRuleDialog,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
