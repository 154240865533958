import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Stack } from 'src/core/ui';
import {
  CancelPurchaseDialogModeRadioGroup,
  PurchaseCancellationType,
} from 'src/dialogs/CancelPurchaseDialog';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkCancelPurchaseDialogProps = ComponentProps<typeof RSModal> & {
  updateKey: string;
  onOkay: (
    cancellationType: PurchaseCancellationType | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

export function BulkCancelPurchaseDialog({
  updateKey,
  onOkay,
  onCancel,
  ...rest
}: BulkCancelPurchaseDialogProps) {
  const [cancellationType, setCancellationType] =
    useState<PurchaseCancellationType>('cancel');

  const [isLoading, setIsLoading] = useState(false);

  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const {
    eventsExpansion: { refreshExpandedListItems },
    refreshCatalog,
  } = useCatalogDataContext();
  const { refreshMetrics } = useCatalogMetricsContext();
  const { refreshData } = usePurchaseDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshData(true);

        // Metrics/events may have changed with TGs being cancelled, so refresh the whole catalog
        refreshMetrics();
        await refreshCatalog();
        await refreshExpandedListItems();

        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshCatalog,
      refreshData,
      refreshExpandedListItems,
      refreshMetrics,
      setActivePosEntity,
      setMainDialogOpened,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.Purchase,
    BulkActionType.CancelPurchase,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const [isSecondScreen, setIsSecondScreen] = useState<boolean>(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(null, false, onPreviewReceived);
      } else {
        onOkay(
          cancellationType,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [onOkay, cancellationType, preview, hasBackgroundBulkEditFeature]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <GenericDialog
        {...rest}
        size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
        header={
          <BulkEditHeader
            headerText={<Content id={ContentId.CancelPurchasesParenth} />}
          />
        }
        onOpened={() => {
          initJob();
        }}
        onKeyUp={(e) => {
          if (submittButtonRef.current && e.key === 'Enter') {
            submittButtonRef.current.click();
          }
        }}
        onClosed={() => {
          setMainDialogOpened(false);
          if (progress) {
            setSelectionMode(undefined);
          }
          // Call the outside one if there is one
          rest.onClosed?.();
        }}
        footer={
          <BulkEditFooter
            entityType={ActionOutboxEntityType.Purchase}
            isLoading={isLoading}
            hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
            onClose={onClose}
            onSubmit={onSubmitHandler}
            submittButtonRef={submittButtonRef}
            onBeforeSubmit={() => {
              if (!isSecondScreen) {
                setIsSecondScreen(true);
                return false; // don't proceed to preview
              }

              return true; // proceed to preview
            }}
          />
        }
        onCancel={isLoading ? undefined : onCancel}
      >
        <BulkEditStatus
          entityType={ActionOutboxEntityType.Purchase}
          isLoading={isRefreshing || isLoading}
          updateKey={updateKey}
        >
          <Stack direction="column" width="full" gap="s">
            {!isLoading && !progress?.failureCount && (
              <>
                {isSecondScreen ? (
                  <Content
                    id={
                      cancellationType === 'cancel'
                        ? ContentId.CancelBulkPurchaseWarning
                        : ContentId.VoidBulkPurchaseWarning
                    }
                  />
                ) : (
                  <CancelPurchaseDialogModeRadioGroup
                    disabled={isLoading}
                    onValueChange={(value) => {
                      if (value === 'cancel' || value === 'void') {
                        setCancellationType(value);
                      }
                    }}
                    value={cancellationType}
                  />
                )}
              </>
            )}
          </Stack>
        </BulkEditStatus>
      </GenericDialog>
    </>
  );
}
