import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ProcessingIcon } from 'src/svgs/Viagogo';

import { RotatingWrapper } from '../../ui/AnimatingWrapper';
import * as styles from './MessageWithIcon.css';

export const WorkingMessage = ({
  message,
}: {
  message: string | JSX.Element;
}) => {
  return (
    <Stack direction="row" gap="m" className={styles.messageContainer}>
      <RotatingWrapper>
        <ProcessingIcon size={vars.iconSize.l} />
      </RotatingWrapper>
      <div className={styles.messageDiv}>{message}</div>
    </Stack>
  );
};
