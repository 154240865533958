import { useCallback, useState } from 'react';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { TAG_VALUE_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { Tag, TagsValueType } from 'src/WebApiController';

import * as styles from './TagsFormBody.css';
import { TagValueDisplay, TagValueInputCell } from './TagValueCell';

const TagListItem = ({
  tag,
  tagsCurrentEntity,
  disabled,
  onClick,
  onBlur,
  isEditing,
  onRowChange,
}: {
  tag: Tag;
  tagsCurrentEntity: Tag[];
  disabled?: boolean;
  isEditing?: boolean;
  onClick: () => void;
  onBlur: () => void;
  onRowChange: (tag: Tag, newValue: string) => void;
}) => {
  const singleValueMsg = useContent(ContentId.SingleValueOnly);
  const dataTypeMsg = useContent(
    TAG_VALUE_TYPE_TO_CID[tag.valueType ?? TagsValueType.String]
  );
  const [hovered, setHovered] = useState(false);

  let metadataTitle = '';
  if (tag.singleValueOnly) {
    metadataTitle += `${singleValueMsg}, `;
  }
  if (dataTypeMsg) {
    metadataTitle += dataTypeMsg;
  }

  return (
    <div
      className={styles.tagRow}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
      title={isEditing ? '' : metadataTitle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className={styles.textSecondary}>{tag.key}</div>
      <hr className={styles.divider} />
      {isEditing ? (
        <TagValueInputCell
          onBlur={onBlur}
          tag={tag}
          disabled={disabled}
          tagsCurrentEntity={tagsCurrentEntity}
          onChange={(newValue) => {
            onRowChange(tag, newValue);
          }}
        />
      ) : (
        <>
          {tag.value || !hovered ? (
            <TagValueDisplay tag={tag} displayMode />
          ) : (
            <Button
              disabled={disabled}
              variant={'link'}
              style={{ whiteSpace: 'nowrap', padding: 0 }}
            >
              <Content id={ContentId.AddValue} />
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export const TagsPanelDisplayMode = ({
  tagsWithEmptyValue,
  tagsWithValue,
  onTagsUpdate,
  onTagValueChange,
  disabled,
}: {
  tagsWithEmptyValue: Tag[];
  tagsWithValue: Tag[];
  disabled?: boolean;
  onTagsUpdate: (tagsCurrentEntity: Tag[]) => void;
  onTagValueChange: (tag: Tag, newValue: string) => void;
}) => {
  const [tagsEditing, setTagsEditing] = useState<Tag>();

  const onTagAdd = useCallback(
    (tag: Tag, newValue: string) => {
      if (newValue) {
        const newTags = [...tagsWithValue, { ...tag, value: newValue }]
          .sort((t1, t2) => (t1.ordinal ?? 0) - (t2.ordinal ?? 0))
          .map((t, i) => ({ ...t, ordinal: i }));
        onTagsUpdate(newTags);
      }
      setTagsEditing(undefined);
    },
    [onTagsUpdate, tagsWithValue]
  );

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {tagsWithEmptyValue.map((tag) => {
        const tagListItem = (
          <TagListItem
            key={`${tag.key}-${tag.value}`}
            tag={tag}
            isEditing={
              tagsEditing?.key === tag.key && tagsEditing?.value === tag.value
            }
            onClick={() => {
              setTagsEditing(tag);
            }}
            tagsCurrentEntity={tagsWithValue}
            disabled={disabled}
            onBlur={() => setTagsEditing(undefined)}
            onRowChange={(tag, newValue) => {
              onTagValueChange(tag, newValue);
              setTagsEditing(undefined);
            }}
          />
        );

        const isLastNonEmptyMultiValueTag =
          tag.value &&
          !tag.singleValueOnly &&
          tagsWithValue.findLast((t) => t.key === tag.key && t.value) === tag;

        if (!isLastNonEmptyMultiValueTag) {
          return tagListItem;
        } else {
          if (tagsEditing?.key === tag.key && tagsEditing?.value === '') {
            const newTag = {
              ...tag,
              value: '',
            };
            return (
              <>
                {tagListItem}
                <TagListItem
                  key={`${tag.key}-${tag.value}-input`}
                  tag={newTag}
                  isEditing={
                    tagsEditing?.key === newTag.key &&
                    tagsEditing?.value === newTag.value
                  }
                  onClick={() => {
                    setTagsEditing(newTag);
                  }}
                  tagsCurrentEntity={tagsWithValue}
                  disabled={disabled}
                  onBlur={() => setTagsEditing(undefined)}
                  onRowChange={onTagAdd}
                />
              </>
            );
          }
          return (
            <>
              {tagListItem}
              <Button
                key={`${tag.key}-${tag.value}-button`}
                onClick={() => {
                  setTagsEditing({
                    ...tag,
                    value: '',
                  });
                }}
                style={{ width: 'min-content', whiteSpace: 'nowrap' }}
                variant={'text'}
                disabled={disabled}
              >
                <PlusIcon
                  withHoverEffect
                  fill={IconsFill.textBrand}
                  size={vars.iconSize.m}
                  disabled={disabled}
                />
                <FormatContent
                  id={FormatContentId.AddAnother}
                  params={tag.key}
                />
              </Button>
            </>
          );
        }
      })}
    </div>
  );
};
