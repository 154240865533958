import { PaymentsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/payment-wrapper';

export function PaypalIcon(props: PaymentsProps) {
  return (
    <SvgWrapper {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          x={0.5}
          y={0.5}
          width={35}
          height={23}
          rx={4}
          fill="#FFF"
          stroke="#CFD4D9"
        />
        <path
          d="M12.234 10.337c.43 0 .86.098 1.053.391l.062.094.04-.26a.157.157 0 0 1 .152-.137h.892c.095 0 .168.09.153.188l-.482 3.19a.262.262 0 0 1-.256.228h-.802c-.095 0-.168-.09-.153-.188l.04-.259s-.44.533-1.234.533c-.462 0-.85-.14-1.122-.474-.296-.364-.417-.885-.332-1.431.164-1.094 1.005-1.875 1.99-1.875ZM8.871 8.625c.622 0 1.091.172 1.356.497.24.296.32.719.237 1.256-.175 1.165-.82 1.783-1.929 1.845l-.189.005h-.589c-.106 0-.199.067-.238.166l-.017.062-.203 1.347a.266.266 0 0 1-.194.22l-.061.008h-.889a.158.158 0 0 1-.154-.14v-.048l.753-4.99a.267.267 0 0 1 .194-.22l.062-.008h1.86Zm3.574 2.701c-.507 0-.918.37-.998.897-.042.259.008.491.14.654.132.165.34.252.598.252.515 0 .92-.358 1.006-.89.039-.258-.015-.492-.152-.66-.135-.165-.34-.253-.594-.253Zm-4.142-1.62h-.254a.155.155 0 0 0-.14.091l-.013.045-.197 1.304h.38c.46-.003.911-.046 1.006-.698.037-.245.008-.423-.09-.543-.123-.15-.332-.187-.57-.197l-.122-.002Zm10.875.72h-.896a.255.255 0 0 0-.213.118l-1.236 1.903-.524-1.829a.26.26 0 0 0-.247-.193h-.88c-.107 0-.182.11-.147.215l.986 3.027-.928 1.369c-.073.107.001.256.127.256h.895a.256.256 0 0 0 .212-.116l2.978-4.496c.072-.108-.002-.255-.127-.255"
          fill="#28346A"
        />
        <path
          d="M22.423 10.395c-.102.678-.612.678-1.107.678h-.28l.197-1.266a.156.156 0 0 1 .153-.133h.129c.336 0 .654 0 .818.194.098.116.127.289.09.527m-.215-1.77h-1.863a.26.26 0 0 0-.256.222l-.754 4.845a.157.157 0 0 0 .154.183h.956a.182.182 0 0 0 .179-.156l.214-1.373a.26.26 0 0 1 .255-.222h.59c1.227 0 1.936-.603 2.121-1.797.084-.521.003-.932-.237-1.22-.265-.315-.735-.482-1.359-.482m4.327 3.51c-.086.517-.491.864-1.008.864-.258 0-.466-.085-.599-.245-.132-.158-.181-.384-.14-.635.08-.513.492-.871 1-.871.254 0 .46.085.595.246.137.163.191.39.152.64m1.244-1.761h-.893a.156.156 0 0 0-.153.133l-.04.253-.062-.092c-.193-.284-.624-.38-1.054-.38-.986 0-1.828.759-1.992 1.821-.085.53.036 1.037.332 1.39.273.325.661.46 1.124.46.795 0 1.235-.517 1.235-.517l-.04.251a.157.157 0 0 0 .154.182h.804a.26.26 0 0 0 .256-.221l.482-3.098a.157.157 0 0 0-.153-.182m1.051-1.615-.765 4.934a.157.157 0 0 0 .154.183h.77a.26.26 0 0 0 .255-.222l.754-4.846a.157.157 0 0 0-.154-.182h-.86a.156.156 0 0 0-.154.133"
          fill="#2890C3"
        />
      </g>
    </SvgWrapper>
  );
}
