import { useDraggable, useDroppable } from '@dnd-kit/core';
import {
  ColumnDef,
  createColumnHelper,
  Row,
  SortingFn,
  sortingFns,
} from '@tanstack/react-table';
import { add } from 'date-fns';
import { range } from 'lodash-es';
import { useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { AutoPriceResultDisplay } from 'src/components/Listings/AutoPriceResultDisplay';
import { FAKE_SOLD_GROUP_ID } from 'src/components/Listings/constants';
import { ListingDeliveryTypeDisplay } from 'src/components/Listings/ListingDeliveryTypeDisplay';
import { ListingDisplay } from 'src/components/Listings/ListingDisplay';
import { TagValueDisplay } from 'src/components/TagsFormBody/TagValueCell';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_SM,
  COLUMN_DEFAULT_SIZE_XL,
  COLUMN_DEFAULT_SIZE_XXL,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { ModalContext } from 'src/contexts/ModalContext';
import {
  NO_GROUP_ID,
  TableRow,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { vars } from 'src/core/themes';
import { Button, Stack, TooltipPopover } from 'src/core/ui';
import { CopyToClipboardV2 } from 'src/core/ui/CopyToClipboard/CopyToClipboardV2';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { useAllInPriceFromListPrice } from 'src/hooks/useListAndAllInPrice';
import { useMapDeliveryTypesToString } from 'src/hooks/useMapDeliveryTypesToString';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { FormatOption, FormatOptionEntries } from 'src/modals/EditTableColumns';
import { CustomColumnName } from 'src/modals/InventoryTableColumns/CustomColumnName';
import { ListingGroupIcon } from 'src/svgs/ListingGroupIcon';
import { ExpandIcon, ForwardIcon } from 'src/svgs/Viagogo';
import { SEATING_SORTING_FN_NAME } from 'src/tables/common/hooks/useSeatingColumnSorting';
import { ListingActionsCell } from 'src/tables/ListingTable/configs/ListingActionsCell';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { DateCell, InHandCell } from 'src/tables/Table/TableCells';
import {
  getCompListingModeIcon,
  getUndercutModeIcon,
} from 'src/utils/autoPricingUtils';
import { ListingTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { evaluateFormula } from 'src/utils/columns/inventory/inventoryCustomColumnUtils';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID } from 'src/utils/constants/contentIdMaps';
import {
  getListingDetailsModalConfigWithDeepLink,
  getListingDetailsRelativeUrl,
  isListingGroupLeader,
} from 'src/utils/inventoryUtils';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import {
  getNextEditableListingRow,
  sortGeneric,
  sortMoney,
} from 'src/utils/tableUtils';
import {
  SECTION_TYPE_TO_PRECISION_SETTING,
  SectionType,
} from 'src/utils/types/sectionType';
import {
  AutoPricingCompListingMode,
  AutoPricingResultType,
  AutoPricingUndercutMode,
  Feature,
  Listing,
  ListingGroup,
  ListingGroupUndercutSetting,
  Marketplace,
  TagsValueType,
  UiMoney,
  UserSetting,
} from 'src/WebApiController';

import * as styles from '../ListingTable.css';
import {
  ListingWithEvent,
  SHIMMERING_DIV_HEIGHT_LISTING_GROUP,
  SHIMMERING_DIV_HEIGHT_SINGLE_LISTING,
} from '../ListingTable.types';
import { AutoPricingToggleCell } from './AutoPricingToggleCell';
import { CompListingBoundsCell } from './CompListingBoundsCell';
import { CustomNumericCell } from './CustomNumericCell';
import { ListingPriceCell } from './ListingPriceCell';
import { ListingProceedsFloorCell } from './ListingProceedsFloorCell';
import { PricingModeCell } from './PricingModeCell';
import { CircuitBreakerMinCompListingCountCell } from './PricingSettings/CircuitBreakerMinCompListingCount';
import { CircuitBreakerVelocityCell } from './PricingSettings/CircuitBreakerVelocity';
import { CircuitBreakerVelocityHoursCell } from './PricingSettings/CircuitBreakerVelocityPeriod';
import { CompListingExcludeFanInventoryCell } from './PricingSettings/CompListingExcludeFanInventory';
import { CompListingMinQuantityCell } from './PricingSettings/CompListingMinQuantity';
import { OutlierModeCell } from './PricingSettings/OutlierDetectionMode';
import { OutlierFilteringCell } from './PricingSettings/OutlierFiltering';
import { OutlierLimitCell } from './PricingSettings/OutlierLimit';
import { OutlierSpacingCell } from './PricingSettings/OutlierSpacing';
import { UndercutAmountCell } from './UndercutAmountCell';

const SECTION_TYPE = SectionType.Listings;

const GroupListingsSortableFields: ListingTableColumnId[] = [
  ListingTableColumnId.ListPrice,
  ListingTableColumnId.AllInPrice,
  ListingTableColumnId.ProceedsCeiling,
  ListingTableColumnId.ProceedsFloor,
  ListingTableColumnId.WebsiteCeiling,
  ListingTableColumnId.WebsiteFloor,
  ListingTableColumnId.UnsoldQuantity,
  ListingTableColumnId.ShownQuantity,
];

/**
 * Sorting wrapper to make sure sorting will not be applied to grouped items.
 * @param desiredSortingFn
 * @returns shadowed sorting fn
 */
const groupSortingWrapper = (
  desiredSortingFn: SortingFn<ListingWithEvent | null>,
  columnId: ListingTableColumnId | undefined = undefined,
  hasGroupListingsSortingFeature = false
): SortingFn<ListingWithEvent | null> => {
  return (
    rowA: Row<ListingWithEvent | null>,
    rowB: Row<ListingWithEvent | null>,
    colId: string
  ) => {
    const canSort =
      hasGroupListingsSortingFeature &&
      columnId &&
      GroupListingsSortableFields.includes(columnId);
    if (rowA.depth + rowB.depth > 0 && !canSort) return 0;
    return desiredSortingFn(rowA, rowB, colId);
  };
};

export const tagColumnDef = (
  tagName: string,
  columnId: ListingTableColumnId | undefined = undefined,
  canSortGroup = false
): ColumnDef<ListingWithEvent | null> => ({
  id: tagName,
  header: () => (
    <TableHeaderCellDiv align="right">
      <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
        {tagName}
      </span>
    </TableHeaderCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_LG,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    if (data?.isCompListing) return undefined;

    const tag = data?.listing?.tags?.find((t) => t.key === tagName);
    if (
      tag?.valueType === TagsValueType.Int ||
      tag?.valueType === TagsValueType.Decimal
    ) {
      return tag ? parseFloat(tag.value) : null;
    }
    return tag?.value;
  },
  cell: function Cell({ getValue, row: { original }, table }) {
    const { value: storedColumnNumberPrecisions = {} } =
      useServerUserSetting<FormatOptionEntries>({
        id: SECTION_TYPE_TO_PRECISION_SETTING[
          table.options.meta?.sectionType ?? SECTION_TYPE
        ],
      });
    const tag = original?.listing?.tags?.find((t) => t.key === tagName);

    if (
      tag?.valueType === TagsValueType.Int ||
      tag?.valueType === TagsValueType.Decimal
    ) {
      const userDefinedPrecision = storedColumnNumberPrecisions[tagName];

      return (
        <CustomNumericCell
          columnId={tagName}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={Boolean(!original || !original.listing)}
          // We do not display custom fields for listing group or comp listing
          hide={Boolean(
            original?.listing?.isLtGrp || !original?.listing?.isFull
          )}
          shimmeringDivHeight={
            original?.isLtGrp
              ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
              : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
          }
          sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
          matchingTag={tag}
          currencyCode={original?.listing?.currency}
        />
      );
    }

    if (!original || !original.listing) {
      return (
        <TableShimmeringDiv
          height={
            original?.isLtGrp
              ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
              : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
          }
        />
      );
    }

    if (original?.listing?.isLtGrp || !original?.listing?.isFull || !tag) {
      return null;
    }

    return (
      <TableCellDiv align="right">
        <TagValueDisplay tag={tag} displayMode />
      </TableCellDiv>
    );
  },
  sortingFn: groupSortingWrapper(sortingFns.basic, columnId, canSortGroup),
});

export const customColumnDef = (
  columnId: string,
  formula?: string | null,
  canSortGroup = false
): ColumnDef<ListingWithEvent | null> => ({
  id: columnId,
  header: ({ table }) => (
    <TableHeaderCellDiv align="right">
      <CustomColumnName
        id={columnId}
        sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
      />
    </TableHeaderCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_LG,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    return formula && data && !data.isCompListing
      ? evaluateFormula(formula, data.listing) ?? null
      : null;
  },
  cell: function Cell({ getValue, row: { original }, table }) {
    const { value: storedInventoryColumnNumberPrecisions = {} } =
      useServerUserSetting<FormatOptionEntries>({
        id: SECTION_TYPE_TO_PRECISION_SETTING[
          table.options.meta?.sectionType ?? SECTION_TYPE
        ],
      });
    const { value: customColumns = [] } = useServerUserSetting<
      CustomListingColumn[]
    >({
      id: UserSetting.InventoryCustomColumns,
    });

    const userDefinedPrecision =
      storedInventoryColumnNumberPrecisions[columnId];

    return (
      <CustomNumericCell
        columnId={columnId}
        userDefinedPrecision={userDefinedPrecision}
        value={getValue<number | null>()}
        isLoading={Boolean(!original || !original.listing)}
        // We do not display custom fields for listing group or comp listing
        hide={Boolean(original?.listing?.isLtGrp || !original?.listing?.isFull)}
        shimmeringDivHeight={
          original?.isLtGrp
            ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
            : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
        }
        sectionType={table.options.meta?.sectionType ?? SECTION_TYPE}
        matchingCustomColumn={customColumns.find((c) => c.id === columnId)}
        currencyCode={original?.listing?.currency}
      />
    );
  },
  sortingFn: groupSortingWrapper(
    sortingFns.basic,
    columnId as ListingTableColumnId,
    canSortGroup
  ),
});

const columnHelper = createColumnHelper<ListingWithEvent | null>();

export const getListingTableColumnConfigs = (canSortGroup = false) => {
  return {
    [ListingTableColumnId.Checkbox]: columnHelper.display({
      id: ListingTableColumnId.Checkbox,
      header: '',
      size: COLUMN_DEFAULT_SIZE_SM,
      minSize: COLUMN_DEFAULT_SIZE_SM,
      maxSize: COLUMN_DEFAULT_SIZE_SM,
      enableSorting: false,
      enableResizing: false,
      cell: function Cell({
        row: {
          id,
          original,
          getIsSelected,
          depth,
          getCanSelectSubRows,
          getCanSelect,
          getIsAllSubRowsSelected,
        },
        table,
      }) {
        const { setShiftKeyRowSelectionState, isNoGroupIdSet } =
          useMultiSelectionContext();

        const isLtGrp = original?.isLtGrp;

        if (!original || !original.listing) {
          return (
            <TableShimmeringDiv
              height={
                isLtGrp
                  ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                  : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
              }
            />
          );
        }

        const isListingGroupSelected = getIsAllSubRowsSelected();
        const isSelected = isListingGroupSelected || getIsSelected();
        const canSelect =
          getCanSelectSubRows() || (getCanSelect() && depth === 0);

        const groupId = isNoGroupIdSet()
          ? NO_GROUP_ID
          : original.event.viagVirtualId;

        return canSelect ? (
          <TableCellDiv
            align="left"
            title={original?.selectionDisabledReason}
            showTooltip={!!original?.selectionDisabledReason}
            style={{ paddingLeft: vars.spacing.sm }}
          >
            <Checkbox
              checked={isSelected}
              disabled={!!original?.selectionDisabledReason}
              onChange={() => {
                setShiftKeyRowSelectionState(
                  id,
                  table.getRowModel().rows as unknown as TableRow[],
                  groupId
                );
              }}
            />
          </TableCellDiv>
        ) : null;
      },
    }),
    [ListingTableColumnId.TicketClassColor]: columnHelper.accessor(
      (data) => data?.ticketClassColor ?? '',
      {
        id: ListingTableColumnId.TicketClassColor,
        header: () => (
          <Content
            id={
              LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                ListingTableColumnId.TicketClassColor
              ]
            }
          />
        ),
        size: COLUMN_DEFAULT_SIZE_BASE,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,

        cell: function Cell({ getValue, row: { original } }) {
          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          const ticketClassColor = getValue<string | null>();

          return (
            <TableCellDiv align="center">
              <div
                style={{
                  height: vars.iconSize.m,
                  width: vars.iconSize.m,
                  borderRadius: '50%',
                  backgroundColor: ticketClassColor ?? '',
                }}
              ></div>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.TicketClassColor,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.ListingId]: columnHelper.accessor(
      (data) => data?.listing?.id,
      {
        id: ListingTableColumnId.ListingId,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.ListingId
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({ getValue, row: { original } }) {
          const { setActivePosEntity } = useActivePosEntityContext<Listing>();
          const { setModal } = useContext(ModalContext);
          const onViewClick = useCallback(async () => {
            if (!original || !original.listing?.id) {
              return;
            }
            setActivePosEntity(
              original.listing.id,
              original.listing.idOnMkp,
              true
            );
            setModal(
              getListingDetailsModalConfigWithDeepLink(
                original.listing.id,
                true
              )
            );
          }, [original, setActivePosEntity, setModal]);

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          // We do not display ID for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <TableCellDiv align="right" title={String(getValue())}>
              <Link to={getListingDetailsRelativeUrl(original.listing.id)}>
                <Button
                  variant="link"
                  size="unset"
                  shape="inline"
                  onClick={onViewClick}
                  style={{ fontSize: vars.typography.fontSize['sm'] }}
                >
                  {getValue() as number}
                </Button>
              </Link>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.ListingId,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.Seating]: columnHelper.accessor(
      (data) =>
        data?.listing
          ? {
              seating: data.listing.seating,
              listingNotes: data.listing.seatTraits,
            }
          : null,
      {
        id: ListingTableColumnId.Seating,
        header: function Header({ table }) {
          const seatingText = useContent(
            LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[ListingTableColumnId.Seating]
          );
          const sectionText = useContent(ContentId.Section);
          const { value: storedInventoryColumnNumberPrecisions = {} } =
            useServerUserSetting<FormatOptionEntries>({
              id: SECTION_TYPE_TO_PRECISION_SETTING[
                table.options.meta?.sectionType ?? SECTION_TYPE
              ],
            });

          const isSectionOnly =
            storedInventoryColumnNumberPrecisions[
              ListingTableColumnId.Seating
            ] === FormatOption.Seating_SectionOnly;

          return (
            <TableHeaderCellDiv align="left">
              <Stack direction="row" style={{ paddingLeft: '20px' }}>
                <span>
                  {isSectionOnly
                    ? `${seatingText} (${sectionText})`
                    : seatingText}
                </span>
              </Stack>
            </TableHeaderCellDiv>
          );
        },
        size: COLUMN_DEFAULT_SIZE_XXL,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original, getCanExpand, getIsExpanded, toggleExpanded, depth },
          cell: { id: cellId },
          column: { id: columnId },
          table,
        }) {
          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          const droppable = useDroppable({
            id: cellId,
            data: {
              ltGrpId: original?.listing?.ltGrpId,
              listingId: original?.listing?.id,
              listingGroupPriority: original?.listing?.ltGrpPrior,
            },
          });
          const draggable = useDraggable({
            id: cellId,
            data: {
              listingId: original?.listing?.id,
              currentListingGroupId: original?.listing?.ltGrpId,
              listingGroupPriority: original?.listing?.ltGrpPrior,
            },
          });

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          const isListing = !original?.isLtGrp && !original?.listing?.isLtGrp;
          const dndProps =
            isListing && !original?.ltGroupName
              ? {
                  ref: (node: HTMLDivElement) => {
                    draggable.setNodeRef(node);
                    droppable.setNodeRef(node);
                  },
                  ...draggable.listeners,
                  ...draggable.attributes,
                }
              : isListingGroupLeader(original?.listing)
              ? {
                  ref: droppable.setNodeRef,
                }
              : {};

          const adjustedListing =
            userDefinedPrecision === FormatOption.Seating_SectionOnly
              ? {
                  ...original.listing,
                  seating: {
                    ...original.listing.seating,
                    row: null,
                    seatFr: null,
                    seatTo: null,
                  },
                }
              : original.listing;

          const canExpand = getCanExpand();
          const isExpanded = getIsExpanded();
          const Icon = isExpanded ? ExpandIcon : ForwardIcon;

          return (
            <TableCellDiv>
              <Stack
                direction="row"
                gap="m"
                alignItems="center"
                width="full"
                {...dndProps}
                style={{ opacity: draggable.isDragging ? 0 : 1 }}
              >
                <Stack>
                  {range(depth).map((i) => {
                    return <div key={i} className={styles.invisibleSpace} />;
                  })}
                </Stack>
                {canExpand && (
                  <>
                    <div>
                      <Icon
                        size={vars.iconSize.s}
                        withHoverEffect
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          toggleExpanded(!isExpanded);
                        }}
                      />
                    </div>
                    {isListingGroupLeader(original.listing) && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          toggleExpanded(!isExpanded);
                        }}
                      >
                        <ListingGroupIcon
                          stroke={vars.color.textBrand}
                          size={vars.iconSize.m}
                          withHoverEffect
                        />
                      </div>
                    )}
                  </>
                )}
                <Stack
                  direction="row"
                  justifyContent="spaceBetween"
                  width="full"
                  alignItems="center"
                >
                  <Stack
                    title={original?.selectionDisabledReason}
                    direction="column"
                    gap="s"
                    width="full"
                  >
                    <ListingDisplay
                      listing={adjustedListing!}
                      showOrgSeat={
                        userDefinedPrecision ===
                        FormatOption.Seating_OriginalSeating
                      }
                      width="full"
                      justifySeatingIconContent="spaceBetween"
                    />
                  </Stack>
                  <div style={{ flexShrink: 0, display: 'flex' }}>
                    {original.ltGroupName && (
                      <TooltipPopover
                        triggerContent={
                          <ListingGroupIcon
                            stroke={vars.color.textBrand}
                            size={vars.iconSize.m}
                          />
                        }
                      >
                        <div>
                          <Content id={ContentId.MergeListingGroupTooltip} />{' '}
                          <strong>{original.ltGroupName}</strong>
                        </div>
                      </TooltipPopover>
                    )}
                  </div>
                </Stack>
              </Stack>
            </TableCellDiv>
          );
        },
        sortingFn: SEATING_SORTING_FN_NAME,
      }
    ),
    [ListingTableColumnId.DeliveryType]: columnHelper.accessor(
      (data) => data?.listing?.delivType,
      {
        id: ListingTableColumnId.DeliveryType,
        header: () => (
          <TableHeaderCellDiv align="left">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.DeliveryType
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original, getCanExpand },
          column: { id: columnId },
          table,
        }) {
          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          const strDeliveryTypes = useMapDeliveryTypesToString({
            deliveryTypesObj: original?.listing,
          });

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          // We do not display for comp listing
          if (original.isCompListing) return null;

          // Don't show delivery type when row can be expanded
          if (getCanExpand()) {
            return null;
          }

          return (
            <TableCellDiv
              align="left"
              title={strDeliveryTypes}
              style={{ height: '100%' }}
            >
              <ListingDeliveryTypeDisplay
                listing={original.listing}
                userDefinedPrecision={userDefinedPrecision}
                showIconTitle={false}
              />
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.DeliveryType,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.ShownQuantity]: columnHelper.accessor(
      (data) => data?.listing?.manMaxDispQty ?? data?.listing?.maxDispQty ?? 0,
      {
        id: ListingTableColumnId.ShownQuantity,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.ShownQuantity
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          // We do not display for comp listing
          if (original.isCompListing) return null;

          return (
            <TableCellDiv align="right">
              <div
                className={
                  isListingGroupLeader(original.listing) ? styles.bold : ''
                }
              >
                {applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: getValue<number>(),
                })}
              </div>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.ShownQuantity,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.UnsoldQuantity]: columnHelper.accessor(
      (data) => data?.listing?.availQty ?? 0,
      {
        id: ListingTableColumnId.UnsoldQuantity,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.UnsoldQuantity
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          return (
            <TableCellDiv align="right">
              <div
                className={
                  isListingGroupLeader(original.listing) ? styles.bold : ''
                }
              >
                {applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: getValue<number>(),
                })}
              </div>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.UnsoldQuantity,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.TicketCount]: columnHelper.accessor(
      (data) => data?.listing?.ticketCnt ?? 0,
      {
        id: ListingTableColumnId.TicketCount,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.TicketCount
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          // We do not display for comp listing
          if (original.isCompListing) return null;

          return (
            <TableCellDiv align="right">
              <div
                className={
                  isListingGroupLeader(original.listing) ? styles.bold : ''
                }
              >
                {applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: getValue<number>(),
                })}
              </div>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.TicketCount,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.SoldQty]: columnHelper.accessor(
      (data) =>
        (data?.listing?.ticketCnt ?? 0) - (data?.listing?.availQty ?? 0),
      {
        id: ListingTableColumnId.SoldQty,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.SoldQty
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          // We do not display for comp listing
          if (original.isCompListing) return null;

          return (
            <TableCellDiv align="right">
              <div
                className={
                  isListingGroupLeader(original.listing) ? styles.bold : ''
                }
              >
                {applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: getValue<number>(),
                })}
              </div>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.SoldQty,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.InHandDate]: columnHelper.accessor(
      (data) => data?.listing?.inHandAt || '',
      {
        id: ListingTableColumnId.InHandDate,
        header: () => (
          <TableHeaderCellDiv align="left">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.InHandDate
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const value = getValue<string>();
          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          // We do not display inhand for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <TableCellDiv align="left">
              <InHandCell date={value ? new Date(value) : null} />
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.datetime,
          ListingTableColumnId.InHandDate,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.UnitCost]: columnHelper.accessor(
      (data) => data?.listing?.unitCst ?? data?.listing?.faceValue,
      {
        id: ListingTableColumnId.UnitCost,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.UnitCost
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const value = getValue<UiMoney>();
          const { getUiCurrency } = useLocalizationContext();
          const currency = getUiCurrency(original?.listing?.currency);

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing) return null;

          // We do not display for comp listing
          if (original.isCompListing) return null;

          if (!value) return <Content id={ContentId.NA} />;

          return (
            <TableCellDiv
              align="right"
              className={
                isListingGroupLeader(original.listing) ? styles.bold : ''
              }
            >
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: currency.code,
                    currencyDecimalDigits: currency.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortMoney<ListingWithEvent>,
          ListingTableColumnId.UnitCost,
          canSortGroup
        ),
      }
    ),

    [ListingTableColumnId.UnsoldProceeds]: columnHelper.accessor(
      (data) =>
        data?.listing ? data.listing.listPrice * data.listing.availQty : null,
      {
        id: ListingTableColumnId.UnsoldProceeds,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.UnsoldProceeds
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const value = getValue<number | null>();
          const { getUiCurrency } = useLocalizationContext();
          const currency = getUiCurrency(original?.listing?.currency);

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing || original?.listing?.isLtGrp) return null;

          // We do not display for comp listing
          if (original.isCompListing) return null;

          if (value == null) return <Content id={ContentId.NA} />;

          return (
            <TableCellDiv align="right">
              <div>
                {applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: value,
                  currencyCode: currency.code,
                  currencyDecimalDigits: currency.dec,
                })}
              </div>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.UnsoldProceeds,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.UnsoldCost]: columnHelper.accessor(
      (data) =>
        data?.listing && (data.listing.unitCst || data.listing.faceValue)
          ? (data.listing.unitCst?.amt ?? data.listing.faceValue?.amt ?? 0) *
            data.listing.availQty
          : null,
      {
        id: ListingTableColumnId.UnsoldCost,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.UnsoldCost
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const value = getValue<number | null>();
          const { getUiCurrency } = useLocalizationContext();
          const currency = getUiCurrency(original?.listing?.currency);

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing) return null;

          // We do not display for comp listing
          if (original.isCompListing) return null;

          if (value == null) return <Content id={ContentId.NA} />;

          return (
            <TableCellDiv align="right">
              <div
                className={
                  isListingGroupLeader(original.listing) ? styles.bold : ''
                }
              >
                {applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: value,
                  currencyCode: currency.code,
                  currencyDecimalDigits: currency.dec,
                })}
              </div>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.UnsoldCost,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.SoldCost]: columnHelper.accessor(
      (data) =>
        data?.listing && (data.listing.unitCst || data.listing.faceValue)
          ? (data.listing.unitCst?.amt ?? data.listing.faceValue?.amt ?? 0) *
            (data.listing.ticketCnt - data.listing.availQty)
          : null,
      {
        id: ListingTableColumnId.SoldCost,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.SoldCost
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const value = getValue<number | null>();
          const { getUiCurrency } = useLocalizationContext();
          const currency = getUiCurrency(original?.listing?.currency);

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing) return null;

          // We do not display for comp listing
          if (original.isCompListing) return null;

          if (value == null) {
            return (
              <TableCellDiv align="right">
                <Content id={ContentId.NA} />
              </TableCellDiv>
            );
          }

          return (
            <TableCellDiv align="right">
              <div
                className={
                  isListingGroupLeader(original.listing) ? styles.bold : ''
                }
              >
                {applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: value,
                  currencyCode: currency.code,
                  currencyDecimalDigits: currency.dec,
                })}
              </div>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.SoldCost,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.TotalCost]: columnHelper.accessor(
      (data) =>
        data?.listing && (data.listing.unitCst || data.listing.faceValue)
          ? (data.listing.unitCst?.amt ?? data.listing.faceValue?.amt ?? 0) *
            data.listing.ticketCnt
          : null,
      {
        id: ListingTableColumnId.TotalCost,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.TotalCost
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const value = getValue<number | null>();
          const { getUiCurrency } = useLocalizationContext();
          const currency = getUiCurrency(original?.listing?.currency);

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing) return null;

          // We do not display for comp listing
          if (original.isCompListing) return null;

          if (value == null) return <Content id={ContentId.NA} />;

          return (
            <TableCellDiv align="right">
              <div
                className={
                  isListingGroupLeader(original.listing) ? styles.bold : ''
                }
              >
                {applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: value,
                  currencyCode: currency.code,
                  currencyDecimalDigits: currency.dec,
                })}
              </div>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.TotalCost,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.ListPrice]: columnHelper.accessor(
      (data) => data?.listing?.listPrice,
      {
        id: ListingTableColumnId.ListPrice,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.ListPrice
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_XL,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ row: { original }, table }) => {
          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing) return null;

          // We do not display pricing input for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          const sellerFee = original.listing.mkpListings.find(
            (m) => m.mkp === Marketplace.StubHub
          )?.sellerFee;

          const listings: ListingWithEvent[] = table
            .getSortedRowModel()
            .rows.filter((row) => row?.original != null)
            .map((row) => row.original!);

          const nextEditableListing = getNextEditableListingRow(
            listings,
            original
          );

          return (
            <TableCellDiv align="right" showTooltip={false}>
              <ListingPriceCell
                currencyCode={original.listing.currency}
                sellerFee={sellerFee}
                field="listPrice"
                otherField="allInPrice"
                listing={original.listing}
                viagVirtualId={original.event?.viagVirtualId}
                disabled={!original.listing?.availQty}
                nextEditableListingId={nextEditableListing?.listing?.id}
              />
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.ListPrice,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.AllInPrice]: columnHelper.accessor(
      (data) =>
        data?.isCompListing
          ? data.listing?.allInPrice // Comp-listing only has the all-in price
          : data?.listing?.listPrice, // List price is the absolute value, so we return this one so sorting can work, rendering doesn't depend on this
      {
        id: ListingTableColumnId.AllInPrice,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.AllInPrice
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_XL,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ row: { original }, table }) => {
          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing) return null;

          if (original.isCompListing) {
            const value = original.listing.allInPrice;
            const currencyCode = original.listing.currency;

            return (
              <TableCellDiv align="right">
                <div>
                  {value != null &&
                    currencyCode != null &&
                    applyNumberFormatting({
                      inputNumber: value,
                      currencyCode: currencyCode,
                    })}
                </div>
              </TableCellDiv>
            );
          }

          // We do not display pricing input for listing group or comp listing
          if (original.listing.isLtGrp || !original.listing.isFull) return null;

          const sellerFee = original.listing.mkpListings.find(
            (m) => m.mkp === Marketplace.StubHub
          )?.sellerFee;

          const listings: ListingWithEvent[] = table
            .getSortedRowModel()
            .rows.filter((row) => row?.original != null)
            .map((row) => row.original!);

          const nextEditableListing = getNextEditableListingRow(
            listings,
            original
          );

          return (
            <TableCellDiv align="right" showTooltip={false}>
              <ListingPriceCell
                currencyCode={original.listing.currency}
                sellerFee={sellerFee}
                field="allInPrice"
                otherField="listPrice"
                listing={original.listing}
                viagVirtualId={original.event?.viagVirtualId}
                disabled={!original.listing?.availQty}
                nextEditableListingId={nextEditableListing?.listing?.id}
              />
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.AllInPrice,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.AutoPricing]: columnHelper.accessor(
      (data) => data?.listing?.isAutoPrc,
      {
        id: ListingTableColumnId.AutoPricing,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.AutoPricing
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { editable } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing) return null;

          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <AutoPricingToggleCell
              listing={original.listing}
              event={original.event}
              editable={editable}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.AutoPricing,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.ProceedsFloor]: columnHelper.accessor(
      (data) => data?.listing?.procsFloor,
      {
        id: ListingTableColumnId.ProceedsFloor,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.ProceedsFloor
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({ row: { original }, getValue }) {
          const value = getValue<number>();

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          const sellerFee = original.listing.mkpListings.find(
            (m) => m.mkp === Marketplace.StubHub
          )?.sellerFee;

          return (
            <ListingProceedsFloorCell
              currencyCode={original.listing.currency}
              sellerFee={sellerFee}
              field="netProceedsFloor"
              listing={original.listing}
              viagVirtualId={original.event?.viagVirtualId}
              disabled={!original.listing?.availQty}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.ProceedsFloor,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.ProceedsCeiling]: columnHelper.accessor(
      (data) => data?.listing?.procsCeil,
      {
        id: ListingTableColumnId.ProceedsCeiling,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.ProceedsCeiling
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          getValue,
          column: { id: columnId },
          table,
        }) {
          const value = getValue<number | null>();
          const { getUiCurrency } = useLocalizationContext();
          const currency = getUiCurrency(original?.listing?.currency);

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing || value == null) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <TableCellDiv align="right">
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: value,
                currencyCode: currency.code,
                currencyDecimalDigits: currency.dec,
              })}
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.ProceedsCeiling,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.WebsiteFloor]: columnHelper.accessor(
      (data) => data?.listing?.procsFloor,
      {
        id: ListingTableColumnId.WebsiteFloor,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.WebsiteFloor
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({ row: { original }, getValue }) {
          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          const sellerFee = original.listing.mkpListings.find(
            (m) => m.mkp === Marketplace.StubHub
          )?.sellerFee;

          return (
            <ListingProceedsFloorCell
              currencyCode={original.listing.currency}
              sellerFee={sellerFee}
              field="netProceedsFloor"
              isAllInPrice
              listing={original.listing}
              viagVirtualId={original.event?.viagVirtualId}
              disabled={!original.listing?.availQty}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.WebsiteFloor,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.WebsiteCeiling]: columnHelper.accessor(
      (data) => data?.listing?.procsCeil,
      {
        id: ListingTableColumnId.WebsiteCeiling,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.WebsiteCeiling
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          getValue,
          column: { id: columnId },
          table,
        }) {
          const allInCeilingPrice = useAllInPriceFromListPrice(
            getValue<number | null | undefined>(),
            original?.listing
          );
          const { getUiCurrency } = useLocalizationContext();
          const currency = getUiCurrency(original?.listing?.currency);

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing || allInCeilingPrice == null) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <TableCellDiv align="right">
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: allInCeilingPrice,
                currencyCode: currency.code,
                currencyDecimalDigits: currency.dec,
              })}
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.WebsiteCeiling,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.UndercutMode]: columnHelper.accessor(
      (data) => data?.listing?.undMode,
      {
        id: ListingTableColumnId.UndercutMode,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.UndercutMode
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          getValue,
          table,
        }) {
          const { editable, userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );
          const undercutMode = getValue<AutoPricingUndercutMode | undefined>();

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing) return null;

          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          const UndercutModeIcon = undercutMode
            ? getUndercutModeIcon(undercutMode)
            : null;
          return (
            <PricingModeCell
              pricingMode={undercutMode}
              DisplayIcon={
                userDefinedPrecision === FormatOption.EnumDisplay_Icon
                  ? UndercutModeIcon
                  : undefined
              }
              listing={original.listing}
              event={original.event}
              field={'undercutMode'}
              editable={editable}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.UndercutMode,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.UndercutAbsoluteAmount]: columnHelper.accessor(
      (data) => data?.listing?.undAbsAmt,
      {
        id: ListingTableColumnId.UndercutAbsoluteAmount,
        header: function Cell() {
          const { getUiCurrency } = useLocalizationContext();
          const { loginContext } = useAppContext();
          const titleText = useContent(ContentId.UndercutAbsoluteAmount);

          const currencyCode = loginContext?.user?.activeAccount.currencyCode;
          const { sym } = getUiCurrency(currencyCode);

          return (
            <TableHeaderCellDiv align="right" title={titleText}>
              <Content id={ContentId.UndercutAmount} />
              {` (${sym})`}
            </TableHeaderCellDiv>
          );
        },
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          getValue,
          table,
        }) {
          const value = getValue<number | null>();
          const { getUiCurrency } = useLocalizationContext();
          const currency = getUiCurrency(original?.listing?.currency);

          const { editable, userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (
            !original.listing ||
            !original.listing?.isAutoPrc ||
            original.listing?.undMode == null
          )
            return null;

          const isPremium = value != null && value < 0;
          const formattedNumber =
            value != null
              ? applyNumberFormatting({
                  inputNumber: Math.abs(value),
                  precision: userDefinedPrecision,
                  currencyCode: currency.code,
                  currencyDecimalDigits: currency.dec,
                })
              : null;

          return (
            <UndercutAmountCell
              formattedNumber={formattedNumber as string | null | undefined}
              isPremium={isPremium}
              listing={original.listing}
              viagVirtualId={original.event?.viagVirtualId}
              field={'undercutAbsoluteAmount'}
              editable={editable}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.UndercutAbsoluteAmount,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.UndercutRelativeAmount]: columnHelper.accessor(
      (data) => data?.listing?.undRelAmt,
      {
        id: ListingTableColumnId.UndercutRelativeAmount,
        header: function Cell() {
          const titleText = useContent(ContentId.UndercutRelativeAmount);

          return (
            <TableHeaderCellDiv align="right" title={titleText}>
              <Content id={ContentId.UndercutAmount} />
              {` (%)`}
            </TableHeaderCellDiv>
          );
        },
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          getValue,
          table,
        }) {
          const value = getValue<number | null>();

          const { editable, userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (
            !original.listing ||
            !original.listing?.isAutoPrc ||
            original.listing?.undMode == null
          )
            return null;

          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          const isPremium = value != null && value < 0;
          const formattedNumber =
            value != null
              ? applyNumberFormatting({
                  inputNumber: Math.abs(value),
                  precision: userDefinedPrecision,
                  isPercent: true,
                })
              : null;

          return (
            <UndercutAmountCell
              formattedNumber={formattedNumber as string | null | undefined}
              isPremium={isPremium}
              listing={original.listing}
              viagVirtualId={original.event?.viagVirtualId}
              field={'undercutRelativeAmount'}
              editable={editable}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.UndercutRelativeAmount,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.UndercutNewPrice]: columnHelper.accessor(
      (data) => data?.listing?.undNewPrice,
      {
        id: ListingTableColumnId.UndercutNewPrice,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.UndercutNewPrice
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          getValue,
          column: { id: columnId },
          table,
        }) {
          const allInMarketPrice = useAllInPriceFromListPrice(
            getValue<number | null | undefined>(),
            original?.listing
          );
          const { getUiCurrency } = useLocalizationContext();
          const currency = getUiCurrency(original?.listing?.currency);

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing || allInMarketPrice == null) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <TableCellDiv align="right">
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: allInMarketPrice,
                currencyCode: currency.code,
                currencyDecimalDigits: currency.dec,
              })}
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.UndercutNewPrice,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.CompListingMode]: columnHelper.accessor(
      (data) => data?.listing?.compMode,
      {
        id: ListingTableColumnId.CompListingMode,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.CompMode} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          getValue,
          table,
        }) {
          const { editable, userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );
          const compListingMode = getValue<
            AutoPricingCompListingMode | undefined
          >();

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing) return null;

          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          const CompListingModeIcon = compListingMode
            ? getCompListingModeIcon(compListingMode)
            : null;

          return (
            <PricingModeCell
              pricingMode={compListingMode}
              DisplayIcon={
                userDefinedPrecision === FormatOption.EnumDisplay_Icon
                  ? CompListingModeIcon
                  : undefined
              }
              listing={original.listing}
              event={original.event}
              field={'compListingMode'}
              editable={editable}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.CompListingMode,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.CompListingFloor]: columnHelper.accessor(
      (data) => data?.listing?.compFloor,
      {
        id: ListingTableColumnId.CompListingFloor,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.CompFloor} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          getValue,
          table,
        }) {
          const value = getValue<number | null>();

          const { editable, userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;

          // We do not display comp listing floor if is listing group or comp listing modes not supporting it
          if (
            original.listing.isLtGrp ||
            original.isCompListing ||
            !original.listing.isFull ||
            (original.listing.compMode !==
              AutoPricingCompListingMode.QualityScore &&
              original.listing.compMode !==
                AutoPricingCompListingMode.SameSection)
          )
            return null;

          const formattedNumber = applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          });

          return (
            <CompListingBoundsCell
              formattedNumber={formattedNumber as string | null | undefined}
              listing={original.listing}
              field="compListingFloor"
              viagVirtualId={original.event?.viagVirtualId}
              editable={editable}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.CompListingFloor,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.CompListingCeiling]: columnHelper.accessor(
      (data) => data?.listing?.compCeil,
      {
        id: ListingTableColumnId.CompListingCeiling,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.CompCeiling} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          getValue,
          table,
        }) {
          const value = getValue<number | null>();

          const { editable, userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;

          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            original.isCompListing ||
            !original.listing.isFull ||
            (original.listing.compMode !==
              AutoPricingCompListingMode.QualityScore &&
              original.listing.compMode !==
                AutoPricingCompListingMode.SameSection)
          )
            return null;

          const formattedNumber = applyNumberFormatting({
            precision: userDefinedPrecision,
            inputNumber: value,
          });

          return (
            <CompListingBoundsCell
              formattedNumber={formattedNumber as string | null | undefined}
              listing={original.listing}
              field="compListingCeiling"
              viagVirtualId={original.event?.viagVirtualId}
              editable={editable}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.CompListingCeiling,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.CompListingMinimumQuantity]: columnHelper.accessor(
      (data) => data?.listing?.compQtyScrAdjJson ?? '',
      {
        id: ListingTableColumnId.CompListingMinimumQuantity,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.CompListingMinimumQuantity
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { editable } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <CompListingMinQuantityCell
              listing={original.listing}
              editable={editable}
              event={original.event}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.CompListingMinimumQuantity,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.CompListingExcludeFanInventory]:
      columnHelper.accessor((data) => data?.listing?.compExclFanInv ?? false, {
        id: ListingTableColumnId.CompListingExcludeFanInventory,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.CompListingExcludeFanInventory
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { editable } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <CompListingExcludeFanInventoryCell
              listing={original.listing}
              editable={editable}
              event={original.event}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.CompListingExcludeFanInventory,
          canSortGroup
        ),
      }),
    [ListingTableColumnId.OutlierMode]: columnHelper.accessor(
      (data) => data?.listing?.outMode ?? '',
      {
        id: ListingTableColumnId.OutlierMode,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.OutlierMode
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { editable } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <OutlierModeCell
              listing={original.listing}
              editable={editable}
              event={original.event}
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.OutlierMode,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.OutlierFiltering]: columnHelper.accessor(
      (data) => data?.listing?.outlStdDev ?? '',
      {
        id: ListingTableColumnId.OutlierFiltering,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.OutlierFiltering
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.OutlierFiltering,
          canSortGroup
        ),
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { editable } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <OutlierFilteringCell
              listing={original.listing}
              editable={editable}
              event={original.event}
            />
          );
        },
      }
    ),
    [ListingTableColumnId.OutlierLimit]: columnHelper.accessor(
      (data) => data?.listing?.outKthLowestLim ?? '',
      {
        id: ListingTableColumnId.OutlierLimit,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.OutlierLimit
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.OutlierLimit,
          canSortGroup
        ),
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { editable } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <OutlierLimitCell
              listing={original.listing}
              editable={editable}
              event={original.event}
            />
          );
        },
      }
    ),
    [ListingTableColumnId.OutlierSpacing]: columnHelper.accessor(
      (data) => data?.listing?.outKthLowestLimAbsSpc ?? '',
      {
        id: ListingTableColumnId.OutlierSpacing,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.OutlierSpacing
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_XL,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.OutlierSpacing,
          canSortGroup
        ),
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { editable } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <OutlierSpacingCell
              listing={original.listing}
              editable={editable}
              event={original.event}
            />
          );
        },
      }
    ),
    [ListingTableColumnId.CircuitBreakerMinCompListingCount]:
      columnHelper.accessor((data) => data?.listing?.cirBrMinCompCount ?? '', {
        id: ListingTableColumnId.CircuitBreakerMinCompListingCount,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.CircuitBreakerMinCompListingCount
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.CircuitBreakerMinCompListingCount,
          canSortGroup
        ),
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { editable } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <CircuitBreakerMinCompListingCountCell
              listing={original.listing}
              editable={editable}
              event={original.event}
            />
          );
        },
      }),
    [ListingTableColumnId.CircuitBreakerVelocity]: columnHelper.accessor(
      (data) => data?.listing?.cirBrMinCompCount ?? '',
      {
        id: ListingTableColumnId.CircuitBreakerVelocity,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.CircuitBreakerVelocity
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.CircuitBreakerVelocity,
          canSortGroup
        ),
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { editable } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <CircuitBreakerVelocityCell
              listing={original.listing}
              editable={editable}
              event={original.event}
            />
          );
        },
      }
    ),
    [ListingTableColumnId.CircuitBreakerVelocityHours]: columnHelper.accessor(
      (data) => data?.listing?.cirBrMinCompCount ?? '',
      {
        id: ListingTableColumnId.CircuitBreakerVelocityHours,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.CircuitBreakerVelocityHours
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.CircuitBreakerVelocityHours,
          canSortGroup
        ),
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { editable } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing for listing group or comp listing
          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            original.isCompListing
          )
            return null;

          return (
            <CircuitBreakerVelocityHoursCell
              listing={original.listing}
              editable={editable}
              event={original.event}
            />
          );
        },
      }
    ),
    [ListingTableColumnId.SeatingRow]: columnHelper.accessor(
      (data) => data?.listing?.seating?.row ?? '',
      {
        id: ListingTableColumnId.SeatingRow,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.SeatingRow
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.SeatingRow,
          canSortGroup
        ),
        cell: ({ row: { original }, getValue }) => {
          const value = getValue<string>();

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display seating info for listing group
          if (original.listing.isLtGrp) return null;

          return <TableCellDiv align="right">{value}</TableCellDiv>;
        },
      }
    ),
    [ListingTableColumnId.SeatingSeats]: columnHelper.accessor(
      (data) => data?.listing?.seating.seatFr ?? '',
      {
        id: ListingTableColumnId.SeatingSeats,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.SeatingSeats
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.SeatingSeats,
          canSortGroup
        ),
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          table,
        }) {
          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );
          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display seating info for listing group
          if (original.listing.isLtGrp) return null;

          const { orgSeatFr, orgSeatTo, seating, availQty } =
            original?.listing ?? {};
          const { seatFr, seatTo } = seating ?? {};

          return (
            <TableCellDiv align="right">
              <SeatingInfo
                section={''}
                row={null}
                seatFr={seatFr}
                seatTo={seatTo}
                orgSeatFr={orgSeatFr}
                orgSeatTo={orgSeatTo}
                showOrgSeat={
                  userDefinedPrecision === FormatOption.Seating_OriginalSeating
                }
                showSeatPrefix={false}
                isSoldOut={availQty === 0}
                justifyIconContent="spaceBetween"
              />
            </TableCellDiv>
          );
        },
      }
    ),
    [ListingTableColumnId.AutoPricingResultType]: columnHelper.accessor(
      (data) => data?.listing?.autoPrcResult,
      {
        id: ListingTableColumnId.AutoPricingResultType,
        header: () => (
          <TableHeaderCellDiv align="center">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.AutoPricingResultType
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.AutoPricingResultType,
          canSortGroup
        ),
        cell: ({ row: { original }, getValue }) => {
          const value = getValue<AutoPricingResultType | null | undefined>();

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing result type info for listing group or comp listing
          if (original.listing.isLtGrp || original.isCompListing) return null;

          return (
            <TableCellDiv align="center" showTooltip={false}>
              <AutoPriceResultDisplay resultType={value} />
            </TableCellDiv>
          );
        },
      }
    ),
    [ListingTableColumnId.LastPriceUpdatedDate]: columnHelper.accessor(
      (data) => data?.listing?.lastPrcUpdOn ?? null,
      {
        id: ListingTableColumnId.LastPriceUpdatedDate,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.LastPriceUpdatedDate
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper(
          sortGeneric,
          ListingTableColumnId.LastPriceUpdatedDate,
          canSortGroup
        ),
        cell: function Cell({
          row: { original },
          getValue,
          column: { id: columnId },
          table,
        }) {
          const value = getValue<string>();
          const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
            useColumnUserSetting(
              columnId,
              table.options.meta?.sectionType ?? SECTION_TYPE
            );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display last price update date info for listing group or comp listing
          if (original.listing.isLtGrp || original.isCompListing) return null;

          return (
            <TableCellDiv align="right">
              <DateCell
                precision={userDefinedPrecision}
                date={value}
                enableUtcFallback
              />
            </TableCellDiv>
          );
        },
      }
    ),
    [ListingTableColumnId.PurchaseCreatedBy]: columnHelper.accessor(
      (data) => [data?.listing?.insertBy],
      {
        id: ListingTableColumnId.PurchaseCreatedBy,
        header: () => (
          <TableHeaderCellDiv align="left">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.PurchaseCreatedBy
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({ getValue, row: { original } }) {
          const { allActiveUserInfos } = useSellerAccountContext();

          const sellerUserIds = getValue<string[] | null>();

          const displayNamesFormatted = useMemo(
            () =>
              sellerUserIds
                ? [...new Set(sellerUserIds)]
                    .map(
                      (sellerUserId) =>
                        allActiveUserInfos?.[sellerUserId]?.displayName
                    )
                    .filter((name) => name != null)
                    .join(', ')
                : null,
            [allActiveUserInfos, sellerUserIds]
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          if (original.listing.isLtGrp || original.isCompListing) return null;

          return (
            <TableCellDiv align="left">{displayNamesFormatted}</TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.PurchaseCreatedBy,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.PurchasedBy]: columnHelper.accessor(
      (data) => data?.listing?.poBys,
      {
        id: ListingTableColumnId.PurchasedBy,
        header: () => (
          <TableHeaderCellDiv align="left">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.PurchasedBy
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({ getValue, row: { original } }) {
          const { allActiveUserInfos } = useSellerAccountContext();

          const sellerUserIds = getValue<string[] | null>();

          const displayNamesFormatted = useMemo(
            () =>
              sellerUserIds
                ? [...new Set(sellerUserIds)]
                    .map(
                      (sellerUserId) =>
                        allActiveUserInfos?.[sellerUserId]?.displayName
                    )
                    .filter((name) => name != null)
                    .join(', ')
                : null,
            [allActiveUserInfos, sellerUserIds]
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          if (original.listing.isLtGrp || original.isCompListing) return null;

          return (
            <TableCellDiv align="left">{displayNamesFormatted}</TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.PurchasedBy,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.PurchaseDate]: columnHelper.accessor(
      (data) => {
        return data?.listing?.poDate ?? null;
      },
      {
        id: ListingTableColumnId.PurchaseDate,
        header: () => (
          <TableHeaderCellDiv align="left">
            <Content
              id={
                LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[
                  ListingTableColumnId.PurchaseDate
                ]
              }
            />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({
          getValue,
          column: { id: columnId },
          row: { original },
          table,
        }) {
          const purchaseDates = getValue<string[] | null>();
          const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
            useColumnUserSetting(
              columnId,
              table.options.meta?.sectionType ?? SECTION_TYPE
            );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          if (original.listing.isLtGrp) return null;

          return (
            <DateCell
              precision={userDefinedPrecision}
              date={purchaseDates}
              enableUtcFallback
              hideRelativeTerms
              align="left"
            />
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.PurchaseDate,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.WastedCost]: columnHelper.accessor(
      (data) => {
        if (!data || !data.listing) return null;
        const { start: eventStart, end: eventEnd } = data.event.dates ?? {};
        if (!eventStart && !eventEnd) return null;
        const eventEndTime = eventEnd
          ? new Date(eventEnd)
          : add(new Date(eventStart!), { hours: 4 });
        if (eventEndTime >= new Date()) return null;
        return (
          data.listing.availQty *
          (data.listing.unitCst?.amt || data.listing.faceValue?.amt || 0)
        );
      },
      {
        id: ListingTableColumnId.WastedCost,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.WastedCost} />
          </TableHeaderCellDiv>
        ),
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          getValue,
          table,
        }) {
          const { getUiCurrency } = useLocalizationContext();
          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing result type info for listing group or comp listing
          if (original.listing.isLtGrp || original.isCompListing) return null;

          const value = getValue<number | null>();
          const currency = getUiCurrency(original.listing.currency);

          return (
            <TableCellDiv align="right">
              {typeof value === 'number' ? (
                applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: value,
                  currencyCode: currency.code,
                  currencyDecimalDigits: currency.dec,
                })
              ) : (
                <Content id={ContentId.NA} />
              )}
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.WastedCost,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.WastedTickets]: columnHelper.accessor(
      (data) => {
        if (!data || !data.listing) return null;
        const { start: eventStart, end: eventEnd } = data.event.dates ?? {};
        if (!eventStart && !eventEnd) return null;
        const eventEndTime = eventEnd
          ? new Date(eventEnd)
          : add(new Date(eventStart!), { hours: 4 });
        if (eventEndTime >= new Date()) return null;
        return data.listing.availQty;
      },
      {
        id: ListingTableColumnId.WastedTickets,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.WastedQty} />
          </TableHeaderCellDiv>
        ),
        cell: function Cell({
          row: { original },
          column: { id: columnId },
          getValue,
          table,
        }) {
          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }
          if (!original.listing) return null;
          // We do not display pricing result type info for listing group or comp listing
          if (original.listing.isLtGrp || original.isCompListing) return null;

          const value = getValue<number | null>();

          return (
            <TableCellDiv align="right">
              {typeof value === 'number' ? (
                applyNumberFormatting({
                  precision: userDefinedPrecision,
                  inputNumber: value,
                })
              ) : (
                <Content id={ContentId.NA} />
              )}
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.WastedTickets,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.Actions]: columnHelper.accessor(
      // We don't actually use this, but this column is to be sorted by broad-casting state and quantity
      // And we need accessorFn to get the sorting activated
      (data) => data?.listing?.availQty,
      {
        id: ListingTableColumnId.Actions,
        enableSorting: false,
        enableResizing: true,
        header: function Cell() {
          const hasTablePinActionColumnFeature = useUserHasFeature(
            Feature.TablePinActionColumn
          );

          if (hasTablePinActionColumnFeature) {
            return null;
          }

          return (
            <TableHeaderCellDiv align="right">
              <Content id={ContentId.Actions} />
            </TableHeaderCellDiv>
          );
        },
        size: COLUMN_DEFAULT_SIZE_XL,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        sortingFn: groupSortingWrapper((r1, r2) => {
          if (r1.original?.listing?.isLtGrp) {
            if (!r2.original?.listing?.isLtGrp) {
              // listing groups sort first (sort r1 before r2)
              // unless this is a sub-level which then listing-group sort last
              return r2.original?.listing?.ltGrpId ===
                r1.original?.listing?.ltGrpId
                ? 1
                : -1;
            }
          }

          if (r2.original?.listing?.isLtGrp) {
            // listing groups sort first (sort r1 after r2)
            // unless this is a sub-level which then listing-group sort last
            return r2.original?.listing?.ltGrpId ===
              r1.original?.listing?.ltGrpId
              ? -1
              : 1;
          }

          const a = r1.original?.listing;
          const b = r2.original?.listing;
          const aQuantity = a?.availQty ?? 0;
          const bQuantity = b?.availQty ?? 0;
          if (aQuantity <= 0 && bQuantity >= 0) {
            return 1;
          } else if (aQuantity >= 0 && bQuantity <= 0) {
            return -1;
          }
          return (a?.ltGrpPrior ?? -1) - (b?.ltGrpPrior ?? -1);
        }),
        cell: ({
          row: { parentId, original, depth, getParentRows },
          table,
        }) => {
          if (!original || (!original.listing && original?.isLtGrp)) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (!original.listing || original.isCompListing) return null;

          const parentRows =
            depth > 0 && parentId && table.getRowModel().rowsById[parentId]
              ? getParentRows()
              : undefined;

          if (depth === 0 && original.listing.ltGrpId === FAKE_SOLD_GROUP_ID) {
            return null;
          }

          const linkOnly =
            table.options.meta?.formatOptionOverrides?.[
              ListingTableColumnId.Actions
            ] === FormatOption.Actions_LinkOnly;

          return (
            <ListingActionsCell
              linkOnly={linkOnly}
              event={original.event}
              listing={original.listing}
              isSubRow={depth > 0}
              disabled={parentRows?.some(
                (pr) =>
                  (pr.original?.listing as ListingGroup)?.isLockedFromEdits
              )}
            />
          );
        },
      }
    ),
    [ListingTableColumnId.PurchaseId]: columnHelper.accessor(
      (data) => data?.listing?.poId,
      {
        id: ListingTableColumnId.PurchaseId,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.ExternalPurchaseIdAbbreviated} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: function Cell({ getValue, row: { original } }) {
          const onViewClick = useCallback(async () => {
            if (!original || !original.listing?.poId) {
              return;
            }
          }, [original]);

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          // We do not display for comp listing
          if (original.isCompListing || !original.listing.poOrderId)
            return null;

          return (
            <TableCellDiv align="left" title={original.listing.poOrderId}>
              <Stack
                gap="s"
                alignItems="center"
                justifyContent="end"
                width="full"
                height="full"
                style={{ wordBreak: 'break-all' }}
              >
                <CopyToClipboardV2 textToCopy={original.listing.poOrderId}>
                  <Link to={getPurchaseOrderRelativeUrl(getValue() as number)}>
                    <Button
                      variant="link"
                      size="unset"
                      shape="inline"
                      style={{ fontSize: vars.typography.fontSize['sm'] }}
                      onClick={onViewClick}
                    >
                      {original.listing.poOrderId}
                    </Button>
                  </Link>
                </CopyToClipboardV2>
              </Stack>
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.PurchaseId,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.VendorAccount]: columnHelper.accessor(
      (data) => data?.listing?.poVendAccEmail ?? '',
      {
        id: ListingTableColumnId.VendorAccount,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.VendorAccount} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,

        cell: function Cell({ getValue, row: { original } }) {
          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          // We do not display for comp listing
          if (original.isCompListing) return null;

          return (
            <TableCellDiv align="left">{getValue() as string}</TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.VendorAccount,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.AutoFulfill]: columnHelper.accessor(
      (data) => data?.listing?.isNoFulfill ?? '',
      {
        id: ListingTableColumnId.AutoFulfill,
        header: () => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.AutoFulfill} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,

        cell: function Cell({ getValue, row: { original } }) {
          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          // We do not display for comp listing or listing-group
          if (original.isLtGrp || original.isCompListing) return null;

          return (
            <TableCellDiv align="left">
              {/* AutoFulfill is negative of IsNoFulfill */}
              <Content id={getValue() ? ContentId.No : ContentId.Yes} />
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.AutoFulfill,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.MarketplaceVisibility]: columnHelper.accessor(
      (data) => data?.listing?.mkpVis ?? '0',
      {
        id: ListingTableColumnId.MarketplaceVisibility,
        header: function Cell() {
          const titleText = useContent(ContentId.MarketplaceVisibility);

          return (
            <TableHeaderCellDiv align="right" title={titleText}>
              <Content id={ContentId.MarketplaceVisibility} />
              {` (%)`}
            </TableHeaderCellDiv>
          );
        },
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,

        cell: function Cell({
          row: { original },
          column: { id: columnId },
          getValue,
          table,
        }) {
          const value = getValue<number | null>();

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          // We do not display for comp listing
          if (original.isCompListing) return null;

          const formattedNumber =
            value != null
              ? applyNumberFormatting({
                  inputNumber: Math.abs(value / 100),
                  precision: userDefinedPrecision,
                  isPercent: true,
                })
              : null;

          return <TableCellDiv align="right">{formattedNumber}</TableCellDiv>;
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.MarketplaceVisibility,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.AbsoluteRankPremium]: columnHelper.accessor(
      (data) => data?.listing?.ltGrpUndercut,
      {
        id: ListingTableColumnId.AbsoluteRankPremium,
        header: () => (
          <TableHeaderCellDiv align="right">
            <Content id={ContentId.AbsoluteRankPremium} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,

        cell: function Cell({
          row: { original },
          getValue,
          column: { id: columnId },
          table,
        }) {
          const value = getValue<
            ListingGroupUndercutSetting | null | undefined
          >();

          const { getUiCurrency } = useLocalizationContext();
          const currency = getUiCurrency(original?.listing?.currency);

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          const absoluteRankPremium = value?.actRankUndAbsAmt ?? undefined;

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (
            !original.listing ||
            absoluteRankPremium === undefined ||
            original.listing.isLtGrp ||
            !original.listing.isFull
          )
            return null;

          return (
            <TableCellDiv align="right">
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: absoluteRankPremium,
                currencyCode: currency.code,
                currencyDecimalDigits: currency.dec,
              })}
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.AbsoluteRankPremium,
          canSortGroup
        ),
      }
    ),
    [ListingTableColumnId.RelativeRankPremium]: columnHelper.accessor(
      (data) => data?.listing?.ltGrpUndercut,
      {
        id: ListingTableColumnId.RelativeRankPremium,
        header: function Cell() {
          const titleText = useContent(ContentId.UndercutRelativeAmount);

          return (
            <TableHeaderCellDiv align="right" title={titleText}>
              <Content id={ContentId.RelativeRankPremium} />
              {` (%)`}
            </TableHeaderCellDiv>
          );
        },
        size: COLUMN_DEFAULT_SIZE_LG,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,

        cell: function Cell({
          row: { original },
          getValue,
          column: { id: columnId },
          table,
        }) {
          const value = getValue<
            ListingGroupUndercutSetting | null | undefined
          >();

          const { userDefinedPrecision } = useColumnUserSetting(
            columnId,
            table.options.meta?.sectionType ?? SECTION_TYPE
          );

          const relativeRankPremium = value?.actRankUndRelAmt ?? undefined;

          if (!original || !original.listing) {
            return (
              <TableShimmeringDiv
                height={
                  original?.isLtGrp
                    ? SHIMMERING_DIV_HEIGHT_LISTING_GROUP
                    : SHIMMERING_DIV_HEIGHT_SINGLE_LISTING
                }
              />
            );
          }

          if (
            original.listing.isLtGrp ||
            !original.listing.isFull ||
            relativeRankPremium === undefined ||
            !original.listing
          ) {
            return null;
          }

          return (
            <TableCellDiv align="right">
              {applyNumberFormatting({
                inputNumber: relativeRankPremium,
                precision: userDefinedPrecision,
                isPercent: true,
              })}
            </TableCellDiv>
          );
        },
        sortingFn: groupSortingWrapper(
          sortingFns.basic,
          ListingTableColumnId.RelativeRankPremium,
          canSortGroup
        ),
      }
    ),
  } as const;
};
