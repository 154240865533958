import { isEqual } from 'lodash-es';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useGetSet } from 'react-use';
import { SeatScoreConfigCard } from 'src/components/Events/SeatScoreConfigCard';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { Switch } from 'src/core/interim/Switch';
import { BezierGraph, SerializedSegment } from 'src/core/POS/BezierGraph';
import { CalcOutputFn } from 'src/core/POS/BezierGraph/BezierGraphManager';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { downloadFileFromBlob } from 'src/utils/fileUtils';
import {
  calcSeatScore,
  getCompleteEventConfigScoreOverrides,
  updateScoreMaintainingRowScoreRatio,
} from 'src/utils/seatScoreUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  EventConfigScoreOverride,
  Feature,
  PricingClient,
} from 'src/WebApiController';

import { EventVenueHeatMap } from '../common/EventSeatMap';
import { ModalProps } from '../Modal';
import { UploadSeatScoreModal } from '../UploadSeatScoreModal';
import * as styles from './EventSeatMapConfig.css';

const INITIAL_ANGLE_SEGMENTS: SerializedSegment[] = [
  { point: { x: 0, y: 100 - 95 } },
  {
    point: { x: 25, y: 100 - 25 },
    handleIn: { x: -20, y: -20 },
    handleOut: { x: 20, y: 20 },
  },
  { point: { x: 100, y: 100 - 5 } },
];
const INITIAL_DISTANCE_SEGMENTS: SerializedSegment[] = [
  { point: { x: 0, y: 100 - 95 } },
  {
    point: { x: 25, y: 100 - 25 },
    handleIn: { x: -20, y: -20 },
    handleOut: { x: 20, y: 20 },
  },
  { point: { x: 100, y: 100 - 5 } },
];
const INITIAL_HEIGHT_SEGMENTS: SerializedSegment[] = [
  { point: { x: 0, y: 100 - 95 } },
  {
    point: { x: 25, y: 100 - 25 },
    handleIn: { x: -20, y: -20 },
    handleOut: { x: 20, y: 20 },
  },
  { point: { x: 100, y: 100 - 5 } },
];

export type GraphSegments = {
  angleImportance?: number;
  angleSegments?: SerializedSegment[];
  distanceImportance?: number;
  distanceSegments?: SerializedSegment[];
  heightImportance?: number;
  heightSegments?: SerializedSegment[];
  scoreSegmentsBySection?: Record<string, SerializedSegment[] | undefined>;
  activeRowIdsBySection?: Record<string, number[]>;
  baseRowIdBySection?: Record<string, number | undefined>;
};

export const EventSeatMapConfigBody = ({
  disabled,
  cancelTo,
  isOverrideTemplate = false,
}: {
  disabled: boolean;
  cancelTo?: ModalProps;
  isOverrideTemplate?: boolean;
}) => {
  const { getValues, watch, setValue, register, formState, reset } =
    useFormContext<EventConfigScoreOverride>();

  const { setModal } = useContext(ModalContext);

  const eventConfigScoreOverrideId = getValues('id');
  const configId = getValues('cfgId');
  const scoreOverrides = watch('scoreOverrides');
  const configPayload = watch('cfgPayload');
  const name = watch('name');
  const isTemplate = watch('isTemplate');

  const nearText = useContent(ContentId.Near);
  const farText = useContent(ContentId.Far);
  const lowText = useContent(ContentId.Low);
  const highText = useContent(ContentId.High);

  const hasScaledSeatScoreFeature = useUserHasFeature(Feature.ScaledSeatScore);

  const [isAdvancedMode, setIsAdvancedMode] = useState(false);

  const {
    event,
    venueMapInfo,
    mapConfigOverridesQuery,
    normalizeConfigOverrideWithUiScore,
  } = useEventMapContext();

  const [getAngleImportance, setAngleImportance] = useGetSet(10);
  const [getDistanceImportance, setDistanceImportance] = useGetSet(8);
  const [getHeightImportance, setHeightImportance] = useGetSet(4);
  const calcAngleOutputRef = useRef<CalcOutputFn>();
  const calcDistanceOutputRef = useRef<CalcOutputFn>();
  const calcHeightOutputRef = useRef<CalcOutputFn>();

  const exportAnglePathRef = useRef<() => SerializedSegment[]>();
  const exportDistancePathRef = useRef<() => SerializedSegment[]>();
  const exportHeightPathRef = useRef<() => SerializedSegment[]>();

  const venueMapMaxScoreRaw = useMemo(
    () =>
      venueMapInfo?.sectionScores.reduce(
        (acc, section) => Math.max(acc, section.scoreRaw ?? 0),
        0
      ) ?? 0,
    [venueMapInfo]
  );

  const graphVariables = useMemo(() => {
    const hasAngle =
      (venueMapInfo?.sections ?? []).findIndex((s) => s.angle != null) >= 0;
    const maxAngle = !hasAngle
      ? 0
      : Math.max(...(venueMapInfo?.sections?.map((s) => s.angle ?? 0) ?? []));

    const hasDistance =
      (venueMapInfo?.sections ?? []).findIndex((s) => s.dist != null) >= 0;
    const maxDistance = !hasDistance
      ? 0
      : Math.max(...(venueMapInfo?.sections?.map((s) => s.dist ?? 0) ?? []));

    const hasHeight =
      (venueMapInfo?.sections ?? []).findIndex((s) => s.height != null) >= 0;
    const maxHeight = !hasHeight
      ? 0
      : Math.max(...(venueMapInfo?.sections?.map((s) => s.height ?? 0) ?? []));

    return {
      hasAngle,
      maxAngle,
      hasDistance,
      maxDistance,
      hasHeight,
      maxHeight,
    };
  }, [venueMapInfo?.sections]);

  const graphSegments = useMemo(() => {
    if (configPayload) {
      try {
        const obj = JSON.parse(configPayload) as GraphSegments;

        if (
          obj.angleImportance != null &&
          obj.angleImportance !== getAngleImportance()
        )
          setAngleImportance(obj.angleImportance);
        if (
          obj.distanceImportance != null &&
          obj.distanceImportance !== getDistanceImportance()
        )
          setDistanceImportance(obj.distanceImportance);
        if (
          obj.heightImportance != null &&
          obj.heightImportance !== getHeightImportance()
        )
          setHeightImportance(obj.heightImportance);

        return obj;
      } catch (_error) {
        console.warn(`Unable to parse as GraphSegments: ${configPayload}`);

        return {};
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configPayload]);

  const templateOverrideScores = useMemo(
    () =>
      getCompleteEventConfigScoreOverrides(
        venueMapInfo?.sectionScores,
        scoreOverrides,
        isOverrideTemplate,
        hasScaledSeatScoreFeature
      ),
    [
      hasScaledSeatScoreFeature,
      isOverrideTemplate,
      scoreOverrides,
      venueMapInfo?.sectionScores,
    ]
  );

  const onCalculateSeatScores = useCallback(() => {
    const calcAngleOutput = calcAngleOutputRef.current;
    const calcDistanceOutput = calcDistanceOutputRef.current;
    const calcHeightOutput = calcHeightOutputRef.current;
    // functions should all be defined here
    if (
      venueMapInfo?.sections &&
      (!graphVariables.hasAngle || calcAngleOutput) &&
      (!graphVariables.hasDistance || calcDistanceOutput) &&
      (!graphVariables.hasHeight || calcHeightOutput)
    ) {
      const sectionWithScoreOverrides = venueMapInfo.sections.flatMap(
        (section) => {
          // Treat nulls as zero
          const angle = section?.angle ?? 0;
          const distance = section?.dist ?? 0;
          const height = section?.height ?? 0;

          let seatScore =
            angle != null || distance != null || height != null
              ? calcSeatScore([
                  {
                    weight: getAngleImportance(),
                    value:
                      angle != null && calcAngleOutput
                        ? calcAngleOutput(
                            (angle / (graphVariables.maxAngle || 1)) * 100
                          )
                        : null,
                  },
                  {
                    weight: getDistanceImportance(),
                    value:
                      distance != null && calcDistanceOutput
                        ? calcDistanceOutput(
                            (distance / (graphVariables.maxDistance || 1)) * 100
                          )
                        : null,
                  },
                  {
                    weight: getHeightImportance(),
                    value:
                      height != null && calcHeightOutput
                        ? calcHeightOutput(
                            (height / (graphVariables.maxHeight || 1)) * 100
                          )
                        : null,
                  },
                ])
              : null;

          if (
            hasScaledSeatScoreFeature &&
            seatScore != null &&
            venueMapMaxScoreRaw
          ) {
            // Scale back from 0-100 to 0-venueMapMaxScoreRaw
            seatScore = (seatScore / venueMapMaxScoreRaw) * 100;
          }

          const overridesToEdit = scoreOverrides?.filter(
            (so) => so.sectionId === section.id
          );

          if (!isOverrideTemplate) {
            updateScoreMaintainingRowScoreRatio(
              seatScore ?? 0,
              overridesToEdit
            );
          }

          return overridesToEdit;
        }
      );

      const configObj = configPayload
        ? (JSON.parse(configPayload) as GraphSegments)
        : {};
      configObj.angleImportance = getAngleImportance();
      configObj.angleSegments = exportAnglePathRef.current?.();
      configObj.distanceImportance = getDistanceImportance();
      configObj.distanceSegments = exportDistancePathRef.current?.();
      configObj.heightImportance = getHeightImportance();
      configObj.heightSegments = exportHeightPathRef.current?.();

      if (!isEqual(sectionWithScoreOverrides, scoreOverrides)) {
        setValue('scoreOverrides', sectionWithScoreOverrides);
      }

      const newConfigPayload = JSON.stringify(configObj);
      if (newConfigPayload !== configPayload) {
        setValue('cfgPayload', newConfigPayload);
      }
    }
  }, [
    configPayload,
    getAngleImportance,
    getDistanceImportance,
    getHeightImportance,
    graphVariables.hasAngle,
    graphVariables.hasDistance,
    graphVariables.hasHeight,
    graphVariables.maxAngle,
    graphVariables.maxDistance,
    graphVariables.maxHeight,
    hasScaledSeatScoreFeature,
    scoreOverrides,
    setValue,
    venueMapMaxScoreRaw,
    venueMapInfo?.sections,
    isOverrideTemplate,
  ]);

  useEffect(() => {
    const calcAngleOutput = calcAngleOutputRef.current;
    const calcDistanceOutput = calcDistanceOutputRef.current;
    const calcHeightOutput = calcHeightOutputRef.current;

    if (
      (!graphVariables.hasAngle || calcAngleOutput) &&
      (!graphVariables.hasDistance || calcDistanceOutput) &&
      (!graphVariables.hasHeight || calcHeightOutput)
    ) {
      if (scoreOverrides) {
        // Init the heat map when all the cal functions are initialized
        onCalculateSeatScores();
      }
    }
  });

  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const onDownloadTemplateClick = useCallback(() => {
    if (!event?.viagId) return;

    tryInvokeApi(
      async () => {
        const file = await new PricingClient(
          activeAccountWebClientConfig
        ).downloadSeatScoreFile(event.viagId!);

        if (file) {
          downloadFileFromBlob(
            file.fileName ?? 'seat-score-template.csv',
            file.data
          );
        }
      },
      (error) => {
        showErrorDialog('PricingClient.downloadSeatScoreFile', error);
      }
    );
  }, [activeAccountWebClientConfig, event?.viagId, showErrorDialog]);

  const [isUploadSeatScoreModalOpen, setIsUploadSeatScoreModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>();
  const onCsvFileUploaded = useCallback(() => {
    if (!uploadedFile || !event?.viagId) return;

    setIsLoading(true);
    tryInvokeApi(
      async () => {
        const result = await new PricingClient(
          activeAccountWebClientConfig
        ).uploadSeatScoreFile(
          event.viagId!,
          configId,
          eventConfigScoreOverrideId,
          name,
          {
            data: uploadedFile,
            fileName: uploadedFile.name,
          }
        );

        if (result) {
          setUploadedFile(undefined);
          cancelTo && setModal(cancelTo);

          // Reset the form values
          reset(normalizeConfigOverrideWithUiScore(result));
          mapConfigOverridesQuery.refetch();
        }
      },
      (error) => {
        showErrorDialog('PricingClient.uploadSeatScoreFile', error);
      },
      () => {
        setIsLoading(false);
        setIsUploadSeatScoreModalOpen(false);
      }
    );
  }, [
    activeAccountWebClientConfig,
    cancelTo,
    configId,
    event?.viagId,
    eventConfigScoreOverrideId,
    mapConfigOverridesQuery,
    name,
    normalizeConfigOverrideWithUiScore,
    reset,
    setModal,
    showErrorDialog,
    uploadedFile,
  ]);

  const requiredMsg = useContent(ContentId.Required);
  const configNameError = formState.errors.name?.message;

  if (!event) {
    return null;
  }

  const hasAnyPhysicalMetadata =
    graphVariables.hasAngle ||
    graphVariables.hasDistance ||
    graphVariables.hasHeight;

  return (
    <div
      className={
        isOverrideTemplate
          ? styles.heatMapEditDetailsContainerFull
          : styles.heatMapEditDetailsContainer
      }
    >
      {/* Instructions */}
      <div className={styles.heatmapPanelInstructionsContainer}>
        <div>
          <PosFormField
            label={<Content id={ContentId.Name} />}
            errors={configNameError}
          >
            <Stack direction="column" gap="l" alignItems="start" width="full">
              <PosTextField
                disabled={disabled}
                rootProps={{
                  state: getTextFieldState(configNameError),
                }}
                {...register('name', {
                  required: requiredMsg,
                })}
              />

              {
                <Stack
                  direction="row"
                  gap="m"
                  justifyContent="spaceAround"
                  alignItems="center"
                >
                  <Checkbox
                    checked={isTemplate ?? false}
                    onChange={() => {
                      const isChecked = !isTemplate;
                      setValue('isTemplate', isChecked);
                    }}
                  />
                  <Content id={ContentId.UseAsTemplate} />
                </Stack>
              }
            </Stack>
          </PosFormField>
        </div>
        <div className={styles.heatmapInstructions}>
          <Content id={ContentId.HowItWorks} />
          <div className={styles.heatmapInstructionsDetails}>
            <li>
              <Content id={ContentId.HeatMapInstruction1} />
            </li>
            <li>
              <Content id={ContentId.HeatMapInstruction2} />
            </li>
          </div>
        </div>
        {!isOverrideTemplate && (
          <div className={styles.heatmapInstructions}>
            <Content id={ContentId.ConfiguringYourHeatMap} />
            <div className={styles.heatmapInstructionsDetails}>
              <li>
                <Content id={ContentId.ConfiguringHeatMapInstruction1} />
              </li>
              <li>
                <Content id={ContentId.ConfiguringHeatMapInstruction2} />
              </li>
            </div>
          </div>
        )}
      </div>

      {/* Bezier Graphs */}
      {!isOverrideTemplate && (
        <div className={styles.heatmapPanelContainer}>
          {
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <Content id={ContentId.ShowAdvancedSettings} />
              <Switch
                checked={isAdvancedMode}
                onChange={(e) => {
                  const isChecked = e.currentTarget.checked;
                  setIsAdvancedMode(isChecked);
                }}
              />
            </div>
          }
          <Button
            variant="text"
            style={{ width: 'fit-content' }}
            onClick={() => {
              setIsUploadSeatScoreModalOpen(true);
            }}
          >
            <Content id={ContentId.UploadSeatScoreFile} />
          </Button>
          <div className={styles.bezzierGraphsContainer}>
            {!hasAnyPhysicalMetadata && (
              <WarningMessage
                message={
                  <Content id={ContentId.NoVenueMetadataForAutopricing} />
                }
              />
            )}
            {
              <Stack direction="column" gap="m">
                <SeatScoreConfigCard
                  showChildren={isAdvancedMode}
                  label={<Content id={ContentId.Angle} />}
                  importance={getAngleImportance()}
                  onDecrementImportance={() => {
                    setAngleImportance((prev) => prev - 1);
                    onCalculateSeatScores();
                  }}
                  onIncrementImportance={() => {
                    setAngleImportance((prev) => prev + 1);
                    onCalculateSeatScores();
                  }}
                >
                  <BezierGraph
                    segments={
                      graphSegments?.angleSegments ?? INITIAL_ANGLE_SEGMENTS
                    }
                    // Right now all angles come in as positive, so we can't really show the negative angle
                    //minLabel="-180°"
                    //midLabel="0"
                    minLabel="0"
                    maxLabel="180°"
                    calcOutputRef={calcAngleOutputRef}
                    exportPathRef={exportAnglePathRef}
                    onDragEnd={({ isPathValid }) => {
                      if (isPathValid) {
                        onCalculateSeatScores();
                      }
                    }}
                  />
                </SeatScoreConfigCard>
                {!graphVariables.hasAngle && (
                  <WarningMessage
                    message={<Content id={ContentId.MissingAngleData} />}
                  />
                )}
              </Stack>
            }
            {
              <Stack direction="column" gap="m">
                <SeatScoreConfigCard
                  showChildren={isAdvancedMode}
                  label={<Content id={ContentId.Distance} />}
                  importance={getDistanceImportance()}
                  onDecrementImportance={() => {
                    setDistanceImportance((prev) => prev - 1);
                    onCalculateSeatScores();
                  }}
                  onIncrementImportance={() => {
                    setDistanceImportance((prev) => prev + 1);
                    onCalculateSeatScores();
                  }}
                >
                  <BezierGraph
                    segments={
                      graphSegments?.distanceSegments ??
                      INITIAL_DISTANCE_SEGMENTS
                    }
                    minLabel={nearText}
                    maxLabel={farText}
                    calcOutputRef={calcDistanceOutputRef}
                    exportPathRef={exportDistancePathRef}
                    onDragEnd={({ isPathValid }) => {
                      if (isPathValid) {
                        onCalculateSeatScores();
                      }
                    }}
                  />
                </SeatScoreConfigCard>
                {!graphVariables.hasDistance && (
                  <WarningMessage
                    message={<Content id={ContentId.MissingDistanceData} />}
                  />
                )}
              </Stack>
            }
            {
              <Stack direction="column" gap="m">
                <SeatScoreConfigCard
                  showChildren={isAdvancedMode}
                  label={<Content id={ContentId.Height} />}
                  importance={getHeightImportance()}
                  onDecrementImportance={() => {
                    setHeightImportance((prev) => prev - 1);
                    onCalculateSeatScores();
                  }}
                  onIncrementImportance={() => {
                    setHeightImportance((prev) => prev + 1);
                    onCalculateSeatScores();
                  }}
                >
                  <BezierGraph
                    segments={
                      graphSegments?.heightSegments ?? INITIAL_HEIGHT_SEGMENTS
                    }
                    minLabel={lowText}
                    maxLabel={highText}
                    calcOutputRef={calcHeightOutputRef}
                    exportPathRef={exportHeightPathRef}
                    onDragEnd={({ isPathValid }) => {
                      if (isPathValid) {
                        onCalculateSeatScores();
                      }
                    }}
                  />
                </SeatScoreConfigCard>
                {!graphVariables.hasHeight && (
                  <WarningMessage
                    message={<Content id={ContentId.MissingHeightData} />}
                  />
                )}
              </Stack>
            }
          </div>
        </div>
      )}

      {/* Seat Heatmap Graph */}
      <div className={styles.heatmapPanelContainer}>
        <div className={styles.heatmapContainer}>
          <EventVenueHeatMap
            scoreOverrides={
              isOverrideTemplate ? templateOverrideScores : scoreOverrides
            }
            configPayload={configPayload}
            onSubmitSectionScoreChange={({
              newScoreOverrides,
              newConfigPayload,
            }) => {
              if (newScoreOverrides) {
                setValue('scoreOverrides', newScoreOverrides);
              }
              if (newConfigPayload) {
                setValue('cfgPayload', newConfigPayload);
              }
              return Promise.resolve();
            }}
          />
        </div>
      </div>
      <UploadSeatScoreModal
        isOpen={isUploadSeatScoreModalOpen}
        isLoading={isLoading}
        uploadedFile={uploadedFile}
        onDownloadTemplateClick={onDownloadTemplateClick}
        onUploadedFileChange={(file) => {
          setUploadedFile(file);
        }}
        onCsvFileUploaded={onCsvFileUploaded}
        onClose={() => {
          setIsUploadSeatScoreModalOpen(false);
        }}
      />
    </div>
  );
};
