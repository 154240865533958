import { useMemo } from 'react';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { EmptySelectionKey } from 'src/utils/adGroupUtils';
import { AdPlatformCatalogResults, Seating } from 'src/WebApiController';

export type ListingsSelectorProps = PosSelectProps & {
  seatings: Record<number, Seating> | null | undefined;
};

export const AdGroupSectionSelector = ({
  seatings,
  placeholderText,
  ...props
}: Omit<ListingsSelectorProps, 'valueOptionsContent'>) => {
  const { data: catalogData } = useAdPlatformCatalogDataContext();

  return (
    <SelectionBody
      {...props}
      catalogData={catalogData}
      seatings={seatings}
      placeholderText={placeholderText}
    />
  );
};

export const SelectionBody = ({
  catalogData,
  seatings,
  placeholderText,
  ...props
}: {
  catalogData: AdPlatformCatalogResults | undefined;
  seatings: Record<number, Seating> | null | undefined;
  placeholderText: string | undefined;
} & Omit<PosSelectProps, 'valueOptionsContent'>) => {
  const sectionsData: Record<string, string> = useMemo(() => {
    if (!seatings) {
      return {};
    }

    const results =
      Object.values(seatings).reduce(
        (acc, seating) => {
          const sectionId = seating.sectionId;
          const sectionName = seating.section;
          if (sectionId && sectionName) {
            acc[sectionId.toString()] = sectionName;
          }
          return acc;
        },
        {} as Record<string, string>
      ) ?? {};

    if (Object.values(results).length == 0) {
      results[EmptySelectionKey] = 'N/A';
    }

    return results;
  }, [seatings]);

  return (
    <PosSelect
      {...props}
      searchable={
        props.searchable != null
          ? props.searchable
          : Object.values(sectionsData).length > 10
      }
      loading={seatings == null}
      placeholderText={placeholderText}
      valueOptionsContent={sectionsData}
    />
  );
};
