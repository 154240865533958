import { Content, useContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { Event, Performer, Venue } from 'src/WebApiController';

import * as styles from './EventSearchDropdownResult.css';

export const VenueConfigSearchResultItem = ({
  events,
  performer,
  venue,
}: {
  events: Event[];
  performer?: Performer | null;
  venue?: Venue | null;
}) => {
  const atText = useContent(ContentId.at);
  return (
    <div className={styles.eventSearchResultItemDiv}>
      <div className={styles.eventSearchResultItemNameDiv}>
        {performer?.name ?? events[0].name}
        {venue?.name ? ` ${atText} ${venue.name}` : ''}
      </div>
      <div className={styles.eventSearchResultItemDateDiv}>
        {events.length} <Content id={ContentId.Events} />
      </div>
    </div>
  );
};
