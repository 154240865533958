import clsx from 'clsx';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useGetReleaseNoteHtml } from 'src/hooks/api/useGetReleaseNoteHtml';
import { useReleaseNotes } from 'src/hooks/useReleaseNotes';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { NoData } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { ReleaseNote, ReleaseNoteType } from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as reportsStyles from '../Reports/Reports.css';
import * as styles from './ReleaseNotes.css';

export function ReleaseNotes() {
  const whatsNewTitle = useContent(ContentId.WhatsNew);
  const releaseNotesText = useContent(ContentId.ReleaseNotes);

  const {
    releaseNoteInfos,
    releaseNoteIdsRead,
    setReleaseNoteIdsRead,
    isLoading,
  } = useReleaseNotes({});

  const isReleaseNoteRead = useCallback(
    (releaseNoteId: number) => {
      return releaseNoteIdsRead?.includes(releaseNoteId);
    },
    [releaseNoteIdsRead]
  );

  const markReleaseNoteAsRead = useCallback(
    async (releaseNoteId: number) => {
      if (isReleaseNoteRead(releaseNoteId)) {
        return;
      }

      await setReleaseNoteIdsRead([
        ...(releaseNoteIdsRead ?? []),
        releaseNoteId,
      ]);
    },
    [isReleaseNoteRead, setReleaseNoteIdsRead, releaseNoteIdsRead]
  );

  const releaseNotesSortedByType = useMemo(() => {
    if (!releaseNoteInfos) {
      return releaseNoteInfos;
    }

    const newFeatures = releaseNoteInfos.filter(
      (releaseNote) =>
        releaseNote.releaseNoteType === ReleaseNoteType.NewFeature
    );

    const bugFixes = releaseNoteInfos.filter(
      (releaseNote) => releaseNote.releaseNoteType === ReleaseNoteType.BugFix
    );

    return [...newFeatures, ...bugFixes];
  }, [releaseNoteInfos]);

  const [activeReleaseNote, setActiveReleaseNote] =
    useState<ReleaseNote | null>(null);

  const { releaseNoteHtmlByReleaseNoteId = {}, isLoading: htmlIsLoading } =
    useGetReleaseNoteHtml({
      releaseNoteIds: activeReleaseNote ? [activeReleaseNote.id] : [],
    });

  useEffect(() => {
    if (activeReleaseNote == null) {
      if (releaseNotesSortedByType?.length) {
        setActiveReleaseNote(releaseNotesSortedByType[0]);
        debounce(() => {
          markReleaseNoteAsRead(releaseNotesSortedByType[0].id);
        }, 600)();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseNotesSortedByType]);

  return (
    <LayoutContent
      mainRoute={MainRoute.ReleaseNotes}
      routeTitle={whatsNewTitle}
    >
      <div className={reportsStyles.root}>
        <h1 className={reportsStyles.pageName}>
          <Content id={ContentId.WhatsNew} />
        </h1>

        <div className={styles.mainContent}>
          {isLoading ? (
            <PosSpinner />
          ) : (
            <>
              {releaseNotesSortedByType?.length ? (
                <>
                  <div
                    className={clsx(
                      styles.tabsContainer,
                      styles.tabsContainerWidth
                    )}
                  >
                    {releaseNotesSortedByType?.map((releaseNote) => {
                      return (
                        <div
                          key={releaseNote.id}
                          onClick={() => {
                            setActiveReleaseNote(releaseNote);
                            markReleaseNoteAsRead(releaseNote.id);
                          }}
                          className={clsx(
                            reportsStyles.navTitle,
                            styles.flexRow,
                            reportsStyles.textPrimary,
                            {
                              [reportsStyles.navTitleActive]:
                                activeReleaseNote?.id === releaseNote.id,
                            }
                          )}
                          title={releaseNote.title}
                        >
                          <span>{releaseNote.title}</span>
                          {isReleaseNoteRead(releaseNote.id) ? null : (
                            <UnreadDot />
                          )}
                        </div>
                      );
                    })}
                  </div>
                  {activeReleaseNote ? (
                    <div className={styles.viewContainer}>
                      {htmlIsLoading ? (
                        <PosSpinner />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              releaseNoteHtmlByReleaseNoteId[
                                activeReleaseNote.id
                              ],
                          }}
                        />
                      )}
                    </div>
                  ) : null}
                </>
              ) : (
                <NoData>
                  <FormatContent
                    id={FormatContentId.NoDataAvailable}
                    params={releaseNotesText}
                  />
                </NoData>
              )}
            </>
          )}
        </div>
      </div>
    </LayoutContent>
  );
}

export const UnreadDot = ({ fill = 'red' }: { fill?: string }) => (
  <div className={styles.dotContainer}>
    <div style={{ backgroundColor: fill }} className={styles.unreadDot}></div>
  </div>
);
