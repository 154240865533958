import { useCallback } from 'react';
import { LaunchBulkEditTags } from 'src/components/common/BulkActions/BulkEditTagsAction';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import {
  BulkEditTagAction,
  BulkEditTagDialog,
} from 'src/dialogs/BulkEdits/BulkEditTagDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { PURCHASE_BULK_UPDATE_TAGS_UPDATE_KEY } from '../../PurchaseActionDropdown.constants';

export const useBulkEditTagsAction = (
  filterQuery: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const updateKey = groupId
    ? `purchaseTags-event-${groupId}}`
    : PURCHASE_BULK_UPDATE_TAGS_UPDATE_KEY;
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkEditTagDialog,
    closeDialog: closeBulkEditTagDialog,
  } = useDialog(DialogId.BulkEditTags, BulkEditTagDialog);

  const onSubmitEditTags = useCallback(
    async (
      action: BulkEditTagAction,
      tagKey: string | null,
      tagValue?: string | undefined,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkUpdateTagsPreview(filterQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateTagsPreview', error, {
              trackErrorData: { filterQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (tagKey) {
        await tryInvokeApi(
          async () => {
            const client = new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            );

            const succeeded =
              action === BulkEditTagAction.Update
                ? await client.bulkUpdateTags(
                    preview!.preview,
                    tagKey,
                    tagValue,
                    updateKey,
                    supportBackgroundProcess
                  )
                : await client.bulkRemoveTags(
                    preview!.preview,
                    tagKey,
                    updateKey,
                    supportBackgroundProcess
                  );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on details modal
                // we force re-loading the entity and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshData(true);
                await refreshExpandedListItems();
              }

              closeBulkEditTagDialog();
            }
          },
          (error) => {
            showErrorDialog('onSubmitEditTags', error, {
              trackErrorData: { tagKey, tagValue, preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQuery,
      showErrorDialog,
      updateKey,
      closeBulkEditTagDialog,
      setActivePosEntity,
      refreshData,
      refreshExpandedListItems,
    ]
  );

  const onChangeTagClick = useCallback(
    (e: React.MouseEvent, editTagAction: BulkEditTagAction) => {
      e.preventDefault();
      e.stopPropagation();
      openBulkEditTagDialog({
        updateKey: updateKey,
        action: editTagAction,
        onOkay: onSubmitEditTags,
        entityType: ActionOutboxEntityType.Purchase,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkEditTagDialog,
      });
    },
    [
      closeBulkEditTagDialog,
      onSubmitEditTags,
      openBulkEditTagDialog,
      setIsLoading,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkEditTags
        key="EditTags"
        onClick={onChangeTagClick}
        disabled={isLoading}
        entityType={ActionOutboxEntityType.Purchase}
      />
    ),
  };
};
