import { ReactNode } from 'react';
import { Stack } from 'src/core/ui';
import { useMapDeliveryTypesToString } from 'src/hooks/useMapDeliveryTypesToString';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FormatOption } from 'src/modals/EditTableColumns';
import { CheckCircleIcon } from 'src/svgs/CheckCircleIcon';
import { getDeliveryTypeIcon } from 'src/utils/ticketTypeUtils';
import {
  DeliveryType,
  Feature,
  Listing,
  ListingGroup,
  PreDeliveryState,
  TicketType,
} from 'src/WebApiController';

import * as styles from './ListingDeliveryTypeDisplay.css';

interface DeliveryTypeIconWrapperProps {
  addCheckIcon: boolean;
  children: ReactNode;
}
const DeliveryTypeIconWrapper = ({
  addCheckIcon,
  children,
}: DeliveryTypeIconWrapperProps) => {
  return addCheckIcon ? (
    <div style={{ position: 'relative' }}>
      {children}
      <CheckCircleIcon className={styles.checkIcon} size="11px" />
    </div>
  ) : (
    <>{children}</>
  );
};

const DeliveryTypeTextWrapper = ({
  addCheckIcon,
  children,
}: DeliveryTypeIconWrapperProps) => {
  return addCheckIcon ? (
    <Stack gap="s" alignItems="center">
      {children}
      <CheckCircleIcon className={styles.checkIconForText} size="11px" />
    </Stack>
  ) : (
    <>{children}</>
  );
};

export const ListingDeliveryTypeDisplay = ({
  listing,
  userDefinedPrecision,
  showIconTitle,
}: {
  listing:
    | Listing
    | {
        delivType: DeliveryType;
        topPriTktType?: TicketType;
        preDelivState?: PreDeliveryState;
      };
  userDefinedPrecision: FormatOption;
  showIconTitle?: boolean;
}) => {
  const listingGroup = listing as ListingGroup;
  const hasTicketTypeIconOverlay = useUserHasFeature(
    Feature.TicketTypeIconOverlay
  );

  const strDeliveryTypes = useMapDeliveryTypesToString({
    deliveryTypesObj: listing,
  });

  const shouldAddCheckIcon =
    hasTicketTypeIconOverlay &&
    listing.preDelivState === PreDeliveryState.PreDelivered;

  return (
    <Stack alignItems="center">
      {listingGroup.deliveryTypes ? (
        <div className={styles.subRowsMetadataContainer}>
          <Stack direction="row" gap="s">
            {listingGroup.deliveryTypes.map((dt) => {
              const key = `${dt.item1}-${dt.item2}`;
              if (userDefinedPrecision === FormatOption.EnumDisplay_Icon) {
                return (
                  <DeliveryTypeIconWrapper
                    key={key}
                    addCheckIcon={shouldAddCheckIcon}
                  >
                    {getDeliveryTypeIcon(
                      dt.item1,
                      dt.item2,
                      undefined,
                      showIconTitle
                    )}
                  </DeliveryTypeIconWrapper>
                );
              } else {
                return (
                  <DeliveryTypeTextWrapper
                    addCheckIcon={shouldAddCheckIcon}
                    key={key}
                  >
                    {strDeliveryTypes}
                  </DeliveryTypeTextWrapper>
                );
              }
            })}
          </Stack>
        </div>
      ) : (
        <>
          {userDefinedPrecision === FormatOption.EnumDisplay_Icon ? (
            <DeliveryTypeIconWrapper addCheckIcon={shouldAddCheckIcon}>
              {getDeliveryTypeIcon(
                listing.delivType,
                listing.topPriTktType,
                undefined,
                showIconTitle
              )}
            </DeliveryTypeIconWrapper>
          ) : (
            <DeliveryTypeTextWrapper addCheckIcon={shouldAddCheckIcon}>
              {strDeliveryTypes}
            </DeliveryTypeTextWrapper>
          )}
        </>
      )}
    </Stack>
  );
};
