import { formatInTimeZone } from 'date-fns-tz';
import { useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { vars } from 'src/core/themes';
import { Card, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { EventConfigScoreOverride } from 'src/WebApiController';

import * as styles from './EventSeatMapSelection.css';

export type GlobalConfigPayloadCardProps = {
  configPayload: string;
  name: string;
  eventId: number | null;
  onClick: (
    eventConfigScoreOverride: EventConfigScoreOverride | null,
    defaultConfigPayload?: string | null
  ) => void;
  createDate: Date;
  updateDate: Date;
};

export function GlobalConfigPayloadCard({
  configPayload,
  name,
  eventId,
  onClick,
  createDate,
  updateDate,
}: GlobalConfigPayloadCardProps) {
  const { timeZone } = useSiteTimezoneContext();
  const [isHovered, setIsHovered] = useState(false);

  const createDateFormatted = formatInTimeZone(
    createDate,
    timeZone,
    'MMM d, yyyy, h:mm a'
  );
  const updateDateFormatted = formatInTimeZone(
    updateDate,
    timeZone,
    'MMM d, yyyy, h:mm a'
  );

  return (
    <Card
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={styles.configPayloadCard}
    >
      <Stack
        gap="xl"
        alignItems="center"
        justifyContent="spaceBetween"
        className={isHovered ? styles.hover : styles.noHover}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onClick(null, configPayload);
        }}
      >
        <div>{name}</div>

        {eventId != null ? <div>{eventId}</div> : undefined}

        <Stack
          gap="m"
          direction="row"
          style={{ fontSize: vars.typography.fontSize.xs }}
        >
          <div
            style={{
              color: vars.color.textPrimaryLight,
            }}
          >
            <Content id={ContentId.CreatedOn} />
          </div>

          <strong style={{ color: vars.color.textSuccess }}>
            {createDateFormatted}
          </strong>

          <div
            style={{
              color: vars.color.textPrimaryLight,
            }}
          >
            <Content id={ContentId.LastUpdatedOn} />
          </div>

          <strong style={{ color: vars.color.textSuccess }}>
            {updateDateFormatted}
          </strong>
        </Stack>
      </Stack>
    </Card>
  );
}
