import { Content } from 'src/contexts/ContentContext';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ContentId } from 'src/utils/constants/contentId';
import { formatPercent } from 'src/utils/numberFormatter';
import { getMetricsDisplay } from 'src/utils/ticketMetricUtils';
import { Permission } from 'src/WebApiController';

import * as styles from './Metrics.css';
import { BulletPoint } from './MetricsDisplay/MetricsDisplay.styled';

export type SaleMainMetricsProps = {
  totalNetProceeds: string | null;
};

export function SaleMainMetrics({ totalNetProceeds }: SaleMainMetricsProps) {
  const hasTotalNetProceedsPermissions = useUserHasAnyOfPermissions(
    Permission.Sales_ViewProceeds,
    Permission.Sales_ViewRecentProceeds
  );
  return totalNetProceeds == null ? (
    hasTotalNetProceedsPermissions ? (
      <Content id={ContentId.NA} />
    ) : null
  ) : (
    <span>{totalNetProceeds}</span>
  );
}

export type SaleHeaderMetricsProps = {
  needFulfillment: boolean;
};

export function SaleHeaderMetrics({ needFulfillment }: SaleHeaderMetricsProps) {
  return needFulfillment ? (
    <div className={styles.saleHeaderMetricsContainer}>
      <Content id={ContentId.NeedFulfillment} />
    </div>
  ) : null;
}

export type SaleSubMetricsProps = {
  margin: number | null;
  ticketsFulfilled: number;
  ticketsSold: number;
  addBulletPoint?: boolean;
  firstBulletPoint?: boolean;
  secondBulletPoint?: boolean;
};

export function SaleSubMetrics({
  margin,
  ticketsFulfilled,
  ticketsSold,
  addBulletPoint = true,
  firstBulletPoint = true,
  secondBulletPoint = true,
}: SaleSubMetricsProps) {
  const hasCostMarginPermission = useUserHasAnyOfPermissions(
    Permission.Sales_ViewCostAndMargin
  );

  return (
    <span className={styles.saleSubMetrics}>
      {margin == null ? (
        hasCostMarginPermission ? (
          <Content id={ContentId.NA} />
        ) : null
      ) : (
        <span>{getMetricsDisplay('marg', `${formatPercent(margin)}`)}</span>
      )}
      {firstBulletPoint && <BulletPoint addBulletPoint={addBulletPoint} />}
      <span>
        {getMetricsDisplay(
          'saleUnfilled',
          `${ticketsFulfilled}/${ticketsSold}`
        )}
      </span>
      {secondBulletPoint && <BulletPoint addBulletPoint={addBulletPoint} />}
    </span>
  );
}
