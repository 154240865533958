import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function DownloadIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M5.222 17.556v2.221h13.444v-2.221h2.223V22H3v-4.444h2.222zM12.89 2v12.889l6-5.889 1.555 1.556-8.666 8.666-8.667-8.666L4.667 9l6 5.889V2h2.222z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
