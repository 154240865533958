import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { createContext, PropsWithChildren, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { SellerUserClient, UserSetting } from 'src/WebApiController';

type UserSettingsMap = {
  [key in keyof typeof UserSetting]?: string;
};

type ISellerUserSettingsContext = {
  queryResult: UseQueryResult<UserSettingsMap | null, Error>;
  contextSettingIds: UserSetting[];
  contextQueryKey: (string | boolean | UserSetting[] | undefined)[];
} | null;

export const SellerUserSettingsContext =
  createContext<ISellerUserSettingsContext>(null);

type SellerUserSettingsProviderProps = PropsWithChildren<{
  initialUserSettingIds: UserSetting[];
  currentLoginUserOnly: boolean;
}>;

export const SellerUserSettingsProvider = ({
  initialUserSettingIds,
  currentLoginUserOnly,
  children,
}: SellerUserSettingsProviderProps) => {
  const { trackError } = useErrorBoundaryContext();

  const { activeAccountWebClientConfig } = useAppContext();
  const sellerUserClient = useMemo(
    () => new SellerUserClient(activeAccountWebClientConfig),
    [activeAccountWebClientConfig]
  );

  const queryKey = [
    'userSettings',
    activeAccountWebClientConfig.activeAccountId,
    currentLoginUserOnly,
    ...initialUserSettingIds,
  ];

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      return await sellerUserClient.getSellerUserSettings(
        initialUserSettingIds,
        currentLoginUserOnly ?? true
      );
    },

    meta: {
      persist: false,
      onError: (error: ErrorTypes) => {
        trackError('SellerUserClient.getSellerUserSettings', error, {
          trackErrorData: { initialUserSettingIds },
        });
      },
    },
    enabled: activeAccountWebClientConfig?.activeAccountId != null,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  return (
    <SellerUserSettingsContext.Provider
      value={{
        queryResult,
        contextSettingIds: initialUserSettingIds,
        contextQueryKey: queryKey,
      }}
    >
      {children}
    </SellerUserSettingsContext.Provider>
  );
};
