import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const FilterSectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 48px);
  margin: 0 auto;
  max-width: var(--radix-popover-content-available-width);
  max-height: calc(var(--radix-popover-content-available-height) - 60px);

  ${({ theme: { media } }) => `
    ${media.makeMediaQuery(
      media.breakpoints.md,
      `
      min-width: 490px;
      max-width: max-content;
      max-height: var(--radix-popover-content-available-height)`
    )}
  `}

  /* Works on Firefox */
    * {
    scrollbar-width: thin;
    scrollbar-color: ${(props) =>
      `${props.theme.colors.secondary400} ${props.theme.colors.backgroundPrimary}`};
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }

  *::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.backgroundPrimary};
    padding: 2px;
    border-radius: 6px;
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.secondary400};
    border-radius: 6px;
    border: 2px solid ${(props) => props.theme.colors.backgroundPrimary};

    &:hover {
      background-color: ${(props) => props.theme.colors.secondary500};
    }
  }
`;

export const FilterDialogBody = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: ${() => `${vars.spacing.sm} ${vars.spacing.lg}`};
  ${({ theme: { media } }) => `
    ${media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding: ${vars.spacing.sm} ${vars.spacing.xl}`
    )}
  `}
`;

export const FilterDialogFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${() => `${vars.spacing.sm} ${vars.spacing.lg} ${vars.spacing.lg}`};
  ${({ theme: { media } }) => `
    ${media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding: ${vars.spacing.sm} ${vars.spacing.xl} ${vars.spacing.lg}`
    )}
  `}
  gap: ${() => vars.spacing.sm};
`;

export const FilterDialogFooterMessage = styled.div`
  display: flex;
  padding: ${() => `${vars.spacing.sm} ${vars.spacing.lg} ${vars.spacing.lg}`};
  ${({ theme: { media } }) => `
    ${media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding: ${vars.spacing.sm} ${vars.spacing.xl} ${vars.spacing.lg}`
    )}
  `}
  align-items: center;
  gap: ${() => vars.spacing.sm};
`;

export const FilterDialogFooterButtons = styled.div`
  display: flex;
  padding: ${() => `${vars.spacing.sm} ${vars.spacing.lg} ${vars.spacing.lg}`};
  ${({ theme: { media } }) => `
    ${media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding: ${vars.spacing.sm} ${vars.spacing.xl} ${vars.spacing.lg}`
    )}
  `}
  gap: ${() => vars.spacing.sm};
`;

export const FilterDialogDisplaySection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
`;

export const FilterDialogDisplaySectionHeader = styled.header`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('caption1', 'bold')};
  text-transform: uppercase;
  padding-bottom: ${() => vars.spacing.sm};
`;

export const FilterDialogDisplaySectionContent = styled.div<{ type?: string }>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: ${(props) => (props.type === 'row' ? 'row' : 'column')};
  flex-wrap: wrap;
  width: 100%;
  gap: ${() => vars.spacing.sm};

  ${({ type }) =>
    type === 'row' &&
    `
      ${FilterDialogItemContainer} {
        min-width: calc(50% - 16px);
        width: max-content;
      }
    `}
`;

export const FilterDialogItemContainer = styled.div`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('body1', 'medium')};

  width: 100%;
`;

export const FilterDialogItemDiv = styled.div`
  margin-top: ${() => vars.spacing.xxs};
`;
