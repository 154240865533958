import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function GenericDisclosuresIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M20.272 2v15.528l-1.628.767V3.629H5.279v16.742h13.365V19.15h1.628V22H3.65V2h16.622zm-6.345 8.817v1.629H7.082v-1.63h6.845zm2.935 0v1.629h-1.448v-1.63h1.448zM13.927 6.89V8.52H7.082V6.89h6.845zm2.935 0V8.52h-1.448V6.89h1.448z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
