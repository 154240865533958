export const enum ListingTab {
  Broadcast = 'broadcast',
  Tickets = 'tickets',
  Pricing = 'pricing',
  Sales = 'sales',
  Purchase = 'purchase',
  Tags = 'tags',
  AutoPricing = 'autopricing',
}

export const ListingTabOrder = [
  ListingTab.Broadcast,
  ListingTab.Tickets,
  ListingTab.Pricing,
  ListingTab.AutoPricing,
  ListingTab.Sales,
  ListingTab.Purchase,
  ListingTab.Tags,
];
