import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useContext } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { vars } from 'src/core/themes';
import { DropdownMenu, Stack } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserGroups } from 'src/hooks/useUserGroups';
import { DeleteIcon, EditIcon, IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { UserGroup } from 'src/WebApiController';

import { EditTeamModal } from '../EditTeam/EditTeamModal';

const columnHelper = createColumnHelper<UserGroup | null>();

export const TEAMS_TABLE_COLUMNS_CONFIG = [
  columnHelper.accessor('userGroupName', {
    id: 'name',
    minSize: 50,
    maxSize: 100,
    enableSorting: true,
    sortingFn: 'text',
    header: () => (
      <TableCellDiv align="left">
        <Content id={ContentId.Team} />
      </TableCellDiv>
    ),
    cell: ({ getValue }) => (
      <TableCellDiv align="left">{getValue()}</TableCellDiv>
    ),
  }),
  columnHelper.accessor((data) => data?.sellerUserIds.length, {
    id: 'members',
    minSize: 50,
    maxSize: 50,
    enableSorting: true,
    sortingFn: 'text',
    header: () => (
      <TableCellDiv align="right">
        <Content id={ContentId.Users} />
      </TableCellDiv>
    ),
    cell: ({ getValue }) => (
      <TableCellDiv align="right">{getValue()}</TableCellDiv>
    ),
  }),

  columnHelper.accessor('userGroupDescription', {
    id: 'description',
    minSize: 50,
    maxSize: 200,
    enableSorting: true,
    sortingFn: 'text',
    header: () => (
      <TableCellDiv align="left">
        <Content id={ContentId.Description} />
      </TableCellDiv>
    ),
    cell: ({ getValue }) => (
      <TableCellDiv align="left">{getValue()}</TableCellDiv>
    ),
  }),
  columnHelper.accessor((data) => data, {
    id: 'action',
    header: () => (
      <TableCellDiv align="right">
        <Content id={ContentId.Actions} />
      </TableCellDiv>
    ),
    minSize: 50,
    maxSize: 50,
    cell: function Cell({ row: { original } }) {
      const { setModal, closeModal } = useContext(ModalContext);
      const { deleteUserGroup } = useUserGroups();
      const deleteConfirmDialog = useBasicDialog();
      const { showErrorDialog } = useErrorBoundaryContext();

      const onDeleteUserGroup = useCallback(async () => {
        deleteConfirmDialog.closeDialog();
        tryInvokeApi(
          async () => {
            await deleteUserGroup(original!.userGroupId);
          },
          (error) => {
            showErrorDialog('UserGroupClient.deleteUserGroup', error, {
              trackErrorData: { ...original },
            });
          }
        );
      }, [deleteConfirmDialog, deleteUserGroup, original, showErrorDialog]);

      if (!original) return null;

      return (
        <TableCellDiv align="right" style={{ gap: vars.spacing.m }}>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <div
                style={{
                  padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}`,
                }}
              >
                <MoreIcon
                  withHoverEffect
                  fill={IconsFill.textBrand}
                  align="middle"
                />
              </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Content align="end">
                <DropdownMenu.Item
                  key="Edit"
                  onClick={() =>
                    setModal({
                      children: (
                        <EditTeamModal
                          userGroup={original}
                          onClose={closeModal}
                        />
                      ),
                      clickOutsideToClose: true,
                      centered: true,
                      size: 'sm',
                    })
                  }
                >
                  <Stack alignItems="center" gap="m" width="full">
                    <EditIcon
                      withHoverEffect
                      fill={IconsFill.textBrand}
                      size={vars.iconSize.m}
                    />
                    <Content id={ContentId.Edit} />
                  </Stack>
                </DropdownMenu.Item>

                <DropdownMenu.Item
                  key="DeleteReport"
                  onClick={() => deleteConfirmDialog.launchDialog()}
                >
                  <Stack alignItems="center" gap="m" width="full">
                    <DeleteIcon
                      withHoverEffect
                      fill={IconsFill.textBrand}
                      size={vars.iconSize.m}
                    />
                    <Content id={ContentId.Remove} />
                  </Stack>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>

          <ConfirmDialog
            {...deleteConfirmDialog.dialogProps}
            headerText={<Content id={ContentId.DeleteTeam} />}
            bodyText={<Content id={ContentId.AreYouSure} />}
            onOkay={() => onDeleteUserGroup()}
            onCancel={() => deleteConfirmDialog.closeDialog()}
          />
        </TableCellDiv>
      );
    },
  }),
];
