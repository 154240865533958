import * as Tabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { mergeProps } from 'src/core/utils';

import { ScrollableToolbar } from '../ScrollableToolbar';
import * as styles from './Tabs.css';

export const Root = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof Tabs.Root>
>(function Root({ ...props }, ref) {
  return (
    <Tabs.Root
      {...props}
      ref={ref}
      {...mergeProps(
        {
          className: clsx(props.className, {
            [styles.tabsRoot]: props.orientation !== 'vertical',
            [styles.tabsRootVertical]: props.orientation === 'vertical',
          }),
        },
        props
      )}
    />
  );
});

export const Trigger = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithoutRef<typeof Tabs.Trigger> & {
    hasErrors?: boolean;
    orientation?: 'horizontal' | 'vertical';
  }
>(function Trigger({ hasErrors, orientation, ...props }, ref) {
  return (
    <Tabs.Trigger
      ref={ref}
      {...mergeProps(
        {
          className: clsx({
            [styles.tabsTrigger]: orientation !== 'vertical',
            [styles.tabsTriggerWithErrors]:
              orientation !== 'vertical' && hasErrors,
            [styles.tabsTriggerVertical]: orientation === 'vertical',
            [styles.tabsTriggerWithErrorsVertical]:
              orientation === 'vertical' && hasErrors,
          }),
        },
        props
      )}
    />
  );
});

export const List = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof Tabs.List> & {
    orientation?: 'horizontal' | 'vertical';
  }
>(function List({ orientation, ...props }, ref) {
  return (
    <ScrollableToolbar
      className={clsx({
        [styles.tabListContainer]: orientation !== 'vertical',
        [styles.tabListContainerVertical]: orientation === 'vertical',
      })}
    >
      <Tabs.List
        ref={ref}
        {...mergeProps(
          {
            className: clsx(props.className, {
              [styles.tabsList]: orientation !== 'vertical',
              [styles.tabsListVertical]: orientation === 'vertical',
            }),
          },
          props
        )}
      />
    </ScrollableToolbar>
  );
});

export const Content = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof Tabs.Content> & {
    orientation?: 'horizontal' | 'vertical';
  }
>(function Content({ orientation, ...props }, ref) {
  return (
    <Tabs.Content
      ref={ref}
      {...mergeProps(
        { style: orientation === 'vertical' ? { width: '100%' } : {} },
        props
      )}
    />
  );
});
