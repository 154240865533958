import React from 'react';
import { TabWithStarIcon } from 'src/components/Listings/InventoryEventPage/TabWithStarIcon';
import { vars } from 'src/core/themes';
import * as Tabs from 'src/core/ui/Tabs';

import * as styles from './EventPage.css';

export interface TabItem<TabType extends string> {
  value: TabType;
  label: React.ReactNode;
  content: React.ReactNode;
  className?: string;
  isVisible?: boolean;
}

interface EventPageTabsProps<TabType extends string> {
  activeTab: TabType;
  setActiveTab: React.Dispatch<React.SetStateAction<TabType>>;
  defaultTab?: TabType;
  onSetDefaultTabClick: (tab: TabType) => void;
  tabs: TabItem<TabType>[];
}

export const EventPageTabs = <TabType extends string>({
  activeTab,
  setActiveTab,
  defaultTab,
  onSetDefaultTabClick,
  tabs,
}: EventPageTabsProps<TabType>) => {
  return (
    <Tabs.Root
      value={activeTab}
      onValueChange={(value) => setActiveTab(value as TabType)}
      className={styles.tabsSection}
    >
      <Tabs.List
        style={{
          padding: `${vars.spacing['lg']} ${vars.spacing['xl']} 0 ${vars.spacing['xl']}`,
        }}
      >
        {tabs
          .filter((tab) => tab.isVisible !== false)
          .map((tab) => (
            <Tabs.Trigger key={tab.value} value={tab.value}>
              <TabWithStarIcon
                onIconClick={onSetDefaultTabClick}
                defaultTab={defaultTab}
                tabType={tab.value}
              >
                {tab.label}
              </TabWithStarIcon>
            </Tabs.Trigger>
          ))}
      </Tabs.List>
      {tabs
        .filter((tab) => tab.isVisible !== false)
        .map((tab) => (
          <Tabs.Content
            key={tab.value}
            value={tab.value}
            className={tab.className}
          >
            {tab.content}
          </Tabs.Content>
        ))}
    </Tabs.Root>
  );
};
