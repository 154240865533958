import React from 'react';
import { IconButton } from 'src/components/Buttons';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import {
  FileName,
  IconWrapper,
  SelectedFileWrapper,
} from './SelectedFile.styled';

export const SelectedFile = ({
  children,
  onRemove,
}: {
  children: React.ReactNode;
  onRemove: () => void;
}) => {
  return (
    <SelectedFileWrapper>
      <FileName>{children}</FileName>
      <IconWrapper>
        <IconButton
          onClick={onRemove}
          titleContentId={ContentId.Remove}
          icon={<CrossIcon withHoverEffect />}
        />
      </IconWrapper>
    </SelectedFileWrapper>
  );
};
