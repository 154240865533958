import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const SellerAccountInfoContainer = styled.div<{ isCurrent: boolean }>`
  display: flex;
  align-items: center;
  gap: ${() => vars.spacing.sm};
  width: 100%;
  cursor: ${(props) => (props.isCurrent ? 'default' : 'inherit')};
`;

export const SellerAccountNameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${() => vars.spacing.sm};
`;

export const AccountTypeDiv = styled.div<{ isCurrent: boolean }>`
  color: ${(props) =>
    props.isCurrent
      ? props.theme.colors.textSuccess
      : props.theme.colors.textPrimary};
`;

export const AccountNameDiv = styled.div<{ isCurrent: boolean }>`
  font-weight: ${(props) => (props.isCurrent ? 700 : 500)};
  color: ${(props) => props.theme.colors.textBrand};
`;

export const ImpersonatedAccountNameDiv = styled.div`
  padding-left: ${() => vars.spacing.xxs};
  color: ${(props) => props.theme.colors.textPrimary};
`;
