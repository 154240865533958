import { useEffect, useState } from 'react';
import { UndercutPriceSettingsMode } from 'src/utils/autoPricingUtils';

export const useUndercutAmountSettings = (
  undercutAbsoluteAmount?: number | null,
  undercutRelativeAmount?: number | null
) => {
  const [undercutAbsoluteAmountMode, setUndercutAbsoluteAmountMode] = useState<
    UndercutPriceSettingsMode | undefined
  >(undefined);
  const [undercutRelativeAmountMode, setUndercutRelativeAmountMode] = useState<
    UndercutPriceSettingsMode | undefined
  >(undefined);

  useEffect(() => {
    const newUAMode =
      (undercutAbsoluteAmount ?? 0) >= 0
        ? UndercutPriceSettingsMode.Discount
        : UndercutPriceSettingsMode.Premium;
    if (newUAMode !== undercutAbsoluteAmountMode) {
      setUndercutAbsoluteAmountMode(newUAMode);
    }

    const newRAMode =
      (undercutRelativeAmount ?? 0) >= 0
        ? UndercutPriceSettingsMode.Discount
        : UndercutPriceSettingsMode.Premium;
    if (newRAMode !== undercutRelativeAmountMode) {
      setUndercutRelativeAmountMode(newRAMode);
    }
  }, [
    undercutAbsoluteAmount,
    undercutAbsoluteAmountMode,
    undercutRelativeAmount,
    undercutRelativeAmountMode,
  ]);

  return {
    undercutAbsoluteAmountMode,
    setUndercutAbsoluteAmountMode,
    undercutRelativeAmountMode,
    setUndercutRelativeAmountMode,
  };
};
