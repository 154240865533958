import React, { useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { CheckIcon, IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { ListingExperiment } from 'src/WebApiController';

type ActionCellProps = {
  experiment: ListingExperiment;
  onResumeExperiment: (listingGroupId: string | null) => void;
  onStopExperiment: (listingGroupId: string | null) => Promise<void>;
};

const enum ExperimentStatus {
  Scheduled = 'scheduled',
  Running = 'running',
  Completed = 'completed',
}

const StatusIcon = ({ status }: { status: ExperimentStatus }) => {
  switch (status) {
    case ExperimentStatus.Scheduled:
      return <CheckIcon fill={vars.color.textDisabled} />;
    case ExperimentStatus.Running:
      return <CheckIcon fill={vars.color.textWarning} />;
    case ExperimentStatus.Completed:
      return <CheckIcon fill={vars.color.textSuccess} />;
    default:
      return null;
  }
};

export const ActionCell: React.FC<ActionCellProps> = ({
  experiment,
  onResumeExperiment,
  onStopExperiment,
}) => {
  const status = useMemo(() => {
    const { startDate, endDate } = experiment;
    const now = new Date();
    if (new Date(startDate) > now) {
      return ExperimentStatus.Scheduled;
    }
    if (new Date(endDate) > now) {
      return ExperimentStatus.Running;
    }
    return ExperimentStatus.Completed;
  }, [experiment]);

  return (
    <Stack direction="row" gap="m">
      <StatusIcon status={status} />
      <PosDropdown
        trigger={
          <div
            style={{ padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}` }}
          >
            <MoreIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              align="middle"
            />
          </div>
        }
        align="end"
      >
        <PosDropdownItem
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onStopExperiment(experiment.listingGroupId);
          }}
          disabled={status != ExperimentStatus.Running}
        >
          <Content id={ContentId.StopExperiment} />
        </PosDropdownItem>
        <PosDropdownItem
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onResumeExperiment(experiment.listingGroupId);
          }}
          disabled={status != ExperimentStatus.Completed}
        >
          <Content id={ContentId.ResumeExperiment} />
        </PosDropdownItem>
      </PosDropdown>
    </Stack>
  );
};
