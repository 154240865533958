import { ChangeEvent, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { Switch } from 'src/core/interim/Switch';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TooltipPopover } from 'src/core/ui/TooltipPopover';
import { useListingHasVenueMapInfo } from 'src/hooks/useListingHasVenueMapInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import { WarningOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Event, Feature, Listing } from 'src/WebApiController';

import { ListingPricingInput } from './ListingPriceForm.types';

type AutoPricingToggleCellProps = {
  listing: Listing;
  editable?: boolean;
  disabled?: boolean;
  event: Event;
};

export const AutoPricingToggleCell = (props: AutoPricingToggleCellProps) => {
  return (
    <EventMapContextProvider event={props.event} doNotGetScoreOverrides>
      <AutoPricingToggleCellContent {...props} />
    </EventMapContextProvider>
  );
};

const AutoPricingToggleCellContent = ({
  listing,
  editable,
  disabled,
  event,
}: AutoPricingToggleCellProps) => {
  const field = 'autoPricingEnabled';
  const { setValue, watch, getFieldState, formState } =
    useFormContext<ListingPricingInput>();

  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);

  const { canSetPrice, autoPricingEnabled, setAutoPricingDefaults } =
    useListingAutoPricingSettings(listing);

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const isSubmittingPrice = watch('isSubmitting');
  const rowIndex = watch('rowIndex');

  const fieldValue = watch(field);
  const canAutoPrice = useUserHasFeature(Feature.AutoPricing) && canSetPrice;
  const { mapExists, hasVenueMapInfo } = useListingHasVenueMapInfo(listing);

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const isDisabled =
    disabled ||
    listing.isDeleted ||
    !canSetPrice ||
    listingSelection.isSingleGroupMultiSelect ||
    fieldIsSubmitting;

  const fieldError = getFieldState(field, formState)?.error?.message;

  const fieldHasChanged =
    formState.defaultValues?.[field] !== fieldValue &&
    !isSubmittingPricingSettings;

  const submit = useCallback(() => {
    if (fieldError) {
      return;
    }
    if (fieldHasChanged) {
      // Only submit if Enter is hit in the box that has changes
      setValue('isSubmittingPricingSettings', true);
      setValue('isSubmittingRowIndex', rowIndex);
    }
  }, [fieldError, fieldHasChanged, setValue, rowIndex]);

  useEffect(() => {
    submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPricingEnabled]);

  return (
    <TableCellDiv align="right" showTooltip={false}>
      {editable &&
        (!canAutoPrice || !hasVenueMapInfo ? (
          <Stack direction="row" gap="s" alignItems="center">
            <TooltipPopover
              variant="link"
              contentVariant="dark"
              triggerContent={
                <WarningOutlineIcon
                  fill={'#FD9927'}
                  size={vars.iconSize.m}
                  withHoverEffect
                />
              }
            >
              {!canAutoPrice ? (
                <Content id={ContentId.NoAutoPricing_MissingPermission} />
              ) : mapExists && !hasVenueMapInfo ? (
                <Content id={ContentId.NoAutoPricing_MissingRowId} />
              ) : (
                <Content id={ContentId.NoAutoPricing_NoVenueConfig} />
              )}
            </TooltipPopover>
            <Content id={ContentId.Off} />
          </Stack>
        ) : (
          <Stack direction="row" gap="s" alignItems="center">
            <Switch
              checked={autoPricingEnabled ?? false}
              disabled={isDisabled}
              onClick={(e: ChangeEvent<HTMLElement>) => e.stopPropagation()}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked !== autoPricingEnabled) {
                  if (isChecked) {
                    // If enabled, set defaults where necessary
                    setAutoPricingDefaults();
                  }

                  setValue('autoPricingEnabled', isChecked);
                }
              }}
            />
            <Content id={autoPricingEnabled ? ContentId.On : ContentId.Off} />
          </Stack>
        ))}
    </TableCellDiv>
  );
};
