import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const PurchaseAccordionContent = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  .accordion {
    height: 100%;
  }
`;

export const PurchaseAccordionItemContainer = styled.div<{
  isExpanded: boolean;
}>`
  .accordion-item {
    border-radius: 0;
    border: 0;
    padding: ${() => `${vars.spacing.xxs} ${vars.spacing.xl}`};

    .accordion-body {
      padding: ${() => vars.spacing.sm};
      width: 100%;
      overflow-x: auto;
      width: 100%;
      border-radius: ${() => `0 0 ${vars.spacing.sm} ${vars.spacing.sm}`};
      border: ${(props) =>
        props.isExpanded
          ? '2px solid ' + props.theme.colors.borderActive
          : '1px solid ' + props.theme.colors.borderDark};
      border-top: 0;
      background: ${({ theme }) => theme.colors.backgroundPrimary};
    }

    .accordion-button {
      &:focus {
        box-shadow: none;
      }
      padding: ${() =>
        `${vars.spacing.lg} ${vars.spacing['3xl']} ${vars.spacing.lg} ${vars.spacing.lg}`};
      width: 100%;

      border-radius: ${(props) =>
        props.isExpanded
          ? `${vars.borderRadius.m} ${vars.borderRadius.m} 0 0`
          : vars.borderRadius.m};

      border: ${(props) =>
        props.isExpanded
          ? '2px solid ' + props.theme.colors.borderActive
          : '1px solid ' + props.theme.colors.borderDark};

      ${(props) => props.isExpanded && 'border-bottom: 0'};

      background-color: ${(props) =>
        props.isExpanded
          ? props.theme.colors.backgroundSecondary
          : props.theme.colors.backgroundPrimary};

      color: ${(props) => props.theme.colors.textPrimary};

      &:hover {
        background-color: ${(props) =>
          props.isExpanded
            ? props.theme.colors.backgroundSecondary
            : props.theme.colors.primary700};

        color: ${(props) =>
          props.isExpanded
            ? props.theme.colors.textBrand
            : props.theme.colors.textDarker};
      }

      ${({ theme: { media } }) =>
        `${media.makeMediaQuery(
          media.breakpoints.md,
          `padding: ${vars.spacing.xl} ${vars.spacing['5xl']} ${vars.spacing.xl} ${vars.spacing.lg};`
        )}`}

      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%)
          ${(props) => (!props.isExpanded ? 'rotate(-90deg)' : '')};
        right: ${() => vars.spacing.xxs};

        ${({ theme: { media } }) =>
          `${media.makeMediaQuery(
            media.breakpoints.md,
            `right: ${vars.spacing.lg};`
          )}`}
      }

      transition: all 0.2s;

      .name {
        font-weight: 600;
      }
    }
  }
`;

export const PurchaseAccordionDividerContainer = styled.div<{
  showShadow: boolean;
}>`
  position: absolute;
  overflow: hidden;
  width: 100%;
  background-color: transparent;
  padding-left: ${() => vars.spacing.sm};
  z-index: 2;
  box-shadow: ${(props) =>
    props.showShadow
      ? `0px 8px 8px -4px ${props.theme.colors.textDisabled}`
      : 'none'};
`;

export const PurchaseAccordionDividerContainerWithBackground = styled.div<{
  hasScrollBar: boolean;
}>`
  background-color: white;
  width: calc(100% - ${(props) => (props.hasScrollBar ? '16px' : '0px')});
  height: 40px;
  display: flex;
  justify-content: space-between;
`;

export const ItemsCountDiv = styled.div`
  display: flex;
  gap: ${() => vars.spacing.sm};
  font-style: italic;
  text-transform: lowercase;
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('caption1')};
`;

export const TotalCostDiv = styled.div`
  text-align: right;
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title6')};
`;

export const PurchaseItemHeaderDateDisplay = styled.div`
  color: ${(props) => props.theme.colors.textLinkPrimary};
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title5', 'bold')};
`;
