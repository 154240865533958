import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import {
  Event,
  Feature,
  ListingClient,
  ListingGroupClient,
} from 'src/WebApiController';

export const useGetCompListings = (
  event?: Event | null,
  selectedListingId?: number,
  selectedListingGroupId?: string | null
) => {
  const hasGroupCompsFeature = useUserHasFeature(Feature.ListingGroupCompUi);

  const [showRelatedListings, setShowRelatedListings] = useState(false);

  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    event?.viagId != null;

  const compListingsQuery = useQuery({
    queryKey: [
      'ListingClient.getCompListingsByEventId',
      activeAccountWebClientConfig,
      event?.viagId,
      showRelatedListings,
      selectedListingId,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new ListingClient(
        activeAccountWebClientConfig
      ).getCompListingsByEventId(
        event!.viagId!,
        selectedListingId,
        showRelatedListings
      );
    },

    enabled: shouldQuery,
    staleTime: DATA_REFRESH_RATE_IN_MILLIS_LONG,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('ListingClient.getCompListingsByEventId', error, {
          trackErrorData: {
            viagogoEventId: event?.viagId,
            selectedListingId,
            showRelatedListings,
          },
        });
      },
    },
  });

  const shouldQueryListingGroupsQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    event?.viagId != null &&
    selectedListingGroupId != null &&
    hasGroupCompsFeature;

  const compListingsGroupQuery = useQuery({
    queryKey: [
      'ListingGroupClient.getListingGroupCompData',
      activeAccountWebClientConfig,
      event?.viagId,
      selectedListingGroupId,
      selectedListingId,
    ],
    queryFn: () => {
      if (!shouldQueryListingGroupsQuery) {
        return null;
      }

      return new ListingGroupClient(
        activeAccountWebClientConfig
      ).getListingGroupCompData(event!.viagId!, selectedListingGroupId);
    },

    enabled: shouldQueryListingGroupsQuery,
    staleTime: DATA_REFRESH_RATE_IN_MILLIS_LONG,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('ListingGroupClient.getListingGroupCompData', error, {
          trackErrorData: {
            viagogoEventId: event?.viagId,
            selectedListingId,
          },
        });
      },
    },
  });

  return {
    showRelatedListings,
    setShowRelatedListings,
    compListingsQuery,
    compListingsGroupQuery,
  };
};
