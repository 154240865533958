import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ReloadButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { Button, Stack } from 'src/core/ui';
import { PurchaseVendorDeliveryOverrideInput } from 'src/dialogs/PurchaseVendorDialog';
import { useGetVendor } from 'src/hooks/api/useGetVendor';
import { useAddVendor } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useAddVendor';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseVendor } from 'src/WebApiController';

interface VendorAutoFulfillProps {
  vendor: PurchaseVendor;
}

export const VendorDeliveryOverrideForm = ({
  vendor,
}: VendorAutoFulfillProps) => {
  const methods = useForm<PurchaseVendor>({
    defaultValues: vendor,
    values: vendor,
  });

  useEffect(() => {
    methods.reset(vendor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor]);

  const { isFetching } = useGetVendor(vendor.id);
  const { onSaveVendor, isLoading: isMutationLoading } = useAddVendor();

  const onReset = useCallback(() => {
    methods.reset();
  }, [methods]);

  const {
    formState: { isDirty },
  } = methods;

  return (
    <FormProvider {...methods}>
      <Stack direction="column" gap="m">
        <PurchaseVendorDeliveryOverrideInput collapsible />
        {isDirty && (
          <Stack direction="row" gap="m" width="full" alignItems="end">
            <ReloadButton
              textContentId={ContentId.Reset}
              variant={'outline'}
              disabled={isMutationLoading || isFetching}
              onClick={onReset}
            />

            <Button
              onClick={() => {
                onSaveVendor(methods.getValues());
              }}
              disabled={isMutationLoading || isFetching}
            >
              <Content id={ContentId.Save} />
            </Button>
          </Stack>
        )}
      </Stack>
    </FormProvider>
  );
};
