import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { getCurrentViewPath } from 'src/utils/deepLinkUtils';
import { ApiException, LoginClient, LoginContext } from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as styles from './Login.css';

export function Login() {
  return (
    <LayoutContent mainRoute={MainRoute.Login}>
      <LoginPage />
    </LayoutContent>
  );
}

function LoginPage() {
  const location = useMemo(() => new URL(window.location.href), []);
  const reactRouterLocation = useLocation();
  const returnUrl = (reactRouterLocation.state as { returnUrl?: string })
    ?.returnUrl;
  const { trackError } = useErrorBoundaryContext();
  const navigate = useNavigate();

  const handleLoginContext = useCallback(
    (loginContext: LoginContext) => {
      if ((loginContext?.authorizationUrl?.length || 0) > 0) {
        window.location.assign(loginContext.authorizationUrl!);
      } else {
        let returnUrl = location?.searchParams?.get('returnUrl');
        if (!returnUrl) {
          returnUrl = getCurrentViewPath(MainRoute.Home);
        }
        if (returnUrl!.startsWith('https://')) {
          // to be safe we should only redirect to full HTTPS
          window.location.assign(returnUrl!);
        } else {
          navigate(returnUrl!);
        }
      }
    },
    [location?.searchParams, navigate]
  );

  const onLoggingInUser = useCallback(async () => {
    try {
      const loginContext = await new LoginClient({}).getLoginContext(
        returnUrl || '/home'
      ); // after we authorize we want to return here

      handleLoginContext(loginContext);
    } catch (error) {
      // When getting a 401 response, we go this route
      try {
        const defaultLoginContext = JSON.parse(
          (error as ApiException)?.response
        ) as LoginContext;

        handleLoginContext(defaultLoginContext);
      } catch (jsonError) {
        trackError('LoginClient.getLoginContext', jsonError as ErrorTypes, {
          error,
        });
      }
    }
  }, [handleLoginContext, returnUrl, trackError]);

  useEffect(() => {
    onLoggingInUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div className={styles.loginContainer}></div>;
}
