import { useContent } from 'src/contexts/ContentContext';
import { FilterInput } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/FilterInput';
import { ContentId } from 'src/utils/constants/contentId';

interface FilterVendorAccountsInputProps {
  disable?: boolean;
  onSearchTextChanged: (searchText: string) => void;
}

export const FilterVendorAccountsInput = ({
  disable,
  onSearchTextChanged,
}: FilterVendorAccountsInputProps) => {
  const vendorAccounts = useContent(ContentId.VendorAccounts);
  return (
    <FilterInput
      disable={disable}
      onSearchTextChanged={onSearchTextChanged}
      searchEntityLabel={vendorAccounts}
    />
  );
};
