import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { IconsFill, InfoOutlineIcon } from 'src/svgs/Viagogo';

import * as styles from './MessageWithIcon.css';

export const InfoMessage = ({
  message,
  iconSize = vars.iconSize.l,
  style,
}: {
  message: string | JSX.Element;
  iconSize?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <Stack
      direction="row"
      gap="m"
      className={styles.messageContainer}
      style={style}
    >
      <InfoOutlineIcon fill={IconsFill.textPrimary} size={iconSize} />
      <div className={styles.messageDiv}>{message}</div>
    </Stack>
  );
};
