import { RecipeVariants } from '@vanilla-extract/recipes';
import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react';
import { mergeProps } from 'src/core/utils';

import * as styles from './CountBadge.css';

export const CountBadge = forwardRef<
  HTMLDivElement,
  Omit<
    ComponentPropsWithoutRef<'div'>,
    'onChange' | 'onKeyDown' | 'onKeyUp' | 'children'
  > &
    RecipeVariants<typeof styles.countBadge.badge> & {
      count: number;
      color?: 'green' | 'red' | 'gray' | 'brand' | 'brandLight';
    }
>(function Root({ count, color = 'brand', ...props }, ref): ReactElement {
  return (
    <div
      ref={ref}
      {...mergeProps(
        {
          className: styles.countBadge.badge({
            color,
          }),
        },
        props
      )}
    >
      {count}
    </div>
  );
});
