import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const SingleFileUploadWithPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${() => `${vars.spacing.lg}`};
  &:not(:last-child) {
    margin-bottom: ${() => vars.spacing.lg};
  }

  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  &:not(:last-child) {
    margin-bottom: ${() => vars.spacing.lg};
  }
`;

export const PageImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
  padding: ${() => vars.spacing.lg};

  &:not(:last-child) {
    margin-bottom: ${() => vars.spacing.lg};
  }
`;

export const UploadFailure = styled.div`
  color: ${(props) => props.theme.colors.textNegative};
`;
