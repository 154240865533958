
import { AdCampaignQueryParam } from './constants/constants';

export const getAdCampaignUrl = (
  adCampaignId: string,
  includeSearchParams = true
): string => {
  let searchParams = '';
  if (includeSearchParams) {
    const url = new URL(window.location.href);
    url.searchParams.set(AdCampaignQueryParam, adCampaignId);
    searchParams = `?${url.searchParams.toString()}`;
  }
  return `/sponsoredlistings/adcampaign${searchParams}`;
};
