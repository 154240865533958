import styled from 'styled-components/macro';

export const FormTable = styled.table`
  border-collapse: collapse;

  th {
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
  }

  td,
  th {
    border: 0px solid transparent;
    background-clip: padding-box;
    vertical-align: middle;
  }

  tr > td + td,
  tr > th + th {
    border-left-width: 16px;
  }

  tr + tr > td {
    border-top-width: 8px;
  }
`;
