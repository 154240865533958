import { ReactNode, useMemo } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { useGetFulfillmentProviderSettings } from 'src/hooks/useGetFulfillmentProviderSettings';
import { MapFulfillmentProviderToIcon } from 'src/navigations/Routes/Settings/Views/FulfillmentProviders';
import {
  EMPTY_SELLER_USER_ID,
  FULFILLMENT_PROVIDER_SELLER_USER_IDS,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';

export const SellerAccountEmployeeSelector = ({
  placeholderText,
  ...props
}: Omit<PosSelectProps, 'valueOptionsContent'> & {
  showEmail?: boolean;
  allowUnchanged?: boolean;
  isFulfillmentSection?: boolean;
}) => {
  const { allActiveUserInfos } = useSellerAccountContext();
  const unchanged = useContent(ContentId.DontChange);
  const { fulfillmentProviderSettings } = useGetFulfillmentProviderSettings();

  const allActiveUserOptions = useMemo(() => {
    if (!allActiveUserInfos) return {};

    const results = {} as Record<string, string>;
    if (props.allowUnchanged) {
      results[EMPTY_SELLER_USER_ID] = unchanged;
    }

    if (props.isFulfillmentSection) {
      if (fulfillmentProviderSettings?.length) {
        fulfillmentProviderSettings.forEach((provider) => {
          if (provider.isEnabled) {
            results[provider.fulfillmentProviderSellerUserId] =
              provider.fulfillmentProviderName;
          }
        });
      }
    }

    Object.values(allActiveUserInfos).reduce((r, cur) => {
      r[cur.userId] = props.showEmail
        ? `${cur.displayName} (${cur.email})`
        : cur.displayName;
      return r;
    }, results);

    return results;
  }, [
    allActiveUserInfos,
    fulfillmentProviderSettings,
    props.allowUnchanged,
    props.isFulfillmentSection,
    props.showEmail,
    unchanged,
  ]);

  const allActiveValueOptionIcons = useMemo(() => {
    if (!allActiveUserOptions) return {};

    const results = {} as Record<string, ReactNode>;
    for (const [sellerUserId, displayName] of Object.entries(
      allActiveUserOptions
    )) {
      if (FULFILLMENT_PROVIDER_SELLER_USER_IDS.has(sellerUserId)) {
        results[sellerUserId] = MapFulfillmentProviderToIcon(displayName);
      }
    }

    return results;
  }, [allActiveUserOptions]);

  return (
    <PosSelect
      {...props}
      searchable={
        props.searchable != null
          ? props.searchable
          : Object.values(allActiveUserOptions).length > 10
      }
      sortFn={(sellerIdA, sellerIdB) => {
        if (sellerIdA === EMPTY_SELLER_USER_ID) {
          return -1;
        }
        if (sellerIdB === EMPTY_SELLER_USER_ID) {
          return 1;
        }
        const isFulfillmentA =
          FULFILLMENT_PROVIDER_SELLER_USER_IDS.has(sellerIdA);
        const isFulfillmentB =
          FULFILLMENT_PROVIDER_SELLER_USER_IDS.has(sellerIdB);

        if (!isFulfillmentA && !isFulfillmentB) {
          return (allActiveUserOptions[sellerIdA] ?? '').localeCompare(
            allActiveUserOptions[sellerIdB] ?? ''
          );
        }

        if (isFulfillmentA && !isFulfillmentB) {
          return -1;
        } else if (!isFulfillmentA && isFulfillmentB) {
          return 1;
        } else if (isFulfillmentA && isFulfillmentB) {
          const aDisplayName = allActiveUserOptions[sellerIdA];
          const bDisplayName = allActiveUserOptions[sellerIdB];
          if (aDisplayName < bDisplayName) {
            return -1;
          } else if (aDisplayName > bDisplayName) {
            return 1;
          }
        }
        return 0;
      }}
      loading={allActiveUserInfos == null}
      placeholderText={placeholderText ?? ContentId.Everyone}
      valueOptionsContent={allActiveUserOptions}
      valueOptionsIcon={allActiveValueOptionIcons}
      sortBy="key"
    />
  );
};
