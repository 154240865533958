import { ReactNode, useEffect } from 'react';
import { useEventFlattedViewSelectedLegend } from 'src/components/Events/EventListing/useEventFlattedViewSelectedLegend';
import { useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { ContentId } from 'src/utils/constants/contentId';

interface SetInventoryFlattenedViewSelectionCountLabelProps {
  children: ReactNode;
}

export const SetInventoryFlattenedViewSelectionCountLabel = ({
  children,
}: SetInventoryFlattenedViewSelectionCountLabelProps) => {
  const listingText = useContent(ContentId.Listing);
  const listingsText = useContent(ContentId.Listings);
  const { setSelectedCountLabel, setUsingCustomSelectedCountLabel } =
    useMultiSelectionContext();

  useEffect(() => {
    setUsingCustomSelectedCountLabel(true);
  }, [setUsingCustomSelectedCountLabel]);

  const selectedLegend = useEventFlattedViewSelectedLegend({
    singleElementLabel: listingText,
    multipleElementsLabel: listingsText,
  });

  useEffect(() => {
    setSelectedCountLabel(selectedLegend);
  }, [selectedLegend, setSelectedCountLabel]);

  return <>{children}</>;
};
