import { isEqual } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  EventMapContextProvider,
  useEventMapContext,
} from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getCompleteEventConfigScoreOverrides,
  getEventConfigScoreOverride,
} from 'src/utils/seatScoreUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Event,
  EventConfigScoreOverride,
  Feature,
  PricingClient,
} from 'src/WebApiController';

import { CancellableFormFooter } from '../common';
import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from '../common/EventEntityHeader';
import { modalDetailsMaxHeight } from '../common/Modals.css';
import { ModalBody, ModalFooter, ModalProps } from '../Modal';
import { EventSeatMapConfigBody } from './EventSeatMapConfigBody';

type EventSeatMapConfigProps = {
  event: Event;
  eventConfigScoreOverride: EventConfigScoreOverride | null;
  onSaveConfigOverride?: (
    eventConfigScoreOverride: EventConfigScoreOverride
  ) => Promise<void | null>;
  cancelTo: ModalProps;
  defaultConfigPayload?: string | undefined;
  isOverrideTemplate: boolean;
};

export const EventSeatMapConfig = ({
  event,
  eventConfigScoreOverride,
  onSaveConfigOverride,
  cancelTo,
  defaultConfigPayload,
  isOverrideTemplate,
}: EventSeatMapConfigProps) => {
  const methods = useForm<EventConfigScoreOverride>({
    defaultValues: undefined,
  });

  return (
    <FormProvider {...methods}>
      <EventMapContextProvider event={event}>
        <EventSeatMapConfigContent
          {...methods}
          cancelTo={cancelTo}
          event={event}
          eventConfigScoreOverride={eventConfigScoreOverride}
          onSaveConfigOverride={onSaveConfigOverride}
          defaultConfigPayload={defaultConfigPayload}
          isOverrideTemplate={isOverrideTemplate}
        />
      </EventMapContextProvider>
    </FormProvider>
  );
};

const EventSeatMapConfigContent = ({
  eventConfigScoreOverride,
  onSaveConfigOverride,
  cancelTo,
  watch,
  getValues,
  formState,
  handleSubmit,
  reset,
  defaultConfigPayload,
  isOverrideTemplate,
}: Omit<
  ComponentProps<typeof FormProvider<EventConfigScoreOverride, unknown>>,
  'children'
> &
  EventSeatMapConfigProps) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const hasScaledSeatScoreFeature = useUserHasFeature(Feature.ScaledSeatScore);
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { event, venueMapsByScoreModelQuery } = useEventMapContext();
  const { setModal, closeModal } = useContext(ModalContext);
  const newModelName = useContent(ContentId.NewModel);

  useEffect(() => {
    if (
      formState.defaultValues === undefined &&
      event &&
      (eventConfigScoreOverride ??
        venueMapsByScoreModelQuery.data?.AdvancedVenueGeometry)
    ) {
      reset(
        getEventConfigScoreOverride(
          event.viagId!,
          venueMapsByScoreModelQuery.data?.AdvancedVenueGeometry!,
          newModelName,
          eventConfigScoreOverride,
          false,
          undefined,
          defaultConfigPayload,
          isOverrideTemplate
        )
      );
    }
  }, [
    eventConfigScoreOverride,
    formState.defaultValues,
    newModelName,
    reset,
    venueMapsByScoreModelQuery.data?.AdvancedVenueGeometry,
    defaultConfigPayload,
    isOverrideTemplate,
    event,
  ]);

  const onSubmit = useCallback(
    async (eventForm: EventConfigScoreOverride) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const client = new PricingClient(activeAccountWebClientConfig);

          const newConfigOverride = {
            ...eventForm,
            scoreOverrides: getCompleteEventConfigScoreOverrides(
              venueMapsByScoreModelQuery.data?.AdvancedVenueGeometry
                ?.sectionScores,
              eventForm.scoreOverrides,
              isOverrideTemplate,
              hasScaledSeatScoreFeature
            )!,
          };

          const newId =
            await client.upsertEventSeatMapScoreOverride(newConfigOverride);

          if (onSaveConfigOverride) {
            eventForm.id = newId;
            await onSaveConfigOverride(eventForm);
          }
          if (cancelTo) {
            setModal(cancelTo);
          } else {
            closeModal(true);
          }
        },
        (error) => {
          showErrorDialog('EventClient.updatePriceSettings', error, {
            trackErrorData: eventForm,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      cancelTo,
      closeModal,
      hasScaledSeatScoreFeature,
      onSaveConfigOverride,
      setModal,
      showErrorDialog,
      isOverrideTemplate,
      venueMapsByScoreModelQuery.data?.AdvancedVenueGeometry?.sectionScores,
    ]
  );

  const hasChanges = useCallback(
    (eventForm: EventConfigScoreOverride) => {
      if (!isEqual(eventForm, formState.defaultValues)) return true;

      return false;
    },
    [formState.defaultValues]
  );

  const onSubmitHandler = useCallback(() => {
    const eventForm = getValues();
    if (!hasChanges(eventForm)) return;

    handleSubmit(onSubmit)();
  }, [getValues, handleSubmit, hasChanges, onSubmit]);

  const formHasChanges = hasChanges(watch());

  return (
    <>
      <CancellableFormHeader
        cancelTo={cancelTo}
        disabled={isLoading || formState.isSubmitting}
        showDialogOnCancel={formHasChanges}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.SeatHeatmap} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        <div className={modalDetailsMaxHeight}>
          <EventSeatMapConfigBody
            disabled={isLoading || formState.isSubmitting}
            cancelTo={cancelTo}
            isOverrideTemplate={isOverrideTemplate}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          cancelTo={cancelTo}
          disabled={isLoading}
          showDialogOnCancel={formHasChanges}
        >
          <OkButton
            onClick={onSubmitHandler}
            disabled={isLoading || !formHasChanges}
            textContentId={ContentId.Save}
          />
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
