import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Event, Feature, Listing } from 'src/WebApiController';

import { InventoryEventTabs } from './InventoryEventPage';
import { MarketListingsForm } from './MarketListings/MarketListings';
import { MarketListingWithAutoPricingForm } from './MarketListings/MarketListingWithAutoPricing';
import { StubHubListingsWithManualPricing } from './MarketListings/StubHubListingsWithManualPricing';

type InventoryEventPageRightPanelProps = {
  event: Event;
  listings: Listing[] | null | undefined;
  selectedListingIds: number[];
  activeTab: InventoryEventTabs;
};

export const InventoryEventPageRightPanel: React.FC<
  InventoryEventPageRightPanelProps
> = ({ event, listings, selectedListingIds, activeTab }) => {
  const hasMergeSimplifiedUIFeature = useUserHasFeature(
    Feature.MergeSimplifiedUI
  );

  if (activeTab === InventoryEventTabs.ManualPricing) {
    return (
      <StubHubListingsWithManualPricing
        event={event}
        eventListings={listings}
        selectedListingIds={selectedListingIds}
      />
    );
  }

  if (hasMergeSimplifiedUIFeature) {
    return (
      <MarketListingsForm
        event={event}
        eventListings={listings}
        selectedListingIds={selectedListingIds}
      />
    );
  }

  return (
    <MarketListingWithAutoPricingForm
      event={event}
      eventListings={listings}
      selectedListingIds={selectedListingIds}
    />
  );
};
