import { ColumnDef } from '@tanstack/react-table';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { getAllInPriceFromListPrice } from 'src/utils/inventoryUtils';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { sortByNumericPrefix } from 'src/utils/tableUtils';
import { Marketplace, MarketTransaction } from 'src/WebApiController';

import * as styles from '../MarketTransactionsTable.css';

type MarketTransactionTableAdditionalInformation = {
  activeAccountUserId: string | null | undefined;
};
export type MarketTransactionColumnType =
  | (MarketTransaction & MarketTransactionTableAdditionalInformation)
  | null;

export const MARKET_TRANSACTIONS_TABLE_COLUMNS_CONFIG: ColumnDef<MarketTransactionColumnType>[] =
  [
    {
      id: 'yourListing',
      header: () => (
        <TableHeaderCellDiv align="left">{/** No header */}</TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.sellerUserId == data?.activeAccountUserId,
      cell: ({ getValue, row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }
        const value = getValue() as string;
        if (!value) {
          return (
            <TableCellDiv align="left" style={{ textTransform: 'none' }} />
          );
        }

        return (
          <TableCellDiv align="left" style={{ textTransform: 'none' }}>
            <div className={styles.badge}>
              <Content id={ContentId.YourListing} />
            </div>
          </TableCellDiv>
        );
      },
      sortingFn: sortByNumericPrefix<MarketTransactionColumnType>,
    },
    {
      id: 'section',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Section} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.section,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="left" style={{ textTransform: 'none' }}>
            {value}
          </TableCellDiv>
        );
      },
      sortingFn: sortByNumericPrefix<MarketTransactionColumnType>,
    },
    {
      id: 'row',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Row} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.row,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="left" style={{ textTransform: 'none' }}>
            {value}
          </TableCellDiv>
        );
      },
      sortingFn: sortByNumericPrefix<MarketTransactionColumnType>,
    },
    {
      id: 'seat',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Seat} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => {
        if (data?.seatTo) {
          return `${data.seatFrom}-${data.seatTo}`;
        }
        return data?.seatFrom;
      },
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="left" style={{ textTransform: 'none' }}>
            {value}
          </TableCellDiv>
        );
      },
    },
    {
      id: 'quantity',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Quantity} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.quantityPurchased,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as number;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="left">{value}</TableCellDiv>;
      },
    },
    {
      id: 'price',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.WebsitePrice} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) =>
        (data?.sellerNetProceeds ?? 0) / (data?.quantityPurchased ?? 1),
      cell: function Cell({ getValue, row: { original } }) {
        const listPrice = getValue() as number;
        const { getUiCurrency } = useLocalizationContext();

        const { loginContext } = useAppContext();
        if (!original) {
          return <TableShimmeringDiv />;
        }

        const sellerFee =
          loginContext?.user?.activeAccount?.marketplaceSettings?.find(
            (m) => m.mkp === Marketplace.StubHub
          )?.sellerFee ?? 0;
        const currency = getUiCurrency(original.sellerCurrencyCode);

        const websitePrice = getAllInPriceFromListPrice(
          listPrice,
          null,
          sellerFee
        );

        return (
          <TableCellDiv align="left">
            {applyNumberFormatting({
              inputNumber: websitePrice,
              currencyCode: currency.code,
              currencyDecimalDigits: currency.dec,
            })}
          </TableCellDiv>
        );
      },
    },
  ];
