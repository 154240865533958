import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useContext } from 'react';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { MoneyClient, UiCurrency } from 'src/WebApiController';

type ILocalizationContext = {
  currencyCache: Record<string, UiCurrency>;
  getUiCurrency: (currencyCode: string | null | undefined) => UiCurrency;
};

export const LocalizationContext = React.createContext<ILocalizationContext>({
  currencyCache: {},
  getUiCurrency: () =>
    ({
      code: 'USD',
      sym: '$',
      dec: 2,
    }) as UiCurrency,
});

export const useLocalizationContext = () => useContext(LocalizationContext);

/**
 * Adds a modal to the page where the provider is added
 * Provides utils to set all or update part of the modal
 * The modal is assumed to be open when it contains content
 */
export const LocalizationContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { trackError } = useErrorBoundaryContext();

  const availableCurrenciesQuery = useQuery({
    queryKey: ['getAvailableCurrencies'],
    queryFn: async () => {
      const results = await new MoneyClient({}).getAvailableCurrencies();

      const resultsMap = results?.reduce(
        (r, cur) => {
          r[cur.code] = cur;
          return r;
        },
        {} as Record<string, UiCurrency>
      );

      return resultsMap;
    },

    staleTime: Infinity, // currencies don't change often and we're invalidate on reload
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('MoneyClient.getAvailableCurrencies', error);
      },
    },
  });

  const getUiCurrency = useCallback(
    (currencyCode: string | null | undefined) => {
      if (!currencyCode || !availableCurrenciesQuery.data) {
        return {
          code: currencyCode ?? '',
          sym: currencyCode ?? '',
          dec: 2,
        } as UiCurrency;
      }

      return (
        availableCurrenciesQuery.data[currencyCode] ??
        ({
          code: currencyCode ?? '',
          sym: currencyCode ?? '',
          dec: 2,
        } as UiCurrency)
      );
    },
    [availableCurrenciesQuery.data]
  );

  return (
    <LocalizationContext.Provider
      value={{
        currencyCache: availableCurrenciesQuery.data ?? {},
        getUiCurrency: getUiCurrency,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
