import 'swiper/swiper.min.css';

import { PropsWithChildren, ReactNode, useRef } from 'react';
import { FlyoverPanel } from 'src/core/POS/FlyoverPanel/FlyoverPanel';
import {
  ResizablePanel,
  ResizablePanelProps,
  ResizeEdge,
} from 'src/core/ui/ResizablePanel/ResizablePanel';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import Swiper, { Scrollbar } from 'swiper';
import { Swiper as SwiperComponent, SwiperSlide } from 'swiper/react';

import * as styles from './ExplorerLayout.css';

export function ExplorerLayout({
  leftPanelContent,
  leftPanelFlyover,
  rightPanelContent,
  rightPanelFlyover,
  autoHide,
  children,
  initialSlide,
  swiperRef,
  ...rest
}: PropsWithChildren<
  Omit<ResizablePanelProps, 'direction'> & {
    leftPanelContent?: ReactNode;
    leftPanelFlyover?: boolean;
    rightPanelContent?: ReactNode;
    rightPanelFlyover?: boolean;
    initialSlide?: number;
    swiperRef?: React.MutableRefObject<Swiper | undefined>;
  }
>) {
  const mainRef = useRef<HTMLDivElement>(null);
  const mobile = useMatchMedia('mobile');

  if (mobile) {
    return (
      <div className={styles.layoutContainer} ref={mainRef}>
        <SwiperComponent
          onSwiper={(swiper) => {
            if (swiperRef) {
              swiperRef.current = swiper;
            }
          }}
          allowTouchMove={false}
          initialSlide={initialSlide ?? leftPanelContent ? 1 : 0}
          touchReleaseOnEdges
          slidesPerView={'auto'}
          scrollbar={{
            hide: true,
            snapOnRelease: true,
          }}
          focusableElements="input, select, option, textarea, button, video, label, span"
          modules={[Scrollbar]}
          className={styles.swiper}
        >
          {leftPanelContent && (
            <SwiperSlide>
              <div className={styles.subPanel}>{leftPanelContent}</div>
            </SwiperSlide>
          )}
          <SwiperSlide>
            <div className={styles.mainPanel}>{children}</div>
          </SwiperSlide>
          {rightPanelContent && (
            <SwiperSlide>
              <div className={styles.subPanel}>{rightPanelContent}</div>
            </SwiperSlide>
          )}
        </SwiperComponent>
      </div>
    );
  }

  const subPanel = (
    <div className={styles.subPanel}>
      {leftPanelContent ?? rightPanelContent}
    </div>
  );

  return (
    <div className={styles.layoutContainer} ref={mainRef}>
      {leftPanelContent &&
        (leftPanelFlyover ? (
          <FlyoverPanel>{subPanel}</FlyoverPanel>
        ) : (
          <ResizablePanel
            direction={ResizeEdge.East}
            autoHide={autoHide}
            {...rest}
          >
            {subPanel}
          </ResizablePanel>
        ))}

      <div className={styles.mainPanel}>{children}</div>

      {rightPanelContent &&
        (rightPanelFlyover ? (
          <FlyoverPanel>{subPanel}</FlyoverPanel>
        ) : (
          <ResizablePanel
            direction={ResizeEdge.West}
            autoHide={autoHide}
            {...rest}
          >
            {subPanel}
          </ResizablePanel>
        ))}
    </div>
  );
}
