import { cloneElement } from 'react';
import { vars } from 'src/core/themes';
import { Stack, Tooltip } from 'src/core/ui';
import { IconsFill } from 'src/svgs/Viagogo';
import { ListingNoteType } from 'src/WebApiController';

export const ListingNoteBadge = ({
  listingNotes,
  icon,
  type,
}: {
  listingNotes?: string[];
  icon: JSX.Element;
  type: ListingNoteType;
}) => {
  const styledIcon = cloneElement(icon, {
    size: vars.iconSize.m,
    fill:
      type === ListingNoteType.Defect
        ? IconsFill.textWarning
        : IconsFill.textPrimary,
  });

  const content = <Stack alignItems="center">{styledIcon}</Stack>;

  return listingNotes ? (
    <Tooltip triggerContent={content}>
      <Stack direction="column" style={{ padding: vars.spacing['sm'] }}>
        {listingNotes?.map((note, idx) => <span key={idx}>{`• ${note}`}</span>)}
      </Stack>
    </Tooltip>
  ) : null;
};
