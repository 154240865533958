import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { AutoPoClient } from 'src/WebApiController';

export function useGetInboundEmailSellerGuidIds() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  return useQuery({
    queryKey: [
      'getInboundEmailSellerGuidIds',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return tryInvokeApi(
        () => {
          return new AutoPoClient(
            activeAccountWebClientConfig
          ).getInboundEmailSellerGuidIds();
        },
        (error) => {
          trackError('AutoPoClient.getInboundEmailSellerGuidIds', error);
        }
      );
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
  });
}
