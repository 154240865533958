import { ComponentProps, useCallback, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { Button } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { ContentId } from 'src/utils/constants/contentId';
import { divideDateInterval } from 'src/utils/dateTimeUtils';
import { newBigIntId } from 'src/utils/idUtils';
import {
  PurchasePayment,
  PurchasePaymentMethodType,
  PurchasePaymentStatus,
  UiMoney,
} from 'src/WebApiController';

import { TableOverflowWrapper } from './AddPurchasePaymentDialog.styled';
import {
  AddPurchasePaymentTable,
  AddPurchasePaymentTableFieldValues,
} from './components/AddPurchasePaymentTable';
import { AddPurchasePaymentTiles } from './components/AddPurchasePaymentTiles';
import {
  GeneratePurchasePaymentsForm,
  useGeneratePurchasePaymentsForm,
} from './components/GeneratePurchasePaymentsForm';

export type AddPurchasePaymentDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  defaultCurrencyCode: string;
  onSave: (data: PurchasePayment[]) => void;
  onCancel: () => void;
};

export function AddPurchasePaymentDialog({
  defaultCurrencyCode,
  onSave,
  onCancel,
  ...genericDialogProps
}: AddPurchasePaymentDialogProps) {
  const { getUiCurrency } = useLocalizationContext();

  const requiredMsg = useContent(ContentId.Required);
  const [isSinglePayment, setIsSinglePayment] = useState(true);
  const getDefaultSinglePaymentStore = () => ({
    currencyCode: defaultCurrencyCode,
    isPaid: true,
  });
  const getDefaultMultiplePaymentsStore = () => [];
  const [singlePaymentStore, setSinglePaymentStore] = useState(
    getDefaultSinglePaymentStore()
  );
  const [multiplePaymentsStore, setMultiplePaymentsStore] = useState<
    AddPurchasePaymentTableFieldValues['payments']
  >(getDefaultMultiplePaymentsStore());

  const paymentsTableForm = useForm<AddPurchasePaymentTableFieldValues>({
    defaultValues: {
      payments: [singlePaymentStore],
    },
  });
  const paymentsTableFieldArray = useFieldArray({
    control: paymentsTableForm.control,
    name: 'payments',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const paymentsTableFormOnSubmit = useCallback(
    paymentsTableForm.handleSubmit((data) => {
      const payments = [];
      for (let i = 0; i < data.payments.length; i++) {
        const payment = data.payments[i];
        if (payment.paymentDueDate === undefined) {
          paymentsTableForm.setError(`payments.${i}.paymentDueDate`, {
            message: requiredMsg,
          });
          return;
        }
        const paymentStatus = payment.isPaid
          ? payment.paymentDate && payment.paymentDate > payment.paymentDueDate
            ? PurchasePaymentStatus.RefundNeeded
            : PurchasePaymentStatus.Paid
          : PurchasePaymentStatus.Unpaid;

        const uiCurrency = getUiCurrency(payment.currencyCode);

        payments.push({
          paymentId: newBigIntId(),
          paymentStatus: paymentStatus,
          paymentDueDate: payment.paymentDueDate.toISOString(),
          paymentDate: payment.paymentDate?.toISOString() ?? null,
          paymentAmount: {
            amt: Number(payment.paymentAmount),
            disp: null,
            currency: uiCurrency.code,
            dec: uiCurrency.dec,
          } as UiMoney,
          currencyCode: payment.currencyCode,
          paymentMethod: {
            id: payment.paymentMethodId!,
            display: '',
            name: '',
            type: PurchasePaymentMethodType.Other,
          },
          convertedPaymentAmount: null,
          convertedCurrencyCode: null,
          conversionRate: null,
          conversionDate: null,
          conversionUserId: null,
        } as PurchasePayment);
      }
      onSave(payments);
    }),
    []
  );

  const generatePaymentsForm = useGeneratePurchasePaymentsForm({
    defaultCurrencyCode,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const generatePaymentsFormOnSubmit = useCallback(
    generatePaymentsForm.handleSubmit(
      ({
        fromDate,
        toDate,
        paymentCount,
        currencyCode,
        paymentAmountType,
        paymentAmount,
        paymentMethodId,
      }) => {
        const dates = divideDateInterval({ fromDate, toDate }, paymentCount);
        const amounts =
          paymentAmountType === 'occurrence'
            ? []
            : dividePaymentAmount(Number(paymentAmount), paymentCount);
        const payments: AddPurchasePaymentTableFieldValues['payments'] = [];
        for (let i = 0; i < paymentCount; i++) {
          payments.push({
            currencyCode,
            paymentAmount:
              paymentAmountType === 'occurrence' ? paymentAmount : amounts[i],
            isPaid: true,
            paymentDueDate: dates[i],
            paymentDate: dates[i],
            paymentMethodId,
          });
        }
        paymentsTableForm.setValue('payments', payments);
      }
    ),
    []
  );

  const state = isSinglePayment
    ? 'singlePayment'
    : paymentsTableForm.getValues('payments').length === 0
    ? 'generatePaymentsForm'
    : 'multiPayment';
  const isMobile = useMatchMedia('mobile');
  return (
    <GenericDialog
      size="xl"
      header={<Content id={ContentId.HowDidYouPay} />}
      footer={
        <>
          {state === 'multiPayment' && (
            <Button
              variant={'outline'}
              onClick={() => {
                paymentsTableForm.reset({
                  payments: getDefaultMultiplePaymentsStore(),
                });
              }}
            >
              <Content id={ContentId.Reset} />
            </Button>
          )}
          <CancelButton onClick={onCancel} />
          <Button
            variant={'regular'}
            onClick={(e) => {
              if (state === 'generatePaymentsForm') {
                generatePaymentsFormOnSubmit(e);
              } else {
                paymentsTableFormOnSubmit(e);
              }
            }}
          >
            {state === 'generatePaymentsForm' ? (
              <Content id={ContentId.Next} />
            ) : (
              <Content id={ContentId.Save} />
            )}
          </Button>
        </>
      }
      onClosed={() => {
        paymentsTableForm.reset();
      }}
      {...genericDialogProps}
      onCancel={onCancel}
    >
      <RadioGroup
        onValueChange={(value) => {
          paymentsTableForm.reset(undefined, { keepValues: true });

          const isSinglePayment = value == ContentId.SinglePayment;
          const values = paymentsTableForm.getValues('payments');
          if (isSinglePayment) {
            setMultiplePaymentsStore(values);
            paymentsTableForm.setValue('payments', [singlePaymentStore]);
          } else {
            setSinglePaymentStore(values[0]);
            paymentsTableForm.setValue('payments', multiplePaymentsStore);
          }
          setIsSinglePayment(isSinglePayment);
        }}
        value={
          isSinglePayment ? ContentId.SinglePayment : ContentId.MultiplePayments
        }
      >
        <Stack direction="column" gap="m">
          <Radio
            value={ContentId.SinglePayment}
            label={<Content id={ContentId.SinglePayment} />}
          />
          <Radio
            value={ContentId.MultiplePayments}
            label={<Content id={ContentId.MultiplePayments} />}
          />
        </Stack>
      </RadioGroup>
      <br />
      {state === 'singlePayment' ? (
        <TableOverflowWrapper>
          <AddPurchasePaymentTable
            form={paymentsTableForm}
            fieldArray={paymentsTableFieldArray}
          />
        </TableOverflowWrapper>
      ) : state === 'generatePaymentsForm' ? (
        <GeneratePurchasePaymentsForm form={generatePaymentsForm} />
      ) : (
        <>
          <TableOverflowWrapper>
            {isMobile ? (
              <AddPurchasePaymentTiles
                form={paymentsTableForm}
                fieldArray={paymentsTableFieldArray}
              />
            ) : (
              <AddPurchasePaymentTable
                form={paymentsTableForm}
                fieldArray={paymentsTableFieldArray}
              />
            )}
          </TableOverflowWrapper>
          <Button
            variant={'text'}
            onClick={() => {
              const payments = paymentsTableForm.getValues('payments');
              const lastPayment = payments[payments.length - 1];
              paymentsTableFieldArray.append({
                currencyCode: lastPayment?.currencyCode ?? defaultCurrencyCode,
                isPaid: true,
                paymentMethodId: lastPayment?.paymentMethodId,
              });
            }}
          >
            <Content id={ContentId.AddPayment} />
          </Button>
        </>
      )}
    </GenericDialog>
  );
}

/**
 * Divides the `totalAmount` into `paymentCount` number of occurrence amounts. If the amount is not
 * evenly divisible, the remainder is added onto the last amount.
 * @param totalAmount
 * @param paymentCount
 */
function dividePaymentAmount(totalAmount: number, paymentCount: number) {
  const perOccurrence = Math.round((totalAmount / paymentCount) * 100) / 100;
  const amounts = [];
  for (let i = 0; i < paymentCount - 1; i++) {
    amounts.push(perOccurrence);
    totalAmount -= perOccurrence;
  }
  // floating point arithmetic issues so we round again
  // TODO would be better to use a currency math library to avoid this issue
  amounts.push(Math.round(totalAmount * 100) / 100);
  return amounts;
}
