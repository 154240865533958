import { useQuery } from '@tanstack/react-query';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMemo, useRef } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { vars } from 'src/core/themes';
import { MARKETPLACE_TO_BACKGROUND_COLOR } from 'src/utils/constants/colorMaps';
import { DATA_REFRESH_RATE_IN_MILLIS_FAST } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ListingClient,
  ListingDetails,
  ListingStatus,
  Marketplace,
} from 'src/WebApiController';

import * as styles from './HistoricalPricingGraph.css';

export const HistoricalPricingGraph = ({
  listing,
}: {
  listing: ListingDetails;
}) => {
  const { mkpListings, id: listingId } = listing;

  const historicalPricingText = useContent(ContentId.HistoricalPricing);
  const priceText = useContent(ContentId.Price);
  const { trackError } = useErrorBoundaryContext();

  const containerRef = useRef<HTMLDivElement>(null);

  const { activeAccountWebClientConfig } = useAppContext();

  const historicalPricingResult = useQuery({
    queryKey: ['ListingClient.getPriceHistoryByListingId', listingId],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new ListingClient(
        activeAccountWebClientConfig
      ).getPriceHistoryByListingId({
        item1: listingId,
        item2: 1,
      });
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('ListingClient.getPriceHistoryByListingId', error, {
          listingId,
        });
      },
    },
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_FAST,
  });

  const marketplacesBroadcasted = useMemo(
    () =>
      mkpListings
        .filter((ml) => ml.mkp && ml.status === ListingStatus.Listed)
        .map((ml) => ml.mkp!),
    [mkpListings]
  );

  const currencyCode = useMemo(() => {
    const { data } = historicalPricingResult;
    if (!data) return;
    return data[0]?.unitNetProceeds.currency;
  }, [historicalPricingResult]);

  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = getUiCurrency(currencyCode);

  const dataAsTuples = useMemo(() => {
    const { data } = historicalPricingResult;
    if (!data) return;

    const massagedData = data.reduce<{
      [marketplace: string]: Highcharts.SeriesOptionsType & {
        data: [number, number][];
      };
    }>((acc, current) => {
      const { marketplace, priceDate, unitNetProceeds } = current;
      if (!marketplace) return acc;

      const marketplaceColor = MARKETPLACE_TO_BACKGROUND_COLOR[marketplace];
      const isListed = marketplacesBroadcasted.includes(marketplace);
      const item = acc[marketplace] ?? {
        data: [],
        type: 'line',
        name: marketplace,
        color: marketplaceColor,
        opacity: isListed ? 1 : 0.35,
        marker: {
          lineColor: marketplaceColor,
        },
        visible: marketplace === Marketplace.StubHub,
      };
      item.data.push([new Date(priceDate).getTime(), unitNetProceeds.amt]);
      acc[marketplace] = item;
      return acc;
    }, {});

    return Object.values(massagedData);
  }, [historicalPricingResult, marketplacesBroadcasted]);

  const options: Highcharts.Options = {
    chart: {
      zooming: { type: 'x' },
      width: 0.9 * (containerRef.current?.clientWidth ?? 500),
    },
    title: {
      text: historicalPricingText,
      align: 'left',
    },
    xAxis: { type: 'datetime' },
    yAxis: {
      title: { text: priceText },
      labels: { format: `${uiCurrency.sym}{text}` },
    },
    legend: { enabled: true },
    plotOptions: {
      line: {
        color: vars.color.backgroundBrandActive,
        marker: {
          radius: 4,
          lineColor: vars.color.backgroundBrandHover,
          fillColor: '#fff',
          lineWidth: 2,
        },
        lineWidth: 2,
        threshold: null,
      },
      series: {
        animation: false,
      },
    },
    series: dataAsTuples,
    // Watermark
    credits: { enabled: false },
  };

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  return dataAsTuples?.length ? (
    <div className={styles.historicalPricingGraphContainer} ref={containerRef}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
      />
    </div>
  ) : null;
};
