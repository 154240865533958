import { useCallback, useState } from 'react';

export type IListExpansion<TItemId> = {
  expandedListItems: TItemId[];
  setExpandedListItems: (items: TItemId[]) => void;
  toggleListItemExpansion: (items: TItemId[]) => boolean;
  setListItemExpansion: (open: boolean, items: TItemId[]) => void;
  refreshExpandedListItems: (refetchAll?: boolean) => Promise<void>;
};

export function useListExpansion<TItemId>(
  initialExpandedItems?: TItemId[]
): IListExpansion<TItemId> {
  const [expandedListItems, setExpandedListItems] = useState<TItemId[]>(
    initialExpandedItems || []
  );

  const isListItemExpanded = useCallback(
    (itemId: TItemId) => {
      const indexOf = expandedListItems.indexOf(itemId);
      return indexOf >= 0;
    },
    [expandedListItems]
  );

  const toggleListItemExpansion = useCallback(
    (itemIds: TItemId[]) => {
      const curCount = expandedListItems.length;
      let newLists = [...expandedListItems];
      itemIds.forEach((itemId) => {
        const isItemExpanded = isListItemExpanded(itemId);
        if (isItemExpanded) {
          // If expanded, un-expanded it by removing it from the array
          newLists = newLists.filter((id) => id !== itemId);
        } else {
          // If not expanded, add it to the array
          newLists.push(itemId);
        }
      });

      setExpandedListItems(newLists);

      return newLists.length > curCount;
    },
    [expandedListItems, isListItemExpanded]
  );

  const setListItemExpansion = useCallback(
    (open: boolean, items: TItemId[]) => {
      let newLists = [...expandedListItems];
      let hasChanged = false;
      items.forEach((itemId) => {
        const isItemExpanded = isListItemExpanded(itemId);
        if (isItemExpanded !== open) {
          if (!open) {
            // If we want to not open, un-expanded it by removing it from the array
            newLists = newLists.filter((id) => id !== itemId);
          } else {
            // If we want to open, add it to the array
            newLists.push(itemId);
          }
          hasChanged = true;
        }
      });

      if (hasChanged) {
        setExpandedListItems(newLists);
      }
    },
    [expandedListItems, isListItemExpanded]
  );

  return {
    expandedListItems: expandedListItems,
    setExpandedListItems: setExpandedListItems,
    toggleListItemExpansion: toggleListItemExpansion,
    setListItemExpansion: setListItemExpansion,
    refreshExpandedListItems: () => Promise.resolve(),
  };
}
