import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { BulkEditSeatTraitsDialog } from 'src/dialogs/BulkEdits/BulkEditSeatTraitsDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  BulkEditTicketGroupClient,
  ListingNote,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { PURCHASE_BULK_UPDATE_SEAT_TRAITS_UPDATE_KEY } from '../../PurchaseActionDropdown.constants';
import { LaunchBulkUpdateSeatTraits } from './LaunchBulkUpdateSeatTraits';

export const useBulkUpdateSeatTraits = (
  filterQuery: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string,
  isEventView?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const updateKey = groupId
    ? `purchase-seat-traits-event-${groupId}`
    : PURCHASE_BULK_UPDATE_SEAT_TRAITS_UPDATE_KEY;

  const { showErrorDialog } = useErrorBoundaryContext();
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const {
    openDialog: openBulkEditSeatTraitsDialog,
    closeDialog: closeBulkEditSeatTraitsDialog,
  } = useDialog(DialogId.BulkEditSeatTraits, BulkEditSeatTraitsDialog);

  const updatePurchaseOrders = !isEventView;

  const onSubmit = useCallback(
    async (
      seatTraits: ListingNote[] | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails,
      appendSeatTraits?: boolean
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const bulkEditClient = updatePurchaseOrders
              ? new BulkEditPurchaseClient(activeAccountWebClientConfig)
              : new BulkEditTicketGroupClient(activeAccountWebClientConfig);

            const preview =
              await bulkEditClient.bulkUpdateSeatTraitsPreview(filterQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateSeatTraitsPreview', error, {
              trackErrorData: { filterQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (seatTraits) {
        await tryInvokeApi(
          async () => {
            const bulkEditClient = updatePurchaseOrders
              ? new BulkEditPurchaseClient(activeAccountWebClientConfig)
              : new BulkEditTicketGroupClient(activeAccountWebClientConfig);

            const succeeded = await bulkEditClient.bulkUpdateSeatTraits(
              {
                item1: preview!.preview,
                item2: seatTraits,
              },
              updateKey,
              supportBackgroundProcess,
              appendSeatTraits
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                await refreshData(true);
                await refreshExpandedListItems();
              }

              closeBulkEditSeatTraitsDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateSeatTraits', error, {
              trackErrorData: {
                preview,
                seatTraits,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkEditSeatTraitsDialog,
      filterQuery,
      refreshData,
      refreshExpandedListItems,
      setIsLoading,
      showErrorDialog,
      updateKey,
      updatePurchaseOrders,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkUpdateSeatTraits
        key={'bulkEditPurchaseSeatTraits'}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkEditSeatTraitsDialog({
            updateKey,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkEditSeatTraitsDialog,
            entityType: ActionOutboxEntityType.TicketGroup,
          });
        }}
        disabled={isLoading}
        labelContentId={ContentId.UpdateSeatTraits}
      />
    ),
  };
};
