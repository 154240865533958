import { Content, Trigger } from '@radix-ui/react-accordion';
import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Button } from 'src/core/ui';
import * as styles from 'src/core/ui/Accordion/Accordion.css';
import { ChevronAccordionIcon } from 'src/svgs/ChevronAccordionIcon';

export const PosAccordionTrigger = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithoutRef<typeof Button> & { chevronPos?: 'front' | 'back' }
>(({ children, className, chevronPos = 'back', ...props }, forwardedRef) => (
  <Trigger
    className={clsx(styles.accordionTrigger, className)}
    {...props}
    ref={forwardedRef}
  >
    {chevronPos === 'front' && (
      <ChevronAccordionIcon
        className={styles.accordionChevron}
        aria-hidden
        withHoverEffect
      />
    )}
    {children}
    {chevronPos === 'back' && (
      <ChevronAccordionIcon
        className={styles.accordionChevron}
        aria-hidden
        withHoverEffect
      />
    )}
  </Trigger>
));
PosAccordionTrigger.displayName = 'PosAccordionTrigger';

export const PosAccordionContent = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof Content>
>(({ children, className, ...props }, forwardedRef) => (
  <Content className={className} {...props} ref={forwardedRef}>
    <div className="AccordionContentText">{children}</div>
  </Content>
));
PosAccordionContent.displayName = 'AccordionContent';
