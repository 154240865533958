import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const MetricViewIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 24">
      <rect x="2" y="3" width="9" height="3" rx="0.3" fill="inherit" />
      <rect x="13" y="3" width="9" height="3" rx="0.3" fill="inherit" />
      <rect x="2" y="10" width="9" height="3" rx="0.3" fill="inherit" />
      <rect x="13" y="10" width="9" height="3" rx="0.3" fill="inherit" />
      <rect x="2" y="17" width="9" height="3" rx="0.3" fill="inherit" />
      <rect x="13" y="17" width="9" height="3" rx="0.3" fill="inherit" />
    </SvgWrapper>
  );
};
