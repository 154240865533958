import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Cell, flexRender } from '@tanstack/react-table';
import { CSSProperties, forwardRef, HTMLAttributes } from 'react';
import { vars } from 'src/core/themes';

import { SectionRowColumnType } from './config/SectionRowTableConfig';
import { EnrichedScoreOrverride } from './SectionRowTableDialog.hooks';

type SortableCellProps = {
  cell: Cell<SectionRowColumnType, unknown>;
  isLargeDesktop?: boolean;
};

export const SortableCell: React.FC<SortableCellProps> = ({ cell }) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: cell.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
    paddingTop: vars.spacing['sm'],
    paddingBottom: vars.spacing['sm'],
    color: ((cell.getValue() ?? {}) as EnrichedScoreOrverride).isNew
      ? vars.color.textWarning
      : undefined,
  };

  return (
    <CellItem
      ref={setNodeRef}
      style={style}
      cell={cell}
      isDragging={isDragging}
      {...attributes}
      {...listeners}
    />
  );
};

type CellItemProps = HTMLAttributes<HTMLDivElement> & {
  cell: Cell<SectionRowColumnType, unknown>;
  draggable?: boolean;
  isDragging?: boolean;
};

// eslint-disable-next-line react/display-name
export const CellItem = forwardRef<HTMLTableCellElement, CellItemProps>(
  ({ cell, draggable = true, isDragging, style, ...props }, ref) => {
    const inlineStyles: CSSProperties = {
      opacity: isDragging ? '0.5' : '1',
      transformOrigin: '50% 50%',
      cursor: draggable ? (isDragging ? 'grabbing' : 'grab') : undefined,
      ...style,
    };

    return (
      <td ref={ref} key={cell.id} style={inlineStyles} {...props}>
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    );
  }
);
