import {
  Controller,
  UseFieldArrayReturn,
  UseFormReturn,
} from 'react-hook-form';
import { CurrencyFilterSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { Switch } from 'src/core/interim/Switch';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  FieldError,
  PurchasePaymentMethodSelectionInput,
} from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { Feature } from 'src/WebApiController';

import { FormTable } from './AddPurchasePaymentTable.styled';

export type AddPurchasePaymentTableFieldValues = {
  payments: {
    currencyCode: string;
    paymentAmount?: number;
    isPaid: boolean;
    paymentDueDate?: Date;
    paymentDate?: Date;
    paymentMethodId?: number;
  }[];
};

export type AddPurchasePaymentTableProps = {
  form: UseFormReturn<AddPurchasePaymentTableFieldValues>;
  fieldArray: UseFieldArrayReturn<
    AddPurchasePaymentTableFieldValues,
    'payments',
    'id'
  >;
};

export function AddPurchasePaymentTable({
  form: {
    control,
    formState,
    resetField,
    setValue,
    getValues,
    getFieldState,
    watch,
    register,
    clearErrors,
  },
  fieldArray: { fields },
}: AddPurchasePaymentTableProps) {
  const requiredMsg = useContent(ContentId.Required);
  const { getUiCurrency } = useLocalizationContext();

  const hasEnforcePurchasePaymentCurrencyFeature = useUserHasFeature(
    Feature.EnforcePurchasePaymentCurrency
  );

  const table = (
    <FormTable>
      <thead>
        <tr>
          <th>
            <Content id={ContentId.TotalDue} />
          </th>
          {!hasEnforcePurchasePaymentCurrencyFeature && <th></th>}
          <th>
            <Content id={ContentId.DateDue} />
          </th>
          <th>
            <Content id={ContentId.Paid} />
          </th>
          <th>
            <Content id={ContentId.DatePaid} />
          </th>
          <th>
            <Content id={ContentId.PaymentMethodUsed} />
          </th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, index) => (
          <tr key={field.id}>
            {!hasEnforcePurchasePaymentCurrencyFeature && (
              <td>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name={`payments.${index}.currencyCode`}
                  render={({ field: { ...field }, fieldState }) => (
                    <CurrencyFilterSelector
                      hasErrors={Boolean(fieldState.error)}
                      {...field}
                    />
                  )}
                />
              </td>
            )}
            <td>
              <PosCurrencyField
                rootProps={{
                  state: getFieldState(
                    `payments.${index}.paymentAmount`,
                    formState
                  ).error?.message
                    ? 'error'
                    : undefined,
                }}
                uiCurrency={getUiCurrency(
                  getValues(`payments.${index}.currencyCode`)
                )}
                value={getValues(`payments.${index}.paymentAmount`)}
                {...register(`payments.${index}.paymentAmount`, {
                  validate: {
                    required: (fieldVal) => {
                      return parseFloat(fieldVal?.toString() ?? '')
                        ? undefined
                        : requiredMsg;
                    },
                  },
                  valueAsNumber: true,
                  onChange: ({ target: { value } }) => {
                    clearErrors(`payments.${index}.paymentAmount`);
                    setValue(
                      `payments.${index}.paymentAmount`,
                      parseFloat(value)
                    );
                  },
                })}
              />
            </td>
            <td>
              <Controller
                name={`payments.${index}.paymentDueDate`}
                rules={{ required: true }}
                control={control}
                render={({ field: { ...field }, fieldState }) => (
                  <DatePickerInput
                    fieldError={fieldState.error}
                    locale={getLocaleFromLanguageOrCurrent()}
                    {...field}
                    onDateChange={(d) => {
                      setValue(field.name, d);
                    }}
                  />
                )}
              />
            </td>
            <td>
              <Controller
                name={`payments.${index}.isPaid`}
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Switch
                    checked={value}
                    onChange={(e) => {
                      const isPaid = e.currentTarget.checked;
                      if (isPaid) {
                        setValue(
                          `payments.${index}.paymentDate`,
                          getValues(`payments.${index}.paymentDueDate`)
                        );
                      } else {
                        resetField(`payments.${index}.paymentDate`);
                      }
                      onChange(e);
                    }}
                    {...field}
                  />
                )}
              />
            </td>
            <td>
              <Controller
                name={`payments.${index}.paymentDate`}
                rules={{ required: watch(`payments.${index}.isPaid`) }}
                control={control}
                render={({ field: { ...field }, fieldState }) => (
                  <DatePickerInput
                    fieldError={fieldState.error}
                    disabled={!watch(`payments.${index}.isPaid`)}
                    locale={getLocaleFromLanguageOrCurrent()}
                    {...field}
                    onDateChange={(d) => {
                      setValue(field.name, d);
                    }}
                  />
                )}
              />
            </td>
            <td>
              <Controller
                control={control}
                name={`payments.${index}.paymentMethodId`}
                rules={{ required: true }}
                render={({ field: { value, ...field }, fieldState }) => (
                  <PurchasePaymentMethodSelectionInput
                    value={value !== undefined ? String(value) : undefined}
                    hasErrors={Boolean(fieldState.error)}
                    {...field}
                  />
                )}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </FormTable>
  );
  return (
    <div>
      {table}
      {formState.errors.payments && (
        <FieldError>
          <Content id={ContentId.RequiredFieldsError} />
        </FieldError>
      )}
    </div>
  );
}
