import { KeyboardEvent, useEffect, useMemo } from 'react';
import {
  ComparableListing,
  ComparableListings,
} from 'src/components/Listings/ComparableListings';
import { useActiveFocusContext } from 'src/contexts/ActiveFocusContext/ActiveFocusContext';
import { useAppContext } from 'src/contexts/AppContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import {
  getAllInPriceFromListPrice,
  getAllInPriceFromListPriceForListing,
} from 'src/utils/inventoryUtils';
import {
  ActivityLogClient,
  CompListing,
  Listing,
  ListingPriceCalculation,
  Marketplace,
} from 'src/WebApiController';

import * as styles from './ConnectedComparableListings.css';

export type ListingWithSeatScore = Listing & {
  seatScore?: number | null | undefined;
};

export type CompListingWithSeatScore = CompListing & {
  seatScore?: number | null | undefined;
};

export type ConnectedComparableListingsProps = {
  isLoading?: boolean;
  currentListing?: ListingWithSeatScore;
  priceOverride?: number | null;
  currentListingFromListingGroup?: ListingWithSeatScore[];
  comparableListings: CompListingWithSeatScore[];
  seatScore?: number | null;
  sortBy?: 'price' | 'dealScore';
  showAutoPriceResults?: boolean;
  isSortDescending?: boolean;
  listingPriceCalc?: ListingPriceCalculation | null;
  /**
   * User's listings
   */
  listings?: Listing[] | null;
};

const mapListingToComparableListing = (
  listing: ListingWithSeatScore
): ComparableListing => ({
  listingId: listing.id,
  section: listing.seating.section,
  row: listing.seating.row ?? undefined,
  seatFr: listing.seating.seatFr ?? undefined,
  seatTo: listing.seating.seatTo ?? undefined,
  price: getAllInPriceFromListPriceForListing(listing),
  currencyCode: listing.currency,
  listingNotes: listing.seatTraits ?? undefined,
  // Price assuming same fees as seller’s account
  sellerNetProceeds: listing.listPrice,
  seatScore: listing.seatScore,
  isYourListing: true,
  availableTickets: listing.availQty,
  validPurchaseQuantities: listing.vldPurQty,
});

export function ConnectedComparableListings({
  isLoading,
  currentListing,
  currentListingFromListingGroup,
  comparableListings,
  listings,
  listingPriceCalc,
  ...rest
}: ConnectedComparableListingsProps) {
  const { activeAccountWebClientConfig } = useAppContext();

  const { onKeyDownHandler, activeItem } = useActiveFocusContext();
  const activeItemIndex = activeItem?.index;

  const onCurrentKeyDownHandler = (e: KeyboardEvent) => {
    onKeyDownHandler(e, activeItemIndex ?? 0);
  };

  const marketplaceListingIdsSet = useMemo(() => {
    const flattenedListings = (listings?.flatMap(flattenListingGroup) ??
      []) as Listing[];

    return new Set<number>(
      flattenedListings.reduce((ids, listing) => {
        const stubHubMkpListings = listing.mkpListings?.find(
          ({ mkp }) => mkp === Marketplace.StubHub
        );
        if (stubHubMkpListings?.mkpListingId) {
          ids.push(parseInt(stubHubMkpListings?.mkpListingId));
        }
        return ids;
      }, [] as number[])
    );
  }, [listings]);

  const { loginContext } = useAppContext();
  const stubHubMkpSettings =
    loginContext?.user?.activeAccount?.marketplaceSettings?.find(
      (m) => m.mkp === Marketplace.StubHub
    );

  const currentListings = useMemo(() => {
    if (currentListingFromListingGroup) {
      return currentListingFromListingGroup.map(mapListingToComparableListing);
    }

    return currentListing
      ? [mapListingToComparableListing(currentListing)]
      : undefined;
  }, [currentListing, currentListingFromListingGroup]);

  const comparableListingsLoaded = useMemo(() => {
    return !isLoading && currentListing?.id != null;
  }, [currentListing?.id, isLoading]);

  useEffect(() => {
    if (comparableListingsLoaded && currentListing) {
      new ActivityLogClient(activeAccountWebClientConfig).trackListingCompsView(
        currentListing.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparableListingsLoaded]);

  return (
    <Stack
      className={styles.root}
      direction="column"
      gap="l"
      onKeyDown={onCurrentKeyDownHandler}
    >
      {isLoading ? (
        <PosSpinner />
      ) : (
        <ComparableListings
          currentListings={currentListings}
          comparableListings={
            comparableListings
              ? comparableListings.map(
                  (compListing) =>
                    ({
                      listingId: compListing.listingId,
                      section: compListing.section ?? undefined,
                      row: compListing.row ?? undefined,
                      seatFr: compListing.seatFrom ?? undefined,
                      seatTo: compListing.seatTo ?? undefined,
                      price: compListing.sellerAllInPrice?.amt,
                      currencyCode: compListing.currencyCode ?? undefined,
                      listingNotes: compListing.listingNotes ?? undefined,
                      sellerNetProceeds:
                        compListing.sellerNetProceeds?.amt ?? undefined,
                      isOutlier:
                        compListing.isWithoutOutlier == null
                          ? false
                          : compListing.isWithoutOutlier,
                      isAnchor: compListing.isAnchor ?? false,
                      isYourListing:
                        marketplaceListingIdsSet.has(compListing.listingId) ||
                        compListing.isSellerCompListing,
                      seatScore: compListing.seatScore,
                      availableTickets: compListing.availableTickets,
                      validPurchaseQuantities:
                        compListing.validPurchaseQuantities,
                    }) as ComparableListing
                )
              : []
          }
          floorPrice={
            listingPriceCalc?.cmpFlr?.amt ??
            (currentListing?.procsFloor
              ? getAllInPriceFromListPrice(
                  currentListing.procsFloor,
                  currentListing,
                  stubHubMkpSettings?.sellerFee
                )
              : undefined)
          }
          ceilingPrice={
            listingPriceCalc?.cmpCeil?.amt ??
            (currentListing?.procsFloor
              ? getAllInPriceFromListPrice(
                  currentListing.procsCeil,
                  currentListing,
                  stubHubMkpSettings?.sellerFee
                )
              : undefined)
          }
          listingPriceCalc={listingPriceCalc}
          isLtGrp={!!currentListingFromListingGroup}
          {...rest}
        />
      )}
    </Stack>
  );
}
