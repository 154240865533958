import {
  ComponentProps,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { CancelButton } from 'src/components/Buttons';
import { ModalContext } from 'src/contexts/ModalContext';
import { UnsavedChanges } from 'src/dialogs/UnsavedChanges';
import { ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';

export type CancellableFormFooterProps = PropsWithChildren<
  {
    cancelTo?: ModalProps;
    onBeforeClose?: () => void;
    showDialogOnCancel?: boolean;
  } & ComponentProps<typeof CancelButton>
>;

export function CancellableFormFooter({
  cancelTo,
  children, // action button
  showDialogOnCancel,
  onBeforeClose,
  ...props
}: PropsWithChildren<CancellableFormFooterProps>) {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { setModal, closeModal } = useContext(ModalContext);

  /**
   * Allow a modal 'config' (`Modal` component props) to determine
   * Which modal to go back to in the workflow
   * If nothing is provided, treat as a close button
   */
  const goToCancelView = useCallback(() => {
    setShowDialog(false);

    onBeforeClose?.();

    if (cancelTo) {
      setModal(cancelTo);
    } else {
      closeModal(true);
    }
  }, [onBeforeClose, cancelTo, setModal, closeModal]);

  const onCancel = useCallback(() => {
    if (showDialogOnCancel) {
      setShowDialog(true);
    } else {
      goToCancelView();
    }
  }, [showDialogOnCancel, goToCancelView]);

  const closeDialog = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  return (
    <>
      <CancelButton
        type="button"
        onClick={onCancel}
        {...props}
        textContentId={showDialogOnCancel ? ContentId.Cancel : ContentId.Close}
      />
      {children}
      <UnsavedChanges
        onOkay={goToCancelView}
        onCancel={closeDialog}
        isOpen={showDialog}
      />
    </>
  );
}
