import { useMemo } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { FormatContent } from 'src/contexts/ContentContext';
import { MultiSelectScope } from 'src/contexts/MultiSelectionContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Stack } from 'src/core/ui';
import { FormatOption } from 'src/modals/EditTableColumns';
import { ListingTableFlattened } from 'src/tables/ListingTable';
import {
  ListingTableColumnId,
  ListingTableFlattenedColumnId,
} from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getEventPerformerVenue } from 'src/utils/eventWithDataUtils';
import { BulkEditPreviewWithDetails } from 'src/WebApiController';

export const BulkEditPreviewListingDetails = ({
  preview,
}: {
  preview?: BulkEditPreviewWithDetails | null;
}) => {
  const { data: catalog } = useCatalogDataContext();

  const listings = useMemo(() => {
    return preview?.listings
      ?.map((l) => {
        const { event, performer, venue } = getEventPerformerVenue(
          l?.viagVirtualId,
          catalog
        );

        if (event) {
          return { event: event.event, listing: l, performer, venue };
        }
        return null;
      })
      .filter((l) => l != null)
      .map((l) => l!);
  }, [catalog, preview?.listings]);

  if (!listings) {
    return null;
  }

  return (
    <Stack
      direction="column"
      style={{ height: '50vh' }}
      gap="l"
      alignItems="center"
    >
      {listings.length <
        (preview?.preview?.bulkEditCountPreview?.Listing?.totalCount ?? 0) && (
        <WarningMessage
          message={
            <FormatContent
              id={FormatContentId.BulkEditMaxItemPreviewDisplayMessage}
              params={[
                `${listings.length}`,
                `${preview?.preview?.bulkEditCountPreview?.Listing?.totalCount}`,
              ]}
            />
          }
        />
      )}
      <ListingTableFlattened
        listings={listings}
        useVirtuoso
        disablePagination
        disableSelection
        listingCount={listings.length}
        ungroupedListingCount={listings.length}
        listingGroupCount={0}
        failedToRetrieveData={false}
        columnVisibilityOverride={[
          ListingTableColumnId.ListingId,
          ListingTableFlattenedColumnId.Event,
          ListingTableFlattenedColumnId.EventDate,
          ListingTableColumnId.Seating,
          ListingTableColumnId.Actions,
        ]}
        formatOptionOverrides={{
          [ListingTableColumnId.Seating]: FormatOption.Seating_FullSeating,
          [ListingTableFlattenedColumnId.Event]: FormatOption.Event_EventName,
          [ListingTableFlattenedColumnId.EventDate]:
            FormatOption.DateTime_Month_DD_YY_Time,
          [ListingTableColumnId.Actions]: FormatOption.Actions_LinkOnly,
        }}
        selectionMode={{
          mode: MultiSelectScope.AllGroups,
        }}
        withOuterPadding={false}
      />
    </Stack>
  );
};
