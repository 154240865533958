import clsx from 'clsx';
import { ReactNode } from 'react';
import { Stack } from 'src/core/ui';

import * as styles from './EventNameDisplay.css';

export type EventNameDisplayProps = {
  eventName?: ReactNode;
  /**
   * Portion of the event name to be displayed with less emphasis.
   */
  eventNameConnector?: ReactNode;
  eventSubName?: ReactNode;
  className?: string;
  slimMode?: boolean;
  showTitle?: boolean;
  boldSubName?: boolean;
  useBigStyle?: boolean;
};

export function EventNameDisplay({
  eventName,
  eventNameConnector,
  eventSubName,
  className,
  slimMode,
  showTitle = true,
  boldSubName = false,
  useBigStyle = false,
}: EventNameDisplayProps) {
  return (
    <Stack
      gap="m"
      alignItems="center"
      direction="row"
      className={`${styles.eventName} ${className}`}
      title={showTitle && typeof eventName === 'string' ? eventName : undefined}
    >
      {eventNameConnector && !eventSubName && (
        <>
          <span
            className={clsx(styles.eventNameConnector, {
              [styles.eventNameConnectorBig]: useBigStyle,
            })}
          >
            {eventNameConnector}
          </span>{' '}
        </>
      )}
      {eventName}
      {eventNameConnector && eventSubName && (
        <>
          {' '}
          <span
            className={clsx(styles.eventNameConnector, {
              [styles.eventNameConnectorBig]: useBigStyle,
            })}
          >
            {eventNameConnector}
          </span>{' '}
          <span
            className={clsx(styles.eventSubName, {
              [styles.eventNameSlim]: slimMode,
              [styles.eventSubNameBold]: boldSubName,
              [styles.eventSubNameBig]: useBigStyle,
            })}
          >
            {eventSubName}
          </span>
        </>
      )}
    </Stack>
  );
}
