import { ComponentProps } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import styled from 'styled-components/macro';

import { BackToModalButton } from './BackToModalButton';

type NextFooterProps = Omit<ComponentProps<typeof Button>, 'children'> & {
  cancelTo?: ModalProps | null;
};

export const NextFooter = ({ cancelTo, ...props }: NextFooterProps) => {
  return (
    <Footer>
      {cancelTo && (
        <BackBtnWrapper>
          <BackToModalButton
            cancelTo={cancelTo}
            textContentId={ContentId.Back}
          />
        </BackBtnWrapper>
      )}
      <Button variant={'regular'} {...props}>
        <Content id={ContentId.Next} />
      </Button>
    </Footer>
  );
};

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: ${() => `${vars.spacing.lg} 0 0 0`};

  > * {
    display: inline-flex;
    align-items: center;
  }
`;

const BackBtnWrapper = styled.div`
  margin-right: ${() => vars.spacing.sm};
`;
