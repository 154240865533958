import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';

import {
    useVendorAccessControlProvidersSelector,
    UseVendorAccessControlProvidersSelectorProps,
} from './useVendorAccessControlProvidersSelector';

export const VendorAccessControlProvidersSelector = ({
  disabled,
  additionalOptions,
  additionalOptionsPosition,
  placeholderText,
  ...props
}: Omit<PosSelectProps, 'valueOptionsContent'> &
  UseVendorAccessControlProvidersSelectorProps) => {
  const {
    query: { isLoading },
    posSelectProps,
  } = useVendorAccessControlProvidersSelector({
    additionalOptions,
    additionalOptionsPosition,
    placeholderText,
  });

  return (
    <PosSelect
      {...props}
      loading={isLoading}
      disabled={disabled}
      {...posSelectProps}
    />
  );
};
