import { ChangeEvent } from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonWithIcon } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Switch } from 'src/core/interim/Switch';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { Slider } from 'src/core/ui/Slider';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { useAvailableAutopricingModes } from 'src/hooks/useAvailableAutopricingModes';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { ResetIcon } from 'src/svgs/Viagogo';
import {
  OUTLIER_MODE_KTH_LOWEST_STRATEGY,
  OutlierModeKthLowestStrategy,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { STANDARD_DEVIATIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import {
  AutoPricingCompListingMode,
  AutoPricingOutlierMode,
  Feature,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
} from 'src/WebApiController';

import { useAutoPricingSettings } from '../useAutoPricingSettings';
import * as styles from './PricingSection.css';

export const AdvancePricingSettings = ({
  listing,
  disabled: externalDisabled,
}: {
  listing?: ListingDetails;
  disabled?: boolean;
}) => {
  const { clearErrors } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();
  const [outlierKthLowestStrategy, setKthLowestOutlierStrategy] = useState<
    OutlierModeKthLowestStrategy | undefined
  >(OutlierModeKthLowestStrategy.Relative);

  const {
    canSetPrice,
    onResetAdvanceSettings,

    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,

    compListingMode,
    compListingQuantityScoreAdjustmentEnabled,
    compListingExcludeFanInventory,
    compListingExcludeDefects,

    outlierMode,
    outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,

    onCompListingChange,
    onCircuitBreakerChange,
    onOutlierChange,

    skipAdvancePricing,
  } = useAutoPricingSettings(listing);

  const { activeConfigOverride, venueMapQuery } = useEventMapContext();
  const hasExcludeFanInventoryFeature = useUserHasFeature(
    Feature.ExcludeFanInventory
  );
  const hasExcludeDefectsFeature = useUserHasFeature(Feature.ExcludeDefects);

  const scores = getCompleteEventConfigScoreOverrides(
    venueMapQuery.data?.sectionScores,
    activeConfigOverride?.scoreOverrides,
    false
  );

  const { outlierModes, outlierModesDisabled } = useAvailableAutopricingModes(
    true,
    null,
    null,
    Boolean(scores?.some((s) => s.score))
  );

  const disabled = externalDisabled || Boolean(skipAdvancePricing);

  return (
    <DetailSection
      name={<Content id={ContentId.AdvancedAutopricingSettings} />}
    >
      <SectionContent numOfColumns={1}>
        {skipAdvancePricing ? null : (
          <>
            {compListingMode === AutoPricingCompListingMode.QualityScore && (
              <DetailGroup style={{ gridColumn: '1' }}>
                <PosFormField
                  label={
                    <Content
                      id={ContentId.CompListingQuantityScoreAdjustment}
                    />
                  }
                >
                  <Switch
                    disabled={disabled || listing?.isDeleted || !canSetPrice}
                    checked={Boolean(compListingQuantityScoreAdjustmentEnabled)}
                    onClick={(e: ChangeEvent<HTMLElement>) =>
                      e.stopPropagation()
                    }
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      if (
                        isChecked !== compListingQuantityScoreAdjustmentEnabled
                      ) {
                        onCompListingChange({
                          compListingQuantityScoreAdjustmentEnabledNew:
                            isChecked,
                        });
                      }
                    }}
                  />
                </PosFormField>
              </DetailGroup>
            )}
            {hasExcludeFanInventoryFeature && (
              <DetailGroup style={{ gridColumn: '1' }}>
                <PosFormField
                  label={<Content id={ContentId.ExcludeFanInventory} />}
                >
                  <Switch
                    disabled={disabled || listing?.isDeleted || !canSetPrice}
                    checked={Boolean(compListingExcludeFanInventory)}
                    onClick={(e: ChangeEvent<HTMLElement>) =>
                      e.stopPropagation()
                    }
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      if (isChecked !== compListingExcludeFanInventory) {
                        onCompListingChange({
                          compListingExcludeFanInventoryNew: isChecked,
                        });
                      }
                    }}
                  />
                </PosFormField>
              </DetailGroup>
            )}
            {/*
             * [NOTE]
             * Exclude defects feature was built first before product changed their mind for the
             * UI to instead show the opposite, "Include Defects" with a default value of false.
             * Originally, the requirement was "Exclude Defects" with a default value of true.
             * The DB columns and pricer backend has already been finished with logic revolving
             * around the "Exclude Defect" verbiage, with the logic gates predicating busisness logic
             * off of "exclude". The UI will now show the copy "Include Defects" and invert the value
             * being read/written from the database, but the rest of the backend (pricer + site) will remain
             * unchanged.
             */}
            {hasExcludeDefectsFeature && (
              <DetailGroup style={{ gridColumn: '1' }}>
                <PosFormField label={<Content id={ContentId.IncludeDefects} />}>
                  <Switch
                    disabled={disabled || listing?.isDeleted || !canSetPrice}
                    checked={!(compListingExcludeDefects ?? true)}
                    onClick={(e: ChangeEvent<HTMLElement>) =>
                      e.stopPropagation()
                    }
                    onChange={(e) => {
                      const compListingExcludeDefectsNew = !e.target.checked;
                      if (
                        compListingExcludeDefectsNew !==
                        compListingExcludeDefects
                      ) {
                        onCompListingChange({
                          compListingExcludeDefectsNew:
                            compListingExcludeDefectsNew,
                        });
                      }
                    }}
                  />
                </PosFormField>
              </DetailGroup>
            )}
            <DetailGroup style={{ gridColumn: '1' }}>
              <PosFormField
                label={<Content id={ContentId.MinCompListingCount} />}
              >
                <Slider.Root
                  disabled={disabled}
                  min={1}
                  max={30}
                  step={1}
                  value={[circuitBreakerMinCompListingCount ?? 10]}
                  onValueChange={([count]) => {
                    onCircuitBreakerChange(
                      undefined,
                      undefined,
                      undefined,
                      undefined,
                      Math.round(count)
                    );
                  }}
                />
              </PosFormField>
            </DetailGroup>
            <DetailGroup style={{ gridColumn: '1' }}>
              <PosFormField label={<Content id={ContentId.OutlierMode} />}>
                <PosEnumSelect
                  value={outlierMode}
                  disabled={disabled || listing?.isDeleted || !canSetPrice}
                  valueOptionsContent={outlierModes}
                  valueOptionsDisabled={outlierModesDisabled}
                  enableEmptySelection={false}
                  placeholderText={ContentId.SkipLogic}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(v) => {
                    onOutlierChange({
                      outlierModeNew: v as AutoPricingOutlierMode,
                    });
                  }}
                />
              </PosFormField>
            </DetailGroup>
            {outlierMode == AutoPricingOutlierMode.StandardDeviations ? (
              <DetailGroup style={{ gridColumn: '1' }}>
                <PosFormField
                  label={<Content id={ContentId.OutlierFiltering} />}
                >
                  <PosEnumSelect
                    value={outlierStandardDeviations?.toString()}
                    disabled={
                      disabled ||
                      listing?.isDeleted ||
                      !canSetPrice ||
                      outlierMode !== AutoPricingOutlierMode.StandardDeviations
                    }
                    valueOptionsContent={STANDARD_DEVIATIONS_TO_CID}
                    enableEmptySelection={true}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(v) => {
                      const value = parseInt(v ?? '');
                      if (value != null && !isNaN(value)) {
                        onOutlierChange({
                          outlierModeNew:
                            AutoPricingOutlierMode.StandardDeviations,
                          outlierStandardDeviationsNew: value,
                        });
                      } else {
                        onOutlierChange({
                          outlierModeNew:
                            AutoPricingOutlierMode.StandardDeviations,
                          outlierStandardDeviationsNew: null,
                        });
                      }
                    }}
                  />
                </PosFormField>
              </DetailGroup>
            ) : outlierMode == AutoPricingOutlierMode.KthLowest ? (
              <DetailGroup style={{ gridColumn: '1' }}>
                <Stack gap="l" alignItems="center">
                  <Content id={ContentId.IgnoreTheFirst} />
                  <PosTextField
                    value={outlierKthLowestLimit ?? ''}
                    disabled={disabled || listing?.isDeleted || !canSetPrice}
                    type="number"
                    min={0}
                    max={100}
                    rootProps={{
                      className: styles.inputInline,
                    }}
                    className={styles.inputInline}
                    onChange={(e) => {
                      const v = parseInt(e.target.value);
                      onOutlierChange({
                        outlierModeNew: AutoPricingOutlierMode.KthLowest,
                        outlierKthLowestLimitNew: v,
                      });
                    }}
                  />
                  <Content id={ContentId.MoreThan} />
                  {outlierKthLowestStrategy ==
                  OutlierModeKthLowestStrategy.Relative ? (
                    <PosTextField
                      disabled={disabled || listing?.isDeleted || !canSetPrice}
                      type="number"
                      min={-1}
                      max={100}
                      rootProps={{
                        className: styles.kthLowestInputField,
                      }}
                      className={styles.kthLowestInputField}
                      value={outlierKthLowestLimitRelativeSpacing ?? ''}
                      onChange={(e) => {
                        let v = parseInt(e.target.value);
                        if (v < 0) {
                          v = 0;
                        } else if (v > 100) {
                          v = 100;
                        }

                        if (!isNaN(v)) {
                          onOutlierChange({
                            outlierModeNew: AutoPricingOutlierMode.KthLowest,
                            outlierKthLowestLimitNew: undefined,
                            outlierKthLowestLimitAbsoluteSpacingNew: null,
                            outlierKthLowestLimitRelativeSpacingNew: v,
                          });
                        }
                      }}
                    />
                  ) : (
                    <PosTextField
                      disabled={disabled || listing?.isDeleted || !canSetPrice}
                      type="number"
                      rootProps={{
                        className: styles.kthLowestInputField,
                      }}
                      className={styles.kthLowestInputField}
                      value={outlierKthLowestLimitAbsoluteSpacing ?? ''}
                      onChange={(e) => {
                        const v = parseInt(e.target.value);
                        onOutlierChange({
                          outlierModeNew: AutoPricingOutlierMode.KthLowest,
                          outlierKthLowestLimitNew: undefined,
                          outlierKthLowestLimitAbsoluteSpacingNew: v,
                          outlierKthLowestLimitRelativeSpacingNew: null,
                        });
                      }}
                    />
                  )}
                  <ToggleGroup
                    disabled={disabled || listing?.isDeleted || !canSetPrice}
                    options={OUTLIER_MODE_KTH_LOWEST_STRATEGY}
                    value={
                      outlierKthLowestStrategy ??
                      OutlierModeKthLowestStrategy.Relative
                    }
                    onValueChange={(value) => {
                      if (!value) {
                        return;
                      }

                      const strat = value as OutlierModeKthLowestStrategy;
                      setKthLowestOutlierStrategy(strat);
                    }}
                  />
                  <Content id={ContentId.TheNextCheapest} />
                </Stack>
              </DetailGroup>
            ) : null}
            <DetailGroup style={{ gridColumn: '1' }}>
              <Stack gap="m" alignItems="center">
                <Content id={ContentId.MaxDiscountInputPrompt1} />
                <PosTextField
                  value={
                    circuitBreakerMaxDiscountVelocityPercent == null
                      ? ''
                      : circuitBreakerMaxDiscountVelocityPercent != null
                      ? roundToPrecision(
                          circuitBreakerMaxDiscountVelocityPercent * 100,
                          2
                        )
                      : ''
                  }
                  disabled={disabled || listing?.isDeleted || !canSetPrice}
                  type="number"
                  min={0}
                  max={100}
                  postfixDisplay="%"
                  rootProps={{
                    className: styles.inputInline,
                  }}
                  className={styles.inputInline}
                  onChange={(e) => {
                    clearErrors('circuitBreakerMaxDiscountVelocityPercent');
                    const v = parseFloat(e.target.value) / 100;
                    onCircuitBreakerChange(
                      undefined,
                      undefined,
                      v,
                      undefined,
                      undefined
                    );
                  }}
                />
                <Content id={ContentId.MaxDiscountInputPrompt2} />
                <PosTextField
                  disabled={disabled || listing?.isDeleted || !canSetPrice}
                  type="number"
                  min={1}
                  max={24}
                  rootProps={{
                    className: styles.inputInline,
                  }}
                  className={styles.inputInline}
                  value={circuitBreakerMaxDiscountVelocityTicksInHours ?? ''}
                  onChange={(e) => {
                    const v = parseInt(e.target.value);
                    onCircuitBreakerChange(
                      undefined,
                      undefined,
                      undefined,
                      v,
                      undefined
                    );
                  }}
                />
                <Content id={ContentId.MaxDiscountInputPrompt3} />
              </Stack>
            </DetailGroup>
            <DetailGroup>
              <ButtonWithIcon
                variant="text"
                icon={<ResetIcon withHoverEffect />}
                textContentId={ContentId.ResetSettings}
                onClick={onResetAdvanceSettings}
              />
            </DetailGroup>
          </>
        )}
      </SectionContent>
    </DetailSection>
  );
};
