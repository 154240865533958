import { ComponentProps, useCallback } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useEventHubContext } from 'src/contexts/EventHubContext/EventHubContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { GroupListingsModal } from 'src/modals/GroupListings';
import { SplitIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import { Event } from 'src/WebApiController';

export const EnterCompanionMode = ({
  event,
  disabled,
}: ComponentProps<typeof GroupListingsModal> & {
  event?: Event;
  disabled?: boolean;
}) => {
  const { onStartSession, onCancelSession, sessionRole, enabled } =
    useEventHubContext();

  const onToggleComapnionMode = useCallback(() => {
    if (sessionRole) {
      onCancelSession();
    } else {
      onStartSession();
    }
  }, [onStartSession, onCancelSession, sessionRole]);

  if (!event?.viagId || !enabled) {
    return null;
  }
  return (
    <PosDropdownItem
      key="EnterCompanionMode"
      onClick={onToggleComapnionMode}
      disabled={disabled}
    >
      <Stack alignItems="center" gap="m" width="full">
        <SplitIcon
          withHoverEffect
          stroke={vars.color.textBrand}
          fill={vars.color.textPrimary}
          size={vars.iconSize.m}
        />
        <Content
          id={
            sessionRole
              ? ContentId.ExitCompanionMode
              : ContentId.EnterCompanionMode
          }
        />
      </Stack>
    </PosDropdownItem>
  );
};
