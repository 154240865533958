import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ProcessingIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M6.101 3.846C10.428.817 16.31 1.595 19.56 5.596l-.044-.054L21.26 4.32l.393 6.385-5.865-2.553 2.068-1.449-.054-.065C15.21 3.622 10.686 3.018 7.27 5.283l-.176.12a8.176 8.176 0 00-3.138 4.29L2.34 8.781a10.134 10.134 0 013.761-4.935zm11.791 16.308c-4.326 3.029-10.208 2.251-13.458-1.75l.044.054-1.745 1.222-.393-6.385 5.865 2.553-2.068 1.449.054.065c2.593 3.016 7.117 3.62 10.533 1.355l.176-.12a8.176 8.176 0 003.138-4.29l1.615.912a10.134 10.134 0 01-3.76 4.935z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
