import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const TileViewIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 24" fill="none">
      <mask id="path-1-inside-1_419_10262" fill="white">
        <rect x="2" y="3" width="20" height="18" rx="0.3" />
      </mask>
      <rect
        x="2"
        y="3"
        width="20"
        height="18"
        rx="0.3"
        stroke={props.fill}
        strokeWidth="4"
        mask="url(#path-1-inside-1_419_10262)"
      />
      <rect x="2" y="10" width="20" height="4" rx="0.3" fill={props.fill} />
    </SvgWrapper>
  );
};
