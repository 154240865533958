import { createColumnHelper } from '@tanstack/react-table';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_SM,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { VendorAccountsActionsCell } from 'src/modals/VendorDetails/components/VendorAccounts/components/VendorAccountsTable/VendorAccountsActionsCell';
import { VendorsAccountsTableColumnId } from 'src/modals/VendorDetails/components/VendorAccounts/components/VendorAccountsTable/vendorsAccountsColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseVendorAccount } from 'src/WebApiController';

export type PurchaseVendorAccountActions = {
  editVendorAccount: (vendorAccount: PurchaseVendorAccount) => void;
};

export type PurchaseVendorAccountWithActions = {
  vendorAccount: PurchaseVendorAccount;
  vendorAccountActions: PurchaseVendorAccountActions;
};

const columnHelper =
  createColumnHelper<PurchaseVendorAccountWithActions | null>();
export const VENDORS_ACCOUNTS_TABLE_COLUMNS_CONFIG = {
  [VendorsAccountsTableColumnId.VendorAccount]: columnHelper.accessor(
    (data) => data?.vendorAccount?.name,
    {
      id: VendorsAccountsTableColumnId.VendorAccount,
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.AccountName} />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_DEFAULT_SIZE_BASE,
      maxSize: COLUMN_DEFAULT_SIZE_BASE,
      enableSorting: false,
      enableResizing: false,
      cell: function Cell({ getValue }) {
        return <TableCellDiv align="left">{getValue()}</TableCellDiv>;
      },
    }
  ),
  [VendorsAccountsTableColumnId.Email]: columnHelper.accessor(
    (data) => data?.vendorAccount?.email,
    {
      id: VendorsAccountsTableColumnId.Email,
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.AccountEmail} />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_DEFAULT_SIZE_BASE,
      maxSize: COLUMN_DEFAULT_SIZE_BASE,
      enableSorting: false,
      enableResizing: false,
      cell: function Cell({ getValue }) {
        return <TableCellDiv align="left">{getValue()}</TableCellDiv>;
      },
    }
  ),
  [VendorsAccountsTableColumnId.Actions]: columnHelper.accessor(() => null, {
    id: VendorsAccountsTableColumnId.Actions,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.Actions} />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_SM,
    minSize: COLUMN_DEFAULT_SIZE_SM,
    maxSize: COLUMN_DEFAULT_SIZE_SM,
    enableSorting: false,
    enableResizing: false,
    cell: function Cell({ row: { original } }) {
      return (
        <TableCellDiv align="right" showTooltip={false}>
          <VendorAccountsActionsCell vendorAccountWithActions={original} />
        </TableCellDiv>
      );
    },
  }),
};
