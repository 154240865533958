import clsx from 'clsx';
import { ComponentProps, PropsWithChildren, ReactNode } from 'react';
import {
  CircleProgressBar as OriginalCircleProgressBar,
  CircleProgressBarProps,
} from 'src/core/POS/CircleProgressBar';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { shared, vars } from 'src/core/themes';
import { mergeProps } from 'src/core/utils';

import * as styles from './MetricTile.css';
import { ArrowDownIcon7, ArrowUpIcon7 } from 'src/svgs/Viagogo';
import { PosIconProps } from 'src/svgs/SvgWrapper';
import { getCurrentLanguage } from 'src/utils/localeUtils';

type RootProps = Pick<ComponentProps<'div'>, 'style' | 'className'> &
  PropsWithChildren<object>;

export function Root({ children, ...props }: RootProps) {
  return (
    <div {...mergeProps({ className: styles.root }, props)}>{children}</div>
  );
}

export function Title({
  isCompact,
  children,
}: { isCompact?: boolean } & PropsWithChildren<object>) {
  return isCompact ? (
    <div className={styles.titleWrapper}>
      <div className={styles.rotatedTitle}>{children}</div>
    </div>
  ) : (
    <div className={styles.title}>{children}</div>
  );
}

export function Metric({
  isCompact: isCompact,
  showLoadingPlaceholders,
  children,
}: PropsWithChildren<{
  isCompact?: boolean;
  /**
   * Whether to show a placeholder if the metric value is `undefined`.
   */
  showLoadingPlaceholders?: boolean;
}>) {
  return (
    <div
      className={clsx(styles.metric, {
        [styles.compactMetric]: isCompact,
      })}
    >
      {showLoadingPlaceholders && children === undefined ? (
        <ContentPlaceholder
          width="200px"
          height="1em"
          className={styles.metricValuePlaceholder}
        />
      ) : (
        children
      )}
    </div>
  );
}

export function FractionMetricText({
  numerator,
  denominator,
}: {
  numerator: ReactNode;
  denominator: ReactNode;
}) {
  return (
    <div className={styles.metricFractionContainer}>
      <div>{numerator}</div>
      <hr className={styles.metricFractionDivider} />
      <div className={shared.typography.title4}>{denominator}</div>
    </div>
  );
}

export function CircleProgressBar({
  ...props
}: Pick<CircleProgressBarProps, 'value' | 'children' | 'size'>) {
  return <OriginalCircleProgressBar {...props} />;
}

export function Link({ href, children }: PropsWithChildren<{ href?: string }>) {
  return (
    <a className={styles.link} href={href}>
      {children}
    </a>
  );
}

/**
 * This component can be used for the "See All" link instead of `Link`.
 * @param props
 */
export function SeeAllLink({ href }: { href?: string }) {
  return <Link href={href}>See All &gt;</Link>;
}

export function PercentChangeMetricText({
  formattedCurrentValue,
  currentValue,
  previousValue,
}: {
  formattedCurrentValue: string,
  currentValue: number,
  previousValue: number,
  }) {
  let percentageChangeValue: number | null = null;
  if (currentValue === previousValue) {
    percentageChangeValue = 0;
  }
  else if (currentValue !== 0 && previousValue !== 0) {
    percentageChangeValue = (currentValue - previousValue) / previousValue;
  }

  const percentageNumberFormatter = new Intl.NumberFormat(getCurrentLanguage(), {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const absPercentageChange = percentageChangeValue
    ? percentageNumberFormatter.format(Math.abs(percentageChangeValue))
    : "";
  const PercentageChangeArrowIcon = (props: PosIconProps) => {
    if (!percentageChangeValue) {
      return <></>;
    }

    return percentageChangeValue && percentageChangeValue < 0
      ? <ArrowDownIcon7 {...props} />
      : <ArrowUpIcon7 {...props} />
  };

  return (
    <div className={styles.metricPercentageChangeOuterContainer}>
      <div>{formattedCurrentValue}</div>
      <div className={styles.metricPercentageChangeInnerContainer}>
        <PercentageChangeArrowIcon size={vars.iconSize.m} />
        <div>{absPercentageChange}</div>
      </div>
    </div>
  );
}
