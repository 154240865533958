import { vars } from 'src/core/themes';

import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const CopyIcon2 = ({ size, stroke, ...props }: PosIconProps) => {
  const s = size ?? vars.iconSize.l;

  return (
    <SvgWrapper size={s} {...props} viewBox={`0 0 20 20`}>
      <path
        d="M12.333 2.4811L15.2024 5.1693C15.4322 5.38451 15.5625 5.68523 15.5625 6V12.3083C15.5625 13.0873 14.931 13.7188 14.1521 13.7188H12.5H7.8792C7.10023 13.7188 6.46875 13.0873 6.46875 12.3083V5.2899V3.66045C6.46875 2.88148 7.10023 2.25 7.8792 2.25H11.7483C11.9655 2.25 12.1745 2.33262 12.333 2.4811Z"
        stroke={stroke || '#643CA2'}
      />
      <path
        d="M11.1521 16.7188H4.8792C4.10023 16.7188 3.46875 16.0873 3.46875 15.3083V6.66045C3.46875 5.88148 4.10023 5.25 4.8792 5.25H8.74833C8.9655 5.25 9.17454 5.33262 9.33303 5.4811L12.2024 8.1693C12.4322 8.38451 12.5625 8.68523 12.5625 9V15.3083C12.5625 16.0873 11.931 16.7188 11.1521 16.7188Z"
        stroke={stroke || '#643CA2'}
      />
    </SvgWrapper>
  );
};
