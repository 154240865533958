import { throttle } from 'lodash-es';
import React, { useCallback, useMemo, useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { Stack } from 'src/core/ui/Stack';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { ContentId } from 'src/utils/constants/contentId';

const enum RelativeAbsoluteOptions {
  Relative = 'relative',
  Absolute = 'absolute',
}

export const enum LanguagePreference {
  Above_Below = 'aboveBelow',
  Increase_Decrease = 'increaseDecrease',
}

const getRelativeAbsoluteOptions = (absoluteUnit: '$' | '123') => [
  { value: RelativeAbsoluteOptions.Relative, children: '%' },
  { value: RelativeAbsoluteOptions.Absolute, children: absoluteUnit },
];

const getAboveBelowOptions = (languagePreference: LanguagePreference) => {
  const aboveText =
    languagePreference === LanguagePreference.Above_Below ? (
      <Content id={ContentId.Above} />
    ) : (
      <Content id={ContentId.Increase} />
    );
  const belowText =
    languagePreference === LanguagePreference.Above_Below ? (
      <Content id={ContentId.Below} />
    ) : (
      <Content id={ContentId.Decrease} />
    );
  return [
    { value: 'true', children: aboveText },
    { value: 'false', children: belowText },
  ];
};

type BulkEditNumberProps = {
  initAmount?: number | null;
  isLoading: boolean;
  onChange: (value: number, isRelative: boolean, isAbove: boolean) => void;
  showAbsolute?: boolean;
  showApplyToAll?: boolean;
  absoluteUnit?: '$' | '123';
  languagePreference?: LanguagePreference;
};

export const BulkEditNumber: React.FC<BulkEditNumberProps> = ({
  initAmount,
  isLoading,
  onChange,
  showAbsolute = false,
  showApplyToAll = false,
  absoluteUnit = '$',
  languagePreference = LanguagePreference.Above_Below,
}) => {
  const [amount, setAmount] = useState<number>(initAmount ?? 0);
  const [isRelative, setIsRelative] = useState<boolean>(true);
  const [isAbove, setIsAbove] = useState<boolean>(false);

  const onValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseFloat(event.target.value);
      if (value < 0 || isNaN(value)) {
        return;
      }
      // we can't decrease by 100 or more % because that will result in zero
      if (!isAbove && isRelative && value > 99) {
        return;
      }
      setAmount(value);

      // Don't call onChange if we are showing apply to all button
      !showApplyToAll && onChange(value, isRelative, isAbove);
    },
    [isAbove, isRelative, onChange, showApplyToAll]
  );

  const onRelativeChange = useCallback(
    (value: string) => {
      const updatedRelative = value === RelativeAbsoluteOptions.Relative;
      setIsRelative(updatedRelative);
      !showApplyToAll && onChange(amount, updatedRelative, isAbove);
    },
    [amount, isAbove, onChange, showApplyToAll]
  );
  const onAboveChange = useCallback(
    (value: string) => {
      const updatedIsAbove = value === 'true';
      setIsAbove(updatedIsAbove);
      !showApplyToAll && onChange(amount, isRelative, updatedIsAbove);
    },
    [amount, isRelative, onChange, showApplyToAll]
  );

  // Throttle the onChange call to avoid calling it too often, once per 800ms
  const onApplyToAll = useMemo(() => {
    if (showApplyToAll) {
      return throttle(() => onChange(amount, isRelative, isAbove), 800, {
        trailing: false,
      });
    }
    return () => undefined;
  }, [amount, isAbove, isRelative, onChange, showApplyToAll]);

  return (
    <Stack direction="row" gap="m" alignItems="center" width="full">
      <PosTextField
        value={amount}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        disabled={isLoading}
        min={0}
        type="number"
        inputMode="numeric"
        onChange={onValueChange}
        postfixDisplay={
          <>
            {showAbsolute ? (
              <ToggleGroup
                itemStyle={{
                  fontSize: vars.typography.fontSize['xs'],
                  height: '22px',
                }}
                options={getRelativeAbsoluteOptions(absoluteUnit)}
                disabled={isLoading}
                value={
                  isRelative
                    ? RelativeAbsoluteOptions.Relative
                    : RelativeAbsoluteOptions.Absolute
                }
                defaultValue={RelativeAbsoluteOptions.Relative}
                onValueChange={onRelativeChange}
              />
            ) : (
              <>% </>
            )}
            <ToggleGroup
              itemStyle={{
                fontSize: vars.typography.fontSize['xs'],
                height: '22px',
              }}
              disabled={isLoading}
              options={getAboveBelowOptions(languagePreference)}
              value={isAbove ? 'true' : 'false'}
              defaultValue={'false'}
              onValueChange={onAboveChange}
            />
          </>
        }
      />
      {showApplyToAll && (
        <Button
          disabled={isLoading || !amount}
          onClick={onApplyToAll}
          variant="text"
        >
          <Content id={ContentId.Apply} />
        </Button>
      )}
    </Stack>
  );
};
