import { ContentId } from "src/utils/constants/contentId";
import { AutoPricingCompListingMode } from "src/WebApiController";
import { useAutoPricingSettingsFormContext } from "../useAutoPricingSettingsFormContext";
import { PosEnumSelect } from "src/core/POS/PosSelect";
import { COMP_LISTING_MODE_TO_FLUENT_CID } from "src/utils/constants/contentIdMaps";
import { Controller } from "react-hook-form";
import { useMemo } from "react";
import { Content } from "src/contexts/ContentContext";

export const AutoPricingCompListingModeSelector = ({
  allowSeatScoreCompListingMode,
  disabled,
}: {
  allowSeatScoreCompListingMode: boolean,
  disabled: boolean,
}) => {
  const methods = useAutoPricingSettingsFormContext();
  const {
    control,
    formState,
    watch,
  } = methods;

  const initialCompListingMode = formState.defaultValues?.compListingMode;

  const { compListingMode } = watch();

  const { valueOptionsContent, valueOptionsDisabled } = useMemo(() => {
    const compListingModes: Record<string, ContentId> = {
      [AutoPricingCompListingMode.SameEvent]: ContentId.InComparableZones,
    };
    if (allowSeatScoreCompListingMode) {
      compListingModes[AutoPricingCompListingMode.QualityScore] =
        COMP_LISTING_MODE_TO_FLUENT_CID[AutoPricingCompListingMode.QualityScore];
    }

    let compListingModesDisabled: Record<string, ContentId> | undefined = undefined;
    if (initialCompListingMode && !!!compListingModes[initialCompListingMode]) {
      const initialModeContentId = COMP_LISTING_MODE_TO_FLUENT_CID[initialCompListingMode];
      compListingModesDisabled = { [initialCompListingMode]: initialModeContentId };
      compListingModes[initialCompListingMode] = initialModeContentId;
    }

    return {
      valueOptionsContent: compListingModes,
      valueOptionsDisabled: compListingModesDisabled,
    }
  }, [methods, compListingMode]);

  const useSelector = Object.keys(valueOptionsContent).length > 1 || valueOptionsDisabled;

  return (
    <Controller
      control={control}
      name='compListingMode'
      defaultValue={AutoPricingCompListingMode.SameEvent}
      render={({
        field: { value, onChange, ...field }
      }) => {
        return (
          useSelector ? (
            <PosEnumSelect
              style={{ width: 'fit-content' }}
              disabled={disabled}
              value={value}
              valueOptionsContent={valueOptionsContent}
              valueOptionsDisabled={valueOptionsDisabled}
              placeholderText={ContentId.None}
              onClick={(e) => e.stopPropagation()}
              onChange={(v) => onChange(v as AutoPricingCompListingMode)}
              {...field}
            />
          ) : (
            <Content id={valueOptionsContent[value as string]} />
          )
        );
      }}
    />
  );
};