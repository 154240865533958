import { useMemo, useState } from 'react';
import { MobileFilterToolbar } from 'src/components/FilterToolbar';
import * as styles from 'src/components/MainFilterBar/MainFilterBar.css';
import { Content } from 'src/contexts/ContentContext/Content';
import { ProcessedQuickFilter } from 'src/hooks/useQuickFilters';
import { ContentId } from 'src/utils/constants/contentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';

const ALL_FILTER_ID = 'all';
export const SidePanelFilters = <TQuery extends QueryWithViewMode>({
  setFilterQuery,
  quickFilters,
  initialQuery,
}: {
  setFilterQuery: (query: TQuery) => void;
  quickFilters: ProcessedQuickFilter<TQuery>[];
  initialQuery: TQuery;
}) => {
  const [selectedQuickFilterId, setSelectedQuickFilterId] =
    useState(ALL_FILTER_ID);

  const finalQuickFilters = useMemo(() => {
    return quickFilters.map(
      ({ id, name, isCustomFilter, isHidden, data: query }) => {
        return {
          id,
          name,
          isCustomFilter,
          isHidden,
          isSelected: selectedQuickFilterId === id,
          onClick: () => {
            setFilterQuery(query);
            setSelectedQuickFilterId(id);
          },
        };
      }
    );
  }, [quickFilters, selectedQuickFilterId, setFilterQuery]);

  const allFilter = useMemo(
    () => [
      {
        id: ALL_FILTER_ID,
        name: <Content id={ContentId.All} />,
        isHidden: false,
        isSelected: selectedQuickFilterId === ALL_FILTER_ID,
        onClick: () => {
          setSelectedQuickFilterId(ALL_FILTER_ID);
          setFilterQuery(initialQuery);
        },
        isCustomFilter: false,
      },
    ],
    [initialQuery, selectedQuickFilterId, setFilterQuery]
  );

  const mergedFilters = useMemo(() => {
    return finalQuickFilters ? [...allFilter, ...finalQuickFilters] : allFilter;
  }, [finalQuickFilters, allFilter]);

  return (
    <div className={styles.sideTableFilterBarDiv}>
      <MobileFilterToolbar
        filters={mergedFilters}
        defaultValue={ALL_FILTER_ID}
        isSideTable
      />
    </div>
  );
};
