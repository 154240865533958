import { ComponentProps, useContext } from 'react';
import { FormProvider } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { typography } from 'src/core/themes/shared.css';
import { Stack } from 'src/core/ui';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import {
  ModalBody,
  ModalBodyDataContainer,
  ModalFooter,
} from 'src/modals/Modal/Modal.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { PermissionAccessArea, SellerRoleInput } from 'src/WebApiController';

import { SellerPermissionSelectionSection } from '../SellerRoleModal/components/SellerPermissionSelectionSection';
import * as styles from './SellerPermissionDisplayModal.css';
import { SellerPermissionDisplayModalProps } from './SellerPermissionDisplayModal.types';

export const SellerPermissionDisplayModalBody = ({
  cancelTo,
  isLoading,
}: Omit<
  ComponentProps<typeof FormProvider<SellerRoleInput, unknown>>,
  'children'
> &
  SellerPermissionDisplayModalProps) => {
  const { closeModal } = useContext(ModalContext);

  return (
    <>
      <CancellableFormHeader cancelTo={cancelTo}>
        <ConnectedEventEntityHeader
          title={
            <Stack gap="m" alignItems="center">
              <Content id={ContentId.Permissions} />
            </Stack>
          }
        />
      </CancellableFormHeader>

      <ModalBody>
        <ModalBodyDataContainer>
          {isLoading ? (
            <PosSpinner />
          ) : (
            <Stack
              direction="column"
              gap="l"
              className={styles.permissionsContainer}
            >
              <span className={typography.body1}>
                <Content id={ContentId.CombinedPermissionViewDescription} />
              </span>

              {Object.keys(PermissionAccessArea).map((area) => (
                <SellerPermissionSelectionSection
                  key={area}
                  area={area as PermissionAccessArea}
                  // We disable the selection since we expect this to be a display modal
                  disabled
                />
              ))}
            </Stack>
          )}
        </ModalBodyDataContainer>
      </ModalBody>

      <ModalFooter>
        <Stack gap="m" alignItems="center">
          <CancelButton onClick={closeModal} textContentId={ContentId.Close} />
        </Stack>
      </ModalFooter>
    </>
  );
};
