import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { CopyToClipboard } from 'src/core/ui/CopyToClipboard';

import * as styles from './LinkAncCopy.css';

interface LinkAndCopyProps {
  valueToCopy: string | number;
  relativeUrl: string;
  linkChildren?: ReactNode;
  copyIconSize?: string;
}

export const LinkAndCopy = ({
  relativeUrl,
  valueToCopy,
  linkChildren,
  copyIconSize,
}: LinkAndCopyProps) => {
  return (
    <Stack gap="s" alignItems="center">
      <NavLink
        title={relativeUrl}
        tag={Link}
        className="nav-link"
        to={relativeUrl}
      >
        <span className={styles.value}>{linkChildren || valueToCopy}</span>
      </NavLink>
      <CopyToClipboard
        textToCopy={valueToCopy}
        copyIconColor={vars.color.textBrand}
        iconSize={copyIconSize}
      />
    </Stack>
  );
};
