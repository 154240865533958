import { useState } from 'react';
import { BulkEditTagsActionPermissionsHandler } from 'src/components/common/BulkActions/BulkEditTagsAction/BulkEditTagsActionPermissionsHandler';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useBulkEditTagsAction } from 'src/components/Sales/SalesActionDropdown/components/BulkEditTagsAction';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { EventWithData } from 'src/WebApiController';
import { ActionOutboxEntityType } from 'src/WebApiController';

import { useSalesBulkActionsState } from '../../useSalesBulkActionsState';

interface TagsActionsProps {
  event: EventWithData['event'];
}

export const TagsActions = ({ event }: TagsActionsProps) => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const [isLoading, setIsLoading] = useState(false);
  const { filterQueryWithEventIds } = useSalesBulkActionsState();

  const bulkUpdateTags = useBulkEditTagsAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event
  );

  return (
    <BulkEditTagsActionPermissionsHandler
      entityType={ActionOutboxEntityType.Sale}
    >
      <PosDropdown
        key="sales-tags-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Tags} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {bulkUpdateTags.dropDown}
      </PosDropdown>
    </BulkEditTagsActionPermissionsHandler>
  );
};
