import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ArtistIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M19.5 15.82c0 .512-.418.93-.929.93h-.139v-3.485h.14c.51 0 .929.419.929.93v1.626zm-7.294 4.786c-1.44 0-3.995-2.602-4.32-4.785l-.018-.174c.476.32 1.056.508 1.69.508 1.162 0 2.137-.65 2.602-1.533.093-.14.186-.186.325-.186.14 0 .279.093.325.186a2.953 2.953 0 002.602 1.533c.385 0 .752-.075 1.09-.207-.359 2.156-2.883 4.658-4.296 4.658zm-4.181-7.434a6.034 6.034 0 005.482-3.53h1.394l1.347 1.3c.372.326.558.837.511 1.348l-.061.938h-9.08l-.011-.102c.14 0 .325.046.418.046zm-2.09 3.624h-.14a.932.932 0 01-.93-.929v-1.626c0-.511.419-.93.93-.93h.14v3.485zm12.636-4.878h-.418a2.894 2.894 0 00-.929-1.998l-1.719-1.672h-2.927l-.186.464c-.65 1.859-2.416 3.067-4.367 3.067-.279 0-.558-.047-.883-.093l-.511-.047-.093.233h-.836c-.14-1.58.51-2.974.557-3.067l.186-.372L5.47 7.04l2.324-.604L6.77 3.973c1.58-.418 5.343-1.161 7.806.325 1.254.744 1.998 1.859 2.276 3.531l.047.326.279.14c.79.464 1.254 1.16 1.393 2.136.047.372.047.79.047 1.208l.65-.232c.233-.093.511-.14.79-.093 0-.372 0-.743-.046-1.115A4.37 4.37 0 0018.2 7.225c-.418-1.904-1.394-3.298-2.88-4.181C11.555.86 5.84 2.765 5.561 2.858l-.696.232 1.022 2.416-2.788.697 1.719 2.37c-.418.976-.697 2.416-.465 3.81-.557.418-.882 1.068-.882 1.812v1.626a2.3 2.3 0 002.323 2.323h1.44C8.35 20.188 10.487 22 12.206 22c1.673 0 3.857-1.812 4.972-3.856h1.393c1.301 0 2.323-1.022 2.323-2.277v-1.626a2.3 2.3 0 00-2.323-2.323z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
