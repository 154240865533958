import { useMemo, useState } from 'react';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  ActivityLogTable,
  ActivityLogTableDataProps,
} from 'src/tables/ActivityLogTable';
import type { ActivityLog } from 'src/WebApiController';
import { ActionType, Feature } from 'src/WebApiController';
import styled from 'styled-components/macro';

import * as styles from './ActivityLog.css';
import {} from './ActivityLog.styled';
import { ActivityLogFilter, ActivityLogFilterQuery } from './ActivityLogFilter';

type ActivityLogUiProps = ActivityLogTableDataProps & object;

export interface GroupedActivityLog extends ActivityLog {
  activityLogs: ActivityLog[];
}

export function ActivityLog({
  activityLogs,
  count,
  failedToRetrieveData,
}: ActivityLogUiProps) {
  const [filter, setFilter] = useState<ActivityLogFilterQuery>({
    actionTypes: [],
    auditActionTypes: [],
    initiatedBy: [],
  });

  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  const filteredData = useMemo(() => {
    return activityLogs?.filter((activityLog) => {
      let allow = true;
      allow =
        filter?.date?.start == null ||
        new Date(filter.date?.start) <= new Date(activityLog.actionDate);
      allow &&=
        filter?.date?.end == null ||
        new Date(filter.date?.end) >= new Date(activityLog.actionDate);
      allow &&=
        ((filter?.actionTypes == null || filter?.actionTypes.length === 0) &&
          (filter?.auditActionTypes == null ||
            filter?.auditActionTypes.length === 0)) ||
        filter.actionTypes.includes(activityLog.actionType) ||
        (activityLog.auditActionType != null &&
          filter.auditActionTypes.includes(activityLog.auditActionType));
      allow &&=
        filter?.initiatedBy == null ||
        filter?.initiatedBy.length === 0 ||
        (activityLog.initiatedBy != null &&
          activityLog.initiatedBy.username != null &&
          filter.initiatedBy.includes(activityLog.initiatedBy.username)) ||
        (activityLog.initiatedByImpersonated != null &&
          activityLog.initiatedByImpersonated.username != null &&
          filter.initiatedBy.includes(
            activityLog.initiatedByImpersonated.username
          ));
      allow &&=
        hasAutoPricingFeature ||
        activityLog.actionType !== ActionType.ListingAutoPricingConfig;
      return allow;
    });
  }, [filter, activityLogs, hasAutoPricingFeature]);

  const groupedData: GroupedActivityLog[] = useMemo(() => {
    if (!filteredData) {
      return [];
    }

    const result: GroupedActivityLog[] = [];
    let currentGroup: GroupedActivityLog | null = null;

    const sortedFilteredData = filteredData.sort((a, b) =>
      a.actionDate.localeCompare(b.actionDate)
    );
    sortedFilteredData.forEach((activityLog) => {
      if (
        currentGroup &&
        currentGroup.actionTypeDisplay === activityLog.actionTypeDisplay &&
        currentGroup.initiatedBy.userId === activityLog.initiatedBy.userId
      ) {
        currentGroup.activityLogs.push(activityLog);
      } else {
        currentGroup = {
          ...activityLog,
          actionTypeDisplay: activityLog.actionTypeDisplay,
          initiatedBy: activityLog.initiatedBy,
          activityLogs: [activityLog],
        };
        result.push(currentGroup);
      }
    });

    return result;
  }, [filteredData]);

  return (
    <div className={styles.activityLogContainer}>
      {count > 0 && (
        <div className={styles.activityLogTitleBarDiv}>
          <StyledDiv>
            <ActivityLogFilter
              currentFilter={filter}
              onCurrentFilterUpdated={setFilter}
              allActionTypes={activityLogs?.map((log) => ({
                actionType: log.actionType,
                auditActionType: log.auditActionType,
                display: log.actionTypeDisplay,
              }))}
              allUsers={activityLogs
                ?.filter((log) => log.initiatedBy?.username && true)
                .map((log) => log.initiatedBy?.username ?? null)}
            />
          </StyledDiv>
        </div>
      )}
      <ActivityLogTable
        count={count}
        activityLogs={filteredData}
        groupedActivityLogs={groupedData}
        failedToRetrieveData={failedToRetrieveData}
      />
    </div>
  );
}

const StyledDiv = styled.span`
  float: left;
`;
