import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ToBeDeterminedIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M8.167 2.533V7.94h-1.5V6.08H3.493v3.21h17v-3.21H18.72l.833-1.52H22v17.063H2V4.561h4.667V2.533h1.5zM20.5 10.812h-17v9.292h17v-9.292zm-9.013 3.014a1.348 1.348 0 011.456.283 1.3 1.3 0 01.288 1.435c-.208.491-.697.81-1.237.808a1.313 1.313 0 01-1.327-1.308 1.313 1.313 0 01.82-1.218zm4 0a1.348 1.348 0 011.456.283 1.3 1.3 0 01.288 1.435c-.208.491-.697.81-1.237.808a1.313 1.313 0 01-1.327-1.308 1.313 1.313 0 01.82-1.218zm-8.667 0a1.348 1.348 0 011.456.283 1.3 1.3 0 01.288 1.435c-.208.491-.697.81-1.237.808A1.313 1.313 0 016 15.044a1.313 1.313 0 01.82-1.218zM17.333 2.533V7.8h-1.479V5.99h-6.52l.821-1.482h5.7V2.533h1.478z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
