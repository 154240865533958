import { ComponentProps, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { reset } from 'svg-pan-zoom';

enum SaveArtifactType {
  SaveArtifact = 'SaveArtifact',
  ResetArtifact = 'ResetArtifact',
}

export type ResetFulfillmentDialgoProps = ComponentProps<typeof RSModal>;

export const ResetFulfillmentDialog = ({
  onOkay,
  onCancel,
  ...rest
}: ResetFulfillmentDialgoProps) => {
  const [resetArtifacts, setResetArtifacts] = useState(false);

  const ResetFulfillmentDialogBody = () => (
    <div>
      <RadioGroup
        onValueChange={(value) => {
          if (value === SaveArtifactType.SaveArtifact) {
            setResetArtifacts(false);
          } else {
            setResetArtifacts(true);
          }
        }}
        value={
          resetArtifacts
            ? SaveArtifactType.ResetArtifact
            : SaveArtifactType.SaveArtifact
        }
      >
        <Stack direction="column" gap="m">
          <Radio
            value={SaveArtifactType.SaveArtifact}
            label={<Content id={ContentId.KeepExistingTicketsOption} />}
          />
          <Radio
            value={SaveArtifactType.ResetArtifact}
            label={<Content id={ContentId.RemoveExistingTicketsOption} />}
          />
        </Stack>
      </RadioGroup>
    </div>
  );

  return (
    <GenericDialog
      {...rest}
      size={'md'}
      header={<Content id={ContentId.ResetFulfillment} />}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      footer={
        <>
          <CancelButton textContentId={ContentId.Cancel} onClick={onCancel} />
          <OkButton
            textContentId={ContentId.ResetFulfillment}
            onClick={() => onOkay(resetArtifacts)}
          />
        </>
      }
    >
      <ResetFulfillmentDialogBody />
    </GenericDialog>
  );
};
