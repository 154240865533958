import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

export const useNetworkState = (offlineTimeoutMs = 0) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const setOffline = debounce(() => {
    setIsOnline(false);
  }, offlineTimeoutMs);

  const setOnline = useCallback(() => {
    setOffline.cancel();
    setIsOnline(true);
  }, [setOffline]);

  useEffect(() => {
    const handleStatusChange = () =>
      navigator.onLine ? setOnline() : setOffline();

    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline, setOffline, setOnline]);

  return isOnline;
};
