import { ReactNode, useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import { useFetchLoginContext } from 'src/hooks/useFetchLoginContext';
import { SignUpPathname } from 'src/navigations/Routes/SignUp/constants';
import { LoginContext } from 'src/WebApiController';

import { isAllowedToSignUp } from './SignUpRedirect';

interface SignUpDisplayValidatorProps {
  children: ReactNode;
}

export const SignUpValidator = ({ children }: SignUpDisplayValidatorProps) => {
  const [loginContextLoaded, setLoginContextLoaded] = useState<boolean>(false);
  const { loginContext, setLoginContext } = useAppContext();
  const { fetchLoginContext } = useFetchLoginContext();

  const navigate = useNavigate();

  useEffect(() => {
    fetchLoginContext(
      (loginContext: LoginContext) => {
        setLoginContext(loginContext);
        setLoginContextLoaded(true);
      },
      () => {
        setLoginContextLoaded(true);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!loginContextLoaded) {
    return null;
  }

  const hasSession = !!loginContext;

  // If there is currently a login context - navigate to home
  if (hasSession) {
    return <Navigate to="/home" />;
  }

  if (location.pathname !== SignUpPathname) {
    if (isAllowedToSignUp()) {
      navigate('/access', { replace: true });
    } else {
      // If the pathname == start, and it's not allowing to sign-up, it will redirect to login
      return <Navigate to="/login" />;
    }
  }

  // Else, render signup
  return <>{children}</>;
};
