import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const FilterToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: ${() => vars.spacing.sm};
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  width: 100%;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(media.breakpoints.xs, `flex-wrap: nowrap`)}`}
`;

export const FilterCount = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundBrandActive};
  width: ${() => vars.iconSize.s};
  height: ${() => vars.iconSize.s};
  color: ${(props) => props.theme.colors.textInverted};
  align-items: center;
  font-size: ${() => vars.typography.fontSize['xs']};
  line-height: 12px;
  justify-content: center;
  border-radius: ${() => vars.spacing.sm};
  display: flex;
`;

export const CustomFilterPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${() => vars.spacing.sm};
  padding: ${() => vars.spacing.lg};
  width: 280px;
  border-radius: ${() => vars.borderRadius.s};
  box-shadow: ${(props) => props.theme.shadow(4, 'c')};
  max-width: var(--radix-popover-content-available-width);
`;

export const CustomFilterPopoverTitle = styled.div`
  font-weight: 600;
`;

export const CustomFilterItem = styled.div`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
  border-radius: 4px;
  padding: ${() => vars.spacing.sm};
  label {
    margin-bottom: 0;
  }
  label > div:nth-child(2) {
    margin-left: ${() => vars.spacing.sm};
  }
  svg {
    display: block;
  }
`;

export const CustomFilterIconsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
`;
