import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import reachDarkIcon from 'src/assets/icons/reach_pro/reach_pro_dark.ico';
import reachLightIcon from 'src/assets/icons/reach_pro/reach_pro_light.ico';
import { isReachProDomain } from 'src/components/SignUp/SignUpRedirect';

interface ReachProDocumentInfoProps {
  children: ReactNode;
}

export const DocumentInfo = ({ children }: ReachProDocumentInfoProps) => {
  const isReachProDomainActive = isReachProDomain();
  const isDarkTheme =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches;

  const reachPropIco = isDarkTheme ? reachLightIcon : reachDarkIcon;

  return (
    <>
      {isReachProDomainActive ? (
        <Helmet>
          <title>Reach Pro</title>
          <link
            data-react-helmet="true"
            rel="icon"
            type="image/png"
            href={reachPropIco}
          />
        </Helmet>
      ) : (
        <Helmet>
          <title>StubHub Pro</title>
          <link
            data-react-helmet="true"
            rel="icon"
            type="image/png"
            sizes="96x96"
            href="/favicon-96x96.png"
          />
          <link
            data-react-helmet="true"
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
        </Helmet>
      )}
      {children}
    </>
  );
};
