import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { vars } from 'src/core/themes';
import styled from 'styled-components';

export const DialogHeader = styled(ModalHeader)`
  justify-content: center;
  .modal-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  svg {
    vertical-align: text-bottom;
  }
`;

export const DialogFooter = styled(ModalFooter)`
  border: none;
  padding-top: 0;
`;

export const DialogBody = styled(ModalBody)`
  padding: ${() => `${vars.spacing.xl}`};
  max-height: 100%;
  overflow: hidden;
`;

export const CrossIconContainer = styled.span`
  position: absolute;
  right: ${() => `${vars.spacing.xl}`};
`;
