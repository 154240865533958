import { isEqual } from 'lodash-es';
import { RefObject, useEffect, useState } from 'react';

export type ScrollEdgesState = {
  /**
   * `true` if scrolled to the very top of the scroll container
   */
  atTop: boolean;
  /**
   * `true` if scrolled to the very bottom of the scroll container
   */
  atBottom: boolean;
  /**
   * `true` if scrolled to the very left of the scroll container
   */
  atLeft: boolean;
  /**
   * `true` if scrolled to the very right of the scroll container.
   */
  atRight: boolean;
};

/**
 * Since the calculation for `atBottom` and `atRight` might have rounding errors due to the scroll
 * position, use a threshold to check whether we are at the bottom or right edge.
 */
const EDGE_THRESHOLD = 1;

const getScrollEdgesState = (el: HTMLElement): ScrollEdgesState => {
  return {
    atTop: el.scrollTop === 0,
    atBottom:
      Math.abs(el.scrollHeight - el.scrollTop - el.clientHeight) <=
      EDGE_THRESHOLD,
    atLeft: el.scrollLeft === 0,
    atRight:
      Math.abs(el.scrollWidth - el.scrollLeft - el.clientWidth) <=
      EDGE_THRESHOLD,
  };
};

/**
 * Hook for tracking whether user has scrolled to the edge of the given scroll container.
 * @param ref
 * @returns `ScrollEdgesState` with a boolean for whether scroll container is at each edge.
 */
export function useScrollEdges(ref: RefObject<HTMLElement>) {
  const [scrollEdgesState, _setScrollEdgesState] = useState<ScrollEdgesState>({
    // since this is the most likely case, we start with top and left as true by default
    atTop: true,
    atBottom: false,
    atLeft: true,
    atRight: false,
  });
  const setScrollEdgesState = (val: ScrollEdgesState) =>
    _setScrollEdgesState((prev) => (isEqual(prev, val) ? prev : val));

  useEffect(() => {
    const el = ref.current;
    const resizeObserver = new ResizeObserver(() => {
      if (el) {
        setScrollEdgesState(getScrollEdgesState(el));
      }
    });
    if (el) {
      resizeObserver.observe(el);
    }
    return () => resizeObserver.disconnect();
  }, [ref]);

  useEffect(() => {
    const el = ref.current;
    const handleScroll = () => {
      if (el) {
        setScrollEdgesState(getScrollEdgesState(el));
      }
    };
    if (el) {
      setScrollEdgesState(getScrollEdgesState(el));
      el.addEventListener('scroll', handleScroll, {
        capture: false,
        passive: true,
      });
    }
    return () => {
      if (el) {
        el.removeEventListener('scroll', handleScroll);
      }
    };
  }, [ref]);

  return scrollEdgesState;
}
