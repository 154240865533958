import { ComponentProps, ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useContent, useFormattedContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { CloseButton } from 'src/modals/Modal';
import { CrossIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

export type CustomFilterDialogFieldValues = {
  filterName: string;
};

export type CustomFilterDialogProps = {
  headerTitle?: ReactNode;
  defaultValues?: CustomFilterDialogFieldValues;
  validateIsFilterUnique: (name: string) => boolean;
  onSave?: (data: CustomFilterDialogFieldValues) => void;
} & ComponentProps<typeof RSModal>;

export const CustomFilterDialog = ({
  headerTitle,
  defaultValues = { filterName: '' },
  validateIsFilterUnique,
  onSave,
  onCancel,
  ...rest
}: CustomFilterDialogProps) => {
  const requiredMsg = useContent(ContentId.Required);
  const nameText = useContent(ContentId.Name);
  const notUniqueMsg = useFormattedContent(FormatContentId.NotUnique, nameText);
  const { formState, register, handleSubmit } =
    useForm<CustomFilterDialogFieldValues>({ defaultValues });

  return (
    <GenericDialog
      size="sm"
      backdrop={true}
      {...rest}
      header={
        <Stack gap="l">
          <div>{headerTitle}</div>
          <CloseButton onClick={onCancel}>
            <CrossIcon
              size={vars.iconSize.l}
              fill={IconsFill.currentColor}
              withHoverEffect
            />
          </CloseButton>
        </Stack>
      }
      footer={
        <>
          <OkButton
            textContentId={ContentId.Save}
            onClick={onSave ? handleSubmit(onSave) : undefined}
          />
        </>
      }
    >
      <PosFormField
        errors={formState.errors.filterName?.message}
        label={nameText}
      >
        <PosTextField
          rootProps={{
            state: getTextFieldState(formState.errors.filterName),
          }}
          {...register('filterName', {
            required: requiredMsg,
            validate: (val) =>
              validateIsFilterUnique(val) || (notUniqueMsg as string),
          })}
        />
      </PosFormField>
    </GenericDialog>
  );
};
