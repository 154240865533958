import { useCallback } from 'react';
import { LaunchBulkUpdateAutoFulfill } from 'src/components/common/BulkActions/BulkUpdateAutoFulfillAction';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { BulkUpdateAutoFulfillDialog } from 'src/dialogs/BulkEdits/BulkUpdateAutoFulfillDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_AUTOFULFILL_UPDATE_KEY } from '../../InventoryActionDropdown.constants';

export const useBulkUpdateAutoFulfillAction = (
  filterQueryWithEventIds: ListingQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const updateKey = event
    ? `listing-autofulfill-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_AUTOFULFILL_UPDATE_KEY;
  const {
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkUpdateAutoFulfillDialog,
    closeDialog: closeBulkUpdateAutofulfillDialog,
  } = useDialog(DialogId.BulkUpdateAutoFulfill, BulkUpdateAutoFulfillDialog);

  const onSubmit = useCallback(
    async (
      isNoFulfill: boolean | null,
      updateSalesOfListings: boolean,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateIsNoFulfillPreview(
              filterQueryWithEventIds,
              updateSalesOfListings
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateIsNoFulfillPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (isNoFulfill != null) {
        await tryInvokeApi(
          async () => {
            const listingClient = new BulkEditListingClient(
              activeAccountWebClientConfig
            );

            const succeeded = await listingClient.bulkUpdateIsNoFulfill(
              preview!.preview,
              updateKey,
              isNoFulfill,
              updateSalesOfListings,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }

              closeBulkUpdateAutofulfillDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateIsNoFulfill', error, {
              trackErrorData: { isNoFulfill, preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQueryWithEventIds,
      showErrorDialog,
      updateKey,
      closeBulkUpdateAutofulfillDialog,
      setActivePosEntity,
      refreshExpandedListItems,
    ]
  );

  const onChangeClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (event) {
        setListItemExpansion(true, [event.viagVirtualId]);
      }

      openBulkUpdateAutoFulfillDialog({
        updateKey,
        onOkay: onSubmit,
        entityType: ActionOutboxEntityType.Listing,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkUpdateAutofulfillDialog,
      });
    },
    [
      event,
      openBulkUpdateAutoFulfillDialog,
      updateKey,
      onSubmit,
      closeBulkUpdateAutofulfillDialog,
      setListItemExpansion,
      setIsLoading,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkUpdateAutoFulfill
        key="UpdateAutoFulfill"
        onClick={onChangeClick}
        disabled={isLoading}
        entityType={ActionOutboxEntityType.Listing}
      />
    ),
  };
};
