import { debounce } from 'lodash-es';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormText } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextArea } from 'src/core/POS/PosTextArea';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { TicketExternalId } from 'src/WebApiController';

import { ExternalIdForm } from '../../UploadExternalIds';
import * as styles from './BulkStringArtifactInput.css';

export const BulkExternalIdInput = ({
  quantity,
  readOnly = false,
  onPreview,
}: {
  quantity: number;
  onPreview?: () => void;
  readOnly?: boolean;
}) => {
  const {
    register,
    trigger,
    setValue,
    watch,
    formState: { errors, touchedFields },
  } = useFormContext<ExternalIdForm>();
  const externalIds = watch('externalIds');

  const initialValue = useMemo(() => {
    const externalIdsArray = externalIds.map(
      ({ externalId }: TicketExternalId) => externalId
    );
    return externalIdsArray.join('\n').trimEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * Registering this was since the dispay value differs from how it is stored
     * ie. string for text area vs TicketExternalId[]
     */
    register('externalIds');

    // loaded from a toggle, show errors
    if (touchedFields?.externalIds?.length) {
      trigger('externalIds');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (e: React.SyntheticEvent) => {
      debounce((e: React.SyntheticEvent) => {
        const newExternalIds = (e.target as HTMLInputElement).value
          .trimEnd()
          .split('\n');

        if (newExternalIds.length < quantity) {
          newExternalIds.push(
            ...new Array(quantity - newExternalIds.length).fill('')
          );
        }

        setValue(
          'externalIds',
          // create array of externalIds in original insert order
          newExternalIds.map((externalId, idx) => ({
            // in order to display "Too many" error, set null
            ...externalIds[idx],
            externalId,
          })),
          { shouldValidate: true, shouldTouch: true, shouldDirty: true }
        );
      }, 100)(e);
    },
    [setValue, externalIds, quantity]
  );

  const externalIdsError = errors?.externalIds?.message;

  return (
    <div className={styles.bulkUploadWrapper}>
      <PosFormField errors={externalIdsError}>
        <PosTextArea
          rootProps={{
            disabled: readOnly,
            state: getTextFieldState(externalIdsError),
          }}
          disabled={readOnly}
          onChange={handleChange}
          defaultValue={initialValue}
          style={{ resize: 'vertical' }}
        />
      </PosFormField>
      {!readOnly && (
        <div className={styles.instructionsWrapper}>
          <FormText>
            <Content id={ContentId.EachTicketIdOnNewLine} />
          </FormText>
          {onPreview && (
            <Button
              className={styles.previewButton}
              variant={'text'}
              onClick={onPreview}
            >
              <Content id={ContentId.Preview} />
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
