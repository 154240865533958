import { isEqual } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { EMPTY_SELLER_USER_ID } from 'src/utils/constants/constants';
import { isSuccess } from 'src/utils/errorUtils';
import { compareMarketplace } from 'src/utils/eventWithDataUtils';
import { getAllInPriceFromListPriceForListing } from 'src/utils/inventoryUtils';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ListingClient,
  ListingDetails,
  ListingDetailsPricingSectionUpdates,
  ListingMarketplacePriceUpdateInput,
  Marketplace,
} from 'src/WebApiController';

const getListingDetailPricingInput = (
  listing: ListingDetails | null | undefined
): ListingDetailsPricingSectionUpdates => {
  const marketplacePriceUpdates = listing?.mkpListings
    ? listing.mkpListings
        ?.filter((m) => m.mkp !== Marketplace.StubHub)
        ?.sort((a, b) => compareMarketplace(a.mkp, b.mkp))
        ?.map((x) => {
          const listPriceMarkup = x.markup ?? x.sellerAccountDefaultMarkup ?? 0;

          return {
            allInPrice: x.isPriceByMkp
              ? x.webPrice ?? listing?.allInPrice
              : null,
            listPrice: x.isPriceByMkp
              ? x.netProcs ?? listing?.listPrice
              : (listing?.listPrice ?? 0) * (1 + listPriceMarkup),
            priceByMarketplace: x.isPriceByMkp,
            marketplace: x.mkp,
            markup:
              x.markup != null ? roundToPrecision(x.markup * 100, 2) : null,
          } as ListingMarketplacePriceUpdateInput;
        })
    : [];
  return {
    id: listing?.id ?? 0,
    rowVersion: null,
    listPrice: listing?.listPrice ?? null,
    allInPrice: getAllInPriceFromListPriceForListing(listing, null) ?? null,
    netProceedsFloor: listing?.procsFloor ?? -1,
    netProceedsCeiling: listing?.procsCeil ?? -1,
    skipPriceProtection: !listing?.id,
    autoPricingEnabled: listing?.isAutoPrc ?? null,
    marketplacePriceUpdates,
    pricerSellerUserId: listing?.pricerId ?? EMPTY_SELLER_USER_ID,
    buyerUserId: null,
    currencyCode: listing?.currency ?? null,
  };
};

export const usePricingForm = (
  listing: ListingDetails | null | undefined,
  onSubmitStart: () => void,
  onSubmitDone: (success?: boolean) => void
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const methods = useForm<ListingDetailsPricingSectionUpdates>({
    defaultValues: getListingDetailPricingInput(listing),
  });

  const onSubmitHandler = useCallback(
    async (pricingSectionForm: ListingDetailsPricingSectionUpdates) => {
      tryInvokeApi(
        async () => {
          onSubmitStart();
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).updateListingPricingSection(pricingSectionForm);

          if (isSuccess(result)) {
            // Reset the form to latest changed
            methods.reset(pricingSectionForm);
            onSubmitDone();
          } else {
            showErrorDialog(
              'ListingClient.updateListingPricingSection',
              { message: result.message, status: result.status },
              {
                trackErrorData: {
                  pricingSectionForm,
                },
              }
            );
          }
        },
        (error) => {
          showErrorDialog('ListingClient.updateListingPricingSection', error, {
            trackErrorData: pricingSectionForm,
          });
        },
        () => {
          onSubmitDone(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      methods,
      onSubmitDone,
      onSubmitStart,
      showErrorDialog,
    ]
  );

  const onSubmit = useCallback(() => {
    methods.handleSubmit(onSubmitHandler)();
  }, [methods, onSubmitHandler]);

  const onReset = useCallback(() => {
    methods.reset(getListingDetailPricingInput(listing));
  }, [listing, methods]);

  const input = methods.watch();

  const hasChanges = useMemo(() => {
    const defaultValues = methods.formState.defaultValues;
    return (
      input.listPrice !== defaultValues?.listPrice ||
      input.allInPrice !== defaultValues?.allInPrice ||
      input.netProceedsFloor !== defaultValues?.netProceedsFloor ||
      input.netProceedsCeiling !== defaultValues?.netProceedsCeiling ||
      input.skipPriceProtection !== defaultValues?.skipPriceProtection ||
      input.autoPricingEnabled !== defaultValues?.autoPricingEnabled ||
      input.pricerSellerUserId !== defaultValues?.pricerSellerUserId ||
      input.buyerUserId !== defaultValues?.buyerUserId ||
      input.currencyCode !== defaultValues?.currencyCode ||
      !isEqual(
        input.marketplacePriceUpdates,
        defaultValues?.marketplacePriceUpdates
      )
    );
  }, [input, methods.formState.defaultValues]);

  return {
    hasChanges,
    methods,
    onSubmit,
    onReset,
  };
};
