import { PosMultiSelect } from "src/core/POS/PosMultiSelect";
import { CompListingQuantity } from "src/WebApiController";
import { useCallback, useMemo } from "react";

const quantitiesOptions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10+'].reduce(
  (r, c) => {
    r[c] = c;
    return r;
  },
  {} as Record<string, string>
);

export const AutoPricingCompQuantitiesSelector = ({
  selectedQuantities,
  onSelectedQuantitiesChange,
  disabled,
}: {
  selectedQuantities: CompListingQuantity | null,
  onSelectedQuantitiesChange: (quantitiesNew: CompListingQuantity) => void,
  disabled: boolean,
  }) => {
  const selectedQuantitiesValues = useMemo(
    () => {
      if (!selectedQuantities) {
        return [];
      }

      const { exactQuantities: exact, greaterThanOrEqualToQuantity: gte } = selectedQuantities;

      const eligibleQuantities = Object.values(quantitiesOptions)
        .filter((value) => !isNaN(parseInt(value)))
        .map((value) => parseInt(value));
      const selectedQuantitiesInts = eligibleQuantities.filter((quantity) => {
        if (((exact as number[]) ?? []).includes(quantity)) {
          return true;
        }
        if (gte != null && (gte as number) <= quantity) {
          return true;
        }
        return false;
      });
      return Object.values(quantitiesOptions).filter((value) =>
        selectedQuantitiesInts.includes(parseInt(value))
      );
    }, [selectedQuantities]);

  const quantitiesValuesDisplayText = selectedQuantitiesValues
    ? selectedQuantitiesValues.join(', ')
    : null;

  const handleOnChange = useCallback(
    (values: string[]) => {
      const exact = [];
      let gte = null;
      for (const value of values) {
        const quantity = parseInt(value);
        if (value.indexOf('+') > -1) {
          gte = quantity;
        } else {
          if (!isNaN(quantity)) {
            exact.push(quantity);
          }
        }
      }

      onSelectedQuantitiesChange({
        exactQuantities: exact,
        greaterThanOrEqualToQuantity: gte,
      });
    }, [selectedQuantities, onSelectedQuantitiesChange]);

  return (
      <PosMultiSelect
        disabled={disabled}
        align="start"
        values={selectedQuantitiesValues}
        onChange={handleOnChange}
        valueOptionsContent={quantitiesOptions}
        valuesDisplayText={quantitiesValuesDisplayText}
        triggerProps={{ style: { width: '180px' } }}
      />
  );
};