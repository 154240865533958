import { ChangeEvent, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { ButtonWithIcon } from 'src/components/Buttons';
import { SeatScoreQuantityMultiplerGraph } from 'src/components/Events/RelativeScoreGraph/SeatScoreQuantityMultiplierGraph';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Switch } from 'src/core/interim/Switch';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { SectionSelectDialog } from 'src/dialogs/SectionSelectDialog';
import { useAvailableAutopricingModes } from 'src/hooks/useAvailableAutopricingModes';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUndercutAmountSettings } from 'src/hooks/useUndercutAmountSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { EditIcon, IconsFill, PlusIcon, ResetIcon } from 'src/svgs/Viagogo';
import {
  getUndercutPriceRepresentingMode,
  SLIDER_MAX,
  SLIDER_MIN,
  UNDERCUT_PRICE_MODE_OPTIONS,
  UndercutPriceSettingsMode,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EventPricingSeatMapForm } from 'src/utils/eventWithDataUtils';
import { roundToPrecision } from 'src/utils/numberFormatter';
import {
  AutoPricingCompListingMode,
  AutoPricingUndercutMode,
  Feature,
} from 'src/WebApiController';

import { RestrictSelectionType } from '../ListingDetails/components/PricingSection/AutoPricingSection.types';
import { CompListingSameQualitySettings } from './components/CompListingSameQualitySettings';
import * as styles from './components/EventPricingSection.css';
import { useEventAutoPricingSettings } from './components/useEventAutoPricingSettings';

export const EventAutoPricingSettings = ({
  disabled,
  hasAnyScore,
}: {
  disabled: boolean;
  hasAnyScore: boolean;
}) => {
  const { setValue, clearErrors, formState } =
    useFormContext<EventPricingSeatMapForm>();

  const sectionSelectDialog = useBasicDialog();

  const {
    canAutoPrice,
    autoPriceFrequency,
    autoPricingEnabled,
    uiCurrency,

    onResetSettings,
    onResetAdvanceSettings,

    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,

    compListingMode,
    compListingFloor,
    compListingCeiling,
    compListingSelectedSectionSettings,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,

    compListingQuantityScoreAdjustmentOverrideJson,

    onUndercutChange,
    onCompListingChange,
    onCompListingModeChange,
  } = useEventAutoPricingSettings();

  const hasExcludeFanInventory = useUserHasFeature(Feature.ExcludeFanInventory);
  const hasAutoPriceFrequencyFeature = useUserHasFeature(
    Feature.AutoPriceFrequency
  );

  const restrictSelection = Boolean(
    compListingOnlyForSameZoneEnabled ||
      compListingOnlyForSelectedSectionsEnabled
  );

  const {
    undercutAbsoluteAmountMode,
    setUndercutAbsoluteAmountMode,
    undercutRelativeAmountMode,
    setUndercutRelativeAmountMode,
  } = useUndercutAmountSettings(undercutAbsoluteAmount, undercutRelativeAmount);

  const { venueMapInfo } = useEventMapContext();

  const compListingFloorError =
    formState.errors.eventAutoPricing?.compListingFloor?.message;
  const compListingSelectedSectionError =
    formState.errors.eventAutoPricing?.compListingSelectedSectionSettings
      ?.message;

  const undercutAbsoluteAmountError =
    formState.errors.eventAutoPricing?.undercutAbsoluteAmount?.message;
  const undercutRelativeAmountError =
    formState.errors.eventAutoPricing?.undercutRelativeAmount?.message;

  const isMobile = useMatchMedia('mobile');

  const {
    compListingModes,
    compListingModesDisabled,
    undercutModes,
    undercutModesDisabled,
    restrictSelectionTypes,
  } = useAvailableAutopricingModes(
    true,
    undercutMode,
    compListingMode,
    hasAnyScore,
    false /* event config won't ever show parent listing */,
    false /* isGlobalEdit */,
    compListingOnlyForSelectedSectionsEnabled
  );

  const isDealScoreMode = undercutMode === AutoPricingUndercutMode.Deal;

  const isParentListingMode =
    compListingMode === AutoPricingCompListingMode.ParentListing;

  const showCompListingRestictSelectionDetails =
    compListingMode != null &&
    (compListingMode === AutoPricingCompListingMode.QualityScore ||
      compListingMode === AutoPricingCompListingMode.SameEvent ||
      compListingMode === AutoPricingCompListingMode.SameZone);

  const showCompListingSettingsDetails = !(
    !compListingMode ||
    compListingMode === AutoPricingCompListingMode.SameZone ||
    compListingMode === AutoPricingCompListingMode.SameEvent ||
    compListingMode === AutoPricingCompListingMode.ParentListing
  );

  const selectedSectionsPills = useMemo(
    () =>
      compListingSelectedSectionSettings?.sectionIdFilter
        ?.map((sectionId) => {
          const section = venueMapInfo?.sections.find(
            (section) => sectionId === section.id
          );
          return {
            value: section?.id.toString() ?? '',
            display: section?.name ?? '',
          };
        })
        .filter((info) => info.value && info.display) ?? [],
    [
      compListingSelectedSectionSettings?.sectionIdFilter,
      venueMapInfo?.sections,
    ]
  );

  return (
    <>
      {canAutoPrice && (
        <DetailSection name={<Content id={ContentId.Autopricing} />}>
          <SectionContent numOfColumns={isMobile ? 1 : 3}>
            <DetailGroup
              style={{ gridColumn: '1 / span 3' }}
              className={styles.autoPricingLabel}
            >
              <Content id={ContentId.CompListingModePrompt} />
            </DetailGroup>
            <DetailGroup style={{ gridColumn: '1 / span 3' }}>
              <Stack gap="l">
                {canAutoPrice && (
                  <PosFormField
                    label={
                      <Content id={ContentId.AutoPricingAllListingsForEvent} />
                    }
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Switch
                        disabled={disabled}
                        checked={Boolean(autoPricingEnabled)}
                        onClick={(e: ChangeEvent<HTMLElement>) =>
                          e.stopPropagation()
                        }
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          if (isChecked !== autoPricingEnabled) {
                            setValue('eventWideAutoPricing', isChecked);
                          }
                        }}
                      />
                    </div>
                  </PosFormField>
                )}
              </Stack>
              <Stack gap="l">
                <div className={styles.inlinePricingInputText}>
                  <Content id={ContentId.AllListings} />
                </div>
                <PosEnumSelect
                  value={compListingMode}
                  disabled={disabled}
                  valueOptionsContent={compListingModes}
                  valueOptionsDisabled={compListingModesDisabled}
                  placeholderText={ContentId.None}
                  onClick={(e) => e.stopPropagation()}
                  onChange={onCompListingModeChange}
                />
              </Stack>
            </DetailGroup>
            {showCompListingSettingsDetails && (
              <>
                <DetailGroup style={{ gridColumn: '1 / span 3' }}>
                  <Stack gap="l">
                    <div className={styles.inlinePricingInputText}>
                      <Content id={ContentId.Between} />
                    </div>
                    <PosFormField
                      errors={compListingFloorError}
                      style={{ width: 'fit-content' }}
                    >
                      <PosTextField
                        rootProps={{
                          state: getTextFieldState(compListingFloorError),
                          style: { maxWidth: '100px' },
                        }}
                        type="number"
                        value={
                          compListingMode == null || compListingFloor == null
                            ? ''
                            : Math.abs(
                                roundToPrecision(
                                  (compListingFloor - 1) * 100,
                                  2
                                )
                              )
                        }
                        postfixDisplay="%"
                        disabled
                        onChange={(e) => {
                          let v = parseFloat(e.target.value);
                          v = parseFloat(v.toFixed(2));
                          if (isNaN(v)) {
                            onCompListingChange({
                              compListingFloorNew: null,
                            });
                            return;
                          }
                          clearErrors('eventAutoPricing.compListingFloor');
                          if (v < SLIDER_MIN) {
                            onCompListingChange({
                              compListingFloorNew: SLIDER_MIN,
                            });
                          } else if (v > SLIDER_MAX) {
                            onCompListingChange({
                              compListingFloorNew: SLIDER_MAX,
                            });
                          } else {
                            onCompListingChange({ compListingFloorNew: v });
                          }
                        }}
                      />
                    </PosFormField>
                    <div className={styles.inlinePricingInputText}>
                      <Content
                        id={
                          (compListingFloor ?? 0) - 1 < 0
                            ? ContentId.Worse
                            : ContentId.Better
                        }
                      />{' '}
                      <Content id={ContentId.ThanMineAnd} />
                    </div>
                    <PosFormField style={{ width: 'fit-content' }}>
                      <PosTextField
                        rootProps={{
                          style: { maxWidth: '100px' },
                        }}
                        type="number"
                        value={
                          compListingMode == null || compListingCeiling == null
                            ? ''
                            : Math.abs(
                                roundToPrecision(
                                  (compListingCeiling - 1) * 100,
                                  2
                                )
                              )
                        }
                        postfixDisplay="%"
                        disabled
                        onChange={(e) => {
                          let v = parseFloat(e.target.value);
                          v = parseFloat(v.toFixed(2));
                          if (isNaN(v)) {
                            onCompListingChange({
                              compListingCeilingNew: null,
                            });
                            return;
                          }

                          clearErrors('eventAutoPricing.compListingCeiling');
                          if (v < SLIDER_MIN) {
                            onCompListingChange({
                              compListingCeilingNew: SLIDER_MIN,
                            });
                          } else if (v > SLIDER_MAX) {
                            onCompListingChange({
                              compListingCeilingNew: SLIDER_MAX,
                            });
                          } else {
                            onCompListingChange({ compListingCeilingNew: v });
                          }
                        }}
                      />
                    </PosFormField>
                    <div className={styles.inlinePricingInputText}>
                      <Content
                        id={
                          (compListingCeiling ?? 0) - 1 < 0
                            ? ContentId.Worse
                            : ContentId.Better
                        }
                      />
                    </div>
                  </Stack>
                </DetailGroup>

                <CompListingSameQualitySettings disabled={disabled} />
              </>
            )}
            {showCompListingRestictSelectionDetails && (
              <DetailGroup style={{ gridColumn: '1 / span 3' }}>
                <PosFormField
                  label={<Content id={ContentId.RestrictSelection} />}
                >
                  <Switch
                    disabled={disabled}
                    checked={restrictSelection}
                    onClick={(e: ChangeEvent<HTMLElement>) =>
                      e.stopPropagation()
                    }
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      if (isChecked) {
                        if (
                          !compListingOnlyForSameZoneEnabled &&
                          !compListingOnlyForSelectedSectionsEnabled
                        ) {
                          // set zone by default
                          onCompListingChange({
                            compListingOnlyForSameZoneEnabledNew: true,
                            compListingOnlyForSelectedSectionsEnabledNew: false,
                          });
                        }
                      } else {
                        onCompListingChange({
                          compListingOnlyForSameZoneEnabledNew: false,
                          compListingOnlyForSelectedSectionsEnabledNew: false,
                        });
                      }
                    }}
                  />
                </PosFormField>
                {restrictSelection && (
                  <Stack gap="l">
                    <div className={styles.inlinePricingInputText}>
                      <Content id={ContentId.RestrictTo} />
                    </div>
                    <PosEnumSelect
                      value={
                        compListingOnlyForSameZoneEnabled
                          ? RestrictSelectionType.SameZone
                          : RestrictSelectionType.SpecificSections
                      }
                      disabled={disabled}
                      valueOptionsContent={restrictSelectionTypes}
                      placeholderText={ContentId.None}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(v) => {
                        clearErrors('eventAutoPricing.compListingFloor');
                        if (v === RestrictSelectionType.SameZone) {
                          onCompListingChange({
                            compListingOnlyForSameZoneEnabledNew: true,
                            compListingOnlyForSelectedSectionsEnabledNew: false,
                          });
                        } else {
                          onCompListingChange({
                            compListingOnlyForSameZoneEnabledNew: false,
                            compListingOnlyForSelectedSectionsEnabledNew: true,
                          });
                        }
                      }}
                    />
                  </Stack>
                )}
                {compListingOnlyForSelectedSectionsEnabled && (
                  <PosFormField
                    errors={compListingSelectedSectionError}
                    style={{ width: 'fit-content' }}
                  >
                    <Stack direction="column" gap="l" alignItems="start">
                      {compListingSelectedSectionSettings?.sectionIdFilter
                        ?.length ? (
                        <>
                          <Content id={ContentId.SelectedSections} />
                          <PillList pills={selectedSectionsPills} disabled />
                          <ButtonWithIcon
                            variant="textPlain"
                            icon={
                              <EditIcon
                                fill={IconsFill.textBrand}
                                size={vars.iconSize.s}
                                withHoverEffect
                              />
                            }
                            textContentId={ContentId.EditSections}
                            onClick={sectionSelectDialog.launchDialog}
                            disabled={disabled}
                            style={{ paddingLeft: 0, paddingRight: 0 }}
                          />
                        </>
                      ) : (
                        <ButtonWithIcon
                          variant="textPlain"
                          shape="none"
                          icon={
                            <PlusIcon
                              fill={IconsFill.textBrand}
                              size={vars.iconSize.s}
                              withHoverEffect
                            />
                          }
                          textContentId={ContentId.SelectSections}
                          onClick={sectionSelectDialog.launchDialog}
                          disabled={disabled}
                          style={{ padding: 0 }}
                        />
                      )}
                    </Stack>
                  </PosFormField>
                )}
              </DetailGroup>
            )}
            <DetailGroup
              style={{ gridColumn: '1 / span 3' }}
              className={styles.autoPricingLabel}
            >
              <Content id={ContentId.UnderCutModePrompt} />
            </DetailGroup>
            {isParentListingMode ? (
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.TakeMyParentListingAnd} />
              </div>
            ) : (
              <>
                <DetailGroup style={{ gridColumn: '1 / span 3' }}>
                  <Stack gap="l">
                    <div className={styles.inlinePricingInputText}>
                      <Content id={ContentId.FindThe} />
                    </div>
                    <PosEnumSelect
                      value={undercutMode}
                      disabled={disabled}
                      valueOptionsContent={undercutModes}
                      valueOptionsDisabled={undercutModesDisabled}
                      placeholderText={ContentId.None}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(v) => {
                        clearErrors('eventAutoPricing.undercutAbsoluteAmount');
                        clearErrors('eventAutoPricing.undercutRelativeAmount');
                        if (v) {
                          onUndercutChange(v as AutoPricingUndercutMode);
                        } else {
                          onUndercutChange(null, null, null);
                        }
                      }}
                    />
                    <div className={styles.inlinePricingInputText}>
                      <Content id={ContentId.OfTheComparableListings} />
                    </div>
                  </Stack>
                </DetailGroup>
                {isDealScoreMode && (
                  <div className={styles.inlinePricingInputText}>
                    <Content id={ContentId.BestDealScorePrompt} />
                  </div>
                )}
              </>
            )}
            <DetailGroup style={{ gridColumn: '1 / span 3' }}>
              <Stack gap="l">
                <ToggleGroup
                  disabled={!undercutMode || !undercutRelativeAmount}
                  options={UNDERCUT_PRICE_MODE_OPTIONS}
                  value={undercutRelativeAmountMode}
                  onValueChange={(value) => {
                    if (!value) return;
                    const mode = value as UndercutPriceSettingsMode;
                    setUndercutRelativeAmountMode(mode);
                    if (undercutRelativeAmount) {
                      const curAmountAbs = Math.abs(undercutRelativeAmount);
                      onUndercutChange(
                        undefined,
                        undefined,
                        getUndercutPriceRepresentingMode(curAmountAbs, mode)
                      );
                    }
                  }}
                />
                <div className={styles.inlinePricingInputText}>
                  <Content id={ContentId.PriceBy} />
                </div>
                <PosFormField
                  errors={undercutRelativeAmountError}
                  style={{ width: 'fit-content' }}
                >
                  <PosTextField
                    rootProps={{
                      state: getTextFieldState(undercutRelativeAmountError),
                      style: { maxWidth: '100px' },
                      disabled: !undercutMode,
                    }}
                    disabled={!undercutMode}
                    value={
                      undercutMode == null
                        ? ''
                        : undercutRelativeAmount != null
                        ? roundToPrecision(
                            Math.abs(undercutRelativeAmount) * 100,
                            8
                          )
                        : ''
                    }
                    type="number"
                    postfixDisplay="%"
                    onChange={(e) => {
                      clearErrors('eventAutoPricing.undercutAbsoluteAmount');
                      clearErrors('eventAutoPricing.undercutRelativeAmount');
                      const v = parseFloat(e.target.value);
                      if (v >= 0 && v <= Number.MAX_VALUE) {
                        let relativeValue = Math.min(v, 100) / 100;
                        relativeValue = getUndercutPriceRepresentingMode(
                          relativeValue,
                          undercutRelativeAmountMode ??
                            UndercutPriceSettingsMode.Discount
                        );

                        onUndercutChange(undefined, undefined, relativeValue);
                      } else {
                        onUndercutChange(undefined, undefined, null);
                      }
                    }}
                  />
                </PosFormField>
                <div className={styles.inlinePricingInputText}>then</div>
                <ToggleGroup
                  disabled={!undercutMode || !undercutAbsoluteAmount}
                  options={UNDERCUT_PRICE_MODE_OPTIONS}
                  value={undercutAbsoluteAmountMode}
                  onValueChange={(value) => {
                    if (!value) return;
                    const mode = value as UndercutPriceSettingsMode;
                    setUndercutAbsoluteAmountMode(mode);
                    if (undercutAbsoluteAmount) {
                      const curAmountAbs = Math.abs(undercutAbsoluteAmount);
                      onUndercutChange(
                        undefined,
                        getUndercutPriceRepresentingMode(curAmountAbs, mode)
                      );
                    }
                  }}
                />
                <div className={styles.inlinePricingInputText}>by</div>
                <PosFormField
                  errors={undercutAbsoluteAmountError}
                  style={{ width: 'fit-content' }}
                >
                  <PosCurrencyField
                    rootProps={{
                      state: getTextFieldState(undercutAbsoluteAmountError),
                      style: { maxWidth: '100px' },
                      disabled: !undercutMode,
                    }}
                    uiCurrency={uiCurrency}
                    disabled={!undercutMode}
                    value={
                      undercutMode == null
                        ? ''
                        : undercutAbsoluteAmount != null
                        ? Math.abs(undercutAbsoluteAmount)
                        : ''
                    }
                    onChange={(e) => {
                      clearErrors('eventAutoPricing.undercutAbsoluteAmount');
                      clearErrors('eventAutoPricing.undercutRelativeAmount');
                      let v = parseFloat(e.target.value);
                      if (v >= 0 && v <= Number.MAX_VALUE) {
                        v = getUndercutPriceRepresentingMode(
                          v,
                          undercutRelativeAmountMode ??
                            UndercutPriceSettingsMode.Discount
                        );

                        onUndercutChange(undefined, undefined, v);
                      } else {
                        onUndercutChange(undefined, null);
                      }
                    }}
                  />
                </PosFormField>
              </Stack>
            </DetailGroup>
            {hasAutoPriceFrequencyFeature ? (
              <>
                <DetailGroup
                  style={{ gridColumn: '1 / span 3' }}
                  className={styles.autoPricingLabel}
                >
                  <Content id={ContentId.AutoPriceFrequency} />
                </DetailGroup>
                <DetailGroup style={{ gridColumn: '1 / span 3' }}>
                  <Stack direction="row" gap="m" alignItems="center">
                    <Content id={ContentId.UpdatePriceEvery} />
                    <PosFormField style={{ maxWidth: '150px' }}>
                      <PosTextField
                        type="number"
                        value={autoPriceFrequency ?? ''}
                        postfixDisplay={
                          <FormatContent
                            id={FormatContentId.NMinutes}
                            params={''}
                          />
                        }
                        onChange={(e) => {
                          let v = parseInt(e.target.value);
                          if (!isNaN(v)) {
                            if (v < 1) {
                              v = 1;
                            }
                            setValue('eventAutoPricing.autoPriceFrequency', v);
                            return;
                          } else {
                            setValue(
                              'eventAutoPricing.autoPriceFrequency',
                              null
                            );
                          }
                        }}
                      />
                    </PosFormField>
                  </Stack>
                </DetailGroup>
              </>
            ) : null}
            <DetailGroup
              style={{ gridColumn: '1 / span 3' }}
              className={styles.autoPricingLabel}
            >
              <Content id={ContentId.QuantityAvailablePremiums} />
            </DetailGroup>
            <DetailGroup style={{ gridColumn: '1 / span 3' }}>
              <SeatScoreQuantityMultiplerGraph
                initialSegments={
                  compListingQuantityScoreAdjustmentOverrideJson
                    ? JSON.parse(compListingQuantityScoreAdjustmentOverrideJson)
                        .segments
                    : undefined
                }
                onChange={({ overrides, segments }) => {
                  onCompListingChange({
                    compListingQuantityScoreAdjustmentOverrideJsonNew:
                      JSON.stringify({ overrides, segments }),
                  });
                }}
              />
            </DetailGroup>
            <DetailGroup style={{ gridColumn: 1 }}>
              <ButtonWithIcon
                variant="text"
                icon={<ResetIcon withHoverEffect />}
                textContentId={ContentId.ResetSettings}
                onClick={() => {
                  onResetSettings();
                  onResetAdvanceSettings();
                }}
              />
            </DetailGroup>
          </SectionContent>
        </DetailSection>
      )}
      <SectionSelectDialog
        {...sectionSelectDialog.dialogProps}
        initialSectionIds={
          compListingSelectedSectionSettings?.sectionIdFilter ?? []
        }
        onSave={(selectedSections) => {
          const compListingSelectedSectionSettingsNew = {
            ...compListingSelectedSectionSettings,
            sectionIdFilter: selectedSections.map((s) => s.id),
            rowIdFilter: compListingSelectedSectionSettings?.rowIdFilter ?? [],
            sectionRowIdFilter:
              compListingSelectedSectionSettings?.sectionRowIdFilter ?? {},
          };
          onCompListingChange({ compListingSelectedSectionSettingsNew });
          sectionSelectDialog.closeDialog();
          clearErrors('eventAutoPricing.compListingSelectedSectionSettings');
        }}
        onClosed={sectionSelectDialog.closeDialog}
      />
    </>
  );
};
