import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { FormatContent, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ErrorMessage } from 'src/core/POS/ErrorMessage';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { SingleFileUploadWithPreview } from 'src/core/POS/SingleFileUploadWithPreview';
import { Stack } from 'src/core/ui';
import { MAX_ALLOWED_UPLOAD_FILE_SIZE } from 'src/core/utils';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  DocumentPage,
  DocumentProcessorClient,
  DocumentType,
  UserDocument,
  UserDocumentLinks,
} from 'src/WebApiController';

import * as styles from './UploadDocumentInput.css';

export type UploadDocumentInputForm = {
  uploadProofDocuments: UserDocument[] | null;
};

type FileUploadInfo = {
  file?: File;
  errorMessage?: string;
  docLink?: UserDocumentLinks;
};

export const UploadDocumentInput = ({
  entityId,
  entityType,
  uploadInstruction,
  documentType,
  setIsSaveDisabled,
}: {
  entityId: number;
  entityType: ActionOutboxEntityType;
  uploadInstruction?: React.ReactNode;
  documentType: DocumentType;
  setIsSaveDisabled: (disabled: boolean) => void;
}) => {
  const { setValue, setError, clearErrors } =
    useFormContext<UploadDocumentInputForm>();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isLoading, setIsLoading] = useState(false);
  const { activeAccountWebClientConfig } = useAppContext();

  const [fileInfo, setFileInfo] = useState<FileUploadInfo>({});

  const failedUpload = useContent(ContentId.UploadFailureMessage);
  const onUploadDocument = useCallback(
    (file: File) => {
      tryInvokeApi(
        async () => {
          const client = new DocumentProcessorClient(
            activeAccountWebClientConfig
          );

          if (file) {
            setIsLoading(true);
            setIsSaveDisabled(true);
            const uploadInfo = await client.getDocumentUploadInfo(
              entityId,
              documentType,
              file.type
            );

            const result = await client.uploadDocumentWithoutProcessing(
              uploadInfo.entityId,
              entityType,
              uploadInfo.documentType,
              uploadInfo.blobName,
              uploadInfo.documentId,
              uploadInfo.contentType,
              {
                data: file,
                fileName: file.name,
              }
            );

            if (result.data) {
              setFileInfo({
                file: file,
                docLink: result.data,
              });
              clearErrors('uploadProofDocuments');
              setValue('uploadProofDocuments', [
                {
                  entityId: result.entityId,
                  documentId: result.data.documentId,
                  documentType: documentType,
                } as UserDocument,
              ]);
            } else {
              setFileInfo({
                file: file,
                errorMessage: result.message ?? failedUpload,
              });
              setValue('uploadProofDocuments', []);
              setError('uploadProofDocuments', {
                message: result.message ?? failedUpload,
              });
            }
          }
        },
        (error) => {
          showErrorDialog(
            'DocumentProcessorClient.uploadDocumentWithoutProcessing',
            error,
            {
              trackErrorData: fileInfo.file,
            }
          );
        },
        () => {
          setIsLoading(false);
          setIsSaveDisabled(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      setIsSaveDisabled,
      entityId,
      documentType,
      entityType,
      clearErrors,
      setValue,
      failedUpload,
      setError,
      showErrorDialog,
      fileInfo.file,
    ]
  );

  return (
    <Stack direction="column" gap="xl">
      {uploadInstruction && (
        <div className={styles.uploadInstructions}>{uploadInstruction}</div>
      )}
      {isLoading && fileInfo.file?.name ? (
        <div className={styles.uploadProofDocLoader}>
          <div className={styles.uploadInstructions}>
            <FormatContent
              id={FormatContentId.Uploading}
              params={fileInfo.file.name}
            />
          </div>
          <PosSpinner />
        </div>
      ) : (
        <SingleFileUploadWithPreview<DocumentPage>
          fileName={fileInfo.file?.name}
          filePages={
            fileInfo.docLink
              ? [
                  {
                    id: fileInfo.docLink.documentId,
                    documentId: fileInfo.docLink.documentId,
                    documentUrl: fileInfo.docLink.documentUri,
                    pageNumber: 1,
                    thumbUrl: fileInfo.docLink.pageUri,
                  },
                ]
              : []
          }
          isDeletable
          onFileDeleted={() => setFileInfo({})}
          onNewFileSelected={(f) => onUploadDocument(f[0])}
          hasError={Boolean(fileInfo.errorMessage)}
          maxFileSize={MAX_ALLOWED_UPLOAD_FILE_SIZE}
        />
      )}
      {fileInfo.errorMessage && (
        <ErrorMessage>{fileInfo.errorMessage}</ErrorMessage>
      )}
    </Stack>
  );
};
