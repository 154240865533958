import signalR, {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import { isEqual } from 'lodash-es';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreview,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { SignalRLogger } from '../NotificationsContext';

export enum BulkEditStage {
  Idle,
  GettingPreview,
  Preview,
  Execute,
  Done,
}

export type BulkEditJob = {
  entityType: ActionOutboxEntityType;
  action: BulkActionType;
  updateKey: string;
  onBulkEditDone?: (
    progress: BulkEditProgress,
    errors: string[]
  ) => Promise<unknown>;
};

export type IBulkEditHubContext = {
  bulkEditHub?: HubConnection;
  progress?: BulkEditProgress;
  setProgress: React.Dispatch<
    React.SetStateAction<BulkEditProgress | undefined>
  >;
  errors: string[];
  setErrors: React.Dispatch<React.SetStateAction<string[]>>;
  warnings: string[];
  setWarnings: React.Dispatch<React.SetStateAction<string[]>>;
  mainDialogOpened: boolean;
  setMainDialogOpened: React.Dispatch<React.SetStateAction<boolean>>;
  stage: BulkEditStage;
  setStage: (stage: BulkEditStage) => void;
  preview?: BulkEditPreviewWithDetails;
  setPreview: (preview: BulkEditPreviewWithDetails | undefined) => void;
  setCurJob: (job: BulkEditJob | undefined) => void;
  /**
   * Retryable entities are entities that are changed during the time between preview and actual action
   */
  retryableEntities?: BulkEditPreview;
  /**
   * Snapshot of the progress before retrying
   */
  progressBeforeRetry?: BulkEditProgress;
  clearRetryStates: () => void;
  onRetryCancel: () => void;
};

export const BulkEditHubContext = createContext<IBulkEditHubContext>({
  bulkEditHub: undefined,
  progress: undefined,
  setProgress: () => {},
  errors: [],
  setErrors: () => {},
  warnings: [],
  setWarnings: () => {},
  mainDialogOpened: false,
  setMainDialogOpened: () => {},
  stage: BulkEditStage.Idle,
  setStage: () => {},
  preview: undefined,
  setPreview: () => {},
  setCurJob: () => {},
  retryableEntities: undefined,
  progressBeforeRetry: undefined,
  clearRetryStates: () => {},
  onRetryCancel: () => {},
});

export const useBulkEditHubContext = () => useContext(BulkEditHubContext);

/**
 * Needs to be in sync with the method in C#
 * GetMethodName in BackgroundActionService.cs
 */
export const getBulkEditMethodName = (
  entityType?: ActionOutboxEntityType,
  bulkEditAction?: BulkActionType
) => {
  if (!entityType || !bulkEditAction) {
    return undefined;
  }

  return `${entityType}-${bulkEditAction}`;
};

export function BulkEditHubContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const [hubConnection, setHubConnection] = useState<signalR.HubConnection>();

  const [curJob, setCurJob] = useState<BulkEditJob>();
  const method = useMemo(
    () => getBulkEditMethodName(curJob?.entityType, curJob?.action),
    [curJob?.action, curJob?.entityType]
  );

  const [progress, setProgress] = useState<BulkEditProgress>();

  const [progressBeforeRetry, setProgressBeforeRetry] =
    useState<BulkEditProgress>();
  const [errorsBeforeRetry, setErrorsBeforeRetry] = useState<string[]>([]);
  const [retryableEntities, setRetryableEntities] = useState<BulkEditPreview>();

  const [errors, setErrors] = useState<string[]>([]);
  const [warnings, setWarnings] = useState<string[]>([]);
  const [mainDialogOpened, setMainDialogOpened] = useState<boolean>(false);

  const [stage, setStage] = useState<BulkEditStage>(BulkEditStage.Idle);
  const [preview, setPreview] = useState<
    BulkEditPreviewWithDetails | undefined
  >(undefined);

  useEffect(() => {
    // Only attempt to connect to signal R when there is a login
    if (activeAccountWebClientConfig.activeAccountId) {
      if (
        !hubConnection ||
        hubConnection.state === HubConnectionState.Disconnected ||
        hubConnection.state === HubConnectionState.Disconnecting
      ) {
        const newHubConnection = new HubConnectionBuilder()
          .withUrl('/hubs/bulkEdit')
          .withAutomaticReconnect()
          .configureLogging(new SignalRLogger())
          .build();

        setHubConnection(newHubConnection);

        newHubConnection
          .start()
          .then(() => {
            console.debug(
              `BulkEditHub connection ready with id: ${newHubConnection.connectionId}`
            );
          })
          .catch((err) => {
            console.warn(
              'BulkEditHub could not be started at this time: ' + err
            );
          });

        newHubConnection.onclose((err) => {
          console.debug('BulkEditHub connection closed', err);
        });
      }
    }

    return () => {
      if (
        hubConnection &&
        !(
          hubConnection.state === HubConnectionState.Disconnected ||
          hubConnection.state === HubConnectionState.Disconnecting
        )
      ) {
        console.debug(
          `Stopping BulkEditHub connection (state: ${hubConnection.state}) with id: ${hubConnection.connectionId}`
        );
        hubConnection.stop();
        setHubConnection(undefined);
      }
    };
  }, [activeAccountWebClientConfig.activeAccountId, hubConnection]);

  const clearRetryStates = useCallback(() => {
    setRetryableEntities(undefined);
    setProgressBeforeRetry(undefined);
    setErrorsBeforeRetry([]);
  }, []);

  const updateProgress = useCallback(
    async (newProgress: BulkEditProgress) => {
      if (
        curJob &&
        method &&
        newProgress.updateKey === curJob.updateKey &&
        newProgress.method === method
      ) {
        if (
          !isEqual(newProgress, progress) &&
          (newProgress.step === BulkEditStep.Initializing || // new initialization, overrides the previous done progress
            progress?.step !== BulkEditStep.Done) // no more progress update is allowed when the last received progress is Done
        ) {
          if (newProgress.step === BulkEditStep.Initializing) {
            clearRetryStates();
          }

          newProgress =
            newProgress.step !== progress?.step
              ? newProgress // step changed, just take new one
              : {
                  // step didn't change, make sure we only take the higher numbers
                  ...newProgress,
                  // This make sure the counts only increase, never decrease
                  successCount: Math.max(
                    newProgress.successCount,
                    progress?.successCount ?? 0
                  ),
                  warningCount: Math.max(
                    newProgress.warningCount,
                    progress?.warningCount ?? 0
                  ),
                  failureCount: Math.max(
                    newProgress.failureCount,
                    progress?.failureCount ?? 0
                  ),
                  totalCount: Math.max(
                    newProgress.totalCount,
                    progress?.totalCount ?? 0
                  ),
                  successPercent: Math.max(
                    newProgress.successPercent ?? 0,
                    progress?.successPercent ?? 0
                  ),
                  warningPercent: Math.max(
                    newProgress.warningPercent ?? 0,
                    progress?.warningPercent ?? 0
                  ),
                  failurePercent: Math.max(
                    newProgress.failurePercent ?? 0,
                    progress?.failurePercent ?? 0
                  ),
                };

          // This make sure that if the count is passed the total, considered the bulk-progress done unless forceNotDone is specified
          if (
            newProgress.step === BulkEditStep.InProgress &&
            newProgress.totalCount > 0 &&
            newProgress.successCount +
              newProgress.warningCount +
              newProgress.failureCount >=
              newProgress.totalCount
          ) {
            newProgress.step = BulkEditStep.Done;
          }

          // NOTE - this is necessary to call before the "setProgress" is called
          // because once the setProgress happens, the bulk edit shows completion
          // but the refresh of data hasn't complete, causing people to report bulk-edit not working
          if (newProgress.step === BulkEditStep.Done) {
            // Ensure that count matches so users don't question wtf is going on
            // Because the Done event can come in before the last count event
            // We only tally up success-count because even if the last message is the error we would have missed it
            newProgress.successCount =
              newProgress.totalCount -
              newProgress.failureCount -
              newProgress.warningCount;

            setStage(BulkEditStage.Done);
            await curJob.onBulkEditDone?.(newProgress, errors);
          }

          if (newProgress.step === BulkEditStep.DoneWithRetryableEntities) {
            setStage(BulkEditStage.Idle);
            setRetryableEntities(newProgress.retryableEntities ?? undefined);
            setProgressBeforeRetry(newProgress);
            setErrorsBeforeRetry(errors);
          }

          setProgress(newProgress);

          if (newProgress?.error && !errors.includes(newProgress.error)) {
            setErrors((prev) => {
              return [...prev, newProgress.error!];
            });
          }
          if (newProgress?.warning && !warnings.includes(newProgress.warning)) {
            setWarnings((prev) => {
              return [...prev, newProgress.warning!];
            });
          }
        }
      }
    },
    [curJob, method, progress, errors, warnings, clearRetryStates]
  );

  useEffect(() => {
    if (curJob) {
      const handler = (progress: BulkEditProgress) => {
        if (!curJob.updateKey || progress.updateKey === curJob.updateKey) {
          updateProgress(progress);
        }
      };

      if (method) {
        hubConnection?.on(method, handler);
        return () => {
          hubConnection?.off(method, handler);
        };
      }
    }
  }, [curJob, hubConnection, method, updateProgress]);

  const [successPercent, setSuccessPercent] = useState(0);
  useEffect(() => {
    if (curJob) {
      if (stage === BulkEditStage.GettingPreview) {
        if (successPercent < 100) {
          const interval = setInterval(() => {
            setProgress({
              updateKey: curJob.updateKey,
              method: method,
              step: BulkEditStep.InProgress,
              successPercent: Math.min(successPercent + 0.6, 100) / 100,
            } as BulkEditProgress);

            setSuccessPercent((prev) => prev + 0.3);
          }, 100);

          return () => clearInterval(interval);
        }
      } else {
        setProgress(undefined);
      }
    }
  }, [curJob, method, retryableEntities, setProgress, stage, successPercent]);

  const setStageWrapper = (newStage: BulkEditStage) => {
    if (curJob) {
      setStage(newStage);
      if (newStage === BulkEditStage.GettingPreview) {
        setSuccessPercent(0);
      } else if (newStage === BulkEditStage.Preview) {
        setSuccessPercent(100);
        setProgress({
          updateKey: curJob.updateKey,
          method: method,
          step: BulkEditStep.InProgress,
          successPercent: 1,
        } as BulkEditProgress);
      }
    }
  };

  const onRetryCancel = async () => {
    setStage(BulkEditStage.Done);
    if (progressBeforeRetry) {
      await curJob?.onBulkEditDone?.(progressBeforeRetry, errorsBeforeRetry);
    }
    clearRetryStates();
  };

  const setCurJobWrapper = (job?: BulkEditJob) => {
    if (!job) {
      setProgress(undefined);
      setPreview(undefined);
      setStage(BulkEditStage.Idle);
    }

    setCurJob(job);
  };

  return (
    <BulkEditHubContext.Provider
      value={{
        bulkEditHub: hubConnection,
        progress,
        setProgress,
        errors,
        setErrors,
        warnings,
        setWarnings,
        mainDialogOpened,
        setMainDialogOpened,
        stage,
        setStage: setStageWrapper,
        preview,
        setPreview,
        setCurJob: setCurJobWrapper,
        retryableEntities,
        progressBeforeRetry,
        clearRetryStates,
        onRetryCancel,
      }}
    >
      {children}
    </BulkEditHubContext.Provider>
  );
}
