import { ComponentProps } from 'react';
import { vars } from 'src/core/themes';
import {
  IconsFill,
  MobileTicketIcon,
  MobileTransferIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { ButtonWithIcon } from './ButtonWithIcon';

export const TransferButton = ({
  textContentId,
  iconOnlyMode,
  variant,
  eTicketUrlOnly,
  ...props
}: Omit<ComponentProps<typeof ButtonWithIcon>, 'icon'> & {
  eTicketUrlOnly?: boolean;
}) => {
  const Icon = eTicketUrlOnly ? MobileTicketIcon : MobileTransferIcon;
  return (
    <ButtonWithIcon
      {...props}
      icon={
        <Icon
          withHoverEffect
          fill={
            iconOnlyMode || (variant && variant !== 'regular')
              ? IconsFill.textBrand
              : IconsFill.textInverted
          }
          size={vars.iconSize.m}
        />
      }
      iconOnlyMode={iconOnlyMode}
      variant={variant}
      textContentId={
        textContentId ||
        (eTicketUrlOnly ? ContentId.AddUrl : ContentId.Transfer)
      }
    />
  );
};
