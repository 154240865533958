import { ReactNode } from 'react';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

export type BulkEditHeaderProps = {
  headerText: ReactNode;
};

export const BulkEditHeader = ({ headerText }: BulkEditHeaderProps) => {
  const { stage } = useBulkEditHubContext();
  return (
    <span>
      {headerText}{' '}
      {stage === BulkEditStage.GettingPreview ? (
        <>
          {' - '}
          <Content id={ContentId.GeneratingPreview} />
        </>
      ) : stage === BulkEditStage.Preview ? (
        <>
          {' - '}
          <Content id={ContentId.Preview} />
        </>
      ) : (
        <></>
      )}
    </span>
  );
};
