import { ReactNode } from 'react';
import { useBulkEditTagsPermissions } from 'src/components/common/BulkActions/BulkEditTagsAction/useBulkEditTagsPermissions';
import { ActionOutboxEntityType } from 'src/WebApiController';

interface BulkEditTagsActionPermissionsHandlerProps {
  children: ReactNode;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.Purchase;
}

export const BulkEditTagsActionPermissionsHandler = ({
  children,
  entityType,
}: BulkEditTagsActionPermissionsHandlerProps) => {
  const { hasEditTagsPermissions } = useBulkEditTagsPermissions({ entityType });

  if (!hasEditTagsPermissions) {
    return null;
  }

  return <>{children}</>;
};
