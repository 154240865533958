import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useContent, useFormattedContent } from 'src/contexts/ContentContext';
import { PosSelectableSearchBox } from 'src/core/POS/PosSearchBox';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

export enum SearchMode {
  EventSearch = 'EventSearch',
  IdLookup = 'IdLookup',
}

export const EventEntitySearchBox = ({
  entityLookupPlaceholder,
  onIdLookup,
  onEventSearch,
  minWidth,
  maxWidth,
  disabled,
  searchText,
  setSearchText,
  defaultSearchMode,
  freezeSearchMode = false,
}: {
  entityLookupPlaceholder: ContentId;
  onIdLookup: (id: string) => void;
  onEventSearch: (searchText: string) => void;
  minWidth?: number | string;
  maxWidth?: number | string;
  disabled?: boolean;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  defaultSearchMode?: SearchMode;
  freezeSearchMode?: boolean;
}) => {
  const [searchMode, setSearchMode] = useState(defaultSearchMode);
  const eventsText = useContent(ContentId.Events);
  const lookupPlaceholder = useContent(entityLookupPlaceholder);
  const searchPlaceholder = useFormattedContent(
    FormatContentId.SearchItem,
    eventsText
  ) as string;

  useEffect(() => {
    if (defaultSearchMode != null && searchMode == null)
      setSearchMode(defaultSearchMode);
  }, [defaultSearchMode, searchMode]);

  return (
    <PosSelectableSearchBox
      value={searchText}
      disabled={disabled}
      minWidth={minWidth}
      maxWidth={maxWidth}
      placeholder={
        searchMode === SearchMode.IdLookup
          ? lookupPlaceholder
          : searchPlaceholder
      }
      onSearchChange={(searchText) => {
        setSearchText(searchText);
        if (searchMode !== SearchMode.IdLookup) {
          onEventSearch(searchText);
        } else if (!searchText) {
          // If the search text is cleared, in Id mode, we want to clear the Id lookup as well
          onIdLookup('');
        }
      }}
      onEnterKeyPressed={
        searchMode === SearchMode.IdLookup ? onIdLookup : undefined
      }
      selectorProps={
        !freezeSearchMode
          ? {
              value: searchMode ?? SearchMode.EventSearch,
              onChange: (newMode) => {
                if (newMode && newMode !== searchMode) {
                  if (searchMode === SearchMode.IdLookup) {
                    onIdLookup(''); // reset ID filters
                  } else {
                    onEventSearch(''); // clear search text when mode changes
                  }
                  setSearchText('');
                  setSearchMode(newMode);
                }
              },
              valueOptionsContent: {
                [SearchMode.EventSearch]: ContentId.Event,
                [SearchMode.IdLookup]: ContentId.Id,
              },
            }
          : undefined
      }
    />
  );
};
