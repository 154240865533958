import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useListingGroupPricingSettings } from 'src/hooks/api/useListingGroupPricingSettings';
import { useGetAccountAutoPricingSettings } from 'src/hooks/useGetAccountAutoPricingSettings';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AutoPricingCompListingMode,
  AutoPricingInputs,
  AutoPricingOutlierMode,
  AutoPricingUndercutMode,
  ListingClient,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
} from 'src/WebApiController';

const getListingDetailsAutoPricingInput = (
  listing: ListingDetails | null | undefined,
  accountPricingSettings?: AutoPricingInputs | null,
  groupPricingSettings?: AutoPricingInputs | null
): ListingDetailsAutoPricingSectionUpdates => {
  const quantityScoreOverrideJson = JSON.parse(
    listing?.compQtyScrAdjJson ?? 'null'
  );

  // Listing is totally null for bulk updates
  const defaultQuantityScoreOverrideJson =
    listing == null
      ? null
      : JSON.stringify({
          ...quantityScoreOverrideJson,
          quantityFilter: null,
        });
  // If SectionId or RowId filters are set, make sure we alwayws set compListingOnlyForSelectedSectionsEnabled to true
  // If a user decides to disable this setting, we need to make it known that the selected sections are not being used
  const defaultCompListingOnlyForSelectedSectionsEnabled =
    !!listing?.compSectionSettings?.sectionIdFilter?.length;
  return {
    id: listing?.id ?? 0,
    rowVersion: null,
    netProceedsFloor: listing?.procsFloor ?? null,
    netProceedsCeiling: listing?.procsCeil ?? null,
    currencyCode: listing?.currency ?? null,
    autoPricingEnabled: listing?.isAutoPrc ?? null,

    compListingFloor:
      listing?.compFloor ??
      groupPricingSettings?.compListingFloor ??
      accountPricingSettings?.compListingFloor ??
      1,
    compListingCeiling:
      listing?.compCeil ??
      groupPricingSettings?.compListingCeiling ??
      accountPricingSettings?.compListingCeiling ??
      6,
    compListingMode:
      listing?.compMode ??
      groupPricingSettings?.compListingMode ??
      accountPricingSettings?.compListingMode ??
      AutoPricingCompListingMode.QualityScore,
    compListingOnlyForSameZoneEnabled:
      listing?.isCompForSameZone ??
      groupPricingSettings?.compListingOnlyForSameZoneEnabled ??
      accountPricingSettings?.compListingOnlyForSameZoneEnabled ??
      !defaultCompListingOnlyForSelectedSectionsEnabled, // Default to false if we have selected sections enabled
    compListingOnlyForSelectedSectionsEnabled:
      listing?.isCompSelectedSections ??
      groupPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
      accountPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
      defaultCompListingOnlyForSelectedSectionsEnabled,
    compListingExcludeFanInventory:
      listing?.compExclFanInv ??
      groupPricingSettings?.compListingExcludeFanInventory ??
      accountPricingSettings?.compListingExcludeFanInventory ??
      null,
    compListingExcludeDefects:
      listing?.compExclDefects ??
      groupPricingSettings?.compListingExcludeDefects ??
      accountPricingSettings?.compListingExcludeDefects ??
      null,
    compListingQuantityScoreAdjustmentEnabled:
      listing?.isCompQtyScrAdj ??
      groupPricingSettings?.compListingQuantityScoreAdjustmentEnabled ??
      accountPricingSettings?.compListingQuantityScoreAdjustmentEnabled ??
      null,
    compListingQuantityScoreAdjustmentOverrideJson:
      listing?.compQtyScrAdjJson ??
      groupPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson ??
      defaultQuantityScoreOverrideJson,
    compListingSelectedSectionSettings:
      listing?.compSectionSettings ??
      groupPricingSettings?.compListingSelectedSectionSettings ??
      accountPricingSettings?.compListingSelectedSectionSettings ??
      null,
    undercutMode:
      listing?.undMode ??
      groupPricingSettings?.undercutMode ??
      accountPricingSettings?.undercutMode ??
      AutoPricingUndercutMode.Simple,
    undercutAbsoluteAmount:
      listing?.undAbsAmt ??
      groupPricingSettings?.undercutAbsoluteAmount ??
      accountPricingSettings?.undercutAbsoluteAmount ??
      0.01,
    undercutRelativeAmount:
      listing?.undRelAmt ??
      groupPricingSettings?.undercutRelativeAmount ??
      accountPricingSettings?.undercutRelativeAmount ??
      0,
    circuitBreakerMaxDiscountVelocityPercent:
      listing?.cirBrMaxDiscVelocPercent ??
      groupPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
      accountPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
      0.8,
    circuitBreakerMaxDiscountVelocityTicksInHours:
      listing?.cirBrMaxDiscVelocTicksInHrs ??
      groupPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
      accountPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
      6,
    circuitBreakerMinCompListingCount:
      listing?.cirBrMinCompCount ??
      groupPricingSettings?.circuitBreakerMinCompListingCount ??
      accountPricingSettings?.circuitBreakerMinCompListingCount ??
      10,
    circuitBreakerRelativeCeiling:
      listing?.cirBrRelCeil ??
      groupPricingSettings?.circuitBreakerRelativeCeiling ??
      accountPricingSettings?.circuitBreakerRelativeCeiling ??
      100,
    circuitBreakerRelativeFloor:
      listing?.cirBrRelFloor ??
      groupPricingSettings?.circuitBreakerRelativeFloor ??
      accountPricingSettings?.circuitBreakerRelativeFloor ??
      1,
    outlierMode:
      listing?.outMode ??
      groupPricingSettings?.outlierMode ??
      accountPricingSettings?.outlierMode ??
      AutoPricingOutlierMode.StandardDeviations,
    outlierStandardDeviations:
      listing?.outlStdDev ??
      groupPricingSettings?.outlierStandardDeviations ??
      accountPricingSettings?.outlierStandardDeviations ??
      3,
    outlierKthLowestLimit:
      listing?.outKthLowestLim ??
      groupPricingSettings?.outlierKthLowestLimit ??
      accountPricingSettings?.outlierKthLowestLimit ??
      null,
    outlierKthLowestLimitRelativeSpacing:
      listing?.outKthLowestLimRelSpc ??
      groupPricingSettings?.outlierKthLowestLimitRelativeSpacing ??
      accountPricingSettings?.outlierKthLowestLimitRelativeSpacing ??
      null,
    outlierKthLowestLimitAbsoluteSpacing:
      listing?.outKthLowestLimAbsSpc ??
      groupPricingSettings?.outlierKthLowestLimitAbsoluteSpacing ??
      accountPricingSettings?.outlierKthLowestLimitAbsoluteSpacing ??
      null,

    // If listing id is null, we're creating the default input for bulk edit - default to skipping everything
    skipAdvancePricing: !listing?.id,
    skipAutoPricing: !listing?.id,
    skipCompListing: !listing?.id,
    skipUndercut: !listing?.id,
  };
};

export const useAutoPricingForm = (
  listing: ListingDetails | null | undefined,
  onSubmitStart: () => void,
  onSubmitDone: (success?: boolean) => void
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { pricingSettings: groupPricingSettings, loaded: groupSettingLoaded } =
    useListingGroupPricingSettings(listing?.ltGrpId);
  const {
    pricingSettings: accountPricingSettings,
    loaded: accountSettingLoaded,
  } = useGetAccountAutoPricingSettings();
  const methods = useForm<ListingDetailsAutoPricingSectionUpdates>({
    defaultValues: getListingDetailsAutoPricingInput(
      listing,
      accountPricingSettings,
      groupPricingSettings
    ),
  });

  useEffect(() => {
    if (groupSettingLoaded || accountSettingLoaded) {
      methods.reset(
        getListingDetailsAutoPricingInput(
          listing,
          accountPricingSettings,
          groupPricingSettings
        )
      );
    }
  }, [
    groupSettingLoaded,
    accountSettingLoaded,
    methods,
    listing,
    accountPricingSettings,
    groupPricingSettings,
  ]);

  const onSubmitHandler = useCallback(
    async (autoPricingSectionForm: ListingDetailsAutoPricingSectionUpdates) => {
      tryInvokeApi(
        async () => {
          onSubmitStart();
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).updateListingAutoPricingSection(autoPricingSectionForm);

          if (isSuccess(result)) {
            // Reset the form to latest changed
            methods.reset(autoPricingSectionForm);
            onSubmitDone();
          } else {
            showErrorDialog(
              'ListingClient.updateListingAutoPricingSection',
              { message: result.message, status: result.status },
              {
                trackErrorData: {
                  autoPricingSectionForm,
                },
              }
            );
          }
        },
        (error) => {
          showErrorDialog(
            'ListingClient.updateListingAutoPricingSection',
            error,
            {
              trackErrorData: autoPricingSectionForm,
            }
          );
        },
        () => {
          onSubmitDone(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      methods,
      onSubmitDone,
      onSubmitStart,
      showErrorDialog,
    ]
  );

  const onSubmit = useCallback(() => {
    methods.handleSubmit(onSubmitHandler)();
  }, [methods, onSubmitHandler]);

  const onReset = useCallback(() => {
    methods.reset(getListingDetailsAutoPricingInput(listing));
  }, [listing, methods]);

  const input = methods.watch();

  const hasChanges = useMemo(() => {
    const defaultValues = methods.formState.defaultValues;
    return (
      input.netProceedsFloor !== defaultValues?.netProceedsFloor ||
      input.netProceedsCeiling !== defaultValues?.netProceedsCeiling ||
      input.currencyCode !== defaultValues?.currencyCode ||
      input.autoPricingEnabled !== defaultValues?.autoPricingEnabled ||
      input.compListingFloor !== defaultValues?.compListingFloor ||
      input.compListingCeiling !== defaultValues?.compListingCeiling ||
      input.compListingMode !== defaultValues?.compListingMode ||
      input.compListingOnlyForSameZoneEnabled !==
        defaultValues?.compListingOnlyForSameZoneEnabled ||
      input.compListingOnlyForSelectedSectionsEnabled !==
        defaultValues?.compListingOnlyForSelectedSectionsEnabled ||
      input.compListingExcludeFanInventory !==
        defaultValues?.compListingExcludeFanInventory ||
      input.compListingExcludeDefects !==
        defaultValues?.compListingExcludeDefects ||
      input.compListingQuantityScoreAdjustmentEnabled !==
        defaultValues?.compListingQuantityScoreAdjustmentEnabled ||
      input.compListingQuantityScoreAdjustmentOverrideJson !==
        defaultValues?.compListingQuantityScoreAdjustmentOverrideJson ||
      input.undercutMode !== defaultValues?.undercutMode ||
      input.undercutAbsoluteAmount !== defaultValues?.undercutAbsoluteAmount ||
      input.undercutRelativeAmount !== defaultValues?.undercutRelativeAmount ||
      input.circuitBreakerMaxDiscountVelocityPercent !==
        defaultValues?.circuitBreakerMaxDiscountVelocityPercent ||
      input.circuitBreakerMaxDiscountVelocityTicksInHours !==
        defaultValues?.circuitBreakerMaxDiscountVelocityTicksInHours ||
      input.circuitBreakerMinCompListingCount !==
        defaultValues?.circuitBreakerMinCompListingCount ||
      input.circuitBreakerRelativeCeiling !==
        defaultValues?.circuitBreakerRelativeCeiling ||
      input.circuitBreakerRelativeFloor !==
        defaultValues?.circuitBreakerRelativeFloor ||
      input.outlierMode !== defaultValues?.outlierMode ||
      input.outlierStandardDeviations !==
        defaultValues?.outlierStandardDeviations ||
      input.outlierKthLowestLimit !== defaultValues?.outlierKthLowestLimit ||
      input.outlierKthLowestLimitRelativeSpacing !==
        defaultValues?.outlierKthLowestLimitRelativeSpacing ||
      input.outlierKthLowestLimitAbsoluteSpacing !==
        defaultValues?.outlierKthLowestLimitAbsoluteSpacing ||
      input.skipAdvancePricing !== defaultValues?.skipAdvancePricing ||
      input.skipAutoPricing !== defaultValues?.skipAutoPricing ||
      input.skipCompListing !== defaultValues?.skipCompListing ||
      input.skipUndercut !== defaultValues?.skipUndercut ||
      !isEqual(
        input.compListingSelectedSectionSettings,
        defaultValues?.compListingSelectedSectionSettings
      )
    );
  }, [input, methods.formState.defaultValues]);

  return {
    hasChanges,
    methods,
    onSubmit,
    onReset,
  };
};
