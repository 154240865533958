import { Content, useContent } from 'src/contexts/ContentContext';
import { shared } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { AddVendorButton } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/AddVendorButton';
import { FilterInput } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/FilterInput';
import { vendorsPageHeaderWrapper } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsPageHeader/VendorsPageHeader.css';
import { ContentId } from 'src/utils/constants/contentId';

interface VendorsPageHeaderProps {
  disableSearch: boolean;
  onVendorAdded: () => void;
  onSearchTextChanged: (searchText: string) => void;
}

export const VendorsPageHeader = ({
  disableSearch,
  onVendorAdded,
  onSearchTextChanged,
}: VendorsPageHeaderProps) => {
  const vendors = useContent(ContentId.Vendors);
  return (
    <div className={vendorsPageHeaderWrapper}>
      <Stack justifyContent="spaceBetween" alignItems="center">
        <div className={shared.typography.title5}>
          <Content id={ContentId.Vendors} />
        </div>
        <Stack direction="row" gap="m" alignItems="center">
          <FilterInput
            disable={disableSearch}
            onSearchTextChanged={onSearchTextChanged}
            searchEntityLabel={vendors}
          />
          <AddVendorButton onVendorAdded={onVendorAdded} />
        </Stack>
      </Stack>
    </div>
  );
};
