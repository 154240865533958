import { MouseEvent, ReactNode, useMemo } from 'react';
import { useToggle } from 'react-use';
import { useGetInventoryGroupingTitle } from 'src/components/Events/EventListing/InventoryEventListing/useGetInventoryGroupingTitle';
import {
  InventoryEventTable,
  InventoryEventTableData,
} from 'src/components/Listings/InventoryEventTable';
import { ActiveSortTableColumnProvider } from 'src/contexts/ActiveSortTableColumnContext';
import { CollapsableViewProvider } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { EventsTableWithInnerGrouping } from 'src/tables/EventsTable/EventsTableWithInnerGrouping';
import { EventsTableColumnId } from 'src/utils/columns/events/eventsColumnUtils.types';
import { CustomEventsColumn } from 'src/utils/columns/events/eventsCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { EVENT_SORT_DATE_ONLY_TO_CID } from 'src/utils/constants/contentIdMaps';
import { SectionType } from 'src/utils/types/sectionType';
import { EventWithData, Feature, UserSetting } from 'src/WebApiController';

import { EventVirtualizedList } from '../EventVirtualizedList';

const getDividerText = (item: InventoryEventTableData) => {
  return item.grouping;
};

type InventoryDetailedEventViewProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
  items?: EventWithData[];
  highlightEvent?: string; // Event UUID
  /**
   * If provided, the list will scroll to the month containing the event with the given viagogo virtual id
   */
  scrollToEventId?: string;
};

export const InventoryDetailedEventView = ({
  items,
  highlightEvent,
  scrollToEventId,
  ...rest
}: InventoryDetailedEventViewProps) => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);
  const hasListingInsightViewV2 = useUserHasFeature(
    Feature.ListingInsightViewV2
  );
  const { getInventoryEventGroupingText } = useGetInventoryGroupingTitle();

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  const normalizedItems = useMemo(() => {
    return (items ?? [])
      .map(
        (e) =>
          ({
            grouping: getInventoryEventGroupingText(e.event),
            events: [e],
          }) as InventoryEventTableData
      )
      .reduce<InventoryEventTableData[]>((r, e) => {
        if (r.length === 0) return [e];

        const prev = r[r.length - 1];
        if (prev.grouping === e.grouping) {
          prev.events = [...prev.events, ...e.events];
        } else {
          r.push(e);
        }

        return r;
      }, []);
  }, [getInventoryEventGroupingText, items]);

  // This is a rough estimate because it only scrolls to the first event in the group (month)
  // May be good enough in some cases but won't work well if there are a lot of events in the same month
  // Non-trivial to scroll to the accurate index for Tanstack Table as there's no native support and there is pagination
  const scrollToItemIndex = useMemo(() => {
    if (scrollToEventId == null || normalizedItems == null) {
      return undefined;
    }

    const scrollToIndex = normalizedItems.findIndex((e) =>
      e.events.some((ev) => ev.event.viagVirtualId === scrollToEventId)
    );

    return scrollToIndex === -1 ? undefined : scrollToIndex;
  }, [normalizedItems, scrollToEventId]);

  return (
    <ColumnResizingContextProvider<EventsTableColumnId>
      userSettingId={UserSetting.EventsColumnWidths}
    >
      <ActiveSortTableColumnProvider>
        {isColumnModalOpen ? (
          <ColumnSettingsModal<CustomEventsColumn>
            onClose={toggleColumnModal}
            sectionType={SectionType.Events}
          />
        ) : null}
        {hasListingInsightViewV2 ? (
          <CollapsableViewProvider isEnabled={true}>
            <EventsTableWithInnerGrouping
              events={items ?? []}
              before={rest.before}
              topListItemBefore={rest.topListItemBefore}
              columnSettingButton={
                <Button
                  variant="textPlain"
                  onClick={onColumnSettingButtonClickHandler}
                >
                  <LayoutIcon size={vars.iconSize.m} />
                  <Content id={ContentId.Columns} />
                </Button>
              }
            />
          </CollapsableViewProvider>
        ) : (
          <EventVirtualizedList
            {...rest}
            items={normalizedItems}
            highlightedId={highlightEvent}
            disablePagination
            getDividerText={getDividerText}
            ItemComponent={InventoryEventTable}
            eventSortOptions={EVENT_SORT_DATE_ONLY_TO_CID}
            scrollToItemIndex={scrollToItemIndex}
            tableActions={
              <>
                <Button
                  variant="textPlain"
                  onClick={onColumnSettingButtonClickHandler}
                >
                  <LayoutIcon size={vars.iconSize.m} />
                  <Content id={ContentId.Columns} />
                </Button>
              </>
            }
          />
        )}
      </ActiveSortTableColumnProvider>
    </ColumnResizingContextProvider>
  );
};
