import { VendorDetailsModalBody } from 'src/modals/VendorDetails/VendorDetailsModalBody';
import { VendorIdQueryParam } from 'src/utils/constants/constants';

export const VendorDetailsModalConfig = {
  children: <VendorDetailsModalBody />,
  clickOutsideToClose: true,
  deepLinkKey: VendorIdQueryParam,
  deepLinkValue: undefined,
  size: 'slide-in',
};
