import { ReactNode } from 'react';
import { Content, useContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './Metrics.css';

export type ListingMainMetricsV2Props = {
  unsoldTicketsCount: ReactNode;
  ticketsCount: ReactNode;
};

export function ListingMainMetricsV2({
  unsoldTicketsCount,
  ticketsCount,
}: ListingMainMetricsV2Props) {
  const unsoldTicketsCountText = useContent(ContentId.NumberOfUnsoldTickets);
  return (
    <div title={unsoldTicketsCountText}>
      {unsoldTicketsCount}
      {'/'}
      {ticketsCount} <Content id={ContentId.Tickets} />
    </div>
  );
}

export const DividerLine = () => {
  return <div className={styles.dividerLine} />;
};

export type ListingSubMetricsV2Props = {
  totalPandL: ReactNode;
  totalUnsoldCost: ReactNode;
  totalUnlistedQuantity: ReactNode;
};

export function ListingSubMetricsV2({
  totalPandL,
  totalUnsoldCost,
  totalUnlistedQuantity,
}: ListingSubMetricsV2Props) {
  const totalUnsoldCostText = useContent(ContentId.TotalCostOfUnsoldTickets);
  const pandLText = useContent(ContentId.PandLTitle);
  const totalUnlistedQuantityText = useContent(
    ContentId.NumberOfUnlistedTicketsDescription
  );

  return (
    <div className={styles.listingSubMetricsV2Container}>
      <span className={styles.listingSubMetricsV2} title={pandLText}>
        {totalPandL}
      </span>
      <DividerLine />
      <span className={styles.listingSubMetricsV2} title={totalUnsoldCostText}>
        {totalUnsoldCost}
      </span>
      <DividerLine />
      <span
        className={styles.listingSubMetricsV2}
        title={totalUnlistedQuantityText}
      >
        {totalUnlistedQuantity}
      </span>
    </div>
  );
}
