import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ActionOutboxEntityType, Permission } from 'src/WebApiController';

interface UseBulkUpdateAutoFulfillPermissionsProps {
  entityType: ActionOutboxEntityType.Listing | ActionOutboxEntityType.Sale;
}

interface BulkUpdateAutoFulfillPermissions {
  hasEditAutoFulfillPermissions: boolean;
}

export const useBulkUpdateAutoFulfillPermissions = ({
  entityType,
}: UseBulkUpdateAutoFulfillPermissionsProps): BulkUpdateAutoFulfillPermissions => {
  const hasEditAutoFulfillPermissions = useUserHasAnyOfPermissions(
    ...(entityType === ActionOutboxEntityType.Listing
      ? [
          Permission.Inventory_UpdateListingInfo,
          Permission.Inventory_UpdateListingInfoOnIsPricerOf,
          Permission.Inventory_UpdateListingInfoOnPurchasedBy,
        ]
      : [
          Permission.Sales_UpdateSalesInfo,
          Permission.Sales_UpdateSalesInfoOnIsFulfillerOf,
          Permission.Sales_UpdateSalesInfoOnIsPricerOf,
          Permission.Sales_UpdateSaleOnPurchasedBy,
        ])
  );

  return {
    hasEditAutoFulfillPermissions,
  };
};
