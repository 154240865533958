import clsx from 'clsx';

/**
 * Merges props according to the following rules:
 * 1. Classnames will be concatenated
 * 2. Callbacks will be called in order, and the result of the final callback will be returned
 * 3. All other props will be overwritten if the value is not `undefined`
 *
 * NOTE: Do **NOT** pass refs into this function. If you need to merge refs use `mergeRefs`
 * separately instead.
 * @param defaultProps
 * @param props
 * @returns Merged props.
 */
export function mergeProps<P extends object, K extends keyof P>(
  defaultProps: Pick<P, K>,
  props: P
): P {
  const resultProps = { ...props };
  for (const key in defaultProps) {
    const defaultProp = defaultProps[key];
    const resultProp = resultProps[key];
    if (
      key === 'className' &&
      typeof defaultProp === 'string' &&
      typeof resultProp === 'string'
    ) {
      resultProps[key] = clsx(defaultProp, resultProp) as typeof resultProp;
    } else if (
      typeof defaultProp === 'function' &&
      typeof resultProp === 'function' &&
      key[0] === 'o' &&
      key[1] === 'n' &&
      key.charCodeAt(2) >= 'A'.charCodeAt(0) &&
      key.charCodeAt(2) <= 'Z'.charCodeAt(0)
    ) {
      resultProps[key] = ((...args: unknown[]) => {
        defaultProp(...args);
        return resultProp(...args);
      }) as typeof resultProp;
    } else if (resultProp === undefined) {
      resultProps[key] = defaultProps[key];
    }
  }
  return resultProps;
}
