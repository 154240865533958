import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { AppRouteConfigs } from 'src/app/AppRoutes';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { SearchResultPickerDialog } from 'src/dialogs/SearchResultPickerDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useHasReleaseNotesUnread } from 'src/hooks/useHasReleaseNotesUnread';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { UnreadDot } from 'src/navigations/Routes/ReleaseNotes';
import {
  IconsFill,
  ProfileOutlineIcon,
  SigninIcon,
  SignoutIcon,
} from 'src/svgs/Viagogo';
import {
  REACT_APP_MESSAGES_BRIDGE_URL,
  REACT_APP_REPORTS_BRIDGE_URL,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { hasAccessToRoute, sortSellerAccount } from 'src/utils/userUtils';
import {
  Feature,
  LoginClient,
  SellerAccount,
  SellerAccountClient,
} from 'src/WebApiController';

import { ProLogo } from '../../../components/common/ProLogo';
import { SellerAccountBasicInfo } from '../../../components/SellerAccountBasicInfo';
import { UserInfoDisplay } from '../UserInfoDisplay';
import { NavTitle } from './MainNavTitle';
import * as styles from './MainNavTopBar.css';
import {
  DropdownDivider,
  DropdownLabel,
  Header,
  HeaderLeftAreaDiv,
  HeaderWrapperDiv,
} from './MainNavTopBar.styled';

export function MainNavTopBar() {
  const {
    appContext,
    cobrandId,
    mainRoute,
    loginContext,
    activeAccountWebClientConfig,
  } = useAppContext();

  const navigate = useNavigate();
  const signInText = useContent(ContentId.SignIn);
  const myAccountText = useContent(ContentId.MyAccount);
  const menuText = loginContext ? myAccountText : signInText;
  const { showErrorDialog } = useErrorBoundaryContext();

  const [sellerAccounts, setSellerAccounts] = useState<SellerAccount[]>([]);
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const [isSellerAccountLoading, setIsSellerAccountLoading] = useState(false);

  const hasAutoPoAccess = useUserHasFeature(Feature.AutoPO);
  const allowedRoutes = Object.values(AppRouteConfigs).filter(
    (r) =>
      r.view != null &&
      hasAccessToRoute(loginContext?.user, appContext?.features, r.view)
  );

  const { hasReleaseNotesUnread } = useHasReleaseNotesUnread({});

  const onSwitchAccountClicked = useCallback(async () => {
    const accounts = await tryInvokeApi(
      async () => {
        setIsSellerAccountLoading(true);
        return await new SellerAccountClient(
          activeAccountWebClientConfig
        ).getCurrentUserSellerAccounts();
      },
      (error) => {
        showErrorDialog(
          'SellerAccountClient.getCurrentUserSellerAccounts',
          error
        );
      },
      () => setIsSellerAccountLoading(false)
    );
    if (accounts) {
      setSellerAccounts(sortSellerAccount(accounts));
      launchDialog();
    }
  }, [activeAccountWebClientConfig, launchDialog, showErrorDialog]);

  const onSellerAccountPicked = useCallback(
    async (account: SellerAccount) => {
      if (account.id === activeAccountWebClientConfig.activeAccountId) {
        return;
      }

      await tryInvokeApi(
        async () => {
          setIsSellerAccountLoading(true);
          await new LoginClient(activeAccountWebClientConfig).setActiveAccount(
            account.id,
            account.impersonatedUserId
          );
          // We need to reload so all the data is reload with the new login context
          window.location.reload();
          closeDialog();
        },
        (error) => {
          showErrorDialog('LoginClient.setActiveAccount', error, {
            trackErrorData: account,
          });
        },
        () => setIsSellerAccountLoading(false)
      );
    },
    [activeAccountWebClientConfig, closeDialog, showErrorDialog]
  );

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <>
      <HeaderWrapperDiv>
        <Header>
          <HeaderLeftAreaDiv>
            <div className="brand-logo">
              <ProLogo />
            </div>
          </HeaderLeftAreaDiv>
          <div className={styles.headerCenterAreaDiv}>
            {allowedRoutes.map(({ view, label, path, icon: Icon }, index) => (
              <NavLink
                key={index}
                tag={Link}
                className={styles.navLink}
                to={path}
              >
                <NavTitle isActive={mainRoute === view} cobrandId={cobrandId}>
                  {Icon && (
                    <Icon
                      size={vars.iconSize.l}
                      fill={IconsFill.currentColor}
                    />
                  )}
                  {label || view}
                </NavTitle>
              </NavLink>
            ))}
          </div>
          <div className={styles.headerRightAreaDiv}>
            <PosDropdown trigger={<ProfileOutlineIcon />} align="end">
              {loginContext ? (
                <>
                  <PosDropdownItem>
                    {loginContext ? (
                      <UserInfoDisplay
                        userInfo={loginContext.user}
                        showUserName={false}
                      />
                    ) : (
                      <DropdownLabel>{menuText}</DropdownLabel>
                    )}
                  </PosDropdownItem>
                  <DropdownDivider />

                  {loginContext?.user?.hasManyAccounts && (
                    <PosDropdownItem onClick={onSwitchAccountClicked}>
                      <Content id={ContentId.SwitchAccount} />
                    </PosDropdownItem>
                  )}

                  <PosDropdownItem onClick={() => navigate('/release-notes')}>
                    <div className={styles.releaseNoteOptionContainer}>
                      <Content id={ContentId.WhatsNew} />
                      {hasReleaseNotesUnread ? <UnreadDot /> : null}
                    </div>
                  </PosDropdownItem>

                  <PosDropdownItem onClick={() => navigate('/notifications')}>
                    <Content id={ContentId.Notifications} />
                  </PosDropdownItem>
                  {!hasAutoPoAccess && (
                    <PosDropdownItem
                      role="link"
                      onClick={() =>
                        openInNewTab(REACT_APP_MESSAGES_BRIDGE_URL!)
                      }
                    >
                      <Content id={ContentId.Messages} />
                    </PosDropdownItem>
                  )}
                  <PosDropdownItem
                    role="link"
                    onClick={() => openInNewTab(REACT_APP_REPORTS_BRIDGE_URL!)}
                  >
                    <Content id={ContentId.Payments} />
                  </PosDropdownItem>
                  {hasAutoPoAccess && (
                    <PosDropdownItem onClick={() => navigate('/messages')}>
                      <Content id={ContentId.Messages} />
                    </PosDropdownItem>
                  )}

                  <PosDropdownItem onClick={() => navigate('/settings')}>
                    <Content id={ContentId.Settings} />
                  </PosDropdownItem>

                  <DropdownDivider />
                  <PosDropdownItem onClick={() => navigate('/logout')}>
                    <Stack gap="m" alignItems="center">
                      <SignoutIcon />
                      <Content id={ContentId.SignOut} />
                    </Stack>
                  </PosDropdownItem>
                </>
              ) : (
                <PosDropdownItem onClick={() => navigate('/login')}>
                  <Stack gap="m" alignItems="center">
                    <SigninIcon />
                    <Content id={ContentId.SignIn} />
                  </Stack>
                </PosDropdownItem>
              )}
            </PosDropdown>
          </div>
        </Header>
      </HeaderWrapperDiv>
      <SearchResultPickerDialog
        {...dialogProps}
        header={<Content id={ContentId.SelectAccountToSwitch} />}
        resultItems={sellerAccounts || []}
        isLoading={isSellerAccountLoading}
        renderItem={(account: SellerAccount) => (
          <SellerAccountBasicInfo
            account={account}
            isCurrent={
              account.id === activeAccountWebClientConfig.activeAccountId
            }
          />
        )}
        onResultItemPicked={onSellerAccountPicked}
        onCancel={closeDialog}
      />
    </>
  );
}
