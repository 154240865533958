import { ReactNode, useCallback } from 'react';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  CatalogDataContextProvider,
  ExpandedEventData,
} from 'src/contexts/CatalogDataContext';
import { CatalogMetricsContextProvider } from 'src/contexts/CatalogMetricsContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { SidePanelProvider } from 'src/contexts/SidePanelContext';
import {
  getPresetFromUiDateTimeRange,
  getUiDateTimeRangeFromPreset,
  InhandDateRangePresetNames,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import { DefaultSaleQuery, EmptySaleQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { addSaleMetrics } from 'src/utils/ticketMetricUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  CatalogClient,
  DateTimeRange,
  PosClientConfig,
  SaleClient,
  SaleDetails,
  SaleMetrics,
  SaleQuery,
  UserSetting,
} from 'src/WebApiController';

import { SalesEventsExplorer } from './SalesEventsExplorer';

export const getCatalogData = async (
  client: CatalogClient,
  filterQuery: SaleQuery,
  includeCounts: boolean
) => {
  return await client.getCatalogForSales(filterQuery, includeCounts);
};

export const SALE_USER_SETTINGS = [
  UserSetting.SaleColumnEditability,
  UserSetting.SaleColumnNumberPrecision,
  UserSetting.SaleColumnOrder,
  UserSetting.SaleColumnsEnabled,
  UserSetting.SaleCustomColumns,
  UserSetting.SaleFlattenedColumnEditability,
  UserSetting.SaleFlattenedColumnNumberPrecision,
  UserSetting.SaleFlattenedColumnOrder,
  UserSetting.SaleFlattenedColumnsEnabled,
  UserSetting.QuickFiltersStateSales,
  UserSetting.SaleColumnWidths,
  UserSetting.SalePageViewMode,
  UserSetting.SalesDefaultTab,
];

export const getCatalogDataExpanded = async (
  viagogoVirtualIds: string[],
  filterQuery: SaleQuery,
  {
    activeAccountWebClientConfig,
    onError,
  }: {
    activeAccountWebClientConfig: PosClientConfig;
    onError?: (error: ErrorTypes) => void;
  }
) => {
  const result = await tryInvokeApi(async () => {
    const allSales = await new SaleClient(
      activeAccountWebClientConfig
    ).getSalesForEvents(
      {
        ...filterQuery,
        // Is is to support the legacy event ID urls
        oldPosEventIds: viagogoVirtualIds
          .filter((id) => id.startsWith('old:'))
          .map((id) => id.substring(4)),
        eventOrMappingIds: viagogoVirtualIds.filter(
          (id) => !id.startsWith('old:')
        ),
      },
      true
    );

    const emptyResults = viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        sales: null,
        listings: null,
        ticketGroups: null,
        failedToRetrieveData: false,
      };
      return results;
    }, {} as ExpandedEventData);

    return Promise.resolve(
      Object.entries(allSales).reduce((results, [id, sales]) => {
        results[id] = {
          sales: sales,
          listings: null,
          ticketGroups: null,
          failedToRetrieveData: false,
        };
        return results;
      }, emptyResults)
    );
  }, onError);

  if (result) {
    return Promise.resolve(result);
  }

  return Promise.resolve(
    viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        failedToRetrieveData: true,
        sales: null,
        listings: null,
        ticketGroups: null,
      };
      return results;
    }, {} as ExpandedEventData)
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const salesQueryValueTransformToUrl = (key: string, value: any) => {
  if (!value) {
    return value;
  }

  const salesKey = key as keyof SaleQuery;
  if (salesKey === 'saleDates') {
    const preset = getPresetFromUiDateTimeRange(value as DateTimeRange);
    if (preset && StandardDateRangePresetNames.includes(preset.name)) {
      return preset.name;
    }
  }

  if (salesKey === 'inHandDates') {
    const preset = getPresetFromUiDateTimeRange(value as DateTimeRange);
    if (preset && InhandDateRangePresetNames.includes(preset.name)) {
      return preset.name;
    }
  }

  return value;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const salesQueryValueTransformFromUrl = (key: string, value: any) => {
  if (!value) {
    return value;
  }

  const salesKey = key as keyof SaleQuery;
  if (salesKey === 'saleDates') {
    const preset = getUiDateTimeRangeFromPreset(
      value,
      StandardDateRangePresetNames
    );
    if (preset) {
      return preset;
    }
  } else if (salesKey === 'inHandDates') {
    const preset = getUiDateTimeRangeFromPreset(
      value,
      InhandDateRangePresetNames
    );
    if (preset) {
      return preset;
    }
  }

  return value;
};

export function SaleContextProvider({
  saveQueryInUrl,
  children,
}: {
  saveQueryInUrl?: boolean;
  children?: ReactNode;
}) {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], filterQuery: SaleQuery) =>
      getCatalogDataExpanded(ids, filterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('SaleClient.getSalesForEvents', error, {
            ...filterQuery,
            eventIds: ids,
          });
        },
      }),
    [activeAccountWebClientConfig, trackError]
  );

  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={SALE_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<SaleQuery>
        initialQuery={DefaultSaleQuery}
        emptyQuery={EmptySaleQuery}
        saveQueryInUrl={saveQueryInUrl}
        viewModeSettingId={UserSetting.SalePageViewMode}
        queryValueTransformToUrl={salesQueryValueTransformToUrl}
        queryValueTransformFromUrl={salesQueryValueTransformFromUrl}
      >
        <CatalogDataContextProvider<SaleQuery>
          queryKey="getCatalogForSales"
          getCatalogData={(c, f) => getCatalogData(c, f, true)}
          getCatalogDataExpanded={getCatalogDataExpandedCallback}
          transformEventData={transformData}
        >
          {children}
        </CatalogDataContextProvider>
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}

export function Sales() {
  return (
    <SaleContextProvider saveQueryInUrl>
      <SalesRouteBody />
    </SaleContextProvider>
  );
}

export const getCatalogMetrics = (
  client: CatalogClient,
  filterQuery: SaleQuery
) => {
  return client.getCatalogSaleMetrics(filterQuery);
};

const SalesRouteBody = () => {
  const { activeAccountWebClientConfig } = useAppContext();

  const getActivePosEntity = useCallback(
    async (saleId: number) => {
      const saleDetail = await new SaleClient(
        activeAccountWebClientConfig
      ).getSaleBySaleId(saleId);

      if (saleDetail) {
        return {
          posEntityId: saleDetail.id,
          posEntity: saleDetail,
          posEntityDisplayId: saleDetail.idOnMkp,
        };
      }

      return {};
    },
    [activeAccountWebClientConfig]
  );

  return (
    <CatalogMetricsContextProvider<SaleMetrics, SaleQuery>
      queryKey="getCatalogSaleMetrics"
      getCatalogMetrics={getCatalogMetrics}
      addCatalogMetrics={addSaleMetrics}
    >
      <ActivePosEntityProvider<SaleDetails>
        entityType={ActionOutboxEntityType.Sale}
        getActivePosEntity={getActivePosEntity}
      >
        <SidePanelProvider sidePanelId="main-pages">
          <SalesEventsExplorer />
        </SidePanelProvider>
      </ActivePosEntityProvider>
    </CatalogMetricsContextProvider>
  );
};
