import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { FieldLabel, FieldWrapper } from 'src/modals/common/Purchase';
import { fieldText } from 'src/modals/common/Purchase/Purchase.css';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './SellerAccountSettingHeader.css';
import {
  HeaderLeftGroup,
  HeaderRightGroup,
  HeaderSection,
  VendorContainer,
} from './SellerAccountSettingHeader.styled';

export const SellerAccountSettingHeader = () => {
  const { loginContext } = useAppContext();
  const sellerAccountName = loginContext?.user?.displayName;
  const userInformation = {
    name: loginContext?.user?.activeAccount?.accountName,
    email: loginContext?.user?.userName,
  };

  return (
    <HeaderSection>
      <div className={styles.headerGroups}>
        <HeaderLeftGroup>
          <VendorContainer>
            <div>{sellerAccountName}</div>
          </VendorContainer>
          <FieldWrapper>
            <FieldLabel>
              <Content id={ContentId.MyAccount} />
            </FieldLabel>
            <div className={fieldText}>{userInformation.name}</div>
          </FieldWrapper>
          <FieldWrapper>
            <FieldLabel>
              <Content id={ContentId.Email} />
            </FieldLabel>
            <div className={fieldText}>{userInformation.email}</div>
          </FieldWrapper>
        </HeaderLeftGroup>
        <HeaderRightGroup></HeaderRightGroup>
      </div>
      <div className={styles.headerGroups}>
        <HeaderRightGroup></HeaderRightGroup>
      </div>
    </HeaderSection>
  );
};
