import { ReactNode } from 'react';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { Permission } from 'src/WebApiController';

interface PurchaseGlobalActionsPermissionsWrapperProps {
  children: ReactNode;
  showBulkUpload?: boolean;
}

export const PurchaseGlobalActionsPermissionsWrapper = ({
  children,
  showBulkUpload,
}: PurchaseGlobalActionsPermissionsWrapperProps) => {
  const hasPurchaseUpdatePermission = useUserHasAnyOfPermissions(
    Permission.Purchases_Edit,
    Permission.Purchases_EditOwned
  );

  if (!showBulkUpload && !hasPurchaseUpdatePermission) {
    return null;
  }

  return <>{children}</>;
};
