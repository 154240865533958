import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PurchaseClient } from 'src/WebApiController';

export const GET_VENDOR_QUERY_KEY = 'getVendor';

export const useGetVendor = (vendorId: number | undefined) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  return useQuery({
    queryKey: [
      GET_VENDOR_QUERY_KEY,
      activeAccountWebClientConfig.activeAccountId,
      vendorId,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return tryInvokeApi(
        () => {
          return new PurchaseClient(activeAccountWebClientConfig).getVendor(
            vendorId
          );
        },
        (error) => {
          trackError('PurchaseClient.getVendor', error);
        }
      );
    },
    enabled: vendorId != null,
    refetchOnWindowFocus: false,
  });
};
