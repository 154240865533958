import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const ActivityLogLaunchButtonIconSpan = styled.span`
  color: ${(props) => props.theme.colors.textBrand};
  ${(props) => props.theme.typography.getStyles('body2')}
  cursor: pointer;
  height: ${() => vars.iconSize.s};
  display: inline-block;
`;
