import { DateTimeRangeWithRelative } from 'src/WebApiController';

const DAY_IN_SECONDS = 86400;
const TIME_BUCKET_TOTAL_COLUMN_ID = 'timeBucketTotal';

export const timeBucketToDayString = (
  timeBucket: DateTimeRangeWithRelative,
  daysText: string,
  totalText: string
) => {
  if (
    timeBucket.relativeStartSecs == null &&
    timeBucket.relativeEndSecs == null
  ) {
    return totalText;
  }
  if (timeBucket.relativeEndSecs == null) {
    return `${Math.floor(
      timeBucket.relativeStartSecs! / DAY_IN_SECONDS
    )}+ ${daysText}`;
  }

  return `${Math.floor(timeBucket.relativeStartSecs! / DAY_IN_SECONDS)}-${
    Math.floor(timeBucket.relativeEndSecs! / DAY_IN_SECONDS) - 1
  } ${daysText}`;
};

export const timeBucketToColumnId = (timeBucket: DateTimeRangeWithRelative) =>
  timeBucket.relativeStartSecs != null || timeBucket.relativeEndSecs != null
    ? `${timeBucket.relativeStartSecs ?? ''}_${
        timeBucket.relativeEndSecs ?? ''
      }`
    : TIME_BUCKET_TOTAL_COLUMN_ID;
