import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { useScroll } from 'react-use';
import {
  Components,
  GroupedVirtuoso,
  GroupedVirtuosoHandle,
} from 'react-virtuoso';
import { ReactElementType } from 'react-window';
import { PageSection } from 'src/components/common/PageSection';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import {
  VirtuosoContext,
  VirtuosoTopListItemWrapper,
} from 'src/core/ui/Virtuoso';
import { ContentId } from 'src/utils/constants/contentId';
import { EVENT_SORT_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  EventUiSort,
  getEventSort,
  getEventUiSort,
  QueryWithViewMode,
} from 'src/utils/eventQueryUtils';
import { EntityWithTicketsQuery, EventSort } from 'src/WebApiController';

import * as styles from './EventVirtualizedList.css';

const EventListVirtuosoID = 'virtuoso-event-list';

const Header: Components<unknown, VirtuosoContext>['Header'] = ({
  context,
}: {
  context?: VirtuosoContext;
}) => {
  return <>{context?.before}</>;
};

type EventVirtualizedListProps<T> = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
  items: T[];
  highlightedId?: string;
  scrollToItemIndex?: number;
  ItemComponent: ReactElementType;
  getDividerText: (item: T) => string;
  onSelect?: (id: string) => void;
  selectedIds?: string[];
  eventSortOptions?: Record<EventUiSort, ContentId>;
  tableActions?: React.ReactElement;
  onItemsRendered?: (items: T[]) => void;
  disablePagination: boolean;
};

export const EventVirtualizedList = <T,>({
  before,
  topListItemBefore,
  items,
  highlightedId,
  scrollToItemIndex,
  onItemsRendered,
  selectedIds = [],
  onSelect,
  ItemComponent,
  getDividerText,
  eventSortOptions,
  tableActions,
  disablePagination,
}: EventVirtualizedListProps<T>) => {
  const { filterQuery, setFilterQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();
  const virtuosoRef = useRef<GroupedVirtuosoHandle>(null);
  const scrollRef = useRef<HTMLElement | null>(null);
  const { y: scrollY } = useScroll(scrollRef);

  const { groups, groupCounts } = useMemo(() => {
    const groups: string[] = [];
    const groupCounts: number[] = [];
    for (const item of items) {
      const dividerText = getDividerText(item);
      if (groups.length === 0 || groups[groups.length - 1] !== dividerText) {
        groups.push(dividerText);
        groupCounts.push(1);
      } else {
        groupCounts[groupCounts.length - 1] += 1;
      }
    }
    return { groups, groupCounts };
  }, [items, getDividerText]);

  useEffect(() => {
    if (scrollToItemIndex !== undefined) {
      virtuosoRef.current?.scrollToIndex(scrollToItemIndex);
    }
  }, [scrollToItemIndex]);

  return (
    <GroupedVirtuoso
      id={EventListVirtuosoID}
      ref={virtuosoRef}
      scrollerRef={(el) => {
        if (el instanceof HTMLElement) scrollRef.current = el;
      }}
      className={styles.root}
      context={
        {
          before,
          scrollY,
          topListItemBefore,
          virtuosoId: EventListVirtuosoID,
        } satisfies VirtuosoContext
      }
      components={{
        Header,
        TopItemList: VirtuosoTopListItemWrapper,
      }}
      overscan={window.innerHeight}
      increaseViewportBy={window.innerHeight}
      itemsRendered={(listItems) => {
        if (onItemsRendered) {
          onItemsRendered(listItems.map((i) => items[i.index]));
        }
      }}
      groupCounts={groupCounts}
      groupContent={(index) => {
        return (
          <PageSection.Header
            className={styles.groupHeader}
            onClick={() => {
              virtuosoRef.current?.scrollToIndex({ groupIndex: index });
            }}
            action={
              <div className={styles.groupHeaderAction}>
                <PosEnumSelect
                  variant="textPlain"
                  shape="none"
                  value={
                    filterQuery
                      ? getEventUiSort(
                          filterQuery.sortBy,
                          filterQuery.isSortDescending
                        )
                      : EventUiSort.DateDesc
                  }
                  defaultValue={getEventUiSort(EventSort.Date, true)}
                  onChange={(sort) => {
                    const [sortBy, isSortDescending] = getEventSort(sort);
                    if (
                      sortBy !== filterQuery.sortBy ||
                      isSortDescending !== filterQuery.isSortDescending
                    ) {
                      setFilterQuery({
                        ...filterQuery,
                        sortBy,
                        isSortDescending,
                      });
                    }
                  }}
                  valueOptionsContent={eventSortOptions ?? EVENT_SORT_TO_CID}
                />
                {tableActions}
              </div>
            }
          >
            <div className={styles.groupHeaderTitle}>
              {(() => {
                if (filterQuery.sortBy === EventSort.Date) {
                  const [month, year] = groups[index].split(' ');
                  return (
                    <>
                      <span>{month}</span>
                      &nbsp;
                      <span className={styles.itemHeaderYear}>{year}</span>
                    </>
                  );
                }
                return <>{groups[index]}</>;
              })()}
            </div>
          </PageSection.Header>
        );
      }}
      itemContent={(index) => {
        const item = items[index];
        return (
          <div className={styles.item}>
            <div className={styles.itemContentWrapper}>
              <ItemComponent
                item={item}
                highlightedId={highlightedId}
                selectedIds={selectedIds}
                onSelect={onSelect}
                disablePagination={disablePagination}
              />
            </div>
          </div>
        );
      }}
    />
  );
};
