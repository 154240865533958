import { clsx } from 'clsx';
import { useAppContext } from 'src/contexts/AppContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import StarburstLogo from 'src/images/StarburstLogo.png';
import { StubHubProLogo } from 'src/svgs';
import { TicketUtilLogo } from 'src/svgs/TicketUtilLogo';
import { CoBrandId } from 'src/WebApiController';

import * as styles from './ProLogo.css';

export const logoAreaDimensions = { height: '40px', width: 'auto' };

export type ProLogoProps = {
  className?: string;
};

export function ProLogo({ className }: ProLogoProps) {
  const { isDarkMode } = useSiteTheme();

  const { appContext, cobrandId, overrideCoBrandId } = useAppContext();

  const onLogoClicked = () => {
    if (appContext?.isDevelopment) {
      let newCobrandId = cobrandId;
      switch (cobrandId) {
        case CoBrandId.StubHub:
          newCobrandId = CoBrandId.TicketUtil;
          break;
        case CoBrandId.TicketUtil:
          newCobrandId = CoBrandId.Starburst;
          break;
        case CoBrandId.Starburst:
        default:
          newCobrandId = CoBrandId.StubHub;
          break;
      }
      overrideCoBrandId(newCobrandId);
    }
  };

  return (
    <div className={clsx(styles.root, className)} onClick={onLogoClicked}>
      {!appContext ? null : cobrandId === CoBrandId.TicketUtil ? (
        <TicketUtilLogo height={25} width={'100%'} />
      ) : cobrandId === CoBrandId.Starburst ? (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <img
          src={StarburstLogo}
          alt="Starburst"
          style={{ objectFit: 'contain', height: '100%', cursor: 'pointer' }}
        />
      ) : (
        <StubHubProLogo
          {...logoAreaDimensions}
          colorMode={isDarkMode ? 'darkBackground' : 'lightBackground'}
        />
      )}
    </div>
  );
}
