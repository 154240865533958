import { useMemo } from 'react';
import { EventNameDisplay } from 'src/components/Events/EventInfo';
import { Stack } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { ParkingPassIcon } from 'src/svgs/Viagogo/functional-svgs/ParkingPass';
import {
  EVENT_TYPE_PARKING,
  getFormattedEventName,
} from 'src/utils/eventWithDataUtils';
import { TopLevelCategory } from 'src/WebApiController';

import { EventWithDataAndMetrics } from './EventsTableColumnsConfig';
import * as styles from './EventsTableColumnsConfig.css';

export const EventDisplayCell = ({
  data,
  forceDisplayEventName,
}: {
  data: EventWithDataAndMetrics;
  forceDisplayEventName?: boolean;
}) => {
  const { event, performer, venue, isSinglePerformerView } = data;
  const [eventName, eventNameConnector, eventSubName, displayVenueOnly] =
    useMemo(() => {
      const eventName = event.shortName || event.name;
      const lowerCasedEventName = eventName.toLocaleLowerCase();
      const lowerCasedPerformer = performer?.name?.toLocaleLowerCase();
      const displayVenueOnly = forceDisplayEventName
        ? false
        : isSinglePerformerView &&
          (event.genre === TopLevelCategory.Concert ||
            event.genre === TopLevelCategory.Theatre) &&
          lowerCasedEventName === lowerCasedPerformer;

      const [
        formattedEventName,
        formattedEventConnector,
        formattedEventSubName,
      ] = getFormattedEventName({
        event,
        performer,
        venue,
        slimMode: isSinglePerformerView,
        useShortName: true,
      });

      return [
        formattedEventName,
        formattedEventConnector,
        formattedEventSubName,
        displayVenueOnly,
      ];
    }, [event, forceDisplayEventName, isSinglePerformerView, performer, venue]);

  return (
    <TableCellDiv align="left" title={event.name}>
      <Stack justifyContent="spaceBetween" width={'full'}>
        {!displayVenueOnly && (
          <EventNameDisplay
            eventName={eventName}
            eventNameConnector={eventNameConnector}
            eventSubName={eventSubName}
            showTitle={false}
            boldSubName={event.genre === TopLevelCategory.Sports}
          />
        )}
        {event.eventType === EVENT_TYPE_PARKING && (
          <ParkingPassIcon className={styles.eventDisplayIcon} />
        )}
      </Stack>
    </TableCellDiv>
  );
};
