import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { BulkDeleteTicketGroupDialog } from 'src/dialogs/BulkEdits/BulkDeleteTicketGroupDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditPreviewWithDetails,
  BulkEditTicketGroupClient,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { TICKET_GROUP_BULK_DELETE_UPDATE_KEY } from '../../PurchaseActionDropdown.constants';
import { LaunchBulkDeleteTicketGroup } from './LaunchBulkDeleteTicketGroup';

export const useBulkDeleteTicketGroup = (
  filterQuery: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const updateKey = groupId
    ? `deleteTicketGroups-event-${groupId}`
    : TICKET_GROUP_BULK_DELETE_UPDATE_KEY;

  const { showErrorDialog } = useErrorBoundaryContext();
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
    refreshCatalog,
  } = useCatalogDataContext();
  const { refreshMetrics } = useCatalogMetricsContext();

  const {
    openDialog: openBulkDeleteTicketGroupDialog,
    closeDialog: closeBulkDeleteTicketGroupDialog,
  } = useDialog(DialogId.BulkDeleteTicketGroup, BulkDeleteTicketGroupDialog);

  const onSubmit = useCallback(
    async (
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditTicketGroupClient(
              activeAccountWebClientConfig
            ).bulkDeleteTicketGroupPreview(filterQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkDeleteTicketGroupPreview', error, {
              trackErrorData: { filterQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditTicketGroupClient(
              activeAccountWebClientConfig
            ).bulkDeleteTicketGroup(
              preview!.preview,
              updateKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                await refreshData(true);
                // Metrics/events may have changed with TGs being cancelled, so refresh the whole catalog
                refreshMetrics();
                await refreshCatalog();
                await refreshExpandedListItems();
              }

              closeBulkDeleteTicketGroupDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkDeleteTicketGroup', error, {
              trackErrorData: { preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkDeleteTicketGroupDialog,
      filterQuery,
      refreshCatalog,
      refreshData,
      refreshExpandedListItems,
      refreshMetrics,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkDeleteTicketGroup
        key="BulkDeleteTicketGroup"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkDeleteTicketGroupDialog({
            updateKey,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkDeleteTicketGroupDialog,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
