import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { TicketTypePriorityInput } from 'src/components/common/TicketTypePriorityInput';
import { DeliveryTypeSelector } from 'src/components/Selectors/DeliveryTypeSelector';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreview,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  DeliveryType,
  ListingDetails,
  TicketTypeOverride,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkEditDeliveryTypeDialogProps = ComponentProps<typeof RSModal> & {
  updateKey: string;
  onOkay: (
    deliveryType: DeliveryType | null,
    ticketTypeRules: TicketTypeOverride | null,
    updateSalesOfListings: boolean,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails,
    retryableEntities?: BulkEditPreview
  ) => void;
  onCancel: () => void;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Purchase
    | ActionOutboxEntityType.TicketGroup;
  infoMessage?: React.ReactNode;
  showUpdateAssociatedSalesOption?: boolean;
};

const INIT_TICKET_TYPE_RULES = {
  overrides: [
    {
      deliveryType: DeliveryType.InApp,
      ticketTypePriorities: [],
    },
  ],
};

export function BulkEditDeliveryTypeDialog({
  updateKey,
  onOkay,
  onCancel,
  entityType,
  infoMessage,
  children,
  showUpdateAssociatedSalesOption = true,
  ...rest
}: BulkEditDeliveryTypeDialogProps) {
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshData } = usePurchaseDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const [isLoading, setIsLoading] = useState(false);
  const {
    setProgress,
    setPreview,
    setMainDialogOpened,
    retryableEntities,
    clearRetryStates,
  } = useBulkEditHubContext();

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (retryableEntities || newProgress?.step === BulkEditStep.Done) {
        if (retryableEntities) {
          clearRetryStates();
        }

        setIsRefreshing(true);
        setActivePosEntity(0);

        if (refreshData) {
          await refreshData(true);
        }
        if (refreshExpandedListItems) {
          await refreshExpandedListItems();
        }
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      clearRetryStates,
      onCancel,
      refreshData,
      refreshExpandedListItems,
      retryableEntities,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    entityType,
    BulkActionType.UpdateDeliveryType,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const [deliveryType, setDeliveryType] = useState<DeliveryType>(
    DeliveryType.InApp
  );
  const [ticketTypeRules, setTicketTypeRules] = useState<TicketTypeOverride>(
    INIT_TICKET_TYPE_RULES
  );
  const [updateSalesOfListings, setUpdateSalesOfListings] = useState(false);

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(
          null,
          null,
          updateSalesOfListings,
          false,
          onPreviewReceived,
          undefined,
          retryableEntities
        );
      } else {
        onOkay(
          deliveryType,
          ticketTypeRules,
          updateSalesOfListings,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [
      onOkay,
      updateSalesOfListings,
      retryableEntities,
      deliveryType,
      ticketTypeRules,
      hasBackgroundBulkEditFeature,
      preview,
    ]
  );

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.UpdateDeliveryType} />}
        />
      }
      size={stage === BulkEditStage.Preview ? 'xl' : 'lg'}
      {...rest}
      onOpened={() => {
        setDeliveryType(DeliveryType.InApp);
        setTicketTypeRules(INIT_TICKET_TYPE_RULES);
        setUpdateSalesOfListings(false);
        initJob();
      }}
      onKeyUp={(e) => {
        if (submittButtonRef.current && e.key === 'Enter') {
          submittButtonRef.current.click();
        }
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      onExit={() => {
        if (retryableEntities) {
          clearRetryStates();
        }
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={entityType}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={entityType}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack direction="column" gap="xxl" width="full">
          {infoMessage}
          <DeliveryTypeSelector
            disabled={isLoading}
            value={deliveryType}
            enableEmptySelection={false}
            onChange={(newD) => {
              if (newD && newD !== deliveryType) {
                setDeliveryType(newD);
              }
            }}
          />

          <TicketTypePriorityInput
            deliveryType={deliveryType}
            ticketTypeRules={ticketTypeRules}
            onTicketTypeRulesChange={(newRules) =>
              setTicketTypeRules(newRules || INIT_TICKET_TYPE_RULES)
            }
            disabled={isLoading}
          />

          {showUpdateAssociatedSalesOption && (
            <Checkbox
              labelPosition="right"
              checked={updateSalesOfListings}
              onChange={() => {
                setUpdateSalesOfListings((prev) => !prev);
              }}
              disabled={isLoading}
              label={<Content id={ContentId.UpdateAssociatedSales} />}
            />
          )}

          {children}
        </Stack>
      </BulkEditStatus>
    </GenericDialog>
  );
}
