export const splitIntoGroups = <T>(array: T[], itemsPerGroup: number) => {
  array = array.slice();
  const result = [];
  while (array.length) {
    result.push(array.splice(0, itemsPerGroup));
  }
  return result;
};

export const randomizeInPlace = (values: unknown[]) => {
  if (values.length <= 1) {
    return values;
  }
  if (values.length === 2) {
    // when there's only 2, guarantee to randomize by swapping
    [values[0], values[1]] = [values[1], values[0]];
    return values;
  }

  for (let i = values.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [values[i], values[j]] = [values[j], values[i]];
  }

  return values;
};

export const moveElements = <T>(
  array: T[],
  fromIndex: number,
  toIndex: number
): T[] => {
  // Ensure the indices are within array bounds
  if (
    fromIndex < 0 ||
    fromIndex >= array.length ||
    toIndex < 0 ||
    toIndex >= array.length
  ) {
    return array;
  }
  if (fromIndex === toIndex) {
    return array;
  }

  // Remove the element from the fromIndex position
  const element = array.splice(fromIndex, 1)[0];

  // Insert the element at the toIndex position
  array.splice(toIndex, 0, element);

  return [...array];
};

export const calculatePercentile = (
  values: number[],
  percentile: number
): number => {
  if (values.length === 0) {
    return 0;
  }

  // Calculate the rank (N * (p / 100)) - adjust for 0-based index
  const rank = (values.length * percentile) / 100.0 - 0.5;
  if (rank <= 0) {
    // If rank is less than or equal to 0, take the first element
    return values[0];
  }

  if (rank >= values.length - 1) {
    // If rank is greater than or equal to the last index, take the last element
    return values[values.length - 1];
  }

  // Interpolate between the two surrounding elements
  const lowerIndex = Math.floor(rank);
  const upperIndex = Math.ceil(rank);
  const fraction = rank - lowerIndex;
  const lowerValue = values[lowerIndex];
  const upperValue = values[upperIndex];

  const percentileValue = lowerValue + fraction * (upperValue - lowerValue);
  return percentileValue;
};
