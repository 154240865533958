import { useCallback } from 'react';
import { FilterPillList } from 'src/components/common/FilterPill/FilterPillList';
import { useStubHubListingManualPricingContext } from 'src/components/Listings/InventoryEventPage/MarketListings/StubHubListingsManualPricingContext';
import { SectionInfo } from 'src/WebApiController';
export const ManualSectionRowIdFilter = ({
  disabled,
}: {
  disabled?: boolean;
}) => {
  const {
    onSectionFilterChanged,
    onRowFiltersChanged,
    selectedSections,
    rowIdFilters,
    sectionRowIdFilters,
  } = useStubHubListingManualPricingContext();

  const selectedSectionChange = useCallback(
    (sectionId: number, selectedSections: SectionInfo[]) => {
      const section: SectionInfo | undefined = selectedSections.find(
        (s) => s.id === sectionId
      );

      if (section) {
        onSectionFilterChanged(section);
      }
    },
    [onSectionFilterChanged]
  );

  return (
    <div style={{ width: '100%', overflow: 'auto' }}>
      <FilterPillList
        disabled={disabled}
        selectedSections={selectedSections}
        rowIdFilters={rowIdFilters}
        sectionRowIdFilters={sectionRowIdFilters}
        applyRowFilterChanges={onRowFiltersChanged}
        applySelectedSectionChanges={selectedSectionChange}
      />
    </div>
  );
};
