import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ShareIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M5.847 12.185v8.311h12.929v-8.311h1.847v10.158H4V12.185h1.847zm6.464-9.842l5.225 5.224-1.306 1.306-2.996-2.995.001 9.077h-1.847V5.88L8.392 8.873 7.087 7.567l5.224-5.224z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
