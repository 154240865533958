import * as ts from 'typescript';

export const EDITABLE_FORMULA_EXAMPLE =
  '2 * (${ticketCount} - ${availableQuantity})';

/**
 * Formula string in a format directly editable and readable.
 * Consists of: Number literals, operators +-*()/, and identifiers in the form of "${FIELD_NAME}",
 * where FIELD_NAME can be any string
 *
 * Example: `${undercutAbsoluteAmount} * (${ticketCount} + 20 + ${random tag name}) + 0.04`
 */
export type FormulaEditable = string;

/**
 * Formula in an editable tokenized format.
 * Consists of: Number literals, operators +-*()/, and identifiers in the form of FIELD_NAME,
 * where FIELD_NAME can be any string
 *
 * Example: `['undercutAbsoluteAmount', '*', '(', 'ticketCount', '+', '20', '+', 'random tag name', ')', '+', '0.04']`
 */
export type FormulaTokenized = string[];

/**
 * Formula in executable format that is ready to evaluate.
 * Consists of: Number literals, operators +-*()/, and identifiers in the form of FORMULA_DATA_ACCESSOR_PREFIX['{FIELD_NAME}'],
 *
 * Example: `data?.undercutAbsoluteAmount * (data?.['ticketCount'] + 20) + 0.04 + data?.['random Tag']`
 */
export type FormulaExecutable = string;
// Changing this prefix will break the evaluation of existing formula stored in DB
const FORMULA_DATA_ACCESSOR_PREFIX = 'data?.';

/**
 * Converts executable formula string (from user settings) to editable formula
 * string (more readable and easy to parse/edit format)
 *
 * Example: `data?.['ticketCount'] + 20` ==> `${ticketCount} + 20`
 * Or (v1 for backward compatability): `data?.ticketCount + 20` ==> `${ticketCount} + 20`
 * @param executableFormula formula from user settings
 * @returns
 */
export const fromExecutableFormula = (
  executableFormula: FormulaExecutable | undefined
): FormulaEditable | undefined => {
  let editableFormula = '';
  const tokens = parseExecutableFormula(executableFormula);
  if (tokens == null) {
    return;
  }

  tokens.forEach((t) => {
    if (t.startsWith(FORMULA_DATA_ACCESSOR_PREFIX)) {
      const isTokenV1 = !t.includes('[') && !t.includes(']');
      const identifier = isTokenV1
        ? // data?.ticketCount => ${ticketCount}
          `$\{${t.replace(FORMULA_DATA_ACCESSOR_PREFIX, '')}}`
        : // data?.['ticketCount'] => ${ticketCount}
          `$\{${t.replace(FORMULA_DATA_ACCESSOR_PREFIX, '').slice(2, -2)}}`;
      editableFormula += identifier;
    } else {
      editableFormula += t;
    }
  });

  return editableFormula;
};

/**
 * Converts tokenized formula string (more readable and easy to parse/edit format)
 * to executable formula string that is ready for evaluation
 *
 * Example: `['ticketCount', '+', '20']` ==> `data?.['ticketCount'] + 20`
 * @param editableFormula formula being edited
 * @returns
 */
export const toExecutableFormula = (
  tokenizedFormula: FormulaTokenized
): FormulaExecutable => {
  return tokenizedFormula
    .map((token) =>
      FORMULA_OPERATORS_AND_NUMBERS_REGEXP_START.test(token)
        ? token
        : `${FORMULA_DATA_ACCESSOR_PREFIX}['${token}']`
    )
    .join(' ');
};

export const EDITABLE_FORMULA_ALLOWED_CHARSET_REGEXP = new RegExp(
  /^[0-9a-z_{}$+\-*/(). ]+$/i
);

export const FORMULA_DISPLAY_ALLOWED_INSERT_CHARS = [
  '@',
  '(',
  ')',
  '+',
  '-',
  '*',
  '/',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '.',
];

export const FORMULA_OPERATORS_AND_NUMBERS_REGEXP_START = /^[+\-*/()]|[0-9.]+/;
export const FORMULA_OPERATORS_AND_NUMBERS_REGEXP_ALL = /[+\-*/()]|[0-9.]+/g;

export const toTokenEditable = (fieldName: string) => {
  return `$\{${fieldName}}`;
};

const PLAIN_LITERAL_CHARSET_REGEXP = new RegExp(/^[0-9+\-*/(). ]+$/i);
/**
 * Parse executableFormula (in the form of FORMULA_DATA_ACCESSOR_PREFIX{FIELD_NAME}), transforming
 * formula into plain literals and identifier
 * Used to access identifiers and validation.
 *
 * E.g. `data?.ticketCount * (data?.AllInPrice + 3)` =>
 *
 * [
 *     'data?.ticketCount',
 *     ' * (',
 *     'data?.AllInPrice',
 *     ' + 3)'
 * ]
 *
 * @param executableFormula
 * @returns parsed list of tokens, or undefined if failed to parse
 */
const parseExecutableFormulaV1 = (
  executableFormula: FormulaExecutable | undefined
): string[] | undefined => {
  const tokens: string[] = [];
  if (!executableFormula) {
    return;
  }

  let executableFormulaSliced = executableFormula;
  while (executableFormulaSliced.includes(FORMULA_DATA_ACCESSOR_PREFIX)) {
    const pos = executableFormulaSliced.indexOf(FORMULA_DATA_ACCESSOR_PREFIX);

    // Plain token
    if (pos > 0) {
      tokens.push(executableFormulaSliced.slice(0, pos));
      if (!tokens[tokens.length - 1].match(PLAIN_LITERAL_CHARSET_REGEXP)) {
        return;
      }
    }

    let posEnd = pos + FORMULA_DATA_ACCESSOR_PREFIX.length;
    for (; posEnd < executableFormulaSliced.length; posEnd++) {
      if (
        !executableFormulaSliced
          .slice(pos + FORMULA_DATA_ACCESSOR_PREFIX.length, posEnd + 1)
          .match(/^[0-9a-z_.?]+$/i)
      ) {
        break;
      }
    }

    tokens.push(executableFormulaSliced.slice(pos, posEnd));

    executableFormulaSliced = executableFormulaSliced.slice(posEnd);
  }

  if (executableFormulaSliced.length > 0) {
    // Plain token
    tokens.push(executableFormulaSliced);
    if (!tokens[tokens.length - 1].match(PLAIN_LITERAL_CHARSET_REGEXP)) {
      return;
    }
  }

  return tokens;
};

/**
 * Parse executableFormula, transforming formula into plain literals and identifier
 * Used to access identifiers and validation.
 *
 * E.g. `data?.['ticketCount'] * (data?.['AllInPrice'] + 3)` =>
 *
 * [
 *     'data?.['ticketCount']',
 *     ' * (',
 *     'data?.['AllInPrice']',
 *     ' + 3)'
 * ]
 *
 * @param executableFormula
 * @returns parsed list of tokens, or undefined if failed to parse
 */
export const parseExecutableFormula = (
  executableFormula: FormulaExecutable | undefined
): string[] | undefined => {
  const tokens: string[] = [];
  if (!executableFormula) {
    return;
  }

  const isExecutableFormulaV1 =
    !executableFormula.includes('[') && !executableFormula.includes(']');
  if (isExecutableFormulaV1) {
    return parseExecutableFormulaV1(executableFormula);
  }

  let executableFormulaSliced = executableFormula;
  while (executableFormulaSliced.includes(FORMULA_DATA_ACCESSOR_PREFIX)) {
    const pos = executableFormulaSliced.indexOf(FORMULA_DATA_ACCESSOR_PREFIX);

    // Plain token
    if (pos > 0) {
      tokens.push(executableFormulaSliced.slice(0, pos));
      if (!tokens[tokens.length - 1].match(PLAIN_LITERAL_CHARSET_REGEXP)) {
        return;
      }
    }

    let posEnd = pos + FORMULA_DATA_ACCESSOR_PREFIX.length;
    for (; posEnd < executableFormulaSliced.length; posEnd++) {
      if (executableFormulaSliced.charAt(posEnd) === ']') {
        posEnd++;
        break;
      }
    }

    tokens.push(executableFormulaSliced.slice(pos, posEnd));

    executableFormulaSliced = executableFormulaSliced.slice(posEnd);
  }

  if (executableFormulaSliced.length > 0) {
    // Plain token
    tokens.push(executableFormulaSliced);
    if (!tokens[tokens.length - 1].match(PLAIN_LITERAL_CHARSET_REGEXP)) {
      return;
    }
  }

  return tokens;
};

const NULL_RETURN_STATEMENT_PLACEHOLDER = 'IDENTIFIER';
const NULL_RETURN_STATEMENT_TEMPLATE = `if (${NULL_RETURN_STATEMENT_PLACEHOLDER} == null) return null; `;
/**
 * Get early return statements from parsing formula, in order to return null if any
 * of identifiers is null.
 * This statement is needed otherwise the transpiled code from formula simply treats null values as 0
 *
 * E.g. `data?.['ticketCount'] * (data?.['AllInPrice'] + 3)` =>
 * "if (data?.['ticketCount'] == null) return null; if (data?.['AllInPrice'] == null) return null; "
 *
 * @param formula
 * @returns
 */
export const toNullReturnStatements = (formula: FormulaExecutable): string => {
  const tokens = parseExecutableFormula(formula);
  if (tokens == null) return '';

  let statements = '';
  tokens.forEach((t) => {
    if (t.startsWith(FORMULA_DATA_ACCESSOR_PREFIX)) {
      statements += NULL_RETURN_STATEMENT_TEMPLATE.replace(
        NULL_RETURN_STATEMENT_PLACEHOLDER,
        t
      );
    }
  });
  return statements;
};

export const filterExecutableFormulaCharacters = (
  formula: FormulaExecutable
) => {
  let filteredFormula = '';
  let isInsideString = false;
  for (let i = 0; i < formula.length; i++) {
    if (formula.charAt(i) === '[') {
      isInsideString = true;
    } else if (formula.charAt(i) === ']') {
      isInsideString = false;
      filteredFormula += formula.charAt(i);
    }
    if (isInsideString || formula.charAt(i).match(/^[0-9a-z_+\-*/().? ]+$/i)) {
      filteredFormula += formula.charAt(i);
    }
  }
  return filteredFormula;
};

/**
 * This is to prevent the case that input formula in some cases "6 data?.ticketCount + 20" will be
 * transpiled to multiple statements without any error:
 * "6 data?.ticketCount + 20" will become "return 6; data?.ticketCount + 20;"
 *
 * @param formula
 * @returns
 */
export const validateFormulaSingleStatement = (formula: string) => {
  const code = `({
    evaluate: (data: any): number | null => {
        return (${formula});
    }});`;

  const transpiledCode = ts.transpile(code).trim();
  return transpiledCode.split(';').filter((t) => t.length).length === 2;
};

/**
 * Result type for parseEditableFormulaBase
 */
type EditableFormulaParseToken = {
  /**
   * If `isPlainToken` is true, `token` will be plain formula literals
   * If `isPlainToken` is false, `token` will be field name that is type agnostic.
   * Depends on the caller to convert it to suitable type
   */
  token: string;
  isPlainToken: boolean;
};

/**
 * Parse editableFormula, transforming formula into plain literals and fieldnames
 * E.g. `${ticketCount} + 20` ==> [{token: 'ticketCount', isPlainToken: false}, {token: ' + 20', isPlainToken: true}]
 * @param editableFormula
 * @returns parsed list of tokens, or undefined if failed to parse
 */
export const parseEditableFormulaBase = (
  editableFormula: FormulaEditable | undefined,
  returnFieldNameOnly?: boolean
): EditableFormulaParseToken[] | undefined => {
  const parsedTokenResult: EditableFormulaParseToken[] = [];
  if (!editableFormula) {
    return;
  }

  let editableFormulaSliced = editableFormula;

  while (editableFormulaSliced.includes('${')) {
    const leftPos = editableFormulaSliced.indexOf('${');

    // Plain token
    if (leftPos > 0) {
      if (editableFormulaSliced.slice(0, leftPos).includes('}')) {
        return;
      }
      if (!returnFieldNameOnly) {
        parsedTokenResult.push({
          token: editableFormulaSliced.slice(0, leftPos),
          isPlainToken: true,
        });
      }
    }

    let rightPos = leftPos;
    for (; rightPos < editableFormulaSliced.length; rightPos++) {
      if (editableFormulaSliced.charAt(rightPos) === '}') {
        break;
      }
    }

    // Field name
    const fieldName = editableFormulaSliced.slice(leftPos + 2, rightPos);

    parsedTokenResult.push({
      token: fieldName,
      isPlainToken: false,
    });

    editableFormulaSliced = editableFormulaSliced.slice(rightPos + 1);
  }

  // Plain token
  if (editableFormulaSliced.length) {
    if (!returnFieldNameOnly) {
      parsedTokenResult.push({
        token: editableFormulaSliced,
        isPlainToken: true,
      });
    }
  }

  return parsedTokenResult;
};

type CustomColumnFormulaEvaluator = {
  evaluate: (data: {
    [key: string]: number | null | undefined;
  }) => number | null;
};

/**
 * Evaluate formula defined in custom listing column.
 *
 * Example formula: "data?.['ticketCount'] + 20"
 * @param formula
 * @param listingReportMetrics
 * @returns evaluated value, undefined on syntax error
 */
export function evaluateFormulaBase(
  formula: FormulaExecutable,
  data?: { [key: string]: number | null | undefined }
) {
  // Transpile and eval interpolated code can be dangerous if the code is uncontrolled
  // Filter out unwanted characters to make it more secure
  const filteredFormula = filterExecutableFormulaCharacters(formula);
  // If there's anything wrong just don't evaluate it
  if (filteredFormula !== formula) {
    return;
  }
  if (data == null) {
    return;
  }

  const nullReturnStatements = toNullReturnStatements(formula);
  const code = `({
    evaluate: (data: any): number | null => {
        ${nullReturnStatements}
        return (${formula});
    }})`;

  const transpiledCode = ts.transpile(code);

  let result: number | null | undefined = undefined;
  try {
    const evaluator: CustomColumnFormulaEvaluator = eval(transpiledCode);
    result = evaluator.evaluate(data);
  } catch (error) {
    // Empty - result would be undefined
  }
  return result;
}

export const tagKeyToFieldName = (tagKey: string) => {
  return `tags.${tagKey}`;
};

export const tagFieldNameToKey = (tagFieldName: string) => {
  return tagFieldName.replace('tags.', '');
};

export const editableFormulaToTokens = (
  formula: string | undefined,
  fieldOptionsReverse: Record<string, string>
) => {
  if (!formula) {
    return [];
  }

  const tokens = [];
  const metrics = Object.values(fieldOptionsReverse);

  while (formula.length > 0) {
    formula = formula.trim();
    let matchingToken = null;
    for (const metric of metrics) {
      // if (formula.startsWith(token)) {
      if (new RegExp('^\\$\\{' + metric + '\\}').test(formula)) {
        matchingToken = metric;
        break;
      }
    }

    if (matchingToken) {
      tokens.push(matchingToken);
      formula = formula.slice(matchingToken.length + 3);
    } else {
      const matches = formula.match(FORMULA_OPERATORS_AND_NUMBERS_REGEXP_START);
      if (matches) {
        tokens.push(matches[0]);
        formula = formula?.slice(matches[0].length);
      }
    }
  }
  return tokens;
};

/**
 * Find the added single character in the newValue that is not present in oldValue
 * @param oldValue
 * @param newValue
 * @returns
 */
export const findAddedSingleChar = (oldValue: string, newValue: string) => {
  if (newValue.length !== oldValue.length + 1) {
    return undefined;
  }

  for (let i = 0; i < newValue.length; i++) {
    if (oldValue[i] !== newValue[i]) {
      return newValue[i];
    }
  }
  return undefined;
};
