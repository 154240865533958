import { RowSelectionState, Updater } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo } from 'react';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { packNumericIds } from 'src/contexts/MultiSelectionContext/MultiSelectionContext.utils';
import { PurchaseOrderTableProps } from 'src/tables/PurchaseTable/PurchaseTable.types';
import {
  PurchaseOrderTicketGroup,
  PurchaseViewMode,
} from 'src/WebApiController';

const getRowIdForPurchase = (
  originalRow: PurchaseOrderTicketGroup | null,
  index: number
) =>
  (
    (originalRow?.isTktGrp ? originalRow.tgId : originalRow?.id) ?? index
  ).toString();

const getRowIdForTicketGroup = (
  originalRow: PurchaseOrderTicketGroup | null,
  index: number
) =>
  originalRow?.tgId
    ? packNumericIds([originalRow.id, originalRow.tgId])
    : index.toString();

export function usePurchaseTableSelection({
  data,
  groupId,
  viewMode,
  disableSelection,
  onItemSelectionsChanged,
}: Pick<
  PurchaseOrderTableProps,
  'groupId' | 'viewMode' | 'disableSelection' | 'onItemSelectionsChanged'
> & {
  data: (PurchaseOrderTicketGroup | null)[] | undefined;
}) {
  const {
    selectionMode,
    setGroupItems,
    getGroupToggleState,
    getSelection,
    totalItemsSelected,
  } = useMultiSelectionContext();
  const rowSelection = useMemo(() => {
    // Don't call "getSelection" outside hook, the result is not memoized
    // and it expensive to calculate.
    const groupSelection = getSelection(groupId);
    // Type casting needed because of a bug in older TS versions: https://github.com/microsoft/TypeScript/issues/44373
    return (data as (PurchaseOrderTicketGroup | null)[]).reduce<
      Record<number | string, boolean>
    >(
      (result, rowItem) => {
        if (viewMode === PurchaseViewMode.EventTileView) {
          if (rowItem?.tgId) {
            const rowId = getRowIdForTicketGroup(rowItem, -1);

            result[rowId] =
              getGroupToggleState(groupId).isGroupSelected &&
              !groupSelection.items.itemIds.length
                ? true
                : (groupSelection?.items.itemIds ?? [])?.includes(rowId) ??
                  false;
          }
        } else {
          if (rowItem?.id) {
            result[rowItem.id] =
              getGroupToggleState(groupId).isGroupSelected &&
              !groupSelection.items.itemIds.length
                ? true
                : (groupSelection?.items.itemIds ?? [])?.includes(
                    String(rowItem?.id)
                  ) ?? false;
          }
        }

        return result;
      },
      {} as Record<number | string, boolean>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalItemsSelected, // Keep it here to trigger the hook
    data,
    viewMode,
    getGroupToggleState,
    getSelection,
    groupId,
  ]);

  // Clear selections when selection mode changes
  useEffect(() => {
    onItemSelectionsChanged?.([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionMode?.mode]);

  const onRowSelectionChange = useCallback(
    (updater: Updater<RowSelectionState>) => {
      if (disableSelection) return;
      const newSelections =
        typeof updater === 'function' ? updater(rowSelection) : updater;

      const newSelectedIds = Object.keys(newSelections).filter(
        (r) => newSelections[r] === true
      );

      setGroupItems(groupId, newSelections);
      onItemSelectionsChanged?.(
        newSelectedIds
          .filter((id) => newSelections[id])
          .map((id) => parseInt(id))
      );
    },
    [
      disableSelection,
      groupId,
      onItemSelectionsChanged,
      rowSelection,
      setGroupItems,
    ]
  );

  const getRowId =
    viewMode === PurchaseViewMode.EventTileView
      ? getRowIdForTicketGroup
      : getRowIdForPurchase;

  return {
    getRowId,
    onRowSelectionChange,
    rowSelection,
    showCheckbox:
      selectionMode?.mode === MultiSelectScope.AllGroups ||
      selectionMode?.mode === MultiSelectScope.SingleGroup,
  };
}
