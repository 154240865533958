import { ReactNode } from 'react';

import * as styles from './HotkeyDisplay.css';

export type HotkeyDisplayProps = {
  children?: ReactNode;
};

export function HotkeyDisplay({ children }: HotkeyDisplayProps) {
  return <div className={styles.root}>{children}</div>;
}
