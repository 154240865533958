import { ReactNode } from 'react';

import * as styles from './EmptySectionContent.css';

type RootProps = {
  icon?: ReactNode;
  children?: ReactNode;
};

export function Root({ icon, children }: RootProps) {
  return (
    <div className={styles.root}>
      {icon && <div className={styles.iconWrapper}>{icon}</div>}
      {children}
    </div>
  );
}

type ErrorProps = {
  children?: ReactNode;
};

export function Error({ children }: ErrorProps) {
  return <div className={styles.errorMsg}>{children}</div>;
}

export function Label({ children }: ErrorProps) {
  return <div className={styles.labelMsg}>{children}</div>;
}

export function DetailMessage({ children }: ErrorProps) {
  return <div className={styles.detailMsg}>{children}</div>;
}

export function SolidIconContainer({ children }: ErrorProps) {
  return <div className={styles.solidIcon}>{children}</div>;
}
