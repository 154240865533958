import { useCallback, useState } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  ConfirmDialog,
  ConfirmDialogProps,
} from 'src/core/interim/dialogs/ConfirmDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SaleClient, SaleDetails } from 'src/WebApiController';

export type TurnOnSaleAutoFulfillDialogProps = Omit<
  ConfirmDialogProps,
  'headerText' | 'bodyText'
> & {
  saleId: number;
};

export const TurnOnSaleAutoFulfillDialog = ({
  saleId,
  onOkay,
  onCancel,
  ...props
}: TurnOnSaleAutoFulfillDialogProps) => {
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const { posEntity: sale, setActivePosEntity } =
    useActivePosEntityContext<SaleDetails>();

  const onTurnOnAutoFulfill = useCallback(async () => {
    setIsLoading(true);
    await tryInvokeApi(
      async () => {
        await new SaleClient(
          activeAccountWebClientConfig
        ).updateSaleIsNoFulfill({
          item1: saleId,
          item2: false,
        });
        setIsLoading(false);

        if (sale) {
          setActivePosEntity(sale.id, sale.idOnMkp, true);
        }

        onOkay();
      },
      (error) => {
        showErrorDialog('SaleClient.updateSaleIsNoFulfill', error, {
          trackErrorData: {
            saleId,
          },
        });
      },
      () => setIsLoading(false)
    );
  }, [
    activeAccountWebClientConfig,
    onOkay,
    sale,
    saleId,
    setActivePosEntity,
    showErrorDialog,
  ]);

  return (
    <ConfirmDialog
      headerText={<Content id={ContentId.AutoFulfill} />}
      bodyText={<Content id={ContentId.TurnOnAutoFulfillPrompt} />}
      onOkay={onTurnOnAutoFulfill}
      onCancel={onCancel}
      okText={ContentId.Yes}
      cancelText={ContentId.No}
      {...props}
      isLoading={isLoading}
    />
  );
};
