import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { EventConfigClient } from 'src/WebApiController';

export const useEventInternalNotes = (
  viagVirtualId?: string | null,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const queryClient = useQueryClient();
  const queryKey = [
    'EventConfigClient.getInternalNotesForEvent',
    viagVirtualId,
  ];

  const shouldQuery =
    !disabled &&
    Boolean(activeAccountWebClientConfig.activeAccountId && viagVirtualId);
  const internalNoteQuery = useQuery({
    queryKey,
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const internalNote = await new EventConfigClient(
        activeAccountWebClientConfig
      ).getInternalNotesForEvent(viagVirtualId!);

      return internalNote;
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('EventConfigClient.getInternalNotesForEvent', error, {
          viagVirtualId,
        });
      },
    },
  });

  const mutation = useMutation({
    mutationFn: async ({ internalNote }: { internalNote: string }) => {
      return await new EventConfigClient(
        activeAccountWebClientConfig
      ).updateInternalNotesForEvent(viagVirtualId ?? undefined, internalNote);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ internalNote }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, internalNote);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { internalNote }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('EventConfigClient.updateInternalNotesForEvent', err, {
        trackErrorData: { internalNote },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    internalNote: internalNoteQuery.data,
    isLoading: internalNoteQuery.isLoading,
    async updateInternalNote(internalNote: string | null) {
      await mutation.mutateAsync({ internalNote: internalNote ?? '' });
    },
  };
};
