import { endOfDay, startOfDay } from 'date-fns';
import { ComponentProps, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { FieldWrapper } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { toExperimentName } from 'src/utils/listingExperimentUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { ListingExperiment } from 'src/WebApiController';

export type ExperimentGroupItem = {
  id: string;
  name: string;
};

export type CreateExperimentDialogProps = ComponentProps<typeof RSModal> & {
  defaultValues: ListingExperiment;
  availableGroups: ExperimentGroupItem[];
  isLoading: boolean;
  isUpdate?: boolean;
  onCreateExperiment: (experiment: ListingExperiment) => Promise<void>;
  onUpdateExperiment: (experiment: ListingExperiment) => Promise<void>;
};

export const CreateExperimentDialog = ({
  defaultValues,
  ...rest
}: CreateExperimentDialogProps) => {
  const methods = useForm<ListingExperiment>({
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <CreateExperimentDialogContent
        defaultValues={defaultValues}
        {...rest}
        {...methods}
      />
    </FormProvider>
  );
};

function CreateExperimentDialogContent({
  defaultValues,
  availableGroups,
  isLoading,
  isUpdate = false,
  onCreateExperiment,
  onUpdateExperiment,
  onClosed,
  onCancel,
  disabled,
  control,
  formState,
  watch,
  clearErrors,
  setError,
  setValue,
  getValues,
  handleSubmit,
  reset,
  ...rest
}: CreateExperimentDialogProps &
  Omit<
    ComponentProps<typeof FormProvider<ListingExperiment, unknown>>,
    'children'
  >) {
  const onSubmitHandler = useCallback(async () => {
    if (isUpdate) {
      await handleSubmit(onUpdateExperiment)();
    } else {
      await handleSubmit(onCreateExperiment)();
    }
    onClosed && onClosed();
  }, [
    handleSubmit,
    isUpdate,
    onClosed,
    onCreateExperiment,
    onUpdateExperiment,
  ]);

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const discount = watch('discountPercentage');
  const listingGroupId = watch('listingGroupId');

  const listingGroupOptions = useMemo(() => {
    const options: Record<string, string> = {};
    for (const item of availableGroups) {
      options[item.id] = item.name;
    }
    return options;
  }, [availableGroups]);

  return (
    <>
      <GenericDialog
        {...rest}
        size="m"
        header={<Content id={ContentId.CreateANewTeam} />}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onSubmitHandler();
          }
        }}
        onOpened={() => {
          reset(defaultValues);
        }}
        footer={
          <Stack direction="row" gap="m">
            <Stack direction="row" gap="m">
              {onCancel && (
                <CancelButton
                  disabled={disabled || isLoading}
                  onClick={onCancel}
                />
              )}
              <Button
                disabled={disabled || isLoading}
                variant={'regular'}
                onClick={onSubmitHandler}
              >
                <Content id={ContentId.Save} />
              </Button>
            </Stack>
          </Stack>
        }
        onCancel={isLoading ? undefined : onCancel}
      >
        <Stack direction="column" gap="m">
          <FieldWrapper>
            <PosFormField label={<Content id={ContentId.ExperimentName} />}>
              <PosTextField
                value={toExperimentName(getValues())}
                readOnly
                disabled
              />
            </PosFormField>
          </FieldWrapper>
          <FieldWrapper>
            <PosFormField label={<Content id={ContentId.Group} />}>
              <PosSelect
                onChange={(key: string) => {
                  setValue('listingGroupId', key == 'N/A' ? null : key);
                }}
                value={listingGroupId ?? ''}
                displayText={listingGroupOptions[listingGroupId ?? ''] ?? 'N/A'}
                disabled={disabled || isLoading || isUpdate}
                style={{ width: '100%' }}
                searchable={Object.keys(listingGroupOptions).length > 10}
                valueOptionsContent={listingGroupOptions}
              />
            </PosFormField>
          </FieldWrapper>

          <FieldWrapper>
            <PosFormField label={<Content id={ContentId.Discount} />}>
              <PosTextField
                type="number"
                value={discount + ''}
                onChange={(event) => {
                  const value = parseInt(event.target.value ?? '0');
                  if (!isNaN(value)) {
                    setValue('discountPercentage', value);
                  }
                }}
                spellCheck={false}
                trimOnBlur={true}
                postfixDisplay={'%'}
                disabled={isUpdate}
              />
            </PosFormField>
          </FieldWrapper>
          <FieldWrapper>
            <PosFormField label={<Content id={ContentId.StartDateTime} />}>
              <DatePickerInput
                fieldError={formState.errors.startDate?.message}
                date={startDate ? startOfDay(new Date(startDate)) : undefined}
                minDate={startOfDay(new Date())}
                onDateChange={(date: Date) => {
                  if (date) {
                    clearErrors('startDate');
                    const dateStr = startOfDay(date).toISOString();
                    if (dateStr !== startDate) {
                      setValue('startDate', dateStr);
                    }
                  }
                }}
                locale={getLocaleFromLanguageOrCurrent()}
                disabled={isUpdate}
              />
            </PosFormField>
          </FieldWrapper>
          <FieldWrapper>
            <PosFormField label={<Content id={ContentId.EndDateTime} />}>
              <DatePickerInput
                fieldError={formState.errors.endDate?.message}
                minDate={new Date(startDate)}
                date={endDate ? new Date(endDate) : undefined}
                onDateChange={(date: Date) => {
                  if (date) {
                    clearErrors('endDate');
                    const dateStr = endOfDay(date).toISOString();
                    if (dateStr !== endDate) {
                      setValue('endDate', dateStr);
                    }
                  }
                }}
                locale={getLocaleFromLanguageOrCurrent()}
              />
            </PosFormField>
          </FieldWrapper>
        </Stack>
      </GenericDialog>
    </>
  );
}
