import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const SaleCancelledIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 21 23">
      <path
        d="M3.00007 16.8C1.90007 14.8 1.10008 13 1.10008 13C0.800078 12.5 0.800069 11.9 1.20007 11.5C1.60007 11.1 2.40008 11 2.80008 11.4L5.50007 14.5V4.5C5.50007 3.7 6.20007 3 7.00007 3C7.80007 3 8.50007 3.7 8.50007 4.5V8.19995"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5002 7.5V17.5C17.5002 20.3 15.3002 22.5 12.5002 22.5H9.2002C7.6002 22.5 6.2002 21.8 5.2002 20.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 10V2.5C8.5 1.7 9.2 1 10 1C10.8 1 11.5 1.7 11.5 2.5V8"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 8V4.5C11.5 3.7 12.2 3 13 3C13.8 3 14.5 3.7 14.5 4.5V5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5 2L1 21.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
