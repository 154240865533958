import { useEffect, useMemo, useRef } from 'react';
import { MinRowsForUsingVirtuoso } from 'src/components/Accordions';
import {
  PosEventsAccordionBodyHeight,
  useSetEventsAccordionMaxHeight,
} from 'src/components/Accordions/useSetEventsAccordionMaxHeight';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { PurchasesBulkActionsPermissions } from 'src/components/Purchases/BulkActions/PurchasesBulkActionsPermissions';
import { PurchasesTileBulkActions } from 'src/components/Purchases/BulkActions/PurchasesTileBulkActions/PurchasesTileBulkActions';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import * as PosCollapsible from 'src/core/POS/PosCollapsible';
import { TimePeriodContainingPurchaseOrderResult } from 'src/WebApiController';

import * as eventStyles from '../EventsAccordion/EventsAccordion.css';
import * as styles from './PurchaseDateAccordion.css';
import { PurchaseDateItemBody } from './PurchaseDateItemBody';
import { PurchaseDateItemHeader } from './PurchaseDateItemHeader';

export type PurchaseDateAccordionMonthGroupItemProps = {
  item: TimePeriodContainingPurchaseOrderResult;
  selectedIds?: string[];
  onSelect: (id: string) => void;
};

export const PurchaseDateAccordionMonthGroupItem = ({
  item,
  selectedIds,
  onSelect,
}: PurchaseDateAccordionMonthGroupItemProps) => {
  const accordionBodyRef = useRef<HTMLDivElement | null>(null);
  const accordionHeaderRef = useRef<HTMLDivElement | null>(null);
  const { getGroupToggleState, selectionMode, toggleGroup, setSelectionMode } =
    useMultiSelectionContext();
  const groupId = item.firstOfTimePeriod;
  const isSelected = getGroupToggleState(groupId).isGroupSelected;

  const isAccordionOpen = useMemo(
    () => !!selectedIds?.includes(item.firstOfTimePeriod),
    [item.firstOfTimePeriod, selectedIds]
  );

  useSetEventsAccordionMaxHeight({
    enabled: item.purchaseCount > MinRowsForUsingVirtuoso,
    isExpanded: isAccordionOpen,
    accordionBodyRef,
    accordionHeaderRef,
  });

  useEffect(() => {
    if (
      !isAccordionOpen &&
      selectionMode &&
      selectionMode.groupId === item.firstOfTimePeriod &&
      selectionMode.mode === MultiSelectScope.SingleGroup
    ) {
      setSelectionMode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccordionOpen, item.firstOfTimePeriod, selectionMode]);

  return (
    <div className={eventStyles.item}>
      {selectionMode?.mode === MultiSelectScope.AllGroups && (
        <div className={styles.checkbox}>
          <Checkbox
            checked={isSelected}
            onChange={() => toggleGroup(groupId)}
          />
        </div>
      )}
      <PosCollapsible.Root
        open={isAccordionOpen}
        onOpenChange={() => {
          onSelect(item.firstOfTimePeriod);
        }}
      >
        <div ref={accordionHeaderRef}>
          <PosCollapsible.Trigger>
            <div className={eventStyles.itemHeader}>
              <PurchaseDateItemHeader
                {...item}
                onToggleMultiSelect={(groupId) => {
                  if (selectedIds && !selectedIds.includes(groupId)) {
                    onSelect(groupId);
                  }
                }}
              />
            </div>
          </PosCollapsible.Trigger>
          <PurchasesBulkActionsPermissions>
            <MultiSelectActionBar groupId={item.firstOfTimePeriod}>
              <PurchasesTileBulkActions groupId={item.firstOfTimePeriod} />
            </MultiSelectActionBar>
          </PurchasesBulkActionsPermissions>
        </div>
        <PosCollapsible.Content>
          <div
            style={{ height: `var(${PosEventsAccordionBodyHeight},100%)` }}
            ref={accordionBodyRef}
          >
            <PurchaseDateItemBody
              {...item}
              isExpanded={!!selectedIds?.includes(item.firstOfTimePeriod)}
            />
          </div>
        </PosCollapsible.Content>
      </PosCollapsible.Root>
    </div>
  );
};
