import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

type PanelContentType = 'comparables' | 'sectionSelection' | 'none';

type ListingDetailsModalSidePanelContextType = {
  contentType: PanelContentType;
  onCloseSidePanel: () => void;
  onShowContent: (
    newContentType: PanelContentType,
    e?: React.MouseEvent<HTMLButtonElement>
  ) => void;
};

const ListingDetailsModalSidePanelContext =
  createContext<ListingDetailsModalSidePanelContextType>({
    contentType: 'none',
    onCloseSidePanel: () => {},
    onShowContent: () => {},
  });

type ListingDetailsModalSidePanelContextProviderProps = {
  children: ReactNode;
};

export const ListingDetailsModalSidePanelContextProvider = ({
  children,
}: ListingDetailsModalSidePanelContextProviderProps) => {
  const [contentType, setContentType] = useState<PanelContentType>('none');

  const onCloseSidePanel = useCallback(() => {
    if (contentType !== 'none') {
      setContentType('none');
    }
  }, [contentType]);

  const onShowContent = useCallback(
    (
      newContentType: PanelContentType,
      e?: React.MouseEvent<HTMLButtonElement>
    ) => {
      e?.stopPropagation();
      e?.preventDefault();

      if (contentType !== newContentType) {
        setContentType(newContentType);
      } else if (contentType !== 'none') {
        setContentType('none');
      }
    },
    [contentType]
  );

  return (
    <ListingDetailsModalSidePanelContext.Provider
      value={{
        contentType,
        onShowContent,
        onCloseSidePanel,
      }}
    >
      {children}
    </ListingDetailsModalSidePanelContext.Provider>
  );
};

export const useListingDetailsModalSidePanelContext = () =>
  useContext(ListingDetailsModalSidePanelContext);
