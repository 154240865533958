﻿import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { ContentId } from 'src/utils/constants/contentId';
import styled from 'styled-components/macro';

const GeneralAdmissionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: ${() => `${vars.spacing.sm} ${vars.spacing.lg}`};
`;

const GeneralAdmissionHeader = styled.div`
  color: ${(props) => props.theme.colors.textBrand};
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title6')};
`;
const GeneralAdmissionText = styled.div`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('body2')};
`;

const GeneralAdmissionMessage = () => {
  return (
    <GeneralAdmissionContainer>
      <GeneralAdmissionHeader>
        <Content id={ContentId.GeneralAdmissionEvent} />
      </GeneralAdmissionHeader>
      <GeneralAdmissionText>
        <Content id={ContentId.GeneralAdmissionMessage} />
      </GeneralAdmissionText>
    </GeneralAdmissionContainer>
  );
};

export { GeneralAdmissionMessage };
