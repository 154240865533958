import { useCallback, useMemo, useState } from 'react';
import { ConfirmButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import {
  Alert,
  AlertWithSuppressionDialog,
} from 'src/dialogs/AlertWithSuppressionDialog';
import { useMergeGroupedListingDialog } from 'src/dialogs/AlertWithSuppressionDialog/useMergeGroupedListingDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ListingTable } from 'src/tables/ListingTable';
import { ContentId } from 'src/utils/constants/contentId';
import { isDatePassedHours } from 'src/utils/dateTimeUtils';
import { EventWithData, Feature } from 'src/WebApiController';

import * as styles from './MergeListings.css';
import { GroupIdNameMap } from './utils';

export type MergeListingsSuggestionsDisplayProps = EventWithData & {
  listingIds: number[];
  disabled?: boolean;
  onMergeSuggestions: (listingIds: number[]) => void;
  groupIdNameMap?: GroupIdNameMap;
};

export const MergeListingsSuggestionsDisplay = ({
  listingIds,
  listings,
  disabled,
  onMergeSuggestions: onMerge,
  groupIdNameMap,
  ...rest
}: MergeListingsSuggestionsDisplayProps) => {
  const listingsSelected = useMemo(() => {
    return listings?.filter((l) => listingIds.includes(l.id)) ?? [];
  }, [listingIds, listings]);

  const hasMergeGroupedListingFeature = useUserHasFeature(
    Feature.MergeListingsThatBelongsToGroups
  );
  const warningDialog = useBasicDialog();
  const mergeSuggestionsGroupListingHookProps = useMergeGroupedListingDialog(
    () => {},
    () => {}
  );

  const [alerts, setAlerts] = useState<Alert[]>([]);

  const onMergeHandler = useCallback(() => {
    const hasListingFromGroup = listingIds.some((listingId) => {
      return listings?.find((l) => l.id === listingId)?.ltGrpId;
    });

    if (
      hasListingFromGroup &&
      isDatePassedHours(mergeSuggestionsGroupListingHookProps.lastTimeStamp, 1)
    ) {
      setAlerts([mergeSuggestionsGroupListingHookProps]);
      warningDialog.launchDialog();
    } else {
      onMerge(listingIds);
    }
  }, [
    listingIds,
    listings,
    mergeSuggestionsGroupListingHookProps,
    onMerge,
    warningDialog,
  ]);

  return (
    <>
      <div className={styles.mergeListingsSuggestionContainer}>
        <div className={styles.mergeListingSugggestionsTableContainer}>
          <ListingTable
            {...rest}
            listings={listingsSelected}
            listCnt={listingsSelected.length}
            showMainColumnsOnly
            listingGroupMap={groupIdNameMap}
          />
        </div>
        <ConfirmButton
          onClick={() => {
            hasMergeGroupedListingFeature
              ? onMergeHandler()
              : onMerge(listingIds);
          }}
          disabled={disabled}
          textContentId={ContentId.MergeSuggested}
        />
      </div>
      <AlertWithSuppressionDialog
        headerText={<Content id={ContentId.MergeListings} />}
        size="md"
        {...warningDialog.dialogProps}
        alerts={alerts}
        onOkay={() => {
          onMerge(listingIds);
          warningDialog.closeDialog();
        }}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
        onCancel={() => warningDialog.closeDialog()}
      />
    </>
  );
};
