import { ComponentProps, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './AddSectionGroupDialog.css';

type FormValues = {
  zone: string | undefined;
  area: string | undefined;
};

export type AddSectionGroupDialogProps = ComponentProps<typeof RSModal> & {
  onOkay: (zoneName: string, sectionGroupName: string) => void;
  onCancel: () => void;
};

export function AddSectionGroupDialog({
  onOkay,
  onCancel,
  ...rest
}: AddSectionGroupDialogProps) {
  const [formValues, setFormValues] = useState<FormValues>({
    zone: undefined,
    area: undefined,
  });

  return (
    <>
      <GenericDialog
        {...rest}
        size="m"
        header={
          <>
            <Content id={ContentId.AddSection} />{' '}
            <Content id={ContentId.Group} />
          </>
        }
        footer={
          <>
            <Button className={styles.cancelButton} onClick={onCancel}>
              <Content id={ContentId.Cancel} />
            </Button>
            <Button
              onClick={() => {
                onOkay(formValues.zone ?? '', formValues.area ?? '');
                setFormValues({ zone: undefined, area: undefined });
              }}
            >
              <Content id={ContentId.Save} />
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        <Stack direction="column" gap="xl">
          <PosFormField label={<Content id={ContentId.Zone} />}>
            <PosTextField
              type="text"
              value={formValues.zone ?? ''}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/:/g, '');
                setFormValues((prev) => ({
                  ...prev,
                  zone: sanitizedValue,
                }));
              }}
            />
          </PosFormField>
          <PosFormField label={<Content id={ContentId.Area} />}>
            <PosTextField
              type="text"
              value={formValues.area ?? ''}
              onChange={(e) => {
                const sanitizedValue = e.target.value.replace(/:/g, '');
                setFormValues((prev) => ({
                  ...prev,
                  area: sanitizedValue,
                }));
              }}
            />
          </PosFormField>
        </Stack>
      </GenericDialog>
    </>
  );
}
