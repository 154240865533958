import { ComponentProps } from 'react';
import { vars } from 'src/core/themes';
import { IconsFill, ResetIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { ButtonWithIcon } from './ButtonWithIcon';

export const DeallocateButton = ({
  textContentId,
  iconOnlyMode,
  variant,
  icon,
  ...props
}: ComponentProps<typeof ButtonWithIcon>) => {
  return (
    <ButtonWithIcon
      {...props}
      icon={
        icon ?? (
          <ResetIcon
            withHoverEffect
            fill={
              iconOnlyMode || (variant && variant !== 'regular')
                ? IconsFill.textWarning
                : IconsFill.textInverted
            }
            size={vars.iconSize.m}
          />
        )
      }
      iconOnlyMode={iconOnlyMode}
      variant={variant}
      textContentId={textContentId || ContentId.DeallocateSale}
    />
  );
};
