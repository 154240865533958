import { useMemo } from 'react';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import {
  getMatchingSectionRow,
  getSectionsSameZone,
} from 'src/utils/seatScoreUtils';
import { AutoPricingCompListingMode, Listing } from 'src/WebApiController';

/**
 * Given comp listing settings and event map, returns the disabled section ids which should not be selectable
 * when restricting selection to sections (compListingOnlyForSelectedSectionsEnabled)
 * @param undercutAbsoluteAmount
 * @param undercutRelativeAmount
 * @returns
 */
export const useCompListingDisabledSections = (
  listing: Listing | null | undefined,
  isBulkEdit: boolean | undefined,
  compListingMode: AutoPricingCompListingMode | null,
  compListingFloor: number | null,
  compListingCeiling: number | null
) => {
  const { venueMapInfo } = useEventMapContext();

  const disabledSectionIds = useMemo(() => {
    if (!venueMapInfo || !compListingMode || !listing || isBulkEdit) return [];

    if (compListingMode === AutoPricingCompListingMode.SameZone) {
      const { ticketClassId } = getMatchingSectionRow(
        listing.seating,
        venueMapInfo.sections
      );

      if (ticketClassId == null) return [];

      const sectionsSameZone = getSectionsSameZone(
        listing.seating,
        venueMapInfo.sections
      );

      return venueMapInfo.sections
        .filter((s) => !sectionsSameZone?.some((ss) => ss.id === s.id))
        .map((s) => s.id);
    } else if (compListingMode === AutoPricingCompListingMode.QualityScore) {
      // section must have at least one row with score within slider range
      const { row } = getMatchingSectionRow(
        listing.seating,
        venueMapInfo.sections
      );
      const rowScore = row?.seatQualityScore;

      if (
        rowScore == null ||
        compListingFloor == null ||
        compListingCeiling == null
      )
        return [];

      const rowScoreFloor = rowScore * compListingFloor;
      const rowScoreCeiling = rowScore * compListingCeiling;

      return venueMapInfo.sections
        .filter((s) => {
          const hasRowWithinRange = s.rows.some(
            (r) =>
              r.seatQualityScore != null &&
              r.seatQualityScore >= rowScoreFloor &&
              r.seatQualityScore <= rowScoreCeiling
          );
          return !hasRowWithinRange;
        })
        .map((s) => s.id);
    }

    return [];
  }, [
    compListingCeiling,
    compListingFloor,
    compListingMode,
    isBulkEdit,
    listing,
    venueMapInfo,
  ]);

  return {
    disabledSectionIds,
  };
};
