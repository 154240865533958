import { UseFormSetError } from 'react-hook-form';
import { IErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { isUniqueWithin } from 'src/utils/validation';
import {
  PosClientConfig,
  SaleClient,
  TicketExternalId,
} from 'src/WebApiController';

export type ExternalIdForm = {
  isBulkUpload: boolean;
  externalIds: TicketExternalId[];
};

export const checkDuplicateExternalIds = async (
  entityId: number,
  externalIds: TicketExternalId[],
  activeAccountWebClientConfig: PosClientConfig,
  alreadyReportedUsedExternalIds: string[],
  setAlreadyReportedUsedExternalIds: (externalIds: string[]) => void,
  onDuplicateExternalId: (index: number) => void,
  onBeforeCheck?: () => void,
  onAfterCheck?: () => void,
  showErrorDialog?: IErrorBoundaryContext['showErrorDialog'],
  viagVirtualId?: string
): Promise<boolean | null> => {
  return tryInvokeApi(
    async () => {
      onBeforeCheck?.();

      if (!externalIds) {
        return false;
      }

      const existingExternalIds = await new SaleClient(
        activeAccountWebClientConfig
      ).getTicketExternalIdsAlreadyInUse(viagVirtualId, externalIds);

      if (existingExternalIds) {
        // Get all the used externalIds that have not yet been reported
        const unreportedBarCodes = existingExternalIds.filter(
          (b) => !alreadyReportedUsedExternalIds.includes(b.externalId)
        );
        if (unreportedBarCodes.length > 0) {
          // We're reporting the dup warnings, so save it so we don't report on it again
          // We want to set all of it (not just the latest unreported externalIds)
          setAlreadyReportedUsedExternalIds(
            existingExternalIds.map((tb) => tb.externalId)
          );

          let hasErrors = false;
          existingExternalIds.forEach((tb) => {
            const index = externalIds.findIndex(
              (b) => b.ticketId === tb.ticketId
            );

            if (index >= 0) {
              hasErrors = true;
              onDuplicateExternalId(index);
            }
          });

          return hasErrors;
        }
      }

      return false;
    },
    (error) => {
      showErrorDialog?.('SaleClient.getExternalIdsAlreadyInUse', error, {
        trackErrorData: {
          saleId: entityId,
          externalIds,
        },
      });
    },
    () => onAfterCheck?.()
  );
};

export const checkExternalIdErrors = (
  isBulkUpload: boolean,
  externalIds: TicketExternalId[],
  ticketCnt: number,
  setError: UseFormSetError<ExternalIdForm>,
  requiredMsg: string,
  missingMsg: string,
  extraMsg: string,
  duplicateMsg: string
) => {
  let hasErrors = false;
  const externalIdsProvided =
    externalIds?.map((b) => b.externalId).filter((b) => b?.length) ?? [];
  if (isBulkUpload) {
    const externalIdsProvided =
      externalIds?.map((b) => b.externalId).filter((b) => b?.length) ?? [];
    if (externalIdsProvided.length < ticketCnt) {
      hasErrors = true;
      setError(`externalIds`, { message: missingMsg as string }); // for the bulk-edit inputs
    } else if (externalIdsProvided.length > ticketCnt) {
      hasErrors = true;
      setError(`externalIds`, { message: extraMsg as string }); // for the bulk-edit inputs
    }
  }

  externalIds?.forEach((b, i) => {
    if (!b.externalId) {
      hasErrors = true;
      setError(`externalIds.${i}.externalId`, { message: requiredMsg });
    } else if (!isUniqueWithin(b.externalId, externalIdsProvided, true)) {
      hasErrors = true;
      if (isBulkUpload) {
        setError(`externalIds`, { message: duplicateMsg }); // for the bulk-edit inputs
      }
      setError(`externalIds.${i}.externalId`, { message: duplicateMsg });
    }
  });
  return hasErrors;
};
