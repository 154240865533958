import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';

interface BulkActionsState {
  areBulkActionsEnabled: boolean;
}

export const useBulkActionsState = (): BulkActionsState => {
  const { totalItemsSelected } = useMultiSelectionContext();

  return {
    areBulkActionsEnabled: totalItemsSelected > 0,
  };
};
