import { ComponentProps, useCallback, useContext } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { GroupListingsAutoCompSettings } from 'src/modals/GroupListingsAutoGroupAndCompV2';
import { ContentId } from 'src/utils/constants/contentId';
import { AutoCompAndAutoGroupMode, Event } from 'src/WebApiController';

export const LaunchAutoCompSettings = ({
  event,
  disabled,
}: {
  event?: Event;
  disabled?: boolean;
} & Omit<
  ComponentProps<typeof GroupListingsAutoCompSettings>,
  'skipAutoGroup'
>) => {
  const { setModal } = useContext(ModalContext);
  const {
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();

  const openAutoCompSettings = useCallback(() => {
    setModal({
      children: (
        <GroupListingsAutoCompSettings
          event={event}
          mode={AutoCompAndAutoGroupMode.AutoComp}
        />
      ),
      clickOutsideToClose: true,
      size: 'slide-in-lg',
    });
  }, [setModal, event]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (event) {
        setListItemExpansion(true, [event.viagVirtualId]);
      }

      openAutoCompSettings();
    },
    [event, openAutoCompSettings, setListItemExpansion]
  );

  return (
    <PosDropdownItem
      key="AutoCompSettings"
      onClick={onLaunchDialog}
      disabled={disabled}
    >
      <Stack alignItems="center" gap="m" width="full">
        <Content id={ContentId.AutoComp} /> <Content id={ContentId.Listings} />
      </Stack>
    </PosDropdownItem>
  );
};
