import { TableOptions } from '@tanstack/react-table';
import { useMemo } from 'react';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Stack } from 'src/core/ui/Stack';
import { ContentId } from 'src/utils/constants/contentId';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { hasChanged } from 'src/utils/posFieldUtils';
import { ListingDetailsAutoPricingSectionUpdatesV2 } from 'src/WebApiController';

import {
  AutoPricingSettingsInput,
  PricingSettingsUpdateKey,
} from '../BulkEditAutoPricingSettingsDialog.types';
import { PricingSettingsPreviewColumns } from './PricingSettingsPreview.constants';
import { PreviewColumn } from './PricingSettingsPreview.types';

export const usePricingSettingsPreviewTable = (
  settingsInput: AutoPricingSettingsInput,
  defaultValues: ListingDetailsAutoPricingSectionUpdatesV2,
  updates: ListingDetailsAutoPricingSectionUpdatesV2,
  updateChangedFields: (
    values: Partial<Record<PricingSettingsUpdateKey, boolean | undefined>>
  ) => void,
  changedFields?: Partial<Record<PricingSettingsUpdateKey, boolean | undefined>>
): Partial<TableOptions<PreviewColumn>> => {
  const conflictText = useContent(ContentId.Conflict);
  const existingSectionsText = useContent(ContentId.ExistingSections);
  const overrideText = useContent(ContentId.Override);
  const naText = useContent(ContentId.NA);

  const { venueMapInfo } = useEventMapContext();

  const sectionIdToName = useMemo(() => {
    return (venueMapInfo?.sections ?? []).reduce(
      (res, section) => {
        res[section.id] = section.name;
        return res;
      },
      {} as Record<number, string>
    );
  }, [venueMapInfo?.sections]);

  const data = useMemo((): PreviewColumn[] => {
    const settings: PreviewColumn[] = [];
    if (
      hasChanged(updates?.autoPricingEnabled, defaultValues.autoPricingEnabled)
    ) {
      settings.push({
        setting: ContentId.EnableAutoPricing,
        from: settingsInput.autoPricingEnabled?.hasConflict
          ? conflictText
          : defaultValues.autoPricingEnabled?.value + '',
        to: updates.autoPricingEnabled?.value + '',
        include: !!changedFields?.autoPricingEnabled,
        onChange: (include: boolean) => {
          updateChangedFields({
            autoPricingEnabled: include,
          });
        },
      });
    }

    if (hasChanged(updates?.compListingMode, defaultValues.compListingMode)) {
      settings.push({
        setting: ContentId.AllListings,
        from: settingsInput.compListingMode?.hasConflict
          ? conflictText
          : defaultValues.compListingMode?.value,
        to: updates.compListingMode?.value,
        include: !!changedFields?.compListingMode,
        onChange: (include: boolean) => {
          updateChangedFields({
            compListingMode: include,
          });
        },
      });
    }

    if (
      hasChanged(
        updates?.compListingSelectedSectionSettings,
        defaultValues.compListingSelectedSectionSettings
      ) ||
      (settingsInput.compListingSelectedSectionSettings?.hasConflict &&
        !updates.includeExistingSelectedSections)
    ) {
      const defaultSelectedSections = (
        defaultValues.compListingSelectedSectionSettings?.value
          ?.sectionIdFilter ?? []
      ).map((id) => sectionIdToName[id]);
      const updatedSectionedSections = (
        updates.compListingSelectedSectionSettings?.value?.sectionIdFilter ?? []
      ).map((id) => sectionIdToName[id]);
      settings.push({
        setting: ContentId.Sections,
        from: settingsInput.compListingSelectedSectionSettings?.hasConflict
          ? conflictText
          : defaultSelectedSections.join(','),
        to: `${
          updates.includeExistingSelectedSections
            ? existingSectionsText + ', '
            : overrideText
        } ${updatedSectionedSections.join(',')}`,
        include: !!changedFields?.compListingSelectedSectionSettings,
        onChange: (include: boolean) => {
          updateChangedFields({
            compListingSelectedSectionSettings: include,
          });
        },
      });
    }
    if (
      hasChanged(
        updates?.compListingQuantityScoreAdjustmentOverrideJson,
        defaultValues.compListingQuantityScoreAdjustmentOverrideJson
      )
    ) {
      settings.push({
        setting: ContentId.SplitType,
        from: settingsInput.compListingQuantityScoreAdjustmentOverrideJson
          ?.hasConflict
          ? conflictText
          : defaultValues.compListingQuantityScoreAdjustmentOverrideJson
              ?.value + '',
        to: updates.compListingQuantityScoreAdjustmentOverrideJson?.value + '',
        include:
          !!changedFields?.compListingQuantityScoreAdjustmentOverrideJson,
        onChange: (include: boolean) => {
          updateChangedFields({
            compListingQuantityScoreAdjustmentOverrideJson: include,
          });
        },
      });
    }
    if (
      hasChanged(
        updates?.undercutAbsoluteAmount,
        defaultValues.undercutAbsoluteAmount
      ) ||
      hasChanged(
        updates?.undercutRelativeAmount,
        defaultValues.undercutRelativeAmount
      )
    ) {
      settings.push({
        setting: ContentId.UnderCutModePrompt,
        from:
          settingsInput.undercutAbsoluteAmount?.hasConflict ||
          settingsInput.undercutRelativeAmount?.hasConflict ? (
            conflictText
          ) : (
            <Stack direction="row" gap="s">
              <Content id={ContentId.PriceBy} />
              <div>
                {defaultValues.undercutRelativeAmount?.value
                  ? applyNumberFormatting({
                      inputNumber: defaultValues.undercutRelativeAmount.value,
                      isPercent: true,
                    })
                  : naText}
              </div>
              <Content id={ContentId.ThenBy} />
              <div>
                {defaultValues.undercutAbsoluteAmount?.value
                  ? applyNumberFormatting({
                      inputNumber: defaultValues.undercutAbsoluteAmount.value,
                      currencyCode: defaultValues.currencyCode,
                    })
                  : naText}
              </div>
            </Stack>
          ),
        to: (
          <Stack direction="row" gap="s">
            <Content id={ContentId.PriceBy} />
            <div>
              {updates.undercutRelativeAmount?.value
                ? applyNumberFormatting({
                    inputNumber: updates.undercutRelativeAmount.value,
                    isPercent: true,
                  })
                : naText}
            </div>
            <Content id={ContentId.ThenBy} />
            <div>
              {updates.undercutAbsoluteAmount?.value
                ? applyNumberFormatting({
                    inputNumber: updates.undercutAbsoluteAmount.value,
                    currencyCode: updates.currencyCode,
                  })
                : naText}
            </div>
          </Stack>
        ),
        include:
          !!changedFields?.undercutAbsoluteAmount ||
          !!changedFields?.undercutRelativeAmount,
        onChange: (include: boolean) => {
          updateChangedFields({
            undercutAbsoluteAmount: include,
            undercutRelativeAmount: include,
          });
        },
      });
    }
    if (
      hasChanged(updates?.netProceedsFloor, defaultValues.netProceedsFloor) ||
      hasChanged(updates?.netProceedsCeiling, defaultValues.netProceedsCeiling)
    ) {
      settings.push({
        setting: ContentId.PriceBelowFloor,
        from:
          settingsInput.netProceedsFloor?.hasConflict ||
          settingsInput.netProceedsCeiling?.hasConflict ? (
            conflictText
          ) : (
            <Stack direction="row">
              <div>
                {defaultValues.netProceedsFloor?.value
                  ? applyNumberFormatting({
                      inputNumber: defaultValues.netProceedsFloor?.value,
                      currencyCode: defaultValues.currencyCode,
                    })
                  : naText}
              </div>
              <Content id={ContentId.OrAbove} />
              <div>
                {defaultValues.netProceedsCeiling?.value
                  ? applyNumberFormatting({
                      inputNumber: defaultValues.netProceedsCeiling?.value,
                      currencyCode: defaultValues.currencyCode,
                    })
                  : naText}
              </div>
            </Stack>
          ),
        to: (
          <Stack direction="row" gap="s">
            <div>
              {updates.netProceedsFloor?.value
                ? applyNumberFormatting({
                    inputNumber: updates.netProceedsFloor?.value,
                    currencyCode: updates.currencyCode,
                  })
                : naText}
            </div>
            <Content id={ContentId.OrAbove} />
            <div>
              {updates.netProceedsCeiling?.value
                ? applyNumberFormatting({
                    inputNumber: updates.netProceedsCeiling?.value,
                    currencyCode: updates.currencyCode,
                  })
                : naText}
            </div>
          </Stack>
        ),
        include:
          !!changedFields?.netProceedsFloor ||
          !!changedFields?.netProceedsCeiling,
        onChange: (include: boolean) => {
          updateChangedFields({
            netProceedsFloor: include,
            netProceedsCeiling: include,
          });
        },
      });
    }
    if (
      !!updates?.netProceedsFloorAdjustmentAmount &&
      updates?.netProceedsFloorAdjustmentRelative != null &&
      !!updates?.netProceedsFloorAdjustmentType
    ) {
      settings.push({
        setting: ContentId.AdjustProceedsFloor,
        from: naText,
        to: (
          <Stack direction="row" gap="s">
            <div>
              {updates.netProceedsFloorAdjustmentRelative
                ? applyNumberFormatting({
                    inputNumber: updates.netProceedsFloorAdjustmentAmount / 100,
                    isPercent: true,
                  })
                : applyNumberFormatting({
                    inputNumber: updates.netProceedsFloorAdjustmentAmount,
                    currencyCode: updates.currencyCode,
                  })}
            </div>
            <Content id={ContentId.RelativeTo} />
            <div>{updates.netProceedsFloorAdjustmentType}</div>
          </Stack>
        ),
        include: !!changedFields?.netProceedsFloorAdjustmentAmount,
        onChange: (include: boolean) => {
          updateChangedFields({
            netProceedsFloorAdjustmentAmount: include,
          });
        },
      });
    }
    if (
      hasChanged(
        updates?.circuitBreakerMinCompListingCount,
        defaultValues.circuitBreakerMinCompListingCount
      )
    ) {
      settings.push({
        setting: ContentId.MinListingsRequired,
        from: settingsInput.circuitBreakerMinCompListingCount?.hasConflict
          ? conflictText
          : defaultValues.circuitBreakerMinCompListingCount?.value,
        to: updates.circuitBreakerMinCompListingCount?.value,
        include: !!changedFields?.circuitBreakerMinCompListingCount,
        onChange: (include: boolean) => {
          updateChangedFields({
            circuitBreakerMinCompListingCount: include,
          });
        },
      });
    }
    if (hasChanged(updates?.outlierMode, defaultValues.outlierMode)) {
      settings.push({
        setting: ContentId.OutlierMode,
        from: settingsInput.outlierMode?.hasConflict
          ? conflictText
          : defaultValues.outlierMode?.value,
        to: updates.outlierMode?.value,
        include: !!changedFields?.outlierMode,
        onChange: (include: boolean) => {
          updateChangedFields({
            outlierMode: include,
          });
        },
      });
    }
    if (
      hasChanged(
        updates?.outlierStandardDeviations,
        defaultValues.outlierStandardDeviations
      )
    ) {
      settings.push({
        setting: ContentId.OutlierFiltering,
        from: settingsInput.outlierStandardDeviations?.hasConflict
          ? conflictText
          : defaultValues.outlierStandardDeviations?.value,
        to: updates.outlierStandardDeviations?.value,
        include: !!changedFields?.outlierStandardDeviations,
        onChange: (include: boolean) => {
          updateChangedFields({
            outlierStandardDeviations: include,
          });
        },
      });
    }

    if (
      hasChanged(
        updates?.outlierKthLowestLimit,
        defaultValues.outlierKthLowestLimit
      ) &&
      (hasChanged(
        updates?.outlierKthLowestLimitAbsoluteSpacing,
        defaultValues.outlierKthLowestLimitAbsoluteSpacing
      ) ||
        hasChanged(
          updates?.outlierKthLowestLimitRelativeSpacing,
          defaultValues.outlierKthLowestLimitRelativeSpacing
        ))
    ) {
      settings.push({
        setting: ContentId.IgnoreTheFirst,
        from:
          settingsInput.outlierKthLowestLimit?.hasConflict ||
          settingsInput.outlierKthLowestLimitAbsoluteSpacing?.hasConflict ||
          settingsInput.outlierKthLowestLimitRelativeSpacing?.hasConflict ? (
            conflictText
          ) : (
            <Stack direction="row" gap="s">
              <div>{defaultValues.outlierKthLowestLimit?.value ?? naText}</div>
              <Content id={ContentId.MoreThan} />
              <div>
                {defaultValues.outlierKthLowestLimitAbsoluteSpacing?.value
                  ? applyNumberFormatting({
                      inputNumber:
                        defaultValues.outlierKthLowestLimitAbsoluteSpacing
                          ?.value,
                    })
                  : defaultValues.outlierKthLowestLimitRelativeSpacing?.value
                  ? applyNumberFormatting({
                      inputNumber:
                        defaultValues.outlierKthLowestLimitRelativeSpacing
                          ?.value,
                      isPercent: true,
                    })
                  : naText}
              </div>
              <Content id={ContentId.TheNextCheapest} />
            </Stack>
          ),
        to: (
          <Stack direction="row" gap="s">
            <div>{updates.outlierKthLowestLimit?.value ?? naText}</div>
            <Content id={ContentId.MoreThan} />
            <div>
              {updates.outlierKthLowestLimitAbsoluteSpacing?.value
                ? applyNumberFormatting({
                    inputNumber:
                      updates.outlierKthLowestLimitAbsoluteSpacing?.value,
                  })
                : updates.outlierKthLowestLimitRelativeSpacing?.value
                ? applyNumberFormatting({
                    inputNumber:
                      updates.outlierKthLowestLimitRelativeSpacing.value,
                    isPercent: true,
                  })
                : naText}
            </div>
            <Content id={ContentId.TheNextCheapest} />
          </Stack>
        ),
        include:
          !!changedFields?.outlierKthLowestLimit ||
          !!changedFields?.outlierKthLowestLimitAbsoluteSpacing ||
          !!changedFields?.outlierKthLowestLimitRelativeSpacing,
        onChange: (include: boolean) => {
          updateChangedFields({
            outlierKthLowestLimit: include,
            outlierKthLowestLimitAbsoluteSpacing: include,
            outlierKthLowestLimitRelativeSpacing: include,
          });
        },
      });
    }

    if (
      hasChanged(
        updates?.circuitBreakerMaxDiscountVelocityPercent,
        defaultValues?.circuitBreakerMaxDiscountVelocityPercent
      ) ||
      hasChanged(
        updates?.circuitBreakerMaxDiscountVelocityTicksInHours,
        defaultValues?.circuitBreakerMaxDiscountVelocityTicksInHours
      )
    ) {
      settings.push({
        setting: ContentId.DonotDropMoreThan,
        from:
          settingsInput.circuitBreakerMaxDiscountVelocityPercent?.hasConflict ||
          settingsInput.circuitBreakerMaxDiscountVelocityPercent
            ?.hasConflict ? (
            conflictText
          ) : (
            <Stack direction="row" gap="s">
              <div>
                {defaultValues.circuitBreakerMaxDiscountVelocityPercent?.value
                  ? applyNumberFormatting({
                      inputNumber:
                        defaultValues.circuitBreakerMaxDiscountVelocityPercent
                          ?.value,
                      isPercent: true,
                    })
                  : naText}
              </div>
              <Content id={ContentId.MaxDiscountInputPrompt2} />
              <div>
                {defaultValues.circuitBreakerMaxDiscountVelocityTicksInHours
                  ?.value
                  ? applyNumberFormatting({
                      inputNumber:
                        defaultValues
                          .circuitBreakerMaxDiscountVelocityTicksInHours?.value,
                    })
                  : naText}
              </div>
              <Content id={ContentId.MaxDiscountInputPrompt3} />
            </Stack>
          ),
        to: (
          <Stack direction="row" gap="s">
            <div>
              {updates.circuitBreakerMaxDiscountVelocityPercent?.value
                ? applyNumberFormatting({
                    inputNumber:
                      updates.circuitBreakerMaxDiscountVelocityPercent.value,
                    isPercent: true,
                  })
                : naText}
            </div>
            <Content id={ContentId.MaxDiscountInputPrompt2} />
            <div>
              {updates.circuitBreakerMaxDiscountVelocityTicksInHours?.value
                ? applyNumberFormatting({
                    inputNumber:
                      updates.circuitBreakerMaxDiscountVelocityTicksInHours
                        ?.value,
                  })
                : naText}
            </div>
            <Content id={ContentId.MaxDiscountInputPrompt3} />
          </Stack>
        ),
        include:
          !!changedFields?.circuitBreakerMaxDiscountVelocityPercent ||
          !!changedFields?.circuitBreakerMaxDiscountVelocityTicksInHours,
        onChange: (include: boolean) => {
          updateChangedFields({
            circuitBreakerMaxDiscountVelocityPercent: include,
            circuitBreakerMaxDiscountVelocityTicksInHours: include,
          });
        },
      });
    }

    return settings;
  }, [
    updates.autoPricingEnabled,
    updates.compListingMode,
    updates.compListingSelectedSectionSettings,
    updates.compListingQuantityScoreAdjustmentOverrideJson,
    updates.undercutAbsoluteAmount,
    updates.undercutRelativeAmount,
    updates.netProceedsFloor,
    updates.netProceedsCeiling,
    updates.netProceedsFloorAdjustmentAmount,
    updates.netProceedsFloorAdjustmentRelative,
    updates.netProceedsFloorAdjustmentType,
    updates.circuitBreakerMinCompListingCount,
    updates.outlierMode,
    updates.outlierStandardDeviations,
    updates.outlierKthLowestLimit,
    updates.outlierKthLowestLimitAbsoluteSpacing,
    updates.outlierKthLowestLimitRelativeSpacing,
    updates.circuitBreakerMaxDiscountVelocityPercent,
    updates.circuitBreakerMaxDiscountVelocityTicksInHours,
    updates.includeExistingSelectedSections,
    updates.currencyCode,
    defaultValues.autoPricingEnabled,
    defaultValues.compListingMode,
    defaultValues.compListingSelectedSectionSettings,
    defaultValues.compListingQuantityScoreAdjustmentOverrideJson,
    defaultValues.undercutAbsoluteAmount,
    defaultValues.undercutRelativeAmount,
    defaultValues.netProceedsFloor,
    defaultValues.netProceedsCeiling,
    defaultValues.circuitBreakerMinCompListingCount,
    defaultValues.outlierMode,
    defaultValues.outlierStandardDeviations,
    defaultValues.outlierKthLowestLimit,
    defaultValues.outlierKthLowestLimitAbsoluteSpacing,
    defaultValues.outlierKthLowestLimitRelativeSpacing,
    defaultValues.circuitBreakerMaxDiscountVelocityPercent,
    defaultValues.circuitBreakerMaxDiscountVelocityTicksInHours,
    defaultValues.currencyCode,
    settingsInput.autoPricingEnabled?.hasConflict,
    settingsInput.compListingMode?.hasConflict,
    settingsInput.compListingSelectedSectionSettings?.hasConflict,
    settingsInput.compListingQuantityScoreAdjustmentOverrideJson?.hasConflict,
    settingsInput.undercutAbsoluteAmount?.hasConflict,
    settingsInput.undercutRelativeAmount?.hasConflict,
    settingsInput.netProceedsFloor?.hasConflict,
    settingsInput.netProceedsCeiling?.hasConflict,
    settingsInput.circuitBreakerMinCompListingCount?.hasConflict,
    settingsInput.outlierMode?.hasConflict,
    settingsInput.outlierStandardDeviations?.hasConflict,
    settingsInput.outlierKthLowestLimit?.hasConflict,
    settingsInput.outlierKthLowestLimitAbsoluteSpacing?.hasConflict,
    settingsInput.outlierKthLowestLimitRelativeSpacing?.hasConflict,
    settingsInput.circuitBreakerMaxDiscountVelocityPercent?.hasConflict,
    conflictText,
    changedFields?.autoPricingEnabled,
    changedFields?.compListingMode,
    changedFields?.compListingSelectedSectionSettings,
    changedFields?.compListingQuantityScoreAdjustmentOverrideJson,
    changedFields?.undercutAbsoluteAmount,
    changedFields?.undercutRelativeAmount,
    changedFields?.netProceedsFloor,
    changedFields?.netProceedsCeiling,
    changedFields?.netProceedsFloorAdjustmentAmount,
    changedFields?.netProceedsFloorAdjustmentRelative,
    changedFields?.netProceedsFloorAdjustmentType,
    changedFields?.circuitBreakerMinCompListingCount,
    changedFields?.outlierMode,
    changedFields?.outlierStandardDeviations,
    changedFields?.outlierKthLowestLimit,
    changedFields?.outlierKthLowestLimitAbsoluteSpacing,
    changedFields?.outlierKthLowestLimitRelativeSpacing,
    changedFields?.circuitBreakerMaxDiscountVelocityPercent,
    changedFields?.circuitBreakerMaxDiscountVelocityTicksInHours,
    updateChangedFields,
    existingSectionsText,
    sectionIdToName,
    naText,
  ]);

  return {
    data,
    columns: PricingSettingsPreviewColumns,
  };
};
