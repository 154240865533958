import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import {
  Detail,
  DetailGroup,
  DetailSection,
  SectionContent,
} from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { ROW_GROUP_SETTING_TO_CID } from 'src/utils/constants/contentIdMaps';
import { RowGroupSetting } from 'src/WebApiController';

import { GroupListingAutoCompSettingsForm } from '../autoCompGroup.types';
import * as styles from './RowRadioGroup.css';

export const RowGroup = () => {
  const { watch, setValue } =
    useFormContext<GroupListingAutoCompSettingsForm>();
  const rowGroupSetting = watch('rowGroupSetting');

  return (
    <DetailSection name={<Content id={ContentId.RowGroups} />}>
      <SectionContent numOfColumns={1}>
        <DetailGroup style={{ gridColumn: '1' }}>
          <Stack direction="row" gap="xl" alignItems="center">
            {Object.entries(ROW_GROUP_SETTING_TO_CID).map(
              ([option, contentId]) => (
                <label
                  key={option}
                  className={`${styles.radioOption} ${
                    rowGroupSetting === option ? styles.selected : ''
                  }`}
                >
                  <input
                    type="radio"
                    value={option}
                    checked={rowGroupSetting === option}
                    onChange={() =>
                      setValue('rowGroupSetting', option as RowGroupSetting)
                    }
                    className={styles.hiddenRadio}
                  />
                  <Content id={contentId} />
                </label>
              )
            )}
          </Stack>
        </DetailGroup>
      </SectionContent>
    </DetailSection>
  );
};
