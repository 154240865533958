import * as RadixSlider from '@radix-ui/react-slider';
import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import { mergeProps } from 'src/core/utils';

import * as styles from './Slider.css';

export const Handle = ({ children }: ComponentPropsWithoutRef<'div'>) => {
  return <div className={styles.sliderHandle}>{children}</div>;
};

export const Root = forwardRef<
  HTMLSpanElement,
  ComponentPropsWithoutRef<typeof RadixSlider.Root> & {
    renderHandle?: (value: number) => ReactNode;
  }
>(function Slider({ renderHandle, value, disabled, ...props }, ref) {
  return (
    <RadixSlider.Root
      ref={ref}
      {...mergeProps({ className: styles.sliderRoot }, props)}
      value={value}
      disabled={disabled}
    >
      <RadixSlider.Track
        className={clsx(styles.sliderTrack, {
          [styles.sliderDisabled]: disabled,
        })}
      >
        <RadixSlider.Range
          className={clsx(styles.sliderRange, {
            [styles.sliderDisabled]: disabled,
          })}
        />
      </RadixSlider.Track>
      {value?.map((v, i) => (
        <RadixSlider.Thumb key={i} className={styles.sliderThumb}>
          {renderHandle ? renderHandle(v) : <Handle>{v}</Handle>}
        </RadixSlider.Thumb>
      ))}
    </RadixSlider.Root>
  );
});
