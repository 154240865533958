import { FormProvider } from 'react-hook-form';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature, ListingDetails } from 'src/WebApiController';

import { HistoricalPricingGraphSection } from '../../components/HistoricalPricingGraphSection';
import { useListingDetailsContext } from '../../ListingDetailsModalV2Context';
import { PricingProtection } from '../AutoPricingForm/Sections/PricingProtection';
import { EconomicsSection } from './EconomicsSection/EconomicsSection';

type ListingDetailsPricingFormProps = {
  listing: ListingDetails;
};

export const ListingDetailsPricingForm = ({
  listing,
}: ListingDetailsPricingFormProps) => {
  const { methods, isLoading } = useListingDetailsContext();
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  return (
    <FormProvider {...methods.pricing}>
      <EconomicsSection listing={listing} disabled={isLoading} />
      {hasAutoPricingFeature && (
        <PricingProtection listing={listing!} disabled={isLoading} />
      )}
      <HistoricalPricingGraphSection listing={listing!} />
    </FormProvider>
  );
};
