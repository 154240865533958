import clsx from 'clsx';
import {
  ListingMainMetrics,
  ListingSubMetrics,
  SaleHeaderMetrics,
  SaleMainMetrics,
  SaleSubMetrics,
} from 'src/components/Metrics';
import {
  ListingMainMetricsV2,
  ListingSubMetricsV2,
} from 'src/components/Metrics/ListingMetricsV2';
import {
  BaseContentProps,
  Content,
  FormatContent,
} from 'src/contexts/ContentContext';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { vars } from 'src/core/themes';
import { MarginMetricIcon, SalesMetricIcon, SignalIcon } from 'src/svgs';
import { UnlistedTicketsIcon } from 'src/svgs/UnlistedTicketsIcon';
import {
  ListingMetrics,
  SaleMetrics,
  TicketGroupMetrics,
  UiMoney,
} from 'src/WebApiController';

import { ContentId } from './constants/contentId';
import { FormatContentId } from './constants/formatContentId';
import { getCurrentLanguage } from './localeUtils';
import * as styles from './ticketMetricUtils.css';

// TODO:
// - Unit test file
// - Are the other untranslated parts even used/needed?
export const getMetricsDisplay = (
  key: keyof SaleMetrics,
  params: BaseContentProps['params']
) => {
  switch (key) {
    case 'qtySold':
      return <Content id={ContentId.Sold} />;
    case 'saleUnfilled':
      return <FormatContent id={FormatContentId.Filled} params={params} />;
    case 'netProcs':
      return <Content id={ContentId.Total} />;
    case 'marg':
      return <FormatContent id={FormatContentId.Margin} params={params} />;
    case 'ttlCst':
      return <Content id={ContentId.CostBasis} />;
    default:
      return '';
  }
};

export type InventorySummaryListingMetricsDisplayStrings = {
  formattedTotalSoldQuantity: string;
  formattedTotalTicketQuantity: string;
  formattedTotalSoldPercentage: string;
  formattedTotalListPrice: string;
  formattedTotalNetProceeds: string;
  formattedTotalGrossMargin: string;
  formattedAverageSalePrice: string;
  totalSoldPercentage: number;
  totalGrossMargin: number;
};

export const getInventorySummaryListingMetricsDisplayStrings = (
  {
    soldQty: totalSoldQuantity,
    tktQty: totalTicketQuantity,
    currency: currencyCode,
    ttlListPrc: totalListPrice,
    netProcs: totalNetProceeds,
    pandL: totalGrossProfit,
  }: ListingMetrics,
  currency?: string | null
) => {
  const quantityNumberFormat = new Intl.NumberFormat(getCurrentLanguage(), {
    style: 'decimal',
    notation: 'compact',
  });

  const curCode = currencyCode || currency || 'USD';
  const currencyNumberFormat = new Intl.NumberFormat(getCurrentLanguage(), {
    style: 'currency',
    notation: 'compact',
    currency: curCode,
  });

  const totalGrossMargin =
    totalGrossProfit?.amt > 0 && totalNetProceeds?.amt > 0
      ? totalGrossProfit.amt / totalNetProceeds.amt
      : 0;
  const totalSoldPercentage =
    totalTicketQuantity > 0 ? totalSoldQuantity / totalTicketQuantity : 0;
  const averageSalePrice =
    totalNetProceeds?.amt > 0 && totalSoldQuantity > 0
      ? totalNetProceeds?.amt / totalSoldQuantity
      : 0;
  return {
    formattedTotalSoldQuantity: quantityNumberFormat.format(
      totalSoldQuantity ?? 0
    ),
    formattedTotalTicketQuantity:
      quantityNumberFormat.format(totalTicketQuantity),
    formattedTotalSoldPercentage: (totalSoldPercentage ?? 0).toLocaleString(
      undefined,
      { style: 'percent', minimumFractionDigits: 0 }
    ),
    formattedTotalListPrice: new Intl.NumberFormat(getCurrentLanguage(), {
      style: 'currency',
      currency: curCode,
    }).format(totalListPrice?.amt ?? 0),
    formattedTotalNetProceeds: currencyNumberFormat.format(
      totalNetProceeds?.amt ?? 0
    ),
    formattedTotalGrossMargin: (totalGrossMargin ?? 0).toLocaleString(
      undefined,
      {
        style: 'percent',
        minimumFractionDigits: 0,
      }
    ),
    formattedAverageSalePrice: currencyNumberFormat.format(
      averageSalePrice ?? 0
    ),
    totalSoldPercentage,
    totalGrossMargin,
  };
};

export const getInventoryListingMetricsDisplayStrings = (
  {
    tktQty: totalTicketQuantity,
    unsoldQty: totalUnsoldQuantity,
    listQty: totalListedQuantity,
    unlistQty: totalUnlistedQuantity,
    currency: currencyCode,
    ttlCst: totalCost,
    unsoldCst: totalUnsoldCost,
    ttlUnsoldListPrc: totalUnsoldListPrice,
    netProcs: totalNetProceeds,
    pandL: totalGrossProfit,
  }: ListingMetrics,
  currency?: string | null
) => {
  const BIG_NUMBER_THRESHOLD = 1000;
  const curCode = currencyCode || currency || 'USD';

  const getFractionDigits = (val: number) =>
    val >= BIG_NUMBER_THRESHOLD ? 1 : 0;

  const getQuantityNumberFormat = () =>
    Intl.NumberFormat(undefined, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });

  const getCurrencyNumberFormat = (val: number) =>
    Intl.NumberFormat(undefined, {
      style: 'currency',
      notation: 'compact',
      currency: curCode,
      minimumFractionDigits: getFractionDigits(val),
      maximumFractionDigits: getFractionDigits(val),
    });

  const totalCostAmount = totalCost?.amt ?? 0;
  const totalUnsoldCostAmount = totalUnsoldCost?.amt ?? 0;
  const totalUnsoldListPriceAmount = totalUnsoldListPrice?.amt ?? 0;
  const totalNetProceedsAmount = totalNetProceeds?.amt ?? 0;
  const totalGrossProfitAmount = totalGrossProfit?.amt ?? 0;
  const totalGrossMargin =
    totalNetProceeds?.amt > 0
      ? (totalGrossProfit?.amt ?? 0) / totalNetProceeds.amt
      : 0;

  return {
    formattedTotalTicketQuantity: getQuantityNumberFormat().format(
      totalTicketQuantity ?? 0
    ),
    formattedTotalUnsoldQuantity: getQuantityNumberFormat().format(
      totalUnsoldQuantity ?? 0
    ),
    formattedTotalListedQuantity: getQuantityNumberFormat().format(
      totalListedQuantity ?? 0
    ),
    formattedTotalUnlistedQuantity: getQuantityNumberFormat().format(
      totalUnlistedQuantity ?? 0
    ),
    formattedTotalCost:
      getCurrencyNumberFormat(totalCostAmount).format(totalCostAmount),
    formattedTotalUnsoldCost: getCurrencyNumberFormat(
      totalUnsoldCostAmount
    ).format(totalUnsoldCostAmount),
    formattedTotalUnsoldListPrice: getCurrencyNumberFormat(
      totalUnsoldListPriceAmount
    ).format(totalUnsoldListPriceAmount),
    formattedTotalNetProceeds: getCurrencyNumberFormat(
      totalNetProceedsAmount
    ).format(totalNetProceedsAmount),

    formattedTotalGrossMargin: totalGrossMargin.toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: 0,
    }),
    formattedTotalGrossProfit: getCurrencyNumberFormat(
      totalGrossProfitAmount
    ).format(totalGrossProfitAmount),
  };
};

type InventoryListingMetricsDisplay = {
  mainMetrics: JSX.Element;
  subMetrics: JSX.Element;
};

/**
 * Displaying metrics for the inventory listing (v2)
 * Main Metric: UnsoldTickets / TotalTickets
 * Sub Metrics: UnsoldCost, ListedQuantity, UnlistedQuantity
 */
export const getInventoryListingMetricsV2 = (
  metrics: ListingMetrics | null | undefined,
  currencyCode?: string | null
): InventoryListingMetricsDisplay => {
  if (!metrics) {
    return {
      mainMetrics: <ContentPlaceholder height="30px" width="64px" />,
      subMetrics: (
        <ListingSubMetricsV2
          totalPandL={
            <>
              <ContentPlaceholder height="16px" width="32px" />
            </>
          }
          totalUnsoldCost={
            <>
              <ContentPlaceholder height="16px" width="32px" />
            </>
          }
          totalUnlistedQuantity={
            <>
              <UnlistedTicketsIcon size={vars.iconSize.m} />
              <ContentPlaceholder height="16px" width="32px" />
            </>
          }
        />
      ),
    };
  }
  const {
    formattedTotalTicketQuantity,
    formattedTotalUnsoldQuantity,
    formattedTotalUnsoldCost: displayTotalUnsoldCost,
    formattedTotalGrossProfit,
    formattedTotalUnlistedQuantity,
  } = getInventoryListingMetricsDisplayStrings(metrics, currencyCode);

  const isLoss = (metrics.pandL?.amt ?? 0) < 0;
  const isProfit = (metrics.pandL?.amt ?? 0) > 0;
  const displayTotalGrossProfit = `${
    isProfit ? '+' : ''
  }${formattedTotalGrossProfit}`;

  return {
    mainMetrics: (
      <ListingMainMetricsV2
        unsoldTicketsCount={formattedTotalUnsoldQuantity}
        ticketsCount={formattedTotalTicketQuantity}
      />
    ),
    subMetrics: (
      <ListingSubMetricsV2
        totalPandL={
          <>
            <span
              className={clsx({
                [styles.profitText]: isProfit,
                [styles.lossText]: isLoss,
              })}
            >
              {displayTotalGrossProfit}
            </span>
          </>
        }
        totalUnsoldCost={
          <>
            <span>{displayTotalUnsoldCost}</span>
          </>
        }
        totalUnlistedQuantity={
          <>
            <span className={styles.iconContainer}>
              <UnlistedTicketsIcon size={vars.iconSize.xs} />
            </span>
            <span>{formattedTotalUnlistedQuantity}</span>
          </>
        }
      />
    ),
  };
};

export const addMoneySameCurrency = (
  a: UiMoney | null | undefined,
  b: UiMoney | null | undefined,
  removeDecimals?: boolean,
  keepDecimalsForValuesLessThanOne?: boolean,
  skipDisplay?: boolean
): UiMoney | null => {
  if (!a && !b) {
    return null;
  }
  if (a && !b) {
    return a;
  }
  if (!a && b) {
    return b;
  }

  const amt = a!.amt + b!.amt;
  if (keepDecimalsForValuesLessThanOne && amt < 1) {
    removeDecimals = false;
  }

  let disp: string | null = null;

  if (!skipDisplay) {
    const dispFormat = new Intl.NumberFormat(undefined, {
      minimumFractionDigits: removeDecimals ? 0 : a!.dec,
      maximumFractionDigits: removeDecimals ? 0 : a!.dec,
      style: 'currency',
      currency: a!.currency ?? 'USD',
    });

    disp = dispFormat.format(amt);
  }

  return {
    amt,
    disp,
    currency: a!.currency,
    dec: a!.dec,
  };
};

export const addListingMetrics = (
  metricA: ListingMetrics,
  metricB: ListingMetrics
): ListingMetrics => {
  const currencyCode = metricA.currency;

  const addListingMoney = (
    a: UiMoney | null | undefined,
    b: UiMoney | null | undefined
  ) =>
    addMoneySameCurrency(
      a,
      b,
      false, // removeDecimal
      true, // keepDecimalForValueLessThanOne
      true // skipDisplay
    );

  return {
    tktQty: metricA.tktQty + metricB.tktQty,
    soldQty: metricA.soldQty + metricB.soldQty,
    unsoldQty: metricA.unsoldQty + metricB.unsoldQty,
    listQty: metricA.listQty + metricB.listQty,
    unlistQty: metricA.listQty + metricB.unlistQty,
    currency: currencyCode,

    ttlCst: addListingMoney(metricA.ttlCst, metricB.ttlCst)!,
    soldCst: addListingMoney(metricA.soldCst, metricB.soldCst)!,
    unsoldCst: addListingMoney(metricA.unsoldCst, metricB.unsoldCst)!,
    ttlListPrc: addListingMoney(metricA.ttlListPrc, metricB.ttlListPrc)!,
    ttlUnsoldListPrc: addListingMoney(
      metricA.ttlUnsoldListPrc,
      metricB.ttlUnsoldListPrc
    )!,
    netProcs: addListingMoney(metricA.netProcs, metricB.netProcs)!,
    pandL: addListingMoney(metricA.pandL, metricB.pandL)!,
  };
};

export const addSaleMetrics = (
  metricA: SaleMetrics,
  metricB: SaleMetrics
): SaleMetrics => {
  const currencyCode = metricA.currency ?? metricB.currency;

  return {
    qtySold: metricA.qtySold + metricB.qtySold,
    saleUnfilled: metricA.saleUnfilled + metricB.saleUnfilled,
    netProcs: addMoneySameCurrency(
      metricA.netProcs,
      metricB.netProcs,
      true, // removeDecimal
      true, // keepDecimalForValueLessThanOne
      true // skipDisplay
    ),
    ttlCst: addMoneySameCurrency(
      metricA.ttlCst,
      metricB.ttlCst,
      true, // removeDecimal
      true, // keepDecimalForValueLessThanOne
      true // skipDisplay
    ),
    currency: currencyCode,
    marg:
      metricA.marg == null && metricB.marg == null
        ? null
        : (metricA.marg ?? 0) + (metricB.marg ?? 0),
  };
};

export const addTicketGroupMetrics = (
  metricA: TicketGroupMetrics,
  metricB: TicketGroupMetrics
): TicketGroupMetrics => {
  const currencyCode = metricA.currency ?? metricB.currency;

  return {
    tktQty: metricA.tktQty + metricB.tktQty,
    ttlCst: addMoneySameCurrency(
      metricA.ttlCst,
      metricB.ttlCst,
      true, // removeDecimal
      true, // keepDecimalForValueLessThanOne
      true // skipDisplay
    ),
    currency: currencyCode,
  };
};

export const getInventoryListingMetrics = (
  metrics: ListingMetrics | null | undefined,
  currencyCode?: string | null
): InventoryListingMetricsDisplay => {
  if (!metrics) {
    return {
      mainMetrics: <ContentPlaceholder height="30px" width="64px" />,
      subMetrics: (
        <ListingSubMetrics
          totalUnsoldListPrice={
            <>
              <SignalIcon size={vars.iconSize.xs} />
              <ContentPlaceholder height="16px" width="32px" />
            </>
          }
          totalNetProceeds={
            <>
              <SalesMetricIcon size={vars.iconSize.xs} />
              <ContentPlaceholder height="16px" width="32px" />
            </>
          }
          totalGrossMargin={
            <>
              <MarginMetricIcon size={vars.iconSize.xs} />
              <ContentPlaceholder height="16px" width="32px" />
            </>
          }
        />
      ),
    };
  }
  const {
    formattedTotalTicketQuantity,
    formattedTotalUnsoldListPrice,
    formattedTotalNetProceeds,
    formattedTotalGrossMargin,
  } = getInventoryListingMetricsDisplayStrings(metrics, currencyCode);
  return {
    mainMetrics: (
      <ListingMainMetrics ticketsCount={formattedTotalTicketQuantity} />
    ),
    subMetrics: (
      <ListingSubMetrics
        totalUnsoldListPrice={
          <>
            <span className={styles.iconContainer}>
              <SignalIcon size={vars.iconSize.xs} />
            </span>
            {formattedTotalUnsoldListPrice}
          </>
        }
        totalNetProceeds={
          <>
            <span className={styles.iconContainer}>
              <SalesMetricIcon size={vars.iconSize.xs} />
            </span>
            {formattedTotalNetProceeds}
          </>
        }
        totalGrossMargin={
          <>
            <span className={styles.iconContainer}>
              <MarginMetricIcon size={vars.iconSize.xs} />
            </span>
            {formattedTotalGrossMargin}
          </>
        }
      />
    ),
  };
};

export const getSalesSaleMetrics = (
  metrics: SaleMetrics | null | undefined,
  addBulletPoint = true,
  firstBulletPoint = true,
  secondBulletPoint = true
) => {
  return metrics === undefined
    ? null
    : !metrics
    ? { headerMetrics: [], mainMetrics: '', subMetrics: [] }
    : {
        headerMetrics: (
          <SaleHeaderMetrics needFulfillment={metrics.saleUnfilled > 0} />
        ),
        mainMetrics: (
          <SaleMainMetrics totalNetProceeds={metrics.netProcs?.disp ?? null} />
        ),
        subMetrics: (
          <SaleSubMetrics
            margin={metrics.marg}
            ticketsFulfilled={metrics.qtySold - metrics.saleUnfilled}
            ticketsSold={metrics.qtySold}
            addBulletPoint={addBulletPoint}
            firstBulletPoint={firstBulletPoint}
            secondBulletPoint={secondBulletPoint}
          />
        ),
      };
};

export const getEmptyMetric = () =>
  ({
    ticketsTotal: 0,
    qtySold: 0,
    saleUnfilled: 0,
    netProcs: { disp: '' } as UiMoney,
    ttlCst: { disp: '' } as UiMoney,
    marg: 0,
    currency: null,
  }) as SaleMetrics;
