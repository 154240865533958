import { ComponentPropsWithRef } from 'react';

import { PosMultiSelect } from './PosMultiSelect';

export function PosEnumMultiSelect<EnumType extends string>({
  values,
  defaultValue,
  onChange,
  valueOptionsContent,
  ...rest
}: {
  values: EnumType[];
  defaultValue?: EnumType | null;
  valueOptionsContent: Record<EnumType, string>;
  onChange: (newValues: EnumType[]) => void;
} & Omit<
  ComponentPropsWithRef<typeof PosMultiSelect>,
  'values' | 'valueOptionsContent' | 'onChange' | 'defaultValue'
>) {
  return (
    <PosMultiSelect
      values={values.map((v) => v as string)}
      defaultValue={
        defaultValue === undefined
          ? undefined
          : defaultValue === null
          ? ''
          : defaultValue
      }
      onChange={(newValues: string[]) => {
        onChange(newValues.map((v) => v as EnumType));
      }}
      valueOptionsContent={valueOptionsContent}
      {...rest}
    />
  );
}
