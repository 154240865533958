import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const QualityScoreModeIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 40 40">
      <circle
        cx="20"
        cy="20"
        r="14"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
      />
      <path
        d="M27.23 18C26.7935 16.4175 25.8499 15.0219 24.5439 14.0274C23.2378 13.0329 21.6416 12.4943 20 12.4943C18.3584 12.4943 16.7622 13.0329 15.4562 14.0274C14.1502 15.0219 13.2065 16.4175 12.77 18"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.393 25.724C22.827 24.288 22.172 17.824 22.172 17.824C22.172 17.824 18.007 22.957 17.607 24.279C17.415 24.9137 17.4829 25.5986 17.7959 26.1832C18.1089 26.7677 18.6413 27.204 19.276 27.396C19.9107 27.588 20.5956 27.52 21.1802 27.2071C21.7647 26.8941 22.201 26.3617 22.393 25.727V25.724Z"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
