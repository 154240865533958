import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function CheckIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M4.814 9.661a.525.525 0 00-.722-.003l-1.866 1.79a.482.482 0 00.006.7l7.776 7.559a.487.487 0 00.705-.012L21.789 7.713A.51.51 0 0021.768 7l-1.871-1.79a.478.478 0 00-.698.029L10.71 14.64a.48.48 0 01-.698.026L4.814 9.66z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
