import { ComponentProps } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { IconsFill, UploadIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { UserDocumentLinks } from 'src/WebApiController';

import { ProofOfPurchaseUploadDilaog } from './ProofOfPurchaseUploadDialog';

export type LaunchProofOfPurchaseUploadDilaogProps = ComponentProps<
  typeof Button
> & { className?: string; proofDocs?: UserDocumentLinks[] | null };

export const LaunchProofOfPurchaseUploadDilaog = ({
  variant = 'link',
  proofDocs,
  ...props
}: LaunchProofOfPurchaseUploadDilaogProps) => {
  const dialog = useBasicDialog();

  const onLaunch = () => {
    dialog.launchDialog();
  };

  return (
    <Stack gap="m" alignItems="center">
      <Button {...props} variant={variant} onClick={onLaunch}>
        <UploadIcon
          withHoverEffect
          fill={IconsFill.currentColor}
          size={vars.iconSize.s}
        />
        <Content
          id={proofDocs ? ContentId.ReuploadProof : ContentId.UploadProof}
        />
      </Button>
      <ProofOfPurchaseUploadDilaog
        {...dialog.dialogProps}
        proofDocs={proofDocs}
        onClosed={dialog.closeDialog}
        onCancel={dialog.closeDialog}
      />
    </Stack>
  );
};
