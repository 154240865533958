import { Stack } from 'src/core/ui';

import { SellerAccountSettingBody } from './SellerAccountSettingBody';
import { SellerAccountSettingHeader } from './SellerAccountSettingHeader';

export const SellerAccountSetting = () => {
  return (
    <Stack direction="column" gap="l" height="full">
      <SellerAccountSettingHeader />

      <SellerAccountSettingBody />
    </Stack>
  );
};
