export const rawFetch = async (url: string, options?: RequestInit) => {
  const a = await fetch(url, options);

  if (a.ok) {
    let b;
    const headers = [...a.headers.entries()];

    const contentTypeHeader = headers.find(([key]) => key === 'content-type');

    const contentType = contentTypeHeader?.[1] ?? null;

    if (contentType && contentType.includes('application/json')) {
      b = await a.json();
    } else {
      b = await a.text();
    }
    return b;
  }
  throw new Error(
    `send request error - ${JSON.stringify({
      status: a.status,
      message: a.statusText,
    })}`
  );
};
