import clsx from 'clsx';
import { ComponentProps, ReactNode } from 'react';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';

import * as styles from './ContentPlaceholder.css';

export type ContentPlaceholderProps = Pick<
  ComponentProps<'div'>,
  'className' | 'style'
> & {
  width?: string;
  height?: string;
  isCircle?: boolean;
  children?: ReactNode;
};

export const ContentPlaceholder = ({
  width = '100%',
  height = '100%',
  isCircle = false,
  children,
  className,
  ...rest
}: ContentPlaceholderProps) => {
  const { isDarkMode } = useSiteTheme();
  return (
    <div
      {...rest}
      className={clsx(styles.placeholder, className, {
        [styles.darkPlaceholder]: isDarkMode,
        [styles.isCircle]: isCircle,
      })}
      style={{
        width,
        height,
      }}
    >
      {children}
    </div>
  );
};
