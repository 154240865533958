import { FormProvider } from 'react-hook-form';
import { ListingDetails } from 'src/WebApiController';

import { useListingDetailsContext } from '../../ListingDetailsModalV2Context';
import { TagsSection } from './TagsSection';

type ListingDetailsTagsFormProps = {
  listing: ListingDetails | null;
};

export const ListingDetailsTagsForm: React.FC<ListingDetailsTagsFormProps> = ({
  listing,
}) => {
  const { methods, isLoading } = useListingDetailsContext();
  return (
    <FormProvider {...methods.tags}>
      <TagsSection listing={listing} disabled={isLoading} />
    </FormProvider>
  );
};
