import { useMemo } from 'react';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { Content } from 'src/contexts/ContentContext';
import { QuickFilter } from 'src/hooks/useQuickFilters';
import { ContentId } from 'src/utils/constants/contentId';
import { KnownVendors } from 'src/utils/constants/knownVendors';
import {
  PurchaseOrderQuery,
  PurchasePaymentStatus,
  UserSetting,
} from 'src/WebApiController';

export const useDefaultPurchaseFilters = ({
  purchaseQuery,
  initialQuery,
}: {
  purchaseQuery: PurchaseOrderQuery;
  initialQuery: PurchaseOrderQuery;
}) => {
  const {
    posSelectProps: { valueOptionsContent: purchaseVendors },
  } = usePurchaseVendorSelector({});

  const ticketmasterVendorId = useMemo(
    () =>
      Object.keys(purchaseVendors).find(
        (key) => purchaseVendors[key] === KnownVendors.Ticketmaster
      ),
    [purchaseVendors]
  );
  const defaultQuickFilters = useMemo(() => {
    const filters: QuickFilter<UserSetting.QuickFiltersCustomPurchases>[] = [
      {
        id: 'unpaid',
        name: <Content id={ContentId.Unpaid} />,
        data: {
          ...initialQuery,
          paymentStatus: PurchasePaymentStatus.Unpaid,
        },
      },
      {
        id: 'refundNeeded',
        name: <Content id={ContentId.RefundNeeded} />,
        data: {
          ...initialQuery,
          paymentStatus: PurchasePaymentStatus.RefundNeeded,
        },
      },
    ];
    if (ticketmasterVendorId) {
      filters.push({
        id: 'ticketmaster',
        name: KnownVendors.Ticketmaster,
        data: {
          ...initialQuery,
          purchaseVendorId: Number(ticketmasterVendorId),
          purchaseVendorAccountIds: null, // changing vendor should reset the vendor-account selection filter
        },
      });
    }

    // Default filters aren't supposed to change view mode
    filters.forEach(
      (f: QuickFilter<UserSetting.QuickFiltersCustomPurchases>) => {
        f.data.viewMode = purchaseQuery.viewMode;
      }
    );
    return filters;
  }, [initialQuery, purchaseQuery.viewMode, ticketmasterVendorId]);

  return defaultQuickFilters;
};
