import { ListingMergeSuggestion } from 'src/WebApiController';

/**
 * Clusted array of listing ids when they have overlap
 * E.g. [[1, 2], [3, 4], [2, 3], [5, 6]] => [[1, 2, 3], [5, 6]]
 * @param listingIds
 * @returns
 */
const clusterListingIds = (listingIds: number[][]) => {
  const clusters: number[][] = [];
  // This step is necessary - check the case of [[1, 2], [3, 4], [2, 3]]
  const sortedListingIds = listingIds.sort((a, b) => a[0] - b[0]);
  sortedListingIds.forEach((ids) => {
    const cluster = clusters.find((c) => ids.some((id) => c.includes(id)));
    if (cluster) {
      ids.forEach((id) => {
        if (!cluster.includes(id)) {
          cluster.push(id);
        }
      });
    } else {
      clusters.push(ids);
    }
  });
  return clusters
    .map((c) => c.sort((a, b) => a - b))
    .filter((c) => c.length > 1);
};

export const clusterListingMergeSuggestionsByListingIdOverlap = (
  mergeSuggestions: ListingMergeSuggestion[] | null | undefined
): ListingMergeSuggestion[] | null | undefined => {
  if (!mergeSuggestions) {
    return mergeSuggestions;
  }

  const mergeSuggestionsByEventId: Record<string, ListingMergeSuggestion[]> =
    {};
  mergeSuggestions.forEach((s) => {
    const key = s.eventId ? s.eventId.toString() : s.eventMappingId ?? '';
    if (!key) return;

    if (!mergeSuggestionsByEventId[key]) {
      mergeSuggestionsByEventId[key] = [];
    }
    mergeSuggestionsByEventId[key].push(s);
  });

  const mergedSuggestions: ListingMergeSuggestion[] = [];

  Object.values(mergeSuggestionsByEventId).forEach((suggestions) => {
    const listingIds = suggestions.map((s) => s.listingIds);
    const clusteredListingIds = clusterListingIds(listingIds);

    clusteredListingIds.forEach((ids) => {
      const mergedSuggestion: ListingMergeSuggestion = {
        eventId: suggestions[0].eventId,
        eventMappingId: suggestions[0].eventMappingId,
        listingIds: ids,
        seatFrom: null,
        seatTo: null,
      };
      mergedSuggestions.push(mergedSuggestion);
    });
  });

  return mergedSuggestions;
};
