import { useCallback } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { vars } from 'src/core/themes';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';
import { FullPageIcon } from 'src/svgs/FullPageIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { Event, Feature } from 'src/WebApiController';

export const EventActionsCell = ({ event }: { event: Event }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const hasInventoryEventPageV2Feature = useUserHasFeature(
    Feature.InventoryEventPageV2
  );

  const eventUrl = createInventoryEventUrl(
    event.viagVirtualId,
    true // includeSearchParams
  );

  const eventUrlV2 = createInventoryEventUrl(
    event.viagVirtualId,
    true, // includeSearchParams
    true // useEventV2
  );

  const onViewClick = useCallback(
    (eventUrl: string) => {
      if (event) {
        window.location.replace(`#${event.viagVirtualId}`);
        setTimeout(() => {
          navigate(eventUrl, {
            state: {
              returnUrl: `${location.pathname}${location.search}#${event.viagVirtualId}`,
            },
          });
        }, 10);
      }
    },
    [event, navigate, location.pathname, location.search]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      {hasInventoryEventPageV2Feature && (
        <Link
          to={eventUrlV2}
          style={{ height: vars.iconSize.l }}
          onClick={(e) => {
            if (e.button === 0 && e.getModifierState('Control') === false) {
              // Perform like a button click when it's a left-click (and no Ctrl)
              // Else the click will be whatever <a /> does
              e.preventDefault();
              e.stopPropagation();

              onViewClick(eventUrlV2);
            }
          }}
        >
          <IconButton
            icon={
              <FullPageIcon
                fill={vars.color.textWarning}
                size={vars.iconSize.xs}
                withHoverEffect
              />
            }
            titleContentId={ContentId.OpenEventPageV2InDevelopment}
          />
        </Link>
      )}
      <Link
        to={eventUrl}
        style={{ height: vars.iconSize.l }}
        onClick={(e) => {
          if (e.button === 0 && e.getModifierState('Control') === false) {
            // Perform like a button click when it's a left-click (and no Ctrl)
            // Else the click will be whatever <a /> does
            e.preventDefault();
            e.stopPropagation();

            onViewClick(eventUrl);
          }
        }}
      >
        <IconButton
          icon={
            <FullPageIcon
              fill={vars.color.textBrand}
              size={vars.iconSize.xs}
              withHoverEffect
            />
          }
          titleContentId={ContentId.OpenEventPage}
        />
      </Link>
    </TableCellDiv>
  );
};
