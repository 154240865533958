import { clsx } from 'clsx';
import {
  SeatInfo,
  SeatInfoProps,
} from 'src/components/UploadArtifacts/UploadETicketsV2/views/Seats/SeatInfo/SeatInfo';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './UnMappedSeat.css';

interface UnmappedSeatProps {
  seatInfo: SeatInfoProps;
  highlightDrop: boolean;
}

export const UnMappedSeat = ({
  seatInfo,
  highlightDrop,
}: UnmappedSeatProps) => {
  return (
    <div
      className={clsx(styles.seatWrapper, styles.unmappedSeatWrapper, {
        [styles.droppable]: highlightDrop,
      })}
    >
      <SeatInfo {...seatInfo} size="big" />
      <span className={styles.dropLabel}>
        <Content id={ContentId.DragNAssignToSeats} />
      </span>
    </div>
  );
};
