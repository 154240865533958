import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
  DATA_REFRESH_RATE_IN_MILLIS_LONG,
  MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS,
} from 'src/utils/constants/constants';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  PurchaseClient,
  PurchaseOrder,
  PurchaseOrderQuery,
  PurchaseViewMode,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { useErrorBoundaryContext } from '../ErrorBoundaryContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import { PURCHASE_SINGLE_TIME_PERIOD_QUERY_KEY } from './PurchaseDataContext.constants';

export const useGetAllPurchasesQuery = () => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { filterQuery } = useFilterQueryContext<PurchaseOrderQuery>();
  const { trackError } = useErrorBoundaryContext();
  const { allEventIds, totalPurchaseCount } = useCatalogDataContext();

  const moddedFilterQuery = {
    ...filterQuery,
    // This is
    viewMode: null,
  };

  const filterPurchaseOrders = useCallback(
    (purchaseOrders: PurchaseOrder[] | null | undefined) => {
      if (purchaseOrders && filterQuery.purchaseOrderState) {
        return purchaseOrders.filter(
          (po) => po.poState === filterQuery.purchaseOrderState
        );
      }

      return purchaseOrders;
    },
    [filterQuery.purchaseOrderState]
  );

  const shouldQuery = !(
    filterQuery.viewMode !== PurchaseViewMode.FlattenedView ||
    (filterQuery.viewMode === PurchaseViewMode.FlattenedView &&
      (!totalPurchaseCount ||
        totalPurchaseCount > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS)) ||
    activeAccountWebClientConfig.activeAccountId == null
  );

  const allPurchasesQuery = useQuery({
    queryKey: [
      PURCHASE_SINGLE_TIME_PERIOD_QUERY_KEY,
      moddedFilterQuery,
      allEventIds,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const filterQueryWithEventIds = {
        ...moddedFilterQuery,
        eventOrMappingIds: (allEventIds ?? []).map((ev) => ev.viagVirtualId),
        performerIds: (allEventIds ?? [])
          .filter((ev) => ev.performerId != null)
          .map((ev) => ev.performerId!),
        venueIds: (allEventIds ?? []).map((ev) => ev.venueId),
      };

      // XXX Workaround for the backend
      // because backend treats empty arrays as "no filter" for backwards compatibility
      if (allEventIds?.length === 0) {
        return [];
      }

      return tryInvokeApi(
        async () => {
          const client = new PurchaseClient(activeAccountWebClientConfig);
          const data = await client.getPurchaseOrders(filterQueryWithEventIds);
          return data;
        },
        (error) => {
          trackError(
            PURCHASE_SINGLE_TIME_PERIOD_QUERY_KEY,
            error,
            filterQueryWithEventIds
          );
        }
      );
    },
    enabled: shouldQuery,
    staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  return {
    allPurchasesQuery,
    allPurchasesFiltered: filterPurchaseOrders(allPurchasesQuery.data),
  };
};
