import clsx from 'clsx';
import { formatInTimeZone } from 'date-fns-tz';
import {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { MerchantColorBadge } from 'src/components/common/MerchantColorBadge';
import { useContent, useContentContext } from 'src/contexts/ContentContext';
import { ConversationDataContext } from 'src/contexts/ConversationDataContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { HorizontalTextList } from 'src/core/POS/HorizontalTextList';
import { vars } from 'src/core/themes';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DotIcon } from 'src/svgs/DotIcon';
import { MailIcon } from 'src/svgs/MailIcon';
import { PurchaseIcon } from 'src/svgs/PurchaseIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { formatDate } from 'src/utils/dateTimeUtils';
import { getEventPerformerVenue } from 'src/utils/eventWithDataUtils';
import { getListingNoteIcon } from 'src/utils/listingNotesUtils';
import {
  AutoPoInboundEmail,
  AutoPoInboundEmailClassificationType,
  Feature,
  ListingNote,
  Marketplace,
  SellerSupportCase,
} from 'src/WebApiController';

import * as styles from './ConversationListItem.css';

// TODO Probably this will be moved to mvc layer when integrating
export enum ConversationType {
  Purchase = 'Purchase',
  Sale = 'Sale',
  Transfer = 'Transfer',
  Private = 'Private',
  Junk = 'Junk',
  Uncategorized = 'Uncategorized',
}

const EMAIL_CLASSIFICATION_TYPE_TO_CONVERSATION_TYPE: Record<
  AutoPoInboundEmailClassificationType,
  ConversationType
> = {
  [AutoPoInboundEmailClassificationType.ExistingPurchaseOrder]:
    ConversationType.Purchase,
  [AutoPoInboundEmailClassificationType.NewPurchaseOrder]:
    ConversationType.Purchase,
  [AutoPoInboundEmailClassificationType.Junk]: ConversationType.Junk,
  [AutoPoInboundEmailClassificationType.OneTimePass]: ConversationType.Private,
  [AutoPoInboundEmailClassificationType.Password]: ConversationType.Private,
  [AutoPoInboundEmailClassificationType.TicketsAvailable]:
    ConversationType.Purchase,
  [AutoPoInboundEmailClassificationType.PurchaseCancelled]:
    ConversationType.Purchase,
  [AutoPoInboundEmailClassificationType.EventCancelled]:
    ConversationType.Purchase,
  [AutoPoInboundEmailClassificationType.EventPostponed]:
    ConversationType.Purchase,
  [AutoPoInboundEmailClassificationType.EnjoyTheEvent]:
    ConversationType.Purchase,
  [AutoPoInboundEmailClassificationType.TransferSent]:
    ConversationType.Transfer,
  [AutoPoInboundEmailClassificationType.TransferAccepted]:
    ConversationType.Transfer,
  [AutoPoInboundEmailClassificationType.TransferExpired]:
    ConversationType.Transfer,
  [AutoPoInboundEmailClassificationType.NewSale]: ConversationType.Sale,
  [AutoPoInboundEmailClassificationType.ConfirmationDeadline]:
    ConversationType.Sale,
  [AutoPoInboundEmailClassificationType.ShippingDeadline]:
    ConversationType.Sale,
  [AutoPoInboundEmailClassificationType.SaleCancelled]: ConversationType.Sale,
  [AutoPoInboundEmailClassificationType.OtherMarketplaceContact]:
    ConversationType.Sale,
};

export const convertEmailClassificationTypeToConversationType = (
  classificationType?: AutoPoInboundEmailClassificationType | null
) =>
  classificationType
    ? EMAIL_CLASSIFICATION_TYPE_TO_CONVERSATION_TYPE[classificationType]
    : ConversationType.Uncategorized;

export const convertConversationTypeToEmailClassificationTypes = (
  conversationTypes: ConversationType[]
) => {
  const entries = Object.entries(
    EMAIL_CLASSIFICATION_TYPE_TO_CONVERSATION_TYPE
  );
  return entries
    .filter(([, type]) => conversationTypes.includes(type))
    .map(([key]) => key as AutoPoInboundEmailClassificationType);
};

export type ConversationInfo = {
  vendorId?: number | null;
  merchantName?: string | null;
  title?: string | null;
  datetime?: Date | null;
  unread?: boolean | null;
  conversationType?: ConversationType;
  sellerSupportCase?: SellerSupportCase;
  inboundEmail?: AutoPoInboundEmail;
  onEmailClassified?: (inboundEmailId: number) => void;
};

export type ConversationListItemProps = ConversationInfo &
  Omit<ComponentProps<'div'>, 'title' | 'content'> & {
    isActive: boolean;
    subtitle?: string | null;
    content?: string | null;
    listingNotes?: ListingNote[] | null;
  };

export function ConversationListItem({
  vendorId,
  merchantName,
  title,
  conversationType,
  datetime,
  unread,
  inboundEmail,
  sellerSupportCase,
  ...props
}: ConversationListItemProps) {
  const { getPurchaseOrderDetails, getSaleDetails } = useContext(
    ConversationDataContext
  );
  const [subtitle, setSubtitle] = useState<string>();
  const [content, setContent] = useState<string>();
  const [listingNotes, setListingNotes] = useState<ListingNote[] | null>();
  const [merchant, setMerchant] = useState<string | null | undefined>(
    merchantName
  );

  const supportCaseIdContent = useContent(ContentId.SupportCaseId);
  const orderIdContent = useContent(ContentId.ExternalPurchaseId);
  const sectionContent = useContent(ContentId.Section);
  const rowContent = useContent(ContentId.Row);
  const seatsContent = useContent(ContentId.Seats);
  const ticketsContent = useContent(ContentId.Tickets);

  const fetchDisplayContent = useCallback(async () => {
    let merchant, content, subtitle;

    if (conversationType === ConversationType.Purchase) {
      if (inboundEmail?.purchaseOrderId) {
        const purchaseOrder = await getPurchaseOrderDetails(
          inboundEmail.purchaseOrderId
        );
        if (purchaseOrder) {
          subtitle = `${orderIdContent}: ${purchaseOrder.vendOrdId}`;
          merchant = purchaseOrder.vendor?.name;

          const catalog = purchaseOrder.miniCatalog;

          // Example of formatted content using the following code:
          // Section {S}, Row {R}, Seats {F}-{T} • {N} Tickets
          // {Venue}, {City} • {EventDate}
          content = '';
          const tg = purchaseOrder.ticketGroups[0];
          if (tg) {
            const { event, performer, venue } = getEventPerformerVenue(
              tg.viagVirtualId,
              catalog
            );

            content += `${sectionContent} ${tg.seating.section}`;
            if (tg.seating.row) {
              content += `, ${rowContent} ${tg.seating.row}`;
            }
            if (tg.seating.seatFr) {
              content += `, ${seatsContent} ${tg.seating.seatFr}`;
              if (tg.seating.seatTo) {
                content += `-${tg.seating.seatTo}`;
              }
            }
            content += ` • ${tg.ticketCnt} ${ticketsContent}`;
            if (venue?.name || event?.event?.dates.start) {
              content += '\n';
              if (venue?.name) {
                content += `${venue.name}`;
                if (venue?.city) {
                  content += `, ${venue.city}`;
                }
                if (event?.event?.dates.start) {
                  content += ' • ';
                }
              }
              if (event?.event?.dates.start) {
                content += formatDate(event.event.dates.start);
              }
            }
            setListingNotes(tg.seatTraits);
          }
        }
      }
    } else if (conversationType === ConversationType.Sale) {
      if (inboundEmail?.saleId) {
        const sale = await getSaleDetails(inboundEmail.saleId);
        if (sale) {
          const { idOnMkp, id, mkp, seating, ticketCnt } = sale;

          subtitle = `${orderIdContent}: ${idOnMkp ?? id}`;
          merchant = mkp;
          content = [
            [
              `${sectionContent} ${seating.section}`,
              seating.row ? `${rowContent} ${seating.row}` : undefined,
              seating.seatFr
                ? `${seatsContent} ${seating.seatFr}` +
                  (seating.seatTo ? `-${seating.seatTo}` : '')
                : undefined,
            ]
              .filter((s) => Boolean(s))
              .join(', '),
            `${ticketCnt} ${ticketsContent}`,
          ]
            .filter((s) => Boolean(s))
            .join(' • ');
        }
      }
    } else if (sellerSupportCase != null) {
      merchant = Marketplace.StubHub;
      subtitle = `${supportCaseIdContent}: ${sellerSupportCase?.sellerSupportCaseId}`;
    }

    setSubtitle(subtitle);
    setMerchant(merchant);
    setContent(content);
  }, [
    conversationType,
    getPurchaseOrderDetails,
    getSaleDetails,
    inboundEmail?.purchaseOrderId,
    inboundEmail?.saleId,
    orderIdContent,
    rowContent,
    seatsContent,
    sectionContent,
    sellerSupportCase,
    supportCaseIdContent,
    ticketsContent,
  ]);

  useEffect(() => {
    fetchDisplayContent();
  }, [fetchDisplayContent, inboundEmail, sellerSupportCase]);

  return (
    <ConversationListItemDisplay
      vendorId={vendorId}
      merchantName={merchant}
      title={title}
      subtitle={subtitle}
      content={content}
      datetime={datetime}
      unread={unread}
      listingNotes={listingNotes}
      conversationType={conversationType}
      inboundEmail={inboundEmail}
      {...props}
    />
  );
}

function ConversationListItemDisplay({
  vendorId,
  merchantName,
  title,
  subtitle,
  content,
  datetime,
  unread,
  listingNotes,
  conversationType,
  isActive = false,
  sellerSupportCase,
  ...props
}: ConversationListItemProps) {
  const contentContext = useContentContext();
  const hasListingNotesAdditionalInfoFeature = useUserHasFeature(
    Feature.ListingNotesAdditionalInfo
  );

  const { timeZone } = useSiteTimezoneContext();
  return (
    <div
      className={clsx(styles.conversationListItem, {
        [styles.conversationListItemActive]: isActive,
      })}
      {...props}
    >
      <div className={styles.iconContainer}>
        {sellerSupportCase != null && (
          <div className={styles.iconBackground}>
            <MailIcon className={styles.icon} fill={vars.color.textBrand} />
          </div>
        )}
        {conversationType === ConversationType.Purchase && (
          <div className={styles.iconBackground}>
            <PurchaseIcon
              className={styles.icon}
              stroke={vars.color.textBrand}
            />
          </div>
        )}
      </div>
      <div className={styles.conversationListItemMainContainer}>
        <div className={styles.topRowContainer}>
          <div className={styles.merchantTagContainer}>
            {(vendorId || merchantName) && (
              <MerchantColorBadge vendorId={vendorId} name={merchantName} />
            )}
          </div>
          <div className={styles.dateTimeContainer}>
            {datetime && (
              <div>
                {formatInTimeZone(datetime, timeZone, 'LLL dd yyyy, h:mm a')}
              </div>
            )}
            <div className={styles.unreadDotContainer}>
              {unread === true && (
                <DotIcon
                  style={{ height: '10px', width: '10px' }}
                  fill={vars.color.textBrand}
                />
              )}
            </div>
          </div>
        </div>

        {title && <div className={styles.titleContainer}>{title}</div>}
        {subtitle && <div className={styles.subtitleContainer}>{subtitle}</div>}
        {content && <div className={styles.contentContainer}>{content}</div>}
        {listingNotes?.length ? (
          <HorizontalTextList
            className={styles.listingNotes}
            preIcon={getListingNoteIcon(
              listingNotes[0],
              contentContext,
              hasListingNotesAdditionalInfoFeature
            )}
            texts={listingNotes.map((n) => n.listingNoteText)}
            maxTextsToShowOnHover={listingNotes.length}
          />
        ) : null}
      </div>
    </div>
  );
}
