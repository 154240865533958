import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function InfoSolidIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M12 2c5.51 0 10 4.49 10 10s-4.49 10-10 10S2 17.51 2 12 6.49 2 12 2zm0 7.867c-.556 0-1.013.426-1.062.97l-.005.096v5.334a1.067 1.067 0 002.13.097l.004-.097v-5.334c0-.588-.478-1.066-1.067-1.066zm0-3.2A1.067 1.067 0 1012 8.8a1.067 1.067 0 000-2.134z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
