import { useQuery } from '@tanstack/react-query';
import { ReactNode } from 'react';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { ContentClient } from 'src/WebApiController';

import { ContentProvider, ContentResolverParams } from './ContentContext';

export function SiteContentProvider({ children }: { children?: ReactNode }) {
  const { trackError } = useErrorBoundaryContext();

  const contentQuery = useQuery({
    queryKey: ['ContentClient.getContentDataMap'],
    queryFn: () =>
      tryInvokeApi(
        async () => {
          const client = new ContentClient({});
          return await client.getContentDataMap();
        },
        (error) => {
          trackError('ContentClient.getContentDataMap', error);
        }
      ),

    staleTime: Infinity, // site content don't change unless re-compile, and we already invalidate on reload
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
  });

  return (
    <ContentProvider
      isLoading={contentQuery.isLoading}
      contentCache={{
        hasValue: (id: string) => contentQuery.data?.[id] != null,
        get: (id: string) => contentQuery.data?.[id]?.body || undefined,
      }}
      contentResolver={contentResolver}
    >
      {children}
    </ContentProvider>
  );
}

// TODO: Website had this as a fetch to their content api route
// in order to pull more content, we currently don't need this.
// For ref: https://dev.azure.com/viagogo/Website/_git/Viagogo.Site?path=/Viagogo.Site.Script/Scripts/react/website-apps/shared/components/content/SiteContentProvider.js&version=GBmain&line=25&lineEnd=26&lineStartColumn=1&lineEndColumn=1&lineStyle=plain&_a=contents
const contentResolver = (content: ContentResolverParams) =>
  Array.isArray(content)
    ? content.map(({ id, defaultValue }) => defaultValue || id)
    : content.defaultValue || content.id;
