import type { ToolbarSlot } from '@react-pdf-viewer/toolbar';
import { clsx } from 'clsx';
import {
  DocumentFileUploadInfo,
  MappedTicketInfo,
} from 'src/components/UploadArtifacts/UploadETicketsV2';
import { useUploadETicketsContext } from 'src/components/UploadArtifacts/UploadETicketsV2/UploadETicketsContext';
import { UploadETicketsDraggableSource } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Draggable/constants';
import { PreviewInfo } from 'src/components/UploadArtifacts/UploadETicketsV2/views/PreviewModal/PreviewInfo/PreviewInfo';
import { PreviewModalNavigationFooter } from 'src/components/UploadArtifacts/UploadETicketsV2/views/PreviewModal/PreviewModalNavigationFooter/PreviewModalNavigationFooter';
import {
  UploadETicketsDraggableData,
  UploadETicketsDraggableOrigin,
} from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/UploadETicketsDraggableData';
import { ImageMagnifier } from 'src/core/POS/ImageMagnifier';
import { PosDraggable, PosDraggableIndicator } from 'src/core/POS/PosDragNDrop';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { DocumentPage } from 'src/WebApiController';

import * as styles from './UploadETicketsPreviewTicketModalBody.css';

const toolbarSlot: Partial<ToolbarSlot> = {
  ShowSearchPopover: () => <></>,
  NumberOfPages: () => <></>,
  GoToPreviousPage: () => <></>,
  GoToNextPage: () => <></>,
  CurrentPageInput: () => <></>,
};

const noop = () => {};

export const UploadETicketsPreviewTicketModalBody = () => {
  const isLargeDesktop = useMatchMedia('largeDesktop');
  const isMobile = useMatchMedia('mobile');
  const {
    selectedUniquePageId,
    hoverUniquePageId,
    uniquePageIdToDocumentFileUploadInfoMap,
    pageUniqueIdToTicketMap,
    entityWithTickets,
  } = useUploadETicketsContext();

  let page: DocumentPage | undefined;
  let file: File | undefined;
  let mappedTicketInfo: MappedTicketInfo | undefined;
  let pageInfo:
    | {
        documentFileUploadInfo: DocumentFileUploadInfo;
        page: DocumentPage;
      }
    | undefined;

  const activeUniquePageId = hoverUniquePageId || selectedUniquePageId;

  if (activeUniquePageId) {
    pageInfo = uniquePageIdToDocumentFileUploadInfoMap[activeUniquePageId];
    if (pageInfo) {
      pageInfo = uniquePageIdToDocumentFileUploadInfoMap[activeUniquePageId];
      page = pageInfo.page;
      file = pageInfo.documentFileUploadInfo.file;
      mappedTicketInfo = pageUniqueIdToTicketMap[activeUniquePageId];
    }
  }

  if (!page || !file || !pageInfo) {
    return null;
  }
  const pages = pageInfo.documentFileUploadInfo.pages;
  if (!pages || pages.length === 0) {
    return null;
  }

  const pdfClassName = isLargeDesktop
    ? styles.pdfViewerLargeDesktop
    : !isMobile
    ? styles.pdfViewerDesktop
    : undefined;

  return (
    <div
      className={clsx(styles.modalBodyWrapper, {
        [styles.modalBodyWrapperMobile]: isMobile,
      })}
    >
      <PosDraggable<UploadETicketsDraggableData>
        className={styles.draggable}
        draggableSource={UploadETicketsDraggableSource}
        draggableData={{
          isSinglePageDocument: pages.length === 1,
          origin: UploadETicketsDraggableOrigin.PreviewModal,
          componentProps: {
            page,
            pageIndex: pages.indexOf(page) ?? 0,
            entityWithTickets: entityWithTickets!,
            documentFileUploadInfo: pageInfo.documentFileUploadInfo,
            pageUniqueIdToTicketMap,
            selectedUniquePageId,
            onMouseEntersPage: noop,
            onMouseLeavesPage: noop,
          },
        }}
      >
        <PosDraggableIndicator
          classNames={styles.draggableIndicator}
          removeHoverEffect={true}
        >
          <div className={styles.container}>
            <div className={clsx(styles.infoWrapper)}>
              <PreviewInfo
                fileName={file.name}
                mappedTicketInfo={mappedTicketInfo}
                section={entityWithTickets?.seating?.section}
              />
            </div>
            <div className={pdfClassName}>
              <ImageMagnifier
                imageSrc={page.thumbUrl}
                magnifierSize="50%"
                pdfViewerClassnames={pdfClassName}
                toolbarSlot={toolbarSlot}
              />
            </div>
            <PreviewModalNavigationFooter />
          </div>
        </PosDraggableIndicator>
      </PosDraggable>
    </div>
  );
};
