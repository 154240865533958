import clsx from 'clsx';
import { ReactNode } from 'react';
import { CoBrandId } from 'src/WebApiController';

import * as styles from './MainNavTopBar.css';

type NavTitleProps = {
  children: ReactNode;
  cobrandId?: CoBrandId;
  isActive?: boolean;
};

export const NavTitle = ({ children, cobrandId, isActive }: NavTitleProps) => {
  return (
    <div
      className={clsx(styles.navTitle, {
        [styles.navTitleTicketUtil]: cobrandId === CoBrandId.TicketUtil,
        [styles.navTitleActive]: !!isActive,
      })}
    >
      {children}
    </div>
  );
};
