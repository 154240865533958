import { Link } from 'react-router-dom';
import { MetricTile } from 'src/components/Metrics';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { getNextDateRange } from 'src/utils/dateTimeUtils';
import { DateTimeRangeWithRelative, ListingQuery } from 'src/WebApiController';

import * as styles from './MetricsSection.css';

export function MetricsSection() {
  const next2WeeksRange = getNextDateRange({ weeks: 2 }, new Date());
  const metrics = [
    {
      key: 'unsoldTickets',
      title: <Content id={ContentId.UnsoldTickets} />,
      href: {
        pathname: '/inventory',
        search: new URLSearchParams({
          quickFilterId: 'unsoldTickets',
          mainFilter: JSON.stringify({
            hasUnsoldTickets: true,
          } satisfies Partial<ListingQuery>),
        }).toString(),
      },
    },
    {
      key: 'unbroadcasted',
      title: <Content id={ContentId.Unbroadcasted} />,
      href: {
        pathname: '/inventory',
        search: new URLSearchParams({
          mainFilter: JSON.stringify({
            isListed: false,
          } satisfies Partial<ListingQuery>),
        }).toString(),
      },
    },
    {
      key: 'next2Weeks',
      title: (
        <>
          🔥 <Content id={ContentId.Next2Weeks} />
        </>
      ),
      href: {
        pathname: '/inventory',
        search: new URLSearchParams({
          quickFilterId: 'next2Weeks',
          mainFilter: JSON.stringify({
            eventDates: {
              start: next2WeeksRange.start.toISOString(),
              end: next2WeeksRange.end.toISOString(),
            } as DateTimeRangeWithRelative,
          } satisfies Partial<ListingQuery>),
        }).toString(),
      },
    },
    {
      key: 'broadcastError',
      title: <Content id={ContentId.BroadcastError} />,
      href: {
        pathname: '/inventory',
        search: new URLSearchParams({
          quickFilterId: 'broadcastError',
          mainFilter: JSON.stringify({
            hasBroadcastError: true,
          } satisfies Partial<ListingQuery>),
        }).toString(),
      },
    },
  ];
  return (
    <Stack gap="xl" flexWrap="wrap">
      {metrics.map(({ key, title, href }) => {
        return (
          <MetricTile.Root key={key} className={styles.tile}>
            <MetricTile.Title>{title}</MetricTile.Title>
            <Link className={styles.link} to={href}>
              <Content id={ContentId.SeeAll} /> &gt;
            </Link>
          </MetricTile.Root>
        );
      })}
    </Stack>
  );
}
