import { useCallback } from 'react';
import {
  ActionOutboxEntityTypeForBulkEdit,
  bulkEditInProgress,
  hasPrimaryAffectedIds,
} from 'src/components/common/BulkActions/BulkEditStatus';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { Content } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import {
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
} from 'src/WebApiController';

export const BulkEditFooter = ({
  entityType,
  primaryEntityTypesAffected,
  isLoading,
  hasBackgroundBulkEditFeature,
  onClose,
  onSubmit,
  onBeforeSubmit,
  disabled,
  submittButtonRef,
  onCloseContentIdOverride,
  onSubmitContentIdOverride,
}: {
  entityType: ActionOutboxEntityTypeForBulkEdit;
  primaryEntityTypesAffected?: ActionOutboxEntityTypeForBulkEdit[];
  isLoading?: boolean;
  hasBackgroundBulkEditFeature: boolean;
  onBeforeSubmit?: () => boolean;
  onSubmit: (
    onPreviewCallback?: (preview: BulkEditPreviewWithDetails) => void
  ) => void;
  onClose: (
    newProgress?: BulkEditProgress,
    closeMainDialogForPopover?: boolean
  ) => void;
  disabled?: boolean;
  submittButtonRef: React.RefObject<HTMLButtonElement>;
  onCloseContentIdOverride?: ContentId;
  onSubmitContentIdOverride?: ContentId;
}) => {
  const {
    stage,
    progress,
    preview,
    setStage,
    setPreview,
    setErrors,
    setWarnings,
    retryableEntities,
    progressBeforeRetry,
    onRetryCancel,
  } = useBulkEditHubContext();

  const onCloseHandler = useCallback(
    (newProgress?: BulkEditProgress) => {
      if (stage === BulkEditStage.Preview) {
        setPreview(undefined);
        setStage(BulkEditStage.Idle);
      } else {
        if (
          stage === BulkEditStage.Idle &&
          retryableEntities &&
          progressBeforeRetry
        ) {
          onRetryCancel();
        }
        onClose(newProgress);
      }
    },
    [
      onClose,
      onRetryCancel,
      progressBeforeRetry,
      retryableEntities,
      setPreview,
      setStage,
      stage,
    ]
  );

  const onSubmitHandler = useCallback(() => {
    if (onBeforeSubmit && !onBeforeSubmit()) {
      return;
    }

    if (stage === BulkEditStage.Idle) {
      setStage(BulkEditStage.GettingPreview);
      onSubmit((preview) => {
        setPreview(preview);
        setStage(BulkEditStage.Preview);
      });
    } else {
      setStage(BulkEditStage.Execute);
      setErrors([]); // clear errors on start/retry
      setWarnings([]); // clear warnings on start/retry
      onSubmit();
    }
  }, [
    onBeforeSubmit,
    onSubmit,
    setErrors,
    setPreview,
    setStage,
    setWarnings,
    stage,
  ]);

  return (
    <>
      <Button variant="outline" onClick={() => onCloseHandler(progress)}>
        {onCloseContentIdOverride ? (
          <Content id={onCloseContentIdOverride} />
        ) : stage === BulkEditStage.Idle &&
          retryableEntities &&
          progressBeforeRetry ? (
          <Content id={ContentId.Done} />
        ) : stage === BulkEditStage.GettingPreview ||
          stage === BulkEditStage.Preview ? (
          <Content id={ContentId.Back} />
        ) : hasBackgroundBulkEditFeature && progress ? (
          <Content id={ContentId.Close} />
        ) : (
          <Content id={ContentId.Cancel} />
        )}
      </Button>

      {(progress?.step !== BulkEditStep.Done ||
        Boolean(progress?.failureCount)) && (
        <Button
          ref={submittButtonRef}
          disabled={
            disabled ||
            stage === BulkEditStage.GettingPreview ||
            stage === BulkEditStage.Execute ||
            (stage === BulkEditStage.Preview &&
              !hasPrimaryAffectedIds(
                preview,
                primaryEntityTypesAffected ?? entityType
              )) ||
            bulkEditInProgress(
              hasBackgroundBulkEditFeature,
              isLoading,
              progress
            )
          }
          onClick={onSubmitHandler}
        >
          {onSubmitContentIdOverride ? (
            <Content id={onSubmitContentIdOverride} />
          ) : stage === BulkEditStage.Idle &&
            retryableEntities &&
            progressBeforeRetry ? (
            <Content id={ContentId.RetrySkipped} />
          ) : stage === BulkEditStage.Idle ||
            stage === BulkEditStage.GettingPreview ? (
            <Content id={ContentId.Preview} />
          ) : stage === BulkEditStage.Preview ||
            stage === BulkEditStage.Execute ? (
            <Content id={ContentId.Confirm} />
          ) : progress?.failureCount ? (
            <Content id={ContentId.Retry} />
          ) : (
            <Content id={ContentId.Save} />
          )}
        </Button>
      )}
    </>
  );
};
