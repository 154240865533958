import { useFormContext } from 'react-hook-form';
import { Switch } from 'src/core/ui';

import { SellerAccountEventSettingsInput } from './FulfillmentSection.types';

export const AutoFulfillCell = ({
  disabled,
  index,
}: {
  disabled?: boolean;
  index: number;
}) => {
  const { watch, setValue } = useFormContext<SellerAccountEventSettingsInput>();

  const isNoFulfill = watch(`settings.${index}.isNoFulfill`);

  return (
    <Switch
      checked={!isNoFulfill}
      disabled={disabled}
      onChange={(e) => e.stopPropagation()}
      onCheckedChange={(isChecked) => {
        setValue(`settings.${index}.isNoFulfill`, !isChecked, {
          shouldDirty: true,
        });
      }}
    />
  );
};
