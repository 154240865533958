import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { PurchaseInfoFilter } from 'src/components/MainFilterBar/usePurchaseInfoFilters';
import { useStandalonePurchaseVendorAccountSelector } from 'src/components/Selectors/PurchaseVendorAccountSelector/StandaloneMultiplePurchaseVendorAccountSelector/useStandalonePurchaseVendorAccountSelector';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { removeDuplicates } from 'src/utils/miscUtils';
import { PurchaseVendorAccount } from 'src/WebApiController';

const triggerStyles = { style: { width: '100%' } };

interface StandalonePurchaseVendorAccountsSelectorProps<
  TFilter extends PurchaseInfoFilter,
> {
  query: TFilter;
  setQuery: (query: TFilter) => void;
}

export const StandalonePurchaseVendorAccountsSelector = <
  TFilter extends PurchaseInfoFilter,
>({
  query,
  setQuery,
}: StandalonePurchaseVendorAccountsSelectorProps<TFilter>) => {
  const { purchaseVendorAccountIds } = query;

  const {
    disabled,
    purchaseVendorAccountsByVendorIds: purchaseVendorAccountsMap,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    onSearchTextChange,
  } = useStandalonePurchaseVendorAccountSelector({
    vendorAccountIds: purchaseVendorAccountIds || undefined,
  });

  const [selectedPurchaseVendorAccounts, setSelectedPurchaseVendorAccounts] =
    useState<Record<string, PurchaseVendorAccount[]>>({});

  // Combines the purchaseVendorAccountsMap that gets updated after search with the selectedPurchaseVendorAccounts
  const purchaseVendorAccountsMapWithSelected = useMemo(() => {
    return {
      ...purchaseVendorAccountsMap,
      ...selectedPurchaseVendorAccounts,
    };
  }, [purchaseVendorAccountsMap, selectedPurchaseVendorAccounts]);

  const multiSelectOptions = useMemo(() => {
    return Object.keys(purchaseVendorAccountsMapWithSelected).reduce<
      Record<string, string>
    >((map, email) => {
      map[email] = email;
      return map;
    }, {});
  }, [purchaseVendorAccountsMapWithSelected]);

  const selectedPurchaseVendorAccountEmails = useMemo(() => {
    return Object.keys(selectedPurchaseVendorAccounts);
  }, [selectedPurchaseVendorAccounts]);

  useEffect(() => {
    const allPurchaseVendorAccounts = Object.values(
      purchaseVendorAccountsMapWithSelected
    ).flat();

    // Get all emails from accountIds
    const emailAccounts =
      (purchaseVendorAccountIds
        ?.map((purchaseVendorAccountId) => {
          return allPurchaseVendorAccounts.find(
            (purchaseVendorAccount) =>
              purchaseVendorAccount.id === purchaseVendorAccountId
          );
        })
        .map((account) => account?.email)
        .filter((email) => !!email) as string[]) ?? [];

    const cleanEmailAccounts = removeDuplicates(emailAccounts);
    if (isEqual(cleanEmailAccounts, selectedPurchaseVendorAccountEmails)) {
      return;
    }

    const cleanPurchaseVendorAccounts = Object.entries(
      purchaseVendorAccountsMapWithSelected
    ).reduce(
      (acc, [key, value]) => {
        if (cleanEmailAccounts.includes(key)) {
          acc[key] = value;
        }
        return acc;
      },
      {} as { [x: string]: PurchaseVendorAccount[] }
    );

    setSelectedPurchaseVendorAccounts(cleanPurchaseVendorAccounts);
  }, [
    purchaseVendorAccountIds,
    purchaseVendorAccountsMapWithSelected,
    selectedPurchaseVendorAccountEmails,
  ]);

  return (
    <PosMultiSelect
      triggerProps={triggerStyles}
      fullWidth={true}
      searchable={true}
      values={selectedPurchaseVendorAccountEmails}
      onChange={(purchaseVendorAccountEmails: string[]) => {
        const purchaseVendorAccounts = purchaseVendorAccountEmails.flatMap(
          (email) => purchaseVendorAccountsMapWithSelected[email]
        );
        const purchaseVendorAccountIds = purchaseVendorAccounts.map(
          (purchaseVendorAccount) => purchaseVendorAccount.id
        );
        const cleanPurchaseVendorAccountIds = removeDuplicates(
          purchaseVendorAccountIds
        );
        setQuery({
          ...query,
          purchaseVendorAccountIds:
            cleanPurchaseVendorAccountIds.length > 0
              ? cleanPurchaseVendorAccountIds
              : null,
        });

        const adaptPurchaseVendorAccountsToPurchaseVendorAccountsMap =
          purchaseVendorAccounts.reduce<
            Record<string, PurchaseVendorAccount[]>
          >((acc, purchaseVendorAccount) => {
            const email = purchaseVendorAccount.email;

            if (!acc[email]) {
              acc[email] = [];
            }

            acc[email].push(purchaseVendorAccount);

            return acc;
          }, {});

        setSelectedPurchaseVendorAccounts(
          adaptPurchaseVendorAccountsToPurchaseVendorAccountsMap
        );
      }}
      onSearchTextChange={onSearchTextChange}
      valueOptionsContent={multiSelectOptions}
      enableEmptySelection
      placeholderText={ContentId.AllVendorAccounts}
      disabled={disabled}
      isFetchingNextPage={isFetchingNextPage}
      hasNextPage={hasNextPage}
      fetchNextPage={fetchNextPage}
      showSelectedOptionsFirst={true}
      includeSelectedInSearch={true}
      sortMode={'none'}
    />
  );
};
