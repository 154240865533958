import { debounce, range } from 'lodash-es';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { VenueRowInput } from 'src/components/Input/VenueRowInput';
import { VenueSectionInput } from 'src/components/Input/VenueSectionInput';
import {
  Content,
  FormatContent,
  useContent,
  useFormattedContent,
} from 'src/contexts/ContentContext';
import { useGetSectionRowFromVenueMapConfig } from 'src/contexts/EventMapContext';
import { FormPage } from 'src/core/POS/FormPage';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Switch } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserCanUpdatePurchaseSeatTraits } from 'src/hooks/useUserHasPurchasePermissions';
import { FieldWrapper, FormRow } from 'src/modals/common';
import * as styles from 'src/modals/common/Purchase/PurchaseModal.css';
import { SeatTraitsSelectionInput } from 'src/modals/common/Purchase/SeatTraitsSelectionInput';
import { IconsFill, SeatIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { INT_MAX_VALUE } from 'src/utils/numberFormatter';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { getNextTicketBy } from 'src/utils/ticketUtils';
import {
  EventListingConstraints,
  Ticket,
  TicketGroupInput,
} from 'src/WebApiController';

import { PurchaseOrderInfoForTicketGroupInput } from '../TicketGroupInput.types';

export const SeatingSection = ({
  ticketGroupIndex,
  disabled,
  listingConstraints,
  purchaseOrderInfo,
}: {
  ticketGroupIndex: number;
  disabled?: boolean;
  listingConstraints?: EventListingConstraints | null;
  purchaseOrderInfo?: PurchaseOrderInfoForTicketGroupInput | null;
}) => {
  const { setValue, formState, getFieldState, clearErrors, watch } =
    useFormContext<PurchaseTicketsInput>();

  const canSetSeatTraits =
    useUserCanUpdatePurchaseSeatTraits(purchaseOrderInfo);

  const initialTicketGroup = formState.defaultValues?.ticketGroups?.[ticketGroupIndex];
  const ticketGroup = watch(`ticketGroups.${ticketGroupIndex}`);

  const ticketCountField: `ticketGroups.${number}.numberOfTickets` = `ticketGroups.${ticketGroupIndex}.numberOfTickets`;

  const sectionField: `ticketGroups.${number}.section` = `ticketGroups.${ticketGroupIndex}.section`;
  const sectionIdField: `ticketGroups.${number}.sectionId` = `ticketGroups.${ticketGroupIndex}.sectionId`;
  const rowField: `ticketGroups.${number}.row` = `ticketGroups.${ticketGroupIndex}.row`;
  const rowIdField: `ticketGroups.${number}.rowId` = `ticketGroups.${ticketGroupIndex}.rowId`;
  const allocateSeatsField: `ticketGroups.${number}.allocateSeats` = `ticketGroups.${ticketGroupIndex}.allocateSeats`;
  const seatIncrementTypeField: `ticketGroups.${number}.seatIncementType` = `ticketGroups.${ticketGroupIndex}.seatIncementType`;

  const ticketCountError = getFieldState(ticketCountField, formState).error
    ?.message;
  const sectionError = getFieldState(sectionField, formState).error?.message;
  const rowError = getFieldState(rowField, formState).error?.message;
  const seatErrors: (string | undefined)[] = [];

  ticketGroup.tickets?.value?.forEach((_, i) => {
    const seatError = getFieldState(
      `ticketGroups.${ticketGroupIndex}.tickets.value.${i}.seat`,
      formState
    ).error?.message;
    seatErrors.push(seatError);
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks,react-hooks/exhaustive-deps
  const allocSeats = useCallback(
    debounce((ticketCnt: number) => {
      const curTicketOrdinals =
        ticketGroup.tickets?.value?.map((t) => t.ord) ?? [];

      const startingOrdinal =
        curTicketOrdinals.length > 0
          ? Math.min(...(ticketGroup.tickets?.value?.map((t) => t.ord) ?? [0]))
          : 0;

      setValue(
        `ticketGroups.${ticketGroupIndex}.tickets`,
        posChangedField(
          ticketCnt
            ? range(ticketCnt).map((index) => {
                const ordinal = startingOrdinal + index;
                // Try to preserve previous seating
                let prevTicket = ticketGroup.tickets?.value?.find(
                  (t) => t.ord === ordinal
                );
                if (!prevTicket && initialTicketGroup?.tickets?.value) {
                  // If the user clear the quantity - ticketGroup.tickets would be empty and so
                  // there's no chance to retrieve the previous tickets, so we need to look at the
                  // default value
                  prevTicket = initialTicketGroup.tickets.value.find(
                    (t) => t!.ord === ordinal
                  ) as Ticket;
                }
                return {
                  id: -ordinal,
                  ord: ordinal,
                  row: ticketGroup?.row?.value,
                  ...(prevTicket ?? {}),
                  seat: prevTicket?.seat ?? '',
                } as Ticket;
              })
            : []
        )
      );
    }, 600),
    [setValue, ticketGroup?.row, ticketGroup.tickets, ticketGroupIndex]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setRowForTickets = useCallback(
    debounce((row: string) => {
      if (ticketGroup.tickets?.value) {
        setValue(
          `ticketGroups.${ticketGroupIndex}.tickets`,
          posChangedField(
            ticketGroup.tickets.value.map((t) => ({ ...t, row: row })) ?? null
          )
        );
      }
    }, 600),
    [setValue, ticketGroup.tickets, ticketGroupIndex]
  );

  const isMobile = useMatchMedia('mobile');

  const getOrSetDefaultAllocateSeats = (tg: TicketGroupInput) => {
    if (tg.allocateSeats == null) {
      tg.allocateSeats = Boolean(
        (ticketGroup.tickets?.value?.length &&
          ticketGroup.tickets!.value.find((t) => t.seat)) ||
          !ticketGroup.tickets?.value?.length
      );
    }
    return tg.allocateSeats;
  };

  useEffect(() => {
    if (
      getOrSetDefaultAllocateSeats(ticketGroup) &&
      !ticketGroup.tickets?.value?.length
    ) {
      allocSeats(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const increaseNone = useContent(ContentId.None);
  const increaseBy1Format = useFormattedContent(
    FormatContentId.NIncreaseBy,
    '1'
  );
  const increaseBy2Format = useFormattedContent(
    FormatContentId.NIncreaseBy,
    '2'
  );
  const increaseByValues = {
    '0': increaseNone as string,
    '1': increaseBy1Format as string,
    '2': increaseBy2Format as string,
  };

  const getOrSetDefaultSeatIncrementType = (tg: TicketGroupInput) => {
    if (!tg.seatIncementType && tg.seatIncementType !== 0) {
      tg.seatIncementType = 1;
    }
    return tg.seatIncementType?.toString();
  };

  const setSeatsIncrement = debounce((seatIndex: number) => {
    if (
      ticketGroup.seatIncementType &&
      ticketGroup.seatIncementType !== 0 &&
      ticketGroup.tickets?.value?.length &&
      seatIndex < ticketGroup.tickets.value.length - 1 &&
      ticketGroup.tickets.value[seatIndex].seat
    ) {
      let seatValue = ticketGroup.tickets.value[seatIndex].seat;
      for (
        let index = seatIndex + 1;
        index < ticketGroup.tickets.value.length;
        index++
      ) {
        const ticket = ticketGroup.tickets.value[index];

        seatValue = getNextTicketBy(
          seatValue,
          ticketGroup.seatIncementType
        ).toString();
        setSeatValue(seatValue, index, ticket);
      }
    }
  }, 500);

  const setSeatValue = (
    seatValue: string,
    seatIndex: number,
    ticket: Ticket
  ) => {
    if (seatValue) {
      clearErrors(
        `ticketGroups.${ticketGroupIndex}.tickets.value.${seatIndex}.seat`
      );
    }

    if (seatValue !== ticket.seat) {
      setValue(
        `ticketGroups.${ticketGroupIndex}.tickets.value.${seatIndex}.seat`,
        seatValue
      );
      setValue(`ticketGroups.${ticketGroupIndex}.tickets.hasChanged`, true);
    }
  };

  const { section: selectedSection, row: selectedRow } =
    useGetSectionRowFromVenueMapConfig(
      ticketGroup?.rowId?.value,
      ticketGroup?.sectionId?.value,
      ticketGroup?.section?.value,
      ticketGroup?.row?.value
    );

  return (
    <FormPage.Section
      icon={<SeatIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />}
      title={<Content id={ContentId.Seating} />}
    >
      <FormRow vertical={isMobile}>
        <FieldWrapper width="max-content">
          <PosFormField
            errors={ticketCountError}
            label={<Content id={ContentId.Quantity} />}
          >
            <PosTextField
              disabled={disabled}
              rootProps={{
                state: getTextFieldState(ticketCountError),
              }}
              type="number"
              inputMode="numeric"
              value={ticketGroup.numberOfTickets?.value || ''}
              onChange={(e) => {
                const v = parseInt(e.target.value) || 0;
                if (v >= 0 && v <= INT_MAX_VALUE) {
                  clearErrors(ticketCountField);

                  if (v !== ticketGroup.numberOfTickets?.value) {
                    allocSeats(v);
                    setValue(ticketCountField, posChangedField(v));
                  }
                }
              }}
            />
          </PosFormField>
        </FieldWrapper>
        <PosFormField label={<Content id={ContentId.AllocatedSeats} />}>
          <br />
          <Switch
            disabled={disabled}
            checked={getOrSetDefaultAllocateSeats(ticketGroup)}
            onCheckedChange={(isChecked) => {
              setValue(allocateSeatsField, isChecked);
              allocSeats(ticketGroup.numberOfTickets?.value ?? 0);
            }}
          />
        </PosFormField>
      </FormRow>

      <FormRow>
        <FieldWrapper width="50%">
          <PosFormField
            errors={sectionError}
            label={<Content id={ContentId.Section} />}
          >
            <VenueSectionInput
              fieldError={sectionError}
              section={ticketGroup.section?.value}
              selectedSection={selectedSection}
              disabled={disabled}
              onChange={(sectionName, section) => {
                clearErrors(sectionField);
                if (section) {
                  setValue(sectionField, posChangedField(section.name));
                  setValue(sectionIdField, posChangedField(section.id));

                  if (section.specRow?.id) {
                    // Setting the section automatically set the speculative row id
                    setValue(rowIdField, posChangedField(section.specRow?.id));
                    setValue(rowField, posChangedField(''));
                    // changing the row changes all the tickets
                    setRowForTickets('');
                  }
                } else {
                  setValue(sectionField, posChangedField(sectionName ?? ''));
                  setValue(sectionIdField, posChangedField(null));

                  // Free-text section do not have speculative row info
                  setValue(rowIdField, posChangedField(null));
                  setValue(rowField, posChangedField(''));
                  setRowForTickets('');
                }
              }}
            />
          </PosFormField>
        </FieldWrapper>
        {ticketGroup.allocateSeats && (
          <FieldWrapper width="50%">
            <PosFormField
              errors={rowError}
              label={<Content id={ContentId.Row} />}
            >
              <VenueRowInput
                fieldError={rowError}
                row={ticketGroup.row?.value}
                selectedRow={selectedRow}
                selectedSection={selectedSection}
                disabled={disabled}
                onChange={(rowName, row) => {
                  clearErrors(rowField);
                  if (row) {
                    setValue(
                      rowField,
                      posChangedField(row.isSpeculative ? '' : row.name)
                    );
                    setValue(rowIdField, posChangedField(row.id));
                    setRowForTickets((row.isSpeculative ? '' : row.name) ?? '');
                  } else {
                    // If we can't find the row - that mean they typed it in
                    // so just keep with they type as the row
                    setValue(rowField, posChangedField(rowName ?? ''));
                    setValue(rowIdField, posChangedField(null));
                    setRowForTickets(rowName ?? '');
                  }
                }}
              />
            </PosFormField>
          </FieldWrapper>
        )}
      </FormRow>

      {ticketGroup.allocateSeats && (
        <>
          <FormRow vertical={isMobile}>
            <FieldWrapper width="max-content">
              <PosFormField
                label={<Content id={ContentId.SeatIncrementType} />}
              >
                <PosSelect
                  disabled={disabled}
                  onChange={(e) => {
                    if (e) {
                      const v = parseInt(e) || 0;
                      setValue(seatIncrementTypeField, v);
                    } else {
                      setValue(seatIncrementTypeField, null);
                    }
                  }}
                  value={getOrSetDefaultSeatIncrementType(ticketGroup)}
                  placeholderText={ContentId.ChooseIncrementType}
                  valueOptionsContent={increaseByValues}
                />
              </PosFormField>
            </FieldWrapper>
          </FormRow>

          <FormRow>
            <FieldWrapper width="100%">
              <div className={styles.fieldWrapperList}>
                {ticketGroup.tickets?.value?.map((t, i) => (
                  <FieldWrapper key={t.id}>
                    <PosFormField
                      errors={seatErrors[i]}
                      label={
                        <FormatContent
                          id={FormatContentId.SeatNumber}
                          params={`${t.ord + 1}`}
                        />
                      }
                    >
                      <PosTextField
                        disabled={disabled}
                        rootProps={{
                          state: getTextFieldState(seatErrors[i]),
                        }}
                        key={t.ord}
                        spellCheck={false}
                        value={t.seat || ''}
                        onChange={(e) => {
                          const v = e.target.value;
                          setSeatValue(v, i, t);
                          setSeatsIncrement(i);
                        }}
                      />
                    </PosFormField>
                  </FieldWrapper>
                ))}
              </div>
            </FieldWrapper>
          </FormRow>
        </>
      )}
      <FormRow>
        <FieldWrapper width="max-content" addFlexGrow>
          <PosFormField label={<Content id={ContentId.SeatTraits} />}>
            <SeatTraitsSelectionInput
              triggerProps={{ style: { width: '100%' } }}
              disabled={disabled || !canSetSeatTraits}
              ticketGroupIndex={ticketGroupIndex}
              validListingNoteIds={listingConstraints?.validListingNoteIds}
            />
          </PosFormField>
        </FieldWrapper>
      </FormRow>
    </FormPage.Section>
  );
};
