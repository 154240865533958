import { formatInTimeZone } from 'date-fns-tz';
import { useFormContext } from 'react-hook-form';
import { InHandDateDisplay } from 'src/components/common/InHandDateDisplay';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { Stack, Switch, TooltipPopover } from 'src/core/ui';
import { useMarketplaceHandledByProcessor } from 'src/hooks/useMarketplaceHandledByProcessor';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  useUserCanUpdateSale,
  useUserCanViewFulfillmentArtifacts,
} from 'src/hooks/useUserHasSalePermissions';
import { Detail, DetailGroup } from 'src/modals/common';
import { modalSection } from 'src/modals/common/Modals.css';
import * as ticketsStyles from 'src/modals/TransferTickets/TransferTickets.css';
import { ViewTickets } from 'src/modals/ViewTickets';
import { IconsFill, WarningOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { TICKET_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  getSaleDetailsModalConfigWithDeepLink,
  getSaleStatusDisplayContentId,
} from 'src/utils/saleUtils';
import {
  getTicketTypeIcon,
  getTicketTypeIcons,
  getTicketTypeLabel,
} from 'src/utils/ticketTypeUtils';
import {
  Feature,
  Marketplace,
  MarketplaceFulfillmentState,
  PointOfSaleSaleStatus,
  SaleDetails,
  SaleInput,
} from 'src/WebApiController';

import * as styles from '../SaleDetails.css';
/**
 * Set of sale statuses that allow for changing ticket type.
 */
const ALLOWED_TICKET_TYPE_CHANGE_SALE_STATUSES = new Set([
  PointOfSaleSaleStatus.PendingConfirmation,
  PointOfSaleSaleStatus.PendingFulfillment,
  PointOfSaleSaleStatus.PendingReshipment,
  PointOfSaleSaleStatus.FulfillmentFailed,
  PointOfSaleSaleStatus.Hold,
  PointOfSaleSaleStatus.CancelledHold,
]);

export type DeliverySectionProps = {
  sale?: SaleDetails | null;
  isBulkEdit?: boolean;
  disabled?: boolean;
};

export const DeliverySection = ({
  sale,
  isBulkEdit,
  disabled,
}: DeliverySectionProps) => {
  const { timeZone } = useSiteTimezoneContext();
  const canEditSale = useUserCanUpdateSale(sale) || isBulkEdit;
  const canViewFulfillmentArtifacts = useUserCanViewFulfillmentArtifacts(sale);
  const hasFufillmentMismatchDisplay = useUserHasFeature(
    Feature.FufillmentMismatchDisplay
  );

  const { watch, setValue } = useFormContext<SaleInput>();

  const ticketType = watch('ticketType');
  const isNoFulfill = watch('isNoFulfill');

  let InHandInfo = null;
  let HoldExpirationInfo = null;

  if (sale?.holdExpiredOn && status === PointOfSaleSaleStatus.Hold) {
    const holdExpirationDateAsDate = stringToUtcDate(sale.holdExpiredOn);

    const holdExpirationDateString = holdExpirationDateAsDate
      ? formatInTimeZone(
          holdExpirationDateAsDate,
          timeZone,
          'MMM d, yyyy HH:mm:ss',
          {
            locale: getLocaleFromLanguageOrCurrent(),
          }
        )
      : undefined;

    HoldExpirationInfo = (
      <Detail
        label={<Content id={ContentId.HoldExpiration} />}
        detail={holdExpirationDateString}
      />
    );
  }

  if (sale?.inHandAt) {
    const date = new Date(sale.inHandAt);

    const dateString = formatInTimeZone(date, timeZone, '(MMM d, yyyy)', {
      locale: getLocaleFromLanguageOrCurrent(),
    });

    const needsToFulfill = Boolean(
      sale?.status &&
        [
          PointOfSaleSaleStatus.Fulfilled,
          PointOfSaleSaleStatus.ProcessingFulfillment,
          PointOfSaleSaleStatus.Rejected,
          PointOfSaleSaleStatus.PendingRejection,
        ].includes(sale.status)
    );

    InHandInfo = (
      <Detail
        label={<Content id={ContentId.InHand} />}
        detail={
          <div className={styles.inHandWrapper}>
            <InHandDateDisplay
              date={date}
              needsToFulfill={needsToFulfill}
              handleFulfillmentState
            />
            {dateString}
          </div>
        }
      />
    );
  } else {
    InHandInfo = (
      <Detail
        label={<Content id={ContentId.InHand} />}
        detail={<Content id={ContentId.NA} />}
      />
    );
  }

  const cancelTo = getSaleDetailsModalConfigWithDeepLink(sale?.id);

  const hasAutoFulfillFeature = useUserHasFeature(Feature.AutoFulfillSetting);
  const saleIsHandledByProcessor = useMarketplaceHandledByProcessor(sale?.mkp);

  const canChangeTicketType =
    isBulkEdit ||
    (sale &&
      (sale.mkp === Marketplace.StubHub || saleIsHandledByProcessor) &&
      ALLOWED_TICKET_TYPE_CHANGE_SALE_STATUSES.has(sale.status));

  const cantChangeTicketTypeTooltip = useContent(
    ContentId.SaleTicketTypeWarning
  );

  return (
    <div className={modalSection}>
      <DetailGroup>
        <Detail
          label={<Content id={ContentId.TicketType} />}
          detail={
            canEditSale ? (
              <Stack direction="column" gap="m">
                {isBulkEdit && (
                  <WarningMessage
                    message={
                      <Content id={ContentId.SaleTicketTypeBulkEditWarning} />
                    }
                  />
                )}
                <PosEnumSelect
                  style={{ width: '100%' }}
                  disabled={disabled || !canChangeTicketType}
                  placeholderText={
                    isBulkEdit ? ContentId.DontChange : ContentId.AllTicketTypes
                  }
                  title={
                    !canChangeTicketType && !isBulkEdit
                      ? cantChangeTicketTypeTooltip
                      : undefined
                  }
                  enableEmptySelection={isBulkEdit}
                  valueOptionsContent={TICKET_TYPE_TO_CID}
                  valueOptionsIcon={getTicketTypeIcons(IconsFill.textPrimary)}
                  value={ticketType?.value}
                  onChange={(newTicketType) => {
                    setValue('ticketType', posChangedField(newTicketType), {
                      shouldDirty: true,
                    });
                  }}
                />
              </Stack>
            ) : (
              <div className={styles.ticketTypeDiv}>
                {getTicketTypeIcon(
                  ticketType?.value ?? null,
                  IconsFill.textPrimary
                )}
                <div className={styles.dataText}>
                  {getTicketTypeLabel(ticketType?.value ?? null)}
                </div>
              </div>
            )
          }
        />
        {!isBulkEdit &&
          Boolean(sale?.availArtTypes?.length) &&
          canViewFulfillmentArtifacts && (
            <ViewTickets
              // If passing null we show "This order is fulfilled on {marketplace}..."
              // prompt when the state is fulfilled but there's no artifact type available.
              type={sale!.availArtTypes?.[0] ?? null}
              cancelTo={cancelTo}
              loadingContentId={FormatContentId.LoadingSaleId}
              searchingContentId={FormatContentId.SearchingForSaleId}
              errorContentId={FormatContentId.CouldNotFindSaleId}
            />
          )}
      </DetailGroup>
      {!isBulkEdit && hasAutoFulfillFeature && saleIsHandledByProcessor && (
        <DetailGroup>
          <Stack direction="column">
            <Detail
              label={<Content id={ContentId.AutoFulfill} />}
              style={{ flex: 1 }}
              detail={
                <Switch
                  checked={!isNoFulfill?.value}
                  disabled={disabled}
                  onChange={(e) => e.stopPropagation()}
                  onCheckedChange={(isChecked) => {
                    setValue('isNoFulfill', posChangedField(!isChecked), {
                      shouldDirty: true,
                    });
                  }}
                />
              }
            />
          </Stack>
        </DetailGroup>
      )}

      {!isBulkEdit && (
        <>
          {sale?.status && (
            <DetailGroup>
              <Detail
                label={<Content id={ContentId.Status} />}
                detail={
                  <>
                    <strong>
                      <Content
                        id={getSaleStatusDisplayContentId(
                          sale.status,
                          sale.mkpContrState,
                          sale.mkp
                        )}
                      />
                    </strong>
                    {
                      // if the Sale is fulfilled in both POS and Marketplace, but the version is mismatched, show the warning
                      sale.status === PointOfSaleSaleStatus.Fulfilled &&
                      sale.isFulfillVerMis ? (
                        <>
                          {hasFufillmentMismatchDisplay && (
                            <Stack>
                              <TooltipPopover
                                variant="link"
                                triggerContent={
                                  <WarningOutlineIcon
                                    fill={IconsFill.textWarning}
                                    withHoverEffect
                                  />
                                }
                              >
                                <WarningMessage
                                  message={
                                    <Content
                                      id={ContentId.VersionMismatchExplanation}
                                    />
                                  }
                                />
                              </TooltipPopover>
                            </Stack>
                          )}
                        </>
                      ) : sale.status !== PointOfSaleSaleStatus.Fulfilled &&
                        sale?.mkpFulfState ===
                          MarketplaceFulfillmentState.Fulfilled ? (
                        <Stack>
                          <TooltipPopover
                            variant="link"
                            triggerContent={
                              <WarningOutlineIcon
                                fill={IconsFill.textWarning}
                                withHoverEffect
                              />
                            }
                          >
                            <WarningMessage
                              message={
                                <Content
                                  id={ContentId.SaleIsFulfilledOnMarketplace2}
                                />
                              }
                            />
                          </TooltipPopover>
                        </Stack>
                      ) : undefined
                    }
                  </>
                }
              />
            </DetailGroup>
          )}
          {HoldExpirationInfo}
          {InHandInfo}
          {sale?.transDest && (
            <DetailGroup>
              <Detail
                label={<Content id={ContentId.YourBuyer} />}
                detail={
                  <div>
                    <div className={ticketsStyles.transferToInfo}>
                      {sale?.transDest.name}
                    </div>
                    <div className={ticketsStyles.transferToInfo}>
                      {sale?.transDest.email}
                    </div>
                    <div className={ticketsStyles.transferToInfo}>
                      {sale?.transDest.phoneNumber}
                    </div>
                  </div>
                }
              />
            </DetailGroup>
          )}
        </>
      )}
    </div>
  );
};
