import { ColumnDef } from '@tanstack/react-table';
import { Content } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { COMP_LISTING_MODE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { AutoGroupListingsPreviewListingUpdate } from 'src/WebApiController';

export const getListingGroupTableConfig =
  (): ColumnDef<AutoGroupListingsPreviewListingUpdate | null>[] => [
    {
      id: 'ListingId',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.ListingId} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.listingId,
      cell: ({ row: { original, depth } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        const { listingId } = original;

        if (depth > 0) return null;

        return <TableCellDiv align="right">{listingId}</TableCellDiv>;
      },
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'Section',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Section} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.area?.sectName,
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align="left">{original.area.sectName}</TableCellDiv>
        );
      },
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'Row',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Row} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.area?.name ?? '',
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align={'right'}>{original.area?.name}</TableCellDiv>
        );
      },
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'CompListingMode',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.CompListingMode} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.compListingMode ?? '',
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align={'left'}>
            {original.compListingMode && (
              <Content
                id={COMP_LISTING_MODE_TO_CID[original.compListingMode]}
              />
            )}
          </TableCellDiv>
        );
      },
      enableSorting: true,
      sortingFn: 'text',
    },
    {
      id: 'CompListingFloor',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.CompListingFloor} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.compListingFloor ?? '',
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align={'right'}>
            {original.compListingFloor}
          </TableCellDiv>
        );
      },
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'CompListingCeiling',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.CompListingCeiling} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.compListingCeiling ?? '',
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align={'right'}>
            {original.compListingCeiling}
          </TableCellDiv>
        );
      },
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'CircuitBreakerMinCompListingCount',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.MinCompListingCount} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.circuitBreakerMinCompListingCount ?? '',
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align={'right'}>
            {original.circuitBreakerMinCompListingCount}
          </TableCellDiv>
        );
      },
      enableSorting: true,
      sortingFn: 'alphanumeric',
    },
    {
      id: 'ExcludeFanInventory',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.ExcludeFanInventory} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.excludeFanInventory ?? '',
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align={'right'}>
            <Content
              id={original.excludeFanInventory ? ContentId.Yes : ContentId.No}
            />
          </TableCellDiv>
        );
      },
      enableSorting: true,
      sortingFn: 'text',
    },
    {
      id: 'AutoPricingEnabled',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.AutoPricingEnabled} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.autoPricingEnabled ?? '',
      cell: ({ row: { original } }) => {
        if (!original) {
          return <TableShimmeringDiv />;
        }

        return (
          <TableCellDiv align={'right'}>
            <Content
              id={original.autoPricingEnabled ? ContentId.Yes : ContentId.No}
            />
          </TableCellDiv>
        );
      },
      enableSorting: true,
      sortingFn: 'text',
    },
  ];
