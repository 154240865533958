import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { ImageMagnifier } from 'src/core/POS/ImageMagnifier';
import {
  PosDraggable,
  PosDraggableIndicator,
  PosDroppable,
} from 'src/core/POS/PosDragNDrop';
import { PosCustomDragLayer } from 'src/core/POS/PosDragNDrop/PosCustomDragLayer';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { SingleFileUploadWithPreview } from 'src/core/POS/SingleFileUploadWithPreview';
import { MAX_ALLOWED_UPLOAD_FILE_SIZE } from 'src/core/utils';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { DocumentPage, Ticket } from 'src/WebApiController';

import * as styles from '../UploadETickets.css';
import {
  UploadETicketsSubInstructions,
  UploadETicketsThumbnailAssigned,
  UploadETicketsThumbnailAssignedMappedTo,
  UploadETicketsThumbnailContainer,
  UploadETicketsThumbnailLoader,
} from '../UploadETickets.styled';
import {
  DocumentFileUploadInfo,
  ETicketsForm,
  getDocumentPageIdToTicketMap,
  getDocumentPageUniqueId,
} from '../UploadETickets.utils';

export function UploadETicketsThumbnailSection({
  eTicketUploadInfos,
  setETicketUploadInfos,
  entitWithTickets,
}: {
  entitWithTickets: Pick<
    EntityWithRealTickets,
    'viagVirtualId' | 'id' | 'ticketCnt' | 'tickets' | 'seating'
  >;
  eTicketUploadInfos: DocumentFileUploadInfo[];
  setETicketUploadInfos: (infos: DocumentFileUploadInfo[]) => void;
}) {
  const { watch, setValue } = useFormContext<ETicketsForm>();
  const watchTickets = watch('ticketAssignments'); // you can supply default value as second argument

  const mappedETicketIds = useMemo(
    () => getDocumentPageIdToTicketMap(watchTickets, entitWithTickets!.tickets),
    [entitWithTickets, watchTickets]
  );

  const onDroppingRemovingTicketFromSeat = useCallback(
    (ticket: Ticket, ticketIndex: number) => {
      setValue(`ticketAssignments.${ticketIndex}`, {
        entityId: ticket.id,
        documentId: '',
        pageNumber: 0,
        pageId: '',
        pageUri: '',
        documentUri: '',
        documentType: null,
      });
    },
    [setValue]
  );

  const onDroppingToThumbnails = useCallback(
    (
      sourcePage: DocumentPage,
      destinationPage: DocumentPage,
      destinationTicket: { ticket: Ticket; ticketIndex: number }
    ) => {
      if (
        sourcePage.documentId === destinationPage.documentId &&
        sourcePage.id === destinationPage.id &&
        destinationTicket
      ) {
        onDroppingRemovingTicketFromSeat(
          destinationTicket.ticket,
          destinationTicket.ticketIndex
        );
      } else {
        // Different page was dropped into this bucket - doesn't matter, we should just find
        // the ticket to remove
        const matchingPages = eTicketUploadInfos.flatMap(
          (ui) =>
            ui.pages?.filter(
              (p) =>
                p.documentId === sourcePage.documentId && p.id === sourcePage.id
            )
        );
        const matchingPage = matchingPages.filter((p) => p != null)[0];
        if (matchingPage) {
          const mappedToTicket =
            mappedETicketIds[getDocumentPageUniqueId(matchingPage)];
          if (mappedToTicket) {
            onDroppingRemovingTicketFromSeat(
              mappedToTicket.ticket,
              mappedToTicket.ticketIndex
            );
          }
        }
      }
    },
    [eTicketUploadInfos, mappedETicketIds, onDroppingRemovingTicketFromSeat]
  );

  const onFileDeleted = useCallback(
    (ui: DocumentFileUploadInfo) => {
      if (ui.pages) {
        // If any of the page is mapped, unmap it
        ui.pages.forEach((ep) => {
          const mappedToTicket = mappedETicketIds[getDocumentPageUniqueId(ep)];
          if (mappedToTicket) {
            onDroppingRemovingTicketFromSeat(
              mappedToTicket.ticket,
              mappedToTicket.ticketIndex
            );
          }
        });
      }
      ui.file = undefined;
      ui.uploadInfo = undefined;
      ui.pages = undefined;

      setETicketUploadInfos([...eTicketUploadInfos]);
    },
    [
      eTicketUploadInfos,
      mappedETicketIds,
      onDroppingRemovingTicketFromSeat,
      setETicketUploadInfos,
    ]
  );

  const onNewFileSelected = useCallback(
    (f: File[], ui: DocumentFileUploadInfo) => {
      // File already uploaded, do nothing/
      if (ui.pages || ui.uploadInfo) {
        return;
      }
      const newUploadUIs = f.map((file) => ({
        file,
        pages: [],
        uploadInfo: undefined,
      }));

      setETicketUploadInfos([...newUploadUIs, ...eTicketUploadInfos]);
    },
    [eTicketUploadInfos, setETicketUploadInfos]
  );

  return (
    <div className={styles.uploadETicketsSectionPanel}>
      <div className={styles.seatAssignmentSectionHeader}>
        <Content id={ContentId.Tickets} />
      </div>
      <UploadETicketsThumbnailContainer>
        {eTicketUploadInfos.map((ui, i) => {
          const key = `e-ticket-file-${i}`;
          // If ui has uploadInfo but no pages, it's being uploaded/processed, so just display a spinner for the file
          return ui.file?.name && !ui.pages ? (
            <UploadETicketsThumbnailLoader key={key}>
              <UploadETicketsSubInstructions>
                {ui.file?.name ? (
                  <FormatContent
                    id={FormatContentId.Uploading}
                    params={ui.file?.name}
                  />
                ) : (
                  ' '
                )}
                {ui.uploadInfo && (
                  // Only allow cancel once the file is uploaded and is only pollling for pages
                  <CancelButton onClick={() => onFileDeleted(ui)} />
                )}
              </UploadETicketsSubInstructions>
              <PosSpinner />
            </UploadETicketsThumbnailLoader>
          ) : (
            // Has no uploadInfo or has pages, display component to display thumbnail or single-file uploader
            <div
              className={styles.singleFileUploadWithPreviewContainer}
              key={key}
            >
              <SingleFileUploadWithPreview<DocumentPage>
                key={key}
                fileName={ui.file?.name}
                filePages={ui.pages || []}
                isDeletable={true}
                onFileDeleted={() => onFileDeleted(ui)}
                multiple={true}
                onNewFileSelected={(f) => onNewFileSelected(f, ui)}
                maxFileSize={MAX_ALLOWED_UPLOAD_FILE_SIZE}
                renderPreview={(ep: DocumentPage) => {
                  const mappedToTicket =
                    mappedETicketIds[getDocumentPageUniqueId(ep)];
                  return (
                    <PosDroppable
                      hideBorder={true}
                      onDrop={({ data }: { data: DocumentPage }) =>
                        onDroppingToThumbnails(data, ep, mappedToTicket)
                      }
                    >
                      {mappedToTicket ? (
                        <UploadETicketsThumbnailAssigned>
                          <FormatContent
                            id={FormatContentId.PageNum}
                            params={`${ep.pageNumber + 1}`}
                          />
                          <UploadETicketsThumbnailAssignedMappedTo>
                            <Content id={ContentId.MappedTo} />
                            <SeatingInfo
                              section={entitWithTickets!.seating.section}
                              row={mappedToTicket.ticket.row}
                              seatFr={
                                mappedToTicket.ticket.seat || (i + 1).toString()
                              }
                              seatTo={''}
                            />
                          </UploadETicketsThumbnailAssignedMappedTo>
                        </UploadETicketsThumbnailAssigned>
                      ) : (
                        <PosDraggable
                          draggableData={ep}
                          previewDragData={{
                            name: (
                              <div>
                                <FormatContent
                                  id={FormatContentId.PageNum}
                                  params={`${ep.pageNumber + 1}`}
                                />
                              </div>
                            ),
                            source: ep.thumbUrl,
                          }}
                        >
                          <PosDraggableIndicator>
                            <div>
                              <FormatContent
                                id={FormatContentId.PageNum}
                                params={`${ep.pageNumber + 1}`}
                              />
                            </div>
                            <ImageMagnifier
                              imageSrc={ep.thumbUrl}
                              magnifierSize={100}
                            />
                          </PosDraggableIndicator>
                        </PosDraggable>
                      )}
                      <PosCustomDragLayer />
                    </PosDroppable>
                  );
                }}
              />
            </div>
          );
        })}
      </UploadETicketsThumbnailContainer>
    </div>
  );
}
