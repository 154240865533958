import { uniq } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import {
  mapReportMetrics,
  mapReportMetricsSummary,
} from 'src/components/ReportsSalePage/ReportsSalePage.utils';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useContent } from 'src/contexts/ContentContext/Content';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useReportMetricsContext } from 'src/contexts/ReportMetricsContext';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useColumnDisplayName } from 'src/modals/InventoryTableColumns/useColumnDisplayName';
import {
  timeBucketToColumnId,
  timeBucketToDayString,
} from 'src/tables/SalesTable/configs/SaleReportTableColumnsConfig.utils';
import {
  SaleReportTableColumnDefContextProvider,
  useSaleReportTableColumnDef,
} from 'src/tables/SalesTable/SaleReportTableColumnDefContext';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID } from 'src/utils/columns/sales/salesReportColumnUtils.constants';
import { ContentId } from 'src/utils/constants/contentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { downloadFileFromBlob } from 'src/utils/fileUtils';
import {
  isReportFilterOverriden,
  SaleReportMetricsWithGroupBy,
} from 'src/utils/reportsUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  DateTimeRangeWithRelative,
  EntityWithTicketsQuery,
  ReportClient,
  SaleQuery,
  SaleReportMetrics,
} from 'src/WebApiController';

import { ReportsSaleContextProvider } from '../../../navigations/Routes/ReportsSale/ReportsSaleContextProvider';
import { ReportExportOptions } from './ReportsExport.types';
import {
  exportReport,
  getReportExportFileBasename,
  mapReportMetricToTableString,
  mapReportMetricToUserIds,
  sortReportMetrics,
} from './ReportsExport.utils';

export function ReportsSaleExport({
  reportExportOptions,
  onReportExported,
}: {
  reportExportOptions: ReportExportOptions | null;
  onReportExported: () => void;
}) {
  // Loading report metrics depend on the context provider (need to wait for the context provider to load the report metrics)

  // If reportToExport is null,
  // no reports need to be exported, so not rendering the <ReportsSaleExportInner component

  // If reportToExport is not null, then render the <ReportsSaleExportInner component.
  // It will load the report metrics and export the report to a PDF file
  // After the report is exported, the onReportExported callback will be called to reset the reportToExport state to null
  if (reportExportOptions == null) return null;

  return (
    <ReportsSaleContextProvider
      reportConfig={reportExportOptions.reportToExport}
      applyMaxRowLimitByDefault={false}
    >
      {reportExportOptions?.serverSideExport ? (
        <ReportsSaleServerSideExportInner
          reportExportOptions={reportExportOptions}
          onReportExported={onReportExported}
        />
      ) : (
        <SaleReportTableColumnDefContextProvider
          report={reportExportOptions.reportToExport}
        >
          <ReportsSaleClientSideExportInner
            reportExportOptions={reportExportOptions}
            onReportExported={onReportExported}
          />
        </SaleReportTableColumnDefContextProvider>
      )}
    </ReportsSaleContextProvider>
  );
}

function ReportsSaleServerSideExportInner({
  reportExportOptions: { reportToExport: reportConfig, fileType },
  onReportExported,
}: {
  reportExportOptions: ReportExportOptions;
  onReportExported: () => void;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { allEventIds } = useCatalogDataContext();
  const { filterQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();
  const { trackError } = useErrorBoundaryContext();

  const downloadReport = async () => {
    await tryInvokeApi(
      async () => {
        const result = await new ReportClient(
          activeAccountWebClientConfig
        ).downloadSaleReportMetrics({
          query: {
            ...(reportConfig.filter as SaleQuery),
            eventOrMappingIds: allEventIds!.map((ev) => ev.viagVirtualId),
            performerIds: allEventIds!
              .filter((ev) => ev.performerId != null)
              .map((ev) => ev.performerId!),
            venueIds: allEventIds!.map((ev) => ev.venueId),
          },
          groupBy: reportConfig.groupBy,
          globalReportTypeId: reportConfig.globalReportTypeId,
          metrics: reportConfig.metrics,
          isUsingFilterOverride:
            reportConfig.hasFilterOverride ||
            isReportFilterOverriden(filterQuery, reportConfig.filter),
          fileType,
          arAgingTimeBuckets: reportConfig.arAgingTimeBuckets ?? [],
        });

        if (result.data) {
          downloadFileFromBlob(
            `${getReportExportFileBasename(
              reportConfig.reportName
            )}.${fileType.toLowerCase()}`,
            result.data
          );
        }
      },
      (error) => {
        trackError('ReportClient.downloadSaleReportMetrics', error);
      },
      () => onReportExported()
    );
  };

  useEffect(() => {
    if (allEventIds != null) {
      downloadReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEventIds]);

  return <></>;
}

function ReportsSaleClientSideExportInner({
  reportExportOptions: { reportToExport: reportConfig, fileType },
  onReportExported,
}: {
  reportExportOptions: ReportExportOptions;
  onReportExported: () => void;
}) {
  const { reportMetrics, isLoading, reportSummary } =
    useReportMetricsContext<SaleReportMetrics>();
  const { data, eventsTransformed } = useCatalogDataContext();
  const { displayedColumnsConfig } = useSaleReportTableColumnDef();
  const { displayNames: columnDisplayNames } = useColumnDisplayName({
    columnIds: reportConfig.metrics,
    sectionType: SectionType.SalesReport,
  });
  const deactivatedText = useContent(ContentId.Deactivated);
  const daysText = useContent(ContentId.Days);
  const totalText = useContent(ContentId.Total);

  const reportMetricsMapped: SaleReportMetricsWithGroupBy[] | undefined =
    useMemo(() => {
      if (!isLoading) {
        return mapReportMetrics(reportMetrics, eventsTransformed, data);
      }
    }, [data, eventsTransformed, isLoading, reportMetrics]);

  const userIds = useMemo(() => {
    if (!reportMetricsMapped || !displayedColumnsConfig?.length) {
      return undefined;
    }

    const userIds: string[] = [];

    reportMetricsMapped.forEach((reportMetric) => {
      const userIdsInRow = mapReportMetricToUserIds(
        reportMetric,
        displayedColumnsConfig
      );
      userIds.push(...userIdsInRow);
    });

    return uniq(userIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedColumnsConfig?.length, reportMetricsMapped]);

  const useGetUserInfosQuery = useGetUserInfos(userIds);

  const allActiveUserInfos = useMemo(() => {
    if (userIds != null && userIds.length === 0) {
      return {};
    }
    return useGetUserInfosQuery.data;
  }, [useGetUserInfosQuery.data, userIds]);

  // Main data to be exported
  const tableData = useMemo(() => {
    if (
      !reportMetricsMapped ||
      !displayedColumnsConfig?.length ||
      !allActiveUserInfos ||
      !deactivatedText
    ) {
      return undefined;
    }

    const result: string[][] = [];

    const sortBy =
      reportConfig.sortBy ??
      GROUP_BY_TO_PRIMARY_COLUMN_ID[reportConfig.groupBy];
    const sortByColumnConfig = displayedColumnsConfig.find(
      (c) => c.id === sortBy
    );

    const dataSorted = reportMetricsMapped.sort((a, b) =>
      sortReportMetrics(
        a,
        b,
        sortByColumnConfig,
        reportConfig.isSortDescending ?? false
      )
    );

    dataSorted.forEach((reportMetric) => {
      const row = mapReportMetricToTableString(
        reportMetric,
        displayedColumnsConfig,
        allActiveUserInfos,
        deactivatedText
      );
      result.push(row);
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allActiveUserInfos,
    deactivatedText,
    displayedColumnsConfig?.length,
    reportConfig.groupBy,
    reportConfig.isSortDescending,
    reportConfig.sortBy,
    reportMetricsMapped,
  ]);

  // Summary data
  const tableFoot = useMemo(() => {
    if (
      !reportSummary ||
      !displayedColumnsConfig?.length ||
      !allActiveUserInfos ||
      !reportMetricsMapped
    ) {
      return undefined;
    }

    const dataMapped = mapReportMetricsSummary(
      reportConfig,
      reportSummary,
      reportMetricsMapped
    );

    if (dataMapped) {
      return mapReportMetricToTableString(
        dataMapped,
        displayedColumnsConfig,
        allActiveUserInfos
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allActiveUserInfos,
    displayedColumnsConfig?.length,
    reportConfig,
    reportMetricsMapped,
    reportSummary,
  ]);

  // Table header
  const tableHeader = useMemo(() => {
    if (
      !columnDisplayNames.length ||
      !displayedColumnsConfig ||
      !reportMetricsMapped ||
      !daysText ||
      !totalText
    ) {
      return undefined;
    }

    let timeBuckets: DateTimeRangeWithRelative[] = [];
    if (reportConfig.arAgingTimeBuckets) {
      timeBuckets = reportConfig.arAgingTimeBuckets.map(
        (t) =>
          ({
            relativeStartSecs: t.relativeStartSecs,
            relativeEndSecs: t.relativeEndSecs,
          }) as DateTimeRangeWithRelative
      );
    }

    return displayedColumnsConfig.map((c) => {
      const index = reportConfig.metrics.indexOf(c.id ?? '');
      if (index < 0) {
        const timeBucket = timeBuckets.find(
          (t) => c.id === timeBucketToColumnId(t)
        );
        if (timeBucket) {
          return timeBucketToDayString(timeBucket, daysText, totalText);
        }
        return '';
      }
      return columnDisplayNames[index];
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnDisplayNames,
    displayedColumnsConfig?.length,
    reportMetricsMapped,
    daysText,
    totalText,
    reportConfig.arAgingTimeBuckets,
    reportConfig.metrics,
  ]);

  const onDataLoaded = (
    tableHeader: string[],
    tableData: string[][],
    tableFoot: string[]
  ) => {
    exportReport(reportConfig, fileType, tableHeader, tableData, tableFoot);

    onReportExported();
  };

  useEffect(() => {
    if (
      data != null &&
      eventsTransformed != null &&
      tableData != null &&
      tableHeader != null &&
      tableFoot != null
    ) {
      onDataLoaded(tableHeader, tableData, tableFoot);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, tableHeader, tableFoot]);

  return <></>;
}
