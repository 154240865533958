import { Controller, useFormContext } from 'react-hook-form';
import { SellerUserListItem } from 'src/components/common/SellerUserListItem';
import { SellerAccountEmployeeMultiSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { Content, useContent } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextArea } from 'src/core/POS/PosTextArea';
import { PosTextField } from 'src/core/POS/PosTextField';
import { shared } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { UserGroup } from 'src/WebApiController';

import * as styles from './EditTeamModal.css';

export const TeamInput = () => {
  const requiredMsg = useContent(ContentId.Required);

  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext<UserGroup>();

  const sellerUserIds = watch('sellerUserIds');

  return (
    <div className={styles.root}>
      <Stack direction="column" gap="l">
        <Controller
          control={control}
          name={'userGroupName'}
          rules={{ required: requiredMsg, maxLength: 100 }}
          render={({ field: { value, ...field } }) => (
            <PosFormField
              errors={errors.userGroupName?.message}
              label={
                <span className={shared.typography.subtitle1}>
                  <Content id={ContentId.TeamName} />
                </span>
              }
            >
              <PosTextField maxLength={100} value={value} {...field} />
            </PosFormField>
          )}
        />
        <Controller
          control={control}
          name={'userGroupDescription'}
          rules={{ maxLength: 200 }}
          render={({ field: { value, ...field } }) => (
            <PosFormField
              errors={errors.userGroupDescription?.message}
              label={
                <span className={shared.typography.subtitle1}>
                  <Content id={ContentId.Description} />
                </span>
              }
            >
              <PosTextArea
                maxLength={200}
                value={value ?? undefined}
                {...field}
              />
            </PosFormField>
          )}
        />
        <Controller
          control={control}
          name={'sellerUserIds'}
          render={({ field: { value, ...field } }) => (
            <PosFormField
              label={
                <span className={shared.typography.subtitle1}>
                  <Content id={ContentId.AddMembersToThisTeam} />
                </span>
              }
            >
              <SellerAccountEmployeeMultiSelector
                triggerProps={{ style: { width: '100%' } }}
                showEmail
                values={value ?? []}
                {...field}
                placeholderText={ContentId.Select}
              />
            </PosFormField>
          )}
        />

        <PosFormField
          label={
            <span className={shared.typography.subtitle1}>
              <Content id={ContentId.MembersOnThisTeam} />
            </span>
          }
        >
          <Stack direction="column" width="full">
            {sellerUserIds.map((sellerUserId) => (
              <SellerUserListItem
                key={sellerUserId}
                sellerUserId={sellerUserId}
                onDelete={(value) => {
                  setValue(
                    'sellerUserIds',
                    sellerUserIds.filter((id) => id !== value)
                  );
                }}
              />
            ))}
          </Stack>
        </PosFormField>
      </Stack>
    </div>
  );
};
