import { useQuery } from '@tanstack/react-query';
import { uniq } from 'lodash-es';
import { ComponentProps, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { CurrencyFilterSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Switch } from 'src/core/interim/Switch';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  FieldError,
  FieldLabel,
  FieldWrapper,
  PurchasePaymentMethodSelectionInput,
} from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import {
  Feature,
  PurchaseClient,
  PurchasePayment,
  PurchasePaymentMethodType,
  PurchasePaymentStatus,
} from 'src/WebApiController';

import * as styles from './EditPurchasePaymentDialog.css';

export type EditPurchasePaymentDialogFormInputs = {
  paymentDueDate: Date;
  paymentDate: Date | null;
  paymentAmount: number;
  currencyCode: string;
  paymentMethodId: number;
  isPaid: boolean;
};

export type EditPurchasePaymentDialogProps = ComponentProps<typeof RSModal> & {
  payment: PurchasePayment;
  onSave: (data: PurchasePayment) => void;
  onCancel: () => void;
};

export function EditPurchasePaymentDialog({
  payment,
  onSave,
  onCancel,
  ...rest
}: EditPurchasePaymentDialogProps) {
  const requiredMsg = useContent(ContentId.Required);

  const hasEnforcePurchasePaymentCurrencyFeature = useUserHasFeature(
    Feature.EnforcePurchasePaymentCurrency
  );

  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  const { getUiCurrency } = useLocalizationContext();
  const paymentMethods = useQuery({
    queryKey: [
      'PurchaseClient.getAccessiblePurchasePaymentMethods',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new PurchaseClient(
        activeAccountWebClientConfig
      ).getAccessiblePurchasePaymentMethods();
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PurchaseClient.getAccessiblePurchasePaymentMethods', error);
      },
    },
  });

  const {
    getValues,
    setValue,
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<EditPurchasePaymentDialogFormInputs>({
    defaultValues: {
      paymentDueDate: new Date(payment.paymentDueDate),
      paymentDate: payment.paymentDate ? new Date(payment.paymentDate) : null,
      paymentAmount: payment.paymentAmount.amt,
      currencyCode: payment.currencyCode,
      paymentMethodId: payment.paymentMethod.id,
      isPaid:
        payment.paymentStatus === PurchasePaymentStatus.Paid ||
        payment.paymentStatus === PurchasePaymentStatus.RefundNeeded,
    },
  });

  const paymentAmount = watch('paymentAmount');
  const currencyCode = watch('currencyCode');

  const uiCurrency = useMemo(
    () => getUiCurrency(currencyCode),
    [currencyCode, getUiCurrency]
  );

  return (
    <GenericDialog
      size="md"
      header={<Content id={ContentId.HowDidYouPay} />}
      footer={
        <>
          {onCancel && <CancelButton onClick={onCancel} />}
          <Button
            variant={'regular'}
            onClick={handleSubmit((data) => {
              const paymentMethod = paymentMethods.data?.find(
                ({ id }) => id === data.paymentMethodId
              );
              const paymentStatus = data.isPaid
                ? payment.paymentDate &&
                  payment.paymentDate > payment.paymentDueDate
                  ? PurchasePaymentStatus.RefundNeeded
                  : PurchasePaymentStatus.Paid
                : PurchasePaymentStatus.Unpaid;
              onSave({
                paymentId: payment.paymentId,
                paymentStatus: paymentStatus,
                paymentDueDate: data.paymentDueDate.toISOString(),
                paymentDate: data.paymentDate?.toISOString() ?? null,
                paymentAmount: {
                  amt: Number(data.paymentAmount),
                  disp: null,
                  currency: uiCurrency.code,
                  dec: uiCurrency.dec,
                },
                currencyCode: data.currencyCode,
                paymentMethod: {
                  id: data.paymentMethodId,
                  display: paymentMethod?.display ?? '',
                  name: paymentMethod?.name ?? '',
                  type: paymentMethod?.type ?? PurchasePaymentMethodType.Other,
                },
                convertedPaymentAmount: null,
                convertedCurrencyCode: null,
                conversionRate: null,
                conversionDate: null,
                conversionUserId: null,
              });
            })}
          >
            <Content id={ContentId.Save} />
          </Button>
        </>
      }
      {...rest}
      onCancel={onCancel}
    >
      <FieldWrapper>
        <FieldLabel>
          <Content id={ContentId.TotalDue} />
        </FieldLabel>
        <div className={styles.formRow}>
          {!hasEnforcePurchasePaymentCurrencyFeature && (
            <Controller
              control={control}
              name="currencyCode"
              rules={{ required: requiredMsg }}
              render={({ field: { ...field }, fieldState }) => (
                <CurrencyFilterSelector
                  hasErrors={Boolean(fieldState.error)}
                  {...field}
                />
              )}
            />
          )}
          <PosCurrencyField
            rootProps={{
              state: getTextFieldState(errors.paymentAmount),
            }}
            uiCurrency={getUiCurrency(currencyCode)}
            value={paymentAmount}
            {...register('paymentAmount', {
              validate: {
                required: (fieldVal) => {
                  return parseFloat(fieldVal?.toString() ?? '')
                    ? undefined
                    : requiredMsg;
                },
              },
              valueAsNumber: true,
            })}
          />
        </div>
        {(errors.currencyCode || errors.paymentAmount) &&
          uniq([errors.currencyCode?.message, errors.paymentAmount?.message])
            .filter((msg) => Boolean(msg))
            .map((msg, i) => {
              return <FieldError key={i}>{msg}</FieldError>;
            })}
      </FieldWrapper>
      <div className={styles.formRow}>
        <FieldWrapper>
          <Controller
            control={control}
            name="paymentDueDate"
            rules={{ required: requiredMsg }}
            render={({ field: { ...field }, fieldState }) => (
              <PosFormField
                label={<Content id={ContentId.DateDue} />}
                errors={fieldState.error?.message}
              >
                <DatePickerInput
                  fieldError={fieldState.error}
                  locale={getLocaleFromLanguageOrCurrent()}
                  {...field}
                  onDateChange={(d) => {
                    setValue(field.name, d);
                  }}
                />
              </PosFormField>
            )}
          />
        </FieldWrapper>
      </div>
      <div className={styles.formRow}>
        <FieldWrapper>
          <Controller
            control={control}
            name="paymentDate"
            rules={{ required: watch('isPaid') ? requiredMsg : false }}
            render={({ field: { ...field }, fieldState }) => {
              return (
                <PosFormField
                  label={<Content id={ContentId.DatePaid} />}
                  errors={fieldState.error?.message}
                >
                  <DatePickerInput
                    fieldError={fieldState.error}
                    disabled={!watch('isPaid')}
                    locale={getLocaleFromLanguageOrCurrent()}
                    {...field}
                    onDateChange={(d) => {
                      setValue(field.name, d);
                    }}
                  />
                </PosFormField>
              );
            }}
          />
          {errors.paymentDate && (
            <FieldError>{errors.paymentDate?.message}</FieldError>
          )}
        </FieldWrapper>
        <FieldWrapper>
          <FieldLabel>
            <Content id={ContentId.Paid} />
          </FieldLabel>
          <Controller
            name="isPaid"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Switch
                checked={value}
                onChange={(e) => {
                  const isPaid = e.currentTarget.checked;
                  if (isPaid) {
                    setValue('paymentDate', getValues('paymentDueDate'));
                  } else {
                    setValue('paymentDate', null);
                  }
                  onChange(e);
                }}
                {...field}
              />
            )}
          />
        </FieldWrapper>
      </div>
      <FieldWrapper>
        <FieldLabel>
          <Content id={ContentId.PaymentMethodUsed} />
        </FieldLabel>
        <Controller
          control={control}
          name="paymentMethodId"
          rules={{ required: requiredMsg }}
          render={({ field: { value, ...field }, fieldState }) => (
            <PurchasePaymentMethodSelectionInput
              value={value !== undefined ? String(value) : undefined}
              hasErrors={Boolean(fieldState.error)}
              {...field}
            />
          )}
        />
        {errors.paymentMethodId && (
          <FieldError>{errors.paymentMethodId?.message}</FieldError>
        )}
      </FieldWrapper>
    </GenericDialog>
  );
}
