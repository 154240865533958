import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const MultiEditCheckboxChecked = (props: PosIconProps) => {
  return (
    <SvgWrapper
      aria-label="MultiEditCheckboxChecked"
      {...props}
      viewBox="0 0 21 21" // The viewbox here is important as it won't display properly without this specific value
    >
      <path
        d="M3 3V3.25V5.5L16.5 5L15.75 18.25H17.25H17.5C18.6046 18.25 19.5 17.3546 19.5 16.25V4C19.5 2.34315 18.1569 1 16.5 1H5C3.89543 1 3 1.89543 3 3Z"
        stroke={props.stroke ?? 'currentColor'}
        fill={props.fill ?? 'transparent'}
        strokeWidth="1.5"
      />

      <path
        d="M15 3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.316071 4.44129 0 5.20435 0 6L0 18C0 18.7956 0.316071 19.5587 0.87868 20.1213C1.44129 20.6839 2.20435 21 3 21H15C15.7956 21 16.5587 20.6839 17.1213 20.1213C17.6839 19.5587 18 18.7956 18 18V6C18 5.20435 17.6839 4.44129 17.1213 3.87868C16.5587 3.31607 15.7956 3 15 3ZM15.045 9.045L7.545 16.545C7.44075 16.6497 7.31686 16.7327 7.18043 16.7894C7.04401 16.8461 6.89773 16.8753 6.75 16.8753C6.60227 16.8753 6.45599 16.8461 6.31957 16.7894C6.18314 16.7327 6.05925 16.6497 5.955 16.545L2.955 13.545C2.75628 13.3317 2.6481 13.0497 2.65324 12.7582C2.65838 12.4668 2.77645 12.1887 2.98257 11.9826C3.18869 11.7764 3.46676 11.6584 3.75822 11.6532C4.04967 11.6481 4.33174 11.7563 4.545 11.955L6.75 14.1585L13.455 7.455C13.558 7.34447 13.6822 7.25582 13.8202 7.19433C13.9582 7.13284 14.1072 7.09978 14.2582 7.09711C14.4093 7.09445 14.5593 7.12223 14.6994 7.17882C14.8395 7.2354 14.9667 7.31961 15.0736 7.42644C15.1804 7.53327 15.2646 7.66052 15.3212 7.8006C15.3778 7.94069 15.4056 8.09073 15.4029 8.24178C15.4002 8.39284 15.3672 8.54181 15.3057 8.67981C15.2442 8.81781 15.1555 8.94201 15.045 9.045Z"
        stroke={props.stroke ?? 'transparent'}
        fill={props.fill ?? 'currentColor'}
      />
    </SvgWrapper>
  );
};
