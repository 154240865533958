import { TextFilter } from 'src/components/Filters/TextFilter';
import { TextFilterQuery } from 'src/components/Filters/TextFilter/TextFilter';

export const RowFilter = <TQuery extends TextFilterQuery>({
  query,
  setQuery,
}: {
  query: TQuery;
  setQuery: (q: TQuery) => void;
}) => {
  return (
    <TextFilter
      query={query}
      setQuery={setQuery}
      keys={{
        textContainsKey: 'rowContains',
        textNotContainsKey: 'rowNotContains',
        textEqualsKey: 'rowEquals',
      }}
    />
  );
};
