import { useMemo } from 'react';
import { InternalNotesFilter } from 'src/components/Filters/InternalNotesFilter/InternalNotesFilter';
import { TextFilterQuery } from 'src/components/Filters/TextFilter/TextFilter';
import { FilterToolbarGroup } from 'src/components/FilterToolbar';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ContentId } from 'src/utils/constants/contentId';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { ActionOutboxEntityType } from 'src/WebApiController';

type EntityType =
  | ActionOutboxEntityType.Listing
  | ActionOutboxEntityType.Purchase
  | ActionOutboxEntityType.Sale;

const getInternalNotesLabel = (entity: EntityType) => {
  if (entity === ActionOutboxEntityType.Listing) {
    return ContentId.PrivateInventoryNotes;
  }

  if (entity === ActionOutboxEntityType.Sale) {
    return ContentId.PrivateSaleNotes;
  }

  if (entity === ActionOutboxEntityType.Purchase) {
    return ContentId.PrivatePurchaseNotes;
  }

  return ContentId.PrivateNote;
};

interface UseMultipleInternalNotesFilterProps {
  entityType: EntityType;
}

export const useMultipleInternalNotesFilter = <
  TQuery extends QueryWithViewMode & TextFilterQuery,
>({
  entityType,
}: UseMultipleInternalNotesFilterProps): FilterToolbarGroup => {
  const { tempQuery, setTempQuery } = useFilterQueryContext<TQuery>();
  return useMemo<FilterToolbarGroup>(() => {
    return {
      titleContentId: ContentId.Notes,
      type: 'row',
      items: [
        {
          filterId: 'internalNotes',
          labelContentId: getInternalNotesLabel(entityType),
          filterQueryKeys: [
            'internalNotesContains',
            'internalNotesEquals',
            'internalNotesNotContains',
          ],
          filterItem: (
            <InternalNotesFilter query={tempQuery} setQuery={setTempQuery} />
          ),
        },
      ],
    };
  }, [setTempQuery, tempQuery, entityType]);
};
