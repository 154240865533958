import * as styles from 'src/core/POS/ToolbarButton/ToolbarButton.css';
import { Select } from 'src/core/ui';

import { QuickFilterToolbarItem } from './QuickFilterToolbarItem';

export const MobileFilterToolbar = ({
  filters,
  defaultValue,
  isSideTable,
}: {
  filters?: QuickFilterToolbarItem[];
  defaultValue?: string;
  isSideTable?: boolean;
}) => {
  if (!filters) return null;

  return (
    <Select.Root
      onValueChange={(value) => {
        // Find the filter that matches the value and call its onClick handler
        filters.find((f) => f.id === value)?.onClick();
      }}
      defaultValue={defaultValue}
    >
      <Select.Trigger
        shape="pill"
        style={{ width: '100%' }}
        className={isSideTable ? styles.button['selected'] : undefined}
      >
        <Select.Value placeholder="Select a filter" />
      </Select.Trigger>
      <Select.Content>
        {filters.map(({ id, name, isHidden }) => {
          if (isHidden) {
            return null;
          }
          return (
            <Select.Item value={id} key={id}>
              {name}
            </Select.Item>
          );
        })}
      </Select.Content>
    </Select.Root>
  );
};
