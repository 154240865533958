import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const PurchaseToolbarDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};
  padding: ${() =>
    `${vars.spacing.sm} ${vars.spacing.lg} ${vars.spacing.sm} ${vars.spacing.m}`};

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `padding: ${vars.spacing.sm} ${vars.spacing['3xl']} ${vars.spacing.sm} ${vars.spacing.xl};`
    )}`}

  .add-purchase {
    white-space: nowrap;
    width: fit-content;
  }
`;

export const FilterBarLeftSide = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: ${() => vars.spacing.sm};

  > div {
    flex-grow: 1;
  }
`;

export const FilterBarRightSide = styled.div`
  display: flex;
  align-items: center;
  padding-inline-start: ${() => vars.spacing.sm};

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `padding-inline-start: ${vars.spacing.lg};`
    )}`}
`;
