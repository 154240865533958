import * as ToggleGroupRadix from '@radix-ui/react-toggle-group';
import clsx from 'clsx';
import React, { ComponentPropsWithoutRef } from 'react';
import { mergeProps } from 'src/core/utils';

import * as styles from './ToggleGroup.css';

export type ToggleItem = {
  value: string;
  children: React.ReactNode;
};

export type ToggleGroupProps = Omit<
  ToggleGroupRadix.ToggleGroupImplSingleProps,
  'type'
> & {
  value?: string;
  defaultValue?: string;
  onValueChange?: (value: string) => void;
  options: ToggleItem[];
  disabled?: boolean;
  itemStyle?: ComponentPropsWithoutRef<typeof ToggleGroupRadix.Item>['style'];
  rovingFocus?: boolean;
};

export const ToggleGroup = ({
  value,
  defaultValue,
  onValueChange,
  options,
  disabled,
  itemStyle,
  rovingFocus = true,
  ...props
}: ToggleGroupProps) => {
  return (
    <ToggleGroupRadix.Root
      className={styles.toggleGroup}
      type="single"
      defaultValue={defaultValue}
      onValueChange={onValueChange}
      disabled={disabled}
      rovingFocus={rovingFocus}
      {...mergeProps(
        {
          className: styles.toggleGroup,
        },
        props
      )}
    >
      {options.map((item, i) => {
        return (
          <ToggleGroupRadix.Item
            key={i}
            className={clsx(styles.toggleGroupItem, {
              [styles.toggleGroupItemSelected]: item.value === value,
              [styles.toggleGroupItemDisabled]: disabled,
              [styles.toggleGroupItemSelectedDisabled]:
                item.value === value && disabled,
            })}
            disabled={disabled}
            style={itemStyle}
            value={item.value}
          >
            {item.children}
          </ToggleGroupRadix.Item>
        );
      })}
    </ToggleGroupRadix.Root>
  );
};
