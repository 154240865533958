import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function PaperTicketsOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M16.703 3.188l-.436 1.545 3.208.554-2.099 11.881L15.594 7.03l-.752-4.159L14.683 2l-1.346.198-2.931.515-.158.436c-.198.514-.674.91-1.228 1.03a1.695 1.695 0 01-1.505-.555l-.277-.357-.515.08L3 4.059 6.168 22l6.1-1.07 5.86 1.03 3.169-17.94-4.594-.832zm-3.287.673l2.614 14.812-8.594 1.466L4.822 5.327l1.861-.317c.713.634 1.703.91 2.654.752a3.082 3.082 0 002.217-1.584l1.862-.317zM8.38 13.953l-.78.137.55 3.12.78-.137-.55-3.12zm1.937-.35l-1.17.207.55 3.12 1.17-.206-.55-3.12zm1.547-.28l-.78.138.55 3.12.78-.137-.55-3.12zm1.938-.35l-1.17.206.55 3.12 1.17-.206-.55-3.12z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
