import { useQuery } from '@tanstack/react-query';
import { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { ConnectedEventInfo } from 'src/components/Events/ConnectedEventInfo';
import { EmailThread } from 'src/components/Messages/EmailThread';
import { SupportCaseBody } from 'src/components/Messages/SupportCaseBody';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import {
  Content,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { ConversationDataContext } from 'src/contexts/ConversationDataContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { SellerSupportCaseDataContext } from 'src/contexts/SellerSupportCaseDataContext';
import { HorizontalTextList } from 'src/core/POS/HorizontalTextList';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useGetSaleWithCatalogData } from 'src/hooks/api/useGetSaleWithCatalogData';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getEventPerformerVenue,
  lookupEventInCatalog,
} from 'src/utils/eventWithDataUtils';
import { getListingNoteIcon } from 'src/utils/listingNotesUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AutoPoClient,
  AutoPoInboundEmail,
  Event,
  Feature,
  ListingNote,
  Marketplace,
  Performer,
  Seating,
  SellerSupportCase,
  SellerSupportCaseClient,
  Venue,
} from 'src/WebApiController';

import {
  ConversationType,
  convertEmailClassificationTypeToConversationType,
} from '../ConversationSidePanel/ConversationListItem/ConversationListItem';
import { ConversationHeaderDisplay } from './ConversationHeaderDisplay';
import * as styles from './ConversationMainPanel.css';

export const ConversationMainPanel = ({
  onEmailClassified,
}: {
  onEmailClassified?: (inboundEmailId: number) => void;
}) => {
  const { inboundEmailId, sellerSupportCaseId } = useParams();
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    sellerSupportCaseId != null;

  const sellerSupportCaseQuery = useQuery({
    queryKey: [
      'SellerSupportCaseClient.getSellerSupportCases',
      activeAccountWebClientConfig.activeAccountId,
      sellerSupportCaseId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      return tryInvokeApi(
        async () => {
          const sellerSupportCases = await new SellerSupportCaseClient(
            activeAccountWebClientConfig
          ).getSellerSupportCases(
            {
              isMostRecent: null,
              sellerSupportCaseIds: [Number(sellerSupportCaseId)],
            },
            null,
            null
          );
          return sellerSupportCases[0] ?? null;
        },
        (error) => {
          trackError('SellerSupportCaseClient.getSellerSupportCases', error, {
            sellerSupportCaseId,
          });
        }
      );
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
  });

  const shouldQuery2 =
    activeAccountWebClientConfig.activeAccountId != null &&
    inboundEmailId != null;
  const inboundEmailQuery = useQuery({
    queryKey: [
      'AutoPoClient.getInboundEmails',
      activeAccountWebClientConfig.activeAccountId,
      inboundEmailId,
    ],
    queryFn: async () => {
      if (!shouldQuery2) {
        return null;
      }
      return tryInvokeApi(
        async () => {
          const inboundEmails = await new AutoPoClient(
            activeAccountWebClientConfig
          ).getInboundEmails(
            {
              inboundEmailIds: [Number(inboundEmailId)],
            },
            null,
            null
          );
          return inboundEmails[0] ?? null;
        },
        (error) => {
          trackError('AutoPoClient.getInboundEmails', error, {
            inboundEmailId,
          });
        }
      );
    },

    enabled: shouldQuery2,
    refetchOnWindowFocus: false,
  });

  if (sellerSupportCaseQuery.isLoading || inboundEmailQuery.isLoading) {
    return <PosSpinner />;
  }
  return (
    <div className={styles.conversationMainPanel}>
      {sellerSupportCaseQuery.data && (
        <SupportCaseHeaderDisplay
          sellerSupportCase={sellerSupportCaseQuery.data}
        />
      )}
      {inboundEmailQuery.data && (
        <EmailHeaderDisplay
          inboundEmail={inboundEmailQuery.data}
          onEmailClassified={onEmailClassified}
        />
      )}
      <ConversationMainDisplay
        sellerSupportCase={sellerSupportCaseQuery.data}
        inboundEmail={inboundEmailQuery.data}
      />
    </div>
  );
};

const EmailHeaderDisplay = ({
  inboundEmail,
  onEmailClassified,
}: {
  inboundEmail: AutoPoInboundEmail;
  onEmailClassified?: (inboundEmailId: number) => void;
}) => {
  const conversationType = convertEmailClassificationTypeToConversationType(
    inboundEmail.classificationType
  );

  const { getPurchaseOrderDetails } = useContext(ConversationDataContext);
  const [orderId, setOrderId] = useState<string>();
  const [merchantName, setMerchantName] = useState<string>();

  type EventInfo = {
    event: Event;
    venue: Venue | null;
    performer: Performer | null;
    seatings: Seating[];
    listingNotes?: ListingNote[] | null;
  };
  const [eventInfos, setEventInfos] = useState<EventInfo[]>([]);
  const { data } = useCatalogDataContext();

  const fetchDisplayContent = useCallback(async () => {
    let orderId: string | undefined;
    let merchantName: string | undefined;
    let eventInfos: EventInfo[] = [];

    if (conversationType === ConversationType.Purchase) {
      if (inboundEmail.purchaseOrderId) {
        const purchaseOrder = await getPurchaseOrderDetails(
          inboundEmail.purchaseOrderId
        );
        if (purchaseOrder) {
          orderId = purchaseOrder.vendOrdId ?? undefined;
          merchantName = purchaseOrder.vendor?.name;

          const eventInfoMap: Record<string, EventInfo> = {};
          for (const tg of purchaseOrder.ticketGroups) {
            const { event, venue, performer } = getEventPerformerVenue(
              tg.viagVirtualId,
              data
            );
            if (event?.event) {
              if (!(event.event.viagVirtualId in eventInfoMap)) {
                eventInfoMap[event.event.viagVirtualId] = {
                  event: event.event,
                  venue: venue ?? null,
                  performer: performer ?? null,
                  seatings: [],
                  listingNotes: tg.seatTraits,
                };
              }
              eventInfoMap[event.event.viagVirtualId].seatings.push(tg.seating);
            }
          }
          eventInfos = Object.values(eventInfoMap).sort((a, b) => {
            if (a.event.dates.start && b.event.dates.start) {
              return (
                new Date(a.event.dates.start).getTime() -
                new Date(b.event.dates.start).getTime()
              );
            }
            return a.event.name.localeCompare(b.event.name);
          });
        }
      }
    }

    setOrderId(orderId);
    setMerchantName(merchantName);
    setEventInfos(eventInfos);
  }, [
    conversationType,
    inboundEmail.purchaseOrderId,
    getPurchaseOrderDetails,
    data,
  ]);

  useEffect(() => {
    fetchDisplayContent();
  }, [inboundEmail, fetchDisplayContent]);

  const messageSaleDataQuery = useGetSaleWithCatalogData(inboundEmail.saleId);
  const { sale } = messageSaleDataQuery.data ?? {};

  useEffect(() => {
    const { sale, catalog: saleCatalog } = messageSaleDataQuery.data ?? {};
    if (sale && saleCatalog) {
      if (sale.mkp) {
        setMerchantName(sale.mkp);
      }
      const event = lookupEventInCatalog(
        saleCatalog,
        sale.viagVirtualId,
        sale.posEvId
      )?.event;
      if (event) {
        setEventInfos([
          {
            event,
            venue: saleCatalog.venues[event.venueId],
            performer: event.perfId
              ? saleCatalog.performers[event.perfId]
              : null,
            seatings: [sale.seating],
          },
        ]);
      }
    }
  }, [messageSaleDataQuery.data]);

  const contentContext = useContentContext();
  const hasListingNotesAdditionalInfoFeature = useUserHasFeature(
    Feature.ListingNotesAdditionalInfo
  );

  return (
    <ConversationHeaderDisplay
      inboundEmail={inboundEmail}
      conversationTitle={inboundEmail.subject}
      purchaseOrderId={inboundEmail.purchaseOrderId ?? undefined}
      saleId={inboundEmail.saleId ?? undefined}
      entityId={
        orderId ? (
          <>
            <Content id={ContentId.PurchaseId} />: {orderId}
          </>
        ) : sale ? (
          <>
            <Content id={ContentId.SaleId} />: {sale.idOnMkp}
          </>
        ) : undefined
      }
      merchantName={merchantName}
      vendorId={inboundEmail.purchaseOrderVendorId}
      fromEmailAddress={inboundEmail.fromEmailAddress ?? undefined}
      toEmailAddress={inboundEmail.toEmailAddress ?? undefined}
      showClassifyOptions={conversationType === ConversationType.Uncategorized}
      onEmailClassified={onEmailClassified}
    >
      {eventInfos.map(({ event, venue, performer, seatings, listingNotes }) => {
        return (
          <Fragment key={event.viagVirtualId}>
            <ConnectedEventInfo
              showYear
              event={event}
              venue={venue}
              performer={performer}
            />
            {seatings.map((seating, i) => (
              <SeatingInfo key={i} {...seating} />
            ))}
            {listingNotes?.length ? (
              <HorizontalTextList
                className={styles.listingNotes}
                preIcon={getListingNoteIcon(
                  listingNotes[0],
                  contentContext,
                  hasListingNotesAdditionalInfoFeature
                )}
                texts={listingNotes.map((n) => n.listingNoteText)}
                maxTextsToShowOnHover={listingNotes.length}
              />
            ) : null}
          </Fragment>
        );
      })}
    </ConversationHeaderDisplay>
  );
};

const SupportCaseHeaderDisplay = ({
  sellerSupportCase,
}: {
  sellerSupportCase: SellerSupportCase;
}) => {
  const generalMessageContent = useContent(ContentId.GeneralMessage);
  const { getSellerUserDetails } = useContext(SellerSupportCaseDataContext);

  const [displayEmail, setDisplayEmail] = useState<string>();

  useEffect(() => {
    (async function fetchDisplayEmail() {
      const sellerUserId =
        sellerSupportCase?.impersonatedUserId ??
        sellerSupportCase?.createByUserId;

      if (sellerUserId) {
        const sellerUser = await getSellerUserDetails(sellerUserId);
        setDisplayEmail(sellerUser?.email);
      }
    })();
  }, [sellerSupportCase, getSellerUserDetails]);

  return (
    <ConversationHeaderDisplay
      conversationTitle={generalMessageContent}
      merchantName={Marketplace.StubHub}
      toEmailAddress={displayEmail}
    />
  );
};

const ConversationMainDisplay = ({
  sellerSupportCase,
  inboundEmail,
}: {
  sellerSupportCase?: SellerSupportCase | null;
  inboundEmail?: AutoPoInboundEmail | null;
}) => {
  return (
    <div className={styles.conversationMainDisplay}>
      {sellerSupportCase && (
        <SupportCaseBody
          sellerSupportCaseId={sellerSupportCase.sellerSupportCaseId}
        />
      )}
      {inboundEmail && (
        <EmailThread
          inboundEmailId={inboundEmail.inboundEmailId}
          purchaseOrderId={inboundEmail.purchaseOrderId}
          saleId={inboundEmail.saleId}
          toEmailAddress={inboundEmail.toEmailAddress}
          classificationType={inboundEmail.classificationType}
        />
      )}
    </div>
  );
};
