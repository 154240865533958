import { Table } from '@tanstack/react-table';
import {
  ChangeEvent,
  MutableRefObject,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Content } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { PosTablePaginationFooterHeight } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import styled from 'styled-components/macro';

import * as styles from './PaginationFooter.css';

type PaginationFooterProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>;
  tableWrapperRef: MutableRefObject<HTMLDivElement | null>;
  showPageSizeOptions?: boolean;
};

const PAGE_SIZE_OPTIONS = [10, 25, 50, 100, 250, 500];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const PaginationFooter = ({
  table,
  tableWrapperRef,
  showPageSizeOptions,
}: PaginationFooterProps) => {
  const paginationWrapperRef = useRef<HTMLDivElement | null>(null);
  const [isPageIndexEmpty, setIsPageIndexEmpty] = useState(false);
  const previousPageSize = table.options.state.pagination?.pageSize ?? 10;

  const onPaginationChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const pageSize = parseInt(e.currentTarget.value);
    if (!pageSize) return;
    table.setPageSize(() => pageSize);
  };

  const displayedPageCountOptions = PAGE_SIZE_OPTIONS.includes(previousPageSize)
    ? PAGE_SIZE_OPTIONS
    : [...PAGE_SIZE_OPTIONS, previousPageSize].sort((a, b) => a - b);

  const footerHeight = paginationWrapperRef.current?.offsetHeight ?? 0;
  useLayoutEffect(() => {
    tableWrapperRef.current?.style.setProperty(
      PosTablePaginationFooterHeight,
      `${footerHeight}px`
    );
  }, [tableWrapperRef, footerHeight]);

  return (
    <Pagination ref={paginationWrapperRef}>
      {showPageSizeOptions ? (
        <div className={styles.pageSizeSelect}>
          <span id="resultsPerPageText">
            <Content id={ContentId.ItemsPerPage} />:
          </span>
          <select
            onChange={onPaginationChange}
            aria-labelledby="resultsPerPageText"
            defaultValue={previousPageSize}
          >
            {displayedPageCountOptions.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      <div className={styles.pagination}>
        <Button
          variant={'text'}
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </Button>
        <Button
          variant={'text'}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </Button>
        <div className={styles.pageInputWrapper}>
          <input
            className={styles.pageInput}
            aria-label="page number"
            type="number"
            inputMode="numeric"
            min="1"
            max={table.getPageCount().toString()}
            value={
              isPageIndexEmpty ? '' : table.getState().pagination.pageIndex + 1
            }
            onChange={(e) => {
              const val = e.target?.value;
              if (val === '') {
                setIsPageIndexEmpty(true);
                return;
              } else {
                setIsPageIndexEmpty(false);
              }
              const page = val ? Number(val) - 1 : 0;
              table.setPageIndex(page);
            }}
          />
          <span className={styles.totalPages}>
            of <strong>{table.getPageCount()}</strong>
          </span>
        </div>
        <Button
          variant={'text'}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </Button>
        <Button
          variant={'text'}
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </Button>
      </div>
    </Pagination>
  );
};

const Pagination = styled.div`
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({
    theme: {
      typography: { size },
    },
  }) => size(1)};
  background: ${(props) => props.theme.colors.backgroundSecondary};
`;
