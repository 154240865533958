import { PropsWithChildren } from 'react';

import * as styles from './DividerLine.css';

export const DividerLine = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.dividerLine}>
      <span className={styles.dividerLineBar} />
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
};
