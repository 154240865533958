import {
  ComponentProps,
  FocusEvent,
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
} from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { TextField } from 'src/core/ui';

export type PosTextFieldProps = ComponentProps<typeof TextField.Input> & {
  trimOnBlur?: boolean;
  prefixDisplay?: ReactNode;
  postfixDisplay?: ReactNode;
  rootProps?: Omit<ComponentProps<typeof TextField.Root>, 'children'>;
};

export const PosTextField = forwardRef<HTMLInputElement, PosTextFieldProps>(
  function PosTextField(
    {
      onBlur,
      onKeyUp,
      trimOnBlur,
      prefixDisplay,
      postfixDisplay,
      rootProps,
      ...props
    },
    ref
  ): ReactElement {
    const customOnBlur = useCallback(
      (params: FocusEvent<HTMLInputElement>) => {
        if (trimOnBlur) {
          params.target.value = params.target.value.trim();
        }
        if (onBlur) {
          onBlur(params);
        }
      },
      [trimOnBlur, onBlur]
    );

    return (
      <TextField.Root disabled={props.disabled} {...rootProps}>
        {prefixDisplay}
        <TextField.Input
          ref={ref}
          {...props}
          onBlur={customOnBlur}
          onKeyUp={onKeyUp}
        />
        {postfixDisplay}
      </TextField.Root>
    );
  }
);

export const getTextFieldState = (
  error?:
    | string
    | string[]
    | FieldError
    | FieldError[]
    | Merge<FieldError, FieldErrorsImpl>
): ComponentProps<typeof TextField.Root>['state'] | undefined => {
  if (!error) return undefined;

  const isArray = Array.isArray(error);

  return (isArray ? error.length : error) ? 'error' : undefined;
};
