import { Row } from '@tanstack/react-table';
import { useRef } from 'react';
import { useKeyPress } from 'src/hooks/useKeyPress';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { isInFullPageEventView } from 'src/utils/deepLinkUtils';
import { Feature } from 'src/WebApiController';

export const useSingleTableShortcutSelection = <T,>({
  isSideTable,
}: {
  isSideTable?: boolean;
}) => {
  const hasEventViewShiftClickSelectionFeature = useUserHasFeature(
    Feature.FullPageEventViewShiftClickSelection
  );
  const inFullPageEventView = isInFullPageEventView();

  const lastSelectedRowRefIndex = useRef<number | null>(null);
  const isShiftKeyPressed = useKeyPress('Shift');
  const isCtrlKeyPressed = useKeyPress('Control');
  const isCmdKeyPressed = useKeyPress('Meta');

  const isCtrlOrCmdKeyPressed = isCtrlKeyPressed || isCmdKeyPressed;

  function updateSelectionForRowAndSubRows({
    row,
    rowId,
    newIsSelected,
    newRowSelection,
  }: {
    row: Row<T | null>;
    rowId: string;
    newIsSelected: boolean;
    newRowSelection: Record<string, boolean>;
  }) {
    newRowSelection[rowId] = newIsSelected;

    if (row.subRows && row.subRows.length > 0) {
      row.subRows.forEach((subRow) => {
        updateSelectionForRowAndSubRows({
          row: subRow,
          rowId: subRow.id,
          newIsSelected,
          newRowSelection,
        });
      });
    }
  }

  return {
    allowShiftClickSelection:
      hasEventViewShiftClickSelectionFeature &&
      inFullPageEventView &&
      !isSideTable,
    isShiftKeyPressed,
    isCtrlOrCmdKeyPressed,
    lastSelectedRowRefIndex,
    updateSelectionForRowAndSubRows,
  };
};
