import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function BarcodeIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M8.8 7.2h1.6v9.6H8.8V7.2zm-5.2 7.6v3.6h3.6V20H2v-5.2h1.6zm4-7.6v9.6H5.2V7.2h2.4zM7.2 4v1.6H3.6v3.6H2V4h5.2zM22 14.8V20h-5.2v-1.6h3.6v-3.6H22zm-3.2-7.6v9.6h-1.6V7.2h1.6zM22 4v5.2h-1.6V5.6h-3.6V4H22zm-8.4 3.2v9.6H16V7.2h-2.4zm-2 0v9.6h.8V7.2h-.8z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
