import { isEqual } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { TicketTypePriorityInput } from 'src/components/common/TicketTypePriorityInput';
import { PURCHASE_BULK_BACKFILL_DELIVERY_SETTINGS_KEY } from 'src/components/Purchases/PurchaseActionDropdown';
import { DeliveryTypeSelector } from 'src/components/Selectors/DeliveryTypeSelector';
import { PurchasePaymentMethodSelector } from 'src/components/Selectors/PurchasePaymentMethodSelector';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack, Switch } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FieldWrapper } from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { EmptyPurchaseQuery } from 'src/utils/eventQueryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  DeliveryType,
  Feature,
  PurchaseClient,
  PurchaseOrderQuery,
  PurchaseOrderState,
  PurchaseVendorAccountDetails,
} from 'src/WebApiController';

import { BulkUpdateDeliveryOverrideDialog } from '../BulkEdits/BulkUpdateDeliveryOverrideDialog';
import * as styles from './PurchaseVendorAccountDialog.css';

export const EmptyVendorAccountId = '00000000-0000-0000-0000-000000000000';

const EmptyVendorAccountDetails: PurchaseVendorAccountDetails = {
  vendorId: 0, // Need to fill in as a required field
  name: '',
  id: EmptyVendorAccountId,
  email: '',
  password: null,
  dftBuyerId: '',
  dftPayMethodId: null,
  isNoFulfill: null,
  delivType: null,
  tktTypeRules: null,
};

export type PurchaseVendorAccountDialogProps = {
  vendorAccountId: string | null;
  vendorId: number;
  onSave: (data: PurchaseVendorAccountDetails) => void;
} & ComponentProps<typeof RSModal>;

export function PurchaseVendorAccountDialog({
  vendorAccountId,
  vendorId,
  ...rest
}: PurchaseVendorAccountDialogProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const methods = useForm<PurchaseVendorAccountDetails>();

  const getVendorAccountDetails = useCallback(() => {
    if (vendorAccountId && vendorAccountId !== EmptyVendorAccountId) {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const details = await new PurchaseClient(
            activeAccountWebClientConfig
          ).getVendorAccountDetails(vendorAccountId);

          if (details?.id === vendorAccountId) {
            methods.reset(details);
          } else {
            methods.reset({
              ...EmptyVendorAccountDetails,
              vendorId,
            });
          }

          methods.reset(details);
        },
        (error) => {
          showErrorDialog('PurchaseClient.getVendorAccountDetails', error, {
            trackErrorData: { vendorAccountId },
          });
        }
      ).finally(() => {
        setIsLoading(false);
      });
    } else {
      methods.reset({
        ...EmptyVendorAccountDetails,
        vendorId,
      });
    }
  }, [
    activeAccountWebClientConfig,
    methods,
    showErrorDialog,
    vendorAccountId,
    vendorId,
  ]);

  useEffect(() => {
    if (rest.isOpen) {
      getVendorAccountDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorAccountId, rest.isOpen]);

  return (
    <FormProvider {...methods}>
      <PurchaseVendorAccountDialogBody isLoading={isLoading} {...rest} />
    </FormProvider>
  );
}

export const PurchaseVendorAccountDialogBody = ({
  isLoading,
  onSave,
  onClosed,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onOkay, // unused
  ...rest
}: Omit<PurchaseVendorAccountDialogProps, 'vendorAccount'> & {
  isLoading: boolean;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey = PURCHASE_BULK_BACKFILL_DELIVERY_SETTINGS_KEY;

  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    register,
    setError,
    handleSubmit,
    formState,
    getValues,
    watch,
    setValue,
  } = useFormContext<PurchaseVendorAccountDetails>();
  const requiredMsg = useContent(ContentId.Required);

  const hasVendorAccountDeliveryStrategyFeature = useUserHasFeature(
    Feature.VendorAccountDeliveryStrategy
  );
  const hasAutoFulfillSettingFeature = useUserHasFeature(
    Feature.AutoFulfillSetting
  );

  const { id } = getValues();

  const nameError = formState.errors.name?.message;
  const emailError = formState.errors.email?.message;
  const paymentMethodError = formState.errors.dftPayMethodId?.message;
  const buyerUserIdError = formState.errors.dftBuyerId?.message;

  const vendorAccountName = watch('name');
  const encryptedEmailAddress = watch('email');
  const defaultPurchasePaymentMethodId = watch('dftPayMethodId');
  const defaultBuyerUserId = watch('dftBuyerId');
  const deliveryType = watch('delivType');
  const isNoFulfill = watch('isNoFulfill');
  const ticketTypeRules = watch('tktTypeRules');

  const [skipAutoFulfill, setSkipAutoFulfill] = useState(isNoFulfill == null);
  const [skipDeliveryOverride, setSkipDeliveryOverride] = useState(
    deliveryType == null
  );

  useEffect(() => {
    if (!isLoading) {
      setSkipAutoFulfill(isNoFulfill == null);
      setSkipDeliveryOverride(deliveryType == null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const updateDialog = useBasicDialog();

  const {
    openDialog: openBulkUpdateDialog,
    closeDialog: closeBulkUpdateDialog,
  } = useDialog(
    DialogId.BulkBackfillDeliverySettings,
    BulkUpdateDeliveryOverrideDialog
  );

  const purchaseQuery = useMemo(() => {
    return {
      ...EmptyPurchaseQuery,
      purchaseOrderState: PurchaseOrderState.Active,
      purchaseVendorAccountIds: [id],
    } as PurchaseOrderQuery;
  }, [id]);

  const onBulkUpdate = useCallback(
    async (
      updateSalesOfListings: boolean,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkBackfillDeliverySettingsPreview(
              purchaseQuery,
              updateSalesOfListings,
              true
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkBackfillDeliverySettingsPreview', error, {
              trackErrorData: { purchaseQuery },
            });
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkBackfillDeliverySettings(
              preview!.preview,
              updateSalesOfListings,
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              closeBulkUpdateDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkBackfillDeliverySettings', error, {
              trackErrorData: {
                preview,
              },
            });
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkUpdateDialog,
      purchaseQuery,
      showErrorDialog,
      updateKey,
    ]
  );

  const onSubmit = useCallback(() => {
    let hasErrors = false;
    if (!encryptedEmailAddress) {
      setError('email', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }
    if (!vendorAccountName) {
      setError('name', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (!hasErrors) {
      const autoFulfillRemovedOrNotChanged =
        skipAutoFulfill || isNoFulfill === formState.defaultValues?.isNoFulfill;
      const deliverySettingsRemovedOrNotChanged =
        skipDeliveryOverride ||
        (deliveryType === formState.defaultValues?.delivType &&
          isEqual(ticketTypeRules, formState.defaultValues?.tktTypeRules));

      if (
        id === EmptyVendorAccountId ||
        (autoFulfillRemovedOrNotChanged && deliverySettingsRemovedOrNotChanged)
      ) {
        handleSubmit(onSave)();
      } else {
        openBulkUpdateDialog({
          updateKey: updateKey,
          entityType: ActionOutboxEntityType.TicketGroup,
          ...updateDialog.dialogProps,
          onSaveOverrideSettings: handleSubmit(onSave),
          onBulkUpdate: onBulkUpdate,
          onCancel: () => {
            closeBulkUpdateDialog();
          },
          backfillDeliveryStrategyFeature:
            Feature.BackfillDeliveryStrategyVendorAccount,
          backfillDeliveryStrategyToSaleFeature:
            Feature.BackfillDeliveryStrategyVendorAccountToSales,
        });
      }
    }
  }, [
    closeBulkUpdateDialog,
    deliveryType,
    encryptedEmailAddress,
    formState.defaultValues?.delivType,
    formState.defaultValues?.isNoFulfill,
    formState.defaultValues?.tktTypeRules,
    handleSubmit,
    id,
    isNoFulfill,
    onBulkUpdate,
    onSave,
    openBulkUpdateDialog,
    requiredMsg,
    setError,
    skipAutoFulfill,
    skipDeliveryOverride,
    ticketTypeRules,
    updateDialog.dialogProps,
    updateKey,
    vendorAccountName,
  ]);

  return (
    <GenericDialog
      {...rest}
      size="m"
      header={
        <Content
          id={
            id === EmptyVendorAccountId
              ? ContentId.AddPurchaseVendorAccount
              : ContentId.EditPurchaseVendorAccount
          }
        />
      }
      footer={
        <>
          {onClosed && (
            <CancelButton
              onClick={onClosed}
              disabled={formState.isSubmitting || isLoading}
            />
          )}
          <Button
            variant={'regular'}
            onClick={onSubmit}
            disabled={formState.isSubmitting || isLoading}
          >
            <Content id={ContentId.Save} />
          </Button>
        </>
      }
      onCancel={onClosed}
    >
      {isLoading ? (
        <PosSpinner />
      ) : (
        <div className={styles.purchaseVendorAccountDialogContainer}>
          <FieldWrapper>
            <PosFormField
              errors={nameError}
              label={<Content id={ContentId.Name} />}
            >
              <PosTextField
                rootProps={{
                  disabled: formState.isSubmitting,
                  state: getTextFieldState(nameError),
                }}
                disabled={formState.isSubmitting}
                {...register('name', {
                  required: true,
                })}
              />
            </PosFormField>
          </FieldWrapper>

          <FieldWrapper>
            <PosFormField
              errors={emailError}
              label={<Content id={ContentId.Email} />}
            >
              <PosTextField
                rootProps={{
                  disabled: formState.isSubmitting,
                  state: getTextFieldState(emailError),
                }}
                disabled={formState.isSubmitting}
                {...register('email', {
                  required: true,
                })}
              />
            </PosFormField>
          </FieldWrapper>

          <FieldWrapper>
            <PosFormField
              errors={emailError}
              label={<Content id={ContentId.Password} />}
            >
              <PosTextField
                rootProps={{
                  disabled: formState.isSubmitting,
                  state: getTextFieldState(emailError),
                }}
                type="password"
                disabled={formState.isSubmitting}
                {...register('password', {
                  required: false,
                })}
              />
            </PosFormField>
          </FieldWrapper>

          <FieldWrapper>
            <PosFormField
              errors={buyerUserIdError}
              label={<Content id={ContentId.DefaultBuyer} />}
            >
              <SellerAccountEmployeeSelector
                value={
                  defaultBuyerUserId ? String(defaultBuyerUserId) : undefined
                }
                style={{ width: '100%' }}
                placeholderText={ContentId.ChooseBuyer}
                onChange={(userId) => {
                  if (userId) {
                    setValue('dftBuyerId', userId);
                  }
                }}
              />
            </PosFormField>
          </FieldWrapper>

          <FieldWrapper>
            <PosFormField
              errors={paymentMethodError}
              label={<Content id={ContentId.DefaultPaymentMethod} />}
            >
              <PurchasePaymentMethodSelector
                style={{
                  width: '100%',
                }}
                value={String(defaultPurchasePaymentMethodId)}
                onChange={(paymentMethodId) => {
                  if (paymentMethodId) {
                    setValue('dftPayMethodId', Number(paymentMethodId));
                  }
                }}
              />
            </PosFormField>
          </FieldWrapper>

          {hasVendorAccountDeliveryStrategyFeature && (
            <Stack gap="m" direction="column">
              <div className={styles.sectionHeading}>
                <Content id={ContentId.DefaultDeliveryConfigurations} />
              </div>
              <p className={styles.subtleText}>
                <Content id={ContentId.UpdateFulfillmentSettingsMessage} />
              </p>

              {hasAutoFulfillSettingFeature && (
                <>
                  <Checkbox
                    label={<Content id={ContentId.EnableAutoFulfillOverride} />}
                    labelPosition="right"
                    onChange={(e) => {
                      const skip = !e.target.checked;
                      setSkipAutoFulfill(skip);
                      if (skip) {
                        setValue('isNoFulfill', null, {
                          shouldDirty: true,
                        });
                      } else {
                        // We want to default to false if the user enables the setting
                        setValue('isNoFulfill', true, {
                          shouldDirty: true,
                        });
                      }
                    }}
                    checked={!skipAutoFulfill}
                  />
                  {!skipAutoFulfill && (
                    <FieldWrapper>
                      <PosFormField
                        label={<Content id={ContentId.AutoFulfill} />}
                      >
                        <Switch
                          checked={!isNoFulfill}
                          disabled={formState.isSubmitting}
                          onChange={(e) => e.stopPropagation()}
                          onCheckedChange={(isChecked) => {
                            setValue('isNoFulfill', !isChecked);
                          }}
                        />
                      </PosFormField>
                    </FieldWrapper>
                  )}
                </>
              )}

              <Checkbox
                label={<Content id={ContentId.EnableDeliveryOverride} />}
                labelPosition="right"
                onChange={(e) => {
                  const skip = !e.target.checked;
                  setSkipDeliveryOverride(skip);
                  if (skip) {
                    setValue('delivType', null, {
                      shouldDirty: true,
                    });
                  } else {
                    setValue('delivType', DeliveryType.InApp, {
                      shouldDirty: true,
                    });
                  }

                  setValue('tktTypeRules', null, {
                    shouldDirty: true,
                  });
                }}
                checked={!skipDeliveryOverride}
              />
              {!skipDeliveryOverride && (
                <>
                  <FieldWrapper>
                    <PosFormField
                      label={<Content id={ContentId.DeliveryType} />}
                    >
                      <DeliveryTypeSelector
                        disabled={formState.isSubmitting}
                        value={deliveryType}
                        enableEmptySelection={true}
                        placeholderText={ContentId.None}
                        onChange={(newD) => {
                          if (newD !== deliveryType) {
                            setValue('delivType', newD);
                            if (!newD) {
                              setValue('tktTypeRules', null);
                            }
                          }
                        }}
                      />
                    </PosFormField>
                  </FieldWrapper>
                  {deliveryType && (
                    <FieldWrapper>
                      <PosFormField
                        label={<Content id={ContentId.DeliveryStrategy} />}
                      >
                        <TicketTypePriorityInput
                          deliveryType={deliveryType}
                          ticketTypeRules={ticketTypeRules}
                          onTicketTypeRulesChange={(newRules) => {
                            setValue('tktTypeRules', newRules);
                          }}
                          disabled={formState.isSubmitting}
                        />
                      </PosFormField>
                    </FieldWrapper>
                  )}
                </>
              )}
            </Stack>
          )}
        </div>
      )}
    </GenericDialog>
  );
};
