import { useCallback, useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { InventoryEventPage } from 'src/components/Listings/InventoryEventPage';
import { MainFilterBar } from 'src/components/MainFilterBar';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { CatalogMetricsContextProvider } from 'src/contexts/CatalogMetricsContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { EventHubContextProvider } from 'src/contexts/EventHubContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { ListingNotificationContextProvider } from 'src/contexts/ListingNotificationContext';
import { MultiSelectionContextProvider } from 'src/contexts/MultiSelectionContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { useGetActiveAccountClientConfig } from 'src/hooks/useGetActiveAccountClientConfig';
import { LayoutContent } from 'src/navigations/LayoutContent';
import {
  getDefaultReturnUrl,
  INVENTORY_USER_SETTINGS,
  queryIdSearchParam,
} from 'src/navigations/Routes/InventoryEvent/constants';
import {
  EventIdQueryParam,
  InventoryDeeplinkQueryParam,
} from 'src/utils/constants/constants';
import { removeDeepLinkUrlPartsFromRelativeUrl } from 'src/utils/deepLinkUtils';
import { EmptyListingQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { ListingCatalogQueryKey } from 'src/utils/inventoryUtils';
import { addListingMetrics } from 'src/utils/ticketMetricUtils';
import {
  ActionOutboxEntityType,
  ListingClient,
  ListingDetails,
  ListingMetrics,
  ListingQuery,
  UserSetting,
} from 'src/WebApiController';

import {
  getCatalogData,
  getCatalogDataExpanded,
  getCatalogMetrics,
  listingQueryValueTransformFromUrl,
  listingQueryValueTransformToUrl,
} from '../Inventory';
import { MainRoute } from '../MainRoute';
import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  POS_EVENT_IDS_SEPARATOR,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_QUERY_PARAM,
  TAB_EVENT_IDS_SEPARATOR,
} from './InventoryEvent.constants';

export function InventoryEvent() {
  const [searchParams] = useSearchParams();
  const oldPosEventId = searchParams.get(queryIdSearchParam);
  const viagEventId = searchParams.get(EventIdQueryParam);

  const activeEventIds = searchParams.get(ACTIVE_EVENT_IDS_QUERY_PARAM);
  const tabEventIds = searchParams.get(TAB_EVENT_IDS_QUERY_PARAM);

  const activeEventIds2 = searchParams.get(ACTIVE_EVENT_IDS_2_QUERY_PARAM);
  const tabEventIds2 = searchParams.get(TAB_EVENT_IDS_2_QUERY_PARAM);

  const location = useLocation();

  const posEventIds = useMemo(
    // We need to make sure all the ids in the params are lowercase or else it won't be found in the CatalogDataContext
    () =>
      (tabEventIds != null
        ? activeEventIds?.toLowerCase()?.split(POS_EVENT_IDS_SEPARATOR)
        : oldPosEventId?.toLowerCase()?.split(POS_EVENT_IDS_SEPARATOR)) ?? [],
    [activeEventIds, oldPosEventId, tabEventIds]
  );

  const viagogoVirtualId = useMemo(
    // We need to make sure all the ids in the params are lowercase or else it won't be found in the CatalogDataContext
    () =>
      tabEventIds2 != null
        ? activeEventIds2?.toLowerCase()
        : viagEventId?.toLowerCase(),
    [activeEventIds2, tabEventIds2, viagEventId]
  );

  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const getCatalogDataExpandedCallback = useCallback(
    (ids: string[], filterQuery: ListingQuery) =>
      getCatalogDataExpanded(ids, filterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('ListingClient.getListingsForEvents', error, {
            ...filterQuery,
            eventIds: ids,
          });
        },
      }),
    [activeAccountWebClientConfig, trackError]
  );

  const initialQuery = useMemo(() => {
    if (posEventIds.length || tabEventIds?.length) {
      const oldPosEventIds = tabEventIds
        ? tabEventIds
            .split(TAB_EVENT_IDS_SEPARATOR)
            .flatMap((eids) => eids.split(POS_EVENT_IDS_SEPARATOR))
        : posEventIds;
      return { ...EmptyListingQuery, oldPosEventIds };
    } else if (tabEventIds2?.length || viagogoVirtualId) {
      const eventOrMappingIds = tabEventIds2
        ? tabEventIds2.split(TAB_EVENT_IDS_SEPARATOR)
        : [viagogoVirtualId!];
      return { ...EmptyListingQuery, eventOrMappingIds };
    }

    return { ...EmptyListingQuery };
  }, [posEventIds, tabEventIds, tabEventIds2, viagogoVirtualId]);

  const getActClientCfg = useGetActiveAccountClientConfig(
    'InventoryEvent.ListingClient.getListingByListingId'
  );

  const getActivePosEntity = useCallback(
    async (listingId: number) => {
      const actAccountCfg = await getActClientCfg();

      const listingDetail = await new ListingClient(
        actAccountCfg
      ).getListingByListingId(listingId);

      if (listingDetail) {
        return {
          posEntityId: listingDetail.id,
          posEntity: listingDetail,
          posEntityDisplayId: listingDetail.idOnMkp,
        };
      }

      return {};
    },
    [getActClientCfg]
  );

  // Only use value provided at the first time (should be from '/inventory')
  const returnUrl = useMemo(() => {
    const relativeUrl =
      (location.state as { returnUrl?: string })?.returnUrl ||
      getDefaultReturnUrl(viagEventId);

    return removeDeepLinkUrlPartsFromRelativeUrl(relativeUrl, [
      InventoryDeeplinkQueryParam,
      ACTIVE_EVENT_IDS_QUERY_PARAM,
      TAB_EVENT_IDS_QUERY_PARAM,
      ACTIVE_EVENT_IDS_2_QUERY_PARAM,
      TAB_EVENT_IDS_2_QUERY_PARAM,
    ]);
  }, [location.state, viagEventId]);

  if (
    !posEventIds.length &&
    !tabEventIds?.length &&
    !tabEventIds2?.length &&
    !viagogoVirtualId
  ) {
    return null;
  }

  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={INVENTORY_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<ListingQuery>
        initialQuery={initialQuery}
        emptyQuery={EmptyListingQuery}
        viewModeSettingId={UserSetting.InventoryPageViewMode}
        saveQueryInUrl
        queryValueTransformToUrl={listingQueryValueTransformToUrl}
        queryValueTransformFromUrl={listingQueryValueTransformFromUrl}
      >
        <CatalogDataContextProvider<ListingQuery>
          queryKey={ListingCatalogQueryKey}
          getCatalogData={(c, f) => getCatalogData(c, f, true)}
          getCatalogDataExpanded={getCatalogDataExpandedCallback}
          transformEventData={transformData}
        >
          <CatalogMetricsContextProvider<ListingMetrics, ListingQuery>
            queryKey="getCatalogListingMetrics"
            getCatalogMetrics={getCatalogMetrics}
            addCatalogMetrics={addListingMetrics}
          >
            <ActivePosEntityProvider<ListingDetails>
              entityType={ActionOutboxEntityType.Listing}
              getActivePosEntity={getActivePosEntity}
            >
              <EventHubContextProvider>
                <ListingNotificationContextProvider>
                  <CatalogMultiSelectionContextProvider type="listing">
                    <MultiSelectionContextProvider>
                      <DialogProvider>
                        <LayoutContent
                          mainRoute={MainRoute.Inventory}
                          routeTitle=""
                        >
                          <MainFilterBar
                            isEventPage
                            returnUrl={returnUrl}
                            posEventId={posEventIds[0]}
                          />
                          <InventoryEventPage
                            posEventIds={posEventIds}
                            viagVirtualId={viagogoVirtualId}
                            returnUrl={returnUrl}
                          />
                        </LayoutContent>
                      </DialogProvider>
                    </MultiSelectionContextProvider>
                  </CatalogMultiSelectionContextProvider>
                </ListingNotificationContextProvider>
              </EventHubContextProvider>
            </ActivePosEntityProvider>
          </CatalogMetricsContextProvider>
        </CatalogDataContextProvider>
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}
