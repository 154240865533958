import clsx from 'clsx';
import { useMemo } from 'react';
import { PerformerCategoryIcon } from 'src/components/common/PerformerCategoryIcon';
import {
  MetricsDisplay,
  MetricsDisplayPlaceholder,
} from 'src/components/Metrics';
import { useAppContext } from 'src/contexts/AppContext';
import { useSidePanelContext } from 'src/contexts/SidePanelContext';
import { vars } from 'src/core/themes';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import {
  getInventoryListingMetrics,
  getSalesSaleMetrics,
} from 'src/utils/ticketMetricUtils';
import {
  CatalogEntity,
  ListingMetrics,
  Performer,
  SaleMetrics,
} from 'src/WebApiController';

import * as styles from './PerformerVenueDisplay.css';
import { PerformerVenueDisplayContainer } from './PerformerVenueDisplay.styled';

const GROUPING_METRICS_PLACEHOLDER_DIMS = { width: '50px' };

export function PerformerVenueDisplay({
  grouping,
  isSelected,
  onPerformerVenueClicked,
  metricsData,
  hideMetrics,
  disabled,
}: {
  grouping: CatalogEntity;
  isSelected: boolean;
  onPerformerVenueClicked: (group: CatalogEntity) => void;
  metricsData: Record<string, object> | undefined;
  hideMetrics?: boolean;
  disabled?: boolean;
}) {
  const { isCollapsed } = useSidePanelContext();
  const { mainRoute } = useAppContext();
  const { loginContext } = useAppContext();
  const displayMetrics = useMemo(() => {
    if (!metricsData) {
      return null;
    }
    return mainRoute === MainRoute.Sales
      ? getSalesSaleMetrics(
          metricsData[grouping.viagId!] as SaleMetrics,
          false,
          true,
          false
        )
      : getInventoryListingMetrics(
          metricsData[grouping.viagId!] as ListingMetrics,
          loginContext?.user?.activeAccount?.currencyCode
        );
  }, [
    grouping.viagId,
    loginContext?.user?.activeAccount?.currencyCode,
    mainRoute,
    metricsData,
  ]);

  const hasCategory = useMemo(() => {
    return (grouping as Performer).categ;
  }, [grouping]);

  const mainMetrics = useMemo(
    () => (mainRoute === MainRoute.Sales ? null : displayMetrics?.mainMetrics),
    [displayMetrics?.mainMetrics, mainRoute]
  );

  return (
    <PerformerVenueDisplayContainer>
      <div
        key={grouping.viagId}
        onClick={() => {
          if (!disabled) {
            onPerformerVenueClicked(grouping);
          }
        }}
        title={`${grouping.name} - ${grouping.descr}`}
        className={clsx(styles.mainGroupingDisplayDiv, {
          [styles.mainGroupingDisplaySelected]: isSelected,
          [styles.mainGroupingDisplayCollapsed]: isCollapsed,
          [styles.mainGroupingDisplayDivActive]: !disabled,
        })}
      >
        <div className={styles.performerVenueDiv}>
          <div className={styles.performerVenueNameDiv}>
            <div className={styles.performerVenueNameInfoDiv}>
              <div
                className={styles.headerContainer}
                style={disabled ? { color: vars.color.textPrimary } : {}}
              >
                <div className={styles.nameDiv}>{grouping.name}</div>
                <div className={styles.headerTicketContainer}>
                  {mainMetrics}
                </div>
              </div>

              <div className={styles.totalDescription}>
                <div
                  className={styles.description}
                  style={disabled ? { color: vars.color.textPrimary } : {}}
                >
                  {hasCategory && (
                    <span className={styles.categoryIconContainer}>
                      <PerformerCategoryIcon
                        performer={grouping as Performer}
                      />
                    </span>
                  )}
                  {/* TODO remove this in POS-1218 after the responsiveness of these tiles is fixed*/}
                  {grouping.descr?.replace(/ Music$/, '')}
                </div>
                {!hideMetrics && (
                  <span className={styles.metrics}>
                    {displayMetrics == null ? (
                      <MetricsDisplayPlaceholder
                        {...GROUPING_METRICS_PLACEHOLDER_DIMS}
                      />
                    ) : (
                      <MetricsDisplay
                        mainMetrics={null}
                        subMetrics={displayMetrics.subMetrics}
                      />
                    )}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PerformerVenueDisplayContainer>
  );
}
