import { useFormContext } from 'react-hook-form';
import { IconButton } from 'src/components/Buttons';
import { DeliveryTypeSelector } from 'src/components/Selectors/DeliveryTypeSelector';
import { vars } from 'src/core/themes';
import { SimpleTable } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DeleteIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Event, Feature } from 'src/WebApiController';

import { AutoFulfillCell } from './AutoFulfillCell';
import { SellerAccountEventSettingsInput } from './FulfillmentSection.types';
import { InHandCell } from './InHandCell';
import { TicketTypePriorityCell } from './TicketTypePriorityCell';
import { VendorCell } from './VendorCell';

export const EventSettingForm = ({
  event,
  disabled,
  onDeleteSetting,
  index,
}: {
  event?: Event;
  disabled?: boolean;
  onDeleteSetting: (index: number) => void;
  index: number;
}) => {
  const hasAutoFulfillSettingFeature = useUserHasFeature(
    Feature.AutoFulfillSetting
  );

  const { watch, setValue } = useFormContext<SellerAccountEventSettingsInput>();

  const settings = watch('settings');
  const delivType = watch(`settings.${index}.delivType`);

  return (
    <SimpleTable.Tr>
      <SimpleTable.Td>
        <VendorCell disabled={disabled} index={index} />
      </SimpleTable.Td>
      <SimpleTable.Td>
        <DeliveryTypeSelector
          value={delivType}
          onChange={(deliveryType) => {
            if (deliveryType !== delivType) {
              setValue(`settings.${index}.delivType`, deliveryType, {
                shouldDirty: true,
              });
              setValue(`settings.${index}.tktTypeRules`, null, {
                shouldDirty: true,
              });
            }
          }}
          disabled={disabled}
        />
      </SimpleTable.Td>
      <SimpleTable.Td>
        <TicketTypePriorityCell disabled={disabled} index={index} />
      </SimpleTable.Td>
      {hasAutoFulfillSettingFeature && (
        <SimpleTable.Td>
          <AutoFulfillCell disabled={disabled} index={index} />
        </SimpleTable.Td>
      )}
      <SimpleTable.Td>
        <InHandCell event={event} disabled={disabled} index={index} />
      </SimpleTable.Td>
      <SimpleTable.Td>
        <IconButton
          icon={
            <DeleteIcon
              fill={vars.color.textNegative}
              size={vars.iconSize.m}
              withHoverEffect
            />
          }
          disabled={disabled}
          titleContentId={ContentId.Delete}
          onClick={() => onDeleteSetting(index)}
        />
      </SimpleTable.Td>
    </SimpleTable.Tr>
  );
};
