import Flexsearch from 'flexsearch';
import { ReactNode, useCallback, useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { CheckIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import {
  Content,
  getContent,
  isContentId,
  useContent,
  useContentContext,
} from '../../../contexts/ContentContext';
import { PosDropdownItem } from '../PosDropdown';
import * as styles from './PosMultiSelect.css';

const EMPTY_CONTENT_DESIGNATOR = '____empty_content_____';
const USE_VIRTUOSO_MIN_OPTIONS_COUNT = 50;

export enum ToggleAllKeys {
  SelectAll = ContentId.SelectAll,
  ClearAll = ContentId.ClearAll,
}

export const isToggleAllValue = (value: string | ContentId | null): boolean => {
  return (
    !!value &&
    (value === ToggleAllKeys.SelectAll || value === ToggleAllKeys.ClearAll)
  );
};

export const PosMultiSelectOptions = ({
  contentHeight,
  selectedOptions,
  onChange,
  valueOptionsContent,
  valueOptionsIcon,
  emptySelectionText,
  searchText,
  sortMode = 'ascending',
  sortFn,
  useVirtualWindow: useVirtualWindowProp,
  expectedItemSizeForVirtualWindow = 40,
  flexSearchIndex,
  showSelectedOptionsFirst = true,
  showSelectAllOption,
  fetchNextPage,
  hasNextPage,
  isFetchingNextPage,
  includeSelectedInSearch,
}: {
  contentHeight: string;
  selectedOptions: string[];
  onChange: (o: string | null) => void;
  emptySelectionText?: string | ContentId;
  valueOptionsContent: Record<string, ContentId | string>;
  valueOptionsIcon?: Record<string, ReactNode>;
  searchText?: string | null;
  sortMode?: 'ascending' | 'descending' | 'none';
  sortFn?: (a: string, b: string) => number;
  useVirtualWindow?: boolean;
  expectedItemSizeForVirtualWindow?: number;
  flexSearchIndex?: Flexsearch.Index | null;
  showSelectedOptionsFirst?: boolean;
  showSelectAllOption: boolean;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  isFetchingNextPage?: boolean;
  includeSelectedInSearch?: boolean;
}) => {
  const contentContext = useContentContext();
  const valueOptionsKeys = Object.keys(valueOptionsContent);
  const hasOptions = valueOptionsKeys.length > 0;

  const useVirtualWindow = useMemo<boolean>(() => {
    if (typeof useVirtualWindowProp === 'boolean') {
      return useVirtualWindowProp;
    }
    return (
      Object.keys(valueOptionsContent).length > USE_VIRTUOSO_MIN_OPTIONS_COUNT
    );
  }, [useVirtualWindowProp, valueOptionsContent]);

  const toggleKey = useMemo<ToggleAllKeys | undefined>(() => {
    if (showSelectAllOption) {
      const allOptionsSelected =
        valueOptionsKeys.length === selectedOptions.length;
      return allOptionsSelected
        ? ToggleAllKeys.ClearAll
        : ToggleAllKeys.SelectAll;
    }

    // Hide SelectAll option
    return selectedOptions.length > 0 ? ToggleAllKeys.ClearAll : undefined;
  }, [selectedOptions, valueOptionsKeys, showSelectAllOption]);

  const selectItems = useMemo(() => {
    const topOptions: string[] = [];
    if (emptySelectionText) {
      topOptions.push(EMPTY_CONTENT_DESIGNATOR);
    } else if (hasOptions && toggleKey) {
      topOptions.push(toggleKey);
    }

    let keysToRender = Object.keys(valueOptionsContent);

    if (searchText && searchText.trim().length > 0) {
      keysToRender = flexSearchIndex?.search(searchText, {
        limit: keysToRender.length,
      }) as string[];
    }

    if (includeSelectedInSearch) {
      selectedOptions.forEach((item) => {
        if (!keysToRender.includes(item)) {
          keysToRender.push(item);
        }
      });
    }

    let selectItems = (keysToRender ?? []).sort((a: string, b: string) => {
      if (!sortMode || sortMode === 'none') {
        return 0;
      }

      const textA = getContent(valueOptionsContent[a], contentContext);
      const textB = getContent(valueOptionsContent[b], contentContext);

      let sortResult = textA
        .toLocaleUpperCase()
        .localeCompare(textB.toLocaleUpperCase());

      if (sortFn != null) {
        sortResult = sortFn(textA, textB);
      } else {
        const numberA = parseFloat(textA);
        const numberB = parseFloat(textB);
        if (!isNaN(numberA) && !isNaN(numberB)) {
          sortResult = numberA - numberB;
        }
      }

      return (sortMode === 'ascending' ? 1 : -1) * sortResult;
    });

    if (showSelectedOptionsFirst) {
      selectItems = [
        ...selectItems.filter((key) => selectedOptions.includes(key)),
        ...selectItems.filter((key) => !selectedOptions.includes(key)),
      ];
    }

    return topOptions.concat(selectItems);
  }, [
    contentContext,
    emptySelectionText,
    flexSearchIndex,
    hasOptions,
    includeSelectedInSearch,
    searchText,
    selectedOptions,
    showSelectedOptionsFirst,
    sortFn,
    sortMode,
    toggleKey,
    valueOptionsContent,
  ]);

  const renderItem = useCallback(
    (key: string) => {
      if (key === EMPTY_CONTENT_DESIGNATOR) {
        return (
          <PosDropdownItem
            key={key}
            tabIndex={0}
            value={emptySelectionText}
            onClick={() => {
              onChange(null);
            }}
            keepOpenAfterSelection={true}
          >
            <Stack
              gap="m"
              justifyContent="spaceBetween"
              alignItems="center"
              width="full"
            >
              <div
                className={
                  selectedOptions.length === 0 ? styles.isSelected : undefined
                }
              >
                <Content id={emptySelectionText} />
              </div>

              {selectedOptions.length === 0 && (
                <CheckIcon
                  size={vars.iconSize.s}
                  fill={IconsFill.textSuccess}
                />
              )}
            </Stack>
          </PosDropdownItem>
        );
      }

      if (isToggleAllValue(key) && toggleKey) {
        return (
          <FilteredOptionItem
            key="toggle-all"
            optionKey={toggleKey}
            optionValue={toggleKey}
            onChange={() => onChange(toggleKey)}
            selectedOptions={selectedOptions}
            includeSelectedInSearch={includeSelectedInSearch}
          />
        );
      }

      return (
        <FilteredOptionItem
          key={key}
          optionKey={key}
          optionValue={valueOptionsContent[key]}
          prefixIcon={valueOptionsIcon?.[key]}
          onChange={(o) => onChange(o)}
          filterText={searchText}
          selectedOptions={selectedOptions}
          includeSelectedInSearch={includeSelectedInSearch}
        />
      );
    },
    [
      emptySelectionText,
      includeSelectedInSearch,
      onChange,
      searchText,
      selectedOptions,
      toggleKey,
      valueOptionsContent,
      valueOptionsIcon,
    ]
  );

  return (
    <div className={styles.posMultiSelectOptionsContainer}>
      {useVirtualWindow ? (
        <Virtuoso
          overscan={10}
          style={{
            height: `clamp(100px, ${contentHeight}, ${
              selectItems.length * expectedItemSizeForVirtualWindow
            }px)`,
          }}
          defaultItemHeight={expectedItemSizeForVirtualWindow}
          data={selectItems}
          itemContent={(_, key) => renderItem(key)}
          endReached={() => {
            !isFetchingNextPage && hasNextPage && fetchNextPage?.();
          }}
        />
      ) : (
        <div className={styles.posMultiSelectOptionsContainerScroll}>
          {selectItems.map((key) => renderItem(key))}
        </div>
      )}
    </div>
  );
};

export const FilteredOptionItem = ({
  optionKey,
  optionValue,
  prefixIcon,
  filterText,
  selectedOptions,
  onChange,
  includeSelectedInSearch,
}: {
  optionKey: string;
  optionValue: string | ContentId;
  prefixIcon?: ReactNode;
  filterText?: string | null;
  selectedOptions: string[];
  onChange: (o: string | null) => void;
  includeSelectedInSearch?: boolean;
}) => {
  const isSelected = selectedOptions.includes(optionKey);

  const contentWrapper = useCallback(
    (
      key: string | ContentId,
      content: string | JSX.Element,
      isSelected: boolean
    ) => {
      return (
        <PosDropdownItem
          tabIndex={0}
          key={key}
          onClick={() => {
            onChange(key);
          }}
          onKeyUp={(k) => {
            if (k.key === 'Enter') {
              onChange(key);
            }
          }}
          keepOpenAfterSelection={true}
        >
          <Stack
            gap="m"
            justifyContent="spaceBetween"
            alignItems="center"
            width="full"
          >
            <Stack gap="m" alignItems="center">
              <div>{prefixIcon}</div>
              <div className={isSelected ? styles.isSelected : undefined}>
                {content}
              </div>
            </Stack>
            {isSelected && (
              <CheckIcon size={vars.iconSize.s} fill={IconsFill.textSuccess} />
            )}
          </Stack>
        </PosDropdownItem>
      );
    },
    [onChange, prefixIcon]
  );

  if (filterText) {
    if (isContentId(optionValue)) {
      return (
        <FilteredContent
          key={optionKey}
          optionKey={optionKey}
          id={optionValue}
          contentWrapper={contentWrapper}
          filterText={filterText}
          isSelected={isSelected}
        />
      );
    } else if (
      optionValue?.toUpperCase()?.includes(filterText?.toUpperCase()) ||
      includeSelectedInSearch
    ) {
      return contentWrapper(optionKey, optionValue, isSelected);
    }
    return null;
  }

  return contentWrapper(optionKey, <Content id={optionValue} />, isSelected);
};

export const FilteredContent = ({
  optionKey,
  id,
  filterText,
  contentWrapper,
  isSelected,
}: {
  optionKey: string | ContentId;
  id: ContentId;
  filterText: string;
  isSelected: boolean;
  contentWrapper: (
    key: string | ContentId,
    content: string | JSX.Element,
    isSelected: boolean
  ) => JSX.Element;
}) => {
  const text = useContent(id);
  if (!text.toUpperCase().includes(filterText.toUpperCase())) {
    return null;
  }

  return contentWrapper(optionKey, <Content id={id} />, isSelected);
};
