import clsx from 'clsx';
import { isEqual, uniq } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { typography } from 'src/core/themes/shared.css';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { PURCHASE_TABLE_COLUMNS_CONFIG } from 'src/tables/PurchaseTable/configs/PurchaseTableColumnsConfig';
import { getColumnPersonalization } from 'src/utils/columns/columnPersonalizationUtils';
import { filterColumnsByFeatures } from 'src/utils/columns/columnUtils';
import { CustomPurchasesColumn } from 'src/utils/columns/purchases/purchasesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import {
  ActionOutboxEntityType,
  Feature,
  UserSetting,
} from 'src/WebApiController';

import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal';
import * as styles from './ColumnSettings.css';
import { useColumnServerUserSettings } from './hooks/useColumnServerUserSettings';
import { TableColumnsInput } from './TableColumnsInputV2';

type TableColumnsModalContainerProps = {
  onClose: () => void;
  // Not fully supporting reporting yet
  sectionType: SectionType.Purchases | SectionType.PurchaseEvent;
  // Only supported for Purchase for now, used for filtering visible columns
  viewMode?: string | null;
  // Only used with SectionType.Listings
  // We only want to show this column for setting if it's visible in the current view
  showTicketClassColor?: boolean;
};

export function PurchaseColumnSettingsModal<T extends CustomPurchasesColumn>({
  onClose,
  sectionType,
  viewMode,
  showTicketClassColor,
}: TableColumnsModalContainerProps) {
  const { appContext, loginContext } = useAppContext();
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );
  const [isOpen, toggleIsOpen] = useToggle(true);
  const {
    storedColumnOrderSetting,
    setColumnOrderSetting,
    customColumns,
    storedColumnsEnabledSetting,
    setColumnsEnabledSetting,
  } = useColumnServerUserSettings<CustomPurchasesColumn>({ sectionType });

  const {
    value: customPurchaseColumns = [],
    setUserSetting: setCustomPurchaseColumns,
  } = useServerUserSetting<CustomPurchasesColumn[]>({
    id: UserSetting.PurchaseCustomColumns,
  });

  const { tagsMetadata } = useTagsForEntityType(
    ActionOutboxEntityType.Purchase,
    true
  );

  const presetColumnIds = Object.values(PURCHASE_TABLE_COLUMNS_CONFIG)
    .map((i) => i.id)
    .filter((id): id is string => id !== undefined);

  const filterColumns = useCallback(
    (columnList: string[]) => {
      // Filter out reporting tags that are later turned off reporting
      const activeTagNames = tagsMetadata?.map((t) => t.key) ?? [];
      const customColumnIds = customPurchaseColumns.map(
        (c) => (c as T & { id: string }).id
      );

      return columnList.filter((columnId) => {
        // Don't show inconfigurable columns - like checkbox
        if (presetColumnIds.includes(columnId)) {
          const isConfigurable = getColumnPersonalization({
            id: columnId,
            sectionType,
          }).isConfigurable;

          // With this new feature, we set action column as a column always showing at the right, thus inconfigurable
          // Without the feature, we treat the column as configurable
          if (!hasTablePinActionColumnFeature) {
            if (columnId === 'action' || columnId === 'actions') {
              return true;
            }
          }

          return isConfigurable;
        }
        return (
          activeTagNames.includes(columnId) ||
          customColumnIds.includes(columnId)
        );
      });
    },
    [
      customPurchaseColumns,
      hasTablePinActionColumnFeature,
      presetColumnIds,
      sectionType,
      tagsMetadata,
    ]
  );

  const storedColumnOrderSettingFiltered = useMemo(() => {
    const allColumnIdsAvailable = filterColumns(
      uniq([
        ...storedColumnOrderSetting.filter(
          (id): id is string => id !== undefined
        ),
        ...presetColumnIds.sort((a, b) => a.localeCompare(b)),
        ...customPurchaseColumns.map((c) => c.id),
        ...(tagsMetadata?.map((t) => t.key) ?? []),
      ])
    );

    const existingColumnsOrderSetting = filterColumns(
      storedColumnOrderSetting as string[]
    );

    allColumnIdsAvailable.forEach((columnId) => {
      if (!existingColumnsOrderSetting.includes(columnId)) {
        existingColumnsOrderSetting.push(columnId);
      }
    });

    return existingColumnsOrderSetting;
  }, [
    customPurchaseColumns,
    filterColumns,
    presetColumnIds,
    storedColumnOrderSetting,
    tagsMetadata,
  ]);

  const storedColumnEnabledSettingFiltered = useMemo(() => {
    if (
      Array.isArray(storedColumnsEnabledSetting) &&
      storedColumnsEnabledSetting.length > 0
    ) {
      return filterColumns(storedColumnsEnabledSetting as string[]);
    } else {
      return filterColumns(storedColumnOrderSetting);
    }
  }, [filterColumns, storedColumnsEnabledSetting, storedColumnOrderSetting]);

  const editabilityId = {
    [SectionType.Purchases]: UserSetting.PurchaseColumnEditability,
    [SectionType.PurchaseEvent]: UserSetting.PurchaseEventColumnEditability,
  }[sectionType];

  const { value: editableColumns = [], setUserSetting: setEditableColumns } =
    useServerUserSetting<string[]>({
      id: editabilityId,
    });

  const [userColumnSelection, setUserColumnSelection] = useState<string[]>(
    filterColumnsByFeatures(
      storedColumnEnabledSettingFiltered,
      sectionType,
      customColumns[sectionType],
      loginContext?.user,
      appContext?.features
    )
  );

  const [userColumnSorting, setUserColumnSorting] = useState<string[]>(
    filterColumnsByFeatures(
      storedColumnOrderSettingFiltered,
      sectionType,
      customColumns[sectionType],
      loginContext?.user,
      appContext?.features
    )
  );
  const [userEditableColumns, setUserEditableColumns] =
    useState<string[]>(editableColumns);
  const [userColumnIsDirty, setUserColumnIsDirty] = useState(false);

  const onSaveHandler = () => {
    if (!userColumnSelection) {
      // TODO: show error
      return;
    }
    setColumnsEnabledSetting(userColumnSelection);
    setColumnOrderSetting(userColumnSorting);
    setEditableColumns(userEditableColumns);
    toggleIsOpen();
  };

  // Sync the user setting to the default if the user has never set it
  useEffect(() => {
    const newColumns = filterColumnsByFeatures(
      storedColumnOrderSettingFiltered,
      sectionType,
      customColumns[sectionType],
      loginContext?.user,
      appContext?.features,
      tagsMetadata ?? []
    );
    const newEnabledColumns = filterColumnsByFeatures(
      storedColumnEnabledSettingFiltered,
      sectionType,
      customColumns[sectionType],
      loginContext?.user,
      appContext?.features
    );
    if (!userColumnIsDirty) {
      if (!isEqual(newColumns, userColumnSorting)) {
        setUserColumnSorting(newColumns);
      }
      if (!isEqual(editableColumns, userEditableColumns)) {
        setUserEditableColumns(editableColumns);
      }
      if (!isEqual(newEnabledColumns, userColumnSelection)) {
        setUserColumnSelection(newEnabledColumns);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appContext?.features,
    editableColumns,
    loginContext?.user,
    storedColumnOrderSettingFiltered,
    storedColumnEnabledSettingFiltered,
  ]);

  // Keep the columns up to date in case the user changes the settings (e.g. adds/deletes custom column)
  useEffect(() => {
    const allColumnIdsAvailable = filterColumns(
      uniq([
        ...storedColumnOrderSetting.filter(
          (id): id is string => id !== undefined
        ),
        ...presetColumnIds.sort((a, b) => a.localeCompare(b)),
        ...customPurchaseColumns.map((c) => c.id),
        ...(tagsMetadata?.map((t) => t.key) ?? []),
      ])
    );

    let userColumnSortingFiltered = filterColumns(userColumnSorting);

    allColumnIdsAvailable.forEach((columnId) => {
      if (!userColumnSortingFiltered.includes(columnId)) {
        userColumnSortingFiltered.push(columnId);
      }
    });

    userColumnSortingFiltered = filterColumnsByFeatures(
      userColumnSortingFiltered,
      sectionType,
      customColumns[sectionType],
      loginContext?.user,
      appContext?.features,
      tagsMetadata ?? []
    );

    if (
      !isEqual(new Set(userColumnSortingFiltered), new Set(userColumnSorting))
    ) {
      setUserColumnSorting(userColumnSortingFiltered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customPurchaseColumns]);

  useEffect(() => {
    if (!isOpen) setTimeout(onClose, 300);
  }, [isOpen, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleIsOpen}
      clickOutsideToClose
      unmountOnClose
      centered
      size="m"
    >
      <ModalHeader onClose={toggleIsOpen}>
        <div className={styles.headerBar}>
          <h5 className={clsx(typography.title5, styles.headerText)}>
            <Content id={ContentId.ColumnSettings} />
          </h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <TableColumnsInput<CustomPurchasesColumn>
          userColumnSelection={userColumnSelection}
          onUserColumnSelectionChange={(newSelection) => {
            setUserColumnSelection(newSelection);
            setUserColumnIsDirty(true);
          }}
          userColumnSorting={userColumnSorting}
          onUserColumnSortingChange={(newSorting) => {
            setUserColumnSorting(newSorting);
            setUserColumnIsDirty(true);
          }}
          userEditableColumns={userEditableColumns}
          onUserEditableColumnsChange={(newEditableColumns) => {
            setUserEditableColumns(newEditableColumns);
            setUserColumnIsDirty(true);
          }}
          customColumns={customPurchaseColumns}
          onCustomColumnsChange={setCustomPurchaseColumns}
          reportingTags={tagsMetadata ?? []}
          enableConfigureEditability
          sectionType={sectionType}
          showTicketClassColor={showTicketClassColor}
          viewMode={viewMode}
        />
      </ModalBody>
      <ModalFooter>
        <CancelButton textContentId={ContentId.Cancel} onClick={toggleIsOpen} />
        <OkButton onClick={onSaveHandler} textContentId={ContentId.Save} />
      </ModalFooter>
    </Modal>
  );
}
