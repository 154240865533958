import { FormProvider } from 'react-hook-form';
import { ListingDetails } from 'src/WebApiController';

import { useListingDetailsContext } from '../../ListingDetailsModalV2Context';
import { AdminHoldNotesSection } from './AdminHoldNotes';
import { MarketplaceBroadcastSection } from './MarketplaceBroadcastSection';

type ListingDetailsBroadcastFormProps = {
  listing: ListingDetails;
};

export const ListingDetailsBroadcastForm: React.FC<
  ListingDetailsBroadcastFormProps
> = ({ listing }) => {
  const { methods, isLoading } = useListingDetailsContext();
  return (
    <FormProvider {...methods.broadcast}>
      <MarketplaceBroadcastSection
        listing={listing!}
        disabled={isLoading || !!listing?.dupListingId}
      />
      {listing?.isAdminHold && <AdminHoldNotesSection />}
    </FormProvider>
  );
};
