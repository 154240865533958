import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const NavBarContainer = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
  color: ${(props) => props.theme.colors.textPrimary};
  z-index: 100;
  padding: 0;
  width: 100%;
`;

export const BottomNavBarContainer = styled(NavBarContainer)`
  position: fixed;
  bottom: 0;
  justify-content: space-around;
`;

export const UnauthorizedAccessMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${() => `${vars.spacing['5xl']} auto`};
`;
