import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PurchaseClient, PurchaseEventOverride } from 'src/WebApiController';

export const usePurchaseEventOverrides = (
  eventId?: number | null,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const queryClient = useQueryClient();
  const getQueryKey = ['PurchaseClient.getPurchaseEventOverrides', eventId];

  const shouldQuery =
    !disabled &&
    Boolean(activeAccountWebClientConfig.activeAccountId && eventId);

  const getQuery = useQuery({
    queryKey: getQueryKey,
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      return await new PurchaseClient(
        activeAccountWebClientConfig
      ).getPurchaseEventOverrides(eventId!);
    },

    enabled: shouldQuery,
    staleTime: Infinity, // We invalidate on settings changed, so we don't need to make this stale
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PurchaseClient.getPurchaseEventOverrides', error, {
          eventId,
        });
      },
    },
  });

  const updateMutation = useMutation({
    mutationFn: async ({ settings }: { settings: PurchaseEventOverride[] }) => {
      return await new PurchaseClient(
        activeAccountWebClientConfig
      ).mergePurchaseEventOverrides(settings, eventId!);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ settings }) => {
      queryClient.cancelQueries({ queryKey: getQueryKey });
      const prevValue =
        queryClient.getQueryData<PurchaseEventOverride[]>(getQueryKey);

      queryClient.setQueryData(getQueryKey, settings);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { settings }, context) => {
      queryClient.setQueryData(getQueryKey, context?.prevValue);
      showErrorDialog('PurchaseClient.mergePurchaseEventOverrides', err, {
        trackErrorData: { settings },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: getQueryKey });
    },
  });

  return {
    purchaseEventOverrides: getQuery.data,
    isLoading: getQuery.isLoading,
    async updatePurchaseEventOverrides(settings: PurchaseEventOverride[]) {
      await updateMutation.mutateAsync({
        settings,
      });
    },
    isMutationLoading: updateMutation.isPending,
  };
};
