import { vars } from 'src/core/themes';
import CircleArrowUp from 'src/images/CircleArrowUp.png';
import { FocalPointInfo } from 'src/WebApiController';

export const addFocalPoint = (
  svgElement: SVGSVGElement,
  focalPoint?: FocalPointInfo | null
) => {
  if (
    focalPoint &&
    focalPoint.x &&
    focalPoint.y &&
    svgElement.getElementById('focal-point-cir') == null
  ) {
    const circle = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'circle'
    );
    circle.setAttribute('id', 'focal-point-cir');
    circle.setAttribute('cx', focalPoint.x.toString());
    circle.setAttribute('cy', focalPoint.y.toString());
    circle.setAttribute('r', '50');
    circle.setAttribute('fill', vars.color.backgroundBrandActive);
    svgElement.appendChild(circle);

    const image = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'image'
    );

    // TODO - There seems to be a bug from Catalog that focal point Type is always Point - even though they should be PointAndDir when angle > 0
    // So IsDirectional is always false even for maps that clearly has a direction (and the angle shows it)
    // So we'll render the arrow whenever there is a non-zero angle
    if ((focalPoint.angleDeg ?? 0) > 0 || focalPoint.isDirectional) {
      image.setAttribute('id', 'focal-point-img');
      image.setAttribute('x', (focalPoint.x - 40).toString());
      image.setAttribute('y', (focalPoint.y - 40).toString());
      image.setAttribute(
        'transform',
        `rotate(${focalPoint.angleDeg ?? 0}, ${focalPoint.x}, ${focalPoint.y})`
      );
      image.setAttribute('href', CircleArrowUp);
      image.setAttribute('width', '80');
      image.setAttribute('height', '80');

      svgElement.appendChild(image);
    } else {
      const circle2 = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'circle'
      );
      circle2.setAttribute('id', 'focal-point-cir-2');
      circle2.setAttribute('cx', focalPoint.x.toString());
      circle2.setAttribute('cy', focalPoint.y.toString());
      circle2.setAttribute('r', '30');
      circle2.setAttribute('fill', vars.color.backgroundBrandActive);
      circle2.setAttribute('stroke', vars.color.textInverted);
      circle2.setAttribute('stroke-width', '10');
      svgElement.appendChild(circle2);
    }
  }
};
