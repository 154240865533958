import { useEffect, useState } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { useContent } from 'src/contexts/ContentContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosFormField } from 'src/core/POS/PosFormField';
import {
  getTextFieldState,
  PosTextField,
  PosTextFieldProps,
} from 'src/core/POS/PosTextField';
import { ContentId } from 'src/utils/constants/contentId';
import { Seating, TicketExternalId } from 'src/WebApiController';

import { ExternalIdForm } from '../../UploadExternalIds';

export const ManualExternalIdEntry = ({
  seating: { section, sectionId, rowId },
  quantity,
  readOnly = false,
}: {
  seating: Seating;
  quantity: number;
  readOnly?: boolean;
}) => {
  const {
    setValue,
    watch,
    trigger,
    formState: { isDirty },
  } = useFormContext<ExternalIdForm>();
  const [showErrors, setShowErrors] = useState<boolean>(isDirty);
  const externalIds = watch('externalIds');

  useEffect(() => {
    // Handle toggle between bulk and manual where extra externalIds were entered
    if (externalIds.length > quantity) {
      setValue('externalIds', externalIds.slice(0, quantity));
      trigger();
    } else if (isDirty) {
      // loaded from a toggle, show errors
      setShowErrors(true);
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {externalIds.map((ticketExternalId: TicketExternalId, i: number) => {
        const { row, seat } = ticketExternalId;
        const id = `${section}-${row}-${seat}-${i}`;

        return (
          <ExternalIdInput
            key={id}
            seating={{
              section,
              sectionId,
              row,
              rowId,
              seatFr: seat || (i + 1).toString(),
              seatTo: '',
              ticketIds: [],
              listingIds: [],
            }}
            disabled={readOnly}
            index={i}
            forceShowErrors={showErrors}
          />
        );
      })}
    </>
  );
};

export const ExternalIdInput = ({
  seating,
  disabled,
  index,
}: {
  seating: Seating;
  disabled: boolean;
  index: number;
  forceShowErrors?: boolean;
}) => {
  const { register, formState } = useFormContext<ExternalIdForm>();

  const field: `externalIds.${number}.externalId` = `externalIds.${index}.externalId`;

  const error = formState.errors.externalIds?.[index]?.externalId;
  const warning = error?.type === 'warning' ? error?.message : '';
  const errorMessage = error?.type !== 'warning' ? error?.message ?? '' : '';

  const requiredMsg = useContent(ContentId.Required);

  return (
    <>
      <SeatExternalIdInput
        seating={seating}
        disabled={disabled}
        errors={errorMessage ? [errorMessage] : undefined}
        {...register(field, {
          required: requiredMsg,
        })}
      />
      {warning && <WarningMessage message={warning} />}
    </>
  );
};

interface SeatExternalIdInputProps extends PosTextFieldProps {
  seating: Seating;
  errors?: string[];
  disabled?: boolean;
}
export const SeatExternalIdInput = React.forwardRef(
  (
    { seating, errors, ...props }: SeatExternalIdInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <PosFormField
        errors={props.disabled ? undefined : errors}
        label={<SeatingInfo {...seating} />}
      >
        <PosTextField
          rootProps={{
            state: getTextFieldState(errors),
          }}
          ref={ref}
          {...props}
        />
      </PosFormField>
    );
  }
);

SeatExternalIdInput.displayName = 'SeatExternalIdInput';
