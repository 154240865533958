import { ComponentProps, useCallback, useState } from 'react';
import {
  Controller,
  FormProvider,
  useForm,
  UseFormSetValue,
} from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog/useConfirmDialog';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useAutoPricingStrategies } from 'src/hooks/api/useAutoPricingStrategies';
import { AutoPricingSection } from 'src/modals/ListingDetails/components/PricingSection/AutoPricingSection';
import { validateAutoPricingSettings } from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AutoPricingSettingsCollection,
  Listing,
  ListingDetailsUpdateInput,
} from 'src/WebApiController';

export type AutoPricingStrategyDialogProps = ComponentProps<typeof RSModal> & {
  defaultValues?: AutoPricingSettingsCollectionInput;
  listing?: Listing;
  listingHasParent?: boolean;
  disabled?: boolean;
  onStrategyUpdated?: (strategyId: number) => void;
  isBulkEdit?: boolean;
};

export type AutoPricingSettingsCollectionInput = ListingDetailsUpdateInput & {
  autoPricingSettingsCollectionId: number | null;
  strategyName: string;
  autoPricingDefaultQuantityFiltersJson: string;
  scoreModel: number | null;
  autoPriceFrequency: number | null;
};

export const AutoPricingStrategyDialog = ({
  defaultValues,
  ...rest
}: AutoPricingStrategyDialogProps) => {
  const methods = useForm<AutoPricingSettingsCollectionInput>({
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <AutoPricingStrategyDialogContent
        defaultValues={defaultValues}
        {...rest}
        {...methods}
      />
    </FormProvider>
  );
};

function AutoPricingStrategyDialogContent({
  defaultValues,
  listing,
  listingHasParent,
  isBulkEdit,
  onClosed,
  onStrategyUpdated,
  onCancel,
  disabled,
  control,
  formState: { errors },
  watch,
  clearErrors,
  setError,
  setValue,
  getValues,
  handleSubmit,
  reset,
  ...rest
}: AutoPricingStrategyDialogProps &
  Omit<
    ComponentProps<
      typeof FormProvider<AutoPricingSettingsCollectionInput, unknown>
    >,
    'children'
  >) {
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isLoading, setIsLoading] = useState(false);

  const requiredMsg = useContent(ContentId.Required);
  const floorMustBeLessThanCeilingError = useContent(
    ContentId.FloorMustBeLessThanCeiling
  );
  const compListingFloorCeilingError = useContent(
    ContentId.CompListingFloorMustLessThanCeiling
  );

  const {
    openConfirmDialog: openConfirmDeleteDialog,
    closeConfirmDialog: closeConfirmDeleteDialog,
  } = useConfirmDialog();

  const autoPricingSettingsCollectionId = watch(
    'autoPricingSettingsCollectionId'
  );

  const { upsertAutoPricingStrategy, deleteAutoPricingStrategy } =
    useAutoPricingStrategies(listing, isBulkEdit);

  const onSubmit = useCallback(
    async (strategyInput: AutoPricingSettingsCollectionInput) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const input = strategyInput as AutoPricingSettingsCollection;
          const id = await upsertAutoPricingStrategy({
            input,
          });

          if (id) {
            onStrategyUpdated?.(id);
          }
        },
        (error) => {
          showErrorDialog('PricingClient.upsertAutoPricingStrategy', error, {
            trackErrorData: strategyInput,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [onStrategyUpdated, showErrorDialog, upsertAutoPricingStrategy]
  );

  const onSubmitHandler = useCallback(() => {
    const listForm = getValues();

    if (
      !validateAutoPricingSettings(
        clearErrors,
        setError,
        setValue as unknown as UseFormSetValue<ListingDetailsUpdateInput>,
        listForm,
        floorMustBeLessThanCeilingError,
        compListingFloorCeilingError,
        requiredMsg,
        true // isStrategy
      )
    ) {
      return;
    }

    handleSubmit(onSubmit)();
    return;
  }, [
    clearErrors,
    compListingFloorCeilingError,
    floorMustBeLessThanCeilingError,
    getValues,
    handleSubmit,
    onSubmit,
    requiredMsg,
    setError,
    setValue,
  ]);

  const onStrategyDelete = useCallback(() => {
    closeConfirmDeleteDialog();

    if (!autoPricingSettingsCollectionId) return;

    setIsLoading(true);
    tryInvokeApi(
      async () => {
        await deleteAutoPricingStrategy({
          collectionId: autoPricingSettingsCollectionId,
        });

        onClosed?.();
      },
      (error) => {
        showErrorDialog('PricingClient.deleteAutoPricingStrategy', error, {
          trackErrorData: { autoPricingSettingsCollectionId },
        });
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [
    autoPricingSettingsCollectionId,
    closeConfirmDeleteDialog,
    deleteAutoPricingStrategy,
    onClosed,
    showErrorDialog,
  ]);

  return (
    <>
      <GenericDialog
        {...rest}
        size="xl"
        header={
          autoPricingSettingsCollectionId ? (
            <Content id={ContentId.EditPricingStrategy} />
          ) : (
            <Content id={ContentId.CreatePricingStrategy} />
          )
        }
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            onSubmitHandler();
          }
        }}
        onOpened={() => {
          reset(defaultValues);
        }}
        footer={
          <Stack
            direction="row"
            gap="m"
            justifyContent="spaceBetween"
            width="full"
          >
            <>
              {autoPricingSettingsCollectionId ? (
                <Button
                  disabled={disabled || isLoading}
                  variant="text"
                  textColor="error"
                  onClick={() =>
                    openConfirmDeleteDialog({
                      size: 'm',
                      headerText: (
                        <Content id={ContentId.DeletePricingStrategy} />
                      ),
                      bodyText: <Content id={ContentId.AreYouSure} />,
                      onOkay: onStrategyDelete,
                      onCancel: closeConfirmDeleteDialog,
                      okText: ContentId.Yes,
                      cancelText: ContentId.No,
                    })
                  }
                >
                  <Content id={ContentId.Delete} />
                </Button>
              ) : (
                <span />
              )}
            </>
            <Stack direction="row" gap="m">
              {onClosed && (
                <CancelButton
                  disabled={disabled || isLoading}
                  onClick={onClosed}
                />
              )}
              <Button
                disabled={disabled || isLoading}
                variant={'regular'}
                onClick={onSubmitHandler}
              >
                <Content id={ContentId.Save} />
              </Button>
            </Stack>
          </Stack>
        }
        onCancel={isLoading ? undefined : onCancel}
      >
        <Stack direction="column" gap="l">
          <Stack direction="column" width="full" gap="xl">
            <span>
              <Content id={ContentId.SavePricingStrategyInfo} />
            </span>

            <Controller
              control={control}
              name="strategyName"
              rules={{ required: requiredMsg }}
              render={({ field: { ...field } }) => (
                <PosFormField
                  errors={errors.strategyName?.message}
                  style={{ fontWeight: 500, color: vars.color.textStrong }}
                  label={<Content id={ContentId.StrategyName} />}
                >
                  <PosTextField maxLength={200} {...field} />
                </PosFormField>
              )}
            />
            <AutoPricingSection
              listing={listing}
              disabled={disabled || isLoading}
              listingHasParent={listingHasParent}
              isBulkEdit={isBulkEdit}
              isStrategyEdit
            />
          </Stack>
        </Stack>
      </GenericDialog>
    </>
  );
}
