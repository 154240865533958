import { useCallback, useState } from 'react';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { Colors } from 'src/core/themes/constants/colors';
import { Stack } from 'src/core/ui';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { CopyIcon } from 'src/svgs';
import { CheckIcon } from 'src/svgs/Viagogo';

const SHOW_COPIED_ICON_TIME = 1000;

interface CopyToClipboardPops {
  textToCopy?: string | number;
  copyIconColor?: string;
  iconSize?: string;
}

export const CopyToClipboard = ({
  textToCopy,
  copyIconColor,
  iconSize,
}: CopyToClipboardPops) => {
  const { isDarkMode } = useSiteTheme();
  const [showingCheckmarkIcon, setShowingCheckmarkIcon] =
    useState<boolean>(false);
  const { copyToClipboard } = useCopyToClipboard();

  const copy = useCallback(async () => {
    if (textToCopy == null || String(textToCopy).length === 0) {
      return;
    }
    await copyToClipboard(String(textToCopy));
    setShowingCheckmarkIcon(true);
    const timeoutId = setTimeout(
      () => setShowingCheckmarkIcon(false),
      SHOW_COPIED_ICON_TIME
    );
    return () => {
      clearTimeout(timeoutId);
    };
  }, [copyToClipboard, textToCopy]);

  return (
    <Stack alignItems="center">
      {showingCheckmarkIcon ? (
        <CheckIcon
          size={iconSize || vars.iconSize.s}
          fill={vars.color.textSuccess}
        />
      ) : (
        <CopyIcon
          onClick={copy}
          withHoverEffect
          fill={
            copyIconColor
              ? copyIconColor
              : isDarkMode
              ? Colors.white
              : Colors.black
          }
          size={iconSize || vars.iconSize.s}
        />
      )}
    </Stack>
  );
};
