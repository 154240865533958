import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ReportsPageToolbarV2 } from 'src/components/Reports/ReportsPageToolbar/ReportsPageToolbarV2';
import { ReportsSalePageV2 } from 'src/components/ReportsSalePage/ReportsSalePageV2';
import { Content } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useReportConfigsV2 } from 'src/hooks/useReportConfigsV2';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypesV2 } from 'src/utils/reportsUtils';

import { MainRoute } from '../MainRoute';
import { ReportsSaleContextProviderV2 } from './ReportsSaleContextProviderV2';

export function ReportsSaleV2() {
  return (
    <LayoutContent mainRoute={MainRoute.Reports}>
      <ReportsSaleContentV2 />
    </LayoutContent>
  );
}

function ReportsSaleContentV2() {
  const { reportId } = useParams();
  const location = useLocation();

  const { reportConfigs, isLoading } = useReportConfigsV2<ReportTypesV2.SaleV2>(
    {
      reportType: ReportTypesV2.SaleV2,
    }
  );

  const reportConfig = reportConfigs?.find(
    (r) => r.reportId?.toString() === reportId
  );

  // Only use value provided at the first time (should be from '/reports')
  const returnUrl = useMemo(() => {
    return (location.state as { returnUrl?: string })?.returnUrl;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reportConfig == null) {
    return isLoading ? (
      <PosSpinner />
    ) : (
      <Stack
        width="full"
        direction="column"
        alignItems="center"
        style={{ padding: vars.spacing.xxl }}
      >
        <WarningMessage
          message={<Content id={ContentId.ReportNotAvailable} />}
        />
      </Stack>
    );
  }

  return (
    <ReportsSaleContextProviderV2 reportConfig={reportConfig}>
      <DialogProvider>
        <ReportsPageToolbarV2
          reportConfig={reportConfig}
          returnUrl={returnUrl ?? '/reports/v2'}
        />
        <ReportsSalePageV2 reportConfig={reportConfig} />
      </DialogProvider>
    </ReportsSaleContextProviderV2>
  );
}
