import { head, union, uniqBy } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { ConnectedComparableListings } from 'src/components/Listings/ConnectedComparableListings';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Stack } from 'src/core/ui';
import { useComparableListingsVenueMapColor } from 'src/hooks/useComparableListingsVenueMapColor';
import { useGetCompListings } from 'src/hooks/useGetCompListings';
import { getSectionInfoDisplay } from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { getListPriceFromAllinPrice } from 'src/utils/inventoryUtils';
import {
  getCompleteEventConfigScoreOverrides,
  getMatchingSectionRow,
  getSeatScore,
} from 'src/utils/seatScoreUtils';
import { filterSectionByZone } from 'src/utils/venueConfigUtils';
import {
  AutoPricingUndercutMode,
  CompListing,
  Event,
  Listing,
  SectionInfo,
} from 'src/WebApiController';

import { useAutoPricingSidePanelContext } from '../../../AutoPricingSidePanel';
import { useAutoPricingPreview } from '../AutoPricePreview/useAutoPricePreview';
import * as styles from './AutoPriceCompListingsPreview.css';

export const AutoPriceCompListingsPreview = ({
  event,
  listing,
  onSelectedSectionsChange,
}: {
  event: Event;
  listing: Listing;
  onSelectedSectionsChange?: (selectedSections: SectionInfo[]) => void;
}) => {
  const { priceCalcQuery, priceUpdates } = useAutoPricingPreview(
    event.viagId,
    listing.id
  );
  const { compListingsQuery } = useGetCompListings(event, listing.id);
  const { metadata } = useAutoPricingSidePanelContext();
  const getColor = useComparableListingsVenueMapColor(
    [listing],
    priceCalcQuery.data?.compListings,
    compListingsQuery.data ?? undefined
  );
  const { venueMapInfo, activeConfigOverride } = useEventMapContext();

  const filterSections = useMemo(() => {
    const selectedSectionId = metadata.sectionIds ?? [];
    return (
      venueMapInfo?.sections?.filter(({ id }) =>
        selectedSectionId.includes(id)
      ) ?? []
    );
  }, [metadata.sectionIds, venueMapInfo]);

  const markedSectionIds = useMemo(
    () => filterSections.map(({ id }) => id),
    [filterSections]
  );

  const selectedSectionIds = useMemo(() => {
    const setionIds: number[] = [];

    const { section } = getMatchingSectionRow(
      listing.seating,
      venueMapInfo?.sections
    );

    if (section) {
      setionIds.push(section.id);
    }

    return setionIds;
  }, [listing.seating, venueMapInfo?.sections]);

  const onSectionHovered = useCallback(
    (hoveredSection: SectionInfo) => {
      return getSectionInfoDisplay(
        hoveredSection,
        priceCalcQuery.data?.compListings ?? [],
        venueMapInfo?.sectionScores
      );
    },
    [priceCalcQuery.data?.compListings, venueMapInfo?.sectionScores]
  );

  const onShiftSelect = useCallback(
    (sectionInfo: SectionInfo) => {
      // We are using the ticketClassId to group the sections
      const selectedClassId = head(
        sectionInfo?.rows
          ?.map(({ tktClass: ticketClass }) => ticketClass?.id)
          .filter((id) => !!id)
      );
      const classSections =
        venueMapInfo?.sections?.filter(filterSectionByZone(selectedClassId)) ||
        [];

      let cloned = [...filterSections];
      if (filterSections.findIndex(({ id }) => id === sectionInfo.id) > -1) {
        const idsToExclude = classSections.map(({ id }) => id);
        cloned = cloned.filter(({ id }) => !idsToExclude.includes(id));
      } else {
        for (const section of classSections) {
          if (filterSections.findIndex(({ id }) => section.id === id) == -1) {
            cloned.push(section);
          }
        }
      }
      onSelectedSectionsChange?.(cloned);
    },
    [filterSections, onSelectedSectionsChange, venueMapInfo?.sections]
  );

  const onSingleSelect = useCallback(
    (section: SectionInfo) => {
      const idx = filterSections.findIndex(({ id }) => id === section.id);
      const cloned = [...filterSections];
      if (idx === -1) {
        cloned.push(section);
      } else {
        cloned.splice(idx, 1);
      }
      onSelectedSectionsChange?.(cloned);
    },
    [filterSections, onSelectedSectionsChange]
  );

  const onSectionClicked = useCallback(
    (e: MouseEvent, sectionInfo: SectionInfo) => {
      if (e.shiftKey) {
        onShiftSelect(sectionInfo);
        return;
      }

      onSingleSelect(sectionInfo);
    },
    [onSingleSelect, onShiftSelect]
  );

  const onToggleMirrors = useCallback(
    (mirrors: SectionInfo[], exclude?: boolean) => {
      if (exclude) {
        const idsToExclude = mirrors.map(({ id }) => id);
        const updated = uniqBy(
          filterSections.filter(({ id }) => !idsToExclude.includes(id)),
          'id'
        ).sort((a, b) => a.name.localeCompare(b.name));
        onSelectedSectionsChange?.(updated);
      } else {
        const updated = uniqBy(filterSections.concat(mirrors), 'id').sort(
          (a, b) => a.name.localeCompare(b.name)
        );
        onSelectedSectionsChange?.(updated);
      }
    },
    [filterSections, onSelectedSectionsChange]
  );

  const scoreOverrides = useMemo(
    () =>
      getCompleteEventConfigScoreOverrides(
        venueMapInfo?.sectionScores,
        activeConfigOverride?.scoreOverrides,
        false
      ),
    [activeConfigOverride?.scoreOverrides, venueMapInfo?.sectionScores]
  );

  const populateListingWithSeatScore = useCallback(
    (listing: Listing) => {
      const seatScore = getSeatScore(
        listing.seating,
        scoreOverrides,
        venueMapInfo?.sections
      );
      if (seatScore != null)
        return {
          ...listing,
          procsFloor: priceUpdates.netProceedsFloor,
          procsCeil: priceUpdates.netProceedsCeiling,
          // This is to make sure we use the auto-preview price instead of the listing's price when showing comparables
          // And we have to override listPrice because allIn is calculated from listPrice (instead of being read from the listing)
          listPrice:
            getListPriceFromAllinPrice(
              priceCalcQuery.data?.websitePrice?.amt,
              listing
            ) ?? listing.listPrice,
          seatScore,
        };

      return listing;
    },
    [
      priceCalcQuery.data?.websitePrice?.amt,
      priceUpdates.netProceedsCeiling,
      priceUpdates.netProceedsFloor,
      scoreOverrides,
      venueMapInfo?.sections,
    ]
  );

  const compListingsWithSeatScore: CompListing[] | undefined = useMemo(() => {
    const compListings =
      priceCalcQuery.data?.compListings?.map((l) => {
        const seatScore = getSeatScore(
          l,
          scoreOverrides,
          venueMapInfo?.sections
        );
        if (seatScore != null) return { ...l, seatScore };

        return l;
      }) ?? [];
    if (filterSections.length === 0) {
      return compListings;
    }
    const filterNames = new Set<string>(filterSections.map(({ name }) => name));
    const filterRowIds = new Set<number>();
    for (const section of filterSections) {
      for (const row of section.rows) {
        filterRowIds.add(row.id);
      }
    }
    return compListings.filter((l) =>
      l.rowId
        ? filterRowIds.has(l.rowId)
        : l.section
        ? filterNames.has(l.section)
        : false
    );
  }, [
    filterSections,
    priceCalcQuery.data?.compListings,
    scoreOverrides,
    venueMapInfo?.sections,
  ]);

  const compListingSectionIds = useMemo(() => {
    // Section name lookup should be sufficient,
    // update here to also check on rowId when necessary.
    const sectionNames = (priceCalcQuery.data?.compListings ?? []).map(
      (l) => l.section
    );
    const sectionIds = (venueMapInfo?.sections ?? [])
      .filter((section) => sectionNames.includes(section.name))
      .map(({ id }) => id);

    return union(sectionIds, markedSectionIds);
  }, [
    markedSectionIds,
    priceCalcQuery.data?.compListings,
    venueMapInfo?.sections,
  ]);

  return (
    <Stack
      direction="column"
      gap="xl"
      style={{ height: 'calc(100% - 52px)' }}
      className={styles.sectionContainer}
    >
      <div className={styles.mapContainerFull}>
        <EventVenueMap
          selectedSectionIds={selectedSectionIds}
          onSectionClicked={onSectionClicked}
          onSectionHovered={onSectionHovered}
          onToggleMirrors={onToggleMirrors}
          setSelectedSections={onSelectedSectionsChange}
          markedSectionIds={compListingSectionIds}
          colorBandProps={undefined}
          getColor={getColor}
          infoIconContent={<Content id={ContentId.AutoPricingDisclaimer} />}
        />
      </div>
      <div className={styles.compsContainerFull}>
        <ConnectedComparableListings
          currentListing={populateListingWithSeatScore(listing)}
          currentListingFromListingGroup={undefined}
          comparableListings={compListingsWithSeatScore}
          sortBy={
            priceUpdates.undercutMode === AutoPricingUndercutMode.Deal &&
            priceUpdates.autoPricingEnabled
              ? 'dealScore'
              : 'price'
          }
          isSortDescending={false}
          isLoading={priceCalcQuery.isLoading}
          listings={undefined}
          showAutoPriceResults={Boolean(
            priceUpdates.autoPricingEnabled === true
          )}
          listingPriceCalc={priceCalcQuery.data}
        />
      </div>
    </Stack>
  );
};
