import { format } from 'date-fns';
import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleCommentNote } from 'src/WebApiController';

import * as styles from './CommentSection.css';

export const CommentSection = ({
  commentNotes,
}: {
  commentNotes?: SaleCommentNote[] | null;
}) => {
  return (
    <Stack direction="column" gap="xl">
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.tableCell}>
              <span>
                <Content id={ContentId.Date} />
              </span>
            </th>
            <th className={styles.tableCell}>
              <span>
                <Content id={ContentId.Email} />
              </span>
            </th>
            <th className={styles.tableCell}>
              <span>
                <Content id={ContentId.Text} />
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {commentNotes
            ?.sort((a, b) => {
              return a.date && b.date
                ? new Date(b.date).getTime() - new Date(a.date).getTime()
                : 0;
            })
            .map((cmt, i) => (
              <tr key={`comment-${i}`}>
                <td className={styles.tableCell}>
                  <span
                    title={
                      cmt.date
                        ? format(new Date(cmt.date), 'MMMM dd, yyyy hh:mmaa')
                        : ''
                    }
                  >
                    {cmt.date
                      ? format(new Date(cmt.date), 'MMMM dd, yyyy hh:mmaa')
                      : ''}
                  </span>
                </td>
                <td title={cmt.email ?? undefined} className={styles.tableCell}>
                  <span>{cmt.email}</span>
                </td>
                <td className={styles.tableCell}>
                  <span title={cmt.text ?? undefined}>{cmt.text}</span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Stack>
  );
};
