import {
  functionalUpdate,
  InitialTableState,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { Table } from 'src/tables/Table';
import { AutoGroupListingsPreviewListingUpdate } from 'src/WebApiController';

import { getListingGroupTableConfig } from './GroupListingAutoCompTableConfig';

export type GroupListingAutoCompTableDataProps = {
  data: AutoGroupListingsPreviewListingUpdate[];
};

export type GroupListingAutoCompTableProps =
  GroupListingAutoCompTableDataProps & {
    onMount?: () => void;
    onUnmount?: (state: object) => void;
    initState?: InitialTableState;
    sorting?: SortingState;
  };

const PAGE_SIZE = 10;

export function GroupListingAutoCompTable({
  data,
  onMount,
  onUnmount,
  initState,
}: GroupListingAutoCompTableProps) {
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: PAGE_SIZE,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize: Math.max(pageSize, data.length),
    }),
    [data.length, pageIndex, pageSize]
  );

  const [sorting, setSorting] = useState<SortingState>([
    { id: 'name', desc: false },
  ]);

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const options: Partial<
    TableOptions<AutoGroupListingsPreviewListingUpdate | null>
  > = useMemo(
    () => ({
      data,
      columns: getListingGroupTableConfig(),
      state: {
        pagination,
        sorting,
      },
      onPaginationChange: setPagination,
      onSortingChange: (sortingUpdaterFn) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
      },
    }),
    [data, pagination, sorting]
  );

  return <Table options={options} />;
}
