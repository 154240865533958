import { useCallback, useEffect, useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import { useFormContext, useWatch } from 'react-hook-form';
import { EntityWithRealTicketsPartial } from 'src/components/UploadArtifacts/UploadETicketsV2';
import { useUploadETicketsContext } from 'src/components/UploadArtifacts/UploadETicketsV2/UploadETicketsContext';
import { UploadETicketsDraggableSource } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Draggable/constants';
import { droppable } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Seats/droppable.css';
import { MappedSeat } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Seats/MappedSeat/MappedSeat';
import { SeatInfoProps } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Seats/SeatInfo/SeatInfo';
import { UnMappedSeat } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Seats/UnmappedSeat/UnmappedSeat';
import { UploadETicketsDraggableData } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/UploadETicketsDraggableData';
import { Content } from 'src/contexts/ContentContext';
import { ErrorMessage } from 'src/core/POS/ErrorMessage';
import { PosDroppable } from 'src/core/POS/PosDragNDrop';
import { PosCustomDragLayer } from 'src/core/POS/PosDragNDrop/PosCustomDragLayer';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DocumentPage,
  DocumentType,
  Ticket,
  TicketType,
  UserDocumentLinks,
} from 'src/WebApiController';

import * as styles from '../UploadETickets.css';
import {
  UploadETicketsSeat,
  UploadETicketsSeatingContainer,
} from '../UploadETickets.styled';
import {
  DocumentFileUploadInfo,
  ETicketsForm,
  getDocumentPageUniqueId,
  getETicketDocumentPageUniqueId,
  getPageToDocumentFileUploadInfoMap,
  UniquePageIdToDocumentFileUploadInfoMap,
} from '../UploadETickets.utils';

export function UploadETicketsSeatingSection({
  entityWithTickets,
  eTicketUploadInfos,
  ticketType,
}: {
  entityWithTickets: EntityWithRealTicketsPartial;
  ticketType: TicketType.ETicket | TicketType.QRCode;
  eTicketUploadInfos: DocumentFileUploadInfo[];
}) {
  const uniquePageIdToDocumentFileUploadInfoMap: UniquePageIdToDocumentFileUploadInfoMap =
    useMemo(
      () => getPageToDocumentFileUploadInfoMap(eTicketUploadInfos),
      [eTicketUploadInfos]
    );
  const { setValue, clearErrors, formState } = useFormContext<ETicketsForm>();
  const watchTickets: UserDocumentLinks[] = useWatch({
    name: 'ticketAssignments',
  });

  const hasMappings = useMemo(() => {
    return watchTickets.some((ticket) => ticket.documentId.length > 0);
  }, [watchTickets]);

  const {
    setUniquePageIdToDocumentFileUploadInfoMap,
    selectedUniquePageId,
    setSelectedUniquePageId,
    selectNextUnmappedTicket,
  } = useUploadETicketsContext();

  useEffect(() => {
    setUniquePageIdToDocumentFileUploadInfoMap(
      uniquePageIdToDocumentFileUploadInfoMap
    );
  }, [
    setUniquePageIdToDocumentFileUploadInfoMap,
    uniquePageIdToDocumentFileUploadInfoMap,
  ]);

  const onDroppingToSeat = useCallback(
    (eTicket: DocumentPage, ticket: Ticket, ticketIndex: number) => {
      setValue(`ticketAssignments.${ticketIndex}`, {
        entityId: ticket.id,
        documentId: eTicket.documentId,
        pageNumber: eTicket.pageNumber,
        pageId: eTicket.id,
        pageUri: eTicket.thumbUrl,
        documentUri: eTicket.documentUrl,
        documentType:
          ticketType === TicketType.ETicket
            ? DocumentType.ETicket
            : DocumentType.QRCode,
      });
      clearErrors(`ticketAssignments.${ticketIndex}`);
      setSelectedUniquePageId(getDocumentPageUniqueId(eTicket));
    },
    [clearErrors, setSelectedUniquePageId, setValue, ticketType]
  );

  const onUnmappingFromSeat = useCallback(
    (ticket: Ticket, ticketIndex: number) => {
      setValue(`ticketAssignments.${ticketIndex}`, {
        entityId: ticket.id,
        documentId: '',
        pageNumber: 0,
        pageId: '',
        pageUri: '',
        documentUri: '',
        documentType: null,
      });
    },
    [setValue]
  );

  const onClearAll = useCallback(() => {
    entityWithTickets.tickets.forEach((ticket, ticketIndex) => {
      onUnmappingFromSeat(ticket, ticketIndex);
    });
  }, [entityWithTickets.tickets, onUnmappingFromSeat]);

  const { isDragging } = useDragLayer((monitor) => {
    return {
      isDragging: monitor.isDragging(),
    };
  });

  return (
    <div className={styles.uploadETicketsSectionPanel}>
      <div className={styles.seatAssignmentSectionHeader}>
        <Content id={ContentId.Seats} />
        <Button
          className={styles.clearButton}
          variant="link"
          onClick={onClearAll}
          disabled={!hasMappings}
        >
          <Content id={ContentId.ClearAll} />
        </Button>
      </div>
      <div className={styles.divider} />
      <UploadETicketsSeatingContainer>
        {entityWithTickets.tickets.map((ticket, ticketIndex) => {
          const assignment = watchTickets.find(
            (wt) => wt.entityId === ticket.id
          );
          const uniquePageId =
            (assignment && getETicketDocumentPageUniqueId(assignment)) ?? '';
          const docUploadInfos =
            assignment && uniquePageIdToDocumentFileUploadInfoMap[uniquePageId];

          const error =
            formState?.errors?.ticketAssignments?.[ticketIndex]?.message;

          const eTicketPage = docUploadInfos?.page;
          const seatInfo: SeatInfoProps = {
            section: entityWithTickets.seating.section,
            row: ticket.row,
            seatFr: ticket.seat,
            ticketIndex: ticketIndex,
          };
          return (
            <UploadETicketsSeat key={ticket.id}>
              <PosDroppable<UploadETicketsDraggableData>
                hideBorder={true}
                onOverClassnames={droppable}
                draggableSource={UploadETicketsDraggableSource}
                onDrop={(args: { data: UploadETicketsDraggableData }) => {
                  const page = args.data.componentProps.page;
                  if (eTicketPage) {
                    if (
                      eTicketPage.id === page.id &&
                      eTicketPage.documentId === page.documentId
                    ) {
                      // same stuff, dont' do anything
                      return;
                    } else {
                      // Unmap the seat first prior to setting a new one, so that the rendering can happen properly
                      onUnmappingFromSeat(ticket, ticketIndex);
                    }
                  }

                  // If the dropped page was already assigned before
                  const assignedTicket = watchTickets.find(
                    (wt) =>
                      wt.documentId === page.documentId && wt.pageId === page.id
                  );
                  if (assignedTicket) {
                    const ticketToUnassign = entityWithTickets.tickets.find(
                      (wt) => wt.id === assignedTicket.entityId
                    );
                    if (ticketToUnassign) {
                      const ticketIndexToUnassign =
                        entityWithTickets.tickets.indexOf(ticketToUnassign);
                      onUnmappingFromSeat(
                        ticketToUnassign,
                        ticketIndexToUnassign
                      );
                    }
                  }
                  onDroppingToSeat(page, ticket, ticketIndex);
                  selectNextUnmappedTicket(getDocumentPageUniqueId(page));
                }}
              >
                {eTicketPage && !isDragging ? (
                  <MappedSeat
                    seatInfo={seatInfo}
                    fileName={
                      docUploadInfos?.documentFileUploadInfo?.file?.name ?? ''
                    }
                    onUnMapSeat={() => onUnmappingFromSeat(ticket, ticketIndex)}
                    isSelected={
                      !!selectedUniquePageId &&
                      uniquePageId === selectedUniquePageId
                    }
                    onClick={() => setSelectedUniquePageId(uniquePageId)}
                  />
                ) : (
                  <UnMappedSeat
                    highlightDrop={isDragging}
                    seatInfo={seatInfo}
                  />
                )}
              </PosDroppable>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <PosCustomDragLayer />
            </UploadETicketsSeat>
          );
        })}
      </UploadETicketsSeatingContainer>
    </div>
  );
}
