import { ColumnDef } from '@tanstack/react-table';
import { Content } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { GridActionType } from 'src/utils/tableUtils';
import {
  PurchasePaymentMethod,
  PurchasePaymentMethodType,
} from 'src/WebApiController';

export type PurchasePaymentMethodWithHandlers = {
  purchasePaymentMethod: PurchasePaymentMethod;
};

export const PAYMENT_METHOD_TABLE_COLUMNS_CONFIG: ColumnDef<PurchasePaymentMethodWithHandlers | null>[] =
  [
    {
      id: 'paymentMethodDisplay',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.PaymentMethod} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.purchasePaymentMethod?.display,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
    },
    {
      id: 'paymentMethodName',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Name} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.purchasePaymentMethod?.name,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
    },
    {
      id: 'paymentMethodType',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Type} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.purchasePaymentMethod?.type,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as PurchasePaymentMethodType;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
    },
  ];
