import { debounce } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useToggle } from 'react-use';
import {
  EventEntitySearchBox,
  SearchMode,
} from 'src/components/Accordions/EventEntitySearchBox/EventEntitySearchBox';
import { EventEntitySearchBoxV2 } from 'src/components/Accordions/EventEntitySearchBox/EventEntitySearchBoxV2';
import { BackSection } from 'src/components/common/BackSection';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { MultiSelectionToggleGlobal } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGlobal';
import { FilterToolbar } from 'src/components/FilterToolbar';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import { ViewModeSelector } from 'src/components/MainFilterBar';
import * as styles from 'src/components/MainFilterBar/MainFilterBar.css';
import {
  purchaseMandatoryFiltersToShow,
  usePurchaseFilters,
} from 'src/components/MainFilterBar/usePurchaseFilters';
import { PurchaseGlobalActionsPermissionsWrapper } from 'src/components/Purchases/PurchaseFilterBar/PurchaseGlobalActionsPermissionsWrapper';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { LaunchBulkUploadPurchaseDialog } from 'src/dialogs/BulkEdits/BulkUploadPurchaseDialog';
import { SearchResultPickerDialog } from 'src/dialogs/SearchResultPickerDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useFilterToolbarQuickFilters } from 'src/hooks/useQuickFilters';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { MessagesAddSourceModal } from 'src/modals/MessagesAddSourceModal/MessagesAddSourceModal';
import { PurchaseWizardModalConfig } from 'src/modals/PurchaseWizard/PurchaseWizardModalConfig';
import { IconsFill, SettingsIcon } from 'src/svgs/Viagogo';
import { PurchaseDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { PURCHASE_VIEW_MODE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import {
  DefaultPurchaseQuery,
  getQueryFromUrl,
} from 'src/utils/eventQueryUtils';
import {
  combineWithTicketGroupIdSimple,
  getPurchaseOrderDetailsModalConfigWithDeepLink,
  splitOutTicketGroupId,
} from 'src/utils/purchaseUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Feature,
  Permission,
  PurchaseClient,
  PurchaseOrder,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  PurchaseViewMode,
  UserSetting,
} from 'src/WebApiController';

import { PurchaseGlobalActionDropdown } from '../PurchaseActionDropdown';
import { PurchaseOrderBasicInfo } from '../PurchaseOrderBasicInfo';
import { useDefaultPurchaseFilters } from './hooks/useDefaultPurchaseFilters';
import { PURCHASE_VIEW_MODE_TO_ICON } from './PurchaseFilterBar.types';

export function PurchaseFilterBar({
  isEventPage = false,
  returnUrl,
  posEventId,
}: {
  isEventPage?: boolean;
  returnUrl?: string;
  posEventId?: string;
}) {
  const { posEntity: purchase, setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();
  const { setModal, isModalOpen } = useContext(ModalContext);
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const { activeAccountWebClientConfig } = useAppContext();

  const [searchId, setSearchId] = useState<string>();
  const [searchResults, setSearchResults] = useState<PurchaseOrderDetails[]>();
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const { selectionMode, setSelectionMode } = useMultiSelectionContext();
  const [searchText, setSearchText] = useState('');
  const [isAddSourceModalOpen, setIsAddSourceModalOpen] = useToggle(false);

  const hasCreatePermission = useUserHasAnyOfPermissions(
    Permission.Purchases_Create
  );

  const hasAutoPoFeature = useUserHasFeature(Feature.AutoPO);
  const hasBulkEditInPurchaseEventFeature = useUserHasFeature(
    Feature.BulkEditInPurchaseEvent
  );
  const hasEventEntitySearchV2 = useUserHasFeature(Feature.EventEntitySearchV2);
  const hasFullPageEventViewEntitySelection = useUserHasFeature(
    Feature.FullPageEventViewEntitySelection
  );

  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    filterQuery: purchaseQuery,
    initialQuery,
    tempQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<PurchaseOrderQuery>();

  const onCloseAddSourceModal = () => {
    setIsAddSourceModalOpen(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      if (text !== purchaseQuery.searchText) {
        setFilterQuery({
          ...purchaseQuery,
          searchText: text || null,
        });
      }
    }, 200),
    [purchaseQuery]
  );

  useEffect(() => {
    setTempQuery(purchaseQuery);
  }, [purchaseQuery, setTempQuery]);

  useEffect(() => {
    if (
      purchaseQuery.marketplaceEntityIds &&
      hasFullPageEventViewEntitySelection
    ) {
      return;
    }
    setSearchText(purchaseQuery.searchText ?? '');
  }, [
    hasFullPageEventViewEntitySelection,
    purchaseQuery.marketplaceEntityIds,
    purchaseQuery.searchText,
  ]);

  const activeId = getDeepLinkIdFromUrl(
    PurchaseDeeplinkQueryParam,
    window.location.href
  );

  const clearSearchEntity = useCallback(() => {
    const newQuery = {
      ...tempQuery,
      searchText: null,
      entityIds: null,
      marketplaceEntityIds: null,
    };
    setFilterQuery(newQuery);
  }, [tempQuery, setFilterQuery]);

  const onLookupById = useCallback(
    async (idText: string, isSearching: boolean) => {
      const { id, ticketGroupId } = splitOutTicketGroupId(idText);
      const idNum = Number(id);
      const idStr = String(id);
      if (
        !id &&
        tempQuery.marketplaceEntityIds &&
        hasFullPageEventViewEntitySelection
      ) {
        const newQuery = {
          ...tempQuery,
          entityIds: null,
          marketplaceEntityIds: null,
        };
        setFilterQuery({ ...newQuery });
        return;
      }

      if (id && !isLoadingSearch) {
        if (purchase?.vendOrdId === idStr || purchase?.id === idNum) {
          // If the id hasn't changed, check that if it's open, if it's not, open it
          if (!isModalOpen) {
            setModal(
              getPurchaseOrderDetailsModalConfigWithDeepLink(
                combineWithTicketGroupIdSimple(purchase.id, ticketGroupId)
              )
            );
          }

          // Else, just don't do anything
          return;
        }

        if (hasFullPageEventViewEntitySelection) {
          const matchedIds = idStr
            .split(',')
            .filter((s) => s.trim())
            .map((s) => s.trim()); // split by commas and remove all empty ones

          if (matchedIds.length > 1) {
            const newQuery = {
              ...DefaultPurchaseQuery,
              marketplaceEntityIds: matchedIds,
              viewMode: PurchaseViewMode.FlattenedView,
            };
            setFilterQuery(newQuery);
            return;
          } else if (tempQuery.marketplaceEntityIds) {
            const newQuery = {
              ...tempQuery,
              marketplaceEntityIds: null,
            };

            setFilterQuery(newQuery);
          }
        }

        setSearchId(idStr);
        setIsLoadingSearch(true);

        try {
          // when user enter a text here, we don't know whether they entered the order id or unique id
          // since 99% of the time they'll be entering a order id, we try to lookup by that first
          const purchaseOrders = isSearching
            ? await tryInvokeApi(
                () =>
                  new PurchaseClient(
                    activeAccountWebClientConfig
                  ).getPurchaseOrdersByVendorOrderId(idStr),
                (error) => {
                  showErrorDialog(
                    'PurchaseClient.getPurchaseOrdersByVendorOrderId',
                    error,
                    { trackErrorData: { saleId: id } }
                  );
                }
              )
            : null;

          if (!purchaseOrders || purchaseOrders.length === 0) {
            if (isNaN(idNum)) {
              // If we can't find by either PO external id or internal id, just launch empty search result dialog
              launchDialog();
              return false;
            }

            // If we didn't find any POs by vendor-order-id, assume this is a unique id search
            setActivePosEntity(idNum, undefined, true);
            setModal(
              getPurchaseOrderDetailsModalConfigWithDeepLink(
                combineWithTicketGroupIdSimple(idNum, ticketGroupId)
              )
            );

            return true;
          } else {
            // We have POs using the vendor order id
            // If there is duplicate vendor order id (which is rare)
            // we need to present to the user and ask them what they want
            if (purchaseOrders.length === 1) {
              const poInfo = purchaseOrders[0];
              // happy path
              setActivePosEntity(poInfo.id, poInfo.vendOrdId, true);
              setModal(
                getPurchaseOrderDetailsModalConfigWithDeepLink(
                  combineWithTicketGroupIdSimple(poInfo.id, ticketGroupId)
                )
              );

              return true;
            } else {
              setSearchResults(
                purchaseOrders.sort((a, b) => {
                  return a.poDate && b.poDate
                    ? a.poDate < b.poDate
                      ? 1
                      : -1
                    : 0;
                })
              );
              launchDialog();
            }
          }
        } finally {
          setIsLoadingSearch(false);
        }
      }

      return false;
    },
    [
      activeAccountWebClientConfig,
      hasFullPageEventViewEntitySelection,
      isLoadingSearch,
      isModalOpen,
      launchDialog,
      purchase?.id,
      purchase?.vendOrdId,
      setActivePosEntity,
      setFilterQuery,
      setModal,
      showErrorDialog,
      tempQuery,
    ]
  );

  const onResultItemPicked = useCallback(
    (purchaseOrder: PurchaseOrder) => {
      setActivePosEntity(purchaseOrder.id, purchaseOrder.vendOrdId, true);
      setModal(
        getPurchaseOrderDetailsModalConfigWithDeepLink(purchaseOrder.id)
      );

      // We do not want to close the search dialog as users may want to click each one
      // We'll let them close the search dialog manually
    },
    [setActivePosEntity, setModal]
  );

  useEffect(() => {
    if (activeId?.length) {
      onLookupById(activeId, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeId]);

  useEffect(() => {
    if (!hasFullPageEventViewEntitySelection) {
      return;
    }

    // Since we only support id searching in flattened view for Purchases, reset filters when view mode changes
    if (
      purchaseQuery.viewMode !== PurchaseViewMode.FlattenedView &&
      !isEventPage &&
      purchaseQuery.marketplaceEntityIds
    ) {
      setFilterQuery({
        ...purchaseQuery,
        marketplaceEntityIds: null,
        searchText: null,
      });
      setSearchText('');
    }
  }, [
    purchaseQuery.marketplaceEntityIds,
    purchaseQuery.viewMode,
    isEventPage,
    purchaseQuery,
    setFilterQuery,
    hasFullPageEventViewEntitySelection,
  ]);

  const location = useLocation();

  useEffect(() => {
    if (!hasFullPageEventViewEntitySelection) {
      return;
    }

    const queryFromUrl = getQueryFromUrl<PurchaseOrderQuery>(
      initialQuery,
      location.search
    );

    if (
      Array.isArray(queryFromUrl?.marketplaceEntityIds) &&
      queryFromUrl?.marketplaceEntityIds.length
    ) {
      setSearchText(() =>
        queryFromUrl?.marketplaceEntityIds
          ? queryFromUrl?.marketplaceEntityIds.join(',')
          : ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFullPageEventViewEntitySelection, initialQuery, setFilterQuery]);

  const defaultQuickFilters = useDefaultPurchaseFilters({
    purchaseQuery,
    initialQuery,
  });

  const useFiltersDialogV2AllApp = useUserHasFeature(
    Feature.FilterToolBarUIV2AllApp
  );

  const { value: defaultViewModeUserSetting, setUserSetting } =
    useServerUserSetting<PurchaseViewMode>({
      id: UserSetting.PurchasePageViewMode,
      currentLoginUserOnly: true,
    });

  const filterToolbarProps = useFilterToolbarQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStatePurchases,
    customQuickFiltersSetting: UserSetting.QuickFiltersCustomPurchases,
    defaultQuickFilters,
    currentQuery: purchaseQuery,
    initialQuery,
    onSelect: ({ id, query }) => {
      if (query.viewMode != null) {
        // We need to update initialQuery, otherwise the quick filter "All"
        // may change the current view mode
        if (query.viewMode !== initialQuery.viewMode) {
          initialQuery.viewMode = query.viewMode;
        }
      }
      setFilterQuery(query);

      // This needs to happen after to reduce re-rendering
      if (query.viewMode != null) {
        // Update user setting view mode too so it won't revert the filter query viewMode
        if (query.viewMode !== defaultViewModeUserSetting) {
          setUserSetting(query.viewMode);
        }
      }
    },
  });

  const isMobile = useMatchMedia('mobile');

  const onAddPurchase = () => {
    setActivePosEntity(0);
    setModal(PurchaseWizardModalConfig);
  };

  const purchaseFilters = usePurchaseFilters();

  const { appliedFilters } = useFiltersHelpers({
    filters: purchaseFilters,
    activeQuery: purchaseQuery,
    mandatoryFiltersToShow: purchaseMandatoryFiltersToShow,
    initialQuery,
  });

  useEffect(() => {
    if (
      purchaseQuery.viewMode === PurchaseViewMode.EventTileView &&
      selectionMode &&
      !hasBulkEditInPurchaseEventFeature
    ) {
      setSelectionMode(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseQuery.viewMode]);

  return (
    <>
      <div className={styles.mainFilterBarDiv}>
        <div className={styles.dockLeftDiv}>
          <div className={styles.mainFilterBarLeftChild}>
            {isEventPage && (
              <BackSection
                returnUrl={returnUrl ?? '/purchases'}
                state={{
                  fromPosEventId: posEventId,
                }}
              />
            )}
            {purchaseQuery && (
              <FilterToolbar
                {...filterToolbarProps}
                filterAppliedCounts={appliedFilters.length}
                onSubmitFilter={() => {
                  setFilterQuery(tempQuery);
                }}
                mandatoryFiltersToShow={purchaseMandatoryFiltersToShow}
                filters={purchaseFilters}
                filterAppliedIds={appliedFilters}
                useNewFilterUX={useFiltersDialogV2AllApp}
                resetTempQuery={resetTempQuery}
                tempQuery={tempQuery}
              />
            )}
          </div>
        </div>
        <div className={styles.dockRightDiv}>
          <div className={styles.mainFilterBarRightChild}>
            {!isMobile && !isEventPage && (
              <>
                <BulkEditStatusPopover
                  entityType={ActionOutboxEntityType.Purchase}
                />
                <ViewModeSelector
                  query={purchaseQuery}
                  initialQuery={initialQuery}
                  setFilterQuery={setFilterQuery}
                  viewModeSettingsId={UserSetting.PurchasePageViewMode}
                  viewModeCidMap={PURCHASE_VIEW_MODE_TO_CID}
                  viewModeIconMap={PURCHASE_VIEW_MODE_TO_ICON}
                />
                {(purchaseQuery.viewMode !== PurchaseViewMode.EventTileView ||
                  hasBulkEditInPurchaseEventFeature) && (
                  <PurchaseGlobalActionsPermissionsWrapper>
                    <MultiSelectionToggleGlobal />
                  </PurchaseGlobalActionsPermissionsWrapper>
                )}
                {hasEventEntitySearchV2 ? (
                  <EventEntitySearchBoxV2
                    maxWidth={350}
                    disabled={isLoadingSearch}
                    onIdLookup={(value) => value && onLookupById(value, true)}
                    onEventSearch={(value) => debouncedOnSearchChange(value)}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    clearSearchEntity={clearSearchEntity}
                  />
                ) : (
                  <EventEntitySearchBox
                    entityLookupPlaceholder={ContentId.LookupByOrderId}
                    maxWidth={350}
                    disabled={isLoadingSearch}
                    onIdLookup={(value) => value && onLookupById(value, true)}
                    onEventSearch={(value) => debouncedOnSearchChange(value)}
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                )}
              </>
            )}
            {isEventPage && hasFullPageEventViewEntitySelection && (
              <EventEntitySearchBox
                entityLookupPlaceholder={ContentId.LookupByOrderId}
                maxWidth={350}
                disabled={isLoadingSearch}
                onIdLookup={(value) => onLookupById(value, true)}
                onEventSearch={(value) => debouncedOnSearchChange(value)}
                searchText={searchText}
                setSearchText={setSearchText}
                defaultSearchMode={SearchMode.IdLookup}
                freezeSearchMode
              />
            )}
          </div>
        </div>
      </div>
      {isMobile && (
        <div className={styles.mainFilterBarDiv}>
          <div className={styles.dockLeftDiv}>
            <div className={styles.mainFilterBarLeftChild}>
              <Stack alignItems="center" gap="m">
                {hasCreatePermission && (
                  <>
                    <Button
                      variant="regular"
                      onClick={onAddPurchase}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <Content
                        id={
                          isMobile ? ContentId.Purchase : ContentId.AddPurchase
                        }
                      />
                    </Button>

                    {!isMobile && (
                      <LaunchBulkUploadPurchaseDialog
                        className="add-purchase"
                        variant="outline"
                      />
                    )}

                    {hasAutoPoFeature && !isMobile && (
                      <>
                        <Button
                          variant="outline"
                          onClick={setIsAddSourceModalOpen}
                        >
                          <SettingsIcon
                            withHoverEffect
                            size={vars.iconSize.s}
                            fill={IconsFill.currentColor}
                          />
                          <Content id={ContentId.AutoSetup} />
                        </Button>

                        <MessagesAddSourceModal
                          isOpen={isAddSourceModalOpen}
                          onClose={onCloseAddSourceModal}
                        />
                      </>
                    )}
                  </>
                )}
              </Stack>

              <Stack alignItems="center" gap="m">
                <BulkEditStatusPopover
                  entityType={ActionOutboxEntityType.Purchase}
                />
                <ViewModeSelector
                  query={purchaseQuery}
                  initialQuery={initialQuery}
                  setFilterQuery={setFilterQuery}
                  viewModeSettingsId={UserSetting.PurchasePageViewMode}
                  viewModeCidMap={PURCHASE_VIEW_MODE_TO_CID}
                  viewModeIconMap={PURCHASE_VIEW_MODE_TO_ICON}
                />
                <PurchaseGlobalActionsPermissionsWrapper showBulkUpload>
                  <MultiSelectionToggleGlobal />
                  <PurchaseGlobalActionDropdown showBulkUpload />
                </PurchaseGlobalActionsPermissionsWrapper>

                {hasEventEntitySearchV2 ? (
                  <EventEntitySearchBoxV2
                    maxWidth={400}
                    disabled={isLoadingSearch}
                    onIdLookup={(value) => value && onLookupById(value, true)}
                    onEventSearch={(value) => debouncedOnSearchChange(value)}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    clearSearchEntity={clearSearchEntity}
                  />
                ) : (
                  <EventEntitySearchBox
                    entityLookupPlaceholder={ContentId.LookupByOrderId}
                    maxWidth={400}
                    disabled={isLoadingSearch}
                    onIdLookup={(value) => value && onLookupById(value, true)}
                    onEventSearch={(value) => debouncedOnSearchChange(value)}
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                )}
              </Stack>
            </div>
          </div>
        </div>
      )}
      <SearchResultPickerDialog
        {...dialogProps}
        header={
          <FormatContent
            id={
              searchResults?.length
                ? FormatContentId.SearchResultPick
                : FormatContentId.CouldNotFindOrderId
            }
            params={[searchId || '', `${searchResults?.length || 0}`]}
          />
        }
        resultItems={searchResults || []}
        renderItem={(purchaseOrder: PurchaseOrderDetails) => (
          <PurchaseOrderBasicInfo purchaseOrder={purchaseOrder} />
        )}
        onResultItemPicked={onResultItemPicked}
        onCancel={closeDialog}
      />
    </>
  );
}
