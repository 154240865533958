import { useCallback, useMemo } from 'react';
import {
  allTimezones,
  ILabelStyle,
  useTimezoneSelect,
} from 'react-timezone-select';
import { Content } from 'src/contexts/ContentContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useCustomTimeZoneSettings } from 'src/hooks/api/useCustomTimeZoneSettings';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './MyAccount.css';

export const TimezoneSection = () => {
  const { customTimeZoneSettings, mergeCustomTimeZoneSettings } =
    useCustomTimeZoneSettings();

  const selectOptions = {
    labelStyle: 'original' as ILabelStyle,
    timezones: allTimezones,
  };

  const { options, parseTimezone } = useTimezoneSelect(selectOptions);

  const onTimezoneChange = useCallback(
    (timezoneValue: string) => {
      if (!timezoneValue) {
        mergeCustomTimeZoneSettings({ input: undefined });
      }

      const timezone = parseTimezone(timezoneValue);
      if (
        timezone.offset != null &&
        timezone.value != null &&
        customTimeZoneSettings?.value !== timezone.value
      ) {
        mergeCustomTimeZoneSettings({
          input: {
            timezoneOffsetMins: -timezone.offset * 60,
            value: timezone.value,
          },
        });
      }
    },
    [parseTimezone, mergeCustomTimeZoneSettings, customTimeZoneSettings?.value]
  );

  const timezoneOptions = useMemo(
    () =>
      Object.values(options || []).reduce(
        (result, cur) => {
          result[cur.value] = cur.label;
          return result;
        },
        {} as Record<string, string>
      ),
    [options]
  );

  return (
    <div
      className={styles.sectionContainer}
      style={{ gap: vars.spacing['xl'] }}
    >
      <h5 className={styles.sectionHeading}>
        <Content id={ContentId.Timezone} />
      </h5>

      <Stack direction="column" gap="m">
        <p className={styles.subtleText}>
          <Content id={ContentId.TimezoneDescription} />
        </p>
        <div className={styles.label}>
          <Content id={ContentId.Timezone} />
        </div>

        <PosSelect
          className={styles.select}
          value={customTimeZoneSettings?.value ?? undefined}
          placeholderText={ContentId.TimezoneDefaultOption}
          enableEmptySelection
          searchable
          valueOptionsContent={timezoneOptions}
          onChange={onTimezoneChange}
        />
      </Stack>
    </div>
  );
};
