import { useCallback } from 'react';
import { LaunchBulkEditCommission } from 'src/components/common/BulkActions/BulkEditCommissionAction';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { BulkEditCommissionDialog } from 'src/dialogs/BulkEdits/BulkEditCommissionDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditPreviewWithDetails,
  BulkEditSaleClient,
  Commission,
  Event,
  SaleQuery,
} from 'src/WebApiController';

import { SALES_BULK_UPDATE_COMMISSION_UPDATE_KEY } from '../../SalesActionDropdown.constants';

export const useBulkEditCommissionAction = (
  filterQueryWithEventIds: SaleQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const updateKey = event
    ? `saleCommission-event-${event.viagVirtualId}`
    : SALES_BULK_UPDATE_COMMISSION_UPDATE_KEY;
  const {
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkEditCommissionDialog,
    closeDialog: closeBulkEditCommissionDialog,
  } = useDialog(DialogId.BulkEditCommission, BulkEditCommissionDialog);

  const onSubmit = useCallback(
    async (
      commissions: Commission[] | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      setIsLoading(true);

      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditSaleClient(
              activeAccountWebClientConfig
            ).bulkUpdatePurchaseCommissionsPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdatePurchaseCommissionsPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditSaleClient(
              activeAccountWebClientConfig
            ).bulkUpdatePurchaseCommissions(
              {
                item1: preview!.preview,
                item2: commissions ?? [],
              },
              updateKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on details modal
                // we force re-loading the entity and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }

              closeBulkEditCommissionDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdatePurchaseCommissions', error, {
              trackErrorData: { commissions, preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      setIsLoading,
      filterQueryWithEventIds,
      showErrorDialog,
      updateKey,
      closeBulkEditCommissionDialog,
      setActivePosEntity,
      refreshExpandedListItems,
    ]
  );

  const onLaunchAction = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (event) {
        setListItemExpansion(true, [event.viagVirtualId]);
      }
      openBulkEditCommissionDialog({
        updateKey,
        onOkay: onSubmit,
        onCancel: closeBulkEditCommissionDialog,
        onClosed: () => {
          setIsLoading(false);
        },
      });
    },
    [
      closeBulkEditCommissionDialog,
      event,
      onSubmit,
      openBulkEditCommissionDialog,
      setIsLoading,
      setListItemExpansion,
      updateKey,
    ]
  );
  return {
    dropDown: (
      <LaunchBulkEditCommission
        key="EditCommission"
        onClick={onLaunchAction}
        disabled={isLoading}
      />
    ),
  };
};
