import { useMatchMedia } from 'src/hooks/useMatchMedia';
import {
  SectionContentGridFormat,
  SectionContentGridMobileFormat,
} from 'src/modals/SaleDetailsV2/SaleDetails.constants';

export const useGetModalGridFormat = () => {
  const isMobile = useMatchMedia('mobile');

  return isMobile
    ? { SectionContentGridFormat: SectionContentGridMobileFormat }
    : { SectionContentGridFormat };
};
