import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function HomeOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        fill="inherit"
        d="M12.184 2L3 9.796V22h6.939v-6.53h6.122v-1.633H8.306V20.367H4.633v-9.836l7.55-6.409 7.552 6.409v9.836h-3.673v-4.081l-1.633.816V22h6.938V9.796z"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
