import {
  functionalUpdate,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { usePaymentsTableColumnsConfig } from 'src/tables/PaymentsTable';
import { Table } from 'src/tables/Table';
import { MarketplaceSalePayment } from 'src/WebApiController';

const getRowId = (row: MarketplaceSalePayment | null, index: number) =>
  (row?.id ?? index).toString();

interface PaymentsTableProps {
  tableData: MarketplaceSalePayment[] | null;
}

export const PaymentsTable = ({ tableData }: PaymentsTableProps) => {
  const paymentsTableColumnConfig = usePaymentsTableColumnsConfig();
  const [sorting, setSorting] = useState<SortingState>([]);

  const options: Partial<TableOptions<MarketplaceSalePayment | null>> = useMemo(
    () => ({
      data: tableData ?? [],
      columns: Object.values(paymentsTableColumnConfig),
      state: {
        sorting,
      },
      getRowId: getRowId,
      onSortingChange: (sortingUpdaterFn) => {
        const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
        setSorting(newSortVal);
      },
      sortingFns: {},
    }),
    [tableData, paymentsTableColumnConfig, sorting]
  );

  return (
    <Table
      options={options}
      tableLayout="fixed"
      withOuterPadding={true}
      useVirtuoso={false}
      usePaginationFooter={false}
    />
  );
};
