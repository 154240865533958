import { first, isEqual, orderBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useKey, useList } from 'react-use';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature, SectionInfo } from 'src/WebApiController';

// Mirror section is defined closest angle but opposite direction
const getMirrorSection = (selected: SectionInfo, candidates: SectionInfo[]) => {
  return first(
    orderBy(
      candidates,
      (s) =>
        Math.abs((selected.directionalAngle ?? 0) + (s.directionalAngle ?? 0)),
      'asc'
    )
  );
};

// Control + M to add mirrors to selected sections
export const useCtrlPlusM = (
  markedSectionIds: number[],
  onToggleMirrors?: (sections: SectionInfo[], exclude?: boolean) => void
) => {
  const { venueMapInfo } = useEventMapContext();
  const hasVenueMapCtrlPlusMFeature = useUserHasFeature(
    Feature.VenueMapCtrlPlusM
  );

  const [prevMarkedIds, setPrevMarkedIds] = useState<Set<number>>(new Set());
  const [mirrors, mirrorsMutation] = useList<number>();

  useEffect(() => {
    // Reset mirrors if marked sections changed not because of the mirrors
    if (!isEqual(prevMarkedIds, new Set(markedSectionIds))) {
      mirrorsMutation.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(markedSectionIds)]);

  const zonedSections = useMemo(() => {
    return (venueMapInfo?.sections || []).reduce(
      (map, section) => {
        const id = section.rows.find((row) => !!row.tktClass)?.tktClass?.id;
        if (id) {
          (map[id] = map[id] || []).push(section);
        }
        return map;
      },
      {} as Record<string, SectionInfo[]>
    );
  }, [venueMapInfo?.sections]);

  const selectedSections = useMemo(
    () =>
      (venueMapInfo?.sections ?? []).filter(({ id }) =>
        markedSectionIds.includes(id)
      ),
    [markedSectionIds, venueMapInfo?.sections]
  );

  const onCtrlMClicked = useCallback(() => {
    if (!hasVenueMapCtrlPlusMFeature || !onToggleMirrors) {
      return;
    }
    // Toggle mirrors when mirrors are already marked
    if (
      mirrors.length > 0 &&
      mirrors.every((id) => markedSectionIds.includes(id))
    ) {
      const mirrorSections = (venueMapInfo?.sections ?? []).filter((s) =>
        mirrors.includes(s.id)
      );
      onToggleMirrors(mirrorSections, true);
      mirrorsMutation.reset();
      return;
    }

    const sectionIdsToAdd: Record<number, SectionInfo> = {};
    for (const selected of selectedSections) {
      if (!selected.directionalAngle) {
        continue;
      }
      const classId = selected.rows.find((row) => !!row.tktClass)?.tktClass?.id;
      if (classId && classId in zonedSections) {
        const sectionCandidates = (zonedSections[classId] || []).filter(
          ({ directionalAngle }) => directionalAngle
        );
        const mirror = getMirrorSection(selected, sectionCandidates);
        if (
          mirror &&
          !markedSectionIds.includes(mirror.id) &&
          !sectionIdsToAdd[mirror.id]
        ) {
          mirrorsMutation.push(mirror.id);
          sectionIdsToAdd[mirror.id] = mirror;
        }
      }
    }
    if (Object.keys(sectionIdsToAdd).length) {
      const idsToAdd = Array.from(Object.keys(sectionIdsToAdd));
      setPrevMarkedIds(new Set([...markedSectionIds, ...idsToAdd.map(Number)]));
      onToggleMirrors(Object.values(sectionIdsToAdd));
    } else {
      console.log('No mirrors found');
    }
  }, [
    hasVenueMapCtrlPlusMFeature,
    markedSectionIds,
    mirrors,
    mirrorsMutation,
    onToggleMirrors,
    selectedSections,
    venueMapInfo?.sections,
    zonedSections,
  ]);

  useKey(
    (event: KeyboardEvent) => event.ctrlKey && event.key === 'm',
    onCtrlMClicked,
    { event: 'keydown' }
  );
};
