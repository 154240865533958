import { ColumnDef } from '@tanstack/react-table';
import { useCallback, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { EventDateDisplay } from 'src/components/Events/EventInfo';
import { useBulkChangeEventAction } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkChangeEventAction';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import {
  Content,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { HorizontalTextList } from 'src/core/POS/HorizontalTextList';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { useMapDeliveryTypesToString } from 'src/hooks/useMapDeliveryTypesToString';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LaunchDownloadUserDocuments } from 'src/modals/PurchaseWizard/LaunchDownloadUserDocuments';
import { PredeliveryActionsDropdown } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/components/PredeliveryActionsDropdown';
import { UploadPredeliveryAction } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/purchaseTicketsFlow.utils';
import { AmendActionData } from 'src/modals/PurchaseWizard/PurchaseWizard.types';
import { CopyIcon } from 'src/svgs';
import { OpenLinkIcon } from 'src/svgs/OpenLinkIcon';
import {
  DeleteIcon,
  EditIcon,
  IconsFill,
  MoreIcon,
  ProcessingIcon,
  UploadIcon,
} from 'src/svgs/Viagogo';
import {
  SeatInfoContainer,
  TableShimmeringDiv,
} from 'src/tables/Table/Table.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { EmptyPurchaseQuery } from 'src/utils/eventQueryUtils';
import {
  compareEventsByName,
  getEventPerformerVenue,
} from 'src/utils/eventWithDataUtils';
import { getListingNoteIcon } from 'src/utils/listingNotesUtils';
import { formatCurrency } from 'src/utils/numberFormatter';
import {
  combineWithTicketGroupId,
  combineWithTicketGroupIdSimple,
  getPurchaseOrderDetailsModalConfigWithDeepLink,
  getPurchaseOrderRelativeUrl,
} from 'src/utils/purchaseUtils';
import { sortMoney, sortSeatingForTable } from 'src/utils/tableUtils';
import { getDeliveryTypeIcon } from 'src/utils/ticketTypeUtils';
import { toSeating } from 'src/utils/ticketUtils';
import {
  DeliveryType,
  Event,
  Feature,
  ListingNote,
  Performer,
  PurchaseOrderDetails,
  Seating,
  TicketGroupEditReason,
  TicketGroupInput,
  Venue,
} from 'src/WebApiController';

import { TicketGroupStatusIndicator } from './TicketGroupStatusIndicator';
import * as styles from './TicketGroupTableColumnsConfig.css';

export type TicketGroupAdditionalData = {
  event?: Event | null;
  performer?: Performer | null;
  venue?: Venue | null;
  purchaseVendorOrderId?: string | null;
  amendActionData?: AmendActionData;
};

export type TicketGroupActionParams = {
  ticketGroupId: number;
  action: TicketGroupEditReason;
  additionalData?: TicketGroupAdditionalData;
};

export type TicketGroupWithHandlers = {
  ticketGroup: TicketGroupInput;
  disabled?: boolean;
  additionalData?: TicketGroupAdditionalData;
  onTicketGroupAction?: (
    params: TicketGroupActionParams,
    showConfirmDialog?: boolean
  ) => void;
  /**
   * Show the ticket groups from expanding a single purchase
   */
  showFromPurchaseAccordions?: boolean;
};

export const TICKET_GROUP_TABLE_COLUMNS_CONFIG: ColumnDef<TicketGroupWithHandlers | null>[] =
  [
    {
      id: 'event',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Event} />
        </TableHeaderCellDiv>
      ),
      minSize: 230,
      maxSize: 600,
      accessorFn: (data) => data?.ticketGroup,
      cell: function Cell({ row: { original } }) {
        const ticketGroup = original?.ticketGroup;
        const { data } = useCatalogDataContext();

        if (!ticketGroup) {
          return <TableShimmeringDiv />;
        }

        const { event } = getEventPerformerVenue(
          ticketGroup.viagogoVirtualId,
          data
        );

        return (
          <TableCellDiv align="left" title={event?.event?.name}>
            {event?.event?.name}
          </TableCellDiv>
        );
      },
      sortingFn: compareEventsByName,
    },
    {
      id: 'venue',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Venue} />
        </TableHeaderCellDiv>
      ),
      minSize: 230,
      maxSize: 600,
      accessorFn: (data) => data?.ticketGroup,
      cell: function Cell({ row: { original } }) {
        const ticketGroup = original?.ticketGroup;
        const { data } = useCatalogDataContext();

        if (!ticketGroup) {
          return <TableShimmeringDiv />;
        }

        const { venue } = getEventPerformerVenue(
          ticketGroup.viagogoVirtualId,
          data
        );

        return (
          <TableCellDiv align="left" title={venue?.name}>
            {venue?.name}
          </TableCellDiv>
        );
      },
    },
    {
      id: 'eventDate',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.EventDate} />
        </TableHeaderCellDiv>
      ),
      minSize: 230,
      maxSize: 600,
      accessorFn: (data) => data?.ticketGroup,
      cell: function Cell({ row: { original } }) {
        const ticketGroup = original?.ticketGroup;
        const { data } = useCatalogDataContext();

        if (!ticketGroup) {
          return <TableShimmeringDiv />;
        }

        const { event } = getEventPerformerVenue(
          ticketGroup.viagogoVirtualId,
          data
        );

        return (
          <TableCellDiv align="left">
            <EventDateDisplay
              eventDateString={event?.event?.dates?.start ?? undefined}
              eventEndDateString={event?.event?.dates?.end ?? undefined}
              showEventDateTbd={true}
              eventSubDateFormat={' - h:mm aaa, eee'}
              slimMode
            />
          </TableCellDiv>
        );
      },
    },
    {
      id: 'seating',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Seats} />
        </TableHeaderCellDiv>
      ),
      minSize: 230,
      maxSize: 400,
      accessorFn: (data) =>
        data
          ? {
              seating: toSeating(data.ticketGroup),
              listingNotes: data.ticketGroup.listingNotes?.value,
            }
          : null,
      cell: function Cell({ getValue, row: { original } }) {
        const contentContext = useContentContext();
        const hasListingNotesAdditionalInfoFeature = useUserHasFeature(
          Feature.ListingNotesAdditionalInfo
        );

        const value = getValue();
        if (!original) {
          return <TableShimmeringDiv />;
        }

        const { seating, listingNotes } = value as {
          seating: Seating;
          listingNotes: ListingNote[] | null;
        };
        return (
          <SeatInfoContainer>
            <SeatingInfo {...(seating as Seating)} />
            {listingNotes?.length ? (
              <HorizontalTextList
                className={styles.listingNotes}
                preIcon={getListingNoteIcon(
                  listingNotes[0],
                  contentContext,
                  hasListingNotesAdditionalInfoFeature
                )}
                texts={listingNotes.map((n) => n.listingNoteText)}
                maxTextsToShowOnHover={listingNotes.length}
              />
            ) : null}
          </SeatInfoContainer>
        );
      },
      sortingFn: sortSeatingForTable<TicketGroupWithHandlers>,
    },
    {
      id: 'quantity',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Quantity} />
        </TableHeaderCellDiv>
      ),
      minSize: 80,
      maxSize: 80,
      accessorFn: (data) =>
        data ? data.ticketGroup.numberOfTickets?.value ?? 0 : null,
      cell: function Cell({ getValue, row: { original } }) {
        const value = getValue() as number;
        const quantityText = useContent(ContentId.QuantityAbbreviated);
        if (!original) {
          return <TableShimmeringDiv />;
        }

        if (original.showFromPurchaseAccordions) {
          return (
            <TableCellDiv align="left">{`${quantityText}: ${value}`}</TableCellDiv>
          );
        }

        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
    },
    {
      id: 'ticketType',
      header: () => (
        <TableHeaderCellDiv align="center">
          <Content id={ContentId.Type} />
        </TableHeaderCellDiv>
      ),
      minSize: 65,
      maxSize: 65,
      accessorFn: (data) => data?.ticketGroup?.deliveryType?.value,
      cell: function Cell({ getValue, row: { original } }) {
        const delivType = getValue<DeliveryType>();
        const strDelivType = useMapDeliveryTypesToString({
          deliveryTypesObj: {
            delivType,
          },
        });

        if (!original) {
          return <TableShimmeringDiv />;
        }
        const icon = getDeliveryTypeIcon(
          delivType,
          null,
          IconsFill.currentColor
        );
        return (
          <TableCellDiv align="center" title={strDelivType}>
            <span style={{ color: vars.color.textStrong }}>{icon}</span>
          </TableCellDiv>
        );
      },
    },
    {
      id: 'cost',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Cost} />
        </TableHeaderCellDiv>
      ),
      minSize: 90,
      maxSize: 110,
      accessorFn: (data) =>
        formatCurrency(
          data?.ticketGroup?.unitCost?.value,
          data?.ticketGroup?.currencyCode?.value
        ),
      cell: function Cell({ getValue, row: { original } }) {
        const value = getValue() as string;
        const costText = useContent(ContentId.Cost);
        if (!original) {
          return <TableShimmeringDiv />;
        }

        if (original.showFromPurchaseAccordions) {
          return (
            <TableCellDiv align="left">{`${costText}: ${value}`}</TableCellDiv>
          );
        }

        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
      sortingFn: sortMoney<TicketGroupWithHandlers>,
    },
    {
      id: 'expectedValue',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.ExpectedProceeds} />
        </TableHeaderCellDiv>
      ),
      minSize: 90,
      maxSize: 110,
      accessorFn: (data) =>
        formatCurrency(
          data?.ticketGroup?.expectedValue?.value,
          data?.ticketGroup?.currencyCode?.value
        ),
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return <TableCellDiv align="right">{value}</TableCellDiv>;
      },
      sortingFn: sortMoney<TicketGroupWithHandlers>,
    },
    {
      id: 'taxPaid',
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.TaxExempt} />
        </TableHeaderCellDiv>
      ),
      minSize: 90,
      maxSize: 110,
      accessorFn: (data) => Boolean(data?.ticketGroup?.taxPaid?.value),
      cell: ({ getValue, row: { original } }) => {
        const taxPaid = getValue() as boolean;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="right">
            {taxPaid ? (
              <Content id={ContentId.No} />
            ) : (
              <Content id={ContentId.Yes} />
            )}
          </TableCellDiv>
        );
      },
    },
    {
      id: 'actions',
      enableSorting: false,
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Actions} />
        </TableHeaderCellDiv>
      ),
      minSize: 100,
      maxSize: 100,
      cell: function Cell({ row: { original } }) {
        const { setActivePosEntity } =
          useActivePosEntityContext<PurchaseOrderDetails>();
        const { setModal } = useContext(ModalContext);

        const showFromAccordions = original?.showFromPurchaseAccordions;
        const [isLoading, setIsLoading] = useState(false);

        const bulkChangeEvent = useBulkChangeEventAction(
          {
            ...EmptyPurchaseQuery,
            ticketGroupIds: [original?.ticketGroup.ticketGroupId ?? 0],
          },
          setIsLoading,
          isLoading,
          original?.ticketGroup.viagogoVirtualId,
          // Passing onSuccess to refresh the purchase if change-Event is successful
          () =>
            setActivePosEntity(
              original!.ticketGroup.purchaseOrderId!,
              undefined,
              true
            ),
          true
        );

        const { copyToClipboard } = useCopyToClipboard();
        const onAmendTickets = useCallback(
          (data?: { additionalData?: TicketGroupAdditionalData }) => {
            if (showFromAccordions) {
              setActivePosEntity(
                original!.ticketGroup.purchaseOrderId!,
                undefined,
                true
              );
              setModal(
                getPurchaseOrderDetailsModalConfigWithDeepLink(
                  combineWithTicketGroupId(
                    (original!.additionalData?.purchaseVendorOrderId ??
                      original!.ticketGroup.purchaseOrderId)!,
                    {
                      ticketGroupId: original!.ticketGroup.ticketGroupId,
                      editIntention: TicketGroupEditReason.AmendTickets,
                    }
                  )
                )
              );
            } else {
              original?.onTicketGroupAction?.({
                ticketGroupId: original!.ticketGroup.ticketGroupId,
                action: TicketGroupEditReason.AmendTickets,
                additionalData: data?.additionalData,
              });
            }
          },
          [original, setActivePosEntity, setModal, showFromAccordions]
        );

        const onCancelTickets = useCallback(() => {
          original?.onTicketGroupAction?.(
            {
              ticketGroupId: original!.ticketGroup.ticketGroupId,
              action: TicketGroupEditReason.CancelTickets,
            },
            true // showConfirmDialog
          );
        }, [original]);

        const onExchangeTickets = useCallback(() => {
          original?.onTicketGroupAction?.({
            ticketGroupId: original!.ticketGroup.ticketGroupId,
            action: TicketGroupEditReason.ExchangeTickets,
            additionalData: original.additionalData,
          });
        }, [original]);

        const onViewTicketGroup = useCallback(() => {
          setActivePosEntity(
            original!.ticketGroup.purchaseOrderId!,
            undefined,
            true
          );
          setModal(
            getPurchaseOrderDetailsModalConfigWithDeepLink(
              combineWithTicketGroupIdSimple(
                (original!.additionalData?.purchaseVendorOrderId ??
                  original!.ticketGroup.purchaseOrderId)!,
                original!.ticketGroup.ticketGroupId
              )
            )
          );
        }, [original, setActivePosEntity, setModal]);

        if (!original) {
          return <TableShimmeringDiv />;
        }

        if (!original.additionalData && !original.onTicketGroupAction) {
          return null;
        }

        return (
          <TableCellDiv align="right" showTooltip={false}>
            <IconButton
              icon={
                original.disabled ? (
                  <OpenLinkIcon size={vars.iconSize.m} withHoverEffect />
                ) : (
                  <EditIcon withHoverEffect size={vars.iconSize.m} />
                )
              }
              onClick={() => onAmendTickets()}
              titleContentId={
                !original.disabled
                  ? ContentId.AmendTickets
                  : ContentId.ViewDetails
              }
            />
            {original.ticketGroup.canGetDocArts ? (
              <LaunchDownloadUserDocuments
                ticketGroupId={original.ticketGroup.ticketGroupId}
                iconOnlyMode
              />
            ) : (
              <PredeliveryActionsDropdown
                triggerCmp={
                  <IconButton
                    icon={
                      <UploadIcon
                        withHoverEffect
                        size={vars.iconSize.m}
                        fill={IconsFill.textBrand}
                      />
                    }
                  />
                }
                ticketGroup={original.ticketGroup}
                onPredeliveryAction={(
                  uploadPredeliveryAction: UploadPredeliveryAction
                ) => {
                  onAmendTickets({
                    additionalData: {
                      amendActionData: {
                        uploadPredeliveryAction: uploadPredeliveryAction,
                      },
                    },
                  });
                }}
              />
            )}

            {!showFromAccordions && (
              <>
                <PosDropdown
                  trigger={
                    <IconButton
                      icon={
                        <MoreIcon
                          size={vars.iconSize.m}
                          fill={IconsFill.textBrand}
                          align="middle"
                        />
                      }
                    />
                  }
                  align="end"
                >
                  {!original.disabled && (
                    <>
                      <PosDropdownItem onClick={onCancelTickets}>
                        <Stack alignItems="center" gap="m">
                          <DeleteIcon
                            fill={IconsFill.textNegative}
                            size={vars.iconSize.m}
                          />
                          <Content id={ContentId.CancelTickets} />
                        </Stack>
                      </PosDropdownItem>
                      <PosDropdownItem onClick={onExchangeTickets}>
                        <Stack alignItems="center" gap="m">
                          <ProcessingIcon size={vars.iconSize.m} />
                          <Content id={ContentId.ExchangeTickets} />
                        </Stack>
                      </PosDropdownItem>

                      {bulkChangeEvent.dropDown}
                    </>
                  )}
                  {(original.additionalData?.purchaseVendorOrderId ??
                    original.ticketGroup.purchaseOrderId) && (
                    <PosDropdownItem
                      onClick={() => {
                        copyToClipboard(
                          combineWithTicketGroupIdSimple(
                            (original.additionalData?.purchaseVendorOrderId ??
                              original.ticketGroup.purchaseOrderId)!,
                            original.ticketGroup.ticketGroupId
                          )
                        );
                      }}
                    >
                      <Stack alignItems="center" gap="m">
                        <CopyIcon
                          fill={vars.color.textPrimary}
                          size={vars.iconSize.m}
                        />
                        <Content id={ContentId.CopyExternalId} />
                      </Stack>
                    </PosDropdownItem>
                  )}
                </PosDropdown>
                {bulkChangeEvent.dialog}
              </>
            )}

            {showFromAccordions && (
              <Link
                style={{
                  padding: `${vars.spacing['xxs']} 0`,
                }}
                to={getPurchaseOrderRelativeUrl(
                  combineWithTicketGroupIdSimple(
                    (original!.additionalData?.purchaseVendorOrderId ??
                      original!.ticketGroup.purchaseOrderId)!,
                    original!.ticketGroup.ticketGroupId
                  )
                )}
                onClick={(e) => {
                  if (
                    e.button === 0 &&
                    e.getModifierState('Control') === false
                  ) {
                    // Perform like a button click when it's a left-click (and no Ctrl)
                    // Else the click will be whatever <a /> does
                    e.preventDefault();
                    e.stopPropagation();

                    onViewTicketGroup();
                  }
                }}
              >
                <IconButton
                  icon={
                    <OpenLinkIcon
                      size={vars.iconSize.m}
                      fill={vars.color.textBrand}
                      withHoverEffect
                    />
                  }
                  titleContentId={ContentId.ViewDetails}
                />
              </Link>
            )}
          </TableCellDiv>
        );
      },
    },
    {
      id: 'errors',
      enableSorting: false,
      header: () => '',
      minSize: 24,
      maxSize: 24,
      cell: ({ row: { original } }) => {
        return original ? (
          <TicketGroupStatusIndicator ticketGroup={original.ticketGroup} />
        ) : null;
      },
    },
  ];
