import { Children, ComponentProps, PropsWithChildren } from 'react';
import { isContentId } from 'src/contexts/ContentContext';
import { Tooltip } from 'src/core/ui';

/**
 * Gets text value - only works for simple text elements
 * @param element
 * @returns
 */
const getTextValueFromSingleChild = (
  element: React.ReactNode
): string | undefined => {
  if (element == null) {
    return undefined;
  }

  if (typeof element === 'string' || typeof element === 'number') {
    return element.toString();
  }

  const childrenArray = Children.toArray(element);
  const isSingleElement = childrenArray.length === 1;
  if (isSingleElement) {
    const childNode = (childrenArray[0] as React.ReactElement)?.props
      ?.children as React.ReactNode;

    if (childNode) {
      return getTextValueFromSingleChild(childNode);
    }
  }

  return undefined;
};

/**
 * Gets content Id recursively from single child
 * @param element
 * @returns
 */
const getContentIdFromSingleChild = (
  element: React.ReactNode
): string | undefined => {
  if (element == null) {
    return undefined;
  }

  if (typeof element === 'string' && isContentId(element)) {
    return element;
  }

  const propsId = (element as React.ReactElement).props?.id;
  if (propsId != null && isContentId(propsId)) {
    return propsId;
  }

  const childrenArray = Children.toArray(element);
  const isSingleElement = childrenArray.length === 1;
  if (isSingleElement) {
    const childNode = (childrenArray[0] as React.ReactElement)?.props
      ?.children as React.ReactNode;

    if (childNode) {
      return getContentIdFromSingleChild(childNode);
    }
  }

  return undefined;
};

export const DivWithTitle = ({
  children,
  showTooltip = true,
  align = 'right',
  title,
  ...props
}: PropsWithChildren<ComponentProps<'div'>> & {
  showTooltip?: boolean;
  align?: 'center' | 'left' | 'right' | undefined;
}) => {
  const content = <div {...props}>{children}</div>;

  const contentUnwrapped = (
    <div
      {...props}
      style={{
        ...props.style,
        whiteSpace: 'nowrap',
        overflow: 'unset',
        textOverflow: 'unset',
        minWidth: 'fit-content',
      }}
    >
      {children}
    </div>
  );

  if (showTooltip) {
    const mappedAligned =
      align === 'left' ? 'start' : align === 'right' ? 'end' : 'center';
    return (
      <Tooltip align={mappedAligned} triggerContent={content}>
        {title || contentUnwrapped}
      </Tooltip>
    );
  }

  return <>{content}</>;
};
