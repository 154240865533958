import clsx from 'clsx';
import { useMatches, useNavigate, useParams } from 'react-router-dom';
import { Components, Virtuoso } from 'react-virtuoso';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { PurchaseEmailDisplay } from 'src/components/Purchases/PurchaseEmailDisplay';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { usePartialPurchaseOrderInboundEmails } from 'src/hooks/api/usePartialPurchaseOrderInboundEmails';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import { SomethingWentWrong } from 'src/views';
import {
  ApiException,
  AutoPoInboundEmailPartialPurchaseOrderQuery,
} from 'src/WebApiController';
import { useSwiper } from 'swiper/react';

import IconBulletList from '~icons/pos/bulletList';

import * as styles from './EmailList.css';

type VirtuosoContext = {
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  isFetchingNextPage: boolean;
};

const Header: Components<unknown, VirtuosoContext>['Header'] = () => {
  const { inboundEmailId, sellerSupportCaseId } = useParams();

  const isLargeDesktop = useMatchMedia('largeDesktop');

  return (
    <div className={styles.emailListHeader}>
      {!isLargeDesktop && (inboundEmailId || sellerSupportCaseId) && (
        <div style={{ padding: vars.spacing['sm'] }}>
          <SwiperButton dir="right" />
        </div>
      )}
    </div>
  );
};

const EmptyPlaceholder: Components<
  unknown,
  VirtuosoContext
>['EmptyPlaceholder'] = ({ context }: { context?: VirtuosoContext }) => {
  if (context?.isLoading) {
    return <PosSpinner />;
  }
  if (context?.isError) {
    const errorInfo = getErrorInfoFromStatusCode(
      (context?.error as ApiException)?.status
    );
    return (
      <SomethingWentWrong
        header={errorInfo.headerDisplay}
        message={<Content id={ContentId.FailToLoadListContent} />}
      />
    );
  }
  return (
    <EmptySectionContent.Root icon={<IconBulletList />}>
      <Content id={ContentId.NoResultFound} />
    </EmptySectionContent.Root>
  );
};

const Footer: Components<unknown, VirtuosoContext>['Footer'] = ({
  context,
}: {
  context?: VirtuosoContext;
}) => {
  if (context?.isFetchingNextPage) {
    return <PosSpinner />;
  }
  // XXX hack to prevent margin collapse on last element
  return <div style={{ paddingTop: '0.05px' }} />;
};

export function EmailList({
  classifiedInboundEmailIds,
  query,
}: {
  classifiedInboundEmailIds: Set<number>;
  query: AutoPoInboundEmailPartialPurchaseOrderQuery;
}) {
  const swiper = useSwiper();
  const isLargeDesktop = useMatchMedia('largeDesktop');

  const { inboundEmailId: activeInboundEmailId } = useParams();
  const navigate = useNavigate();
  const matches = useMatches();

  const {
    flattenedData,
    isLoading,
    isError,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = usePartialPurchaseOrderInboundEmails({ query });

  return (
    <div className={styles.emailListContainer}>
      <Virtuoso
        data={
          query.includeUnclassified && !query.classificationTypes
            ? flattenedData.filter(
                ({ inboundEmailId }) =>
                  !classifiedInboundEmailIds.has(inboundEmailId)
              )
            : flattenedData
        }
        context={{
          isLoading: isLoading,
          isError,
          error,
          isFetchingNextPage,
        }}
        components={{ Footer, Header, EmptyPlaceholder }}
        endReached={() => !isFetching && hasNextPage && fetchNextPage()}
        itemContent={(_, partialPurchaseOrderInboundEmail) => {
          const {
            inboundEmailId,
            subject,
            toEmailAddress,
            fromEmailAddress,
            createDate,
            orderId,
            quantity,
            purchaseOrderDate,
            faceValue,
            purchaseOrderVendorId,
          } = partialPurchaseOrderInboundEmail;
          return (
            <div
              className={clsx(styles.emailItemContainer, {
                [styles.emailItemContainerActive]:
                  activeInboundEmailId &&
                  inboundEmailId === Number(activeInboundEmailId),
              })}
              onClick={() => {
                if (!isLargeDesktop) {
                  swiper?.slideNext();
                }

                const parentMatch = matches.at(-2);
                navigate({
                  pathname: `${parentMatch?.pathname}/${inboundEmailId}`,
                  search: window.location.search,
                });
              }}
            >
              <div className={styles.emailDisplayWrapper}>
                <PurchaseEmailDisplay
                  vendorId={purchaseOrderVendorId}
                  subject={subject}
                  toEmailAddress={toEmailAddress}
                  fromEmailAddress={fromEmailAddress}
                  emailDate={new Date(createDate)} // TODO POS-1778 this should be receipt date instead
                  orderId={orderId ? `#${orderId}` : undefined}
                  order={
                    quantity ? (
                      <FormatContent
                        id={FormatContentId.TicketCount}
                        params={quantity.toLocaleString()}
                      />
                    ) : undefined
                  }
                  orderDate={
                    purchaseOrderDate ? new Date(purchaseOrderDate) : undefined
                  }
                  // TODO add currency for faceValue
                  faceValue={faceValue}
                />
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
