import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PurchaseClient } from 'src/WebApiController';

export const useDeliveryOverrideForNewTicketGroup = (
  eventId?: number | null,
  vendorId?: number | null,
  vendorAccountId?: string | null,
  isManual?: boolean,
  disabled?: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const getQueryKey = [
    'PurchaseClient.getDeliveryOverrideForNewTicketGroup',
    eventId,
  ];

  const shouldQuery =
    !disabled && Boolean(activeAccountWebClientConfig.activeAccountId);

  const getQuery = useQuery({
    queryKey: getQueryKey,
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      return await new PurchaseClient(
        activeAccountWebClientConfig
      ).getDeliveryOverrideForNewTicketGroup({
        item1: eventId ?? null,
        item2: vendorId ?? null,
        item3: vendorAccountId ?? null,
        item4: isManual ?? false,
      });
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'PurchaseClient.getDeliveryOverrideForNewTicketGroup',
          error,
          {
            eventId,
            vendorId,
          }
        );
      },
    },
  });

  return {
    deliveryTypeDefault: getQuery.data?.delivType,
    ticketTypeRulesDefault: getQuery.data?.tktTypeRules,
    inHandAtDefault: getQuery.data?.inHandAt,
    isLoading: getQuery.isLoading,
    hasDefaultValues: !!getQuery.data,
  };
};
