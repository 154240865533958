import { vars } from 'src/core/themes';
import { ArrowDownIcon, ArrowUpIcon } from 'src/svgs/Viagogo';

import { SortIconContainer } from './SortButton.styled';

export enum SortStatus {
  'UNSORTED',
  'ASC',
  'DESC',
}

export enum ArrowType {
  'UP',
  'DOWN',
}

type GetIconClass = (
  arrow: ArrowType,
  disabled: boolean,
  status: SortStatus
) => string;
const getIconClass: GetIconClass = (arrow, disabled, status) => {
  if (!disabled) {
    // If asc order, up arrow is active state
    if (status === SortStatus.ASC && arrow === ArrowType.UP) {
      return 'sort-active';
    }

    // If desc order, down arrow is active state
    if (status === SortStatus.DESC && arrow === ArrowType.DOWN) {
      return 'sort-active';
    }
  }

  return 'sort-inactive';
};

function getNextSortStatus(
  status: SortStatus,
  allowUnsorted: boolean
): SortStatus {
  switch (status) {
    case SortStatus.UNSORTED:
      return SortStatus.ASC;
    case SortStatus.ASC:
      return SortStatus.DESC;
    case SortStatus.DESC:
    default: {
      if (allowUnsorted) {
        return SortStatus.UNSORTED;
      }

      return SortStatus.ASC;
    }
  }
}

export type SortButtonProps = {
  sortStatus: SortStatus;
  disabled?: boolean;
  onClick?: (status: SortStatus) => void;
  allowUnsorted?: boolean;
};

export function SortButton({
  onClick,
  sortStatus,
  disabled = false,
  allowUnsorted = true,
}: SortButtonProps) {
  const upArrowClass = getIconClass(ArrowType.UP, disabled, sortStatus);
  const downArrowClass = getIconClass(ArrowType.DOWN, disabled, sortStatus);

  return (
    <SortIconContainer
      onClick={() => {
        !disabled && onClick?.(getNextSortStatus(sortStatus, allowUnsorted));
      }}
    >
      <ArrowUpIcon
        size={vars.iconSize.xs}
        withHoverEffect
        className={upArrowClass}
      />
      <ArrowDownIcon
        size={vars.iconSize.xs}
        withHoverEffect
        className={downArrowClass}
      />
    </SortIconContainer>
  );
}
