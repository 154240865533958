import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { useSellerRoleContext } from 'src/contexts/SellerRoleContext';
import { ErrorMessage } from 'src/core/POS/ErrorMessage';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { PosTextArea } from 'src/core/POS/PosTextArea';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { TabPanel } from 'src/core/ui/TabPanel';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  PermissionAccessArea,
  SellerRoleInfo,
  SellerRoleInput,
} from 'src/WebApiController';

import * as styles from '../SellerRoleModal.css';
import { MAX_ROLE_DETAILS_LENGTH } from '../SellerRoleModal.types';
import { SellerPermissionSelectionSection } from './SellerPermissionSelectionSection';
import { SellerRoleUsersSection } from './SellerRoleUsersSection';

export const SellerRoleDetails = ({
  roleInfo,
  disabled,
}: {
  roleInfo?: SellerRoleInfo | null;
  disabled?: boolean;
}) => {
  const { register, formState, watch, setValue } =
    useFormContext<SellerRoleInput>();
  const requiredTxt = useContent(ContentId.Required);

  const [selectedRoleTemplate, setSelectedRoleTemplate] = useState<
    number | undefined
  >(undefined);

  const { allPermissions, allSellerRolesForSelection, getSellerRole } =
    useSellerRoleContext();

  const nameError = formState.errors.name?.message;
  const detailsError = formState.errors.details?.message;
  const details = watch('details');

  const onRoleTemplateSelected = useCallback(
    (role: string) => {
      const roleId = parseInt(role);
      if (roleId) {
        const roleInfo = getSellerRole(roleId);

        if (roleInfo) {
          const copyPermissions = roleInfo.permissions.filter(
            (p) => allPermissions?.[p]
          );

          // Only copy over the visible
          if (copyPermissions.length) {
            setSelectedRoleTemplate(roleId);
            setValue('permissions', copyPermissions);
          }
        }
      }
    },
    [allPermissions, getSellerRole, setValue]
  );

  const [tabIndex, setTabIndex] = useState(0);
  const tabs = useMemo(
    () => [
      {
        value: ContentId.Permissions,
        title: <Content id={ContentId.Permissions} />,
        content: (
          <PermissionsTabContent
            disabled={disabled || Boolean(roleInfo?.prefinedRole)}
          />
        ),
      },
      {
        value: ContentId.Access,
        title: <Content id={ContentId.Access} />,
        content: (
          <Stack
            direction="column"
            gap="l"
            className={styles.permissionsContainer}
          >
            <SellerRoleUsersSection roleInfo={roleInfo} />
          </Stack>
        ),
      },
    ],
    // careful adding dependency here - causing this to re-calculate will cause a re-render
    // and it can cause losing focus to inputs on every key stroke, etc
    [disabled, roleInfo]
  );

  return (
    <Stack direction="column" width="full" gap="l" height="full">
      <PosFormField
        label={<Content id={ContentId.TitleName} />}
        errors={nameError}
      >
        <PosTextField
          rootProps={{
            state: getTextFieldState(formState.errors.name),
          }}
          disabled={disabled || Boolean(roleInfo?.prefinedRole)}
          {...register('name', { required: requiredTxt })}
        />
      </PosFormField>
      <Stack direction="column" width="full" gap="m">
        <PosFormField
          label={<Content id={ContentId.DescriptionOfPurpose} />}
          errors={detailsError}
        >
          <PosTextArea
            rootProps={{
              state: getTextFieldState(formState.errors.details),
            }}
            maxLength={MAX_ROLE_DETAILS_LENGTH}
            disabled={disabled || Boolean(roleInfo?.prefinedRole)}
            {...register('details', { required: requiredTxt })}
          />
        </PosFormField>
        <span className={styles.characterCountSpan}>
          <FormatContent
            id={FormatContentId.NCharactersRemaining}
            params={`${MAX_ROLE_DETAILS_LENGTH - (details?.length ?? 0)}`}
          />
        </span>
      </Stack>

      {!roleInfo?.prefinedRole && allSellerRolesForSelection && (
        // We only show this if it's not a predefined Role
        <Stack
          gap="l"
          flexWrap="wrap"
          width="full"
          justifyContent="spaceBetween"
          alignItems="center"
        >
          <Stack direction="column">
            <span className={styles.label}>
              <Content id={ContentId.UseExistingRoleAsTemplateTitle} />
            </span>
            <span className={styles.labelExplanation}>
              <Content id={ContentId.UseExistingRoleAsTemplateExplanation} />
            </span>
          </Stack>
          <PosSelect
            style={{ flex: 1 }}
            disabled={disabled}
            value={selectedRoleTemplate?.toString()}
            valueOptionsContent={allSellerRolesForSelection}
            onChange={onRoleTemplateSelected}
            enableEmptySelection
            placeholderText={ContentId.SelectTemplate}
          />
        </Stack>
      )}

      <TabPanel
        tabs={tabs}
        activeTabIndex={tabIndex}
        onValueChange={(value: string) => {
          switch (value) {
            case ContentId.Access:
              setTabIndex(1);
              break;
            case ContentId.Permissions:
            default:
              setTabIndex(0);
              break;
          }
        }}
      />
    </Stack>
  );
};

const PermissionsTabContent = ({ disabled }: { disabled?: boolean }) => {
  const { getFieldState } = useFormContext<SellerRoleInput>();
  const permissionErrors = getFieldState('permissions').error?.message;

  const ref = useRef<HTMLDivElement>(null);

  // Make sure the error focuses when it becomes visible
  useEffect(() => {
    if (permissionErrors) {
      ref.current?.focus();
    }
  }, [permissionErrors]);

  return (
    <Stack direction="column" gap="l" className={styles.permissionsContainer}>
      {permissionErrors && (
        <ErrorMessage tabIndex={0} ref={ref}>
          {permissionErrors}
        </ErrorMessage>
      )}

      {Object.keys(PermissionAccessArea).map((area) => (
        <SellerPermissionSelectionSection
          key={area}
          area={area as PermissionAccessArea}
          disabled={disabled}
        />
      ))}
    </Stack>
  );
};
