import {
  getPresetFromUiDateTimeRange,
  getUiDateTimeRangeFromPreset,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import { DateTimeRange, MarketplacePaymentQuery } from 'src/WebApiController';

export const marketplacePaymentQueryValueTransformToUrl = (
  key: string,
  value: DateTimeRange
) => {
  if (!value) {
    return value;
  }

  const queryKey = key as keyof MarketplacePaymentQuery;

  if (queryKey === 'paymentDates') {
    const preset = getPresetFromUiDateTimeRange(value);
    if (preset && StandardDateRangePresetNames.includes(preset.name)) {
      return preset.name;
    }
  }

  return value;
};

export const marketplacePaymentValueTransformFromUrl = (
  key: string,
  value: string
) => {
  if (!value) {
    return value;
  }

  const queryKey = key as keyof MarketplacePaymentQuery;
  if (queryKey === 'paymentDates') {
    const preset = getUiDateTimeRangeFromPreset(
      value,
      StandardDateRangePresetNames
    );
    if (preset) {
      return preset;
    }
  }

  return value;
};
