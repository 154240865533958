import { useBulkBroadcastActionsPermission } from 'src/components/common/BulkActions/BulkBroadcastActions/useBulkBroadcastActionsPermission';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export const LaunchRemoveHold = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const { hasSellerAccountAdminPermission } =
    useBulkBroadcastActionsPermission();

  if (!hasSellerAccountAdminPermission) {
    return null;
  }

  return (
    <>
      <PosDropdownItem key="removeHold" onClick={onClick} disabled={disabled}>
        <Stack alignItems="center" gap="m" width="full">
          <Content id={ContentId.RemoveHold} />
        </Stack>
      </PosDropdownItem>
    </>
  );
};
