import { useMemo } from 'react';
import {
  PosMultiSelect,
  PosMultiSelectProps,
} from 'src/core/POS/PosMultiSelect';
import { useKnownCountries } from 'src/hooks/api/useKnownCountries';
import { ContentId } from 'src/utils/constants/contentId';

export const CountryMultiSelector = ({
  placeholderText,
  ...props
}: Omit<PosMultiSelectProps, 'valueOptionsContent'>) => {
  const knownCountriesQuery = useKnownCountries();

  const knownCountriesOptions = useMemo(() => {
    if (knownCountriesQuery.data == null) {
      return {};
    }

    return Object.entries(knownCountriesQuery.data).reduce(
      (r, [key, value]) => {
        r[key] = value;
        return r;
      },
      {} as Record<string, string>
    );
  }, [knownCountriesQuery.data]);

  return (
    <PosMultiSelect
      {...props}
      loading={knownCountriesQuery.data == null}
      placeholderText={placeholderText ?? ContentId.SelectCountry}
      valueOptionsContent={knownCountriesOptions}
      showSelectedOptionsFirst
      showSelectAllOption={true}
    />
  );
};
