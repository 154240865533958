import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function LoadingIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M4.929 4.686l1.768 1.768A7.477 7.477 0 004.5 11.757c0 2.072.84 3.947 2.198 5.305L4.93 18.829A9.969 9.969 0 012 11.757a9.969 9.969 0 012.929-7.07zM22 11.757a9.969 9.969 0 01-2.929 7.071l-1.768-1.767a7.477 7.477 0 002.197-5.304c0-2.07-.84-3.945-2.196-5.302l1.768-1.768A9.969 9.969 0 0122 11.757z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
