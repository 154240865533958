import { FormProvider, useForm } from 'react-hook-form';
import { getInHandDateBasedOnEvent } from 'src/utils/eventWithDataUtils';
import { DeliveryType } from 'src/WebApiController';

import {
  SeatSaverListingDetailsInputForm,
  SeatSaverListingWizardProps,
} from './SeatSaverListingWizard.types';
import { SeatSaverListingWizardBody } from './SeatSaverListingWizardBody';

export const SeatSaverListingWizard = ({
  cancelTo,
  events,
  ...rest
}: SeatSaverListingWizardProps) => {
  const methods = useForm<SeatSaverListingDetailsInputForm>({
    defaultValues: {
      inputs:
        events?.map((ev) => ({
          event: ev,
          autoBroadcastCreatedListing: false,
          seatings: [],
          quantity: 0,
          unitNetProceeds: 0,
          websitePrice: 0,
          faceValue: null,
          deliveryType: DeliveryType.InApp,
          inHandDate: getInHandDateBasedOnEvent(ev, 2),
          daysBeforeEvent: 2,
          sellerOwnTickets: true,
        })) ?? [],
    },
  });

  return (
    <FormProvider {...methods}>
      <SeatSaverListingWizardBody
        {...methods}
        events={events}
        cancelTo={cancelTo}
        {...rest}
      />
    </FormProvider>
  );
};
