import { ReportGroupBy } from 'src/WebApiController';

import { ColumnPersonalizationFlags } from '../columnUtils.types';
import { SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from './salesColumnUtils.constants';
import {
  SalesReportTableColumnId,
  SalesTableColumnId,
} from './salesColumnUtils.types';

export const SALE_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES: Record<
  SalesReportTableColumnId,
  Partial<ColumnPersonalizationFlags>
> = {
  // These can be primary columns for grouping. The flag 'isRequired' will be determined at run time

  [SalesReportTableColumnId.SaleMonth]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.SaleWeek]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.SaleDay]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.Performer]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.Venue]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.EventGenre]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.EventCity]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.Event]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.EventCountry]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.SeatingSection]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.SeatingRow]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.SaleMarketplace]: { isHiddenByDefault: true },
  [SalesReportTableColumnId.MarketplacePaymentTerms]: {
    isHiddenByDefault: true,
  },

  [SalesReportTableColumnId.QuantitySold]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.QuantitySold
    ],
    isHiddenByDefault: true,
    isRequired: false,
  },
  [SalesReportTableColumnId.Proceeds]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.Proceeds
    ],
    isHiddenByDefault: true,
    isRequired: false,
  },
  [SalesReportTableColumnId.SoldTicketCost]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.SoldTicketCost
    ],
  },
  [SalesReportTableColumnId.Charges]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.Charges
    ],
  },
  [SalesReportTableColumnId.Credits]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.Credits
    ],
  },

  [SalesReportTableColumnId.EventId]: {
    // This can be a number but shoudln't be treated as a number (precision etc.)
    isNumeric: false,
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.Category]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.EventDate]: {
    isDateTime: true,
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.EventStateProvince]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.SaleId]: {
    // This can be a number but shoudln't be treated as a number (precision etc.)
    isNumeric: false,
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.MarketplaceSaleId]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.Status]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.Status
    ],
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.TicketType]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.TicketType
    ],
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.InHandDate]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.InHandDate
    ],
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.Fulfiller]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.PurchaseId]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.PurchasedBy]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.PurchaseDate]: {
    isHiddenByDefault: true,
    isDateTime: true,
  },
  [SalesReportTableColumnId.Vendor]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.SeatingSeats]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.PricedBy]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.OrderDate]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.OrderDate
    ],
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.CurrencyCode]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.PaymentDate]: {
    isDateTime: true,
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.VendorAccountEmail]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.VendorAccountEmail
    ],
  },
  [SalesReportTableColumnId.InternalNotes]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.CancellationDate]: {
    isDateTime: true,
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.OutstandingBalance]: {
    isHiddenByDefault: true,
    isNumeric: true,
    isCurrency: true,
  },
  [SalesReportTableColumnId.PandL]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.PandL
    ],
  },
  [SalesReportTableColumnId.Margin]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.Margin
    ],
  },
  [SalesReportTableColumnId.PaymentState]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.PaymentState
    ],
    requiredFeatures: [],
  },
  [SalesReportTableColumnId.PaymentReceived]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.PaymentReceived
    ],
  },
  [SalesReportTableColumnId.PaymentReferenceId]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.PaymentReferenceId
    ],
  },
  [SalesReportTableColumnId.FulfillmentDate]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.FulfillmentDate
    ],
    requiredFeatures: [],
  },
  [SalesReportTableColumnId.PurchaseCreatedBy]: {
    ...SALE_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[
      SalesTableColumnId.PurchaseCreatedBy
    ],
  },
  [SalesReportTableColumnId.TicketId]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.TicketGroupId]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.CommissionUser]: {
    isHiddenByDefault: true,
  },
  [SalesReportTableColumnId.CommissionPercent]: {
    isHiddenByDefault: true,
    isPercent: true,
  },
  [SalesReportTableColumnId.CommissionShare]: {
    isHiddenByDefault: true,
    isCurrency: true,
  },
} as const;

/**
 * These columns must show up when a group by is applied
 */
export const GROUP_BY_TO_PRIMARY_COLUMN_ID: Record<
  string,
  SalesReportTableColumnId
> = {
  [ReportGroupBy.Performer]: SalesReportTableColumnId.Performer,
  [ReportGroupBy.Venue]: SalesReportTableColumnId.Venue,
  [ReportGroupBy.Event]: SalesReportTableColumnId.Event,
  [ReportGroupBy.EventGenre]: SalesReportTableColumnId.EventGenre,
  [ReportGroupBy.EventCountry]: SalesReportTableColumnId.EventCountry,
  [ReportGroupBy.EventCity]: SalesReportTableColumnId.EventCity,
  [ReportGroupBy.SeatingSection]: SalesReportTableColumnId.SeatingSection,
  [ReportGroupBy.SeatingRow]: SalesReportTableColumnId.SeatingRow,
  [ReportGroupBy.SaleMarketplace]: SalesReportTableColumnId.SaleMarketplace,
  [ReportGroupBy.SaleMonth]: SalesReportTableColumnId.SaleMonth,
  [ReportGroupBy.SaleWeek]: SalesReportTableColumnId.SaleWeek,
  [ReportGroupBy.SaleDay]: SalesReportTableColumnId.SaleDay,
  [ReportGroupBy.SaleId]: SalesReportTableColumnId.SaleId,
  [ReportGroupBy.PricedBy]: SalesReportTableColumnId.PricedBy,
  [ReportGroupBy.Vendor]: SalesReportTableColumnId.Vendor,
  [ReportGroupBy.PurchasedBy]: SalesReportTableColumnId.PurchasedBy,
  [ReportGroupBy.FulfilledBy]: SalesReportTableColumnId.Fulfiller,
  [ReportGroupBy.SaleIdTicketIdCommissionUser]: SalesReportTableColumnId.SaleId,
};

export const NON_METRIC_SALES_REPORT_TABLE_COLUMN_IDS: SalesReportTableColumnId[] =
  [
    SalesReportTableColumnId.Event,
    SalesReportTableColumnId.EventGenre,
    SalesReportTableColumnId.EventCountry,
    SalesReportTableColumnId.EventCity,
    SalesReportTableColumnId.SeatingSection,
    SalesReportTableColumnId.SeatingRow,
    SalesReportTableColumnId.Performer,
    SalesReportTableColumnId.Venue,
    SalesReportTableColumnId.EventId,
    SalesReportTableColumnId.Category,
    SalesReportTableColumnId.EventDate,
    SalesReportTableColumnId.EventStateProvince,
    SalesReportTableColumnId.SaleMarketplace,
    SalesReportTableColumnId.SaleMonth,
    SalesReportTableColumnId.SaleWeek,
    SalesReportTableColumnId.SaleDay,
    SalesReportTableColumnId.SaleId,
    SalesReportTableColumnId.MarketplaceSaleId,
    SalesReportTableColumnId.Status,
    SalesReportTableColumnId.TicketType,
    SalesReportTableColumnId.InHandDate,
    SalesReportTableColumnId.PaymentState,
    SalesReportTableColumnId.PricedBy,
    SalesReportTableColumnId.Fulfiller,
    SalesReportTableColumnId.PurchaseId,
    SalesReportTableColumnId.PurchasedBy,
    SalesReportTableColumnId.PurchaseDate,
    SalesReportTableColumnId.Vendor,
    SalesReportTableColumnId.SeatingSeats,
    SalesReportTableColumnId.OrderDate,
    SalesReportTableColumnId.CurrencyCode,
    SalesReportTableColumnId.PaymentDate,
    SalesReportTableColumnId.FulfillmentDate,
    SalesReportTableColumnId.VendorAccountEmail,
    SalesReportTableColumnId.InternalNotes,
    SalesReportTableColumnId.CancellationDate,
    SalesReportTableColumnId.PurchaseCreatedBy,
    SalesReportTableColumnId.TicketId,
    SalesReportTableColumnId.TicketGroupId,
    SalesReportTableColumnId.CommissionUser,
    SalesReportTableColumnId.CommissionPercent,
    SalesReportTableColumnId.CommissionShare,
    SalesReportTableColumnId.MarketplacePaymentTerms,
  ];

/**
 * These columns from NON_METRIC_SALES_REPORT_TABLE_COLUMN_IDS
 * can only show up when a group by is applied (but GROUP_BY_TO_PRIMARY_COLUMN_ID takes precedence)
 */
export const GROUP_BY_TO_VISIBLE_COLUMN_IDS: Record<
  string,
  SalesReportTableColumnId[]
> = {
  [ReportGroupBy.Event]: [
    SalesReportTableColumnId.Performer,
    SalesReportTableColumnId.Venue,
    SalesReportTableColumnId.EventGenre,
    SalesReportTableColumnId.EventCity,
    SalesReportTableColumnId.EventId,
    SalesReportTableColumnId.Category,
    SalesReportTableColumnId.EventDate,
    SalesReportTableColumnId.EventStateProvince,
  ],
  [ReportGroupBy.Performer]: [SalesReportTableColumnId.Category],
  [ReportGroupBy.ArAgingMarketplace]: [
    SalesReportTableColumnId.SaleMarketplace,
    SalesReportTableColumnId.MarketplacePaymentTerms,
  ],
  [ReportGroupBy.SeatingRow]: [SalesReportTableColumnId.SeatingSection],
  [ReportGroupBy.SaleId]: [
    SalesReportTableColumnId.Event,
    SalesReportTableColumnId.EventGenre,
    SalesReportTableColumnId.EventCountry,
    SalesReportTableColumnId.EventCity,
    SalesReportTableColumnId.SeatingSection,
    SalesReportTableColumnId.SeatingRow,
    SalesReportTableColumnId.Performer,
    SalesReportTableColumnId.Venue,
    SalesReportTableColumnId.EventId,
    SalesReportTableColumnId.Category,
    SalesReportTableColumnId.EventDate,
    SalesReportTableColumnId.EventStateProvince,
    SalesReportTableColumnId.SaleMarketplace,
    SalesReportTableColumnId.OrderDate,
    SalesReportTableColumnId.SaleId,
    SalesReportTableColumnId.MarketplaceSaleId,
    SalesReportTableColumnId.Status,
    SalesReportTableColumnId.TicketType,
    SalesReportTableColumnId.InHandDate,
    SalesReportTableColumnId.PaymentState,
    SalesReportTableColumnId.Fulfiller,
    SalesReportTableColumnId.PurchaseId,
    SalesReportTableColumnId.PurchasedBy,
    SalesReportTableColumnId.Vendor,
    SalesReportTableColumnId.SeatingSeats,
    SalesReportTableColumnId.CurrencyCode,
    SalesReportTableColumnId.PurchaseDate,
    SalesReportTableColumnId.PaymentDate,
    SalesReportTableColumnId.FulfillmentDate,
    SalesReportTableColumnId.VendorAccountEmail,
    SalesReportTableColumnId.InternalNotes,
    SalesReportTableColumnId.CancellationDate,
    SalesReportTableColumnId.PurchaseCreatedBy,
  ],
  [ReportGroupBy.SaleIdTicketIdCommissionUser]: [
    SalesReportTableColumnId.Event,
    SalesReportTableColumnId.EventGenre,
    SalesReportTableColumnId.EventCountry,
    SalesReportTableColumnId.EventCity,
    SalesReportTableColumnId.SeatingSection,
    SalesReportTableColumnId.SeatingRow,
    SalesReportTableColumnId.Performer,
    SalesReportTableColumnId.Venue,
    SalesReportTableColumnId.EventId,
    SalesReportTableColumnId.Category,
    SalesReportTableColumnId.EventDate,
    SalesReportTableColumnId.EventStateProvince,
    SalesReportTableColumnId.SaleMarketplace,
    SalesReportTableColumnId.OrderDate,
    SalesReportTableColumnId.SaleId,
    SalesReportTableColumnId.MarketplaceSaleId,
    SalesReportTableColumnId.Status,
    SalesReportTableColumnId.TicketType,
    SalesReportTableColumnId.InHandDate,
    SalesReportTableColumnId.PaymentState,
    SalesReportTableColumnId.Fulfiller,
    SalesReportTableColumnId.PurchaseId,
    SalesReportTableColumnId.PurchasedBy,
    SalesReportTableColumnId.PurchaseDate,
    SalesReportTableColumnId.Vendor,
    SalesReportTableColumnId.SeatingSeats,
    SalesReportTableColumnId.CurrencyCode,
    SalesReportTableColumnId.PaymentDate,
    SalesReportTableColumnId.FulfillmentDate,
    SalesReportTableColumnId.VendorAccountEmail,
    SalesReportTableColumnId.InternalNotes,
    SalesReportTableColumnId.CancellationDate,
    SalesReportTableColumnId.PurchaseCreatedBy,
    SalesReportTableColumnId.TicketId,
    SalesReportTableColumnId.TicketGroupId,
    SalesReportTableColumnId.CommissionUser,
    SalesReportTableColumnId.CommissionPercent,
    SalesReportTableColumnId.CommissionShare,
  ],
};
