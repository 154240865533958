import { ComponentProps, ReactElement, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { Content, useContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import {
  FieldError,
  FieldLabel,
  FieldWrapper,
} from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { PAYMENT_METHOD_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { PurchasePaymentMethod } from 'src/WebApiController';

export type PurchasePaymentMethodDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onSave?: (data: PurchasePaymentMethod) => void | Promise<void>;
  onCancel?: () => void;
  paymentMethod: PurchasePaymentMethod | undefined;
};

export function PurchasePaymentMethodDialog({
  onSave,
  onCancel,
  paymentMethod,
  ...genericDialogProps
}: PurchasePaymentMethodDialogProps): ReactElement | null {
  const requiredText = useContent(ContentId.Required);

  const {
    control,
    register,
    reset,
    handleSubmit,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<PurchasePaymentMethod>({
    defaultValues: paymentMethod,
  });

  const { id } = getValues();

  useEffect(() => {
    reset(paymentMethod);
  }, [paymentMethod, reset]);

  return (
    <GenericDialog
      size="md"
      header={
        <Content
          id={
            id <= 0 ? ContentId.AddPurchaseVendor : ContentId.EditPurchaseVendor
          }
        />
      }
      footer={
        <>
          <CancelButton disabled={isSubmitting} onClick={onCancel} />
          <Button
            variant={'regular'}
            disabled={isSubmitting}
            onClick={onSave ? handleSubmit(onSave) : undefined}
          >
            <Content id={ContentId.Save} />
          </Button>
        </>
      }
      onClosed={() => {
        reset();
      }}
      {...genericDialogProps}
      onCancel={onCancel}
    >
      <FieldWrapper>
        <FieldLabel>
          <Content id={ContentId.Type} />
        </FieldLabel>
        <Controller
          control={control}
          name="type"
          rules={{ required: requiredText }}
          render={({ field: { value, ...field } }) => (
            <PosSelect
              placeholderText={ContentId.ChoosePaymentMethodTypePlaceholder}
              valueOptionsContent={PAYMENT_METHOD_TYPE_TO_CID}
              value={value}
              disabled={isSubmitting}
              hasErrors={Boolean(errors.type)}
              {...field}
            />
          )}
        />
        {errors.type && <FieldError>{errors.type?.message}</FieldError>}
      </FieldWrapper>
      <FieldWrapper>
        <PosFormField
          errors={errors.name?.message}
          label={<Content id={ContentId.Name} />}
        >
          <PosTextField
            rootProps={{
              disabled: isSubmitting,
              state: getTextFieldState(errors.name),
            }}
            disabled={isSubmitting}
            {...register('name', {
              required: true,
            })}
          />
        </PosFormField>
      </FieldWrapper>
      <FieldWrapper>
        <PosFormField
          errors={errors.display?.message}
          label={<Content id={ContentId.PaymentAccount} />}
        >
          <PosTextField
            rootProps={{
              disabled: isSubmitting,
              state: getTextFieldState(errors.display),
            }}
            spellCheck={false}
            disabled={isSubmitting}
            {...register('display', {
              required: true,
            })}
          />
        </PosFormField>
      </FieldWrapper>
    </GenericDialog>
  );
}
