import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { UK_COUNTRY_CODES } from 'src/utils/constants/constants';
import { getIsInternationalEvent } from 'src/utils/eventWithDataUtils';
import { isMarketplaceActive } from 'src/utils/userUtils';
import {
  Event,
  ListingMarketplaceSetting,
  Marketplace,
} from 'src/WebApiController';

import { useGetEventFullInfo } from './useGetEventFullInfo';

/**
 * Quick hook to provide grouping marketplace settings base off an event
 */
export const useEventMarketplaceSettings = (event?: Event) => {
  const { loginContext } = useAppContext();

  const { venue } = useGetEventFullInfo(event);
  const isUKEvent = Boolean(
    event != null &&
      venue?.country?.code &&
      UK_COUNTRY_CODES.includes(venue.country.code.toUpperCase())
  );
  const isInternationalEvent = getIsInternationalEvent(venue?.country?.code);

  const marketplaceSettings = useMemo(
    () => loginContext?.user?.activeAccount?.marketplaceSettings,
    [loginContext?.user?.activeAccount?.marketplaceSettings]
  );

  const eventMarketplaceSettings = useMemo(
    () =>
      marketplaceSettings
        ?.filter((mk) => isMarketplaceActive(mk))
        .filter(
          (mk) => !isInternationalEvent || mk.mkp === Marketplace.StubHub
        ) ?? [],
    [isInternationalEvent, marketplaceSettings]
  );

  const defaultListingMarketplaceSettings = useMemo(() => {
    return eventMarketplaceSettings.reduce(
      (arr, { mkp }) => {
        const marketplaceId = Object.keys(Marketplace).indexOf(mkp) ?? -1;
        if (marketplaceId >= 0) {
          arr[marketplaceId + 1 + ''] = { listingBroadcastStateId: 2 };
        }
        return arr;
      },
      {} as Record<string, ListingMarketplaceSetting>
    );
  }, [eventMarketplaceSettings]);

  return {
    eventMarketplaceSettings,
    defaultListingMarketplaceSettings,
    isUKEvent,
    isInternationalEvent,
  };
};
