import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function UnHideIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M12 7.515c3.451 0 6.627 1.818 8.235 4.735-1.647 2.879-4.823 4.735-8.235 4.735-3.451 0-6.627-1.818-8.235-4.735C5.373 9.371 8.549 7.515 12 7.515zM12 6c4.47 0 8.275 2.576 10 6.25-1.725 3.674-5.569 6.25-10 6.25s-8.275-2.576-10-6.25C3.725 8.576 7.53 6 12 6zm0 3.068c-1.843 0-3.333 1.44-3.333 3.22 0 1.78 1.49 3.22 3.333 3.22 1.804 0 3.294-1.402 3.333-3.106H12v-.038l3.02-1.44A3.361 3.361 0 0012 9.068z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
