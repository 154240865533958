import { Performer } from 'src/WebApiController';

import * as styles from './EventSearchDropdownResult.css';

export const PerformerSearchResultItem = ({
  performer,
}: {
  performer: Performer;
}) => {
  return (
    <div className={styles.eventSearchResultItemDiv}>
      <div className={styles.eventSearchResultItemNameDiv}>
        {performer.name}
      </div>
    </div>
  );
};
