import { once } from 'lodash-es';
import React, { useContext, useState } from 'react';

export type IAdCampaignIdContext = {
  setAdCampaignId: (adCampaignId: string | undefined) => void;
  adCampaignId: string | undefined;
};

export const createAdCampaignIdContext = once(() => {
  return React.createContext<IAdCampaignIdContext>({} as IAdCampaignIdContext);
});

export const useAdCampaignIdContext = () =>
  useContext(createAdCampaignIdContext());

export const AdCampaignIdProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [campaignId, setCampaignId] = useState<string | undefined>(undefined);

  const setAdCampaignId = (adCampaignId: string | undefined) => {
    setCampaignId(adCampaignId);
  };

  const AdCampaignIdContext = createAdCampaignIdContext();

  return (
    <AdCampaignIdContext.Provider
      value={{
        setAdCampaignId: setAdCampaignId,
        adCampaignId: campaignId,
      }}
    >
      {children}
    </AdCampaignIdContext.Provider>
  );
};
