import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import { ListingDetailsModal } from './ListingDetailsModal';
import { ListingDetailsModalV2 } from './ListingDetailsModalV2';

export const ListingDetailsModalWrapper: React.FC = () => {
  const hasListingUpdateBySectionFeature = useUserHasFeature(
    Feature.ListingUpdateBySection
  );

  return hasListingUpdateBySectionFeature ? (
    <ListingDetailsModalV2 />
  ) : (
    <ListingDetailsModal />
  );
};
