import { Table } from '@tanstack/react-table';
import { useCallback, useEffect, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { getColumnPersonalization } from 'src/utils/columns/columnPersonalizationUtils';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import {
  ListingReportMetricsWithGroupBy,
  SaleReportMetricsWithGroupBy,
} from 'src/utils/reportsUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { MoneyClient, PosClientConfig } from 'src/WebApiController';

import { CustomNumericCell } from './CustomNumericCell';

export const CustomNumericFooterCell = ({
  table,
  columnId,
  matchingCustomColumn,
  shimmeringDivHeight,
  sectionType,
}: {
  table:
    | Table<ListingReportMetricsWithGroupBy | null>
    | Table<SaleReportMetricsWithGroupBy | null>;
  matchingCustomColumn?: CustomListingColumn | CustomSalesColumn;
  columnId: string;
  shimmeringDivHeight: string;
  sectionType: SectionType;
}) => {
  const { userDefinedPrecision } = useColumnUserSetting(columnId, sectionType);
  const { activeAccountWebClientConfig } = useAppContext();
  const [sumValue, setSumValue] = useState<number | null>(null);

  const original = table.options.meta?.summaryData;

  const loadData = useCallback(
    async (
      original: ListingReportMetricsWithGroupBy | SaleReportMetricsWithGroupBy,
      matchingCustomColumn: CustomListingColumn | CustomSalesColumn,
      activeAccountWebClientConfig: PosClientConfig
    ) => {
      let newSumValue: number | null = null;

      const tableRows = table.getFilteredRowModel().rows;
      const columnValues = tableRows.map((r) => {
        const value = r.getValue<number | null>(columnId);
        if (value == null || isNaN(value) || !isFinite(value)) {
          return null;
        }
        return value;
      });

      const allNull = columnValues.every((d) => d == null);

      const { isCurrency } = getColumnPersonalization({
        id: columnId,
        sectionType,
        matchingCustomColumn,
      });

      if (!allNull) {
        if (isCurrency) {
          const targetCurrencyCode = original.currency;
          newSumValue = 0;
          const currencyCodes = tableRows.map((r) => r.original?.currency);

          // Need to sum the currency values
          const valuesByCurrencyCodeToConvert: {
            [currencyCode: string]: number;
          } = {};

          currencyCodes.forEach((currencyCode, i) => {
            if (currencyCode && columnValues[i] != null) {
              if (currencyCode === targetCurrencyCode) {
                newSumValue = (newSumValue ?? 0) + columnValues[i]!;
              } else {
                valuesByCurrencyCodeToConvert[currencyCode] =
                  (valuesByCurrencyCodeToConvert[currencyCode] ?? 0) +
                  (columnValues[i] ?? 0);
              }
            }
          });

          newSumValue += await new MoneyClient(
            activeAccountWebClientConfig
          ).getConvertedMoneyAmount(
            valuesByCurrencyCodeToConvert,
            targetCurrencyCode
          );
        } else {
          newSumValue = columnValues.reduce<number>(
            (total, columnValue) => total + (columnValue ?? 0),
            0
          );
        }
      }

      setSumValue(newSumValue);
    },
    [columnId, sectionType, table]
  );

  useEffect(() => {
    if (!original || !matchingCustomColumn || !activeAccountWebClientConfig) {
      return;
    }

    loadData(original, matchingCustomColumn, activeAccountWebClientConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    original,
    matchingCustomColumn,
    activeAccountWebClientConfig,
    columnId,
    sectionType,
  ]);

  if (!original) {
    return <TableShimmeringDiv height={shimmeringDivHeight} />;
  }

  return (
    <CustomNumericCell
      columnId={columnId}
      userDefinedPrecision={userDefinedPrecision}
      value={sumValue}
      isLoading={!original}
      shimmeringDivHeight={shimmeringDivHeight}
      sectionType={sectionType}
      matchingCustomColumn={matchingCustomColumn}
      currencyCode={original?.currency}
    />
  );
};
