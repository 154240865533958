import { ColumnDef } from '@tanstack/react-table';
import {
  customColumnDef as customColumnDefEvent,
  EVENTS_TABLE_COLUMNS_CONFIG,
  EventWithDataAndMetrics,
} from 'src/tables/EventsTable/configs/EventsTableColumnsConfig';
import {
  customColumnDef as customColumnDefListingReport,
  LISTING_REPORT_TABLE_COLUMNS_CONFIG,
  tagColumnDef as tagColumnDefListingReport,
} from 'src/tables/ListingTable/configs/ListingReportTableColumnsConfig';
import {
  customColumnDef as customColumnDefListing,
  getListingTableColumnConfigs,
  tagColumnDef as tagColumnDefListing,
} from 'src/tables/ListingTable/configs/ListingTableColumnsConfig';
import { LISTING_TABLE_FLATTENED_COLUMNS_CONFIG } from 'src/tables/ListingTable/configs/ListingTableFlattenedColumnsConfig';
import { ListingWithEvent } from 'src/tables/ListingTable/ListingTable.types';
import { PURCHASE_TABLE_COLUMNS_CONFIG } from 'src/tables/PurchaseTable/configs/PurchaseTableColumnsConfig';
import {
  customColumnDef as customColumnDefSaleReport,
  SALE_REPORT_TABLE_COLUMNS_CONFIG,
  tagColumnDef as tagColumnDefSaleReport,
} from 'src/tables/SalesTable/configs/SaleReportTableColumnsConfig';
import {
  customColumnDef as customColumnDefSale,
  SALE_TABLE_COLUMNS_CONFIG,
  tagColumnDef as tagColumnDefSale,
} from 'src/tables/SalesTable/configs/SaleTableColumnsConfig';
import { SALE_TABLE_FLATTENED_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleTableFlattenedColumnsConfig';
import { SaleWithEvent } from 'src/tables/SalesTable/SalesTable.type';
import { EventsTableColumnId } from 'src/utils/columns/events/eventsColumnUtils.types';
import {
  GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING,
  GROUP_BY_TO_VISIBLE_COLUMN_IDS as GROUP_BY_TO_VISIBLE_COLUMN_IDS_LISTINGS,
  NON_METRIC_LISTING_REPORT_TABLE_COLUMN_IDS,
} from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import { CustomPurchasesColumn } from 'src/utils/columns/purchases/purchasesCustomColumnUtils.types';
import {
  SalesReportTableColumnId,
  SalesTableColumnId,
  SalesTableFlattenedColumnId,
} from 'src/utils/columns/sales/salesColumnUtils.types';
import {
  GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES,
  GROUP_BY_TO_VISIBLE_COLUMN_IDS as GROUP_BY_TO_VISIBLE_COLUMN_IDS_SALES,
  NON_METRIC_SALES_REPORT_TABLE_COLUMN_IDS,
} from 'src/utils/columns/sales/salesReportColumnUtils.constants';
import {
  AdCampaignEntity,
  AdGroup,
  FeatureDetail,
  PurchaseOrder,
  ReportGroupBy,
  Tag,
  UserDetail,
} from 'src/WebApiController';

import {
  ListingReportMetricsWithGroupBy,
  SaleReportMetricsWithGroupBy,
} from '../reportsUtils';
import { SectionType } from '../types/sectionType';
import { hasFeatures } from '../userUtils';
import { AdCampaignsListingsColumn as CustomAdCampaignsColumn } from './adCampaigns/adCampaignsCustomColumnUtils.types';
import { AdGroupsListingsColumn as CustomAdGroupsColumn } from './adGroups/adGroupsCustomColumnUtils.types';
import { getColumnPersonalization } from './columnPersonalizationUtils';
import {
  ColumnPersonalizationFlags,
  GetListingColumnConfigById,
} from './columnUtils.types';
import { CustomEventsColumn } from './events/eventsCustomColumnUtils.types';
import {
  ListingReportTableColumnId,
  ListingTableColumnId,
  ListingTableFlattenedColumnId,
} from './inventory/inventoryColumnUtils.types';
import { CustomListingColumn } from './inventory/inventoryCustomColumnUtils.types';
import { CustomSalesColumn } from './sales/salesCustomColumnUtils.types';

export const getColumnConfigById = ({
  id,
  customColumns,
  sectionType,
  reportingTags,
}: {
  id: string;
  sectionType: SectionType;
  customColumns?: (
    | CustomSalesColumn
    | CustomListingColumn
    | CustomEventsColumn
    | CustomPurchasesColumn
    | CustomAdCampaignsColumn
    | CustomAdGroupsColumn
  )[];
  reportingTags?: Tag[];
}): {
  personalization: ColumnPersonalizationFlags;
  definition:
    | ColumnDef<ListingWithEvent | null>
    | ColumnDef<ListingReportMetricsWithGroupBy | null>
    | ColumnDef<SaleWithEvent | null>
    | ColumnDef<SaleReportMetricsWithGroupBy | null>
    | ColumnDef<EventWithDataAndMetrics | null>
    | ColumnDef<PurchaseOrder | null>
    | ColumnDef<AdCampaignEntity | null>
    | ColumnDef<AdGroup | null>
    | undefined;
} => {
  const matchingCustomColumn = (customColumns ?? []).find((c) => c.id === id);
  const matchingTag = (reportingTags ?? []).find((t) => t.key === id);

  const tableColumns = getListingTableColumnConfigs();

  const { customColumnDef, tagColumnDef, tableColumnsConfig } =
    sectionType === SectionType.Listings
      ? {
          customColumnDef: customColumnDefListing,
          tagColumnDef: tagColumnDefListing,
          tableColumnsConfig: tableColumns[id as ListingTableColumnId],
        }
      : sectionType === SectionType.ListingsFlattened
      ? {
          customColumnDef: customColumnDefListing,
          tagColumnDef: tagColumnDefListing,
          tableColumnsConfig:
            tableColumns[id as ListingTableColumnId] ||
            LISTING_TABLE_FLATTENED_COLUMNS_CONFIG[
              id as ListingTableFlattenedColumnId
            ],
        }
      : sectionType === SectionType.ListingsReport
      ? {
          customColumnDef: customColumnDefListingReport,
          tagColumnDef: tagColumnDefListingReport,
          tableColumnsConfig:
            LISTING_REPORT_TABLE_COLUMNS_CONFIG[
              id as ListingReportTableColumnId
            ],
        }
      : sectionType === SectionType.Sales
      ? {
          customColumnDef: customColumnDefSale,
          tagColumnDef: tagColumnDefSale,
          tableColumnsConfig:
            SALE_TABLE_COLUMNS_CONFIG[id as SalesTableColumnId],
        }
      : sectionType === SectionType.SalesFlattened
      ? {
          customColumnDef: customColumnDefSale,
          tagColumnDef: tagColumnDefSale,
          tableColumnsConfig:
            SALE_TABLE_COLUMNS_CONFIG[id as SalesTableColumnId] ||
            SALE_TABLE_FLATTENED_COLUMNS_CONFIG[
              id as SalesTableFlattenedColumnId
            ],
        }
      : sectionType === SectionType.SalesReport
      ? {
          customColumnDef: customColumnDefSaleReport,
          tagColumnDef: tagColumnDefSaleReport,
          tableColumnsConfig:
            SALE_REPORT_TABLE_COLUMNS_CONFIG[id as SalesReportTableColumnId],
        }
      : {
          // SectionType.Event
          customColumnDef: customColumnDefEvent,
          tagColumnDef: undefined,
          tableColumnsConfig:
            EVENTS_TABLE_COLUMNS_CONFIG[id as EventsTableColumnId],
        };

  const definition = matchingCustomColumn
    ? customColumnDef(id)
    : matchingTag
    ? tagColumnDef?.(matchingTag.key)
    : tableColumnsConfig;

  const personalization = getColumnPersonalization({
    id,
    sectionType,
    matchingCustomColumn,
    matchingTag,
  });

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    definition: definition as any, // FIXME: type
    personalization,
  };
};

export const filterColumnsByFeatures = (
  columns: string[],
  sectionType: SectionType,
  customColumns?: (
    | CustomSalesColumn
    | CustomListingColumn
    | CustomEventsColumn
    | CustomPurchasesColumn
    | CustomAdCampaignsColumn
    | CustomAdGroupsColumn
  )[],
  user?: UserDetail | null,
  featureDetails?: FeatureDetail[] | null,
  reportingTags?: Tag[],
  groupBy?: ReportGroupBy,
  viewMode?: string
) => {
  return columns.filter((c) => {
    if (reportingTags?.find((t) => t.key === c) != null) {
      return true;
    }
    const customColumn = customColumns?.find((col) => col.id === c);
    if (customColumn) {
      const dependentColumns = customColumn.dependency;
      if (!dependentColumns) return true;

      // Inherit required features from dependent preset columns
      return (dependentColumns as string[]).every((d) => {
        const columnDef = getColumnPersonalization({ id: d, sectionType });
        const hasFeatureForColumn =
          columnDef.requiredFeatures.length === 0 ||
          hasFeatures(user, featureDetails, columnDef.requiredFeatures);
        return hasFeatureForColumn;
      });
    }

    if (groupBy) {
      if (sectionType === SectionType.SalesReport) {
        if (
          NON_METRIC_SALES_REPORT_TABLE_COLUMN_IDS.includes(
            c as SalesReportTableColumnId
          ) &&
          !GROUP_BY_TO_VISIBLE_COLUMN_IDS_SALES[groupBy]?.includes(
            c as SalesReportTableColumnId
          ) &&
          GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES[groupBy] !== c
        ) {
          return false;
        }
      } else if (sectionType === SectionType.ListingsReport) {
        if (
          NON_METRIC_LISTING_REPORT_TABLE_COLUMN_IDS.includes(
            c as ListingReportTableColumnId
          ) &&
          !GROUP_BY_TO_VISIBLE_COLUMN_IDS_LISTINGS[groupBy]?.includes(
            c as ListingReportTableColumnId
          ) &&
          GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING[groupBy] !== c
        ) {
          return false;
        }
      }
    }

    const columnDef = getColumnPersonalization({ id: c, sectionType });
    const hasFeatureForColumn =
      columnDef.requiredFeatures.length === 0 ||
      hasFeatures(user, featureDetails, columnDef.requiredFeatures);

    const visibleInCurrentViewMode =
      viewMode == null ||
      columnDef.visibleViewModes.length === 0 ||
      columnDef.visibleViewModes.includes(viewMode);

    return hasFeatureForColumn && visibleInCurrentViewMode;
  });
};

export const getListingColumnConfigById = (
  id: string,
  customColumns?: CustomListingColumn[],
  reportingTags?: Tag[]
): GetListingColumnConfigById => {
  return getColumnConfigById({
    id,
    sectionType: SectionType.Listings,
    customColumns,
    reportingTags,
  }) as GetListingColumnConfigById;
};

export const getListingFlattenedColumnConfigById = (
  id: string,
  customColumns?: CustomListingColumn[],
  reportingTags?: Tag[]
): GetListingColumnConfigById => {
  return getColumnConfigById({
    id,
    sectionType: SectionType.ListingsFlattened,
    customColumns,
    reportingTags,
  }) as GetListingColumnConfigById;
};

type GetListingReportColumnConfigById = {
  personalization: ColumnPersonalizationFlags;
  definition: ColumnDef<ListingReportMetricsWithGroupBy | null> | undefined;
};

export const getListingReportColumnConfigById = (
  id: string,
  customColumns?: CustomListingColumn[],
  reportingTags?: Tag[]
): GetListingReportColumnConfigById => {
  return getColumnConfigById({
    id,
    sectionType: SectionType.ListingsReport,
    customColumns,
    reportingTags,
  }) as GetListingReportColumnConfigById;
};

type GetSaleColumnConfigById = {
  personalization: ColumnPersonalizationFlags;
  definition: ColumnDef<SaleWithEvent | null>;
};

export const getSaleColumnConfigById = (
  id: string,
  customColumns?: CustomSalesColumn[],
  reportingTags?: Tag[]
): GetSaleColumnConfigById => {
  return getColumnConfigById({
    id,
    sectionType: SectionType.Sales,
    customColumns,
    reportingTags,
  }) as GetSaleColumnConfigById;
};

export const getSaleFlattenedColumnConfigById = (
  id: string,
  customColumns?: CustomSalesColumn[],
  reportingTags?: Tag[]
): GetSaleColumnConfigById => {
  return getColumnConfigById({
    id,
    sectionType: SectionType.SalesFlattened,
    customColumns,
    reportingTags,
  }) as GetSaleColumnConfigById;
};

type GetSaleReportColumnConfigById = {
  personalization: ColumnPersonalizationFlags;
  definition: ColumnDef<SaleReportMetricsWithGroupBy | null>;
};

export const getSaleReportColumnConfigById = (
  id: string,
  customColumns?: CustomSalesColumn[],
  reportingTags?: Tag[]
): GetSaleReportColumnConfigById => {
  return getColumnConfigById({
    id,
    sectionType: SectionType.SalesReport,
    customColumns,
    reportingTags,
  }) as GetSaleReportColumnConfigById;
};

type GetEventsColumnConfigById = {
  personalization: ColumnPersonalizationFlags;
  definition: ColumnDef<EventWithDataAndMetrics | null>;
};

export const getEventsColumnConfigById = (
  id: string,
  customColumns?: CustomEventsColumn[]
): GetEventsColumnConfigById => {
  return getColumnConfigById({
    id,
    sectionType: SectionType.Events,
    customColumns,
    reportingTags: undefined,
  }) as GetEventsColumnConfigById;
};

type GetPurchasesColumnConfigById = {
  personalization: ColumnPersonalizationFlags;
  definition: ColumnDef<PurchaseOrder | null>;
};

export const getPurchasesColumnConfigById = (
  id: string,
  customColumns?: CustomPurchasesColumn[]
): GetPurchasesColumnConfigById => {
  return getColumnConfigById({
    id,
    sectionType: SectionType.Purchases,
    customColumns,
    reportingTags: undefined,
  }) as GetPurchasesColumnConfigById;
};

export const defaultListingColumnsConfig = Object.values(
  getListingTableColumnConfigs()
)
  .filter((item) => {
    const columnConfig = getListingColumnConfigById(
      item.id as ListingTableColumnId
    );
    return (
      !columnConfig.personalization.isHiddenByDefault &&
      (columnConfig.personalization.isConfigurable ||
        columnConfig.personalization.isRequired)
    );
  })
  .map((column) => column.id as ListingTableColumnId);

const DEFAULT_LISTING_FLATTENED_COLUMNS_FROM_LISTING_COLUMNS = [
  ListingTableColumnId.Seating,
  ListingTableColumnId.TicketCount,
  ListingTableColumnId.UnsoldQuantity,
  ListingTableColumnId.UnitCost,
  ListingTableColumnId.ListPrice,
  ListingTableColumnId.AllInPrice,
  ListingTableColumnId.DeliveryType,
  ListingTableColumnId.InHandDate,
  ListingTableColumnId.Actions,
];

export const defaultListingFlattenedColumnsConfig = [
  ...Object.values(LISTING_TABLE_FLATTENED_COLUMNS_CONFIG)
    .filter((item) => {
      const columnConfig = getListingFlattenedColumnConfigById(
        item.id as string
      );
      return (
        !columnConfig.personalization.isHiddenByDefault &&
        (columnConfig.personalization.isConfigurable ||
          columnConfig.personalization.isRequired)
      );
    })
    .map((column) => column.id as ListingTableColumnId),
  ...DEFAULT_LISTING_FLATTENED_COLUMNS_FROM_LISTING_COLUMNS,
];

export const defaultSalesColumnsConfig = Object.values(
  SALE_TABLE_COLUMNS_CONFIG
)
  .filter((item) => {
    const columnConfig = getSaleColumnConfigById(item.id as SalesTableColumnId);
    return (
      !columnConfig.personalization.isHiddenByDefault &&
      (columnConfig.personalization.isConfigurable ||
        columnConfig.personalization.isRequired)
    );
  })
  .map((column) => column.id as SalesTableColumnId);

const DEFAULT_SALES_FLATTENED_COLUMNS_FROM_SALES_COLUMNS = [
  SalesTableColumnId.Seating,
  SalesTableColumnId.OrderDate,
  SalesTableColumnId.QuantitySold,
  SalesTableColumnId.Proceeds,
  SalesTableColumnId.Status,
  SalesTableColumnId.TicketType,
  SalesTableColumnId.InHandDate,
  SalesTableColumnId.Actions,
];

export const defaultSalesFlattenedColumnsConfig = [
  ...Object.values(SALE_TABLE_FLATTENED_COLUMNS_CONFIG)
    .filter((item) => {
      const columnConfig = getSaleFlattenedColumnConfigById(item.id as string);
      return (
        !columnConfig.personalization.isHiddenByDefault &&
        (columnConfig.personalization.isConfigurable ||
          columnConfig.personalization.isRequired)
      );
    })
    .map((column) => column.id as SalesTableColumnId),
  ...DEFAULT_SALES_FLATTENED_COLUMNS_FROM_SALES_COLUMNS,
];

export const defaultEventsColumnsConfig = Object.values(
  EVENTS_TABLE_COLUMNS_CONFIG
)
  .filter((item) => {
    const columnConfig = getEventsColumnConfigById(
      item.id as EventsTableColumnId
    );
    return (
      !columnConfig.personalization.isHiddenByDefault &&
      (columnConfig.personalization.isConfigurable ||
        columnConfig.personalization.isRequired)
    );
  })
  .map((column) => column.id as EventsTableColumnId);

export const defaultPurchasesColumnsConfig = Object.values(
  PURCHASE_TABLE_COLUMNS_CONFIG
)
  .filter((item) => {
    const columnConfig = getPurchasesColumnConfigById(
      item.id as PurchasesTableColumnId
    );
    return (
      !columnConfig.personalization.isHiddenByDefault &&
      (columnConfig.personalization.isConfigurable ||
        columnConfig.personalization.isRequired)
    );
  })
  .map((column) => column.id as PurchasesTableColumnId);

export const defaultPurchaseEventEnabledColumnsConfig = [
  PurchasesTableColumnId.VendorOrderId,
  PurchasesTableColumnId.Seating,
  PurchasesTableColumnId.Tickets,
  PurchasesTableColumnId.Shipment,
  PurchasesTableColumnId.Cost,
  PurchasesTableColumnId.Actions,
];
