import { ReactNode, useMemo } from 'react';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { vars } from 'src/core/themes';
import { Stack, TooltipPopover } from 'src/core/ui';
import { InfoOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { formatCurrency, formatPercent } from 'src/utils/numberFormatter';
import { ListingPriceCalculation, Seating } from 'src/WebApiController';

import * as styles from './ComparableListingDisplayTooltip.css';
import { DividerLine } from './DividerLine';

export type ComparableListingDisplayTooltipProps = {
  seating?: Seating;
  price?: ReactNode;
  listingPriceCalc?: ListingPriceCalculation | null;
};

export function ComparableListingDisplayTooltip({
  seating,
  price,
  listingPriceCalc,
}: ComparableListingDisplayTooltipProps) {
  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = useMemo(
    () => getUiCurrency(listingPriceCalc?.websitePrice?.currency ?? 'USD'),
    [getUiCurrency, listingPriceCalc?.websitePrice?.currency]
  );
  const pricingAgainst = listingPriceCalc?.compListings?.find(
    (x) => x.listingId === listingPriceCalc?.underCutChosenListingId
  );
  const content = (
    <Stack alignItems="center">
      {<InfoOutlineIcon withHoverEffect size={vars.iconSize.s} />}
    </Stack>
  );

  return (
    <TooltipPopover
      triggerContent={content}
      contentVariant="dark"
      contentStyle={{ padding: 0 }}
    >
      <Stack direction="column" className={styles.container}>
        <Stack
          justifyContent="spaceBetween"
          gap="xxl"
          className={styles.section}
        >
          <span>
            <Content id={ContentId.PricingAgainst} />
          </span>
          {pricingAgainst?.sellerAllInPrice?.disp ?? (
            <Content id={ContentId.NA} />
          )}
        </Stack>
        <DividerLine>-</DividerLine>
        <div className={styles.section}>
          <Stack justifyContent="spaceBetween" gap="xxl">
            <span>
              % <Content id={ContentId.DiscountApplied} />
            </span>
            {formatPercent(listingPriceCalc?.udrRel ?? 0, 0)}
          </Stack>
          <Stack justifyContent="spaceBetween" gap="xxl">
            <span>
              % <Content id={ContentId.RankPremium} />
            </span>
            {formatPercent(listingPriceCalc?.udrRelPrm ?? 0, 0)}
          </Stack>
        </div>
        <DividerLine>+</DividerLine>
        <div className={styles.section}>
          <Stack justifyContent="spaceBetween" gap="xxl">
            <span>
              {uiCurrency.sym} <Content id={ContentId.DiscountApplied} />
            </span>
            {formatCurrency(
              listingPriceCalc?.udrAbs ?? 0,
              uiCurrency.code,
              uiCurrency.dec
            )}
          </Stack>
          <Stack justifyContent="spaceBetween" gap="xxl">
            <span>
              {uiCurrency.sym} <Content id={ContentId.RankPremium} />
            </span>
            {formatCurrency(
              listingPriceCalc?.udrAbsPrm ?? 0,
              uiCurrency.code,
              uiCurrency.dec
            )}
          </Stack>
        </div>
        <DividerLine>=</DividerLine>
        <Stack
          justifyContent="spaceBetween"
          gap="xxl"
          className={styles.section}
        >
          <SeatingInfo
            darkBackground
            section={seating?.section ?? ''}
            row={seating?.row ?? ''}
            showSectionPrefix
            // purposely leaving these out
            seatFr={''}
            seatTo={''}
          />
          {price}
        </Stack>
      </Stack>
    </TooltipPopover>
  );
}
