import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ExpandIcon(props: IconsProps) {
  return (
    <SvgWrapper aria-label="ExpandIcon" {...props}>
      <path
        fill="inherit"
        d="M19.502 6L22 8.333l-9.993 9.33-.007-.006-.007.006L2 8.333 4.498 6 12 13.004z"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
