import { CancelButton } from 'src/components/Buttons';
import { DocumentFileUploadInfo } from 'src/components/UploadArtifacts/UploadETicketsV2';
import {
  UploadETicketsSubInstructions,
  UploadETicketsThumbnailLoader,
} from 'src/components/UploadArtifacts/UploadETicketsV2/UploadETickets.styled';
import { FormatContent } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { FormatContentId } from 'src/utils/constants/formatContentId';

interface UploadETicketsUploadingFileProps {
  onCancel: (documentFileUploadInfo: DocumentFileUploadInfo) => void;
  documentFileUploadInfo: DocumentFileUploadInfo;
}

export const UploadETicketsUploadingFile = ({
  onCancel,
  documentFileUploadInfo,
}: UploadETicketsUploadingFileProps) => {
  return (
    <UploadETicketsThumbnailLoader>
      <UploadETicketsSubInstructions>
        {documentFileUploadInfo.file?.name ? (
          <FormatContent
            id={FormatContentId.Uploading}
            params={documentFileUploadInfo.file?.name}
          />
        ) : (
          ' '
        )}
        {documentFileUploadInfo.uploadInfo && (
          // Only allow cancel once the file is uploaded and is only pollling for pages
          <CancelButton onClick={() => onCancel(documentFileUploadInfo)} />
        )}
      </UploadETicketsSubInstructions>
      <PosSpinner />
    </UploadETicketsThumbnailLoader>
  );
};
