import { ReactNode, useEffect } from 'react';
import {
  NO_GROUP_ID,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { Stack } from 'src/core/ui';
import { PurchaseOrderTable } from 'src/tables/PurchaseTable';
import { PurchaseViewMode } from 'src/WebApiController';

type PurchaseFlattenedViewProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
};

export const PurchaseFlattenedView = ({
  before,
  topListItemBefore,
}: PurchaseFlattenedViewProps) => {
  const { allPurchasesQuery, allPurchasesFiltered, purchaseCount } =
    usePurchaseDataContext();
  const { setAllGroupsIds } = useMultiSelectionContext();

  useEffect(() => {
    setAllGroupsIds([NO_GROUP_ID]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack direction="column" height="full" width="full">
      {before}
      {topListItemBefore}
      <PurchaseOrderTable
        groupId={NO_GROUP_ID}
        useVirtuoso
        disablePagination
        purchaseCount={purchaseCount}
        purchaseOrders={allPurchasesFiltered}
        getDataFail={Boolean(allPurchasesQuery.error)}
        isItemsLoading={allPurchasesQuery.isLoading}
        viewMode={PurchaseViewMode.FlattenedView}
      />
    </Stack>
  );
};
