import { format } from 'date-fns';
import { useMemo } from 'react';
import { PaymentsTable } from 'src/components/Payments/Tabs/components/table/PaymentsTable';
import { useSingleMonthMarketplacePaymentsQuery } from 'src/contexts/MarketplacePaymentDataContext';

interface GroupedPaymentsAccordionBodyProps {
  firstOfMonth: string;
}

export const GroupedPaymentsAccordionBody = ({
  firstOfMonth,
}: GroupedPaymentsAccordionBodyProps) => {
  const queryDate = useMemo(
    () => format(new Date(firstOfMonth), 'yyyy-MM-dd'),
    [firstOfMonth]
  );

  const { singleMonthPaymentQuery } = useSingleMonthMarketplacePaymentsQuery(
    queryDate,
    true
  );

  return <PaymentsTable tableData={singleMonthPaymentQuery.data ?? null} />;
};
