import { VenueNameDisplay } from 'src/components/Events/EventInfo';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import * as styles from 'src/tables/Table/Table.css';
import { TopLevelCategory } from 'src/WebApiController';

import { EventWithDataAndMetrics } from './EventsTableColumnsConfig';

export const VenueDisplayCell = ({
  data,
}: {
  data: EventWithDataAndMetrics;
}) => {
  const lowerCasedEventName = data.event.name.toLocaleLowerCase();
  const lowerCasedPerformer = data.performer?.name?.toLocaleLowerCase();
  const doNotShowVenue =
    data.event.genre === TopLevelCategory.Sports && data.isSinglePerformerView;
  const displayVenueOnly =
    data.isSinglePerformerView &&
    (data.event.genre === TopLevelCategory.Concert ||
      data.event.genre === TopLevelCategory.Theatre) &&
    lowerCasedEventName === lowerCasedPerformer;
  return (
    <TableCellDiv
      align="left"
      className={styles.basicFont}
      title={data.venue?.name}
    >
      {(!doNotShowVenue || displayVenueOnly) && (
        <VenueNameDisplay
          venueName={data.venue?.shortName || data.venue?.name}
          slimMode
          showTitle={false}
        />
      )}
    </TableCellDiv>
  );
};
