import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ReloadIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M4.394 5.647C.817 9.91 1.304 16.212 5.486 19.722c4.183 3.51 10.474 2.895 14.051-1.368a10.215 10.215 0 001.983-3.687l.094-.32-1.977-.46-.085.284a8.247 8.247 0 01-1.596 2.961C15.07 20.572 10 21.068 6.632 18.243S2.872 10.34 5.76 6.898l.139-.162c2.723-3.083 7.264-3.672 10.56-1.422l.07.049L14.9 7.304l6.348.951-2.039-6.086-1.374 1.638.057.04C13.702.814 7.8 1.587 4.394 5.647z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
