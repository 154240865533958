import { ReactNode } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { shared, vars } from 'src/core/themes';
import { Button, Card } from 'src/core/ui';
import { IconsFill, MinusIcon, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './SeatScoreConfigCard.css';

export type SeatScoreConfigCardProps = {
  label: ReactNode;
  importance: number;
  showChildren: boolean;
  onIncrementImportance?: () => void;
  onDecrementImportance?: () => void;
  /**
   * Pass the BezierGraph as a child
   */
  children?: ReactNode;
};

/**
 * Card that shows seat score configuration graph for usage in the seat score override editing view.
 * @param props
 * @returns
 */
export function SeatScoreConfigCard({
  label,
  importance,
  showChildren,
  onIncrementImportance,
  onDecrementImportance,
  children,
}: SeatScoreConfigCardProps) {
  return (
    <Card className={styles.card}>
      <div className={styles.header}>
        <div className={styles.label}>{label}</div>
        <Button
          className={styles.circleButton}
          variant="outline"
          shape="pill"
          disabled={importance <= 0}
          onClick={onDecrementImportance}
        >
          <MinusIcon size={vars.iconSize.m} fill={IconsFill.textBrand} />
        </Button>
        <div className={styles.importanceContainer}>
          <div className={styles.importanceValue}>{importance}</div>
          <div className={shared.typography.body3}>
            <Content id={ContentId.Importance} />
          </div>
        </div>
        <Button
          className={styles.circleButton}
          variant="outline"
          shape="pill"
          disabled={importance >= 10}
          onClick={onIncrementImportance}
        >
          <PlusIcon size={vars.iconSize.m} fill={IconsFill.textBrand} />
        </Button>
      </div>
      <div
        className={styles.graphWrapper}
        style={
          showChildren
            ? undefined
            : {
                left: -9999,
                position: 'absolute',
                maxHeight: '1px',
                maxWidth: '1px',
              }
        } // we need to render the graph or else the function refs won't work, so the way to hide them is to put this element totally off-screen
      >
        {children}
      </div>
    </Card>
  );
}
