import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const SplitIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 24">
      <circle
        cx="10.5"
        cy="10.5"
        r="10"
        stroke="currentColor"
        fill="none"
      ></circle>
      <path
        d="M 13.768 6.316 C 13.768 6.316 11.686 6.316 10.651 6.316 C 10.085 6.316 9.332 6.656 8.962 7.801 C 8.495 9.253 7.302 10.006 7.302 10.006 M 7.302 10.006 L 2.264 10.006 L 5.5 10.006 M 7.302 10.006 C 7.302 10.006 8.48 11.512 8.962 12.826 C 9.233 13.564 10.087 14.226 10.651 14.226 C 11.8 14.226 13.768 14.226 13.768 14.226"
        stroke="currentColor"
        fill="none"
      ></path>
      <path
        d="M 12.331 3.441 L 15.206 6.316 L 12.331 9.193"
        stroke="currentColor"
        fill="none"
      ></path>
      <path
        d="M 12.331 11.349 L 15.206 14.226 L 12.331 17.101"
        stroke="currentColor"
        fill="none"
      ></path>
    </SvgWrapper>
  );
};
