import { useEffect, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';

export const CurrencyFilterSelector = (
  props: Omit<PosSelectProps, 'searchable' | 'valueOptionsContent'>
) => {
  const { loginContext } = useAppContext();
  const { currencyCache } = useLocalizationContext();

  const { value, onChange, enableEmptySelection } = props;

  useEffect(() => {
    if (
      loginContext?.user?.activeAccount?.currencyCode &&
      !value &&
      !enableEmptySelection
    ) {
      onChange(loginContext?.user?.activeAccount?.currencyCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginContext?.user?.activeAccount?.currencyCode, value]);

  const availableCurrenciesOptions = useMemo(
    () =>
      Object.values(currencyCache || []).reduce(
        (result, cur) => {
          result[cur.code] = cur.code;
          return result;
        },
        {} as Record<string, string>
      ),
    [currencyCache]
  );

  return (
    <PosSelect
      {...props}
      searchable
      valueOptionsContent={availableCurrenciesOptions}
    />
  );
};
