import { PosIconProps } from './SvgWrapper';

export const ReportGroupIcon = (props: Omit<PosIconProps, 'viewBox'>) => {
  return (
    <svg
      width={props.width ?? '34'}
      height={props.height ?? '39'}
      viewBox="0 0 34 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0946 15.9412C28.596 12.5798 28.3018 7.75688 25.2118 4.68774C21.8275 1.3263 16.2361 1.3263 12.7047 4.68774C9.32044 8.04918 9.32044 13.6029 12.7047 17.1104C14.4704 18.8642 16.6775 19.7411 18.8847 19.7411C20.6504 19.7411 22.4161 19.1565 24.0346 18.1335L29.7732 23.8333L31.8332 21.6411L26.0946 15.9412ZM14.229 6.22916C15.5832 4.87499 17.2759 4.19791 18.9686 4.19791C20.6613 4.19791 22.354 4.87499 23.7082 6.22916C26.4165 8.93749 26.4165 13.1693 23.7082 15.7083C20.9998 18.4167 16.7681 18.4167 14.229 15.7083C11.5207 13 11.5207 8.76822 14.229 6.22916Z"
        fill="#677383"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15H6V39H9V15ZM3 23.4H0V39H3V23.4ZM13 25.8H16V39H13V25.8ZM22 22H19V39H22V22ZM25 24H28V39H25V24ZM34 30H31V39H34V30Z"
        fill="#677383"
      />
    </svg>
  );
};
