import { AdGroupListingsQueryParam } from './constants/constants';

export const getAdGroupListingsUrl = (
  adGroupId: string,
  includeSearchParams = true
): string => {
  let searchParams = '';
  if (includeSearchParams) {
    const url = new URL(window.location.href);
    url.searchParams.set(AdGroupListingsQueryParam, adGroupId);
    searchParams = `?${url.searchParams.toString()}`;
  }
  return `/sponsoredlistings/adgrouplistings${searchParams}`;
};
