import { BucketType, EventWithData, GroupingType } from 'src/WebApiController';
import {
  AutoPricingInputs,
  ConfigTypeOverride,
  ListingGroup,
  ListingGroupListingMarketplaceSettings,
  MergeListingGroupInputList,
  RowCompOverride,
  RowGroupSetting,
  Seating,
  UiMoney,
  ZoneSectionAreaGroupLookup,
} from 'src/WebApiController';

export type GroupListingsBodyProps = {
  events: EventWithData[];
  listingGroup?: ListingGroup;
  onEventSelected: (event?: EventWithData) => void;
  isViewingTemplate: boolean;
};

export type GroupingTemplateSeating = {
  id: string | number;
  seating: Seating;
  availQty: number | null;
  unitCst: UiMoney | null;
  faceValue: UiMoney | null; // this is just to match the IListingGroupItem interface
  ticketClassId?: number | null;
  priority?: number;
};

export type GroupingTemplate = {
  groupId: string;
  groupName: string;
  desiredActiveListings: number;
  deprioritizedQuantities: string[];
  seatings: GroupingTemplateSeating[];
  // This is for when applying to events
  // it's gonna be more efficient to check if a listing matches the group
  seatingsMap?: {
    sectionRowKeys: Record<string, GroupingTemplateSeating>;
    rowIdKeys: Record<number, GroupingTemplateSeating>;
  };
};

export type MergeListingGroupInputListFields = MergeListingGroupInputList & {
  templateSettings: {
    groupBy: ListingGroupBy;
    desiredActiveListings: number;
    deprioritizedQuantities: string[];
    groupTemplates: GroupingTemplate[];
    randomizePriority: boolean;
    templateRankPremiums: {
      undercutAbsoluteAmount: number | null;
      undercutRelativeAmount: number | null;
    } | null;
    minActiveListings: number | null;
    maxActiveListings: number | null;
    targetPercentage: number | null;
  };
  eventSettings: {
    groupBys: ListingGroupBy[];
    desiredActiveListings: number;
    minActiveListings: number | null;
    maxActiveListings: number | null;
    targetPercentage: number | null;
    deprioritizedQuantities: string[];
  }[];
  pricingSettingsInputs?: AutoPricingInputs;
  resetListingAutoPricingSettings?: boolean; // Toggle to reset the auto pricing settings within group
  configTypeOverride: ConfigTypeOverride;
  combineCornersAndEnds: boolean;
  zoneSectionAreaGroupLookup: ZoneSectionAreaGroupLookup;
  rowCompOffset: number;
  rowGroupSetting: RowGroupSetting;
  sectionGroupCustomRankEnabled: boolean;
  rowCompOverrides: RowCompOverride[];
};

/**
 * If this is null, the backend will do nothing
 * If it's undefined or empty array, the backend will clear out the values to be null in the db
 * If it has a value, then we will have a value
 */
export type BulkEditListingGroupSettingsFields = {
  // pricingSettingsInputs?: AutoPricingInputs | null;
  desiredActiveListings?: number | null;
  deprioritizedQuantities?: string[] | null;
  rankPremiums?: {
    undercutAbsoluteAmount: number | null;
    undercutRelativeAmount: number | null;
  } | null;
  marketplaceSettings?: ListingGroupListingMarketplaceSettings | null;
  pricingSettingsInputs?: AutoPricingInputs;
};

export type ListingGroupBy = {
  groupingType: GroupingType;
  numOfClusters?: number;
  bucketOption?: BucketType;
  groupingValue?: string;
};

export const ListingGroupingTypesWithClusteringOptions = [
  GroupingType.Row,
  GroupingType.Quantity,
  GroupingType.UnitCost,
  GroupingType.SeatScore,
];
export const ListingGroupingTypesNotSupportingAdditionalGroupBys = [
  GroupingType.Custom,
  GroupingType.None,
];

export type GroupListingSortingCriteria = {
  isAdminHold: boolean;
  availableQuantity: number;
};
