import { Feature } from 'src/WebApiController';

import { useUserHasFeature } from './useUserHasFeature';

/**
 * Returns `true` when user has the given feature _and_ has the general feature for autopricing;
 * Otherwise, returns `false`.
 */
export const useUserHasAutopricingFeature = (feature: Feature) => {
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const hasFeature = useUserHasFeature(feature);
  return hasAutoPricingFeature && hasFeature;
};
