import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect, PosSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { ContentId } from 'src/utils/constants/contentId';
import { TAG_VALUE_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  Feature,
  Permission,
  Tag,
  TagsValueType,
  UserSetting,
} from 'src/WebApiController';

import { CancelButton } from '../Buttons';
import * as styles from './TagsFormBody.css';

// Special characters that are not allowed in reporting column names to avoid interfering with the formula
const SPECIAL_CHAR_SET = ["'", '[', ']', '{', '}'];
const DEFAULT_MAX_NUM_REPORTING_COLUMN = 5;

export const EditTagMetadataForm = ({
  mergedTagsMetadata,
  mergedTagsMetadataWithNoValue,
  disabled,
  onSubmit,
  onDeleteSubmit,
  onClose,
  metricsInUse,
}: {
  mergedTagsMetadata: Tag[];
  mergedTagsMetadataWithNoValue: Tag[];
  disabled?: boolean;
  onSubmit: (newTagMetadata: Tag) => void;
  onDeleteSubmit: (tagToDelete: Tag) => void;
  onClose: () => void;
  metricsInUse: string[];
}) => {
  const {
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<Tag>({
    defaultValues: { key: '', value: '', valueType: TagsValueType.String },
  });

  const hasTagTypesUserGroupFeature = useUserHasFeature(
    Feature.TagTypesUserGroup
  );

  // TODO: Change this to correct one: https://thestubhub.atlassian.net/browse/POS-3668
  const hasDeleteTagTypePermission = useUserHasAnyOfPermissions(
    Permission.Inventory_AddTagType,
    Permission.Sales_AddTagType,
    Permission.Purchases_AddTagType,
    Permission.Events_AddTagType
  );

  const reportMsg = useContent(ContentId.Reporting);
  const requiredMsg = useContent(ContentId.Required);
  const specialCharsMsg = useContent(ContentId.SpecialCharactersNotAllowed);

  const { value: maxNumReportingColumn = DEFAULT_MAX_NUM_REPORTING_COLUMN } =
    useServerUserSetting<number>({
      id: UserSetting.ReportingColumnLimit,
    });

  const key = watch('key');
  const valueType = watch('valueType');
  const singleValueOnly = watch('singleValueOnly') ?? false;
  const allowMultipleValues = !singleValueOnly;
  const includeInReport = watch('includeInReport');

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const unselectReportConfirmDialog = useBasicDialog();
  const deleteConfirmDialog = useBasicDialog();
  const keyOptionsContent = useMemo(() => {
    const keyOptions = mergedTagsMetadata
      .sort((r1, r2) => r1.key.localeCompare(r2.key))
      .reduce(
        (re, r) => {
          re[r.key] = r.includeInReport ? `${r.key} (${reportMsg})` : r.key;
          return re;
        },
        {} as Record<string, string>
      );

    return keyOptions ?? {};
  }, [mergedTagsMetadata, reportMsg]);

  const isUsingTagWithNoValue = useMemo(() => {
    return mergedTagsMetadataWithNoValue.find((t) => t.key === key) != null;
  }, [mergedTagsMetadataWithNoValue, key]);

  const validateTagInput = useCallback(
    (data: Tag) => {
      clearErrors('key');
      clearErrors('valueType');

      if (!data.key) {
        setError('key', { message: requiredMsg }, { shouldFocus: true });
        return false;
      }

      if (!data.valueType) {
        setError('valueType', { message: requiredMsg }, { shouldFocus: true });
        return false;
      }

      if (SPECIAL_CHAR_SET.some((c) => data.key.includes(c))) {
        setError('key', { message: specialCharsMsg }, { shouldFocus: true });
        return false;
      }

      return true;
    },
    [clearErrors, requiredMsg, setError, specialCharsMsg]
  );

  const onSubmitHandler = useCallback(
    async (newTag: Tag) => {
      // Validation
      if (!validateTagInput(newTag)) {
        return;
      }

      try {
        setIsSubmitting(true);
        await onSubmit(newTag);
        setIsSubmitting(false);
        onClose();
      } catch (e) {
        // error tracking is handled in the api hook, only doing console here
        console.error('Unable to update tag metadata:', e);
      } finally {
        setIsSubmitting(false);
      }
    },
    [onClose, onSubmit, validateTagInput]
  );
  const tagTypesOptionsContent = useMemo(() => {
    return Object.entries(TAG_VALUE_TYPE_TO_CID).reduce(
      (cur, [key, value]) => {
        if (!hasTagTypesUserGroupFeature && key === TagsValueType.UserGroup) {
          return cur;
        }
        cur[key] = value;
        return cur;
      },
      {} as Record<string, string>
    );
  }, [hasTagTypesUserGroupFeature]);

  const onDeleteSubmitHandler = useCallback(
    async (tag: Tag) => {
      // Validation
      if (!tag.key) {
        return;
      }

      try {
        setIsSubmitting(true);
        await onDeleteSubmit(tag);
        setIsSubmitting(false);
        onClose();
      } catch (e) {
        // error tracking is handled in the api hook, only doing console here
        console.error('Unable to delete tag metadata:', e);
      } finally {
        setIsSubmitting(false);
      }
    },
    [onClose, onDeleteSubmit]
  );

  const exceedsReportingColumnLimit = useMemo(() => {
    const numReportingTagsExcludingCurrent = mergedTagsMetadata.filter(
      (t) => t.includeInReport && t.key !== key
    ).length;

    return numReportingTagsExcludingCurrent >= maxNumReportingColumn;
  }, [key, maxNumReportingColumn, mergedTagsMetadata]);

  return (
    <>
      <div className={styles.formMainContent}>
        <PosFormField
          label={<Content id={ContentId.Name} />}
          errors={errors?.key?.message}
        >
          <PosSelect
            onChange={(key: string) => {
              clearErrors('key');
              setValue('key', key);

              const existingTag = mergedTagsMetadata.find((t) => t.key === key);
              if (existingTag && existingTag.valueType) {
                setValue('valueType', existingTag.valueType);
                setValue(
                  'singleValueOnly',
                  existingTag.singleValueOnly ?? false
                );
                setValue(
                  'includeInReport',
                  existingTag.includeInReport ?? false
                );
              } else {
                setValue('valueType', TagsValueType.String);
                setValue('singleValueOnly', false);
                setValue('includeInReport', false);
              }
            }}
            value={key}
            displayText={key}
            disabled={disabled || isSubmitting}
            style={{ width: '100%' }}
            searchable
            valueOptionsContent={keyOptionsContent}
            placeholderText={ContentId.Key}
          />
        </PosFormField>

        <PosFormField
          label={<Content id={ContentId.ValueType} />}
          errors={errors?.valueType?.message}
        >
          <PosEnumSelect<TagsValueType>
            placeholderText={ContentId.SelectValueType}
            valueOptionsContent={tagTypesOptionsContent}
            value={valueType}
            onChange={(newValueType) => {
              clearErrors('valueType');
              setValue('valueType', newValueType);
            }}
            disabled={!isUsingTagWithNoValue || disabled || isSubmitting}
            style={{ width: '100%' }}
          />
        </PosFormField>
        <Checkbox
          labelPosition="right"
          checked={allowMultipleValues}
          onChange={() => {
            // isChecked is true means allow multiple values
            const isChecked = !allowMultipleValues;

            setValue('singleValueOnly', !isChecked);
            if (isChecked) {
              setValue('includeInReport', false);
            }
          }}
          disabled={!isUsingTagWithNoValue || disabled || isSubmitting}
          label={<Content id={ContentId.AllowMutipleValues} />}
        />
        <Checkbox
          labelPosition="right"
          disabled={
            disabled ||
            exceedsReportingColumnLimit ||
            !singleValueOnly ||
            isSubmitting
          }
          checked={includeInReport ?? false}
          onChange={() => {
            const isChecked = !includeInReport;
            if (!isChecked) {
              if (metricsInUse.includes(key)) {
                unselectReportConfirmDialog.launchDialog();
              } else {
                setValue('includeInReport', isChecked);
              }
            } else {
              setValue('includeInReport', isChecked);
            }
          }}
          label={<Content id={ContentId.IncludeInColumns} />}
        />

        <div
          className={styles.formFooter}
          style={{ justifyContent: 'space-between' }}
        >
          <Stack direction="row" gap="m">
            <Button
              onClick={() => {
                handleSubmit(onSubmitHandler)();
              }}
              disabled={disabled || isSubmitting || !key}
            >
              <Content id={ContentId.Save} />
            </Button>

            <CancelButton
              onClick={onClose}
              disabled={disabled || isSubmitting}
              textContentId={ContentId.Cancel}
            />
          </Stack>
          <div>
            {hasDeleteTagTypePermission && (
              <Button
                variant="outline"
                style={
                  !(disabled || isSubmitting || !key)
                    ? { color: vars.color.textError }
                    : {}
                }
                onClick={() => {
                  deleteConfirmDialog.launchDialog();
                }}
                disabled={disabled || isSubmitting || !key}
              >
                <Content id={ContentId.Delete} />
              </Button>
            )}
          </div>
        </div>
      </div>
      <ConfirmDialog
        {...unselectReportConfirmDialog.dialogProps}
        size={'m'}
        headerText={<Content id={ContentId.TurnOffReporting} />}
        bodyText={<Content id={ContentId.TurnOffReportingWarning} />}
        onOkay={() => {
          setValue('includeInReport', false);
          unselectReportConfirmDialog.closeDialog();
        }}
        onCancel={() => unselectReportConfirmDialog.closeDialog()}
      />
      <ConfirmDialog
        {...deleteConfirmDialog.dialogProps}
        size="m"
        headerText={<Content id={ContentId.DeleteTag} />}
        bodyText={
          <Stack direction="column" gap="m">
            <Content id={ContentId.DeleteTagConfirmation1} />
            <span />
            <Content id={ContentId.DeleteTagConfirmation2} />
          </Stack>
        }
        onOkay={handleSubmit(onDeleteSubmitHandler)}
        disabled={disabled || isSubmitting}
        onCancel={() => deleteConfirmDialog.closeDialog()}
      />
    </>
  );
};
