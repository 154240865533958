import { ReactNode } from 'react';

interface SalesBulkActionsPermissionsProps {
  children: ReactNode;
}

// TODO: Feature is now GA, need to clean this up.
export const SalesBulkActionsPermissions = ({
  children,
}: SalesBulkActionsPermissionsProps) => {
  return <>{children}</>;
};
