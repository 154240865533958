export enum KnownVendors {
  StubHub = 'StubHub',
  Ticketmaster = 'TicketMaster',
  TicketmasterCa = 'Ticketmaster CA',
  Axs = 'AXS',
  Eventbrite = 'Eventbrite',
  Livenation = 'Livenation',
  BroadwayDotCom = 'broadway.com',
  Telecharge = 'Telecharge',
  Ticketon = 'Ticketon',
  Seetickets = 'Seetickets',
  Etix = 'Etix',
  Tixr = 'Tixr',
  Festicket = 'Festicket',
  Insomniac = 'Insomniac',
  DiceImport = 'Dice  Import',
  MgmResorts = 'MGM Resorts',
  Tickeri = 'Tickeri',
  SmithCenterImport = 'Smith Center  Import',
  Stubwire = 'Stubwire',
  Computicket = 'Computicket',
}
