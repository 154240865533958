import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';

export const LaunchChangeListingPrice = ({
  onClick,
  disabled,
}: {
  onClick: (
    e: React.MouseEvent<HTMLButtonElement>,
    increasePrice: boolean
  ) => void;
  disabled?: boolean;
}) => {
  const hasEditPricingPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_SetPrice,
    Permission.Inventory_SetPriceOnIsPricerOf,
    Permission.Inventory_SetPriceOnPurchasedBy
  );

  if (!hasEditPricingPermission) {
    return null;
  }

  return (
    <>
      {hasEditPricingPermission && (
        <PosDropdownItem
          key="IncreasePriceBy"
          onClick={(e) => onClick(e, true)}
          disabled={disabled}
        >
          <Content id={ContentId.IncreasePrice} />
        </PosDropdownItem>
      )}
      {hasEditPricingPermission && (
        <PosDropdownItem
          key="DecreasePriceBy"
          onClick={(e) => onClick(e, false)}
          disabled={disabled}
        >
          <Content id={ContentId.DecreasePrice} />
        </PosDropdownItem>
      )}
    </>
  );
};
