import { ChangeEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { Radio, RadioGroup } from 'src/core/ui';
import { Stack } from 'src/core/ui';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import {
  PRICE_FLOOR_CEILING_MODE_OPTIONS,
  PriceFloorCeilingMode,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getAllInPriceFromListPrice,
  getListPriceFromAllinPrice,
} from 'src/utils/inventoryUtils';
import {
  AutoPricingCompListingMode,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
  Marketplace,
  UserSetting,
} from 'src/WebApiController';

import { useAutoPricingSettings } from '../useAutoPricingSettings';
import * as styles from './PricingSection.css';

export const PricingProtection = ({
  listing,
  disabled,
}: {
  listing?: ListingDetails | null;
  disabled?: boolean;
}) => {
  const { setValue, watch, clearErrors, formState } =
    useFormContext<ListingDetailsAutoPricingSectionUpdates>();

  const { loginContext } = useAppContext();
  const stubHubMkpSettings =
    loginContext?.user?.activeAccount?.marketplaceSettings?.find(
      (m) => m.mkp === Marketplace.StubHub
    );

  const netProceedsFloor = watch('netProceedsFloor');
  const netProceedsCeiling = watch('netProceedsCeiling');

  const floorError = formState.errors.netProceedsFloor?.message;
  const ceilingError = formState.errors.netProceedsCeiling?.message;

  const { canSetPrice, autoPricingEnabled, uiCurrency, compListingMode } =
    useAutoPricingSettings(listing);

  const priceProtectionDisabled =
    disabled ||
    listing?.isDeleted ||
    !canSetPrice ||
    Boolean(
      autoPricingEnabled &&
        compListingMode === AutoPricingCompListingMode.ParentListing
    );

  const { value: priceFloorMode, setUserSetting: setPriceFloorMode } =
    useServerUserSetting<PriceFloorCeilingMode>({
      id: UserSetting.PriceFloorCeilingMode,
      currentLoginUserOnly: true,
    });

  const onPriceFloorChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = parseFloat(e.target.value);
      if (v >= 0 && v < Number.MAX_VALUE) {
        if (netProceedsCeiling && v < netProceedsCeiling) {
          clearErrors('netProceedsFloor');
        }

        let newV = v;
        if (priceFloorMode === PriceFloorCeilingMode.Website) {
          // If we're editing in WebSite mode, we need to convert it to proceeds
          newV = getListPriceFromAllinPrice(
            v,
            listing,
            stubHubMkpSettings?.sellerFee
          )!;
        }

        setValue('netProceedsFloor', newV);
      } else {
        setValue('netProceedsFloor', null);
      }
    },
    [
      clearErrors,
      netProceedsCeiling,
      priceFloorMode,
      setValue,
      listing,
      stubHubMkpSettings?.sellerFee,
    ]
  );

  const onPriceCeilingChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const v = parseFloat(e.target.value);
      if (v >= 0 && v <= Number.MAX_VALUE) {
        if (netProceedsFloor && v > netProceedsFloor) {
          clearErrors('netProceedsCeiling');
        }

        let newV = v;
        if (priceFloorMode === PriceFloorCeilingMode.Website) {
          // If we're editing in WebSite mode, we need to convert it to proceeds
          newV = getListPriceFromAllinPrice(
            v,
            listing,
            stubHubMkpSettings?.sellerFee
          )!;
        }

        setValue('netProceedsCeiling', newV);
      } else {
        setValue('netProceedsCeiling', null);
      }
    },
    [
      clearErrors,
      netProceedsFloor,
      priceFloorMode,
      setValue,
      listing,
      stubHubMkpSettings?.sellerFee,
    ]
  );

  return (
    <>
      <DetailSection name={<Content id={ContentId.PricingProtection} />}>
        <SectionContent numOfColumns={1}>
          <DetailGroup style={{ gridColumn: '1' }}>
            <PosFormField label={<Content id={ContentId.EnterValuesFor} />}>
              <RadioGroup
                value={priceFloorMode}
                defaultValue={PriceFloorCeilingMode.Proceeds}
                onValueChange={setPriceFloorMode}
                disabled={priceProtectionDisabled}
              >
                <Stack direction="column" gap="m">
                  {PRICE_FLOOR_CEILING_MODE_OPTIONS.map((m) => (
                    <Radio
                      key={m.value}
                      value={m.value}
                      label={m.children}
                      disabled={priceProtectionDisabled}
                    />
                  ))}
                </Stack>
              </RadioGroup>
            </PosFormField>
          </DetailGroup>

          <DetailGroup style={{ gridColumn: '1' }}>
            <Stack gap="l" alignItems="center">
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.DontPriceBelow} />
              </div>
              <PosFormField
                errors={floorError}
                style={{ width: 'fit-content' }}
              >
                <PosCurrencyField
                  rootProps={{
                    state: getTextFieldState(floorError),
                    disabled: priceProtectionDisabled,
                  }}
                  placeholder={undefined}
                  disabled={priceProtectionDisabled}
                  uiCurrency={uiCurrency}
                  value={
                    (priceFloorMode === PriceFloorCeilingMode.Website
                      ? getAllInPriceFromListPrice(
                          netProceedsFloor,
                          listing,
                          stubHubMkpSettings?.sellerFee
                        )
                      : netProceedsFloor) ?? ''
                  }
                  onChange={onPriceFloorChange}
                />
              </PosFormField>
              <div className={styles.inlinePricingInputText}>
                <Content id={ContentId.OrAbove} />
              </div>
              <PosFormField
                errors={ceilingError}
                style={{ width: 'fit-content' }}
              >
                <PosCurrencyField
                  rootProps={{
                    state: getTextFieldState(ceilingError),
                    disabled: priceProtectionDisabled,
                  }}
                  placeholder={undefined}
                  disabled={priceProtectionDisabled}
                  uiCurrency={uiCurrency}
                  value={
                    (priceFloorMode === PriceFloorCeilingMode.Website
                      ? getAllInPriceFromListPrice(
                          netProceedsCeiling,
                          listing,
                          stubHubMkpSettings?.sellerFee
                        )
                      : netProceedsCeiling) ?? ''
                  }
                  onChange={onPriceCeilingChange}
                />
              </PosFormField>
            </Stack>
          </DetailGroup>
        </SectionContent>
      </DetailSection>
    </>
  );
};
