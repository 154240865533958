import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import { ticketsAndSeatsZIndex } from 'src/components/UploadArtifacts/UploadETicketsV2/constants';
import { useUploadETicketsContext } from 'src/components/UploadArtifacts/UploadETicketsV2/UploadETicketsContext';
import { UploadETicketsPreviewTicketModal } from 'src/components/UploadArtifacts/UploadETicketsV2/views/PreviewModal/UploadETicketsPreviewTicketModal';
import { UploadETicketsTicketsSection } from 'src/components/UploadArtifacts/UploadETicketsV2/views/UploadETicketsTicketsSection';
import { Content } from 'src/contexts/ContentContext';
import { useDndFilerUploaderContext } from 'src/core/POS/DnDFileUploader/DndFileUploaderContext';
import { vars } from 'src/core/themes';
import { mergeProps } from 'src/core/utils';
import { useOnElementResize } from 'src/hooks/useOnElementResize';
import { useTimeoutFlag } from 'src/hooks/useTimeoutFlag';
import { ContentId } from 'src/utils/constants/contentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { TicketType, UserDocumentLinks } from 'src/WebApiController';

import * as styles from '../UploadETickets.css';
import {
  UploadETicketsInstructions,
  UploadETicketsSubInstructions,
} from '../UploadETickets.styled';
import {
  DocumentFileUploadInfo,
  ETicketsForm,
  getDocumentPageUniqueId,
} from '../UploadETickets.utils';
import { UploadETicketsSeatingSection } from './UploadETicketsSeatingSection';

export type EntityWithRealTicketsPartial = Pick<
  EntityWithRealTickets,
  'viagVirtualId' | 'id' | 'ticketCnt' | 'tickets' | 'seating' | 'entityType'
>;
const onWindowResize = (entries: ResizeObserverEntry[]) => {};

export const UploadETicketsSeatAssignmentBody = ({
  entityWithTickets,
  ticketType,
  eTicketUploadInfos,
  setETicketUploadInfos,
  className,
}: {
  entityWithTickets: EntityWithRealTicketsPartial;
  ticketType: TicketType.ETicket | TicketType.QRCode;
  eTicketUploadInfos: DocumentFileUploadInfo[];
  setETicketUploadInfos: (infos: DocumentFileUploadInfo[]) => void;
  className?: string;
}) => {
  const { setValue } = useFormContext<ETicketsForm>();
  const watchedTickets: UserDocumentLinks[] = useWatch({
    name: 'ticketAssignments',
  });
  const autoAssignment = useRef(true);
  const { isDragging } = useDndFilerUploaderContext();
  const { setSelectedUniquePageId } = useUploadETicketsContext();
  const ticketsSectionPortalRef = useRef<HTMLDivElement>(null);
  const hasRenderModalAnimation = useTimeoutFlag({ msTimeout: 500 });

  /*
   * Use to trigger window repaint on browser window change size because
   * the portal positioning element needs to know about it to recalculate
   * the width.
   */
  useOnElementResize({
    element: ticketsSectionPortalRef.current,
    callback: onWindowResize,
  });

  useEffect(() => {
    const allPagesAvailable =
      eTicketUploadInfos.find(
        (ui) => ui.file && ui.uploadInfo && (ui.pages?.length || 0) === 0
      ) == null; // returns false where any uploadInfo (that has file and uploadInfo) has no pages
    if (autoAssignment.current && allPagesAvailable) {
      // Only perform once, and perform only when all pages are available
      const allPages = eTicketUploadInfos
        .flatMap((ui) => ui.pages)
        .filter((p) => p);
      if (
        allPages.length > 0 && // if we have pages to assigne
        watchedTickets.filter((t) => t.pageId !== '').length === 0 // and we have not assign any ticket to any seat
      ) {
        // Try to auto-assign seat based on order of the pages
        // Users can re-adjust if they see fit
        // TODO - we can probably use some smart-scanning features to figure out which page is for which seat
        watchedTickets.forEach((t, index) => {
          if (index < allPages.length) {
            const page = allPages[index];
            t.documentId = page!.documentId;
            t.pageId = page!.id;
            t.pageNumber = page!.pageNumber;
            t.pageUri = page!.thumbUrl;
            t.documentUri = page!.documentUrl;
          }
        });

        setValue('ticketAssignments', [...watchedTickets]);
        autoAssignment.current = false;
        const firstPage = allPages[0];
        if (firstPage) {
          setSelectedUniquePageId(getDocumentPageUniqueId(firstPage));
        }
      }
    }
  }, [setSelectedUniquePageId, eTicketUploadInfos, setValue, watchedTickets]);

  return (
    <div
      ref={ticketsSectionPortalRef}
      {...mergeProps(
        { className: styles.uploadETicketsBodyContainer },
        { className: className }
      )}
    >
      <div className={styles.container} />
      {ticketsSectionPortalRef?.current &&
        hasRenderModalAnimation &&
        createPortal(
          <div
            style={{
              position: 'absolute',
              zIndex: ticketsAndSeatsZIndex,
              top:
                ticketsSectionPortalRef.current?.getBoundingClientRect()?.top ??
                0,
              right: vars.spacing.xl,
              width: ticketsSectionPortalRef.current?.clientWidth,
              height: ticketsSectionPortalRef.current?.clientHeight,
              opacity: isDragging ? 0.03 : 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <UploadETicketsInstructions>
              <Content
                id={
                  ticketType === TicketType.ETicket
                    ? ContentId.AssignETicketsIns
                    : ContentId.AssignQRCodesIns
                }
              />
            </UploadETicketsInstructions>
            <UploadETicketsSubInstructions>
              <Content
                id={
                  ticketType === TicketType.ETicket
                    ? ContentId.HoverZoomETicketIns
                    : ContentId.HoverZoomQRCodeIns
                }
              />
            </UploadETicketsSubInstructions>
            <div className={styles.uploadETicketsSection}>
              <UploadETicketsPreviewTicketModal />
              <UploadETicketsTicketsSection
                entityWithTickets={entityWithTickets}
                eTicketUploadInfos={eTicketUploadInfos}
                setETicketUploadInfos={setETicketUploadInfos}
              />
              <UploadETicketsSeatingSection
                ticketType={ticketType}
                entityWithTickets={entityWithTickets}
                eTicketUploadInfos={eTicketUploadInfos}
              />
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};
