import { Dispatch, SetStateAction } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { ContentId } from 'src/utils/constants/contentId';

export const EventEntitySearchBoxV2 = ({
  onIdLookup,
  onEventSearch,
  minWidth = 250,
  maxWidth,
  disabled,
  searchText,
  setSearchText,
  clearSearchEntity,
}: {
  onIdLookup: (id: string) => void;
  onEventSearch: (searchText: string) => void;
  minWidth?: number | string;
  maxWidth?: number | string;
  disabled?: boolean;
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  clearSearchEntity?: () => void;
}) => {
  const searchPlaceholder = useContent(ContentId.SearchEventOrLookupId);
  return (
    <PosSearchBox
      value={searchText}
      disabled={disabled}
      minWidth={minWidth}
      maxWidth={maxWidth}
      placeholder={searchPlaceholder}
      onSearchChange={(searchText) => {
        setSearchText(searchText);

        if (!searchText && clearSearchEntity) {
          clearSearchEntity();
        } else {
          onEventSearch(searchText);
        }
      }}
      onEnterKeyPressed={onIdLookup}
    />
  );
};
