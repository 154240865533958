import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { ModalBodyDataContainer } from 'src/modals/Modal/Modal.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { TransferTicketsForm } from 'src/utils/ticketUtils';

export const TransferIdProofBody = ({ disabled }: { disabled?: boolean }) => {
  const { register, formState, watch } = useFormContext<TransferTicketsForm>();
  const transferProofId = watch('transferProofId');

  const transferProofIdErrpr = formState?.errors?.transferProofId?.message;
  return (
    <ModalBodyDataContainer>
      <Stack direction="column" gap="xl">
        <Content id={ContentId.ProvideTransferProofIdInstruction} />
        <PosFormField
          label={<Content id={ContentId.TransferProofId} />}
          errors={transferProofIdErrpr}
        >
          <PosTextField
            disabled={disabled}
            value={transferProofId ?? ''}
            {...register('transferProofId')}
          />
        </PosFormField>
      </Stack>
    </ModalBodyDataContainer>
  );
};
