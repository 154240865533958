export const PURCHASE_MONTHLY_CONTAINER_QUERY_KEY =
  'PurchaseClient.getMonthsContainerPurchaseOrders';
export const PURCHASE_SINGLE_MONTH_QUERY_KEY =
  'PurchaseClient.getPurchaseOrders';

export const PURCHASE_SINGLE_TIME_PERIOD_QUERY_KEY =
  'PurchaseClient.getPurchaseOrders';

export const PURCHASE_TIME_PERIOD_CONTAINER_QUERY_KEY =
  'PurchaseClient.getTimePeriodsContainerPurchaseOrders';
