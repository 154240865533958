import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { mergeProps } from 'src/core/utils';

import * as styles from './Card.css';

export const Card = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<'div'>>(
  function Card({ children, ...props }, ref) {
    return (
      <div ref={ref} {...mergeProps({ className: styles.root }, props)}>
        {children}
      </div>
    );
  }
);
