import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function CrossIcon(props: IconsProps) {
  return (
    <SvgWrapper aria-label="CrossIcon" {...props}>
      <path
        fill="inherit"
        d="M18.02 4L12 10.02 5.98 4 4 5.98 10.02 12 4 18.02 5.98 20 12 13.98 18.02 20 20 18.02 13.98 12 20 5.98z"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
