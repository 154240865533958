import { Row, SortingFns } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { FormatOption } from 'src/modals/EditTableColumns';
import { ListingWithEvent } from 'src/tables/ListingTable/ListingTable.types';
import { SaleWithEvent } from 'src/tables/SalesTable/SalesTable.type';
import {
  sortListingSeating,
  sortListingSeatingSection,
  sortPurchaseOrderSeatingSection,
  sortPurchaseOrderTicketGroupSeating,
  sortSalesSeating,
  sortSalesSeatingSection,
} from 'src/utils/tableUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { PurchaseOrderTicketGroup } from 'src/WebApiController';

export const SEATING_SORTING_FN_NAME = 'seatingSortingFn';

const listingSectionTypes: Readonly<SectionType[]> = [
  SectionType.Listings,
  SectionType.ListingsFlattened,
  SectionType.ListingsReport,
];

const purchaseSectionTypes: Readonly<SectionType[]> = [
  SectionType.Purchases,
  SectionType.PurchaseEvent,
  SectionType.PurchaseSideTable,
];

const salesSectionTypes: Readonly<SectionType[]> = [
  SectionType.Sales,
  SectionType.SalesFlattened,
  SectionType.SalesReport,
  SectionType.SalesSideTable,
];

type SortingEntity =
  | ListingWithEvent
  | SaleWithEvent
  | PurchaseOrderTicketGroup;

export type SeatingSortingFn<T extends SortingEntity> = (
  rowA: Row<T | null>,
  rowB: Row<T | null>,
  colId: string
) => number;

export type SortinWrapper<T extends SortingEntity> = (
  rowA: Row<T | null>,
  rowB: Row<T | null>,
  columnId: string,
  sortingFn: SeatingSortingFn<T>
) => number;

export const useSeatingColumnSorting = <T extends SortingEntity>(
  columnId: string,
  sectionType: SectionType,
  sortingWrapper?: SortinWrapper<T>
): SortingFns => {
  const { userDefinedPrecision } = useColumnUserSetting(columnId, sectionType);

  return useMemo(() => {
    let sortingFn: SeatingSortingFn<T> = (
      rowA: Row<T | null>,
      rowB: Row<T | null>,
      colId: string
    ) => 0;

    const isSectionOnlyPrecision =
      userDefinedPrecision === FormatOption.Seating_SectionOnly;

    if (listingSectionTypes.includes(sectionType)) {
      sortingFn = (
        isSectionOnlyPrecision ? sortListingSeatingSection : sortListingSeating
      ) as SeatingSortingFn<T>;
    } else if (purchaseSectionTypes.includes(sectionType)) {
      sortingFn = (
        isSectionOnlyPrecision
          ? sortPurchaseOrderSeatingSection
          : sortPurchaseOrderTicketGroupSeating
      ) as SeatingSortingFn<T>;
    } else if (salesSectionTypes.includes(sectionType)) {
      sortingFn = (
        isSectionOnlyPrecision ? sortSalesSeatingSection : sortSalesSeating
      ) as SeatingSortingFn<T>;
    }

    const seatingSortingFn = (rowA: Row<T | null>, rowB: Row<T | null>) => {
      if (sortingWrapper) {
        return sortingWrapper(rowA, rowB, columnId, sortingFn);
      }
      return sortingFn(rowA, rowB, columnId);
    };

    return {
      [SEATING_SORTING_FN_NAME]: seatingSortingFn,
    };
  }, [columnId, sectionType, sortingWrapper, userDefinedPrecision]);
};
