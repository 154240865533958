import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function DuplicateOutlinedIcon(props: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper {...props} viewBox="2 2 20 20" fill="none">
      <circle cx="12" cy="12" r="9.60526" stroke={props.fill || '#87909A'} />
      <path
        d="M13.1084 10.1942V8.80883C13.1084 8.36212 12.7463 8 12.2996 8H8.80883C8.36212 8 8 8.36212 8 8.80883V14.3429C8 14.7896 8.36212 15.1517 8.80883 15.1517H11.5669"
        stroke={props.fill || '#87909A'}
        strokeWidth="0.808828"
      />
      <rect
        x="11.5758"
        y="10.0433"
        width="5.10839"
        height="7.15174"
        rx="0.808828"
        stroke={props.fill || '#87909A'}
        strokeWidth="0.808828"
      />
    </SvgWrapper>
  );
}
