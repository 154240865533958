import { RowData } from '@tanstack/react-table';
import { clsx } from 'clsx';
import { PosVirtuosoTableProps } from 'src/tables/Table/PosVirtuosoTableComponent/PosVirtuosoTableContext';
import { PosTableFooterSticky } from 'src/tables/Table/Table.css';

export const PosVirtuosoTableFooter = <T extends RowData>({
  children,
  context,
}: PosVirtuosoTableProps<T>) => (
  <tfoot className={clsx({ [PosTableFooterSticky]: context?.useStickyFooter })}>
    {children}
  </tfoot>
);
