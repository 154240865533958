import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function SeatSaverIcon(props: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper
      {...props}
      viewBox="0 0 16 16" // The viewbox here is important as it won't display properly without this specific value
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0474 6.31849H12.6965H11.185H10.5373H7.29841V6.2915V4.67206V4.18623V3.07962H7.91919C8.27007 3.59244 8.83687 3.88934 9.45765 3.88934C10.0784 3.88934 10.6452 3.59244 10.9961 3.07962H11.6169V5.77868L12.6965 5.23887V2H10.3483L10.2134 2.2969C10.1324 2.51282 9.94348 2.67476 9.72756 2.75574C9.29571 2.89069 8.89085 2.70175 8.70192 2.32389L8.59395 2H6.24578V4.21323V4.78003H5.57101C5.16615 4.78003 4.7613 4.94197 4.4644 5.21188L1.3335 8.07287V8.47773V9.55735V11.4197V14.5776H2.41312H10.8342C11.1581 14.5776 11.4819 14.4426 11.7249 14.2267C11.8328 14.1188 11.9138 14.0648 12.0218 13.8758C12.2107 13.5789 12.3187 13.2011 12.3187 12.8502V12.7422C12.6155 12.6883 12.8855 12.5533 13.1014 12.3644C13.1284 12.3374 13.1284 12.3374 13.1554 12.3104C13.4523 12.0135 13.6142 11.6356 13.6142 11.2038V10.556C13.6412 10.529 13.6412 10.529 13.6682 10.502C13.9651 10.2051 14.127 9.82726 14.127 9.39541V8.82861C14.154 8.80162 14.154 8.80162 14.181 8.80162C14.208 8.80162 14.208 8.77463 14.235 8.77463C14.5049 8.50472 14.6668 8.15385 14.6668 7.74899C14.6128 7.12821 14.235 6.64238 13.7222 6.39946C13.4253 6.31849 13.0474 6.31849 13.0474 6.31849ZM13.4253 7.9919C13.3713 8.04588 13.2633 8.09986 13.1823 8.09986H8.70192V9.17949H13.0204V9.39541C13.0204 9.53036 12.9664 9.63833 12.8855 9.74629C12.7775 9.82726 12.6695 9.88124 12.5346 9.88124H8.72891V10.9609V10.9609H12.5076V11.1768C12.5076 11.3117 12.4536 11.4197 12.3726 11.5277C12.2647 11.6086 12.1567 11.6626 12.0218 11.6626H8.70192V11.6626V11.6626V12.7422H11.239V12.9582C11.239 13.2281 11.0231 13.444 10.7532 13.444H2.41312V8.5587L5.19315 5.9946C5.30111 5.91363 5.43606 5.85965 5.57101 5.85965H6.24578V6.31849V7.39811V7.39811H13.1823C13.3713 7.39811 13.5332 7.56005 13.5332 7.74899C13.5332 7.82996 13.4792 7.91093 13.4253 7.9919V7.9919Z"
        fill="inherit"
      />
    </SvgWrapper>
  );
}
