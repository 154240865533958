import { vars } from 'src/core/themes';

import { PosEnumSelect, PosEnumSelectProps } from '../PosSelect';
import { PosSearchBox, PosSearchBoxProps } from './PosSearchBox';

export type PosSelectableSearchBoxProps<EnumType extends string> = Omit<
  PosSearchBoxProps,
  'postfixDisplay'
> & {
  selectorProps:
    | Omit<
        PosEnumSelectProps<EnumType>,
        'shape' | 'size' | 'variant' | 'className'
      >
    | undefined;
};

export function PosSelectableSearchBox<EnumType extends string>({
  selectorProps,
  ...props
}: PosSelectableSearchBoxProps<EnumType>) {
  return (
    <PosSearchBox
      {...props}
      postfixDisplay={
        selectorProps && (
          <PosEnumSelect
            shape="pill"
            size="md"
            variant="outline"
            style={{ marginRight: `calc(-1 * ${vars.spacing.sm})` }}
            {...selectorProps}
          />
        )
      }
    />
  );
}
