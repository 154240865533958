import { ComponentProps } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { ReportHistoryTable } from 'src/dialogs/ReportHistoryDialog/ReportHistoryTable';
import { useFetchReportSnapshotsInfo } from 'src/dialogs/ReportHistoryDialog/useFetchReportSnapshotsInfo';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { ContentId } from 'src/utils/constants/contentId';

export type ReportHistoryDialog = {
  reportConfig: ReportConfig | undefined;
} & ComponentProps<typeof RSModal>;

export function ReportHistoryDialog({
  reportConfig,
  isOpen,
  onCancel,
}: ReportHistoryDialog) {
  const reportId = reportConfig?.reportId;

  const { isFetching, data: reportSnapshotInfos } = useFetchReportSnapshotsInfo(
    {
      reportConfigId: reportId ?? undefined,
    }
  );

  if (!reportId) {
    return null;
  }

  return (
    <GenericDialog
      size="m"
      header={<Content id={ContentId.ReportHistory} />}
      footer={null}
      isOpen={isOpen}
      onCancel={onCancel}
    >
      {isFetching ? (
        <PosSpinner />
      ) : (
        reportSnapshotInfos && (
          <ReportHistoryTable
            reportConfigId={reportId}
            reportSnapshotInfos={reportSnapshotInfos}
          />
        )
      )}
    </GenericDialog>
  );
}
