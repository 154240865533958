import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';

import { ConfirmDialog, ConfirmDialogProps } from './ConfirmDialogV2';

export const useConfirmDialog = () => {
  const { openDialog, closeDialog } = useDialog<ConfirmDialogProps>(
    DialogId.Confirm,
    ConfirmDialog
  );

  return {
    openConfirmDialog: openDialog,
    closeConfirmDialog: closeDialog,
  };
};
