import React, { ComponentProps } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { OkButton } from 'src/components/Buttons/OkButton';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { vars } from 'src/core/themes';
import { ErrorSolidIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';

import * as styles from './ErrorDialog.css';

export type ErrorDialogProps = {
  statusCode?: number | null;
  messageText?: string | React.ReactNode | null;
  onOkay?: () => void;
  headerText?: React.ReactNode;
} & ComponentProps<typeof RSModal>;

export function ErrorDialog({
  statusCode,
  messageText,
  headerText,
  onOkay,
  ...rest
}: ErrorDialogProps) {
  const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
    statusCode,
    messageText
  );
  const header = headerText ?? headerDisplay;

  return (
    <GenericDialog
      size="m"
      header={header}
      footer={
        <>
          <OkButton textContentId={ContentId.OK} onClick={onOkay} />
        </>
      }
      {...rest}
      includeCloseButton={false}
    >
      <div className={styles.errorMessageBody}>
        <ErrorSolidIcon fill={IconsFill.textNegative} size={vars.iconSize.xl} />
        {messageDisplay}
      </div>
    </GenericDialog>
  );
}
