import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import {
  CheckIcon,
  CrossIcon,
  IconsFill,
  ProcessingIcon,
} from 'src/svgs/Viagogo';
import {
  OUTLIER_MODE_KTH_LOWEST_STRATEGY,
  OutlierModeKthLowestStrategy,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { AutoPricingOutlierMode } from 'src/WebApiController';

import {
  ListingPricingInput,
  ListingPricingSettingsCellProps,
} from '../ListingPriceForm.types';
import * as styles from './Cell.css';

export const OutlierSpacingCell: React.FC<ListingPricingSettingsCellProps> = ({
  listing,
  editable,
  event,
}) => {
  const { watch, formState, getFieldState, setValue } =
    useFormContext<ListingPricingInput>();

  const saveMsg = useContent(ContentId.Save);
  const cancelMsg = useContent(ContentId.Cancel);

  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);

  const {
    outlierMode,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,
    canAutoPrice,
    onOutlierChange,
  } = useListingAutoPricingSettings(listing);
  const [outlierKthLowestStrategy, setOutlierKthLowestStrategy] =
    useState<OutlierModeKthLowestStrategy>(
      OutlierModeKthLowestStrategy.Relative
    );

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingPrice = watch('isSubmitting');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const isDisabled =
    listing.isDeleted ||
    !canAutoPrice ||
    listingSelection.isSingleGroupMultiSelect ||
    fieldIsSubmitting ||
    !editable ||
    outlierMode !== AutoPricingOutlierMode.KthLowest;

  const submittingField = watch('submittingField');
  const isSubmittingField =
    fieldIsSubmitting &&
    submittingField === 'outlierKthLowestLimitAbsoluteSpacing';

  const value = useMemo(() => {
    if (outlierKthLowestStrategy === OutlierModeKthLowestStrategy.Relative) {
      return outlierKthLowestLimitRelativeSpacing;
    }
    return outlierKthLowestLimitAbsoluteSpacing;
  }, [
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestStrategy,
  ]);

  const hasChange = useMemo(() => {
    if (outlierKthLowestStrategy === OutlierModeKthLowestStrategy.Relative) {
      return (
        outlierKthLowestLimitRelativeSpacing !==
        formState.defaultValues?.outlierKthLowestLimitRelativeSpacing
      );
    }
    return (
      outlierKthLowestLimitAbsoluteSpacing !==
      formState.defaultValues?.outlierKthLowestLimitAbsoluteSpacing
    );
  }, [
    formState.defaultValues?.outlierKthLowestLimitAbsoluteSpacing,
    formState.defaultValues?.outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,
    outlierKthLowestStrategy,
  ]);

  const onUpdateOutlierSpacing = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let value = parseInt(e.target.value);
      if (value < 0) {
        value = 0;
      } else if (value > 100) {
        value = 100;
      }

      if (!isNaN(value)) {
        if (
          outlierKthLowestStrategy === OutlierModeKthLowestStrategy.Relative
        ) {
          onOutlierChange({
            outlierModeNew: AutoPricingOutlierMode.KthLowest,
            outlierKthLowestLimitAbsoluteSpacingNew:
              formState.defaultValues?.outlierKthLowestLimitAbsoluteSpacing ??
              null,
            outlierKthLowestLimitRelativeSpacingNew: value,
          });
        } else {
          onOutlierChange({
            outlierModeNew: AutoPricingOutlierMode.KthLowest,
            outlierKthLowestLimitAbsoluteSpacingNew: value,
            outlierKthLowestLimitRelativeSpacingNew:
              formState.defaultValues?.outlierKthLowestLimitRelativeSpacing ??
              null,
          });
        }
      }
    },
    [
      formState.defaultValues?.outlierKthLowestLimitAbsoluteSpacing,
      formState.defaultValues?.outlierKthLowestLimitRelativeSpacing,
      onOutlierChange,
      outlierKthLowestStrategy,
    ]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <PosFormField
        errors={
          getFieldState('outlierKthLowestLimitAbsoluteSpacing', formState)
            ?.error?.message
        }
        showErrorsInline
        className={styles.formFieldContainer}
      >
        <PosTextField
          className={styles.formTextFiled}
          value={value ?? ''}
          disabled={isDisabled}
          type="number"
          min={0}
          max={100}
          onChange={onUpdateOutlierSpacing}
          postfixDisplay={
            <>
              <ToggleGroup
                className={styles.formSuffixGroups}
                disabled={isDisabled}
                options={OUTLIER_MODE_KTH_LOWEST_STRATEGY}
                value={
                  outlierKthLowestStrategy ??
                  OutlierModeKthLowestStrategy.Relative
                }
                onValueChange={(value) => {
                  if (!value) {
                    return;
                  }
                  setOutlierKthLowestStrategy(
                    value as OutlierModeKthLowestStrategy
                  );
                }}
              />
              {!isDisabled && hasChange ? (
                <Stack direction="row" gap="s">
                  <CheckIcon
                    title={saveMsg}
                    withHoverEffect
                    size={vars.iconSize.s}
                    fill={IconsFill.textSuccess}
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue('isSubmittingPricingSettings', true);
                      setValue('isSubmittingRowIndex', rowIndex);
                      setValue(
                        'submittingField',
                        'outlierKthLowestLimitAbsoluteSpacing'
                      );
                    }}
                  />
                  <CrossIcon
                    title={cancelMsg}
                    withHoverEffect
                    size={vars.iconSize.s}
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue(
                        'outlierKthLowestLimitAbsoluteSpacing',
                        formState.defaultValues
                          ?.outlierKthLowestLimitAbsoluteSpacing ?? null
                      );
                      setValue(
                        'outlierKthLowestLimitRelativeSpacing',
                        formState.defaultValues
                          ?.outlierKthLowestLimitRelativeSpacing ?? null
                      );
                    }}
                  />
                </Stack>
              ) : isSubmittingField ? (
                <div className="operation-button">
                  <RotatingWrapper>
                    <ProcessingIcon size={vars.iconSize.s} />
                  </RotatingWrapper>
                </div>
              ) : undefined}
            </>
          }
        />
      </PosFormField>
    </TableCellDiv>
  );
};
