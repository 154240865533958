import { PosIconProps, SvgWrapper } from 'src/svgs/SvgWrapper';

export function ChevronRightIcon(props: PosIconProps) {
  return (
    <SvgWrapper
      aria-label="ChevronRightIcon"
      fill={props.fill || 'currentColor'}
      {...props}
      viewBox="0 0 10 16"
    >
      <path d="M9.813 7.429q0 .464-.33.813l-5.821 5.813q-.33.33-.804.33t-.804-.33l-.679-.67q-.33-.348-.33-.813 0-.473.33-.804l4.339-4.339-4.339-4.33q-.33-.348-.33-.813 0-.473.33-.804l.679-.67q.321-.339.804-.339t.804.339l5.821 5.813q.33.33.33.804z" />
    </SvgWrapper>
  );
}
