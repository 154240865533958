import { RefObject, useRef } from 'react';

export type AnimateScrollDirection = 'up' | 'down' | 'left' | 'right';

export type AnimateScrollTimingFn = (elapsed: number) => number;

/**
 * Timing function that slightly speeds up the scroll as time goes on.
 * @param elapsed
 * @returns `elapsed^1.05`
 */
export const defaultTimingFn: AnimateScrollTimingFn = (elapsed: number) =>
  Math.pow(elapsed, 1.05);

/**
 * Hook for animating scrolling in a given direction.
 * @param ref
 * @param options
 * @returns
 */
export function useAnimateScroll(
  ref: RefObject<HTMLElement>,
  options?: {
    /**
     * Pixels per second.
     */
    pxPerSecond?: number;
    /**
     * This is used to modify the speed of the scroll.
     *
     * eg. If you return `Math.pow(elapsed, 1.1)`, this speeds up the scroll as you continue
     * scrolling.
     * @param elapsed Time elapsed in milliseconds
     * @returns Modified time elapsed in milliseconds
     */
    timingFn?: (elapsed: number) => number;
  }
) {
  const { pxPerSecond = 500, timingFn = defaultTimingFn } = options ?? {};

  const scrollDirection = useRef<AnimateScrollDirection>();
  const rafHandle = useRef<number>();
  const startScrollState = useRef<{ scrollTop: number; scrollLeft: number }>();
  const startTimestamp = useRef<number>();
  const prevTimestamp = useRef<number>();

  const animateScroll = (timestamp: number) => {
    if (ref.current) {
      const { scrollTop: startScrollTop, scrollLeft: startScrollLeft } =
        startScrollState.current ??
        (startScrollState.current = {
          scrollTop: ref.current.scrollTop,
          scrollLeft: ref.current.scrollLeft,
        });

      if (prevTimestamp.current !== timestamp) {
        const elapsed =
          timestamp -
          (startTimestamp.current ?? (startTimestamp.current = timestamp));
        const offset = pxPerSecond * (timingFn(elapsed) / 1000);
        switch (scrollDirection.current) {
          case 'up':
            ref.current.scrollTop = Math.max(startScrollTop - offset, 0);
            break;
          case 'down':
            {
              const maxScrollTop =
                ref.current.scrollHeight - ref.current.clientHeight;
              ref.current.scrollTop = Math.min(
                startScrollTop + offset,
                maxScrollTop
              );
            }
            break;
          case 'left':
            ref.current.scrollLeft = Math.max(startScrollLeft - offset, 0);
            break;
          case 'right':
            {
              const maxScrollLeft =
                ref.current.scrollWidth - ref.current.clientWidth;
              ref.current.scrollLeft = Math.min(
                startScrollLeft + offset,
                maxScrollLeft
              );
            }
            break;
        }
        prevTimestamp.current = timestamp;
      }
    }

    if (scrollDirection.current !== undefined) {
      rafHandle.current = requestAnimationFrame(animateScroll);
    }
  };

  return {
    scrollDirection,
    startScroll(direction: AnimateScrollDirection) {
      scrollDirection.current = direction;
      rafHandle.current = requestAnimationFrame(animateScroll);
    },
    endScroll() {
      if (rafHandle.current !== undefined) {
        cancelAnimationFrame(rafHandle.current);
      }
      scrollDirection.current = undefined;
      rafHandle.current = undefined;
      startScrollState.current = undefined;
      startTimestamp.current = undefined;
      prevTimestamp.current = undefined;
    },
  };
}
