import { formatInTimeZone } from 'date-fns-tz';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import { ActivityLogDateCellDiv } from '../ActivityLogTable.styled';

export function ActivityLogDateRangeCell({
  dateFrom,
  dateTo,
}: {
  dateFrom: Date | null;
  dateTo: Date | null;
}) {
  const { timeZone } = useSiteTimezoneContext();

  const formatDate = (date: Date | null) =>
    date ? (
      <strong>{formatInTimeZone(date, timeZone, 'LLL do yyyy')}</strong>
    ) : (
      <Content id={ContentId.NA} />
    );

  if (dateFrom?.toDateString() === dateTo?.toDateString()) {
    const date = dateFrom || dateTo;
    return <ActivityLogDateCellDiv>{formatDate(date)}</ActivityLogDateCellDiv>;
  }

  return (
    <Stack direction="column">
      <ActivityLogDateCellDiv>{formatDate(dateFrom)}</ActivityLogDateCellDiv>
      <ActivityLogDateCellDiv>{formatDate(dateTo)}</ActivityLogDateCellDiv>
    </Stack>
  );
}
