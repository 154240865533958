import { ComponentProps, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import {
  UploadDocumentInput,
  UploadDocumentInputForm,
} from 'src/components/Input/UploadDocumentInput';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { ImageMagnifier } from 'src/core/POS/ImageMagnifier';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  DocumentType,
  ListingClient,
  ListingDetails,
  UserDocumentLinks,
} from 'src/WebApiController';

export type ProofOfPurchaseUploadDilaogProps = ComponentProps<
  typeof RSModal
> & {
  onCancel: () => void;
  proofDocs?: UserDocumentLinks[] | null;
};

export function ProofOfPurchaseUploadDilaog({
  onClosed,
  onCancel,
  proofDocs,
  ...rest
}: ProofOfPurchaseUploadDilaogProps) {
  const methods = useForm<UploadDocumentInputForm>();

  const { posEntity: listing, setActivePosEntity } =
    useActivePosEntityContext<ListingDetails>();
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isLoading, setIsLoading] = useState(false);

  const onUploadProofOfPurchase = useCallback(() => {
    const uploadDocs = methods.getValues()?.uploadProofDocuments;
    if (!listing?.id || !uploadDocs?.length) {
      return;
    }

    setIsLoading(true);
    tryInvokeApi(
      async () => {
        const result = await new ListingClient(
          activeAccountWebClientConfig
        ).updateProofOfPurchaseForListing(listing.id!, uploadDocs!);

        if (!isSuccess(result)) {
          showErrorDialog(
            'updateProofOfPurchaseForListing',
            { status: result.status, message: result.message },
            { trackErrorData: uploadDocs }
          );
        } else {
          await setActivePosEntity(listing.id, listing.idOnMkp, true);
        }
        onClosed?.();
      },
      (error) => {
        showErrorDialog('PurchaseClient.importPurchaseFromFile', error, {
          trackErrorData: { uploadDocs },
        });
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [
    activeAccountWebClientConfig,
    listing?.id,
    listing?.idOnMkp,
    methods,
    onClosed,
    setActivePosEntity,
    showErrorDialog,
  ]);

  return (
    <GenericDialog
      {...rest}
      size="xl"
      header={<Content id={ContentId.UploadProofOfPurchase} />}
      footer={
        <>
          <CancelButton onClick={onCancel} textContentId={ContentId.Cancel} />
          <OkButton
            disabled={
              isLoading || !methods.getValues().uploadProofDocuments?.length
            }
            onClick={() => {
              onUploadProofOfPurchase();
            }}
            textContentId={ContentId.Save}
          />
        </>
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <Stack direction="column" gap="l" width="full">
        <FormProvider {...methods}>
          <UploadDocumentInput
            entityId={listing!.id!}
            entityType={ActionOutboxEntityType.Listing}
            setIsSaveDisabled={setIsLoading}
            documentType={DocumentType.ListingVerification}
            uploadInstruction={
              <Content id={ContentId.ProofOfPurchasedInstruction} />
            }
          />
        </FormProvider>{' '}
        {!methods.getValues().uploadProofDocuments?.length &&
          proofDocs?.map((d) => (
            <p key={d.documentId}>
              <ImageMagnifier imageSrc={d.pageUri} magnifierSize={100} />
            </p>
          ))}
      </Stack>
    </GenericDialog>
  );
}
