import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ForwardIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        fill="inherit"
        d="M6.34 4.498L8.673 2l9.33 9.993-.006.007.006.007L8.673 22 6.34 19.502 13.344 12z"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
