import { useMemo } from 'react';
import { VendorIdQueryParam } from 'src/utils/constants/constants';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';

export const useGetVendorIdFromUrl = (): number | undefined => {
  return useMemo(() => {
    const vendorId = getDeepLinkIdFromUrl(
      VendorIdQueryParam,
      window.location.href
    );
    return vendorId ? Number(vendorId) : undefined;
  }, []);
};
