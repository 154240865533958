import * as styles from './EventEntityHeader.css';
import { HeaderContent } from './EventEntityHeader.styled';

export const EventEntityHeader = ({ title }: { title: React.ReactNode }) => {
  return (
    <div className={styles.headerWrapper}>
      <HeaderContent>
        <h2 className={styles.headerTitle}>{title}</h2>
      </HeaderContent>
    </div>
  );
};
