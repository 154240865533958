import React, { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Switch } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { posField } from 'src/utils/posFieldUtils';
import { ListingDetailsAutoPricingSectionUpdatesV2 } from 'src/WebApiController';

import {
  AutoPricingSettingsInput,
  PricingSettingsComponent,
} from '../BulkEditAutoPricingSettingsDialog.types';
import { useAutoPricingSettings } from '../useBulkAutoPricingSettings';
import { PricingSetting } from './PricingSetting';

type AutoPricingEnabledSettingProps = {
  origin: Pick<AutoPricingSettingsInput, 'autoPricingEnabled'>;
};

export const AutoPricingEnabledSetting: React.FC<
  AutoPricingEnabledSettingProps
> = ({ origin }) => {
  const { header, settings, hasConflict, hasChange, onReset } =
    useAutoPricingEnabled(origin);

  return (
    <PricingSetting
      header={header}
      detail={settings}
      hasConflict={hasConflict}
      hasChange={hasChange}
      onReset={onReset}
    />
  );
};

export const useAutoPricingEnabled = (
  origin: Pick<AutoPricingSettingsInput, 'autoPricingEnabled'>
): PricingSettingsComponent => {
  const { autoPricingEnabled } = useAutoPricingSettings();

  const { setValue } =
    useFormContext<ListingDetailsAutoPricingSectionUpdatesV2>();

  const hasChange = useMemo(
    () => origin.autoPricingEnabled?.value !== autoPricingEnabled?.value,
    [autoPricingEnabled?.value, origin.autoPricingEnabled?.value]
  );

  const onReset = useCallback(() => {
    setValue(
      'autoPricingEnabled',
      posField(origin.autoPricingEnabled.value, false)
    );
  }, [setValue, origin.autoPricingEnabled?.value]);

  const onUpdateAutoPricingEnabled = useCallback(
    (isChecked: boolean) => {
      if (isChecked !== autoPricingEnabled?.value) {
        const changed = isChecked !== origin.autoPricingEnabled?.value;
        setValue('autoPricingEnabled', posField(isChecked, changed));
      }
    },
    [autoPricingEnabled?.value, origin.autoPricingEnabled?.value, setValue]
  );

  return {
    header: <Content id={ContentId.EnableAutoPricing} />,
    settings: (
      <Switch
        checked={Boolean(autoPricingEnabled?.value)}
        onChange={(e) => e.stopPropagation()}
        onCheckedChange={onUpdateAutoPricingEnabled}
      />
    ),
    fromDisplay: origin.autoPricingEnabled?.value + '',
    toDisplay: autoPricingEnabled?.value + '',
    hasConflict: origin.autoPricingEnabled.hasConflict,
    hasChange,
    onReset,
  };
};
