import { UploadPredeliveryAction } from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/purchaseTicketsFlow.utils';
import {
  Event,
  Performer,
  TicketGroupEditReason,
  Venue,
} from 'src/WebApiController';

export const Steps = {
  PurchaseVendorInfoStep: 'PurchaseVendorInfoStep',
  PurchaseWizardMainStep: 'PurchaseWizardMainStep',
  TicketGroupInputStep: 'TicketGroupInputStep',
  TicketGroupBlankInputStep: 'TicketGroupBlankInputStep',
} as const;

export const AddActions = [
  TicketGroupEditReason.EditEvent,
  TicketGroupEditReason.ExchangeTickets,
  TicketGroupEditReason.AddTickets,
];

export const TicketGroupInputSteps: (keyof typeof Steps)[] = [
  Steps.TicketGroupInputStep,
  Steps.TicketGroupBlankInputStep,
];

export type AmendActionData = {
  uploadPredeliveryAction: UploadPredeliveryAction | undefined;
};

export type TicketGroupEditContextAdditionalData = {
  event?: Event | null;
  performer?: Performer | null;
  venue?: Venue | null;
  amendActionData?: AmendActionData;
};

export type TicketGroupEditContext = {
  startWithEventSearch: boolean;
  ticketGroupId: number;
  ticketGroupIdToRemove?: number; // in the case of exchange
  editIntention: TicketGroupEditReason;
  editInfo: string;
  additionalData?: TicketGroupEditContextAdditionalData;
};
