import { useEffect, useMemo, useState } from 'react';
import { SidePanelFilters } from 'src/components/Events/EventPage/SidePanel/SidePanelFilters';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { QuickFilter, useQuickFilters } from 'src/hooks/useQuickFilters';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DateRangePresetName,
  getDateRangePresets,
} from 'src/utils/dateTimeUtils';
import {
  DateTimeRangeWithRelative,
  SaleQuery,
  UserSetting,
} from 'src/WebApiController';

import { useDefaultSaleFilters } from './hooks/useDefaultSaleFilters';

export function SaleSideTableFilterBar() {
  const {
    filterQuery: saleQuery,
    initialQuery,
    setFilterQuery,
    setTempQuery,
  } = useFilterQueryContext<SaleQuery>();

  useEffect(() => {
    setTempQuery(saleQuery);
  }, [saleQuery, setTempQuery]);

  const defaultQuickFilters = useDefaultSaleFilters({
    saleQuery,
    initialQuery,
  });

  const { quickFilters } = useQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStateSales,
    customQuickFiltersSetting: UserSetting.QuickFiltersCustomSales,
    defaultQuickFilters,
  });

  return (
    <SidePanelFilters
      setFilterQuery={setFilterQuery}
      quickFilters={quickFilters}
      initialQuery={initialQuery}
    />
  );
}
