import React, {
  createContext,
  Fragment,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import { splitFormatContent } from 'src/utils/contentUtils';
import { memoryStore, sessionStorageStore } from 'src/utils/stores';

const CONTENT_NOT_FOUND_ENTITY = '__CONTENT_NOT_FOUND__';

export type ContentIdAndDefaultValue = { id: string; defaultValue?: string };
export type ContentResolverParams =
  | ContentIdAndDefaultValue
  | ContentIdAndDefaultValue[];

export interface IContentContext {
  contentCache: {
    hasValue: (id: string) => boolean;
    get: (id: string) => string | undefined;
  };
  contentResolver: (
    args: ContentResolverParams
  ) => string | string[] | undefined;
  formattedContentResolver: (
    id: string,
    params?: string | React.ReactNode | string[] | React.ReactNode[],
    defaultValue?: string
  ) => string | React.ReactNode | undefined;
  isLoading?: boolean;
}

export type IContentProvider = Pick<
  IContentContext,
  'contentCache' | 'isLoading'
> & {
  contentResolver: (args: ContentResolverParams) => string | string[];
};

const emptyContentResolver = (args: ContentResolverParams) => {
  const { defaultValue, id } = Array.isArray(args) ? args[0] : args;
  return defaultValue || id;
};

const emptyAppContext: IContentContext = {
  isLoading: false,
  contentCache: {
    hasValue: () => false,
    get: (id: string) => id || undefined,
  },
  contentResolver: emptyContentResolver,
  formattedContentResolver: (_, params, defaultValue) =>
    format(defaultValue || _, params),
};

const ContentContext = createContext<IContentContext>(emptyAppContext);

const store = sessionStorageStore.isEnabled()
  ? sessionStorageStore
  : memoryStore;

export const useContentContext = () => useContext(ContentContext);

export const ContentProvider = ({
  isLoading,
  contentCache,
  children,
}: PropsWithChildren<IContentProvider>) => {
  // TODO - hook-up with TelemetryProvider
  //const logger = useTelemetryLogger();
  const cacheResolver = useCallback(
    (args: ContentResolverParams) => {
      const isArray = Array.isArray(args);
      const contentsArray = isArray ? args : [args];
      const allCached = contentsArray.every(({ id }) =>
        contentCache.hasValue(id)
      );
      if (!allCached) {
        //log info on uncached content ids to telemetry
        const invalidContentIDs =
          store.get<string[]>('invalidContentIDs', CONTENT_NOT_FOUND_ENTITY) ||
          [];
        contentsArray.forEach(({ id }) => {
          if (!contentCache.hasValue(id)) {
            if (!invalidContentIDs.includes(id)) {
              //logger.log('Content not found in cache for content ID:  ' + id);
              invalidContentIDs.push(id);
            }
          }
        });
        store.set(
          'invalidContentIDs',
          CONTENT_NOT_FOUND_ENTITY,
          invalidContentIDs
        );
      }

      return isArray
        ? contentsArray.map(
            ({ id, defaultValue }) => contentCache.get(id) || defaultValue || id
          )
        : // comment out the first 2 conditions locally to find ids that have yet to be translated
          contentCache.get(args.id) || args.defaultValue || args.id;
    },
    [contentCache]
  );

  return (
    <ContentContext.Provider
      value={{
        isLoading,
        contentCache,
        contentResolver: cacheResolver,
        formattedContentResolver: (
          id: string,
          params:
            | string
            | React.ReactNode
            | string[]
            | React.ReactNode[]
            | undefined,
          defaultValue
        ) => {
          const value = cacheResolver({ id, defaultValue }) as string;
          return format(value, params);
        },
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};

const format = (
  rawValue: string | undefined,
  args: string | React.ReactNode | string[] | React.ReactNode[] | undefined
): string | React.ReactNode | undefined => {
  try {
    let formattedValue = rawValue;
    if (formattedValue === undefined) {
      return formattedValue;
    }

    const params =
      args === undefined ? [] : Array.isArray(args) ? args : [args];

    const hasReactElement = params.some((p) => React.isValidElement(p));
    if (hasReactElement) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let parts = splitFormatContent(formattedValue) as any[];
      for (let i = 0; i < params.length; i++) {
        parts = parts.map((p) => (p === `{${i}}` ? params[i] : p));
      }

      return (
        <span>
          {parts.map((p, i) => (
            <Fragment key={i}>{p} </Fragment>
          ))}
        </span>
      );
    } else {
      for (let i = 0; i < params.length; i++) {
        formattedValue = formattedValue?.replaceAll(
          `{${i}}`,
          params[i] as string
        );
      }

      return formattedValue;
    }
  } catch (e) {
    return '';
  }
};
