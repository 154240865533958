import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { FormPage } from 'src/core/POS/FormPage';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { CheckboxContainer, FormRow } from 'src/modals/common';
import { SeatSaverIcon } from 'src/svgs';
import { IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { SeatSaverListingDetailsInputForm } from '../SeatSaverListingWizard.types';

export const ListingSection = ({
  inputIndeces,
  disabled,
}: {
  inputIndeces: number[];
  disabled?: boolean;
}) => {
  const { watch, setValue } =
    useFormContext<SeatSaverListingDetailsInputForm>();

  const isMobile = useMatchMedia('mobile');

  // Of these will have same value, so we just need one
  const autoBroadcastCreatedListing = watch(
    `inputs.${inputIndeces[0]}.autoBroadcastCreatedListing`
  );

  const sellerOwnTickets = watch(`inputs.${inputIndeces[0]}.sellerOwnTickets`);

  return (
    <FormPage.Section
      icon={
        <SeatSaverIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
      }
      title={<Content id={ContentId.Listing} />}
    >
      <FormRow vertical={isMobile}>
        <CheckboxContainer>
          <PosFormField>
            <Checkbox
              labelPosition="right"
              label={<Content id={ContentId.AutoBroadcastNewListing} />}
              disabled={disabled}
              checked={autoBroadcastCreatedListing ?? false}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked !== autoBroadcastCreatedListing) {
                  inputIndeces.forEach((i) => {
                    setValue(
                      `inputs.${i}.autoBroadcastCreatedListing`,
                      isChecked
                    );
                  });
                }
              }}
            />
          </PosFormField>
        </CheckboxContainer>
        <CheckboxContainer>
          <PosFormField>
            <Checkbox
              labelPosition="right"
              label={<Content id={ContentId.SellerOwnTickets} />}
              disabled={disabled}
              checked={sellerOwnTickets ?? false}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked !== sellerOwnTickets) {
                  inputIndeces.forEach((i) => {
                    setValue(`inputs.${i}.sellerOwnTickets`, isChecked);
                  });
                }
              }}
            />
          </PosFormField>
        </CheckboxContainer>
      </FormRow>
    </FormPage.Section>
  );
};
