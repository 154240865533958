import { useMemo } from 'react';
import { isCancelledState } from 'src/utils/purchaseUtils';
import { PurchaseOrder } from 'src/WebApiController';

import * as styles from './PurchaseTableColumnsConfig.css';

type PurchaseCellProps = Pick<
  PurchaseOrder,
  'vendor' | 'vendOrdId' | 'poState'
>;

export function PurchaseIdCell({
  vendor,
  vendOrdId,
  poState,
}: PurchaseCellProps) {
  const isCancelled = isCancelledState(poState);

  const titleText = useMemo(() => {
    let text = `#${vendOrdId}`;
    if (vendor) {
      text += ` - ${vendor.name}`;
    }
    return text;
  }, [vendOrdId, vendor]);

  return (
    <div className={styles.styledCellRow}>
      <div className={styles.styledCell} title={titleText}>
        <span className={styles.noWrap}>#{vendOrdId}</span>
        <span className={styles.noWrap}>{vendor?.name}</span>
      </div>
      {isCancelled && (
        <div className={styles.purchaseOrderLabel} title={poState}>
          {poState}
        </div>
      )}
    </div>
  );
}
