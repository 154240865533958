import { vars } from 'src/core/themes';
import styled from 'styled-components';

export const SubfilterContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: ${() => vars.spacing['sm']};
  flex-grow: 1;
  background: ${(props) =>
    props.isSelected ? '#f1ebfa' : props.theme.colors.backgroundSecondary};
  border: ${(props) =>
    props.isSelected ? '1px solid #3F1D75' : '1px solid transparent'};
  border-radius: ${() => vars.borderRadius.m};
  padding: ${() => vars.spacing['3xl']};
  cursor: pointer;
  &:hover {
    background: #f1ebfa;
    border-color: ${(props) => (props.isSelected ? undefined : '#f1ebfa')};
  }
`;
