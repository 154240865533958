import { Row, Table as ReactTable } from '@tanstack/react-table';
import { useMemo } from 'react';
import { PosTableData } from 'src/tables/Table';

interface UseGetVirtuosoDataProps<T extends PosTableData> {
  table: ReactTable<T>;
}

interface VirtuosoGroupData<T extends PosTableData> {
  allSingleRows: Row<T>[]; // No grouped rows, just single rows with data
  rowHeaderGroups: Row<T>[]; // Just grouped rows

  // Array of numbers where each number represents the amount of rows
  // in a group
  groupCounts: number[];
}

export const useGetVirtuosoGroups = <T extends PosTableData>({
  table,
}: UseGetVirtuosoDataProps<T>) => {
  // Only use it for getting the arrays of single rows and
  // the array of header rows
  const groupedTableRows = table.getRowModel().rows;

  return useMemo<VirtuosoGroupData<T>>(() => {
    const rowGroups: Row<T>[][] = [];
    const rowHeaderGroups: Row<T>[] = [];
    const groupCounts: number[] = [];

    // Use it for accessing current rendering single row
    const allSingleRows: Row<T>[] = [];

    const map: Record<string, Row<T>[]> = {};
    for (const row of groupedTableRows) {
      // id: grouping row ID (when it's parent, parentId is undefined)
      // parentId: single row parent ID
      const { id, parentId, groupingValue } = row;

      const groupingId = parentId || id;
      map[groupingId] = map[groupingId] || [];
      const localRowGroups: Row<T>[] = map[groupingId];

      const isSingleRow = !row.getIsGrouped();

      // Only keep
      if (isSingleRow) {
        localRowGroups.push(row);
        allSingleRows.push(row);
      } else {
        rowHeaderGroups.push(row);
      }

      if (!rowGroups.includes(localRowGroups)) {
        rowGroups.push(localRowGroups);
      }
    }

    for (const group of rowGroups) {
      groupCounts.push(group.length);
    }

    return { groupCounts, rowHeaderGroups, allSingleRows };
  }, [groupedTableRows]);
};
