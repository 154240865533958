import { format } from 'date-fns';
import { ComponentProps, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { convertDateToTemporalPlainDateTime } from 'src/utils/dateTimeUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  CatalogClient,
  EventMappingRequest,
  ValueTupleOfEventAndVenueAndPerformer,
} from 'src/WebApiController';

import { FieldWrapper } from '../../modals/common/Purchase';

export type RequestEventDialogFieldValues = Omit<
  EventMappingRequest,
  'eventDate'
> & {
  eventDate: Date;
};

export type RequestEventDialogProps = ComponentProps<typeof RSModal> & {
  onSave: (data: ValueTupleOfEventAndVenueAndPerformer) => void;
  onCancel: () => void;
};

export function RequestEventDialog({
  onSave,
  onCancel,
  ...dialogProps
}: RequestEventDialogProps) {
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm<RequestEventDialogFieldValues>();
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const curLocale = getLocaleFromLanguageOrCurrent();
  const onSaveHandler = useCallback<Parameters<typeof handleSubmit>[0]>(
    async ({ eventDate, ...data }) => {
      setIsLoading(true);
      const requestedEvent = {
        ...data,
        eventDate: convertDateToTemporalPlainDateTime(eventDate).toString(),
      };
      await tryInvokeApi(
        async () => {
          const results = await new CatalogClient(
            activeAccountWebClientConfig
          ).requestEventMapping(requestedEvent);

          if (results) {
            // Set the search query so it set the event to be displayed
            onSave(results);
          }
        },
        (error) => {
          showErrorDialog('CatalogClient.requestEventMapping', error, {
            trackErrorData: requestedEvent,
          });
        },
        () => setIsLoading(false)
      );
    },
    [activeAccountWebClientConfig, onSave, showErrorDialog]
  );

  return (
    <GenericDialog
      size="md"
      header={<Content id={ContentId.RequestEvent} />}
      footer={
        <>
          <CancelButton onClick={onCancel} disabled={isLoading} />
          <Button
            variant={'regular'}
            disabled={isLoading}
            onClick={handleSubmit(onSaveHandler)}
          >
            <Content id={ContentId.RequestEvent} />
          </Button>
        </>
      }
      {...dialogProps}
      onCancel={onCancel}
    >
      {isLoading && <PosSpinner />}
      {!isLoading && (
        <Stack direction="column" gap="l">
          <PosFormField
            errors={errors?.eventName?.message}
            label={<Content id={ContentId.EventName} />}
          >
            <PosTextField
              rootProps={{
                state: getTextFieldState(errors?.eventName),
              }}
              {...register('eventName', {
                required: 'Required',
              })}
            />
          </PosFormField>
          <FieldWrapper>
            <Stack gap="m" alignItems="center">
              <PosFormField
                errors={errors?.eventDate?.message}
                label={<Content id={ContentId.EventDate} />}
              >
                <Controller
                  control={control}
                  name="eventDate"
                  rules={{ required: 'Required' }}
                  render={({ field: { ...field } }) => (
                    <DatePickerInput
                      fieldError={errors.eventDate}
                      locale={getLocaleFromLanguageOrCurrent()}
                      disabledDay={
                        (d) =>
                          d <
                          new Date() /* Event date should always be in the future */
                      }
                      {...field}
                      onDateChange={(date) => {
                        const currentDate = field.value;
                        date.setHours(currentDate?.getHours() ?? 0);
                        date.setMinutes(currentDate?.getMinutes() ?? 0);
                        field.onChange(date);
                      }}
                    />
                  )}
                />
              </PosFormField>
              <PosFormField
                errors={errors?.eventDate?.message}
                label={<Content id={ContentId.EventTime} />}
              >
                <Controller
                  control={control}
                  name="eventDate"
                  rules={{ required: 'Required' }}
                  render={({ field: { value, ...field } }) => (
                    <PosTextField
                      type="time"
                      rootProps={{
                        state: getTextFieldState(errors.eventDate),
                      }}
                      value={
                        (value &&
                          format(value, 'HH:mm', {
                            locale: curLocale,
                          })) ||
                        undefined
                      }
                      onChange={(e) => {
                        const date = (value && new Date(value)) || new Date();
                        const time = e.target.value.split(':');
                        date.setHours(parseInt(time[0]));
                        date.setMinutes(parseInt(time[1]));
                        field.onChange(date);
                      }}
                    />
                  )}
                />
              </PosFormField>
            </Stack>
          </FieldWrapper>
          <PosFormField
            errors={errors?.venueName?.message}
            label={<Content id={ContentId.VenueName} />}
          >
            <PosTextField
              {...register('venueName', {
                required: 'Required',
              })}
            />
          </PosFormField>
          <PosFormField
            errors={errors?.city?.message}
            label={<Content id={ContentId.City} />}
          >
            <PosTextField
              rootProps={{
                state: getTextFieldState(errors?.city),
              }}
              {...register('city', {
                required: 'Required',
              })}
            />
          </PosFormField>
          <PosFormField
            errors={errors?.stateProvince?.message}
            label={<Content id={ContentId.StateProvince} />}
          >
            <PosTextField
              rootProps={{
                state: getTextFieldState(errors?.stateProvince),
              }}
              {...register('stateProvince', {
                required: 'Required',
              })}
            />
          </PosFormField>
          <PosFormField
            errors={errors?.countryCode?.message}
            label={<Content id={ContentId.Country} />}
          >
            <PosTextField
              rootProps={{
                state: getTextFieldState(errors?.countryCode),
              }}
              {...register('countryCode', {
                required: 'Required',
              })}
            />
          </PosFormField>
        </Stack>
      )}
    </GenericDialog>
  );
}
