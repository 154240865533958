import { ColumnDef } from '@tanstack/react-table';
import { Content } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from '../SectionRowTableDialog.css';
import { EnrichedScoreOrverride } from '../SectionRowTableDialog.hooks';

export const indexFromCellId = (id?: string) =>
  parseInt(id?.split('_')?.[1] || '0');

export type SectionRowColumnType = {
  section: string;
  sectionId: number;
  rows: EnrichedScoreOrverride[];
  unordered: EnrichedScoreOrverride[];
};

const ordinalWithSuffix = (num: number) => {
  const remaining = num % 10,
    hundreds = num % 100;
  if (remaining === 1 && hundreds !== 11) {
    return num + 'st';
  }
  if (remaining === 2 && hundreds !== 12) {
    return num + 'nd';
  }
  if (remaining === 3 && hundreds !== 13) {
    return num + 'rd';
  }
  return num + 'th';
};

const getRowColumns = (maxRowCount: number) => {
  const columns: ColumnDef<SectionRowColumnType>[] = [];
  for (let index = 1; index <= maxRowCount; index++) {
    columns.push({
      id: `${index}`,
      header: () => (
        <TableHeaderCellDiv align="center" className={styles.tableHeader}>
          {ordinalWithSuffix(index)}
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.rows?.[index - 1],
      cell: ({ getValue, row: { original } }) => {
        const row = getValue() as EnrichedScoreOrverride | undefined;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        const rowName = row?.rowName ?? null;
        return (
          <TableCellDiv align="center" style={{ textTransform: 'none' }}>
            {rowName}
          </TableCellDiv>
        );
      },
      enableSorting: false,
    });
  }

  return columns;
};

export const getSectionColumns = (
  rowCount: number
): ColumnDef<SectionRowColumnType>[] => {
  return [
    {
      id: 'section',
      header: () => (
        <TableHeaderCellDiv align="center" className={styles.tableHeader}>
          <Content id={ContentId.Section} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data.section,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="center" style={{ textTransform: 'none' }}>
            {value}
          </TableCellDiv>
        );
      },
      enableSorting: false,
    } as ColumnDef<SectionRowColumnType>,
    ...getRowColumns(rowCount),
    {
      id: 'unordered',
      header: () => (
        <TableHeaderCellDiv align="center" className={styles.tableHeader}>
          <Content id={ContentId.Unordered} />
        </TableHeaderCellDiv>
      ),
      accessorFn: (data) => data?.unordered,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as EnrichedScoreOrverride[];
        if (!original) {
          return <TableShimmeringDiv />;
        }
        const display = value.map(({ rowName }) => rowName).join(',');
        return (
          <TableCellDiv align="center" style={{ textTransform: 'none' }}>
            {display}
          </TableCellDiv>
        );
      },
      enableSorting: false,
    } as ColumnDef<SectionRowColumnType>,
  ];
};
