import { HubConnectionState } from '@microsoft/signalr';
import { isEqual } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { UK_COUNTRY_CODES } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { getIsInternationalEvent } from 'src/utils/eventWithDataUtils';
import { isMarketplaceActive } from 'src/utils/userUtils';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreview,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  Event,
  ListingDetails,
  Marketplace,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';
import * as styles from './BulkEditBroadcastSettingsDialog.css';
import { MarketplaceDisplay } from './MarketplaceDisplay';

export type MarketplaceBroadcastSelectionMode = 'all' | 'none' | 'default';

export type BulkEditBroadcastSettingsDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  marketplaceBroadcastSelectionMode?: MarketplaceBroadcastSelectionMode;
  onOkay: (
    marketplacesToBroadcast: Marketplace[] | null,
    marketplacesToUnbroadcast: Marketplace[] | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreview
  ) => void;
  onCancel: () => void;
  event?: Event;
};

export function BulkEditBroadcastSettingsDialog({
  updateKey,
  marketplaceBroadcastSelectionMode = 'default',
  onOkay,
  onCancel,
  event,
  ...rest
}: BulkEditBroadcastSettingsDialogProps) {
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshExpandedListItems();
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.UpdateBroadcastSettings,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const { venue } = useGetEventFullInfo(event);

  const isUKEvent = Boolean(
    event != null &&
      venue?.country?.code &&
      UK_COUNTRY_CODES.includes(venue.country.code.toUpperCase())
  );
  const isInternationalEvent = getIsInternationalEvent(venue?.country?.code);

  const { loginContext } = useAppContext();
  const marketplaceSettings =
    loginContext?.user?.activeAccount?.marketplaceSettings;

  const marketplacesActive = useMemo(() => {
    return (
      marketplaceSettings
        ?.filter((mk) => isMarketplaceActive(mk))
        .filter(
          (mk) =>
            event == null ||
            !isInternationalEvent ||
            mk.mkp === Marketplace.StubHub
        )
        .map((mk) => mk.mkp) ?? []
    );
  }, [event, isInternationalEvent, marketplaceSettings]);

  const [marketplacesSelected, setMarketplacesSelected] =
    useState<Marketplace[]>(marketplacesActive);

  const [marketplacesToBroadcast, setMarketplacesToBroadcast] = useState<
    Marketplace[]
  >([]);
  const marketplacesToUnbroadcast = useMemo(() => {
    return (
      marketplacesSelected?.filter(
        (mk) => !marketplacesToBroadcast.includes(mk)
      ) ?? []
    );
  }, [marketplacesSelected, marketplacesToBroadcast]);

  useEffect(() => {
    if (marketplaceBroadcastSelectionMode === 'all') {
      if (!isEqual(marketplacesToBroadcast, marketplacesActive)) {
        setMarketplacesToBroadcast(marketplacesActive);
      }
      if (!isEqual(marketplacesSelected, marketplacesActive)) {
        setMarketplacesSelected(marketplacesActive);
      }
    }
    if (marketplaceBroadcastSelectionMode === 'none') {
      if (marketplacesToBroadcast.length !== 0) {
        setMarketplacesToBroadcast([]);
      }
      if (!isEqual(marketplacesSelected, marketplacesActive)) {
        setMarketplacesSelected(marketplacesActive);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceBroadcastSelectionMode]);

  const onListingBroadcastToggle = useCallback(
    (isSelected: boolean, isBroadcasting: boolean, mk: Marketplace) => {
      if (isSelected) {
        if (!marketplacesSelected.includes(mk)) {
          setMarketplacesSelected((prev) => [...prev, mk]);
        }
      } else {
        setMarketplacesToBroadcast((prev) => prev.filter((m) => m !== mk));
        setMarketplacesSelected((prev) => prev.filter((m) => m !== mk));
      }
      if (isBroadcasting) {
        if (!marketplacesToBroadcast.includes(mk)) {
          setMarketplacesToBroadcast((prev) => [...prev, mk]);
        }
      } else {
        setMarketplacesToBroadcast((prev) => prev.filter((m) => m !== mk));
      }
    },
    [marketplacesSelected, marketplacesToBroadcast]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(null, null, false, onPreviewReceived);
      } else {
        onOkay(
          marketplacesToBroadcast,
          marketplacesToUnbroadcast,
          hasBackgroundBulkEditFeature,
          undefined,
          preview!.preview
        );
      }
      setIsLoading(false);
    },
    [
      onOkay,
      marketplacesToBroadcast,
      marketplacesToUnbroadcast,
      preview,
      hasBackgroundBulkEditFeature,
    ]
  );
  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      size={stage === BulkEditStage.Preview ? 'xl' : 'lg'}
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.BroadcastSettings} />}
        />
      }
      onOpened={() => {
        initJob();
      }}
      onKeyUp={(e) => {
        if (submittButtonRef.current && e.key === 'Enter') {
          submittButtonRef.current.click();
        }
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={ActionOutboxEntityType.Listing}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={ActionOutboxEntityType.Listing}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <div className={styles.bulkEditBroadcastSettingsDialogContainer}>
          <div className={styles.fieldWrapper}>
            {marketplaceSettings
              ?.filter(
                (mk) =>
                  event == null ||
                  !isInternationalEvent ||
                  mk.mkp === Marketplace.StubHub
              )
              .map((mk) => {
                const isActive = marketplacesToBroadcast.includes(mk.mkp);
                const isSelected = marketplacesSelected.includes(mk.mkp);
                const loading =
                  isLoading ||
                  progress?.step === BulkEditStep.Initializing ||
                  progress?.step === BulkEditStep.InProgress;

                if (mk.mkp === Marketplace.StubHub) {
                  return [
                    <MarketplaceDisplay
                      key={`${mk}-${false}`}
                      marketplace={mk.mkp}
                      marketplaceActive={isMarketplaceActive(mk)}
                      disabled={loading}
                      event={event}
                      isActive={isActive}
                      isSelected={isSelected}
                      isLoading={
                        loading && progress?.step !== BulkEditStep.Done
                      }
                      isUKEvent={isUKEvent}
                      onListingBroadcastToggle={onListingBroadcastToggle}
                    />,
                    <MarketplaceDisplay
                      key={`${mk}-${true}`}
                      marketplace={mk.mkp}
                      marketplaceActive={isMarketplaceActive(mk)}
                      disabled={loading}
                      event={event}
                      isViagogo
                      isActive={isActive}
                      isSelected={isSelected}
                      isLoading={
                        loading && progress?.step !== BulkEditStep.Done
                      }
                      isUKEvent={isUKEvent}
                      onListingBroadcastToggle={onListingBroadcastToggle}
                    />,
                  ];
                }

                // event == null means we are in the "Bulk edit for all listings" flow
                return event == null || !isInternationalEvent
                  ? [
                      <MarketplaceDisplay
                        key={`${mk}-${false}`}
                        marketplace={mk.mkp}
                        marketplaceActive={isMarketplaceActive(mk)}
                        disabled={loading}
                        event={event}
                        isActive={isActive}
                        isSelected={isSelected}
                        isLoading={
                          loading && progress?.step !== BulkEditStep.Done
                        }
                        onListingBroadcastToggle={onListingBroadcastToggle}
                      />,
                    ]
                  : [];
              })}
          </div>
        </div>
      </BulkEditStatus>
    </GenericDialog>
  );
}
