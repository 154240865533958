import { PaymentsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/payment-wrapper';

export function BankAccountIcon(props: PaymentsProps) {
  return (
    <SvgWrapper {...props}>
      <g fill="none" fillRule="evenodd">
        <rect
          x={0.5}
          y={0.5}
          width={35}
          height={23}
          rx={4}
          fill="#FFF"
          stroke="#CFD4D9"
        />
        <path
          d="m18.035 6-6.652 5.118H10.5v.919h1.776v5.15H10.5v.918h15v-.918h-1.78v-4.266l-.93-.459v4.725h-2.266v-5.15H25.5v-.919h-.814L18.035 6Zm0 1.164 5.138 3.954H12.896l5.139-3.954Zm-1.596 10.023h3.155v-5.15h-3.155v5.15Zm-3.233 0h2.303v-5.15h-2.303v5.15Z"
          fill="#677383"
        />
      </g>
    </SvgWrapper>
  );
}
