export const TicketmasterLogo = ({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      fill="#006ce3"
      viewBox="0 0 332.4 47.2"
      width={width ?? 143}
      height={height ?? 20}
    >
      <g>
        <path d="M1.3,14.5h6l1.4-6.6L17.6,5l-2,9.5H23l-1.3,6.1h-7.4l-3,13.9c-0.2,0.7-0.4,1.9-0.4,3.1 c0,1.9,1.3,3.1,3.2,3.1c1.5,0,3-0.3,4-0.7l-1.4,6.4c-1.4,0.3-3,0.7-4.5,0.7c-5.6,0-9.6-2.2-9.6-8.3c0-2.5,0.5-4.9,1.1-7.4L6,20.6 H0L1.3,14.5z"></path>
        <g>
          <path d="M216.1,41.5H216c-2.8,3.6-5.7,5.6-10.3,5.6c-5.7,0-9.8-3.2-9.8-9.1c0-10.5,11.7-11.3,19.5-11.3h2.9 c0.2-0.9,0.2-1.8,0.2-2.7c0-3.2-3.5-4.1-6.4-4.1c-3.6,0-7.2,0.9-10.5,2.5l1.2-6.6c3.5-1.2,7.1-1.9,10.8-1.9 c6.4,0,12.4,2.3,12.4,9.8c0,4.3-3.2,16.8-3.9,22.8h-7L216.1,41.5 M208,41.1c6.2,0,8.1-4.3,9.4-9.4h-2.9c-4,0-10.7,0.7-10.7,6.1 C203.8,39.9,205.9,41.1,208,41.1z"></path>
          <path d="M26.4,14.5h8.3l-6.8,31.8h-8.3L26.4,14.5 M29.4,0.8h8.3L36,8.7h-8.3L29.4,0.8z"></path>
          <path d="M59.7,21.8c-1.6-1.1-3.8-1.6-6.1-1.6c-6.6,0-10.3,6.2-10.3,12.2c0,4.1,1.4,8.3,7,8.3c2,0,4.6-0.6,6.7-1.6 l-0.9,6.6c-2.5,1.2-5.3,1.5-8,1.5c-8.2,0-13.5-5.5-13.5-13.7c0-11.1,7.4-19.6,18.8-19.6c3.1,0,6.1,0.6,8.3,1.2L59.7,21.8z"></path>
          <polygon points="68.8,0 77.1,0 71.6,26.1 71.7,26.1 83.3,14.5 94,14.5 78.8,29 88.3,46.4 79,46.4 70.8,29.5 70.7,29.5 67.1,46.4 59,46.4 			"></polygon>
          <path d="M123.9,14.5h6l1.4-6.6l8.9-2.9l-2,9.5h7.4l-1.3,6.1h-7.4l-3,13.9c-0.2,0.7-0.4,1.9-0.4,3.1 c0,1.9,1.3,3.1,3.2,3.1c1.5,0,3-0.3,4-0.7l-1.4,6.4c-1.4,0.3-3,0.7-4.5,0.7c-5.6,0-9.6-2.2-9.6-8.3c0-2.5,0.5-4.9,1.1-7.4 l2.3-10.8h-6L123.9,14.5z"></path>
          <path d="M121.1,25.9c0-8.1-5.2-12.1-13-12.1C97.6,13.8,90,23.3,90,33.2c0,9.5,6.2,13.9,15.3,13.9 c3.4,0,6.9-0.8,10.1-1.8l1.1-6.6c-3.1,1.4-6.4,2.3-9.8,2.3c-5.3,0-8.4-1.9-8.8-6.9l0,0c0-0.3,0-0.6,0-0.9c0-0.1,0-0.1,0-0.2v-0.1 l0,0c0-2.2,0.5-4.3,1.3-6.3l0,0c1.6-4.1,3.8-6.9,8.7-6.9c3.5,0,5.3,1.9,5.3,5.3c0,0.7-0.1,1.4-0.2,2.2h-11.3 c-0.7,2.5-0.9,4.2-0.9,5.7h19.5C120.8,30.6,121.1,28.3,121.1,25.9z"></path>
          <path d="M149.2,18c0.2-1.2,0.5-2.5,0.7-3.5h7.7l-0.7,4.3h0.1c2.2-3.3,5.9-5,9.8-5c3.8,0,7.7,2.2,8.1,6.1h0.1 c2.1-4.1,6.7-6.1,11.2-6.1c4.8,0,8.8,3.9,8.8,8.7c0,2.8-0.7,6.1-1.4,8.7l-3.3,15.2H182l3.3-15.5c0.4-1.9,1.1-4.9,1.1-6.8 c0-2.3-1.8-3.8-3.9-3.8c-5.8,0-7.2,6.7-8.2,11L171,46.4h-8.3l3.3-15.5c0.4-1.9,1.1-4.9,1.1-6.8c0-2.3-1.8-3.8-3.9-3.8 c-5.6,0-7.2,6.7-8.2,11l-3.3,15.1h-8.3L149.2,18z"></path>
          <path d="M250.7,21c-0.9-0.5-3.2-1.2-5.5-1.2c-2.5,0-5.6,0.7-5.6,3.2c0,4,10.8,5.5,10.8,13.3 c0,8.3-7.2,10.8-14.3,10.8c-3.3,0-6.7-0.5-9.7-1.9l1.8-6.6c2.3,1.3,4.7,2.5,7.9,2.5c2.8,0,5.7-0.9,5.7-3.5 c0-5.1-10.8-5.6-10.8-13.6c0-7.5,7.2-10.1,13.8-10.1c3,0,6.1,0.6,7.8,1L250.7,21z"></path>
          <path d="M256,14.5h6l1.4-6.6l8.9-2.9l-2,9.5h7.4l-1.3,6.1H269l-3,13.9c-0.2,0.7-0.4,1.9-0.4,3.1 c0,1.9,1.3,3.1,3.2,3.1c1.5,0,3-0.3,4-0.7l-1.4,6.4c-1.4,0.3-3,0.7-4.5,0.7c-5.6,0-9.6-2.2-9.6-8.3c0-2.5,0.5-4.9,1-7.4l2.4-10.8 h-6L256,14.5z"></path>
          <path d="M312.2,18.8c0.2-1.4,0.5-2.8,0.7-4.3h7.9l-1.1,6h0.1c1.5-3.5,5.6-6.7,9.4-6.7c1.1,0,2.2,0.1,3.2,0.3 l-1.7,8.1c-0.9-0.3-2.1-0.5-3.2-0.5c-5.9,0-8.7,5.3-9.8,10.4l-3,14.3h-8.3L312.2,18.8z"></path>
          <path d="M306.5,25.9c0-8.1-5.2-12.1-13-12.1c-10.4,0-18.1,9.5-18.1,19.4c0,9.5,6.2,13.9,15.3,13.9 c3.4,0,6.9-0.8,10.1-1.8l1.1-6.6c-3.1,1.4-6.4,2.3-9.8,2.3c-5.3,0-8.4-1.9-8.8-6.9l0,0c0-0.3,0-0.6,0-0.9c0-0.1,0-0.1,0-0.2v-0.1 l0,0c0-2.2,0.5-4.3,1.3-6.3l0,0c1.6-4.1,3.8-6.9,8.7-6.9c3.5,0,5.3,1.9,5.3,5.3c0,0.7-0.1,1.4-0.2,2.2h-11.3 c-0.7,2.5-0.9,4.2-0.9,5.7h19.5C306.2,30.6,306.5,28.3,306.5,25.9z"></path>
        </g>
      </g>
    </svg>
  );
};
