import { ComponentProps, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useWindowSize } from 'react-use';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosTextArea } from 'src/core/POS/PosTextArea';
import { Button } from 'src/core/ui';
import { AddFileIcon } from 'src/svgs/AddFileIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  FileParameter,
  Marketplace,
  SellerSupportCaseClient,
} from 'src/WebApiController';

import { ButtonWithIcon } from '../../Buttons';
import { AttachmentInfo, AttachmentsPanel } from '../AttachmentsPanel';
import * as styles from './SupportCaseBody.css';

type UploadAttachmentInfo = {
  id: string;
  fileName: string;
  file?: File;
};

type SupportCaseReplyFieldValues = {
  attachmentInfo: UploadAttachmentInfo[];
  noteContent: string;
};

// 2^31-1 (2GB) is technically the maximum size allowed
// to be stored in DB. Change this if there are other
// explicit limits on content length.
const CONTENT_MAX_SIZE = Math.pow(2, 31) - 1;

export const SupportCaseReplyInput = ({
  sellerSupportCaseId,
  sellerSupportCaseNotesIsLoading,
  onReply,
  ...props
}: ComponentProps<'div'> & {
  sellerSupportCaseId: number;
  sellerSupportCaseNotesIsLoading: boolean;
  onReply?: () => void;
}) => {
  const { height: windowHeight } = useWindowSize();

  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isLoading, setLoading] = useState<boolean>(false);
  const requiredMsg = useContent(ContentId.Required);

  const defaultValues = { attachmentInfo: [], noteContent: '' };

  const { register, handleSubmit, watch, setValue, reset } =
    useForm<SupportCaseReplyFieldValues>({
      defaultValues,
    });

  const sendReply = async (
    noteContent: string,
    attachments: FileParameter[] | null
  ) => {
    tryInvokeApi(
      async () => {
        await new SellerSupportCaseClient(
          activeAccountWebClientConfig
        ).createSellerSupportCaseReply(
          sellerSupportCaseId,
          noteContent,
          Marketplace.StubHub,
          attachments
        );

        onReply?.();
      },
      (error) => {
        showErrorDialog('createSellerSupportCaseReply', error);
      }
    );
  };

  const noteContent = watch('noteContent');
  const attachments = watch('attachmentInfo');

  const inputRef = useRef<HTMLInputElement>(null);

  const onDeleteAttachment = useCallback(
    (attachmentToDelete: AttachmentInfo) => {
      const newAttachments = attachments.filter(
        (a) => a.id !== attachmentToDelete.attachmentId
      );
      setValue('attachmentInfo', newAttachments);
    },
    [attachments, setValue]
  );

  const onUploadFileClick = useCallback(() => {
    // trigger the hidden input
    inputRef?.current?.click();
  }, []);

  const onNewFileSelected = (file: File) => {
    if (attachments.every((e) => e.fileName !== file.name)) {
      const newAttachments = [
        ...attachments,
        {
          id: file.name,
          fileName: file.name,
          file: file,
        } as UploadAttachmentInfo,
      ];
      setValue('attachmentInfo', newAttachments);
    }
  };

  const onSubmit = async (data: SupportCaseReplyFieldValues) => {
    setLoading(true);
    const attachmentsContent: FileParameter[] = data.attachmentInfo
      .filter((att) => att.file)
      .map((att) => {
        return { data: att.file!, fileName: att.fileName };
      });

    await sendReply(data.noteContent, attachmentsContent);

    reset(defaultValues);
    setLoading(false);
  };

  return (
    <div {...props}>
      <div className={styles.replyInputContainer}>
        <PosTextArea
          {...register('noteContent', {
            required: requiredMsg,
          })}
          style={{ maxHeight: `${windowHeight / 4}px`, minHeight: '20px' }}
          maxLength={CONTENT_MAX_SIZE}
        />
        <div className={styles.replyBottomBarContainer}>
          <div>
            <input
              ref={inputRef}
              type="file"
              id="file-uploader"
              style={{ display: 'none' }}
              onChange={(e) => {
                const newFiles = e.target?.files;
                if (newFiles?.[0]) {
                  onNewFileSelected(newFiles[0]);
                }
              }}
            />
            <ButtonWithIcon
              icon={<AddFileIcon />}
              disabled={sellerSupportCaseNotesIsLoading || isLoading}
              onClick={onUploadFileClick}
            />
          </div>
          <Button
            disabled={
              sellerSupportCaseNotesIsLoading || isLoading || !noteContent
            }
            onClick={() => handleSubmit(onSubmit)()}
          >
            <Content id={ContentId.Reply} />
          </Button>
        </div>
      </div>
      {attachments && attachments.length > 0 && (
        <AttachmentsPanel
          attachments={attachments.map((a) => {
            return {
              attachmentId: a.id,
              name: a.fileName,
            };
          })}
          showDelete
          onDeleteClicked={onDeleteAttachment}
        />
      )}
    </div>
  );
};
