import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  UploadETicketsSection,
  UploadETicketsSectionProps,
} from 'src/modals/PurchaseWizard/PurchaseTicketsFlow/components/UploadETicketsSection';
import { UploadETicketsSectionV2 } from 'src/modals/PurchaseWizard/PurchaseTicketsFlowV2/components/UploadETicketsSectionV2';
import { Feature } from 'src/WebApiController';

type UploadETicketsVersionSelectorProps = UploadETicketsSectionProps;

export const UploadETicketsSectionSelector = ({
  ticketType,
  ticketGroupIndex,
  onCancel,
}: UploadETicketsVersionSelectorProps) => {
  const hasUploadTicketFilesFlowV2 = useUserHasFeature(
    Feature.UploadTicketFilesFlowV2
  );

  if (hasUploadTicketFilesFlowV2) {
    return (
      <UploadETicketsSectionV2
        ticketGroupIndex={ticketGroupIndex}
        onCancel={onCancel}
        ticketType={ticketType}
      />
    );
  }

  return (
    <UploadETicketsSection
      ticketGroupIndex={ticketGroupIndex}
      onCancel={onCancel}
      ticketType={ticketType}
    />
  );
};
