import { UploadBarcodesBody } from 'src/components/UploadArtifacts/UploadBarcodes/UploadBarcodesBody';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ModalBodyDataContainer } from 'src/modals/Modal/Modal.styled';
import { BarcodeIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleDetails } from 'src/WebApiController';

export const TransferTicketmasterBarcodesBody = ({
  disabled,
  instructionsContentId,
  viewOnly,
}: {
  disabled?: boolean;
  instructionsContentId: ContentId;
  viewOnly?: boolean;
}) => {
  const { posEntity: sale } = useActivePosEntityContext<SaleDetails>();

  return (
    <ModalBodyDataContainer>
      <Stack direction="column" gap="xl">
        <Content id={instructionsContentId} />
        {!viewOnly ? (
          <UploadBarcodesBody
            isReadOnly={disabled}
            ticketCnt={sale!.ticketCnt}
            seating={sale!.seating}
            instructionContentId={ContentId.EnterBarcodesForTransferIns}
          />
        ) : (
          <Stack direction="column" gap="l">
            {sale?.tickets
              ?.slice(0, sale.ticketCnt)
              ?.map((t) => (
                <PosTextField
                  key={t.id}
                  prefixDisplay={
                    <BarcodeIcon size={vars.iconSize.m} align="middle" />
                  }
                  value={t.barcode ?? ''}
                />
              ))}
          </Stack>
        )}
      </Stack>
    </ModalBodyDataContainer>
  );
};
