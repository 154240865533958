import { useCallback, useMemo, useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { Button, Stack } from 'src/core/ui';
import { CurrencyConversionOverrideDialog } from 'src/dialogs/CurrencyConversionOverrideDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useCurrencyConversionOverrides } from 'src/hooks/useCurrencyConversionOverrides';
import { ContentId } from 'src/utils/constants/contentId';
import { GridActionType } from 'src/utils/tableUtils';
import {
  CurrencyConversionOverride,
  CurrencyConversionOverrideRequest,
  PaymentMethodNameKnownRegex,
} from 'src/WebApiController';

import { VendorTableContainer } from '../VendorsTable/VendorsTableContainer.styled';
import { CurrencyConversionOverridesTable } from './CurrencyConversionOverridesTable';

const EMPTY_CURRENCY_CONVERSION_OVERRIDE_INPUT: CurrencyConversionOverrideRequest =
  {
    currencyConversionOverrideId: null,
    fromCurrencyCode: '',
    toCurrencyCode: '',
    conversionRateDate: '',
    purchasePaymentMethodId: null,
    paymentMethodNameKnownRegexId: PaymentMethodNameKnownRegex.All,
  } as CurrencyConversionOverrideRequest;

export function CurrencyConversionOverridesTableContainer({
  currencyConversionOverrides,
}: {
  currencyConversionOverrides?: CurrencyConversionOverride[] | null;
}) {
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const deleteDialog = useBasicDialog();
  const [
    currentCurrencyConversionOverride,
    setCurrentCurrencyConversionOverride,
  ] = useState<CurrencyConversionOverrideRequest>();

  const otherExistingCurrencyConversionOverrides = useMemo(() => {
    return currencyConversionOverrides?.filter(
      (c) =>
        c.currencyConversionOverrideId !==
        currentCurrencyConversionOverride?.currencyConversionOverrideId
    );
  }, [
    currencyConversionOverrides,
    currentCurrencyConversionOverride?.currencyConversionOverrideId,
  ]);

  const {
    createCurrencyConversionOverride,
    updateCurrencyConversionOverride,
    deleteCurrencyConversionOverride,
  } = useCurrencyConversionOverrides();

  const onAddNewCurrencyConversionOverride = useCallback(() => {
    setCurrentCurrencyConversionOverride(
      EMPTY_CURRENCY_CONVERSION_OVERRIDE_INPUT
    );
    launchDialog();
  }, [launchDialog]);

  const onSaveCurrencyConversionOverride = useCallback(
    async (currencyConversionOverride: CurrencyConversionOverrideRequest) => {
      if (currencyConversionOverride.currencyConversionOverrideId == null) {
        await createCurrencyConversionOverride(currencyConversionOverride);
      } else {
        await updateCurrencyConversionOverride(currencyConversionOverride);
      }
      closeDialog();
    },
    [
      closeDialog,
      createCurrencyConversionOverride,
      updateCurrencyConversionOverride,
    ]
  );

  const onCurrencyConversionOverrideCancel = useCallback(() => {
    setCurrentCurrencyConversionOverride(undefined);
    closeDialog();
  }, [closeDialog]);

  const onCurrencyConversionOverrideAction = (
    currencyConversionOverride: CurrencyConversionOverride,
    actionType: GridActionType
  ) => {
    switch (actionType) {
      case GridActionType.Edit:
        setCurrentCurrencyConversionOverride(currencyConversionOverride);
        launchDialog();
        break;
      case GridActionType.Delete:
        setCurrentCurrencyConversionOverride(currencyConversionOverride);
        deleteDialog.launchDialog();
        break;
      default:
        break;
    }
  };

  const onCurrencyConversionOverrideDelete = useCallback(async () => {
    if (
      currentCurrencyConversionOverride?.currencyConversionOverrideId != null
    ) {
      await deleteCurrencyConversionOverride(
        currentCurrencyConversionOverride?.currencyConversionOverrideId
      );
    }
    setCurrentCurrencyConversionOverride(undefined);
    deleteDialog.closeDialog();
  }, [
    currentCurrencyConversionOverride?.currencyConversionOverrideId,
    deleteDialog,
    deleteCurrencyConversionOverride,
  ]);

  return (
    <>
      <VendorTableContainer>
        <Stack direction="column" gap="m">
          <Button
            variant={'outline'}
            onClick={onAddNewCurrencyConversionOverride}
          >
            <Content id={ContentId.AddNewOverrideFxRate} />
          </Button>
          <CurrencyConversionOverridesTable
            currencyConversionOverrides={currencyConversionOverrides}
            onCurrencyConversionOverrideAction={
              onCurrencyConversionOverrideAction
            }
          />
        </Stack>
      </VendorTableContainer>
      <CurrencyConversionOverrideDialog
        {...dialogProps}
        currencyConversionOverride={currentCurrencyConversionOverride}
        otherExistingCurrencyConversionOverrides={
          otherExistingCurrencyConversionOverrides ?? []
        }
        onClosed={onCurrencyConversionOverrideCancel}
        onSave={onSaveCurrencyConversionOverride}
      />
      <ConfirmDialog
        {...deleteDialog.dialogProps}
        size={'m'}
        headerText={<Content id={ContentId.DeleteOverrideFxRate} />}
        bodyText={<Content id={ContentId.DeleteFxOverrideWarning} />}
        onOkay={onCurrencyConversionOverrideDelete}
        onCancel={deleteDialog.closeDialog}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
      />
    </>
  );
}
