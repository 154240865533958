import * as Sentry from '@sentry/react';
import { ErrorTypes } from 'src/contexts/ErrorBoundaryContext';

/*
This is meant for handling errors for any api calls, default to logging in console
If unauthorized, will redirect to the /unauthorized route
Can supply a generic onError which can perform any custom action
*/
export const tryInvokeApi = async <T>(
  f: () => Promise<T>,
  onError?: (error: ErrorTypes) => void,
  onFinally?: () => void
) => {
  try {
    return await f();
  } catch (error: unknown) {
    console.error(error);
    if (!onError) {
      try {
        Sentry.captureException(error);
      } catch (err) {
        console.warn('Sentry log request failed', err);
      }
    }
    onError?.(error as ErrorTypes);
  } finally {
    onFinally?.();
  }

  return null;
};
