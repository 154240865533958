import { ComponentProps, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import { ShareReportBody } from './ShareReportBody';

export type ShareReportDialogProps = ComponentProps<typeof RSModal> & {
  reportName: string;
  roleIdsShared: number[];
  sellerUserIdsShared: string[];
  isLoading?: boolean;
  onOkay: (roleIds: number[], sellerUserIds: string[]) => void;
  onCancel: () => void;
};

export function ShareReportDialog({
  reportName,
  roleIdsShared,
  sellerUserIdsShared,
  isLoading,
  onOkay,
  onCancel,
  ...rest
}: ShareReportDialogProps) {
  const [newRoleIds, setNewRoleIds] = useState<number[]>([]);
  const [newSellerUserIds, setNewSellerUserIds] = useState<string[]>([]);

  return (
    <GenericDialog
      {...rest}
      size="m"
      header={
        <FormatContent id={FormatContentId.ShareQuoted} params={[reportName]} />
      }
      onOpened={() => {
        setNewRoleIds(roleIdsShared);
        setNewSellerUserIds(sellerUserIdsShared);
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      footer={
        <>
          <Button
            variant="outline"
            onClick={() => {
              onCancel();
            }}
          >
            <Content id={ContentId.Close} />
          </Button>

          <Button
            disabled={isLoading}
            onClick={() => {
              onOkay(newRoleIds, newSellerUserIds);
            }}
          >
            <Content id={ContentId.Share} />
          </Button>
        </>
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <Stack direction="column" gap="l">
        <ShareReportBody
          roleIds={newRoleIds}
          sellerUserIds={newSellerUserIds}
          onRoleIdsChange={setNewRoleIds}
          onSellerUserIdsChange={setNewSellerUserIds}
        />
      </Stack>
    </GenericDialog>
  );
}
