export enum MainRoute {
  SignUp = 'SignUp',
  Login = 'Login',
  LoginCallback = 'LoginCallback',
  Logout = 'Logout',
  LoginFailure = 'LoginFailure',
  Home = 'Home',
  Inventory = 'Inventory',
  Sales = 'Sales',
  Purchases = 'Purchases',
  RedirectToUK = 'RedirectToUK',
  SellerAccountSetting = 'SellerAccountSetting',
  Activity = 'Activity',
  Notifications = 'Notifications',
  ReleaseNotes = 'ReleaseNotes',
  Reports = 'Reports',
  ReportsV2 = 'ReportsV2',
  SyncCenter = 'SyncCenter',
  Email = 'Email',
  Settings = 'Settings',
  SponsoredListings = 'SponsoredListings',
  Payments = 'Payments',
}
