import { ReactNode } from 'react';
import { getDeliveryTypeIcon } from 'src/utils/ticketTypeUtils';
import { DeliveryType } from 'src/WebApiController';

export const getDeliveryTypeIcons = () => {
  const InApp = getDeliveryTypeIcon(DeliveryType.InApp);
  const MemberCard = getDeliveryTypeIcon(DeliveryType.MemberCard);
  const PDF = getDeliveryTypeIcon(DeliveryType.PDF);
  const Paper = getDeliveryTypeIcon(DeliveryType.Paper);
  const Wallet = getDeliveryTypeIcon(DeliveryType.Wallet);
  const Custom = getDeliveryTypeIcon(DeliveryType.Custom);

  return {
    [DeliveryType.InApp]: InApp,
    [DeliveryType.MemberCard]: MemberCard,
    [DeliveryType.PDF]: PDF,
    [DeliveryType.Paper]: Paper,
    [DeliveryType.Wallet]: Wallet,
    [DeliveryType.Custom]: Custom,
  } as Record<string, ReactNode>;
};
