import { useContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { getInHandDisplayData } from 'src/utils/saleUtils';

import { inhandDateContainer } from './InHandDateDisplay.css';

export function InHandDateDisplay({
  date,
  needsToFulfill,
  handleFulfillmentState,
}: {
  date?: Date | null;
  needsToFulfill?: boolean;
  handleFulfillmentState?: boolean;
}) {
  const inhand = useContent(ContentId.InHand);
  const dateLocal =
    date &&
    new Date(
      Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
      )
    );

  const { displayText, displayTooltip, alertIcon } = getInHandDisplayData(
    inhand,
    dateLocal,
    new Date(),
    needsToFulfill
  );

  if (!needsToFulfill && handleFulfillmentState) {
    return null;
  }

  return (
    <div title={displayTooltip ?? undefined} className={inhandDateContainer}>
      {handleFulfillmentState && alertIcon}
      <span>{displayText}</span>
    </div>
  );
}
