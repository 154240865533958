/* IMPORTANT - whenever you add a new one here
   You need to add a handling for it in the BulkEditStatusSalePopover.tsx
  */
export const SALES_BULK_UPDATE_GENERAL_SETTINGS_UPDATE_KEY =
  'general-settings-event-sales';
export const SALES_BULK_UPDATE_COMMISSION_UPDATE_KEY = 'commission-event-sales';
export const SALES_BULK_UPDATE_TAGS_UPDATE_KEY = 'tags-event-sales';
export const SALES_BULK_UPDATE_INTERNAL_NOTES_UPDATE_KEY =
  'update-internal-notes-sales';
export const SALES_BULK_UPDATE_AUTOFULFILL_UPDATE_KEY =
  'update-autofulfill-sales';
export const SALES_BULK_UPDATE_MARK_AS_FULFILLED_UPDATE_KEY =
  'update-mark-as-fulfilled-sales';
