import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useToggle } from 'react-use';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { Content, useContent } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { shared } from 'src/core/themes';
import { Button } from 'src/core/ui';
import {
  PosAccordionContent,
  PosAccordionItem,
  PosAccordionRoot,
  PosAccordionTrigger,
} from 'src/core/ui/Accordion';
import { GroupBySelector } from 'src/dialogs/ReportBuilderDialog/GroupBySelector';
import { ReportConfig, useReportConfigs } from 'src/hooks/useReportConfigs';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { TableColumnsDisplay } from 'src/modals/ColumnSettings/TableColumnsDisplay';
import { FieldWrapper } from 'src/modals/common/Purchase';
import { useColumnDisplayName } from 'src/modals/InventoryTableColumns/useColumnDisplayName';
import { LISTING_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/ListingTable/configs/ListingReportTableColumnsConfig';
import { SALE_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleReportTableColumnsConfig';
import { isMetricConfigurableByGroupBy as isListingMetricConfigurableByGroupBy } from 'src/utils/columns/inventory/inventoryColumnUtils';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING } from 'src/utils/columns/inventory/inventoryColumnUtils.constants';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { filterCustomColumnsForListingReport } from 'src/utils/columns/inventory/inventoryReportCustomColumnUtils';
import { isMetricConfigurableByGroupBy as isSaleMetricConfigurableByGroupBy } from 'src/utils/columns/sales/salesColumnUtils';
import { filterCustomColumnsForSale } from 'src/utils/columns/sales/salesCustomColumnUtils';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { GROUP_BY_TO_PRIMARY_COLUMN_ID as GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES } from 'src/utils/columns/sales/salesReportColumnUtils.constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  REPORT_INVENTORY_GROUP_BY_TO_CID,
  REPORT_IS_SORT_DESCENDING_TO_CID,
  REPORT_SALE_GROUP_BY_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { ReportTypes } from 'src/utils/reportsUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  ActionOutboxEntityType,
  ReportGroupBy,
  UserSetting,
} from 'src/WebApiController';

import { ShareReportBody } from '../ShareReportDialog';
import {
  InventoryMetricsSelectionInputModal,
  SalesMetricsSelectionInputModal,
} from './MetricsSelectionInputModal';
import * as styles from './ReportBuilderDialog.css';
import { ReportBuilderFilterInput } from './ReportBuilderFilterInput';
import { WidgetsSelectionInput } from './WidgetsSelectionInput';

export type ReportBuilderDialogProps = {
  report: ReportConfig;
  onSave: (data: ReportConfig) => void;
} & ComponentProps<typeof RSModal>;

const AdvancedSettingsId = 'advanced-settings';

type AdvancedSettingsAccordion = typeof AdvancedSettingsId | undefined;

export function ReportBuilderDialogBody<S extends ReportTypes>({
  onSave,
  onClosed,
  register,
  setError,
  setValue,
  handleSubmit,
  formState,
  watch,
  reportType,
  ...rest
}: Omit<ReportBuilderDialogProps, 'report'> &
  Omit<
    ComponentProps<typeof FormProvider<ReportConfig, unknown>>,
    'children' | 'trigger' | 'reset' | 'unregister'
  > & {
    reportType: ReportTypes;
  }) {
  const { reportConfigs: existingReports } = useReportConfigs<S>({
    reportType,
  });

  const requiredMsg = useContent(ContentId.Required);
  const duplicateNameMsg = useContent(ContentId.DuplicatedReportName);

  const nameError = formState.errors.reportName?.message;
  const numberOfItemsPerPageError =
    formState.errors.numberOfItemsPerPage?.message;
  const widgetsError = formState.errors.widgets?.message;
  const filtersError = formState.errors.filter?.message;
  const metricsError = formState.errors.metrics?.message;
  const groupByError = formState.errors.groupBy?.message;

  const reportId = watch('reportId');
  const reportName = watch('reportName');
  const groupBy = watch('groupBy');
  const metrics = watch('metrics');
  const numberOfItemsPerPage = watch('numberOfItemsPerPage');
  const sortBy = watch('sortBy');
  const isSortDescending = watch('isSortDescending');
  const roleIdsToShare = watch('roleIdsToShare');
  const sellerUserIdsToShare = watch('sellerUserIdsToShare');

  const [isMetricModalOpen, toggleMetricModal] = useToggle(false);
  const [advancedSettingsExpandedItem, setAdvancedSettingsExpandedItem] =
    useState<AdvancedSettingsAccordion>(undefined);

  const onSubmit = useCallback(() => {
    let hasErrors = false;
    if (!reportName) {
      setError('reportName', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (metrics == null || metrics.length === 0) {
      setError('metrics', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (
      existingReports
        ?.filter((r) => r.reportId !== reportId)
        .some(
          (r) =>
            r.reportName.toLocaleUpperCase() === reportName.toLocaleUpperCase()
        )
    ) {
      setError(
        'reportName',
        { message: duplicateNameMsg },
        { shouldFocus: true }
      );
      hasErrors = true;
    }

    if (!hasErrors) {
      handleSubmit(onSave)();
    }
  }, [
    reportName,
    metrics,
    reportId,
    existingReports,
    setError,
    requiredMsg,
    duplicateNameMsg,
    handleSubmit,
    onSave,
  ]);

  const { tagsMetadataNumeric } = useTagsForEntityType(
    reportType === ReportTypes.Inventory
      ? ActionOutboxEntityType.Listing
      : ActionOutboxEntityType.Sale,
    true
  );

  const { tagsMetadata: tagsMetadataEvent } = useTagsForEntityType(
    ActionOutboxEntityType.SellerEvent,
    true
  );

  const { tagsMetadata: tagsMetadataPurchase } = useTagsForEntityType(
    ActionOutboxEntityType.Purchase,
    true
  );

  const reportingTags = useMemo(() => {
    if (groupBy === ReportGroupBy.Event) {
      return [...(tagsMetadataNumeric ?? []), ...(tagsMetadataEvent ?? [])];
    } else if (groupBy === ReportGroupBy.PurchaseId) {
      return [...(tagsMetadataNumeric ?? []), ...(tagsMetadataPurchase ?? [])];
    }

    return tagsMetadataNumeric;
  }, [groupBy, tagsMetadataEvent, tagsMetadataNumeric, tagsMetadataPurchase]);

  const { value: customListingColumns = [] } = useServerUserSetting<
    CustomListingColumn[]
  >({
    id: UserSetting.InventoryCustomColumns,
  });
  const { value: customSaleColumns = [] } = useServerUserSetting<
    CustomSalesColumn[]
  >({
    id: UserSetting.SaleCustomColumns,
  });

  const { displayNames: metricDisplayNames } = useColumnDisplayName({
    columnIds: metrics ?? [],
    sectionType:
      reportType === ReportTypes.Inventory
        ? SectionType.ListingsReport
        : SectionType.SalesReport,
  });

  const filterColumnByGroupBy = useCallback(
    (columnId: string, groupBy: ReportGroupBy) => {
      if (reportType === ReportTypes.Inventory) {
        return isListingMetricConfigurableByGroupBy(columnId, groupBy);
      } else if (reportType === ReportTypes.Sale) {
        return isSaleMetricConfigurableByGroupBy(columnId, groupBy);
      }

      return true;
    },
    [reportType]
  );

  const metricsFiltered = useMemo(() => {
    // Filter out reporting tags that are later turned off reporting
    const customColumnsFiltered =
      reportType === ReportTypes.Inventory
        ? filterCustomColumnsForListingReport(
            customListingColumns,
            tagsMetadataNumeric
          )
        : filterCustomColumnsForSale(customSaleColumns, tagsMetadataNumeric);

    const presetColumnIds = Object.values(
      reportType === ReportTypes.Inventory
        ? LISTING_REPORT_TABLE_COLUMNS_CONFIG
        : SALE_REPORT_TABLE_COLUMNS_CONFIG
    )
      .map((i) => i.id)
      .filter((id) => filterColumnByGroupBy(id, groupBy));

    const activeTagNames = reportingTags?.map((t) => t.key) ?? [];
    const customColumnIds = customColumnsFiltered.map((c) => c.id);

    return metrics.filter(
      (m) =>
        presetColumnIds.includes(m) ||
        activeTagNames.includes(m) ||
        customColumnIds.includes(m)
    );
  }, [
    reportType,
    customListingColumns,
    tagsMetadataNumeric,
    customSaleColumns,
    reportingTags,
    metrics,
    filterColumnByGroupBy,
    groupBy,
  ]);

  const primaryColumnId = useMemo(() => {
    const GROUP_BY_TO_PRIMARY_COLUMN_ID =
      reportType === ReportTypes.Inventory
        ? GROUP_BY_TO_PRIMARY_COLUMN_ID_LISTING
        : GROUP_BY_TO_PRIMARY_COLUMN_ID_SALES;
    return GROUP_BY_TO_PRIMARY_COLUMN_ID[groupBy];
  }, [groupBy, reportType]);

  const groupByOptions =
    reportType === ReportTypes.Inventory
      ? REPORT_INVENTORY_GROUP_BY_TO_CID
      : REPORT_SALE_GROUP_BY_TO_CID;

  const sortByOptions = useMemo(() => {
    const options = metrics.reduce<Record<string, string>>((acc, metric, i) => {
      acc[metric] = metricDisplayNames[i];
      return acc;
    }, {});

    return options ?? {};
  }, [metricDisplayNames, metrics]);

  const onSortByChange = useCallback(
    (newValue: string) => {
      if (newValue !== groupBy) {
        setValue('sortBy', newValue);
      } else {
        // Special case: sort by groupBy (performer, venue, event)
        setValue('sortBy', null);
      }
    },
    [groupBy, setValue]
  );

  const onIsSortDescendingChange = useCallback(
    (newValue: string) => {
      setValue('isSortDescending', newValue === 'true');
    },
    [setValue]
  );

  return (
    <>
      <GenericDialog
        {...rest}
        size="lg"
        header={
          <Content
            id={reportId == null ? ContentId.AddReport : ContentId.EditReport}
          />
        }
        footer={
          <>
            {onClosed && (
              <CancelButton
                onClick={onClosed}
                disabled={formState.isSubmitting}
              />
            )}
            <Button
              variant={'regular'}
              onClick={onSubmit}
              disabled={formState.isSubmitting}
            >
              <Content id={ContentId.Save} />
            </Button>
          </>
        }
        onCancel={onClosed}
      >
        <div className={styles.reportBuilderDialogContainer}>
          <FieldWrapper>
            <PosFormField
              errors={nameError}
              label={
                <span className={shared.typography.subtitle1}>
                  <Content id={ContentId.Name} />
                </span>
              }
            >
              <PosTextField
                rootProps={{
                  disabled: formState.isSubmitting,
                  state: getTextFieldState(nameError),
                }}
                disabled={formState.isSubmitting}
                {...register('reportName')}
              />
            </PosFormField>
          </FieldWrapper>
          <FieldWrapper>
            <PosFormField
              errors={groupByError}
              label={
                <span className={shared.typography.subtitle1}>
                  <Content id={ContentId.GroupBy} />
                </span>
              }
            >
              <GroupBySelector
                options={groupByOptions}
                reportType={reportType}
                metricsFiltered={metricsFiltered}
              />
            </PosFormField>
          </FieldWrapper>

          <FieldWrapper>
            <PosFormField
              errors={filtersError}
              label={
                <span className={shared.typography.subtitle1}>
                  <Content id={ContentId.Filters} />
                </span>
              }
            >
              <ReportBuilderFilterInput reportType={reportType} />
            </PosFormField>
          </FieldWrapper>
          <FieldWrapper>
            <PosFormField
              errors={metricsError}
              label={
                <span className={shared.typography.subtitle1}>
                  <Content id={ContentId.Columns} />
                </span>
              }
            >
              <div className={styles.metricsContainer}>
                {metricsFiltered != null && metricsFiltered.length > 0 ? (
                  <>
                    {reportType === ReportTypes.Inventory ? (
                      <TableColumnsDisplay<CustomListingColumn>
                        items={metricsFiltered ?? []}
                        sectionType={SectionType.ListingsReport}
                        tableType="inventory"
                      />
                    ) : (
                      <TableColumnsDisplay<CustomSalesColumn>
                        items={metricsFiltered ?? []}
                        sectionType={SectionType.SalesReport}
                        tableType="sales"
                      />
                    )}
                  </>
                ) : (
                  <div className={styles.noneSelectedText}>
                    <Content id={ContentId.NoneSelected} />
                  </div>
                )}

                <Button
                  className={styles.editButton}
                  variant="link"
                  onClick={toggleMetricModal}
                >
                  <Content id={ContentId.EditColumns} />
                </Button>
              </div>
            </PosFormField>
          </FieldWrapper>
          <FieldWrapper>
            <PosFormField
              label={
                <span className={shared.typography.subtitle1}>
                  <Content id={ContentId.Sharing} />
                </span>
              }
            >
              <div className={styles.sharingContainer}>
                <Content id={ContentId.SharingReportDescription} />
                <ShareReportBody
                  roleIds={roleIdsToShare}
                  sellerUserIds={sellerUserIdsToShare}
                  onRoleIdsChange={(value) => setValue('roleIdsToShare', value)}
                  onSellerUserIdsChange={(value) =>
                    setValue('sellerUserIdsToShare', value)
                  }
                />
              </div>
            </PosFormField>
          </FieldWrapper>
          <FieldWrapper>
            <PosAccordionRoot
              type="single"
              value={advancedSettingsExpandedItem}
              collapsible
              onValueChange={(activeExpandedItem) => {
                setAdvancedSettingsExpandedItem(
                  activeExpandedItem as AdvancedSettingsAccordion
                );
              }}
            >
              <PosAccordionItem value={AdvancedSettingsId}>
                <PosAccordionTrigger>
                  <Content id={ContentId.AdvancedSettings} />
                </PosAccordionTrigger>
                <PosAccordionContent>
                  {reportType === ReportTypes.Inventory && (
                    <FieldWrapper>
                      <PosFormField
                        errors={widgetsError}
                        label={
                          <span className={shared.typography.subtitle1}>
                            <Content id={ContentId.Widgets} />
                          </span>
                        }
                      >
                        <WidgetsSelectionInput
                          disabled={formState.isSubmitting}
                        />
                      </PosFormField>
                    </FieldWrapper>
                  )}
                  <FieldWrapper>
                    <PosFormField
                      errors={numberOfItemsPerPageError}
                      label={<Content id={ContentId.NumberOfItemsPerPage} />}
                    >
                      <PosTextField
                        rootProps={{
                          disabled: formState.isSubmitting,
                          state: getTextFieldState(numberOfItemsPerPageError),
                        }}
                        disabled={formState.isSubmitting}
                        type="number"
                        inputMode="numeric"
                        value={numberOfItemsPerPage ?? ''}
                        onChange={(e) => {
                          const v = parseInt(e.target.value);
                          if (!isNaN(v) && v > 0) {
                            if (v !== numberOfItemsPerPage) {
                              setValue('numberOfItemsPerPage', v);
                            }
                          } else {
                            setValue('numberOfItemsPerPage', null);
                          }
                        }}
                      />
                    </PosFormField>
                  </FieldWrapper>

                  <FieldWrapper>
                    <PosFormField
                      label={
                        <span className={shared.typography.subtitle1}>
                          <Content id={ContentId.SortBy} />
                        </span>
                      }
                    >
                      <div className={styles.sortByContainer}>
                        <PosSelect
                          style={{ width: '100%' }}
                          displayText={sortByOptions[sortBy ?? primaryColumnId]}
                          value={sortBy ?? primaryColumnId}
                          defaultValue={primaryColumnId}
                          onChange={onSortByChange}
                          valueOptionsContent={sortByOptions}
                          placeholderText={ContentId.Columns}
                        />
                        <PosSelect
                          style={{ width: '100%' }}
                          displayText={
                            REPORT_IS_SORT_DESCENDING_TO_CID[
                              String(Boolean(isSortDescending))
                            ]
                          }
                          value={String(Boolean(isSortDescending))}
                          onChange={onIsSortDescendingChange}
                          valueOptionsContent={REPORT_IS_SORT_DESCENDING_TO_CID}
                        />
                      </div>
                    </PosFormField>
                  </FieldWrapper>
                </PosAccordionContent>
              </PosAccordionItem>
            </PosAccordionRoot>
          </FieldWrapper>
        </div>
      </GenericDialog>
      {isMetricModalOpen && (
        <>
          {reportType === ReportTypes.Inventory ? (
            <InventoryMetricsSelectionInputModal
              onClose={toggleMetricModal}
              groupBy={groupBy}
            />
          ) : (
            <SalesMetricsSelectionInputModal
              onClose={toggleMetricModal}
              groupBy={groupBy}
            />
          )}
        </>
      )}
    </>
  );
}
