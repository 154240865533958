import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { id } from 'date-fns/locale';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { SELLER_ACCOUNT_SELLER_USERS_QUERY_KEY } from 'src/contexts/SellerAccountContext';
import { Feature, UserGroup, UserGroupClient } from 'src/WebApiController';

import { useUserHasFeature } from './useUserHasFeature';

export type UserGroupInput = Omit<UserGroup, 'userGroupId'>;

/**
 * Data and functions returned by the hook to help with CRUD of user group
 */
type UseUserGroups = {
  userGroups: UserGroup[] | null | undefined;
  isLoading: boolean;
  deleteUserGroup: (userGroupId: number) => Promise<boolean>;
  createUserGroup: (userGroupInput: UserGroupInput) => Promise<number | null>;
  updateUserGroup: (
    userGroupId: number,
    userGroupInput: UserGroupInput
  ) => Promise<boolean>;
};
/**
 * Hook to help with CRUD of user group
 * @param options
 * @returns
 */
export function useUserGroups(): UseUserGroups {
  const { showErrorDialog, trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const userGroupClient = useMemo(
    () => new UserGroupClient(activeAccountWebClientConfig),
    [activeAccountWebClientConfig]
  );

  const hasTeamManagementFeature = useUserHasFeature(Feature.TeamManagement);

  const queryClient = useQueryClient();
  const queryKey = [
    'useUserGroups',
    activeAccountWebClientConfig.activeAccountId,
  ];

  const shouldQuery =
    activeAccountWebClientConfig?.activeAccountId != null &&
    hasTeamManagementFeature;

  const userGroupQuery = useQuery({
    queryKey,
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      return await userGroupClient.getUserGroups();
    },

    enabled: shouldQuery,
    meta: {
      onError: (err: ErrorTypes) => {
        trackError('UserGroupClient.getUserGroups', err);
      },
    },
    refetchOnWindowFocus: false,
  });

  const updateMutation = useMutation({
    mutationFn: async ({ value }: { value: UserGroup }) => {
      return await userGroupClient.updateUserGroup(
        value.userGroupId,
        value.userGroupName,
        value.userGroupDescription ?? undefined,
        value.sellerUserIds
      );
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ value }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData<UserGroup[] | null>(queryKey);

      const newValue =
        value.userGroupId == null
          ? [...(prevValue ?? []), value]
          : prevValue?.map((r) =>
              r.userGroupId === value.userGroupId ? value : r
            );

      queryClient.setQueryData<UserGroup[]>(queryKey, newValue);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { value }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('userGroupClient.updateUserGroup', err, {
        trackErrorData: { id, value },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: [
          SELLER_ACCOUNT_SELLER_USERS_QUERY_KEY,
          activeAccountWebClientConfig.activeAccountId,
        ],
      });
    },
  });

  const updateUserGroup = async (
    userGroupId: number,
    userGroupInput: UserGroupInput
  ) => {
    return await updateMutation.mutateAsync({
      value: {
        userGroupId,
        userGroupName: userGroupInput.userGroupName,
        userGroupDescription: userGroupInput.userGroupDescription,
        sellerUserIds: userGroupInput.sellerUserIds,
      },
    });
  };

  const createMutation = useMutation({
    mutationFn: async ({ value }: { value: UserGroupInput }) => {
      return await userGroupClient.insertUserGroup(
        value.userGroupName,
        value.userGroupDescription ?? undefined,
        value.sellerUserIds
      );
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ value }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData<UserGroup[] | null>(queryKey);

      const newValue = [...(prevValue ?? []), value as UserGroup];

      queryClient.setQueryData<UserGroup[]>(queryKey, newValue);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { value }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('userGroupClient.createUserGroup', err, {
        trackErrorData: { id, value },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: [
          SELLER_ACCOUNT_SELLER_USERS_QUERY_KEY,
          activeAccountWebClientConfig.activeAccountId,
        ],
      });
    },
  });

  const createUserGroup = async (userGroupInput: UserGroupInput) => {
    return await createMutation.mutateAsync({ value: userGroupInput });
  };

  const deleteMutation = useMutation({
    mutationFn: async (userGroupId: number) => {
      return await userGroupClient.deleteUserGroup(userGroupId);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: (userGroupId: number) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData<UserGroup[] | null>(queryKey);

      const newValue = prevValue?.filter((r) => r.userGroupId !== userGroupId);

      queryClient.setQueryData<UserGroup[]>(queryKey, newValue);
      return { prevValue };
    },
    onError: (err: ErrorTypes, userGroupId: number, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('userGroupClient.deleteUserGroup', err, {
        trackErrorData: { id, userGroupId },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({
        queryKey: [
          SELLER_ACCOUNT_SELLER_USERS_QUERY_KEY,
          activeAccountWebClientConfig.activeAccountId,
        ],
      });
    },
  });

  const deleteUserGroup = (userGroupId: number) => {
    return deleteMutation.mutateAsync(userGroupId);
  };

  return {
    userGroups: userGroupQuery.data,
    isLoading: userGroupQuery.isLoading,
    deleteUserGroup,
    createUserGroup,
    updateUserGroup,
  };
}
