import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function PrintAtHomeIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M17.796 4v3.265H22V17.47h-1.347l-1.714-1.632h1.428V8.898H3.633v6.939h1.632L6.041 14h11.673l2.53 6.122H3.51l1.102-2.653H2V7.265h3.96V4h11.836zm-1.143 11.633h-9.51l-.776 1.836L6 18.49h11.837l-.408-1.02-.776-1.836zM9.02 10.204v1.633H4.94v-1.633H9.02zm7.143-4.571H7.592v1.632h8.571V5.633z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
