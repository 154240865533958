import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SellerAccountBasicInfo } from 'src/components/SellerAccountBasicInfo';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { SearchResultPickerDialog } from 'src/dialogs/SearchResultPickerDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { sortSellerAccount } from 'src/utils/userUtils';
import {
  LoginClient,
  SellerAccount,
  SellerAccountClient,
} from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import * as styles from './Login.css';

export function LoginCallback() {
  return (
    <LayoutContent mainRoute={MainRoute.LoginCallback}>
      <LoginCallbackPage />
    </LayoutContent>
  );
}

function LoginCallbackPage() {
  const navigate = useNavigate();
  const { showErrorDialog } = useErrorBoundaryContext();
  const location = useMemo(() => new URL(window.location.href), []);
  const [returnUrl, setReturnUrl] = useState<string | null>(null);
  const [sellerAccounts, setSellerAccounts] = useState<SellerAccount[] | null>(
    null
  );
  const [filteredSellerAccounts, setFilteredSellerAccounts] = useState<
    SellerAccount[]
  >([]);

  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();

  const onSellerAccountPicked = useCallback(
    async (account: SellerAccount) => {
      await tryInvokeApi(
        async () => {
          await new LoginClient({}).setActiveAccount(
            account.id,
            account.impersonatedUserId
          );
          if (returnUrl) {
            navigate(returnUrl);
          }
          closeDialog();
        },
        (error) => {
          showErrorDialog('AdminClient.setActiveAccount', error, {
            trackErrorData: account,
          });
        }
      );
    },
    [closeDialog, navigate, returnUrl, showErrorDialog]
  );

  const onLoginCallback = useCallback(async () => {
    tryInvokeApi(
      async () => {
        const code = location.searchParams.get('code');
        const state = location.searchParams.get('state');
        if (code && state) {
          const loginContext = await new LoginClient(
            {}
          ).getLoginContextAfterAuthorization({
            code: location.searchParams.get('code') || '',
            state: location.searchParams.get('state') || '',
          }); // after we authorize we want to return here

          if (loginContext?.user?.hasManyAccounts) {
            const accounts = await new SellerAccountClient({
              activeAccountId: loginContext?.user?.activeAccount?.id,
            }).getCurrentUserSellerAccounts();
            setReturnUrl(loginContext.returnUrl);

            if (accounts) {
              const sorted = sortSellerAccount(accounts);
              setSellerAccounts(sorted);
              setFilteredSellerAccounts(sorted);
              launchDialog();
            } else {
              setSellerAccounts([]);
              setFilteredSellerAccounts([]);
            }
          } else if (loginContext?.returnUrl) {
            if (loginContext.returnUrl.startsWith('https://')) {
              // to be safe we should only redirect to full HTTPS
              window.location.assign(loginContext.returnUrl);
            } else {
              navigate(loginContext.returnUrl);
            }
          }
        }
      },
      (error) => {
        showErrorDialog(
          'LoginClient.getLoginContextAfterAuthorization',
          error,
          {
            onDismissError: () => {
              window.location.assign('/unauthorized');
            },
            trackErrorData: {
              code: location.searchParams.get('code'),
              state: location.searchParams.get('state'),
            },
          }
        );
      }
    );
  }, [launchDialog, location.searchParams, navigate, showErrorDialog]);

  const onFilterSellerAccount = useCallback(
    (text: string) => {
      text = text.toLocaleUpperCase();
      const filtered = sellerAccounts?.filter(
        (sa) =>
          sa.accountName.toLocaleUpperCase().includes(text) ||
          sa.impersonatedUsername?.toLocaleUpperCase()?.includes(text) ||
          sa.id.toLocaleUpperCase().includes(text)
      );
      setFilteredSellerAccounts(filtered ?? []);
    },
    [sellerAccounts]
  );

  useEffect(() => {
    onLoginCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.loginContainer}>
      {sellerAccounts == null ? (
        <PosSpinner />
      ) : (
        sellerAccounts.length == 0 && (
          <WarningMessage
            message={<Content id={ContentId.NoActiveAccountsForLogin} />}
          />
        )
      )}
      <SearchResultPickerDialog
        {...dialogProps}
        header={<Content id={ContentId.SelectAccountToSignIn} />}
        resultItems={filteredSellerAccounts || []}
        renderItem={(account: SellerAccount) => (
          <SellerAccountBasicInfo account={account} isCurrent={false} />
        )}
        onResultItemPicked={onSellerAccountPicked}
        onResultItemSearched={onFilterSellerAccount}
        onCancel={undefined}
      />
    </div>
  );
}
