import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const AccountSectionLabel = styled.div`
  font-size: ${() => vars.typography.fontSize.base};
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const AccountSectionEmail = styled(AccountSectionLabel)`
  color: ${(props) => props.theme.colors.textStrong};
  font-weight: 500;
`;

export const VendorSectionLabel = styled(AccountSectionLabel)``;

export const VendorSectionName = styled.div`
  color: ${(props) => props.theme.colors.textStrong};
  font-weight: 500;
`;
