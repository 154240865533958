import { useState } from 'react';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { IconsFill, LoadingIcon, WarningOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import * as styles from './ReportsMaxSizeMessage.css';

export const ReportsMaxSizeMessage = ({
  count,
  totalCount,
  onBypassLimit,
}: {
  count: number;
  totalCount?: number | null;
  onBypassLimit: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  if (count >= (totalCount ?? -1)) {
    return null;
  }

  return (
    <Stack
      gap="l"
      alignItems="center"
      justifyContent="center"
      className={styles.messageContainer}
    >
      <WarningOutlineIcon fill={IconsFill.textWarning} size={vars.iconSize.l} />
      <div style={{ fontWeight: 700 }}>
        <Content id={ContentId.MaxReportSizeWarningLabel} />
      </div>
      <span>
        <FormatContent
          id={FormatContentId.MaxReportSizeWarningMessage}
          params={[`${totalCount}`, `${count}`]}
        />
      </span>

      <Button
        onClick={() => {
          setIsLoading(true);
          onBypassLimit();
        }}
        variant="outline"
      >
        <Content id={ContentId.BypassLimit} />{' '}
        {isLoading && (
          <RotatingWrapper>
            <LoadingIcon
              withHoverEffect
              fill={IconsFill.textPrimary}
              align="middle"
            />
          </RotatingWrapper>
        )}
      </Button>
    </Stack>
  );
};
