import { groupBy, isEqual, size } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Modal as RSModal } from 'reactstrap';
import { RowRelativeScoreInfo } from 'src/components/Events/RowRelativeScoreDisplay';
import { Content } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Button } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { GraphSegments } from 'src/modals/EventSeatMapConfig/EventSeatMapConfigBody';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionInfo, SectionScoreOverride } from 'src/WebApiController';

import {
  ChangeRowScoreBodyMultiSection,
  rowScoreFilter,
  rowScoreOverrideFilter,
} from './ChangeRowScoreBodyMultiSection';
import { findScoreOverrideByRowId } from './ChangeRowScoreDialog';
import * as styles from './ChangeRowScoreDialog.css';

export type ChangeRowScoreDialogMultiSectionProps = ComponentProps<
  typeof RSModal
> & {
  selectedSections: SectionInfo[];
  scoreOverrides?: SectionScoreOverride[] | null;
  configPayload: string | null;
  onOkay: (
    newScoreOverrides: SectionScoreOverride[],
    newConfigPayload: string | null
  ) => void;
  onCancel: () => void;
};

export function ChangeRowScoreDialogMultiSection({
  selectedSections,
  scoreOverrides,
  configPayload,
  onOkay,
  onCancel,
  ...rest
}: ChangeRowScoreDialogMultiSectionProps) {
  const confirmDialog = useBasicDialog();

  const [newConfigPayload, setNewConfigPayload] = useState(configPayload);

  const [rowDataToEdit, setRowDataToEdit] = useState<RowRelativeScoreInfo[]>(
    []
  );
  const selectedSectionIds = useMemo(
    () => selectedSections.map(({ id }) => id),
    [selectedSections]
  );
  const speculativeRowIds = useMemo(
    () =>
      selectedSections
        .filter(({ specRow }) => !!specRow)
        .map(({ specRow }) => specRow!.id),
    [selectedSections]
  );

  const sectionScoreOverride = useMemo(
    () => groupBy(scoreOverrides, 'sectionId'),
    [scoreOverrides]
  );

  // Reset the initial row data, mainly the size, the default value will be reset
  // from initialSegmentsFromConfig
  useEffect(() => {
    let maxRowNumber = 0;
    for (const section of selectedSections) {
      const eligibleRows = section.rows.filter(rowScoreFilter)?.length ?? 0;
      maxRowNumber = Math.max(eligibleRows, maxRowNumber);
    }
    for (const sectionId of selectedSectionIds) {
      const eligibleRows =
        sectionScoreOverride[sectionId]?.filter(
          rowScoreOverrideFilter(speculativeRowIds)
        )?.length ?? 0;
      maxRowNumber = Math.max(eligibleRows, maxRowNumber);
    }

    const allRowData: RowRelativeScoreInfo[] = [];
    for (let i = 0; i < maxRowNumber; i++) {
      // here RowRelativeScoreInfo represents a row in a section
      allRowData.push({
        ordinal: i,
        isBase: i === maxRowNumber / 2,
        percentage: 0,
      } as RowRelativeScoreInfo);
    }
    setRowDataToEdit(allRowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSections.length, setRowDataToEdit]);

  const configPayloadObj = useMemo(() => {
    if (configPayload) {
      let obj: GraphSegments | undefined = undefined;
      try {
        obj = JSON.parse(configPayload) as GraphSegments;
      } catch (e) {
        console.warn(`Unable to parse as GraphSegments: ${configPayload}`);
      }

      if (obj) {
        return obj;
      }
    }
  }, [configPayload]);

  // Should be true if all selected sections are configured together at once
  const initialSegmentsFromConfig = useMemo(() => {
    if (!configPayloadObj?.scoreSegmentsBySection) {
      return undefined;
    }

    const selectedScoreSegments = Object.entries(
      configPayloadObj.scoreSegmentsBySection
    )
      .filter(([key, _]) => selectedSectionIds.includes(parseInt(key)))
      .map(([_, value]) => value);

    if (size(selectedScoreSegments) < 1) {
      return undefined;
    }

    const common = selectedScoreSegments[0];
    if (selectedScoreSegments.every((section) => isEqual(section, common))) {
      return common;
    }
    return undefined;
  }, [configPayloadObj, selectedSectionIds]);

  const onSubmit = useCallback(() => {
    const newScoreOverrides = [...(scoreOverrides ?? [])];
    const newSectionScoreOverrides = newScoreOverrides.filter((s) =>
      selectedSections.some((ss) => ss.id === s.sectionId)
    );
    const baseRowIndex = rowDataToEdit.findIndex((r) => r.isBase);
    if (baseRowIndex < 0) {
      onOkay(newScoreOverrides, newConfigPayload);
      return;
    }

    for (const sectionId of selectedSectionIds) {
      const sectionRows = (sectionScoreOverride[sectionId] ?? [])
        .filter(rowScoreOverrideFilter(speculativeRowIds))
        .sort((a, b) => (a.rowOrdinal ?? 0) - (b.rowOrdinal ?? 0));
      const sanitizedIndex = Math.min(baseRowIndex, sectionRows.length - 1);

      const baseRowId = sectionRows[sanitizedIndex]?.rowId;
      const baseRowScore = findScoreOverrideByRowId(
        newSectionScoreOverrides,
        baseRowId
      )?.score;

      const baseRowPercentage = rowDataToEdit[sanitizedIndex]?.percentage ?? 0;

      if (baseRowScore) {
        // all work based off this, so if this doesn't exist, nothing to do
        for (let i = 0; i < rowDataToEdit.length; i++) {
          const rowId = sectionRows[i]?.rowId;

          const rowScoreOverrideToEdit = newSectionScoreOverrides.find(
            (s) => s.rowId === rowId
          );
          if (
            rowScoreOverrideToEdit &&
            !rowDataToEdit[i].isBase &&
            rowDataToEdit[i]?.percentage != null
          ) {
            rowScoreOverrideToEdit.score =
              ((100 + (rowDataToEdit[i]?.percentage ?? 0)) /
                (100.0 + baseRowPercentage)) *
              baseRowScore;
          }
        }

        // We need to ensure all the speculative rows also set with the score (it'll be the base row score)
        newSectionScoreOverrides
          .filter((r) => speculativeRowIds.includes(r.rowId))
          .forEach((r) => (r.score = baseRowScore));
      }

      onOkay(newScoreOverrides, newConfigPayload);
    }
  }, [
    newConfigPayload,
    onOkay,
    rowDataToEdit,
    scoreOverrides,
    sectionScoreOverride,
    selectedSectionIds,
    selectedSections,
    speculativeRowIds,
  ]);

  const sectionNameWithEllipsis = useMemo(() => {
    const maxSectionsToShow = 5;
    const sectionNames = selectedSections.map((s) => s.name);
    if (sectionNames.length > maxSectionsToShow) {
      return `${sectionNames.slice(0, maxSectionsToShow).join(', ')}...`;
    }
    return sectionNames.join(', ');
  }, [selectedSections]);

  if (!selectedSections.length) {
    return null;
  }

  return (
    <>
      <GenericDialog
        {...rest}
        size="m"
        header={<Content id={ContentId.RowScore} />}
        onKeyUp={(e) => e.key === 'Enter' && confirmDialog.launchDialog()}
        footer={
          <>
            <Button className={styles.cancelButton} onClick={onCancel}>
              <Content id={ContentId.Cancel} />
            </Button>
            <Button onClick={confirmDialog.launchDialog}>
              <Content id={ContentId.Save} />
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        <div className={styles.ChangeRowScoreDialogContainer}>
          <div className={styles.topRowContainer}>
            <div className={styles.selectedSectionContainer}>
              <Content id={ContentId.Section} />
              <div
                className={styles.selectedSectionNameContainer}
                title={selectedSections.map((s) => s.name).join(', ')}
              >
                {sectionNameWithEllipsis}
              </div>
            </div>
          </div>
          <div className={styles.rowScoreContainer}>
            <ChangeRowScoreBodyMultiSection
              selectedSections={selectedSections}
              rowDataToEdit={rowDataToEdit}
              setRowDataToEdit={setRowDataToEdit}
              configPayload={newConfigPayload}
              setConfigPayload={setNewConfigPayload}
              initialSegmentsFromConfig={initialSegmentsFromConfig}
            />
          </div>
        </div>
      </GenericDialog>
      <ConfirmDialog
        size="m"
        {...confirmDialog.dialogProps}
        headerText={<Content id={ContentId.EditRowScores} />}
        bodyText={<Content id={ContentId.AreYouSureEditRowsMultipleSections} />}
        onOkay={() => {
          onSubmit();
          confirmDialog.closeDialog();
        }}
        onCancel={() => confirmDialog.closeDialog()}
      />
    </>
  );
}
