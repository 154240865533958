import clsx from 'clsx';
import { endOfDay, startOfDay } from 'date-fns';
import { isEmpty } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import {
  CreateExperimentDialog,
  ExperimentGroupItem,
} from 'src/dialogs/PricingABTest/CreateExperimentDialog';
import { useListingExperiments } from 'src/hooks/api/useListingExperiments';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { PlusIcon } from 'src/svgs/Viagogo';
import { ListingExperimentTable } from 'src/tables/ListingExperimentTable/ListingExperimentTable';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Event,
  Listing,
  ListingExperiment,
  ListingGroup,
} from 'src/WebApiController';

import * as styles from '../InventoryEventPage.css';

type InventoryPricingABTestSectionProps = {
  event: Event;
  listings: Listing[];
};

export const InventoryPricingABTestSection: React.FC<
  InventoryPricingABTestSectionProps
> = ({ event, listings }) => {
  const {
    isLoading,
    experiments,
    onUpdateExperiment,
    onCreateExperiment,
    onStopExperiment,
  } = useListingExperiments(event.viagId!);

  const { loginContext } = useAppContext();

  const [selectedExperiment, setSelectedExperiment] =
    useState<ListingExperiment | null>(null);

  const listingGroups = useMemo(() => {
    return listings.filter(({ isLtGrp }) => isLtGrp) as ListingGroup[];
  }, [listings]);

  const availableListingGroups = useMemo((): ExperimentGroupItem[] => {
    const candidates = listingGroups
      .map(
        (group) =>
          ({
            id: (group as ListingGroup).ltGrpId,
            name: (group as ListingGroup).groupName,
          }) as ExperimentGroupItem
      )
      .concat({ id: 'N/A', name: 'N/A' });

    if (selectedExperiment) {
      return candidates;
    }

    const usedListingGroupIds = experiments?.map(
      ({ listingGroupId }) => listingGroupId ?? 'N/A'
    );

    return candidates.filter(({ id }) => !usedListingGroupIds?.includes(id));
  }, [experiments, listingGroups, selectedExperiment]);

  const defaultValues = useMemo((): ListingExperiment => {
    if (selectedExperiment) {
      return selectedExperiment;
    }
    const current = new Date();
    const startDate = startOfDay(current).toISOString();
    current.setDate(current.getDate() + 7);
    const endDate = endOfDay(current).toISOString();
    return {
      viagogoEventId: event.viagId!,
      listingGroupId: null,
      listingGroupName: null,
      targetPercentage: null,
      discountPercentage: 10,
      startDate,
      endDate,
      insertBy: loginContext?.user?.displayName || '',
    };
  }, [event.viagId, loginContext?.user?.displayName, selectedExperiment]);

  const createExperimentDialog = useBasicDialog();

  const onResumeExperiment = useCallback(
    (listingGroupId: string | null) => {
      const selected = experiments?.find(
        (experiment) => experiment.listingGroupId === listingGroupId
      );
      setSelectedExperiment(selected ?? null);
      createExperimentDialog.launchDialog();
    },
    [createExperimentDialog, experiments]
  );

  const onCloseDialog = useCallback(() => {
    setSelectedExperiment(null);
    createExperimentDialog.closeDialog();
  }, [createExperimentDialog]);

  return (
    <>
      <div className={styles.tableSection}>
        <div
          className={clsx(
            styles.tableContainer,
            styles.inventoryEventPageTableContainer
          )}
        >
          <Stack justifyContent="end" className={styles.hoistedColumnSettings}>
            <Stack width="full" direction="column" alignItems="end">
              <Button
                variant="textPlain"
                onClick={() => createExperimentDialog.launchDialog()}
                disabled={isEmpty(availableListingGroups)}
              >
                <PlusIcon size={vars.iconSize.m} />
                <Content id={ContentId.AddABTest} />
              </Button>
            </Stack>
          </Stack>
        </div>
        <ListingExperimentTable
          isLoading={isLoading}
          experiments={experiments}
          listingGroups={listingGroups}
          onResumeExperiment={onResumeExperiment}
          onStopExperiment={onStopExperiment}
        />
      </div>
      <CreateExperimentDialog
        {...createExperimentDialog.dialogProps}
        viagogoEventId={1}
        isLoading={isLoading}
        defaultValues={defaultValues}
        availableGroups={availableListingGroups}
        isUpdate={!!selectedExperiment}
        onCreateExperiment={onCreateExperiment}
        onUpdateExperiment={onUpdateExperiment}
        onClosed={onCloseDialog}
        onCancel={onCloseDialog}
      />
    </>
  );
};
