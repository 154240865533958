import { useContent } from 'src/contexts/ContentContext';
import { getActivityLogChangeDisplay } from 'src/utils/activityLogUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { ActivityLogAdditionalInformationDictionaryOfActivityLogValueChange } from 'src/WebApiController';

import {
  activityAdditionalInfoItem,
  activityAdditionalInfoList,
} from './ActivityLog.css';

export const ActivityLogAdditionalInfo = (
  additionalInfo: ActivityLogAdditionalInformationDictionaryOfActivityLogValueChange
) => {
  const entries = Object.entries(additionalInfo);
  const removedContent = useContent(ContentId.Empty);
  return (
    <div className={activityAdditionalInfoList}>
      {entries.map(([k, v]) => (
        <div className={activityAdditionalInfoItem} key={k}>
          {getActivityLogChangeDisplay(k, v, removedContent, true)}
        </div>
      ))}
    </div>
  );
};
