import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';

import {
  usePurchasePaymentMethodSelector,
  UsePurchasePaymentMethodSelectorOptions,
} from './usePurchasePaymentMethodSelector';

export type PurchasePaymentMethodSelectorProps = Omit<
  PosSelectProps,
  'valueOptionsContent'
> &
  UsePurchasePaymentMethodSelectorOptions;

export function PurchasePaymentMethodSelector({
  disabled,
  additionalOptions,
  additionalOptionsPosition,
  ...props
}: PurchasePaymentMethodSelectorProps) {
  const { posSelectProps } = usePurchasePaymentMethodSelector({
    disabled,
    additionalOptions,
    additionalOptionsPosition,
  });
  return <PosSelect {...props} disabled={disabled} {...posSelectProps} />;
}
