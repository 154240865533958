import { useMemo } from 'react';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { useContent } from 'src/contexts/ContentContext';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import {
  EmptySelectionKey,
  getListingDisplaytext,
} from 'src/utils/adGroupUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { getPerformerAndVenueForEvent } from 'src/utils/eventWithDataUtils';
import { Listing } from 'src/WebApiController';

type Listings = {
  listingsData: Record<string, Listing> | null | undefined;
};

export type ListingsSelectorProps = PosSelectProps & Listings;

export const AdGroupListingSelector = ({
  listingsData,
  placeholderText,
  ...props
}: Omit<ListingsSelectorProps, 'valueOptionsContent'>) => {
  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const rowText = useContent(ContentId.Row);
  const seatText = useContent(ContentId.Seat);
  const seatsText = useContent(ContentId.Seats);
  const sectionText = useContent(ContentId.Section);

  const listings: Record<string, string> = useMemo(() => {
    if (!listingsData) {
      return {};
    }
    const results =
      Object.values(listingsData)
        ?.map((l) => {
          const event = l.viagEvId
            ? catalogData?.events?.[l.viagEvId]
            : undefined;
          const { performer, venue } = getPerformerAndVenueForEvent(
            event?.event,
            catalogData
          );
          if (event) {
            return { event: event.event, listing: l, performer, venue };
          }

          return null;
        })
        ?.filter((l) => l != null)
        ?.map((l) => l!)
        ?.reduce(
          (acc, l) => {
            acc[l?.listing.idOnMkp!] = getListingDisplaytext(
              l?.listing,
              sectionText,
              rowText,
              seatText,
              seatsText
            );
            return acc;
          },
          {} as Record<string, string>
        ) ?? {};

    if (Object.values(results).length == 0) {
      results[EmptySelectionKey] = 'N/A';
    }
    return results;
  }, [catalogData, listingsData, rowText, seatText, seatsText, sectionText]);

  return (
    <PosSelect
      {...props}
      searchable={
        props.searchable != null
          ? props.searchable
          : Object.values(listings).length > 10
      }
      loading={listingsData == null}
      placeholderText={placeholderText}
      valueOptionsContent={listings}
    />
  );
};
