import { debounce } from 'lodash-es';
import { useCallback } from 'react';
import { useContent, useFormattedContent } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { SaleQuery } from 'src/WebApiController';

/**
 * Search box for filtering sales on ClassifyEmail modal when classifying sales.
 * @returns
 */
export function SaleSearchBox() {
  const eventsText = useContent(ContentId.Events);
  const searchPlaceholderText = useFormattedContent(
    FormatContentId.SearchItem,
    eventsText
  ) as string;

  const { filterQuery: saleQuery, setFilterQuery } =
    useFilterQueryContext<SaleQuery>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      if (text !== saleQuery.searchText) {
        setFilterQuery({
          ...saleQuery,
          searchText: text || null,
        });
      }
    }, 200),
    [saleQuery, setFilterQuery]
  );

  return (
    <PosSearchBox
      placeholder={searchPlaceholderText}
      onSearchChange={debouncedOnSearchChange}
    />
  );
}
