import { ClientLogClient, LogLevel } from 'src/WebApiController';

import { REACT_APP_NAME } from './constants/constants';

export const logToServer = (
  logLevel: LogLevel,
  errorId: string,
  errorMessage: string,
  additionalInfo: object
) => {
  return new ClientLogClient({}).log({
    logLevel: logLevel,
    applicationSource: REACT_APP_NAME,
    errorId: errorId,
    error: errorMessage,
    pageUrl: window.location.href,
    componentStack: '',
    additionalInfo: additionalInfo,
  });
};
