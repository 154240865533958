import { useFormContext } from 'react-hook-form';
import {
  ListingNoteSelectionInput,
  ListingNoteSelectionInputProps,
} from 'src/components/Input/ListingNoteSelectionInput';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';

export type SeatTraitsSelectionInputProps = {
  ticketGroupIndex: number;
} & Omit<ListingNoteSelectionInputProps, 'listingNotes' | 'onChange'>;

export const SeatTraitsSelectionInput = ({
  ticketGroupIndex,
  disabled,
  validListingNoteIds,
  ...rest
}: SeatTraitsSelectionInputProps) => {
  const { setValue, watch } = useFormContext<PurchaseTicketsInput>();

  const listingNotesField: `ticketGroups.${number}.listingNotes` = `ticketGroups.${ticketGroupIndex}.listingNotes`;
  const listingNotes = watch(listingNotesField);
  return (
    <ListingNoteSelectionInput
      {...rest}
      avoidCollisions={false}
      side="bottom"
      disabled={disabled}
      listingNotes={listingNotes?.value ?? null}
      validListingNoteIds={validListingNoteIds}
      onChange={(listingNotes) =>
        setValue(listingNotesField, posChangedField(listingNotes))
      }
    />
  );
};
