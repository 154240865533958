import { debounce } from 'lodash-es';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { Feature, UserSetting } from 'src/WebApiController';

export enum SidePanelPage {
  General = 'General', // The default
  InventoryEvent = 'InventoryEvent',
  SaleEvent = 'SaleEvent',
  PurchaseEvent = 'PurchaseEvent',
}

export type ISidePanelContext = {
  isCollapsed: boolean;

  // Show small/big panel content
  setCollapsed: (val: boolean) => void;

  // Show/hide the panel
  isHidden: boolean;
  setHidden: (val: boolean) => void;

  // Sidepanel widths
  sidePanelWidths: Record<SidePanelPage, number> | null;
  onUpdateSidePanelWidth: (page: SidePanelPage, width: number) => void;
};

export const createResizablePanelContext =
  React.createContext<ISidePanelContext>({} as ISidePanelContext);

export const useSidePanelContext = () =>
  React.useContext(createResizablePanelContext);

type SidePanelType = 'content' | 'flyover';

type SidePanelPersistedState = Pick<
  ISidePanelContext,
  'isCollapsed' | 'isHidden'
>;

const defaultSidePanelState: SidePanelPersistedState = {
  isCollapsed: false,
  isHidden: false,
};

const getSidePanelKey = (sidePanelId: string): string => {
  return `${SIDE_PANEL_STORAGE_KEY}-${sidePanelId}`;
};

const SIDE_PANEL_STORAGE_KEY = 'sidePanelState';

const persistSidePanelState = (
  sidePanelId: string,
  sidePanelState: SidePanelPersistedState
) => {
  localStorage.setItem(
    getSidePanelKey(sidePanelId),
    JSON.stringify(sidePanelState)
  );
};

const isFlyoverPanel = (panel?: SidePanelType): boolean => {
  return panel === 'flyover';
};

const getSidePanelState = (sidePanelId: string): SidePanelPersistedState => {
  const stringifiedSidePanelState = localStorage.getItem(
    getSidePanelKey(sidePanelId)
  );

  if (!stringifiedSidePanelState) {
    return defaultSidePanelState;
  }

  try {
    return {
      ...defaultSidePanelState,
      ...JSON.parse(stringifiedSidePanelState),
    };
  } catch (e) {
    return defaultSidePanelState;
  }
};

export const SidePanelProvider = ({
  sidePanelId,
  children,
  initialOpened,
  panelType,
}: {
  sidePanelId: string;
  children: React.ReactNode;
  initialOpened?: boolean;
  panelType?: SidePanelType;
}) => {
  const sidePanelType: SidePanelType = panelType || 'content';

  const [state, setState] = useState<SidePanelPersistedState>(() => {
    const loadedState = getSidePanelState(sidePanelId);
    if (isFlyoverPanel(panelType)) {
      loadedState.isHidden = true;
    }

    if (initialOpened) {
      loadedState.isHidden = false;
    }
    return loadedState;
  });

  const { value: sidePanelWidthsSetting, setUserSetting } =
    useServerUserSetting<Record<SidePanelPage, number>>({
      id: UserSetting.SidePanelWidths,
    });
  const hasSidePanelWidthsFeature = useUserHasFeature(Feature.SidePanelWidths);

  const stateRef = useRef<SidePanelPersistedState>(state);
  stateRef.current = state;

  useEffect(() => {
    if (isFlyoverPanel(sidePanelType)) {
      setState({
        ...stateRef.current,
        isHidden: true,
      });
    }
  }, [sidePanelType]);

  useEffect(() => {
    // Don't persist panel state for flyover panel
    if (isFlyoverPanel(sidePanelType)) {
      return;
    }
    persistSidePanelState(sidePanelId, state);
  }, [sidePanelType, sidePanelId, state]);

  const setCollapsed = useCallback((isCollapsed: boolean) => {
    setState({
      ...stateRef.current,
      isCollapsed,
    });
  }, []);

  const setHidden = useCallback((isHidden: boolean) => {
    setState({
      ...stateRef.current,
      isHidden,
    });
  }, []);

  const sidePanelWidths = useMemo((): Record<SidePanelPage, number> | null => {
    if (!hasSidePanelWidthsFeature) {
      return null;
    }
    return sidePanelWidthsSetting ?? null;
  }, [hasSidePanelWidthsFeature, sidePanelWidthsSetting]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUpdateSidePanelWidth = useCallback(
    debounce((page: SidePanelPage, width: number) => {
      if (!hasSidePanelWidthsFeature || sidePanelWidths == null) {
        return;
      }
      const updatedWidths = {
        ...sidePanelWidths,
        [page]: width.toFixed(4),
      };
      setUserSetting(updatedWidths);
    }, 500),
    [setUserSetting, sidePanelWidths, hasSidePanelWidthsFeature]
  );

  return (
    <createResizablePanelContext.Provider
      value={{
        isCollapsed: state.isCollapsed,
        setCollapsed,
        isHidden: state.isHidden,
        setHidden,
        sidePanelWidths,
        onUpdateSidePanelWidth,
      }}
    >
      {children}
    </createResizablePanelContext.Provider>
  );
};
