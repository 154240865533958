import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PurchaseClient } from 'src/WebApiController';

export const useGetIsPurchaseIdsInUsedForVendorId = (
  orderId: string | null | undefined,
  vendorId: number | null | undefined
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const query = useQuery({
    queryKey: ['PurchaseClient.getIsPurchasingIdsInUse', orderId, vendorId],
    queryFn: async () => {
      if (activeAccountWebClientConfig == null || !orderId) {
        return null;
      }

      return await new PurchaseClient(
        activeAccountWebClientConfig
      ).getIsPurchasingIdsInUse(orderId, vendorId);
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PurchaseClient.getIsPurchasingIdsInUse', error, {
          trackErrorData: {
            orderId,
            vendorId,
          },
        });
      },
    },
  });

  return {
    purchasingIdInUseQuery: query,
  };
};
