/* eslint-disable react-hooks/exhaustive-deps */
import { ComponentProps, useEffect, useMemo } from 'react';
import { useList } from 'react-use';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionInfo } from 'src/WebApiController';

import * as styles from './SectionSelectDialog.css';
import { SectionSelector } from './SectionSelector';

export type SectionSelectDialogProps = ComponentProps<typeof RSModal> & {
  initialSectionIds: number[];
  disabledSectionIds?: number[];
  onSave: (selectedSections: SectionInfo[]) => void;
};

export const SectionSelectDialog = ({
  initialSectionIds,
  disabledSectionIds,
  onSave,
  onClosed,
  ...rest
}: SectionSelectDialogProps) => {
  const [selectedSections, selectedSectionsMutations] = useList<SectionInfo>();
  const { venueMapInfo } = useEventMapContext();

  useEffect(() => {
    if (venueMapInfo?.sections) {
      const initialSections = venueMapInfo.sections.filter((section) =>
        initialSectionIds.includes(section.id)
      );
      selectedSectionsMutations.set(initialSections);
    }
  }, [
    JSON.stringify(initialSectionIds),
    JSON.stringify(venueMapInfo?.sections),
  ]);

  const selectedSectionIds = useMemo(() => {
    return selectedSections.map((s) => s.id);
  }, [selectedSections]);

  return (
    <GenericDialog
      {...rest}
      size="lg"
      header={<Content id={ContentId.SelectSections} />}
      footer={
        <Stack direction="row" justifyContent="spaceBetween" width="full">
          {selectedSections.length > 0 ? (
            <Button
              variant={'textPlain'}
              onClick={() => selectedSectionsMutations.clear()}
            >
              <Content id={ContentId.ClearAll} />
            </Button>
          ) : (
            <span></span>
          )}
          <Stack direction="row" gap="m">
            {onClosed && <CancelButton onClick={onClosed} />}
            <Button
              variant={'regular'}
              onClick={() => onSave(selectedSections)}
            >
              <Content id={ContentId.Save} />
            </Button>
          </Stack>
        </Stack>
      }
      onCancel={onClosed}
    >
      <div className={styles.sectionSelectDialogContainer}>
        <SectionSelector
          sectionIds={selectedSectionIds}
          disabledSectionIds={disabledSectionIds}
          onSelectedSectionsChange={selectedSectionsMutations.set}
        />
      </div>
    </GenericDialog>
  );
};
