import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function DeleteIcon(props: IconsProps) {
  return (
    <SvgWrapper aria-label="DeleteIcon" {...props}>
      <path
        d="M13.667 2v1.667h7.5v1.666H5.333v15h12.5V6.167L19.5 7v15H3.667V5.333H2V3.667h7.5V2h4.167zm-3.75 6.25v9.167H8.25V8.25h1.667zm5 0v9.167H13.25V8.25h1.667z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
