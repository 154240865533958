import { Cell } from '@tanstack/react-table';
import clsx from 'clsx';
import { CSSProperties, ReactNode, useRef } from 'react';
import { vars } from 'src/core/themes';
import { PosTableData } from 'src/tables/Table';
import { pinnedCell, tableTd } from 'src/tables/Table/components/TableTd.css';
import { PosGroupingTableTd } from 'src/tables/Table/Table.css';
import { getCommonPinningStyles } from 'src/tables/Table/Table.utils';
import { PinnedCellGradient } from 'src/tables/Table/TableCells/PinnedCellGradient';

interface TableTdProps<T extends PosTableData> {
  cell: Cell<T, unknown>;
  tableCellStyle?: CSSProperties;
  children: ReactNode;
  isLargeDesktop?: boolean;
  isResizeEnabled?: boolean;
  isGrouping?: boolean;
  classNames?: string;
}

export const TableTd = <T extends PosTableData>({
  children,
  tableCellStyle,
  cell,
  isLargeDesktop,
  isResizeEnabled,
  isGrouping,
  classNames,
  ...props
}: TableTdProps<T>) => {
  const isPinned = cell.column.getIsPinned();
  const cellRef = useRef<HTMLTableCellElement | null>(null);

  return (
    <td
      style={{
        ...getCommonPinningStyles(cell.column),
        paddingLeft: isLargeDesktop
          ? cell.column.columnDef.meta?.paddingLeftLargeDesktop
          : cell.column.columnDef.meta?.paddingLeft,

        paddingTop: vars.spacing['sm'],
        paddingBottom: vars.spacing['sm'],

        ...tableCellStyle,
        // These are needed for text overflow to work
        width: isResizeEnabled ? cell.column.getSize() : tableCellStyle?.width,
        minWidth: isResizeEnabled
          ? cell.column.getSize()
          : tableCellStyle?.minWidth,
        maxWidth: isResizeEnabled
          ? cell.column.getSize()
          : tableCellStyle?.maxWidth,
        ...cell.column.columnDef.meta?.styleOverrides,
      }}
      className={clsx(
        isGrouping ? PosGroupingTableTd : undefined,
        tableTd,
        classNames,
        isPinned ? pinnedCell : undefined
      )}
      {...props}
      ref={cellRef}
    >
      {isPinned && (
        <PinnedCellGradient height={cellRef.current?.clientHeight ?? 0} />
      )}
      {children}
    </td>
  );
};
