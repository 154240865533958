import { ComponentProps, useCallback, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PurchaseClient } from 'src/WebApiController';
import styled from 'styled-components/macro';

const RadioLabelDescription = styled.div`
  font-size: ${() => vars.typography.fontSize['sm']};
`;

export type PurchaseCancellationType = 'cancel' | 'void';

export type CancelPurchaseDialogProps = ComponentProps<typeof RSModal> & {
  purchaseOrderId: number;
  numOfSales: number;
  onSave: () => void;
  onCancel: () => void;
};

export function CancelPurchaseDialog({
  purchaseOrderId,
  numOfSales,
  onSave,
  onCancel,
  ...dialogProps
}: CancelPurchaseDialogProps) {
  const [cancellationType, setCancellationType] =
    useState<PurchaseCancellationType>('cancel');
  const [isSecondScreen, setIsSecondScreen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const onInternalSubmit = useCallback(async () => {
    await tryInvokeApi(
      async () => {
        setIsSubmitting(true);

        const results =
          cancellationType === 'cancel'
            ? await new PurchaseClient(
                activeAccountWebClientConfig
              ).cancelPurchaseOrder(purchaseOrderId)
            : await new PurchaseClient(
                activeAccountWebClientConfig
              ).voidPurchaseOrder(purchaseOrderId);

        if (results) {
          onSave();
          setIsSecondScreen(false);
        }
      },
      (error) => {
        showErrorDialog(
          cancellationType === 'cancel'
            ? 'PurchaseClient().cancelPurchaseOrder'
            : 'PurchaseClient().voidPurchaseOrder',
          error,
          {
            trackErrorData: { purchaseOrderId },
          }
        );
      },
      () => setIsSubmitting(false)
    );
  }, [
    activeAccountWebClientConfig,
    cancellationType,
    onSave,
    purchaseOrderId,
    showErrorDialog,
  ]);

  const cancelPurchase = () => {
    setIsSecondScreen(false);
    onCancel();
  };

  if (isSecondScreen) {
    return (
      <GenericDialog
        size="md"
        header={<Content id={ContentId.CancelPurchase} />}
        footer={
          <>
            <CancelButton disabled={isSubmitting} onClick={cancelPurchase} />
            <Button
              disabled={isSubmitting}
              variant={'regular'}
              onClick={() => onInternalSubmit()}
            >
              <Content id={ContentId.Next} />
            </Button>
          </>
        }
        {...dialogProps}
        onCancel={cancelPurchase}
      >
        {isSubmitting ? (
          <PosSpinner />
        ) : numOfSales === 1 ? (
          <Content id={ContentId.SaleForEditTickets} />
        ) : (
          <FormatContent
            id={FormatContentId.SalesForRemovedTickets}
            params={`${numOfSales}`}
          />
        )}
      </GenericDialog>
    );
  }

  return (
    <GenericDialog
      size="md"
      header={<Content id={ContentId.CancelPurchase} />}
      footer={
        <>
          {onCancel && (
            <CancelButton onClick={onCancel} disabled={isSubmitting} />
          )}
          <Button
            disabled={isSubmitting}
            variant={'regular'}
            onClick={() => {
              if (numOfSales > 0) {
                setIsSecondScreen(true);
              } else {
                onInternalSubmit();
              }
            }}
          >
            <Content id={ContentId.Next} />
          </Button>
        </>
      }
      {...dialogProps}
      onCancel={onCancel}
    >
      {isSubmitting ? (
        <PosSpinner />
      ) : (
        <CancelPurchaseDialogModeRadioGroup
          onValueChange={(value) => {
            if (value === 'cancel' || value === 'void') {
              setCancellationType(value);
            }
          }}
          value={cancellationType}
        />
      )}
    </GenericDialog>
  );
}

export const CancelPurchaseDialogModeRadioGroup = ({
  onValueChange,
  value,
  disabled,
}: {
  onValueChange?: ((value: string) => void) | undefined;
  value: PurchaseCancellationType;
  disabled?: boolean;
}) => {
  return (
    <RadioGroup onValueChange={onValueChange} value={value}>
      <Stack direction="column" gap="m">
        <Radio
          value={'cancel' satisfies PurchaseCancellationType}
          disabled={disabled}
          label={
            <Stack direction="column">
              <Content id={ContentId.CancelPurchase} />
              <RadioLabelDescription>
                <Content id={ContentId.CancelPurchaseDescription} />
              </RadioLabelDescription>
            </Stack>
          }
        />
        <Radio
          value={'void' satisfies PurchaseCancellationType}
          disabled={disabled}
          label={
            <Stack direction="column">
              <Content id={ContentId.VoidPurchase} />
              <RadioLabelDescription>
                <Content id={ContentId.VoidPurchaseDescription} />
              </RadioLabelDescription>
            </Stack>
          }
        />
      </Stack>
    </RadioGroup>
  );
};
