import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { forwardRef, useMemo } from 'react';
import { UseMeasureRect } from 'react-use/lib/useMeasure';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { ContentIds } from 'src/utils/constants/contentIdDataMap';

import * as styles from './DistributionPieChart.css';
import { DistributionEntry } from './DistributionPieChart.types';

export type DistributionWidgetProps = {
  titleContentId: ContentId;
  distributions: DistributionEntry[];
  container?: UseMeasureRect;
};

export const DistributionPieChartWidget = forwardRef<
  HTMLDivElement,
  DistributionWidgetProps
>(({ titleContentId, distributions, container }, ref) => {
  const data = useMemo(() => {
    const sum = distributions.reduce((res, { value }) => {
      res += value;
      return res;
    }, 0);
    return distributions
      .sort((a, b) => b.value - a.value)
      .map(({ label, value, color }) => ({
        name: label,
        y: (value * 100) / sum,
        color,
      }));
  }, [distributions]);

  const options: Highcharts.Options = useMemo(
    () => ({
      title: {
        text: '',
        align: 'left',
      },
      chart: {
        width: container?.width ?? 500,
        height: (container?.height ?? 300) - 30,
        backgroundColor: 'transparent',
        spacingBottom: 0,
        marginTop: 0,
        spacingTop: 0,
        marginBottom: 0,
        spacingRight: 0,
        spacingLeft: 28,
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'right',
        verticalAlign: 'middle',
        width: '65%',
        itemStyle: {
          width: 'fit-content' as unknown as never,
          fontSize: vars.typography.fontSize.sm,
          color: vars.color.textStrong,
        },
        itemHoverStyle: {
          color: vars.color.textStrongHover,
        },
        squareSymbol: false,
        symbolWidth: 20,
        symbolRadius: 4,
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
          },
          borderWidth: 2,
        },
        series: {
          allowPointSelect: true,
        },
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      series: [
        {
          showInLegend: true,
          type: 'pie',
          innerSize: '60%',
          name: ContentIds[titleContentId].defaultValue,
          data,
        },
      ],
      credits: {
        enabled: false,
      },
    }),
    [container?.height, container?.width, data, titleContentId]
  );
  return (
    <Stack direction="column" width="fit" className={styles.container}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Stack>
  );
});

DistributionPieChartWidget.displayName = 'DistributionPieChartWidget';
