import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const ListingGroupIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper
      {...props}
      fill="none" // this one cannot have any fill
      viewBox="0 0 25 21" // The viewbox here is important as it won't display properly without this specific value
    >
      <path
        d="M5 5H4V16H21V14"
        stroke="inherit"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 1H7V12H24V1Z"
        stroke="inherit"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 9H1V20H18V18"
        stroke="inherit"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
