import { ProLogo } from 'src/components/common/ProLogo';
import { HamburgerMenuIcon } from 'src/svgs';

import * as styles from './MobileTopBar.css';

type MobileTopBarProps = {
  toggleMenu: () => void;
};

export const MobileTopBar = ({ toggleMenu }: MobileTopBarProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.icon} onClick={toggleMenu}>
        <HamburgerMenuIcon />
      </div>
      <div className={styles.logoContainer}>
        <ProLogo />
      </div>
    </div>
  );
};
