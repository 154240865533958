import { useCallback, useMemo } from 'react';
import { GroupedPaymentsAccordionBody } from 'src/components/Payments/Tabs/components/GroupedPaymentsAccordionBody';
import { GroupedPaymentsAccordionHeader } from 'src/components/Payments/Tabs/components/GroupedPaymentsAccordionHeader';
import * as PosCollapsible from 'src/core/POS/PosCollapsible';
import { MonthContainingMarketplacePaymentsResult } from 'src/WebApiController';
import * as styles from './GroupedPaymentsAccordion.css';

export type GroupedPaymentsAccordionProps = {
  item: MonthContainingMarketplacePaymentsResult;
  selectedIds: string[];
  onSelect: (id: string) => void;
};

export const GroupedPaymentsAccordion = ({
  item,
  selectedIds,
  onSelect,
}: GroupedPaymentsAccordionProps) => {
  const { firstOfMonth } = item;

  const isAccordionOpen = useMemo(
    () => selectedIds.includes(firstOfMonth),
    [firstOfMonth, selectedIds]
  );

  const onOpenChange = useCallback(() => {
    onSelect(firstOfMonth);
  }, [firstOfMonth, onSelect]);

  return (
    <div className={styles.groupedPaymentsAccordionWrapper}>
      <PosCollapsible.Root open={isAccordionOpen} onOpenChange={onOpenChange}>
        <PosCollapsible.Trigger>
          <GroupedPaymentsAccordionHeader
            monthContainingMarketplacePayment={item}
          />
        </PosCollapsible.Trigger>
        <PosCollapsible.Content>
          <GroupedPaymentsAccordionBody firstOfMonth={firstOfMonth} />
        </PosCollapsible.Content>
      </PosCollapsible.Root>
    </div>
  );
};
