import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import { CurrencySection } from '../MyAccount/CurrencySection';
import { SellerAccountSettingCurrencyConversionOverrides } from '../SellerAccountSetting/SellerAccountSettingCurrencyConversionOverrides';
import * as styles from './CurrencyConversion.css';

export const CurrencyConversion = () => {
  const hasDailyFxRateOverrideFeature = useUserHasFeature(
    Feature.OverrideDailyFxRate
  );

  return (
    <div>
      <CurrencySection />
      {hasDailyFxRateOverrideFeature && (
        <>
          <div className={styles.divider} />
          <SellerAccountSettingCurrencyConversionOverrides />
        </>
      )}
    </div>
  );
};
