import { ReactNode } from 'react';
import { FlattenedViewIcon } from 'src/svgs/FlattenedViewIcon';
import { MetricViewIcon } from 'src/svgs/MetricViewIcon';
import { TileViewIcon } from 'src/svgs/TileViewIcon';
import { IconsFill } from 'src/svgs/Viagogo';
import { InventoryViewMode } from 'src/WebApiController';

export const INVENTORY_VIEW_MODE_TO_ICON: Record<string, ReactNode> = {
  [InventoryViewMode.TileView]: (
    <TileViewIcon fill={IconsFill.currentColor} withHoverEffect />
  ),
  [InventoryViewMode.MetricView]: (
    <MetricViewIcon fill={IconsFill.currentColor} withHoverEffect />
  ),
  [InventoryViewMode.FlattenedView]: (
    <FlattenedViewIcon fill={IconsFill.currentColor} withHoverEffect />
  ),
};
