import { useCallback, useMemo } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { useSellerRoleContext } from 'src/contexts/SellerRoleContext';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { shared, vars } from 'src/core/themes';
import { CrossIcon, ProfileOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './ShareReportDialog.css';
import { FieldLabelWrapper, FieldWrapper } from './ShareReportDialog.styled';

export type ShareReportBodyProps = {
  roleIds: number[];
  sellerUserIds: string[];
  onRoleIdsChange: (roleIds: number[]) => void;
  onSellerUserIdsChange: (sellerUserIds: string[]) => void;
  isLoading?: boolean;
};

export function ShareReportBody({
  roleIds,
  sellerUserIds,
  onRoleIdsChange,
  onSellerUserIdsChange,
  isLoading,
}: ShareReportBodyProps) {
  const { allActiveUserInfos } = useSellerAccountContext();
  const { activeAccountUserId } = useAppContext();
  const addUserAndGroupContent = useContent(ContentId.AddUsersAndGroups);

  const valuesAggregated = useMemo(() => {
    const values = [...roleIds.map((rid) => rid.toString()), ...sellerUserIds];
    return values;
  }, [roleIds, sellerUserIds]);

  const { allSellerRoles } = useSellerRoleContext();

  const isRole = useCallback(
    (value: string) => {
      return Object.values(allSellerRoles ?? {}).some(
        (r) => r.id === Number(value)
      );
    },
    [allSellerRoles]
  );

  const keyOptionsContent = useMemo(() => {
    if (allActiveUserInfos == null) return {};

    let keyOptions: Record<string, string> = {};

    if (allActiveUserInfos) {
      keyOptions = Object.entries(allActiveUserInfos)
        .sort((r1, r2) => r1[1].email.localeCompare(r2[1].email))
        .reduce<Record<string, string>>((re, r) => {
          if (r[0] === activeAccountUserId) return re;

          re[r[0]] = r[1].email;
          return re;
        }, {});
    }

    if (allSellerRoles) {
      Object.entries(allSellerRoles).forEach((r) => {
        keyOptions[r[0]] = r[1].name;
      });
    }

    return keyOptions;
  }, [activeAccountUserId, allActiveUserInfos, allSellerRoles]);

  const onSelectionUpdated = useCallback(
    (newValues: string[]) => {
      const newRoleIds = newValues
        .filter((v) => isRole(v))
        .map((v) => Number(v)) as number[];
      const newSellerUserIds = newValues.filter((v) => !isRole(v));

      onRoleIdsChange(newRoleIds);
      onSellerUserIdsChange(newSellerUserIds);
    },
    [isRole, onRoleIdsChange, onSellerUserIdsChange]
  );

  const removeRole = useCallback(
    (role: string) => {
      onRoleIdsChange(roleIds.filter((r) => r !== Number(role)));
    },
    [onRoleIdsChange, roleIds]
  );

  const removeSellerUser = useCallback(
    (sellerUserId: string) => {
      onSellerUserIdsChange(sellerUserIds.filter((r) => r !== sellerUserId));
    },
    [onSellerUserIdsChange, sellerUserIds]
  );

  return (
    <div className={styles.shareReportBodyContainer}>
      <FieldWrapper
        style={{
          gap: vars.spacing['sm'],
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: '0',
        }}
      >
        <PosMultiSelect
          align="start"
          triggerProps={{ style: { width: '100%' } }}
          values={valuesAggregated}
          searchable={true}
          onChange={onSelectionUpdated}
          valueOptionsContent={keyOptionsContent}
          placeholderText={addUserAndGroupContent}
        />
      </FieldWrapper>

      {roleIds.length || sellerUserIds.length ? (
        <div className={styles.shredPeopleList}>
          <FieldLabelWrapper>
            <span className={shared.typography.subtitle2}>
              <Content id={ContentId.PeopleWithAccess} />
            </span>
          </FieldLabelWrapper>
          {roleIds.map((value) => (
            <SharedPeopleListItem
              key={value}
              role={value}
              onDelete={removeRole}
            />
          ))}
          {sellerUserIds.map((value) => (
            <SharedPeopleListItem
              key={value}
              sellerUserId={value}
              onDelete={removeSellerUser}
            />
          ))}
        </div>
      ) : null}

      {isLoading && (
        <FieldWrapper>
          <PosSpinner />
        </FieldWrapper>
      )}
    </div>
  );
}

type SharedPeopleListItemProps = {
  sellerUserId?: string;
  role?: number;
  onDelete?: (value: string) => void;
};

const SharedPeopleListItem = ({
  sellerUserId,
  role,
  onDelete,
}: SharedPeopleListItemProps) => {
  const { allActiveUserInfos } = useSellerAccountContext();
  const { getSellerRole } = useSellerRoleContext();

  const roleInfo = role ? getSellerRole(role) : null;
  const userEmail = useMemo(() => {
    if (!sellerUserId || !allActiveUserInfos) return undefined;

    return allActiveUserInfos[sellerUserId]?.email;
  }, [allActiveUserInfos, sellerUserId]);

  const textDisplay =
    userEmail ?? (roleInfo ? `${roleInfo.name}: ${roleInfo.id}` : '');
  const value = sellerUserId ?? roleInfo?.id?.toString() ?? '';

  const isUser = sellerUserId != null;

  return (
    <div className={styles.sharedPeopleListItem}>
      <div
        className={shared.typography.body2}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: vars.spacing['sm'],
          width: '65%',
        }}
        title={textDisplay}
      >
        {isUser && <ProfileOutlineIcon size={vars.iconSize.xl} />}
        <span
          style={{
            textOverflow: 'ellipsis',
            maxWidth: '85%',
            overflowX: 'clip',
            whiteSpace: 'nowrap',
          }}
        >
          {textDisplay}
        </span>
      </div>
      <ButtonWithIcon
        variant="textPlain"
        shape="none"
        icon={
          <CrossIcon
            withHoverEffect
            fill={vars.color.textBrand}
            size={vars.iconSize.s}
          />
        }
        onClick={() => {
          onDelete?.(value);
        }}
        textContentId={ContentId.RemoveAccess}
        style={{
          fontSize: vars.typography.fontSize.sm,
          whiteSpace: 'nowrap',
        }}
        startWithText
      />
    </div>
  );
};
