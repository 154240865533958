import {
  Listing,
  Permission,
  PurchaseOrder,
  Sale,
  UserDetail,
} from 'src/WebApiController';

export const hasPermission = (
  userDetail: UserDetail | null | undefined,
  permission: Permission
): boolean => {
  const { permissions: userPermissions } = userDetail || {};

  if (!userPermissions) {
    return false;
  }

  return userPermissions.filter((p) => p === permission).length > 0;
};

export const hasAnyOfPermissions = (
  userDetail: UserDetail | null | undefined,
  permissions: Permission[]
): boolean => {
  const { permissions: userPermissions } = userDetail || {};

  if (!userPermissions) {
    return false;
  }

  return userPermissions.filter((p) => permissions.includes(p)).length > 0;
};

// --- All permission-base actions --- //

export const canPriceInventory = (
  userDetail: UserDetail | null | undefined,
  pricerId: string | null
) => {
  // If user is only a pricer, then they can only price inventory where they're set to be the pricer
  if (
    hasPermission(userDetail, Permission.Inventory_SetPriceOnIsPricerOf) &&
    userDetail?.userId === pricerId
  ) {
    return;
  }

  // TODO - POS-865 - handle Permission.Inventory_SetPriceOnPurchasedBy

  return hasPermission(userDetail, Permission.Inventory_SetPrice);
};

export const hasPermissionForListing = (
  userDetail: UserDetail | null | undefined,
  listing: Listing | null | undefined,
  forBulkEdit: boolean | undefined,
  root: Permission,
  isPricerOf?: Permission,
  isPurchasedBy?: Permission
) => {
  if (userDetail == null) {
    return false;
  }
  if (
    hasAnyOfPermissions(userDetail, [root]) ||
    (listing != null && listing.id <= 0) // new create should always allow for current user
  ) {
    return true;
  }

  if (isPricerOf && hasAnyOfPermissions(userDetail, [isPricerOf])) {
    if (forBulkEdit) {
      return true; // for bulk-edit, we don't need to check against listing here
    }
    if (
      listing != null &&
      (listing.pricerId == null || // not priced by anyone means priced by everyone
        listing.pricerId ==
          (userDetail.activeAccount.impersonatedUserId ?? userDetail.userId))
    ) {
      return true;
    }
  }

  if (isPurchasedBy && hasAnyOfPermissions(userDetail, [isPurchasedBy])) {
    if (forBulkEdit) {
      return true; // for bulk-edit, we don't need to check against listing here
    }
    if (
      listing != null &&
      (listing.isCurUserPo ||
        !listing.poBys?.length || // not bought by anyone means bought by everyone
        listing.poBys.includes(
          userDetail.activeAccount.impersonatedUserId ?? userDetail.userId
        ))
    ) {
      return true;
    }
  }

  return false;
};

export const hasPermissionForSale = (
  userDetail: UserDetail | null | undefined,
  sale: Sale | null | undefined,
  root: Permission,
  isFulfillerOf?: Permission,
  isPricerOf?: Permission,
  isPurchasedBy?: Permission
) => {
  if (userDetail == null || sale == null) {
    return false;
  }
  if (
    hasAnyOfPermissions(userDetail, [root]) ||
    sale.id <= 0 // new create should always allow for current user)
  ) {
    return true;
  }

  if (isFulfillerOf && hasAnyOfPermissions(userDetail, [isFulfillerOf])) {
    if (
      sale.fulfillerId == null || // not fulfill by anyone means fulfill by everyone
      sale.fulfillerId ==
        (userDetail.activeAccount.impersonatedUserId ?? userDetail.userId)
    ) {
      return true;
    }
  }

  if (isPricerOf && hasAnyOfPermissions(userDetail, [isPricerOf])) {
    if (
      !sale.owners?.pricerIds?.length || // not priced by anyone means priced by everyone
      sale.owners.pricerIds.includes(
        userDetail.activeAccount.impersonatedUserId ?? userDetail.userId
      )
    ) {
      return true;
    }
  }

  if (isPurchasedBy && hasAnyOfPermissions(userDetail, [isPurchasedBy])) {
    if (
      !sale.owners?.buyerIds?.length || // not bought by anyone means bought by everyones
      sale.owners.buyerIds.includes(
        userDetail.activeAccount.impersonatedUserId ?? userDetail.userId
      )
    ) {
      return true;
    }
  }

  return false;
};

export type PurchaseInfoForPermission = Pick<PurchaseOrder, 'id' | 'buyerId'>;

export const hasPermissionForPurchase = (
  userDetail: UserDetail | null | undefined,
  purchase: PurchaseInfoForPermission | null | undefined,
  root: Permission,
  isPurchasedBy?: Permission,
  allowForNewCreate = true
) => {
  if (userDetail == null) {
    return false;
  }
  const perms = [root];
  if (isPurchasedBy) {
    perms.push(isPurchasedBy);
  }
  if (
    hasAnyOfPermissions(userDetail, perms) ||
    (allowForNewCreate && (purchase == null || purchase.id <= 0))
  ) {
    return true;
  }

  return false;
};
