import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export const HistoryIcon = (props: IconsProps): React.ReactNode => (
  <SvgWrapper {...props}>
    <path
      clipRule="evenodd"
      d="M14.7454 15.7914C17.8825 13.1591 18.2478 8.43275 15.5647 5.23515C13.0213 2.20411 8.62259 1.61557 5.48368 3.85491L4.45334 2.62711L2.92414 7.1916L7.68483 6.47825L6.46347 5.02206L6.51524 4.98552C8.98769 3.29768 12.3937 3.73956 14.436 6.05223L14.5404 6.17352C16.7058 8.75406 16.4113 12.5635 13.8861 14.6823C11.361 16.8012 7.55829 16.4297 5.39296 13.8492C4.83847 13.1883 4.4348 12.4344 4.19584 11.6285L4.13266 11.4154L2.6499 11.7603L2.72047 12C3.01598 13.0042 3.51758 13.9433 4.20721 14.7652C6.89032 17.9628 11.6083 18.4237 14.7454 15.7914ZM5.48368 3.85491C5.46959 3.86496 5.45553 3.87506 5.44149 3.88523L5.4839 3.85516L5.48368 3.85491ZM10.4999 10.4767V6.66667H9.16657V11.1901L11.9634 13.0547L12.703 11.9453L10.4999 10.4767Z"
      fill="inherit"
      fillRule="evenodd"
      transform="translate(3 3) scale(1.35)"
      // eslint-disable-next-line react/no-unknown-property
      transform-origin="center"
    />
  </SvgWrapper>
);
