import {
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { EmailPurchaseOrderContext } from 'src/contexts/EmailPurchaseOrderContext';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { TicketGroupEditContext } from 'src/modals/PurchaseWizard/PurchaseWizard.types';
import {
  PurchaseDeeplinkQueryParam,
  PurchaseFromEmailPoQueryParam,
} from 'src/utils/constants/constants';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import { getEventPerformerVenue } from 'src/utils/eventWithDataUtils';
import { newBigIntId } from 'src/utils/idUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  PurchaseOrderDetailsInput,
  splitOutTicketGroupId,
  toPartialPurchaseOrderToPurchaseOrderDetailsInput,
  toPurchaseOrderDetailsInput,
} from 'src/utils/purchaseUtils';
import { CatalogClient, PurchaseOrderDetails } from 'src/WebApiController';

import { PurchaseWizardBody } from './PurchaseWizardBody';

export const PurchaseWizard = () => {
  const { posEntity: purchase, setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();

  const idParam = getDeepLinkIdFromUrl(
    PurchaseDeeplinkQueryParam,
    window.location.href
  );
  const {
    id: activeId,
    ticketGroupId,
    ticketGroupEditReason,
    ticketGroupEditContext,
  } = useMemo(() => {
    return splitOutTicketGroupId(idParam);
  }, [idParam]);

  const purchaseOrderId = Number(activeId);

  const isFromAutoPo =
    getDeepLinkIdFromUrl(
      PurchaseFromEmailPoQueryParam,
      window.location.href
    ) === 'true';

  useEffect(() => {
    if (isFromAutoPo) {
      if (purchase?.id !== activeId) {
        setActivePosEntity(purchaseOrderId, null, true);
      }
    }
  }, [activeId, isFromAutoPo, purchase, purchaseOrderId, setActivePosEntity]);

  const { isLoading, loadingState } =
    useEventItemLoadingDisplay<PurchaseOrderDetails>(
      FormatContentId.LoadingOrderId,
      FormatContentId.SearchingForOrderId,
      FormatContentId.CouldNotFindOrderId,
      activeId?.toString()
    );

  if (purchaseOrderId > 0) {
    if (!purchase) {
      // If we don't have a purchase yet, either return loading state or nothing
      return loadingState;
    }

    // If we do have a purchase already, only return loadingState if it is not loading (ie, it's something else wrong)
    if (!isLoading && loadingState) return loadingState;
  }

  return (
    <PurchaseOrderDetailsForm
      purchase={purchase}
      loadingState={loadingState}
      highlightTicketGroupIds={
        ticketGroupId != null ? [ticketGroupId] : undefined
      }
      initialTicketGroupEditContext={{
        editIntention: ticketGroupEditReason,
        ...ticketGroupEditContext,
      }}
    />
  );
};

const PurchaseOrderDetailsForm = ({
  purchase,
  loadingState,
  ...rest
}: {
  purchase?: PurchaseOrderDetails | null;
  loadingState?: ReactNode;
  highlightTicketGroupIds?: number[];
  initialTicketGroupEditContext?: Partial<TicketGroupEditContext>;
}) => {
  const { loginContext, activeAccountUserId, activeAccountWebClientConfig } =
    useAppContext();
  const { partialPurchaseOrder } = useContext(EmailPurchaseOrderContext);
  const { data } = useCatalogDataContext();
  const [poInput, setPoInput] = useState<PurchaseOrderDetailsInput | null>(
    null
  );

  const createPurchaseInput = useCallback(async () => {
    const poInput = toPurchaseOrderDetailsInput(
      purchase,
      activeAccountUserId,
      loginContext?.user?.activeAccount?.currencyCode,
      data
    );

    if (poInput) {
      const tgMissingEvents = poInput?.ticketGroups?.filter(
        (tg) => tg.event == null
      );

      if (tgMissingEvents?.length) {
        const missingViagVirtualIds = tgMissingEvents?.map(
          (tg) => tg.viagogoVirtualId
        );
        const smallCatalog = await new CatalogClient(
          activeAccountWebClientConfig
        ).getCatalogForPosEventIds(missingViagVirtualIds);

        tgMissingEvents.forEach((tg) => {
          const viagogoVirtualId = tg.viagogoVirtualId;

          const { event, performer, venue } = getEventPerformerVenue(
            viagogoVirtualId,
            smallCatalog
          );

          tg.event = event?.event ?? null;
          tg.performer = performer ?? null;
          tg.venue = venue ?? null;
        });
      }

      setPoInput(poInput);
    }
  }, [
    activeAccountUserId,
    activeAccountWebClientConfig,
    data,
    loginContext?.user?.activeAccount?.currencyCode,
    purchase,
  ]);

  const createDefaultValues = useCallback(() => {
    if (purchase && !poInput) {
      return undefined;
    }

    return (
      poInput ??
      toPartialPurchaseOrderToPurchaseOrderDetailsInput(
        partialPurchaseOrder,
        activeAccountUserId,
        loginContext?.user?.activeAccount?.currencyCode
      ) ??
      ({
        id: newBigIntId(),
        buyerUserId: posChangedField(activeAccountUserId),
        purchaseDate: posChangedField(new Date().toISOString()),
        currencyCode: posChangedField(
          loginContext?.user?.activeAccount?.currencyCode
        ),
        ticketGroupIntentions: {},
        deal: null,
      } as PurchaseOrderDetailsInput)
    );
  }, [
    activeAccountUserId,
    loginContext?.user?.activeAccount?.currencyCode,
    partialPurchaseOrder,
    poInput,
    purchase,
  ]);

  const methods = useForm<PurchaseOrderDetailsInput>({
    defaultValues: createDefaultValues(),
  });

  useEffect(() => {
    if (purchase && !poInput) {
      createPurchaseInput();
    } else {
      methods.reset(createDefaultValues());
    }
  }, [createDefaultValues, createPurchaseInput, methods, poInput, purchase]);

  if (!methods.formState.defaultValues) {
    // If we have purchase but unable to load the purchase default value yet, return loading state
    return loadingState;
  }

  return (
    <FormProvider {...methods}>
      <PurchaseWizardBody
        {...methods}
        {...rest}
        purchaseHasMergedListings={purchase?.hasMergedListings}
      />
    </FormProvider>
  );
};
