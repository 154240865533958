import { useCallback, useContext, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { ModalContext } from 'src/contexts/ModalContext/ModalContext';
import { vars } from 'src/core/themes';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { OpenLinkIcon } from 'src/svgs/OpenLinkIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { isInFullPageEventView } from 'src/utils/deepLinkUtils';
import {
  getSaleActionButton,
  getSaleDetailsDeepLinkUrl,
  getSaleDetailsModalConfigWithDeepLink,
  getSaleDetailsRelativeUrl,
  NON_MAIN_SALE_ACTIONS,
} from 'src/utils/saleUtils';
import {
  Marketplace,
  PointOfSaleSaleStatus,
  SaleActionType,
  SaleDetails,
  TicketType,
} from 'src/WebApiController';

export const SalesActionsCell = ({
  saleId,
  marketplaceSaleId,
  marketplace,
  saleStatus,
  actions,
  ticketType,
  linkOnly,
  isNoFulfill,
}: {
  saleId: number;
  marketplaceSaleId: string | null;
  marketplace: Marketplace | null;
  saleStatus: PointOfSaleSaleStatus;
  actions: SaleActionType[];
  ticketType: TicketType | null;
  linkOnly?: boolean;
  isNoFulfill?: boolean | null;
}) => {
  const { setActivePosEntity, isLoading } =
    useActivePosEntityContext<SaleDetails>();
  const { setModal } = useContext(ModalContext);
  const { mainRoute } = useAppContext();
  const navigate = useNavigate();

  const onViewClick = useCallback(
    async (newTab: boolean) => {
      const inFullPageEventView = isInFullPageEventView();
      if (newTab) {
        window.open(getSaleDetailsDeepLinkUrl(saleId), '_blank');
      } else if (mainRoute === MainRoute.Sales || inFullPageEventView) {
        setActivePosEntity(saleId, marketplaceSaleId, true);
        setModal(getSaleDetailsModalConfigWithDeepLink(saleId, true));
      } else {
        navigate(getSaleDetailsRelativeUrl(saleId));
      }
    },
    [
      mainRoute,
      setActivePosEntity,
      saleId,
      marketplaceSaleId,
      setModal,
      navigate,
    ]
  );

  const mainActionTypes = useMemo(
    () => actions.filter((a) => !NON_MAIN_SALE_ACTIONS.includes(a)),
    [actions]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      {!linkOnly &&
        (saleStatus === PointOfSaleSaleStatus.PendingConfirmation ||
        saleStatus === PointOfSaleSaleStatus.PendingAllocation ||
        saleStatus === PointOfSaleSaleStatus.Hold ||
        saleStatus === PointOfSaleSaleStatus.CancelledHold ? (
          <>
            {getSaleActionButton(
              saleId,
              marketplaceSaleId,
              marketplace,
              saleStatus,
              mainActionTypes[0],
              ticketType,
              isNoFulfill,
              undefined,
              true,
              undefined,
              isLoading
            )}
            {mainActionTypes.length > 1 &&
              getSaleActionButton(
                saleId,
                marketplaceSaleId,
                marketplace,
                saleStatus,
                actions[1],
                ticketType,
                isNoFulfill,
                undefined,
                true,
                undefined,
                isLoading
              )}
          </>
        ) : mainActionTypes.length > 0 ? (
          getSaleActionButton(
            saleId,
            marketplaceSaleId,
            marketplace,
            saleStatus,
            mainActionTypes[mainActionTypes.length - 1],
            ticketType,
            isNoFulfill,
            undefined,
            true,
            undefined,
            isLoading
          )
        ) : null)}
      <Link
        style={{ padding: `${vars.spacing['xxs']} ${vars.spacing['sm']}` }}
        to={getSaleDetailsRelativeUrl(saleId)}
        onClick={(e) => {
          if (e.button === 0 && e.getModifierState('Control') === false) {
            // Perform like a button click when it's a left-click (and no Ctrl)
            // Else the click will be whatever <a /> does
            e.preventDefault();
            e.stopPropagation();

            onViewClick(!!linkOnly);
          }
        }}
      >
        <IconButton
          icon={
            <OpenLinkIcon
              size={vars.iconSize.m}
              withHoverEffect
              fill={vars.color.textBrand}
            />
          }
          titleContentId={ContentId.ViewDetails}
        />
      </Link>
    </TableCellDiv>
  );
};
