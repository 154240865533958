import { clsx } from 'clsx';
import { ComponentProps, forwardRef, ReactNode } from 'react';

import * as styles from './ToolbarButton.css';

export type ToolbarButtonProps = ComponentProps<'button'> & {
  isSelected?: boolean;
  children?: ReactNode;
};

export const ToolbarButton = forwardRef<HTMLButtonElement, ToolbarButtonProps>(
  function ToolbarButton(
    { isSelected, children, className, ...buttonProps },
    ref
  ) {
    return (
      <button
        ref={ref}
        className={clsx(
          styles.button[isSelected ? 'selected' : 'unselected'],
          className
        )}
        {...buttonProps}
      >
        {children}
      </button>
    );
  }
);
