import { MouseEventHandler, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { DeleteIcon, IconsFill } from 'src/svgs/Viagogo';
import {
  PurchaseOrderDealCommission,
  PurchaseOrderDetailsInput,
} from 'src/WebApiController';

import { ChangeCommissionInput } from './DealConfigHurdle';

export const createDefaultDealCommission = (
  userId: string
): PurchaseOrderDealCommission => ({
  userId,
  splitValue: 0,
});

export const DealConfigSplitCommissionVendorAccount = ({
  vendorCommission,
  onChangeCommission,
}: {
  vendorCommission: PurchaseOrderDealCommission;
  onChangeCommission: (input: number) => void;
}) => {
  const { watch } = useFormContext<PurchaseOrderDetailsInput>();
  const vendorAccount = watch('vendorAccount');

  return (
    <Stack
      direction="row"
      gap="l"
      style={{ marginRight: `calc(${vars.spacing.lg} + ${vars.iconSize.l})` }}
    >
      <PosTextField
        rootProps={{ style: { flex: 2 } }}
        type="text"
        maxLength={200}
        value={vendorAccount?.value?.email}
        disabled={true}
      />

      <PosTextField
        rootProps={{ style: { flex: 1 } }}
        onChange={(e) => onChangeCommission(Number(e.target.value))}
        type="number"
        inputMode="decimal"
        postfixDisplay="%"
        value={Number(vendorCommission.splitValue)}
        min={0}
        max={100}
        maxLength={200}
      />
    </Stack>
  );
};

export const DealConfigSplitCommissionPurchasedBy = ({
  index,
  commission,
  onDeleteCommission,
  onChangeCommission,
}: {
  index: number;
  commission: PurchaseOrderDealCommission;
  onDeleteCommission: MouseEventHandler<SVGElement>;
  onChangeCommission: (input: ChangeCommissionInput) => void;
}) => {
  return (
    <Stack
      direction="row"
      gap="l"
      alignItems="center"
      style={{
        ...(index === 0 && {
          marginRight: `calc(${vars.spacing.lg} + ${vars.iconSize.l})`,
        }),
      }}
    >
      <SellerAccountEmployeeSelector
        allowUnchanged={false}
        value={commission?.userId ?? ''}
        onClick={(e) => e.stopPropagation()}
        onChange={(value) => {
          onChangeCommission({
            changedUserId: value,
            changedValue: null,
            commissionIndex: index,
          });
        }}
        style={{ flex: 2 }}
      />

      <PosTextField
        rootProps={{ style: { flex: 1 } }}
        type="number"
        inputMode="decimal"
        postfixDisplay="%"
        min={0}
        max={100}
        value={commission.splitValue}
        onChange={(e) => {
          onChangeCommission({
            changedUserId: null,
            changedValue: Number(e.target.value),
            commissionIndex: index,
          });
        }}
        maxLength={200}
      />
      {index !== 0 && (
        <DeleteIcon
          fill={IconsFill.currentColor}
          withHoverEffect
          onClick={onDeleteCommission}
        />
      )}
    </Stack>
  );
};
