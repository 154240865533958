import { useState } from 'react';
import { useBulkUpdateAutoFulfillPermissions } from 'src/components/common/BulkActions/BulkUpdateAutoFulfillAction/useBulkUpdateAutoFulfillPermissions';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useSalesBulkActionsState } from 'src/components/Sales/BulkActions/useSalesBulkActionsState';
import { useBulkMarkAsFulfilledAction } from 'src/components/Sales/SalesActionDropdown/components/BulkMarkAsFulfilled';
import { useBulkUpdateAutoFulfillAction } from 'src/components/Sales/SalesActionDropdown/components/BulkUpdateAutoFulfillAction';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useAnyMarketplaceHandledByProcessor } from 'src/hooks/useMarketplaceHandledByProcessor';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  EventWithData,
  Feature,
} from 'src/WebApiController';

interface FulfillmentActionsProps {
  event?: EventWithData['event'];
}

export const FulfillmentActions = ({ event }: FulfillmentActionsProps) => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const [isLoading, setIsLoading] = useState(false);
  const { filterQueryWithEventIds } = useSalesBulkActionsState();
  const bulkUpdateAutoFulfill = useBulkUpdateAutoFulfillAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event
  );

  const bulkMarkAsFulfilled = useBulkMarkAsFulfilledAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    event
  );

  const { hasEditAutoFulfillPermissions } = useBulkUpdateAutoFulfillPermissions(
    {
      entityType: ActionOutboxEntityType.Sale,
    }
  );
  const hasAutoFulfillFeature = useUserHasFeature(Feature.AutoFulfillSetting);
  const hasMarkedAsFulfilled = useUserHasFeature(Feature.SaleMarkedAsFulfilled);
  const anyMkpHandledByProcessor = useAnyMarketplaceHandledByProcessor();

  if (
    (!hasAutoFulfillFeature && !hasMarkedAsFulfilled) ||
    !anyMkpHandledByProcessor ||
    !hasEditAutoFulfillPermissions
  )
    return null;

  return (
    <>
      <PosDropdown
        key="sales-settings-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Fulfillment} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {bulkUpdateAutoFulfill.dropDown}
        {bulkMarkAsFulfilled.dropDown}
      </PosDropdown>
    </>
  );
};
