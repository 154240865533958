import clsx from 'clsx';
import { differenceInDays } from 'date-fns';
import { FormatContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import {
  CalendarOutlineIcon,
  IconsFill,
  ToBeDeterminedIcon,
} from 'src/svgs/Viagogo';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getEventDateDisplayStrings } from 'src/utils/dateTimeUtils';

import * as styles from './EventDateDisplay.css';

export type EventDateDisplayProps = {
  eventDateString?: string;
  /**
   * Optional end date that is used to calculate and show the number of dates after the date.
   *
   * eg. Feb 12 - 2:30pm, Sunday (3 days)
   */
  eventEndDateString?: string;
  /**
   * If `eventDate` is `undefined`, shows a `ToBeDeterminedIcon` instead of the event date if this
   * is set to `true`. Otherwise if `false`, event date will be fully omitted.
   */
  showEventDateTbd?: boolean;
  eventDateFormat?: string;
  eventSubDateFormat?: string;
  slimMode?: boolean;
  className?: string;
};

export function EventDateDisplay({
  slimMode,
  eventDateString,
  eventEndDateString,
  showEventDateTbd,
  eventDateFormat,
  eventSubDateFormat,
  className,
}: EventDateDisplayProps) {
  if (eventDateString) {
    const numDays: number =
      eventDateString && eventEndDateString
        ? differenceInDays(
            new Date(eventEndDateString),
            new Date(eventDateString)
          ) + 1
        : 0;

    const { formattedEventDate, formattedEventSubDate } =
      getEventDateDisplayStrings(eventDateString, {
        dateFormat: eventDateFormat,
        subDateFormat: eventSubDateFormat,
      });
    return (
      <Stack gap="m" alignItems="center" className={className}>
        {!slimMode && (
          <div className={styles.iconWrapper}>
            <CalendarOutlineIcon
              size={vars.iconSize.s}
              fill={IconsFill.textDisabled}
            />
          </div>
        )}
        <Stack
          direction="row"
          flexWrap={slimMode ? undefined : 'wrap'}
          alignItems="center"
        >
          <span
            className={clsx(styles.eventDate, {
              [styles.slimEventDate]: slimMode,
            })}
          >
            {formattedEventDate}
          </span>
          <span
            className={clsx(styles.eventSubDate, {
              [styles.slimEventSubDate]: slimMode,
            })}
          >
            {formattedEventSubDate}
            {numDays > 1 && (
              <>
                {' '}
                (
                <FormatContent
                  id={FormatContentId.NDays}
                  params={numDays.toString()}
                />
                )
              </>
            )}
          </span>
        </Stack>
      </Stack>
    );
  }
  if (showEventDateTbd) {
    return (
      <ToBeDeterminedIcon
        size={vars.iconSize.s}
        fill={IconsFill.textDisabled}
      />
    );
  }
  return null;
}
