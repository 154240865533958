import { ComponentProps, useCallback, useContext } from 'react';
import { CancelButton } from 'src/components/Buttons';
import { ModalContext } from 'src/contexts/ModalContext';
import { ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';

export const BackToModalButton = ({
  textContentId,
  cancelTo,
  ...rest
}: { textContentId?: ContentId } & Omit<
  ComponentProps<typeof CancelButton>,
  'onClick'
> & {
    cancelTo?: ModalProps;
  }) => {
  const { setModal, closeModal } = useContext(ModalContext);
  /**
   * Allow a modal 'config' (`Modal` component props) to determine
   * Which modal to go back to in the workflow
   * If nothing is provided, treat as a close button
   */
  const onCancel = useCallback(() => {
    if (cancelTo) {
      setModal(cancelTo);
    } else {
      closeModal();
    }
  }, [setModal, closeModal, cancelTo]);

  return (
    <CancelButton {...rest} onClick={onCancel} textContentId={textContentId} />
  );
};
