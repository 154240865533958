import { ReactNode, useCallback, useMemo, useRef } from 'react';
import {
  PosEventsAccordionBodyHeight,
  useSetEventsAccordionMaxHeight,
} from 'src/components/Accordions/useSetEventsAccordionMaxHeight';
import { EventVirtualizedList } from 'src/components/Events/EventListing/EventVirtualizedList';
import { EventCheckbox } from 'src/components/Events/EventListing/EventVirtualizedList/EventCheckbox';
import { useGetInventoryGroupingTitle } from 'src/components/Events/EventListing/InventoryEventListing/useGetInventoryGroupingTitle';
import { EventStatus } from 'src/components/Events/EventStatus';
import { InputPriceFocusProvider } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import * as PosCollapsible from 'src/core/POS/PosCollapsible';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import { EventAccordionItemProps } from './EventAccordionItem';
import * as styles from './EventsAccordion.css';
import { eventStatus } from './EventsAccordion.css';

type EventItemProps = {
  onSelect: (ids: string) => void;
  selectedIds?: string[];
  item: EventAccordionItemProps;
  disablePagination: boolean;
};

export const EventItemCollapsible = ({
  item,
  disablePagination,
  selectedIds = [],
  onSelect,
}: EventItemProps) => {
  const {
    HeaderComponent,
    BodyComponent,
    AfterHeaderComponent,
    usingVirtuoso,
    ...rest
  } = item ?? {};
  const accordionBodyRef = useRef<HTMLDivElement | null>(null);
  const accordionHeaderRef = useRef<HTMLDivElement | null>(null);
  const { selectionMode, setSelectionMode } = useMultiSelectionContext();
  const hasEventStatusDisplay = useUserHasFeature(Feature.EventStatusDisplay);

  const isAccordionOpen = useMemo(
    () => selectedIds.includes(item.event.viagVirtualId),
    [item.event.viagVirtualId, selectedIds]
  );

  const isMobile = useMatchMedia('mobile');

  useSetEventsAccordionMaxHeight({
    enabled: usingVirtuoso,
    isExpanded: isAccordionOpen,
    accordionBodyRef,
    accordionHeaderRef,
  });

  const hideBulkActions = useCallback(() => {
    if (
      selectionMode &&
      selectionMode.groupId === item.itemId &&
      selectionMode.mode === MultiSelectScope.SingleGroup
    ) {
      setSelectionMode();
    }
  }, [item.itemId, selectionMode, setSelectionMode]);

  return (
    <div className={styles.itemRow}>
      {selectionMode?.mode === MultiSelectScope.AllGroups && (
        <div className={styles.checkbox}>
          <EventCheckbox eventId={item?.event?.viagVirtualId} />
        </div>
      )}
      <PosCollapsible.Root
        className={styles.collapsibleRoot}
        open={isAccordionOpen}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            hideBulkActions();
          }
          onSelect(item?.event.viagVirtualId);
        }}
      >
        <div
          className={!isMobile ? styles.stickyCollapsibleTrigger : undefined}
          ref={accordionHeaderRef}
        >
          <PosCollapsible.Trigger>
            <div className={styles.itemHeader}>
              {hasEventStatusDisplay && item?.event.eventStatus && (
                <div className={eventStatus}>
                  <EventStatus status={item?.event.eventStatus} />
                </div>
              )}
              <HeaderComponent {...rest} hideYearFromDate={false} />
            </div>
          </PosCollapsible.Trigger>
          {AfterHeaderComponent && <AfterHeaderComponent />}
        </div>
        <PosCollapsible.Content>
          <InputPriceFocusProvider
            disablePagination={disablePagination}
            pageSize={disablePagination ? item.listCnt || item.salesCnt : 10}
          >
            <div
              id="test-expanded"
              ref={accordionBodyRef}
              style={{ height: `var(${PosEventsAccordionBodyHeight},100%)` }}
            >
              <BodyComponent disablePagination={disablePagination} {...rest} />
            </div>
          </InputPriceFocusProvider>
        </PosCollapsible.Content>
      </PosCollapsible.Root>
    </div>
  );
};

export type VirtualizedListingAccordionProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
  onSelect: (id: string) => void;
  selectedIds?: string[];
  items: EventAccordionItemProps[];
  scrollToItemIndex?: number;
  tableActions?: React.ReactElement;
  onItemsRendered?: (items: EventAccordionItemProps[]) => void;
  disablePagination: boolean;
};

export const EventsAccordion = (props: VirtualizedListingAccordionProps) => {
  const { getInventoryEventGroupingText } = useGetInventoryGroupingTitle();

  const getTableDividerText = useCallback(
    (item: EventAccordionItemProps) =>
      getInventoryEventGroupingText(item.event),
    [getInventoryEventGroupingText]
  );

  return (
    <EventVirtualizedList
      {...props}
      getDividerText={getTableDividerText}
      ItemComponent={EventItemCollapsible}
    />
  );
};
