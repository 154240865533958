import React, { useCallback, useState } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { IconsFill, ResetIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  ActivityLogClient,
  ApiException,
  Feature,
  SaleClient,
  SaleDetails,
} from 'src/WebApiController';

import { ResetFulfillmentDialog } from '../components/ResetFulfillmentDialog';

export const LaunchResetFulfillmentV2 = ({
  saleId,
  marketplaceSaleId,
  variant,
  iconOnlyMode,
  disabled,
}: {
  saleId: number;
  marketplaceSaleId?: string | null;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<SaleDetails>();

  const {
    openDialog: openResetFulfillment,
    closeDialog: closeResetFulfillment,
  } = useDialog(DialogId.ResetFulfillment, ResetFulfillmentDialog);

  const resetFulfillment = useCallback(
    (resetArtifacts: boolean) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const client = new SaleClient(activeAccountWebClientConfig);

          const result = await client.resetFulfillment(
            saleId,
            resetArtifacts,
            true
          );
          if (!isSuccess(result)) {
            showErrorDialog(
              'SaleClient.resetFulfillment',
              {
                message: result.message ?? genericError,
                status: result.status!,
              } as ApiException,
              {
                trackErrorData: { saleId },
              }
            );
          } else {
            await setActivePosEntity(saleId, marketplaceSaleId, true);
          }
        },
        (error) => {
          showErrorDialog('SaleClient.resetFulfillment', error, {
            trackErrorData: { saleId },
          });
        },
        () => {
          setIsLoading(false);
          closeResetFulfillment();
        }
      );
    },
    [
      activeAccountWebClientConfig,
      closeResetFulfillment,
      genericError,
      marketplaceSaleId,
      saleId,
      setActivePosEntity,
      showErrorDialog,
    ]
  );

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      new ActivityLogClient(activeAccountWebClientConfig).trackEntityView(
        saleId,
        ActionOutboxEntityType.Sale
      );

      openResetFulfillment({
        onOkay: (resetArtifacts: boolean) => {
          resetFulfillment(resetArtifacts);
          closeResetFulfillment();
        },
        onCancel: closeResetFulfillment,
      });
    },
    [
      activeAccountWebClientConfig,
      saleId,
      openResetFulfillment,
      closeResetFulfillment,
      resetFulfillment,
    ]
  );

  return (
    <ButtonWithIcon
      onClick={onLaunchDialog}
      disabled={disabled || isLoading}
      icon={
        <ResetIcon
          withHoverEffect
          fill={
            !iconOnlyMode && variant == 'regular'
              ? IconsFill.textInverted
              : IconsFill.textNegative
          }
          size={vars.iconSize.m}
        />
      }
      iconOnlyMode={iconOnlyMode}
      variant={variant}
      textContentId={ContentId.ResetFulfillment}
    />
  );
};
