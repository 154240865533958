import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const SaleIdContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  color: ${({ theme: { colors } }) => colors.textPrimary};
`;

export const AttrLabel = styled.span`
  vertical-align: baseline;
  text-transform: uppercase;
  ${({ theme: { typography } }) => typography.getStyles('caption2', 'regular')}
`;

export const AttrValue = styled.span`
  margin: ${() => `0 ${vars.spacing.xxs}`};
  font-size: ${() => vars.typography.fontSize['sm']};
  font-weight: 400;
  line-height: 20px;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      font-size: ${() => vars.typography.fontSize['lg']};
      line-height: 24px;
    }`
    )}`}
`;
