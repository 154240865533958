import signalR, {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { SignalRLogger } from '../NotificationsContext';

export type IListingNotificationContext = {
  hubConnection?: HubConnection;
};

export const ListingNotificationContext =
  createContext<IListingNotificationContext>({
    hubConnection: undefined,
  });

export const useListingNotificationContext = () =>
  useContext(ListingNotificationContext);

export function ListingNotificationContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const hasHandlingListingSignalRFeature = useUserHasFeature(
    Feature.HandlingListingSignalR
  );
  const { activeAccountWebClientConfig } = useAppContext();
  const [hubConnection, setHubConnection] = useState<signalR.HubConnection>();

  useEffect(() => {
    // Only attempt to connect to signal R when there is a login
    if (
      activeAccountWebClientConfig.activeAccountId &&
      hasHandlingListingSignalRFeature
    ) {
      if (
        !hubConnection ||
        hubConnection.state === HubConnectionState.Disconnected ||
        hubConnection.state === HubConnectionState.Disconnecting
      ) {
        const newHubConnection = new HubConnectionBuilder()
          .withUrl('/hubs/listingnotification')
          .withAutomaticReconnect()
          .configureLogging(new SignalRLogger())
          .build();

        setHubConnection(newHubConnection);

        newHubConnection
          .start()
          .then(() => {
            console.debug(
              `ListingNotification connection ready with id: ${newHubConnection.connectionId}`
            );
          })
          .catch((err) => {
            console.warn(
              'ListingNotification could not be started at this time: ' + err
            );
          });

        newHubConnection.onclose((err) => {
          console.debug('ListingNotification connection closed', err);
        });
      }
    }

    return () => {
      if (
        hubConnection &&
        !(
          hubConnection.state === HubConnectionState.Disconnected ||
          hubConnection.state === HubConnectionState.Disconnecting
        )
      ) {
        console.debug(
          `Stopping ListingNotification connection (state: ${hubConnection.state}) with id: ${hubConnection.connectionId}`
        );
        hubConnection.stop();
        setHubConnection(undefined);
      }
    };
  }, [
    activeAccountWebClientConfig.activeAccountId,
    hubConnection,
    hasHandlingListingSignalRFeature,
  ]);

  return (
    <ListingNotificationContext.Provider
      value={{
        hubConnection,
      }}
    >
      {children}
    </ListingNotificationContext.Provider>
  );
}
