import { MouseEvent, useCallback, useContext } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { PurchaseIcon } from 'src/svgs';
import {
  EventIdQueryParam,
  PurchaseDeeplinkQueryParam,
} from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { addDeepLinkUrlPart } from 'src/utils/deepLinkUtils';
import { getPurchaseOrderDetailsModalConfigWithDeepLink } from 'src/utils/purchaseUtils';
import { Event } from 'src/WebApiController';

interface AddPurchaseProps {
  event: Event;
}

export const AddPurchaseAction = ({ event }: AddPurchaseProps) => {
  const { setModal } = useContext(ModalContext);

  const addPurchase = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();

      // To display addPurchaseModal for a concrete event,
      // the URL must contain ?eventId=<eventId>&uniquePurchaseId=0
      addDeepLinkUrlPart(EventIdQueryParam, event.viagVirtualId);
      addDeepLinkUrlPart(PurchaseDeeplinkQueryParam, '0');
      setModal(getPurchaseOrderDetailsModalConfigWithDeepLink());
    },
    [event.viagVirtualId, setModal]
  );

  return (
    <PosDropdownItem key="AddPurchase" onClick={addPurchase}>
      <Stack alignItems="center" gap="m" width="full">
        <PurchaseIcon size={vars.iconSize.m} stroke={vars.color.textPrimary} />
        <Content id={ContentId.AddPurchase} />
      </Stack>
    </PosDropdownItem>
  );
};
