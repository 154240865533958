import { MouseEvent, useCallback, useEffect, useMemo } from 'react';
import { useToggle } from 'react-use';
import { PurchaseDatesAccordion } from 'src/components/Accordions/PurchaseDatesAccordion';
import {
  TicketGroupsEventsAccordion,
  translateEventToTicketGroupAccordionItem,
} from 'src/components/Accordions/TicketGroupsEventsAccordion';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { MultiSelectActionBar } from 'src/components/common/MultiSelect/MultiSelectActionBar';
import { MainFilterBar } from 'src/components/MainFilterBar';
import { PurchasesGlobalBulkActions } from 'src/components/Purchases/BulkActions/PurchasesGlobalBulkActions/PurchasesGlobalBulkActions';
import { PurchaseGlobalActionsPermissionsWrapper } from 'src/components/Purchases/PurchaseFilterBar/PurchaseGlobalActionsPermissionsWrapper';
import {
  SetPurchasesAccordionSelectionCountLabel,
  SetPurchasesFlattenedViewSelectionCountLabel,
  SetPurchasesTimeViewSelectionCountLabel,
} from 'src/components/Purchases/SelectedItemsLabel';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import {
  CatalogDataContextProvider,
  ExpandedEventData,
  useCatalogDataContext,
} from 'src/contexts/CatalogDataContext';
import { CatalogMetricsContextProvider } from 'src/contexts/CatalogMetricsContext';
import { CatalogMultiSelectionContextProvider } from 'src/contexts/CatalogMultiSelectionContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  FilterQueryContextProvider,
  useFilterQueryContext,
} from 'src/contexts/FilterQueryContext';
import {
  MultiSelectionContextProvider,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import {
  PurchaseDataContextProvider,
  usePurchaseDataContext,
} from 'src/contexts/PurchaseDataContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { PurchaseColumnSettingsModal } from 'src/modals/ColumnSettings/PurchaseColumnSettings';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { LayoutIcon } from 'src/svgs';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getPresetFromUiDateTimeRange,
  getUiDateTimeRangeFromPreset,
  PurchaseDateRangePresetNames,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import {
  DefaultPurchaseQuery,
  EmptyPurchaseQuery,
} from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { addTicketGroupMetrics } from 'src/utils/ticketMetricUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { SomethingWentWrong } from 'src/views';
import {
  ActionOutboxEntityType,
  CatalogClient,
  DateTimeRange,
  Feature,
  PosClientConfig,
  PurchaseClient,
  PurchaseOrderDetails,
  PurchaseOrderQuery,
  PurchaseViewMode,
  TicketGroupMetrics,
  UserSetting,
} from 'src/WebApiController';

import { MainRoute } from '../MainRoute';
import { PurchaseFlattenedView } from './PurchaseFlattenedView';
import * as styles from './Purchases.css';
import { PurchaseToolbar } from './PurchaseToolbar';

export const getCatalogData = async (
  client: CatalogClient,
  filterQuery: PurchaseOrderQuery,
  includeCounts: boolean
) => {
  return await client.getCatalogForPurchaseOrders(filterQuery, includeCounts);
};

export const PURCHASE_USER_SETTINGS = [
  UserSetting.PurchaseColumnEditability,
  UserSetting.PurchaseColumnNumberPrecision,
  UserSetting.PurchaseColumnOrder,
  UserSetting.PurchaseColumnsEnabled,
  UserSetting.PurchaseCustomColumns,
  UserSetting.QuickFiltersStatePurchases,
  UserSetting.PurchaseColumnWidths,
  UserSetting.PurchasePageViewMode,
  UserSetting.PurchaseEventColumnOrder,
  UserSetting.PurchaseEventColumnsEnabled,
  UserSetting.PurchaseEventColumnEditability,
  UserSetting.PurchaseEventColumnNumberPrecision,
  UserSetting.PurchaseEventColumnWidths,
  UserSetting.PurchasesDefaultTab,
];

export const purchaseQueryValueTransformToUrl = (
  key: string,
  value: DateTimeRange
) => {
  if (!value) {
    return value;
  }

  const queryKey = key as keyof PurchaseOrderQuery;

  if (queryKey === 'purchaseDates') {
    const preset = getPresetFromUiDateTimeRange(value);
    if (preset && PurchaseDateRangePresetNames.includes(preset.name)) {
      return preset.name;
    }
  } else if (queryKey === 'paymentDates') {
    const preset = getPresetFromUiDateTimeRange(value);
    if (preset && StandardDateRangePresetNames.includes(preset.name)) {
      return preset.name;
    }
  }

  return value;
};

export const purchaseQueryValueTransformFromUrl = (
  key: string,
  value: string
) => {
  if (!value) {
    return value;
  }

  const queryKey = key as keyof PurchaseOrderQuery;
  if (queryKey === 'purchaseDates') {
    const preset = getUiDateTimeRangeFromPreset(
      value,
      PurchaseDateRangePresetNames
    );
    if (preset) {
      return preset;
    }
  } else if (queryKey === 'paymentDates') {
    const preset = getUiDateTimeRangeFromPreset(
      value,
      StandardDateRangePresetNames
    );
    if (preset) {
      return preset;
    }
  }

  return value;
};

export const getCatalogDataExpanded = async (
  viagogoVirtualIds: string[],
  filterQuery: PurchaseOrderQuery,
  {
    activeAccountWebClientConfig,
    onError,
  }: {
    activeAccountWebClientConfig: PosClientConfig;
    onError?: (error: ErrorTypes) => void;
  }
) => {
  const result = await tryInvokeApi(async () => {
    const allTicketGroups = await new PurchaseClient(
      activeAccountWebClientConfig
    ).getTicketGroupsForEvents({
      ...filterQuery,
      // Is is to support the legacy event ID urls
      oldPosEventIds: viagogoVirtualIds
        .filter((id) => id.startsWith('old:'))
        .map((id) => id.substring(4)),
      eventOrMappingIds: viagogoVirtualIds.filter(
        (id) => !id.startsWith('old:')
      ),
    });

    const emptyResults = viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        sales: null,
        listings: null,
        ticketGroups: null,
        failedToRetrieveData: false,
      };
      return results;
    }, {} as ExpandedEventData);

    return Promise.resolve(
      Object.entries(allTicketGroups).reduce((results, [id, ticketGroups]) => {
        results[id] = {
          sales: null,
          listings: null,
          ticketGroups: ticketGroups,
          failedToRetrieveData: false,
        };
        return results;
      }, emptyResults)
    );
  }, onError);

  if (result) {
    return Promise.resolve(result);
  }

  return Promise.resolve(
    viagogoVirtualIds.reduce((results, id) => {
      results[id] = {
        failedToRetrieveData: true,
        sales: null,
        listings: null,
        ticketGroups: null,
      };
      return results;
    }, {} as ExpandedEventData)
  );
};

export function Purchases() {
  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={PURCHASE_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <FilterQueryContextProvider<PurchaseOrderQuery>
        initialQuery={DefaultPurchaseQuery}
        viewModeSettingId={UserSetting.PurchasePageViewMode}
        emptyQuery={EmptyPurchaseQuery}
        queryValueTransformToUrl={purchaseQueryValueTransformToUrl}
        queryValueTransformFromUrl={purchaseQueryValueTransformFromUrl}
        saveQueryInUrl
      >
        <PurchasePage />
      </FilterQueryContextProvider>
    </SellerUserSettingsProvider>
  );
}

export const getCatalogMetrics = (
  client: CatalogClient,
  filterQuery: PurchaseOrderQuery
) => {
  return client.getCatalogTicketGroupMetrics(filterQuery);
};

function PurchasePage() {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { filterQuery } = useFilterQueryContext<PurchaseOrderQuery>();

  const getCatalogDataExpandedCallback = useCallback(
    (viagVirtualIds: string[], transformedFilterQuery: PurchaseOrderQuery) => {
      if (filterQuery.viewMode !== PurchaseViewMode.EventTileView) {
        return Promise.resolve({});
      }

      return getCatalogDataExpanded(viagVirtualIds, transformedFilterQuery, {
        activeAccountWebClientConfig,
        onError: (error) => {
          trackError('PurchaseClient.getTicketGroupsForEvents', error, {
            ...transformedFilterQuery,
            eventIds: viagVirtualIds,
          });
        },
      });
    },
    [activeAccountWebClientConfig, filterQuery.viewMode, trackError]
  );

  return (
    <CatalogDataContextProvider<PurchaseOrderQuery>
      queryKey="getCatalogForPurchaseOrders"
      getCatalogData={(c, f) => getCatalogData(c, f, true)}
      getCatalogDataExpanded={getCatalogDataExpandedCallback}
      transformEventData={transformData}
    >
      <PurchaseDataContextProvider>
        <CatalogMultiSelectionContextProvider
          type={
            filterQuery.viewMode === PurchaseViewMode.EventTileView
              ? 'ticketGroup'
              : 'purchase'
          }
        >
          <MultiSelectionContextProvider>
            <CatalogMetricsContextProvider<
              TicketGroupMetrics,
              PurchaseOrderQuery
            >
              queryKey="getCatalogTicketGroupMetrics"
              getCatalogMetrics={getCatalogMetrics}
              addCatalogMetrics={addTicketGroupMetrics}
              disabled={filterQuery.viewMode !== PurchaseViewMode.EventTileView}
            >
              <PurchasesContent />
            </CatalogMetricsContextProvider>
          </MultiSelectionContextProvider>
        </CatalogMultiSelectionContextProvider>
      </PurchaseDataContextProvider>
    </CatalogDataContextProvider>
  );
}

function PurchasesContent() {
  const purchasesTitle = useContent(ContentId.Purchases);
  const { filterQuery, isQueryInitialized } =
    useFilterQueryContext<PurchaseOrderQuery>();

  const isColumnSelectionEnabled = useUserHasFeature(
    Feature.PurchaseEditColumns
  );
  const { activeAccountWebClientConfig } = useAppContext();

  const {
    eventsTransformed,
    isLoading: isCatalogDataLoading,
    eventsExpansion: { expandedListItems, toggleListItemExpansion },
  } = useCatalogDataContext();
  const onTicketGroupsEventExpanded = useCallback(
    async (viagVirtualId: string) => {
      toggleListItemExpansion([viagVirtualId]);
    },
    [toggleListItemExpansion]
  );

  const {
    timePeriodPurchasesQuery,
    allPurchasesQuery,
    purchaseCount,
    errorInfo,
    selectedIds,
    onSelect,
    scrollToId,
    setScrollToId,
    onSetActivePurchaseOrderCallback,
  } = usePurchaseDataContext();

  const isMobile = useMatchMedia('mobile');

  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  const getActivePosEntity = useCallback(
    async (purchaseId: number) => {
      const purchaseDetails = await new PurchaseClient(
        activeAccountWebClientConfig
      ).getPurchaseOrderByPurchaseOrderId(purchaseId, false);
      if (purchaseDetails) {
        return {
          posEntityId: purchaseDetails.id,
          posEntity: purchaseDetails,
          event: null,
          posEntityDisplayId: purchaseDetails.id.toString(),
        };
      }

      return {};
    },
    [activeAccountWebClientConfig]
  );

  const ticketGroupItems = useMemo(() => {
    // Don't transform events if they are not used in the current view mode
    if (
      !filterQuery.viewMode ||
      filterQuery.viewMode !== PurchaseViewMode.EventTileView
    ) {
      return [];
    }
    // Only for PurchaseViewMode.EventTileView
    return (
      eventsTransformed?.map((e, i) =>
        translateEventToTicketGroupAccordionItem(e, i)
      ) || []
    );
  }, [eventsTransformed, filterQuery.viewMode]);

  const { clearShiftKeySelection, setSelectionMode } =
    useMultiSelectionContext();

  useEffect(() => {
    clearShiftKeySelection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery.viewMode]);

  const dataIsLoading =
    !isQueryInitialized ||
    filterQuery.viewMode === PurchaseViewMode.EventTileView
      ? isCatalogDataLoading || eventsTransformed == null
      : filterQuery.viewMode === PurchaseViewMode.FlattenedView
      ? isCatalogDataLoading ||
        eventsTransformed == null ||
        allPurchasesQuery.isLoading
      : timePeriodPurchasesQuery.isLoading ||
        timePeriodPurchasesQuery.data == null;

  const hasData =
    filterQuery.viewMode === PurchaseViewMode.EventTileView
      ? ticketGroupItems.length
      : filterQuery.viewMode === PurchaseViewMode.FlattenedView
      ? purchaseCount > MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS ||
        allPurchasesQuery.data?.length
      : timePeriodPurchasesQuery.data?.length;

  // Reset and close selection mode when changing view
  useEffect(() => {
    setSelectionMode();
  }, [filterQuery.viewMode, setSelectionMode]);

  return (
    <ActivePosEntityProvider<PurchaseOrderDetails>
      entityType={ActionOutboxEntityType.Purchase}
      getActivePosEntity={getActivePosEntity}
      onSetActiveItemCompleteCallback={onSetActivePurchaseOrderCallback}
    >
      <DialogProvider>
        <LayoutContent
          mainRoute={MainRoute.Purchases}
          routeTitle={purchasesTitle}
        >
          <MainFilterBar />
          <div className={styles.contentContainer}>
            {dataIsLoading && <PosSpinner />}
            {errorInfo && (
              <SomethingWentWrong
                header={errorInfo.errorHeader}
                message={
                  errorInfo.errorMessage ?? (
                    <Content id={ContentId.FailToLoadListContent} />
                  )
                }
              />
            )}
            {!dataIsLoading && !errorInfo && (
              <>
                {!isMobile && (
                  <PurchaseToolbar
                    viewMode={filterQuery.viewMode}
                    onColumnSettingButtonClickHandler={
                      onColumnSettingButtonClickHandler
                    }
                  />
                )}
                {hasData ? (
                  filterQuery.viewMode === PurchaseViewMode.FlattenedView ? (
                    <PurchaseFlattenedView
                      topListItemBefore={
                        <PurchaseGlobalActionsPermissionsWrapper>
                          <SetPurchasesFlattenedViewSelectionCountLabel>
                            <MultiSelectActionBar>
                              <PurchasesGlobalBulkActions />
                            </MultiSelectActionBar>
                          </SetPurchasesFlattenedViewSelectionCountLabel>
                        </PurchaseGlobalActionsPermissionsWrapper>
                      }
                    />
                  ) : filterQuery.viewMode ===
                    PurchaseViewMode.EventTileView ? (
                    <TicketGroupsEventsAccordion
                      topListItemBefore={
                        <PurchaseGlobalActionsPermissionsWrapper>
                          <SetPurchasesAccordionSelectionCountLabel
                            events={eventsTransformed}
                          >
                            <MultiSelectActionBar>
                              <PurchasesGlobalBulkActions isEventView />
                            </MultiSelectActionBar>
                          </SetPurchasesAccordionSelectionCountLabel>
                        </PurchaseGlobalActionsPermissionsWrapper>
                      }
                      disablePagination
                      items={ticketGroupItems}
                      selectedIds={expandedListItems}
                      onSelect={onTicketGroupsEventExpanded}
                      tableActions={
                        <>
                          {isColumnSelectionEnabled && (
                            <Button
                              variant="textPlain"
                              onClick={onColumnSettingButtonClickHandler}
                            >
                              <LayoutIcon size={vars.iconSize.m} />
                              <Content id={ContentId.Columns} />
                            </Button>
                          )}
                        </>
                      }
                    />
                  ) : (
                    <PurchaseDatesAccordion
                      topListItemBefore={
                        <PurchaseGlobalActionsPermissionsWrapper>
                          <SetPurchasesTimeViewSelectionCountLabel>
                            <MultiSelectActionBar>
                              <PurchasesGlobalBulkActions />
                            </MultiSelectActionBar>
                          </SetPurchasesTimeViewSelectionCountLabel>
                        </PurchaseGlobalActionsPermissionsWrapper>
                      }
                      selectedIds={selectedIds}
                      onSelect={onSelect}
                      items={timePeriodPurchasesQuery.data ?? []}
                      scrollToId={scrollToId}
                      onScroll={() => {
                        if (scrollToId) setScrollToId(undefined);
                      }}
                      tableActions={
                        <>
                          {isColumnSelectionEnabled && (
                            <Button
                              variant="textPlain"
                              onClick={onColumnSettingButtonClickHandler}
                            >
                              <LayoutIcon size={vars.iconSize.m} />
                              <Content id={ContentId.Columns} />
                            </Button>
                          )}
                        </>
                      }
                    />
                  )
                ) : (
                  <EmptySectionContent.Root
                    icon={
                      <EmptySectionContent.SolidIconContainer>
                        <SearchSolidIcon />
                      </EmptySectionContent.SolidIconContainer>
                    }
                  >
                    <EmptySectionContent.Label>
                      <Content id={ContentId.NoResultFound} />
                    </EmptySectionContent.Label>
                    <EmptySectionContent.DetailMessage>
                      <Content id={ContentId.NoEventsMatching} />
                    </EmptySectionContent.DetailMessage>
                  </EmptySectionContent.Root>
                )}
                {isColumnSelectionEnabled && isColumnModalOpen ? (
                  <PurchaseColumnSettingsModal
                    sectionType={
                      filterQuery.viewMode === PurchaseViewMode.EventTileView
                        ? SectionType.PurchaseEvent
                        : SectionType.Purchases
                    }
                    onClose={toggleColumnModal}
                    viewMode={filterQuery.viewMode}
                  />
                ) : null}
              </>
            )}
          </div>
        </LayoutContent>
      </DialogProvider>
    </ActivePosEntityProvider>
  );
}
