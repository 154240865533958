import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const EventIcon = (props: PosIconProps) => {
  const stroke = props.stroke || '#3F1D75';
  return (
    <SvgWrapper {...props} viewBox="0 0 24 24">
      <path
        d="M4.5 2.5H0.5V23.5H23.5V2.5H19.5"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 0.5H4.5V4.5H7.5V0.5Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 0.5H16.5V4.5H19.5V0.5Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 2.5H16.5"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 7.5H23.5"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 10L13.5 13.5H17.5L14 16L16 20.5L12 17.5L8 20.5L10 16L6.5 13.5H10.5L12 10Z"
        stroke={stroke}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
