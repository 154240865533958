import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_XL,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import * as PosCollapsible from 'src/core/POS/PosCollapsible';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { ForwardIcon } from 'src/svgs/Viagogo';
import { TableShimmeringDiv } from 'src/tables/Table';
import { AdCampaignsTableColumnId } from 'src/utils/columns/adCampaigns/adCampaignsColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID as ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID } from 'src/utils/constants/contentIdMaps';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { MoneyType, UiMoney } from 'src/WebApiController';

import {
  AdCampaignEntityWithMetrics,
  SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS as SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS,
} from '../AdCampaignsTable.type';
import { AdCampaignActionsCell } from './AdCampaignsActionsCell';
import { AdCampaignsDateCell } from './AdCampaignsDateCell';
import * as styles from './AdCampaignsTableColumnsConfig.css';

const columnHelper = createColumnHelper<AdCampaignEntityWithMetrics | null>();

export const ADCAMPAIGNS_TABLE_COLUMNS_CONFIG: ColumnDef<
  AdCampaignEntityWithMetrics | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>[] = [
  columnHelper.accessor((data) => (data ? data.name || '' : null), {
    id: AdCampaignsTableColumnId.Name,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdCampaignsTableColumnId.Name
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      if (!original) {
        return (
          <TableShimmeringDiv
            height={SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS}
          />
        );
      }

      return <TableCellDiv align="left">{getValue<string>()}</TableCellDiv>;
    },
  }),
  columnHelper.accessor((data) => (data ? data.startDateTime || '' : null), {
    id: AdCampaignsTableColumnId.StartDateTime,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdCampaignsTableColumnId.StartDateTime
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    enableSorting: true,
    cell: ({
      getValue,
      row: { original },
      table: {
        options: { meta },
      },
    }) => {
      const rowSupportsAccordion = meta?.rowSupportsAccordion;

      const value = getValue();
      if (!original) {
        return (
          <TableShimmeringDiv
            height={SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS}
          />
        );
      }

      return (
        <TableCellDiv align="left">
          <Stack direction="row" gap="m">
            {rowSupportsAccordion && (
              <PosCollapsible.Trigger
                onClick={(e) => e.stopPropagation()}
                className={styles.adCampaignEntityTableCollapseTrigger}
                FoldIcon={ForwardIcon}
                iconSize={vars.iconSize.s}
              />
            )}
            <AdCampaignsDateCell date={value ? new Date(value) : null} />
          </Stack>
        </TableCellDiv>
      );
    },
    sortingFn: 'datetime',
  }),
  columnHelper.accessor((data) => (data ? data.endDateTime || '' : null), {
    id: AdCampaignsTableColumnId.EndDateTime,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdCampaignsTableColumnId.EndDateTime
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_XL,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    enableSorting: true,
    cell: ({
      getValue,
      row: { original },
      table: {
        options: { meta },
      },
    }) => {
      const rowSupportsAccordion = meta?.rowSupportsAccordion;

      const value = getValue();
      if (!original) {
        return (
          <TableShimmeringDiv
            height={SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS}
          />
        );
      }

      return (
        <TableCellDiv align="left">
          <Stack direction="row" gap="m">
            {rowSupportsAccordion && (
              <PosCollapsible.Trigger
                onClick={(e) => e.stopPropagation()}
                className={styles.adCampaignEntityTableCollapseTrigger}
                FoldIcon={ForwardIcon}
                iconSize={vars.iconSize.s}
              />
            )}
            <AdCampaignsDateCell date={value ? new Date(value) : null} />
          </Stack>
        </TableCellDiv>
      );
    },
    sortingFn: 'datetime',
  }),
  columnHelper.accessor((data) => (data ? data.budget : null), {
    id: AdCampaignsTableColumnId.Budget,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdCampaignsTableColumnId.Budget
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<MoneyType | null>();
      const { getUiCurrency } = useLocalizationContext();
      const currency = getUiCurrency(value?.currencyCode);

      if (!original) {
        return (
          <TableShimmeringDiv
            height={SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS}
          />
        );
      }

      if (value == null || value == undefined)
        return <Content id={ContentId.NA} />;

      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            inputNumber: value.amount,
            currencyCode: currency.code,
            currencyDecimalDigits: currency.dec,
          })}
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.state || '' : null), {
    id: AdCampaignsTableColumnId.State,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdCampaignsTableColumnId.State
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      // TODO
      const value = getValue() as string;

      if (!original) {
        return (
          <TableShimmeringDiv
            height={SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS}
          />
        );
      }

      return <TableCellDiv align="left">{value}</TableCellDiv>;
    },
  }),
  columnHelper.accessor((data) => (data ? data.adSpend : null), {
    id: AdCampaignsTableColumnId.AdSpend,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdCampaignsTableColumnId.AdSpend
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<UiMoney | null | undefined>();
      const { getUiCurrency } = useLocalizationContext();
      const currency = getUiCurrency(value?.currency);

      if (!original || value === undefined) {
        return (
          <TableShimmeringDiv
            height={SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS}
          />
        );
      }

      if (value === null) return <Content id={ContentId.NA} />;

      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            inputNumber: value.amt,
            currencyCode: currency.code,
            currencyDecimalDigits: currency.dec,
          })}
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.salesProceeds : null), {
    id: AdCampaignsTableColumnId.SoldProceeds,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content
          id={
            ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdCampaignsTableColumnId.SoldProceeds
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: function Cell({ getValue, row: { original } }) {
      const value = getValue<UiMoney | null | undefined>();
      const { getUiCurrency } = useLocalizationContext();
      const currency = getUiCurrency(value?.currency);

      if (!original || value === undefined) {
        return (
          <TableShimmeringDiv
            height={SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS}
          />
        );
      }

      if (value === null) return <Content id={ContentId.NA} />;

      return (
        <TableCellDiv align="right">
          {applyNumberFormatting({
            inputNumber: value.amt,
            currencyCode: currency.code,
            currencyDecimalDigits: currency.dec,
          })}
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => (data ? data.qtySold : null), {
    id: AdCampaignsTableColumnId.SoldQty,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content
          id={
            ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdCampaignsTableColumnId.SoldQty
            ]
          }
        />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_LG,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ getValue, row: { original } }) => {
      const value = getValue();
      if (!original || value === undefined) {
        return (
          <TableShimmeringDiv
            height={SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS}
          />
        );
      }

      if (value === null) return <Content id={ContentId.NA} />;

      return <TableCellDiv align="right">{value}</TableCellDiv>;
    },
  }),
  // columnHelper.accessor((data) => (data ? data.currencyCode : null), {
  //   id: AdCampaignsTableColumnId.CurrencyCode,
  //   header: () => (
  //     <TableHeaderCellDiv align="right">
  //       <Content
  //         id={
  //           ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID[
  //             AdCampaignsTableColumnId.CurrencyCode
  //           ]
  //         }
  //       />
  //     </TableHeaderCellDiv>
  //   ),
  //   size: COLUMN_DEFAULT_SIZE_BASE,
  //   minSize: COLUMN_MIN_SIZE,
  //   maxSize: COLUMN_MAX_SIZE,
  //   cell: ({ getValue, row: { original } }) => {
  //     const value = getValue();
  //     if (!original) {
  //       return (
  //         <TableShimmeringDiv
  //           height={SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS}
  //         />
  //       );
  //     }
  //     return <TableCellDiv align="right">{value ?? 0}</TableCellDiv>;
  //   },
  // }),
  columnHelper.display({
    id: AdCampaignsTableColumnId.Actions,
    enableSorting: false,
    header: () => (
      <TableCellDiv align="right">
        <Content
          id={
            ADCAMPAIGNS_TABLE_COLUMN_ID_TO_CONTENT_ID[
              AdCampaignsTableColumnId.Actions
            ]
          }
        />
      </TableCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_BASE,
    minSize: COLUMN_MIN_SIZE,
    maxSize: COLUMN_MAX_SIZE,
    cell: ({ row: { original } }) => {
      return !original ? (
        <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS} />
      ) : (
        <AdCampaignActionsCell adCampaignId={original.campaignId} />
      );
    },
  }),
];
