import { useQuery } from '@tanstack/react-query';
import { head, uniqBy } from 'lodash-es';
import { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useSyncCenterSettings } from 'src/hooks/api/useSyncCenterSetting';
import { getSectionInfoDisplay } from 'src/utils/autoPricingUtils';
import { filterSectionByZone } from 'src/utils/venueConfigUtils';
import {
  ListingClient,
  ListingDetailsPricingUpdates,
  ListingPriceCalculation,
  SectionInfo,
  VenueMapInfo,
} from 'src/WebApiController';

type AutoPricingPreviewQueryKeyType =
  | {
      listingId: number | undefined;
      eventId: number | null;
      listingInput: ListingDetailsPricingUpdates | undefined;
    }
  | undefined;

export const useAutoPricingPreview = (
  viagogoEventId: number | null,
  listingId?: number | undefined,
  listingInput?: ListingDetailsPricingUpdates
) => {
  const { data } = useSyncCenterSettings();

  const [queryKey, setQueryKey] =
    useState<AutoPricingPreviewQueryKeyType>(undefined);

  useDebounce(
    () =>
      setQueryKey({
        eventId: viagogoEventId,
        listingId,
        listingInput: listingInput,
      }),
    300,
    [viagogoEventId, listingId, JSON.stringify(listingInput)]
  );

  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const shouldQuery = Boolean(
    activeAccountWebClientConfig.activeAccountId != null &&
      queryKey?.eventId &&
      queryKey?.listingId &&
      queryKey?.listingInput
  );

  const priceCalcQuery = useQuery({
    queryKey: ['ListingClient.getListingPriceCalculation', queryKey],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const r = await new ListingClient(
        activeAccountWebClientConfig
      ).getListingPriceCalculation(
        queryKey?.eventId ?? undefined,
        queryKey?.listingId ?? undefined,
        data?.priceByNetProceeds ?? undefined,
        queryKey?.listingInput!
      );
      return r;
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'ListingClient.getListingPriceCalculation',
          error,
          listingInput
        );
      },
    },
  });

  return { priceCalcQuery };
};

export const useVenueMapHandlers = (
  filterSections: SectionInfo[],
  pricingPreview?: ListingPriceCalculation,
  venueMapInfo?: VenueMapInfo | null,
  onSelectedSectionsChange?: (selectedSections: SectionInfo[]) => void
) => {
  const onShiftSelect = useCallback(
    (sectionInfo: SectionInfo) => {
      // We are using the ticketClassId to group the sections
      const selectedClassId = head(
        sectionInfo?.rows
          ?.map(({ tktClass: ticketClass }) => ticketClass?.id)
          .filter((id) => !!id)
      );
      const classSections =
        venueMapInfo?.sections?.filter(filterSectionByZone(selectedClassId)) ||
        [];

      const classedSectionIds = classSections.map(({ id }) => id);
      const filteredSeciontIds = filterSections.map(({ id }) => id);
      let cloned = [...filterSections];
      if (classedSectionIds.every((id) => filteredSeciontIds.includes(id))) {
        cloned = cloned.filter(({ id }) => !classedSectionIds.includes(id));
      } else {
        for (const section of classSections) {
          if (!filteredSeciontIds.includes(section.id)) {
            cloned.push(section);
          }
        }
      }
      onSelectedSectionsChange?.(cloned);
    },
    [filterSections, onSelectedSectionsChange, venueMapInfo?.sections]
  );

  const onSingleSelect = useCallback(
    (section: SectionInfo) => {
      const idx = filterSections.findIndex(({ id }) => id === section.id);
      const cloned = [...filterSections];
      if (idx === -1) {
        cloned.push(section);
      } else {
        cloned.splice(idx, 1);
      }
      onSelectedSectionsChange?.(cloned);
    },
    [filterSections, onSelectedSectionsChange]
  );

  const onSectionClicked = useCallback(
    (e: MouseEvent, sectionInfo: SectionInfo) => {
      if (e.shiftKey) {
        onShiftSelect(sectionInfo);
        return;
      }

      onSingleSelect(sectionInfo);
    },
    [onSingleSelect, onShiftSelect]
  );

  const onToggleMirrors = useCallback(
    (mirrors: SectionInfo[], exclude?: boolean) => {
      if (exclude) {
        const idsToExclude = mirrors.map(({ id }) => id);
        const updated = uniqBy(
          filterSections.filter(({ id }) => !idsToExclude.includes(id)),
          'id'
        ).sort((a, b) => a.name.localeCompare(b.name));
        onSelectedSectionsChange?.(updated);
      } else {
        const updated = uniqBy(filterSections.concat(mirrors), 'id').sort(
          (a, b) => a.name.localeCompare(b.name)
        );
        onSelectedSectionsChange?.(updated);
      }
    },
    [filterSections, onSelectedSectionsChange]
  );

  const onSectionHovered = useCallback(
    (hoveredSection: SectionInfo) => {
      return getSectionInfoDisplay(
        hoveredSection,
        pricingPreview?.compListings ?? [],
        venueMapInfo?.sectionScores
      );
    },
    [pricingPreview?.compListings, venueMapInfo?.sectionScores]
  );

  return {
    onSectionClicked,
    onToggleMirrors,
    onSectionHovered,
  };
};
