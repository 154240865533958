/* eslint-disable react-hooks/exhaustive-deps */
import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { useGetAccountAutoPricingSettings } from 'src/hooks/useGetAccountAutoPricingSettings';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { validateAutoPricingSettings } from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getListingDetailsUpdateInput,
  isListingDetailsInputChanged,
} from 'src/utils/inventoryUtils';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  Event,
  Listing,
  ListingDetails,
  ListingDetailsUpdateInput,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';
import { AutoPricingSettings } from './components/AutoPricingSettings';
import { GeneralSettings } from './components/GeneralSettings';

export type BulkEditListingSettingsDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  isLoading?: boolean;
  listings?: Listing[] | null;
  selectedListingIds?: number[];
  event?: Event;
  onOkay: (
    listing: ListingDetailsUpdateInput | null,
    hasPurchaseUpdate: boolean,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel?: () => void;
  bulkEditMode: 'autoPricing' | 'general';
};

export const BulkEditListingSettingsDialog = ({
  listings,
  ...rest
}: BulkEditListingSettingsDialogProps) => {
  const methods = useForm<ListingDetailsUpdateInput>({
    defaultValues: getListingDetailsUpdateInput(
      null,
      null,
      [],
      null,
      null,
      true
    ),
  });

  return (
    <FormProvider {...methods}>
      <BulkEditListingSettingsDialogContent
        listings={listings}
        {...rest}
        {...methods}
      />
    </FormProvider>
  );
};

function BulkEditListingSettingsDialogContent({
  updateKey,
  isLoading,
  listings,
  bulkEditMode,
  selectedListingIds,
  event,
  onOkay,
  onCancel,
  handleSubmit,
  getValues,
  clearErrors,
  setError,
  setValue,
  watch,
  reset,
  formState,
  ...rest
}: BulkEditListingSettingsDialogProps &
  Omit<
    ComponentProps<typeof FormProvider<ListingDetailsUpdateInput, unknown>>,
    'children'
  >) {
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();

  const { pricingSettings } = useGetAccountAutoPricingSettings();

  const input = watch();

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);
        await refreshExpandedListItems();
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel?.();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, stage, preview, initJob } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    bulkEditMode === 'autoPricing'
      ? BulkActionType.UpdateAutoPricingSettings
      : BulkActionType.UpdateGeneralSettings,
    updateKey,
    onBulkEditDone
  );

  const selectedListings = useMemo(() => {
    const flattenedListings = listings?.flatMap((l) => flattenListingGroup(l));
    return flattenedListings
      ?.filter((l) => l.isFull && selectedListingIds?.includes(l.id))
      .map((l) => l as Listing);
  }, [listings, selectedListingIds]);

  const hasParents = useMemo(() => {
    return selectedListings?.every((l) => !!l.ltGrpParentId);
  }, [selectedListings]);

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const floorMustBeLessThanCeilingError = useContent(
    ContentId.FloorMustBeLessThanCeiling
  );

  const compListingFloorCeilingError = useContent(
    ContentId.CompListingFloorMustLessThanCeiling
  );

  const requiredMsg = useContent(ContentId.Required);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      const listForm = getValues();
      if (
        !validateAutoPricingSettings(
          clearErrors,
          setError,
          setValue,
          listForm,
          floorMustBeLessThanCeilingError,
          compListingFloorCeilingError,
          requiredMsg
        )
      )
        return;

      if (onPreviewReceived) {
        const hasPurchaseUpdate = listForm.buyerUserId != null;
        onOkay(null, hasPurchaseUpdate, false, onPreviewReceived);
      } else {
        handleSubmit((i) =>
          onOkay(
            i,
            i.buyerUserId != null,
            hasBackgroundBulkEditFeature,
            undefined,
            preview
          )
        )();
      }
    },
    [onOkay, preview, hasBackgroundBulkEditFeature]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const isInputValid = useMemo(() => {
    return isListingDetailsInputChanged(
      input,
      formState.defaultValues as ListingDetailsUpdateInput
    );
  }, [input, formState.defaultValues]);

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <GenericDialog
        {...rest}
        size={
          stage === BulkEditStage.Preview
            ? 'xl'
            : bulkEditMode == 'autoPricing'
            ? 'xl'
            : 'md'
        }
        header={
          <BulkEditHeader
            headerText={
              <Content
                id={
                  bulkEditMode === 'autoPricing'
                    ? ContentId.AutopricingSettings
                    : ContentId.ListingSettings
                }
              />
            }
          />
        }
        onOpened={() => {
          reset(
            getListingDetailsUpdateInput(
              null,
              null,
              [],
              pricingSettings,
              null,
              true
            )
          );
          initJob();
        }}
        onKeyUp={(e) => {
          if (submittButtonRef.current && e.key === 'Enter' && isInputValid) {
            submittButtonRef.current.click();
          }
        }}
        onClosed={() => {
          setMainDialogOpened(false);
          if (progress) {
            setSelectionMode(undefined);
          }

          rest.onClosed?.();
        }}
        footer={
          <BulkEditFooter
            entityType={ActionOutboxEntityType.Listing}
            isLoading={isLoading}
            hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
            onClose={onClose}
            onSubmit={onSubmitHandler}
            disabled={!isInputValid}
            submittButtonRef={submittButtonRef}
          />
        }
        onCancel={isLoading ? undefined : onCancel}
      >
        <BulkEditStatus
          entityType={ActionOutboxEntityType.Listing}
          isLoading={isRefreshing || isLoading}
          updateKey={updateKey}
        >
          <Stack direction="column" width="full" gap="s">
            <EventMapContextProvider event={event}>
              {bulkEditMode === 'autoPricing' && (
                <AutoPricingSettings
                  isLoading={isLoading}
                  hasParents={hasParents}
                  isGlobalEdit={!event}
                />
              )}
              {bulkEditMode === 'general' && (
                <GeneralSettings isLoading={isLoading} event={event} />
              )}
            </EventMapContextProvider>
          </Stack>
        </BulkEditStatus>
      </GenericDialog>
    </>
  );
}
