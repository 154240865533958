import {
  QueryObserverResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { createContext, useCallback, useMemo, useState } from 'react';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { AdCampaignEntity, CampaignManagerClient } from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useErrorBoundaryContext } from '../ErrorBoundaryContext';

export type IAdCampaignsDataContext = {
  adCampaignsDataQuery: UseQueryResult<AdCampaignEntity[] | null>;
  allCampaignData: AdCampaignEntity[] | null | undefined;
  errorInfo?: { errorHeader: React.ReactNode; errorMessage: React.ReactNode };
  refreshData: (adCampaignId?: string) => Promise<QueryObserverResult>;
};

export const AdCampaignsDataContext = createContext<IAdCampaignsDataContext>({
  adCampaignsDataQuery: {} as UseQueryResult<AdCampaignEntity[] | null>,
  allCampaignData: [],
  refreshData: () => Promise.resolve({} as QueryObserverResult),
});

export const AdCampaignsDataContextProvider = ({
  adCampaignId,
  children,
}: {
  adCampaignId: string | undefined;
  children: React.ReactNode;
}) => {
  const [errorInfo, setErrorInfo] = useState<{
    errorHeader: React.ReactNode;
    errorMessage: React.ReactNode;
  }>();

  const { campaignDataQuery, allCampaignData, refreshData } =
    useGetCampaignDataQuery(adCampaignId, setErrorInfo);

  return (
    <AdCampaignsDataContext.Provider
      value={{
        adCampaignsDataQuery: campaignDataQuery,
        allCampaignData,
        errorInfo,
        refreshData,
      }}
    >
      {children}
    </AdCampaignsDataContext.Provider>
  );
};

export const useGetCampaignDataQuery = (
  adCampaignId: string | undefined,
  setErrorInfo: (
    value: React.SetStateAction<
      | {
          errorHeader: React.ReactNode;
          errorMessage: React.ReactNode;
        }
      | undefined
    >
  ) => void
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const CAMPAIGN_DATA_QUERY_KEY = 'CampaignManagerClient.getCampaignData';

  const queryKey = useMemo(
    () => [
      CAMPAIGN_DATA_QUERY_KEY,
      activeAccountWebClientConfig.activeAccountId,
      adCampaignId,
    ],
    [activeAccountWebClientConfig.activeAccountId, adCampaignId]
  );

  const shouldQuery = activeAccountWebClientConfig.activeAccountId != null;

  const campaignDataQuery = useQuery({
    queryKey: [
      CAMPAIGN_DATA_QUERY_KEY,
      activeAccountWebClientConfig,
      adCampaignId,
    ],
    queryFn: async () => {
      setErrorInfo(undefined);

      if (!shouldQuery) {
        return null;
      }

      return tryInvokeApi(
        async () => {
          const client = new CampaignManagerClient(
            activeAccountWebClientConfig
          );
          const data = await client.retrieveCampaigns(adCampaignId ?? null);
          return data;
        },
        (error) => {
          const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
            error?.status,
            error?.message
          );
          setErrorInfo({
            errorHeader: headerDisplay,
            errorMessage: messageDisplay,
          });
          trackError(CAMPAIGN_DATA_QUERY_KEY, error, { adCampaignId });
        }
      );
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  const queryClient = useQueryClient();

  const refreshData = useCallback(async () => {
    queryClient.invalidateQueries({
      queryKey: queryKey,
    });

    return await campaignDataQuery.refetch();
  }, [campaignDataQuery, queryClient, queryKey]);

  return {
    campaignDataQuery,
    allCampaignData: campaignDataQuery.data,
    refreshData: refreshData,
  };
};
