import { isEqual } from 'lodash-es';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { TableVirtuoso } from 'react-virtuoso';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import {
  Content,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect, PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { SimpleTable, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { newGuidId } from 'src/utils/idUtils';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import { GroupingType } from 'src/WebApiController';

import * as styles from '../../GroupListings.css';
import {
  GroupingTemplateSeating,
  ListingGroupBy,
  ListingGroupingTypesWithClusteringOptions,
  MergeListingGroupInputListFields,
} from '../groupingTypes';
import {
  getAvailableGroupingTypes,
  getListingGroupsMap,
} from '../groupingUtils';

export const SeatingSelectionTable = ({
  uniqueSeatings,
}: {
  uniqueSeatings: GroupingTemplateSeating[];
}) => {
  const { watch, setValue } =
    useFormContext<MergeListingGroupInputListFields>();

  const contentContext = useContentContext();
  const groupTemplates = watch('templateSettings.groupTemplates');
  const groupBy = watch('templateSettings.groupBy');
  const desiredActiveListings = watch('templateSettings.desiredActiveListings');
  const deprioritizedQuantities = watch(
    'templateSettings.deprioritizedQuantities'
  );
  const randomizePriority = watch('templateSettings.randomizePriority');

  const noneStr = useContent(ContentId.None);
  const newGroupName = useContent(ContentId.NewGroup);

  const availableGroups = useMemo(
    () =>
      groupTemplates.reduce(
        (r, g) => {
          r[g.groupId] = g.groupName;
          return r;
        },
        { None: noneStr } as Record<string, string>
      ),
    [groupTemplates, noneStr]
  );

  const { venueMapInfo, activeConfigOverride } = useEventMapContext();
  const scoreOverrides = useMemo(
    () =>
      getCompleteEventConfigScoreOverrides(
        venueMapInfo?.sectionScores,
        activeConfigOverride?.scoreOverrides,
        false
      ),
    [activeConfigOverride?.scoreOverrides, venueMapInfo?.sectionScores]
  );

  const getGroupedListings = useCallback(
    (groupBy: ListingGroupBy) => {
      const listingGroupsMap = getListingGroupsMap(
        [groupBy],
        contentContext,
        uniqueSeatings,
        scoreOverrides,
        venueMapInfo?.sections
      );
      return !listingGroupsMap
        ? []
        : Object.values(listingGroupsMap)
            .filter((group) => group.data.length)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((group) => {
              return {
                groupId: newGuidId(),
                groupName: group.name,
                desiredActiveListings,
                deprioritizedQuantities,
                marketplaceSettings: null,
                seatings: group.data.map((s, i) => ({
                  ...(s as GroupingTemplateSeating),
                  priority: i + 1,
                })),
              };
            });
    },
    [
      contentContext,
      uniqueSeatings,
      scoreOverrides,
      venueMapInfo?.sections,
      desiredActiveListings,
      deprioritizedQuantities,
    ]
  );

  const updateGroupedListings = useCallback(
    (localGroupBy: ListingGroupBy) => {
      if (isEqual(localGroupBy, groupBy)) {
        return;
      }

      setValue(`templateSettings.groupBy`, localGroupBy);

      if (localGroupBy.groupingType !== GroupingType.Custom) {
        const newTemplates = getGroupedListings(localGroupBy);
        setValue('templateSettings.groupTemplates', newTemplates);
      } else {
        if (groupTemplates.length === 0) {
          setValue('templateSettings.groupTemplates', [
            {
              groupId: newGuidId(),
              groupName: `${newGroupName} 1`,
              desiredActiveListings,
              deprioritizedQuantities,
              seatings: [],
            },
          ]);
        }
      }
    },
    [
      deprioritizedQuantities,
      desiredActiveListings,
      getGroupedListings,
      groupBy,
      groupTemplates.length,
      newGroupName,
      setValue,
    ]
  );

  const onGroupingTypeChange = useCallback(
    (newGroupType: GroupingType, newNumOfClusters?: number) => {
      updateGroupedListings({
        groupingType: newGroupType,
        numOfClusters: newNumOfClusters,
      });
    },
    [updateGroupedListings]
  );

  const renderHeaderContent = useCallback(() => {
    return (
      <SimpleTable.Tr style={{ backgroundColor: vars.color.backgroundPrimary }}>
        <SimpleTable.Th
          className={styles.tableCell}
          style={{ width: '0%' }} // this makes this column width fit-content
        >
          <Content id={ContentId.Group} />
        </SimpleTable.Th>
        <SimpleTable.Th>
          <Content
            id={
              groupBy.groupingType === GroupingType.Quantity
                ? ContentId.UnsoldQuantity
                : groupBy.groupingType === GroupingType.UnitCost
                ? ContentId.UnitCost
                : ContentId.Seating
            }
          />
        </SimpleTable.Th>
      </SimpleTable.Tr>
    );
  }, [groupBy.groupingType]);

  const renderRowContent = useCallback(
    (gts: GroupingTemplateSeating, groupId: string | undefined) => {
      const { id, seating, availQty, unitCst, faceValue } = gts;

      return (
        <>
          <SimpleTable.Td
            className={styles.tableCell}
            style={{ width: '0%' }} // this makes this column width fit-content
          >
            <PosSelect
              value={groupId || 'None'}
              style={{ width: '100%' }}
              size="md"
              contentProps={{ align: 'end' }}
              placeholderText={ContentId.Select}
              onChange={(newGroupId: string | null) => {
                if (newGroupId) {
                  const oldGroup = groupTemplates.find(
                    (g) => g.groupId.toString() === groupId
                  );
                  if (oldGroup) {
                    oldGroup.seatings = oldGroup.seatings
                      .filter((s) => s.id !== id)
                      .map((s, i) => ({ ...s, priority: i + 1 })); // normalize priority
                  }

                  const group = groupTemplates.find(
                    (g) => g.groupId.toString() === newGroupId
                  );

                  if (group) {
                    // Always push to the end
                    group.seatings.push({
                      id,
                      seating,
                      availQty,
                      unitCst,
                      faceValue,
                      priority: group.seatings.length + 1,
                    });

                    setValue('templateSettings.groupTemplates', [
                      ...groupTemplates,
                    ]);
                  } else {
                    setValue('templateSettings.groupTemplates', [
                      ...groupTemplates,
                    ]);
                  }
                }
              }}
              valueOptionsContent={availableGroups}
            />
          </SimpleTable.Td>
          <SimpleTable.Td className={styles.tableCell}>
            {groupBy.groupingType === GroupingType.Quantity ? (
              availQty
            ) : groupBy.groupingType === GroupingType.UnitCost ? (
              unitCst?.disp ?? unitCst?.amt
            ) : (
              <SeatingInfo {...seating} seatFr={null} seatTo={null} />
            )}
          </SimpleTable.Td>
        </>
      );
    },
    [availableGroups, groupBy.groupingType, groupTemplates, setValue]
  );

  return (
    <>
      <Stack direction="row" gap="l">
        <PosFormField
          label={<Content id={ContentId.GroupingDefault} />}
          style={{ width: 'max-content' }}
        >
          <PosEnumSelect
            disabled={!uniqueSeatings.length}
            value={groupBy.groupingType}
            defaultValue={GroupingType.Custom}
            onChange={(v) =>
              v && onGroupingTypeChange(v, groupBy.numOfClusters)
            }
            valueOptionsContent={getAvailableGroupingTypes([])}
          />
        </PosFormField>
        {ListingGroupingTypesWithClusteringOptions.includes(
          groupBy.groupingType
        ) && (
          <PosFormField
            label={<Content id={ContentId.NumberOfBuckets} />}
            style={{ width: 'max-content' }}
          >
            <PosTextField
              value={groupBy.numOfClusters ?? '1'}
              type="number"
              min={0}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const v = parseInt(e.target.value);
                if (v >= 1) {
                  if (v !== groupBy.numOfClusters) {
                    onGroupingTypeChange(groupBy.groupingType, v);
                  }
                }
              }}
            />
          </PosFormField>
        )}
        <PosFormField
          label={<Content id={ContentId.DesiredActiveListings} />}
          style={{ width: 'max-content' }}
        >
          <PosTextField
            value={desiredActiveListings ?? '0'}
            type="number"
            min={0}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value;
              const v = parseInt(value);
              if (v >= 0) {
                if (v !== desiredActiveListings) {
                  setValue(`templateSettings.desiredActiveListings`, v);
                }

                let hasChanged = false;
                groupTemplates.forEach((t) => {
                  if (t.desiredActiveListings !== v) {
                    t.desiredActiveListings = v;
                    hasChanged = true;
                  }
                });

                if (hasChanged) {
                  setValue('templateSettings.groupTemplates', [
                    ...groupTemplates,
                  ]);
                }
              }
            }}
          />
        </PosFormField>
      </Stack>
      <PosFormField>
        <Checkbox
          labelPosition="right"
          label={<Content id={ContentId.RandomizeRankForEachEvents} />}
          checked={randomizePriority}
          onChange={(e) => {
            const isChecked = e.target.checked;
            if (isChecked !== randomizePriority) {
              setValue('templateSettings.randomizePriority', isChecked);
            }
          }}
        />
      </PosFormField>
      <div className={styles.tableContainerVirtuoso}>
        <TableVirtuoso
          data={uniqueSeatings}
          style={{ width: '100%', height: '100%' }}
          overscan={{ main: 500, reverse: 500 }}
          components={{
            Table: SimpleTable.Table,
            TableHead: SimpleTable.Thead,
            TableBody: SimpleTable.Tbody,
            TableRow: SimpleTable.Tr,
          }}
          fixedHeaderContent={renderHeaderContent}
          itemContent={(index, gts) => {
            const { id } = gts;
            const groupId = groupTemplates.find((gt) =>
              gt.seatings.some((s) => s.id === gts.id)
            )?.groupId;
            return renderRowContent(gts, groupId);
          }}
        />
      </div>
    </>
  );
};
