import { PurchaseEventItemHeader } from 'src/modals/common';
import { VenueConfigSummary } from 'src/modals/common/Summary';
import { formatCurrency } from 'src/utils/numberFormatter';
import { getTicketGroupInputTotalCost } from 'src/utils/ticketUtils';
import { TicketGroupInput } from 'src/WebApiController';

interface PurchaseTicketFlowEventHeaderProps {
  isSingleInputModeForMultiEvent: boolean;
  ticketGroups: TicketGroupInput[];
}

export const PurchaseTicketsFlowEventHeader = ({
  isSingleInputModeForMultiEvent,
  ticketGroups,
}: PurchaseTicketFlowEventHeaderProps) => {
  const ticketGroup = ticketGroups[0];

  if (!ticketGroup || !ticketGroup.event) {
    return null;
  }

  if (!isSingleInputModeForMultiEvent) {
    const totalCost = getTicketGroupInputTotalCost(ticketGroup);
    return (
      <PurchaseEventItemHeader
        event={ticketGroup.event!}
        performer={ticketGroup.performer}
        venue={ticketGroup.venue}
        numOfTickets={ticketGroup.numberOfTickets?.value || 0}
        hideYearFromDate={false}
        cost={formatCurrency(totalCost || 0, ticketGroup.currencyCode?.value)}
      />
    );
  }

  const { event, performer, venue } = ticketGroup;

  const venues =
    (venue && {
      [event.viagVirtualId]: venue,
    }) ||
    undefined;

  const performers =
    (performer && {
      [event.viagVirtualId]: performer,
    }) ||
    undefined;

  return (
    <VenueConfigSummary
      events={ticketGroups.map((tg) => tg.event!) || []}
      eventSelected={event}
      venues={venues}
      performers={performers}
    />
  );
};
