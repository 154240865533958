import DOMPurify from 'dompurify';
import { ContentId } from 'src/utils/constants/contentId';
import { ContentIds } from 'src/utils/constants/contentIdDataMap';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { FormatContentIds } from 'src/utils/constants/formatContentIdDataMap';

import {
  ContentIdAndDefaultValue,
  IContentContext,
  useContentContext,
} from './ContentContext';

export type BaseContentProps = {
  id: string;
  formatted: boolean;
  params?: string | React.ReactElement | string[] | React.ReactElement[];
  raw?: boolean;
  defaultValue?: string;
};

type ContentProps = {
  id: ContentId | string | undefined;
  raw?: boolean;
};

export const Content = ({ id, raw = false }: ContentProps) => {
  if (!id) {
    return null;
  }

  const content = ContentIds[id as ContentId];
  if (!content) {
    return id;
  }
  return (
    <BaseContent {...content} formatted={false} params={undefined} raw={raw} />
  );
};

export const FormatContent = ({
  id,
  raw = false,
  params,
}: { id: FormatContentId } & Omit<BaseContentProps, 'id' | 'formatted'>) => {
  const content = FormatContentIds[id];
  if (!content) {
    return id;
  }

  return (
    <BaseContent {...content} formatted={true} params={params} raw={raw} />
  );
};

const BaseContent = ({
  id,
  formatted,
  params,
  raw = false,
  defaultValue,
}: BaseContentProps) => {
  const { contentResolver, formattedContentResolver } = useContentContext();
  let resolved = formatted
    ? formattedContentResolver(id, params, defaultValue)
    : contentResolver({ id, defaultValue });

  const isString = typeof resolved === 'string';
  if (isString) resolved = (resolved as string)?.replaceAll('\u00a0', ' '); // this replaces the &nbsp; with normal space, else long lines won't break
  return raw && resolved !== undefined && isString ? (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(resolved as string),
      }}
    />
  ) : (
    <>{resolved}</>
  );
};

export const useContent = (id: ContentId | string | undefined) => {
  const contentContext = useContentContext();
  return getContent(id, contentContext);
};

export const useContents = (ids: ContentId[]) => {
  const contentContext = useContentContext();

  return getContents(ids, contentContext);
};

export const getContent = (
  id: ContentId | string | undefined,
  contentContext: IContentContext
) => {
  if (!id) {
    return '';
  }
  const content = ContentIds[id as ContentId];
  return content ? (contentContext.contentResolver(content) as string) : id;
};

export const getContents = (
  ids: (ContentId | string)[],
  contentContext: IContentContext
) => {
  const results: string[] = [];
  const contents: ContentIdAndDefaultValue[] = [];
  ids.forEach((i) => {
    const c = ContentIds[i as ContentId];
    if (c) {
      contents.push(c);
    } else {
      results.push(i);
    }
  });

  return results.concat(
    ...(contentContext.contentResolver(contents) as string[])
  );
};

export const useFormattedContent = (
  contentId: FormatContentId,
  params: BaseContentProps['params']
) => {
  const contentContext = useContentContext();

  return getFormattedContent(contentId, params, contentContext);
};

export const getFormattedContent = (
  contentId: FormatContentId,
  params: BaseContentProps['params'],
  contentContext: IContentContext
) => {
  const { id, defaultValue } = FormatContentIds[contentId];
  return contentContext.formattedContentResolver(id, params, defaultValue);
};

export const isContentId = (value: string | ContentId): value is ContentId => {
  return ContentIds[value as ContentId] != null;
};

export const isFormatContentId = (
  value: string | FormatContentId
): value is FormatContentId => {
  return FormatContentIds[value as FormatContentId] != null;
};

/**
 * Compares 2 contentId to be used when sorting
 * @param contentContext
 * @param contentA
 * @param contentB
 */
export const contentComparator = (
  contentContext: IContentContext,
  contentA: ContentId | string | undefined,
  contentB: ContentId | string | undefined
): number => {
  return getContent(contentA, contentContext)
    .toLocaleUpperCase()
    .localeCompare(getContent(contentB, contentContext).toLocaleUpperCase());
};
