import { withoutEmptyValues } from './helpers';

// Determine if all values in a collection are unique
export function uniqueValues(
  values: unknown[],
  accessorFn?: (val: unknown) => unknown
) {
  // apply accessorFn if passed
  let valuesToCheck = accessorFn ? values.map(accessorFn) : values;

  // ignore empty type values as duplicates
  valuesToCheck = withoutEmptyValues(valuesToCheck);

  // sets remove dupe values since all must be unique
  const set = new Set(valuesToCheck);

  // if same size, nothing was removed thus all unique
  return valuesToCheck.length === set.size;
}

// Determine difference between values size and desired count
export function requiredCount(
  values: unknown[],
  required: number,
  accessorFn?: (val: unknown) => unknown
) {
  // apply accessorFn if passed
  const allValues = accessorFn ? values.map(accessorFn) : values;

  // empty values are missing values
  const nonEmptyValues = withoutEmptyValues(allValues);

  if (allValues.length > required) {
    // Extra values, return positive difference
    return allValues.length - required;
  } else if (nonEmptyValues.length < required) {
    // Missing values, return negative difference
    return nonEmptyValues.length - required;
  }

  return 0;
}

/**
 * Determine if a value is unique within the array of values.
 * @param value: target value to look for in the array
 * @param allValues: values to search for value in
 * @param inclusive?: whether allValues should already include a single occurrence of the value
 *    ie. we are asserting the value is already in the array allValues only once
 */
export function isUniqueWithin<T>(value: T, allValues: T[], inclusive = true) {
  const expectedLength = inclusive ? 1 : 0;
  const filteredOutEmptyValues = withoutEmptyValues(allValues);
  return (
    !value ||
    filteredOutEmptyValues.filter((x) => x === value).length === expectedLength
  );
}
