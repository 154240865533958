import { useQuery } from '@tanstack/react-query';
import { cloneDeep } from 'lodash-es';
import { Fragment, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { DELIVERY_OPTIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  DeliveryType,
  Feature,
  TicketClient,
  TicketType,
} from 'src/WebApiController';

import * as styles from '../SyncCenter.css';
import {
  SyncCenterFieldValues,
  SyncCenterTicketTypeRules,
} from '../SyncCenter.types';
import { getTicketTypePriorityOptions } from '../SyncCenter.utils';
import {
  mapTicketTypeWithPriorityList,
  shiftTicketTypePriority,
  sortTicketTypePriority,
} from './ticketTypeOverrideInputForm.utils';

export function TicketTypeOverrideInputForm({
  disabled,
}: {
  disabled?: boolean;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const hasDisplayTicketOrderedByPriorityFeature = useUserHasFeature(
    Feature.DisplayTicketOrderedByPriority
  );
  const mappingsQuery = useQuery({
    queryKey: [
      'getAllDeliveryTypeTicketTypeMappings',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new TicketClient(
        activeAccountWebClientConfig
      ).getAllDeliveryTypeTicketTypeMappings();
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog(
          'TicketClient.getAllDeliveryTypeTicketTypeMappings',
          error
        );
      },
    },
  });

  const { watch, setValue, getValues } =
    useFormContext<SyncCenterFieldValues>();

  const ticketTypeRules = watch('ticketTypeRules');

  const onOnlyThisChange = useCallback(
    (
      deliveryType: DeliveryType,
      ticketTypes: TicketType[],
      selectedTicketType: TicketType
    ) => {
      const values = getValues();
      const ticketTypeRules = cloneDeep(values.ticketTypeRules);
      const priorities = ticketTypeRules?.[deliveryType] ?? {};
      ticketTypes.forEach((ticketType) => {
        priorities[ticketType] = ticketType === selectedTicketType ? 1 : -1;
      });

      ticketTypeRules![deliveryType] = priorities;
      setValue('ticketTypeRules', ticketTypeRules);
    },
    [getValues, setValue]
  );

  if (mappingsQuery.isLoading) {
    return <PosSpinner />;
  }

  return (
    <>
      {mappingsQuery.data &&
        mappingsQuery.data
          // only allow ordering delivery types with more than 1 ticket type since it's
          // pointless if there's only 1 or less since the order is obvious in that case
          .filter(({ ticketTypes }) => Object.keys(ticketTypes).length > 1)
          .map(({ deliveryType, ticketTypes: tts }) => {
            if (deliveryType === DeliveryType.Wallet)
            {
              return null;
            }

            const deliveryTypeContentId = DELIVERY_OPTIONS_TO_CID[deliveryType];

            const ticketTypes = Object.keys(tts).map((k) => k as TicketType);

            const numBlockedTicketTypes =
              Object.values(ticketTypeRules?.[deliveryType] ?? {}).filter(
                (to) => to == -1
              ).length ?? 0;

            const allTicketTypesHavePriority = ticketTypes.every(
              (tt) => ticketTypeRules?.[deliveryType]?.[tt] != null
            );

            // This is used for display only - the ticketTypes variable is ordered by default priority
            // so we don't want to alter that
            const ticketTypesOrdered =
              allTicketTypesHavePriority &&
              hasDisplayTicketOrderedByPriorityFeature
                ? [...ticketTypes].sort((a, b) => {
                    const aPriority = ticketTypeRules?.[deliveryType]?.[a] ?? 0;
                    const bPriority = ticketTypeRules?.[deliveryType]?.[b] ?? 0;
                    return sortTicketTypePriority(aPriority, bPriority);
                  })
                : ticketTypes;

            return (
              <div key={deliveryType}>
                <div className={styles.enumeratedPriorityLabel}>
                  <FormatContent
                    id={FormatContentId.TicketTypePriority}
                    params={[
                      <Content
                        key={deliveryTypeContentId}
                        id={deliveryTypeContentId}
                      />,
                    ]}
                  />
                </div>
                <div className={styles.enumeratedPriorityInputGrid}>
                  {ticketTypesOrdered.map((ticketType) => (
                    <TicketTypeOverrideInputFormSingleTicketType
                      key={ticketType}
                      disabled={disabled}
                      ticketType={ticketType}
                      ticketTypes={ticketTypes}
                      deliveryType={deliveryType}
                      ticketTypeRules={ticketTypeRules}
                      numBlockedTicketTypes={numBlockedTicketTypes}
                      setTicketTypeRules={(rules) =>
                        setValue('ticketTypeRules', rules)
                      }
                      onOnlyThisChange={onOnlyThisChange}
                    />
                  ))}
                </div>
              </div>
            );
          })}
    </>
  );
}

function TicketTypeOverrideInputFormSingleTicketType({
  disabled,
  ticketType,
  ticketTypes,
  deliveryType,
  ticketTypeRules,
  numBlockedTicketTypes,
  setTicketTypeRules,
  onOnlyThisChange,
}: {
  disabled?: boolean;
  ticketType: TicketType;
  ticketTypes: TicketType[];
  deliveryType: DeliveryType;
  ticketTypeRules?: SyncCenterTicketTypeRules;
  numBlockedTicketTypes: number;
  setTicketTypeRules: (rules?: SyncCenterTicketTypeRules) => void;
  onOnlyThisChange: (
    deliveryType: DeliveryType,
    ticketTypes: TicketType[],
    selectedTicketType: TicketType
  ) => void;
}) {
  const onResetAll = useCallback(() => {
    if (ticketTypeRules == null) {
      return;
    }

    const ticketTypeRulesNew = cloneDeep(ticketTypeRules ?? {});
    ticketTypeRulesNew[deliveryType] = undefined;

    setTicketTypeRules(ticketTypeRulesNew);
  }, [deliveryType, ticketTypeRules, setTicketTypeRules]);

  const getDraftTicketTypeRules = useCallback(() => {
    const ticketTypeRulesNew = cloneDeep(ticketTypeRules ?? {});
    ticketTypeRulesNew[deliveryType] = ticketTypeRulesNew[deliveryType] ?? {};
    return ticketTypeRulesNew;
  }, [deliveryType, ticketTypeRules]);

  return (
    <Fragment key={ticketType}>
      <div>{ticketType}</div>
      <div>
        <PosSelect
          disabled={disabled}
          placeholderText={ContentId.SetPriority}
          value={
            ticketTypeRules?.[deliveryType]?.[ticketType] == null
              ? undefined
              : String(ticketTypeRules?.[deliveryType]?.[ticketType])
          }
          valueOptionsContent={getTicketTypePriorityOptions(
            ticketTypeRules?.[deliveryType]?.[ticketType] == -1
              ? // if the current is blocked, it can piggy back
                ticketTypes.length - numBlockedTicketTypes + 1
              : ticketTypes.length - numBlockedTicketTypes
          )}
          onChange={(val) => {
            if (val === ContentId.OnlyThis) {
              onOnlyThisChange(deliveryType, ticketTypes, ticketType);
              return;
            }
            if (val === ContentId.ResetAll) {
              onResetAll();
              return;
            }

            const priorityOrderBefore = mapTicketTypeWithPriorityList(
              ticketTypes,
              deliveryType,
              ticketTypeRules
            );

            const priorityOrderAfter = shiftTicketTypePriority(
              priorityOrderBefore,
              ticketType,
              Number(val)
            );

            const ticketTypeRulesNew = getDraftTicketTypeRules();

            priorityOrderAfter.forEach((to) => {
              ticketTypeRulesNew[deliveryType]![to.ticketType] = to.priority;
            });

            // This should never happen, but just in case
            ticketTypes.forEach((tt, i) => {
              if (ticketTypeRulesNew[deliveryType]![tt] == null) {
                ticketTypeRulesNew[deliveryType]![tt] = i + ticketTypes.length;
              }
            });

            setTicketTypeRules(ticketTypeRulesNew);
          }}
        />
      </div>
    </Fragment>
  );
}
