import { useCallback, useMemo } from 'react';
import { getCompQuantitiesOptions } from 'src/utils/inventoryUtils';
import { CompListingQuantity } from 'src/WebApiController';

export const useAccountQuantitiesFilter = () => {
  const quantitiesFilterOptions = useMemo(() => getCompQuantitiesOptions(), []);

  const onUpdateQuantitiesFilter = useCallback(
    (values: string[], onUpdate: (fitler: CompListingQuantity) => void) => {
      const exactQuantities = [];
      let greaterThanOrEqualToQuantity: number | null = null;
      for (const value of values) {
        const quantity = parseInt(value);
        if (value.indexOf('+') > -1) {
          greaterThanOrEqualToQuantity = quantity;
        } else {
          if (!isNaN(quantity)) {
            exactQuantities.push(quantity);
          }
        }
      }

      const quantitiesFilter: CompListingQuantity = {
        exactQuantities: exactQuantities.filter(
          (quantity) =>
            !greaterThanOrEqualToQuantity ||
            quantity < greaterThanOrEqualToQuantity
        ),
        greaterThanOrEqualToQuantity,
      };

      onUpdate(quantitiesFilter);
    },
    []
  );

  const getQuantitiesFilterValue = useCallback(
    (filter: CompListingQuantity | null) => {
      if (!filter) {
        return [];
      }
      const { exactQuantities, greaterThanOrEqualToQuantity } = filter;

      const eligibleQuantities = Object.values(quantitiesFilterOptions)
        .filter((value) => !isNaN(parseInt(value)))
        .map((value) => parseInt(value));
      const selectedQuantities = eligibleQuantities.filter((quantity) => {
        if (((exactQuantities as number[]) ?? []).includes(quantity)) {
          return true;
        }
        if (
          greaterThanOrEqualToQuantity != null &&
          (greaterThanOrEqualToQuantity as number) <= quantity
        ) {
          return true;
        }
        return false;
      });
      return Object.values(quantitiesFilterOptions).filter((value) =>
        selectedQuantities.includes(parseInt(value))
      );
    },
    [quantitiesFilterOptions]
  );

  const getDefaultQuantitiesFilter = useCallback(
    (oridinal: number): CompListingQuantity => {
      if (oridinal == 0) {
        return { exactQuantities: [], greaterThanOrEqualToQuantity: 1 };
      }
      return { exactQuantities: [], greaterThanOrEqualToQuantity: 2 };
    },
    []
  );

  return {
    quantitiesFilterOptions,
    getDefaultQuantitiesFilter,
    getQuantitiesFilterValue,
    onUpdateQuantitiesFilter,
  };
};
