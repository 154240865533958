import React, { ComponentProps, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export type ConfirmDialogProps = {
  onOkay?: () => Promise<void> | void;
  headerText: React.ReactNode;
  bodyText: React.ReactNode;
  onCancel?: () => void;
  cancelText?: ContentId;
  okText?: ContentId;
} & ComponentProps<typeof RSModal>;

export function ConfirmDialog({
  headerText,
  bodyText,
  onCancel,
  onOkay,
  okText,
  cancelText,
  isOpen,
  ...rest
}: ConfirmDialogProps) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <GenericDialog
      header={headerText}
      footer={
        <>
          {onCancel && (
            <CancelButton
              disabled={isLoading}
              textContentId={cancelText}
              onClick={onCancel}
            />
          )}
          {onOkay && (
            <OkButton
              disabled={isLoading}
              textContentId={okText}
              onClick={async () => {
                setIsLoading(true);
                await onOkay();
                setIsLoading(false);
              }}
            />
          )}
        </>
      }
      {...rest}
      isOpen={isOpen}
      onCancel={onCancel}
    >
      <Stack direction="column" gap="l" width="full" alignItems="center">
        {bodyText}
        {isLoading && <PosSpinner />}
      </Stack>
    </GenericDialog>
  );
}
