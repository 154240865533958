import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Virtuoso } from 'react-virtuoso';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { Content, useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import * as Tabs from 'src/core/ui/Tabs';
import { useEventMarketplaceSettings } from 'src/hooks/useEventMarketplaceSettings';
import { ContentId } from 'src/utils/constants/contentId';
import { newGuidId } from 'src/utils/idUtils';
import { EventWithData } from 'src/WebApiController';
import { Event, MergeListingGroupInput } from 'src/WebApiController';

import IconBulletList from '~icons/pos/bulletList';

import * as styles from '../GroupListings.css';
import { GroupingTemplates } from './GroupingTemplates';
import { MergeListingGroupInputListFields } from './groupingTypes';
import { getListingsForMergeListingGroupInput } from './groupingUtils';
import { ListingGroupInput } from './ListingGroupInput';
import { ListingTableForGroups } from './ListingTableForGroups';

const TemplateTabKey = 'Template_Tab';

export const EventGroupings = ({
  events,
  onEventSelected,
  containerHeight,
}: {
  events: EventWithData[];
  onEventSelected: (event?: EventWithData) => void;
  containerHeight?: number;
}) => {
  const { watch, formState, setValue, getValues } =
    useFormContext<MergeListingGroupInputListFields>();

  const mergeListingGroupInputs = watch('mergeListingGroupInputs');
  const newGroupName = useContent(ContentId.NewGroup);

  const { defaultListingMarketplaceSettings } = useEventMarketplaceSettings(
    events?.[0]?.event
  );

  const onAddNewGroup = useCallback(
    (event: Event, eventIndex: number) => {
      mergeListingGroupInputs.push({
        listingGroupId: newGuidId(),
        name: `${newGroupName} ${mergeListingGroupInputs.length + 1}`,
        desiredActiveListings: getValues(
          `eventSettings.${eventIndex}.desiredActiveListings`
        ),
        minActiveListings: getValues(
          `eventSettings.${eventIndex}.minActiveListings`
        ),
        maxActiveListings: getValues(
          `eventSettings.${eventIndex}.maxActiveListings`
        ),
        targetPercentage: getValues(
          `eventSettings.${eventIndex}.targetPercentage`
        ),
        deprioritizedQuantities: getValues(
          `eventSettings.${eventIndex}.deprioritizedQuantities`
        ),
        marketplaceSettings: {
          listingMarketplaceSettings: defaultListingMarketplaceSettings,
        },
        viagogoEventId: event.viagId,
        viagogoMappingId: event.mappingId,
        listingGroupItems: [],
        groupUndercutSetting: {
          undAbsAmt: null,
          undRelAmt: null,
          actRankUndAbsAmt: null,
          actRankUndRelAmt: null,
        },
        groupingMethods: {
          groupingMethods: [],
        },
      } as MergeListingGroupInput);

      setValue('mergeListingGroupInputs', [...mergeListingGroupInputs]);
    },
    [
      defaultListingMarketplaceSettings,
      getValues,
      mergeListingGroupInputs,
      newGroupName,
      setValue,
    ]
  );

  if (!events.length) {
    return (
      <EmptySectionContent.Root icon={<IconBulletList />}>
        <Content id={ContentId.NoGroupingAvailableForDefault} />
      </EmptySectionContent.Root>
    );
  }

  return (
    <Tabs.Root
      defaultValue={
        events.length > 1 ? TemplateTabKey : events[0].event.viagVirtualId
      }
    >
      {events.length > 1 && (
        <>
          <Tabs.List
            style={{
              // We need to override this and add the border to tabListContainer so it spans left-to-right
              // otherwise the width fit-content won't work
              border: 'none',
              marginBottom: 0,
              width: 'fit-content',
            }}
          >
            <Tabs.Trigger
              key={TemplateTabKey}
              value={TemplateTabKey}
              className={styles.tabsTrigger}
              onClick={() => onEventSelected(undefined)}
            >
              <Content id={ContentId.GroupingTemplate} />
            </Tabs.Trigger>
            {events.map((ev, i) => {
              let hasErrors = false;
              mergeListingGroupInputs.forEach((m, i) => {
                const isForThisEvent =
                  ev.event.viagVirtualId ===
                  (m.viagogoEventId?.toString() ?? m.viagogoMappingId);

                if (isForThisEvent) {
                  hasErrors =
                    hasErrors ||
                    Boolean(
                      formState.errors.mergeListingGroupInputs?.[i]?.name
                        ?.message
                    );
                }
              });

              return (
                <Tabs.Trigger
                  key={ev.event.viagVirtualId}
                  className={styles.tabsTrigger}
                  hasErrors={hasErrors}
                  value={ev.event.viagVirtualId}
                  onClick={() => onEventSelected(ev)}
                >
                  {i + 1}
                </Tabs.Trigger>
              );
            })}
          </Tabs.List>
          <Tabs.Content
            key={TemplateTabKey}
            value={TemplateTabKey}
            style={{
              height: containerHeight
                ? `calc(${containerHeight}px - ${vars.spacing['3xl']} - ${vars.spacing['5xl']})`
                : '75vh',
            }}
          >
            <GroupingTemplates events={events} />
          </Tabs.Content>
        </>
      )}

      {events.map((ev, i) => {
        const groupingInputs = mergeListingGroupInputs.filter(
          (m) =>
            ev.event.viagVirtualId ===
            (m.viagogoEventId?.toString() ?? m.viagogoMappingId)
        );

        return (
          <Tabs.Content
            key={ev.event.viagVirtualId}
            value={ev.event.viagVirtualId}
          >
            <Stack direction="column" gap="xl" height="full">
              <div
                className={styles.groupingTablesVirtuosoContainer}
                style={
                  containerHeight
                    ? {
                        height:
                          events.length > 1
                            ? `calc(${containerHeight}px - ${vars.spacing['3xl']} - ${vars.spacing['5xl']})`
                            : `calc(${containerHeight}px - ${vars.spacing['m']})`,
                      }
                    : undefined
                }
              >
                <Virtuoso
                  data={groupingInputs}
                  totalCount={groupingInputs.length}
                  components={{
                    Header: () => (
                      <ListingTableForGroups event={ev} eventIndex={i} />
                    ),
                    Footer: () => (
                      <>
                        {' '}
                        {!groupingInputs.length && (
                          <EmptySectionContent.Root icon={<IconBulletList />}>
                            <Content
                              id={ContentId.NoGroupingAvailableForDefault}
                            />
                          </EmptySectionContent.Root>
                        )}
                        <div className={styles.groupToolbar}>
                          <Button
                            onClick={() => onAddNewGroup(ev.event, i)}
                            variant="text"
                          >
                            + <Content id={ContentId.AddGroup} />
                          </Button>
                        </div>
                      </>
                    ),
                  }}
                  itemContent={(index, group) => (
                    <ListingGroupInput
                      key={index}
                      mergeListingGroupInputIndex={mergeListingGroupInputs.indexOf(
                        group
                      )}
                      listings={getListingsForMergeListingGroupInput(
                        group,
                        events
                      )}
                      style={{ marginTop: vars.spacing['xl'] }}
                    />
                  )}
                />
              </div>
            </Stack>
          </Tabs.Content>
        );
      })}
    </Tabs.Root>
  );
};
