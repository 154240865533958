import { Fragment, useMemo } from 'react';
import { LinkAndCopy } from 'src/components/common/LinkAndCopy';
import { Content, useContent } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { getUserDisplayName, useGetUserInfos } from 'src/hooks/userGetUserInfo';
import {
  Detail,
  DetailGroup,
  DetailSection,
  SectionContent,
} from 'src/modals/common';
import * as styles from 'src/modals/SaleDetails/SaleDetails.css';
import { ContentId } from 'src/utils/constants/contentId';
import { QUALITY_CONTROL_STATE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { removeDuplicates } from 'src/utils/miscUtils';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { QualityControlState, UiPurchaseInfo } from 'src/WebApiController';

export const PurchaseInfoSection = ({
  isSeatSaver,
  purchaseInfos,
  qualityControlState,
  insertBy,
}: {
  isSeatSaver?: boolean;
  purchaseInfos: UiPurchaseInfo[];
  qualityControlState?: QualityControlState | null;
  insertBy?: string | null;
}) => {
  const useGetUserInfosQuery = useGetUserInfos(
    [...purchaseInfos.map((u) => u.buyerUserId), insertBy]
      .filter((id) => id != null)
      .map((id) => id!)
  );

  const deactivatedText = useContent(ContentId.Deactivated);
  const buyerUserInfos = useMemo(() => {
    return purchaseInfos.map((bi) => {
      const buyerUser =
        bi.buyerUserId && useGetUserInfosQuery.data?.[bi.buyerUserId];
      return {
        buyerUserId: bi.buyerUserId,
        buyerUser,
      };
    });
  }, [purchaseInfos, useGetUserInfosQuery.data]);

  const insertByUser = insertBy
    ? useGetUserInfosQuery.data?.[insertBy]
    : undefined;

  return (
    <DetailSection name={<Content id={ContentId.Purchase} />}>
      {isSeatSaver ? (
        <SectionContent numOfColumns={1}>
          <DetailGroup>
            <Content id={ContentId.PlaceholderPurchaseMessage} />
            <Button
              variant="link"
              size="unset"
              style={{ width: 'max-content' }}
            >
              <Content id={ContentId.WhatIsAPlacholder} />
            </Button>
          </DetailGroup>
          <DetailGroup>
            <Detail
              label={<Content id={ContentId.CreatedBy} />}
              detail={
                insertByUser && (
                  <strong>{getUserDisplayName(insertByUser)}</strong>
                )
              }
            />
          </DetailGroup>
        </SectionContent>
      ) : (
        <SectionContent numOfColumns={1}>
          <DetailGroup>
            <Detail
              label={
                <Content
                  id={
                    purchaseInfos.length < 2
                      ? ContentId.Purchase
                      : ContentId.Purchases
                  }
                />
              }
              detail={
                <div className={styles.linkIconsWrapper}>
                  {purchaseInfos.map(({ purchaseOrderId, vendorOrderId }) => {
                    return (
                      <Fragment key={purchaseOrderId}>
                        {purchaseOrderId && (
                          <LinkAndCopy
                            relativeUrl={getPurchaseOrderRelativeUrl(
                              purchaseOrderId
                            )}
                            linkChildren={vendorOrderId}
                            valueToCopy={vendorOrderId ?? purchaseOrderId}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              }
            />
          </DetailGroup>
          <DetailGroup>
            <Detail
              label={<Content id={ContentId.PurchasedBy} />}
              detail={
                <strong>
                  {removeDuplicates(
                    buyerUserInfos.map((bui) =>
                      bui.buyerUser
                        ? getUserDisplayName(bui.buyerUser, deactivatedText)
                        : bui.buyerUserId
                    )
                  ).join(', ')}
                </strong>
              }
            />
          </DetailGroup>
          <DetailGroup>
            <Detail
              label={
                <Content
                  id={
                    purchaseInfos.length < 2
                      ? ContentId.Vendor
                      : ContentId.Vendors
                  }
                />
              }
              detail={
                <span>
                  {removeDuplicates(
                    purchaseInfos
                      .filter((v) => v.vendor?.vendorName != null)
                      .map((v) => v.vendor!.vendorName)
                  ).join(', ')}
                </span>
              }
            />
          </DetailGroup>
          <DetailGroup>
            <Detail
              label={
                <Content
                  id={
                    purchaseInfos.length < 2
                      ? ContentId.VendorAccount
                      : ContentId.VendorAccounts
                  }
                />
              }
              detail={
                <span>
                  {removeDuplicates(
                    purchaseInfos
                      .filter(
                        (v) => v.vendorAccount?.encryptedEmailAddress != null
                      )
                      .map((v) =>
                        v.vendorAccount!.vendorAccountName
                          ? `${v.vendorAccount!.vendorAccountName} (${
                              v.vendorAccount!.encryptedEmailAddress
                            })`
                          : v.vendorAccount!.encryptedEmailAddress
                      )
                  ).join(', ')}
                </span>
              }
            />
          </DetailGroup>
          {qualityControlState !== undefined && (
            <DetailGroup>
              <Detail
                label={<Content id={ContentId.QualityControlState} />}
                detail={
                  qualityControlState ? (
                    <Content
                      id={QUALITY_CONTROL_STATE_TO_CID[qualityControlState]}
                    />
                  ) : (
                    <Content id={ContentId.Unset} />
                  )
                }
              />
            </DetailGroup>
          )}
          {insertBy && (
            <DetailGroup>
              <Detail
                label={<Content id={ContentId.CreatedBy} />}
                detail={
                  insertByUser && (
                    <strong>{getUserDisplayName(insertByUser)}</strong>
                  )
                }
              />
            </DetailGroup>
          )}
          <DetailGroup>
            <Detail
              label={<Content id={ContentId.Notes} />}
              detail={
                <span>
                  {removeDuplicates(
                    purchaseInfos
                      .filter((p) => p.internalNotes?.length)
                      .map((p) => p.internalNotes)
                  ).join(', ')}
                </span>
              }
            />
          </DetailGroup>
        </SectionContent>
      )}
    </DetailSection>
  );
};
