import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import {
  DealProvider,
  useDealContext,
} from 'src/contexts/DealContext/DealContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DeleteIcon, EditIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField, posField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import {
  DealSplitType,
  DealType,
  Feature,
  PurchaseOrderDeal,
  PurchaseOrderDealDetails,
  SellerUserAccountInfo,
} from 'src/WebApiController';

import { DealFallbackSection } from './DealConfigHurdle/DealConfigFallbackHurdle';
import {
  createDefaultDealConfig,
  DealConfigSection,
} from './DealConfigSection';
import { DealDateRangeSection } from './DealDateRangeSection';
import * as styles from './Deals.css';
import { DealSplitTypeSection } from './DealSplitTypeSection';
import { DealTypeSection } from './DealTypeSection';

export const createDefaultPODeal = (
  userId: string,
  allActiveUserInfos?: Record<string, SellerUserAccountInfo> | null
): PurchaseOrderDeal => ({
  dealId: null,
  dealType: DealType.Sor,
  dealDetails: {
    dealConfigId: null,
    dealFallbackConfig: createDefaultDealConfig(userId, allActiveUserInfos),
    dealConfigs: posField([
      createDefaultDealConfig(userId, allActiveUserInfos),
    ]),
    dealStartDate: new Date().toDateString(),
    dealEndDate: new Date().toDateString(),
    configSplitType: DealSplitType.Sales,
    guaranteedAmountPaid: 0,
  },
});

const SORSection = ({ sorDeal }: { sorDeal?: PurchaseOrderDealDetails }) => {
  if (!sorDeal) {
    return;
  }

  return (
    <Stack direction="column" gap="xl">
      <DealDateRangeSection
        dealStartDate={sorDeal.dealStartDate}
        dealEndDate={sorDeal.dealEndDate}
      />
      <DealSplitTypeSection dealSplitType={sorDeal.configSplitType} />
      <DealConfigSection dealConfigs={sorDeal.dealConfigs?.value || null} />
      {sorDeal.dealFallbackConfig && (
        <DealFallbackSection dealFallbackConfig={sorDeal.dealFallbackConfig} />
      )}
    </Stack>
  );
};

const GuaranteedSection = ({
  guarunteedAmountPaid,
}: {
  guarunteedAmountPaid: number;
}) => {
  const { watch } = useFormContext<PurchaseOrderDetailsInput>();
  const { getUiCurrency } = useLocalizationContext();
  const { updateLocalDealDetails } = useDealContext();
  const currency = watch('currencyCode');
  return (
    <PosFormField
      label={
        <div className={styles.SectionTitle}>
          <Content id={ContentId.AmountPaid} />
        </div>
      }
    >
      <PosCurrencyField
        uiCurrency={getUiCurrency(currency?.value)}
        value={guarunteedAmountPaid}
        onChange={(e) => {
          const v = parseFloat(e.target.value) || 0;
          updateLocalDealDetails({
            guaranteedAmountPaid: Number(v),
          });
        }}
      />
    </PosFormField>
  );
};

const DealSectionContent = ({
  allActiveUserInfos,
}: {
  allActiveUserInfos?: Record<string, any> | null;
}) => {
  const [isEditDeal, setIsEditDeal] = useState(false);
  const { watch, setValue } = useFormContext<PurchaseOrderDetailsInput>();
  const hasConsignmentDealFeature = useUserHasFeature(Feature.ConsignmentDeal);
  const dealField = watch('deal');
  const { localDeal, setLocalDeal, isValidCommissionSplits } = useDealContext();

  if (!hasConsignmentDealFeature) {
    return null;
  }

  const { value: deal } = dealField || {};

  // Show Collapsed Deal UI if deal is present
  if (deal && !isEditDeal) {
    return (
      <Stack justifyContent="spaceBetween" className={styles.DealContainer}>
        <Stack direction="column">
          <div className={styles.DealHeader}>
            <Content id={ContentId.Deal} />
          </div>
          {deal?.dealType === DealType.Sor && <Content id={ContentId.SOR} />}
          {deal?.dealType === DealType.Guaranteed && (
            <Content id={ContentId.GUR} />
          )}
        </Stack>
        <Stack style={{ alignItems: 'center' }} gap="m">
          <DeleteIcon
            fill={IconsFill.currentColor}
            withHoverEffect
            onClick={() => setValue('deal', posChangedField(null))}
          />
          <Button
            variant={'outline'}
            onClick={() => {
              setIsEditDeal(true);
              setLocalDeal(deal);
            }}
            style={{ alignSelf: 'center' }}
          >
            <EditIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              size={vars.iconSize.m}
            />
            <Content id={ContentId.UpdateDeal} />
          </Button>
        </Stack>
      </Stack>
    );
  }

  return isEditDeal ? (
    <Stack direction="column" className={styles.DealContainer} gap="xl">
      <DealTypeSection dealType={localDeal?.dealType} setValue={setValue} />
      {localDeal?.dealType === DealType.Sor ? (
        <SORSection sorDeal={localDeal?.dealDetails} />
      ) : (
        <GuaranteedSection
          guarunteedAmountPaid={
            localDeal?.dealDetails.guaranteedAmountPaid || 0
          }
        />
      )}
      <Stack gap="m" justifyContent="end">
        <Button
          variant="outline"
          onClick={() => {
            setIsEditDeal(false);
          }}
          style={{ alignSelf: 'end' }}
        >
          <Content id={ContentId.Cancel} />
        </Button>
        <Button
          onClick={() => {
            setIsEditDeal(false);
            setValue('deal', posChangedField(localDeal || null));
          }}
          style={{ alignSelf: 'end' }}
          disabled={!isValidCommissionSplits}
        >
          <Content id={ContentId.Save} />
        </Button>
      </Stack>
    </Stack>
  ) : (
    <Stack
      justifyContent="spaceBetween"
      className={styles.CollapsedDealContainer}
    >
      <div className={styles.DealHeader}>
        <Content id={ContentId.Deal} />
      </div>
      <Button
        variant={'link'}
        onClick={() => {
          setIsEditDeal(true);
          setLocalDeal(createDefaultPODeal('userId', allActiveUserInfos));
        }}
        style={{ alignSelf: 'end' }}
      >
        <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
        <Content id={ContentId.AddNewDeal} />
      </Button>
    </Stack>
  );
};

export const DealSection = () => {
  const { allActiveUserInfos } = useSellerAccountContext();
  const { watch } = useFormContext<PurchaseOrderDetailsInput>();
  const hasConsignmentDealFeature = useUserHasFeature(Feature.ConsignmentDeal);

  if (!hasConsignmentDealFeature) {
    return null;
  }

  const dealField = watch('deal');
  const { value: deal } = dealField || {};

  return (
    <DealProvider deal={deal ?? undefined}>
      <DealSectionContent allActiveUserInfos={allActiveUserInfos} />
    </DealProvider>
  );
};
