import { range } from 'lodash-es';
import { ComponentProps, useCallback, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ListingClient,
  ListingDetails,
  ListingMetrics,
  SplitListingInput,
} from 'src/WebApiController';

import { Summary } from '../common/Summary';
import { ModalBodyHeaderContainer } from '../Modal/Modal.styled';
import { SplitListingBody } from './SplitListingBody';

export const SplitListingModal = ({ cancelTo }: { cancelTo?: ModalProps }) => {
  const { posEntity: listing } = useActivePosEntityContext<ListingDetails>();

  const methods = useForm<SplitListingInput>({
    defaultValues: {
      sourceListingId: listing?.id,
      numOfListings: 2,
      ticketsByListingNumber: (listing?.tickets ?? []).map((t) => ({
        ticket: t,
        listingNumber: 1,
      })),
      alwaysUnbroadcastListing: false,
      useOriginalTicketGroupInfoForNewListings: false,
    },
  });

  return (
    <FormProvider {...methods}>
      <SplitListingModalContent {...methods} cancelTo={cancelTo} />
    </FormProvider>
  );
};

const SplitListingModalContent = ({
  cancelTo,
  formState,
  handleSubmit,
  setError,
}: {
  cancelTo?: ModalProps;
} & Omit<
  ComponentProps<typeof FormProvider<SplitListingInput, unknown>>,
  'children'
>) => {
  const { loadingState } = useEventItemLoadingDisplay<ListingDetails>(
    FormatContentId.LoadingListingId,
    FormatContentId.SearchingForListingId,
    FormatContentId.CouldNotFindListingId
  );

  const { closeModal } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshMetrics } = useCatalogMetricsContext<ListingMetrics>();

  const needToSplitToTwoOrMoreListingsError = useContent(
    ContentId.NeedToSplitToTwoOrMoreListings
  );
  const listingNumberUsedError = useContent(
    ContentId.ListingNumberUsedRequired
  );

  const [isLoading, setIsLoading] = useState(false);
  const { isDirty, isSubmitting } = formState;

  const { showErrorDialog } = useErrorBoundaryContext();

  const onSubmit = useCallback(
    async (formData: SplitListingInput) => {
      if (formData.numOfListings < 2) {
        setError('numOfListings', {
          message: needToSplitToTwoOrMoreListingsError,
        });
        return;
      }

      const listingNumberMap = range(1, formData.numOfListings + 1).reduce(
        (r, i) => {
          r[i.toString()] = false;
          return r;
        },
        {} as Record<string, boolean>
      );

      formData.ticketsByListingNumber.forEach((t) => {
        listingNumberMap[t.listingNumber.toString()] = true;
      });

      if (Object.values(listingNumberMap).includes(false)) {
        setError('ticketsByListingNumber', {
          message:
            listingNumberUsedError +
            ' ' +
            Object.keys(listingNumberMap)
              .filter((n) => !listingNumberMap[n])
              .join(', '),
        });
        return;
      }

      // If this is called, there are no form errors (else onSubmit is never called)
      setIsLoading(true);

      return await tryInvokeApi(
        async () => {
          const result = await new ListingClient(
            activeAccountWebClientConfig
          ).splitListings(formData);
          if (result) {
            await refreshExpandedListItems();
            refreshMetrics?.();

            // After we split - the original listing is gone - so we don't want to cancel back to it
            // so we just close the main modal
            closeModal(true);
          }
        },
        (error) => {
          showErrorDialog('ListingClient.splitListings', error, {
            trackErrorData: formData,
          });
        },
        () => setIsLoading(false)
      );
    },
    [
      activeAccountWebClientConfig,
      closeModal,
      listingNumberUsedError,
      needToSplitToTwoOrMoreListingsError,
      refreshExpandedListItems,
      refreshMetrics,
      setError,
      showErrorDialog,
    ]
  );

  const { event, posEntity: listing } =
    useActivePosEntityContext<ListingDetails>();

  if (loadingState) return loadingState;

  return (
    <>
      <CancellableFormHeader
        cancelTo={cancelTo}
        disabled={isLoading || isSubmitting}
        showDialogOnCancel={isDirty}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.SplitListing} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        {isLoading ? (
          <PosSpinner />
        ) : (
          <>
            <ModalBodyHeaderContainer>
              <Summary event={event!} posEntity={listing!} />
            </ModalBodyHeaderContainer>
            <SplitListingBody />
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <CancellableFormFooter
          cancelTo={cancelTo}
          disabled={isLoading || isSubmitting}
          showDialogOnCancel={isDirty}
        >
          <ConfirmButton
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading || isSubmitting}
            textContentId={ContentId.Split}
          />
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
