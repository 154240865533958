import { ZoneSectionAreaRankOverride } from 'src/WebApiController';

export type ZoneAreaMetadata = {
  [key: string]: { key: string; id: string; color: string; rank: number };
};

type ColorWithRank = {
  zoneKey: string;
  zoneAreaName: string;
  color: string;
  rank: number;
  medianScore: number;
};

const baseColors = [
  '#3E008E', // StubHub Purple
  '#70A12F', // StubHub camo green
  '#D81819', // StubHub red
  '#EF3081', // StubHub pink
  '#04A746', // StubHub leaf green
  '#F2B824', // StubHub gold
  '#006BB4', // StubHub blue
  '#FF0000', // Red
  '#00FF00', // Green
  '#0000FF', // Blue
  '#FFA500', // Orange
  '#800080', // Purple
  '#008080', // Teal
  '#FF00FF', // Magenta
  '#00FFFF', // Cyan
  '#00008B', // Dark Blue
  '#8B0000', // Dark Red
  '#006400', // Dark Green
  '#4B0082', // Indigo
  '#FF4500', // Orange Red
  '#2E8B57', // Sea Green
  '#8B4513', // Saddle Brown
  '#9932CC', // Dark Orchid
  '#8FBC8F', // Dark Sea Green
  '#4682B4', // Steel Blue
  '#B22222', // Firebrick
  '#5F9EA0', // Cadet Blue
  '#D2691E', // Chocolate
  '#6495ED', // Cornflower Blue
  '#DC143C', // Crimson
  '#00CED1', // Dark Turquoise
  '#9400D3', // Dark Violet
  '#FF7F50', // Coral
  '#2F4F4F', // Dark Slate Gray
  '#8A2BE2', // Blue Violet
  '#CD5C5C', // Indian Red
  '#2E8B57', // Sea Green
];

export const getRandomColor = (index: number): string => {
  if (index < baseColors.length) {
    return baseColors[index];
  }

  // Only pick a random one if we've run out of base colors to use for top level zones
  return baseColors[Math.floor(Math.random() * baseColors.length)];
};

export const adjustColorOpacity = (hexColor: string, rank: number): string => {
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  // Adjust hue based on the rank
  const lightnessAdjustment = (rank - 1) * 0.15;
  const adjustLightness = (value: number) =>
    Math.min(255, Math.round(value + (255 - value) * lightnessAdjustment));

  const adjustedR = adjustLightness(r);
  const adjustedG = adjustLightness(g);
  const adjustedB = adjustLightness(b);

  // Adjust alpha based on rank
  const alpha = 1 - (rank - 1) * 0.15;
  const alphaHex = Math.round(alpha * 255)
    .toString(16)
    .padStart(2, '0');

  return `#${adjustedR.toString(16).padStart(2, '0')}${adjustedG
    .toString(16)
    .padStart(2, '0')}${adjustedB.toString(16).padStart(2, '0')}${alphaHex}`;
};

export const getZoneAreaMetadata = (
  zoneNameToRankMap: {
    [key: string]: {
      [key: string]: number;
    };
  },
  zoneSectionAreaRankOverrides: ZoneSectionAreaRankOverride[],
  groupIdToMedianSeatScoreMap: { [key: string]: number },
  useCustomRank: boolean
): ZoneAreaMetadata => {
  if (useCustomRank && zoneSectionAreaRankOverrides.length > 0) {
    return getZoneAreaRankOverrideMetadata(
      zoneSectionAreaRankOverrides,
      groupIdToMedianSeatScoreMap
    );
  }

  const colorWithRankArray: ColorWithRank[] = [];

  const zoneColors: { [key: string]: string } = {};

  Object.keys(zoneNameToRankMap).forEach((zoneName, i) => {
    zoneColors[zoneName] = getRandomColor(i);
  });

  Object.entries(zoneNameToRankMap).forEach(([zone, zoneSectionAreas]) => {
    Object.entries(zoneSectionAreas).forEach(([zoneSectionArea, rank]) => {
      const zoneAreaName = `${zone}: ${zoneSectionArea}`;
      const adjustedColor = adjustColorOpacity(zoneColors[zone], rank);
      colorWithRankArray.push({
        zoneKey: `${zone}`,
        zoneAreaName: zoneAreaName,
        color: adjustedColor,
        rank: rank,
        medianScore: groupIdToMedianSeatScoreMap[zoneAreaName] ?? 0,
      });
    });
  });

  colorWithRankArray.sort((a, b) => {
    const zoneComparison = a.zoneKey.localeCompare(b.zoneKey);
    if (zoneComparison !== 0) {
      return zoneComparison;
    }

    return a.medianScore - b.medianScore;
  });

  const zoneAreaMetadata: ZoneAreaMetadata = {};
  colorWithRankArray.forEach(({ zoneAreaName, color, rank }) => {
    zoneAreaMetadata[zoneAreaName] = {
      color,
      rank,
      id: zoneAreaName,
      key: zoneAreaName,
    };
  });

  return zoneAreaMetadata;
};

export const getZoneAreaRankOverrideMetadata = (
  zoneSectionAreaRankOverrides: ZoneSectionAreaRankOverride[],
  groupIdToMedianSeatScoreMap: { [key: string]: number }
): ZoneAreaMetadata => {
  const colorWithRankArray: ColorWithRank[] = [];

  zoneSectionAreaRankOverrides.forEach(({ zoneAreaName, rank, color }, i) => {
    colorWithRankArray.push({
      zoneKey: zoneAreaName.split(':')[0],
      zoneAreaName,
      color: `${color}`,
      rank,
      medianScore: groupIdToMedianSeatScoreMap[zoneAreaName] ?? 0,
    });
  });

  // Only sort by rank
  colorWithRankArray.sort((a, b) => {
    return a.medianScore - b.medianScore;
  });

  const zoneAreaMetadata: ZoneAreaMetadata = {};
  colorWithRankArray.forEach(({ zoneAreaName, color, rank }) => {
    zoneAreaMetadata[zoneAreaName] = {
      color,
      rank,
      id: zoneAreaName,
      key: zoneAreaName,
    };
  });

  return zoneAreaMetadata;
};
