import { range } from 'lodash-es';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { Switch } from 'src/core/interim/Switch';
import { PosSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { FieldError } from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { SplitListingInput } from 'src/WebApiController';

import { ModalBodyDataContainer } from '../Modal/Modal.styled';
import * as styles from './SplitListing.css';
import {
  MainLabel,
  SoldTicketContainer,
  SplitListingTableContainer,
  SplitListQuickOptionsContainer,
} from './SplitListing.styled';

export const SplitListingBody = () => {
  const { watch, setValue, formState, clearErrors } =
    useFormContext<SplitListingInput>();
  const numOfListings = watch('numOfListings');
  const ticketsByListingNumber = watch('ticketsByListingNumber');

  const [allowManualSplitSoldTickets, setAllowManualSplitSoldTickets] =
    useState(false);

  const regenerateListingTicketIds = (numOfListings: number) => {
    // for every ticket who's list index is greater than numOfListings, set it to numOfListings
    const newListingsTickets = ticketsByListingNumber.map((t) => {
      if (t.listingNumber > numOfListings) {
        t.listingNumber = numOfListings;
      }

      return t;
    });

    clearErrors('numOfListings');
    setValue('numOfListings', numOfListings);
    setValue('ticketsByListingNumber', newListingsTickets);
  };

  const assignListingNumberToTicket = useCallback(
    (newListingNum: number, ticketIndex: number) => {
      ticketsByListingNumber[ticketIndex].listingNumber = newListingNum;

      // To keep all the contiguous tickets in the same listing
      // All tickets above ticketIndex but listing-num greater than it has to be set to the same num
      for (let i = ticketIndex - 1; i >= 0; i--) {
        if (ticketsByListingNumber[i].listingNumber > newListingNum) {
          ticketsByListingNumber[i].listingNumber = newListingNum;
        }
      }
      // And all tickets below ti but listing-num less than it also has to be set to the same num
      for (let i = ticketIndex + 1; i < ticketsByListingNumber.length; i++) {
        if (ticketsByListingNumber[i].listingNumber < newListingNum) {
          ticketsByListingNumber[i].listingNumber = newListingNum;
        }
      }

      clearErrors('ticketsByListingNumber');
      setValue('ticketsByListingNumber', [...ticketsByListingNumber]);
    },
    [clearErrors, setValue, ticketsByListingNumber]
  );

  return (
    <ModalBodyDataContainer>
      <MainLabel>
        <Content id={ContentId.SplitListing} />
      </MainLabel>
      <SplitListQuickOptionsContainer>
        <div>
          <Content id={ContentId.NumOfListingsToCreate} />:
          <div className={styles.numOfListingContainer}>
            <PosTextField
              type="number"
              inputMode="numeric"
              value={numOfListings}
              onChange={(e) => {
                const newNum = parseInt(e.target.value);
                if (newNum !== numOfListings) {
                  regenerateListingTicketIds(
                    Math.min(Math.max(2, newNum), ticketsByListingNumber.length)
                  );
                }
              }}
            />
          </div>
        </div>
        <div>
          <Content id={ContentId.IgnoreSoldTickets} />:
          <Switch
            checked={allowManualSplitSoldTickets}
            onChange={(value) => {
              if (value.target.checked !== allowManualSplitSoldTickets) {
                setAllowManualSplitSoldTickets(value.target.checked);
              }
            }}
          />
          {formState.errors.numOfListings?.message && (
            <FieldError>{formState.errors.numOfListings?.message}</FieldError>
          )}
        </div>
      </SplitListQuickOptionsContainer>
      <SplitListingTableContainer>
        {formState.errors.ticketsByListingNumber?.message && (
          <FieldError>
            {formState.errors.ticketsByListingNumber?.message}
          </FieldError>
        )}
        <thead>
          <tr className="header">
            <th>
              <Content id={ContentId.Ticket} />
            </th>
            <th>
              <Content id={ContentId.Seat} />
            </th>
            <th>
              <Content id={ContentId.UnitCost} />
            </th>
            <th>
              <Content id={ContentId.Listing} />
            </th>
          </tr>
        </thead>
        <tbody>
          {ticketsByListingNumber.map((t, ti) => (
            <tr key={ti} className={t.listingNumber % 2 === 0 ? 'even' : ''}>
              <td title={`${t.ticket.tgId}-${t.ticket.ord}`}>
                <FormatContent
                  id={FormatContentId.SeatNumber}
                  params={(ti + 1).toString()}
                />
              </td>
              <td>{t.ticket.seat}</td>
              <td>{t.ticket.unitCost?.disp}</td>
              <td>
                {t.ticket.saleId && !allowManualSplitSoldTickets ? (
                  <SoldTicketContainer title={t.listingNumber.toString()}>
                    <Content id={ContentId.Sold} />
                  </SoldTicketContainer>
                ) : (
                  <PosSelect
                    value={t.listingNumber.toString()}
                    size="md"
                    contentProps={{ align: 'end' }}
                    onChange={(newListingNumber: string | null) => {
                      if (newListingNumber) {
                        const newListingNum = parseInt(newListingNumber);
                        assignListingNumberToTicket(newListingNum, ti);
                      }
                    }}
                    valueOptionsContent={range(numOfListings).reduce(
                      (r, i) => {
                        r[(i + 1).toString()] = (i + 1).toString();
                        return r;
                      },
                      {} as Record<string, string>
                    )}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </SplitListingTableContainer>
    </ModalBodyDataContainer>
  );
};
