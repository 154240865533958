import { useCallback, useContext } from 'react';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { ImageMagnifier } from 'src/core/POS/ImageMagnifier';
import { Button } from 'src/core/ui';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ArtifactType,
  DocumentType,
  SaleDetails,
  Ticket,
} from 'src/WebApiController';

import {
  Divider,
  PreviewTicket,
  SeatLabel,
} from '../../components/UploadArtifacts/UploadETickets/UploadETickets.styled';
import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';

export const ViewETickets = ({
  artifactType,
  cancelTo,
}: {
  artifactType: ArtifactType | null;
  cancelTo: ModalProps;
}) => {
  const { setModal } = useContext(ModalContext);
  const { event, posEntity } = useActivePosEntityContext<SaleDetails>();
  const goBack = useCallback(() => {
    setModal(cancelTo);
  }, [setModal, cancelTo]);

  return (
    <>
      <CancellableFormHeader cancelTo={cancelTo}>
        <ConnectedEventEntityHeader
          title={
            <Content
              id={
                artifactType === ArtifactType.ETicket
                  ? ContentId.ViewETickets
                  : ContentId.ViewQRCodes
              }
            />
          }
        />
      </CancellableFormHeader>
      <ModalBody>
        <ModalBodyHeaderContainer>
          <Summary event={event!} posEntity={posEntity!} />
        </ModalBodyHeaderContainer>
        <ModalBodyDataContainer>
          <TicketArtifacts
            section={posEntity!.seating.section}
            tickets={posEntity!.tickets}
            artifactType={artifactType}
          />
        </ModalBodyDataContainer>
      </ModalBody>
      <ModalFooter>
        <Button onClick={goBack}>
          <Content id={ContentId.Back} />
        </Button>
      </ModalFooter>
    </>
  );
};

// Pulled into a separate component since there were some weird issues with error boundary otherwise
const TicketArtifacts = ({
  section,
  tickets,
  artifactType,
}: {
  section: string;
  tickets: Ticket[];
  artifactType: ArtifactType | null;
}) => {
  const { posEntityId, entityType } = useActivePosEntityContext<SaleDetails>();

  return (
    <>
      {tickets.map(({ id, row, seat, eTicket, qrCode }, ix) => {
        const userDoc =
          artifactType === ArtifactType.ETicket ? eTicket : qrCode;
        return (
          <div key={id}>
            <PreviewTicket>
              <SeatLabel>
                <SeatingInfo
                  row={row}
                  seatFr={seat}
                  seatTo={null}
                  section={section}
                />
              </SeatLabel>
              {userDoc?.pageUri && (
                <ImageMagnifier
                  imageSrc={userDoc.pageUri}
                  magnifierSize={100}
                  trackingEntityType={entityType}
                  trackingEntityId={posEntityId}
                  trackingDocumentId={userDoc.documentId}
                  trackingDocumentType={
                    artifactType === ArtifactType.ETicket
                      ? DocumentType.ETicket
                      : DocumentType.QRCode
                  }
                />
              )}
            </PreviewTicket>
            {ix !== tickets.length - 1 && <Divider />}
          </div>
        );
      })}
    </>
  );
};
