import { ComponentProps, ReactNode, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { IconButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, Stack } from 'src/core/ui';
import { Detail, DetailGroup, SectionContent } from 'src/modals/common';
import { GroupListingAutoCompSettingsForm } from 'src/modals/GroupListingsAutoGroupAndCompV2/autoCompGroup.types';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { ROW_COMP_SETTING_TO_CID } from 'src/utils/constants/contentIdMaps';
import { RowCompOverride } from 'src/WebApiController';

import * as styles from './AddRowCompOverrideDialog.css';

export type AddRowCompOverrideDialogProps = ComponentProps<typeof RSModal> & {
  onOkay: () => void;
  onCancel: () => void;
};

export function AddRowCompOverrideDialog({
  onOkay,
  onCancel,
  ...rest
}: AddRowCompOverrideDialogProps) {
  const { watch, setValue } =
    useFormContext<GroupListingAutoCompSettingsForm>();

  const rowCompOverrides = watch('rowCompOverrides');

  const onRowFromChange = useCallback(
    (index: number, value: number) => {
      setValue(`rowCompOverrides.${index}.rowOrdinalFrom`, value);
    },
    [setValue]
  );

  const onRowToChange = useCallback(
    (index: number, value: number) => {
      setValue(`rowCompOverrides.${index}.rowOrdinalTo`, value);
    },
    [setValue]
  );

  const onRowCompChange = useCallback(
    (index: number, value: number) => {
      setValue(`rowCompOverrides.${index}.offsetOverride`, value);
    },
    [setValue]
  );

  const onAddRule = useCallback(() => {
    const newRowCompOverrides = [
      ...rowCompOverrides,
      {
        rowFrom: undefined,
        rowTo: undefined,
        override: 1,
      },
    ] as RowCompOverride[];
    setValue('rowCompOverrides', newRowCompOverrides);
  }, [rowCompOverrides, setValue]);

  const onRemoveRule = useCallback(
    (index: number) => {
      const updatedRowCompOverrides = rowCompOverrides.filter(
        (_, i) => i !== index
      );
      setValue('rowCompOverrides', updatedRowCompOverrides);
    },
    [rowCompOverrides, setValue]
  );

  const rowCompOverrideInput = (
    index: number,
    rco?: RowCompOverride | undefined
  ): ReactNode => (
    <Stack
      direction="row"
      alignItems="end"
      gap="xxl"
      justifyContent="spaceEvenly"
    >
      <SectionContent numOfColumns={2}>
        <DetailGroup>
          <Detail
            label={
              <>
                <Content id={ContentId.Row} /> <Content id={ContentId.From} />
              </>
            }
            detail={
              <PosTextField
                type="number"
                onChange={(e) => {
                  const val = parseInt(e.target.value, 10) ?? undefined;
                  onRowFromChange(index, val);
                }}
                min={
                  index == 0 ? 0 : rowCompOverrides[index - 1].rowOrdinalTo + 1
                }
                value={rowCompOverrides[index].rowOrdinalFrom}
              />
            }
            style={{
              width: '8em',
            }}
          />
        </DetailGroup>
        <DetailGroup>
          <Detail
            label={
              <>
                <Content id={ContentId.Row} /> <Content id={ContentId.To} />
              </>
            }
            detail={
              <PosTextField
                type="number"
                onChange={(e) => {
                  const val = parseInt(e.target.value, 10) ?? undefined;
                  onRowToChange(index, val);
                }}
                min={index < 0 ? 0 : rowCompOverrides[index].rowOrdinalFrom}
                value={rowCompOverrides[index].rowOrdinalTo}
              />
            }
            style={{
              width: '8em',
            }}
          />
        </DetailGroup>
      </SectionContent>
      <Stack direction="row" gap="l">
        {Object.entries(ROW_COMP_SETTING_TO_CID).map(([option, contentId]) => {
          const rowCompSetting = rowCompOverrides[index].offsetOverride;
          return (
            <label
              key={option}
              className={`${styles.radioOption} ${
                rowCompSetting == parseInt(option) ? styles.selected : ''
              }`}
            >
              <input
                type="radio"
                value={option}
                checked={rowCompSetting == parseInt(option)}
                onChange={(e) =>
                  onRowCompChange(index, parseInt(e.target.value))
                }
                className={styles.hiddenRadio}
              />
              <Content id={contentId} />
            </label>
          );
        })}
      </Stack>

      <div className={styles.removeRuleButton}>
        <IconButton
          onClick={() => onRemoveRule(index)}
          titleContentId={ContentId.Remove}
          icon={<CrossIcon withHoverEffect />}
        />
      </div>
    </Stack>
  );

  return (
    <>
      <GenericDialog
        {...rest}
        fullscreen="lg"
        size="lg"
        header={<Content id={ContentId.RowCompOverrides} />}
        footer={
          <>
            <Button className={styles.cancelButton} onClick={onCancel}>
              <Content id={ContentId.Cancel} />
            </Button>
            <Button
              onClick={() => {
                onOkay();
              }}
            >
              <Content id={ContentId.Save} />
            </Button>
          </>
        }
        onCancel={onCancel}
      >
        {rowCompOverrides.map((rowCompOverride, index) => (
          <div className={styles.rowCompOverrideRow} key={index}>
            {rowCompOverrideInput(index, rowCompOverride)}
          </div>
        ))}

        <Button
          className={styles.addRuleButton}
          variant="text"
          onClick={() => onAddRule()}
        >
          <Content id={ContentId.AddRule} />
        </Button>
      </GenericDialog>
    </>
  );
}
