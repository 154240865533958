import * as Label from '@radix-ui/react-label';
import { useCallback, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SellerAccountClient } from 'src/WebApiController';

import * as styles from './EditUserModal.css';

const AddUserModalHeader = () => {
  return (
    <div className={styles.header}>
      <h5 className={styles.headerText}>
        <Content id={ContentId.AddUser} />
      </h5>
    </div>
  );
};

type AddUserInput = {
  email: string;
};

type AddUserModalFooterProps = { onClose: () => void };

const AddUserModalFooter = ({ onClose }: AddUserModalFooterProps) => {
  const { handleSubmit, setError, getValues, clearErrors, resetField } =
    useFormContext<AddUserInput>();
  const requiredMsg = useContent(ContentId.Required);
  const stubhubLoginMsg = useContent(ContentId.StubHubLoginRequired);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { refetchUserInfos, allUserInfos } = useSellerAccountContext();

  const onSubmit = useCallback(
    async (formValue: AddUserInput) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          if (
            Object.values(allUserInfos ?? {}).some(
              (u) => u.email === formValue.email
            )
          ) {
            onClose();
            resetField('email');
            return;
          }

          const result = await new SellerAccountClient(
            activeAccountWebClientConfig
          ).activateUserForSellerAccountByEmail(formValue.email);

          if (result) {
            refetchUserInfos();
            resetField('email');
            onClose();
          } else {
            setError('email', { message: stubhubLoginMsg });
          }
        },
        (error) => {
          showErrorDialog(
            'SellerAccountClient.activateUserForSellerAccount',
            error,
            {
              trackErrorData: formValue,
            }
          );
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      allUserInfos,
      onClose,
      refetchUserInfos,
      resetField,
      setError,
      showErrorDialog,
      stubhubLoginMsg,
    ]
  );

  const onSubmitHandler = useCallback(() => {
    const formValue = getValues();

    const { email } = formValue;

    clearErrors('email');

    let hasErrors = false;

    if (!email) {
      setError('email', { message: requiredMsg });
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    handleSubmit(onSubmit)();
  }, [getValues, clearErrors, handleSubmit, onSubmit, setError, requiredMsg]);

  const cancelText = useContent(ContentId.Cancel);
  const saveText = useContent(ContentId.Add);
  return (
    <div className={styles.footer}>
      <div className={styles.buttonGroup}>
        <Button variant="outline" onClick={onClose} disabled={isLoading}>
          {cancelText}
        </Button>
        <Button onClick={onSubmitHandler} disabled={isLoading}>
          {saveText}
        </Button>
      </div>
    </div>
  );
};

export const AddUserModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const methods = useForm<AddUserInput>();

  return (
    <FormProvider {...methods}>
      <AddUserModalContent isOpen={isOpen} onClose={onClose} />
    </FormProvider>
  );
};

const AddUserModalContent = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    clearErrors,
  } = useFormContext<AddUserInput>();

  const email = watch('email');

  return (
    <Modal isOpen={isOpen} onClose={onClose} unmountOnClose centered size="sm">
      <ModalHeader onClose={onClose}>
        <AddUserModalHeader />
      </ModalHeader>
      <ModalBody>
        <div className={styles.root}>
          <div className={styles.formInputGroup}>
            <Label.Root className={styles.input} htmlFor="email">
              <Content id={ContentId.EnterStubHubEmailPrompt} />
            </Label.Root>
            <PosFormField errors={errors.email?.message}>
              <PosTextField
                value={email}
                {...register('email')}
                onChange={(e) => {
                  clearErrors('email');
                  setValue('email', e.target.value);
                }}
              />
            </PosFormField>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <AddUserModalFooter onClose={onClose} />
      </ModalFooter>
    </Modal>
  );
};
