import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const DateRangeTriggerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  width: 100%;

  > *:first-child {
    padding-right: ${() => vars.spacing.lg};
  }
  > *:last-child {
    padding-right: 0;
  }
`;

export const DateRangeTrigger = styled.button<{
  isActive: boolean;
}>`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('body1')};
  background-color: ${vars.color.backgroundPrimaryActive};
  color: ${(props) =>
    props.isActive
      ? props.theme.colors.textStrong
      : props.theme.colors.textPrimary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${() => `${vars.spacing.xs} ${vars.spacing.lg}`};
  width: 100%;
  white-space: nowrap;
  border-radius: ${() => vars.spacing.xxs};
  border: 1px solid ${(props) => props.theme.colors.borderDark};
  z-index: 3;
  cursor: pointer;
  outline: none;
  transition: border 0.2s;

  &:hover:not([disabled]) {
    border-color: ${(props) => props.theme.colors.borderActive};
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${(props) => props.theme.colors.borderActive};
  margin-right: ${() => vars.spacing.sm};
  padding-right: ${() => vars.spacing.sm};

  svg {
    height: 18px;
    cursor: pointer;
  }

  svg > path {
    fill: ${(props) => props.theme.colors.textBrand};
  }
`;

export const DateRangeInputContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${() => vars.spacing.sm};
  padding-top: ${() => vars.spacing.xl};
  border-top: 1px solid ${(props) => props.theme.colors.borderPrimary};
`;

export const DateInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  height: ${() => `${vars.spacing['5xl']}`};
  padding: ${() =>
    `${vars.spacing.xxs} ${vars.spacing.lg} ${vars.spacing.m} ${vars.spacing.lg}`};
  width: 140px;
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('caption2')};
  color: ${(props) => props.theme.colors.textPrimary};
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
  border-radius: ${() => vars.spacing.xxs};
  background-color: ${(props) => props.theme.colors.backgroundSecondary};

  &:focus-within {
    border-color: ${(props) => props.theme.colors.borderActive};
    box-shadow: ${(props) => props.theme.shadow(2, 'b')};
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export const DateInput = styled.input`
  border: none;
  outline: none;
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('body2')};
  color: ${(props) => props.theme.colors.textStrong};
  background-color: transparent;
`;

export const CustomDateRangeButton = styled.button`
  display: inline-flex;
  align-items: center;
  gap: ${() => vars.spacing.sm};
  padding: ${() => `${vars.spacing.sm} ${vars.spacing.lg}`};
  margin: ${() =>
    `${vars.spacing.sm} -${vars.spacing.lg} -${vars.spacing.lg} -${vars.spacing.lg}`};
  width: calc(100% + 32px);
  font-weight: 500;
  text-align: left;
  :hover {
    background-color: ${(props) => props.theme.colors.backgroundBrandLight};
  }
  border: none;
  background: none;
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('body1')};
`;

export const DateErrorContainer = styled.ul`
  display: block;
  width: 100%;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('caption2')};
  color: ${(props) =>
    props.color /* partner */ ?? props.theme.colors.textNegative};
`;

export const DateRangePickerContainer = styled.div`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('body1')};
  padding: ${() => vars.spacing.lg};
  width: 100%;
`;
