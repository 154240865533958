import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  POS_EVENT_IDS_SEPARATOR,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_QUERY_PARAM,
  TAB_EVENT_IDS_SEPARATOR,
} from 'src/navigations/Routes/InventoryEvent/InventoryEvent.constants';
import { MainRoute } from 'src/navigations/Routes/MainRoute';

export const useCatchUpParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { mainRoute } = useAppContext();

  const posEventIds = searchParams
    .get(TAB_EVENT_IDS_QUERY_PARAM)
    ?.toLowerCase();
  const activeEventIds = searchParams
    .get(ACTIVE_EVENT_IDS_QUERY_PARAM)
    ?.toLowerCase();

  const viagVirtualIds = searchParams
    .get(TAB_EVENT_IDS_2_QUERY_PARAM)
    ?.toLowerCase();
  const activeViagVirtualId = searchParams
    .get(ACTIVE_EVENT_IDS_2_QUERY_PARAM)
    ?.toLowerCase();

  // [['event1', 'event2'], ['event3', 'event4'], ['event5']]
  const posEventIdsList = useMemo(
    () =>
      posEventIds
        ?.split(TAB_EVENT_IDS_SEPARATOR)
        ?.map((eid) => eid.split(POS_EVENT_IDS_SEPARATOR)),
    [posEventIds]
  );

  const viagVirtualIdsList = useMemo(
    () => viagVirtualIds?.split(TAB_EVENT_IDS_SEPARATOR),
    [viagVirtualIds]
  );

  const numberOfEvents = useMemo(
    () => viagVirtualIdsList?.length ?? posEventIdsList?.length,
    [posEventIdsList?.length, viagVirtualIdsList?.length]
  );

  const selectedIndex = useMemo(() => {
    if (activeEventIds && posEventIdsList) {
      const activePosEventIds = activeEventIds.split(POS_EVENT_IDS_SEPARATOR);
      return posEventIdsList.findIndex((posEventIds) =>
        activePosEventIds.some((posEventId) => posEventIds.includes(posEventId))
      );
    } else if (activeViagVirtualId && viagVirtualIdsList) {
      return viagVirtualIdsList.findIndex(
        (viagId) => activeViagVirtualId === viagId
      );
    }
  }, [
    activeEventIds,
    activeViagVirtualId,
    posEventIdsList,
    viagVirtualIdsList,
  ]);

  const isCatchupMode = useMemo(
    () =>
      mainRoute === MainRoute.Inventory &&
      ((activeEventIds && posEventIds) ||
        (activeViagVirtualId && viagVirtualIds)),
    [
      mainRoute,
      activeEventIds,
      posEventIds,
      activeViagVirtualId,
      viagVirtualIds,
    ]
  );

  const onPrevClick = useCallback(() => {
    if (
      (posEventIdsList == null && viagVirtualIdsList == null) ||
      selectedIndex == null
    ) {
      return;
    }
    const index = selectedIndex - 1;
    if (index >= 0) {
      if (posEventIdsList) {
        setSearchParams((prev) => {
          prev.set(
            ACTIVE_EVENT_IDS_QUERY_PARAM,
            posEventIdsList[index].join(POS_EVENT_IDS_SEPARATOR)
          );
          return prev;
        });
      } else if (viagVirtualIdsList) {
        setSearchParams((prev) => {
          prev.set(ACTIVE_EVENT_IDS_2_QUERY_PARAM, viagVirtualIdsList[index]);
          return prev;
        });
      }
    }
  }, [posEventIdsList, selectedIndex, setSearchParams, viagVirtualIdsList]);

  const onNextClick = useCallback(() => {
    if (
      (posEventIdsList == null && viagVirtualIdsList == null) ||
      selectedIndex == null
    ) {
      return;
    }
    const index = selectedIndex + 1;
    if (posEventIdsList) {
      if (index < posEventIdsList.length) {
        setSearchParams((prev) => {
          prev.set(
            ACTIVE_EVENT_IDS_QUERY_PARAM,
            posEventIdsList[index].join(POS_EVENT_IDS_SEPARATOR)
          );
          return prev;
        });
      }
    } else if (viagVirtualIdsList) {
      if (index < viagVirtualIdsList.length) {
        setSearchParams((prev) => {
          prev.set(ACTIVE_EVENT_IDS_2_QUERY_PARAM, viagVirtualIdsList[index]);
          return prev;
        });
      }
    }
  }, [posEventIdsList, selectedIndex, setSearchParams, viagVirtualIdsList]);

  return {
    posEventIdsList,
    viagVirtualIdsList,
    activeEventIds,
    activeViagVirtualId,
    numberOfEvents,
    selectedIndex,
    onPrevClick,
    onNextClick,
    setSearchParams,
    isCatchupMode,
  };
};
