import { PropsWithChildren, useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { ReportMetricsContextProviderV2 } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { EmptySaleQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { ReportV2Client, SaleQuery, UserSetting } from 'src/WebApiController';

import { getCatalogData } from '../Sales';

export function ReportsSaleContextProviderV2({
  reportConfig,
  isExport,
  children,
}: PropsWithChildren<{
  reportConfig: ReportConfigV2;
  isExport?: boolean;
}>) {
  const { activeAccountWebClientConfig } = useAppContext();

  const getReportMetrics = useCallback(
    (filters: SaleQuery, pageNumber: number) => {
      return new ReportV2Client(
        activeAccountWebClientConfig
      ).getSaleReportMetrics({
        ...reportConfig.request,
        pageNumber,
        filters,
      });
    },
    [activeAccountWebClientConfig, reportConfig]
  );

  const getReportMetricsSummary = useCallback(
    (filters: SaleQuery) => {
      return new ReportV2Client(
        activeAccountWebClientConfig
      ).getSaleReportSummary({
        ...reportConfig.request,
        filters,
      });
    },
    [activeAccountWebClientConfig, reportConfig]
  );

  return (
    <FilterQueryContextProvider<SaleQuery>
      initialQuery={reportConfig.request.filters as SaleQuery}
      emptyQuery={EmptySaleQuery}
      loadQueryFromUrl={false}
      // Not actually used
      viewModeSettingId={UserSetting.SalePageViewMode}
    >
      <CatalogDataContextProvider<SaleQuery>
        queryKey="getCatalogForSale"
        getCatalogData={(c, f) => getCatalogData(c, f, false)}
        transformEventData={transformData}
      >
        <ReportMetricsContextProviderV2<SaleQuery>
          queryKey="getReportSaleMetricsV2"
          getReportMetrics={getReportMetrics}
          getReportMetricsSummary={getReportMetricsSummary}
          disabled={isExport}
        >
          {children}
        </ReportMetricsContextProviderV2>
      </CatalogDataContextProvider>
    </FilterQueryContextProvider>
  );
}
