import { createContext, ReactNode, useContext } from 'react';
import { useToggle } from 'react-use';

type MenuContextType = {
  isMenuOpened: boolean;
  toggleIsMenuOpened: (nextValue?: boolean) => void;
};

const MenuContext = createContext<MenuContextType>({
  isMenuOpened: false,
  toggleIsMenuOpened: () => void 0,
});

type MenuContextProviderProps = { children: ReactNode };

export const MenuContextProvider = ({ children }: MenuContextProviderProps) => {
  const [isMenuOpened, toggleIsMenuOpened] = useToggle(false);

  return (
    <MenuContext.Provider value={{ isMenuOpened, toggleIsMenuOpened }}>
      {children}
    </MenuContext.Provider>
  );
};

export const useMenuState = () => useContext(MenuContext);
