import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { ErrorMessage } from 'src/core/POS/ErrorMessage';
import { Button, Stack } from 'src/core/ui';
import { ModalBodyDataContainer } from 'src/modals/Modal/Modal.styled';
import { ContentId } from 'src/utils/constants/contentId';
import { TransferTicketsForm } from 'src/utils/ticketUtils';

import { URLInputField } from './URLInput';
import * as styles from './UrlProof.css';

export const UrlProofBody = ({ disabled }: { disabled?: boolean }) => {
  const { register, formState, watch } = useFormContext<TransferTicketsForm>();
  const { fields, append, remove } = useFieldArray({
    name: 'acceptanceUrlInputs',
  });
  const allUrlInputs = watch('acceptanceUrlInputs');
  useEffect(() => {
    if (fields.length === 0) {
      append({ id: fields.length, url: '' });
    }
  }, [append, fields.length]);

  const allUrlsError = formState?.errors?.acceptanceUrlInputs?.message;
  return (
    <ModalBodyDataContainer>
      <Stack direction="column" gap="xl">
        <Content id={ContentId.ProvideTransferUrlInstruction} />
        <div className={styles.sectionWrapper}>
          {fields.map((field, index) => {
            const error =
              formState?.errors?.acceptanceUrlInputs?.[index]?.url?.message;
            return (
              <URLInputField
                disabled={disabled}
                id={field.id}
                key={field.id}
                index={index}
                register={register}
                canRemove={(allUrlInputs?.length || 0) > 1}
                onRemove={() => remove(index)}
                allFields={allUrlInputs}
                error={error}
              />
            );
          })}
          <div className={styles.addUrlWrapper}>
            <Button
              disabled={disabled}
              variant={'text'}
              onClick={() => append({ id: fields.length, url: '' })}
            >
              + <Content id={ContentId.AddUrl} />
            </Button>
          </div>
          {allUrlsError && <ErrorMessage>{allUrlsError}</ErrorMessage>}
        </div>
      </Stack>
    </ModalBodyDataContainer>
  );
};
