import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext/Content';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { DEAL_CONFIG_SPLIT_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { DealSplitType } from 'src/WebApiController';

import * as styles from './Deals.css';
import { useDealContext } from 'src/contexts/DealContext/DealContext';
import { posChangedField } from 'src/utils/posFieldUtils';

export const DealSplitTypeSection = ({
  dealSplitType,
}: {
  dealSplitType: DealSplitType | null;
}) => {
  const { localDeal, updateLocalDealDetails} = useDealContext();

  const dealConfigs = localDeal?.dealDetails?.dealConfigs;
  if (!dealSplitType) {
    return;
  }

  return (
    <PosFormField
      label={
        <div className={styles.SectionTitle}>
          <Content id={ContentId.RevenueSplitHurdle} />
        </div>
      }
      errors={undefined}
    >
      <PosEnumSelect
        valueOptionsContent={DEAL_CONFIG_SPLIT_TYPE_TO_CID}
        value={dealSplitType}
        onChange={(value) => {
          updateLocalDealDetails({configSplitType: value});

          if (value === DealSplitType.None) {
            updateLocalDealDetails({dealConfigs: 
              posChangedField(dealConfigs?.value && dealConfigs.value.length > 0
                ? [dealConfigs.value[0]]
                : [])
            });
          }
        }}
      />
    </PosFormField>
  );
};
