import { useQuery } from '@tanstack/react-query';
import { pickBy } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDebounce } from 'react-use';
import {
  ConversationType,
  convertEmailClassificationTypeToConversationType,
} from 'src/components/Messages/ConversationSidePanel/ConversationListItem/ConversationListItem';
import { EmailBody } from 'src/components/Messages/EmailBody';
import { PurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { EmailPurchaseOrderContext } from 'src/contexts/EmailPurchaseOrderContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Button, ScrollArea } from 'src/core/ui';
import { SaleContextProvider } from 'src/navigations/Routes/Sales';
import { ContentId } from 'src/utils/constants/contentId';
import { AUTO_PO_INBOUND_EMAIL_CLASSIFICATION_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getPurchaseOrderDetailsModalConfigWithDeepLink } from 'src/utils/purchaseUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AutoPoClient,
  AutoPoInboundEmailClassificationType,
  PurchaseClient,
  PurchasingIdInUseResultType,
} from 'src/WebApiController';

import * as styles from './ClassifyEmail.css';
import { SaleSearchAccordionList } from './SaleSearchAccordionList';
import { SaleSearchBox } from './SaleSearchBox';

function getClassificationTypeToCid(
  conversationType: ConversationType
): Record<string, string> {
  return pickBy(AUTO_PO_INBOUND_EMAIL_CLASSIFICATION_TYPE_TO_CID, (_, key) => {
    return (
      convertEmailClassificationTypeToConversationType(
        key as AutoPoInboundEmailClassificationType
      ) === conversationType
    );
  });
}

type ClassifyEmailFieldValues = {
  classificationType?: AutoPoInboundEmailClassificationType;
  orderId?: string;
  purchaseOrderVendorId?: number;
  saleId?: number;
};

export type ClassifyEmailProps = {
  conversationType: ConversationType;
  inboundEmailId: number;
  onEmailClassified?: (inboundEmailId: number) => void;
};

export function ClassifyEmail({
  conversationType,
  inboundEmailId,
  onEmailClassified,
}: ClassifyEmailProps) {
  // XXX hack to fix breaking of this message since it uses non-breaking space for some reason
  const orderIdInUseMessage = useContent(ContentId.OrderIdInUse).replaceAll(
    '\u00a0',
    ' '
  );
  const orderIdInUseSameVendorMessage = useContent(
    ContentId.OrderIdInUseSameVendor
  );
  const orderIdDoesNotExistMessage = useContent(ContentId.OrderIdDoesNotExist);
  const orderIdDoesNotExistForVendorMessage = useContent(
    ContentId.OrderIdDoesNotExistForVendor
  );

  const classificationTypeToCid = getClassificationTypeToCid(conversationType);
  const defaultClassificationType = Object.keys(
    classificationTypeToCid
  )[0] as AutoPoInboundEmailClassificationType;

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<ClassifyEmailFieldValues>({
    defaultValues: {
      classificationType: defaultClassificationType,
      orderId: undefined, // TODO use orderId from partialpurchaseorder
    },
  });

  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const { setModal, closeModal } = useContext(ModalContext);
  const {
    setOrderId,
    setClassificationType,
    reset: resetEmailPurchaseOrderContext,
  } = useContext(EmailPurchaseOrderContext);

  const watchedClassificationType = watch('classificationType');
  const [debouncedOrderId, setDebouncedOrderId] = useState<string>();
  useDebounce(
    () => {
      if (
        watchedClassificationType &&
        convertEmailClassificationTypeToConversationType(
          watchedClassificationType
        ) === ConversationType.Purchase
      ) {
        setDebouncedOrderId(watch('orderId'));
      }
    },
    200, // lower delay since the api call is relatively cheap
    [watchedClassificationType, watch('orderId')]
  );
  const watchedPurchaseOrderVendorId = watch('purchaseOrderVendorId');
  const orderIdInUseQuery = useQuery({
    queryKey: [
      'PurchaseClient.getIsPurchasingIdsInUse',
      activeAccountWebClientConfig.activeAccountId,
      conversationType,
      watchedPurchaseOrderVendorId,
      debouncedOrderId,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      if (conversationType !== ConversationType.Purchase || !debouncedOrderId) {
        return PurchasingIdInUseResultType.NoneMatching;
      }

      const orderId = debouncedOrderId.toString().trim();
      return await new PurchaseClient(
        activeAccountWebClientConfig
      ).getIsPurchasingIdsInUse(orderId, watchedPurchaseOrderVendorId);
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError(err: ErrorTypes) {
        trackError('PurchaseClient.getIsPurchasingIdsInUse', err, {
          orderId: debouncedOrderId,
          purchaseOrderVendorId: watchedPurchaseOrderVendorId,
        });
      },
    },
  });
  const orderIdUsedByPurchaseOrderIdsResult = useQuery({
    queryKey: [
      'PurchaseClient.getPurchaseOrderIdsUsingOrderId',
      activeAccountWebClientConfig.activeAccountId,
      conversationType,
      debouncedOrderId,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      if (conversationType !== ConversationType.Purchase || !debouncedOrderId) {
        return [];
      }
      const orderId = debouncedOrderId.toString().trim();
      const purchaseOrderIds = await new PurchaseClient(
        activeAccountWebClientConfig
      ).getPurchaseOrderIdsUsingOrderId(orderId);
      return purchaseOrderIds;
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError(err: ErrorTypes) {
        trackError('PurchaseClient.getPurchaseOrderIdsUsingOrderId', err, {
          orderId: debouncedOrderId,
        });
      },
    },
  });

  useEffect(() => {
    const purchaseOrderIds = orderIdUsedByPurchaseOrderIdsResult.data;
    if (
      conversationType !== ConversationType.Purchase ||
      !purchaseOrderIds ||
      !debouncedOrderId
    ) {
      return;
    }

    if (
      watchedClassificationType ===
      AutoPoInboundEmailClassificationType.NewPurchaseOrder
    ) {
      if (orderIdInUseQuery.data === PurchasingIdInUseResultType.SameOrderId) {
        setError('orderId', {
          type: 'existingOrderId',
          message: orderIdInUseMessage,
        });
      } else if (
        orderIdInUseQuery.data ===
        PurchasingIdInUseResultType.SameOrderIdAndVendorId
      ) {
        setError('orderId', {
          type: 'existingOrderId',
          message: orderIdInUseSameVendorMessage,
        });
      }
    } else {
      if (orderIdInUseQuery.data === PurchasingIdInUseResultType.NoneMatching) {
        setError('orderId', {
          type: 'existingOrderId',
          message: orderIdDoesNotExistMessage,
        });
      } else if (
        orderIdInUseQuery.data === PurchasingIdInUseResultType.SameOrderId
      ) {
        setError('orderId', {
          type: 'existingOrderId',
          message: orderIdDoesNotExistForVendorMessage,
        });
      }
    }
  }, [
    conversationType,
    watchedClassificationType,
    debouncedOrderId,
    orderIdInUseQuery.data,
    orderIdUsedByPurchaseOrderIdsResult.data,
    setError,
    orderIdInUseMessage,
    orderIdInUseSameVendorMessage,
    orderIdDoesNotExistMessage,
    orderIdDoesNotExistForVendorMessage,
  ]);

  // if marked as new order, but the order id and vendor already exist,
  // suggest that the user classifies as an existing order
  const classifyNewOrderAsExistingOrder =
    conversationType === ConversationType.Purchase &&
    watchedClassificationType &&
    orderIdInUseQuery.isSuccess &&
    watchedClassificationType ===
      AutoPoInboundEmailClassificationType.NewPurchaseOrder &&
    orderIdInUseQuery.data ===
      PurchasingIdInUseResultType.SameOrderIdAndVendorId;
  // if marked as an existing order, but the order id and vendor combo does not exist,
  // send the user to the create purchase order modal first before classifying
  const createNewOrderForExistingOrder =
    conversationType === ConversationType.Purchase &&
    watchedClassificationType &&
    orderIdInUseQuery.isSuccess &&
    watchedClassificationType !==
      AutoPoInboundEmailClassificationType.NewPurchaseOrder &&
    orderIdInUseQuery.data !==
      PurchasingIdInUseResultType.SameOrderIdAndVendorId;

  const onSubmit = handleSubmit(async (data) => {
    if (classifyNewOrderAsExistingOrder) {
      clearErrors(['orderId']);
      setValue(
        'classificationType',
        AutoPoInboundEmailClassificationType.ExistingPurchaseOrder
      );
    } else if (
      createNewOrderForExistingOrder ||
      data.classificationType ===
        AutoPoInboundEmailClassificationType.NewPurchaseOrder
    ) {
      setOrderId(data.orderId);
      setClassificationType(data.classificationType);
      setModal({
        ...getPurchaseOrderDetailsModalConfigWithDeepLink(),
        onExit: () => {
          resetEmailPurchaseOrderContext();
        },
      });
    } else {
      const purchaseOrderId =
        orderIdUsedByPurchaseOrderIdsResult.data?.[0] ?? null;
      await tryInvokeApi(
        async () => {
          await new AutoPoClient(
            activeAccountWebClientConfig
          ).classifyInboundEmail(
            inboundEmailId,
            data.classificationType,
            purchaseOrderId,
            data.saleId
          );
          onEmailClassified?.(inboundEmailId);
        },
        (error) => {
          showErrorDialog('AutoPoClient.classifyInboundEmail', error, {
            trackErrorData: {
              inboundEmailId,
            },
          });
        }
      );

      resetEmailPurchaseOrderContext();
      closeModal();
    }
  });

  return (
    <div className={styles.root}>
      <ScrollArea className={styles.emailSection}>
        <div className={styles.emailPreviewTitle}>
          <Content id={ContentId.EmailPreview} />
        </div>
        <div className={styles.emailWrapper}>
          <EmailBody inboundEmailId={inboundEmailId} />
        </div>
      </ScrollArea>
      <div className={styles.formSection}>
        <div className={styles.formContainer}>
          <PosFormField label={<Content id={ContentId.Subject} />}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="classificationType"
              render={({ field: { onChange, ...props } }) => (
                <PosEnumSelect
                  style={{ width: '100%' }}
                  placeholderText={ContentId.SelectSubjectPlaceholder}
                  valueOptionsContent={classificationTypeToCid}
                  onChange={(value) => {
                    if (value) {
                      const conversationType =
                        convertEmailClassificationTypeToConversationType(value);
                      if (conversationType !== ConversationType.Purchase) {
                        setValue('orderId', undefined);
                      }
                      if (
                        conversationType !== ConversationType.Sale &&
                        conversationType !== ConversationType.Transfer
                      ) {
                        setValue('saleId', undefined);
                      }
                    }
                    clearErrors('orderId');
                    onChange(value);
                  }}
                  {...props}
                />
              )}
            />
          </PosFormField>
          {conversationType === ConversationType.Purchase ? (
            <>
              <PosFormField
                label={<Content id={ContentId.Vendor} />}
                errors={errors.purchaseOrderVendorId?.message}
              >
                <Controller
                  control={control}
                  rules={{ required: true }}
                  name="purchaseOrderVendorId"
                  render={({ field: { value, onChange, ...props } }) => (
                    <PurchaseVendorSelector
                      style={{ width: '100%' }}
                      value={value?.toString()}
                      onChange={(value: string | null) => {
                        clearErrors('orderId');
                        onChange(value ? parseInt(value) : undefined);
                      }}
                      {...props}
                    />
                  )}
                />
              </PosFormField>
              <PosFormField
                label={<Content id={ContentId.ExternalPurchaseId} />}
                {...(errors.orderId?.message === orderIdInUseMessage
                  ? { warnings: errors.orderId?.message }
                  : { errors: errors.orderId?.message })}
              >
                <PosTextField
                  type="text"
                  {...register('orderId', {
                    required: true,
                    onChange() {
                      clearErrors('orderId');
                    },
                  })}
                />
              </PosFormField>
            </>
          ) : conversationType === ConversationType.Sale ||
            conversationType === ConversationType.Transfer ? (
            <>
              <PosFormField
                label={<Content id={ContentId.SaleId} />}
                warnings={errors.saleId?.message}
              >
                <PosTextField
                  type="text"
                  {...register('saleId', {
                    required: true,
                    valueAsNumber: true,
                    onChange() {
                      clearErrors('saleId');
                    },
                  })}
                />
              </PosFormField>
            </>
          ) : null}
          <div className={styles.footer}>
            <Button
              disabled={
                orderIdInUseQuery.isLoading ||
                orderIdUsedByPurchaseOrderIdsResult.isLoading
              }
              onClick={onSubmit}
            >
              {classifyNewOrderAsExistingOrder ? (
                <FormatContent
                  id={FormatContentId.ClassifyAs}
                  params={<Content id={ContentId.ExistingOrder} />}
                />
              ) : createNewOrderForExistingOrder ? (
                <Content id={ContentId.CreateNewOrder} />
              ) : (
                <Content id={ContentId.Link} />
              )}
            </Button>
          </div>
        </div>
        {(conversationType === ConversationType.Sale ||
          conversationType === ConversationType.Transfer) && (
          <SaleContextProvider>
            <div className={styles.searchBoxWrapper}>
              <SaleSearchBox />
            </div>
            <div className={styles.accordionListwrapper}>
              <SaleSearchAccordionList
                onSelectSale={(sale) => {
                  setValue('saleId', sale.id);
                }}
              />
            </div>
          </SaleContextProvider>
        )}
      </div>
    </div>
  );
}
