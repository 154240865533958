import { debounce, isEmpty } from 'lodash-es';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { vars } from 'src/core/themes';
import { FocalPointInfo, SectionInfo } from 'src/WebApiController';
import svgPanZoom from 'svg-pan-zoom';

import { addFocalPoint } from '../shared/utils/addFocalPoint';
import {
  isMissiongSectionData,
  registerPanningListners,
} from '../VenueMapContent.hooks';
import { StandardColorSpec } from './VenueMapV3Content';

const clickEvents = ['click'];

const ZOOM_SCALE_SENSITIVITY = 0.2;

type GetColor = (info: {
  sectionId: number;
  rowId?: number;
  sectionName?: string;
  ticketClassId: number;
  isSelected: boolean;
  useRowColor?: boolean;
}) => { fill: string; stroke: string; textColor?: string } | void;

type SvgMapInitializerProps = {
  availableSections: SectionInfo[];
  selectedSections?: SectionInfo[];
  markedSectionIds?: number[];
  onSectionClicked?: (
    e: MouseEvent,
    selectedSection: SectionInfo,
    surroundingSections?: SectionInfo[]
  ) => void;
  onSectionHovered?: (hoveredSection: SectionInfo) => ReactNode;
  setSelectedSections?: (sections: SectionInfo[]) => void;
  isZoomEnabled: boolean;
  showDefaultMapColors?: boolean;
  focalPoint?: FocalPointInfo | null;
  colors?: StandardColorSpec;
  getColor?: GetColor;
  isHeatMap?: boolean;
};

export const useSvgMap = ({
  availableSections,
  selectedSections,
  markedSectionIds,
  onSectionClicked,
  onSectionHovered,
  setSelectedSections,
  isZoomEnabled,
  focalPoint,
  getColor,
  isHeatMap = false,
}: SvgMapInitializerProps) => {
  const localRef = useRef<HTMLDivElement>();

  const isPanning = useRef(false);
  const [hoveredContent, setHoverContent] = useState<ReactNode>();
  const [zoomFlag, setZoomFlag] = useState(false);
  const [venueMapSvg, setVenueMapSvg] = useState<SvgPanZoom.Instance>();
  const [sectionGroups, setSectionGroups] = useState<SVGElement[]>([]);

  const sectionIdsMissingRowOrder = useMemo(
    () => availableSections.filter(isMissiongSectionData).map(({ id }) => id),
    [availableSections]
  );

  const setSelectedHandler = useCallback(
    (e: Event) => {
      if (isPanning.current) {
        return;
      }
      const mouseEvent = e as MouseEvent;
      const target = e.target as SVGElement;

      const selectedTicketClassId = parseInt(getTicketClassId(target) ?? '0');
      const selectedSectionId = parseInt(getSectionId(target) ?? '0');

      const selectedSection = availableSections.find(
        (x) =>
          x.id === selectedSectionId &&
          (selectedTicketClassId === 0 ||
            x.specRow?.ticketClassId === selectedTicketClassId ||
            !x.rows?.length ||
            !selectedTicketClassId ||
            x.rows?.find((r) => r.tktClass?.id === selectedTicketClassId))
      );

      selectedSection && onSectionClicked?.(mouseEvent, selectedSection);
    },
    [availableSections, onSectionClicked]
  );

  const mouseEnter = useCallback(
    ({ target }: MouseEvent) => {
      const section = target as SVGElement;
      const sectionId = parseInt(getSectionId(section) ?? '0');

      const hoveredSection = availableSections.find((s) => s.id === sectionId);
      if (!hoveredSection) return;

      const hoveredContent = onSectionHovered?.(hoveredSection);
      setHoverContent(hoveredContent);
      const sectionPath = section.querySelector('path[t="s"]') as SVGElement;
      sectionPath?.setAttribute('fill-opacity', '0.75');
    },
    [availableSections, onSectionHovered]
  );

  const mouseLeave = useCallback(({ target }: MouseEvent) => {
    const section = target as SVGElement;
    const sectionPath = section.querySelector('path[t="s"]') as SVGElement;

    // If there are row paths, set the section path to be transparent
    // otherwise set the section path to be opaque
    const sectionRows = [
      ...section.querySelectorAll('g[id="row"] path'),
    ] as HTMLElement[];
    if (!isEmpty(sectionRows)) {
      sectionPath?.setAttribute('fill-opacity', '0');
    } else {
      sectionPath?.setAttribute('fill-opacity', '1');
    }
  }, []);

  const initializeSvg = useCallback(
    (svgElement: SVGSVGElement) => {
      addFocalPoint(svgElement, focalPoint);
      return svgPanZoom(svgElement, {
        zoomScaleSensitivity: ZOOM_SCALE_SENSITIVITY,
        zoomEnabled: isZoomEnabled,
        onZoom: () => setZoomFlag(true),
        onPan: () => setZoomFlag(true),
      });
    },
    [focalPoint, isZoomEnabled]
  );

  const onResize = useCallback(() => {
    if (localRef.current && venueMapSvg) {
      const svgElement = localRef.current.getElementsByTagName('svg')[0];
      svgElement.setAttribute('width', localRef.current.clientWidth.toString());
      svgElement.setAttribute(
        'height',
        localRef.current.clientHeight.toString()
      );
      venueMapSvg.resize(); // update SVG cached size and controls positions
      venueMapSvg.fit();
      venueMapSvg.center();
    }
  }, [venueMapSvg]);

  useEffect(() => {
    if (!venueMapSvg) return;

    const debounceOnResize = debounce(onResize, 200);

    // add eventListeners
    sectionGroups.forEach((element) => {
      const sectionId = parseInt(getSectionId(element) ?? '0');
      const isSelected =
        selectedSections?.some(({ id }) => id === sectionId) ?? false;
      const isMarked = markedSectionIds?.some((id) => id === sectionId);

      const sectionPath = element.querySelector('path[t="s"]') as SVGElement;
      const sectionLabel = element.querySelector('text[t="s"]') as SVGElement;

      element.style.cursor = 'pointer';

      const sectionRows = [
        ...element.querySelectorAll('g[id="row"] path'),
      ] as HTMLElement[];
      // If there are row paths, set the section path to be transparent
      // otherwise set the section path to be opaque
      if (!isEmpty(sectionRows)) {
        sectionPath?.setAttribute('fill', vars.color.backgroundHighlightHover);
        sectionPath?.setAttribute('fill-opacity', '0');
      } else {
        sectionPath?.setAttribute('fill-opacity', '1');
      }
      sectionPath?.setAttribute('stroke-width', '2px');
      sectionPath?.setAttribute('stroke', 'black');
      sectionLabel?.removeAttribute('fill');

      if (isSelected) {
        sectionPath?.setAttribute('stroke', vars.color.textBrand);
        sectionPath?.setAttribute('stroke-width', '6px');
        sectionLabel?.setAttribute('fill', vars.color.textBrand);
      } else if (isMarked) {
        sectionPath?.setAttribute('stroke', vars.color.textBrand);
        sectionPath?.setAttribute('stroke-width', '6px');
      }

      element.addEventListener('mouseenter', mouseEnter);
      element.addEventListener('mouseleave', mouseLeave);
      clickEvents.forEach((e) =>
        element.addEventListener(e, setSelectedHandler)
      );
    });

    window.addEventListener('resize', debounceOnResize);

    const deregisterPanEvents = registerPanningListners(
      localRef.current,
      isPanning
    );

    return () => {
      window.removeEventListener('resize', debounceOnResize);

      // remove eventListeners
      sectionGroups.forEach((element) => {
        element.removeEventListener('mouseenter', mouseEnter);
        element.removeEventListener('mouseleave', mouseLeave);
        clickEvents.forEach((e) =>
          element.removeEventListener(e, setSelectedHandler)
        );
      });

      deregisterPanEvents();
    };
  }, [
    markedSectionIds,
    mouseEnter,
    mouseLeave,
    onResize,
    sectionGroups,
    selectedSections,
    setSelectedHandler,
    venueMapSvg,
  ]);

  const onInitializeContent = useCallback(
    (element: HTMLDivElement | null) => {
      if (!element || !!venueMapSvg) return false;
      localRef.current = element;
      const svgElement = element.getElementsByTagName('svg')[0];

      // The initialize part will clean up the unused groups and set the row level colors
      const sectionGroups = initializeGroups(
        svgElement,
        getColor,
        sectionIdsMissingRowOrder,
        isHeatMap
      );
      setSectionGroups(sectionGroups);

      // Initialize the SVG pan zoom with the cleaned up svgElement
      const svg = initializeSvg(svgElement);
      setVenueMapSvg(svg);
      return true;
    },
    [venueMapSvg, getColor, sectionIdsMissingRowOrder, isHeatMap, initializeSvg]
  );

  // getColor will be refresh upon section selections, this is to refresh the row colors
  useEffect(() => {
    if (localRef.current && venueMapSvg && !isEmpty(sectionGroups)) {
      resetRowScoreColors(sectionGroups, getColor);
    }
  }, [getColor, sectionGroups, venueMapSvg]);

  const onZoom = useCallback(
    (zoomIn = true) => {
      if (!venueMapSvg) return;
      zoomIn ? venueMapSvg.zoomIn() : venueMapSvg.zoomOut();
      setZoomFlag(true);
    },
    [venueMapSvg]
  );

  const onReset = useCallback(() => {
    if (!venueMapSvg) return;
    venueMapSvg.resize(); // update SVG cached size and controls positions
    venueMapSvg.fit();
    venueMapSvg.center();
    setZoomFlag(false);
    setSelectedSections?.([]);
  }, [setSelectedSections, venueMapSvg]);

  return {
    onInitializeContent,
    onZoom,
    onReset,
    zoomFlag,
    hoveredContent,
  };
};

const initializeGroups = (
  svgElement: SVGSVGElement,
  getColor?: GetColor,
  sectionIdsMissingRowOrder: number[] = [],
  isHeatMap = false
) => {
  const sectionGroups = reworkGroups(
    svgElement,
    sectionIdsMissingRowOrder,
    isHeatMap
  );

  if (!sectionGroups) {
    return [] as SVGElement[];
  }
  // Set row level score colors
  resetRowScoreColors(sectionGroups, getColor);
  return sectionGroups;
};

const resetRowScoreColors = (
  sectionGroups: SVGElement[],
  getColor?: GetColor
) => {
  for (const sectionGroup of sectionGroups) {
    // Set row level score color
    const sectionRows = [
      ...sectionGroup.querySelectorAll('g[id="row"] path'),
    ] as HTMLElement[];

    // Default to section level color if there is not rows in the section
    if (!isEmpty(sectionRows)) {
      sectionRows.forEach((path) => {
        const rowId = parseInt(path.getAttribute('eid') ?? '0');

        const { fill, stroke } = getColor?.({
          sectionId: -1,
          rowId,
          ticketClassId: -1,
          isSelected: false,
          useRowColor: true,
        }) ?? { fill: '', stroke: '' };

        path.setAttribute('fill', fill);
        path.setAttribute('stroke', stroke);
      });
    } else {
      // Set section level color
      const sectionId = parseInt(getSectionId(sectionGroup) ?? '0');
      const ticketClassId = parseInt(getTicketClassId(sectionGroup) ?? '0');

      const { fill, stroke } = getColor?.({
        sectionId,
        ticketClassId,
        isSelected: false,
      }) ?? { fill: '', stroke: '' };

      const sectionPath = sectionGroup.querySelector(
        'path[t="s"]'
      ) as SVGElement;
      sectionPath?.setAttribute('fill', fill);
      sectionPath?.setAttribute('stroke', stroke);
    }
  }
};

/**
 * Rework the SVG groups to limit the group number by grouping elements by section.
 * In each of the section group we want to ensure the row paths are at the bottom for
 * only displaying the row colors.
 *
 * We rebuild the group so that row paths comes first and then the section path and label.
 * This way section label will be on top of the section path, and row paths will be at the bottom.
 *
 * This will significantly reduce the number of groups in the SVG and improve the performance.
 *
 * @param svg the svg file
 * @returns reordered svg file
 */
const reworkGroups = (
  svg: SVGSVGElement,
  sectionIdsMissingRowOrder: number[],
  isHeatMap = false
) => {
  const rowPaths = svg.getElementById('rowpaths') as SVGElement;
  const sectionPaths = svg.getElementById('sectionpaths') as SVGElement;
  const sectionLables = svg.getElementById('sectionlabels') as SVGElement;
  const ticketClassPaths = svg.getElementById('ticketclasspaths') as SVGElement;
  const ticketClassLables = svg.getElementById(
    'ticketclasslabels'
  ) as SVGElement;
  const rowLablePaths = svg.getElementById('rowlabels') as SVGElement;

  // Required paths groups for V3 VenueMap SVG
  if (!rowPaths || !sectionPaths || !sectionLables) {
    return [];
  }

  // Map from section id to row paths
  const sectionToRows = (
    [...rowPaths.querySelectorAll('g')] as SVGElement[]
  ).reduce(
    (map, element) => {
      const sectionId = getSectionId(element);
      const ticketclassid = getTicketClassId(element);
      const sectionKey = toSectionKey(sectionId, ticketclassid);
      const rowPath = element.querySelector('path') as SVGElement;
      (map[sectionKey] = map[sectionKey] || ([] as HTMLElement[])).push(
        rowPath
      );
      return map;
    },
    {} as Record<string, SVGElement[]>
  );

  // Map from section id to section label
  const sectionToLable = (
    [...sectionLables.querySelectorAll('g')] as SVGElement[]
  ).reduce(
    (map, element) => {
      const sectionId = getSectionId(element);
      const ticketclassid = getTicketClassId(element);
      const sectionKey = toSectionKey(sectionId, ticketclassid);
      const textElement = element.querySelector('text') as SVGElement;
      if (
        sectionIdsMissingRowOrder.includes(parseInt(sectionId ?? '0')) &&
        isHeatMap
      ) {
        if (
          textElement.textContent &&
          textElement.textContent.slice(-1) !== '!'
        ) {
          textElement.textContent = `${textElement.textContent}!`;
        }
      }
      map[sectionKey] = textElement;
      return map;
    },
    {} as Record<string, SVGElement>
  );

  // Main logic to rework the section groups
  ([...sectionPaths.querySelectorAll('g')] as SVGElement[]).forEach(
    (section) => {
      const sectionId = getSectionId(section);
      const ticketClassId = getTicketClassId(section);
      const sectionKey = toSectionKey(sectionId, ticketClassId);
      // The original section path
      const sectionPath = section.querySelector('path') as SVGElement;
      if (!sectionPath || !sectionId) return;
      section.removeChild(sectionPath);

      const rowGroup = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'g'
      );
      rowGroup.setAttribute('id', `row`);
      for (const row of sectionToRows[sectionKey] ?? []) {
        row.setAttribute('pointer-events', 'none');
        row.setAttribute('fill-opacity', '1');
        rowGroup.appendChild(row);
      }
      section.appendChild(rowGroup);
      section.appendChild(sectionPath);

      if (sectionToLable[sectionKey]) {
        section.appendChild(sectionToLable[sectionKey]);
      }
    }
  );

  // Clean up all other groups and leave only the section paths group
  const root = sectionPaths.parentElement as HTMLElement;
  rowPaths && root.removeChild(rowPaths);
  sectionLables && root.removeChild(sectionLables);
  ticketClassPaths && root.removeChild(ticketClassPaths);
  ticketClassLables && root.removeChild(ticketClassLables);
  rowLablePaths && root.removeChild(rowLablePaths);

  return [...sectionPaths.querySelectorAll('g')] as SVGElement[];
};

// We need both section and ticket class id to uniquely identify a section sprite in svg file
const toSectionKey = (sectionId: string | null, ticketClassId: string | null) =>
  `s${sectionId || ''}-t${ticketClassId || ''}`;

// Get section id from the element, traverse up the parent elements if not found
const getSectionId = (element: SVGElement): string | null => {
  if (!element?.tagName) {
    return null;
  }
  if (element.parentElement && element.tagName !== 'g') {
    return getSectionId(element.parentElement as unknown as SVGElement);
  }
  return (
    element.getAttribute('sectionId') ??
    element.getAttribute('sectionid') ??
    element.getAttribute('section-id')
  );
};

// Get ticket class id from the element, traverse up the parent elements if not found
const getTicketClassId = (element: SVGElement): string | null => {
  if (!element?.tagName) {
    return null;
  }
  if (element.parentElement && element.tagName !== 'g') {
    return getTicketClassId(element.parentElement as unknown as SVGElement);
  }
  return (
    element.getAttribute('ticketClassId') ??
    element.getAttribute('ticketclassid') ??
    element.getAttribute('ticket-class-id')
  );
};
