import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { GET_VENDOR_QUERY_KEY } from 'src/hooks/api/useGetVendor';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  PurchaseClient,
  PurchaseVendor,
} from 'src/WebApiController';

interface UseAddVendorProps {
  onVendorSaved: (vendor: PurchaseVendor) => void;
}

export const useAddVendor = (props?: UseAddVendorProps) => {
  const onVendorSaved = props?.onVendorSaved;
  const [currentVendor, setCurrentVendor] = useState<PurchaseVendor>();
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const vendorDialog = useBasicDialog();
  const { launchDialog, closeDialog } = vendorDialog;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const onVendorCancel = useCallback(() => {
    closeDialog();
    setCurrentVendor(undefined);
  }, [closeDialog]);

  const onSaveVendor = useCallback(
    async (vendor: PurchaseVendor) => {
      setIsLoading(true);
      await tryInvokeApi(
        async () => {
          const newId = await new PurchaseClient(
            activeAccountWebClientConfig
          ).mergeVendor(vendor);

          if (newId) {
            await onVendorSaved?.(vendor);
            queryClient.invalidateQueries({
              queryKey: [
                GET_VENDOR_QUERY_KEY,
                activeAccountWebClientConfig.activeAccountId,
                newId,
              ],
            });
          } else {
            // This should never happen
            // Normal errors would have fallen to the error catch
            showErrorDialog(
              'PurchaseClient.mergeVendor',
              {
                message: 'Unable to create or save vendor ' + vendor.id,
                status: 500,
              } as ApiException,
              {
                trackErrorData: vendor,
              }
            );
          }
        },
        (error) => {
          showErrorDialog('PurchaseClient.mergeVendor', error, {
            trackErrorData: vendor,
          });
        },
        () => {
          setIsLoading(false);
          onVendorCancel();
        }
      );
    },
    [
      activeAccountWebClientConfig,
      onVendorSaved,
      queryClient,
      showErrorDialog,
      onVendorCancel,
    ]
  );

  const onEditVendor = useCallback(
    (vendor: PurchaseVendor) => {
      setCurrentVendor(vendor);
      launchDialog();
    },
    [launchDialog]
  );

  return {
    currentVendor,
    onEditVendor,
    onSaveVendor,
    onVendorCancel,
    vendorDialog,
    isLoading,
  };
};
