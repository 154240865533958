import * as RadixTooltip from '@radix-ui/react-tooltip';
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import { mergeProps, RADIX_COLLISION_PADDING } from 'src/core/utils';

import * as styles from './Tooltip.css';

export const Provider = RadixTooltip.Provider;

export const Root = RadixTooltip.Root;

export const Trigger = RadixTooltip.Trigger;

export const Content = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof RadixTooltip.Content>
>(function Content({ children, ...props }, ref) {
  return (
    <RadixTooltip.Portal>
      <RadixTooltip.Content
        ref={ref}
        sideOffset={4}
        collisionPadding={RADIX_COLLISION_PADDING}
        {...mergeProps({ className: styles.tooltipContent }, props)}
      >
        {children}
        <RadixTooltip.Arrow className={styles.tooltipArrow} />
      </RadixTooltip.Content>
    </RadixTooltip.Portal>
  );
});

export const Tooltip = ({
  triggerContent,
  children,
  delayDuration = 300,
  align = 'start',
  ...rest
}: RadixTooltip.TooltipTriggerProps & {
  triggerContent: ReactNode;
  delayDuration?: RadixTooltip.TooltipProps['delayDuration'];
  onOpenChange?: RadixTooltip.TooltipProps['onOpenChange'];
  align?: RadixTooltip.TooltipContentProps['align'];
}) => {
  return (
    <Provider>
      <Root delayDuration={delayDuration}>
        <Trigger asChild {...rest}>
          {triggerContent}
        </Trigger>
        <Content align={align} className={styles.tooltipContent}>
          {children}
        </Content>
      </Root>
    </Provider>
  );
};
