import { debounce } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import {
  ItemIdWithRowVersion,
  useCatalogDataContext,
} from 'src/contexts/CatalogDataContext';
import { useListingNotificationContext } from 'src/contexts/ListingNotificationContext';
import { entityTypeNumberToEntityTypeEnum } from 'src/utils/activityLogUtils';
import { ActionOutboxEntityType, Listing } from 'src/WebApiController';

export const useRefreshListingOnNotification = () => {
  const { hubConnection } = useListingNotificationContext();

  const { refreshEventForItems } = useCatalogDataContext();
  const { setActivePosEntity, posEntityId, posEntity } =
    useActivePosEntityContext<Listing>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetActivePosEntity = useCallback(
    debounce((posEntityId: number) => {
      setActivePosEntity(posEntityId, undefined, true);
    }, 3000),
    []
  );

  const [_, setItemsIdsToRefresh] = useState<ItemIdWithRowVersion[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetItemsIdsToRefresh = useCallback(
    debounce((ids: ItemIdWithRowVersion[]) => {
      refreshEventForItems(ids, ActionOutboxEntityType.Listing);
      setItemsIdsToRefresh((prev) => []);
    }, 5000),
    [refreshEventForItems]
  );

  useEffect(() => {
    const messageHandler = async (message: string) => {
      try {
        const messageList = JSON.parse(message);
        for (const m of messageList) {
          console.debug(
            'useRefreshListingOnNotification - got new notification',
            m,
            hubConnection
          );

          // Refresh relevant listing
          if (m.EntityId === posEntityId && posEntityId) {
            if (
              posEntity?.rowVer &&
              m.RowVersion &&
              m.RowVersion > posEntity?.rowVer
            ) {
              debouncedSetActivePosEntity(posEntityId);
            }
          }
          const entityType = entityTypeNumberToEntityTypeEnum(m.EntityType);
          if (
            entityType === ActionOutboxEntityType.Listing &&
            m.EntityId &&
            m.RowVersion
          ) {
            setItemsIdsToRefresh((prev) => {
              const existingRowVer = prev.find((i) => i.id === m.EntityId)
                ?.rowVer;
              if (existingRowVer && m.RowVersion <= existingRowVer) {
                return prev;
              }

              const newIds: ItemIdWithRowVersion[] = [
                ...prev.filter((i) => i.id !== m.EntityId),
                { id: m.EntityId, rowVer: m.RowVersion },
              ];
              debouncedSetItemsIdsToRefresh(newIds);
              return newIds;
            });
          }
        }
      } catch (e) {
        console.error(
          'useRefreshListingOnNotification - malformed SignalR Message: ' +
            message
        );
      }
    };

    if (hubConnection) {
      hubConnection.on('new', messageHandler);
    }

    return () => {
      if (hubConnection) {
        hubConnection.off('new', messageHandler);
      }
    };
  }, [
    debouncedSetActivePosEntity,
    debouncedSetItemsIdsToRefresh,
    hubConnection,
    posEntity?.rowVer,
    posEntityId,
  ]);
};
