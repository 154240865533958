import { useFilterQueryState } from 'src/components/MainFilterBar/useFilterQueryState';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import {
  ActionOutboxEntityType,
  PurchaseOrderQuery,
} from 'src/WebApiController';

export const usePurchaseTileBulkActionsState = (
  groupId?: string,
  isEventView?: boolean
) => {
  const { getSelection, isAllSelected } = useMultiSelectionContext();

  const groupSelection = getSelection(groupId);

  const { filterQueryWithEventIds } = useFilterQueryState<PurchaseOrderQuery>(
    ActionOutboxEntityType.Purchase,
    groupId ? [groupId] : undefined,
    isEventView
  );

  return {
    allPurchasesSelected: isAllSelected,
    groupSelection,
    filterQueryWithEventIds,
  };
};
