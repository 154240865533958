import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const PerformerVenueFilterBarDiv = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  gap: ${() => vars.spacing.sm};
  padding: ${() => `${vars.spacing.lg} ${vars.spacing.xl}`};
  justify-content: ${(props) =>
    props.isCollapsed ? 'flex-end' : 'space-between'};
  align-items: center;
  border-bottom: 0;
  position: relative;
`;

export const SortButtonContainer = styled.div`
  padding-inline-start: ${() => vars.spacing.sm};

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `padding-inline-start: ${vars.spacing.lg};`
    )}`}
`;
