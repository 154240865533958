import clsx from 'clsx';
import React, { ComponentProps } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { darkModeRoot } from 'src/app/App.css';
import { ErrorBoundaryContextProvider } from 'src/contexts/ErrorBoundaryContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { ActionOutboxEntityType } from 'src/WebApiController';

import * as styles from './Modal.css';
import { ModalBody, ModalFooter, StyledModal } from './Modal.styled';
import { ModalHeader } from './ModalHeader';

export type FullControlModalProps = Omit<
  ComponentProps<typeof RSModal>,
  'Body' | 'Header' | 'Footer' | 'ContentWrapper'
> & { children: React.ReactNode };

export type ModalProps =
  | ({
      Body: React.ComponentType | JSX.Element;
      Header?: React.ComponentType | JSX.Element;
      Footer?: React.ComponentType | JSX.Element;
      ContentWrapper?: React.FC;
      size?:
        | 'sm'
        | 'lg'
        | 'xl'
        | 'fullscreen'
        | 'slide-in'
        | 'slide-in-lg'
        | 'slide-left'
        | 'slide-left-sm';
      /**
       * Whether to dismiss the dialog when clicking outside of its boundary
       * For message box, setting this to true is the norm.
       * For wizards, we do not want user to accidentally dismiss the entire dialog by clicking elsewhere.
       * Therefore, set this to true with care.
       */
      clickOutsideToClose?: boolean;
      /* When set, the url is appended with a query param <deepLinkKey>=<deepLinkValue> to allow a deep link visible on the browser's address bar */
      deepLinkKey?: string;
      deepLinkValue?: string | number;
      trackViewEntityType?: ActionOutboxEntityType;
      onCloseOverride?: () => void;
      onExit?: () => void;
    } & ComponentProps<typeof RSModal>)
  | FullControlModalProps;

export const Modal = ({
  Header,
  Body,
  Footer,
  isOpen,
  onClose,
  children,
  clickOutsideToClose,
  backdrop,
  scrollable = true,
  size = 'lg',
  className,
  ...rest
}: ModalProps) => {
  const hasChildren = React.Children.count(children) > 0;
  const commonModalProps = {
    isOpen: isOpen,
    toggle: onClose,
    fullscreen: 'sm',
  };
  const { isDarkMode } = useSiteTheme();

  return (
    <ErrorBoundaryContextProvider>
      <StyledModal
        {...commonModalProps}
        backdrop={clickOutsideToClose ? backdrop : 'static'}
        backdropClassName={styles.backdrop}
        size={size}
        scrollable={scrollable}
        keyboard
        className={clsx(className, {
          [darkModeRoot]: isDarkMode,
        })}
        contentClassName={styles.modal}
        {...rest}
      >
        {hasChildren ? (
          children
        ) : (
          <>
            {Header ? (
              <ModalHeader onClose={onClose}>
                {asJsxElement(Header)}
              </ModalHeader>
            ) : null}
            <ModalBody>{asJsxElement(Body)}</ModalBody>
            {Footer ? <ModalFooter>{asJsxElement(Footer)}</ModalFooter> : null}
          </>
        )}
      </StyledModal>
    </ErrorBoundaryContextProvider>
  );
};

const asJsxElement = (Element: React.ComponentType | JSX.Element) =>
  typeof Element === 'function' ? <Element /> : Element;
