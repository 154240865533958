import { useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { QuickFilter } from 'src/hooks/useQuickFilters';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DateRangePresetName,
  getDateRangePresets,
} from 'src/utils/dateTimeUtils';
import {
  DateTimeRangeWithRelative,
  SaleQuery,
  UserSetting,
} from 'src/WebApiController';

export const useDefaultSaleFilters = ({
  saleQuery,
  initialQuery,
}: {
  saleQuery: SaleQuery;
  initialQuery: SaleQuery;
}) => {
  const defaultQuickFilters = useMemo(() => {
    const [last30DaysPreset] = getDateRangePresets([
      DateRangePresetName.Last30Days,
    ]);
    const filters = [
      {
        id: 'recentlySold',
        name: <Content id={ContentId.RecentlySold} />,
        data: {
          ...initialQuery,
          eventTimeFrameFilter: null,
          saleDates: {
            start: last30DaysPreset.range.start.toISOString(),
            end: last30DaysPreset.range.end.toISOString(),
          } as DateTimeRangeWithRelative,
        },
      },
    ];

    // Default filters aren't supposed to change view mode
    filters.forEach((f: QuickFilter<UserSetting.QuickFiltersCustomSales>) => {
      f.data.viewMode = saleQuery.viewMode;
    });
    return filters;
  }, [initialQuery, saleQuery.viewMode]);

  return defaultQuickFilters;
};
