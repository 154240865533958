import { formatInTimeZone } from 'date-fns-tz';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import { ListingPriceInput } from 'src/components/Input/ListingPriceInput';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { Switch } from 'src/core/interim/Switch';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { SimpleTable } from 'src/core/ui';
import {
  Alert,
  AlertWithSuppressionDialog,
  useManualPriceDisableAutoPricingDialog,
} from 'src/dialogs/AlertWithSuppressionDialog';
import { useManualPriceAdjustFloorOrCeilingDialog } from 'src/dialogs/AlertWithSuppressionDialog/useManualPriceAdjustFloorOrCeilingDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserCanSetPrice } from 'src/hooks/useUserHasListingPermissions';
import {
  Detail,
  DetailGroup,
  DetailSection,
  SectionContent,
} from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { isDatePassedHours } from 'src/utils/dateTimeUtils';
import {
  compareMarketplace,
  getIsInternationalEvent,
} from 'src/utils/eventWithDataUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ListingClient,
  ListingDetails,
  ListingDetailsPricingSectionUpdates,
  ListingMetrics,
  ListingStatus,
  Marketplace,
  Permission,
} from 'src/WebApiController';

import * as styles from './EconomicsSection.css';

type EconomicsSectionProps = {
  listing?: ListingDetails | null;
  disabled?: boolean;
};

export const EconomicsSection = ({
  listing,
  disabled,
}: EconomicsSectionProps) => {
  const { timeZone } = useSiteTimezoneContext();
  const isListed =
    listing?.status === ListingStatus.Listed ||
    listing?.status === ListingStatus.ListingPending;

  const { activeAccountWebClientConfig, loginContext } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = useMemo(
    () =>
      getUiCurrency(
        listing?.currency ??
          loginContext?.user?.activeAccount?.currencyCode ??
          'USD'
      ),
    [
      getUiCurrency,
      listing?.currency,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );
  const canChangePricer = useUserHasAnyOfPermissions(
    Permission.Inventory_SetPrice
  );
  const { refreshMetrics } = useCatalogMetricsContext<ListingMetrics>();

  const { watch, setValue, register, getValues, formState } =
    useFormContext<ListingDetailsPricingSectionUpdates>();
  const defaultPlaceholder = useContent(ContentId.Default);

  const listPrice = watch('listPrice');
  const marketplacePriceUpdates = watch('marketplacePriceUpdates');
  const autoPricingEnabled = watch('autoPricingEnabled');
  const netProceedsFloor = watch('netProceedsFloor');
  const netProceedsCeiling = watch('netProceedsCeiling');
  const pricerSellerUserId = watch('pricerSellerUserId');
  const canSetPrice = useUserCanSetPrice(listing, false);

  const onUnbroadcast = useCallback(() => {
    if (!listing) {
      return;
    }
    tryInvokeApi(
      async () => {
        const client = new ListingClient(activeAccountWebClientConfig);

        const result = await client.deleteMarketplaceListings(listing.id, []);
        if (result) {
          refreshMetrics?.();

          // Refresh the active data so the SaleDetail dialog and table will have the new content
          await setActivePosEntity(listing.id, listing.idOnMkp, true);
        }
      },
      (error) => {
        showErrorDialog('ListingClient.deleteMarketplaceListings', error, {
          trackErrorData: {
            listingId: listing.id,
            marketplaceListingID: listing.idOnMkp,
          },
        });
      }
    );
  }, [
    activeAccountWebClientConfig,
    listing,
    refreshMetrics,
    setActivePosEntity,
    showErrorDialog,
  ]);

  const thirdPartyMPs = (
    listing?.mkpListings?.map(
      ({ mkp, markup, sellerAccountDefaultMarkup, sellerFee }) => ({
        mkp,
        markup,
        sellerAccountDefaultMarkup:
          sellerAccountDefaultMarkup != null
            ? roundToPrecision(sellerAccountDefaultMarkup * 100, 2)
            : null,
        sellerFee,
      })
    ) ??
    loginContext?.user?.activeAccount?.marketplaceSettings?.map(
      ({ mkp, markup, sellerFee }) => ({
        mkp,
        markup,
        sellerAccountDefaultMarkup: markup,
        sellerFee,
      })
    ) ??
    []
  )
    .filter((ml) => ml.mkp)
    .reduce(
      (r, m) => {
        r[m.mkp!] = m;
        return r;
      },
      {} as Record<
        Marketplace,
        {
          mkp: Marketplace;
          markup: number | null;
          sellerAccountDefaultMarkup: number | null;
          sellerFee: number | null;
        }
      >
    );
  const stubHubMarketplace = thirdPartyMPs[Marketplace.StubHub];

  const isDisabled = useMemo(() => {
    if (disabled || !canSetPrice) return true;
    // Bulk edit mode should not disable the section
    if (!listing) return false;
    if (listing.isDeleted) return true;

    return false;
  }, [disabled, canSetPrice, listing]);

  const { event, venue } = useGetEventFullInfo(null);

  const isInternationalEvent = getIsInternationalEvent(venue?.country?.code);

  const warningDialog = useBasicDialog();

  const manualPriceDisableAutoPricingProps =
    useManualPriceDisableAutoPricingDialog(() => {
      setValue('autoPricingEnabled', false);
    });

  const marketplacePrices = marketplacePriceUpdates
    .filter((m) => m.priceByMarketplace)
    .map((m) => m.listPrice);
  const allListPrices = useMemo(() => {
    const p = [listPrice, ...marketplacePrices]
      .filter((n) => n != null)
      .map((n) => n!);

    return p;
  }, [listPrice, marketplacePrices]);

  const smallestListPrice = useMemo(
    () => Math.min(...allListPrices.filter((l) => l > 0)),
    [allListPrices]
  );
  const largestListPrice = useMemo(
    () => Math.max(...allListPrices.filter((l) => l > 0)),
    [allListPrices]
  );
  const manualPriceAdjustFloorProps = useManualPriceAdjustFloorOrCeilingDialog(
    smallestListPrice,
    netProceedsFloor,
    netProceedsCeiling,
    () => {
      if (smallestListPrice < (netProceedsFloor ?? Number.MIN_VALUE)) {
        setValue('netProceedsFloor', smallestListPrice);
      }
    },
    () => {
      if (
        listPrice != null &&
        listPrice > 0 &&
        listPrice < (netProceedsFloor ?? Number.MIN_VALUE)
      ) {
        setValue('listPrice', netProceedsFloor);
      }

      marketplacePriceUpdates.forEach((m, i) => {
        if (m.priceByMarketplace && m.listPrice != null && m.listPrice > 0) {
          if (m.listPrice < (netProceedsFloor ?? 0)) {
            setValue(
              `marketplacePriceUpdates.${i}.listPrice`,
              netProceedsFloor
            );
          }
        }
      });
    }
  );

  const manualPriceAdjustCeilingProps =
    useManualPriceAdjustFloorOrCeilingDialog(
      largestListPrice,
      netProceedsFloor,
      netProceedsCeiling,
      () => {
        if (largestListPrice > (netProceedsCeiling ?? Number.MAX_VALUE)) {
          setValue('netProceedsCeiling', largestListPrice);
        }
      },
      () => {
        if (
          listPrice != null &&
          listPrice > 0 &&
          listPrice > (netProceedsCeiling ?? Number.MAX_VALUE)
        ) {
          setValue('listPrice', netProceedsCeiling);
        }

        marketplacePriceUpdates.forEach((m, i) => {
          if (m.priceByMarketplace && m.listPrice != null && m.listPrice > 0) {
            if (m.listPrice > (netProceedsCeiling ?? Number.MAX_VALUE)) {
              setValue(
                `marketplacePriceUpdates.${i}.listPrice`,
                netProceedsCeiling
              );
            }
          }
        });
      }
    );

  const [alerts, setAlerts] = useState<Alert[]>([]);
  const rowsCount = marketplacePriceUpdates.length + 1;
  const [inputRefsArray] = useState(() =>
    Array.from({ length: rowsCount }, () => createRef<HTMLInputElement>())
  );
  const [focusedInputIndex, setFocusedInputIndex] = useState(0);

  const getFloorAlerts = useCallback((): Alert[] => {
    if (smallestListPrice < (netProceedsFloor ?? Number.MIN_VALUE)) {
      if (isDatePassedHours(manualPriceAdjustFloorProps.lastTimeStamp, 1)) {
        return [manualPriceAdjustFloorProps];
      }
    }
    return [];
  }, [manualPriceAdjustFloorProps, netProceedsFloor, smallestListPrice]);

  const getCeilingAlerts = useCallback((): Alert[] => {
    if (largestListPrice > (netProceedsCeiling ?? Number.MAX_VALUE)) {
      if (isDatePassedHours(manualPriceAdjustCeilingProps.lastTimeStamp, 1)) {
        return [manualPriceAdjustCeilingProps];
      }
    }
    return [];
  }, [largestListPrice, manualPriceAdjustCeilingProps, netProceedsCeiling]);
  const getAutoPriceAlerts = useCallback((): Alert[] => {
    if (
      autoPricingEnabled &&
      isDatePassedHours(manualPriceDisableAutoPricingProps.lastTimeStamp, 1)
    ) {
      return [manualPriceDisableAutoPricingProps];
    }
    return [];
  }, [autoPricingEnabled, manualPriceDisableAutoPricingProps]);

  const onPriceChanged = useCallback(() => {
    if (listPrice === formState?.defaultValues?.listPrice) {
      return;
    }
    const newAlerts = [
      ...getFloorAlerts(),
      ...getCeilingAlerts(),
      ...getAutoPriceAlerts(),
    ];

    if (newAlerts.length) {
      setAlerts(newAlerts);
      warningDialog.launchDialog();
    }
  }, [
    listPrice,
    formState?.defaultValues?.listPrice,
    getFloorAlerts,
    getCeilingAlerts,
    getAutoPriceAlerts,
    warningDialog,
  ]);

  const onPriceEdit = useCallback(
    (newPrice: number, priceByMarketplaceEnabled: boolean, idx: number) => {
      if (listPrice) {
        const newMarkup = (newPrice - listPrice) / listPrice;

        if (priceByMarketplaceEnabled) {
          setValue(
            `marketplacePriceUpdates.${idx}.markup`,
            roundToPrecision(newMarkup * 100, 2)
          );
          onPriceChanged();
        }
      }
    },
    [listPrice, onPriceChanged, setValue]
  );

  const handleEnter = (index: number) => {
    let nextIndex = 0;
    for (let i = index + 1; i < rowsCount; i++) {
      // find next enabled
      if (marketplacePriceUpdates[i - 1]?.priceByMarketplace) {
        nextIndex = i;
        break;
      }
    }
    const nextInput = inputRefsArray?.[nextIndex]?.current;
    if (nextInput) {
      nextInput.focus();
      setFocusedInputIndex(() => nextIndex);
    }
    onPriceChanged();
  };

  useEffect(() => {
    if (isDisabled) return;
    const nextInput = inputRefsArray?.[focusedInputIndex]?.current;
    if (nextInput) {
      setTimeout(() => {
        nextInput.focus();
        nextInput.select();
      }, 100);
    }
  }, [isDisabled, focusedInputIndex, inputRefsArray]);

  return (
    <DetailSection name={<Content id={ContentId.Economics} />}>
      <SectionContent key={'StubHub'} numOfColumns={3}>
        <SimpleTable.Table>
          <SimpleTable.Thead>
            <SimpleTable.Tr>
              <SimpleTable.Th
                className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
              >
                <Content id={ContentId.Merchant} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
              >
                <Content id={ContentId.Proceeds} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
              >
                <Content id={ContentId.WebsitePrice} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
              >
                <Content id={ContentId.Markup} />
              </SimpleTable.Th>
              <SimpleTable.Th
                className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
              >
                <Content id={ContentId.PriceByMarketplace} />
              </SimpleTable.Th>
            </SimpleTable.Tr>
          </SimpleTable.Thead>
          <SimpleTable.Tbody>
            <SimpleTable.Tr className={styles.broadcastRow} key="StubHub">
              <SimpleTable.Td className={styles.broadcastCell}>
                <MarketplaceLogo
                  marketplace={stubHubMarketplace.mkp}
                  event={event!}
                  isViagogo={false}
                />
              </SimpleTable.Td>
              <SimpleTable.Td className={styles.broadcastCell}>
                <ListingPriceInput
                  ref={inputRefsArray[0]}
                  isBulkEdit={false}
                  currencyCode={uiCurrency.code}
                  disabled={isDisabled}
                  field="listPrice"
                  otherField="allInPrice"
                  fieldToOtherRatio={1 - (stubHubMarketplace?.sellerFee ?? 0)}
                  onUnbroadcast={isListed ? onUnbroadcast : undefined}
                  onChange={(_) => onPriceChanged()}
                  onEnter={() => handleEnter(0)}
                />
              </SimpleTable.Td>
              <SimpleTable.Td className={styles.broadcastCell}>
                <ListingPriceInput
                  isBulkEdit={false}
                  currencyCode={uiCurrency.code}
                  disabled={isDisabled}
                  isAllInPrice
                  field="allInPrice"
                  otherField="listPrice"
                  fieldToOtherRatio={
                    1 / (1 - (stubHubMarketplace?.sellerFee ?? 0))
                  }
                  onUnbroadcast={isListed ? onUnbroadcast : undefined}
                  onChange={(_) => onPriceChanged()}
                  onEnter={() => handleEnter(0)}
                />
              </SimpleTable.Td>
            </SimpleTable.Tr>
            {!isInternationalEvent &&
              // We only show other marketplaces when the Event is US
              marketplacePriceUpdates
                .filter((mp) => mp.marketplace !== Marketplace.StubHub)
                .sort((a, b) =>
                  compareMarketplace(a.marketplace, b.marketplace)
                )
                .map((mp, i) => {
                  const ml = thirdPartyMPs[mp.marketplace!];

                  const priceByMarketplaceEnabled = getValues(
                    `marketplacePriceUpdates.${i}.priceByMarketplace`
                  );

                  return (
                    <SimpleTable.Tr
                      className={styles.broadcastRow}
                      key={ml.mkp}
                    >
                      <SimpleTable.Td className={styles.broadcastCell}>
                        <MarketplaceLogo
                          marketplace={ml.mkp}
                          event={event!}
                          isViagogo={false}
                        />
                      </SimpleTable.Td>
                      <SimpleTable.Td className={styles.broadcastCell}>
                        <ListingPriceInput
                          ref={inputRefsArray[i + 1]}
                          isBulkEdit={false}
                          currencyCode={uiCurrency.code}
                          disabled={!mp.priceByMarketplace || isDisabled}
                          field={`marketplacePriceUpdates.${i}.listPrice`}
                          otherField={`marketplacePriceUpdates.${i}.allInPrice`}
                          fieldToOtherRatio={1 - (ml.sellerFee ?? 0)}
                          onUnbroadcast={isListed ? onUnbroadcast : undefined}
                          onEnter={() => handleEnter(i + 1)}
                          onChange={(val) => {
                            onPriceEdit(val, priceByMarketplaceEnabled, i);
                          }}
                        />
                      </SimpleTable.Td>
                      <SimpleTable.Td className={styles.broadcastCell}>
                        <ListingPriceInput
                          isBulkEdit={false}
                          currencyCode={uiCurrency.code}
                          disabled={!mp.priceByMarketplace || isDisabled}
                          isAllInPrice
                          field={`marketplacePriceUpdates.${i}.allInPrice`}
                          otherField={`marketplacePriceUpdates.${i}.listPrice`}
                          fieldToOtherRatio={1 / (1 - (ml.sellerFee ?? 0))}
                          onUnbroadcast={isListed ? onUnbroadcast : undefined}
                          onChange={(val) => {
                            onPriceEdit(val, priceByMarketplaceEnabled, i);
                          }}
                          onEnter={() => handleEnter(i + 1)}
                        />
                      </SimpleTable.Td>
                      <SimpleTable.Td>
                        <PosFormField>
                          <PosTextField
                            disabled={
                              isDisabled || Boolean(priceByMarketplaceEnabled)
                            }
                            type="number"
                            placeholder={`${ml.sellerAccountDefaultMarkup}`}
                            postfixDisplay="%"
                            {...register(`marketplacePriceUpdates.${i}.markup`)}
                          />
                        </PosFormField>
                      </SimpleTable.Td>

                      <SimpleTable.Td>
                        <Switch
                          disabled={isDisabled}
                          checked={mp.priceByMarketplace}
                          onChange={() => {
                            const newMode = !mp.priceByMarketplace;
                            setValue(
                              `marketplacePriceUpdates.${i}.priceByMarketplace`,
                              newMode
                            );
                            if (!newMode) {
                              // If this is false, set the value to listValue
                              const mpListPrice = roundToPrecision(
                                (listPrice ?? 0) * (1 + (ml.markup ?? 0)),
                                uiCurrency.dec
                              );
                              setValue(
                                `marketplacePriceUpdates.${i}.listPrice`,
                                mpListPrice
                              );
                            }

                            setValue(
                              `marketplacePriceUpdates.${i}.markup`,
                              null
                            );
                          }}
                        />
                      </SimpleTable.Td>
                    </SimpleTable.Tr>
                  );
                })}
          </SimpleTable.Tbody>
        </SimpleTable.Table>
        <>
          <DetailGroup style={{ gridColumn: '1' }}>
            <PosFormField
              label={<Content id={ContentId.PricedBy} />}
              style={{ width: '100%' }}
            >
              <SellerAccountEmployeeSelector
                style={{ width: '100%' }}
                value={pricerSellerUserId ?? ''}
                disabled={disabled || listing?.isDeleted || !canChangePricer}
                enableEmptySelection
                allowUnchanged={false}
                onChange={(newId) => {
                  if (newId !== pricerSellerUserId) {
                    setValue('pricerSellerUserId', newId);
                  }
                }}
                onClick={(e) => e.stopPropagation()}
              />
            </PosFormField>
          </DetailGroup>

          {listing && (
            <DetailGroup style={{ gridColumn: 1 }}>
              {listing.lastPrcUpdOn && (
                <Detail
                  label={<Content id={ContentId.LastPriceUpdatedDate} />}
                  detail={
                    <span>
                      {listing.lastPrcUpdOn
                        ? formatInTimeZone(
                            new Date(listing.lastPrcUpdOn),
                            timeZone,
                            'MMM d, yyyy',
                            {
                              locale: getLocaleFromLanguageOrCurrent(),
                            }
                          )
                        : ''}
                    </span>
                  }
                />
              )}
            </DetailGroup>
          )}
        </>
      </SectionContent>

      <AlertWithSuppressionDialog
        headerText={<Content id={ContentId.ManualPriceWarning} />}
        size="md"
        {...warningDialog.dialogProps}
        alerts={alerts}
        onOkay={() => warningDialog.closeDialog()}
        onCancel={() => warningDialog.closeDialog()}
      />
    </DetailSection>
  );
};
