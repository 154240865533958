import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const PaperTicketsIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1353 2.12558L10.8449 3.15528L12.9835 3.52492L11.5842 11.4457L10.396 4.68663L9.89439 1.91435L9.78878 1.3335L8.89109 1.46551L6.93729 1.80874L6.83168 2.09917C6.69967 2.44241 6.38284 2.70643 6.0132 2.78564C5.64356 2.83845 5.27393 2.70643 5.0099 2.416L4.82508 2.17838L4.48185 2.23119L2 2.70643L4.11221 14.6668L8.17822 13.954L12.0858 14.6404L14.198 2.68003L11.1353 2.12558ZM4.9571 13.4259L3.21452 3.55132L4.45545 3.3401C4.93069 3.76254 5.59076 3.94736 6.22442 3.84175C6.85809 3.73614 7.41254 3.3401 7.70297 2.78564L8.94389 2.57442L10.6865 12.449L4.9571 13.4259ZM8.42176 8.78642L9.2018 8.64883L9.56869 10.7289L8.78865 10.8665L8.42176 8.78642ZM5.06746 9.39361L5.58749 9.30194L5.95417 11.382L5.43414 11.4737L5.06746 9.39361ZM6.09847 9.20692L6.87851 9.06941L7.24519 11.1495L6.46515 11.287L6.09847 9.20692ZM7.38965 8.97416L7.90968 8.88249L8.27636 10.9626L7.75633 11.0543L7.38965 8.97416Z"
        fill="#87909A"
      />
    </SvgWrapper>
  );
};
