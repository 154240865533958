import {
  ExpandedState,
  getExpandedRowModel,
  Row,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shiftSelect } from 'src/components/Events/EventPage/EventPage.css';
import { useSingleTableShortcutSelection } from 'src/components/Events/EventPage/hooks/useSingleTableShortcutSelection';
import { ColumnResizingContextProvider } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { useClearShiftSelectionOnSortingChange } from 'src/contexts/MultiSelectionContext/useClearShiftSelectionOnSortingChange';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { useSyncCenterSettings } from 'src/hooks/api/useSyncCenterSetting';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { PurchaseOrderTableProps } from 'src/tables/PurchaseTable/PurchaseTable.types';
import { useGetPurchasesTableColumns } from 'src/tables/PurchaseTicketTable/useGetPurchasesTableColumns';
import { NoData, Table } from 'src/tables/Table';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import { MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isInFullPageEventView } from 'src/utils/deepLinkUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { SomethingWentWrong } from 'src/views';
import {
  Feature,
  PurchaseOrderTicketGroup,
  PurchaseViewMode,
  UserSetting,
} from 'src/WebApiController';

import { useSeatingColumnSorting } from '../common/hooks/useSeatingColumnSorting';
import { usePaginationSettings } from '../Table/usePaginationSettings';
import { usePurchaseTableData } from './usePurchaseTableData';
import { usePurchaseTableSelection } from './usePurchaseTableSelection';

const PAGE_SIZE = 10;

export function PurchaseOrderTable({
  groupId,
  purchaseOrders,
  purchaseCount,
  getDataFail,
  onMount,
  onUnmount,
  initState,
  disablePagination,
  useVirtuoso,
  viewMode = PurchaseViewMode.TileView,
  isItemsLoading,
  RowComponent,
  rowSupportsAccordion,
  virtuosoItemSize,
  columnVisibilityOverride,
  formatOptionOverrides,
  withOuterPadding = true,
  disableSelection,
  isSideTable,
  onItemSelectionsChanged,
}: PurchaseOrderTableProps) {
  const settings = useSyncCenterSettings();
  const { isAutoPoQualityControlled = false } = settings.data ?? {};

  const purchasesText = useContent(ContentId.Purchases);
  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );
  const hasFullPageEventViewSidePanelSelectionFilterFeature = useUserHasFeature(
    Feature.FullPageEventViewSidePanelSelectionFilter
  );

  const [expandedState, setExpandedState] = useState<ExpandedState>({});

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || [{ id: 'purchaseDate', desc: true }]
  );

  useClearShiftSelectionOnSortingChange({ sortingState: sorting });

  const sectionType = isSideTable
    ? SectionType.PurchaseSideTable
    : viewMode === PurchaseViewMode.EventTileView
    ? SectionType.PurchaseEvent
    : SectionType.Purchases;

  const seatingColumnSorting =
    useSeatingColumnSorting<PurchaseOrderTicketGroup>(
      PurchasesTableColumnId.Seating,
      sectionType
    );

  const { data, hasNoDataLoad } = usePurchaseTableData({
    purchaseOrders,
    purchaseCount,
    viewMode,
    isItemsLoading,
  });

  const { pagination, setPagination } = usePaginationSettings(
    data.length,
    -1,
    PAGE_SIZE,
    disablePagination,
    initState
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const columns = useGetPurchasesTableColumns({
    groupId,
    viewMode: viewMode,
    columnVisibilityOverride,
    isSideTable,
  });

  const { rowSelection, onRowSelectionChange, getRowId, showCheckbox } =
    usePurchaseTableSelection({
      data,
      groupId,
      viewMode,
      disableSelection,
      onItemSelectionsChanged,
    });

  const {
    lastSelectedRowRefIndex,
    allowShiftClickSelection,
    isShiftKeyPressed,
    isCtrlOrCmdKeyPressed,
    updateSelectionForRowAndSubRows,
  } = useSingleTableShortcutSelection<PurchaseOrderTicketGroup>({
    isSideTable,
  });

  const onRowShiftSelect = useCallback(
    (
      tableRows: Row<PurchaseOrderTicketGroup | null>[] | undefined,
      row: Row<PurchaseOrderTicketGroup | null>,
      index: number
    ) => {
      if (!tableRows || !allowShiftClickSelection || showCheckbox) {
        return;
      }
      const rowId = row.id.toString();
      const newIsSelected = !rowSelection[rowId];

      if (isShiftKeyPressed && lastSelectedRowRefIndex.current !== null) {
        const start = Math.min(lastSelectedRowRefIndex.current, index);
        const end = Math.max(lastSelectedRowRefIndex.current, index);

        const newRowSelection = { ...rowSelection };
        for (let i = start; i <= end; i++) {
          const row = tableRows[i];
          const rowId = getRowId(row.original, i);

          if (row) {
            updateSelectionForRowAndSubRows({
              row,
              rowId,
              newIsSelected,
              newRowSelection,
            });
          }
        }

        lastSelectedRowRefIndex.current = index;
        onRowSelectionChange(newRowSelection);
      } else if (isCtrlOrCmdKeyPressed) {
        const newRowSelection = { ...rowSelection };
        newRowSelection[rowId] = newIsSelected;
        updateSelectionForRowAndSubRows({
          row,
          rowId,
          newIsSelected, // Select the clicked row
          newRowSelection,
        });
        lastSelectedRowRefIndex.current = index;
        onRowSelectionChange(newRowSelection);
      } else {
        // No modifier key pressed, reset selection
        const newRowSelection = {};

        updateSelectionForRowAndSubRows({
          row,
          rowId,
          newIsSelected,
          newRowSelection,
        });

        lastSelectedRowRefIndex.current = index;
        onRowSelectionChange(newRowSelection);
      }
    },
    [
      allowShiftClickSelection,
      getRowId,
      isCtrlOrCmdKeyPressed,
      isShiftKeyPressed,
      lastSelectedRowRefIndex,
      onRowSelectionChange,
      rowSelection,
      showCheckbox,
      updateSelectionForRowAndSubRows,
    ]
  );
  const inFullPageEventView = isInFullPageEventView();
  const options = useMemo<
    Partial<TableOptions<PurchaseOrderTicketGroup | null>>
  >(
    () => ({
      data,
      columns: columns,
      state: {
        pagination,
        rowSelection,
        expanded: expandedState,
        sorting,
        columnVisibility: {
          checkbox: showCheckbox,
          qualityControlState: isAutoPoQualityControlled ?? false,
        },
        columnPinning: {
          right: hasTablePinActionColumnFeature
            ? [PurchasesTableColumnId.Actions]
            : undefined,
        },
      },
      meta: {
        rowSupportsAccordion,
        sectionType,
        formatOptionOverrides,
      },
      enableRowSelection:
        showCheckbox ||
        (inFullPageEventView &&
          hasFullPageEventViewSidePanelSelectionFilterFeature),
      enableMultiRowSelection: showCheckbox,
      enableSubRowSelection:
        showCheckbox && viewMode !== PurchaseViewMode.TileView, // For tile view, only select purchaseOrdes
      getRowId: getRowId,
      onPaginationChange: setPagination,
      onRowSelectionChange:
        !allowShiftClickSelection || showCheckbox
          ? onRowSelectionChange
          : undefined,
      onSortingChange: setSorting,
      onExpandedChange: setExpandedState,
      getSubRows: (originalRow) => {
        if (
          !originalRow ||
          !originalRow.ticketGroupItems?.length ||
          originalRow.ticketGroupItems?.length < 2
        ) {
          return [];
        }

        return originalRow.ticketGroupItems.map(
          (tgItem) => tgItem as PurchaseOrderTicketGroup
        );
      },
      getRowCanExpand: (originalRow) =>
        viewMode === PurchaseViewMode.TileView &&
        !!(
          originalRow?.original?.ticketGroupItems?.length &&
          originalRow?.original?.ticketGroupItems?.length > 1
        ),
      getExpandedRowModel: (table) => getExpandedRowModel()(table),
      autoResetExpanded: false,
      paginateExpandedRows: false,
      sortingFns: { ...seatingColumnSorting },
    }),
    [
      data,
      columns,
      pagination,
      rowSelection,
      expandedState,
      sorting,
      showCheckbox,
      isAutoPoQualityControlled,
      hasTablePinActionColumnFeature,
      rowSupportsAccordion,
      sectionType,
      formatOptionOverrides,
      inFullPageEventView,
      hasFullPageEventViewSidePanelSelectionFilterFeature,
      viewMode,
      getRowId,
      setPagination,
      allowShiftClickSelection,
      onRowSelectionChange,
      seatingColumnSorting,
    ]
  );

  if (getDataFail) {
    return (
      <SomethingWentWrong
        message={<Content id={ContentId.FailToLoadListContent} />}
      />
    );
  }

  if (hasNoDataLoad) {
    return (
      <NoData>
        <WarningMessage
          message={
            <FormatContent
              id={FormatContentId.ApplyFilterToReduceCountsToSeeData}
              params={[MAX_NUM_OF_ITEMS_FOR_FLATTENED_VIEWS.toString()]}
            />
          }
        />
      </NoData>
    );
  }

  return data?.length > 0 ? (
    <ColumnResizingContextProvider<PurchasesTableColumnId>
      userSettingId={
        viewMode === PurchaseViewMode.EventTileView
          ? UserSetting.PurchaseEventColumnWidths
          : UserSetting.PurchaseColumnWidths
      }
    >
      <Table
        options={options}
        tableLayout="fixed"
        withOuterPadding={withOuterPadding}
        useVirtuoso={useVirtuoso}
        tableHeadStyle={
          disablePagination
            ? {
                position: 'sticky',
                top: '0',
                zIndex: '10',
              }
            : undefined
        }
        className={clsx({ [shiftSelect]: isShiftKeyPressed })}
        RowComponent={RowComponent}
        virtuosoItemSize={virtuosoItemSize}
        onRowShiftSelect={onRowShiftSelect}
      />
    </ColumnResizingContextProvider>
  ) : // We should never see this - because the events are filtered to only those that has purchaseOrders
  // So this is to prevent a crash - but this should be looked at
  viewMode === PurchaseViewMode.FlattenedView ? null : (
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={purchasesText}
      />
    </NoData>
  );
}
