import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const LayoutIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper aria-label="LayoutIcon" {...props} viewBox="0 0 15 15">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2H6V13H9V2ZM10 2V13H12.5C12.7761 13 13 12.7761 13 12.5V2.5C13 2.22386 12.7761 2 12.5 2H10ZM2.5 2H5V13H2.5C2.22386 13 2 12.7761 2 12.5V2.5C2 2.22386 2.22386 2 2.5 2ZM2.5 1C1.67157 1 1 1.67157 1 2.5V12.5C1 13.3284 1.67157 14 2.5 14H12.5C13.3284 14 14 13.3284 14 12.5V2.5C14 1.67157 13.3284 1 12.5 1H2.5Z"
        fill="inherit"
      />
    </SvgWrapper>
  );
};
