import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ParkingPassIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M9.4 12v6.4H11V16h2c1.32 0 2.4-1.08 2.4-2.4 0-1.32-1.08-2.4-2.4-2.4H9.4v.8zM9 2l.24.4C9.8 3.4 10.88 4 12 4c1.12 0 2.2-.6 2.76-1.6L15 2h5v6.8h-4V7.2h2.4V3.6h-2.52c-.88 1.24-2.36 2-3.88 2s-3-.76-3.88-2H5.6v3.6H8v1.6H5.6v11.6h12.8V9.6l1.6.8V22H4V2h5zm4 10.8c.44 0 .8.36.8.8 0 .44-.36.8-.8.8h-2v-1.6h2zm.6-5.6v1.6h-3.2V7.2h3.2z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
