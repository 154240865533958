import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { usePurchaseVendorAccountSelector } from 'src/components/Selectors/PurchaseVendorAccountSelector/usePurchaseVendorAccountSelector';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { PosMultiSelectProps } from 'src/core/POS/PosMultiSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseVendor, PurchaseVendorAccount } from 'src/WebApiController';

interface AccountSelectorOptions {
  disabled: boolean;
  multiSelectOptions: PosMultiSelectProps['valueOptionsContent'];
  purchaseVendorAccountsByVendorIds: Record<string, PurchaseVendorAccount[]>;
  availableVendorAccounts: Record<string, PurchaseVendorAccount>;
  onSearchTextChange?: Dispatch<SetStateAction<string | undefined>>;
  isFetchingNextPage: boolean;
  hasNextPage: boolean;
  fetchNextPage: () => void;
}

export const useStandalonePurchaseVendorAccountSelector = ({
  vendorAccountIds,
}: {
  vendorAccountIds?: string[];
}): AccountSelectorOptions => {
  const [purchaseVendorAccountsMap, setPurchaseVendorAccountsMap] = useState<
    Record<string, PurchaseVendorAccount[]>
  >({});
  const { query: purchaseVendorQuery, availableVendors } =
    usePurchaseVendorSelector({});

  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const vendorIds = useMemo(() => {
    let vendorIds: number[] = [];
    if (purchaseVendorQuery && !purchaseVendorQuery.isFetching) {
      if (purchaseVendorQuery.data?.length) {
        vendorIds = (purchaseVendorQuery.data ?? []).map(
          (vendor: PurchaseVendor) => vendor.id
        );
      }
    }
    return vendorIds;
  }, [purchaseVendorQuery]);

  const noVendorIds = !vendorIds.length;

  const onPurchaseVendorAccountsListChange = useCallback(
    (purchaseVendorAccounts: PurchaseVendorAccount[]) => {
      const pvaMap: Record<string, PurchaseVendorAccount[]> = {};
      purchaseVendorAccounts
        .filter((pva) => availableVendors[pva.vendorId])
        .forEach((purchaseVendorAccount) => {
          const email =
            purchaseVendorAccount.email || purchaseVendorAccount.name;
          if (!email) {
            return;
          }
          const purchaseVendorAccountsList = pvaMap[email] ?? [];
          purchaseVendorAccountsList.push(purchaseVendorAccount);
          pvaMap[email] = purchaseVendorAccountsList;
        });
      setPurchaseVendorAccountsMap(pvaMap);
    },
    [availableVendors]
  );

  const multiSelectOptions = useMemo(() => {
    return Object.keys(purchaseVendorAccountsMap).reduce<
      Record<string, string>
    >((map, email) => {
      map[email] = email;
      return map;
    }, {});
  }, [purchaseVendorAccountsMap]);

  const {
    query: purchaseVendorAccountsQuery,
    sortedVendorAccounts,
    availableVendorAccounts,
  } = usePurchaseVendorAccountSelector({
    vendorId: vendorIds,
    disabled: noVendorIds,
    placeholderText: ContentId.AllVendorAccounts,
    searchText,
    vendorAccountIds,
  });

  const { isFetchingNextPage, hasNextPage, fetchNextPage } =
    purchaseVendorAccountsQuery;

  const purchaseVendorAccounts = sortedVendorAccounts;

  useEffect(() => {
    onPurchaseVendorAccountsListChange(purchaseVendorAccounts ?? []);
  }, [onPurchaseVendorAccountsListChange, purchaseVendorAccounts]);

  return {
    disabled: noVendorIds || purchaseVendorAccountsQuery.isFetching,
    multiSelectOptions,
    purchaseVendorAccountsByVendorIds: purchaseVendorAccountsMap,
    availableVendorAccounts,
    isFetchingNextPage,
    onSearchTextChange: setSearchText,
    hasNextPage,
    fetchNextPage,
  };
};
