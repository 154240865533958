export enum AdCampaignsTableColumnId {
  Name = 'name',
  State = 'state',
  Budget = 'budget',
  StartDateTime = 'startDateTime',
  EndDateTime = 'endDateTime',
  AdSpend = 'adSpend',
  SoldProceeds = 'salesProceeds',
  SoldQty = 'qtySold',
  Actions = 'action',
}
