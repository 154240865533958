import { useCallback } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { BlinkingWrapper } from 'src/core/ui/AnimatingWrapper';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { Detail, DetailGroup, DetailSection } from 'src/modals/common';
import { ModalBodyDataContainer } from 'src/modals/Modal/Modal.styled';
import { TicketsSection } from 'src/modals/SaleDetails/components/TicketsSection';
import * as styles from 'src/modals/TransferTickets/TransferTickets.css';
import { CopyIcon } from 'src/svgs/CopyIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { Marketplace, SaleDetails } from 'src/WebApiController';

export const TransferTicketsRecipientBody = () => {
  const { posEntity: sale } = useActivePosEntityContext<SaleDetails>();

  const { loginContext } = useAppContext();
  const yourTickets = useContent(ContentId.YourTickets);
  const app = useContent(ContentId.App);
  const transferFrom = useContent(ContentId.TransferFrom);

  const yourBuyer = useContent(ContentId.YourBuyer);
  const transferTo = useContent(ContentId.TransferTo);
  const contact = useContent(ContentId.Contact);

  const { referenceIsCopied, copyToClipboard } = useCopyToClipboard();

  const onCopy = useCallback(
    (text: string, referenceId: string) => {
      copyToClipboard(text, referenceId);
    },
    [copyToClipboard]
  );

  const recipientNameReferenceId = 'recipientName';
  const recipientEmailReferenceId = 'recipientEmail';

  return (
    <ModalBodyDataContainer>
      <Stack direction="column" gap="xl">
        <Content id={ContentId.VerifyBuyerInfo} />

        <Stack direction="column" gap="l">
          <DetailSection name={yourTickets}>
            <DetailGroup>
              {sale?.transSrc?.length ? (
                sale?.transSrc?.map((tts) => (
                  <>
                    <div className={styles.detailWrapper}>
                      <Detail
                        label={app}
                        detail={
                          <div className={styles.transferToInfo}>{tts.app}</div>
                        }
                      />
                    </div>
                    <div className={styles.detailWrapper}>
                      <Detail
                        label={transferFrom}
                        detail={
                          <div>
                            <div className={styles.transferToInfo}>
                              {tts.name}
                            </div>
                            <div className={styles.transferToInfo}>
                              {tts.email}
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </>
                ))
              ) : (
                <>
                  <div className={styles.detailWrapper}>
                    <Detail
                      label={app}
                      detail={
                        <div className={styles.transferToInfo}>
                          {Marketplace.StubHub}
                        </div>
                      }
                    />
                  </div>
                  <div className={styles.detailWrapper}>
                    <Detail
                      label={transferFrom}
                      detail={
                        <div>
                          <div className={styles.transferToInfo}>
                            {loginContext?.user?.displayName}
                          </div>
                          <div className={styles.transferToInfo}>
                            {loginContext?.user?.userName}
                          </div>
                        </div>
                      }
                    />
                  </div>
                </>
              )}
              <TicketsSection
                {...sale!.seating}
                quantity={sale!.qtySold}
                listingId={sale!.listingId}
              />{' '}
            </DetailGroup>
          </DetailSection>
          <DetailSection name={yourBuyer}>
            <DetailGroup>
              {sale?.transDest ? (
                <>
                  <div className={styles.detailWrapper}>
                    <Detail
                      label={transferTo}
                      detail={
                        <div className={styles.detailWrapper}>
                          <div className={styles.transferToInfo}>
                            {sale.transDest.name}
                          </div>
                          <CopyIcon
                            withHoverEffect
                            size={vars.iconSize.s}
                            fill="currentColor"
                            onClick={() =>
                              onCopy(
                                sale.transDest!.name!,
                                recipientNameReferenceId
                              )
                            }
                          />
                          {referenceIsCopied === recipientNameReferenceId && (
                            <span className={styles.isCopiedLabel}>
                              <Content id={ContentId.Copied} />
                            </span>
                          )}
                        </div>
                      }
                    />
                  </div>
                  <div className={styles.detailWrapper}>
                    <Detail
                      label={contact}
                      detail={
                        <div className={styles.detailWrapper}>
                          <div className={styles.transferToInfo}>
                            {sale.transDest.email}
                          </div>
                          <CopyIcon
                            withHoverEffect
                            size={vars.iconSize.s}
                            fill="currentColor"
                            onClick={() =>
                              onCopy(
                                sale.transDest!.email!,
                                recipientEmailReferenceId
                              )
                            }
                          />
                          {referenceIsCopied === recipientEmailReferenceId && (
                            <span className={styles.isCopiedLabel}>
                              <Content id={ContentId.Copied} />
                            </span>
                          )}
                        </div>
                      }
                    />
                  </div>
                </>
              ) : (
                <div className={styles.detailWrapper}>
                  <Detail
                    label={transferTo}
                    detail={
                      <div style={{ padding: `${vars.spacing.sm} 0` }}>
                        <BlinkingWrapper className="blink-on">
                          <div>
                            <Content id={ContentId.RetrievingBuyersInfo} />
                          </div>
                        </BlinkingWrapper>
                      </div>
                    }
                  />
                </div>
              )}
            </DetailGroup>
          </DetailSection>
        </Stack>
      </Stack>
    </ModalBodyDataContainer>
  );
};
