import { ComponentProps, useCallback, useContext } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { GroupListingsModal } from 'src/modals/GroupListings';
import { SeatSaverListingWizard } from 'src/modals/SeatSaverListingWizard/SeatSaverListingWizard';
import { SeatSaverIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import { Event, Feature, Permission } from 'src/WebApiController';

export const LaunchAddSeatSaver = ({
  events,
  disabled,
}: ComponentProps<typeof GroupListingsModal> & {
  event?: Event;
  disabled?: boolean;
}) => {
  const hasPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_AddSeatSaver
  );
  const hasSeatSaverFeature = useUserHasFeature(Feature.SeatSaver);

  const { setModal } = useContext(ModalContext);
  const {
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();

  const addSeatSaver = useCallback(() => {
    setModal({
      children: <SeatSaverListingWizard events={events} />,
      clickOutsideToClose: true,
      size: 'slide-in',
    });
  }, [setModal, events]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (events?.length) {
        setListItemExpansion(
          true,
          events.map((ev) => ev.viagVirtualId)
        );
      }

      addSeatSaver();
    },
    [events, addSeatSaver, setListItemExpansion]
  );

  if (!hasPermission || !hasSeatSaverFeature) {
    return null;
  }

  return (
    <PosDropdownItem
      key="AddSeatSaver"
      onClick={onLaunchDialog}
      disabled={disabled}
    >
      <Stack alignItems="center" gap="m" width="full">
        <SeatSaverIcon
          withHoverEffect
          fill={vars.color.textPrimary}
          size={vars.iconSize.m}
        />
        <Content id={ContentId.AddPlaceholder} />
      </Stack>
    </PosDropdownItem>
  );
};
