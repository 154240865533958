import { SortingState, Updater } from '@tanstack/react-table';
import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { moveElements, randomizeInPlace } from 'src/utils/arrayUtils';
import {
  IListingGroupItem,
  Listing,
  ListingGroupItemInput,
} from 'src/WebApiController';

import {
  GroupListingSortingCriteria,
  MergeListingGroupInputListFields,
} from './groupingTypes';
import { flattenListingGroup, quantityMatches } from './groupingUtils';

type FormEditableFields =
  | 'listingGroupItems'
  | 'desiredActiveListings'
  | 'minActiveListings'
  | 'maxActiveListings'
  | 'targetPercentage'
  | 'deprioritizedQuantities'
  | 'undRelAmt'
  | 'undAbsAmt';
type FormRegistableFields = 'name' | 'undAbsAmt';

const AllowedSortingColumns = ['Section', 'Row', 'Remaining'];

export const groupPrioritySort =
  (
    idToSortingCriteria: Record<number, GroupListingSortingCriteria>,
    deprioritizedQuantities: string[] | null
  ) =>
  <T extends { listingId: number; priority: number }>(a: T, b: T): number => {
    if (isEmpty(deprioritizedQuantities)) {
      return a.priority - b.priority;
    }
    const { availableQuantity: quantityA } = idToSortingCriteria[a.listingId];
    const { availableQuantity: quantityB } = idToSortingCriteria[b.listingId];
    const deprioritizedA = quantityMatches(quantityA, deprioritizedQuantities);
    const deprioritizedB = quantityMatches(quantityB, deprioritizedQuantities);
    if (deprioritizedA === deprioritizedB) {
      return a.priority - b.priority;
    }
    return deprioritizedA ? 1 : -1;
  };

const groupColumnSort =
  (
    items: IListingGroupItem[],
    rowIdToRowOrdinalMap: Record<number, number> | undefined,
    newSorting: SortingState
  ) =>
  (a: ListingGroupItemInput, b: ListingGroupItemInput): number => {
    const sortBySection = newSorting[0]?.id === 'Section';
    const sortByRow = newSorting[0]?.id === 'Row';
    const sortByUnsoldQty = newSorting[0]?.id === 'Remaining';

    // If we are sorting by the unsold qty (remaining) just use the number comparison
    const unsoldA = items.find((l) => l.id === a.listingId)?.availQty ?? 0;
    const unsoldB = items.find((l) => l.id === b.listingId)?.availQty ?? 0;
    if (sortByUnsoldQty) {
      if (newSorting[0].desc) {
        return unsoldB - unsoldA;
      }

      return unsoldA - unsoldB;
    }

    const seatingA = items.find((l) => l.id === a.listingId)?.seating;
    const seatingB = items.find((l) => l.id === b.listingId)?.seating;

    // If we are sorting by row, try to see if we can sort by the row ordinal. If that data is
    // not available, then we will fallback to the original behavior of sorting by row name
    if (sortByRow) {
      const rowOrdinalA =
        !!rowIdToRowOrdinalMap && seatingA?.rowId != null
          ? rowIdToRowOrdinalMap[seatingA?.rowId] ?? undefined
          : undefined;

      const rowOrdinalB =
        !!rowIdToRowOrdinalMap && seatingB?.rowId != null
          ? rowIdToRowOrdinalMap[seatingB?.rowId] ?? undefined
          : undefined;

      // Before we sort by row ordinal we need to ensure ordinals exist for both listings.
      // If either are missing, we cannot compare a valid row ordinal to an undefined row ordinal.
      //
      // Additionally, we cannot a default value when the ordinal is missing, as that could
      // lead to incorrect sorting. We want to avoid a scenario where all listings without
      // a valid row ordinal mapping are clustered together in the final sort. In these
      // cases, we prefer to fallback to just sorting on the string value of the row name.
      const canSortByOrdinals =
        rowOrdinalA !== undefined && rowOrdinalB !== undefined;

      if (canSortByOrdinals) {
        if (newSorting[0].desc) {
          return rowOrdinalB - rowOrdinalA;
        } else {
          return rowOrdinalA - rowOrdinalB;
        }
      }
    }

    const valueA = sortBySection ? seatingA?.section : seatingA?.row ?? '';
    const valueB = sortBySection ? seatingB?.section : seatingB?.row ?? '';

    if (valueA != null && valueB != null) {
      if (newSorting[0].desc) {
        return valueB.localeCompare(valueA, undefined, {
          numeric: true,
        });
      } else {
        return valueA.localeCompare(valueB, undefined, {
          numeric: true,
        });
      }
    }
    return 0;
  };

export const useGroupSortingCriteria = (listings: Listing[]) => {
  // This is used only for ordering of the grouped listings
  return useMemo(
    () =>
      (listings.flatMap(flattenListingGroup) as Listing[]).reduce(
        (acc, { id, isAdminHold, availQty }) => {
          acc[id] = {
            isAdminHold,
            availableQuantity: availQty,
          };
          return acc;
        },
        {} as Record<number, GroupListingSortingCriteria>
      ) ?? {},
    [listings]
  );
};

// Hook to group the group input form operations
export const useMergeListingGroupInputForm = (index: number) => {
  const { watch, setValue, formState, register } =
    useFormContext<MergeListingGroupInputListFields>();
  const mergeListingGroupInputs = watch('mergeListingGroupInputs');
  const eventSettings = watch('eventSettings');
  const listingsInGroupInput = watch(`mergeListingGroupInputs.${index}`);

  const deprioritizedQuantities = useMemo(() => {
    if (!isEmpty(listingsInGroupInput?.deprioritizedQuantities)) {
      return listingsInGroupInput.deprioritizedQuantities;
    }
    return eventSettings?.[index]?.deprioritizedQuantities ?? [];
  }, [listingsInGroupInput?.deprioritizedQuantities, eventSettings, index]);

  const listingGroupNameError = useMemo(
    () => formState.errors.mergeListingGroupInputs?.[index]?.name?.message,
    [formState.errors.mergeListingGroupInputs, index]
  );

  const onDeleteGroup = useCallback(() => {
    mergeListingGroupInputs.splice(index, 1);
    setValue('mergeListingGroupInputs', [...mergeListingGroupInputs]);
  }, [index, mergeListingGroupInputs, setValue]);

  const onSetValues = useCallback(
    (
      name: FormEditableFields,
      value: ListingGroupItemInput[] | string[] | number | null
    ) => {
      switch (name) {
        case 'desiredActiveListings':
        case 'minActiveListings':
        case 'maxActiveListings':
        case 'targetPercentage':
          setValue(
            `mergeListingGroupInputs.${index}.${name}`,
            value as number | null
          );
          break;
        case 'deprioritizedQuantities':
          setValue(
            `mergeListingGroupInputs.${index}.${name}`,
            value as string[] | null
          );
          break;
        case 'listingGroupItems':
          setValue(
            `mergeListingGroupInputs.${index}.listingGroupItems`,
            value as ListingGroupItemInput[]
          );
          break;
        case 'undAbsAmt':
        case 'undRelAmt':
          setValue(
            `mergeListingGroupInputs.${index}.groupUndercutSetting.${name}`,
            value as number | null
          );
          break;
        default:
          break;
      }
    },
    [index, setValue]
  );

  const onRegister = useCallback(
    (name: FormRegistableFields, options: RegisterOptions) => {
      switch (name) {
        case 'name':
          return register(`mergeListingGroupInputs.${index}.name`, options);
        case 'undAbsAmt':
          return register(
            `mergeListingGroupInputs.${index}.groupUndercutSetting.undAbsAmt`,
            options
          );
        default:
          break;
      }
    },
    [index, register]
  );

  return {
    listingsInGroupInput,
    listingGroupNameError,
    deprioritizedQuantities,
    onDeleteGroup,
    onSetValues,
    onRegister,
  };
};

// Hook to group the group item table operations
export const useGroupItemTableOperations = (
  listings: Listing[],
  listingGroupItems: ListingGroupItemInput[] | null,
  deprioritizedQuantities: string[] | null,
  onSetValues: (
    name: FormEditableFields,
    value: ListingGroupItemInput[] | string[] | number | null
  ) => void
) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const idToSortingCriteria = useGroupSortingCriteria(listings);

  useEffect(() => {
    // Reset sorting when listings change
    setSorting([]);
  }, [listingGroupItems?.length]);

  const { venueMapInfo } = useEventMapContext();
  const rowIdToRowOrdinalMap = useMemo(() => {
    return venueMapInfo?.sections.reduce(
      (acc, section) => {
        section.rows.forEach((row) => {
          if (row.id != null && row.ordinal != null) {
            acc[row.id] = row.ordinal;
          }
        });
        return acc;
      },
      {} as Record<number, number>
    );
  }, [venueMapInfo]);

  const assignNewPriority = useCallback(
    (newPriority: number, listingId: number) => {
      if (listingGroupItems == null) return;

      // Remove sorting because we are going to change the order of listings
      // should override the existing sorting
      setSorting([]);
      const sorted = listingGroupItems
        .filter((l) => l.listingId > 0)
        .sort((a, b) => a.priority - b.priority);

      const toIndex = sorted.findIndex((l) => l.priority === newPriority);
      const fromIndex = sorted.findIndex((l) => l.listingId === listingId);
      if (fromIndex === toIndex) {
        return;
      }

      // Enfored order by the bottom ticket quantity
      const enforedListings = moveElements(sorted, fromIndex, toIndex)
        .map((l, i) => ({ ...l, priority: i + 1 }))
        .sort(groupPrioritySort(idToSortingCriteria, deprioritizedQuantities))
        .map((l, i) => ({ ...l, priority: i + 1 }));

      // Save the new listing (ordered by priority)
      onSetValues(`listingGroupItems`, enforedListings);
    },
    [
      deprioritizedQuantities,
      idToSortingCriteria,
      listingGroupItems,
      onSetValues,
    ]
  );

  const onSortingChange = useCallback(
    (updater: Updater<SortingState>) => {
      if (listingGroupItems == null) return;

      const newSorting =
        typeof updater === 'function' ? updater(sorting) : updater;
      setSorting(newSorting);

      // Only allow sorting by Section, Row, and Unsold Qty (i.e. remaining)
      if (!AllowedSortingColumns.includes(newSorting[0]?.id)) {
        return;
      }

      const flattened = listings.flatMap(flattenListingGroup);

      // Sort and assign new priority to all listings based on sorting
      const listingsInGroupNew = listingGroupItems
        .filter((l) => l.listingId > 0)
        .sort(groupColumnSort(flattened, rowIdToRowOrdinalMap, newSorting))
        .map((l, i) => ({ ...l, priority: i + 1 }));

      // Enfored order by the bottom ticket quantity
      const enforedListings = listingsInGroupNew
        .sort(groupPrioritySort(idToSortingCriteria, deprioritizedQuantities))
        .map((l, i) => ({ ...l, priority: i + 1 }));

      onSetValues(`listingGroupItems`, enforedListings);
    },
    [
      sorting,
      listings,
      listingGroupItems,
      idToSortingCriteria,
      deprioritizedQuantities,
      onSetValues,
      rowIdToRowOrdinalMap,
    ]
  );

  const onRandomizeRanks = useCallback(() => {
    if (listingGroupItems == null) return;

    setSorting([]);
    randomizeInPlace(listingGroupItems); // randomize the order and set the new order as the new priority

    const sortedListings = listingGroupItems
      .filter((l) => l.listingId > 0)
      .map((l, i) => ({ ...l, priority: i + 1 }))
      .sort(groupPrioritySort(idToSortingCriteria, deprioritizedQuantities))
      .map((l, i) => ({ ...l, priority: i + 1 }));

    onSetValues(`listingGroupItems`, sortedListings);
  }, [
    deprioritizedQuantities,
    idToSortingCriteria,
    listingGroupItems,
    onSetValues,
  ]);

  const removeListingFromInput = useCallback(
    (listingId: number) => {
      if (listingGroupItems == null) return;

      onSetValues(`listingGroupItems`, [
        ...listingGroupItems
          .filter((lx) => lx.listingId !== listingId)
          .map((lx, i) => ({ ...lx, priority: i + 1 })),
      ]);
    },
    [listingGroupItems, onSetValues]
  );

  const onUpdateDeprioritizedQuantities = useCallback(
    (value: string[]) => {
      if (listingGroupItems == null) return;

      const filteredItems = listingGroupItems.filter((l) => l.listingId > 0);
      if (filteredItems?.length > 0) {
        const sortedListings = listingGroupItems
          .filter((l) => l.listingId > 0)
          .map((l, i) => ({ ...l, priority: i + 1 }))
          .sort(groupPrioritySort(idToSortingCriteria, value))
          .map((l, i) => ({ ...l, priority: i + 1 }));
        onSetValues(`listingGroupItems`, sortedListings);
      }
      onSetValues(`deprioritizedQuantities`, value);
    },
    [idToSortingCriteria, listingGroupItems, onSetValues]
  );

  return {
    sorting,
    assignNewPriority,
    onSortingChange,
    onRandomizeRanks,
    onUpdateDeprioritizedQuantities,
    removeListingFromInput,
  };
};
