import {
  evaluateFormulaBase,
  FormulaEditable,
  FormulaExecutable,
  fromExecutableFormula,
  parseEditableFormulaBase,
  parseExecutableFormula,
  validateFormulaSingleStatement,
} from 'src/utils/columns/customColumnUtils';
import { EVENTS_FORMULA_FIELDS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { ListingDetailedMetrics } from 'src/WebApiController';

import { EventsFormulaFields } from '../../../tables/EventsTable/configs/EventsTableColumnsConfig';
import { ColumnPersonalizationFlags } from '../columnUtils.types';
import {
  getPersonalizationFlagsFromFormulaBase,
  MathematicalToken,
  parseMathematicalTokensFromFormulaBase,
} from '../customColumnMathUtils';
import { EVENTS_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from './eventsColumnUtils.constants';
import { EventsTableColumnId } from './eventsColumnUtils.types';
import {
  FORMULA_FIELD_TO_COLUMN_ID,
  LISTING_METRIC_TEST_DATA,
} from './eventsCustomColumnUtils.constants';

export const parseEditableFormula = (
  editableFormula: FormulaEditable | undefined
): string[] | undefined => {
  const parsedTokenResult = parseEditableFormulaBase(editableFormula);
  if (!parsedTokenResult) {
    return;
  }

  let parseFailed = false;
  const tokens: string[] = [];
  parsedTokenResult.forEach((tokenResult) => {
    if (tokenResult.isPlainToken) {
      tokens.push(tokenResult.token);
    } else {
      const fieldName = tokenResult.token;
      if (
        EVENTS_FORMULA_FIELDS_TO_CID[fieldName as keyof EventsFormulaFields] ==
        null
      ) {
        parseFailed = true;
        return;
      }
      tokens.push(
        EVENTS_FORMULA_FIELDS_TO_CID[fieldName as keyof EventsFormulaFields]
      );
    }
  });

  if (parseFailed) return;

  return tokens;
};

const mapFormulaFields = (
  metrics?: ListingDetailedMetrics
): EventsFormulaFields => {
  return {
    actualStr: metrics?.str,
    expectedStr: undefined,
    liftPercentage: metrics?.liftPerc,
    listedAtp: metrics?.listedATP?.amt,
    revenue: metrics?.rvn?.amt,
    totalAtp: metrics?.atp?.amt,
    totalCost: metrics?.ttlCst?.amt,
    totalGrossProfit: metrics?.pandL?.amt,
    totalListPrice: metrics?.ttlListPrc?.amt,
    totalListedQuantity: metrics?.listQty,
    totalListedValue: undefined,
    totalNetProceeds: metrics?.netProcs?.amt,
    totalSoldCost: metrics?.soldCst?.amt,
    totalSoldQuantity: metrics?.soldQty,
    totalTicketQuantity: metrics?.tktQty,
    totalUnsoldListPrice: metrics?.ttlUnsoldListPrc?.amt,
    totalUnsoldQuantity: metrics?.unsoldQty,
  };
};

export const evaluateFormula = (
  formula: FormulaExecutable,
  metrics?: ListingDetailedMetrics
) => {
  const data = mapFormulaFields(metrics);

  return evaluateFormulaBase(formula, data);
};

export const validateFormula = (formula: string) => {
  return (
    parseExecutableFormula(formula) != null &&
    evaluateFormula(formula, LISTING_METRIC_TEST_DATA) != null &&
    validateFormulaSingleStatement(formula)
  );
};

export const findDependentEventsTableColumnIds = (
  editableFormula: FormulaEditable
): EventsTableColumnId[] => {
  const fieldNames =
    parseEditableFormulaBase(editableFormula, true)?.map((t) => t.token) ?? [];

  return (
    Object.entries(FORMULA_FIELD_TO_COLUMN_ID)
      .filter(([key, value]) => fieldNames.includes(key) && value != null)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => value!)
  );
};

export function isCustomEventsColumn(columnId: string) {
  return !Object.values(EventsTableColumnId).some(
    (l) => l.toString() === columnId
  );
}

const getTokenFromFieldName = (fieldName: string): MathematicalToken => {
  const columnId =
    FORMULA_FIELD_TO_COLUMN_ID[fieldName as keyof EventsFormulaFields];

  if (columnId == null) {
    // Tag field - here we assume it's not currency - might need to change
    return {
      token: `tag-${fieldName}`,
      isPlainToken: false,
      isCurrency: false,
    };
  }

  const { isCurrency, isPercent } =
    EVENTS_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[columnId];

  return {
    token: columnId,
    isPlainToken: false,
    isCurrency,
    isPercent,
  };
};

/**
 * Parse editableFormula, transforming formula into mathematical tokens
 * to infer personalization flags
 *
 * E.g. `${ticketCount} + 20` ==> [
 *     { token: 'ticketCount', isCurrency: false, isPercent: false, isPlainToken: false },
 *     { token: '+', isCurrency: false, isPercent: false, isPlainToken: true },
 *     { token: '20', isCurrency: false, isPercent: false, isPlainToken: true }
 * ]
 * @param editableFormula
 * @returns parsed list of tokens, or undefined if failed to parse
 */
const parseMathematicalTokensFromFormula = (
  editableFormula: FormulaEditable | undefined
): MathematicalToken[] | undefined => {
  return parseMathematicalTokensFromFormulaBase(
    editableFormula,
    getTokenFromFieldName
  );
};

export const getPersonalizationFlagsFromFormula = (
  executableFormula: FormulaExecutable
): Partial<ColumnPersonalizationFlags> => {
  const editableFormula = fromExecutableFormula(executableFormula);
  const mathTokens = parseMathematicalTokensFromFormula(editableFormula);

  return getPersonalizationFlagsFromFormulaBase(mathTokens);
};
