import React from 'react';

import { PaymentSize, PaymentViewbox } from '../enums';

type WrapperPropType = {
  isSquare?: boolean;
  size?: PaymentSize;
  title?: string;
  disabled?: boolean;
  children: JSX.Element | JSX.Element[];
  onClick?: React.MouseEventHandler<SVGElement>;
  onMouseDown?: React.MouseEventHandler<SVGElement>;
  onMouseEnter?: React.MouseEventHandler<SVGElement>;
  onMouseLeave?: React.MouseEventHandler<SVGElement>;
};

const sizes = {
  [PaymentSize.s]: {
    width: '24px',
    height: '16px',
  },
  [PaymentSize.l]: {
    width: '36px',
    height: '24px',
  },
};

export const SvgWrapper = ({
  children,
  isSquare,
  size = PaymentSize.l,
  title,
  disabled = false,
  onClick,
  onMouseDown,
  onMouseEnter,
  onMouseLeave,
}: WrapperPropType) => (
  <svg
    viewBox={isSquare ? PaymentViewbox.square : PaymentViewbox.rect}
    xmlns="http://www.w3.org/2000/svg"
    color="inherit"
    role="img"
    aria-labelledby={title}
    width={sizes[size].width}
    height={sizes[size].height}
    xlinkTitle={title}
    opacity={disabled ? 0.5 : 1}
    onClick={disabled ? undefined : onClick}
    onMouseDown={disabled ? undefined : onMouseDown}
    onMouseEnter={disabled ? undefined : onMouseEnter}
    onMouseLeave={disabled ? undefined : onMouseLeave}
  >
    {children}
  </svg>
);
