import React, { useCallback, useState } from 'react';
import { DeallocateButton } from 'src/components/Buttons/DeallocateButton';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { vars } from 'src/core/themes';
import { ButtonProps, Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ErrorOutlineIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import { getPosManagedMarketplaceSaleInputFromSale } from 'src/utils/saleUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  ActivityLogClient,
  ApiException,
  Marketplace,
  Permission,
  PointOfSaleSaleStatus,
  SaleActionType,
  SaleClient,
  SaleDetails,
} from 'src/WebApiController';

import * as styles from './LaunchDeallocateSale.css';

export const LaunchDeallocateSale = ({
  action,
  saleId,
  marketplaceSaleId,
  marketplace,
  saleStatus,
  variant,
  iconOnlyMode,
  disabled,
}: {
  action: SaleActionType.Deallocate | SaleActionType.RejectAllocation;
  saleId: number;
  marketplaceSaleId?: string | null;
  marketplace?: Marketplace | null;
  saleStatus: PointOfSaleSaleStatus;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { event, posEntity, setActivePosEntity } =
    useActivePosEntityContext<SaleDetails>();

  const hasPermission = useUserHasAnyOfPermissions(
    Permission.SellerAccount_Admin
  );

  const { launchDialog, closeDialog, dialogProps } = useBasicDialog();
  const [shouldAdminHold, setShouldAdminHold] = useState<boolean>(false);
  const onToggleAdminHold = useCallback(
    () => setShouldAdminHold((prev) => !prev),
    []
  );

  const deallocateSale = useCallback(() => {
    setIsLoading(true);
    tryInvokeApi(
      async () => {
        const client = new SaleClient(activeAccountWebClientConfig);

        if (
          marketplace === Marketplace.Offline &&
          saleStatus === PointOfSaleSaleStatus.Fulfilled
        ) {
          // If the Offline Sale is set to be Fulfilled - it can't just be deallocated
          // We need to put it back to PendingFulfillment first then the Deallocation will work
          let saleDetails = posEntity;
          if (saleDetails == null) {
            saleDetails = await client.getSaleBySaleId(saleId);
          }
          const offlineSaleUpdate = getPosManagedMarketplaceSaleInputFromSale(
            saleDetails,
            event!
          );

          offlineSaleUpdate.saleStatus = posChangedField(
            PointOfSaleSaleStatus.PendingFulfillment
          );
          await client.mergePosManagedMarketplaceSale(offlineSaleUpdate);
        }

        const result = await client.deallocateSale(saleId, shouldAdminHold);
        if (!isSuccess(result)) {
          showErrorDialog(
            'SaleClient.deallocateSale',
            {
              message: result.message ?? genericError,
              status: result.status!,
            } as ApiException,
            {
              trackErrorData: { saleId },
            }
          );
        } else {
          await setActivePosEntity(saleId, marketplaceSaleId, true);
        }
      },
      (error) => {
        showErrorDialog('SaleClient.deallocateSale', error, {
          trackErrorData: { saleId },
        });
      },
      () => {
        setIsLoading(false);
        closeDialog();
      }
    );
  }, [
    activeAccountWebClientConfig,
    closeDialog,
    event,
    genericError,
    marketplace,
    marketplaceSaleId,
    posEntity,
    saleId,
    saleStatus,
    setActivePosEntity,
    shouldAdminHold,
    showErrorDialog,
  ]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      new ActivityLogClient(activeAccountWebClientConfig).trackEntityView(
        saleId,
        ActionOutboxEntityType.Sale
      );
      launchDialog();
    },
    [saleId, launchDialog, activeAccountWebClientConfig]
  );

  const contentId =
    action === SaleActionType.RejectAllocation
      ? ContentId.RejectAllocation
      : ContentId.DeallocateSale;

  return (
    <div>
      <DeallocateButton
        onClick={onLaunchDialog}
        variant={variant}
        disabled={disabled || isLoading}
        iconOnlyMode={iconOnlyMode}
        textContentId={contentId}
        titleContentId={contentId}
        icon={
          action === SaleActionType.RejectAllocation ? (
            <ErrorOutlineIcon
              withHoverEffect
              fill={
                iconOnlyMode || (variant && variant !== 'regular')
                  ? IconsFill.textWarning
                  : IconsFill.textInverted
              }
              size={vars.iconSize.m}
            />
          ) : undefined
        }
      />
      <ConfirmDialog
        {...dialogProps}
        disabled={isLoading}
        isLoading={isLoading}
        headerText={<Content id={contentId} />}
        onOkay={deallocateSale}
        bodyText={
          <Stack direction="column" gap="l">
            <Content
              id={
                action === SaleActionType.RejectAllocation
                  ? ContentId.RejectAllocationWarning
                  : ContentId.DeallocateSaleWarning
              }
            />
            {hasPermission && action !== SaleActionType.RejectAllocation && (
              <Checkbox
                className={styles.checkboxStyles}
                labelPosition="right"
                checked={shouldAdminHold}
                onChange={onToggleAdminHold}
                label={<Content id={ContentId.PutSaleListingOnHold} />}
              />
            )}
          </Stack>
        }
      />
    </div>
  );
};
