import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function MobileTransferIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M17.938 8.875V19a3 3 0 01-3 3H9.061a3 3 0 01-3-3v-3.875h1.563v4.313a1 1 0 00.883.993l.117.006h6.75a1 1 0 00.993-.883l.007-.116V8.874h1.563zM12 17.938a.937.937 0 110 1.874.937.937 0 010-1.875zm-7.67-6.5l.582.632-1.336 1.39 5.924-.001v.895H3.576l1.336 1.45-.582.633-1.748-1.898L2 13.906l.582-.633 1.748-1.835zM14.937 2a3 3 0 013 3v1.375h-1.562V4.562a1 1 0 00-1-1h-6.75a1 1 0 00-.993.884l-.007.117v8.062H6.062V5a3 3 0 013-3h5.876zm4.733 3.063l1.748 1.898.582.633-.582.633-1.748 1.835-.582-.632 1.336-1.39-5.924.001v-.895h5.924l-1.336-1.45.582-.633z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
