import { useCallback, useMemo, useState } from 'react';
import { usePurchasePaymentMethodSelector } from 'src/components/Selectors/PurchasePaymentMethodSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { AddPurchasePaymentMethodDialog } from 'src/dialogs/AddPurchasePaymentMethodDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PurchaseClient, PurchasePaymentMethod } from 'src/WebApiController';

export type PurchasePaymentMethodSelectionInputProps = Omit<
  PosSelectProps,
  'valueOptionsContent'
> & {
  hideAdditionalOptions?: boolean;
};

export function PurchasePaymentMethodSelectionInput({
  disabled,
  onChange,
  hideAdditionalOptions,
  ...posSelectProps
}: PurchasePaymentMethodSelectionInputProps) {
  const addNewPaymentMethodContent = useContent(
    ContentId.AddPaymentMethodAction
  );
  const { activeAccountWebClientConfig } = useAppContext();
  const additionalOptions = useMemo(
    () => ({
      [ContentId.AddPaymentMethodAction]: addNewPaymentMethodContent + '...',
    }),
    [addNewPaymentMethodContent]
  );

  const { query, posSelectProps: hookPosSelectProps } =
    usePurchasePaymentMethodSelector({
      disabled,
      additionalOptions: hideAdditionalOptions ? undefined : additionalOptions,
    });

  const { showErrorDialog } = useErrorBoundaryContext();
  const insertPurchasePaymentMethod = useCallback(
    (paymentMethod: PurchasePaymentMethod) =>
      tryInvokeApi(
        () =>
          new PurchaseClient(
            activeAccountWebClientConfig
          ).insertPurchasePaymentMethod(paymentMethod),
        (error) => {
          showErrorDialog('PurchaseClient.insertPurchasePaymentMethod', error, {
            trackErrorData: paymentMethod,
          });
        }
      ),
    [activeAccountWebClientConfig, showErrorDialog]
  );

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <>
      <PosSelect
        {...hookPosSelectProps}
        {...posSelectProps}
        disabled={disabled}
        onChange={(option) => {
          if (option && option in additionalOptions) {
            setIsDialogOpen(true);
            return;
          }

          onChange(option);
        }}
      />
      <AddPurchasePaymentMethodDialog
        isOpen={isDialogOpen}
        onSave={async (paymentMethod) => {
          const newId = await insertPurchasePaymentMethod(paymentMethod);
          await query.refetch();
          if (newId !== undefined) {
            onChange(String(newId));
          }
          setIsDialogOpen(false);
        }}
        onCancel={() => {
          setIsDialogOpen(false);
        }}
      />
    </>
  );
}
