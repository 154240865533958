import { ReactNode, useEffect, useMemo } from 'react';
import { useGetNoSelectedItemsLabel } from 'src/components/common/MultiSelect/useGetNoSelectedItemsLabel';
import { useContent } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { unpackNumericIds } from 'src/contexts/MultiSelectionContext/MultiSelectionContext.utils';
import { ContentId } from 'src/utils/constants/contentId';
import { EventWithData } from 'src/WebApiController';

interface SetPurchasesAccordionSelectionCountLabelProps {
  children: ReactNode;
  events: EventWithData[] | undefined;
}
export const SetPurchasesAccordionSelectionCountLabel = ({
  children,
  events,
}: SetPurchasesAccordionSelectionCountLabelProps) => {
  const {
    getSelection,
    totalItemsSelected,
    selectionMode,
    setSelectedCountLabel,
    setUsingCustomSelectedCountLabel,
  } = useMultiSelectionContext();

  const noItemsSelected = useGetNoSelectedItemsLabel();
  const isAllGroupsMode = selectionMode?.mode === MultiSelectScope.AllGroups;

  const eventText = useContent(ContentId.Event);
  const eventsText = useContent(ContentId.Events);
  const ticketGroupText = useContent(ContentId.TicketGroup);
  const ticketGroupsText = useContent(ContentId.TicketGroups);
  const selectedText = useContent(ContentId.Selected);
  const fromText = useContent(ContentId.From);

  useEffect(() => {
    setUsingCustomSelectedCountLabel(true);
  }, [setUsingCustomSelectedCountLabel]);

  const eventWithDataMap = useMemo<Record<string, EventWithData>>(() => {
    const map: Record<string, EventWithData> = {};
    events?.forEach((item) => {
      const id = item.event.viagVirtualId;
      map[id] = item;
    });
    return map;
  }, [events]);

  const selectedCountLabel = useMemo<string>(() => {
    if (!totalItemsSelected) {
      return noItemsSelected;
    }

    const selection = getSelection();

    let eventsCount = 0;
    let ticketGroupsCount = 0;

    if (isAllGroupsMode) {
      eventsCount = new Set([
        ...selection.groupIds,
        ...selection.items.groupIds,
      ]).size;
    }

    // Ticket groups count & purchase orders count
    // All group selected
    selection.groupIds.forEach((eventId) => {
      ticketGroupsCount += eventWithDataMap[eventId]?.ticketGrpsCnt ?? 0;
    });

    // Single elements selected - Ticket Group & Purchase Orders count
    const ticketGroupIdsSet = new Set();
    selection.items.itemIds.forEach((id) => {
      const [poId, tckGroupId] = unpackNumericIds(id);
      ticketGroupIdsSet.add(tckGroupId);
    });

    ticketGroupsCount += ticketGroupIdsSet.size;

    const eventsLabel = eventsCount === 1 ? eventText : eventsText;
    const ticketsGroupLabel =
      ticketGroupsCount === 1 ? ticketGroupText : ticketGroupsText;

    return (
      isAllGroupsMode
        ? `(${ticketGroupsCount} ${ticketsGroupLabel} ${selectedText} ${fromText} ${eventsCount} ${eventsLabel})`
        : `(${ticketGroupsCount} ${ticketsGroupLabel} ${selectedText})`
    ).toLocaleLowerCase();
  }, [
    eventText,
    eventWithDataMap,
    eventsText,
    fromText,
    getSelection,
    isAllGroupsMode,
    noItemsSelected,
    selectedText,
    ticketGroupText,
    ticketGroupsText,
    totalItemsSelected,
  ]);

  useEffect(() => {
    setSelectedCountLabel(selectedCountLabel);
  }, [selectedCountLabel, setSelectedCountLabel]);

  return <>{children}</>;
};
