import { Marketplace, PosFieldArrayOfTag, Tag } from 'src/WebApiController';

export const tagFieldHasChanges = (
  tags1: PosFieldArrayOfTag | null | undefined,
  tags2: PosFieldArrayOfTag | null | undefined,
  // tagsMetadata is an optional parameter
  // If it is provided, only tags that are in tagsMetadata will be compared
  tagsMetadata?: Tag[]
): boolean => {
  if (!tags1?.hasChanged) {
    return false;
  }

  const _tags1 = !tags1?.value
    ? null
    : !tagsMetadata
    ? tags1.value
    : tags1.value.filter((t) => tagsMetadata.find((tm) => tm.key === t.key));
  const _tags2 = !tags2?.value
    ? null
    : !tagsMetadata
    ? tags2.value
    : tags2.value.filter((t) => tagsMetadata.find((tm) => tm.key === t.key));

  if ((!_tags1 || !_tags1.length) && (!_tags2 || !_tags2.length)) {
    return false;
  }
  if (!_tags1 || !_tags1.length || !_tags2 || !_tags2.length) {
    return true;
  }
  if (_tags1.length !== _tags2.length) {
    return true;
  }
  return _tags1.some((t1) => {
    return !_tags2.find(
      (t2) =>
        t1.key === t2.key &&
        t1.value === t2.value &&
        t1.includeInReport === t2.includeInReport &&
        t1.ordinal === t2.ordinal
    );
  });
};

export const tagHasChanges = (
  tags1: Tag[] | null | undefined,
  tags2: Tag[] | null | undefined,
  // tagsMetadata is an optional parameter
  // If it is provided, only tags that are in tagsMetadata will be compared
  tagsMetadata?: Tag[]
): boolean => {
  const _tags1 = !tags1
    ? null
    : !tagsMetadata
    ? tags1
    : tags1.filter((t) => tagsMetadata.find((tm) => tm.key === t.key));
  const _tags2 = !tags2
    ? null
    : !tagsMetadata
    ? tags2
    : tags2.filter((t) => tagsMetadata.find((tm) => tm.key === t.key));

  if ((!_tags1 || !_tags1.length) && (!_tags2 || !_tags2.length)) {
    return false;
  }
  if (!_tags1 || !_tags1.length || !_tags2 || !_tags2.length) {
    return true;
  }
  if (_tags1.length !== _tags2.length) {
    return true;
  }
  return _tags1.some((t1) => {
    return !_tags2.find(
      (t2) =>
        t1.key === t2.key &&
        t1.value === t2.value &&
        t1.includeInReport === t2.includeInReport &&
        t1.ordinal === t2.ordinal
    );
  });
};

export function ToMarketplaceEnum(value: string | null | undefined) {
  if (!value) {
    return null;
  }

  const entry = Object.entries(Marketplace).find(([, val]) => val === value);
  return entry ? entry[1] : undefined;
}
