import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { mergeProps } from 'src/core/utils';
import { ContentId } from 'src/utils/constants/contentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { TicketType } from 'src/WebApiController';

import * as styles from '../UploadETickets.css';
import {
  UploadETicketsInstructions,
  UploadETicketsSubInstructions,
} from '../UploadETickets.styled';
import {
  DocumentFileUploadInfo,
  ETicketsForm,
  getDocumentPagesMap,
} from '../UploadETickets.utils';
import { UploadETicketsSeatingSection } from './UploadETicketsSeatingSection';
import { UploadETicketsThumbnailSection } from './UploadETicketsThumbnailSection';

export const UploadETicketsSeatAssignmentBody = ({
  entitWithTickets,
  ticketType,
  eTicketUploadInfos,
  setETicketUploadInfos,
  className,
}: {
  entitWithTickets: Pick<
    EntityWithRealTickets,
    'viagVirtualId' | 'id' | 'ticketCnt' | 'tickets' | 'seating' | 'entityType'
  >;
  ticketType: TicketType.ETicket | TicketType.QRCode;
  eTicketUploadInfos: DocumentFileUploadInfo[];
  setETicketUploadInfos: (infos: DocumentFileUploadInfo[]) => void;
  className?: string;
}) => {
  const allPagesAvailable =
    eTicketUploadInfos.find(
      (ui) => ui.file && ui.uploadInfo && (ui.pages?.length || 0) === 0
    ) == null; // returns false where any uploadInfo (that has file and uploadInfo) has no pages
  const eTicketPages = getDocumentPagesMap(eTicketUploadInfos);
  const { watch, setValue } = useFormContext<ETicketsForm>();
  const watchTickets = watch('ticketAssignments'); // you can supply default value as second argument

  const autoAssignment = useRef(true);
  useEffect(() => {
    if (autoAssignment.current && allPagesAvailable) {
      // Only perform once, and perform only when all pages are available
      const allPages = eTicketUploadInfos
        .flatMap((ui) => ui.pages)
        .filter((p) => p);
      if (
        allPages.length > 0 && // if we have pages to assigne
        watchTickets.filter((t) => t.pageId !== '').length === 0 // and we have not assign any ticket to any seat
      ) {
        // Try to auto-assign seat based on order of the pages
        // Users can re-adjust if they see fit
        // TODO - we can probably use some smart-scanning features to figure out which page is for which seat
        watchTickets.forEach((t, index) => {
          if (index < allPages.length) {
            const page = allPages[index];
            t.documentId = page!.documentId;
            t.pageId = page!.id;
            t.pageNumber = page!.pageNumber;
            t.pageUri = page!.thumbUrl;
            t.documentUri = page!.documentUrl;
          }
        });

        setValue('ticketAssignments', [...watchTickets]);
        autoAssignment.current = false;
      }
    }
  }, [
    allPagesAvailable,
    eTicketPages,
    eTicketUploadInfos,
    setValue,
    watchTickets,
  ]);

  return (
    <div
      {...mergeProps(
        { className: styles.uploadETicketsBodyContainer },
        { className: className }
      )}
    >
      <UploadETicketsInstructions>
        <Content
          id={
            ticketType === TicketType.ETicket
              ? ContentId.AssignETicketsIns
              : ContentId.AssignQRCodesIns
          }
        />
      </UploadETicketsInstructions>
      <UploadETicketsSubInstructions>
        <Content
          id={
            ticketType === TicketType.ETicket
              ? ContentId.HoverZoomETicketIns
              : ContentId.HoverZoomQRCodeIns
          }
        />
      </UploadETicketsSubInstructions>

      <div className={styles.uploadETicketsSection}>
        <UploadETicketsThumbnailSection
          entitWithTickets={entitWithTickets}
          eTicketUploadInfos={eTicketUploadInfos}
          setETicketUploadInfos={setETicketUploadInfos}
        />
        <UploadETicketsSeatingSection
          ticketType={ticketType}
          entityWithTickets={entitWithTickets}
          eTicketUploadInfos={eTicketUploadInfos}
        />
      </div>
    </div>
  );
};
