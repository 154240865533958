import { debounce } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  useContent,
  useFormattedContent,
} from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { shared, vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { PurchaseVendorDialog } from 'src/dialogs/PurchaseVendorDialog';
import { useGetAccessibleVendorAccountsQuery } from 'src/hooks/api/useGetAccessibleVendorAccountsQuery';
import { useGetAccessibleVendorsQuery } from 'src/hooks/api/useGetAccessibleVendorsQuery';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useFlexSearchIndex } from 'src/hooks/useFlexSearchIndex';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EditIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SomethingWentWrong } from 'src/views/SomethingWentWrong';
import {
  ApiException,
  Feature,
  PurchaseClient,
  PurchaseVendor,
  PurchaseVendorAccount,
} from 'src/WebApiController';

import { SellerAccountSettingVendorAccounts } from '../SellerAccountSettingVendorAccounts';
import * as styles from './SellerAccountSettingVendors.css';

export function SellerAccountSettingVendors() {
  const vendorsText = useContent(ContentId.Vendors);

  const hasSellerAccountPurchaseVendorOverrideFeature = useUserHasFeature(
    Feature.SellerAccountPurchaseVendorOverride
  );

  const searchPlaceholderText = useFormattedContent(
    FormatContentId.SearchItem,
    vendorsText
  ) as string;

  const vendorsQuery = useGetAccessibleVendorsQuery({
    getOverride: true,
    getVendorAccountsCount: true,
  });

  const vendorAccountsQuery = useGetAccessibleVendorAccountsQuery(
    vendorsQuery.data?.map((v) => v.id)
  );

  const vendorDialog = useBasicDialog();
  const deleteDialog = useBasicDialog();
  const [currentVendor, setCurrentVendor] = useState<PurchaseVendor>();
  const [searchText, setSearchText] = useState<string>('');

  const vendorVenueOptionsContent = useMemo(() => {
    return (
      vendorsQuery.data?.reduce(
        (r, cur) => {
          r[cur.id.toString()] = cur.name;
          return r;
        },
        {} as Record<string, string>
      ) ?? {}
    );
  }, [vendorsQuery.data]);

  const { flexSearchIndex } = useFlexSearchIndex(
    vendorVenueOptionsContent,
    Object.keys(vendorVenueOptionsContent).length > 0,
    true
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      const newText = text.trim();
      if (newText !== searchText) {
        setSearchText(newText);
      }
    }, 200),
    [searchText]
  );

  const vendorData = useMemo(() => {
    const vendorsData = vendorsQuery.data ?? undefined;
    if (!searchText || !flexSearchIndex) {
      return vendorsData;
    }

    const keysToRender = new Set(
      (flexSearchIndex.search(searchText) as string[]).map(Number)
    );

    return vendorsData?.filter((v) => keysToRender.has(v.id));
  }, [flexSearchIndex, searchText, vendorsQuery.data]);

  const onAddNewVendor = useCallback(() => {
    setCurrentVendor({
      id: -1,
      name: '',
      isGlobal: false,
      delivType: null,
      isNoFulfill: null,
      tktTypeRules: null,
      vendAccCounts: 0,
    });
    vendorDialog.launchDialog();
  }, [vendorDialog]);

  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const onSaveVendor = useCallback(
    async (vendor: PurchaseVendor) => {
      await tryInvokeApi(
        async () => {
          const newId = await new PurchaseClient(
            activeAccountWebClientConfig
          ).mergeVendor(vendor);

          if (newId) {
            vendorsQuery.refetch();
            vendorDialog.closeDialog();
          } else {
            // This should never happen
            // Normal errors would have fallen to the error catch
            showErrorDialog(
              'PurchaseClient.mergeVendor',
              {
                message: 'Unable to create or save vendor ' + vendor.id,
                status: 500,
              } as ApiException,
              {
                trackErrorData: vendor,
              }
            );
          }
        },
        (error) => {
          showErrorDialog('PurchaseClient.mergeVendor', error, {
            trackErrorData: vendor,
          });
        }
      );
    },
    [activeAccountWebClientConfig, vendorDialog, vendorsQuery, showErrorDialog]
  );

  const onVendorCancel = useCallback(() => {
    setCurrentVendor(undefined);
    vendorDialog.closeDialog();
  }, [vendorDialog]);

  const onVendorDelete = useCallback(async () => {
    await tryInvokeApi(
      async () => {
        const newId = await new PurchaseClient(
          activeAccountWebClientConfig
        ).deleteVendor(currentVendor?.id);

        if (newId) {
          vendorsQuery.refetch();
          deleteDialog.closeDialog();
        } else {
          // This should never happen
          // Normal errors would have fallen to the error catch
          showErrorDialog(
            'PurchaseClient.deleteVendor',
            {
              message: 'Unable to delete vendor ' + currentVendor?.id,
              status: 500,
            } as ApiException,
            {
              trackErrorData: currentVendor,
            }
          );
        }
      },
      (error) => {
        showErrorDialog('PurchaseClient.deleteVendor', error, {
          trackErrorData: currentVendor,
        });
      }
    );
  }, [
    activeAccountWebClientConfig,
    deleteDialog,
    vendorsQuery,
    showErrorDialog,
    currentVendor,
  ]);

  const vendorAccountsByVendorId = useMemo(() => {
    return vendorAccountsQuery.data?.reduce(
      (r, cur) => {
        if (!r[cur.vendorId]) {
          r[cur.vendorId] = [];
        }
        r[cur.vendorId].push(cur);
        return r;
      },
      {} as Record<number, PurchaseVendorAccount[]>
    );
  }, [vendorAccountsQuery.data]);

  return (
    <>
      <div
        className={shared.flex({
          direction: 'column',
          gap: 'xl',
        })}
      >
        <div
          className={shared.flex({
            alignItems: 'center',
            justifyContent: 'spaceBetween',
          })}
        >
          <div className={shared.typography.title5}>
            <Content id={ContentId.Vendors} />
          </div>
          <Stack direction="row" gap="m" alignItems="center">
            <PosSearchBox
              placeholder={searchPlaceholderText}
              onSearchChange={debouncedOnSearchChange}
              disabled={
                vendorsQuery.isLoading ||
                vendorAccountsQuery.isLoading ||
                !flexSearchIndex
              }
            />
            <Button onClick={onAddNewVendor}>
              <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
              <span style={{ whiteSpace: 'nowrap' }}>
                <Content id={ContentId.NewVendor} />
              </span>
            </Button>
          </Stack>
        </div>
        {vendorsQuery.isLoading || vendorAccountsQuery.isLoading ? (
          <PosSpinner />
        ) : vendorsQuery.isError || vendorAccountsQuery.isError ? (
          <SomethingWentWrong
            message={<Content id={ContentId.FailToLoadListContent} />}
          />
        ) : (
          <Virtuoso
            className={styles.vendorVirtuoso}
            style={{ minHeight: vendorsQuery.data?.length ? '90vh' : '0' }}
            data={vendorData}
            overscan={window.innerHeight}
            itemContent={(i, vendor) => {
              const { id, name } = vendor;
              return (
                <div
                  key={id}
                  className={shared.flex({
                    direction: 'column',
                    gap: 'lg',
                  })}
                >
                  <div className={shared.flex({ gap: 'md' })}>
                    <div className={styles.vendorName}>{name}</div>
                    <EditIcon
                      withHoverEffect
                      onClick={() => {
                        setCurrentVendor(vendor);
                        vendorDialog.launchDialog();
                      }}
                    />
                    {/* <DeleteIcon // This is never implemented - we have a dummy endpoint DeleteVendor that does nothing
                      withHoverEffect
                      onClick={() => {
                        setCurrentVendor(vendor);
                        deleteDialog.launchDialog();
                      }}
                    /> */}
                  </div>
                  <SellerAccountSettingVendorAccounts
                    vendorId={id}
                    vendorAccounts={vendorAccountsByVendorId?.[id]}
                    refetchVendorAccounts={vendorAccountsQuery.refetch}
                  />
                </div>
              );
            }}
          />
        )}
      </div>
      {currentVendor && (
        <PurchaseVendorDialog
          {...vendorDialog.dialogProps}
          vendor={currentVendor}
          onClosed={onVendorCancel}
          onSave={onSaveVendor}
        />
      )}
      <ConfirmDialog
        {...deleteDialog.dialogProps}
        size="md"
        headerText={<Content id={ContentId.DeletePurchaseVendor} />}
        bodyText={<Content id={ContentId.DeletePurchaseVendorWarning} />}
        onOkay={onVendorDelete}
        onCancel={deleteDialog.closeDialog}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
      />
    </>
  );
}
