import { ComponentProps, useCallback, useContext } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { Button } from 'src/core/ui';
import { ViewSeatsListModal } from 'src/modals/ViewSeatsListModal';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType, ListingDetails } from 'src/WebApiController';

export const LaunchViewAllSeats = ({
  listingId,
  marketplaceListingId,
  cancelTo,
  seeAvailableSeats,
}: {
  listingId: number;
  marketplaceListingId?: string | null;

  disabled?: boolean;
} & ComponentProps<typeof ViewSeatsListModal>) => {
  const { setModal } = useContext(ModalContext);
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();

  const viewComps = useCallback(() => {
    setModal({
      children: (
        <ViewSeatsListModal
          cancelTo={cancelTo}
          seeAvailableSeats={seeAvailableSeats}
        />
      ),
      clickOutsideToClose: true,
      size: 'slide-in',
      deepLinkValue: listingId,
      trackViewEntityType: ActionOutboxEntityType.Listing,
    });
  }, [setModal, cancelTo, listingId, seeAvailableSeats]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      setActivePosEntity(listingId, marketplaceListingId);
      viewComps();
    },
    [setActivePosEntity, listingId, marketplaceListingId, viewComps]
  );

  return (
    <Button variant={'link'} onClick={onLaunchDialog}>
      <Content id={ContentId.SeeAll} />
    </Button>
  );
};
