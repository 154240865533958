import { RowData } from '@tanstack/react-table';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useActiveFocusContext } from 'src/contexts/ActiveFocusContext/ActiveFocusContext';
import { PosVirtuosoTableRowProps } from 'src/tables/Table/PosVirtuosoTableComponent/PosVirtuosoTableContext';

import * as styles from '../Table.css';

export const PosVirtuosoTableRow = <T extends RowData>({
  context,
  children,
  ...props
}: PosVirtuosoTableRowProps<T>) => {
  const {
    tableRowStyle,
    tableRows,
    rowWrapper,
    RowComponent,
    onRowShiftSelect,
  } = context || {};
  const inputRef = useRef<HTMLTableRowElement>(null);
  const { activeItem, onKeyDownHandler } = useActiveFocusContext();

  useEffect(() => {
    if (activeItem?.id && inputRef.current && activeItem.id === props.item.id) {
      inputRef.current.focus();
      inputRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
      inputRef.current.focus();
    }
  }, [activeItem, props.item.id]);

  const rowProps = {
    className: clsx(styles.tableRow, {
      [styles.isSelectedRow]: props.item.getIsSelected(),
      [styles.isSubRow]: props.item.depth > 0,
    }),
    style: {
      ...tableRowStyle,
      width: '99%',
    },
    ref: inputRef,
    key: props.item.id,
    onClick: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      e.stopPropagation();
      props.item.toggleSelected();
      onRowShiftSelect?.(tableRows, props.item, props['data-item-index']);
    },
    onKeyDown:
      activeItem != null
        ? (e: React.KeyboardEvent<HTMLTableRowElement>) =>
            onKeyDownHandler(e, props['data-index'], tableRows)
        : undefined,
    tabIndex:
      activeItem != null
        ? props.item.getIsSelected() || props.item.id === activeItem?.id
          ? 0
          : -1
        : undefined,
    ...props,
  };

  children = rowWrapper
    ? rowWrapper(props.item.original, children, props['data-index'])
    : children;

  if (RowComponent) {
    return (
      <RowComponent row={props.item} {...rowProps}>
        {children}
      </RowComponent>
    );
  }

  return <tr {...rowProps}>{children}</tr>;
};
