import clsx from 'clsx';
import { ComponentProps, PropsWithChildren, useEffect } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { darkModeRoot } from 'src/app/App.css';
import { useGenericDialogContext } from 'src/contexts/GenericDialogContext/GenericDialogContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { useListenForEscapeKeyPressed } from 'src/hooks/useListenForEscapeKeyPressed';
import { CrossIcon } from 'src/svgs/Viagogo';

import * as styles from './GenericDialog.css';
import {
  CrossIconContainer,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from './GenericDialog.styled';

export type GenericDialogProps = {
  header: React.ReactNode;
  footer: React.ReactNode;
  includeCloseButton?: boolean;
  isFullHeight?: boolean;
  onCancel?: () => void;
} & ComponentProps<typeof RSModal>;

export const GenericDialog = ({
  header,
  footer,
  children,
  className,
  isFullHeight,
  contentClassName,
  includeCloseButton = true,
  scrollable = true,
  ...rest
}: PropsWithChildren<GenericDialogProps>) => {
  const { isOpen, onCancel } = rest;
  const isEscapeKeyPressed = useListenForEscapeKeyPressed();
  const { setIsDialogOpen } = useGenericDialogContext();

  useEffect(() => {
    if (isEscapeKeyPressed && isOpen && onCancel) {
      onCancel();
    }
  }, [isEscapeKeyPressed, isOpen, onCancel]);

  useEffect(() => {
    if (isOpen) {
      setIsDialogOpen(true);
    } else {
      // Give time to run on next render so calling onCancel when Escape key
      // is pressed doesn't collide with dialog closed
      // (Escape key should run with dialog open).
      setTimeout(() => setIsDialogOpen(false), 100);
    }
  }, [isOpen, setIsDialogOpen]);

  const { isDarkMode } = useSiteTheme();
  return (
    <RSModal
      backdrop="static"
      backdropClassName={styles.backdrop}
      size="sm"
      className={clsx(className, {
        [darkModeRoot]: isDarkMode,
      })}
      contentClassName={clsx(contentClassName, styles.modalContent, {
        [styles.fullHeightModalContent]: isFullHeight,
      })}
      {...rest}
      centered={false}
      scrollable={scrollable}
    >
      <DialogHeader>
        {header}
        {includeCloseButton && rest.onCancel && (
          <CrossIconContainer>
            <CrossIcon
              size={vars.iconSize.m}
              onClick={rest.onCancel}
              withHoverEffect
            />
          </CrossIconContainer>
        )}
      </DialogHeader>
      <DialogBody>{children}</DialogBody>
      <DialogFooter>{footer}</DialogFooter>
    </RSModal>
  );
};
