import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

// XXX: Use this instead of `theme.media.tablet` because theme breakpoints are exclusive.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tabletBreakPoint = ({ theme }: { theme: any }) =>
  `min-width: ${theme.media.breakpoints.md}px`;

export const InputGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${() => vars.spacing.m};

  label {
    width: 100%;
  }

  .DayPickerInput input {
    // fixes overflow issue on smaller screen sizes
    width: 120px;
  }

  @media only screen and (${tabletBreakPoint}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const InputRow = styled.div`
  display: flex;
  column-gap: ${() => vars.spacing.m};
  align-items: baseline;
  flex-wrap: wrap;
`;

export const InputRowAmount = styled(InputRow)`
  flex-wrap: nowrap;

  div:nth-child(2) {
    flex-grow: 1;
  }
`;

export const FullWidthSelectionInputContainer = styled.div`
  div.full-width {
    max-width: 100%;
    width: 100%;
    .value-selected {
      position: absolute;
      width: 80%;
      text-align: left;
    }
  }
`;

export const InputRowSide = styled.div`
  width: 100%;
  @media only screen and (${tabletBreakPoint}) {
    width: initial;
    flex-grow: 1;
  }
`;
