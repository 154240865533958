import clsx from 'clsx';
import { isEqual, uniq } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useToggle } from 'react-use';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { prependTicketClassColorColumnForFirstTime } from 'src/components/Listings/InventoryEventPage/Sections/ManualPricingSection.utils';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { typography } from 'src/core/themes/shared.css';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { EVENTS_TABLE_COLUMNS_CONFIG } from 'src/tables/EventsTable/configs/EventsTableColumnsConfig';
import { LISTING_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/ListingTable/configs/ListingReportTableColumnsConfig';
import { getListingTableColumnConfigs } from 'src/tables/ListingTable/configs/ListingTableColumnsConfig';
import { LISTING_TABLE_FLATTENED_COLUMNS_CONFIG } from 'src/tables/ListingTable/configs/ListingTableFlattenedColumnsConfig';
import { PURCHASE_TABLE_COLUMNS_CONFIG } from 'src/tables/PurchaseTable/configs/PurchaseTableColumnsConfig';
import { SALE_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleReportTableColumnsConfig';
import { SALE_TABLE_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleTableColumnsConfig';
import { SALE_TABLE_FLATTENED_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleTableFlattenedColumnsConfig';
import { getColumnPersonalization } from 'src/utils/columns/columnPersonalizationUtils';
import {
  defaultEventsColumnsConfig,
  defaultListingColumnsConfig,
  defaultListingFlattenedColumnsConfig,
  defaultPurchaseEventEnabledColumnsConfig,
  defaultPurchasesColumnsConfig,
  defaultSalesColumnsConfig,
  defaultSalesFlattenedColumnsConfig,
  filterColumnsByFeatures,
} from 'src/utils/columns/columnUtils';
import { CustomEventsColumn } from 'src/utils/columns/events/eventsCustomColumnUtils.types';
import { filterCustomColumnsForListing } from 'src/utils/columns/inventory/inventoryCustomColumnUtils';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { CustomPurchasesColumn } from 'src/utils/columns/purchases/purchasesCustomColumnUtils.types';
import { filterCustomColumnsForSale } from 'src/utils/columns/sales/salesCustomColumnUtils';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import {
  ActionOutboxEntityType,
  Feature,
  UserSetting,
} from 'src/WebApiController';

import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal';
import * as styles from './ColumnSettings.css';
import { TableColumnsInput } from './TableColumnsInput';

export type TableColumnsSectionType =
  | SectionType.Listings
  | SectionType.ListingsFlattened
  | SectionType.InventorySideTable
  | SectionType.Sales
  | SectionType.SalesFlattened
  | SectionType.SalesSideTable
  | SectionType.Events
  | SectionType.Purchases
  | SectionType.PurchaseEvent
  | SectionType.PurchaseSideTable;
type TableColumnsModalContainerProps = {
  onClose: () => void;
  // Not fully supporting reporting yet
  sectionType: TableColumnsSectionType;
  // Only supported for Purchase for now, used for filtering visible columns
  viewMode?: string | null;
  // Only used with SectionType.Listings
  // We only want to show this column for setting if it's visible in the current view
  showTicketClassColor?: boolean;
};

export function ColumnSettingsModal<
  T extends
    | CustomListingColumn
    | CustomSalesColumn
    | CustomEventsColumn
    | CustomPurchasesColumn,
>({
  onClose,
  sectionType,
  viewMode,
  showTicketClassColor,
}: TableColumnsModalContainerProps) {
  const { appContext, loginContext } = useAppContext();

  const hasTablePinActionColumnFeature = useUserHasFeature(
    Feature.TablePinActionColumn
  );
  const hasSortListingsInGroupFeature = useUserHasFeature(
    Feature.SortListingsInGroup
  );

  const [isOpen, toggleIsOpen] = useToggle(true);
  const {
    value: storedEventsColumnOrderSetting = defaultEventsColumnsConfig,
    setUserSetting: setEventsColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.EventsColumnOrder,
  });
  const {
    value: storedInventoryColumnOrderSetting = defaultListingColumnsConfig,
    setUserSetting: setInventoryColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryColumnOrder,
  });
  const {
    value:
      storedInventoryFlattenedColumnOrderSetting = defaultListingFlattenedColumnsConfig,
    setUserSetting: setInventoryFlattenedColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryFlattenedColumnOrder,
  });
  const {
    value:
      storedInventorySideTableColumnsOrderSetting = defaultListingColumnsConfig,
    setUserSetting: setInventorySideTableColumnsOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventorySideTableColumnOrder,
  });
  const {
    value: storedSaleColumnOrderSetting = defaultSalesColumnsConfig,
    setUserSetting: setSaleColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SaleColumnOrder,
  });
  const {
    value:
      storedSaleFlattenedColumnOrderSetting = defaultSalesFlattenedColumnsConfig,
    setUserSetting: setSaleFlattenedColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SaleFlattenedColumnOrder,
  });
  const {
    value: storedSaleSideTableColumnOrderSetting = defaultSalesColumnsConfig,
    setUserSetting: setSaleSideTableColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SalesSideTableColumnOrder,
  });
  const {
    value: storedPurchaseColumnOrderSetting = defaultPurchasesColumnsConfig,
    setUserSetting: setPurchaseColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseColumnOrder,
  });
  const {
    value:
      storedPurchaseEventColumnOrderSetting = defaultPurchaseEventEnabledColumnsConfig,
    setUserSetting: setPurchaseEventColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseEventColumnOrder,
  });
  const {
    value:
      storedPurchaseSideTableColumnOrderSetting = defaultPurchasesColumnsConfig,
    setUserSetting: setPurchaseSideTableColumnOrderSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseSideTableColumnOrder,
  });

  const setColumnOrderSetting = {
    [SectionType.Events]: setEventsColumnOrderSetting,
    [SectionType.Listings]: setInventoryColumnOrderSetting,
    [SectionType.ListingsFlattened]: setInventoryFlattenedColumnOrderSetting,
    [SectionType.ListingsReport]: () => console.log('Unused'),
    [SectionType.InventorySideTable]: setInventorySideTableColumnsOrderSetting,
    [SectionType.Sales]: setSaleColumnOrderSetting,
    [SectionType.SalesFlattened]: setSaleFlattenedColumnOrderSetting,
    [SectionType.SalesReport]: () => console.log('Unused'),
    [SectionType.SalesSideTable]: setSaleSideTableColumnOrderSetting,
    [SectionType.Purchases]: setPurchaseColumnOrderSetting,
    [SectionType.PurchaseEvent]: setPurchaseEventColumnOrderSetting,
    [SectionType.PurchaseSideTable]: setPurchaseSideTableColumnOrderSetting,
  }[sectionType];

  const {
    value: storedEventsColumnsEnabledSetting,
    setUserSetting: setEventsColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.EventsColumnsEnabled,
  });
  const {
    value: storedInventoryColumnsEnabledSetting,
    setUserSetting: setInventoryColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryColumnsEnabled,
  });
  const {
    value: storedInventoryFlattenedColumnsEnabledSetting,
    setUserSetting: setInventoryFlattenedColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryFlattenedColumnsEnabled,
  });
  const {
    value: storedInventorySideTableColumnsEnabledSetting,
    setUserSetting: setInventorySideTableColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.InventorySideTableColumnsEnabled,
  });
  const {
    value: storedSaleColumnsEnabledSetting,
    setUserSetting: setSaleColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SaleColumnsEnabled,
  });
  const {
    value: storedSaleFlattenedColumnsEnabledSetting,
    setUserSetting: setSaleFlattenedColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SaleFlattenedColumnsEnabled,
  });
  const {
    value: storedSaleSideTableColumnsEnabledSetting,
    setUserSetting: setSaleSideTableColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.SalesSideTableColumnsEnabled,
  });
  const {
    value: storedPurchaseColumnsEnabledSetting,
    setUserSetting: setPurchaseColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseColumnsEnabled,
  });
  const {
    value:
      storedPurchaseEventColumnsEnabledSetting = defaultPurchaseEventEnabledColumnsConfig,
    setUserSetting: setPurchaseEventColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseEventColumnsEnabled,
  });
  const {
    value: storedPurchaseSideTableColumnsEnabledSetting,
    setUserSetting: setPurchaseSideTableColumnsEnabledSetting,
  } = useServerUserSetting<string[]>({
    id: UserSetting.PurchaseSideTableColumnsEnabled,
  });

  const setColumnsEnabledSetting = {
    [SectionType.Events]: setEventsColumnsEnabledSetting,
    [SectionType.Listings]: setInventoryColumnsEnabledSetting,
    [SectionType.ListingsFlattened]: setInventoryFlattenedColumnsEnabledSetting,
    [SectionType.ListingsReport]: () => console.log('Unused'),
    [SectionType.InventorySideTable]:
      setInventorySideTableColumnsEnabledSetting,
    [SectionType.Sales]: setSaleColumnsEnabledSetting,
    [SectionType.SalesFlattened]: setSaleFlattenedColumnsEnabledSetting,
    [SectionType.SalesReport]: () => console.log('Unused'),
    [SectionType.SalesSideTable]: setSaleSideTableColumnsEnabledSetting,
    [SectionType.Purchases]: setPurchaseColumnsEnabledSetting,
    [SectionType.PurchaseEvent]: setPurchaseEventColumnsEnabledSetting,
    [SectionType.PurchaseSideTable]: setPurchaseSideTableColumnsEnabledSetting,
  }[sectionType];

  const {
    value: customEventsColumns = [],
    setUserSetting: setCustomEventsColumns,
  } = useServerUserSetting<CustomEventsColumn[]>({
    id: UserSetting.EventsCustomColumns,
  });
  const {
    value: customListingColumns = [],
    setUserSetting: setCustomListingColumns,
  } = useServerUserSetting<CustomListingColumn[]>({
    id: UserSetting.InventoryCustomColumns,
  });
  const {
    value: customSaleColumns = [],
    setUserSetting: setCustomSaleColumns,
  } = useServerUserSetting<CustomSalesColumn[]>({
    id: UserSetting.SaleCustomColumns,
  });
  const {
    value: customPurchaseColumns = [],
    setUserSetting: setCustomPurchaseColumns,
  } = useServerUserSetting<CustomPurchasesColumn[]>({
    id: UserSetting.PurchaseCustomColumns,
  });
  const setCustomColumns = {
    [SectionType.Events]: setCustomEventsColumns,
    [SectionType.Listings]: setCustomListingColumns,
    [SectionType.ListingsFlattened]: setCustomListingColumns,
    [SectionType.ListingsReport]: () => console.log('Unused'),
    [SectionType.InventorySideTable]: setCustomListingColumns,
    [SectionType.Sales]: setCustomSaleColumns,
    [SectionType.SalesFlattened]: setCustomSaleColumns,
    [SectionType.SalesReport]: () => console.log('Unused'),
    [SectionType.SalesSideTable]: setCustomSaleColumns,
    [SectionType.Purchases]: setCustomPurchaseColumns,
    [SectionType.PurchaseEvent]: setCustomPurchaseColumns,
    [SectionType.PurchaseSideTable]: setCustomPurchaseColumns,
  }[sectionType] as (value: T[]) => void;

  const actionOutboxEntityType = useMemo(() => {
    switch (sectionType) {
      case SectionType.Events:
        return ActionOutboxEntityType.SellerEvent;
      case SectionType.Listings:
      case SectionType.ListingsFlattened:
      case SectionType.InventorySideTable:
        return ActionOutboxEntityType.Listing;
      case SectionType.Sales:
      case SectionType.SalesFlattened:
      case SectionType.SalesSideTable:
        return ActionOutboxEntityType.Sale;
      case SectionType.Purchases:
      case SectionType.PurchaseEvent:
      case SectionType.PurchaseSideTable:
        return ActionOutboxEntityType.Purchase;
    }
  }, [sectionType]);

  const { tagsMetadataNumeric } = useTagsForEntityType(
    actionOutboxEntityType,
    true
  );

  const customColumnsFiltered = {
    [SectionType.Events]: customEventsColumns, // TODO: check if correct
    [SectionType.Listings]: filterCustomColumnsForListing(
      customListingColumns,
      tagsMetadataNumeric
    ),
    [SectionType.ListingsFlattened]: filterCustomColumnsForListing(
      customListingColumns,
      tagsMetadataNumeric
    ),
    [SectionType.ListingsReport]: () => console.log('Unused'),
    [SectionType.InventorySideTable]: filterCustomColumnsForListing(
      customListingColumns,
      tagsMetadataNumeric
    ),
    [SectionType.Sales]: filterCustomColumnsForSale(
      customSaleColumns,
      tagsMetadataNumeric
    ),
    [SectionType.SalesFlattened]: filterCustomColumnsForSale(
      customSaleColumns,
      tagsMetadataNumeric
    ),
    [SectionType.SalesReport]: () => console.log('Unused'),
    [SectionType.SalesSideTable]: filterCustomColumnsForSale(
      customSaleColumns,
      tagsMetadataNumeric
    ),
    [SectionType.Purchases]: customPurchaseColumns,
    [SectionType.PurchaseEvent]: customPurchaseColumns,
    [SectionType.PurchaseSideTable]: customPurchaseColumns,
  }[sectionType] as T[];

  const { tagsMetadata } = useTagsForEntityType(actionOutboxEntityType, true);

  const presetColumns = {
    [SectionType.Events]: EVENTS_TABLE_COLUMNS_CONFIG,
    [SectionType.Listings]: getListingTableColumnConfigs(
      hasSortListingsInGroupFeature
    ),
    [SectionType.ListingsFlattened]: {
      ...LISTING_TABLE_FLATTENED_COLUMNS_CONFIG,
      ...getListingTableColumnConfigs(hasSortListingsInGroupFeature),
    },
    [SectionType.InventorySideTable]: getListingTableColumnConfigs(
      hasSortListingsInGroupFeature
    ),
    [SectionType.ListingsReport]: LISTING_REPORT_TABLE_COLUMNS_CONFIG,
    [SectionType.Sales]: SALE_TABLE_COLUMNS_CONFIG,
    [SectionType.SalesFlattened]: {
      ...SALE_TABLE_FLATTENED_COLUMNS_CONFIG,
      ...SALE_TABLE_COLUMNS_CONFIG,
    },
    [SectionType.SalesReport]: SALE_REPORT_TABLE_COLUMNS_CONFIG,
    [SectionType.SalesSideTable]: SALE_TABLE_COLUMNS_CONFIG,
    [SectionType.Purchases]: PURCHASE_TABLE_COLUMNS_CONFIG,
    [SectionType.PurchaseEvent]: PURCHASE_TABLE_COLUMNS_CONFIG,
    [SectionType.PurchaseSideTable]: PURCHASE_TABLE_COLUMNS_CONFIG,
  }[sectionType];
  const presetColumnIds = Object.values(presetColumns).map((i) => i.id);

  const filterColumns = useCallback(
    (columnList: string[]) => {
      // Filter out reporting tags that are later turned off reporting
      const activeTagNames = tagsMetadata?.map((t) => t.key) ?? [];
      const customColumnIds = customColumnsFiltered.map(
        (c) => (c as T & { id: string }).id
      );
      return columnList.filter((columnId) => {
        // Don't show inconfigurable columns - like checkbox
        if (presetColumnIds.includes(columnId)) {
          const isConfigurable = getColumnPersonalization({
            id: columnId,
            sectionType,
          }).isConfigurable;

          // With this new feature, we set action column as a column always showing at the right, thus inconfigurable
          // Without the feature, we treat the column as configurable
          if (!hasTablePinActionColumnFeature) {
            if (columnId === 'action' || columnId === 'actions') {
              return true;
            }
          }

          return isConfigurable;
        }
        return (
          activeTagNames.includes(columnId) ||
          customColumnIds.includes(columnId)
        );
      });
    },
    [
      customColumnsFiltered,
      hasTablePinActionColumnFeature,
      presetColumnIds,
      sectionType,
      tagsMetadata,
    ]
  );

  const storedColumnsEnabledSetting = {
    [SectionType.Events]: storedEventsColumnsEnabledSetting,
    [SectionType.Listings]: storedInventoryColumnsEnabledSetting,
    [SectionType.ListingsFlattened]:
      storedInventoryFlattenedColumnsEnabledSetting,
    [SectionType.ListingsReport]: [],
    [SectionType.InventorySideTable]:
      storedInventorySideTableColumnsEnabledSetting,
    [SectionType.Sales]: storedSaleColumnsEnabledSetting,
    [SectionType.SalesFlattened]: storedSaleFlattenedColumnsEnabledSetting,
    [SectionType.SalesReport]: [],
    [SectionType.SalesSideTable]: storedSaleSideTableColumnsEnabledSetting,
    [SectionType.Purchases]: storedPurchaseColumnsEnabledSetting,
    [SectionType.PurchaseEvent]: storedPurchaseEventColumnsEnabledSetting,
    [SectionType.PurchaseSideTable]:
      storedPurchaseSideTableColumnsEnabledSetting,
  }[sectionType];

  const storedColumnOrderSetting = {
    [SectionType.Events]: storedEventsColumnOrderSetting,
    [SectionType.Listings]: storedInventoryColumnOrderSetting,
    [SectionType.ListingsFlattened]: storedInventoryFlattenedColumnOrderSetting,
    [SectionType.ListingsReport]: [],
    [SectionType.InventorySideTable]:
      storedInventorySideTableColumnsOrderSetting,
    [SectionType.Sales]: storedSaleColumnOrderSetting,
    [SectionType.SalesFlattened]: storedSaleFlattenedColumnOrderSetting,
    [SectionType.SalesReport]: [],
    [SectionType.SalesSideTable]: storedSaleSideTableColumnOrderSetting,
    [SectionType.Purchases]: storedPurchaseColumnOrderSetting,
    [SectionType.PurchaseEvent]: storedPurchaseEventColumnOrderSetting,
    [SectionType.PurchaseSideTable]: storedPurchaseSideTableColumnOrderSetting,
  }[sectionType];

  const storedColumnOrderSettingFiltered = useMemo(() => {
    const allColumnIdsAvailable = filterColumns(
      uniq([
        ...storedColumnOrderSetting,
        ...presetColumnIds.sort((a, b) => a.localeCompare(b)),
        ...customColumnsFiltered.map((c) => c.id),
        ...(tagsMetadata?.map((t) => t.key) ?? []),
      ])
    );

    const existingColumnsOrderSetting = filterColumns(
      storedColumnOrderSetting as string[]
    );

    allColumnIdsAvailable.forEach((columnId) => {
      if (!existingColumnsOrderSetting.includes(columnId)) {
        existingColumnsOrderSetting.push(columnId);
      }
    });

    return existingColumnsOrderSetting;
  }, [
    customColumnsFiltered,
    filterColumns,
    presetColumnIds,
    storedColumnOrderSetting,
    tagsMetadata,
  ]);

  const storedColumnEnabledSettingFiltered = useMemo(() => {
    if (
      Array.isArray(storedColumnsEnabledSetting) &&
      storedColumnsEnabledSetting.length > 0
    ) {
      return filterColumns(storedColumnsEnabledSetting as string[]);
    } else {
      return filterColumns(storedColumnOrderSetting);
    }
  }, [filterColumns, storedColumnsEnabledSetting, storedColumnOrderSetting]);

  const editabilityId = {
    [SectionType.Events]: UserSetting.EventsColumnEditability,
    [SectionType.Listings]: UserSetting.InventoryColumnEditability,
    [SectionType.ListingsFlattened]:
      UserSetting.InventoryFlattenedColumnEditability,
    [SectionType.ListingsReport]: '' as UserSetting, // Unused
    [SectionType.InventorySideTable]:
      UserSetting.InventorySideTableColumnEditability,
    [SectionType.Sales]: UserSetting.SaleColumnEditability,
    [SectionType.SalesFlattened]: UserSetting.SaleFlattenedColumnEditability,
    [SectionType.SalesReport]: '' as UserSetting, // Unused,
    [SectionType.SalesSideTable]: UserSetting.SalesSideTableColumnEditability,
    [SectionType.Purchases]: UserSetting.PurchaseColumnEditability,
    [SectionType.PurchaseEvent]: UserSetting.PurchaseEventColumnEditability,
    [SectionType.PurchaseSideTable]:
      UserSetting.PurchaseSideTableColumnEditability,
  }[sectionType];
  const { value: editableColumns = [], setUserSetting: setEditableColumns } =
    useServerUserSetting<string[]>({
      id: editabilityId,
    });

  const customColumns = {
    [SectionType.Events]: customEventsColumns,
    [SectionType.Listings]: customListingColumns,
    [SectionType.ListingsFlattened]: customListingColumns,
    [SectionType.ListingsReport]: [], // Unused
    [SectionType.InventorySideTable]: customListingColumns,
    [SectionType.Sales]: customSaleColumns,
    [SectionType.SalesFlattened]: customSaleColumns,
    [SectionType.SalesReport]: [], // Unused
    [SectionType.SalesSideTable]: customSaleColumns,
    [SectionType.Purchases]: customPurchaseColumns,
    [SectionType.PurchaseEvent]: customPurchaseColumns,
    [SectionType.PurchaseSideTable]: customPurchaseColumns,
  }[sectionType];

  const [userColumnSelection, setUserColumnSelection] = useState<string[]>(
    filterColumnsByFeatures(
      storedColumnEnabledSettingFiltered,
      sectionType,
      customColumns,
      loginContext?.user,
      appContext?.features
    )
  );

  const [userColumnSorting, setUserColumnSorting] = useState<string[]>(
    filterColumnsByFeatures(
      storedColumnOrderSettingFiltered,
      sectionType,
      customColumns,
      loginContext?.user,
      appContext?.features
    )
  );
  const [userEditableColumns, setUserEditableColumns] =
    useState<string[]>(editableColumns);
  const [userColumnIsDirty, setUserColumnIsDirty] = useState(false);

  const onSaveHandler = () => {
    if (!userColumnSelection) {
      // TODO: show error
      return;
    }
    setColumnsEnabledSetting(userColumnSelection);
    setColumnOrderSetting(userColumnSorting);
    setEditableColumns(userEditableColumns);
    toggleIsOpen();
  };

  useEffect(() => {
    // XX Migration to add TicketClassColor column for users
    // When the column becomes available for the first time
    if (
      storedInventoryColumnOrderSetting != null &&
      storedInventoryColumnsEnabledSetting != null
    ) {
      prependTicketClassColorColumnForFirstTime(
        storedInventoryColumnOrderSetting,
        storedInventoryColumnsEnabledSetting,
        setInventoryColumnOrderSetting,
        setInventoryColumnsEnabledSetting
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedInventoryColumnOrderSetting, storedInventoryColumnsEnabledSetting]);

  // Sync the user setting to the default if the user has never set it
  useEffect(() => {
    const newColumns = filterColumnsByFeatures(
      storedColumnOrderSettingFiltered,
      sectionType,
      customColumns,
      loginContext?.user,
      appContext?.features,
      tagsMetadata ?? []
    );
    const newEnabledColumns = filterColumnsByFeatures(
      storedColumnEnabledSettingFiltered,
      sectionType,
      customColumns,
      loginContext?.user,
      appContext?.features
    );
    if (!userColumnIsDirty) {
      if (!isEqual(newColumns, userColumnSorting)) {
        setUserColumnSorting(newColumns);
      }
      if (!isEqual(editableColumns, userEditableColumns)) {
        setUserEditableColumns(editableColumns);
      }
      if (!isEqual(newEnabledColumns, userColumnSelection)) {
        setUserColumnSelection(newEnabledColumns);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appContext?.features,
    customListingColumns,
    editableColumns,
    loginContext?.user,
    storedColumnOrderSettingFiltered,
    storedColumnEnabledSettingFiltered,
  ]);

  // Keep the columns up to date in case the user changes the settings (e.g. adds/deletes custom column)
  useEffect(() => {
    const allColumnIdsAvailable = filterColumns(
      uniq([
        ...storedColumnOrderSetting,
        ...presetColumnIds.sort((a, b) => a.localeCompare(b)),
        ...customColumnsFiltered.map((c) => c.id),
        ...(tagsMetadata?.map((t) => t.key) ?? []),
      ])
    );

    let userColumnSortingFiltered = filterColumns(userColumnSorting);

    allColumnIdsAvailable.forEach((columnId) => {
      if (!userColumnSortingFiltered.includes(columnId)) {
        userColumnSortingFiltered.push(columnId);
      }
    });

    userColumnSortingFiltered = filterColumnsByFeatures(
      userColumnSortingFiltered,
      sectionType,
      customColumns,
      loginContext?.user,
      appContext?.features,
      tagsMetadata ?? []
    );

    if (
      !isEqual(new Set(userColumnSortingFiltered), new Set(userColumnSorting))
    ) {
      setUserColumnSorting(userColumnSortingFiltered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customColumnsFiltered]);

  useEffect(() => {
    if (!isOpen) setTimeout(onClose, 300);
  }, [isOpen, onClose]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={toggleIsOpen}
        clickOutsideToClose
        unmountOnClose
        centered
        size="m"
      >
        <ModalHeader onClose={toggleIsOpen}>
          <div className={styles.headerBar}>
            <h5 className={clsx(typography.title5, styles.headerText)}>
              <Content id={ContentId.ColumnSettings} />
            </h5>
          </div>
        </ModalHeader>
        <ModalBody>
          <TableColumnsInput<T>
            userColumnSelection={userColumnSelection}
            onUserColumnSelectionChange={(newSelection) => {
              setUserColumnSelection(newSelection);
              setUserColumnIsDirty(true);
            }}
            userColumnSorting={userColumnSorting}
            onUserColumnSortingChange={(newSorting) => {
              setUserColumnSorting(newSorting);
              setUserColumnIsDirty(true);
            }}
            userEditableColumns={userEditableColumns}
            onUserEditableColumnsChange={(newEditableColumns) => {
              setUserEditableColumns(newEditableColumns);
              setUserColumnIsDirty(true);
            }}
            customColumns={customColumnsFiltered}
            onCustomColumnsChange={setCustomColumns}
            reportingTags={tagsMetadata ?? []}
            enableConfigureEditability
            sectionType={sectionType}
            showTicketClassColor={showTicketClassColor}
            viewMode={viewMode}
          />
        </ModalBody>
        <ModalFooter>
          <CancelButton
            textContentId={ContentId.Cancel}
            onClick={toggleIsOpen}
          />
          <OkButton onClick={onSaveHandler} textContentId={ContentId.Save} />
        </ModalFooter>
      </Modal>
    </>
  );
}
