import { useCallback, useContext } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { useSellerRoleContext } from 'src/contexts/SellerRoleContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Button, Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { DeleteIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  SellerAccountClient,
  SellerRoleInfo,
} from 'src/WebApiController';

export const LaunchDeleteRole = ({
  roleInfo,
  disabled,
  setIsLoading,
}: {
  roleInfo: SellerRoleInfo;
  disabled?: boolean;
  setIsLoading?: (isLoading: boolean) => void;
}) => {
  const { closeModal } = useContext(ModalContext);
  const { showErrorDialog, genericError } = useErrorBoundaryContext();

  const { activeAccountWebClientConfig } = useAppContext();
  const { refetchSellerRoleInfos } = useSellerRoleContext();
  const { allUserInfos } = useSellerAccountContext();

  const userCount = Object.values(allUserInfos ?? {}).filter((u) =>
    u.roles.includes(roleInfo.id)
  ).length;

  const dialogProps = useBasicDialog();

  const onDeleteRole = useCallback(async () => {
    setIsLoading?.(true);
    dialogProps.closeDialog();

    await tryInvokeApi(
      async () => {
        const r = await new SellerAccountClient(
          activeAccountWebClientConfig
        ).deleteSellerRole(roleInfo.id);

        if (isSuccess(r)) {
          refetchSellerRoleInfos();
          closeModal(true);
        } else {
          showErrorDialog(
            'SellerAccountClient.deleteSellerRole',
            {
              message: r.message ?? genericError,
              status: r.status!,
            } as ApiException,
            {
              trackErrorData: {
                roleInfo,
              },
            }
          );
        }
      },
      (error) => {
        showErrorDialog('SellerAccountClient.deleteSellerRole', error);
      },
      () => setIsLoading?.(false)
    );
  }, [
    activeAccountWebClientConfig,
    closeModal,
    dialogProps,
    genericError,
    refetchSellerRoleInfos,
    roleInfo,
    setIsLoading,
    showErrorDialog,
  ]);

  return (
    <>
      <Button
        variant="text"
        textColor="error"
        onClick={() => dialogProps.launchDialog()}
        disabled={disabled}
      >
        <Stack gap="m">
          <DeleteIcon withHoverEffect fill={IconsFill.currentColor} />
          <Content id={ContentId.DeleteRole} />
        </Stack>
      </Button>

      <ConfirmDialog
        headerText={<Content id={ContentId.DeleteRole} />}
        bodyText={
          userCount > 0 ? (
            <WarningMessage
              message={<Content id={ContentId.RoleWithUsersCannotBeDeleted} />}
            />
          ) : (
            <Content id={ContentId.AreYouSure} />
          )
        }
        {...dialogProps.dialogProps}
        centered
        disabled={disabled}
        onOkay={userCount > 0 ? undefined : onDeleteRole}
        onClosed={dialogProps.closeDialog}
        onCancel={dialogProps.closeDialog}
      />
    </>
  );
};
