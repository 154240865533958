import { DefaultTheme } from 'styled-components/macro';

import { Colors } from './constants/colors';
import { PurpleTheme } from './legacy';
import {
  ThemeColors,
  ThemeMediaQuery,
  ThemeTypography,
} from './legacy/theme-types';

type CustomDefaultTheme = DefaultTheme & {
  name: string;
  rounded: boolean;
  floatingInputLabel: boolean;
  filledInLoading: boolean;
  colors: ThemeColors;
  typography: ThemeTypography;
  spacing: (...multipliers: number[]) => string;
  media: ThemeMediaQuery;
  logoPath: string;
};

const OldTheme = PurpleTheme as CustomDefaultTheme;

export const TicketUtilLegacyTheme: CustomDefaultTheme = {
  ...OldTheme /* We will slowly remove this as will fill in all the blanks */,
  colors: {
    ...OldTheme.colors,
    primary100: Colors.tuBlue100,
    primary200: Colors.tuBlue200,
    primary300: Colors.tuBlue300,
    primary400: Colors.tuBlue400,
    primary500: Colors.tuBlue500,
    primary600: Colors.tuBlue600,
    borderPrimary: Colors.neutral500,
    borderActive: Colors.tuBlue100,
    borderDark: Colors.neutral400,
    backgroundSecondary: Colors.neutral600,
    backgroundBrandActive: Colors.tuBlue100,
    backgroundBrandLight: Colors.tuBlue600,
    backgroundBrandHover: Colors.tuBlue200,
    backgroundDark: Colors.tuBlue025,
    textBrand: Colors.tuBlue100,
    textBrandHover: Colors.tuBlue200,
    textOnBrandBgLink: Colors.tuBlue600,
  },
};
