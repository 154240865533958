import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef, ReactNode } from 'react';
import { mergeProps } from 'src/core/utils';

import { Stack } from '../Stack';
import * as styles from './RadioGroup.css';

export const RadioGroup = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof RadixRadioGroup.Root>
>(function Root({ ...props }, ref) {
  return <RadixRadioGroup.Root {...props} ref={ref} />;
});

export const Radio = forwardRef<
  HTMLButtonElement,
  ComponentPropsWithoutRef<typeof RadixRadioGroup.Item> & { label: ReactNode }
>(function Root({ label, value, disabled, ...props }, ref) {
  return (
    <Stack gap="m">
      <RadixRadioGroup.Item
        ref={ref}
        {...mergeProps(
          {
            className: styles.radioItem,
          },
          props
        )}
        value={value}
        id={value}
      >
        <RadixRadioGroup.Indicator
          className={clsx(styles.radioIndicator, {
            [styles.radioIndicatorDisabled]: disabled,
          })}
        />
      </RadixRadioGroup.Item>
      <label className={styles.radioLabel} htmlFor={value}>
        {label}
      </label>
    </Stack>
  );
});
