import { Content } from 'src/contexts/ContentContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { EventConfigScoreOverride } from 'src/WebApiController';

import * as styles from './EventSeatMapSelection.css';
import { GlobalConfigPayloadCard } from './GlobalConfigPayloadCard';

type GlobalConfigPayloadSelectionProps = {
  onConfigPayloadSelection: (
    eventConfigScoreOverride: EventConfigScoreOverride | null,
    defaultConfigPayload?: string | null
  ) => void;
  eventConfigScoreOverridesForEvent: EventConfigScoreOverride[] | undefined;
};

export function GlobalConfigPayloadSelection({
  onConfigPayloadSelection,
  eventConfigScoreOverridesForEvent,
}: GlobalConfigPayloadSelectionProps) {
  const { sellerAccountScoreOverridesQuery } = useEventMapContext();
  const sellerConfigPayloadsFromOtherVenueConfigs =
    sellerAccountScoreOverridesQuery.data?.filter(
      (eventConfigScoreOverride) =>
        !eventConfigScoreOverridesForEvent?.some((e) => {
          return e.id == eventConfigScoreOverride.eventConfigScoreOverrideId;
        })
    ) ?? [];

  return sellerConfigPayloadsFromOtherVenueConfigs?.length > 0 ? (
    <div className={styles.sellerAccountSelectorContainer}>
      <div className={styles.selectionHeaderText}>
        <Content id={ContentId.CreateNewFromTemplateConfigPayload} />
      </div>
      <Stack
        direction="column"
        gap="l"
        width="full"
        alignItems="center"
        className={styles.sellerAccountConfigPayloadsContainer}
      >
        {sellerConfigPayloadsFromOtherVenueConfigs.map(
          (eventConfigScoreOverride) => {
            return (
              <GlobalConfigPayloadCard
                key={eventConfigScoreOverride.eventConfigScoreOverrideId}
                name={eventConfigScoreOverride.name}
                eventId={eventConfigScoreOverride.eventId}
                configPayload={eventConfigScoreOverride.configPayload}
                onClick={onConfigPayloadSelection}
                createDate={new Date(eventConfigScoreOverride.createDate)}
                updateDate={new Date(eventConfigScoreOverride.updateDate)}
              />
            );
          }
        )}
      </Stack>
    </div>
  ) : undefined;
}
