import clsx from 'clsx';
import { FormatContent } from 'src/contexts/ContentContext';
import { FormatContentId } from 'src/utils/constants/formatContentId';

import * as styles from './HorizontalTextList.css';

export function HorizontalTextList({
  className,
  texts,
  preIcon: prefix,
  maxTextsToShow: textCountToShow = 1,
  maxTextsToShowOnHover: textCountToShowTitle = 1,
}: {
  className?: string;
  texts: string[];
  preIcon?: JSX.Element;
  maxTextsToShow?: number;
  maxTextsToShowOnHover?: number;
}) {
  const textToDisplay = texts.slice(0, textCountToShow).join(', ');
  const titleTextToDisplay = texts.slice(0, textCountToShowTitle).join(', ');
  return (
    <div
      className={clsx(styles.horizontalTextListContainer, className)}
      title={titleTextToDisplay}
    >
      {prefix && (
        <div className={styles.horizontalTextListPreIconContainer}>
          {prefix}
        </div>
      )}
      <div className={styles.horizontalTextListTextContainer}>
        {textToDisplay}
      </div>
      {texts.length > textCountToShow && (
        <div className={styles.horizontalTextListMoreContainer}>
          <FormatContent
            id={FormatContentId.AndXMore}
            params={`${texts.length - textCountToShow}`}
          />
        </div>
      )}
    </div>
  );
}
