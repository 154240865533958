import { Column } from '@tanstack/react-table';
import { CSSProperties } from 'react';

export const itemSizeDefault = (el: HTMLElement) =>
  el.getBoundingClientRect().height;

export const getCommonPinningStyles = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  column: Column<any, unknown>
): CSSProperties => {
  const isPinned = column.getIsPinned();
  return {
    left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
    right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    zIndex: 0,
    background: isPinned ? 'inherit' : undefined,
  };
};
