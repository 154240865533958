import * as styles from './AdGroupCreate.css';
interface AdGroupSelectErrorProps {
  error?: string;
}

export const AdGroupSelectError = ({ error }: AdGroupSelectErrorProps) => {
  return (
    <>
      {error && (
        <div className={styles.errorsContainer}>
          <div className={styles.error}>{error}</div>
        </div>
      )}
    </>
  );
};
