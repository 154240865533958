import { ColumnDef } from '@tanstack/react-table';
import { ListingWithEvent } from 'src/tables/ListingTable/ListingTable.types';
import { Feature } from 'src/WebApiController';

export type ColumnPersonalizationFlags = {
  /**
   * Determines whether user should be able to toggle visibility of a column. Still
   * allows for user to adjust ordering of column when true.
   * DEFAULT: false
   */
  isRequired: boolean;
  /**
   * Whether the setting should appear in the configuration setting
   * If false, ensure column is always enabled
   * DEFAULT: true
   */
  isConfigurable: boolean;
  /**
   * Whether the field can be toggled between editable and read-only
   * DEFAULT: false
   */
  isEditabilityConfigurable: boolean;
  /**
   * Identifies a column as numeric AND whether numeric precision should be applied to the data.
   * DEFAULT: false
   */
  isNumeric: boolean;
  /**
   * Identifies a column that can be displayed as icon or enum value
   * DEFAULT: false
   */
  isEnumWithIcon: boolean;
  /**
   * Whether a column should be hidden in lieu of a user preference set (will be used for autopricing)
   * DEFAULT: false
   */
  isHiddenByDefault: boolean;
  /* Whether the number is a currency
   * DEFAULT: false
   */
  isCurrency: boolean;
  /* Whether the number is a percent
   * DEFAULT: false
   */
  isPercent: boolean;
  /**
   * Whether should not expect decimals
   *
   * Default: `false`
   */
  isInteger: boolean;
  /**
   * Timestamp/DateTime
   */
  isDateTime: boolean;
  /**
   * Requires these Features to show and configure
   * DEFAULT: empty
   */
  requiredFeatures: Readonly<Feature[]>;
  /**
   * List of view modes that the column is visible in, defaults to all view modes if empty
   * currently only used for PurchaseTable columns
   * DEFAULT: empty
   */
  visibleViewModes: Readonly<string[]>;
};

export const PERSONALIZATION_DEFAULTS: ColumnPersonalizationFlags = {
  isRequired: false,
  isConfigurable: true,
  isEditabilityConfigurable: false,
  isNumeric: false,
  isEnumWithIcon: false,
  isInteger: false,
  isHiddenByDefault: false,
  isCurrency: false,
  isDateTime: false,
  isPercent: false,
  requiredFeatures: [],
  visibleViewModes: [],
};

export type GetColumnConfigById<T> = {
  personalization: ColumnPersonalizationFlags;
  definition: ColumnDef<T | null> | undefined;
};

export type GetListingColumnConfigById = {
  personalization: ColumnPersonalizationFlags;
  definition: ColumnDef<ListingWithEvent | null> | undefined;
};
