import { useQuery } from '@tanstack/react-query';
import {
  ConversationType,
  convertEmailClassificationTypeToConversationType,
} from 'src/components/Messages/ConversationSidePanel/ConversationListItem/ConversationListItem';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SomethingWentWrong } from 'src/views';
import {
  AutoPoClient,
  AutoPoInboundEmailClassificationType,
} from 'src/WebApiController';

import * as styles from './EmailThread.css';
import { EmailThreadItem } from './EmailThreadItem';

export type EmailThreadRequest = {
  inboundEmailId: number;
  purchaseOrderId: number | null;
  saleId: number | null;
  toEmailAddress: string | null;
  classificationType: AutoPoInboundEmailClassificationType | null;
};

export type EmailThreadProps = EmailThreadRequest & {
  // other UI only props here
};

export function EmailThread(request: EmailThreadProps) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const threadQuery = useQuery({
    queryKey: ['emailThread', request],
    queryFn() {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return tryInvokeApi(
        async () => {
          const {
            inboundEmailId,
            purchaseOrderId,
            saleId,
            toEmailAddress,
            classificationType,
          } = request;
          if (purchaseOrderId) {
            return new AutoPoClient(
              activeAccountWebClientConfig
            ).getPurchaseInboundEmailThread(purchaseOrderId, true);
          }
          if (saleId) {
            return new AutoPoClient(
              activeAccountWebClientConfig
            ).getSaleInboundEmailThread(saleId, true);
          }
          if (
            classificationType &&
            convertEmailClassificationTypeToConversationType(
              classificationType
            ) === ConversationType.Private &&
            toEmailAddress
          ) {
            return new AutoPoClient(
              activeAccountWebClientConfig
            ).getPrivateInboundEmailThread(
              toEmailAddress,
              classificationType,
              true
            );
          }
          return new AutoPoClient(
            activeAccountWebClientConfig
          ).getSingleInboundEmailThread(inboundEmailId);
        },
        (error) => {
          trackError('AutoPoClient.getSingleInboundEmailThread', error, {
            request: JSON.stringify(request),
          });
        }
      );
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
  });

  if (threadQuery.isLoading) {
    return <PosSpinner />;
  }
  if (threadQuery.isError) {
    return <SomethingWentWrong></SomethingWentWrong>; // TODO
  }
  return (
    <div>
      {threadQuery.data?.map((email) => {
        return (
          <div key={email.inboundEmailId} className={styles.threadItem}>
            <EmailThreadItem email={email} />
          </div>
        );
      })}
    </div>
  );
}
