import { FieldError } from 'react-hook-form';
import { RowSelector } from 'src/components/Selectors/SectionRowSelector';
import { useContent } from 'src/contexts/ContentContext';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { ContentId } from 'src/utils/constants/contentId';
import { RowInfo, SectionInfo } from 'src/WebApiController';

export const VenueRowInput = ({
  row,
  selectedRow,
  selectedSection,
  disabled,
  fieldError,
  onChange,
}: {
  row?: string | null;
  selectedRow?: RowInfo | null;
  selectedSection?: SectionInfo | null;
  disabled?: boolean;
  fieldError?: string | string[] | FieldError | FieldError[];
  onChange: (rowName?: string | null, row?: RowInfo | null) => void;
}) => {
  selectedRow =
    selectedRow ?? selectedSection?.rows?.find((r) => r.name === row);

  const chooseRow = useContent(ContentId.ChooseRow);

  return selectedSection?.rows?.filter((r) => !r.isSpeculative)?.length ? (
    <RowSelector
      style={{ width: '100%' }}
      disabled={disabled}
      hasErrors={Boolean(fieldError)}
      value={selectedRow?.id?.toString() ?? '-1'}
      displayText={selectedRow?.name || row || chooseRow}
      selectedSection={selectedSection}
      onChange={(rowId) => {
        const id = parseInt(rowId);
        const row = selectedSection?.rows?.find((r) => r.id === id);
        onChange(row?.name ?? rowId, row);
      }}
    />
  ) : (
    <PosTextField
      disabled={disabled}
      rootProps={{
        state: getTextFieldState(fieldError),
      }}
      spellCheck={false}
      value={
        (selectedRow?.isSpeculative ? row : selectedRow?.name) || row || ''
      }
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};
