import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { hasFeature } from 'src/utils/userUtils';
import { Feature } from 'src/WebApiController';

export const useUserHasFeature = (feature: Feature) => {
  const { appContext, loginContext } = useAppContext();

  const hasFeatureResult = useMemo(
    () => hasFeature(loginContext?.user, appContext?.features, feature),
    [appContext?.features, feature, loginContext?.user]
  );

  return hasFeatureResult;
};
