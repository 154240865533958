import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { useGetCompListings } from 'src/hooks/useGetCompListings';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { ListingDetails } from 'src/WebApiController';

import { ViewCompsBody } from './ViewCompsBody';

export const ViewCompsModal = ({ cancelTo }: { cancelTo?: ModalProps }) => {
  const { loadingState } = useEventItemLoadingDisplay<ListingDetails>(
    FormatContentId.LoadingListingId,
    FormatContentId.SearchingForListingId,
    FormatContentId.CouldNotFindListingId
  );

  const { event, posEntity: listing } =
    useActivePosEntityContext<ListingDetails>();

  const { showRelatedListings, setShowRelatedListings, compListingsQuery } =
    useGetCompListings(event, listing?.id);

  if (loadingState) return loadingState;

  return (
    <>
      <CancellableFormHeader cancelTo={cancelTo}>
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.ViewComps} />}
        />
      </CancellableFormHeader>

      {compListingsQuery.isLoading ? (
        <div style={{ height: '100%' }}>
          <PosSpinner />
        </div>
      ) : (
        <ModalBody style={{ padding: vars.spacing.xl }}>
          <ViewCompsBody
            listing={listing}
            compListings={compListingsQuery.data}
            showRelatedListings={showRelatedListings}
            setShowRelatedListings={setShowRelatedListings}
          />
        </ModalBody>
      )}

      <ModalFooter>
        <CancellableFormFooter cancelTo={cancelTo}></CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
