import { isEqual } from 'lodash-es';
import { PurchaseEventOverride } from 'src/WebApiController';

export const hasNoChangeOrIsDeleteOnly = (
  purchaseEventOverrides: PurchaseEventOverride[] | null | undefined,
  formValuesettings: PurchaseEventOverride[] | null | undefined
): boolean => {
  // Basically return true if every setting in the formValuesettings is contained within the purchaseEventOverrides
  if (!formValuesettings?.length) {
    return true;
  }

  for (const formValueSetting of formValuesettings) {
    const matchingSetting = purchaseEventOverrides?.find(
      (setting) => setting.vendorId === formValueSetting.vendorId
    );

    if (!matchingSetting) {
      return false;
    }

    if (!isEqual(matchingSetting, formValueSetting)) {
      return false;
    }
  }

  return true;
};
