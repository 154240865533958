export enum TimeSeriesLineChartUnit {
  Number = 'number',
  Price = 'price',
}

export type TimeSeriesLineChartUnitType =
  | {
      type: TimeSeriesLineChartUnit.Number;
    }
  | {
      type: TimeSeriesLineChartUnit.Price;
      currency: string;
    };
