import { useContext } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

import { AddTeamModal } from './EditTeam/AddTeamModal';
import { TeamsTable } from './TeamsTable';
import * as styles from './UsersManagement.css';

export const Teams = () => {
  const { setModal, closeModal } = useContext(ModalContext);
  const hasTeamManagementFeature = useUserHasFeature(Feature.TeamManagement);
  if (!hasTeamManagementFeature) return null;

  return (
    <Stack className={styles.root} direction="column" gap="l">
      <Stack justifyContent="spaceBetween" alignItems="center">
        <div className={styles.title}>
          <Content id={ContentId.Teams} />
        </div>
        <Button
          variant="outline"
          style={{ alignSelf: 'flex-end', textTransform: 'capitalize' }}
          onClick={() =>
            setModal({
              children: <AddTeamModal onClose={closeModal} />,
              clickOutsideToClose: true,
              centered: true,
              size: 'sm',
            })
          }
        >
          <PlusIcon fill={IconsFill.currentColor} size={vars.iconSize.s} />
          <Content id={ContentId.AddNewTeam} />
        </Button>
      </Stack>
      <TeamsTable />
    </Stack>
  );
};
