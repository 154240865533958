import { isEmpty } from 'lodash-es';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { BLOCKED_PRIORITY } from 'src/utils/ticketTypeUtils';
import {
  CompListingQuantityFilters,
  DeliveryType,
  SyncCenterSettings,
  TicketType,
  TicketTypePriorityOrder,
  TicketTypeRule,
} from 'src/WebApiController';

import { SyncCenterFieldValues } from './SyncCenter.types';

export const getTicketTypePriorityOptions = (len: number) => {
  const options: { [key: string]: string } = {
    [ContentId.ResetAll]: ContentId.ResetAll,
    [ContentId.OnlyThis]: ContentId.OnlyThis,
    [`${BLOCKED_PRIORITY}`]: ContentId.Blocked,
  };
  for (let i = 1; i <= len; i++) {
    const order = i.toString();
    options[order] = order;
  }
  return options;
};

export const getListingPackQuantityOptions = (len: number) => {
  const options: { [key: string]: string } = {};
  for (let i = 1; i <= len; i++) {
    options[i] = `${i}+`;
  }
  return options;
};

export function toSyncCenterFieldValues({
  ticketTypeRules,
  ...settings
}: SyncCenterSettings): SyncCenterFieldValues {
  const fieldValues: SyncCenterFieldValues = { ...settings };
  fieldValues.ticketTypeRules = {};
  if (ticketTypeRules) {
    for (const { deliveryType, ticketTypePriorityOrders } of ticketTypeRules) {
      if (deliveryType != null) {
        const ticketTypePriorityOrderMap: {
          [key in TicketType]?: number;
        } = {};
        for (const {
          ticketType,
          priorityOrder,
          blocked = false,
        } of ticketTypePriorityOrders) {
          if (blocked) {
            ticketTypePriorityOrderMap[ticketType] = BLOCKED_PRIORITY;
          } else if (priorityOrder != null) {
            ticketTypePriorityOrderMap[ticketType] = priorityOrder;
          }
        }
        fieldValues.ticketTypeRules[deliveryType] = ticketTypePriorityOrderMap;
      }
    }
  }
  /* JavaScript has a bug where 0.07 * 100 = 7.00000000001 (and various similar problems exists)
    The way is to realize how many maximum decimals we need and just round about that out
  */
  if (fieldValues.priceFloor) {
    fieldValues.priceFloor = roundToPrecision(fieldValues.priceFloor! * 100, 2);
  }
  if (fieldValues.maxDiscountVelocityPercent) {
    fieldValues.maxDiscountVelocityPercent = roundToPrecision(
      fieldValues.maxDiscountVelocityPercent! * 100,
      2
    );
  }
  if (fieldValues.relativeFloor) {
    fieldValues.relativeFloor = roundToPrecision(
      fieldValues.relativeFloor! * 100,
      2
    );
  }
  if (fieldValues.relativeCeiling) {
    fieldValues.relativeCeiling = roundToPrecision(
      fieldValues.relativeCeiling! * 100,
      2
    );
  }
  if (fieldValues.marketplaceSettings) {
    for (let i = 0; i < fieldValues.marketplaceSettings.length; i++) {
      if (fieldValues.marketplaceSettings[i].markup) {
        fieldValues.marketplaceSettings[i].markup = roundToPrecision(
          fieldValues.marketplaceSettings[i].markup! * 100,
          3
        );
      }

      if (fieldValues.marketplaceSettings[i].sellerFee) {
        fieldValues.marketplaceSettings[i].sellerFee = roundToPrecision(
          fieldValues.marketplaceSettings[i].sellerFee! * 100,
          3
        );
      }
    }
  }

  if (fieldValues.defaultQuantityFilters) {
    for (let i = 0; i < fieldValues.defaultQuantityFilters.length; i++) {
      // Just in case, if for some reason a seller account is missing quantity values or ordinal values,
      // configure the values for them. This shouldn't ever happen but putting this logic because for every
      // seller account that hasn't ever configured a defaultQuantityFilter payload, we do the initial payload
      // setup below
      if (fieldValues.defaultQuantityFilters[i].quantity == null) {
        fieldValues.defaultQuantityFilters[i].ordinal = i;
        fieldValues.defaultQuantityFilters[i].quantity = 2;
      }

      if (fieldValues.defaultQuantityFilters[i].ordinal == null) {
        fieldValues.defaultQuantityFilters[i].ordinal = i;
      }
    }
  } else {
    // If a seller has never configured any default quantity filters, then we need
    // to configure the json for them with ordinals and a default quantity of 2. After
    // this has been done we shouldn't ever need to do it again for that selller account.
    // We do 10 filters because product is requesting we support packs of up to 10
    fieldValues.defaultQuantityFilters = [];
    for (let i = 0; i < 10; i++) {
      if (i == 0) {
        fieldValues.defaultQuantityFilters.push({
          ordinal: i,
          quantity: 1,
        });
      } else {
        fieldValues.defaultQuantityFilters.push({
          ordinal: i,
          quantity: 2,
        });
      }
    }
  }

  if (isEmpty(fieldValues.compListingQuantityFilters)) {
    const exactMatchQuantityFilters: CompListingQuantityFilters[] = [];
    for (let i = 1; i <= 9; i++) {
      if (i == 1) {
        exactMatchQuantityFilters.push({
          posListingQuantity: i,
          compQuantityFilters: {
            exactQuantities: [],
            greaterThanOrEqualToQuantity: 1,
          },
        });
      } else {
        exactMatchQuantityFilters.push({
          posListingQuantity: i,
          compQuantityFilters: {
            exactQuantities: [],
            greaterThanOrEqualToQuantity: 2,
          },
        });
      }
    }
    const greaterThanOrEqualToQuantityFilter = {
      posListingQuantity: 10,
      compQuantityFilters: {
        exactQuantities: [],
        greaterThanOrEqualToQuantity: 2,
      },
    };
    fieldValues.compListingQuantityFilters = {
      exactMatchQuantityFilters,
      greaterThanOrEqualToQuantityFilter,
    };
  }

  return fieldValues;
}

export function toSyncCenterSettings(
  { ticketTypeRules, ...fieldValues }: SyncCenterFieldValues,
  hasPricingMultiSelectCompQuantitiesFeature = false
): SyncCenterSettings {
  const settingsTicketTypeRules: TicketTypeRule[] = [];
  if (ticketTypeRules) {
    for (const [key, ticketTypePriorityOrderMap] of Object.entries(
      ticketTypeRules
    )) {
      if (ticketTypePriorityOrderMap) {
        const ticketTypePriorityOrders: TicketTypePriorityOrder[] = [];
        for (const [key, value] of Object.entries(ticketTypePriorityOrderMap)) {
          if (value) {
            ticketTypePriorityOrders.push({
              ticketType: key as TicketType,
              priorityOrder: value === -1 ? null : value,
              blocked: value === -1 ? true : null,
            });
          }
        }
        if (ticketTypePriorityOrders.length > 0) {
          settingsTicketTypeRules.push({
            deliveryType: key as DeliveryType,
            ticketTypePriorityOrders,
          });
        }
      }
    }
  }
  /* Again, JavaScript has a bug where 0.7 * 0.01 = 0.006999999999999999 (and various similar problems exists)
    The way is to realize how many maximum decimals we need (e.g. check in DB) and just round about that out
  */
  if (fieldValues.priceFloor) {
    fieldValues.priceFloor = roundToPrecision(
      fieldValues.priceFloor! * 0.01,
      4
    );
  }
  if (fieldValues.maxDiscountVelocityPercent) {
    fieldValues.maxDiscountVelocityPercent = roundToPrecision(
      fieldValues.maxDiscountVelocityPercent! * 0.01,
      4
    );
  }
  if (fieldValues.relativeFloor) {
    fieldValues.relativeFloor = roundToPrecision(
      fieldValues.relativeFloor! * 0.01,
      4
    );
  }
  if (fieldValues.relativeCeiling) {
    fieldValues.relativeCeiling = roundToPrecision(
      fieldValues.relativeCeiling! * 0.01,
      4
    );
  }
  if (fieldValues.marketplaceSettings) {
    for (let i = 0; i < fieldValues.marketplaceSettings.length; i++) {
      if (fieldValues.marketplaceSettings[i].markup) {
        fieldValues.marketplaceSettings[i].markup = roundToPrecision(
          fieldValues.marketplaceSettings[i].markup! * 0.01,
          5
        );
      }
      if (fieldValues.marketplaceSettings[i].sellerFee) {
        fieldValues.marketplaceSettings[i].sellerFee = roundToPrecision(
          fieldValues.marketplaceSettings[i].sellerFee! * 0.01,
          5
        );
      }
    }
  }
  if (hasPricingMultiSelectCompQuantitiesFeature) {
    fieldValues.defaultQuantityFilters = null;
  } else {
    fieldValues.compListingQuantityFilters = null;
  }

  const settings: SyncCenterSettings = {
    ...fieldValues,
    ticketTypeRules: settingsTicketTypeRules,
  };
  return settings;
}

export const setValueAsNumber = (value: string) =>
  value === '' || value == null ? null : Number(value);
