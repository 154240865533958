import { clsx } from 'clsx';
import {
  ClassAttributes,
  ComponentProps,
  ComponentRef,
  createElement,
  forwardRef,
  FunctionComponent,
  NamedExoticComponent,
} from 'react';

type HTMLElementType<T extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[T] extends ClassAttributes<infer E> ? E : never;

/**
 * Use this to add a `className` to intrinsic jsx components like `div`.
 * @param type
 * @param className
 * @returns
 */
export function styled<T extends keyof JSX.IntrinsicElements>(
  type: T,
  className: string
) {
  return Object.assign(
    // eslint-disable-next-line react/display-name
    forwardRef<HTMLElementType<T>, ComponentProps<T>>(function (props, ref) {
      return createElement(type, {
        ref,
        ...props,
        className: clsx(className, props.className),
      });
    }),
    { displayName: `VanillaExtractStyled(${type})` }
  );
}

/**
 * Use this to add a `className` to react components.
 * @param Component
 * @param className
 * @returns
 */
export function styledComponent<P extends { className?: string }>(
  Component: FunctionComponent<P> | NamedExoticComponent<P>,
  className: string
) {
  const displayName = `VanillaExtractStyled(${
    Component?.displayName ?? Component.name
  })`;
  const addClassNameToProps = (props: P) => ({
    ...props,
    className: clsx(className, props.className),
  });
  return Object.assign(
    '$$typeof' in Component
      ? // eslint-disable-next-line react/display-name
        forwardRef<
          ComponentRef<typeof Component>,
          ComponentProps<typeof Component>
        >((props, ref) =>
          createElement(
            Component,
            Object.assign(addClassNameToProps(props), { ref })
          )
        )
      : (props: P) => createElement(Component, addClassNameToProps(props)),
    { displayName }
  );
}
