import { useCallback } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { BulkDeleteSeatSaversDialog } from 'src/dialogs/BulkEdits/BulkDeleteSeatSaversDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_DELETE_SEAT_SAVERS_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchDeleteSeatSavers } from './LaunchDeleteSeatSavers';

export const useBulkDeleteSeatSavers = (
  filterQueryWithEventIds: ListingQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext();

  const updateKey = event
    ? `inventory-delete-seat-savers-event-${event.viagVirtualId}`
    : INVENTORY_BULK_DELETE_SEAT_SAVERS_UPDATE_KEY;

  const {
    refreshCatalog,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkDeleteSeatSavers,
    closeDialog: closeBulkDeleteSeatSavers,
  } = useDialog(DialogId.BulkDeleteSeatSeavers, BulkDeleteSeatSaversDialog);

  const onSubmitDeleteSeatSavers = useCallback(
    async (
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      const client = new BulkEditListingClient(activeAccountWebClientConfig);
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await client.bulkDeleteSeatSaversPreview(
              filterQueryWithEventIds
            );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkDeleteSeatSaversPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await client.bulkDeleteSeatSavers(
              preview!.preview,
              updateKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Because delete listings can result in an empty event - so we need to reget catalog
                await refreshCatalog();

                setActivePosEntity(0);
                await refreshExpandedListItems();
              }
              closeBulkDeleteSeatSavers();
            }
          },
          (error) => {
            showErrorDialog('onSubmitDeleteSeatSavers', error, {
              trackErrorData: { preview, event },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkDeleteSeatSavers,
      event,
      filterQueryWithEventIds,
      refreshCatalog,
      refreshExpandedListItems,
      setActivePosEntity,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchDeleteSeatSavers
        key="onSubmitDeleteSeatSavers"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkDeleteSeatSavers({
            updateKey,
            onOkay: onSubmitDeleteSeatSavers,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkDeleteSeatSavers,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
