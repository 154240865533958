import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const HoldIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 21">
      <path
        d="M19 20.5C19.8284 20.5 20.5 19.8284 20.5 19C20.5 18.1716 19.8284 17.5 19 17.5C18.1716 17.5 17.5 18.1716 17.5 19C17.5 19.8284 18.1716 20.5 19 20.5Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 20.5C8.82843 20.5 9.5 19.8284 9.5 19C9.5 18.1716 8.82843 17.5 8 17.5C7.17157 17.5 6.5 18.1716 6.5 19C6.5 19.8284 7.17157 20.5 8 20.5Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 0.5H4L8 17.5H19"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49988 15.5H20.4999L23.4999 6.5H5.3999"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
