import { UserDetail } from 'src/WebApiController';

import {
  ActiveAccountDisplay,
  ActiveUserDisplay,
  UserInfoDisplayContainer,
} from './UserInfoDisplay.styled';

export const UserInfoDisplay = ({
  userInfo,
  showUserName = true,
}: {
  userInfo?: UserDetail | null;
  showUserName?: boolean;
}) => {
  const fullUserName = `${userInfo?.displayName}${
    userInfo?.impersonatorDisplayName
      ? ` (${userInfo?.impersonatorDisplayName})`
      : ''
  }`;

  const accountName = userInfo?.activeAccount?.accountName || '';
  return (
    <UserInfoDisplayContainer>
      <ActiveAccountDisplay>{accountName}</ActiveAccountDisplay>
      {showUserName && (
        <ActiveUserDisplay title={fullUserName}>
          {fullUserName}
        </ActiveUserDisplay>
      )}
    </UserInfoDisplayContainer>
  );
};
