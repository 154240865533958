import { useCallback, useEffect, useRef, useState } from 'react';
import { Content, useContent } from 'src/contexts/ContentContext';
import { Button, Stack, TextArea } from 'src/core/ui';
import { useSellerEventNote } from 'src/hooks/api/useSellerEventNote';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ContentId } from 'src/utils/constants/contentId';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { Permission } from 'src/WebApiController';

import * as styles from './NoteSectionV2.css';

export const NoteSectionV2 = ({
  eventId,
  disabled,
}: {
  eventId: number | null;
  disabled?: boolean;
}) => {
  const hasEventsEditPermission = useUserHasAnyOfPermissions(
    Permission.Events_Edit
  );

  const [newNote, setNewNote] = useState('');

  const { notes, addNote, isLoading } = useSellerEventNote(eventId);

  const handleKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter') {
        if (e.shiftKey) {
          e.preventDefault();
          setNewNote((prevNote) => prevNote + '\n');
        } else {
          e.preventDefault();
          addNote(newNote);
          setNewNote('');
        }
      }
    },
    [addNote, newNote]
  );

  const messagesStartRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    if (messagesStartRef.current) {
      messagesStartRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [notes]);

  return (
    <Stack direction="column" gap="s" className={styles.noteTabContainer}>
      <Stack
        direction="column"
        alignItems="end"
        gap="l"
        width="full"
        className={styles.inputContainer}
      >
        <TextArea.Root>
          <TextArea.Area
            maxLength={2000}
            disabled={!hasEventsEditPermission || disabled}
            className={styles.inputField}
            value={newNote}
            onChange={(e) => {
              setNewNote(e.target.value);
            }}
            onKeyDown={handleKeyPress}
            placeholder={useContent(ContentId.TypeYourNoteHere)}
          ></TextArea.Area>
        </TextArea.Root>
        <Button
          className={styles.inputButton}
          onClick={() => {
            addNote(newNote);
            setNewNote('');
          }}
          disabled={
            !hasEventsEditPermission ||
            disabled ||
            newNote.trim() === '' ||
            isLoading
          }
        >
          <Content id={ContentId.AddNote} />
        </Button>
      </Stack>
      <div className={styles.eventLogContainer}>
        {notes?.map((note) => {
          const localDateString = stringToUtcDate(
            note.createDate
          ).toLocaleString();

          return (
            <div
              key={note.sellerEventNoteId}
              className={styles.eventLogItemContainer}
            >
              <div className={styles.eventLogItemTitle}>
                {note.sellerDisplayName} • {localDateString}
              </div>
              <div className={styles.eventLogItemContent}>{note.content}</div>
            </div>
          );
        })}
        <div ref={messagesStartRef} />
      </div>
    </Stack>
  );
};
