import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ActivityLogClient, ActivityLogQuery } from 'src/WebApiController';

export const useGetActivityLogByFilter = (
  activityLogFilterQuery: ActivityLogQuery,
  getAuditData: boolean,
  disabled: boolean
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled && activeAccountWebClientConfig.activeAccountId != null;
  const activityLogQuery = useQuery({
    queryKey: [
      'ActivityLogClient.getActivityLogByFilter',
      activeAccountWebClientConfig.activeAccountId,
      activityLogFilterQuery,
      getAuditData,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      if (getAuditData) {
        return await new ActivityLogClient(
          activeAccountWebClientConfig
        ).getActivityLogWithAuditByFilter(activityLogFilterQuery);
      }

      return await new ActivityLogClient(
        activeAccountWebClientConfig
      ).getActivityLogByFilter(activityLogFilterQuery);
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('ActivityLogClient.getActivityLogByFilter', error, {
          itemId: activityLogFilterQuery.entityId,
          entityType: activityLogFilterQuery.entityType,
          activityLogFilterQuery,
        });
      },
    },
    enabled: shouldQuery,
  });

  return { activityLogQuery };
};
