import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { Event, EventConfigClient } from 'src/WebApiController';

const DEFAULT_TOP_N_TRANSACTIONS = 10;

export const useGetMarketTransactionsForEvent = (
  event?: Event | null,
  topN = DEFAULT_TOP_N_TRANSACTIONS
) => {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    event?.viagId != null;
  const transactionsQuery = useQuery({
    queryKey: [
      'EventConfigClient.getMarketTransactionsForEvent',
      activeAccountWebClientConfig,
      event?.viagId,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new EventConfigClient(
        activeAccountWebClientConfig
      ).getMarketTransactionsForEvent(event!.viagId!, topN);
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('EventConfigClient.getMarketTransactionsForEvent', error, {
          trackErrorData: {
            viagogoEventId: event?.viagId,
          },
        });
      },
    },
  });

  return {
    transactionsQuery,
  };
};
