import { debounce } from 'lodash-es';
import React, { createContext, useCallback, useContext, useState } from 'react';

export interface ISignUpContext {
  hasSignedUp: boolean;
  isSignupSubmitted: boolean;
  setIsSignupSubmitted: (hasSignedUp: boolean) => void;

  hasCheckedSignUpRedirect: boolean;
  setHasCheckedSignUpRedirect: (setHasCheckedSignUpRedirect: boolean) => void;
}

const defaultSignUpContext: ISignUpContext = {
  hasSignedUp: false,
  isSignupSubmitted: false,
  setIsSignupSubmitted: () => undefined,
  hasCheckedSignUpRedirect: false,
  setHasCheckedSignUpRedirect: () => undefined,
};

export const SignUpContext =
  createContext<ISignUpContext>(defaultSignUpContext);

export const useSignUpContext = () => useContext(SignUpContext);

export const SignUpContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isSignupSubmitted, setIsSignupSubmitted] = useState(false);
  const [hasSignedUp, setHasSignedUp] = useState(false);
  const [hasCheckedSignUpRedirect, setHasCheckedSignUpRedirect] =
    useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetHasSignedUp = useCallback(
    debounce((value: boolean) => {
      setHasSignedUp(value);
    }, 500),
    []
  );

  const handleSetIsSignupSubmitted = useCallback(() => {
    setIsSignupSubmitted(true);
    debouncedSetHasSignedUp(true);
  }, [debouncedSetHasSignedUp]);

  return (
    <SignUpContext.Provider
      value={{
        hasSignedUp,
        isSignupSubmitted,
        setIsSignupSubmitted: handleSetIsSignupSubmitted,
        hasCheckedSignUpRedirect,
        setHasCheckedSignUpRedirect,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};
