import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { CalendarProps } from 'react-date-range';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import * as selectorStyles from 'src/core/POS/DateRangeSelector/DateRangeSelector.css';
import {
  CustomDateRangeButton,
  DateRangePickerContainer,
  DateRangeTrigger,
  DateRangeTriggerContent,
  IconContainer,
} from 'src/core/POS/DateRangeSelector/DateRangeSelector.styled';
import { vars } from 'src/core/themes';
import { Popover, Stack } from 'src/core/ui';
import { CalendarIcon, CrossIcon, ExpandIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  formatDate,
  tryGetDatePresetSuggestionText,
} from 'src/utils/dateTimeUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';

import * as styles from './DatePicker.css';
import { DatePickerInput } from './DatePickerInput';
import {
  DatePickerPresets,
  DatePickerPresetsProp,
} from './DatePickerPresets/DatePickerPresets';

export type DateSelectorProps = Omit<DatePickerPresetsProp, 'onClick'> &
  Omit<CalendarProps, 'onChange'> & {
    disabled?: boolean;
    defaultDate?: Date;
    placeholderText?: string;
    onChange: (date?: Date, presetName?: string) => void;
    /**
     * If true, the site's timezone will be applied to the date being displayed.
     * the underlying date value (value, data, onDateChange) will not be changed.
     */
    useSiteTimeZone?: boolean;
  } & Omit<CalendarProps, 'onChange'>;

export function DateSelector({
  date,
  defaultDate,
  onChange,
  placeholderText,
  disabled,
  className,
  dateDisplayFormat = 'MM/dd/yyyy',
  color = vars.color.backgroundHighlight,
  locale = getLocaleFromLanguageOrCurrent(),
  useSiteTimeZone = true,
  ...presetProps
}: DateSelectorProps) {
  const { toZonedTime, timeZone } = useSiteTimezoneContext();
  const [isOpen, setIsOpen] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const placeholderTextContent = useContent(placeholderText);

  const defaultDateDisplayText = useContent(ContentId.DateRangeDisplayText);

  const onDatePickerChange = useCallback(
    (date?: Date, presetName?: string) => {
      if (!date) {
        onChange(undefined);
        return;
      }
      onChange(date, presetName);
    },
    [onChange]
  );

  const onClear = useCallback(() => {
    onDatePickerChange(defaultDate ? new Date(defaultDate) : undefined);
  }, [defaultDate, onDatePickerChange]);

  const onSuggestionClick = (date: Date, presetName?: string) => {
    onDatePickerChange(date, presetName);
    setIsOpen(false);
  };

  const getDisplayText = useCallback(() => {
    const suggestionText = tryGetDatePresetSuggestionText(
      date,
      presetProps.targetDate,
      presetProps.inHandDaysBeforeEvent,
      useSiteTimeZone ? toZonedTime : undefined
    );

    if (suggestionText) {
      return suggestionText;
    }

    if (date) {
      return `${formatDate(
        (useSiteTimeZone ? toZonedTime(date) : date).toISOString(),
        undefined,
        timeZone
      )}`;
    }

    return placeholderText ? placeholderTextContent : defaultDateDisplayText;
  }, [
    date,
    presetProps.targetDate,
    presetProps.inHandDaysBeforeEvent,
    placeholderText,
    placeholderTextContent,
    defaultDateDisplayText,
    useSiteTimeZone,
    toZonedTime,
    timeZone,
  ]);

  const highlightDropBox = !(
    (date ?? undefined) === (defaultDate ?? undefined)
  );

  return (
    <Popover.Root
      open={isOpen}
      onOpenChange={(isOpen: boolean) => {
        setIsOpen(isOpen);
        if (!isOpen) {
          setShowDatePicker(false);
        }
      }}
    >
      <Popover.Trigger asChild>
        <DateRangeTrigger isActive={highlightDropBox} disabled={disabled}>
          <DateRangeTriggerContent>
            <div
              className={
                !date ? styles.noValueSelectedColor : styles.selectedValueColor
              }
            >
              {getDisplayText()}
            </div>
            <div className={selectorStyles.dateRangeTriggerContentRight}>
              {!disabled && date && highlightDropBox && (
                <IconContainer>
                  <CrossIcon size={vars.iconSize.xs} onClick={onClear} />
                </IconContainer>
              )}
              <ExpandIcon size={vars.iconSize.xs} />
            </div>
          </DateRangeTriggerContent>
        </DateRangeTrigger>
      </Popover.Trigger>
      <Popover.Content align="start" className={styles.datePopoverContent}>
        <DateRangePickerContainer>
          <DatePickerPresets
            {...presetProps}
            disabled={disabled}
            onClick={onSuggestionClick}
            useSiteTimeZone={useSiteTimeZone}
          />
          <Stack direction="column" gap="l">
            <CustomDateRangeButton
              disabled={disabled}
              onClick={() => setShowDatePicker((val) => !val)}
            >
              <div className={selectorStyles.optionText}>
                <Content id={ContentId.CustomDate} />
              </div>
              <CalendarIcon size={vars.iconSize.m} withHoverEffect />
            </CustomDateRangeButton>
            {showDatePicker && (
              <DatePickerInput
                {...presetProps}
                date={date}
                onDateChange={onDatePickerChange}
                className={clsx(className, styles.datePicker)}
                dateDisplayFormat={dateDisplayFormat}
                color={color}
                locale={locale}
                useSiteTimeZone={useSiteTimeZone}
              />
            )}
          </Stack>
        </DateRangePickerContainer>
      </Popover.Content>
    </Popover.Root>
  );
}
