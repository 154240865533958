import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { FormatOption } from 'src/modals/EditTableColumns';
import { ContentId } from 'src/utils/constants/contentId';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { Feature, UiCurrency } from 'src/WebApiController';

import { DisplayType } from './ComparableListingItem';
import * as styles from './FloorCeilingBarDisplay.css';

export type FloorCeilingBarDisplayProps = {
  displayType: DisplayType.FloorDisplay | DisplayType.CeilingDisplay;
  currency: UiCurrency;
  floorCeilingPrice: number;
};

export const FloorCeilingBarDisplay: React.FC<FloorCeilingBarDisplayProps> = ({
  displayType,
  currency,
  floorCeilingPrice,
}) => {
  return (
    <div className={styles.floorBarDisplay}>
      <div className={styles.floorBarDotLeft} />
      <span className={styles.floorBar} />
      <Stack gap="s">
        <span className={styles.floorBarTitle}>
          <Content
            id={
              displayType === DisplayType.FloorDisplay
                ? ContentId.Floor
                : ContentId.Ceiling
            }
          />
        </span>
        <span className={styles.floorBarPrice}>
          {applyNumberFormatting({
            precision: FormatOption.Precise,
            inputNumber: floorCeilingPrice,
            currencyCode: currency.code,
            currencyDecimalDigits: currency.dec,
          })}
        </span>
      </Stack>
      <div className={styles.floorBarDotRight} />
    </div>
  );
};
