import { MutableRefObject, useCallback, useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';

interface UseSetTopListItemPositionProps {
  topItemListRef: MutableRefObject<HTMLDivElement | null>;
  virtuosoId: string;
  isScrollAtTop: boolean;
  virtuosoScrollY: number;
}

/**
 * Virtuoso's list wrapper (virtuoso-item-list) has paddingTop to separate
 * from sticky header. When Virtuoso component TopListItem changes its height,
 * virtuoso-item-list doesn't update it's padding.
 * This hook, maintains that paddingTop update to date.
 */
export const useSetTopListItemPosition = ({
  topItemListRef,
  virtuosoId,
  isScrollAtTop,
  virtuosoScrollY,
}: UseSetTopListItemPositionProps) => {
  const virtuosoItemListSelector = `[id="${virtuosoId}"] [data-test-id="virtuoso-item-list"]`;

  const updateVirtuosoListPaddingTop = useCallback(() => {
    requestAnimationFrame(() => {
      const topItemListEl = topItemListRef.current;
      const virtuosoItemListEl = document.querySelector(
        virtuosoItemListSelector
      ) as HTMLDivElement;

      if (virtuosoItemListEl && topItemListEl) {
        virtuosoItemListEl.style.paddingTop = `${topItemListEl.offsetHeight}px`;
      }
    });
  }, [topItemListRef, virtuosoItemListSelector]);

  // Apply paddingTop to virtuoso-item-list getting it from TopItemList height.
  useResizeObserver<HTMLDivElement>({
    ref: topItemListRef,
    onResize: () => {
      const topItemListEl = topItemListRef.current;
      if (!topItemListEl) {
        return;
      }

      if (virtuosoScrollY < topItemListEl.offsetTop || isScrollAtTop) {
        updateVirtuosoListPaddingTop();
      }
    },
  });

  useEffect(() => {
    if (isScrollAtTop) {
      updateVirtuosoListPaddingTop();
    }
  }, [isScrollAtTop, updateVirtuosoListPaddingTop]);
};
