import {
  InitialTableState,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { FormatContent, useContent } from 'src/contexts/ContentContext';
import { NoData, Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { CurrencyConversionOverride } from 'src/WebApiController';

import {
  CURRENCY_CONVERSION_OVERRIDE_TABLE_COLUMNS_CONFIG,
  CurrencyConversionOverrideWithHandlers,
} from './configs/CurrencyConversionOverridesTableColumnsConfig';

export type CurrencyConversionOverridesTableDataProps = {
  currencyConversionOverrides?: CurrencyConversionOverride[] | null;
};

export type CurrencyConversionOverridesTableProps =
  CurrencyConversionOverridesTableDataProps & {
    onMount?: () => void;
    onUnmount?: (state: object) => void;
    initState?: InitialTableState;
  };

const PAGE_SIZE = 10;

export function CurrencyConversionOverridesTable({
  currencyConversionOverrides,
  onCurrencyConversionOverrideAction,
  onMount,
  onUnmount,
  initState,
}: CurrencyConversionOverridesTableProps &
  Pick<
    CurrencyConversionOverrideWithHandlers,
    'onCurrencyConversionOverrideAction'
  >) {
  const currencyConversionOverridesText = useContent(ContentId.Override);

  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || [
      { id: 'currencyConversionOverrideConversionRateDate', desc: false },
    ]
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: PAGE_SIZE,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const data = useMemo(
    () =>
      currencyConversionOverrides?.map(
        (v) =>
          ({
            currencyConversionOverride: v,
            onCurrencyConversionOverrideAction,
          }) as CurrencyConversionOverrideWithHandlers
      ),
    [currencyConversionOverrides, onCurrencyConversionOverrideAction]
  );

  const options: Partial<
    TableOptions<CurrencyConversionOverrideWithHandlers | null>
  > = useMemo(
    () => ({
      data,
      columns: CURRENCY_CONVERSION_OVERRIDE_TABLE_COLUMNS_CONFIG,
      state: {
        pagination,
        sorting,
      },
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
    }),
    [data, pagination, sorting]
  );

  return currencyConversionOverrides?.length ? (
    <Table options={options} />
  ) : (
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={currencyConversionOverridesText}
      />
    </NoData>
  );
}
