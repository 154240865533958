import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

type ClockIconProps = Omit<IconsProps, 'viewBox'> & { noOutline?: boolean };

export function ClockIcon({ noOutline, ...props }: ClockIconProps) {
  const fillColor = props.fill || '#9471D7';
  return (
    <SvgWrapper {...props}>
      <>
        {!noOutline && (
          <circle
            cx="12"
            cy="12"
            r="11.58333"
            fill="none"
            stroke={fillColor}
            strokeWidth="0.833333"
          />
        )}
      </>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 22.4348 12 Z M 24 12 Z M 17.7391 9.913 C 17.7391 13.0828 15.1697 15.6522 12 15.6522 C 8.8304 15.6522 6.2609 13.0828 6.2609 9.913 C 6.2609 6.7434 8.8304 4.1739 12 4.1739 C 15.1697 4.1739 17.7391 6.7434 17.7391 9.913 Z M 18.7826 9.913 C 18.7826 13.659 15.746 16.6956 12 16.6956 C 8.2541 16.6956 5.2174 13.659 5.2174 9.913 C 5.2174 6.1671 8.2541 3.1304 12 3.1304 C 15.746 3.1304 18.7826 6.1671 18.7826 9.913 Z M 12.1565 6.2609 H 10.8 V 10.3722 L 13.835 11.5863 L 14.3388 10.3268 L 12.1565 9.4538 V 6.2609 Z M 8.3478 19.826 V 18.7826 H 15.6522 V 19.826 H 8.3478 Z"
        fill={fillColor}
      />
    </SvgWrapper>
  );
}
