import { ReactNode } from 'react';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import { useUpdatePurchasesPermissions } from '../useUpdatePurchasesPermissions';

interface BulkEditAccountingActionPermissionsHandlerProps {
  children: ReactNode;
}

export const BulkEditAccountingActionPermissionsHandler = ({
  children,
}: BulkEditAccountingActionPermissionsHandlerProps) => {
  const { hasUpdatePurchasesPermission } = useUpdatePurchasesPermissions();
  const hasBulkConvertPaymentLinesFeature = useUserHasFeature(
    Feature.BulkConvertPaymentLines
  );
  const hasMarkPurchaseAsPaidFeature = useUserHasFeature(Feature.MarkPOAsPaid);

  if (
    !hasUpdatePurchasesPermission ||
    (!hasBulkConvertPaymentLinesFeature && !hasMarkPurchaseAsPaidFeature)
  ) {
    return null;
  }

  return <>{children}</>;
};
