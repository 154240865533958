import styled, { keyframes } from 'styled-components/macro';

const CircleWrapperAnimation = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const RotatingWrapper = styled.div`
  display: flex;
  &.blink-on {
    animation: blink-animation 1s steps(10, start) infinite;
    -webkit-animation: blink-animation 1s steps(10, start) infinite;
    transition: all 0.2s;
  }

  svg {
    animation: ${CircleWrapperAnimation} 1s linear infinite;
  }
`;
