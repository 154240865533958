import * as Popover from '@radix-ui/react-popover';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import {
  mergeProps,
  RADIX_COLLISION_PADDING,
  RADIX_SIDE_OFFSET,
  styledComponent,
} from 'src/core/utils';

import * as styles from './Popover.css';

export const Root = Popover.Root;

export const Trigger = Popover.Trigger;

export const Content = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<typeof Popover.Content>
>(function Content({ children, ...props }, ref) {
  return (
    <Popover.Portal>
      <Popover.Content
        ref={ref}
        sideOffset={RADIX_SIDE_OFFSET}
        collisionPadding={RADIX_COLLISION_PADDING}
        {...mergeProps({ className: styles.content }, props)}
      >
        {children}
      </Popover.Content>
    </Popover.Portal>
  );
});

export const Arrow = styledComponent(Popover.Arrow, styles.arrow);

export const Close = Popover.Close;
