import { ColumnPersonalizationFlags } from './columnUtils.types';
import { FormulaEditable, parseEditableFormulaBase } from './customColumnUtils';

export type MathematicalToken = {
  // This is for debug purpose
  token: string;
  isPlainToken: boolean;
  isCurrency?: boolean;
  isPercent?: boolean;
};

const LEFT_PARENTHESIS = '(';
const RIGHT_PARENTHESIS = ')';
const PLUS = '+';
const MINUS = '-';
const MULTIPLICATION = '*';
const DIVISION = '/';

const isOperator = (token: string) => {
  return (
    token === PLUS ||
    token === MINUS ||
    token === MULTIPLICATION ||
    token === DIVISION
  );
};

const isParenthesis = (token: string) => {
  return token === LEFT_PARENTHESIS || token === RIGHT_PARENTHESIS;
};

/**
 * Parse plain token into single mathematical tokens
 * E.g. ' + 20' => ['+', '20']
 * E.g. ' - 0.04))' => ['-', '0.04', ')', ')']
 * @param token
 * @returns
 */
export const parseMathematicalTokenFromPlainToken = (token: string) => {
  const tokens: string[] = [];
  let currentToken = '';
  for (let i = 0; i < token.length; i++) {
    const char = token[i];
    if (char === ' ') {
      if (currentToken) {
        tokens.push(currentToken);
        currentToken = '';
      }
      continue;
    }
    if (isOperator(char) || isParenthesis(char)) {
      if (currentToken) {
        tokens.push(currentToken);
        currentToken = '';
      }
      tokens.push(char);
      continue;
    }
    currentToken += char;
  }
  if (currentToken) {
    tokens.push(currentToken);
  }
  return tokens.map((t) => t.trim());
};

/**
 * Take in a list of tokens with only literals and {addition, subtraction}
 * Return the result of the evaluation (e.g. is currency or not)
 * @param tokens
 * @returns
 */
const evaluateMathematicalTokensAdditionSubtraction = (
  tokens: MathematicalToken[]
): MathematicalToken => {
  const tokenStack: MathematicalToken[] = [];
  let i = 0;
  while (i < tokens.length) {
    const token = tokens[i];

    if (token.token === PLUS || token.token === MINUS) {
      const previousToken = tokenStack.pop();
      const nextToken = tokens[i + 1];

      if (previousToken && nextToken) {
        // If either previousToken or nextToken is currency, the result is currency
        const isCurrency = previousToken.isCurrency || nextToken.isCurrency;
        // If either previousToken and nextToken is percent, the result is percent
        const isPercent = previousToken.isPercent || nextToken.isPercent;
        tokenStack.push({
          token: `(${previousToken.token}) ${token.token} (${nextToken.token})`,
          isPlainToken: false,
          isCurrency,
          isPercent,
        });
      }
      i += 2;
    } else {
      tokenStack.push(token);
      i++;
    }
  }
  return tokenStack[0];
};

/**
 * Take in a list of tokens with only literals and {addition, subtraction, multiplication, division}
 * Return the result of the evaluation (e.g. is currency or not)
 * @param tokens
 * @returns
 */
const evaluateMathematicalTokensMultiplicationDivision = (
  tokens: MathematicalToken[]
): MathematicalToken => {
  if (tokens.length === 1) {
    return tokens[0];
  }

  const tokenStack: MathematicalToken[] = [];
  let i = 0;
  // Handle multiplication and division first
  // throw them into evaluateMathematicalTokensAdditionSubtraction for addition and subtraction
  while (i < tokens.length) {
    const token = tokens[i];
    if (token.token === MULTIPLICATION || token.token === DIVISION) {
      const previousToken = tokenStack.pop();
      const nextToken = tokens[i + 1];

      if (!previousToken || !nextToken) {
        break;
      }

      if (token.token === MULTIPLICATION) {
        // If either previousToken or nextToken is currency, the result is currency
        const isCurrency = previousToken.isCurrency || nextToken.isCurrency;
        // If either previousToken and nextToken is percent, the result is percent
        const isPercent = Boolean(
          previousToken.isPercent || nextToken.isPercent
        );
        tokenStack.push({
          token: `(${previousToken.token}) * (${nextToken.token})`,
          isPlainToken: false,
          isCurrency,
          isPercent,
        });
      } else {
        // If previousToken is currency and nextToken is not currency, the result is currency
        const isCurrency = Boolean(
          previousToken.isCurrency && !nextToken.isCurrency
        );
        const isPercent = Boolean(
          previousToken.isPercent && !nextToken.isPercent
        );
        tokenStack.push({
          token: `(${previousToken.token}) / (${nextToken.token})`,
          isPlainToken: false,
          isCurrency,
          isPercent,
        });
      }
      i += 2;
    } else {
      tokenStack.push(token);
      i++;
    }
  }
  return evaluateMathematicalTokensAdditionSubtraction(tokenStack);
};

/**
 * Takes in a list of tokens with only literals and {addition, subtraction, multiplication, division, parentheses}
 * Return the result of the evaluation (e.g. is currency or not)
 *
 * Assumptions: '-' is a binary operator, not a unary operator
 * @param tokens
 * @returns
 */
export const evaluateMathematicalTokens = (
  tokens: MathematicalToken[]
): MathematicalToken => {
  // Handle parentheses - get rid of them and evaluate the expression inside
  // throw them into evaluateMathematicalTokensMultiplicationDivision
  const tokenStack: MathematicalToken[] = [];
  let i = 0;
  while (i < tokens.length) {
    const token = tokens[i];

    if (token.token === RIGHT_PARENTHESIS) {
      const tokensWithoutParentheses: MathematicalToken[] = [];
      let poppedToken = tokenStack.pop();

      while (poppedToken && poppedToken.token !== LEFT_PARENTHESIS) {
        tokensWithoutParentheses.push(poppedToken);
        poppedToken = tokenStack.pop();
      }
      // tokensWithoutParentheses is in reverse order, so we need to reverse it back
      const tokensWithoutParenthesesTrimmed =
        tokensWithoutParentheses.reverse();

      const result = evaluateMathematicalTokensMultiplicationDivision(
        tokensWithoutParenthesesTrimmed
      );
      tokenStack.push(result);
    } else {
      tokenStack.push(token);
    }
    i++;
  }

  return evaluateMathematicalTokensMultiplicationDivision(tokenStack);
};

export const getPersonalizationFlagsFromFormulaBase = (
  mathTokens: MathematicalToken[] | undefined
): Partial<ColumnPersonalizationFlags> => {
  if (!mathTokens?.length) {
    return {
      isCurrency: false,
      isPercent: false,
    };
  }

  const mergedToken = evaluateMathematicalTokens(mathTokens);

  return {
    isCurrency: mergedToken?.isCurrency ?? false,
    isPercent: mergedToken?.isPercent ?? false,
  };
};

/**
 * Parse editableFormula, transforming formula into plain literals and contentId tied with ColumnId and field name
 * Used for preview and validation.
 * E.g. `${ticketCount} + 20` ==> [ContentId.Original, ' + 20']
 * @param editableFormula
 * @returns parsed list of tokens, or undefined if failed to parse
 */
export const parseMathematicalTokensFromFormulaBase = (
  editableFormula: FormulaEditable | undefined,
  getTokenFromFieldName: (fieldName: string) => MathematicalToken
): MathematicalToken[] | undefined => {
  const parsedTokenResult = parseEditableFormulaBase(editableFormula);
  if (!parsedTokenResult) {
    return;
  }

  const tokens: MathematicalToken[] = [];
  parsedTokenResult.forEach((tokenResult) => {
    if (tokenResult.isPlainToken) {
      const parsedPlainTokens = parseMathematicalTokenFromPlainToken(
        tokenResult.token
      );
      tokens.push(
        ...parsedPlainTokens.map((t) => ({ token: t, isPlainToken: true }))
      );
    } else {
      const fieldName = tokenResult.token;

      tokens.push(getTokenFromFieldName(fieldName));
    }
  });

  return tokens;
};
