import { ComponentProps, useCallback, useContext } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { vars } from 'src/core/themes';
import { ManagedMarketplaceSale } from 'src/modals/ManagedMarketplaceSale';
import { EditIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType, SaleDetails } from 'src/WebApiController';

export const LaunchEditOfflineSale = ({
  saleId,
  marketplaceSaleId,
  cancelTo,
  iconOnlyMode,
  variant,
  ...buttonProps
}: Omit<
  ComponentProps<typeof ButtonWithIcon>,
  'onClick' | 'textContentId' | 'icon' | 'iconProps'
> &
  Omit<
    ComponentProps<typeof ManagedMarketplaceSale>,
    'sale' | 'listing' | 'event' | 'venue'
  > & {
    saleId: number;
    marketplaceSaleId: string | null;
    eventId: string | null;
  }) => {
  const { setModal } = useContext(ModalContext);

  const { setActivePosEntity } = useActivePosEntityContext<SaleDetails>();

  const editOfflineSale = useCallback(() => {
    setModal({
      children: <ManagedMarketplaceSale cancelTo={cancelTo} />,
      clickOutsideToClose: true,
      size: 'slide-in',
      deepLinkValue: saleId,
      trackViewEntityType: ActionOutboxEntityType.Sale,
    });
  }, [setModal, cancelTo, saleId]);

  const onLaunchDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setActivePosEntity(saleId, marketplaceSaleId);
      editOfflineSale();
    },
    [editOfflineSale, marketplaceSaleId, saleId, setActivePosEntity]
  );

  return (
    <ButtonWithIcon
      {...buttonProps}
      onClick={onLaunchDialog}
      textContentId={ContentId.EditOfflineSale}
      iconOnlyMode={iconOnlyMode}
      variant={variant}
      icon={
        <EditIcon
          size={vars.iconSize.m}
          withHoverEffect
          fill={
            !iconOnlyMode && variant == 'regular'
              ? IconsFill.textInverted
              : IconsFill.textBrand
          }
        />
      }
    />
  );
};
