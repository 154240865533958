import { styled } from 'src/core/utils';

import * as styles from './SimpleTable.css';

export const Table = styled('table', styles.table);

export const Thead = styled('thead', styles.thead);

export const Tbody = styled('tbody', styles.tbody);

export const Tr = styled('tr', styles.tr);

export const Th = styled('th', styles.th);

export const Td = styled('td', styles.td);
