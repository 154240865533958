import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const ContainerStyledDiv = styled.div<{
  isHorizontal: boolean;
}>`
  position: relative;
  display: flex;
  flex-grow: 0;
  align-items: stretch;

  ${(props) => (props.isHorizontal ? 'width: auto' : 'height: auto')};
  ${(props) => (props.isHorizontal ? 'height: 100%' : 'width: 100%')};
`;

export const ContentStyledDiv = styled.div.attrs(
  (props: { isHorizontal: boolean; size: number }) => ({
    style: props.isHorizontal
      ? {
          width: `${props.size}px`,
        }
      : {
          height: `${props.size}px`,
        },
  })
)<{
  isHorizontal: boolean;
  size: number;
  shouldAnimateContent: boolean;
}>`
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  flex-flow: ${(props) => (props.isHorizontal ? 'row' : 'column')};
  transition: ${(props) => (props.shouldAnimateContent ? 'all 0.3s' : 'none')};
`;

export const DividerContainer = styled.div<{
  isHorizontal: boolean;
}>`
  width: ${() => vars.iconSize.l};
  right: -8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: ${(props) => (props.isHorizontal ? 'ew-resize' : 'ns-resize')};

  &:hover {
    .divider {
      ${(props) => (props.isHorizontal ? 'width: 4px' : 'width: 4px')};
      padding: 0;
      background-color: ${(props) => props.theme.colors.backgroundBrandHover};
    }
  }

  .divider {
    ${(props) => (props.isHorizontal ? 'width: 2px' : 'height: 2px')};
    transition: all 0.2s;
    position: relative;
    margin-right: -2px;
    background-color: ${(props) => props.theme.colors.borderPrimary};
    z-index: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
