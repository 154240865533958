import { useCallback, useState } from 'react';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { Content } from 'src/contexts/ContentContext';
import { shared } from 'src/core/themes';
import { Card, Stack } from 'src/core/ui';
import { useGetFilteredAccessibleVendorAccountsQuery } from 'src/hooks/api/useGetFilteredAccessibleVendorAccountsQuery';
import { useInvalidateGetVendorQuery } from 'src/hooks/api/useInvalidateGetVendorQuery';
import { FilterVendorAccountsInput } from 'src/modals/VendorDetails/components/VendorAccounts/components/FilterVendorAccountsInput';
import { VendorAccountsTable } from 'src/modals/VendorDetails/components/VendorAccounts/components/VendorAccountsTable/VendorAccountsTable';
import { vendorsPageHeaderWrapper } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsPageHeader/VendorsPageHeader.css';
import { OnVendorAccountSavedFn } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useAddVendorAccount';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType, PurchaseVendor } from 'src/WebApiController';

import { AddVendorAccount } from './components/AddVendorAccount';
import * as styles from './VendorAccounts.css';

interface VendorAccountsProps {
  vendor: PurchaseVendor;
}

export const VendorAccounts = ({ vendor }: VendorAccountsProps) => {
  const [vendorAccountsSerchText, setVendorAccountsSerchText] =
    useState<string>('');
  const vendorAccountsQuery = useGetFilteredAccessibleVendorAccountsQuery(
    [vendor.id],
    vendorAccountsSerchText
  );
  const invalidateGetVendorQuery = useInvalidateGetVendorQuery();

  const { refreshSearch, updateVendorAccount } = vendorAccountsQuery;

  const onVendorAccountSaved = useCallback<OnVendorAccountSavedFn>(
    ({ vendorAccount, isNew }) => {
      if (isNew) {
        refreshSearch();
        invalidateGetVendorQuery();
        return;
      }
      updateVendorAccount(vendorAccount);
    },
    [invalidateGetVendorQuery, refreshSearch, updateVendorAccount]
  );

  return (
    <Stack direction="column" height="full" id="vendor-accounts-----">
      <div className={vendorsPageHeaderWrapper}>
        <Stack justifyContent="spaceBetween" alignItems="center">
          <div className={shared.typography.title5}>
            <Content id={ContentId.Accounts} />{' '}
            <span className={styles.vendorAccountsLength}>
              ({vendor.vendAccCounts})
            </span>
          </div>
          <Stack direction="row" gap="m" alignItems="center">
            <BulkEditStatusPopover
              entityType={ActionOutboxEntityType.TicketGroup}
            />
            <FilterVendorAccountsInput
              onSearchTextChanged={setVendorAccountsSerchText}
            />
            <AddVendorAccount
              vendor={vendor}
              onVendorAccountSaved={onVendorAccountSaved}
            />
          </Stack>
        </Stack>
      </div>
      <Card className={styles.card}>
        <VendorAccountsTable
          vendorAccountsQuery={vendorAccountsQuery}
          onVendorAccountSaved={onVendorAccountSaved}
        />
      </Card>
    </Stack>
  );
};
