import {
  CompListing,
  EventConfigScoreOverride,
  Seating,
  SectionInfo,
  SectionScoreOverride,
  VenueMapInfo,
} from 'src/WebApiController';

export function calcSeatScore(
  values: {
    /**
     * Should be a normalized value between 0 and 100.
     */
    value?: number | null;
    /**
     * The weight (importance) of the value from 0 to 10.
     */
    weight: number;
  }[]
) {
  let sum = 0;
  let count = 0;
  for (const { value, weight } of values) {
    if (value != null) {
      sum += weight * (value / 100);
      count++;
    }
  }
  return count > 0 ? sum / (count * 10) : 0;
}

/**
 *
 * @param defaultScoreOverrides
 * @param scoreOverrides
 * @param defaultAllScoresToZero
 * @param scaleScoreRaw Calculate scoreRaw from scaled score which is 0-100
 * @returns
 */
export const getCompleteEventConfigScoreOverrides = (
  defaultScoreOverrides?: SectionScoreOverride[] | null,
  scoreOverrides?: SectionScoreOverride[] | null,
  defaultAllScoresToZero?: boolean,
  scaleScoreRaw?: boolean
) => {
  // There's no max score from venue map, so we should just use the score as is
  if (!defaultScoreOverrides?.length) {
    return scoreOverrides?.map((so) => ({
      ...so,

      scoreRaw: so.score,
    }));
  }

  if (!scoreOverrides?.length) {
    return defaultScoreOverrides.map((so) => ({
      ...so,
      score: defaultAllScoresToZero ? 0 : so.score,
    }));
  }

  const maxDefaultScoreRaw = defaultScoreOverrides?.reduce(
    (max, score) => Math.max(max, score.scoreRaw ?? 0),
    0
  );

  const curOverridesMap = (scoreOverrides ?? []).reduce(
    (cur, score) => {
      const key = `${score.sectionId}-${score.rowId}`;
      cur[key] = score;

      return cur;
    },
    {} as Record<string, SectionScoreOverride>
  );

  const result = [
    ...(scoreOverrides?.map((so) => {
      let scoreRaw = so.score;
      if (scaleScoreRaw && so.score != null && maxDefaultScoreRaw) {
        scoreRaw = so.score * 0.01 * maxDefaultScoreRaw;
      }

      return { ...so, scoreRaw };
    }) ?? []),
  ];
  (defaultScoreOverrides || []).forEach((dfScore) => {
    const key = `${dfScore.sectionId}-${dfScore.rowId}`;

    if (!curOverridesMap[key]) {
      result.push({
        ...dfScore,
        score: defaultAllScoresToZero ? 0 : dfScore.score,
        scoreRaw: defaultAllScoresToZero ? 0 : dfScore.score,
      });
    }
  });

  return result;
};

export const getEventConfigScoreOverride = (
  viagogoEventId: number,
  venueMapConfig: VenueMapInfo,
  newName: string,
  eventConfigScoreOverride?: EventConfigScoreOverride | null,
  defaultAllScoresToZero?: boolean,
  scaleScoreRaw?: boolean,
  defaultConfigPayload?: string | null,
  isOverrideTemplate?: boolean
) => {
  return {
    viagogoEventId: viagogoEventId,
    id: isOverrideTemplate ? -1 : eventConfigScoreOverride?.id ?? -1,
    name: isOverrideTemplate
      ? newName
      : eventConfigScoreOverride?.name ?? newName,
    eventIds: eventConfigScoreOverride?.eventIds,
    cfgId: isOverrideTemplate
      ? venueMapConfig!.venueCfgId
      : eventConfigScoreOverride?.cfgId ?? venueMapConfig!.venueCfgId,
    cfgPayload: isOverrideTemplate
      ? defaultConfigPayload
      : defaultConfigPayload ?? eventConfigScoreOverride?.cfgPayload,
    isActive: isOverrideTemplate ? null : eventConfigScoreOverride?.isActive,
    scoreOverrides: isOverrideTemplate
      ? eventConfigScoreOverride?.scoreOverrides
      : getCompleteEventConfigScoreOverrides(
          venueMapConfig?.sectionScores,
          eventConfigScoreOverride?.scoreOverrides,
          defaultAllScoresToZero,
          scaleScoreRaw
        ) ?? [],

    // TODO: old field for global template configs feature. Determine what the plan is
    // for this feature and remove if we do not plan on releasing it so we can reduce
    // the confusing naming
    isTemplate: eventConfigScoreOverride?.isTemplate,
  } as EventConfigScoreOverride;
};

export const getRowRepresentingSectionMedianScore = (
  sectionId: number,
  seatScores: SectionScoreOverride[]
): SectionScoreOverride | undefined => {
  const sectionScores = seatScores!.filter(
    (s) => s.sectionId === sectionId && s.score
  );

  return sectionScores.sort((s1, s2) => s1.rowId - s2.rowId)[
    Math.round(sectionScores.length / 2)
  ];
};

export const getMedianScoreForSection = (
  sectionId: number,
  seatScores: SectionScoreOverride[]
) => {
  const sectionScores = seatScores!.filter((s) => s.sectionId === sectionId);
  const values = sectionScores.map((s) => s.score ?? -1);
  return median(values);
};

export const getMatchingSectionRow = (
  l: Seating | CompListing,
  sections?: SectionInfo[] | null
) => {
  const rowId = l.rowId;
  const sectionName = l.section?.trim().toLocaleUpperCase();
  const rowName = l.row?.trim().toLocaleUpperCase();

  if (rowId) {
    const section = sections?.find((s) => s.rows.some((r) => r.id === rowId));

    if (section) {
      const row = section?.rows.find((r) => r.id === rowId) ?? section?.specRow;

      const ticketClassId =
        section?.rows.find((r) => r.id === rowId) != null
          ? section?.rows.find((r) => r.id === rowId)?.tktClass?.id
          : section?.specRow?.ticketClassId;

      return { section, row, ticketClassId };
    }
  }

  const section = sections?.find(
    (s) =>
      s.name.toLocaleUpperCase() === sectionName &&
      (!rowName ||
        s.rows.some((r) => r.name?.trim().toLocaleUpperCase() === rowName))
  );

  const row =
    section?.rows.find((r) => !!r.name && r.name === rowName) ??
    section?.specRow;

  const ticketClassId =
    section?.rows.find((r) => !!r.name && r.name === rowName) != null
      ? section?.rows.find((r) => !!r.name && r.name === rowName)?.tktClass?.id
      : section?.specRow?.ticketClassId;

  return { section, row, ticketClassId };
};

export const getSectionsSameZone = (
  l: Seating | CompListing,
  sections: SectionInfo[] | undefined
) => {
  const { ticketClassId } = getMatchingSectionRow(l, sections);

  if (!ticketClassId) return [];

  return sections?.filter((s) =>
    s.rows.some((r) => r.tktClass?.id === ticketClassId)
  );
};

export const getSeatScore = (
  l: Seating | CompListing | null | undefined,
  scoreOverrides?: SectionScoreOverride[] | null,
  sections?: SectionInfo[] | null
) => {
  if (!l) return undefined;

  let seatScore = scoreOverrides?.find(
    (sso) => l.rowId != null && l.rowId === sso.rowId
  )?.score;
  if (seatScore != null) return seatScore;

  const { row } = getMatchingSectionRow(l, sections);
  if (!row) return undefined;

  seatScore = scoreOverrides?.find((sso) => row?.id === sso.rowId)?.score;
  return seatScore;
};

export const updateScoreForSectionMaintainingRowScoreRatio = (
  newScore: number,
  sectionId: number,
  scoreOverrides?: SectionScoreOverride[] | null
) => {
  const newScoreOverrides = [...(scoreOverrides ?? [])];
  const overridesToEdit = newScoreOverrides?.filter(
    (so) => so.sectionId === sectionId
  );

  if (overridesToEdit.length) {
    updateScoreMaintainingRowScoreRatio(newScore, overridesToEdit);

    return newScoreOverrides;
  } else {
    return null;
  }
};

export const updateScoreMaintainingRowScoreRatio = (
  newScore: number,
  overridesToEdit: SectionScoreOverride[]
) => {
  const firstNonZeroRow = overridesToEdit.find((r) => r.score);
  if (!firstNonZeroRow) {
    // if there's no row with score > 0, then we just set all row to be the same score
    overridesToEdit.forEach((so) => (so.score = newScore));
  } else {
    // Find the median row score
    const scores: number[] = overridesToEdit
      .map((r) => r.score)
      .filter(
        (score): score is number => score !== null && score !== undefined
      );

    // If the median is somehow 0 just set it to 1 so the ratio doesn't change
    let med = median(scores);
    if (med == 0) {
      med = 1;
    }

    const ratio = newScore / med;
    overridesToEdit.forEach((so) => (so.score = (so.score ?? 0) * ratio));
  }
};

export const median = (values: number[]) => {
  if (values.length === 0) return 0;

  values.sort((a, b) => a - b);

  const half = Math.floor(values.length / 2);

  if (values.length % 2) {
    return values[half];
  }

  return (values[half - 1] + values[half]) / 2.0;
};
