import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  CatalogClient,
  CatalogResults,
  SaleClient,
  SaleDetails,
} from 'src/WebApiController';

export function useGetSaleWithCatalogData(saleId?: number | null) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  return useQuery({
    queryKey: ['getSaleWithCatalogData', saleId],
    async queryFn() {
      if (!saleId) return null;

      let sale: SaleDetails;
      let catalog: CatalogResults;
      try {
        sale = await new SaleClient(
          activeAccountWebClientConfig
        ).getSaleBySaleId(saleId);
      } catch (err: unknown) {
        trackError('SaleClient.getSaleBySaleId', err as ErrorTypes, {
          saleId,
        });
        throw err;
      }
      try {
        catalog = await new CatalogClient(
          activeAccountWebClientConfig
        ).getCatalogForSaleBySaleId(sale.id);
      } catch (err: unknown) {
        trackError(
          'CatalogClient.getCatalogForSaleBySaleId',
          err as ErrorTypes,
          {
            saleId,
          }
        );
        throw err;
      }
      return { sale, catalog };
    },
    refetchOnWindowFocus: false,
  });
}
