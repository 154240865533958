import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const SimpleUndercutIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 40 40">
      <path
        d="M32 8H8V32H32V8Z"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 12.8H16V27.2H24V12.8Z"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 20H24"
        stroke={props.stroke ?? '#212121'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
