import { useCallback } from 'react';
import { LaunchBulkEditCommission } from 'src/components/common/BulkActions/BulkEditCommissionAction';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext/PurchaseDataContext';
import { BulkEditCommissionDialog } from 'src/dialogs/BulkEdits/BulkEditCommissionDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  Commission,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { PURCHASE_BULK_UPDATE_COMMISSION_UPDATE_KEY } from '../../PurchaseActionDropdown.constants';

export const useBulkEditCommissionAction = (
  filterQuery: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const updateKey = groupId
    ? `purchaseCommission-event-${groupId}}`
    : PURCHASE_BULK_UPDATE_COMMISSION_UPDATE_KEY;
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkEditCommissionDialog,
    closeDialog: closeBulkEditCommissionDialog,
  } = useDialog(DialogId.BulkEditCommission, BulkEditCommissionDialog);

  const onSubmit = useCallback(
    async (
      commissions: Commission[] | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkUpdatePurchaseCommissionsPreview(filterQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdatePurchaseCommissionsPreview', error, {
              trackErrorData: { filterQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkUpdatePurchaseCommissions(
              {
                item1: preview!.preview,
                item2: commissions ?? [],
              },
              updateKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on details modal
                // we force re-loading the entity and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshData(true);
                await refreshExpandedListItems();
              }

              closeBulkEditCommissionDialog();
            }
          },
          (error) => {
            showErrorDialog('onSubmitEditCommissions', error, {
              trackErrorData: { commissions, preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQuery,
      showErrorDialog,
      updateKey,
      closeBulkEditCommissionDialog,
      setActivePosEntity,
      refreshData,
      refreshExpandedListItems,
    ]
  );

  const onLaunchAction = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      openBulkEditCommissionDialog({
        updateKey,
        onOkay: onSubmit,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkEditCommissionDialog,
      });
    },
    [
      closeBulkEditCommissionDialog,
      onSubmit,
      openBulkEditCommissionDialog,
      setIsLoading,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkEditCommission
        key="EditCommission"
        onClick={onLaunchAction}
        disabled={isLoading}
      />
    ),
  };
};
