import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const FieldLabelWrapper = styled.div`
  font-weight: 600;
  ${({
    theme: {
      media,
      typography: { getStyles },
    },
  }) => `
  ${getStyles('body1')}
  padding-left: 0;
  ${media.makeMediaQuery(
    media.breakpoints.md,
    `padding-left: 0px;` // reset padding for tablets and above
  )}
`}
`;

export const FromToLabel = styled.div`
  color: ${(props) => props.theme.colors.textPrimary};
  padding-bottom: ${() => vars.spacing.sm};
  text-transform: uppercase;
`;

export const DividerIcon = styled.div`
  display: grid;
  place-content: center;
  border-radius: 50%;
  width: ${() => vars.spacing.xl};
  height: ${() => vars.spacing.xl};
  color: ${(props) => props.theme.colors.textBrand};
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
  background: ${(props) => props.theme.colors.backgroundPrimary};
`;

export const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  place-content: center;
  position: relative;
  min-height: 53px;
  margin-top: ${() => `-${vars.spacing.xxs}`};
  margin-bottom: ${() => `-${vars.spacing.xxs}`};
`;
