import { useCallback } from 'react';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { US_COUNTRY_CODES } from 'src/utils/constants/constants';
import { getStubhubEventWithPricingUrl } from 'src/utils/inventoryUtils';
import { Event } from 'src/WebApiController';

interface OpenStubhubDropdownActionProps {
  event: Event;
}

export const OpenStubhubDropdownAction = ({
  event,
}: OpenStubhubDropdownActionProps) => {
  const { venue } = useGetEventFullInfo(event);

  const onOpenInStubHubClicked = useCallback(
    (event: Event) => {
      const eventId = event?.viagId;

      // null item return means it was not found (204 status)
      window.open(
        getStubhubEventWithPricingUrl(
          eventId,
          !US_COUNTRY_CODES.includes(venue?.country?.code?.toUpperCase() ?? '')
        ),
        '_blank'
      );
    },
    [venue?.country?.code]
  );

  return (
    <PosDropdownItem
      key="StubHub"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onOpenInStubHubClicked(event);
      }}
    >
      {'StubHub 🡵'}
    </PosDropdownItem>
  );
};
