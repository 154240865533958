import { SellerRoleInfo, SellerRoleInput } from 'src/WebApiController';

export const mapToSellerRoleInput = (
  role?: SellerRoleInfo | null,
  userIds?: string[] | null
) => {
  return {
    id: role?.id ?? -1,
    details: role?.details,
    name: role?.name,
    permissions: [...(role?.permissions ?? [])],
    userIds: userIds,
  } as SellerRoleInput;
};
