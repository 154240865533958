/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import * as styles from 'src/components/Events/EventPage/EventPage.css';
import { InventorySideTable } from 'src/components/Listings/InventoryEventPage/InventorySideTable';
import { NoteSectionV2 } from 'src/components/Listings/InventoryEventPage/Sections/NoteSectionV2';
import { PurchasesSideTable } from 'src/components/Purchases/PurchaseEventPage/PurchasesSideTable';
import { SalesSideTable } from 'src/components/Sales/SaleEventPage/SaleSideTable';
import { Content } from 'src/contexts/ContentContext/Content';
import { Stack } from 'src/core/ui';
import * as Tabs from 'src/core/ui/Tabs';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { EntityWithTicketsQuery, Event, Feature } from 'src/WebApiController';

import { SidePanelHeader, SidePanelTab } from './SidePanelHeader';

type SidePanelProps = {
  event: Event;
  pageType: 'Inventory' | 'Sales' | 'Purchases';
  selectedEntityIds?: number[];
  filterQuery?: EntityWithTicketsQuery;
};

export const SidePanel = ({
  event,
  pageType,
  selectedEntityIds,
  filterQuery,
}: SidePanelProps) => {
  const hasFullPageEventViewSidePanelSelectionFilterFeature = useUserHasFeature(
    Feature.FullPageEventViewSidePanelSelectionFilter
  );

  // State Hooks
  const [activeTab, setActiveTab] = useState<SidePanelTab>(() => {
    switch (pageType) {
      case 'Sales':
        return SidePanelTab.PURCHASES;
      case 'Purchases':
        return SidePanelTab.INVENTORY;
      // Add more cases as needed
      default:
        return SidePanelTab.SALES;
    }
  });

  const isMobile = useMatchMedia('mobile');

  const tabs = useMemo(() => {
    const marketplaceEntityIds = filterQuery?.marketplaceEntityIds?.map(Number);
    switch (pageType) {
      case 'Sales': {
        const saleIdsQuery = hasFullPageEventViewSidePanelSelectionFilterFeature
          ? {
              saleIds:
                (selectedEntityIds && selectedEntityIds?.length > 0
                  ? selectedEntityIds
                  : null) || marketplaceEntityIds,
            }
          : undefined;
        return [
          {
            label: <Content id={ContentId.Purchases} />,
            value: SidePanelTab.PURCHASES,
            content: (
              <Stack direction="column" width="full" gap="m">
                <PurchasesSideTable
                  viagVirtualId={event.viagVirtualId}
                  queryInput={saleIdsQuery}
                />
              </Stack>
            ),
          },
          {
            label: <Content id={ContentId.Inventory} />,
            value: SidePanelTab.INVENTORY,
            content: (
              <Stack direction="column" width="full" gap="m">
                <InventorySideTable
                  viagVirtualId={event.viagVirtualId}
                  queryInput={saleIdsQuery}
                />
              </Stack>
            ),
          },
          {
            label: <Content id={ContentId.Notes} />,
            value: SidePanelTab.NOTES,
            content: <NoteSectionV2 eventId={event.viagId} />,
          },
        ];
      }
      case 'Purchases': {
        const purchaseOrderIdsQuery =
          hasFullPageEventViewSidePanelSelectionFilterFeature
            ? {
                purchaseOrderIds:
                  (selectedEntityIds && selectedEntityIds?.length > 0
                    ? selectedEntityIds
                    : null) || marketplaceEntityIds,
              }
            : undefined;

        return [
          {
            label: <Content id={ContentId.Inventory} />,
            value: SidePanelTab.INVENTORY,
            content: (
              <Stack direction="column" width="full" gap="m">
                <InventorySideTable
                  viagVirtualId={event.viagVirtualId}
                  queryInput={purchaseOrderIdsQuery}
                />
              </Stack>
            ),
          },
          {
            label: <Content id={ContentId.Sales} />,
            value: SidePanelTab.SALES,
            content: (
              <Stack direction="column" width="full" gap="m">
                <SalesSideTable
                  viagVirtualId={event.viagVirtualId}
                  queryInput={purchaseOrderIdsQuery}
                />
              </Stack>
            ),
          },
          {
            label: <Content id={ContentId.Notes} />,
            value: SidePanelTab.NOTES,
            content: <NoteSectionV2 eventId={event.viagId} />,
          },
        ];
      }
      default: // 'Inventory'
        return [];
    }
  }, [pageType, selectedEntityIds, filterQuery]);

  return (
    <Tabs.Root
      value={activeTab}
      onValueChange={(value) => setActiveTab(value as SidePanelTab)}
      style={{ width: '100%' }}
    >
      <Stack
        direction="column"
        className={styles.sidePanelRoot}
        gap="l"
        width="full"
      >
        {isMobile && <SwiperButton dir="left" />}
        <SidePanelHeader tabs={tabs} />
        {tabs.map((tab) => (
          <Tabs.Content key={tab.value} value={tab.value}>
            {tab.content}
          </Tabs.Content>
        ))}
      </Stack>
    </Tabs.Root>
  );
};
