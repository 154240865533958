import { useUpdatePurchasesPermissions } from 'src/components/Purchases/BulkActions/useUpdatePurchasesPermissions';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { ContentId } from 'src/utils/constants/contentId';

export const LaunchBulkEditCommission = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const { hasPurchaseUpdateCommissionPermission } =
    useUpdatePurchasesPermissions();

  if (!hasPurchaseUpdateCommissionPermission) {
    return null;
  }

  return (
    <PosDropdownItem
      key="UpdateBuyerCommission"
      onClick={(e) => onClick(e)}
      disabled={disabled}
    >
      <Content id={ContentId.UpdateBuyerCommission} />
    </PosDropdownItem>
  );
};
