import { useContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

export const SalesGlobalActionDropdown = () => {
  const actionsMsg = useContent(ContentId.Actions);

  // Return actual content when we have actions
  // again
  return null;

  // return (
  //   <PosDropdown
  //     key="sales-global-action-dropdown"
  //     trigger={
  //       <div title={actionsMsg} style={{ padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}` }}>
  //         <MoreIcon withHoverEffect fill={IconsFill.textBrand} align="middle" />
  //       </div>
  //     }
  //     align="end"
  //   ></PosDropdown>
  // );
};
