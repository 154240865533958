import { ReactNode } from 'react';
import { useQuickFilterSearchParam } from 'src/hooks/useQuickFilters';

export type Subfilter<D> = {
  id: string;
  name: ReactNode | string;
  data: D;
};

export type UseQuickSubfiltersOptions<D> = {
  quickSubfilters: { [parentQuickFilterId: string]: Subfilter<D>[] };
  initialQuery: D;
};

export function useQuickSubfilters<D>({
  quickSubfilters,
}: UseQuickSubfiltersOptions<D>) {
  const currentQuickFilterId = useQuickFilterSearchParam();
  const filteredQuickSubfilters =
    currentQuickFilterId != null
      ? quickSubfilters[currentQuickFilterId] ?? []
      : [];
  return {
    quickSubfilters: filteredQuickSubfilters,
  };
}
