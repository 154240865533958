import { range } from 'lodash-es';

export const generateShimmeringRows = (
  numOfRows: number,
  numOfListingGroups?: number
) => {
  return [
    ...(numOfListingGroups
      ? range(0, numOfListingGroups, 1).map(() => ({
          listing: null,
          isLtGrp: true,
        }))
      : []),
    ...range(0, numOfRows, 1).map(() => null),
  ];
};
