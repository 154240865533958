import { type AsyncStorage } from '@tanstack/react-query-persist-client';
import { clear, del, get, set } from 'idb-keyval';

export function clearIDBCache() {
  clear();
}

export function createIDBAsyncStorage(): AsyncStorage {
  return {
    getItem: async (key) => await get(key),
    setItem: async (key, data) => await set(key, data),
    removeItem: (key: string) => del(key),
  };
}
