import { SectionInfo } from 'src/WebApiController';

import { useEventMapContext } from './EventMapContext';

export const useGetSectionRowFromVenueMapConfig = (
  rowId?: number | null,
  sectionId?: number | null,
  sectionName?: string | null,
  rowName?: string | null
) => {
  const { venueMapInfo } = useEventMapContext();

  if (!venueMapInfo) return {};

  let section: SectionInfo | undefined = undefined;
  if (sectionId) {
    section = venueMapInfo.sections.find((s) => s.id == sectionId);
  }

  if (rowId) {
    let row = section?.rows.find((r) => r.id === rowId);

    if (row) {
      return { section, row };
    }

    section = venueMapInfo.sections.find((s) =>
      s.rows.some((r) => r.id === rowId)
    );

    if (!section) return {};

    row = section.rows.find((r) => r.id === rowId);

    // Row cannot be null, because if it was, the section would have been null
    if (!row) return {};

    return { section, row };
  } else {
    const section = venueMapInfo.sections.find((s) => s.name === sectionName);

    if (!section) return {};

    const row = section.rows.find((r) => r.name === rowName);

    return { section, row };
  }
};
