import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function SellOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M20.583 9.6c-.445-.122-1.012-.122-1.012-.122H10.947V4.619h.932a2.77 2.77 0 002.307 1.215 2.77 2.77 0 002.308-1.215h.931v4.049l1.62-.81V3h-3.523l-.202.445c-.122.324-.405.567-.729.689a1.251 1.251 0 01-1.538-.648L12.89 3H9.368v4.17H8.356c-.607 0-1.214.243-1.66.648L2 12.109v9.757H16.251c.486 0 .972-.202 1.336-.526.162-.162.283-.243.445-.526a2.956 2.956 0 00.446-1.539v-.162c.445-.08.85-.283 1.174-.566l.08-.081a2.293 2.293 0 00.69-1.66v-.972l.08-.081a2.293 2.293 0 00.688-1.66v-.85c.04-.04.04-.04.081-.04.04 0 .04-.041.081-.041A2.14 2.14 0 0022 11.623 2.455 2.455 0 0020.583 9.6zm-.445 2.388a.603.603 0 01-.365.162h-6.72v1.62h6.477v.323c0 .203-.08.364-.202.526-.162.122-.324.203-.526.203h-5.709v1.62h5.668v.323c0 .203-.08.365-.202.526-.162.122-.324.203-.527.203h-4.98v1.62h3.806v.323a.726.726 0 01-.728.729H3.62v-7.328l4.17-3.846a.967.967 0 01.566-.203h1.012v2.309h10.405c.284 0 .527.242.527.525 0 .122-.081.243-.162.365z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
