import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { BulkUngroupListingsDialog } from 'src/dialogs/BulkEdits/BulkUngroupListingsDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UNGROUP_LISTINGS_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchUngroupListings } from './LaunchUngroupListings';

export const useBulkUngroupListings = (
  filterQueryWithEventIds: ListingQuery,

  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const updateKey = event
    ? `inventory-ungroup-listing-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UNGROUP_LISTINGS_UPDATE_KEY;

  const {
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();

  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkUngroupListingsDialog,
    closeDialog: closeBulkUngroupListingsDialog,
  } = useDialog(DialogId.BulkUngroupListings, BulkUngroupListingsDialog);

  const onSubmit = useCallback(
    async (
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUngroupListingsPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUngroupListingsPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUngroupListings(
              preview!.preview,
              updateKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                await refreshExpandedListItems();
              }

              closeBulkUngroupListingsDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUngroupListings', error, {
              trackErrorData: {
                preview,
                error,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkUngroupListingsDialog,
      filterQueryWithEventIds,
      refreshExpandedListItems,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  const onChangeClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (event) {
        setListItemExpansion(true, [event.viagVirtualId]);
      }
      openBulkUngroupListingsDialog({
        updateKey,
        isLoading,
        onOkay: onSubmit,
        entityType: ActionOutboxEntityType.Listing,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkUngroupListingsDialog,
      });
    },
    [
      closeBulkUngroupListingsDialog,
      event,
      isLoading,
      onSubmit,
      openBulkUngroupListingsDialog,
      setIsLoading,
      setListItemExpansion,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchUngroupListings
        key="onSubmitUngroupListings"
        onClick={onChangeClick}
        disabled={isLoading}
      />
    ),
  };
};
