import { TableCellDiv } from 'src/core/ui/TableCellDiv';

import * as styles from './LinkCell.css';

export function LinkCell({
  text,
  url,
  openInNewTab,
  align,
  style,
  separator,
}: {
  text: string | string[];
  url: string | string[];
  openInNewTab?: boolean;
  align: 'left' | 'right' | 'center';
  style?: React.CSSProperties;
  separator?: React.ReactElement;
}) {
  if (!Array.isArray(text) && !Array.isArray(url)) {
    return (
      <TableCellDiv align={align} style={style}>
        <a
          className={styles.textLink}
          href={url}
          target={openInNewTab ? '_blank' : undefined}
          rel={openInNewTab ? 'noreferrer' : undefined}
        >
          {text}
        </a>
      </TableCellDiv>
    );
  }

  const texts = text as string[];
  const urls = url as string[];

  return (
    <TableCellDiv align={align} style={style}>
      {texts.flatMap((text, index) => {
        const url = urls[index];
        if (index < texts.length - 1 && separator) {
          return [
            <a
              key={index}
              className={styles.textLink}
              href={url}
              target={openInNewTab ? '_blank' : undefined}
              rel={openInNewTab ? 'noreferrer' : undefined}
            >
              {text}
            </a>,
            separator,
          ];
        }

        return [
          <a
            key={index}
            className={styles.textLink}
            href={url}
            target={openInNewTab ? '_blank' : undefined}
            rel={openInNewTab ? 'noreferrer' : undefined}
          >
            {text}
          </a>,
        ];
      })}
    </TableCellDiv>
  );
}
