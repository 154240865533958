import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function NotifyIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M12 3l8.327 14.408-1.47.735L12 6.265 4.816 18.673h16.245L22 20.306H2L12 3zm-.082 13.061a.816.816 0 110 1.633.816.816 0 010-1.633zm.817-5.714v4.898h-1.633v-4.898h1.633z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
