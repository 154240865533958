import clsx from 'clsx';
import { isEqual, uniq } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { typography } from 'src/core/themes/shared.css';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { TableColumnsInput } from 'src/modals/ColumnSettings/TableColumnsInput';
import { LISTING_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/ListingTable/configs/ListingReportTableColumnsConfig';
import { filterColumnsByFeatures } from 'src/utils/columns/columnUtils';
import { isMetricConfigurableByGroupBy } from 'src/utils/columns/inventory/inventoryColumnUtils';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { filterCustomColumnsForListingReport } from 'src/utils/columns/inventory/inventoryReportCustomColumnUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import {
  ActionOutboxEntityType,
  ReportGroupBy,
  UserSetting,
} from 'src/WebApiController';

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '../../../modals/Modal';
import * as styles from './MetricsSelectionInputModal.css';

export const InventoryMetricsSelectionInputModal = ({
  onClose,
  headerContentId,
  groupBy,
}: {
  onClose: () => void;
  headerContentId?: ContentId;
  groupBy?: ReportGroupBy;
}) => {
  const { appContext, loginContext } = useAppContext();
  const { setValue, watch, clearErrors } = useFormContext<ReportConfig>();
  const [isOpen, toggleIsOpen] = useToggle(true);

  const {
    value: customListingColumns = [],
    setUserSetting: setCustomListingColumns,
  } = useServerUserSetting<CustomListingColumn[]>({
    id: UserSetting.InventoryCustomColumns,
  });

  const { tagsMetadataNumeric } = useTagsForEntityType(
    ActionOutboxEntityType.Listing,
    true
  );

  const { tagsMetadata: tagsMetadataEvent } = useTagsForEntityType(
    ActionOutboxEntityType.SellerEvent,
    true
  );

  const { tagsMetadata: tagsMetadataPurchase } = useTagsForEntityType(
    ActionOutboxEntityType.Purchase,
    true
  );

  const customListingColumnsFiltered = filterCustomColumnsForListingReport(
    customListingColumns,
    tagsMetadataNumeric
  );

  const reportingTags = useMemo(() => {
    if (groupBy === ReportGroupBy.Event) {
      return [...(tagsMetadataNumeric ?? []), ...(tagsMetadataEvent ?? [])];
    } else if (groupBy === ReportGroupBy.PurchaseId) {
      return [...(tagsMetadataNumeric ?? []), ...(tagsMetadataPurchase ?? [])];
    }

    return tagsMetadataNumeric;
  }, [groupBy, tagsMetadataEvent, tagsMetadataNumeric, tagsMetadataPurchase]);

  const metrics = watch('metrics');

  const presetColumnIds = Object.values(LISTING_REPORT_TABLE_COLUMNS_CONFIG)
    .map((i) => i.id)
    .filter((id) => {
      if (!id) return false;

      return isMetricConfigurableByGroupBy(id, groupBy);
    }) as string[];

  const activeTagNames = useMemo(
    () => reportingTags?.map((t) => t.key) ?? [],
    [reportingTags]
  );

  const customColumnIds = customListingColumnsFiltered.map((c) => c.id);

  const filterColumns = useCallback(
    (columnList: string[]) => {
      return columnList.filter(
        (m) =>
          presetColumnIds.includes(m) ||
          activeTagNames.includes(m) ||
          customColumnIds.includes(m)
      );
    },
    [activeTagNames, customColumnIds, presetColumnIds]
  );

  const metricsFiltered = useMemo(
    () => filterColumns(metrics),
    [filterColumns, metrics]
  );

  const [userColumnSelection, setUserColumnSelection] = useState<string[]>(
    metricsFiltered ?? []
  );

  const storedColumnOrderSettingFiltered = useMemo(() => {
    const allColumnIdsAvailable = filterColumns(
      uniq([
        ...metricsFiltered,
        ...presetColumnIds.sort((a, b) => a.localeCompare(b)),
        ...customColumnIds.sort((a, b) => a.localeCompare(b)),
        ...activeTagNames.sort((a, b) => a.localeCompare(b)),
      ])
    );

    const existingColumnsOrderSetting = filterColumns(
      metricsFiltered as string[]
    );

    allColumnIdsAvailable.forEach((columnId) => {
      if (!existingColumnsOrderSetting.includes(columnId)) {
        existingColumnsOrderSetting.push(columnId);
      }
    });

    return existingColumnsOrderSetting;
  }, [
    activeTagNames,
    customColumnIds,
    filterColumns,
    metricsFiltered,
    presetColumnIds,
  ]);

  const [userColumnSorting, setUserColumnSorting] = useState<string[]>(
    filterColumnsByFeatures(
      storedColumnOrderSettingFiltered,
      SectionType.ListingsReport,
      customListingColumns,
      loginContext?.user,
      appContext?.features,
      reportingTags,
      groupBy
    )
  );

  const [userColumnIsDirty, setUserColumnIsDirty] = useState(false);

  const onSaveHandler = () => {
    const sortedColumnSelection = userColumnSorting.filter((c) =>
      userColumnSelection.includes(c)
    );
    setValue('metrics', sortedColumnSelection);
    clearErrors('metrics');
    toggleIsOpen();
  };

  useEffect(() => {
    if (!isOpen) setTimeout(onClose, 300);
  }, [isOpen, onClose]);

  // Sync the user setting to the default if the user has never set it
  useEffect(() => {
    const newColumns = filterColumnsByFeatures(
      storedColumnOrderSettingFiltered,
      SectionType.ListingsReport,
      customListingColumns,
      loginContext?.user,
      appContext?.features,
      reportingTags,
      groupBy
    );

    if (!userColumnIsDirty) {
      if (!isEqual(newColumns, userColumnSorting)) {
        setUserColumnSorting(newColumns);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customListingColumns, activeTagNames, storedColumnOrderSettingFiltered]);

  // Keep the columns up to date in case the user changes the settings (e.g. deletes custom column)
  useEffect(() => {
    const allColumnIdsAvailable = filterColumns(
      uniq([
        ...metricsFiltered,
        ...presetColumnIds.sort((a, b) => a.localeCompare(b)),
        ...customColumnIds.sort((a, b) => a.localeCompare(b)),
        ...activeTagNames.sort((a, b) => a.localeCompare(b)),
      ])
    );

    let userColumnSortingFiltered = filterColumns(userColumnSorting);

    allColumnIdsAvailable.forEach((columnId) => {
      if (!userColumnSortingFiltered.includes(columnId)) {
        userColumnSortingFiltered.push(columnId);
      }
    });

    userColumnSortingFiltered = filterColumnsByFeatures(
      storedColumnOrderSettingFiltered,
      SectionType.ListingsReport,
      customListingColumns,
      loginContext?.user,
      appContext?.features,
      reportingTags,
      groupBy
    );

    if (
      !isEqual(new Set(userColumnSortingFiltered), new Set(userColumnSorting))
    ) {
      setUserColumnSorting(userColumnSortingFiltered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customColumnIds]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={toggleIsOpen}
        clickOutsideToClose
        unmountOnClose
        centered
        size="sm"
      >
        <ModalHeader onClose={toggleIsOpen}>
          <div className={styles.headerBar}>
            <h5 className={clsx(typography.title5, styles.headerText)}>
              <Content id={headerContentId ?? ContentId.ColumnSettings} />
            </h5>
          </div>
        </ModalHeader>
        <ModalBody>
          <TableColumnsInput<CustomListingColumn>
            userColumnSelection={userColumnSelection}
            onUserColumnSelectionChange={(newSelection) => {
              setUserColumnSelection(newSelection);
              setUserColumnIsDirty(true);
            }}
            userColumnSorting={userColumnSorting}
            onUserColumnSortingChange={(newSorting) => {
              setUserColumnSorting(newSorting);
              setUserColumnIsDirty(true);
            }}
            onCustomColumnsChange={setCustomListingColumns}
            customColumns={customListingColumnsFiltered}
            reportingTags={reportingTags}
            sectionType={SectionType.ListingsReport}
            groupBy={groupBy}
          />
        </ModalBody>
        <ModalFooter>
          <CancelButton
            textContentId={ContentId.Cancel}
            onClick={toggleIsOpen}
          />
          <OkButton onClick={onSaveHandler} textContentId={ContentId.Save} />
        </ModalFooter>
      </Modal>
    </>
  );
};
