import { Content } from 'src/contexts/ContentContext';
import { Detail, DetailGroup, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { formatSeatDisplay } from 'src/utils/ticketUtils';
import { SaleDetails } from 'src/WebApiController';

interface TicketsSoldAsProps {
  sale: SaleDetails;
}

export const TicketsSoldAs = ({ sale }: TicketsSoldAsProps) => {
  if (!sale.mkpSeating) {
    return null;
  }
  const { section, row, seatFr, seatTo } = sale.mkpSeating;
  return (
    <SectionContent numOfColumns={4}>
      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.Section} />}
          detail={section}
        />
      </DetailGroup>

      <DetailGroup>
        <Detail label={<Content id={ContentId.Row} />} detail={row} />
      </DetailGroup>

      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.Seats} />}
          detail={<>{formatSeatDisplay(seatFr, seatTo)}</>}
        />
      </DetailGroup>

      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.Quantity} />}
          detail={<strong>x {sale.qtySold}</strong>}
        />
      </DetailGroup>
    </SectionContent>
  );
};
