import { debounce } from 'lodash-es';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormText } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextArea } from 'src/core/POS/PosTextArea';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { TicketBarcode } from 'src/WebApiController';

import { BarcodeForm } from '../UploadBarcodes.utils';
import {
  BulkUploadWrapper,
  InstructionsWrapper,
} from './BulkBarcodeInput.styled';

export const BulkBarcodeInput = ({
  quantity,
  readOnly = false,
  onPreview,
}: {
  quantity: number;
  onPreview?: () => void;
  readOnly?: boolean;
}) => {
  const {
    register,
    trigger,
    setValue,
    watch,
    formState: { errors, touchedFields },
  } = useFormContext<BarcodeForm>();
  const barcodes = watch('barcodes');

  const initialValue = useMemo(() => {
    const barcodesArray = barcodes.map(({ barcode }: TicketBarcode) => barcode);
    return barcodesArray.join('\n').trimEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    /**
     * Registering this was since the dispay value differs from how it is stored
     * ie. string for text area vs TicketBarcode[]
     */
    register('barcodes');

    // loaded from a toggle, show errors
    if (touchedFields?.barcodes?.length) {
      trigger('barcodes');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = useCallback(
    (e: React.SyntheticEvent) => {
      debounce((e: React.SyntheticEvent) => {
        const newBarcodes = (e.target as HTMLInputElement).value
          .trimEnd()
          .split('\n');

        if (newBarcodes.length < quantity) {
          newBarcodes.push(
            ...new Array(quantity - newBarcodes.length).fill('')
          );
        }

        setValue(
          'barcodes',
          // create array of barcodes in original insert order
          newBarcodes.map((barcode, idx) => ({
            // in order to display "Too many" error, set null
            ...barcodes[idx],
            barcode,
          })),
          { shouldValidate: true, shouldTouch: true, shouldDirty: true }
        );
      }, 100)(e);
    },
    [setValue, barcodes, quantity]
  );

  const barcodesError = errors?.barcodes?.message;

  return (
    <BulkUploadWrapper>
      <PosFormField errors={barcodesError}>
        <PosTextArea
          rootProps={{
            disabled: readOnly,
            state: getTextFieldState(barcodesError),
          }}
          disabled={readOnly}
          onChange={handleChange}
          defaultValue={initialValue}
          style={{ resize: 'vertical' }}
        />
      </PosFormField>
      {!readOnly && (
        <InstructionsWrapper>
          <FormText>
            <Content id={ContentId.EachBarcodeOnNewLine} />
          </FormText>
          {onPreview && (
            <Button variant={'text'} onClick={onPreview}>
              <Content id={ContentId.Preview} />
            </Button>
          )}
        </InstructionsWrapper>
      )}
    </BulkUploadWrapper>
  );
};
