import { PosIconProps, SvgWrapper } from 'src/svgs/SvgWrapper';

export function ChevronDownIcon(props: PosIconProps) {
  return (
    <SvgWrapper
      aria-label="ChevronDownIcon"
      fill="currentColor"
      {...props}
      viewBox="0 0 15 16"
    >
      <path d="M14.384 6.286q0 .473-.33.804l-5.813 5.813q-.339.339-.813.339-.482 0-.804-.339L.811 7.09q-.339-.321-.339-.804 0-.473.339-.813l.661-.67q.348-.33.813-.33.473 0 .804.33l4.339 4.339 4.339-4.339q.33-.33.804-.33.464 0 .813.33l.67.67q.33.348.33.813z" />
    </SvgWrapper>
  );
}
