import clsx from 'clsx';
import { useCallback } from 'react';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { GametimeLogo, StubHubLogo, TickPickLogo, ViagogoLogo } from 'src/svgs';
import { AutomatiqLogo } from 'src/svgs/AutomatiqLogo';
import { AxsLogo } from 'src/svgs/AxsLogo';
import { SeatGeekLogo } from 'src/svgs/SeatGeekLogo';
import { TicketmasterLogo } from 'src/svgs/TicketmasterLogo';
import { VividSeatsLogo } from 'src/svgs/VividSeatsLogo';
import {
  MarketplaceEventIdPlaceholder,
  MarketplaceEventUrlTemplate,
} from 'src/utils/constants/constants';
import { getIsInternationalEvent } from 'src/utils/eventWithDataUtils';
import { getStubhubEventWithPricingUrl } from 'src/utils/inventoryUtils';
import {
  Event,
  Marketplace,
  SellerEventMarketplaceEventInfo,
} from 'src/WebApiController';

import * as styles from './MarketplaceLogo.css';

export const logoAreaDimensions = { height: '30px', width: 'auto' };
export const logoDimensions = {
  [Marketplace.Ticketmaster]: { height: 30, width: 80 },
  [Marketplace.VividSeats]: { height: 30, width: 80 },
  [Marketplace.SeatGeek]: { height: 30, width: 47 },
  [Marketplace.Gametime]: { height: 30, width: 80 },
  [Marketplace.TickPick]: { height: 30, width: 80 },
  [Marketplace.AXS]: { height: 30, width: 80 },
  [Marketplace.Automatiq]: { height: 30, width: 80 },
};

export const MarketplaceLogo = ({
  marketplace,
  isViagogo,
  event,
  grayScaleColor,
  marketplaceEventInfo,
  onClick,
}: {
  marketplace?: Marketplace | null;
  isViagogo?: boolean;
  event?: Event;
  grayScaleColor?: boolean;
  marketplaceEventInfo?: SellerEventMarketplaceEventInfo | null;
  /**
   * Override of the onClick event for the logo. By default, the logo will open the event in the marketplace.
   */
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  const { isDarkMode } = useSiteTheme();
  const { venue } = useGetEventFullInfo(event);

  const isInternationalEvent = getIsInternationalEvent(venue?.country?.code);

  const onOpenInStubHubClicked = useCallback(() => {
    if (marketplace === Marketplace.StubHub) {
      window.open(
        getStubhubEventWithPricingUrl(event?.viagId, isInternationalEvent),
        '_blank'
      );
    } else if (marketplaceEventInfo?.marketplace) {
      let urlTemplate =
        MarketplaceEventUrlTemplate[marketplaceEventInfo.marketplace];

      let urlEventId =
        marketplaceEventInfo.eventIdOverride ??
        marketplaceEventInfo.mappedEventId ??
        '';
      if (marketplace === Marketplace.Ticketmaster) {
        urlEventId = marketplaceEventInfo.websiteEventId ?? urlEventId;
      }
      if (urlEventId) {
        urlTemplate = urlTemplate.replace(
          MarketplaceEventIdPlaceholder,
          urlEventId
        );
        window.open(urlTemplate, '_blank', 'noopener noreferrer');
      }
    }
  }, [event?.viagId, isInternationalEvent, marketplace, marketplaceEventInfo]);

  return (
    <div
      className={clsx(styles.logoContainer, {
        [styles.grayscaleLogo]: grayScaleColor,
        [styles.clickable]: onClick || event,
      })}
      onClick={
        onClick ??
        ((e) => {
          e.preventDefault();
          e.stopPropagation();
          if (event != null) {
            onOpenInStubHubClicked();
          }
        })
      }
    >
      {marketplace === Marketplace.StubHub ? (
        isViagogo ? (
          <ViagogoLogo {...logoAreaDimensions} />
        ) : (
          <StubHubLogo
            {...logoAreaDimensions}
            colorMode={isDarkMode ? 'darkBackground' : 'lightBackground'}
          />
        )
      ) : marketplace === Marketplace.Ticketmaster ? (
        <TicketmasterLogo {...logoDimensions[marketplace]} />
      ) : marketplace === Marketplace.VividSeats ? (
        <VividSeatsLogo {...logoDimensions[marketplace]} />
      ) : marketplace === Marketplace.SeatGeek ? (
        <SeatGeekLogo {...logoDimensions[marketplace]} />
      ) : marketplace === Marketplace.Gametime ? (
        <GametimeLogo {...logoDimensions[marketplace]} />
      ) : marketplace === Marketplace.TickPick ? (
        <TickPickLogo {...logoDimensions[marketplace]} />
      ) : marketplace === Marketplace.AXS ? (
        <AxsLogo {...logoDimensions[marketplace]} />
      ) : marketplace === Marketplace.Automatiq ? (
        <AutomatiqLogo {...logoDimensions[marketplace]} />
      ) : (
        <div>{marketplace ?? 'Unknown Marketplace'}</div>
      )}
    </div>
  );
};
