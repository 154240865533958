import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { SomethingWentWrong } from 'src/views';

import * as styles from './OfflineWrapper.css';

export const Offline = () => {
  return (
    <div className={styles.offlineWrapper}>
      <SomethingWentWrong
        header={<Content id={ContentId.Error_BadGatewayError_Header} />}
        message={
          <Content id={ContentId.Error_BadGatewayError_GenericMessage} />
        }
      />
    </div>
  );
};
