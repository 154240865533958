import { MarketplacePaymentsTabContent } from 'src/components/Payments/Tabs/MarketplacePaymentsTabContent';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { MarketplacePaymentDataContextProvider } from 'src/contexts/MarketplacePaymentDataContext';
import {
  DefaultMarketplacePaymentQuery,
  EmptyMarketplacePaymentQuery,
} from 'src/navigations/Routes/Payments/Payments.constants';
import {
  marketplacePaymentQueryValueTransformToUrl,
  marketplacePaymentValueTransformFromUrl,
} from 'src/navigations/Routes/Payments/Payments.utils';
import { MarketplacePaymentQuery, UserSetting } from 'src/WebApiController';

export const MarketplacePaymentsTab = () => {
  return (
    <FilterQueryContextProvider<MarketplacePaymentQuery>
      initialQuery={DefaultMarketplacePaymentQuery}
      viewModeSettingId={UserSetting.PurchasePageViewMode}
      emptyQuery={EmptyMarketplacePaymentQuery}
      queryValueTransformToUrl={marketplacePaymentQueryValueTransformToUrl}
      queryValueTransformFromUrl={marketplacePaymentValueTransformFromUrl}
      saveQueryInUrl
    >
      <MarketplacePaymentDataContextProvider>
        <MarketplacePaymentsTabContent />
      </MarketplacePaymentDataContextProvider>
    </FilterQueryContextProvider>
  );
};
