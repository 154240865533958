import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const EventSearchDropdownResultItemHeader = styled.div`
  font-weight: 700;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};
  margin-inline-start: 0;
  margin-inline-end: 0;

  display: flex;
  align-items: center;
  justify-content: left;

  &:not(:first-child) {
    padding-top: ${() => vars.spacing.lg};
  }

  svg {
    padding-right: ${() => vars.spacing.sm};
  }
`;
