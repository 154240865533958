import { MouseEvent, ReactNode, useMemo } from 'react';
import { useToggle } from 'react-use';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { SalesTableFlattened } from 'src/tables/SalesTable/SalesTableFlattened';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { SaleQuery } from 'src/WebApiController';

type SalesFlattenedViewProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
  /**
   * If provided, the list will scroll to the month containing the event with the given posEventId
   */
  scrollToEventId?: string;
};

export const SalesFlattenedView = ({
  before,
  topListItemBefore,
}: SalesFlattenedViewProps) => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  const { filterQuery } = useFilterQueryContext<SaleQuery>();
  const { eventsTransformed } = useCatalogDataContext();
  const { salesCount } = useMemo(() => {
    let salesCount = 0;

    (eventsTransformed ?? []).forEach((ev) => {
      salesCount += ev.salesCnt;
    });

    return { salesCount };
  }, [eventsTransformed]);

  const sales = eventsTransformed?.every((ev) => ev.sales == null)
    ? null
    : eventsTransformed?.flatMap((ev) => ev.sales);

  return (
    <Stack direction="column" height="full" width="full">
      {isColumnModalOpen ? (
        <ColumnSettingsModal<CustomListingColumn>
          onClose={toggleColumnModal}
          sectionType={SectionType.SalesFlattened}
          showTicketClassColor
        />
      ) : null}
      {before}
      {topListItemBefore}
      <Stack justifyContent="end">
        <Button variant="textPlain" onClick={onColumnSettingButtonClickHandler}>
          <LayoutIcon size={vars.iconSize.m} />
          <Content id={ContentId.Columns} />
        </Button>
      </Stack>
      <SalesTableFlattened
        useVirtuoso
        disablePagination
        salesCount={
          filterQuery.entityIds?.length
            ? filterQuery.entityIds.length
            : filterQuery.marketplaceEntityIds?.length
            ? filterQuery.marketplaceEntityIds.length
            : salesCount
        }
        sales={sales?.filter((s) => s != null).map((s) => s!)}
        failedToRetrieveData={false}
      />
    </Stack>
  );
};
