import { RecipeVariants } from '@vanilla-extract/recipes';
import { ComponentPropsWithoutRef, forwardRef, PropsWithChildren } from 'react';
import { mergeProps } from 'src/core/utils';

import * as styles from './Stack.css';

export type StackProps = ComponentPropsWithoutRef<'div'> &
  PropsWithChildren<RecipeVariants<typeof styles.stack>>;

/**
 * Basically a wrapper around flexbox. Styling with flex is very common, so this component allows
 * for flexbox without a new style.
 * @param props
 */
export const Stack = forwardRef<HTMLDivElement, StackProps>(function Stack(
  {
    direction,
    gap,
    justifyContent,
    flexWrap,
    alignItems,
    width,
    height,
    children,
    ...props
  },
  ref
) {
  return (
    <div
      ref={ref}
      {...mergeProps(
        {
          className: styles.stack({
            direction,
            gap,
            justifyContent,
            flexWrap,
            alignItems,
            width,
            height,
          }),
        },
        props
      )}
    >
      {children}
    </div>
  );
});
