import Rainbow from 'rainbowvis.js';
import { useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './ColorBand.css';

export type ColorBandProps = {
  numOfSteps: number;
  spectrum: string[];
  labels?: ContentId[] | string[];
};

export const ColorBand = ({ numOfSteps, spectrum, labels }: ColorBandProps) => {
  const colorSteps = useMemo(() => {
    const rainbow = new Rainbow();
    rainbow.setNumberRange(1, numOfSteps);
    rainbow.setSpectrumByArray(spectrum);

    const colorSteps = [];
    for (let i = 0; i < numOfSteps; i++) {
      const color = rainbow.colorAt(i + 1);
      colorSteps.push(<ColorStep key={i} color={`#${color}`} />);
    }
    return colorSteps;
  }, [numOfSteps, spectrum]);

  return (
    <div>
      <div className={styles.colorStepsContainer}>{colorSteps}</div>
      <div className={styles.colorLabelsContainer}>
        {labels?.map((l, i) => (
          <span key={`${l}-${i}`}>{<Content id={l} />}</span>
        ))}
      </div>
    </div>
  );
};

const ColorStep = ({ color }: { color: string }) => {
  return (
    <div className={styles.colorStep} style={{ backgroundColor: color }}></div>
  );
};
