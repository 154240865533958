import { useState } from 'react';
import { ExportPurchasesButton } from 'src/components/Buttons';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { usePurchaseTileBulkActionsState } from 'src/components/Purchases/BulkActions/PurchasesTileBulkActions/usePurchaseTileBulkActionsState';
import { useBulkCancelPurchase } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkCancelPurchase';
import { useBulkChangeEventAction } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkChangeEventAction';
import { useBulkDeleteTicketGroup } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkDeleteTicketGroup';
import { useBulkEditCommissionAction } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkEditCommissionAction';
import { useBulkUpdateDeliveryType } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkUpdateDeliveryType';
import { useBulkUpdateInternalNotesAction } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkUpdateInternalNotesAction';
import { useBulkUpdatePurchaseSettings } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkUpdatePurchaseGeneralSettings';
import { useBulkUpdateSeatTraits } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkUpdateSeatTraits';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';

import { PurchasesBulkActionProps } from '../../PurchasesGlobalBulkActions/PurchasesBulkActions.types';
import { useUpdatePurchasesPermissions } from '../../useUpdatePurchasesPermissions';

type SettingsActionsProps = {
  groupId: string;
};

export const SettingsActions = ({
  groupId,
  isEventView,
}: SettingsActionsProps & PurchasesBulkActionProps) => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const [isLoading, setIsLoading] = useState(false);

  const { filterQueryWithEventIds } = usePurchaseTileBulkActionsState(
    groupId,
    isEventView
  );
  const hasExportPurchasesFeature = useUserHasFeature(Feature.ExportPurchases);

  const bulkUpdateGeneralSettings = useBulkUpdatePurchaseSettings(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    groupId
  );

  const bulkUpdateDeliveryType = useBulkUpdateDeliveryType(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    groupId,
    isEventView
  );

  const bulkUpdateSeatTraits = useBulkUpdateSeatTraits(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    groupId,
    isEventView
  );

  const bulkUpdateInternalNotes = useBulkUpdateInternalNotesAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    groupId
  );

  const bulkEditCommissionAction = useBulkEditCommissionAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    groupId
  );

  const bulkCancelPurchaseAction = useBulkCancelPurchase(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    groupId
  );

  const bulkDeleteTicketGroupAction = useBulkDeleteTicketGroup(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    groupId
  );

  const bulkChangeEvent = useBulkChangeEventAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    groupId
  );

  const {
    hasUpdatePurchasesPermission,
    hasPurchaseUpdateCommissionPermission,
  } = useUpdatePurchasesPermissions();

  if (!hasUpdatePurchasesPermission && !hasPurchaseUpdateCommissionPermission) {
    return null;
  }

  return (
    <>
      <PosDropdown
        key="purchases-settings-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Settings} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {!isEventView && bulkUpdateGeneralSettings.dropDown}

        {bulkUpdateDeliveryType.dropDown}
        {bulkUpdateSeatTraits.dropDown}

        {!isEventView && bulkUpdateInternalNotes.dropDown}
        {!isEventView && bulkEditCommissionAction.dropDown}
        {!isEventView && bulkCancelPurchaseAction.dropDown}

        {isEventView && bulkDeleteTicketGroupAction.dropDown}
        {isEventView && bulkChangeEvent.dropDown}

        {hasExportPurchasesFeature && (
          <PosDropdownItem key="ExportPurchase">
            <ExportPurchasesButton
              style={{ color: vars.color.textStrong, width: '100%' }}
              variant="text"
              shape="rect"
              filterQuery={filterQueryWithEventIds}
              disabled={!areBulkActionsEnabled}
              textContentId={ContentId.ExportPurchases}
              textOnly
              isEventView={isEventView ?? false}
            />
          </PosDropdownItem>
        )}
      </PosDropdown>
      {isEventView && bulkChangeEvent.dialog}
    </>
  );
};
