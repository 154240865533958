import Rainbow from 'rainbowvis.js';
import { useMemo } from 'react';
import { VenueMapContentProps } from 'src/components/Events/VenueMap/VenueMapContent';
import { getMedianScoreForSection } from 'src/utils/seatScoreUtils';
import { SectionScoreOverride } from 'src/WebApiController';

export const HeatMapColorSteps = 100; // The heat map will be normalized to values 1 to 100
export const HeatMapColors = ['780000', 'C80000', 'FF8200', 'FFFF8C'];
const HeatMapColorForNoScore = 'FFFFFF';

export function createGetColor(
  seatScores?: SectionScoreOverride[] | null
): NonNullable<VenueMapContentProps['getColor']> {
  return ({ sectionId, rowId, useRowColor }) => {
    const rainbow = new Rainbow();
    rainbow.setNumberRange(1, HeatMapColorSteps);
    rainbow.setSpectrumByArray(HeatMapColors);

    if (seatScores?.length) {
      const scores = seatScores
        .filter((ss) => ss.score != null)
        .map((ss) => ss.score!);
      // We have seat scores
      const min = Math.min(...scores);
      const max = Math.max(...scores);
      if (max >= min) {
        const range = max - min;
        if (useRowColor && rowId) {
          const rowScore = seatScores.find((ss) => ss.rowId === rowId)?.score;
          if (rowScore != null) {
            const normalizedScore =
              (range === 0 ? rowScore : (rowScore - min) / range) *
              HeatMapColorSteps;
            const color = rainbow.colorAt(HeatMapColorSteps - normalizedScore);
            return { fill: `#${color}`, stroke: 'none' };
          }
          return { fill: 'white', stroke: 'none' };
        }

        const score = getMedianScoreForSection(sectionId, seatScores);
        if (score > 0) {
          const normalizedScore =
            (range === 0 ? score : (score - min) / range) * HeatMapColorSteps;
          // We want to flip the color band since we have the 0 as the high-quality
          const color = rainbow.colorAt(HeatMapColorSteps - normalizedScore);
          return { fill: `#${color}`, stroke: 'black' };
        } else {
          // We only use the rainbow when we actually have a score
          const color = HeatMapColorForNoScore;
          return { fill: `#${color}`, stroke: 'black' };
        }
      } else {
        console.log(`Max ${max} < Min ${min}.`);
      }
    }

    return { fill: 'white', stroke: 'black' };
  };
}

export const useHeatMapColor = (sections?: SectionScoreOverride[] | null) => {
  const getColor = useMemo(() => createGetColor(sections), [sections]);
  return { getColor };
};
