import { ComponentType } from 'react';
import { vars } from 'src/core/themes';
import { BASE_LIGHT_PALETTE } from 'src/core/themes/legacy/shared/palette/common-palette-light';
import { HistoryIcon as HistoryIconOriginal } from 'src/svgs/Viagogo';
import styled from 'styled-components/macro';

/**
 * XXX: Fix for `HistoryIcon` size and positioning.
 */
const HistoryIconFix = styled.div`
  path {
    scale: 1.35;
    transform: translate(-1px, -1px);
  }
`;

type HistoryIconProps = Parameters<typeof HistoryIconOriginal>[0];

/**
 * XXX: Fix because `HistoryIcon` is typed incorrectly.
 */
const HistoryIconFixedType =
  HistoryIconOriginal as unknown as ComponentType<HistoryIconProps>;

export const HistoryIcon = (props: HistoryIconProps) => {
  return (
    <HistoryIconFix>
      <HistoryIconFixedType {...props} />
    </HistoryIconFix>
  );
};

export const UpdatedByBannerWrapper = styled.div`
  background-color: ${BASE_LIGHT_PALETTE.surfaceSecondary};
  color: ${BASE_LIGHT_PALETTE.textSecondaryOnSurface};
  padding: ${vars.spacing.m} ${vars.spacing.lg};
  line-height: normal;
  border-radius: ${vars.borderRadius.m};
`;
