import { MutableRefObject, useImperativeHandle } from 'react';
import { PurchaseVendorAccountDialog } from 'src/dialogs/PurchaseVendorAccountDialog';
import { PurchaseVendorAccountActions } from 'src/modals/VendorDetails/components/VendorAccounts/components/VendorAccountsTable/VendorsAccountsTableColumnsConfig';
import { useAddVendorAccountActions } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorActions/useAddVendorAccountActions';
import { OnVendorAccountSavedFn } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useAddVendorAccount';
import { PurchaseVendorAccount } from 'src/WebApiController';

export type VendorAccountActionsImperativeRef = PurchaseVendorAccountActions;

interface VendorAccountsActionsProps {
  vendorAccountsActionsRef: MutableRefObject<VendorAccountActionsImperativeRef | null>;
  onVendorAccountSaved: OnVendorAccountSavedFn;
}

export const VendorAccountsActions = ({
  vendorAccountsActionsRef,
  onVendorAccountSaved,
}: VendorAccountsActionsProps) => {
  const {
    onEditVendorAccount,
    venderAccountDialog,
    currentVendorAccount,
    onVendorAccountCancel,
    onSaveVendorAccount,
  } = useAddVendorAccountActions({ onVendorAccountSaved });

  useImperativeHandle(
    vendorAccountsActionsRef,
    () => {
      return {
        editVendorAccount: (vendorAccount: PurchaseVendorAccount) => {
          onEditVendorAccount(vendorAccount);
        },
      };
    },
    [onEditVendorAccount]
  );

  return (
    <>
      {currentVendorAccount && (
        <PurchaseVendorAccountDialog
          {...venderAccountDialog.dialogProps}
          vendorAccountId={currentVendorAccount.id}
          vendorId={currentVendorAccount.vendorId}
          onClosed={onVendorAccountCancel}
          onSave={onSaveVendorAccount}
        />
      )}
    </>
  );
};
