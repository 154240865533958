import { Getter } from '@tanstack/react-table';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { SectionType } from 'src/utils/types/sectionType';

import {
  SHIMMERING_DIV_HEIGHT_LISTING_REPORT,
  SHIMMERING_DIV_HEIGHT_SALE_REPORT,
} from './ReportTableCommon.constants';

/**
 * Use this component to render any cell with text, as long as getValue() returns string.
 * @param param0
 * @returns
 */
export function TextCell<T>({
  getValue,
  row: { original },
  sectionType,
  align,
  style,
}: {
  getValue: Getter<string>;
  row: { original: T | null };
  sectionType: SectionType.SalesReport | SectionType.ListingsReport;
  align: 'left' | 'right' | 'center';
  style?: React.CSSProperties;
}) {
  const shimmeringDivHeight =
    sectionType === SectionType.SalesReport
      ? SHIMMERING_DIV_HEIGHT_SALE_REPORT
      : SHIMMERING_DIV_HEIGHT_LISTING_REPORT;
  if (!original) {
    return <TableShimmeringDiv height={shimmeringDivHeight} />;
  }

  return (
    <TableCellDiv align={align} style={style}>
      {getValue()}
    </TableCellDiv>
  );
}
