import { isEqual } from 'lodash-es';
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { NO_GROUP_ID } from 'src/contexts/MultiSelectionContext/MultiSelectionContext';
import {
  GroupStateInput,
  InputId,
  MultiSelectionContextType,
  SelectionMode,
  TableRow,
} from 'src/contexts/MultiSelectionContext/MultiSelectionContext.types';
import { useKeyPress } from 'src/hooks/useKeyPress';
import { NOT_FOUND_INDEX } from 'src/utils/constants/global';

export interface IShiftKeySelection {
  setShiftKeyRowSelectionState: (
    rowId: InputId, // Row ID clicked
    rows: TableRow[], // Table rows
    groupId: InputId // Group ID the row belongs to
  ) => void;
  setShiftKeyGroupSelectionState: (groupId: InputId) => void;
  clearShiftKeySelection: () => void;

  // All groups IDs of the tables
  allGroupsIds: string[];

  // Checks if NoGroupId is present in all groups arrays
  isNoGroupIdSet: () => boolean;
}

interface ShiftKeySelectionProps {
  allGroupsIds: string[];
  shiftKeySelectionRef: MutableRefObject<ShiftKeyImperativeRef | null>;
  selectionMode?: SelectionMode;
  getGroupToggleState: MultiSelectionContextType['getGroupToggleState'];
  setGroupItems: MultiSelectionContextType['setGroupItems'];
  toggleGroup: MultiSelectionContextType['toggleGroup'];
}

export interface ShiftKeyImperativeRef {
  clearShiftKeySelection: () => void;
  setShiftKeyGroupSelectionState: IShiftKeySelection['setShiftKeyGroupSelectionState'];
}

export const NO_ROW_SELECTED_INDEX = -1;

type RowIndexSelection = {
  groupIndex: number;
  rowIndex: number;
  tableRows: TableRow[];
};

type ShiftKeySelection = {
  lastRow: RowIndexSelection; // Last row the use clicked without shift key
  shiftRow: RowIndexSelection; // Row selected with shift key
};

export const defaultShiftSelection: ShiftKeySelection = {
  lastRow: {
    rowIndex: NO_ROW_SELECTED_INDEX,
    groupIndex: NO_ROW_SELECTED_INDEX,
    tableRows: [],
  },
  shiftRow: {
    rowIndex: NO_ROW_SELECTED_INDEX,
    groupIndex: NO_ROW_SELECTED_INDEX,
    tableRows: [],
  },
};

type PerfRefProps = Pick<
  ShiftKeySelectionProps,
  | 'getGroupToggleState'
  | 'setGroupItems'
  | 'selectionMode'
  | 'allGroupsIds'
  | 'toggleGroup'
> & {
  isShiftKeyPressed: boolean;
  shiftKeySelection: ShiftKeySelection;
  clearShiftKeySelection: () => void;
};

const isCleanState = (shiftKeySelection: ShiftKeySelection) => {
  return isEqual(shiftKeySelection, defaultShiftSelection);
};

/**
 * ShiftKey selection rows
 *
 * How to use it:
 * * When clicking a checkbox in a row, call "setShiftKeyRowSelectionState" with
 * the rowID, groupId and all rows of the table.
 * * When clicking a checkbox that represents an entire group, call
 * "setShiftKeyGroupSelectionState" with the groupId.
 *
 * How it works:
 * For selecting rows from one table to another and all in between, we need to
 * know the overall structure of how this is rendered. Usually, a table belongs
 * to a group, so the UI is rendering a group with a table inside.
 * The internal state is conformed by 2 objects with the same structure
 * (selected rowId, groupId the row belongs to, table Rows) and a list of all
 * groups Ids with the same order they are rendered. So, to go
 * from table 1 row 2 to table 4 row 4, we need to select from table1.row2
 * to the end of that table, then groups 2 & 3 and then from table4.row0 to
 * table4.row4.
 * If the user selects (with or without pressing shift Key) an entire group,
 * this is stored with `rowIndex` with `NO_ROW_SELECTED_INDEX` value
 * (this means, select entire group).
 */
export const useShiftKeySelection = ({
  allGroupsIds,
  shiftKeySelectionRef,
  selectionMode,
  setGroupItems,
  getGroupToggleState,
  toggleGroup,
}: ShiftKeySelectionProps) => {
  const isShiftKeyPressed = useKeyPress('Shift');
  const [shiftKeySelection, setShiftKeySelection] = useState<ShiftKeySelection>(
    defaultShiftSelection
  );

  const clearShiftKeySelection = useCallback(() => {
    setShiftKeySelection({ ...defaultShiftSelection });
  }, []);

  const perPropsRef: PerfRefProps = {
    shiftKeySelection,
    getGroupToggleState,
    setGroupItems,
    isShiftKeyPressed,
    selectionMode,
    allGroupsIds,
    toggleGroup,
    clearShiftKeySelection,
  };
  const refPerfProps = useRef<PerfRefProps>(perPropsRef);
  refPerfProps.current = perPropsRef;

  const setShiftKeyRowSelectionState = useCallback(
    (rowId: InputId, tableRows: TableRow[], groupId: InputId) => {
      const { allGroupsIds, isShiftKeyPressed, shiftKeySelection } =
        refPerfProps.current;
      const rowIndex = tableRows.findIndex((row) => row.id === String(rowId));

      if (rowIndex === NOT_FOUND_INDEX) {
        return;
      }

      let groupIndex = allGroupsIds.findIndex((gId) => gId === groupId);

      groupIndex =
        groupIndex === NOT_FOUND_INDEX ? NO_ROW_SELECTED_INDEX : groupIndex;

      if (
        !isShiftKeyPressed ||
        // if the user is pressing shift, and it hasn't selected any row yet,
        // then assume it's the first row selected
        (isShiftKeyPressed && isCleanState(shiftKeySelection))
      ) {
        setShiftKeySelection({
          ...shiftKeySelection,
          lastRow: {
            rowIndex,
            groupIndex,
            tableRows,
          },
        });
      } else {
        setShiftKeySelection({
          ...shiftKeySelection,
          shiftRow: {
            rowIndex,
            groupIndex,
            tableRows,
          },
        });
      }
    },
    []
  );

  const setShiftKeyGroupSelectionState = useCallback((groupId: InputId) => {
    const {
      allGroupsIds,
      isShiftKeyPressed,
      shiftKeySelection,
      selectionMode,
      clearShiftKeySelection,
    } = refPerfProps.current;
    const groupIndex = allGroupsIds.findIndex((gId) => gId === groupId);

    // When clicking a toggle all checkbox inside an accordion,
    // (single table) shift selection should be ignored and just do
    // the toggle all action.
    if (selectionMode?.mode === 'singleGroup') {
      clearShiftKeySelection();
      return;
    }

    if (groupIndex === NOT_FOUND_INDEX) {
      return;
    }

    if (
      !isShiftKeyPressed ||
      // if the user is pressing shift, and it hasn't selected any row yet,
      // then assume it's the first row selected
      (isShiftKeyPressed && isCleanState(shiftKeySelection))
    ) {
      setShiftKeySelection({
        ...shiftKeySelection,
        lastRow: {
          rowIndex: NO_ROW_SELECTED_INDEX,
          groupIndex,
          tableRows: [],
        },
      });
    } else {
      setShiftKeySelection({
        ...shiftKeySelection,
        shiftRow: {
          rowIndex: NO_ROW_SELECTED_INDEX,
          groupIndex,
          tableRows: [],
        },
      });
    }
  }, []);

  // Make row range selection if conditions are met
  useEffect(() => {
    const { lastRow, shiftRow } = shiftKeySelection;
    const {
      getGroupToggleState,
      setGroupItems,
      isShiftKeyPressed,
      selectionMode,
      allGroupsIds,
      toggleGroup,
      clearShiftKeySelection,
    } = refPerfProps.current;
    if (
      lastRow.groupIndex === NO_ROW_SELECTED_INDEX ||
      shiftRow.groupIndex === NO_ROW_SELECTED_INDEX ||
      !selectionMode?.mode ||
      !isShiftKeyPressed
    ) {
      return;
    }

    // Select start with the lowest group index
    const minIndex = Math.min(lastRow.groupIndex, shiftRow.groupIndex);
    const maxIndex = Math.max(lastRow.groupIndex, shiftRow.groupIndex);

    // How to the use did the selection on the table
    const isTopToBottomUserSelection =
      lastRow.groupIndex <= shiftRow.groupIndex;
    const isSameGroupSelection = lastRow.groupIndex === shiftRow.groupIndex;

    for (let groupIndex = minIndex; groupIndex <= maxIndex; groupIndex++) {
      const currentRowIndexSelection: RowIndexSelection = {
        groupIndex: groupIndex,
        rowIndex: NO_ROW_SELECTED_INDEX,
        tableRows: [],
      };

      if (lastRow.groupIndex === groupIndex) {
        currentRowIndexSelection.rowIndex = lastRow.rowIndex;
        currentRowIndexSelection.tableRows = lastRow.tableRows;
      } else if (shiftRow.groupIndex === groupIndex) {
        currentRowIndexSelection.rowIndex = shiftRow.rowIndex;
        currentRowIndexSelection.tableRows = shiftRow.tableRows;
      }

      const { tableRows } = currentRowIndexSelection;
      const activeGroupId = allGroupsIds[groupIndex];
      const shouldSelectAllGroup =
        currentRowIndexSelection.rowIndex === NO_ROW_SELECTED_INDEX;
      const groupState = getGroupToggleState(activeGroupId);

      // Select full group
      if (shouldSelectAllGroup) {
        if (!groupState.isGroupSelected) {
          toggleGroup(activeGroupId);
        }
        continue;
      }

      // Select remaining rows on the group
      let fromIndex = NO_ROW_SELECTED_INDEX;
      let toIndex = NO_ROW_SELECTED_INDEX;

      // Always select table rows from top to bottom
      if (isSameGroupSelection) {
        // Normal selection from-to row indexes
        fromIndex = Math.min(lastRow.rowIndex, shiftRow.rowIndex);
        toIndex = Math.max(lastRow.rowIndex, shiftRow.rowIndex);
      } else if (isTopToBottomUserSelection) {
        // TopToBottom user selection
        if (groupIndex === lastRow.groupIndex) {
          // From table
          fromIndex = currentRowIndexSelection.rowIndex;
          toIndex = tableRows.length - 1;
        } else if (groupIndex === shiftRow.groupIndex) {
          // To table
          fromIndex = 0;
          toIndex = currentRowIndexSelection.rowIndex;
        }
      } else {
        // BottomToTop Selection
        if (groupIndex === lastRow.groupIndex) {
          fromIndex = 0;
          toIndex = currentRowIndexSelection.rowIndex;
        } else if (groupIndex === shiftRow.groupIndex) {
          fromIndex = currentRowIndexSelection.rowIndex;
          toIndex = tableRows.length - 1;
        }
      }

      if (
        fromIndex === NO_ROW_SELECTED_INDEX ||
        toIndex === NO_ROW_SELECTED_INDEX
      ) {
        // Reset everything in case of an error
        clearShiftKeySelection();
        return;
      }

      const groupStateInput = tableRows
        .slice(fromIndex, toIndex + 1) // +1 because slice end index is not included
        .map((row) => row.id)
        .concat(groupState.items) // Current selected items
        .reduce<GroupStateInput>((result, rowId) => {
          result[String(rowId)] = true;
          return result;
        }, {});
      setGroupItems(activeGroupId, groupStateInput);
    }

    // Reset and set last shift selection to lastRow
    // to continue selecting with shiftKey
    setShiftKeySelection({
      ...shiftKeySelection,
      lastRow: {
        ...shiftRow,
      },
      shiftRow: {
        ...defaultShiftSelection.shiftRow,
      },
    });
  }, [shiftKeySelection]);

  useImperativeHandle(
    shiftKeySelectionRef,
    () => {
      return {
        clearShiftKeySelection,
        setShiftKeyGroupSelectionState,
      };
    },
    [clearShiftKeySelection, setShiftKeyGroupSelectionState]
  );

  const isNoGroupIdSet = useCallback(() => {
    const { allGroupsIds } = refPerfProps.current;
    return allGroupsIds.includes(NO_GROUP_ID);
  }, []);

  return {
    clearShiftKeySelection,
    setShiftKeyRowSelectionState,
    setShiftKeyGroupSelectionState,
    isNoGroupIdSet,
  };
};
