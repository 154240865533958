import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { PurchaseVendorDialog } from 'src/dialogs/PurchaseVendorDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  PurchaseClient,
  PurchaseVendor,
} from 'src/WebApiController';

export type PurchaseVendorSelectionInputProps = Omit<
  PosSelectProps,
  'value' | 'onChange' | 'valueOptionsContent'
> & {
  fieldName: 'vendor' | 'secondaryVendor';
};

export const PurchaseVendorSelectionInput = ({
  disabled,
  fieldName,
  ...posSelectProps
}: PurchaseVendorSelectionInputProps) => {
  const addVendorText = useContent(ContentId.AddVendorAction);
  const additionalOptions = useMemo(
    () => ({
      [ContentId.AddVendorAction]: addVendorText + '...',
    }),
    [addVendorText]
  );

  const { query, posSelectProps: hookPosSelectProps } =
    usePurchaseVendorSelector({
      disabled,
      additionalOptions,
    });

  const { setValue, clearErrors, watch, formState } =
    useFormContext<PurchaseOrderDetailsInput>();
  const { activeAccountWebClientConfig } = useAppContext();
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const [currentVendor, setCurrentVendor] = useState<PurchaseVendor>();
  const { showErrorDialog } = useErrorBoundaryContext();

  const vendor = watch(fieldName);

  useEffect(() => {
    // If we don't have a selected account, auto-select the first one in the list
    if (!vendor) {
      const vendors = query.data;
      if (vendors?.length) {
        clearErrors(fieldName);
        setValue(fieldName, posChangedField(vendors[0]));
      }
    }
  }, [clearErrors, fieldName, query.data, setValue, vendor]);

  const onAddNewVendor = useCallback(() => {
    setCurrentVendor({
      id: -1,
      name: '',
      isGlobal: false,
      isNoFulfill: null,
      delivType: null,
      tktTypeRules: null,
      vendAccCounts: 0,
    });

    launchDialog();
  }, [launchDialog]);

  const onSaveVendor = useCallback(
    async (vendor: PurchaseVendor) => {
      await tryInvokeApi(
        async () => {
          const newId = await new PurchaseClient(
            activeAccountWebClientConfig
          ).mergeVendor(vendor);

          if (newId) {
            query.refetch();
            setValue(
              fieldName,
              posChangedField({
                ...vendor,
                id: newId,
              })
            );
            clearErrors(fieldName);

            closeDialog();
            setCurrentVendor(undefined);
          } else {
            // This should never happen
            // Normal errors would have fallen to the error catch
            showErrorDialog(
              'PurchaseClient.mergeVendor',
              {
                message: 'Unable to create or save vendor ' + vendor.id,
                status: 500,
              } as ApiException,
              {
                trackErrorData: vendor,
              }
            );
          }
        },
        (error) => {
          showErrorDialog('PurchaseClient.mergeVendor', error, {
            trackErrorData: vendor,
          });
        }
      );
    },
    [
      activeAccountWebClientConfig,
      clearErrors,
      closeDialog,
      fieldName,
      query,
      setValue,
      showErrorDialog,
    ]
  );

  const onVendorCancel = useCallback(() => {
    closeDialog();
    setCurrentVendor(undefined);
  }, [closeDialog]);

  return (
    <>
      <PosSelect
        {...posSelectProps}
        {...hookPosSelectProps}
        hasErrors={Boolean(formState.errors.vendor?.message)}
        disabled={disabled}
        value={vendor?.value?.id?.toString()}
        onChange={(value) => {
          clearErrors(fieldName);
          if (value) {
            const vendorId = parseInt(value);
            if (value === ContentId.AddVendorAction) {
              onAddNewVendor();
            } else if (vendorId !== vendor?.value?.id) {
              const newVendor = query.data!.find(
                (v: PurchaseVendor) => v.id === vendorId
              );
              if (newVendor) {
                setValue(fieldName, posChangedField(newVendor));
              }
            }
          }
        }}
      />
      {currentVendor && (
        <PurchaseVendorDialog
          {...dialogProps}
          vendor={currentVendor}
          onClosed={onVendorCancel}
          onSave={onSaveVendor}
        />
      )}
    </>
  );
};
