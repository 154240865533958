import { Content, useContent } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { FormatOption } from 'src/modals/EditTableColumns';
import { ContentId } from 'src/utils/constants/contentId';
import { applyNumberFormatting } from 'src/utils/numberFormatter';

export type DateCellProps = {
  /**
   * Date as string, should be parseable by Date constructor
   */
  date: string | string[] | null;
  // TODO: handle date ranges
  dateEnd?: string | null;
  precision?: FormatOption;
  align?: 'left' | 'center' | 'right';
  enableUtcFallback?: boolean;
  /**
   * Hide "Ago"/"In" text
   */
  hideRelativeTerms?: boolean;
  /**
   * Try to use site timezone first, then fall back to local timezone
   */
  useSiteTimeZone?: boolean;
  style?: React.CSSProperties;
};

export const DateCell = ({
  precision = FormatOption.DateTime_MonthDD_Time,
  date,
  align = 'right',
  enableUtcFallback = false,
  hideRelativeTerms = false,
  style,
  useSiteTimeZone = true,
}: DateCellProps) => {
  const inText = useContent(ContentId.In);
  const agoText = useContent(ContentId.Ago);
  const nowText = useContent(ContentId.JustNow);
  const { timeZone } = useSiteTimezoneContext();
  if (!date)
    return (
      <TableCellDiv align={align} style={style}>
        <Content id={ContentId.NA} />
      </TableCellDiv>
    );

  let formattedDate: string | null | undefined;
  if (!Array.isArray(date)) {
    formattedDate = applyNumberFormatting({
      precision,
      inputNumber: date,
      isDateTime: true,
      enableUtcFallback,

      timeZone: useSiteTimeZone ? timeZone : undefined,
    });
  } else {
    const dates = date as string[];
    formattedDate = dates
      .map((date) =>
        applyNumberFormatting({
          precision,
          inputNumber: date,
          isDateTime: true,
          enableUtcFallback,
          localeText: {
            in: inText,
            ago: agoText,
            now: nowText,
          },
          timeZone: useSiteTimeZone ? timeZone : undefined,
        })
      )
      .join(', ');
  }

  return (
    <TableCellDiv align={align} style={style}>
      {formattedDate}
    </TableCellDiv>
  );
};
