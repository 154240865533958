import { ChangeEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { Switch } from 'src/core/interim/Switch';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import { ProcessingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import {
  ListingPricingInput,
  ListingPricingSettingsCellProps,
} from '../ListingPriceForm.types';

export const CompListingExcludeFanInventoryCell: React.FC<
  ListingPricingSettingsCellProps
> = ({ listing, editable, event }) => {
  const { watch, setValue } = useFormContext<ListingPricingInput>();

  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);

  const { compListingExcludeFanInventory, canAutoPrice, onCompListingChange } =
    useListingAutoPricingSettings(listing);

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingPrice = watch('isSubmitting');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const isDisabled =
    listing.isDeleted ||
    !canAutoPrice ||
    listingSelection.isSingleGroupMultiSelect ||
    fieldIsSubmitting ||
    !editable;

  const submittingField = watch('submittingField');
  const isSubmittingField =
    fieldIsSubmitting && submittingField === 'compListingExcludeFanInventory';

  const onToggleExcludeFanInventory = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isChecked = e.target.checked;
      if (isChecked === compListingExcludeFanInventory) {
        return;
      }
      onCompListingChange({
        compListingExcludeFanInventoryNew: isChecked,
      });
      // Only submit if Enter is hit in the box that has changes
      setValue('isSubmittingPricingSettings', true);
      setValue('isSubmittingRowIndex', rowIndex);
      setValue('submittingField', 'compListingExcludeFanInventory');
    },
    [compListingExcludeFanInventory, onCompListingChange, rowIndex, setValue]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <Stack direction="row" gap="s" alignItems="center">
        {isSubmittingField ? (
          <div className="operation-button">
            <RotatingWrapper>
              <ProcessingIcon size={vars.iconSize.m} />
            </RotatingWrapper>
          </div>
        ) : (
          <>
            <Switch
              disabled={isDisabled}
              checked={Boolean(compListingExcludeFanInventory)}
              onClick={(e: ChangeEvent<HTMLElement>) => e.stopPropagation()}
              onChange={onToggleExcludeFanInventory}
            />

            <Content
              id={compListingExcludeFanInventory ? ContentId.On : ContentId.Off}
            />
          </>
        )}
      </Stack>
    </TableCellDiv>
  );
};
