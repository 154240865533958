import { vars } from 'src/core/themes';
import styled, { keyframes } from 'styled-components/macro';

export const SpinnerWrapper = styled.div<{ centered?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.centered ? '100%' : undefined)};
  padding: ${(props) => (props.centered ? vars.spacing.lg : undefined)};
`;

export const SpinnerContainer = styled.div<{ size?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: calc(4 * ${(props) => props.size});
  height: calc(4 * ${(props) => props.size});
`;

SpinnerContainer.defaultProps = { size: vars.iconSize.s };

export const SpinnerSVG = styled.svg`
  width: 60%;
  position: absolute;
`;

const circleAnimation = keyframes`
  0% {
    stroke-dasharray: 0%, 300%;
  }

  100% {
    stroke-dasharray: 300%, 300%;
  }
`;

const circleWrapperAnimation = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerCircleWrapper = styled.div`
  width: 100%;
  position: absolute;
  animation: ${circleWrapperAnimation} 0.6s linear infinite;
`;

export const SpinnerCircleSVG = styled.svg`
  width: 100%;
  stroke-width: 2px;
  animation: ${circleAnimation} 1s linear infinite alternate;
`;
