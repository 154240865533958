import { Stack } from 'src/core/ui';
import { AutoCompAndAutoGroupMode } from 'src/WebApiController';

import {
  ConfigTypeSelector,
  RowComp,
  RowGroup,
  SectionGroup,
} from './components/index';
import * as styles from './GroupListingsAutoCompSettingsBody.css';

export const GroupListingsAutoCompSettingsBody = ({
  mode,
}: {
  mode: AutoCompAndAutoGroupMode;
}) => {
  return (
    <Stack direction="column" className={styles.autoCompsettingsContainer}>
      {mode != AutoCompAndAutoGroupMode.AutoGroup && <RowComp />}
      <RowGroup />
      <ConfigTypeSelector />
      <div className={styles.sectionGroup}>
        <SectionGroup mode={mode} />
      </div>
    </Stack>
  );
};
