import { Content } from 'src/contexts/ContentContext';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';
import styled from 'styled-components/macro';

export type ProceedsCellProps = {
  displayValue?: string;
  margin?: number;
};

export const ProceedsCell = ({ displayValue, margin }: ProceedsCellProps) => {
  const hasTotalNetProceedsPermissions = useUserHasAnyOfPermissions(
    Permission.Sales_ViewProceeds,
    Permission.Sales_ViewRecentProceeds
  );

  return (
    <ProceedsWrapper>
      <Proceeds>
        {!displayValue ? (
          hasTotalNetProceedsPermissions ? (
            <Content id={ContentId.NA} />
          ) : null
        ) : (
          displayValue
        )}
      </Proceeds>
      {margin && <ProceedsMargin>{margin.toFixed(2)}%</ProceedsMargin>}
    </ProceedsWrapper>
  );
};

const ProceedsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.textPrimary};
`;

const Proceeds = styled.span`
  font-weight: 500;
`;

const ProceedsMargin = styled.span`
  margin-left: 10px;
  ${({ theme: { typography } }) => typography.getStyles('caption1', 'regular')}
`;
