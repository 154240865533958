import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

import {
  PurchaseDetailsDataTableCell,
  PurchaseDetailsDataTableFooter,
} from '../PurchaseModal.styled';

export const CostDetailsDataTable = styled.table`
  width: 50%;
  svg {
    display: block;
  }

  tr.padding-top-bottom td {
    padding-bottom: ${() => vars.spacing.m};
  }
`;

export const PurchaseDetailsDataTableLabelCell = styled(
  PurchaseDetailsDataTableCell
)`
  color: ${(props) => props.theme.colors.textPrimary};
`;

export const PurchaseDetailsDataTableValueCell = styled(
  PurchaseDetailsDataTableCell
)`
  font-weight: 600;
`;

export const PurchaseDetailsDataTableValueFooter = styled(
  PurchaseDetailsDataTableFooter
)`
  font-weight: 600;
`;
