import { ColumnPersonalizationFlags } from '../columnUtils.types';
import { EventsTableColumnId } from './eventsColumnUtils.types';

export const EVENTS_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES: Record<
  EventsTableColumnId,
  Partial<ColumnPersonalizationFlags>
> = {
  [EventsTableColumnId.Checkbox]: { isConfigurable: false },
  [EventsTableColumnId.ATP]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.Action]: { isConfigurable: false, isRequired: true },
  [EventsTableColumnId.ActualSTR]: { isNumeric: true, isPercent: true },
  [EventsTableColumnId.Date]: { isDateTime: true },
  [EventsTableColumnId.Event]: { isHiddenByDefault: false },
  [EventsTableColumnId.Venue]: { isHiddenByDefault: false },
  [EventsTableColumnId.Location]: {},
  [EventsTableColumnId.LiftPercentage]: { isNumeric: true, isPercent: true },
  [EventsTableColumnId.ListedAtp]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.ListedQty]: { isNumeric: true, isInteger: true },
  [EventsTableColumnId.Profit]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.Revenue]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.SoldCost]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.SoldQty]: { isNumeric: true, isInteger: true },
  [EventsTableColumnId.TotalQty]: { isNumeric: true, isInteger: true },
  [EventsTableColumnId.UnsoldQty]: { isNumeric: true, isInteger: true },
  [EventsTableColumnId.UnsoldProceeds]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.UnsoldCost]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.TotalCost]: { isNumeric: true, isCurrency: true },
  [EventsTableColumnId.LastPriceUpdatedDate]: {
    isHiddenByDefault: true,
    isDateTime: true,
  },
};
