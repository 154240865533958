import { Row } from '@tanstack/react-table';
import { useCallback } from 'react';
import {
  SeatingSortingFn,
  useSeatingColumnSorting,
} from 'src/tables/common/hooks/useSeatingColumnSorting';
import { ListingWithEvent } from 'src/tables/ListingTable/ListingTable.types';
import { ListingTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { SectionType } from 'src/utils/types/sectionType';
import { Feature } from 'src/WebApiController';

import { useUserHasFeature } from './useUserHasFeature';

export const useSortListingTableSeatingColumn = (sectionType: SectionType) => {
  const hasSortListingsInGroupFeature = useUserHasFeature(
    Feature.SortListingsInGroup
  );

  const sortingWrapper = useCallback(
    (
      rowA: Row<ListingWithEvent | null>,
      rowB: Row<ListingWithEvent | null>,
      columnId: string,
      sortingFn: SeatingSortingFn<ListingWithEvent>
    ) => {
      if (rowA.depth + rowB.depth > 0 && !hasSortListingsInGroupFeature) {
        return 0;
      }
      return sortingFn(rowA, rowB, columnId);
    },
    [hasSortListingsInGroupFeature]
  );

  return useSeatingColumnSorting<ListingWithEvent>(
    ListingTableColumnId.Seating,
    sectionType,
    sortingWrapper
  );
};
