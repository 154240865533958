import { Content } from 'src/contexts/ContentContext';
import { LaunchActivityLog } from 'src/modals/ActivityLog';
import { InventoryDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { getListingDetailsModalConfigWithDeepLink } from 'src/utils/inventoryUtils';

import { ShareButton } from '../ShareButton/ShareButton';
import {
  entityInfoShareButtonIdNumberWrapper,
  entityText,
} from './EventEntityHeader.css';
import { EntityIdNumberDiv, EntityInfoDiv } from './EventEntityHeader.styled';

export const ListingInfoDisplay = ({
  entityId,
  ticketCnt,
}: {
  entityId: number;
  ticketCnt: number;
}) => {
  return (
    <EntityInfoDiv>
      <div className={entityInfoShareButtonIdNumberWrapper}>
        <ShareButton deepLinkQueryParam={InventoryDeeplinkQueryParam} />
        <EntityIdNumberDiv>#{entityId}</EntityIdNumberDiv>
      </div>
      <div className={entityText}>
        {ticketCnt} <Content id={ContentId.Tickets} />
      </div>
      <LaunchActivityLog
        entityId={entityId}
        cancelTo={getListingDetailsModalConfigWithDeepLink(entityId)}
      />
    </EntityInfoDiv>
  );
};
