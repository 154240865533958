import React, { useCallback } from 'react';

import { useActiveFocusContext } from '../ActiveFocusContext/ActiveFocusContext';
import { FocusContext, IInputPriceFocusContext } from './FocusContext.types';

export const createInputPriceFocusContext =
  React.createContext<IInputPriceFocusContext>({} as IInputPriceFocusContext);

export const useInputPriceFocusContext = () =>
  React.useContext(createInputPriceFocusContext);

export const InputPriceFocusProvider = ({
  pageSize,
  disablePagination,
  children,
}: {
  pageSize: number;
  disablePagination: boolean;
  children: React.ReactNode;
}) => {
  const [disabledFocusContexts, setDisabledFocusContexts] =
    React.useState<FocusContext[]>();

  const [inputPriceFocusContext, setInputPriceFocusContext] =
    React.useState<FocusContext>();

  const { setActiveItem } = useActiveFocusContext();

  const onSetInputPriceFocusContext = useCallback(
    (input?: FocusContext) => {
      if (input) {
        setActiveItem(undefined); // Clear active focus context or else we have conflicts
      }
      setInputPriceFocusContext(input);
    },
    [setActiveItem]
  );

  return (
    <createInputPriceFocusContext.Provider
      value={{
        pageSize,
        disablePagination,
        inputPriceFocusContext,
        setInputPriceFocusContext: onSetInputPriceFocusContext,
        setDisabledFocusContexts,
        disabledFocusContexts,
      }}
    >
      {children}
    </createInputPriceFocusContext.Provider>
  );
};
