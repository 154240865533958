import { Content, useContent } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { TICKET_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { TicketTypePriority } from 'src/WebApiController';

import * as styles from './FulfillmentSection.css';

export const TicketTypePriorityPill = ({
  ticketTypePriority,
}: {
  ticketTypePriority: TicketTypePriority;
}) => {
  const isBlocked =
    ticketTypePriority.priority === -1 || ticketTypePriority.isBlocked;
  const blockedTxt = useContent(ContentId.Blocked);
  return (
    <Stack direction="row" className={styles.pillItemContainer}>
      <span>
        <Content id={TICKET_TYPE_TO_CID[ticketTypePriority.ticketType]} />
      </span>
      <span>{': '}</span>
      <span className={styles.textBold}>
        {isBlocked ? blockedTxt : ticketTypePriority.priority}
      </span>
    </Stack>
  );
};
