import { Content } from "src/contexts/ContentContext";
import { Stack } from "src/core/ui";
import { ContentId } from "src/utils/constants/contentId";
import {
  EventSectionClearingPrices,
  SectionInfo,
  UiMoney,
} from "src/WebApiController";

import * as styles from './EventSectionClearingPriceSectionDisplay.css';
import { applyNumberFormatting } from "src/utils/numberFormatter";

const SectionClearingPricePercentile = ({
  percentileLabel,
  percentileValue,
}: {
  percentileLabel: string,
  percentileValue: UiMoney,
  }) => {
  const percentileDisplayValue =
    applyNumberFormatting({
      inputNumber: percentileValue.amt,
      currencyCode: percentileValue.currency,
      currencyDecimalDigits: 0,
    }) ?? undefined;
  return (
    <Stack
      gap="m"
      style={{
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <div className={styles.sectionDisplayLabel}>
        {percentileLabel}
      </div>
      <div className={styles.sectionDisplayValue}>
        {percentileDisplayValue}
      </div>
    </Stack>
  );
};

export const EventSectionClearingPriceSectionDisplay = ({
  section,
  sectionClearingPricesBySectionId,
}: {
  section: SectionInfo,
  sectionClearingPricesBySectionId: Record<number, EventSectionClearingPrices>,
  }) => {
  const prices = sectionClearingPricesBySectionId[section.id];

  return (
    <Stack direction="column" gap="s">
      <Stack gap="m" alignItems="center">
        <div className={styles.sectionDisplay}>
          <Content id={ContentId.Section} />
        </div>
        <div className={styles.sectionDisplay} title={section.id.toString()}>
          {section.name}
        </div>
      </Stack>
      {prices != null && (
        <>
          <SectionClearingPricePercentile percentileLabel="1st" percentileValue={prices.p01} />
          <SectionClearingPricePercentile percentileLabel="25th" percentileValue={prices.p25} />
          <SectionClearingPricePercentile percentileLabel="50th" percentileValue={prices.p50} />
          <SectionClearingPricePercentile percentileLabel="75th" percentileValue={prices.p75} />
          <SectionClearingPricePercentile percentileLabel="99th" percentileValue={prices.p99} />
        </>
      )}
    </Stack>
  );
};