import { useCallback, useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { CopyIcon } from 'src/svgs';
import { IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getUrlWithDeepLink } from 'src/utils/deepLinkUtils';

import { sharedButtonSpan } from './ShareButton.css';

export type ShareButtonProps = {
  deepLinkQueryParam: 'uniqueSaleId' | 'uniqueInventoryId' | 'uniquePurchaseId';
  isCopiedLocation?: 'left' | 'right';
  usePopup?: boolean;
};

export const ShareButton = ({
  deepLinkQueryParam,
  isCopiedLocation = 'left',
  usePopup = false,
}: ShareButtonProps) => {
  const referenceId = 'ShareButton';
  const linkCopiedDialog = useBasicDialog();
  const [isUrlCopied, setIsUrlCopied] = useState(false);
  const { copyToClipboard } = useCopyToClipboard();
  const onClick = () => {
    const url = getUrlWithDeepLink(deepLinkQueryParam);
    if (url) {
      copyToClipboard(url.toString(), referenceId);
      if (usePopup) {
        linkCopiedDialog.launchDialog();
      }
      setIsUrlCopied(true);
      setTimeout(() => {
        setIsUrlCopied(false);
        if (usePopup) {
          linkCopiedDialog.closeDialog();
        }
      }, 3000); //Message will be shown for 3 seconds
    }
  };

  const showCopiedLabel = useCallback(
    (copiedLocation: string) => {
      return (
        !usePopup &&
        isUrlCopied &&
        isCopiedLocation === copiedLocation && (
          <Content id={ContentId.LinkCopied} />
        )
      );
    },
    [usePopup, isCopiedLocation, isUrlCopied]
  );

  const onOkay = () => {
    linkCopiedDialog.closeDialog();
  };

  return (
    <>
      <Stack
        direction="row"
        gap="s"
        onClick={onClick}
        alignItems="center"
        className={sharedButtonSpan}
      >
        {showCopiedLabel('left')}
        <CopyIcon
          withHoverEffect
          fill={IconsFill.textBrand}
          size={vars.iconSize.m}
        />
        {showCopiedLabel('right')}
      </Stack>
      <ConfirmDialog
        {...linkCopiedDialog.dialogProps}
        headerText={<Content id={ContentId.LinkCopied} />}
        bodyText={<Content id={ContentId.LinkHasBeenCopiedToClipboard} />}
        onOkay={onOkay}
        onCancel={undefined}
        okText={ContentId.OK}
      />
    </>
  );
};
