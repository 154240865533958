import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ObstructedViewIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M12 6C7.53 6 3.725 8.667 2 12.47c1.725 3.805 5.569 6.471 10 6.471s8.275-2.666 10-6.47A10.948 10.948 0 0012 6zm0 11.333c-3.451 0-6.627-1.882-8.235-4.902.823-1.529 2.078-2.745 3.53-3.607l1.881 1.882a3.291 3.291 0 00-.51 1.725A3.33 3.33 0 0012 15.765c.627 0 1.216-.196 1.725-.47l1.49 1.49a9.891 9.891 0 01-3.215.548zm4.706-1.294l-1.882-1.882c.274-.47.47-1.02.47-1.647h-3.333l.823-.392 2.196-1.098c-.549-1.138-1.686-1.922-3.02-1.922-.627 0-1.215.196-1.725.51l-1.49-1.49c1.02-.353 2.079-.589 3.216-.589 3.45 0 6.627 1.883 8.235 4.902a8.602 8.602 0 01-3.49 3.608z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
