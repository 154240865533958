import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function UploadIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M5.222 17.556v2.221h13.444v-2.221h2.223V22h-2.222l-.001-.001H5.222V22H3v-4.444h2.222zM11.778 2l8.666 8.667-1.555 1.555-6-5.889v12.89h-2.222V6.332l-6 5.89-1.556-1.556L11.778 2z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
