import React, { createContext, useContext, useState } from 'react';

import { ModalProvider } from '../ModalContext';

// DialogId is an enum that stores the unique DialogIds to pre
export enum DialogId {
  AddToGroup = 'add-to-group-dialog',
  AutoPricingStrategy = 'auto-pricing-strategy',
  Confirm = 'confirm',
  BulkEditListingSettings = 'bulk-edit-listing-settings-dialog',
  BulkUpdateAutoFulfill = 'bulk-update-auto-fulfill-dialog',
  BulkEditTags = 'bulk-edit-tags-dialog',
  BulkChangeFloorCeilingActions = 'bulk-change-floor-ceiling-actions',
  BulkAddListingAdminHold = 'bulk-add-listing-admin-hold-dialog',
  BulkEditMarketplacePrices = 'bulk-edit-marketplace-prices-dialog',
  BulkEditListingPrice = 'bulk-edit-listing-price-dialog',
  BulkDeleteSeatSeavers = 'bulk-delete-seat-savers-dialog',
  BulkEditSeatTraits = 'bulk-edit-seat-traits-dialog',
  BulkEditBroadcastSettings = 'bulk-edit-broadcast-settings-dialog',
  BulkRemoveHold = 'bulk-remove-hold-dialog',
  BulkChangeTicketTypeRule = 'bulk-change-ticket-type-rule-dialog',
  BulkMarkAsFulfilled = 'bulk-mark-as-fulfilled-dialog',
  BulkEditPurchaseSettings = 'bulk-edit-purchase-settings-dialog',
  BulkEditCommission = 'bulk-edit-commission-dialog',
  BulkChangeEvent = 'bulk-change-event-dialog',
  BulkEditMarketplaceSeating = 'bulk-edit-marketplace-seating-dialog',
  BulkEditDeliveryType = 'bulk-edit-delivery-type-dialog',
  BulkDeleteTicketGroup = 'bulk-delete-ticket-group-dialog',
  BulkEditSalesSettings = 'bulk-edit-sales-settings-dialog',
  BulkCancelPurchase = 'bulk-cancel-purchase-dialog',
  BulkUpdateInternalNotes = 'bulk-update-internal-notes-dialog',
  BulkRemovePredilivery = 'bulk-remove-predelivery-dialog',
  BulkUngroupListings = 'bulk-ungroup-listings-dialog',
  BulkBackfillDeliverySettings = 'bulk-backfill-delivery-settings-dialog',
  BulkConvertPurchasePayments = 'bulk-convert-purchase-payments-dialog',
  ResetFulfillment = 'reset-fulfillment-dialog',
  BulkUpdateListingGroupSettings = 'bulk-update-listing-group-settings-dialog',
  BulkMarkPurchaseAsPaid = 'bulk-mark-purchase-as-paid-dialog',
  BulkEditAutoPricingSettings = 'bulk-edit-auto-pricing-settings-dialog',
}

type DialogStore = {
  id: DialogId;
  content: React.ReactNode;
};
interface DialogContextType {
  openDialog: (id: DialogId, options: Omit<DialogStore, 'id'>) => void;
  closeDialog: (id: DialogId) => void;
}

const DialogContext = createContext<DialogContextType>({
  openDialog: () => {},
  closeDialog: () => {},
});

export const useDialogContext = () => useContext(DialogContext);

// DialogProvider is a context provider that manages the state of the dialogs
export const DialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [dialogs, setDialogs] = useState<DialogStore[]>([]);

  const openDialog = (id: DialogId, options: Omit<DialogStore, 'id'>) => {
    setDialogs((prevDialogs) => [...prevDialogs, { ...options, id }]);
  };

  const closeDialog = (id: DialogId) => {
    setDialogs((prevDialogs) =>
      prevDialogs.filter((dialog) => dialog.id !== id)
    );
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {/* ModalProvider needs to be inside DialogProvider, as modals can launch dialogs, but not the other way around */}
      <ModalProvider>
        {children}
        {dialogs.map(({ id, content }) => (
          <div key={id}>{content}</div>
        ))}
      </ModalProvider>
    </DialogContext.Provider>
  );
};

export default DialogContext;
