import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DownloadIcon, IconsFill, ProcessingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Feature,
  ListingClient,
  ListingQuery,
} from 'src/WebApiController';

import { useFilterQueryState } from '../MainFilterBar/useFilterQueryState';
import { ButtonWithIcon } from './ButtonWithIcon';

export const ExportInventoryButton = ({
  textContentId,
  iconOnlyMode,
  textOnly,
  variant,
  ...props
}: {
  textContentId?: ContentId;
  iconOnlyMode?: boolean;
  textOnly?: boolean;
  variant?: string;
} & Omit<ComponentProps<typeof ButtonWithIcon>, 'icon' | 'onClick'>) => {
  const { eventsTransformed } = useCatalogDataContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { filterQuery } = useFilterQueryContext<ListingQuery>();
  const { filterQueryWithEventIds } = useFilterQueryState<ListingQuery>(
    ActionOutboxEntityType.Listing
  );
  const [isLoading, setIsLoading] = useState(false);
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const isButtonDisabled = isLoading || eventsTransformed == null;

  const onExportButtonClicked = useCallback(async () => {
    setIsLoading(true);
    await tryInvokeApi(
      async () => {
        const result = await new ListingClient(
          activeAccountWebClientConfig
        ).exportListings({
          item1: filterQueryWithEventIds,
          item2: {
            hasAutoPricingFeature,
          },
        });

        if (result.data) {
          const url = URL.createObjectURL(result.data);
          const a = document.createElement('a');
          a.setAttribute('href', url);
          a.setAttribute('download', result.fileName ?? '');
          a.click();
          URL.revokeObjectURL(url);
          a.remove();
        }
      },
      (error) => {
        showErrorDialog('ListingClient.exportListings', error, {
          trackErrorData: { filterQuery },
        });
      },
      () => setIsLoading(false)
    );
  }, [
    activeAccountWebClientConfig,
    filterQuery,
    filterQueryWithEventIds,
    hasAutoPricingFeature,
    showErrorDialog,
  ]);

  const iconFill = useMemo(
    () =>
      props.disabled
        ? IconsFill.textDisabled
        : iconOnlyMode || (variant ?? 'text') !== 'regular'
        ? IconsFill.textBrand
        : IconsFill.textInverted,
    [iconOnlyMode, props.disabled, variant]
  );
  return (
    <ButtonWithIcon
      {...props}
      onClick={isButtonDisabled ? undefined : onExportButtonClicked}
      disabled={isLoading || props.disabled}
      icon={
        !textOnly ? (
          isLoading ? (
            <RotatingWrapper>
              <ProcessingIcon fill={iconFill} />
            </RotatingWrapper>
          ) : (
            <DownloadIcon withHoverEffect fill={iconFill} />
          )
        ) : null
      }
      iconOnlyMode={iconOnlyMode}
      variant={variant ?? 'text'}
      textContentId={textContentId || ContentId.Export}
    />
  );
};
