import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import { vars } from 'src/core/themes';
import { ListingDetails } from 'src/WebApiController';

import { getSeatDisplay } from '../ListingDetails/components';

export const ViewSeatsListBody = ({
  listing,
  seeAvailableSeats,
}: {
  listing?: ListingDetails | null;
  seeAvailableSeats?: boolean;
}) => {
  const navigate = useNavigate();
  const tickets = listing?.tickets;
  const seatsElements = useMemo(() => {
    if (tickets) {
      if (seeAvailableSeats) {
        return tickets.filter((t) => !t.saleId).map((t) => getSeatDisplay(t));
      } else {
        return tickets
          .filter((t) => t.saleId)
          .map((t) => getSeatDisplay(t, navigate));
      }
    }
  }, [seeAvailableSeats, tickets, navigate]);

  return (
    <Virtuoso
      style={{ padding: vars.spacing.xl }}
      data={seatsElements}
      itemContent={(_, seatsElement) => {
        return <div style={{ padding: vars.spacing.xxs }}>{seatsElement}</div>;
      }}
    />
  );
};
