import { ChangeEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Popover } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TooltipPopover } from 'src/core/ui/TooltipPopover';
import { useListingHasVenueMapInfo } from 'src/hooks/useListingHasVenueMapInfo';
import { useUserCanSetPrice } from 'src/hooks/useUserHasListingPermissions';
import {
  CheckIcon,
  CrossIcon,
  IconsFill,
  ProcessingIcon,
} from 'src/svgs/Viagogo';
import { PricingSettingsFieldNumeric } from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { AutoPricingCompListingMode, Listing } from 'src/WebApiController';

import { ListingPricingInput } from './ListingPriceForm.types';

type CompListingBoundsCellProps = {
  formattedNumber?: string | null;
  listing: Listing;
  editable?: boolean;
  field: PricingSettingsFieldNumeric;
  disabled?: boolean;
  viagVirtualId?: string;
};

export const CompListingBoundsCell = ({
  formattedNumber,
  listing,
  editable,
  field,
  disabled,
  viagVirtualId,
}: CompListingBoundsCellProps) => {
  const { setValue, watch, clearErrors, getFieldState, formState } =
    useFormContext<ListingPricingInput>();
  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(viagVirtualId);
  const isSelectionMode = listingSelection.isSingleGroupMultiSelect;

  const saveMsg = useContent(ContentId.Save);

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingPrice = watch('isSubmitting');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');
  const autoPricingEnabled = watch('autoPricingEnabled');
  const compListingMode = watch('compListingMode');
  const fieldValue = watch(field);

  const canSetPrice = useUserCanSetPrice(listing, false);
  const { hasVenueMapInfo } = useListingHasVenueMapInfo(listing);

  const isAutoPricingDisabled = !(hasVenueMapInfo && canSetPrice);

  const isCompListingAmountDisabled =
    disabled ||
    listing.isDeleted ||
    !canSetPrice ||
    !autoPricingEnabled ||
    !compListingMode ||
    compListingMode === AutoPricingCompListingMode.SameZone ||
    compListingMode === AutoPricingCompListingMode.SameEvent ||
    compListingMode === AutoPricingCompListingMode.ParentListing ||
    isAutoPricingDisabled;

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const fieldError = getFieldState(field, formState)?.error?.message;

  const fieldHasChanged =
    formState.defaultValues?.[field] !== fieldValue &&
    fieldValue &&
    fieldValue > 0 &&
    !isSubmittingPricingSettings;

  const submit = useCallback(() => {
    if (fieldError) {
      return;
    }
    if (fieldHasChanged) {
      // Only submit if Enter is hit in the box that has changes
      setValue('isSubmittingPricingSettings', true);
      setValue('isSubmittingRowIndex', rowIndex);
    }
  }, [fieldError, fieldHasChanged, setValue, rowIndex]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let newValue: number | null = parseFloat(e.target.value) ?? null;

      clearErrors(field);

      if (Number.isNaN(newValue) || newValue > Infinity) {
        newValue = null;
      }
      setValue(field, newValue);
    },
    [clearErrors, field, setValue]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <TooltipPopover
        variant="link"
        triggerContent={
          <span>
            {formattedNumber != null ? (
              formattedNumber
            ) : isCompListingAmountDisabled ? (
              ''
            ) : (
              <Content id={ContentId.Edit} />
            )}
          </span>
        }
        style={
          !editable || isCompListingAmountDisabled
            ? { color: vars.color.textPrimary }
            : {}
        }
        disabled={
          isCompListingAmountDisabled ||
          isSelectionMode ||
          disabled ||
          fieldIsSubmitting ||
          !editable
        }
        onClosed={() => {
          if (formState.defaultValues?.[field]) {
            setValue(field, formState.defaultValues?.[field] ?? null);
          }
        }}
      >
        <PosFormField
          errors={disabled ? undefined : fieldError}
          showErrorsInline
          style={{ width: 'fit-content' }}
        >
          <PosTextField
            postfixDisplay={
              <>
                {fieldIsSubmitting ? (
                  <RotatingWrapper>
                    <ProcessingIcon size={vars.iconSize.l} />
                  </RotatingWrapper>
                ) : fieldHasChanged && !fieldError ? (
                  <CheckIcon
                    title={saveMsg}
                    withHoverEffect
                    size={vars.iconSize.s}
                    fill={IconsFill.textSuccess}
                    onClick={(e) => {
                      e.stopPropagation();
                      submit();
                    }}
                  />
                ) : null}

                <Popover.Close asChild>
                  <CrossIcon withHoverEffect />
                </Popover.Close>
              </>
            }
            type="number"
            value={
              compListingMode == null || fieldValue == null ? '' : fieldValue
            }
            disabled={disabled || isSelectionMode || fieldIsSubmitting}
            onChange={onChangeHandler}
            onKeyDown={handleKeyDown}
          />
        </PosFormField>
      </TooltipPopover>
    </TableCellDiv>
  );
};
