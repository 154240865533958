import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
  ReactNode,
} from 'react';
import { vars } from 'src/core/themes';
import { Tooltip } from 'src/core/ui';
import { mergeProps } from 'src/core/utils';
import { IconsFill, WarningSolidIcon } from 'src/svgs/Viagogo';

import * as styles from './PosFormField.css';

export type PosFormFieldProps = React.PropsWithChildren<{
  label?: ReactNode;
  errors?: string | string[];
  warnings?: string | string[];
  showErrorsInline?: boolean;
  doNotShowErrorDetails?: boolean;
  labelPosition?: 'top' | 'bottom' | undefined;
}>;

export const PosFormField = forwardRef<
  HTMLDivElement,
  ComponentPropsWithoutRef<'div'> & PosFormFieldProps
>(function PosFormField(
  {
    label,
    errors,
    warnings,
    showErrorsInline,
    doNotShowErrorDetails,
    children,
    labelPosition = 'top',
    ...rest
  },
  ref
): ReactElement {
  const isArray = Array.isArray(errors);
  const errorsDiv = errors && (
    <div className={styles.errorsContainer}>
      {isArray ? (
        (errors as string[]).map((er, i) => (
          <div key={`${er}-${i}`} className={styles.error}>
            {er}
          </div>
        ))
      ) : (
        <div className={styles.error}>{errors}</div>
      )}
    </div>
  );
  const warningsDiv = warnings && (
    <div className={styles.warningsContainer}>
      {isArray ? (
        (warnings as string[]).map((er, i) => (
          <div key={`${er}-${i}`} className={styles.warning}>
            {er}
          </div>
        ))
      ) : (
        <div className={styles.warning}>{warnings}</div>
      )}
    </div>
  );

  return (
    <div
      ref={ref}
      {...mergeProps({ className: styles.formFieldContainer }, rest)}
    >
      {labelPosition === 'top' && label}
      <div className={styles.childrenWithInlineErrors}>
        {children}
        {errors && showErrorsInline && (
          <Tooltip
            triggerContent={
              <div className={styles.tooltipIconWrapper}>
                <WarningSolidIcon
                  size={vars.iconSize.m}
                  fill={IconsFill.textNegative}
                />
              </div>
            }
          >
            {errorsDiv}
          </Tooltip>
        )}
        {warnings && showErrorsInline && (
          <Tooltip
            triggerContent={
              <div className={styles.tooltipIconWrapper}>
                <WarningSolidIcon
                  size={vars.iconSize.m}
                  fill={IconsFill.textWarning}
                />
              </div>
            }
          >
            {warningsDiv}
          </Tooltip>
        )}
      </div>
      {labelPosition === 'bottom' && label}
      {!showErrorsInline && !doNotShowErrorDetails && errorsDiv}
      {!showErrorsInline && !doNotShowErrorDetails && warningsDiv}
    </div>
  );
});
