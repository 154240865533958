import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useInventoryEventBulkActionsState } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/useInventoryEventBulkActionsState';
import { useBulkUpdateListingSettings } from 'src/components/Listings/InventoryActionDropdown/components/BulkUpdateListingSettingsAction';
import { Content } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { ContentIds } from 'src/utils/constants/contentIdDataMap';
import { COMMAND_OPTION_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { EventWithData } from 'src/WebApiController';

import * as styles from './CommandMenu.css';

interface CommandMenuProps {
  groupId: string;
  event: EventWithData['event'];
}

export enum CommandOptionType {
  ToggleSelectionMode = 'toggle-selection-mode',
  SelectAll = 'select-all',
  AutoPricingSettings = 'auto-pricing-settings',
}

const CommandMenu = ({ groupId, event }: CommandMenuProps) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const dialogRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { toggleGroup, selectionMode, setSelectionMode, getSelection } =
    useMultiSelectionContext();

  const listingSelection = getSelection(groupId);

  const onToggleSelectionModeClick = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();

      if (listingSelection.isSingleGroupMultiSelect) {
        setSelectionMode();
      } else {
        setSelectionMode({
          mode: MultiSelectScope.SingleGroup,
          groupId,
        });
      }
    },
    [groupId, listingSelection.isSingleGroupMultiSelect, setSelectionMode]
  );

  const onSelectAllClick = useCallback(() => {
    toggleGroup(groupId);
  }, [groupId, toggleGroup]);

  const { affectedVisibleListingIds, filterQueryWithEventIds } =
    useInventoryEventBulkActionsState(event);

  const [isLoading, setIsLoading] = useState(false);

  const bulkEditAutoPricingSettings = useBulkUpdateListingSettings(
    'autoPricing',
    filterQueryWithEventIds,
    affectedVisibleListingIds,
    setIsLoading,
    isLoading,
    event
  );

  const filteredCommandOptions = useMemo(() => {
    const commandOptions = [
      {
        label: (
          <Content
            id={
              COMMAND_OPTION_TYPE_TO_CID[CommandOptionType.ToggleSelectionMode]
            }
          />
        ),
        id: CommandOptionType.ToggleSelectionMode,
        searchText: `${
          ContentIds[
            COMMAND_OPTION_TYPE_TO_CID[CommandOptionType.ToggleSelectionMode]
          ].defaultValue
        }`,
      },
      {
        label: (
          <Content
            id={COMMAND_OPTION_TYPE_TO_CID[CommandOptionType.SelectAll]}
          />
        ),
        id: CommandOptionType.SelectAll,
        searchText: `${
          ContentIds[COMMAND_OPTION_TYPE_TO_CID[CommandOptionType.SelectAll]]
            .defaultValue
        }`,
      },
    ];

    return commandOptions.filter((option) =>
      option.searchText.toString().toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);

  const filteredSettingOptions = useMemo(() => {
    const settingOptions = [
      {
        label: (
          <Content
            id={
              COMMAND_OPTION_TYPE_TO_CID[CommandOptionType.AutoPricingSettings]
            }
          />
        ),
        id: CommandOptionType.AutoPricingSettings,
        searchText: `${
          ContentIds[
            COMMAND_OPTION_TYPE_TO_CID[CommandOptionType.AutoPricingSettings]
          ].defaultValue
        }`,
      },
    ];

    return settingOptions.filter((option) =>
      option.searchText.toString().toLowerCase().includes(search.toLowerCase())
    );
  }, [search]);

  const combinedOptions = useMemo(
    () => [...filteredCommandOptions, ...filteredSettingOptions],
    [filteredCommandOptions, filteredSettingOptions]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        setOpen((prev) => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const handleOptionSelect = useCallback(
    (option: {
      label: JSX.Element;
      id: CommandOptionType;
      searchText: string;
    }) => {
      setOpen((prev) => !prev);

      if (option.id === CommandOptionType.ToggleSelectionMode) {
        onToggleSelectionModeClick();
      } else if (option.id === CommandOptionType.SelectAll) {
        if (selectionMode == undefined) {
          onToggleSelectionModeClick();
        }
        onSelectAllClick();
      } else if (option.id === CommandOptionType.AutoPricingSettings) {
        bulkEditAutoPricingSettings.openDialog();
      }
    },
    [
      onToggleSelectionModeClick,
      selectionMode,
      onSelectAllClick,
      bulkEditAutoPricingSettings,
    ]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!open) {
        return;
      }

      if (event.key === 'Escape') {
        setOpen(false); // Close dialog on Escape
      } else if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key)) {
        event.preventDefault();

        if (event.key === 'ArrowDown') {
          setSelectedIndex((prevIndex) =>
            prevIndex === combinedOptions.length - 1 ? 0 : prevIndex + 1
          );
        } else if (event.key === 'ArrowUp') {
          setSelectedIndex((prevIndex) =>
            prevIndex === 0 ? combinedOptions.length - 1 : prevIndex - 1
          );
        } else if (event.key === 'Enter') {
          handleOptionSelect(combinedOptions[selectedIndex]);
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [open, combinedOptions, selectedIndex, handleOptionSelect]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dialogRef.current &&
        !dialogRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [open]);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  return (
    <div
      className={styles.dialogStyles}
      style={{ display: open ? 'block' : 'none' }}
      ref={dialogRef}
    >
      <input
        ref={inputRef}
        className={styles.inputStyles}
        placeholder="Type a command or search..."
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      {filteredCommandOptions.length > 0 && (
        <div>
          <div className={styles.groupHeader}>
            <Content id={ContentId.Commands} />
          </div>
          <Stack direction="column" className={styles.listStyles}>
            {filteredCommandOptions.map((option, index) => {
              return (
                <div
                  key={`${option.label}-${index}`}
                  className={`${styles.itemStyles} ${
                    selectedIndex === index ? styles.selectedItemStyles : ''
                  }`}
                  onMouseEnter={() => setSelectedIndex(index)}
                  onClick={() => {
                    if (combinedOptions.length === 0) {
                      return;
                    }

                    handleOptionSelect(combinedOptions[selectedIndex]);
                  }}
                >
                  <span className={styles.itemTextStyles}>{option.label}</span>
                </div>
              );
            })}
          </Stack>
        </div>
      )}

      {filteredSettingOptions.length > 0 && (
        <div>
          <div className={styles.groupHeader}>
            <Content id={ContentId.Settings} />
          </div>
          <Stack direction="column" className={styles.listStyles}>
            {filteredSettingOptions.map((option, index) => {
              return (
                <div
                  key={`${option.label}-${
                    index + filteredCommandOptions.length
                  }`}
                  className={`${styles.itemStyles} ${
                    selectedIndex === index + filteredCommandOptions.length
                      ? styles.selectedItemStyles
                      : ''
                  }`}
                  onMouseEnter={() =>
                    setSelectedIndex(index + filteredCommandOptions.length)
                  }
                  onClick={() => {
                    if (combinedOptions.length === 0) {
                      return;
                    }

                    handleOptionSelect(combinedOptions[selectedIndex]);
                  }}
                >
                  <span className={styles.itemTextStyles}>{option.label}</span>
                </div>
              );
            })}
          </Stack>
        </div>
      )}
    </div>
  );
};

export default CommandMenu;
