import { Link } from 'react-router-dom';
import { ActivityLogDisplay } from 'src/components/ActivityLog';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { PageSection } from 'src/components/common/PageSection';
import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Card } from 'src/core/ui';
import { useLiveActivityLog } from 'src/hooks/useLiveActivityLog';
import { ErrorSolidIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import IconBulletList from '~icons/pos/bulletList';

import * as styles from './ActivitySection.css';

const ACTIVITY_LOG_ITEM_COUNT = 6;

export function ActivitySection() {
  const activityLogResult = useLiveActivityLog(ACTIVITY_LOG_ITEM_COUNT);
  return (
    <PageSection>
      <PageSection.Header
        action={
          <Link className={styles.link} to="/activity">
            <Content id={ContentId.SeeAll} />
          </Link>
        }
      >
        <Content id={ContentId.Activity} />
      </PageSection.Header>
      <Card>
        {activityLogResult.isLoading ? (
          <EmptySectionContent.Root>
            <PosSpinner />
          </EmptySectionContent.Root>
        ) : activityLogResult.isError ? (
          <EmptySectionContent.Root
            icon={
              <ErrorSolidIcon
                fill={IconsFill.textNegative}
                size={vars.iconSize.xl}
              />
            }
          >
            <EmptySectionContent.Error>
              <Content
                id={ContentId.Error_InternalServerError_GenericMessage}
              />
            </EmptySectionContent.Error>
          </EmptySectionContent.Root>
        ) : activityLogResult.data?.length === 0 ? (
          <EmptySectionContent.Root icon={<IconBulletList />}>
            <Content id={ContentId.NoActivityLog} />
          </EmptySectionContent.Root>
        ) : (
          <div className={styles.content}>
            {activityLogResult.data?.map((activityLog) => (
              <div
                key={activityLog.activityLogId}
                className={styles.activityWrapper}
              >
                <ActivityLogDisplay activityLog={activityLog} />
              </div>
            ))}
          </div>
        )}
      </Card>
    </PageSection>
  );
}
