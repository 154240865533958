import { PosSpinner } from 'src/core/POS/PosSpinner';
import * as styles from './AccountAutoPricingSettings.css';
import { ContentId } from 'src/utils/constants/contentId';
import { Content } from 'src/contexts/ContentContext/Content';
import { useAccountAutoPricingSettings } from './AccountAutoPricingSettings.hooks';
import { AccountAutoPricingSettingsForm } from './AccountAutoPricingSettingsForm';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

export const AccountAutoPricingSettings = () => {
  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();

  const {
    accountAutoPricingSettings,
    onMergeAccountAutoPricingSettings,
    isLoading,
    isPending,
  } = useAccountAutoPricingSettings();

  const hasAllowSeatScoreCompListingModeFeature = useUserHasFeature(
    Feature.AllowSeatScoreCompListingMode,
  );

  const hasPriceByWebsitePriceFeature = useUserHasFeature(
    Feature.PriceByWebsitePrice,
  );

  const uiCurrency = getUiCurrency(loginContext?.user?.activeAccount?.currencyCode);

  return (
    <div className={styles.root}>
      <div className={styles.center}>
        <h1 className={styles.pageName}>
          <Content id={ContentId.Autopricing} />
        </h1>
        {isLoading ? (
          <PosSpinner />
        ) : (
            <AccountAutoPricingSettingsForm
              pricingSettings={accountAutoPricingSettings}
              uiCurrency={uiCurrency}
              disabled={isPending}
              onSaved={onMergeAccountAutoPricingSettings}
              allowSeatScoreCompListingMode={hasAllowSeatScoreCompListingModeFeature}
              allowPriceByWebsitePrice={hasPriceByWebsitePriceFeature}
            />
        )}
      </div>
    </div>
  );
};