import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  PurchaseOrderDetails,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkDeleteTicketGroupDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  onOkay: (
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

export function BulkDeleteTicketGroupDialog({
  updateKey,
  onOkay,
  onCancel,
  ...rest
}: BulkDeleteTicketGroupDialogProps) {
  const { setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
    refreshCatalog,
  } = useCatalogDataContext();
  const { refreshMetrics } = useCatalogMetricsContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshData(true);

        // Metrics/events may have changed with TGs being cancelled, so refresh the whole catalog
        refreshMetrics();
        await refreshCatalog();
        await refreshExpandedListItems();

        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshCatalog,
      refreshData,
      refreshExpandedListItems,
      refreshMetrics,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.TicketGroup,
    BulkActionType.DeleteTicketGroup,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature = useMemo(
    () => bulkEditHub?.state === HubConnectionState.Connected,
    [bulkEditHub?.state]
  );

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(false, onPreviewReceived);
      } else {
        onOkay(hasBackgroundBulkEditFeature, undefined, preview);
      }
      setIsLoading(false);
    },
    [onOkay, preview, hasBackgroundBulkEditFeature]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);
  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <GenericDialog
        {...rest}
        size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
        header={
          <BulkEditHeader
            headerText={<Content id={ContentId.CancelTicketGroupsParenth} />}
          />
        }
        onOpened={() => {
          initJob();
        }}
        onKeyUp={(e) => {
          if (submittButtonRef.current && e.key === 'Enter') {
            submittButtonRef.current.click();
          }
        }}
        onClosed={() => {
          setMainDialogOpened(false);
          if (progress) {
            setSelectionMode(undefined);
          }
          // Call the outside one if there is one
          rest.onClosed?.();
        }}
        footer={
          <BulkEditFooter
            entityType={ActionOutboxEntityType.TicketGroup}
            // There can be cases where only purchases are affec
            primaryEntityTypesAffected={[
              ActionOutboxEntityType.TicketGroup,
              ActionOutboxEntityType.Purchase,
            ]}
            isLoading={isLoading}
            hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
            onClose={onClose}
            onSubmit={onSubmitHandler}
            submittButtonRef={submittButtonRef}
          />
        }
        onCancel={isLoading ? undefined : onCancel}
      >
        <BulkEditStatus
          entityType={ActionOutboxEntityType.TicketGroup}
          isLoading={isRefreshing || isLoading}
          updateKey={updateKey}
        >
          <Content id={ContentId.CancelBulkTicketGroupWarning} />
        </BulkEditStatus>
      </GenericDialog>
    </>
  );
}
