import clsx from 'clsx';
import { Button } from 'src/core/ui';

import * as styles from './FormatOptionPill.css';
import { FormatOptionPillBaseProps } from './FormatOptionPill.types';

/**
 * FormatOptionPill Presentational Layer
 */
export const FormatOptionPillBase = ({
  onClick,
  isDisabled,
  onPointerDown,
  content,
  isSelected,
}: FormatOptionPillBaseProps) => {
  return (
    <Button
      className={clsx(styles.root, { [styles.selected]: isSelected })}
      onClick={onClick}
      disabled={isDisabled}
      onPointerDown={onPointerDown}
      size="md"
    >
      {content}
    </Button>
  );
};
