/**
 * Standard
 */
export enum FormatOption_Standard {
  Millions = 'millions',
  Thousands = 'thousands',
  Whole = 'whole',
  Precise = 'precise',
}

export enum FormatOption_Seating {
  // Used by listing only
  Seating_OriginalSeating = 'originalSeating',
  // For listing this is the remaining seating available
  Seating_FullSeating = 'fullSeating',
  Seating_SectionOnly = 'sectionOnly',
}

export enum FormatOption_Event {
  Event_FullInfo = 'fullEventInfo',
  Event_EventName = 'eventName',
}

export enum FormatOption_DateTime {
  DateTime_MMDD = 'MM/DD',
  DateTime_MMDD_Time = 'MM/DD Time',
  DateTime_MMDD_Time_Day = 'MM/DD Time, Day',
  DateTime_MMDDYY = 'MM/DD/YY',
  DateTime_MonthDD = 'Month DD',
  DateTime_MonthDD_Time = 'Month DD Time',
  DateTime_MonthDD_Time_Day = 'Month DD Time, Day',
  DateTime_Month_DD_YY = 'Month DD YY',
  DateTime_Month_DD_YY_Time = 'Month DD YY, Time',
  DateTime_Month_DD_YY_Time_Day = 'Month DD YY, Time, Day',
}

export enum FormatOption_EnumDisplay {
  EnumDisplay_Icon = 'icon',
  EnumDisplay_Text = 'text',
}

/**
 * Percentage options
 */
export enum FormatOption_Percent {
  Percent_RoundToTwo = 'per_2dp',
  Percent_RoundToOne = 'per_1dp',
  Percent_RoundToWhole = 'per_0dp', // (Whole)
  Percent_AsDecimal_RoundToOne = 'dec_1dp',
  Percent_AsDecimal_RoundToTwo = 'dec_2dp',
  Percent_AsDecimal_RoundToThree = 'dec_3dp',
}

export enum FormatOption_HiddenCustomFormat {
  // This is a hack to show action column - link only
  Actions_LinkOnly = 'Actions_LinkOnly',
  // This is a hack to show external ID column (ID only, no marketplace or metadata)
  Id_RawIdOnly = 'Id_RawIdOnly',
}

export const FormatOption = {
  ...FormatOption_Standard,
  ...FormatOption_Seating,
  ...FormatOption_Event,
  ...FormatOption_DateTime,
  ...FormatOption_EnumDisplay,
  ...FormatOption_Percent,
  ...FormatOption_HiddenCustomFormat,
} as const;
export type FormatOption = (typeof FormatOption)[keyof typeof FormatOption];

export const enum FormatOptionType {
  Standard = 'standard',
  Seating = 'seating',
  Event = 'event',
  DateTime = 'dateTime',
  EnumDisplay = 'enumDisplay',
  Percentage = 'percentage',
}
