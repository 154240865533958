import { ReactNode, useEffect } from 'react';
import { useEventsAccordionSelectedLegend } from 'src/components/Events/EventListing/useEventsAccordionSelectedLegend';
import { useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { ContentId } from 'src/utils/constants/contentId';

interface SetSalesEventAccordionSelectionCountLabelProps {
  children: ReactNode;
}

export const SetSalesEventAccordionSelectionCountLabel = ({
  children,
}: SetSalesEventAccordionSelectionCountLabelProps) => {
  const saleText = useContent(ContentId.Sale);
  const salesText = useContent(ContentId.Sales);
  const { setSelectedCountLabel, setUsingCustomSelectedCountLabel } =
    useMultiSelectionContext();

  useEffect(() => {
    setUsingCustomSelectedCountLabel(true);
  }, [setUsingCustomSelectedCountLabel]);

  const selectedLegend = useEventsAccordionSelectedLegend({
    singleElementLabel: saleText,
    multipleElementsLabel: salesText,
  });

  useEffect(() => {
    setSelectedCountLabel(selectedLegend);
  }, [selectedLegend, setSelectedCountLabel]);

  return <>{children}</>;
};
