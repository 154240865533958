import { MouseEventHandler, ReactNode } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import { IconButtonContainer } from './IconButton.styled';

export const IconButton = ({
  icon,
  titleContentId,
  onClick,
  disabled,
  style,
  className,
}: {
  icon: ReactNode;
  titleContentId?: ContentId | string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  disabled?: boolean;
  style?: React.CSSProperties;
  className?: string;
}) => {
  const title = useContent(titleContentId || ContentId.EmptyContent);

  return (
    <IconButtonContainer
      title={title}
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
      style={style}
      className={className}
    >
      {icon}
    </IconButtonContainer>
  );
};
