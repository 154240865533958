import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { MultiSelectDropBoxWithPills } from 'src/core/POS/MultiSelectDropBoxWithPills';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypesV2 } from 'src/utils/reportsUtils';
import { ReportColumn } from 'src/WebApiController';

import { useGetReportTableColumnIdMapping } from '../useGetReportTableColumnIdMapping';

interface GroupBySelectorProps {
  options: Record<string, ContentId | string>;
  reportType: ReportTypesV2;
}

export const GroupBySelectorV2 = ({
  options,
  reportType,
}: GroupBySelectorProps) => {
  const { watch, clearErrors, setValue } = useFormContext<ReportConfigV2>();

  const {
    listingReportColumnIdToGroupingMappingQuery: {
      data: listingReportColumnIdToGrouping,
    },
    saleReportColumnIdToGroupingMappingQuery: {
      data: saleReportColumnIdToGrouping,
    },
  } = useGetReportTableColumnIdMapping();

  const groupbyField = 'request.groupings';
  const sortByField = 'request.orderBy';

  const groupBy = watch(groupbyField);
  const sortBy = watch(sortByField);

  const groupByNames = useMemo(
    () => groupBy?.map((g) => g.columnName) ?? [],
    [groupBy]
  );

  const onGroupByChange = useCallback(
    (values: string[]) => {
      const reportColumnIdToGrouping =
        reportType === ReportTypesV2.InventoryV2
          ? listingReportColumnIdToGrouping
          : saleReportColumnIdToGrouping;

      const newReportGroupingColumns: ReportColumn[] = [];
      values.forEach((columnName) => {
        const columnValue = reportColumnIdToGrouping?.[columnName];
        if (columnValue) {
          newReportGroupingColumns.push({
            columnName,
            columnValue,
          });
        }
      });

      if (sortBy == null || !values.includes(sortBy)) {
        setValue(sortByField, values[0]);
      }

      clearErrors(groupbyField);
      setValue(groupbyField, newReportGroupingColumns);
    },
    [
      clearErrors,
      listingReportColumnIdToGrouping,
      reportType,
      saleReportColumnIdToGrouping,
      setValue,
      sortBy,
    ]
  );

  return (
    <MultiSelectDropBoxWithPills
      style={{ width: '100%' }}
      values={groupByNames}
      onChange={(values) => {
        onGroupByChange(values);
      }}
      valueOptionsContent={options}
      direction="column"
      flexWrap="grow"
    />
  );
};
