import { useCallback } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { ErrorTypes } from 'src/contexts/ErrorBoundaryContext';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { LoginClient, LoginContext } from 'src/WebApiController';

export const useFetchLoginContext = () => {
  const loginErrorMessage = useContent(
    ContentId.Error_Unauthorized_GenericMessage
  );
  const fetchLoginContext = useCallback(
    (
      onSuccess: (loginContext: LoginContext) => void,
      onError?: (error: ErrorTypes) => void
    ) => {
      tryInvokeApi(
        () =>
          new LoginClient({})
            .getLoginContext(window.location.pathname)
            .then((loginContext) => {
              if (
                loginContext.isSuccess &&
                loginContext?.user?.activeAccount?.id != null
              ) {
                onSuccess(loginContext);
              } else {
                onError?.({
                  status: 401,
                  message: loginErrorMessage,
                  response: JSON.stringify(loginContext),
                });
              }
            }),
        (error) => {
          onError?.(error);
        }
      );
    },
    [loginErrorMessage]
  );

  return {
    fetchLoginContext,
  };
};
