import { EventsTable } from 'src/tables/EventsTable';
import { EventWithData } from 'src/WebApiController';

export type InventoryEventTableData = {
  grouping: string;
  events: EventWithData[];
};

export const InventoryEventTable = ({
  item,
  highlightedId,
}: {
  item: InventoryEventTableData;
  highlightedId?: string;
}) => {
  return (
    <div data-testid="inventory-events-table">
      <EventsTable
        events={item.events}
        highlightedId={highlightedId}
        failedToRetrieveData={false}
      />
    </div>
  );
};
