import { TableCellDiv as TableCellDivV1 } from 'src/core/ui/TableCellDiv';
import styled from 'styled-components/macro';

export const TableCellDiv = styled((props) => <TableCellDivV1 {...props} />)`
  .styled-input {
    border-bottom: 0;
    display: flex;

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      border-bottom: 0;
    }
  }
`;
