import {
  EventAccordionItemBodyComponentType,
  MinRowsForUsingVirtuoso,
} from 'src/components/Accordions';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { PurchaseOrderTable } from 'src/tables/PurchaseTable';
import { PurchaseViewMode } from 'src/WebApiController';

type EventTicketGroupBodyProps = EventAccordionItemBodyComponentType;

export const EventTicketGroupBody = (props: EventTicketGroupBodyProps) => {
  const Body = PurchaseOrderTableEventAccordionWrapper;

  return <Body {...props} />;
};

const PurchaseOrderTableEventAccordionWrapper = (
  props: EventAccordionItemBodyComponentType
) => {
  const { ticketGroups, ticketGrpsCnt, ...rest } = props;

  const hasVirtuosoMinRows = ticketGrpsCnt > MinRowsForUsingVirtuoso;

  const { isEnabled } = useCollapsableViewContext();

  return (
    <PurchaseOrderTable
      {...rest}
      useVirtuoso={isEnabled || hasVirtuosoMinRows}
      purchaseCount={ticketGrpsCnt}
      purchaseOrders={ticketGroups}
      groupId={props.event.viagVirtualId}
      viewMode={PurchaseViewMode.EventTileView}
    />
  );
};
