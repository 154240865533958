import { ColumnDef } from '@tanstack/react-table';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { ReportMetricsResponse } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { SalesReportTableColumnId } from 'src/utils/columns/sales/salesColumnUtils.types';

import { saleReportV2TableColumnDef } from './configs/SaleReportTableColumnsConfigV2';

type SaleReportTableColumnDefContextType = {
  displayedColumnsConfig: ColumnDef<ReportMetricsResponse | null>[] | undefined;
};

const SaleReportTableColumnDefContext =
  createContext<SaleReportTableColumnDefContextType>({
    displayedColumnsConfig: undefined,
  });

export const SaleReportTableColumnDefContextProviderV2 = ({
  report,
  children,
}: PropsWithChildren<{
  report: ReportConfigV2;
}>) => {
  // const { appContext, loginContext } = useAppContext();
  // const { tagsMetadataNumeric, isLoading: saleTagIsLoading } =
  //   useTagsForEntityType(ActionOutboxEntityType.Sale, true);

  // const { tagsMetadata: tagsMetadataEvent, isLoading: eventTagIsLoading } =
  //   useTagsForEntityType(ActionOutboxEntityType.SellerEvent, true);

  // const {
  //   tagsMetadata: tagsMetadataPurchase,
  //   isLoading: purchaseTagIsLoading,
  // } = useTagsForEntityType(ActionOutboxEntityType.Purchase, true);

  // // const { value: customSaleColumns = [], isLoading: customColumnIsLoading } =
  // //   useServerUserSetting<CustomSaleColumn[]>({
  // //     id: UserSetting.InventoryCustomColumns,
  // //   });

  // // const customSaleColumnsFiltered = filterCustomColumnsForSaleReport(
  // //   customSaleColumns,
  // //   tagsMetadataNumeric
  // // );

  const displayedColumnsConfig = useMemo(() => {
    const columns = [
      ...(report.request.groupings?.map((c) => c.columnName) ?? []),
      ...(report.request.aggregations?.map((c) => c.columnName) ?? []),
    ];

    return columns.map((columnId) =>
      saleReportV2TableColumnDef(columnId as SalesReportTableColumnId)
    );
  }, [report.request.aggregations, report.request.groupings]);

  const isLoading = false;
  // saleTagIsLoading ||
  // eventTagIsLoading ||
  // purchaseTagIsLoading ||
  // customColumnIsLoading;

  return (
    <SaleReportTableColumnDefContext.Provider
      value={{
        displayedColumnsConfig: isLoading ? undefined : displayedColumnsConfig,
      }}
    >
      {children}
    </SaleReportTableColumnDefContext.Provider>
  );
};

export const useSaleReportTableColumnDefV2 = () =>
  useContext(SaleReportTableColumnDefContext);
