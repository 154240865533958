import { DialogId, useDialogContext } from './DialogContext';

type DialogPropsWithCallbacks<PropsType> = PropsType & {
  onClosed?: () => void;
  onCancel?: () => void;
};

export function useDialog<PropsType>(
  dialogId: DialogId,
  DialogComponent: React.ComponentType<DialogPropsWithCallbacks<PropsType>>
) {
  const { openDialog, closeDialog } = useDialogContext();

  const openDialogWithProps = (props: DialogPropsWithCallbacks<PropsType>) => {
    openDialog(dialogId, {
      content: (
        <DialogComponent
          {...props}
          isOpen={true}
          onClosed={() => {
            if (props.onClosed) {
              props.onClosed();
            } else {
              closeDialog(dialogId);
            }
          }}
          onCancel={() => {
            if (props.onCancel) {
              props.onCancel();
            } else {
              closeDialog(dialogId);
            }
          }}
        />
      ),
    });
  };

  return {
    openDialog: openDialogWithProps,
    closeDialog: () => closeDialog(dialogId),
  };
}
