import { useCallback, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useToggle } from 'react-use';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { Button } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useUserGroups } from 'src/hooks/useUserGroups';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { UserGroup } from 'src/WebApiController';

import * as styles from './EditTeamModal.css';
import { TeamInput } from './TeamInput';

type EditTeamModalFooterProps = { onClose: () => void };

export const EditTeamModal = ({
  userGroup,
  onClose,
}: {
  userGroup: UserGroup;
  onClose: () => void;
}) => {
  const methods = useForm<UserGroup>({
    defaultValues: userGroup,
  });

  return (
    <FormProvider {...methods}>
      <EditTeamModalContent onClose={onClose} />
    </FormProvider>
  );
};

export const EditTeamModalContent = ({ onClose }: { onClose: () => void }) => {
  const [isOpen, toggleIsOpen] = useToggle(true);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        toggleIsOpen();
        onClose();
      }}
      unmountOnClose
      clickOutsideToClose
      centered
      size="sm"
    >
      <ModalHeader
        onClose={() => {
          toggleIsOpen();
          onClose();
        }}
      >
        <EditTeamModalHeader />
      </ModalHeader>
      <ModalBody>
        <TeamInput />
      </ModalBody>
      <ModalFooter>
        <EditTeamModalFooter
          onClose={() => {
            toggleIsOpen();
            onClose();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

export const EditTeamModalHeader = () => {
  return (
    <div className={styles.header}>
      <h5 className={styles.headerText}>
        <Content id={ContentId.EditATeam} />
      </h5>
    </div>
  );
};

export const EditTeamModalFooter = ({ onClose }: EditTeamModalFooterProps) => {
  const { updateUserGroup, deleteUserGroup } = useUserGroups();
  const deleteText = useContent(ContentId.Delete);
  const cancelText = useContent(ContentId.Cancel);
  const saveText = useContent(ContentId.Save);

  const { handleSubmit } = useFormContext<UserGroup>();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isLoading, setIsLoading] = useState(false);
  const deleteConfirmDialog = useBasicDialog();

  const onUpdateSubmit = useCallback(
    async (formValue: UserGroup) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const result = await updateUserGroup(
            formValue.userGroupId,
            formValue
          );

          if (result) {
            onClose();
          }
        },
        (error) => {
          showErrorDialog('UserGroupClient.updateUserGroup', error, {
            trackErrorData: formValue,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [onClose, showErrorDialog, updateUserGroup]
  );

  const onUpdateSubmitHandler = useCallback(() => {
    handleSubmit(onUpdateSubmit)();
  }, [handleSubmit, onUpdateSubmit]);

  const onDeleteSubmit = useCallback(
    async (formValue: UserGroup) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const result = await deleteUserGroup(formValue.userGroupId);

          if (result) {
            onClose();
          }
        },
        (error) => {
          showErrorDialog('UserGroupClient.deleteUserGroup', error, {
            trackErrorData: formValue,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [deleteUserGroup, onClose, showErrorDialog]
  );

  return (
    <>
      <div className={styles.footer}>
        <div>
          <Button
            variant="outline"
            className={styles.cancel}
            onClick={() => {
              deleteConfirmDialog.launchDialog();
            }}
            disabled={isLoading}
          >
            {deleteText}
          </Button>
        </div>
        <div className={styles.buttonGroup}>
          <Button variant="outline" onClick={onClose} disabled={isLoading}>
            {cancelText}
          </Button>
          <Button onClick={onUpdateSubmitHandler} disabled={isLoading}>
            {saveText}
          </Button>
        </div>
      </div>
      <ConfirmDialog
        {...deleteConfirmDialog.dialogProps}
        headerText={<Content id={ContentId.DeleteTeam} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        onOkay={handleSubmit(onDeleteSubmit)}
        disabled={isLoading}
        onCancel={() => deleteConfirmDialog.closeDialog()}
      />
    </>
  );
};
