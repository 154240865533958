import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const AllocationSeatTable = styled.table`
  width: 100%;
  table-layout: auto;

  th {
    ${(props) => props.theme.typography.getStyles('caption1')}
    text-transform: capitalize;
  }

  th,
  td {
    padding-right: ${() => vars.spacing.lg};
  }

  td {
    font-weight: 700;
    font-size: ${() => vars.typography.fontSize['sm']};

    label {
      margin-bottom: 0;
      min-width: auto;
    }
  }
`;

export const NoAllocationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${() => vars.spacing.lg};

  .nav-link {
    color: ${(props) => props.theme.colors.textBrand};

    &:hover {
      text-decoration: underline;
    }
  }
`;
