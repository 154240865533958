import { cloneDeep } from 'lodash-es';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import {
  ArTimeBucketWithData,
  SaleReportMetricsWithGroupBy,
} from 'src/utils/reportsUtils';
import { addMoneySameCurrency } from 'src/utils/ticketMetricUtils';
import { EventWithData } from 'src/WebApiController';
import {
  CatalogResults,
  Marketplace,
  ReportGroupBy,
  SaleReportMetrics,
} from 'src/WebApiController';

export const mapReportMetrics = (
  reportMetrics: SaleReportMetrics[] | undefined,
  eventsTransformed: EventWithData[] | undefined,
  data: CatalogResults | undefined
): SaleReportMetricsWithGroupBy[] | undefined => {
  const isArAgingReport =
    reportMetrics != null && reportMetrics.every((r) => r.timeBkt != null);

  const getGroupByData = (r: SaleReportMetrics) => {
    const event = r.eventId
      ? eventsTransformed?.find(
          (e) =>
            e.event.viagId?.toString() === r.eventId ||
            e.event.mappingId === r.eventId
        )?.event
      : undefined;

    const performerId = r.perfId ?? event?.perfId;
    const performer = performerId ? data?.performers?.[performerId] : undefined;

    const venueId = r.venueId ?? event?.venueId;
    const venue = venueId ? data?.venues?.[venueId] : undefined;

    return { event, performer, venue };
  };

  let metricsMapped: SaleReportMetricsWithGroupBy[] | undefined;

  // For now assume that metrics are grouped by marketplace
  if (isArAgingReport) {
    // Group by marketplace, then reduce to get the timeBucketsWithData
    const metricsByMarketplace = reportMetrics.reduce(
      (acc, r) => {
        const key = r.mkp!;
        const existing = acc[key] ?? [];
        return {
          ...acc,
          [key]: [...existing, r],
        };
      },
      {} as Record<Marketplace, SaleReportMetrics[]>
    );

    metricsMapped = Object.values(metricsByMarketplace).map((metrics) => {
      const timeBucketsWithData = metrics.reduce((acc, r) => {
        return [
          ...acc,
          {
            timeBkt: r.timeBkt!,
            ttlOutsBal: r.ttlOutsBal!,
          },
        ];
      }, [] as ArTimeBucketWithData[]);

      const { event, performer, venue } = getGroupByData(metrics[0]);
      return {
        ...metrics[0],
        performer,
        venue,
        event,
        timeBucketsWithData,
      } as SaleReportMetricsWithGroupBy;
    });
  } else {
    metricsMapped = reportMetrics?.map((r) => {
      const { event, performer, venue } = getGroupByData(r);

      return {
        ...r,
        performer,
        venue,
        event,
      } as SaleReportMetricsWithGroupBy;
    });
  }

  return metricsMapped;
};

export const mapReportMetricsSummary = (
  reportConfig: ReportConfig,
  reportSummary: SaleReportMetrics | undefined,
  reportMetricsMapped: SaleReportMetricsWithGroupBy[] | undefined
): SaleReportMetricsWithGroupBy | undefined => {
  if (reportConfig.groupBy !== ReportGroupBy.ArAgingMarketplace) {
    return reportSummary as SaleReportMetricsWithGroupBy;
  }

  const timeBucketsWithData = reportMetricsMapped?.reduce((acc, metric) => {
    if (acc.length === 0) {
      acc.push(...cloneDeep(metric.timeBucketsWithData!));
      return acc;
    }

    metric.timeBucketsWithData?.forEach((d) => {
      const existingIndex = acc.findIndex(
        (a) =>
          a.timeBkt?.relativeEndSecs === d.timeBkt?.relativeEndSecs &&
          a.timeBkt?.relativeStartSecs === d.timeBkt?.relativeStartSecs
      );
      if (existingIndex !== -1) {
        acc[existingIndex].ttlOutsBal = addMoneySameCurrency(
          acc[existingIndex].ttlOutsBal,
          d.ttlOutsBal,
          undefined,
          undefined
        )!;
      } else {
        acc.push(d);
      }
    });

    return acc;
  }, [] as ArTimeBucketWithData[]);

  return {
    ...reportSummary,
    timeBucketsWithData,
  } as SaleReportMetricsWithGroupBy;
};
