import { ColumnDef } from '@tanstack/react-table';
import { GroupedActivityLog } from 'src/components/ActivityLog';
import { ActivityLogAdditionalInfo } from 'src/components/ActivityLog/ActivityLogAdditionalInfo';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { CountBadge, Stack } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { ExpandIcon, ForwardIcon } from 'src/svgs/Viagogo';
import { TableShimmeringDiv } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import {
  ActivityLogAdditionalInformationDictionaryOfActivityLogValueChange,
  ActivityLogInitiatedByUser,
} from 'src/WebApiController';

import { ActivityLogDateCell } from './ActivityLogDateCell';
import { ActivityLogDateRangeCell } from './ActivityLogDateRangeCell';

export const ACTIVITY_LOG_GROUPED_TABLE_COLUMNS_CONFIG: ColumnDef<GroupedActivityLog | null>[] =
  [
    {
      id: 'accordionToggle',
      header: '',
      minSize: 24,
      maxSize: 40,
      meta: {
        styleOverrides: { padding: 0 },
      },
      cell: ({
        row: { original, toggleExpanded, getCanExpand, getIsExpanded },
      }) => {
        const canExpand = true; // getCanExpand();
        const isExpanded = getIsExpanded();
        const Icon = isExpanded ? ExpandIcon : ForwardIcon;
        if ((original && original?.activityLogs.length < 2) || !original) {
          return null;
        }

        return (
          <TableCellDiv align="center" showTooltip={false}>
            {canExpand && (
              <Stack
                direction="column"
                height="full"
                alignItems="center"
                gap="s"
              >
                <CountBadge
                  count={original.activityLogs.length}
                  color="brandLight"
                />
                <Icon
                  size={vars.iconSize.s}
                  withHoverEffect
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    toggleExpanded(!isExpanded);
                  }}
                />
              </Stack>
            )}
          </TableCellDiv>
        );
      },
    },
    {
      id: 'actionDate',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.ActivityLogDate} />
        </TableHeaderCellDiv>
      ),
      maxSize: 90,
      accessorFn: (data) => (data ? data.actionDate || '' : null),
      cell: ({
        getValue,
        row: { original, getCanExpand },
        table: { getState },
      }) => {
        const canExpand = getCanExpand();

        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }

        if (canExpand) {
          const sortState = getState().sorting.find(
            (s) => s.id === 'actionDate'
          );
          const activityLogs = original?.activityLogs;
          const firstDate = activityLogs[0].actionDate;
          const lastDate = activityLogs[activityLogs.length - 1].actionDate;

          const [dateFrom, dateTo] = [firstDate, lastDate].sort((a, b) => {
            return sortState?.desc ? b.localeCompare(a) : a.localeCompare(b);
          });

          return (
            <TableCellDiv align="left">
              <ActivityLogDateRangeCell
                dateFrom={dateFrom ? stringToUtcDate(dateFrom) : null}
                dateTo={dateTo ? stringToUtcDate(dateTo) : null}
              />
            </TableCellDiv>
          );
        }

        return (
          <TableCellDiv align="left">
            <ActivityLogDateCell date={value ? stringToUtcDate(value) : null} />
          </TableCellDiv>
        );
      },
      sortingFn: 'datetime',
    },
    {
      id: 'actionType',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.ActivityLogActionType} />
        </TableHeaderCellDiv>
      ),
      enableSorting: false,
      accessorFn: (data) => (data ? data.actionTypeDisplay : null),
      cell: ({ getValue, row: { original } }) => {
        const actionTypeDisplay = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="left" title={actionTypeDisplay}>
            {actionTypeDisplay}
          </TableCellDiv>
        );
      },
    },
    {
      id: 'initiatedBy',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.ActivityLogInitiatedBy} />
        </TableHeaderCellDiv>
      ),
      enableSorting: false,
      accessorFn: (data) => data?.initiatedBy,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as ActivityLogInitiatedByUser;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="left" overflowWrap="anywhere">
            {value?.username}
          </TableCellDiv>
        );
      },
    },
    {
      id: 'additionalInfo',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.ActivityLogAdditionalInfo} />
        </TableHeaderCellDiv>
      ),
      enableSorting: false,
      accessorFn: (data) => (data ? data.additionalInfo : null),
      cell: ({ getValue, row: { original, getCanExpand } }) => {
        const canExpand = getCanExpand();

        const value =
          getValue() as ActivityLogAdditionalInformationDictionaryOfActivityLogValueChange;
        if (!original) {
          return <TableShimmeringDiv />;
        }

        if (canExpand) {
          return null;
        }
        return (
          <TableCellDiv align="left">
            <ActivityLogAdditionalInfo {...value} />
          </TableCellDiv>
        );
      },
    },
  ];
