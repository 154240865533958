import { useFormContext } from 'react-hook-form';
import { TicketTypePriorityInput } from 'src/components/common/TicketTypePriorityInput';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button, Popover, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import { SellerAccountEventSettingsInput } from './FulfillmentSection.types';
import { TicketTypePriorityPill } from './TicketTypePriorityPill';

export const TicketTypePriorityCell = ({
  disabled,
  index,
}: {
  disabled?: boolean;
  index: number;
}) => {
  const { watch, setValue } = useFormContext<SellerAccountEventSettingsInput>();

  const delivType = watch(`settings.${index}.delivType`);
  const ticketTypeRules = watch(`settings.${index}.tktTypeRules`);

  const ticketTypePriorities = ticketTypeRules?.overrides.find(
    (t) => t.deliveryType === delivType
  )?.ticketTypePriorities;

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      gap="m"
      width="full"
      alignItems="center"
    >
      {ticketTypePriorities
        ?.sort((a, b) => {
          if (a.priority === -1 || a.isBlocked) return 1;
          if (b.priority === -1 || b.isBlocked) return -1;
          return a.priority - b.priority;
        })
        .map((t, i) => (
          <TicketTypePriorityPill
            key={`ticket-type-priority-${i}`}
            ticketTypePriority={t}
          />
        ))}
      <Popover.Root>
        <Popover.Trigger asChild>
          <Button
            variant="textPlain"
            style={{ padding: vars.spacing['xs'] }}
            disabled={disabled}
          >
            <Content id={ContentId.Update} />
          </Button>
        </Popover.Trigger>
        <Popover.Content
          align="start"
          style={{
            maxHeight: 'var(--radix-popover-content-available-height)',
          }}
        >
          <div style={{ padding: vars.spacing['m'] }}>
            <TicketTypePriorityInput
              deliveryType={delivType}
              ticketTypeRules={ticketTypeRules}
              onTicketTypeRulesChange={(newRules) => {
                setValue(`settings.${index}.tktTypeRules`, newRules, {
                  shouldDirty: true,
                });
              }}
              disabled={disabled}
            />
          </div>
        </Popover.Content>
      </Popover.Root>
    </Stack>
  );
};
