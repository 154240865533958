import { StarIcon as StarIconOutline } from '@heroicons/react/24/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/24/solid';
import { ReactNode, useState } from 'react';
import { Stack } from 'src/core/ui';

import * as styles from './InventoryEventPage.css';

export const TABS_NONE = 'none';

export function TabWithStarIcon<TTabs>({
  children,
  onIconClick,
  defaultTab,
  tabType,
}: {
  children: ReactNode;
  onIconClick: (value: TTabs) => void;
  defaultTab?: TTabs | undefined;
  tabType: TTabs;
}) {
  const [iconHovered, setIconHovered] = useState(false);

  return (
    <Stack
      direction="row"
      gap="m"
      alignItems="center"
      className={styles.tabHover}
    >
      {children}
      {defaultTab === tabType ? (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            onIconClick(TABS_NONE as TTabs);
          }}
        >
          <StarIconSolid className={`${styles.defaultTabIcon}`} />
        </div>
      ) : (
        <div
          onMouseEnter={() => setIconHovered(true)}
          onMouseLeave={() => setIconHovered(false)}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            onIconClick(tabType);
          }}
          className={styles.hoverIconContainer}
        >
          {iconHovered ? (
            <StarIconSolid className={`${styles.icon} ${styles.iconVisible}`} />
          ) : (
            <StarIconOutline
              className={`${styles.icon} ${styles.iconVisible}`}
            />
          )}
        </div>
      )}
    </Stack>
  );
}
