import { Content } from 'src/contexts/ContentContext';
import {
  ConfirmDialog,
  ConfirmDialogProps,
} from 'src/core/interim/dialogs/ConfirmDialog';
import { ContentId } from 'src/utils/constants/contentId';

import { contentWrapper } from './DeleteHeatMapTemplateConfirmDialog.css';

export type DeleteHeatMapTemplateConfirmDialogProps = Omit<
  ConfirmDialogProps,
  'headerText' | 'bodyText'
> & {
  onConfirm: () => void;
  onCancel: () => void;
};

export const DeleteHeatMapTemplateConfirmDialog = ({
  onConfirm,
  onCancel,
  ...props
}: DeleteHeatMapTemplateConfirmDialogProps) => {
  return (
    <ConfirmDialog
      size="md"
      headerText={<Content id={ContentId.ConfirmDeletion} />}
      bodyText={
        <div className={contentWrapper}>
          <Content id={ContentId.DeleteAllTemplates} />
        </div>
      }
      okText={ContentId.Confirm}
      cancelText={ContentId.Cancel}
      {...props}
      onOkay={() => onConfirm()}
      onCancel={() => onCancel()}
    />
  );
};
