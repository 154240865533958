import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { ListingGroup } from 'src/WebApiController';

import { AdvancePricingSettings } from './AdvancePricingSettings';
import { AutoPricingSettingsSection } from './AutoPricingSettingsSection';
import { useListingGroupAutoPricingSettings } from './useListingGroupAutoPricingSettings';

export const AutoPricingSettings = ({
  listingGroup,
}: {
  listingGroup?: ListingGroup;
}) => {
  const { canAutoPrice } = useListingGroupAutoPricingSettings(listingGroup);

  if (!canAutoPrice) {
    return null;
  }

  return (
    <>
      <DetailSection name={<Content id={ContentId.Autopricing} />}>
        <SectionContent numOfColumns={1}>
          {canAutoPrice ? (
            <AutoPricingSettingsSection listingGroup={listingGroup} />
          ) : (
            <DetailGroup style={{ gridColumn: '1' }}>
              <Stack direction="column" gap="l">
                {!canAutoPrice ? (
                  <Content id={ContentId.NoAutoPricing_MissingPermission} />
                ) : (
                  <Content id={ContentId.NoAutoPricing_NoVenueConfig} />
                )}
              </Stack>
            </DetailGroup>
          )}
        </SectionContent>
      </DetailSection>
      <AdvancePricingSettings listingGroup={listingGroup} />
    </>
  );
};
