import { useState } from 'react';
import { SerializedSegment } from 'src/core/POS/BezierGraph';
import { roundToPrecision } from 'src/utils/numberFormatter';

import {
  RelativeScoreGraph,
  RelativeScoreGraphBezier,
  RelativeScoreGraphBezierProps,
  RelativeScoreGraphRow,
  ROW_GAP_HEIGHT,
  ROW_HEIGHT,
} from './RelativeScoreGraph';

const ROW_COUNT = 10;

export const INITIAL_SEGMENTS: SerializedSegment[] = (() => {
  const totalHeight = ROW_COUNT * ROW_HEIGHT + (ROW_COUNT - 1) * ROW_GAP_HEIGHT;
  const firstPointX = (ROW_HEIGHT / 2 / totalHeight) * 100;
  return [
    { point: { x: firstPointX, y: 34.5 } },
    {
      point: { x: 100 - firstPointX, y: 50 },
      handleIn: { x: 0, y: -11 },
      handleOut: { x: 0, y: 5 },
    },
    { point: { x: 100 - firstPointX, y: 60 } },
  ];
})();

/**
 * Reference: https://github.com/stubhub/StubHub.PointOfSale.AutoPricing/blob/1efd01964818286fe07c14af0bec48094f91b068/src/StubHub.PointOfSale.AutoPricing/Models/Strategies/CompListingSetting.cs#L50
 */
export type QuantityScoreAdjustment = {
  /**
   * quantity
   */
  q: number;
  /**
   * seat score multiplier
   */
  m: number;
};

export type SeatScoreQuantityMultiplerGraphProps = {
  initialSegments?: RelativeScoreGraphBezierProps['initialSegments'];
  onChange?: (ev: {
    overrides: QuantityScoreAdjustment[];
    segments: SerializedSegment[];
  }) => void;
};

export function SeatScoreQuantityMultiplerGraph({
  initialSegments = INITIAL_SEGMENTS,
  onChange,
}: SeatScoreQuantityMultiplerGraphProps) {
  const [values, setValues] = useState<number[]>(() => {
    return Array<number>(ROW_COUNT).fill(0);
  });
  return (
    <RelativeScoreGraph>
      {values.map((value, i) => (
        <RelativeScoreGraphRow
          key={i}
          label={values.length - i}
          valueDisplay={value.toLocaleString(undefined, {
            style: 'percent',
            minimumFractionDigits: 1,
          })}
          value={value}
        />
      ))}
      <RelativeScoreGraphBezier
        initialSegments={initialSegments}
        rowCount={values.length}
        onChange={({ values, segments }) => {
          setValues(values);

          onChange?.({
            overrides: values.map((val, i) => ({
              q: ROW_COUNT - i,
              m: roundToPrecision(1 + val, 3),
            })),
            segments,
          });
        }}
      />
    </RelativeScoreGraph>
  );
}
