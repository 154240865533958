import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { SellerUserSettingsContext } from 'src/contexts/SellerUserSettingsContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  CustomQuickFilterOfListingQuery,
  CustomQuickFilterOfPurchaseOrderQuery,
  CustomQuickFilterOfSaleQuery,
  SellerUserClient,
  UserSetting,
} from 'src/WebApiController';

/**
 * Generic type for a `useUserSetting` hook.
 *
 * This can be implemented with different storage solutions.
 */
export type UseUserSettingHook = <T>(options: {
  id?: UserSetting;
  currentLoginUserOnly?: boolean;
}) => {
  value: T | undefined;
  setUserSetting: (value: T) => Promise<unknown>;
  isLoading: boolean;
};

/**
 * An implementation of an `useUserSetting` hook using backend apis for storage.
 *
 * **NOTE**: Any getters and setters for settings with enum values from the backend should implement
 * custom overrides. These should be added in the sections marked with `ADD OVERRIDES HERE` in the
 * implementation.
 * @param option
 * @returns
 */
export const useServerUserSetting: UseUserSettingHook = <T>({
  id,
  currentLoginUserOnly = true,
}: {
  id?: UserSetting;
  /* whether to use the active account's user or the login user (which includes impersonating user) Id */
  currentLoginUserOnly?: boolean;
}) => {
  const { showErrorDialog, trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const sellerUserClient = useMemo(
    () => new SellerUserClient(activeAccountWebClientConfig),
    [activeAccountWebClientConfig]
  );

  // Try to use the seller user settings context if possible
  const sellerUserSettingsContext = useContext(SellerUserSettingsContext);
  const userSettingsContextQueryResult = sellerUserSettingsContext?.queryResult;
  const contextIsReady =
    userSettingsContextQueryResult != null &&
    userSettingsContextQueryResult?.isFetched &&
    !userSettingsContextQueryResult.isRefetching &&
    !userSettingsContextQueryResult?.isPending;

  const queryKey = useMemo(
    () => [
      'userSetting',
      activeAccountWebClientConfig.activeAccountId,
      currentLoginUserOnly,
      id,
      contextIsReady,
    ],
    [
      activeAccountWebClientConfig.activeAccountId,
      contextIsReady,
      currentLoginUserOnly,
      id,
    ]
  );

  const result = useQuery<T | null>({
    queryKey,
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) return null;

      // ADD OVERRIDES HERE FOR SETTINGS WITH ENUMS VALUES
      switch (id) {
        case UserSetting.QuickFiltersCustomInventory:
          return (await sellerUserClient.getCustomInventoryQuickFiltersSetting()) as T;
        case UserSetting.QuickFiltersCustomSales:
          return (await sellerUserClient.getCustomSalesQuickFiltersSetting()) as T;
        case UserSetting.QuickFiltersCustomPurchases:
          return (await sellerUserClient.getCustomPurchasesQuickFiltersSetting()) as T;
      }
      return defaultGetSellerUserSetting(currentLoginUserOnly);
    },

    enabled: activeAccountWebClientConfig?.activeAccountId != null,
    staleTime: Infinity, // We invalidate on user settings changed, so we don't need to make this stale
    refetchOnWindowFocus: false,
    meta: {
      persist: false,
      onError: (error: ErrorTypes) => {
        trackError('SellerUserClient.getSellerUserSetting', error, {
          trackErrorData: { id },
        });
      },
    },
  });

  const defaultGetSellerUserSetting = useCallback(
    async (currentLoginUserOnly?: boolean) => {
      if (!id) {
        return null;
      }

      if (sellerUserSettingsContext?.contextSettingIds?.includes(id)) {
        return null;
      }

      const resp = await sellerUserClient.getSellerUserSettings(
        [id],
        currentLoginUserOnly ?? false
      );

      const userSettingValue = resp[id];

      return userSettingValue == null
        ? null
        : (JSON.parse(userSettingValue) satisfies T);
    },
    [id, sellerUserClient, sellerUserSettingsContext?.contextSettingIds]
  );

  const defaultSetSellerUserSetting = useCallback(
    async (value: T, currentLoginUserOnly?: boolean) => {
      return await sellerUserClient.setSellerUserSetting(
        id,
        JSON.stringify(value),
        currentLoginUserOnly ?? true
      );
    },
    [id, sellerUserClient]
  );

  const queryClient = useQueryClient();
  const setUserSetting = useCallback(
    (value: T) => {
      if (id && sellerUserSettingsContext?.contextSettingIds.includes(id)) {
        const newData = {
          ...(sellerUserSettingsContext.queryResult.data ?? {}),
          [id]: JSON.stringify(value),
        };
        queryClient.setQueryData(
          sellerUserSettingsContext.contextQueryKey,
          newData
        );
      } else {
        queryClient.setQueryData(queryKey, value);
      }

      return tryInvokeApi(
        async () => {
          if (activeAccountWebClientConfig.activeAccountId == null) return;

          let success = false;
          // ADD OVERRIDES HERE FOR SETTINGS WITH ENUMS VALUES
          switch (id) {
            case UserSetting.QuickFiltersCustomInventory:
              success =
                await sellerUserClient.setCustomInventoryQuickFiltersSetting(
                  value as CustomQuickFilterOfListingQuery[]
                );
              break;
            case UserSetting.QuickFiltersCustomSales:
              success =
                await sellerUserClient.setCustomSalesQuickFiltersSetting(
                  value as CustomQuickFilterOfSaleQuery[]
                );
              break;
            case UserSetting.QuickFiltersCustomPurchases:
              success =
                await sellerUserClient.setCustomPurchasesQuickFiltersSetting(
                  value as CustomQuickFilterOfPurchaseOrderQuery[]
                );
              break;
            default:
              success = await defaultSetSellerUserSetting(
                value,
                currentLoginUserOnly
              );
              break;
          }

          if (success) {
            if (
              id &&
              sellerUserSettingsContext?.contextSettingIds.includes(id)
            ) {
              queryClient.invalidateQueries({
                queryKey: sellerUserSettingsContext.contextQueryKey,
              });
            } else {
              queryClient.invalidateQueries({ queryKey });
            }
          }
        },
        (error) => {
          showErrorDialog('onSubmitChangePrice', error, {
            trackErrorData: {
              userSettings: queryKey,
            },
          });
        }
      );
    },
    [
      activeAccountWebClientConfig.activeAccountId,
      currentLoginUserOnly,
      defaultSetSellerUserSetting,
      id,
      queryClient,
      queryKey,
      sellerUserClient,
      sellerUserSettingsContext?.contextQueryKey,
      sellerUserSettingsContext?.contextSettingIds,
      sellerUserSettingsContext?.queryResult,
      showErrorDialog,
    ]
  );

  const getValue = useCallback(() => {
    if (id && sellerUserSettingsContext?.contextSettingIds?.includes(id)) {
      const userSettingValue = userSettingsContextQueryResult?.data?.[id];

      return userSettingValue == null
        ? null
        : (JSON.parse(userSettingValue) satisfies T);
    }

    return result.data;
  }, [
    id,
    result.data,
    sellerUserSettingsContext?.contextSettingIds,
    userSettingsContextQueryResult?.data,
  ]);

  return {
    value: getValue() ?? undefined,
    setUserSetting: setUserSetting,
    isLoading: result.isLoading,
  };
};
