import { ReactNode } from 'react';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { Permission } from 'src/WebApiController';

interface PurchaseMultiSelectPermissionsProps {
  children: ReactNode;
}

export const PurchasesBulkActionsPermissions = ({
  children,
}: PurchaseMultiSelectPermissionsProps) => {
  const hasPurchaseUpdatePermission = useUserHasAnyOfPermissions(
    Permission.Purchases_Edit,
    Permission.Purchases_EditOwned
  );

  if (!hasPurchaseUpdatePermission) {
    return null;
  }

  return <>{children}</>;
};
