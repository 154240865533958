import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function ConnectedIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M15.75 2v5h-1.656V3.625H3.625v10.469h10.469l-.001-4.219H9.875v2.583l-1.625.813V8.25H22V22H8.25v-6.25H2V2h13.75zm4.594 7.875H15.75v5.875H9.875v4.594h10.469V9.875z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
