import { isEqual } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button, Stack } from 'src/core/ui';
import { ApplyHeatMapToAllEventsDialog } from 'src/dialogs/ApplyHeatMapToAllEventsDialog';
import { ChangeRowScoreDialogMultiSection } from 'src/dialogs/ChangeRowScoreDialog';
import { SectionRowTableDialog } from 'src/dialogs/SectionRowTableDialog/SectionRowTableDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { EventVenueHeatMap } from 'src/modals/common/EventSeatMap';
import { EventSeatMapSelection } from 'src/modals/EventSeatMapSelection';
import { ContentId } from 'src/utils/constants/contentId';
import { EventPricingSeatMapForm } from 'src/utils/eventWithDataUtils';
import {
  getCompleteEventConfigScoreOverrides,
  getEventConfigScoreOverride,
} from 'src/utils/seatScoreUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Event,
  EventConfigScoreOverride,
  Feature,
  PricingClient,
  RowInfo,
  ScoreModel,
  SectionInfo,
  SectionScoreOverride,
} from 'src/WebApiController';

import * as styles from './InventoryEventEditMapSection.css';

type EventSeatMapFormType = Pick<EventPricingSeatMapForm, 'eventScoreOverride'>;

export type EventScoreUpdate = {
  newScoreOverrides?: SectionScoreOverride[];
  newConfigPayload?: string;
};

type InventoryEventEditMapSectionProps = {
  event: Event;
};

export const InventoryEventEditMapSection: React.FC<
  InventoryEventEditMapSectionProps
> = ({ event }) => {
  return <InventoryEventEditMapSectionBody event={event} />;
};

export const InventoryEventEditMapSectionBody = ({
  event,
}: {
  event: Event;
}) => {
  const { activeConfigOverride, mapConfigOverridesQuery, isMapLoading } =
    useEventMapContext();

  const methods = useForm<EventSeatMapFormType>({
    defaultValues: {
      eventScoreOverride: activeConfigOverride,
    },
  });

  useEffect(() => {
    if (
      activeConfigOverride !== null &&
      !isEqual(
        methods.formState.defaultValues?.eventScoreOverride,
        activeConfigOverride
      )
    ) {
      methods.reset({
        eventScoreOverride: activeConfigOverride,
      });
    }
  }, [activeConfigOverride, methods]);

  if (
    (!activeConfigOverride && mapConfigOverridesQuery.isLoading) ||
    isMapLoading
  ) {
    return <PosSpinner />;
  }

  return (
    <FormProvider {...methods}>
      <InventoryEventEditMapSectionContent event={event} />
    </FormProvider>
  );
};

export const InventoryEventEditMapSectionContent = ({
  event,
}: {
  event: Event;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { watch, setValue, getValues, formState, handleSubmit } =
    useFormContext<EventSeatMapFormType>();

  const hasScaledSeatScoreFeature = useUserHasFeature(Feature.ScaledSeatScore);

  const {
    venueMapInfo,
    venueMapsByScoreModelQuery,
    activeConfigOverride,
    mapConfigOverridesQuery,
  } = useEventMapContext();
  const { setModal } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const { pricingSettings } = useGetEventAutoPricingSettings(event!, true);
  const hasScoreSectionRowTableFeature = useUserHasFeature(
    Feature.ScoreSectionRowTable
  );

  const sanitizedScoreModel = useMemo(() => {
    const scoreModel = pricingSettings?.scoreModel;
    if (scoreModel) return scoreModel;

    const { AdvancedVenueGeometry, MergedVenueGeometry } =
      venueMapsByScoreModelQuery.data ?? {};
    if (AdvancedVenueGeometry) return ScoreModel.AdvancedVenueGeometry;
    if (MergedVenueGeometry) return ScoreModel.MergedVenueGeometry;
    return ScoreModel.VenueGeometry;
  }, [pricingSettings?.scoreModel, venueMapsByScoreModelQuery.data]);

  const modeledSectionScores = useMemo(() => {
    switch (sanitizedScoreModel) {
      case ScoreModel.AdvancedVenueGeometry:
        return venueMapsByScoreModelQuery.data?.AdvancedVenueGeometry;

      case ScoreModel.MergedVenueGeometry:
        return venueMapsByScoreModelQuery.data?.MergedVenueGeometry;

      default:
        return venueMapsByScoreModelQuery.data?.VenueGeometry;
    }
  }, [
    sanitizedScoreModel,
    venueMapsByScoreModelQuery.data?.AdvancedVenueGeometry,
    venueMapsByScoreModelQuery.data?.MergedVenueGeometry,
    venueMapsByScoreModelQuery.data?.VenueGeometry,
  ]);

  // Hook form fields
  const eventScoreOverride = watch('eventScoreOverride');
  const configPayload = watch('eventScoreOverride.cfgPayload');
  const name = watch('eventScoreOverride.name');
  const scoreOverrides =
    watch('eventScoreOverride.scoreOverrides') ??
    getCompleteEventConfigScoreOverrides(modeledSectionScores?.sectionScores);

  const newModelName = useContent(ContentId.NewModel);
  const changeRowScoreDialog = useBasicDialog();
  const sectionRowDialog = useBasicDialog();
  const applyToAllDialog = useBasicDialog();

  const [applyToAllEventsForConfig, setApplyToAllEventsForConfig] = useState<
    EventConfigScoreOverride | undefined
  >();

  const onSectionSeatScoreChanged = useCallback(
    ({ newScoreOverrides, newConfigPayload }: EventScoreUpdate) => {
      let newOverride: EventConfigScoreOverride | undefined = undefined;
      if (eventScoreOverride) {
        newOverride = { ...eventScoreOverride };
      } else if (activeConfigOverride) {
        newOverride = { ...activeConfigOverride };
      } else {
        newOverride = getEventConfigScoreOverride(
          event!.viagId!,
          modeledSectionScores ?? venueMapInfo!,
          newModelName,
          null
        );
      }

      if (newScoreOverrides != null) {
        newOverride.scoreOverrides = newScoreOverrides;
      }
      if (newConfigPayload != null) {
        newOverride.cfgPayload = newConfigPayload;
      }

      if (newScoreOverrides != null || newConfigPayload != null) {
        setValue('eventScoreOverride', newOverride);
      }

      return Promise.resolve();
    },
    [
      eventScoreOverride,
      activeConfigOverride,
      event,
      modeledSectionScores,
      venueMapInfo,
      newModelName,
      setValue,
    ]
  );

  const onSectionRowChange = useCallback(
    (updated: SectionScoreOverride[]) => {
      let newOverride: EventConfigScoreOverride | undefined = undefined;
      if (eventScoreOverride) {
        newOverride = { ...eventScoreOverride };
      } else if (activeConfigOverride) {
        newOverride = { ...activeConfigOverride };
      } else {
        newOverride = getEventConfigScoreOverride(
          event!.viagId!,
          modeledSectionScores ?? venueMapInfo!,
          newModelName,
          null
        );
      }
      newOverride.scoreOverrides = updated;
      setValue('eventScoreOverride', newOverride);
    },
    [
      activeConfigOverride,
      event,
      eventScoreOverride,
      modeledSectionScores,
      newModelName,
      setValue,
      venueMapInfo,
    ]
  );

  const onRowSeatScoreChanged = useCallback(
    (
      newScoreOverrides: SectionScoreOverride[],
      newConfigPayload: string | null
    ) => {
      onSectionSeatScoreChanged({
        newScoreOverrides,
        newConfigPayload: newConfigPayload ?? undefined,
      }).then(() => {
        changeRowScoreDialog.closeDialog();
      });
    },
    [onSectionSeatScoreChanged, changeRowScoreDialog]
  );

  const allSections = useMemo(
    () => venueMapInfo?.sections ?? [],
    [venueMapInfo?.sections]
  );

  const sectionWithRowsWithOrdinal = useMemo(() => {
    const sections: SectionInfo[] = [];
    const rows: Record<number, RowInfo[]> = {};
    allSections.forEach((s) => {
      rows[s.id] = s.rows
        .filter((r) => !r.isSpeculative && r.ordinal != null)
        .sort((a, b) => (a.ordinal ?? 0) - (b.ordinal ?? 0));
    });

    allSections.forEach((s) => {
      if (rows[s.id].length > 0) {
        sections.push(s);
      }
    });
    return sections;
  }, [allSections]);

  const scoreName = useMemo(() => {
    if (name) {
      return name;
    }

    if (sanitizedScoreModel === ScoreModel.AdvancedVenueGeometry) {
      return <Content id={ContentId.StubhubRecommended} />;
    }

    if (sanitizedScoreModel === ScoreModel.MergedVenueGeometry) {
      return <Content id={ContentId.MergedVenueGeometry} />;
    }

    if (sanitizedScoreModel === ScoreModel.VenueGeometry) {
      return <Content id={ContentId.VenueGeometry} />;
    }

    return <Content id={ContentId.Default} />;
  }, [name, sanitizedScoreModel]);

  const onSubmit = useCallback(
    async (eventForm: EventSeatMapFormType) => {
      tryInvokeApi(
        async () => {
          const client = new PricingClient(activeAccountWebClientConfig);

          if (
            event?.viagId &&
            eventForm.eventScoreOverride &&
            !isEqual(
              eventForm.eventScoreOverride,
              formState.defaultValues?.eventScoreOverride
            )
          ) {
            setIsLoading(true);
            const newId = await client.upsertEventSeatMapScoreOverride({
              ...eventForm.eventScoreOverride,
              viagogoEventId: event.viagId,
              scoreOverrides: getCompleteEventConfigScoreOverrides(
                venueMapInfo?.sectionScores,
                eventForm.eventScoreOverride.scoreOverrides,
                false,
                hasScaledSeatScoreFeature
              )!,
            });

            setValue('eventScoreOverride.id', newId);

            const result = await mapConfigOverridesQuery.refetch();
            const newConfig = result.data?.find((ev) => ev.id === newId);

            const oldId = eventForm.eventScoreOverride?.id;
            if (
              newId !== activeConfigOverride?.id &&
              oldId <= 0 &&
              (newConfig?.eventIds?.length ?? 0) >= 2
            ) {
              setApplyToAllEventsForConfig(newConfig);
              applyToAllDialog.launchDialog();
              return;
            }

            await client.setEventSeatMapScoreOverride(
              event.viagId,
              newId,
              null
            );
          }
        },
        (error) => {
          showErrorDialog('PricingClient.updates', error, {
            trackErrorData: eventForm,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      activeConfigOverride?.id,
      applyToAllDialog,
      event.viagId,
      formState.defaultValues?.eventScoreOverride,
      hasScaledSeatScoreFeature,
      mapConfigOverridesQuery,
      setValue,
      showErrorDialog,
      venueMapInfo?.sectionScores,
    ]
  );

  const hasChanges = useCallback(
    (eventForm: EventSeatMapFormType) => {
      // Make sure we only send back the settable props
      return !isEqual(
        formState.defaultValues?.eventScoreOverride,
        eventForm.eventScoreOverride
      );
    },
    [formState.defaultValues?.eventScoreOverride]
  );

  const onSubmitHandler = useCallback(() => {
    if (!hasChanges(getValues())) return;

    handleSubmit(onSubmit)();
  }, [getValues, handleSubmit, hasChanges, onSubmit]);

  if (!event) {
    return null;
  }

  if (isLoading) {
    return <PosSpinner />;
  }

  return (
    <>
      <Stack direction="row" gap="l" className={styles.sectionContainer}>
        <Stack direction="column" gap="l" className={styles.editableMap}>
          <div className={styles.heatmapContainer}>
            <EventVenueHeatMap
              scoreOverrides={scoreOverrides}
              configPayload={configPayload}
              onSubmitSectionScoreChange={onSectionSeatScoreChanged}
            />
          </div>
          <div className={styles.heatmapFooter}>
            <div>
              <Stack direction="row" gap="m">
                {sectionWithRowsWithOrdinal.length > 0 && (
                  <Button
                    variant="link"
                    size="lg"
                    onClick={changeRowScoreDialog.launchDialog}
                    style={{ paddingLeft: 0 }}
                  >
                    <Content id={ContentId.EditRowScores} />
                  </Button>
                )}
                {hasScoreSectionRowTableFeature && (
                  <Button
                    variant="link"
                    size="lg"
                    onClick={sectionRowDialog.launchDialog}
                  >
                    <Content id={ContentId.CheckVenueRows} />
                  </Button>
                )}
              </Stack>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <div>
                {scoreName}
                {'  -'}
              </div>
              <Button
                variant="link"
                size="lg"
                onClick={() => {
                  // We need to save the seat map prior to closing the modal and launch the
                  // seat map selection modal
                  onSubmit(getValues()).then(() =>
                    setModal({
                      children: (
                        <EventSeatMapSelection event={event} fromTabView />
                      ),
                      clickOutsideToClose: true,
                      size: 'slide-in',
                    })
                  );
                }}
              >
                <Content id={ContentId.Edit} />
              </Button>
            </div>
          </div>
        </Stack>
        <Stack
          direction="column"
          gap="l"
          className={styles.heatmapInstructions}
        >
          <Content id={ContentId.HowItWorks} />
          <div className={styles.heatmapInstructionsDetails}>
            <li>
              <Content id={ContentId.HeatMapInstruction1} />
            </li>
            <li>
              <Content id={ContentId.HeatMapInstruction2} />
            </li>
          </div>
        </Stack>
      </Stack>
      <div className={styles.absoluteButton}>
        <Button
          variant="outline"
          size="lg"
          disabled={isLoading || !hasChanges(getValues())}
          onClick={onSubmitHandler}
        >
          <Content id={ContentId.Save} />
        </Button>
      </div>
      <ChangeRowScoreDialogMultiSection
        {...changeRowScoreDialog.dialogProps}
        selectedSections={sectionWithRowsWithOrdinal}
        configPayload={configPayload}
        scoreOverrides={scoreOverrides}
        onOkay={onRowSeatScoreChanged}
        onCancel={changeRowScoreDialog.closeDialog}
      />
      <SectionRowTableDialog
        {...sectionRowDialog.dialogProps}
        sections={venueMapInfo?.sections ?? []}
        scoreOverrides={scoreOverrides}
        onClose={sectionRowDialog.closeDialog}
        onSectionRowChange={onSectionRowChange}
      />

      <ApplyHeatMapToAllEventsDialog
        {...applyToAllDialog.dialogProps}
        event={event!}
        eventIds={applyToAllEventsForConfig?.eventIds}
        eventConfigScoreOverrideId={applyToAllEventsForConfig?.id}
        onClose={() => {
          setApplyToAllEventsForConfig(undefined);
          applyToAllDialog.closeDialog();
        }}
      />
    </>
  );
};
