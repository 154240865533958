import clsx from 'clsx';
import { useMemo } from 'react';
import { ConnectedEventInfo } from 'src/components/Events/ConnectedEventInfo';
import { MetricsDisplay } from 'src/components/Metrics';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { TagIcon } from 'src/svgs';
import { CheckIcon } from 'src/svgs/Viagogo';
import {
  getInventoryListingMetrics,
  getInventoryListingMetricsV2,
} from 'src/utils/ticketMetricUtils';
import { Feature, ListingMetrics } from 'src/WebApiController';

import * as styles from './InventoryEventCatchUp.css';
import { InventoryEventCatchUpCardProps } from './InventoryEventCatchUpCard.types';

export function InventoryEventCatchUpCard({
  event,
  showYear,
  onClick,
  isActive,
  isReviewed,
}: InventoryEventCatchUpCardProps) {
  const { venue, performer } = useGetEventFullInfo(event);
  const { eventMetrics } = useCatalogMetricsContext<ListingMetrics>();
  const { loginContext } = useAppContext();
  const hasInventoryTileMetricV2Feature = useUserHasFeature(
    Feature.InventoryTileMetricV2
  );
  const getInventoryListingMetricsFn = hasInventoryTileMetricV2Feature
    ? getInventoryListingMetricsV2
    : getInventoryListingMetrics;

  const metrics = useMemo(
    () =>
      getInventoryListingMetricsFn(
        eventMetrics?.[event.viagVirtualId],
        loginContext?.user?.activeAccount?.currencyCode
      ),
    [
      getInventoryListingMetricsFn,
      eventMetrics,
      event,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );

  return (
    <Stack
      className={clsx(styles.inventoryEventCatchUpCard, {
        [styles.inventoryEventCatchUpCardActive]: isActive,
        [styles.inventoryEventCatchUpCardReviewed]: isReviewed,
      })}
      direction="column"
      gap="s"
      onClick={onClick}
    >
      <Stack direction="row" justifyContent="spaceBetween" alignItems="center">
        <div>
          {metrics && (
            <MetricsDisplay
              style={{ alignItems: 'flex-start' }}
              key="catchupCardMetrics"
              mainMetrics={
                <div className={styles.mainMetricsContainer}>
                  {!hasInventoryTileMetricV2Feature && (
                    <TagIcon
                      fill={vars.color.textPrimary}
                      size={vars.iconSize.m}
                    />
                  )}
                  {metrics.mainMetrics}
                </div>
              }
            />
          )}
        </div>
        <div>
          {isReviewed && (
            <CheckIcon
              className={styles.checkIcon}
              size={vars.iconSize.s}
              fill={vars.color.backgroundBrand}
            />
          )}
        </div>
      </Stack>
      <ConnectedEventInfo
        showYear={showYear}
        showEventDateTbd
        event={event}
        performer={performer}
        venue={venue}
        slimMode
      />
    </Stack>
  );
}
