import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PricingClient } from 'src/WebApiController';

export const useGetEventSellThroughRates = (viagogoEventId?: number | null) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    activeAccountWebClientConfig.activeAccountId != null &&
    viagogoEventId != null;

  const sellThroughRatesQuery = useQuery({
    queryKey: [
      'getEventSellThroughRates',
      activeAccountWebClientConfig.activeAccountId,
      viagogoEventId,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new PricingClient(
        activeAccountWebClientConfig
      ).getEventSellThroughRates(viagogoEventId!);
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('PricingClient.getEventSellThroughRates', error, {
          viagogoEventId,
        });
      },
    },
  });

  return sellThroughRatesQuery;
};
