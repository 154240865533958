import { ReactNode } from 'react';
import {
  ConnectedEventInfo,
  ConnectedEventInfoProps,
} from 'src/components/Events/ConnectedEventInfo';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { EventMappingStateFilter } from 'src/utils/eventQueryUtils';
import { EventWithData } from 'src/WebApiController';
import { Event, Performer, Venue } from 'src/WebApiController';

import * as styles from './EventItemHeader.css';

export const METRICS_PLACEHOLDER_DIMS = { width: '50px' };

export type EventItemHeaderType = EventWithData & {
  performer?: Performer | null;
  venue?: Venue | null;
  hideYearFromDate: boolean;
  mainMetrics?: string | React.ReactNode;
  subMetrics?: string[] | React.ReactNode[];
};

export type EventItemHeaderComponentType = Pick<
  ConnectedEventInfoProps,
  'showYear' | 'showMappingState'
> & {
  event: Event;
  performer?: Performer | null;
  venue?: Venue | null;
  actions?: ReactNode;
  headerMetrics?: ReactNode;
  metrics?: ReactNode;
};

/**
 * Wraps `ConnectedEventInfo` and adds actions and metrics.
 * @param props
 * @returns
 */
export const EventItemHeader = ({
  showYear,
  event,
  performer,
  venue,
  actions,
  headerMetrics,
  metrics,
  showMappingState,
}: EventItemHeaderComponentType) => {
  const isMobile = useMatchMedia('mobile');

  const eventMappingState =
    event.viagId == null
      ? event.mapRejRsnId != null
        ? EventMappingStateFilter.Rejected
        : EventMappingStateFilter.Pending
      : undefined; // Mapped is not used for display yet

  const afterContent = (
    <>
      <Stack className={styles.actions} gap="m" alignItems="center">
        {actions}
        {headerMetrics}
      </Stack>
      <Stack gap="s" alignItems="center">
        {metrics}
      </Stack>
    </>
  );

  return (
    <Stack
      className={styles.root}
      direction="column"
      gap="m"
      justifyContent="spaceBetween"
    >
      <ConnectedEventInfo
        showYear={showYear}
        showEventDateTbd
        event={event}
        performer={performer}
        venue={venue}
        showMappingState={showMappingState}
        eventMappingState={eventMappingState}
        mappingRejectionReasonId={event.mapRejRsnId}
        mappingRejectionReasonDescriptionDefault={event.mapRejDftTxt}
      />
      {isMobile ? (
        afterContent
      ) : (
        <Stack direction="column" gap="m" alignItems="end">
          {afterContent}
        </Stack>
      )}
    </Stack>
  );
};
