import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { debounce } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PurchaseClient, PurchaseVendorAccount } from 'src/WebApiController';

import { usePurchaseVendorSelector } from '../PurchaseVendorSelector/usePurchaseVendorSelector';

export type UsePurchaseVendorAccountSelectorOptions = {
  vendorId?: number | number[] | null;
  disabled?: boolean;
  additionalOptions?: Record<string, string>;
  additionalOptionsPosition?: 'top' | 'bottom';
  placeholderText?: string | ContentId;
  paginateCount?: number | null;
  searchText?: string;
  purchaseVendorAccountEmail?: string;
  vendorAccountIds?: string[];
};

export const usePurchaseVendorAccountSelector = ({
  vendorId,
  disabled,
  additionalOptions,
  additionalOptionsPosition = 'bottom',
  placeholderText,
  paginateCount = 1000,
  searchText,
  purchaseVendorAccountEmail,
  vendorAccountIds,
}: UsePurchaseVendorAccountSelectorOptions): {
  query: UseInfiniteQueryResult<
    InfiniteData<
      {
        results: PurchaseVendorAccount[];
        skip: number;
      },
      unknown
    >,
    Error
  >;
  sortedVendorAccounts: PurchaseVendorAccount[];
  availableVendorAccounts: Record<string, PurchaseVendorAccount>;
  posSelectProps: Pick<
    ComponentProps<typeof PosSelect>,
    | 'searchable'
    | 'placeholderText'
    | 'sortMode'
    | 'valueOptionsContent'
    | 'sortFn'
    | 'loading'
  >;
} => {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { availableVendors } = usePurchaseVendorSelector({});
  const hasVendorId = Array.isArray(vendorId)
    ? vendorId.length > 0
    : !!vendorId;

  const shouldQuery =
    !disabled &&
    activeAccountWebClientConfig.activeAccountId != null &&
    hasVendorId;

  const [debouncedSearchText, setDebouncedSearchText] = useState<
    string | undefined
  >(searchText);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSearchText = useCallback(
    debounce((value: string | undefined) => {
      setDebouncedSearchText(value);
    }, 500),
    []
  );

  useEffect(() => {
    if (searchText !== debouncedSearchText) {
      debouncedSetSearchText(searchText);
    }
  }, [searchText, debouncedSetSearchText, debouncedSearchText]);

  const query = useInfiniteQuery({
    queryKey: [
      'getAccessibleVendorAccounts',
      activeAccountWebClientConfig.activeAccountId,
      vendorId,
      paginateCount,
      debouncedSearchText,
    ],
    queryFn: async ({ pageParam: skip = 0 }) => {
      if (!shouldQuery || !hasVendorId) {
        return {
          results: [],
          skip,
        };
      }

      const vendorIds = Array.isArray(vendorId) ? vendorId : [vendorId!];
      const result = await tryInvokeApi(
        () => {
          return new PurchaseClient(
            activeAccountWebClientConfig
          ).getAccessibleVendorAccounts(
            { item1: vendorIds, item2: [] },
            searchText,
            paginateCount ? paginateCount : null,
            skip ?? 0
          );
        },
        (error) => {
          trackError('PurchaseClient.getAccessibleVendorAccounts', error, {
            vendorIds,
          });
        }
      );

      return {
        results: result ?? [],
        skip,
      };
    },
    getNextPageParam: (lastPage) => {
      return lastPage?.results.length
        ? lastPage.skip + lastPage.results.length
        : undefined;
    },
    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    networkMode: 'offlineFirst',
    initialPageParam: 0,
    placeholderData: (previousData) => previousData, // identity function with the same behaviour as `keepPreviousData`
  });

  const purchaseVendorAccountQuery = useQuery({
    queryKey: [
      'getAccessibleVendorAccounts',
      activeAccountWebClientConfig.activeAccountId,
      vendorId,
      purchaseVendorAccountEmail,
      vendorAccountIds,
    ],
    queryFn: async ({ pageParam: skip = 0 }) => {
      if (!shouldQuery || !hasVendorId) {
        return null;
      }

      const vendorIds = Array.isArray(vendorId) ? vendorId : [vendorId!];

      return tryInvokeApi(
        () => {
          return new PurchaseClient(
            activeAccountWebClientConfig
          ).getAccessibleVendorAccounts(
            { item1: vendorIds, item2: vendorAccountIds || [] },
            purchaseVendorAccountEmail,
            null,
            null
          );
        },
        (error) => {
          trackError('PurchaseClient.getAccessibleVendorAccounts', error, {
            vendorIds,
            vendorAccountIds,
          });
        }
      );
    },
    enabled: shouldQuery && !!purchaseVendorAccountEmail,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    networkMode: 'offlineFirst',
  });

  const sortedVendorAccounts = useMemo<PurchaseVendorAccount[]>(() => {
    if (!query.data) {
      return [];
    }

    // NOTE - it is purposely to use a Map and for loops here instead of
    // using the spread operator (...) because the spread operator will
    // cause a Stack-overflow when there are too many elements
    const resultMap = new Map<string, PurchaseVendorAccount>();

    query.data.pages.forEach((p) => {
      p.results.forEach((i) => resultMap.set(i.id, i));
    });

    purchaseVendorAccountQuery.data?.forEach((p) => {
      resultMap.set(p.id, p);
    });

    const unionResult: PurchaseVendorAccount[] = [];
    for (const va of resultMap.values()) {
      unionResult.push(va);
    }

    return unionResult;
  }, [purchaseVendorAccountQuery.data, query.data]);

  const availableVendorAccounts = useMemo(() => {
    const results = sortedVendorAccounts
      ?.filter((d) => d.email || d.name) // Only show those with emails
      ?.reduce(
        (r, cur) => {
          r[cur.id] = cur;
          return r;
        },
        {} as Record<string, PurchaseVendorAccount>
      );

    return results ?? {};
  }, [sortedVendorAccounts]);

  const availableVendorAccountsOptions = useMemo(() => {
    const results = sortedVendorAccounts
      ?.filter((d) => d.email || d.name) // Only show those with emails
      ?.reduce(
        (r, cur) => {
          const vendor = availableVendors[cur.vendorId];
          r[cur.id] = `${cur.email || cur.name} ${
            Array.isArray(vendorId) && vendorId.length > 1 && vendor
              ? '(' + vendor.name + ')'
              : ''
          }`;
          return r;
        },
        (additionalOptions && additionalOptionsPosition === 'top'
          ? additionalOptions
          : {}) as Record<string, string>
      );

    return {
      ...(additionalOptionsPosition === 'top' ? additionalOptions : {}),
      ...(results ?? {}),
      ...(additionalOptionsPosition === 'bottom' ? additionalOptions : {}),
    };
  }, [
    additionalOptions,
    additionalOptionsPosition,
    availableVendors,
    sortedVendorAccounts,
    vendorId,
  ]);

  const addOptionTexts = useMemo(() => {
    return Object.keys(additionalOptions ?? {});
  }, [additionalOptions]);

  return {
    query,
    sortedVendorAccounts,
    availableVendorAccounts,
    posSelectProps: {
      searchable: true,
      loading: query.isLoading,
      placeholderText: placeholderText ?? ContentId.ChooseAccount,
      valueOptionsContent: availableVendorAccountsOptions,
      sortMode: 'ascending',
      sortFn: (a: string, b: string) => {
        if (addOptionTexts.includes(a)) {
          return additionalOptionsPosition === 'top' ? -1 : 1;
        }
        if (addOptionTexts.includes(b)) {
          return additionalOptionsPosition === 'top' ? 1 : -1;
        }
        return availableVendorAccountsOptions[a]?.localeCompare(
          availableVendorAccountsOptions[b]
        );
      },
    },
  };
};
