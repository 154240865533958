import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  ListingDetails,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkRemovePredeliveryDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  onOkay: (
    removeBarcodes: boolean,
    removePdfs: boolean,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

export const BulkRemovePredeliveryDialog = ({
  updateKey,
  onCancel,
  onOkay,
  ...rest
}: BulkRemovePredeliveryDialogProps) => {
  const [removeBarcodes, setRemoveBarcodes] = useState(true);
  const [removePdfs, setRemovePdfs] = useState(true);

  const [isLoading, setIsLoading] = useState(false);
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshExpandedListItems();
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.RemovePredelivery,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(false, false, false, onPreviewReceived);
      } else {
        onOkay(
          removeBarcodes,
          removePdfs,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [onOkay, removeBarcodes, removePdfs, preview, hasBackgroundBulkEditFeature]
  );

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.RemovePredeliveredArtifacts} />}
        />
      }
      onOpened={() => {
        setRemoveBarcodes(true);
        setRemovePdfs(true);
        initJob();
      }}
      onKeyUp={(e) => {
        if (
          submittButtonRef.current &&
          e.key === 'Enter' &&
          (removeBarcodes || removePdfs)
        ) {
          submittButtonRef.current.click();
        }
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={ActionOutboxEntityType.Listing}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
      {...rest}
    >
      <BulkEditStatus
        entityType={ActionOutboxEntityType.Listing}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack direction="column" gap="l">
          <WarningMessage
            message={
              <Content id={ContentId.RemovePredeliveredArtifactsWarning} />
            }
          />

          <Stack direction="column" gap="l">
            <Checkbox
              checked={removeBarcodes}
              disabled={isLoading}
              onChange={(e) => setRemoveBarcodes(e.target.checked)}
              label={<Content id={ContentId.RemoveBarcodes} />}
              labelPosition="right"
            />
            <Checkbox
              checked={removePdfs}
              disabled={isLoading}
              onChange={(e) => setRemovePdfs(e.target.checked)}
              label={<Content id={ContentId.RemovePdfs} />}
              labelPosition="right"
            />

            {isLoading && <PosSpinner />}
          </Stack>
        </Stack>
      </BulkEditStatus>
    </GenericDialog>
  );
};
