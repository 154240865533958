import { useNavigate } from 'react-router-dom';
import { Content } from 'src/contexts/ContentContext';
import { ToolbarButton } from 'src/core/POS/ToolbarButton';
import { vars } from 'src/core/themes';
import { Badge } from 'src/core/ui/Badge';
import { ScrollableToolbar } from 'src/core/ui/ScrollableToolbar';
import { CalendarOutlineIcon, ChatIcon, MailIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

export type EmailNavPageName =
  | 'inbox'
  | 'uncategorized'
  | 'recentlyCategorized';

export const EMAIL_NAV_PAGE_NAME_MAP: { [pageName in EmailNavPageName]: true } =
  {
    inbox: true,
    uncategorized: true,
    recentlyCategorized: true,
  };

type EmailNavToolbarProps = {
  pageName: EmailNavPageName;
  uncategorizedEmailsCount?: number | null;
};

export function EmailNavToolbar({
  pageName,
  uncategorizedEmailsCount,
}: EmailNavToolbarProps) {
  const navigate = useNavigate();
  return (
    <ScrollableToolbar>
      <ToolbarButton
        isSelected={pageName === 'inbox'}
        onClick={() => navigate('/messages')}
      >
        <ChatIcon size={vars.iconSize.s} />
        <span>
          <Content id={ContentId.Messages} />
        </span>
      </ToolbarButton>
      <ToolbarButton
        isSelected={pageName === 'uncategorized'}
        onClick={() => navigate('/messages/uncategorized')}
      >
        <MailIcon size={vars.iconSize.s} />
        <span>
          <Content id={ContentId.Uncategorized} />
        </span>
        {uncategorizedEmailsCount != null && (
          <Badge>{uncategorizedEmailsCount}</Badge>
        )}
      </ToolbarButton>
      <ToolbarButton
        isSelected={pageName === 'recentlyCategorized'}
        onClick={() => navigate('/messages/recently-categorized')}
      >
        <CalendarOutlineIcon size={vars.iconSize.s} />
        <span>
          <Content id={ContentId.RecentlyCategorized} />
        </span>
      </ToolbarButton>
    </ScrollableToolbar>
  );
}
