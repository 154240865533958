import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function MarginMetricIcon(props: PosIconProps) {
  return (
    <SvgWrapper {...props} viewBox="0 0 57 58" fill="none">
      <path
        d="M56.5402 2.95654C56.5162 1.29986 55.1537 -0.023681 53.4971 0.000328804L26.4999 0.391592C24.8432 0.415602 23.5197 1.77807 23.5437 3.43475C23.5677 5.09143 24.9302 6.41497 26.5868 6.39096L50.5843 6.04317L50.9321 30.0407C50.9561 31.6973 52.3186 33.0209 53.9753 32.9969C55.632 32.9729 56.9555 31.6104 56.9315 29.9537L56.5402 2.95654ZM5.15184 57.1174L55.6924 5.09037L51.3887 0.909657L0.848162 52.9367L5.15184 57.1174Z"
        fill="currentColor"
      />
      <path
        d="M22.5135 16.5C22.5135 23.0443 18.4977 27 15.0135 27C11.5293 27 7.51352 23.0443 7.51352 16.5C7.51352 9.95568 11.5293 6 15.0135 6C18.4977 6 22.5135 9.95568 22.5135 16.5Z"
        stroke="currentColor"
        strokeWidth="6"
      />
      <path
        d="M53.5135 44.5C53.5135 51.0443 49.4977 55 46.0135 55C42.5293 55 38.5135 51.0443 38.5135 44.5C38.5135 37.9557 42.5293 34 46.0135 34C49.4977 34 53.5135 37.9557 53.5135 44.5Z"
        stroke="currentColor"
        strokeWidth="6"
      />
    </SvgWrapper>
  );
}
