import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { useContent } from 'src/contexts/ContentContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';

import { SellerAccountEventSettingsInput } from './FulfillmentSection.types';

export const VendorCell = ({
  disabled,
  index,
}: {
  disabled?: boolean;
  index: number;
}) => {
  const { watch, setValue, clearErrors } =
    useFormContext<SellerAccountEventSettingsInput>();

  const allVendorsText = useContent(ContentId.AllVendors);

  const settings = watch('settings');
  const vendorId = watch(`settings.${index}.vendorId`);

  const { posSelectProps } = usePurchaseVendorSelector({
    placeholderText: allVendorsText,
  });

  const availableVendorOptions = Object.entries(
    posSelectProps.valueOptionsContent
  ).reduce(
    (acc, [key, value]) => {
      if (
        !settings?.some(
          (s, i) =>
            i !== index && s.vendorId != null && s.vendorId.toString() === key
        )
      ) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, string>
  );

  const isAllVendorsSelectedInOtherRows = useMemo(
    () => settings?.some((s, i) => i !== index && s.vendorId == null),
    [index, settings]
  );

  useEffect(() => {
    if (
      (vendorId == null && isAllVendorsSelectedInOtherRows) ||
      (vendorId != null &&
        !Object.keys(availableVendorOptions).includes(vendorId.toString()))
    ) {
      setValue(
        `settings.${index}.vendorId`,
        Number(Object.keys(availableVendorOptions)[0]),
        {
          shouldDirty: true,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PosSelect
      value={vendorId?.toString()}
      onChange={(value: string | null) => {
        clearErrors(`settings.${index}.vendorId`);
        setValue(`settings.${index}.vendorId`, value ? Number(value) : null, {
          shouldDirty: true,
        });
      }}
      disabled={disabled}
      {...posSelectProps}
      valueOptionsContent={availableVendorOptions}
      enableEmptySelection={!isAllVendorsSelectedInOtherRows}
    />
  );
};
