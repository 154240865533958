import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { DetailSection } from 'src/modals/common';
import { ListingNotesSection } from 'src/modals/common/ListingNotesSection';
import {
  DeliverySection,
  EconomicsSection,
  TicketsSection,
} from 'src/modals/ListingDetails/components';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Event,
  ListingDetails,
  ListingDetailsUpdateInput,
} from 'src/WebApiController';

export const GeneralSettings = ({
  event,
  isLoading,
}: {
  listing?: ListingDetails | undefined;
  event?: Event;
  isLoading?: boolean;
}) => {
  const { watch } = useFormContext<ListingDetailsUpdateInput>();

  return (
    <>
      <TicketsSection disabled={isLoading} isBulkEdit />

      <DeliverySection disabled={isLoading} isBulkEdit />

      <EconomicsSection
        disabled={isLoading}
        activeEvent={event}
        displayMode={'NO_PRICE'}
        isBulkEdit
      />
    </>
  );
};
