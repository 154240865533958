import { useMemo } from 'react';
import { getContent, useContentContext } from 'src/contexts/ContentContext';
import {
  DELIVERY_OPTIONS_TO_CID,
  TICKET_TYPE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  DeliveryType,
  TicketType,
  ValueTupleOfDeliveryTypeAndNullableTicketType,
} from 'src/WebApiController';

interface UseMapDeliveryTypesToStringProps {
  deliveryTypesObj:
    | {
        deliveryTypes?: ValueTupleOfDeliveryTypeAndNullableTicketType[];
        delivType?: DeliveryType | null;
        topPriTktType?: TicketType | null;
      }
    | undefined
    | null;
}

export const useMapDeliveryTypesToString = ({
  deliveryTypesObj,
}: UseMapDeliveryTypesToStringProps): string => {
  const contentContext = useContentContext();

  const { deliveryTypes, delivType, topPriTktType } = deliveryTypesObj || {};

  return useMemo<string>(() => {
    const delTypes = deliveryTypes
      ? deliveryTypes
      : [{ item1: delivType, item2: topPriTktType }];

    return delTypes
      .map((dt) =>
        dt.item2
          ? getContent(TICKET_TYPE_TO_CID[dt.item2], contentContext)
          : dt.item1
          ? getContent(DELIVERY_OPTIONS_TO_CID[dt.item1], contentContext)
          : ''
      )
      .join(', ');
  }, [contentContext, delivType, deliveryTypes, topPriTktType]);
};
