import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { EventSearch } from 'src/components/Events/EventSearch';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  Event,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';
import * as styles from './BulkChangeEventDialog.css';

export type BulkChangeEventDialogProps = ComponentProps<typeof RSModal> & {
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.TicketGroup;
  updateKey: string;
  onOkay: (
    event: Event | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
  doNotUseBackgroundProgress?: boolean;
};

export function BulkChangeEventDialog({
  entityType,
  updateKey,
  onOkay,
  onCancel,
  doNotUseBackgroundProgress,
  ...rest
}: BulkChangeEventDialogProps) {
  const {
    refreshCatalog,
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);
  const [event, setEvent] = useState<Event>();

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        if (event) {
          setListItemExpansion(true, [event.viagVirtualId]);
        }

        await refreshCatalog();
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      event,
      onCancel,
      refreshCatalog,
      setListItemExpansion,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    entityType,
    BulkActionType.ChangeEvent,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    !doNotUseBackgroundProgress &&
    bulkEditHub?.state === HubConnectionState.Connected;

  const onEventsSelect = useCallback(
    (
      data: {
        event: Event;
      }[]
    ) => {
      const events = data.map((e) => e.event);
      setEvent(events[0]);
    },
    []
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(null, false, onPreviewReceived);
      } else {
        onOkay(event!, hasBackgroundBulkEditFeature, undefined, preview);
      }
      setIsLoading(false);
    },
    [onOkay, event, preview, hasBackgroundBulkEditFeature]
  );

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      className={styles.bulkEditDialogContainer}
      size={stage === BulkEditStage.Preview ? 'xl' : 'lg'}
      isFullHeight={stage === BulkEditStage.Idle}
      header={
        <BulkEditHeader headerText={<Content id={ContentId.ChangeEvent} />} />
      }
      onOpened={() => {
        setEvent(undefined);
        initJob();
      }}
      onKeyUp={(e) => {
        if (submittButtonRef.current && e.key === 'Enter' && event) {
          submittButtonRef.current.click();
        }
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={entityType}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          disabled={!event}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={entityType}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
        height="full"
      >
        <EventSearch
          selectedEvents={event?.viagVirtualId ? [event.viagVirtualId] : []}
          onEventsSelect={onEventsSelect}
          numEventsSelected={0}
          isSingleSelect
        />
      </BulkEditStatus>
    </GenericDialog>
  );
}
