import { useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { ActivityLog } from 'src/components/ActivityLog';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { TabPanel } from 'src/core/ui/TabPanel';
import { TabItem } from 'src/core/ui/TabPanel/TabPanel.types';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { BackToModalButton } from 'src/modals/common';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { ActivityLogClient } from 'src/WebApiController';

import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { ModalBodyDataContainer } from '../Modal/Modal.styled';
import { ListingActivityLogTab, TAB_ALL } from './ActivityLogModal.constants';
import {
  getActivityLogFilterByTab,
  getActivityLogTabValues,
} from './ActivityLogModal.utils';
import { useGetActivityLogByFilter } from './useGetActivityLogByFilter';

export const ActivityLogModal = ({ cancelTo }: { cancelTo?: ModalProps }) => {
  const { loadingState } = useEventItemLoadingDisplay(
    FormatContentId.LoadingSaleId,
    FormatContentId.SearchingForSaleId,
    FormatContentId.CouldNotFindSaleId
  );

  return loadingState ? (
    loadingState
  ) : (
    <ActivityLogModalContent cancelTo={cancelTo} />
  );
};

const ActivityLogModalContent = ({ cancelTo }: { cancelTo?: ModalProps }) => {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const details = useActivePosEntityContext();

  const [activeTab, setActiveTab] = useState<string>(TAB_ALL);

  const shouldQuery =
    details.posEntity?.id != null &&
    details.posEntity?.entityType != null &&
    activeAccountWebClientConfig.activeAccountId != null;

  const activityLogTagNameQuery = useQuery({
    queryKey: [
      'ActivityLogClient.GetActivityLogTagNamesByEntityId',
      activeAccountWebClientConfig.activeAccountId,
      details.posEntity?.id,
      details.posEntity?.entityType,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      return await new ActivityLogClient(
        activeAccountWebClientConfig
      ).getActivityLogTagNamesByEntityId(
        details.posEntity!.id!,
        details.posEntity!.entityType
      );
    },

    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'ActivityLogClient.getActivityLogTagNamesByEntityId',
          error,
          {
            itemId: details.posEntity?.id,
            entityType: details.posEntity?.entityType,
          }
        );
      },
    },
    enabled: shouldQuery,
  });

  const tagNames = useMemo(
    () =>
      activityLogTagNameQuery.data?.sort((a, b) => a.localeCompare(b)) ?? [],
    [activityLogTagNameQuery.data]
  );

  const activityLogTopLevelFilter = useMemo(() => {
    return {
      ...getActivityLogFilterByTab(
        details.posEntity?.entityType ?? null,
        activeTab,
        tagNames.includes(activeTab)
      ),
      entityId: details.posEntity?.id ?? null,
    };
  }, [
    activeTab,
    details.posEntity?.entityType,
    details.posEntity?.id,
    tagNames,
  ]);

  const { activityLogQuery } = useGetActivityLogByFilter(
    activityLogTopLevelFilter,
    true,
    // disabled
    details.posEntity?.id == null
  );

  const activityLogDisplay = useMemo(
    () => (
      <>
        {activityLogQuery.isLoading ? (
          <PosSpinner />
        ) : (
          <ActivityLog
            count={activityLogQuery.data?.length ?? 0}
            failedToRetrieveData={Boolean(activityLogQuery.error)}
            activityLogs={activityLogQuery.data ?? []}
          />
        )}
      </>
    ),
    [activityLogQuery.data, activityLogQuery.error, activityLogQuery.isLoading]
  );

  const tabs: TabItem[] = useMemo(
    () => [
      ...getActivityLogTabValues(details.posEntity?.entityType ?? null).map(
        ({ value, contentId }) => {
          return {
            value,
            title: <Content id={contentId} />,
            content: activityLogDisplay,
          };
        }
      ),
      ...tagNames.map((tagName) => {
        return {
          value: tagName,
          title: <span>{tagName}</span>,
          content: activityLogDisplay,
        };
      }),
    ],
    [activityLogDisplay, details.posEntity?.entityType, tagNames]
  );

  const activeTabIndex = useMemo(() => {
    return tabs.findIndex((item) => item.value === activeTab) ?? 0;
  }, [activeTab, tabs]);

  const onValueChange = useCallback(
    (v: string) => setActiveTab(v as ListingActivityLogTab),
    []
  );

  return (
    <>
      <CancellableFormHeader cancelTo={cancelTo}>
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.ActivityLogLaunchButtonText} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        <ModalBodyDataContainer>
          <TabPanel
            tabs={tabs}
            activeTabIndex={activeTabIndex}
            onValueChange={onValueChange}
            orientation="vertical"
            tabListProps={{ style: { paddingTop: vars.spacing['lg'] } }}
          />
        </ModalBodyDataContainer>
      </ModalBody>

      <ModalFooter>
        <BackToModalButton cancelTo={cancelTo} textContentId={ContentId.Back} />
      </ModalFooter>
    </>
  );
};
