import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext/Content';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { Detail, DetailGroup } from 'src/modals/common';
import { OpenLinkIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import { getListingDetailsRelativeUrl } from 'src/utils/inventoryUtils';
import { selectDistinctListingIds } from 'src/utils/ticketUtils';
import { Ticket } from 'src/WebApiController';

import * as styles from '../SaleDetails.css';

export const ListingLinksDetail = ({
  listingId,
  originatedFromListingId,
  tickets,
}: {
  listingId?: number | null;
  originatedFromListingId?: number | null;
  tickets?: Ticket[];
}) => {
  const renderListing = (contentId: string, listingId?: number | null) => {
    if (!listingId) return null;

    return (
      <DetailGroup>
        <Detail
          label={<Content id={contentId} />}
          detail={
            <NavLink
              title={getListingDetailsRelativeUrl(listingId)}
              tag={Link}
              className="nav-link"
              to={getListingDetailsRelativeUrl(listingId)}
            >
              <Stack style={{ alignItems: 'center' }}>
                <div className={styles.ticketListingId}>{'#' + listingId}</div>
                <OpenLinkIcon
                  size={vars.iconSize.s}
                  fill={vars.color.textBrand}
                  withHoverEffect
                  style={{ marginBottom: 2 }}
                />
              </Stack>
            </NavLink>
          }
        />
      </DetailGroup>
    );
  };

  const distinctListingIds = tickets?.length
    ? selectDistinctListingIds(tickets)
    : [];

  const hasOriginatedListing =
    originatedFromListingId &&
    distinctListingIds.includes(originatedFromListingId);

  return (
    <>
      {tickets?.length ? (
        <>
          {!hasOriginatedListing &&
            renderListing(ContentId.OriginatedListing, originatedFromListingId)}
          {distinctListingIds.map((listingId) =>
            renderListing(
              listingId === originatedFromListingId
                ? ContentId.OriginatedListing
                : ContentId.AllocatedListing,
              listingId
            )
          )}
        </>
      ) : (
        <>
          {originatedFromListingId &&
            renderListing(ContentId.OriginatedListing, originatedFromListingId)}
          {listingId !== originatedFromListingId &&
            renderListing(ContentId.AllocatedListing, listingId)}
        </>
      )}
    </>
  );
};
