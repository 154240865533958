import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const EventSearchInputBoxContainer = styled.div`
  flex: 1;
  display: grid;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(media.breakpoints.md, `min-width: 600px;`)}`}
`;

export const EventSearchItemDiv = styled.div`
  display: flex;
  gap: ${() => vars.spacing.lg};
  align-items: center;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
  border-radius: ${() => vars.borderRadius.m};
  padding-left: ${() => vars.spacing.lg};

  &:hover {
    background-color: ${(props) => props.theme.colors.backgroundSecondary};
    border-color: ${(props) => props.theme.colors.borderDark};
  }
  svg {
    cursor: pointer;
  }
`;

export const EventSearchItemInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  flex-grow: 1;
  padding: ${() =>
    `${vars.spacing.lg} ${vars.spacing.lg} ${vars.spacing.lg} 0`};
`;
