import { ReactNode } from 'react';
import { FlattenedViewIcon } from 'src/svgs/FlattenedViewIcon';
import { TileViewIcon } from 'src/svgs/TileViewIcon';
import { IconsFill } from 'src/svgs/Viagogo';
import { PurchaseViewMode } from 'src/WebApiController';

export const PURCHASE_VIEW_MODE_TO_ICON: Record<string, ReactNode> = {
  [PurchaseViewMode.TileView]: (
    <TileViewIcon fill={IconsFill.currentColor} withHoverEffect />
  ),
  [PurchaseViewMode.FlattenedView]: (
    <FlattenedViewIcon fill={IconsFill.currentColor} withHoverEffect />
  ),
  [PurchaseViewMode.EventTileView]: (
    <TileViewIcon fill={IconsFill.currentColor} withHoverEffect />
  ),
};
