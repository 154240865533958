import { useMemo } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import {
  mapBrandNameToBackgroundcolor,
  mapBrandNameToTextcolor,
} from 'src/utils/constants/colorMaps';
import { ContentId } from 'src/utils/constants/contentId';
import { Marketplace } from 'src/WebApiController';

import { usePurchaseVendorSelector } from '../../Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import * as styles from './MerchantColorBadge.css';

export const MerchantColorBadge = ({
  vendorId,
  name,
}: {
  vendorId?: number | null;
  name?: Marketplace | string | null;
}) => {
  const { availableVendors } = usePurchaseVendorSelector({});
  const vendor = useMemo(() => {
    if (vendorId == null) {
      return null;
    }

    return availableVendors[vendorId];
  }, [availableVendors, vendorId]);

  const directOfflineContent = useContent(ContentId.DirectOffline);

  const displayName = useMemo(() => {
    return vendor?.name ?? name ?? directOfflineContent;
  }, [directOfflineContent, name, vendor]);

  return (
    <div
      style={{
        background: mapBrandNameToBackgroundcolor(displayName),
        color: mapBrandNameToTextcolor(displayName),
      }}
      className={styles.merchantColorBadge}
    >
      {displayName}
    </div>
  );
};
