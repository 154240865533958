import { DefaultTheme } from 'styled-components/macro';

import { Colors } from './constants/colors';
import { PurpleTheme } from './legacy';
import {
  ThemeColors,
  ThemeMediaQuery,
  ThemeTypography,
} from './legacy/theme-types';

type CustomDefaultTheme = DefaultTheme & {
  name: string;
  rounded: boolean;
  floatingInputLabel: boolean;
  filledInLoading: boolean;
  colors: ThemeColors;
  typography: ThemeTypography;
  spacing: (...multipliers: number[]) => string;
  media: ThemeMediaQuery;
  logoPath: string;
};

const OldTheme = PurpleTheme as CustomDefaultTheme;

export const DarkLegacyTheme: CustomDefaultTheme = {
  ...OldTheme /* We will slowly remove this as will fill in all the blanks */,
  colors: {
    ...OldTheme.colors,
    borderPrimary: Colors.neutral075,
    borderActive: Colors.indigo200,
    borderDark: Colors.neutral075,
    backgroundPrimary: Colors.neutral025,
    backgroundSecondary: Colors.dark060,
    backgroundBrandActive: Colors.indigo300,
    backgroundBrandLight: Colors.indigo100,
    backgroundBrandHover: Colors.indigo200,
    backgroundDark: Colors.neutral025,
    textBrand: Colors.indigo600,
    textBrandHover: Colors.indigo200,
    textOnBrandBgLink: Colors.white,
    textStrong: Colors.neutral500,
  },
};
