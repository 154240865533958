import { UniqueIdentifier } from '@dnd-kit/core';
import { AnimateLayoutChanges, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ReactNode } from 'react';
import { PlaceholderColumnGroupListItem } from 'src/modals/ColumnSettings/ColumnGroupListItem';

/**
 * Extends functionality of any group component to inherit sort functionality
 */
export const DropGroupWrapper = ({
  children,
  id,
  columnIdDisplayNameMapping,
}: {
  children: ReactNode;
  id: UniqueIdentifier;
  columnIdDisplayNameMapping: Record<string, string>;
}) => {
  const animateLayoutChanges: AnimateLayoutChanges = ({
    isSorting,
    wasDragging,
  }) => (isSorting || wasDragging ? false : true);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, animateLayoutChanges });

  // When dragging, collapse the children to show one item only
  return (
    <div
      ref={setNodeRef}
      style={{
        cursor: 'move',
        transform: CSS.Translate.toString(transform),
        transition: transition,
        ...(isDragging
          ? {
              opacity: 0.5,
            }
          : {}),
      }}
      {...attributes}
      {...listeners}
    >
      {isDragging ? (
        <PlaceholderColumnGroupListItem
          displayName={columnIdDisplayNameMapping[id]}
        />
      ) : (
        children
      )}
    </div>
  );
};
