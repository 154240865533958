import { groupBy, isEmpty, keyBy } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { SectionInfo, SectionScoreOverride } from 'src/WebApiController';

const sortSectionName = (a = '', b = '') => {
  const numA = parseInt(a);
  const numB = parseInt(b);
  if (isNaN(numA) || isNaN(numB)) {
    return a.localeCompare(b);
  }
  return numA - numB;
};

export type EnrichedScoreOrverride = SectionScoreOverride & {
  sectionName?: string;
  isNew?: boolean;
};

export const useTableData = (
  sections: SectionInfo[],
  scoreOverrides?: SectionScoreOverride[]
) => {
  const idToSection = useMemo(() => keyBy(sections, 'id'), [sections]);
  const sectionToZoneId = useMemo(
    () =>
      scoreOverrides?.reduce(
        (acc, override) => {
          acc[override.sectionId] = override.tkClsId;
          return acc;
        },
        {} as Record<number, number | null>
      ) ?? {},
    [scoreOverrides]
  );
  const rowIdToName = useMemo(
    () =>
      sections.reduce(
        (acc, section) => {
          section.rows.forEach((row) => {
            acc[row.id] = row.name;
          });
          return acc;
        },
        {} as Record<number, string | null>
      ),
    [sections]
  );

  // Captures the original ordinal of the row from catalog
  const rowIdToOrdinal = useMemo(
    () =>
      sections.reduce(
        (acc, section) => {
          section.rows.forEach((row) => {
            acc[row.id] = row.ordinal;
          });
          return acc;
        },
        {} as Record<number, number | null>
      ),
    [sections]
  );

  const [overrides, setOverrides] = useState<SectionScoreOverride[]>(
    scoreOverrides ?? []
  );
  const [selectedSectionIds, setSelectedSectionIds] = useState<number[]>([]);

  useEffect(() => {
    if (!isEmpty(scoreOverrides)) {
      setOverrides(
        scoreOverrides?.map((override) => ({
          ...override,
          rowName: override.rowName ?? rowIdToName[override.rowId],
        })) ?? []
      );
    } else {
      setOverrides(
        sections.flatMap((section) => {
          return section.rows.map((row) => ({
            sectionId: section.id,
            sectionName: section.name,
            rowId: row.id,
            rowName: row.name,
            rowOrdinal: row.ordinal,
            tkClsId: row.tktClass?.id ?? null,
            score: row.seatQualityScore,
            scoreRaw: row.seatQualityScore,
            isManualOverride: false,
          }));
        })
      );
    }
  }, [rowIdToName, scoreOverrides, sections, rowIdToOrdinal]);

  const enrichedOverrides: EnrichedScoreOrverride[] = useMemo(() => {
    return overrides.map((override) => ({
      ...override,
      sectionName: idToSection[override.sectionId]?.name,
      isNew: rowIdToName[override.rowId] == null,
    }));
  }, [idToSection, overrides, rowIdToName]);

  const data = useMemo(() => {
    const sectionToOverrides = groupBy(enrichedOverrides, 'sectionId');
    return Object.entries(sectionToOverrides)
      .map(([sectionId, scores]) => {
        const rows = scores
          .filter(({ rowOrdinal }) => rowOrdinal != null)
          .sort((a, b) => a.rowOrdinal! - b.rowOrdinal!);
        const unordered = scores
          .filter(({ rowOrdinal }) => rowOrdinal == null)
          .sort((a, b) => (a.rowName ?? '').localeCompare(b.rowName ?? ''));
        return {
          sectionId: Number(sectionId),
          section: idToSection[sectionId]?.name ?? '',
          rows,
          unordered,
        };
      })
      .sort((a, b) => sortSectionName(a.section, b.section));
  }, [enrichedOverrides, idToSection]);

  const onAddRows = useCallback(
    (rows: string[]) => {
      setOverrides((prev) => {
        const updated = [...prev];
        let minRowId = 0;
        for (const score of updated) {
          if (minRowId > score.rowId) {
            minRowId = score.rowId;
          }
        }
        let newOverrides: SectionScoreOverride[] = [];
        for (const sectionId of selectedSectionIds) {
          newOverrides = newOverrides.concat(
            rows.map((row, index) => ({
              sectionId: sectionId,
              rowId: minRowId - rows.length + index,
              rowName: row,
              rowOrdinal: index,
              tkClsId: sectionToZoneId[sectionId] ?? null,
              score: 0,
              scoreRaw: 0,
              isManualOverride: true,
            }))
          );

          minRowId -= rows.length;
          updated
            .filter((override) => override.sectionId === sectionId)
            .forEach((element) => {
              element.rowOrdinal =
                element.rowOrdinal != null
                  ? element.rowOrdinal + rows.length
                  : null;
              element.isManualOverride = true;
            });
        }

        // Reset manual override if the row is moved to its original position
        const proceeds = [...updated, ...newOverrides];
        proceeds.forEach((override) => {
          if (rowIdToOrdinal[override.rowId] == override.rowOrdinal) {
            override.isManualOverride = false;
          }
        });

        return proceeds;
      });
    },
    [rowIdToOrdinal, sectionToZoneId, selectedSectionIds]
  );

  const onSwapOrdinals = useCallback(
    (
      sectionId: number,
      from: EnrichedScoreOrverride,
      to: EnrichedScoreOrverride
    ) => {
      if (
        from.rowName == null ||
        from.rowOrdinal == null ||
        to.rowOrdinal == null ||
        to.rowName == null
      ) {
        return;
      }
      if (from.rowOrdinal == to.rowOrdinal) {
        return;
      }

      setOverrides((prev) => {
        const sectionRows = prev
          .filter(
            (override) =>
              override.sectionId === sectionId && override.rowOrdinal != null
          )
          .sort((a, b) => a.rowOrdinal! - b.rowOrdinal!);

        const proceeds = prev.map((override) => {
          if (override.sectionId === sectionId && override.rowOrdinal != null) {
            if (override.rowOrdinal === from.rowOrdinal) {
              const isManualOverride =
                override.isManualOverride ||
                to.rowOrdinal != override.rowOrdinal;
              return {
                ...override,
                rowOrdinal: to.rowOrdinal,
                isManualOverride,
              };
            }
            const currentIndex = sectionRows.findIndex(
              (r) => r.rowName === override.rowName
            );
            if (from.rowOrdinal! < to.rowOrdinal!) {
              if (
                override.rowOrdinal > from.rowOrdinal! &&
                override.rowOrdinal <= to.rowOrdinal!
              ) {
                if (currentIndex <= 0) {
                  return override;
                }
                const rowOrdinal = sectionRows[currentIndex - 1].rowOrdinal;
                const isManualOverride =
                  override.isManualOverride ||
                  rowOrdinal != override.rowOrdinal;
                return {
                  ...override,
                  rowOrdinal,
                  isManualOverride,
                };
              }
            }

            if (from.rowOrdinal! > to.rowOrdinal!) {
              if (
                override.rowOrdinal < from.rowOrdinal! &&
                override.rowOrdinal >= to.rowOrdinal!
              ) {
                if (currentIndex <= -1 || currentIndex >= sectionRows.length) {
                  return override;
                }
                const rowOrdinal = sectionRows[currentIndex + 1].rowOrdinal;
                const isManualOverride =
                  override.isManualOverride ||
                  rowOrdinal != override.rowOrdinal;
                return {
                  ...override,
                  rowOrdinal,
                  isManualOverride,
                };
              }
            }
          }
          return override;
        });

        // Reset manual override if the row is moved to its original position
        proceeds.forEach((override) => {
          if (rowIdToOrdinal[override.rowId] == override.rowOrdinal) {
            override.isManualOverride = false;
          }
        });

        return proceeds;
      });
    },
    [rowIdToOrdinal]
  );

  return {
    data,
    overrides,
    selectedSectionIds,
    onAddRows,
    onSwapOrdinals,
    onSelectSections: setSelectedSectionIds,
  };
};
