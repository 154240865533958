import { useMemo } from 'react';
import { useSidePanelContext } from 'src/contexts/SidePanelContext';
import { vars } from 'src/core/themes';
import { ResizeEdge } from 'src/core/ui/ResizablePanel/ResizablePanel';
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from 'src/svgs/Viagogo';

import { expandButton } from './SidePanelButton.css';

export function SidePanelButton({
  direction = ResizeEdge.East,
  position = 'absolute',
}: {
  direction?: ResizeEdge;
  position?: 'relative' | 'absolute';
}) {
  const { isHidden, setHidden } = useSidePanelContext();
  const ExpandIcon = useMemo(() => {
    if (direction === ResizeEdge.East) {
      return isHidden ? ChevronRightIcon : ChevronLeftIcon;
    } else if (direction === ResizeEdge.West) {
      return isHidden ? ChevronLeftIcon : ChevronRightIcon;
    } else if (direction === ResizeEdge.South) {
      return isHidden ? ChevronDownIcon : ChevronUpIcon;
    } else {
      return isHidden ? ChevronUpIcon : ChevronDownIcon;
    }
  }, [direction, isHidden]);

  return (
    <div
      id="side-panel-button"
      onClick={() => setHidden(!isHidden)}
      className={expandButton}
      style={
        position === 'relative' ? { position: 'relative', top: 0 } : undefined
      }
    >
      <ExpandIcon
        id="side-panel-button-icon"
        withHoverEffect
        fill={vars.color.textBrandActive}
        size={vars.iconSize.xs}
      />
    </div>
  );
}
