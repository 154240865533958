import styled from 'styled-components/macro';

export const AdCampaignsToolbarDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};
  padding: ${(props) => props.theme.spacing(2, 4, 2, 3)};

  ${({ theme: { media, spacing } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `padding: ${spacing(2, 8, 2, 6)};`
    )}`}

  .add-adCampaigns {
    white-space: nowrap;
    width: fit-content;
  }
`;

export const FilterBarLeftSide = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 8px;

  > div {
    flex-grow: 1;
  }
`;

export const FilterBarRightSide = styled.div`
  display: flex;
  align-items: center;
  padding-inline-start: ${(props) => props.theme.spacing(2)};

  ${({ theme: { media, spacing } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `padding-inline-start: ${spacing(4)};`
    )}`}
`;
