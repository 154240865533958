import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { ListingDeliveryTypeDisplay } from 'src/components/Listings/ListingDeliveryTypeDisplay';
import { AccessorReturnType } from 'src/components/Reports/ReportsExport/ReportsExport.types';
import { useAppContext } from 'src/contexts/AppContext';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_LG,
  COLUMN_DEFAULT_SIZE_XL,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import {
  Content,
  isContentId,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { useColumnUserSetting } from 'src/hooks/useColumnUserSetting';
import { useMapDeliveryTypesToString } from 'src/hooks/useMapDeliveryTypesToString';
import { useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import {
  FormatOption,
  type FormatOptionEntries,
} from 'src/modals/EditTableColumns';
import { CustomColumnName } from 'src/modals/InventoryTableColumns/CustomColumnName';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';
import { PaymentSize } from 'src/svgs/Viagogo';
import { LinkCell } from 'src/tables/ReportTableCommon/LinkCell';
import { SHIMMERING_DIV_HEIGHT_LISTING_REPORT } from 'src/tables/ReportTableCommon/ReportTableCommon.constants';
import { TableHeader } from 'src/tables/ReportTableCommon/TableHeader';
import { TextCell } from 'src/tables/ReportTableCommon/TextCell';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { DateCell, InHandCell } from 'src/tables/Table/TableCells';
import { getColumnPersonalization } from 'src/utils/columns/columnPersonalizationUtils';
import { ListingReportTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { evaluateFormula } from 'src/utils/columns/inventory/inventoryReportCustomColumnUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { ContentIds } from 'src/utils/constants/contentIdDataMap';
import {
  MARKETPLACE_SALE_PAYMENT_STATE_TO_CID,
  PAYMENT_METHOD_TYPE_TO_CID,
  PURCHASE_PAYMENT_STATUS_TO_CID,
  SALE_PAYMENT_STATE_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { getListingDetailsRelativeUrl } from 'src/utils/inventoryUtils';
import {
  applyNumberFormatting,
  formatPercent,
} from 'src/utils/numberFormatter';
import { getPaymentMethodIcon } from 'src/utils/paymentMethodUtils';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { ListingReportMetricsWithGroupBy } from 'src/utils/reportsUtils';
import { getSaleDetailsRelativeUrl } from 'src/utils/saleUtils';
import { sortMoney } from 'src/utils/tableUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  DeliveryType,
  MarketplacePaymentState,
  PointOfSalePaymentState,
  PurchasePaymentMethodType,
  PurchasePaymentStatus,
  TagsValueType,
  UserSetting,
} from 'src/WebApiController';

import { CustomNumericCell } from './CustomNumericCell';
import { CustomNumericFooterCell } from './CustomNumericFooterCell';

const SECTION_TYPE = SectionType.ListingsReport;

export const tagColumnDef = (
  tagName: string
): ColumnDef<ListingReportMetricsWithGroupBy | null> => ({
  id: tagName,
  header: () => (
    <TableHeaderCellDiv align="right">
      <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
        {tagName}
      </span>
    </TableHeaderCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_BASE,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    const tag = data?.tags.find((t) => t.key === tagName);
    return tag ? parseFloat(tag.value) : null;
  },
  cell: function Cell({ getValue, row: { original } }) {
    const { userDefinedPrecision } = useColumnUserSetting(
      tagName,
      SECTION_TYPE
    );

    const tag = original?.tags.find((t) => t.key === tagName);

    return (
      <CustomNumericCell
        columnId={tagName}
        userDefinedPrecision={userDefinedPrecision}
        value={getValue<number | null>()}
        isLoading={!original}
        shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_LISTING_REPORT}
        sectionType={SECTION_TYPE}
        matchingTag={tag}
        currencyCode={original?.currency}
      />
    );
  },
});

export const eventTagColumnDef = (
  tagName: string
): ColumnDef<ListingReportMetricsWithGroupBy | null> => ({
  id: tagName,
  header: () => (
    <TableHeaderCellDiv align="right">
      <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
        {tagName}
      </span>
    </TableHeaderCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_BASE,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    const tag = data?.eventTags.find((t) => t.key === tagName);
    return tag?.value;
  },
  cell: function Cell({ getValue, row: { original } }) {
    const { value: storedInventoryColumnNumberPrecisions = {} } =
      useServerUserSetting<FormatOptionEntries>({
        id: UserSetting.InventoryColumnNumberPrecision,
      });
    const tag = original?.tags.find((t) => t.key === tagName);

    const userDefinedPrecision = storedInventoryColumnNumberPrecisions[tagName];

    if (!original) {
      return (
        <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
      );
    }

    if (
      tag?.valueType === TagsValueType.Decimal ||
      tag?.valueType === TagsValueType.Int
    ) {
      return (
        <CustomNumericCell
          columnId={tagName}
          userDefinedPrecision={userDefinedPrecision}
          value={getValue<number | null>()}
          isLoading={!original}
          shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_LISTING_REPORT}
          sectionType={SECTION_TYPE}
          matchingTag={tag}
          currencyCode={original?.currency}
        />
      );
    }

    return (
      <TableCellDiv align="right">
        <div>{getValue<string | undefined>()}</div>
      </TableCellDiv>
    );
  },
});

export const customColumnDef = (
  columnId: string,
  formula?: string | null
): ColumnDef<ListingReportMetricsWithGroupBy | null> => ({
  id: columnId,
  header: () => (
    <TableHeaderCellDiv align="right">
      <CustomColumnName id={columnId} sectionType={SECTION_TYPE} />
    </TableHeaderCellDiv>
  ),
  size: COLUMN_DEFAULT_SIZE_BASE,
  minSize: COLUMN_MIN_SIZE,
  maxSize: COLUMN_MAX_SIZE,
  accessorFn: (data) => {
    return formula && data ? evaluateFormula(formula, data) ?? null : null;
  },
  cell: function Cell({ getValue, row: { original } }) {
    const { value: customColumns = [] } = useServerUserSetting<
      CustomListingColumn[]
    >({
      id: UserSetting.InventoryCustomColumns,
    });

    const { userDefinedPrecision } = useColumnUserSetting(
      columnId,
      SECTION_TYPE
    );

    return (
      <CustomNumericCell
        columnId={columnId}
        userDefinedPrecision={userDefinedPrecision}
        value={getValue<number | null>()}
        isLoading={!original}
        shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_LISTING_REPORT}
        sectionType={SECTION_TYPE}
        matchingCustomColumn={customColumns.find((c) => c.id === columnId)}
        currencyCode={original?.currency}
      />
    );
  },

  footer: function Cell({ table }) {
    const { value: customColumns = [] } = useServerUserSetting<
      CustomListingColumn[]
    >({
      id: UserSetting.InventoryCustomColumns,
    });

    return (
      <CustomNumericFooterCell
        table={table}
        columnId={columnId}
        shimmeringDivHeight={SHIMMERING_DIV_HEIGHT_LISTING_REPORT}
        matchingCustomColumn={customColumns.find((c) => c.id === columnId)}
        sectionType={SECTION_TYPE}
      />
    );
  },
});

const columnHelper =
  createColumnHelper<ListingReportMetricsWithGroupBy | null>();

export const LISTING_REPORT_TABLE_COLUMNS_CONFIG = {
  [ListingReportTableColumnId.Performer]: columnHelper.accessor(
    (data) => data?.performer?.name ?? '',
    {
      id: ListingReportTableColumnId.Performer,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.Venue]: columnHelper.accessor(
    (data) => data?.venue?.name ?? '',
    {
      id: ListingReportTableColumnId.Venue,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.Event]: columnHelper.accessor(
    (data) => data?.event?.name ?? '',
    {
      id: ListingReportTableColumnId.Event,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.EventGenre]: columnHelper.accessor(
    (data) => data?.genre ?? data?.event?.genre ?? '',
    {
      id: ListingReportTableColumnId.EventGenre,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.EventCountry]: columnHelper.accessor(
    (data) => data?.country?.name ?? data?.venue?.country?.name ?? '',
    {
      id: ListingReportTableColumnId.EventCountry,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.EventCity]: columnHelper.accessor(
    (data) => data?.city ?? data?.venue?.city ?? '',
    {
      id: ListingReportTableColumnId.EventCity,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.SeatingSection]: columnHelper.accessor(
    (data) => data?.section ?? '',
    {
      id: ListingReportTableColumnId.SeatingSection,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.SeatingRow]: columnHelper.accessor(
    (data) => data?.row ?? '',
    {
      id: ListingReportTableColumnId.SeatingRow,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.UnsoldQuantity]: columnHelper.accessor(
    (data) => data?.availQty ?? 0,
    {
      id: ListingReportTableColumnId.UnsoldQuantity,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <TableCellDiv align="right">
            <div>
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: getValue(),
              })}
            </div>
          </TableCellDiv>
        );
      },
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }
        const number = accessorFn?.(original, -1);

        return (
          <TableCellDiv align="right">
            <div>
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: number,
              })}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.TicketCount]: columnHelper.accessor(
    (data) => data?.tktQty ?? 0,
    {
      id: ListingReportTableColumnId.TicketCount,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <TableCellDiv align="right">
            <div>
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: getValue(),
              })}
            </div>
          </TableCellDiv>
        );
      },
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }
        const number = accessorFn?.(original, -1);

        return (
          <TableCellDiv align="right">
            <div>
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: number,
              })}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.SoldQuantity]: columnHelper.accessor(
    (data) => data?.soldQty ?? 0,
    {
      id: ListingReportTableColumnId.SoldQuantity,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <TableCellDiv align="right">
            <div>
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: getValue(),
              })}
            </div>
          </TableCellDiv>
        );
      },
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }
        const number = accessorFn?.(original, -1);

        return (
          <TableCellDiv align="right">
            <div>
              {applyNumberFormatting({
                precision: userDefinedPrecision,
                inputNumber: number,
              })}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  // TODO: Change to total cost
  [ListingReportTableColumnId.UnitCost]: columnHelper.accessor(
    (data) => data?.ttlCst,
    {
      id: ListingReportTableColumnId.UnitCost,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = getValue();

        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<ListingReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.SoldCost]: columnHelper.accessor(
    (data) => data?.soldCst,
    {
      id: ListingReportTableColumnId.SoldCost,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue();
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<ListingReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.UnsoldCost]: columnHelper.accessor(
    (data) => data?.unsoldCst,
    {
      id: ListingReportTableColumnId.UnsoldCost,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue();
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<ListingReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.SoldProceeds]: columnHelper.accessor(
    (data) => data?.soldProcs,
    {
      id: ListingReportTableColumnId.SoldProceeds,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue();
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<ListingReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.ListPrice]: columnHelper.accessor(
    (data) => data?.ttlListPrc,
    {
      id: ListingReportTableColumnId.ListPrice,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue();
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.AllInPrice]: columnHelper.accessor(
    (data) => data?.ttlWebPrc,
    {
      id: ListingReportTableColumnId.AllInPrice,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue();
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.PandL]: columnHelper.accessor(
    (data) => data?.pandL,
    {
      id: ListingReportTableColumnId.PandL,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue();
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
      sortingFn: sortMoney<ListingReportMetricsWithGroupBy>,
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);
        if (!value) return <Content id={ContentId.NA} />;

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value.amt,
                    currencyCode: value.currency,
                    currencyDecimalDigits: value.dec,
                  })
                : value.disp ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.Margin]: columnHelper.accessor(
    (data) => data?.marg,
    {
      id: ListingReportTableColumnId.Margin,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue();
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value,
                    isPercent: true,
                  })
                : value ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
      footer: function Cell({ table, column: { id: columnId, accessorFn } }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );

        const original = table.options.meta?.summaryData;

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = accessorFn?.(original, -1);

        return (
          <TableCellDiv align="right">
            <div>
              {userDefinedPrecision
                ? applyNumberFormatting({
                    precision: userDefinedPrecision,
                    inputNumber: value,
                    isPercent: true,
                  })
                : value ?? <Content id={ContentId.NA} />}
            </div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.EventId]: columnHelper.accessor(
    (data) => data?.event?.viagId ?? '',
    {
      id: ListingReportTableColumnId.EventId,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => {
        const eventId = params.row.original?.eventId;
        if (!eventId) {
          return (
            <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
          );
        }
        const eventUrl = createInventoryEventUrl(eventId);
        return (
          <LinkCell
            text={String(params.getValue())}
            url={eventUrl}
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.Category]: columnHelper.accessor(
    (data) => data?.performer?.descr ?? '',
    {
      id: ListingReportTableColumnId.Category,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.EventDate]: columnHelper.accessor(
    (data) => data?.event?.dates?.start ?? '',
    {
      id: ListingReportTableColumnId.EventDate,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const value = getValue<string>();
        const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
          useColumnUserSetting(columnId, SECTION_TYPE);

        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={value}
            align="left"
            enableUtcFallback
            hideRelativeTerms
            useSiteTimeZone={false}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.EventStateProvince]: columnHelper.accessor(
    (data) => data?.venue?.state ?? '',
    {
      id: ListingReportTableColumnId.EventStateProvince,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.SaleId]: columnHelper.accessor(
    (data) => data?.sales?.map((s) => s.saleId) ?? null,
    {
      id: ListingReportTableColumnId.SaleId,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const saleIds = getValue<number[]>();

        if (!saleIds) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <LinkCell
            text={saleIds.map((saleId) => `#${saleId}`)}
            url={saleIds.map((saleId) => getSaleDetailsRelativeUrl(saleId))}
            separator={<span>{', '}</span>}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.HoldId]: columnHelper.accessor(
    (data) => data?.holds?.map((s) => s.saleId) ?? null,
    {
      id: ListingReportTableColumnId.HoldId,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const holdIds = getValue<number[]>();

        if (!holdIds) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <LinkCell
            text={holdIds.map((holdId) => `#${holdId}`)}
            url={holdIds.map((holdId) => getSaleDetailsRelativeUrl(holdId))}
            separator={<span>{', '}</span>}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.SeatingSeats]: columnHelper.accessor(
    (data) => {
      if (!data?.seatFr) return null;
      let text = data.seatFr;
      if (data.seatTo && data.seatTo !== data.seatFr) {
        text += ` - ${data.seatTo}`;
      }
      return text;
    },
    {
      id: ListingReportTableColumnId.SeatingSeats,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.DeliveryType]: columnHelper.accessor(
    (data) => data?.delivType ?? null,
    {
      id: ListingReportTableColumnId.DeliveryType,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );
        const value = getValue<DeliveryType | null>();

        const strDeliveryTypes = useMapDeliveryTypesToString({
          deliveryTypesObj: { delivType: value },
        });

        if (!original || !value) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <TableCellDiv align="left" title={strDeliveryTypes}>
            <ListingDeliveryTypeDisplay
              listing={{ delivType: value }}
              userDefinedPrecision={userDefinedPrecision}
              showIconTitle={false}
            />
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.PurchasePaymentStatus]: columnHelper.accessor(
    (data) => data?.poInfos?.pmtStatuses ?? null,
    {
      id: ListingReportTableColumnId.PurchasePaymentStatus,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue }) {
        const values = getValue<PurchasePaymentStatus[] | null>();

        const { contentResolver } = useContentContext();

        if (values == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const contentIds = values.map((v) => PURCHASE_PAYMENT_STATUS_TO_CID[v]);
        const contentText = contentIds
          .map((cid) => {
            if (isContentId(cid)) {
              return contentResolver(ContentIds[cid as ContentId]);
            } else {
              return cid;
            }
          })
          .join(', ');

        return (
          <TableCellDiv align="left" title={contentText}>
            {contentText}
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.PaymentMethodType]: columnHelper.accessor(
    (data) => data?.poInfos?.pmtMethods ?? null,
    {
      id: ListingReportTableColumnId.PaymentMethodType,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { userDefinedPrecision } = useColumnUserSetting(
          columnId,
          SECTION_TYPE
        );
        const { contentResolver } = useContentContext();

        const value = getValue<PurchasePaymentMethodType[] | null>();
        if (!original || !value) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        if (userDefinedPrecision === FormatOption.EnumDisplay_Text) {
          const contentIds = value.map((v) => PAYMENT_METHOD_TYPE_TO_CID[v]);
          const contentText = contentIds
            .map((cid) => {
              if (isContentId(cid)) {
                return contentResolver(ContentIds[cid as ContentId]);
              } else {
                return cid;
              }
            })
            .join(', ');

          return (
            <TableCellDiv align="left" title={contentText}>
              {contentText}
            </TableCellDiv>
          );
        }

        return (
          <TableCellDiv
            align="left"
            title={value?.length === 1 ? value[0] : undefined}
          >
            {value.map((v, i) => {
              const PurchaseMethodIcon = getPaymentMethodIcon(v);
              return (
                <PurchaseMethodIcon
                  size={PaymentSize.s}
                  key={`paymenticon-${i}`}
                  isSquare={false}
                />
              );
            })}
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.InHandDate]: columnHelper.accessor(
    (data) => data?.inHandDate ?? '',
    {
      id: ListingReportTableColumnId.InHandDate,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue<string | null>();
        if (!original) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }
        return (
          <TableCellDiv align="left">
            <InHandCell date={value ? new Date(value) : null} />
          </TableCellDiv>
        );
      },
      sortingFn: 'datetime',
    }
  ),
  [ListingReportTableColumnId.PurchaseId]: columnHelper.accessor(
    (data) => data?.poInfos?.poIds ?? null,
    {
      id: ListingReportTableColumnId.PurchaseId,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const purchaseIds = getValue<number[] | null>();

        if (!purchaseIds) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <LinkCell
            text={purchaseIds.map((purchaseId) => `#${purchaseId}`)}
            url={purchaseIds.map((purchaseId) =>
              getPurchaseOrderRelativeUrl(purchaseId)
            )}
            separator={<span>{', '}</span>}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.PurchasedBy]: columnHelper.accessor(
    (data) => ({
      stringValueType: AccessorReturnType.PURCHASE_BUYER_INFOS,
      value: data?.poInfos?.buyers,
    }),
    {
      id: ListingReportTableColumnId.PurchasedBy,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue }) {
        const buyerInfos = getValue().value;

        const useGetUserInfosQuery = useGetUserInfos(
          buyerInfos?.map((u) => u.buyerUserId)
        );
        const deactivatedText = useContent(ContentId.Deactivated);

        const buyerUserInfos = useMemo(() => {
          if (buyerInfos == null) return [];

          return buyerInfos.map((bi) => {
            const buyerUser = useGetUserInfosQuery.data?.[bi.buyerUserId];
            return {
              buyerUserId: bi.buyerUserId,
              buyerUser,
              averageBuyerCommissionPercentage:
                bi.averageBuyerCommissionPercent,
            };
          });
        }, [buyerInfos, useGetUserInfosQuery.data]);

        if (buyerInfos == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <TableCellDiv align="left">
            {buyerUserInfos
              .map(
                (bui) =>
                  `${
                    bui.buyerUser?.name ??
                    bui.buyerUser?.email ??
                    bui.buyerUserId
                  } ${
                    bui.buyerUser?.isDeactivated ? `(${deactivatedText})` : ''
                  } ${
                    bui.averageBuyerCommissionPercentage
                      ? `(${formatPercent(
                          bui.averageBuyerCommissionPercentage,
                          2
                        )})`
                      : ''
                  }`
              )
              .join(', ')}
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.PurchaseDate]: columnHelper.accessor(
    (data) => data?.poInfos?.poDates ?? null,
    {
      id: ListingReportTableColumnId.PurchaseDate,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_XL,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        column: { id: columnId },
        row: { original },
      }) {
        const purchaseDates = getValue<string[] | null>();
        const { userDefinedPrecision = FormatOption.DateTime_MonthDD_Time } =
          useColumnUserSetting(columnId, SECTION_TYPE);

        if (original == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <DateCell
            precision={userDefinedPrecision}
            date={purchaseDates}
            enableUtcFallback
            hideRelativeTerms
            align="left"
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.CancellationDatePurchase]: columnHelper.accessor(
    (data) => data?.poInfos?.cancDates ?? null,
    {
      id: ListingReportTableColumnId.CancellationDatePurchase,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const purchaseDates = getValue<string[] | null>();

        if (!purchaseDates) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <DateCell
            date={purchaseDates}
            enableUtcFallback
            hideRelativeTerms
            align="left"
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.Vendor]: columnHelper.accessor(
    (data) => {
      if (!data) return null;
      const { poInfos: purchaseInfos } = data;
      if (!purchaseInfos?.vendors.length) return null;

      return purchaseInfos.vendors.map((v) => v.vendorName).join(', ');
    },
    {
      id: ListingReportTableColumnId.Vendor,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.InternalNotesPurchase]: columnHelper.accessor(
    (data) => {
      if (!data) return null;
      const { poInfos: purchaseInfos } = data;
      if (!purchaseInfos?.privNotes.length) return null;

      return purchaseInfos.privNotes.join(', ');
    },
    {
      id: ListingReportTableColumnId.InternalNotesPurchase,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.VendorOrderId]: columnHelper.accessor(
    (data) => {
      if (!data) return null;
      const { poInfos: purchaseInfos } = data;
      if (!purchaseInfos?.vendOrdIds.length) return null;

      return purchaseInfos.vendOrdIds.join(', ');
    },
    {
      id: ListingReportTableColumnId.VendorOrderId,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),

  [ListingReportTableColumnId.VendorAccountEmail]: columnHelper.accessor(
    (data) => {
      if (!data) return null;
      const { poInfos: purchaseInfos } = data;
      if (!purchaseInfos?.vendorAccs.length) return null;

      return purchaseInfos.vendorAccs
        .map((v) => v.encryptedEmailAddress)
        .join(', ');
    },
    {
      id: ListingReportTableColumnId.VendorAccountEmail,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.SalePaymentState]: columnHelper.accessor(
    (data) =>
      data?.sales
        ?.map((s) => ({
          marketplacePaymentState: s.mkpPmtState,
          paymentState: s.pmtState,
        }))
        ?.filter(
          (s) => s.marketplacePaymentState != null || s.paymentState != null
        ) ?? null,
    {
      id: ListingReportTableColumnId.SalePaymentState,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue }) {
        const values = getValue<
          {
            marketplacePaymentState: MarketplacePaymentState;
            paymentState: PointOfSalePaymentState;
          }[]
        >();
        if (values == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const pmtStates = values.map((s) =>
          s.marketplacePaymentState != null
            ? MARKETPLACE_SALE_PAYMENT_STATE_TO_CID[s.marketplacePaymentState]
            : SALE_PAYMENT_STATE_TO_CID[s.paymentState!]
        );

        const valuesDeduped = Array.from(new Set(pmtStates));

        return (
          <TableCellDiv align="left">
            {valuesDeduped.flatMap((contentId, index) => {
              if (index < valuesDeduped.length - 1) {
                return [
                  <Content key={index} id={contentId} />,
                  <span key={`separator-${index}`}>{', '}</span>,
                ];
              }

              return [<Content key={index} id={contentId} />];
            })}
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.Charges]: columnHelper.accessor(
    (data) =>
      data?.sales?.map((s) => s.chargs?.amt).filter((c) => c != null) ?? null,
    {
      id: ListingReportTableColumnId.Charges,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { loginContext } = useAppContext();
        const { getUiCurrency } = useLocalizationContext();
        const uiCurrency = getUiCurrency(
          original?.currency ?? loginContext?.user?.activeAccount.currencyCode
        );

        const { isCurrency, isPercent } = getColumnPersonalization({
          id: columnId,
          sectionType: SECTION_TYPE,
        });

        if (original == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = getValue<number[] | null>();

        const formatted =
          value
            ?.map((v) =>
              applyNumberFormatting({
                precision: FormatOption.Precise,
                inputNumber: v,
                currencyCode: uiCurrency.code,
                currencyDecimalDigits: uiCurrency.dec,
                isPercent: isPercent && !isCurrency,
              })
            )
            ?.join(', ') ?? '';

        return (
          <TableCellDiv align="right">
            <div>{formatted}</div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.Credits]: columnHelper.accessor(
    (data) =>
      data?.sales?.map((s) => s.creds?.amt).filter((c) => c != null) ?? null,
    {
      id: ListingReportTableColumnId.Credits,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({
        getValue,
        row: { original },
        column: { id: columnId },
      }) {
        const { loginContext } = useAppContext();
        const { getUiCurrency } = useLocalizationContext();
        const uiCurrency = getUiCurrency(
          original?.currency ?? loginContext?.user?.activeAccount.currencyCode
        );

        const { isCurrency, isPercent } = getColumnPersonalization({
          id: columnId,
          sectionType: SECTION_TYPE,
        });

        if (original == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const value = getValue<number[] | null>();

        const formatted =
          value
            ?.map((v) =>
              applyNumberFormatting({
                precision: FormatOption.Precise,
                inputNumber: v,
                currencyCode: uiCurrency.code,
                currencyDecimalDigits: uiCurrency.dec,
                isPercent: isPercent && !isCurrency,
              })
            )
            ?.join(', ') ?? '';

        return (
          <TableCellDiv align="right">
            <div>{formatted}</div>
          </TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.Fulfiller]: columnHelper.accessor(
    (data) => ({
      stringValueType: AccessorReturnType.SELLER_USER_ID,
      value:
        (data?.sales
          ?.map((s) => s.fulfillerId)
          .filter((c) => c != null) as string[]) ?? null,
    }),
    {
      id: ListingReportTableColumnId.Fulfiller,
      header: (params) => (
        <TableHeader {...params} align={'left'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { allActiveUserInfos } = useSellerAccountContext();

        const sellerUserIds = getValue().value;

        const fulfillerFormatted = useMemo(
          () =>
            sellerUserIds
              ? [...new Set(sellerUserIds)]
                  .map(
                    (fulfillerId) =>
                      allActiveUserInfos?.[fulfillerId]?.displayName
                  )
                  .filter((name) => name != null)
                  .join(', ')
              : null,
          [allActiveUserInfos, sellerUserIds]
        );

        if (original == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return <TableCellDiv align="left">{fulfillerFormatted}</TableCellDiv>;
      },
    }
  ),
  [ListingReportTableColumnId.ListingId]: columnHelper.accessor(
    (data) => data?.listId?.toString() ?? '',
    {
      id: ListingReportTableColumnId.ListingId,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: ({ getValue }) => {
        const listingId = getValue<string>();

        if (!listingId) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        const url = getListingDetailsRelativeUrl(listingId!);
        return (
          <LinkCell
            text={`#${listingId}`}
            url={url}
            openInNewTab
            align="left"
            style={{ fontWeight: 600 }}
          />
        );
      },
    }
  ),
  [ListingReportTableColumnId.Zone]: columnHelper.accessor(
    (data) => data?.zone ?? '',
    {
      id: ListingReportTableColumnId.Zone,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
  [ListingReportTableColumnId.PricedBy]: columnHelper.accessor(
    (data) => ({
      stringValueType: AccessorReturnType.SELLER_USER_ID,
      value: data?.pricerId ?? null,
    }),
    {
      id: ListingReportTableColumnId.PricedBy,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { allActiveUserInfos } = useSellerAccountContext();

        const pricerSellerUserId = getValue().value;

        const pricer = useMemo(
          () =>
            pricerSellerUserId
              ? allActiveUserInfos?.[pricerSellerUserId]?.displayName
              : null,
          [allActiveUserInfos, pricerSellerUserId]
        );

        if (original == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return <TableCellDiv align="left">{pricer}</TableCellDiv>;
      },
    }
  ),
  [ListingReportTableColumnId.PurchaseCreatedBy]: columnHelper.accessor(
    (data) => ({
      stringValueType: AccessorReturnType.SELLER_USER_ID,
      value: data?.poInfos?.createdBys,
    }),
    {
      id: ListingReportTableColumnId.PurchaseCreatedBy,
      header: (params) => (
        <TableHeader {...params} sectionType={SECTION_TYPE} align="left" />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue, row: { original } }) {
        const { allActiveUserInfos } = useSellerAccountContext();

        const sellerUserIds = getValue().value;

        const displayNamesFormatted = useMemo(
          () =>
            sellerUserIds
              ? [...new Set(sellerUserIds)]
                  .map(
                    (sellerUserId) =>
                      allActiveUserInfos?.[sellerUserId]?.displayName
                  )
                  .filter((name) => name != null)
                  .join(', ')
              : null,
          [allActiveUserInfos, sellerUserIds]
        );

        if (original == null) {
          return (
            <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_LISTING_REPORT} />
          );
        }

        return (
          <TableCellDiv align="left">{displayNamesFormatted}</TableCellDiv>
        );
      },
    }
  ),
  [ListingReportTableColumnId.InternalNotes]: columnHelper.accessor(
    (data) => data?.privNotes ?? '',
    {
      id: ListingReportTableColumnId.InternalNotes,
      header: (params) => (
        <TableHeader {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
      size: COLUMN_DEFAULT_SIZE_LG,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: (params) => (
        <TextCell {...params} align={'right'} sectionType={SECTION_TYPE} />
      ),
    }
  ),
} as const;
