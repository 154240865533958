import { useSyncCenterSettings } from 'src/hooks/api/useSyncCenterSetting';
import { PurchaseInfoSection } from 'src/modals/common/PurchaseInfoSection';
import { ListingDetails } from 'src/WebApiController';

type ListingDetailsPurchaseFormProps = {
  listing?: ListingDetails | null;
};

export const ListingDetailsPurchaseForm: React.FC<
  ListingDetailsPurchaseFormProps
> = ({ listing }) => {
  const settings = useSyncCenterSettings();
  const isAutoPoQualityControlled =
    settings.data?.isAutoPoQualityControlled ?? false;

  return (
    <PurchaseInfoSection
      isSeatSaver={listing?.isSeatSaver}
      qualityControlState={
        isAutoPoQualityControlled ? listing?.qcState : undefined
      }
      purchaseInfos={listing!.purchaseInfos}
      insertBy={listing?.insertBy}
    />
  );
};
