import {
  PosMultiSelect,
  PosMultiSelectProps,
} from 'src/core/POS/PosMultiSelect';
import { useUserGroups } from 'src/hooks/useUserGroups';

export const UserGroupMultiSelector = ({
  ...props
}: Omit<PosMultiSelectProps, 'valueOptionsContent'>) => {
  const { userGroups, isLoading } = useUserGroups();

  const userGroupsContentSelect = (userGroups ?? []).reduce<
    Record<string, string>
  >((acc, userGroup) => {
    acc[userGroup.userGroupId.toString()] = userGroup.userGroupName;
    return acc;
  }, {});

  return (
    <PosMultiSelect
      {...props}
      loading={isLoading}
      valueOptionsContent={userGroupsContentSelect}
    />
  );
};
