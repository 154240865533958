import { createColumnHelper } from '@tanstack/react-table';
import {
  COLUMN_DEFAULT_SIZE_BASE,
  COLUMN_DEFAULT_SIZE_SM,
  COLUMN_DEFAULT_SIZE_XS,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content, useContent } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { AccountType } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/AccountStatus/AccountType';
import { VendorActionsCell } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsTable/VendorActionsCell';
import { VendorsTableColumnId } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsTable/vendorsColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseVendor } from 'src/WebApiController';

export type PurchaseVendorActions = {
  addNewVendorAccount: (vendor: PurchaseVendor) => void;
  viewVendorDetails: (vendor: PurchaseVendor) => void;
  editVendor: (vendor: PurchaseVendor) => void;
};

export type PurchaseVendorsWithActions = {
  vendor: PurchaseVendor;
  vendorActions: PurchaseVendorActions;
};

const columnHelper = createColumnHelper<PurchaseVendorsWithActions | null>();
export const VENDORS_TABLE_COLUMNS_CONFIG = {
  [VendorsTableColumnId.Vendor]: columnHelper.accessor(
    (data) => data?.vendor?.name,
    {
      id: VendorsTableColumnId.Vendor,
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Vendor} />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_BASE,
      minSize: COLUMN_MIN_SIZE,
      maxSize: COLUMN_MAX_SIZE,
      cell: function Cell({ getValue }) {
        return <TableCellDiv align="left">{getValue()}</TableCellDiv>;
      },
    }
  ),
  [VendorsTableColumnId.TotalAccounts]: columnHelper.accessor(
    (data) => data?.vendor?.vendAccCounts ?? 0,
    {
      id: VendorsTableColumnId.TotalAccounts,
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Accounts} />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_XS,
      minSize: COLUMN_DEFAULT_SIZE_XS,
      maxSize: COLUMN_MIN_SIZE,
      cell: function Cell({ getValue }) {
        return <TableCellDiv align="right">{getValue()}</TableCellDiv>;
      },
    }
  ),
  [VendorsTableColumnId.AccountType]: columnHelper.accessor(
    (data) => data?.vendor?.isGlobal ?? false,
    {
      id: VendorsTableColumnId.AccountType,
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Type} />
        </TableHeaderCellDiv>
      ),
      size: COLUMN_DEFAULT_SIZE_SM,
      minSize: COLUMN_DEFAULT_SIZE_SM,
      maxSize: COLUMN_MIN_SIZE,
      cell: function Cell({ getValue }) {
        const systemText = useContent(ContentId.System);
        const customText = useContent(ContentId.Custom);
        return (
          <TableCellDiv
            align="left"
            title={getValue() ? systemText : customText}
          >
            <AccountType type={getValue() ? 'system' : 'custom'} />
          </TableCellDiv>
        );
      },
    }
  ),
  [VendorsTableColumnId.Actions]: columnHelper.accessor(() => null, {
    id: VendorsTableColumnId.Actions,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.Actions} />
      </TableHeaderCellDiv>
    ),
    size: COLUMN_DEFAULT_SIZE_SM,
    minSize: COLUMN_DEFAULT_SIZE_XS,
    maxSize: COLUMN_DEFAULT_SIZE_SM,
    enableSorting: false,
    enableResizing: false,
    cell: function Cell({ row: { original } }) {
      return (
        <TableCellDiv align="right" showTooltip={false}>
          <VendorActionsCell vendorWithActions={original} />
        </TableCellDiv>
      );
    },
  }),
};
