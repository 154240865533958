import { ComponentProps, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { Button, Radio, RadioGroup, Stack, TextArea } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './InternationalComplianceDialog.css';

type WorksFor = 'viagogo' | 'eventOrganizer';

/**
 * Do NOT change this object without consulting with the backend. This type is stored in the db as
 * a user setting.
 */
export type InternationalComplianceSetting = {
  /**
   * Timestamp of when user last submitted with "Use this answer for the next 24 hours".
   */
  doNotAskAgainTimestamp: Date | null;
  ticketRestrictions: string | null;
  worksFor: WorksFor | null;
  isSpeculativeListing: boolean | null;
};

type InternationalComplianceDialogProps = Omit<
  ComponentProps<typeof RSModal>,
  'onSubmit'
> & {
  onSubmit: (result: InternationalComplianceSetting) => void;
};

export function InternationalComplianceDialog({
  onSubmit,
  ...dialogProps
}: InternationalComplianceDialogProps) {
  const [reuseAnswer, setSaveAnswer] = useState(false);
  const [restrictions, setRestrictions] = useState<string | null>(null);
  const [worksFor, setWorksFor] = useState<WorksFor | null>(null);
  const [isSpeculativeListing, setIsSpeculativeListing] = useState<
    boolean | null
  >(null);
  const [isSecondStep, setIsSecondStep] = useState(false);
  return (
    <GenericDialog
      size="m"
      header={<Content id={ContentId.FinalQuestionsOnYourTickets} />}
      footer={
        !isSecondStep ? (
          <>
            <Button
              variant="outline"
              onClick={() => {
                setIsSecondStep(true);
              }}
            >
              <Content id={ContentId.YesToSome} />
            </Button>
            <Button
              onClick={() => {
                onSubmit({
                  doNotAskAgainTimestamp: reuseAnswer ? new Date() : null,
                  ticketRestrictions: null,
                  worksFor: null,
                  isSpeculativeListing: null,
                });
              }}
            >
              <Content id={ContentId.NoToAll} />
            </Button>
          </>
        ) : (
          <Button
            onClick={() => {
              onSubmit({
                doNotAskAgainTimestamp: reuseAnswer ? new Date() : null,
                ticketRestrictions: restrictions,
                worksFor,
                isSpeculativeListing,
              });
            }}
          >
            <Content id={ContentId.Finish} />
          </Button>
        )
      }
      includeCloseButton={false}
      {...dialogProps}
    >
      <Stack direction="column" gap="xl">
        <div>
          <div className={styles.question}>
            <Content id={ContentId.DoTheseTicketsHaveAnyRestrictions} />
          </div>
          {isSecondStep && (
            <TextArea.Root>
              <TextArea.Area
                onChange={(e) => {
                  setRestrictions(e.target.value);
                }}
              ></TextArea.Area>
            </TextArea.Root>
          )}
        </div>

        <div>
          <div className={styles.question}>
            <Content id={ContentId.DoYouWorkForEventOrganizerOrViagogo} />
          </div>
          {isSecondStep && (
            <RadioGroup
              onValueChange={(value) => {
                setWorksFor(value as WorksFor);
              }}
              value={worksFor ?? undefined}
            >
              <Stack direction="column" gap="m">
                <Radio
                  value="viagogo"
                  label={<Content id={ContentId.IWorkForViagogo} />}
                />
                <Radio
                  value="eventOrganizer"
                  label={<Content id={ContentId.IWorkForEventOrganizer} />}
                />
              </Stack>
            </RadioGroup>
          )}
        </div>
        <div>
          <div className={styles.question}>
            {' '}
            <Content id={ContentId.IsThisASpeculativeListing} />
          </div>
          {isSecondStep && (
            <RadioGroup
              onValueChange={(value) => {
                setIsSpeculativeListing(value === 'yes');
              }}
              value={
                isSpeculativeListing === true
                  ? 'yes'
                  : isSpeculativeListing === false
                  ? 'no'
                  : undefined
              }
            >
              <Stack direction="column" gap="m">
                <Radio value="yes" label={<Content id={ContentId.Yes} />} />
                <Radio value="no" label={<Content id={ContentId.No} />} />
              </Stack>
            </RadioGroup>
          )}
        </div>
        <Checkbox
          checked={reuseAnswer}
          labelPosition="right"
          label={<Content id={ContentId.UseThisAnswerForNext24Hours} />}
          onChange={(e) => {
            setSaveAnswer(e.target.checked);
          }}
        />
      </Stack>
    </GenericDialog>
  );
}
