import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { EditIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseVendor, PurchaseVendorAccount } from 'src/WebApiController';

import * as modalStyles from '../PurchaseModal.css';
import { BodySectionTitle } from '../PurchaseModal.styled';
import * as styles from './DeliverySection.css';
import {
  AccountSectionEmail,
  AccountSectionLabel,
  VendorSectionLabel,
  VendorSectionName,
} from './DeliverySection.styled';

export type DeliverySectionProps = {
  vendor: PurchaseVendor | null;
  vendorAccount: PurchaseVendorAccount | null;
  onChangeClick?: () => void;
  disabled?: boolean;
};

export const DeliverySection = ({
  vendor,
  vendorAccount,
  onChangeClick,
  disabled,
}: DeliverySectionProps) => {
  return (
    <div className={modalStyles.bodySection}>
      <BodySectionTitle>
        <Content id={ContentId.TicketDelivery} />
      </BodySectionTitle>
      <div className={styles.accountVendorSection}>
        <div className={styles.accountSection}>
          <AccountSectionLabel>
            <Content id={ContentId.Account} />
          </AccountSectionLabel>
          <AccountSectionEmail>{vendorAccount?.email}</AccountSectionEmail>
          {!disabled && (
            <Button variant={'link'} onClick={onChangeClick}>
              <EditIcon fill={IconsFill.currentColor} size={vars.iconSize.s} />
              <Content id={ContentId.Change} />
            </Button>
          )}
        </div>
        <div>
          <VendorSectionLabel>
            <Content id={ContentId.Vendor} />
          </VendorSectionLabel>
          <VendorSectionName>{vendor?.name}</VendorSectionName>
        </div>
      </div>
    </div>
  );
};
