import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function DotIcon({
  fill,
  stroke,
  ...props
}: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper {...props} viewBox="0 0 8 8" fill="none">
      <circle cx="4" cy="4" r="4" fill={fill ?? stroke ?? 'currentColor'} />
    </SvgWrapper>
  );
}
