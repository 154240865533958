import { MetricTile } from 'src/components/Metrics';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { InventorySummaryListingMetricsDisplayStrings } from 'src/utils/ticketMetricUtils';

import * as styles from './InventorySummaryWidgets.css';

export const AverageTicketPriceDisplay = ({
  formattedTotalMetrics,
}: {
  formattedTotalMetrics:
    | InventorySummaryListingMetricsDisplayStrings
    | undefined
    | null;
}) => {
  return (
    <MetricTile.Root className={styles.metric}>
      <MetricTile.Title isCompact>
        <Content id={ContentId.ATP} />
      </MetricTile.Title>
      <div className={styles.metricWithVisualContainer}>
        <MetricTile.Metric showLoadingPlaceholders={!formattedTotalMetrics}>
          {formattedTotalMetrics?.formattedAverageSalePrice}
        </MetricTile.Metric>
        {/* Placeholder div with the same height as the metric visual to center the
            text in the same way */}
        <div style={{ height: '78px' }} />
      </div>
    </MetricTile.Root>
  );
};
