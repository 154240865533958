import { EventAccordionItemProps } from 'src/components/Accordions';
import { EventPageHeaderBase } from 'src/components/Events/EventPage/EventPageHeader';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';
import { formatPercent } from 'src/utils/numberFormatter';
import {
  ActionOutboxEntityType,
  Event,
  SaleMetrics,
} from 'src/WebApiController';

export const SaleEventPageHeader = ({
  event,
  saleAccordionItem,
}: {
  event: Event;
  saleAccordionItem?: EventAccordionItemProps;
}) => {
  const { eventMetrics: eventMetricsMap } =
    useCatalogMetricsContext<SaleMetrics>();

  const eventMetrics = eventMetricsMap?.[event?.viagVirtualId ?? ''];

  const formattedMetrics = [
    {
      name: <Content id={ContentId.Margin} />,
      value: formatPercent(eventMetrics?.marg || 0),
    },
    {
      name: <Content id={ContentId.Filled} />,
      value: `${
        eventMetrics?.qtySold || 0 / (eventMetrics?.saleUnfilled || 0)
      }/${eventMetrics?.qtySold}`,
    },
    {
      name: <Content id={ContentId.Sales} />,
      value: eventMetrics?.qtySold,
    },
  ];
  return (
    <EventPageHeaderBase
      event={event}
      entityType={ActionOutboxEntityType.Sale}
      eventMetrics={formattedMetrics}
      eventAccordionItem={saleAccordionItem}
    />
  );
};
