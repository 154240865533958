import { useEffect, useMemo } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';

export const useAccordionItem = (viagVirtualId: string) => {
  const {
    isLoading,
    isItemsLoading,
    eventsTransformed,
    eventsExpansion: { setListItemExpansion },
  } = useCatalogDataContext();

  useEffect(() => {
    if (viagVirtualId) {
      setListItemExpansion(true, [viagVirtualId]);
    }
  }, [setListItemExpansion, viagVirtualId]);

  const accordionItemProps = useMemo(
    () =>
      eventsTransformed?.length
        ? eventsTransformed.find(
            (ev) => ev.event.viagVirtualId === viagVirtualId
          )
        : undefined,
    [eventsTransformed, viagVirtualId]
  );

  return { isLoading, isItemsLoading, accordionItemProps };
};
