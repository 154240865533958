import { calc } from '@vanilla-extract/css-utils';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';

import * as styles from './CircleProgressBar.css';

/**
 * Glyph widths in px for a font size of 24px.
 */
const GLYPH_WIDTHS = {
  '-': 11.25,
  ',': 5.09,
  '.': 4.97,
  '%': 20.63,
  '1': 11.75,
  '2': 15.13,
  '3': 15.83,
  '4': 16.28,
  '5': 15.47,
  '6': 15.84,
  '7': 13.94,
  '8': 15.88,
  '9': 15.84,
};
/**
 * Arbitrary default width of 13px.
 */
const DEFAULT_GLYPH_WIDTH = 13;

/**
 * The maximum text width assuming the default props.
 */
const MAX_TEXT_WIDTH = 56;

/**
 * @param text
 * @param maxTextWidth
 * @returns The estimated font scaling factor needed to fit within the given `maxTextWidth`.
 */
const calcEstimatedFontScale = (text: string, maxTextWidth: number) => {
  let textWidth = 0;
  for (const c of text) {
    textWidth +=
      GLYPH_WIDTHS[c as keyof typeof GLYPH_WIDTHS] ?? DEFAULT_GLYPH_WIDTH;
  }
  // since we set a letter spacing of `-0.0625em` which comes out to -1px with a font size of 24px,
  // we reduce by this amount between each letter
  const letterSpacingOffset = -(text.length - 1);
  return maxTextWidth / (textWidth + letterSpacingOffset);
};

/**
 * -100%
 */
const MIN_VALUE = -100;
/**
 * 200%
 */
const MAX_VALUE = 200;

export type CircleProgressBarProps = {
  /**
   * Height and width of the bounding box.
   */
  size?: number;
  /**
   * Thickness of the progress bar.
   */
  barWidth?: number;
  /**
   * Progress percentage. This does not affect what text is displayed in the center.
   *
   * eg. `75` for 75%
   */
  value?: number;
  /**
   * Text to render in the center of the circle.
   */
  children?: string;
};

export function CircleProgressBar({
  size = 48,
  barWidth = 6,
  value = 0,
  children,
}: CircleProgressBarProps) {
  const { isDarkMode } = useSiteTheme();

  value = Math.min(Math.max(value, MIN_VALUE), MAX_VALUE);

  const radius = (size - barWidth) / 2;
  const center = radius + barWidth / 2;
  const circumference = 2 * Math.PI * radius;

  const relativePercent = (value / 100) % 1 || Math.sign(value);
  const offset = circumference * (1 - relativePercent);

  const foregroundColor =
    value < 0
      ? !isDarkMode
        ? vars.color.backgroundDisabled
        : vars.color.borderError
      : value <= 100
      ? !isDarkMode
        ? vars.color.backgroundBrandActive
        : vars.color.textBrand
      : !isDarkMode
      ? '#2D0F54'
      : vars.color.textBrand;

  const backgroundColor =
    value < 0
      ? !isDarkMode
        ? 'transparent'
        : vars.color.backgroundPrimaryActive
      : value <= 100
      ? !isDarkMode
        ? vars.color.backgroundBrandHoverLight
        : vars.color.backgroundPrimaryActive
      : vars.color.backgroundBrandActive;

  return (
    <div className={styles.progressBar}>
      <svg
        style={{
          ...assignInlineVars({
            [styles.foregroundColorVar]: foregroundColor,
            [styles.backgroundColorVar]: backgroundColor,
          }),
          display: 'inline-block',
        }}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        transform="rotate(-90)"
      >
        <circle
          r={radius}
          cx={center}
          cy={center}
          fill="transparent"
          stroke={styles.backgroundColorVar}
          strokeWidth={barWidth}
        />
        <circle
          r={radius}
          cx={center}
          cy={center}
          fill="transparent"
          stroke={styles.foregroundColorVar}
          strokeWidth={barWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
      <div
        className={styles.progressText}
        style={{
          ...(children && children.length > 4
            ? {
                fontSize: calc(vars.typography.fontSize['2xl'])
                  .multiply(calcEstimatedFontScale(children, MAX_TEXT_WIDTH))
                  .toString(),
              }
            : {}),
        }}
      >
        {children}
      </div>
    </div>
  );
}
