import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { GET_ACCESSIBLE_VENDORS_QUERY_KEY } from 'src/hooks/api/useGetAccessibleVendorsQuery';

export const useInvalidateGetAccessibleVendorsQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [GET_ACCESSIBLE_VENDORS_QUERY_KEY],
      type: 'active',
    });
  }, [queryClient]);
};
