import { SelectAllHotkeys } from 'src/components/common/MultiSelect/Toggle/contants';
import { MultiSelectionToggle } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggle';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { PosHotkeys } from 'src/core/POS/PosHotkeys';

interface MultiSelectionToggleGroupProps {
  groupId: string;
  onToggle?: (groupId: string, isChecked: boolean) => void;
  enableHotkey?: boolean;
}

export const MultiSelectionToggleGroup = ({
  groupId,
  onToggle,
  enableHotkey = false,
}: MultiSelectionToggleGroupProps) => {
  const { selectionMode, setSelectionMode, getSelection } =
    useMultiSelectionContext();
  const listingSelection = getSelection(groupId);
  const isChecked = selectionMode?.groupId === groupId;

  const onSelectMultipleClick = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    onToggle?.(groupId, isChecked);

    if (listingSelection.isSingleGroupMultiSelect) {
      setSelectionMode();
    } else {
      setSelectionMode({
        mode: MultiSelectScope.SingleGroup,
        groupId,
      });
    }
  };

  // Since hotkeys are shared with globalToggle, disabled the hotkey
  // in here will make the global to be disabled too.
  const keyNames = enableHotkey ? SelectAllHotkeys : '';

  return (
    <PosHotkeys
      keyNames={keyNames}
      onHotkeyActivated={(_keyName, _event) => {
        onSelectMultipleClick();
      }}
      disabled={!enableHotkey}
    >
      <MultiSelectionToggle
        onChange={onSelectMultipleClick}
        isToggled={isChecked}
      />
    </PosHotkeys>
  );
};
