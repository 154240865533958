import { MouseEvent } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { vars } from 'src/core/themes';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ExpandIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import { Feature } from 'src/WebApiController';

import { FormatOption } from '../EditTableColumns.constants';
import { generateFormatOptionDisplayString } from '../EditTableColumns.util';
import { FormatOptionPillBase } from './FormatOptionPill.base';
import { FormatOptionPillSelect } from './FormatOptionPill.select';
import type {
  FormatOptionPillContainerProps,
  SelectableFormatOptionPillContainerProps,
} from './FormatOptionPill.types';

export const FormatOptionPillContainer = (
  props:
    | FormatOptionPillContainerProps
    | SelectableFormatOptionPillContainerProps
) => {
  const hasViewListingOriginalSeatingFeature = useUserHasFeature(
    Feature.ViewListingOriginalSeating
  );
  const {
    flags = {},
    isSelectable,
    option = flags.isDateTime
      ? FormatOption.DateTime_MonthDD_Time
      : FormatOption.Precise,
    ...rest
  } = props;

  let id: string | undefined;
  let onSave: ((id: string, precision: FormatOption) => void) | undefined;
  let sectionType: SectionType | undefined;
  const { isCurrency, isPercent } = flags;

  const originalSeating = useContent(ContentId.OriginalSeating);
  const fullSeating = useContent(
    (props.sectionType === SectionType.Listings ||
      props.sectionType === SectionType.ListingsFlattened) &&
      hasViewListingOriginalSeatingFeature
      ? ContentId.RemainingSeatingOnly
      : ContentId.FullSeating
  );
  const sectionOnly = useContent(ContentId.SectionOnly);
  const fullEventInfo = useContent(ContentId.FullEventInfo);
  const eventName = useContent(ContentId.EventName);
  const icon = useContent(ContentId.Icon);
  const text = useContent(ContentId.Text);
  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();

  const uiCurrency = getUiCurrency(
    loginContext?.user?.activeAccount.currencyCode
  );

  const textContent = generateFormatOptionDisplayString({
    localizedText: {
      originalSeating,
      fullSeating,
      sectionOnly,
      fullEventInfo,
      eventName,
      icon,
      text,
    },
    uiCurrency,
    isCurrency,
    isPercent,
    option,
  });

  const onPointerDownHandler = (e: MouseEvent) => e.stopPropagation();

  const formatOptionPillBase = (
    <FormatOptionPillBase
      onPointerDown={onPointerDownHandler}
      content={
        isSelectable ? (
          <>
            {textContent}
            <ExpandIcon size={vars.iconSize.xs} fill={IconsFill.textBrand} />
          </>
        ) : (
          textContent
        )
      }
      {...rest}
    />
  );

  if (isSelectable) {
    id = props.id;
    onSave = props.onSave;
    sectionType = props.sectionType;
  }

  if (!isSelectable || !id || !onSave || !sectionType)
    return formatOptionPillBase;

  return (
    <FormatOptionPillSelect
      id={id}
      initialValue={option}
      onSave={onSave}
      sectionType={sectionType}
      trigger={formatOptionPillBase}
    />
  );
};
