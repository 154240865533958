import { isEqual, uniq } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { TableColumnsInput } from 'src/modals/ColumnSettings/TableColumnsInput';
import { filterColumnsByFeatures } from 'src/utils/columns/columnUtils';
import { ListingReportTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { filterCustomColumnsForListingReport } from 'src/utils/columns/inventory/inventoryReportCustomColumnUtils';
import { SectionType } from 'src/utils/types/sectionType';
import {
  ActionOutboxEntityType,
  ReportColumn,
  UserSetting,
} from 'src/WebApiController';

import { useGetReportTableColumnIdMapping } from '../useGetReportTableColumnIdMapping';

export const InventoryMetricsSelectionInput = ({
  onSaved,
}: {
  onSaved?: () => void;
}) => {
  const { appContext, loginContext } = useAppContext();
  const { setValue, watch, clearErrors } = useFormContext<ReportConfigV2>();

  const {
    value: customListingColumns = [],
    setUserSetting: setCustomListingColumns,
  } = useServerUserSetting<CustomListingColumn[]>({
    id: UserSetting.InventoryCustomColumns,
  });
  const {
    listingReportColumnIdToAggregateMappingQuery: {
      data: listingReportColumnIdToValue,
    },
  } = useGetReportTableColumnIdMapping();

  const groupingsField = 'request.groupings';
  const metricsField = 'request.aggregations';

  const { tagsMetadataNumeric } = useTagsForEntityType(
    ActionOutboxEntityType.Listing,
    true
  );

  const { tagsMetadata: tagsMetadataEvent } = useTagsForEntityType(
    ActionOutboxEntityType.SellerEvent,
    true
  );

  const { tagsMetadata: tagsMetadataPurchase } = useTagsForEntityType(
    ActionOutboxEntityType.Purchase,
    true
  );

  const customListingColumnsFiltered = filterCustomColumnsForListingReport(
    customListingColumns,
    tagsMetadataNumeric
  );

  const groupBy = watch(groupingsField);
  const metricsColumns = watch(metricsField);

  const reportingTags = useMemo(() => {
    const groupByColumNames = groupBy?.map((g) => g.columnName) ?? [];
    if (groupByColumNames.includes(ListingReportTableColumnId.Event)) {
      return [...(tagsMetadataNumeric ?? []), ...(tagsMetadataEvent ?? [])];
    }
    if (groupByColumNames.includes(ListingReportTableColumnId.PurchaseId)) {
      return [...(tagsMetadataNumeric ?? []), ...(tagsMetadataPurchase ?? [])];
    }

    return tagsMetadataNumeric;
  }, [groupBy, tagsMetadataEvent, tagsMetadataNumeric, tagsMetadataPurchase]);

  const requiredColumnIds = useMemo(
    () => groupBy?.map((g) => g.columnName) ?? [],
    [groupBy]
  );

  const metrics = useMemo(
    () => [
      ...requiredColumnIds,
      ...(metricsColumns?.map((m) => m.columnName) ?? []),
    ],
    [metricsColumns, requiredColumnIds]
  );

  const presetColumnIds = useMemo(
    () => [
      ...requiredColumnIds,
      ...Object.keys(listingReportColumnIdToValue ?? {}),
    ],
    [listingReportColumnIdToValue, requiredColumnIds]
  );

  const activeTagNames = useMemo(
    // Not implemented yet
    // () => reportingTags?.map((t) => t.key) ?? [],
    () => [] as string[],
    []
  );

  // Not implemented yet
  // const customColumnIds = customListingColumnsFiltered.map((c) => c.id);
  const customColumnIds = useMemo(() => [] as string[], []);

  const filterColumns = useCallback(
    (columnList: string[]) => {
      return columnList.filter(
        (m) =>
          presetColumnIds.includes(m) ||
          activeTagNames.includes(m) ||
          customColumnIds.includes(m)
      );
    },
    [activeTagNames, customColumnIds, presetColumnIds]
  );

  const metricsFiltered = useMemo(
    () => filterColumns(metrics),
    [filterColumns, metrics]
  );

  const userColumnSelection = useMemo(
    () => metricsFiltered ?? [],
    [metricsFiltered]
  );

  const storedColumnOrderSettingFiltered = useMemo(() => {
    const allColumnIdsAvailable = filterColumns(
      uniq([
        ...metricsFiltered,
        ...presetColumnIds,
        ...customColumnIds,
        ...activeTagNames,
      ])
    ).sort((a, b) => a.localeCompare(b));

    const existingColumnsOrderSetting = filterColumns(
      metricsFiltered as string[]
    );

    allColumnIdsAvailable.forEach((columnId) => {
      if (!existingColumnsOrderSetting.includes(columnId)) {
        existingColumnsOrderSetting.push(columnId);
      }
    });

    return existingColumnsOrderSetting;
  }, [
    activeTagNames,
    customColumnIds,
    filterColumns,
    metricsFiltered,
    presetColumnIds,
  ]);

  const [userColumnSorting, setUserColumnSorting] = useState<string[]>(
    filterColumnsByFeatures(
      storedColumnOrderSettingFiltered,
      SectionType.ListingsReport,
      customListingColumns,
      loginContext?.user,
      appContext?.features,
      reportingTags
    )
  );

  useEffect(() => {
    setUserColumnSorting(
      filterColumnsByFeatures(
        storedColumnOrderSettingFiltered,
        SectionType.ListingsReport,
        customListingColumns,
        loginContext?.user,
        appContext?.features,
        reportingTags
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedColumnOrderSettingFiltered]);

  const onSaveHandler = (
    userColumnSorting: string[],
    userColumnSelection: string[]
  ) => {
    const sortedColumnSelection = userColumnSorting.filter((c) =>
      userColumnSelection.includes(c)
    );

    const newColumns: ReportColumn[] = [];
    sortedColumnSelection.forEach((columnName) => {
      const columnValue = listingReportColumnIdToValue?.[columnName];
      if (columnName && columnValue) {
        newColumns.push({
          columnName,
          columnValue,
        });
      }
    });

    setValue(metricsField, newColumns);
    clearErrors(metricsField);
    onSaved?.();
  };

  // Keep the columns up to date in case the user changes the settings (e.g. deletes custom column)
  useEffect(() => {
    const allColumnIdsAvailable = filterColumns(
      uniq([
        ...metricsFiltered,
        ...presetColumnIds,
        ...customColumnIds,
        ...activeTagNames,
      ])
    ).sort((a, b) => a.localeCompare(b));

    let userColumnSortingFiltered = filterColumns(userColumnSorting);

    allColumnIdsAvailable.forEach((columnId) => {
      if (!userColumnSortingFiltered.includes(columnId)) {
        userColumnSortingFiltered.push(columnId);
      }
    });

    userColumnSortingFiltered = filterColumnsByFeatures(
      storedColumnOrderSettingFiltered,
      SectionType.ListingsReport,
      customListingColumns,
      loginContext?.user,
      appContext?.features,
      reportingTags
    );

    if (
      !isEqual(new Set(userColumnSortingFiltered), new Set(userColumnSorting))
    ) {
      setUserColumnSorting(userColumnSortingFiltered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customColumnIds]);

  return (
    <TableColumnsInput<CustomListingColumn>
      requiredColumns={requiredColumnIds}
      userColumnSelection={userColumnSelection}
      onUserColumnSelectionChange={(newSelection) => {
        onSaveHandler(userColumnSorting, newSelection);
      }}
      userColumnSorting={userColumnSorting}
      onUserColumnSortingChange={(newSorting) => {
        setUserColumnSorting(newSorting);
        onSaveHandler(newSorting, userColumnSelection);
      }}
      // Not implemented yet
      // onCustomColumnsChange={setCustomListingColumns}
      customColumns={customListingColumnsFiltered}
      reportingTags={reportingTags}
      sectionType={SectionType.ListingsReport}
      style={{
        padding: 'unset',
        width: '100%',
      }}
    />
  );
};
