import { debounce, isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ListItem, Virtuoso } from 'react-virtuoso';
import removeAccents from 'remove-accents';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { PerformerVenueDisplay } from 'src/components/Events/PerformerVenueList/PerformerVenueDisplay/PerformerVenueDisplay';
import { PerformerVenueFilterBar } from 'src/components/Events/PerformerVenueList/PerformerVenueFilterBar/PerformerVenueFilterBar';
import { SelectedPerformerVenueContainer } from 'src/components/Events/PerformerVenueList/PerformerVenueList.styled';
import { SelectedPerformerVenueList } from 'src/components/Events/PerformerVenueList/SelectedPerformerVenueList';
import { SelectedPerformerVenuesDivider } from 'src/components/Events/PerformerVenueList/SelectedPerformerVenueListDivider';
import { FilterToolbarItemId } from 'src/components/FilterToolbar';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  DefaultEventGroupingQuery,
  QueryWithViewMode,
} from 'src/utils/eventQueryUtils';
import { compareLocaleLowercased } from 'src/utils/localeUtils';
import {
  DEFAULT_REPORT_FILTER_EDITABILITY,
  ReportFilterEditability,
} from 'src/utils/reportsFilterUtils';
import { SomethingWentWrong } from 'src/views';
import {
  CatalogEntity,
  EntityWithTicketsQuery,
  EventGroupingQuery,
  EventGroupingType,
} from 'src/WebApiController';

import { PerformerVenueFilterBarProps } from './PerformerVenueFilterBar/PerformerVenueFilterBar.types';
import * as styles from './PerformerVenueList.css';

type PerformerVenueListingItem =
  | { entity: CatalogEntity; isSelected: boolean }
  | number;

type CatalogEntitiesWithGroupingType = {
  entities: CatalogEntity[];
  groupingType: EventGroupingType;
};

export type PerformerVenueListProps = {
  onItemsRendered?: (
    items: CatalogEntity[],
    itemType: EventGroupingType
  ) => void;
  hideMetrics?: boolean;
  updateTempQuery?: boolean;
  onEditabilityChange?: (
    filterId: FilterToolbarItemId,
    editability: ReportFilterEditability
  ) => void;
} & Pick<
  PerformerVenueFilterBarProps,
  | 'onEditabilityChange'
  | 'performerFilterEditability'
  | 'venueFilterEditability'
  | 'embeddedDisplayOnly'
  | 'swiperRef'
>;

export function PerformerVenueList({
  onItemsRendered,
  hideMetrics,
  updateTempQuery,
  embeddedDisplayOnly,
  performerFilterEditability,
  venueFilterEditability,
  ...props
}: PerformerVenueListProps) {
  const defaultGroupingQuery = useMemo(() => {
    return {
      ...DefaultEventGroupingQuery,
      groupingType:
        !embeddedDisplayOnly ||
        (performerFilterEditability ?? DEFAULT_REPORT_FILTER_EDITABILITY) !==
          ReportFilterEditability.Hidden
          ? EventGroupingType.Performer
          : EventGroupingType.Venue,
    };
  }, [embeddedDisplayOnly, performerFilterEditability]);

  const [groupingQuery, setGroupingQuery] =
    useState<EventGroupingQuery>(defaultGroupingQuery);
  const [debouncedGroupingQuery, setDebouncedGroupingQuery] =
    useState<EventGroupingQuery>(groupingQuery);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceWrapper = useCallback(
    debounce((f: EventGroupingQuery) => {
      setDebouncedGroupingQuery(f);
    }, 200),
    []
  );

  const handleFilterChange = useCallback(
    (f: EventGroupingQuery) => {
      setGroupingQuery(f);
      debounceWrapper(f);
    },
    [debounceWrapper]
  );

  const [selectedPerformerVenuesWithType, setSelectedPerformerVenuesWithType] =
    useState<CatalogEntitiesWithGroupingType[]>([]);
  const [selectedDividerIsVisibleInList, setSelectedDividerIsVisibleInList] =
    useState(false);
  const [
    selectedDividerButtonShouldBeVisible,
    setSelectedDividerButtonShouldBeVisible,
  ] = useState(false);
  const [showSelectedList, setShowSelectedList] = useState(false);

  const { filterQuery, setFilterQuery, setTempQuery } = useFilterQueryContext<
    EntityWithTicketsQuery & QueryWithViewMode
  >();
  const { isLoading, data, errorInfo } = useCatalogDataContext();
  const { performerMetrics, venueMetrics } = useCatalogMetricsContext();

  const selectedPerformerVenues: CatalogEntity[] = useMemo(
    () =>
      selectedPerformerVenuesWithType.find(
        (s) => s.groupingType === groupingQuery.groupingType
      )?.entities ?? [],
    [groupingQuery.groupingType, selectedPerformerVenuesWithType]
  );

  const updateSelectedPerformerVenues = useCallback(
    (newSelectedPerformerVenues: CatalogEntitiesWithGroupingType[]): void => {
      if (newSelectedPerformerVenues.length) {
        // We want to retain the selected main groupings of other type than currently selected
        // So what when switching back the main grouping type, they won't get cleared
        const selectedPerformerVenuesOtherType =
          selectedPerformerVenuesWithType.filter(
            (s) =>
              !newSelectedPerformerVenues.some(
                (n) => n.groupingType === s.groupingType
              )
          );
        setSelectedPerformerVenuesWithType([
          ...selectedPerformerVenuesOtherType,
          ...newSelectedPerformerVenues,
        ]);
      } else {
        setSelectedPerformerVenuesWithType([]);
      }

      const newFilterQuery = { ...filterQuery };
      const performerGroupings = newSelectedPerformerVenues.find(
        (s) => s.groupingType === EventGroupingType.Performer
      );
      const venueGroupings = newSelectedPerformerVenues.find(
        (s) => s.groupingType === EventGroupingType.Venue
      );
      if (performerGroupings) {
        newFilterQuery.performerIds = performerGroupings.entities.length
          ? performerGroupings.entities.map((mg) => mg.viagId!).sort()
          : null;
      }
      if (venueGroupings) {
        newFilterQuery.venueIds = venueGroupings.entities.length
          ? venueGroupings.entities.map((mg) => mg.viagId!).sort()
          : null;
      }

      if (performerGroupings || venueGroupings) {
        if (updateTempQuery) {
          setTempQuery(newFilterQuery);
        }
        setFilterQuery(newFilterQuery);
      }
    },
    [
      filterQuery,
      selectedPerformerVenuesWithType,
      updateTempQuery,
      setTempQuery,
      setFilterQuery,
    ]
  );

  const filterPerformerVenues = useCallback(
    (
      groupings: CatalogEntity[] | undefined,
      selectedPerformerVenues: CatalogEntity[]
    ) => {
      if (!groupings) return undefined;

      let filteredGroups = [...groupings];

      if (selectedPerformerVenues?.length) {
        const curSelectedIds = selectedPerformerVenues.map((mg) => mg.viagId);
        // Any performer id or venue id in the query that is no longer in the result list should be removed (unselected)
        const newAllIds = filteredGroups.map((mg) => mg.viagId);
        const updateSelectedIds = curSelectedIds?.filter((id) =>
          newAllIds.includes(id)
        );
        if (!isEqual(curSelectedIds, updateSelectedIds)) {
          const newSelectedPerformerVenues = selectedPerformerVenues.filter(
            (mg) => updateSelectedIds.includes(mg.viagId)
          );

          updateSelectedPerformerVenues([
            {
              entities: newSelectedPerformerVenues,
              groupingType: groupingQuery.groupingType,
            },
          ]);
        }
      }

      const searchText = removeAccents(
        debouncedGroupingQuery?.searchText?.toUpperCase() || ''
      );
      if (searchText.length > 0) {
        filteredGroups = filteredGroups.filter((g) => {
          const isSelected = selectedPerformerVenues.some(
            (mg2) => mg2.viagId === g.viagId
          );
          const entity = removeAccents(g.name.toUpperCase());
          const searchTerms = searchText.split(/\s/); // any whitespace

          const isMatchingSearch = searchTerms.every((term) =>
            entity.includes(term)
          );
          // We always keep the selected around
          return isSelected || isMatchingSearch;
        });
      }

      // NOTE - For main-grouping, we will sort in-memory because the list is not paged,
      // but if it is paged, we will need to sort server side
      filteredGroups.sort((a, b) => {
        const result = compareLocaleLowercased(a.name, b.name);
        return debouncedGroupingQuery?.isSortDescending ? -1 * result : result;
      });

      return filteredGroups;
    },
    [
      debouncedGroupingQuery?.isSortDescending,
      debouncedGroupingQuery?.searchText,
      groupingQuery.groupingType,
      updateSelectedPerformerVenues,
    ]
  );

  const onPerformerVenueClicked = useCallback(
    (g: CatalogEntity) => {
      // Clicking will toggle the display
      const newSelectedPerformerVenues =
        selectedPerformerVenues.findIndex((sg) => sg.viagId === g.viagId) >= 0
          ? selectedPerformerVenues.filter((sg) => sg.viagId !== g.viagId)
          : [...selectedPerformerVenues, g];

      if (
        newSelectedPerformerVenues.length > 0 &&
        !selectedDividerIsVisibleInList
      ) {
        setSelectedDividerButtonShouldBeVisible(true);
      }

      updateSelectedPerformerVenues([
        {
          entities: newSelectedPerformerVenues,
          groupingType: groupingQuery.groupingType,
        },
      ]);
    },
    [
      groupingQuery.groupingType,
      selectedDividerIsVisibleInList,
      selectedPerformerVenues,
      updateSelectedPerformerVenues,
    ]
  );

  const selectedPerformerVenuesMap = selectedPerformerVenues.reduce<
    Record<string, CatalogEntity>
  >((result, cur) => {
    result[cur.viagId!] = cur;
    return result;
  }, {});

  const filteredData = filterPerformerVenues(
    groupingQuery.groupingType === EventGroupingType.Performer
      ? data?.performers
        ? Object.values(data.performers)
        : undefined
      : data?.venues
      ? Object.values(data.venues)
      : undefined,
    selectedPerformerVenues
  );

  useEffect(() => {
    // Currently we only need to update `selectedPerformerVenues` according to `filterQuery`
    // When:
    // 1. loading from a url that contains non-empty performerIds/venueIds query params
    // 2. filterQuery has been reset
    if (data === undefined) {
      return;
    }

    if (!selectedPerformerVenues.length) {
      const newSelectedPerformerVenues: CatalogEntitiesWithGroupingType[] = [];
      if (filterQuery.performerIds?.length) {
        const groupings = Object.values(data?.performers ?? {}) || [];
        const performerGroupings = groupings.filter(
          (mg) => mg.viagId && filterQuery.performerIds?.includes(mg.viagId)
        );

        if (groupingQuery.groupingType !== EventGroupingType.Performer) {
          handleFilterChange({
            ...groupingQuery,
            groupingType: EventGroupingType.Performer,
          });
          // Assigning state directly because `updateSelectedPerformerVenues`
          // Needs to use the state immediately
          groupingQuery.groupingType = EventGroupingType.Performer;
        }

        newSelectedPerformerVenues.push({
          entities: performerGroupings,
          groupingType: EventGroupingType.Performer,
        });
      }

      if (filterQuery.venueIds?.length) {
        const groupings = Object.values(data?.venues ?? {}) || [];
        const venueGroupings = groupings.filter(
          (mg) => mg.viagId && filterQuery.venueIds?.includes(mg.viagId)
        );

        if (!filterQuery.performerIds?.length) {
          if (groupingQuery.groupingType !== EventGroupingType.Venue) {
            handleFilterChange({
              ...groupingQuery,
              groupingType: EventGroupingType.Venue,
            });
          }
        }

        newSelectedPerformerVenues.push({
          entities: venueGroupings,
          groupingType: EventGroupingType.Venue,
        });
      }

      if (newSelectedPerformerVenues.length) {
        updateSelectedPerformerVenues(newSelectedPerformerVenues);
      }
    }

    if (!filterQuery.performerIds?.length && !filterQuery.venueIds?.length) {
      updateSelectedPerformerVenues([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery.performerIds, filterQuery.venueIds, data]);

  const isSelected = useCallback(
    (mg: CatalogEntity) => {
      const isSelected = selectedPerformerVenuesMap[mg.viagId!];
      return Boolean(isSelected);
    },
    [selectedPerformerVenuesMap]
  );

  const getItemsToDisplay = useCallback((): {
    items: PerformerVenueListingItem[];
    indexOfDivider: number;
  } => {
    const { selected, unselected } = (filteredData || []).reduce(
      ({ selected, unselected }, mg) => {
        if (isSelected(mg)) {
          selected.push(mg);
        } else {
          unselected.push(mg);
        }
        return { unselected, selected };
      },
      { unselected: [], selected: [] } as {
        unselected: CatalogEntity[];
        selected: CatalogEntity[];
      }
    );

    const items: PerformerVenueListingItem[] = selected.map((mg) => {
      return { entity: mg, isSelected: true };
    });

    let indexOfDivider = -1;
    if (selected.length) {
      indexOfDivider = items.length;
      items.push(selected.length);
    }

    unselected.forEach((mg) => {
      items.push({ entity: mg, isSelected: false });
    });

    return { items, indexOfDivider };
  }, [filteredData, isSelected]);

  const { items, indexOfDivider } = getItemsToDisplay();

  const onItemsRenderedHandler = (
    items: ListItem<PerformerVenueListingItem>[]
  ) => {
    onItemsRendered?.(
      items
        .filter((i) => i.data != null)
        .map((i) => (typeof i.data === 'number' ? undefined : i.data!.entity))
        .filter((i) => i != null)
        .map((i) => i!),
      groupingQuery.groupingType
    );

    if (indexOfDivider >= 0 && items.length) {
      // if there is a divider and the divider is not hidden to the top
      const dividerInView = indexOfDivider >= items[0].index;

      if (dividerInView) {
        if (!selectedDividerIsVisibleInList)
          setSelectedDividerIsVisibleInList(true);
        if (selectedDividerButtonShouldBeVisible)
          setSelectedDividerButtonShouldBeVisible(false);
        if (showSelectedList) setShowSelectedList(false);
      } else {
        // divider is out of view
        if (selectedDividerIsVisibleInList)
          setSelectedDividerIsVisibleInList(false);
        if (!selectedDividerButtonShouldBeVisible)
          setSelectedDividerButtonShouldBeVisible(true);
      }
    } else {
      if (selectedDividerIsVisibleInList)
        setSelectedDividerIsVisibleInList(false);
      if (selectedDividerButtonShouldBeVisible)
        setSelectedDividerButtonShouldBeVisible(false);
      if (showSelectedList) setShowSelectedList(false);
    }
  };

  const showSelectedPerformerVenuesList = () => {
    setShowSelectedList(!showSelectedList);
  };

  const NoMatchingContent = useMemo(() => {
    if (groupingQuery.groupingType === EventGroupingType.Performer) {
      return <Content id={ContentId.NoPerformersMatching} />;
    }
    return <Content id={ContentId.NoVenuesMatching} />;
  }, [groupingQuery]);

  const activeEditability = useMemo(() => {
    return groupingQuery.groupingType === EventGroupingType.Performer
      ? performerFilterEditability
      : venueFilterEditability;
  }, [
    groupingQuery.groupingType,
    performerFilterEditability,
    venueFilterEditability,
  ]);

  return (
    <div className={styles.performerVenuesDiv}>
      <PerformerVenueFilterBar
        groupingQuery={groupingQuery}
        onGroupingQueryChanged={handleFilterChange}
        embeddedDisplayOnly={embeddedDisplayOnly}
        performerFilterEditability={performerFilterEditability}
        venueFilterEditability={venueFilterEditability}
        {...props}
      />
      <div className={styles.performerVenuesContainer}>
        {isLoading && <PosSpinner />}
        {errorInfo && (
          <SomethingWentWrong
            header={errorInfo.errorHeader}
            message={<Content id={ContentId.FailToLoadListContent} />}
          />
        )}
        {!isLoading && !errorInfo && (
          <div className={styles.mainGroupingItemsContainer}>
            {selectedDividerButtonShouldBeVisible &&
              selectedPerformerVenues.length > 0 && (
                <SelectedPerformerVenueContainer>
                  <SelectedPerformerVenueList
                    selectedPerformerVenues={selectedPerformerVenues}
                    isOpen={showSelectedList}
                    onPerformerVenueClicked={onPerformerVenueClicked}
                    metricsData={
                      groupingQuery.groupingType === EventGroupingType.Performer
                        ? performerMetrics
                        : venueMetrics
                    }
                    disabled={
                      embeddedDisplayOnly &&
                      activeEditability !== ReportFilterEditability.Edit
                    }
                  />
                  <SelectedPerformerVenuesDivider
                    selectedCount={selectedPerformerVenues.length}
                    totalCount={filteredData!.length}
                    onClick={showSelectedPerformerVenuesList}
                    isOpened={showSelectedList}
                  />
                </SelectedPerformerVenueContainer>
              )}
            {items?.length > 0 && (
              <Virtuoso
                data={items}
                itemsRendered={onItemsRenderedHandler}
                itemContent={(i, d) => {
                  if (typeof d === 'number') {
                    return (
                      <SelectedPerformerVenuesDivider
                        key={'divider-' + indexOfDivider}
                        selectedCount={d}
                        totalCount={filteredData!.length}
                      />
                    );
                  } else {
                    return (
                      <PerformerVenueDisplay
                        key={d.entity.viagId}
                        grouping={d.entity}
                        isSelected={d.isSelected}
                        onPerformerVenueClicked={onPerformerVenueClicked}
                        hideMetrics={hideMetrics}
                        metricsData={
                          groupingQuery.groupingType ===
                          EventGroupingType.Performer
                            ? performerMetrics
                            : venueMetrics
                        }
                        disabled={
                          embeddedDisplayOnly &&
                          activeEditability !== ReportFilterEditability.Edit
                        }
                      />
                    );
                  }
                }}
              />
            )}
            {items?.length === 0 && (
              <EmptySectionContent.Root
                icon={
                  <EmptySectionContent.SolidIconContainer>
                    <SearchSolidIcon />
                  </EmptySectionContent.SolidIconContainer>
                }
              >
                <EmptySectionContent.Label>
                  <Content id={ContentId.NoResultFound} />
                </EmptySectionContent.Label>
                <EmptySectionContent.DetailMessage>
                  {NoMatchingContent}
                </EmptySectionContent.DetailMessage>
              </EmptySectionContent.Root>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
