import { useUpdateSalesSettingsPermissions } from 'src/components/Sales/BulkActions/useUpdateSalesSettingsPermissions';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export const LaunchUpdateSalesSettings = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const { hasUpdateSalesSettingsPermission } =
    useUpdateSalesSettingsPermissions();

  if (!hasUpdateSalesSettingsPermission) {
    return null;
  }

  return (
    <>
      <PosDropdownItem
        key="general-settings-event-sales"
        onClick={onClick}
        disabled={disabled}
      >
        <Stack gap="m" alignItems="center" width="full">
          <span style={{ textAlign: 'left' }}>
            <Content id={ContentId.EditGeneralSettings} />
          </span>
        </Stack>
      </PosDropdownItem>
    </>
  );
};
