import { format } from 'date-fns';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useCallback, useMemo } from 'react';
import { UseMeasureRect } from 'react-use/lib/useMeasure';
import { useContent } from 'src/contexts/ContentContext';
import { LineChartSeries } from 'src/contexts/EventMetricsContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { applyNumberFormatting } from 'src/utils/numberFormatter';

import * as styles from './TimeSeriesLineChart.css';
import {
  TimeSeriesLineChartUnit,
  TimeSeriesLineChartUnitType,
} from './TimeSeriesLineChart.types';

const getXAxisRelativeLabel = (
  today: string | undefined,
  value: string | number,
  unit: 'hour' | 'day' | 'week'
): number => {
  const current = today ? new Date(today) : new Date();
  const temp = new Date(value);
  const diffInMilli = current.getTime() - temp.getTime();
  if (unit === 'hour') {
    return Math.ceil(diffInMilli / (3600 * 1_000));
  }

  if (unit === 'day') {
    return Math.ceil(diffInMilli / (24 * 3600 * 1000));
  }

  return Math.ceil(diffInMilli / (7 * 24 * 3600 * 1000));
};

export type TimeSeriesLineChartProps = {
  series: LineChartSeries[];
  labelUnit?: 'hour' | 'day' | 'week';
  today?: string;
  unitType?: TimeSeriesLineChartUnitType;
  container?: UseMeasureRect;
  isLoading?: boolean;
};

export const TimeSeriesLineChart: React.FC<TimeSeriesLineChartProps> = ({
  series,
  today,
  labelUnit = 'day',
  unitType = {
    type: TimeSeriesLineChartUnit.Number,
  },
  container,
  isLoading,
}) => {
  const { getUiCurrency } = useLocalizationContext();
  const nowText = useContent(ContentId.Now);
  const hourText = useContent(ContentId.Hour);
  const hoursText = useContent(ContentId.Hours);
  const dayText = useContent(ContentId.Day);
  const daysText = useContent(ContentId.Days);
  const weekText = useContent(ContentId.Week);
  const weeksText = useContent(ContentId.Weeks);
  const agoText = useContent(ContentId.Ago);

  const { seriesData } = useMemo(() => {
    const colors = series.every(({ color }) => color != null)
      ? series.map(({ color }) => color!)
      : undefined;
    const seriesData = series.map(({ name, data }) => ({
      type: 'spline',
      name,
      data: data.map(({ date, value }) => [new Date(date).getTime(), value]),
    })) as Highcharts.SeriesSplineOptions[];
    return { colors, seriesData };
  }, [series]);

  const formatXAxisLabel = useCallback(
    (value: number) => {
      if (today && value === new Date(today).getTime()) {
        return nowText.toLowerCase();
      }
      const diff = getXAxisRelativeLabel(today, value, labelUnit);
      if (labelUnit === 'hour') {
        const unit = diff > 1 ? hoursText : hourText;
        return `${diff} ${unit} ${agoText}`.toLowerCase();
      }
      if (labelUnit === 'day') {
        const unit = diff > 1 ? daysText : dayText;
        return `${diff} ${unit} ${agoText}`.toLowerCase();
      }
      const unit = diff > 1 ? weeksText : weekText;
      return `${diff} ${unit} ${agoText}`.toLowerCase();
    },
    [
      agoText,
      dayText,
      daysText,
      hourText,
      hoursText,
      labelUnit,
      nowText,
      today,
      weekText,
      weeksText,
    ]
  );

  const formatYAxisLabel = useCallback(
    (value: number) => {
      if (unitType.type === TimeSeriesLineChartUnit.Price) {
        const currency = getUiCurrency(unitType.currency);
        const decimalDigits = value ?? 0 > 100 ? 0 : 2;
        return (
          applyNumberFormatting({
            inputNumber: value,
            currencyCode: currency.code,
            currencyDecimalDigits: decimalDigits,
          }) ?? ''
        );
      }
      return value.toLocaleString();
    },
    [getUiCurrency, unitType]
  );

  const formatTooltip = useCallback(
    ({ x, y }: Highcharts.Point) => {
      const formatter =
        labelUnit === 'hour'
          ? 'yyyy-MM-dd HH:mm:ss'
          : labelUnit === 'day'
          ? 'yyyy-MM-dd HH'
          : 'yyyy-MM-dd';
      const date = format(new Date(x), formatter);
      const value = y && formatYAxisLabel(y);
      return `${date}:\n ${value}`;
    },
    [formatYAxisLabel, labelUnit]
  );

  const options: Highcharts.Options = useMemo(
    () => ({
      title: { text: undefined },
      chart: {
        width: container?.width ?? 600,
        height: (container?.height ?? 300) - 10,
        backgroundColor: 'transparent',
        type: 'spline',
      },
      plotOptions: {},
      tooltip: {
        headerFormat: '<b>{series.name}</b><br>',
        pointFormatter: function () {
          return formatTooltip(this);
        },
      },
      xAxis: {
        type: 'datetime',
        dateTimeLabelFormats: {
          day: '%Y-%m-%d',
          month: '%m',
          year: '%Y',
        },
        labels: {
          enabled: true,
          formatter: function () {
            return formatXAxisLabel(this.value as number);
          },
          style: { color: vars.color.textPrimary },
        },
      },
      yAxis: {
        title: {
          text: undefined,
        },
        gridLineColor: 'transparent',
        labels: {
          enabled: true,
          formatter: function () {
            return formatYAxisLabel(this.value as number);
          },
          style: { color: vars.color.textPrimary },
        },
      },
      legend: { enabled: false },
      series: seriesData,
      credits: { enabled: false },
    }),
    [
      container?.height,
      container?.width,
      formatTooltip,
      formatXAxisLabel,
      formatYAxisLabel,
      seriesData,
    ]
  );

  return (
    <Stack
      direction="column"
      width="full"
      height="full"
      className={styles.container}
    >
      {isLoading ? (
        <ContentPlaceholder height="4rem" width="80%" />
      ) : (
        <HighchartsReact highcharts={Highcharts} options={options} />
      )}
    </Stack>
  );
};
