import * as styles from './FulfillmentProviders.css';
import { Content } from "src/contexts/ContentContext";
import { ContentId } from "src/utils/constants/contentId";
import {
    FulfillmentProviderCard
} from "src/navigations/Routes/Settings/Views/FulfillmentProviders/FulfillmentProviderCard";
import { Stack } from "src/core/ui";
import { useGetFulfillmentProviderSettings } from "src/hooks/useGetFulfillmentProviderSettings";
import { TradedeskTransferLogo } from "src/svgs/TradedeskTransferLogo";
import { TdcTransferLogo } from "src/svgs/TdcTransferLogo";
import { TaciyonLogo } from "src/svgs/TaciyonLogo";
import { StubhubFulfillmentLogo } from "src/svgs/StubhubFulfillmentLogo";

export const MapFulfillmentProviderToIcon = (providerName: string) => {
    // TODO: switch to mapping icon to guid instead of name once they are created in the DB
    switch (providerName.toLowerCase()) {
        case "stubhub fulfillment":
            return <StubhubFulfillmentLogo />;
        case "taciyon":
            return <TaciyonLogo />;
        case "tradedesk transfer":
            return <TradedeskTransferLogo />;
        case "transfer":
            return <TdcTransferLogo />;
        default:
            return null;
    }
};

export const FulfillmentProviders = () => {
    const {
        fulfillmentProviderSettings
    } = useGetFulfillmentProviderSettings();

    const providers = fulfillmentProviderSettings ?? [];

    return (
        <div>
            <div className={styles.root}>
                <div className={styles.center}>
                    <h1 className={styles.pageName}>
                        <Content id={ContentId.FulfillmentProviders} />
                    </h1>
                    <div className={styles.subtleText}>
                        <Content id={ContentId.FulfillmentProvidersSubText} />
                    </div>
                    <div className={styles.rootContainer}>
                        <Stack gap="l" alignItems="center">
                            {providers.length > 0 ? (
                                providers.map(provider => (
                                    <FulfillmentProviderCard
                                        key={provider.fulfillmentProviderSellerUserId}
                                        logo={MapFulfillmentProviderToIcon(provider.fulfillmentProviderName)}
                                        fulfillmentProviderSellerUserId={provider.fulfillmentProviderSellerUserId}
                                        name={provider.fulfillmentProviderName}
                                        description={provider.descriptionMessage}
                                        enabled={provider.isEnabled}
                                    />
                                ))
                            ) : (
                                <div>
                                    <Content id={ContentId.NoFulfillmentProviders} />
                                </div>
                            )}
                        </Stack>
                    </div>
                </div>
            </div>
        </div>
    );
};
