import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionType,
  ActivityLogQuery,
  AuditActionType,
} from 'src/WebApiController';

export const EMPTY_ACTIVITY_LOG_QUERY: ActivityLogQuery = {
  userId: null,
  includeUserOnly: null,
  impersonatedUserId: null,
  entityId: null,
  entityType: null,
  actionTypes: null,
  auditActionTypes: null,
  take: null,
  skip: null,
  tagName: null,
};

export const TAB_ALL = 'all';

/*
 * Listing Entity Activity Log Constants
 */

export const enum ListingActivityLogTab {
  All = TAB_ALL,
  Price = 'price',
  Broadcast = 'broadcast',
  Delivery = 'delivery',
  DeliveryDocuments = 'deliveryDocuments',
  PrivateNotes = 'privateNotes',
  Tickets = 'tickets',
  Event = 'event',
  Allocation = 'allocation',
}

export const TAG_AUDIT_ACTION_TYPES: AuditActionType[] = [
  AuditActionType.TagCreate,
  AuditActionType.TagDelete,
  AuditActionType.TagUpdate,
  AuditActionType.TagTypeCreate,
  AuditActionType.TagTypeUpdate,
];

export const LISTING_ACTIVITY_LOG_TAB_TO_ACTION_TYPES: Record<
  ListingActivityLogTab,
  ActionType[] | null
> = {
  [ListingActivityLogTab.All]: null,
  [ListingActivityLogTab.Price]: [
    ActionType.ListingUpdateUnitNetProceeds,
    ActionType.ListingUpdateAllInPrice,
    ActionType.ListingBroadcastUpdateAllInPrice,
    ActionType.ListingBroadcastUpdateUnitNetProceeds,
  ],
  [ListingActivityLogTab.Broadcast]: [
    ActionType.ListingBroadcast,
    ActionType.ListingUnbroadcast,
    ActionType.ListingMarketplaceInsert,
    ActionType.ListingMarketplaceUpdate,
    ActionType.ListingMarketplaceDelete,
    ActionType.BroadcastMarketplaceListing,
    ActionType.UnbroadcastMarketplaceListing,
  ],
  [ListingActivityLogTab.Delivery]: [
    ActionType.ListingUpdateDeliveryType,
    ActionType.ListingUpdateInHandDate,
    ActionType.ListingBroadcastUpdateInHandDate,
    ActionType.ListingBroadcastUpdateDeliveryType,
  ],
  [ListingActivityLogTab.DeliveryDocuments]: [
    ActionType.ListingPreDeliverBarcodes,
    ActionType.ListingPreDeliverETickets,
    ActionType.ListingPreDeliverTicketExternalIds,
    ActionType.ListingPreDeliverTicketUrls,
    ActionType.ListingRemoveTicketBarcodes,
    ActionType.ListingRemoveTicketUserDocuments,
    ActionType.ListingUploadVerificationDocumentAction,
    ActionType.ListingDocumentUploadActivity,
    ActionType.ListingDocumentViewed,
    ActionType.ListingDocumentDownloaded,
  ],
  [ListingActivityLogTab.PrivateNotes]: [
    ActionType.ListingUpdateListingNotes,
    ActionType.ListingBroadcastUpdateListingNotes,
  ],
  [ListingActivityLogTab.Tickets]: [
    ActionType.ListingUpdateSplitType,
    ActionType.ListingUpdateHideSeats,
    ActionType.ListingBroadcastUpdateSplitType,
    ActionType.ListingUpdateQuantity,
    ActionType.ListingBroadcastUpdateQuantity,
    ActionType.ListingUpdateSeating,
    ActionType.ListingBroadcastUpdateSeating,
    ActionType.ListingMarketplaceSeatingOverrideUpdate,
  ],
  [ListingActivityLogTab.Event]: [],
  [ListingActivityLogTab.Allocation]: [
    ActionType.ListingSaleMarketplaceInsert,
    ActionType.ListingSaleConfirm,
    ActionType.ListingSaleDeallocate,
  ],
};

export const LISTING_ACTIVITY_LOG_TAB_TO_AUDIT_ACTION_TYPES: Record<
  ListingActivityLogTab,
  AuditActionType[] | null
> = {
  [ListingActivityLogTab.All]: null,
  [ListingActivityLogTab.Price]: [],
  [ListingActivityLogTab.Broadcast]: [],
  [ListingActivityLogTab.Delivery]: [],
  [ListingActivityLogTab.DeliveryDocuments]: [],
  [ListingActivityLogTab.PrivateNotes]: [],
  [ListingActivityLogTab.Tickets]: [],
  [ListingActivityLogTab.Event]: [AuditActionType.ListingSellerEventUpdate],
  [ListingActivityLogTab.Allocation]: [],
};

export const LISTING_ACTIVITY_LOG_TAB_TO_CID: Record<
  ListingActivityLogTab,
  ContentId
> = {
  [ListingActivityLogTab.All]: ContentId.All,
  [ListingActivityLogTab.Price]: ContentId.Price,
  [ListingActivityLogTab.Broadcast]: ContentId.Broadcast,
  [ListingActivityLogTab.Delivery]: ContentId.Delivery,
  [ListingActivityLogTab.DeliveryDocuments]: ContentId.DeliveryDocuments,
  [ListingActivityLogTab.PrivateNotes]: ContentId.Notes,
  [ListingActivityLogTab.Tickets]: ContentId.Tickets,
  [ListingActivityLogTab.Event]: ContentId.Event,
  [ListingActivityLogTab.Allocation]: ContentId.Allocation,
};

/*
 * Sale Entity Activity Log Constants
 */

export const enum SaleActivityLogTab {
  All = TAB_ALL,
  Delivery = 'delivery',
  Cost = 'cost',
  PrivateNotes = 'privateNotes',
  Commission = 'commission',
  Allocation = 'allocation',
}

export const SALE_ACTIVITY_LOG_TAB_TO_ACTION_TYPES: Record<
  SaleActivityLogTab,
  ActionType[] | null
> = {
  [SaleActivityLogTab.All]: null,
  [SaleActivityLogTab.Delivery]: [
    ActionType.SaleUploadBarcodes,
    ActionType.SaleUploadTicketUrls,
    ActionType.SaleUploadEtickets,
    ActionType.SaleUploadProofOfTransfer,
    ActionType.SaleDocumentUploadActivity,
    ActionType.SaleUploadTransferProofId,
    ActionType.SaleBroadcastUploadBarcodes,
    ActionType.SaleBroadcastUploadETickets,
    ActionType.SaleBroadcastUploadTransferUrls,
    ActionType.SaleBroadcastUploadTransferProof,
    ActionType.SaleBroadcastUploadTransferProofId,
  ],
  [SaleActivityLogTab.Cost]: [
    ActionType.SaleCostUpdate,
    ActionType.SaleCostInsert,
    ActionType.SaleCostDelete,
  ],
  [SaleActivityLogTab.PrivateNotes]: [],
  [SaleActivityLogTab.Commission]: [ActionType.SaleCommissionsUpdate],
  [SaleActivityLogTab.Allocation]: [
    ActionType.SaleMarketplaceInsert,
    ActionType.SaleConfirm,
    ActionType.SaleDeallocate,
  ],
};

export const SALE_ACTIVITY_LOG_TAB_TO_AUDIT_ACTION_TYPES: Record<
  SaleActivityLogTab,
  AuditActionType[] | null
> = {
  [SaleActivityLogTab.All]: null,
  [SaleActivityLogTab.Delivery]: [],
  [SaleActivityLogTab.Cost]: [],
  [SaleActivityLogTab.PrivateNotes]: [
    AuditActionType.SaleInternalNotesInsert,
    AuditActionType.SaleInternalNotesUpdate,
  ],
  [SaleActivityLogTab.Commission]: [],
  [SaleActivityLogTab.Allocation]: [],
};

export const SALE_ACTIVITY_LOG_TAB_TO_CID: Record<
  SaleActivityLogTab,
  ContentId
> = {
  [SaleActivityLogTab.All]: ContentId.All,
  [SaleActivityLogTab.Delivery]: ContentId.Delivery,
  [SaleActivityLogTab.Cost]: ContentId.Cost,
  [SaleActivityLogTab.PrivateNotes]: ContentId.PrivateNote,
  [SaleActivityLogTab.Commission]: ContentId.Commission,
  [SaleActivityLogTab.Allocation]: ContentId.Allocation,
};

/*
 * Purchase order entity activity log constants
 */
export const enum PurchaseOrderActivityLogTab {
  All = TAB_ALL,
  Cost = 'cost',
  Payment = 'payment',
  TicketGroups = 'ticket group',
  PurchaseDetails = 'purchase detail',
  Commission = 'commission',
  PrivateNotes = 'privateNotes',
}

export const PURCHASE_ORDER_ACTIVITY_LOG_TAB_TO_ACTION_TYPES: Record<
  PurchaseOrderActivityLogTab,
  ActionType[] | null
> = {
  [PurchaseOrderActivityLogTab.All]: null,
  [PurchaseOrderActivityLogTab.Cost]: [
    ActionType.PurchaseCostDelete,
    ActionType.PurchaseCostInsert,
    ActionType.PurchaseCostUpdate,
    ActionType.PurchaseCostsMerge,
  ],
  [PurchaseOrderActivityLogTab.Payment]: [
    ActionType.PurchasePaymentDelete,
    ActionType.PurchasePaymentInsert,
    ActionType.PurchasePaymentUpdate,
    ActionType.PurchasePaymentsMerge,
  ],
  [PurchaseOrderActivityLogTab.TicketGroups]: [
    ActionType.PurchaseTicketGroupDelete,
    ActionType.PurchaseTicketGroupInsert,
    ActionType.PurchaseTicketGroupUpdate,
    ActionType.PurchaseTicketGroupsMerge,
  ],
  [PurchaseOrderActivityLogTab.PurchaseDetails]: [
    ActionType.PurchaseUpdate,
    ActionType.PurchaseInsert,
    ActionType.PurchaseCancel,
  ],
  [PurchaseOrderActivityLogTab.Commission]: [],
  [PurchaseOrderActivityLogTab.PrivateNotes]: [],
};

export const PURCHASE_ORDER_ACTIVITY_LOG_TAB_TO_AUDIT_ACTION_TYPES: Record<
  PurchaseOrderActivityLogTab,
  AuditActionType[] | null
> = {
  [PurchaseOrderActivityLogTab.All]: null,
  [PurchaseOrderActivityLogTab.Cost]: [],
  [PurchaseOrderActivityLogTab.Payment]: [],
  [PurchaseOrderActivityLogTab.TicketGroups]: [],
  [PurchaseOrderActivityLogTab.PurchaseDetails]: [],
  [PurchaseOrderActivityLogTab.Commission]: [
    AuditActionType.PurchaseCommissionInsert,
    AuditActionType.PurchaseCommissionUpdate,
  ],
  [PurchaseOrderActivityLogTab.PrivateNotes]: [
    AuditActionType.PurchaseInternalNotesInsert,
    AuditActionType.PurchaseInternalNotesUpdate,
  ],
};

export const PURCHASE_ORDER_ACTIVITY_LOG_TO_CID: Record<
  PurchaseOrderActivityLogTab,
  ContentId
> = {
  [PurchaseOrderActivityLogTab.All]: ContentId.All,
  [PurchaseOrderActivityLogTab.Cost]: ContentId.Cost,
  [PurchaseOrderActivityLogTab.Payment]: ContentId.Payment,
  [PurchaseOrderActivityLogTab.TicketGroups]: ContentId.TicketGroups,
  [PurchaseOrderActivityLogTab.PurchaseDetails]: ContentId.PurchaseDetails,
  [PurchaseOrderActivityLogTab.Commission]: ContentId.Commission,
  [PurchaseOrderActivityLogTab.PrivateNotes]: ContentId.PrivateNote,
};
