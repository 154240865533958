// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const withoutEmptyValues = (arr: any[]) => {
  return arr.filter(
    (val) =>
      val !== '' &&
      val !== null &&
      val !== undefined &&
      (Array.isArray(val)
        ? val.length !== undefined
          ? val.length > 0
          : true
        : true)
  );
};
