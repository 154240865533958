import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useGetEventMapConfig } from 'src/contexts/EventMapContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect, PosSelect } from 'src/core/POS/PosSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { useTicketTypes } from 'src/hooks/api/useTicketTypes';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { TICKET_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { posChangedField } from 'src/utils/posFieldUtils';
import { getConsecutiveSeatingsForQuantity } from 'src/utils/saleUtils';
import { isSectionTypeGeneralAdmission } from 'src/utils/ticketUtils';
import {
  DeliveryType,
  Event,
  Feature,
  ListingDetails,
  PosManagedMarketplaceSaleInput,
  Ticket,
  TicketType,
} from 'src/WebApiController';

type MarketplaceSaleInputTicket = Pick<
  PosManagedMarketplaceSaleInput,
  'section' | 'row' | 'seatFrom' | 'seatTo' | 'ticketType' | 'quantitySold'
>;

export const TicketSection = ({
  maxQuantity,
  deliveryType,
  disabled,
  tickets,
  event,
  listing,
  isEditingExisting,
}: {
  maxQuantity?: number;
  deliveryType?: DeliveryType;
  disabled?: boolean;
  tickets?: Ticket[];
  event?: Event | null;
  listing?: ListingDetails;
  isEditingExisting?: boolean;
}) => {
  const venueMapQuery = useGetEventMapConfig(
    event?.viagId,
    event?.viagId == null || listing?.sectionType != null
  );

  const hasAllocateNonConsecutiveSeatsFeature = useUserHasFeature(
    Feature.AllocateNonConsecutiveSeats
  );

  const requiredMsg = useContent(ContentId.Required);

  const { watch, getValues, register, formState, setValue, clearErrors } =
    useFormContext<MarketplaceSaleInputTicket>();
  const { section, row, seatFrom, seatTo, ticketType } = getValues();

  const quantitySold = watch('quantitySold');
  const sectionError = formState.errors.section?.message;
  const quantitySoldError = formState.errors.quantitySold?.message;
  const rowError = formState.errors.row?.message;
  const seatFromError = formState.errors.seatFrom?.message;
  const seatToError = formState.errors.seatTo?.message;
  const isMobile = useMatchMedia('mobile');

  const { ticketTypes } = useTicketTypes(deliveryType);

  const deliveryTypeToTicketTypesMap = useMemo(() => {
    const ticketTypeOptions = { ...TICKET_TYPE_TO_CID };
    if (deliveryType) {
      if (ticketTypes?.length) {
        Object.keys(ticketTypeOptions).forEach((k) => {
          const tt = k as TicketType;
          if (!ticketTypes.includes(tt)) {
            delete ticketTypeOptions[tt];
          }
        });

        // if ticket type is null or not exists in the options (because feature not allow)
        // default to 1st available ones
        if (
          ticketType?.value == null ||
          ticketTypeOptions[ticketType?.value] == null
        ) {
          setValue('ticketType', posChangedField(ticketTypes[0]));
        }
      }
    }

    return ticketTypeOptions;
  }, [deliveryType, setValue, ticketType, ticketTypes]);

  const [seatFromToOptions, setSeatFromToOptions] = useState<
    Record<number, number> | undefined
  >();
  const [seatFromOptions, setSeatFromOptions] = useState<
    Record<string, string>
  >({});

  const setSeatTo = useCallback(
    (quantitySold: number, seatFr: string, seatToOrdinal: number) => {
      if (quantitySold < 2) {
        setValue('seatTo', posChangedField(seatFr));
      } else {
        const t = tickets!.find((t) => t.lstOrd === seatToOrdinal);
        if (t) setValue('seatTo', posChangedField(t.seat));
      }
    },
    [setValue, tickets]
  );

  const canAllocateToNonConsecutiveSeats = useMemo(() => {
    if (!hasAllocateNonConsecutiveSeatsFeature) {
      return false;
    }

    let isSeatingGA = false;

    if (listing?.sectionType != null) {
      isSeatingGA = isSectionTypeGeneralAdmission(listing.sectionType);
    } else {
      const sections = venueMapQuery.data?.sections;
      if (sections != null && section != null) {
        isSeatingGA = isSectionTypeGeneralAdmission(
          sections.find(
            (s) =>
              s.name.toLocaleLowerCase() === section?.value?.toLocaleLowerCase()
          )?.sectionType
        );
      }
    }

    return isSeatingGA;
  }, [
    hasAllocateNonConsecutiveSeatsFeature,
    listing?.sectionType,
    section,
    venueMapQuery.data?.sections,
  ]);

  useEffect(() => {
    const result = getConsecutiveSeatingsForQuantity(
      tickets,
      quantitySold?.value,
      canAllocateToNonConsecutiveSeats
    );
    if (!result || listing?.isSeatSaver) return;

    if (result.maxAvailableQuantity < (quantitySold?.value ?? 0)) {
      // Current quantity sold not valid, so set new max possible quantity sold and quit
      // the next cycle of the use effect will recalculate the options
      setValue('quantitySold', posChangedField(result.maxAvailableQuantity));
      return;
    }

    const newSeatFromToOptions = result.result;

    if (
      tickets?.length &&
      newSeatFromToOptions &&
      !isEqual(newSeatFromToOptions, seatFromToOptions)
    ) {
      setSeatFromToOptions(newSeatFromToOptions);
      const seatFromOrdinals = Object.keys(newSeatFromToOptions);
      const newSeatFromOptions = seatFromOrdinals.reduce(
        (r, c) => {
          const t = tickets!.find((t) => t.lstOrd === parseInt(c));
          r[(t!.lstOrd ?? t!.ord).toString()] = t!.seat;
          return r;
        },
        {} as Record<string, string>
      );

      if (!seatFrom?.value) {
        const seatFr = Object.values(newSeatFromOptions)[0];
        setValue('seatFrom', posChangedField(seatFr));

        const seatToOrdinal =
          newSeatFromToOptions![parseInt(seatFromOrdinals[0])];
        setSeatTo(quantitySold!.value!, seatFr, seatToOrdinal);
      } else {
        // Either the seatFr is no longer valid due to quantity change, or seatTo, need to revalidate both
        const seatFromTicket = tickets!.find((t) => t.seat === seatFrom.value);

        let seatFr = seatFromTicket!.seat;
        let seatFromOrdinal = seatFromTicket!.lstOrd ?? seatFromTicket!.ord;
        if (!seatFromOrdinals.includes(seatFromOrdinal.toString())) {
          seatFr = Object.values(newSeatFromOptions)[0];
          setValue('seatFrom', posChangedField(seatFr));
          seatFromOrdinal = parseInt(seatFromOrdinals[0]);
        }

        const seatToOrdinal = newSeatFromToOptions![seatFromOrdinal];
        setSeatTo(quantitySold!.value!, seatFr, seatToOrdinal);
      }

      setSeatFromOptions(newSeatFromOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantitySold]);

  const ticketFrom = useMemo(() => {
    return tickets?.find((t) => t.seat === seatFrom?.value);
  }, [tickets, seatFrom?.value]);

  return (
    <DetailSection name={<Content id={ContentId.Tickets} />}>
      <SectionContent numOfColumns={isMobile ? 1 : 2}>
        <DetailGroup>
          <PosFormField
            errors={quantitySoldError}
            label={<Content id={ContentId.Quantity} />}
          >
            <PosTextField
              rootProps={{
                state: getTextFieldState(quantitySoldError),
              }}
              // We disable if editing the Sale - existing sale should not be
              // allowed to edit quantity as that mess up the ticket allocations
              disabled={
                isEditingExisting || disabled || (maxQuantity ?? 0) <= 0
              }
              type="number"
              inputMode="numeric"
              value={quantitySold?.value ?? ''}
              onChange={(e) => {
                const v = parseInt(e.target.value);
                if (v > 0 && v <= maxQuantity!) {
                  clearErrors('quantitySold');

                  if (v !== quantitySold?.value) {
                    setValue('quantitySold', posChangedField(v));
                  }
                } else {
                  setValue('quantitySold', null);
                }
              }}
            />
          </PosFormField>
          <PosFormField
            errors={sectionError}
            label={<Content id={ContentId.TicketType} />}
          >
            <PosEnumSelect
              disabled={disabled}
              style={{ width: '100%' }}
              valueOptionsContent={deliveryTypeToTicketTypesMap}
              value={ticketType?.value}
              onChange={(t: TicketType | null) => {
                if (t && t !== ticketType?.value) {
                  setValue('ticketType', posChangedField(t));
                }
              }}
            />
          </PosFormField>
        </DetailGroup>
        <DetailGroup>
          <PosFormField
            errors={sectionError}
            label={<Content id={ContentId.Section} />}
          >
            <PosTextField
              rootProps={{
                state: getTextFieldState(sectionError),
              }}
              disabled={true}
              spellCheck={false}
              {...register('section.value', {
                required: requiredMsg,
                onChange: ({ target: { value } }) => {
                  setValue('section', posChangedField(value));
                },
              })}
            />
          </PosFormField>
          <PosFormField
            label={<Content id={ContentId.Row} />}
            errors={rowError}
          >
            <PosTextField
              spellCheck={false}
              disabled={true}
              rootProps={{
                state: getTextFieldState(rowError),
              }}
              {...register('row.value', {
                onChange: ({ target: { value } }) => {
                  setValue('row', posChangedField(value));
                },
              })}
            />
          </PosFormField>
          <PosFormField
            label={<Content id={ContentId.SeatFrom} />}
            errors={seatFromError}
          >
            <PosSelect
              disabled={disabled || !seatFromToOptions}
              style={{ width: '100%' }}
              hasErrors={Boolean(getTextFieldState(seatFromError))}
              valueOptionsContent={seatFromOptions}
              placeholderText={ContentId.SelectSeatFrom}
              value={ticketFrom?.lstOrd?.toString()}
              onChange={(newSeatOrdinal) => {
                const ord = parseInt(newSeatOrdinal);
                if (ord >= 0) {
                  const fromTick = tickets!.find((t) => t.lstOrd === ord);
                  const toTick = tickets!.find(
                    (t) => t.lstOrd === seatFromToOptions![ord]
                  );

                  setValue('seatFrom', posChangedField(fromTick!.seat));
                  setValue('seatTo', posChangedField(toTick!.seat));
                }
              }}
            />
          </PosFormField>
          <PosFormField
            label={<Content id={ContentId.SeatTo} />}
            errors={seatToError}
          >
            <PosTextField
              spellCheck={false}
              disabled={true} // This is auto-field based on SeatFrom and Quantity - so always disabled this
              {...register('seatTo.value', {
                onChange: ({ target: { value } }) => {
                  setValue('seatTo', posChangedField(value));
                },
              })}
            />
          </PosFormField>
        </DetailGroup>
      </SectionContent>
    </DetailSection>
  );
};
