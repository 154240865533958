import * as styles from './FulfillmentProviders.css';
import { Card } from "reactstrap";
import { Switch } from "src/core/ui";
import { useCallback, useState } from "react";
import { FulfillmentProviderClient } from "src/WebApiController";
import { tryInvokeApi } from "src/utils/tryExecuteUtils";
import { useAppContext } from "src/contexts/AppContext";
import {useErrorBoundaryContext} from "src/contexts/ErrorBoundaryContext";

interface FulfillmentProviderCardProps {
    logo: JSX.Element | null;
    fulfillmentProviderSellerUserId: string;
    name: string;
    description: string;
    enabled: boolean;
}

export const FulfillmentProviderCard: React.FC<FulfillmentProviderCardProps> = ({ logo, fulfillmentProviderSellerUserId, name, description, enabled }) => {
    const { activeAccountWebClientConfig } = useAppContext();
    const [isSwitchChecked, setIsSwitchChecked] = useState(enabled);
    const { trackError } = useErrorBoundaryContext();

    const toggleFulfillmentProviderSetting = useCallback(
        async (isChecked: boolean) => {
            const originalState = isSwitchChecked;
            setIsSwitchChecked(isChecked);

            await tryInvokeApi(
                async () => {
                    const fulfillmentProviderClient = new FulfillmentProviderClient(
                        activeAccountWebClientConfig
                    );

                    const succeeded = await fulfillmentProviderClient.toggleFulfillmentProviderSetting(
                        fulfillmentProviderSellerUserId,
                        isChecked
                    );

                    if (!succeeded) {
                        setIsSwitchChecked(originalState);
                    }
                },
                (error) => {
                    setIsSwitchChecked(originalState);
                    trackError('FulfillmentProviderClient.ToggleFulfillmentProviderSetting', error);
                }
            );
        },
        [activeAccountWebClientConfig, fulfillmentProviderSellerUserId, isSwitchChecked]
    );

    return (
        <Card className={styles.card}>
            <div>
                <div className={styles.header}>
                    <span>
                        {logo}
                    </span>
                    <span className={styles.providerName}>
                        {name}
                    </span>
                </div>
                <div className={styles.providerDescription}>
                    {description}
                </div>
            </div>
            <div className={styles.footer}>
                <Switch
                    checked={isSwitchChecked}
                    key={fulfillmentProviderSellerUserId}
                    onChange={(e) => e.stopPropagation()}
                    onCheckedChange={(isChecked) => {
                        toggleFulfillmentProviderSetting(isChecked);
                    }}
                />
            </div>
        </Card>
    );
};
