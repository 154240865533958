import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function FoldIcon(props: IconsProps) {
  return (
    <SvgWrapper aria-label="FoldIcon" {...props}>
      <path
        fill="inherit"
        d="M4.498 17.663L2 15.331 11.993 6l.007.007.007-.007L22 15.33l-2.498 2.333L12 10.66z"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
