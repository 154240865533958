import { Row } from '@tanstack/react-table';
import clsx from 'clsx';
import { MutableRefObject, ReactNode, useCallback, useMemo } from 'react';
import { GroupedVirtuoso } from 'react-virtuoso';
import { PageSection } from 'src/components/common/PageSection';
import { useColumnResizingContext } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { TableTd } from 'src/tables/Table/components/TableTd';
import { PosGroupedVirtuosoContext } from 'src/tables/Table/GroupedVirtuosoTable/PosGroupedVirtuosoContext';
import { PosGroupedVirtuosoGroup } from 'src/tables/Table/GroupedVirtuosoTable/PosGroupedVirtuosoGroup';
import { PosGroupedVirtuosoItem } from 'src/tables/Table/GroupedVirtuosoTable/PosGroupedVirtuosoItem';
import { PosGroupedVirtuosoList } from 'src/tables/Table/GroupedVirtuosoTable/PosGroupedVirtuosoList';
import { PosGroupedVirtuosoScroller } from 'src/tables/Table/GroupedVirtuosoTable/PosGroupedVirtuosoScroller';
import { groupedVirtuosoWrapper } from 'src/tables/Table/GroupedVirtuosoTable/PosGroupedVirtuosoTable.css';
import { useGetVirtuosoGroups } from 'src/tables/Table/GroupedVirtuosoTable/useGetVirtuosoGroups';
import {
  cellNoWrapStyle,
  groupRow,
  groupRowFirstCell,
  groupValue,
} from 'src/tables/Table/GroupRow.css';

import { PosTableData } from '../Table';

interface GroupedVirtuosoTableProps<T extends PosTableData> {
  context: PosGroupedVirtuosoContext<T>;
  renderRowContent: (row: Row<T>) => ReactNode;
  scrollRef?: MutableRefObject<HTMLElement | null>;
}

const groupedVirtuosoComponents = {
  List: PosGroupedVirtuosoList,
  Group: PosGroupedVirtuosoGroup,
  Item: PosGroupedVirtuosoItem,
  Scroller: PosGroupedVirtuosoScroller,
};

export const PosGroupedVirtuosoTable = <T extends PosTableData>({
  context,
  renderRowContent,
  scrollRef,
}: GroupedVirtuosoTableProps<T>) => {
  const { table } = context;
  const isGrouping = table.getState().grouping.length > 0;
  const isLargeDesktop = useMatchMedia('largeDesktop');
  const { isResizeEnabled } = useColumnResizingContext();

  const { groupCounts, rowHeaderGroups, allSingleRows } = useGetVirtuosoGroups({
    table,
  });

  const groupedContext = useMemo<PosGroupedVirtuosoContext<T>>(
    () => ({
      ...context,
      tableRows: allSingleRows,
    }),
    [allSingleRows, context]
  );

  const groupContentRenderer = useCallback(
    (groupIndex: number) => {
      const rowsGroup: Row<T> = rowHeaderGroups[groupIndex];

      // Used when there is no grouping applied
      if (!rowHeaderGroups.length) {
        return <td />;
      }

      return (
        <>
          {rowsGroup.getVisibleCells().map((cell, index) => {
            const isFirstCell = index === 0;
            return (
              <TableTd
                data-group={true} // Keep this to not apply padding
                key={cell.id}
                isLargeDesktop={isLargeDesktop}
                isGrouping={isGrouping}
                isResizeEnabled={isResizeEnabled}
                cell={cell}
                classNames={groupRow}
                tableCellStyle={isFirstCell ? cellNoWrapStyle : undefined}
              >
                <PageSection.Header
                  headerClassName={clsx({
                    [groupRowFirstCell]: isFirstCell,
                  })}
                >
                  {isFirstCell ? (
                    <span className={groupValue}>
                      {rowsGroup.groupingValue as string}
                    </span>
                  ) : null}
                </PageSection.Header>
              </TableTd>
            );
          })}
        </>
      );
    },
    [isGrouping, isLargeDesktop, isResizeEnabled, rowHeaderGroups]
  );

  const itemContentRenderer = useCallback(
    (globalIndex: number) => {
      return renderRowContent(allSingleRows[globalIndex]);
    },
    [allSingleRows, renderRowContent]
  );

  const setScrollerRef = useCallback(
    (el: unknown) => {
      if (el instanceof HTMLElement && scrollRef) {
        scrollRef.current = el;
      }
    },
    [scrollRef]
  );

  return (
    <GroupedVirtuoso
      className={groupedVirtuosoWrapper}
      components={groupedVirtuosoComponents}
      groupCounts={groupCounts}
      context={groupedContext}
      scrollerRef={setScrollerRef}
      groupContent={groupContentRenderer}
      itemContent={itemContentRenderer}
    />
  );
};
