import { ComponentProps } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export const CancelButton = ({
  textContentId = ContentId.Cancel,
  variant,
  ...props
}: { textContentId?: ContentId } & Omit<
  ComponentProps<typeof Button>,
  'children'
>) => {
  const { isDarkMode } = useSiteTheme();
  const effectiveVariant = variant ?? (isDarkMode ? 'outlineGray' : 'outline');
  return (
    <Button variant={effectiveVariant} {...props}>
      <Content id={textContentId} />
    </Button>
  );
};
