import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  EventMapContextProvider,
  useEventMapContext,
} from 'src/contexts/EventMapContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { vars } from 'src/core/themes';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useAvailableAutopricingModes } from 'src/hooks/useAvailableAutopricingModes';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import { ProcessingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import { AutoPricingOutlierMode } from 'src/WebApiController';

import {
  ListingPricingInput,
  ListingPricingSettingsCellProps,
} from '../ListingPriceForm.types';
import * as styles from './Cell.css';

export const OutlierModeCell: React.FC<ListingPricingSettingsCellProps> = (
  props
) => {
  return (
    <EventMapContextProvider event={props.event} doNotGetScoreOverrides>
      <OutlierModeCellContent {...props} />
    </EventMapContextProvider>
  );
};

const OutlierModeCellContent: React.FC<ListingPricingSettingsCellProps> = ({
  listing,
  editable,
  event,
}) => {
  const { watch, formState, getFieldState, setValue } =
    useFormContext<ListingPricingInput>();

  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);

  const { outlierMode, canAutoPrice, onOutlierChange } =
    useListingAutoPricingSettings(listing);

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingPrice = watch('isSubmitting');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const isDisabled =
    listing.isDeleted ||
    !canAutoPrice ||
    listingSelection.isSingleGroupMultiSelect ||
    fieldIsSubmitting ||
    !editable;

  const submittingField = watch('submittingField');
  const isSubmittingField =
    fieldIsSubmitting && submittingField === 'outlierMode';

  const { activeConfigOverride, venueMapInfo } = useEventMapContext();

  const scores = getCompleteEventConfigScoreOverrides(
    venueMapInfo?.sectionScores,
    activeConfigOverride?.scoreOverrides,
    false
  );
  const { outlierModes, outlierModesDisabled } = useAvailableAutopricingModes(
    true,
    null,
    null,
    Boolean(scores?.some((s) => s.score))
  );

  const onUpdateOutlierMode = useCallback(
    (value: AutoPricingOutlierMode | null) => {
      if (!value || value === outlierMode) {
        return;
      }
      if (value === formState.defaultValues?.outlierMode) {
        // reset too preview settings
        onOutlierChange({
          outlierModeNew: value,
          outlierKthLowestLimitNew:
            formState.defaultValues?.outlierKthLowestLimit,
          outlierKthLowestLimitAbsoluteSpacingNew:
            formState.defaultValues?.outlierKthLowestLimitAbsoluteSpacing,
          outlierKthLowestLimitRelativeSpacingNew:
            formState.defaultValues?.outlierKthLowestLimitRelativeSpacing,
          outlierStandardDeviationsNew:
            formState.defaultValues?.outlierStandardDeviations,
        });
      } else {
        onOutlierChange({
          outlierModeNew: value,
        });
      }
      // Only submit if Enter is hit in the box that has changes
      setValue('isSubmittingPricingSettings', true);
      setValue('isSubmittingRowIndex', rowIndex);
      setValue('submittingField', 'outlierMode');
    },
    [
      formState.defaultValues?.outlierKthLowestLimit,
      formState.defaultValues?.outlierKthLowestLimitAbsoluteSpacing,
      formState.defaultValues?.outlierKthLowestLimitRelativeSpacing,
      formState.defaultValues?.outlierMode,
      formState.defaultValues?.outlierStandardDeviations,
      onOutlierChange,
      outlierMode,
      rowIndex,
      setValue,
    ]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <PosFormField
        errors={getFieldState('outlierMode', formState)?.error?.message}
        showErrorsInline
        className={styles.formFieldContainer}
      >
        {isSubmittingField ? (
          <div className="operation-button">
            <RotatingWrapper>
              <ProcessingIcon size={vars.iconSize.m} />
            </RotatingWrapper>
          </div>
        ) : (
          <PosEnumSelect
            className={styles.formSelectField}
            disabled={isDisabled}
            value={outlierMode}
            valueOptionsContent={outlierModes}
            valueOptionsDisabled={outlierModesDisabled}
            enableEmptySelection={false}
            placeholderText={ContentId.SkipLogic}
            onClick={(e) => e.stopPropagation()}
            onChange={onUpdateOutlierMode}
          />
        )}
      </PosFormField>
    </TableCellDiv>
  );
};
