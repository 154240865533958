import { useEffect } from 'react';
import { SidePanelFilters } from 'src/components/Events/EventPage/SidePanel/SidePanelFilters';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useQuickFilters } from 'src/hooks/useQuickFilters';
import { ListingQuery, UserSetting } from 'src/WebApiController';

import { useDefaultListingFilter } from './hooks/useDefaultListingFilter';

export function ListingSideTableFilterBar() {
  const {
    filterQuery: listingQuery,
    initialQuery,
    setFilterQuery,
    setTempQuery,
  } = useFilterQueryContext<ListingQuery>();

  useEffect(() => {
    setTempQuery(listingQuery);
  }, [listingQuery, setTempQuery]);

  const defaultQuickFilters = useDefaultListingFilter({
    initialQuery,
    listingQuery,
  });

  const { quickFilters } = useQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStateInventory,
    customQuickFiltersSetting: UserSetting.QuickFiltersCustomInventory,
    defaultQuickFilters,
  });

  return (
    <SidePanelFilters
      setFilterQuery={setFilterQuery}
      quickFilters={quickFilters}
      initialQuery={initialQuery}
    />
  );
}
