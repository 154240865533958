import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { AddressClient } from 'src/WebApiController';

export function useStateProvinces() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const query = useQuery({
    queryKey: [
      'getStateProvinces',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return await new AddressClient(
        activeAccountWebClientConfig
      ).getStateProvinces();
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('AddressClient.getStateProvinces', error);
      },
    },
  });

  return query;
}
