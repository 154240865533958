import { Store } from './Store';

const memObj: Record<string, unknown> = {};

export const buildKey = (key: string, entityName: string) =>
  `${entityName}_${key}`;

const set = <T>(key: string, entityName: string, value: T) => {
  memObj[buildKey(key, entityName)] = value;
};

const get = <T>(key: string, entityName: string): T | null =>
  memObj[buildKey(key, entityName)] as T;

const contains = (key: string, entityName: string): boolean =>
  buildKey(key, entityName) in memObj;

const truncate = <T>(key: string, entityName: string, initVal?: T) => {
  memObj[buildKey(key, entityName)] = initVal;
};

const isEnabled = () => true;

export const memoryStore: Store = { get, set, truncate, contains, isEnabled };
