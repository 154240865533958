import { useEffect, useMemo } from 'react';
import { useKeyPress } from 'react-use';
import {
  bulKActionsHighlightLetter,
  toggleButton,
} from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggle.css';
import { useBulkEditHubContext } from 'src/contexts/BulkEditHubContext';
import { useContent } from 'src/contexts/ContentContext';
import { useGenericDialogContext } from 'src/contexts/GenericDialogContext/GenericDialogContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { vars } from 'src/core/themes';
import { Button, Tooltip } from 'src/core/ui';
import { MultiEditCheckboxChecked } from 'src/svgs/MultiEditCheckboxChecked';
import { MultiEditCheckboxUnchecked } from 'src/svgs/MultiEditCheckboxUnchecked';
import { ContentId } from 'src/utils/constants/contentId';
import { getCurrentLanguage } from 'src/utils/localeUtils';

interface MultiSelectionToggleProps {
  isToggled: boolean;
  onChange: (e: React.MouseEvent) => void;
}

export const MultiSelectionToggle = ({
  onChange,
  isToggled,
}: MultiSelectionToggleProps) => {
  const { mainDialogOpened } = useBulkEditHubContext();
  const { isDialogOpen } = useGenericDialogContext();
  const isEnglishLanguage = getCurrentLanguage().startsWith('en-');
  const tooltipText = useContent(ContentId.SelectItemsForBulkEdit);
  const isEscKeyPressed = useKeyPress('Escape')[0];
  const { setSelectionMode, selectionMode } = useMultiSelectionContext();
  const isToggleOn = !!selectionMode?.mode;

  const tooltipTextHighlighted = useMemo(() => {
    if (!isEnglishLanguage) {
      return tooltipText;
    }
    const parts = tooltipText.split(new RegExp('S', 'g'));
    return (
      <span>
        {parts[0]}
        <span className={bulKActionsHighlightLetter}>S</span>
        {parts[1]}
      </span>
    );
  }, [isEnglishLanguage, tooltipText]);

  useEffect(() => {
    if (isEscKeyPressed && isToggleOn && !mainDialogOpened && !isDialogOpen) {
      setSelectionMode();
    }
  }, [
    isEscKeyPressed,
    setSelectionMode,
    isToggleOn,
    mainDialogOpened,
    isDialogOpen,
  ]);

  return (
    <Tooltip
      triggerContent={
        <Button
          variant="text"
          shape="none"
          size="unset"
          onClick={onChange}
          className={toggleButton}
        >
          {isToggled ? (
            <MultiEditCheckboxChecked size={vars.iconSize.m} />
          ) : (
            <MultiEditCheckboxUnchecked size={vars.iconSize.m} />
          )}
        </Button>
      }
    >
      {tooltipTextHighlighted}
    </Tooltip>
  );
};
