import { isEqual } from 'lodash-es';
import { Point } from 'paper';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { BezierGraph, SerializedSegment } from 'src/core/POS/BezierGraph';
import { CalcOutputFn } from 'src/core/POS/BezierGraph/BezierGraphManager';
import { vars } from 'src/core/themes';
import { CrossIcon } from 'src/svgs/Viagogo';
import { roundToPrecision } from 'src/utils/numberFormatter';

import * as styles from './RelativeScoreGraph.css';

export const ROW_HEIGHT = styles.ROW_HEIGHT;
export const ROW_GAP_HEIGHT = styles.ROW_GAP_HEIGHT;

const NEGATIVE_TEXT_COLOR = '#db3f84';
const POSITIVE_TEXT_COLOR = '#33b2b1';

export type RelativeScoreGraphProps = {
  children?: ReactNode;
};

export function RelativeScoreGraph({ children }: RelativeScoreGraphProps) {
  return <div className={styles.root}>{children}</div>;
}

export type RelativeScoreGraphRowProps = {
  label?: ReactNode;
  valueDisplay?: ReactNode;
  /**
   * Percentage value from 0 to 1.
   *
   * eg. `0.5` for 50%
   */
  value?: number;
  showRemoveButton?: boolean;
  onRemove?: () => void;
};

export function RelativeScoreGraphRow({
  label,
  valueDisplay,
  value,
  showRemoveButton = false,
  onRemove,
}: RelativeScoreGraphRowProps) {
  const isBase = value === 0;
  const negativeWidth =
    !isBase && value != null && value < 0 ? `${-value * 100}%` : 0;
  const positiveWidth =
    !isBase && value != null && value > 0 ? `${value * 100}%` : 0;
  return (
    <div className={styles.row}>
      <div className={styles.rowLabel}>{label}</div>
      <div className={styles.bar}>
        <div className={styles.barNegativeBackground}>
          <div
            className={styles.barNegativeForeground}
            style={{ width: negativeWidth }}
          />
        </div>
        <div className={styles.barPositiveBackground}>
          <div
            className={styles.barPositiveForeground}
            style={{ width: positiveWidth }}
          />
        </div>
      </div>
      <div
        style={{
          minWidth: '60px',
          color: isBase
            ? vars.color.textBrand
            : value != null && value > 0
            ? POSITIVE_TEXT_COLOR
            : NEGATIVE_TEXT_COLOR,
        }}
      >
        {valueDisplay}
      </div>
      <div>
        {showRemoveButton && (
          <div className={styles.hiddenButton}>
            <CrossIcon
              size={vars.iconSize.s}
              withHoverEffect
              onClick={onRemove}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const PATH_COLOR = '#33b2b1';

export type RelativeScoreGraphBezierProps = {
  rowCount: number;
  initialSegments: SerializedSegment[];
  onChange?: (ev: { values: number[]; segments: SerializedSegment[] }) => void;
};

export function RelativeScoreGraphBezier({
  initialSegments,
  rowCount,
  onChange,
}: RelativeScoreGraphBezierProps) {
  const [segments, setSegments] = useState(initialSegments);

  useEffect(() => {
    if (!isEqual(segments, initialSegments)) {
      setSegments(initialSegments);
    }
  }, [initialSegments, segments]);

  const calcScoreOutputRef = useRef<CalcOutputFn>();
  const exportScorePathRef = useRef<() => SerializedSegment[]>();

  const onUpdateRowScores = (segments: paper.Segment[]) => {
    const calcScoreOutput = calcScoreOutputRef.current;
    if (calcScoreOutput) {
      const totalHeight =
        rowCount * ROW_HEIGHT + (rowCount - 1) * ROW_GAP_HEIGHT;
      const centerToCenterHeight = ROW_HEIGHT + ROW_GAP_HEIGHT;
      const isBaseRow = (x: number) => {
        const baseRowSegmentX = segments[1]?.point.x;
        if (baseRowSegmentX != null) {
          const xMin = Math.max(
            0,
            x - (centerToCenterHeight / 2 / totalHeight) * 100
          );
          const xMax = Math.min(
            100,
            x + (centerToCenterHeight / 2 / totalHeight) * 100
          );
          if (baseRowSegmentX >= xMin && baseRowSegmentX < xMax) {
            return true;
          }
        }
        return false;
      };

      const values: number[] = [];
      for (let i = 0; i < rowCount; i++) {
        const x =
          ((ROW_HEIGHT / 2 + i * centerToCenterHeight) / totalHeight) * 100;
        const y = isBaseRow(x) ? 50 : calcScoreOutput(x);
        values.push(roundToPrecision((y * 2 - 100) / 100, 3));
      }
      onChange?.({ values, segments: exportScorePathRef.current?.() ?? [] });
    }
  };

  return (
    <div className={styles.bezierGraphWrapper}>
      <BezierGraph
        segments={segments}
        minLabel=""
        maxLabel=""
        pathColor={PATH_COLOR}
        calcOutputRef={calcScoreOutputRef}
        exportPathRef={exportScorePathRef}
        fixedSegmentAmount
        hideAxes
        hideActionLabel
        horizontalGraph
        // Limit to move the middle point in the middle line
        deltaTransform={(segment, localDelta) => {
          return segment.isFirst() || segment.isLast()
            ? new Point(0, localDelta.y)
            : new Point(localDelta.x, 0);
        }}
        onPathSetFromSegments={({ segments }) => {
          onUpdateRowScores(segments);
        }}
        onDragEnd={({ isPathValid, segments }) => {
          if (isPathValid) {
            onUpdateRowScores(segments);
          }
        }}
      />
    </div>
  );
}
