import { MultiSelectActionsContainer } from 'src/components/common/MultiSelect/MultiSelectActionsContainer';
import { SettingsActions } from 'src/components/Purchases/BulkActions/PurchasesTileBulkActions/Actions/SettingsActions';
import { TagsActions } from 'src/components/Purchases/BulkActions/PurchasesTileBulkActions/Actions/TagsActions';

import { AccountingActions } from './Actions/AccountingActions';

interface PurchasesTileBulkActionsProps {
  groupId: string;
  isEventView?: boolean;
}

export const PurchasesTileBulkActions = ({
  groupId,
  isEventView,
}: PurchasesTileBulkActionsProps) => {
  return (
    <MultiSelectActionsContainer>
      <TagsActions groupId={groupId} isEventView={isEventView} />
      <SettingsActions groupId={groupId} isEventView={isEventView} />
      <AccountingActions groupId={groupId} isEventView={isEventView} />
    </MultiSelectActionsContainer>
  );
};
