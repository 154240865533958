import 'bootstrap/dist/css/bootstrap.css';
import './index.css';

import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { router } from './app/AppRoutes';
import { initSentry } from './utils/sentry';
import { AdminClient } from './WebApiController';

new AdminClient({})
  .getAppContext()
  .then((appContext) => initSentry(appContext));

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<RouterProvider router={router} />);
