import { ColumnDef } from '@tanstack/react-table';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { vars } from 'src/core/themes';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { ContentId } from 'src/utils/constants/contentId';

import {
  PreviewColumn,
  PricingSettingsPreviewColumnId,
} from './PricingSettingsPreview.types';

export const PricingSettingsPreviewColumns: ColumnDef<PreviewColumn>[] = [
  {
    id: PricingSettingsPreviewColumnId.Setting,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content id={ContentId.Settings} />
      </TableHeaderCellDiv>
    ),
    minSize: 80,
    maxSize: 150,
    accessorFn: (data) => data.setting,
    cell: ({ getValue }) => {
      const value = getValue<ContentId>();

      return (
        <TableCellDiv align="left" style={{ paddingLeft: vars.spacing.sm }}>
          <Content id={value} />
        </TableCellDiv>
      );
    },
    enableSorting: false,
  },
  {
    id: PricingSettingsPreviewColumnId.SettingFrom,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content id={ContentId.From} />
      </TableHeaderCellDiv>
    ),
    minSize: 80,
    accessorFn: (data) => data.from,
    cell: ({ getValue }) => {
      const value = getValue<string>();

      return <TableCellDiv>{value}</TableCellDiv>;
    },
    enableSorting: false,
  },
  {
    id: PricingSettingsPreviewColumnId.SettingTo,
    header: () => (
      <TableHeaderCellDiv align="left">
        <Content id={ContentId.To} />
      </TableHeaderCellDiv>
    ),
    minSize: 80,
    accessorFn: (data) => data.to,
    cell: ({ getValue }) => {
      const value = getValue<string>();

      return <TableCellDiv>{value}</TableCellDiv>;
    },
    enableSorting: false,
  },
  {
    id: PricingSettingsPreviewColumnId.Checkbox,
    header: () => (
      <TableHeaderCellDiv align="right">
        <Content id={ContentId.Apply} />
      </TableHeaderCellDiv>
    ),
    size: 80,
    accessorFn: (data) => data,
    cell: ({ getValue }) => {
      const { include, onChange } = getValue<PreviewColumn>();

      return (
        <TableCellDiv align="right" style={{ paddingRight: vars.spacing.sm }}>
          <Checkbox
            checked={include}
            onChange={() => {
              onChange(!include);
            }}
          />
        </TableCellDiv>
      );
    },
    enableSorting: false,
  },
];
