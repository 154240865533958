import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const AddFileIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper
      {...props}
      viewBox="0 0 24 24" // The viewbox here is important as it won't display properly without this specific value
    >
      <path
        d="M15.189 2l5.186 5.184v1.143h-6.38V3.633H5.146v16.734H18.73V9.143l1.646.816V22H3.5V2h11.689zm-2.7 8.125v2.574h2.573V13.8H12.49v2.574h-1.103v-2.574H8.813V12.7h2.573v-2.574h1.103zm3.153-5.35v1.919h1.934l-1.934-1.918z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
};
