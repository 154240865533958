import { useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { vars } from 'src/core/themes';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { FullPageIcon } from 'src/svgs';
import { EventIdQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature } from 'src/WebApiController';
import { Event } from 'src/WebApiController';

export const FullPageEventAction = ({ event }: { event: Event }) => {
  const hasFullPageEventViewFeature = useUserHasFeature(
    Feature.FullPageEventView
  );
  const navigate = useNavigate();
  const { mainRoute } = useAppContext();

  let searchParams = '';
  const url = new URL(window.location.href);
  url.searchParams.set(EventIdQueryParam, event.viagVirtualId);
  searchParams = `?${url.searchParams.toString()}`;

  let eventUrl = '';
  if (mainRoute === MainRoute.Sales) {
    eventUrl = `/sales/event${searchParams}`;
  } else {
    eventUrl = `/purchases/event${searchParams}`;
  }

  const onViewClick = useCallback(
    (eventUrl: string) => {
      if (event) {
        window.location.replace(`#${event.viagVirtualId}`);
        setTimeout(() => {
          navigate(eventUrl, {
            state: {
              returnUrl: `${location.pathname}${location.search}#${event.viagVirtualId}`,
            },
          });
        }, 10);
      }
    },
    [event, navigate]
  );

  if (!hasFullPageEventViewFeature) {
    return null;
  }

  return (
    <Link
      to={eventUrl}
      style={{ height: vars.iconSize.l }}
      onClick={(e) => {
        if (e.button === 0 && e.getModifierState('Control') === false) {
          // Perform like a button click when it's a left-click (and no Ctrl)
          // Else the click will be whatever <a /> does
          e.preventDefault();
          e.stopPropagation();

          onViewClick(eventUrl);
        }
      }}
    >
      <IconButton
        icon={
          <FullPageIcon
            fill={vars.color.textBrand}
            size={vars.iconSize.m}
            withHoverEffect
          />
        }
        titleContentId={ContentId.OpenEventPage}
      />
    </Link>
  );
};
