import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { SellerRoleInfo, SellerRoleInput } from 'src/WebApiController';

import { mapToSellerRoleInput } from './SellerRoleModal.utils';
import { SellerRoleModalBody } from './SellerRoleModalBody';

export const SellerRoleModal = ({
  roleInfo,
}: {
  roleInfo?: SellerRoleInfo | null;
}) => {
  const { allActiveUserInfos } = useSellerAccountContext();

  const userIdsOfRoles = useMemo(() => {
    if (!roleInfo?.id || !allActiveUserInfos) return null;

    const usersOfRole =
      roleInfo &&
      Object.values(allActiveUserInfos).filter((u) =>
        u.roles.includes(roleInfo.id)
      );
    return usersOfRole?.map((u) => u.userId);
  }, [allActiveUserInfos, roleInfo]);

  const methods = useForm<SellerRoleInput>({
    defaultValues: mapToSellerRoleInput(roleInfo, userIdsOfRoles),
  });

  useEffect(() => {
    if (
      (roleInfo?.id && (methods.formState.defaultValues?.id ?? 0) < 0) ||
      userIdsOfRoles?.length !==
        methods.formState.defaultValues?.userIds?.length
    ) {
      methods.reset(mapToSellerRoleInput(roleInfo, userIdsOfRoles));
    }
  }, [methods, roleInfo, userIdsOfRoles]);

  return (
    <FormProvider {...methods}>
      <SellerRoleModalBody {...methods} roleInfo={roleInfo} />
    </FormProvider>
  );
};
