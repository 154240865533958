import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { FormPage } from 'src/core/POS/FormPage';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Radio, RadioGroup, Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { CheckboxContainer, FieldWrapper, FormRow } from 'src/modals/common';
import { fieldText } from 'src/modals/common/Purchase/Purchase.css';
import { TagIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getAllInPriceFromListPrice,
  getListPriceFromAllinPrice,
} from 'src/utils/inventoryUtils';
import { formatCurrency } from 'src/utils/numberFormatter';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { Marketplace } from 'src/WebApiController';

import { CostType } from '../TicketGroupInput';

export const CostSection = ({
  ticketGroupIndex,
  disabled,
}: {
  ticketGroupIndex: number;
  disabled?: boolean;
}) => {
  const { setValue, formState, getFieldState, clearErrors, watch } =
    useFormContext<PurchaseOrderDetailsInput>();

  const { getUiCurrency } = useLocalizationContext();
  const { loginContext } = useAppContext();

  const ticketGroup = watch(`ticketGroups.${ticketGroupIndex}`);
  const ticketCnt = watch(
    `ticketGroups.${ticketGroupIndex}.numberOfTickets.value`
  );
  const unitCost = watch(`ticketGroups.${ticketGroupIndex}.unitCost.value`);
  const faceValueCost = watch(
    `ticketGroups.${ticketGroupIndex}.faceValueCost.value`
  );
  const expectedValue = watch(
    `ticketGroups.${ticketGroupIndex}.expectedValue.value`
  );
  const websitePrice = watch(`ticketGroups.${ticketGroupIndex}.webPrice`);
  const taxPaid = watch(`ticketGroups.${ticketGroupIndex}.taxPaid.value`);
  const autoBroadcastCreatedListing = watch(
    `ticketGroups.${ticketGroupIndex}.autoBroadcastCreatedListing`
  );

  const unitCostField: `ticketGroups.${number}.unitCost` = `ticketGroups.${ticketGroupIndex}.unitCost`;
  const taxPaidField: `ticketGroups.${number}.taxPaid` = `ticketGroups.${ticketGroupIndex}.taxPaid`;
  const expectedValueField: `ticketGroups.${number}.expectedValue` = `ticketGroups.${ticketGroupIndex}.expectedValue`;
  const websitePriceField: `ticketGroups.${number}.webPrice` = `ticketGroups.${ticketGroupIndex}.webPrice`;

  const faceValueCostField: `ticketGroups.${number}.faceValueCost` = `ticketGroups.${ticketGroupIndex}.faceValueCost`;

  const unitCostError = getFieldState(unitCostField, formState)?.error?.message;
  const faceValueCostError = getFieldState(faceValueCostField, formState)?.error
    ?.message;

  const uiCurrency = useMemo(
    () => getUiCurrency(ticketGroup.currencyCode?.value),
    [getUiCurrency, ticketGroup.currencyCode?.value]
  );

  const isMobile = useMatchMedia('mobile');

  const [costSelected, setCostSelected] = useState<CostType>(
    CostType.CostPerTicket
  );
  const [totalCost, setTotalCost] = useState(0);

  useEffect(() => {
    if (costSelected === CostType.CostPerTicket) {
      setTotalCost((unitCost ?? 0) * (ticketCnt ?? 0));
    }
  }, [unitCost, costSelected, ticketCnt]);

  const setFaceValue = useCallback(
    (faceValue: number) => {
      if (
        faceValue >= 0 &&
        faceValue <= Number.MAX_VALUE &&
        faceValue !== faceValueCost
      ) {
        setValue(faceValueCostField, posChangedField(faceValue));
      }
    },
    [faceValueCostField, setValue, faceValueCost]
  );

  const setUnitCost = useCallback(
    (newUnitCost: number) => {
      if (newUnitCost >= 0 && newUnitCost <= Number.MAX_VALUE) {
        clearErrors(unitCostField);

        if (newUnitCost !== unitCost) {
          if (newUnitCost === faceValueCost) {
            setFaceValue(newUnitCost);

            if (newUnitCost > 0 || autoBroadcastCreatedListing === false) {
              clearErrors(faceValueCostField);
            }
          }
          setValue(unitCostField, posChangedField(newUnitCost));
        }
      }
    },
    [
      autoBroadcastCreatedListing,
      clearErrors,
      faceValueCostField,
      setFaceValue,
      setValue,
      faceValueCost,
      unitCost,
      unitCostField,
    ]
  );

  const stubHubMarketplace =
    loginContext?.user?.activeAccount.marketplaceSettings.find(
      (mps) => mps.mkp === Marketplace.StubHub
    );
  return (
    <FormPage.Section
      icon={<TagIcon size={vars.iconSize.s} />}
      title={<Content id={ContentId.Cost} />}
    >
      <FormRow>
        <FieldWrapper>
          <RadioGroup
            value={costSelected}
            onValueChange={(value) => {
              if (value) {
                setCostSelected(value as CostType);
              }
            }}
          >
            <Stack direction="column" gap="m">
              <Radio
                value={CostType.CostPerTicket}
                label={
                  <span>
                    <Content id={ContentId.CostPerTicket} />
                  </span>
                }
                disabled={disabled}
              />
              <Radio
                value={CostType.TotalCost}
                label={
                  <span>
                    <Content id={ContentId.TotalCost} />
                  </span>
                }
                disabled={disabled}
              />
            </Stack>
          </RadioGroup>
        </FieldWrapper>
      </FormRow>
      {costSelected === CostType.TotalCost && (
        <FormRow vertical={isMobile}>
          <FieldWrapper width="max-content" addFlexGrow>
            <PosFormField
              errors={unitCostError}
              label={<Content id={ContentId.TotalTicketCost} />}
            >
              <PosCurrencyField
                disabled={disabled}
                rootProps={{
                  state: getTextFieldState(unitCostError),
                }}
                uiCurrency={uiCurrency}
                value={totalCost}
                onChange={(e) => {
                  const v = parseFloat(e.target.value) || 0;
                  if (v >= 0 && v <= Number.MAX_VALUE) {
                    setTotalCost(v);
                    if (ticketGroup.numberOfTickets?.value) {
                      const costPerTicket =
                        v / ticketGroup.numberOfTickets.value;
                      setUnitCost(costPerTicket);
                    }
                  }
                }}
              />
            </PosFormField>
          </FieldWrapper>
        </FormRow>
      )}
      <FormRow vertical={isMobile}>
        <FieldWrapper width="max-content" addFlexGrow>
          <PosFormField
            errors={unitCostError}
            label={<Content id={ContentId.CostPerTicket} />}
          >
            <PosCurrencyField
              disabled={disabled || costSelected === CostType.TotalCost}
              rootProps={{
                state: getTextFieldState(unitCostError),
              }}
              uiCurrency={uiCurrency}
              value={unitCost ?? undefined}
              onChange={(e) => {
                const v = parseFloat(e.target.value) || 0;
                setUnitCost(v);
                setTotalCost(
                  ticketGroup.numberOfTickets?.value
                    ? v * ticketGroup.numberOfTickets.value
                    : 0
                );
              }}
            />
          </PosFormField>
        </FieldWrapper>
        <FieldWrapper width="max-content" addFlexGrow>
          <PosFormField
            label={<Content id={ContentId.FaceValue} />}
            errors={faceValueCostError}
          >
            <PosCurrencyField
              disabled={disabled}
              uiCurrency={uiCurrency}
              value={faceValueCost ?? undefined}
              onChange={(e) => {
                const v = parseFloat(e.target.value) || 0;
                setFaceValue(v);

                if (v > 0 || autoBroadcastCreatedListing === false) {
                  clearErrors(faceValueCostField);
                }
              }}
            />
          </PosFormField>
        </FieldWrapper>
        <FieldWrapper width="max-content" addFlexGrow>
          <PosFormField label={<Content id={ContentId.ExpectedProceeds} />}>
            <PosCurrencyField
              disabled={disabled}
              uiCurrency={uiCurrency}
              value={expectedValue ?? undefined}
              onChange={(e) => {
                const newExpectedValue = parseFloat(e.target.value) || 0;
                if (
                  newExpectedValue >= 0 &&
                  newExpectedValue <= Number.MAX_VALUE &&
                  newExpectedValue !== expectedValue
                ) {
                  setValue(
                    expectedValueField,
                    posChangedField(newExpectedValue)
                  );
                  const newWebsitePrice = getAllInPriceFromListPrice(
                    newExpectedValue,
                    null,
                    stubHubMarketplace?.sellerFee
                  );
                  if (newWebsitePrice)
                    setValue(websitePriceField, newWebsitePrice);
                }
              }}
            />
          </PosFormField>
        </FieldWrapper>
        <FieldWrapper width="max-content" addFlexGrow>
          <PosFormField label={<Content id={ContentId.WebsitePrice} />}>
            <PosCurrencyField
              disabled={disabled}
              uiCurrency={uiCurrency}
              value={websitePrice ?? ''}
              onChange={(e) => {
                const newWebsitePrice = parseFloat(e.target.value) || 0;
                if (
                  newWebsitePrice >= 0 &&
                  newWebsitePrice <= Number.MAX_VALUE &&
                  newWebsitePrice !== websitePrice
                ) {
                  setValue(websitePriceField, newWebsitePrice);
                  const newExpectedValue = getListPriceFromAllinPrice(
                    newWebsitePrice,
                    null,
                    stubHubMarketplace?.sellerFee
                  );
                  if (newExpectedValue)
                    setValue(
                      expectedValueField,
                      posChangedField(newExpectedValue)
                    );
                }
              }}
            />
          </PosFormField>
        </FieldWrapper>
      </FormRow>
      {costSelected === CostType.CostPerTicket && (
        <FormRow vertical={isMobile}>
          <FieldWrapper width="max-content" addFlexGrow>
            <PosFormField
              errors={unitCostError}
              label={<Content id={ContentId.TotalTicketCost} />}
            >
              <div className={fieldText}>
                {formatCurrency(
                  totalCost || 0,
                  uiCurrency.code,
                  uiCurrency.dec
                )}
              </div>
            </PosFormField>
          </FieldWrapper>
        </FormRow>
      )}
      <FormRow vertical={isMobile}>
        <CheckboxContainer>
          <PosFormField>
            <Checkbox
              labelPosition="right"
              label={<Content id={ContentId.ThisWasTaxExempt} />}
              disabled={disabled}
              checked={(taxPaid ?? 0) === 0} // tax exempt true means zero tax is paid
              onChange={(e) => {
                const isChecked = e.target.checked;
                setValue(taxPaidField, posChangedField(isChecked ? 0 : 1));
              }}
            />
          </PosFormField>
        </CheckboxContainer>
      </FormRow>
    </FormPage.Section>
  );
};
