import { useMemo } from 'react';
import { useReportConfigs } from 'src/hooks/useReportConfigs';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { ReportTypes } from 'src/utils/reportsUtils';
import { ActionOutboxEntityType, UserSetting } from 'src/WebApiController';

export function useGetMetricsInUse({
  entityType,
}: {
  entityType: ActionOutboxEntityType;
}) {
  const { reportConfigs: listingReports } =
    useReportConfigs<ReportTypes.Inventory>({
      reportType: ReportTypes.Inventory,
    });
  const { reportConfigs: saleReports } = useReportConfigs<ReportTypes.Sale>({
    reportType: ReportTypes.Sale,
  });
  const { value: listingColumns = [] } = useServerUserSetting<string[]>({
    id: UserSetting.InventoryColumnOrder,
  });
  const { value: saleColumns = [] } = useServerUserSetting<string[]>({
    id: UserSetting.SaleColumnOrder,
  });

  const metricsInUse = useMemo(() => {
    if (entityType === ActionOutboxEntityType.Listing) {
      return [
        ...new Set([
          ...listingColumns,
          ...listingReports.flatMap((r) => r.metrics),
        ]),
      ];
    } else if (entityType === ActionOutboxEntityType.Sale) {
      return [
        ...new Set([...saleColumns, ...saleReports.flatMap((r) => r.metrics)]),
      ];
    } else {
      return [];
    }
  }, [entityType, listingColumns, listingReports, saleColumns, saleReports]);

  return {
    metricsInUse,
  };
}
