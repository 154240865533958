import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './AccountStatus.css';

export type type = 'custom' | 'system';

interface AccountTypeProps {
  type: type;
}

export const AccountType = ({ type }: AccountTypeProps) => {
  return (
    <div className={type === 'system' ? styles.system : styles.custom}>
      <Content id={type === 'system' ? ContentId.System : ContentId.Custom} />
    </div>
  );
};
