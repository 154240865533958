import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { FormPage } from 'src/core/POS/FormPage';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { CheckboxContainer, FormRow } from 'src/modals/common';
import { IconsFill, PaperTicketsOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';

export const NewListingSection = ({
  ticketGroupIndex,
  disabled,
}: {
  ticketGroupIndex: number;
  disabled?: boolean;
}) => {
  const { setValue, watch, clearErrors } =
    useFormContext<PurchaseOrderDetailsInput>();
  const isMobile = useMatchMedia('mobile');

  const autoBroadcastCreatedListing = watch(
    `ticketGroups.${ticketGroupIndex}.autoBroadcastCreatedListing`
  );

  const faceValue =
    watch(`ticketGroups.${ticketGroupIndex}.faceValueCost`)?.value ?? 0;

  return (
    <FormPage.Section
      icon={
        <PaperTicketsOutlineIcon
          size={vars.iconSize.s}
          fill={IconsFill.currentColor}
        />
      }
      title={<Content id={ContentId.Listing} />}
    >
      <FormRow vertical={isMobile}>
        <CheckboxContainer>
          <PosFormField>
            <Checkbox
              labelPosition="right"
              label={<Content id={ContentId.AutoBroadcastNewListing} />}
              disabled={disabled}
              checked={autoBroadcastCreatedListing ?? false}
              onChange={(e) => {
                const isChecked = e.target.checked;
                if (isChecked !== autoBroadcastCreatedListing) {
                  setValue(
                    `ticketGroups.${ticketGroupIndex}.autoBroadcastCreatedListing`,
                    isChecked
                  );
                }
                if (!isChecked || faceValue > 0) {
                  clearErrors(`ticketGroups.${ticketGroupIndex}.faceValueCost`);
                }
              }}
            />
          </PosFormField>
        </CheckboxContainer>
      </FormRow>
    </FormPage.Section>
  );
};
