import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  UniqueIdentifier,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { DropWrapper } from 'src/core/POS/DragAndDropSort/DragAndDrop';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';

import { ColumnListItem } from '../InventoryTableColumns/ColumnListItem/ColumnListItem';
import { useColumnDisplayName } from '../InventoryTableColumns/useColumnDisplayName';
import {
  ColumnGroupListItem,
  PlaceholderColumnGroupListItem,
} from './ColumnGroupListItem';
import * as styles from './ColumnsSection.css';
import { useAdaptedColumns } from './hooks/useAdaptedColumns';

export type ColumnItem = {
  id: string;
  children?: string[];
};

type ColumnsSelectionProps = {
  titleContentId?: ContentId;
  items?: string[];
  renderActions: (id: string) => JSX.Element;
  onDragEnd?: (event: DragEndEvent) => void;
  sectionType: SectionType;
  highlightIndex?: number;
  shouldHideItem?: (id: string) => boolean;
  viewMode?: string | null;
};

/**
 * The sections that represent the Add Columns and Current Columns for the Edit Columns experience
 */
export const ColumnsSection = ({
  titleContentId,
  items = [],
  renderActions,
  onDragEnd,
  sectionType,
  highlightIndex,
  shouldHideItem,
  viewMode,
}: ColumnsSelectionProps) => {
  const { adaptedColumns, columnGroup } = useAdaptedColumns({
    columns: items,
    sectionType,
    viewMode,
  });

  const { columnIdDisplayNameMapping } = useColumnDisplayName({
    columnIds: [...items, columnGroup?.id || ''],
    sectionType,
  });

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const renderColumnListItem = (
    id: string,
    index: number,
    isPlaceholder = false
  ) => {
    return (
      <ColumnListItem
        key={id}
        draggable
        actionButton={renderActions(id)}
        highlighted={isPlaceholder ? false : highlightIndex === index}
      >
        <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
          {columnIdDisplayNameMapping[id]}
        </span>
      </ColumnListItem>
    );
  };

  return (
    <div>
      {titleContentId && (
        <div className={styles.title}>
          <Content id={titleContentId} />
        </div>
      )}
      <div className={styles.itemsContainer}>
        {onDragEnd ? (
          <DndContext
            onDragStart={(event) => setActiveId(event.active.id)}
            onDragEnd={onDragEnd}
          >
            <SortableContext
              items={adaptedColumns.map((item, index) => item.id)}
              strategy={verticalListSortingStrategy}
            >
              {adaptedColumns.map((item, index) => {
                if (shouldHideItem?.(item.id)) {
                  return null;
                } else if (item.children && item.children.length > 0) {
                  return (
                    <ColumnGroupListItem
                      id={item.id}
                      key={item.id}
                      columnIdDisplayNameMapping={columnIdDisplayNameMapping}
                      items={item.children}
                      highlightIndex={highlightIndex}
                      renderActions={renderActions}
                      groupIndex={index}
                    />
                  );
                } else {
                  return (
                    <DropWrapper id={item.id} key={item.id}>
                      {renderColumnListItem(item.id, items.indexOf(item.id))}
                    </DropWrapper>
                  );
                }
              })}
            </SortableContext>
            <DragOverlay>
              {activeId && columnGroup?.id === activeId ? (
                <PlaceholderColumnGroupListItem
                  displayName={columnIdDisplayNameMapping[activeId]}
                />
              ) : (
                renderColumnListItem(
                  activeId as string,
                  adaptedColumns.findIndex((col) => col.id === activeId),
                  true
                )
              )}
            </DragOverlay>
          </DndContext>
        ) : (
          <>
            {items.map((item, i) => (
              <ColumnListItem
                key={item}
                actionButton={renderActions(item)}
                highlighted={highlightIndex === i}
              >
                <span style={{ overflowX: 'clip', textOverflow: 'ellipsis' }}>
                  {columnIdDisplayNameMapping[item]}
                </span>
              </ColumnListItem>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
