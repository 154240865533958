import { ChangeEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Switch } from 'src/core/interim/Switch';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Detail, DetailGroup, SectionContent } from 'src/modals/common';
import { ListingLinksDetail } from 'src/modals/SaleDetails/components/ListingLinksDetail';
import { useGetModalGridFormat } from 'src/modals/SaleDetailsV2/hooks/useGetModalGridFormat';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { formatSeatDisplay } from 'src/utils/ticketUtils';
import { Feature, SaleInput, Seating, Ticket } from 'src/WebApiController';

export const TicketsSection = ({
  section,
  row,
  seatFr,
  seatTo,
  quantity,
  tickets,
  listingId,
  originatedFromListingId,
  isSeatSaver,
}: Seating & {
  quantity: number;
  tickets?: Ticket[];
  listingId?: number | null;
  originatedFromListingId?: number | null;
  isSeatSaver?: boolean | null;
}) => {
  const { watch, setValue } = useFormContext<SaleInput>();
  const disableAutoSourcing = watch('disableAutoSourcing');
  const { SectionContentGridFormat } = useGetModalGridFormat();

  const allowDisableAutoSourcing = useUserHasFeature(
    Feature.DisableAutoSourcing
  );

  return (
    <SectionContent format={SectionContentGridFormat}>
      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.Section} />}
          detail={section}
        />
      </DetailGroup>

      <DetailGroup>
        <Detail label={<Content id={ContentId.Row} />} detail={row} />
      </DetailGroup>

      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.Seats} />}
          detail={<>{formatSeatDisplay(seatFr, seatTo)}</>}
        />
      </DetailGroup>

      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.Quantity} />}
          detail={<strong>x {quantity}</strong>}
        />
      </DetailGroup>
      <ListingLinksDetail
        listingId={listingId}
        originatedFromListingId={originatedFromListingId}
        tickets={tickets ?? []}
      />
      {isSeatSaver && allowDisableAutoSourcing && (
        <DetailGroup>
          <Detail
            label={<Content id={ContentId.AutoSourceTickets} />}
            detail={
              <div style={{ width: '25%' }}>
                <Switch
                  checked={!disableAutoSourcing}
                  onClick={(e: ChangeEvent<HTMLElement>) => e.stopPropagation()}
                  onChange={(e) => {
                    // by default, AutoSource Ticekts is set to true
                    // if not checked, seller wants to disable auto sourcing.
                    const isChecked = e.currentTarget.checked;

                    setValue(
                      'disableAutoSourcing',
                      posChangedField(!isChecked),
                      {
                        shouldDirty: true,
                      }
                    );
                  }}
                />
              </div>
            }
          />
        </DetailGroup>
      )}
    </SectionContent>
  );
};
