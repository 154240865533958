import {
  AccessorReturn,
  AccessorReturnType,
} from 'src/components/Reports/ReportsExport/ReportsExport.types';
import { SaleReportMetricsWithGroupBy } from 'src/utils/reportsUtils';
import { Money, UiMoney } from 'src/WebApiController';

import { SalesReportTableColumnId } from '../../../utils/columns/sales/salesColumnUtils.types';

// Only primitive types / Money / AccessorReturn are allowed for proper support from ReportsExport.utils.ts
type SaleReportTableAccessorFn = (
  data: SaleReportMetricsWithGroupBy | null
) =>
  | string
  | string[]
  | number
  | number[]
  | boolean
  | Money
  | UiMoney
  | AccessorReturn<unknown>
  | null
  | undefined;

export const SALE_REPORT_TABLE_COLUMNS_ACCESSOR = {
  [SalesReportTableColumnId.Performer]: (data) => data?.performer?.name ?? '',
  [SalesReportTableColumnId.Venue]: (data) => data?.venue?.name ?? '',
  [SalesReportTableColumnId.Event]: (data) => data?.event?.name ?? '',
  [SalesReportTableColumnId.EventGenre]: (data) =>
    data?.genre ?? data?.event?.genre ?? '',
  [SalesReportTableColumnId.EventCountry]: (data) =>
    data?.country?.name ?? data?.venue?.country?.name ?? '',
  [SalesReportTableColumnId.EventCity]: (data) =>
    data?.city ?? data?.venue?.city ?? '',
  [SalesReportTableColumnId.SeatingSection]: (data) => data?.section ?? '',
  [SalesReportTableColumnId.SeatingRow]: (data) => data?.row ?? '',
  [SalesReportTableColumnId.SaleMarketplace]: (data) => data?.mkp ?? '',
  [SalesReportTableColumnId.SaleMonth]: (data) => data?.saleDate ?? '',
  [SalesReportTableColumnId.SaleWeek]: (data) => data?.saleDate ?? '',
  [SalesReportTableColumnId.SaleDay]: (data) => data?.saleDate ?? '',
  [SalesReportTableColumnId.QuantitySold]: (data) => data?.qtySold ?? 0,
  [SalesReportTableColumnId.Proceeds]: (data) => data?.netProcs,
  [SalesReportTableColumnId.SoldTicketCost]: (data) => data?.soldCst,
  [SalesReportTableColumnId.CommissionPercent]: (data) =>
    data?.commission?.commissionPercentage,

  [SalesReportTableColumnId.CommissionShare]: (data) => data?.commssionShr,
  [SalesReportTableColumnId.OutstandingBalance]: (data) => data?.ttlOutsBal,
  [SalesReportTableColumnId.Charges]: (data) => data?.chargs,
  [SalesReportTableColumnId.Credits]: (data) => data?.creds,
  [SalesReportTableColumnId.PandL]: (data) => data?.pandL,
  [SalesReportTableColumnId.Margin]: (data) => data?.marg,
  [SalesReportTableColumnId.EventId]: (data) => data?.event?.viagId ?? '',
  [SalesReportTableColumnId.Category]: (data) =>
    data?.performer?.categ[0] ?? '',
  [SalesReportTableColumnId.EventDate]: (data) =>
    data?.event?.dates?.start ?? '',

  [SalesReportTableColumnId.EventStateProvince]: (data) =>
    data?.venue?.state ?? '',
  [SalesReportTableColumnId.SaleId]: (data) => data?.saleId?.toString() ?? '',
  [SalesReportTableColumnId.MarketplaceSaleId]: (data) =>
    data?.mkpSaleId ? `#${data.mkpSaleId}` : '',
  [SalesReportTableColumnId.Status]: (data) => data?.saleStatus,
  [SalesReportTableColumnId.TicketType]: (data) => data?.ticketType,

  [SalesReportTableColumnId.InHandDate]: (data) => data?.inHandDate,

  [SalesReportTableColumnId.PaymentState]: (data) => ({
    stringValueType: AccessorReturnType.STRING,
    stringValues: data?.mkpPmtState ?? data?.pmtState,
    value: {
      marketplacePaymentState: data?.mkpPmtState,
      paymentState: data?.pmtState,
    },
  }),
  [SalesReportTableColumnId.PaymentReceived]: (data) => data?.pmtRcv,
  [SalesReportTableColumnId.PaymentReferenceId]: (data) => data?.pmtRefId,
  [SalesReportTableColumnId.PricedBy]: (data) => ({
    stringValueType: AccessorReturnType.SELLER_USER_ID,
    value: data?.pricerId,
  }),
  [SalesReportTableColumnId.Fulfiller]: (data) => ({
    stringValueType: AccessorReturnType.SELLER_USER_ID,
    value: data?.fulfillerId,
  }),
  [SalesReportTableColumnId.CommissionUser]: (data) => ({
    stringValueType: AccessorReturnType.SELLER_USER_ID,
    value: data?.commission?.buyerUserId,
  }),
  [SalesReportTableColumnId.PurchaseId]: (data) => data?.poInfos?.poIds,
  [SalesReportTableColumnId.PurchasedBy]: (data) => ({
    stringValueType: AccessorReturnType.PURCHASE_BUYER_INFOS,
    value: data?.poInfos?.buyers,
  }),
  [SalesReportTableColumnId.PurchaseDate]: (data) => data?.poInfos.poDates,
  [SalesReportTableColumnId.Vendor]: (data) => {
    if (!data) return null;
    const { poInfos } = data;
    if (!poInfos?.vendors.length) return null;
    return poInfos.vendors.map((v) => v.vendorName).join(', ');
  },
  [SalesReportTableColumnId.VendorAccountEmail]: (data) => {
    if (!data) return null;
    const { poInfos } = data;
    if (!poInfos?.vendorAccs.length) return null;
    return poInfos.vendorAccs.map((v) => v.encryptedEmailAddress).join(', ');
  },
  [SalesReportTableColumnId.SeatingSeats]: (data) => {
    if (!data?.seatFr) return null;
    let text = data.seatFr;
    if (data.seatTo && data.seatTo !== data.seatFr) {
      text += ` - ${data.seatTo}`;
    }
    return text;
  },
  [SalesReportTableColumnId.OrderDate]: (data) => data?.saleDate,
  [SalesReportTableColumnId.CancellationDate]: (data) => data?.cancelDate,
  [SalesReportTableColumnId.CurrencyCode]: (data) => data?.currency ?? '',
  [SalesReportTableColumnId.InternalNotes]: (data) => data?.privNotes ?? '',
  [SalesReportTableColumnId.TicketId]: (data) =>
    data?.ticketId?.toString() ?? '',
  [SalesReportTableColumnId.TicketGroupId]: (data) => data?.tktGrId?.toString(),
  [SalesReportTableColumnId.PaymentDate]: (data) => data?.lastPmtOn,
  [SalesReportTableColumnId.FulfillmentDate]: (data) => data?.fulfillDate,
  [SalesReportTableColumnId.PurchaseCreatedBy]: (data) => ({
    stringValueType: AccessorReturnType.SELLER_USER_ID,
    value: data?.poInfos?.createdBys,
  }),
  [SalesReportTableColumnId.MarketplacePaymentTerms]: (data) =>
    data?.mkpPmtTerm,
} satisfies Record<string, SaleReportTableAccessorFn>;
