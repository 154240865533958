import { ReactNode } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { Button, ButtonProps, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import { IconButton } from './IconButton';

export const ButtonWithIcon = ({
  textContentId,
  titleContentId,
  icon,
  iconOnlyMode,
  onClick,
  disabled,
  variant,
  justifyContent,
  style,
  startWithText,
  ...rest
}: {
  textContentId?: ContentId | string;
  titleContentId?: ContentId | string;
  icon?: ReactNode;
  iconOnlyMode?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  startWithText?: boolean;
} & Omit<ButtonProps, 'children' | 'onClick'>) => {
  const defaultStyle = variant === 'text' ? { width: '100%' } : {};
  return (
    <>
      {icon && iconOnlyMode ? (
        <IconButton
          icon={icon}
          disabled={disabled}
          onClick={onClick}
          titleContentId={titleContentId ?? textContentId}
        />
      ) : (
        <Button
          onClick={onClick}
          disabled={disabled}
          {...rest}
          variant={variant}
          style={{ ...defaultStyle, ...(style ?? {}) }}
          justifyContent={
            justifyContent ?? variant === 'text' ? 'space-between' : 'center'
          }
        >
          <Stack alignItems="center" gap="m">
            {startWithText ? (
              <>
                <Content id={textContentId || ContentId.EmptyContent} />
                {icon}
              </>
            ) : (
              <>
                {icon}
                <Content id={textContentId || ContentId.EmptyContent} />
              </>
            )}
          </Stack>
        </Button>
      )}
    </>
  );
};
