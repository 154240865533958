import clsx from 'clsx';
import {
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useToggle } from 'react-use';
import { ExpandCollapsableContentButton } from 'src/components/common/CollapsableContent/ExpandCollapsableContentButton';
import { collapsedRow } from 'src/components/Events/EventListing/InventoryEventListing/InventoryFlattenedView.css';
import { AdGroupsDataContext } from 'src/contexts/AdGroupsDataContext/AdGroupsDataContext';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content } from 'src/contexts/ContentContext';
import { InputPriceFocusProvider } from 'src/contexts/InputPriceFocusContext/InputPriceFocusContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { LayoutIcon } from 'src/svgs';
import { ListingWithEvent } from 'src/tables/ListingTable/ListingTable.types';
import { ListingTableFlattened } from 'src/tables/ListingTable/ListingTableFlattened';
import { getTopLevelCategoryName } from 'src/utils/adGroupUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { getEventPerformerVenue } from 'src/utils/eventWithDataUtils';
import { EventWithData, Listing } from 'src/WebApiController';

import * as styles from './AdGroupListingsFlattenedView.css';

type AdGroupListingsFlattenedViewProps = {
  before?: ReactNode;
};

export const AdGroupListingsFlattenedView = ({
  before,
}: AdGroupListingsFlattenedViewProps) => {
  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isCollapsedView, setIsCollapsedView } = useCollapsableViewContext();

  const { data: catalog } = useAdPlatformCatalogDataContext();

  const { adGroupsData } = useContext(AdGroupsDataContext);
  const adGroup = adGroupsData ? adGroupsData[0] : null;

  const onColumnSettingButtonClickHandler = (
    e: MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    if (!isColumnModalOpen) toggleColumnModal(true);
  };

  const getListingById = useCallback(
    (listingId: string) => {
      if (catalog == null) {
        return undefined;
      }
      return catalog.listingsByExternalId[listingId];
    },
    [catalog]
  );

  const getListingsForEvents = useCallback(
    (eventIds: number[]) => {
      if (catalog == null) {
        return undefined;
      }
      return Object.values(catalog.events).flatMap(
        (event) => event.listings ?? []
      );
    },
    [catalog]
  );

  const listingExternalIdLookup = useCallback(
    (key: string) => {
      return getListingById(key);
    },
    [getListingById]
  );

  const venueMapLookup = useCallback(
    (eventId?: number) => {
      if (!catalog || !eventId) {
        return undefined;
      }
      return catalog.venueMapByEventId[eventId];
    },
    [catalog]
  );

  const resolveListings = useCallback(async () => {
    if (!adGroup || !catalog) {
      return undefined;
    }

    const listingsArray: Listing[] = [];

    // Get explicit listings
    const listingPromises = adGroup.bidModifiers
      .filter((b) => b.key.externalId != null)
      .map((b) => {
        const listing = listingExternalIdLookup(b.key.externalId!);
        if (listing) {
          listingsArray.push(listing);
        }
      });
    await Promise.all(listingPromises);

    // Get listings for ticket class
    const ticketClassPromises = adGroup.bidModifiers
      .filter((b) => b.key.ticketClassId != null && b.key.eventId != null)
      .map(async (b) => {
        const eventId = b.key.eventId!;
        const venueMap = venueMapLookup(eventId);
        const rows = venueMap?.sections
          .flatMap((section) => section.rows)
          .filter(
            (row) =>
              row?.tktClass?.id.toString() == b.key.ticketClassId?.toString()
          );

        const event = catalog?.events[eventId.toString()];
        if (event && rows) {
          const listings = getListingsForEvents([eventId]);
          const filteredListings = Object.values(listings ?? [])
            .flat()
            .filter((l) => rows.some((row) => l.seating.rowId == row.id));
          listingsArray.push(...filteredListings);
        }
      });
    await Promise.all(ticketClassPromises);

    // Get listings for section
    const sectionPromises = adGroup.bidModifiers
      .filter((b) => b.key.sectionId != null && b.key.eventId != null)
      .map(async (b) => {
        const eventId = b.key.eventId!;
        const venueMap = venueMapLookup(eventId);
        const sections = venueMap?.sections.filter(
          (section) => section.id.toString() == b.key.sectionId?.toString()
        );
        const event = catalog?.events[eventId.toString()];
        if (sections && event) {
          const listings = getListingsForEvents([eventId]);
          const filteredListings = Object.values(listings ?? [])
            .flat()
            .filter((l) =>
              sections.some((section) => l.seating.sectionId == section.id)
            );
          listingsArray.push(...filteredListings);
        }
      });
    await Promise.all(sectionPromises);

    // Get explicit events
    const explicitEvents = adGroup.bidModifiers
      .filter(
        (b) =>
          b.key.eventId != null &&
          b.key.sectionId == null &&
          b.key.ticketClassId == null &&
          b.key.externalId == null
      )
      .reduce<EventWithData[]>((acc, b) => {
        const event = catalog?.events[b.key.eventId!];
        if (event) {
          acc.push(event);
        }
        return acc;
      }, []);

    // Get events for venue
    const eventsForVenues = adGroup.bidModifiers
      .filter((b) => b.key.venueId != null)
      .reduce<EventWithData[]>((acc, b) => {
        const matchedEvents = Object.values(catalog?.events || {}).filter(
          (e) => e.event.venueId.toString() == b.key.venueId?.toString()
        );
        return acc.concat(matchedEvents);
      }, []);

    // Get events for performer
    const eventsForPerformer = adGroup.bidModifiers
      .filter((b) => b.key.leafCategoryId != null)
      .reduce<EventWithData[]>((acc, b) => {
        const matchedEvents = Object.values(catalog?.events || {}).filter(
          (e) => e.event.perfId?.toString() == b.key.leafCategoryId?.toString()
        );
        return acc.concat(matchedEvents);
      }, []);

    // Get events for genre
    const eventsForGenre = adGroup.bidModifiers
      .filter((b) => b.key.topLevelCategoryId != null)
      .reduce<EventWithData[]>((acc, b) => {
        const matchedEvents = Object.values(catalog?.events || {}).filter(
          (e) =>
            e.event.genre?.toString() ==
            getTopLevelCategoryName(b.key.topLevelCategoryId!)
        );
        return acc.concat(matchedEvents);
      }, []);

    // Concat all events
    const allEvents: Set<EventWithData> = [
      explicitEvents,
      eventsForVenues,
      eventsForPerformer,
      eventsForGenre,
    ].reduce((set, events) => {
      events.forEach((event) => set.add(event));
      return set;
    }, new Set<EventWithData>());

    // Retrieve listings for events
    const listingsMapForEvents = getListingsForEvents(
      [...allEvents]
        .map((event) => event.event.viagId)
        .filter(
          (viagId): viagId is number => viagId !== null && viagId !== undefined
        )
    );

    listingsArray.push(...Object.values(listingsMapForEvents ?? []).flat());

    return listingsArray
      ?.map((l) => {
        const { event, performer, venue } = getEventPerformerVenue(
          l.viagVirtualId,
          {
            ...catalog,
            viagIdToLatestIdLookup: {},
            posIdToViagIdLookup: {},
          }
        );
        if (event) {
          return {
            event: event.event,
            listing: l,
            performer,
            venue,
            eventWithData: event,
          };
        }

        return null;
      })
      .filter((l) => l != null)
      .map((l) => l!);
  }, [
    adGroup,
    catalog,
    getListingsForEvents,
    listingExternalIdLookup,
    venueMapLookup,
  ]);

  const [processedListings, setProcessedListings] = useState<
    (ListingWithEvent & { eventWithData: EventWithData })[]
  >([]);

  useEffect(() => {
    const processAdGroup = async () => {
      if (!adGroupsData || !catalog) {
        return [];
      }

      setIsLoading(true);
      const listings = await resolveListings();
      setProcessedListings(
        listings?.map((l) => ({
          event: l.event,
          performer: l.performer ?? undefined,
          venue: l.venue ?? undefined,
          eventWithData: l.eventWithData,
        })) ?? []
      );
      setIsLoading(false);
    };
    processAdGroup();
  }, [adGroupsData, catalog, resolveListings]);

  const { listingCount, ungroupedListingCount, listingGroupCount } =
    useMemo(() => {
      let listingCount = 0;
      let ungroupedListingCount = 0;
      let listingGroupCount = 0;

      (processedListings ?? []).forEach(({ eventWithData }) => {
        listingCount += eventWithData.listCnt;
        listingGroupCount += eventWithData.listGrpCnt;
        ungroupedListingCount += eventWithData.ungrListCnt;
      });

      return { listingCount, ungroupedListingCount, listingGroupCount };
    }, [processedListings]);

  return (
    <Stack direction="column" height="full" width="full">
      <div
        className={clsx({
          [styles.metricsAnimationUncollapsed]: !isCollapsedView,
          [styles.metricsAnimationCollapsed]: isCollapsedView,
        })}
      >
        {before}
      </div>
      <Stack
        justifyContent="end"
        alignItems="center"
        className={clsx({
          [collapsedRow]: isCollapsedView,
        })}
      >
        {isCollapsedView && (
          <ExpandCollapsableContentButton
            onClick={() => setIsCollapsedView(false)}
          />
        )}
        <Button variant="textPlain" onClick={onColumnSettingButtonClickHandler}>
          <LayoutIcon size={vars.iconSize.m} />
          <Content id={ContentId.Columns} />
        </Button>
      </Stack>
      <InputPriceFocusProvider
        disablePagination
        pageSize={processedListings.length}
      >
        {isLoading ? (
          <PosSpinner />
        ) : (
          <ListingTableFlattened
            useVirtuoso
            disablePagination
            listingCount={listingCount}
            ungroupedListingCount={ungroupedListingCount}
            listingGroupCount={listingGroupCount}
            listings={processedListings}
            failedToRetrieveData={false}
          />
        )}
      </InputPriceFocusProvider>
    </Stack>
  );
};
