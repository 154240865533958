import {
  FilterToolbarItem,
  FilterToolbarItemId,
} from 'src/components/FilterToolbar';

/**
 * Some filters have the null value as a valid value, so we need to allow them
 * as valid.
 * When a refactor to track which filters are applied and which doesn't
 * without relying on the filter value, this can be safely removed.
 */
const excludedFilterIds: FilterToolbarItemId[] = ['purchaseOrderState'];

export const isFilterEmpty = (
  filterId: FilterToolbarItemId,
  tempQuery: Partial<Record<FilterToolbarItemId, unknown>>,
  filtersMap: Partial<Record<FilterToolbarItemId, FilterToolbarItem>>,
  mandatoryFiltersToShow: FilterToolbarItemId[]
): boolean => {
  if (mandatoryFiltersToShow.includes(filterId)) {
    return false;
  }
  return (
    filtersMap[filterId]?.filterQueryKeys?.every((filterId) => {
      return (
        tempQuery[filterId] == null && !excludedFilterIds.includes(filterId)
      );
    }) ?? true
  );
};
