import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { PageSection } from 'src/components/common/PageSection';
import { ActivePosEntityProvider } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { ToolbarButton } from 'src/core/POS/ToolbarButton';
import { Card } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { SaleWithEvent } from 'src/tables/SalesTable/SalesTable.type';
import { RowWrapper } from 'src/tables/Table/Table.types';
import { DATA_REFRESH_RATE_IN_MILLIS_SHORT } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { getLastNDaysSaleQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  CatalogClient,
  Feature,
  Sale,
  SaleClient,
  SaleDetails,
  SaleQuery,
  UserSetting,
} from 'src/WebApiController';

import * as styles from './RecentSalesSection.css';
import { RecentSalesTable } from './RecentSalesTable';

const PREVIOUS_DAYS_TO_PULL = 7;
const NEW_ROW_ACTIVE_DURATION = 5_000;

export const RecentSalesSection = () => {
  const salesFilterQuery = getLastNDaysSaleQuery(PREVIOUS_DAYS_TO_PULL);
  const { activeAccountWebClientConfig } = useAppContext();
  const previousValues = useRef<Set<number>>();
  const [newRows, setNewRows] = useState<number[]>([]);
  const { trackError } = useErrorBoundaryContext();
  const hasUnprocessedOrdersHoldingPenFeature = useUserHasFeature(
    Feature.AutomatiqUnprocessedOrdersHoldingPen
  );
  const [activeTab, setActiveTab] = useState<
    'recentSales' | 'unableToProcessSales'
  >('recentSales');

  const handleTabChange = (tab: 'recentSales' | 'unableToProcessSales') => {
    if (hasUnprocessedOrdersHoldingPenFeature) {
      setActiveTab(tab);
    }
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      activeTab === 'recentSales'
        ? 'SaleClient.getSalesForEvents.recent'
        : 'SaleClient.getUnableToProcessSales',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      if (activeTab === 'recentSales') {
        return tryInvokeApi(
          async () => {
            const client = new SaleClient(activeAccountWebClientConfig);
            return await client.getSalesForEvents(salesFilterQuery, false);
          },
          (error: ErrorTypes) => {
            trackError('SaleClient.getSalesForEvents.recent', error);
          }
        );
      }

      return tryInvokeApi(
        async () => {
          const client = new SaleClient(activeAccountWebClientConfig);
          return await client.getUnableToProcessSales();
        },
        (error: ErrorTypes) => {
          trackError('SaleClient.getUnableToProcessSales', error);
        }
      );
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_SHORT,
  });

  const flattenedData = useMemo(() => {
    if (!data) return [];
    return Object.values<Sale[]>(data)
      .flat()
      .filter(
        (s) =>
          (activeTab === 'recentSales' && !s.isUnprocessedOrdFromPro) ||
          (hasUnprocessedOrdersHoldingPenFeature &&
            activeTab === 'unableToProcessSales' &&
            s.isUnprocessedOrdFromPro)
      )
      .sort(
        (a, b) =>
          new Date(b.saleDate).valueOf() - new Date(a.saleDate).valueOf()
      );
  }, [activeTab, data, hasUnprocessedOrdersHoldingPenFeature]);

  useEffect(() => {
    if (!data) return;
    const isInitial = !previousValues.current;
    const prev = previousValues.current ?? new Set();
    const newValues = flattenedData
      .filter((sale) => !prev.has(sale.id))
      .map((sale) => sale.id);
    newValues.forEach((id) => prev.add(id));
    if (newValues.length && !isInitial) {
      setNewRows(newValues);
      setTimeout(() => {
        setNewRows([]);
      }, NEW_ROW_ACTIVE_DURATION);
    }
    previousValues.current = prev;
  }, [data, flattenedData]);

  const getCatalogData = async (
    client: CatalogClient,
    filterQuery: SaleQuery
  ) => {
    return await client.getCatalogForSales(filterQuery, false);
  };

  const getCatalogDataExpanded = async () => ({});

  const getActivePosEntity = useCallback(
    async (saleId: number) => {
      const saleDetail = await new SaleClient(
        activeAccountWebClientConfig
      ).getSaleBySaleId(saleId);

      if (!saleDetail) return {};
      return {
        posEntityId: saleDetail.id,
        posEntity: saleDetail,
        posEntityDisplayId: saleDetail.idOnMkp,
      };
    },
    [activeAccountWebClientConfig]
  );

  const onRowWrapper: RowWrapper<SaleWithEvent | null> = (
    rowData,
    tableRow
  ) => {
    const isNewRow = !!rowData?.sale.id && newRows.includes(rowData?.sale.id);
    return (
      <div className={clsx(styles.row, { [styles.isNewRow]: isNewRow })}>
        {tableRow}
      </div>
    );
  };

  return (
    <PageSection>
      <PageSection.Header
        action={
          <Link className={styles.link} to="/sales">
            <Content id={ContentId.SeeAll} />
          </Link>
        }
      >
        {hasUnprocessedOrdersHoldingPenFeature ? (
          <div>
            <ToolbarButton
              isSelected={activeTab === 'recentSales'}
              onClick={() => handleTabChange('recentSales')}
            >
              <Content id={ContentId.RecentSales} />
            </ToolbarButton>
            <ToolbarButton
              isSelected={activeTab === 'unableToProcessSales'}
              onClick={() => handleTabChange('unableToProcessSales')}
            >
              <Content id={ContentId.UnableToProcess} />
            </ToolbarButton>
          </div>
        ) : (
          <Content id={ContentId.RecentSales} />
        )}
      </PageSection.Header>

      <Card className={styles.card}>
        <FilterQueryContextProvider<SaleQuery>
          initialQuery={salesFilterQuery}
          emptyQuery={salesFilterQuery}
          viewModeSettingId={UserSetting.SalePageViewMode}
        >
          <CatalogDataContextProvider<SaleQuery>
            queryKey="getCatalogForSales"
            getCatalogData={getCatalogData}
            getCatalogDataExpanded={getCatalogDataExpanded}
            transformEventData={transformData}
          >
            <ActivePosEntityProvider<SaleDetails>
              entityType={ActionOutboxEntityType.Sale}
              getActivePosEntity={getActivePosEntity}
              onSetActiveItemCompleteCallback={() => refetch()}
            >
              <DialogProvider>
                {!isLoading ? (
                  <RecentSalesTable
                    data={flattenedData}
                    onRowWrapper={onRowWrapper}
                  />
                ) : (
                  <PosSpinner />
                )}
              </DialogProvider>
            </ActivePosEntityProvider>
          </CatalogDataContextProvider>
        </FilterQueryContextProvider>
      </Card>
    </PageSection>
  );
};
