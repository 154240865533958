import { ColumnDef } from '@tanstack/react-table';
import { ReactNode, useMemo } from 'react';
import { MarketplaceLogo } from 'src/components/common/MarketplaceLogo';
import {
  COLUMN_DEFAULT_SIZE_SM,
  COLUMN_MAX_SIZE,
  COLUMN_MIN_SIZE,
} from 'src/contexts/ColumnResizingContext/ColumnResizingContext.types';
import { Content } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { FormatOption_DateTime } from 'src/modals/EditTableColumns';
import { SHIMMERING_DIV_HEIGHT_PAYMENTS } from 'src/tables/PaymentsTable/PaymentsTableCommon.constants';
import { TableShimmeringDiv } from 'src/tables/Table';
import { DateCell } from 'src/tables/Table/TableCells';
import { MarketplacePaymentsTableColumnId } from 'src/utils/columns/payments/paymentsColumnsUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { MARKETPLACE_SALE_PAYMENT_STATE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { safeSortDateTime, sortMoney } from 'src/utils/tableUtils';
import {
  Marketplace,
  MarketplacePaymentState,
  MarketplaceSalePayment,
  UiMoney,
} from 'src/WebApiController';

export const usePaymentsTableColumnsConfig = () => {
  return useMemo<
    Record<
      MarketplacePaymentsTableColumnId,
      ColumnDef<MarketplaceSalePayment | null>
    >
  >(() => {
    // Reuse components for same values
    const datesMap: Map<string, ReactNode> = new Map();
    const tableShimmeringDiv = (
      <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_PAYMENTS} />
    );

    return {
      [MarketplacePaymentsTableColumnId.PaymentDate]: {
        id: MarketplacePaymentsTableColumnId.PaymentDate,
        accessorFn: (data) => data?.pmtDate,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.PaymentDate} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const date = getValue<string | null>();
          if (!original) {
            return tableShimmeringDiv;
          }

          if (!date) {
            return null;
          }

          if (!datesMap.has(date)) {
            datesMap.set(
              date,
              <DateCell
                precision={FormatOption_DateTime.DateTime_Month_DD_YY}
                date={date}
                align="left"
                enableUtcFallback
                hideRelativeTerms
              />
            );
          }
          return <TableCellDiv align="left">{datesMap.get(date)}</TableCellDiv>;
        },
        sortingFn: safeSortDateTime<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.Marketplace]: {
        id: MarketplacePaymentsTableColumnId.Marketplace,
        accessorFn: (data) => data?.mkp,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.Marketplace} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const marketplace = getValue<Marketplace>();
          if (!original) {
            return tableShimmeringDiv;
          }

          return (
            <TableCellDiv align="left">
              <MarketplaceLogo marketplace={marketplace} />
            </TableCellDiv>
          );
        },
      },
      [MarketplacePaymentsTableColumnId.ExternalPaymentID]: {
        id: MarketplacePaymentsTableColumnId.ExternalPaymentID,
        accessorFn: (data) => data?.extPmtId,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.ExternalPaymentId} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const extPmtId = getValue<string | null>();
          if (!original) {
            return tableShimmeringDiv;
          }
          return <TableCellDiv align="left">{extPmtId}</TableCellDiv>;
        },
      },
      [MarketplacePaymentsTableColumnId.State]: {
        id: MarketplacePaymentsTableColumnId.State,
        accessorFn: (data) => data?.state,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.State} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const state = getValue<MarketplacePaymentState | null>();
          if (!original) {
            return tableShimmeringDiv;
          }

          if (!state) {
            return null;
          }

          return (
            <TableCellDiv align="left">
              <Content id={MARKETPLACE_SALE_PAYMENT_STATE_TO_CID[state]} />
            </TableCellDiv>
          );
        },
      },
      [MarketplacePaymentsTableColumnId.PaymentAmount]: {
        id: MarketplacePaymentsTableColumnId.PaymentAmount,
        accessorFn: (data) => data?.pmtAmt,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.PaymentAmount} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const pmtAmt = getValue<UiMoney | null>();
          if (!original) {
            return tableShimmeringDiv;
          }

          if (!pmtAmt) {
            return null;
          }

          return <TableCellDiv align="left">{pmtAmt.disp}</TableCellDiv>;
        },
        sortingFn: sortMoney<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.CreditAmount]: {
        id: MarketplacePaymentsTableColumnId.CreditAmount,
        accessorFn: (data) => data?.credit,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.CreditAmount} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const credit = getValue<UiMoney | null>();
          if (!original) {
            return tableShimmeringDiv;
          }

          if (!credit) {
            return null;
          }

          return <TableCellDiv align="left">{credit.disp}</TableCellDiv>;
        },
        sortingFn: sortMoney<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.ChargeAmount]: {
        id: MarketplacePaymentsTableColumnId.ChargeAmount,
        accessorFn: (data) => data?.charge,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.ChargeAmount} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const charge = getValue<UiMoney | null>();

          if (!original) {
            return tableShimmeringDiv;
          }

          if (!charge) {
            return null;
          }

          return <TableCellDiv align="left">{charge.disp}</TableCellDiv>;
        },
        sortingFn: sortMoney<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.ProceedsAmount]: {
        id: MarketplacePaymentsTableColumnId.ProceedsAmount,
        accessorFn: (data) => data?.pmtAmt,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.ProceedsAmount} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const proceeds = getValue<UiMoney | null>();

          if (!original) {
            return tableShimmeringDiv;
          }

          if (!proceeds) {
            return null;
          }

          return <TableCellDiv align="left">{proceeds.disp}</TableCellDiv>;
        },
        sortingFn: sortMoney<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.ReceivedDate]: {
        id: MarketplacePaymentsTableColumnId.ReceivedDate,
        accessorFn: (data) => data?.recvDate,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.ReceivedDate} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const date = getValue<string | null>();

          if (!original) {
            return tableShimmeringDiv;
          }

          if (!date) {
            return null;
          }

          if (!datesMap.has(date)) {
            datesMap.set(
              date,
              <DateCell
                precision={FormatOption_DateTime.DateTime_Month_DD_YY}
                date={date}
                align="left"
                enableUtcFallback
                hideRelativeTerms
              />
            );
          }
          return <TableCellDiv align="left">{datesMap.get(date)}</TableCellDiv>;
        },
        sortingFn: safeSortDateTime<MarketplaceSalePayment>,
      },
      [MarketplacePaymentsTableColumnId.PaymentReferenceID]: {
        id: MarketplacePaymentsTableColumnId.PaymentReferenceID,
        accessorFn: (data) => data?.pmtRefId,
        header: (params) => (
          <TableHeaderCellDiv align="left">
            <Content id={ContentId.PaymentReferenceID} />
          </TableHeaderCellDiv>
        ),
        size: COLUMN_DEFAULT_SIZE_SM,
        minSize: COLUMN_MIN_SIZE,
        maxSize: COLUMN_MAX_SIZE,
        cell: ({ getValue, row: { original } }) => {
          const pmtRefId = getValue<string | null>();
          if (!original) {
            return tableShimmeringDiv;
          }
          return <TableCellDiv align="left">{pmtRefId}</TableCellDiv>;
        },
      },
    };
  }, []);
};
