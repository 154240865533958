import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function MailIcon({
  fill,
  stroke,
  ...props
}: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper {...props} viewBox="0 0 12 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 4.2402V7.7402V9.8002H11V7.7602L9.4 6.9602L7.5 6.0002L10.2 4.6402V7.36107L11 7.7602V4.2402V2.2002H1V4.2402ZM10.2 9.0002H1.8V8.2402L5.4 6.4402L6 6.7402L6.6 6.4402L10.2 8.2402V9.0002ZM6 5.86019L1.8 3.76019V3.00019H10.2V3.76019L6 5.86019ZM1.8 4.64019L4.5 6.0002L1.8 7.3602V4.64019Z"
        fill={fill ?? stroke ?? 'currentColor'}
      />
    </SvgWrapper>
  );
}
