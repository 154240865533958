import { PropsWithChildren, useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { CatalogDataContextProvider } from 'src/contexts/CatalogDataContext';
import { FilterQueryContextProvider } from 'src/contexts/FilterQueryContext';
import { ReportMetricsContextProviderV2 } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { EmptyListingQuery } from 'src/utils/eventQueryUtils';
import { transformData } from 'src/utils/eventWithDataUtils';
import {
  ListingQuery,
  ReportV2Client,
  UserSetting,
} from 'src/WebApiController';

import { getCatalogData } from '../Inventory';

export function ReportsInventoryContextProviderV2({
  reportConfig,
  isExport,
  children,
}: PropsWithChildren<{
  reportConfig: ReportConfigV2;
  isExport?: boolean;
}>) {
  const { activeAccountWebClientConfig } = useAppContext();

  const getReportMetrics = useCallback(
    (filters: ListingQuery, pageNumber: number) => {
      return new ReportV2Client(
        activeAccountWebClientConfig
      ).getInventoryReportMetrics({
        ...reportConfig.request,
        pageNumber,
        filters,
      });
    },
    [activeAccountWebClientConfig, reportConfig]
  );

  const getReportMetricsSummary = useCallback(
    (filters: ListingQuery) => {
      return new ReportV2Client(
        activeAccountWebClientConfig
      ).getInventoryReportSummary({
        ...reportConfig.request,
        filters,
      });
    },
    [activeAccountWebClientConfig, reportConfig]
  );

  return (
    <FilterQueryContextProvider<ListingQuery>
      initialQuery={reportConfig.request.filters as ListingQuery}
      emptyQuery={EmptyListingQuery}
      loadQueryFromUrl={false}
      // Not actually used
      viewModeSettingId={UserSetting.InventoryPageViewMode}
    >
      <CatalogDataContextProvider<ListingQuery>
        queryKey="getCatalogForListing"
        getCatalogData={(c, f) => getCatalogData(c, f, false)}
        transformEventData={transformData}
      >
        <ReportMetricsContextProviderV2<ListingQuery>
          queryKey="getReportListingMetricsV2"
          getReportMetrics={getReportMetrics}
          getReportMetricsSummary={getReportMetricsSummary}
          disabled={isExport}
        >
          {children}
        </ReportMetricsContextProviderV2>
      </CatalogDataContextProvider>
    </FilterQueryContextProvider>
  );
}
