import { useState } from 'react';
import { CancelButton } from 'src/components/Buttons';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import * as Tooltip from 'src/core/ui';
import { useGetInboundEmailSellerGuidIds } from 'src/hooks/api/useGetInboundEmailSellerGuidIds';
import { CopyIcon } from 'src/svgs';
import { ContentId } from 'src/utils/constants/contentId';

import AddMessageSourceIcon from '~icons/pos/addMessageSource';

import { Modal, ModalBody, ModalFooter, ModalHeader } from '../Modal';
import * as styles from './MessagesAddSourceModal.css';

export type MessagesAddSourceModalProps = {
  isOpen?: boolean;
  onClose?: () => void;
};

export function MessagesAddSourceModal({
  isOpen,
  onClose,
}: MessagesAddSourceModalProps) {
  const guidsQuery = useGetInboundEmailSellerGuidIds();
  const [showCopiedText, setShowCopiedText] = useState(false);
  return (
    <Modal isOpen={isOpen} onClose={onClose} unmountOnClose centered size="sm">
      <ModalHeader onClose={onClose}>
        <div className={styles.headerBar}>
          <h5 className={styles.headerText}>
            <Content id={ContentId.AddMessagingSource} />
          </h5>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className={styles.modalBody}>
          <AddMessageSourceIcon width={undefined} height={40} />
          <div>
            <Content id={ContentId.AddMessagingSourceDescription} />
          </div>
          <ol className={styles.orderedList}>
            <li className={styles.orderedListItem}>
              <Content id={ContentId.AddMessagingSourceStep1} />
            </li>
            <li className={styles.orderedListItem}>
              <div>
                <Content id={ContentId.AddMessagingSourceStep2} />
              </div>
              {guidsQuery.data?.map((id) => {
                const email = `${id}@ticketoutbox.com`;
                return (
                  <div key={id} className={styles.forwardingEmailContainer}>
                    <span className={styles.forwardingEmail}>{email}</span>
                    <Tooltip.Provider>
                      <Tooltip.Root
                        onOpenChange={(open) => {
                          if (!open) {
                            setShowCopiedText(false);
                          }
                        }}
                      >
                        <Tooltip.Trigger asChild>
                          <div>
                            <CopyIcon
                              withHoverEffect
                              size={vars.iconSize.s}
                              fill="currentColor"
                              onClick={(e) => {
                                e.stopPropagation(); // stops the tooltip from disappearing on click

                                navigator.clipboard.writeText(email);
                                setShowCopiedText(true);
                              }}
                            />
                          </div>
                        </Tooltip.Trigger>
                        <Tooltip.Content
                          // XXX modal z-index is 1050
                          style={{ zIndex: 1051 }}
                          side="right"
                          onPointerDownOutside={(e) => {
                            e.preventDefault(); // stops the tooltip from disappearing on click
                          }}
                        >
                          {showCopiedText ? (
                            <Content id={ContentId.Copied} />
                          ) : (
                            <Content id={ContentId.CopyEmailAddress} />
                          )}
                        </Tooltip.Content>
                      </Tooltip.Root>
                    </Tooltip.Provider>
                  </div>
                );
              })}
            </li>
            <li className={styles.orderedListItem}>
              <Content id={ContentId.AddMessagingSourceStep3} />
            </li>
          </ol>
        </div>
      </ModalBody>
      <ModalFooter>
        <CancelButton
          variant="regular"
          textContentId={ContentId.Close}
          onClick={onClose}
        />
      </ModalFooter>
    </Modal>
  );
}
