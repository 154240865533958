import {
  ComponentProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormProvider } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { OkButton } from 'src/components/Buttons/OkButton';
import {
  EventSearch,
  EventSearchResult,
} from 'src/components/Events/EventSearch';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { getInHandDateBasedOnEvent } from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  DeliveryType,
  Event,
  EventTimeFrameFilter,
  ListingClient,
  Performer,
  Venue,
} from 'src/WebApiController';

import { CancellableFormFooter } from '../common';
import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from '../common/EventEntityHeader';
import { Summary, VenueConfigSummary } from '../common/Summary';
import {
  ModalBody,
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
  ModalFooter,
} from '../Modal/Modal.styled';
import { SeatSaverInput } from './components/SeatSaverInput';
import * as styles from './SeatSaverListingWizard.css';
import {
  SeatSaverListingDetailsInputForm,
  SeatSaverListingWizardProps,
} from './SeatSaverListingWizard.types';

export const SeatSaverListingWizardBody = ({
  disabled,
  events,
  cancelTo,
  formState,
  handleSubmit,
  watch,
  setValue,
  getValues,
  setError,
}: Omit<
  ComponentProps<
    typeof FormProvider<SeatSaverListingDetailsInputForm, unknown>
  >,
  'children'
> & { disabled?: boolean } & SeatSaverListingWizardProps) => {
  const multiEventInputsDialog = useBasicDialog();
  const { showErrorDialog, genericError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { closeModal, setModal } = useContext(ModalContext);
  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitting } = formState;
  const [curEventGroupIndex, setCurEventGroupIndex] = useState(0);
  const [shouldGroupEventsByVenueId, setShouldGroupEventsByVenueId] =
    useState<boolean>(true);
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const [showEventSearch, setShowEventSearch] = useState(!events?.length);
  const [selectedEvents, setSelectedEvents] = useState<Event[] | undefined>(
    events
  );
  const [selectedVenues, setSelectedVenues] = useState<Record<string, Venue>>(
    {}
  );
  const [selectedPerformers, setSelectedPerformer] = useState<
    Record<string, Performer>
  >({});

  const eventsGroups = useMemo(() => {
    // Group the events for display
    const eventsWithNoVenueConfig = selectedEvents?.filter(
      (ev) => !ev.venueCfgId
    );

    const eventsWithVenueConfig = (selectedEvents ?? []).filter(
      (ev) => ev.venueCfgId
    );

    let eventsGroups = [];

    if (shouldGroupEventsByVenueId) {
      const eventsGroupByVenueConfigs = eventsWithVenueConfig.reduce(
        (r, ev) => {
          const curList = r[ev.venueCfgId!];
          if (curList) {
            curList.push(ev);
          } else {
            r[ev.venueCfgId!] = [ev];
          }
          return r;
        },
        {} as Record<number, Event[]>
      );

      // now combine them
      eventsGroups = Object.values(eventsGroupByVenueConfigs).map(
        (events) => events
      );
    } else {
      eventsGroups = eventsWithVenueConfig.map((e) => [e]);
    }

    eventsWithNoVenueConfig?.forEach((ev) => {
      eventsGroups.push([ev]);
    });

    return eventsGroups;
  }, [shouldGroupEventsByVenueId, selectedEvents]);

  const onSubmit = useCallback(
    async (formValues: SeatSaverListingDetailsInputForm) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const client = new ListingClient(activeAccountWebClientConfig);

          const result = await client.createSeatSaver(formValues.inputs);

          if (isSuccess(result)) {
            await refreshExpandedListItems();
            closeModal(true);
          } else {
            showErrorDialog(
              'ListingClient.createSeatSaver',
              {
                message: result.message ?? genericError,
                status: result.status!,
              } as ApiException,
              {
                onDismissError: () => cancelTo && setModal(cancelTo),
                trackErrorData: formValues,
              }
            );
          }
        },
        (error) => {
          showErrorDialog('ListingClient.createSeatSaver', error, {
            trackErrorData: formValues,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      cancelTo,
      closeModal,
      genericError,
      refreshExpandedListItems,
      setModal,
      showErrorDialog,
    ]
  );

  const hasChanges = useCallback(
    (formValues: SeatSaverListingDetailsInputForm) => {
      return formState.defaultValues !== formValues;
    },
    [formState.defaultValues]
  );

  const requiredMsg = useContent(ContentId.Required);

  const onSubmitHandler = useCallback(() => {
    const formValues = getValues();

    const changed = hasChanges(formValues);
    if (!changed) return;

    let hasErrors = false;

    // Validate the current group to see whether they are good to proceed
    const curGroupEvents = eventsGroups[curEventGroupIndex];
    const eventIds = curGroupEvents.map((ev) => ev.viagVirtualId);
    formValues.inputs.map((input, i) => {
      if (eventIds.includes(input.event.viagVirtualId)) {
        if (!input.seatings?.length) {
          hasErrors = true;
          setError(
            `inputs.${i}.seatings`,
            { message: requiredMsg },
            { shouldFocus: true }
          );
        } else {
          input.seatings.forEach((s, j) => {
            if (!s.section) {
              hasErrors = true;
              setError(
                `inputs.${i}.seatings.${j}.section`,
                {
                  message: requiredMsg,
                },
                { shouldFocus: true }
              );
            }
            if (!s.quantity) {
              hasErrors = true;
              setError(
                `inputs.${i}.seatings.${j}.quantity`,
                {
                  message: requiredMsg,
                },
                { shouldFocus: true }
              );
            }
          });
        }
      }
    });

    if (!hasErrors && curEventGroupIndex < eventsGroups.length - 1) {
      setCurEventGroupIndex(curEventGroupIndex + 1);
    } else {
      handleSubmit(onSubmit)();
    }
  }, [
    getValues,
    hasChanges,
    eventsGroups,
    curEventGroupIndex,
    setError,
    requiredMsg,
    handleSubmit,
    onSubmit,
  ]);

  const formHasChanges = hasChanges(watch());

  const onEventSummaryClose = useCallback(() => {
    setSelectedEvents(undefined);
  }, []);

  const onEventsSelect = useCallback(
    (data: EventSearchResult[]) => {
      const changedEvents = data.map((e) => e.event);
      const newEvents = data.filter((d) => d.isSelected).map((e) => e.event);
      const unchangedEvents = (selectedEvents ?? []).filter((selectedEvent) =>
        changedEvents.every(
          (changedEvent) =>
            changedEvent.viagVirtualId !== selectedEvent.viagVirtualId
        )
      );

      setSelectedEvents([...unchangedEvents, ...newEvents]);

      let venChanged = false;
      let perfChanged = false;
      data.forEach((d) => {
        if (d.venue) {
          selectedVenues[d.event.viagVirtualId] = d.venue;
          venChanged = true;
        }
        if (d.performer) {
          selectedPerformers[d.event.viagVirtualId] = d.performer;
          perfChanged = true;
        }
      });

      if (venChanged) {
        setSelectedVenues({ ...selectedVenues });
      }
      if (perfChanged) {
        setSelectedPerformer({ ...selectedPerformers });
      }
    },
    [selectedEvents, selectedPerformers, selectedVenues]
  );

  const onNextFromEventSearch = useCallback(() => {
    const curInputs = getValues().inputs;
    const newInputs = selectedEvents?.filter((ev) =>
      curInputs.every((ci) => ci.event.viagVirtualId !== ev.viagVirtualId)
    );

    // Modify the forms
    if (newInputs) {
      setValue('inputs', [
        ...curInputs,
        ...newInputs.map((ev) => ({
          event: ev,
          seatings: [],
          quantity: 0,
          unitNetProceeds: 0,
          websitePrice: 0,
          faceValue: null,
          deliveryType: DeliveryType.InApp,
          ticketTypeRules: null,
          inHandDate: getInHandDateBasedOnEvent(ev, 2),
          autoBroadcastCreatedListing: false,
          daysBeforeEvent: 2,
          sellerOwnTickets: true,
        })),
      ]);
    }

    setShowEventSearch(false);
    setCurEventGroupIndex(0);
  }, [getValues, selectedEvents, setValue]);

  return (
    <>
      <CancellableFormHeader
        cancelTo={cancelTo}
        disabled={formState.isSubmitting}
        showDialogOnCancel={formHasChanges}
      >
        <ConnectedEventEntityHeader
          title={
            <>
              <Content id={ContentId.AddPlaceholder} />
            </>
          }
        />
      </CancellableFormHeader>

      <ModalBody>
        {showEventSearch ? (
          <ModalBodyDataContainer style={{ height: '100%' }}>
            <EventSearch
              onEventsSelect={onEventsSelect}
              selectedEvents={selectedEvents?.map((ev) => ev.viagVirtualId)}
              numEventsSelected={0}
              timeFrame={EventTimeFrameFilter.Future}
            />
          </ModalBodyDataContainer>
        ) : (
          <>
            <ModalBodyHeaderContainer>
              {eventsGroups[curEventGroupIndex].length === 1 ? (
                <Summary
                  event={eventsGroups[curEventGroupIndex][0]}
                  proposedPerformer={
                    selectedPerformers[
                      eventsGroups[curEventGroupIndex][0].viagVirtualId
                    ]
                  }
                  proposedVenue={
                    selectedVenues[
                      eventsGroups[curEventGroupIndex][0].viagVirtualId
                    ]
                  }
                  actions={
                    events == null && (
                      <div
                        onClick={
                          isLoading || isSubmitting
                            ? undefined
                            : onEventSummaryClose
                        }
                        className={styles.nowrap}
                      >
                        <CrossIcon size={vars.iconSize.m} withHoverEffect />
                      </div>
                    )
                  }
                />
              ) : (
                <VenueConfigSummary
                  events={eventsGroups[curEventGroupIndex]}
                  venues={selectedVenues}
                  performers={selectedPerformers}
                  eventSelected={eventsGroups[curEventGroupIndex][0]}
                  actions={
                    events == null && (
                      <div
                        onClick={
                          isLoading || isSubmitting
                            ? undefined
                            : onEventSummaryClose
                        }
                        className={styles.nowrap}
                      >
                        <CrossIcon size={vars.iconSize.m} withHoverEffect />
                      </div>
                    )
                  }
                />
              )}
            </ModalBodyHeaderContainer>
            <ModalBodyDataContainer>
              {isLoading ? (
                <PosSpinner />
              ) : (
                <SeatSaverInput events={eventsGroups[curEventGroupIndex]} />
              )}
            </ModalBodyDataContainer>
          </>
        )}
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          cancelTo={cancelTo}
          disabled={disabled || isLoading}
          showDialogOnCancel={formHasChanges}
        >
          {showEventSearch ? (
            <OkButton
              onClick={() => {
                const existGroupedEvents = eventsGroups.some(
                  (group) => group.length > 1
                );
                if (existGroupedEvents) {
                  // Ask the user if he wants to use grouped events
                  multiEventInputsDialog.launchDialog();
                } else {
                  // No grouped events, then proceed to the next page
                  onNextFromEventSearch();
                  multiEventInputsDialog.closeDialog();
                }
              }}
              disabled={disabled || isLoading || !selectedEvents?.length}
              textContentId={ContentId.Next}
            />
          ) : (
            <>
              {!events?.length && (
                <CancelButton
                  disabled={disabled || isLoading}
                  onClick={() => {
                    if (curEventGroupIndex === 0) {
                      setShowEventSearch(true);
                    } else {
                      setCurEventGroupIndex(curEventGroupIndex - 1);
                    }
                  }}
                  textContentId={ContentId.Back}
                />
              )}
              <OkButton
                onClick={onSubmitHandler}
                disabled={disabled || isLoading}
                textContentId={
                  curEventGroupIndex < eventsGroups.length - 1
                    ? ContentId.Next
                    : ContentId.Save
                }
              />
            </>
          )}
        </CancellableFormFooter>
      </ModalFooter>
      <ConfirmDialog
        {...multiEventInputsDialog.dialogProps}
        size="m"
        cancelText={ContentId.No}
        okText={ContentId.Yes}
        headerText={
          <Content id={ContentId.SameEventsShareSameVenueConfigTitle} />
        }
        bodyText={
          <Stack direction="column" gap="l">
            <span>
              <Content id={ContentId.SameEventsShareSameVenueConfigHeader} />
            </span>
            <span>
              <Content id={ContentId.SameEventsShareSameVenueConfigMessage1} />
            </span>
            <span>
              <Content id={ContentId.SameEventsShareSameVenueConfigMessage2} />
            </span>
          </Stack>
        }
        onCancel={() => {
          setShouldGroupEventsByVenueId(false);
          onNextFromEventSearch();
          multiEventInputsDialog.closeDialog();
        }}
        onOkay={() => {
          setShouldGroupEventsByVenueId(true);
          onNextFromEventSearch();
          multiEventInputsDialog.closeDialog();
        }}
      />
    </>
  );
};
