import { useQuery } from '@tanstack/react-query';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TagsFormBodyV2 } from 'src/components/TagsFormBody';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { AddPurchasePaymentDialog } from 'src/dialogs/AddPurchasePaymentDialog';
import { ConvertCurrencyDialog } from 'src/dialogs/ConvertCurrency';
import { ConvertCurrencyForm } from 'src/dialogs/ConvertCurrency/ConvertCurrencyDialog';
import { EditPurchasePaymentDialog } from 'src/dialogs/EditPurchasePaymentDialog';
import { PurchaseCostDialog } from 'src/dialogs/PurchaseCostDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetDefaultAutoGeneratedPaymentMethod } from 'src/hooks/useGetDefaultAutoGeneratedPaymentMethod';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  useUserCanUpdatePurchaseCommission,
  useUserCanUpdatePurchaseTags,
} from 'src/hooks/useUserHasPurchasePermissions';
import {
  BodySectionTitle,
  ListingNotesSection,
  PurchasePaymentStatusTextContainer,
  PurchaseVendorHeader,
  TicketDetailRowPaymentList,
  TicketsDetailRow,
} from 'src/modals/common';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from 'src/modals/Modal/Modal.styled';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { TicketGroupWithHandlers } from 'src/tables/PurchaseTicketTable/configs/TicketGroupTableColumnsConfig';
import { PurchaseTicketTable } from 'src/tables/PurchaseTicketTable/PurchaseTicketTable';
import { DEFAULT_AUTO_GENERATED_PAYMENT_METHOD } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { PURCHASE_PAYMENT_STATUS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { newBigIntId, newIntId } from 'src/utils/idUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { getCosts } from 'src/utils/ticketUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  AutoPoClient,
  CostDetail,
  Feature,
  Permission,
  PurchaseClient,
  PurchaseOrderCostType,
  PurchaseOrderDetails,
  PurchasePayment,
  PurchasePaymentMethod,
  PurchasePaymentMethodType,
  PurchasePaymentStatus,
  TicketGroupEditReason,
  UiMoney,
} from 'src/WebApiController';

import { CommissionSection } from './CommissionSection';
import { CostDetailsTable } from './CostDetailsTable';
import { DealSection } from './Deal/DealSection';
import { DeliverySection } from './DeliverySection/DeliverySection';
import { useGetListingAndSaleIds } from './hooks/useGetListingAndSaleIds';
import { PaymentDetailsTable } from './PaymentDetailsTable';
import * as styles from './PurchaseModal.css';

type PurchaseMainDetailsProps = {
  highlightTicketGroupIds?: number[];
  disabled?: boolean;
  allowPaymentEdits: boolean;
  onSecondaryAccountOpen: () => void;
};

export function PurchaseMainDetails({
  disabled,
  allowPaymentEdits,
  highlightTicketGroupIds,
  onTicketGroupAction,
  onSecondaryAccountOpen,
}: PurchaseMainDetailsProps &
  Pick<TicketGroupWithHandlers, 'onTicketGroupAction'>) {
  const { posEntity: purchaseOrder } =
    useActivePosEntityContext<PurchaseOrderDetails>();
  const { setValue, getValues, watch } =
    useFormContext<PurchaseOrderDetailsInput>();

  const poId = watch('id');
  const { saleIds, listingIds } = useGetListingAndSaleIds({
    ticketGroups: purchaseOrder?.ticketGroups,
  });

  const { posEntity: purchase } =
    useActivePosEntityContext<PurchaseOrderDetails>();

  const { loginContext } = useAppContext();

  const ticketGroups = watch('ticketGroups');
  const currencyCode = watch('currencyCode');

  const currency =
    currencyCode?.value ??
    loginContext?.user?.activeAccount?.currencyCode ??
    'USD';

  const payments = watch('payments');
  const additionalCosts = watch('additionalCosts');

  const [editMode, setEditMode] = useState(poId <= 0);

  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { getUiCurrency } = useLocalizationContext();
  const { totalTicketCost, totalShipping, totalCost } = getCosts(
    ticketGroups,
    additionalCosts?.value
  );

  const totalPaymentAmount = (payments?.value ?? []).reduce((acc, payment) => {
    if (payment.paymentStatus == PurchasePaymentStatus.Paid) {
      acc += payment.paymentAmount.amt;
    }
    return acc;
  }, 0);

  const { trackError } = useErrorBoundaryContext();
  const hasMarkPurchaseAsPaidFeature = useUserHasFeature(Feature.MarkPOAsPaid);

  const inboundEmailId = useQuery({
    queryKey: [
      'AutoPoClient.getAutoPoClassifiedInboundEmailIdForEntity',
      activeAccountWebClientConfig,
      poId,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new AutoPoClient(
        activeAccountWebClientConfig
      ).getAutoPoClassifiedInboundEmailIdForEntity(poId, null);
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'AutoPoClient.getAutoPoClassifiedInboundEmailIdForEntity',
          error,
          {
            purchaseOrderId: poId,
          }
        );
      },
    },
  });
  const navigate = useNavigate();

  const insertFallbackPurchasePaymentMethod = useCallback(
    async () =>
      await tryInvokeApi(
        async () => {
          return await new PurchaseClient(
            activeAccountWebClientConfig
          ).insertPurchasePaymentMethod({
            id: newIntId(), // will let backend auto generate a new one
            display: DEFAULT_AUTO_GENERATED_PAYMENT_METHOD,
            name: DEFAULT_AUTO_GENERATED_PAYMENT_METHOD,
            type: PurchasePaymentMethodType.Other,
          } as PurchasePaymentMethod);
        },
        (error) => {
          showErrorDialog('PurchaseClient.insertPurchasePaymentMethod', error, {
            trackErrorData: {
              paymentMethodName: DEFAULT_AUTO_GENERATED_PAYMENT_METHOD,
            },
          });
        }
      ),
    [activeAccountWebClientConfig, showErrorDialog]
  );

  const { defaultAutoGeneratedPaymentMethod } =
    useGetDefaultAutoGeneratedPaymentMethod();

  const onMarkPurchaseAsPaid = useCallback(async () => {
    let paymentMethodId = null;

    const vendorAccount = getValues('vendorAccount');
    if (vendorAccount?.value?.dftPayMethodId != null) {
      // if vendor account has default payment, use it
      paymentMethodId = vendorAccount.value?.dftPayMethodId;
    } else if (defaultAutoGeneratedPaymentMethod?.id != null) {
      // if fallback default payment already exist for that seller, use it
      paymentMethodId = defaultAutoGeneratedPaymentMethod?.id;
    } else {
      // if neither vendor account payment or fallback payment exists, create the fallback payment for seller account
      paymentMethodId = await insertFallbackPurchasePaymentMethod();
    }

    const existingPayments = getValues('payments');
    const newPayments = [];
    const uiCurrency = getUiCurrency(currency);

    newPayments.push({
      paymentId: newBigIntId(),
      paymentStatus: PurchasePaymentStatus.Paid,
      paymentDueDate: purchase?.poDate,
      paymentDate: purchase?.poDate,
      paymentAmount: {
        amt: totalCost - totalPaymentAmount,
        disp: null,
        currency: uiCurrency.code,
        dec: uiCurrency.dec,
      } as UiMoney,
      currencyCode: uiCurrency.code,
      paymentMethod: {
        id: paymentMethodId, // backend only need the id for inserting payment
        display: '',
        name: '',
        type: PurchasePaymentMethodType.Other,
      },
      convertedPaymentAmount: null,
      convertedCurrencyCode: null,
      conversionRate: null,
      conversionDate: null,
      conversionUserId: null,
    } as PurchasePayment);

    if (existingPayments == null) {
      setValue('payments', posChangedField(newPayments));
    } else {
      setValue(
        'payments',
        posChangedField([...existingPayments!.value!, ...newPayments])
      );
    }
  }, [
    setValue,
    getValues,
    currency,
    defaultAutoGeneratedPaymentMethod,
    getUiCurrency,
    insertFallbackPurchasePaymentMethod,
    purchase,
    totalCost,
    totalPaymentAmount,
  ]);

  return (
    <>
      <ModalBodyHeaderContainer>
        <PurchaseVendorHeader
          editMode={editMode}
          disabled={disabled}
          onToggleEditDetails={() => setEditMode(!editMode)}
          numOfSales={saleIds.size}
          numOfListings={listingIds.size}
          onGoToMessageThread={
            inboundEmailId.data
              ? () => {
                  navigate(`/messages/${inboundEmailId.data}`);
                }
              : undefined
          }
          enableMarkAsPaid={
            hasMarkPurchaseAsPaidFeature && totalCost > totalPaymentAmount
          }
          onMarkPurchaseAsPaid={onMarkPurchaseAsPaid}
        />
      </ModalBodyHeaderContainer>
      <ModalBodyDataContainer>
        <PurchaseMainDetailsBody
          disabled={disabled}
          allowPaymentEdits={allowPaymentEdits}
          numOfSales={saleIds.size}
          highlightTicketGroupIds={highlightTicketGroupIds}
          onTicketGroupAction={onTicketGroupAction}
          onSecondaryAccountOpen={onSecondaryAccountOpen}
        />
      </ModalBodyDataContainer>
    </>
  );
}

function PurchaseMainDetailsBody({
  disabled,
  allowPaymentEdits,
  highlightTicketGroupIds,
  onTicketGroupAction,
  onSecondaryAccountOpen,
}: PurchaseMainDetailsProps &
  Pick<TicketGroupWithHandlers, 'onTicketGroupAction'> & {
    numOfSales: number;
  }) {
  const { setValue, getValues, watch } =
    useFormContext<PurchaseOrderDetailsInput>();
  const { posEntity: purchase } =
    useActivePosEntityContext<PurchaseOrderDetails>();

  const canConvertPaymentLines = useUserHasFeature(Feature.ConvertPaymentLines);

  const canCreateTagType = useUserHasAnyOfPermissions(
    Permission.Purchases_AddTagType
  );
  const canEditTags = useUserCanUpdatePurchaseTags(purchase);
  const canEditCommission = useUserCanUpdatePurchaseCommission(purchase);

  const { loginContext } = useAppContext();
  const currentUserId = loginContext?.user?.userId!;
  const costDialog = useBasicDialog();
  const addPaymentDialog = useBasicDialog();
  const editPaymentDialog = useBasicDialog();
  const convertCurrencyDialog = useBasicDialog();
  const [currentCostModel, setCurrentCostModel] = useState<CostDetail>();
  const [currentPayment, setCurrentPayment] = useState<PurchasePayment>();

  const ticketGroups = watch('ticketGroups');
  const currencyCode = watch('currencyCode');

  const currency =
    currencyCode?.value ??
    loginContext?.user?.activeAccount?.currencyCode ??
    'USD';

  const payments = watch('payments');
  const secondaryVendor = watch('secondaryVendor');
  const secondaryVendorAccount = watch('secondaryVendorAccount');
  const additionalCosts = watch('additionalCosts');
  const tags = watch('tags');
  const internalNotes = watch('privateNotes');

  const onSetPaymentPaid = useCallback(
    ({ paymentId }: PurchasePayment) => {
      const index = payments?.value?.findIndex(
        (p) => p.paymentId === paymentId
      );
      if (index != null && index >= 0) {
        const newPaymentDate = new Date().toISOString();
        if (newPaymentDate > payments!.value![index].paymentDueDate) {
          setValue(
            `payments.value.${index}.paymentStatus`,
            PurchasePaymentStatus.RefundNeeded
          );
        } else {
          setValue(
            `payments.value.${index}.paymentStatus`,
            PurchasePaymentStatus.Paid
          );
        }

        setValue(`payments.value.${index}.paymentDate`, newPaymentDate);
        setValue(`payments.hasChanged`, true);
      }
    },
    [payments, setValue]
  );
  const onEditPayment = useCallback(
    (payment: PurchasePayment) => {
      setCurrentPayment(payment);

      editPaymentDialog.launchDialog();
    },
    [editPaymentDialog]
  );
  const onEditPaymentSave = useCallback(
    (payment: PurchasePayment) => {
      const existingPayment = payments?.value?.find(
        (p) => p.paymentId === payment.paymentId
      );
      if (existingPayment) {
        payment.paymentId = existingPayment.paymentId;
        Object.assign(existingPayment, payment);

        setValue('payments', posChangedField(payments!.value));
      }

      editPaymentDialog.closeDialog();
    },
    [payments, editPaymentDialog, setValue]
  );
  const onEditPaymentCancel = useCallback(() => {
    setCurrentPayment(undefined);
    editPaymentDialog.closeDialog();
  }, [editPaymentDialog]);

  const onRemovePayment = useCallback(
    ({ paymentId }: PurchasePayment) => {
      const newPayments = payments?.value?.filter(
        (p) => p.paymentId !== paymentId
      );
      if (newPayments && newPayments?.length !== payments?.value?.length) {
        setValue('payments', posChangedField(newPayments));
      }

      editPaymentDialog.closeDialog();
    },
    [payments?.value, editPaymentDialog, setValue]
  );

  const onAddNewCost = useCallback(() => {
    setCurrentCostModel({
      id: newBigIntId(),
      cost: null,
      costType: PurchaseOrderCostType.Tax,
      date: new Date().toISOString(),
      description: null,
    } as CostDetail);

    costDialog.launchDialog();
  }, [costDialog, setCurrentCostModel]);

  const onAddNewCostSave = useCallback(
    (cost: CostDetail) => {
      const existingCost = additionalCosts?.value?.find(
        (p) => p.id === cost.id
      );
      if (existingCost) {
        existingCost.cost = cost.cost;
        existingCost.costType = cost.costType;
        existingCost.date = cost.date;
        existingCost.description = cost.description;

        setValue('additionalCosts', posChangedField(additionalCosts!.value));
      } else {
        setValue(
          'additionalCosts',
          posChangedField([...(additionalCosts?.value ?? []), cost])
        );
      }
      costDialog.closeDialog();
    },
    [additionalCosts, costDialog, setValue]
  );

  const onAddNewCostCancel = useCallback(() => {
    setCurrentCostModel(undefined);
    costDialog.closeDialog();
  }, [costDialog]);

  const onAddNewPayment = useCallback(() => {
    addPaymentDialog.launchDialog();
  }, [addPaymentDialog]);

  const onAddNewPaymentSave = useCallback(
    (payments: PurchasePayment[]) => {
      const existingPayments = getValues('payments');
      if (existingPayments == null) {
        setValue('payments', posChangedField(payments));
      } else {
        setValue(
          'payments',
          posChangedField([...existingPayments!.value!, ...payments])
        );
      }
      addPaymentDialog.closeDialog();
    },
    [setValue, getValues, addPaymentDialog]
  );

  const onAddNewPaymentCancel = useCallback(() => {
    addPaymentDialog.closeDialog();
  }, [addPaymentDialog]);

  const onEditCost = useCallback(
    (cost: CostDetail) => {
      setCurrentCostModel(cost);

      costDialog.launchDialog();
    },
    [costDialog]
  );

  const onRemoveCost = useCallback(
    (costId: number) => {
      const newCosts = additionalCosts?.value?.filter((p) => p.id !== costId);
      if (newCosts && newCosts?.length !== additionalCosts?.value?.length) {
        setValue('additionalCosts', posChangedField(newCosts));
      }
    },
    [additionalCosts, setValue]
  );

  const onInternalNotesChanged = useCallback(
    (notes?: string | null) => {
      return setValue('privateNotes', posChangedField(notes ?? null));
    },
    [setValue]
  );

  const onConvertCurrency = useCallback(
    (payment: PurchasePayment) => {
      setCurrentPayment(payment);
      convertCurrencyDialog.launchDialog();
    },
    [convertCurrencyDialog]
  );

  const onCancelConvertCurrency = useCallback(() => {
    convertCurrencyDialog.closeDialog();
    setCurrentPayment(undefined);
  }, [convertCurrencyDialog]);

  const onSaveConvertCurrency = useCallback(
    (formValues: ConvertCurrencyForm) => {
      if (currentPayment && payments) {
        const updatedPayment = Object.assign({}, currentPayment, formValues);
        const filteredPayments = (payments.value ?? []).map((payment) =>
          payment.paymentId === currentPayment.paymentId
            ? updatedPayment
            : payment
        );
        setValue('payments', posChangedField(filteredPayments));
      }
      convertCurrencyDialog.closeDialog();
    },
    [convertCurrencyDialog, currentPayment, payments, setValue]
  );

  return (
    <>
      <div className={styles.bodySection}>
        <DealSection />
        <BodySectionTitle>
          <Content id={ContentId.TicketsPurchased} />
          {purchase?.pmtStat && (
            <PurchasePaymentStatusTextContainer className={purchase.pmtStat}>
              <Content id={PURCHASE_PAYMENT_STATUS_TO_CID[purchase.pmtStat]} />
            </PurchasePaymentStatusTextContainer>
          )}
        </BodySectionTitle>

        <PurchaseTicketTable
          disabled={disabled}
          currencyCode={currencyCode?.value ?? purchase?.currency ?? 'USD'}
          ticketGroups={ticketGroups}
          highlightTicketGroupIds={highlightTicketGroupIds}
          onTicketGroupAction={onTicketGroupAction}
        />
        {!disabled && onTicketGroupAction && (
          <TicketsDetailRow>
            <Button
              variant={'link'}
              onClick={() =>
                onTicketGroupAction({
                  ticketGroupId: -1,
                  action: TicketGroupEditReason.AddTickets,
                })
              }
            >
              <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
              <Content
                id={
                  ticketGroups?.length
                    ? ContentId.AddTicketsForAnotherEvent
                    : ContentId.AddTickets
                }
              />
            </Button>
          </TicketsDetailRow>
        )}
      </div>
      {secondaryVendor && secondaryVendorAccount && (
        <DeliverySection
          disabled={disabled}
          vendor={secondaryVendor.value}
          vendorAccount={secondaryVendorAccount.value}
          onChangeClick={onSecondaryAccountOpen}
        />
      )}
      <div className={styles.bodySection}>
        <BodySectionTitle>
          <Content id={ContentId.CostAndCredits} />
        </BodySectionTitle>
        <TicketsDetailRow>
          <CostDetailsTable
            disabled={disabled}
            currencyCode={currency}
            ticketGroups={ticketGroups}
            costs={additionalCosts?.value}
            onEditCost={onEditCost}
            onAddNewCost={onAddNewCost}
            onRemoveCost={onRemoveCost}
          />
        </TicketsDetailRow>
      </div>
      <CommissionSection disabled={disabled} readonly={!canEditCommission} />
      <div className={styles.bodySection}>
        <BodySectionTitle>
          <Content id={ContentId.Payments} />
        </BodySectionTitle>
        <TicketDetailRowPaymentList>
          <PaymentDetailsTable
            disabled={!allowPaymentEdits}
            currencyCode={currency}
            payments={payments?.value}
            onSetAsPaid={onSetPaymentPaid}
            onEditPayment={onEditPayment}
            onRemovePayment={onRemovePayment}
            onConvertCurrency={onConvertCurrency}
          />
        </TicketDetailRowPaymentList>
        {allowPaymentEdits && (
          <TicketsDetailRow>
            <Button variant={'link'} onClick={onAddNewPayment}>
              <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
              <Content id={ContentId.AddLineItem} />
            </Button>
          </TicketsDetailRow>
        )}
      </div>
      <div className={styles.bodySection}>
        <BodySectionTitle>
          <Content id={ContentId.Notes} />
        </BodySectionTitle>
        <ListingNotesSection
          internalNotes={internalNotes?.value}
          disabled={disabled}
          onInternalNotesChanged={onInternalNotesChanged}
        />
      </div>
      <div className={styles.bodySection}>
        <BodySectionTitle>
          <Content id={ContentId.Tags} />
        </BodySectionTitle>

        <TagsFormBodyV2
          tagsCurrentEntity={tags?.value ?? []}
          onTagsUpdate={(tags) => {
            setValue('tags', { value: tags, hasChanged: true });
          }}
          disabled={disabled}
          entityType={ActionOutboxEntityType.Purchase}
          canCreateTagType={canCreateTagType}
          canEditTags={canEditTags}
        />
      </div>

      {costDialog.dialogProps.isOpen && (
        <PurchaseCostDialog
          {...costDialog.dialogProps}
          cost={currentCostModel!}
          currencyCode={currency}
          onSave={onAddNewCostSave}
          onClosed={onAddNewCostCancel}
        />
      )}

      {addPaymentDialog.dialogProps.isOpen && (
        <AddPurchasePaymentDialog
          {...addPaymentDialog.dialogProps}
          defaultCurrencyCode={currency}
          onSave={onAddNewPaymentSave}
          onCancel={onAddNewPaymentCancel}
        />
      )}
      {editPaymentDialog.dialogProps.isOpen && currentPayment && (
        <EditPurchasePaymentDialog
          {...editPaymentDialog.dialogProps}
          onSave={onEditPaymentSave}
          onCancel={onEditPaymentCancel}
          payment={currentPayment}
        />
      )}

      {canConvertPaymentLines &&
        convertCurrencyDialog.dialogProps.isOpen &&
        currentPayment && (
          <ConvertCurrencyDialog
            {...convertCurrencyDialog.dialogProps}
            onSave={onSaveConvertCurrency}
            onCancel={onCancelConvertCurrency}
            payment={currentPayment}
            currentUserId={currentUserId}
          />
        )}
    </>
  );
}
