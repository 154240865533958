import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { DivWithTitle } from 'src/core/ui/DivWithTitle';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { FormatOption } from 'src/modals/EditTableColumns';
import { TableShimmeringDiv } from 'src/tables/Table/Table.styled';
import { getColumnPersonalization } from 'src/utils/columns/columnPersonalizationUtils';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { applyNumberFormatting } from 'src/utils/numberFormatter';
import { SectionType } from 'src/utils/types/sectionType';
import { ReportTag, Tag } from 'src/WebApiController';

export const CustomNumericCell = ({
  columnId,
  userDefinedPrecision,
  value,
  isLoading,
  hide,
  shimmeringDivHeight,
  sectionType,
  matchingCustomColumn,
  matchingTag,
  currencyCode,
}: {
  columnId: string;
  userDefinedPrecision: FormatOption | undefined;
  value: number | null;
  isLoading: boolean;
  hide?: boolean;
  shimmeringDivHeight: string;
  sectionType: SectionType;
  matchingCustomColumn?: CustomListingColumn | CustomSalesColumn;
  matchingTag?: Tag | ReportTag;
  currencyCode?: string;
}) => {
  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = getUiCurrency(
    currencyCode ?? loginContext?.user?.activeAccount.currencyCode
  );

  const { isCurrency, isPercent } = getColumnPersonalization({
    id: columnId,
    sectionType,
    matchingCustomColumn,
    matchingTag,
  });

  if (isLoading) {
    return <TableShimmeringDiv height={shimmeringDivHeight} />;
  }

  if (hide) {
    return null;
  }

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <DivWithTitle>
        {value == null ? (
          ''
        ) : isNaN(value) || !isFinite(value) ? (
          <Content id={ContentId.NA} />
        ) : (
          <>
            {applyNumberFormatting({
              precision: userDefinedPrecision,
              inputNumber: value,
              currencyCode: isCurrency ? uiCurrency.code : undefined,
              currencyDecimalDigits: isCurrency ? uiCurrency.dec : undefined,
              isPercent: isPercent && !isCurrency,
            })}
          </>
        )}
      </DivWithTitle>
    </TableCellDiv>
  );
};
