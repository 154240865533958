import { isEmpty } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useMeasure } from 'react-use';
import {
  RowRelativeScoreDisplay,
  RowRelativeScoreInfo,
} from 'src/components/Events/RowRelativeScoreDisplay';
import { SerializedSegment } from 'src/core/POS/BezierGraph';
import { CalcOutputFn } from 'src/core/POS/BezierGraph/BezierGraphManager';
import { GraphSegments } from 'src/modals/EventSeatMapConfig/EventSeatMapConfigBody';

import {
  ChangeRowScoreBody,
  DEFAULT_ROW_GAP_PX,
  getUpdatedRowData,
  GRAPH_WIDTH_PERCENTAGE,
  INITIAL_ROW_SCORE_SEGMENTS,
  ROW_GAP_RATIO,
} from './ChangeRowScoreBody';
import * as styles from './ChangeRowScoreDialog.css';

export type ChangeRowScoreBodySingleSectionProps = {
  sectionId: number;
  rowDataToEdit: RowRelativeScoreInfo[];
  setRowDataToEdit: React.Dispatch<
    React.SetStateAction<RowRelativeScoreInfo[]>
  >;
  configPayload: string | null;
  setConfigPayload: (configPayload: string | null) => void;
};

export const ChangeRowScoreBodySingleSection = ({
  sectionId,
  rowDataToEdit,
  setRowDataToEdit,
  configPayload,
  setConfigPayload,
}: ChangeRowScoreBodySingleSectionProps) => {
  const [singleRowRef, { height: rowHeight }] = useMeasure<HTMLDivElement>();
  const gapHeight: number = useMemo(() => {
    if (!rowHeight) {
      return DEFAULT_ROW_GAP_PX;
    }
    return rowHeight * ROW_GAP_RATIO;
  }, [rowHeight]);

  const calcScoreOutputRef = useRef<CalcOutputFn>();
  const exportScorePathRef = useRef<() => SerializedSegment[]>();
  const [isNumRowsChanged, setIsNumRowsChanged] = useState(false);

  const graphSegments = useMemo(() => {
    if (configPayload) {
      try {
        const obj = JSON.parse(configPayload) as GraphSegments;
        return obj;
      } catch (e) {
        console.warn(`Unable to parse as GraphSegments: ${configPayload}`);
        return {};
      }
    }
  }, [configPayload]);

  const onUpdateRowScores = useCallback(
    (segments: paper.Segment[]) => {
      const calcScoreOutput = calcScoreOutputRef.current;
      if (calcScoreOutput) {
        const newRowDataToEdit = getUpdatedRowData(
          segments,
          calcScoreOutput,
          rowDataToEdit,
          rowHeight,
          gapHeight
        );
        setRowDataToEdit(newRowDataToEdit);

        const configObj = { ...graphSegments };

        configObj.scoreSegmentsBySection =
          configObj.scoreSegmentsBySection ?? {};
        configObj.activeRowIdsBySection = configObj.activeRowIdsBySection ?? {};
        configObj.baseRowIdBySection = configObj.baseRowIdBySection ?? {};

        configObj.scoreSegmentsBySection[sectionId.toString()] =
          exportScorePathRef.current?.();
        configObj.activeRowIdsBySection[sectionId.toString()] =
          rowDataToEdit.map((r) => r.rowId!);
        configObj.baseRowIdBySection[sectionId.toString()] = rowDataToEdit.find(
          (r) => r.isBase
        )?.rowId;

        setConfigPayload(JSON.stringify(configObj));
      }
    },
    [
      gapHeight,
      graphSegments,
      rowDataToEdit,
      rowHeight,
      sectionId,
      setConfigPayload,
      setRowDataToEdit,
    ]
  );

  const rowList = rowDataToEdit.map((r, i) => (
    <RowRelativeScoreDisplay
      key={i}
      ref={i === 0 ? singleRowRef : undefined}
      rowRelativeScoreInfo={r}
      graphWidthPercentage={GRAPH_WIDTH_PERCENTAGE}
    />
  ));

  const initializedRef = useRef<boolean>();
  useEffect(() => {
    const segmentsConfig = graphSegments?.scoreSegmentsBySection?.[sectionId];
    if (
      !isEmpty(segmentsConfig) &&
      !initializedRef.current &&
      calcScoreOutputRef.current
    ) {
      onUpdateRowScores(segmentsConfig as paper.Segment[]);
      initializedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(graphSegments), sectionId, onUpdateRowScores]);

  return (
    <div className={styles.rowScoreGraphicBodyContainer}>
      <ChangeRowScoreBody
        rowList={rowList}
        initialSegments={
          graphSegments?.scoreSegmentsBySection?.[sectionId.toString()] ??
          INITIAL_ROW_SCORE_SEGMENTS
        }
        calcScoreOutputRef={calcScoreOutputRef}
        exportScorePathRef={exportScorePathRef}
        rowDataToEdit={rowDataToEdit}
        gapHeight={gapHeight}
        onUpdateRowScores={onUpdateRowScores}
        onResized={({ segments }) => {
          if (isNumRowsChanged) {
            setIsNumRowsChanged(false);
            onUpdateRowScores(segments);
          }
        }}
      />
    </div>
  );
};
