import { Content } from 'src/contexts/ContentContext';
import { ContentId } from 'src/utils/constants/contentId';

import { EventEntityHeader } from '../common/EventEntityHeader';
import { ModalProps } from '../Modal';
import { ClassifyEmail, ClassifyEmailProps } from './ClassifyEmail';

export * from './ClassifyEmail';

export const getClassifyEmailModalConfig = (
  props: ClassifyEmailProps
): ModalProps => ({
  Header: (
    <EventEntityHeader title={<Content id={ContentId.ClassifyMessage} />} />
  ),
  Body: <ClassifyEmail {...props} />,
  clickOutsideToClose: true,
  size: 'fullscreen',
});
