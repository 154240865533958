import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { PageSection } from 'src/components/common/PageSection';
import { ConnectedNotificationDisplay } from 'src/components/ConnectedNotificationDisplay';
import { Content } from 'src/contexts/ContentContext';
import { useNotificationsContext } from 'src/contexts/NotificationsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Card } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import IconAlarmAdd from '~icons/pos/alarmAdd';

import * as styles from './NotificationsSection.css';

export function NotificationsSection() {
  const { notifications, notificationsIsInitialLoading, dismissNotification } =
    useNotificationsContext();
  const recentNotifications = useMemo(
    () => notifications.slice(0, 4),
    [notifications]
  );

  return (
    <PageSection>
      <PageSection.Header
        action={
          <Link className={styles.link} to="/notifications">
            <Content id={ContentId.SeeAll} /> ({notifications.length})
          </Link>
        }
      >
        <Content id={ContentId.Notifications} />
      </PageSection.Header>
      <Card>
        {notificationsIsInitialLoading ? (
          <EmptySectionContent.Root>
            <PosSpinner />
          </EmptySectionContent.Root>
        ) : recentNotifications.length === 0 ? (
          <EmptySectionContent.Root
            icon={<IconAlarmAdd width="32px" height="34px" />}
          >
            <Content id={ContentId.NoNotifications} />
          </EmptySectionContent.Root>
        ) : (
          <div className={styles.content}>
            {recentNotifications.map((notification) => (
              <div
                key={notification.notificationId}
                className={styles.notificationWrapper}
              >
                <ConnectedNotificationDisplay
                  notification={notification}
                  onDismiss={() => {
                    dismissNotification(notification.notificationId);
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </Card>
    </PageSection>
  );
}
