import clsx from 'clsx';
import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePurchaseVendorSelector } from 'src/components/Selectors/PurchaseVendorSelector/usePurchaseVendorSelector';
import { Content } from 'src/contexts/ContentContext';
import { MultiSelectDropBoxWithPills } from 'src/core/POS/MultiSelectDropBoxWithPills';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { shared, vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { useAutoPoRestrictionSettings } from 'src/hooks/api/useAutoPoRestrictionSettings';
import { useKnownCountries } from 'src/hooks/api/useKnownCountries';
import { ContentId } from 'src/utils/constants/contentId';
import {
  AutoPoSellerAccountCountryRestriction,
  AutoPoSellerAccountVendorRestriction,
} from 'src/WebApiController';

import * as styles from './PurchaseOrderSetting.css';
import {
  sortCountryRestrictions,
  sortVendorRestrictions,
} from './PurchaseOrderSetting.utils';

type RestictionFormFieldValues = {
  countryRestrictions: AutoPoSellerAccountCountryRestriction[];
  vendorRestrictions: AutoPoSellerAccountVendorRestriction[];
};

const enum IncludeExcludeOptions {
  Include = 'include',
  Exclude = 'exclude',
}

const getIncludeExcludeOptions = () => {
  const includeText = <Content id={ContentId.Include} />;
  const excludeText = <Content id={ContentId.Exclude} />;
  return [
    { value: IncludeExcludeOptions.Include, children: includeText },
    { value: IncludeExcludeOptions.Exclude, children: excludeText },
  ];
};

export function AutoPoRestictionForm() {
  const {
    countryRestrictionMutation,
    countryRestrictionQuery,
    vendorRestrictionMutation,
    vendorRestrictionQuery,
  } = useAutoPoRestrictionSettings();

  const [isLoading, setIsLoading] = useState(false);

  const [includeExclude, setIncludeExclude] = useState<IncludeExcludeOptions>(
    IncludeExcludeOptions.Include
  );

  const { query: vendorQuery, posSelectProps: vendorSelectProps } =
    usePurchaseVendorSelector({});

  const knownCountriesQuery = useKnownCountries();

  const knownCountriesOption = useMemo(() => {
    if (knownCountriesQuery.data == null) {
      return {};
    }

    return Object.entries(knownCountriesQuery.data).reduce(
      (r, [key, value]) => {
        r[key] = value;
        return r;
      },
      {} as Record<string, string>
    );
  }, [knownCountriesQuery.data]);

  const formDefaultValues = useMemo(() => {
    return {
      countryRestrictions: (countryRestrictionQuery.data ?? []).sort(
        sortCountryRestrictions
      ),
      vendorRestrictions: (vendorRestrictionQuery.data ?? []).sort(
        sortVendorRestrictions
      ),
    };
  }, [countryRestrictionQuery.data, vendorRestrictionQuery.data]);

  useEffect(() => {
    if (vendorRestrictionQuery.data) {
      let newIncludeExclude = IncludeExcludeOptions.Include;
      if (
        vendorRestrictionQuery.data.every((r) => !r.isIncluded) &&
        vendorRestrictionQuery.data.length > 0
      ) {
        newIncludeExclude = IncludeExcludeOptions.Exclude;
      }

      if (newIncludeExclude !== includeExclude) {
        setIncludeExclude(newIncludeExclude);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorRestrictionQuery.data]);

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { defaultValues },
  } = useForm<RestictionFormFieldValues>({
    defaultValues: formDefaultValues,
  });

  useEffect(() => {
    reset(formDefaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDefaultValues]);

  const countryRestrictions = watch('countryRestrictions');
  const vendorRestrictions = watch('vendorRestrictions');

  const onSubmitHandler = useCallback(
    async (data: RestictionFormFieldValues) => {
      setIsLoading(true);
      try {
        // if country dirty, then update country
        if (
          !isEqual(data.countryRestrictions, defaultValues?.countryRestrictions)
        ) {
          await countryRestrictionMutation.mutateAsync({
            restrictions: data.countryRestrictions,
          });
        }

        // We only trim the data when submitting, so that we keep the data when user is switching between include/exclude
        const vendorRestrictionsForSumbit = data.vendorRestrictions
          .filter((r) => {
            if (includeExclude === IncludeExcludeOptions.Include) {
              return r.isIncluded;
            }
            return !r.isIncluded;
          })
          .sort(sortVendorRestrictions);

        // if vendor dirty, then update vendor
        if (
          !isEqual(
            vendorRestrictionsForSumbit,
            defaultValues?.vendorRestrictions
          )
        ) {
          await vendorRestrictionMutation.mutateAsync({
            restrictions: vendorRestrictionsForSumbit,
          });
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      countryRestrictionMutation,
      defaultValues?.countryRestrictions,
      defaultValues?.vendorRestrictions,
      includeExclude,
      vendorRestrictionMutation,
    ]
  );

  const includedCountryCodes = useMemo(() => {
    return countryRestrictions.filter((r) => r.isEnabled).map((r) => r.country);
  }, [countryRestrictions]);

  const includedVendorIds = useMemo(() => {
    return vendorRestrictions
      .filter((r) => r.isIncluded)
      .map((r) => r.vendorId.toString());
  }, [vendorRestrictions]);

  const excludedVendorIds = useMemo(() => {
    return vendorRestrictions
      .filter((r) => !r.isIncluded)
      .map((r) => r.vendorId.toString());
  }, [vendorRestrictions]);

  const onIncludedVendorChange = useCallback(
    (values: string[]) => {
      // If select all, then we clear the list
      const isAllSelected =
        values.length >=
        Object.keys(vendorSelectProps.valueOptionsContent).length;

      const newVendorRestrictions = [
        ...(isAllSelected
          ? []
          : values.map((id) => ({
              vendorId: parseInt(id),
              isIncluded: true,
            }))),
        // Remove them from excluded list
        ...vendorRestrictions.filter(
          (r) => !r.isIncluded && !values.includes(r.vendorId.toString())
        ),
      ].sort(sortVendorRestrictions);

      setValue('vendorRestrictions', newVendorRestrictions);
    },
    [setValue, vendorRestrictions, vendorSelectProps.valueOptionsContent]
  );

  const onExcludedVendorChange = useCallback(
    (values: string[]) => {
      const newVendorRestrictions = [
        // Remove them from included list
        ...vendorRestrictions.filter(
          (r) => r.isIncluded && !values.includes(r.vendorId.toString())
        ),
        ...values.map((id) => ({
          vendorId: parseInt(id),
          isIncluded: false,
        })),
      ].sort(sortVendorRestrictions);

      setValue('vendorRestrictions', newVendorRestrictions);
    },
    [setValue, vendorRestrictions]
  );

  const onIncludedCountryChange = useCallback(
    (values: string[]) => {
      // If select all, then we clear the list
      const isAllSelected =
        values.length >= Object.keys(knownCountriesOption).length;

      const newCountryRestrictions = isAllSelected
        ? []
        : values
            .map((countryCode) => ({
              country: countryCode,
              isEnabled: true,
            }))
            .sort(sortCountryRestrictions);

      setValue('countryRestrictions', newCountryRestrictions);
    },
    [knownCountriesOption, setValue]
  );

  const onIncludeExcludeChange = useCallback((value: string) => {
    if (!value) {
      return;
    }

    setIncludeExclude(value as IncludeExcludeOptions);
  }, []);

  if (
    countryRestrictionQuery.isLoading ||
    vendorQuery.isLoading ||
    knownCountriesQuery.isLoading
  ) {
    return <PosSpinner />;
  }

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.sectionName}>
        <Content id={ContentId.AutoPoConfiguration} />
      </div>
      <div className={styles.form}>
        <p className={styles.subtleText}>
          <Content id={ContentId.AutoPoConfigurationDescription} />
        </p>
        <p className={clsx(styles.text, shared.typography.title5)}>
          <Content id={ContentId.Vendor} />
        </p>
        <p className={styles.subtleText}>
          <Content id={ContentId.AutoPoVendorConfigutationDescription} />
        </p>
        <ToggleGroup
          style={{ width: 'min-content' }}
          itemStyle={{
            fontSize: vars.typography.fontSize.base,
            height: '22px',
          }}
          disabled={isLoading}
          options={getIncludeExcludeOptions()}
          value={includeExclude}
          onValueChange={onIncludeExcludeChange}
        />

        {includeExclude === IncludeExcludeOptions.Include ? (
          <PosFormField className={styles.formTitle}>
            <MultiSelectDropBoxWithPills
              {...vendorSelectProps}
              loading={vendorQuery.isLoading}
              style={{ width: '100%' }}
              side="top"
              placeholderText={ContentId.IncludeAllVendors}
              values={includedVendorIds}
              onChange={onIncludedVendorChange}
              searchable
              flexWrap={'wrap'}
              closeAfterToggleAll
            />
          </PosFormField>
        ) : (
          <PosFormField className={styles.formTitle}>
            <MultiSelectDropBoxWithPills
              {...vendorSelectProps}
              loading={vendorQuery.isLoading}
              style={{ width: '100%' }}
              side="top"
              placeholderText={ContentId.None}
              values={excludedVendorIds}
              onChange={onExcludedVendorChange}
              searchable
              flexWrap={'wrap'}
            />
          </PosFormField>
        )}

        <p className={clsx(styles.text, shared.typography.title5)}>
          <Content id={ContentId.Country} />
        </p>

        <p className={styles.subtleText}>
          <Content id={ContentId.IncludedCountries} />
        </p>
        <PosFormField className={styles.formTitle}>
          <MultiSelectDropBoxWithPills
            valueOptionsContent={knownCountriesOption}
            sortMode="ascending"
            loading={vendorQuery.isLoading}
            style={{ width: '100%' }}
            side="top"
            placeholderText={ContentId.IncludeAllCountries}
            values={includedCountryCodes}
            onChange={onIncludedCountryChange}
            searchable
            flexWrap={'wrap'}
            closeAfterToggleAll
          />
        </PosFormField>

        <div className={styles.footer}>
          <Button disabled={isLoading} onClick={handleSubmit(onSubmitHandler)}>
            <Content id={ContentId.Save} />
          </Button>
        </div>
      </div>
    </div>
  );
}
