import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Detail, DetailGroup, SectionContent } from 'src/modals/common';
import { ListingLinksDetail } from 'src/modals/SaleDetails/components/ListingLinksDetail';
import { useGetModalGridFormat } from 'src/modals/SaleDetailsV2/hooks/useGetModalGridFormat';
import { ContentId } from 'src/utils/constants/contentId';
import { SaleDetails } from 'src/WebApiController';

interface TicketsSoldAsProps {
  sale: SaleDetails;
}

export const TicketsSoldAs = ({ sale }: TicketsSoldAsProps) => {
  const { SectionContentGridFormat } = useGetModalGridFormat();

  if (!sale.mkpSeating) {
    return null;
  }
  const { section, row, seatFr, seatTo } = sale.mkpSeating;
  return (
    <SectionContent format={SectionContentGridFormat}>
      <ListingLinksDetail
        originatedFromListingId={sale.originatedFromListingId}
      />

      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.Seating} />}
          detail={
            <SeatingInfo
              section={section ?? ''}
              row={row ?? ''}
              fontSize={vars.typography.fontSize.base}
              seatFr={seatFr}
              seatTo={seatTo}
            />
          }
        />
      </DetailGroup>

      <DetailGroup>
        <Detail
          isStrong
          label={<Content id={ContentId.QuantityAbbreviated} />}
          detail={<strong>x {sale.qtySold}</strong>}
        />
      </DetailGroup>
    </SectionContent>
  );
};
