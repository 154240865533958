import { AdCampaignEntity, UiMoney } from 'src/WebApiController';

export const SHIMMERING_DIV_HEIGHT_SINGLE_ADCAMPAIGNS = '40px';

type ReportingMetrics = {
  adSpend: UiMoney | null | undefined;
  salesProceeds: UiMoney | null | undefined;
  qtySold: number | null | undefined;
};

export type AdCampaignEntityWithMetrics = AdCampaignEntity & ReportingMetrics;
