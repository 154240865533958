import { FormulaExecutable } from 'src/utils/columns/customColumnUtils';
import { Listing, Tag, UiMoney } from 'src/WebApiController';

import {
  LISTING_FORMULA_FIELDS_TO_CID,
  ListingFormulaFieldsListing,
} from '../../constants/contentIdMaps';
import { getAllInPriceFromListPrice } from '../../inventoryUtils';
import { ColumnPersonalizationFlags } from '../columnUtils.types';
import {
  getPersonalizationFlagsFromFormulaBase,
  MathematicalToken,
  parseMathematicalTokensFromFormulaBase,
} from '../customColumnMathUtils';
import {
  evaluateFormulaBase,
  FormulaEditable,
  fromExecutableFormula,
  parseEditableFormulaBase,
  parseExecutableFormula,
  tagFieldNameToKey,
  tagKeyToFieldName,
  validateFormulaSingleStatement,
} from '../customColumnUtils';
import { LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from './inventoryColumnUtils.constants';
import { ListingTableColumnId } from './inventoryColumnUtils.types';
import { CustomListingColumn } from './inventoryCustomColumnUtils.types';

/**
 * Type dedicated for format evaluation to decouple dependency with `Listing` type
 * Consists of numeric fields from `Listing` supported to add into formula
 *
 * Read before changing:
 * ONLY SAFE WHEN adding fields to this type,
 * changing/removing any field may break the evaluation of existing formula stored in DB
 */
export enum ListingFormulaFields {
  availableQuantity = 'availableQuantity',
  soldQuantity = 'soldQuantity',
  ticketCount = 'ticketCount',
  unitCost = 'unitCost',
  soldCost = 'soldCost',
  unsoldCost = 'unsoldCost',
  soldProceeds = 'soldProceeds',
  listPrice = 'listPrice',
  allInPrice = 'allInPrice',
  netProceedsFloor = 'netProceedsFloor',
  netProceedsCeiling = 'netProceedsCeiling',
  websiteFloor = 'websiteFloor',
  websiteCeiling = 'websiteCeiling',
  undercutAbsoluteAmount = 'undercutAbsoluteAmount',
  undercutRelativeAmount = 'undercutRelativeAmount',
  compListingFloor = 'compListingFloor',
  compListingCeiling = 'compListingCeiling',
}

const listingToFormulaFields = (listing?: Listing | null) => {
  const tagFields = listing?.tags?.reduce((acc, tag) => {
    const numericValue = parseFloat(tag.value);
    if (isNaN(numericValue)) return acc;

    return {
      ...acc,
      [tagKeyToFieldName(tag.key)]: numericValue,
    };
  }, {});

  return {
    [ListingFormulaFields.availableQuantity]: listing?.availQty,
    [ListingFormulaFields.ticketCount]: listing?.ticketCnt,
    [ListingFormulaFields.unitCost]:
      listing?.unitCst?.amt || listing?.faceValue?.amt,
    [ListingFormulaFields.listPrice]: listing?.listPrice,
    [ListingFormulaFields.allInPrice]: listing?.allInPrice,
    [ListingFormulaFields.netProceedsFloor]: listing?.procsFloor,
    [ListingFormulaFields.netProceedsCeiling]: listing?.procsCeil,
    [ListingFormulaFields.websiteFloor]: getAllInPriceFromListPrice(
      listing?.procsFloor,
      listing
    ),
    [ListingFormulaFields.websiteCeiling]: getAllInPriceFromListPrice(
      listing?.procsCeil,
      listing
    ),
    [ListingFormulaFields.undercutAbsoluteAmount]: listing?.undAbsAmt,
    [ListingFormulaFields.undercutRelativeAmount]: listing?.undRelAmt,
    [ListingFormulaFields.compListingFloor]: listing?.compFloor,
    [ListingFormulaFields.compListingCeiling]: listing?.compCeil,
    ...tagFields,
  };
};

export const FORMULA_FIELD_TO_COLUMN_ID: Record<
  ListingFormulaFieldsListing,
  ListingTableColumnId
> = {
  [ListingFormulaFields.availableQuantity]: ListingTableColumnId.UnsoldQuantity,
  [ListingFormulaFields.ticketCount]: ListingTableColumnId.TicketCount,
  [ListingFormulaFields.unitCost]: ListingTableColumnId.UnitCost,
  [ListingFormulaFields.listPrice]: ListingTableColumnId.ListPrice,
  [ListingFormulaFields.allInPrice]: ListingTableColumnId.AllInPrice,
  [ListingFormulaFields.netProceedsFloor]: ListingTableColumnId.ProceedsFloor,
  [ListingFormulaFields.netProceedsCeiling]:
    ListingTableColumnId.ProceedsCeiling,
  [ListingFormulaFields.websiteFloor]: ListingTableColumnId.WebsiteFloor,
  [ListingFormulaFields.websiteCeiling]: ListingTableColumnId.WebsiteCeiling,
  [ListingFormulaFields.undercutAbsoluteAmount]:
    ListingTableColumnId.UndercutAbsoluteAmount,
  [ListingFormulaFields.undercutRelativeAmount]:
    ListingTableColumnId.UndercutRelativeAmount,
  [ListingFormulaFields.compListingFloor]:
    ListingTableColumnId.CompListingFloor,
  [ListingFormulaFields.compListingCeiling]:
    ListingTableColumnId.CompListingCeiling,
};

export const filterCustomColumnsForListing = (
  customColumns: CustomListingColumn[],
  tagsForEntityType: Tag[] | undefined
): CustomListingColumn[] => {
  const listingFormulaFields = Object.keys(LISTING_FORMULA_FIELDS_TO_CID);
  const tagKeys = tagsForEntityType?.map((t) => tagKeyToFieldName(t.key)) ?? [];
  return customColumns.filter((column) => {
    const editableFormula = fromExecutableFormula(column.formula);
    if (!editableFormula) return false;

    const fieldNamesUsed =
      parseEditableFormulaBase(editableFormula, true)?.map((t) => t.token) ??
      [];

    return fieldNamesUsed.every(
      (f) => listingFormulaFields.includes(f) || tagKeys.includes(f)
    );
  });
};

/**
 * Mock listing data explicitly used to test-evaluate formula
 * not putting under src/mock to reduce coupling
 */
export const LISTING_FORMULA_TEST_DATA: Listing = {
  availQty: 1,
  ticketCnt: 1,
  unitCst: { amt: 1 } as UiMoney,
  faceValue: { amt: 2 } as UiMoney,
  listPrice: 1,
  allInPrice: 1,
  procsFloor: 1,
  procsCeil: 1,
  undAbsAmt: 1,
  undRelAmt: 1,
  compFloor: 1,
} as Listing;

/**
 * Parse editableFormula, transforming formula into plain literals and contentId tied with ColumnId and field name
 * Used for preview and validation.
 * E.g. `${ticketCount} + 20` ==> [ContentId.Original, ' + 20']
 * @param editableFormula
 * @returns parsed list of tokens, or undefined if failed to parse
 */
export const parseEditableFormula = (
  editableFormula: FormulaEditable | undefined
): string[] | undefined => {
  const parsedTokenResult = parseEditableFormulaBase(editableFormula);
  if (!parsedTokenResult) {
    return;
  }

  const tokens: string[] = [];
  parsedTokenResult.forEach((tokenResult) => {
    if (tokenResult.isPlainToken) {
      tokens.push(tokenResult.token);
    } else {
      const fieldName = tokenResult.token;
      if (
        LISTING_FORMULA_FIELDS_TO_CID[
          fieldName as ListingFormulaFieldsListing
        ] == null
      ) {
        // Tag field
        tokens.push(tagFieldNameToKey(fieldName));
        return;
      }
      tokens.push(
        LISTING_FORMULA_FIELDS_TO_CID[fieldName as ListingFormulaFieldsListing]
      );
    }
  });

  return tokens;
};

export const findDependentListingTableColumnIds = (
  editableFormula: FormulaEditable
): ListingTableColumnId[] => {
  const fieldNames =
    parseEditableFormulaBase(editableFormula, true)?.map((t) => t.token) ?? [];

  return (
    Object.entries(FORMULA_FIELD_TO_COLUMN_ID)
      .filter(([key]) => fieldNames.includes(key))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => value)
  );
};

/**
 * Evaluate formula defined in custom listing column.
 *
 * Example formula: "data?.ticketCount + 20"
 * @param formula
 * @param listing
 * @returns evaluated value, undefined on syntax error
 */
export const evaluateFormula = (
  formula: FormulaExecutable,
  listing?: Listing | null
) => {
  const data = listingToFormulaFields(listing);

  return evaluateFormulaBase(formula, data);
};

const createListingTestData = (
  tagsForEntityType: Tag[] | undefined
): Listing => {
  const tagData = tagsForEntityType?.map((t) => {
    t.value = '1';
    return t;
  });

  return {
    ...LISTING_FORMULA_TEST_DATA,
    tags: tagData ?? [],
  };
};

export const validateFormula = (
  formula: string,
  tagsForEntityType: Tag[] | undefined
) => {
  return (
    parseExecutableFormula(formula) != null &&
    evaluateFormula(formula, createListingTestData(tagsForEntityType)) !=
      null &&
    validateFormulaSingleStatement(formula)
  );
};

const getTokenFromFieldName = (fieldName: string): MathematicalToken => {
  const columnId =
    FORMULA_FIELD_TO_COLUMN_ID[fieldName as ListingFormulaFieldsListing];

  if (columnId == null) {
    // Tag field - here we assume it's not currency - might need to change
    return {
      token: `tag-${fieldName}`,
      isPlainToken: false,
      isCurrency: false,
    };
  }

  const { isCurrency, isPercent } =
    LISTING_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[columnId];

  return {
    token: columnId,
    isPlainToken: false,
    isCurrency,
    isPercent,
  };
};

/**
 * Parse editableFormula, transforming formula into mathematical tokens
 * to infer personalization flags
 *
 * E.g. `${ticketCount} + 20` ==> [
 *     { token: 'ticketCount', isCurrency: false, isPercent: false, isPlainToken: false },
 *     { token: '+', isCurrency: false, isPercent: false, isPlainToken: true },
 *     { token: '20', isCurrency: false, isPercent: false, isPlainToken: true }
 * ]
 * @param editableFormula
 * @returns parsed list of tokens, or undefined if failed to parse
 */
export const parseMathematicalTokensFromFormula = (
  editableFormula: FormulaEditable | undefined
): MathematicalToken[] | undefined => {
  return parseMathematicalTokensFromFormulaBase(
    editableFormula,
    getTokenFromFieldName
  );
};

export const getPersonalizationFlagsFromFormula = (
  executableFormula: FormulaExecutable
): Partial<ColumnPersonalizationFlags> => {
  const editableFormula = fromExecutableFormula(executableFormula);
  const mathTokens = parseMathematicalTokensFromFormula(editableFormula);

  return getPersonalizationFlagsFromFormulaBase(mathTokens);
};
