import { ZoneAreaMetadata } from '../utils/colorUtils';
import * as styles from './ZoneSectionGroup.css';

export type ZoneSectionGroupListProps = {
  zoneAreaMetadata: ZoneAreaMetadata;
};

export const ZoneSectionGroupList = ({
  zoneAreaMetadata,
}: ZoneSectionGroupListProps) => {
  return (
    <div className={styles.zoneGroupsContainer}>
      {Object.entries(zoneAreaMetadata).map(([label, md]) => (
        <div key={label} className={styles.zoneGroupContainer}>
          <div
            className={styles.zoneGroupColorBox}
            style={{ backgroundColor: md.color }}
          />
          <span>{label}</span>
        </div>
      ))}
    </div>
  );
};
