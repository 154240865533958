import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function SearchSolidIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <g fill="inherit" fillRule="evenodd">
        <path d="M16.703 14.715c2.309-3.103 2.037-7.555-.815-10.388-3.124-3.103-8.285-3.103-11.545 0-3.124 3.103-3.124 8.23 0 11.467 1.63 1.62 3.667 2.429 5.705 2.429 1.63 0 3.26-.54 4.753-1.484L20.098 22 22 19.976l-5.297-5.261zM5.75 5.75C7 4.5 8.563 3.875 10.125 3.875c1.563 0 3.125.625 4.375 1.875 2.5 2.5 2.5 6.406 0 8.75-2.5 2.5-6.406 2.5-8.75 0-2.5-2.5-2.5-6.406 0-8.75z" />
        <path d="M10.112 3.7c-1.612.024-3.168.68-4.4 1.88l-.06.06-.07.065c-2.507 2.43-2.507 6.398-.014 8.971l.07.072.067.07c2.43 2.507 6.398 2.507 8.971.014l.074-.074-.01.01c2.61-2.447 2.61-6.526.014-9.122C13.49 4.38 11.873 3.7 10.2 3.7h-.088zm.088 1.253c1.338 0 2.637.547 3.668 1.579 2.082 2.08 2.103 5.3.077 7.263l-.077.073c-2.102 2.103-5.365 2.103-7.322.015l-.014-.015c-2.082-2.08-2.103-5.3-.077-7.263l.077-.073C7.563 5.5 8.862 4.953 10.2 4.953z" />
      </g>
    </SvgWrapper>
  );
}
