export enum ReportFilterEditability {
  Hidden = 'hidden',
  View = 'view',
  Edit = 'edit',
}

export const DEFAULT_REPORT_FILTER_EDITABILITY = ReportFilterEditability.Hidden;

export enum ReportPerformerVenueFilterItemId {
  PerformerIds = 'performerIds',
  VenueIds = 'venueIds',
}
