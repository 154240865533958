import { ComponentProps, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CancelButton } from 'src/components/Buttons';
import { PerformerCategoryIcon } from 'src/components/common/PerformerCategoryIcon';
import { AdGroupGenreSelector } from 'src/components/Selectors/AdGroupGenreSelector';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { PillItemProps } from 'src/core/ui/PillList/PillItem';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import {
  getTopLevelCategoryId,
  getTopLevelCategoryName,
} from 'src/utils/adGroupUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { Performer } from 'src/WebApiController';

import { BodySectionTitle, FieldWrapper } from '../common';
import * as styles from './AdGroupCreate.css';
import { BodySectionBody } from './AdGroupCreate.styled';
import { AdGroupSelectError } from './AdGroupSelectError';

export interface GenreSectionProps {
  onSelectGenre: (genre?: number) => void;
  genreId?: number;
  error?: string;
}

export const GenreSection = ({
  onSelectGenre,
  genreId,
  error,
}: GenreSectionProps) => {
  const addGenreDialog = useBasicDialog();

  const onAddGenreSave = useCallback(
    (genreId?: number) => {
      onSelectGenre(genreId);
      addGenreDialog.closeDialog();
    },
    [onSelectGenre, addGenreDialog]
  );

  const onAddGenreCancel = useCallback(() => {
    addGenreDialog.closeDialog();
  }, [addGenreDialog]);

  const onAddGenre = useCallback(() => {
    addGenreDialog.launchDialog();
  }, [addGenreDialog]);

  const pills = useMemo(() => {
    const pills = [] as PillItemProps[];
    if (genreId) {
      pills.push({
        value: 'genreId',
        display: getTopLevelCategoryName(genreId),
        icon: (
          <PerformerCategoryIcon
            performer={{ genreId: genreId } as Performer}
          />
        ),
        onDelete: () => {
          onSelectGenre(undefined);
        },
      });
    }

    return pills;
  }, [genreId, onSelectGenre]);

  return (
    <>
      <div className={styles.bodySection}>
        <BodySectionTitle>
          <Content id={ContentId.Genre} />
        </BodySectionTitle>
        {genreId ? (
          <BodySectionBody>
            <PillList pills={pills} />
          </BodySectionBody>
        ) : (
          <BodySectionBody>
            <Button variant={'link'} onClick={onAddGenre}>
              <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
              <Content id={ContentId.AddGenre} />
            </Button>
            <AdGroupSelectError error={error} />
          </BodySectionBody>
        )}
      </div>
      {addGenreDialog.dialogProps.isOpen && (
        <AddGenreDialog
          {...addGenreDialog.dialogProps}
          onSave={onAddGenreSave}
          onCancel={onAddGenreCancel}
        />
      )}
    </>
  );
};

export type AddGenreFieldValues = {
  genreId?: number;
};

export type AddGenreDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  onSave: (genreId?: number) => void;
  onCancel: () => void;
};

export const AddGenreDialog = ({
  onSave,
  onCancel,
  ...genericDialogProps
}: AddGenreDialogProps) => {
  const addGenreForm = useForm<AddGenreFieldValues>({
    defaultValues: {
      genreId: undefined,
    },
  });

  const setGenre = useCallback(
    (genreId: number) => {
      addGenreForm.clearErrors('genreId');
      addGenreForm.setValue('genreId', genreId);
    },
    [addGenreForm]
  );

  const genreId = addGenreForm.watch('genreId');

  const onSubmit = useCallback(() => {
    onSave(genreId);
  }, [genreId, onSave]);

  return (
    <GenericDialog
      size="xl"
      header={<Content id={ContentId.Genre} />}
      footer={
        <>
          <CancelButton onClick={onCancel} />
          <Button
            variant={'regular'}
            onClick={addGenreForm.handleSubmit(onSubmit)}
          >
            <Content id={ContentId.OK} />
          </Button>
        </>
      }
      onClosed={() => {
        addGenreForm.reset();
      }}
      {...genericDialogProps}
      onCancel={onCancel}
    >
      <FieldWrapper>
        <PosFormField label={<Content id={ContentId.Genre} />}>
          <AdGroupGenreSelector
            value={
              genreId ? getTopLevelCategoryName(genreId).toString() : undefined
            }
            onChange={(genreId) => {
              setGenre(getTopLevelCategoryId(genreId));
            }}
            style={{ width: '100%' }}
          />
        </PosFormField>
      </FieldWrapper>
    </GenericDialog>
  );
};
