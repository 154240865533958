import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function WarningSolidIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M12 2c5.51 0 10 4.49 10 10s-4.49 10-10 10S2 17.51 2 12 6.49 2 12 2zm0 13.2a1.067 1.067 0 100 2.134 1.067 1.067 0 000-2.134zm0-8.533c-.556 0-1.013.426-1.062.97l-.005.096v5.334a1.067 1.067 0 002.13.097l.004-.097V7.733c0-.588-.478-1.066-1.067-1.066z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
