import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { TicketTypePriorityInput } from 'src/components/common/TicketTypePriorityInput';
import { DeliveryTypeRadioInput } from 'src/components/Input/DeliveryTypeRadioInput';
import { InHandDateInput } from 'src/components/Input/InHandDateInput';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { FormPage } from 'src/core/POS/FormPage';
import { InfoMessage } from 'src/core/POS/MessageWithIcon/InfoMessage';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { AlertWithSuppressionDialog } from 'src/dialogs/AlertWithSuppressionDialog';
import { useDeliveryInfoChangeDialog } from 'src/dialogs/AlertWithSuppressionDialog/useDeliveryInfoChangeDialog';
import { useDeliveryOverrideForNewTicketGroup } from 'src/hooks/api/useDeliveryOverrideForNewTicketGroup';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  useUserCanUpdateListingTicketTypePriorityFromPurchase,
  useUserCanUpdatePurchaseCustomDeliveryType,
  useUserCanUpdatePurchaseDeliveryType,
} from 'src/hooks/useUserHasPurchasePermissions';
import { FieldWrapper, FormRow } from 'src/modals/common/Purchase';
import { useGetListingAndSaleIds } from 'src/modals/common/Purchase/hooks/useGetListingAndSaleIds';
import { IconsFill, SeatMapIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { isDatePassedHours } from 'src/utils/dateTimeUtils';
import { getInHandDateBasedOnEvent } from 'src/utils/eventWithDataUtils';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseTicketsInput } from 'src/utils/purchaseUtils';
import { DeliveryType, Feature } from 'src/WebApiController';

import { PurchaseOrderInfoForTicketGroupInput } from '../TicketGroupInput.types';

export const DeliverySection = ({
  disabled,
  ticketGroupIndex,
  purchaseOrderInfo,
}: {
  ticketGroupIndex: number;
  disabled?: boolean;
  purchaseOrderInfo?: PurchaseOrderInfoForTicketGroupInput | null;
}) => {
  const { setValue, formState, getFieldState, clearErrors, watch } =
    useFormContext<PurchaseTicketsInput>();

  const ticketGroup = watch(`ticketGroups.${ticketGroupIndex}`);

  const {
    isLoading: deliveryOverrideDefaultIsLoading,
    hasDefaultValues: hasDeliveryOverrideValues,
    deliveryTypeDefault,
    ticketTypeRulesDefault,
    inHandAtDefault,
  } = useDeliveryOverrideForNewTicketGroup(
    ticketGroup.event?.viagId,
    purchaseOrderInfo?.vendor?.id,
    purchaseOrderInfo?.vendAcc?.id,
    true, // isManual
    ticketGroup.ticketGroupId > 0 /* disabled if not new ticket group */
  );

  const canEditDeliveryType =
    useUserCanUpdatePurchaseDeliveryType(purchaseOrderInfo);
  const canEditCustomDeliveryType =
    useUserCanUpdatePurchaseCustomDeliveryType(purchaseOrderInfo);
  const canEditTicketTypePriority =
    useUserCanUpdateListingTicketTypePriorityFromPurchase(purchaseOrderInfo);

  const hasUpdateSalesOfListingsFeature = useUserHasFeature(
    Feature.UpdateSalesOfListingsDeliveryInfo
  );
  const { saleIds } = useGetListingAndSaleIds({
    ticketGroups: [{ tickets: ticketGroup.tickets?.value }],
  });
  const hasAnySales = saleIds.size > 0;

  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = useMemo(
    () => getUiCurrency(ticketGroup.currencyCode?.value),
    [getUiCurrency, ticketGroup.currencyCode?.value]
  );

  const deliveryOptionField: `ticketGroups.${number}.deliveryType` = `ticketGroups.${ticketGroupIndex}.deliveryType`;
  const inHandAtField: `ticketGroups.${number}.inHandDate` = `ticketGroups.${ticketGroupIndex}.inHandDate`;
  const deliveryCostField: `ticketGroups.${number}.deliveryCost` = `ticketGroups.${ticketGroupIndex}.deliveryCost`;
  const daysBeforeEventField = 'daysBeforeEvent' as const;

  const ticketTypeOverrideField: `ticketGroups.${number}.ticketTypeRules` = `ticketGroups.${ticketGroupIndex}.ticketTypeRules`;

  const defaultDelivType =
    formState.defaultValues?.ticketGroups?.[ticketGroupIndex]?.deliveryType
      ?.value;

  const inHandAt = watch(inHandAtField)?.value;
  const daysBeforeEvent = watch(daysBeforeEventField);
  const delivType = watch(deliveryOptionField)?.value;
  const delivCst = watch(deliveryCostField)?.value;
  const tktTypeRules = watch(ticketTypeOverrideField);
  const updateSalesOfListings = watch('updateSalesOfListings');

  const isNewTicketGroup = ticketGroup.ticketGroupId < 0;

  const deliveryOptionError = getFieldState(deliveryOptionField, formState)
    .error?.message;
  const inHandAtError = getFieldState(inHandAtField, formState).error?.message;

  const isMobile = useMatchMedia('mobile');

  const warningDialog = useBasicDialog();
  const deliveryInfoChangeHookProps = useDeliveryInfoChangeDialog(
    () => {
      setValue('updateSalesOfListings', true);
    },
    () => {
      // set don't change back
      setValue('updateSalesOfListings', false);
    }
  );

  const deliveryTypeHasChanged =
    delivType !==
    formState.defaultValues?.ticketGroups?.[ticketGroupIndex]?.deliveryType;

  // Used for setting value when the default value is loaded
  useEffect(() => {
    if (!deliveryOverrideDefaultIsLoading) {
      const desiredDelivType = deliveryTypeDefault ?? delivType;

      if (deliveryTypeDefault && delivType !== deliveryTypeDefault) {
        setValue(deliveryOptionField, posChangedField(deliveryTypeDefault));
      }

      if (inHandAtDefault && inHandAt !== inHandAtDefault) {
        setValue(inHandAtField, posChangedField(inHandAtDefault));
      }

      if (ticketTypeRulesDefault) {
        setValue(ticketTypeOverrideField, {
          overrides: ticketTypeRulesDefault.overrides.filter(
            (o) => o.deliveryType === desiredDelivType
          ),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deliveryOverrideDefaultIsLoading,
    deliveryTypeDefault,
    inHandAtDefault,
    ticketTypeRulesDefault,
  ]);

  useEffect(() => {
    if (!inHandAt && ticketGroup.event?.dates?.start) {
      const inHandDate = getInHandDateBasedOnEvent(
        ticketGroup.event,
        daysBeforeEvent
      );
      setValue(inHandAtField, posChangedField(inHandDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inHandAtField]);

  const onDeliveryTypeChange = (newDelType: DeliveryType) => {
    if (!disabled && newDelType && newDelType !== delivType) {
      clearErrors(deliveryOptionField);
      setValue(deliveryOptionField, posChangedField(newDelType));

      if (ticketTypeRulesDefault) {
        setValue(ticketTypeOverrideField, {
          overrides: ticketTypeRulesDefault.overrides.filter(
            (o) => o.deliveryType === newDelType
          ),
        });
      }

      confirmUpdateSales();
    }
  };

  const confirmUpdateSales = useCallback(() => {
    if (
      hasAnySales &&
      hasUpdateSalesOfListingsFeature &&
      // only ask once per form
      updateSalesOfListings == null
    ) {
      if (isDatePassedHours(deliveryInfoChangeHookProps.lastTimeStamp, 1)) {
        // Show warning if it has passed 1 hr or not suppressed
        warningDialog.launchDialog();
      } else {
        // If suppressed, assumed it's true (since the current alert suppression only save for Yes answer)
        setValue('updateSalesOfListings', true);
      }
    }
  }, [
    deliveryInfoChangeHookProps.lastTimeStamp,
    hasAnySales,
    hasUpdateSalesOfListingsFeature,
    setValue,
    updateSalesOfListings,
    warningDialog,
  ]);

  return (
    <>
      <FormPage.Section
        icon={
          <SeatMapIcon fill={IconsFill.currentColor} size={vars.iconSize.s} />
        }
        title={<Content id={ContentId.TicketDelivery} />}
      >
        {deliveryTypeHasChanged && (
          <InfoMessage
            message={
              <Content id={ContentId.BulkEditDeliveryTypePurchaseMessage} />
            }
          />
        )}
        {hasDeliveryOverrideValues && (
          <InfoMessage
            message={<Content id={ContentId.DeliveryOverrideInfo} />}
          />
        )}
        <FormRow vertical={isMobile}>
          <FieldWrapper>
            <DeliveryTypeRadioInput
              value={delivType}
              onChange={onDeliveryTypeChange}
              error={deliveryOptionError}
              disabled={
                disabled ||
                deliveryOverrideDefaultIsLoading ||
                (!canEditDeliveryType && !canEditCustomDeliveryType)
              }
              // If the original value is custom, we allow user to set it back to custom
              hideCustomType={
                !canEditCustomDeliveryType &&
                defaultDelivType !== DeliveryType.Custom
              }
            />
          </FieldWrapper>
        </FormRow>

        {(isNewTicketGroup || deliveryTypeHasChanged) && (
          <FormRow vertical={isMobile}>
            <Stack direction="column" gap="l">
              <FieldWrapper>
                <PosFormField
                  label={<Content id={ContentId.DeliveryStrategy} />}
                  style={{ gap: vars.spacing['sm'] }}
                >
                  <TicketTypePriorityInput
                    deliveryType={delivType ?? null}
                    ticketTypeRules={tktTypeRules}
                    onTicketTypeRulesChange={(newRules) => {
                      setValue(ticketTypeOverrideField, newRules);
                    }}
                    disabled={
                      disabled ||
                      deliveryOverrideDefaultIsLoading ||
                      !canEditTicketTypePriority
                    }
                  />
                </PosFormField>
              </FieldWrapper>
            </Stack>
          </FormRow>
        )}

        <FormRow vertical={isMobile}>
          <FieldWrapper width="max-content">
            <PosFormField
              label={<Content id={ContentId.TotalDeliveryCharge} />}
            >
              <PosCurrencyField
                disabled={disabled}
                uiCurrency={uiCurrency}
                value={delivCst ?? ''}
                onChange={(e) => {
                  const v = parseFloat(e.target.value) || 0;
                  if (v >= 0 && v <= Number.MAX_VALUE && v !== delivCst) {
                    setValue(deliveryCostField, posChangedField(v));
                  }
                }}
              />
            </PosFormField>
          </FieldWrapper>
          <FieldWrapper width="max-content">
            <PosFormField
              label={<Content id={ContentId.InHandDate} />}
              errors={inHandAtError}
            >
              <InHandDateInput
                disabled={disabled || deliveryOverrideDefaultIsLoading}
                onChange={(date, daysBeforeEvent) => {
                  if (date) {
                    clearErrors(inHandAtField);
                    const dateStr = date.toISOString();
                    if (dateStr !== inHandAt) {
                      setValue(inHandAtField, posChangedField(dateStr));
                    }

                    setValue(daysBeforeEventField, daysBeforeEvent);

                    confirmUpdateSales();
                  }
                }}
                value={inHandAt ?? null}
                targetDate={
                  ticketGroup.event?.dates?.start
                    ? new Date(ticketGroup.event?.dates?.start)
                    : new Date()
                }
              />
            </PosFormField>
          </FieldWrapper>
        </FormRow>
      </FormPage.Section>
      <AlertWithSuppressionDialog
        headerText={<Content id={ContentId.ListingUpdateConfirmation} />}
        size="md"
        {...warningDialog.dialogProps}
        alerts={[deliveryInfoChangeHookProps]}
        onOkay={() => {
          warningDialog.closeDialog();
        }}
        okText={ContentId.Yes}
        cancelText={ContentId.No}
        onCancel={() => warningDialog.closeDialog()}
      />
    </>
  );
};
