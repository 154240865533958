import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  AutoCurrencyConversionSettings,
  SellerAccountClient,
} from 'src/WebApiController';

export const useAutoCurrencyConversionSettings = () => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const queryClient = useQueryClient();
  const queryKey = [
    'getAutoCurrencyConversionSettings',
    activeAccountWebClientConfig.activeAccountId,
  ];

  const shouldQuery = activeAccountWebClientConfig.activeAccountId != null;
  const query = useQuery({
    queryKey,
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new SellerAccountClient(
        activeAccountWebClientConfig
      ).getAutoCurrencyConversionSettings();
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError(
          'SellerAccountClient.getAutoCurrencyConversionSettings',
          error
        );
      },
    },
  });

  const mutation = useMutation({
    mutationFn: async ({
      input,
    }: {
      input: AutoCurrencyConversionSettings;
    }) => {
      return await new SellerAccountClient(
        activeAccountWebClientConfig
      ).mergeAutoCurrencyConversionSettings(input);
    },
    // optimistic ui
    onMutate: ({ input }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, input);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { input }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog(
        'SellerAccountClient.mergeAutoCurrencyConversionSettings',
        err,
        {
          trackErrorData: { input },
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    autoCurrencyConversionSettings: query.data,
    isLoading: query.isLoading,
    mergeAutoCurrencyConversionSettings: mutation.mutateAsync,
  };
};
