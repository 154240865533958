import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ActionOutboxEntityType, Permission } from 'src/WebApiController';

interface UseBulkEditTagsPermissionsProps {
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.Purchase;
}

interface BulkEditTagsPermissions {
  hasEditTagsPermissions: boolean;
}

export const useBulkEditTagsPermissions = ({
  entityType,
}: UseBulkEditTagsPermissionsProps): BulkEditTagsPermissions => {
  const hasEditTagsPermission = useUserHasAnyOfPermissions(
    ...(entityType === ActionOutboxEntityType.Listing
      ? [
          Permission.Inventory_UpdateTags,
          Permission.Inventory_UpdateTagsOnIsPricerOf,
          Permission.Inventory_UpdateTagsOnPurchasedBy,
        ]
      : entityType === ActionOutboxEntityType.Sale
      ? [
          Permission.Sales_UpdateTags,
          Permission.Sales_UpdateTagsOnIsFulfillerOf,
          Permission.Sales_UpdateTagsOnIsPricerOf,
          Permission.Sales_UpdateTagsOnPurchasedBy,
        ]
      : [Permission.Purchases_UpdateTags, Permission.Purchases_UpdateTagsOwned])
  );

  const { tagsMetadata: existingTagsMetadata = [] } =
    useTagsForEntityType(entityType);

  return {
    hasEditTagsPermissions:
      hasEditTagsPermission || (existingTagsMetadata ?? []).length > 0,
  };
};
