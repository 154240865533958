import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { SimpleTable } from 'src/core/ui';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useUserCanBroadcast } from 'src/hooks/useUserHasListingPermissions';
import { UK_COUNTRY_CODES } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import {
  compareMarketplace,
  getIsInternationalEvent,
} from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Feature,
  ListingClient,
  ListingDetails,
  ListingMetrics,
  ListingStatus,
  Marketplace,
  MarketplaceListingStatusInfo,
} from 'src/WebApiController';

import { MarketplaceDisplay } from './MarketplaceDisplay';
import * as styles from './MarketplaceDisplay.css';
import { MarketplaceFailure } from './useGetMarketplaceFailures';

export const MarketplaceTable = ({
  listing,
  disabled,
  marketplaceFailures,
}: {
  listing: ListingDetails;
  disabled?: boolean;
  marketplaceFailures: MarketplaceFailure[];
}) => {
  const { setActivePosEntity, updateActivePosEntityInfo } =
    useActivePosEntityContext<ListingDetails>();

  const { event, venue } = useGetEventFullInfo();

  const isUKEvent = Boolean(
    venue?.country?.code &&
      UK_COUNTRY_CODES.includes(venue.country.code.toUpperCase())
  );
  const isInternationalEvent = getIsInternationalEvent(venue?.country?.code);

  const { refreshMetrics } = useCatalogMetricsContext<ListingMetrics>();

  const [isLoading, setIsLoading] = useState(false);

  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog, trackError } = useErrorBoundaryContext();

  const { mkpListings } = listing;

  const marketplaceFailuresByMarketplace = useMemo(
    () =>
      marketplaceFailures.reduce(
        (r, c) => {
          r[c.marketplace] = c;
          return r;
        },
        {} as Record<Marketplace, MarketplaceFailure>
      ),
    [marketplaceFailures]
  );

  // We need to keep a local state of the listing status so we can use this to reflect the listing toggle and status when user clicks
  // otherwise we have to wait until the status is updated in the server before the UI shows the updates
  // However, mainting this local state in sync with the server is a bit nuanced - do be care of changing this logic
  const [marketplaceListingStatus, setMarketplaceListingStatus] = useState<
    Record<Marketplace, ListingStatus>
  >(
    // Initialized this state to what the current market listing status is
    mkpListings
      .filter((ml) => ml.mkp)
      .reduce(
        (ml, cur) => {
          ml[cur.mkp!] = cur.status;
          return ml;
        },
        {} as Record<Marketplace, ListingStatus>
      )
  );

  // Only when the marketling-listing changed should we try to refresh the local states with it
  useEffect(() => {
    const newStatuses = mkpListings
      .filter((ml) => ml.mkp)
      .reduce(
        (ml, cur) => {
          ml[cur.mkp!] = cur.status;
          return ml;
        },
        {} as Record<Marketplace, ListingStatus>
      );

    if (!isEqual(newStatuses, marketplaceListingStatus)) {
      setMarketplaceListingStatus(newStatuses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mkpListings]); // We only want to run this when mkpListings changed - not the other things

  const onSetMarketplaceListingStatus = useCallback(
    (marketplace: Marketplace, status: ListingStatus) => {
      const newStatues = {
        ...marketplaceListingStatus,
        [marketplace]: status,
      };
      setMarketplaceListingStatus(newStatues);
    },
    [marketplaceListingStatus]
  );

  const onListingBroadcastToggle = useCallback(
    (isBroadcasting: boolean, marketPlace: Marketplace) => {
      tryInvokeApi(
        async () => {
          setIsLoading(true);

          if (isBroadcasting) {
            await new ListingClient(
              activeAccountWebClientConfig
            ).createMarketplaceListings(listing.id, [marketPlace]);
          } else {
            await new ListingClient(
              activeAccountWebClientConfig
            ).deleteMarketplaceListings(listing.id, [marketPlace]);
          }
        },
        (error) => {
          showErrorDialog(
            isBroadcasting
              ? 'ListingClient.createMarketplaceListings'
              : 'ListingClient.deleteMarketplaceListings',
            error,
            {
              trackErrorData: {
                listingId: listing.id,
                marketPlaces: [marketPlace],
              },
            }
          );
        },
        () => setIsLoading(false)
      );
    },
    [activeAccountWebClientConfig, listing, showErrorDialog]
  );

  const canBroadcast = useUserCanBroadcast(listing, false);

  const hasFastStatusFeature = useUserHasFeature(
    Feature.SlimListingBroadcastStatus
  );

  useEffect(() => {
    // If we have any Marketplace listing pending status - we need to poll to get the latest status
    const statusChecking = setInterval(async () => {
      const marketplacesInPending = Object.keys(
        marketplaceListingStatus
      ).filter(
        (marketplace) =>
          marketplaceListingStatus[marketplace as Marketplace] ===
            ListingStatus.ListingPending ||
          marketplaceListingStatus[marketplace as Marketplace] ===
            ListingStatus.DelistingPending
      );

      if (marketplacesInPending.length > 0 && !isLoading) {
        await tryInvokeApi(
          async () => {
            setIsLoading(true);

            const client = new ListingClient(activeAccountWebClientConfig);

            const newListing = hasFastStatusFeature
              ? await client.getListingStatusByListingId(listing.id)
              : await client.getListingByListingId(listing.id);

            if (newListing) {
              const newMarketplaceStatuses = {} as Record<
                Marketplace,
                ListingStatus
              >;

              // We we get back a listing from server, it may or may not have finished the job
              // So we just have to assume the status is flowing in one direct only, ie, if we set something to pending,
              // we expect it to either finish or failed (but not reversed)
              const newMarketplaceListings = newListing.mkpListings;
              newMarketplaceListings.forEach((ml) => {
                if (ml.mkp) {
                  if (
                    marketplaceListingStatus[ml.mkp!] ===
                    ListingStatus.DelistingPending
                  ) {
                    // If we are delisting-pending, set if the new status is either Delisted or DelistingFailed
                    if (
                      ml.status === ListingStatus.Disabled ||
                      ml.status === ListingStatus.Delisted ||
                      ml.status === ListingStatus.DelistingFailed
                    ) {
                      newMarketplaceStatuses[ml.mkp] = ml.status;
                    }
                  } else if (
                    marketplaceListingStatus[ml.mkp!] ===
                    ListingStatus.ListingPending
                  ) {
                    // If we are listing-pending, set if the new status is either Listed or ListedFailed
                    if (
                      ml.status === ListingStatus.Disabled ||
                      ml.status === ListingStatus.Listed ||
                      ml.status === ListingStatus.ListingFailed
                    ) {
                      newMarketplaceStatuses[ml.mkp] = ml.status;
                    }
                  }
                }
              });

              // Only if we do get status changes shall we try to actually update the active-item
              // provider with the latest states, or else the toggle will jump back and forth due
              // to the useEffect earlier (since we modified the active listing)
              if (Object.keys(newMarketplaceStatuses).length > 0) {
                const newStatus = {
                  ...marketplaceListingStatus,
                  ...newMarketplaceStatuses,
                };
                setMarketplaceListingStatus(newStatus);

                // Use the calculated status on the new listing obj
                newListing.mkpListings.forEach((ml) => {
                  if (ml.mkp) {
                    ml.status = newStatus[ml.mkp!];
                  }
                });

                // Only stop running this if all the status is no longer pending
                clearInterval(statusChecking);

                let updatedListing = newListing as ListingDetails;
                if (hasFastStatusFeature) {
                  const newMkpListings = newListing.mkpListings.reduce(
                    (r, c) => {
                      r[c.mkp] = c;
                      return r;
                    },
                    {} as Record<Marketplace, MarketplaceListingStatusInfo>
                  );

                  updatedListing = {
                    ...listing,
                    ...newListing,
                    // for marketplaces, we also need to merge since the MarketplaceListingStatusInfo is just a subset of the MarketplaceListingDetails
                    mkpListings: listing.mkpListings.map((mkp) => ({
                      ...mkp,
                      ...(newMkpListings[mkp.mkp] ?? {}),
                    })),
                  } as ListingDetails;
                }

                refreshMetrics?.();
                updateActivePosEntityInfo({
                  event: event,
                  posEntity: updatedListing,
                  posEntityId: newListing.id,
                  posEntityDisplayId: newListing.idOnMkp,
                });
              }
            }
          },
          (error) => {
            // Because this is a background process - we don't want to show error dialog
            trackError('ListingClient.getListingByListingId', error, {
              listingId: listing.id,
            });
          },
          () => setIsLoading(false)
        );
      }
    }, 2000 /* 2 sec */);

    return () => clearInterval(statusChecking);
  }, [
    activeAccountWebClientConfig,
    event,
    listing,
    listing.id,
    listing.idOnMkp,
    marketplaceListingStatus,
    mkpListings,
    refreshMetrics,
    setActivePosEntity,
    updateActivePosEntityInfo,
    trackError,
    isLoading,
    hasFastStatusFeature,
  ]);

  const isDisabled = disabled || !canBroadcast;

  return (
    <SimpleTable.Table>
      <SimpleTable.Thead>
        <SimpleTable.Tr>
          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Content id={ContentId.Merchant} />
          </SimpleTable.Th>
          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Content id={ContentId.Broadcast} />
          </SimpleTable.Th>
          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Content id={ContentId.Status} />
          </SimpleTable.Th>

          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Content id={ContentId.Section} />
          </SimpleTable.Th>
          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Content id={ContentId.Row} />
          </SimpleTable.Th>
          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Content id={ContentId.Seats} />
          </SimpleTable.Th>

          <SimpleTable.Th
            className={`${styles.broadcastHeaderCell} ${styles.broadcastCell}`}
          >
            <Content id={ContentId.ListingId} />
          </SimpleTable.Th>
        </SimpleTable.Tr>
      </SimpleTable.Thead>
      <SimpleTable.Tbody>
        {mkpListings
          .filter((ml) => ml.mkp && ml.mkp !== Marketplace.Offline)
          .sort((a, b) => compareMarketplace(a.mkp, b.mkp))
          .flatMap((ml, i) => {
            const mpListingStatus = marketplaceListingStatus[ml.mkp!];

            // When the UnitNetProceeds value is 0, we should prevent broadcasting
            const isDisabledWithPriceCheck =
              isDisabled ||
              (mpListingStatus === ListingStatus.Delisted &&
                listing.listPrice === 0 &&
                (ml.netProcs == null || ml.netProcs === 0));

            if (ml.mkp === Marketplace.StubHub) {
              return [
                <MarketplaceDisplay
                  key={`${ml.mkpListingId}-${ml.mkp}-${false}`}
                  marketplaceListing={ml}
                  formIndex={i}
                  status={mpListingStatus}
                  setListingStatus={onSetMarketplaceListingStatus}
                  disabled={isDisabledWithPriceCheck}
                  isDeleted={listing.isDeleted}
                  isUKEvent={isUKEvent}
                  onListingBroadcastToggle={
                    isUKEvent
                      ? (listing, marketplace) => {
                          window.open('/redirect-uk', '_blank');
                          onListingBroadcastToggle(listing, marketplace);
                        }
                      : onListingBroadcastToggle
                  }
                  error={marketplaceFailuresByMarketplace[Marketplace.StubHub]}
                  qcState={listing.qcState}
                />,
                <MarketplaceDisplay
                  key={`${ml.mkpListingId}-${ml.mkp}-${true}`}
                  marketplaceListing={ml}
                  formIndex={i}
                  status={mpListingStatus}
                  setListingStatus={onSetMarketplaceListingStatus}
                  disabled={isDisabledWithPriceCheck}
                  isViagogo
                  isDeleted={listing.isDeleted}
                  isUKEvent={isUKEvent}
                  onListingBroadcastToggle={onListingBroadcastToggle}
                  error={marketplaceFailuresByMarketplace[Marketplace.StubHub]}
                  qcState={listing.qcState}
                />,
              ];
            }

            return !isInternationalEvent
              ? [
                  // We only show other marketplaces when the Event is US
                  <MarketplaceDisplay
                    key={`${ml.mkpListingId}-${ml.mkp}-${false}`}
                    marketplaceListing={ml}
                    formIndex={i}
                    status={mpListingStatus}
                    setListingStatus={onSetMarketplaceListingStatus}
                    disabled={isDisabledWithPriceCheck}
                    isDeleted={listing.isDeleted}
                    onListingBroadcastToggle={onListingBroadcastToggle}
                    error={ml.mkp && marketplaceFailuresByMarketplace[ml.mkp]}
                    qcState={listing.qcState}
                  />,
                ]
              : [];
          })}
      </SimpleTable.Tbody>
    </SimpleTable.Table>
  );
};
