import { ReactNode } from 'react';

import { CountBadge } from '../CountBadge';
import { Stack } from '../Stack';
import * as styles from './Badge.css';

export function Badge({ children }: { children?: ReactNode }) {
  return <div className={styles.badge}>{children}</div>;
}

export function BadgeWithCounts({
  count,
  children,
  title,
}: {
  count: number;
  children?: ReactNode;
  title?: string;
}) {
  return (
    <Stack gap="m" className={styles.badgeWithCount} title={title}>
      {children} <CountBadge count={count} color="brandLight" />
    </Stack>
  );
}
