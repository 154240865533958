import { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSignUpContext } from 'src/contexts/SignUpContext/SignUpContext';
import { SignUpPathname } from 'src/navigations/Routes/SignUp/constants';

const allowedHostnames = ['reachpro.com', 'www.reachpro.com'];

export const isReachProDomain = () => {
  const { hostname } = window.location;
  return allowedHostnames.includes(hostname);
};

export const isAllowedToSignUp = () => {
  return isReachProDomain();
};

interface SignUpValidatorProps {
  children: ReactNode;
}

export const SignUpRedirect = ({ children }: SignUpValidatorProps) => {
  const { hasCheckedSignUpRedirect, setHasCheckedSignUpRedirect } =
    useSignUpContext();

  useEffect(() => {
    setHasCheckedSignUpRedirect(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAllowedToSignUp() && !hasCheckedSignUpRedirect) {
    if (location.pathname !== SignUpPathname) {
      return <Navigate to={SignUpPathname} />;
    }
  }

  return <>{children}</>;
};
