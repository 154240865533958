import { ComponentProps, ReactNode } from 'react';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { mergeProps } from 'src/core/utils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';

import * as styles from './SummaryTile.css';

type RootProps = Pick<ComponentProps<'div'>, 'style' | 'className'> & {
  children?: ReactNode;
};

export function Root({ children, ...props }: RootProps) {
  return (
    <div {...mergeProps({ className: styles.root }, props)}>{children}</div>
  );
}

export function DateDisplay({ date }: { date: Date }) {
  const currentLocale = getLocaleFromLanguageOrCurrent();
  const month = date.toLocaleString(currentLocale.code, { month: 'long' });
  const day = date.toLocaleString(currentLocale.code, { day: '2-digit' });
  const dayOfWeek = date.toLocaleString(currentLocale.code, {
    weekday: 'short',
  });
  return (
    <div className={styles.dateContainer}>
      <div className={styles.dateMonth}>{month}</div>
      <div className={styles.dateDay}>{day}</div>
      <div className={styles.dateDivider} />
      <div className={styles.dateDayOfWeek}>{dayOfWeek}</div>
    </div>
  );
}

export function Body({ children }: { children?: ReactNode }) {
  return <div className={styles.body}>{children}</div>;
}

export function Title({ children }: { children?: ReactNode }) {
  return <div className={styles.title}>{children}</div>;
}

export function Description({ children }: { children?: ReactNode }) {
  return <div className={styles.description}>{children}</div>;
}

export function MetricGroup({
  children,
  ...props
}: { children?: ReactNode } & Pick<
  ComponentProps<'div'>,
  'style' | 'className'
>) {
  return (
    <div {...mergeProps({ className: styles.metricGroup }, props)}>
      {children}
    </div>
  );
}

export function Metric({
  showLoadingPlaceholders = false,
  name,
  children,
}: {
  /**
   * Whether to show a placeholder if the metric value is `undefined`.
   */
  showLoadingPlaceholders?: boolean;
  name?: ReactNode;
  children?: ReactNode;
}) {
  return (
    <div className={styles.metric}>
      <div className={styles.metricName}>{name}</div>
      <div className={styles.metricValue}>
        {showLoadingPlaceholders && children === undefined ? (
          <ContentPlaceholder
            width="80px"
            height="1em"
            className={styles.metricValuePlaceholder}
          />
        ) : (
          children
        )}
      </div>
    </div>
  );
}
