import { useFormContext } from 'react-hook-form';
import { Content, useContent } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { Stack } from 'src/core/ui';
import { DetailGroup, DetailSection, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { CONFIG_TYPE_OVERRIDE_TO_CID } from 'src/utils/constants/contentIdMaps';

import { GroupListingAutoCompSettingsForm } from '../autoCompGroup.types';
import * as styles from './ConfigTypeSelector.css';

export const ConfigTypeSelector = () => {
  const { watch, setValue } =
    useFormContext<GroupListingAutoCompSettingsForm>();
  const configTypeOverride = watch('configTypeOverride');
  const combineCornersAndEnds = watch('combineCornersAndEnds');

  return (
    <DetailSection name={<Content id={ContentId.VenueConfiguration} />}>
      <SectionContent numOfColumns={1}>
        <DetailGroup style={{ gridColumn: '1' }}>
          <PosFormField className={styles.dropdown}>
            <PosEnumSelect
              style={{
                backgroundColor: 'white',
              }}
              contentProps={{ className: styles.animate }}
              onChange={(newValue) => {
                if (newValue && newValue !== configTypeOverride) {
                  setValue('configTypeOverride', newValue);
                }
              }}
              placeholderText={useContent(ContentId.Inferred)}
              value={configTypeOverride}
              valueOptionsContent={CONFIG_TYPE_OVERRIDE_TO_CID}
            />
          </PosFormField>
          <Stack direction="row" gap="m" alignItems="center">
            <Checkbox
              checked={combineCornersAndEnds ?? false}
              onChange={() => {
                const isChecked = !combineCornersAndEnds;
                setValue('combineCornersAndEnds', isChecked);
              }}
            />
            <Content id={ContentId.CombineCornersAndEnds} />
          </Stack>
        </DetailGroup>
      </SectionContent>
    </DetailSection>
  );
};
