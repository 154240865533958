import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSellerRoleContext } from 'src/contexts/SellerRoleContext';
import { SellerRoleInput } from 'src/WebApiController';

import { mapToSellerRoleInput } from './SellerPermissionDisplayModal.utils';
import { SellerPermissionDisplayModalBody } from './SellerPermissionDisplayModalBody';

export const SellerPermissionDisplayModal = ({
  roleIds,
}: {
  roleIds?: number[] | null;
}) => {
  const { allSellerRoles, isLoading } = useSellerRoleContext();

  const roleInfos = useMemo(() => {
    if (!roleIds || !allSellerRoles) return null;

    return roleIds.map((roleId) => allSellerRoles[roleId]);
  }, [allSellerRoles, roleIds]);

  const methods = useForm<SellerRoleInput>({
    defaultValues: mapToSellerRoleInput(roleInfos),
  });

  useEffect(() => {
    methods.reset(mapToSellerRoleInput(roleInfos));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleInfos]);

  return (
    <FormProvider {...methods}>
      <SellerPermissionDisplayModalBody {...methods} isLoading={isLoading} />
    </FormProvider>
  );
};
