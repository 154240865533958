import { ComponentProps } from 'react';
import { vars } from 'src/core/themes';
import { DownloadIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { ButtonWithIcon } from './ButtonWithIcon';

export const DownloadButton = ({
  textContentId,
  iconOnlyMode,
  variant,
  ...props
}: Omit<ComponentProps<typeof ButtonWithIcon>, 'icon'>) => {
  return (
    <ButtonWithIcon
      {...props}
      icon={
        <DownloadIcon
          withHoverEffect
          fill={
            iconOnlyMode || (variant && variant !== 'regular')
              ? IconsFill.textBrand
              : IconsFill.textInverted
          }
          size={vars.iconSize.m}
        />
      }
      iconOnlyMode={iconOnlyMode}
      variant={variant}
      textContentId={textContentId || ContentId.Download}
    />
  );
};
