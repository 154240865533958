import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  EventSellThroughRateTargetsForm,
  SellThroughRateGraphBody,
  SellThroughRateHistoryInput,
  SellThroughRateSectionProps,
  SellThroughRateTargetInput,
} from 'src/components/Events/SellThroughRateSection';
import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { shared } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useGetEventSellThroughRates } from 'src/hooks/api/useGetEventSellThroughRates';
import { useSellerEventSellThroughRateTargets } from 'src/hooks/api/useSellerEventSellThroughRateTargets';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './InventoryEventPage.css';

export const SellThroughRateSection = ({
  event,
  ...rest
}: SellThroughRateSectionProps) => {
  const { sellThroughRateTargetsQuery } = useSellerEventSellThroughRateTargets(
    event.viagId,
    event.mappingId
  );

  const methods = useForm<EventSellThroughRateTargetsForm>({
    defaultValues: {
      strTargets: sellThroughRateTargetsQuery.data ?? [],
    },
  });

  useEffect(() => {
    const strTargets =
      sellThroughRateTargetsQuery.data?.map<SellThroughRateHistoryInput>(
        (t) => ({
          timelineDate: t.timelineDate,
          sellThroughRate: t.sellThroughRatePercentage * 100,
        })
      );

    const defaultStrTargets: SellThroughRateHistoryInput[] = [
      { timelineDate: event.dates.start ?? undefined, sellThroughRate: 100 },
    ];

    methods.reset({
      strTargets: strTargets?.length ? strTargets : defaultStrTargets,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellThroughRateTargetsQuery.data]);

  return (
    <FormProvider {...methods}>
      <SellThroughRateSectionBody {...methods} event={event} {...rest} />
    </FormProvider>
  );
};

const SellThroughRateSectionBody = ({
  event,
  disabled,
}: SellThroughRateSectionProps) => {
  const sellThroughRatesQuery = useGetEventSellThroughRates(event.viagId);
  const { sellThroughRateTargetsQuery } = useSellerEventSellThroughRateTargets(
    event.viagId,
    event.mappingId
  );

  if (
    sellThroughRatesQuery.isLoading ||
    sellThroughRateTargetsQuery.isLoading
  ) {
    return <PosSpinner />;
  }

  return (
    <div className={styles.tableSection}>
      <Stack direction="column" width="full">
        <Stack direction="column" className={styles.header} gap="m">
          <span className={shared.typography.title3}>
            <Content id={ContentId.SellThroughRate} />
          </span>
          <span className={shared.typography.body2}>
            <Content id={ContentId.SellThroughRateSubtitle} />
          </span>
        </Stack>
        <Stack direction="row" gap="xl">
          <SellThroughRateTargetInput event={event} disabled={disabled} />
          <SellThroughRateGraphBody event={event} disabled={disabled} />
        </Stack>
      </Stack>
    </div>
  );
};
