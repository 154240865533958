import clsx from 'clsx';
import { ComponentProps, ReactNode } from 'react';
import { EventVenueMap } from 'src/components/Events/VenueMap/EventVenueMap';
import { Content } from 'src/contexts/ContentContext';
import {
  EventSessionRole,
  useEventHubContext,
} from 'src/contexts/EventHubContext';
import {
  ListingCompsNumberRange,
  ListingCompsSpectrum,
} from 'src/hooks/useComparableListingsVenueMapColor';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature, SectionInfo } from 'src/WebApiController';

import * as styles from './InventoryEventPage.css';

export const InventoryEventPageSeatMapV2 = ({
  selectedListings,
  selectedSectionIds,
  markedSectionIds,
  onSectionClicked,
  onSectionHovered,
  onToggleMirrors,
  setSelectedSections,
  showColorBand,
  getColor,
  statsContent,
}: {
  onSectionClicked: (e: MouseEvent, section: SectionInfo) => void;
  onSectionHovered?: (hoveredSection: SectionInfo) => ReactNode;
  onToggleMirrors?: (mirrors: SectionInfo[], exclude?: boolean) => void;
  setSelectedSections?: (sections: SectionInfo[]) => void;
  selectedListings: number[];
  selectedSectionIds: number[];
  markedSectionIds: number[];
  showColorBand?: boolean;
  getColor?: ComponentProps<typeof EventVenueMap>['getColor'];
  statsContent?: React.ReactNode;
}) => {
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const { sessionRole, enabled } = useEventHubContext();

  return (
    <>
      <div
        className={clsx(styles.pricingModelTile, {
          [styles.sessionMode]:
            enabled && sessionRole === EventSessionRole.SidePanel,
        })}
      >
        <EventVenueMap
          selectedSectionIds={selectedSectionIds}
          markedSectionIds={markedSectionIds}
          onSectionClicked={onSectionClicked}
          onSectionHovered={onSectionHovered}
          onToggleMirrors={onToggleMirrors}
          setSelectedSections={setSelectedSections}
          showDefaultMapColors={selectedListings.length === 0}
          colorBandProps={
            showColorBand
              ? {
                  numOfSteps: ListingCompsNumberRange,
                  spectrum: ListingCompsSpectrum,
                  labels: [ContentId.Cheaper, ContentId.MoreExpensive],
                }
              : undefined
          }
          getColor={getColor}
          infoIconContent={
            hasAutoPricingFeature ? (
              <Content id={ContentId.AutoPricingDisclaimer} />
            ) : undefined
          }
          statsContent={statsContent}
        />
      </div>
    </>
  );
};
