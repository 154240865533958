import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const FlattenedViewIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 24">
      <rect x="2" y="4" width="20" height="3" rx="0.3" fill="inherit" />
      <rect x="2" y="10.5" width="20" height="3" rx="0.3" fill="inherit" />
      <rect x="2" y="17" width="20" height="3" rx="0.3" fill="inherit" />
    </SvgWrapper>
  );
};
