import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ModalContext } from 'src/contexts/ModalContext';
import { UnsavedChanges } from 'src/dialogs/UnsavedChanges';
import { ModalHeader, ModalProps } from 'src/modals/Modal';

export type CancellableFormHeaderProps = PropsWithChildren<{
  onClose?: () => void;
  onBeforeClose?: () => void;
  cancelTo?: ModalProps;
  showDialogOnCancel?: boolean;
  disabled?: boolean;
}>;

export function CancellableFormHeader({
  onClose,
  onBeforeClose,
  cancelTo,
  children, // action button
  showDialogOnCancel,
  disabled,
}: PropsWithChildren<CancellableFormHeaderProps>) {
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { setModal, closeModal, updateModal } = useContext(ModalContext);

  /**
   * Allow a modal 'config' (`Modal` component props) to determine
   * Which modal to go back to in the workflow
   * If nothing is provided, treat as a close button
   */
  const goToCancelView = useCallback(() => {
    setShowDialog(false);

    onBeforeClose?.();

    if (cancelTo) {
      setModal(cancelTo);
    } else if (onClose) {
      onClose();
    } else {
      closeModal(true);
    }
  }, [onBeforeClose, onClose, cancelTo, setModal, closeModal]);

  const onCancel = useCallback(() => {
    if (showDialogOnCancel) {
      setShowDialog(true);
    } else {
      goToCancelView();
    }
  }, [showDialogOnCancel, goToCancelView]);

  const closeDialog = useCallback(() => {
    setShowDialog(false);
  }, [setShowDialog]);

  useEffect(() => {
    updateModal({ onCloseOverride: onCancel });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDialogOnCancel]);

  return (
    <ModalHeader onClose={onCancel} disabled={disabled}>
      {children}
      <UnsavedChanges
        onOkay={goToCancelView}
        onCancel={closeDialog}
        isOpen={showDialog}
      />
    </ModalHeader>
  );
}
