import { useCallback } from 'react';
import { INVENTORY_BULK_UPDATE_PRICE_FLOOR_CEILING_UPDATE_KEY } from 'src/components/Listings/InventoryActionDropdown';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import {
  BulkEditListingPricingFloorCeilingDialog,
  FloorCeiling,
} from 'src/dialogs/BulkEdits/BulkEditListingPricingFloorCeilingDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditPreviewWithDetails,
  EventWithData,
} from 'src/WebApiController';
import {
  BulkEditListingClient,
  ListingDetails,
  ListingQuery,
  PricingFloorCeilingAdjustmentType,
} from 'src/WebApiController';

type BulkChangeFloorCeilingActionsProps = {
  setIsLoading: (v: boolean) => void;
  isLoading: boolean;
  event?: EventWithData['event'] | undefined;
  filterQueryWithEventIds: ListingQuery;
};

export const useBulkChangeFloorCeilingActions = ({
  setIsLoading,
  isLoading,
  event,
  filterQueryWithEventIds,
}: BulkChangeFloorCeilingActionsProps) => {
  const {
    openDialog: openBulkChangeFloorCeilingDialog,
    closeDialog: closeBulkChangeFloorCeilingDialog,
  } = useDialog(
    DialogId.BulkChangeFloorCeilingActions,
    BulkEditListingPricingFloorCeilingDialog
  );

  const { showErrorDialog } = useErrorBoundaryContext();
  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();
  const { activeAccountWebClientConfig } = useAppContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const updateKey = event
    ? `listingPricingFloorCeiling-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_PRICE_FLOOR_CEILING_UPDATE_KEY;

  const onSubmitChangeFloorCeilingCallback = useCallback(
    async (
      amount: number,
      isAbove: boolean,
      floorCeiling: FloorCeiling,
      isRelative: boolean,
      adjustmentType: PricingFloorCeilingAdjustmentType,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      setIsLoading(true);

      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdatePriceFloorCeilingPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdatePriceFloorCeilingPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const client = new BulkEditListingClient(
              activeAccountWebClientConfig
            );

            // At the moment, if we are submitting a bulk ceiling update, we should always use a percentage
            // based adjustment, and the values of isRelative and adjustmentType are ignored.
            let adjustment = (isAbove ? 100 + amount : 100 - amount) / 100;
            if (floorCeiling === FloorCeiling.Floor && !isRelative) {
              // If we are performing a bulk floor adjustment, and isRelative is false, that means we want
              // to do an absolute adjustment. The adjustment value needs to be positive if isAbove is true,
              // and negative if isAbove is false
              adjustment = isAbove ? amount : -1 * amount;
            }

            const succeeded =
              floorCeiling === FloorCeiling.Floor
                ? await client.bulkUpdatePriceFloor(
                    preview!.preview,
                    adjustment,
                    updateKey,
                    isRelative,
                    adjustmentType,
                    supportBackgroundProcess
                  )
                : await client.bulkUpdatePriceCeiling(
                    preview!.preview,
                    adjustment,
                    updateKey,
                    supportBackgroundProcess
                  );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }

              closeBulkChangeFloorCeilingDialog();
            }
          },
          (error) => {
            showErrorDialog('ListingClient.onSubmitChangeFloorCeiling', error, {
              trackErrorData: { amount, isAbove, floorCeiling, preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      setIsLoading,
      filterQueryWithEventIds,
      showErrorDialog,
      updateKey,
      closeBulkChangeFloorCeilingDialog,
      setActivePosEntity,
      refreshExpandedListItems,
    ]
  );

  const onChangeFloorCeilingClick = useCallback(
    (e: React.MouseEvent, floorCeiling: FloorCeiling) => {
      e.preventDefault();
      e.stopPropagation();

      openBulkChangeFloorCeilingDialog({
        updateKey,
        floorCeiling,
        onOkay: onSubmitChangeFloorCeilingCallback,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkChangeFloorCeilingDialog,
      });
    },
    [
      closeBulkChangeFloorCeilingDialog,
      onSubmitChangeFloorCeilingCallback,
      openBulkChangeFloorCeilingDialog,
      setIsLoading,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <>
        <PosDropdownItem
          key="AdjustPricingFloor"
          onClick={(e) => onChangeFloorCeilingClick(e, FloorCeiling.Floor)}
          disabled={isLoading}
        >
          <Content id={ContentId.AdjustPricingFloor} />
        </PosDropdownItem>
        <PosDropdownItem
          key="AdjustPricingCeiling"
          onClick={(e) => onChangeFloorCeilingClick(e, FloorCeiling.Ceiling)}
          disabled={isLoading}
        >
          <Content id={ContentId.AdjustPricingCeiling} />
        </PosDropdownItem>
      </>
    ),
  };
};
