import {
  MutableRefObject,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { SetMarketplaceChargeDialog } from 'src/modals/SaleDetails/components/MarketplacePaymentSection/SetMarketplaceChargeDialog';
import { posChangedField } from 'src/utils/posFieldUtils';
import {
  SaleDetails,
  SaleInput,
  SiteMarketplacePaymentLineInput,
} from 'src/WebApiController';

export interface SetMarketplaceChargeActions {
  addNewMarketplacePaymentLine: () => void;
  editNewMarketplacePaymentLine: (
    marketplacePaymentLine: SiteMarketplacePaymentLineInput
  ) => void;
  removeNewMarketplacePaymentLine: (externalPaymentId: string) => void;
}

interface SetMarketplaceChargeProps {
  setMarketplaceActionsRef: MutableRefObject<SetMarketplaceChargeActions | null>;
  sale: SaleDetails;
}

export const SetMarketplaceCharge = ({
  setMarketplaceActionsRef,
  sale,
}: SetMarketplaceChargeProps) => {
  const setMarketplaceChargeDialog = useBasicDialog();
  const [currentMarketplacePaymentLine, setCurrentMarketplacePaymentLine] =
    useState<SiteMarketplacePaymentLineInput | undefined>();

  const { setValue, watch } = useFormContext<SaleInput>();

  const marketplacePaymentLines = watch('marketplacePaymentLines')?.value;
  const onSave = useCallback(
    (formMarketplacePaymentLineInput: SiteMarketplacePaymentLineInput) => {
      let updatedMktPaymentLines: SiteMarketplacePaymentLineInput[] = [];
      const isUpdating = !!currentMarketplacePaymentLine;
      if (isUpdating) {
        // Update mktPaymentLine in place
        updatedMktPaymentLines = (marketplacePaymentLines ?? []).map(
          (mktPaymentLine) =>
            mktPaymentLine.externalPaymentLineId ===
            formMarketplacePaymentLineInput.externalPaymentLineId
              ? formMarketplacePaymentLineInput
              : mktPaymentLine
        );
      } else {
        // If is new mktPaymentLine
        updatedMktPaymentLines = [
          ...(marketplacePaymentLines ?? []),
          formMarketplacePaymentLineInput,
        ];
      }

      setValue(
        'marketplacePaymentLines',
        posChangedField(updatedMktPaymentLines),
        {
          shouldDirty: true,
        }
      );
      setMarketplaceChargeDialog.closeDialog();
    },
    [
      currentMarketplacePaymentLine,
      marketplacePaymentLines,
      setMarketplaceChargeDialog,
      setValue,
    ]
  );

  const onCancel = useCallback(() => {
    setMarketplaceChargeDialog.closeDialog();
  }, [setMarketplaceChargeDialog]);

  const removeNewMarketplacePaymentLine = useCallback(
    (externalPaymentId: string) => {
      const filteredMarketplacePaymentLines = marketplacePaymentLines?.filter(
        (mktPaymentLine) =>
          mktPaymentLine.externalPaymentLineId !== externalPaymentId
      );
      setValue(
        'marketplacePaymentLines',
        posChangedField(filteredMarketplacePaymentLines ?? []),
        {
          shouldDirty: true,
        }
      );
    },
    [marketplacePaymentLines, setValue]
  );

  useImperativeHandle(
    setMarketplaceActionsRef,
    () => {
      return {
        addNewMarketplacePaymentLine: () => {
          setCurrentMarketplacePaymentLine(undefined);
          setMarketplaceChargeDialog.launchDialog();
        },
        editNewMarketplacePaymentLine: (
          marketplacePaymentLine: SiteMarketplacePaymentLineInput
        ) => {
          setCurrentMarketplacePaymentLine(marketplacePaymentLine);
          setMarketplaceChargeDialog.launchDialog();
        },
        removeNewMarketplacePaymentLine,
      };
    },
    [removeNewMarketplacePaymentLine, setMarketplaceChargeDialog]
  );

  if (!setMarketplaceChargeDialog.dialogProps.isOpen) {
    return null;
  }

  return (
    <SetMarketplaceChargeDialog
      {...setMarketplaceChargeDialog.dialogProps}
      siteMarketplacePaymentLineInput={currentMarketplacePaymentLine}
      sale={sale}
      onSave={onSave}
      onCancel={onCancel}
    />
  );
};
