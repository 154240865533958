import { format } from 'date-fns';
import { ReactNode, useEffect, useMemo, useRef } from 'react';
import { useScroll } from 'react-use';
import {
  Components,
  GroupedVirtuoso,
  GroupedVirtuosoHandle,
} from 'react-virtuoso';
import { ListOnScrollProps } from 'react-window';
import { PageSection } from 'src/components/common/PageSection';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import {
  VirtuosoContext,
  VirtuosoTopListItemWrapper,
} from 'src/core/ui/Virtuoso';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { PURCHASE_SORT_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  EventUiSort,
  getEventSort,
  getEventUiSort,
} from 'src/utils/eventQueryUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import {
  EventSort,
  PurchaseOrderQuery,
  TimePeriodContainingPurchaseOrderResult,
} from 'src/WebApiController';

import * as eventStyles from '../EventsAccordion/EventsAccordion.css';
import * as styles from './PurchaseDateAccordion.css';
import { PurchaseDateAccordionMonthGroupItem } from './PurchaseDatesAccordionMonthGroupItem';

const PurchasesVirtuosoID = 'purchases-virtuoso';

const Header: Components<unknown, VirtuosoContext>['Header'] = ({
  context,
}: {
  context?: VirtuosoContext;
}) => {
  return <>{context?.before}</>;
};

export type VirtualizedPurchaseDatesAccordionProps = {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
  onSelect: (id: string) => void;
  selectedIds?: string[];
  items: TimePeriodContainingPurchaseOrderResult[];
  scrollToId?: string;
  onScroll?: (props: ListOnScrollProps) => void;
  tableActions?: React.ReactElement;
};

export const PurchaseDatesAccordion = ({
  before,
  onSelect,
  selectedIds,
  items,
  topListItemBefore,
  tableActions,
}: VirtualizedPurchaseDatesAccordionProps) => {
  const scrollRef = useRef<HTMLElement | null>(null);
  const { y: scrollY } = useScroll(scrollRef);
  const { setAllGroupsIds } = useMultiSelectionContext();

  const { groups, groupCounts } = useMemo(() => {
    const groups: string[] = [];
    const groupCounts: number[] = [];
    for (const item of items) {
      const dividerText = format(new Date(item.firstOfTimePeriod), 'yyyy', {
        locale: getLocaleFromLanguageOrCurrent(),
      });
      if (groups.length === 0 || groups[groups.length - 1] !== dividerText) {
        groups.push(dividerText);
        groupCounts.push(1);
      } else {
        groupCounts[groupCounts.length - 1] += 1;
      }
    }
    return { groups, groupCounts };
  }, [items]);

  useEffect(() => {
    const itemsIds = items.map((item) => item.firstOfTimePeriod);
    setAllGroupsIds(itemsIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  const virtuosoRef = useRef<GroupedVirtuosoHandle>(null);
  const isLargeDesktop = useMatchMedia('largeDesktop');

  const { filterQuery, setFilterQuery } =
    useFilterQueryContext<PurchaseOrderQuery>();

  return (
    <GroupedVirtuoso
      id={PurchasesVirtuosoID}
      ref={virtuosoRef}
      scrollerRef={(el) => {
        if (el instanceof HTMLElement) scrollRef.current = el;
      }}
      className={eventStyles.root}
      context={
        {
          before,
          scrollY,
          topListItemBefore,
          virtuosoId: PurchasesVirtuosoID,
        } satisfies VirtuosoContext
      }
      components={{
        Header,
        TopItemList: VirtuosoTopListItemWrapper,
      }}
      groupCounts={groupCounts}
      groupContent={(index) => {
        return (
          <div className={styles.purchaseAccordionContainer}>
            <PageSection.Header
              className={eventStyles.groupHeader}
              onClick={() => {
                virtuosoRef.current?.scrollToIndex({ groupIndex: index });
              }}
              action={
                <div className={eventStyles.groupHeaderAction}>
                  {!isLargeDesktop && (
                    <PosEnumSelect
                      variant="textPlain"
                      shape="none"
                      value={
                        filterQuery
                          ? getEventUiSort(
                              EventSort.Date,
                              filterQuery?.isSortDescending
                            )
                          : EventUiSort.DateDesc
                      }
                      defaultValue={getEventUiSort(EventSort.Date, true)}
                      onChange={(sort) => {
                        const [, isSortDescending] = getEventSort(sort);
                        if (
                          isSortDescending !== filterQuery?.isSortDescending
                        ) {
                          setFilterQuery({
                            ...(filterQuery ?? {}),
                            isSortDescending,
                          });
                        }
                      }}
                      valueOptionsContent={PURCHASE_SORT_TO_CID}
                    />
                  )}
                  {tableActions}
                </div>
              }
            >
              <div className={eventStyles.groupHeaderTitle}>
                {(() => {
                  const [month, year] = groups[index].split(' ');
                  return (
                    <>
                      <span>{month}</span>
                      &nbsp;
                      <span className={eventStyles.itemHeaderYear}>{year}</span>
                    </>
                  );
                })()}
              </div>
            </PageSection.Header>
          </div>
        );
      }}
      itemContent={(index) => (
        <PurchaseDateAccordionMonthGroupItem
          item={items[index]}
          selectedIds={selectedIds}
          onSelect={onSelect}
        />
      )}
    />
  );
};
