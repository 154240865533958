import {
  getExpandedRowModel,
  InitialTableState,
  PaginationState,
  SortingState,
  TableOptions,
  Updater,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Table } from 'src/tables/Table';
import { Feature } from 'src/WebApiController';

import {
  getListingGroupTableConfig,
  IListingGroupItemWithMeta,
} from './ListingGroupTableColumnsConfig';

export type ListingGroupInputTableDataProps = {
  data: IListingGroupItemWithMeta[];
};

export type ListingGroupInputTableProps = ListingGroupInputTableDataProps & {
  onMount?: () => void;
  onUnmount?: (state: object) => void;
  initState?: InitialTableState;
  sorting?: SortingState;
  onSortingChange?: (sorting: Updater<SortingState>) => void;
  useVirtuoso?: boolean;
};

const PAGE_SIZE = 10;

export function ListingGroupInputTable({
  data,
  onMount,
  onUnmount,
  initState,
  sorting,
  onSortingChange,
  useVirtuoso,
}: ListingGroupInputTableProps) {
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const hasUndercutByRankListingGroupFeature = useUserHasFeature(
    Feature.UndercutByRankListingGroup
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: PAGE_SIZE,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize: Math.max(pageSize, data.length),
    }),
    [data.length, pageIndex, pageSize]
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const options: Partial<TableOptions<IListingGroupItemWithMeta | null>> =
    useMemo(
      () => ({
        data,
        columns: getListingGroupTableConfig().map((c) => ({
          ...c,
          enableSorting:
            c.id === 'Section' ||
            c.id === 'Row' ||
            c.id === 'Remaining' ||
            c.enableSorting,
        })),
        state: {
          pagination,
          sorting,
        },
        onPaginationChange: setPagination,
        onSortingChange: onSortingChange,
        getRowCanExpand: () =>
          hasAutoPricingFeature && hasUndercutByRankListingGroupFeature,
        getSubRows: (originalRow) =>
          (originalRow?.id ?? 0) > 0
            ? [{ ...originalRow!, id: -originalRow!.id }]
            : [], // we just want the same row - but we'll be display separate content
        getExpandedRowModel: (table) => getExpandedRowModel()(table),
        paginateExpandedRows: false,
      }),
      [
        data,
        hasAutoPricingFeature,
        hasUndercutByRankListingGroupFeature,
        onSortingChange,
        pagination,
        sorting,
      ]
    );

  return <Table options={options} useVirtuoso={useVirtuoso} />;
}
