import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { ActionOutboxEntityType, Permission } from 'src/WebApiController';

interface UseBulkUpdateInternalNotesPermissionsProps {
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.Purchase;
}

interface BulkUpdateInternalNotesPermissions {
  hasEditInternalNotesPermissions: boolean;
}

export const useBulkUpdateInternalNotesPermissions = ({
  entityType,
}: UseBulkUpdateInternalNotesPermissionsProps): BulkUpdateInternalNotesPermissions => {
  const hasEditInternalNotesPermissions = useUserHasAnyOfPermissions(
    ...(entityType === ActionOutboxEntityType.Listing
      ? [
          Permission.Inventory_UpdateListingInfo,
          Permission.Inventory_UpdateListingInfoOnIsPricerOf,
          Permission.Inventory_UpdateListingInfoOnPurchasedBy,
        ]
      : entityType === ActionOutboxEntityType.Sale
      ? [
          Permission.Sales_UpdateSalesInfo,
          Permission.Sales_UpdateSalesInfoOnIsFulfillerOf,
          Permission.Sales_UpdateSalesInfoOnIsPricerOf,
          Permission.Sales_UpdateSaleOnPurchasedBy,
        ]
      : [Permission.Purchases_Edit, Permission.Purchases_EditOwned])
  );

  return {
    hasEditInternalNotesPermissions,
  };
};
