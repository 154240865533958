import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const VenueMapContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
  padding: ${() => vars.spacing.lg};
  border-radius: ${() => vars.spacing.lg};
`;

export const StyledDiv = styled.div<{
  removePinchZoom?: boolean;
  setTransitionAnimation?: boolean;
  blurMap?: boolean;
  hideMap?: boolean;
}>`
  & svg {
    height: 100%;
    width: 100%;
  }
  & * {
    text-anchor: middle;
    font-weight: bold;
  }
  & .svg-pan-zoom_viewport {
    transition: ${(props) =>
      props.removePinchZoom
        ? `none`
        : `transform 0.5s cubic-bezier(0, 0.8, 0.5, 1)`};
  }
  height: 100%;
  cursor: move;
  transition: ${({ setTransitionAnimation }) =>
    setTransitionAnimation ? '0.2s filter linear, 0.2s opacity ease-in' : ''};
  filter: ${({ blurMap }) => (blurMap ? 'blur(5px)' : '')};
  opacity: ${({ hideMap }) => (hideMap ? 0 : 1)};
`;

export const StyledResetVariant = styled.span<{
  showFiltersOnTop?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-anchor: none;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: ${(props) => (props.showFiltersOnTop ? 1 : 100)};
  padding: ${() => vars.spacing.sm};
`;

export const IconParent = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
`;

export const StyledIconParentVariant = styled(IconParent)`
  height: ${() => vars.spacing.xl};
  width: ${() => vars.spacing.xl};
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
  border-radius: 50%;
  background-color: ${(props) => props.theme.colors.backgroundPrimary};
`;

export const StyledBorderDiv = styled.div`
  display: none;
  margin-top: ${() => vars.spacing.sm};
  width: 40px;
  @media ${({ theme: { media } }) => `${media.tablet}, ${media.desktop}`} {
    display: block;
  }
`;

export const StyledHoverDiv = styled.div`
  display: none;

  &:nth-of-type(1) {
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.borderPrimary};
    border-radius: ${({ theme }) =>
      `${theme.borderRadius.s}, ${theme.borderRadius.s}, 0, 0`};
  }

  &:nth-of-type(2) {
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: ${(props) => props.theme.colors.borderPrimary};
    border-radius: ${({ theme }) =>
      `0, 0, ${theme.borderRadius.s}, ${theme.borderRadius.s}`};
  }

  @media ${({ theme: { media } }) => `${media.tablet}, ${media.desktop}`} {
    display: flex;
    background-color: ${(props) => props.theme.colors.backgroundPrimary};
    justify-content: center;
    align-items: center;
    height: 30px;
    :hover {
      background-color: ${(props) => props.theme.colors.backgroundInteraction};
      cursor: pointer;
    }
  }
`;

export const SellerListingDiv = styled.span`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('body2')};
  border-radius: ${({ theme: { borderRadius } }) => borderRadius.s};
  line-height: ${() => vars.typography.lineHeight.sm};
  padding: 0 ${() => vars.spacing.sm};
  height: ${() => vars.typography.lineHeight.sm};
  display: inline-block;
  background-color: ${(props) => props.theme.colors.backgroundInfo};
  color: ${(props) => props.theme.colors.textStrong};
  text-align: center;
`;
