import { ComponentProps, useCallback, useContext, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmButton } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useAutoCompSettingsPreview } from 'src/hooks/api/useAutoCompSettingsPreview';
import { useGetAutoCompSettings } from 'src/hooks/api/useGetAutoCompSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AutoCompAndAutoGroupMode,
  ConfigTypeOverride,
  Event,
  Feature,
  PricingClient,
  RowGroupSetting,
} from 'src/WebApiController';

import { ModalBodyDataContainer } from '../Modal/Modal.styled';
import { GroupListingAutoCompSettingsForm } from './autoCompGroup.types';
import * as styles from './GroupListingsAutoCompSettings.css';
import { GroupListingsAutoCompSettingsBody } from './GroupListingsAutoCompSettingsBody';
import { GroupListingsAutoGroupListing } from './GroupListingsAutoGroupListings';
import { GroupListingsAutoGroupListingsV2 } from './GroupListingsAutoGroupListingsV2';

export const GroupListingsAutoCompSettings = ({
  event,
  mode,
}: {
  event: Event;
  mode: AutoCompAndAutoGroupMode;
}) => {
  const autoCompSettingsQuery = useGetAutoCompSettings({
    viagogoEventId: event?.viagId,
  });

  const methods = useForm<GroupListingAutoCompSettingsForm>({
    defaultValues: {
      configTypeOverride:
        autoCompSettingsQuery.data?.configTypeOverride ??
        ConfigTypeOverride.ConcertsSectionsBehindStage,
      rowCompOffset: autoCompSettingsQuery.data?.rowCompOffset ?? 0,
      rowGroupSetting:
        autoCompSettingsQuery.data?.rowGroupSetting ??
        RowGroupSetting.SingleTwoToFiveSixPlus,
      rowCompOverrides: autoCompSettingsQuery.data?.rowCompOverrides ?? [],
      sectionGroupCustomRankEnabled:
        mode != AutoCompAndAutoGroupMode.AutoGroup &&
        (autoCompSettingsQuery.data?.sectionGroupCustomRankEnabled ?? false),
      zoneSectionAreaGroupLookup: {
        zoneNameToRankMap: {},
        sectionIdToGroupIdMap: {},
        groupIdToSectionIdMap: {},
        customSectionGroups: [],
        zoneSectionAreaRankOverrides: [],
      },
    },
  });

  useEffect(() => {
    if (autoCompSettingsQuery.data) {
      methods.reset({
        configTypeOverride:
          autoCompSettingsQuery.data.configTypeOverride ??
          ConfigTypeOverride.ConcertsSectionsBehindStage,
        combineCornersAndEnds: true,
        rowCompOffset: autoCompSettingsQuery.data.rowCompOffset ?? 0,
        rowGroupSetting:
          autoCompSettingsQuery.data.rowGroupSetting ??
          RowGroupSetting.SingleTwoToFiveSixPlus,
        rowCompOverrides: autoCompSettingsQuery.data.rowCompOverrides ?? [],
        sectionGroupCustomRankEnabled:
          mode != AutoCompAndAutoGroupMode.AutoGroup &&
          (autoCompSettingsQuery.data.sectionGroupCustomRankEnabled ?? false),
        zoneSectionAreaGroupLookup: {
          zoneNameToRankMap: {},
          sectionIdToGroupIdMap: {},
          groupIdToSectionIdMap: {},
          customSectionGroups: [],
          zoneSectionAreaRankOverrides: [],
        },
      });
    }
  }, [autoCompSettingsQuery.data, methods, mode]);

  return autoCompSettingsQuery.isLoading ||
    autoCompSettingsQuery.isFetching ||
    autoCompSettingsQuery.isPending ? (
    <div className={styles.spinnerContainer}>
      <PosSpinner />
    </div>
  ) : (
    <EventMapContextProvider event={event}>
      <FormProvider {...methods}>
        <GroupListingsAutoCompSettingsContent
          {...methods}
          event={event}
          mode={mode}
        />
      </FormProvider>
    </EventMapContextProvider>
  );
};

const GroupListingsAutoCompSettingsContent = ({
  watch,
  setValue,
  getValues,
  event,
  mode,
}: { event: Event; mode: AutoCompAndAutoGroupMode } & Omit<
  ComponentProps<
    typeof FormProvider<GroupListingAutoCompSettingsForm, unknown>
  >,
  'children'
>) => {
  const { setModal, closeModal } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const rowCompOffset = watch('rowCompOffset');
  const rowGroupSetting = watch('rowGroupSetting');
  const configTypeOverride = watch('configTypeOverride');
  const combineCornersAndEnds = watch('combineCornersAndEnds');
  const rowCompOverrides = watch('rowCompOverrides');
  const sectionGroupCustomRankEnabled = watch('sectionGroupCustomRankEnabled');
  const lookup = watch('zoneSectionAreaGroupLookup');

  const autoCompSettingsPreviewQuery = useAutoCompSettingsPreview({
    viagogoEventId: event?.viagId,
    configTypeOverride: configTypeOverride,
    combineCornersAndEnds: combineCornersAndEnds,
    enabled: true,
  });

  const hasUpgradedGroupListingViewFeature = useUserHasFeature(
    Feature.UpgradedGroupListingView
  );

  useEffect(() => {
    if (autoCompSettingsPreviewQuery.data) {
      setValue(
        'zoneSectionAreaGroupLookup',
        autoCompSettingsPreviewQuery.data.zoneSectionAreaGroupLookup
      );
    }
  }, [autoCompSettingsPreviewQuery.data, setValue]);

  const onSaveAutoCompSettings = useCallback(() => {
    const autoCompForm = getValues();

    if (
      autoCompForm.configTypeOverride &&
      autoCompForm.rowCompOffset != null &&
      autoCompForm.rowGroupSetting &&
      autoCompForm.rowCompOverrides &&
      lookup
    )
      return tryInvokeApi(
        async () => {
          await new PricingClient(
            activeAccountWebClientConfig
          ).upsertAutoCompSettings({
            viagogoEventId: event.viagId!,
            configTypeOverride: autoCompForm.configTypeOverride,
            rowCompOffset: autoCompForm.rowCompOffset,
            rowGroupSetting: autoCompForm.rowGroupSetting,
            rowCompOverrides: autoCompForm.rowCompOverrides,
            sectionGroupCustomRankEnabled:
              autoCompForm.sectionGroupCustomRankEnabled,
            zoneSectionAreaGroupLookupJson: JSON.stringify(lookup),
          });
        },
        (error) => {
          showErrorDialog('PricingClient.upsertAutoCompSettings', error, {
            trackErrorData: {
              event,
              configTypeOverride: autoCompForm.configTypeOverride,
              rowCompOffset: autoCompForm.rowCompOffset,
              rowGroupSetting: autoCompForm.rowGroupSetting,
              rowCompOverrides: autoCompForm.rowCompOverrides,
            },
          });
        }
      );

    return Promise.resolve();
  }, [activeAccountWebClientConfig, event, getValues, lookup, showErrorDialog]);

  return (
    <>
      <CancellableFormHeader>
        <ConnectedEventEntityHeader
          title={
            mode === AutoCompAndAutoGroupMode.AutoGroup ? (
              <>
                <Content id={ContentId.AutoGroup} />{' '}
                <Content id={ContentId.Listings} />
              </>
            ) : (
              <Content
                id={
                  mode === AutoCompAndAutoGroupMode.AutoCompAndAutoGroup
                    ? ContentId.AutoCompAndGroupListings
                    : ContentId.AutoComp
                }
              />
            )
          }
        />
      </CancellableFormHeader>

      <ModalBody>
        <ModalBodyDataContainer>
          {autoCompSettingsPreviewQuery.isPending ||
          autoCompSettingsPreviewQuery.isFetching ||
          autoCompSettingsPreviewQuery.isLoading ? (
            <PosSpinner />
          ) : (
            <GroupListingsAutoCompSettingsBody mode={mode} />
          )}
        </ModalBodyDataContainer>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter>
          {
            <ConfirmButton
              onClick={() => {
                if (mode == AutoCompAndAutoGroupMode.AutoComp) {
                  onSaveAutoCompSettings().then(() => closeModal());
                  return;
                }

                onSaveAutoCompSettings().then(() =>
                  setModal({
                    children: hasUpgradedGroupListingViewFeature ? (
                      <GroupListingsAutoGroupListingsV2
                        event={event}
                        mode={mode}
                        rowCompOffset={rowCompOffset}
                        rowGroupSetting={rowGroupSetting}
                        configTypeOverride={configTypeOverride}
                        combineCornersAndEnds={combineCornersAndEnds}
                        rowCompOverrides={rowCompOverrides}
                        sectionGroupCustomRankEnabled={
                          sectionGroupCustomRankEnabled
                        }
                        lookup={lookup}
                        cancelTo={{
                          children: (
                            <GroupListingsAutoCompSettings
                              event={event}
                              mode={mode}
                            />
                          ),
                          clickOutsideToClose: true,
                          size: 'slide-in-lg',
                        }}
                        isLoading={false}
                      />
                    ) : (
                      <GroupListingsAutoGroupListing
                        event={event}
                        mode={mode}
                        rowCompOffset={rowCompOffset}
                        rowGroupSetting={rowGroupSetting}
                        configTypeOverride={configTypeOverride}
                        combineCornersAndEnds={combineCornersAndEnds}
                        rowCompOverrides={rowCompOverrides}
                        sectionGroupCustomRankEnabled={
                          sectionGroupCustomRankEnabled
                        }
                        lookup={lookup}
                        cancelTo={{
                          children: (
                            <GroupListingsAutoCompSettings
                              event={event}
                              mode={mode}
                            />
                          ),
                          clickOutsideToClose: true,
                          size: 'slide-in-lg',
                        }}
                        isLoading={false}
                      />
                    ),
                    clickOutsideToClose: true,
                    size: hasUpgradedGroupListingViewFeature
                      ? 'slide-left'
                      : 'slide-in',
                  })
                );
              }}
              textContentId={ContentId.Next}
            />
          }
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
