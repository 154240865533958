import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { DATA_REFRESH_RATE_IN_MILLIS_SHORT } from 'src/utils/constants/constants';
import { EventConfigClient, SellerEventNoteResult } from 'src/WebApiController';

export const useSellerEventNote = (viagogoEventId?: number | null) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();
  const queryClient = useQueryClient();

  const queryKey = [
    'EventConfigClient.getSellerEventNotesByViagogoEventId',
    viagogoEventId,
  ];

  const shouldQuery = Boolean(viagogoEventId);

  const notesQuery = useQuery({
    queryKey,
    queryFn: async () => {
      if (!viagogoEventId) {
        return null;
      }
      return await new EventConfigClient(
        activeAccountWebClientConfig
      ).getSellerEventNotesByViagogoEventId(viagogoEventId);
    },
    enabled: shouldQuery,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_SHORT,
    meta: {
      onError: (error: Error) => {
        trackError(
          'EventConfigClient.getSellerEventNotesByViagogoEventId',
          error,
          {
            viagogoEventId,
          }
        );
      },
    },
  });

  const addNoteMutation = useMutation({
    mutationFn: async (newNote: string) => {
      return await new EventConfigClient(
        activeAccountWebClientConfig
      ).insertSellerEventNotes(viagogoEventId ?? undefined, newNote);
    },
    onError: (error: Error, newNote: string, context: any) => {
      queryClient.setQueryData(queryKey, context?.previousNotes);
      showErrorDialog('EventConfigClient.insertSellerEventNotes', error, {
        trackErrorData: { viagogoEventId, newNote },
      });
    },
    onSuccess: (result: SellerEventNoteResult) => {
      queryClient.setQueryData(
        queryKey,
        (old: SellerEventNoteResult[] = []) => [...old, result]
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const addNote = useCallback(
    async (newNote: string) => {
      if (newNote.trim() === '') {
        return;
      }
      await addNoteMutation.mutateAsync(newNote);
    },
    [addNoteMutation]
  );

  return {
    notes: notesQuery.data,
    addNote,
    isLoading: notesQuery.isLoading || addNoteMutation.isPending,
    isSuccess: notesQuery.isSuccess && addNoteMutation.isSuccess,
  };
};
