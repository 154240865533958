import React from 'react';
import { vars } from 'src/core/themes';

interface CircleIconProps {
  icon: React.ReactNode;
  size?: number | string;
  backgroundColor?: string;
  styleOverride?: React.CSSProperties;
  className?: string;
}

const CircleIcon: React.FC<CircleIconProps> = ({
  icon,
  size = 80,
  backgroundColor = vars.color.backgroundDisabled,
  styleOverride = {},
  className = '',
}) => (
  <div
    className={className}
    style={{
      width: size,
      height: size,
      backgroundColor,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...styleOverride,
    }}
  >
    {icon}
  </div>
);

export default CircleIcon;
