import { Content } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { WarningOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './NewHeatmapAvailableNotification.css';

export const NewHeatmapAvailableNotification = () => {
  return (
    <div className={styles.notificationContainer}>
      <Stack direction="row" alignItems="center" gap="m">
        <WarningOutlineIcon className={styles.icon} />
        <Content id={ContentId.DeprecatingHeatMaps} />
      </Stack>
    </div>
  );
};
