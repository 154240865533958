import { formatInTimeZone } from 'date-fns-tz';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { ContentId } from 'src/utils/constants/contentId';

import { ActivityLogDateCellDiv } from '../ActivityLogTable.styled';

export function ActivityLogDateCell({ date }: { date: Date | null }) {
  const { timeZone } = useSiteTimezoneContext();
  const formattedDate = date ? (
    <>
      <strong>{formatInTimeZone(date, timeZone, 'LLL do yyyy')}</strong>
      <span>{formatInTimeZone(date, timeZone, 'hh:mm:ss aa')}</span>
    </>
  ) : (
    <Content id={ContentId.NA} />
  );
  return <ActivityLogDateCellDiv>{formattedDate}</ActivityLogDateCellDiv>;
}
