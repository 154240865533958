import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const PendingAllocationIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 23">
      <path
        d="M11.8999 22.5C17.1466 22.5 21.3999 18.2467 21.3999 13C21.3999 7.75329 17.1466 3.5 11.8999 3.5C6.6532 3.5 2.3999 7.75329 2.3999 13C2.3999 18.2467 6.6532 22.5 11.8999 22.5Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5999 20.8L4.8999 22.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3 20.8L18.9 22.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.3999 13.5H12.3999V7.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.89995 7.50005C0.299951 5.90005 0.299951 3.60005 1.89995 2.00005C3.49995 0.400049 5.79995 0.400049 7.39995 2.00005"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8999 7.50005C23.4999 5.90005 23.4999 3.60005 21.8999 2.00005C20.2999 0.400049 17.9999 0.400049 16.3999 2.00005"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
