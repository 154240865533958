import { ReactNode } from 'react';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { shared } from 'src/core/themes';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getCurrentLanguage } from 'src/utils/localeUtils';

import { MerchantColorBadge } from '../../common/MerchantColorBadge';
import * as styles from './PurchaseEmailDisplay.css';

const dateFormatter = new Intl.DateTimeFormat(getCurrentLanguage(), {
  dateStyle: 'long',
});

export type PurchaseEmailDisplayProps = {
  vendorId?: number | null;
  subject?: ReactNode;
  toEmailAddress?: ReactNode;
  fromEmailAddress?: ReactNode;
  emailDate?: Date;
  orderId?: ReactNode;
  order?: ReactNode;
  orderDate?: Date;
  faceValue?: ReactNode;
};

export function PurchaseEmailDisplay({
  vendorId,
  subject,
  toEmailAddress,
  fromEmailAddress,
  emailDate,
  ...purchaseOrderDetails
}: PurchaseEmailDisplayProps) {
  const { orderId, order, orderDate, faceValue } = purchaseOrderDetails;
  return (
    <div>
      <div className={styles.row}>
        {vendorId && <MerchantColorBadge vendorId={vendorId} />}
        <div className={styles.emailDate}>
          {emailDate && dateFormatter.format(emailDate)}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.subject}>{subject}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.emailAddresses}>
          {fromEmailAddress && (
            <span>
              <FormatContent
                id={FormatContentId.EmailAddressFrom}
                params={<>{fromEmailAddress}</>}
              />
            </span>
          )}
          {fromEmailAddress && toEmailAddress && <br />}
          {toEmailAddress && (
            <span>
              <FormatContent
                id={FormatContentId.EmailAddressTo}
                params={<>{toEmailAddress}</>}
              />
            </span>
          )}
        </div>
      </div>
      {Object.values(purchaseOrderDetails).some((val) => val != null) && (
        <div className={styles.detailsSection}>
          {orderId && (
            <PurchaseOrderDetail
              label={<Content id={ContentId.ExternalPurchaseId} />}
            >
              {orderId}
            </PurchaseOrderDetail>
          )}
          {order && (
            <PurchaseOrderDetail label={<Content id={ContentId.Tickets} />}>
              {order}
            </PurchaseOrderDetail>
          )}
          {orderDate && (
            <PurchaseOrderDetail
              label={<Content id={ContentId.PurchaseDate} />}
            >
              {dateFormatter.format(orderDate)}
            </PurchaseOrderDetail>
          )}
          {faceValue && (
            <PurchaseOrderDetail label={<Content id={ContentId.FaceValue} />}>
              {faceValue}
            </PurchaseOrderDetail>
          )}
        </div>
      )}
    </div>
  );
}

function PurchaseOrderDetail({
  label,
  children,
}: {
  label: ReactNode;
  children: ReactNode;
}) {
  return (
    <div className={styles.detailsItem}>
      <div className={shared.typography.body2}>{label}</div>
      <div className={shared.typography.subtitle1}>{children}</div>
    </div>
  );
}
