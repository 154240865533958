import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import {
  ACTIVE_EVENT_IDS_2_QUERY_PARAM,
  ACTIVE_EVENT_IDS_QUERY_PARAM,
  TAB_EVENT_IDS_2_QUERY_PARAM,
  TAB_EVENT_IDS_QUERY_PARAM,
} from 'src/navigations/Routes/InventoryEvent/InventoryEvent.constants';
import { MainRoute } from 'src/navigations/Routes/MainRoute';
import { BackIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { CoBrandId } from 'src/WebApiController';

import * as styles from './BackSection.css';

export const BackSection = ({
  returnUrl,
  state,
}: {
  returnUrl: string;
  state?: unknown;
}) => {
  const navigate = useNavigate();
  const { cobrandId, mainRoute } = useAppContext();

  const [searchParams] = useSearchParams();

  const activeEventIds1 = searchParams.get(ACTIVE_EVENT_IDS_QUERY_PARAM);
  const tabEventIds1 = searchParams.get(TAB_EVENT_IDS_QUERY_PARAM);
  const activeEventIds2 = searchParams.get(ACTIVE_EVENT_IDS_2_QUERY_PARAM);
  const tabEventIds2 = searchParams.get(TAB_EVENT_IDS_2_QUERY_PARAM);

  const isCatchupMode = useMemo(
    () =>
      mainRoute === MainRoute.Inventory &&
      ((activeEventIds1 && tabEventIds1) || (activeEventIds2 && tabEventIds2)),
    [mainRoute, activeEventIds1, tabEventIds1, activeEventIds2, tabEventIds2]
  );

  return (
    <div className={styles.backSection}>
      <Button
        variant="textPlain"
        shape="none"
        textColor={cobrandId === CoBrandId.TicketUtil ? 'inverted' : 'strong'}
        onClick={() => {
          navigate(returnUrl, { state });
        }}
      >
        <BackIcon
          size={vars.iconSize.s}
          fill={
            cobrandId === CoBrandId.TicketUtil
              ? IconsFill.textInverted
              : IconsFill.currentColor
          }
        />
        <span>
          <Content id={isCatchupMode ? ContentId.Exit : ContentId.GoBack} />
        </span>
      </Button>
    </div>
  );
};
