﻿import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';

import { GeneralAdmissionMessage } from './GeneralAdmissionMessage';
import { rawFetch } from './shared/utils/rawFetch';
import VenueMapContent, { VenueMapContentProps } from './VenueMapContent';

const domParser = new DOMParser();
const placeholder = `<svg width="1" height="1"><circle cx="50" cy="50" r="40" stroke="transparent" stroke-width="4" fill="transparent" /></svg>`;

export const VenueMap = ({
  isGeneralAdmissionOnly,
  svgMapUrl,
  ...rest
}: Omit<VenueMapContentProps, 'svg'> & {
  isGeneralAdmissionOnly?: boolean;
}) => {
  if (!svgMapUrl) {
    throw new Error('svgMapUrl not available');
  }

  const mapQuery = useQuery({
    queryKey: [svgMapUrl],
    queryFn: () =>
      rawFetch(svgMapUrl, {}).then((text: string) => {
        const s = domParser.parseFromString(text, 'image/svg+xml');
        let isNewMap = false;
        if (s.querySelectorAll('path[eid]').length) isNewMap = true;

        if (text) {
          s
            .querySelectorAll('path[obs]')
            ?.forEach((el) => el.setAttribute('style', 'display: none'));
          s
            .querySelectorAll('path[eid]')
            ?.forEach((el) => el.setAttribute('style', 'display: block'));
          const svgElement = s.children[0];
          svgElement.setAttribute(
            'width',
            ref?.current?.clientWidth?.toString() ?? '0'
          );
          svgElement.setAttribute(
            'height',
            ref?.current?.clientHeight?.toString() ?? '0'
          );
          const serializer = new XMLSerializer();
          return {
            svg: serializer.serializeToString(svgElement),
            isNewMap,
          };
        }

        throw Error('Failed to get venue map : ' + svgMapUrl);
      }),

    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const ref = useRef<HTMLDivElement>(null);

  const { svg } = mapQuery.data ?? { svg: placeholder, isNewMap: false };

  if (isGeneralAdmissionOnly) {
    return <GeneralAdmissionMessage />;
  }

  if (!svg || mapQuery.isLoading) {
    return <div style={{ width: '100%', height: '100%' }} ref={ref}></div>;
  }

  return (
    <VenueMapContent ref={ref} svg={svg} svgMapUrl={svgMapUrl} {...rest} />
  );
};
