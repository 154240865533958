export enum EventsTableColumnId {
  Checkbox = 'checkbox',
  Event = 'event',
  Venue = 'venue',
  Location = 'location',
  Date = 'date',
  TotalQty = 'totalQty',
  SoldQty = 'soldQty',
  ActualSTR = 'actualSTR',
  Revenue = 'revenue',
  SoldCost = 'soldCost',
  Profit = 'profit',
  LiftPercentage = 'liftpercentage',
  ATP = 'atp',
  ListedQty = 'listedQty',
  ListedAtp = 'listedAtp',
  UnsoldQty = 'unsoldQty',
  Action = 'action',
  UnsoldProceeds = 'unsoldProceeds',
  UnsoldCost = 'unsoldCost',
  TotalCost = 'totalCost',
  LastPriceUpdatedDate = 'lastPriceUpdatedDate',
}
