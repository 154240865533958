import { ColumnDef } from '@tanstack/react-table';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { ReportConfig } from 'src/hooks/useReportConfigs';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import {
  filterColumnsByFeatures,
  getSaleReportColumnConfigById,
} from 'src/utils/columns/columnUtils';
import { filterCustomColumnsForSale } from 'src/utils/columns/sales/salesCustomColumnUtils';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { SaleReportMetricsWithGroupBy } from 'src/utils/reportsUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { hasFeatures } from 'src/utils/userUtils';
import {
  ActionOutboxEntityType,
  ReportGroupBy,
  UserSetting,
} from 'src/WebApiController';

import { SalesReportTableColumnId } from '../../utils/columns/sales/salesColumnUtils.types';
import {
  arTimeBucketColumnDef,
  customColumnDef,
  eventTagColumnDef,
  SALE_REPORT_TABLE_COLUMNS_CONFIG,
  tagColumnDef,
} from './configs/SaleReportTableColumnsConfig';

type SaleReportTableColumnDefContextType = {
  displayedColumnsConfig:
    | ColumnDef<SaleReportMetricsWithGroupBy | null>[]
    | undefined;
  isLoading?: boolean;
};

const SaleReportTableColumnDefContext =
  createContext<SaleReportTableColumnDefContextType>({
    displayedColumnsConfig: undefined,
  });

export const SaleReportTableColumnDefContextProvider = ({
  report,
  children,
}: PropsWithChildren<{
  report: ReportConfig;
}>) => {
  const { appContext, loginContext } = useAppContext();
  const { tagsMetadataNumeric, isLoading: saleTagIsLoading } =
    useTagsForEntityType(ActionOutboxEntityType.Sale, true);

  const { tagsMetadata: tagsMetadataEvent, isLoading: eventTagIsLoading } =
    useTagsForEntityType(ActionOutboxEntityType.SellerEvent, true);

  const { value: customSaleColumns = [], isLoading: customColumnIsLoading } =
    useServerUserSetting<CustomSalesColumn[]>({
      id: UserSetting.SaleCustomColumns,
    });

  const customSaleColumnsFiltered = filterCustomColumnsForSale(
    customSaleColumns,
    tagsMetadataNumeric
  );

  const displayedColumnsConfig = useMemo(() => {
    const columns = new Set([
      // Add unconfigurable columns first
      ...Object.values(SALE_REPORT_TABLE_COLUMNS_CONFIG)
        .filter((column) => {
          const columnDef = getSaleReportColumnConfigById(
            column.id as SalesReportTableColumnId
          ).personalization;
          const hasFeatureForColumn =
            columnDef.requiredFeatures.length === 0 ||
            hasFeatures(
              loginContext?.user,
              appContext?.features,
              columnDef.requiredFeatures
            );
          return !columnDef.isConfigurable && hasFeatureForColumn;
        })
        .map((item) => item.id as SalesReportTableColumnId),
      // Add user defined columns next
      ...filterColumnsByFeatures(
        report.metrics,
        SectionType.SalesReport,
        customSaleColumnsFiltered,
        loginContext?.user,
        appContext?.features,
        tagsMetadataNumeric,
        report.groupBy
      ),
    ]);

    const displayedColumns = Array.from(columns).reduce<
      ColumnDef<SaleReportMetricsWithGroupBy | null>[]
    >((acc, columnId) => {
      if (tagsMetadataNumeric?.find((t) => t.key === columnId)) {
        acc.push(tagColumnDef(columnId));
      } else if (tagsMetadataEvent?.find((t) => t.key === columnId)) {
        acc.push(eventTagColumnDef(columnId));
      } else if (customSaleColumnsFiltered.find((c) => c.id === columnId)) {
        acc.push(
          customColumnDef(
            columnId,
            customSaleColumnsFiltered.find((c) => c.id === columnId)?.formula
          )
        );
      } else {
        const columnConfig = Object.values(
          SALE_REPORT_TABLE_COLUMNS_CONFIG
        ).find((column) => column.id === columnId);
        if (columnConfig)
          acc.push(
            columnConfig as ColumnDef<SaleReportMetricsWithGroupBy | null>
          );
      }

      return acc;
    }, []);

    // Add time bucket columns for AR Aging reports
    if (report.groupBy === ReportGroupBy.ArAgingMarketplace) {
      report.arAgingTimeBuckets?.forEach((bucket) => {
        displayedColumns.push(arTimeBucketColumnDef(bucket));
      });
    }

    return displayedColumns;
  }, [
    appContext?.features,
    customSaleColumnsFiltered,
    loginContext?.user,
    report.arAgingTimeBuckets,
    report.groupBy,
    report.metrics,
    tagsMetadataEvent,
    tagsMetadataNumeric,
  ]);

  const isLoading =
    saleTagIsLoading || eventTagIsLoading || customColumnIsLoading;

  return (
    <SaleReportTableColumnDefContext.Provider
      value={{
        displayedColumnsConfig: isLoading ? undefined : displayedColumnsConfig,
        isLoading,
      }}
    >
      {children}
    </SaleReportTableColumnDefContext.Provider>
  );
};

export const useSaleReportTableColumnDef = () =>
  useContext(SaleReportTableColumnDefContext);
