import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { CrossIcon, IconsFill, SearchSolidIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { PosTextField, PosTextFieldProps } from '../PosTextField';

export type PosSearchBoxProps = PosTextFieldProps & {
  onSearchChange?: (value: string) => void;
  onEnterKeyPressed?: (curValue: string) => void;
  minWidth?: number | string;
  maxWidth?: number | string;
  focusOnMount?: boolean;
};

export function PosSearchBox({
  value,
  placeholder,
  onChange,
  onSearchChange,
  onEnterKeyPressed,
  onKeyDown,
  onBlur,
  minWidth,
  maxWidth,
  disabled,
  postfixDisplay,
  focusOnMount,
  ...textFieldProps
}: PosSearchBoxProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [inputText, setInputText] = useState<string>(value?.toString() ?? '');

  const onInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    if (!disabled) {
      onChange?.(e);
      onSearchTextChanged(e.target.value);
    }
  };

  const onSearchTextChanged = (value: string) => {
    if (!disabled) {
      onSearchChange?.(value);
      setInputText(value);
    }
  };

  const onKeyDownHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!disabled) {
      const key = e.key;
      if (key === 'Enter') {
        e.stopPropagation();

        // TODO - figuring out how to clear the input when Enter key is handled
        onEnterKeyPressed?.(e.currentTarget.value);
      } else if (key === 'Escape') {
        e.stopPropagation();
        onSearchTextChanged('');
      }

      onKeyDown?.(e);
    }
  };

  useEffect(() => {
    if (value !== undefined && value !== inputText) {
      setInputText(value?.toString());
    }
  }, [inputText, value]);

  const onBlurHandler = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (inputText?.startsWith(' ') || inputText?.endsWith(' ')) {
        setInputText(inputText.trim());
      }
      onBlur?.(e);
    },
    [inputText, onBlur]
  );

  const hasText = Boolean(inputText && inputText.length > 0);
  const searchPlaceholder = useContent(ContentId.Search);

  useEffect(() => {
    if (focusOnMount && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusOnMount]);

  return (
    <PosTextField
      ref={inputRef}
      rootProps={{
        style: {
          minWidth,
          maxWidth,
        },
        variant: 'regular',
        shape: 'pill',
      }}
      value={inputText || ''}
      placeholder={placeholder || searchPlaceholder}
      disabled={disabled}
      onChange={onInputChanged}
      onKeyDown={onKeyDownHandler}
      onBlur={onBlurHandler}
      prefixDisplay={
        <SearchSolidIcon
          size={vars.iconSize.l}
          fill={IconsFill.textPrimary}
          withHoverEffect
        />
      }
      postfixDisplay={
        <>
          {hasText && (
            <CrossIcon
              withHoverEffect
              align="middle"
              size={vars.iconSize.m}
              onClick={() => onSearchTextChanged('')}
            />
          )}
          {postfixDisplay && postfixDisplay}
        </>
      }
      {...textFieldProps}
    />
  );
}
