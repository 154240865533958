import { vars } from 'src/core/themes';

import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const CopyIcon = ({ size, fill, stroke, ...props }: PosIconProps) => {
  const s = size ?? vars.iconSize.l;

  return (
    <SvgWrapper aria-label="CopyIcon" size={s} {...props} viewBox={`0 0 20 20`}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6495 9.82965V5.84613H5.65137V17H15V9.82965H10.6495ZM15.9227 8.82965C15.9734 8.98973 16 9.15819 16 9.32965V18H4.65137V4.84613H11.1495C11.3207 4.84613 11.4894 4.87364 11.6495 4.9264C11.8522 4.99323 12.0411 5.10054 12.204 5.24374L15.4374 8.08595C15.6662 8.28714 15.8326 8.54514 15.9227 8.82965ZM11.6495 6.08769L14.7688 8.82965H11.6495V6.08769Z"
        fill={fill ?? stroke}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 2.5H11.9324V3.5H3.5V16.6868H2.5V2.5Z"
        fill={fill ?? stroke}
      />
    </SvgWrapper>
  );
};
