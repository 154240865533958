import { UseFormSetError } from 'react-hook-form';
import { IErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { URL_REGEX } from 'src/utils/constants/constants';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { isUniqueWithin } from 'src/utils/validation';
import { PosClientConfig, SaleClient, TicketUrl } from 'src/WebApiController';

export type TicketUrlForm = {
  isBulkUpload: boolean;
  ticketUrls: TicketUrl[];
};

export const checkDuplicateTicketUrls = async (
  entityId: number,
  ticketUrls: TicketUrl[],
  activeAccountWebClientConfig: PosClientConfig,
  alreadyReportedUsedTicketUrls: string[],
  setAlreadyReportedUsedTicketUrls: (ticketUrls: string[]) => void,
  onDuplicateTicketUrl: (index: number) => void,
  onBeforeCheck?: () => void,
  onAfterCheck?: () => void,
  showErrorDialog?: IErrorBoundaryContext['showErrorDialog'],
  viagVirtualId?: string
): Promise<boolean | null> => {
  return tryInvokeApi(
    async () => {
      onBeforeCheck?.();

      if (!ticketUrls) {
        return false;
      }

      const existingTicketUrls = await new SaleClient(
        activeAccountWebClientConfig
      ).getTicketUrlsAlreadyInUse(viagVirtualId, ticketUrls);

      if (existingTicketUrls) {
        // Get all the used ticketUrls that have not yet been reported
        const unreportedBarCodes = existingTicketUrls.filter(
          (b) => !alreadyReportedUsedTicketUrls.includes(b.url)
        );
        if (unreportedBarCodes.length > 0) {
          // We're reporting the dup warnings, so save it so we don't report on it again
          // We want to set all of it (not just the latest unreported ticketUrls)
          setAlreadyReportedUsedTicketUrls(
            existingTicketUrls.map((tb) => tb.url)
          );

          let hasErrors = false;
          existingTicketUrls.forEach((tb) => {
            const index = ticketUrls.findIndex(
              (b) => b.ticketId === tb.ticketId
            );

            if (index >= 0) {
              hasErrors = true;
              onDuplicateTicketUrl(index);
            }
          });

          return hasErrors;
        }
      }

      return false;
    },
    (error) => {
      showErrorDialog?.('SaleClient.getTicketUrlsAlreadyInUse', error, {
        trackErrorData: {
          saleId: entityId,
          ticketUrls,
        },
      });
    },
    () => onAfterCheck?.()
  );
};

export const checkTicketUrlErrors = (
  isBulkUpload: boolean,
  ticketUrls: TicketUrl[],
  ticketCnt: number,
  setError: UseFormSetError<TicketUrlForm>,
  requiredMsg: string,
  missingMsg: string,
  extraMsg: string,
  duplicateMsg: string,
  invalidUrlMsg: string
) => {
  let hasErrors = false;
  const ticketUrlsProvided =
    ticketUrls?.map((b) => b.url).filter((b) => b?.length) ?? [];
  if (isBulkUpload) {
    const ticketUrlsProvided =
      ticketUrls?.map((b) => b.url).filter((b) => b?.length) ?? [];
    if (ticketUrlsProvided.length < ticketCnt) {
      hasErrors = true;
      setError(`ticketUrls`, { message: missingMsg as string }); // for the bulk-edit inputs
    } else if (ticketUrlsProvided.length > ticketCnt) {
      hasErrors = true;
      setError(`ticketUrls`, { message: extraMsg as string }); // for the bulk-edit inputs
    } else if (ticketUrlsProvided.some((b) => !b.match(URL_REGEX))) {
      hasErrors = true;
      setError(`ticketUrls`, { message: invalidUrlMsg }); // for the bulk-edit inputs
    }
  }

  ticketUrls?.forEach((b, i) => {
    if (!b.url) {
      hasErrors = true;
      setError(`ticketUrls.${i}.url`, { message: requiredMsg });
    } else if (!b.url.match(URL_REGEX)) {
      setError(`ticketUrls.${i}.url`, { message: invalidUrlMsg });
    } else if (!isUniqueWithin(b.url, ticketUrlsProvided, true)) {
      hasErrors = true;
      if (isBulkUpload) {
        setError(`ticketUrls`, { message: duplicateMsg }); // for the bulk-edit inputs
      }
      setError(`ticketUrls.${i}.url`, { message: duplicateMsg });
    }
  });
  return hasErrors;
};
