import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { IconsFill, SuccessOutlineIcon } from 'src/svgs/Viagogo';

import * as styles from './MessageWithIcon.css';

export const SuccessMessage = ({
  message,
}: {
  message: string | JSX.Element;
}) => {
  return (
    <Stack direction="row" gap="m" className={styles.messageContainer}>
      <SuccessOutlineIcon fill={IconsFill.textSuccess} size={vars.iconSize.l} />
      <div className={styles.messageDiv}>{message}</div>
    </Stack>
  );
};
