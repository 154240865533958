import { FormatContent } from 'src/contexts/ContentContext';
import { NO_GROUP_ID } from 'src/contexts/MultiSelectionContext';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Stack } from 'src/core/ui';
import { FormatOption } from 'src/modals/EditTableColumns';
import { PurchaseOrderTable } from 'src/tables/PurchaseTable';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  BulkEditPreviewWithDetails,
  PurchaseViewMode,
} from 'src/WebApiController';

export const BulkEditPreviewTicketGroupDetails = ({
  preview,
}: {
  preview?: BulkEditPreviewWithDetails | null;
}) => {
  if (!preview?.ticketGroups) {
    return null;
  }

  return (
    <Stack
      direction="column"
      style={{ height: '50vh' }}
      gap="l"
      alignItems="center"
    >
      {preview.ticketGroups.length <
        (preview?.preview?.bulkEditCountPreview?.TicketGroup?.totalCount ??
          0) && (
        <WarningMessage
          message={
            <FormatContent
              id={FormatContentId.BulkEditMaxItemPreviewDisplayMessage}
              params={[
                `${preview.ticketGroups.length}`,
                `${preview?.preview?.bulkEditCountPreview?.TicketGroup?.totalCount}`,
              ]}
            />
          }
        />
      )}

      <PurchaseOrderTable
        groupId={NO_GROUP_ID}
        useVirtuoso
        disablePagination
        disableSelection
        purchaseCount={preview.ticketGroups.length}
        purchaseOrders={preview.ticketGroups}
        getDataFail={false}
        isItemsLoading={false}
        viewMode={PurchaseViewMode.EventTileView}
        columnVisibilityOverride={[
          PurchasesTableColumnId.VendorOrderId,
          PurchasesTableColumnId.Event,
          PurchasesTableColumnId.EventDate,
          PurchasesTableColumnId.Seating,
          PurchasesTableColumnId.Actions,
        ]}
        formatOptionOverrides={{
          [PurchasesTableColumnId.VendorOrderId]: FormatOption.Id_RawIdOnly,
          [PurchasesTableColumnId.Seating]: FormatOption.Seating_FullSeating,
          [PurchasesTableColumnId.Event]: FormatOption.Event_EventName,
          [PurchasesTableColumnId.EventDate]:
            FormatOption.DateTime_Month_DD_YY_Time,
          [PurchasesTableColumnId.Actions]: FormatOption.Actions_LinkOnly,
        }}
        withOuterPadding={false}
      />
    </Stack>
  );
};
