import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function SeatIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M20.333 9.5V12H7V6.167c0-1.375 1.125-2.5 2.5-2.5h5c1.375 0 2.5 1.125 2.5 2.5v5l1.667-.834V6.167A4.179 4.179 0 0014.5 2h-5a4.179 4.179 0 00-4.167 4.167V12H3.667V9.5H2v5.833c0 1.084.708 2 1.667 2.334V22h1.666v-4.167h13.334V22h1.666v-4.333A2.482 2.482 0 0022 15.333V9.5h-1.667zm0 5.833a.836.836 0 01-.833.834h-15a.836.836 0 01-.833-.834v-1.666h16.666v1.666z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
