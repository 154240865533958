import { LaunchActivityLog } from 'src/modals/ActivityLog';
import { SaleDeeplinkQueryParam } from 'src/utils/constants/constants';
import { getSaleDetailsModalConfigWithDeepLink } from 'src/utils/saleUtils';

import { ShareButton } from '../ShareButton/ShareButton';
import * as styles from './EventEntityHeader.css';
import { EntityIdNumberDiv, EntityInfoDiv } from './EventEntityHeader.styled';

export const SaleInfoDisplay = ({
  marketplace,
  entityId,
  idOnMkp,
}: {
  marketplace?: string | null;
  idOnMkp: string | null;
  entityId: number;
}) => {
  return (
    <EntityInfoDiv>
      <div className={styles.entityInfoShareButtonIdNumberWrapper}>
        <ShareButton deepLinkQueryParam={SaleDeeplinkQueryParam} />
        <EntityIdNumberDiv>#{idOnMkp || entityId}</EntityIdNumberDiv>
      </div>
      <div className={styles.entityText}>{marketplace}</div>
      <LaunchActivityLog
        entityId={entityId}
        cancelTo={getSaleDetailsModalConfigWithDeepLink(entityId)}
      />
    </EntityInfoDiv>
  );
};
