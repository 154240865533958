import { CatalogEntity } from 'src/WebApiController';

import { PerformerVenueDisplay } from '../PerformerVenueDisplay';
import { SelectedPerformerVenueListContainer } from './SelectedPerformerVenueList.styled';

export const SelectedPerformerVenueList = ({
  selectedPerformerVenues,
  isOpen,
  onPerformerVenueClicked,
  metricsData,
  disabled,
}: {
  selectedPerformerVenues: CatalogEntity[];
  isOpen: boolean;
  onPerformerVenueClicked: (g: CatalogEntity) => void;
  metricsData: Record<string, object> | undefined;
  disabled?: boolean;
}) => {
  return (
    <SelectedPerformerVenueListContainer isOpen={isOpen}>
      {selectedPerformerVenues.map((mg) => (
        <PerformerVenueDisplay
          key={mg.viagId}
          grouping={mg}
          isSelected={true}
          onPerformerVenueClicked={onPerformerVenueClicked}
          metricsData={metricsData}
          disabled={disabled}
        />
      ))}
    </SelectedPerformerVenueListContainer>
  );
};
