import { ComponentProps } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export const OkButton = ({
  textContentId = ContentId.OK,
  variant = 'regular',
  ...rest
}: { textContentId?: ContentId } & Omit<
  ComponentProps<typeof Button>,
  'children'
>) => {
  return (
    <Button variant={variant} {...rest}>
      <Content id={textContentId} />
    </Button>
  );
};
