import { Content, useContent } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Card, Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { LaunchProofOfPurchaseUploadDilaog } from 'src/dialogs/ProofOfPurchaseUploadDialog';
import { FieldError } from 'src/modals/common';
import { CheckIcon, IconsFill, ProcessingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { PENDING_PROOF_STATE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { ListingDetails, PendingProofState } from 'src/WebApiController';

import * as styles from './ProofOfPurchaseSection.css';

export const ProofOfPurchaseSection = ({
  listing,
}: {
  listing: ListingDetails;
}) => {
  const pendingProofText = useContent(
    PENDING_PROOF_STATE_TO_CID[
      listing.pendProofState ?? PendingProofState.Approved
    ]
  );
  if (!listing.pendProofState) return null;

  return (
    <Card style={{ padding: vars.spacing['lg'] }}>
      <Stack gap="l" alignItems="center" justifyContent="spaceBetween">
        {listing.pendProofState === PendingProofState.PendingProof ? (
          <Stack direction="column">
            <span className={styles.errorLabel}>{pendingProofText}</span>
            <FieldError>
              <Content id={ContentId.ProofOfPurchasedRequiredMessage} />
            </FieldError>
          </Stack>
        ) : (
          <Stack
            gap="m"
            alignItems="center"
            className={
              listing.pendProofState === PendingProofState.PendingReview
                ? styles.warningLabel
                : undefined
            }
          >
            <Content id={ContentId.ProofOfPurchase} />
            {listing.pendProofState === PendingProofState.PendingReview ? (
              <RotatingWrapper title={pendingProofText}>
                <ProcessingIcon fill={IconsFill.textWarning} />
              </RotatingWrapper>
            ) : (
              <CheckIcon fill={IconsFill.textSuccess} />
            )}
          </Stack>
        )}
        <LaunchProofOfPurchaseUploadDilaog
          proofDocs={listing.proofOfPurchaseDocs}
        />
      </Stack>
    </Card>
  );
};
