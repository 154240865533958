import { isEqual } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { OkButton } from 'src/components/Buttons/OkButton';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  EventMapContextProvider,
  useEventMapContext,
} from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { ApplyHeatMapToAllEventsDialog } from 'src/dialogs/ApplyHeatMapToAllEventsDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import {
  EventPricingSeatMapForm,
  validateAutoPricingSettings,
} from 'src/utils/eventWithDataUtils';
import { getCompleteEventConfigScoreOverrides } from 'src/utils/seatScoreUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  Event,
  EventConfigScoreOverride,
  Feature,
  ListingClient,
  PricingClient,
} from 'src/WebApiController';

import { CancellableFormFooter } from '../common';
import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from '../common/EventEntityHeader';
import { modalDetails } from '../common/Modals.css';
import { ModalBody, ModalFooter } from '../Modal';
import { ModalBodyDataContainer } from '../Modal/Modal.styled';
import { EventPricingSeatMapBody } from './EventPricingSeatMapBody';

type EventPricingSeatMapProps = {
  event: Event;
};

export const EventPricingSeatMap = ({ event }: EventPricingSeatMapProps) => {
  return (
    <EventMapContextProvider event={event}>
      <EventPricingSeatMapFormBody event={event} />
    </EventMapContextProvider>
  );
};

export const EventPricingSeatMapFormBody = ({
  event,
}: EventPricingSeatMapProps) => {
  const { activeConfigOverride, mapConfigOverridesQuery, isMapLoading } =
    useEventMapContext();

  const { pricingSettings, allListingEnabledPricing, pricingSettingsQuery } =
    useGetEventAutoPricingSettings(event, true);

  const methods = useForm<EventPricingSeatMapForm>({
    defaultValues: {
      eventAutoPricing: pricingSettings,
      eventWideAutoPricing: allListingEnabledPricing,
      eventScoreOverride: activeConfigOverride,
    },
  });

  useEffect(() => {
    if (
      (activeConfigOverride &&
        !isEqual(
          methods.formState.defaultValues?.eventScoreOverride,
          activeConfigOverride
        )) ||
      (pricingSettings &&
        !isEqual(
          methods.formState.defaultValues?.eventAutoPricing,
          pricingSettings
        )) ||
      (allListingEnabledPricing != null &&
        methods.formState.defaultValues?.eventWideAutoPricing !==
          allListingEnabledPricing)
    ) {
      methods.reset({
        eventAutoPricing: pricingSettings,
        eventWideAutoPricing: allListingEnabledPricing,
        eventScoreOverride: activeConfigOverride,
      });
    }
  }, [
    activeConfigOverride,
    allListingEnabledPricing,
    methods,
    pricingSettings,
  ]);

  if (
    (!activeConfigOverride &&
      !pricingSettings &&
      mapConfigOverridesQuery.isLoading) ||
    isMapLoading ||
    pricingSettingsQuery.isLoading
  ) {
    return <PosSpinner />;
  }

  return (
    <FormProvider {...methods}>
      <EventPricingSeatMapContent
        {...methods}
        refreshAutoPricingSettings={() => pricingSettingsQuery.refetch()}
      />
    </FormProvider>
  );
};

const EventPricingSeatMapContent = ({
  watch,
  formState,
  handleSubmit,
  clearErrors,
  setError,
  setValue,
  refreshAutoPricingSettings,
}: Omit<
  ComponentProps<typeof FormProvider<EventPricingSeatMapForm, unknown>>,
  'children'
> & { refreshAutoPricingSettings: () => void }) => {
  const { closeModal } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog } = useErrorBoundaryContext();
  const { event, activeConfigOverride, venueMapInfo, mapConfigOverridesQuery } =
    useEventMapContext();
  const { setActivePosEntity } = useActivePosEntityContext();
  const hasScaledSeatScoreFeature = useUserHasFeature(Feature.ScaledSeatScore);

  const curFormValues = watch();

  const compListingFloorCeilingError = useContent(
    ContentId.CompListingFloorMustLessThanCeiling
  );
  const requiredMsg = useContent(ContentId.Required);

  const [applyToAllEventsForConfig, setApplyToAllEventsForConfig] = useState<
    EventConfigScoreOverride | undefined
  >();
  const { launchDialog, closeDialog, dialogProps } = useBasicDialog();

  const onSubmit = useCallback(
    async (eventForm: EventPricingSeatMapForm) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const client = new PricingClient(activeAccountWebClientConfig);
          const listingClient = new ListingClient(activeAccountWebClientConfig);
          const promises: Promise<boolean | boolean[]>[] = [];

          if (
            eventForm.eventWideAutoPricing !==
            formState.defaultValues?.eventWideAutoPricing
          ) {
            promises.push(
              listingClient.updateAutoPricingEnabledForEvent(
                event!.posIds,
                !!eventForm.eventWideAutoPricing
              )
            );
          }

          if (
            event?.viagId &&
            eventForm.eventAutoPricing &&
            !isEqual(
              eventForm.eventAutoPricing,
              formState.defaultValues?.eventAutoPricing
            )
          ) {
            promises.push(
              client.mergeEventPricingSettings(
                event.viagId,
                eventForm.eventAutoPricing!
              )
            );
          }

          if (promises.length) {
            await Promise.all(promises);
            setActivePosEntity(0); // This will clear any active listing so it will pick up the latest pricing change here when re-open
            refreshAutoPricingSettings();
          }

          if (
            event?.viagId &&
            eventForm.eventScoreOverride &&
            !isEqual(
              eventForm.eventScoreOverride,
              formState.defaultValues?.eventScoreOverride
            )
          ) {
            const newId = await client.upsertEventSeatMapScoreOverride({
              ...eventForm.eventScoreOverride,
              viagogoEventId: event.viagId,
              scoreOverrides: getCompleteEventConfigScoreOverrides(
                venueMapInfo?.sectionScores,
                eventForm.eventScoreOverride.scoreOverrides,
                false,
                hasScaledSeatScoreFeature
              )!,
            });

            const oldId = eventForm.eventScoreOverride?.id;
            setValue('eventScoreOverride.id', newId);

            const result = await mapConfigOverridesQuery.refetch();
            const newConfig = result.data?.find((ev) => ev.id === newId);

            if (
              newId !== activeConfigOverride?.id &&
              oldId <= 0 &&
              (newConfig?.eventIds?.length ?? 0) >= 2
            ) {
              setApplyToAllEventsForConfig(newConfig);
              launchDialog();
              return;
            }

            await client.setEventSeatMapScoreOverride(
              event.viagId,
              newId,
              null
            );
          }

          closeModal(true);
        },
        (error) => {
          showErrorDialog('PricingClient.updates', error, {
            trackErrorData: eventForm,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      activeConfigOverride?.id,
      closeModal,
      event,
      formState.defaultValues?.eventAutoPricing,
      formState.defaultValues?.eventScoreOverride,
      formState.defaultValues?.eventWideAutoPricing,
      hasScaledSeatScoreFeature,
      launchDialog,
      mapConfigOverridesQuery,
      refreshAutoPricingSettings,
      setActivePosEntity,
      setValue,
      showErrorDialog,
      venueMapInfo?.sectionScores,
    ]
  );

  const hasChanges = useCallback(
    (eventForm: EventPricingSeatMapForm) => {
      // Make sure we only send back the settable props
      return (
        !isEqual(
          formState.defaultValues?.eventAutoPricing,
          eventForm.eventAutoPricing
        ) ||
        !isEqual(
          formState.defaultValues?.eventScoreOverride,
          eventForm.eventScoreOverride
        ) ||
        eventForm.eventWideAutoPricing !==
          formState.defaultValues?.eventWideAutoPricing
      );
    },
    [
      formState.defaultValues?.eventAutoPricing,
      formState.defaultValues?.eventScoreOverride,
      formState.defaultValues?.eventWideAutoPricing,
    ]
  );

  const onSubmitHandler = useCallback(() => {
    if (!hasChanges(curFormValues)) return;

    if (
      !validateAutoPricingSettings(
        clearErrors,
        setError,
        setValue,
        curFormValues,
        compListingFloorCeilingError,
        requiredMsg
      )
    ) {
      return;
    }

    handleSubmit(onSubmit)();
  }, [
    clearErrors,
    compListingFloorCeilingError,
    curFormValues,
    handleSubmit,
    hasChanges,
    onSubmit,
    requiredMsg,
    setError,
    setValue,
  ]);

  const formHasChanges = hasChanges(curFormValues);

  return (
    <>
      <CancellableFormHeader
        disabled={isLoading || formState.isSubmitting}
        showDialogOnCancel={formHasChanges}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.PricingDetails} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        <div className={modalDetails}>
          <ModalBodyDataContainer>
            <EventPricingSeatMapBody />
          </ModalBodyDataContainer>
        </div>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          disabled={isLoading}
          showDialogOnCancel={formHasChanges}
        >
          <OkButton
            onClick={onSubmitHandler}
            disabled={isLoading || !formHasChanges}
            textContentId={ContentId.Save}
          />
        </CancellableFormFooter>
      </ModalFooter>

      <ApplyHeatMapToAllEventsDialog
        {...dialogProps}
        event={event!}
        eventIds={applyToAllEventsForConfig?.eventIds}
        eventConfigScoreOverrideId={applyToAllEventsForConfig?.id}
        onClose={() => {
          setApplyToAllEventsForConfig(undefined);
          closeDialog();
          closeModal(true);
        }}
      />
    </>
  );
};
