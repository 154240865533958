import { differenceWith } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { useDealContext } from 'src/contexts/DealContext/DealContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import * as PosCollapsible from 'src/core/POS/PosCollapsible';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PurchaseOrderDealConfig,
  PurchaseOrderDetailsInput,
} from 'src/WebApiController';

import * as styles from '../Deals.css';
import { ChangeCommissionInput } from './DealConfigHurdle';
import { DealConfigHurdleSplit } from './DealConfigHurdleSplit';
import { createDefaultDealCommission } from './DealConfigHurdleSplitCommissions';

export const DealFallbackSection = ({
  dealFallbackConfig,
}: {
  dealFallbackConfig: PurchaseOrderDealConfig;
}) => {
  const { watch } = useFormContext<PurchaseOrderDetailsInput>();
  const { updateLocalDealDetails } = useDealContext();
  const { allActiveUserInfos } = useSellerAccountContext();
  const [open, setOpen] = useState(true);

  const { purchaserCommissions, vendorCommission } = dealFallbackConfig;
  const activeUserIds = useMemo(
    () =>
      allActiveUserInfos
        ? Object.values(allActiveUserInfos).map((u) => u.userId)
        : [],
    [allActiveUserInfos]
  );

  const vendorAccount = watch('vendorAccount');
  useEffect(() => {
    updateLocalDealDetails({
      dealFallbackConfig: {
        ...dealFallbackConfig,
        vendorCommission: {
          ...vendorCommission,
          userId: vendorAccount?.value?.id || '',
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorAccount]);

  const onChangeVendorCommission = useCallback((changedValue: number) => {
    updateLocalDealDetails({
      dealFallbackConfig: {
        ...dealFallbackConfig,
        vendorCommission: {
          ...vendorCommission,
          splitValue: changedValue,
        },
      },
    });
  }, []);

  const onAddNewCommission = useCallback(() => {
    const firstAvailableUserId = differenceWith(
      activeUserIds,
      purchaserCommissions ?? [],
      (activeUserId, commission) => activeUserId === commission.userId
    )[0];

    if (!firstAvailableUserId) return;
    updateLocalDealDetails({
      dealFallbackConfig: {
        ...dealFallbackConfig,
        purchaserCommissions: [
          ...purchaserCommissions,
          createDefaultDealCommission(firstAvailableUserId),
        ],
      },
    });
  }, [
    activeUserIds,
    dealFallbackConfig,
    purchaserCommissions,
    updateLocalDealDetails,
  ]);

  const onChangeCommission = useCallback(
    ({
      changedUserId,
      changedValue,
      commissionIndex,
    }: ChangeCommissionInput) => {
      if (changedUserId) {
        const isUserIdAlreadyUsed = purchaserCommissions?.find(
          (existingCommission) => existingCommission.userId === changedUserId
        );
        if (isUserIdAlreadyUsed) return;
        updateLocalDealDetails({
          dealFallbackConfig: {
            ...dealFallbackConfig,
            purchaserCommissions: [
              ...purchaserCommissions.map((commission, i) =>
                i === commissionIndex
                  ? { ...commission, userId: changedUserId }
                  : commission
              ),
            ],
          },
        });
      }

      if (changedValue !== null) {
        updateLocalDealDetails({
          dealFallbackConfig: {
            ...dealFallbackConfig,
            purchaserCommissions: [
              ...purchaserCommissions.map((commission, i) =>
                i === commissionIndex
                  ? { ...commission, splitValue: changedValue }
                  : commission
              ),
            ],
          },
        });
      }
    },
    [dealFallbackConfig, purchaserCommissions, updateLocalDealDetails]
  );

  const onDeleteCommission = useCallback(
    (removeIndex: number) => {
      if (dealFallbackConfig.purchaserCommissions.length <= 1) return;
      const newCommissions = [...dealFallbackConfig.purchaserCommissions];
      const updatedCommissions = newCommissions.filter(
        (_, i) => i !== removeIndex
      );

      updateLocalDealDetails({
        dealFallbackConfig: {
          ...dealFallbackConfig,
          purchaserCommissions: updatedCommissions,
        },
      });
    },
    [dealFallbackConfig, updateLocalDealDetails]
  );

  return (
    <PosCollapsible.Root open={open} onOpenChange={() => setOpen(!open)}>
      <div className={styles.DealConfigFallback}>
        <PosCollapsible.Trigger>
          <Stack
            direction="column"
            className={styles.DealConfigFallbackHeaderContainer}
          >
            <div className={styles.DealConfigFallbackTitle}>
              <Content id={ContentId.FallbackSplit} />
            </div>
            <div>
              <Content id={ContentId.FallbackSplitInfo} />
            </div>
          </Stack>
        </PosCollapsible.Trigger>
        <PosCollapsible.Content>
          <div className={styles.DealConfigFallbackContentHurdleContainer}>
            <DealConfigHurdleSplit
              index={0}
              config={dealFallbackConfig}
              vendorCommission={vendorCommission}
              onChangeVendorCommission={onChangeVendorCommission}
              onDeleteCommission={onDeleteCommission}
              onChangeCommission={onChangeCommission}
              onAddNewCommission={onAddNewCommission}
            />
          </div>
        </PosCollapsible.Content>
      </div>
    </PosCollapsible.Root>
  );
};
