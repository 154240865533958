import clsx from 'clsx';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Content, useContentContext } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { AddFileIcon } from 'src/svgs/AddFileIcon';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { FormatContentIds } from 'src/utils/constants/formatContentIdDataMap';

import * as styles from './DnDFileUploader.css';
import { DnDFileUploaderLabel, SVGWrapper } from './DnDFileUploader.styled';
import { SelectedFile } from './SelectedFile';

function preventDefaults(e: React.SyntheticEvent) {
  e.preventDefault();
  e.stopPropagation();
}

export interface DnDFileUploaderExternalContext {
  selectFiles: () => void; // Opens HTML file picker
  isDragging: boolean;
  handleDrag: (e: React.DragEvent) => void;
  handleDrop: (e: React.DragEvent) => void;
}

interface DnDFileUploaderProps {
  onChange?: (files: File[]) => void; // called with all selected files
  onNewFilesSelected?: (files: File[]) => void; // called only with new selected files
  acceptedFileTypes?: string[];
  multiple?: boolean;
  disabled?: boolean;
  maxFileSize?: number;
  showSelectedFiles?: boolean;
  useLabelV2Styles?: boolean;
  setExternalContext?: (context: DnDFileUploaderExternalContext) => void;
  highlightDropBoxOnDragging?: boolean;
}

export const DnDFileUploader = ({
  onChange,
  multiple,
  disabled,
  acceptedFileTypes = [],
  maxFileSize,
  showSelectedFiles = true,
  useLabelV2Styles = false,
  onNewFilesSelected,
  setExternalContext,
  highlightDropBoxOnDragging = true,
}: DnDFileUploaderProps) => {
  const { formattedContentResolver } = useContentContext();

  const isMobile = useMatchMedia('mobile');
  const inputRef = useRef<HTMLInputElement>(null);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const acceptProp =
    acceptedFileTypes?.length > 0
      ? {
          accept: acceptedFileTypes.join(', '),
        }
      : {};

  const callbacksRef = useRef<
    Pick<DnDFileUploaderProps, 'onChange' | 'onNewFilesSelected'>
  >({
    onChange,
    onNewFilesSelected,
  });

  callbacksRef.current = {
    onChange,
    onNewFilesSelected,
  };

  const maxFileSizeErrorMsg = useMemo(() => {
    if (maxFileSize == null) return null;

    return formattedContentResolver(
      FormatContentId.MaxAllowedFileSizeMb,
      [(maxFileSize / 1024 / 1024).toFixed(0)],
      FormatContentIds[FormatContentId.MaxAllowedFileSizeMb].defaultValue
    ) as string;
  }, [formattedContentResolver, maxFileSize]);

  useEffect(() => {
    callbacksRef.current.onChange?.(files);
  }, [files]);

  const onBtnClick = useCallback(() => {
    // trigger the hidden input
    inputRef?.current?.click();
  }, []);

  const handleDrag = useCallback(
    (e: React.DragEvent) => {
      preventDefaults(e);

      const { type } = e;

      if (type === 'dragenter' || type === 'dragover') {
        setIsDragging(true);
      } else if (type === 'dragleave') {
        setIsDragging(false);
      }
    },
    [setIsDragging]
  );

  const updateSelectedFiles = useCallback(
    (newFiles: FileList | null) => {
      if (newFiles?.length) {
        callbacksRef.current.onNewFilesSelected?.(Array.from(newFiles));
        if (multiple) {
          // If multiple, add on to already selected files
          setFiles((prevFiles) => [...prevFiles, ...Array.from(newFiles)]);
        } else {
          // Else, replace existing files with the new selected file
          setFiles([newFiles[0]]);
        }
      }
    },
    [multiple]
  );

  const handleDrop = useCallback(
    (e: React.DragEvent) => {
      preventDefaults(e);

      setIsDragging(false);
      const newFiles = e.dataTransfer?.files;

      if (maxFileSize != null) {
        const files = Array.from(newFiles || []);
        if (files.some((f) => f.size > maxFileSize)) {
          setErrorMsg(maxFileSizeErrorMsg);
          return;
        }
      }

      setErrorMsg(null);

      updateSelectedFiles(newFiles);
    },
    [maxFileSize, maxFileSizeErrorMsg, updateSelectedFiles]
  );

  const handleChange = (e: React.SyntheticEvent) => {
    preventDefaults(e);
    const target = e.target as HTMLInputElement;

    setIsDragging(false);

    const newFiles = target?.files;

    if (maxFileSize != null) {
      const files = Array.from(newFiles || []);
      if (files.some((f) => f.size > maxFileSize)) {
        setErrorMsg(maxFileSizeErrorMsg);
        return;
      }
    }

    setErrorMsg(null);
    updateSelectedFiles(newFiles);
  };

  const onRemoveFile = (index: number) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);

    inputRef!.current!.value = newFiles.map((f) => f.name).join(',');
  };

  // Set external context if needed
  useEffect(() => {
    setExternalContext?.({
      isDragging,
      handleDrag,
      handleDrop,
      selectFiles: () => {
        inputRef.current?.click();
      },
    });
  }, [handleDrag, handleDrop, isDragging, setExternalContext]);

  return (
    <div className={styles.dnDFileUploadWrapper}>
      <input
        className={styles.fileInput}
        disabled={disabled}
        ref={inputRef}
        type="file"
        id="dnd-file-uploader"
        multiple={multiple}
        onChange={handleChange}
        {...acceptProp}
      />
      <DnDFileUploaderLabel
        htmlFor="dnd-file-uploader"
        className={clsx(styles.uploaderLabel, {
          dragging: isDragging,
          [styles.interaction]: highlightDropBoxOnDragging && isDragging,
        })}
      >
        <SVGWrapper>
          <AddFileIcon size="40px" />
        </SVGWrapper>
        <div
          className={clsx(styles.labelContent, {
            [styles.labelContentV2]: useLabelV2Styles,
          })}
        >
          {!isMobile && (
            <>
              <div>
                <Content id={ContentId.DragNDropFiles} />
              </div>
              <div>
                <Content id={ContentId.Or} />
              </div>
            </>
          )}

          <Button
            disabled={disabled}
            variant={'text'}
            size="md"
            onClick={onBtnClick}
            className={
              useLabelV2Styles ? styles.uploadButtonV2 : styles.uploadButton
            }
          >
            <Content
              id={multiple ? ContentId.BrowseFiles : ContentId.BrowseFile}
            />
          </Button>
          <div
            className={styles.dnDTrigger}
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        </div>
      </DnDFileUploaderLabel>
      {showSelectedFiles &&
        files.map((file: File, i: number) => (
          <SelectedFile key={file.name} onRemove={() => onRemoveFile(i)}>
            {file.name}
          </SelectedFile>
        ))}
      {errorMsg ? (
        <span style={{ color: vars.color.textError }}>{errorMsg}</span>
      ) : null}
    </div>
  );
};
