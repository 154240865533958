import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDefaultZoomOption } from 'src/hooks/useDefaultZoomOption';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { UserSetting } from 'src/WebApiController';

export enum SiteZoomOption {
  '65%' = '65%',
  '75%' = '75%',
  '90%' = '90%',
  '100%' = '100%',
}

export const DEFAULT_ZOOM_OPTION = SiteZoomOption['75%'];

export enum SiteTheme {
  Light = 'light',
  Dark = 'dark',
}

export enum SiteThemeOption {
  Light = 'light',
  Dark = 'dark',
  System = 'system',
}

export const SITE_THEME_MODE_STORAGE_KEY = 'userSetting_siteThemeMode';
export const SITE_ZOOM_STORAGE_KEY = 'userSetting_siteZoom';

type SiteThemeContextType = {
  isDarkMode: boolean;
  theme: SiteTheme;
  setSiteMode: (mode: SiteThemeOption) => void;
  setSiteZoom: (zoom: SiteZoomOption) => void;
  selectedMode: SiteThemeOption;
  selectedZoom: SiteZoomOption;
  isZoomSupported: boolean;
  hasFeature?: boolean;
};

const siteThemeContextDefaults = {
  isDarkMode: false,
  theme: SiteTheme.Light,
  setSiteMode: () => void 0,
  setSiteZoom: () => void 0,
  selectedMode: SiteThemeOption.System,
  selectedZoom: DEFAULT_ZOOM_OPTION,
  isZoomSupported: false,
};

const SiteThemeContext = createContext<SiteThemeContextType>(
  siteThemeContextDefaults
);

type SiteThemeContextProviderType = {
  children: ReactNode;
};

export const SiteThemeContextProvider = ({
  children,
}: SiteThemeContextProviderType) => {
  const systemSetting =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
      ? SiteTheme.Dark
      : SiteTheme.Light;
  const modeToThemeMap = {
    [SiteThemeOption.System]: systemSetting,
    [SiteThemeOption.Dark]: SiteTheme.Dark,
    [SiteThemeOption.Light]: SiteTheme.Light,
  };

  const [isZoomSupported, setIsZoomSupported] = useState<boolean>(false);
  const defaultZoomOption = useDefaultZoomOption();

  const [selectedZoom, setSelectedZoom] =
    useState<SiteZoomOption>(defaultZoomOption);
  const [selectedMode, setSelectedMode] = useState<SiteThemeOption>(
    SiteThemeOption.System
  );
  const theme = modeToThemeMap[selectedMode];
  const { value: serverSiteThemeValue, setUserSetting: setThemeUserSetting } =
    useServerUserSetting<SiteThemeOption>({
      id: UserSetting.SiteTheme,
      currentLoginUserOnly: true,
    });
  const { value: serverSiteZoomValue, setUserSetting: setZoomUserSetting } =
    useServerUserSetting<SiteZoomOption>({
      id: UserSetting.SiteZoom,
      currentLoginUserOnly: true,
    });

  const setSiteMode = (mode: SiteThemeOption) => {
    setThemeUserSetting(mode);
    localStorage.setItem(SITE_THEME_MODE_STORAGE_KEY, mode);
    setSelectedMode(mode);
  };

  const applyZoom = (zoom: SiteZoomOption) => {
    // TODO - all the zoom stuff is to be removed
    // We no longer use document style zoom
    // (document.body.style as CSSStyleDeclaration & { zoom: string }).zoom = zoom;
  };

  const setSiteZoom = (zoom: SiteZoomOption) => {
    setZoomUserSetting(zoom);
    localStorage.setItem(SITE_ZOOM_STORAGE_KEY, zoom);
    setSelectedZoom(zoom);
    applyZoom(zoom);
  };

  useEffect(() => {
    const lsScheme = localStorage.getItem(SITE_THEME_MODE_STORAGE_KEY) as
      | SiteThemeOption
      | undefined;
    const persistedSiteThemeMode =
      serverSiteThemeValue ?? lsScheme ?? SiteThemeOption.System;
    setSelectedMode(persistedSiteThemeMode);
  }, [serverSiteThemeValue]);

  useEffect(() => {
    const lsZoom = localStorage.getItem(SITE_ZOOM_STORAGE_KEY) as
      | SiteZoomOption
      | undefined;
    const persistedSiteZoom =
      serverSiteZoomValue ?? lsZoom ?? defaultZoomOption;
    setSelectedZoom(persistedSiteZoom);
    applyZoom(persistedSiteZoom);
  }, [defaultZoomOption, serverSiteZoomValue]);

  return (
    <SiteThemeContext.Provider
      value={{
        isDarkMode: theme === SiteTheme.Dark,
        theme,
        isZoomSupported,
        setSiteMode,
        setSiteZoom,
        selectedMode,
        selectedZoom,
        hasFeature: true,
      }}
    >
      {children}
    </SiteThemeContext.Provider>
  );
};

export const useSiteTheme = () => useContext(SiteThemeContext);
