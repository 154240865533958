import { ColumnDef } from '@tanstack/react-table';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { ReportMetricsResponse } from 'src/contexts/ReportMetricsContext/ReportMetricsContextV2';
import { ReportConfigV2 } from 'src/hooks/useReportConfigsV2';
import { ListingReportTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';

import { listingReportV2TableColumnDef } from './configs/ListingReportTableColumnsConfigV2';

type ListingReportTableColumnDefContextType = {
  displayedColumnsConfig: ColumnDef<ReportMetricsResponse | null>[] | undefined;
};

const ListingReportTableColumnDefContext =
  createContext<ListingReportTableColumnDefContextType>({
    displayedColumnsConfig: undefined,
  });

export const ListingReportTableColumnDefContextProviderV2 = ({
  report,
  children,
}: PropsWithChildren<{
  report: ReportConfigV2;
}>) => {
  // const { appContext, loginContext } = useAppContext();
  // const { tagsMetadataNumeric, isLoading: listingTagIsLoading } =
  //   useTagsForEntityType(ActionOutboxEntityType.Listing, true);

  // const { tagsMetadata: tagsMetadataEvent, isLoading: eventTagIsLoading } =
  //   useTagsForEntityType(ActionOutboxEntityType.SellerEvent, true);

  // const {
  //   tagsMetadata: tagsMetadataPurchase,
  //   isLoading: purchaseTagIsLoading,
  // } = useTagsForEntityType(ActionOutboxEntityType.Purchase, true);

  // // const { value: customListingColumns = [], isLoading: customColumnIsLoading } =
  // //   useServerUserSetting<CustomListingColumn[]>({
  // //     id: UserSetting.InventoryCustomColumns,
  // //   });

  // // const customListingColumnsFiltered = filterCustomColumnsForListingReport(
  // //   customListingColumns,
  // //   tagsMetadataNumeric
  // // );

  const displayedColumnsConfig = useMemo(() => {
    const columns = [
      ...(report.request.groupings?.map((c) => c.columnName) ?? []),
      ...(report.request.aggregations?.map((c) => c.columnName) ?? []),
    ];

    return columns.map((columnId) =>
      listingReportV2TableColumnDef(columnId as ListingReportTableColumnId)
    );
  }, [report.request.aggregations, report.request.groupings]);

  const isLoading = false;
  // listingTagIsLoading ||
  // eventTagIsLoading ||
  // purchaseTagIsLoading ||
  // customColumnIsLoading;

  return (
    <ListingReportTableColumnDefContext.Provider
      value={{
        displayedColumnsConfig: isLoading ? undefined : displayedColumnsConfig,
      }}
    >
      {children}
    </ListingReportTableColumnDefContext.Provider>
  );
};

export const useListingReportTableColumnDefV2 = () =>
  useContext(ListingReportTableColumnDefContext);
