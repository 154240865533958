import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { Permission } from 'src/WebApiController';

interface UseUpdateSalesSettingsPermissions {
  hasUpdateSalesSettingsPermission: boolean;
}

export const useUpdateSalesSettingsPermissions =
  (): UseUpdateSalesSettingsPermissions => {
    const hasSaleViewPermission = useUserHasAnyOfPermissions(
      Permission.Sales_UpdateSalesInfo,
      Permission.Sales_UpdateSalesInfoOnIsFulfillerOf,
      Permission.Sales_UpdateSalesInfoOnIsPricerOf,
      Permission.Sales_UpdateSaleOnPurchasedBy
    );

    return {
      hasUpdateSalesSettingsPermission: hasSaleViewPermission,
    };
  };
