import { formatInTimeZone } from 'date-fns-tz';
import { useState } from 'react';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { vars } from 'src/core/themes';
import { Card, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { ListingDetails } from 'src/WebApiController';

import * as styles from './DuplicateSeatDialogEntry.css';

export type DuplicateSeatDialogEntryProps = {
  activeListing: ListingDetails;
  listingId: number;
  lastUpdateDate: Date;
  onClick: (listingId: number) => void;
};

export function DuplicateSeatDialogEntry({
  activeListing,
  listingId,
  lastUpdateDate,
  onClick,
}: DuplicateSeatDialogEntryProps) {
  const { timeZone } = useSiteTimezoneContext();
  const [isHovered, setIsHovered] = useState(false);
  const formattedDate = formatInTimeZone(
    lastUpdateDate,
    timeZone,
    'MMM d, yyyy, h:mm a'
  );

  return (
    <Card
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Stack
        gap="s"
        alignItems="center"
        justifyContent="spaceBetween"
        className={isHovered ? styles.hover : styles.noHover}
        onClick={() => onClick(listingId)}
      >
        <SeatingInfo
          showSectionPrefix
          section={activeListing.seating.section}
          row={activeListing.seating.row}
          seatFr={activeListing.seating.seatFr}
          seatTo={activeListing.seating.seatTo}
        />

        <Stack
          gap="m"
          direction="row"
          style={{ fontSize: vars.typography.fontSize.xs }}
        >
          <div
            style={{
              marginRight: vars.spacing['4xl'],
              color: vars.color.textPrimaryLight,
            }}
          >
            ID: {listingId}
          </div>
          <div
            style={{
              color: vars.color.textPrimaryLight,
            }}
          >
            <Content id={ContentId.LastUpdatedOn} />
          </div>

          <strong style={{ color: vars.color.textSuccess }}>
            {formattedDate}
          </strong>
        </Stack>
      </Stack>
    </Card>
  );
}
