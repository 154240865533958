import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { SellerAccountClient } from 'src/WebApiController';

export function useSyncCenterSettings() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const query = useQuery({
    queryKey: [
      'getSyncCenterSettings',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new SellerAccountClient(
        activeAccountWebClientConfig
      ).getSyncCenterSettings();
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    meta: {
      persist: false,

      onError: (error: ErrorTypes) => {
        showErrorDialog('SellerAccountClient.getSyncCenterSettings', error);
      },
    },
  });

  return query;
}
