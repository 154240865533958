import { useQuery, useQueryClient } from '@tanstack/react-query';
import { createContext, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { getErrorInfoFromStatusCode } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AdPlatformReportingClient,
  AdPlatformTransactionAgg,
  TransactionAggType,
} from 'src/WebApiController';

export type IAdPlatformReportingContext = {
  reportData: AdPlatformTransactionAgg[] | null | undefined;
  errorInfo?: { errorHeader: React.ReactNode; errorMessage: React.ReactNode };
  // TODO: Re-enable after CPC support
  //refreshData: (
  //  aggType: TransactionAggType,
  //  adCampaignId?: string
  //) => Promise<QueryObserverResult<unknown, unknown>>;
};

const REPORTING_DATA_QUERY_KEY =
  'AdPlatformReportingClient.getTransactionReport';

export const AdPlatformReportingContext =
  createContext<IAdPlatformReportingContext>({
    reportData: [],
    //refreshData: () =>
    //  Promise.resolve({} as QueryObserverResult<unknown, unknown>),
  });

export const AdPlatformReportingContextProvider = ({
  aggType,
  adCampaignId,
  children,
}: {
  aggType: TransactionAggType;
  adCampaignId: string | undefined;
  children: React.ReactNode;
}) => {
  const [errorInfo, setErrorInfo] = useState<{
    errorHeader: React.ReactNode;
    errorMessage: React.ReactNode;
  }>();

  // TODO: Re-enable after CPC support
  //const { reportQuery } = useGetAdPlatformReportingQuery(
  //  aggType,
  //  adCampaignId,
  //  setErrorInfo
  //);
  const reportData: AdPlatformTransactionAgg[] = [];

  const { activeAccountWebClientConfig } = useAppContext();
  const queryClient = useQueryClient();

  // const refreshData = useCallback(
  //   async (aggType: TransactionAggType, campaignId?: string) => {
  //     queryClient.invalidateQueries({
  //       queryKey: [
  //         REPORTING_DATA_QUERY_KEY,
  //         activeAccountWebClientConfig,
  //         aggType,
  //         campaignId,
  //       ],
  //     });
  //      return reportQuery.refetch();
  //   },
  //   [activeAccountWebClientConfig, queryClient]
  // );

  return (
    <AdPlatformReportingContext.Provider
      value={{
        reportData,
        errorInfo,
        //refreshData,
      }}
    >
      {children}
    </AdPlatformReportingContext.Provider>
  );
};

const useGetAdPlatformReportingQuery = (
  aggType: TransactionAggType,
  adCampaignId: string | undefined,
  setErrorInfo: (
    value: React.SetStateAction<
      | {
          errorHeader: React.ReactNode;
          errorMessage: React.ReactNode;
        }
      | undefined
    >
  ) => void
) => {
  const { activeAccountWebClientConfig, loginContext } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const { getUiCurrency } = useLocalizationContext();

  const uiCurrency = getUiCurrency(
    loginContext?.user?.activeAccount?.currencyCode
  );

  const shouldQuery = activeAccountWebClientConfig.activeAccountId != null;

  const reportQuery = useQuery({
    queryKey: [
      REPORTING_DATA_QUERY_KEY,
      activeAccountWebClientConfig.activeAccountId,
      aggType,
      adCampaignId,
    ],
    queryFn: async () => {
      setErrorInfo(undefined);

      if (!shouldQuery) {
        return null;
      }

      return tryInvokeApi(
        async () => {
          const client = new AdPlatformReportingClient(
            activeAccountWebClientConfig
          );
          const data = await client.getTransactionReport(
            uiCurrency.code,
            aggType,
            adCampaignId,
            undefined
          );
          return data;
        },
        (error) => {
          const { headerDisplay, messageDisplay } = getErrorInfoFromStatusCode(
            error?.status,
            error?.message
          );
          setErrorInfo({
            errorHeader: headerDisplay,
            errorMessage: messageDisplay,
          });
          trackError(REPORTING_DATA_QUERY_KEY, error, {
            aggType: aggType,
            adCampaignId: adCampaignId,
          });
        }
      );
    },
    enabled: activeAccountWebClientConfig.activeAccountId != null,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    networkMode: 'offlineFirst',
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });
  return { reportQuery, reportData: reportQuery.data };
};
