import { clsx } from 'clsx';
import { useMemo } from 'react';
import { CloseButton } from 'reactstrap';
import {
  getDocumentPageUniqueId,
  MappedTicketInfo,
} from 'src/components/UploadArtifacts/UploadETicketsV2';
import { BasePageDocumentProps } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/BasePageDocumentProps';
import { ClearButton } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/ClearButton/ClearButton';
import { MappedPageLabel } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/MappedLabel/MappedPageLabel';
import * as styles from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/SinglePageDocument/SinglePageDocument.css';
import { PosDraggableIndicator } from 'src/core/POS/PosDragNDrop';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { PaperTicketsIcon } from 'src/svgs/PaperTicketsIcon';

export type SinglePageDocumentPageProps = Omit<
  BasePageDocumentProps,
  'page' | 'pageIndex'
> & {
  onRemoveMappedTicket: (ticketInfo: MappedTicketInfo) => void;
  onPageClick: (uniquePageId: string) => void;
  onFileDeleted: () => void;
};

export const SinglePageDocumentPage = ({
  documentFileUploadInfo,
  onFileDeleted,
  entityWithTickets,
  pageUniqueIdToTicketMap,
  isDragging,
  isDragElement,
  onRemoveMappedTicket,
  onPageClick,
  selectedUniquePageId,
  onMouseEntersPage,
  onMouseLeavesPage,
}: SinglePageDocumentPageProps) => {
  const useMobileStyle = !useMatchMedia('largeDesktop');
  const { pages, file } = documentFileUploadInfo;
  const fileName = file!.name;

  const uniquePageId: string = useMemo(() => {
    const page = pages?.[0];
    if (!page) {
      return '';
    }
    return getDocumentPageUniqueId(page);
  }, [pages]);

  const ticketInfo = useMemo(() => {
    if (!pages?.length) {
      return undefined;
    }
    return pageUniqueIdToTicketMap[uniquePageId];
  }, [uniquePageId, pageUniqueIdToTicketMap, pages?.length]);

  const isSelected = selectedUniquePageId === uniquePageId;

  return (
    <div
      className={clsx(
        styles.singlePageDocumentWrapper,
        styles.documentWrapper,
        {
          [styles.draggingElement]: isDragElement,
          [styles.pageHover]: !isDragging,
          [styles.isSelected]: isSelected,
          [styles.isDragging]: isDragging,
          [styles.draggingWrapper]: isDragElement && isDragging,
        }
      )}
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        onPageClick(uniquePageId);
      }}
      onMouseEnter={() => {
        if (isDragging) {
          return;
        }
        onMouseEntersPage(uniquePageId);
      }}
      onMouseLeave={() => {
        if (isDragging) {
          return;
        }
        onMouseLeavesPage();
      }}
    >
      <PosDraggableIndicator
        leftHandle={true}
        rightHandle={false}
        topHandle={false}
        bottomHandle={false}
        classNames={styles.dragHandle}
        removeHoverEffect={true}
      >
        <div className={styles.nameContainer}>
          <Stack alignItems="center" gap="m">
            {!useMobileStyle && <PaperTicketsIcon />}
            <div
              className={clsx(styles.documentName, {
                [styles.documentNameDragging]: isDragElement,
              })}
            >
              {fileName}
            </div>
          </Stack>
          {!isDragElement && (
            <CloseButton
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                e.preventDefault();
                onFileDeleted();
              }}
            />
          )}
        </div>
        {!isDragElement && ticketInfo && (
          <div className={clsx(styles.mappedWrapper, styles.singlePageWrapper)}>
            <MappedPageLabel
              ticketInfo={ticketInfo}
              entityWithTickets={entityWithTickets}
            />
            <ClearButton
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                e.preventDefault();
                onRemoveMappedTicket(ticketInfo);
              }}
            />
          </div>
        )}
      </PosDraggableIndicator>
    </div>
  );
};
