import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { ContentId } from 'src/utils/constants/contentId';

export const LaunchBulkEditListingGroupSettings = ({
  onClick,
  onlyListingGroupsSelected,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onlyListingGroupsSelected: boolean;
}) => {
  return (
    <>
      <PosDropdownItem
        key="bulkUpdateListingGroupSettings"
        onClick={onClick}
        disabled={!onlyListingGroupsSelected}
      >
        <span
          style={{
            color: onlyListingGroupsSelected ? undefined : 'gray',
            cursor: onlyListingGroupsSelected ? 'pointer' : 'default',
          }}
        >
          <Content id={ContentId.BulkEditListingGroupSettings} />
        </span>
      </PosDropdownItem>
    </>
  );
};
