import { useQuery } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Components, Virtuoso } from 'react-virtuoso';
import { ActivityLogDisplay } from 'src/components/ActivityLog';
import {
  ActivityLogFilter,
  ActivityLogFilterQuery,
} from 'src/components/ActivityLog/ActivityLogFilter';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Card } from 'src/core/ui';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ErrorSolidIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { ActivityLog, ActivityLogClient } from 'src/WebApiController';

import IconBulletList from '~icons/pos/bulletList';

import { MainRoute } from '../MainRoute';
import * as styles from './Activity.css';

/**
 * Number of items to fetch
 */
const ACTIVITY_LOG_ITEM_COUNT = 1000;

type VirtuosoContext = {
  isLoading?: boolean;
  isError?: boolean;
  filter: ActivityLogFilterQuery;
  setFilter: Dispatch<SetStateAction<ActivityLogFilterQuery>>;
  data?: ActivityLog[];
};

const EmptyPlaceholder: Components<
  unknown,
  VirtuosoContext
>['EmptyPlaceholder'] = ({ context }: { context?: VirtuosoContext }) => {
  if (context?.isLoading) {
    return <PosSpinner />;
  }
  if (context?.isError) {
    return (
      <EmptySectionContent.Root
        icon={
          <ErrorSolidIcon
            fill={IconsFill.textNegative}
            size={vars.iconSize.xl}
          />
        }
      >
        <EmptySectionContent.Error>
          <Content id={ContentId.Error_InternalServerError_GenericMessage} />
        </EmptySectionContent.Error>
      </EmptySectionContent.Root>
    );
  }
  return (
    <EmptySectionContent.Root icon={<IconBulletList />}>
      <Content id={ContentId.NoActivityLog} />
    </EmptySectionContent.Root>
  );
};

const Header: Components<unknown, VirtuosoContext>['Header'] = ({
  context,
}: {
  context?: VirtuosoContext;
}) => {
  const { filter, setFilter, data } = context ?? {};
  return (
    <div className={styles.center}>
      <h1 className={styles.pageName}>
        <Content id={ContentId.Activity} />
      </h1>
      {filter && setFilter && (
        <div style={{ marginBottom: vars.spacing.m }}>
          <ActivityLogFilter
            currentFilter={filter}
            onCurrentFilterUpdated={setFilter}
            allActionTypes={data?.map((log) => ({
              actionType: log.actionType,
              display: log.actionTypeDisplay,
            }))}
            allUsers={data
              ?.filter((log) => log.initiatedBy?.username && true)
              .map((log) => log.initiatedBy?.username ?? null)}
          />
        </div>
      )}
    </div>
  );
};

// XXX hack to prevent margin collapse on last element
const Footer = () => {
  return <div style={{ paddingTop: '0.05px' }} />;
};

export function Activity() {
  const routeTitle = useContent(ContentId.Activity);
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const activityLogResult = useQuery({
    queryKey: [
      'ActivityLogClient.getRecentActivityLog',
      activeAccountWebClientConfig.activeAccountId,
      ACTIVITY_LOG_ITEM_COUNT,
    ],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new ActivityLogClient(
        activeAccountWebClientConfig
      ).getRecentActivityLog(ACTIVITY_LOG_ITEM_COUNT, true);
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('ActivityLogClient.getRecentActivityLog', error, {
          ACTIVITY_LOG_ITEM_COUNT,
        });
      },
    },
  });

  const [filter, setFilter] = useState<ActivityLogFilterQuery>({
    actionTypes: [],
    auditActionTypes: [],
    initiatedBy: [],
  });
  const filteredData = useMemo(() => {
    return activityLogResult.data?.filter((activityLog) => {
      let allow = true;
      allow =
        filter?.date?.start == null ||
        new Date(filter.date?.start) <= new Date(activityLog.actionDate);
      allow &&=
        filter?.date?.end == null ||
        new Date(filter.date?.end) >= new Date(activityLog.actionDate);
      allow &&=
        ((filter?.actionTypes == null || filter?.actionTypes.length === 0) &&
          (filter?.auditActionTypes == null ||
            filter?.auditActionTypes.length === 0)) ||
        filter.actionTypes.includes(activityLog.actionType) ||
        (activityLog.auditActionType != null &&
          filter.auditActionTypes.includes(activityLog.auditActionType));
      allow &&=
        filter?.initiatedBy == null ||
        filter?.initiatedBy.length === 0 ||
        (activityLog.initiatedBy != null &&
          activityLog.initiatedBy.username != null &&
          filter.initiatedBy.includes(activityLog.initiatedBy.username)) ||
        (activityLog.initiatedByImpersonated != null &&
          activityLog.initiatedByImpersonated.username != null &&
          filter.initiatedBy.includes(
            activityLog.initiatedByImpersonated.username
          ));
      return allow;
    });
  }, [filter, activityLogResult.data]);

  return (
    <LayoutContent mainRoute={MainRoute.Activity} routeTitle={routeTitle}>
      <Virtuoso
        data={filteredData}
        context={{
          isLoading: activityLogResult.isLoading,
          isError: activityLogResult.isError,
          filter,
          setFilter,
          data: activityLogResult.data ?? [],
        }}
        components={{ Header, Footer, EmptyPlaceholder }}
        itemContent={(_, activityLog) => {
          return (
            <div className={styles.center}>
              <Card className={styles.activityCard}>
                <ActivityLogDisplay activityLog={activityLog} />
              </Card>
            </div>
          );
        }}
      />
    </LayoutContent>
  );
}
