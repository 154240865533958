import { ListingReportMetrics, Tag, UiMoney } from 'src/WebApiController';

import {
  LISTING_REPORT_FORMULA_FIELDS_TO_CID,
  ListingFormulaFieldsListingReportMetrics,
} from '../../constants/contentIdMaps';
import { ColumnPersonalizationFlags } from '../columnUtils.types';
import {
  getPersonalizationFlagsFromFormulaBase,
  MathematicalToken,
  parseMathematicalTokensFromFormulaBase,
} from '../customColumnMathUtils';
import {
  evaluateFormulaBase,
  FormulaEditable,
  FormulaExecutable,
  fromExecutableFormula,
  parseEditableFormulaBase,
  parseExecutableFormula,
  tagFieldNameToKey,
  tagKeyToFieldName,
  validateFormulaSingleStatement,
} from '../customColumnUtils';
import { LISTING_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES } from './inventoryColumnUtils.constants';
import { ListingReportTableColumnId } from './inventoryColumnUtils.types';
import { ListingFormulaFields } from './inventoryCustomColumnUtils';
import { CustomListingColumn } from './inventoryCustomColumnUtils.types';

/**
 * Select custom columns that only refers to fields that are displayable in the listing report (e.g. ticket quantity)
 * @param customColumns
 * @returns
 */
export const filterCustomColumnsForListingReport = (
  customColumns: CustomListingColumn[],
  tagsForEntityType: Tag[] | undefined
): CustomListingColumn[] => {
  const listingFormulaFields = Object.keys(
    LISTING_REPORT_FORMULA_FIELDS_TO_CID
  );
  const tagKeys = tagsForEntityType?.map((t) => tagKeyToFieldName(t.key)) ?? [];

  return customColumns.filter((column) => {
    const editableFormula = fromExecutableFormula(column.formula);
    if (!editableFormula) return false;

    const fieldNamesUsed =
      parseEditableFormulaBase(editableFormula, true)?.map((t) => t.token) ??
      [];

    return fieldNamesUsed.every(
      (f) => listingFormulaFields.includes(f) || tagKeys.includes(f)
    );
  });
};

const listingReportMetricsToFormulaFields = (
  listingMetrics?: ListingReportMetrics
) => {
  const tagFields = listingMetrics?.tags?.reduce((acc, tag) => {
    const numericValue = parseFloat(tag.value);
    if (isNaN(numericValue)) return acc;

    return {
      ...acc,
      [tagKeyToFieldName(tag.key)]: numericValue,
    };
  }, {});

  return {
    [ListingFormulaFields.availableQuantity]: listingMetrics?.availQty,
    [ListingFormulaFields.ticketCount]: listingMetrics?.tktQty,
    [ListingFormulaFields.unitCost]: listingMetrics?.ttlCst?.amt,
    [ListingFormulaFields.listPrice]: listingMetrics?.ttlListPrc?.amt,
    [ListingFormulaFields.allInPrice]: listingMetrics?.ttlWebPrc?.amt,
    [ListingFormulaFields.soldQuantity]: listingMetrics?.soldQty,
    [ListingFormulaFields.soldCost]: listingMetrics?.soldCst?.amt,
    [ListingFormulaFields.unsoldCost]: listingMetrics?.unsoldCst?.amt,
    [ListingFormulaFields.soldProceeds]: listingMetrics?.soldProcs?.amt,
    ...tagFields,
  };
};

export const FORMULA_FIELD_TO_COLUMN_ID: Record<
  ListingFormulaFieldsListingReportMetrics,
  ListingReportTableColumnId
> = {
  ['availableQuantity']: ListingReportTableColumnId.UnsoldQuantity,
  ['ticketCount']: ListingReportTableColumnId.TicketCount,
  ['unitCost']: ListingReportTableColumnId.UnitCost,
  ['listPrice']: ListingReportTableColumnId.ListPrice,
  ['allInPrice']: ListingReportTableColumnId.AllInPrice,
  ['soldQuantity']: ListingReportTableColumnId.SoldQuantity,
  ['soldCost']: ListingReportTableColumnId.SoldCost,
  ['unsoldCost']: ListingReportTableColumnId.UnitCost,
  ['soldProceeds']: ListingReportTableColumnId.SoldProceeds,
};

export const parseEditableFormula = (
  editableFormula: FormulaEditable | undefined
): string[] | undefined => {
  const parsedTokenResult = parseEditableFormulaBase(editableFormula);
  if (!parsedTokenResult) {
    return;
  }

  const tokens: string[] = [];
  parsedTokenResult.forEach((tokenResult) => {
    if (tokenResult.isPlainToken) {
      tokens.push(tokenResult.token);
    } else {
      const fieldName = tokenResult.token;
      if (
        LISTING_REPORT_FORMULA_FIELDS_TO_CID[
          fieldName as ListingFormulaFieldsListingReportMetrics
        ] == null
      ) {
        // Tag field
        tokens.push(tagFieldNameToKey(fieldName));
        return;
      }
      tokens.push(
        LISTING_REPORT_FORMULA_FIELDS_TO_CID[
          fieldName as ListingFormulaFieldsListingReportMetrics
        ]
      );
    }
  });

  return tokens;
};

/**
 * Mock listing data explicitly used to test-evaluate formula
 * not putting under src/mock to reduce coupling
 */
export const LISTING_FORMULA_TEST_DATA: ListingReportMetrics = {
  availQty: 1,
  tktQty: 1,
  ttlCst: { amt: 1 } as UiMoney,
  ttlListPrc: { amt: 1 } as UiMoney,
  ttlWebPrc: { amt: 1 } as UiMoney,
  soldQty: 1,
  soldCst: { amt: 1 } as UiMoney,
  unsoldCst: { amt: 1 } as UiMoney,
  soldProcs: { amt: 1 } as UiMoney,
} as ListingReportMetrics;

export const findDependentListingTableColumnIds = (
  editableFormula: FormulaEditable
): ListingReportTableColumnId[] => {
  const fieldNames =
    parseEditableFormulaBase(editableFormula, true)?.map((t) => t.token) ?? [];

  return (
    Object.entries(FORMULA_FIELD_TO_COLUMN_ID)
      .filter(([key]) => fieldNames.includes(key))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_, value]) => value)
  );
};

/**
 * Evaluate formula defined in custom listing column.
 *
 * Example formula: "data?.ticketCount + 20"
 * @param formula
 * @param listingReportMetrics
 * @returns evaluated value, undefined on syntax error
 */
export const evaluateFormula = (
  formula: FormulaExecutable,
  listingReportMetrics?: ListingReportMetrics
) => {
  const data = listingReportMetricsToFormulaFields(listingReportMetrics);

  return evaluateFormulaBase(formula, data);
};

const createListingTestData = (
  tagsForEntityType: Tag[] | undefined
): ListingReportMetrics => {
  const tagData = tagsForEntityType?.map((t) => {
    t.value = '1';
    return t;
  });

  return {
    ...LISTING_FORMULA_TEST_DATA,
    tags: tagData ?? [],
  };
};

export const validateFormula = (
  formula: string,
  tagsForEntityType: Tag[] | undefined
) => {
  return (
    parseExecutableFormula(formula) != null &&
    evaluateFormula(formula, createListingTestData(tagsForEntityType)) !=
      null &&
    validateFormulaSingleStatement(formula)
  );
};

const getTokenFromFieldName = (fieldName: string): MathematicalToken => {
  const columnId =
    FORMULA_FIELD_TO_COLUMN_ID[
      fieldName as ListingFormulaFieldsListingReportMetrics
    ];

  if (columnId == null) {
    // Tag field - here we assume it's not currency - might need to change
    return {
      token: `tag-${fieldName}`,
      isPlainToken: false,
      isCurrency: false,
    };
  }

  const { isCurrency, isPercent } =
    LISTING_REPORT_TABLE_COLUMN_PERSONALIZATION_CONFIG_OVERRIDES[columnId];

  return {
    token: columnId,
    isPlainToken: false,
    isCurrency,
    isPercent,
  };
};

/**
 * Parse editableFormula, transforming formula into mathematical tokens
 * to infer personalization flags
 *
 * E.g. `${ticketCount} + 20` ==> [
 *     { token: 'ticketCount', isCurrency: false, isPercent: false, isPlainToken: false },
 *     { token: '+', isCurrency: false, isPercent: false, isPlainToken: true },
 *     { token: '20', isCurrency: false, isPercent: false, isPlainToken: true }
 * ]
 * @param editableFormula
 * @returns parsed list of tokens, or undefined if failed to parse
 */
const parseMathematicalTokensFromFormula = (
  editableFormula: FormulaEditable | undefined
): MathematicalToken[] | undefined => {
  return parseMathematicalTokensFromFormulaBase(
    editableFormula,
    getTokenFromFieldName
  );
};

export const getPersonalizationFlagsFromFormula = (
  executableFormula: FormulaExecutable
): Partial<ColumnPersonalizationFlags> => {
  const editableFormula = fromExecutableFormula(executableFormula);
  const mathTokens = parseMathematicalTokensFromFormula(editableFormula);

  return getPersonalizationFlagsFromFormulaBase(mathTokens);
};
