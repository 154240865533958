import { RowData } from '@tanstack/react-table';
import { PosVirtuosoTableProps } from 'src/tables/Table/PosVirtuosoTableComponent/PosVirtuosoTableContext';
import * as styles from 'src/tables/Table/Table.css';

export const PosVirtuosoTableHead = <T extends RowData>({
  children,
  context,
}: PosVirtuosoTableProps<T>) => (
  <thead className={styles.tableHead} style={context?.tableHeadStyle}>
    {children}
  </thead>
);
