import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const HeaderSection = styled.header<{ muted?: boolean }>`
  background-color: ${(props) =>
    props.muted
      ? props.theme.colors.backgroundSecondary
      : props.theme.colors.backgroundInfo};
  color: ${(props) => props.theme.colors.textPrimary};
  padding: ${() =>
    `${vars.spacing.xl} ${vars.spacing.lg} ${vars.spacing.sm} ${vars.spacing.lg}`};
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding: ${vars.spacing.xl} ${vars.spacing.xl} ${vars.spacing.lg} ${vars.spacing.xl};
      `
    )}`}
`;

export const HeaderRightGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      text-align: right;
      max-width: 300px;
      align-items: flex-end;
      `
    )}`}
`;

export const HeaderLeftGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      max-width: 300px;
      `
    )}`}
`;

export const VendorContainer = styled.div`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title4')};
  padding-block-end: ${() => vars.spacing.lg};

  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const PurchaseOrderStateContainer = styled.div`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title4')};

  color: ${(props) => props.theme.colors.textNegative};
  padding-block-end: ${() => vars.spacing.lg};
  width: min-content;
  border: 1px solid ${(props) => props.theme.colors.textNegative};
  border-radius: ${() => vars.spacing.sm};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${() => vars.spacing.sm};
`;
