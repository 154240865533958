import { Fragment, useCallback, useMemo } from 'react';
import {
  Content,
  getContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { PosSelect } from 'src/core/POS/PosSelect';
import { useTicketTypes } from 'src/hooks/api/useTicketTypes';
import { getTicketTypePriorityOptions } from 'src/navigations/Routes/Settings/Views/SyncCenter/SyncCenter.utils';
import { ContentId } from 'src/utils/constants/contentId';
import { TICKET_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  backfillTicketTypePriorityOrders,
  BLOCKED_PRIORITY,
  shiftTicketTypePriority,
  sortPriorityOrders,
  isPriorityBlocked,
} from 'src/utils/ticketTypeUtils';
import {
  DeliveryType, TicketType,
  TicketTypeOverride,
  TicketTypePriority
} from 'src/WebApiController';

import * as styles from './TicketTypePriorityInput.css';

export const TicketTypePriorityInput = ({
  deliveryType,
  ticketTypeRules,
  onTicketTypeRulesChange,
  disabled,
}: {
  deliveryType: DeliveryType | null;
  ticketTypeRules?: TicketTypeOverride | null;
  onTicketTypeRulesChange: (ticketTypeRules: TicketTypeOverride | null) => void;
  disabled?: boolean;
}) => {
  const { ticketTypes } = useTicketTypes(deliveryType);
  const contentContext = useContentContext();

  const onOnlyThisChange = useCallback(
    (ticketType: TicketType) => {
      const ticketTypePriorityOrdersNew: TicketTypePriority[] = ticketTypes.map(
        (tt) => {
          if (tt === ticketType) {
            return {
              ticketType,
              priority: 1,
              isBlocked: false,
            };
          }
          return {
            ticketType: tt,
            priority: 0,
            isBlocked: true,
          };
        }
      );

      onTicketTypeRulesChange({
        overrides: [
          {
            deliveryType: deliveryType ?? DeliveryType.InApp,
            ticketTypePriorities: ticketTypePriorityOrdersNew,
          },
        ],
      });
    },
    [deliveryType, onTicketTypeRulesChange, ticketTypes]
  );

  const onResetAll = useCallback(() => {
    onTicketTypeRulesChange(null);
  }, [onTicketTypeRulesChange]);

  const defaultTicketTypePriorityOrders: TicketTypePriority[] = useMemo(
    () =>
      ticketTypes.map((tt, i) => ({
        ticketType: tt,
        priority: i + 1,
        isBlocked: false,
      })),
    [ticketTypes]
  );

  const overridesForDeliveryType = ticketTypeRules?.overrides?.find(
    (o) => o.deliveryType === deliveryType
  );

  const onPriorityChange = useCallback(
    (val: string, ticketType: TicketType) => {
      if (val === ContentId.OnlyThis) {
        onOnlyThisChange(ticketType);
        return;
      }
      if (val === ContentId.ResetAll) {
        onResetAll();
        return;
      }

      const priorityOrderBefore =
        overridesForDeliveryType?.ticketTypePriorities ??
        defaultTicketTypePriorityOrders;
      backfillTicketTypePriorityOrders(ticketTypes, priorityOrderBefore);

      const priorityOrderAfter = shiftTicketTypePriority(
        priorityOrderBefore,
        ticketType,
        Number(val)
      );

      const ticketTypePriorityOrdersNew = ticketTypes.map((tt, ii) => {
        const to = priorityOrderAfter.find((to) => to.ticketType === tt);

        if (to) {
          return { ...to };
        }

        // This should never happen, but just in case
        return {
          ticketType: tt,
          priority: ii + ticketTypes.length,
          isBlocked: false,
        };
      });

      onTicketTypeRulesChange({
        overrides: [
          {
            deliveryType: deliveryType ?? DeliveryType.InApp,
            ticketTypePriorities: ticketTypePriorityOrdersNew,
          },
        ],
      });
    },
    [
      defaultTicketTypePriorityOrders,
      deliveryType,
      onOnlyThisChange,
      onResetAll,
      onTicketTypeRulesChange,
      overridesForDeliveryType?.ticketTypePriorities,
      ticketTypes,
    ]
  );

  // This is used for display only - the ticketTypes variable is ordered by default priority
  // so we don't want to alter that
  const { ticketTypesOrderedByPriority, numBlockedTicketTypes, isEmptyTicketTypesOverridesFromResponse } = useMemo(
    () => {

      const ticketTypePriorityOverrides = [...(overridesForDeliveryType?.ticketTypePriorities ?? [])]
        .filter(to => to.ticketType != null)
        .map((to) => ({
          ...to,
          // If the priority is null (which is actually broken data), it is effectively blocked
          isBlocked: isPriorityBlocked(to),
        }))
      
      let numBlockedTicketTypes =
        ticketTypePriorityOverrides.filter((to) => to.isBlocked)
          .length ?? 0;

      const orderedTicketTypePriority = ticketTypePriorityOverrides
        .sort(sortPriorityOrders)
        .map((to) => to.ticketType);

      // this need to happen when data is corrupted or overrides only got partially returned from backend 
      // or emtpy overrides returned from backedn
      const missingTicketTypePriority = ticketTypes.filter(tt => !orderedTicketTypePriority.includes(tt));

      // if overrides are partially returned, treat the missing ones as Blocked if any
      if (ticketTypePriorityOverrides.length > 0) {
        numBlockedTicketTypes += missingTicketTypePriority.length ?? 0;
      }

      return {
        ticketTypesOrderedByPriority: orderedTicketTypePriority.concat(missingTicketTypePriority),
        numBlockedTicketTypes,
        isEmptyTicketTypesOverridesFromResponse: ticketTypePriorityOverrides.length == 0
      }
    },
    [
      overridesForDeliveryType?.ticketTypePriorities,
      ticketTypes
    ]
  );

  return (
    <div className={styles.ticketTypePriorityInputGrid}>
      {ticketTypesOrderedByPriority.map((ticketType, i) => {
        const priorityOrder =
          overridesForDeliveryType?.ticketTypePriorities.find(
            (to) => to.ticketType === ticketType
          );

        const isBlocked = 
          isEmptyTicketTypesOverridesFromResponse ? false : // if the backend responded with empty, then the priority will be default and not blocked
            priorityOrder == null || isPriorityBlocked(priorityOrder)
          
        /**
         *  UI will make the ticket type priorty contiguous, changing and saving the priority from website
         *  will change the priority value on the backend (also when it map to multiple backend ticket type),
         *  but the order of the priority would still retain.
         * */ 
        const uiTicketPriority = ticketTypesOrderedByPriority.indexOf(ticketType) + 1;
        return (
          <Fragment key={ticketType}>
            <div>
              <Content id={TICKET_TYPE_TO_CID[ticketType]} />
            </div>
            <div>
              <PosSelect
                disabled={disabled}
                placeholderText={`${uiTicketPriority} (${getContent(
                  ContentId.Default,
                  contentContext
                )})`}
                value={
                  priorityOrder
                    ? String(
                        isBlocked
                          ? BLOCKED_PRIORITY
                          : uiTicketPriority
                      )
                    : isEmptyTicketTypesOverridesFromResponse ? undefined : String(BLOCKED_PRIORITY) // if partially returned, the remaining priority should be blocked
                }
                valueOptionsContent={getTicketTypePriorityOptions(
                  isBlocked
                    ? // if the current is blocked, it can piggy back
                      ticketTypes.length - numBlockedTicketTypes + 1
                    : ticketTypes.length - numBlockedTicketTypes
                )}
                onChange={(val) => onPriorityChange(val, ticketType)}
              />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
