import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const CustomDeliveryIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 18 19">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9817 2.63665L11.9612 2.61287L9.72953 2.62311L9.55736 2.92515C9.34591 3.28074 8.94452 3.51347 8.51961 3.53123C8.0997 3.51972 7.71483 3.30325 7.4771 2.93114L7.3172 2.6326L6.92658 2.62609L4.08433 2.68263L4.1593 16.3263L8.80328 16.3263H13.1589L12.9817 2.63665ZM5.27097 3.84976L5.33144 15.1302L11.87 15.133L11.8095 3.85257L10.3934 3.85155C9.96625 4.41134 9.27633 4.74541 8.55363 4.7424C7.83093 4.73939 7.13401 4.40932 6.68708 3.85077L5.27097 3.84976Z"
        fill={props.fill ?? '#87909A'}
      />
      <rect
        x="10.0098"
        y="8.75"
        width="1.5"
        height="5.25"
        transform="rotate(38.9789 10.0098 8.75)"
        fill={props.fill ?? '#87909A'}
      />
      <mask id="path-3-inside-1_6828_192626" fill="white">
        <path d="M7.63872 14.0666L6.47266 13.123L6.58391 14.1779L7.63872 14.0666Z" />
      </mask>
      <path
        d="M7.63872 14.0666L6.47266 13.123L6.58391 14.1779L7.63872 14.0666Z"
        fill={props.fill ?? '#87909A'}
      />
      <path
        d="M6.47266 13.123L7.10169 12.3457L5.22494 10.827L5.47817 13.2279L6.47266 13.123ZM7.63872 14.0666L7.74362 15.0611L10.1445 14.8078L8.26776 13.2892L7.63872 14.0666ZM6.58391 14.1779L5.58943 14.2827L5.69432 15.2772L6.68881 15.1723L6.58391 14.1779ZM5.84362 13.9004L7.00969 14.844L8.26776 13.2892L7.10169 12.3457L5.84362 13.9004ZM7.53383 13.0721L6.47902 13.1834L6.68881 15.1723L7.74362 15.0611L7.53383 13.0721ZM7.5784 14.073L7.46714 13.0182L5.47817 13.2279L5.58943 14.2827L7.5784 14.073Z"
        fill={props.fill ?? '#87909A'}
        mask="url(#path-3-inside-1_6828_192626)"
      />
    </SvgWrapper>
  );
};
