import { format } from 'date-fns';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';

export const getGroupingLabelForString = (value: string): string => {
  return value.charAt(0).toUpperCase();
};

export const getGroupLabelForDates = (date?: string | null): string => {
  if (!date) {
    return '-';
  }
  return format(new Date(date), 'MMMM yyyy', {
    locale: getLocaleFromLanguageOrCurrent(),
  });
};

export const getGroupLabelForNumber = (value: number, prefix = ''): string => {
  const length = String(Math.abs(Math.trunc(value))).length;
  const sign = value < 0 ? '-' : '';
  const from = 10 ** (length - 1);
  const fromLabel = from === 1 ? 0 : from.toLocaleString();
  const to = (10 ** length).toLocaleString();
  return `${sign}${prefix}${fromLabel} to ${sign}${prefix}${to}`;
};

export const getGroupLabelForPercentNumber = (
  value: number,
  prefix = ''
): string => {
  let from = Math.abs(Math.trunc((value ?? 0) * 10));
  from = from === 10 ? 9 : from; // Avoid going over 100%
  const sign = value < 0 ? '-' : '';
  const fromLabel = (from * 10).toLocaleString();
  const to = ((from + 1) * 10).toLocaleString();
  return `${sign}${prefix}${fromLabel} - ${sign}${prefix}${to}%`;
};
