import { debounce } from 'lodash-es';
import { useCallback, useState } from 'react';
import { useFormattedContent } from 'src/contexts/ContentContext';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { FormatContentId } from 'src/utils/constants/formatContentId';

interface FilterVendorAccountProps {
  disable?: boolean;
  onSearchTextChanged: (searchText: string) => void;
  searchEntityLabel: string;
}

export const FilterInput = ({
  disable,
  onSearchTextChanged,
  searchEntityLabel,
}: FilterVendorAccountProps) => {
  const [searchText, setSearchText] = useState<string>('');

  const searchPlaceholderText = useFormattedContent(
    FormatContentId.SearchItem,
    searchEntityLabel
  ) as string;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      const newText = text.trim();
      if (newText !== searchText) {
        setSearchText(newText);
        onSearchTextChanged(newText);
      }
    }, 250),
    [searchText, onSearchTextChanged]
  );

  return (
    <PosSearchBox
      placeholder={searchPlaceholderText}
      onSearchChange={debouncedOnSearchChange}
      disabled={disable}
    />
  );
};
