import { debounce } from 'lodash-es';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EventEntitySearchBoxV2 } from 'src/components/Accordions/EventEntitySearchBox/EventEntitySearchBoxV2';
import { BackSection } from 'src/components/common/BackSection';
import { BulkEditStatusPopover } from 'src/components/common/BulkActions/BulkEditStatusPopover';
import { MultiSelectionToggleGlobal } from 'src/components/common/MultiSelect/Toggle/MultiSelectionToggleGlobal';
import { FilterToolbar } from 'src/components/FilterToolbar';
import { useFiltersHelpers } from 'src/components/FilterToolbar/useFiltersHelpers';
import {
  saleMandatoryFiltersToShow,
  useSaleFilters,
  ViewModeSelector,
} from 'src/components/MainFilterBar';
import * as styles from 'src/components/MainFilterBar/MainFilterBar.css';
import { SaleBasicInfo } from 'src/components/Sales/SaleBasicInfo';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { FormatContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { SearchResultPickerDialog } from 'src/dialogs/SearchResultPickerDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { useFilterToolbarQuickFilters } from 'src/hooks/useQuickFilters';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { SaleDeeplinkQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { SALES_VIEW_MODE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { getDeepLinkIdFromUrl } from 'src/utils/deepLinkUtils';
import { DefaultSaleQuery, getQueryFromUrl } from 'src/utils/eventQueryUtils';
import { getSaleDetailsModalConfigWithDeepLink } from 'src/utils/saleUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Feature,
  Sale,
  SaleClient,
  SaleDetails,
  SaleQuery,
  SalesViewMode,
  UserSetting,
} from 'src/WebApiController';
import Swiper from 'swiper';

import {
  EventEntitySearchBox,
  SearchMode,
} from '../../Accordions/EventEntitySearchBox/EventEntitySearchBox';
import { SwiperButton } from '../../Buttons/SwiperButton';
import { SalesGlobalActionDropdown } from '../SalesActionDropdown';
import { useDefaultSaleFilters } from './hooks/useDefaultSaleFilters';
import { SALE_VIEW_MODE_TO_ICON } from './SaleFilterBar.types';

export function SaleFilterBar({
  isSearchOnly,
  isEventPage = false,
  returnUrl,
  posEventId,
  swiperRef,
}: {
  isSearchOnly?: boolean;
  isEventPage?: boolean;
  returnUrl?: string;
  posEventId?: string;
  swiperRef?: React.MutableRefObject<Swiper | undefined>;
}) {
  const { posEntity: sale, setActivePosEntity } =
    useActivePosEntityContext<SaleDetails>();
  const { closeDialog, launchDialog, dialogProps } = useBasicDialog();
  const { setModal, isModalOpen } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();

  const [searchId, setSearchId] = useState<string>();
  const [searchResults, setSearchResults] = useState<Sale[]>();
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [defaultSearchMode, setDefaultSearchMode] = useState<
    SearchMode | undefined
  >();

  const { showErrorDialog } = useErrorBoundaryContext();
  const hasFullPageEventViewEntitySelection = useUserHasFeature(
    Feature.FullPageEventViewEntitySelection
  );

  const useFiltersDialogV2AllApp = useUserHasFeature(
    Feature.FilterToolBarUIV2AllApp
  );
  const hasEventEntitySearchV2 = useUserHasFeature(Feature.EventEntitySearchV2);

  const {
    filterQuery: saleQuery,
    initialQuery,
    tempQuery,
    setFilterQuery,
    setTempQuery,
    resetTempQuery,
  } = useFilterQueryContext<SaleQuery>();

  const location = useLocation();

  useEffect(() => {
    if (!hasFullPageEventViewEntitySelection) {
      return;
    }

    const queryFromUrl = getQueryFromUrl<SaleQuery>(
      initialQuery,
      location.search
    );

    if (
      Array.isArray(queryFromUrl?.entityIds) &&
      queryFromUrl?.entityIds.length
    ) {
      setDefaultSearchMode(SearchMode.IdLookup);
      setSearchText((prev) =>
        queryFromUrl?.entityIds ? queryFromUrl?.entityIds.join(',') : prev
      );
    } else if (
      Array.isArray(queryFromUrl?.marketplaceEntityIds) &&
      queryFromUrl?.marketplaceEntityIds.length
    ) {
      setDefaultSearchMode(SearchMode.IdLookup);
      setSearchText((prev) =>
        queryFromUrl?.marketplaceEntityIds
          ? queryFromUrl?.marketplaceEntityIds.join(',')
          : prev
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialQuery, hasFullPageEventViewEntitySelection]);

  useEffect(() => {
    setTempQuery(saleQuery);
  }, [saleQuery, setTempQuery]);

  const { marketplaceEntityIds, searchText: saleQuerySearchText } = saleQuery;
  useEffect(() => {
    if (marketplaceEntityIds && hasFullPageEventViewEntitySelection) {
      return;
    }

    if (saleQuerySearchText) {
      setSearchText(saleQuerySearchText);
      setDefaultSearchMode(SearchMode.EventSearch);
    } else if (marketplaceEntityIds && Array.isArray(marketplaceEntityIds)) {
      const ids = marketplaceEntityIds.join(',');
      if (ids.length > 0) {
        setSearchText(ids);
        setDefaultSearchMode(SearchMode.IdLookup);
      }
    }
  }, [
    hasFullPageEventViewEntitySelection,
    marketplaceEntityIds,
    saleQuerySearchText,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnSearchChange = useCallback(
    debounce((text: string) => {
      if (text !== saleQuery.searchText) {
        setFilterQuery({
          ...saleQuery,
          searchText: text || null,
        });
      }
    }, 200),
    [saleQuery]
  );

  const activeDeepLinkSaleId = getDeepLinkIdFromUrl(
    SaleDeeplinkQueryParam,
    window.location.href
  );

  const onLookupById = useCallback(
    async (id: string, isSearching: boolean) => {
      if (!id && (tempQuery.entityIds || tempQuery.marketplaceEntityIds)) {
        const newQuery = {
          ...tempQuery,
          entityIds: null,
          marketplaceEntityIds: null,
        };
        setFilterQuery({ ...newQuery });
        return;
      }
      if (id && !isLoadingSearch) {
        if (
          sale?.idOnMkp === id ||
          String(sale?.id) === id ||
          activeDeepLinkSaleId === id
        ) {
          // If the id hasn't changed, check that if it's open, if it's not, open it
          if (!isModalOpen) {
            if (activeDeepLinkSaleId) {
              setActivePosEntity(
                Number(activeDeepLinkSaleId),
                undefined,
                false
              );
            }
            setModal(getSaleDetailsModalConfigWithDeepLink(sale?.id));
          }

          // Else, just don't do anything
          return;
        }

        const matchedIds = id
          .split(',')
          .filter((s) => s.trim())
          .map((s) => s.trim()); // split by commas and remove all empty ones

        if (matchedIds.length > 1) {
          const newQuery = {
            ...DefaultSaleQuery,
            entityIds: null,
            marketplaceEntityIds: matchedIds,
            viewMode: SalesViewMode.FlattenedView,
          };
          setFilterQuery(newQuery);
          return;
        } else if (tempQuery.entityIds || tempQuery.marketplaceEntityIds) {
          const newQuery = {
            ...tempQuery,
            entityIds: null,
            marketplaceEntityIds: null,
          };
          setFilterQuery(newQuery);
        }

        setSearchId(id);
        setIsLoadingSearch(true);

        try {
          // when user enter a text here, we don't know whether they entered the sale id or unique id
          // since 99% of the time they'll be entering a sale id, we try to lookup by that first
          const sales = isSearching
            ? await tryInvokeApi(
                () =>
                  new SaleClient(
                    activeAccountWebClientConfig
                  ).getSalesByMarketplaceSaleId(id),
                (error) => {
                  showErrorDialog(
                    'SaleClient.getSalesByMarketplaceSaleId',
                    error,
                    { trackErrorData: { saleId: id } }
                  );
                }
              )
            : null;

          if (!sales || sales.length === 0) {
            const idNum = Number(id);
            if (isNaN(idNum)) {
              // If we can't find by either PO external id or internal id, just launch empty search result dialog
              launchDialog();
              return false;
            }

            // If we didn't find any sales by market-place-sale-id, assume this is a unique id search
            setActivePosEntity(idNum, undefined, true);
            setModal(getSaleDetailsModalConfigWithDeepLink(idNum));

            return true;
          } else {
            // We have sales using the market place id
            // 99% of the time, the id should be unique, but if we support more than 1 marketplaces
            // the ids may collide and return more than one result, therefore we need to present to the user and ask them what they want
            if (sales.length === 1) {
              const saleInfo = sales[0];
              // happy path
              setActivePosEntity(saleInfo.id, saleInfo.idOnMkp, true);
              setModal(
                getSaleDetailsModalConfigWithDeepLink(saleInfo.id, true)
              );

              return true;
            } else {
              setSearchResults(sales);
              launchDialog();
            }
          }
        } finally {
          setIsLoadingSearch(false);
        }
      }

      return false;
    },
    [
      activeAccountWebClientConfig,
      activeDeepLinkSaleId,
      isLoadingSearch,
      isModalOpen,
      launchDialog,
      sale,
      setActivePosEntity,
      setFilterQuery,
      setModal,
      showErrorDialog,
      tempQuery,
    ]
  );

  const clearSearchEntity = useCallback(() => {
    const newQuery = {
      ...tempQuery,
      searchText: null,
      entityIds: null,
      marketplaceEntityIds: null,
    };
    setFilterQuery(newQuery);
  }, [tempQuery, setFilterQuery]);

  const onResultItemPicked = useCallback(
    (sale: Sale) => {
      setActivePosEntity(sale.id, sale.idOnMkp, true);
      setModal(getSaleDetailsModalConfigWithDeepLink(sale.id, true));

      // We do not want to close the search dialog as users may want to click each one
      // We'll let them close the search dialog manually
    },
    [setActivePosEntity, setModal]
  );

  useEffect(() => {
    if (activeDeepLinkSaleId?.length) {
      onLookupById(activeDeepLinkSaleId, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeepLinkSaleId]);

  const defaultQuickFilters = useDefaultSaleFilters({
    saleQuery,
    initialQuery,
  });

  const { value: defaultViewModeUserSetting, setUserSetting } =
    useServerUserSetting<SalesViewMode>({
      id: UserSetting.SalePageViewMode,
      currentLoginUserOnly: true,
    });

  const filterToolbarProps = useFilterToolbarQuickFilters({
    quickFiltersStateSetting: UserSetting.QuickFiltersStateSales,
    customQuickFiltersSetting: UserSetting.QuickFiltersCustomSales,
    defaultQuickFilters,
    currentQuery: saleQuery,
    initialQuery,
    onSelect: ({ query }) => {
      if (query.viewMode != null) {
        // We need to update initialQuery, otherwise the quick filter "All"
        // may change the current view mode
        if (query.viewMode !== initialQuery.viewMode) {
          initialQuery.viewMode = query.viewMode;
        }
      }
      setFilterQuery(query);

      // This needs to happen after to reduce re-rendering
      if (query.viewMode != null) {
        // Update user setting view mode too so it won't revert the filter query viewMode
        if (query.viewMode !== defaultViewModeUserSetting) {
          setUserSetting(query.viewMode);
        }
      }
    },
  });

  const { filters: saleFilters } = useSaleFilters();

  const isMobile = useMatchMedia('mobile');

  const { appliedFilters } = useFiltersHelpers({
    filters: saleFilters,
    activeQuery: saleQuery,
    mandatoryFiltersToShow: saleMandatoryFiltersToShow,
    initialQuery,
  });

  const filterToolbar = saleQuery && (
    <FilterToolbar
      {...filterToolbarProps}
      filterAppliedCounts={appliedFilters.length}
      filterAppliedIds={appliedFilters}
      onSubmitFilter={() => {
        setFilterQuery(tempQuery);
      }}
      filters={saleFilters}
      mandatoryFiltersToShow={saleMandatoryFiltersToShow}
      useNewFilterUX={useFiltersDialogV2AllApp}
      resetTempQuery={resetTempQuery}
      tempQuery={tempQuery}
    />
  );

  return (
    <>
      {isSearchOnly ? (
        <div className={styles.mainFilterBarDiv}>
          <div className={styles.dockLeftDiv}>
            <div className={styles.mainFilterBarLeftChild}>
              <SwiperButton dir="left" swiperRef={swiperRef} />
            </div>
          </div>
          <div className={styles.dockRightDiv}>
            <div className={styles.mainFilterBarRightChild}>
              {!isEventPage && (
                <>
                  <BulkEditStatusPopover
                    entityType={ActionOutboxEntityType.Sale}
                  />
                  {!isMobile && (
                    <ViewModeSelector
                      query={saleQuery}
                      initialQuery={initialQuery}
                      setFilterQuery={setFilterQuery}
                      viewModeSettingsId={UserSetting.SalePageViewMode}
                      viewModeCidMap={SALES_VIEW_MODE_TO_CID}
                      viewModeIconMap={SALE_VIEW_MODE_TO_ICON}
                    />
                  )}
                  <MultiSelectionToggleGlobal />
                  <SalesGlobalActionDropdown />
                  {hasEventEntitySearchV2 ? (
                    <EventEntitySearchBoxV2
                      maxWidth={'auto'}
                      disabled={isLoadingSearch}
                      onIdLookup={(value) => onLookupById(value, true)}
                      onEventSearch={(value) => debouncedOnSearchChange(value)}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      clearSearchEntity={clearSearchEntity}
                    />
                  ) : (
                    <EventEntitySearchBox
                      entityLookupPlaceholder={ContentId.LookupBySaleId}
                      maxWidth="auto"
                      disabled={isLoadingSearch}
                      onIdLookup={(value) => onLookupById(value, true)}
                      onEventSearch={(value) => debouncedOnSearchChange(value)}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      defaultSearchMode={defaultSearchMode}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.mainFilterBarDiv}>
          {isMobile ? (
            filterToolbar
          ) : (
            <>
              <div className={styles.dockLeftDiv}>
                <div className={styles.mainFilterBarLeftChild}>
                  {isEventPage && (
                    <BackSection
                      returnUrl={returnUrl ?? '/sales'}
                      state={{
                        fromPosEventId: posEventId,
                      }}
                    />
                  )}
                  {filterToolbar}
                </div>
              </div>
              <div className={styles.dockRightDiv}>
                <div className={styles.mainFilterBarRightChild}>
                  {!isEventPage && (
                    <>
                      <BulkEditStatusPopover
                        entityType={ActionOutboxEntityType.Sale}
                      />
                      <ViewModeSelector
                        query={saleQuery}
                        initialQuery={initialQuery}
                        setFilterQuery={setFilterQuery}
                        viewModeSettingsId={UserSetting.SalePageViewMode}
                        viewModeCidMap={SALES_VIEW_MODE_TO_CID}
                        viewModeIconMap={SALE_VIEW_MODE_TO_ICON}
                      />
                      <MultiSelectionToggleGlobal />
                      <SalesGlobalActionDropdown />
                      {hasEventEntitySearchV2 ? (
                        <EventEntitySearchBoxV2
                          maxWidth={'auto'}
                          disabled={isLoadingSearch}
                          onIdLookup={(value) => onLookupById(value, true)}
                          onEventSearch={(value) =>
                            debouncedOnSearchChange(value)
                          }
                          searchText={searchText}
                          setSearchText={setSearchText}
                          clearSearchEntity={clearSearchEntity}
                        />
                      ) : (
                        <EventEntitySearchBox
                          entityLookupPlaceholder={ContentId.LookupBySaleId}
                          maxWidth="auto"
                          disabled={isLoadingSearch}
                          onIdLookup={(value) => onLookupById(value, true)}
                          onEventSearch={(value) =>
                            debouncedOnSearchChange(value)
                          }
                          searchText={searchText}
                          setSearchText={setSearchText}
                          defaultSearchMode={defaultSearchMode}
                        />
                      )}
                    </>
                  )}
                  {isEventPage && hasFullPageEventViewEntitySelection && (
                    <EventEntitySearchBox
                      entityLookupPlaceholder={ContentId.LookupBySaleId}
                      maxWidth="auto"
                      disabled={isLoadingSearch}
                      onIdLookup={(value) => onLookupById(value, true)}
                      onEventSearch={(value) => debouncedOnSearchChange(value)}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      defaultSearchMode={SearchMode.IdLookup}
                      freezeSearchMode
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <SearchResultPickerDialog
        {...dialogProps}
        header={
          <FormatContent
            id={
              searchResults?.length
                ? FormatContentId.SearchResultPick
                : FormatContentId.CouldNotFindSaleId
            }
            params={[searchId || '', `${searchResults?.length || 0}`]}
          />
        }
        resultItems={searchResults || []}
        renderItem={(sale: Sale) => <SaleBasicInfo sale={sale} />}
        onResultItemPicked={onResultItemPicked}
        onCancel={closeDialog}
      />
    </>
  );
}
