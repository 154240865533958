import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSelectProps } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { AccessControlProvider, PurchaseClient } from 'src/WebApiController';

export type UseVendorAccessControlProvidersSelectorProps = {
  disabled?: boolean;
  additionalOptions?: Record<string, string>;
  additionalOptionsPosition?: 'top' | 'bottom';
  placeholderText?: string | ContentId;
};

export const useVendorAccessControlProvidersSelector = ({
  disabled,
  additionalOptions,
  additionalOptionsPosition = 'bottom',
  placeholderText,
}: UseVendorAccessControlProvidersSelectorProps): {
  query: UseQueryResult<AccessControlProvider[] | null>;
  posSelectProps: Pick<
    PosSelectProps,
    'placeholderText' | 'valueOptionsContent'
  >;
} => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled && activeAccountWebClientConfig.activeAccountId != null;
  const query = useQuery({
    queryKey: [
      'getAccessibleAccessControlProviders',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return tryInvokeApi(
        () => {
          return new PurchaseClient(
            activeAccountWebClientConfig
          ).getAccessibleAccessControlProviders();
        },
        (error) => {
          trackError(
            'PurchaseClient.getAccessibleAccessControlProviders',
            error
          );
        }
      );
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
  });

  const availableAccessControlProviders = useMemo(() => {
    const dataSorted = query.data;
    dataSorted?.sort((a: AccessControlProvider, b: AccessControlProvider) =>
      a.name.localeCompare(b.name)
    );

    const results = dataSorted?.reduce(
      (r, cur) => {
        r[cur.id] = cur.name;
        return r;
      },
      (additionalOptions && additionalOptionsPosition === 'top'
        ? additionalOptions
        : {}) as Record<string, string>
    );

    return {
      ...(additionalOptionsPosition === 'top' ? additionalOptions : {}),
      ...(results ?? {}),
      ...(additionalOptionsPosition === 'bottom' ? additionalOptions : {}),
    };
  }, [query.data, additionalOptions, additionalOptionsPosition]);

  return {
    query,
    posSelectProps: {
      placeholderText: placeholderText ?? ContentId.VendorGroup,
      valueOptionsContent: availableAccessControlProviders,
    },
  };
};
