import { ROW_FILTER_MISSING } from 'src/components/common/FilterPill/FilterPillList.constants';
import { Listing } from 'src/WebApiController';

export const filterListingItemsBySectionRow = (
  listing: Listing,
  listingSectionId: number | null,
  sectionIdFilters: number[],
  rowIdFilters: number[],
  sectionRowIdFilters: { [key: string]: string },
  quantityFilter: number
) => {
  const sectionId = listingSectionId ?? 0;
  const rowId = listing.seating.rowId ?? 0;
  const quantity = listing.availQty ?? 0;

  // filter by quantity
  if (quantity < quantityFilter) {
    return false;
  }

  // filter by section
  const sectionIncluded =
    sectionIdFilters.length > 0 ? sectionIdFilters.includes(sectionId) : true;

  if (!sectionIncluded) {
    return false;
  }

  // No row filters for the section, return true
  if (
    sectionRowIdFilters[sectionId] == null ||
    sectionRowIdFilters[sectionId] === ROW_FILTER_MISSING
  ) {
    return true;
  }

  // filter by row
  const rowIncluded =
    rowIdFilters.length > 0 ? rowIdFilters.includes(rowId) : true;

  return rowIncluded;
};
