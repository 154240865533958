import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { CloseButton } from 'reactstrap';
import { SeatingInfo } from 'src/components/common/SeatingInfo';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { ErrorMessage } from 'src/core/POS/ErrorMessage';
import { ImageMagnifier } from 'src/core/POS/ImageMagnifier';
import {
  PosDraggable,
  PosDraggableIndicator,
  PosDroppable,
} from 'src/core/POS/PosDragNDrop';
import { PosCustomDragLayer } from 'src/core/POS/PosDragNDrop/PosCustomDragLayer';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import {
  DocumentPage,
  DocumentType,
  Ticket,
  TicketType,
} from 'src/WebApiController';

import * as styles from '../UploadETickets.css';
import {
  SeatLabel,
  UploadETicketsSeat,
  UploadETicketsSeatingContainer,
  UploadETicketsSeatingPlaceholder,
} from '../UploadETickets.styled';
import {
  DocumentFileUploadInfo,
  ETicketsForm,
  getDocumentPagesMap,
  getETicketDocumentPageUniqueId,
} from '../UploadETickets.utils';

export function UploadETicketsSeatingSection({
  entityWithTickets,
  eTicketUploadInfos,
  ticketType,
  isFinalPreview,
}: {
  entityWithTickets: Pick<
    EntityWithRealTickets,
    'viagVirtualId' | 'id' | 'ticketCnt' | 'tickets' | 'seating' | 'entityType'
  >;
  ticketType: TicketType.ETicket | TicketType.QRCode;
  eTicketUploadInfos: DocumentFileUploadInfo[];
  isFinalPreview?: boolean;
}) {
  const eTicketPages = getDocumentPagesMap(eTicketUploadInfos);
  const { watch, setValue, clearErrors, formState } =
    useFormContext<ETicketsForm>();
  const watchTickets = watch('ticketAssignments'); // you can supply default value as second argument

  const onDroppingToSeat = useCallback(
    (eTicket: DocumentPage, ticket: Ticket, ticketIndex: number) => {
      setValue(`ticketAssignments.${ticketIndex}`, {
        entityId: ticket.id,
        documentId: eTicket.documentId,
        pageNumber: eTicket.pageNumber,
        pageId: eTicket.id,
        pageUri: eTicket.thumbUrl,
        documentUri: eTicket.documentUrl,
        documentType:
          ticketType === TicketType.ETicket
            ? DocumentType.ETicket
            : DocumentType.QRCode,
      });
      clearErrors(`ticketAssignments.${ticketIndex}`);
    },
    [clearErrors, setValue, ticketType]
  );

  const onUnmappingFromSeat = useCallback(
    (ticket: Ticket, ticketIndex: number) => {
      setValue(`ticketAssignments.${ticketIndex}`, {
        entityId: ticket.id,
        documentId: '',
        pageNumber: 0,
        pageId: '',
        pageUri: '',
        documentUri: '',
        documentType: null,
      });
    },
    [setValue]
  );

  return (
    <div className={styles.uploadETicketsSectionPanel}>
      <div className={styles.seatAssignmentSectionHeader}>
        <Content id={ContentId.Seats} />
      </div>
      <UploadETicketsSeatingContainer>
        {entityWithTickets.tickets.map((t, i) => {
          const assignment = watchTickets.find((wt) => wt.entityId === t.id);
          const eTicketPage =
            assignment &&
            eTicketPages[getETicketDocumentPageUniqueId(assignment)];

          const error = formState?.errors?.ticketAssignments?.[i]?.message;
          return (
            <UploadETicketsSeat key={t.id}>
              <PosDroppable<DocumentPage>
                onDrop={(args: { data: DocumentPage }) => {
                  if (eTicketPage) {
                    if (
                      eTicketPage.id === args.data.id &&
                      eTicketPage.documentId === args.data.documentId
                    ) {
                      // same stuff, dont' do anything
                      return;
                    } else {
                      // Unmap the seat first prior to setting a new one, so that the rendering can happen properly
                      onUnmappingFromSeat(t, i);
                    }
                  }
                  onDroppingToSeat(args.data, t, i);
                }}
              >
                {eTicketPage ? (
                  <PosDraggable
                    draggableData={eTicketPage}
                    className="draggable-seat"
                    previewDragData={{
                      name: (
                        <FormatContent
                          id={FormatContentId.PageNum}
                          params={`${eTicketPage.pageNumber + 1}`}
                        />
                      ),
                      source: eTicketPage.thumbUrl,
                    }}
                  >
                    <PosDraggableIndicator>
                      <div className={styles.mappedSeatingInfoContainer}>
                        <SeatingInfo
                          section={entityWithTickets.seating.section}
                          row={t.row}
                          seatFr={t.seat || (i + 1).toString()}
                          seatTo={''}
                        />
                        {!isFinalPreview && (
                          <CloseButton
                            onClick={() => onUnmappingFromSeat(t, i)}
                          />
                        )}
                      </div>
                      <ImageMagnifier
                        imageSrc={eTicketPage.thumbUrl}
                        magnifierSize={100}
                      />
                    </PosDraggableIndicator>
                  </PosDraggable>
                ) : assignment?.pageUri ? (
                  <PosDraggableIndicator>
                    <div className={styles.mappedSeatingInfoContainer}>
                      <SeatLabel>
                        <SeatingInfo
                          section={entityWithTickets.seating.section}
                          row={t.row}
                          seatFr={t.seat || (i + 1).toString()}
                          seatTo={''}
                        />
                      </SeatLabel>
                      {!isFinalPreview && (
                        <CloseButton
                          onClick={() => onUnmappingFromSeat(t, i)}
                        />
                      )}
                    </div>
                    <ImageMagnifier
                      imageSrc={assignment.pageUri}
                      magnifierSize={100}
                    />
                  </PosDraggableIndicator>
                ) : (
                  <>
                    <UploadETicketsSeatingPlaceholder>
                      <SeatingInfo
                        section={entityWithTickets.seating.section}
                        row={t.row}
                        seatFr={t.seat || (i + 1).toString()}
                        seatTo={''}
                      />
                      <Content id={ContentId.DragNAssignToSeats} />
                    </UploadETicketsSeatingPlaceholder>
                  </>
                )}
              </PosDroppable>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              <PosCustomDragLayer />
            </UploadETicketsSeat>
          );
        })}
      </UploadETicketsSeatingContainer>
    </div>
  );
}
