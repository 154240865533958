export type BorderRadiusOption =
  | 's'
  | 'm'
  | 'l'
  | 'xl'
  | 'xxl'
  | 'circle'
  | 'squared';
export const borderRadius = {
  s: '4px',
  m: '8px',
  l: '12px',
  xl: '16px',
  xxl: '24px',
  circle: '50%',
  squared: '0',
};
export const shadowConfig = {
  color: '#2f343b25', // neutral075, 25% percentage
};
export type ShadowPosition = 't' | 'c' | 'b';

export const shadow = (
  level: 0 | 1 | 2 | 3 | 4 | 5 = 0,
  shadowPosition: ShadowPosition = 'c'
): string => {
  const shadowBlur = level === 0 ? 0 : 2 ** level;
  let offset = level === 0 ? 0 : shadowBlur / 2;
  if (shadowPosition === 'c') {
    offset = 0;
  }
  if (shadowPosition === 't') {
    offset = 0 - offset;
  }
  return `0 ${offset}px ${shadowBlur}px 0 ${shadowConfig.color}`;
};
