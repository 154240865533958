import clsx from 'clsx';
import { ReactNode } from 'react';
import { Stack } from 'src/core/ui';
import { ParkingPassIcon } from 'src/svgs/Viagogo';
import { TopLevelCategory } from 'src/WebApiController';

import { EventDateDisplay } from './EventDateDisplay';
import * as styles from './EventInfo.css';
import {
  EventMappingStateDisplay,
  EventMappingStateDisplayProps,
} from './EventMappingStateDisplay';
import { EventNameDisplay } from './EventNameDisplay';
import { VenueNameDisplay } from './VenueNameDisplay';

export type EventInfoProps = {
  eventName?: ReactNode;
  /**
   * Portion of the event name to be displayed with less emphasis.
   */
  eventNameConnector?: ReactNode;
  eventSubName?: ReactNode;
  eventDateString?: string;
  /**
   * Optional end date that is used to calculate and show the number of dates after the date.
   *
   * eg. Feb 12 - 2:30pm, Sunday (3 days)
   */
  eventEndDateString?: string;
  /**
   * If `eventDate` is `undefined`, shows a `ToBeDeterminedIcon` instead of the event date if this
   * is set to `true`. Otherwise if `false`, event date will be fully omitted.
   */
  showEventDateTbd?: boolean;
  /**
   * Whether to show the year in the event date
   */
  showYear?: boolean;
  venueName?: ReactNode;
  /**
   * The location of the venue (eg. City, State).
   */
  venueLocation?: ReactNode;
  /**
   * Whether to show the mapping state (pending, rejected) of the event.
   */
  showMappingState?: boolean;
  /* Show only event name
   */
  eventNameOnly?: boolean;
  /* Show venue and date in smaller/compressed style */
  slimMode?: boolean;
  showParkingPassIcon?: boolean;

  eventGenre?: TopLevelCategory;
} & EventMappingStateDisplayProps;

/**
 * Component that shows event info.
 * @param props
 * @returns
 */
export function EventInfo({
  eventName,
  eventNameConnector,
  eventSubName,
  eventDateString,
  eventEndDateString,
  showEventDateTbd,
  showMappingState,
  mappingRejectionReasonId,
  mappingRejectionReasonDescriptionDefault,
  eventMappingState,
  showYear = false,
  venueName,
  venueLocation,
  eventNameOnly,
  slimMode,
  showParkingPassIcon = false,
  eventGenre,
}: EventInfoProps) {
  return (
    <Stack
      className={clsx(styles.root, {
        [styles.rootSlim]: slimMode,
      })}
      direction="column"
      gap="s"
    >
      <Stack direction="row" gap="m" justifyContent="spaceBetween">
        <EventNameDisplay
          eventName={eventName}
          eventNameConnector={eventNameConnector}
          eventSubName={eventSubName}
          slimMode={slimMode}
          boldSubName={eventGenre === TopLevelCategory.Sports}
        />
        {showParkingPassIcon && (
          <ParkingPassIcon className={styles.eventDisplayIcon} />
        )}
      </Stack>
      {!eventNameOnly &&
        (venueName ||
          venueLocation ||
          eventDateString ||
          eventEndDateString) && (
          <Stack
            className={styles.eventDetails}
            gap="l"
            direction={slimMode ? 'column' : undefined}
            flexWrap={slimMode ? undefined : 'wrap'}
            alignItems={slimMode ? 'start' : 'center'}
          >
            <VenueNameDisplay
              venueName={venueName}
              venueLocation={venueLocation}
              slimMode={slimMode}
            />
            <EventDateDisplay
              eventDateString={eventDateString}
              eventEndDateString={eventEndDateString}
              eventDateFormat={showYear ? 'MMM d, y' : undefined}
              showEventDateTbd={showEventDateTbd}
              slimMode={slimMode}
            />
          </Stack>
        )}
      {showMappingState && (
        <EventMappingStateDisplay
          eventMappingState={eventMappingState}
          mappingRejectionReasonId={mappingRejectionReasonId}
          mappingRejectionReasonDescriptionDefault={
            mappingRejectionReasonDescriptionDefault
          }
        />
      )}
    </Stack>
  );
}
