import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import { ExpandCollapsableContentButton } from 'src/components/common/CollapsableContent/ExpandCollapsableContentButton';
import * as styles from 'src/components/Events/EventListing/InventoryEventListing/InventoryFlattenedView.css';
import { collapsedRow } from 'src/components/Events/EventListing/InventoryEventListing/InventoryFlattenedView.css';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Stack } from 'src/core/ui';
import { EventsTable } from 'src/tables/EventsTable/EventsTable';
import { EventsTableColumnId } from 'src/utils/columns/events/eventsColumnUtils.types';
import { EventWithData } from 'src/WebApiController';

import { header } from './EventsTableWithInnerGrouping.css';

interface EventsTableWithInnerGroupingProps {
  before?: ReactNode;
  topListItemBefore?: ReactNode;
  events: EventWithData[];
  columnSettingButton?: ReactNode;
  tableHeadStyle?: CSSProperties;
  highlightEvent?: string; // Event UUID
}

const posTableHeadStyle: CSSProperties = {
  position: 'sticky',
  top: '0',
};

export const EventsTableWithInnerGrouping = ({
  events,
  before,
  topListItemBefore,
  columnSettingButton,
  highlightEvent,
}: EventsTableWithInnerGroupingProps) => {
  const { isCollapsedView, setIsCollapsedView } = useCollapsableViewContext();
  const { onScroll } = useCollapsableViewContext();

  return (
    <Stack direction="column" height="full" width="full">
      <div
        className={clsx({
          [styles.metricsAnimationUncollapsed]: !isCollapsedView,
          [styles.metricsAnimationCollapsed]: isCollapsedView,
        })}
      >
        {before}
      </div>
      {topListItemBefore}
      <Stack
        justifyContent="end"
        alignItems="center"
        className={clsx(
          {
            [collapsedRow]: isCollapsedView,
          },
          header
        )}
        gap="m"
      >
        {isCollapsedView && (
          <ExpandCollapsableContentButton
            onClick={() => setIsCollapsedView(false)}
          />
        )}
        {columnSettingButton}
      </Stack>
      <EventsTable
        events={events}
        failedToRetrieveData={false}
        useVirtuoso={true}
        usePagination={false}
        useDataGrouping={true}
        withOuterPadding={true}
        onScroll={onScroll}
        tableHeadStyle={posTableHeadStyle}
        highlightedId={highlightEvent}
        initState={{
          sorting: [
            {
              id: EventsTableColumnId.Date,
              desc: false,
            },
          ],
          grouping: [EventsTableColumnId.Date],
        }}
      />
    </Stack>
  );
};
