import { Content, FormatContent } from 'src/contexts/ContentContext';
import {
  ActionOutboxEntityType,
  ActivityLogValueChange,
} from 'src/WebApiController';

import { ACTIVITY_LOG_STATE_TO_CID } from './constants/contentIdMaps';
import { FormatContentId } from './constants/formatContentId';

export const getActivityLogChangeDisplay = (
  activity: string,
  v: ActivityLogValueChange,
  emptyContentPlaceholder = '',
  detailed = false
) => {
  const normalizedMessage = normalizeActivityLogMessage(v.message?.message);
  const newValueNormalized = normalizeActivityLogValue(
    v.newValue,
    emptyContentPlaceholder
  );

  return v.message ? (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <span>
        <Content id={ACTIVITY_LOG_STATE_TO_CID[v.message.state]} />
        {normalizedMessage?.length && `: ${normalizedMessage[0]}`}
      </span>
      {detailed &&
        (normalizedMessage?.length ?? 0) > 1 &&
        normalizedMessage!.slice(1).map((m, i) => <span key={i}>{m}</span>)}
    </div>
  ) : v.oldValue && v.newValue ? (
    <FormatContent
      key={activity}
      id={FormatContentId.ActivityLogChangeFromTo}
      params={[`${activity}`, `${v.oldValue}`, `${newValueNormalized}`]}
    />
  ) : v.oldValue ? (
    <FormatContent
      key={activity}
      id={FormatContentId.ActivityLogChangeFrom}
      params={[`${activity}`, `${v.oldValue}`]}
    />
  ) : activity ? (
    `${activity}: ${newValueNormalized}`
  ) : (
    newValueNormalized
  );
};

export const normalizeActivityLogValue = (
  value: string | null | undefined,
  emptyPlaceholder?: string
) => {
  let strippedValue = value ?? '';
  if (strippedValue.startsWith("'") && strippedValue.endsWith("'")) {
    strippedValue = strippedValue.substring(1, strippedValue.length - 1);
  }
  if (strippedValue.startsWith('"') && strippedValue.endsWith('"')) {
    strippedValue = strippedValue.substring(1, strippedValue.length - 1);
  }

  if (!strippedValue) {
    return emptyPlaceholder;
  }

  return value;
};

export const normalizeActivityLogMessage = (
  message?: string | null
): string[] | undefined => {
  if (!message) {
    return undefined;
  }

  try {
    if (message.startsWith(StubHubApiErrorFormat)) {
      message = message.replace(StubHubApiErrorFormat, '');
    }

    return normalizeErrorMessage(message);
  } catch (e) {
    console.warn(e);
    return message
      ?.split('.')
      ?.filter((s) => s.length)
      ?.map((s) => `${s.trim()}.`);
  }
};

export const StubHubApiErrorFormat =
  'An error occurred with this API request: ';

export type ActivityLogMessageDetails = {
  code?: string | null;
  message?: string | null;
  errors?: {
    eventIds: string[] | null | undefined;
    'seller_listing.seating.section': string[] | null | undefined;
    'seller_listing.seating.row': string[] | null | undefined;
    'seller_listing.seating.seat_from': string[] | null | undefined;
    'seller_listing.seating.seat_to': string[] | null | undefined;
    'seller_listing.number_of_tickets': string[] | null | undefined;
    'seller_listing.display_number_of_tickets': string[] | null | undefined;
    'seller_listing.split_type': string[] | null | undefined;
    'seller_listing.ticket_price.amt': string[] | null | undefined;
    'seller_listing.ticket_price.currency': string[] | null | undefined;
    'seller_listing.ticket_proceeds.amt': string[] | null | undefined;
    'seller_listing.ticket_proceeds.currency': string[] | null | undefined;
    'seller_listing.listing_note_ids': string[] | null | undefined;
    'seller_listing.face_value.amt': string[] | null | undefined;
    'seller_listing.face_value.currency': string[] | null | undefined;
    'seller_listing.ticket_location_address_id': string[] | null | undefined;
    'seller_listing.in_hand_at': string[] | null | undefined;
    'seller_listing.ticket_type': string[] | null | undefined;
    'seller_listing.reservation_details.code': string[] | null | undefined;
    'seller_listing.reservation_details.pin': string[] | null | undefined;
    'seller_listing.reservation_details.store': string[] | null | undefined;
    'seller_listing.reservation_details.expiry_date':
      | string[]
      | null
      | undefined;
    'seller_listing.reservation_details.release_date':
      | string[]
      | null
      | undefined;
    'seller_listing.face_value': string[] | null | undefined;
    'seller_listing.additional_information.primary_order_id':
      | string[]
      | null
      | undefined;
    'seller_listing.additional_information.home_or_away':
      | string[]
      | null
      | undefined;
  };
};

export const StubHubApiPropertiesToPosProperties: Record<string, string> = {
  'seating.section': 'Section',
  'seating.row': 'Row',
  'seating.seat_from': 'SeatFrom',
  'seating.seat_to': 'SeatTo',
  number_of_tickets: 'Quantity',
  display_number_of_tickets: 'Max Display Quantity',
  split_type: 'SplitType',
  'ticket_price.amt': 'All-in Price',
  'ticket_price.currency': 'Currency Code',
  'ticket_proceeds.amt': 'List Price',
  'ticket_proceeds.currency': 'Currency Code',
  listing_note_ids: 'Listing Notes',
  'face_value.amt': 'Face Value',
  'face_value.currency': 'Currency Code',
  ticket_location_address_id: 'Ticket Location Address Id',
  in_hand_at: 'In-hand At',
  ticket_type: 'Ticket Type',
  'reservation_details.code': 'Reservation Code',
  'reservation_details.pin': 'Reservation Pin',
  'reservation_details.store': 'Reservation Store',
  'reservation_details.expiry_date': 'Reservation Expiry Date',
  'reservation_details.release_date': 'Reservation Release Date',
  face_value: '',
  'additional_information.primary_order_id': 'Primary Order Id',
  'additional_information.home_or_away': 'Home or Away',
};

type Error = {
  message?: string;
  errors?: unknown;
};

const extractMessage = (error: Error): string[] | undefined => {
  if (error.message) {
    return normalizeErrorMessage(error.message);
  } else if (error.errors) {
    const result = normalizeErrorObject(error.errors);
    if (result) return result;

    // TODO - this would no longer be needed when Broadcast service returns all these in one giant "message"
    return Object.values(error.errors)
      .flatMap((m) => m)
      .filter((m) => m)
      .map((m) => {
        let ms = m!.toString();
        Object.keys(StubHubApiPropertiesToPosProperties).forEach((k) => {
          ms = ms!
            .toString()
            .replace(`'${k}'`, `'${StubHubApiPropertiesToPosProperties[k]}'`);
        });
        return ms;
      });
  }

  return undefined;
};

const normalizeErrorMessage = (message?: string) => {
  if (!message) {
    return undefined;
  }
  try {
    const errorObj = JSON.parse(message.trim());
    const result = normalizeErrorObject(errorObj);
    if (result) {
      return result;
    }
  } catch (e) {
    console.warn(`Unable to parse:  ${message}
${e}`);
  }
  return message
    ?.split('.')
    ?.filter((s) => s.length)
    ?.map((s) => `${s.trim()}.`);
};

const normalizeErrorObject = (errorObj: Error[] | Error) => {
  if (Array.isArray(errorObj)) {
    return errorObj
      .flatMap((e: Error) => extractMessage(e))
      .filter((s) => s)
      .map((s) => s!);
  } else if (typeof errorObj === 'object') {
    return extractMessage(errorObj);
  }

  return undefined;
};

export const entityTypeNumberToEntityTypeEnum = (
  entityType: number | null | undefined
): ActionOutboxEntityType | undefined => {
  switch (entityType) {
    case 1:
      return ActionOutboxEntityType.Sale;
    case 2:
      return ActionOutboxEntityType.Listing;
    case 3:
      return ActionOutboxEntityType.Purchase;
    case 4:
      return ActionOutboxEntityType.SellerSupportCase;
    case 5:
      return ActionOutboxEntityType.Document;
    case 6:
      return ActionOutboxEntityType.SellerEvent;
    case 7:
      return ActionOutboxEntityType.InboundEmail;
    default:
      return undefined;
  }
};
