import { DefaultTheme } from 'styled-components';

import { media } from './shared/base/media-queries';
import { getStyles, size } from './shared/base/typography';
import { Colors } from './shared/colors';
import { BASE_LIGHT_PALETTE } from './shared/palette/common-palette-light';
import { STUBHUB_LIGHT } from './shared/palette/stubhub-brand-palette';
import { borderRadius, shadow } from './shared/shape';
import { spacing } from './shared/spacing';

const purpleBranding = {
  primary100: Colors.indigo100,
  primary200: Colors.indigo200,
  primary700: Colors.indigo700,
  secondary075: Colors.fuchsia075,
  secondary100: Colors.fuchsia100,
};

export const BaseTheme: DefaultTheme = {
  name: 'base',
  rounded: true,
  floatingInputLabel: true,
  filledInLoading: true,
  logoPath:
    '/images/Assets/Icons/bfx/stubhub-logo-merch-purple-mweb.440b3765.svg',
  colors: {
    primary025: Colors.indigo025,
    primary050: Colors.indigo050,
    primary060: Colors.indigo060,
    primary070: Colors.indigo070,
    primary075: Colors.indigo075,
    primary100: purpleBranding.primary100,
    primary150: purpleBranding.primary100, // need to be retired
    primary200: purpleBranding.primary200,
    primary300: Colors.indigo300,
    primary400: Colors.indigo300, // need to be retired
    primary500: Colors.indigo500,
    primary600: Colors.indigo600,
    primary700: Colors.indigo700,
    secondary: Colors.gray100, // we need to retire secondaryxx in phase3 and use newSecondary instead
    secondary100: Colors.gray100,
    secondary200: Colors.gray200,
    secondary300: Colors.gray300,
    secondary400: Colors.gray400,
    secondary450: Colors.gray450,
    secondary500: Colors.gray500,
    secondary600: Colors.gray600,
    secondary700: Colors.gray700,
    secondary800: Colors.gray800,
    secondary900: Colors.gray900,
    secondary1000: Colors.gray1000,
    secondary1200: Colors.gray250,
    newSecondary075: purpleBranding.secondary075, // need to rename to secondaryxx after we retire the above secondaryxx
    newSecondary100: purpleBranding.secondary100,
    newSecondary200: Colors.fuchsia200,
    newSecondary500: Colors.fuchsia500,
    newSecondary600: Colors.fuchsia600,
    newSecondary700: Colors.fuchsia700,
    accent075: Colors.aqua075,
    accent100: Colors.aqua100,
    accent200: Colors.aqua200,
    accent500: Colors.aqua500,
    accent600: Colors.aqua600,
    accent700: Colors.aqua700,
    // Please only use functional colors defined below, exclude the 'need to be retired'
    negative075: Colors.negative075,
    negative100: Colors.negative100,
    negative200: Colors.negative200,
    negative500: Colors.negative500,
    success075: Colors.success075,
    success100: Colors.success100,
    success500: Colors.success500,
    warning075: Colors.warning075,
    warning100: Colors.warning100,
    warning500: Colors.warning500,
    info075: Colors.info075,
    info100: Colors.info100,
    info200: Colors.info200,
    info300: Colors.info200, // need to be retired
    info500: Colors.info500,
    info1000: Colors.info075, // need to be retired

    textPrimary: Colors.neutral100, // this is the default text color
    textPrimaryHover: Colors.neutral200,
    textSecondary: purpleBranding.secondary100, // this is the fuchsia
    textBrand: purpleBranding.primary100, // this is primary branding color for each theme
    textBrandHover: purpleBranding.primary200,
    textDisabled: Colors.neutral300,
    textInverted: Colors.neutral1000,
    textStrong: Colors.neutral050,
    textDarker: Colors.neutral075,
    textDarkest: Colors.neutral025,
    textHighlight: purpleBranding.secondary100, // this is fuchsia
    textLinkPrimary: purpleBranding.primary200,
    textLinkActive: Colors.indigo500,
    textLinkHover: purpleBranding.primary100,
    textLinkVisited: Colors.indigo300,
    textLinksGroupPrimary: Colors.neutral100,
    textOnBrandBg: Colors.neutral1000, // this is text with brand color background, white for purple, black for vgg
    textOnBrandBgLink: Colors.indigo600, // this is navi with brand color background, white for purple, black for vgg
    textOnBrandBgHover: Colors.neutral1000,
    textNavPrimary: Colors.neutral100,
    textNavActive: Colors.indigo500,
    textNavHover: purpleBranding.primary100,
    textNavVisited: Colors.indigo300,
    textNegative: Colors.negative100,
    textSuccess: Colors.success100,
    textInfo: Colors.info100,
    textWarning: Colors.warning100,
    textLogoVggGray: Colors.gray200,
    textLogoVggGreen: Colors.green100,

    backgroundNegative: Colors.negative500,
    backgroundNegativeDark: Colors.negative100,
    backgroundNegativeHover: Colors.negative200,
    backgroundSuccess: Colors.success500,
    backgroundWarning: Colors.warning500,
    backgroundInfo: Colors.info500,
    backgroundPrimary: Colors.neutral1000, // this is default background color which is white
    backgroundSecondary: Colors.neutral600,
    backgroundInteraction: Colors.neutral600,
    backgroundHover: Colors.neutral200,
    backgroundDark: Colors.neutral075,
    backgroundNeutral: Colors.neutral100,
    backgroundDisabled: Colors.neutral300,
    backgroundActiveDisabled: Colors.neutral700,
    backgroundAccent100: Colors.aqua100, // this is background color of date picker
    backgroundAccent700: Colors.aqua700, // this is lighter background of date picker
    overlayPrimary: Colors.neutral000, // need to use with opacity 25%
    backgroundBrandActive: purpleBranding.primary100, // this is brand color as background
    backgroundBrandHover: purpleBranding.primary200,
    backgroundBrandLight: purpleBranding.primary700,
    iconPrimary: Colors.neutral100,
    iconHover: Colors.neutral200,
    iconDisabled: Colors.neutral300,

    borderPrimary: Colors.neutral400, // this is default border color
    borderActive: purpleBranding.primary100, // this is primary brand color as border color
    borderInverted: Colors.neutral1000, // white
    borderDark: Colors.neutral075,
    borderNeutral: Colors.neutral100,
    borderActiveDisabled: Colors.neutral300,
    borderDisabled: Colors.neutral500,
    borderNegative: Colors.negative100, // this is for error border

    link100: Colors.darkBlue250, // need to be retired
    highlight100: Colors.aqua100, // need to be retired
    highlight200: Colors.aqua200, // need to be retired
    highlight300: Colors.aqua200, // need to be retired
    highlight400: Colors.aqua200, // need to be retired
    highlight450: Colors.aqua500, // need to be retired
    highlight500: Colors.aqua500, // need to be retired
    highlight600: Colors.aqua500, // need to be retired
    highlight700: Colors.info075, // need to be retired
    urgency100: Colors.negative100, // need to be retired
    urgency200: Colors.negative200, // need to be retired
    urgency250: Colors.negative200, // need to be retired
    urgency300: Colors.negative200, // need to be retired
    urgency500: Colors.negative500, // need to be retired
    warning: Colors.warning100, // need to be retired
    danger200: Colors.warning100, // need to be retired
    error100: Colors.negative100, // need to be retired
    white: Colors.neutral1000, // need to be retired
    black: Colors.neutral000, // need to be retired
  },
  palette: {
    ...BASE_LIGHT_PALETTE,
    ...STUBHUB_LIGHT,
  },
  typography: {
    size,
    getStyles,
  },
  spacing,
  borderRadius,
  shadow,
  media,
};
