import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const ProcessingFulfillmentIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 24">
      <path
        d="M6.5 15.5H0.5V4.5H14.5V6.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 4.5H0.5L4.5 0.5H10.5L14.5 4.5Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 0.5V4.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 23.5C20.366 23.5 23.5 20.366 23.5 16.5C23.5 12.634 20.366 9.5 16.5 9.5C12.634 9.5 9.5 12.634 9.5 16.5C9.5 20.366 12.634 23.5 16.5 23.5Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 17.5H16.5V12.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
