import { formatInTimeZone } from 'date-fns-tz';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { DivWithTitle } from 'src/core/ui/DivWithTitle';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './AdCampaignsTableColumnsConfig.css';

export function AdCampaignsDateCell({ date }: { date: Date | null }) {
  const { timeZone } = useSiteTimezoneContext();
  const formattedDate = date ? (
    <>
      <strong>{formatInTimeZone(date, timeZone, 'LLL d, yyyy')}</strong>
      <span>{formatInTimeZone(date, timeZone, 'EEEE')}</span>
    </>
  ) : (
    <Content id={ContentId.NA} />
  );
  return (
    <DivWithTitle className={styles.styledCell}>{formattedDate}</DivWithTitle>
  );
}
