/* eslint-disable @typescript-eslint/no-explicit-any */
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { createContext, useContext } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useToggle } from 'react-use';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { Content } from 'src/contexts/ContentContext';
import { useEventHubContext } from 'src/contexts/EventHubContext';
import { TabItem } from 'src/core/ui/TabPanel/TabPanel.types';
import { ContentId } from 'src/utils/constants/contentId';
import {
  Event,
  ListingDetails,
  ListingDetailsAutoPricingSectionUpdates,
  ListingDetailsBroadcastSectionUpdates,
  ListingDetailsPricingSectionUpdates,
  ListingDetailsTagsSectionUpdates,
  ListingDetailsTicketSectionUpdates,
} from 'src/WebApiController';

import { ListingTab } from './components/listingDetailsUtils';
import { useHasListingPricePermissions } from './components/useHasListingPricePermissions';
import { useListingDetailsMethods } from './ListingDetailsModalV2.hooks';
import { ListingDetailsAutoPricingForm } from './ListingForms/AutoPricingForm';
import { useAutoPricingSidePanelContext } from './ListingForms/AutoPricingSidePanel';
import { ListingDetailsBroadcastForm } from './ListingForms/BroadcastForm';
import { ListingDetailsPricingForm } from './ListingForms/PricingForm';
import { ListingDetailsPurchaseForm } from './ListingForms/PurchaseForm';
import { ListingDetailsSalesForm } from './ListingForms/SalesForm';
import { ListingDetailsTagsForm } from './ListingForms/TagsForm';
import { ListingDetailsTicketsForm } from './ListingForms/TicketsForm';

type ModalMethods = {
  broadcast: UseFormReturn<
    ListingDetailsBroadcastSectionUpdates,
    any,
    undefined
  >;
  tickets: UseFormReturn<ListingDetailsTicketSectionUpdates, any, undefined>;
  tags: UseFormReturn<ListingDetailsTagsSectionUpdates, any, undefined>;
  pricing: UseFormReturn<ListingDetailsPricingSectionUpdates, any, undefined>;
  autoPricing: UseFormReturn<
    ListingDetailsAutoPricingSectionUpdates,
    any,
    undefined
  >;
};

type ListingDetailsModalContextType = {
  event: Event | null | undefined;
  listing: ListingDetails | null | undefined;
  tabs: TabItem[];
  isLoading: boolean;
  hasChanges: boolean;
  activeTabIndex: number;
  setLoading: (nextValue: boolean) => void;
  onTabChange: (v: ListingTab) => void;
  setActiveTabIndex: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: () => void;
  onReset: (tabIndex: number) => void;
  methods: ModalMethods;
};

const ListingDetailsModalContext =
  createContext<ListingDetailsModalContextType>({
    event: null,
    listing: null,
    tabs: [],
    isLoading: false,
    hasChanges: false,
    activeTabIndex: 0,
    setLoading: (_: boolean) => void 0,
    onTabChange: (_: ListingTab) => void 0,
    setActiveTabIndex: (_) => void 0,
    onSubmit: () => void 0,
    onReset: (_: number) => void 0,
    methods: {} as ModalMethods,
  });

export const ListingDetailsModalContextProvider = ({
  children,
}: PropsWithChildren) => {
  const {
    event,
    posEntity: listing,
    setActivePosEntity,
  } = useActivePosEntityContext<ListingDetails>();
  const { onUpdateListing } = useEventHubContext();

  const { onShowContent, onCloseSidePanel } = useAutoPricingSidePanelContext();

  const [isLoading, setLoading] = useToggle(false);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  const onSaveCompleted = useCallback(
    (success = true) => {
      if (listing?.id && success) {
        setActivePosEntity(listing.id, listing?.idOnMkp, true);
        onUpdateListing(listing.id);
      }
      setLoading(false);
    },
    [
      listing?.id,
      listing?.idOnMkp,
      onUpdateListing,
      setActivePosEntity,
      setLoading,
    ]
  );

  const onSaveStart = useCallback(() => {
    setLoading(true);
  }, [setLoading]);

  const { updated, methods, onSubmits, onResets } = useListingDetailsMethods(
    listing,
    onSaveStart,
    onSaveCompleted
  );
  const { canSetPrice, canAutoPrice } = useHasListingPricePermissions(listing);

  const tabs: TabItem[] = useMemo(() => {
    if (listing == null) {
      return [];
    }
    const listingTabs = [
      {
        value: ListingTab.Broadcast,
        title: <Content id={ContentId.Broadcast} />,
        content: <ListingDetailsBroadcastForm listing={listing} />,
      },
      {
        value: ListingTab.Tickets,
        title: <Content id={ContentId.Tickets} />,
        content: <ListingDetailsTicketsForm event={event} listing={listing} />,
      },
    ];

    if (canSetPrice) {
      listingTabs.push({
        value: ListingTab.Pricing,
        title: <Content id={ContentId.Pricing} />,
        content: <ListingDetailsPricingForm listing={listing} />,
      });
    }

    if (canAutoPrice) {
      listingTabs.push({
        value: ListingTab.AutoPricing,
        title: <Content id={ContentId.Autopricing} />,
        content: <ListingDetailsAutoPricingForm listing={listing} />,
      });
    }

    listingTabs.push(
      ...[
        {
          value: ListingTab.Sales,
          title: <Content id={ContentId.Sales} />,
          content: <ListingDetailsSalesForm listing={listing} />,
        },
        {
          value: ListingTab.Purchase,
          title: <Content id={ContentId.Purchase} />,
          content: <ListingDetailsPurchaseForm listing={listing} />,
        },
        {
          value: ListingTab.Tags,
          title: <Content id={ContentId.Tags} />,
          content: <ListingDetailsTagsForm listing={listing} />,
        },
      ]
    );
    return listingTabs;
  }, [canAutoPrice, canSetPrice, event, listing]);

  const onReset = useCallback(
    (tabIndex: number) => {
      if (tabIndex >= tabs.length) {
        return;
      }
      switch (tabs[tabIndex].value) {
        case ListingTab.Broadcast:
          onResets.broadcast();
          break;
        case ListingTab.Tickets:
          onResets.tickets();
          break;
        case ListingTab.Pricing:
          onResets.pricing();
          break;
        case ListingTab.AutoPricing:
          onResets.autoPricing();
          break;
        case ListingTab.Tags:
          onResets.tags();
          break;
        default:
          break;
      }
    },
    [onResets, tabs]
  );

  const onTabChange = useCallback(
    (value: ListingTab) => {
      if (value === ListingTab.AutoPricing) {
        onShowContent('comparables');
      } else {
        onCloseSidePanel();
      }

      const tabIndex = tabs.findIndex((t) => t.value === value);
      if (tabIndex !== activeTabIndex) {
        onReset(activeTabIndex);
        setActiveTabIndex(tabIndex ?? 0);
      }
    },
    [tabs, activeTabIndex, onShowContent, onCloseSidePanel, onReset]
  );

  const onSubmit = useCallback(() => {
    if (activeTabIndex >= tabs.length) {
      return;
    }
    switch (tabs[activeTabIndex].value) {
      case ListingTab.Broadcast:
        onSubmits.broadcast();
        break;
      case ListingTab.Tickets:
        onSubmits.tickets();
        break;
      case ListingTab.Pricing:
        onSubmits.pricing();
        break;
      case ListingTab.AutoPricing:
        onSubmits.autoPricing();
        break;
      case ListingTab.Tags:
        onSubmits.tags();
        break;
      default:
        break;
    }
  }, [activeTabIndex, onSubmits, tabs]);

  const hasChanges = useMemo(() => {
    if (activeTabIndex >= tabs.length) {
      return false;
    }
    switch (tabs[activeTabIndex].value) {
      case ListingTab.Broadcast:
        return updated.broadcast;
      case ListingTab.Tickets:
        return updated.tickets;
      case ListingTab.Pricing:
        return updated.pricing;
      case ListingTab.AutoPricing:
        return updated.autoPricing;
      case ListingTab.Tags:
        return updated.tags;
      default:
        return false;
    }
  }, [activeTabIndex, tabs, updated]);

  return (
    <ListingDetailsModalContext.Provider
      value={{
        event,
        listing,
        tabs,
        isLoading,
        activeTabIndex,
        hasChanges,
        setLoading,
        setActiveTabIndex,
        onTabChange,
        onSubmit,
        onReset,
        methods,
      }}
    >
      {children}
    </ListingDetailsModalContext.Provider>
  );
};

export const useListingDetailsContext = () =>
  useContext(ListingDetailsModalContext);
