import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function UnbroadcastIcon({
  noOutline,
  ...props
}: Omit<PosIconProps, 'viewBox'> & { noOutline?: boolean }) {
  return (
    <SvgWrapper {...props} viewBox="0 0 20 20" fill="none">
      {!noOutline && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 18.6957C14.8024 18.6957 18.6957 14.8024 18.6957 10C18.6957 5.19752 14.8024 1.30435 10 1.30435C5.19752 1.30435 1.30435 5.19752 1.30435 10C1.30435 14.8024 5.19752 18.6957 10 18.6957ZM10 20C15.5229 20 20 15.5229 20 10C20 4.47716 15.5229 0 10 0C4.47716 0 0 4.47716 0 10C0 15.5229 4.47716 20 10 20Z"
          fill="#9471D7"
        />
      )}
      <path
        d="M8.70302 13.4347C9.40284 12.822 9.47354 11.7581 8.86093 11.0583C8.24831 10.3584 7.18435 10.2877 6.48452 10.9003C5.78469 11.513 5.71399 12.577 6.32661 13.2768C6.93924 13.9766 8.00318 14.0473 8.70302 13.4347Z"
        fill="#9471D7"
        stroke="#9471D7"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.0752 12.4649C12.1143 11.8763 12.0371 11.2859 11.8481 10.7273C11.659 10.1687 11.3617 9.6527 10.9733 9.20896C10.5849 8.76522 10.1128 8.40231 9.58407 8.14099C9.05529 7.87968 8.48032 7.72506 7.89185 7.68597"
        stroke="#9471D7"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14.8754 12.6509C15.0036 10.7198 14.3595 8.81687 13.0848 7.36063C11.81 5.90441 10.0089 5.01418 8.07788 4.88578"
        stroke="#9471D7"
        strokeWidth="1.7"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </SvgWrapper>
  );
}
