import { isEqual } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button } from 'src/core/ui';
import { useEventTags } from 'src/hooks/api/useEventTags';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { DetailSection } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { tagHasChanges } from 'src/utils/tagUtils';
import { ActionOutboxEntityType, Permission, Tag } from 'src/WebApiController';

import { CancelButton } from '../../../Buttons';
import { TagsFormBodyV2 } from '../../../TagsFormBody';
import * as styles from '../InventoryEventPage.css';

type TagsUpdateInput = {
  tags: Tag[];
};

export const TagsSection = ({
  eventId,
  disabled,
}: {
  eventId: string;
  disabled?: boolean;
}) => {
  const canCreateTage = useUserHasAnyOfPermissions(
    Permission.Events_AddTagType
  );
  const canEditTags = useUserHasAnyOfPermissions(Permission.Events_UpdateTags);

  const { setValue, watch, handleSubmit } = useForm<TagsUpdateInput>();
  const tags = watch('tags') ?? [];
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    tags: existingTags,
    updateTags,
    isLoading,
  } = useEventTags(eventId, disabled);

  const { tagsMetadata } = useTagsForEntityType(
    ActionOutboxEntityType.SellerEvent
  );

  const onSubmitHandler = useCallback(
    async (data: TagsUpdateInput) => {
      setIsSubmitting(true);
      await updateTags(data.tags, eventId);
      setIsSubmitting(false);
    },
    [eventId, updateTags]
  );

  useEffect(() => {
    if (existingTags && !isEqual(tags, existingTags)) {
      setValue('tags', existingTags);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingTags]);

  const hasChanges = tagHasChanges(tags, existingTags, tagsMetadata ?? []);

  if (isLoading) {
    return <PosSpinner />;
  }

  return (
    <div className={styles.tableSection} style={{ maxWidth: '768px' }}>
      <DetailSection name={<Content id={ContentId.Tags} />}>
        <TagsFormBodyV2
          canCreateTagType={canCreateTage}
          canEditTags={canEditTags}
          tagsCurrentEntity={tags}
          onTagsUpdate={(tags) => {
            setValue('tags', tags);
          }}
          disabled={disabled || isSubmitting}
          entityType={ActionOutboxEntityType.SellerEvent}
        />

        {hasChanges && (
          <div className={styles.formFooter}>
            <Button
              disabled={isSubmitting || disabled}
              onClick={() => {
                handleSubmit(onSubmitHandler)();
              }}
            >
              <Content id={ContentId.Save} />
            </Button>

            <CancelButton
              onClick={() => setValue('tags', existingTags ?? [])}
              disabled={isSubmitting || disabled}
              textContentId={ContentId.Cancel}
            />
          </div>
        )}
      </DetailSection>
    </div>
  );
};
