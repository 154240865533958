import { useCallback, useState } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { DownloadIcon, ProcessingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { downloadFileFromBlob } from 'src/utils/fileUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SaleClient } from 'src/WebApiController';

export const LaunchDownloadUserDocuments = ({
  saleId,
  variant,
  iconOnlyMode,
  disabled,
}: {
  saleId: number;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const [isLoading, setIsLoading] = useState(false);

  const onDownload = useCallback(async () => {
    setIsLoading(true);
    return await tryInvokeApi(
      async () => {
        const result = await new SaleClient(
          activeAccountWebClientConfig
        ).downloadMergedUserDocumentsPdf(saleId);
        if (result.data) {
          downloadFileFromBlob(result.fileName ?? 'tickets.pdf', result.data);
        }
      },
      (error) => {
        showErrorDialog('ListingClient.downloadMergedUserDocumentsPdf', error);
      },
      () => setIsLoading(false)
    );
  }, [activeAccountWebClientConfig, saleId, showErrorDialog]);

  const onClickHandler = useCallback(
    (e: React.MouseEvent) => {
      onDownload();
    },
    [onDownload]
  );

  const iconFill =
    iconOnlyMode || (variant && variant !== 'regular')
      ? vars.color.textBrand
      : vars.color.textInverted;

  return (
    <ButtonWithIcon
      disabled={disabled || isLoading}
      variant={variant}
      iconOnlyMode={iconOnlyMode}
      onClick={onClickHandler}
      textContentId={ContentId.DownloadETickets}
      icon={
        isLoading ? (
          <RotatingWrapper>
            <ProcessingIcon fill={iconFill} />
          </RotatingWrapper>
        ) : (
          <DownloadIcon
            withHoverEffect
            fill={iconFill}
            size={vars.iconSize.m}
          />
        )
      }
    />
  );
};
