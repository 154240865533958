import { useMeasure } from 'react-use';
import { ContentId } from 'src/utils/constants/contentId';

import { DashboardWidgetTile } from '../DashboardWidgetTile/DashboardWidgetTile';
import {
  TimeSeriesLineChart,
  TimeSeriesLineChartProps,
} from '../TimeSeriesLineChart';

type EventSupplyWidgetProps = {
  title: ContentId;
} & TimeSeriesLineChartProps;

export const EventSupplyWidget: React.FC<EventSupplyWidgetProps> = ({
  title,
  ...rest
}) => {
  const [measureRef, container] = useMeasure<HTMLDivElement>();
  return (
    <DashboardWidgetTile ref={measureRef} titleContentId={title} type="chart">
      <TimeSeriesLineChart {...rest} container={container} />
    </DashboardWidgetTile>
  );
};
