import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function BackIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        fill="inherit"
        d="M17.663 19.502L15.331 22 6 12.007 6.007 12 6 11.993 15.33 2l2.333 2.498L10.66 12z"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
