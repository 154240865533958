import { Fragment, useMemo } from 'react';
import { LinkAndCopy } from 'src/components/common/LinkAndCopy';
import { Content } from 'src/contexts/ContentContext';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { UseGetUserInfo, useGetUserInfos } from 'src/hooks/userGetUserInfo';
import { Detail, DetailGroup, SectionContent } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { getPurchaseOrderRelativeUrl } from 'src/utils/purchaseUtils';
import { formatSeatDisplay } from 'src/utils/ticketUtils';
import { SaleDetails } from 'src/WebApiController';

interface SeatsSoldAsProps {
  sale: SaleDetails;
}

const NUM_OF_COLUMNS = 8;

export const TicketsAllocated = ({ sale }: SeatsSoldAsProps) => {
  const { ticketGroupInfos } = sale;

  const buyerUserIds = useMemo<string[]>(
    () =>
      ticketGroupInfos
        .filter((tkgInfo) => !!tkgInfo.buyerUserId)
        .map((tkgInfo) => tkgInfo.buyerUserId) as string[],
    [ticketGroupInfos]
  );

  const usersInfoQuery = useGetUserInfos(buyerUserIds);

  const usersMap: Record<string, UseGetUserInfo> = usersInfoQuery.data || {};

  if (!sale.originatedFromListingId) {
    return null;
  }

  return (
    <SectionContent numOfColumns={NUM_OF_COLUMNS}>
      {ticketGroupInfos.map((tkInfo, index) => {
        const { seating } = tkInfo;
        const isFirstRow = index === 0;

        return (
          <Fragment key={tkInfo.ticketGroupId}>
            <DetailGroup>
              <Detail
                isStrong
                label={isFirstRow ? <Content id={ContentId.Section} /> : ''}
                detail={seating.section}
              />
            </DetailGroup>

            <DetailGroup>
              <Detail
                label={isFirstRow ? <Content id={ContentId.Row} /> : ''}
                detail={seating.row ?? '-'}
              />
            </DetailGroup>

            <DetailGroup>
              <Detail
                isStrong
                label={isFirstRow ? <Content id={ContentId.Seats} /> : ''}
                detail={
                  <>{formatSeatDisplay(seating.seatFr, seating.seatTo)}</>
                }
              />
            </DetailGroup>

            <DetailGroup>
              <Detail
                isStrong
                label={isFirstRow ? <Content id={ContentId.Quantity} /> : ''}
                detail={<strong>x {tkInfo.ticketCount}</strong>}
              />
            </DetailGroup>
            <DetailGroup>
              <Detail
                label={isFirstRow ? <Content id={ContentId.Purchase} /> : ''}
                detail={
                  <LinkAndCopy
                    relativeUrl={getPurchaseOrderRelativeUrl(
                      tkInfo.purchaseOrderId
                    )}
                    linkChildren={tkInfo.vendorOrderId}
                    valueToCopy={tkInfo.vendorOrderId ?? tkInfo.purchaseOrderId}
                  />
                }
              />
            </DetailGroup>

            <DetailGroup>
              <Detail
                isStrong
                label={
                  isFirstRow ? <Content id={ContentId.VendorAccount} /> : ''
                }
                detail={tkInfo.vendorAccount?.encryptedEmailAddress}
              />
            </DetailGroup>

            <DetailGroup>
              <Detail
                isStrong
                label={isFirstRow ? <Content id={ContentId.PurchasedBy} /> : ''}
                detail={
                  (tkInfo.buyerUserId &&
                    usersMap?.[tkInfo.buyerUserId]?.name) || (
                    <ContentPlaceholder
                      width={`(100/${NUM_OF_COLUMNS})px`}
                      height={'28px'}
                    />
                  )
                }
              />
            </DetailGroup>

            <DetailGroup>
              <Detail
                isStrong
                label={isFirstRow ? <Content id={ContentId.Notes} /> : ''}
                detail={tkInfo.internalNotes ?? '-'}
              />
            </DetailGroup>
          </Fragment>
        );
      })}
    </SectionContent>
  );
};
