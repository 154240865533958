import { isEqual } from 'lodash-es';
import { SellerAccountEventSetting } from 'src/WebApiController';

export const hasNoChangeOrIsDeleteOnly = (
  sellerAccountEventSettings: SellerAccountEventSetting[] | null | undefined,
  formValuesettings: SellerAccountEventSetting[] | null | undefined
): boolean => {
  // Basically return true if every setting in the formValuesettings is contained within the sellerAccountEventSettings
  if (!formValuesettings?.length) {
    return true;
  }

  for (const formValueSetting of formValuesettings) {
    const matchingSetting = sellerAccountEventSettings?.find(
      (setting) =>
        setting.vendorId === formValueSetting.vendorId &&
        setting.delivType === formValueSetting.delivType
    );

    if (!matchingSetting) {
      return false;
    }

    if (!isEqual(matchingSetting, formValueSetting)) {
      return false;
    }
  }

  return true;
};
