import { ComponentProps, PropsWithChildren, useContext } from 'react';
import { ModalContext } from 'src/contexts/ModalContext';
import { Button } from 'src/core/ui';

export function CloseModalButton({
  children,
  ...props
}: PropsWithChildren<ComponentProps<typeof Button>>) {
  const { closeModal } = useContext(ModalContext);

  return (
    <Button variant={'regular'} onClick={closeModal} {...props}>
      {children}
    </Button>
  );
}
