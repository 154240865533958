import { isEqual } from 'lodash-es';
import { useState } from 'react';
import { ActivityLogFilterQuery } from 'src/components/ActivityLog/ActivityLogFilter';
import { EventSearchFilterQuery } from 'src/components/Events/EventSearch';
import {
  FilterDialogV2,
  FilterDialogV2Props,
} from 'src/components/FilterToolbar/FilterDialogV2/FilterDialogV2';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ToolbarButton } from 'src/core/POS/ToolbarButton';
import { vars } from 'src/core/themes';
import { Popover } from 'src/core/ui';
import { ScrollableToolbar } from 'src/core/ui/ScrollableToolbar';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { FilterIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  EmptyListingQuery,
  EmptyPurchaseQuery,
  EmptySaleQuery,
} from 'src/utils/eventQueryUtils';
import { ReportFilterEditability } from 'src/utils/reportsFilterUtils';
import { hasAnyOfPermissions } from 'src/utils/userPermissionUtils';
import { Permission } from 'src/WebApiController';

import {
  CustomFilterButton,
  CustomFilterButtonProps,
} from './CustomFilterButton';
import { CustomFilterDialog } from './CustomFilterButton/CustomFilterDialog';
import { FilterDialog } from './FilterDialog';
import * as styles from './FilterToolbar.css';
import { FilterCount, FilterToolbarContainer } from './FilterToolbar.styled';
import { MobileFilterToolbar } from './MobileFilterToolbar';
import { QuickFilterToolbarItem } from './QuickFilterToolbarItem';

export type FilterToolbarGroup = {
  titleContentId?: ContentId;
  items: FilterToolbarItem[];
  type?: string;
  requiredPermissions?: Permission[];
};

export type FilterToolbarItemId =
  | keyof typeof EmptyListingQuery
  | keyof typeof EmptySaleQuery
  | keyof typeof EmptyPurchaseQuery
  | keyof ActivityLogFilterQuery
  | keyof EventSearchFilterQuery;

export type FilterToolbarItem = {
  filterId: FilterToolbarItemId;
  labelContentId: ContentId;
  filterItem: JSX.Element;
  requiredPermissions?: Permission[];

  reportFilterEditability?: ReportFilterEditability;

  /**
   * Some filters, use more than one key in the query, and not all at the
   * same time, so in other to know if the filter is present or not in the
   * filters modal, we check if any of this is used.
   */
  filterQueryKeys: FilterToolbarItemId[]; // Query keys used in the filter
};

export type FilterToolbarProps = {
  filters: FilterToolbarGroup[];
  quickFilters?: QuickFilterToolbarItem[];
  /**
   * Whether the "All" quick filter is currently selected.
   */
  isAllSelected?: boolean;
  onResetAll: () => void;
  resetTempQuery?: (keys?: FilterToolbarItemId[]) => void; // TODO: make this not optional
  tempQuery?: Partial<Record<FilterToolbarItemId, unknown>>;
  onSubmitFilter?: () => void;
  filterAppliedCounts: number;
  filterAppliedIds?: FilterToolbarItemId[];
  mandatoryFiltersToShow: FilterToolbarItemId[];
  allContentId?: ContentId;

  showAllButton?: boolean;
  showCustomFilterButton?: boolean;
  onCreateCustomQuickFilter?: (filter: { name: string }) => void;
  onUpdateCustomQuickFilter?: (
    id: string,
    updatedFilter: { name: string }
  ) => void;
  filterAppliedMessage?: React.ReactNode;

  //TODO: Remove this prop after implementing the filters across the project
  useNewFilterUX?: boolean;
} & Pick<
  CustomFilterButtonProps,
  'onToggleQuickFilter' | 'onMoveQuickFilter' | 'onDeleteCustomQuickFilter'
> &
  Pick<
    FilterDialogV2Props,
    | 'onEditabilityChange'
    | 'embeddedDisplayOnly'
    | 'performerVenueFilter'
    | 'listView'
  >;

export const EMPTY_QUICK_FILTERS = [];

export function FilterToolbar({
  filters,
  quickFilters,
  isAllSelected,
  allContentId,
  onResetAll,
  onSubmitFilter,
  filterAppliedCounts,
  showAllButton = true,
  showCustomFilterButton = true,
  onCreateCustomQuickFilter,
  onUpdateCustomQuickFilter,
  filterAppliedIds,
  resetTempQuery,
  tempQuery,
  mandatoryFiltersToShow,
  useNewFilterUX = false,
  embeddedDisplayOnly = false,
  performerVenueFilter,
  filterAppliedMessage,
  onEditabilityChange,
  listView,
  ...customFilterButtonProps
}: FilterToolbarProps) {
  const customFilterDialog = useBasicDialog();
  // the quick filter currently being edited
  const [currQuickFilterId, setCurrQuickFilterId] = useState<string | null>(
    null
  );
  const currQuickFilter = quickFilters?.find(
    ({ id }) => id === currQuickFilterId
  );

  const { loginContext } = useAppContext();
  const filteredFilters = filters.filter(
    (f) =>
      f.requiredPermissions == null ||
      hasAnyOfPermissions(loginContext?.user, f.requiredPermissions)
  );

  const isMobile = useMatchMedia('mobile');
  const allFilter = showAllButton
    ? [
        {
          id: 'all',
          name: <Content id={allContentId ?? ContentId.All} />,
          isHidden: false,
          isSelected: isAllSelected,
          onClick: onResetAll,
          isCustomFilter: false,
        },
      ]
    : [];

  const mergedFilters = quickFilters
    ? [...allFilter, ...quickFilters]
    : allFilter;

  const { initialQuery } = useFilterQueryContext();

  const hasFiltersApplied = !isEqual(tempQuery, initialQuery);

  const onSaveQuickFilter = showCustomFilterButton
    ? () => {
        customFilterDialog.launchDialog();
      }
    : undefined;

  if (embeddedDisplayOnly || listView) {
    return (
      <FilterDialogV2
        filters={filteredFilters}
        onResetAll={onResetAll}
        onSubmitFilter={onSubmitFilter}
        appliedFilterIds={filterAppliedIds || []}
        onSaveQuickFilter={onSaveQuickFilter}
        resetTempQuery={resetTempQuery}
        tempQuery={tempQuery}
        initialQuery={initialQuery}
        mandatoryFiltersToShow={mandatoryFiltersToShow}
        filterAppliedMessage={filterAppliedMessage}
        onEditabilityChange={onEditabilityChange}
        embeddedDisplayOnly={embeddedDisplayOnly}
        listView={listView}
        performerVenueFilter={performerVenueFilter}
      />
    );
  }

  return (
    <FilterToolbarContainer>
      {isMobile && <MobileFilterToolbar filters={mergedFilters} />}
      {filters.length > 0 && (
        <Popover.Root
          onOpenChange={(isOpen) => {
            if (useNewFilterUX && !isOpen) {
              resetTempQuery?.();
            }
          }}
        >
          <Popover.Trigger asChild>
            <div className={styles.filterToolbarIcon}>
              <FilterIcon
                title="filter"
                fill={
                  hasFiltersApplied
                    ? IconsFill.textBrand
                    : IconsFill.textPrimary
                }
                size={vars.iconSize.m}
                withHoverEffect
              />
              <div className={styles.filterCountLabel}>
                <Content id={ContentId.Filters} />
              </div>
              {hasFiltersApplied && filterAppliedCounts > 0 && (
                <FilterCount>{filterAppliedCounts}</FilterCount>
              )}
            </div>
          </Popover.Trigger>
          <Popover.Content
            align="start"
            style={{
              maxHeight: 'var(--radix-popover-content-available-height)',
              overflowY: 'auto',
            }}
          >
            {useNewFilterUX ? (
              <FilterDialogV2
                filters={filteredFilters}
                onResetAll={onResetAll}
                onSubmitFilter={onSubmitFilter}
                appliedFilterIds={filterAppliedIds || []}
                onSaveQuickFilter={onSaveQuickFilter}
                resetTempQuery={resetTempQuery}
                tempQuery={tempQuery}
                initialQuery={initialQuery}
                mandatoryFiltersToShow={mandatoryFiltersToShow}
                filterAppliedMessage={filterAppliedMessage}
                onEditabilityChange={onEditabilityChange}
              />
            ) : (
              <FilterDialog
                hasFiltersApplied={hasFiltersApplied}
                onResetAll={onResetAll}
                onSubmitFilter={onSubmitFilter}
                filters={filteredFilters}
                onSaveQuickFilter={onSaveQuickFilter}
                filterAppliedMessage={filterAppliedMessage}
              />
            )}
          </Popover.Content>
        </Popover.Root>
      )}
      {!isMobile && (
        <ScrollableToolbar>
          {mergedFilters?.map(({ id, name, isHidden, isSelected, onClick }) => {
            if (isHidden) {
              return null;
            }
            return (
              <ToolbarButton key={id} isSelected={isSelected} onClick={onClick}>
                {name}
              </ToolbarButton>
            );
          })}
        </ScrollableToolbar>
      )}
      {showCustomFilterButton && (
        <>
          <CustomFilterButton
            quickFilters={quickFilters ?? EMPTY_QUICK_FILTERS}
            {...customFilterButtonProps}
            onCreateCustomQuickFilter={() => {
              customFilterDialog.launchDialog();
            }}
            onUpdateCustomQuickFilter={(id) => {
              setCurrQuickFilterId(id);
              customFilterDialog.launchDialog();
            }}
          />
          {customFilterDialog.dialogProps.isOpen && (
            <CustomFilterDialog
              {...customFilterDialog.dialogProps}
              headerTitle={
                currQuickFilterId ? (
                  <Content id={ContentId.EditCustomFilter} />
                ) : (
                  <Content id={ContentId.CreateCustomFilter} />
                )
              }
              defaultValues={
                currQuickFilter && currQuickFilter.isCustomFilter
                  ? {
                      filterName: currQuickFilter.name as string,
                    }
                  : undefined
              }
              validateIsFilterUnique={(val) => {
                if (currQuickFilterId && val === currQuickFilterId) {
                  return true;
                }
                return (
                  quickFilters === undefined ||
                  !quickFilters.some(({ id }) => id === val)
                );
              }}
              onSave={(data) => {
                if (currQuickFilterId) {
                  onUpdateCustomQuickFilter?.(currQuickFilterId, {
                    name: data.filterName,
                  });
                } else {
                  onCreateCustomQuickFilter?.({ name: data.filterName });
                }
                setCurrQuickFilterId(null);
                customFilterDialog.closeDialog();
              }}
              onCancel={() => {
                setCurrQuickFilterId(null);
                customFilterDialog.closeDialog();
              }}
            />
          )}
        </>
      )}
    </FilterToolbarContainer>
  );
}
