import { useCallback, useState } from 'react';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { SALES_DATE_FILTER_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  getDisabledDatesForTimeFrameFilter,
  StandardDateRangePresetNames,
} from 'src/utils/dateTimeUtils';
import {
  DateTimeRangeWithRelative,
  Feature,
  ListingQuery,
} from 'src/WebApiController';

import { StackedFilter } from './StackedFilter';

export enum SaleDateFilterEnum {
  HasSaleDate = 'HasSaleDate',
  NoSaleDate = 'NoSaleDate',
}

export const SaleDateStackedFilter = () => {
  const { tempQuery, setTempQuery } = useFilterQueryContext<ListingQuery>();

  const hasDynamicDateTimeFiltersFeature = useUserHasFeature(
    Feature.DynamicDateTimeFilters
  );

  const [mode, setMode] = useState<SaleDateFilterEnum>(() => {
    // Set the initial mode based on tempQuery
    return tempQuery.lastSaleDates
      ? SaleDateFilterEnum.HasSaleDate
      : tempQuery.noSaleDates
      ? SaleDateFilterEnum.NoSaleDate
      : SaleDateFilterEnum.HasSaleDate;
  });

  const setDateFilter = useCallback(
    (mode: SaleDateFilterEnum, date: DateTimeRangeWithRelative | null) => {
      const newQuery: ListingQuery = {
        ...tempQuery,
        lastSaleDates: mode === SaleDateFilterEnum.HasSaleDate ? date : null,
        noSaleDates: mode === SaleDateFilterEnum.NoSaleDate ? date : null,
      };

      setTempQuery(newQuery);
      setMode(mode);
    },
    [tempQuery, setTempQuery]
  );

  return (
    <StackedFilter
      FilterComponents={[
        <PosEnumSelect
          key="topFilter"
          value={mode}
          valueOptionsContent={SALES_DATE_FILTER_TO_CID}
          enableEmptySelection={false}
          onChange={(mode) => {
            if (mode === SaleDateFilterEnum.HasSaleDate) {
              setDateFilter(mode, tempQuery['lastSaleDates']);
            } else if (mode === SaleDateFilterEnum.NoSaleDate) {
              setDateFilter(mode, tempQuery['noSaleDates']);
            }
          }}
        />,
        <DateRangeSelector
          key="dateRangeSelector"
          useRelativePresets={hasDynamicDateTimeFiltersFeature}
          presetNames={StandardDateRangePresetNames}
          value={tempQuery['lastSaleDates'] || tempQuery['noSaleDates']}
          defaultValue={null}
          onBlur={(value) => {
            setDateFilter(mode, value);
          }}
          disabledDay={(d) =>
            getDisabledDatesForTimeFrameFilter(
              d,
              tempQuery.eventTimeFrameFilter
            )
          }
          isEndDateFixed
          displayTextStyle={{ maxWidth: '260px' }}
        />,
      ]}
    />
  );
};
