import { UseFormSetError } from 'react-hook-form';
import { IErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { isUniqueWithin } from 'src/utils/validation';
import {
  PosClientConfig,
  SaleClient,
  TicketBarcode,
} from 'src/WebApiController';

export type BarcodeForm = {
  isBulkUpload: boolean;
  barcodes: TicketBarcode[];
};
export const checkDuplicateBarcodes = async (
  entityId: number,
  barcodes: TicketBarcode[],
  activeAccountWebClientConfig: PosClientConfig,
  alreadyReportedUsedBarcodes: string[],
  setAlreadyReportedUsedBarcodes: (barcodes: string[]) => void,
  onDuplicateBarcode: (index: number) => void,
  onBeforeCheck?: () => void,
  onAfterCheck?: () => void,
  showErrorDialog?: IErrorBoundaryContext['showErrorDialog'],
  viagVirtualId?: string
): Promise<boolean | null> => {
  return tryInvokeApi(
    async () => {
      onBeforeCheck?.();

      if (!barcodes) {
        return false;
      }

      const existingBarcodes = await new SaleClient(
        activeAccountWebClientConfig
      ).getBarcodesAlreadyInUse(viagVirtualId, barcodes);

      if (existingBarcodes) {
        // Get all the used barcodes that have not yet been reported
        const unreportedBarCodes = existingBarcodes.filter(
          (b) => !alreadyReportedUsedBarcodes.includes(b.barcode)
        );
        if (unreportedBarCodes.length > 0) {
          // We're reporting the dup warnings, so save it so we don't report on it again
          // We want to set all of it (not just the latest unreported barcodes)
          setAlreadyReportedUsedBarcodes(
            existingBarcodes.map((tb) => tb.barcode)
          );

          let hasErrors = false;
          existingBarcodes.forEach((tb) => {
            const index = barcodes.findIndex((b) => b.ticketId === tb.ticketId);

            if (index >= 0) {
              hasErrors = true;
              onDuplicateBarcode(index);
            }
          });

          return hasErrors;
        }
      }

      return false;
    },
    (error) => {
      showErrorDialog?.('SaleClient.getBarcodesAlreadyInUse', error, {
        trackErrorData: {
          saleId: entityId,
          barcodes,
        },
      });
    },
    () => onAfterCheck?.()
  );
};

export const checkBarcodeErrors = (
  isBulkUpload: boolean,
  barcodes: TicketBarcode[],
  ticketCnt: number,
  setError: UseFormSetError<BarcodeForm>,
  requiredMsg: string,
  missingMsg: string,
  extraMsg: string,
  duplicateMsg: string
) => {
  let hasErrors = false;
  const barcodesProvided =
    barcodes?.map((b) => b.barcode).filter((b) => b?.length) ?? [];
  if (isBulkUpload) {
    const barcodesProvided =
      barcodes?.map((b) => b.barcode).filter((b) => b?.length) ?? [];
    if (barcodesProvided.length < ticketCnt) {
      hasErrors = true;
      setError(`barcodes`, { message: missingMsg as string }); // for the bulk-edit inputs
    } else if (barcodesProvided.length > ticketCnt) {
      hasErrors = true;
      setError(`barcodes`, { message: extraMsg as string }); // for the bulk-edit inputs
    }
  }

  barcodes?.forEach((b, i) => {
    if (!b.barcode) {
      hasErrors = true;
      setError(`barcodes.${i}.barcode`, { message: requiredMsg });
    } else if (!isUniqueWithin(b.barcode, barcodesProvided, true)) {
      hasErrors = true;
      if (isBulkUpload) {
        setError(`barcodes`, { message: duplicateMsg }); // for the bulk-edit inputs
      }
      setError(`barcodes.${i}.barcode`, { message: duplicateMsg });
    }
  });
  return hasErrors;
};
