import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useSidePanelContext } from 'src/contexts/SidePanelContext';
import { Modal, ModalHeader } from 'src/modals/Modal';
import styled from 'styled-components/macro';

export function FlyoverPanel({ children }: { children: ReactNode | null }) {
  const { setHidden, isHidden } = useSidePanelContext();

  // Workaround: Modal from Reactstrap only initializes properly when
  // `isOpen` set to true, so only mount the modal when `isOpen` is true.
  //
  // Not using conditional render here {!isHidden && <Modal ..>} because we
  // want to keep the state of the children across opening/closing modal
  const [componentDidInit, setComponentInit] = useState<boolean>(false);

  useEffect(() => {
    if (!componentDidInit && !isHidden) {
      setComponentInit(true);
    }
  }, [isHidden, componentDidInit]);

  const closeModal = useCallback(() => {
    setHidden(true);
  }, [setHidden]);

  return (
    <>
      {componentDidInit && (
        <Modal
          isOpen={!isHidden}
          clickOutsideToClose={true}
          onClose={closeModal}
          size={'slide-left-sm'}
          unmountOnClose={false}
        >
          <ModalHeader onClose={closeModal}>
            <ModalHeaderContent />
          </ModalHeader>
          {children}
        </Modal>
      )}
    </>
  );
}

const ModalHeaderContent = styled.div`
  min-height: 40px;
`;
