import { Temporal, toTemporalInstant } from '@js-temporal/polyfill';
import {
  add,
  addDays,
  differenceInDays,
  differenceInHours,
  Duration,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  formatDistance,
  intlFormat,
  isEqual,
  isValid,
  parse,
  parseISO,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
  subDays,
  subWeeks,
  subYears,
} from 'date-fns';
import { ReactNode } from 'react';
import {
  Content,
  FormatContent,
  useFormattedContent,
} from 'src/contexts/ContentContext';
import {
  DateTimeRange,
  DateTimeRangeWithRelative,
  EventTimeFrameFilter,
  RelativeTimeRoundingMode,
} from 'src/WebApiController';

import { ContentId } from './constants/contentId';
import { FormatContentId } from './constants/formatContentId';

export type DateRangePreset = {
  name: DateRangePresetName;
  label: JSX.Element;
  range: DateRangeType;
};

export type DateRangeRelativePreset = {
  name: DateRangePresetName;
  label: JSX.Element;
  range: DateTimeRangeWithRelative;
};

type DateRangeType = {
  start: Date;
  end: Date;
};

export const getLastDateRange = (
  duration: Duration,
  end: Date
): DateRangeType => ({
  start: startOfDay(sub(end, duration)),
  end: endOfDay(end),
});

export const getNextDateRange = (
  duration: Duration,
  start: Date
): DateRangeType => ({
  start: startOfDay(start),
  end: endOfDay(add(start, duration)),
});

export const getLastDateRangeRelative = (
  duration: Duration,
  roundingMode?: RelativeTimeRoundingMode
): DateTimeRangeWithRelative => {
  const now = new Date();
  return {
    relativeStartSecs: (sub(now, duration).getTime() - now.getTime()) / 1000,
    relativeEndSecs: 0,
    roundingMode,
  } as DateTimeRangeWithRelative;
};

export const getNextDateRangeRelative = (
  duration: Duration,
  roundingMode?: RelativeTimeRoundingMode
): DateTimeRangeWithRelative => {
  const now = new Date();
  return {
    relativeStartSecs: 0,
    relativeEndSecs: (add(now, duration).getTime() - now.getTime()) / 1000,
    roundingMode,
  } as DateTimeRangeWithRelative;
};

export enum DateRangePresetName {
  Today = 'Today',
  Last3Days = 'Last3Days',
  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days',
  Last90Days = 'Last90Days',

  ThisWeek = 'ThisWeek',
  ThisMonth = 'ThisMonth',
  ThisYear = 'ThisYear',
  AlreadyInHand = 'AlreadyInHand',

  NextWeek = 'NextWeek',
  NextMonth = 'NextMonth',
  NextYear = 'NextYear',

  PastYear = 'PastYear',

  LastYear = 'LastYear',
  OlderThanLastYear = 'OlderThanLastYear',
}

export const StandardDateRangePresetNames = [
  DateRangePresetName.Today,
  DateRangePresetName.Last3Days,
  DateRangePresetName.Last7Days,
  DateRangePresetName.Last30Days,
  DateRangePresetName.Last90Days,
];

export const SaleDateRangeRelativePresetNames = [
  DateRangePresetName.Today,
  DateRangePresetName.Last3Days,
  DateRangePresetName.Last7Days,
  DateRangePresetName.Last30Days,
  DateRangePresetName.Last90Days,
  DateRangePresetName.PastYear,
];

export const InhandDateRangePresetNames = [
  DateRangePresetName.Today,
  DateRangePresetName.ThisWeek,
  DateRangePresetName.ThisMonth,
  DateRangePresetName.ThisYear,
  DateRangePresetName.AlreadyInHand,
];

export const EventDateRangePresetNames = [
  DateRangePresetName.Today,
  DateRangePresetName.ThisWeek,
  DateRangePresetName.ThisMonth,
  DateRangePresetName.ThisYear,
];

export const NextDateRangePresetNames = [
  DateRangePresetName.Today,
  DateRangePresetName.NextWeek,
  DateRangePresetName.NextMonth,
  DateRangePresetName.NextYear,
];

export const PastDateRangePresetNames = [
  DateRangePresetName.Last7Days,
  DateRangePresetName.Last30Days,
  DateRangePresetName.PastYear,
];

export const PurchaseDateRangePresetNames = [
  DateRangePresetName.Today,
  DateRangePresetName.Last3Days,
  DateRangePresetName.Last7Days,
  DateRangePresetName.Last30Days,
  DateRangePresetName.Last90Days,
  DateRangePresetName.ThisYear,
  DateRangePresetName.LastYear,
  DateRangePresetName.OlderThanLastYear,
];

const DateRangePresetsMap = {
  [DateRangePresetName.Today]: {
    name: DateRangePresetName.Today,
    label: <Content id={ContentId.Today} />,
    range: (now: Date) => getLastDateRange({ days: 0 }, now),
  },
  [DateRangePresetName.Last3Days]: {
    name: DateRangePresetName.Last3Days,
    label: <FormatContent id={FormatContentId.LastNDays} params={[`${3}`]} />,
    range: (now: Date) => getLastDateRange({ days: 3 }, now),
  },
  [DateRangePresetName.Last7Days]: {
    name: DateRangePresetName.Last7Days,
    label: <FormatContent id={FormatContentId.LastNDays} params={[`${7}`]} />,
    range: (now: Date) => getLastDateRange({ days: 7 }, now),
  },
  [DateRangePresetName.Last30Days]: {
    name: DateRangePresetName.Last30Days,
    label: <FormatContent id={FormatContentId.LastNDays} params={[`${30}`]} />,
    range: (now: Date) => getLastDateRange({ days: 30 }, now),
  },
  [DateRangePresetName.Last90Days]: {
    name: DateRangePresetName.Last90Days,
    label: <FormatContent id={FormatContentId.LastNDays} params={[`${90}`]} />,
    range: (now: Date) => getLastDateRange({ days: 90 }, now),
  },
  [DateRangePresetName.ThisWeek]: {
    name: DateRangePresetName.ThisWeek,
    label: <Content id={ContentId.ThisWeek} />,
    range: (now: Date) => ({
      start: startOfWeek(now),
      end: endOfWeek(now),
    }),
  },
  [DateRangePresetName.ThisMonth]: {
    name: DateRangePresetName.ThisMonth,
    label: <Content id={ContentId.ThisMonth} />,
    range: (now: Date) => ({
      start: startOfMonth(now),
      end: endOfMonth(now),
    }),
  },
  [DateRangePresetName.ThisYear]: {
    name: DateRangePresetName.ThisYear,
    label: <Content id={ContentId.ThisYear} />,
    range: (now: Date) => ({
      start: startOfYear(now),
      end: endOfYear(now),
    }),
  },
  [DateRangePresetName.AlreadyInHand]: {
    name: DateRangePresetName.AlreadyInHand,
    label: <Content id={ContentId.AlreadyInHand} />,
    range: (now: Date) => ({
      start: new Date('01/01/1970'),
      end: endOfDay(now),
    }),
  },
  [DateRangePresetName.NextWeek]: {
    name: DateRangePresetName.NextWeek,
    label: <Content id={ContentId.NextWeek} />,
    range: (now: Date) => getNextDateRange({ weeks: 1 }, now),
  },
  [DateRangePresetName.NextMonth]: {
    name: DateRangePresetName.NextMonth,
    label: <Content id={ContentId.NextMonth} />,
    range: (now: Date) => getNextDateRange({ months: 1 }, now),
  },
  [DateRangePresetName.NextYear]: {
    name: DateRangePresetName.NextYear,
    label: <Content id={ContentId.NextYear} />,
    range: (now: Date) => getNextDateRange({ years: 1 }, now),
  },
  [DateRangePresetName.PastYear]: {
    name: DateRangePresetName.PastYear,
    label: <Content id={ContentId.PastYear} />,
    range: (now: Date) => getLastDateRange({ years: 1 }, now),
  },
  [DateRangePresetName.LastYear]: {
    name: DateRangePresetName.LastYear,
    label: <Content id={ContentId.LastYear} />,
    range: (now: Date) => ({
      start: subYears(startOfYear(now), 1),
      end: endOfDay(subDays(startOfYear(now), 1)),
    }),
  },
  [DateRangePresetName.OlderThanLastYear]: {
    name: DateRangePresetName.OlderThanLastYear,
    label: <Content id={ContentId.OlderThanLastYear} />,
    range: (now: Date) => ({
      start: new Date('01/01/1970'),
      end: endOfDay(subDays(subYears(startOfYear(now), 1), 1)),
    }),
  },
} satisfies {
  [key in DateRangePresetName]: {
    name: key /* eslint-disable-line no-restricted-globals */;
    label: ReactNode;
    range: (now: Date) => { start: Date; end: Date };
  };
};
const DateRangePresets = Object.values(DateRangePresetsMap);

export const getDateRangePresets = (
  presetNames: DateRangePresetName[],
  now: Date = new Date()
): DateRangePreset[] => {
  return DateRangePresets.filter((drp) => presetNames.includes(drp.name)).map(
    (drp) => ({
      name: drp.name,
      label: drp.label,
      range: drp.range(now),
    })
  );
};

const DateRangeRelativePresetsMap = {
  [DateRangePresetName.Today]: {
    name: DateRangePresetName.Today,
    label: <Content id={ContentId.Today} />,
    range: () => getLastDateRangeRelative({ days: 0 }),
  },
  [DateRangePresetName.Last3Days]: {
    name: DateRangePresetName.Last3Days,
    label: <FormatContent id={FormatContentId.LastNDays} params={[`${3}`]} />,
    range: () => getLastDateRangeRelative({ days: 3 }),
  },
  [DateRangePresetName.Last7Days]: {
    name: DateRangePresetName.Last7Days,
    label: <FormatContent id={FormatContentId.LastNDays} params={[`${7}`]} />,
    range: () => getLastDateRangeRelative({ days: 7 }),
  },
  [DateRangePresetName.Last30Days]: {
    name: DateRangePresetName.Last30Days,
    label: <FormatContent id={FormatContentId.LastNDays} params={[`${30}`]} />,
    range: () => getLastDateRangeRelative({ days: 30 }),
  },
  [DateRangePresetName.Last90Days]: {
    name: DateRangePresetName.Last90Days,
    label: <FormatContent id={FormatContentId.LastNDays} params={[`${90}`]} />,
    range: () => getLastDateRangeRelative({ days: 90 }),
  },
  [DateRangePresetName.PastYear]: {
    name: DateRangePresetName.PastYear,
    label: <Content id={ContentId.PastYear} />,
    range: () => getLastDateRangeRelative({ months: 12 }),
  },
  [DateRangePresetName.ThisWeek]: {
    name: DateRangePresetName.ThisWeek,
    label: <Content id={ContentId.ThisWeek} />,
    range: () =>
      getLastDateRangeRelative({ days: 0 }, RelativeTimeRoundingMode.Week),
  },
  [DateRangePresetName.ThisMonth]: {
    name: DateRangePresetName.ThisMonth,
    label: <Content id={ContentId.ThisMonth} />,
    range: () =>
      getLastDateRangeRelative({ days: 0 }, RelativeTimeRoundingMode.Month),
  },
  [DateRangePresetName.ThisYear]: {
    name: DateRangePresetName.ThisYear,
    label: <Content id={ContentId.ThisYear} />,
    range: () =>
      getLastDateRangeRelative({ days: 0 }, RelativeTimeRoundingMode.Year),
  },
  [DateRangePresetName.AlreadyInHand]: {
    name: DateRangePresetName.AlreadyInHand,
    label: <Content id={ContentId.AlreadyInHand} />,
    range: () => {
      return {
        relativeStartSecs: null,
        relativeEndSecs: 0,
      } as DateTimeRangeWithRelative;
    },
  },
  [DateRangePresetName.NextWeek]: {
    name: DateRangePresetName.NextWeek,
    label: <Content id={ContentId.NextWeek} />,
    range: () => getNextDateRangeRelative({ weeks: 1 }),
  },
  [DateRangePresetName.NextMonth]: {
    name: DateRangePresetName.NextMonth,
    label: <Content id={ContentId.NextMonth} />,
    range: () => getNextDateRangeRelative({ months: 1 }),
  },
  [DateRangePresetName.NextYear]: {
    name: DateRangePresetName.NextYear,
    label: <Content id={ContentId.NextYear} />,
    range: () => getNextDateRangeRelative({ years: 1 }),
  },
  [DateRangePresetName.LastYear]: {
    name: DateRangePresetName.LastYear,
    label: <Content id={ContentId.LastYear} />,
    range: () => {
      const now = new Date();
      return {
        relativeEndSecs:
          (sub(now, { years: 1 }).getTime() - now.getTime()) / 1000,
        relativeStartSecs:
          (sub(now, { years: 1 }).getTime() - now.getTime()) / 1000,
        roundingMode: RelativeTimeRoundingMode.Year,
      } as DateTimeRangeWithRelative;
    },
  },
  [DateRangePresetName.OlderThanLastYear]: {
    name: DateRangePresetName.OlderThanLastYear,
    label: <Content id={ContentId.OlderThanLastYear} />,
    range: () => {
      const now = new Date();
      return {
        relativeEndSecs:
          (sub(now, { years: 2 }).getTime() - now.getTime()) / 1000,
        relativeStartSecs: null,
        roundingMode: RelativeTimeRoundingMode.Year,
      } as DateTimeRangeWithRelative;
    },
  },
} satisfies {
  [key: string]: {
    name: string /* eslint-disable-line no-restricted-globals */;
    label: ReactNode;
    range: () => DateTimeRangeWithRelative;
  };
};
const DateRangeRelativePresets = Object.values(DateRangeRelativePresetsMap);

export const getDateRangeRelativePresets = (
  presetNames: DateRangePresetName[]
): DateRangeRelativePreset[] => {
  return DateRangeRelativePresets.filter((drp) =>
    presetNames.includes(drp.name)
  ).map((drp) => ({
    name: drp.name,
    label: drp.label,
    range: drp.range(),
  }));
};

export enum DatePresetName {
  Now = 'Now',
  OneDayBeforeEvent = 'OneDayBeforeEvent',
  OneWeekBeforeEvent = 'OneWeekBeforeEvent',
  DaysBeforeEvent = 'DaysBeforeEvent',
}

export const getDatePresets = (
  presetNames: DatePresetName[],
  targetDate: Date,
  inHandDaysBeforeEvent?: number
): DatePreset[] => {
  return DatePresets.filter((dp) => presetNames.includes(dp.name)).map(
    (dp) => ({
      name: dp.name,
      label: dp.label,
      value: dp.value(
        targetDate,
        dp.name === DatePresetName.DaysBeforeEvent
          ? inHandDaysBeforeEvent
          : undefined
      ),
    })
  );
};

export type DatePreset = {
  name: DatePresetName;
  label: JSX.Element;
  value: Date;
};

export const StandardDatePresetNames = [
  DatePresetName.Now,
  DatePresetName.OneDayBeforeEvent,
  DatePresetName.OneWeekBeforeEvent,
];

export const DatePresets = [
  {
    name: DatePresetName.Now,
    label: <Content id={ContentId.Now} />,
    value: () => new Date(),
  },
  {
    name: DatePresetName.OneDayBeforeEvent,
    label: <Content id={ContentId.OneDayBeforeEvent} />,
    value: (event: Date) => subDays(event, 1),
  },
  {
    name: DatePresetName.OneWeekBeforeEvent,
    label: <Content id={ContentId.OneWeekBeforeEvent} />,
    value: (event: Date) => subWeeks(event, 1),
  },
  {
    name: DatePresetName.DaysBeforeEvent,
    label: <FormatContent id={FormatContentId.DaysBeforeEvent} />,
    value: (event: Date, inHandDaysBeforeEvent?: number) =>
      subDays(event, inHandDaysBeforeEvent ?? 0),
  },
];

export function formatDate(
  dateString: string,
  locale?: string,
  timeZone?: string
): string {
  return dateString
    ? intlFormat(
        new Date(dateString),
        { month: '2-digit', day: '2-digit', year: 'numeric', timeZone },
        { locale }
      )
    : '';
}

export const formatDateRange = ({ start, end }: DateRangeType): string => {
  if (start && end && differenceInDays(start, end) === 0) {
    return formatDate(start.toISOString());
  }

  const from = formatDate(start.toISOString());
  const to = formatDate(end.toISOString());

  return `${from}-${to}`;
};

export const formatRelativeDateRange = ({
  relativeStartSecs,
  relativeEndSecs,
  roundingMode,
}: DateTimeRangeWithRelative): string => {
  let startDate =
    relativeStartSecs != null
      ? new Date(new Date().getTime() + relativeStartSecs * 1000)
      : null;
  let endDate =
    relativeEndSecs != null
      ? new Date(new Date().getTime() + relativeEndSecs * 1000)
      : null;

  if (roundingMode === RelativeTimeRoundingMode.Year) {
    startDate = startDate ? startOfYear(startDate) : null;
    endDate = endDate ? endOfYear(endDate) : null;
  } else if (roundingMode === RelativeTimeRoundingMode.Month) {
    startDate = startDate ? startOfMonth(startDate) : null;
    endDate = endDate ? endOfMonth(endDate) : null;
  } else if (roundingMode === RelativeTimeRoundingMode.Week) {
    startDate = startDate ? startOfWeek(startDate) : null;
    endDate = endDate ? endOfWeek(endDate) : null;
  } else {
    startDate = startDate ? startOfDay(startDate) : null;
    endDate = endDate ? endOfDay(endDate) : null;
  }
  if (
    startDate != null &&
    endDate != null &&
    differenceInDays(startDate, endDate) === 0
  ) {
    return formatDate(startDate.toISOString());
  }

  const from = formatDate((startDate ?? new Date(0)).toISOString());
  const to = endDate ? formatDate(endDate.toISOString()) : '';

  return `${from}-${to}`;
};

export const getDateRangeRelativePresetDisplayText = (
  preset: DateRangeRelativePreset,
  style?: React.CSSProperties
) => {
  const { label, range } = preset;
  return (
    <div
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ...style,
      }}
    >
      {label}{' '}
      <span className="optional-display">{`(${formatRelativeDateRange(
        range
      )})`}</span>
    </div>
  );
};

export const getDateRangePresetDisplayText = (
  preset: DateRangePreset,
  style?: React.CSSProperties
) => {
  const { label, range } = preset;
  return (
    <div
      style={{
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ...style,
      }}
    >
      {label}{' '}
      <span className="optional-display">{`(${formatDateRange(range)})`}</span>
    </div>
  );
};
export const getDatePresetDisplayText = (
  preset: DatePreset,
  inHandDaysBeforeEvent?: number,
  toZonedTime?: (date: Date) => Date
) => {
  const { label, value } = preset;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const daysBeforeEventLabel = useFormattedContent(
    FormatContentId.DaysBeforeEvent,
    `${inHandDaysBeforeEvent ?? 0}`
  );

  return (
    <div>
      {preset.name === DatePresetName.DaysBeforeEvent
        ? daysBeforeEventLabel
        : label}{' '}
      <span className="optional-display">{`(${formatDate(
        (toZonedTime ? toZonedTime(value) : value).toISOString()
      )})`}</span>
    </div>
  );
};

export const getPresetFromUiDateTimeRange = (
  value?: DateTimeRange | null,
  toZonedTime?: (date: Date) => Date
): DateRangePreset | undefined => {
  if (!value || !value.start || !value.end) {
    return undefined;
  }

  const now = toZonedTime ? toZonedTime(new Date()) : new Date();
  const start = startOfDay(
    toZonedTime ? toZonedTime(new Date(value.start)) : new Date(value.start)
  );
  const end = endOfDay(
    toZonedTime ? toZonedTime(new Date(value.end)) : new Date(value.end)
  );

  const preset = DateRangePresets.find((p) => {
    const range = p.range(now);
    return isEqual(start, range.start) && isEqual(end, range.end);
  });

  return preset ? { ...preset, range: preset.range(now) } : undefined;
};

export const getRelativePresetFromUiDateTimeRange = (
  value?: DateTimeRangeWithRelative | null
): DateRangeRelativePreset | undefined => {
  if (
    !value ||
    (value.relativeStartSecs == null && value.relativeEndSecs == null)
  ) {
    return undefined;
  }

  const preset = DateRangeRelativePresets.find((p) => {
    const range = p.range();
    return (
      value.relativeEndSecs === range.relativeEndSecs &&
      value.relativeStartSecs === range.relativeStartSecs &&
      value.roundingMode === range.roundingMode
    );
  });

  return preset ? { ...preset, range: preset.range() } : undefined;
};

export const getPresetFromDate = (
  selectedDate: Date | null | undefined,
  targetDate: Date,
  inHandDaysBeforeEvent?: number
): DatePreset | undefined => {
  if (!selectedDate) {
    return undefined;
  }

  const preset = DatePresets.find((p) => {
    const value = p.value(targetDate, inHandDaysBeforeEvent);

    return isEqual(startOfDay(value), startOfDay(selectedDate));
  });

  return preset
    ? {
        ...preset,
        value: preset.value(targetDate || new Date(), inHandDaysBeforeEvent),
      }
    : undefined;
};

export const getUiDateTimeRangeFromPreset = (
  value: string,
  onlyIncludes: DateRangePresetName[]
): DateTimeRange | undefined => {
  const name = value as keyof typeof DateRangePresetName;
  const presetEnum = DateRangePresetName[name];
  if (onlyIncludes.includes(presetEnum)) {
    const preset = getDateRangePresets([presetEnum]);
    if (preset.length >= 1) {
      return {
        start: preset[0].range.start.toISOString(),
        end: preset[0].range.end.toISOString(),
      };
    }
  }

  return undefined;
};

export const tryGetDateRangePresetSuggestionText = (
  value?: DateTimeRange | null,
  toZonedTime?: (date: Date) => Date,
  style?: React.CSSProperties
) => {
  const preset = getPresetFromUiDateTimeRange(value, toZonedTime);
  if (preset) {
    return getDateRangePresetDisplayText(preset, style);
  }

  return '';
};

export const tryGetDateRangeRelativePresetSuggestionText = (
  value?: DateTimeRangeWithRelative | null
) => {
  const preset = getRelativePresetFromUiDateTimeRange(value);
  if (preset) {
    return getDateRangeRelativePresetDisplayText(preset);
  }

  return '';
};

export const tryGetDatePresetSuggestionText = (
  value: Date | null | undefined,
  targetDate: Date,
  inHandDaysBeforeEvent?: number,
  // If provided, the date will be converted to the site's timezone before formatting
  toZonedTime?: (date: Date) => Date
) => {
  const preset = getPresetFromDate(value, targetDate, inHandDaysBeforeEvent);
  if (preset) {
    return getDatePresetDisplayText(preset, inHandDaysBeforeEvent, toZonedTime);
  }

  return '';
};

export const stringToUtcDate = (dateString: string) => {
  const inputStringWithTimeZone = isValid(parseISO(dateString))
    ? dateString.split('').at(-1) !== 'Z' // Add Z for GMT if no timeZone specified
      ? dateString.concat('Z')
      : dateString
    : dateString;
  return new Date(inputStringWithTimeZone);
};

export const formatFromUtcDateString = (utcDate: string, formatStr: string) => {
  const date = stringToUtcDate(utcDate);
  const dateDisplay = format(date, formatStr);

  return dateDisplay;
};

/**
 * Divides a date interval into `n` number of dates.
 *
 * If the interval is cleanly divisible into months, it will use months as the period.
 * Otherwise, days will be used for the period. If days are not evenly divisible, the period will be
 * rounded down. The last date will still be the `toDate`, so the last period will be the longest in
 * this case.
 *
 * Note: Since 1 weeks is always equal to 7 days, a period of 2 weeks will also be calculated using
 * days.
 *
 * @param interval Date interval
 * @param n Number of dates we want to end up with
 * @returns The date interval split into `n` number of dates.
 */
export function divideDateInterval(
  interval: {
    fromDate: Date;
    toDate: Date;
  },
  n: number
): Date[] {
  const from = toTemporalInstant
    .call(interval.fromDate)
    .toZonedDateTimeISO(Temporal.Now.timeZoneId());
  const to = toTemporalInstant
    .call(interval.toDate)
    .toZonedDateTimeISO(Temporal.Now.timeZoneId());
  const durationUntil = from.until(to);
  const durationMonths = durationUntil.round({
    largestUnit: 'months',
    smallestUnit: 'months',
    roundingMode: 'trunc',
    relativeTo: from,
  });
  const isWholeNumberOfMonths =
    Temporal.Duration.compare(durationUntil, durationMonths, {
      relativeTo: from,
    }) === 0 && durationMonths.months % (n - 1) === 0;
  const period = isWholeNumberOfMonths
    ? { months: durationMonths.months / (n - 1) }
    : { days: Math.floor(durationUntil.total('days') / (n - 1)) };
  const dates: Date[] = [];
  for (let i = 0, curr = from; i < n - 1; i++, curr = curr.add(period)) {
    dates.push(new Date(curr.epochMilliseconds));
  }
  dates.push(interval.toDate);
  return dates;
}

/**
 * Used for restricting the date picker selectable dates based on the selected
 * `EventTimeFrameFilter`.
 * @param timeFrameFilter
 * @returns
 */
export function getDisabledDatesForTimeFrameFilter(
  date: Date,
  timeFrameFilter: EventTimeFrameFilter | null
) {
  if (!timeFrameFilter) {
    return false;
  }
  switch (timeFrameFilter) {
    case EventTimeFrameFilter.Future:
      return date < subDays(new Date(), 1); // If we do < Date on the dote it will disable today due to the time
    case EventTimeFrameFilter.Past:
      return date > addDays(new Date(), 1); // If we do > Date on the dote it will disable today due to the time
  }
}

/**
 *  Used for restricting the date picker presets based on the selected `EventTimeFrameFilter`.
 * @param timeFrameFilter
 * @returns
 */
export function getPresetNamesForTimeFrameFilter(
  timeFrameFilter: EventTimeFrameFilter | null
) {
  if (!timeFrameFilter) {
    return EventDateRangePresetNames;
  }
  switch (timeFrameFilter) {
    case EventTimeFrameFilter.Future:
      return NextDateRangePresetNames;
    case EventTimeFrameFilter.Past:
      return PastDateRangePresetNames;
  }
}

/* Check whether if the given date is passed some hours from now.  A null/undefined date is considered a min date and will always returns true */
export const isDatePassedHours = (
  dateTime: Date | null | undefined,
  hours: number
) => {
  // NOTE - dateTime from UserSettings will not parsed as a Date, and so
  // here we need to do toString() and the convert to a Date to be sure
  // or else the comparison will not work
  return !(
    dateTime &&
    differenceInHours(new Date(), new Date(dateTime.toString())) <= hours
  );
};

export const getTimeAgoString = (date: Date) => {
  return formatDistance(date, new Date(), {
    addSuffix: true,
  });
};

export function convertDateToTemporalPlainDateTime(date: Date) {
  return toTemporalInstant
    .call(date)
    .toZonedDateTimeISO(Temporal.Now.timeZoneId())
    .toPlainDateTime();
}

/**
 * `dateString` might be either a plain datetime without a timezone or a utc datetime.
 * For events we want to assume that even if the datetime is UTC, it is still a datetime in the
 * local timezone of the event.
 *
 * DO NOT pass in an offset date time into this function.
 * @param dateString UTC or plain datetime string
 * @param options
 * @returns
 */
export function getEventDateDisplayStrings(
  dateString: string,
  {
    dateFormat,
    subDateFormat,
  }: {
    dateFormat?: string;
    subDateFormat?: string;
  } = {}
) {
  return {
    formattedEventDate: format(new Date(dateString), dateFormat ?? 'MMM d'),
    formattedEventSubDate: format(
      new Date(dateString),
      subDateFormat ?? ' - h:mm aaa, eeee'
    ),
  };
}

export const SQL_MAX_DATEITME = new Date('9999-12-31T23:59:59.99Z');

export const isSqlMaxDate = (date: Date) => {
  return (
    date.getFullYear() === 9999 &&
    date.getMonth() === 11 &&
    date.getDate() === 31
  );
};

export type DateConfig = {
  start: string;
  end: string;
};

export type DateErrorsConfig = {
  endDateBeforeStartDate: string;
  invalidEndDate: string;
  invalidStartDate: string;
};

export const validateDate = (
  dateObj: DateConfig,
  locale: Locale,
  errorConfig: DateErrorsConfig
): string[] => {
  const { end, start } = dateObj;
  const errors = [];
  const parsedStartDate = parse(start, 'P', new Date(), { locale });
  const parsedEndDate = parse(end, 'P', new Date(), { locale });

  if (start && !isValid(parsedStartDate))
    errors.push(errorConfig.invalidStartDate);
  if (end && !isValid(parsedEndDate)) errors.push(errorConfig.invalidEndDate);

  try {
    if (start && end && parsedEndDate < parsedStartDate) {
      errors.push(errorConfig.endDateBeforeStartDate);
    }
  } catch (error) {
    /* empty */
  }

  return errors;
};

export const getEventTimeFrameFromDateTimeRange = (
  start?: Date,
  end?: Date
) => {
  if (start && start > new Date()) {
    return EventTimeFrameFilter.Future;
  }
  if (end && end < new Date()) {
    return EventTimeFrameFilter.Past;
  }

  return null;
};

export const roundRelativeDateUp = (
  date: Date,
  roundingMode: RelativeTimeRoundingMode | null | undefined
) => {
  if (roundingMode === RelativeTimeRoundingMode.Week) {
    return startOfWeek(date);
  }
  if (roundingMode === RelativeTimeRoundingMode.Month) {
    return startOfMonth(date);
  }
  if (roundingMode === RelativeTimeRoundingMode.Year) {
    return startOfYear(date);
  }
  return startOfDay(date);
};

export const roundRelativeDateDown = (
  date: Date,
  roundingMode: RelativeTimeRoundingMode | null | undefined
) => {
  if (roundingMode === RelativeTimeRoundingMode.Week) {
    return endOfWeek(date);
  }
  if (roundingMode === RelativeTimeRoundingMode.Month) {
    return endOfMonth(date);
  }
  if (roundingMode === RelativeTimeRoundingMode.Year) {
    return endOfYear(date);
  }
  return endOfDay(date);
};
