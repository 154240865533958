import clsx from 'clsx';
import { forwardRef, PropsWithChildren } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';

import * as styles from './DashboardWidgetTile.css';

export type DashboardWidgetTileProps = {
  titleContentId: string | null | undefined;
  type?: 'chart' | 'ditribution' | 'default';
} & PropsWithChildren;

export const DashboardWidgetTile = forwardRef<
  HTMLDivElement,
  DashboardWidgetTileProps
>(({ titleContentId, type = 'default', children }, ref) => {
  return (
    <div
      ref={ref}
      className={clsx(styles.widgetContainer, {
        [styles.chartContainer]: type === 'chart',
        [styles.distributionContainer]: type === 'ditribution',
      })}
    >
      <div className={styles.widgetTitleContainer}>
        {titleContentId ? (
          <Content id={titleContentId} />
        ) : (
          <ContentPlaceholder height="1rem" width="100%" />
        )}
      </div>
      <div className={styles.widgetChildrenContainer}>{children}</div>
    </div>
  );
});

DashboardWidgetTile.displayName = 'DashboardWidgetTile';
