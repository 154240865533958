import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function SuccessOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M12 2c5.51 0 10 4.49 10 10s-4.49 10-10 10S2 17.51 2 12 6.49 2 12 2zm0 1.667c-4.592 0-8.333 3.741-8.333 8.333 0 4.592 3.741 8.333 8.333 8.333 4.592 0 8.333-3.741 8.333-8.333 0-4.592-3.741-8.333-8.333-8.333zm3.65 4.959c.39.298.466.855.168 1.245l-4.06 5.334a.889.889 0 01-.61.345l-.098.006a.89.89 0 01-.7-.342L8.19 12.453a.888.888 0 111.4-1.095l1.451 1.854 3.364-4.417a.89.89 0 011.246-.169z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
