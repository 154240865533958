import { CSSProperties } from 'react';
import { vars } from 'src/core/themes';
import { Stack, StackProps } from 'src/core/ui';
import { IconsFill, WarningOutlineIcon } from 'src/svgs/Viagogo';

import * as styles from './MessageWithIcon.css';

export const WarningMessage = ({
  message,
  iconSize = vars.iconSize.l,
  messageStyle,
  ...rest
}: {
  message: string | JSX.Element;
  iconSize?: string;
  messageStyle?: CSSProperties;
} & StackProps) => {
  return (
    <Stack
      direction="row"
      gap="m"
      className={styles.messageContainer}
      {...rest}
    >
      <WarningOutlineIcon fill={IconsFill.textWarning} size={iconSize} />
      <div className={styles.messageDiv} style={messageStyle}>
        {message}
      </div>
    </Stack>
  );
};
