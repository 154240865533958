import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { getAdGroupListingsUrl } from 'src/utils/adGroupListingsUtils';
import { ContentId } from 'src/utils/constants/contentId';

export const AdGroupActionsCell = ({ adGroupId }: { adGroupId: string }) => {
  const navigate = useNavigate();

  const onViewClick = useCallback(
    (url: string) => {
      setTimeout(() => {
        navigate(url, {
          state: {
            returnUrl: `${location.pathname}${location.search}`,
          },
        });
      }, 10);
    },
    [navigate]
  );

  return (
    <TableCellDiv align="right">
      <PosDropdown
        trigger={
          <div
            style={{ padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}` }}
          >
            <MoreIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              align="middle"
            />
          </div>
        }
        align="end"
      >
        <PosDropdownItem
          onClick={(e) => {
            if (e.button === 0 && e.getModifierState('Control') === false) {
              // Perform like a button click when it's a left-click (and no Ctrl)
              // Else the click will be whatever <a /> does
              e.preventDefault();
              e.stopPropagation();

              onViewClick(getAdGroupListingsUrl(adGroupId));
            }
          }}
        >
          <Content id={ContentId.ViewApplicableListings} />
        </PosDropdownItem>
      </PosDropdown>
    </TableCellDiv>
  );
};
