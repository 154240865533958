import { forwardRef, useMemo } from 'react';
import { Components } from 'react-virtuoso';
import { ColumnHeader } from 'src/tables/Table/components';
import { PosGroupedVirtuosoContext } from 'src/tables/Table/GroupedVirtuosoTable/PosGroupedVirtuosoContext';
import * as styles from 'src/tables/Table/Table.css';

import { PosTableData } from '../Table';

type C<T extends PosTableData> = Components<
  any,
  PosGroupedVirtuosoContext<T>
>['List'];

export const PosGroupedVirtuosoList: Components['List'] = forwardRef(
  function GroupedVirtuosoList({ context, children, ...props }, ref) {
    const { table } = context! as PosGroupedVirtuosoContext<PosTableData>;
    const headerGroups = table.getHeaderGroups();

    const tableHeaderCmp = useMemo(() => {
      return (
        <table className={styles.table}>
          <thead className={styles.tableHead}>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <ColumnHeader key={header.id} header={header} table={table} />
                ))}
              </tr>
            ))}
          </thead>
        </table>
      );
    }, [headerGroups, table]);

    return (
      <div {...props} ref={ref}>
        <div>{tableHeaderCmp}</div>
        {children}
      </div>
    );
  }
);
