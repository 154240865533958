import { useMemo } from 'react';
import {
  PosMultiSelect,
  PosMultiSelectProps,
} from 'src/core/POS/PosMultiSelect';
import { useCities } from 'src/hooks/api/useCities';
import { useStateProvinces } from 'src/hooks/api/useStateProvinces';
import { ContentId } from 'src/utils/constants/contentId';
import { City } from 'src/WebApiController';

export const CityMultiSelector = ({
  placeholderText,
  stateProvinceIds,
  countryCodes,
  ...props
}: Omit<PosMultiSelectProps, 'valueOptionsContent'> & {
  countryCodes: string[] | null;
  stateProvinceIds: number[] | null;
}) => {
  const { citiesWithStateProvinceQuery, citiesWithoutStateProvinceQuery } =
    useCities();
  const stateProvincesQuery = useStateProvinces();

  const citiesOptions = useMemo(() => {
    if (
      citiesWithStateProvinceQuery.data == null ||
      citiesWithoutStateProvinceQuery.data == null
    ) {
      return {};
    }

    const cities: City[] = [];

    // add cities without state/province
    if (countryCodes?.length) {
      countryCodes.forEach((countryCode) => {
        cities.push(
          ...(citiesWithoutStateProvinceQuery.data?.[countryCode] ?? [])
        );
      });
    } else {
      cities.push(
        ...Object.values(citiesWithoutStateProvinceQuery.data).flatMap(
          (cities) => cities
        )
      );
    }

    if (stateProvinceIds?.length) {
      // If state/province is specified, add cities associated with those state/province
      stateProvinceIds.forEach((stateProvinceId) => {
        cities.push(
          ...(citiesWithStateProvinceQuery.data?.[stateProvinceId] ?? [])
        );
      });
    } else {
      // If state/province is not specified
      if (countryCodes?.length) {
        // Case 1: Country is specified, add all cities associated with that country
        const allStateProvinceIdsForCountries = countryCodes.flatMap(
          (countryCode) =>
            stateProvincesQuery.data?.[countryCode]?.map((sp) => sp.id) ?? []
        );

        allStateProvinceIdsForCountries.forEach((stateProvinceId) => {
          cities.push(
            ...(citiesWithStateProvinceQuery.data?.[stateProvinceId] ?? [])
          );
        });
      } else {
        // Case 2: Country is not specified, add all cities
        cities.push(
          ...Object.values(citiesWithStateProvinceQuery.data).flatMap(
            (cities) => cities
          )
        );
      }
    }

    return cities.reduce(
      (r, s) => {
        let displayName = s.name;
        if (stateProvinceIds?.length !== 1 && s.stateProvinceName != null) {
          displayName = `${s.name}, ${s.stateProvinceName}`;
        }
        if (countryCodes?.length !== 1) {
          displayName = `${displayName}, ${s.countryName}`;
        }

        r[s.id] = displayName;
        return r;
      },
      {} as Record<number, string>
    );
  }, [
    citiesWithStateProvinceQuery.data,
    citiesWithoutStateProvinceQuery.data,
    countryCodes,
    stateProvinceIds,
    stateProvincesQuery.data,
  ]);

  return (
    <PosMultiSelect
      {...props}
      loading={
        citiesWithStateProvinceQuery.data == null ||
        citiesWithoutStateProvinceQuery.data == null
      }
      placeholderText={placeholderText ?? ContentId.SelectCity}
      valueOptionsContent={citiesOptions}
      showSelectedOptionsFirst
    />
  );
};
