import {
  InitialTableState,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { FormatContent, useContent } from 'src/contexts/ContentContext';
import { NoData, Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EventSectionClearingPrices } from 'src/WebApiController';

import {
  EVENT_SECTION_CLEARING_PRICES_TABLE_COLUMNS_CONFIG,
  EventSectionClearingPricesTableColumnId,
  EventSectionClearingPricesTableColumnType,
} from './EventSectionClearingPricesTableColumnsConfig';

const PAGE_SIZE = 10;

export const EventSectionClearingPricesTable = ({
  sectionClearingPrices,
  initState,
}: {
  sectionClearingPrices: EventSectionClearingPrices[] | undefined;
  initState?: InitialTableState;
}) => {
  const sectionsText = useContent(ContentId.Sections);

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || [
      {
        id: EventSectionClearingPricesTableColumnId.SectionName.toString(),
        desc: false,
      },
    ]
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: PAGE_SIZE,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const options: Partial<
    TableOptions<EventSectionClearingPricesTableColumnType>
  > = useMemo(
    () => ({
      data: sectionClearingPrices,
      columns: EVENT_SECTION_CLEARING_PRICES_TABLE_COLUMNS_CONFIG,
      state: {
        pagination,
        sorting,
      },
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
    }),
    [sectionClearingPrices, pagination, sorting]
  );

  return sectionClearingPrices?.length ? (
    <Table options={options} />
  ) : (
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={sectionsText}
      />
    </NoData>
  );
};
