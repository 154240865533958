import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isEmpty, sortBy } from 'lodash-es';
import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { DATA_REFRESH_RATE_IN_MILLIS_SHORT } from 'src/utils/constants/constants';
import { EventConfigClient } from 'src/WebApiController';

export const useEventLastReviewedDateTime = (viagVirtualIds: string[]) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { trackError, showErrorDialog } = useErrorBoundaryContext();

  const queryClient = useQueryClient();
  const queryKey = [
    'EventConfigClient.getLastReviewedDateTimeForEvent',
    sortBy(viagVirtualIds).join(','),
  ];

  const shouldQuery = Boolean(
    activeAccountWebClientConfig.activeAccountId && !isEmpty(viagVirtualIds)
  );
  const eventLastReviewedQuery = useQuery({
    queryKey,
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const response = await new EventConfigClient(
        activeAccountWebClientConfig
      ).getLastReviewedDateTimeForEvents(viagVirtualIds);

      return response;
    },

    enabled: shouldQuery,
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_SHORT,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('EventConfigClient.getLastReviewedDateTimeForEvent', error, {
          viagVirtualIds,
        });
      },
    },
  });

  const mutation = useMutation({
    mutationFn: async ({
      lastReviewedDateTime,
      viagVirtualId,
    }: {
      lastReviewedDateTime: string;
      viagVirtualId: string;
    }) => {
      return await new EventConfigClient(
        activeAccountWebClientConfig
      ).updateLastReviewedDateTimeForEvent(
        viagVirtualId ?? undefined,
        lastReviewedDateTime
      );
    },
    onMutate: ({ viagVirtualId, lastReviewedDateTime }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue: Record<string, string> | undefined =
        queryClient.getQueryData(queryKey);
      if (prevValue) {
        prevValue[viagVirtualId] = lastReviewedDateTime;
        queryClient.setQueryData(queryKey, prevValue);
      } else {
        queryClient.setQueryData(queryKey, {
          [viagVirtualId]: lastReviewedDateTime,
        });
      }
      return { prevValue };
    },
    onError: (err: ErrorTypes, { lastReviewedDateTime }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog(
        'EventConfigClient.updateLastReviewedDateTimeForEvent',
        err,
        {
          trackErrorData: { lastReviewedDateTime },
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries();
    },
  });

  const onUpdateLastReviewedDateTime = useCallback(
    async (viagVirtualId: string | null) => {
      if (viagVirtualId) {
        await mutation.mutateAsync({
          lastReviewedDateTime: new Date().toISOString(),
          viagVirtualId,
        });
      }
    },
    [mutation]
  );

  return {
    eventLastReviewed: eventLastReviewedQuery.data,
    onUpdateLastReviewedDateTime,
    isLoading: eventLastReviewedQuery.isPending || mutation.isPending,
    isCompleted: mutation.isSuccess,
    loadEventLastReviewed: eventLastReviewedQuery.refetch,
  };
};
