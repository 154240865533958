import { useCallback, useMemo } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import {
  BulkEditListingSettingsDialog,
  BulkEditListingSettingsDialogProps,
} from 'src/dialogs/BulkEdits/BulkEditListingSettingsDialog';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  Listing,
  ListingDetails,
  ListingDetailsUpdateInput,
  ListingQuery,
} from 'src/WebApiController';

import {
  INVENTORY_BULK_UPDATE_AUTOPRICING_SETTINGS_UPDATE_KEY,
  INVENTORY_BULK_UPDATE_GENERAL_SETTINGS_UPDATE_KEY,
} from '../../InventoryActionDropdown.constants';
import { LaunchUpdateListingSettings } from './LaunchUpdateListingSettings';

export const useBulkUpdateListingSettings = (
  bulkEditMode: 'general' | 'autoPricing',
  filterQueryWithEventIds: ListingQuery,
  affectedVisibleListingIds: number[],
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const updateKey =
    bulkEditMode === 'general'
      ? event
        ? `listingSettings-event-${event?.viagVirtualId}`
        : INVENTORY_BULK_UPDATE_GENERAL_SETTINGS_UPDATE_KEY
      : event
      ? `autopricingSettings-event-${event?.viagVirtualId}`
      : INVENTORY_BULK_UPDATE_AUTOPRICING_SETTINGS_UPDATE_KEY;

  const { setActivePosEntity } = useActivePosEntityContext<ListingDetails>();

  const {
    eventsTransformed,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const { showErrorDialog } = useErrorBoundaryContext();

  const { openDialog: openBulkEditDialog, closeDialog: closeBulkEditDialog } =
    useDialog<BulkEditListingSettingsDialogProps>(
      DialogId.BulkEditListingSettings,
      BulkEditListingSettingsDialog
    );

  const onSubmit = useCallback(
    (
      listingUpdate: ListingDetailsUpdateInput | null,
      hasPurchaseUpdate: boolean,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        tryInvokeApi(
          async () => {
            const client = await new BulkEditListingClient(
              activeAccountWebClientConfig
            );
            const preview =
              bulkEditMode === 'general'
                ? await client.bulkUpdateGeneralSettingsPreview(
                    filterQueryWithEventIds,
                    hasPurchaseUpdate
                  )
                : await client.bulkUpdateAutoPricingSettingsPreview(
                    filterQueryWithEventIds
                  );

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateGeneralSettingsPreview', error, {
              trackErrorData: {
                filterQueryWithEventIds,
                hasPurchaseUpdate,
                bulkEditMode,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (listingUpdate) {
        tryInvokeApi(
          async () => {
            const client = await new BulkEditListingClient(
              activeAccountWebClientConfig
            );
            const input = {
              item1: preview!.preview,
              item2: listingUpdate,
            };

            const succeeded =
              bulkEditMode === 'general'
                ? await client.bulkUpdateGeneralSettings(
                    input,
                    updateKey,
                    supportBackgroundProcess
                  )
                : await client.bulkUpdateAutoPricingSettings(
                    input,
                    updateKey,
                    supportBackgroundProcess
                  );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }

              closeBulkEditDialog();
            }
          },
          (error) => {
            showErrorDialog(
              bulkEditMode === 'general'
                ? 'bulkUpdateGeneralSettings'
                : 'bulkUpdateAutoPricingSettings',
              error,
              {
                trackErrorData: {
                  listingUpdate,
                  preview,
                  hasPurchaseUpdate,
                  bulkEditMode,
                },
              }
            );
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      bulkEditMode,
      closeBulkEditDialog,
      filterQueryWithEventIds,
      refreshExpandedListItems,
      setActivePosEntity,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  const listings = useMemo(() => {
    return (eventsTransformed ?? [])
      .flatMap((ev) => ev.listings)
      .flatMap(flattenListingGroup)
      .filter(({ id, isLtGrp }) => id > 0 && !isLtGrp) as Listing[];
  }, [eventsTransformed]);

  const openDialog = () => {
    openBulkEditDialog({
      event: event,
      updateKey: updateKey,
      isLoading: isLoading,
      listings: listings,
      selectedListingIds: affectedVisibleListingIds,
      onOkay: onSubmit,
      onClosed: () => {
        setIsLoading(false);
      },
      bulkEditMode: bulkEditMode,
    });
  };

  return {
    dropDown: (
      <LaunchUpdateListingSettings
        key={`LaunchUpdateListingSettings-${bulkEditMode}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openDialog();
        }}
        disabled={isLoading}
        bulkEditMode={bulkEditMode}
      />
    ),
    openDialog: openDialog,
  };
};
