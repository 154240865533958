import clsx from 'clsx';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { useToggle } from 'react-use';
import { EventAccordionItemProps } from 'src/components/Accordions';
import {
  AverageTicketPriceDisplay,
  SalesAndMarginDisplay,
  TicketsListedAndSoldDisplay,
} from 'src/components/Listings/InventorySummaryWidgets';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { useCollapsableViewContext } from 'src/contexts/CollapsableViewContext/CollapsableViewContext';
import { Content } from 'src/contexts/ContentContext';
import {
  MultiSelectScope,
  useMultiSelectionContext,
} from 'src/contexts/MultiSelectionContext';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ColumnSettingsModal } from 'src/modals/ColumnSettings/ColumnSettings';
import { LayoutIcon } from 'src/svgs';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { getInventorySummaryListingMetricsDisplayStrings } from 'src/utils/ticketMetricUtils';
import { SectionType } from 'src/utils/types/sectionType';
import { EventWithData } from 'src/WebApiController';
import { Feature, ListingMetrics } from 'src/WebApiController';

import { LastReviewedDisplay } from '../../InventorySummaryWidgets/LastReviewedDisplay';
import * as styles from '../InventoryEventPage.css';
import { useCatchUpParams } from '../InventoryEventPage.hooks';

type InventoryEventContentProps = {
  accordionItemProps?: EventWithData;
  inventoryAccordionItem?: EventAccordionItemProps;
  selectedListingIds: number[];
  setSelectedListingIds: Dispatch<SetStateAction<number[]>>;
};

export function InventoryEventInfoSection({
  accordionItemProps,
  inventoryAccordionItem,
  selectedListingIds,
  setSelectedListingIds,
}: InventoryEventContentProps) {
  const event = accordionItemProps?.event;
  const { BodyComponent, AfterHeaderComponent } = inventoryAccordionItem || {};
  const { onScroll, isCollapsedView, isEnabled } = useCollapsableViewContext();
  const hasEventLastReviewedFeature = useUserHasFeature(
    Feature.EventLastReviewed
  );

  const hasEventListingTableHotkeysFeature = useUserHasFeature(
    Feature.EventListingTableHotkeys
  );

  const { loginContext } = useAppContext();
  const { selectionMode } = useMultiSelectionContext();
  const isSelectionModeOn = !!selectionMode?.mode;

  const { isCatchupMode } = useCatchUpParams();

  const [isColumnModalOpen, toggleColumnModal] = useToggle(false);
  const { eventMetrics: eventMetricsMap } =
    useCatalogMetricsContext<ListingMetrics>();

  const eventMetrics = eventMetricsMap?.[event?.viagVirtualId ?? ''];
  const formattedTotalMetrics = eventMetrics
    ? getInventorySummaryListingMetricsDisplayStrings(
        eventMetrics,
        loginContext?.user?.activeAccount?.currencyCode
      )
    : undefined;

  const onColumnSettingButtonClickHandler = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isColumnModalOpen) toggleColumnModal(true);
    },
    [isColumnModalOpen, toggleColumnModal]
  );

  return (
    <div className={styles.tableSection}>
      {BodyComponent && accordionItemProps && (
        <div
          className={clsx(
            styles.tableContainer,
            styles.inventoryEventPageTableContainer
          )}
        >
          <Stack
            gap="xl"
            flexWrap="wrap"
            className={clsx({
              [styles.metricsAnimationUncollapsed]: !isCollapsedView,
              [styles.metricsAnimationCollapsed]: isCollapsedView,
            })}
          >
            <TicketsListedAndSoldDisplay
              formattedTotalMetrics={formattedTotalMetrics}
            />
            <SalesAndMarginDisplay
              formattedTotalMetrics={formattedTotalMetrics}
            />
            <AverageTicketPriceDisplay
              formattedTotalMetrics={formattedTotalMetrics}
            />
            {hasEventLastReviewedFeature && !isCatchupMode && (
              <LastReviewedDisplay viagVirtualId={event?.viagVirtualId} />
            )}
          </Stack>
          <Stack justifyContent="end" className={styles.hoistedColumnSettings}>
            {isColumnModalOpen ? (
              <ColumnSettingsModal<CustomListingColumn>
                onClose={toggleColumnModal}
                sectionType={SectionType.Listings}
              />
            ) : null}
            <Stack width="full" direction="column" alignItems="end">
              <Button
                variant="textPlain"
                onClick={onColumnSettingButtonClickHandler}
              >
                <LayoutIcon size={vars.iconSize.m} />
                <Content id={ContentId.Columns} />
              </Button>
            </Stack>
          </Stack>

          <div className={styles.bulkActionsStickyWrapper}>
            {AfterHeaderComponent && <AfterHeaderComponent />}
          </div>
          <div
            className={clsx({
              [styles.inventoryEventDetailsBodyWrapper]: isEnabled,
              [styles.inventoryEventDetailsBodyPadding]:
                !isSelectionModeOn && !isCollapsedView,
            })}
          >
            <BodyComponent
              {...accordionItemProps}
              selectedIds={selectedListingIds}
              onItemSelectionsChanged={setSelectedListingIds}
              selectionMode={{ mode: MultiSelectScope.SingleItem }}
              disablePagination
              useTableNavKeys={hasEventListingTableHotkeysFeature}
              useVirtuoso
              onVirtuosoTableScroll={onScroll}
            />
          </div>
        </div>
      )}
    </div>
  );
}
