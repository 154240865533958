import {
  functionalUpdate,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';
import { VendorsTableColumnId } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsTable/vendorsColumnUtils.types';
import {
  PurchaseVendorsWithActions,
  VENDORS_TABLE_COLUMNS_CONFIG,
} from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsTable/VendorsTableColumnsConfig';
import { Table } from 'src/tables/Table';

const getRowId = (row: PurchaseVendorsWithActions | null, index: number) =>
  (row?.vendor?.id ?? index).toString();

const columns = Object.values(VENDORS_TABLE_COLUMNS_CONFIG);

interface VendorsTableProps {
  vendors: PurchaseVendorsWithActions[];
}

export const VendorsTable = ({ vendors }: VendorsTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([
    { id: VendorsTableColumnId.TotalAccounts, desc: true },
  ]);

  const options: Partial<TableOptions<PurchaseVendorsWithActions | null>> =
    useMemo(
      () => ({
        data: vendors,
        columns: columns,
        state: {
          sorting,
        },
        onSortingChange: (sortingUpdaterFn) => {
          const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
          setSorting(newSortVal);
        },
        getRowId: getRowId,
      }),
      [sorting, vendors]
    );

  return <Table options={options} withOuterPadding tableLayout="fixed" />;
};
