import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { TicketBarcode } from 'src/WebApiController';

import { BarcodeForm } from '../UploadBarcodes.utils';
import { BarcodeInput } from './BarcodeInput';

export const ManualBarcodeEntry = ({
  quantity,
  readOnly = false,
}: {
  quantity: number;
  readOnly?: boolean;
}) => {
  const {
    setValue,
    watch,
    trigger,
    formState: { isDirty },
  } = useFormContext<BarcodeForm>();
  const [showErrors, setShowErrors] = useState<boolean>(isDirty);
  const barcodes = watch('barcodes');

  useEffect(() => {
    // Handle toggle between bulk and manual where extra barcodes were entered
    if (barcodes.length > quantity) {
      setValue('barcodes', barcodes.slice(0, quantity));
      trigger();
    } else if (isDirty) {
      // loaded from a toggle, show errors
      setShowErrors(true);
      trigger();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {barcodes.map((ticketBarcode: TicketBarcode, i: number) => {
        return (
          <BarcodeInput
            key={ticketBarcode.ticketId}
            ticketBarcode={ticketBarcode}
            disabled={readOnly}
            index={i}
            forceShowErrors={showErrors}
          />
        );
      })}
    </>
  );
};
