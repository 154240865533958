import { useNavigate } from 'react-router-dom';
import { Content } from 'src/contexts/ContentContext';
import { Button } from 'src/core/ui';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ContentId } from 'src/utils/constants/contentId';

import { MainRoute } from '../MainRoute';
import * as styles from './Login.css';

export function LoginFailure() {
  return (
    <LayoutContent mainRoute={MainRoute.LoginFailure}>
      <LoginFailurePage />
    </LayoutContent>
  );
}

function LoginFailurePage() {
  const navigate = useNavigate();
  return (
    <div className={styles.loginContainer}>
      <div className={styles.errorContainer}>
        <Content id={ContentId.Error_Unauthorized_Header} />
      </div>
      <Button variant={'regular'} onClick={() => navigate('/login')}>
        <Content id={ContentId.SignIn} />
      </Button>
    </div>
  );
}
