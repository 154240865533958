import { ColumnDef } from '@tanstack/react-table';
import { ActivityLogAdditionalInfo } from 'src/components/ActivityLog/ActivityLogAdditionalInfo';
import { Content } from 'src/contexts/ContentContext';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { TableShimmeringDiv } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import {
  ActivityLog,
  ActivityLogAdditionalInformationDictionaryOfActivityLogValueChange,
} from 'src/WebApiController';

import { ActivityLogDateCell } from './ActivityLogDateCell';

export const ACTIVITY_LOG_TABLE_COLUMNS_CONFIG: ColumnDef<ActivityLog | null>[] =
  [
    {
      id: 'actionDate',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.ActivityLogDate} />
        </TableHeaderCellDiv>
      ),
      maxSize: 90,
      accessorFn: (data) => (data ? data.actionDate || '' : null),
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="left">
            <ActivityLogDateCell date={value ? stringToUtcDate(value) : null} />
          </TableCellDiv>
        );
      },
      sortingFn: 'datetime',
    },
    {
      id: 'actionType',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.ActivityLogActionType} />
        </TableHeaderCellDiv>
      ),
      enableSorting: false,
      accessorFn: (data) => (data ? data.actionTypeDisplay : null),
      cell: ({ getValue, row: { original } }) => {
        const actionTypeDisplay = getValue() as string;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="left" title={actionTypeDisplay}>
            {actionTypeDisplay}
          </TableCellDiv>
        );
      },
    },
    {
      id: 'initiatedBy',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.ActivityLogInitiatedBy} />
        </TableHeaderCellDiv>
      ),
      enableSorting: false,
      accessorFn: (data) => data,
      cell: ({ getValue, row: { original } }) => {
        const value = getValue() as ActivityLog;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="left" overflowWrap="anywhere">
            {value?.initiatedBy?.username}
            {value?.initiatedByImpersonated?.username
              ? ` (impersonating ${value.initiatedByImpersonated.username})`
              : ''}
          </TableCellDiv>
        );
      },
    },
    {
      id: 'additionalInfo',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.ActivityLogAdditionalInfo} />
        </TableHeaderCellDiv>
      ),
      enableSorting: false,
      accessorFn: (data) => (data ? data.additionalInfo : null),
      cell: ({ getValue, row: { original } }) => {
        const value =
          getValue() as ActivityLogAdditionalInformationDictionaryOfActivityLogValueChange;
        if (!original) {
          return <TableShimmeringDiv />;
        }
        return (
          <TableCellDiv align="left">
            <ActivityLogAdditionalInfo {...value} />
          </TableCellDiv>
        );
      },
    },
  ];
