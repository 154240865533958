import { PaymentsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/payment-wrapper';

export function WireIcon(props: PaymentsProps) {
  return (
    <SvgWrapper isSquare={true} {...props}>
      <path
        d="M15.036 3.815c.563.767 1.038 1.747 1.396 2.883a14.093 14.093 0 0 0 1.856-.745 8.724 8.724 0 0 0-3.252-2.138ZM5.702 5.962c.594.287 1.22.536 1.863.74.36-1.14.836-2.122 1.399-2.887a8.755 8.755 0 0 0-3.262 2.147Zm3.102 1.074a14.532 14.532 0 0 0 6.382.005c-.724-2.236-1.887-3.671-3.06-3.765l-.13-.004c-1.234.002-2.446 1.438-3.192 3.764Zm7.109 4.412a16.95 16.95 0 0 0-.385-3.145A15.621 15.621 0 0 1 8.466 8.3c-.22 1-.348 2.059-.38 3.149h7.827Zm.857-3.486c.243 1.097.382 2.267.414 3.487l3.528-.001a8.672 8.672 0 0 0-1.575-4.469c-.758.392-1.551.722-2.367.983Zm-9.962 3.802c.016-1.334.157-2.612.422-3.806a16.009 16.009 0 0 1-2.375-.969 8.677 8.677 0 0 0-1.568 4.478l3.521.297Zm1.284.956c.045 1.138.191 2.232.438 3.262a15.242 15.242 0 0 1 3.427-.395c1.177 0 2.358.133 3.511.393.248-1.028.396-2.122.44-3.26H8.091Zm8.608 3.594c.783.243 1.55.549 2.286.914A8.639 8.639 0 0 0 20.7 12.72h-3.52a18.007 18.007 0 0 1-.48 3.594ZM3.3 12.72a8.659 8.659 0 0 0 1.714 4.508 16.28 16.28 0 0 1 2.278-.913 18.178 18.178 0 0 1-.473-3.595H3.3Zm2.564 5.482a8.737 8.737 0 0 0 3.1 1.983c-.524-.713-.975-1.617-1.325-2.66l-.095.028c-.57.182-1.136.4-1.68.65Zm10.482-.665c-.355 1.042-.81 1.946-1.335 2.659a8.744 8.744 0 0 0 3.092-1.962 13.73 13.73 0 0 0-1.757-.697Zm-7.465-.341c.759 2.217 1.915 3.533 3.119 3.533 1.202 0 2.356-1.312 3.115-3.524a14.393 14.393 0 0 0-6.234-.01ZM12 22a10.004 10.004 0 0 1-7.478-3.368.642.642 0 0 1-.345-.408A9.893 9.893 0 0 1 2 12.099C2 6.487 6.486 2 12 2a9.977 9.977 0 0 1 7.718 3.649c.035.012.106.088.16.176C21.27 7.64 22 9.76 22 12l-.005.291C21.84 17.646 17.356 22 12 22Z"
        fill="#677383"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
