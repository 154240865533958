import { useQuery } from '@tanstack/react-query';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import removeAccents from 'remove-accents';
import { FilterToolbarItemId } from 'src/components/FilterToolbar';
import { FilterDialog } from 'src/components/FilterToolbar/FilterDialog';
import * as filterToolbarStyles from 'src/components/FilterToolbar/FilterToolbar.css';
import { FilterCount } from 'src/components/FilterToolbar/FilterToolbar.styled';
import { useAppContext } from 'src/contexts/AppContext';
import {
  Content,
  useContent,
  useFormattedContent,
} from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { DateRangeSelector } from 'src/core/POS/DateRangeSelector';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button, Popover, Stack } from 'src/core/ui';
import { PillList } from 'src/core/ui/PillList';
import { PillItemProps } from 'src/core/ui/PillList/PillItem';
import { RequestEventDialog } from 'src/dialogs/RequestEventDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import {
  ArtistIcon,
  FilterIcon,
  IconsFill,
  LocationOutlineIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import {
  EVENT_SORT_TO_CID,
  YES_NO_ENUM_FILTER_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EventDateRangePresetNames } from 'src/utils/dateTimeUtils';
import {
  FromYesNoEnum,
  getAppliedFilterCounts,
  getEventSort,
  getEventUiSort,
  ToYesNoEnum,
} from 'src/utils/eventQueryUtils';
import {
  getPerformerAndVenueForEvent,
  sortEventsByDate,
  sortEventsByName,
} from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  CatalogClient,
  CatalogResults,
  DateTimeRange,
  Event,
  EventSearchQuery,
  EventSort,
  EventWithData,
  Performer,
  ValueTupleOfEventAndVenueAndPerformer,
  Venue,
} from 'src/WebApiController';

import * as styles from './EventSearch.css';
import { EventSearchInputBoxContainer } from './EventSearch.styled';
import { EventSearchFilterQuery, EventSearchProps } from './EventSearch.types';
import { EventSearchDropdownResult } from './EventSearchDropdownResult';
import { EventSearchItem } from './EventSearchItem';

const DEFAULT_EVENT_SEARCH_FILTER_QUERY: EventSearchFilterQuery = {
  showParkingTickets: true,
};

export function EventSearch({
  selectedEvents,
  eventsToSearchFrom,
  showEventsToSearchFromOnEmptySearch,
  onEventsSelect,
  searchTextPrefill,
  numEventsSelected,
  onDeleteNumEventsSelected,
  maxNumOfResults,
  allowSearchByVenueConfig,
  searchPlaceHolder,
  isSingleSelect,
  timeFrame,
}: EventSearchProps) {
  const [tempFilterQuery, setTempFilterQuery] =
    useState<EventSearchFilterQuery>(DEFAULT_EVENT_SEARCH_FILTER_QUERY);
  const [filterQuery, setFilterQuery] = useState<EventSearchFilterQuery>(
    DEFAULT_EVENT_SEARCH_FILTER_QUERY
  );

  const [dateFilter, setDateFilter] = useState<
    DateTimeRange | null | undefined
  >();

  const [isAllSelected, setIsAllSelected] = useState<boolean>(false);

  const toggleSelectAll = () => {
    const events =
      (filteredSearchEvents?.events &&
        Object.values(filteredSearchEvents.events)) ??
      [];
    onEventsSelect(
      events.map((eventWithData) => {
        const { event } = eventWithData;
        const catalog = filteredSearchEvents;
        const { performer, venue } = getPerformerAndVenueForEvent(
          eventWithData.event,
          catalog
        );
        return {
          event,
          isSelected: isAllSelected ? false : true,
          performer,
          venue,
        };
      })
    );
    setIsAllSelected((previous) => !previous);
  };

  const filterAppliedCounts = getAppliedFilterCounts(
    tempFilterQuery,
    DEFAULT_EVENT_SEARCH_FILTER_QUERY
  );
  const onResetAll = () => {
    setTempFilterQuery(DEFAULT_EVENT_SEARCH_FILTER_QUERY);
    setFilterQuery(DEFAULT_EVENT_SEARCH_FILTER_QUERY);
  };
  const onSubmitFilter = () => {
    setFilterQuery(tempFilterQuery);
  };

  const numEventsSelectedText = useFormattedContent(
    FormatContentId.EventsSelected,
    numEventsSelected.toString()
  );
  const searchPlaceholderText = useContent(
    searchPlaceHolder ?? ContentId.SearchEventPlaceholderText
  );
  const { activeAccountWebClientConfig } = useAppContext();
  const isMobile = useMatchMedia('mobile');
  const { showErrorDialog } = useErrorBoundaryContext();
  const [searchText, setSearchText] = useState<string>(searchTextPrefill ?? '');
  const [searchTextDebounced, setSearchTextDebounced] = useState<string>();
  const [searchEventsQuery, setSearchEventQuery] = useState<
    EventSearchQuery & {
      performer?: Performer | null;
      venue?: Venue | null;
      event?: Event | null;
    }
  >();
  const [eventSortQuery, setEventSortQuery] = useState<{
    sortBy: EventSort | null;
    isSortDescending: boolean | null;
  }>({ sortBy: null, isSortDescending: false });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchTextChangedDebounced = useCallback(
    debounce((s: string | undefined) => {
      s = s?.trim();
      if (s !== searchTextDebounced) {
        setSearchTextDebounced(s);

        if (
          searchEventsQuery &&
          (!s || searchEventsQuery.performerId || searchEventsQuery.venueId)
        ) {
          // If we have a filterQuery, and we s is null (being reset)
          // or we have either performerId or venueId set
          // we want to invoke a search-event based on s
          searchEventsQuery.searchText = s || null;
          setSearchEventQuery({ ...searchEventsQuery });
        }
      }
    }, 300),
    [searchEventsQuery]
  );

  useEffect(() => {
    if (searchTextPrefill) {
      onSearchTextChangedDebounced(searchTextPrefill);
    }
  }, [onSearchTextChangedDebounced, searchTextPrefill]);

  const getStaticResult = useCallback(
    async (searchText: string) => {
      if (eventsToSearchFrom) {
        const eventResults = eventsToSearchFrom.filter((e) =>
          removeAccents(e.event.name)
            .toLocaleUpperCase()
            .includes(removeAccents(searchText ?? '').toLocaleUpperCase())
        );
        return {
          events: eventResults.reduce<Record<string, EventWithData>>((r, e) => {
            r[e.event.viagVirtualId] = e;
            return r;
          }, {}),
          venueCfgs: allowSearchByVenueConfig
            ? eventResults
                .filter((e) => e.event.venueCfgId)
                .reduce<Record<string, Event[]>>((r, e) => {
                  const key = e.event.venueCfgId!.toString();
                  if (r[key]) {
                    r[key] = [...r[key], e.event];
                  } else {
                    r[key] = [e.event];
                  }
                  return r;
                }, {})
            : {},
        } as CatalogResults;
      }
    },
    [allowSearchByVenueConfig, eventsToSearchFrom]
  );

  const searchEvents = useQuery({
    queryKey: ['searchEvents', searchEventsQuery],
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }

      if (
        !searchEventsQuery?.searchText &&
        !searchEventsQuery?.performerId &&
        !searchEventsQuery?.venueId &&
        !searchEventsQuery?.event
      ) {
        if (eventsToSearchFrom != null && showEventsToSearchFromOnEmptySearch) {
          return getStaticResult('');
        }
        // If all of these are nulls, return nothing
        return null;
      }

      if (eventsToSearchFrom != null) {
        return getStaticResult(searchEventsQuery?.searchText ?? '');
      }

      return tryInvokeApi(
        async () => {
          if (searchEventsQuery.event) {
            return {
              events: {
                [searchEventsQuery.event.viagVirtualId]: {
                  event: searchEventsQuery.event,
                  sales: null,
                  listings: null,
                } as EventWithData,
              },
              performers: searchEventsQuery.performer?.viagId
                ? {
                    [searchEventsQuery.performer?.viagId]:
                      searchEventsQuery.performer,
                  }
                : {},
              venues: searchEventsQuery.venue?.viagId
                ? {
                    [searchEventsQuery.venue?.viagId]: searchEventsQuery.venue,
                  }
                : {},
            } as CatalogResults;
          }

          return new CatalogClient(activeAccountWebClientConfig).searchEvents(
            searchEventsQuery
          );
        },
        (error) => {
          showErrorDialog('CatalogClient.searchEvents', error, {
            trackErrorData: searchEventsQuery,
          });
        }
      );
    },
    refetchOnWindowFocus: false,
  });

  const filteredSearchEvents = useMemo<typeof searchEvents.data>(() => {
    const catalog = searchEvents.data;
    if (!catalog) {
      return null;
    }
    const filteredEvents: typeof catalog.events = {};
    for (const [key, event] of Object.entries(catalog.events)) {
      if (
        dateFilter?.start &&
        (!event.event.dates.start ||
          new Date(event.event.dates.start).valueOf() <
            new Date(dateFilter?.start).valueOf())
      ) {
        continue;
      }
      if (
        dateFilter?.end &&
        (!event.event.dates.start ||
          new Date(event.event.dates.start).valueOf() >
            new Date(dateFilter?.end).valueOf())
      ) {
        continue;
      }
      // filter out parking tickets
      if (
        !filterQuery.showParkingTickets &&
        // TODO we should check the event type here instead
        // currently we don't have that data in the response
        event.event.name.toLowerCase().startsWith('parking passes only')
      ) {
        continue;
      }
      filteredEvents[key] = event;
    }
    return {
      ...catalog,
      events: filteredEvents,
    };
  }, [searchEvents.data, dateFilter, filterQuery.showParkingTickets]);

  const shouldQuery = Boolean(
    searchTextDebounced &&
      !(searchEventsQuery?.performerId && searchEventsQuery?.venueId)
  );

  const autoCompleteResultQuery = useQuery({
    queryKey: [
      'getSearchEventsAutoCompleteResult',
      searchTextDebounced,
      timeFrame,
      maxNumOfResults ?? 10,
    ],
    queryFn: () => {
      return tryInvokeApi(
        async () => {
          if (!shouldQuery) {
            return null;
          }

          if (eventsToSearchFrom != null) {
            return getStaticResult(searchTextDebounced!);
          } else {
            return new CatalogClient(
              activeAccountWebClientConfig
            ).getSearchEventsAutoCompleteResult(
              searchTextDebounced,
              maxNumOfResults ?? 10, // we want 10 at least so we can get the best result up top
              timeFrame
            );
          }
        },
        (error) => {
          showErrorDialog(
            'CatalogClient.getSearchEventsAutoCompleteResult',
            error,
            { trackErrorData: { searchTextDebounced } }
          );
        }
      );
    },
    refetchOnWindowFocus: false,
    enabled: shouldQuery,
  });

  const getItemsToDisplay = useCallback(() => {
    const events =
      (filteredSearchEvents?.events &&
        Object.values(filteredSearchEvents.events)) ??
      [];

    if (!events || events.length === 0) return [];
    const result = [] as React.ReactNode[];
    if (eventSortQuery.sortBy === EventSort.MainDisplay) {
      sortEventsByName(events, eventSortQuery.isSortDescending);
    } else {
      sortEventsByDate(events, eventSortQuery.isSortDescending);
    }

    events.forEach((event) => {
      const isSelected = selectedEvents?.find(
        (seid) => seid === event.event.viagVirtualId
      );
      result.push(
        <EventSearchItem
          event={event.event}
          isSelected={Boolean(isSelected)}
          catalog={filteredSearchEvents!}
          onChecked={(
            event: Event,
            isSelected: boolean,
            performer?: Performer | null,
            venue?: Venue | null
          ) => {
            onEventsSelect([{ event, isSelected, performer, venue }]);
          }}
          isSingleSelect={isSingleSelect}
        />
      );
    });
    return result;
  }, [
    filteredSearchEvents,
    eventSortQuery.sortBy,
    eventSortQuery.isSortDescending,
    selectedEvents,
    isSingleSelect,
    onEventsSelect,
  ]);

  const eventsToDisplay = getItemsToDisplay();

  const onSearchItemSelected = useCallback(
    async (
      searchText: string | null,
      event?: Event | null,
      performer?: Performer | null,
      venue?: Venue | null,
      events?: Event[]
    ) => {
      setSearchText(searchText || '');
      setSearchTextDebounced(undefined);
      if (event != null) {
        setSearchEventQuery({
          searchText: null,
          performerId: null,
          venueId: null,
          performer: performer,
          venue: venue,
          event: event,
          timeFrame: timeFrame ?? null,
        });
        onEventsSelect([{ event, isSelected: true, performer, venue }]);
      } else if (events != null) {
        // venue config selected
        onEventsSelect(
          events.map((ev) => ({
            event: ev,
            isSelected: true,
            performer: null,
            venue: null,
          }))
        );
      } else {
        setSearchEventQuery({
          searchText: searchText || performer?.name || '',
          performer:
            performer ??
            (searchEventsQuery?.event ? null : searchEventsQuery?.performer),
          performerId:
            performer?.viagId ?? searchEventsQuery?.performer?.viagId ?? null,
          venue:
            venue ||
            (searchEventsQuery?.event ? null : searchEventsQuery?.venue),
          venueId: venue?.viagId ?? searchEventsQuery?.venue?.viagId ?? null,
          timeFrame: timeFrame ?? null,
        });
      }
    },
    [
      onEventsSelect,
      searchEventsQuery?.event,
      searchEventsQuery?.performer,
      searchEventsQuery?.venue,
      timeFrame,
    ]
  );

  const hasResult = Boolean(
    (searchEventsQuery || showEventsToSearchFromOnEmptySearch) &&
      eventsToDisplay?.length
  );
  const pills = useMemo(() => {
    const pills = [] as PillItemProps[];
    if (searchEventsQuery?.performer && searchEventsQuery.event == null) {
      pills.push({
        value: 'performer',
        display: searchEventsQuery.performer.name,
        icon: <ArtistIcon size={vars.iconSize.m} />,
        onDelete: () => {
          if (searchEventsQuery) {
            searchEventsQuery.performer = undefined;
            searchEventsQuery.performerId = null;
            setSearchEventQuery({ ...searchEventsQuery! });
          }
        },
      });
    }

    if (searchEventsQuery?.venue && searchEventsQuery.event == null) {
      pills.push({
        value: 'venue',
        display: searchEventsQuery.venue.name,
        icon: <LocationOutlineIcon size={vars.iconSize.m} />,
        onDelete: () => {
          if (searchEventsQuery) {
            searchEventsQuery.venue = undefined;
            searchEventsQuery.venueId = null;
            setSearchEventQuery({ ...searchEventsQuery! });
          }
        },
      });
    }

    if (numEventsSelected && numEventsSelected > 0) {
      pills.push({
        value: 'numEventsSelected',
        display: numEventsSelectedText,
        onDelete: onDeleteNumEventsSelected,
      });
    }

    return pills;
  }, [
    searchEventsQuery,
    numEventsSelected,
    numEventsSelectedText,
    onDeleteNumEventsSelected,
  ]);

  const requestEventDialog = useBasicDialog();

  const onSaveRequestEvent = useCallback(
    async (results: ValueTupleOfEventAndVenueAndPerformer) => {
      if (!results) {
        return;
      }

      // Set the search query so it set the event to be displayed
      setSearchEventQuery({
        ...searchEventsQuery,
        searchText: null,
        event: results.item1,
        venueId: null,
        venue: results.item2,
        performer: results.item3,
        performerId: null,
        timeFrame: timeFrame ?? null,
      });

      requestEventDialog.closeDialog();
    },
    [requestEventDialog, searchEventsQuery, timeFrame]
  );

  const openDropdown =
    searchText &&
    searchTextDebounced &&
    !(searchEventsQuery?.performerId && searchEventsQuery?.venueId);

  return (
    <>
      <Stack
        direction="column"
        gap="l"
        className={styles.eventSearchContainer}
        height="full"
      >
        <Stack direction="column" gap="s">
          <div className={styles.eventSearchInputContainer}>
            <EventSearchInputBoxContainer>
              <PosDropdown
                align="start"
                rootProps={{
                  open: Boolean(openDropdown),
                }}
                trigger={
                  <div className={styles.eventSearchInputSearchBox}>
                    <PosSearchBox
                      value={searchText}
                      placeholder={searchPlaceholderText}
                      maxWidth={'100%'}
                      onSearchChange={(value) => {
                        setSearchText(value);
                        onSearchTextChangedDebounced(value);
                      }}
                      postfixDisplay={
                        hasResult && (
                          <Button
                            variant="textPlain"
                            textColor="strong"
                            shape="none"
                            size="unset"
                            onClick={() => {
                              setSearchEventQuery(undefined);
                              setEventSortQuery({
                                sortBy: null,
                                isSortDescending: true,
                              });
                              setSearchText('');
                            }}
                          >
                            <Content id={ContentId.ResetAll} />
                          </Button>
                        )
                      }
                    />
                  </div>
                }
                onOpenAutoFocus={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                onCloseAutoFocus={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                {openDropdown ? (
                  <EventSearchDropdownResult
                    searchText={searchTextDebounced}
                    isSearching={autoCompleteResultQuery.isFetching}
                    catalogResults={autoCompleteResultQuery.data}
                    onSearchItemSelected={onSearchItemSelected}
                    maxNumOfResults={maxNumOfResults}
                  />
                ) : undefined}
              </PosDropdown>
            </EventSearchInputBoxContainer>

            <div className={styles.eventSearchSortBoxContainer}>
              <Popover.Root onOpenChange={onSubmitFilter}>
                <Popover.Trigger asChild>
                  <div className={filterToolbarStyles.filterToolbarIcon}>
                    <FilterIcon
                      title="filter"
                      fill={
                        filterAppliedCounts
                          ? IconsFill.textBrand
                          : IconsFill.textPrimary
                      }
                      withHoverEffect
                    />
                    {Boolean(filterAppliedCounts) && (
                      <FilterCount>{filterAppliedCounts}</FilterCount>
                    )}
                  </div>
                </Popover.Trigger>
                <Popover.Content
                  align="start"
                  style={{
                    maxHeight: 'var(--radix-popover-content-available-height)',
                  }}
                >
                  <FilterDialog
                    hasFiltersApplied={filterAppliedCounts > 0}
                    onResetAll={onResetAll}
                    onSubmitFilter={onSubmitFilter}
                    filters={[
                      {
                        items: [
                          {
                            filterId:
                              'showParkingTickets' as FilterToolbarItemId,
                            labelContentId: ContentId.ShowParkingTickets,
                            filterQueryKeys: [
                              'showParkingTickets',
                            ] as FilterToolbarItemId[],
                            filterItem: (
                              <PosEnumSelect
                                style={{ width: '100%' }}
                                value={ToYesNoEnum(
                                  tempFilterQuery.showParkingTickets
                                )}
                                valueOptionsContent={YES_NO_ENUM_FILTER_TO_CID}
                                onChange={(yesNoEnumValue) => {
                                  if (
                                    yesNoEnumValue !==
                                    ToYesNoEnum(
                                      tempFilterQuery.showParkingTickets
                                    )
                                  ) {
                                    setTempFilterQuery({
                                      ...tempFilterQuery,
                                      showParkingTickets:
                                        FromYesNoEnum(yesNoEnumValue),
                                    });
                                  }
                                }}
                              />
                            ),
                          },
                          {
                            filterId: 'eventDate' as FilterToolbarItemId,
                            labelContentId: ContentId.EventDate,
                            filterQueryKeys: [
                              'eventDate',
                            ] as unknown as FilterToolbarItemId[],
                            filterItem: (
                              <DateRangeSelector
                                presetNames={EventDateRangePresetNames}
                                value={dateFilter}
                                onBlur={(value) => {
                                  setDateFilter(value);
                                }}
                              />
                            ),
                          },
                        ],
                      },
                    ]}
                  />
                </Popover.Content>
              </Popover.Root>
            </div>
          </div>

          <PillList pills={pills} />
        </Stack>

        <div className={styles.buttonsContainer}>
          {hasResult && !isSingleSelect && (
            <Button variant="text" onClick={toggleSelectAll}>
              <Content
                id={isAllSelected ? ContentId.SelectNone : ContentId.SelectAll}
              />
            </Button>
          )}
          {hasResult && (
            <PosEnumSelect
              variant="outline"
              shape="pill"
              value={
                !isMobile
                  ? getEventUiSort(
                      eventSortQuery.sortBy,
                      eventSortQuery.isSortDescending
                    )
                  : null
              }
              defaultValue={
                isMobile ? null : getEventUiSort(EventSort.Date, true)
              }
              onChange={(sort) => {
                const [sortBy, isSortDescending] = getEventSort(sort);
                if (
                  sortBy !== eventSortQuery.sortBy ||
                  isSortDescending !== eventSortQuery.isSortDescending
                ) {
                  setEventSortQuery({
                    ...eventSortQuery,
                    sortBy,
                    isSortDescending,
                  });
                }
              }}
              valueOptionsContent={EVENT_SORT_TO_CID}
            />
          )}
        </div>
        <div className={styles.eventSearchResultContainer}>
          {!hasResult ? (
            searchEvents.isPending ? (
              <PosSpinner />
            ) : (
              <>
                <div>
                  <Content id={ContentId.SearchEmptyResult} />
                </div>
                {eventsToSearchFrom == null && (
                  <div>
                    <Button
                      variant="text"
                      onClick={requestEventDialog.launchDialog}
                    >
                      <Content id={ContentId.RequestEvent} />
                    </Button>
                  </div>
                )}
              </>
            )
          ) : (
            <Virtuoso
              style={{ width: '100%' }}
              data={eventsToDisplay}
              overscan={window.innerHeight}
              itemContent={(i, d) => d}
            />
          )}
        </div>
      </Stack>
      {requestEventDialog.dialogProps.isOpen && (
        <RequestEventDialog
          {...requestEventDialog.dialogProps}
          onSave={onSaveRequestEvent}
          onCancel={requestEventDialog.closeDialog}
        />
      )}
    </>
  );
}
