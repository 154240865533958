import { clsx } from 'clsx';
import { ReactNode, useCallback, useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { Colors } from 'src/core/themes/constants/colors';
import {
  copyContentWrapper,
  copyIcon,
  copyIconHover,
  copyToClipboardDisplay,
  hidden,
  showCopyIcon,
  successText,
} from 'src/core/ui/CopyToClipboard/CopyToClipboardV2.css';
import useCopyToClipboard from 'src/hooks/useCopyToClipboard';
import { CopyIcon2 } from 'src/svgs/CopyIcon2';
import { ContentId } from 'src/utils/constants/contentId';

const SHOW_COPIED_ICON_TIME = 1000;

interface CopyToClipboardPops {
  textToCopy?: string | number;
  children: ReactNode;
}

export const CopyToClipboardV2 = ({
  textToCopy,
  children,
}: CopyToClipboardPops) => {
  const { isDarkMode } = useSiteTheme();
  const [showingCopiedElement, setShowingCopiedElement] =
    useState<boolean>(false);
  const { copyToClipboard } = useCopyToClipboard();

  const copy = useCallback(async () => {
    if (
      showingCopiedElement ||
      textToCopy == null ||
      String(textToCopy).length === 0
    ) {
      return;
    }
    await copyToClipboard(String(textToCopy));
    setShowingCopiedElement(true);
    const timeoutId = setTimeout(
      () => setShowingCopiedElement(false),
      SHOW_COPIED_ICON_TIME
    );
    return () => {
      clearTimeout(timeoutId);
    };
  }, [copyToClipboard, showingCopiedElement, textToCopy]);

  return (
    <div onClick={copy} className={copyToClipboardDisplay}>
      <div className={copyContentWrapper}>
        <span className={showingCopiedElement ? hidden : ''}>{children}</span>
        {showingCopiedElement && (
          <span className={successText}>
            <Content id={ContentId.CopiedWithExclamationMark} />
          </span>
        )}
      </div>
      <CopyIcon2
        className={clsx(copyIcon, {
          [copyIconHover]: !showingCopiedElement,
          [showCopyIcon]: showingCopiedElement,
        })}
        stroke={isDarkMode ? Colors.white : Colors.black}
        withHoverEffect
        fill="transparent"
        size={vars.iconSize.m}
      />
    </div>
  );
};
