import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function PaperTicketOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M10.3989904,3.0003168 L10.5736604,3.39996603 C10.8194533,3.96234629 11.3756279,4.33338135 12,4.33338135 C12.6243721,4.33338135 13.1805467,3.96234629 13.4263396,3.39996603 L13.4263396,3.39996603 L13.6010096,3.0003168 L18,3 L18,21.003381 L6,21.003381 L6,3 L10.3989904,3.0003168 Z M16.6666667,4.33249246 L14.4328889,4.33338135 L14.3906422,4.4004874 C13.8955989,5.129473 13.0854427,5.60450567 12.1818901,5.66103715 L12.1818901,5.66103715 L12,5.66671468 C11.0239787,5.66671468 10.1374039,5.17807204 9.60935784,4.4004874 L9.60935784,4.4004874 L9.56622222,4.33338135 L7.33333333,4.33249246 L7.33333333,19.670048 L16.6666667,19.670048 L16.6666667,4.33249246 Z M15,15 L15,18 L14,18 L14,15 L15,15 Z M11.5,15 L11.5,18 L10.5,18 L10.5,15 L11.5,15 Z M13.3,15 L13.3,18 L12.5,18 L12.5,15 L13.3,15 Z M9.8,15 L9.8,18 L9,18 L9,15 L9.8,15 Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
