import { ChangeEvent, useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Popover } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { ToggleGroup } from 'src/core/ui/ToggleGroup';
import { TooltipPopover } from 'src/core/ui/TooltipPopover';
import { useListingHasVenueMapInfo } from 'src/hooks/useListingHasVenueMapInfo';
import { useUndercutAmountSettings } from 'src/hooks/useUndercutAmountSettings';
import { useUserCanSetPrice } from 'src/hooks/useUserHasListingPermissions';
import {
  CheckIcon,
  CrossIcon,
  IconsFill,
  ProcessingIcon,
} from 'src/svgs/Viagogo';
import {
  getUndercutPriceRepresentingMode,
  PricingSettingsFieldNumeric,
  UNDERCUT_PRICE_MODE_OPTIONS,
  UndercutPriceSettingsMode,
} from 'src/utils/autoPricingUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { Listing } from 'src/WebApiController';

import { ListingPricingInput } from './ListingPriceForm.types';

type UndercutAmountCellProps = {
  formattedNumber?: string | null;
  isPremium: boolean;
  listing: Listing;
  editable?: boolean;
  field: PricingSettingsFieldNumeric;
  disabled?: boolean;
  viagVirtualId?: string;
};

export const UndercutAmountCell = ({
  formattedNumber,
  isPremium,
  listing,
  editable,
  field,
  disabled,
  viagVirtualId,
}: UndercutAmountCellProps) => {
  const { setValue, watch, clearErrors, getFieldState, formState } =
    useFormContext<ListingPricingInput>();
  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(viagVirtualId);
  const isSelectionMode = listingSelection.isSingleGroupMultiSelect;

  const saveMsg = useContent(ContentId.Save);

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingPrice = watch('isSubmitting');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');
  const autoPricingEnabled = watch('autoPricingEnabled');
  const undercutMode = watch('undercutMode');
  const undercutAbsoluteAmount = watch('undercutAbsoluteAmount');
  const undercutRelativeAmount = watch('undercutRelativeAmount');
  const fieldValue = watch(field);

  const {
    undercutAbsoluteAmountMode,
    setUndercutAbsoluteAmountMode,
    undercutRelativeAmountMode,
    setUndercutRelativeAmountMode,
  } = useUndercutAmountSettings(undercutAbsoluteAmount, undercutRelativeAmount);

  const undercutAmountMode = useMemo(
    () =>
      field === 'undercutAbsoluteAmount'
        ? undercutAbsoluteAmountMode
        : undercutRelativeAmountMode,
    [field, undercutAbsoluteAmountMode, undercutRelativeAmountMode]
  );
  const setUndercutAmountMode = useMemo(
    () =>
      field === 'undercutAbsoluteAmount'
        ? setUndercutAbsoluteAmountMode
        : setUndercutRelativeAmountMode,
    [field, setUndercutAbsoluteAmountMode, setUndercutRelativeAmountMode]
  );

  const { loginContext } = useAppContext();

  const { getUiCurrency } = useLocalizationContext();
  const uiCurrency = useMemo(
    () =>
      getUiCurrency(
        listing?.currency ?? loginContext?.user?.activeAccount?.currencyCode
      ),
    [
      getUiCurrency,
      listing?.currency,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );

  const canSetPrice = useUserCanSetPrice(listing, false);
  const { hasVenueMapInfo } = useListingHasVenueMapInfo(listing);
  const isAutoPricingDisabled = !(hasVenueMapInfo && canSetPrice);
  const isUnderCutAmountDisabled =
    disabled ||
    listing?.isDeleted ||
    !canSetPrice ||
    !autoPricingEnabled ||
    undercutMode == null ||
    isAutoPricingDisabled;

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const fieldError = getFieldState(field, formState)?.error?.message;

  const fieldHasChanged =
    formState.defaultValues?.[field] !== fieldValue &&
    !isSubmittingPricingSettings;

  const submit = useCallback(() => {
    if (fieldError) {
      return;
    }
    if (fieldHasChanged) {
      // Only submit if Enter is hit in the box that has changes
      setValue('isSubmittingPricingSettings', true);
      setValue('isSubmittingRowIndex', rowIndex);
    }
  }, [fieldError, fieldHasChanged, setValue, rowIndex]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      submit();
    }
  };

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      clearErrors(field);

      let v = parseFloat(e.target.value);
      if (v >= 0 && v <= Number.MAX_VALUE) {
        if (field === 'undercutRelativeAmount') {
          v = Math.min(v, 100) / 100;
        }
        v = getUndercutPriceRepresentingMode(
          v,
          undercutAmountMode ?? UndercutPriceSettingsMode.Discount
        );
        setValue(field, v);
      } else {
        setValue(field, null);
      }
    },
    [clearErrors, field, setValue, undercutAmountMode]
  );

  const postfixDisplay = (
    <>
      {field === 'undercutRelativeAmount' && '%'}{' '}
      <ToggleGroup
        itemStyle={{
          fontSize: vars.typography.fontSize['xs'],
          height: '22px',
        }}
        disabled={isUnderCutAmountDisabled || !fieldValue}
        options={UNDERCUT_PRICE_MODE_OPTIONS}
        value={undercutAmountMode}
        defaultValue={undercutAmountMode}
        onValueChange={(value) => {
          if (!value) return;

          const mode = value as UndercutPriceSettingsMode;
          setUndercutAmountMode(mode);

          if (fieldValue) {
            const curAmountAbs = Math.abs(fieldValue);
            setValue(
              field,
              mode == UndercutPriceSettingsMode.Premium
                ? -curAmountAbs
                : curAmountAbs
            );
          }
        }}
      />
      {fieldIsSubmitting ? (
        <RotatingWrapper>
          <ProcessingIcon size={vars.iconSize.l} />
        </RotatingWrapper>
      ) : fieldHasChanged && !fieldError ? (
        <CheckIcon
          title={saveMsg}
          withHoverEffect
          size={vars.iconSize.s}
          fill={IconsFill.textSuccess}
          onClick={(e) => {
            e.stopPropagation();
            submit();
          }}
        />
      ) : null}
      <Popover.Close asChild>
        <CrossIcon withHoverEffect />
      </Popover.Close>
    </>
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <TooltipPopover
        variant="link"
        triggerContent={
          <>
            <span>
              {formattedNumber != null ? (
                formattedNumber
              ) : isUnderCutAmountDisabled ? (
                ''
              ) : (
                <Content id={ContentId.Edit} />
              )}
            </span>
            {formattedNumber && (
              <>
                {' '}
                <Content
                  id={isPremium ? ContentId.Premium : ContentId.Discount}
                />
              </>
            )}
          </>
        }
        style={
          !editable
            ? { color: vars.color.textPrimary }
            : isUnderCutAmountDisabled
            ? { color: vars.color.textPrimary }
            : {}
        }
        disabled={
          isUnderCutAmountDisabled ||
          isSelectionMode ||
          fieldIsSubmitting ||
          disabled ||
          !editable
        }
        onClosed={() => {
          if (formState.defaultValues?.[field]) {
            setValue(field, formState.defaultValues?.[field] ?? null);
          }
        }}
      >
        <PosFormField
          errors={disabled ? undefined : fieldError}
          showErrorsInline
          style={{ width: 'fit-content' }}
        >
          {field === 'undercutRelativeAmount' ? (
            <PosTextField
              postfixDisplay={postfixDisplay}
              type="number"
              value={
                undercutMode == null
                  ? ''
                  : fieldValue != null
                  ? roundToPrecision(Math.abs(fieldValue) * 100, 2)
                  : ''
              }
              disabled={
                disabled ||
                isSelectionMode ||
                fieldIsSubmitting ||
                isUnderCutAmountDisabled
              }
              onChange={onChangeHandler}
              onKeyDown={handleKeyDown}
            />
          ) : (
            <PosCurrencyField
              disabled={
                disabled ||
                isSelectionMode ||
                fieldIsSubmitting ||
                isUnderCutAmountDisabled
              }
              uiCurrency={uiCurrency}
              value={
                undercutMode == null
                  ? ''
                  : undercutAbsoluteAmount != null
                  ? Math.abs(undercutAbsoluteAmount)
                  : ''
              }
              onChange={onChangeHandler}
              onKeyDown={handleKeyDown}
              postfixDisplay={postfixDisplay}
            />
          )}
        </PosFormField>
      </TooltipPopover>
    </TableCellDiv>
  );
};
