import { subMinutes } from 'date-fns';
import { isEqual } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { InHandDateInput } from 'src/components/Input/InHandDateInput';
import { Event } from 'src/WebApiController';

import { SellerAccountEventSettingsInput } from './FulfillmentSection.types';

export const InHandCell = ({
  event,
  disabled,
  index,
}: {
  event?: Event;
  disabled?: boolean;
  index: number;
}) => {
  const { watch, setValue } = useFormContext<SellerAccountEventSettingsInput>();

  const inHandAt = watch(`settings.${index}.inHandAt`);

  const inHandDate = inHandAt ? new Date(inHandAt) : new Date();

  return (
    <InHandDateInput
      disabled={disabled}
      allowEmptySelection
      onChange={(date, daysBeforeEvent) => {
        if (!isEqual(date, inHandDate)) {
          if (date != null) {
            // We need to take off the timezone offset from the date
            // because the InHandDateInput uses same timezome as the event
            date = subMinutes(date, date.getTimezoneOffset());
          }
          setValue(`settings.${index}.inHandAt`, date?.toISOString() ?? null, {
            shouldDirty: true,
          });
        }
      }}
      value={inHandAt}
      targetDate={
        event?.dates?.start ? new Date(event?.dates?.start) : new Date()
      }
    />
  );
};
