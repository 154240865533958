import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosSelectProps } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PurchaseClient, PurchaseVendor } from 'src/WebApiController';

export type UsePurchaseVendorSelectorOptions = {
  disabled?: boolean;
  additionalOptions?: Record<string, string>;
  additionalOptionsPosition?: 'top' | 'bottom';
  placeholderText?: string | ContentId;
};

export const usePurchaseVendorSelector = ({
  disabled,
  additionalOptions,
  additionalOptionsPosition = 'bottom',
  placeholderText,
}: UsePurchaseVendorSelectorOptions): {
  query: UseQueryResult<PurchaseVendor[] | null>;
  availableVendors: Record<string, PurchaseVendor>;
  posSelectProps: Pick<
    PosSelectProps,
    | 'placeholderText'
    | 'valueOptionsContent'
    | 'sortMode'
    | 'searchable'
    | 'sortFn'
    | 'loading'
  >;
} => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const shouldQuery =
    !disabled && activeAccountWebClientConfig.activeAccountId != null;
  const query = useQuery({
    queryKey: [
      'getAccessibleVendors',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return tryInvokeApi(
        () => {
          return new PurchaseClient(
            activeAccountWebClientConfig
          ).getAccessibleVendors(false, false);
        },
        (error) => {
          trackError('PurchaseClient.getAccessibleVendors', error);
        }
      );
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    networkMode: 'offlineFirst',
  });

  const availableVendors = useMemo(() => {
    const dataSorted = query.data;
    dataSorted?.sort((a: PurchaseVendor, b: PurchaseVendor) =>
      a.name.localeCompare(b.name)
    );

    const results = dataSorted?.reduce(
      (r, cur) => {
        r[cur.id] = cur;
        return r;
      },
      {} as Record<string, PurchaseVendor>
    );

    return results ?? {};
  }, [query.data]);

  const availableVendorsOptions = useMemo(() => {
    const dataSorted = query.data;
    dataSorted?.sort((a: PurchaseVendor, b: PurchaseVendor) =>
      a.name.localeCompare(b.name)
    );

    const results = dataSorted?.reduce(
      (r, cur) => {
        r[cur.id] = cur.name;
        return r;
      },
      (additionalOptions && additionalOptionsPosition === 'top'
        ? additionalOptions
        : {}) as Record<string, string>
    );

    return {
      ...(additionalOptionsPosition === 'top' ? additionalOptions : {}),
      ...(results ?? {}),
      ...(additionalOptionsPosition === 'bottom' ? additionalOptions : {}),
    };
  }, [query.data, additionalOptions, additionalOptionsPosition]);

  return {
    query,
    availableVendors,
    posSelectProps: {
      loading: query.isLoading,
      placeholderText: placeholderText ?? ContentId.ChooseVendor,
      valueOptionsContent: availableVendorsOptions,
      sortMode: 'ascending',
      searchable: true,
      sortFn: (a: string, b: string) => {
        const addOptionTexts = Object.values(additionalOptions ?? {});
        if (addOptionTexts.includes(a)) {
          return additionalOptionsPosition === 'top' ? -1 : 1;
        }
        if (addOptionTexts.includes(b)) {
          return additionalOptionsPosition === 'top' ? 1 : -1;
        }
        return a.localeCompare(b);
      },
    },
  };
};
