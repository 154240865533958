import clsx from 'clsx';
import { PropsWithChildren, ReactNode } from 'react';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';

import * as styles from './DetailSection.css';

export const DetailSection = ({
  name,
  children,
  extraContent,
  fullHeight,
  className,
}: PropsWithChildren<{
  name: ReactNode;
  extraContent?: ReactNode;
  fullHeight?: boolean;
  className?: string;
}>) => {
  const isMobile = useMatchMedia('mobile');
  return (
    <Stack
      direction="column"
      gap="l"
      className={clsx(styles.detailSection, className)}
      height={fullHeight ? 'full' : 'fit'}
    >
      <Stack
        direction={isMobile ? 'column' : 'row'}
        gap="l"
        alignItems="start"
        height={fullHeight ? 'full' : 'fit'}
      >
        <Stack
          direction={isMobile ? 'row' : 'column'}
          gap="m"
          className={isMobile ? undefined : styles.sectionHeaderContainer}
        >
          <span className={styles.sectionHeader}>{name}</span>
        </Stack>
        <Stack
          direction="column"
          gap="m"
          className={styles.detailSectionContent}
          height={fullHeight ? 'full' : 'fit'}
        >
          {children}
        </Stack>
      </Stack>
      {extraContent}
    </Stack>
  );
};
