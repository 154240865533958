import { ReactNode } from 'react';

import { DragHandles } from './PosDragNDrop.styled';

export interface PosDraggableIndicatorHandles {
  topHandle?: boolean;
  rightHandle?: boolean;
  bottomHandle?: boolean;
  leftHandle?: boolean;
}

interface PosDraggableIndicatorProps extends PosDraggableIndicatorHandles {
  children: ReactNode;
  classNames?: string;
  removeHoverEffect?: boolean;
}

export function PosDraggableIndicator({
  children,
  topHandle = true,
  rightHandle = true,
  bottomHandle = true,
  leftHandle = true,
  classNames,
  removeHoverEffect = false,
}: PosDraggableIndicatorProps) {
  return (
    <DragHandles
      topHandle={topHandle}
      rightHandle={rightHandle}
      bottomHandle={bottomHandle}
      leftHandle={leftHandle}
      className={classNames}
      removeHoverEffect={removeHoverEffect}
    >
      {children}
    </DragHandles>
  );
}
