import { useMemo } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AdGroupsFlattenedView } from 'src/components/AdGroupsFlattenedView/AdGroupsFlattenedView';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { AdCampaignIdProvider } from 'src/contexts/AdCampaignIdContext/AdCampaignIdContext';
import { AdCampaignsDataContextProvider } from 'src/contexts/AdCampaignsDataContext/AdCampaignsDataContext';
import {
  AdGroupsDataContextProvider,
  useAdGroupsDataContext,
} from 'src/contexts/AdGroupsDataContext/AdGroupsDataContext';
import {
  AdPlatformCatalogDataContextProvider,
  useAdPlatformCatalogDataContext,
} from 'src/contexts/AdPlatformCatalogDataContext';
import { AdPlatformReportingContextProvider } from 'src/contexts/AdPlatformReportingContext/AdPlatformReportingContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogProvider } from 'src/contexts/DialogContext/DialogContext';
import { SellerUserSettingsProvider } from 'src/contexts/SellerUserSettingsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { SearchSolidIcon } from 'src/svgs/Viagogo';
import { AdCampaignQueryParam } from 'src/utils/constants/constants';
import { ContentId } from 'src/utils/constants/contentId';
import { removeDeepLinkUrlPartsFromRelativeUrl } from 'src/utils/deepLinkUtils';
import { SomethingWentWrong } from 'src/views';
import { TransactionAggType, UserSetting } from 'src/WebApiController';

import { getDefaultReturnUrl } from '../InventoryEvent/constants';
import { MainRoute } from '../MainRoute';
import { AdGroupsToolbar } from '../SponsoredListings/AdGroupsToolbar/AdGroupsToolbar';

const SPONSOREDLISTINGS_USER_SETTINGS: UserSetting[] = [];

export const AdCampaign = () => {
  const [searchParams] = useSearchParams();
  const adCampaignId = searchParams.get(AdCampaignQueryParam);

  if (!adCampaignId) {
    return null;
  }

  return (
    <SellerUserSettingsProvider
      initialUserSettingIds={SPONSOREDLISTINGS_USER_SETTINGS}
      currentLoginUserOnly={true}
    >
      <AdPlatformCatalogDataContextProvider>
        <AdCampaignsDataContextProvider adCampaignId={adCampaignId}>
          <AdGroupsDataContextProvider
            adCampaignId={adCampaignId}
            adGroupId={undefined}
          >
            <AdPlatformReportingContextProvider
              aggType={TransactionAggType.AdGroupId}
              adCampaignId={adCampaignId}
            >
              <AdGroupsContent adCampaignId={adCampaignId} />
            </AdPlatformReportingContextProvider>
          </AdGroupsDataContextProvider>
        </AdCampaignsDataContextProvider>
      </AdPlatformCatalogDataContextProvider>
    </SellerUserSettingsProvider>
  );
};

export const AdGroupsContent = ({ adCampaignId }: { adCampaignId: string }) => {
  const location = useLocation();

  const returnUrl = useMemo(() => {
    const relativeUrl =
      (location.state as { returnUrl?: string })?.returnUrl ||
      getDefaultReturnUrl(adCampaignId);

    return removeDeepLinkUrlPartsFromRelativeUrl(relativeUrl, [
      AdCampaignQueryParam,
    ]);
  }, [adCampaignId, location.state]);

  const {
    adGroupsDataQuery,
    adGroupsData,
    errorInfo: errorInfoAdGroups,
  } = useAdGroupsDataContext();

  const { errorInfo: errorInfoCatalog } = useAdPlatformCatalogDataContext();

  const errorInfo = errorInfoAdGroups ?? errorInfoCatalog;

  return (
    <AdCampaignIdProvider>
      <DialogProvider>
        <LayoutContent mainRoute={MainRoute.SponsoredListings} routeTitle="">
          <Stack direction="column" width="full" height="full">
            {errorInfo == null &&
              (adGroupsDataQuery.isLoading ||
                adGroupsDataQuery.data == null) && <PosSpinner />}
            {errorInfo && (
              <SomethingWentWrong
                header={errorInfo.errorHeader}
                message={
                  errorInfo.errorMessage ?? (
                    <Content id={ContentId.FailToLoadListContent} />
                  )
                }
              />
            )}
            {!(adGroupsDataQuery.isLoading || adGroupsDataQuery.data == null) &&
              !errorInfo && (
                <>
                  <AdGroupsToolbar returnUrl={returnUrl ?? undefined} />
                  {adGroupsData?.length ? (
                    <AdGroupsFlattenedView />
                  ) : (
                    <EmptySectionContent.Root
                      icon={
                        <EmptySectionContent.SolidIconContainer>
                          <SearchSolidIcon />
                        </EmptySectionContent.SolidIconContainer>
                      }
                    >
                      <EmptySectionContent.Label>
                        <Content id={ContentId.NoResultFound} />
                      </EmptySectionContent.Label>
                      <EmptySectionContent.DetailMessage>
                        <Content id={ContentId.NoEventsMatching} />
                      </EmptySectionContent.DetailMessage>
                    </EmptySectionContent.Root>
                  )}
                </>
              )}
          </Stack>
        </LayoutContent>
      </DialogProvider>
    </AdCampaignIdProvider>
  );
};
