import { vars } from 'src/core/themes';
import styled from 'styled-components';

export const StyledAdGroupCreateBody = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;

  @supports (-webkit-touch-callout: none) {
    max-height: -webkit-fill-available;
  }
`;

export const HeaderRightGroup = styled.div<{ editMode?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ editMode, theme: { media, spacing } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      text-align: ${editMode ? 'left' : 'right'};
      max-width: calc(50% - ${spacing(4)});
      align-items: ${editMode ? 'flex-start' : 'flex-end'};
      `
    )}`}
`;

export const HeaderLeftGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme: { media, spacing } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      max-width: calc(50% - ${spacing(4)});
      `
    )}`}
`;

export const HeaderContainer = styled.div`
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('title4')};
  padding-block-end: ${(props) => props.theme.spacing(4)};

  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const BodySectionTitle = styled.div`
  ${(props) => props.theme.typography.getStyles('subtitle1')};
  color: ${(props) => props.theme.colors.textBrand};
  text-transform: uppercase;
  margin: 0;
  padding-block-end: ${() => vars.spacing.lg};
`;

export const BodySectionBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-block-end: ${() => vars.spacing.sm};
  ${({
    theme: {
      typography: { getStyles },
    },
  }) => getStyles('subtitle1', 'regular')};

  ${({ theme: { media } }) =>
    `${media.makeMediaQuery(
      media.breakpoints.md,
      `
      padding-block-end: ${vars.spacing.lg};
      `
    )}`}
`;
