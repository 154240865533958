import { useCallback, useEffect, useState } from 'react';
import { ButtonWithIcon, IconButton } from 'src/components/Buttons';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { vars } from 'src/core/themes';
import { ButtonProps } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { SignalIcon } from 'src/svgs';
import { DuplicateOutlinedIcon } from 'src/svgs/DuplicateOutlinedIcon';
import { PendingQCIcon } from 'src/svgs/PendingQC';
import { ContentId } from 'src/utils/constants/contentId';
import {
  getListingBroadcastIcon,
  getListingGroupForListing,
} from 'src/utils/inventoryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  Event,
  Feature,
  Listing,
  ListingClient,
  ListingDetails,
  ListingGroupClient,
  ListingMetrics,
  ListingStatus,
  Marketplace,
  MarketplaceListingStatusInfo,
  QualityControlState,
} from 'src/WebApiController';

export const LaunchBroadcastListing = ({
  forTable,
  listing,
  event,
  variant,
  iconOnlyMode,
  disabled,
  lockListingGroupFromEdits,
}: {
  forTable: boolean;
  listing: Listing;
  event?: Event | null;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
  lockListingGroupFromEdits?: (lock: boolean) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { showErrorDialog, trackError } = useErrorBoundaryContext();
  const { posEntity, updateActivePosEntityInfo } =
    useActivePosEntityContext<ListingDetails>();

  const { activeAccountWebClientConfig } = useAppContext();
  const {
    updateItemInEvent,
    eventsExpansion: { refreshExpandedListItems },
    eventsTransformed,
  } = useCatalogDataContext();
  const { refreshMetrics } = useCatalogMetricsContext<ListingMetrics>();

  const hasFastStatusFeature = useUserHasFeature(
    Feature.SlimListingBroadcastStatus
  );

  const isNotListed =
    listing.status !== ListingStatus.Listed &&
    listing.status !== ListingStatus.ListingPending;

  useEffect(() => {
    // We're only polling in this for the table, because the modal has the MarketplaceBroadcastSection polling already
    if (forTable) {
      // If we have any Marketplace listing pending status - we need to poll to get the latest status
      const statusChecking = setInterval(async () => {
        const needChecking =
          listing.status === ListingStatus.ListingPending ||
          listing.status === ListingStatus.DelistingPending;

        if (
          needChecking &&
          !isLoading &&
          activeAccountWebClientConfig.activeAccountId
        ) {
          await tryInvokeApi(
            async () => {
              setIsLoading(true);

              const client = new ListingClient(activeAccountWebClientConfig);

              const newListing = hasFastStatusFeature
                ? await client.getListingStatusByListingId(listing.id)
                : await client.getListingByListingId(listing.id);

              if (newListing) {
                // We we get back a listing from server, it may or may not have finished the job
                // So we just have to assume the status is flowing in one direct only, ie, if we set something to pending,
                // we expect it to either finish or failed (but not reversed)
                let newStatus: ListingStatus | undefined = undefined;
                if (listing.status === ListingStatus.DelistingPending) {
                  // If we are delisting-pending, set if the new status is either Delisted or DelistingFailed
                  if (
                    newListing.status === ListingStatus.Disabled ||
                    newListing.status === ListingStatus.Delisted ||
                    newListing.status === ListingStatus.DelistingFailed
                  ) {
                    newStatus = newListing.status;
                  }
                } else if (listing.status === ListingStatus.ListingPending) {
                  // If we are listing-pending, set if the new status is either Listed or ListedFailed
                  if (
                    newListing.status === ListingStatus.Disabled ||
                    newListing.status === ListingStatus.Listed ||
                    newListing.status === ListingStatus.ListingFailed
                  ) {
                    newStatus = newListing.status;
                  }
                }

                let updatedListing = newListing as ListingDetails;
                if (hasFastStatusFeature) {
                  const newMkpListings = newListing.mkpListings.reduce(
                    (r, c) => {
                      r[c.mkp] = c;
                      return r;
                    },
                    {} as Record<Marketplace, MarketplaceListingStatusInfo>
                  );

                  updatedListing = {
                    ...(posEntity ?? listing),
                    ...newListing,
                    // for marketplaces, we also need to merge since the MarketplaceListingStatusInfo is just a subset of the MarketplaceListingDetails
                    mkpListings: (posEntity ?? listing).mkpListings.map(
                      (mkp) => ({
                        ...mkp,
                        ...(newMkpListings[mkp.mkp] ?? {}),
                      })
                    ),
                  } as ListingDetails;
                }

                // Only if we do get status changes shall we try to actually update the active-item
                // provider with the latest states, or else the toggle will jump back and forth due
                // to the useEffect earlier (since we modified the active listing)
                if (newStatus) {
                  // Only stop running this if all the status is no longer pending
                  clearInterval(statusChecking);

                  refreshMetrics?.();
                  updateItemInEvent(
                    updatedListing,
                    ActionOutboxEntityType.Listing
                  );
                  if (posEntity && updatedListing.id === posEntity.id) {
                    updateActivePosEntityInfo({
                      posEntity: updatedListing,
                      posEntityId: updatedListing!.id,
                      posEntityDisplayId: updatedListing!.idOnMkp,
                    });
                  }
                }
              }
            },
            (error) => {
              // Because this is a background process - we don't want to show error dialog
              trackError(
                'LaunchBroadcastListing.getListingByListingId',
                error,
                {
                  listingId: listing.id,
                }
              );
            },
            () => setIsLoading(false)
          );
        }
      }, 2000 /* 2 sec */);

      return () => clearInterval(statusChecking);
    }
  }, [
    activeAccountWebClientConfig,
    listing,
    listing.id,
    listing.idOnMkp,
    refreshMetrics,
    trackError,
    updateItemInEvent,
    forTable,
    posEntity,
    updateActivePosEntityInfo,
    isLoading,
    hasFastStatusFeature,
  ]);

  const onBroadcastClick = useCallback(async () => {
    setIsLoading(true);

    // Update the intention so the UI is changed immediately to the pending state
    const newStatus = isNotListed
      ? ListingStatus.ListingPending
      : ListingStatus.DelistingPending;

    if (forTable) {
      if (listing.ltGrpId) {
        listing.ltGrpActive = newStatus === ListingStatus.ListingPending;

        const listingGroup = getListingGroupForListing(
          event?.viagId,
          listing.ltGrpId,
          eventsTransformed
        );

        if (listingGroup) {
          listingGroup.isLockedFromEdits = true;

          updateItemInEvent(listingGroup, ActionOutboxEntityType.Listing);
        }
      }

      updateItemInEvent(
        { ...listing, status: newStatus },
        ActionOutboxEntityType.Listing
      );
    } else {
      updateActivePosEntityInfo({
        posEntity: {
          ...posEntity!,
          status: newStatus,
          mkpListings: posEntity!.mkpListings?.map((ml) => ({
            ...ml,
            status:
              isNotListed && !(ml.isEnabled ?? true) ? ml.status : newStatus,
          })),
        },
        posEntityId: posEntity!.id,
        posEntityDisplayId: posEntity!.idOnMkp,
      });
    }

    await tryInvokeApi(
      async () => {
        const client = new ListingClient(activeAccountWebClientConfig);

        let result = false;
        if (isNotListed) {
          result = await client.createMarketplaceListings(listing.id, []);
        } else {
          if (listing.ltGrpId) {
            // if this is part of the group, reduce active listing count of the group first
            await new ListingGroupClient(
              activeAccountWebClientConfig
            ).setListingActiveInGroup(listing.ltGrpId, listing.id, false);
          }

          result = await client.deleteMarketplaceListings(listing.id, []);
        }

        await refreshExpandedListItems();
        return result;
      },
      (error) => {
        showErrorDialog(
          isNotListed
            ? 'ListingClient.createMarketplaceListings'
            : 'ListingClient.deleteMarketplaceListings',
          error,
          {
            trackErrorData: {
              listingId: listing.id,
              idOnMkp: listing.idOnMkp,
            },
          }
        );
      },
      () => {
        setIsLoading(false);
        if (listing.ltGrpId) {
          lockListingGroupFromEdits?.(false);
        }
      }
    );
  }, [
    isNotListed,
    forTable,
    listing,
    updateItemInEvent,
    event?.viagId,
    eventsTransformed,
    updateActivePosEntityInfo,
    posEntity,
    activeAccountWebClientConfig,
    refreshExpandedListItems,
    showErrorDialog,
    lockListingGroupFromEdits,
  ]);

  const { iconComponent: ListingBroadcastIcon, contentId } =
    getListingBroadcastIcon(
      listing.status,
      listing.qcState,
      false,
      listing.isUndeliv,
      listing.brdcstStatus,
      listing.pendProofState
    );

  if (
    listing.qcState === QualityControlState.Pending &&
    listing.status !== ListingStatus.Listed
  ) {
    return iconOnlyMode ? (
      <IconButton
        icon={
          <PendingQCIcon
            size={vars.iconSize.m}
            stroke={vars.color.textDisabled}
          />
        }
        titleContentId={ContentId.PendingQC}
      />
    ) : null;
  }

  if (listing.dupListingId) {
    return iconOnlyMode ? (
      <IconButton
        icon={<DuplicateOutlinedIcon size={vars.iconSize.m} />}
        titleContentId={ContentId.DuplicatedListing}
      />
    ) : null;
  }

  return (
    <ButtonWithIcon
      onClick={onBroadcastClick}
      disabled={disabled || isLoading}
      textContentId={
        !iconOnlyMode
          ? isNotListed
            ? ContentId.Broadcast
            : ContentId.Unbroadcast
          : contentId
      }
      icon={
        iconOnlyMode ? (
          <ListingBroadcastIcon size={vars.iconSize.m} />
        ) : (
          <SignalIcon size={vars.iconSize.s} />
        )
      }
      variant={variant}
      iconOnlyMode={iconOnlyMode}
    />
  );
};
