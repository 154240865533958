import { ComponentProps, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { Content, FormatContent } from 'src/contexts/ContentContext';
import { Checkbox } from 'src/core/interim/Checkbox';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { WarningMessage } from 'src/core/POS/MessageWithIcon';
import { PosTextArea } from 'src/core/POS/PosTextArea';
import { Button } from 'src/core/ui';
import { FieldWrapper } from 'src/modals/common/Purchase';
import { ContentId } from 'src/utils/constants/contentId';
import { TICKET_GROUP_EDIT_REASON_TO_CID } from 'src/utils/constants/contentIdMaps';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import {
  TicketGroupEditReason,
  TicketGroupIntention,
} from 'src/WebApiController';

import * as styles from './TicketGroupIntentionDialog.css';

export type TicketGroupIntentionDialogProps = ComponentProps<typeof RSModal> & {
  salesCount?: number;
  intention: TicketGroupIntention;
  onSave: (intention: TicketGroupIntention, deallocateSales?: boolean) => void;
  saveButtonContentId?: ContentId;
};

export function TicketGroupIntentionDialog({
  intention,
  ...rest
}: TicketGroupIntentionDialogProps) {
  const methods = useForm<TicketGroupIntention>({
    defaultValues: intention,
  });

  useEffect(() => {
    methods.reset(intention);
  }, [intention, methods]);

  return (
    <FormProvider {...methods}>
      <TicketGroupIntentionDialogBody
        intention={intention}
        {...methods}
        {...rest}
      />
    </FormProvider>
  );
}

const TicketGroupIntentionDialogBody = ({
  salesCount,
  saveButtonContentId,
  onSave,
  onClosed,
  getValues,
  control,
  handleSubmit,
  formState,
  ...rest
}: TicketGroupIntentionDialogProps &
  Omit<
    ComponentProps<typeof FormProvider<TicketGroupIntention, unknown>>,
    'children'
  >) => {
  const { reason } = getValues();

  const [deallocateSales, setDeallocateSales] = useState(true);

  return (
    <GenericDialog
      {...rest}
      size="m"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      header={<Content id={TICKET_GROUP_EDIT_REASON_TO_CID[reason]} />}
      footer={
        <>
          {onClosed && <CancelButton onClick={onClosed} />}
          <Button
            variant={'regular'}
            onClick={(e) => {
              handleSubmit((i) => onSave(i, deallocateSales))();
            }}
            disabled={formState.isSubmitting}
          >
            <Content id={saveButtonContentId ?? ContentId.Next} />
          </Button>
        </>
      }
      onCancel={() => {
        onClosed?.();
      }}
    >
      <div className={styles.ticketGroupIntentionContainer}>
        <FieldWrapper>
          <Content id={ContentId.EditTicketNotes} />
        </FieldWrapper>
        {!!salesCount && (
          <FieldWrapper>
            <WarningMessage
              message={
                <>
                  {salesCount === 1 ? (
                    <Content
                      id={
                        reason === TicketGroupEditReason.AmendTickets
                          ? ContentId.SaleForEditTickets
                          : ContentId.SaleForRemovedTickets
                      }
                    />
                  ) : (
                    <FormatContent
                      id={
                        reason === TicketGroupEditReason.AmendTickets
                          ? FormatContentId.SalesForEditTickets
                          : FormatContentId.SalesForRemovedTickets
                      }
                      params={salesCount.toString()}
                    />
                  )}
                </>
              }
            />
          </FieldWrapper>
        )}
        {reason === TicketGroupEditReason.EditEvent && (
          <>
            <FieldWrapper>
              <WarningMessage
                message={<Content id={ContentId.EditEventWarning2} />}
              />
            </FieldWrapper>

            <FieldWrapper>
              <Checkbox
                checked={deallocateSales}
                // This is not really needed since we are using the onClick event but it is retained to be consistent with the other checkbox
                onChange={() => {
                  setDeallocateSales(!deallocateSales);
                }}
                label={
                  <Content
                    id={ContentId.DeallocateSalesOfAffectedTicketsWarning}
                  />
                }
                labelPosition="right"
              />
            </FieldWrapper>
          </>
        )}
        <FieldWrapper>
          <Controller
            control={control}
            name="additionalInfo"
            render={({ field: { ...field } }) => (
              <PosTextArea {...field} rows={5} />
            )}
          />
        </FieldWrapper>
      </div>
    </GenericDialog>
  );
};
