import {
  DocumentPage,
  DocumentUploadInfo,
  Ticket,
  UserDocument,
  UserDocumentLinks,
} from 'src/WebApiController';

export type ETicketsForm = {
  ticketAssignments: UserDocumentLinks[];
};

export type DocumentFileUploadInfo = {
  file?: File;
  uploadInfo?: DocumentUploadInfo;
  pages?: DocumentPage[];
};

export const getDocumentPageUniqueId = (ep: DocumentPage) =>
  `${ep.id}-${ep.documentId}`;
export const getETicketDocumentPageUniqueId = (ep: UserDocument) =>
  `${ep.pageId}-${ep.documentId}`;

export const getDocumentPagesMap = (docUploadInfos: DocumentFileUploadInfo[]) =>
  docUploadInfos
    .flatMap((x) => x.pages || [])
    .filter((ep) => ep)
    .reduce(
      (prev, cur) => {
        prev[getDocumentPageUniqueId(cur)] = cur;
        return prev;
      },
      {} as { [docPageUniqueId: string]: DocumentPage }
    );

export const getDocumentPageIdToTicketMap = (
  ticketAssignments: UserDocument[],
  tickets: Ticket[]
) =>
  ticketAssignments.reduce(
    (prev, cur) => {
      const index = tickets.findIndex((t) => t.id === cur.entityId);
      prev[getETicketDocumentPageUniqueId(cur)] = {
        ticketIndex: index,
        ticket: tickets[index],
      };
      return prev;
    },
    {} as {
      [docPageUniqueId: string]: { ticketIndex: number; ticket: Ticket };
    }
  );
