import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function SearchOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M16.703 14.715c2.309-3.103 2.037-7.555-.815-10.388-3.124-3.103-8.285-3.103-11.545 0-3.124 3.103-3.124 8.23 0 11.467 1.63 1.62 3.667 2.429 5.705 2.429 1.63 0 3.26-.54 4.753-1.484L20.098 22 22 19.976l-5.297-5.261zM5.75 5.75C7 4.5 8.563 3.875 10.125 3.875c1.563 0 3.125.625 4.375 1.875 2.5 2.5 2.5 6.406 0 8.75-2.5 2.5-6.406 2.5-8.75 0-2.5-2.5-2.5-6.406 0-8.75z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
