import { useMemo } from 'react';
import { Ticket } from 'src/WebApiController';

interface UseNumberOfSalesProps {
  ticketGroups?: { tickets?: Ticket[] | null }[] | null;
}

export const useGetListingAndSaleIds = ({
  ticketGroups,
}: UseNumberOfSalesProps) => {
  return useMemo(() => {
    const ids = ticketGroups?.flatMap((tg) => ({
      saleIds:
        tg.tickets
          ?.filter((t) => t.saleId || t.holdId)
          ?.map((t) => (t.saleId || t.holdId)!) ?? [],
      listingIds:
        tg.tickets?.filter((t) => t.listingId)?.map((t) => t.listingId!) ?? [],
    }));

    const result = ids?.reduce(
      (r, cur) => {
        cur.saleIds.forEach((id) => r.saleIds.add(id));
        cur.listingIds.forEach((id) => r.listingIds.add(id));
        return r;
      },
      {
        saleIds: new Set<number>(),
        listingIds: new Set<number>(),
      }
    );

    return (
      result ?? {
        saleIds: new Set<number>(),
        listingIds: new Set<number>(),
      }
    );
  }, [ticketGroups]);
};
