import { Content } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { BulkEditProgress } from 'src/WebApiController';

import * as styles from './BulkEditPreviewDisplay.css';

export const BulkEditGettingPreview = ({
  progress,
}: {
  progress?: BulkEditProgress;
}) => {
  return (
    <>
      <PosSpinner />
      <Stack justifyContent="spaceBetween">
        <div className={styles.textMain}>
          <Content id={ContentId.BulkEditGettingPreview} />
        </div>

        <span>
          {roundToPrecision((progress?.successPercent ?? 0) * 100, 0)} / 100%
        </span>
      </Stack>
    </>
  );
};
