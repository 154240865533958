import { groupBy, isEmpty, meanBy } from 'lodash-es';
import Rainbow from 'rainbowvis.js';
import { useMemo } from 'react';
import { VenueMapContentProps } from 'src/components/Events/VenueMap/VenueMapContent';
import { vars } from 'src/core/themes';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { CompListing, Listing, UiMoney } from 'src/WebApiController';

export const ListingCompsSpectrum = ['0094DC', 'FFFFFF', 'D80F5E'];
export const ListingCompsRangeMin = -10;
export const ListingCompsRangeMax = 10;
/**
 * Number of steps in the spectrum.
 */
export const ListingCompsNumberRange =
  ListingCompsRangeMax - ListingCompsRangeMin + 1;

const STEPS_PER_HALF = (ListingCompsNumberRange - 1) / 2;

/**
 * The ratio that represents the maximum end of the spectrum.
 */
const MAX_RATIO = 2;
const MAX_RATIO_INCREMENT = Math.abs(MAX_RATIO - 1) / STEPS_PER_HALF;
/**
 * The ratio that represents the minimum end of the spectrum.
 */
const MIN_RATIO = 0.5;
const MIN_RATIO_INCREMENT = Math.abs(MIN_RATIO - 1) / STEPS_PER_HALF;

/**
 * Round to 8 decimal places by default.
 */
const ROUNDING_DECIMAL_PLACES = 8;

/**
 * Gets the range index for a comparable price on a scale of 50% to 200% of the given `price`.
 *
 * The value is negated before returning because for the spectrum is actual relative to the `price`
 * instead of the comparable price, so the spectrum is actually reversed.
 * @param price
 * @param comparablePrice
 * @returns The color for the given comparable price.
 */
export function getCompListingSpectrumIndex(
  price: number,
  comparablePrice: number
) {
  // the ratio relative to 100%
  const relativeRatio =
    Math.max(MIN_RATIO, Math.min(comparablePrice / price, MAX_RATIO)) - 1;
  if (relativeRatio > 0) {
    return -Math.ceil(
      roundToPrecision(
        relativeRatio / MAX_RATIO_INCREMENT,
        ROUNDING_DECIMAL_PLACES
      )
    );
  }
  if (relativeRatio < 0) {
    return -Math.floor(
      roundToPrecision(
        relativeRatio / MIN_RATIO_INCREMENT,
        ROUNDING_DECIMAL_PLACES
      )
    );
  }
  return 0;
}

export function useComparableListingsVenueMapColor(
  listings: Listing[],
  compListings?: CompListing[],
  defaultCompListings?: CompListing[]
): NonNullable<VenueMapContentProps['getColor']> {
  const compListingsBySection = useMemo(() => {
    if (!compListings) return {};

    return groupBy(
      compListings.filter((l) => l.section),
      (l) => l.section
    );
  }, [compListings]);
  const compListingsByRowId = useMemo(() => {
    if (!compListings) return {};

    return groupBy(
      compListings.filter((l) => l.rowId),
      (l) => l.rowId
    );
  }, [compListings]);

  const defaultCompListingsBySection = useMemo(() => {
    if (!defaultCompListings) return {};

    return groupBy(
      defaultCompListings.filter((l) => l.section),
      (l) => l.section
    );
  }, [defaultCompListings]);
  const defaultCompListingsByRowId = useMemo(() => {
    if (!defaultCompListings) return {};

    return groupBy(
      defaultCompListings.filter((l) => l.rowId),
      (l) => l.rowId
    );
  }, [defaultCompListings]);

  const sellerListingsBySection = useMemo(
    () =>
      groupBy(
        listings.flatMap(flattenListingGroup) as Listing[],
        (listing) => listing.seating.section
      ),
    [listings]
  );
  const sellerListingsByRowId = useMemo(
    () =>
      groupBy(
        listings.flatMap(flattenListingGroup) as Listing[],
        (listing) => listing.seating.rowId
      ),
    [listings]
  );

  const avgOverallPrice = meanBy(
    (compListings ?? [])
      .map(
        ({ sellerNetProceeds, sellerAllInPrice }) =>
          sellerNetProceeds ?? sellerAllInPrice
      )
      .filter((money): money is UiMoney => money != null),
    ({ amt }) => amt
  );

  return useMemo(() => {
    return ({ sectionName, rowId, isSelected, isMarked }) => {
      if (!sectionName && !rowId) {
        return;
      }

      if (isSelected) {
        return { fill: vars.color.backgroundHighlightHover, stroke: 'black' };
      }

      if (isMarked) {
        return {
          fill: vars.color.backgroundBrandHoverLight,
          stroke: 'black',
        };
      }

      const hasSellerListings =
        (rowId && !isEmpty(sellerListingsByRowId[rowId])) ||
        (sectionName && !isEmpty(sellerListingsBySection[sectionName]));

      const selectedComplistings =
        (rowId && compListingsByRowId[rowId]) ??
        (sectionName && compListingsBySection[sectionName]);

      if (selectedComplistings) {
        const avgCompPrice = meanBy(
          selectedComplistings
            .map(
              ({ sellerNetProceeds, sellerAllInPrice }) =>
                sellerNetProceeds ?? sellerAllInPrice
            )
            .filter((money): money is UiMoney => money != null),
          ({ amt }) => amt
        );

        const rainbow = new Rainbow();
        rainbow.setNumberRange(ListingCompsRangeMin, ListingCompsRangeMax);
        rainbow.setSpectrumByArray(ListingCompsSpectrum);

        const color = rainbow.colorAt(
          getCompListingSpectrumIndex(avgCompPrice, avgOverallPrice)
        );
        return {
          fill: `#${color}`,
          stroke: hasSellerListings
            ? vars.color.backgroundBrandActive
            : 'black',
          strokeWidth: hasSellerListings ? '10px' : undefined,
        };
      }

      const hasCompListing =
        (rowId && !isEmpty(defaultCompListingsByRowId[rowId])) ||
        (sectionName && !isEmpty(defaultCompListingsBySection[sectionName]));

      return {
        fill: hasCompListing ? vars.color.backgroundPrimaryActive : 'white',
        stroke: hasCompListing ? 'black' : '#A6A6A6',
        textColor: hasCompListing ? 'black' : '#B7B7B7',
      };
    };
  }, [
    avgOverallPrice,
    compListingsByRowId,
    compListingsBySection,
    defaultCompListingsByRowId,
    defaultCompListingsBySection,
    sellerListingsByRowId,
    sellerListingsBySection,
  ]);
}
