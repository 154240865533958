import { DragLayerMonitor, useDragLayer } from 'react-dnd';

import { PosCustomDragLayerContainer } from './PosDragNDrop.styled';

export type PosCustomDragData = {
  name: string | JSX.Element;
  source: string;
  width?: number;
  height?: number;
};

export const PosCustomDragLayer = () => {
  const { item, currentOffset } = useDragLayer((monitor: DragLayerMonitor) => {
    return {
      isDragging: monitor.isDragging(),
      item: monitor.getItem() as { dragData: PosCustomDragData },
      currentOffset: monitor.getSourceClientOffset(),
    };
  });

  const dragData = item?.dragData;

  return dragData ? (
    <PosCustomDragLayerContainer
      currentOffsetX={currentOffset?.x || 0}
      currentOffsetY={currentOffset?.y || 0}
    >
      <div>{dragData.name}</div>
      <img
        src={dragData.source}
        alt=""
        width={dragData.width || 200}
        height={dragData.height}
      />
    </PosCustomDragLayerContainer>
  ) : null;
};
