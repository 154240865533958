import type { OnTabPanelKeyDown } from './TabPanel.types';

export const onTabPanelKeyDown: OnTabPanelKeyDown = (
  e,
  setActiveTab,
  optionsLength
) => {
  const isLeftKey = e.key === 'ArrowLeft';
  const isRightKey = e.key === 'ArrowRight';
  if (!isLeftKey && !isRightKey) return;

  e.preventDefault();

  setActiveTab((current) => {
    if (isLeftKey) {
      const nextIndex = current - 1;
      return nextIndex < 0 ? optionsLength - 1 : nextIndex;
    }
    const nextIndex = current + 1;
    return nextIndex > optionsLength - 1 ? 0 : nextIndex;
  });
};
