import { useMemo } from 'react';
import { useContent } from 'src/contexts/ContentContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { ReportConfig, useReportConfigs } from 'src/hooks/useReportConfigs';
import {
  ReportConfigV2,
  useReportConfigsV2,
} from 'src/hooks/useReportConfigsV2';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ReportsTableContainer } from 'src/tables/ReportsTable/ReportsTableContainer';
import { ReportsTableContainerV2 } from 'src/tables/ReportsTable/ReportsTableContainerV2';
import { ContentId } from 'src/utils/constants/contentId';
import { ReportTypes, ReportTypesV2 } from 'src/utils/reportsUtils';

import { MainRoute } from '../MainRoute';
import * as styles from './Reports.css';

export function Reports() {
  const reportsTitle = useContent(ContentId.Reports);

  return (
    <LayoutContent mainRoute={MainRoute.Reports} routeTitle={reportsTitle}>
      <ReportsPage />
    </LayoutContent>
  );
}

function ReportsPage() {
  const {
    reportConfigs: reportConfigsInventory,
    isLoading: reportInventoryLoading,
  } = useReportConfigs<ReportTypes.Inventory>({
    reportType: ReportTypes.Inventory,
  });
  const { reportConfigs: reportConfigsSale, isLoading: reportSaleLoading } =
    useReportConfigs<ReportTypes.Sale>({
      reportType: ReportTypes.Sale,
    });

  const reports: ReportConfig[] = useMemo(() => {
    const reportsInventoryWithType = reportConfigsInventory.map((r) => ({
      ...r,
      reportType: ReportTypes.Inventory,
    }));
    const reportsSaleWithType = reportConfigsSale.map((r) => ({
      ...r,
      reportType: ReportTypes.Sale,
    }));
    return [...reportsInventoryWithType, ...reportsSaleWithType];
  }, [reportConfigsInventory, reportConfigsSale]);

  return (
    <div className={styles.root}>
      {reportInventoryLoading || reportSaleLoading ? (
        <PosSpinner />
      ) : (
        <ReportsTableContainer reports={reports} />
      )}
    </div>
  );
}

export function ReportsV2() {
  const reportsTitle = 'Reports V2 (In development)'; // no need to translate - once we release - we will need to use ContentId.Report again

  return (
    <LayoutContent mainRoute={MainRoute.ReportsV2} routeTitle={reportsTitle}>
      <ReportsPageV2 />
    </LayoutContent>
  );
}

function ReportsPageV2() {
  const {
    reportConfigs: reportConfigsInventory,
    isLoading: reportInventoryLoading,
  } = useReportConfigsV2<ReportTypesV2.InventoryV2>({
    reportType: ReportTypesV2.InventoryV2,
  });
  const { reportConfigs: reportConfigsSale, isLoading: reportSaleLoading } =
    useReportConfigsV2<ReportTypesV2.SaleV2>({
      reportType: ReportTypesV2.SaleV2,
    });

  const reports: ReportConfigV2[] = useMemo(() => {
    const reportsInventoryWithType = reportConfigsInventory.map((r) => ({
      ...r,
      reportType: ReportTypesV2.InventoryV2,
    }));
    const reportsSaleWithType = reportConfigsSale.map((r) => ({
      ...r,
      reportType: ReportTypesV2.SaleV2,
    }));
    return [...reportsInventoryWithType, ...reportsSaleWithType];
  }, [reportConfigsInventory, reportConfigsSale]);

  return (
    <div className={styles.root}>
      {reportInventoryLoading || reportSaleLoading ? (
        <PosSpinner />
      ) : (
        <ReportsTableContainerV2 reports={reports} />
      )}
    </div>
  );
}
