import { useQueries, useQueryClient } from '@tanstack/react-query';
import { isEqual, once } from 'lodash-es';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { QueryWithViewMode } from 'src/utils/eventQueryUtils';
import { getServerSideOnlyMetricsQuery } from 'src/utils/getServerSideOnlyMetricsQuery';
import {
  CatalogClient,
  EntityWithTicketsQuery,
  Listing,
  Sale,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogDataContext } from '../CatalogDataContext';
import { useFilterQueryContext } from '../FilterQueryContext';
import { useGetEventGroupsToGetMetricsFor } from './useGetEventGroupsToGetMetricsFor';

export type ICatalogMetricsContext<TMetrics extends object> = {
  isLoading: boolean;
  eventMetrics?: Record<string, TMetrics>;
  performerMetrics?: Record<string, TMetrics>;
  venueMetrics?: Record<string, TMetrics>;
  refreshMetrics: () => void;
};

export type ExpandedEventData = {
  [key: string]: {
    sales: Sale[] | null;
    listings: Listing[] | null;
    failedToRetrieveData: boolean;
  };
};

export const createCatalogMetricsContext = once(<TMetrics extends object>() =>
  React.createContext({} as ICatalogMetricsContext<TMetrics>)
);

export function useCatalogMetricsContext<TMetrics extends object>() {
  return useContext(createCatalogMetricsContext<TMetrics>());
}

type CatalogMetricsContextProviderProps<
  TMetrics extends object,
  TQuery extends EntityWithTicketsQuery,
> = PropsWithChildren<{
  queryKey: string;
  getCatalogMetrics?: (
    client: CatalogClient,
    filterQuery: TQuery
  ) => Promise<{
    events: Record<string, TMetrics>;
    performers: Record<string, TMetrics>;
    venues: Record<string, TMetrics>;
  }>;
  /**
   * Needs to be provided if getCatalogMetrics is provided
   */
  addCatalogMetrics?: (metricA: TMetrics, metricB: TMetrics) => TMetrics;
  disabled?: boolean;
}>;

export function CatalogMetricsContextProvider<
  TMetrics extends object,
  TQuery extends EntityWithTicketsQuery & QueryWithViewMode,
>({
  queryKey,
  getCatalogMetrics,
  addCatalogMetrics,
  children,
  disabled,
}: CatalogMetricsContextProviderProps<TMetrics, TQuery>) {
  const CatalogMetricsContext = createCatalogMetricsContext<TMetrics>();

  const { trackError } = useErrorBoundaryContext();

  const { data } = useCatalogDataContext();
  const { filterQuery } = useFilterQueryContext<TQuery>();
  const { activeAccountWebClientConfig } = useAppContext();
  const queryClient = useQueryClient();
  const groupsToGetMetricsFor = useGetEventGroupsToGetMetricsFor(data);

  const queries = useMemo(() => {
    // Removing the client-side queries
    const transformedQuery = getServerSideOnlyMetricsQuery(
      filterQuery
    ) as TQuery;
    return {
      queries: groupsToGetMetricsFor.map((idGroup) => {
        const shouldQuery =
          !disabled &&
          !(
            !idGroup.length ||
            !transformedQuery ||
            !activeAccountWebClientConfig.activeAccountId ||
            !getCatalogMetrics
          );

        return {
          queryKey: [
            queryKey,
            transformedQuery,
            idGroup,
            activeAccountWebClientConfig.activeAccountId,
          ],
          queryFn: async () => {
            if (!shouldQuery) {
              return null;
            }

            return await getCatalogMetrics!(
              new CatalogClient(activeAccountWebClientConfig),
              {
                ...transformedQuery,
                eventOrMappingIds: idGroup.map((ev) => ev.viagVirtualId),
                performerIds: idGroup
                  .filter((ev) => ev.performerId != null)
                  .map((ev) => ev.performerId!),
                venueIds: idGroup.map((ev) => ev.venueId),
              }
            );
          },
          enabled: shouldQuery,
          staleTime: Infinity, // Since we're always refetching on an interval, we don't want query to calculate whether the data is stale
          refetchOnWindowFocus: false,
          onError: (error: ErrorTypes) => {
            trackError('CatalogClient.getCatalogMetrics', error, {
              ...transformedQuery,
              eventIds: idGroup,
            });
            // Since this is just loading metrics, we have the data so we'll just ignore that metrics error
          },
          refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
        };
      }),
    };
  }, [
    activeAccountWebClientConfig,
    disabled,
    getCatalogMetrics,
    groupsToGetMetricsFor,
    queryKey,
    trackError,
    filterQuery,
  ]);

  const metricsQueriesFromHook = useQueries(queries);
  const [metricsQueries, setMetricsQueries] = useState(metricsQueriesFromHook);

  /**
   * IMPORTANT: This is needed because event that useQueries receives a memoized object,
   * on every render it returns a new array with metrics, causing the app
   * to re-render.
   */
  useEffect(() => {
    if (!isEqual(metricsQueries, metricsQueriesFromHook)) {
      setMetricsQueries(metricsQueriesFromHook);
      return;
    }
  }, [metricsQueriesFromHook, metricsQueries]);

  const refreshMetrics = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [queryKey],
    });
    metricsQueries.forEach((q) => q.refetch());
  }, [metricsQueries, queryClient, queryKey]);

  const getMergedMetricsRecord = useCallback(
    (metricsRecords: (Record<string, TMetrics> | undefined)[]) => {
      const entries = metricsRecords.filter((m) => m != null) as Record<
        string,
        TMetrics
      >[];
      if (!entries.length) {
        return undefined;
      }

      return entries.reduce(
        (obj, m) => {
          Object.entries(m).forEach(([key, value]) => {
            if (addCatalogMetrics == null) {
              obj[key] = value;
            } else {
              obj[key] = obj[key] ? addCatalogMetrics(obj[key], value) : value;
            }
          });
          return obj;
        },
        {} as Record<string, TMetrics>
      );
    },
    [addCatalogMetrics]
  );

  const contextValues = useMemo<ICatalogMetricsContext<TMetrics>>(() => {
    return {
      isLoading: metricsQueries.every((q) => q.isLoading),
      eventMetrics: getMergedMetricsRecord(
        metricsQueries.map((q) => q.data?.events)
      ),
      performerMetrics: getMergedMetricsRecord(
        metricsQueries.map((q) => q.data?.performers)
      ),
      venueMetrics: getMergedMetricsRecord(
        metricsQueries.map((q) => q.data?.venues)
      ),
      refreshMetrics: refreshMetrics,
    };
  }, [getMergedMetricsRecord, metricsQueries, refreshMetrics]);

  return (
    <CatalogMetricsContext.Provider value={contextValues}>
      {children}
    </CatalogMetricsContext.Provider>
  );
}
