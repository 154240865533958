import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { CurrencyFilterSelector } from 'src/components/Selectors/CurrencyFilterSelector';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkConvertPurchasePaymentDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  onOkay: (
    fromCurrencyCode: string | null,
    toCurrencyCode: string | null,
    conversionRate: number | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

export function BulkConvertPurchasePaymentDialog({
  updateKey,
  onOkay,
  onCancel,
  entityType,
  ...rest
}: BulkConvertPurchasePaymentDialogProps) {
  const { getUiCurrency } = useLocalizationContext();
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshData } = usePurchaseDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);

  const [fromCurrencyCode, setFromCurrencyCode] = useState('');
  const [toCurrencyCode, setToCurrencyCode] = useState('');
  const [conversionRate, setConversionRate] = useState(1);

  const uiCurrencyFrom = useMemo(
    () => getUiCurrency(fromCurrencyCode),
    [fromCurrencyCode, getUiCurrency]
  );

  const uiCurrencyTo = useMemo(
    () => getUiCurrency(toCurrencyCode),
    [toCurrencyCode, getUiCurrency]
  );

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        // Depending whether it's Listing, Sales, or Purchase
        // the context would be different here
        if (refreshData) {
          await refreshData(true);
        }
        if (refreshExpandedListItems) {
          await refreshExpandedListItems();
        }
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }

        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshData,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    entityType,
    BulkActionType.ConvertPurchasePayment,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const [isRefreshing, setIsRefreshing] = useState(false);

  const isInputValid = useMemo(() => {
    return (
      fromCurrencyCode &&
      toCurrencyCode &&
      conversionRate > 0 &&
      fromCurrencyCode != toCurrencyCode
    );
  }, [fromCurrencyCode, toCurrencyCode, conversionRate]);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      if (onPreviewReceived) {
        onOkay(null, null, null, false, onPreviewReceived);
      } else {
        onOkay(
          fromCurrencyCode,
          toCurrencyCode,
          conversionRate,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [
      onOkay,
      fromCurrencyCode,
      toCurrencyCode,
      conversionRate,
      hasBackgroundBulkEditFeature,
      preview,
    ]
  );

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      size={stage === BulkEditStage.Preview ? 'xl' : 'lg'}
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.ConvertPurchasePayment} />}
        />
      }
      onOpened={() => {
        setConversionRate(1);
        setFromCurrencyCode('');
        setToCurrencyCode('');
        initJob();
      }}
      onKeyUp={(e) => {
        if (submittButtonRef.current && e.key === 'Enter' && isInputValid) {
          submittButtonRef.current.click();
        }
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={entityType}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          disabled={!isInputValid}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={entityType}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack direction="row" width="full" gap="l" alignItems="center">
          <PosCurrencyField
            uiCurrency={uiCurrencyFrom}
            value={1}
            onChange={() => void 0}
            disabled={true}
          />

          <CurrencyFilterSelector
            value={fromCurrencyCode}
            onChange={(e) => {
              setFromCurrencyCode(e);
            }}
          />

          <span>{' > '}</span>

          <PosCurrencyField
            uiCurrency={uiCurrencyTo}
            value={conversionRate}
            onChange={() => void 0}
            disabled={true}
          />
          <CurrencyFilterSelector
            value={toCurrencyCode}
            onChange={(e) => {
              setToCurrencyCode(e);
            }}
          />

          <PosTextField
            value={conversionRate}
            type="number"
            inputMode="decimal"
            onChange={(e) => {
              let value = parseFloat(e.target.value);
              if (isNaN(value) || value < 0) {
                value = 0;
              }
              setConversionRate(value);
            }}
            postfixDisplay={<Content id={ContentId.FX} />}
          />
        </Stack>
      </BulkEditStatus>
    </GenericDialog>
  );
}
