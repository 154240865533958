import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TimeDistance } from 'src/components/common/TimeDistance';
import { Content } from 'src/contexts/ContentContext';
import { useEventLastReviewedContext } from 'src/contexts/SellerEventLastReviewContext/SellerEventLastReviewContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { CheckIcon, LoadingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';

import * as styles from './LastReviewedDisplay.css';

const FadeAnimationDuration = 1_000;
const GracePeriod = 3_000;

type InteractiveButtonProps = {
  isLoading: boolean;
  isCompleted: boolean;
  onClick: () => Promise<void>;
};

const InteractiveButton: React.FC<InteractiveButtonProps> = ({
  isLoading,
  isCompleted,
  onClick,
}) => {
  const [animationStyle, setAnimationStyle] = useState<string | null>();

  // Grace period to allow fade out
  const clearAnimation = useCallback(() => {
    setTimeout(() => setAnimationStyle(null), FadeAnimationDuration);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let timeout: any = null;
    if (isCompleted) {
      setAnimationStyle(styles.checkBoxFadeIn);
      timeout = setTimeout(() => {
        setAnimationStyle(styles.checkBoxFadeOut);
        clearAnimation();
      }, GracePeriod);
    }

    return () => timeout && clearTimeout(timeout);
  }, [clearAnimation, isCompleted]);

  const icon = useMemo(() => {
    if (isLoading) {
      return <LoadingIcon className={styles.spinnerCircle} />;
    }
    if (animationStyle) {
      return (
        <CheckIcon
          fill={vars.color.backgroundBrandActive}
          className={animationStyle}
        />
      );
    }
    return null;
  }, [isLoading, animationStyle]);

  return (
    <Button
      className={styles.lastReviewedButton}
      variant="outline"
      size="md"
      disabled={!!animationStyle || isLoading}
      onClick={onClick}
    >
      {icon}
      {!animationStyle && !isLoading ? (
        <Content id={ContentId.MarkAsReviewed} />
      ) : null}
    </Button>
  );
};

const DateTimeRefreshFrequency = 5_000;

type LastReviewedDisplayProps = {
  viagVirtualId?: string;
};

export const LastReviewedDisplay: React.FC<LastReviewedDisplayProps> = ({
  viagVirtualId,
}) => {
  const {
    eventLastReviewed,
    isCompleted,
    isLoading,
    onSettingVirtualIds,
    onUpdateLastReviewedDateTime,
  } = useEventLastReviewedContext();

  useEffect(() => {
    if (viagVirtualId) {
      onSettingVirtualIds([viagVirtualId]);
    }
  }, [onSettingVirtualIds, viagVirtualId]);

  const lastReviewedDateTime = useMemo(() => {
    if (viagVirtualId && eventLastReviewed) {
      return eventLastReviewed[viagVirtualId];
    }
    return null;
  }, [eventLastReviewed, viagVirtualId]);

  const onUpdateLastReviewed = useCallback(async () => {
    if (viagVirtualId) {
      onUpdateLastReviewedDateTime(viagVirtualId);
    }
  }, [onUpdateLastReviewedDateTime, viagVirtualId]);

  return (
    <div className={styles.lastReviewed}>
      <div className={styles.lastReviewedTitle}>
        <Content id={ContentId.LastReviewed} />
      </div>
      <div className={styles.lastReviewedDateTime}>
        {lastReviewedDateTime ? (
          <TimeDistance
            date={stringToUtcDate(lastReviewedDateTime)}
            frequency={DateTimeRefreshFrequency}
          />
        ) : (
          <Content id={ContentId.PendingReview} />
        )}
        <InteractiveButton
          isLoading={isLoading}
          isCompleted={isCompleted}
          onClick={onUpdateLastReviewed}
        />
      </div>
    </div>
  );
};
