import { useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { IconsFill, MoreIcon, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { PURCHASE_COST_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import { formatCurrency } from 'src/utils/numberFormatter';
import { getCosts } from 'src/utils/ticketUtils';
import { CostDetail, TicketGroupInput } from 'src/WebApiController';

import {
  PurchaseDetailsDataTableCell,
  PurchaseDetailsDataTableFooter,
} from '../PurchaseModal.styled';
import * as styles from './CostDetailsTable.css';
import {
  CostDetailsDataTable,
  PurchaseDetailsDataTableLabelCell,
  PurchaseDetailsDataTableValueCell,
  PurchaseDetailsDataTableValueFooter,
} from './CostDetailsTable.styled';

export type CostDetailsTableProps = {
  currencyCode: string;
  ticketGroups?: TicketGroupInput[];
  costs?: CostDetail[] | null;
  disabled?: boolean;
  onEditCost: (cost: CostDetail) => void;
  onAddNewCost: () => void;
  onRemoveCost: (costId: number) => void;
};

export function CostDetailsTable({
  currencyCode,
  ticketGroups,
  costs,
  disabled,
  onEditCost,
  onAddNewCost,
  onRemoveCost,
}: CostDetailsTableProps) {
  const { totalTicketCost, totalShipping, totalCost } = getCosts(
    ticketGroups,
    costs
  );

  const dividerClassName = useMemo(() => {
    return `add-divider-top padding-top-bottom
    ${
      costs !== undefined && costs != null && costs.length > 0
        ? ' add-divider-bottom'
        : ''
    }`;
  }, [costs]);

  return (
    <CostDetailsDataTable>
      <tbody>
        <tr key="ticket-cost" className="padding-top-bottom">
          <td>
            <PurchaseDetailsDataTableLabelCell>
              <Content id={ContentId.TotalTicketCost} />
            </PurchaseDetailsDataTableLabelCell>
          </td>
          <td>
            <PurchaseDetailsDataTableValueCell align="right">
              {formatCurrency(totalTicketCost, currencyCode)}
            </PurchaseDetailsDataTableValueCell>
          </td>
        </tr>
        <tr key="ticket-shipping" className={dividerClassName}>
          <td>
            <PurchaseDetailsDataTableLabelCell>
              <Content id={ContentId.Shipping} />
            </PurchaseDetailsDataTableLabelCell>
          </td>
          <td>
            <PurchaseDetailsDataTableValueCell align="right">
              {formatCurrency(totalShipping, currencyCode)}
            </PurchaseDetailsDataTableValueCell>
          </td>
        </tr>
        {costs?.map((costDetail) => {
          const { id, cost, costType, description } = costDetail;
          return (
            <tr key={id} className="padding-top-bottom">
              <td>
                <PurchaseDetailsDataTableLabelCell
                  title={description || undefined}
                >
                  {<Content id={PURCHASE_COST_TYPE_TO_CID[costType]} />}
                </PurchaseDetailsDataTableLabelCell>
              </td>
              <td>
                <PurchaseDetailsDataTableValueCell
                  align="right"
                  title={description || undefined}
                >
                  {cost?.disp ||
                    formatCurrency(
                      cost?.amt,
                      cost?.currency ?? currencyCode,
                      cost?.dec
                    )}
                </PurchaseDetailsDataTableValueCell>
              </td>
              {!disabled && (
                <td>
                  <PurchaseDetailsDataTableCell
                    style={{ paddingLeft: vars.spacing['lg'] }}
                  >
                    <PosDropdown
                      trigger={
                        <MoreIcon
                          withHoverEffect
                          fill={IconsFill.textBrand}
                          align="middle"
                        />
                      }
                      align="end"
                    >
                      <PosDropdownItem onClick={() => onEditCost(costDetail)}>
                        <Content id={ContentId.Edit} />
                      </PosDropdownItem>
                      <PosDropdownItem onClick={() => onRemoveCost(id)}>
                        <Content id={ContentId.Remove} />
                      </PosDropdownItem>
                    </PosDropdown>
                  </PurchaseDetailsDataTableCell>
                </td>
              )}
            </tr>
          );
        })}
        {!disabled && (
          <tr>
            <td>
              <Button variant="link" size="unset" onClick={onAddNewCost}>
                <PlusIcon
                  size={vars.iconSize.s}
                  fill={IconsFill.currentColor}
                />
                <Content id={ContentId.AddLineItem} />
              </Button>
            </td>
          </tr>
        )}
        <tr key="totalCost">
          <td>
            <PurchaseDetailsDataTableFooter>
              <div className={styles.totalCostWrapper}>
                <Content id={ContentId.TotalPurchaseCost} />
              </div>
            </PurchaseDetailsDataTableFooter>
          </td>
          <td>
            <PurchaseDetailsDataTableValueFooter align="right">
              {formatCurrency(totalCost, currencyCode)}
            </PurchaseDetailsDataTableValueFooter>
          </td>
        </tr>
      </tbody>
    </CostDetailsDataTable>
  );
}
