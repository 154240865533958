import { PropsWithChildren } from 'react';
import { mergeProps } from 'src/core/utils';
import { BroadcastFailedIcon } from 'src/svgs/BroadcastFailedIcon';

import { container, messageContainer } from './ErrorIconMessage.css';

export const ErrorIconMessage = ({
  alignment = 'left',
  children,
  ...props
}: PropsWithChildren<{
  alignment?: 'center' | 'left' | 'right';
  className?: string;
}>) => {
  return (
    <div
      {...mergeProps(
        {
          className: container({
            alignment,
          }),
        },
        props
      )}
    >
      <BroadcastFailedIcon style={{ flexShrink: '0' }} />
      <div className={messageContainer}>{children}</div>
    </div>
  );
};
