import { useCallback } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { PurchaseVendorAccountDialog } from 'src/dialogs/PurchaseVendorAccountDialog';
import { useAddVendorAccountActions } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorActions/useAddVendorAccountActions';
import { OnVendorAccountSavedFn } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/hooks/useAddVendorAccount';
import { IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseVendor } from 'src/WebApiController';

interface AddVendorAccountProps {
  vendor: PurchaseVendor;
  onVendorAccountSaved: OnVendorAccountSavedFn;
}

export const AddVendorAccount = ({
  vendor,
  onVendorAccountSaved,
}: AddVendorAccountProps) => {
  const {
    currentVendorAccount,
    onAddNewVendorAccount,
    onSaveVendorAccount,
    onVendorAccountCancel,
    venderAccountDialog,
  } = useAddVendorAccountActions({ onVendorAccountSaved });

  const vendorId = vendor.id;
  const addVendorAccount = useCallback(() => {
    onAddNewVendorAccount(vendorId);
  }, [onAddNewVendorAccount, vendorId]);

  return (
    <>
      <Button onClick={addVendorAccount}>
        <PlusIcon size={vars.iconSize.s} fill={IconsFill.currentColor} />
        <span style={{ whiteSpace: 'nowrap' }}>
          <Content id={ContentId.AddAccountAction} />
        </span>
      </Button>
      {currentVendorAccount && (
        <PurchaseVendorAccountDialog
          {...venderAccountDialog.dialogProps}
          vendorAccountId={currentVendorAccount.id}
          vendorId={currentVendorAccount.vendorId}
          onClosed={onVendorAccountCancel}
          onSave={onSaveVendorAccount}
        />
      )}
    </>
  );
};
