import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  AutoPoClient,
  AutoPoSellerAccountCountryRestriction,
  AutoPoSellerAccountVendorRestriction,
} from 'src/WebApiController';

export function useAutoPoRestrictionSettings() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const queryClient = useQueryClient();

  const countryQueryKey = [
    'getSellerAccountCountryRestrictions',
    activeAccountWebClientConfig.activeAccountId,
  ];

  const vendorQueryKey = [
    'getSellerAccountVendorRestrictions',
    activeAccountWebClientConfig.activeAccountId,
  ];

  const countryRestrictionQuery = useQuery({
    queryKey: countryQueryKey,
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new AutoPoClient(
        activeAccountWebClientConfig
      ).getSellerAccountCountryRestrictions();
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog(
          'AutoPoClient.getSellerAccountCountryRestrictions',
          error
        );
      },
    },
  });

  const countryRestrictionMutation = useMutation({
    mutationFn: async ({
      restrictions,
    }: {
      restrictions: AutoPoSellerAccountCountryRestriction[];
    }) => {
      return await new AutoPoClient(
        activeAccountWebClientConfig
      ).upsertSellerAccountCountryRestrictions(restrictions);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ restrictions }) => {
      queryClient.cancelQueries({ queryKey: countryQueryKey });
      const prevValue = queryClient.getQueryData(countryQueryKey);
      queryClient.setQueryData(countryQueryKey, restrictions);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { restrictions }, context) => {
      queryClient.setQueryData(countryQueryKey, context?.prevValue);
      showErrorDialog(
        'AutoPoClient.upsertSellerAccountCountryRestrictions',
        err,
        {
          trackErrorData: { restrictions },
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: countryQueryKey });
    },
  });

  const vendorRestrictionQuery = useQuery({
    queryKey: vendorQueryKey,
    queryFn: () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return new AutoPoClient(
        activeAccountWebClientConfig
      ).getSellerAccountVendorRestrictions();
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog(
          'AutoPoClient.getSellerAccountVendorRestrictions',
          error
        );
      },
    },
  });

  const vendorRestrictionMutation = useMutation({
    mutationFn: async ({
      restrictions,
    }: {
      restrictions: AutoPoSellerAccountVendorRestriction[];
    }) => {
      return await new AutoPoClient(
        activeAccountWebClientConfig
      ).upsertSellerAccountVendorRestrictions(restrictions);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates
    onMutate: ({ restrictions }) => {
      queryClient.cancelQueries({ queryKey: vendorQueryKey });
      const prevValue = queryClient.getQueryData(vendorQueryKey);
      queryClient.setQueryData(vendorQueryKey, restrictions);
      return { prevValue };
    },
    onError: (err: ErrorTypes, { restrictions }, context) => {
      queryClient.setQueryData(vendorQueryKey, context?.prevValue);
      showErrorDialog(
        'AutoPoClient.upsertSellerAccountVendorRestrictions',
        err,
        {
          trackErrorData: { restrictions },
        }
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: vendorQueryKey });
    },
  });

  return {
    countryRestrictionQuery,
    countryRestrictionMutation,
    vendorRestrictionQuery,
    vendorRestrictionMutation,
  };
}
