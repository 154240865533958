import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { GET_VENDOR_QUERY_KEY } from 'src/hooks/api/useGetVendor';

export const useInvalidateGetVendorQuery = () => {
  const queryClient = useQueryClient();

  return useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [GET_VENDOR_QUERY_KEY],
      // type: 'active',
    });
  }, [queryClient]);
};
