import { ScrollAreaProps } from '@radix-ui/react-scroll-area';
import { PropsWithChildren } from 'react';
import { ScrollArea } from 'src/core/ui';

import * as styles from './ScrollableToolbar.css';

export function ScrollableToolbar({
  children,
  ...props
}: PropsWithChildren<ScrollAreaProps>) {
  return (
    <ScrollArea showScrollEdges {...props}>
      <div className={styles.root}>{children}</div>
    </ScrollArea>
  );
}
