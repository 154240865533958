import { clsx } from 'clsx';
import { ReactNode } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { SeatIcon } from 'src/svgs/SeatIcon';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './SeatInfo.css';

export interface SeatInfoProps {
  section: string;
  row: string | null;
  seatFr: string | undefined;
  ticketIndex: number;
  size?: 'small' | 'big';
  icon?: ReactNode;
}

export const SeatInfo = ({
  seatFr,
  section,
  ticketIndex,
  row,
  size = 'small',
  icon,
}: SeatInfoProps) => {
  const isSmallSize = size === 'small';
  return (
    <Stack
      alignItems="center"
      gap="m"
      className={clsx(
        styles.seatingWrapper,
        isSmallSize ? styles.sizeSmall : styles.sizeBig
      )}
      flexWrap="wrap"
    >
      {icon || <SeatIcon size={vars.iconSize.m} />}
      <span className={styles.value}>{section}</span>
      {row && (
        <>
          <span className={styles.label}>
            <Content id={ContentId.Row} />
          </span>
          <span className={styles.value}>{row}</span>
        </>
      )}
      <span className={styles.label}>
        <Content id={ContentId.Seat} />
      </span>
      <span className={styles.value}>
        {seatFr || (ticketIndex + 1).toString()}
      </span>
    </Stack>
  );
};
