import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  PageUniqueIdToTicketMap,
  UniquePageIdToDocumentFileUploadInfoMap,
} from 'src/components/UploadArtifacts/UploadETicketsV2/UploadETickets.utils';
import { EntityWithRealTicketsPartial } from 'src/components/UploadArtifacts/UploadETicketsV2/views/UploadETicketsSeatAssignmentBody';

export type IUploadETicketsContext = {
  pageUniqueIdToTicketMap: PageUniqueIdToTicketMap; // Handles relationship between ticket and seat
  setPageUniqueIdToTicketMap: (
    pageUniqueIdToTicketMap: PageUniqueIdToTicketMap
  ) => void;

  uniquePageIdToDocumentFileUploadInfoMap: UniquePageIdToDocumentFileUploadInfoMap;
  setUniquePageIdToDocumentFileUploadInfoMap: (
    uniquePageIdToDocumentFileUploadInfoMap: UniquePageIdToDocumentFileUploadInfoMap
  ) => void;

  entityWithTickets: EntityWithRealTicketsPartial | undefined;

  selectedUniquePageId: string | undefined;
  setSelectedUniquePageId: (uniquePageId: string | undefined) => void;

  hoverUniquePageId: string | undefined;
  setHoverUniquePageId: (uniquePageId: string | undefined) => void;

  selectPrevUnmappedTicket: () => void;
  selectNextUnmappedTicket: (fromUniquePageId?: string) => void;
};

const defaultUploadETicketsContext: IUploadETicketsContext = {
  entityWithTickets: undefined,
  pageUniqueIdToTicketMap: {},
  setPageUniqueIdToTicketMap: (
    pageUniqueIdToTicketMap: PageUniqueIdToTicketMap
  ) => undefined,
  selectedUniquePageId: undefined,
  setSelectedUniquePageId: (uniquePageId: string | undefined) => undefined,

  hoverUniquePageId: undefined,
  setHoverUniquePageId: (uniquePageId: string | undefined) => undefined,

  uniquePageIdToDocumentFileUploadInfoMap: {},
  setUniquePageIdToDocumentFileUploadInfoMap: (
    uniquePageIdToDocumentFileUploadInfoMap: UniquePageIdToDocumentFileUploadInfoMap
  ) => undefined,
  selectPrevUnmappedTicket: () => undefined,
  selectNextUnmappedTicket: (fromUniquePageId: string | undefined) => undefined,
};

export const UploadETicketsContext = createContext<IUploadETicketsContext>(
  defaultUploadETicketsContext
);

export const useUploadETicketsContext = () => useContext(UploadETicketsContext);

export const UploadETicketsContextProvider = ({
  children,
  entityWithTickets,
}: {
  children: ReactNode;
  entityWithTickets: EntityWithRealTicketsPartial;
}) => {
  const [pageUniqueIdToTicketMap, setPageUniqueIdToTicketMap] =
    useState<PageUniqueIdToTicketMap>(
      defaultUploadETicketsContext.pageUniqueIdToTicketMap
    );

  const [
    uniquePageIdToDocumentFileUploadInfoMap,
    setUniquePageIdToDocumentFileUploadInfoMap,
  ] = useState<UniquePageIdToDocumentFileUploadInfoMap>({});

  const [selectedUniquePageId, setSelectedUniquePageId] = useState<
    string | undefined
  >();

  const [hoverUniquePageId, setHoverUniquePageId] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (selectedUniquePageId) {
      const existsPageId =
        uniquePageIdToDocumentFileUploadInfoMap[selectedUniquePageId];
      if (!existsPageId) {
        setSelectedUniquePageId(undefined);
      }
    }
  }, [
    pageUniqueIdToTicketMap,
    selectedUniquePageId,
    uniquePageIdToDocumentFileUploadInfoMap,
  ]);

  const selectNextTicket = useCallback(
    (direction: 'prev' | 'next') => {
      const uniquePageIds = Object.keys(
        uniquePageIdToDocumentFileUploadInfoMap
      );
      const currentIndex = selectedUniquePageId
        ? uniquePageIds.indexOf(selectedUniquePageId)
        : 0;

      let nextIndex = 0;
      if (direction === 'prev') {
        nextIndex =
          currentIndex - 1 < 0 ? uniquePageIds.length - 1 : currentIndex - 1;
      } else {
        // next
        nextIndex =
          currentIndex + 1 === uniquePageIds.length ? 0 : currentIndex + 1;
      }

      setSelectedUniquePageId(uniquePageIds[nextIndex]);
    },
    [selectedUniquePageId, uniquePageIdToDocumentFileUploadInfoMap]
  );

  /**
   * Select previous unmapped ticket from the current selected ticket.
   */
  const selectPrevUnmappedTicket = useCallback(() => {
    const uniquePageIds = Object.keys(uniquePageIdToDocumentFileUploadInfoMap);

    const selectedUniquePageIdIndex = selectedUniquePageId
      ? uniquePageIds.indexOf(selectedUniquePageId)
      : 0;

    const nextKeysFromBeggingToCurrentIndex = uniquePageIds
      .slice(0, selectedUniquePageIdIndex)
      .reverse();

    // Array of keys to search next unmapped ticket from currentIndex to the end
    const nextKeysFromCurrentIndexToEnd = uniquePageIds
      .slice(
        selectedUniquePageIdIndex + 1 // +1 to not include current uniquePageId
      )
      .reverse();

    const notMappedUniquePageId = [
      ...nextKeysFromBeggingToCurrentIndex, // 0 to current index
      ...nextKeysFromCurrentIndexToEnd, // current index to end
    ].find((pageIdToDocument) => !pageUniqueIdToTicketMap[pageIdToDocument]);

    if (notMappedUniquePageId) {
      setSelectedUniquePageId(notMappedUniquePageId);
    } else {
      // Set prev
      selectNextTicket('prev');
    }
  }, [
    pageUniqueIdToTicketMap,
    selectNextTicket,
    selectedUniquePageId,
    uniquePageIdToDocumentFileUploadInfoMap,
  ]);

  /**
   * Select next unmapped ticket from the current selected ticket.
   */
  const selectNextUnmappedTicket = useCallback(
    (fromUniquePageId: string | undefined) => {
      const uniquePageIds = Object.keys(
        uniquePageIdToDocumentFileUploadInfoMap
      );

      let selectedUniquePageIdIndex = 0;
      if (fromUniquePageId) {
        selectedUniquePageIdIndex = uniquePageIds.indexOf(fromUniquePageId);
      } else if (selectedUniquePageId) {
        selectedUniquePageIdIndex = uniquePageIds.indexOf(selectedUniquePageId);
      }

      // Array of keys to search next unmapped ticket from currentIndex to the end
      const nextKeysFromCurrentIndexToEnd = uniquePageIds.slice(
        selectedUniquePageIdIndex + 1 // +1 to not include current uniquePageId
      );

      const nextKeysFromBeggingToCurrentIndex = uniquePageIds.slice(
        0,
        selectedUniquePageIdIndex
      );

      const notMappedUniquePageId = [
        ...nextKeysFromCurrentIndexToEnd, // current index to end
        ...nextKeysFromBeggingToCurrentIndex, // 0 to current index
      ].find((pageIdToDocument) => !pageUniqueIdToTicketMap[pageIdToDocument]);

      if (notMappedUniquePageId) {
        setSelectedUniquePageId(notMappedUniquePageId);
      } else {
        // Set first
        selectNextTicket('next');
      }
    },
    [
      pageUniqueIdToTicketMap,
      selectNextTicket,
      selectedUniquePageId,
      uniquePageIdToDocumentFileUploadInfoMap,
    ]
  );

  return (
    <UploadETicketsContext.Provider
      value={{
        entityWithTickets,

        pageUniqueIdToTicketMap,
        setPageUniqueIdToTicketMap,

        hoverUniquePageId,
        setHoverUniquePageId,

        uniquePageIdToDocumentFileUploadInfoMap,
        setUniquePageIdToDocumentFileUploadInfoMap,

        selectedUniquePageId,
        setSelectedUniquePageId,

        selectPrevUnmappedTicket,
        selectNextUnmappedTicket,
      }}
    >
      {children}
    </UploadETicketsContext.Provider>
  );
};
