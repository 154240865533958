import { sortBy } from 'lodash-es';
import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { vars } from 'src/core/themes';
import {
  useCrossSellerEventMetrics,
  useEventSupplyMetrics,
  useEventWebsiteMetrics,
  useSellerEventMetrics,
} from 'src/hooks/api/useEventMetrics';
import { ContentId } from 'src/utils/constants/contentId';
import { getCurrentLanguage } from 'src/utils/localeUtils';
import { getInventorySummaryListingMetricsDisplayStrings } from 'src/utils/ticketMetricUtils';
import {
  EventSupplyMetricsTimePeriod,
  EventWebsiteMetricsTimePeriod,
  EventWithData,
  ListingMetrics,
  SellerEventDashboardMetricsTimePeriod,
} from 'src/WebApiController';

import { useAppContext } from '../AppContext';
import { useCatalogMetricsContext } from '../CatalogMetricsContext';
import { useContent } from '../ContentContext';
import {
  LineChartSeries,
  SellerEventPerformanceMetricsData,
  SupplyMetrics,
  TimeSeriesEntry,
  WebsiteMetrics,
} from './EventMetricsContext.types';

type EventMetricsContextType = {
  supplyMetrics: SupplyMetrics;
  websiteMetrics: WebsiteMetrics;
  performanceMetrics: SellerEventPerformanceMetricsData; // The current seller
};

const EventMetricsContext = createContext<EventMetricsContextType>({
  supplyMetrics: {
    ticketCountSeries: [],
    ticketPricesSeries: [],
    currency: 'USD',
    timePeriod: EventSupplyMetricsTimePeriod.LastSevenDays,
    updateTimePeriod: (_: EventSupplyMetricsTimePeriod | null) => void 0,
    isLoading: false,
    today: '',
  },
  websiteMetrics: {
    metrics: null,
    timePeriod: EventWebsiteMetricsTimePeriod.OneHour,
    updateTimePeriod: (_: EventWebsiteMetricsTimePeriod | null) => void 0,
    isLoading: false,
  },
  performanceMetrics: {
    metrics: {
      marketShares: [],
      ticketPricePercentiles: {},
    },
    timePeriod: SellerEventDashboardMetricsTimePeriod.AllTime,
    updateTimePeriod: (_: SellerEventDashboardMetricsTimePeriod | null) =>
      void 0,
    isLoading: false,
  },
});

export const EventMetricsProvider = ({
  children,
  event,
}: PropsWithChildren & { event: EventWithData }) => {
  const { loginContext } = useAppContext();
  const posId = event.event?.posIds[0];
  const {
    supplyMetrics,
    isLoading: isLoadingSupply,
    timePeriod: supplyTimePeriod,
    onUpdateTimePeriod: onUpdateSupplyTimePeriod,
  } = useEventSupplyMetrics(posId);

  const {
    sellerPerformanceMetrics,
    isLoading: isLoadingPerformance,
    timePeriod: performanceTimePeriod,
    onUpdateTimePeriod: onUpdatePeroformanceTimePeriod,
  } = useSellerEventMetrics(posId);

  const {
    websiteMetrics,
    isLoading: isLoadingWebsite,
    timePeriod: websiteTimePeriod,
    onUpdateTimePeriod: onUpdateWebsiteTimePeriod,
  } = useEventWebsiteMetrics(posId);

  const { eventPerformanceMetrics } = useCrossSellerEventMetrics(posId);

  const { eventMetrics: eventMetricsMap } =
    useCatalogMetricsContext<ListingMetrics>();

  const eventMetrics = eventMetricsMap?.[event.event?.viagVirtualId ?? ''];
  const currencyCode =
    eventMetrics?.currency ??
    loginContext?.user?.activeAccount?.currencyCode ??
    'USD';

  const formattedTotalMetrics = eventMetrics
    ? getInventorySummaryListingMetricsDisplayStrings(
        eventMetrics,
        currencyCode
      )
    : undefined;

  const currencyFormatter = new Intl.NumberFormat(getCurrentLanguage(), {
    style: 'currency',
    notation: 'compact',
    currency: currencyCode,
  });
  const formattedProfitAndLoss = currencyFormatter.format(
    eventMetrics?.pandL?.amt ?? 0
  );

  const ticketQuantity = useContent(ContentId.TicketQuantity);

  const supplyData = useMemo(() => {
    const today = new Date().toISOString();
    const ticketCountEntries: TimeSeriesEntry[] = [];
    const minEntries: TimeSeriesEntry[] = [];
    const p25Entries: TimeSeriesEntry[] = [];
    const p50Entries: TimeSeriesEntry[] = [];
    for (const metrics of sortBy(supplyMetrics, 'dateTime')) {
      const { dateTime: date, ticketCount, min, p25, p50, p75, max } = metrics;
      ticketCountEntries.push({ date, value: ticketCount });
      minEntries.push({ date, value: min });
      p25Entries.push({ date, value: p25 });
      p50Entries.push({ date, value: p50 });
    }

    // Add Current
    ticketCountEntries.push({
      date: today,
      value: websiteMetrics?.ticketsOnPlatform?.currentValue ?? 0,
    });
    minEntries.push({
      date: today,
      value: eventPerformanceMetrics?.atp?.min ?? 0,
    });
    p25Entries.push({
      date: today,
      value: eventPerformanceMetrics?.atp?.p25 ?? 0,
    });
    p50Entries.push({
      date: today,
      value: eventPerformanceMetrics?.atp?.p50 ?? 0,
    });

    const ticketCountSeries: LineChartSeries[] = [
      {
        name: ticketQuantity,
        color: vars.color.backgroundBrandActive,
        data: ticketCountEntries,
      },
    ];
    const ticketPricesSeries: LineChartSeries[] = [
      { name: 'min', data: minEntries },
      { name: 'p25', data: p25Entries },
      { name: 'p50', data: p50Entries },
    ];
    return {
      ticketCountSeries,
      ticketPricesSeries,
      today,
      currency: eventPerformanceMetrics?.atp?.currency ?? 'USD',
    };
  }, [
    websiteMetrics?.ticketsOnPlatform?.currentValue,
    eventPerformanceMetrics?.atp?.min,
    eventPerformanceMetrics?.atp?.p25,
    eventPerformanceMetrics?.atp?.p50,
    eventPerformanceMetrics?.atp?.currency,
    ticketQuantity,
    supplyMetrics,
  ]);

  const supplyMetricsData = useMemo(
    () => ({
      ...supplyData,
      timePeriod: supplyTimePeriod,
      updateTimePeriod: onUpdateSupplyTimePeriod,
      isLoading: isLoadingSupply,
    }),
    [isLoadingSupply, onUpdateSupplyTimePeriod, supplyData, supplyTimePeriod]
  );

  const websiteMetricsData = useMemo(
    () => ({
      metrics: websiteMetrics ?? null,
      timePeriod: websiteTimePeriod,
      updateTimePeriod: onUpdateWebsiteTimePeriod,
      isLoading: isLoadingWebsite,
    }),
    [
      isLoadingWebsite,
      onUpdateWebsiteTimePeriod,
      websiteMetrics,
      websiteTimePeriod,
    ]
  );

  const performanceMetricsData: SellerEventPerformanceMetricsData =
    useMemo(() => {
      return {
        metrics: {
          marketShares: sellerPerformanceMetrics?.mktShares ?? [],
          ticketPricePercentiles: sellerPerformanceMetrics?.atp ?? {},
          ...(formattedTotalMetrics ?? {}),
          formattedProfitAndLoss,
        },
        timePeriod: performanceTimePeriod,
        updateTimePeriod: onUpdatePeroformanceTimePeriod,
        isLoading: isLoadingPerformance,
      };
    }, [
      formattedProfitAndLoss,
      formattedTotalMetrics,
      isLoadingPerformance,
      onUpdatePeroformanceTimePeriod,
      performanceTimePeriod,
      sellerPerformanceMetrics?.atp,
      sellerPerformanceMetrics?.mktShares,
    ]);

  return (
    <EventMetricsContext.Provider
      value={{
        supplyMetrics: supplyMetricsData,
        performanceMetrics: performanceMetricsData,
        websiteMetrics: websiteMetricsData,
      }}
    >
      {children}
    </EventMetricsContext.Provider>
  );
};

export const useEventMetrics = () => useContext(EventMetricsContext);
