import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';

import {
  usePurchaseVendorSelector,
  UsePurchaseVendorSelectorOptions,
} from './usePurchaseVendorSelector';

export type PurchaseVendorSelectorProps = Omit<
  PosSelectProps,
  'valueOptionsContent'
> &
  UsePurchaseVendorSelectorOptions;

export function PurchaseVendorSelector({
  disabled,
  additionalOptions,
  additionalOptionsPosition,
  placeholderText,
  ...props
}: PurchaseVendorSelectorProps) {
  const { posSelectProps } = usePurchaseVendorSelector({
    additionalOptions,
    additionalOptionsPosition,
    placeholderText,
  });

  return <PosSelect {...props} disabled={disabled} {...posSelectProps} />;
}
