import { PosIconProps, SvgWrapper } from 'src/svgs/SvgWrapper';

export const ArrowRight = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 24 24" fill="none">
      <path
        d="M1.5957 10.5029H17.5957"
        stroke="#212121"
        strokeWidth="1.6"
        strokeMiterlimit="10"
      />
      <path
        d="M11.998 3.65723L17.598 10.5017L11.998 17.3461"
        stroke="#212121"
        strokeWidth="1.6"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </SvgWrapper>
  );
};
