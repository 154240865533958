import { ReactNode, useState } from 'react';
import { useUnmount } from 'react-use';
import { Components, Virtuoso } from 'react-virtuoso';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { ConnectedNotificationDisplay } from 'src/components/ConnectedNotificationDisplay';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useNotificationsContext } from 'src/contexts/NotificationsContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { ToolbarButton } from 'src/core/POS/ToolbarButton';
import { vars } from 'src/core/themes';
import { Card } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { LayoutContent } from 'src/navigations/LayoutContent';
import { ContentId } from 'src/utils/constants/contentId';
import { Feature, NotificationType } from 'src/WebApiController';

import IconAlarmAdd from '~icons/pos/alarmAdd';

import { MainRoute } from '../MainRoute';
import * as styles from './Notifications.css';

type VirtuosoContext = {
  isLoading?: boolean;
};

const notificationTypeSetMap = {
  newSales: new Set([NotificationType.SaleNew]),
  failures: new Set([
    NotificationType.SaleFulfilmentFailed,
    NotificationType.ListingHideSeatsFailed,
    NotificationType.ListingUpdateAllInPriceFailed,
    NotificationType.ListingUpdateInHandDateFailed,
    NotificationType.ListingUpdateSplitTypeFailed,
    NotificationType.ListingUpdateDeliveryTypeFailed,
    NotificationType.ListingUpdateUnitNetProceedsFailed,
    NotificationType.ListingBroadcastFailed,
    NotificationType.ListingUnbroadcastFailed,
    NotificationType.ListingMergeFailed,
    NotificationType.ListingSplitFailed,
  ]),
  autopricing: new Set([
    NotificationType.AutopriceBelowFloor,
    NotificationType.AutopriceInsufficientCompListings,
    NotificationType.AutopriceFirebrake,
  ]),
  messages: new Set([NotificationType.SellerSupportCaseNewReply]),
  cancellations: new Set([NotificationType.SaleMarketplaceCancelled]),
};

const Header: Components<unknown, VirtuosoContext>['Header'] = () => {
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);

  const { setNotificationFilter } = useNotificationsContext();
  useUnmount(() => {
    setNotificationFilter({});
  });

  const [selectedQuickFilterId, setSelectedQuickFilterId] = useState<
    string | null
  >(null);

  const quickFilters: {
    id: string;
    name: ReactNode;
    onClick: () => void;
  }[] = [
    {
      id: 'newSales',
      name: <Content id={ContentId.NewSales} />,
      onClick: () => {
        setNotificationFilter({
          type: notificationTypeSetMap.newSales,
        });
      },
    },
    {
      id: 'failures',
      name: <Content id={ContentId.Failures} />,
      onClick: () => {
        setNotificationFilter({
          type: notificationTypeSetMap.failures,
        });
      },
    },
    {
      id: 'messages',
      name: <Content id={ContentId.Messages} />,
      onClick: () => {
        setNotificationFilter({
          type: notificationTypeSetMap.messages,
        });
      },
    },
    {
      id: 'cancellations',
      name: <Content id={ContentId.Cancellations} />,
      onClick: () => {
        setNotificationFilter({
          type: notificationTypeSetMap.cancellations,
        });
      },
    },
  ];

  if (hasAutoPricingFeature) {
    quickFilters.push({
      id: 'autopricing',
      name: <Content id={ContentId.Autopricing} />,
      onClick: () => {
        setNotificationFilter({
          type: notificationTypeSetMap.autopricing,
        });
      },
    });
  }

  return (
    <div className={styles.center}>
      <h1 className={styles.pageName}>
        <Content id={ContentId.Notifications} />
      </h1>
      <div style={{ marginBottom: vars.spacing.m }}>
        <ToolbarButton
          isSelected={selectedQuickFilterId == null}
          onClick={() => {
            setSelectedQuickFilterId(null);
            setNotificationFilter({});
          }}
        >
          <Content id={ContentId.All} />
        </ToolbarButton>
        {quickFilters.map(({ id, name, onClick }) => (
          <ToolbarButton
            key={id}
            isSelected={selectedQuickFilterId === id}
            onClick={() => {
              setSelectedQuickFilterId(id);
              onClick();
            }}
          >
            {name}
          </ToolbarButton>
        ))}
      </div>
    </div>
  );
};

// XXX hack to prevent margin collapse on last element
const Footer = () => {
  return <div style={{ paddingTop: '0.05px' }} />;
};

const EmptyPlaceholder: Components<
  unknown,
  VirtuosoContext
>['EmptyPlaceholder'] = ({ context }: { context?: VirtuosoContext }) => {
  const { noResultsForNotificationFilter } = useNotificationsContext();
  if (context?.isLoading) {
    return <PosSpinner />;
  }
  return (
    <EmptySectionContent.Root
      icon={<IconAlarmAdd width="32px" height="34px" />}
    >
      {noResultsForNotificationFilter ? (
        <Content id={ContentId.NoFilteredNotifications} />
      ) : (
        <Content id={ContentId.NoNotifications} />
      )}
    </EmptySectionContent.Root>
  );
};

export function Notifications() {
  const notificationsTitle = useContent(ContentId.Notifications);
  const { notifications, notificationsIsInitialLoading, dismissNotification } =
    useNotificationsContext();

  return (
    <LayoutContent
      mainRoute={MainRoute.Notifications}
      routeTitle={notificationsTitle}
    >
      <Virtuoso
        data={notifications}
        context={{ isLoading: notificationsIsInitialLoading }}
        components={{ Header, Footer, EmptyPlaceholder }}
        itemContent={(_, notification) => {
          return (
            <div className={styles.center}>
              <Card className={styles.notificationCard}>
                <ConnectedNotificationDisplay
                  notification={notification}
                  onDismiss={() => {
                    dismissNotification(notification.notificationId);
                  }}
                />
              </Card>
            </div>
          );
        }}
      />
    </LayoutContent>
  );
}
