import { useEffect } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useFilterQueryContext } from 'src/contexts/FilterQueryContext';
import { ReportsSaleContextProviderV2 } from 'src/navigations/Routes/ReportsSale/ReportsSaleContextProviderV2';
import { downloadFileFromBlob } from 'src/utils/fileUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { ReportV2Client, SaleQuery } from 'src/WebApiController';

import { ReportExportOptionsV2 } from './ReportsExport.types';
import { getReportExportFileBasename } from './ReportsExport.utils';

export function ReportsSaleExportV2({
  reportExportOptions,
  onReportExported,
}: {
  reportExportOptions: ReportExportOptionsV2 | null;
  onReportExported: () => void;
}) {
  // Loading report metrics depend on the context provider (need to wait for the context provider to load the report metrics)

  // If reportToExport is null,
  // no reports need to be exported, so not rendering the <ReportsSaleServerSideExportInner component

  // If reportToExport is not null, then render the <ReportsSaleServerSideExportInner component.
  // It will load the report metrics and export the report to a PDF file
  // After the report is exported, the onReportExported callback will be called to reset the reportToExport state to null
  if (reportExportOptions == null) return null;

  return (
    <ReportsSaleContextProviderV2
      reportConfig={reportExportOptions.reportToExport}
    >
      <ReportsSaleServerSideExportInner
        reportExportOptions={reportExportOptions}
        onReportExported={onReportExported}
      />
    </ReportsSaleContextProviderV2>
  );
}

function ReportsSaleServerSideExportInner({
  reportExportOptions: { reportToExport: reportConfig, fileType },
  onReportExported,
}: {
  reportExportOptions: ReportExportOptionsV2;
  onReportExported: () => void;
}) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { eventsTransformed } = useCatalogDataContext();
  const { filterQuery } = useFilterQueryContext<SaleQuery>();
  const { trackError } = useErrorBoundaryContext();

  const allEventIds = (eventsTransformed ?? []).flatMap((ev) => ({
    viagVirtualId: ev.event.viagVirtualId,
    performerId: ev.event.perfId,
    venueId: ev.event.venueId,
  }));

  const downloadReport = async () => {
    await tryInvokeApi(
      async () => {
        const queryWithCatalog = {
          ...filterQuery,
          eventIds: allEventIds.map((ev) => ev.viagVirtualId),
          performerIds: allEventIds
            .filter((ev) => ev.performerId != null)
            .map((ev) => ev.performerId!),
          venueIds: allEventIds.map((ev) => ev.venueId),
        } as SaleQuery;

        const result = await new ReportV2Client(
          activeAccountWebClientConfig
        ).downloadSaleReportMetrics(
          {
            ...reportConfig.request,
            filters: queryWithCatalog,
          },
          fileType
        );

        if (result.data) {
          downloadFileFromBlob(
            `${getReportExportFileBasename(
              reportConfig.reportName
            )}.${fileType.toLowerCase()}`,
            result.data
          );
        }
      },
      (error) => {
        trackError('ReportV2Client.downloadSaleReportMetrics', error);
      },
      () => onReportExported()
    );
  };

  useEffect(() => {
    if (eventsTransformed != null) {
      downloadReport();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsTransformed]);

  return <></>;
}
