import { ReactNode } from 'react';
import { OpenStubhubDropdownAction } from 'src/components/common/OpenSubhubDropdownAction';
import { EventItemHeaderType } from 'src/components/Events/EventItemHeader/EventItemHeader';
import { useContent } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { Permission } from 'src/WebApiController';

import { LaunchAddSeatSaver } from './components/AddSeatSaverAction';
import { EnterCompanionMode } from './components/EnterPairModeAction/EnterPairModeAction';
import { LaunchMergeListings } from './components/MergeListingsAction/LaunchMergeListings';

export const InventoryEventActionDropdown = ({
  isEventPage,
  extraDropdownItems,
  ...props
}: EventItemHeaderType & {
  isEventPage?: boolean;
  extraDropdownItems?: ReactNode;
}) => {
  const actionsMsg = useContent(ContentId.Actions);
  const { event } = props;

  const hasUpdateListingInfoPermission = useUserHasAnyOfPermissions(
    Permission.Inventory_UpdateListingInfo
  );

  return (
    <>
      <PosDropdown
        key="event-action-dropdown"
        trigger={
          <div
            title={actionsMsg}
            style={{ padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}` }}
          >
            <MoreIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              align="middle"
            />
          </div>
        }
        align="end"
      >
        <OpenStubhubDropdownAction event={event} />
        <EnterCompanionMode event={event} />
        {props.listCnt >= 2 ? (
          <>
            {hasUpdateListingInfoPermission && (
              <LaunchMergeListings event={event} />
            )}
          </>
        ) : null}

        <LaunchAddSeatSaver events={[event]} />
        {extraDropdownItems}
      </PosDropdown>
    </>
  );
};
