import {
  ColumnDef,
  InitialTableState,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { formatInTimeZone } from 'date-fns-tz';
import { useCallback, useMemo, useState } from 'react';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { DownloadIcon, IconsFill } from 'src/svgs/Viagogo';
import { NoData, Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import { downloadFileFromUrl } from 'src/utils/fileUtils';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { ReportSnapshotInfo } from 'src/WebApiController';

interface ReportHistoryTableProps {
  reportConfigId: number;
  reportSnapshotInfos: ReportSnapshotInfo[];
  initState?: InitialTableState;
}
const PAGE_SIZE = 15;

const getHistorySnapshotTableColumnsConfig = (
  reportId: number
): ColumnDef<ReportSnapshotInfo>[] => {
  return [
    {
      id: 'date',
      header: () => (
        <TableHeaderCellDiv align="left">
          <Content id={ContentId.Date} />
        </TableHeaderCellDiv>
      ),
      accessorKey: 'createdDate',
      cell: function Cell({ row }) {
        const { timeZone } = useSiteTimezoneContext();
        const curLocale = getLocaleFromLanguageOrCurrent();
        const formattedDate = formatInTimeZone(
          stringToUtcDate(row.original.createdDate),
          timeZone,
          'yyyy/MM/dd hh:mm a',
          {
            locale: curLocale,
          }
        );
        return (
          <TableCellDiv align="left" title={row.original.name}>
            {formattedDate}
          </TableCellDiv>
        );
      },
      enableSorting: true,
    },
    {
      id: 'actions',
      enableSorting: false,
      header: () => (
        <TableHeaderCellDiv align="right">
          <Content id={ContentId.Actions} />
        </TableHeaderCellDiv>
      ),
      minSize: 50,
      maxSize: 50,
      cell: function Cell({ row: { original } }) {
        const onDownloadClick = useCallback(() => {
          downloadFileFromUrl(original.name, original.downloadUri);
        }, [original.downloadUri, original.name]);

        return (
          <TableCellDiv align="right" title={original.name}>
            <Button variant="textPlain" onClick={onDownloadClick}>
              <DownloadIcon
                withHoverEffect
                fill={IconsFill.textBrand}
                size={vars.iconSize.m}
              />
            </Button>
          </TableCellDiv>
        );
      },
    },
  ];
};

export const ReportHistoryTable = ({
  reportSnapshotInfos,
  reportConfigId,

  initState,
}: ReportHistoryTableProps) => {
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting ?? [{ id: 'date', desc: true }]
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: PAGE_SIZE,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const reportHistoryText = useContent(ContentId.ReportHistory);
  const options: Partial<TableOptions<ReportSnapshotInfo>> = useMemo(
    () => ({
      data: reportSnapshotInfos,
      columns: getHistorySnapshotTableColumnsConfig(reportConfigId),
      state: {
        pagination,
        sorting,
      },
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
    }),
    [pagination, reportConfigId, reportSnapshotInfos, sorting]
  );

  return (
    <>
      {reportSnapshotInfos?.length ? (
        <Table options={options} />
      ) : (
        <NoData>
          <FormatContent
            id={FormatContentId.NoDataAvailable}
            params={reportHistoryText}
          />
        </NoData>
      )}
    </>
  );
};
