import { fromZonedTime, toZonedTime } from 'date-fns-tz';
import { createContext, ReactNode, useCallback, useContext } from 'react';
import { useCustomTimeZoneSettings } from 'src/hooks/api/useCustomTimeZoneSettings';

import { DEFAULT_TIMEZONE_LOCAL } from './SiteTimezoneContext.constants';

type SiteTimezoneContextType = {
  timeZone: string;
  timezoneOffsetMins: number;
  toZonedTime: (date: Date) => Date;
  fromZonedTime: (date: Date) => Date;
};

const SiteTimezoneContextDefaults = {
  timeZone: DEFAULT_TIMEZONE_LOCAL,
  timezoneOffsetMins: new Date().getTimezoneOffset(),
  toZonedTime: (date: Date) => date,
  fromZonedTime: (date: Date) => date,
};

const SiteTimezoneContext = createContext<SiteTimezoneContextType>(
  SiteTimezoneContextDefaults
);

type SiteTimezoneContextProviderType = {
  children: ReactNode;
};

export const SiteTimezoneContextProvider = ({
  children,
}: SiteTimezoneContextProviderType) => {
  const { customTimeZoneSettings } = useCustomTimeZoneSettings();

  const toZonedTimeWrapper = useCallback(
    (date: Date) => {
      return toZonedTime(
        date,
        customTimeZoneSettings?.value ?? DEFAULT_TIMEZONE_LOCAL
      );
    },
    [customTimeZoneSettings]
  );

  const fromZonedTimeWrapper = useCallback(
    (date: Date) => {
      return fromZonedTime(
        date,
        customTimeZoneSettings?.value ?? DEFAULT_TIMEZONE_LOCAL
      );
    },
    [customTimeZoneSettings]
  );

  return (
    <SiteTimezoneContext.Provider
      value={{
        timeZone: customTimeZoneSettings?.value ?? DEFAULT_TIMEZONE_LOCAL,
        timezoneOffsetMins:
          customTimeZoneSettings?.timezoneOffsetMins ??
          new Date().getTimezoneOffset(),
        toZonedTime: toZonedTimeWrapper,
        fromZonedTime: fromZonedTimeWrapper,
      }}
    >
      {children}
    </SiteTimezoneContext.Provider>
  );
};

export const useSiteTimezoneContext = () => useContext(SiteTimezoneContext);
