import { isEqual } from 'lodash-es';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button, Stack } from 'src/core/ui';
import { ChangeRowScoreDialogMultiSection } from 'src/dialogs/ChangeRowScoreDialog';
import { SectionRowTableDialog } from 'src/dialogs/SectionRowTableDialog/SectionRowTableDialog';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { EventPricingSeatMapForm } from 'src/utils/eventWithDataUtils';
import {
  getCompleteEventConfigScoreOverrides,
  getEventConfigScoreOverride,
} from 'src/utils/seatScoreUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  EventConfigScoreOverride,
  Feature,
  PricingClient,
  RowInfo,
  ScoreModel,
  SectionInfo,
  SectionScoreOverride,
} from 'src/WebApiController';

import { DetailSection, SectionContent } from '../common';
import { EventVenueHeatMap } from '../common/EventSeatMap';
import { EventSeatMapSelection } from '../EventSeatMapSelection';
import { EventAutoPricingSettings } from './EventAutoPricingSettings';
import { EventPricingSeatMap } from './EventPricingSeatMap';
import * as styles from './EventPricingSeatMap.css';

export type EventScoreUpdate = {
  newScoreOverrides?: SectionScoreOverride[];
  newConfigPayload?: string;
};

export const EventPricingSeatMapBody = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { watch, setValue, getValues, formState } =
    useFormContext<EventPricingSeatMapForm>();

  const hasScaledSeatScoreFeature = useUserHasFeature(Feature.ScaledSeatScore);

  const {
    event,
    venueMapInfo,
    venueMapsByScoreModelQuery,
    activeConfigOverride,
  } = useEventMapContext();
  const { setModal } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const { pricingSettings } = useGetEventAutoPricingSettings(event!, true);
  const hasScoreSectionRowTableFeature = useUserHasFeature(
    Feature.ScoreSectionRowTable
  );
  const hasEditMapInListingTabFeature = useUserHasFeature(
    Feature.EditMapInListingTab
  );

  const sanitizedScoreModel = useMemo(() => {
    const scoreModel = pricingSettings?.scoreModel;
    if (scoreModel) return scoreModel;

    const { AdvancedVenueGeometry, MergedVenueGeometry } =
      venueMapsByScoreModelQuery.data ?? {};
    if (AdvancedVenueGeometry) return ScoreModel.AdvancedVenueGeometry;
    if (MergedVenueGeometry) return ScoreModel.MergedVenueGeometry;
    return ScoreModel.VenueGeometry;
  }, [pricingSettings?.scoreModel, venueMapsByScoreModelQuery.data]);

  const modeledSectionScores = useMemo(() => {
    switch (sanitizedScoreModel) {
      case ScoreModel.AdvancedVenueGeometry:
        return venueMapsByScoreModelQuery.data?.AdvancedVenueGeometry;

      case ScoreModel.MergedVenueGeometry:
        return venueMapsByScoreModelQuery.data?.MergedVenueGeometry;

      default:
        return venueMapsByScoreModelQuery.data?.VenueGeometry;
    }
  }, [
    sanitizedScoreModel,
    venueMapsByScoreModelQuery.data?.AdvancedVenueGeometry,
    venueMapsByScoreModelQuery.data?.MergedVenueGeometry,
    venueMapsByScoreModelQuery.data?.VenueGeometry,
  ]);

  // Hook form fields
  const eventScoreOverride = watch('eventScoreOverride');
  const configPayload = watch('eventScoreOverride.cfgPayload');
  const name = watch('eventScoreOverride.name');
  const scoreOverrides =
    watch('eventScoreOverride.scoreOverrides') ??
    getCompleteEventConfigScoreOverrides(modeledSectionScores?.sectionScores);
  const scoreModel = watch('eventAutoPricing.scoreModel');

  useEffect(() => {
    if (sanitizedScoreModel !== scoreModel) {
      setValue('eventAutoPricing.scoreModel', sanitizedScoreModel);
    }
  }, [sanitizedScoreModel, scoreModel, setValue]);

  const hasAnyScore = useMemo(
    () => scoreOverrides?.some((s) => s.score),
    [scoreOverrides]
  );

  const newModelName = useContent(ContentId.NewModel);
  const changeRowScoreDialog = useBasicDialog();
  const sectionRowDialog = useBasicDialog();

  const onSectionSeatScoreChanged = useCallback(
    ({ newScoreOverrides, newConfigPayload }: EventScoreUpdate) => {
      let newOverride: EventConfigScoreOverride | undefined = undefined;
      if (eventScoreOverride) {
        newOverride = { ...eventScoreOverride };
      } else if (activeConfigOverride) {
        newOverride = { ...activeConfigOverride };
      } else {
        newOverride = getEventConfigScoreOverride(
          event!.viagId!,
          modeledSectionScores ?? venueMapInfo!,
          newModelName,
          null
        );
      }

      if (newScoreOverrides != null) {
        newOverride.scoreOverrides = newScoreOverrides;
      }
      if (newConfigPayload != null) {
        newOverride.cfgPayload = newConfigPayload;
      }

      if (newScoreOverrides != null || newConfigPayload != null) {
        setValue('eventScoreOverride', newOverride);
      }

      return Promise.resolve();
    },
    [
      eventScoreOverride,
      activeConfigOverride,
      event,
      modeledSectionScores,
      venueMapInfo,
      newModelName,
      setValue,
    ]
  );

  const onSectionRowChange = useCallback(
    (updated: SectionScoreOverride[]) => {
      let newOverride: EventConfigScoreOverride | undefined = undefined;
      if (eventScoreOverride) {
        newOverride = { ...eventScoreOverride };
      } else if (activeConfigOverride) {
        newOverride = { ...activeConfigOverride };
      } else {
        newOverride = getEventConfigScoreOverride(
          event!.viagId!,
          modeledSectionScores ?? venueMapInfo!,
          newModelName,
          null
        );
      }
      newOverride.scoreOverrides = updated;
      setValue('eventScoreOverride', newOverride);
    },
    [
      activeConfigOverride,
      event,
      eventScoreOverride,
      modeledSectionScores,
      newModelName,
      setValue,
      venueMapInfo,
    ]
  );

  const onRowSeatScoreChanged = useCallback(
    (
      newScoreOverrides: SectionScoreOverride[],
      newConfigPayload: string | null
    ) => {
      onSectionSeatScoreChanged({
        newScoreOverrides,
        newConfigPayload: newConfigPayload ?? undefined,
      }).then(() => {
        changeRowScoreDialog.closeDialog();
      });
    },
    [onSectionSeatScoreChanged, changeRowScoreDialog]
  );

  const onSavingSeatMap = useCallback(() => {
    const eventForm = getValues();
    if (
      eventForm.eventScoreOverride &&
      !isEqual(
        eventForm.eventScoreOverride,
        formState.defaultValues?.eventScoreOverride
      )
    ) {
      return tryInvokeApi(
        async () => {
          setIsLoading(true);

          const client = new PricingClient(activeAccountWebClientConfig);
          const newId = await client.upsertEventSeatMapScoreOverride({
            ...eventForm.eventScoreOverride!,
            scoreOverrides: getCompleteEventConfigScoreOverrides(
              modeledSectionScores?.sectionScores,
              eventForm.eventScoreOverride!.scoreOverrides,
              false,
              hasScaledSeatScoreFeature
            )!,
          });

          if (event?.viagId && newId !== activeConfigOverride?.id) {
            await client.setEventSeatMapScoreOverride(
              event.viagId,
              newId,
              null
            );
          }
        },
        (error) => {
          showErrorDialog(
            'PricingClient.upsertEventSeatMapScoreOverride',
            error,
            {
              trackErrorData: { event },
            }
          );
        },
        () => {
          setIsLoading(false);
        }
      );
    }

    return Promise.resolve();
  }, [
    activeAccountWebClientConfig,
    activeConfigOverride?.id,
    event,
    formState.defaultValues?.eventScoreOverride,
    getValues,
    hasScaledSeatScoreFeature,
    modeledSectionScores?.sectionScores,
    showErrorDialog,
  ]);

  const allSections = useMemo(
    () => venueMapInfo?.sections ?? [],
    [venueMapInfo?.sections]
  );

  const sectionWithRowsWithOrdinal = useMemo(() => {
    const sections: SectionInfo[] = [];
    const rows: Record<number, RowInfo[]> = {};
    allSections.forEach((s) => {
      rows[s.id] = s.rows
        .filter((r) => !r.isSpeculative && r.ordinal != null)
        .sort((a, b) => (a.ordinal ?? 0) - (b.ordinal ?? 0));
    });

    allSections.forEach((s) => {
      if (rows[s.id].length > 0) {
        sections.push(s);
      }
    });
    return sections;
  }, [allSections]);

  const scoreName = useMemo(() => {
    if (name) {
      return name;
    }

    if (sanitizedScoreModel === ScoreModel.AdvancedVenueGeometry) {
      return <Content id={ContentId.StubhubRecommended} />;
    }

    if (sanitizedScoreModel === ScoreModel.MergedVenueGeometry) {
      return <Content id={ContentId.MergedVenueGeometry} />;
    }

    if (sanitizedScoreModel === ScoreModel.VenueGeometry) {
      return <Content id={ContentId.VenueGeometry} />;
    }

    return <Content id={ContentId.Default} />;
  }, [name, sanitizedScoreModel]);

  if (!event) {
    return null;
  }

  return (
    <>
      <EventAutoPricingSettings
        hasAnyScore={hasAnyScore}
        disabled={isLoading}
      />

      {!hasEditMapInListingTabFeature && (
        <>
          <DetailSection name={<Content id={ContentId.HeatMap} />}>
            <SectionContent numOfColumns={1}>
              {isLoading ? (
                <PosSpinner />
              ) : (
                <Stack direction="column" gap="l">
                  <div className={styles.heatmapContainer}>
                    <EventVenueHeatMap
                      scoreOverrides={scoreOverrides}
                      configPayload={configPayload}
                      onSubmitSectionScoreChange={onSectionSeatScoreChanged}
                    />
                  </div>
                  <div className={styles.heatmapFooter}>
                    <div>
                      {sectionWithRowsWithOrdinal.length > 0 && (
                        <Stack direction="row" gap="m">
                          <Button
                            variant="link"
                            size="lg"
                            onClick={changeRowScoreDialog.launchDialog}
                            style={{ paddingLeft: 0 }}
                          >
                            <Content id={ContentId.EditRowScores} />
                          </Button>
                          {hasScoreSectionRowTableFeature && (
                            <Button
                              variant="link"
                              size="lg"
                              onClick={sectionRowDialog.launchDialog}
                            >
                              <Content id={ContentId.CheckVenueRows} />
                            </Button>
                          )}
                        </Stack>
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                      }}
                    >
                      <div>
                        {scoreName}
                        {' -'}
                      </div>
                      <Button
                        variant="link"
                        size="lg"
                        onClick={() => {
                          // We need to save the seat map prior to closing the modal and launch the
                          // seat map selection modal
                          onSavingSeatMap().then(() =>
                            setModal({
                              children: (
                                <EventSeatMapSelection
                                  event={event}
                                  cancelTo={{
                                    children: (
                                      <EventPricingSeatMap event={event} />
                                    ),
                                    clickOutsideToClose: true,
                                    size: 'slide-in',
                                  }}
                                />
                              ),
                              clickOutsideToClose: true,
                              size: 'slide-in',
                            })
                          );
                        }}
                      >
                        <Content id={ContentId.Edit} />
                      </Button>
                    </div>
                  </div>
                  <div className={styles.heatmapInstructions}>
                    <Content id={ContentId.HowItWorks} />
                    <div className={styles.heatmapInstructionsDetails}>
                      <li>
                        <Content id={ContentId.HeatMapInstruction1} />
                      </li>
                      <li>
                        <Content id={ContentId.HeatMapInstruction2} />
                      </li>
                    </div>
                  </div>
                </Stack>
              )}
            </SectionContent>
          </DetailSection>
          <ChangeRowScoreDialogMultiSection
            {...changeRowScoreDialog.dialogProps}
            selectedSections={sectionWithRowsWithOrdinal}
            configPayload={configPayload}
            scoreOverrides={scoreOverrides}
            onOkay={onRowSeatScoreChanged}
            onCancel={changeRowScoreDialog.closeDialog}
          />
          <SectionRowTableDialog
            {...sectionRowDialog.dialogProps}
            sections={venueMapInfo?.sections ?? []}
            scoreOverrides={scoreOverrides}
            onClose={sectionRowDialog.closeDialog}
            onSectionRowChange={onSectionRowChange}
          />
        </>
      )}
    </>
  );
};
