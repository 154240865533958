import { ComponentProps, useCallback, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PurchaseClient } from 'src/WebApiController';

export type RestorePurchaseDialogProps = ComponentProps<typeof RSModal> & {
  purchaseOrderId: number;
  onSave: () => void;
  onCancel: () => void;
};

export function RestorePurchaseDialog({
  purchaseOrderId,
  onSave,
  onCancel,
  ...dialogProps
}: RestorePurchaseDialogProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const onInternalSubmit = useCallback(async () => {
    await tryInvokeApi(
      async () => {
        setIsSubmitting(true);

        const results = await new PurchaseClient(
          activeAccountWebClientConfig
        ).restorePurchaseOrder(purchaseOrderId);

        if (results) {
          onSave();
        }
      },
      (error) => {
        showErrorDialog('PurchaseClient.restorePurchaseOrder', error, {
          trackErrorData: { purchaseOrderId },
        });
      },
      () => setIsSubmitting(false)
    );
  }, [activeAccountWebClientConfig, onSave, purchaseOrderId, showErrorDialog]);

  return (
    <GenericDialog
      size="md"
      header={<Content id={ContentId.RestorePurchase} />}
      footer={
        <>
          <CancelButton disabled={isSubmitting} onClick={onCancel} />
          <Button
            disabled={isSubmitting}
            variant={'regular'}
            onClick={() => onInternalSubmit()}
          >
            <Content id={ContentId.Confirm} />
          </Button>
        </>
      }
      {...dialogProps}
      onCancel={onCancel}
    >
      {isSubmitting ? (
        <PosSpinner />
      ) : (
        <Content id={ContentId.AreYouSureYouWantToRestoreThisPurchase} />
      )}
    </GenericDialog>
  );
}
