import { isEqual } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useEventMapContext } from 'src/contexts/EventMapContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { useGetAccountAutoPricingSettings } from 'src/hooks/useGetAccountAutoPricingSettings';
import { useGetEventAutoPricingSettings } from 'src/hooks/useGetEventAutoPricingSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
  DEFAULT_COMP_LISTING_FLOOR,
  DEFAULT_UNDERCUT_ABSOLUTE_AMT,
  DEFAULT_UNDERCUT_RELATIVE_AMT,
} from 'src/utils/autoPricingUtils';
import { getCurrentLanguage } from 'src/utils/localeUtils';
import { coalesceUndefinedOnly } from 'src/utils/miscUtils';
import {
  AutoPricingCompListingMode,
  AutoPricingInputs,
  AutoPricingOutlierMode,
  AutoPricingUndercutMode,
  Feature,
  ListingDetailsUpdateInput,
  ListingGroup,
  SectionInfo,
  SelectedSectionSettings,
} from 'src/WebApiController';

type UpdatingKeys = keyof AutoPricingInputs;

export const useListingGroupAutoPricingSettings = <T extends FieldValues>(
  listingGroup?: ListingGroup
) => {
  const { setValue: setFormValue, watch, formState } = useFormContext<T>();

  const { loginContext } = useAppContext();
  const { getUiCurrency } = useLocalizationContext();

  const updateInput = watch('pricingSettingsInputs' as Path<T>) as
    | AutoPricingInputs
    | undefined;

  const resetListingAutoPricingSettings = watch(
    'resetListingAutoPricingSettings' as Path<T>
  );

  const compListingFloorError = watch(
    'pricingSettingsInputs.compListingFloor.message' as Path<T>
  );
  const compListingSelectedSectionError = watch(
    'pricingSettingsInputs.compListingSelectedSectionSettings.message' as Path<T>
  );
  const undercutAbsoluteAmountError = watch(
    'pricingSettingsInputs.undercutAbsoluteAmount.message' as Path<T>
  );
  const undercutRelativeAmountError = watch(
    'pricingSettingsInputs.undercutRelativeAmount.message' as Path<T>
  );

  const {
    undercutMode,
    undercutAbsoluteAmount,
    undercutRelativeAmount,

    compListingMode,
    compListingCeiling,
    compListingFloor,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingQuantityScoreAdjustmentEnabled,
    compListingExcludeFanInventory,
    compListingExcludeDefects,
    compListingQuantityScoreAdjustmentOverrideJson,
    compListingSelectedSectionSettings,

    outlierMode,
    outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,

    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
  } = updateInput || {};

  const setValue = useCallback(
    (key: UpdatingKeys, value: any) => {
      setFormValue(`pricingSettingsInputs.${key}` as Path<T>, value);
    },
    [setFormValue]
  );

  const uiCurrency = useMemo(
    () =>
      getUiCurrency(
        listingGroup?.currency ??
          loginContext?.user?.activeAccount?.currencyCode
      ),
    [
      getUiCurrency,
      listingGroup?.currency,
      loginContext?.user?.activeAccount?.currencyCode,
    ]
  );
  const currencyNumberFormat = useMemo(
    () =>
      new Intl.NumberFormat(getCurrentLanguage(), {
        style: 'currency',
        currency: uiCurrency.code,
      }),
    [uiCurrency.code]
  );

  const { event } = useEventMapContext();
  const { pricingSettings: eventPricingSettings } =
    useGetEventAutoPricingSettings(event!, false);
  const { pricingSettings: accountPricingSettings } =
    useGetAccountAutoPricingSettings();

  const onResetSettings = useCallback(() => {
    setValue('undercutMode', null);
    setValue('undercutAbsoluteAmount', null);
    setValue('undercutRelativeAmount', null);

    setValue('compListingMode', null);
    setValue('compListingFloor', null);
    setValue('compListingCeiling', null);
    setValue('compListingSelectedSectionSettings', null);
    setValue('compListingQuantityScoreAdjustmentOverrideJson', null);
    setValue('compListingOnlyForSameZoneEnabled', null);
    setValue('compListingOnlyForSelectedSectionsEnabled', null);
  }, [setValue]);

  const onResetAdvanceSettings = useCallback(() => {
    setValue('compListingQuantityScoreAdjustmentEnabled', null);
    setValue('compListingExcludeFanInventory', null);
    setValue('compListingExcludeDefects', null);

    setValue('circuitBreakerRelativeCeiling', null);
    setValue('circuitBreakerRelativeFloor', null);
    setValue('circuitBreakerMaxDiscountVelocityPercent', null);
    setValue('circuitBreakerMaxDiscountVelocityTicksInHours', null);
    setValue('circuitBreakerMinCompListingCount', null);

    setValue('outlierMode', null);
    setValue('outlierStandardDeviations', null);
    setValue('outlierKthLowestLimit', null);
    setValue('outlierKthLowestLimitRelativeSpacing', null);
    setValue('outlierKthLowestLimitAbsoluteSpacing', null);
  }, [setValue]);

  const circuitBreakerSettings = useMemo(() => {
    if (
      circuitBreakerMaxDiscountVelocityPercent != null ||
      circuitBreakerMaxDiscountVelocityTicksInHours != null ||
      circuitBreakerMinCompListingCount != null ||
      circuitBreakerRelativeCeiling != null ||
      circuitBreakerRelativeFloor != null
    ) {
      // if it has any of these set - use the entire area
      return {
        circuitBreakerMaxDiscountVelocityPercent,
        circuitBreakerMaxDiscountVelocityTicksInHours,
        circuitBreakerMinCompListingCount,
        circuitBreakerRelativeCeiling,
        circuitBreakerRelativeFloor,
      };
    }

    if (
      eventPricingSettings?.circuitBreakerMaxDiscountVelocityPercent != null ||
      eventPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours !=
        null ||
      eventPricingSettings?.circuitBreakerMinCompListingCount != null ||
      eventPricingSettings?.circuitBreakerRelativeCeiling != null ||
      eventPricingSettings?.circuitBreakerRelativeFloor != null
    ) {
      // if it has any of these set - use the entire area
      return {
        circuitBreakerMaxDiscountVelocityPercent:
          eventPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
          null,
        circuitBreakerMaxDiscountVelocityTicksInHours:
          eventPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
          null,
        circuitBreakerMinCompListingCount:
          eventPricingSettings?.circuitBreakerMinCompListingCount ?? null,
        circuitBreakerRelativeCeiling:
          eventPricingSettings?.circuitBreakerRelativeCeiling ?? null,
        circuitBreakerRelativeFloor:
          eventPricingSettings?.circuitBreakerRelativeFloor ?? null,
      };
    }

    // if it has any of these set - use the entire area
    return {
      circuitBreakerMaxDiscountVelocityPercent:
        accountPricingSettings?.circuitBreakerMaxDiscountVelocityPercent ??
        null,
      circuitBreakerMaxDiscountVelocityTicksInHours:
        accountPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours ??
        null,
      circuitBreakerMinCompListingCount:
        accountPricingSettings?.circuitBreakerMinCompListingCount ?? null,
      circuitBreakerRelativeCeiling:
        accountPricingSettings?.circuitBreakerRelativeCeiling ?? null,
      circuitBreakerRelativeFloor:
        accountPricingSettings?.circuitBreakerRelativeFloor ?? null,
    };
  }, [
    accountPricingSettings?.circuitBreakerMaxDiscountVelocityPercent,
    accountPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours,
    accountPricingSettings?.circuitBreakerMinCompListingCount,
    accountPricingSettings?.circuitBreakerRelativeCeiling,
    accountPricingSettings?.circuitBreakerRelativeFloor,
    circuitBreakerMaxDiscountVelocityPercent,
    circuitBreakerMaxDiscountVelocityTicksInHours,
    circuitBreakerMinCompListingCount,
    circuitBreakerRelativeCeiling,
    circuitBreakerRelativeFloor,
    eventPricingSettings?.circuitBreakerMaxDiscountVelocityPercent,
    eventPricingSettings?.circuitBreakerMaxDiscountVelocityTicksInHours,
    eventPricingSettings?.circuitBreakerMinCompListingCount,
    eventPricingSettings?.circuitBreakerRelativeCeiling,
    eventPricingSettings?.circuitBreakerRelativeFloor,
  ]);

  const undercutSettings = useMemo(() => {
    if (
      undercutMode != null ||
      undercutAbsoluteAmount != null ||
      undercutRelativeAmount != null
    ) {
      // if it has any of these set - use the entire area
      return {
        undercutMode,
        undercutAbsoluteAmount,
        undercutRelativeAmount,
      };
    }

    if (
      eventPricingSettings?.undercutMode != null ||
      eventPricingSettings?.undercutAbsoluteAmount != null ||
      eventPricingSettings?.undercutRelativeAmount != null
    ) {
      // if it has any of these set - use the entire area
      return {
        undercutMode: eventPricingSettings?.undercutMode ?? null,
        undercutAbsoluteAmount:
          eventPricingSettings?.undercutAbsoluteAmount ?? null,
        undercutRelativeAmount:
          eventPricingSettings?.undercutRelativeAmount ?? null,
      };
    }

    // if it has any of these set - use the entire area
    return {
      undercutMode:
        accountPricingSettings?.undercutMode ?? AutoPricingUndercutMode.Simple,
      undercutAbsoluteAmount:
        accountPricingSettings?.undercutAbsoluteAmount ??
        DEFAULT_UNDERCUT_ABSOLUTE_AMT,
      undercutRelativeAmount:
        accountPricingSettings?.undercutRelativeAmount ??
        DEFAULT_UNDERCUT_RELATIVE_AMT,
    };
  }, [
    accountPricingSettings?.undercutAbsoluteAmount,
    accountPricingSettings?.undercutMode,
    accountPricingSettings?.undercutRelativeAmount,
    eventPricingSettings?.undercutAbsoluteAmount,
    eventPricingSettings?.undercutMode,
    eventPricingSettings?.undercutRelativeAmount,
    undercutAbsoluteAmount,
    undercutMode,
    undercutRelativeAmount,
  ]);

  const compListingSettings = useMemo(() => {
    if (
      compListingMode != null ||
      compListingFloor != null ||
      compListingCeiling != null ||
      compListingOnlyForSameZoneEnabled != null ||
      compListingOnlyForSelectedSectionsEnabled != null ||
      compListingExcludeFanInventory != null ||
      compListingExcludeDefects != null ||
      compListingSelectedSectionSettings != null ||
      compListingQuantityScoreAdjustmentEnabled != null ||
      compListingQuantityScoreAdjustmentOverrideJson != null
    ) {
      // if it has any of these set - use the entire area
      return {
        compListingMode,
        compListingFloor: compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR,
        compListingCeiling:
          compListingCeiling ??
          (compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR) +
            DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
        compListingOnlyForSameZoneEnabled,
        compListingOnlyForSelectedSectionsEnabled,
        compListingExcludeFanInventory,
        compListingExcludeDefects,
        compListingSelectedSectionSettings,
        compListingQuantityScoreAdjustmentEnabled,
        compListingQuantityScoreAdjustmentOverrideJson,
      };
    }

    if (
      eventPricingSettings?.compListingMode != null ||
      eventPricingSettings?.compListingFloor != null ||
      eventPricingSettings?.compListingCeiling != null ||
      eventPricingSettings?.compListingOnlyForSameZoneEnabled != null ||
      eventPricingSettings?.compListingOnlyForSelectedSectionsEnabled != null ||
      eventPricingSettings?.compListingExcludeFanInventory != null ||
      eventPricingSettings?.compListingExcludeDefects != null ||
      eventPricingSettings?.compListingQuantityScoreAdjustmentEnabled != null ||
      eventPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson !=
        null ||
      eventPricingSettings?.compListingSelectedSectionSettings != null
    ) {
      // if it has any of these set - use the entire area
      return {
        compListingMode: eventPricingSettings?.compListingMode ?? null,
        compListingFloor:
          eventPricingSettings?.compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR,
        compListingCeiling:
          eventPricingSettings?.compListingCeiling ??
          (eventPricingSettings?.compListingFloor ??
            DEFAULT_COMP_LISTING_FLOOR) +
            DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
        compListingOnlyForSameZoneEnabled:
          eventPricingSettings?.compListingOnlyForSameZoneEnabled ?? null,
        compListingOnlyForSelectedSectionsEnabled:
          eventPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
          null,
        compListingQuantityScoreAdjustmentEnabled:
          eventPricingSettings?.compListingQuantityScoreAdjustmentEnabled ??
          null,
        compListingExcludeFanInventory:
          eventPricingSettings?.compListingExcludeFanInventory ?? null,
        compListingExcludeDefects:
          eventPricingSettings?.compListingExcludeDefects ?? null,
        compListingQuantityScoreAdjustmentOverrideJson:
          eventPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson ??
          null,
        compListingSelectedSectionSettings:
          eventPricingSettings?.compListingSelectedSectionSettings ?? null,
      };
    }

    // if it has any of these set - use the entire area
    return {
      compListingMode:
        accountPricingSettings?.compListingMode ??
        AutoPricingCompListingMode.QualityScore,
      compListingFloor:
        accountPricingSettings?.compListingFloor ?? DEFAULT_COMP_LISTING_FLOOR,
      compListingCeiling:
        accountPricingSettings?.compListingCeiling ??
        (accountPricingSettings?.compListingFloor ??
          DEFAULT_COMP_LISTING_FLOOR) +
          DEFAULT_COMP_LISTING_CEIL_MORE_THAN_FLOOR,
      compListingOnlyForSameZoneEnabled:
        accountPricingSettings?.compListingOnlyForSameZoneEnabled ?? null,
      compListingOnlyForSelectedSectionsEnabled:
        accountPricingSettings?.compListingOnlyForSelectedSectionsEnabled ??
        null,
      compListingQuantityScoreAdjustmentEnabled:
        accountPricingSettings?.compListingQuantityScoreAdjustmentEnabled ??
        null,
      compListingExcludeFanInventory:
        accountPricingSettings?.compListingExcludeFanInventory ?? null,
      compListingExcludeDefects:
        accountPricingSettings?.compListingExcludeDefects ?? null,
      compListingQuantityScoreAdjustmentOverrideJson:
        accountPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson ??
        null,
      compListingSelectedSectionSettings:
        accountPricingSettings?.compListingSelectedSectionSettings ?? null,
    };
  }, [
    accountPricingSettings?.compListingCeiling,
    accountPricingSettings?.compListingFloor,
    accountPricingSettings?.compListingMode,
    accountPricingSettings?.compListingOnlyForSameZoneEnabled,
    accountPricingSettings?.compListingOnlyForSelectedSectionsEnabled,
    accountPricingSettings?.compListingQuantityScoreAdjustmentEnabled,
    accountPricingSettings?.compListingExcludeFanInventory,
    accountPricingSettings?.compListingExcludeDefects,
    accountPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson,
    accountPricingSettings?.compListingSelectedSectionSettings,
    compListingCeiling,
    compListingFloor,
    compListingMode,
    compListingOnlyForSameZoneEnabled,
    compListingOnlyForSelectedSectionsEnabled,
    compListingQuantityScoreAdjustmentEnabled,
    compListingExcludeFanInventory,
    compListingExcludeDefects,
    compListingQuantityScoreAdjustmentOverrideJson,
    compListingSelectedSectionSettings,
    eventPricingSettings?.compListingCeiling,
    eventPricingSettings?.compListingFloor,
    eventPricingSettings?.compListingMode,
    eventPricingSettings?.compListingOnlyForSameZoneEnabled,
    eventPricingSettings?.compListingOnlyForSelectedSectionsEnabled,
    eventPricingSettings?.compListingQuantityScoreAdjustmentEnabled,
    eventPricingSettings?.compListingExcludeFanInventory,
    eventPricingSettings?.compListingExcludeDefects,
    eventPricingSettings?.compListingQuantityScoreAdjustmentOverrideJson,
    eventPricingSettings?.compListingSelectedSectionSettings,
  ]);

  const outlierSettings = useMemo(() => {
    if (
      outlierMode != null ||
      outlierStandardDeviations != null ||
      outlierKthLowestLimit != null
    ) {
      // if it has any of these set - use the entire area
      return {
        outlierMode,
        outlierStandardDeviations,
        outlierKthLowestLimit,
        outlierKthLowestLimitRelativeSpacing,
        outlierKthLowestLimitAbsoluteSpacing,
      };
    }
    if (
      eventPricingSettings?.outlierMode != null ||
      eventPricingSettings?.outlierStandardDeviations != null
      // TODO - add rest of settings here
    ) {
      // if it has any of these set - use the entire area
      return {
        outlierMode: eventPricingSettings?.outlierMode ?? null,
        outlierStandardDeviations:
          eventPricingSettings?.outlierStandardDeviations ?? null,
      };
    }

    // if it has any of these set - use the entire area
    return {
      outlierMode: accountPricingSettings?.outlierMode ?? null,
      outlierStandardDeviations:
        accountPricingSettings?.outlierStandardDeviations ?? null,
      // TODO - add rest of settings here
    };
  }, [
    accountPricingSettings?.outlierMode,
    accountPricingSettings?.outlierStandardDeviations,
    eventPricingSettings?.outlierMode,
    eventPricingSettings?.outlierStandardDeviations,
    outlierKthLowestLimit,
    outlierKthLowestLimitAbsoluteSpacing,
    outlierKthLowestLimitRelativeSpacing,
    outlierMode,
    outlierStandardDeviations,
  ]);

  const onUndercutChange = useCallback(
    (
      undercutModeNew?: AutoPricingUndercutMode | null,
      undercutAbsoluteAmountNew?: number | null,
      undercutRelativeAmountNew?: number | null
    ) => {
      if (
        undercutModeNew !== undefined ||
        undercutAbsoluteAmountNew !== undefined ||
        undercutRelativeAmountNew !== undefined
      ) {
        setValue(
          'undercutMode',
          coalesceUndefinedOnly(undercutModeNew, undercutSettings.undercutMode)!
        );
        setValue(
          'undercutAbsoluteAmount',
          coalesceUndefinedOnly(
            undercutAbsoluteAmountNew,
            undercutSettings.undercutAbsoluteAmount
          )!
        );
        setValue(
          'undercutRelativeAmount',
          coalesceUndefinedOnly(
            undercutRelativeAmountNew,
            undercutSettings.undercutRelativeAmount
          )!
        );
      }
    },
    [
      setValue,
      undercutSettings.undercutAbsoluteAmount,
      undercutSettings.undercutMode,
      undercutSettings.undercutRelativeAmount,
    ]
  );

  const onCompListingChange = useCallback(
    (input: {
      compListingModeNew?: AutoPricingCompListingMode | null;
      compListingFloorNew?: number | null;
      compListingCeilingNew?: number | null;
      compListingOnlyForSameZoneEnabledNew?: boolean | null;
      compListingOnlyForSelectedSectionsEnabledNew?: boolean | null;
      compListingQuantityScoreAdjustmentEnabledNew?: boolean | null;
      compListingExcludeFanInventoryNew?: boolean | null;
      compListingExcludeDefectsNew?: boolean | null;
      compListingQuantityScoreAdjustmentOverrideJsonNew?: string | null;
      compListingSelectedSectionSettingsNew?: SelectedSectionSettings | null;
    }) => {
      const {
        compListingModeNew,
        compListingFloorNew,
        compListingCeilingNew,
        compListingOnlyForSameZoneEnabledNew,
        compListingOnlyForSelectedSectionsEnabledNew,
        compListingQuantityScoreAdjustmentEnabledNew,
        compListingExcludeFanInventoryNew,
        compListingExcludeDefectsNew,
        compListingQuantityScoreAdjustmentOverrideJsonNew,
        compListingSelectedSectionSettingsNew,
      } = input;

      if (
        compListingModeNew !== undefined ||
        compListingFloorNew !== undefined ||
        compListingCeilingNew !== undefined ||
        compListingOnlyForSameZoneEnabledNew !== undefined ||
        compListingOnlyForSelectedSectionsEnabledNew !== undefined ||
        compListingQuantityScoreAdjustmentEnabledNew !== undefined ||
        compListingExcludeFanInventoryNew !== undefined ||
        compListingExcludeDefectsNew !== undefined ||
        compListingQuantityScoreAdjustmentOverrideJsonNew !== undefined ||
        compListingSelectedSectionSettingsNew !== undefined
      ) {
        const sanitizedCompListingOnlyForSameZoneEnabledNew =
          compListingOnlyForSelectedSectionsEnabledNew
            ? false
            : compListingOnlyForSameZoneEnabledNew;
        const sanitizedCompListingOnlyForSelectedSectionsEnabledNew =
          compListingOnlyForSameZoneEnabledNew
            ? false
            : compListingOnlyForSelectedSectionsEnabledNew;

        setValue(
          'compListingMode',
          coalesceUndefinedOnly(
            compListingModeNew,
            compListingSettings.compListingMode
          )!
        );
        setValue(
          'compListingFloor',
          coalesceUndefinedOnly(
            compListingFloorNew,
            compListingSettings.compListingFloor
          )!
        );
        setValue(
          'compListingCeiling',
          coalesceUndefinedOnly(
            compListingCeilingNew,
            compListingSettings.compListingCeiling
          )!
        );
        setValue(
          'compListingOnlyForSameZoneEnabled',
          coalesceUndefinedOnly(
            sanitizedCompListingOnlyForSameZoneEnabledNew,
            compListingSettings.compListingOnlyForSameZoneEnabled
          )!
        );
        setValue(
          'compListingOnlyForSelectedSectionsEnabled',
          coalesceUndefinedOnly(
            compListingOnlyForSelectedSectionsEnabledNew,
            compListingSettings.compListingOnlyForSelectedSectionsEnabled
          )!
        );
        setValue(
          'compListingQuantityScoreAdjustmentEnabled',
          coalesceUndefinedOnly(
            sanitizedCompListingOnlyForSelectedSectionsEnabledNew,
            compListingSettings.compListingQuantityScoreAdjustmentEnabled
          )!
        );
        setValue(
          'compListingExcludeFanInventory',
          coalesceUndefinedOnly(
            compListingExcludeFanInventoryNew,
            compListingSettings.compListingExcludeFanInventory
          )!
        );
        setValue(
          'compListingExcludeDefects',
          coalesceUndefinedOnly(
            compListingExcludeDefectsNew,
            compListingSettings.compListingExcludeDefects
          )!
        );
        setValue(
          'compListingQuantityScoreAdjustmentOverrideJson',
          coalesceUndefinedOnly(
            compListingQuantityScoreAdjustmentOverrideJsonNew,
            compListingSettings.compListingQuantityScoreAdjustmentOverrideJson
          )!
        );
        setValue(
          'compListingSelectedSectionSettings',
          coalesceUndefinedOnly(
            compListingSelectedSectionSettingsNew,
            compListingSettings.compListingSelectedSectionSettings
          )!
        );
      }
    },
    [
      setValue,
      compListingSettings.compListingMode,
      compListingSettings.compListingFloor,
      compListingSettings.compListingCeiling,
      compListingSettings.compListingOnlyForSameZoneEnabled,
      compListingSettings.compListingOnlyForSelectedSectionsEnabled,
      compListingSettings.compListingQuantityScoreAdjustmentEnabled,
      compListingSettings.compListingExcludeFanInventory,
      compListingSettings.compListingExcludeDefects,
      compListingSettings.compListingQuantityScoreAdjustmentOverrideJson,
      compListingSettings.compListingSelectedSectionSettings,
    ]
  );

  const onCircuitBreakerChange = useCallback(
    (
      circuitBreakerRelativeCeilingNew?: number | null,
      circuitBreakerRelativeFloorNew?: number | null,
      circuitBreakerMaxDiscountVelocityPercentNew?: number | null,
      circuitBreakerMaxDiscountVelocityTicksInHoursNew?: number | null,
      circuitBreakerMinCompListingCountNew?: number | null
    ) => {
      if (
        circuitBreakerRelativeCeilingNew !== undefined ||
        circuitBreakerRelativeFloorNew !== undefined ||
        circuitBreakerMaxDiscountVelocityPercentNew !== undefined ||
        circuitBreakerMaxDiscountVelocityTicksInHoursNew !== undefined ||
        circuitBreakerMinCompListingCountNew !== undefined
      ) {
        setValue(
          'circuitBreakerRelativeCeiling',
          coalesceUndefinedOnly(
            circuitBreakerRelativeCeilingNew,
            circuitBreakerSettings.circuitBreakerRelativeCeiling
          ) ?? null
        );
        setValue(
          'circuitBreakerRelativeFloor',
          coalesceUndefinedOnly(
            circuitBreakerRelativeFloorNew,
            circuitBreakerSettings.circuitBreakerRelativeFloor
          ) ?? null
        );
        setValue(
          'circuitBreakerMaxDiscountVelocityPercent',
          coalesceUndefinedOnly(
            circuitBreakerMaxDiscountVelocityPercentNew,
            circuitBreakerSettings.circuitBreakerMaxDiscountVelocityPercent
          ) ?? null
        );
        setValue(
          'circuitBreakerMaxDiscountVelocityTicksInHours',
          coalesceUndefinedOnly(
            circuitBreakerMaxDiscountVelocityTicksInHoursNew,
            circuitBreakerSettings.circuitBreakerMaxDiscountVelocityTicksInHours
          ) ?? null
        );
        setValue(
          'circuitBreakerMinCompListingCount',
          coalesceUndefinedOnly(
            circuitBreakerMinCompListingCountNew,
            circuitBreakerSettings.circuitBreakerMinCompListingCount
          ) ?? null
        );
      }
    },
    [
      circuitBreakerSettings.circuitBreakerMaxDiscountVelocityPercent,
      circuitBreakerSettings.circuitBreakerMaxDiscountVelocityTicksInHours,
      circuitBreakerSettings.circuitBreakerMinCompListingCount,
      circuitBreakerSettings.circuitBreakerRelativeCeiling,
      circuitBreakerSettings.circuitBreakerRelativeFloor,
      setValue,
    ]
  );

  const onOutlierChange = useCallback(
    (input: {
      outlierModeNew?: AutoPricingOutlierMode | null;
      outlierStandardDeviationsNew?: number | null;
      outlierKthLowestLimitNew?: number | null;
      outlierKthLowestLimitRelativeSpacingNew?: number | null;
      outlierKthLowestLimitAbsoluteSpacingNew?: number | null;
    }) => {
      const {
        outlierModeNew,
        outlierStandardDeviationsNew,
        outlierKthLowestLimitNew,
        outlierKthLowestLimitRelativeSpacingNew,
        outlierKthLowestLimitAbsoluteSpacingNew,
      } = input;

      // Covers both null and undefined cases
      if (outlierModeNew !== undefined) {
        setValue(
          'outlierMode',
          coalesceUndefinedOnly(outlierModeNew, outlierSettings.outlierMode) ??
            null
        );

        switch (outlierModeNew) {
          case AutoPricingOutlierMode.StandardDeviations:
            setValue(
              'outlierStandardDeviations',
              coalesceUndefinedOnly(
                outlierStandardDeviationsNew,
                outlierSettings.outlierStandardDeviations
              ) ?? null
            );
            setValue('outlierKthLowestLimit', null);
            setValue('outlierKthLowestLimitRelativeSpacing', null);
            setValue('outlierKthLowestLimitAbsoluteSpacing', null);
            break;
          case AutoPricingOutlierMode.KthLowest:
            setValue('outlierStandardDeviations', null);
            setValue(
              'outlierKthLowestLimit',
              coalesceUndefinedOnly(
                outlierKthLowestLimitNew,
                outlierSettings.outlierKthLowestLimit
              ) ?? null
            );

            setValue(
              'outlierKthLowestLimitRelativeSpacing',
              coalesceUndefinedOnly(
                outlierKthLowestLimitRelativeSpacingNew,
                outlierSettings.outlierKthLowestLimitRelativeSpacing
              ) ?? null
            );

            setValue(
              'outlierKthLowestLimitAbsoluteSpacing',
              coalesceUndefinedOnly(
                outlierKthLowestLimitAbsoluteSpacingNew,
                outlierSettings.outlierKthLowestLimitAbsoluteSpacing
              ) ?? null
            );
            break;
          case AutoPricingOutlierMode.SkipLogic:
            setValue('outlierStandardDeviations', null);
            setValue('outlierKthLowestLimit', null);
            setValue('outlierKthLowestLimitRelativeSpacing', null);
            setValue('outlierKthLowestLimitAbsoluteSpacing', null);
        }
      } else {
        setValue('outlierMode', null);
        setValue('outlierStandardDeviations', null);
        setValue('outlierKthLowestLimit', null);
        setValue('outlierKthLowestLimitRelativeSpacing', null);
        setValue('outlierKthLowestLimitAbsoluteSpacing', null);
      }
    },
    [
      outlierSettings.outlierKthLowestLimit,
      outlierSettings.outlierKthLowestLimitAbsoluteSpacing,
      outlierSettings.outlierKthLowestLimitRelativeSpacing,
      outlierSettings.outlierMode,
      outlierSettings.outlierStandardDeviations,
      setValue,
    ]
  );

  const setAutoPricingDefaults = useCallback(() => {
    // If this or any higher level don't have, set the default
    if (
      !undercutMode &&
      !eventPricingSettings?.undercutMode &&
      !accountPricingSettings?.undercutMode
    ) {
      setValue('undercutMode', undercutMode);
      setValue('undercutAbsoluteAmount', undercutAbsoluteAmount);
      setValue('undercutRelativeAmount', undercutRelativeAmount);
    }

    // If this or any higher level don't have, set the default
    if (
      !compListingMode &&
      !eventPricingSettings?.compListingMode &&
      !accountPricingSettings?.compListingMode
    ) {
      setValue('compListingMode', compListingMode);
      setValue('compListingFloor', compListingFloor);
      setValue('compListingCeiling', compListingCeiling);
    }
  }, [
    accountPricingSettings?.compListingMode,
    accountPricingSettings?.undercutMode,
    compListingCeiling,
    compListingFloor,
    compListingMode,
    eventPricingSettings?.compListingMode,
    eventPricingSettings?.undercutMode,
    setValue,
    undercutAbsoluteAmount,
    undercutMode,
    undercutRelativeAmount,
  ]);

  const applySectionRowFilterCompListingChanges = useCallback(
    (
      newSectionIdFilter: number[],
      sections: SectionInfo[],
      compListingSelectedSectionSettingsNew?:
        | SelectedSectionSettings
        | null
        | undefined
    ) => {
      // If sectionIdFilter is empty, we should disable the compListingOnlyForSelectedSectionsEnabled
      // and revert back to QualitScore mode. When users add section filters, we should enable
      // compListingOnlyForSelectedSectionsEnabled and put the mode to SameEvent only on when the first
      // section is added. If the user swaps the comp mode after the first section we don't want to keep
      // forcing them back into SameEvent mode.
      if (newSectionIdFilter.length === 0 && sections.length > 0) {
        onCompListingChange({
          compListingSelectedSectionSettingsNew,
          compListingModeNew: AutoPricingCompListingMode.QualityScore,
          compListingOnlyForSelectedSectionsEnabledNew: false,
        });
      } else {
        if (newSectionIdFilter.length === 1 && sections.length === 0) {
          onCompListingChange({
            compListingSelectedSectionSettingsNew,
            compListingModeNew: AutoPricingCompListingMode.SameEvent,
            compListingOnlyForSelectedSectionsEnabledNew: true,
          });

          return;
        }

        // Otherwise, just update the section filters and ensure the selectedSections flag is true
        onCompListingChange({
          compListingSelectedSectionSettingsNew,
          compListingOnlyForSelectedSectionsEnabledNew: true,
        });
      }
    },
    [onCompListingChange]
  );

  const onCompListingSectionIdFilterChange = useCallback(
    (
      sectionId: number,
      selectedSections: SectionInfo[],
      section?: SectionInfo
    ) => {
      let newSectionIdFilter =
        compListingSelectedSectionSettings?.sectionIdFilter ?? [];

      let newRowIdFilter =
        compListingSelectedSectionSettings?.rowIdFilter ?? [];

      let newSectionRowIdFilter =
        compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};

      if (newSectionIdFilter.includes(sectionId)) {
        newSectionIdFilter = newSectionIdFilter.filter(
          (id) => id !== sectionId
        );

        // Remove all the associated row ids for this section
        const section = selectedSections.find((s) => s.id === sectionId);
        if (section) {
          const rowIds = section.rows.map((r) => r.id);
          newRowIdFilter = newRowIdFilter.filter((id) => !rowIds.includes(id));
        }

        // Remove the sectionRow mapping so we don't display it in pills
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [sectionId]: omitted, ...updatedSectionRowIdFilter } =
          compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};

        newSectionRowIdFilter = updatedSectionRowIdFilter;
      } else {
        newSectionIdFilter.push(sectionId);

        // If a section is present, add all the rows associated with the newly selected section
        if (section) {
          const newRowIds = section.rows.map((r) => r.id);
          newRowIds.forEach((newRowId) => {
            if (!newRowIdFilter.includes(newRowId)) {
              newRowIdFilter.push(newRowId);
            }
          });
        }
      }

      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings,
        sectionIdFilter: newSectionIdFilter,
        rowIdFilter: newRowIdFilter,
        sectionRowIdFilter: newSectionRowIdFilter,
      };

      applySectionRowFilterCompListingChanges(
        newSectionIdFilter,
        selectedSections,
        compListingSelectedSectionSettingsNew
      );
    },
    [
      applySectionRowFilterCompListingChanges,
      compListingSelectedSectionSettings,
    ]
  );

  const onCompListingSectionZoneChange = useCallback(
    (sections: SectionInfo[]) => {
      let newSectionIdFilter =
        compListingSelectedSectionSettings?.sectionIdFilter ?? [];

      let newRowIdFilter =
        compListingSelectedSectionSettings?.rowIdFilter ?? [];

      const newSectionRowIdFilter =
        compListingSelectedSectionSettings?.sectionRowIdFilter ?? {};

      // If every section passed in already exists in prevSections then we should remove them entirely
      const completelyOverlap = sections.every((section) =>
        newSectionIdFilter.includes(section.id)
      );

      if (completelyOverlap) {
        const sectionIdsToRemove = new Set(
          sections.map((section) => section.id)
        );

        newSectionIdFilter = newSectionIdFilter.filter(
          (id) => !sectionIdsToRemove.has(id)
        );

        // Remove all associated rows as well
        const rowIdsToRemove = sections
          .map((section) => section.rows.map((r) => r.id))
          .flat();

        const rowIdsToRemoveSet = new Set(rowIdsToRemove);

        newRowIdFilter = newRowIdFilter.filter(
          (rowId) => !rowIdsToRemoveSet.has(rowId)
        );

        // Update sectionRowIdFilters to remove the sections that have been removed
        sections.forEach((section) => {
          if (section.id in newSectionRowIdFilter) {
            delete newSectionRowIdFilter[section.id];
          }
        });
      } else {
        // Upsert of the new sections
        sections.forEach((section) => {
          if (!(section.id in newSectionRowIdFilter)) {
            newSectionIdFilter.push(section.id);

            const newRowIds = section.rows.map((r) => r.id);
            newRowIds.forEach((newRowId) => {
              if (!newRowIdFilter.includes(newRowId)) {
                newRowIdFilter.push(newRowId);
              }
            });
          }
        });
      }

      const compListingSelectedSectionSettingsNew = {
        ...compListingSelectedSectionSettings,
        sectionIdFilter: newSectionIdFilter,
        rowIdFilter: newRowIdFilter,
        sectionRowIdFilter: newSectionRowIdFilter,
      };

      applySectionRowFilterCompListingChanges(
        newSectionIdFilter,
        sections,
        compListingSelectedSectionSettingsNew
      );
    },
    [
      applySectionRowFilterCompListingChanges,
      compListingSelectedSectionSettings,
    ]
  );

  const hasChanges = useCallback(
    (listingForm: ListingDetailsUpdateInput) => {
      // Make sure we only send back the settable props
      if (
        formState.defaultValues?.pricingSettingsInputs?.compListingMode !==
          listingForm.compListingMode ||
        formState.defaultValues?.pricingSettingsInputs?.compListingFloor !==
          listingForm.compListingFloor ||
        formState.defaultValues?.pricingSettingsInputs?.compListingCeiling !==
          listingForm.compListingCeiling ||
        formState.defaultValues?.pricingSettingsInputs
          ?.compListingQuantityScoreAdjustmentEnabled !==
          listingForm.compListingQuantityScoreAdjustmentEnabled ||
        formState.defaultValues?.pricingSettingsInputs
          ?.compListingQuantityScoreAdjustmentOverrideJson !==
          listingForm.compListingQuantityScoreAdjustmentOverrideJson ||
        formState.defaultValues?.pricingSettingsInputs
          ?.compListingOnlyForSameZoneEnabled !==
          listingForm.compListingOnlyForSameZoneEnabled ||
        formState.defaultValues?.pricingSettingsInputs
          ?.compListingOnlyForSelectedSectionsEnabled !==
          listingForm.compListingOnlyForSelectedSectionsEnabled ||
        formState.defaultValues?.pricingSettingsInputs
          ?.compListingExcludeFanInventory !==
          listingForm.compListingExcludeFanInventory ||
        formState.defaultValues?.pricingSettingsInputs
          ?.compListingExcludeDefects !==
          listingForm.compListingExcludeDefects ||
        !isEqual(
          formState.defaultValues?.pricingSettingsInputs
            ?.compListingSelectedSectionSettings,
          listingForm?.compListingSelectedSectionSettings
        ) ||
        formState.defaultValues?.pricingSettingsInputs?.undercutMode !==
          listingForm.undercutMode ||
        formState.defaultValues?.pricingSettingsInputs
          ?.undercutAbsoluteAmount !== listingForm.undercutAbsoluteAmount ||
        formState.defaultValues?.pricingSettingsInputs
          ?.undercutRelativeAmount !== listingForm.undercutRelativeAmount ||
        formState.defaultValues?.pricingSettingsInputs
          ?.circuitBreakerMaxDiscountVelocityPercent !==
          listingForm.circuitBreakerMaxDiscountVelocityPercent ||
        formState.defaultValues?.pricingSettingsInputs
          ?.circuitBreakerMaxDiscountVelocityTicksInHours !==
          listingForm.circuitBreakerMaxDiscountVelocityTicksInHours ||
        formState.defaultValues?.pricingSettingsInputs
          ?.circuitBreakerMinCompListingCount !==
          listingForm.circuitBreakerMinCompListingCount ||
        formState.defaultValues?.pricingSettingsInputs
          ?.circuitBreakerRelativeCeiling !==
          listingForm.circuitBreakerRelativeCeiling ||
        formState.defaultValues?.pricingSettingsInputs
          ?.circuitBreakerRelativeFloor !==
          listingForm.circuitBreakerRelativeFloor ||
        formState.defaultValues?.pricingSettingsInputs?.outlierMode !==
          listingForm.outlierMode ||
        formState.defaultValues?.pricingSettingsInputs
          ?.outlierStandardDeviations !== listingForm.outlierStandardDeviations
      ) {
        return true;
      }
    },
    [
      formState.defaultValues?.pricingSettingsInputs?.compListingMode,
      formState.defaultValues?.pricingSettingsInputs?.compListingFloor,
      formState.defaultValues?.pricingSettingsInputs?.compListingCeiling,
      formState.defaultValues?.pricingSettingsInputs
        ?.compListingQuantityScoreAdjustmentEnabled,
      formState.defaultValues?.pricingSettingsInputs
        ?.compListingQuantityScoreAdjustmentOverrideJson,
      formState.defaultValues?.pricingSettingsInputs
        ?.compListingOnlyForSameZoneEnabled,
      formState.defaultValues?.pricingSettingsInputs
        ?.compListingOnlyForSelectedSectionsEnabled,
      formState.defaultValues?.pricingSettingsInputs
        ?.compListingExcludeFanInventory,
      formState.defaultValues?.pricingSettingsInputs?.compListingExcludeDefects,
      formState.defaultValues?.pricingSettingsInputs
        ?.compListingSelectedSectionSettings,
      formState.defaultValues?.pricingSettingsInputs?.undercutMode,
      formState.defaultValues?.pricingSettingsInputs?.undercutAbsoluteAmount,
      formState.defaultValues?.pricingSettingsInputs?.undercutRelativeAmount,
      formState.defaultValues?.pricingSettingsInputs
        ?.circuitBreakerMaxDiscountVelocityPercent,
      formState.defaultValues?.pricingSettingsInputs
        ?.circuitBreakerMaxDiscountVelocityTicksInHours,
      formState.defaultValues?.pricingSettingsInputs
        ?.circuitBreakerMinCompListingCount,
      formState.defaultValues?.pricingSettingsInputs
        ?.circuitBreakerRelativeCeiling,
      formState.defaultValues?.pricingSettingsInputs
        ?.circuitBreakerRelativeFloor,
      formState.defaultValues?.pricingSettingsInputs?.outlierMode,
      formState.defaultValues?.pricingSettingsInputs?.outlierStandardDeviations,
    ]
  );

  const setResetListingAutoPricingSettings = useCallback(
    (reset: any) => {
      setFormValue('resetListingAutoPricingSettings' as Path<T>, reset);
    },
    [setFormValue]
  );

  const onCompListingModeChange = useCallback(
    (mode: AutoPricingCompListingMode | null) => {
      if (!mode) {
        onCompListingChange({
          compListingModeNew: null,
          compListingFloorNew: null,
          compListingCeilingNew: null,
        });
        return;
      }

      onCompListingChange({
        compListingModeNew: mode,
        // Reset flag on mode change
        compListingOnlyForSameZoneEnabledNew: false,
        compListingOnlyForSelectedSectionsEnabledNew: false,
      });

      if (mode === AutoPricingCompListingMode.ParentListing) {
        onUndercutChange(AutoPricingUndercutMode.Simple);
      }
    },
    [onCompListingChange, onUndercutChange]
  );

  const canAutoPrice = useUserHasFeature(Feature.AutoPricing);

  return {
    canAutoPrice,
    uiCurrency,
    currencyNumberFormat,

    onResetSettings,
    onResetAdvanceSettings,
    setAutoPricingDefaults,
    eventPricingSettings,
    accountPricingSettings,
    event,

    ...circuitBreakerSettings,
    ...compListingSettings,
    ...undercutSettings,
    ...outlierSettings,

    onUndercutChange,
    onCompListingChange,
    onCircuitBreakerChange,
    onOutlierChange,
    onCompListingSectionIdFilterChange,
    onCompListingSectionZoneChange,
    onCompListingModeChange,

    compListingFloorError,
    compListingSelectedSectionError,
    undercutAbsoluteAmountError,
    undercutRelativeAmountError,

    resetListingAutoPricingSettings,
    setResetListingAutoPricingSettings,

    hasChanges,
  };
};
