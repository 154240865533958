import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { DATA_REFRESH_RATE_IN_MILLIS_LONG } from 'src/utils/constants/constants';
import { DefaultSaleQuery } from 'src/utils/eventQueryUtils';
import { Sale, SaleClient } from 'src/WebApiController';

export function useGetSalesByEventId(viagVirtualId?: string | null) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();
  const { eventsTransformed } = useCatalogDataContext();
  const salesQuery = useQuery({
    queryKey: ['getSalesByEventId', viagVirtualId],
    async queryFn() {
      if (!viagVirtualId) return null;

      let sales: { [key: string]: Sale[] };

      const event = eventsTransformed?.find(
        (ev) => ev.event.viagVirtualId === viagVirtualId
      );
      try {
        sales = await new SaleClient(
          activeAccountWebClientConfig
        ).getSalesForEvents(
          {
            ...DefaultSaleQuery,
            eventOrMappingIds: [viagVirtualId],
            oldPosEventIds: event?.event.posIds ?? null,
          },
          true
        );
      } catch (err: unknown) {
        trackError('SaleClient.getSalesByEventId', err as ErrorTypes, {
          viagVirtualId,
        });
        throw err;
      }
      return { sales };
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    refetchInterval: DATA_REFRESH_RATE_IN_MILLIS_LONG,
  });

  return {
    sales: Object.values(salesQuery.data?.sales?.[viagVirtualId ?? ''] ?? []),
    isLoading: salesQuery.isPending,
  };
}
