import { FormProvider } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { DetailSection } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { Event, ListingDetails } from 'src/WebApiController';

import { NotesSection } from '../../components/NotesSection';
import { useListingDetailsContext } from '../../ListingDetailsModalV2Context';
import { DeliverySection } from './DeliverySection/DeliverySection';
import { TicketsSection } from './TicketsSection';

type ListingDetailsTicketsFormProps = {
  event: Event | null | undefined;
  listing: ListingDetails;
  isLoading?: boolean;
};

export const ListingDetailsTicketsForm: React.FC<
  ListingDetailsTicketsFormProps
> = ({ event, listing, isLoading }) => {
  const { methods } = useListingDetailsContext();
  return (
    <FormProvider {...methods.tickets}>
      <TicketsSection listing={listing!} disabled={isLoading} />
      <DetailSection name={<Content id={ContentId.Notes} />}>
        <NotesSection listing={listing} disabled={isLoading} />
      </DetailSection>
      <DeliverySection event={event} listing={listing!} disabled={isLoading} />
    </FormProvider>
  );
};
