import React, { ComponentProps, ReactNode, useState } from 'react';
import { useDebounce } from 'react-use';
import { Modal as RSModal } from 'reactstrap';
import { CancelButton } from 'src/components/Buttons';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSearchBox } from 'src/core/POS/PosSearchBox';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { Button, Stack } from 'src/core/ui';
import { SearchOutlineIcon } from 'src/svgs/Viagogo';

import * as styles from './SearchResultPickerDialog.css';

export type SearchResultPickerDialogProps<T> = {
  header: JSX.Element;
  resultItems: T[];
  isLoading?: boolean;
  renderItem?: (item: T) => React.ReactElement;
  onResultItemPicked: (item: T) => void;
  onCancel?: () => void;
} & ComponentProps<typeof RSModal>;

export function SearchResultPickerDialog<T>({
  header,
  resultItems,
  isLoading,
  renderItem,
  onResultItemPicked,
  onResultItemSearched,
  onCancel,
  ...rest
}: SearchResultPickerDialogProps<T>) {
  const [textSearch, setTextSearch] = useState('');
  useDebounce(
    () => {
      onResultItemSearched?.(textSearch);
    },
    300,
    [textSearch]
  );

  return (
    <GenericDialog
      size="lg"
      {...rest}
      header={header}
      onCancel={onCancel}
      footer={onCancel && <CancelButton onClick={onCancel} />}
      includeCloseButton={true}
    >
      {isLoading ? (
        <PosSpinner />
      ) : (
        <Stack direction="column" gap="m" width="full">
          {onResultItemSearched && (
            <PosSearchBox
              prefixDisplay={<SearchOutlineIcon />}
              value={textSearch}
              onSearchChange={setTextSearch}
            />
          )}
          {resultItems?.map((ri, i) => (
            <Button
              className={styles.searchResultItem}
              variant="text"
              key={i}
              onClick={() => onResultItemPicked(ri)}
            >
              {renderItem?.(ri) || (ri as ReactNode)}
            </Button>
          ))}
        </Stack>
      )}
    </GenericDialog>
  );
}
