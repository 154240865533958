import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const ActivityLogTitleTextSpan = styled.span`
  align-items: center;
  font-size: ${() => vars.typography.fontSize['2xl']};
  font-weight: 700;
  color: ${(props) => props.theme.colors.textBrand};
  padding-top: ${(props) => (props.onClick ? '2px' : vars.spacing.sm)};
  padding-bottom: 2px;
`;
