import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { PricingClient } from 'src/WebApiController';

export const useGetAccountAutoPricingSettings = () => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const shouldQuery = activeAccountWebClientConfig.activeAccountId != null;
  const pricingSettingsQuery = useQuery({
    queryKey: [
      'PricingClient.getAccountAutoPricingSettings',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      const pricingSettings = await new PricingClient(
        activeAccountWebClientConfig
      ).getAccountAutoPricingSettings();

      return pricingSettings;
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('PricingClient.getAccountAutoPricingSettings', error, {
          trackErrorData: {
            activeAccountId: activeAccountWebClientConfig.activeAccountId,
          },
        });
      },
    },
  });

  return {
    pricingSettings: pricingSettingsQuery.data,
    pricingSettingsQuery,
    loaded: pricingSettingsQuery.isFetched,
  };
};
