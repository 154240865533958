import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const PendingRejectionIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 26 30">
      <path
        d="M3.11384 16.8C2.01384 14.8 1.21385 13 1.21385 13C0.913848 12.5 0.913839 11.9 1.31384 11.5C1.71384 11.1 2.51384 11 2.91384 11.4L5.61384 14.5V4.5C5.61384 3.7 6.31384 3 7.11384 3C7.91384 3 8.61384 3.7 8.61384 4.5V8.19995"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 22.5H9.31396C7.71396 22.5 6.31396 21.8 5.31396 20.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6138 7.5V12.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.61377 10V2.5C8.61377 1.7 9.31377 1 10.1138 1C10.9138 1 11.6138 1.7 11.6138 2.5V8"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6138 8V4.5C11.6138 3.7 12.3138 3 13.1138 3C13.9138 3 14.6138 3.7 14.6138 4.5V5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6138 2L1.11377 21.5"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 29C21.866 29 25 25.866 25 22C25 18.134 21.866 15 18 15C14.134 15 11 18.134 11 22C11 25.866 14.134 29 18 29Z"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M21 23H18V18"
        stroke="black"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
};
