import clsx from 'clsx';
import { IconButton } from 'src/components/Buttons';
import { typography } from 'src/core/themes/shared.css';
import { Stack } from 'src/core/ui';
import { PurchaseVendorDialog } from 'src/dialogs/PurchaseVendorDialog';
import * as styles from 'src/modals/ColumnSettings/ColumnSettings.css';
import { SummaryWrapper } from 'src/modals/common/Summary/SummaryWrapper';
import { useAddVendorActions } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorActions/useAddVendorActions';
import { EditIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { PurchaseVendor } from 'src/WebApiController';

import * as vendorSummaryStyles from './VendorSummary.css';

interface VendorSummaryProps {
  vendor: PurchaseVendor;
  onVendorDeleted: () => void;
}

export const VendorSummary = ({
  vendor,
  onVendorDeleted,
}: VendorSummaryProps) => {
  const {
    onEditVendor,
    currentVendor,
    vendorDialog,
    onVendorCancel,
    onSaveVendor,
  } = useAddVendorActions();

  return (
    <>
      <SummaryWrapper>
        <Stack
          width="full"
          justifyContent="spaceBetween"
          alignItems="center"
          className={vendorSummaryStyles.summaryContent}
        >
          <h5 className={clsx(typography.title5, styles.headerText)}>
            {vendor.name}
          </h5>

          <Stack>
            <IconButton
              onClick={() => onEditVendor(vendor)}
              icon={<EditIcon withHoverEffect />}
              titleContentId={ContentId.Edit}
            />
            {/* NOT IMPLEMENTED IN THE BE YET */}
            {/*<DeleteVendorButton*/}
            {/*  vendor={vendor}*/}
            {/*  onVendorDeleted={onVendorDeleted}*/}
            {/*/>*/}
          </Stack>
        </Stack>
      </SummaryWrapper>
      {currentVendor && (
        <PurchaseVendorDialog
          {...vendorDialog.dialogProps}
          vendor={currentVendor}
          onClosed={onVendorCancel}
          onSave={onSaveVendor}
          useReducedEdit={true}
        />
      )}
    </>
  );
};
