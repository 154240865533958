import { useAppContext } from 'src/contexts/AppContext';
import { getHasAccessToRoutes } from 'src/utils/userUtils';

export const useMainAccessibleRoute = () => {
  const { loginContext, appContext } = useAppContext();
  const mainAccessibleRoutes = getHasAccessToRoutes(
    loginContext?.user,
    appContext?.features
  );

  return mainAccessibleRoutes.length ? mainAccessibleRoutes[0] : undefined;
};
