import { vars } from 'src/core/themes';

import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const ChevronAccordionIcon = (props: PosIconProps) => {
  return (
    <SvgWrapper {...props} viewBox="0 0 16 16">
      <g opacity="0.5">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.501 4.33545L14 5.73506L8.0039 11.3335L8 11.3296L7.9961 11.3335L2 5.73506L3.49902 4.33545L7.99962 8.53807L12.501 4.33545Z"
          fill={vars.color.textStrong}
        />
      </g>
    </SvgWrapper>
  );
};
