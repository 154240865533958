import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useRef, useState } from 'react';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { TicketTypePriorityInput } from 'src/components/common/TicketTypePriorityInput';
import { DeliveryTypeSelector } from 'src/components/Selectors/DeliveryTypeSelector';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { InfoMessage } from 'src/core/POS/MessageWithIcon/InfoMessage';
import { Stack } from 'src/core/ui';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  DeliveryType,
  TicketTypeOverride,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkChangeTicketTypeRuleDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  onOkay: (
    deliveryType: DeliveryType | null,
    ticketTypeRules: TicketTypeOverride | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

const INIT_TICKET_TYPE_RULES = {
  overrides: [
    {
      deliveryType: DeliveryType.InApp,
      ticketTypePriorities: [],
    },
  ],
};

export function BulkChangeTicketTypeRuleDialog({
  updateKey,
  onOkay,
  onCancel,
  ...rest
}: BulkChangeTicketTypeRuleDialogProps) {
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        await refreshExpandedListItems();
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.UpdateTicketTypeRules,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const [deliveryType, setDeliveryType] = useState<DeliveryType>(
    DeliveryType.InApp
  );
  const [ticketTypeRules, setTicketTypeRules] = useState<TicketTypeOverride>(
    INIT_TICKET_TYPE_RULES
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        onOkay(null, null, false, onPreviewReceived);
      } else {
        onOkay(
          deliveryType,
          ticketTypeRules,
          hasBackgroundBulkEditFeature,
          undefined,
          preview
        );
      }
      setIsLoading(false);
    },
    [
      onOkay,
      deliveryType,
      ticketTypeRules,
      preview,
      hasBackgroundBulkEditFeature,
    ]
  );

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.DeliveryStrategy} />}
        />
      }
      onOpened={() => {
        setDeliveryType(DeliveryType.InApp);
        setTicketTypeRules(INIT_TICKET_TYPE_RULES);
        initJob();
      }}
      onKeyUp={(e) => {
        if (submittButtonRef.current && e.key === 'Enter') {
          submittButtonRef.current.click();
        }
      }}
      onClick={(e) => {
        // This so that this dialog does not cause event to flow to the EventHeader open/close event
        e.stopPropagation();
        e.preventDefault();
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={ActionOutboxEntityType.Listing}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={ActionOutboxEntityType.Listing}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack direction="column" gap="xxl" width="full">
          <InfoMessage
            message={
              <Content id={ContentId.BulkEditTicketTypePriorityInstruction} />
            }
          />
          <DeliveryTypeSelector
            disabled={isLoading}
            value={deliveryType}
            enableEmptySelection={false}
            onChange={(newD) => {
              if (newD && newD !== deliveryType) {
                setDeliveryType(newD);
              }
            }}
          />
          <TicketTypePriorityInput
            deliveryType={deliveryType}
            ticketTypeRules={ticketTypeRules}
            onTicketTypeRulesChange={(newRules) =>
              setTicketTypeRules(newRules ?? INIT_TICKET_TYPE_RULES)
            }
            disabled={isLoading}
          />
        </Stack>
      </BulkEditStatus>
    </GenericDialog>
  );
}
