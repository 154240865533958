import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { useCallback, useMemo, useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { DropWrapper } from 'src/core/POS/DragAndDropSort/DragAndDrop';
import { vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useGetMetricsInUse } from 'src/hooks/useGetMetricsInUse';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasAnyOfPermissions } from 'src/hooks/useUserHasAnyOfPermissions';
import { HamburgerMenuIcon } from 'src/svgs';
import { DeleteIcon, EditIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType, Permission, Tag } from 'src/WebApiController';

import { ButtonWithIcon } from '../Buttons';
import { CreateTagMetadataForm } from './CreateTagMetadataForm';
import { EditTagMetadataForm } from './EditTagMetadataForm';
import * as styles from './TagsFormBody.css';
import { TagValueDisplay, TagValueInputCell } from './TagValueCell';

const TagDraggableListItem = ({
  tag,
  tagsCurrentEntity,
  disabled,
  onBlur,
  isEditing,
  onRowStartEdit,
  onRowChange,
  onRowDelete,
}: {
  tag: Tag;
  tagsCurrentEntity: Tag[];
  disabled?: boolean;
  onRowDelete: (tag: Tag) => void;
  isEditing?: boolean;
  onRowStartEdit?: () => void;
  onRowChange: (tag: Tag, newValue: string) => void;
  onBlur: () => void;
}) => {
  return (
    <div
      className={styles.tagRowDraggable}
      onPointerDown={(e) => {
        // Disable dragging
        if (disabled) {
          e.stopPropagation();
        }
      }}
    >
      <div className={styles.horizontalFlex}>
        {!disabled && (
          <HamburgerMenuIcon
            className={styles.icon}
            size={vars.iconSize.s}
            color={vars.color.textPrimary}
          />
        )}
        <div style={{ color: vars.color.textStrong, whiteSpace: 'nowrap' }}>
          {tag.key}
        </div>
      </div>
      <div className={styles.horizontalFlex}>
        {isEditing ? (
          <div
            onPointerDown={(e) => {
              e.stopPropagation();
            }}
          >
            <TagValueInputCell
              onBlur={onBlur}
              tag={tag}
              disabled={disabled}
              tagsCurrentEntity={tagsCurrentEntity}
              onChange={(newValue) => {
                onRowChange(tag, newValue);
              }}
            />
          </div>
        ) : (
          <>
            <TagValueDisplay tag={tag} />
            <div
              onPointerDown={(e) => {
                e.stopPropagation();
              }}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: vars.spacing['xxs'],
              }}
            >
              <EditIcon
                fill={IconsFill.textBrand}
                withHoverEffect
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  onRowStartEdit?.();
                }}
              />
              <DeleteIcon
                disabled={disabled}
                fill={IconsFill.textBrand}
                withHoverEffect
                onClick={() => onRowDelete(tag)}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const TagsPanelEditMode = ({
  tagsWithEmptyValue,
  tagsWithValue,
  onTagsUpdate,
  onTagValueChange,
  disabled,
  entityType,
  onEditDone,
}: {
  tagsWithEmptyValue: Tag[];
  tagsWithValue: Tag[];
  disabled?: boolean;
  onTagsUpdate: (tagsCurrentEntity: Tag[]) => void;
  onTagValueChange: (tag: Tag, newValue: string) => void;
  onEditDone: () => void;
  entityType:
    | ActionOutboxEntityType.Listing
    | ActionOutboxEntityType.Purchase
    | ActionOutboxEntityType.Sale
    | ActionOutboxEntityType.SellerEvent;
}) => {
  const [isEditingTagMetadata, setIsEditingTagMetadata] =
    useState<boolean>(false);
  const [isCreatingTag, setIsCreatingTag] = useState<boolean>(false);
  const [tagsEditing, setTagsEditing] = useState<Tag>();
  const {
    tagsMetadata: existingTagsCurrentType = [],
    tagsWithNoValue: existingTagsCurrentTypeWithNoValue = [],
    mergeEntityTagsMetadata,
    deleteEntityTagsMetadata,
  } = useTagsForEntityType(entityType);
  const { metricsInUse } = useGetMetricsInUse({ entityType });

  const hasCreateTagPermission = useUserHasAnyOfPermissions(
    entityType === ActionOutboxEntityType.Listing
      ? Permission.Inventory_AddTagType
      : entityType === ActionOutboxEntityType.Sale
      ? Permission.Sales_AddTagType
      : entityType === ActionOutboxEntityType.Purchase
      ? Permission.Purchases_AddTagType
      : Permission.Events_AddTagType
  );

  const onDragEndHandler = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      const activeIndex = active.data.current?.sortable.index;
      const overIndex = over?.data.current?.sortable.index;
      if (!over) return;
      onTagsUpdate(
        arrayMove(tagsWithEmptyValue, activeIndex, overIndex)
          .filter((t) => t.value)
          .map((t, i) => ({
            ...t,
            ordinal: i,
          }))
      );
    },
    [onTagsUpdate, tagsWithEmptyValue]
  );

  const onTagDelete = useCallback(
    (tag: Tag) => {
      const newTags = tagsWithEmptyValue.filter(
        (t) => !(t.key === tag.key && t.value === tag.value)
      );
      onTagsUpdate(newTags.filter((t) => t.value));
    },
    [onTagsUpdate, tagsWithEmptyValue]
  );

  const onTagMetadataUpdate = useCallback(
    async (tagMetadataUpdate: Tag) => {
      // Update tags metadata immediately
      await mergeEntityTagsMetadata([tagMetadataUpdate]);
    },
    [mergeEntityTagsMetadata]
  );

  const onTagMetadataDelete = useCallback(
    async (tag: Tag) => {
      // Delete tags metadata immediately
      await deleteEntityTagsMetadata([tag]);
      // Remove tags with the deleted key in the entity in edit mode
      onTagsUpdate(tagsWithEmptyValue.filter((t) => t.key !== tag.key));
    },
    [deleteEntityTagsMetadata, onTagsUpdate, tagsWithEmptyValue]
  );

  // Merge tags with no value from the entity type with the ones from the entity
  const existingTagsCurrentTypeWithNoValueMerged = useMemo(
    () =>
      existingTagsCurrentTypeWithNoValue.filter(
        (t) => tagsWithValue.find((t2) => t2.key === t.key) == null
      ),
    [existingTagsCurrentTypeWithNoValue, tagsWithValue]
  );

  return (
    <Stack direction="column">
      <div className={styles.dndContainer}>
        <DndContext onDragEnd={onDragEndHandler}>
          <SortableContext
            items={tagsWithValue.map((tag) => tag.ordinal?.toString() ?? '')}
          >
            {tagsWithValue.map((tag) => (
              <DropWrapper
                id={tag.ordinal?.toString() ?? ''}
                key={`${tag.key}-${tag.value}`}
              >
                <TagDraggableListItem
                  key={`${tag.key}-${tag.value}`}
                  tag={tag}
                  tagsCurrentEntity={tagsWithValue}
                  isEditing={
                    tagsEditing?.key === tag.key &&
                    tagsEditing?.value === tag.value
                  }
                  onRowStartEdit={() => {
                    setTagsEditing(tag);
                  }}
                  disabled={isEditingTagMetadata || disabled}
                  onBlur={() => setTagsEditing(undefined)}
                  onRowChange={onTagValueChange}
                  onRowDelete={onTagDelete}
                />
              </DropWrapper>
            ))}
          </SortableContext>
        </DndContext>
      </div>
      {isEditingTagMetadata ? (
        <>
          {isCreatingTag ? (
            <CreateTagMetadataForm
              mergedTagsMetadata={existingTagsCurrentType ?? []}
              onSubmit={onTagMetadataUpdate}
              onClose={() => setIsEditingTagMetadata(false)}
            />
          ) : (
            <EditTagMetadataForm
              mergedTagsMetadata={existingTagsCurrentType ?? []}
              mergedTagsMetadataWithNoValue={
                existingTagsCurrentTypeWithNoValueMerged
              }
              onSubmit={onTagMetadataUpdate}
              onDeleteSubmit={onTagMetadataDelete}
              onClose={() => setIsEditingTagMetadata(false)}
              metricsInUse={metricsInUse}
            />
          )}
        </>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {hasCreateTagPermission ? (
              <ButtonWithIcon
                icon={
                  <PlusIcon
                    withHoverEffect
                    fill={IconsFill.textBrand}
                    size={vars.iconSize.m}
                  />
                }
                textContentId={ContentId.CreateNewTag}
                onClick={() => {
                  setIsCreatingTag(true);
                  setIsEditingTagMetadata(true);
                }}
                style={{ width: 'min-content', whiteSpace: 'nowrap' }}
                variant={'text'}
              />
            ) : (
              <span />
            )}
            {mergeEntityTagsMetadata.length > 0 ? (
              <ButtonWithIcon
                icon={
                  <EditIcon
                    withHoverEffect
                    fill={IconsFill.textBrand}
                    size={vars.iconSize.m}
                  />
                }
                textContentId={ContentId.EditTagTypes}
                onClick={() => {
                  setIsCreatingTag(false);
                  setIsEditingTagMetadata(true);
                }}
                style={{ width: 'min-content', whiteSpace: 'nowrap' }}
                variant={'text'}
              />
            ) : (
              <span />
            )}
          </div>
          <Button
            onClick={() => {
              setIsEditingTagMetadata(false);
              setTagsEditing(undefined);
              onTagsUpdate(tagsWithValue.map((t, i) => ({ ...t, ordinal: i })));
              onEditDone();
            }}
            disabled={disabled}
            style={{ width: '100%', whiteSpace: 'nowrap' }}
            variant="outline"
          >
            <Content id={ContentId.Done} />
          </Button>
        </>
      )}
    </Stack>
  );
};
