import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const ListingTilesContainer = styled.div``;
export const UnHideIconContainer = styled.span`
  display: flex;
  ${({ theme: { typography } }) => typography.getStyles('body1', 'medium')}
`;

export const Divider = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};
`;

export const CheckboxContainer = styled.div`
  label {
    margin-bottom: 0;
  }
`;

export const DetailLabel = styled.span<{ textAlign?: TextAlign }>`
  ${({ theme: { typography } }) => typography.getStyles('caption1', 'regular')}
  color: ${({ theme: { colors } }) => colors.textPrimary};
  text-align: ${(props) =>
    props.textAlign ? props.textAlign.toString() : 'left'};
`;

export const GroupCountBadge = styled.div`
  width: ${() => vars.spacing.xl};
  height: ${() => vars.spacing.xl};
  background-color: ${(props) => props.theme.colors.backgroundBrandActive};
  border-radius: 12px;
  color: ${(props) => props.theme.colors.textInverted};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

export const DetailValue = styled.span<{ textAlign?: TextAlign }>`
  ${({ theme: { typography } }) => typography.getStyles('caption1', 'regular')}
  text-align: ${(props) =>
    props.textAlign ? props.textAlign.toString() : 'left'};
  padding-right: ${() => vars.spacing.xxs};
`;

export const DetailUnitCostValue = styled.span`
  ${({ theme: { typography } }) => typography.getStyles('caption1', 'regular')}
  display: flex;
  align-items: center;
  padding-right: ${() => vars.spacing.xxs};
`;

export enum TextAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}
