import { ChangeEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import {
  CheckIcon,
  CrossIcon,
  IconsFill,
  ProcessingIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { AutoPricingOutlierMode } from 'src/WebApiController';

import {
  ListingPricingInput,
  ListingPricingSettingsCellProps,
} from '../ListingPriceForm.types';
import * as styles from './Cell.css';

export const OutlierLimitCell: React.FC<ListingPricingSettingsCellProps> = ({
  listing,
  editable,
  event,
}) => {
  const { watch, formState, getFieldState, setValue } =
    useFormContext<ListingPricingInput>();

  const saveMsg = useContent(ContentId.Save);
  const cancelMsg = useContent(ContentId.Cancel);

  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);

  const { outlierMode, outlierKthLowestLimit, canAutoPrice, onOutlierChange } =
    useListingAutoPricingSettings(listing);

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingPrice = watch('isSubmitting');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const isDisabled =
    listing.isDeleted ||
    !canAutoPrice ||
    listingSelection.isSingleGroupMultiSelect ||
    fieldIsSubmitting ||
    !editable ||
    outlierMode !== AutoPricingOutlierMode.KthLowest;

  const submittingField = watch('submittingField');
  const isSubmittingField =
    fieldIsSubmitting && submittingField === 'outlierKthLowestLimit';

  const onUpdateOutlierLimit = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value);
      onOutlierChange({
        outlierModeNew: outlierMode,
        outlierKthLowestLimitNew: value,
      });
    },
    [onOutlierChange, outlierMode]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <PosFormField
        errors={
          getFieldState('outlierKthLowestLimit', formState)?.error?.message
        }
        showErrorsInline
        className={styles.formFieldContainer}
      >
        <PosTextField
          className={styles.formTextFiled}
          value={outlierKthLowestLimit ?? ''}
          disabled={isDisabled}
          type="number"
          min={0}
          max={100}
          onChange={onUpdateOutlierLimit}
          postfixDisplay={
            !isDisabled &&
            outlierKthLowestLimit !==
              formState.defaultValues?.outlierKthLowestLimit ? (
              <Stack direction="row" gap="s">
                <CheckIcon
                  title={saveMsg}
                  withHoverEffect
                  size={vars.iconSize.s}
                  fill={IconsFill.textSuccess}
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue('isSubmittingPricingSettings', true);
                    setValue('isSubmittingRowIndex', rowIndex);
                    setValue('submittingField', 'outlierKthLowestLimit');
                  }}
                />
                <CrossIcon
                  title={cancelMsg}
                  withHoverEffect
                  size={vars.iconSize.s}
                  onClick={(e) => {
                    e.stopPropagation();
                    setValue(
                      'outlierKthLowestLimit',
                      formState.defaultValues?.outlierKthLowestLimit ?? null
                    );
                  }}
                />
              </Stack>
            ) : isSubmittingField ? (
              <div className="operation-button">
                <RotatingWrapper>
                  <ProcessingIcon size={vars.iconSize.s} />
                </RotatingWrapper>
              </div>
            ) : undefined
          }
        />
      </PosFormField>
    </TableCellDiv>
  );
};
