import {
  functionalUpdate,
  RowSelectionState,
  SortingState,
  TableOptions,
  Updater,
} from '@tanstack/react-table';
import clsx from 'clsx';
import {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { EventAccordionItemBodyComponentType } from 'src/components/Accordions';
import {
  Content,
  FormatContent,
  useContent,
} from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { packNumericIds } from 'src/contexts/MultiSelectionContext/MultiSelectionContext.utils';
import { useClearShiftSelectionOnSortingChange } from 'src/contexts/MultiSelectionContext/useClearShiftSelectionOnSortingChange';
import { NoData, Table, TableProps } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { generateShimmeringRows } from 'src/utils/dataTableUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import { SomethingWentWrong } from 'src/views';
import {
  Event,
  Performer,
  TicketGroupInput,
  Venue,
} from 'src/WebApiController';

import { RowComponentWithRowData } from '../Table/Table.types';
import { usePaginationSettings } from '../Table/usePaginationSettings';
import {
  TICKET_GROUP_TABLE_COLUMNS_CONFIG,
  TicketGroupWithHandlers,
} from './configs/TicketGroupTableColumnsConfig';
import * as styles from './TicketGroupTable.css';

export type TicketGroupTableDataProps = {
  ticketGroups?: TicketGroupInput[] | null;
  highlightTicketGroupIds?: number[];
} & Pick<
  EventAccordionItemBodyComponentType,
  | 'onMount'
  | 'onUnmount'
  | 'initState'
  | 'getDataFail'
  | 'disablePagination'
  | 'ticketGrpsCnt'
>;

export type TicketGroupTableProps = TicketGroupTableDataProps & {
  groupId: string;
  event?: Event | null;
  performer?: Performer | null;
  venue?: Venue | null;
  disabled?: boolean;
  /**
   * Show the ticket groups from expanding a single purchase
   */
  showFromPurchaseAccordions?: boolean;
} & Pick<
    TableProps<TicketGroupWithHandlers | null>,
    'tableStyle' | 'tableHeadStyle' | 'tableCellStyle' | 'withOuterPadding'
  > &
  Pick<TicketGroupWithHandlers, 'onTicketGroupAction'>;

const PAGE_SIZE = 10;

const getRowId = (
  originalRow: TicketGroupWithHandlers | null,
  index: number
) =>
  originalRow?.ticketGroup.purchaseOrderId
    ? packNumericIds([
        originalRow.ticketGroup.purchaseOrderId,
        originalRow.ticketGroup.ticketGroupId,
      ])
    : index.toString();

export function TicketGroupTable({
  groupId,
  ticketGroups,
  highlightTicketGroupIds,
  onTicketGroupAction,
  event,
  performer,
  venue,
  onMount,
  onUnmount,
  disabled,
  initState,
  showFromPurchaseAccordions = false,
  ticketGrpsCnt,
  tableStyle,
  tableHeadStyle,
  tableCellStyle,
  getDataFail,
  disablePagination,
  withOuterPadding,
}: TicketGroupTableProps) {
  const ticketsText = useContent(ContentId.Tickets);

  const { watch } = useFormContext<PurchaseOrderDetailsInput>();
  const vendorOrderId = watch('vendorOrderId');

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || []
  );

  const { setGroupItems, getGroupToggleState, getSelection, selectionMode } =
    useMultiSelectionContext();

  useClearShiftSelectionOnSortingChange({ sortingState: sorting });

  const groupSelection = getSelection(groupId);

  const data = useMemo(() => {
    if (!ticketGroups || (!ticketGroups.length && showFromPurchaseAccordions)) {
      return generateShimmeringRows(ticketGrpsCnt ?? 1) as null[];
    }

    return ticketGroups?.map(
      (tg) =>
        ({
          ticketGroup: tg,
          disabled,
          onTicketGroupAction,
          additionalData: {
            purchaseVendorOrderId: vendorOrderId?.value,
            event,
            performer,
            venue,
          },
          showFromPurchaseAccordions,
        }) as TicketGroupWithHandlers
    );
  }, [
    ticketGroups,
    showFromPurchaseAccordions,
    ticketGrpsCnt,
    disabled,
    onTicketGroupAction,
    vendorOrderId?.value,
    event,
    performer,
    venue,
  ]);

  const { pagination, setPagination } = usePaginationSettings(
    data.length,
    -1,
    PAGE_SIZE,
    disablePagination,
    initState
  );

  useEffect(() => {
    onMount?.();
  });

  useEffect(() => {
    /**
     * Intent for `onMount` is use with 'windowing' in order to maintain user state
     * when the component is scrolled back into view.
     * Anything that needs to be persisted in `react-table` state should be added here.
     * Only update on unmount to ensure we aren't doing too many re-renders.
     */
    return () => onUnmount?.({ pagination, sorting });
  }, [pagination, sorting, onUnmount]);

  const rowSelection = useMemo(() => {
    // Type casting needed because of a bug in older TS versions: https://github.com/microsoft/TypeScript/issues/44373
    return (data as (TicketGroupWithHandlers | null)[]).reduce<
      Record<string, boolean>
    >(
      (result, rowItem) => {
        if (rowItem?.ticketGroup?.purchaseOrderId) {
          const rowId = getRowId(rowItem!, -1);
          result[rowId] =
            getGroupToggleState(groupId).isGroupSelected &&
            !groupSelection.items.itemIds.length
              ? true
              : (groupSelection?.items.itemIds ?? [])?.includes(rowId) ?? false;
        }
        return result;
      },
      {} as Record<string, boolean>
    );
  }, [data, getGroupToggleState, groupId, groupSelection.items.itemIds]);

  const onRowSelectionChange = useCallback(
    (updater: Updater<RowSelectionState>) => {
      const newSelections =
        typeof updater === 'function' ? updater(rowSelection) : updater;
      setGroupItems(groupId, newSelections);
    },
    [groupId, rowSelection, setGroupItems]
  );

  const options: Partial<TableOptions<TicketGroupWithHandlers | null>> =
    useMemo(
      () => ({
        data,
        columns: TICKET_GROUP_TABLE_COLUMNS_CONFIG,
        state: {
          pagination,
          rowSelection,
          sorting,
          columnVisibility: {
            event: showFromPurchaseAccordions,
            venue: showFromPurchaseAccordions,
            eventDate: showFromPurchaseAccordions,
            expectedValue: !showFromPurchaseAccordions,
            taxPaid: !showFromPurchaseAccordions,
            errors: !showFromPurchaseAccordions,
          },
        },
        enableSorting: !showFromPurchaseAccordions,
        getRowId: getRowId,
        onPaginationChange: setPagination,
        onRowSelectionChange,
        onSortingChange: (sortingUpdaterFn) => {
          const newSortVal = functionalUpdate(sortingUpdaterFn, sorting);
          setSorting(newSortVal);
        },
      }),
      [
        data,
        onRowSelectionChange,
        pagination,
        rowSelection,
        setPagination,
        showFromPurchaseAccordions,
        sorting,
      ]
    );

  const RowComponent: ComponentType<
    RowComponentWithRowData<TicketGroupWithHandlers | null>
  > = (props) => {
    const { row, className, children, ...rest } = props;
    const highlight = highlightTicketGroupIds?.includes(
      row?.original?.ticketGroup.ticketGroupId ?? -1
    );
    return (
      <tr
        {...rest}
        className={clsx(className, {
          [styles.highlightTicketGroup]: highlight,
        })}
      >
        {children}
      </tr>
    );
  };

  return getDataFail ? (
    <SomethingWentWrong
      message={<Content id={ContentId.FailToLoadListContent} />}
    />
  ) : data?.length ? (
    <Table
      options={options}
      RowComponent={RowComponent}
      tableStyle={tableStyle}
      tableCellStyle={tableCellStyle}
      tableHeadStyle={tableHeadStyle}
      resizeDisabled={showFromPurchaseAccordions}
      withOuterPadding={withOuterPadding}
    />
  ) : (
    // We should never see this - because the events are filtered to only those that has listings
    // So this is to prevent a crash - but this should be looked at
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={ticketsText}
      />
    </NoData>
  );
}
