import { TelemetryProvider } from '@viagogo/telemetry-react';
import { PropsWithChildren } from 'react';
import {
  REACT_APP_APP_INSIGHTS,
  REACT_APP_NAME,
} from 'src/utils/constants/constants';

export const AppTelemetryProvider = ({
  children,
}: PropsWithChildren<object>) => {
  if (REACT_APP_APP_INSIGHTS && REACT_APP_NAME) {
    return (
      <TelemetryProvider
        initConfig={{
          instrumentationKey: REACT_APP_APP_INSIGHTS,
          pageId: REACT_APP_NAME,
          options: {
            disableFetchTracking: true,
          },
        }}
      >
        {children}
      </TelemetryProvider>
    );
  }
  return <>{children}</>;
};
