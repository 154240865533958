import {
  InitialTableState,
  PaginationState,
  SortingState,
  TableOptions,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { FormatContent, useContent } from 'src/contexts/ContentContext';
import { NoData, Table } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { MarketTransaction } from 'src/WebApiController';

import {
  MARKET_TRANSACTIONS_TABLE_COLUMNS_CONFIG,
  MarketTransactionColumnType,
} from './configs/MarketTransactionsTableColumnsConfig';

export type MarketTransactionsTableDataProps = {
  marketTransactions?: MarketTransaction[] | null;
};

export type MarketTransactionsTableProps = MarketTransactionsTableDataProps & {
  initState?: InitialTableState;
};

export function MarketTransactionsTable({
  marketTransactions,
  initState,
}: MarketTransactionsTableProps) {
  const { activeAccountUserId, loginContext } = useAppContext();
  const transactionsText = useContent(ContentId.Transactions);

  // Enable passing in table state as parameters -- we can remount with the last state the user was on
  const [sorting, setSorting] = useState<SortingState>(
    initState?.sorting || []
  );
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: initState?.pagination?.pageIndex || 0,
    pageSize: marketTransactions?.length ?? 0,
  });
  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  useEffect(() => {
    if (pagination.pageSize !== marketTransactions?.length) {
      setPagination((prevState) => ({
        ...prevState,
        pageSize: marketTransactions?.length ?? 0,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketTransactions]);

  let data: MarketTransactionColumnType[] | undefined = undefined;
  if (marketTransactions) {
    data = marketTransactions.map((marketTransaction) => {
      return {
        ...marketTransaction,
        activeAccountUserId:
          loginContext?.user?.activeAccount?.id ?? activeAccountUserId ?? '',
      };
    });
  }

  const options: Partial<TableOptions<MarketTransactionColumnType | null>> =
    useMemo(
      () => ({
        data,
        columns: MARKET_TRANSACTIONS_TABLE_COLUMNS_CONFIG,
        state: {
          pagination,
          sorting,
          activeAccountUserId,
        },
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
      }),
      [data, activeAccountUserId, pagination, sorting]
    );

  return marketTransactions?.length ? (
    <Table options={options} />
  ) : (
    <NoData>
      <FormatContent
        id={FormatContentId.NoDataAvailable}
        params={transactionsText}
      />
    </NoData>
  );
}
