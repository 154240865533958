import { useFormContext } from 'react-hook-form';
import { IconButton } from 'src/components/Buttons';
import { DeliveryTypeSelector } from 'src/components/Selectors/DeliveryTypeSelector';
import { vars } from 'src/core/themes';
import { SimpleTable } from 'src/core/ui';
import { DeleteIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { PurchaseEventOverridesInput } from './FulfillmentSection.types';
import { VendorCell } from './VendorCell';

export const OverrideSettingForm = ({
  disabled,
  onDeleteSetting,
  index,
}: {
  disabled?: boolean;
  onDeleteSetting: (index: number) => void;
  index: number;
}) => {
  const { watch, setValue } = useFormContext<PurchaseEventOverridesInput>();

  const delivType = watch(`settings.${index}.delivType`);

  return (
    <SimpleTable.Tr>
      <SimpleTable.Td>
        <VendorCell disabled={disabled} index={index} />
      </SimpleTable.Td>
      <SimpleTable.Td>
        <DeliveryTypeSelector
          value={delivType}
          onChange={(deliveryType) => {
            if (deliveryType && deliveryType !== delivType) {
              setValue(`settings.${index}.delivType`, deliveryType, {
                shouldDirty: true,
              });
            }
          }}
          disabled={disabled}
        />
      </SimpleTable.Td>
      <SimpleTable.Td>
        <IconButton
          icon={
            <DeleteIcon
              fill={vars.color.textNegative}
              size={vars.iconSize.m}
              withHoverEffect
            />
          }
          disabled={disabled}
          titleContentId={ContentId.Delete}
          onClick={() => onDeleteSetting(index)}
        />
      </SimpleTable.Td>
    </SimpleTable.Tr>
  );
};
