import { byLogo } from 'src/components/SignUp/Footer/Footer.css';
import { Stack } from 'src/core/ui';
import { StubHubLogo } from 'src/svgs';

export const Footer = () => {
  return (
    <Stack gap="l" justifyContent="center" alignItems="center">
      <span className={byLogo}>By</span>
      <StubHubLogo colorMode="white" width="64px" height="100%" />
    </Stack>
  );
};
