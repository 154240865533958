import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const TagIcon = (props: PosIconProps) => {
  return (
    // It's import for the viewbox fo this SVG to stay 20 else it won't resize properly
    <SvgWrapper aria-label="TagIcon" {...props} viewBox="0 0 20 20">
      <path
        d="M12.2277 0.666672H10.9256V1.96876V4.57292V5.19141C10.828 5.25652 10.7303 5.32162 10.6652 5.38672C10.1444 5.90756 10.1444 6.72136 10.6652 7.24219C11.186 7.76303 11.9998 7.76303 12.5207 7.24219C13.0415 6.72136 13.0415 5.90756 12.5207 5.38672C12.423 5.28907 12.3579 5.25652 12.2603 5.19141V3.72657H14.1808V9.26042L7.89828 15.5104L2.36442 9.97657L8.61442 3.72657H10.5024L9.8514 2.42448H8.09359L0.541504 9.97657L7.89828 17.3333L15.4504 9.78126V2.42448H12.2277V0.666672Z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
};
