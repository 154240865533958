import { BarcodeForm } from 'src/components/UploadArtifacts/UploadBarcodes';
import { EntityWithRealTicketsPartial } from 'src/components/UploadArtifacts/UploadETicketsV2';
import {
  ActionOutboxEntityType,
  CostDetail,
  DeliveryType,
  MarketplaceEntityWithTickets,
  Ticket,
  TicketGroup,
  TicketGroupInput,
  TicketTransferRequest,
  VenueMapSectionType,
} from 'src/WebApiController';

import { ContentId } from './constants/contentId';
import { ContentIds } from './constants/contentIdDataMap';
import { FormatContentId } from './constants/formatContentId';
import { FormatContentIds } from './constants/formatContentIdDataMap';
import { posField } from './posFieldUtils';

export type EntityWithRealTickets = MarketplaceEntityWithTickets & {
  tickets: Ticket[];
  entityType: ActionOutboxEntityType;
};

export function initSeats(
  count: number,
  row: string | null,
  seatFr?: string | null,
  seatTo?: string | null
) {
  const seats = [];
  const startSeat = parseInt(seatFr!);
  const initialHasRowsAndSeats = Boolean(seatFr);

  for (let i = 0; i < count; i++) {
    const seatNum = !isNaN(startSeat) ? `${startSeat + i}` : i + 1;
    const seat = initialHasRowsAndSeats
      ? seatFr === seatTo
        ? seatFr || seatNum
        : seatNum
      : '';
    seats.push(makeSeat(i, row, seat));
  }
  return seats;
}

export function makeSeat(i: number, row: string | null, seat: string | number) {
  return {
    id: -i,
    ord: i,
    lstOrd: i,
    row: row,
    seat: seat,
  } as Ticket;
}

// Stringify the props because RHF lib only allows for string returns in TS
export const validateSeat = (
  seat: string,
  ordinal: number,
  currentSeats: Record<string, number>
) => {
  if (!seat) {
    return JSON.stringify(ContentIds[ContentId.Required]);
  }

  // Sometimes this comes in as a number for some reason
  seat = seat.toString().trim();
  if (currentSeats[seat] && currentSeats[seat] !== ordinal) {
    return JSON.stringify({
      ...FormatContentIds[FormatContentId.DuplicatingSeats],
      params: currentSeats[seat.trim()],
    });
  }
};

export type TransferTicketsForm = {
  /* This is to used with react-hook-form's field-arrays, which requires these things to be an object
     The id field is basically just the ordinal number of the input.
     The values for these will get mapped to "acceptanceUrls" before submitting to the backend.
   */
  acceptanceUrlInputs: { id: number; url: string }[];
} & TicketTransferRequest &
  BarcodeForm;

export const getTicketGroupTotalCost = (tg: TicketGroup) => {
  return getTicketGroupTotalTicketCost(tg) + (tg.delivCst?.amt ?? 0);
};

export const getTicketGroupInputTotalCost = (tg: TicketGroupInput) => {
  return getTicketGroupInputTotalTicketCost(tg) + (tg.deliveryCost?.value ?? 0);
};

export const getTicketGroupTotalTicketCost = (tg: TicketGroup) => {
  return (tg.unitCst?.amt ?? 0) * tg.ticketCnt;
};

export const getTicketGroupInputTotalTicketCost = (tg: TicketGroupInput) => {
  return (tg.unitCost?.value ?? 0) * (tg.numberOfTickets?.value ?? 0);
};

export const getCosts = (
  ticketGroups?: TicketGroupInput[],
  costs?: CostDetail[] | null
) => {
  // TODO - https://thestubhub.atlassian.net/browse/POS-426
  // how do we handle these being in different currency units?
  // Perhaps when the currency code for each ticket-group is different from the main currency code, then
  // we submit all the amounts to the server to use the money service to convert prior to displaying them
  const totalTicketCost = (ticketGroups ?? []).reduce(
    (acc, tg) => acc + getTicketGroupInputTotalTicketCost(tg),
    0
  );

  const totalShipping = (ticketGroups ?? []).reduce(
    (acc, tg) => acc + (tg.deliveryCost?.value ?? 0),
    0
  );

  const totalCost = (costs ?? []).reduce(
    (acc, c) => acc + (c.cost?.amt ?? 0),
    totalTicketCost + totalShipping
  );

  return {
    totalTicketCost,
    totalShipping,
    totalCost,
  };
};

const getLongestConsecutiveNumber = (str: string) => {
  let longestNumber = '';
  let currentNumber = '';

  for (let i = str.length - 1; i >= 0; i--) {
    const charCode = str.charCodeAt(i);

    if (charCode >= 48 && charCode <= 57) {
      currentNumber = str[i] + currentNumber;
    } else {
      if (currentNumber.length > longestNumber.length) {
        longestNumber = currentNumber;
      }
      currentNumber = '';
    }
  }

  // Check the last currentNumber in case the string ends with a number
  if (currentNumber.length > longestNumber.length) {
    longestNumber = currentNumber;
  }

  return longestNumber;
};

export const fitsSeatIncrementType = (
  seatFr: string,
  seatTo: string,
  quantity: number,
  increment: number
) => {
  let seatValue = seatFr;
  for (let i = 0; i < quantity - 1; i++) {
    seatValue = getNextTicketBy(seatValue, increment).toString();
  }

  return seatValue === seatTo;
};

export const getNextTicketBy = (
  ticketNo: string | number,
  increment: number
) => {
  let result = ticketNo;
  for (let i = 0; i < increment; i++) {
    result = getNextTicket(result);
  }

  return result;
};

export const getNextTicket = (ticketNo: string | number) => {
  if (typeof ticketNo === 'number') {
    return ticketNo + 1;
  }

  const lastChar = ticketNo.slice(-1);

  if (lastChar === 'Z') {
    return `${ticketNo}A`;
  } else if (lastChar === 'z') {
    return `${ticketNo}a`;
  } else if (!isNaN(parseInt(lastChar))) {
    const numPart = getLongestConsecutiveNumber(ticketNo);

    return `${ticketNo.slice(0, ticketNo.length - numPart.length)}${
      parseInt(numPart) + 1
    }`;
  }

  return (
    ticketNo.slice(0, -1) + String.fromCharCode(lastChar.charCodeAt(0) + 1)
  );
};

export const selectEventInfoFromTicketGroups = (
  ticketGroups: TicketGroupInput[]
) => {
  return ticketGroups.map((ticketGroup) => {
    return {
      tickets: posField([] as Ticket[]),
      event: ticketGroup.event,
      posEventId: ticketGroup.posEventId,
      viagogoEventId: ticketGroup.viagogoEventId,
      eventMappingId: ticketGroup.eventMappingId,
      viagogoVirtualId:
        ticketGroup.event?.viagVirtualId ?? ticketGroup.viagogoVirtualId,
      purchaseOrderId: ticketGroup.purchaseOrderId,
      venue: ticketGroup.venue,
      currencyCode: ticketGroup.currencyCode,
      ticketGroupId: ticketGroup.ticketGroupId,
      deliveryType: posField(
        ticketGroup.deliveryType?.value ?? DeliveryType.InApp
      ),
      taxPaid: posField(1), // This is to turn off taxExempt by default
    } as unknown as TicketGroupInput;
  });
};

export const selectDistinctListingIds = (tickets: Ticket[]) => {
  return Array.from(new Set(tickets.map((ticket) => ticket.listingId)));
};

export const selectDistinctPurchaseOrderIds = (tickets: Ticket[]) => {
  return Array.from(new Set(tickets.map((ticket) => ticket.poId)));
};

export const formatSeatDisplay = (
  seatFr: string | null,
  seatTo: string | null
) => {
  let text = seatFr ?? '';
  if (seatTo && seatFr && seatTo !== seatFr) {
    text += ` - ${seatTo}`;
  }

  return text;
};

export const SECTION_TYPES_GA = [
  VenueMapSectionType.GeneralAdmission,
  VenueMapSectionType.GeneralAdmissionSeating,
  VenueMapSectionType.GeneralAdmissionStanding,
  VenueMapSectionType.GeneralAdmissionWithQueueNumber,
];

export const isSectionTypeGeneralAdmission = (
  sectionType: VenueMapSectionType | null | undefined
) => sectionType != null && SECTION_TYPES_GA.includes(sectionType);

export const toSeating = (ticketGroup: TicketGroupInput) => {
  return {
    section: ticketGroup.section?.value ?? '',
    row: ticketGroup.row?.value ?? '',
    seatFr: ticketGroup.tickets?.value?.length
      ? ticketGroup.tickets.value[0]!.seat
      : '',
    seatTo: ticketGroup.tickets?.value?.length
      ? ticketGroup.tickets.value[ticketGroup.tickets?.value?.length - 1]!.seat
      : '',
    sectionId: ticketGroup.sectionId?.value ?? null,
    rowId: ticketGroup.rowId?.value ?? null,
    ticketIds: null,
    listingIds: null,
  };
};

export const toEntityWithTicket = (
  ticketGroup: TicketGroupInput
): EntityWithRealTicketsPartial => {
  return {
    id: ticketGroup.ticketGroupId,
    entityType: ActionOutboxEntityType.TicketGroup,
    viagVirtualId: ticketGroup.viagogoVirtualId,
    seating: toSeating(ticketGroup),
    ticketCnt: ticketGroup.numberOfTickets?.value ?? 0,
    tickets: ticketGroup.tickets?.value ?? [],
  };
};
