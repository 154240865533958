import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { DEFAULT_AUTO_GENERATED_PAYMENT_METHOD } from 'src/utils/constants/constants';
import { PurchaseClient } from 'src/WebApiController';

export const useGetDefaultAutoGeneratedPaymentMethod = () => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const shouldQuery = activeAccountWebClientConfig.activeAccountId != null;
  const defaultAutoGeneratedPaymentMethodQuery = useQuery({
    queryKey: [
      'PurchaseClient.getPurchasePaymentMethodsByName',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }
      const response = await new PurchaseClient(
        activeAccountWebClientConfig
      ).getPurchasePaymentMethodsByName(DEFAULT_AUTO_GENERATED_PAYMENT_METHOD);

      if (response == null || response.length == 0) {
        return null;
      }

      return response[0];
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog(
          'PurchaseClient.getPurchasePaymentMethodsByName',
          error,
          {
            trackErrorData: {
              activeAccountId: activeAccountWebClientConfig.activeAccountId,
            },
          }
        );
      },
    },
  });

  return {
    defaultAutoGeneratedPaymentMethod:
      defaultAutoGeneratedPaymentMethodQuery.data,
    defaultAutoGeneratedPaymentMethodQuery,
  };
};
