import { formatInTimeZone } from 'date-fns-tz';
import {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { SellerSupportCaseDataContext } from 'src/contexts/SellerSupportCaseDataContext';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AttachmentResource,
  SellerSupportCaseAttachmentRequest,
  SellerSupportCaseClient,
  SellerUser,
} from 'src/WebApiController';

import { AttachmentInfo, AttachmentsPanel } from '../AttachmentsPanel';
import * as styles from './SupportCaseNoteDisplay.css';

export type SupportCaseNoteDisplayProps = {
  createdByUserId?: string | null;
  noteDate?: Date;
  noteContent?: string | null;
  attachments?: AttachmentResource[] | null;
} & ComponentProps<'div'>;

export const SupportCaseNoteDisplay = ({
  createdByUserId,
  noteDate,
  noteContent,
  attachments,
  ...props
}: SupportCaseNoteDisplayProps) => {
  const { timeZone } = useSiteTimezoneContext();
  const { getSellerUserDetails } = useContext(SellerSupportCaseDataContext);
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const [userDetails, setUserDetails] = useState<SellerUser | null>();
  const [attachmentsInfo, setAttachmentsInfo] = useState<AttachmentInfo[]>([]);

  const getUserDetails = useCallback(
    async (sellerUserId: string | null | undefined) => {
      const details =
        sellerUserId && getSellerUserDetails
          ? await getSellerUserDetails(sellerUserId)
          : null;
      setUserDetails(details);
    },
    [getSellerUserDetails]
  );

  useEffect(() => {
    getUserDetails(createdByUserId);
  }, [createdByUserId, getUserDetails]);

  const fetchAttachmentMetadata = useCallback(async () => {
    const requests: SellerSupportCaseAttachmentRequest[] =
      attachments?.map((a) => {
        return {
          id: a.id,
          contentType: a.content_type,
          name: a.name,
        };
      }) ?? [];

    const attachmentsResult = await tryInvokeApi(
      async () => {
        return await new SellerSupportCaseClient(
          activeAccountWebClientConfig
        ).retrieveSellerSupportCaseNoteAttachments(requests, false);
      },
      (error) => {
        trackError(
          'SellerSupportCaseClient.retrieveSellerSupportCaseNoteAttachments',
          error,
          { requests: JSON.stringify(requests) }
        );
      }
    );

    if (attachmentsResult == null) {
      return;
    }

    if (attachmentsResult?.length === attachments?.length) {
      const info: AttachmentInfo[] = [];
      for (let i = 0; i < attachmentsResult.length; i++) {
        info.push({
          attachmentId: attachments[i].id,
          name: attachmentsResult[i].fileName,
          size: attachmentsResult[i].length,
          // We aren't fetching content here. Actual content will be fetched if user
          // downloads the file
          content: undefined,
          contentType: attachmentsResult[i].contentType,
        });
      }
      setAttachmentsInfo(info);
    }
  }, [activeAccountWebClientConfig, attachments, trackError]);

  useEffect(() => {
    fetchAttachmentMetadata();
  }, [attachments, fetchAttachmentMetadata]);

  return (
    <div {...props} className={styles.noteDisplayContainer}>
      <div className={styles.header}>
        <div className={styles.senderInfoContainer}>
          <div className={styles.senderTitleContainer}>
            {userDetails?.sellerDisplayName}
          </div>
          {userDetails?.email && (
            <div className={styles.senderEmailContainer}>
              {userDetails.email}
            </div>
          )}
        </div>
        <div className={styles.dateTimeContainer}>
          {noteDate &&
            formatInTimeZone(noteDate, timeZone, 'LLL dd yyyy, h:mm')}
        </div>
      </div>
      <div className={styles.contentContainer}>{noteContent}</div>
      {attachments && attachments.length > 0 && (
        <AttachmentsPanel attachments={attachmentsInfo} isDownloadable />
      )}
    </div>
  );
};
