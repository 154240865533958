export const AxsLogo = ({
  width,
  height,
}: {
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <svg
      width={width ?? 196}
      height={height ?? 80}
      viewBox="0 0 196 80"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      textRendering="geometricPrecision"
      shapeRendering="geometricPrecision"
    >
      <g>
        <path
          opacity="0.932"
          fill="#0755A0"
          d="M -0.5,52.5 C -0.5,47.5 -0.5,42.5 -0.5,37.5C 1.95672,31.5459 6.29006,27.3792 12.5,25C 21.4175,23.356 30.4175,22.5226 39.5,22.5C 39.7553,18.5075 38.0886,15.6742 34.5,14C 26.4222,13.483 18.4222,13.8163 10.5,15C 8.28609,12.0746 5.95276,9.24128 3.5,6.5C 5.91694,3.95811 8.91694,2.45811 12.5,2C 22.8418,0.0295423 33.1751,0.0295423 43.5,2C 51.1506,4.77898 55.3173,10.279 56,18.5C 56.8238,34.0744 56.6571,49.5744 55.5,65C 52.8333,65.6667 50.1667,65.6667 47.5,65C 45.7068,62.2047 43.5402,59.7047 41,57.5C 34.0771,66.0433 25.2438,68.8766 14.5,66C 7.05688,64.0499 2.05688,59.5499 -0.5,52.5 Z M 30.5,35.5 C 39.4238,33.8522 41.9238,37.1855 38,45.5C 34.517,53.826 28.6836,56.1593 20.5,52.5C 16.2763,47.3452 16.6096,42.5119 21.5,38C 24.6282,37.1588 27.6282,36.3254 30.5,35.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.91"
          fill="#0755A0"
          d="M 54.5,0.5 C 64.5056,0.333518 74.5056,0.500185 84.5,1C 98.7258,25.9485 112.059,51.4485 124.5,77.5C 121.894,78.6517 119.227,78.8184 116.5,78C 108.7,67.7014 100.367,57.868 91.5,48.5C 86.7147,53.9071 82.048,59.4071 77.5,65C 73.8484,65.4986 70.1817,65.6653 66.5,65.5C 66.3398,63.8008 66.5065,62.1341 67,60.5C 71.4419,51.9501 75.6085,43.2834 79.5,34.5C 71.9327,24.0906 63.766,14.0906 55,4.5C 54.51,3.20677 54.3433,1.87344 54.5,0.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.885"
          fill="#0755A0"
          d="M 100.5,0.5 C 110.998,0.0259205 121.331,0.52592 131.5,2C 123.376,12.4591 114.876,22.6257 106,32.5C 102.735,24.2056 99.5685,15.8723 96.5,7.5C 97.5923,4.97634 98.9257,2.64301 100.5,0.5 Z"
        />
      </g>
      <g>
        <path
          opacity="0.921"
          fill="#0755A0"
          d="M 144.5,0.5 C 156.129,-0.354397 167.462,0.978936 178.5,4.5C 177.358,8.93176 175.025,12.7651 171.5,16C 168.153,15.783 164.82,15.1163 161.5,14C 157.5,13.3333 153.5,13.3333 149.5,14C 145.205,15.8451 144.538,18.5118 147.5,22C 153.67,24.4602 160.003,26.4602 166.5,28C 178.903,33.8487 182.736,43.3487 178,56.5C 175.382,61.1163 171.549,64.283 166.5,66C 154.531,68.5899 142.865,67.5899 131.5,63C 125.995,61.4877 125.162,58.6544 129,54.5C 131.016,52.316 133.183,50.316 135.5,48.5C 142.569,53.0067 150.236,54.5067 158.5,53C 164.126,50.513 164.792,47.1797 160.5,43C 153.936,40.6919 147.27,38.6919 140.5,37C 130.211,31.813 126.711,23.6463 130,12.5C 133.069,6.23488 137.902,2.23488 144.5,0.5 Z"
        />
      </g>
    </svg>
  );
};
