import { UploadETicketsDraggableSource } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Draggable/constants';
import * as styles from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/SinglePageDocument/SinglePageDocument.css';
import {
  SinglePageDocumentPage,
  SinglePageDocumentPageProps,
} from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/SinglePageDocument/SinglePageDocumentPage';
import {
  UploadETicketsDraggableData,
  UploadETicketsDraggableOrigin,
} from 'src/components/UploadArtifacts/UploadETicketsV2/views/Tickets/Documents/UploadETicketsDraggableData';
import { PosDraggable } from 'src/core/POS/PosDragNDrop';

export const SingleDraggablePageDocument = (
  props: SinglePageDocumentPageProps
) => {
  const { pages } = props.documentFileUploadInfo;
  const page = pages?.[0]!;
  return (
    <PosDraggable<UploadETicketsDraggableData>
      className={styles.dragContainer}
      draggableSource={UploadETicketsDraggableSource}
      draggableData={{
        isSinglePageDocument: true,
        origin: UploadETicketsDraggableOrigin.TicketCard,
        componentProps: {
          ...props,
          page,
          pageIndex: 0, // zero because is a file with a single page
        },
      }}
    >
      <SinglePageDocumentPage {...props} />
    </PosDraggable>
  );
};
