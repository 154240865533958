import { Navigate, useLocation, useParams } from 'react-router-dom';
import { createInventoryEventUrl } from 'src/navigations/Routes/InventoryEvent/InventoryEvent.utils';

/**
 * This component is used to redirect old URL with the form
 * /inventory/event/:eventId to the new URL /inventory/event?queryId=eventId
 * to keep users who might have the old URL saved in favourites working.
 * @constructor
 */
export const InventoryEventPageRedirect = () => {
  const { eventId: eventIdParam } = useParams();
  const location = useLocation();

  if (eventIdParam) {
    return (
      <Navigate
        to={createInventoryEventUrl(
          eventIdParam,
          true,
          false,
          'queryId' /* this is the old param */
        )}
        replace={true}
        state={location.state}
      />
    );
  }

  return <Navigate to="/" replace={true} />;
};
