import { useMemo } from 'react';
import {
  FilterToolbarGroup,
  FilterToolbarItemId,
} from 'src/components/FilterToolbar/FilterToolbar';
import { getAppliedFilters } from 'src/utils/eventQueryUtils';

interface FiltersHelpersProps<TQuery extends object> {
  filters: FilterToolbarGroup[];
  initialQuery: TQuery;
  activeQuery: TQuery;
  mandatoryFiltersToShow: FilterToolbarItemId[];
}

interface UseFiltersHelpers {
  ignoredKeys: FilterToolbarItemId[];
  appliedFilters: FilterToolbarItemId[];
}

export const useFiltersHelpers = <TQuery extends object>({
  filters,
  initialQuery,
  activeQuery,
}: FiltersHelpersProps<TQuery>): UseFiltersHelpers => {
  /**
   * Gets the filters keys that are not shown in the UI.
   * For example, sortBy
   */
  const ignoredKeys = useMemo(() => {
    const allVisualFiltersIds = filters.flatMap((filterGroup) =>
      filterGroup.items.map((filter) => filter.filterId)
    );
    const allQueryIds = Object.keys(initialQuery) as FilterToolbarItemId[];

    return allQueryIds.filter(
      (filterId) => !allVisualFiltersIds.includes(filterId)
    );
  }, [filters, initialQuery]);

  const appliedFilters = useMemo(() => {
    return getAppliedFilters(activeQuery, initialQuery, ignoredKeys, filters);
  }, [filters, ignoredKeys, initialQuery, activeQuery]);

  return {
    ignoredKeys,
    appliedFilters,
  };
};
