import { ChangeEvent, useCallback, useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { BUCKET_OPTIONS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { BucketType, GroupingType } from 'src/WebApiController';

import {
  ListingGroupBy,
  ListingGroupingTypesWithClusteringOptions,
} from './groupingTypes';

export const GroupingBucketOptions = ({
  index,
  groupBy,
  canClusterByRows,
  onGroupingTypeChange,
}: {
  index: number;
  groupBy: ListingGroupBy;
  canClusterByRows: boolean;
  onGroupingTypeChange: (
    groupByIndex: number,
    newGroupType: GroupingType,
    newNumOfClusters?: number,
    newBucketOption?: BucketType
  ) => void;
}) => {
  const [bucketOption, setBucketOption] = useState<BucketType>(
    BucketType.Clusters
  );

  const shouldShowBucketsOption = useCallback(
    (groupBy: ListingGroupBy) => {
      if (groupBy.groupingType === GroupingType.Row) {
        return canClusterByRows;
      }

      return ListingGroupingTypesWithClusteringOptions.includes(
        groupBy.groupingType
      );
    },
    [canClusterByRows]
  );

  const onBucketOptionChange = useCallback((option: BucketType) => {
    setBucketOption(option);
  }, []);

  if (!shouldShowBucketsOption(groupBy)) {
    return null;
  }

  return (
    <Stack direction="column" gap="m" justifyContent="spaceBetween">
      <PosFormField
        label={<Content id={ContentId.NumberOfBuckets} />}
        style={{ width: 'max-content' }}
      >
        <PosTextField
          value={groupBy.numOfClusters ?? '1'}
          type="number"
          min={0}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const v = parseInt(e.target.value);
            if (v >= 1) {
              if (v !== groupBy.numOfClusters) {
                onGroupingTypeChange(
                  index,
                  groupBy.groupingType,
                  v,
                  bucketOption
                );
              }
            }
          }}
        />
      </PosFormField>
      <PosFormField
        label={<Content id={ContentId.BucketOptions} />}
        style={{ width: 'max-content' }}
      >
        <PosEnumSelect
          style={{ width: 'fit-content' }}
          valueOptionsContent={BUCKET_OPTIONS_TO_CID}
          defaultValue={bucketOption}
          value={bucketOption}
          onChange={(v) => {
            if (v != null) {
              onBucketOptionChange(v);
              onGroupingTypeChange(
                index,
                groupBy.groupingType,
                groupBy.numOfClusters ?? 1,
                v
              );
            }
          }}
        />
      </PosFormField>
    </Stack>
  );
};
