import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ListingClient } from 'src/WebApiController';

import { UseGetListingMergeSuggestions } from './UseGetListingMergeSuggestions.types';
import { clusterListingMergeSuggestionsByListingIdOverlap } from './useGetListingMergeSuggestions.utils';

export const useGetListingMergeSuggestions = (
  excludeHeldListings = false
): UseGetListingMergeSuggestions => {
  const { trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();

  const shouldQuery = activeAccountWebClientConfig.activeAccountId != null;
  const mergeSuggestionsQuery = useQuery({
    queryKey: [
      'ListingClient.getListingMergeSuggestions',
      activeAccountWebClientConfig,
      excludeHeldListings,
    ],
    queryFn: () => {
      if (!shouldQuery) {
        return null;
      }
      return new ListingClient(
        activeAccountWebClientConfig
      ).getListingMergeSuggestions(excludeHeldListings);
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        trackError('ListingClient.getListingMergeSuggestions', error);
      },
    },
  });

  const mergeSuggestions = useMemo(() => {
    return clusterListingMergeSuggestionsByListingIdOverlap(
      mergeSuggestionsQuery.data
    );
  }, [mergeSuggestionsQuery.data]);

  return {
    mergeSuggestions,
    isLoading: mergeSuggestionsQuery.isLoading,
  };
};
