import { clsx } from 'clsx';
import React, { ReactNode } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useDndFilerUploaderContext } from 'src/core/POS/DnDFileUploader/DndFileUploaderContext';
import { Stack } from 'src/core/ui';
import { IconsFill, UploadIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './DndGlobalFileDrop.css';

interface DndGlobalFileDropProps {
  children: ReactNode;
  className?: string;
}

export const DndGlobalFileDrop = ({
  children,
  className,
}: DndGlobalFileDropProps) => {
  const { isDragging, handleDrag, handleDrop } = useDndFilerUploaderContext();

  return (
    <div
      className={clsx(styles.mainWrapper, className)}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
    >
      {isDragging && (
        <div className={styles.draggingWrapper}>
          <div className={styles.innerWrapper}>
            <Stack
              className={styles.dropFilesWrapper}
              direction="column"
              gap="l"
              alignItems="center"
            >
              <UploadIcon
                withHoverEffect
                fill={IconsFill.currentColor}
                size={'40px'}
              />
              <span className={styles.dropFilesLabel}>
                <Content id={ContentId.DropYourFileAnywhereToUpload} />
              </span>
            </Stack>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
