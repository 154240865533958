import { isEqual } from 'lodash-es';
import { FilterToolbarItem } from 'src/components/FilterToolbar';
import { PosEnumMultiSelect } from 'src/core/POS/PosMultiSelect/PosEnumMultiSelect';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { EVENT_STATUS_TO_CID } from 'src/utils/constants/contentIdMaps';
import { Feature, ListingQuery } from 'src/WebApiController';

export type EventStateFilter = Pick<ListingQuery, 'eventStatuses'>;

interface UseEventStateFiltersProps<TFilter extends EventStateFilter> {
  query: TFilter;
  setQuery: (query: TFilter) => void;
}

export const useEventStateFilters = <TFilter extends EventStateFilter>({
  query,
  setQuery,
}: UseEventStateFiltersProps<TFilter>): FilterToolbarItem[] => {
  const hasEventStateFilterFeature = useUserHasFeature(
    Feature.EventStateFilter
  );

  if (!hasEventStateFilterFeature) {
    return [];
  }

  return [
    {
      filterId: 'eventStatuses',
      labelContentId: ContentId.EventState,
      filterQueryKeys: ['eventStatuses'],
      filterItem: (
        <PosEnumMultiSelect
          triggerProps={{ style: { width: '100%' } }}
          values={query.eventStatuses || []}
          placeholderText={ContentId.All}
          enableEmptySelection
          onChange={(eventStatuses) => {
            const isChanged = !isEqual(eventStatuses, query.eventStatuses);

            setQuery({
              ...query,
              eventStatuses: isChanged ? eventStatuses : [],
            });
          }}
          valueOptionsContent={EVENT_STATUS_TO_CID}
        />
      ),
    },
  ];
};
