import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { FailureMessage, WarningMessage } from 'src/core/POS/MessageWithIcon';
import { Stack } from 'src/core/ui';
import { TabPanel } from 'src/core/ui/TabPanel';
import { TabItem } from 'src/core/ui/TabPanel/TabPanel.types';
import { ContentId } from 'src/utils/constants/contentId';
import { ACTION_OUTBOX_ENTITY_TYPE_TO_CID } from 'src/utils/constants/contentIdMaps';
import {
  ActionOutboxEntityType,
  BulkEditPreviewWithDetails,
} from 'src/WebApiController';

import { ActionOutboxEntityTypeForBulkEdit } from '../../bulkEditUtils';
import * as styles from './BulkEditPreviewDisplay.css';
import { BulkEditPreviewListingDetails } from './BulkEditPreviewListingDetails';
import { BulkEditPreviewPurchaseDetails } from './BulkEditPreviewPurchaseDetails';
import { BulkEditPreviewSaleDetails } from './BulkEditPreviewSaleDetails';
import { BulkEditPreviewTicketGroupDetails } from './BulkEditPreviewTicketGroupDetails';

const NUM_OF_ITEMS_TO_FLAG_AS_TOO_LARGE = 12000;

export const BulkEditPreviewDisplay = ({
  entityType,
  preview,
  entityUpdatesPreview,
}: {
  entityType: ActionOutboxEntityTypeForBulkEdit;
  preview?: BulkEditPreviewWithDetails | null;
  entityUpdatesPreview?: React.ReactNode;
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const isTooMany =
    (preview?.allItemsCount ?? 0) > NUM_OF_ITEMS_TO_FLAG_AS_TOO_LARGE;

  const tabs: TabItem[] = useMemo(
    () =>
      preview?.preview.bulkEditCountPreview
        ? Object.entries(preview.preview.bulkEditCountPreview)
            .filter((p) => p[1].totalCount > 0)
            .sort((a, b) =>
              a[0] === entityType
                ? -1
                : b[0] === entityType
                ? 1
                : a[0].localeCompare(b[0])
            )
            .map(([tab, value]) => ({
              value: tab,
              title: (
                <Stack
                  key={tab}
                  gap="l"
                  alignItems="center"
                  justifyContent="spaceBetween"
                >
                  <span className={styles.entityLabel}>
                    <Content
                      id={
                        ACTION_OUTBOX_ENTITY_TYPE_TO_CID[
                          tab as ActionOutboxEntityType
                        ]
                      }
                    />
                    :
                  </span>

                  <Stack gap="s" alignItems="center">
                    <span className={styles.entityCount}>
                      {value.totalCount}
                    </span>
                  </Stack>
                </Stack>
              ),
              content: (
                <>
                  {tab === ActionOutboxEntityType.Listing &&
                    preview.listings && (
                      <BulkEditPreviewListingDetails preview={preview} />
                    )}
                  {tab === ActionOutboxEntityType.Sale && preview.sales && (
                    <BulkEditPreviewSaleDetails preview={preview} />
                  )}
                  {tab === ActionOutboxEntityType.Purchase &&
                    preview.purchases && (
                      <BulkEditPreviewPurchaseDetails preview={preview} />
                    )}
                  {tab === ActionOutboxEntityType.TicketGroup &&
                    preview.ticketGroups && (
                      <BulkEditPreviewTicketGroupDetails preview={preview} />
                    )}
                </>
              ),
            }))
        : [],
    [entityType, preview]
  );

  return (
    <Stack
      direction="column"
      className={clsx(styles.root, {
        [styles.extendedHeight]: !!entityUpdatesPreview,
      })}
      gap="l"
    >
      {preview?.error && <FailureMessage message={preview.error} />}
      {entityUpdatesPreview}
      {preview?.allItemsCount ? (
        <Stack className={styles.entityLabel} gap="l" alignItems="center">
          <span
            className={
              isTooMany ? styles.entityCountLargeAlarm : styles.entityCountLarge
            }
          >
            {preview?.allItemsCount}
          </span>
          <span>
            <Content id={ContentId.ItemsWillBeUpdatedIfApplicable} />
          </span>
        </Stack>
      ) : (
        <WarningMessage
          justifyContent="center"
          width="full"
          message={<Content id={ContentId.NoItemsWereEligibleForUpdate} />}
        />
      )}

      {tabs.length > 0 && (
        <TabPanel
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          onValueChange={(v) =>
            setActiveTabIndex(tabs.findIndex((t) => t.value === v))
          }
        />
      )}
    </Stack>
  );
};
