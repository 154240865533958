import { HubConnectionState } from '@microsoft/signalr';
import { ComponentProps, useCallback, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useBulkEditHubContext } from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { BulkEditFooter } from 'src/dialogs/BulkEdits/common/BulkEditFooter';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { BulkEditListingGroupSettingsFields } from 'src/modals/GroupListings/components/groupingTypes';
import { ContentId } from 'src/utils/constants/contentId';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  Event,
} from 'src/WebApiController';

import { BulkEditHeader } from '../common/BulkEditHeader';
import { BulkEditListingGroupSettingsDialogBody } from './BulkEditListingGroupSettingsDialogBody';

export type BulkEditListingGroupSettingsDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  isLoading?: boolean;
  event: Event;
  currency: string;
  onOkay: (
    input: BulkEditListingGroupSettingsFields | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel?: () => void;
};

const getDefaultFormValues = () => {
  return {
    marketplaceSettings: { listingMarketplaceSettings: {} },
    desiredActiveListings: null,
    deprioritizedQuantities: null,
    rankPremiums: {
      undercutAbsoluteAmount: null,
      undercutRelativeAmount: null,
    },
    pricingSettingsInputs: {},
  };
};

export const BulkEditListingGroupSettingsDialog = ({
  event,
  currency,
  ...rest
}: BulkEditListingGroupSettingsDialogProps) => {
  const methods = useForm<BulkEditListingGroupSettingsFields>({
    defaultValues: getDefaultFormValues(),
  });

  return (
    <FormProvider {...methods}>
      <BulkEditListingGroupSettingsDialogContent
        event={event}
        currency={currency}
        {...rest}
        {...methods}
      />
    </FormProvider>
  );
};

export const BulkEditListingGroupSettingsDialogContent = ({
  event,
  currency,
  updateKey,
  isLoading,
  onOkay,
  onCancel,
  handleSubmit,
  getValues,
  clearErrors,
  setError,
  setValue,
  watch,
  register,
  reset,
  formState,
  ...rest
}: BulkEditListingGroupSettingsDialogProps &
  Omit<
    ComponentProps<
      typeof FormProvider<BulkEditListingGroupSettingsFields, unknown>
    >,
    'children'
  >) => {
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();

  const onClose = useCallback(
    async (newProgress?: BulkEditProgress) => {
      if (newProgress?.step === BulkEditStep.Done) {
        await refreshExpandedListItems();
        setSelectionMode(undefined);
        setProgress(undefined);
        setPreview(undefined);
      }
      onCancel?.();
    },
    [
      onCancel,
      refreshExpandedListItems,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, stage, preview, initJob } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.EditListingGroupSetting,
    updateKey,
    onBulkEditDone
  );

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const onSubmitHandler = useCallback(
    (onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void) => {
      if (onPreviewReceived) {
        onOkay(null, false, onPreviewReceived);
      } else {
        handleSubmit((i) =>
          onOkay(i, hasBackgroundBulkEditFeature, undefined, preview)
        )();
      }
    },
    [onOkay, handleSubmit, hasBackgroundBulkEditFeature, preview]
  );

  return (
    <>
      <GenericDialog
        {...rest}
        size="xl"
        header={
          <BulkEditHeader
            headerText={<Content id={ContentId.BulkEditListingGroupSettings} />}
          />
        }
        onOpened={() => {
          reset(getDefaultFormValues());
          initJob();
        }}
        onClosed={() => {
          setMainDialogOpened(false);
          if (progress) {
            setSelectionMode(undefined);
          }

          rest.onClosed?.();
        }}
        footer={
          <BulkEditFooter
            entityType={ActionOutboxEntityType.Listing}
            isLoading={isLoading}
            hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
            onClose={onClose}
            onSubmit={onSubmitHandler}
            submittButtonRef={submitButtonRef}
          />
        }
        onCancel={isLoading ? undefined : onCancel}
      >
        <BulkEditStatus
          entityType={ActionOutboxEntityType.Listing}
          isLoading={isLoading}
          updateKey={updateKey}
        >
          <BulkEditListingGroupSettingsDialogBody
            event={event}
            currency={currency}
            watch={watch}
            register={register}
            setValue={setValue}
          />
        </BulkEditStatus>
      </GenericDialog>
    </>
  );
};
