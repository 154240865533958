import {
  SeatingInfo,
  SeatingInfoProps,
} from 'src/components/common/SeatingInfo';
import { Content, useContentContext } from 'src/contexts/ContentContext';
import { HorizontalTextList } from 'src/core/POS/HorizontalTextList';
import { CountBadge, Stack, StackProps } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { getListingGroupItemCount } from 'src/utils/inventoryUtils';
import { getListingNoteIcon } from 'src/utils/listingNotesUtils';
import {
  Feature,
  GroupType,
  Listing,
  ListingGroup,
} from 'src/WebApiController';

import * as styles from './ListingDisplay.css';

export const ListingDisplay = ({
  listing,
  hideListingNotes,
  showOrgSeat,
  width,
  justifySeatingIconContent,
}: {
  listing: Listing;
  hideListingNotes?: boolean;
  width?: 'fit' | 'full';
  justifySeatingIconContent?: StackProps['justifyContent'];
} & Pick<SeatingInfoProps, 'showOrgSeat'>) => {
  const listingGroup = listing as ListingGroup;
  const contentContext = useContentContext();
  const hasListingNotesAdditionalInfoFeature = useUserHasFeature(
    Feature.ListingNotesAdditionalInfo
  );
  const hasUnmappedSeatingWarningFeature = useUserHasFeature(
    Feature.ListingTableUnmappedSeatingWarning
  );

  const { seating, seatTraits } = listing;

  return listingGroup.isLtGrp ? (
    <Stack direction="row" gap="m" alignItems="center" width={width}>
      {listingGroup.groupType === GroupType.Active ? (
        <Content id={ContentId.Active} />
      ) : listingGroup.groupType === GroupType.Inactive ? (
        <Content id={ContentId.Queued} />
      ) : listingGroup.groupType === GroupType.FullySold ? (
        <Content id={ContentId.Sold} />
      ) : listingGroup.groupType === GroupType.Invisible ? (
        <Content id={ContentId.HiddenDueToFilter} />
      ) : (
        <b>{listingGroup.groupName}</b>
      )}
      {listingGroup.groupType !== GroupType.Leader && (
        <CountBadge
          count={getListingGroupItemCount(listingGroup)}
          color="gray"
        />
      )}
    </Stack>
  ) : (
    <Stack direction="column" gap="s" width={width}>
      <SeatingInfo
        {...seating}
        orgSeatFr={listing.orgSeatFr}
        orgSeatTo={listing.orgSeatTo}
        showOrgSeat={showOrgSeat}
        isSeatSaver={listing.isSeatSaver}
        isDuplicate={!!listing.dupListingId}
        isSoldOut={listing.availQty === 0}
        justifyIconContent={justifySeatingIconContent}
        showUnmappedSection={hasUnmappedSeatingWarningFeature}
      />
      {!hideListingNotes && seatTraits?.length ? (
        <HorizontalTextList
          className={styles.listingNotes}
          preIcon={getListingNoteIcon(
            seatTraits[0],
            contentContext,
            hasListingNotesAdditionalInfoFeature
          )}
          texts={seatTraits.map((n) => n.listingNoteText)}
          maxTextsToShowOnHover={seatTraits.length}
        />
      ) : null}
    </Stack>
  );
};
