import { MouseEvent, useContext } from 'react';
import { BackSection } from 'src/components/common/BackSection';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { Button } from 'src/core/ui';
import { AdGroupCreateModal } from 'src/modals/AdGroupCreate/AdGroupCreate';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './AdGroupsToolbar.css';
import {
  AdGroupsToolbarDiv,
  FilterBarLeftSide,
  FilterBarRightSide,
} from './AdGroupsToolbar.styled';

export type AdGroupsToolbarType = {
  disabled?: boolean;
  onColumnSettingButtonClickHandler?: (
    e: MouseEvent<HTMLButtonElement>
  ) => void;
};

export function AdGroupsToolbar({
  returnUrl,
}: {
  returnUrl: string | undefined;
}) {
  const { setModal } = useContext(ModalContext);

  return (
    <AdGroupsToolbarDiv className={styles.adGroupsFilterBar}>
      <FilterBarLeftSide>
        {returnUrl && <BackSection returnUrl={returnUrl!} />}
      </FilterBarLeftSide>
      <FilterBarRightSide>
        <Button
          className="add-adGroup"
          variant="regular"
          onClick={() => setModal(AdGroupCreateModal)}
        >
          <Content id={ContentId.AddAdGroup} />
        </Button>
      </FilterBarRightSide>
    </AdGroupsToolbarDiv>
  );
}
