import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const SelectedFileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${() => `${vars.spacing.m}`};
  align-items: center;
`;

export const FileName = styled.div`
  ${(props) => props.theme.typography.getStyles('subtitle2')}
`;

export const IconWrapper = styled.div`
  padding: ${() =>
    `0 ${vars.spacing.sm} ${vars.spacing.xs} ${vars.spacing.sm}`};
`;
