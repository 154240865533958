import { useMemo } from 'react';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { ContentPlaceholder } from 'src/core/POS/ContentPlaceholder';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import {
  ArtistIcon,
  LocationOutlineIcon,
  MyTicketsOutlineIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getPerformerAndVenueForEvent } from 'src/utils/eventWithDataUtils';
import { CatalogResults, Event, Performer, Venue } from 'src/WebApiController';

import * as styles from './EventSearchDropdownResult.css';
import { EventSearchDropdownResultItemHeader } from './EventSearchDropdownResult.styled';
import { EventSearchResultItem } from './EventSearchResultItem';
import { PerformerSearchResultItem } from './PerformerSearchResultItem';
import { VenueConfigSearchResultItem } from './VenueConfigSearchResultItem';
import { VenueSearchResultItem } from './VenueSearchResultItem';

export const EventSearchDropdownResult = ({
  searchText,
  isSearching,
  catalogResults,
  maxNumOfResults,
  onSearchItemSelected,
}: {
  searchText: string;
  isSearching: boolean;
  catalogResults?: CatalogResults | null;
  maxNumOfResults?: number;
  onSearchItemSelected: (
    searchText: string | null,
    event?: Event | null,
    performer?: Performer | null,
    venue?: Venue | null,
    events?: Event[]
  ) => void;
}) => {
  const { data } = useCatalogDataContext();

  maxNumOfResults = maxNumOfResults ?? 5;

  const events = useMemo(
    () =>
      Object.values(
        catalogResults?.events || (isSearching ? [null, null, null] : [])
      )
        .sort(
          (e1, e2) =>
            // Reverse sort so the mapped are top
            (e2?.event.viagId ?? 0) - (e1?.event.viagId ?? 0)
        )
        .map((e, i) => {
          const { performer, venue } = getPerformerAndVenueForEvent(
            e?.event,
            catalogResults
          );
          return (
            <PosDropdownItem
              key={`event-${e?.event.viagVirtualId}-${i}`}
              title={e?.event?.name}
              disabled={!e?.event?.name}
              onClick={() =>
                e?.event &&
                onSearchItemSelected(null, e.event, performer, venue)
              }
            >
              <div className={styles.eventSearchDropdownResultItem}>
                {e?.event ? (
                  <EventSearchResultItem event={e.event} venue={venue} />
                ) : (
                  <ContentPlaceholder height="24px" width="100%" />
                )}
              </div>
            </PosDropdownItem>
          );
        }),
    [catalogResults, isSearching, onSearchItemSelected]
  );

  const performers = useMemo(
    () =>
      Object.values(
        catalogResults?.performers || (isSearching ? [null, null, null] : [])
      ).map((p, i) => (
        <PosDropdownItem
          key={`performer-${p?.viagId}-${i}`}
          title={p?.name}
          onClick={() => p && onSearchItemSelected(null, null, p, null)}
          disabled={!p?.name}
        >
          <div className={styles.eventSearchDropdownResultItem}>
            {p ? (
              <PerformerSearchResultItem performer={p} />
            ) : (
              <ContentPlaceholder height="24px" width="100%" />
            )}
          </div>
        </PosDropdownItem>
      )),
    [catalogResults?.performers, isSearching, onSearchItemSelected]
  );

  const venues = useMemo(
    () =>
      Object.values(
        catalogResults?.venues || (isSearching ? [null, null, null] : [])
      )
        .filter((v) => !v?.excludeSearch)
        .map((v, i) => (
          <PosDropdownItem
            key={`venue-${v?.viagId}-${i}`}
            title={v?.name}
            onClick={() => v && onSearchItemSelected(null, null, null, v)}
            disabled={!v?.name}
          >
            <div className={styles.eventSearchDropdownResultItem}>
              {v ? (
                <VenueSearchResultItem venue={v} />
              ) : (
                <ContentPlaceholder height="24px" width="100%" />
              )}
            </div>
          </PosDropdownItem>
        )),
    [catalogResults?.venues, isSearching, onSearchItemSelected]
  );

  const venueConfigs = useMemo(
    () =>
      Object.values(catalogResults?.venueCfgs ?? []).map((v, i) => {
        const { venue, performer } = getPerformerAndVenueForEvent(v[0], data);
        return (
          <PosDropdownItem
            key={`venueConfig-${v[0].venueCfgId}-${i}`}
            title={`${v[0].name} - ${v[0].venueId}`}
            onClick={() => v && onSearchItemSelected(null, null, null, null, v)}
          >
            <div className={styles.eventSearchDropdownResultItem}>
              {v ? (
                <VenueConfigSearchResultItem
                  events={v}
                  venue={venue}
                  performer={performer}
                />
              ) : (
                <ContentPlaceholder height="24px" width="100%" />
              )}
            </div>
          </PosDropdownItem>
        );
      }),
    [catalogResults?.venueCfgs, data, onSearchItemSelected]
  );

  const resultItemList = useMemo(() => {
    const results = [];
    if (venueConfigs.length) {
      results.push(
        <PosDropdownItem key="venue-config-header">
          <EventSearchDropdownResultItemHeader>
            <LocationOutlineIcon />
            <Content id={ContentId.PerformerAtVenue} />
          </EventSearchDropdownResultItemHeader>
        </PosDropdownItem>
      );
      results.push(...venueConfigs.slice(0, maxNumOfResults));
    }

    if (events.length) {
      results.push(
        <PosDropdownItem key="event-header">
          <EventSearchDropdownResultItemHeader>
            <MyTicketsOutlineIcon />
            <Content id={ContentId.Events} />
          </EventSearchDropdownResultItemHeader>
        </PosDropdownItem>
      );
      results.push(...events.slice(0, maxNumOfResults));
    }
    if (performers.length) {
      results.push(
        <PosDropdownItem key="performer-header">
          <EventSearchDropdownResultItemHeader>
            <ArtistIcon />
            <Content id={ContentId.Performers} />
          </EventSearchDropdownResultItemHeader>
        </PosDropdownItem>
      );
      results.push(...performers.slice(0, maxNumOfResults));
    }
    if (venues.length) {
      results.push(
        <PosDropdownItem key="venue-header">
          <EventSearchDropdownResultItemHeader>
            <LocationOutlineIcon />
            <Content id={ContentId.Venues} />
          </EventSearchDropdownResultItemHeader>
        </PosDropdownItem>
      );
      results.push(...venues.slice(0, maxNumOfResults));
    }

    results.push(<EventSearchDropdownResultItemHeader key="divider-header" />);
    results.push(
      <PosDropdownItem
        key="sell-all-results-header"
        title={`See all results for ${searchText}`}
        onClick={() => onSearchItemSelected(searchText, null, null, null)}
        disabled={!searchText}
      >
        <div className={styles.eventSearchDropdownResultItem}>
          <Content id={ContentId.SeeAllResultsFor} /> <b>{searchText}</b>
        </div>
      </PosDropdownItem>
    );

    return results;
  }, [
    events,
    maxNumOfResults,
    onSearchItemSelected,
    performers,
    searchText,
    venueConfigs,
    venues,
  ]);

  return <>{resultItemList}</>;
};
