import { useState } from 'react';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { usePurchaseTileBulkActionsState } from 'src/components/Purchases/BulkActions/PurchasesTileBulkActions/usePurchaseTileBulkActionsState';
import { useBulkConvertPurchasePaymentsAction } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkConvertPurchasePaymentsAction';
import { useBulkMarkPurchaseAsPaid } from 'src/components/Purchases/PurchaseActionDropdown/components/BulkMarkPurchaseAsPaid';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { BulkEditAccountingActionPermissionsHandler } from '../../PurchasesGlobalBulkActions/BulkEditAccountingActionPermissionsHandler';
import { PurchasesBulkActionProps } from '../../PurchasesGlobalBulkActions/PurchasesBulkActions.types';

type AccountingActionsProps = {
  groupId: string;
};

export const AccountingActions = ({
  groupId,
  isEventView,
}: AccountingActionsProps & PurchasesBulkActionProps) => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const [isLoading, setIsLoading] = useState(false);

  const { filterQueryWithEventIds } = usePurchaseTileBulkActionsState(
    groupId,
    isEventView
  );

  const bulkConvertPayments = useBulkConvertPurchasePaymentsAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    groupId
  );

  const bulkMarkPurchaseAsPaidAction = useBulkMarkPurchaseAsPaid(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  return (
    <BulkEditAccountingActionPermissionsHandler>
      <PosDropdown
        key="purchases-accounting-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Accounting} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="start"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {!isEventView && bulkConvertPayments.dropDown}
        {bulkMarkPurchaseAsPaidAction.dropDown}
      </PosDropdown>
    </BulkEditAccountingActionPermissionsHandler>
  );
};
