import { RecipeVariants } from '@vanilla-extract/recipes';
import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef, PropsWithChildren } from 'react';
import { useColumnResizingContext } from 'src/contexts/ColumnResizingContext/ColumnResizingContext';
import { mergeProps } from 'src/core/utils';

import { DivWithTitle } from '../DivWithTitle';
import * as styles from './TableCellDiv.css';

export type TableCellDivProps = ComponentPropsWithoutRef<'div'> &
  PropsWithChildren<RecipeVariants<typeof styles.tableCellDivRecipe>> & {
    showTooltip?: boolean;
    wrap?: 'wrap' | 'nowrap' | undefined;
  };

export const TableCellDiv = forwardRef<HTMLDivElement, TableCellDivProps>(
  function TableCellDiv(
    {
      align = 'left',
      overflowWrap,
      children,
      showTooltip,
      title,
      wrap,
      ...props
    },
    ref
  ) {
    const { isResizeEnabled } = useColumnResizingContext();
    return (
      <DivWithTitle
        ref={ref}
        align={align}
        showTooltip={showTooltip}
        title={title}
        {...mergeProps(
          {
            className: clsx(
              styles.tableCellDivRecipe({
                align,
                overflowWrap,
                textWrap: wrap ? wrap : isResizeEnabled ? 'nowrap' : 'wrap',
              }),
              styles.tableCellDiv
            ),
          },
          props
        )}
      >
        {children}
      </DivWithTitle>
    );
  }
);
