import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  UploadETickets,
  UploadETicketsModalProps,
} from 'src/modals/UploadETickets';
import { UploadETicketsV2 } from 'src/modals/UploadETickets/UploadETicketsV2';
import { Feature } from 'src/WebApiController';

type UploadETicketsVersionSelectorProps = UploadETicketsModalProps;

export const UploadETicketsSelector = (
  props: UploadETicketsVersionSelectorProps
) => {
  const hasUploadTicketFilesFlowV2 = useUserHasFeature(
    Feature.UploadTicketFilesFlowV2
  );

  if (hasUploadTicketFilesFlowV2) {
    return <UploadETicketsV2 {...props} />;
  }

  return <UploadETickets {...props} />;
};
