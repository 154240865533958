import { useCallback, useMemo } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { LISTING_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/ListingTable/configs/ListingReportTableColumnsConfig';
import { getListingTableColumnConfigs } from 'src/tables/ListingTable/configs/ListingTableColumnsConfig';
import { LISTING_TABLE_FLATTENED_COLUMNS_CONFIG } from 'src/tables/ListingTable/configs/ListingTableFlattenedColumnsConfig';
import { PURCHASE_TABLE_COLUMNS_CONFIG } from 'src/tables/PurchaseTable/configs/PurchaseTableColumnsConfig';
import { SALE_REPORT_TABLE_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleReportTableColumnsConfig';
import { SALE_TABLE_FLATTENED_COLUMNS_CONFIG } from 'src/tables/SalesTable/configs/SaleTableFlattenedColumnsConfig';
import {
  getListingColumnConfigById,
  getListingReportColumnConfigById,
  getPurchasesColumnConfigById,
  getSaleColumnConfigById,
  getSaleReportColumnConfigById,
} from 'src/utils/columns/columnUtils';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { CustomPurchasesColumn } from 'src/utils/columns/purchases/purchasesCustomColumnUtils.types';
import { CustomSalesColumn } from 'src/utils/columns/sales/salesCustomColumnUtils.types';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionType } from 'src/utils/types/sectionType';
import {
  ActionOutboxEntityType,
  Feature,
  UserSetting,
} from 'src/WebApiController';

import { SALE_TABLE_COLUMNS_CONFIG } from '../../tables/SalesTable/configs/SaleTableColumnsConfig';
import { FunctionOptionPill } from '../EditTableColumns/FormatOptionPill';
import { ColumnsSection } from './ColumnsSection';
import * as styles from './TableColumns.css';

type TableColumnsDisplayProps = {
  items: string[];
  sectionType?: SectionType;
  tableType: 'inventory' | 'sales';
};

export const TableColumnsDisplayV2 = <T,>({
  items,
  sectionType = SectionType.Listings,
  tableType,
}: TableColumnsDisplayProps) => {
  const hasSortListingsInGroupFeature = useUserHasFeature(
    Feature.SortListingsInGroup
  );
  const { value: customColumns = [] } = useServerUserSetting<T[]>({
    id: UserSetting.InventoryCustomColumns,
  });

  const { tagsMetadata } = useTagsForEntityType(
    tableType === 'inventory'
      ? ActionOutboxEntityType.Listing
      : ActionOutboxEntityType.Sale,
    true
  );
  const { tagsMetadata: tagsMetadataEvent } = useTagsForEntityType(
    ActionOutboxEntityType.SellerEvent,
    true
  );

  const { tagsMetadata: tagsMetadataPurchase } = useTagsForEntityType(
    ActionOutboxEntityType.Purchase,
    true
  );

  const allColumnConfigs = useMemo(
    () => getListingTableColumnConfigs(hasSortListingsInGroupFeature),
    [hasSortListingsInGroupFeature]
  );

  const itemsFiltered = useMemo(() => {
    // Filter out reporting tags that were later turned off reporting
    const presetColumnIds = Object.values(
      sectionType === SectionType.Listings
        ? allColumnConfigs
        : sectionType === SectionType.ListingsFlattened
        ? {
            ...LISTING_TABLE_FLATTENED_COLUMNS_CONFIG,
            ...allColumnConfigs,
          }
        : sectionType === SectionType.ListingsReport
        ? LISTING_REPORT_TABLE_COLUMNS_CONFIG
        : sectionType === SectionType.Sales
        ? SALE_TABLE_COLUMNS_CONFIG
        : sectionType === SectionType.PurchaseEvent ||
          sectionType === SectionType.Purchases
        ? PURCHASE_TABLE_COLUMNS_CONFIG
        : sectionType === SectionType.SalesFlattened
        ? {
            ...SALE_TABLE_FLATTENED_COLUMNS_CONFIG,
            ...SALE_TABLE_COLUMNS_CONFIG,
          }
        : SALE_REPORT_TABLE_COLUMNS_CONFIG
    ).map((i) => i.id);
    const activeTagNames = tagsMetadata?.map((t) => t.key) ?? [];
    const activeEventTagNames = tagsMetadataEvent?.map((t) => t.key) ?? [];
    const activePurchaseTagNames =
      tagsMetadataPurchase?.map((t) => t.key) ?? [];
    const customColumnIds = customColumns.map(
      (c) => (c as T & { id: string }).id
    );

    return items.filter(
      (m) =>
        presetColumnIds.includes(m) ||
        activeTagNames.includes(m) ||
        activeEventTagNames.includes(m) ||
        activePurchaseTagNames.includes(m) ||
        customColumnIds.includes(m)
    );
  }, [
    allColumnConfigs,
    customColumns,
    items,
    sectionType,
    tagsMetadata,
    tagsMetadataEvent,
    tagsMetadataPurchase,
  ]);

  const isTagColumn = useCallback(
    (tagName: string) =>
      [
        ...(tagsMetadata ?? []),
        ...(tagsMetadataEvent ?? []),
        ...(tagsMetadataPurchase ?? []),
      ].some((t) => t.key === tagName),
    [tagsMetadata, tagsMetadataEvent, tagsMetadataPurchase]
  );

  const renderColumnRowActions = useCallback(
    (id: string) => {
      const { isNumeric, isCurrency, isPercent, isDateTime } =
        sectionType === SectionType.Listings
          ? getListingColumnConfigById(
              id,
              customColumns as CustomListingColumn[]
            ).personalization
          : sectionType === SectionType.ListingsReport
          ? getListingReportColumnConfigById(
              id,
              customColumns as CustomListingColumn[]
            ).personalization
          : sectionType === SectionType.Sales
          ? getSaleColumnConfigById(id, customColumns as CustomSalesColumn[])
              .personalization
          : sectionType === SectionType.PurchaseEvent ||
            sectionType === SectionType.Purchases
          ? getPurchasesColumnConfigById(
              id,
              customColumns as CustomPurchasesColumn[]
            ).personalization
          : getSaleReportColumnConfigById(
              id,
              customColumns as CustomSalesColumn[]
            ).personalization;

      const isCustom = customColumns.find(
        (c) => (c as T & { id: string }).id === id
      );

      return (
        <div
          className={styles.actions}
          onPointerDown={(e) => {
            e.stopPropagation();
          }}
        >
          {isCustom && (
            <div className={styles.badge}>
              <Content id={ContentId.Custom} />
            </div>
          )}
          {isTagColumn(id) && (
            <div className={styles.badge}>
              <Content id={ContentId.Tag} />
            </div>
          )}

          <FunctionOptionPill />
        </div>
      );
    },
    [customColumns, isTagColumn, sectionType]
  );

  return (
    <ColumnsSection
      items={itemsFiltered}
      renderActions={renderColumnRowActions}
      sectionType={sectionType}
    />
  );
};
