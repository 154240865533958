import { ChangeEvent, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { useListingAutoPricingSettings } from 'src/modals/ListingDetails/components/useListingAutoPricingSettings';
import {
  CheckIcon,
  CrossIcon,
  IconsFill,
  ProcessingIcon,
} from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import {
  ListingPricingInput,
  ListingPricingSettingsCellProps,
} from '../ListingPriceForm.types';
import * as styles from './Cell.css';

export const CircuitBreakerVelocityHoursCell: React.FC<
  ListingPricingSettingsCellProps
> = ({ listing, editable, event }) => {
  const { watch, formState, getFieldState, setValue } =
    useFormContext<ListingPricingInput>();

  const saveMsg = useContent(ContentId.Save);
  const cancelMsg = useContent(ContentId.Cancel);

  const { getSelection } = useMultiSelectionContext();
  const listingSelection = getSelection(event.viagVirtualId);

  const {
    circuitBreakerMaxDiscountVelocityTicksInHours,
    canAutoPrice,
    onCircuitBreakerChange,
  } = useListingAutoPricingSettings(listing);

  const isSubmittingPricingSettings = watch('isSubmittingPricingSettings');
  const isSubmittingPrice = watch('isSubmitting');
  const isSubmittingRowIndex = watch('isSubmittingRowIndex');
  const rowIndex = watch('rowIndex');

  const fieldIsSubmitting =
    (isSubmittingPricingSettings || isSubmittingPrice) &&
    (isSubmittingRowIndex === undefined || isSubmittingRowIndex === rowIndex);

  const isDisabled =
    listing.isDeleted ||
    !canAutoPrice ||
    listingSelection.isSingleGroupMultiSelect ||
    fieldIsSubmitting ||
    !editable;

  const submittingField = watch('submittingField');
  const isSubmittingField =
    fieldIsSubmitting &&
    submittingField === 'circuitBreakerMaxDiscountVelocityTicksInHours';

  const onUpdateVelocity = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value);
      if (isNaN(value)) {
        return;
      }
      onCircuitBreakerChange(undefined, undefined, undefined, value);
    },
    [onCircuitBreakerChange]
  );

  return (
    <TableCellDiv align="right" showTooltip={false}>
      <PosFormField
        errors={
          getFieldState(
            'circuitBreakerMaxDiscountVelocityTicksInHours',
            formState
          )?.error?.message
        }
        showErrorsInline
        className={styles.formFieldContainer}
      >
        <PosTextField
          className={styles.formTextFiled}
          value={circuitBreakerMaxDiscountVelocityTicksInHours ?? 2}
          disabled={isDisabled}
          type="number"
          min={1}
          max={24}
          onChange={onUpdateVelocity}
          postfixDisplay={
            <>
              <div>h</div>
              {!isDisabled &&
              circuitBreakerMaxDiscountVelocityTicksInHours !==
                formState.defaultValues
                  ?.circuitBreakerMaxDiscountVelocityTicksInHours ? (
                <Stack direction="row" gap="s">
                  <CheckIcon
                    title={saveMsg}
                    withHoverEffect
                    size={vars.iconSize.s}
                    fill={IconsFill.textSuccess}
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue('isSubmittingPricingSettings', true);
                      setValue('isSubmittingRowIndex', rowIndex);
                      setValue(
                        'submittingField',
                        'circuitBreakerMaxDiscountVelocityTicksInHours'
                      );
                    }}
                  />
                  <CrossIcon
                    title={cancelMsg}
                    withHoverEffect
                    size={vars.iconSize.s}
                    onClick={(e) => {
                      e.stopPropagation();
                      setValue(
                        'circuitBreakerMaxDiscountVelocityTicksInHours',
                        formState.defaultValues
                          ?.circuitBreakerMaxDiscountVelocityTicksInHours ??
                          null
                      );
                    }}
                  />
                </Stack>
              ) : isSubmittingField ? (
                <div className="operation-button">
                  <RotatingWrapper>
                    <ProcessingIcon size={vars.iconSize.s} />
                  </RotatingWrapper>
                </div>
              ) : undefined}
            </>
          }
        />
      </PosFormField>
    </TableCellDiv>
  );
};
