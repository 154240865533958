import { createContext, ReactNode, useCallback, useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { SellerAccountClient, SellerUser } from 'src/WebApiController';

type ISellerSupportCaseDataContext = {
  getSellerUserDetails: (sellerUserId: string) => Promise<SellerUser | null>;
};

export const SellerSupportCaseDataContext =
  createContext<ISellerSupportCaseDataContext>({
    getSellerUserDetails: () => Promise.resolve(null),
  });

export const SellerSupportCaseDataContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const userDetailsLookup: Record<string, SellerUser> = useMemo(() => {
    return {};
  }, []);

  const getSellerUserDetails = useCallback(
    async (sellerUserId: string) => {
      if (sellerUserId == null) {
        return null;
      }

      if (!Object.keys(userDetailsLookup).includes(sellerUserId)) {
        const userDetails = await tryInvokeApi(
          async () => {
            return await new SellerAccountClient(
              activeAccountWebClientConfig
            ).getSellerUser(sellerUserId);
          },
          (error) => {
            trackError('SellerAccountClient.getSellerUser', error);
          }
        );
        if (userDetails != null) {
          userDetailsLookup[sellerUserId] = userDetails;
        }
      }
      return userDetailsLookup[sellerUserId];
    },
    [activeAccountWebClientConfig, trackError, userDetailsLookup]
  );

  return (
    <SellerSupportCaseDataContext.Provider
      value={{
        getSellerUserDetails,
      }}
    >
      {children}
    </SellerSupportCaseDataContext.Provider>
  );
};
