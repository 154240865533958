import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function SettingsIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M5.334 4.09a9.99 9.99 0 013.541-2.048c.33-.109.694 0 .912.271A2.829 2.829 0 0012 3.377c.873 0 1.678-.395 2.213-1.064a.833.833 0 01.912-.271 10 10 0 013.541 2.048c.259.232.347.6.22.923a2.827 2.827 0 00.187 2.447A2.823 2.823 0 0021.1 8.845c.344.053.62.314.69.655.28 1.348.28 2.74 0 4.088a.833.833 0 01-.69.653 2.827 2.827 0 00-2.027 1.385 2.828 2.828 0 00-.187 2.449.833.833 0 01-.22.923 10.01 10.01 0 01-3.541 2.047.833.833 0 01-.912-.27A2.827 2.827 0 0012 19.71c-.873 0-1.678.396-2.213 1.065a.833.833 0 01-.912.27A9.99 9.99 0 015.334 19a.833.833 0 01-.22-.924 2.827 2.827 0 00-.187-2.448A2.827 2.827 0 002.9 14.245a.833.833 0 01-.69-.655 10.04 10.04 0 010-4.088.833.833 0 01.69-.655 2.827 2.827 0 002.027-1.384c.437-.756.497-1.65.187-2.45a.833.833 0 01.22-.921V4.09zm1.038 4.203A4.488 4.488 0 013.75 10.36a8.411 8.411 0 000 2.368 4.493 4.493 0 012.622 2.067 4.491 4.491 0 01.478 3.3A8.282 8.282 0 008.9 19.28a4.488 4.488 0 013.1-1.238c1.175 0 2.275.453 3.1 1.238a8.323 8.323 0 002.05-1.186 4.488 4.488 0 01.478-3.3c.576-1 1.515-1.74 2.622-2.067a8.411 8.411 0 000-2.37 4.488 4.488 0 01-2.622-2.065 4.488 4.488 0 01-.478-3.3 8.323 8.323 0 00-2.05-1.186A4.488 4.488 0 0112 5.043a4.488 4.488 0 01-3.1-1.236 8.323 8.323 0 00-2.05 1.185 4.487 4.487 0 01-.478 3.301zM12 16.043a4.5 4.5 0 110-9 4.5 4.5 0 010 9zm0-1.667a2.833 2.833 0 100-5.666 2.833 2.833 0 000 5.666z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
