import { ComponentProps, ReactNode, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppContext } from 'src/contexts/AppContext';
import { useContent, useFormattedContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { isSuccess } from 'src/utils/errorUtils';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ApiException,
  PosClientConfig,
  PosUiActionResult,
  TicketUrl,
} from 'src/WebApiController';

import {
  checkDuplicateTicketUrls,
  checkTicketUrlErrors,
  TicketUrlForm,
} from './UploadTicketUrls.utils';

export type UploadTicketUrlsFormProps = {
  entityWithTickets: Pick<
    EntityWithRealTickets,
    'viagVirtualId' | 'id' | 'ticketCnt' | 'tickets' | 'seating'
  >;
  renderContent: (onUploadClick: () => void, disabled?: boolean) => ReactNode;
  onComplete: (isCancelled: boolean) => void;
  onUpload?: (
    posClientConfig: PosClientConfig,
    entityId: number,
    ticketUrls: TicketUrl[]
  ) => Promise<PosUiActionResult>;
  defaultToBulk?: boolean;
  isReadOnly?: boolean;
};

export const UploadTicketUrlsForm = (props: UploadTicketUrlsFormProps) => {
  const methods = useForm<TicketUrlForm>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      ticketUrls: props.entityWithTickets.tickets?.map(
        ({ id, ticketUrl, row, seat }) => {
          return {
            ticketId: id,
            url: ticketUrl || '',
            section: props.entityWithTickets.seating.section,
            row: row,
            seat: seat,
          };
        }
      ),
    },
  });

  return (
    <FormProvider {...methods}>
      <UploadTicketUrlsContent {...props} {...methods} />
    </FormProvider>
  );
};

const UploadTicketUrlsContent = ({
  entityWithTickets,
  renderContent,
  onComplete,
  onUpload,
  handleSubmit,
  setError,
  watch,
  isReadOnly = false,
}: UploadTicketUrlsFormProps &
  Omit<
    ComponentProps<typeof FormProvider<TicketUrlForm, unknown>>,
    'children'
  >) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const { ticketCnt } = entityWithTickets;
  const ticketUrls = watch('ticketUrls');
  const bulkUpload = watch('isBulkUpload');

  const [readOnly, setReadOnly] = useState<boolean>(isReadOnly);
  const { showErrorDialog, genericError } = useErrorBoundaryContext();

  const [alreadyReportedUsedTicketUrls, setAlreadyReportedUsedTicketUrls] =
    useState<string[]>([]);
  const ticketUrlAlreadyUsedWarning = useContent(
    ContentId.TicketUrlAlreadyInUsed
  );

  const requiredMsg = useContent(ContentId.Required);
  const duplicateMsg = useContent(ContentId.DuplicateUrl);
  const ticketUrlsProvided =
    ticketUrls?.map((b) => b.url).filter((b) => b?.length) ?? [];
  const missingMsg = useFormattedContent(
    FormatContentId.MissingTicketUrls,
    `${ticketCnt - ticketUrlsProvided.length}`
  );
  const invalidUrlMsg = useContent(ContentId.InvalidUrl);

  const extraMsg = useFormattedContent(
    FormatContentId.ExtraTicketUrls,
    `${ticketUrlsProvided.length - ticketCnt}`
  );

  const onSubmit = useCallback(
    async ({ ticketUrls }: TicketUrlForm) => {
      if (!onUpload) {
        console.log(
          'Internal Error - no onUploadTicketUrls provided for non-readonly mode.'
        );
        return;
      }

      if (!entityWithTickets) {
        console.log('Internal Error - entity (listing or sale) is null.');
        return;
      }

      tryInvokeApi(
        async () => {
          setReadOnly(true);

          const result = await onUpload(
            activeAccountWebClientConfig,
            entityWithTickets.id,
            ticketUrls!
          );
          if (!isSuccess(result)) {
            showErrorDialog(
              'uploadTicketUrls',
              {
                message: result.message ?? genericError,
                status: result.status!,
              } as ApiException,
              {
                onDismissError: () => onComplete(true),
                trackErrorData: {
                  saleId: entityWithTickets?.id,
                  ticketUrls,
                },
              }
            );
          } else {
            onComplete(false);
          }
        },
        (error) => {
          showErrorDialog('uploadTicketUrls', error, {
            trackErrorData: {
              saleId: entityWithTickets?.id,
              ticketUrls,
            },
          });
        },
        () => setReadOnly(false)
      );
    },
    [
      onUpload,
      entityWithTickets,
      activeAccountWebClientConfig,
      showErrorDialog,
      genericError,
      onComplete,
    ]
  );

  const onUploadClick = useCallback(async () => {
    let hasErrors = checkTicketUrlErrors(
      bulkUpload,
      ticketUrls,
      ticketCnt,
      setError,
      requiredMsg,
      missingMsg as string,
      extraMsg as string,
      duplicateMsg,
      invalidUrlMsg
    );

    if (hasErrors) {
      return;
    }

    hasErrors = Boolean(
      await checkDuplicateTicketUrls(
        entityWithTickets!.id,
        ticketUrls,
        activeAccountWebClientConfig,
        alreadyReportedUsedTicketUrls,
        setAlreadyReportedUsedTicketUrls,
        (index: number) =>
          setError(`ticketUrls.${index}.url`, {
            type: 'warning',
            message: ticketUrlAlreadyUsedWarning,
          }),
        () => setReadOnly(true),
        () => setReadOnly(false),
        showErrorDialog,
        entityWithTickets!.viagVirtualId
      )
    );

    if (!hasErrors) {
      handleSubmit(onSubmit)();
    }
  }, [
    bulkUpload,
    ticketUrls,
    ticketCnt,
    setError,
    requiredMsg,
    missingMsg,
    extraMsg,
    duplicateMsg,
    invalidUrlMsg,
    entityWithTickets,
    activeAccountWebClientConfig,
    alreadyReportedUsedTicketUrls,
    showErrorDialog,
    ticketUrlAlreadyUsedWarning,
    handleSubmit,
    onSubmit,
  ]);

  const reallyReadOnly = isReadOnly || readOnly;

  return renderContent(onUploadClick, reallyReadOnly);
};
