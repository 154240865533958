import { DefaultTheme } from 'styled-components';

import { BaseTheme } from './base-theme';
import { BASE_LIGHT_PALETTE } from './shared/palette/common-palette-light';
import { STUBHUB_LIGHT } from './shared/palette/stubhub-brand-palette';

export const PurpleTheme: DefaultTheme = {
  ...BaseTheme,
  name: 'purple',
  colors: { ...BaseTheme.colors }, // exactly the same as BaseTheme colors
  palette: {
    ...BASE_LIGHT_PALETTE,
    ...STUBHUB_LIGHT,
  },
};
