import { useMemo } from 'react';
import { getContent, useContentContext } from 'src/contexts/ContentContext';
import { useTagsForEntityType } from 'src/hooks/useTagsForEntityType';
import { useServerUserSetting } from 'src/hooks/useUserSetting';
import { EventsTableColumnId } from 'src/utils/columns/events/eventsColumnUtils.types';
import {
  ListingReportTableColumnId,
  ListingTableColumnId,
  ListingTableFlattenedColumnId,
} from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { CustomListingColumn } from 'src/utils/columns/inventory/inventoryCustomColumnUtils.types';
import { PurchasesTableColumnId } from 'src/utils/columns/purchases/purchasesColumnUtils.types';
import {
  SalesReportTableColumnId,
  SalesTableColumnId,
  SalesTableFlattenedColumnId,
} from 'src/utils/columns/sales/salesColumnUtils.types';
import {
  EVENTS_TABLE_COLUMN_ID_TO_CONTENT_ID,
  LISTING_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID,
  LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID,
  LISTING_TABLE_FLATTENED_COLUMN_ID_TO_CONTENT_ID,
  PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID,
  SALE_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID,
  SALE_TABLE_COLUMN_ID_TO_CONTENT_ID,
  SALE_TABLE_FLATTENED_COLUMN_ID_TO_CONTENT_ID,
} from 'src/utils/constants/contentIdMaps';
import {
  SECTION_TYPE_TO_ACTION_OUTBOX_ENTITY_TYPE,
  SECTION_TYPE_TO_CUSTOM_COLUMN_SETTING,
  SectionType,
} from 'src/utils/types/sectionType';

export const getContentIdForColumn = (id: string, sectionType: SectionType) => {
  return {
    [SectionType.Listings]:
      LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[id as ListingTableColumnId],
    [SectionType.ListingsFlattened]:
      LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[id as ListingTableColumnId] ||
      LISTING_TABLE_FLATTENED_COLUMN_ID_TO_CONTENT_ID[
        id as ListingTableFlattenedColumnId
      ],
    [SectionType.InventorySideTable]:
      LISTING_TABLE_COLUMN_ID_TO_CONTENT_ID[id as ListingTableColumnId],
    [SectionType.Sales]:
      SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId],
    [SectionType.SalesFlattened]:
      SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId] ||
      SALE_TABLE_FLATTENED_COLUMN_ID_TO_CONTENT_ID[
        id as SalesTableFlattenedColumnId
      ],
    [SectionType.SalesSideTable]:
      SALE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesTableColumnId],
    [SectionType.Events]:
      EVENTS_TABLE_COLUMN_ID_TO_CONTENT_ID[id as EventsTableColumnId],
    [SectionType.SalesReport]:
      SALE_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID[id as SalesReportTableColumnId],
    [SectionType.Purchases]:
      PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as PurchasesTableColumnId],
    [SectionType.PurchaseEvent]:
      PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as PurchasesTableColumnId],
    [SectionType.PurchaseSideTable]:
      PURCHASE_TABLE_COLUMN_ID_TO_CONTENT_ID[id as PurchasesTableColumnId],
    [SectionType.ListingsReport]:
      LISTING_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID[
        id as ListingReportTableColumnId
      ],
  }[sectionType];
};

export const isCustomColumn = (id: string, sectionType: SectionType) => {
  return getContentIdForColumn(id, sectionType) == null;
};

export const useColumnDisplayName = ({
  columnIds,
  sectionType,
}: {
  columnIds: string[];
  sectionType: SectionType;
}) => {
  const contentContext = useContentContext();

  const { isTag } = useTagsForEntityType(
    SECTION_TYPE_TO_ACTION_OUTBOX_ENTITY_TYPE[sectionType],
    true
  );

  const { value: customListingColumns = [] } = useServerUserSetting<
    CustomListingColumn[]
  >({
    id: SECTION_TYPE_TO_CUSTOM_COLUMN_SETTING[sectionType],
  });

  const displayNamesWithContentId: string[] = useMemo(
    () =>
      columnIds.map((id) => {
        if (isTag(id)) {
          return id;
        } else if (isCustomColumn(id, sectionType)) {
          return customListingColumns.find((c) => c.id === id)?.name ?? id;
        } else {
          return getContentIdForColumn(id, sectionType);
        }
      }),
    [columnIds, customListingColumns, isTag, sectionType]
  );

  const columnIdDisplayNameMapping: Record<string, string> = useMemo(
    () =>
      columnIds.reduce((access, id) => {
        if (isTag(id)) {
          return {
            ...access,
            [id]: id,
          };
        } else if (isCustomColumn(id, sectionType)) {
          const customColumn = customListingColumns.find((c) => c.id === id);
          return {
            ...access,
            [id]: customColumn?.name ?? id,
          };
        } else {
          const contentId = getContentIdForColumn(id, sectionType);
          return {
            ...access,
            [id]: getContent(contentId, contentContext),
          };
        }
      }, {}),
    [columnIds, customListingColumns, contentContext, isTag, sectionType]
  );

  const displayNames: string[] = useMemo(() => {
    return displayNamesWithContentId.map((name) =>
      getContent(name, contentContext)
    );
  }, [contentContext, displayNamesWithContentId]);

  return { displayNames, columnIdDisplayNameMapping };
};
