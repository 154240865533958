import { Content } from 'src/contexts/ContentContext';
import { mergeProps } from 'src/core/utils';
import { ContentId } from 'src/utils/constants/contentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import { TicketType } from 'src/WebApiController';

import * as styles from '../UploadETickets.css';
import { UploadETicketsInstructions } from '../UploadETickets.styled';
import { DocumentFileUploadInfo } from '../UploadETickets.utils';
import { UploadETicketsSeatingSection } from './UploadETicketsSeatingSection';

export const UploadETicketsFinalPreviewBody = ({
  ticketType,
  entityWithTickets,
  eTicketUploadInfos,
  className,
}: {
  ticketType: TicketType.ETicket | TicketType.QRCode;
  entityWithTickets: Pick<
    EntityWithRealTickets,
    'viagVirtualId' | 'id' | 'ticketCnt' | 'tickets' | 'seating' | 'entityType'
  >;
  eTicketUploadInfos: DocumentFileUploadInfo[];
  className?: string;
}) => {
  return (
    <div
      {...mergeProps(
        { className: styles.uploadETicketsBodyContainer },
        { className: className }
      )}
    >
      <UploadETicketsInstructions>
        <Content id={ContentId.ConfirmETicketsIns} />
      </UploadETicketsInstructions>

      <div className={styles.uploadETicketsSection}>
        <UploadETicketsSeatingSection
          ticketType={ticketType}
          entityWithTickets={entityWithTickets}
          eTicketUploadInfos={eTicketUploadInfos}
          isFinalPreview
        />
      </div>
    </div>
  );
};
