import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { Button, Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';
import { downloadFileFromBlob } from 'src/utils/fileUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { PurchaseClient } from 'src/WebApiController';

export type RequestEventDetailsViewProps = {
  isRequestingEventDetails: boolean;
  setIsRequestingEventDetails: (isOpen: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
};

export function RequestEventDetailsView({
  isRequestingEventDetails,
  setIsRequestingEventDetails,
  setIsLoading,
}: RequestEventDetailsViewProps) {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const onAnswer = useCallback(
    (hasEventId: boolean) => {
      setIsLoading(true);
      tryInvokeApi(
        async () => {
          const file = await new PurchaseClient(
            activeAccountWebClientConfig
          ).downloadPurchaseFileTemplate(hasEventId);

          if (file) {
            downloadFileFromBlob(
              file.fileName ?? 'purchase-creation-template.csv',
              file.data
            );
          }
        },
        (error) => {
          showErrorDialog('PurchaseClient.downloadPurchaseFileTemplate', error);
        },
        () => {
          setIsLoading(false);
          setIsRequestingEventDetails(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      setIsRequestingEventDetails,
      setIsLoading,
      showErrorDialog,
    ]
  );

  return (
    isRequestingEventDetails && (
      <>
        <Content id={ContentId.StubhubEventIdKnown} />
        <Stack
          direction="row"
          justifyContent="spaceBetween"
          alignItems="center"
          gap="m"
        >
          <Button
            style={{ width: '100%' }}
            variant="regular"
            onClick={() => {
              onAnswer(false);
            }}
          >
            <Content id={ContentId.No} />
          </Button>
          <Button
            style={{ width: '100%' }}
            variant="regular"
            onClick={() => {
              onAnswer(true);
            }}
          >
            <Content id={ContentId.Yes} />
          </Button>
        </Stack>
      </>
    )
  );
}
