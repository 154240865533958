import { createColumnHelper } from '@tanstack/react-table';
import { useContext } from 'react';
import { ButtonWithIcon } from 'src/components/Buttons';
import {
  Content,
  getContents,
  useContentContext,
} from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { useSellerAccountContext } from 'src/contexts/SellerAccountContext';
import { useSellerRoleContext } from 'src/contexts/SellerRoleContext';
import { Stack } from 'src/core/ui';
import { BadgeWithCounts } from 'src/core/ui/Badge';
import { TableCellDiv } from 'src/core/ui/TableCellDiv';
import { OpenLinkIcon } from 'src/svgs';
import { EditIcon } from 'src/svgs/Viagogo';
import { TableShimmeringDiv } from 'src/tables/Table';
import { ContentId } from 'src/utils/constants/contentId';
import {
  PERMISSION_ACCESS_AREA_TO_CID,
  PERMISSION_TO_CID,
} from 'src/utils/constants/contentIdMaps';
import { PermissionAccessArea, SellerRoleInfo } from 'src/WebApiController';

import { getSellerRoleModalConfig } from '../SellerRoleModal';
import * as styles from '../UsersManagement.css';

const columnHelper = createColumnHelper<SellerRoleInfo | null>();
const SHIMMERING_DIV_HEIGHT_SINGLE_ROW = '30px';

export const ROLES_TABLE_COLUMNS_CONFIG = [
  columnHelper.accessor('name', {
    id: 'name',
    enableSorting: true,
    sortingFn: 'text',
    header: () => (
      <TableCellDiv align="left">
        <Content id={ContentId.Role} />
      </TableCellDiv>
    ),
    cell: ({ getValue }) => (
      <TableCellDiv align="left">{getValue()}</TableCellDiv>
    ),
  }),
  columnHelper.accessor('permissions', {
    id: 'permissions',
    header: () => (
      <TableCellDiv align="left">
        <Content id={ContentId.Permission} />
      </TableCellDiv>
    ),
    cell: function Cell({ getValue }) {
      const contentContext = useContentContext();
      const { allSellerRoles, allPermissions } = useSellerRoleContext();
      if (!allSellerRoles) {
        return <TableShimmeringDiv height={SHIMMERING_DIV_HEIGHT_SINGLE_ROW} />;
      }
      const permissions = getValue();
      const permissionsByAreas = permissions.reduce(
        (r, p) => {
          const permInfo = allPermissions?.[p];
          if (permInfo) {
            r[permInfo.area] = (r[permInfo.area] ?? 0) + 1;
          }
          return r;
        },
        {} as Record<PermissionAccessArea, number>
      );
      return (
        <TableCellDiv align="left">
          <Stack gap="m" alignItems="center">
            {Object.keys(permissionsByAreas).map((pa) => (
              <BadgeWithCounts
                key={pa}
                count={permissionsByAreas[pa as PermissionAccessArea]}
                title={getContents(
                  permissions
                    .filter((p) => PERMISSION_TO_CID[p])
                    .map((p) => PERMISSION_TO_CID[p][0]),
                  contentContext
                ).join(', ')}
              >
                {
                  <Content
                    id={
                      PERMISSION_ACCESS_AREA_TO_CID[pa as PermissionAccessArea]
                    }
                  />
                }
              </BadgeWithCounts>
            ))}
          </Stack>
        </TableCellDiv>
      );
    },
  }),
  columnHelper.accessor((data) => data?.id, {
    id: 'users',
    header: () => (
      <TableCellDiv align="right">
        <Content id={ContentId.Users} />
      </TableCellDiv>
    ),
    minSize: 50,
    maxSize: 100,
    cell: function Cell({ getValue }) {
      const { allUserInfos } = useSellerAccountContext();
      const roleId = getValue() ?? -1;
      const count = Object.values(allUserInfos ?? {}).filter((u) =>
        u.roles.includes(roleId)
      ).length;

      return <TableCellDiv align="right">{count}</TableCellDiv>;
    },
  }),
  columnHelper.accessor(
    (data) =>
      data?.isDeleted ? 'deleted' : data?.prefinedRole ? 'default' : 'custom',
    {
      id: 'status',
      enableSorting: true,
      sortingFn: 'alphanumeric',
      header: () => (
        <TableCellDiv align="left">
          <Content id={ContentId.Type} />
        </TableCellDiv>
      ),
      cell: ({ getValue }) => {
        const status = getValue();
        return (
          <TableCellDiv align="left">
            {status === 'deleted' ? (
              <div className={styles.inactiveBadge}>
                <Content id={ContentId.Deactivated} />
              </div>
            ) : (
              <div
                className={
                  status === 'default'
                    ? styles.defaultBadge
                    : styles.activeBadge
                }
              >
                <Content
                  id={
                    status === 'default' ? ContentId.System : ContentId.Custom
                  }
                />
              </div>
            )}
          </TableCellDiv>
        );
      },
    }
  ),
  columnHelper.accessor((data) => data, {
    id: 'action',
    header: () => (
      <TableCellDiv align="right">
        <Content id={ContentId.Actions} />
      </TableCellDiv>
    ),
    minSize: 50,
    maxSize: 100,
    cell: function Cell({ getValue }) {
      const { setModal } = useContext(ModalContext);
      const role = getValue();

      return (
        <TableCellDiv align="right">
          <ButtonWithIcon
            titleContentId={
              role?.prefinedRole ? ContentId.View : ContentId.Edit
            }
            iconOnlyMode
            icon={
              role?.prefinedRole ? (
                <OpenLinkIcon />
              ) : (
                <EditIcon withHoverEffect />
              )
            }
            onClick={() => setModal(getSellerRoleModalConfig(role))}
          />
        </TableCellDiv>
      );
    },
  }),
];
