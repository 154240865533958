import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function PlusIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        fill="inherit"
        d="M10.588 4v6.588H4v2.824h6.588V20h2.824v-6.588H20v-2.824h-6.588V4z"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
