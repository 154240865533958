import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { BulkEditPurchaseSettingsDialog } from 'src/dialogs/BulkEdits/BulkEditPurchaseSettingsDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditPreviewWithDetails,
  BulkEditPurchaseClient,
  PurchaseOrderDetailsInput,
  PurchaseOrderQuery,
} from 'src/WebApiController';

import { PURCHASE_BULK_UPDATE_GENERAL_SETTINGS_UPDATE_KEY } from '../../PurchaseActionDropdown.constants';
import { LaunchUpdatePurchaseSettings } from './LaunchUpdatePurchaseSettings';

export const useBulkUpdatePurchaseSettings = (
  filterQuery: PurchaseOrderQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  groupId?: string
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const updateKey = groupId
    ? `purchaseSettings-event-${groupId}`
    : PURCHASE_BULK_UPDATE_GENERAL_SETTINGS_UPDATE_KEY;

  const { showErrorDialog } = useErrorBoundaryContext();
  const { refreshData } = usePurchaseDataContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const {
    openDialog: openBulkEditPurchaseSettingsDialog,
    closeDialog: closeBulkEditPurchaseSettingsDialog,
  } = useDialog(
    DialogId.BulkEditPurchaseSettings,
    BulkEditPurchaseSettingsDialog
  );

  const onSubmit = useCallback(
    async (
      purchaseInput: PurchaseOrderDetailsInput | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const client = new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            );

            const preview =
              await client.bulkUpdateGeneralSettingsPreview(filterQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateGeneralSettingsPreview', error, {
              trackErrorData: { filterQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (purchaseInput?.buyerUserId != null) {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditPurchaseClient(
              activeAccountWebClientConfig
            ).bulkUpdateGeneralSettings(
              {
                item1: preview!.preview,
                item2: purchaseInput.buyerUserId!,
              },
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                await refreshData(true);
                await refreshExpandedListItems();
              }

              closeBulkEditPurchaseSettingsDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateGeneralSettings', error, {
              trackErrorData: { preview, purchaseInput },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkEditPurchaseSettingsDialog,
      filterQuery,
      refreshData,
      refreshExpandedListItems,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchUpdatePurchaseSettings
        key="purchaseGeneralSettingsUpdate"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkEditPurchaseSettingsDialog({
            updateKey,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkEditPurchaseSettingsDialog,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
