import { FontWeightType, TypographyVariantType } from '../../theme-types';
import { media } from './media-queries';
import { fontWeightConfig, VariantConfig } from './typography-config';

type TypographySize = {
  'font-size': string;
  'line-height': string;
  'letter-spacing': string;
};

export const size = (multiplier: number): TypographySize => ({
  'font-size': `${multiplier * 2 + 10}px`,
  'line-height': 'regular',
  'letter-spacing': 'regular',
});

export const getStyles = (
  variant: TypographyVariantType,
  weight?: FontWeightType
): string =>
  `
    font-size: ${VariantConfig[variant].sm.fontSize};
    font-weight: ${
      weight
        ? fontWeightConfig[weight]
        : VariantConfig[variant].sm.defaultfontWeight
    };
    line-height: ${VariantConfig[variant].sm.lineHeight};
    ${
      VariantConfig[variant].sm.letterSpacing !== undefined
        ? `letter-spacing: ${VariantConfig[variant].sm.letterSpacing}`
        : ''
    };

    ${media.makeMediaQuery(
      media.breakpoints.md,
      `
        font-size: ${VariantConfig[variant].lg.fontSize};
        font-weight: ${
          weight
            ? fontWeightConfig[weight]
            : VariantConfig[variant].lg.defaultfontWeight
        };
        line-height: ${VariantConfig[variant].lg.lineHeight};
        ${
          VariantConfig[variant].lg.letterSpacing !== undefined
            ? `letter-spacing: ${VariantConfig[variant].lg.letterSpacing}`
            : ''
        };
      `
    )}
  `;
