import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function LocationOutlineIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M12.007 2c4.173 0 7.557 3.368 7.557 7.525 0 3.537-2.698 7.538-6.263 11.19l-.22.223-.348.346-.185.177-.576.539-.646-.61-.232-.228-.294-.298-.317-.33a36.187 36.187 0 01-2.52-2.96c-1.33-1.755-2.353-3.48-2.947-5.126-.37-1.024-.566-2-.566-2.923C4.45 5.368 7.834 2 12.007 2zm0 1.68c-3.247 0-5.878 2.617-5.878 5.845 0 .712.159 1.5.466 2.352.528 1.463 1.47 3.052 2.706 4.682.712.94 1.477 1.834 2.242 2.651l.311.328.123.127.122-.123c3.113-3.192 5.566-6.764 5.772-9.634l.01-.195.003-.188c0-3.228-2.63-5.846-5.877-5.846zm0 2.627a3.25 3.25 0 110 6.5 3.25 3.25 0 010-6.5zm0 1.68a1.57 1.57 0 100 3.14 1.57 1.57 0 000-3.14z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
