import * as Sentry from '@sentry/react';
import { UseQueryResult } from '@tanstack/react-query';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import {
  Event,
  EventConfigScoreOverride,
  Feature,
  SellerAccountEventConfigScoreOverrides,
  TicketClassInfo,
  VenueMapInfo,
} from 'src/WebApiController';

import { ErrorTypes } from '../ErrorBoundaryContext';
import { useGetEventMapConfig } from './useGetEventMapConfig';
import { useGetEventMapConfigOverrides } from './useGetEventMapConfigOverrides';
import { useGetEventMapsByScoreModelConfig } from './useGetEventMapsByScoreModelConfig';

export type EventMapContextProps = {
  event?: Event | null;
  ticketClasses: TicketClassInfo[];
  /**
   * @deprecated Use venueMapInfo and isMapLoading instead.
   */
  venueMapQuery: UseQueryResult<VenueMapInfo | null, ErrorTypes>;
  venueMapsByScoreModelQuery: UseQueryResult<
    {
      readonly VenueGeometry?: VenueMapInfo | undefined;
      readonly MergedVenueGeometry?: VenueMapInfo | undefined;
      readonly AdvancedVenueGeometry?: VenueMapInfo | undefined;
    } | null,
    ErrorTypes
  >;
  isMapLoading: boolean;
  venueMapInfo: VenueMapInfo | null;
  mapConfigOverridesQuery: UseQueryResult<
    EventConfigScoreOverride[] | null,
    ErrorTypes
  >;
  sellerAccountScoreOverridesQuery: UseQueryResult<
    SellerAccountEventConfigScoreOverrides[] | null,
    ErrorTypes
  >;
  activeConfigOverride?: EventConfigScoreOverride | null;
  isGeneralAdmissionOnly: boolean;
  normalizeConfigOverrideWithUiScore: (
    scoreOverride: EventConfigScoreOverride
  ) => EventConfigScoreOverride;
};

export const EventMapContext = createContext<EventMapContextProps>({
  event: null,
  ticketClasses: [],
  venueMapQuery: {} as UseQueryResult<VenueMapInfo | null>,
  venueMapsByScoreModelQuery: {} as UseQueryResult<{
    readonly VenueGeometry?: VenueMapInfo | undefined;
    readonly MergedVenueGeometry?: VenueMapInfo | undefined;
  } | null>,
  isMapLoading: false,
  venueMapInfo: null,
  mapConfigOverridesQuery: {} as UseQueryResult<EventConfigScoreOverride[]>,
  sellerAccountScoreOverridesQuery: {} as UseQueryResult<
    SellerAccountEventConfigScoreOverrides[]
  >,
  activeConfigOverride: null,
  isGeneralAdmissionOnly: false,
  normalizeConfigOverrideWithUiScore: (x) => x,
});

export const useEventMapContext = () => useContext(EventMapContext);

const populateScoreForDisplay = (
  eventConfigScoreOverride: EventConfigScoreOverride | null | undefined,
  venueMapMaxScoreRaw: number,
  venueMapInfo: VenueMapInfo | null | undefined,
  normalizeScores: boolean
) => {
  if (!eventConfigScoreOverride) {
    return eventConfigScoreOverride;
  }

  const scoreOverrides = eventConfigScoreOverride.scoreOverrides.map(
    (scoreOverride) => {
      let score = scoreOverride.scoreRaw;

      // If the venue config ids do not align that means we are pulling scores
      // from a different venue config as a template, and should not normalize
      normalizeScores =
        normalizeScores &&
        eventConfigScoreOverride.cfgId == venueMapInfo?.venueCfgId;
      if (
        normalizeScores &&
        scoreOverride.scoreRaw != null &&
        venueMapMaxScoreRaw
      ) {
        // Score normalized to 0-100
        score = (scoreOverride.scoreRaw / venueMapMaxScoreRaw) * 100;
      }

      return {
        ...scoreOverride,
        score,
      };
    }
  );

  return {
    ...eventConfigScoreOverride,
    scoreOverrides,
  };
};

export const EventMapContextProvider = ({
  event,
  children,
  disabled,
  doNotGetScoreOverrides,
}: PropsWithChildren<{
  event?: Event | null;
  disabled?: boolean;
  doNotGetScoreOverrides?: boolean;
}>) => {
  const viagogoEventId = event?.viagId;

  const hasScaledSeatScoreFeature = useUserHasFeature(Feature.ScaledSeatScore);

  const venueMapQuery = useGetEventMapConfig(viagogoEventId, disabled);

  const venueMapsByScoreModelQuery = useGetEventMapsByScoreModelConfig(
    viagogoEventId,
    disabled
  );

  /**
   * VenueMapBaseInfo are all the same for different score models,
   * this is the safe guard to ensure we have a venue map present
   * in case any of the queries returned empty map due to race
   * conditions.
   */
  const venueMapInfo = useMemo((): VenueMapInfo | null => {
    if (venueMapQuery.data) {
      return venueMapQuery.data;
    }
    if (venueMapsByScoreModelQuery.data) {
      return (
        Object.values(venueMapsByScoreModelQuery.data).find(
          (mapInfo) => !!mapInfo
        ) ?? null
      );
    }

    return null;
  }, [venueMapQuery.data, venueMapsByScoreModelQuery.data]);

  const configId = venueMapInfo?.venueCfgId;

  const {
    seatScoreOverridesQuery: mapConfigOverridesQuery,
    sellerAccountScoreOverridesQuery: sellerAccountScoreOverridesQuery,
  } = useGetEventMapConfigOverrides(
    doNotGetScoreOverrides
      ? undefined // If we don't want the score overrides, just pass null into here
      : viagogoEventId,
    doNotGetScoreOverrides ? undefined : configId,
    doNotGetScoreOverrides || disabled
  );

  /* This logic was something carried from CX code
     We don't think we need this, but leaving this to know how is GA handles
  const isGeneralAdmissionOnly = Boolean(
    venueMapQuery.data &&
      venueMapQuery.data.sections.every(
        (s) =>
          s.sectionType === VenueMapSectionType.GeneralAdmission ||
          s.sectionType === VenueMapSectionType.GeneralAdmissionWithQueueNumber
      )
  );*/

  const venueMapMaxScoreRaw = useMemo(
    () =>
      venueMapQuery.data?.sectionScores.reduce(
        (acc, section) => Math.max(acc, section.scoreRaw ?? 0),
        0
      ) ?? 0,
    [venueMapQuery.data]
  );

  const mapConfigOverridesQueryWithUIScore = useMemo(() => {
    if (!mapConfigOverridesQuery.data?.length) {
      return mapConfigOverridesQuery;
    }

    const data = mapConfigOverridesQuery.data.map((configOverride) =>
      populateScoreForDisplay(
        configOverride,
        venueMapMaxScoreRaw,
        venueMapQuery.data,
        hasScaledSeatScoreFeature
      )
    );

    return {
      ...mapConfigOverridesQuery,
      data,
    } as UseQueryResult<EventConfigScoreOverride[], ErrorTypes>;
  }, [
    hasScaledSeatScoreFeature,
    mapConfigOverridesQuery,
    venueMapMaxScoreRaw,
    venueMapQuery.data,
  ]);

  const activeConfigOverride = useMemo(():
    | EventConfigScoreOverride
    | undefined => {
    if (mapConfigOverridesQueryWithUIScore.isLoading) {
      return undefined;
    }

    const activeConfig = mapConfigOverridesQueryWithUIScore.data?.find(
      (c) => c.isActive
    );

    if (!activeConfig) {
      return undefined;
    }

    const rowIdToOrdinal =
      venueMapInfo?.sections?.reduce(
        (acc, section) => {
          for (const row of section.rows) {
            acc[row.id] = row.ordinal;
          }
          return acc;
        },
        {} as Record<number, number | null>
      ) ?? {};

    const sanitizedScores = activeConfig?.scoreOverrides?.map((score) => {
      const ordinal = score.isManualOverride
        ? score.rowOrdinal
        : rowIdToOrdinal[score.rowId] ?? score.rowOrdinal;
      return {
        ...score,
        rowOrdinal: ordinal,
      };
    });

    return {
      ...activeConfig,
      scoreOverrides: sanitizedScores,
    };
  }, [
    venueMapInfo?.sections,
    mapConfigOverridesQueryWithUIScore.data,
    mapConfigOverridesQueryWithUIScore.isLoading,
  ]);

  const normalizeConfigOverrideWithUiScore = useCallback(
    (scoreOverride: EventConfigScoreOverride) => {
      const result = populateScoreForDisplay(
        scoreOverride,
        venueMapMaxScoreRaw,
        venueMapQuery.data,
        hasScaledSeatScoreFeature
      );

      return result!;
    },
    [hasScaledSeatScoreFeature, venueMapMaxScoreRaw, venueMapQuery.data]
  );

  const ticketClasses = useMemo(() => {
    try {
      if (venueMapQuery.data) {
        return Object.values(
          venueMapQuery.data.sections
            .flatMap((s) => s.rows.map((r) => r.tktClass))
            .reduce(
              (r, c) => {
                if (c?.id) {
                  r[c.id] = c;
                }
                return r;
              },
              {} as Record<number, TicketClassInfo>
            )
        );
      }
      return [];
    } catch (error) {
      // TODO - remove when this is main-stream
      console.error(error);
      try {
        Sentry.captureException(error);
      } catch (err) {
        console.warn('Sentry log request failed', err);
      }
    }

    return [];
  }, [venueMapQuery.data]);

  const isMapLoading = useMemo(
    () => venueMapQuery.isLoading || venueMapsByScoreModelQuery.isLoading,
    [venueMapQuery.isLoading, venueMapsByScoreModelQuery.isLoading]
  );

  return (
    <EventMapContext.Provider
      value={{
        event,
        ticketClasses,
        venueMapQuery,
        venueMapsByScoreModelQuery,
        isMapLoading,
        venueMapInfo,
        mapConfigOverridesQuery: mapConfigOverridesQueryWithUIScore,
        sellerAccountScoreOverridesQuery,
        isGeneralAdmissionOnly: false,
        activeConfigOverride,
        normalizeConfigOverrideWithUiScore: normalizeConfigOverrideWithUiScore,
      }}
    >
      {children}
    </EventMapContext.Provider>
  );
};
