import { IListingGroupItem, Listing, ListingGroup } from 'src/WebApiController';

export type GroupIdNameMap = Record<string, string>;
// Recursively flatten group items
export const getFlattenedListings = (
  listings?: Listing[] | null
): [Listing[], Record<string, string>] => {
  if (!listings) {
    return [[], {}];
  }
  const groupIdNameMap: GroupIdNameMap = {};

  // Recursively flatten group items
  const flattenGroupItems = (item: IListingGroupItem): Listing[] => {
    if (item.isLtGrp) {
      if (
        (item as ListingGroup).groupType === 'Leader' &&
        (item as ListingGroup).ltGrpId !== null
      ) {
        // Keep track of group id to group name mapping for display use
        groupIdNameMap[(item as ListingGroup).ltGrpId as string] = (
          item as ListingGroup
        ).groupName;
      }
      return [...(item as ListingGroup).groupItems.flatMap(flattenGroupItems)];
    }
    return [item as Listing];
  };

  const flattenedListings = listings.flatMap(flattenGroupItems);
  return [flattenedListings, groupIdNameMap];
};
