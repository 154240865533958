import { HubConnectionState } from '@microsoft/signalr';
import { useQuery } from '@tanstack/react-query';
import {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ConfirmButton } from 'src/components/Buttons';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { useInventoryEventBulkActionsState } from 'src/components/Events/EventListing/InventoryEventListing/BulkActions/InventoryEventBulkActions/useInventoryEventBulkActionsState';
import { INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY } from 'src/components/Listings/InventoryActionDropdown';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useBulkEditHubContext } from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { useEventMarketplaceSettings } from 'src/hooks/useEventMarketplaceSettings';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { isSuccess } from 'src/utils/errorUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  AutoCompAndAutoGroupMode,
  BulkActionType,
  BulkEditListingClient,
  ConfigTypeOverride,
  Event,
  Feature,
  ListingGroupClient,
  RowCompOverride,
  RowGroupSetting,
  ZoneSectionAreaGroupLookup,
} from 'src/WebApiController';

import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';
import { GroupListingAutoCompSettingsForm } from './autoCompGroup.types';
import { GroupListingAutoCompGroup } from './autoCompGroup.types';
import { GroupListingsAutoGroupListingsBody } from './GroupListingsAutoGroupListingsBody';

export const GroupListingsAutoGroupListing = ({
  event,
  mode,
  rowCompOffset,
  rowGroupSetting,
  configTypeOverride,
  combineCornersAndEnds,
  rowCompOverrides,
  sectionGroupCustomRankEnabled,
  lookup,
  cancelTo,
  isLoading,
}: {
  event: Event;
  mode: AutoCompAndAutoGroupMode;
  rowCompOffset: number;
  rowGroupSetting: RowGroupSetting;
  configTypeOverride: ConfigTypeOverride;
  combineCornersAndEnds: boolean;
  rowCompOverrides: RowCompOverride[];
  sectionGroupCustomRankEnabled: boolean;
  lookup: ZoneSectionAreaGroupLookup;
  cancelTo?: ModalProps;
  isLoading: boolean;
}) => {
  const methods = useForm<GroupListingAutoCompSettingsForm>({
    defaultValues: {
      mergeListingGroupInputs: [],
      configTypeOverride: configTypeOverride,
      combineCornersAndEnds: combineCornersAndEnds,
      zoneSectionAreaGroupLookup: lookup,
      rowCompOffset: rowCompOffset,
      rowGroupSetting: rowGroupSetting,
      rowCompOverrides: rowCompOverrides,
      sectionGroupCustomRankEnabled: sectionGroupCustomRankEnabled,
    },
  });

  return isLoading ? (
    <PosSpinner />
  ) : (
    <FormProvider {...methods}>
      <GroupListingsAutoGroupListingBody
        event={event}
        mode={mode}
        cancelTo={cancelTo}
        {...methods}
      />
    </FormProvider>
  );
};

export const GroupListingsAutoGroupListingBody = ({
  event,
  mode,
  cancelTo,
  formState,
  handleSubmit,
  watch,
  setValue,
}: {
  event: Event;
  mode: AutoCompAndAutoGroupMode;
  cancelTo?: ModalProps;
} & Omit<
  ComponentProps<
    typeof FormProvider<GroupListingAutoCompSettingsForm, unknown>
  >,
  'children'
>) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const hasBulkActionGroupListingsFeature = useUserHasFeature(
    Feature.BulkActionGroupListings
  );

  const { filterQueryWithEventIds } = useInventoryEventBulkActionsState(event);

  const eventListingIds = useMemo(
    () =>
      hasBulkActionGroupListingsFeature
        ? filterQueryWithEventIds.entityIds ?? []
        : [],
    [filterQueryWithEventIds.entityIds, hasBulkActionGroupListingsFeature]
  );

  // Values needed for the autoGroupListingsPreviewQuery
  const viagogoEventId = event?.viagId;
  const rowCompOffset = watch('rowCompOffset');
  const rowGroupSetting = watch('rowGroupSetting');
  const rowCompOverrides = watch('rowCompOverrides');
  const configTypeOverride = watch('configTypeOverride');
  const combineCornersAndEnds = watch('combineCornersAndEnds');
  const useCustomRank = watch('sectionGroupCustomRankEnabled');
  const lookup = watch('zoneSectionAreaGroupLookup');

  const shouldQuery = Boolean(
    viagogoEventId &&
      eventListingIds != null &&
      configTypeOverride &&
      combineCornersAndEnds != null &&
      rowCompOffset != null &&
      rowGroupSetting &&
      rowCompOverrides &&
      !!lookup
  );

  const autoGroupListingsPreviewQuery = useQuery({
    queryKey: [
      'ListingGroupClient.autoGroupListingsPreview',
      viagogoEventId,
      configTypeOverride,
      combineCornersAndEnds,
      rowCompOffset,
      rowGroupSetting,
      JSON.stringify(eventListingIds),
      JSON.stringify(lookup?.sectionIdToGroupIdMap),
    ],
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      const result = await new ListingGroupClient(
        activeAccountWebClientConfig
      ).autoGroupListingsPreview({
        viagogoEventId: viagogoEventId!,
        eventListingIds: eventListingIds!,
        configTypeOverride: configTypeOverride!,
        combineCornersAndEnds: combineCornersAndEnds!,
        rowCompOffset: rowCompOffset!,
        rowGroupSetting: rowGroupSetting!,
        rowCompOverrides: rowCompOverrides!,
        useCustomRank: useCustomRank,
        zoneSectionAreaGroupLookupJson: JSON.stringify(lookup!),
      });

      if (isSuccess(result)) {
        return result.data;
      } else {
        showErrorDialog(
          'ListingGroupClient.autoGroupListingsPreview',
          { message: result.message, status: result.status },
          {
            trackErrorData: {
              viagogoEventId,
              configTypeOverride,
              combineCornersAndEnds,
            },
          }
        );
      }
    },

    enabled: shouldQuery,
    refetchOnWindowFocus: false,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('ListingGroupClient.autoGroupListingsPreview', error, {
          trackErrorData: {
            viagogoEventId,
            configTypeOverride,
          },
        });
      },
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const { isSubmitting } = formState;
  const { setActivePosEntity } = useActivePosEntityContext();
  const { closeModal } = useContext(ModalContext);

  const mergeListingGroupInputs = watch('mergeListingGroupInputs');

  const { defaultListingMarketplaceSettings } =
    useEventMarketplaceSettings(event);

  useEffect(() => {
    if (autoGroupListingsPreviewQuery.data) {
      const newJson = JSON.stringify(autoGroupListingsPreviewQuery.data);
      const curJson = JSON.stringify(mergeListingGroupInputs);
      if (newJson !== curJson) {
        setValue(
          'mergeListingGroupInputs',
          Object.entries(autoGroupListingsPreviewQuery.data.listingGroups).map(
            ([name, listings]) => {
              return {
                name: name,
                items: listings,
              } as GroupListingAutoCompGroup;
            }
          )
        );
      }
    }
  }, [
    autoGroupListingsPreviewQuery.data,
    defaultListingMarketplaceSettings,
    mergeListingGroupInputs,
    setValue,
  ]);

  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const [formIsDone, setFormIsDone] = useState(false);

  const { setProgress, setMainDialogOpened } = useBulkEditHubContext();
  const { bulkEditHub, progress, errors, warnings } = useBulkEditHub(
    ActionOutboxEntityType.Listing,
    BulkActionType.GroupListings,
    INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY,
    () => {
      setActivePosEntity(0);
      setFormIsDone(true);
      return refreshExpandedListItems();
    }
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const onSubmit = useCallback(() => {
    setIsLoading(true);
    tryInvokeApi(
      async () => {
        const result = await new BulkEditListingClient(
          activeAccountWebClientConfig
        ).bulkAutoCompAndGroupListingsV2(
          {
            viagogoEventId: viagogoEventId!,
            mode: mode,
            eventListingIds: eventListingIds!,
            configTypeOverride: configTypeOverride!,
            combineCornersAndEnds: combineCornersAndEnds!,
            rowCompOffset: rowCompOffset!,
            rowGroupSetting: rowGroupSetting!,
            rowCompOverrides: rowCompOverrides!,
            useCustomRank: useCustomRank,
            zoneSectionAreaGroupLookupJson: JSON.stringify(lookup!),
            mergeListingGroupInputs: null,
          },
          INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY,
          hasBackgroundBulkEditFeature
        );

        if (!hasBackgroundBulkEditFeature) {
          if (result) {
            setActivePosEntity(0);
            await refreshExpandedListItems();

            closeModal();
          }
        }
      },
      (error) => {
        showErrorDialog(
          'BulkEditListingClient.bulkAutoCompAndGroupListingsV2',
          error,
          {
            trackErrorData: formState,
          }
        );
      },
      () => {
        setIsLoading(false);
      }
    );
  }, [
    activeAccountWebClientConfig,
    viagogoEventId,
    mode,
    eventListingIds,
    configTypeOverride,
    combineCornersAndEnds,
    rowCompOffset,
    rowGroupSetting,
    rowCompOverrides,
    useCustomRank,
    lookup,
    hasBackgroundBulkEditFeature,
    setActivePosEntity,
    refreshExpandedListItems,
    closeModal,
    showErrorDialog,
    formState,
  ]);

  return (
    <>
      <CancellableFormHeader
        disabled={isLoading || isSubmitting}
        showDialogOnCancel={true}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.AutoGroup} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        <ModalBodyHeaderContainer>
          <Summary event={event} />
        </ModalBodyHeaderContainer>
        <ModalBodyDataContainer>
          {isLoading || autoGroupListingsPreviewQuery.isPending ? (
            <PosSpinner />
          ) : (
            <BulkEditStatus
              entityType={ActionOutboxEntityType.Listing}
              updateKey={INVENTORY_BULK_CREATE_LISTING_GROUPS_KEY}
            >
              <GroupListingsAutoGroupListingsBody />
            </BulkEditStatus>
          )}
        </ModalBodyDataContainer>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          disabled={isLoading || isSubmitting}
          cancelTo={cancelTo}
          onBeforeClose={() => {
            setProgress(undefined);
            setMainDialogOpened(false);
          }}
        >
          {!formIsDone && (
            <ConfirmButton
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              disabled={isLoading || isSubmitting}
              textContentId={ContentId.Save}
            />
          )}
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
