import clsx from 'clsx';
import { isValid, parse } from 'date-fns';
import { format } from 'date-fns';
import { CalendarProps } from 'react-date-range';
import { FieldError } from 'react-hook-form';
import TimePicker from 'react-time-picker';
import { useSiteTimezoneContext } from 'src/contexts/SiteTimezoneContext/SiteTimezoneContext';
import { Popover } from 'src/core/ui';
import { CalendarOutlineIcon } from 'src/svgs/Viagogo';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';

import { getTextFieldState, PosTextField } from '../PosTextField';
import * as styles from './DatePicker.css';

const TIME_PICKER_CALLBACK_FORMAT = 'HH:mm';

export const TimePickerInput = ({
  value,
  fieldError,
  disabled,
  className,
  timeDisplayFormat = 'hh:mm a',
  locale = getLocaleFromLanguageOrCurrent(),
  onTimeChange,
  textFieldStyles,
  useSiteTimeZone = true,
  ...props
}: Omit<CalendarProps, 'onChange'> & {
  value?: string | null | Date;
  /**
   * the whole data object will be returned. Because if the timezone is applied,
   * the returned date can change
   */
  onTimeChange?: (t: Date | null) => void;
  fieldError?: string | FieldError | undefined;
  disabled?: boolean;
  timeDisplayFormat?: string;
  textFieldStyles?: React.CSSProperties;
  /**
   * If true, the site's timezone will be applied to the date being displayed.
   * the underlying date value (value, data, onDateChange) will not be changed.
   */
  useSiteTimeZone?: boolean;
}) => {
  const { toZonedTime, fromZonedTime } = useSiteTimezoneContext();

  let date = value ? new Date(value) : undefined;
  if (useSiteTimeZone) {
    if (date) {
      date = toZonedTime(date);
    }
    if (props.minDate) {
      props.minDate = toZonedTime(props.minDate);
    }
    if (props.maxDate) {
      props.maxDate = toZonedTime(props.maxDate);
    }
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild disabled={disabled}>
        <div className={styles.datePickerContainer}>
          <PosTextField
            rootProps={{
              state: getTextFieldState(fieldError),
              disabled: disabled,
            }}
            placeholder={'00:00 am'}
            prefixDisplay={<CalendarOutlineIcon withHoverEffect />}
            value={date ? format(date, timeDisplayFormat) : undefined}
            onChange={(e) => {
              let str = e.target.value;
              let newTime = parse(str, timeDisplayFormat, new Date(), {
                locale: getLocaleFromLanguageOrCurrent(),
              });
              if (isValid(newTime)) {
                if (useSiteTimeZone) {
                  newTime = fromZonedTime(newTime);
                  str = format(newTime, TIME_PICKER_CALLBACK_FORMAT);
                }
                onTimeChange?.(newTime);
              }
            }}
            disabled={disabled}
            style={textFieldStyles}
          />
        </div>
      </Popover.Trigger>
      <Popover.Content align="start">
        <TimePicker
          {...props}
          value={date}
          format={timeDisplayFormat}
          className={clsx(className, styles.datePicker, styles.timePicker)}
          locale={locale.code}
          onChange={(t) => {
            if (t) {
              let newTime = parse(t, TIME_PICKER_CALLBACK_FORMAT, new Date());

              if (isValid(newTime)) {
                if (useSiteTimeZone) {
                  newTime = fromZonedTime(newTime);
                }
                onTimeChange?.(newTime);
              }
            }
          }}
          disabled={disabled}
          clockIcon={null}
          clearIcon={null}
        />
      </Popover.Content>
    </Popover.Root>
  );
};
