import { useCallback, useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { OkButton } from 'src/components/Buttons/OkButton';
import {
  DocumentFileUploadInfo,
  ETicketsForm,
  UploadETicketForm,
  UploadETicketsSeatAssignmentBody,
} from 'src/components/UploadArtifacts/UploadETicketsV2';
import { UploadETicketsContextProvider } from 'src/components/UploadArtifacts/UploadETicketsV2/UploadETicketsContext';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useCatalogMetricsContext } from 'src/contexts/CatalogMetricsContext/CatalogMetricsContext';
import { Content } from 'src/contexts/ContentContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { DndGlobalFileDrop } from 'src/core/POS/DnDFileUploader/components/DndGlobalFileDrop';
import { DndFilerUploaderContextProvider } from 'src/core/POS/DnDFileUploader/DndFileUploaderContext';
import { useEventItemLoadingDisplay } from 'src/hooks/useEventItemLoadingDisplay';
import { CancellableFormFooter } from 'src/modals/common';
import { CancellableFormHeader } from 'src/modals/common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from 'src/modals/common/EventEntityHeader';
import { ModalBody, ModalFooter, ModalProps } from 'src/modals/Modal';
import { ContentId } from 'src/utils/constants/contentId';
import { FormatContentId } from 'src/utils/constants/formatContentId';
import { EntityWithRealTickets } from 'src/utils/ticketUtils';
import {
  DocumentProcessorClient,
  DocumentUploadInfo,
  FileParameter,
  PosClientConfig,
  PosUiActionResult,
  SaleMetrics,
  TicketType,
  UserDocument,
} from 'src/WebApiController';

import { Summary } from '../common/Summary';
import {
  ModalBodyDataContainer,
  ModalBodyHeaderContainer,
} from '../Modal/Modal.styled';

export type UploadETicketsModalProps = {
  onUploadDocuments: (
    client: DocumentProcessorClient,
    docUploadInfo: DocumentUploadInfo,
    file: FileParameter
  ) => Promise<void>;
  onUploadETickets: (
    posClientConfig: PosClientConfig,
    entityId: number,
    ticketAssignments: UserDocument[]
  ) => Promise<PosUiActionResult>;
  ticketType: TicketType.ETicket | TicketType.QRCode;
  cancelTo?: ModalProps;
  loadingContentId: FormatContentId;
  searchingContentId: FormatContentId;
  errorContentId: FormatContentId;
};

export const UploadETicketsV2 = ({
  loadingContentId,
  searchingContentId,
  errorContentId,
  ...rest
}: UploadETicketsModalProps) => {
  const { loadingState } = useEventItemLoadingDisplay<EntityWithRealTickets>(
    loadingContentId,
    searchingContentId,
    errorContentId
  );

  return loadingState || <UploadETicketsContent {...rest} />;
};

export type UploadETicketsBodyProps = Omit<
  UploadETicketsModalProps,
  'loadingContentId' | 'searchingContentId' | 'errorContentId'
>;

const UploadETicketsContent = ({
  ticketType,
  onUploadDocuments,
  onUploadETickets,
  cancelTo,
}: UploadETicketsBodyProps) => {
  const { setModal, closeModal } = useContext(ModalContext);

  const { posEntity, setActivePosEntity } =
    useActivePosEntityContext<EntityWithRealTickets>();

  const { refreshMetrics } = useCatalogMetricsContext<SaleMetrics>();

  const goBack = useCallback(
    async (isCancelled: boolean) => {
      if (!isCancelled) {
        refreshMetrics?.();

        // Refresh the active data so the SaleDetail dialog will have the new content
        await setActivePosEntity(posEntity!.id, posEntity!.idOnMkp, true);
      }

      if (cancelTo) {
        setModal(cancelTo);
      } else {
        closeModal(true);
      }
    },
    [
      cancelTo,
      refreshMetrics,
      setActivePosEntity,
      posEntity,
      setModal,
      closeModal,
    ]
  );

  return (
    <UploadETicketsContextProvider entityWithTickets={posEntity!}>
      <UploadETicketForm
        entityWithTickets={posEntity!}
        ticketType={ticketType}
        onComplete={goBack}
        onUploadDocuments={onUploadDocuments}
        onUploadETickets={onUploadETickets}
        renderContent={(
          eTicketUploadInfos: DocumentFileUploadInfo[],
          setETicketUploadInfos: (infos: DocumentFileUploadInfo[]) => void,
          onNext: () => void,
          onCancel: () => void,
          disabled?: boolean
        ) => (
          <UploadETicketFormContent
            eTicketUploadInfos={eTicketUploadInfos}
            setETicketUploadInfos={setETicketUploadInfos}
            cancelTo={cancelTo}
            ticketType={ticketType}
            onCancel={onCancel}
            onNext={onNext}
            disabled={disabled}
          />
        )}
      />
    </UploadETicketsContextProvider>
  );
};

const UploadETicketFormContent = ({
  cancelTo,
  ticketType,
  onNext,
  onCancel,
  disabled,
  eTicketUploadInfos,
  setETicketUploadInfos,
}: {
  cancelTo?: ModalProps;
  ticketType: TicketType.ETicket | TicketType.QRCode;
  onNext: () => void;
  onCancel: () => void;
  disabled?: boolean;
  eTicketUploadInfos: DocumentFileUploadInfo[];
  setETicketUploadInfos: (infos: DocumentFileUploadInfo[]) => void;
}) => {
  const { formState } = useFormContext<ETicketsForm>();
  const { isDirty, isSubmitting } = formState;

  const { event, posEntity } =
    useActivePosEntityContext<EntityWithRealTickets>();

  return (
    <>
      <CancellableFormHeader
        cancelTo={cancelTo}
        disabled={disabled || isSubmitting}
        showDialogOnCancel={isDirty}
        onBeforeClose={onCancel}
      >
        <ConnectedEventEntityHeader
          title={
            <Content
              id={
                ticketType === TicketType.ETicket
                  ? ContentId.UploadETickets
                  : ContentId.UploadQRCodes
              }
            />
          }
        />
      </CancellableFormHeader>

      <ModalBody>
        <DndFilerUploaderContextProvider>
          <DndGlobalFileDrop>
            <ModalBodyHeaderContainer>
              <Summary event={event!} posEntity={posEntity!} />
            </ModalBodyHeaderContainer>
            <ModalBodyDataContainer
              style={{ height: '100%', overflow: 'hidden' }}
            >
              <UploadETicketsSeatAssignmentBody
                entityWithTickets={posEntity!}
                ticketType={ticketType}
                eTicketUploadInfos={eTicketUploadInfos}
                setETicketUploadInfos={setETicketUploadInfos}
              />
            </ModalBodyDataContainer>
          </DndGlobalFileDrop>
        </DndFilerUploaderContextProvider>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          cancelTo={cancelTo}
          disabled={disabled || isSubmitting}
          showDialogOnCancel={isDirty}
          onBeforeClose={onCancel}
        >
          <OkButton
            onClick={onNext}
            disabled={disabled || isSubmitting}
            textContentId={ContentId.Save}
          />
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
};
