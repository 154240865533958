import { useMemo } from 'react';
import { AppRouteConfigs } from 'src/app/AppRoutes';
import { useAppContext } from 'src/contexts/AppContext';
import { hasAccessToRoute } from 'src/utils/userUtils';

import { MenuItem } from '../MenuItem/MenuItem';
import * as styles from './MainMenu.css';

export const MainMenu = ({ toggleClose }: { toggleClose: () => void }) => {
  const { loginContext, appContext, mainRoute } = useAppContext();

  const user = loginContext?.user;
  const features = appContext?.features;
  const allowedRoutes = useMemo(
    () =>
      Object.values(AppRouteConfigs)
        .filter(
          (r) => r.view != null && hasAccessToRoute(user, features, r.view)
        )
        .map(({ view, label, path, icon }, index) => (
          <MenuItem
            key={index}
            textContent={label || view}
            icon={icon}
            isActive={mainRoute === view}
            path={path}
            onClick={toggleClose}
          />
        )),
    [features, user, mainRoute, toggleClose]
  );

  return <div className={styles.menu}>{allowedRoutes}</div>;
};
