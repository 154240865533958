import { ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';
import { ADCAMPAIGNS_TABLE_COLUMNS_CONFIG } from 'src/tables/AdCampaignsTable/configs/AdCampaignsTableColumnConfig';
import { AdCampaignsTableColumnId } from 'src/utils/columns/adCampaigns/adCampaignsColumnUtils.types';

import { AdCampaignEntityWithMetrics } from './AdCampaignsTable.type';

export const useGetAdCampaignsTableColumns = () => {
  return useMemo(() => {
    // TODO: Filter out reporting columns until we enabled this on AdCampaign side
    const columns = ADCAMPAIGNS_TABLE_COLUMNS_CONFIG.filter(
      (item) =>
        ![
          AdCampaignsTableColumnId.AdSpend,
          AdCampaignsTableColumnId.SoldProceeds,
          AdCampaignsTableColumnId.SoldQty,
        ].includes(item.id as AdCampaignsTableColumnId)
    ).map((item) => item.id as AdCampaignsTableColumnId);

    return Array.from(columns).reduce<
      ColumnDef<AdCampaignEntityWithMetrics | null>[]
    >((acc, columnId) => {
      const columnConfig = Object.values(ADCAMPAIGNS_TABLE_COLUMNS_CONFIG).find(
        (column) => column.id === columnId
      );
      if (columnConfig) {
        acc.push(columnConfig);
      }
      return acc;
    }, []);
  }, []);
};
