import { ComponentProps, useCallback, useMemo, useState } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { RotatingWrapper } from 'src/core/ui/AnimatingWrapper';
import { DownloadIcon, IconsFill, ProcessingIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  SaleClient,
  SaleQuery,
} from 'src/WebApiController';

import { useFilterQueryState } from '../MainFilterBar/useFilterQueryState';
import { ButtonWithIcon } from './ButtonWithIcon';

export const ExportSalesButton = ({
  textContentId,
  textOnly,
  iconOnlyMode,
  variant,
  ...props
}: Omit<ComponentProps<typeof ButtonWithIcon>, 'icon' | 'onClick'> & {
  textOnly?: boolean;
}) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const { filterQueryWithEventIds } = useFilterQueryState<SaleQuery>(
    ActionOutboxEntityType.Sale
  );
  const [isLoading, setIsLoading] = useState(false);

  const onExportButtonClicked = useCallback(async () => {
    setIsLoading(true);
    await tryInvokeApi(
      async () => {
        const result = await new SaleClient(
          activeAccountWebClientConfig
        ).exportSales(filterQueryWithEventIds);

        if (result.data) {
          const url = URL.createObjectURL(result.data);
          const a = document.createElement('a');
          a.setAttribute('href', url);
          a.setAttribute('download', result.fileName ?? '');
          a.click();
          URL.revokeObjectURL(url);
          a.remove();
        }
      },
      (error) => {
        showErrorDialog('SaleClient.exportSales', error, {
          trackErrorData: { filterQueryWithEventIds },
        });
      },
      () => setIsLoading(false)
    );
  }, [activeAccountWebClientConfig, filterQueryWithEventIds, showErrorDialog]);

  const iconFill = useMemo(
    () =>
      props.disabled
        ? IconsFill.textDisabled
        : iconOnlyMode || (variant ?? 'text') !== 'regular'
        ? IconsFill.textBrand
        : IconsFill.textInverted,
    [iconOnlyMode, props.disabled, variant]
  );
  return (
    <ButtonWithIcon
      {...props}
      onClick={onExportButtonClicked}
      disabled={isLoading || props.disabled}
      icon={
        !textOnly ? (
          isLoading ? (
            <RotatingWrapper>
              <ProcessingIcon fill={iconFill} />
            </RotatingWrapper>
          ) : (
            <DownloadIcon withHoverEffect fill={iconFill} />
          )
        ) : null
      }
      iconOnlyMode={iconOnlyMode}
      variant={variant ?? 'text'}
      textContentId={textContentId || ContentId.Export}
    />
  );
};
