import { SaleDetailsModalSelector } from 'src/modals/SaleDetails/SaleDetailsModalSelector';
import { SaleDeeplinkQueryParam } from 'src/utils/constants/constants';

export const SaleDetailsModalConfig = {
  children: <SaleDetailsModalSelector />,
  clickOutsideToClose: true,
  deepLinkKey: SaleDeeplinkQueryParam,
  deepLinkValue: undefined,
  size: 'slide-in',
};
