import { getSeatDisplayText } from 'src/components/common/SeatingInfo';
import { Event, Listing, TopLevelCategory, Venue } from 'src/WebApiController';

import { getEventDateDisplayStrings } from './dateTimeUtils';
import { getFormattedEventName } from './eventWithDataUtils';

const TopLevelCategoryMap: { [key in TopLevelCategory]: number } = {
  [TopLevelCategory.Theatre]: 1,
  [TopLevelCategory.Sports]: 2,
  [TopLevelCategory.Concert]: 3,
  [TopLevelCategory.Festival]: 1023,
};

export const getTopLevelCategoryId = (name: string): number => {
  const category = TopLevelCategory[name as keyof typeof TopLevelCategory];
  return TopLevelCategoryMap[category];
};

export const getTopLevelCategoryName = (id: number): TopLevelCategory => {
  return (
    Object.keys(TopLevelCategoryMap) as Array<keyof typeof TopLevelCategoryMap>
  ).find((key) => TopLevelCategoryMap[key] === id) as TopLevelCategory;
};

export const EmptySelectionKey = 'empty';

export const getListingDisplaytext = (
  listing: Listing,
  sectionText: string,
  rowText: string,
  seatText: string,
  seatsText: string
): string => {
  const section = listing.seating.section;
  const seatFr = listing.seating.seatFr;
  const seatTo = listing.seating.seatTo;
  const hasSeats = !!(seatFr || seatTo);
  const isSingleSeat = hasSeats && (seatFr === seatTo || !seatTo);

  let seatingText = '';
  if (hasSeats) {
    const seatDisplayText = getSeatDisplayText({
      seatFr: listing.seating.seatFr,
      seatTo: listing.seating.seatTo,
    });
    if (seatDisplayText) {
      seatingText = `${isSingleSeat ? seatText : seatsText} ${seatDisplayText}`;
    }
  }

  return `${sectionText} ${section} ${rowText} ${listing.seating.row} ${seatingText}`;
};

export const getEventDisplayText = (event: Event, venue?: Venue): string => {
  let eventName: string | null = null;
  let eventNameConnector: string | null = null;
  let eventSubName: string | null = null;
  let eventDisplayName = '';

  [eventName, eventNameConnector, eventSubName] = getFormattedEventName({
    event,
  });

  if (venue) {
    eventDisplayName += `${venue.name} `;
  }

  eventDisplayName += `${eventName} ${eventNameConnector} ${eventSubName}`;

  if (event.dates?.start) {
    const { formattedEventDate, formattedEventSubDate } =
      getEventDateDisplayStrings(event.dates?.start, {
        dateFormat: undefined,
        subDateFormat: undefined,
      });

    eventDisplayName += ` ${formattedEventDate} ${formattedEventSubDate}`;
  }
  return eventDisplayName;
};
