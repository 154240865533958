import { useMemo } from 'react';
import { useFlexSearchIndex } from 'src/hooks/useFlexSearchIndex';
import { PurchaseVendorsWithActions } from 'src/navigations/Routes/Settings/Views/VendorManagement/Vendors/components/VendorsTable/VendorsTableColumnsConfig';

interface UseGetFilteredVendorsProps {
  vendors: PurchaseVendorsWithActions[] | undefined;
  searchText: string;
}

export const useGetFilteredVendors = ({
  vendors = [],
  searchText,
}: UseGetFilteredVendorsProps): PurchaseVendorsWithActions[] => {
  const { vendorOptionsContent, vendorIdsMap } = useMemo(() => {
    const vendorIdsMap: Record<number, PurchaseVendorsWithActions> = {};
    const vendorOptionsContent: Record<number, string> = {};

    vendors?.forEach((vendorWithActions) => {
      const vendorId = vendorWithActions.vendor.id;
      vendorOptionsContent[vendorId] = vendorWithActions.vendor.name;
      vendorIdsMap[vendorId] = vendorWithActions;
    });

    return {
      vendorIdsMap,
      vendorOptionsContent,
    };
  }, [vendors]);

  const { flexSearchIndex } = useFlexSearchIndex(
    vendorOptionsContent,
    Object.keys(vendorOptionsContent).length > 0,
    true
  );

  const filteredVendorIds = useMemo<number[]>(() => {
    if (!searchText || !flexSearchIndex || !vendors) {
      return [];
    }

    return (flexSearchIndex.search(searchText) as string[]).map(Number);
  }, [flexSearchIndex, searchText, vendors]);

  return useMemo(() => {
    if (!searchText) {
      return vendors;
    }

    if (filteredVendorIds.length === 0) {
      return [];
    }

    const filteredVendors: PurchaseVendorsWithActions[] = [];
    for (const vendorId of filteredVendorIds) {
      filteredVendors.push(vendorIdsMap[vendorId]);
    }
    return filteredVendors;
  }, [filteredVendorIds, searchText, vendorIdsMap, vendors]);
};
