import { Store } from './Store';

const set = (key: string, entityName: string, value: unknown) => {
  try {
    window.sessionStorage.setItem(
      buildKey(key, entityName),
      JSON.stringify(value)
    );
  } catch (e) {
    // serialize error possible
    console.log(e);
  }
};

const get = (key: string, entityName: string) => {
  const str = window.sessionStorage.getItem(buildKey(key, entityName));
  try {
    return JSON.parse(str || 'null');
  } catch (e) {
    // serialize error possible
    console.log(e);
  }
  return null;
};

const truncate = (key: string, entityName: string, initVal: unknown = '') => {
  try {
    window.sessionStorage.setItem(
      buildKey(key, entityName),
      JSON.stringify(initVal)
    );
  } catch (e) {
    console.log(e);
  }
};

const contains = (key: string, entityName: string) => {
  const value = get(key, entityName);
  return value && value != null;
};

const buildKey = (key: string, entityName: string) => {
  return `${entityName}_${key}`;
};

const isEnabled = () => {
  return (
    window.sessionStorage &&
    typeof window.sessionStorage.getItem === 'function' &&
    typeof window.sessionStorage.setItem === 'function' &&
    typeof window.sessionStorage.removeItem === 'function'
  );
};

export const sessionStorageStore: Store = {
  contains,
  get,
  isEnabled,
  set,
  truncate,
};
