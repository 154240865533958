import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function FullPageIcon({
  fill,
  stroke,
  ...props
}: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper
      aria-label="FullPageIcon"
      {...props}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M15.5 8.5L23.5 0.5"
        stroke={stroke ?? fill ?? 'currentColor'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 23.5L8.5 15.5"
        stroke={stroke ?? fill ?? 'currentColor'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 23.5H0.5V18.5"
        stroke={stroke ?? fill ?? 'currentColor'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 5.5V0.5H18.5"
        stroke={stroke ?? fill ?? 'currentColor'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 15.5L23.5 23.5"
        stroke={stroke ?? fill ?? 'currentColor'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 0.5L8.5 8.5"
        stroke={stroke ?? fill ?? 'currentColor'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.5 5.5V0.5H5.5"
        stroke={stroke ?? fill ?? 'currentColor'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5 23.5H23.5V18.5"
        stroke={stroke ?? fill ?? 'currentColor'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgWrapper>
  );
}
