import clsx from 'clsx';
import { uniqueId } from 'lodash-es';
import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { vars } from 'src/core/themes';
import { mergeProps } from 'src/core/utils';

import { hoverEffect } from './SvgWrapper.css';

export type PosIconProps = ComponentPropsWithoutRef<'svg'> & {
  size?: string;
  withHoverEffect?: boolean;
  title?: string;
  disabled?: boolean;
};

type ViewBoxProp = {
  /**
   * Custom viewbox definition should be defined specific to the SVG you're pulling from
   */
  viewBox: string;
};

export const SvgWrapper = forwardRef<SVGSVGElement, PosIconProps & ViewBoxProp>(
  function SvgWrapper(
    {
      withHoverEffect,
      size = vars.iconSize.l,
      children,
      height,
      width,
      title,
      fill,
      stroke,
      disabled,
      ...props
    },
    ref
  ) {
    fill = disabled ? vars.color.textDisabled : fill;

    const [parsedWidth, parsedHeight] = [width, height].map((attr) =>
      attr != null ? parseInt(attr.toString(), 10) : attr
    );

    const inheritedWidth = parsedWidth ?? parsedHeight ?? 24;
    const inheritedHeight = parsedHeight ?? parsedWidth ?? 24;
    const newUniqueId = uniqueId();

    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        fill={fill || 'currentColor'}
        stroke={stroke || 'inherit'}
        width={inheritedWidth}
        height={inheritedHeight}
        {...mergeProps(
          {
            className: clsx(
              `custom-sizing-${newUniqueId}`,
              withHoverEffect ? hoverEffect : undefined
            ),
            cursor: disabled ? 'none' : undefined,
          },
          props
        )}
      >
        {size && (
          <defs>
            <style type="text/css">{`.custom-sizing-${newUniqueId} {width: ${
              size ?? inheritedWidth
            }; height: ${size ?? inheritedHeight};}`}</style>
          </defs>
        )}
        {children}
        {title && <title>{title}</title>}
      </svg>
    );
  }
);
