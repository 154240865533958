import { ConnectedEventInfo } from 'src/components/Events/ConnectedEventInfo';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { useGetEventFullInfo } from 'src/hooks/useGetEventFullInfo';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';

import { InventoryEventCatchUpCardProps } from './InventoryEventCatchUpCard.types';

export function InventoryEventCatchUpDropdown({
  cardProps,
}: {
  cardProps: InventoryEventCatchUpCardProps[];
}) {
  return (
    <PosDropdown
      key="event-catchup-dropdown"
      trigger={
        <div style={{ padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}` }}>
          <MoreIcon withHoverEffect fill={IconsFill.textBrand} align="middle" />
        </div>
      }
      align="end"
    >
      {cardProps.map((props, i) => (
        <InventoryEvehtCatchUpDrodownItem key={i} {...props} />
      ))}
    </PosDropdown>
  );
}

function InventoryEvehtCatchUpDrodownItem({
  event,
  isActive,
  onClick,
}: InventoryEventCatchUpCardProps) {
  const { venue, performer } = useGetEventFullInfo(event);

  return (
    <PosDropdownItem
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.();
      }}
      style={{
        backgroundColor: isActive ? vars.color.backgroundHighlight : '',
      }}
    >
      <ConnectedEventInfo
        showEventDateTbd
        event={event}
        performer={performer}
        venue={venue}
        slimMode
      />
    </PosDropdownItem>
  );
}
