import {
  BankAccountIcon,
  GeneralIcon,
  PaypalIcon,
  WalletIcon,
  WireIcon,
} from 'src/svgs/Viagogo';
import { PurchasePaymentMethodType } from 'src/WebApiController';

export function getPaymentMethodIcon(method: PurchasePaymentMethodType) {
  switch (method) {
    case PurchasePaymentMethodType.PayPal:
      return PaypalIcon;
    case PurchasePaymentMethodType.MoneyOrder:
    case PurchasePaymentMethodType.Cash:
    case PurchasePaymentMethodType.COD:
      return WalletIcon;
    case PurchasePaymentMethodType.BankTransfer:
      return WireIcon;
    case PurchasePaymentMethodType.Check:
    case PurchasePaymentMethodType.ECheck:
      return BankAccountIcon;

    case PurchasePaymentMethodType.DebitCard:
    case PurchasePaymentMethodType.DebitNote:
    case PurchasePaymentMethodType.CreditMemo:
    case PurchasePaymentMethodType.Card:
    case PurchasePaymentMethodType.Credit:
    case PurchasePaymentMethodType.Other:
    default:
      return GeneralIcon;
  }
}
