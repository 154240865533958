import { clsx } from 'clsx';
import { MappedTicketInfo } from 'src/components/UploadArtifacts/UploadETicketsV2';
import { SeatInfo } from 'src/components/UploadArtifacts/UploadETicketsV2/views/Seats/SeatInfo/SeatInfo';
import { Content } from 'src/contexts/ContentContext';
import { useSiteTheme } from 'src/contexts/SiteTheme/SiteThemeContext';
import { vars } from 'src/core/themes';
import { Colors } from 'src/core/themes/constants/colors';
import { Stack } from 'src/core/ui';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { PageIcon } from 'src/svgs/PageIcon';
import { PaperTicketsIcon } from 'src/svgs/PaperTicketsIcon';
import { CheckIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './PreviewInfo.css';

interface PreviewInfoProps {
  fileName: string;
  mappedTicketInfo: MappedTicketInfo | undefined;
  section: string | undefined;
}

export const PreviewInfo = ({
  mappedTicketInfo,
  fileName,
  section,
}: PreviewInfoProps) => {
  const isMobile = useMatchMedia('mobile');
  const { isDarkMode } = useSiteTheme();
  const isLargeDesktop = useMatchMedia('largeDesktop');
  const useMobileStyle = isMobile || isLargeDesktop;
  const { ticket, ticketIndex } = mappedTicketInfo || {};

  return (
    <Stack
      direction={useMobileStyle ? 'row' : 'column'}
      alignItems={useMobileStyle ? 'center' : 'start'}
      justifyContent={useMobileStyle ? 'spaceBetween' : undefined}
      gap={'m'}
    >
      <Stack
        alignItems="center"
        gap="m"
        className={styles.fileNameWrapper}
        width={useMobileStyle ? 'half' : 'full'}
      >
        <PaperTicketsIcon
          className={clsx(styles.paperTicketIcon, styles.icon)}
          size={vars.iconSize.s}
        />
        <div>{fileName}</div>
      </Stack>
      {ticket ? (
        <div className={styles.seatInfoWrapper}>
          <Stack justifyContent={useMobileStyle ? 'end' : undefined}>
            <SeatInfo
              icon={
                isDarkMode ? (
                  <CheckIcon
                    fill={Colors.white}
                    className={styles.checkIconDarkMode}
                    size={vars.iconSize.s}
                  />
                ) : (
                  <CheckIcon
                    fill={IconsFill.textSuccess}
                    className={styles.checkIconLightMode}
                    size={vars.iconSize.s}
                  />
                )
              }
              seatFr={ticket.seat}
              ticketIndex={ticketIndex!}
              row={ticket.row}
              section={section ?? ''}
            />
          </Stack>
        </div>
      ) : (
        <Stack
          gap="m"
          alignItems="center"
          justifyContent={useMobileStyle ? 'end' : undefined}
          width="half"
          className={styles.notMapped}
        >
          <PageIcon size={vars.iconSize.s} className={styles.icon} />
          <Content id={ContentId.NotMapped} />
        </Stack>
      )}
    </Stack>
  );
};
