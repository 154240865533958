import { Content } from 'src/contexts/ContentContext';
import { useMatchMedia } from 'src/hooks/useMatchMedia';

import { ContentId } from './constants/contentId';

export const DeviceAwareContentWrapper = ({
  defaultId,
  mobileId,
}: {
  defaultId: ContentId;
  mobileId: ContentId;
}) => {
  const isMobile = useMatchMedia('mobile');
  return <Content id={isMobile ? mobileId : defaultId} />;
};

export const splitFormatContent = (s: string) => {
  const replacer = /\{\d\}/;
  const result = [];
  let curS = '';
  for (let i = 0; i < s.length; i++) {
    let foundPlaceholder = false;
    if (s[i] === '{') {
      const placeholder = s.substring(i, i + 3);
      if (placeholder.match(replacer)) {
        if (curS) {
          result.push(curS);
          curS = '';
        }
        result.push(placeholder);
        i = i + 2; // skip to the }
        foundPlaceholder = true;
      }
    }

    if (!foundPlaceholder) {
      curS += s[i];
    }
  }

  if (curS) {
    result.push(curS);
  }

  return result;
};
