import { useCallback, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ButtonWithIcon } from 'src/components/Buttons';
import { Content, useContent } from 'src/contexts/ContentContext';
import { DatePickerInput } from 'src/core/POS/DateSelector';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { PosTextField } from 'src/core/POS/PosTextField';
import { shared, vars } from 'src/core/themes';
import { Button, Stack } from 'src/core/ui';
import { useGetEventSellThroughRates } from 'src/hooks/api/useGetEventSellThroughRates';
import { useSellerEventSellThroughRateTargets } from 'src/hooks/api/useSellerEventSellThroughRateTargets';
import { CrossIcon, EditIcon, IconsFill, PlusIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { getLocaleFromLanguageOrCurrent } from 'src/utils/localeUtils';
import { SellThroughRateHistory } from 'src/WebApiController';

import * as styles from './SellThroughRateSection.css';
import {
  EventSellThroughRateTargetsForm,
  SellThroughRateSectionProps,
} from './SellThroughRateSection.types';

export const SellThroughRateTargetInput = ({
  event,
  disabled,
}: SellThroughRateSectionProps) => {
  const requiredMsg = useContent(ContentId.Required);

  const sellThroughRatesQuery = useGetEventSellThroughRates(event.viagId);
  const { sellThroughRateTargetsQuery, sellThroughRateTargetsMutation } =
    useSellerEventSellThroughRateTargets(event.viagId, event.mappingId);

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { watch, setValue, setError, clearErrors, handleSubmit } =
    useFormContext<EventSellThroughRateTargetsForm>();

  const strTargets = watch('strTargets');

  const validate = useCallback(
    (formValue: EventSellThroughRateTargetsForm) => {
      clearErrors();

      const strTargets = formValue.strTargets;

      let hasError = false;

      strTargets.forEach((str, index) => {
        if (str.timelineDate === undefined) {
          setError(`strTargets.${index}.timelineDate`, {
            message: requiredMsg,
          });
          hasError = true;
        }

        if (str.sellThroughRate === undefined) {
          setError(`strTargets.${index}.sellThroughRate`, {
            message: requiredMsg,
          });
          hasError = true;
        }
      });

      return !hasError;
    },
    [clearErrors, requiredMsg, setError]
  );

  const onSubmit = useCallback(
    async (formValue: EventSellThroughRateTargetsForm) => {
      setIsLoading(true);

      if (!validate(formValue)) {
        setIsLoading(false);
        return;
      }

      const input = formValue.strTargets
        .sort(
          (a, b) =>
            new Date(a.timelineDate!).getTime() -
            new Date(b.timelineDate!).getTime()
        )
        .map((s) => ({
          timelineDate: s.timelineDate,
          sellThroughRatePercentage: s.sellThroughRate! * 0.01,
        })) as SellThroughRateHistory[];

      await sellThroughRateTargetsMutation.mutateAsync({ input });

      setIsLoading(false);
      setIsEditing(false);
    },
    [validate, sellThroughRateTargetsMutation]
  );

  const onRowRemove = useCallback(
    (index: number) => {
      const newStrTargets = [...strTargets];
      newStrTargets.splice(index, 1);
      setValue('strTargets', newStrTargets);
    },
    [setValue, strTargets]
  );

  const onRowAdd = useCallback(() => {
    setValue('strTargets', [
      ...strTargets,
      {
        timelineDate: undefined,
        sellThroughRate: undefined,
      },
    ]);
  }, [setValue, strTargets]);

  const onClearAll = useCallback(() => {
    setValue('strTargets', []);
  }, [setValue]);

  if (
    sellThroughRatesQuery.isPending ||
    sellThroughRateTargetsQuery.isPending
  ) {
    return <PosSpinner />;
  }

  return (
    <Stack direction="column" gap="xxl" className={styles.inputContainer}>
      <Stack direction="column" gap="l">
        <span className={shared.typography.title5}>
          <Content id={ContentId.DefineTargets} />
        </span>
        <span>
          <Content id={ContentId.DefineTargetsSubtitle} />
        </span>
      </Stack>
      <Stack direction="column" gap="xl">
        <Stack direction="column" gap="l">
          {strTargets?.map((str, index) => (
            <SellThroughRateTargetInputRow
              key={index}
              index={index}
              onRemove={onRowRemove}
              editable={isEditing}
              disabled={disabled || isLoading}
            />
          ))}
        </Stack>
        {isEditing && (
          <Stack direction="row" justifyContent="spaceBetween">
            <ButtonWithIcon
              variant="textPlain"
              shape="none"
              icon={
                <PlusIcon
                  fill={IconsFill.textBrand}
                  size={vars.iconSize.s}
                  withHoverEffect
                />
              }
              textContentId={ContentId.AddTarget}
              onClick={onRowAdd}
              disabled={disabled || isLoading}
              style={{ padding: 0 }}
            />
            <Button
              variant="textPlain"
              onClick={onClearAll}
              disabled={disabled || isLoading}
              style={{ padding: 0 }}
            >
              <Content id={ContentId.ClearAll} />
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack direction="column" alignItems="end">
        {isEditing ? (
          <Button
            onClick={handleSubmit(onSubmit)}
            disabled={disabled || isLoading}
          >
            <Content id={ContentId.Save} />
          </Button>
        ) : (
          <ButtonWithIcon
            variant="textPlain"
            shape="none"
            icon={<EditIcon fill={IconsFill.textBrand} withHoverEffect />}
            onClick={() => setIsEditing(true)}
            textContentId={ContentId.Edit}
            disabled={disabled || isLoading}
            style={{ padding: 0 }}
          />
        )}
      </Stack>
    </Stack>
  );
};

const SellThroughRateTargetInputRow = ({
  index,
  onRemove,
  editable,
  disabled,
}: {
  index: number;
  onRemove: (index: number) => void;
  editable: boolean;
  disabled: boolean;
}) => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext<EventSellThroughRateTargetsForm>();
  const requiredMsg = useContent(ContentId.Required);

  return (
    <Stack direction="column" gap="s">
      <Stack direction="row" gap="l" alignItems="center">
        <Controller
          control={control}
          name={`strTargets.${index}.timelineDate`}
          rules={{ required: requiredMsg }}
          render={({ field: { ...field }, fieldState }) => (
            <PosFormField
              errors={errors.strTargets?.[index]?.timelineDate?.message}
            >
              <DatePickerInput
                fieldError={fieldState.error}
                locale={getLocaleFromLanguageOrCurrent()}
                disabled={!editable || disabled}
                {...field}
                onDateChange={(d) => {
                  setValue(field.name, d.toISOString());
                }}
              />
            </PosFormField>
          )}
        />

        <Controller
          control={control}
          name={`strTargets.${index}.sellThroughRate`}
          rules={{ required: requiredMsg }}
          render={({ field: { value, ...field } }) => (
            <PosFormField
              errors={errors.strTargets?.[index]?.sellThroughRate?.message}
            >
              <PosTextField
                value={value}
                disabled={!editable || disabled}
                min={0}
                type="number"
                inputMode="numeric"
                onChange={(e) => {
                  if (!e.target.value) {
                    field.onChange(null);
                    return;
                  }

                  const value = parseFloat(e.target.value);
                  if (value < 0) return;
                  if (value > 100) return;

                  field.onChange(value);
                }}
                postfixDisplay="%"
              />
            </PosFormField>
          )}
        />

        {editable && (
          <CrossIcon
            withHoverEffect
            size={vars.iconSize.xl}
            onClick={() => {
              onRemove(index);
            }}
          />
        )}
      </Stack>
    </Stack>
  );
};
