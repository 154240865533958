import { isEqual } from 'lodash-es';
import {
  ComponentProps,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { OkButton } from 'src/components/Buttons/OkButton';
import { AdCampaignsDataContext } from 'src/contexts/AdCampaignsDataContext/AdCampaignsDataContext';
import { AdGroupsDataContext } from 'src/contexts/AdGroupsDataContext/AdGroupsDataContext';
import { useAdPlatformCatalogDataContext } from 'src/contexts/AdPlatformCatalogDataContext';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import { getTopLevelCategoryId } from 'src/utils/adGroupUtils';
import { ContentId } from 'src/utils/constants/contentId';
import { getPerformerAndVenueForEvent } from 'src/utils/eventWithDataUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  AdGroupBidModifierKey,
  AdGroupState,
  CampaignManagerClient,
  CreateAdGroupBidModifierValue,
  CreateAdGroupRequest,
  EventWithData,
  Listing,
  Performer,
  Seating,
  TicketClassInfo,
  Venue,
} from 'src/WebApiController';

import { CancellableFormFooter, FieldWrapper } from '../common';
import { CancellableFormHeader } from '../common/CancellableFormHeader';
import { ConnectedEventEntityHeader } from '../common/EventEntityHeader';
import { modalDetails } from '../common/Modals.css';
import { ModalBody } from '../Modal';
import { ModalBodyDataContainer, ModalFooter } from '../Modal/Modal.styled';
import { EventSection } from './AdGroupSelectEventSection';
import { GenreSection } from './AdGroupSelectGenreSection';
import { ListingSection } from './AdGroupSelectListingSection';
import { PerformerSection } from './AdGroupSelectPerformerSection';
import { SeatingSection } from './AdGroupSelectSeatingSection';
import { TicketClassSection } from './AdGroupSelectTicketClassSection';

type AdGroupCreateInput = {
  genreId?: number;
  performer: Performer | null | undefined;
  event: EventWithData | null | undefined;
  venue: Venue | null | undefined;
  ticketClass: TicketClassInfo | null | undefined;
  seating: Seating | null | undefined;
  listing: Listing | null | undefined;
  name: string;
  baseBid: number;
  maxBid: number;
  dailyBudget: number;
};

export const AdGroupCreateForm = () => {
  const defaultValues = useMemo(() => {
    return {
      name: '',
      baseBid: 0,
      maxBid: 0,
      dailyBudget: 0,
    } as AdGroupCreateInput;
  }, []);

  const methods = useForm<AdGroupCreateInput>({
    defaultValues,
  });

  return (
    <FormProvider {...methods}>
      <AdGroupCreateFormContent {...methods} />
    </FormProvider>
  );
};

export const AdGroupCreateModal = {
  children: <AdGroupCreateForm />,
  clickOutsideToClose: true,
  size: 'slide-in',
};

export function AdGroupCreateFormContent({
  formState,
  handleSubmit,
  watch,
  setError,
  clearErrors,
}: Omit<
  ComponentProps<typeof FormProvider<AdGroupCreateInput, unknown>>,
  'children'
>) {
  const curFormValues = watch();

  const { closeModal } = useContext(ModalContext);

  const { allCampaignData } = useContext(AdCampaignsDataContext);

  const adCampaignEntity = allCampaignData ? allCampaignData[0] : null;

  const { showErrorDialog } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const { refreshData } = useContext(AdGroupsDataContext);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (adGroupCreateInput: AdGroupCreateInput) => {
      setIsLoading(true);

      if (!adCampaignEntity) {
        return;
      }

      const currencyCode = adCampaignEntity?.currencyCode;

      tryInvokeApi(
        async () => {
          setIsLoading(true);

          const client = new CampaignManagerClient(
            activeAccountWebClientConfig
          );

          const createAdGroupClientRequest: CreateAdGroupRequest = {
            name: adGroupCreateInput.name,
            state: AdGroupState.Active,
            campaignId: adCampaignEntity!.campaignId,
            baseBid: {
              currencyCode: currencyCode,
              amount: adGroupCreateInput.baseBid,
            },
            maxBid: {
              currencyCode: currencyCode,
              amount: adGroupCreateInput.maxBid,
            },
            dailyBudget: {
              currencyCode: currencyCode,
              amount: adGroupCreateInput.dailyBudget,
            },
            bidModifiers: [],
          };

          const bidModifierValue: CreateAdGroupBidModifierValue = {
            isManaged: false,
            value: 1.0,
          };

          const baseKey: AdGroupBidModifierKey = {
            topLevelCategoryId: null,
            leafCategoryId: null,
            venueId: null,
            eventId: null,
            ticketClassId: null,
            sectionId: null,
            rowId: null,
            externalId: null,
          };

          // Add specific bid modifier, based on hierarchy
          if (adGroupCreateInput.listing) {
            createAdGroupClientRequest.bidModifiers.push({
              key: {
                ...baseKey,
                eventId: adGroupCreateInput.event!.event.viagId,
                externalId: adGroupCreateInput.listing.idOnMkp!,
              },
              value: bidModifierValue,
            });
          } else if (adGroupCreateInput.seating) {
            createAdGroupClientRequest.bidModifiers.push({
              key: {
                ...baseKey,
                eventId: adGroupCreateInput.event!.event.viagId,
                sectionId: adGroupCreateInput.seating.sectionId,
              },
              value: bidModifierValue,
            });
          } else if (adGroupCreateInput.ticketClass) {
            createAdGroupClientRequest.bidModifiers.push({
              key: {
                ...baseKey,
                eventId: adGroupCreateInput.event!.event.viagId,
                ticketClassId: adGroupCreateInput.ticketClass.id,
              },
              value: bidModifierValue,
            });
          } else if (adGroupCreateInput.event) {
            createAdGroupClientRequest.bidModifiers.push({
              key: {
                ...baseKey,
                eventId: adGroupCreateInput.event!.event.viagId!,
              },
              value: bidModifierValue,
            });
          } else if (adGroupCreateInput.venue) {
            createAdGroupClientRequest.bidModifiers.push({
              key: {
                ...baseKey,
                venueId: adGroupCreateInput.venue.viagId,
              },
              value: bidModifierValue,
            });
          } else if (adGroupCreateInput.performer) {
            createAdGroupClientRequest.bidModifiers.push({
              key: {
                ...baseKey,
                leafCategoryId: adGroupCreateInput.performer.viagId,
              },
              value: bidModifierValue,
            });
          } else if (adGroupCreateInput.genreId) {
            createAdGroupClientRequest.bidModifiers.push({
              key: {
                ...baseKey,
                topLevelCategoryId: adGroupCreateInput.genreId,
              },
              value: bidModifierValue,
            });
          }
          const response = await client.createAdGroup(
            createAdGroupClientRequest
          );

          if (response.success) {
            await refreshData(adCampaignEntity.campaignId, undefined);
          } else {
            const errorMessage = 'Failed to create Ad Group';

            showErrorDialog(
              'CampaignManagerClient.createAdGroup',
              { message: errorMessage, status: 500 },
              {
                trackErrorData: adGroupCreateInput,
              }
            );
            return;
          }

          closeModal(true);
        },
        (error) => {
          showErrorDialog('CampaignManagerClient.createAdGroup', error, {
            trackErrorData: adGroupCreateInput,
          });
        },
        () => {
          setIsLoading(false);
        }
      );
    },
    [
      activeAccountWebClientConfig,
      adCampaignEntity,
      closeModal,
      refreshData,
      showErrorDialog,
    ]
  );

  const hasChanges = useCallback(
    (form: AdGroupCreateInput) => {
      return (
        !isEqual(formState.defaultValues?.name, form.name) ||
        !isEqual(formState.defaultValues?.baseBid, form.baseBid) ||
        !isEqual(formState.defaultValues?.maxBid, form.maxBid) ||
        !isEqual(formState.defaultValues?.dailyBudget, form.dailyBudget) ||
        !isEqual(formState.defaultValues?.genreId, form.genreId) ||
        !isEqual(formState.defaultValues?.performer, form.performer) ||
        !isEqual(formState.defaultValues?.venue, form.venue) ||
        !isEqual(formState.defaultValues?.event, form.event) ||
        !isEqual(formState.defaultValues?.ticketClass, form.ticketClass) ||
        !isEqual(formState.defaultValues?.seating, form.seating) ||
        !isEqual(formState.defaultValues?.listing, form.listing)
      );
    },
    [
      formState.defaultValues?.name,
      formState.defaultValues?.baseBid,
      formState.defaultValues?.maxBid,
      formState.defaultValues?.dailyBudget,
      formState.defaultValues?.genreId,
      formState.defaultValues?.performer,
      formState.defaultValues?.venue,
      formState.defaultValues?.event,
      formState.defaultValues?.ticketClass,
      formState.defaultValues?.seating,
      formState.defaultValues?.listing,
    ]
  );

  const requiredMsg = useContent(ContentId.Required);
  const maxBidLessThanBaseBidMsg = useContent(
    ContentId.MaxBidLessThanBaseBidMsg
  );
  const dailyBudgetLessThanMaxBidMsg = useContent(
    ContentId.DailyBudgetLessThanMaxBidMsg
  );

  const validateForm = useCallback(() => {
    let hasErrors = false;

    clearErrors('name');
    if (!curFormValues.name) {
      setError('name', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    clearErrors('baseBid');
    if (!curFormValues.baseBid) {
      setError('baseBid', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    clearErrors('maxBid');
    if (!curFormValues.maxBid) {
      setError('maxBid', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (curFormValues.maxBid < curFormValues.baseBid) {
      setError(
        'maxBid',
        { message: maxBidLessThanBaseBidMsg },
        { shouldFocus: true }
      );
      hasErrors = true;
    }

    clearErrors('dailyBudget');
    if (!curFormValues.dailyBudget) {
      setError('dailyBudget', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    if (curFormValues.dailyBudget < curFormValues.maxBid) {
      setError(
        'dailyBudget',
        { message: dailyBudgetLessThanMaxBidMsg },
        { shouldFocus: true }
      );
      hasErrors = true;
    }

    // Require at least genre to be specified
    clearErrors('genreId');
    if (!curFormValues.genreId) {
      setError('genreId', { message: requiredMsg }, { shouldFocus: true });
      hasErrors = true;
    }

    return !hasErrors;
  }, [
    clearErrors,
    curFormValues.baseBid,
    curFormValues.dailyBudget,
    curFormValues.genreId,
    curFormValues.maxBid,
    curFormValues.name,
    dailyBudgetLessThanMaxBidMsg,
    maxBidLessThanBaseBidMsg,
    requiredMsg,
    setError,
  ]);

  const onSubmitHandler = useCallback(() => {
    if (!hasChanges(curFormValues)) return;

    if (!validateForm()) {
      return;
    }

    handleSubmit(onSubmit)();
  }, [hasChanges, curFormValues, validateForm, handleSubmit, onSubmit]);

  const formHasChanges = hasChanges(curFormValues);

  return (
    <>
      <CancellableFormHeader
        disabled={isLoading || formState.isSubmitting}
        showDialogOnCancel={formHasChanges}
      >
        <ConnectedEventEntityHeader
          title={<Content id={ContentId.AddAdGroup} />}
        />
      </CancellableFormHeader>

      <ModalBody>
        <div className={modalDetails}>
          <ModalBodyDataContainer>
            <AdGroupCreateFormBody />
          </ModalBodyDataContainer>
        </div>
      </ModalBody>

      <ModalFooter>
        <CancellableFormFooter
          disabled={isLoading}
          showDialogOnCancel={formHasChanges}
        >
          <OkButton
            onClick={onSubmitHandler}
            disabled={isLoading || !formHasChanges}
            textContentId={ContentId.Save}
          />
        </CancellableFormFooter>
      </ModalFooter>
    </>
  );
}

export const AdGroupCreateFormBody = () => {
  const { setValue, watch, formState, clearErrors } =
    useFormContext<AdGroupCreateInput>();

  const namePlaceholder = useContent(ContentId.EnterAdGroupName);

  const { getUiCurrency } = useLocalizationContext();
  const { loginContext } = useAppContext();

  const uiCurrency = getUiCurrency(
    loginContext?.user?.activeAccount?.currencyCode
  );

  const name = watch('name');
  const baseBid = watch('baseBid');
  const maxBid = watch('maxBid');
  const dailyBudget = watch('dailyBudget');

  const setName = useCallback(
    (name: string) => {
      clearErrors('name');
      setValue('name', name);
    },
    [clearErrors, setValue]
  );

  const setBaseBid = useCallback(
    (baseBid: number) => {
      if (baseBid >= 0 && baseBid <= Number.MAX_VALUE) {
        clearErrors('baseBid');
        setValue('baseBid', baseBid);
      }
    },
    [clearErrors, setValue]
  );

  const setMaxBid = useCallback(
    (maxBid: number) => {
      if (maxBid >= 0 && maxBid <= Number.MAX_VALUE) {
        clearErrors('maxBid');
        setValue('maxBid', maxBid);
      }
    },
    [clearErrors, setValue]
  );

  const setDailyBudget = useCallback(
    (dailyBudget: number) => {
      if (dailyBudget >= 0 && dailyBudget <= Number.MAX_VALUE) {
        clearErrors('dailyBudget');
        setValue('dailyBudget', dailyBudget);
      }
    },
    [clearErrors, setValue]
  );

  return (
    <>
      <FieldWrapper>
        <PosFormField
          errors={formState.errors.name?.message}
          label={<Content id={ContentId.Name} />}
        >
          <PosTextField
            rootProps={{
              state: getTextFieldState(formState.errors.name),
            }}
            value={name || undefined}
            placeholder={namePlaceholder}
            spellCheck={false}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </PosFormField>
      </FieldWrapper>
      <AdGroupCreateFormHierarchy />
      <FieldWrapper>
        <PosFormField
          errors={formState.errors.baseBid?.message}
          label={<Content id={ContentId.BaseBid} />}
        >
          <PosCurrencyField
            rootProps={{
              state: getTextFieldState(formState.errors.baseBid),
            }}
            uiCurrency={uiCurrency}
            value={baseBid}
            onChange={(e) => {
              const v = parseFloat(e.target.value) || 0;
              setBaseBid(v);
            }}
          />
        </PosFormField>
      </FieldWrapper>
      <FieldWrapper>
        <PosFormField
          errors={formState.errors.maxBid?.message}
          label={<Content id={ContentId.MaxBid} />}
        >
          <PosCurrencyField
            rootProps={{
              state: getTextFieldState(formState.errors.maxBid),
            }}
            uiCurrency={uiCurrency}
            value={maxBid}
            onChange={(e) => {
              const v = parseFloat(e.target.value) || 0;
              setMaxBid(v);
            }}
          />
        </PosFormField>
      </FieldWrapper>
      <FieldWrapper>
        <PosFormField
          errors={formState.errors.dailyBudget?.message}
          label={<Content id={ContentId.DailyBudget} />}
        >
          <PosCurrencyField
            rootProps={{
              state: getTextFieldState(formState.errors.dailyBudget),
            }}
            uiCurrency={uiCurrency}
            value={dailyBudget}
            onChange={(e) => {
              const v = parseFloat(e.target.value) || 0;
              setDailyBudget(v);
            }}
          />
        </PosFormField>
      </FieldWrapper>
    </>
  );
};

export const AdGroupCreateFormHierarchy = () => {
  const { setValue, watch, clearErrors, formState } =
    useFormContext<AdGroupCreateInput>();

  const genreId = watch('genreId');
  const performer = watch('performer');
  const venue = watch('venue');
  const event = watch('event');
  const ticketClass = watch('ticketClass');
  const seating = watch('seating');
  const listing = watch('listing');

  const { data: catalogData } = useAdPlatformCatalogDataContext();

  const onSelectGenre = (genreId?: number, clearChildren = true): void => {
    clearErrors('genreId');
    setValue('genreId', genreId);

    if (clearChildren) {
      setValue('performer', undefined);
      setValue('venue', undefined);
      setValue('event', undefined);
      setValue('ticketClass', undefined);
      setValue('seating', undefined);
      setValue('listing', undefined);
    }
  };

  const onSelectPerformer = (
    performer: Performer | null | undefined,
    clearChildren = true
  ): void => {
    clearErrors('performer');
    setValue('performer', performer);

    if (performer && performer.categ?.length > 0 && genreId === undefined) {
      const genreId = getTopLevelCategoryId(performer.categ[0]);
      onSelectGenre(genreId, false);
    }

    if (clearChildren) {
      setValue('venue', undefined);
      setValue('event', undefined);
      setValue('ticketClass', undefined);
      setValue('seating', undefined);
      setValue('listing', undefined);
    }
  };

  const onSelectVenue = (
    venue: Venue | null | undefined,
    clearChildren = true
  ): void => {
    clearErrors('venue');
    setValue('venue', venue);

    if (clearChildren) {
      setValue('event', undefined);
      setValue('ticketClass', undefined);
      setValue('seating', undefined);
      setValue('listing', undefined);
    }
  };

  const onSelectEvent = (
    event: EventWithData | null | undefined,
    clearChildren = true
  ): void => {
    clearErrors('event');
    setValue('event', event);

    if (clearChildren) {
      setValue('ticketClass', undefined);
      setValue('seating', undefined);
      setValue('listing', undefined);
    }

    // TODO: Also handle onSelectVenue() when we enable this

    if (catalogData && event && performer === undefined) {
      const { performer: performerForEvent } = getPerformerAndVenueForEvent(
        event.event,
        catalogData
      );

      if (performerForEvent) {
        onSelectPerformer(performerForEvent, false);
      }
    }
  };

  const onSelectTicketClass = (
    ticketClass: TicketClassInfo | null | undefined,
    clearChildren = true
  ): void => {
    clearErrors('ticketClass');
    setValue('ticketClass', ticketClass);

    if (clearChildren) {
      setValue('seating', undefined);
      setValue('listing', undefined);
    }
  };

  const onSelectSeating = (
    seating: Seating | null | undefined,
    parentTicketClass: TicketClassInfo | null | undefined,
    clearChildren = true
  ): void => {
    clearErrors('seating');
    setValue('seating', seating);

    if (ticketClass === undefined) {
      onSelectTicketClass(parentTicketClass, false);
    }

    if (clearChildren) {
      setValue('listing', undefined);
    }
  };

  const onSelectListing = (
    listing: Listing | null | undefined,
    parentSeating: Seating | null | undefined,
    parentTicketClass: TicketClassInfo | null | undefined
  ): void => {
    clearErrors('listing');
    setValue('listing', listing);

    if (seating === undefined) {
      onSelectSeating(parentSeating, parentTicketClass, false);
    }
  };

  return (
    <>
      <Stack direction="column" width="full" gap="l">
        <GenreSection
          onSelectGenre={onSelectGenre}
          genreId={genreId}
          error={formState.errors.genreId?.message}
        />
        <PerformerSection
          onSelectPerformer={onSelectPerformer}
          genreId={genreId}
          performer={performer}
          error={formState.errors.performer?.message}
        />
        <EventSection
          onSelectEvent={onSelectEvent}
          performerId={performer?.viagId ?? undefined}
          genreId={genreId}
          event={event}
          error={formState.errors.event?.message}
        />
        <TicketClassSection
          onSelectTicketClass={onSelectTicketClass}
          eventId={event?.event.viagId ?? undefined}
          ticketClass={ticketClass}
          error={formState.errors.ticketClass?.message}
        />
        <SeatingSection
          onSelectSeating={onSelectSeating}
          eventId={event?.event.viagId ?? undefined}
          ticketClass={ticketClass ?? undefined}
          seating={seating}
          error={formState.errors.seating?.message}
        />
        <ListingSection
          onSelectListing={onSelectListing}
          eventId={event?.event.viagId ?? undefined}
          ticketClass={ticketClass ?? undefined}
          seating={seating ?? undefined}
          listing={listing}
          error={formState.errors.listing?.message}
        />
      </Stack>
    </>
  );
};
