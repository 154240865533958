import { Row } from '@tanstack/react-table';
import {
  createContext,
  KeyboardEvent,
  ReactNode,
  useContext,
  useState,
} from 'react';

type OnKeyDownHandler = (
  e: KeyboardEvent,
  index: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rows?: Row<any>[]
) => void;

type ActiveFocusContextType = {
  activeItem?: ActiveItem | null;
  setActiveItem: (activeItem: ActiveItem | undefined) => void;
  onKeyDownHandler: OnKeyDownHandler;
};

const ActiveFocusContext = createContext<ActiveFocusContextType>({
  activeItem: null,
  setActiveItem: () => void 0,
  onKeyDownHandler: () => void 0,
});

type ActiveFocusContextProviderProps = {
  children: ReactNode;
};

type ActiveItem = {
  id: string;
  index: number;
};

/**
 * TODO: add jsdoc description
 */
export const ActiveFocusContextProvider = ({
  children,
}: ActiveFocusContextProviderProps) => {
  const [activeItem, setActiveItem] = useState<ActiveItem>();
  const [rows, setRows] = useState<Row<unknown>[]>([]);

  // We have to take in rows as a parameter to track row order
  const onKeyDownHandler: OnKeyDownHandler = (e, index, inputRows) => {
    const { key } = e;
    const isArrowDown = key === 'ArrowDown';
    const isArrowUp = key === 'ArrowUp';
    const effectiveRows = inputRows ?? rows;

    if (!isArrowDown && !isArrowUp) return;
    e.preventDefault();

    const newIndex = isArrowUp
      ? index - 1 < 0
        ? effectiveRows.length - 1
        : index - 1
      : index + 1 > effectiveRows.length - 1
      ? 0
      : index + 1;

    if (inputRows) setRows(inputRows);
    setActiveItem({ id: effectiveRows[newIndex].id, index: newIndex });
  };

  return (
    <ActiveFocusContext.Provider
      value={{
        activeItem,
        setActiveItem,
        onKeyDownHandler,
      }}
    >
      {children}
    </ActiveFocusContext.Provider>
  );
};

export const useActiveFocusContext = () => useContext(ActiveFocusContext);
