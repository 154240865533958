import { ReactNode } from 'react';
import { PageSection } from 'src/components/common/PageSection';

export const HeaderWidgetContainer = ({
  title,
  action,
}: {
  title: string;
  action?: ReactNode;
}) => {
  return (
    <PageSection.Header action={action}>
      <span>{title}</span>
    </PageSection.Header>
  );
};
