import { PosIconProps, SvgWrapper } from './SvgWrapper';

export function SignalIcon(props: Omit<PosIconProps, 'viewBox'>) {
  return (
    <SvgWrapper
      {...props}
      viewBox="0 0 59 60" // The viewbox here is important as it won't display properly without this specific value
    >
      <circle cx="10.2073" cy="49.7805" r="10.2073" fill="currentColor" />
      <path
        d="M9.21951 25.0854C22.3902 25.0854 35.2317 36.939 35.2317 51.0976M9.21951 5C35.2317 5 54 25.0854 54 51.0976"
        stroke="currentColor"
        fill="none"
        strokeWidth="10"
      />
    </SvgWrapper>
  );
}
