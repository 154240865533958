import { useQuery } from '@tanstack/react-query';
import {
  ComponentPropsWithRef,
  Dispatch,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Components, Virtuoso } from 'react-virtuoso';
import { SwiperButton } from 'src/components/Buttons/SwiperButton';
import * as EmptySectionContent from 'src/components/common/EmptySectionContent';
import { useAppContext } from 'src/contexts/AppContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { PosEnumSelect } from 'src/core/POS/PosSelect';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { clearIDBCache } from 'src/core/utils/createIndexDBPersister';
import { useClassifiedInboundEmails } from 'src/hooks/api/useClassifiedInboundEmails';
import { useMatchMedia } from 'src/hooks/useMatchMedia';
import { ErrorSolidIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { CONVERSATION_SORT_TO_CID } from 'src/utils/constants/contentIdMaps';
import { stringToUtcDate } from 'src/utils/dateTimeUtils';
import {
  EventUiSort,
  getEventSort,
  getEventUiSort,
} from 'src/utils/eventQueryUtils';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  AutoPoInboundEmail,
  EventSort,
  Marketplace,
  SellerSupportCase,
  SellerSupportCaseClient,
} from 'src/WebApiController';
import { useSwiper } from 'swiper/react';

import IconBulletList from '~icons/pos/bulletList';

import {
  ConversationFilter,
  ConversationFilterQuery,
  defaultConversationFilter,
  getCategoryConversationTypes,
} from './ConversationFilter';
import {
  ConversationInfo,
  ConversationListItem,
  ConversationType,
  convertConversationTypeToEmailClassificationTypes,
  convertEmailClassificationTypeToConversationType,
} from './ConversationListItem/ConversationListItem';
import * as styles from './ConversationSidePanel.css';

type VirtuosoContext = {
  isLoading?: boolean;
  isError?: boolean;
  filter: ConversationFilterQuery;
  setFilter: Dispatch<SetStateAction<ConversationFilterQuery>>;
  allCount?: number;
  unreadCount?: number;
  isFetchingNextPage: boolean;
};

const UnreadFilter = ({
  context,
}: {
  context: VirtuosoContext | undefined;
}) => {
  const { filter, setFilter, allCount, unreadCount } = context ?? {};

  if (filter == null || setFilter == null) {
    return null;
  }

  return (
    <div className={styles.unreadFilterRowContainer}>
      <div className={styles.tabFilterContainer}>
        <div
          className={
            styles.underlinedTab + (filter.isUnread !== true ? ' active' : '')
          }
          onClick={() => {
            setFilter({ ...filter, isUnread: undefined });
          }}
        >
          <Content id={ContentId.All} />
          {allCount != null && ` (${allCount})`}
        </div>
        <div
          className={
            styles.underlinedTab + (filter.isUnread === true ? ' active' : '')
          }
          onClick={() => {
            setFilter({ ...filter, isUnread: true });
          }}
        >
          <Content id={ContentId.Unread} />
          {unreadCount != null && ` (${unreadCount})`}
        </div>
      </div>
      <PosEnumSelect
        variant="textPlain"
        shape="none"
        style={{ paddingBottom: vars.spacing['xxs'] }}
        value={
          filter
            ? getEventUiSort(EventSort.Date, filter?.isSortDescending)
            : EventUiSort.DateDesc
        }
        defaultValue={getEventUiSort(EventSort.Date, true)}
        onChange={(sort) => {
          const [, isSortDescending] = getEventSort(sort);
          if (isSortDescending !== filter?.isSortDescending) {
            setFilter({
              ...filter,
              isSortDescending,
            });
          }
        }}
        valueOptionsContent={CONVERSATION_SORT_TO_CID}
      />
    </div>
  );
};

const Header: Components<unknown, VirtuosoContext>['Header'] = ({
  context,
}: {
  context?: VirtuosoContext;
}) => {
  const { filter, setFilter } = context ?? {};
  const { inboundEmailId, sellerSupportCaseId } = useParams();

  const isLargeDesktop = useMatchMedia('largeDesktop');

  const [searchParams] = useSearchParams();

  return (
    <div>
      {!isLargeDesktop && (inboundEmailId || sellerSupportCaseId) && (
        <div style={{ paddingRight: vars.spacing['sm'], float: 'right' }}>
          <SwiperButton dir="right" />
        </div>
      )}
      <h1 className={styles.pageName}>
        <Content id={ContentId.Inbox} />
      </h1>
      {filter && setFilter && (
        <div className={styles.filterContainer}>
          <ConversationFilter
            category={searchParams.get('category')}
            currentFilter={filter}
            setFilter={setFilter}
          />
          <UnreadFilter context={context} />
        </div>
      )}
    </div>
  );
};

const Item: Components<ConversationInfo, VirtuosoContext>['Item'] = ({
  children,
  ...rest
}: ComponentPropsWithRef<'div'>) => {
  return (
    <div className={styles.listItemWrapper} {...rest}>
      {children}
    </div>
  );
};

const EmptyPlaceholder: Components<
  unknown,
  VirtuosoContext
>['EmptyPlaceholder'] = ({ context }: { context?: VirtuosoContext }) => {
  if (context?.isError) {
    return (
      <EmptySectionContent.Root
        icon={
          <ErrorSolidIcon
            fill={IconsFill.textNegative}
            size={vars.iconSize.xl}
          />
        }
      >
        <EmptySectionContent.Error>
          <Content id={ContentId.Error_InternalServerError_GenericMessage} />
        </EmptySectionContent.Error>
        <Button
          variant={'regular'}
          onClick={() => {
            clearIDBCache(); // delete the cache storage before reloading
            window.location.reload();
          }}
        >
          <Content id={ContentId.Retry} />
        </Button>
      </EmptySectionContent.Root>
    );
  }
  if (context?.isLoading) {
    return <PosSpinner />;
  }
  return (
    <EmptySectionContent.Root icon={<IconBulletList />}>
      <Content id={ContentId.NoConversation} />
    </EmptySectionContent.Root>
  );
};

const Footer: Components<unknown, VirtuosoContext>['Footer'] = ({
  context,
}: {
  context?: VirtuosoContext;
}) => {
  if (context?.isFetchingNextPage) {
    return <PosSpinner />;
  }
  // XXX hack to prevent margin collapse on last element

  return <div style={{ paddingTop: '0.05px' }} />;
};

const sellerSupportCaseToConversationData = (
  supportCase: SellerSupportCase
): ConversationInfo => {
  return {
    datetime: stringToUtcDate(supportCase.lastNoteDate),
    merchantName: Marketplace.StubHub,
    conversationType:
      supportCase.entityType === ActionOutboxEntityType.Sale
        ? ConversationType.Sale
        : undefined,
    unread: supportCase.unreadNoteCount > 0,
    sellerSupportCase: supportCase,
  };
};

const inboundEmailToConversationData = (
  inboundEmail: AutoPoInboundEmail
): ConversationInfo => {
  const conversationType = convertEmailClassificationTypeToConversationType(
    inboundEmail.classificationType
  );

  return {
    datetime: stringToUtcDate(
      inboundEmail.receiveDate ?? inboundEmail.createDate
    ),
    title: inboundEmail.subject,
    vendorId: inboundEmail.purchaseOrderVendorId,
    unread: false,
    conversationType,
    inboundEmail,
  };
};

export function ConversationSidePanel() {
  const {
    inboundEmailId: activeInboundEmailId,
    sellerSupportCaseId: activeSellerSupportCaseId,
  } = useParams();
  const { activeAccountWebClientConfig } = useAppContext();
  const { trackError } = useErrorBoundaryContext();

  const [filter, setFilter] = useState<ConversationFilterQuery>(
    defaultConversationFilter
  );
  const swiper = useSwiper();
  const isLargeDesktop = useMatchMedia('largeDesktop');

  const sellerSupportCaseQuery = useQuery({
    queryKey: [
      'SellerSupportCaseClient.getSellerSupportCases',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return tryInvokeApi(
        async () => {
          return await new SellerSupportCaseClient(
            activeAccountWebClientConfig
          ).getSellerSupportCases(
            {
              isMostRecent: null,
              sellerSupportCaseIds: null,
            },
            null,
            null
          );
        },
        (error) => {
          trackError('SellerSupportCaseClient.getSellerSupportCases', error);
        }
      );
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
  });
  const sellerSupportCases = sellerSupportCaseQuery.data;
  const sellerSupportCasesIsLoading = sellerSupportCaseQuery.isLoading;
  const sellerSupportCasesIsError = sellerSupportCaseQuery.isError;

  const generalMessageContent = useContent(ContentId.GeneralMessage);

  const [searchParams] = useSearchParams();
  const category = searchParams.get('category');
  const supportCaseOnly = category === 'support';
  const filteredConversationTypes = getCategoryConversationTypes(category);

  const {
    isLoading: classifiedEmailIsLoading,
    isError: classifiedEmailIsError,
    inboundEmailsTotalCount,
    inboundEmailsClassified,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useClassifiedInboundEmails(
    category
      ? convertConversationTypeToEmailClassificationTypes(
          filteredConversationTypes as ConversationType[]
        )
      : undefined,
    filter.isSortDescending
  );

  const conversationData = useMemo(() => {
    if (sellerSupportCases == null || inboundEmailsClassified == null) {
      return [];
    }

    const supportCasesMapped = sellerSupportCases
      .map(sellerSupportCaseToConversationData)
      .map((c) => {
        c.title = generalMessageContent;
        return c;
      });

    const inboundEmailsMapped = inboundEmailsClassified.map(
      inboundEmailToConversationData
    );

    return supportCasesMapped.concat(inboundEmailsMapped);
  }, [generalMessageContent, sellerSupportCases, inboundEmailsClassified]);

  const filteredDataByType = useMemo(
    () =>
      conversationData.filter(
        (conversation) =>
          (filteredConversationTypes.length === 0 ||
            filteredConversationTypes.includes(
              conversation.conversationType ?? ConversationType.Uncategorized
            )) &&
          (!supportCaseOnly || conversation.sellerSupportCase != null)
      ),
    [conversationData, filteredConversationTypes, supportCaseOnly]
  );

  const allCount = useMemo(() => {
    const supportCaseCount = filteredDataByType.filter(
      (d) => d.sellerSupportCase != null
    ).length;

    return supportCaseCount + (inboundEmailsTotalCount ?? 0);
  }, [filteredDataByType, inboundEmailsTotalCount]);

  // TODO these filters will be eventually passed to the API as query params
  // POS-2210
  const filteredData = useMemo(
    () =>
      conversationData
        .filter(
          (conversation) =>
            (filteredConversationTypes.length === 0 ||
              filteredConversationTypes.includes(
                conversation.conversationType ?? ConversationType.Uncategorized
              )) &&
            (!supportCaseOnly || conversation.sellerSupportCase != null) &&
            (filter.isUnread === undefined ||
              (conversation.unread ?? false) === filter.isUnread)
        )
        .sort((a, b) => {
          if (a.datetime == null || b.datetime == null) {
            return 0;
          }
          const cmpResult = a.datetime < b.datetime ? -1 : 1;
          return filter.isSortDescending ? -cmpResult : cmpResult;
        }),
    [
      conversationData,
      filteredConversationTypes,
      filter.isSortDescending,
      filter.isUnread,
      supportCaseOnly,
    ]
  );

  const navigate = useNavigate();
  const onConversationClicked = (conversationInfo: ConversationInfo) => {
    if (!isLargeDesktop) {
      swiper?.slideNext();
    }

    if (conversationInfo.inboundEmail?.inboundEmailId != null) {
      navigate(
        {
          pathname: `${conversationInfo.inboundEmail.inboundEmailId}`,
          search: window.location.search,
        },
        { relative: 'route' }
      );
    } else if (
      conversationInfo.sellerSupportCase?.sellerSupportCaseId != null
    ) {
      navigate(
        {
          pathname: `support/${conversationInfo.sellerSupportCase.sellerSupportCaseId}`,
          search: window.location.search,
        },
        { relative: 'route' }
      );
    }
  };

  return (
    <div className={styles.conversationSidePanel}>
      <Virtuoso
        data={filteredData}
        components={{ Header, Footer, EmptyPlaceholder, Item }}
        context={{
          filter,
          setFilter,
          allCount,
          unreadCount: filteredDataByType.filter((m) => m.unread === true)
            .length,
          isError: sellerSupportCasesIsError || classifiedEmailIsError,
          isLoading: sellerSupportCasesIsLoading || classifiedEmailIsLoading,
          isFetchingNextPage,
        }}
        endReached={() => !isFetchingNextPage && hasNextPage && fetchNextPage()}
        itemContent={(i, conversationInfo) => {
          const {
            vendorId,
            merchantName,
            title,
            datetime,
            unread,
            conversationType,
            inboundEmail,
            sellerSupportCase,
          } = conversationInfo;
          return (
            <ConversationListItem
              vendorId={vendorId}
              merchantName={merchantName}
              title={title}
              datetime={datetime}
              unread={unread}
              conversationType={conversationType}
              inboundEmail={inboundEmail}
              sellerSupportCase={sellerSupportCase}
              isActive={
                (inboundEmail != null &&
                  activeInboundEmailId != null &&
                  inboundEmail.inboundEmailId ===
                    Number(activeInboundEmailId)) ||
                (sellerSupportCase != null &&
                  activeSellerSupportCaseId != null &&
                  sellerSupportCase.sellerSupportCaseId ===
                    Number(activeSellerSupportCaseId))
              }
              onClick={() => onConversationClicked(conversationInfo)}
            />
          );
        }}
      />
    </div>
  );
}
