import { RestrictSelectionType } from 'src/modals/ListingDetails/components/PricingSection/AutoPricingSection.types';
import { ContentId } from 'src/utils/constants/contentId';
import {
  COMP_LISTING_MODE_TO_CID,
  COMP_LISTING_MODE_TO_FLUENT_CID,
  OUTLIER_MODE_TO_CID,
  RESTRICT_SELECTION_TYPE_TO_CID,
  UNDERCUT_MODE_TO_CID,
  UNDERCUT_MODE_TO_FLUENT_CID,
} from 'src/utils/constants/contentIdMaps';
import {
  AutoPricingCompListingMode,
  AutoPricingOutlierMode,
  AutoPricingUndercutMode,
  Feature,
} from 'src/WebApiController';

import { useUserHasFeature } from './useUserHasFeature';

export const useAvailableAutopricingModes = (
  fluent: boolean,
  undercutMode: AutoPricingUndercutMode | null | undefined,
  compListingMode: AutoPricingCompListingMode | null | undefined,
  hasSeatScores: boolean,
  hasParentListing?: boolean,
  isGlobalEdit?: boolean,
  compListingOnlyForSelectedSectionsEnabled?: boolean | null
) => {
  // Feature checks
  const hasAdvanceAutopricingModes = useUserHasFeature(
    Feature.AdvanceAutopricingModes
  );
  const hasParentListingModeFeature = useUserHasFeature(
    Feature.CompListingMode_ParentListing
  );

  const restrictSelectionTypes = {
    ...RESTRICT_SELECTION_TYPE_TO_CID,
  } as Record<string, ContentId>;

  if (isGlobalEdit) {
    if (!compListingOnlyForSelectedSectionsEnabled) {
      delete restrictSelectionTypes[RestrictSelectionType.SpecificSections];
    }
  }

  if (compListingMode !== AutoPricingCompListingMode.QualityScore) {
    delete restrictSelectionTypes[RestrictSelectionType.SameZone];
  }

  const defaultRestrictSelectionType = Object.keys(restrictSelectionTypes)[0];

  const compListingModes = {
    ...(fluent ? COMP_LISTING_MODE_TO_FLUENT_CID : COMP_LISTING_MODE_TO_CID),
  } as Record<string, ContentId>;

  const undercutModes = {
    ...(fluent ? UNDERCUT_MODE_TO_FLUENT_CID : UNDERCUT_MODE_TO_CID),
  } as Record<string, ContentId>;

  const outlierModes = {
    ...OUTLIER_MODE_TO_CID,
  } as Record<string, ContentId>;

  if (!hasAdvanceAutopricingModes) {
    if (undercutMode !== AutoPricingUndercutMode.RowExtrapolation) {
      delete undercutModes[AutoPricingUndercutMode.RowExtrapolation];
    }
    if (compListingMode !== AutoPricingCompListingMode.SameSection) {
      delete compListingModes[AutoPricingCompListingMode.SameSection];
    }
  }

  if (!hasParentListingModeFeature || !hasParentListing) {
    delete compListingModes[AutoPricingCompListingMode.ParentListing];
  }

  // Determine which modes are disabled based on permissions and data availability
  // For each mode group, start them all off as enabled (i.e. all comp listing modes
  // enabled, all undercut modes enabled, etc.) and then disable them as needed
  const compListingModesDisabled: Record<string, ContentId | undefined> = {
    ...compListingModes,
  };
  Object.keys(compListingModesDisabled).forEach(
    (k) => (compListingModesDisabled[k] = undefined)
  );

  const undercutModesDisabled: Record<string, ContentId | undefined> = {
    ...undercutModes,
  };
  Object.keys(undercutModesDisabled).forEach(
    (k) => (undercutModesDisabled[k] = undefined)
  );

  const outlierModesDisabled: Record<string, ContentId | undefined> = {
    ...outlierModes,
  };
  Object.keys(outlierModesDisabled).forEach(
    (k) => (outlierModesDisabled[k] = undefined)
  );

  if (!hasSeatScores && !isGlobalEdit) {
    compListingModesDisabled[AutoPricingCompListingMode.QualityScore] =
      ContentId.AutopricingNotAvailableNoScores;
    compListingModesDisabled[AutoPricingCompListingMode.SameSection] =
      ContentId.AutopricingNotAvailableNoScores;

    undercutModesDisabled[AutoPricingUndercutMode.Deal] =
      ContentId.AutopricingNotAvailableNoScores;

    outlierModesDisabled[AutoPricingOutlierMode.StandardDeviations] =
      ContentId.AutopricingNotAvailableNoScores;
  }

  return {
    undercutModes,
    undercutModesDisabled,
    compListingModes,
    compListingModesDisabled,
    outlierModes,
    outlierModesDisabled,
    defaultRestrictSelectionType,
    restrictSelectionTypes,
  };
};
