import { ComponentProps } from 'react';
import { vars } from 'src/core/themes';
import { ReloadIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { ButtonWithIcon } from './ButtonWithIcon';

export const ReloadButton = ({
  textContentId,
  ...props
}: Omit<ComponentProps<typeof ButtonWithIcon>, 'icon'>) => {
  return (
    <ButtonWithIcon
      {...props}
      icon={<ReloadIcon withHoverEffect size={vars.iconSize.m} />}
      textContentId={textContentId || ContentId.Reload}
    />
  );
};
