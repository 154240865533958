import { Point } from 'paper';
import { MutableRefObject } from 'react';
import { RowRelativeScoreInfo } from 'src/components/Events/RowRelativeScoreDisplay';
import {
  BezierGraph,
  OnResizedFn,
  SerializedSegment,
} from 'src/core/POS/BezierGraph';
import { CalcOutputFn } from 'src/core/POS/BezierGraph/BezierGraphManager';
import { roundToPrecision } from 'src/utils/numberFormatter';

import * as styles from './ChangeRowScoreDialog.css';

export const GRAPH_WIDTH_PERCENTAGE = 60;
export const DEFAULT_ROW_GAP_PX = 10;
// When the height of a single row is available, we want to set height of
// gap to ${ROW_HEIGHT * ROW_GAP_RATIO} to keep whole graph proportional
export const ROW_GAP_RATIO = 0.42;
const PATH_COLOR = '#33b2b1';
export const INITIAL_ROW_SCORE_SEGMENTS: SerializedSegment[] = [
  { point: { x: 0.5, y: 50 } },
  {
    point: { x: 50, y: 50 },
    handleIn: { x: -10, y: 0 },
    handleOut: { x: 10, y: 0 },
  },
  { point: { x: 100 - 0.5, y: 50 } },
];

const calcCoordinateForRow = (
  i: number,
  rowDataToEdit: RowRelativeScoreInfo[],
  rowHeight: number,
  gapHeight: number,
  totalDivHeight: number
) => {
  let x;
  if (rowHeight > 0) {
    x = ((i * (gapHeight + rowHeight) + rowHeight / 2) / totalDivHeight) * 100;
  } else {
    x = ((i + 0.5) / rowDataToEdit.length) * 100;
  }
  x = Math.max(Math.min(100, x), 0);
  return x;
};

export const calcBoundsForRow = (
  i: number,
  len: number,
  rowHeight: number,
  gapHeight: number,
  totalDivHeight: number
) => {
  let xMin, xMax;
  if (rowHeight > 0) {
    xMin =
      ((i * (gapHeight + rowHeight) - 0.5 * gapHeight) / totalDivHeight) * 100;
    xMax =
      (((i + 1) * (gapHeight + rowHeight) - 0.5 * gapHeight) / totalDivHeight) *
      100;
  } else {
    xMin = (i / len) * 100;
    xMax = ((i + 1) / len) * 100;
  }
  xMin = Math.max(0, xMin);
  xMax = Math.min(100, xMax);

  return {
    xMin,
    xMax,
  };
};

const findRowIndexForX = (
  x: number,
  len: number,
  rowHeight: number,
  gapHeight: number,
  totalDivHeight: number
) => {
  // Using for loop here to find the index of the row instead of math
  // for better readability
  for (let i = 0; i < len; i++) {
    const { xMin, xMax } = calcBoundsForRow(
      i,
      len,
      rowHeight,
      gapHeight,
      totalDivHeight
    );
    if (x >= xMin && x < xMax) {
      return i;
    }
  }
  return len - 1;
};

export const getUpdatedRowData = (
  segments: paper.Segment[],
  calcScoreOutput: CalcOutputFn,
  rowDataToEdit: RowRelativeScoreInfo[],
  rowHeight: number,
  gapHeight: number
) => {
  const totalDivHeight =
    rowDataToEdit.length * (rowHeight + gapHeight) - gapHeight;
  const baseRowSegmentX = segments[1] != null ? segments[1].point.x : undefined;
  const baseRowIdx =
    baseRowSegmentX != null
      ? findRowIndexForX(
          baseRowSegmentX,
          rowDataToEdit.length,
          rowHeight,
          gapHeight,
          totalDivHeight
        )
      : undefined;

  for (let i = 0; i < rowDataToEdit.length; i++) {
    // [0, 100] -> [-100, 100]
    const x = calcCoordinateForRow(
      i,
      rowDataToEdit,
      rowHeight,
      gapHeight,
      totalDivHeight
    );
    const percentage = calcScoreOutput(x) * 2 - 100;
    // Keeping actual value and display value consistent - both rounding to 1 decimal
    rowDataToEdit[i].percentage = roundToPrecision(percentage, 1);

    if (baseRowIdx != null) {
      rowDataToEdit[i].isBase = baseRowIdx === i;
    }
    if (baseRowIdx === i) {
      // Always override the base percentage to 0
      rowDataToEdit[i].percentage = 0;
    }
  }

  return [...rowDataToEdit];
};

export const ChangeRowScoreBody = ({
  rowList,
  initialSegments,
  calcScoreOutputRef,
  exportScorePathRef,
  rowDataToEdit,
  gapHeight,
  onUpdateRowScores,
  onResized,
}: {
  rowList: JSX.Element[];
  initialSegments: SerializedSegment[];
  calcScoreOutputRef: MutableRefObject<CalcOutputFn | undefined>;
  exportScorePathRef: MutableRefObject<(() => SerializedSegment[]) | undefined>;
  rowDataToEdit: RowRelativeScoreInfo[];
  gapHeight: number;
  onUpdateRowScores: (segments: paper.Segment[]) => void;
  onResized?: OnResizedFn;
}) => {
  return (
    <div
      className={styles.rowScoreGraphicContainer}
      style={{
        gap: `${gapHeight}px`,
      }}
    >
      {rowList}
      <div
        className={styles.rowScoreBezierOverlay}
        style={{
          width: `${GRAPH_WIDTH_PERCENTAGE}%`,
          left: `${50 - GRAPH_WIDTH_PERCENTAGE / 2}%`,
        }}
      >
        {rowDataToEdit.length > 1 && (
          <BezierGraph
            segments={initialSegments}
            minLabel=""
            maxLabel=""
            pathColor={PATH_COLOR}
            calcOutputRef={calcScoreOutputRef}
            exportPathRef={exportScorePathRef}
            fixedSegmentAmount
            hideAxes
            hideActionLabel
            horizontalGraph
            deltaTransform={(segment, localDelta) => {
              if (segment.isFirst() || segment.isLast()) {
                // First and last point should only move horizontally
                return new Point(0, localDelta.y);
              }
              // Middle point should only move vertically
              return new Point(localDelta.x, 0);
            }}
            onDragEnd={({ isPathValid, segments }) => {
              if (isPathValid) {
                onUpdateRowScores(segments);
              }
            }}
            onResized={onResized}
          />
        )}
      </div>
    </div>
  );
};
