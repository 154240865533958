import { useBulkUpdateAutoFulfillPermissions } from 'src/components/common/BulkActions/BulkUpdateAutoFulfillAction/useBulkUpdateAutoFulfillPermissions';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { useAnyMarketplaceHandledByProcessor } from 'src/hooks/useMarketplaceHandledByProcessor';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ContentId } from 'src/utils/constants/contentId';
import { ActionOutboxEntityType, Feature } from 'src/WebApiController';

export const LaunchBulkMarkAsFulfilled = ({
  onClick,
  disabled,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}) => {
  const { hasEditAutoFulfillPermissions } = useBulkUpdateAutoFulfillPermissions(
    {
      entityType: ActionOutboxEntityType.Sale,
    }
  );
  const hasMarkedAsFulfilled = useUserHasFeature(Feature.SaleMarkedAsFulfilled);
  const anyMkpHandledByProcessor = useAnyMarketplaceHandledByProcessor();

  if (
    !anyMkpHandledByProcessor ||
    !hasEditAutoFulfillPermissions ||
    !hasMarkedAsFulfilled
  ) {
    return null;
  }

  return (
    <PosDropdownItem
      key="MarkAsFulfilled"
      onClick={onClick}
      disabled={disabled}
    >
      <Content id={ContentId.MarkAsFulfilled} />
    </PosDropdownItem>
  );
};
