import { HubConnectionState } from '@microsoft/signalr';
import { isEqual } from 'lodash-es';
import { ComponentProps, useCallback, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Modal as RSModal } from 'reactstrap';
import { BulkEditStatus } from 'src/components/common/BulkActions/BulkEditStatus';
import { SellerAccountEmployeeSelector } from 'src/components/Selectors/SellerAccountEmployeeSelector';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import {
  BulkEditStage,
  useBulkEditHubContext,
} from 'src/contexts/BulkEditHubContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content, useContent } from 'src/contexts/ContentContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { usePurchaseDataContext } from 'src/contexts/PurchaseDataContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosFormField } from 'src/core/POS/PosFormField';
import { Stack } from 'src/core/ui';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { useBulkEditHub } from 'src/hooks/useBulkEditHub';
import { DetailSection } from 'src/modals/common';
import { ContentId } from 'src/utils/constants/contentId';
import { posChangedField } from 'src/utils/posFieldUtils';
import { PurchaseOrderDetailsInput } from 'src/utils/purchaseUtils';
import {
  ActionOutboxEntityType,
  BulkActionType,
  BulkEditPreviewWithDetails,
  BulkEditProgress,
  BulkEditStep,
  DateTimeRange,
  PurchaseOrderDetails,
} from 'src/WebApiController';

import { BulkEditFooter } from '../common/BulkEditFooter';
import { BulkEditHeader } from '../common/BulkEditHeader';

export type BulkEditPurchaseSettingsDialogProps = ComponentProps<
  typeof RSModal
> & {
  updateKey: string;
  monthYear?: DateTimeRange;
  onOkay: (
    purchaseInput: PurchaseOrderDetailsInput | null,
    supportBackgroundProcess?: boolean,
    onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
    preview?: BulkEditPreviewWithDetails
  ) => void;
  onCancel: () => void;
};

export const BulkEditPurchaseSettingsDialog = ({
  purchases,
  ...rest
}: BulkEditPurchaseSettingsDialogProps) => {
  const methods = useForm<PurchaseOrderDetailsInput>();

  return (
    <FormProvider {...methods}>
      <BulkEditPurchaseSettingsDialogContent
        purchases={purchases}
        {...rest}
        {...methods}
      />
    </FormProvider>
  );
};

function BulkEditPurchaseSettingsDialogContent({
  updateKey,
  onOkay,
  onCancel,
  handleSubmit,
  watch,
  reset,
  setValue,
  formState,
  ...rest
}: BulkEditPurchaseSettingsDialogProps &
  Omit<
    ComponentProps<typeof FormProvider<PurchaseOrderDetailsInput, unknown>>,
    'children'
  >) {
  const { setActivePosEntity } =
    useActivePosEntityContext<PurchaseOrderDetails>();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const { refreshData } = usePurchaseDataContext();
  const { setSelectionMode } = useMultiSelectionContext();
  const { setProgress, setPreview, setMainDialogOpened } =
    useBulkEditHubContext();
  const [isLoading, setIsLoading] = useState(false);
  const dontChange = useContent(ContentId.DontChange);
  const buyerUserId = watch('buyerUserId');

  const input = watch();

  const onClose = useCallback(
    async (
      newProgress?: BulkEditProgress,
      closeMainDialogForPopover?: boolean
    ) => {
      if (newProgress?.step === BulkEditStep.Done) {
        setIsRefreshing(true);
        setActivePosEntity(0);

        if (refreshData) {
          await refreshData(true);
        }
        if (refreshExpandedListItems) {
          await refreshExpandedListItems();
        }
        setSelectionMode(undefined);
        if (closeMainDialogForPopover) {
          setMainDialogOpened(false);
        } else {
          setProgress(undefined);
          setPreview(undefined);
        }
        setIsRefreshing(false);
        // progress kept to allow the BulkEditStatusPopover to show the result
      }
      onCancel();
    },
    [
      onCancel,
      refreshData,
      refreshExpandedListItems,
      setActivePosEntity,
      setMainDialogOpened,
      setPreview,
      setProgress,
      setSelectionMode,
    ]
  );

  const onBulkEditDone = useCallback(
    async (doneProgress: BulkEditProgress, finalErrors: string[]) => {
      if (finalErrors.length === 0 && doneProgress.step === BulkEditStep.Done) {
        onClose(doneProgress, true);
      }
    },
    [onClose]
  );

  const { bulkEditHub, progress, preview, initJob, stage } = useBulkEditHub(
    ActionOutboxEntityType.Purchase,
    BulkActionType.UpdateGeneralSettings,
    updateKey,
    onBulkEditDone
  );

  const hasBackgroundBulkEditFeature =
    bulkEditHub?.state === HubConnectionState.Connected;

  const alertDialog = useBasicDialog();

  const onSubmitHandler = useCallback(
    async (
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void
    ) => {
      if (onPreviewReceived) {
        setIsLoading(true);
        await onOkay(null, false, onPreviewReceived);
        setIsLoading(false);
      } else {
        handleSubmit((i) =>
          onOkay(i, hasBackgroundBulkEditFeature, undefined, preview)
        )();
      }
    },
    [onOkay, handleSubmit, preview, hasBackgroundBulkEditFeature]
  );

  const [isRefreshing, setIsRefreshing] = useState(false);

  const isInputValid = useMemo(() => {
    return !isEqual(input, formState.defaultValues ?? {});
  }, [formState.defaultValues, input]);

  const submittButtonRef = useRef<HTMLButtonElement>(null);

  return (
    <GenericDialog
      {...rest}
      size={stage === BulkEditStage.Preview ? 'xl' : 'md'}
      // Now only purchased by is supported. Use ContentId.PurchaseSettings when multiple settings are supported
      header={
        <BulkEditHeader
          headerText={<Content id={ContentId.UpdatePurchasedBy} />}
        />
      }
      onOpened={() => {
        reset();
        initJob();
      }}
      onKeyUp={(e) => {
        if (submittButtonRef.current && e.key === 'Enter' && isInputValid) {
          submittButtonRef.current.click();
        }
      }}
      onClosed={() => {
        setMainDialogOpened(false);
        if (progress) {
          setSelectionMode(undefined);
        }
        // Call the outside one if there is one
        rest.onClosed?.();
      }}
      footer={
        <BulkEditFooter
          entityType={ActionOutboxEntityType.Purchase}
          isLoading={isLoading}
          hasBackgroundBulkEditFeature={hasBackgroundBulkEditFeature}
          onClose={onClose}
          onSubmit={onSubmitHandler}
          disabled={!isInputValid}
          submittButtonRef={submittButtonRef}
        />
      }
      onCancel={isLoading ? undefined : onCancel}
    >
      <BulkEditStatus
        entityType={ActionOutboxEntityType.Purchase}
        isLoading={isRefreshing || isLoading}
        updateKey={updateKey}
      >
        <Stack direction="column" width="full" gap="s">
          <DetailSection name={<Content id={ContentId.WhenWhereWho} />}>
            <PosFormField label={<Content id={ContentId.PurchasedBy} />}>
              <SellerAccountEmployeeSelector
                value={buyerUserId?.value ?? undefined}
                placeholderText={dontChange}
                enableEmptySelection
                onChange={(newId: string) => {
                  if (newId !== buyerUserId?.value) {
                    setValue('buyerUserId', posChangedField(newId));
                  }
                }}
                style={{ width: '100%' }}
              />
            </PosFormField>
          </DetailSection>
        </Stack>
      </BulkEditStatus>
    </GenericDialog>
  );
}
