import clsx from 'clsx';
import { isEqual, range } from 'lodash-es';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { MinRowsForUsingVirtuoso } from 'src/components/Accordions';
import {
  Content,
  getContent,
  useContent,
  useContentContext,
} from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { getTextFieldState, PosTextField } from 'src/core/POS/PosTextField';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { ShuffleIcon } from 'src/svgs/ShuffleIcon';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import {
  Listing,
  ListingGroupItemInput,
  ListingGroupUndercutSetting,
} from 'src/WebApiController';
import { Feature } from 'src/WebApiController';

import * as styles from '../GroupListings.css';
import { flattenListingGroup, getQuantitiesOptions } from './groupingUtils';
import {
  useGroupItemTableOperations,
  useMergeListingGroupInputForm,
} from './ListingGroupInput.hook';
import { ListingGroupInputTable } from './ListingGroupInputTable';
import { IListingGroupItemWithMeta } from './ListingGroupTableColumnsConfig';

export const ListingGroupInput = ({
  mergeListingGroupInputIndex,
  listings,
  style,
}: {
  listings: Listing[];
  mergeListingGroupInputIndex: number;
  style?: React.CSSProperties;
}) => {
  const { getUiCurrency } = useLocalizationContext();
  const firstListing = useMemo(() => {
    if (listings.length === 0) {
      return undefined;
    }
    return listings[0];
  }, [listings]);
  const currency = getUiCurrency(firstListing?.currency);
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  const hasGroupingDripFeature = useUserHasFeature(Feature.GroupingDrip);

  const contentContext = useContentContext();
  const requiredMsg = useContent(ContentId.Required);

  const {
    listingsInGroupInput,
    listingGroupNameError,
    deprioritizedQuantities: inferredDeprioritizedQuantities, // default to event settings value
    onDeleteGroup,
    onSetValues,
    onRegister,
  } = useMergeListingGroupInputForm(mergeListingGroupInputIndex);
  const {
    groupUndercutSetting,
    listingGroupItems,
    desiredActiveListings,
    minActiveListings,
    maxActiveListings,
    targetPercentage,
    deprioritizedQuantities,
  } = listingsInGroupInput ?? {};
  const { undAbsAmt = null, undRelAmt = null } =
    groupUndercutSetting ?? ({} as ListingGroupUndercutSetting);

  const {
    sorting,
    assignNewPriority,
    onSortingChange,
    onRandomizeRanks,
    onUpdateDeprioritizedQuantities,
    removeListingFromInput,
  } = useGroupItemTableOperations(
    listings,
    listingGroupItems,
    inferredDeprioritizedQuantities,
    onSetValues
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setUndercutAmount = useCallback(
    (
      listingId: number,
      undercutAbsoluteAmount?: number | null,
      undercutRelativeAmount?: number | null
    ) => {
      const item = listingGroupItems.findLast((l) => l.listingId === listingId);
      if (
        !!item &&
        (undercutAbsoluteAmount != null || undercutRelativeAmount != null)
      ) {
        item.undercutSetting = {
          undAbsAmt: undercutAbsoluteAmount ?? null,
          undRelAmt: undercutRelativeAmount ?? null,
          actRankUndAbsAmt: null,
          actRankUndRelAmt: null,
        };

        onSetValues(`listingGroupItems`, [...listingGroupItems]);
      }
    },
    [listingGroupItems, onSetValues]
  );

  const data = useMemo(() => {
    if (listingGroupItems == null) return;

    const itemsSize = listingGroupItems.filter((l) => l.listingId > 0).length;
    const priorityOptions = range(itemsSize).reduce(
      (r, i) => {
        r[(i + 1).toString()] = (i + 1).toString();
        return r;
      },
      {} as Record<string, string>
    );

    const listingsInGroupMap = listingGroupItems.reduce(
      (r, l) => {
        r[l.listingId] = l;
        return r;
      },
      {} as Record<number, ListingGroupItemInput>
    );

    return listings
      ?.flatMap(flattenListingGroup)
      ?.filter((l) => listingsInGroupMap[l.id])
      ?.sort(
        (a, b) =>
          listingsInGroupMap[a.id].priority - listingsInGroupMap[b.id].priority
      )
      ?.map<IListingGroupItemWithMeta>((l, i) => {
        return {
          ...l,
          rank: i,
          assignNewPriority,
          setUndercutAmount,
          priorityOptions,
          removeListingFromInput,
          ltGrpUndercut: {
            undAbsAmt: null,
            undRelAmt: null,
            actRankUndAbsAmt: null,
            actRankUndRelAmt: null,
          },
        };
      });
  }, [
    assignNewPriority,
    listings,
    listingGroupItems,
    removeListingFromInput,
    setUndercutAmount,
  ]);

  const useVirtuoso = data && data.length > MinRowsForUsingVirtuoso;

  // This only happens when the container is virtuoso and the data is not ready
  if (!listingsInGroupInput || !data) {
    return null;
  }

  return (
    <Stack direction="column" gap="l" style={style}>
      <Stack direction="row" gap="l" alignItems="center">
        <PosFormField
          label={<Content id={ContentId.Name} />}
          errors={listingGroupNameError}
          style={{ flex: '1' }}
        >
          <PosTextField
            rootProps={{
              state: getTextFieldState(listingGroupNameError),
            }}
            {...onRegister(`name`, { required: requiredMsg })}
          />
        </PosFormField>

        {hasAutoPricingFeature ? (
          <PosFormField
            label={<Content id={ContentId.RankPremium} />}
            style={{ flex: '0.5' }}
          >
            <PosCurrencyField
              rootProps={{
                style: { width: '100px' },
              }}
              uiCurrency={currency}
              {...onRegister(`undAbsAmt`, {
                valueAsNumber: true,
                setValueAs: (v) => (v ? parseFloat(v) : null),
              })}
              value={undAbsAmt ?? undefined}
            />

            <PosTextField
              rootProps={{
                style: { width: '100px' },
              }}
              type="number"
              postfixDisplay="%"
              onChange={(e) => {
                const v = parseFloat(e.target.value);
                let relativeValue = null;
                if (v >= 0 && v <= Number.MAX_VALUE) {
                  relativeValue = Math.min(v, 100) / 100;
                }
                onSetValues(`undRelAmt`, relativeValue);
              }}
              value={
                undRelAmt != null
                  ? roundToPrecision(Math.abs(undRelAmt) * 100, 8)
                  : ''
              }
            />
          </PosFormField>
        ) : null}
        {hasGroupingDripFeature == false && (
          <PosFormField
            label={<Content id={ContentId.DesiredActiveListings} />}
            style={{ width: '155px' }}
          >
            <PosTextField
              value={desiredActiveListings ?? '0'}
              type="number"
              min={0}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const v = parseInt(e.target.value);
                let desiredActiveListings = null;
                if (v >= 0) {
                  desiredActiveListings = Math.min(v, listingGroupItems.length);
                }
                onSetValues(`desiredActiveListings`, desiredActiveListings);
              }}
            />
          </PosFormField>
        )}

        <PosFormField
          label={<Content id={ContentId.QuantitiesForcedToBottom} />}
          style={{ width: '190px' }}
        >
          <PosMultiSelect
            align="start"
            triggerProps={{ style: { width: '100%' } }}
            values={deprioritizedQuantities ?? []}
            onChange={(newValues) => {
              if (!isEqual(newValues, deprioritizedQuantities)) {
                onUpdateDeprioritizedQuantities(newValues);
              }
            }}
            valueOptionsContent={getQuantitiesOptions()}
          />
        </PosFormField>

        <PosFormField label={'Actions'} style={{ width: 'auto' }}>
          <Stack gap="m" alignItems="center" style={{ height: 40 }}>
            <ShuffleIcon
              onClick={onRandomizeRanks}
              title={getContent(ContentId.ShuffleRanks, contentContext)}
              fill={vars.color.textPrimary}
              withHoverEffect
            />
            <CrossIcon
              onClick={onDeleteGroup}
              withHoverEffect
              title={getContent(ContentId.Delete, contentContext)}
            />
          </Stack>
        </PosFormField>
      </Stack>
      {hasGroupingDripFeature && (
        <Stack direction="row" gap="l" alignItems="center">
          <PosFormField
            label={<Content id={ContentId.MinActiveListings} />}
            style={{ width: '155px' }}
          >
            <PosTextField
              value={minActiveListings ?? '0'}
              type="number"
              min={0}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const v = parseInt(e.target.value);
                if (!isNaN(v)) {
                  if (v > (maxActiveListings ?? 0)) {
                    onSetValues(`maxActiveListings`, v);
                  }

                  onSetValues(`minActiveListings`, v);
                }
              }}
            />
          </PosFormField>
          <PosFormField
            label={<Content id={ContentId.MaxActiveListings} />}
            style={{ width: '155px' }}
          >
            <PosTextField
              value={maxActiveListings ?? '0'}
              type="number"
              min={0}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const v = parseInt(e.target.value);
                if (!isNaN(v)) {
                  if (v < (minActiveListings ?? 0)) {
                    onSetValues(`minActiveListings`, v);
                  }
                  onSetValues(`maxActiveListings`, v);
                }
              }}
            />
          </PosFormField>
          <PosFormField
            label={<Content id={ContentId.TargetPercentage} />}
            style={{ flex: '0.5' }}
          >
            <PosTextField
              rootProps={{
                style: { width: '100px' },
              }}
              type="number"
              postfixDisplay="%"
              onChange={(e) => {
                const v = parseFloat(e.target.value);
                let target = null;
                if (v >= 0 && v <= Number.MAX_VALUE) {
                  target = Math.min(v, 100) / 100;
                }
                onSetValues(`targetPercentage`, target);
              }}
              value={
                targetPercentage != null
                  ? roundToPrecision(Math.abs(targetPercentage) * 100, 8)
                  : ''
              }
            />
          </PosFormField>
        </Stack>
      )}
      <div
        className={clsx(styles.tableContainer, {
          [styles.tableContainerVirtuoso]: useVirtuoso,
        })}
      >
        <ListingGroupInputTable
          data={data}
          sorting={sorting}
          onSortingChange={onSortingChange}
          useVirtuoso={useVirtuoso}
        />
      </div>
    </Stack>
  );
};
