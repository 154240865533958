import { Content } from 'src/contexts/ContentContext';
import { TableHeaderCellDiv } from 'src/core/ui/TableHeaderCellDiv';
import { ListingReportTableColumnId } from 'src/utils/columns/inventory/inventoryColumnUtils.types';
import { SalesReportTableColumnId } from 'src/utils/columns/sales/salesColumnUtils.types';
import {
  LISTING_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID,
  SALE_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID,
} from 'src/utils/constants/contentIdMaps';
import { SectionType } from 'src/utils/types/sectionType';

export const TableHeader = ({
  column: { id: columnId },
  sectionType,
  align = 'left',
}: {
  column: { id: string };
  sectionType: SectionType.SalesReport | SectionType.ListingsReport;
  align: 'left' | 'right' | 'center';
}) => {
  const contentId =
    sectionType === SectionType.SalesReport
      ? SALE_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID[
          columnId as SalesReportTableColumnId
        ]
      : LISTING_REPORT_TABLE_COLUMN_ID_TO_CONTENT_ID[
          columnId as ListingReportTableColumnId
        ];
  return (
    <TableHeaderCellDiv align={align}>
      <Content id={contentId} />
    </TableHeaderCellDiv>
  );
};
