import { isEqual } from 'lodash-es';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Content } from 'src/contexts/ContentContext';
import { Button, Stack } from 'src/core/ui';
import { CrossIcon, ProfileOutlineIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { SellerRoleInput, SellerUserAccountInfo } from 'src/WebApiController';

export const SellerUserDisplay = ({
  user,
  userIds,
  disabled,
}: {
  user: SellerUserAccountInfo;
  userIds: string[] | null;
  disabled?: boolean;
}) => {
  const { setValue } = useFormContext<SellerRoleInput>();

  const onRemoveUserFromRole = useCallback(() => {
    if (!userIds)
      // nothing to remove from
      return;

    const newUserIds = userIds.filter((uid) => uid !== user.userId);
    if (!isEqual(newUserIds.sort(), userIds.sort())) {
      setValue('userIds', newUserIds);
    }
  }, [setValue, user.userId, userIds]);

  return (
    <Stack
      gap="l"
      alignItems="center"
      justifyContent="spaceBetween"
      width="full"
    >
      <Stack gap="m" alignItems="center">
        <ProfileOutlineIcon />
        {user.displayName} ({user.email})
      </Stack>

      <Button variant="text" onClick={onRemoveUserFromRole} disabled={disabled}>
        <Stack gap="m" alignItems="center">
          <Content id={ContentId.RemoveAccess} />
          <CrossIcon withHoverEffect />
        </Stack>
      </Button>
    </Stack>
  );
};
