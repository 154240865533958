import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { BulkRemovePredeliveryDialog } from 'src/dialogs/BulkEdits/BulkRemovePredeliveryDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_REMOVE_PREDELIVERY_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchRemovePredelivery } from './LaunchRemovePredelivery';

export const useBulkRemovePredelivery = (
  filterQueryWithEventIds: ListingQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setSelectionMode } = useMultiSelectionContext();

  const updateKey = event
    ? `inventory-predelivery-event-${event.viagVirtualId}`
    : INVENTORY_BULK_REMOVE_PREDELIVERY_UPDATE_KEY;

  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkRemovePredeliveryDialog,
    closeDialog: closeBulkRemovePredeliveryDialog,
  } = useDialog(DialogId.BulkRemovePredilivery, BulkRemovePredeliveryDialog);

  const onSubmit = useCallback(
    async (
      removeBarcodes: boolean,
      removePdfs: boolean,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkRemovePredeliveryPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkRemovePredeliveryPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkRemovePredelivery(
              preview!.preview,
              removeBarcodes,
              removePdfs,
              updateKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                setSelectionMode();
                await refreshExpandedListItems();
              }

              closeBulkRemovePredeliveryDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkRemovePredelivery', error, {
              trackErrorData: {
                preview,
                removeBarcodes,
                removePdfs,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkRemovePredeliveryDialog,
      filterQueryWithEventIds,
      refreshExpandedListItems,
      setIsLoading,
      setSelectionMode,
      showErrorDialog,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchRemovePredelivery
        key="removePredeliveredArtifacts"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkRemovePredeliveryDialog({
            updateKey,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkRemovePredeliveryDialog,
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
