import { ReactNode } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { vars } from 'src/core/themes';
import { CrossIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { Stack } from '../Stack';
import * as styles from './PillList.css';
export type PillItemProps = {
  value: string;
  display: ReactNode | ContentId;
  icon?: React.ReactElement;
  disabled?: boolean;
  onPillHover?: (e: React.MouseEvent<HTMLElement>) => void;
  onPillLeave?: (e: React.MouseEvent<HTMLElement>) => void;
  onPillClick?: (id: number, e: React.MouseEvent<HTMLElement>) => void;
  onDelete?: () => void;
  postfixItem?: React.ReactNode;
  customPillContainer?: string;
};

export const PillItem = ({
  value,
  display,
  icon,
  disabled,
  onPillHover,
  onPillLeave,
  onPillClick,
  onDelete,
  postfixItem,
  customPillContainer,
}: PillItemProps) => {
  return (
    <div
      className={customPillContainer ?? styles.pillItemContainer}
      key={value}
      title={typeof display === 'string' ? display : undefined}
      onMouseEnter={onPillHover}
      onMouseLeave={onPillLeave}
      onClick={(e) => onPillClick && onPillClick(parseInt(value ?? '0'), e)}
    >
      <Stack
        direction="row"
        gap="m"
        alignItems="center"
        className={styles.pillItemText}
      >
        <Stack alignItems="center">{icon}</Stack>
        <Content id={typeof display === 'string' ? display : undefined} />
        {postfixItem}
      </Stack>
      {!disabled && (
        <div className={styles.pillDeleteButtonContaienr}>
          <CrossIcon
            withHoverEffect
            onClick={onDelete}
            size={vars.iconSize.s}
          />
        </div>
      )}
    </div>
  );
};
