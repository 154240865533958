import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function AisleSeatIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M9.015 16.406l-1.866 4.85H22l-4.328-11.193h-2.24V8.57H13.94v1.493h-.746V6.33A3.742 3.742 0 009.463 2.6H7.97A3.742 3.742 0 004.24 6.331v3.732h-.746V8.57H2V12.301c0 .97.634 1.792 1.493 2.09v2.388h1.492V14.54h7.463v2.24h1.492V14.39a2.223 2.223 0 001.493-2.09v-.746h1.231l3.172 8.21H9.313l1.68-4.366-1.978 1.007zM5.73 6.331a2.245 2.245 0 012.24-2.238h1.492A2.245 2.245 0 0111.7 6.33v3.732h-5.97V6.33zm8.21 5.97c0 .41-.337.747-.747.747H4.239a.748.748 0 01-.746-.747v-.746H13.94v.746z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
