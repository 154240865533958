import { PaperScope } from 'paper';

const TEXT_COLOR = '#677383';
const AXIS_COLOR = '#B1BAC2';
const PATH_COLOR = '#3F1D75';
const INVALID_COLOR = 'red';

type HitResultType = 'handle-in' | 'handle-out' | 'segment' | 'stroke';

function isHitResultType(type: string): type is HitResultType {
  return (
    type === 'handle-in' ||
    type === 'handle-out' ||
    type === 'segment' ||
    type === 'stroke'
  );
}

/**
 * Library agnostic point representation. This is used for serializing the bezier path.
 */
export type SerializedPoint = {
  x: number;
  y: number;
};

/**
 * Library agnostic segment representation. This is used for serializing the bezier path.
 */
export type SerializedSegment = {
  point: SerializedPoint;
  handleIn?: SerializedPoint;
  handleOut?: SerializedPoint;
};

export type CalcOutputFn = (input: number) => number;

export class BezierGraphManager {
  spacing = 8;
  handleSize = 8;
  labelHeight = 12;
  hitTestOptions = {
    selected: true,
    segments: true,
    handles: true,
    stroke: true,
    tolerance: 4,
  };

  scope: paper.PaperScope;
  /**
   * The bounds of the plot relative to the plot.
   *
   * This will be at position (0, 0) with dimensions (100, 100). The values relative to the plot can
   * be thought of as the percentage of each dimension from 0 to 100.
   */
  localPlotBounds: paper.Rectangle;

  /**
   * Label for the action the user is about to perform.
   *
   * eg. add segment, delete segment
   */
  actionLabel: paper.PointText;
  verticalAxisLabel: paper.PointText;
  minLabel: paper.PointText;
  maxLabel: paper.PointText;
  midLabel: paper.PointText;
  fixedSegmentAmount: boolean;
  hideAxes: boolean;
  hideActionLabel: boolean;
  horizontalGraph: boolean;
  pathColor: string;
  deltaTransform:
    | undefined
    | ((segment: paper.Segment, delta: paper.Point) => paper.Point);
  axes: paper.Path;

  path: paper.Path;
  /**
   * Used to get the intersection with the `path` in order to get the y value for a given x.
   */
  verticalIntersector: paper.Path;
  plot: paper.Group;

  constructor(
    canvas: HTMLCanvasElement,
    {
      minLabel,
      maxLabel,
      midLabel,
    }: {
      minLabel: string;
      maxLabel: string;
      midLabel?: string;
    },
    fixedSegmentAmount: boolean,
    hideAxes: boolean,
    hideActionLabel: boolean,
    horizontalGraph: boolean,
    pathColor?: string,
    deltaTransform?: (
      segment: paper.Segment,
      delta: paper.Point
    ) => paper.Point,
    canManageControlPoints?: boolean
  ) {
    this.scope = new PaperScope();
    this.scope.setup(canvas);
    this.scope.settings.handleSize = this.handleSize;
    this.fixedSegmentAmount = fixedSegmentAmount;
    this.hideAxes = hideAxes;
    this.hideActionLabel = hideActionLabel;
    this.horizontalGraph = horizontalGraph;
    this.deltaTransform = deltaTransform;
    this.pathColor = pathColor ?? PATH_COLOR;

    new this.scope.Tool();
    const {
      Color,
      Group,
      Path,
      Point,
      PointText,
      Rectangle,
      Segment,
      project,
      view,
      tool,
    } = this.scope;

    project.currentStyle.fontFamily = 'Inter';
    project.currentStyle.fontSize = 12;
    project.currentStyle.fontWeight = 500;

    this.localPlotBounds = new Rectangle(0, 0, 100, 100);

    // initialize graph elements
    (() => {
      this.actionLabel = new PointText(new Point(0, 0));
      this.actionLabel.fillColor = new Color(TEXT_COLOR);

      this.verticalAxisLabel = new PointText(new Point(0, 0));
      this.verticalAxisLabel.rotation = -90;
      this.verticalAxisLabel.fillColor = new Color(TEXT_COLOR);
      this.verticalAxisLabel.content = 'VALUE';

      this.minLabel = new PointText(new Point(0, 0));
      this.minLabel.fillColor = new Color(TEXT_COLOR);
      this.minLabel.content = minLabel;

      this.maxLabel = new PointText(new Point(0, 0));
      this.maxLabel.fillColor = new Color(TEXT_COLOR);
      this.maxLabel.content = maxLabel;

      this.midLabel = new PointText(new Point(0, 0));
      this.midLabel.fillColor = new Color(TEXT_COLOR);
      this.midLabel.content = midLabel ?? '';

      this.axes = new Path();
      this.axes.strokeColor = new Color(AXIS_COLOR);
    })();

    // initialize path
    (() => {
      this.path = new Path();
      this.path.selectedColor = new Color(this.pathColor);
      this.path.strokeColor = new Color(this.pathColor);
      this.path.strokeWidth = 2;
      this.path.strokeScaling = false;

      this.verticalIntersector = new Path([
        new Segment(this.localPlotBounds.topLeft),
        new Segment(this.localPlotBounds.bottomLeft),
      ]);
      this.verticalIntersector.visible = false;

      this.plot = new Group([this.path, this.verticalIntersector]);
      this.plot.applyMatrix = false;
    })();

    let prevMouseMoveEv: paper.MouseEvent | undefined;
    let hitType: HitResultType | undefined;
    let segment: paper.Segment | undefined;
    let startMouseDownPoint: paper.Point | undefined;
    let startSegmentPoint: paper.Point | undefined;
    let startSegmentHandleIn: paper.Point | undefined;
    let startSegmentHandleOut: paper.Point | undefined;

    tool.onKeyDown = tool.onKeyUp = (ev: paper.ToolEvent) => {
      if (prevMouseMoveEv) {
        // simulate mouse move event with the modifiers changed
        view.onMouseMove?.({ ...prevMouseMoveEv, modifiers: ev.modifiers });
      }
    };

    view.onMouseMove = view.onMouseDown = (ev: paper.MouseEvent) => {
      if (ev.type === 'mousemove') {
        prevMouseMoveEv = ev;
        this.setActionLabel('');
      }
      const hitResult = project.hitTest(ev.point, this.hitTestOptions);
      if (!hitResult || !isHitResultType(hitResult.type)) return;

      if (ev.modifiers.shift) {
        const segment = hitResult.segment;
        if (!this.fixedSegmentAmount) {
          switch (hitResult.type) {
            case 'segment':
              // can't delete first or last segment
              if (segment.isFirst() || segment.isLast()) break;

              if (ev.type === 'mousemove') {
                this.setActionLabel('Delete');
              } else if (ev.type === 'mousedown') {
                segment.remove();
              }
              break;
            case 'handle-in':
              if (ev.type === 'mousemove') {
                this.setActionLabel('Delete');
              } else if (ev.type === 'mousedown') {
                segment.handleIn = new Point(0, 0);
              }
              break;
            case 'handle-out':
              if (ev.type === 'mousemove') {
                this.setActionLabel('Delete');
              } else if (ev.type === 'mousedown') {
                segment.handleOut = new Point(0, 0);
              }
              break;
          }
        }
        return;
      }

      switch (hitResult.type) {
        case 'stroke':
          if (canManageControlPoints === true && !this.fixedSegmentAmount) {
            if (ev.type === 'mousemove') {
              this.setActionLabel('Add');
            } else if (ev.type === 'mousedown') {
              hitType = 'segment';
              const localEvPoint = this.plot.globalToLocal(ev.point);

              segment = this.path.insert(
                hitResult.location.index + 1,
                localEvPoint
              );
              segment.selected = true;
              this.path.smooth({
                type: 'catmull-rom',
                factor: 0.5,
                from: segment.previous,
                to: segment.next,
              });
            }
          }
          break;
        case 'segment':
        case 'handle-in':
        case 'handle-out':
          if (ev.type === 'mousemove') {
            this.setActionLabel('Move');
          } else if (ev.type === 'mousedown') {
            hitType = hitResult.type;
            segment = hitResult.segment;
            startMouseDownPoint = ev.point.clone();
            startSegmentPoint = segment.point.clone();
            startSegmentHandleIn = segment.handleIn.clone();
            startSegmentHandleOut = segment.handleOut.clone();
          }
          break;
      }
    };

    view.onMouseDrag = (ev: paper.MouseEvent) => {
      if (!segment || !startMouseDownPoint) return;

      const localEvPoint = this.plot.globalToLocal(ev.point);
      const localStartMouseDownPoint =
        this.plot.globalToLocal(startMouseDownPoint);
      const localDelta = localEvPoint.subtract(localStartMouseDownPoint);
      switch (hitType) {
        case 'segment': {
          if (!startSegmentPoint) break;

          let pointDelta;
          if (this.deltaTransform != null) {
            pointDelta = this.deltaTransform(segment, localDelta);
          } else {
            pointDelta =
              segment.isFirst() || segment.isLast()
                ? new Point(0, localDelta.y)
                : localDelta;
          }

          const newPoint = startSegmentPoint.add(pointDelta);
          segment.point = newPoint;
          segment.point.x = Math.min(
            Math.max(
              this.localPlotBounds.left,
              newPoint.x,
              ...(segment.previous
                ? [
                    segment.previous.point.x - segment.handleIn.x,
                    segment.previous.point.x + segment.previous.handleOut.x,
                  ]
                : [])
            ),
            this.localPlotBounds.right,
            ...(segment.next
              ? [
                  segment.next.point.x - segment.handleOut.x,
                  segment.next.point.x + segment.next.handleIn.x,
                ]
              : [])
          );
          segment.point.y = Math.min(
            Math.max(this.localPlotBounds.top, newPoint.y),
            this.localPlotBounds.bottom
          );
          break;
        }
        case 'handle-in': {
          if (!startSegmentHandleIn) break;

          const newPoint = startSegmentHandleIn.add(localDelta);
          const minX = segment.previous
            ? segment.previous.point.x - segment.point.x
            : Number.NEGATIVE_INFINITY;
          segment.handleIn.x = Math.min(Math.max(minX, newPoint.x), 0);
          segment.handleIn.y = newPoint.y;
          break;
        }
        case 'handle-out': {
          if (!startSegmentHandleOut) break;

          const newPoint = startSegmentHandleOut.add(localDelta);
          const maxX = segment.next
            ? segment.next.point.x - segment.point.x
            : Number.POSITIVE_INFINITY;
          segment.handleOut.x = Math.min(Math.max(0, newPoint.x), maxX);
          segment.handleOut.y = newPoint.y;
          break;
        }
      }

      if (!this.isPathValid()) {
        this.path.selectedColor = new Color(INVALID_COLOR);
        this.path.strokeColor = new Color(INVALID_COLOR);
      } else {
        this.path.selectedColor = new Color(this.pathColor);
        this.path.strokeColor = new Color(this.pathColor);
      }
    };

    view.onMouseUp = () => {
      prevMouseMoveEv = undefined;
      hitType = undefined;
      segment = undefined;
      startMouseDownPoint = undefined;
      startSegmentPoint = undefined;
      startSegmentHandleIn = undefined;
      startSegmentHandleOut = undefined;
    };

    view.onResize = (_ev: { size: paper.Size; delta: paper.Size }) => {
      this.onResize(_ev.size.width, _ev.size.height);
    };
    this.draw();
  }

  getViewSize() {
    return this.scope.view.viewSize;
  }

  getSegments() {
    return this.path.segments;
  }

  onResize(w: number, h: number) {
    const { view } = this.scope;
    view.viewSize.width = w;
    view.viewSize.height = h;
    this.draw();
  }

  private calcScreenCoordOutput(input: number) {
    // just return the output values for the ends in case the intersection algorithm doesn't work correctly
    if (input <= this.path.firstSegment.point.x) {
      return this.path.firstSegment.point.y;
    }
    if (input >= this.path.lastSegment.point.x) {
      return this.path.lastSegment.point.y;
    }

    this.verticalIntersector.position.x = input;
    const intersections = this.verticalIntersector.getIntersections(this.path);
    if (intersections.length !== 1) {
      throw new Error(
        `${intersections.length} intersection(s) found. Number of vertical insersections should be exactly 1.`
      );
    }
    return intersections[0].point.y;
  }

  /**
   * Treat `path` as a function on a cartesian plane and calculate the output value for a given
   * input value.
   * @param input Input value between 0 and 100 inclusive
   * @returns Output value between 0 and 100 inclusive
   */
  calcOutput(input: number) {
    // convert from screen coordinates to cartesian coordinates
    return 100 - this.calcScreenCoordOutput(input);
  }

  setActionLabel(text: string) {
    this.actionLabel.content = this.hideActionLabel ? '' : text;
    this.drawActionLabel();
  }

  setLabels({ minLabel, maxLabel }: { minLabel: string; maxLabel: string }) {
    this.minLabel.content = minLabel;
    this.maxLabel.content = maxLabel;
    this.drawLabels();
  }

  /**
   * Path is set on a 100x100 plane with screen coordinates (coords start from the top left).
   * @param inputSegments
   */
  setPath(inputSegments: SerializedSegment[]) {
    const { Segment } = this.scope;

    this.path.removeSegments();
    inputSegments.forEach(({ point, handleIn, handleOut }) => {
      this.path.add(new Segment(point, handleIn, handleOut));
    });
    this.path.fullySelected = true;
  }

  /**
   * Converts path segments into a form that can be serialized more easily and restored from.
   * @returns Array of `SerializedSegment`
   */
  exportPath(): SerializedSegment[] {
    return this.path.segments.map((segment) => {
      const { point, handleIn, handleOut } = segment;
      const serializedSegment: SerializedSegment = {
        point: { x: point.x, y: point.y },
      };
      if (segment.hasHandles()) {
        serializedSegment.handleIn = {
          x: handleIn.x,
          y: handleIn.y,
        };
        serializedSegment.handleOut = {
          x: handleOut.x,
          y: handleOut.y,
        };
      }
      return serializedSegment;
    });
  }

  /**
   * @param callback
   * @returns Handler function that can be passed in to `removeMouseUpEventListener`.
   */
  addMouseUpEventListener(
    callback: (
      ev: paper.MouseEvent,
      customEv: {
        calcOutput: CalcOutputFn;
        isPathValid: boolean;
        segments: paper.Segment[];
      }
    ) => void
  ) {
    const handler = (ev: paper.MouseEvent) => {
      callback(ev, {
        calcOutput: (input: number) => this.calcOutput(input),
        isPathValid: this.isPathValid(),
        segments: this.path.segments,
      });
    };
    this.scope.view.on('mouseup', handler);
    return handler;
  }

  /**
   * @param handler Pass in the return of `addMouseUpEventListener` in order to remove the listener.
   */
  removeMouseUpEventListener(handler: (ev: paper.MouseEvent) => void) {
    this.scope.view.off('mouseup', handler);
  }

  private isPathValid() {
    if (!this.path.isInside(this.localPlotBounds)) {
      return false;
    }

    for (const segment of this.path.segments) {
      const { previous, next, point, handleIn, handleOut } = segment;
      if (
        (previous && point.x + handleIn.x < previous.point.x) ||
        (next && point.x + handleOut.x > next.point.x)
      ) {
        return false;
      }
    }
    return true;
  }

  private getPlotBounds() {
    const { Rectangle, Point, view } = this.scope;

    const left = this.hideAxes ? 0 : this.labelHeight + this.spacing;
    const top = this.hideAxes ? 0 : this.handleSize / 2 + 1;
    const right =
      view.bounds.width - (this.hideAxes ? 0 : this.handleSize / 2 + 1);
    const bottom =
      view.bounds.height -
      (this.hideAxes ? 0 : this.spacing + this.labelHeight);
    return new Rectangle(new Point(left, top), new Point(right, bottom));
  }

  private draw() {
    if (!this.hideAxes) {
      this.drawAxes();
    }
    if (!this.hideActionLabel) {
      this.drawActionLabel();
    }
    if (!this.hideAxes) {
      this.drawLabels();
    }
    this.drawPlot();
  }

  private drawAxes() {
    const { Point } = this.scope;

    const plotBounds = this.getPlotBounds();

    this.axes.removeSegments();
    this.axes.add(new Point(plotBounds.left, plotBounds.top));
    this.axes.add(new Point(plotBounds.left, plotBounds.bottom));
    this.axes.add(new Point(plotBounds.left, plotBounds.bottom));
    this.axes.add(new Point(plotBounds.right, plotBounds.bottom));
  }

  private drawActionLabel() {
    const { Point, view } = this.scope;

    this.actionLabel.position = view.bounds.topRight.add(
      new Point(
        -(this.actionLabel.bounds.width / 2 + this.spacing),
        this.actionLabel.bounds.height / 2 + this.spacing
      )
    );
  }

  private drawLabels() {
    const { Point } = this.scope;

    const plotBounds = this.getPlotBounds();

    this.verticalAxisLabel.position = new Point(
      plotBounds.left - this.labelHeight / 2 - this.spacing,
      plotBounds.top + this.verticalAxisLabel.bounds.height / 2
    );

    const horizontalAxisLabelY =
      plotBounds.bottom + this.labelHeight / 2 + this.spacing;
    this.minLabel.position = new Point(
      plotBounds.left + this.minLabel.bounds.width / 2,
      horizontalAxisLabelY
    );
    this.maxLabel.position = new Point(
      plotBounds.right - this.maxLabel.bounds.width / 2,
      horizontalAxisLabelY
    );
    this.midLabel.position = new Point(
      plotBounds.center.x,
      horizontalAxisLabelY
    );
  }

  private drawPlot() {
    const { Point } = this.scope;
    const plotBounds = this.getPlotBounds();
    this.plot.matrix.reset();
    this.plot.translate(plotBounds.topLeft);
    this.plot.scale(
      plotBounds.width / 100,
      plotBounds.height / 100,
      plotBounds.topLeft
    );
    if (this.horizontalGraph) {
      this.plot.rotate(90, plotBounds.topLeft);
      this.plot.translate(
        new Point(plotBounds.bottomLeft.y - plotBounds.topLeft.y, 0)
      );
      this.plot.scale(
        plotBounds.width / plotBounds.height,
        plotBounds.height / plotBounds.width,
        plotBounds.topLeft
      );
    }
  }
}
