import { useMemo } from 'react';
import { PosSelect, PosSelectProps } from 'src/core/POS/PosSelect';
import { ContentId } from 'src/utils/constants/contentId';
import { SectionInfo } from 'src/WebApiController';

export type SectionSelectorProps = Omit<
  PosSelectProps,
  'valueOptionsContent'
> & { sections?: SectionInfo[] | null };

export function SectionSelector({
  disabled,
  placeholderText,
  sections,
  ...props
}: SectionSelectorProps) {
  const valueOptionsContent = useMemo(() => {
    const sectionOptions = sections
      ?.sort((s1, s2) => s1.name.localeCompare(s2.name))
      .reduce(
        (r, s) => {
          r[s.id.toString()] = s.name;
          return r;
        },
        {} as Record<string, string>
      );

    return sectionOptions ?? {};
  }, [sections]);

  return (
    <PosSelect
      {...props}
      disabled={disabled}
      loading={sections === undefined}
      searchable
      allowSearchAsInput
      valueOptionsContent={valueOptionsContent}
      placeholderText={placeholderText ?? ContentId.ChooseSection}
    />
  );
}
