import { useCallback, useContext, useState } from 'react';
import { useActivePosEntityContext } from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { ModalContext } from 'src/contexts/ModalContext';
import { ConfirmDialog } from 'src/core/interim/dialogs/ConfirmDialog';
import { PosDropdown, PosDropdownItem } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { useBasicDialog } from 'src/hooks/useBasicDialog';
import { GroupListingsModal } from 'src/modals/GroupListings';
import { IconsFill, MoreIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import { Event, ListingGroup, ListingGroupClient } from 'src/WebApiController';

export const ListingGroupActions = ({
  event,
  listingGroup,
  disabled,
  setIsLoading,
  slideLeft,
}: {
  event: Event;
  listingGroup: ListingGroup;
  disabled?: boolean;
  slideLeft?: boolean;
  setIsLoading?: (value: boolean) => void;
}) => {
  const { setActivePosEntity } = useActivePosEntityContext();
  const { setModal } = useContext(ModalContext);
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();
  const {
    updateExpandedListItems,
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();
  const [isUngrouping, setIsUngrouping] = useState(false);

  const ungroupConfirmDialog = useBasicDialog();

  const onUngroupListings = useCallback(() => {
    tryInvokeApi(
      async () => {
        setIsLoading?.(true);
        setIsUngrouping(true);

        if (listingGroup.ltGrpId) {
          const ungroupedListings = await new ListingGroupClient(
            activeAccountWebClientConfig
          ).ungroupListings(listingGroup.ltGrpId);

          updateExpandedListItems(ungroupedListings);

          setActivePosEntity(0);

          // kick off a background refresh, but not wait since we already force updateExpandedListItems
          refreshExpandedListItems();
        }
      },
      (error) => {
        showErrorDialog('ListingGroupClient.ungroupListings', error, {
          trackErrorData: listingGroup,
        });
      },
      () => {
        setIsLoading?.(false);
        setIsUngrouping(false);
        ungroupConfirmDialog.closeDialog();
      }
    );
  }, [
    activeAccountWebClientConfig,
    listingGroup,
    refreshExpandedListItems,
    setActivePosEntity,
    setIsLoading,
    showErrorDialog,
    ungroupConfirmDialog,
    updateExpandedListItems,
  ]);

  return (
    <>
      <PosDropdown
        trigger={
          <div
            style={{ padding: `0 ${vars.spacing.sm} 0 ${vars.spacing.xxs}` }}
          >
            <MoreIcon
              withHoverEffect
              fill={IconsFill.textBrand}
              align="middle"
            />
          </div>
        }
        align="end"
        triggerProps={{ disabled: disabled }}
      >
        <PosDropdownItem
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setModal({
              children: (
                <GroupListingsModal
                  events={[event]}
                  listingGroup={listingGroup}
                />
              ),
              clickOutsideToClose: true,
              ...{ size: slideLeft ? 'slide-left' : 'slide-in' },
            });
          }}
          disabled={disabled}
        >
          <Content id={ContentId.Edit} />
        </PosDropdownItem>
        <PosDropdownItem
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            ungroupConfirmDialog.launchDialog();
          }}
          disabled={disabled}
        >
          <Content id={ContentId.Ungroup} />
        </PosDropdownItem>
      </PosDropdown>

      <ConfirmDialog
        {...ungroupConfirmDialog.dialogProps}
        headerText={<Content id={ContentId.Ungroup} />}
        bodyText={<Content id={ContentId.AreYouSure} />}
        onOkay={onUngroupListings}
        disabled={isUngrouping}
        isLoading={isUngrouping}
        onCancel={() => ungroupConfirmDialog.closeDialog()}
      />
    </>
  );
};
