import { useState } from 'react';
import { useBulkBroadcastActionsPermission } from 'src/components/common/BulkActions/BulkBroadcastActions/useBulkBroadcastActionsPermission';
import { useBulkActionsState } from 'src/components/common/BulkActions/useBulkActionsState';
import { useBulkAddHoldAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkAddHoldAction';
import { useBulkEditBroadcastGlobalSettingsAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkEditBroadcastSettingsAction/useBulkEditBroadcastGlobalSettingsAction';
import { useBulkRemoveHoldAction } from 'src/components/Listings/InventoryActionDropdown/components/BulkRemoveHoldAction';
import { useBulkUngroupListings } from 'src/components/Listings/InventoryActionDropdown/components/LaunchUngroupListingsAction';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdown } from 'src/core/POS/PosDropdown';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { ChevronDownIcon, IconsFill } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

import { useInventoryEventBulkActionsState } from '../../InventoryEventBulkActions/useInventoryEventBulkActionsState';

export const BroadcastActions = () => {
  const { areBulkActionsEnabled } = useBulkActionsState();
  const [isLoading, setIsLoading] = useState(false);
  const { hasBroadcastPermission } = useBulkBroadcastActionsPermission();

  const { filterQueryWithEventIds } = useInventoryEventBulkActionsState();

  const bulkAddHold = useBulkAddHoldAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkRemoveHold = useBulkRemoveHoldAction(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkUngroupListings = useBulkUngroupListings(
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
    undefined
  );

  const bulkEditBroadcastSettings = useBulkEditBroadcastGlobalSettingsAction({
    filterQueryWithEventIds,
    setIsLoading,
    isLoading,
  });

  return (
    <>
      <PosDropdown
        key="events-broadcast-actions-dropdown"
        trigger={
          <Button variant="outline">
            <Content id={ContentId.Broadcast} />
            <ChevronDownIcon
              size={vars.iconSize.xs}
              fill={IconsFill.currentColor}
            />
          </Button>
        }
        align="end"
        triggerProps={{ disabled: isLoading || !areBulkActionsEnabled }}
      >
        {hasBroadcastPermission && bulkEditBroadcastSettings.dropDown}
        {bulkAddHold.dropDown}
        {bulkRemoveHold.dropDown}
        {bulkUngroupListings.dropDown}
      </PosDropdown>
    </>
  );
};
