import { SortingState } from '@tanstack/react-table';
import { createContext, useContext, useState } from 'react';

export type IActiveSortTableColumnContext = {
  activeSortTableColumn?: SortingState;
  setActiveSortTableColumn: (val: SortingState) => void;
};

export const CreateActiveSortTableColumnContext =
  createContext<IActiveSortTableColumnContext>({
    setActiveSortTableColumn: () => {},
  });

export const useActiveSortTableColumnContext = () =>
  useContext(CreateActiveSortTableColumnContext);

export const ActiveSortTableColumnProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [activeSortTableColumn, setActiveSortTableColumn] =
    useState<SortingState>();

  return (
    <CreateActiveSortTableColumnContext.Provider
      value={{ activeSortTableColumn, setActiveSortTableColumn }}
    >
      {children}
    </CreateActiveSortTableColumnContext.Provider>
  );
};
