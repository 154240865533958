import { useCallback } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { Content } from 'src/contexts/ContentContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { InfoMessage } from 'src/core/POS/MessageWithIcon/InfoMessage';
import { BulkEditDeliveryTypeDialog } from 'src/dialogs/BulkEdits/BulkEditDeliveryTypeDialog';
import { ContentId } from 'src/utils/constants/contentId';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  DeliveryType,
  Event,
  ListingQuery,
  TicketTypeOverride,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_DELIVERY_TYPE_UPDATE_KEY } from '../../InventoryActionDropdown.constants';
import { LaunchBulkUpdateDeliveryType } from './LaunchBulkUpdateDeliveryType';

export const useBulkUpdateDeliveryType = (
  filterQuery: ListingQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();

  const updateKey = event
    ? `inventory-delivery-type-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_DELIVERY_TYPE_UPDATE_KEY;

  const { showErrorDialog } = useErrorBoundaryContext();
  const {
    eventsExpansion: { refreshExpandedListItems },
  } = useCatalogDataContext();

  const {
    openDialog: openBulkEditDeliveryTypeDialog,
    closeDialog: closeBulkEditDeliveryTypeDialog,
  } = useDialog(DialogId.BulkEditDeliveryType, BulkEditDeliveryTypeDialog);
  const onSubmit = useCallback(
    async (
      deliveryType: DeliveryType | null,
      tickeTypePriorities: TicketTypeOverride | null,
      _updateSalesOfListings: boolean, // not used
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateDeliveryTypePreview(filterQuery);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateDeliveryTypePreview', error, {
              trackErrorData: { filterQuery },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (deliveryType && tickeTypePriorities) {
        await tryInvokeApi(
          async () => {
            const succeeded = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateDeliveryType(
              {
                item1: preview!.preview,
                item2: deliveryType,
                item3: tickeTypePriorities.overrides[0].ticketTypePriorities,
              },
              updateKey,
              supportBackgroundProcess
            );
            if (!supportBackgroundProcess) {
              if (succeeded) {
                refreshExpandedListItems();
              }

              closeBulkEditDeliveryTypeDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateDeliveryType', error, {
              trackErrorData: {
                preview,
                deliveryType,
                tickeTypePriorities,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      activeAccountWebClientConfig,
      closeBulkEditDeliveryTypeDialog,
      filterQuery,
      refreshExpandedListItems,
      setIsLoading,
      showErrorDialog,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkUpdateDeliveryType
        key="purchaseDeliveryTypeUpdate"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          openBulkEditDeliveryTypeDialog({
            header: (
              <Content id={ContentId.UpdateDeliveryTypeForPlaceholders} />
            ),
            updateKey,
            onOkay: onSubmit,
            onClosed: () => {
              setIsLoading(false);
            },
            onCancel: closeBulkEditDeliveryTypeDialog,
            entityType: ActionOutboxEntityType.Listing,
            infoMessage: (
              <InfoMessage
                message={
                  <Content id={ContentId.BulkUpdatePlaceholdersMessage} />
                }
              />
            ),
            showUpdateAssociatedSalesOption: false, // Placeholder listings don't have allocated sales
          });
        }}
        disabled={isLoading}
      />
    ),
  };
};
