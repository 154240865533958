import { useQuery } from '@tanstack/react-query';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import { AddressClient } from 'src/WebApiController';

export function useCities() {
  const { activeAccountWebClientConfig } = useAppContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const citiesWithStateProvinceQuery = useQuery({
    queryKey: [
      'getCitiesWithStateProvince',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return await new AddressClient(
        activeAccountWebClientConfig
      ).getCitiesWithStateProvince();
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('AddressClient.getCitiesWithStateProvince', error);
      },
    },
  });

  const citiesWithoutStateProvinceQuery = useQuery({
    queryKey: [
      'getCitiesWithoutStateProvince',
      activeAccountWebClientConfig.activeAccountId,
    ],
    queryFn: async () => {
      if (activeAccountWebClientConfig.activeAccountId == null) {
        return null;
      }
      return await new AddressClient(
        activeAccountWebClientConfig
      ).getCitiesWithoutStateProvince();
    },

    enabled: activeAccountWebClientConfig.activeAccountId != null,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    meta: {
      onError: (error: ErrorTypes) => {
        showErrorDialog('AddressClient.getCitiesWithoutStateProvince', error);
      },
    },
  });

  return { citiesWithStateProvinceQuery, citiesWithoutStateProvinceQuery };
}
