import { clsx } from 'clsx';
import { HTMLProps } from 'react';
import { useDrop } from 'react-dnd';

import { DraggableSource } from './PosDraggable';
import { DroppableContainer } from './PosDragNDrop.styled';

export function PosDroppable<T>({
  children,
  onDrop,
  hideBorder,
  className,
  onOverClassnames,
  draggableSource,
  ...rest
}: {
  onDrop?: (item: { data: T }) => void;
  hideBorder?: boolean;
  onOverClassnames?: string;
  draggableSource?: string;
} & Omit<HTMLProps<HTMLDivElement>, 'ref' | 'as' | 'onDrop'>) {
  const [{ isOver }, dropRef] = useDrop(
    {
      accept: draggableSource || DraggableSource,
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    },
    [draggableSource, onDrop]
  );

  return (
    <DroppableContainer
      ref={dropRef}
      isOver={isOver}
      {...rest}
      hideBorder={hideBorder}
      className={clsx(className, isOver ? onOverClassnames : undefined)}
    >
      {children}
    </DroppableContainer>
  );
}
