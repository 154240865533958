import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { EventMapContextProvider } from 'src/contexts/EventMapContext';
import { Stack } from 'src/core/ui';
import { useGetEventListingConstraints } from 'src/hooks/useGetEventListingConstraints';
import { Event } from 'src/WebApiController';

import { SeatSaverListingDetailsInputForm } from '../SeatSaverListingWizard.types';
import { DeliverySection } from './DeliverySection';
import { ListingSection } from './ListingSection';
import { SeatingSection } from './SeatingSection';

export const SeatSaverInput = ({
  events,
  disabled,
}: {
  events: Event[]; // This is at least 1, but if more than one, must be of same venueConfigId
  disabled?: boolean;
}) => {
  const { watch } = useFormContext<SeatSaverListingDetailsInputForm>();

  const viagogoEventIds = Array.from(
    new Set(events.filter((ev) => ev.viagId).map((ev) => ev.viagId!))
  );
  const inputs = watch('inputs');
  const inputIndeces = useMemo(() => {
    const eventIds = events.map((ev) => ev.viagVirtualId);
    return inputs
      .map((input, i) => {
        if (eventIds.includes(input.event.viagVirtualId)) {
          return i;
        }
        return -1;
      })
      .filter((i) => i >= 0);
  }, [events, inputs]);

  const { eventListingConstraintsQuery } =
    useGetEventListingConstraints(viagogoEventIds);

  return (
    <EventMapContextProvider disabled={disabled} event={events[0]}>
      <Stack direction="column" gap="xl" width="full">
        <ListingSection inputIndeces={inputIndeces} disabled={disabled} />
        <SeatingSection
          inputIndeces={inputIndeces}
          disabled={disabled}
          eventListingConstraints={
            eventListingConstraintsQuery.data?.[viagogoEventIds[0]]
          }
        />
        <DeliverySection inputIndeces={inputIndeces} disabled={disabled} />
      </Stack>
    </EventMapContextProvider>
  );
};
