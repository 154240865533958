import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { id } from 'date-fns/locale';
import { useMemo } from 'react';
import { useAppContext } from 'src/contexts/AppContext';
import {
  ErrorTypes,
  useErrorBoundaryContext,
} from 'src/contexts/ErrorBoundaryContext';
import {
  CurrencyConversionOverride,
  CurrencyConversionOverrideRequest,
  Feature,
  PurchaseClient,
} from 'src/WebApiController';

import { useUserHasFeature } from './useUserHasFeature';

/**
 * Data and functions returned by the hook to help with CRUD of CurrencyConversionOverride
 */
type UseCurrencyConversionOverrides = {
  currencyConversionOverrides: CurrencyConversionOverride[] | null | undefined;
  isLoading: boolean;
  deleteCurrencyConversionOverride: (
    currencyConversionOverrideId: number
  ) => Promise<boolean>;
  createCurrencyConversionOverride: (
    userGroupInput: CurrencyConversionOverrideRequest
  ) => Promise<number | null>;
  updateCurrencyConversionOverride: (
    userGroupInput: CurrencyConversionOverrideRequest
  ) => Promise<boolean>;
};

/**
 * Hook to help with CRUD of CurrencyConversionOverride
 * @param options
 * @returns
 */
export function useCurrencyConversionOverrides(): UseCurrencyConversionOverrides {
  const { showErrorDialog, trackError } = useErrorBoundaryContext();
  const { activeAccountWebClientConfig } = useAppContext();
  const client = useMemo(
    () => new PurchaseClient(activeAccountWebClientConfig),
    [activeAccountWebClientConfig]
  );

  const hasOverrideDailyFxRateFeature = useUserHasFeature(
    Feature.OverrideDailyFxRate
  );

  const queryClient = useQueryClient();
  const queryKey = [
    'useCurrencyConversionOverrides',
    activeAccountWebClientConfig.activeAccountId,
  ];

  const shouldQuery =
    activeAccountWebClientConfig?.activeAccountId != null &&
    hasOverrideDailyFxRateFeature;

  const getAllQuery = useQuery({
    queryKey,
    queryFn: async () => {
      if (!shouldQuery) {
        return null;
      }

      return await client.getAllCurrencyConversionOverride();
    },

    enabled: shouldQuery,
    meta: {
      onError: (err: ErrorTypes) => {
        trackError('PurchaseClient.getAllCurrencyConversionOverride', err);
      },
    },
    refetchOnWindowFocus: false,
  });

  const updateMutation = useMutation({
    mutationFn: async ({
      value,
    }: {
      value: CurrencyConversionOverrideRequest;
    }) => {
      return await client.updateCurrencyConversionOverride(value);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates

    onMutate: ({ value }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData<
        CurrencyConversionOverride[] | null
      >(queryKey);

      const newValue = prevValue?.map((r) =>
        r.currencyConversionOverrideId === value.currencyConversionOverrideId
          ? (value as CurrencyConversionOverride)
          : r
      );

      queryClient.setQueryData<CurrencyConversionOverride[]>(
        queryKey,
        newValue
      );
      return { prevValue };
    },
    onError: (err: ErrorTypes, { value }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('client.updateCurrencyConversionOverride', err, {
        trackErrorData: { id, value },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const updateCurrencyConversionOverride = async (
    userGroupInput: CurrencyConversionOverrideRequest
  ) => {
    return await updateMutation.mutateAsync({
      value: userGroupInput,
    });
  };

  const createMutation = useMutation({
    mutationFn: async ({
      value,
    }: {
      value: CurrencyConversionOverrideRequest;
    }) => {
      return await client.insertCurrencyConversionOverride(value);
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates

    onMutate: ({ value }) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData<
        CurrencyConversionOverride[] | null
      >(queryKey);

      const newValue = [
        ...(prevValue ?? []),
        value as CurrencyConversionOverride,
      ];

      queryClient.setQueryData<CurrencyConversionOverride[]>(
        queryKey,
        newValue
      );
      return { prevValue };
    },
    onError: (err: ErrorTypes, { value }, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('client.insertCurrencyConversionOverride', err, {
        trackErrorData: { id, value },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const createCurrencyConversionOverride = async (
    userGroupInput: CurrencyConversionOverrideRequest
  ) => {
    return await createMutation.mutateAsync({ value: userGroupInput });
  };

  const deleteMutation = useMutation({
    mutationFn: async (currencyConversionOverrideId: number) => {
      return await client.deleteCurrencyConversionOverride(
        currencyConversionOverrideId
      );
    },
    // optimistic ui
    // Reference: https://tanstack.com/query/v3/docs/react/guides/optimistic-updates

    onMutate: (currencyConversionOverrideId) => {
      queryClient.cancelQueries({ queryKey });
      const prevValue = queryClient.getQueryData<
        CurrencyConversionOverride[] | null
      >(queryKey);

      const newValue = prevValue?.filter(
        (r) => r.currencyConversionOverrideId !== currencyConversionOverrideId
      );

      queryClient.setQueryData<CurrencyConversionOverride[]>(
        queryKey,
        newValue
      );
      return { prevValue };
    },
    onError: (err: ErrorTypes, currencyConversionOverrideId, context) => {
      queryClient.setQueryData(queryKey, context?.prevValue);
      showErrorDialog('client.deleteCurrencyConversionOverride', err, {
        trackErrorData: { id, currencyConversionOverrideId },
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const deleteCurrencyConversionOverride = (
    currencyConversionOverrideId: number
  ) => {
    return deleteMutation.mutateAsync(currencyConversionOverrideId);
  };

  return {
    currencyConversionOverrides: getAllQuery.data,
    isLoading: getAllQuery.isLoading,
    deleteCurrencyConversionOverride,
    createCurrencyConversionOverride,
    updateCurrencyConversionOverride,
  };
}
