import { ButtonProps } from 'src/core/ui';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { Feature } from 'src/WebApiController';

import { LaunchResetFulfillment } from './LaunchResetFulfillment';
import { LaunchResetFulfillmentV2 } from './LaunchResetFulfillmentV2';

export const LaunchResetFulfillmentButton = ({
  saleId,
  marketplaceSaleId,
  variant,
  iconOnlyMode,
  disabled,
}: {
  saleId: number;
  marketplaceSaleId?: string | null;
  variant?: ButtonProps['variant'];
  iconOnlyMode?: boolean;
  disabled?: boolean;
}) => {
  const hasResetFulfillmentKeepArtifactsFeature = useUserHasFeature(
    Feature.ResetFulfillmentKeepArtifacts
  );

  if (!hasResetFulfillmentKeepArtifactsFeature) {
    return (
      <LaunchResetFulfillment
        saleId={saleId}
        marketplaceSaleId={marketplaceSaleId}
        iconOnlyMode={iconOnlyMode}
        variant={variant}
        disabled={disabled}
      />
    );
  }

  return (
    <LaunchResetFulfillmentV2
      saleId={saleId}
      marketplaceSaleId={marketplaceSaleId}
      iconOnlyMode={iconOnlyMode}
      variant={variant}
      disabled={disabled}
    />
  );
};
