import { IconButton } from 'src/components/Buttons';
import { Stack } from 'src/core/ui';
import { PurchaseVendorAccountWithActions } from 'src/modals/VendorDetails/components/VendorAccounts/components/VendorAccountsTable/VendorsAccountsTableColumnsConfig';
import { EditIcon } from 'src/svgs/Viagogo';
import { ContentId } from 'src/utils/constants/contentId';

interface VendorAccountsActionsCellProps {
  vendorAccountWithActions: PurchaseVendorAccountWithActions | null;
}

export const VendorAccountsActionsCell = ({
  vendorAccountWithActions,
}: VendorAccountsActionsCellProps) => {
  if (!vendorAccountWithActions) {
    return null;
  }

  return (
    <Stack justifyContent="end">
      <IconButton
        icon={<EditIcon withHoverEffect />}
        onClick={() =>
          vendorAccountWithActions?.vendorAccountActions.editVendorAccount(
            vendorAccountWithActions.vendorAccount
          )
        }
        titleContentId={ContentId.Edit}
      />
    </Stack>
  );
};
