import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function AccessibilityIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        d="M10.094 6.888c1.34 0 2.437-1.1 2.437-2.444A2.448 2.448 0 0010.094 2c-1.34 0-2.438 1.1-2.438 2.444a2.422 2.422 0 002.438 2.444zm-.244.611l1.138 1.14-3.494 3.504 3.494 3.503-1.138 1.14-3.453-3.462a4.52 4.52 0 00-.772 2.525c0 1.222.447 2.322 1.3 3.177a4.45 4.45 0 003.169 1.304c1.219 0 2.315-.448 3.168-1.304 1.666-1.67 1.707-4.358.204-6.11H9.688l-.772-1.629h5.24l3.738 3.748 1.137-1.14 1.138 1.14-2.275 2.321-1.706-1.71c.04 1.629-.529 3.299-1.788 4.562A6.08 6.08 0 0110.094 22a6.112 6.112 0 01-4.306-10.428L9.85 7.5zm8.369 10.387c1.137 0 2.031.896 2.031 2.037 0 1.14-.894 2.036-2.031 2.036a2.014 2.014 0 01-2.032-2.036c0-1.14.894-2.037 2.032-2.037zm0 1.63c-.244 0-.407.162-.407.407 0 .244.163.407.407.407.243 0 .406-.163.406-.407 0-.245-.163-.408-.406-.408zM10.094 3.628a.816.816 0 010 1.63.816.816 0 01-.813-.815c0-.448.366-.815.813-.815z"
        fill="inherit"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
