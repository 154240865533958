import { useUpdatePurchasesPermissions } from 'src/components/Purchases/BulkActions/useUpdatePurchasesPermissions';
import { Content } from 'src/contexts/ContentContext';
import { PosDropdownItem } from 'src/core/POS/PosDropdown';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

export const LaunchBulkUpdateSeatTraits = ({
  onClick,
  disabled,
  labelContentId = ContentId.UpdateSeatTraits,
}: {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  labelContentId?: ContentId;
}) => {
  const { hasPurchaseUpdateSeatTraitsPermission } =
    useUpdatePurchasesPermissions();

  if (!hasPurchaseUpdateSeatTraitsPermission) {
    return null;
  }

  return (
    <>
      <PosDropdownItem
        key="update-seat-traits-event-purchases"
        onClick={onClick}
        disabled={disabled}
      >
        <Stack gap="m" alignItems="center" width="full">
          <Content id={labelContentId} />
        </Stack>
      </PosDropdownItem>
    </>
  );
};
