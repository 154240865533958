import { isEqual } from 'lodash-es';
import { ChangeEvent, useState } from 'react';
import {
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form';
import { useContent } from 'src/contexts/ContentContext';
import { Content } from 'src/contexts/ContentContext';
import { useLocalizationContext } from 'src/contexts/LocalizationContext';
import { PosCurrencyField } from 'src/core/POS/PosCurrencyField';
import { PosFormField } from 'src/core/POS/PosFormField';
import { PosMultiSelect } from 'src/core/POS/PosMultiSelect';
import { PosTextField } from 'src/core/POS/PosTextField';
import { Stack } from 'src/core/ui';
import * as Tabs from 'src/core/ui/Tabs';
import { useUserHasFeature } from 'src/hooks/useUserHasFeature';
import { DetailSection, SectionContent } from 'src/modals/common';
import { BulkEditAdvancePricingSettings } from 'src/modals/GroupListings/components/AutoPricingSettings/BulkEditAdvancePricingSettings';
import { BulkEditAutoPricingSettingsSection } from 'src/modals/GroupListings/components/AutoPricingSettings/BulkEditAutoPricingSettingsSection';
import { BulkEditListingGroupSettingsFields } from 'src/modals/GroupListings/components/groupingTypes';
import { getQuantitiesOptions } from 'src/modals/GroupListings/components/groupingUtils';
import { BulkEditListingGroupSettingsMarketplaceBroadcast } from 'src/modals/GroupListings/components/MarketplaceBroadcast/BulkEditListingGroupSettingsMarketplaceBroadcast';
import { ContentId } from 'src/utils/constants/contentId';
import { roundToPrecision } from 'src/utils/numberFormatter';
import { Event, Feature } from 'src/WebApiController';

import * as styles from './BulkEditListingGroupSettingsDialog.css';

enum GroupListingsTab {
  Groupings = 'groupings',
  Broadcast = 'broadcast',
  AutoPricing = 'autopricing',
}

export const BulkEditListingGroupSettingsDialogBody = ({
  event,
  currency,
  watch,
  register,
  setValue,
}: {
  event: Event;
  currency: string;
  setValue: UseFormSetValue<BulkEditListingGroupSettingsFields>;
  watch: UseFormWatch<BulkEditListingGroupSettingsFields>;
  register: UseFormRegister<BulkEditListingGroupSettingsFields>;
}) => {
  const [activeTab, setActiveTab] = useState<GroupListingsTab>(
    GroupListingsTab.Groupings
  );

  return (
    <>
      <Stack className={styles.entityLabel} gap="l" alignItems="center">
        <span>
          <Content id={ContentId.BulkEditListingGroupSettingsWarning} />
        </span>
      </Stack>
      <Tabs.Root
        value={activeTab}
        onValueChange={(value) => setActiveTab(value as GroupListingsTab)}
        style={{ height: '100%' }}
      >
        <Tabs.List>
          <Tabs.Trigger value={GroupListingsTab.Groupings}>
            <Content id={ContentId.GroupListings} />
          </Tabs.Trigger>
          <Tabs.Trigger value={GroupListingsTab.Broadcast}>
            <Content id={ContentId.Broadcast} />
          </Tabs.Trigger>
          <Tabs.Trigger value={GroupListingsTab.AutoPricing}>
            <Content id={ContentId.AutopricingSettings} />
          </Tabs.Trigger>
        </Tabs.List>
        <Tabs.Content
          value={GroupListingsTab.Groupings}
          style={{ height: '90%' }}
        >
          <BulkEditListingGroupSettingsInput
            currency={currency}
            watch={watch}
            register={register}
            setValue={setValue}
          />
        </Tabs.Content>
        <Tabs.Content value={GroupListingsTab.Broadcast}>
          <BulkEditListingGroupSettingsMarketplaceBroadcast event={event} />
        </Tabs.Content>
        <Tabs.Content value={GroupListingsTab.AutoPricing}>
          <>
            <DetailSection name={<Content id={ContentId.Autopricing} />}>
              <SectionContent numOfColumns={1}>
                <BulkEditAutoPricingSettingsSection />
              </SectionContent>
            </DetailSection>
            <BulkEditAdvancePricingSettings />
          </>
        </Tabs.Content>
      </Tabs.Root>
    </>
  );
};

export const BulkEditListingGroupSettingsInput = ({
  currency,
  watch,
  register,
  setValue,
}: {
  currency: string;
  setValue: UseFormSetValue<BulkEditListingGroupSettingsFields>;
  watch: UseFormWatch<BulkEditListingGroupSettingsFields>;
  register: UseFormRegister<BulkEditListingGroupSettingsFields>;
}) => {
  // Setup hooks
  const { getUiCurrency } = useLocalizationContext();

  // Variables
  const uiCurrency = getUiCurrency(currency);
  const dontChangePlaceholder = useContent(ContentId.DontChange);

  // Form values
  const desiredActiveListings = watch('desiredActiveListings');
  const deprioritizedQuantities = watch('deprioritizedQuantities');
  const undercutAbsoluteAmount = watch('rankPremiums.undercutAbsoluteAmount');
  const undercutRelativeAmount = watch('rankPremiums.undercutRelativeAmount');

  // FF
  const hasAutoPricingFeature = useUserHasFeature(Feature.AutoPricing);
  return (
    <Stack direction="column" gap="xl" alignItems="start">
      {hasAutoPricingFeature && (
        <PosFormField
          label={<Content id={ContentId.RankPremium} />}
          style={{ flex: '0.5' }}
        >
          <PosCurrencyField
            rootProps={{
              style: { width: '200px' },
            }}
            uiCurrency={uiCurrency}
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              if (isNaN(v)) {
                setValue(`rankPremiums.undercutAbsoluteAmount`, null);
                return;
              }

              setValue(`rankPremiums.undercutAbsoluteAmount`, v);
            }}
            value={undercutAbsoluteAmount ?? undefined}
            placeholder={dontChangePlaceholder}
          />

          <PosTextField
            rootProps={{
              style: { width: '200px' },
            }}
            type="number"
            postfixDisplay="%"
            placeholder={dontChangePlaceholder}
            onChange={(e) => {
              const v = parseFloat(e.target.value);
              if (isNaN(v)) {
                setValue(`rankPremiums.undercutRelativeAmount`, null);
                return;
              }

              let relativeValue = null;
              if (v >= 0 && v <= Number.MAX_VALUE) {
                relativeValue = Math.min(v, 100) / 100;
              }
              setValue(`rankPremiums.undercutRelativeAmount`, relativeValue);
            }}
            value={
              undercutRelativeAmount != null
                ? roundToPrecision(Math.abs(undercutRelativeAmount) * 100, 8)
                : ''
            }
          />
        </PosFormField>
      )}
      <PosFormField
        label={<Content id={ContentId.DesiredActiveListings} />}
        style={{ width: '200px' }}
      >
        <PosTextField
          value={desiredActiveListings ?? undefined}
          type="number"
          min={0}
          placeholder={dontChangePlaceholder}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            let v = parseInt(e.target.value);
            if (isNaN(v)) {
              setValue(`desiredActiveListings`, null);
              return;
            }

            if (v < 0) {
              v = 0;
            }
            setValue(`desiredActiveListings`, v);
          }}
        />
      </PosFormField>
      <PosFormField
        label={<Content id={ContentId.QuantitiesForcedToBottom} />}
        style={{ width: '190px' }}
      >
        <PosMultiSelect
          align="start"
          placeholderText={dontChangePlaceholder}
          triggerProps={{ style: { width: '100%' } }}
          values={deprioritizedQuantities ?? []}
          onChange={(newValues) => {
            if (newValues.length === 0) {
              setValue('deprioritizedQuantities', null);
              return;
            }

            if (!isEqual(newValues, deprioritizedQuantities)) {
              setValue('deprioritizedQuantities', newValues);
            }
          }}
          valueOptionsContent={getQuantitiesOptions()}
        />
      </PosFormField>
    </Stack>
  );
};
