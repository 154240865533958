import { UploadETicketsSectionSelector } from 'src/modals/PurchaseWizard/UploadETicketsSectionSelector';
import { ListingActionType, TicketType } from 'src/WebApiController';

import { UploadPredeliveryAction } from '../purchaseTicketsFlow.utils';
import { UploadBarcodesSection } from './UploadBarcodesSection';
import { UploadExternalIdsSection } from './UploadExternalIdsSection';
import { UploadTicketUrlsSection } from './UploadTicketUrlsSection';

export const PredeliverySection = ({
  ticketGroupIndex,
  action,
  onCancel,
}: {
  ticketGroupIndex: number;
  onCancel: () => void;
  action: UploadPredeliveryAction;
}) => {
  switch (action) {
    case ListingActionType.PredeliverBarcodes:
      return (
        <UploadBarcodesSection
          ticketGroupIndex={ticketGroupIndex}
          onCancel={onCancel}
        />
      );
    case ListingActionType.PredeliverETickets:
      return (
        <UploadETicketsSectionSelector
          ticketGroupIndex={ticketGroupIndex}
          onCancel={onCancel}
          ticketType={TicketType.ETicket}
        />
      );
    case ListingActionType.PredeliverExternalIds:
      return (
        <UploadExternalIdsSection
          ticketGroupIndex={ticketGroupIndex}
          onCancel={onCancel}
        />
      );
    case ListingActionType.PredeliverQRCode:
      return (
        <UploadETicketsSectionSelector
          ticketGroupIndex={ticketGroupIndex}
          onCancel={onCancel}
          ticketType={TicketType.QRCode}
        />
      );
    case ListingActionType.PredeliverTicketUrls:
      return (
        <UploadTicketUrlsSection
          ticketGroupIndex={ticketGroupIndex}
          onCancel={onCancel}
        />
      );
  }
};
