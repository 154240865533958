import { PosIconProps, SvgWrapper } from 'src/svgs/SvgWrapper';

export function ChevronUpIcon(props: PosIconProps) {
  return (
    <SvgWrapper
      aria-label="ChevronUpIcon"
      fill="currentColor"
      {...props}
      viewBox="0 0 10 16"
    >
      <path d="M14.384 10.857q0 .473-.33.804l-.67.67q-.339.339-.813.339-.482 0-.804-.339l-4.339-4.33-4.339 4.33q-.321.339-.804.339t-.804-.339l-.67-.67q-.339-.321-.339-.804 0-.473.339-.813l5.813-5.813q.33-.33.804-.33.464 0 .813.33l5.804 5.813q.339.339.339.813z" />
    </SvgWrapper>
  );
}
