import { useCallback } from 'react';
import { LaunchBulkUpdateInternalNotes } from 'src/components/common/BulkActions/BulkUpdateInternalNotesAction';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { BulkUpdateInternalNotesDialog } from 'src/dialogs/BulkEdits/BulkUpdateInternalNotesDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  ActionOutboxEntityType,
  BulkEditListingClient,
  BulkEditPreviewWithDetails,
  Event,
  ListingQuery,
} from 'src/WebApiController';

import { INVENTORY_BULK_UPDATE_INTERNAL_NOTES_UPDATE_KEY } from '../../InventoryActionDropdown.constants';

export const useBulkUpdateInternalNotesAction = (
  filterQueryWithEventIds: ListingQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const updateKey = event
    ? `listing-internal-notes-event-${event.viagVirtualId}`
    : INVENTORY_BULK_UPDATE_INTERNAL_NOTES_UPDATE_KEY;
  const {
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkUpdateInternalNotesDialog,
    closeDialog: closeBulkUpdateInternalNotesDialog,
  } = useDialog(
    DialogId.BulkUpdateInternalNotes,
    BulkUpdateInternalNotesDialog
  );

  const onSubmit = useCallback(
    async (
      isUpdate: boolean | null,
      searchString: string | null,
      replaceString: string | null,
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditListingClient(
              activeAccountWebClientConfig
            ).bulkUpdateInternalNotesPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateInternalNotesPreview', error, {
              trackErrorData: { filterQueryWithEventIds },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else if (
        isUpdate != null &&
        searchString != null &&
        replaceString != null
      ) {
        await tryInvokeApi(
          async () => {
            const listingClient = new BulkEditListingClient(
              activeAccountWebClientConfig
            );

            const succeeded = await listingClient.bulkUpdateInternalNotes(
              preview!.preview,
              updateKey,
              isUpdate,
              searchString,
              replaceString,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }

              closeBulkUpdateInternalNotesDialog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateInternalNotes', error, {
              trackErrorData: {
                isUpdate,
                searchString,
                replaceString,
                preview,
              },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQueryWithEventIds,
      showErrorDialog,
      updateKey,
      closeBulkUpdateInternalNotesDialog,
      setActivePosEntity,
      refreshExpandedListItems,
    ]
  );

  const onChangeClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (event) {
        setListItemExpansion(true, [event.viagVirtualId]);
      }

      openBulkUpdateInternalNotesDialog({
        updateKey,
        onOkay: onSubmit,
        entityType: ActionOutboxEntityType.Listing,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkUpdateInternalNotesDialog,
      });
    },
    [
      closeBulkUpdateInternalNotesDialog,
      event,
      onSubmit,
      openBulkUpdateInternalNotesDialog,
      setIsLoading,
      setListItemExpansion,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkUpdateInternalNotes
        key="UpdateInternalNotes"
        onClick={onChangeClick}
        disabled={isLoading}
        entityType={ActionOutboxEntityType.Listing}
      />
    ),
  };
};
