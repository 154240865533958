import { IconsProps } from '../icon-types';
import { SvgWrapper } from '../svg-wrapper/icon-wrapper';

export function GenericFeatureIcon(props: IconsProps) {
  return (
    <SvgWrapper {...props}>
      <path
        fill="inherit"
        d="M22 9.908L15.08 8.89 12 2.5 8.92 8.89 2 9.908l5 4.966-1.2 7.001 6.2-3.297 1.68-.936-.96-.489-.72-.407-.72.407-3.36 1.791.64-3.826.12-.814-.56-.61-2.72-2.687 3.72-.53.84-.121.36-.774L12 6.163l1.68 3.42.36.773.84.122 3.72.53-2.72 2.686-.56.61.12.814.64 3.826-1.44-.814-.92 1.343 4.48 2.402-1.2-7.001z"
        fillRule="evenodd"
      />
    </SvgWrapper>
  );
}
