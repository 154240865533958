import clsx from 'clsx';
import { MerchantDisplay } from 'src/components/common/MerchantDisplay';
import { useContent } from 'src/contexts/ContentContext';
import { Stack } from 'src/core/ui';
import { ContentId } from 'src/utils/constants/contentId';

import * as styles from './SaleMarketplaceIdDisplay.css';

export type SaleMarketplaceIdDisplayProps = {
  marketplaceSaleId: string | null;
  saleId: number;
  marketplace: string;
  textAlign?: 'left' | 'right';
  classNames?: string;
};

export const SaleMarketplaceIdDisplay = ({
  saleId,
  marketplaceSaleId,
  marketplace,
  textAlign,
  classNames,
}: SaleMarketplaceIdDisplayProps) => {
  const uniqueIdLabel = useContent(ContentId.UniqueId);
  return (
    <Stack
      direction="column"
      justifyContent="end"
      className={clsx(
        textAlign === 'left' ? styles.mainContentLeft : styles.mainContentRight,
        classNames
      )}
      title={`${uniqueIdLabel}: ${saleId}`}
    >
      <span>#{marketplaceSaleId || saleId}</span>
      <span>
        <MerchantDisplay name={marketplace} />
      </span>
    </Stack>
  );
};
