import { useMemo } from 'react';
import {
  useFilterQueryState,
  UseFilterQueryStateType,
} from 'src/components/MainFilterBar/useFilterQueryState';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { useMultiSelectionContext } from 'src/contexts/MultiSelectionContext';
import { flattenListingGroup } from 'src/modals/GroupListings/components/groupingUtils';
import { EventWithData } from 'src/WebApiController';
import {
  ActionOutboxEntityType,
  Listing,
  ListingQuery,
} from 'src/WebApiController';

interface UseInventoryEventBulkActionsState {
  isAllListingsSelected: boolean;
  affectedVisibleListingIds: number[];
  filterQueryWithEventIds: UseFilterQueryStateType<ListingQuery>;
}

export const useInventoryEventBulkActionsState = (
  event?: EventWithData['event']
): UseInventoryEventBulkActionsState => {
  const { isAllSelected } = useMultiSelectionContext();

  const { filterQueryWithEventIds } = useFilterQueryState<ListingQuery>(
    ActionOutboxEntityType.Listing,
    event?.viagVirtualId ? [event.viagVirtualId] : []
  );

  const { eventsTransformed } = useCatalogDataContext();

  const listings = useMemo(() => {
    return (eventsTransformed ?? [])
      .flatMap((ev) => ev.listings)
      .flatMap(flattenListingGroup)
      .filter(({ id, isLtGrp }) => id > 0 && !isLtGrp) as Listing[];
  }, [eventsTransformed]);

  const affectedVisibleListingIds = useMemo(() => {
    // Else we must respect only the entity Ids and event Ids
    const ids = [...(filterQueryWithEventIds.entityIds ?? [])];
    ids.push(
      ...listings
        .filter(
          (l) =>
            filterQueryWithEventIds.eventOrMappingIds?.includes(l.viagVirtualId)
        )
        .map((l) => l.id)
    );
    return ids;
  }, [
    filterQueryWithEventIds.entityIds,
    filterQueryWithEventIds.eventOrMappingIds,
    listings,
  ]);

  return {
    isAllListingsSelected: isAllSelected,
    affectedVisibleListingIds,
    filterQueryWithEventIds,
  };
};
