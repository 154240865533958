import 'swiper/swiper-bundle.min.css';

import { ComponentProps, ReactElement, useMemo, useRef, useState } from 'react';
import { Content } from 'src/contexts/ContentContext';
import { GenericDialog } from 'src/core/interim/dialogs/GenericDialog';
import { PosSpinner } from 'src/core/POS/PosSpinner';
import { vars } from 'src/core/themes';
import { Button } from 'src/core/ui';
import { useGetReleaseNoteHtml } from 'src/hooks/api/useGetReleaseNoteHtml';
import { ContentId } from 'src/utils/constants/contentId';
import { ReleaseNote, ReleaseNoteType } from 'src/WebApiController';
import { Pagination } from 'swiper';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';

import * as styles from './ReleaseNoteDisplayDialog.css';

export type ReleaseNoteDisplayDialogProps = Omit<
  ComponentProps<typeof GenericDialog>,
  'header' | 'footer'
> & {
  releaseNoteInfos?: ReleaseNote[] | null;
  onReleaseNotesRead?: () => void;
};

export function ReleaseNoteDisplayDialog({
  releaseNoteInfos,
  onReleaseNotesRead,
  ...genericDialogProps
}: ReleaseNoteDisplayDialogProps): ReactElement | null {
  const newFeatures = releaseNoteInfos?.filter(
    (r) => r.releaseNoteType === ReleaseNoteType.NewFeature
  );
  const bugFixes = releaseNoteInfos?.filter(
    (r) => r.releaseNoteType === ReleaseNoteType.BugFix
  );

  const swiperRef = useRef<SwiperRef>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const totalSlides = useMemo(() => {
    return (newFeatures?.length ?? 0) + (bugFixes?.length ? 1 : 0);
  }, [newFeatures, bugFixes]);

  // We could fetch release notes individually, but it will cause the dialog swiper to jump around because
  // the height of the slides will change as the content loads
  // Sacfrice a bit of performance for a better user experience
  const { releaseNoteHtmlByReleaseNoteId = {}, isLoading: htmlIsLoading } =
    useGetReleaseNoteHtml({
      releaseNoteIds: releaseNoteInfos?.map((r) => r.id),
    });

  return (
    <GenericDialog
      size="lg"
      header={<Content id={ContentId.WhatsNew} />}
      footer={null}
      onClosed={() => {}}
      {...genericDialogProps}
      onCancel={
        // If user clicks cross icon, we want to mark all release notes as read
        // Otherwise they will pop up again
        // User can go to "What's New" page to see them again
        () => onReleaseNotesRead?.()
      }
    >
      <Swiper
        pagination={true}
        modules={[Pagination]}
        ref={swiperRef}
        initialSlide={0}
        onSlideChange={(swiper) => {
          setActiveIndex(swiper.activeIndex);
        }}
        className={styles.swiper}
      >
        {newFeatures?.map((releaseNote) => {
          return (
            <SwiperSlide key={releaseNote.id}>
              <NewFeatureDisplay
                releaseNote={releaseNote}
                releaseNoteHtmlByReleaseNoteId={releaseNoteHtmlByReleaseNoteId}
                isLoading={htmlIsLoading}
              />
            </SwiperSlide>
          );
        })}

        {bugFixes?.length ? (
          <SwiperSlide>
            <BugFixesDisplay
              releaseNotes={bugFixes}
              releaseNoteHtmlByReleaseNoteId={releaseNoteHtmlByReleaseNoteId}
              isLoading={htmlIsLoading}
            />
          </SwiperSlide>
        ) : null}

        <div className={styles.footer}>
          <div>
            {activeIndex > 0 ? (
              <Button
                variant={'regular'}
                onClick={() => swiperRef.current?.swiper.slidePrev()}
              >
                <Content id={ContentId.Back} />
              </Button>
            ) : null}
          </div>
          <Button
            variant={'regular'}
            onClick={() => {
              if (activeIndex + 1 === totalSlides) {
                onReleaseNotesRead?.();
              } else {
                swiperRef.current?.swiper.slideNext();
              }
            }}
            disabled={htmlIsLoading}
          >
            {activeIndex + 1 === totalSlides ? (
              <Content id={ContentId.Done} />
            ) : (
              <Content id={ContentId.Next} />
            )}
          </Button>
        </div>
      </Swiper>
    </GenericDialog>
  );
}

export const NewFeatureDisplay = ({
  releaseNote,
  alignLeft,
  releaseNoteHtmlByReleaseNoteId,
  isLoading,
}: {
  releaseNote: ReleaseNote;
  alignLeft?: boolean;
  releaseNoteHtmlByReleaseNoteId: Record<number, string>;
  isLoading?: boolean;
}) => {
  return (
    <div
      className={styles.newFeatureDisplay}
      style={alignLeft ? { alignItems: 'flex-start' } : undefined}
    >
      <div className={styles.releaseNoteHeader}>
        <p className={styles.newFeatureTitle}>{releaseNote.title}</p>
        <div className={styles.newFeatureBadge}>
          ✦ <Content id={ContentId.NewFeature} />
        </div>
      </div>

      {isLoading ? (
        <PosSpinner />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: releaseNoteHtmlByReleaseNoteId[releaseNote.id],
          }}
        />
      )}
    </div>
  );
};

const BugFixesDisplay = ({
  releaseNotes,
  releaseNoteHtmlByReleaseNoteId,
  isLoading,
}: {
  releaseNotes: ReleaseNote[];
  releaseNoteHtmlByReleaseNoteId: Record<number, string>;
  isLoading?: boolean;
}) => {
  return (
    <div className={styles.bugFixesDisplay}>
      <div className={styles.bugFixBadge}>
        <Content id={ContentId.BugFixes} />
      </div>
      {releaseNotes.map((releaseNote) => {
        return (
          <div
            key={releaseNote.id}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: vars.spacing['sm'],
            }}
          >
            <p className={styles.bugFixTitle}>{releaseNote.title}</p>

            {isLoading ? (
              <PosSpinner />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: releaseNoteHtmlByReleaseNoteId[releaseNote.id],
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
