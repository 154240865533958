import { useCallback } from 'react';
import { LaunchBulkMarkAsFulfilled } from 'src/components/common/BulkActions/BulkMarkAsFulfilled';
import { SALES_BULK_UPDATE_MARK_AS_FULFILLED_UPDATE_KEY } from 'src/components/Sales/SalesActionDropdown';
import {
  IPosEntity,
  useActivePosEntityContext,
} from 'src/contexts/ActivePosEntityContext';
import { useAppContext } from 'src/contexts/AppContext';
import { useCatalogDataContext } from 'src/contexts/CatalogDataContext';
import { DialogId } from 'src/contexts/DialogContext/DialogContext';
import { useDialog } from 'src/contexts/DialogContext/useDialog';
import { useErrorBoundaryContext } from 'src/contexts/ErrorBoundaryContext';
import { BulkMarkAsFulfilledDialog } from 'src/dialogs/BulkEdits/BulkMarkAsFulfilledDialog';
import { tryInvokeApi } from 'src/utils/tryExecuteUtils';
import {
  BulkEditPreviewWithDetails,
  BulkEditSaleClient,
  Event,
  SaleQuery,
} from 'src/WebApiController';

export const useBulkMarkAsFulfilledAction = (
  filterQueryWithEventIds: SaleQuery,
  setIsLoading: (v: boolean) => void,
  isLoading?: boolean,
  event?: Event
) => {
  const { activeAccountWebClientConfig } = useAppContext();
  const { setActivePosEntity } = useActivePosEntityContext<IPosEntity>();
  const updateKey = event
    ? `sales-markasfulfilled-event-${event.viagVirtualId}`
    : SALES_BULK_UPDATE_MARK_AS_FULFILLED_UPDATE_KEY;
  const {
    refreshCatalog,
    eventsExpansion: { setListItemExpansion, refreshExpandedListItems },
  } = useCatalogDataContext();
  const { showErrorDialog } = useErrorBoundaryContext();

  const {
    openDialog: openBulkMarkAsFulfilledDialog,
    closeDialog: closeBulkMarkAsFulfilledDialog,
  } = useDialog(DialogId.BulkMarkAsFulfilled, BulkMarkAsFulfilledDialog);

  const onSubmit = useCallback(
    async (
      supportBackgroundProcess?: boolean,
      onPreviewReceived?: (preview: BulkEditPreviewWithDetails) => void,
      preview?: BulkEditPreviewWithDetails
    ) => {
      setIsLoading(true);
      if (onPreviewReceived) {
        await tryInvokeApi(
          async () => {
            const preview = await new BulkEditSaleClient(
              activeAccountWebClientConfig
            ).bulkUpdateMarkAsFulfilledPreview(filterQueryWithEventIds);

            onPreviewReceived(preview);
          },
          (error) => {
            showErrorDialog('bulkUpdateMarkAsFulfilledPreview', error, {
              trackErrorData: { event },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      } else {
        await tryInvokeApi(
          async () => {
            const saleClient = new BulkEditSaleClient(
              activeAccountWebClientConfig
            );

            const succeeded = await saleClient.bulkUpdateMarkAsFulfilled(
              preview!.preview,
              updateKey,
              supportBackgroundProcess
            );

            if (!supportBackgroundProcess) {
              if (succeeded) {
                // Invalidate the activePosEntity, so that when clicking on listingDetails
                // we force re-loading the listing and newest pricing settings can be displayed
                setActivePosEntity(0);
                await refreshExpandedListItems();
              }

              closeBulkMarkAsFulfilledDialog();
              await refreshCatalog();
            }
          },
          (error) => {
            showErrorDialog('bulkUpdateMarkAsFulfilled', error, {
              trackErrorData: { preview },
            });
          },
          () => {
            if (!supportBackgroundProcess) {
              setIsLoading(false);
            }
          }
        );
      }
    },
    [
      setIsLoading,
      activeAccountWebClientConfig,
      filterQueryWithEventIds,
      showErrorDialog,
      event,
      updateKey,
      closeBulkMarkAsFulfilledDialog,
      refreshCatalog,
      setActivePosEntity,
      refreshExpandedListItems,
    ]
  );

  const onChangeClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (event) {
        setListItemExpansion(true, [event.viagVirtualId]);
      }

      openBulkMarkAsFulfilledDialog({
        updateKey,
        onOkay: onSubmit,
        onClosed: () => {
          setIsLoading(false);
        },
        onCancel: closeBulkMarkAsFulfilledDialog,
      });
    },
    [
      closeBulkMarkAsFulfilledDialog,
      event,
      onSubmit,
      openBulkMarkAsFulfilledDialog,
      setIsLoading,
      setListItemExpansion,
      updateKey,
    ]
  );

  return {
    dropDown: (
      <LaunchBulkMarkAsFulfilled
        key="MarkAsFulfilled"
        onClick={onChangeClick}
        disabled={isLoading}
      />
    ),
  };
};
