import { ReactNode } from 'react';
import { vars } from 'src/core/themes';
import { Stack } from 'src/core/ui';

import { PosSpinner } from '../PosSpinner';
import * as styles from './PosFloatingSpinner.css';

export const PosFloatingSpinner = ({ message }: { message?: ReactNode }) => {
  return (
    <Stack
      gap="l"
      alignItems="center"
      justifyContent="center"
      className={styles.container}
    >
      <div>
        <PosSpinner size={vars.iconSize.xxs} centered={false} />
      </div>
      {message && <div>{message}</div>}
    </Stack>
  );
};
