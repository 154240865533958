import { PosIconProps, SvgWrapper } from './SvgWrapper';

export const UnlistedTicketsIcon = (props: PosIconProps) => {
  return (
    // It's import for the viewbox fo this SVG to stay this value else it won't resize properly
    <SvgWrapper {...props} viewBox="0 0 11 11" fill="none">
      <path
        d="M2.78471 10.4981C3.72427 10.4981 4.48593 9.73648 4.48593 8.79692C4.48593 7.85736 3.72427 7.0957 2.78471 7.0957C1.84516 7.0957 1.0835 7.85736 1.0835 8.79692C1.0835 9.73648 1.84516 10.4981 2.78471 10.4981Z"
        fill="#87909A"
        stroke="#87909A"
      />
      <path
        d="M2.62012 4.68066C4.81523 4.68066 6.95548 6.65626 6.95548 9.01603"
        stroke="#87909A"
        strokeWidth="1.5"
      />
    </SvgWrapper>
  );
};
