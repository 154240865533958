import { vars } from 'src/core/themes';
import styled from 'styled-components/macro';

export const PurchaseTicketTableContainer = styled.div`
  margin-bottom: ${() => vars.spacing.xl};
  border: 1px solid ${(props) => props.theme.colors.borderPrimary};
  border-radius: ${() => vars.spacing.sm};
  overflow: hidden;
`;

export const PurchaseEventItemHeaderWrapper = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.colors.borderPrimary};
  padding: ${() =>
    `${vars.spacing.lg} ${vars.spacing.lg} ${vars.spacing.sm} ${vars.spacing.lg}`};
  background-color: ${(props) => props.theme.colors.backgroundSecondary};
`;

export const ChangesIndicator = styled.div`
  color: ${(props) => props.theme.colors.backgroundBrandActive};
  font-size: ${() => vars.typography.fontSize['2xl']};
  cursor: pointer;
`;
