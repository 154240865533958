import { format } from 'date-fns';
import { useMemo } from 'react';
import { Stack } from 'src/core/ui';
import { formatCurrency } from 'src/utils/numberFormatter';
import { MonthContainingMarketplacePaymentsResult } from 'src/WebApiController';

import * as styles from './GroupedPaymentsAccordionHeader.css';

interface PaymentsAccordionHeaderProps {
  monthContainingMarketplacePayment: MonthContainingMarketplacePaymentsResult;
}

export const GroupedPaymentsAccordionHeader = ({
  monthContainingMarketplacePayment,
}: PaymentsAccordionHeaderProps) => {
  const displayText = useMemo(() => {
    const { totalPaymentAmount } = monthContainingMarketplacePayment;
    return {
      displayDate: format(
        new Date(monthContainingMarketplacePayment.firstOfMonth),
        'MMMM, yyyy'
      ),
      formattedAmount: formatCurrency(
        totalPaymentAmount?.amt,
        totalPaymentAmount?.currency,
        totalPaymentAmount?.dec
      ),
    };
  }, [monthContainingMarketplacePayment]);

  return (
    <Stack justifyContent="spaceBetween" className={styles.itemHeader}>
      <div>{displayText.displayDate}</div>
      <div>{displayText.formattedAmount}</div>
    </Stack>
  );
};
