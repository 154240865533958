import { RecipeVariants } from '@vanilla-extract/recipes';
import { ComponentPropsWithoutRef, forwardRef, ReactElement } from 'react';
import { mergeProps } from 'src/core/utils';

import * as styles from './TextArea.css';

export const Root = forwardRef<
  HTMLDivElement,
  Omit<ComponentPropsWithoutRef<'div'>, 'onChange' | 'onKeyDown' | 'onKeyUp'> &
    RecipeVariants<typeof styles.root.textArea> & { disabled?: boolean }
>(function Root(
  { variant = 'regular', shape = 'square', state, children, ...props },
  ref
): ReactElement {
  return (
    <div
      ref={ref}
      {...mergeProps(
        {
          className: styles.root.textArea({
            state: props.disabled ? 'disabled' : state,
            variant,
            shape,
          }),
        },
        props
      )}
    >
      {children}
    </div>
  );
});

export const Area = forwardRef<
  HTMLTextAreaElement,
  Omit<ComponentPropsWithoutRef<'textarea'>, 'type' | 'children'> &
    RecipeVariants<typeof styles.input.textArea>
>(function Area({ alignment = 'left', ...props }, ref): ReactElement {
  return (
    <textarea
      ref={ref}
      {...mergeProps(
        {
          className: styles.input.textArea({
            alignment,
          }),
        },
        props
      )}
    />
  );
});
