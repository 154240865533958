import { ReactNode } from 'react';

import * as styles from './InstructionsList.css';

export function InstructionsList({ children }: { children?: ReactNode }) {
  return <ol className={styles.orderedList}>{children}</ol>;
}

export function InstructionsListItem({
  containerClassName,
  children,
}: {
  containerClassName?: string;
  children?: ReactNode;
}) {
  return (
    <li className={styles.orderedListItem}>
      <div className={containerClassName}>{children}</div>
    </li>
  );
}
